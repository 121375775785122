import * as Helpers from 'shared/helpers/andys-little-helpers';
import * as ProgressSpinner from 'client/components/progress-spinner';
import * as React from 'react';
import * as Types from './types';
import * as classnames from 'classnames';

export interface ComponentProps {
  fileExtension: string;

  errorMessages: string[] | undefined;
  failedStep: Types.ImportStep | undefined;
  importState: Types.ImportState;

  stillWorking: boolean;

  handleFileSelected: (fileName: string, contents: string) => void;

  chooseFileButtonDisabled?: boolean;
}

export class FileChooser extends React.PureComponent<ComponentProps, {}> {
  onFileInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const files = (event.target as any).files;

    if (files.length !== 1) {
      return;
    }

    const file: File = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (!reader.result) {
        throw new Error('Unable to read file!');
      }
      if (typeof reader.result !== 'string') {
        throw new Error('Unexpected file contents (not a string)');
      }

      this.props.handleFileSelected(file.name, reader.result);
    };

    reader.readAsBinaryString(file);
  }

  render() {
    const ChooseFileButton = (props: { label: string }) => {
      const className = classnames(
        'import-modal-file-picker align-bottom btn btn-default btn-file mfc-button mfc-submit-button mfc-button-primary',
        { disabled: this.props.chooseFileButtonDisabled },
      );

      return (
        <label className={className}>
          {props.label}
          <input
            type="file"
            id="import-modal-file-picker-input-element"
            className="import-modal-file-picker-input-element"
            data-testid="file-picker"
            onChange={this.onFileInputChange}
            style={{ display: 'none' }}
            accept={this.props.fileExtension}
            disabled={this.props.chooseFileButtonDisabled}
          />
        </label>
      );
    };

    const ChooseFileContent = () => (
      <div className="import-modal-content-area">
        <div className="text-center">
          <ChooseFileButton label="Choose File" />
        </div>
      </div>
    );

    const ParsingContent = () => (
      <div className="import-modal-content-area">
        <div className="import-modal-spinner-container">
          <ProgressSpinner.ProgressSpinner label={this.props.stillWorking ? 'Still Parsing...' : 'Parsing...'} />
        </div>
      </div>
    );

    const ValidatingContent = () => (
      <div className="import-modal-content-area">
        <div className="import-modal-spinner-container">
          <ProgressSpinner.ProgressSpinner label={this.props.stillWorking ? 'Still Validating...' : 'Validating...'} />
        </div>
      </div>
    );

    const ValidatedContent = () => (
      <div className="import-modal-content-area">
        <div className="text-center">
          <span className="fa fa-check fa-5x import-modal-validated-status-icon" />
          <div className="import-modal-status-text">Validated</div>
        </div>
      </div>
    );

    const ImportingContent = () => (
      <div className="import-modal-content-area">
        <div className="import-modal-spinner-container">
          <ProgressSpinner.ProgressSpinner label={this.props.stillWorking ? 'Still Importing...' : 'Importing...'} />
        </div>
      </div>
    );

    const ImportedContent = () => (
      <div className="import-modal-content-area">
        <div className="text-center">
          <span className="fa fa-check fa-5x import-modal-imported-status-icon" />
          <div className="import-modal-status-text">Imported</div>
        </div>
      </div>
    );

    const FailedContent = () => (
      <div>
        <div className="text-center">
          <span className="fa fa-times fa-5x import-modal-failed-status-icon" />
          <div className="import-modal-status-text">{this.props.failedStep} Failed</div>
        </div>
        <div id="import-error-message-box" className="alert alert-danger">
          <strong>There was a problem with the import file.</strong>
          <ul id="import-error-list">
            {(this.props.errorMessages || []).map((message, index) => <li key={`import-message-${index}`}>{message}</li>)}
          </ul>
        </div>
        <div className="text-center">
          <ChooseFileButton label="Choose Another File" />
        </div>
      </div>
    );

    switch (this.props.importState) {
      case Types.ImportState.Initial:
        return <ChooseFileContent />;
      case Types.ImportState.Parsing:
        return <ParsingContent />;
      case Types.ImportState.Validating:
        return <ValidatingContent />;
      case Types.ImportState.Validated:
        return <ValidatedContent />;
      case Types.ImportState.Importing:
        return <ImportingContent />;
      case Types.ImportState.Imported:
        return <ImportedContent />;
      case Types.ImportState.Failed:
        return <FailedContent />;
      default:
        Helpers.assertUnreachable(this.props.importState);
        return <div />; // makes the compiler happy :-)
    }
  }
}
