import * as _ from 'lodash';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Helpers from './helpers';
import * as Types from './types';
export * from './types';

interface SuccessfulParsedSupplierCommitment {
  success: true;
  importableSupplierCommitments: Types.ImportableSupplierCommitment[];
}
interface FailedParsedSupplierCommitment {
  success: false;
  reason: string;
}
type ParsedSupplierCommitmentResult = SuccessfulParsedSupplierCommitment | FailedParsedSupplierCommitment;
const genericFailureMessage = 'The file cannot be processed. Please verify that the format of the spreadsheet is correct.';
export async function getImportableSupplierCommitments(workBookContainer: ExcelCommon.WorkBookContainer): Promise<ParsedSupplierCommitmentResult> {
  try {
    const workBook = await Helpers.getSupplierCommitmentWorkBookFromWorkBookContainer(workBookContainer);
    if (!workBook) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const supplierCommitments = Helpers.getSupplierCommitments(workBook);

    if (supplierCommitments.length === 0) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const isMalformedHeader = (supplierCommitment: Types.ImportableSupplierCommitment) => _.isEmpty(supplierCommitment.customerIdentifier) || _.isEmpty(supplierCommitment.season) || _.isEmpty(supplierCommitment.supplierIdentifier) || _.isNil(supplierCommitment.year);

    if (supplierCommitments.filter(sc => !isMalformedHeader(sc)).length !==  supplierCommitments.length) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    return {
      success: true,
      importableSupplierCommitments: supplierCommitments,
    };
  } catch (error) {
    return {
      success: false,
      reason: genericFailureMessage,
    };
  }
}
