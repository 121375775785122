import { flowRight } from 'lodash';
import { push } from 'connected-react-router';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SupplierName, SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { connect } from 'react-redux';
import { CartTrackingDetailsRecordBarResponse, CartTrackingDetailsRecordBarQuery } from 'client/app/transportation/cart-tracking/details/cart-tracking-details-record-bar/cart-tracking-details-record-bar-query';
import { CartTrackingDetailsRecordBar } from 'client/app/transportation/cart-tracking/details/cart-tracking-details-record-bar/cart-tracking-details-record-bar';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { SupplierCartTrackingStatusMutation } from 'client/app/transportation/cart-tracking/details/cart-tracking-details-record-bar/cart-tracking-supplier-tracking-status-mutation';

interface OwnProps {
  supplierId: SupplierId;
}

interface SupplierRecordBarProps {
  supplierName: SupplierName;
  cartTrackingStatus: SupplierCartTrackingStatus;
}

const WithSupplier = msyncQuery<CartTrackingDetailsRecordBarResponse, OwnProps, SupplierRecordBarProps | undefined>(CartTrackingDetailsRecordBarQuery, {
  options(ownProps): { variables } {
    return {
      variables: {
        id: ownProps.supplierId,
      },
    };
  },
  props({ data, ownProps }): SupplierRecordBarProps | undefined {
    if (!data.response) {
      return undefined;
    }

    return {
      supplierName: data.response.name,
      cartTrackingStatus: data.response.cartTrackingStatus,
    };
  },
});

interface MutationProps {
  updateSupplierCartTrackingStatus: (args: { supplierId: SupplierId, status: string }) => shame;
}

const WithUpdateSupplierCartTrackingStatusMutation = msyncMutation(SupplierCartTrackingStatusMutation, {
  props: ({ mutate }): MutationProps => {
    return {
      updateSupplierCartTrackingStatus: (args: { supplierId: SupplierId, status: string }) =>  mutate({
        variables: {
          supplierId: args.supplierId,
          status: args.status,
        },
      }),
    };
  },
});

interface DispatchProps {
  onBackButtonClicked(): void;
  onSupplierClick(supplierId: SupplierId): void;
}

const mapDispatchToProps = {
  onBackButtonClicked: () => push(`/transportation/cartTracking/list`),
  onSupplierClick: supplierId => push(`/admin/suppliers/details/${supplierId}`),
};

export type ComponentProps =
  OwnProps &
  MutationProps;

const component = flowRight(
  WithSupplier,
  WithUpdateSupplierCartTrackingStatusMutation,
  connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(CartTrackingDetailsRecordBar);

export default (props: OwnProps) => propToComponent(component, props);
