import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { ReceivingOverviewSidebar } from 'client/app/transportation/receiving/details/sidebar/sidebar';
import { SupplierOrderPlannedArrivalDate, SupplierOrderStoreDeliveryDate, SupplierOrderInvoiced } from 'shared/schemas/supplier-order';
import { msyncQuery } from 'client/hoc/graphql/query';
import { ReceivingOverviewSidebarQuery, ReceivingOverviewSidebarResponse } from 'client/app/transportation/receiving/details/sidebar/sidebar-query';
import { SupplierOrderStatus, DateTimeStr, ReceivableOrderReceivingStatus, DeliveryMethods, OrderMethod, SupplierOrderTypes, CustomerOrderType, ShippingUnitType } from 'shared/types';
import { SellDepartmentIdentifier } from 'shared/schemas/sell-department';
import { SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { getUserName } from 'shared/schemas/user';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';

interface OwnProps {
  receivableOrderId: number;
  actionMenuItems?: Array<{
    label: string;
    action: (setInProgress: SetInProgress, identifier: string) => void;
  }>;
}

interface SidebarProps {
  customer: string;
  deliveryMethod: DeliveryMethods;
  identifier: string;
  invoiced: SupplierOrderInvoiced;
  invoiceNumber: string | null;
  isOrderSent: boolean;
  lastPurchaseOrderEmailedAt?: DateTimeStr | null;
  lastPurchaseOrderEmailedName?: string | null;
  lastReceivingReceiptEmailedAt?: DateTimeStr | null;
  lastReceivingReceiptEmailedName?: string | null;
  mfcAreaIdentifier?: MfcAreaIdentifier | null;
  notes?: string | null;
  orderMethod: OrderMethod;
  orderStatus: SupplierOrderStatus | null;
  orderType: SupplierOrderTypes | CustomerOrderType;
  plannedArrivalDate: SupplierOrderPlannedArrivalDate;
  received: ReceivableOrderReceivingStatus;
  sellDepartment: SellDepartmentIdentifier;
  shippingUnitType: ShippingUnitType | null;
  storeDeliveryDate: SupplierOrderStoreDeliveryDate;
  subSellDepartment?: string | null;
  supplier: string;
  scanBased: boolean | null;
}

const WithSupplierOrder = msyncQuery<ReceivingOverviewSidebarResponse, OwnProps, SidebarProps | undefined>(ReceivingOverviewSidebarQuery, {
  options(ownProps): { variables } {
    return {
      variables: {
        id: ownProps.receivableOrderId,
      },
    };
  },
  props({ data }): SidebarProps | undefined {
    if (!data.response) {
      return undefined;
    }

    return {
      identifier: data.response.identifier,
      orderType: data.response.orderType,
      plannedArrivalDate: data.response.arrivalDate,
      storeDeliveryDate: data.response.storeDeliveryDate,
      orderMethod: data.response.orderMethod,
      shippingUnitType: data.response.shippingUnitType,
      invoiceNumber: data.response.invoiceNumber,
      sellDepartment: data.response.sellDepartmentIdentifier,
      subSellDepartment: data.response.subSellDepartmentIdentifier,
      supplier: `${data.response.supplierVendorIdentifier} - ${data.response.supplierVendorName}`,
      customer: `${data.response.customerIdentifier} - ${data.response.customerName}`,
      mfcAreaIdentifier: data.response.mfcAreaIdentifier,
      notes: data.response.notes,
      deliveryMethod: data.response.deliveryMethod,
      orderStatus: data.response.orderStatus,
      received: data.response.receivingStatus,
      invoiced: data.response.invoiced,
      isOrderSent: data.response.orderStatus === SupplierOrderStatus.Sent,
      lastPurchaseOrderEmailedName: data.response.lastPurchaseOrderEmailedInfo && getUserName(data.response.lastPurchaseOrderEmailedInfo),
      lastPurchaseOrderEmailedAt: data.response.lastPurchaseOrderEmailedInfo && data.response.lastPurchaseOrderEmailedInfo.emailedAt,
      lastReceivingReceiptEmailedAt: data.response.mails.length > 0 ? data.response.mails[0].createdAt : undefined,
      lastReceivingReceiptEmailedName: data.response.mails.length > 0 ? getUserName(data.response.mails[0].user) : undefined,
      scanBased: data.response.scanBased,
    };
  },
});

export type ComponentProps =
  OwnProps &
  SidebarProps;

const component = flowRight(
  WithSupplierOrder,
)(ReceivingOverviewSidebar);

export default (props: OwnProps) => propToComponent(component, props);
