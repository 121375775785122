import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/invoice/invoice-graphql-types';

export interface Input {
  input: GraphQLTypes.SendInvoicesToAcumaticaInput;
}

export interface Response {
  result: {
    invoice: Pick<GraphQLTypes.Invoice, 'id' | 'transferredToAccounting'>;
  };
}

export const Mutation = gql`
  mutation SendInvoicesToAcumatica($input: SendInvoicesToAcumaticaInput!) {
    result: SendInvoicesToAcumatica(input: $input) {
      invoices {
        id
        transferredToAccounting
      }
    }
  }
`;
