import * as _ from 'lodash';
import * as React from 'react';
import { formatInteger, ReceivingSummaryStats } from 'shared/types';
import { StatsCard } from 'client/components/simple-components/simple-stats-card';

export function ReceivingReceivedCard(props: Pick<ReceivingSummaryStats, 'unit' | 'received'>) {
  return (
    <StatsCard title={`${props.unit || ''} Received`}>{_.isNil(props.received) ? '-' : formatInteger(props.received)}</StatsCard>
  );
}
