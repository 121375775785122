import { DateStr, DateTimeStr, RoutePlanActionTypes, LoadOrigin, LoadType, DropoffLocation, RouteType, LoadDestination, Carrier, FilterValues } from 'shared/types';
import { CustomerId, CustomerIdentifier, CustomerName } from 'shared/schemas/customer';
import { MfcAreaId, MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { User } from 'shared/schemas/user';
import { Saved } from 'shared/schemas/record';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';

export type RoutePlanCustomerId = CustomerId;
export type RoutePlanCustomerIdentifier = CustomerIdentifier;
export type RoutePlanCustomerName = CustomerName;

export type RoutePlanMfcAreaId = MfcAreaId;
export type RoutePlanMfcAreaIdentifier = MfcAreaIdentifier;

export type RoutePlanId = Flavor<number, 'routePlanId'>;
export type RoutePlanIdentifier = Flavor<string, 'routePlanIdentifier'>;
export type RoutePlanDeliveryDate = Flavor<DateStr, 'routePlanDeliveryDate'>;

export type RoutePlanStores = Flavor<number, 'routePlanStores'>;
export type RoutePlanLoadCount = Flavor<number, 'routePlanLoadCount'>;
export type RoutePlanStopCount = Flavor<number, 'routePlanStopCount'>;
export type RoutePlanRacks = Flavor<number, 'routePlanRacks'>;
export type RoutePlanPallets = Flavor<number, 'routePlanPallets'>;
export type RoutePlanLastModified = Flavor<DateTimeStr, 'routePlanLastModified'>;

export enum RoutePlanStatus {
  Draft = 'Draft',
  ReadyToRoute = 'Ready To Route',
  Routed = 'Routed',
  Extracted = 'Extracted',
  ChangedByVBills = 'Changed By VBills',
}

export interface RoutePlanIdOnly {
  id: RoutePlanId;
}

export type RoutePlan = RoutePlanGQLResult;
export interface RoutePlanGQLResult {
  id: RoutePlanId;
  identifier: RoutePlanIdentifier;
  customerId: CustomerId;
  customerIdentifier: RoutePlanCustomerIdentifier;
  customerName: RoutePlanCustomerName;

  mfcAreaId: MfcAreaId | null;
  mfcAreaIdentifier: RoutePlanMfcAreaIdentifier | null;
  deliveryDate: RoutePlanDeliveryDate;
  status: RoutePlanStatus;
  sellDepartmentId: SellDepartmentId;

  storeCount: number;
  loadCount: RoutePlanLoadCount;
  peakOrderNumberCount: number;
  stopCount: RoutePlanStopCount;
  racks: RoutePlanRacks;
  pallets: RoutePlanPallets;
  lastModified: RoutePlanLastModified | null;

  origin: LoadOrigin;
  numberOfAttachedOrders: number;

  actions: RoutePlanActions;
  loadType: LoadType;
  dropoffLocation: DropoffLocation;
  routeType: RouteType;
  destination: LoadDestination;
  carrier: Carrier;
  trailerTemperature: number;
  forceReroute: boolean;
  customerOrdersUpdatedSinceRoutePacketPrinting: boolean;
}

export interface RoutePlanActions {
  createRoutePackets: RoutePlanAction;
  downloadDittyCards: RoutePlanAction;
  emailMerchandiserAsn: RoutePlanAction;
  createCustomerAsn: RoutePlanAction;
  downloadStoreDeliverySpreadsheet: RoutePlanAction;
  sendLoadsToLoadmaster: RoutePlanAction;
  emailRouteSheetsToPeak: RoutePlanAction;
  createPalletReport: RoutePlanAction;
  downloadStoreAsn: RoutePlanAction;
}

export interface RoutePlanAction {
  completed: boolean;
  completedAt: DateTimeStr | null;
  user: Saved<User> | null;
}

type RoutePlanActionResolver = () => Promise<{
  completed: boolean;
  completedAt: DateTimeStr | null;
  user: () => Promise<Saved<User> | null> | null;
}>;
export interface RoutePlanActionResolvers {
  createRoutePackets: RoutePlanActionResolver;
  downloadDittyCards: RoutePlanActionResolver;
  emailMerchandiserAsn: RoutePlanActionResolver;
  createCustomerAsn: RoutePlanActionResolver;
  downloadStoreDeliverySpreadsheet: RoutePlanActionResolver;
  sendLoadsToLoadmaster: RoutePlanActionResolver;
  emailRouteSheetsToPeak: RoutePlanActionResolver;
  createPalletReport: RoutePlanActionResolver;
  downloadStoreAsn: RoutePlanActionResolver;
}

export interface RoutePlanLoadsResolvers {
  numberOfStores: number;
  racks: number;
  pallets: number;
  dropsCombinedWithAdditionalDrops: number;
  totalFee: number;
  milesCombinedWithAdditionalMiles: number;
  loadCount: number;
}

export interface CreateRoutePlanInput {
  identifier?: RoutePlanIdentifier;
  customerId: RoutePlanCustomerId;
  mfcAreaId?: RoutePlanMfcAreaId;
  deliveryDate: RoutePlanDeliveryDate;
  origin: LoadOrigin;
  sellDepartmentId: SellDepartmentId;
}

enum IsValidCreateRoutePlanInput { }
export type ValidCreateRoutePlanInput = CreateRoutePlanInput & IsValidCreateRoutePlanInput;

export interface CreateRoutePlanResponse {
  createRoutePlan: {
    routePlan: RoutePlanGQLResult;
  };
}

export interface EditRoutePlanInput {
  id: RoutePlanId;
  identifier?: RoutePlanIdentifier;
  customerId?: CustomerId;
  mfcAreaId?: MfcAreaId;
  deliveryDate?: RoutePlanDeliveryDate;
  origin?: LoadOrigin;
  sellDepartmentId?: SellDepartmentId;
  status?: RoutePlanStatus;
  loadType?: LoadType | null;
  dropoffLocation?: DropoffLocation | null;
  routeType?: RouteType | null;
  destination?: LoadDestination | null;
  carrier?: Carrier | null;
  trailerTemperature?: number | null;

  // We coupled this type to all layers of the server, so Andy doesn't really
  // want this field to be exposed to the graphql layer, but to save time,
  // Andy will put forceReroute here.
  forceReroute?: boolean;
}

enum IsValidEditRoutePlanInput { }
export type ValidEditRoutePlanInput = EditRoutePlanInput & IsValidEditRoutePlanInput;

export interface EditRoutePlanActionsInput {
  routePlanId: RoutePlanId;
  actionType: RoutePlanActionTypes;
}

export interface EditRoutePlanActionsResponse {
  routePlanResult: {
    routePlan: RoutePlanGQLResult;
  };
}

enum IsValidEditRoutePlanActionsInput { }
export type ValidEditRoutePlanActionsInput = EditRoutePlanActionsInput & IsValidEditRoutePlanActionsInput;

export interface EditRoutePlanResponse {
  editRoutePlan: {
    routePlan: RoutePlanGQLResult;
  };
}

export interface MarkRoutePlanAsReadyToRouteResponse {
  routePlanResponse: {
    routePlan: RoutePlanGQLResult;
  };
}

export interface ExtractRoutePlanResponse {
  extractRoutePlan: {
    routePlan: RoutePlanGQLResult;
    directRouteCsv: {
      id: number;
    };
  };
}

export interface RoutePlanSortInput {
  sortField: shame;
  sortOrder: shame;
}

export interface RoutePlanSearchInput {
  text: string;

  identifier?: boolean;
  customerIdentifier?: boolean;
  customerName?: boolean;
  mfcAreaIdentifier?: boolean;
  status?: boolean;
}

export interface RoutePlanFilterInput {
  identifier?: FilterValues<RoutePlanIdentifier>;
  customer?: FilterValues<RoutePlanCustomerId>;
  customerIdentifier?: FilterValues<RoutePlanCustomerIdentifier>;
  customerName?: FilterValues<RoutePlanCustomerName>;
  mfcArea?: FilterValues<RoutePlanMfcAreaIdentifier>;
  deliveryDate?: FilterValues<RoutePlanDeliveryDate>;
  status?: FilterValues<RoutePlanStatus>;
}

export type GetRoutePlansLimitInput = Flavor<number, 'getRoutePlansInputLimit'>;
export type GetRoutePlansOffsetInput = Flavor<number, 'getRoutePlansInputOffset'>;

export interface GetRoutePlanInput {
  id: RoutePlanId;
}

export interface GetRoutePlansInput {
  sort?: RoutePlanSortInput[];
  filters?: RoutePlanFilterInput;
  search?: RoutePlanSearchInput;
  limit?: GetRoutePlansLimitInput;
  offset?: GetRoutePlansOffsetInput;
  scope?: RoutePlanFilterInput;
}

export interface GetRoutePlansListPageFilterOptionsInput {
  // filters?: RoutePlanFilterInput;
  // search?: RoutePlanSearchInput;
  scope?: RoutePlanFilterInput;
}

export interface GetRoutePlansResponse {
  routePlans: RoutePlanGQLResult[];
  ids: number[];
  totalCount: number;
  totalUnfilteredCount: number;
}

export type RoutePlanRoot = Partial<RoutePlanGQLResult> & { id: RoutePlanId };

export interface RoutePlanFilterOption {
  id: string;
  value: string;
  displayValue: string;
}

export interface GetRoutePlansListPageFilterOptionsResponse {
  customers: RoutePlanFilterOption[];
  mfcAreas: RoutePlanFilterOption[];
  statuses: RoutePlanFilterOption[];
}

export interface AcquireRoutingLoadNumbersInput {
  routePlanId: RoutePlanId;
  loadCount: number;
}

export interface AcquireRoutingLoadNumbersResponse {
  loadNumbers: string[];
}

export interface MarkCustomerOrdersAsShippedInput {
  routePlanId?: RoutePlanId;
  routingLoadIds?: RoutingLoadId[];
}

export interface MarkCustomerOrdersAsShippedResponse {
  markCustomerOrdersAsShipped: CustomerOrderId[];
}

export interface MerchandisersWithStores {
  name: string;
  merchandiserId: number;
  storeIdentifiers: string[];
}

export interface StoresWithMerchandisers {
  storeId: number;
  storeIdentifier: string;
  storeDisplay: string;
  merchandiserName: string;
  merchandiserId: number;
  route: number;
  customerId: number;
  sellDepartmentId: number;
  supervisorRank: number;
}

export interface MerchandisersWithStoresResponse {
  merchandisersWithStores: MerchandisersWithStores[];
}

export interface GetMerchandisersWithStoresInput {
  routePlanId: RoutePlanId;
}

export interface StoresWithMerchandisersResponse {
  storesWithMerchandisers: StoresWithMerchandisers[];
}

export interface GetStoresWithMerchandisersInput {
  routePlanId: RoutePlanId;
}

export interface GetUniqueStopsForRoutePlanInput {
  routePlanId: RoutePlanId;
}

export interface UniqueStopForRoutePlan {
  stopNumber: string;
  loadNumber: string;
  storeId: number;
}

export interface UniqueStopsForRoutePlanResponse {
  stops: UniqueStopForRoutePlan[];
}

export interface EmailMerchandisersInput {
  storeIds: number[];
  merchandiserIds: number[];
  body: string;
  subject: string;
  carbonCopy: string;
}

export interface EmailMerchandiserSpecificASNsInput {
  routePlanId: RoutePlanId;
  merchandiserIds: number[];
}
export type EmailMerchandiserSpecificASNsResponse = boolean;

export interface AddAdditionalStoreToRoutePlanInput {
  routePlanId: number;
  storeId: number;
  numberOfPallets: number;
  numberOfRacks: number;
}
export interface AddAdditionalStoreToRoutePlanResponse {
  routePlan: RoutePlan;
}

export interface StoreSummaryReportStore {
  storeId: number;
  storeIdentifier: string;
  storeDisplay: string;
}

export interface StoreSummaryReportCheckableStore extends StoreSummaryReportStore {
  checked: boolean;
}

export interface StoreSummaryReportStoresResponse {
  stores: StoreSummaryReportStore[];
}

export interface GetStoreSummaryReportStoresInput {
  routePlanId: RoutePlanId;
}
