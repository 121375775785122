import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ApolloRefetch, ActiveSort } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { buildTableStateModule } from 'client/state/tables';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { GetRelatedCustomerOrdersResponse, RelatedCustomerOrder } from 'schema/supplier-order/types';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import { getRelatedCustomerOrdersQuery } from './query';
import { CustomerOrdersTable } from './table';

export interface OwnProps {
  supplierOrderId: SupplierOrderId;
  tableName: string;
}

interface StateProps {
  activeSortFields: ActiveSort[];
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const TableStateHelpers = buildTableStateModule(ownProps.tableName);
  return TableStateHelpers.commonTableProps(state);
};

interface WithRelatedCustomerOrdersProps {
  customerOrders?: RelatedCustomerOrder[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  refetchTable?: ApolloRefetch;
  loadMoreRecords: () => void;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithRelatedCustomerOrders = msyncQuery<{ getRelatedCustomerOrders: GetRelatedCustomerOrdersResponse }, OwnProps & StateProps & WithTableParentProps, WithRelatedCustomerOrdersProps>(
  getRelatedCustomerOrdersQuery,
  {
    alias: 'withRelatedCustomerOrders',
    options(ownProps) {
      return {
        variables: {
          input: {
            supplierOrderId: ownProps.supplierOrderId,
            sort: ownProps.activeSortFields,
          },
        },
        fetchPolicy: 'network-only',
      };
    },
    props({ data, ownProps }): WithRelatedCustomerOrdersProps {
      const content = data.getRelatedCustomerOrders;
      return {
        customerOrders: content ? content.relatedCustomerOrders : undefined,
        loading: _.isNil(data.loading) ? true : data.loading,
        totalCount: content ? content.relatedCustomerOrders.length : 0,
        totalUnfilteredCount: content ? content.relatedCustomerOrders.length : 0,
        refetchTable: data.refetch,
        filteredRecordIds: content ? idsFor(content.relatedCustomerOrders) : [],
        // eslint-disable-next-line  no-empty
        loadMoreRecords: () => {},
        dataRequest: {
          operationName: 'getRelatedCustomerOrders',
          query: getRelatedCustomerOrdersQuery,
          variables: data.variables,
          worksheetName: 'Sheet1',
          workbookName: 'Related Customer POs',
        },
      };
    },
  },
);

export type ComponentProps =
  OwnProps &
  StateProps &
  WithRelatedCustomerOrdersProps &
  WithTableParentProps;

const component = flowRight(
    tableParentHoc(),
    connect<StateProps, {}, OwnProps>(mapStateToProps),
    WithRelatedCustomerOrders,
  )(CustomerOrdersTable);

export const RelatedCustomerOrdersContainer = (props: OwnProps) => propToComponent(component, props);
