import { tableName, property, belongsTo, definePresentation, required } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { SellDepartment } from './sell-department';
import { IRecord } from './record';
import { displayType } from './dsl';

export type SubSellDepartmentId= Flavor<number, 'subSellDepartmentId'>;
export type SubSellDepartmentIdentifier = Flavor<string, 'subSellDepartmentIdentifier'>;

@tableName('subSellDepartments')
export class SubSellDepartment implements IRecord {
  id?: SubSellDepartmentId;
  @belongsTo('sellDepartments')
  @property @required sellDepartment: SellDepartment;
  sellDepartmentId: number;

  @property @required identifier: SubSellDepartmentIdentifier;
}

definePresentation(SubSellDepartment, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'Sub Sell Department',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 100,
  },
  sellDepartment: {
    formDisplay: false,
  },
});
