import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../form';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="sellDepartments" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

function SellDepartmentForm(props: shame) {
  return (
    <Col sm={12}>
      <Form horizontal onSubmit={props.handleSubmit}>
        <div className="simple-form" data-testid="sell-department">
          <FormGroup data-testid="info-row-1">
            <Field name="identifier" labelColSize={2} inputColSize={4} autoFocus={!props.record || !props.record.id} />
          </FormGroup>
        </div>
      </Form>
    </Col>
  );
}
/*
SellDepartmentForm.propTypes = {
  loading: React.PropTypes.bool,
  initialValues: React.PropTypes.object,

  // come from redux-form
  pristine: React.PropTypes.bool.isRequired,
  submitting: React.PropTypes.bool.isRequired,
  handleSubmit: React.PropTypes.func.isRequired,
};
*/
export default SellDepartmentForm;
