import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, setSchemaOptions, required } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, SORT_TYPES, DateStr } from 'shared/types';
import { Product, ProductId } from './product';
import { SupplierItem, SupplierItemId } from 'shared/schemas/supplier-item';
import { SupplierOrderProductGroup } from 'shared/schemas/supplier-order-product-group';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { IRecord } from 'shared/schemas/record';
import { SupplierItemDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type SupplierOrderProductId = Flavor<number, 'supplierOrderProductId'>;

@tableName('supplierOrderProducts')
export class SupplierOrderProduct implements IRecord {
  static readonly UniqueConstraints: Array<keyof SupplierOrderProduct> = ['supplierOrderProductGroup', 'supplierItem'];

  id?: SupplierOrderProductId;

  @belongsTo('supplierOrders', { through: 'supplierOrderProductGroups', foreignQueryKeys: ['identifier', 'lastModifiedAt', 'receivingStatus'] })
  @property supplierOrder: SupplierOrder;
  supplierOrderId: number;

  @belongsTo('supplierOrderProductGroups', { foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier', 'description', 'isCombo', 'shippingUnitQuantity', 'packsPerShippingUnit'] })
  @property @required supplierOrderProductGroup: SupplierOrderProductGroup;
  supplierOrderProductGroupId: number;

  @belongsTo('supplierItems', { foreignQueryKeys: [] })
  @property @required supplierItem: SupplierItem;
  supplierItemId: SupplierItemId;

  @belongsTo('products', { through: 'supplierItems', foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier', 'description', 'packSize'] })
  @property product: Product;
  productId: ProductId;

  @property @required productDescription: Flavor<string, 'supplierOrderProductProductDescription'>;

  @property shelvesPerRack: number | null;
  @property packsPerShelf: number | null;
  @property @required packSize: number; // supplier pack size

  @property packsPerShippingUnit: number | null;
  @property @required packQuantity: number; // supplier

  @property @required cost: number;
  @property @required retailPrice: number;
  @property additionalCost?: number | null;
  @property supplierPackCost?: number | null;
  @property stems?: string | null;
  @property pieceQty?: number | null;

  @property extendedCost?: number | null;
  // # things (piece or packs) * quantity
  // piece cost * pieces ordered

  @property sellDays?: number | null;
  @property sellDate?: DateStr | null;
  @property shippingUnitQuantity?: number | null;

  @property piecesPerShippingUnit: number | null; // calculated

  @property productIdentifier: string; // calculated
  @property upc: string; // calculated
  @property rackType: string; // calculated
}

// Validators moved to shared/validations and server/validations

definePresentation(SupplierOrderProduct, {
  supplierOrder: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  supplierItem: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierItemDisplayValueResolver }),
  },
  product: {
    tableDisplay: true,
    sortable: true,
    searchable: true,
    formDisplay: false,
    includeInFormQuery: true,
    columnWidth: 10,
    displayName: 'Prod ID',
  },
  retailPrice: {
    displayName: 'Piece Retail',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  cost: { // piece cost -- TODO: does this need renaming, or does the rest of the system expect piece cost here too?
    displayName: 'Piece Cost',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  supplierPackCost: {
    displayName: 'Supplier Pack Cost',
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {},
      calculation: (p: SupplierOrderProduct) => `(1.0 * ${p.cost} * ${p.packSize})`,
    },
  },
  additionalCost: {
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  packQuantity: { // supplier pack quantity
    displayName: 'Supplier Pack Quantity',
    tableDisplay: true,
    sortable: true,
    tableEditable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  packsPerShippingUnit: {
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    displayName: 'PPR',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  packSize: { // supplier pack
    displayName: 'Supp Pk',
    sortable: true,
    tableDisplay: true,
    type: TYPES.NUMBER,
    tableEditable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  packsPerShelf: {
    displayName: 'PPS',
    type: TYPES.NUMBER,
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  shelvesPerRack: {
    displayName: 'SPR',
    columnWidth: 5,
    sortable: true,
    tableEditable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  productDescription: {
    tableDisplay: true,
    sortable: true,
    searchable: true,
    tableEditable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  stems: {
    tableDisplay: true,
    tableEditable: true,
    sortable: true,
    columnWidth: 5,
    type: TYPES.STRING,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  pieceQty: {
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    calculationSpec: {
      dependencies: {},
      calculation: (p: SupplierOrderProduct) => `(1.0 * ${p.packQuantity} * ${p.packSize})`,
    },
  },
  extendedCost: {
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {},
      calculation: (p: SupplierOrderProduct) => `(1.0 * ${p.cost} * ${p.packQuantity} * ${p.packSize})`,
    },
  },
  sellDays: {
    tableDisplay: true,
    sortable: true,
    formDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }), // this affects cell type for in-table editing, too.
    includeInFormQuery: true,
    tableEditable: true,
    columnWidth: 10,
    displayName: 'Sell Days',
    type: TYPES.NUMBER,
  },
  sellDate: {
    displayName: 'Sell Date',
    sortable: true,
    tableDisplay: true,
    filterable: true,
    columnWidth: 8,
    tableEditable: false,
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    calculationSpec: {
      dependencies: {},
      calculation: (sop: SupplierOrderProduct) => `(case when ${sop.sellDays || -1} < 0 then null else (SELECT store_delivery_date FROM supplier_orders WHERE id = (SELECT supplier_order_id FROM supplier_order_product_groups WHERE id = supplier_order_products.supplier_order_product_group_id)) + ${sop.sellDays} end)`,
    },
  },
  supplierOrderProductGroup: {
    tableDisplay: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  shippingUnitQuantity: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {},
      calculation: sop => `CASE WHEN ${sop.packsPerShippingUnit} = 0 THEN NULL WHEN ${sop.packsPerShippingUnit} IS NULL THEN NULL ELSE (${sop.packQuantity} * 1.0) /  ${sop.packsPerShippingUnit} END`,
    },
  },
  piecesPerShippingUnit: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
    calculationSpec: {
      dependencies: { },
      calculation: (sop: SupplierOrderProduct) => `(COALESCE(supplier_order_products.pack_size, 0) * COALESCE(supplier_order_products.packs_per_shipping_unit, 0)) `,
    },
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  productIdentifier: {
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: (sop: SupplierOrderProduct) => `
        (
            SELECT
              products.identifier
            FROM
              products
            INNER JOIN
              supplier_items ON supplier_items.product_id = products.id AND supplier_items.id = supplier_order_products.supplier_item_id
        )
      `,
    },
    tableDisplay: false,
    sortable: true,
    filterable: false,
  },
  upc: {
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: (sop: SupplierOrderProduct) => `
        (
            SELECT
              product_upcs.identifier
            FROM
              product_upcs
            INNER JOIN
              products ON products.id = product_upcs.product_id
            INNER JOIN
              supplier_items ON supplier_items.product_id = products.id AND supplier_items.id = supplier_order_products.supplier_item_id
            WHERE
              product_upcs.primary IS TRUE
              AND product_upcs.enabled IS TRUE
        )
      `,
    },
    tableDisplay: false,
    sortable: true,
    filterable: false,
  },
  rackType: {
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: (sop: SupplierOrderProduct) => `
        (
            SELECT
              supplier_order_product_groups.identifier
            FROM
              supplier_order_product_groups
            WHERE
              supplier_order_product_groups.id = supplier_order_products.supplier_order_product_group_id
        )
      `,
    },
    tableDisplay: false,
    sortable: true,
    filterable: false,
  },
});

setSchemaOptions(SupplierOrderProduct, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'product', foreignColumn: 'identifier' },
  uniqueConstraints: SupplierOrderProduct.UniqueConstraints,
});
