import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { DailySalesReportUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import { Thunker } from 'client/types/redux-types';
import * as Queries from './query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { SalesReportProductDetailLevel, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerId: number | undefined;
  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
  sellDepartmentId: number | undefined;
  scanBased: boolean;
  poBased: boolean;
  productClassIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  productIds: SelectableValue | undefined;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
  mfcAreaIds: SelectableValue | undefined;
  regionIds: SelectableValue | undefined;
  marketIds: SelectableValue | undefined;
  storeIds: SelectableValue | undefined;
  productDetailLevel: SalesReportProductDetailLevel | undefined;
  reportDownloadStatus: MutationStatus;
  downloadButtonDisabled: boolean;
  storeBreakdownDownloadButtonDisabled: boolean;
  scanProductOptions: boolean[];
  emailFieldsDisabled: boolean;
  includeGrossMargin: boolean;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const downloadButtonDisabled = Selectors.shouldDownloadBeDisabled(state);
  const storeBreakdownDownloadButtonDisabled = Selectors.shouldStoreBreakdownDownloadBeDisabled(state);

  if (!formValues) {
    return {
      customerId: undefined,
      dateRangeYearComparison: undefined,
      sellDepartmentId: undefined,
      scanBased: false,
      poBased: false,
      productClassIds: undefined,
      productSubClassIds: undefined,
      productIds: undefined,
      primaryGlobalAll: undefined,
      mfcAreaIds: undefined,
      regionIds: undefined,
      marketIds: undefined,
      storeIds: undefined,
      productDetailLevel: undefined,
      reportDownloadStatus: MutationStatus.Initial,
      downloadButtonDisabled,
      storeBreakdownDownloadButtonDisabled,
      scanProductOptions: [],
      emailFieldsDisabled: false,
      includeGrossMargin: false,
    };
  }
  const scanProductOptions = Selectors.getScanProductOptions(state);
  const emailFieldsDisabled = !formValues[Constants.FormFields.emailReportDaily];

  return {
    customerId: formValues[Constants.FormFields.customerId],
    dateRangeYearComparison: formValues[Constants.FormFields.dateRangeYearComparison],
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    scanBased: formValues[Constants.FormFields.scanBased],
    poBased: formValues[Constants.FormFields.poBased],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    productIds: formValues[Constants.FormFields.productIds],
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
    mfcAreaIds: formValues[Constants.FormFields.mfcAreaIds],
    regionIds: formValues[Constants.FormFields.regionIds],
    marketIds: formValues[Constants.FormFields.marketIds],
    storeIds: formValues[Constants.FormFields.storeIds],
    productDetailLevel: formValues[Constants.FormFields.productDetailLevel],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    downloadButtonDisabled,
    storeBreakdownDownloadButtonDisabled,
    scanProductOptions,
    emailFieldsDisabled,
    includeGrossMargin: formValues[Constants.FormFields.includeGrossMargin],
  };
};

interface DispatchProps {
  handleDownloadDailySalesReportClicked(): Thunker;
  handleDownloadStoreBreakdownReportClicked(): Thunker;
  handleDateRangesChanged: (state: DateRangeYearComparisonValue | undefined) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadDailySalesReportClicked: Actions.handleDownloadDailySalesReportClicked,
  handleDownloadStoreBreakdownReportClicked: Actions.handleDownloadStoreBreakdownReportClicked,
  handleDateRangesChanged: Actions.handleDateRangesChanged,
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  Queries.MfcAreasQueryProps &
  SharedQueries.RegionQueryProps &
  SharedQueries.MarketQueryProps &
  SharedQueries.StoreQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: { },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts(),
  Queries.withMfcAreas,
  SharedQueries.withRegions,
  SharedQueries.withMarkets,
  SharedQueries.withStores,
  UserParamsMappers.withDailySalesReportReportUserParamMappers,
)(DailySalesReportUI) as Component<OwnProps>;

export const DailySalesReport = (props: OwnProps) => propToComponent(component, props);
