import * as React from 'react';
import { FieldWrapper } from 'client/components/form';
import { FormGroup } from 'client/components/third-party';

interface OwnProps {
  showRacksReturned: boolean;
}

export const BolForm: React.SFC<OwnProps> = props => {
  return (
    <div>
      <FormGroup>
        <FieldWrapper
          table="bols"
          name="identifier"
          inputColSize={3}
          horizontalLabel={false}
          required={true}
          autoFocus
        />
        <FieldWrapper
          table="bols"
          name="receivedAt"
          inputColSize={9}
          horizontalLabel={false}
          disabled={true}
          required={true}
        />
      </FormGroup>

      <FormGroup>
        <FieldWrapper
          table="bols"
          name="user"
          inputColSize={props.showRacksReturned ? 9 : 12}
          horizontalLabel={false}
          disabled={true}
          required={true}
        />
        {props.showRacksReturned && <FieldWrapper
          table="bols"
          name="racksReturned"
          inputColSize={3}
          horizontalLabel={false}
        />}
      </FormGroup>

      <FormGroup>
        <FieldWrapper
          table="bols"
          name="notes"
          inputColSize={12}
          horizontalLabel={false}
        />
      </FormGroup>
    </div>
  );
};
