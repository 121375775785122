import gql from 'graphql-tag';
import { DateTimeStr } from 'shared/types';

export interface SendEmailResponse {
  response: {
    invoice: {
      id: number,
      emailedAt: DateTimeStr | null;
      emailedByUserId: number | null;
      emailedByUser: string | null;
    },
  };
}

export interface SendEmailInput {
  invoiceId: number;
  emails: string[];
}

export const Mutation = gql`
mutation EmailInvoiceMutation($invoiceId: Int!, $emails: [String!]!) {
  response: EmailInvoice(invoiceId: $invoiceId, emails: $emails) {
    invoice {
      id
      emailedAt
      emailedByUserId
      emailedByUser
    }
    customerOrder {
      id
      invoiced
      invoiceEmailed
      orderStatus
    }
  }
}
`;
