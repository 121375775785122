import * as React from 'react';
import { RecordNavigationBar } from 'client/app/transportation/receiving/details/receiving-details-navigation-bar/record-navigation-bar';
import { RecordNavItem } from 'client/components/record-nav';

interface OwnProps {
  receivableOrderId: number;
}

export const ReceivingDetailsNavigationBar: React.SFC<OwnProps> = props => {
  const baseUrl = `/transportation/receiving/details/${props.receivableOrderId}`;

  return (
    <RecordNavigationBar>
      <RecordNavItem label="Order Details" key="details" route={`${baseUrl}/overview`} testid="navbar-order-details" />
      <RecordNavItem label="Receiving Worksheet" key="worksheet" route={`${baseUrl}/worksheet`} testid="navbar-receiving-worksheet" />
      <RecordNavItem label="Related BOLs" key="related-bols" route={`${baseUrl}/related-bols`} testid="navbar-related-bols" />
    </RecordNavigationBar>
  );
};
