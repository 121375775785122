import { Lens } from '@atomic-object/lenses';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { Carrier } from 'shared/types';

export interface CheckedLoadNumbers {
  [routingLoadId: number]: boolean;
}

interface LoadListState {
  readonly applyInvoiceToLoadsModalShown: boolean;
  readonly routingLoadIds: RoutingLoadId[];
  readonly selectedCarrier: Carrier;
  readonly enteredInvoiceNumber: string;
  readonly assignLoadsToTrailersModalShown: boolean;
  readonly assignedTrailerNumbers: {
    [routingLoadId: number]: string;
  };
  readonly notifyPeakModalShown: boolean;
  readonly checkedLoadNumbers: CheckedLoadNumbers;
}

export const InitialState: LoadListState = {
  applyInvoiceToLoadsModalShown: false,
  routingLoadIds: [],
  selectedCarrier: Carrier.PEAK,
  enteredInvoiceNumber: '',
  assignLoadsToTrailersModalShown: false,
  assignedTrailerNumbers: {},
  notifyPeakModalShown: false,
  checkedLoadNumbers: {},
};

export type Type = LoadListState;

export const applyInvoiceToLoadsModalShown = Lens.from<Type>().prop('applyInvoiceToLoadsModalShown');
export const routingLoadIds = Lens.from<Type>().prop('routingLoadIds');
export const selectedCarrier = Lens.from<Type>().prop('selectedCarrier');
export const enteredInvoiceNumber = Lens.from<Type>().prop('enteredInvoiceNumber');
export const assignLoadsToTrailersModalShown = Lens.from<Type>().prop('assignLoadsToTrailersModalShown');
export const assignedTrailerNumbers = Lens.from<Type>().prop('assignedTrailerNumbers');
export const checkedLoadNumbers = Lens.from<Type>().prop('checkedLoadNumbers');
export const notifyPeakModalShown = Lens.from<Type>().prop('notifyPeakModalShown');
