import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { flowRight } from 'lodash';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { ProductList as ProductListCore } from './product-list';
import { PropsOf } from 'client/lib/react';
import * as Actions from 'client/actions/product-worksheet';
import { ProductShipmentConfiguration } from 'client/types/product-worksheet';
import { ComboCart, OrderMethod } from 'shared/types';
import { determineProductListButtonsVisibility, getCheckedCustomerOrderProductGroupIds } from 'client/helpers/product-worksheet';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { ProductListResponseItem } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-container';
import { ApolloRefetch } from 'client/types';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import assertCompatible from 'shared/helpers/assert-compatible';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { InitialState } from 'client/state/state';

type ComponentProps = PropsOf<typeof ProductListCore>;
export interface OwnProps {
  client: ApolloClient<NormalizedCacheObject>,
  customerOrderId: number,
  customerOrderDetails?: { orderMethod: OrderMethod },
  products: ProductShipmentConfiguration[],
  comboCarts: ComboCart[],
  productListResponseItems: ProductListResponseItem[],
  selectedProduct: any,
  refreshProductsList: ApolloRefetch,
  worksheetStatsRefetch: ApolloRefetch,
  salesPlanIdentifier: string | undefined,
  loading: boolean,
}

export interface StateProps {
  checkedProductIds: Immutable.Set<number>,
  checkedComboCartIds: Immutable.Set<number>,
  showAddFromSalesPlanButton: boolean,
  showAddProductButton: boolean,
  showNewRackTypeButton: boolean,
  showRemoveButton: boolean,
  showCreateComboCartButton: boolean,
  removeCustomerOrderProductGroupIds: CustomerOrderProductGroupId[],
}

export interface DispatchProps {
  handleProductClicked: (productId: number) => void,
  handleProductChecked: (productId: number) => void,
  handleComboCartChecked: (comboCartId: number) => void,
  handleAddNewProductButtonClicked: () => void,
  handleAddNewRackTypeButtonClicked: () => void,
  handleRemoveProductButtonClicked: () => void,
  handlePencilClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void,
  handleTrashCanClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void,
  handleComboCartPencilClicked: (CustomerOrderProductGroupId: CustomerOrderProductGroupId) => void,
  handleRemoveButtonClicked(): void,
}

export const mapStateToProps = (state: typeof InitialState): StateProps => ({
  checkedProductIds: state.productWorksheet.checkedProductIds,
  checkedComboCartIds: state.productWorksheet.checkedComboCartIds,
  removeCustomerOrderProductGroupIds: state.productWorksheet.removeCustomerOrderProductGroupIds ? state.productWorksheet.removeCustomerOrderProductGroupIds.toJS() : [],
  ...determineProductListButtonsVisibility(state.productWorksheet.checkedProductIds.toJS(), state.productWorksheet.checkedComboCartIds.toJS()),
});

export const mapDispatchToProps = (dispatch: any, ownProps: OwnProps & StateProps & WithMutationProps): DispatchProps => {
  const deleteFunction = async (customerOrderProductGroupIds: CustomerOrderProductGroupId[] ) => {
    try {
      if (await ownProps.removeCustomerOrderProductGroups(customerOrderProductGroupIds, true)) {
        await ownProps.refreshProductsList();
        await ownProps.worksheetStatsRefetch();
        const checkedProductIdsToUncheck = ownProps.products.filter(product => {
          return ownProps.checkedProductIds.includes(product.productId)
            && product.shipmentConfigurations.length === 1
            && customerOrderProductGroupIds.includes(product.shipmentConfigurations[0].customerOrderProductGroupId);
        }).map(product => product.productId);
        dispatch(Actions.removeModalConfirmed({ customerOrderProductGroupIds, checkedProductIdsToUncheck }));
      }
    } catch (error) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem removing customer order product group', error.message);
      return;
    }
  };

  return {
    handleProductClicked: (productId: number) => dispatch(Actions.productClicked(ownProps.customerOrderId, productId)),
    handleProductChecked: (productId: number) => dispatch(Actions.productChecked(productId)),
    handleComboCartChecked: (comboCartId: number) => dispatch(Actions.comboCartChecked(comboCartId)),
    handleAddNewProductButtonClicked: () => dispatch(Actions.addNewProductButtonClicked()),
    handleAddNewRackTypeButtonClicked: () => dispatch(Actions.addNewRackTypeButtonClicked()),
    handleRemoveProductButtonClicked() {
      const customerOrderProductGroupIds = getCheckedCustomerOrderProductGroupIds({
        checkedComboCartIds: ownProps.checkedComboCartIds,
        checkedProductIds: ownProps.checkedProductIds,
        productListResponseItems: ownProps.productListResponseItems,
      });

      dispatch(Actions.removedFromProductList(customerOrderProductGroupIds, deleteFunction));
    },

    handlePencilClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => dispatch(Actions.editRackTypeButtonClicked(customerOrderProductGroupId)),
    handleTrashCanClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => dispatch(Actions.removedFromProductList([customerOrderProductGroupId], deleteFunction)),
    handleComboCartPencilClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => dispatch(Actions.editComboCartButtonClicked(customerOrderProductGroupId)),
    handleRemoveButtonClicked: () => deleteFunction(ownProps.removeCustomerOrderProductGroupIds),
  };
};

export interface WithMutationProps {
  removeCustomerOrderProductGroups(customerOrderProductGroupIds: CustomerOrderProductGroupId[], forceDelete: boolean): Promise<CustomerOrderProductGroupId[]>;
}

const withRemoveCustomerOrderProductGroupsMutation = msyncMutation(gql`mutation RemoveCustomerOrderProductGroups($ids: [Int]!) { delete(type: CustomerOrderProductGroup, ids: $ids) }`, {
  props: ({ mutate }) => ({
    removeCustomerOrderProductGroups: (customerOrderProductGroupIds: number[]) => mutate({
      variables: { ids: customerOrderProductGroupIds },
      refetchQueries: ['findAllCustomerOrderProductGroupsForProductWorksheet'],
    }),
  }),
});

assertCompatible<ComponentProps, StateProps & DispatchProps & OwnProps & WithMutationProps>();

export const ProductList = flowRight(
  withApollo,
  withRemoveCustomerOrderProductGroupsMutation,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps),
  connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(ProductListCore);
