import * as React from 'react';
import * as _ from 'lodash';
import { createSelector } from 'reselect';

// export interface OwnProps {
//   isInModal?: boolean;
//   children?: React.ReactElement<any>;
// }

interface TableParentState {
  rowHeight?: number;
  rowsPerPage?: number;
  containerHeight?: number;
  containerWidth?: number;
  bodyHeight?: number;
  bodyWidth?: number;
  adjustTableDimensions(): void;
  overlay?: React.CSSProperties | undefined;
}

export type TableParentInfo = TableParentState;

export function tableParentHoc(args?: { skip?: (props: any) => boolean, isInModal?: boolean }) {
  const isInModal = args?.isInModal;

  interface StyleInput {
    boundingRectangle: ClientRect | DOMRect;
    modalBoundingRectangle: ClientRect | undefined;
    windowScrollX: number;
    windowScrollY: number;
  }
  const calculateStyles = createSelector([
    (obj: StyleInput) => obj.boundingRectangle.height,
    (obj: StyleInput) => obj.boundingRectangle.left,
    (obj: StyleInput) => obj.boundingRectangle.top,
    (obj: StyleInput) => obj.boundingRectangle.width,
    (obj: StyleInput) => obj.modalBoundingRectangle ? obj.modalBoundingRectangle.left : undefined,
    (obj: StyleInput) => obj.modalBoundingRectangle ? obj.modalBoundingRectangle.top : undefined,
    (obj: StyleInput) => obj.windowScrollX,
    (obj: StyleInput) => obj.windowScrollY,
  ], (
      boundingRectangleHeight,
      boundingRectangleLeft,
      boundingRectangleTop,
      boundingRectangleWidth,
      modalBoundingRectangleLeft,
      modalBoundingRectangleTop,
      windowScrollX,
      windowScrollY,
    ) => {
      const leftAdjustment = modalBoundingRectangleLeft ? -modalBoundingRectangleLeft : 0;
      const topAdjustment = modalBoundingRectangleTop ? -modalBoundingRectangleTop : 0;

      return {
        height: boundingRectangleHeight,
        left: windowScrollX + boundingRectangleLeft + leftAdjustment,
        margin: 0,
        position: 'fixed' as any, // avoid whining about type
        top: windowScrollY + boundingRectangleTop + topAdjustment,
        width: boundingRectangleWidth,
        zIndex: 999,
      };
  });

  const getOverlayStyles = (element: Element) => {
    const modalElement = isInModal ? document.querySelector('.modal-content') : undefined;
    const boundingRectangle = element.getBoundingClientRect();
    const modalBoundingRectangle = modalElement ? modalElement.getBoundingClientRect() : undefined;

    return calculateStyles({
      boundingRectangle,
      modalBoundingRectangle,
      windowScrollX: window.scrollX,
      windowScrollY: window.scrollY,
    });
  };

  return <P extends {tableParentInfo: TableParentState}>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Exclude<P, {tableParentInfo: TableParentState}>> => {

    const skipFn = args?.skip ?? (() => false);

    return class TableParent extends React.Component<Exclude<P, {tableParentInfo: TableParentState}>, TableParentState> {
      private node?: HTMLElement | null;
      private componentIsMounted: boolean = false;
      private handleWindowResizeDebounce: () => void;
      constructor(props: Exclude<P, {tableParentInfo: TableParentState}>) {
        super(props);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        const debouncedFunc = _.debounce(() => { this.handleWindowResize(); }, 100);
        this.handleWindowResizeDebounce = debouncedFunc.bind(this);
        this.state = {
          rowHeight: undefined,
          rowsPerPage: undefined,
          containerHeight: undefined,
          containerWidth: undefined,
          bodyHeight: undefined,
          bodyWidth: undefined,
          adjustTableDimensions: this.handleWindowResize,
        };
      }

      componentDidMount() {
        this.componentIsMounted = true;
        window.addEventListener('resize', this.handleWindowResizeDebounce);
        requestAnimationFrame(() => { this.handleWindowResize(); });
      }

      componentWillUnmount() {
        this.componentIsMounted = false;
        window.removeEventListener('resize', this.handleWindowResizeDebounce);
      }

      componentDidUpdate() {
        this.handleWindowResizeDebounce();
      }

      public render() {
        if (skipFn(this.props)) {
          return <div />;
        }

        const props = { ...this.props, tableParentInfo: this.state } as P;
        return (
          <div className="mfc-table-parent" ref={node => (this.node = node)}>
            <WrappedComponent {...props}/>
          </div>
        );
      }

      public handleWindowResize() {
        if (!this.componentIsMounted) {
          // console.debug({source_file: 'table-parent.tsx', componentIsMounted: this.componentIsMounted, tableParentInfo: this.state});
          return;
        }

        if (this.node) {
          const tableContainer = this.node.querySelector('.ReactTable');
          const table = this.node.querySelector('.rt-table');
          const tableHeaderRow = this.node.querySelector('.rt-thead');
          const tableBody = this.node.querySelector('.rt-tbody');
          const tableBodyRow = this.node.querySelectorAll('.rt-tr-group').item(0);
          const tablePaginator = this.node.querySelector('.table-paginator');
          const tableFooterRow = this.node.querySelector('.rt-tfoot');
          const root = document.querySelector('#root');

          const tableFooterRowCountAdjustment = tableFooterRow ? 1 : 0;

          // If the filterable table hasn't been rendered yet try again
          if (!tableContainer && !this.node.querySelector('.table-empty')) {
            return;
          }

          if (tableContainer && tableHeaderRow && tableBody && root && table) {
            let tableWidth = tableContainer.clientWidth;

            if (tableWidth === 0 && this.state.containerWidth) {
              tableWidth = this.state.containerWidth;
            }

            let tableBodyWidth = tableBody.clientWidth;
            if (tableBodyWidth === 0 && this.state.bodyWidth) {
              tableBodyWidth = this.state.bodyWidth;
            }

            const additionalBottomMargin = isInModal ? 75 : 0; // Need a bit more room at the bottom for the modal to look good (when the table is in a modal)
            const tablePaginatorHeight = tablePaginator ? tablePaginator.getBoundingClientRect().height : 0; // pagination height

            const tableBottomMargin = 10;

            const tableBodyTop = tableBody.getBoundingClientRect().top; // DomUtils.cumulativeOffset(tableBody).top;

            if (tableBodyTop === 0) {
              // Not sure why this happens, but I think it might be when the table gets display: none. Don't want to
              // change the dimensions in this case so just stop at this point.
              return;
            }

            const tableBodyHeight = window.innerHeight - tableBodyTop - tablePaginatorHeight - tableBottomMargin - additionalBottomMargin;

            let tableBodyRowHeight = tableBodyRow ? this.getOutsideHeight(tableBodyRow) : 0;
            if (!tableBodyRow || tableBodyRowHeight < 5) {  // Saw tiny rows a couple of times, so treat that like we don't know what the height should be
              if (this.state.rowHeight) {
                // Use whatever was determined previously
                tableBodyRowHeight = this.state.rowHeight;
              } else {
                // The row height is 0, bail until we have a real row height
                return;
              }
            }

            const tableStyles = window.getComputedStyle(table);
            const tableBorderTopWidth = tableStyles?.borderTopWidth ? Number.parseInt(tableStyles.borderTopWidth) : 0;

            const tableContainerStyles = window.getComputedStyle(tableContainer);
            const tableContainerBorderBottomWidth = tableContainerStyles?.borderBottomWidth ? Number.parseInt(tableContainerStyles.borderBottomWidth) : 0;

            if (tableBodyRowHeight > 0) {
              const tableBodyRowsPerPage = Math.max(Math.floor((tableBodyHeight) * 1.0 / tableBodyRowHeight), 1) - tableFooterRowCountAdjustment;

              // readjust the height of the table body to so that the rows fit exactly
              // subtract one pixel to handle the table paginator border/bottom row
              // border doubling.
              const adjustedTableBodyHeight = (tableBodyRowsPerPage * tableBodyRowHeight) -
                (_.isNil(tablePaginator) ? tableContainerBorderBottomWidth : 0);

              const tableHeight = tableBorderTopWidth +
                this.getOutsideHeight(tableHeaderRow) +
                adjustedTableBodyHeight +
                (tableFooterRowCountAdjustment * tableBodyRowHeight) +
                (tableFooterRowCountAdjustment > 0 ? (tablePaginator ? 2 : 0) : 0);

              const stateUpdates: Partial<TableParentState> = {};

              const overlayStyles = getOverlayStyles(tableBody);
              if (overlayStyles !== this.state.overlay) {
                stateUpdates.overlay = overlayStyles;
              }

              if (tableBodyRowHeight !== 0 && tableBodyRowHeight !== this.state.rowHeight) {
                stateUpdates.rowHeight = tableBodyRowHeight;
              }

              if (tableBodyRowsPerPage !== this.state.rowsPerPage) {
                stateUpdates.rowsPerPage = tableBodyRowsPerPage;
              }

              if (adjustedTableBodyHeight !== 0 && adjustedTableBodyHeight !== this.state.bodyHeight) {
                stateUpdates.bodyHeight = adjustedTableBodyHeight;
              }

              if (tableWidth !== 0 && tableWidth !== this.state.containerWidth) {
                stateUpdates.containerWidth = tableWidth;
              }

              if (tableBodyWidth !== 0 && tableBodyWidth !== this.state.bodyWidth) {
                stateUpdates.bodyWidth = tableBodyWidth;
              }

              if (tableHeight !== 0 && tableHeight !== this.state.containerHeight) {
                stateUpdates.containerHeight = tableHeight;
              }

              if (!_.isEmpty(stateUpdates) && this.componentIsMounted) {
                // console.debug({source_file: 'table-parent.tsx', componentIsMounted: this.componentIsMounted, tableParentInfo: this.state});
                this.setState(stateUpdates as TableParentState);
              }
            }
          }
        }
      }

      private getOutsideHeight(element: Element) {
        const clientHeight = element.clientHeight;
        const styles = window.getComputedStyle(element);

        if (!styles) {
          return clientHeight;
        }

        const borderTopWidth = styles.borderTopWidth ? Math.ceil(Number.parseFloat(styles.borderTopWidth)) : 0;
        const borderBottomWidth = styles.borderBottomWidth ? Math.ceil(Number.parseFloat(styles.borderBottomWidth)) : 0;
        const marginTopWidth = styles.marginTop ? Math.ceil(Number.parseFloat(styles.marginTop)) : 0;
        const marginBottomWidth = styles.marginBottom ? Math.ceil(Number.parseFloat(styles.marginBottom)) : 0;

        return marginTopWidth + borderTopWidth + clientHeight + borderBottomWidth + marginBottomWidth;
      }
    };
  };
}
