import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as CreateVendorMutation from './mutation';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { VendorFormValues } from '../vendor-form';

const createVendor = async (dispatch: Dispatch<any>, formValues: VendorFormValues): Promise<number> => {
  const contacts = formValues.contacts ? formValues.contacts.map(contact => {
    return {
      id: contact.id,
      name: contact.name,
      email: contact.email,
      phoneOffice: contact.phoneOffice,
      phoneCell: contact.phoneCell,
      primary: contact.primary,
    };
  }) : [];
  const input: CreateVendorMutation.Input = {
    newVendorValues: {
      identifier: formValues.identifier,
      name: formValues.name,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      country: formValues.country,
      phone: formValues.phone,
      fax: formValues.fax,
      contacts,
    },
  };
  const result = await msyncClientMutation<CreateVendorMutation.Response>({
    mutation: CreateVendorMutation.Mutation,
    variables: input,
    dispatch,
  });
  return result.data.createVendor.vendor.id;
};

export const formSubmitted = (formValues: VendorFormValues): Thunker =>
  async dispatch => {
    const newVendorId = await createVendor(dispatch, formValues);
    dispatch(push(`/admin/vendors/details/${newVendorId}`));
  };
