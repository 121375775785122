import * as _ from 'lodash';
import { getFormValues } from 'redux-form';

import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const downloadDisabledDueToCustomerSheet = !!formValues[Constants.FormFields.includeCustomerSheet]
    ? (_.isNil(formValues[Constants.FormFields.beginDate]) || _.isNil(formValues[Constants.FormFields.endDate]))
    : false;

  const downloadDisabledDueToSupplierSheet = !!formValues[Constants.FormFields.includeSupplierSheet]
    ? (_.isNil(formValues[Constants.FormFields.mfcArrivalBeginDate]) || _.isNil(formValues[Constants.FormFields.mfcArrivalEndDate]))
    : false;

  const downloadDisabledBecauseNeitherSheetSelected = !(formValues[Constants.FormFields.includeCustomerSheet] || formValues[Constants.FormFields.includeSupplierSheet]);

  return _.isEmpty(extractSelectedValues(formValues[Constants.FormFields.customerIds]))
    || downloadDisabledDueToCustomerSheet
    || downloadDisabledDueToSupplierSheet
    || downloadDisabledBecauseNeitherSheetSelected;
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
