import * as State from 'client/state/route-plan-printing';
import { ActionTypes, ActionTypeKeys } from 'client/actions/route-plan-printing';
import { MutationStatus } from 'client/actions/mutations';
import { flow } from 'lodash';

type RoutePlanPrintingState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): RoutePlanPrintingState {
  switch (action.type) {
    case ActionTypeKeys.ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY: {
      return State.createRoutePacketsModalShown.set(state, action.payload.visible);
    }
    case ActionTypeKeys.ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED: {
      return State.InitialState;
    }
    case ActionTypeKeys.ROUTE_SHEETS_CLICKED: {
      return flow(
        State.routeSheetsChecked.set(!state.routeSheetsChecked),
        State.returnInstructionsChecked.set(!state.routeSheetsChecked), // return instructions is aligned with route sheets
        State.specialInstructionsChecked.set(false), // always turn off the special instructions if you are toggling the route sheets
      )(state);
    }
    case ActionTypeKeys.DELIVERY_ACKNOWLEDGEMENTS_CLICKED: {
      return State.deliveryAcknowledgementsChecked.set(state, !state.deliveryAcknowledgementsChecked);
    }
    case ActionTypeKeys.PURCHASE_ORDER_INVOICES_CLICKED: {
      return State.purchaseOrderInvoicesChecked.set(state, !state.purchaseOrderInvoicesChecked);
    }
    case ActionTypeKeys.PURCHASE_ORDER_INVOICES_CHANGED: {
      return State.purchaseOrderInvoicesChecked.set(state, action.payload.selected);
    }
    case ActionTypeKeys.MARK_ORDERS_AS_SHIPPED_CLICKED: {
      return State.markOrdersAsShippedChecked.set(state, !state.markOrdersAsShippedChecked);
    }
    case ActionTypeKeys.RETURN_INSTRUCTIONS_CLICKED: {
      return State.returnInstructionsChecked.set(state, !state.returnInstructionsChecked);
    }
    case ActionTypeKeys.SPECIAL_INSTRUCTIONS_CLICKED: {
      return State.specialInstructionsChecked.set(state, !state.specialInstructionsChecked);
    }
    case ActionTypeKeys.ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY: {
      // TODO: redux is sooooooo clumsy. GQL is not implemented right in msync either.  whe I decide it's worth it to make this work in redux land, I'll do it.  but likely, I'll just replace redux.
      // const intermediateState = State.selectedLoadConfiguration.set(state,
      //  await thingyToGetWhetherMostlyOnPallets(action.payload.routePlanId) ? load by rows (2 pallets per row) : load by columns (3 carts per row));
      // return State.downloadDittyCardModalShown.set(intermediateState, action.payload.visible);
      return State.downloadDittyCardModalShown.set(state, action.payload.visible);
    }
    case ActionTypeKeys.ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY: {
      if (!action.payload.visible) {
        return State.InitialState;   // clears out the search field, select all, etc.
      }
      return State.emailMerchandisersModalShown.set(state, action.payload.visible);
    }
    case ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CLOSED: {
      return State.InitialState;
    }
    case ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CREATE_CLICKED: {
      return State.InitialState;
    }
    case ActionTypeKeys.LOAD_CONFIGURATION_CHANGED: {
      return State.selectedLoadConfiguration.set(state, action.payload.loadConfiguration);
    }

    case ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_STARTED: {
      return State.sendLoadsToLoadMasterActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_COMPLETED: {
      return State.sendLoadsToLoadMasterActionStatus.set(state, MutationStatus.Complete);
    }

    case ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_FAILED:
    case ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_DONE_DONE: {
      return State.sendLoadsToLoadMasterActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_STARTED: {
      return State.emailRouteSheetsToPeakActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_COMPLETED: {
      return State.emailRouteSheetsToPeakActionStatus.set(state, MutationStatus.Complete);
    }

    case ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_FAILED:
    case ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_DONE_DONE: {
      return State.emailRouteSheetsToPeakActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.SELECTED_ROUTING_LOAD_IDS_CHANGED: {
      return State.selectedRoutingLoadIds.set(state, action.payload.routingLoadIds);
    }
    case ActionTypeKeys.DOWNLOAD_DITTY_CARD_MENU_ITEM_CLICKED: {
      return State.selectedRoutingLoadIds.set(state, action.payload.routingLoadIds);
    }
    case ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_STARTED: {
      return State.downloadPalletReportActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_DONE: {
      return State.downloadPalletReportActionStatus.set(state, MutationStatus.Complete);
    }

    case ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_COMPLETED:
    case ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_FAILED: {
      return State.downloadPalletReportActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.ROUTE_PLAN_SET_MERCHANDISERS_WITH_STORES: {
      return State.merchandisersWithStores.set(state,
        action.payload.merchandisersWithStores.map(mws => ({ ...mws, checked: false })));
    }

    case ActionTypeKeys.SELECTED_MERCHANDISERS_CHANGED: {
      const newMerchandisersWithStores = state.merchandisersWithStores.map(mws => {
        return {
          ...mws,
          checked: mws.merchandiserId === action.payload.merchandiserId ? !mws.checked : mws.checked,
        };
      });
      return State.merchandisersWithStores.set(state, newMerchandisersWithStores);
    }

    case ActionTypeKeys.MERCHANDISERS_SELECT_ALL_CLICKED: {
      return State.isMerchandisersSelectAllChecked.update(state, currentValue => !currentValue);
    }

    case ActionTypeKeys.MERCHANDISERS_SEARCHED: {
      return State.merchandiserSearchText.set(state, action.payload.searchText);
    }

    case ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_STARTED: {
      return State.createCustomerAdvanceShipNoticeActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_COMPLETE: {
      return State.createCustomerAdvanceShipNoticeActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_STARTED: {
      return State.downloadStoreDeliverySpreadsheetActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_COMPLETE: {
      return State.downloadStoreDeliverySpreadsheetActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_STARTED: {
      return State.createCustomerAdvanceShipNoticeActionStatus.set(state, MutationStatus.InProgress);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_COMPLETED: {
      return State.InitialState;
    }
    case ActionTypeKeys.DOWNLOAD_STORE_ASN_FAILED: {
      return State.downloadStoreAdvanceShipNoticeActionStatus.set(state, MutationStatus.Initial);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_BUTTON_CLICKED: {
      return State.downloadStoreAsnModalShown.set(state, true);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_CANCEL_BUTTON_CLICKED: {
      return State.InitialState;   // clears out the search field, select all, etc.
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_SET_STORES_WITH_MERCHANDISERS: {
      return State.storesWithMerchandisers.set(state, action.payload.storesWithMerchandisers);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_STORE_CHANGED: {
      const newStoresWithMerchandisers = state.storesWithMerchandisers.map(swm => {
        return {
          ...swm,
          checked: swm.storeId === action.payload.storeId ? !swm.checked : swm.checked,
        };
      });
      return State.storesWithMerchandisers.set(state, newStoresWithMerchandisers);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_SELECT_ALL_CLICKED: {
      return State.isStoresSelectAllChecked.update(state, currentValue => !currentValue);
    }

    case ActionTypeKeys.DOWNLOAD_STORE_ASN_SEARCHED: {
      return State.storeSearchText.set(state, action.payload.searchText);
    }

    default:
      return state;
  }
}
