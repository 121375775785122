import * as React        from 'react';
import { RecordNavItem } from 'client/components/record-nav';
import { SupplierOrderId } from 'shared/schemas/supplier-order';

export default function buildRecordNavItems(supplierOrderId?: SupplierOrderId) {
  const baseCreateUrl = '/orders/supplier/create';
  const baseDetailUrl = `/orders/supplier/details/${supplierOrderId}`;
  const supplierOrderOverviewTab = supplierOrderId
    ? <RecordNavItem label="Overview" key="overview" route={`${baseDetailUrl}/overview`}/>
    : <RecordNavItem label="Overview" key="overview" route={`${baseCreateUrl}`}/>;

  const additionalTabs = [
    <RecordNavItem label="Related Customer POs" key="related-customer-orders" route={`${baseDetailUrl}/related-customer-orders`} />,
  ];

  return [
    supplierOrderOverviewTab,
    ...additionalTabs,
  ];
}
