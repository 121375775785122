import * as React from 'react';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import RoutePlanDetails from './route-plan-details';
import { TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME } from 'client/constants';
import * as AttachedCustomerOrdersTableHoc from 'client/app/transportation/routing/attached-customer-orders-table/attached-customer-orders-table-hoc';
import { Button } from 'client/components/third-party';
import { AttachedCustomerOrdersTableAndModal } from 'client/app/transportation/routing/attached-customer-orders-table/attached-customer-orders-table-and-modal';
import { withRoutePlanIdFromParams } from './with-route-plan-id-from-params';
import { RoutePlanSectionNames } from '../route-plan-section-names';
import { RouteChildrenProps } from 'react-router';

type OwnProps = RouteChildrenProps<{ id?: string }>;

interface WithRoutePlanIdProps {
  routePlanId?: RoutePlanId;
}

export const AttachedCustomerOrdersTable = AttachedCustomerOrdersTableHoc.buildCustomerOrdersTable({ component: AttachedCustomerOrdersTableAndModal, isInModal: false, alias: 'attached' });

const RoutePlanAttachOrders: React.SFC<OwnProps & WithRoutePlanIdProps> = props => {
  return (
    <RoutePlanDetails routePlanId={props.routePlanId} sectionName={RoutePlanSectionNames.AttachOrders}>
      {props.routePlanId &&
        <AttachedCustomerOrdersTable
          routePlanId={props.routePlanId}
          tableName={TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME}
          type="Attached"
        />}
      {!props.routePlanId &&
        <div className="table-placeholder">
          <h2 className="message">There are currently no Customer Orders in this Route Plan</h2>
          <Button bsStyle="primary"
            bsClass="mfc-button mfc-new-record-button"
            data-testid="empty-state-attach-orders-button"
            disabled>
            Attach Orders
          </Button>
        </div>
      }
    </RoutePlanDetails>
  );
};

const component = flowRight(
  withRoutePlanIdFromParams,
)(RoutePlanAttachOrders);

export default (props: OwnProps) => propToComponent(component, props);
