import * as React from 'react';
import { Field } from 'redux-form';
import { Tabs } from 'client/components/third-party';

interface ReduxFormFieldProps {
  id?: string;
  testid?: string;
  proppedChildren?: React.ReactNode | React.ReactNode[];
  input: {
    name: string;
    onChange: (x: any) => any;
    value: any;
  };
}

const tabFunc = (field: ReduxFormFieldProps) => {
  return (
    <Tabs
      id={field.id}
      data-testid={field.testid}
      activeKey={field.input.value}
      onSelect={field.input.onChange}>
      {field.proppedChildren}
    </Tabs>
  );
};

interface FormTabsProps {
  id?: string;
  testid?: string;
  name: string;
  children?: React.ReactNode | React.ReactNode[];
}

export default class FormTabs extends React.PureComponent<FormTabsProps, {}> {
  render() {
    return (
      <Field
        id={this.props.id}
        testid={this.props.testid}
        name={this.props.name}
        component={tabFunc}
        proppedChildren={this.props.children}
      />
    );
  }
}
