import * as BS from 'react-bootstrap';
import * as ReduxForm from 'redux-form';

// I wish I understood this better, but I don't. So this is the best I can come
// up with right now. We're using the Bootstrap.Form element in our forms (at least
// in some of them). We're using that element with reduxForm. The type signatures of
// the onSubmit property is different for Boostrap.Form and ReduxForm.Form - and
// even though we're using the Bootstrap.Form in the JSX, the callback gets called
// with the arguments for a ReduxForm.Form.
//
// So the following nonsense is trying to get TypeScript to be happy about us passing
// a ReduxForm.Form friendly callback to a Bootstrap.Form element.
type FormPropsWithoutOnSubmit = Omit<BS.FormProps, 'onSubmit'>;
export type Form = React.ComponentClass<FormPropsWithoutOnSubmit & { onSubmit?: ReduxForm.ConfigProps<any, any>['onSubmit'] }>;
export const Form = BS.Form as Form; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export const Col = BS.Col;
export const Row = BS.Row;
export const FormGroup = BS.FormGroup;
export const Button = BS.Button;
export const ButtonGroup = BS.ButtonGroup;
export const OverlayTrigger = BS.OverlayTrigger;
export const Tooltip = BS.Tooltip;
export const ProgressBar = BS.ProgressBar;
export const Popover = BS.Popover;
export const Nav = BS.Nav;
export const NavItem = BS.NavItem;
export const ListGroupItem = BS.ListGroupItem;
export const ListGroup = BS.ListGroup;
export const Alert = BS.Alert;
export const HelpBlock = BS.HelpBlock;
export const Tabs = BS.Tabs;
export const Tab = BS.Tab;

export const FormControl = BS.FormControl;

export const Modal = BS.Modal;

export const ControlLabel = BS.ControlLabel;
export const Dropdown = BS.Dropdown;
export const DropdownButton = BS.DropdownButton;
export const MenuItem = BS.MenuItem;
export const Glyphicon = BS.Glyphicon;
export type OverlayTriggerProps = BS.OverlayTriggerProps;
export type { BootstrapDatepicker as BSDatePicker } from '../types/datepicker';

