import { tableName, property, definePresentation, setSchemaOptions, belongsTo, required } from './dsl';
import { DISPLAY_TYPES, TYPES } from '../types';
import { IRecord } from 'shared/schemas/record';
import { displayType } from './dsl';
import { MerchandiserDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('merchandisers')
export class Merchandiser implements IRecord {
  static readonly SchemaName = 'Merchandiser';

  id?: number;
   // not sure `identifier` is needed after disconnecting vbills
  @property @required identifier: string;
  @property @required name: string;
  @property address1?: string | null;
  @property address2?: string | null;
  @property city?: string | null;
  @property state?: string | null;
  @property zip?: string | null;
  @property phone?: string | null;
  @property fax?: string | null;
  @property cell?: string | null;
  @property email?: string | null;

  @belongsTo('merchandisers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'], alias: 'coordinator', nativeTableFK: 'coordinatorId' })
  @property coordinator?: Merchandiser | null;
  coordinatorId?: number | null;

  @property isCoordinator: boolean;
  @property isSupervisor: boolean;
  @property supervisorList: string;
  @property storeList: string;
}

setSchemaOptions(Merchandiser, {
  softDeletable: false,
});

definePresentation(Merchandiser, {
  coordinator: {
    displayName: 'Coordinator',
    tableDisplayColumns: { name: 'Coordinator' },
    type: TYPES.STRING,
    sortable: true,
    filterable: true,
    tableDisplay: true,
    searchable: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: MerchandiserDisplayValueResolver }),
  },
  isCoordinator: {
    displayName: 'Is Coordinator',
    type: TYPES.BOOLEAN,
    sortable: true,
    filterable: true,
    tableDisplay: false,
    searchable: false,
    columnWidth: 0,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `(exists (select * from merchandisers m2 where merchandisers.id = m2.coordinator_id))`;
      },
    },
  },
  isSupervisor: {
    displayName: 'Is Supervisor',
    type: TYPES.BOOLEAN,
    sortable: true,
    filterable: true,
    tableDisplay: false,
    searchable: false,
    columnWidth: 0,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `(exists (select * from stores s2 where s2.merchandising_supervisor_id = merchandisers.id))`;
      },
    },
  },
  supervisorList: {
    displayName: 'Coordinator Over:',
    type: TYPES.STRING,
    sortable: false,
    filterable: false,
    tableDisplay: true,
    searchable: true,
    columnWidth: 40,
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `((select string_agg(m2.name, ', ' order by m2.name) from merchandisers m2 where m2.coordinator_id = merchandisers.id))`;
      },
    },
  },
  storeList: {
    displayName: 'Stores',
    type: TYPES.STRING,
    sortable: false,
    filterable: false,
    tableDisplay: true,
    searchable: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `((select string_agg(s2.identifier::int::text, ', ' order by s2.identifier::int) from stores s2 where s2.merchandising_supervisor_id = merchandisers.id and s2.identifier ~ '^\\d+$'))`;
      },
    },
  },
  identifier: {
    sortable: true,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  name: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    searchable: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    required: true,
  },
  address1: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  address2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  city: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  state: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  zip: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  phone: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  fax: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  cell: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  email: {
    sortable: true,
    filterable: true,
    searchable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  coordinatorId: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
});
