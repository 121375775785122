import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { ReportTypes, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';

export const formName = 'mobileSalesPerformanceReport';
export const reportType = ReportTypes.MobileSalesPerformanceReport;

export enum FormFields {
  id = 'id',
  customerId = 'customerId',

  dateRangeYearComparison = 'dateRangeYearComparison',

  sellDepartmentId = 'sellDepartmentId',
  scanBased = 'scanBased',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  productIds = 'productIds',

  primaryGlobalAll = 'primaryGlobalAll',
  mfcAreaIds = 'mfcAreaIds',
  regionIds = 'regionIds',
  marketIds = 'marketIds',
  storeIds = 'storeIds',
}

export interface FormValues {
  [FormFields.id]: number | undefined;
  [FormFields.customerId]: number | undefined;
  [FormFields.dateRangeYearComparison]: DateRangeYearComparisonValue | undefined;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;

  [FormFields.primaryGlobalAll]: PrimaryGlobalAll | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.regionIds]: SelectableValue | undefined;
  [FormFields.marketIds]: SelectableValue | undefined;
  [FormFields.storeIds]: SelectableValue | undefined;
}
