import * as _ from 'lodash';
import * as HOC from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as ApolloClient from 'apollo-client';
import * as GraphQL from 'client/hoc/graphql/query';
import * as Query from './query';
import * as Actions from './actions';
import * as State from 'client/state/state';
import * as SupplierCommitmentState from 'client/state/supplier-commitment';
import { RouteChildrenProps } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {
}

type UrlProps = RouteChildrenProps<{ id: string }>;

interface StateProps {
  supplierCommitmentId: number;
  updateSupplierItemCostsModalShown: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: ContainerProps & UrlProps): StateProps => {
  const supplierCommitmentState = State.supplierCommitmentLens.get(state);

  return {
    supplierCommitmentId: ownProps.match ? _.parseInt(ownProps.match.params.id) : -1,
    updateSupplierItemCostsModalShown: SupplierCommitmentState.updateSupplierItemCostsModalShown.get(supplierCommitmentState),
  };
};

type DispatchProps = {
  handleProductSelected(productId: number): void;
};

const mapDispatchToProps: DispatchProps = {
  handleProductSelected: Actions.handleProductSelected,
};

interface WithSupplierCommitmentRowProps {
  startWeek: number | null | undefined;
  endWeek: number | null | undefined;
  loading: boolean;
}

const WithSupplierCommitmentRows = GraphQL.msyncQuery<Query.GetSupplierCommitmentWeeklyProductVolumeQueryResponse, StateProps, WithSupplierCommitmentRowProps, Query.GetSupplierCommitmentWeeklyProductVolumeQueryInput>(Query.GetSupplierCommitmentWeeklyProductVolumeQuery, {
  alias: 'getSupplierCommitmentWeeklyProductVolume',
  options(ownProps): { variables, fetchPolicy: ApolloClient.FetchPolicy } {
    return {
      variables: {
        supplierCommitmentId: ownProps.supplierCommitmentId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSupplierCommitmentRowProps {
    const supplierCommitment = props.data.supplierCommitment ? props.data.supplierCommitment : undefined;

    return {
      startWeek: supplierCommitment ? supplierCommitment.startWeek : undefined,
      endWeek: supplierCommitment ? supplierCommitment.endWeek : undefined,
      loading: props.data.loading,
    };
  },
});

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  WithSupplierCommitmentRows,
)(UI.SupplierCommitmentDetailPage);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  WithSupplierCommitmentRowProps &
  StateProps;

assertCompatible<UI.UIProps, CombinedProps>();
export const SupplierCommitmentDetailContainer = (props: ContainerProps) => HOC.propToComponent(component, props);
