import { connect } from 'react-redux';
import * as Actions from 'client/actions/import-route';
import * as State from 'client/state/state';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { propToComponent } from 'client/hoc/hoc';
import { ImportRouteModalContentStepTwoUI, ComponentProps as UIProps } from './import-route-modal-step-two-ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { FileUploadStatus } from 'client/state/import-route';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  uploadState: FileUploadStatus;
  errorMessages: string[];
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    uploadState: ImportRouteSelectors.uploadState(state),
    errorMessages: ImportRouteSelectors.errorMessages(state),
  };
};

interface DispatchProps {
  handleFileSelected(fileName: string, fileContents: string): void;
}

const mapDispatchToProps: DispatchProps = {
  handleFileSelected: Actions.routeFileUploaded,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ImportRouteModalContentStepTwoUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
