import * as _ from 'lodash';
import { excludeNils } from 'shared/helpers/andys-little-helpers';

export const makeFilterOptions = (strings: Array<string | null | undefined>) => {
  return _.orderBy(strings.map(str => {
    return {
      id: str,
      value: str,
      displayValue: str,

    };
  }), o => o.displayValue);
};

export const makeDropdownOptions = (strings: Array<string | null | undefined>) => {
  const filtered = excludeNils(strings);
  return makeFilterOptions(filtered) as Array<{id: string, value: string, displayValue: string}>;
};
