import { Lens } from '@atomic-object/lenses';
import { VBillsFileFormat } from 'shared/file-parsers/direct-route-upl-file-parser';
import { RoutingLoadRouteType, RoutingLoadLoadType, RoutingLoadDropoffLocation, RoutingLoadLoadOrigin, RoutingLoadLoadDestination, RoutingLoadCarrier, RoutingLoadTrailerTemperature } from 'schema/routing-load/routing-load-graphql-types';
import { Carrier, RouteType, LoadDestination, GlobalStoreIdentifier, assertAsInt } from 'shared/types';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import * as DirectRouteFileParser from 'shared/file-parsers/direct-route-upl-file-parser';
import * as uuid from 'uuid';
import { orThrow } from 'shared/helpers';
import { keyByUnique } from 'client/lib/meta';

const DataStoreTypes = {
  customers           : undefined as unknown as { identifier: string },
  stores              : undefined as unknown as { customerReduxId: string, identifier: string, city: string, state: string, zip: string },
  customerOrders      : undefined as unknown as { identifier: string, customerReduxId: string },
  loads               : undefined as unknown as { loadNumber: string | null, truckId: string, routeNumber: number | null, totalMiles: number | null },
  stops               : undefined as unknown as { storeReduxId: string, loadReduxId: string, boxes: number | null, racks: number | null, pallets: number | null, mileage: number | null, sequenceNumber: number | null, customerOrderReduxId: string },
  splitStopQuantities : undefined as unknown as { loadReduxId: string, customerOrderProductGroupId: number, quantity: Int, storeReduxId: string, totalRackQuantityForGroup: Int },
};

type DST = keyof typeof DataStoreTypes;
type Entry<K extends DST> = (typeof DataStoreTypes)[K];
type ImportRouteData = { [K in DST]: { entries: Dictionary<Entry<K>> } };

/** Doesn't build the data-store, but builds Lens-mediated accessors fot the relevant Sub-Store. */
const createDataStorePackage = <N extends DST>(dataStoreName: N) => {
  const dataStoreLens = Lens.from<ImportRouteData>().prop(dataStoreName);
  const dataStoreEntriesLens = Lens.from<ImportRouteData[N]>().prop('entries'); // GlobalState.some_data_store_root.[dataStoreName]
  const createWithId = (dataStore: ImportRouteData, id: string, input: Entry<N>) => dataStoreLens.update(dataStore, dataStoreEntriesLens.update(v => ({ ...v, [id]: input })));
  return {
    dataStoreEntriesLens,
    dataStoreLens,
    fetchId: (dataStore: ImportRouteData[N], values: Partial<Entry<N>>) =>
      _.findKey(dataStore.entries, x => _.keys(values).reduce((accum, k) => accum && values[k] === x[k], true))
        ?? orThrow(`Entry matching ${JSON.stringify(values)} not found.`) /* TODO: Some better way to handle this?*/,
    findById: <F extends keyof Entry<N>>(data: ImportRouteData[N], id: string, field: F) => (data.entries[id] ?? orThrow(`NOT FOUND`))[field as any] as Entry<N>[F],
    createWithId,
    create: (dataStore: ImportRouteData, input: Entry<N>) => createWithId(dataStore, uuid.v4(), input),
    createDataStore: () => ({ entries: {} }),
    update:
      <T extends keyof Entry<N>>
      (dataStore: ImportRouteData, id: string, field: T, value: Entry<N>[T]) =>
        dataStoreLens.update(dataStore, dataStoreEntriesLens.update(v => ({ ...v, [id]: { ...(v[id]), [field]: value } }))),
  };
};

// const DataStore = strongMapValues(DataStoreTypes, (_v, k) => createDataStorePackage(k));
const DataStore = {
  customers           : createDataStorePackage('customers'),
  stores              : createDataStorePackage('stores'),
  customerOrders      : createDataStorePackage('customerOrders'),
  loads               : createDataStorePackage('loads'),
  stops               : createDataStorePackage('stops'),
  splitStopQuantities : createDataStorePackage('splitStopQuantities'),
};

 export const buildDataStore = (parsedFile: DirectRouteFileParser.VBillsFileFormat[]): ImportRouteData => {
  const customers      = { entries: keyByUnique(DirectRouteFileParser.getCustomers     (parsedFile).map(o => ({ ...o,                                  __reduxId: uuid.v4() })), x => x.__reduxId) };
  const cusRef = (identifier: string) => ({ customerReduxId: DataStore.customers.fetchId(customers, { identifier }) });
  const stores         = { entries: keyByUnique(DirectRouteFileParser.getStores        (parsedFile).map(o => ({ ...o, ...cusRef(o.customerIdentifier), __reduxId: uuid.v4() })), x => x.__reduxId) };
  const customerOrders = { entries: keyByUnique(DirectRouteFileParser.getCustomerOrders(parsedFile).map(o => ({ ...o, ...cusRef(o.customerIdentifier), __reduxId: uuid.v4() })), x => x.__reduxId) };
  const loads          = { entries: keyByUnique(DirectRouteFileParser.getLoads         (parsedFile).map(o => ({ ...o,  loadNumber: o.identifier,       __reduxId: uuid.v4() })), x => x.__reduxId) };
  const stops          = { entries: keyByUnique(DirectRouteFileParser.getStops         (parsedFile).map(o => {
      const customerReduxId = DataStore.customers.fetchId(customers, { identifier: o.customerIdentifier });
      return ({
        ...o,
        storeReduxId: DataStore.stores.fetchId(stores, { customerReduxId, identifier: o.storeIdentifier }),
        loadReduxId: DataStore.loads.fetchId(loads, { truckId: o.truckId }),
        customerOrderReduxId: DataStore.customerOrders.fetchId(customerOrders, { customerReduxId, identifier: o.customerOrderIdentifier }),
        __reduxId: uuid.v4(),
      });
    }), x => x.__reduxId),
  };

  return {
    customers,
    stores,
    customerOrders,
    loads,
    stops,
    splitStopQuantities: { entries: {} as Dictionary<Entry<'splitStopQuantities'>> },
  };
};

/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
export enum ImportRouteProcessStep {
  RouteInformation = 'RouteInformation',
  ImportFile = 'ImportFile',
  ProcessSplitStops = 'ProcessSplitStops',
  SubmittingRoutes = 'SubmittingRoutes',
}

interface ImportRouteState {
  readonly routePlanId: number | null;
  readonly importRouteModalShown: boolean;
  readonly parsedUplFile: VBillsFileFormat[];
  readonly parsedUplFileName: string;
  readonly fileUploadStatus: FileUploadStatus;
  readonly routeInformation: RouteInformation;
  readonly fileName: string | null;
  readonly fileContents: string | null;
  readonly dataStore: ImportRouteData;
  readonly parsingErrorMessages: string[];
  readonly processStep: ImportRouteProcessStep;
  readonly routeSubmitStatus: RouteSubmitStatus;
  readonly selectedSplitStopStore: string | null;
}

export type Type = ImportRouteState;

export interface RouteInformation {
  loadType: RoutingLoadLoadType | null;
  dropoffLocation: RoutingLoadDropoffLocation | null;
  routeType: RoutingLoadRouteType | null;
  origin: RoutingLoadLoadOrigin | null;
  destination: RoutingLoadLoadDestination | null;
  carrier: RoutingLoadCarrier | null;
  trailerTemperature: RoutingLoadTrailerTemperature | null;
}

const initialRouteInformation = Object.freeze({
  loadType: null,
  dropoffLocation: null,
  routeType: RouteType.RoundTrip,
  origin: null,
  destination: LoadDestination.Masterpiece,
  carrier: Carrier.PEAK,
  trailerTemperature: null,
});

export enum FileUploadStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export enum RouteSubmitStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Complete = 'Complete',
}

export const InitialState: Readonly<ImportRouteState> = Object.freeze({
  routePlanId           : null                                   ,
  importRouteModalShown : false                                  ,
  parsedUplFile         : []                                     ,
  parsedUplFileName     : ''                                     ,
  fileUploadStatus      : FileUploadStatus.Initial               ,
  routeInformation      : initialRouteInformation                ,
  fileName              : null                                   ,
  fileContents          : null                                   ,
  parsingErrorMessages  : []                                     ,
  processStep           : ImportRouteProcessStep.RouteInformation,
  routeSubmitStatus     : RouteSubmitStatus.Initial              ,
  selectedSplitStopStore: null                                   ,
  dataStore: {
    stores             : { entries: {} },
    customers          : { entries: {} },
    stops              : { entries: {} },
    loads              : { entries: {} },
    customerOrders     : { entries: {} },
    splitStopQuantities: { entries: {} },
  },
});

export const importRouteModalShown = Lens.from<ImportRouteState>().prop('importRouteModalShown');
export const parsedUplFile         = Lens.from<ImportRouteState>().prop('parsedUplFile'        );
export const parsedUplFileName     = Lens.from<ImportRouteState>().prop('parsedUplFileName'    );
export const fileUploadStatus      = Lens.from<ImportRouteState>().prop('fileUploadStatus'     );
export const fileName              = Lens.from<ImportRouteState>().prop('fileName'             );
export const fileContents          = Lens.from<ImportRouteState>().prop('fileContents'         );
export const parsingErrorMessages  = Lens.from<ImportRouteState>().prop('parsingErrorMessages' );
export const routeInformation      = Lens.from<ImportRouteState>().prop('routeInformation'     );
export const routePlanId           = Lens.from<ImportRouteState>().prop('routePlanId'          );
export const processStep           = Lens.from<ImportRouteState>().prop('processStep'          );

const importRouteRootDataStoreLens = Lens.from<ImportRouteState>().prop('dataStore'); // GlobalState.importRoute.dataStore
export const customerDataStore             = importRouteRootDataStoreLens .comp(DataStore.customers           .dataStoreLens        );
export const customerDataStoreEntries      = customerDataStore            .comp(DataStore.customers           .dataStoreEntriesLens );
export const storeDataStore                = importRouteRootDataStoreLens .comp(DataStore.stores              .dataStoreLens        );
export const storeDataStoreEntries         = storeDataStore               .comp(DataStore.stores              .dataStoreEntriesLens );
export const loadDataStore                 = importRouteRootDataStoreLens .comp(DataStore.loads               .dataStoreLens        );
export const loadDataStoreEntries          = loadDataStore                .comp(DataStore.loads               .dataStoreEntriesLens );
export const stopDataStore                 = importRouteRootDataStoreLens .comp(DataStore.stops               .dataStoreLens        );
export const stopDataStoreEntries          = stopDataStore                .comp(DataStore.stops               .dataStoreEntriesLens );
export const customerOrderDataStore        = importRouteRootDataStoreLens .comp(DataStore.customerOrders      .dataStoreLens        );
export const customerOrderDataStoreEntries = customerOrderDataStore       .comp(DataStore.customerOrders      .dataStoreEntriesLens );
export const splitStopQuantityDataStore    = importRouteRootDataStoreLens .comp(DataStore.splitStopQuantities .dataStoreLens        );
export const splitStopQuantities           = splitStopQuantityDataStore   .comp(DataStore.splitStopQuantities .dataStoreEntriesLens );

export const selectedSplitStopStore = Lens.of<ImportRouteState, GlobalStoreIdentifier | null>({
  get: state => {
    if (state.selectedSplitStopStore === null)
      return null;

    const storeEntry = storeDataStoreEntries(state)[state.selectedSplitStopStore];
    return {
      customerIdentifier: DataStore.customers.findById(customerDataStore(state), storeEntry.customerReduxId, 'identifier'),
      storeNumber: storeEntry.identifier,
    };
  },
  set: (state, value) => {
    if (value === null)
      return { ...state, selectedSplitStopStore: null };

    try {   // getStoreId returns error if store is not found.
      return { ...state, selectedSplitStopStore: getStoreReduxId(state, value.customerIdentifier, value.storeNumber) };
    } catch {
      return { ...state, selectedSplitStopStore: null };
    }
  },
});

export const getCustomerReduxId = (state: ImportRouteState, customerIdentifier: string) => DataStore.customers.fetchId(customerDataStore(state), { identifier: customerIdentifier });
export const getStoreReduxId = (state: ImportRouteState, customerIdentifier: string, storeIdentifier: string) => DataStore.stores.fetchId(storeDataStore(state), {
  identifier: storeIdentifier,
  customerReduxId: getCustomerReduxId(state, customerIdentifier),
});

export const getCustomerOrderReduxId = (state: ImportRouteState, customerIdentifier: string, customerOrderIdentifier: string) => DataStore.customerOrders.fetchId(customerOrderDataStore(state), {
  customerReduxId: getCustomerReduxId(state, customerIdentifier),
  identifier: customerOrderIdentifier,
});

export const getLoadReduxIdByLoadNumber = (state: ImportRouteState, loadNumber: string) => DataStore.loads.fetchId(loadDataStore(state), { loadNumber });
export const getLoadReduxIdByTruckId = (state: ImportRouteState, truckId: string) => DataStore.loads.fetchId(loadDataStore(state), { truckId });
export const getLoadReduxIdByRouteNumber = (state: ImportRouteState, routeNumber: Int) => DataStore.loads.fetchId(loadDataStore(state), { routeNumber });
export const createCustomer = (customerInput: { identifier: string }) => (state: ImportRouteState) => importRouteRootDataStoreLens.update(state, v => DataStore.customers.create(v, customerInput));
export const createStore = (storeInput: { identifier: string, customerIdentifier: string }) => (state: ImportRouteState) =>
  importRouteRootDataStoreLens.update(state, v => DataStore.stores.create(v, {
    identifier: storeInput.identifier,
    customerReduxId: DataStore.customers.fetchId(customerDataStore(state), { identifier: storeInput.customerIdentifier }),
    city: '',
    state: '',
    zip: '',
  }));

export const createCustomerOrder = (customerOrderInput: { customerIdentifier: string, identifier: string}) => (state: ImportRouteState) =>
  importRouteRootDataStoreLens.update(state, v => DataStore.customerOrders.create(v, {
    identifier: customerOrderInput.identifier,
    customerReduxId: DataStore.customers.fetchId(customerDataStore(state), { identifier: customerOrderInput.customerIdentifier }),
  }));

export const createLoad = (loadInput: { loadNumber: string | null, routeNumber: Int, truckId: string }) => (state: ImportRouteState) =>
  importRouteRootDataStoreLens.update(state, v => DataStore.loads.create(v, {
    loadNumber: loadInput.loadNumber,
    truckId: loadInput.truckId,
    routeNumber: loadInput.routeNumber,
    totalMiles: null,
  }));

export const createStop = (stopInput: {
  storeIdentifier: string,
  sequenceNumber: number,
  loadIdentifier: string,
  customerOrderId: string,
  racks: number,
  customerIdentifier: string,
  truckId: string,
}) => (state: ImportRouteState) =>
  importRouteRootDataStoreLens.update(state, v => DataStore.stops.create(v, {
    storeReduxId: getStoreReduxId(state, stopInput.customerIdentifier, stopInput.storeIdentifier),
    loadReduxId: getLoadReduxIdByTruckId(state, stopInput.truckId),
    racks: stopInput.racks,
    boxes: 0,
    pallets: 0,
    mileage: 0,
    sequenceNumber: stopInput.sequenceNumber,
    customerOrderReduxId: getCustomerOrderReduxId(state, stopInput.customerIdentifier, stopInput.customerOrderId),
  }));

/** returns a function that given an ImportRouteState, produces a new one with loads now assigned new loadNumbers. */
export const updateLoadNumber = ({loadReduxId, loadNumber}: {loadReduxId: string, loadNumber: string}) => importRouteRootDataStoreLens.comp(
  DataStore.loads.dataStoreLens,
  DataStore.loads.dataStoreEntriesLens,
).update(loadsEntries => ({ ...loadsEntries, [loadReduxId]: { ...loadsEntries[loadReduxId], loadNumber } }));

export const updateSplitStopQuantity = (input: {
  customerOrderProductGroupId: number;
  loadNumber: string;
  quantity: Int;
  selectedStore: GlobalStoreIdentifier;
  totalRackQuantityForGroup: Int;
}) => (outerState: ImportRouteState) => {
  const state = importRouteRootDataStoreLens.get(outerState);
  const updatedInnerState = DataStore.splitStopQuantities.dataStoreLens.update(state, ds => {
    const loadReduxId = DataStore.loads.fetchId(state.loads, { loadNumber: input.loadNumber });
    const storeReduxId = DataStore.stores.fetchId(state.stores, {
      customerReduxId: DataStore.customers.fetchId(state.customers, { identifier: input.selectedStore.customerIdentifier }),
      identifier: input.selectedStore.storeNumber,
    });

    return {
      ...ds,
      entries: {
        ...ds.entries,
        [`${storeReduxId}/${loadReduxId}/${input.customerOrderProductGroupId}`]: {
          loadReduxId,
          customerOrderProductGroupId: input.customerOrderProductGroupId,
          storeReduxId,
          quantity: input.quantity,
          totalRackQuantityForGroup: input.totalRackQuantityForGroup,
        },
      },
    };
  });

  return importRouteRootDataStoreLens.update(outerState, () => updatedInnerState);
};

const getLoadReduxIdsForStore = createSelector([stopDataStoreEntries, loadDataStoreEntries], (stopEntries, loads) =>
  (storeReduxId: string): string[] =>
    R.values(stopEntries) .filter(stop => stop.storeReduxId === storeReduxId) .map(stop => stop.loadReduxId));

const getSpacesRemainingInLoad = (state: ImportRouteState, loadNumber: string, selectedStore: GlobalStoreIdentifier ): number => {
  const stopEntries = R.values(stopDataStoreEntries(state));
  const storeReduxId = getStoreReduxId(state, selectedStore.customerIdentifier, selectedStore.storeNumber);
  const loadReduxId = getLoadReduxIdByLoadNumber(state, loadNumber);
  const spaces = stopEntries
    .filter(stop => stop.storeReduxId === storeReduxId && stop.loadReduxId === loadReduxId)
    .reduce((accum, stop) => (stop.racks ?? 0) + accum, 0);

  const allocatedQuantity = R.values(splitStopQuantities(state))
    .filter(splitStopQuantity => splitStopQuantity.storeReduxId === storeReduxId && splitStopQuantity.loadReduxId === loadReduxId)
    .reduce((accum, split) => split.quantity + accum, 0);

  return spaces - allocatedQuantity;
};

export const autoFill = (args: {
  groupsWithOrderQuantities: Array<{ customerOrderProductGroupId: number, rackQuantityOrdered: Int }>,
}) => (state: ImportRouteState) => {
  const { customerIdentifier, storeNumber } = selectedSplitStopStore(state) ?? {};
  if (!customerIdentifier || !storeNumber) return state;

  const loadsStoppingAtThisStoreWithSpacesRemaining = getLoadReduxIdsForStore(state)(getStoreReduxId(state, customerIdentifier, storeNumber))
    .map(id => ({loadReduxId: id, loadNumber: DataStore.loads.findById(loadDataStore(state), id, 'loadNumber') ?? orThrow('oops')}))
    .map(({loadReduxId, loadNumber}) => ({ loadReduxId, loadNumber, spacesRemaining: getSpacesRemainingInLoad(state, loadNumber, { customerIdentifier, storeNumber }) }))
    .filter(l => l.spacesRemaining > 0);

  if (loadsStoppingAtThisStoreWithSpacesRemaining.length !== 1)
    return  state; // auto fill only kicks in on the last load for this store (it solves a sort of sudoku puzzle)

  return  args.groupsWithOrderQuantities.reduce((acc, g) => {
    const storeId = getStoreReduxId(acc, customerIdentifier, storeNumber);
    const storeSplitsForThisCOPG = _.values(splitStopQuantities(acc)).filter(s => s.customerOrderProductGroupId === g.customerOrderProductGroupId && s.storeReduxId === storeId);
    if (!!storeSplitsForThisCOPG.find(s => s.loadReduxId === loadsStoppingAtThisStoreWithSpacesRemaining[0].loadReduxId))
      return acc; // somehow, already filled in the last load at least partially -- user must tweak allocation manually now

    return updateSplitStopQuantity({
      loadNumber: loadsStoppingAtThisStoreWithSpacesRemaining[0].loadNumber,
      customerOrderProductGroupId: g.customerOrderProductGroupId,
      quantity: assertAsInt(Math.max(0, g.rackQuantityOrdered - _.sum(storeSplitsForThisCOPG.map(s => s.quantity)))),
      selectedStore: { customerIdentifier, storeNumber },
      totalRackQuantityForGroup: g.rackQuantityOrdered,
      // totalRackQuantityForGroup: undefined as shame,
    })(acc);
  }, state);
};

// Helpers for split stops, maybe should be moved elsewhere
const sameStopDifferentLoad = (a: Entry<'stops'>, b: Entry<'stops'>) =>
      a.customerOrderReduxId === b.customerOrderReduxId
  &&  a.storeReduxId         === b.storeReduxId
  &&  a.loadReduxId          !== b.loadReduxId;

// TODO: replace this with find by id
const findStoreById = (state: ImportRouteData, storeId: string) => DataStore.stores.dataStoreEntriesLens(DataStore.stores.dataStoreLens(state))[storeId];
const findStoreByStop = (state: ImportRouteData, stop: Entry<'stops'>) => findStoreById(state, stop.storeReduxId);
const uniqueGlobalStoreIdentifierString = (storeIdentifier: GlobalStoreIdentifier): string => storeIdentifier.customerIdentifier + storeIdentifier.storeNumber;
const innerGetAllStoresWithSplitStops = (state: ImportRouteData): GlobalStoreIdentifier[] => R.pipe(
  () => _.values(DataStore.stops.dataStoreEntriesLens(DataStore.stops.dataStoreLens(state))),
  (stopEntries: Array<Entry<'stops'>>): Array<Entry<'stops'>> => stopEntries.filter(stop => stopEntries.find(otherStop => sameStopDifferentLoad(stop, otherStop))),
  (splitStops: Array<Entry<'stops'>>) => splitStops.map(stop => findStoreByStop(state, stop)),
  (storeEntries: Array<Entry<'stores'>>): GlobalStoreIdentifier[] => storeEntries.map(store => ({ storeNumber: store.identifier, customerIdentifier: DataStore.customers.findById(DataStore.customers.dataStoreLens(state), store.customerReduxId, 'identifier') })),
  (storeIdentifiers: GlobalStoreIdentifier[]): GlobalStoreIdentifier[] => R.uniqBy(uniqueGlobalStoreIdentifierString, storeIdentifiers),
  (storeIdentifiers: GlobalStoreIdentifier[]): GlobalStoreIdentifier[] => R.sortBy(uniqueGlobalStoreIdentifierString, storeIdentifiers),
)();

export const getAllStoresWithSplitStops = R.pipe(
  importRouteRootDataStoreLens,
  innerGetAllStoresWithSplitStops,
);

export const splitStopsExist = R.pipe(
  importRouteRootDataStoreLens,
  (state: ImportRouteData): Array<Entry<'stops'>> => _.values(DataStore.stops.dataStoreEntriesLens(DataStore.stops.dataStoreLens(state))),
  (stopEntries: Array<Entry<'stops'>>): Array<Entry<'stops'>> => stopEntries.filter(stop => stopEntries.find(otherStop => sameStopDifferentLoad(stop, otherStop))),
  (stopEntries: Array<Entry<'stops'>>) => stopEntries.length > 0,
);

export const setSelectedStoreToFirstSplitStopStore = (state: ImportRouteState) => {
  const splitStopStores = getAllStoresWithSplitStops(state);
  return splitStopStores.length === 0 ? state : selectedSplitStopStore.set(state, splitStopStores[0]);
};
