import * as React from 'react';
import { CustomerOrderStatus } from 'shared/types';
import buildRecordBarStatusToggle from 'client/components/record-bar/build-record-bar-status-toggle';
import { ButtonGroup, Button } from 'client/components/third-party';
import { Thunker } from 'client/types/redux-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';

interface OwnProps {
  record: any;
  onChangeRecordStatus: (field: string, val: string) => void;
}

const onNewCustomerOrderButtonClicked = (): Thunker => {
  return async (dispatch, getState) => {
    dispatch(push('/orders/customer/create'));
  };
};

interface DispatchProps {
  handleNewCustomerOrderButtonClicked(): void;
}

const mapDispatchToProps = {
  handleNewCustomerOrderButtonClicked: onNewCustomerOrderButtonClicked,
};

const statuses = [CustomerOrderStatus.Draft, CustomerOrderStatus.Approved];

function getStatusToggle(props: OwnProps) {
  if (!props.record) {
    return <div />;
  }

  if (props.record.orderStatus === 'Routed') {
    return buildRecordBarStatusToggle({
      value: props.record.orderStatus,
      options: ['Routed'],
      default: 'Routed',
      onChangeRecordStatus: props.onChangeRecordStatus,
      field: 'orderStatus',
      disabled: true,
    });
  }
  return buildRecordBarStatusToggle({
    value: props.record.orderStatus,
    options: statuses,
    default: CustomerOrderStatus.Draft,
    onChangeRecordStatus: props.onChangeRecordStatus,
    field: 'orderStatus',
  });
}

type ComponentProps = OwnProps & DispatchProps;

const CustomerOrderRecordBarUI = (props: ComponentProps) => {
  const statusToggle = getStatusToggle(props);

  // Don't want to show the button if this is a "new record page"
  const showNewRecordButton = props.record && props.record.id;

  return (
    <div className="detail">
      {showNewRecordButton &&
        <div className="detail">
          <ButtonGroup bsSize="large">
            <Button className="record-bar-button record-bar-button-medium-icon" onClick={props.handleNewCustomerOrderButtonClicked}>
              New Customer Order
            </Button>
          </ButtonGroup>
        </div>
      }
      {statusToggle}
    </div>
  );
};

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(CustomerOrderRecordBarUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
