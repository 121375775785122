import FormComponent from 'client/components/crud-forms/product-toss-form';
import buildAdminCreatePage from '../admin/admin-create-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminCreatePage({
  formName: 'ProductTossRecordDetailsForm',
  table: 'productToss',
  title: 'Toss Record',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
