import * as React from 'react';
import { Row, FormGroup } from 'client/components/third-party';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import * as Immutable from 'immutable';
import { isEqual } from 'lodash';
import { LabeledSelect, FieldWrapper } from 'client/components/form';
import { OrderMethod, PackOrderMethod, ShippingUnitOrderMethod } from 'shared/types';
import { SupplierItemDropDownItem } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import * as _ from 'lodash';

interface FormProps {
  supplierItemFieldIdentifier: string;
  orderMethod: OrderMethod;
  supplierItemIds?: Immutable.Set<number>;
  handleSupplierItemChanged: (supplierItemId: number, supplierFieldIdentifier: string) => void;
  handlePacksPerRackCalculationInputChange: (supplierFieldIdentifier: string, field?: 'shelvesPerRack' | 'packsPerShelf' | 'packSize', value?: number) => void;
  disableSupplierItemField: boolean;
  supplierItems: any[];
  showDeleteButton: boolean;
  handleDeleteSupplierItemClick: () => void;
  supplierItemIndex: number;
  supplierItemSelected: boolean;
  comboProductGroupId?: number;
}

class SupplierItemForm extends React.PureComponent<FormProps, {}> {
  componentDidMount() { this.props.handlePacksPerRackCalculationInputChange(this.props.supplierItemFieldIdentifier); } // Kickstart the calculated pieces per rack
  handlePackSizeChange = event => this.props.handlePacksPerRackCalculationInputChange(this.props.supplierItemFieldIdentifier, 'packSize', event.target.value);
  handlePacksPerShelfChange = event => this.props.handlePacksPerRackCalculationInputChange(this.props.supplierItemFieldIdentifier, 'packsPerShelf', event.target.value);
  handleShelvesPerRackChange = event => this.props.handlePacksPerRackCalculationInputChange(this.props.supplierItemFieldIdentifier, 'shelvesPerRack', event.target.value);
  handleSupplierItemChanged = id => this.props.handleSupplierItemChanged(id, this.props.supplierItemFieldIdentifier);
  itemLabelFormatter = (item: SupplierItemDropDownItem) => item.label;
  render() {
    const props: FormProps = this.props;
    const { supplierItemFieldIdentifier, supplierItemIds, disableSupplierItemField, supplierItems, showDeleteButton, handleDeleteSupplierItemClick, supplierItemIndex, comboProductGroupId } = props;
    let parameterFields: JSX.Element = <div />;
    if (props.orderMethod === ShippingUnitOrderMethod) {
      parameterFields = (
        <FormGroup>
          <FieldWrapper label="Pack"            table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.packSize`             } horizontalLabel={false} inputColSize={2} required disabled={!props.supplierItemSelected} handleChange={this.handlePackSizeChange}       />
          <FieldWrapper label="PPS"             table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.packsPerShelf`        } horizontalLabel={false} inputColSize={2} required disabled={!props.supplierItemSelected} handleChange={this.handlePacksPerShelfChange}  />
          <FieldWrapper label="SPR"             table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.shelvesPerRack`       } horizontalLabel={false} inputColSize={2} required disabled={!props.supplierItemSelected} handleChange={this.handleShelvesPerRackChange} />
          <FieldWrapper label="PPR"             table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.packsPerShippingUnit` } horizontalLabel={false} inputColSize={2} required disabled                                                                              />
          <FieldWrapper label="Pieces per Rack" table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.piecesPerShippingUnit`} horizontalLabel={false} inputColSize={3}          disabled                                                                              />
        </FormGroup>
      );
    } else if (props.orderMethod === PackOrderMethod) {
      parameterFields = (
        <FormGroup>
          <FieldWrapper label="Pack" table="supplierOrderProducts" name={`${supplierItemFieldIdentifier}.packSize`} inputColSize={3} required disabled={!props.supplierItemSelected} />
        </FormGroup>
      );
    }

    return (
      <div>
        <Row>
          {showDeleteButton && <span className="mfc-simple-icon-button product-worksheet-product-form-delete-product-button" data-testid={`remove-supplier-item-${supplierItemIndex}`} onClick={handleDeleteSupplierItemClick}><span className="fa fa-trash" /></span>}
          <FormGroup>
            <Field
              name={`${supplierItemFieldIdentifier}.supplierItemId`}
              inputColSize={12}
              horizontalLabel={false}
              valueField="id"
              textFormatter={this.itemLabelFormatter}
              label="Product"
              placeholder="Select Product"
              component={LabeledSelect}
              testid="supplier-item-ids"
              handleChange={this.handleSupplierItemChanged}
              required
              disabled={disableSupplierItemField || (supplierItemIds?.size ?? 0) > 0 || !_.isNil(comboProductGroupId)}
              options={supplierItems}
              autoFocus={supplierItemIndex === 0}
            />
          </FormGroup>
          {parameterFields}
        </Row>
      </div>
    );
  }
}

const handleAddSupplierItemClick = fields =>  () => fields.push({});
const onDeleteSupplierItemClick = (fields, index) => () => fields.remove(index);

export interface SupplierItemsFormProps {
  orderMethod: OrderMethod;
  supplierItemIds?: Immutable.Set<number>;
  handleSupplierItemChanged: (supplierItemId: number, supplierItemFieldIdentifier: string) => void;
  handlePacksPerRackCalculationInputChange: (supplierItemFieldIdentifier: string, field?: 'shelvesPerRack' | 'packsPerShelf' | 'packSize', value?: number) => void;
  disableSupplierItemField: boolean;
  supplierItems: any[];
  activeSupplierItems: any[];
  showAddSupplierItemButton: boolean;
  supplierItemSelectedList: boolean[];
  savedSupplierOrderProductIds: number[];
  currentSupplierOrderProductIds: number[];
  comboProductGroupId?: number;
}

export class SupplierItemsForm extends React.Component<WrappedFieldArrayProps & SupplierItemsFormProps, {}> {
  constructor(props: WrappedFieldArrayProps & SupplierItemsFormProps) { super(props); handleAddSupplierItemClick(props.fields); }
  shouldComponentUpdate(nextProps: WrappedFieldArrayProps & SupplierItemsFormProps) {
    return nextProps.fields.length !== this.props.fields.length ||
      !isEqual(nextProps.supplierItemSelectedList, this.props.supplierItemSelectedList) ||
      this.props.comboProductGroupId !== nextProps.comboProductGroupId ||
      this.props.showAddSupplierItemButton !== nextProps.showAddSupplierItemButton;
  }

  render() {
    const { fields, orderMethod, supplierItemIds, handleSupplierItemChanged, disableSupplierItemField, supplierItems, activeSupplierItems, showAddSupplierItemButton, supplierItemSelectedList, savedSupplierOrderProductIds, currentSupplierOrderProductIds, comboProductGroupId } = this.props;
    return (
      <div>
        {fields.map((supplierItemFieldIdentifier, index) => {
          return (
            <div key={index} data-testid={`supplier-item-form-${index}`}>
              <SupplierItemForm
                supplierItemFieldIdentifier={supplierItemFieldIdentifier}
                orderMethod={orderMethod}
                supplierItemIds={supplierItemIds}
                handleSupplierItemChanged={handleSupplierItemChanged}
                handleDeleteSupplierItemClick={onDeleteSupplierItemClick(fields, index)}
                disableSupplierItemField={disableSupplierItemField && savedSupplierOrderProductIds.indexOf(currentSupplierOrderProductIds[index]) !== -1}
                comboProductGroupId={comboProductGroupId}
                supplierItems={supplierItemSelectedList[index] ? supplierItems : activeSupplierItems}
                supplierItemIndex={index}
                showDeleteButton={fields.length > 1}
                handlePacksPerRackCalculationInputChange={this.props.handlePacksPerRackCalculationInputChange}
                supplierItemSelected={supplierItemSelectedList[index]}
              />
            </div>
          );
        })}
        {showAddSupplierItemButton &&
          <div className="text-center">
            <div className="mfc-form-button" data-testid="add-another-supplier-item" onClick={handleAddSupplierItemClick(fields)}>
              <span className="fa fa-plus-circle" /> Add Another Product
            </div>
          </div>
        }
      </div>
    );
  }
}
