import gqlTag from 'graphql-tag';
import { GetRoutingLoadsInput } from 'schema/routing-load/routing-load-graphql-types';
import { DateStr } from 'shared/types';

export interface AssignLoadsToTrailersRowsResponse {
  getRoutingLoads?: {
    routingLoads: Array<{
      id: number;
      identifier: string,
      deliveryDate: DateStr,
      customerNames: string,
      mfcAreaIdentifiers: string,
      dropsCombinedWithAdditionalDrops: number,
      trailerNumber: string,
    }>;
    loading: boolean;
  };
  uiHasErrors: boolean;
}

export const AssignLoadsToTrailersFragment = gqlTag`
  fragment AssignLoadsToTrailersQueryFragment on RoutingLoad {
    id
    identifier
    deliveryDate
    customerNames
    mfcAreaIdentifiers
    dropsCombinedWithAdditionalDrops
    trailerNumber
  }
`;

export type AssignLoadsToTrailersInput = GetRoutingLoadsInput;
export const AssignLoadsToTrailersQuery = gqlTag`
  query AssignLoadsToTrailersQuery($sort: [SortInput!], $filters: RoutingLoadFilterInput, $search: RoutingLoadSearchInput, $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput) {
    getRoutingLoads: GetRoutingLoads(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routingLoads {
        ...AssignLoadsToTrailersQueryFragment
      }
    }
  }
  ${AssignLoadsToTrailersFragment}
`;

export interface WithAssignLoadsToTrailersRowsQueryProps {
  routingLoads: Array<{
    id: number;
    identifier: string;
    deliveryDate: DateStr;
    customerNames: string;
    mfcAreaIdentifiers: string;
    dropsCombinedWithAdditionalDrops: number;
    trailerNumber: string;
  }>;
  loading: boolean;
}
