import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES, SelectableValue, PrimaryGlobal, PrimaryGlobalAll, FilterOperator, ActiveInactive } from 'shared/types';
import gql from 'graphql-tag';
import { DropDownOptions } from 'client/types';
import { SelectableRow } from 'client/components/selectable/types';
import { excludeNils } from 'shared/helpers/andys-little-helpers';
import * as _ from 'lodash';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const applyFilterIfListHasValues = (field: string, value: SelectableValue | any[] | undefined, operator?: FilterOperator): Array<{ field: string, values: any[], operator?: FilterOperator }> => {
  if (value) {
    const values = excludeNils(Array.isArray(value) ? value : value.values);

    if (values.length > 0) {
      return [{ field, values, operator }];
    }
  }
  return [];
};

export const getStoresForMfcAreas = <T extends { mfcAreas: Array<{ id: number }> }> (stores: T[], mfcAreaIdSelectableValue?: SelectableValue): T[] => {
  // We can't filter mfcAreas in the query because the old schema
  // does not support filtering on has-many relationships.
  // So, if the user has selected mfc areas, then filter out the stores
  // that do not have associated mfc areas.
  const mfcAreaIds = extractSelectedValues(mfcAreaIdSelectableValue);
  if (mfcAreaIds && mfcAreaIds.length > 0) {
    return stores.filter(store => store.mfcAreas.some(mfcArea => mfcAreaIds.includes(mfcArea.id)));
  } else {
    return stores;
  }
};

export interface FindAllCustomersQueryResponse {
  customers?: Array<{
    id: number;
    name: string;
    identifier: string;
  }>;
}

export const FindAllCustomersQuery = gql`
  query FindAllCustomersQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    customers: findAll(type: Customer, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Customer {
        id
        name
        identifier
      }
    }
  }
`;

export interface CustomerQueryProps {
  customerOptions: DropDownOptions;
  customersLoading: boolean;
}

export const withCustomers = msyncQuery<FindAllCustomersQueryResponse, {}, CustomerQueryProps>(FindAllCustomersQuery, {
  alias: 'withCustomers',
  options() {
    return {
      variables: {
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): CustomerQueryProps {
    const { data } = props;
    if (data.loading || data.customers === undefined) {
      return {
        customerOptions: [],
        customersLoading: true,
      };
    }

    return {
      customerOptions: data.customers.map(customer => ({
        id: customer.id,
        value: `${customer.identifier} - ${customer.name}`,
        identifier: customer.identifier,
      })),
      customersLoading: false,
    };
  },
});

export interface FindAllSellDepartmentsQueryResponse {
  sellDepartments?: Array<{
    id: number;
    identifier: string;
  }>;
}

export const FindAllSellDepartmentsQuery = gql`
  query FindAllSellDepartmentsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    sellDepartments: findAll(type: SellDepartment, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SellDepartment {
        id
        identifier
      }
    }
  }
`;

export interface SellDepartmentQueryProps {
  sellDepartmentOptions: DropDownOptions;
  sellDepartmentsLoading: boolean;
}
export const withSellDepartments = msyncQuery<FindAllSellDepartmentsQueryResponse, {}, SellDepartmentQueryProps, {}>(FindAllSellDepartmentsQuery, {
  alias: 'withSellDepartments',
  options(ownProps) {
    return {
      variables: {
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SellDepartmentQueryProps {
    const { data } = props;
    if (data.loading || data.sellDepartments === undefined) {
      return {
        sellDepartmentOptions: [],
        sellDepartmentsLoading: data.loading,
      };
    }

    return {
      sellDepartmentOptions: data.sellDepartments.map(sellDepartment => ({
        id: sellDepartment.id,
        value: sellDepartment.identifier,
        identifier: sellDepartment.identifier,
      })),
      sellDepartmentsLoading: false,
    };
  },
});

export interface FindAllProductClassesQueryResponse {
  productClasses?: Array<{
    id: number;
    identifier: string;
    sellDepartment: {
      id: number;
      identifier: string;
    }
  }>;
}

export const FindAllProductClassesQuery = gql`
  query FindAllProductClassesQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    productClasses: findAll(type: ProductClass, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on ProductClass {
        id
        identifier
        sellDepartment {
          id
          identifier
        }
      }
    }
  }
`;

export interface ProductClassQueryProps {
  productClassOptions?: SelectableRow[];
  productClassesLoading?: boolean;
}
export const withProductClasses = msyncQuery<FindAllProductClassesQueryResponse, { customerId?: number, sellDepartmentId?: number }, ProductClassQueryProps, {}>(FindAllProductClassesQuery, {
  alias: 'withProductClasses',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          ...applyFilterIfListHasValues('sellDepartment', [ownProps.sellDepartmentId]),
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): ProductClassQueryProps {
    const { data } = props;
    if (data.loading || data.productClasses === undefined) {
      return {
        productClassOptions: [],
        productClassesLoading: true,
      };
    }

    return {
      productClassOptions: data.productClasses.map(productClass => ({
        id: productClass.id,
        cells: [
          productClass.identifier,
          productClass.sellDepartment.identifier,
        ],
      })),
      productClassesLoading: false,
    };
  },
});

export interface FindAllProductSubClassesQueryResponse {
  productSubClasses?: Array<{
    id: number;
    identifier: string;
    productClass: {
      id: number;
      identifier: string;
    }
  }>;
}

export const FindAllProductSubClassesQuery = gql`
  query FindAllProductSubClassesQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    productSubClasses: findAll(type: ProductSubClass, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on ProductSubClass {
        id
        identifier
        productClass {
          id
          identifier
        }
      }
    }
  }
`;

export interface ProductSubClassQueryProps {
  productSubClassOptions?: SelectableRow[];
  productSubClassesLoading?: boolean;
}
export const withProductSubClasses = msyncQuery<FindAllProductSubClassesQueryResponse, { customerId?: number, sellDepartmentId: number | undefined, productClassIds?: SelectableValue }, ProductSubClassQueryProps, {}>(FindAllProductSubClassesQuery, {
  alias: 'withProductSubClasses',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          ...applyFilterIfListHasValues('sellDepartment', [ownProps.sellDepartmentId]),
          ...applyFilterIfListHasValues('productClass', ownProps.productClassIds),
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): ProductSubClassQueryProps {
    const { data } = props;
    if (data.loading || data.productSubClasses === undefined) {
      return {
        productSubClassOptions: [],
        productSubClassesLoading: data.loading,
      };
    }

    return {
      productSubClassOptions: data.productSubClasses.map(productSubClass => ({
        id: productSubClass.id,
        cells: [
          productSubClass.identifier,
          productSubClass.productClass.identifier,
        ],
      })),
      productSubClassesLoading: data.loading,
    };
  },
});

export interface FindAllProductsQueryResponse {
  products?: Array<{
    id: number;
    identifier: string;
    replenishmentIdentifier: string;
    description: string;
  }>;
}

export const FindAllProductsQuery = gql`
  query FindAllProductsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    products: findAll(type: Product, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Product {
        id
        identifier
        replenishmentIdentifier
        description
      }
    }
  }
`;

export interface ProductQueryProps {
  productOptions?: SelectableRow[];
  productsLoading?: boolean;
}
export const withProducts = (opts?: { onlyIncludeParentReplenishmentProducts?: boolean }) => {
  return msyncQuery<FindAllProductsQueryResponse, { customerId?: number, sellDepartmentId?: number, productClassIds?: SelectableValue, productSubClassIds?: SelectableValue, scanProductOptions?: boolean[] }, ProductQueryProps, {}>(FindAllProductsQuery, {
    alias: 'withProducts',
    skip(ownProps) {
      return ownProps.customerId === undefined;
    },
    options(ownProps) {
      return {
        variables: {
          filters: [
            ...(opts && opts.onlyIncludeParentReplenishmentProducts ? [{ field: 'isItsOwnReplenishmentProduct', values: [true] }] : []),
            { field: 'customer', values: [ownProps.customerId] },
            ...applyFilterIfListHasValues('sellDepartment', [ownProps.sellDepartmentId]),
            ...applyFilterIfListHasValues('productClass', ownProps.productClassIds),
            ...applyFilterIfListHasValues('productSubClass', ownProps.productSubClassIds),
            ...applyFilterIfListHasValues('scanProduct', ownProps.scanProductOptions),
          ],
          sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
        },
        fetchPolicy: 'network-only',
      };
    },
    props(props): ProductQueryProps {
      const { data } = props;
      if (data.loading || data.products === undefined) {
        return {
          productOptions: [],
          productsLoading: data.loading,
        };
      }

      return {
        productOptions: data.products.map(product => ({
          id: product.id,
          cells: [
            product.identifier,
            product.description,
          ],
        })),
        productsLoading: data.loading,
      };
    },
  });
};

export interface FindAllMfcAreasQueryResponse {
  mfcAreas?: Array<{
    id: number;
    identifier: string;
    sellDepartment: {
      id: number;
      identifier: string;
    }
  }>;
}

export const FindAllMfcAreasQuery = gql`
  query FindAllMfcAreasQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    mfcAreas: findAll(type: MfcArea, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on MfcArea {
        id
        identifier
        sellDepartment {
          id
          identifier
        }
      }
    }
  }
`;

export interface FindAllSalesPlansQueryResponse {
  salesPlans?: Array<{
    id: number;
    identifier: string;
    year: string;
    customerId: number;
    customerIdentifier: string;
    sellDepartmentIdentifier: string;
  }>;
}

export const FindAllSalesPlansQuery = gql`
  query FindAllSalesPlansQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlans: findAll(type: SalesPlan, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlan {
        id
        identifier
        year
        customerId
        customerIdentifier
        sellDepartmentIdentifier
      }
    }
  }
`;

export interface SalesPlanQueryProps {
  salesPlanOptions: DropDownOptions;
  salesPlansLoading: boolean;
}

export const withSalesPlans = msyncQuery<FindAllSalesPlansQueryResponse, { customerId?: number }, SalesPlanQueryProps, {}>(FindAllSalesPlansQuery, {
  alias: 'withSalesPlans',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          { field: 'customer', values: [ownProps.customerId] },
        ],
        sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SalesPlanQueryProps {
    const { data } = props;
    if (data.loading || data.salesPlans === undefined) {
      return {
        salesPlanOptions: [],
        salesPlansLoading: data.loading,
      };
    }

    return {
      salesPlanOptions: data.salesPlans.map(salesPlan => ({
        id: salesPlan.id,
        value: `${salesPlan.year} - ${salesPlan.identifier}`,
        identifier: salesPlan.identifier,
      })),
      salesPlansLoading: data.loading,
    };
  },
});

export interface FindAllRegionsQueryResponse {
  regions?: Array<{
    id: number;
    identifier: string;
    description: string;
  }>;
}

export const FindAllRegionsQuery = gql`
  query FindAllRegionsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    regions: findAll(type: Region, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Region {
        id
        identifier
        description
      }
    }
  }
`;

export interface RegionQueryProps {
  regionOptions?: SelectableRow[];
  regionsLoading?: boolean;
}
export const withRegions = msyncQuery<FindAllRegionsQueryResponse, { customerId?: number }, RegionQueryProps, {}>(FindAllRegionsQuery, {
  alias: 'withRegions',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          { field: 'customer', values: [ownProps.customerId] },
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): RegionQueryProps {
    const { data } = props;
    if (data.loading || data.regions === undefined) {
      return {
        regionOptions: [],
        regionsLoading: data.loading,
      };
    }

    return {
      regionOptions: data.regions.map(region => ({
        id: region.id,
        cells: [
          region.identifier,
          region.description,
        ],
      })),
      regionsLoading: data.loading,
    };
  },
});

export interface FindAllMarketsQueryResponse {
  markets?: Array<{
    id: number;
    identifier: string;
    description: string;
  }>;
}

export const FindAllMarketsQuery = gql`
  query FindAllMarketsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    markets: findAll(type: Market, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Market {
        id
        identifier
        description
      }
    }
  }
`;

export interface MarketQueryProps {
  marketOptions?: SelectableRow[];
  marketsLoading?: boolean;
}

export const withMarkets = msyncQuery<FindAllMarketsQueryResponse, { customerId?: number, regionIds?: SelectableValue }, MarketQueryProps, {}>(FindAllMarketsQuery, {
  alias: 'withMarkets',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          { field: 'customer', values: [ownProps.customerId] },
          ...applyFilterIfListHasValues('region', ownProps.regionIds),
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): MarketQueryProps {
    const { data } = props;
    if (data.loading || data.markets === undefined) {
      return {
        marketOptions: [],
        marketsLoading: data.loading,
      };
    }

    return {
      marketOptions: data.markets.map(market => ({
        id: market.id,
        cells: [
          market.identifier,
          market.description,
        ],
      })),
      marketsLoading: data.loading,
    };
  },
});

interface Store {
  id: number;
  identifier: string;
  city: string;
  state: string;
  primaryGlobal: PrimaryGlobal;
  mfcAreas: Array<{ id: number }>;
  activeStatus: ActiveInactive;
}

export interface FindAllStoresQueryResponse {
  stores?: Store[];
}

export const FindAllStoresQuery = gql`
  query FindAllStoresQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    stores: findAll(type: Store, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Store {
        id
        identifier
        city
        state
        mfcAreas {
          id
        }
        primaryGlobal
        activeStatus
      }
    }
  }
`;

export interface StoreQueryProps {
  storeOptions?: SelectableRow[];
  storesLoading?: boolean;
}
export const withStores = msyncQuery<FindAllStoresQueryResponse, { customerId?: number, regionIds?: SelectableValue, marketIds?: SelectableValue, mfcAreaIds?: SelectableValue, primaryGlobalAll: PrimaryGlobalAll }, StoreQueryProps, {}>(FindAllStoresQuery, {
  alias: 'withStores',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          { field: 'customer', values: [ownProps.customerId] },
          ...((ownProps.primaryGlobalAll === PrimaryGlobalAll.All || _.isNil(ownProps.primaryGlobalAll)) ? [] : [{ field: 'primaryGlobal', values: [ownProps.primaryGlobalAll] }]),
          ...applyFilterIfListHasValues('market', ownProps.marketIds),
          ...applyFilterIfListHasValues('region', ownProps.regionIds),
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): StoreQueryProps {
    const { data } = props;
    if (data.loading || data.stores === undefined) {
      return {
        storeOptions: [],
        storesLoading: true,
      };
    }

    const stores = getStoresForMfcAreas(data.stores, props.ownProps.mfcAreaIds);

    return {
      storeOptions: stores.map(store => ({
        id: store.id,
        cells: [store.identifier, store.city, store.state, store.primaryGlobal, store.activeStatus],
      })),
      storesLoading: false,
    };
  },
});

export interface FindAllSuppliersQueryResponse {
  suppliers?: Array<{
    id: number;
    name: string;
    identifier: string;
  }>;
}

export const FindAllSuppliersQuery = gql`
  query FindAllSuppliersQuery($type: RecordType = Supplier, $sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    suppliers: findAll(type: $type, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on Supplier {
        id
        name
        identifier
      }
    }
  }
`;

export interface SupplierQueryProps {
  supplierOptions: DropDownOptions;
  suppliersLoading: boolean;
}
export const withSuppliers = msyncQuery<FindAllSuppliersQueryResponse, {}, SupplierQueryProps>(FindAllSuppliersQuery, {
  alias: 'withSuppliers',
  options(ownProps) {
    return {
      variables: {
        type: 'Supplier',
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SupplierQueryProps {
    const { data } = props;
    if (data.loading || data.suppliers === undefined) {
      return {
        supplierOptions: [],
        suppliersLoading: true,
      };
    }

    return {
      supplierOptions: data.suppliers.map(supplier => ({
        id: supplier.id,
        value: `${supplier.identifier} - ${supplier.name}`,
        identifier: supplier.identifier,
      })),
      suppliersLoading: false,
    };
  },
});
