import * as React from 'react';
import * as _ from 'lodash';

export interface OwnProps {
  children?: React.ReactElement<any>;
}

interface ContainerParentState {
  containerHeight?: number;
  containerWidth?: number;
}

export type ContainerParentInfo = ContainerParentState;

export function containerParentHoc(parentSelector: string) {
  return (WrappedComponent: new (passedProps: OwnProps) => React.Component<OwnProps, ContainerParentState>) => {
    return class TableParent extends React.Component<OwnProps, ContainerParentState> {
      private node?: HTMLElement | null;
      private componentIsMounted: boolean = false;
      private handleWindowResizeDebounce: () => void;

      constructor(props: OwnProps) {
        super(props);

        this.handleWindowResize = this.handleWindowResize.bind(this);
        const debouncedFunc = _.debounce(() => {
          this.handleWindowResize();
        }, 100);
        this.handleWindowResizeDebounce = debouncedFunc.bind(this);

        this.state = {
          containerHeight: undefined,
          containerWidth: undefined,
        };
      }

      componentDidMount() {
        this.componentIsMounted = true;
        window.addEventListener('resize', this.handleWindowResizeDebounce);
        requestAnimationFrame(() => {
          this.handleWindowResize();
        });
      }

      componentWillUnmount() {
        this.componentIsMounted = false;
        window.removeEventListener('resize', this.handleWindowResizeDebounce);
      }

      componentDidUpdate() {
        this.handleWindowResizeDebounce();
      }

      public render() {
        const props = { ...this.props, containerParentInfo: this.state };
        return (
          <div className="mfc-container-parent" ref={node => (this.node = node)}>
            <WrappedComponent {...props}/>
          </div>
        );
      }

      public handleWindowResize() {
        if (!this.componentIsMounted) {
          return;
        }

        if (this.node) {
          const container = this.node.querySelector(parentSelector);
          const containerHeight = container ? this.getOutsideHeight(container) : 0;
          const containerWidth = container ? this.getOutsideWidth(container) : 0;

          if (this.componentIsMounted) {
            this.setState({ containerHeight, containerWidth } as ContainerParentState);
          }
        }
      }

      private getOutsideHeight(element: Element) {
        const clientHeight = element.clientHeight;
        const styles = window.getComputedStyle(element);

        if (!styles) {
          return clientHeight;
        }

        const borderTopWidth = styles.borderTopWidth ? Math.ceil(Number.parseFloat(styles.borderTopWidth)) : 0;
        const borderBottomWidth = styles.borderBottomWidth ? Math.ceil(Number.parseFloat(styles.borderBottomWidth)) : 0;
        const marginTopWidth = styles.marginTop ? Math.ceil(Number.parseFloat(styles.marginTop)) : 0;
        const marginBottomWidth = styles.marginBottom ? Math.ceil(Number.parseFloat(styles.marginBottom)) : 0;

        return marginTopWidth + borderTopWidth + clientHeight + borderBottomWidth + marginBottomWidth;
      }

      private getOutsideWidth(element: Element) {
        const clientWidth = element.clientWidth;
        const styles = window.getComputedStyle(element);

        if (!styles) {
          return clientWidth;
        }

        const borderLeftWidth = styles.borderLeftWidth ? Math.ceil(Number.parseFloat(styles.borderLeftWidth)) : 0;
        const borderRightWidth = styles.borderRightWidth ? Math.ceil(Number.parseFloat(styles.borderRightWidth)) : 0;
        const marginLeftWidth = styles.marginLeft ? Math.ceil(Number.parseFloat(styles.marginLeft)) : 0;
        const marginRightWidth = styles.marginRight ? Math.ceil(Number.parseFloat(styles.marginRight)) : 0;

        return marginLeftWidth + borderLeftWidth + clientWidth + borderRightWidth + marginRightWidth;
      }
    };
  };
}
