import { push } from 'connected-react-router';
import { Thunker } from 'client/types/redux-types';
import { ConfirmOkToSaveFunction } from './save-confirmation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GetSalesPlansQueryName } from './query';
import * as Mutations from './mutations';

export const newRecordButtonClicked = () => push('/planning/sales/create');
export const rowSelected = (row: { id: number }) => push(`/planning/sales/details/${row.id}`);

export const deleteMenuItemClicked = (ids: number[], confirmOkToSave: ConfirmOkToSaveFunction): Thunker => {
  return async (dispatch, getState) => {
    if (await confirmOkToSave()) {
      await msyncClientMutation<Mutations.DeleteSalesPlansResponse, Mutations.DeleteSalesPlansInput>({
        mutation: Mutations.DeleteSalesPlansMutation,
        variables: {
          ids,
        },
        dispatch,
        refetchQueries: [GetSalesPlansQueryName], // TODO: Remove this and figure out the cache issue with the mutation
        showBusyModal: true,
      });
    }
  };
};
