import * as _ from 'lodash';
import * as Constants from './constants';
import * as ReduxForm from 'redux-form';
import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  return _.isNil(formValues[Constants.FormFields.customerId]) ||
    _.isNil(formValues[Constants.FormFields.date]);
};

export const getReportDownloadStatus = (state: State.Type) => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
