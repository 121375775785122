import { Action, Dispatch } from 'redux';
import { Carrier } from 'shared/types';
import { RoutingLoadId, NotifyPeakReadyToInvoiceResponse, EditRoutingLoadsResponse, AssignLoadsToTrailersResponse } from 'schema/routing-load/routing-load-graphql-types';
import { ApplyInvoiceToLoadsMutation, SetCarrierOnRoutingLoadMutation } from 'client/app/transportation/loads/apply-invoice-to-loads-modal/apply-invoice-to-loads-modal-mutation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { TrailerNumbers } from 'client/app/transportation/loads/assign-loads-to-trailers-modal/assign-loads-to-trailers-modal-ui';
import { clickCell, edit } from 'client/actions/table';
import { APPLY_LOADS_TO_TRAILERS_TABLE } from 'client/constants';
import { AssignLoadsToTrailersMutation } from 'client/app/transportation/loads/assign-loads-to-trailers-modal/assign-loads-to-trailers-modal-mutation';
import { mutationSent, mutationResponseReceived, removeRecentlyReceivedMutationResponse } from 'client/actions/mutations';
import { NotifyPeakMutation } from 'client/app/transportation/loads/notify-peak-modal/notify-peak-modal-mutation';
import { timeout } from 'shared/helpers';
import * as State from 'client/state/state';
import * as LoadListSelectors from 'client/state/load-list-selectors';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { ApplyInvoiceToLoadsQuery, ApplyInvoiceToLoadsResponse } from 'client/app/transportation/loads/apply-invoice-to-loads-modal/apply-invoice-to-loads-modal-query';
import { Thunker } from 'client/types/redux-types';
import { expectedErrorReceived, ExpectedErrorReceivedAction } from 'client/actions/error';

const MaximumLoadsForMenuAction = 100;

export type ActionTypes =
  SetApplyInvoiceToLoadsModalVisibilityAction |
  SetApplyInvoiceToLoadsCarrierChangedAction |
  SetApplyInvoiceToLoadsInvoiceNumberChangedAction |
  SetAssignLoadsToTrailersModalVisibilityAction |
  SetTrailerNumberOnLoadsAction |
  SetNotifyPeakModalVisibilityAction |
  ChangesCheckboxClickedAction;

export enum ActionTypeKeys {
  LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY = 'App/LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY',
  LOAD_LIST_APPLY_INVOICE_TO_LOADS_CARRIER_CHANGED = 'App/LOAD_LIST_APPLY_INVOICE_TO_LOADS_CARRIER_CHANGED',
  LOAD_LIST_APPLY_INVOICE_TO_LOADS_INVOICE_NUMBER_CHANGED = 'App/LOAD_LIST_APPLY_INVOICE_TO_LOADS_INVOICE_NUMBER_CHANGED',
  LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY = 'App/LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY',
  LOAD_LIST_SET_TRAILER_NUMBER_ON_LOADS  = 'App/LOAD_LIST_SET_TRAILER_NUMBER_ON_LOADS',
  LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY = 'App/LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY',
  LOAD_LIST_NOTIFY_PEAK_SEND_BUTTON = 'App/LOAD_LIST_NOTIFY_PEAK_SEND_BUTTON',
  LOAD_LIST_NOTIFY_PEAK_CHANGES_CHECKBOX_CLICKED = 'App/LOAD_LIST_NOTIFY_PEAK_CHANGES_CHECKBOX_CLICKED',
}

export interface SetApplyInvoiceToLoadsModalVisibilityAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
    routingLoadIds: RoutingLoadId[];
  };
}

const getErrorIfTooManyLoadsSelected = (routingLoadIds: RoutingLoadId[]): ExpectedErrorReceivedAction | null => {
  if (routingLoadIds.length >= MaximumLoadsForMenuAction) {
    return expectedErrorReceived({
      message: 'Please select fewer loads and try again.',
      debugInfo: {},
    });
  }

  return null;
};

export function showApplyInvoiceToLoadsModal(routingLoadIds: RoutingLoadId[]): SetApplyInvoiceToLoadsModalVisibilityAction | ExpectedErrorReceivedAction  {
  const error = getErrorIfTooManyLoadsSelected(routingLoadIds);
  if (error) {
    return error;
  }

  return {
    type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY,
    payload: {
      showModal: true,
      routingLoadIds,
    },
  };
}

export function hideApplyInvoiceToLoadsModal(): SetApplyInvoiceToLoadsModalVisibilityAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY,
    payload: {
      showModal: false,
      routingLoadIds: [],
    },
  };
}

export interface SetApplyInvoiceToLoadsCarrierChangedAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_CARRIER_CHANGED;
  payload: {
    carrier: Carrier;
  };
}

export function handleCarrierChanged(carrier: Carrier): SetApplyInvoiceToLoadsCarrierChangedAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_CARRIER_CHANGED,
    payload: {
      carrier,
    },
  };
}

export interface SetApplyInvoiceToLoadsInvoiceNumberChangedAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_INVOICE_NUMBER_CHANGED;
  payload: {
    invoiceNumber: string,
  };
}

export function handleInvoiceNumberChanged(invoiceNumber: string): SetApplyInvoiceToLoadsInvoiceNumberChangedAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_INVOICE_NUMBER_CHANGED,
    payload: {
      invoiceNumber,
    },
  };
}

const applyInvoiceToLoads = async (dispatch: Dispatch<any>, routingLoadIds: RoutingLoadId[], invoiceNumber: string): Promise<void> => {
  await msyncClientMutation<EditRoutingLoadsResponse>({
    mutation: ApplyInvoiceToLoadsMutation,
    variables: {
      input: {
        ids: routingLoadIds,
        invoiceNumber,
      },
    },
    dispatch,
  });
};

export function handleAssignInvoiceToLoadsApplyClicked(): Thunker {
  return async (dispatch: Dispatch<shame>, getState: () => State.Type) => {
    const state = getState();
    const routingLoadIds = LoadListSelectors.selectedRoutingLoadIds(state);
    const invoiceNumber = LoadListSelectors.enteredInvoiceNumber(state);

    await applyInvoiceToLoads(dispatch, routingLoadIds, invoiceNumber);
  };
}

const setCarrierOnLoads = async (dispatch: Dispatch<any>, routingLoadIds: RoutingLoadId[], carrier: Carrier): Promise<void> => {
  await msyncClientMutation<EditRoutingLoadsResponse>({
    mutation: SetCarrierOnRoutingLoadMutation,
    variables: {
      input: {
        ids: routingLoadIds,
        carrier,
      },
    },
    dispatch,
  });
};

export function handleSetCarrierButtonClicked(): Thunker {
  return async (dispatch: Dispatch<shame>, getState: () => State.Type) => {
    const state = getState();
    const routingLoadIds = LoadListSelectors.selectedRoutingLoadIds(state);
    const carrier = LoadListSelectors.selectedCarrier(state);

    const routingLoadIdsToUpdate = await getRoutingLoadsToUpdateCarrier(dispatch, routingLoadIds, carrier);

    await setCarrierOnLoads(dispatch, routingLoadIdsToUpdate, carrier);
  };
}

async function getRoutingLoadsToUpdateCarrier(dispatch: Dispatch<shame>, routingLoadIds: RoutingLoadId[], carrier: Carrier) {
  const routingLoadIdsToUpdateResponse = await msyncClientQuery<ApplyInvoiceToLoadsResponse>({
    variables: {
      filters: {
        id: { values: routingLoadIds },
      },
    },
    dispatch,
    query: ApplyInvoiceToLoadsQuery,
  });

  if (!routingLoadIdsToUpdateResponse || !routingLoadIdsToUpdateResponse.data || !routingLoadIdsToUpdateResponse.data.getRoutingLoads) {
    throw new Error('unexpected error');
  }
  return routingLoadIdsToUpdateResponse.data.getRoutingLoads.routingLoads.filter(load => load.carrier.rawValue !== carrier).map(load => load.id);
}

export interface SetAssignLoadsToTrailersModalVisibilityAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
    routingLoadIds: RoutingLoadId[];
  };
}

export function showAssignLoadsToTrailersModal(routingLoadIds: RoutingLoadId[]): SetAssignLoadsToTrailersModalVisibilityAction | ExpectedErrorReceivedAction {
  const error = getErrorIfTooManyLoadsSelected(routingLoadIds);
  if (error) {
    return error;
  }

  return {
    type: ActionTypeKeys.LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY,
    payload: {
      showModal: true,
      routingLoadIds,
    },
  };
}

export function hideAssignLoadsToTrailersModal(): SetAssignLoadsToTrailersModalVisibilityAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY,
    payload: {
      showModal: false,
      routingLoadIds: [],
    },
  };
}

const assignLoadsToTrailers = async (dispatch: Dispatch<any>, trailerNumbers: TrailerNumbers): Promise<void> => {
  const assignedLoadsAndTrailers = Object.keys(trailerNumbers).map(routingLoadId => ({ routingLoadId, trailerNumber: trailerNumbers[routingLoadId] }));
  await msyncClientMutation<AssignLoadsToTrailersResponse>({
    mutation: AssignLoadsToTrailersMutation,
    variables: {
      input: {
        assignedLoadsAndTrailers,
      },
    },
    dispatch,
  });
};

export function handleAssignLoadsToTrailersApplyClicked(assignedTrailerNumbers: TrailerNumbers): Thunker {
  return async (dispatch: Dispatch<shame>) => {
    try {
      dispatch(mutationSent(false));

      await assignLoadsToTrailers(dispatch, assignedTrailerNumbers);

      dispatch(hideAssignLoadsToTrailersModal());
    } finally {
      dispatch(mutationResponseReceived());
      dispatch(removeRecentlyReceivedMutationResponse());
    }
  };
}

export interface SetTrailerNumberOnLoadsAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_SET_TRAILER_NUMBER_ON_LOADS;
  payload: {
    routingLoadId: RoutingLoadId;
    trailerNumber: string;
  };
}

export function setTrailerNumberOnLoads(routingLoadId: RoutingLoadId, trailerNumber: string): SetTrailerNumberOnLoadsAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_SET_TRAILER_NUMBER_ON_LOADS,
    payload: {
      routingLoadId,
      trailerNumber,
    },
  };
}

type Clunker = Thunker;

export const selectFirstTrailerNumber = (): Clunker => {
  return async (dispatch: Dispatch<shame>) => {
    // JCN 07/03/2018
    // Wait for a moment for things to settle in react-land,
    // then dispatch the actions to automatically edit
    // the first trailer entry cell.
    await timeout(100);

    dispatch(clickCell(APPLY_LOADS_TO_TRAILERS_TABLE, 0, 0));
    dispatch(clickCell(APPLY_LOADS_TO_TRAILERS_TABLE, 0, 5));
    dispatch(edit(APPLY_LOADS_TO_TRAILERS_TABLE, true, false));
  };
};

export interface NotifyPeakSendButtonClickedAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_SEND_BUTTON;
  payload: {
    routingLoadIds: RoutingLoadId[];
  };
}

const notifyPeak = async (dispatch: Dispatch<any>, selectedRoutingLoadIds: RoutingLoadId[], changedRoutingLoadIds: RoutingLoadId[]): Promise<void> => {
  await msyncClientMutation<NotifyPeakReadyToInvoiceResponse>({
    mutation: NotifyPeakMutation,
    variables: {
      input: {
        selectedRoutingLoadIds,
        changedRoutingLoadIds,
      },
    },
    dispatch,
  });
};

export function handleNotifyPeakSendButtonClicked(selectedRoutingLoadIds: RoutingLoadId[], changedRoutingLoadIds: RoutingLoadId[]): Thunker {
  return async (dispatch: Dispatch<shame>) => {
    try {
      dispatch(mutationSent(false));

      await notifyPeak(dispatch, selectedRoutingLoadIds, changedRoutingLoadIds);

      dispatch(mutationResponseReceived());
      dispatch(hideNotifyPeakModal());
    } finally {
      dispatch(removeRecentlyReceivedMutationResponse());
    }
  };
}

export interface SetNotifyPeakModalVisibilityAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
    routingLoadIds: RoutingLoadId[];
  };
}

export function showNotifyPeakModal(routingLoadIds: RoutingLoadId[]): SetNotifyPeakModalVisibilityAction | ExpectedErrorReceivedAction {
  const error = getErrorIfTooManyLoadsSelected(routingLoadIds);
  if (error) {
    return error;
  }

  return {
    type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY,
    payload: {
      showModal: true,
      routingLoadIds,
    },
  };
}

export function hideNotifyPeakModal(): SetNotifyPeakModalVisibilityAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY,
    payload: {
      showModal: false,
      routingLoadIds: [],
    },
  };
}

export interface ChangesCheckboxClickedAction extends Action {
  type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_CHANGES_CHECKBOX_CLICKED;
  payload: {
    routingLoadId: RoutingLoadId;
    checked: boolean;
  };
}

export function handleChangesCheckboxClicked(routingLoadId: RoutingLoadId, checked: boolean): ChangesCheckboxClickedAction {
  return {
    type: ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_CHANGES_CHECKBOX_CLICKED,
    payload: {
      routingLoadId,
      checked,
    },
  };
}
