import * as React from 'react';
import { ComponentProps } from 'client/app/transportation/cart-tracking/list/suppliers-table-container';
import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME } from 'client/constants';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import PageTitle from 'client/components/page-title';

type OwnProps = ComponentProps;
const tableName = CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME;

const columns: IColumn[] = [
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'Supplier ID',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'name',
    accessor: 'name',
    header: 'Supplier',
    tableEditable: false,
    columnWidth: 50,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'totalCarts',
    accessor: 'totalCarts',
    header: 'Cart Balance',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'cartTrackingStatus',
    accessor: 'cartTrackingStatus',
    header: 'Status',
    tableEditable: false,
    columnWidth: 14,
    sortable: true,
    cellType: CELL_TYPES.STATUS,
    type: TYPES.STRING,
  },
  {
    id: 'lastAdjustmentDate',
    accessor: 'lastAdjustmentDate',
    header: 'Last Update',
    tableEditable: false,
    columnWidth: 16,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
];

export default class SuppliersTable extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  // eslint-disable-next-line no-empty
  noop = () => { };

  public render() {
    return (
      <div id= "mfc-page-content">
        <div className="cart-tracking-suppliers-table-wrapper">
          <PageTitle title="Cart Tracking"/>
          <this.FilterableTable
            table={tableName}
            content={this.props.rows || []}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={this.noop}
            loadMoreRecords={this.noop}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={'No Suppliers'}
            checkable={false}
            list={true}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            disableCreate={true}
            displayLoadingIndicator={true}
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
          />
        </div>
      </div>
    );
  }
}
