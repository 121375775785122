import { SimpleCheckbox } from '../simple-components/simple-checkbox';
import * as React from 'react';

export interface SelectAllProps {
  checked: boolean;
  disabled: boolean;
  onClick: (value: boolean) => void;
  testid: string;
}

export const SelectAll = (props: SelectAllProps) => {
  return (
    <div className="selectable-select-all-checkbox-container">
      <SimpleCheckbox
        disabled={props.disabled}
        label="Select All"
        onChange={props.onClick}
        testid={props.testid}
        value={props.checked}
      />
    </div>
  );
};
