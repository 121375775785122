import * as React from 'react';
import * as CustomerOrderHeaderForm from './customer-order-header-form';
import * as CustomerOrderDetails from './customer-order-details';
import { RecordNav } from 'client/components/record-nav';
import navBuilder from 'client/app/orders/customer-orders/nav-items';

interface Props extends CustomerOrderHeaderForm.OwnProps, CustomerOrderDetails.OwnProps {}

export default function CustomerOrderForm({ handleSubmit, record, formName, confirmOkToSave }: Props) {
  return (
    <div>
      <RecordNav RecordNavItems={record && navBuilder(record.id, record)} disabled={!record || !record.id}/>
      <div className="mfc-form-details-with-sidebar">
        <CustomerOrderDetails.default record={record} formName={formName} confirmOkToSave={confirmOkToSave} handleSubmit={handleSubmit} />
      </div>
      <div className="mfc-form-sidebar">
        <CustomerOrderHeaderForm.default formName={formName} customerOrderId={record.id} record={record}/>
      </div>
    </div>
  );
}
