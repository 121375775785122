import * as React from 'react';
import { Nav, Row } from 'client/components/third-party';

export interface Props {
  RecordNavItems: any[];
  disabled: boolean;
}

class RecordNav extends React.Component<Props, any> {
  public render() {
    const { RecordNavItems, disabled } = this.props;

    return (
      <Row>
        <Nav bsStyle="pills" className="record-nav-bar">
          {RecordNavItems.map((child: any) => React.cloneElement(child, { disabled }))}
        </Nav>
      </Row>);
  }
}

export default RecordNav;
