import { notNil, notNilOrEmptyString } from 'shared/helpers/andys-little-helpers';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as ReduxForm from 'redux-form';
import * as ReportState from 'client/state/reports';
import * as State from 'client/state/state';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getScanProductOptions = (state: State.Type): boolean[] => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return [];
  }

  return [
    formValues[Constants.FormFields.scanBased] === true ? true : undefined,
    formValues[Constants.FormFields.poBased] === true ? false : undefined,
  ].filter(notNil);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];

  const year = formValues[Constants.FormFields.year];
  const beginWeek = formValues[Constants.FormFields.beginWeek];
  const endWeek = formValues[Constants.FormFields.endWeek];
  const season = formValues[Constants.FormFields.season];

  if (!formValues[Constants.FormFields.customerId] ||
    extractSelectedValues(formValues[Constants.FormFields.productIds]).length === 0 ||
    !notNilOrEmptyString(year) ||
    !notNilOrEmptyString(beginWeek) ||
    !notNilOrEmptyString(endWeek) ||
    !notNilOrEmptyString(season) ||
    !sellDepartmentId) {
    return true;
  }

  return false;
};

export const getReportDownloadStatus = (state: State.Type): Mutations.MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
