import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import * as Constants from './constants';
import * as Selectors from './selectors';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GenerateProductReportByCategoryInput, GenerateProductReportBySalesPlanInput } from 'schema/customer-order-report/types';
import { generateProductReplenishmentReportByCategory, generateProductReplenishmentReportBySalesPlan } from './mutation';
import * as ReportActions from '../shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import { convertPrimaryGlobalAllToJustPrimaryGlobal } from 'client/types/primary-global-all';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export enum ActionTypeKeys {
}

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const values = getFormValues(Constants.formName)(state) as Constants.FormValues;
    const filterByCategoryOrSalesPlan = Selectors.getFilterByCategoryOrSalesPlan(state);

    let response: { data: CommonSchemaTypes.GenerateReportMutationResponse } | undefined;

    dispatch(ReportActions.reportDownloadStarted());

    try {
      switch (filterByCategoryOrSalesPlan) {
        case Constants.FilterByCategoryOrSalesPlan.ByCategory:
          if (_.isNil(values.beginDate) || _.isNil(values.endDate) || _.isNil(values.customerId) || _.isNil(values.sellDepartmentId)) {
            throw new Error('Invalid data provided');
          }
          if (!_.isNil(values.salesPlanForPieceTargetId) && (_.isNil(values.pieceTargetBeginDate) || _.isNil(values.pieceTargetEndDate))) {
            throw new Error('Please provide a date range for the piece target if a sales plan is selected.');
          }
          response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, GenerateProductReportByCategoryInput>({
            mutation: generateProductReplenishmentReportByCategory,
            variables: {
              reportByCategoryInput: {
                beginDate: values.beginDate,
                endDate: values.endDate,
                customerId: values.customerId,
                salesPlanForPieceTargetId: values.salesPlanForPieceTargetId,
                pieceTargetBeginDate: values.pieceTargetBeginDate,
                pieceTargetEndDate: values.pieceTargetEndDate,
                sellDepartmentId: values.sellDepartmentId,
                includePoBased: !!values.poBased,
                includeScanBased: !!values.scanBased,
                primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(values.primaryGlobalAll),
                mfcAreaIds: extractSelectedValues(values.mfcAreaIds),
                productClassIds: extractSelectedValues(values.productClassIds),
                productSubClassIds: extractSelectedValues(values.productSubClassIds),
                productIds: extractSelectedValues(values.productIds),
                pieceTargetPrimaryStoresOnly: values.pieceTargetPrimaryStoresOnly ?? false,
                groupBy: values.groupBy,
              },
            },
            dispatch,
          });
          break;
        case Constants.FilterByCategoryOrSalesPlan.BySalesPlan:
          if (_.isNil(values.beginDate) || _.isNil(values.endDate) || _.isNil(values.customerId) || _.isNil(values.salesPlanId)) {
            throw new Error('Invalid data provided');
          }
          if ((_.isNil(values.pieceTargetBeginDate) || _.isNil(values.pieceTargetEndDate))) {
            throw new Error('Please provide a date range for the piece target.');
          }
          response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, GenerateProductReportBySalesPlanInput>({
            mutation: generateProductReplenishmentReportBySalesPlan,
            variables: {
              reportBySalesPlanInput: {
                beginDate: values.beginDate,
                endDate: values.endDate,
                customerId: values.customerId,
                primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(values.primaryGlobalAll),
                mfcAreaIds: extractSelectedValues(values.mfcAreaIds),
                salesPlanId: values.salesPlanId,
                pieceTargetBeginDate: values.pieceTargetBeginDate,
                pieceTargetEndDate: values.pieceTargetEndDate,
                pieceTargetPrimaryStoresOnly: values.pieceTargetPrimaryStoresOnly ?? false,
                groupBy: values.groupBy,
              },
            },
            dispatch,
          });
          break;
      }
      if (!response) {
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;
      window.location.replace(`/report/fileDownload/${reportId}`);

    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
