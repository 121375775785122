import * as _ from 'lodash';
import { PropsExtending, getNumber } from 'shared/types';

/**
 * Given a sequence of elements whose sizes are determined by the given key, split any element which is too big
 * into the minimum number of smaller replicas not to exceed the given target size.
 *
 * @example // loads of carts not to exceed 30 carts per load
 * const unBalancedLoads = [
 *  { trailer: 'S1003', carts: 46 },
 *  { trailer: 'S1015', carts: 29 },
 *  { trailer: 'S1028', carts: 90 },
 * ];
 * const balanced = splitBySize(unBalancedLoads, 'carts', 30);
 * expect(balanced).to.depp.equal([
 *   { trailer: 'S1003', carts: 30 },
 *   { trailer: 'S1003', carts: 16 },
 *   { trailer: 'S1015', carts: 29 },
 *   { trailer: 'S1028', carts: 30 },
 *   { trailer: 'S1028', carts: 30 },
 *   { trailer: 'S1028', carts: 30 },
 * ]);
 */
export const splitBySize = <X, K extends PropsExtending<X, number>>(sequence: X[], sizeProperty: K, maximumSize: Int) =>
  maximumSize <= 0 ? sequence : _.flatMap(sequence, x => {
    const providedValue = getNumber(x, sizeProperty);
    if (providedValue === 0)
      return [{ ...x, [sizeProperty]: 0 }];

    const splits: X[] = Array(_.floor(providedValue / maximumSize)).fill({ ...x, [sizeProperty]: maximumSize });
    const remainingValue = providedValue % maximumSize;
    if (remainingValue > 0)
      splits.push({ ...x, [sizeProperty]: remainingValue });

    return splits;
  });
