import * as React from 'react';
import { Col } from 'client/components/third-party';
import { buildFilterableTable } from 'client/containers/table/table-filter-container';
import { SupplierItemNewCostModal } from '../../supplier-item/new-cost-modal';
import { flowRight } from 'lodash';
import dataContainer from '../../../hoc/data-container';
import { tableDisplayColumns } from '../../../../shared/schemas';
import { tableParentHoc } from 'client/components/table/table-parent';

const supplierItemCostTable = 'supplierItemCosts';

const FilterableTable = buildFilterableTable(supplierItemCostTable);

/*
SupplierItemCostFormGroup.propTypes = {
  record: React.PropTypes.object,
  refetchTable: React.PropTypes.func,
  performDeletion: React.PropTypes.func.isRequired,

  // From dataContainer
  content: React.PropTypes.array,
  loading: React.PropTypes.bool,
  columns: React.PropTypes.array,
  totalCount: React.PropTypes.number,
  totalUnfilteredCount: React.PropTypes.number,
  filteredRecordIds: React.PropTypes.array,
  loadMoreRecords: React.PropTypes.func,
  searchableFields: React.PropTypes.arrayOf(
    React.PropTypes.shape({
      id: React.PropTypes.string,
      name: React.PropTypes.string,
    })
  ),
  availableFilters: React.PropTypes.array,

  // From tableParentHoc
  tableParentInfo: React.PropTypes.object.isRequired,
  dataRequest: React.PropTypes.object,
};
*/
class SupplierItemCostFormGroup extends React.Component<shame, shame> {
  constructor(props) { super(props); this.state = { showNewCostModal: false }; }
  render() {
    const { refetchTable, record } = this.props;
    const onClose = () => this.setState({ showNewCostModal: false });
    const placeholder = 'There are currently no costs for this supplier item.';
    return record && record.id ?
      <Col sm={12}>
        <div className="mfc-record-content" data-testid="cost-info">
          <FilterableTable
            table={supplierItemCostTable}
            content={this.props.content}
            loading={this.props.loading}
            columns={this.props.columns}
            placeholder={placeholder}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            onNewClicked={() => this.setState({ showNewCostModal: true })}
            refetchTable={this.props.refetchTable}
            loadMoreRecords={this.props.loadMoreRecords}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            checkable
            newButtonLabel={'Add Cost'}
            headerMenuItems={[
              { label: 'Delete Selected Rows', onClick: this.props.performDeletion, uncheckRecordFollowingClick: true },
            ]}
            rowMenuItems={[
              { label: 'Delete Row', onClick: this.props.performDeletion, uncheckRecordFollowingClick: true },
            ]}
            tableParentInfo={this.props.tableParentInfo}
            tablePaginated
            dataRequest={this.props.dataRequest}
          >
            <SupplierItemNewCostModal name="supplierItemCosts" isModal show={this.state.showNewCostModal} onClose={onClose} supplierItemId={record.id} refetchTable={refetchTable}/>
          </FilterableTable>
        </div>
      </Col>
    :
      <div/>
    ;
  }
}
const table = 'supplierItemCosts';
const columns = tableDisplayColumns(table);
export default flowRight(
  tableParentHoc(),
  dataContainer({
    table,
    columns,
    scope: ({ record: { id } }) => ([{ field: 'supplierItem', values: [id] }]),
    workbookName: props => `${props.record.product.identifier} - ${props.record.supplier.name}, ${props.record.customer.name} - Cost Info`,
  }),
)(SupplierItemCostFormGroup as shame) as typeof SupplierItemCostFormGroup;
