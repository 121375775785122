import * as React from 'react';
import * as _ from 'lodash';
import { Form, FormGroup, Row, Col } from 'client/components/third-party';
import { LabeledDisplay, LabeledDisplayMoney } from '../../form';
import { getFormValues, change } from 'redux-form';
import { optionsContainerGenerator, optionsContainerWithFilters } from '../../../hoc/options-container-generator';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { Saved } from 'shared/schemas/record';
import { SupplierItem } from 'shared/schemas/supplier-item';
import { Product } from 'shared/schemas/product';
import { SpecializeField } from 'client/components/form/field-wrapper';
import { RackShippingUnit, PalletShippingUnit } from 'shared/types';
import { ActiveFilter } from 'client/types';
import { buildFragment, columns as getColumns, flattenFragment } from 'shared/schemas';
import gqlTag from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { upperFirst } from 'lodash';
import { singular } from 'pluralize';

const Field = SpecializeField(SupplierItem, { alwaysShowErrors: true, hideOptionalLabel: true });
interface DropDownOption { value: number; label: string; }

interface StateProps {
  selectedProductId: number;
  selectedProductDetails?: Saved<Partial<Product>>;
  packsPerShelf?: number;
  shelvesPerRack?: number;
}

interface DispatchProps {
  onCustomerChange: (args: any) => any;
  onPpsChange: (args: any) => any;
  onSprChange: (args: any) => any;
}

interface Props extends StateProps, DispatchProps {
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  formName: string;
  handleSubmit: (args: any) => any;
  record: Saved<SupplierItem>;
  suppliers: DropDownOption[];
  suppliersLoading?: boolean;
  customers: DropDownOption[];
  customersLoading?: boolean;
  products: DropDownOption[];
  productsLoading?: boolean;
}

const getGrossMarginPercentage = (price: number | null | undefined, margin: number | null | undefined) => {
  if (!price || !margin) {
    return `0%`;
  }

  const grossMarginPercentage = 100 * (margin / price);
  return `${_.round(grossMarginPercentage, 1)}%`;
};

function SupplierItemInfoFormGroup(props: Props) {
  const {
    handleSubmit,
    record,
    suppliers,
    customers,
    products,
    onCustomerChange,
    onPpsChange,
    onSprChange,
    selectedProductDetails,
  } = props;
  const selectedSellDepartment = selectedProductDetails?.sellDepartment ? selectedProductDetails.sellDepartment.identifier : null;
  const selectedProductClass = selectedProductDetails?.productClass ? selectedProductDetails.productClass.identifier : null;
  const selectedProductSubClass = selectedProductDetails?.productSubClass ? selectedProductDetails.productSubClass.identifier : null;
  const selectedUpcCode = selectedProductDetails?.primaryUpcCode ? selectedProductDetails.primaryUpcCode.identifier : null;
  // const selectedActivePrice = selectedProductDetails?.activePrice ? selectedProductDetails.primaryUpcCode.identifier : null;
  const selectedActivePrice = selectedProductDetails?.activePrice;
  const selectedSku = selectedProductDetails?.sku;
  const margin = selectedProductDetails?.activePrice && record?.activeCost ? selectedProductDetails.activePrice - record.activeCost : null;
  return (
    <Col sm={12}>
      <Form horizontal onSubmit={handleSubmit} data-testid="supplier-item-info">
        <div data-testid="supplier-item">
          <Row>
            <div className="mfc-form-heading">
              Product Details
          </div>
            <FormGroup data-testid="product-details-row-1">
              <Field name="supplierId" labelColSize={2} inputColSize={8} options={suppliers} loading={props.suppliersLoading} autoFocus={!props.record || !props.record.id} disabled={(props.record.id || 0) > 0}/>
            </FormGroup>
            <FormGroup data-testid="product-details-row-1">
              <Field
                name="customerId"
                labelColSize={2}
                inputColSize={8}
                options={customers}
                loading={props.customersLoading}
                handleChange={onCustomerChange}
                hideOptionalLabel={true}
                required={true}
                disabled={(props.record.id || 0) > 0}
              />
            </FormGroup>
            <FormGroup data-testid="product-details-row-1">
              <Field name="productId" labelColSize={2} inputColSize={8} options={products} loading={props.productsLoading} disabled={(props.record.id || 0) > 0}/>
            </FormGroup>
            <FormGroup data-testid="product-details-row-1">
              <LabeledDisplayMoney labelColSize={2} inputColSize={3} label="Cost" value={record.activeCost} testid="active-cost" />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Sell Department" value={selectedSellDepartment} testid="sell-department" />
            </FormGroup>
            <FormGroup data-testid="product-details-row-2">
              <LabeledDisplayMoney labelColSize={2} inputColSize={3} label="Price" value={selectedActivePrice} testid="active-price" />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Class" value={selectedProductClass} testid="product-class" />
            </FormGroup>
            <FormGroup data-testid="product-details-row-3">
              <LabeledDisplayMoney labelColSize={2} inputColSize={3} label="Gross Margin" value={margin} testid="margin" />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Subclass" value={selectedProductSubClass} testid="product-sub-class" />
            </FormGroup>
            <FormGroup data-testid="product-details-row-4">
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Gross Margin %" value={getGrossMarginPercentage(selectedActivePrice, margin)} testid="margin-percent" />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="UPC" value={selectedUpcCode} testid="upc" />
            </FormGroup>
            <FormGroup data-testid="product-details-row-5">
              <LabeledDisplay labelColSize={7} inputColSize={3} label="SKU" value={selectedSku} testid="sku" />
            </FormGroup>
            <FormGroup data-testid="product-details-row-6" />
          </Row>
          <Row>
            <div className="mfc-form-heading">
              Supplier Details
          </div>
            <FormGroup data-testid="supplier-specs-row-4">
              <Field name="supplierProdIdentifier" labelColSize={2} inputColSize={3} />
            </FormGroup>
            <FormGroup data-testid="supplier-specs-row-5">
              <Field name="fobLocation" labelColSize={2} inputColSize={3} />
            </FormGroup>
          </Row>
          <Row>
            <div className="mfc-form-heading">
              Packing Specs
          </div>
            <FormGroup data-testid="supplier-specs-row-1">
              <Field name="packSize" labelColSize={2} inputColSize={3} />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Customer Pack" value={selectedProductDetails?.packSize} testid="product-pack-size" />
            </FormGroup>
          </Row>
          <Row>
            <div className="mfc-form-heading">
              Primary Shipping Unit
          </div>
            <FormGroup>
              <Field mfcClassName="mfc-primary-shipping-method" label={'Ship by Rack'} name="primaryShippingUnitType" labelColSize={1} inputColSize={3} optionValue={RackShippingUnit} />
            </FormGroup>
            <FormGroup data-testid="supplier-specs-row-2">
              {/* <Field name="packsPerShelf" labelColSize={2} inputColSize={3}  /> */}
              <Field name="packsPerShelf" labelColSize={2} inputColSize={3} handleChange={onPpsChange} />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Customer PPS" value={selectedProductDetails?.packsPerShelf} testid="product-pps" />
            </FormGroup>
            <FormGroup data-testid="supplier-specs-row-3">
              {/* <Field name="shelvesPerRack" labelColSize={2} inputColSize={3}  /> */}
              <Field name="shelvesPerRack" labelColSize={2} inputColSize={3} handleChange={onSprChange} />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Customer SPR" value={selectedProductDetails?.shelvesPerRack} testid="product-spr" />
            </FormGroup>
            <FormGroup data-testid="supplier-specs-row-4">
              <Field name="packsPerRack" labelColSize={2} inputColSize={3} disabled />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Customer PPR" value={selectedProductDetails?.packsPerRack} testid="product-ppr" />
            </FormGroup>
            <FormGroup>
              <Field mfcClassName="mfc-primary-shipping-method" label={'Ship by Pallet'} name="primaryShippingUnitType" labelColSize={1} inputColSize={3} optionValue={PalletShippingUnit} />
            </FormGroup>
            <FormGroup data-testid="ship-by-pallet-row-1">
              <Field name="casesPerPallet" labelColSize={2} inputColSize={3} />
              <LabeledDisplay labelColSize={2} inputColSize={3} label="Customer CPP" value={selectedProductDetails?.casesPerPallet} testid="product-cpp" />
            </FormGroup>
          </Row>
        </div>
      </Form>
    </Col>
  );
}

const withSuppliers = optionsContainerGenerator({ table: 'suppliers', columns: ['identifier', 'name'] });
const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
type WithProductsPropsProduct = Pick<Product, 'identifier' | 'description'>;

interface WithProductsProps {
  regions: WithProductsPropsProduct[];
}

const parentFilter: (ownProps: any) => ActiveFilter[] = (ownProps: any) => {
  const customerId = ownProps[`selectedCustomerId`];
  const filters: ActiveFilter[] = [
    { field: 'customer', values: [customerId].filter(Boolean) },
    { field: 'mfcDistribution', values: [false] },
  ];

  return filters;
};

const withProducts = optionsContainerWithFilters<WithProductsProps>({
  columns: ['identifier', 'description'],
  getFilters: parentFilter,
  table: 'products',
  skip: () => !parentFilter,
});

const withRecordDetails = (table: string, field: string, columns?: string[]) => msyncQuery<{ content: shame }>(gqlTag`
  query WithRecordDetails${_.upperFirst(_.camelCase(table))}($type: RecordType!, $id: Int!) {
    content: find(type: $type, id: $id) {
      id,
      ...${table}DetailFragment
    }
  }
  ${gqlTag`${buildFragment(table, columns || getColumns(table), `${table}DetailFragment`)}`}
  `, {
    skip    : p => _.isNil(p[`${field}Id`]),
    options : p => ({ variables: { type: upperFirst(singular(table)), id: p[`${field}Id`] } }),
    props   : result => result.data.loading || !result?.data?.content ? {} : { [`${field}Details`]: flattenFragment(result.data.content, table) },
  });

const withProductDetails = withRecordDetails('products', 'selectedProduct', [
  'activePrice'    , 'primaryUpcCode', 'sellDepartment', 'productClass'  ,
  'productSubClass', 'sku'           , 'packSize'      , 'packsPerShelf' ,
  'shelvesPerRack' , 'casesPerPallet',
]);

function mapStateToProps(state, { formName, ...ownProps }) {
  const values = getFormValues(formName)(state) as SupplierItem;

  return {
    selectedCustomerId: values?.customerId || null,
    selectedProductId: values?.productId || null,
    packsPerShelf: values?.packsPerShelf || null,
    shelvesPerRack: values?.shelvesPerRack || null,
  };
}

function mapDispatchToProps(dispatch, { formName, ...ownProps }) {
  return {
    onCustomerChange(value) {
      dispatch(change(formName, 'productId', null));
      dispatch(change(formName, 'customerId', value));
    },
    onPpsChange(eventArgs) {
      const pps = Number.parseInt(eventArgs.target ? eventArgs.target.value : `${eventArgs}`);
      const ppr = pps * ownProps.shelvesPerRack;
      dispatch(change(formName, 'packsPerRack', _.isNaN(ppr) ? null : ppr));
      dispatch(change(formName, 'packsPerShelf', _.isNaN(pps) ? null : pps));
    },
    onSprChange(eventArgs) {
      const spr = Number.parseInt(eventArgs.target ? eventArgs.target.value : `${eventArgs}`);
      const ppr = ownProps.packsPerShelf * spr;
      dispatch(change(formName, 'packsPerRack', _.isNaN(ppr) ? null : ppr));
      dispatch(change(formName, 'shelvesPerRack', _.isNaN(spr) ? null : spr));
    },
  };
}

export default flowRight(
  withCustomers,
  connect(mapStateToProps, undefined),
  connect(undefined, mapDispatchToProps),
  withSuppliers,
  withProducts,
  withProductDetails,
)(SupplierItemInfoFormGroup);
