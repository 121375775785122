import * as _ from 'lodash';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as Mutation from '../../sales-plans/sidebar/mutation';

export const handleDownloadExcelReportClicked = (selectedSalesPlanId: number | null): Thunker => {
  return async dispatch => {

    if (_.isNil(selectedSalesPlanId)) {
      throw new Error('Sales plan not selected.');
    }

    dispatch(ReportActions.reportDownloadStarted());
    try {
      const result = await msyncClientMutation<Mutation.DownloadDifferenceReportCalculationSpreadsheetMutationResponse, Mutation.DownloadDifferenceReportCalculationSpreadsheetMutationInput>({
        mutation: Mutation.DownloadDifferenceReportCalculationSpreadsheetMutation,
        variables: {
          salesPlanId: selectedSalesPlanId,
        },
        dispatch,
      });

      const reportId = result.data.response.report.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
