import * as React from 'react';
import { Modal, Form, Row, FormGroup } from 'client/components/third-party';
import { Field } from 'redux-form';
import { LabeledSelect } from 'client/components/form';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { SupplierItemDropDownItem } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';

export interface Props {
  supplierItems: Array<{ id: SupplierItemId, label: string }>;
  invoiceId: number;

  onClose: () => void;
  addVendorChargebackLineItemsFromSupplierItems: (supplierItemIds: SupplierItemId[], invoiceId: number) => Promise<void>;

  // Redux Form
  handleSubmit: (formValues: any) => any;
  reset: () => void;
}

interface FormValues {
  supplierItemIds: SupplierItemId[];
}

export function VendorChargebackAddLineItemsModal(props: Props) {
  function closeAndReset(): void {
    props.onClose();
    props.reset();
  }

  async function onSubmit(formValues: FormValues): Promise<void> {
    await props.addVendorChargebackLineItemsFromSupplierItems(formValues.supplierItemIds, props.invoiceId);
    closeAndReset();
  }

  return (
    <Modal
      key="add-vendor-chargeback-line-item-modal"
      id="add-vendor-chargeback-line-item-modal"
      animation
      backdrop="static"
      show={true}
      onHide={closeAndReset}>
      <Form horizontal onSubmit={props.handleSubmit(onSubmit)} data-testid="add-vendor-chargeback-line-item-modal">
        <GlobalAsyncModalHeader>Add Products</GlobalAsyncModalHeader>
        <Modal.Body>
          <Row>
            {/* <FormGroup className="supplier-order-modal-add-new-product-details"> */}
            <FormGroup className="vendor-chargeback-modal-supplier-items">
              <Field
                name="supplierItemIds"
                inputColSize={10}
                options={props.supplierItems || []}
                horizontalLabel={false}
                valueField="id"
                textFormatter={(item: SupplierItemDropDownItem) => item.label}
                label="Products"
                placeholder="Select Products"
                component={LabeledSelect}
                testid="supplier-item"
                multi
                required>
              </Field>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={closeAndReset} />
          <GlobalSaveButton
            label={'Add'}
            testid={'modal-submit'}
            type="submit"
            disabled={false}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
