import gql from 'graphql-tag';
import { DateTimeStr } from 'shared/types';

export interface Input {
  vendorId: number;
}

export interface QueryResponse {
  getVendor: {
    vendor: {
      id: number;
      identifier: string;
      name: string | null;
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string;
      fax: string;
      contacts: Array<{
        id: number;
        name: string;
        email: string;
        phoneOffice: string;
        phoneCell: string;
        primary: string;
      }>;
      lastModifiedAt: DateTimeStr | null;
    };
  };
}

export const Query = gql`
  query VendorEditFormQuery($vendorId: Int!) {
    getVendor: GetVendor(id: $vendorId) {
      vendor {
        id
        identifier
        name
        address1
        address2
        city
        state
        zip
        country
        phone
        fax
        contacts {
          id
          name
          email
          phoneOffice
          phoneCell
          primary
        }
        lastModifiedAt
      }
    }
  }
`;
