import gql from 'graphql-tag';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { ReceivableOrderReceivingStatus, DateStr, SupplierOrderTypes, CustomerOrderType, OrderMethod, ShippingUnitType, DeliveryMethods, SupplierOrderStatus, DateTimeStr } from 'shared/types';
import { UnifiedReceivableOrderLastPurchaseOrderEmailedInfo } from 'schema/unified-receivable-order/unified-receivable-order-graphql-types';

export interface ReceivingOverviewSidebarVariables {
  id: SupplierOrderId;
}

export interface ReceivingOverviewSidebarResponse {
  response?: {
    id: number;
    arrivalDate: DateStr;
    customerIdentifier: string;
    customerName: string;
    deliveryMethod: DeliveryMethods;
    identifier: string;
    invoiced: boolean;
    invoiceNumber: string | null;
    lastPurchaseOrderEmailedInfo: UnifiedReceivableOrderLastPurchaseOrderEmailedInfo | undefined;
    mails: Array<{
      id: number;
      createdAt: DateTimeStr;
      user: {
        id: number;
        firstName: string;
        lastName: string;
      }
    }>;
    mfcAreaIdentifier: string | null;
    notes: string | null;
    orderMethod: OrderMethod;
    orderStatus: SupplierOrderStatus | null;
    orderType: SupplierOrderTypes | CustomerOrderType;
    receivingStatus: ReceivableOrderReceivingStatus;
    sellDepartmentIdentifier: string;
    shippingUnitType: ShippingUnitType | null;
    storeDeliveryDate: DateStr;
    subSellDepartmentIdentifier: string | null;
    supplierVendorIdentifier: string | null;
    supplierVendorName: string | null;
    scanBased: boolean | null;
  };
}

export const ReceivingOverviewSidebarQuery = gql`
  query ReceivingOverviewSidebarQuery($id: Int!) {
    response: GetUnifiedReceivableOrder(id: $id) {
      id
      identifier
      supplierVendorIdentifier
      supplierVendorName
      customerIdentifier
      customerName
      mfcAreaIdentifier
      sellDepartmentIdentifier
      subSellDepartmentIdentifier
      orderType
      arrivalDate
      receivingStatus
      deliveryMethod
      storeDeliveryDate
      orderMethod
      shippingUnitType
      invoiced
      invoiceNumber
      orderStatus
      notes
      scanBased
      lastPurchaseOrderEmailedInfo {
        firstName
        lastName
        emailedAt
      }
      mails {
        id
        createdAt
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
