import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/customer-order-allocation/customer-order-allocation-types';

export interface PalletInput {
  routePlanId: GraphQLTypes.ReviewStoresOverridePalletsInput['routePlanId'];
  storeId: GraphQLTypes.ReviewStoresOverridePalletsInput['storeId'];
  palletQuantity: GraphQLTypes.ReviewStoresOverridePalletsInput['palletQuantity'];
}

export interface PalletResponse {
  __typename: string;
  overridePallets: {
    __typename: string;
    reviewStoresCustomerOrderAllocation: Pick<GraphQLTypes.ReviewStoresCustomerOrderAllocations, 'storeId' | 'pallets' | 'routePlanId' | 'isOverriddenPallets'> & { __typename: string };
  };
}

export const PalletMutation = gql`
  mutation ReviewStoresOverridePalletsMutation($routePlanId: Int!, $storeId: Int!, $palletQuantity: Int!) {
    overridePallets: ReviewStoresOverridePallets(routePlanId: $routePlanId, storeId: $storeId, palletQuantity: $palletQuantity) {
      reviewStoresCustomerOrderAllocation {
        routePlanId
        storeId
        pallets
        isOverriddenPallets
      }
    }
  }
`;

export interface RackInput {
  routePlanId: GraphQLTypes.ReviewStoresOverrideRacksInput['routePlanId'];
  storeId: GraphQLTypes.ReviewStoresOverrideRacksInput['storeId'];
  rackQuantity: GraphQLTypes.ReviewStoresOverrideRacksInput['rackQuantity'];
}

export interface RackResponse {
  overrideRacks: {
    reviewStoresCustomerOrderAllocation: Pick<GraphQLTypes.ReviewStoresCustomerOrderAllocations, 'storeId' | 'racks'>;
  };
}

export const RackMutation = gql`
  mutation ReviewStoresOverrideRacksMutation($routePlanId: Int!, $storeId: Int!, $rackQuantity: Int!) {
    overrideRacks: ReviewStoresOverrideRacks(routePlanId: $routePlanId, storeId: $storeId, rackQuantity: $rackQuantity) {
      reviewStoresCustomerOrderAllocation {
        routePlanId
        storeId
        racks
        isOverriddenRacks
      }
    }
  }
`;

export interface RemoveStoresInput {
  routePlanId: GraphQLTypes.ReviewStoresRemoveStoresInput['routePlanId'];
  storeIds: GraphQLTypes.ReviewStoresRemoveStoresInput['storeIds'];
}

export interface RemoveStoresResponse {
  removeStores: {
    routePlanId: number;
  };
}

export const RemoveStoresMutation = gql`
  mutation ReviewStoresRemoveStoresMutation($routePlanId: Int!, $storeIds: [Int!]!) {
    removeStores: RemoveStoresFromRoutePlan(routePlanId: $routePlanId, storeIds: $storeIds) {
      routePlanId
    }
  }
`;
