import { tableName, property, hasMany, definePresentation, setSchemaOptions, required } from './dsl';
import { DISPLAY_TYPES, ActiveInactive } from '../types';
import { ProductClass } from './product-class';
import { IRecord } from './record';
import { displayType } from './dsl';

export type SellDepartmentId = Flavor<number, 'sellDepartmentId'>;
export type SellDepartmentIdentifier = string;

@tableName('sellDepartments')
export class SellDepartment implements IRecord {
  static readonly SchemaName = 'SellDepartment';

  id?: SellDepartmentId;

  @hasMany('productClasses')
  @property productClasses: ProductClass[];

  @property @required identifier: SellDepartmentIdentifier;

  @property @required activeStatus: ActiveInactive;
}

definePresentation(SellDepartment, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'Sell Department',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 95,
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  productClasses: {
    formDisplay: false,
  },
});

setSchemaOptions(SellDepartment, {
  softDeletable: true,
});
