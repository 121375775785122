import * as _ from 'lodash';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as PerformanceReportHelpers from 'shared/app/reports/performance';
import * as ReduxForm from 'redux-form';
import * as ReportState from 'client/state/reports';
import * as State from 'client/state/state';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { getDateRangeForDateRangeYearComparison, getDaysInBetween } from 'shared/helpers/date-helpers';
import { PerformanceReportDateDetailLevel, PerformanceReportStoreDetailLevel } from 'shared/types';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getScanProductOptions = (state: State.Type): boolean[] => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues)
    return [];

  return [
    formValues[Constants.FormFields.scanBased] === true ? true : undefined,
    formValues[Constants.FormFields.poBased] === true ? false : undefined,
  ].filter(notNil);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues)
    return true;

  const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
  if (!dateRangeYearComparison)
    return true;

  const dateRanges = getDateRangeForDateRangeYearComparison(formValues[Constants.FormFields.dateRangeYearComparison]!);
  if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate)
    return true;

  const beginDate = dateRanges.dateRange.startDate;
  const endDate = dateRanges.dateRange.endDate;
  const lastYearBeginDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.startDate : undefined;
  const lastYearEndDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.endDate : undefined;

  const dateColumnBehavior = formValues[Constants.FormFields.dateColumnBehavior];
  const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];
  const productGroupingBehavior = formValues[Constants.FormFields.productGroupingBehavior];

  const enabledPerformanceMeasureFields = PerformanceReportHelpers.getEnabledPerformanceMeasureFieldNames(formValues);

  if (!formValues[Constants.FormFields.customerId] ||
    !beginDate ||
    !endDate ||
    (formValues[Constants.FormFields.dateRangeYearComparison]?.compareToPreviousYear &&
      (!lastYearBeginDate || !lastYearEndDate)) ||
    extractSelectedValues(formValues[Constants.FormFields.productIds]).length === 0 ||
    extractSelectedValues(formValues[Constants.FormFields.storeIds]).length === 0 ||
    !formValues[Constants.FormFields.storeDetailLevel] ||
    !formValues[Constants.FormFields.dateDetailLevel] ||
    !productGroupingBehavior ||
    !dateColumnBehavior ||
    enabledPerformanceMeasureFields.length === 0 ||
    _.isNil(formValues[Constants.FormFields.primaryGlobalAll]) ||
    isTheExpectedReportTooLarge(formValues) ||
    !sellDepartmentId) {
    return true;
  }

  return false;
};

export const getReportDownloadStatus = (state: State.Type): Mutations.MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};

export const getExpectedNumberOfGeneratedRows = (formValues: Constants.FormValues) => {
  const storeDetailLevel = formValues[Constants.FormFields.storeDetailLevel];
  const dateDetailLevel = formValues[Constants.FormFields.dateDetailLevel];
  const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];

  let dayFactor = 1;
  if (dateRangeYearComparison) {
    const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
    if (dateRanges && dateRanges.dateRange && dateRanges.dateRange.startDate && dateRanges.dateRange.endDate) {
      const beginDate = dateRanges.dateRange.startDate;
      const endDate = dateRanges.dateRange.endDate;
      dayFactor = (dateDetailLevel === PerformanceReportDateDetailLevel.Day ? getDaysInBetween(beginDate, endDate) : 1);
    }
  }
  const storeFactor = (storeDetailLevel === PerformanceReportStoreDetailLevel.Store ? extractSelectedValues(formValues[Constants.FormFields.storeIds]).length : 1);

  const expectedNumberOfRows = extractSelectedValues(formValues[Constants.FormFields.productIds]).length
  * storeFactor
  * dayFactor;
  return expectedNumberOfRows;
};

export const isTheExpectedReportTooLarge = (formValues: Constants.FormValues) => {
  return false; // getExpectedNumberOfGeneratedRows(formValues) > Constants.MAX_NUMBER_OF_ROWS;
};
