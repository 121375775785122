import { connect, MapDispatchToProps } from 'react-redux';
import { DateStr, ImmutableDateRange, ReplenishmentByProductGroupingOptions } from 'shared/types';
import { getFormValues } from 'redux-form';
import { ReplenishmentByProductUI, ComponentProps as UIProps } from './ui';
import { Thunker } from 'client/types/redux-types';
import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import * as Actions from './actions';
import * as Constants from './constants';
import * as Queries from '../shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as State from 'client/state/state';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import * as Selectors from './selectors';
import * as SharedActions from '../shared/actions';
import { MutationStatus } from 'client/actions/mutations';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { SelectableValue } from 'client/components/selectable/selectable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
}

interface StateProps {
  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;
  pieceTargetBeginDate: DateStr | undefined;
  pieceTargetEndDate: DateStr | undefined;
  customerId: number | undefined;
  downloadButtonDisabled: boolean;
  productClassIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  salesPlanId: number | undefined;
  salesPlanForPieceTargetId: number | undefined;
  scanProductOptions: boolean[] | undefined;
  sellDepartmentId: number | undefined;
  filterByCategoryOrSalesPlan: Constants.FilterByCategoryOrSalesPlan;
  scanBased: boolean;
  poBased: boolean;
  reportDownloadStatus: MutationStatus;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
  pieceTargetPrimaryStoresOnly: boolean | undefined;
  groupBy: ReplenishmentByProductGroupingOptions;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const filterByCategoryOrSalesPlan = Selectors.getFilterByCategoryOrSalesPlan(state);

  if (!formValues) {
    return {
      beginDate: undefined,
      endDate: undefined,
      pieceTargetBeginDate: undefined,
      pieceTargetEndDate: undefined,
      customerId: undefined,
      downloadButtonDisabled: true,
      productClassIds: undefined,
      productSubClassIds: undefined,
      salesPlanId: undefined,
      salesPlanForPieceTargetId: undefined,
      scanProductOptions: undefined,
      sellDepartmentId: undefined,
      filterByCategoryOrSalesPlan,
      scanBased: false,
      poBased: false,
      reportDownloadStatus: MutationStatus.Initial,
      primaryGlobalAll: undefined,
      pieceTargetPrimaryStoresOnly: false,
      groupBy: ReplenishmentByProductGroupingOptions.GroupByProduct,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);
  const scanProductOptions = Selectors.getScanProductOptions(state);

  return {
    beginDate: formValues[Constants.FormFields.beginDate],
    endDate: formValues[Constants.FormFields.endDate],
    pieceTargetBeginDate: formValues[Constants.FormFields.pieceTargetBeginDate],
    pieceTargetEndDate: formValues[Constants.FormFields.pieceTargetEndDate],
    customerId: formValues[Constants.FormFields.customerId],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    salesPlanId: formValues[Constants.FormFields.salesPlanId],
    salesPlanForPieceTargetId: formValues[Constants.FormFields.salesPlanForPieceTargetId],
    scanProductOptions,
    filterByCategoryOrSalesPlan,
    scanBased: formValues[Constants.FormFields.scanBased],
    poBased: formValues[Constants.FormFields.poBased],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
    pieceTargetPrimaryStoresOnly: formValues[Constants.FormFields.pieceTargetPrimaryStoresOnly],
    groupBy: formValues[Constants.FormFields.groupBy],
  };
};

interface DispatchProps {
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handlePieceTargetDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handleDownloadExcelReportClicked(): Thunker;
  handleCustomerChanged: (customerId: number) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => void;
  handleProductClassesFilterChanged: (productClassIds: number[]) => void;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => void;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleSalesPlanChanged: (salesPlanId: number) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleCustomerChanged: SharedActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDateRangeChanged: SharedActions.handleDateRangeChanged(Constants.formName, Constants.FormFields),
  handlePieceTargetDateRangeChanged: SharedActions.handlePieceTargetDateRangeChanged(Constants.formName, Constants.FormFields),
  handleSellDepartmentChanged: SharedActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handlePOBasedFilterChanged: SharedActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleSalesPlanChanged: SharedActions.handleSalesPlanChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  Queries.MfcAreasQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  SharedQueries.SalesPlanQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.primaryGlobalAll]: PrimaryGlobalAll.All,
      [Constants.FormFields.filterByCategoryOrSalesPlan]: Constants.FilterByCategoryOrSalesPlan.ByCategory,
      [Constants.FormFields.groupBy]: ReplenishmentByProductGroupingOptions.GroupByProduct,
    },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withMfcAreas,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({onlyIncludeParentReplenishmentProducts: true}),
  SharedQueries.withSalesPlans,
  UserParamsMappers.withReplenishmentByProductReportUserParamMappers,
)(ReplenishmentByProductUI) as Component<OwnProps>;

export const ReplenishmentByProduct = (props: OwnProps) => propToComponent(component, props);
