import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as TableActions from '../../actions/table';
import { FilterableTableUI, FilterableTableProps } from 'client/components/table/filterable-table';
import assertCompatible from 'shared/helpers/assert-compatible';
import { IColumn } from 'client/components/table/column';
import { ActiveSort, AvailableSearchField, AvailableFilter, ButtonClickHandler } from 'client/types';
import { OnRowSelect } from 'client/components/table/table-ui';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import * as GlobalState from 'client/state/state';
import { createSelector } from 'reselect';
import { Return, SORT_TYPES } from 'shared/types';
import { buildTableStateModule } from 'client/state/tables';
import { orThrowBug } from 'shared/helpers';
import { PropsWithChildren } from 'react';

export type OwnProps = PropsWithChildren<{
  // come from somewhere
  table            : string                                               ,
  columns          : IColumn[]                                            ,
  excelColumns    ?: IColumn[]                                            ,
  loading         ?: boolean                                              ,
  searchableFields : AvailableSearchField[]                               ,
  availableFilters : AvailableFilter[]                                    ,
  onRowSelect     ?: OnRowSelect                                          ,
  onRowFocused    ?: (row: Dictionary<any>) => any                        ,
  checkable       ?: boolean                                              ,
  list            ?: boolean                                              ,
  headerMenuItems ?: RowMenuItem[]                                        ,
  rowMenuItems    ?: RowMenuItem[]                                        ,
  onNewClicked    ?: ButtonClickHandler                                   ,
  newButtonLabel  ?: string                                               ,
  buttons         ?: Array<{ label: string, onClick: ButtonClickHandler }>,

  // come from presentational component using this
  placeholder?: string,

  // come from top level data-table container
  content                        : any[]                  ,
  refetchTable                  ?: () => void             ,
  refetchStats                  ?: () => Promise<void>    ,
  totalUnfilteredCount           : number                 ,
  totalCount                     : number                 ,
  filteredRecordIds              : number[]               ,
  loadMoreRecords               ?: () => void             ,
  disableCreate                 ?: boolean                ,
  displayLoadingIndicator       ?: boolean                ,
  tableParentInfo                : TableParentInfo        ,
  tablePaginated                 : boolean                ,
  confirmOkToSave               ?: () => Promise<boolean> ,
  handleSubmit                  ?: () => Promise<boolean> ,
  dataRequest                   ?: MsyncDataRequest       ,
  disableTableDownload          ?: boolean                ,
  footerData                    ?: { [k: string]: any }   ,
  footerProps                   ?: { [k: string]: any }   ,
  supplementalRecordBarComponent?: React.FunctionComponent,
  alwaysDisplayTable            ?: boolean                ,
  noDataText                    ?: string                 ,
}>;

const mapStateToProps = (table: string, convertSortFields: (input: ActiveSort[]) => Array<{ sortField: string; sortOrder: SORT_TYPES }>) => {
  const TableStateHelpers = buildTableStateModule(table);
  return (state: GlobalState.Type) => {
    const tableState = TableStateHelpers.tableStateLens.get(state);
    return {
      tablePageNumber  :                   TableStateHelpers.tablePageNumber (tableState )       ,
      activeFilters    :                   TableStateHelpers.activeFilters   (tableState )       ,
      activeSearch     :                   TableStateHelpers.activeSearch    (tableState )       ,
      activeSearchText :                   TableStateHelpers.activeSearchText(tableState )       ,
      numCheckedRecords:                   TableStateHelpers.checkedRecordIds(tableState ).length,
      sortFields       : convertSortFields(TableStateHelpers.activeSortFields(tableState))       ,// Not quite the same as "ActiveSort" type, as the foreignColumn has been made part of the field
    };
  };
};

const mapDispatchToProps = (table: string) => (dispatch: TableActions.DispatchFunction) => ({
  onFilterSelected      : (field: string, value: unknown        ) => dispatch(TableActions.toggleFilter      (table, field, value      )),
  onSearch              : (text: string                         ) => dispatch(TableActions.setSearchText     (table, text              )),
  onSearchColumnSelected: (column: string                       ) => dispatch(TableActions.setSearchField    (table, column            )),
  onTableSort           : (field: string, multiColumn: boolean  ) => dispatch(TableActions.toggleSort        (table, field, multiColumn)),
  onToggleFilter        : (field: string, value: unknown        ) => dispatch(TableActions.toggleFilter      (table, field, value      )),
  onClearAllFilters     : (                                     ) => dispatch(TableActions.clearAllFilters   (table                    )),
  setTablePageNumber    : (pageNumber: Int, row?: number        ) => dispatch(TableActions.setTablePageNumber(table, pageNumber, row   )),
});

type StateProps = Return<Return<typeof mapStateToProps>>;
type DispatchProps = Return<Return<typeof mapDispatchToProps>>;
assertCompatible<FilterableTableProps, StateProps & DispatchProps & OwnProps>();

/**
 * Want to do some conversion to the sort info stored in Redux state. This function
 * returns a reselect function that will only do the conversion if the input
 * changes (prevent re-rendering).
 *
 * This is all wrapped up in a maker function to avoid having to worry about multiple
 * tables on the same screen (not sure if it's really an issue, but don't want to
 * investigate it now).
 */
const makeConvertSortFieldsFunc = () => createSelector(
  _.identity,
  (activeSortFields: ActiveSort[]) => activeSortFields.map(f => !f ? orThrowBug('Missing sort field') : {
    sortField: f.foreignColumn ? `${f.sortField}.${f.foreignColumn}` : f.sortField,
    sortOrder: f.sortOrder,
  }),
);

export const withFilterAndSortCapabilities = (uiTable: string) =>
  SomeTableComponent =>
    connect<StateProps, DispatchProps, OwnProps>(mapStateToProps(uiTable, makeConvertSortFieldsFunc()), mapDispatchToProps(uiTable))
    (SomeTableComponent) as (props: OwnProps) => JSX.Element; /*DVM 2021 09 28 - was "any" before I came here.*/

export const buildFilterableTable          = (uiTable: string) => withFilterAndSortCapabilities(uiTable)(FilterableTableUI);
