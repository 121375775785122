import 'reflect-metadata';
import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { OutboundMail } from 'shared/schemas/outbound-mail';

@tableName('outboundMailAttachments')
export class OutboundMailAttachment implements IRecord {
  id?: number;
  @property @required filename: string;
  @property contentId?: string;
  @property @required contentType: string;
  @property @required content: Buffer;

  @belongsTo('outboundMails')
  @property @required outboundMail: OutboundMail;
  outboundMailId: number;
}

definePresentation(OutboundMailAttachment, {
  filename: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  content: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  contentId: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  contentType: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  outboundMail: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
});
