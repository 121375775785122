import * as FileSaver from 'client/utils/filesaver';
import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { flowRight } from 'lodash';
import { FormGroup, Form, Row, Col } from 'client/components/third-party';
import { SpecializeField } from 'client/components/form';
import { optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { DateStr, toDateStr, OrderMethod, ShippingUnitType, SORT_TYPES, CustomerOrderType, KnownSellDepartment, toDateTimeStr, KnownVendor, DateTimeStr, QueryAsExcelColumn } from 'shared/types';
import { ActiveFilter } from 'client/types';
import { CustomerOrder, CustomerOrderId, CustomerOrderIdentifier } from 'shared/schemas/customer-order';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import * as Validators from 'shared/validators';
import * as FormHelpers from 'client/helpers/form-helpers';
import { SalesPlanId, SalesPlan } from 'shared/schemas/sales-plan';
import { SellDepartmentId, SellDepartment } from 'shared/schemas/sell-department';
import { CustomerId, Customer, CustomerIdentifier } from 'shared/schemas/customer';
import { SubSellDepartment } from 'shared/schemas/sub-sell-department';
import { MfcArea } from 'shared/schemas/mfc-area';
import { Saved } from 'shared/schemas/record';
import { getUserName } from 'shared/schemas/user';
import { yearOf, formatDateTime } from 'shared/helpers/date-helpers';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { send } from 'shared/send';
import { getAutoSelectableSalesPlanId } from 'client/app/orders/customer-orders/overview/auto-selectable-sales-plan-helpers';
import { downloadExcelAction } from 'client/app/orders/customer-orders/overview/customer-order-excel-helpers';
import { Vendor } from 'shared/schemas/vendor';
import * as _ from 'lodash';
import { isExternalDistributionOrder } from 'shared/app/customer-order-helpers';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import * as Actions from './actions';
import { msyncQuery } from 'client/hoc/graphql/query';
import { CustomerOrderInvoiceQueryResponse, CustomerOrderInvoiceQuery, CustomerOrderInvoiceQueryInput } from '../product-worksheet/customer-order-invoice-query';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as DownloadExcel from 'client/utils/download-excel';
import { AlertModal, AlertModalSituations } from 'client/components/alert-modal';
import { customerOrderHeaderLens } from 'client/state/state';
import * as ImportCustomerOrderSpreadsheetActions from 'client/actions/import-customer-order-spreadsheet';
import { ImportCustomerOrderSpreadsheetModal } from 'client/app/orders/customer-orders/overview/import-customer-order-spreadsheet-modal';
import { createSelector } from 'reselect';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import gql from 'graphql-tag';

const Field = SpecializeField(CustomerOrder, { horizontalLabel: false });

type Props = OwnProps & DataProps & DispatchProps & StateProps & InvoiceProps;

export interface OwnProps {
  formName: string;
  handleSubmit: () => Promise<boolean>;
  customerOrderId?: CustomerOrderId;
  record: CustomerOrder;
}

interface DataProps {
  customers: Array<Saved<Customer>>;
  vendors: Array<Saved<Vendor>>;
  sellDepartments: Array<Saved<SellDepartment>>;
  subSellDepartments: Array<Saved<SubSellDepartment>>;
  mfcAreas: Array<Saved<MfcArea>>;
  salesPlans: Array<Saved<SalesPlan>>;
}

interface StateProps {
  alertModal: {
    isShown: boolean,
    situation: string | undefined;
  };
  customerOrderIdentifier?: CustomerOrderIdentifier;
  customerIdentifier?: CustomerIdentifier;
  customerId?: CustomerId;
  sellDepartmentId?: SellDepartmentId;
  orderDate?: DateStr;
  shippingUnitType?: ShippingUnitType;
  salesPlanId?: SalesPlanId;
  orderType?: CustomerOrderType;
  scanBasedDisabled: boolean;
  received?: boolean;
  vendorId?: number;
  showCreateInvoice: boolean;
  showViewInvoice: boolean;
}

interface DispatchProps {
  onSellDepartmentChange: (value: any) => void;
  onOrderTypeChange: (value: any) => void;
  setSalesPlan(salesPlanId: SalesPlanId): void;
  onVendorChange: (value: any) => void;
  onOrderMethodChange: (value: any) => void;
  createInvoiceMenuItemClicked: (customerOrderId: number) => void;
  downloadCustomerOrderSpreadsheet: (customerOrderId: number) => void;
  viewInvoiceMenuItemClicked: (invoiceId: number) => void;
  downloadExcel: (args: {
    dataRequest: GraphQLQuery.MsyncDataRequest,
    columns: QueryAsExcelColumn[],
    setInProgress: () => void;
    setNotInProgress: () => void;
    handleExpectedError: (args: { message: string, debugInfo: any }) => void;
  }) => any;
  showAlert: (situation: string) => void;
  hideAlert: () => void;
  onImportCustomerOrderSpreadsheetOpenModalButtonClicked: () => void;
  showReplaceOrderModal: () => void;
}

interface CustomerOrderForm {
  identifier?: CustomerOrder['identifier'];
  customer?: {
    identifier?: Customer['identifier'];
  };
  sellDepartmentId?: CustomerOrder['sellDepartmentId'];
  customerId?: CustomerOrder['customerId'];
  orderDate?: CustomerOrder['orderDate'];
  shippingUnitType?: CustomerOrder['shippingUnitType'];
  salesPlanId?: CustomerOrder['salesPlanId'];
  orderType?: CustomerOrder['orderType'];
  vendorId?: CustomerOrder['vendorId'];
  received?: boolean;
  scanBased?: boolean;
}

const getAlertModalInfo = createSelector(
  [
    (state: any) => customerOrderHeaderLens(state).alertModalVisible,
    (state: any) => customerOrderHeaderLens(state).alertModalSituation,
  ],
  (modalVisible, situation) => {
    return {
      isShown: modalVisible,
      situation,
    };
  }
);

const mapStateToProps = (state: any, ownProps: OwnProps & DataProps & InvoiceProps): StateProps => {
  const values: CustomerOrderForm = getFormValues(ownProps.formName)(state);
  let unassignedSellDepartmentId = 0;
  const externalDist = isExternalDistributionOrder(values.orderType);
  if (externalDist && ownProps.sellDepartments) {
    const unassignedSellDepartment = ownProps.sellDepartments.find(s => s.identifier === KnownSellDepartment.Unassigned);
    if (!unassignedSellDepartment) {
      throw new Error('unassigned sell department not found');
    }
    unassignedSellDepartmentId = unassignedSellDepartment.id;
  }

  const vendorIdentifier = ownProps.record.vendor
    ? ownProps.record.vendor.identifier
    : undefined;

  return {
    customerOrderIdentifier: values.identifier,
    customerIdentifier: values.customer ? values.customer.identifier : undefined,
    sellDepartmentId: (externalDist && unassignedSellDepartmentId) ? unassignedSellDepartmentId : values.sellDepartmentId,
    customerId: values.customerId,
    orderDate: values.orderDate ? toDateStr(values.orderDate) : undefined,
    shippingUnitType: values.shippingUnitType,
    salesPlanId: values.salesPlanId,
    orderType: values.orderType,
    scanBasedDisabled: values.orderType === CustomerOrderType.PO,
    vendorId: values.vendorId,
    received: values.received,
    showCreateInvoice: !ownProps.record.invoiced && vendorIdentifier === KnownVendor.MFC && !values.scanBased && !!ownProps.record.id,
    showViewInvoice: !!ownProps.invoiceId,
    alertModal: getAlertModalInfo(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps: OwnProps & DataProps & StateProps): DispatchProps => {
  const { formName, sellDepartments, orderType } = ownProps;
  return {
    onSellDepartmentChange(value) {
      const sellDepartment = sellDepartments.find(s => s.id === value);
      if (!sellDepartment) {
        console.error('Sell dept unresolvable:' + value, sellDepartments);
        throw new Error('Sell dept unresolvable: ' + value);
      }
      if (sellDepartment.identifier === 'INDOOR FLORAL') {
        dispatch(change(formName, 'orderMethod', OrderMethod.Pack));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Pallet));
      } else if (sellDepartment.identifier === 'OUTDOOR GARDEN') {
        dispatch(change(formName, 'orderMethod', OrderMethod.ShippingUnit));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Rack));
      } else if (sellDepartment.identifier === 'MISC') {
        dispatch(change(formName, 'orderMethod', OrderMethod.Pack));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.NotApplicable));
      }
      dispatch(change(formName, 'sellDepartmentId', value));
      dispatch(change(formName, 'subSellDepartmentId', null));
    },
    setSalesPlan(salesPlanId: SalesPlanId) {
      dispatch(change(formName, 'salesPlanId', salesPlanId));
    },
    onOrderTypeChange(value) {
      if (value === CustomerOrderType.PO) {
        dispatch(change(formName, 'scanBased', false));
      } else if (orderType === CustomerOrderType.PO) {
        dispatch(change(formName, 'scanBased', true));
      } else if (isExternalDistributionOrder(value)) {
        const sellDepartment = sellDepartments.find(s => s.identifier === KnownSellDepartment.Unassigned);
        if (!sellDepartment) {
          console.error('Sell dept unresolvable:' + value, sellDepartments);
          throw new Error('Sell dept unresolvable: ' + value);
        }
        dispatch(change(formName, 'sellDepartmentId', sellDepartment.id));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Pallet));
        dispatch(change(formName, 'orderMethod', OrderMethod.Pack));
        dispatch(change(formName, 'salesPlanId', null));
      }
      dispatch(change(formName, 'orderType', value));
    },
    onVendorChange(value) {
      dispatch(change(formName, 'vendorId', value));
    },
    onOrderMethodChange(value) {
      dispatch(change(formName, 'orderMethod', value));
      if (value === OrderMethod.ShippingUnit) {
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Rack));
      }
    },
    createInvoiceMenuItemClicked: (customerOrderId: number) => dispatch(Actions.createInvoiceMenuItemClicked(customerOrderId)),
    viewInvoiceMenuItemClicked: (invoiceId: number) => dispatch(Actions.viewInvoiceMenuItemClicked(invoiceId)),
    downloadExcel: args => {
      dispatch(DownloadExcel.downloadExcel(args));
    },
    downloadCustomerOrderSpreadsheet: (customerOrderId: number) => dispatch(Actions.downloadCustomerOrderSpreadsheet(customerOrderId)),
    showAlert: (situation: string) => {
      dispatch(Actions.replaceCustomerOrderMenuItemClicked(situation));
    },
    hideAlert: () => {
      dispatch(Actions.handleAlertModalCancelButtonClicked());
    },
    onImportCustomerOrderSpreadsheetOpenModalButtonClicked: () => {
      dispatch(Actions.handleAlertModalCancelButtonClicked());
      dispatch(ImportCustomerOrderSpreadsheetActions.importCustomerOrderSpreadsheetOpenModalButtonClicked());
    },
    showReplaceOrderModal: () => {
      dispatch(ImportCustomerOrderSpreadsheetActions.importCustomerOrderSpreadsheetOpenModalButtonClicked());
    },
  };
};

interface ShippingUnitOption {
  id: string;
  identifier: string;
}

const downloadPdfAction = async (args: { customerOrderId: CustomerOrderId, customerOrderIdentifier: CustomerOrderIdentifier, fileNameSuffix: string }) => {
  const pdfBlob = await send({
    url: `/report/itemAllocation/${args.customerOrderId}`,
    method: 'GET',
    asBlob: true,
  });

  const filename = `${args.customerOrderIdentifier} - ${args.fileNameSuffix}` || 'Customer Order';

  FileSaver.saveAs(pdfBlob, `${filename}.pdf`);
};

const menuItems = (props: Props) => {
  // TODO: Reselect?
  const items = [
    {
      label: 'Allocation Sheet',
      action: async (setInProgress: SetInProgress) => {
        setInProgress(true);
        try {
          await downloadPdfAction({
            customerOrderId: props.customerOrderId!, // TODO: Don't like the usage of ! here in production code. If we know there's an ID here it should be possible to get Typescript to know that as well
            customerOrderIdentifier: props.customerOrderIdentifier!,
            fileNameSuffix: '',
          });
        } finally {
          setInProgress(false);
        }
      },
    },
    {
      label: 'Download Customer Order',
      action: async (setInProgress: SetInProgress) => {
        setInProgress(true);
        try {
          if (props.record.id) {
            await props.downloadCustomerOrderSpreadsheet(props.record.id);
          }
        } finally {
          setInProgress(false);
        }
      },
    },
    ...(!!props.invoiceId
      ? [{
        label: 'View Invoice',
        action: async () => {
          if (props.invoiceId) {
            props.viewInvoiceMenuItemClicked(props.invoiceId);
          }
        },
      }]
      : props.showCreateInvoice
        ? [{
          label: 'Create Invoice',
          action: async (setInProgress: SetInProgress) => {
            setInProgress(true);
            if (props.record.id)
              await props.createInvoiceMenuItemClicked(props.record.id);

            setInProgress(false);
          },
        }]
        : []),
    {
      label: 'Replace Customer Order',
      action: async (setInProgress: SetInProgress) => {
        setInProgress(true);
        try {
          return  props.invoiceId                                         ? props.showAlert('alreadyInvoiced')
            :     props.received && props.record.orderStatus === 'Routed' ? props.showAlert('alreadyReceivedAndRouted')
            :     props.received                                          ? props.showAlert('alreadyReceived')
            :                       props.record.orderStatus === 'Routed' ? props.showAlert('alreadyRouted')
            :                                                               props.showReplaceOrderModal();
        } finally {
          setInProgress(false);
        }
      },
    },
    {
      label: `To ${props.record.orderMethod === 'Shipping Unit' ? 'Packs' : 'Carts'}`,
      action: async (setInProgress: SetInProgress) => {
        setInProgress(true);
        await msyncClientMutation({
          mutation: gql`
            mutation BridgeRequest($input: BridgeRequestInput!) {
              result: BridgeRequest(input: $input) {
                result
              }
            }
          `,
          variables: {
            input: {
              params: {
                command: props.record.orderMethod === 'Shipping Unit' ? 'caseify' : 'rackify',
                co: props.record.id,
              },
            },
          },
          dispatch: () => {return undefined as any;},
          refetchQueries: [],
        });
        // not a very reacty way to do this, but the architecture of this system makes doing it correct too high-friction on such short notice.  workarounds and hacks ensue, technical debt, already high, continues to accrue.
        location.reload();
      },
    },
  ];

  const sellDepartment = props.sellDepartments.find(s => s.id === props.sellDepartmentId);
  if (sellDepartment?.identifier === KnownSellDepartment.OutdoorGarden) {
    items.push({
      label: 'Customer Order Summary',
      action: downloadExcelAction(props),
    });
  }
  return items;
};

class CustomerOrderHeaderForm extends React.Component<Props, {}> {

  componentWillReceiveProps(nextProps: OwnProps & DataProps & StateProps & DispatchProps) {
    if (!nextProps.setSalesPlan) {
      return;
    }

    const oldCustomerId = this.props.customerId || null;
    const oldCustomerOrderId = this.props.customerOrderId || null;
    const oldOrderDate = this.props.orderDate || null;
    const oldSellDepartmentId = this.props.sellDepartmentId || null;
    const oldOrderType = this.props.orderType || null;
    const salesPlan = getAutoSelectableSalesPlanId({...nextProps, oldCustomerId, oldCustomerOrderId, oldOrderDate, oldSellDepartmentId, oldOrderType});
    if (!!salesPlan) {
      nextProps.setSalesPlan(salesPlan.id);
    }
  }

  onInvoiceIdentifierClicked = () => {
    if (this.props.invoiceId) {
      this.props.viewInvoiceMenuItemClicked(this.props.invoiceId);
    }
  }

  render() {
    const alertModalSituations: AlertModalSituations = {
      alreadyInvoiced: {
        title: 'Order Already Invoiced',
        message: 'You cannot replace an order that has been invoiced.',
        buttons: [
          { label: 'OK', action: this.props.hideAlert },
        ],
      },
      alreadyReceivedAndRouted: {
        title: 'Order Already Received and Routed',
        message: 'You are changing a customer order that has already been received and routed. After import, be sure to notify others who may be affected.',
        buttons: [
          { label: 'Continue', action: this.props.onImportCustomerOrderSpreadsheetOpenModalButtonClicked },
          { label: 'Cancel', action: this.props.hideAlert },
        ],
      },
      alreadyReceived: {
        title: 'Order Already Received',
        message: 'You are changing a customer order that has already been received. After import, be sure to notify others who may be affected.',
        buttons: [
          { label: 'Continue', action: this.props.onImportCustomerOrderSpreadsheetOpenModalButtonClicked },
          { label: 'Cancel', action: this.props.hideAlert },
        ],
      },
      alreadyRouted: {
        title: 'Order Already Routed',
        message: 'You are changing a customer order that has already been routed. After import, be sure to notify others who may be affected.',
        buttons: [
          { label: 'Continue', action: this.props.onImportCustomerOrderSpreadsheetOpenModalButtonClicked },
          { label: 'Cancel', action: this.props.hideAlert },
        ],
      },
    };

    const isCreated = !!this.props.customerOrderId;
    const isExternalDistribution = isExternalDistributionOrder(this.props.orderType);
    // When creating a supplier order, you can select N/A as a valid
    // shipping unit. After a supplier order has been saved, we want
    // to prevent the shipping unit to be changed to N/A.
    const filteredShippingUnitTypes = Object.values(ShippingUnitType)
      .filter(shippingUnitType => {
        if (!isCreated) {
          return true;
        }

        if (shippingUnitType !== ShippingUnitType.NotApplicable) {
          return true;
        }

        if (this.props.shippingUnitType === ShippingUnitType.NotApplicable) {
          return true;
        }

        return false;
      })
      .map((shippingUnitType): ShippingUnitOption => {
        return {
          id: shippingUnitType,
          identifier: shippingUnitType,
        };
      });

    const orderTypes = Object.values(CustomerOrderType);
    // No External Distribution in dropdown if customer order exists already and is set to a non-distribution type
    if (isCreated && !isExternalDistribution) {
      _.pull(orderTypes, CustomerOrderType.ExternalDistribution);
    }

    const orderTypesForDropDown = orderTypes.map(ot => {
      return { id: ot, identifier: ot };
    });

    return (
      <Form horizontal onSubmit={this.props.handleSubmit}>
        { this.props.alertModal.isShown && <AlertModal
          visible={this.props.alertModal.isShown}
          situation={this.props.alertModal.situation}
          alertModalSituations={alertModalSituations}
        /> }
        { this.props.customerId && this.props.customerOrderId && <ImportCustomerOrderSpreadsheetModal customerOrderId={this.props.customerOrderId} /> }
        <HiddenFocusField />
        <div data-testid="customer-order">
          <Row>
            <FormGroup>
              <Field
                name="identifier"
                inputColSize={6}
                validators={[Validators.CAPITALIZED, Validators.REQUIRED_IF_EDITING]}
                normalize={FormHelpers.NormalizePoNumbers}
                autoFocus={!this.props.customerId}
              />
              {this.props.customerOrderId && this.props.customerOrderIdentifier &&
                <SimpleMenu
                  label="Actions"
                  className="customer-order-actions-menu"
                  menuItems={menuItems(this.props)}
                />}
            </FormGroup>
            <FormGroup>
              <Field name="orderType" inputColSize={6} options={orderTypesForDropDown} textFormatter={value => value.identifier} optionValue={this.props.orderType} handleChange={this.props.onOrderTypeChange} disabled={isExternalDistribution && isCreated} />
              <Field name="scanBased" inputColSize={6} disabled={this.props.scanBasedDisabled}/>
            </FormGroup>
            <FormGroup>
              <Field name="sellDepartment" inputColSize={12} options={this.props.sellDepartments} placeholder={'Select Department'} handleChange={this.props.onSellDepartmentChange} disabled={isExternalDistribution}/>
            </FormGroup>
            {!isExternalDistribution && this.props.subSellDepartments && this.props.subSellDepartments.length > 0 &&
              <FormGroup>
                <Field name="subSellDepartment" inputColSize={12} options={this.props.subSellDepartments} required clearable={false} />
              </FormGroup>
            }
            <FormGroup>
              <Field name="customer" inputColSize={6} options={this.props.customers} disabled={isCreated}/>
              <Field name="mfcArea" inputColSize={6} options={this.props.mfcAreas} placeholder="Select MFC Area" disabled={!this.props.mfcAreas || !this.props.mfcAreas.length || isExternalDistribution} autoSetFirstOptionIfPossible={!isCreated} clearable />
            </FormGroup>
            {isExternalDistribution &&
              <FormGroup>
                  <Field name="vendor" inputColSize={12} placeholder="Select Vendor" options={this.props.vendors} handleChange={this.props.onVendorChange} required clearable={false}/>
              </FormGroup>
            }
            <FormGroup>
              <Field name="orderMethod" inputColSize={6} placeholder="Select Order Method" disabled={isCreated || isExternalDistribution} handleChange={this.props.onOrderMethodChange}/>
              <Field name="shippingUnitType" inputColSize={6} placeholder="Select Shipping Unit" options={filteredShippingUnitTypes} textFormatter={(value: ShippingUnitOption) => value.identifier} optionValue={this.props.shippingUnitType} />
            </FormGroup>
            <FormGroup>
              <Field name="orderDate" inputColSize={6} />
              {isExternalDistribution &&
                <Field name="distributionArrivalDate" inputColSize={6} />
              }
              {!isExternalDistribution &&
                <Field name="supplierOrderArrivalDate" inputColSize={6} disabled />
              }
            </FormGroup>
            <FormGroup>
              {isExternalDistribution &&
                <Col sm={6}>
                    <SimpleCheckbox
                      testid="received-checkbox"
                      value={this.props.received !== undefined ? this.props.received : false} // Just for types - this should never be undefined on a distribution order
                      onChange={_.noop}
                      label="Received"
                      disabled
                    />
                </Col>
              }
            </FormGroup>
            <FormGroup>
              <Field name="salesPlan" inputColSize={12} options={this.props.salesPlans} disabled={!this.props.customerId || !this.props.orderDate || isExternalDistribution} />
            </FormGroup>
            <FormGroup>
              <Field name="notes" inputColSize={12} />
            </FormGroup>
            {this.props.record.lastModifiedAt && <FormGroup>
              <Col sm={12}>
                <Row>
                  <div className="mfc-form-stacked-label">Last Modified</div>
                </Row>
                <Row className="mfc-order-status-details">
                  <Col sm={12} className="status">
                    <div className={classnames('status-pill', 'neutral', {ellipsis: true})}>
                        {getUserName(this.props.record.lastModifiedUser)} – {this.props.record.lastModifiedAt ? formatDateTime(toDateTimeStr(this.props.record.lastModifiedAt), 'MM/DD/YYYY') : ''}
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup> }
            <FormGroup>
              <Col sm={12}>
                <Row>
                  <div className="mfc-form-stacked-label">Invoice</div>
                </Row>
                <Row className="mfc-order-status-details">
                  <Col sm={12} className="status">
                    {getInvoicedStatusText({
                      vendorIdentifier: this.props.record.vendor ? this.props.record.vendor.identifier : undefined,
                      scanBased: this.props.record.scanBased,
                      invoiceIdentifier: this.props.invoiceIdentifier,
                      invoiced: this.props.record.invoiced,
                      invoiceId: this.props.invoiceId,
                      invoicedAt: this.props.invoicedAt,
                      invoicedBy: this.props.invoicedBy,
                      onInvoiceIdentifierClicked: this.onInvoiceIdentifierClicked,
                    })}
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Row>
        </div>
      </Form>
    );
  }

  static defaultProps = {
    mfcAreas: [],
    customers: [],
    sellDepartments: [],
  };
}

const getInvoicedStatusText = (args: { vendorIdentifier: string | undefined, scanBased: boolean, invoiceIdentifier: string | undefined, invoiced: boolean, invoiceId: number | undefined, invoicedAt: DateTimeStr | undefined, invoicedBy: string | undefined, onInvoiceIdentifierClicked: () => void }) => {
  // If it doesn't have MFC as the vendor, or it is scan based, it isn't invoiceable
  if (!args.vendorIdentifier || args.vendorIdentifier !== KnownVendor.MFC || args.scanBased) {
    return getStatusWithText('N/A', false);
  }

  // If it is invoiceable and has an invoice record, it was invoiced in our system (even if it wasn't sent, thus why we check this before the invoiced flag)
  if (args.invoiced && args.invoiceIdentifier && args.invoiceId) {
    return getStatusWithText(<span>#<a onClick={args.onInvoiceIdentifierClicked}>{args.invoiceIdentifier}</a> - {args.invoicedBy} - {formatDateTime(args.invoicedAt, 'MM/DD/YYYY')}</span>, true);
  }

  // If it is invoiced, but we have no invoice ID in Msync, then it must have been invoiced in VBills and synced over
  if (args.invoiced) {
    return getStatusWithText('Invoiced in VBills', true);
  }

  // It is invoiceable but no record has been created
  return getStatusWithText('Not Yet Invoiced', false);
};

const getStatusWithText = (textToDisplay: string | JSX.Element, shouldStatusBeGreen: boolean) => {
  return <div className={classnames('status-pill', shouldStatusBeGreen ? 'success' : 'neutral', { ellipsis: true })}>
          {textToDisplay}
        </div>
  ;
};

const mfcAreaFilters = (ownProps: Props) => {
  const filters: ActiveFilter[] = [];
  if (ownProps.customerId) {
    filters.push({ field: 'customer', values: [ownProps.customerId]});
  }
  if (ownProps.sellDepartmentId) {
    filters.push({ field: 'sellDepartment', values: [ownProps.sellDepartmentId]});
  }
  return filters;
};

const salesPlanFilters = (ownProps: Props) => {
  const filters: ActiveFilter[] = [];

  if (ownProps.orderDate) {
    const thisYear = yearOf(ownProps.orderDate);
    const lastYear = thisYear - 1;
    const nextYear = thisYear + 1;

    filters.push({
      field: 'year', values: [
        lastYear.toString(),
        thisYear.toString(),
        nextYear.toString(),
    ]});
  }

  if (ownProps.customerId) {
    filters.push({ field: 'customer', values: [ownProps.customerId]});
  }
  return filters;
};

const subSellDepartmentFilters = (ownProps: Props) => {
  const filters: ActiveFilter[] = [];
  if (ownProps.sellDepartmentId) {
    filters.push({ field: 'sellDepartment', values: [ownProps.sellDepartmentId]});
  }
  return filters;
};

const vendorFilters = (ownProps: Props) => {
  const filters: ActiveFilter[] = [];
  if (ownProps.customerId) {
    filters.push({ field: 'customer', values: [ownProps.customerId]});
  }
  return filters;
};

interface InvoiceProps {
  invoiceId: number | undefined;
  invoicedAt: DateTimeStr | undefined;
  invoicedBy: string | undefined;
  invoiceIdentifier: string | undefined;
}

const withCustomerOrderInvoice = msyncQuery<CustomerOrderInvoiceQueryResponse, Props, {}, CustomerOrderInvoiceQueryInput>(CustomerOrderInvoiceQuery, {
  alias: 'withCustomerOrderInvoice',
  skip(ownProps) {
    return _.isNil(ownProps.customerOrderId);
  },
  options(ownProps) {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId!, // The "skip" above will prevent this from being null/undefined by the time it gets here
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props(props): InvoiceProps {
    const { data } = props;
    if (data.loading || _.isNil(data.customerOrder) || _.isNil(data.customerOrder.customerOrderInvoice)) {
      return {
        invoiceId: undefined,
        invoicedAt: undefined,
        invoicedBy: undefined,
        invoiceIdentifier: undefined,
      };
    }
    const invoice = data.customerOrder.customerOrderInvoice;
    return {
      invoiceId: invoice.id,
      invoiceIdentifier: invoice.identifier,
      invoicedAt: invoice.firstDownloadedAt || invoice.emailedAt || invoice.ediInvoicedAt || undefined,
      invoicedBy: invoice.firstDownloadedByUser || invoice.emailedByUser || invoice.ediInvoicedByUser || undefined,
    };
  },
});

const withMfcAreaOptions = optionsContainerWithFilters({ table: 'mfcAreas', columns: ['identifier'], getFilters: mfcAreaFilters, skip: (ownProps: Props) => !ownProps.customerId || !ownProps.sellDepartmentId });
const withCustomerOptions = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
const withSellDepartmentOptions = optionsContainerGenerator({ table: 'sellDepartments', columns: ['identifier'] });
const withSubSellDepartmentOptions = optionsContainerWithFilters({ table: 'subSellDepartments', columns: ['identifier'], getFilters: subSellDepartmentFilters, skip: (ownProps: Props) => !ownProps.sellDepartmentId });
const withSalesPlanOptions = optionsContainerWithFilters({
  table: 'salesPlans',
  columns: ['year', 'identifier', 'customer', 'startWeek', 'endWeek', 'sellDepartment'],
  getFilters: salesPlanFilters,
  sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year'}],
});
const withVendorOptions = optionsContainerWithFilters({
  table: 'vendors',
  columns: ['identifier'],
  getFilters: vendorFilters,
  sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier'}],
});

export default flowRight(
  withSellDepartmentOptions,
  withCustomerOptions,
  withCustomerOrderInvoice, // Must come before connect/mapStateToProps
  connect(mapStateToProps, undefined),
  withVendorOptions, // Must come after connect/mapStateToProps
  withSalesPlanOptions, // Do not re-order: We need sales plans in mapDispatchToProps, but we need props from mapStateToProps
  connect(undefined, mapDispatchToProps),
  withSubSellDepartmentOptions,
  withMfcAreaOptions,
)(CustomerOrderHeaderForm);
