import * as _ from 'lodash';
import * as React from 'react';
import gql from 'graphql-tag';
import { buildAdminDetailPage } from 'client/containers/admin/admin-detail-page';
import { SupplierOrderForm } from './supplier-order-form';
import SupplierOrderStatusToggle from '../record-bar-status-toggle';
import { SupplierOrderStatus, ReceivableOrderReceivingStatus } from 'shared/types';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { withReceivingEmailNotificationMutation, EmailReceivingNotificationMutationProps } from 'client/hoc/with-receiving-email-notification-mutation';
import { NotificationArgs } from 'client/hoc/with-notification';
import { SupplierOrderComparator } from 'shared/app/orders/supplier-order-comparator';

/**
 * NOTE: Intentionally not using the buildFragment function to build this up.
 *       Primarily doing that so we can get access to details of the SOPs, which
 *       don't come back in the default fragment. Also allows for trimming out a bunch
 *       of stuff that's not needed here.
 */
const SupplierOrderDetailsQuery = gql`
    query GetSupplierOrder($type: RecordType, $id: Int!) {
      data: GetSupplierOrder(type: $type, id: $id) {
        id
        ...SupplierOrderDetailsFragment
        lastPurchaseOrderEmailedInfo {
          firstName
          lastName
          emailedAt
        }
      }
    }

    fragment SupplierOrderDetailsFragment on SupplierOrder {
      updatedAt
      identifier
      orderStatus
      orderType
      orderMethod
      shippingUnitType
      plannedArrivalDate
      deliveryMethod
      supplierLocation {
        id
        identifier
      }
      invoiceNumber
      invoiced
      notes
      receivingStatus
      invoice
      storeDeliveryDate
      warehouse
      orderDate
      emailedAt
      lastModifiedAt
      revised
      invoiceTotal
      supplier {
        id
        name
      }
      customer {
        id
        name
      }
      sellDepartment {
        id
        identifier
      }
      subSellDepartment {
        id
        identifier
      }
      mfcArea {
        id
        identifier
      }
      receivableOrder {
        id
        identifier
      }
      supplierOrderProductGroups {
        id
        identifier
        description
        isCombo
        orderMethod
        packsPerShippingUnit
        packQuantity
        shippingUnitQuantity
        desiredShippingUnitQuantity
        receivableOrderDetail {
          id
          identifier
        }
        supplierOrderProducts { # Need this for determining if a notification email should be sent to transportation
          id
          packQuantity
          productDescription
          supplierItem {
            id
          }
        }
      }
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  `;

const notificationOptions: NotificationArgs<SupplierOrder, { receivingStatusChanged: boolean }> = {
  onlyDisplayOnce: true,
  shouldDisplay: (previousRecord?: SupplierOrder, currentRecord?: SupplierOrder) => {
    if (currentRecord?.orderStatus !== SupplierOrderStatus.Sent) {
      return { shouldDisplay: false };
    }

    if (previousRecord && currentRecord) {
      if (previousRecord.receivingStatus !== currentRecord.receivingStatus) {
        const message =
          <div>
            This Supplier Order is now {currentRecord.receivingStatus}. A notification email has been sent to Transportation. Please coordinate as appropriate.
          </div>;
        return {
          shouldDisplay: true,
          message,
          data: {
            receivingStatusChanged: true,
          },
        };
      }

      if (currentRecord.receivingStatus === ReceivableOrderReceivingStatus.FullyReceived) {
        const changed = !SupplierOrderComparator.isEqualForReceivingReceiptPurposes(previousRecord, currentRecord);
        if (changed) {
          return {
            shouldDisplay: true,
            message: <div>A notification email has been sent to Transportation regarding the changes made to this Fully Received supplier order. Please coordinate as appropriate.</div>,
            data: {
              receivingStatusChanged: false,
            },
          };
        }
      }
    }
    return { shouldDisplay: false };
  },
  beforeDisplay: async (record?: SupplierOrder, props?: EmailReceivingNotificationMutationProps, data?: { receivingStatusChanged: boolean } ): Promise<void> => {
    if (record?.receivableOrderId && props?.emailNotificationOfReceivingStatus) {
      await props.emailNotificationOfReceivingStatus(record.receivableOrderId, data?.receivingStatusChanged ?? false);
    }
  },
};

const Overview = buildAdminDetailPage({
  table: 'SupplierOrder',
  formName: 'supplierOrderForm',
  FormComponent: SupplierOrderForm,
  OptionalStatusToggle: SupplierOrderStatusToggle,
  query: SupplierOrderDetailsQuery,
  saveConfirmationOptions: {
    onlyAskOnce: true,
    shouldDisplay: (record?: SupplierOrder) => !_.isNil(record) && !_.isNil(record.orderStatus) && record.orderStatus !== SupplierOrderStatus.Draft,
    message: (record?: SupplierOrder) => {
      if (_.isNil(record) || _.isNil(record.orderStatus)) {
        return {
          title: '',
          body: '',
        };
      }

      switch (record?.orderStatus) {
        case SupplierOrderStatus.Sent: {
          return {
            title: 'Order Already Sent',
            body: 'You\'re changing details for an order that has already been sent to the supplier. Be sure to resend a revised PO, and notify others who may be affected.',
          };
        }
        default:
          return {
            title: '',
            body: '',
          };
      }
    },
  },
  notificationOptions,
});

export default withReceivingEmailNotificationMutation(Overview as shame);
