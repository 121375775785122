import { reportingForm } from 'client/components/report-user-params/reporting-form';
import * as _ from 'lodash';
import * as Actions from './actions';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as ReactRedux from 'react-redux';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportUserParams from 'client/components/report-user-params';
import * as Selectors from './selectors';
import * as SharedOrderActions from 'client/app/orders/reports/shared/actions';
import * as State from 'client/state/state';
import * as UI from './ui';
import * as UserParamsMappers from './user-params-mappers';
import * as WrappedContainer from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import { SalesPlanIdentifier, SelectableValue } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerId: number | undefined;
  season: SalesPlanIdentifier | undefined;
  downloadButtonDisabled: boolean;
  poBased: boolean;
  productClassIds: SelectableValue | undefined;
  productIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  reportDownloadStatus: Mutations.MutationStatus;
  scanBased: boolean;
  scanProductOptions: boolean[] | undefined;
  sellDepartmentId: number | undefined;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (!formValues) {
    return {
      customerId: undefined,
      season: undefined,
      downloadButtonDisabled: true,
      poBased: false,
      productClassIds: undefined,
      productIds: undefined,
      productSubClassIds: undefined,
      reportDownloadStatus: Mutations.MutationStatus.Initial,
      scanBased: false,
      scanProductOptions: undefined,
      sellDepartmentId: undefined,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);
  const scanProductOptions = Selectors.getScanProductOptions(state);

  return {
    customerId: formValues[Constants.FormFields.customerId],
    season: formValues[Constants.FormFields.season],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    poBased: formValues[Constants.FormFields.poBased],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productIds: formValues[Constants.FormFields.productIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    scanBased: formValues[Constants.FormFields.scanBased],
    scanProductOptions,
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
  };
};

interface DispatchProps {
  handleCustomerChanged: (customerId: number) => void;
  handleDownloadExcelReportClicked(): ReduxTypes.Thunker;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (productClassIds: number[]) => ReduxTypes.Thunker;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => ReduxTypes.Thunker;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => ReduxTypes.Thunker;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  handleCustomerChanged: SharedOrderActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handlePOBasedFilterChanged: SharedOrderActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedOrderActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedOrderActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedOrderActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleSellDepartmentChanged: SharedOrderActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  ReduxFormProps &
  ReportUserParams.UserParamsMapperProps &
  DispatchProps;

assertCompatible<UI.ComponentProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
  }),
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({ onlyIncludeParentReplenishmentProducts: true }),
  UserParamsMappers.withPerformanceReportUserParamMappers,
)(UI.SupplierFutureCommitmentReportUI) as Component<OwnProps>;

export const SupplierFutureCommitmentReport = (props: OwnProps) => WrappedContainer.propToComponent(component, props);
