import * as State from 'client/state/transportation-reports';
import * as HmgOpsActions from './hmg-ops/actions';

const ActionTypeKeys = {
  ...HmgOpsActions.ActionTypeKeys,
};

export default function reducer(state = State.InitialState, action: HmgOpsActions.ActionTypes): State.Type {
  switch (action.type) {
    case ActionTypeKeys.HmgOpsReportTypeSelectionChanged: {
      return State.hmgOpsReportTypeLens.set(state, action.payload);
    }
    default:
      return state;
  }
}
