import gql from 'graphql-tag';
import { DateStr } from 'shared/types';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';

export interface CartTrackingSuppliersQueryResponse {
  response?: {
    records: Array<{
      id: number;
      identifier: string;
      name: string;
      totalCarts?: number;
      cartTrackingStatus: SupplierCartTrackingStatus;
      lastAdjustmentDate?: DateStr;
    }>;
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

export const CartTrackingSuppliersQuery = gql`
  query CartTrackingSuppliersQuery($type: RecordType = Supplier, $filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    response: findAllWithTotal(type: $type, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      records {
        ... on Supplier {
          id
          identifier
          name
          totalCarts
          cartTrackingStatus
          lastAdjustmentDate
        }
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
`;

// Alas... The attempt to separate business logic from the data layer is being put on hold
// Replacing this, (which would hit a custom graphql endpoint and go through the supplier repository)
// With a simple findAll
// export const CartTrackingSuppliersQuery =
//   query($filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
//     response: FindSuppliersForCartTracking(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
//       records {
//         id,
//         ...CartTrackingSupplierFields
//       }
//       ids
//       totalCount
//       totalUnfilteredCount
//     }
//   }
//   fragment CartTrackingSupplierFields on SupplierForCartTracking {
//     identifier
//     name
//     cartTrackingStatus
//     totalCarts
//     lastAdjustmentDate
//   }
