import { VendorListUI, UIProps } from './ui';
import { wrapComponent } from 'client/hoc/hoc';
import { connect, ConnectedProps } from 'react-redux';
import { buildMapStateToPropsFunction } from 'client/containers/msync-redux';
import * as VendorListState from './state';
import { TableParentInfo, tableParentHoc } from 'client/components/table/table-parent';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import * as VendorListQuery from './query';
import * as _ from 'lodash';
import { FilterSpecificationInput } from 'shared/types';
import { AvailableSearchField, ActiveSearch, ActiveSort, AvailableFilter } from 'client/types';
import * as Actions from './actions';
import { EMPTY_ARRAY } from 'client/constants';
import { convertSearchAllToSpecificFields } from 'client/helpers/table-helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {

}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: FilterSpecificationInput[];
  tablePageNumber: number;
  tableName: string;
  searchableFields: AvailableSearchField[];
}

// We don't want to worry about global state for each of our selectors.
// buildMapStateToProps allows us to specify a lens to get our local state
// from our global state, and a mapStateToProps function that receives the
// local state. This makes it easier to write selectors that don't need to
// redundantly grab from global state.
const mapStateToProps = buildMapStateToPropsFunction<VendorListState.Type, StateProps, OwnProps>(
  VendorListState.genericTableActions.tableStateLens,
  (state): StateProps => {
    return {
      tableName: VendorListState.tableName,
      activeSortFields: VendorListState.genericTableActions.activeSortFields(state),
      activeSearch: VendorListState.genericTableActions.activeSearch(state),
      activeFilters: VendorListState.genericTableActions.activeFilters(state),
      tablePageNumber: VendorListState.genericTableActions.tablePageNumber(state),
      searchableFields: VendorListState.genericTableActions.availableSearchFields(state),
    };
  },
);

const mapDispatchToProps = {
  onNewClicked: Actions.newRecordButtonClicked,
  onRowSelected: Actions.rowSelected,
};

interface QueryProps {
  vendorRows: any[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  availableFilters: AvailableFilter[];
  dataRequest: MsyncDataRequest;
}

const createVendorRows = (vendors: VendorListQuery.Vendor[]): UIProps['vendorRows'] => {
  return vendors.map(vendor => {
    return {
      id: vendor.id,
      identifier: vendor.identifier,
      name: vendor.name || '-',
    };
  });
};

const withVendorRows = msyncQuery<VendorListQuery.QueryResponse, StateProps & WithTableParentProps, QueryProps, VendorListQuery.Input>(VendorListQuery.Query, {
  alias: 'withVendorRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps) {
    return {
      fetchPolicy: 'network-only',
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: convertSearchAllToSpecificFields(ownProps.activeSearch, ownProps.searchableFields),
        limit: ownProps.tableParentInfo.rowsPerPage ?? 0,
        offset: (ownProps.tableParentInfo.rowsPerPage ?? 0) * ownProps.tablePageNumber,
      },
    };
  },
  props(props): QueryProps {
    const { data } = props;

    return {
      vendorRows: data.getVendors ? createVendorRows(data.getVendors.vendors) : [],
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getVendors ? data.getVendors.ids : [],
      totalCount: data.getVendors ? data.getVendors.totalCount : 0,
      totalUnfilteredCount: data.getVendors ? data.getVendors.totalUnfilteredCount : 0,
      dataRequest: {
        query: VendorListQuery.ExcelQuery,
        variables: {
          filters: props.ownProps.activeFilters,
          sort: props.ownProps.activeSortFields,
          search: convertSearchAllToSpecificFields(props.ownProps.activeSearch, props.ownProps.searchableFields),
        },
        workbookName: 'Vendors',
      },
      availableFilters: EMPTY_ARRAY,
    };
  },
});

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  WithTableParentProps &
  QueryProps &
  ConnectedProps<typeof connector>;

export const VendorListContainer = wrapComponent(VendorListUI)<OwnProps, CombinedProps>(
  tableParentHoc(),
  connector,
  withVendorRows,
);
