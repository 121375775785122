import * as React from 'react';
import * as _ from 'lodash';
import { Col } from 'client/components/third-party';
import SupplierOrderSummaryTable from 'client/app/orders/customer-orders/related-supplier-orders/supplier-order-summary-table';
import * as SupplierOrdersTableHoc from './supplier-orders-table-hoc';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { RELATED_SUPPLIER_ORDERS_TABLE_NAME } from 'client/constants';

export interface OwnProps {
  params: {
    id: string;
  };
  confirmOkToSave: () => any;
  dataRequest: MsyncDataRequest;
  performDeletion: (ids: number[]) => Promise<boolean>;
}

export const RelatedSupplierOrdersTable = SupplierOrdersTableHoc.makeComponent({ component: SupplierOrderSummaryTable, isInModal: false, isRelatedToCustomerOrder: true });

export default class RelatedSupplierOrders extends React.Component<OwnProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
    };

    // It's important that these functions be methods on the component class, versus
    // anonymous functions created in the render() function. If a different anonymous
    // function is passed in each time that will cause re-renders.
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  private showModal() {
    this.setState({ showModal: true });
  }

  private hideModal() {
    this.setState({ showModal: false });
  }

  public render() {
    const customerOrderId = _.parseInt(this.props.params.id);

    return (
      <Col sm={12} className="customer-order-sub-tab-page">
        <div data-testid="related-supplier-orders">
          <RelatedSupplierOrdersTable
            tableName={RELATED_SUPPLIER_ORDERS_TABLE_NAME}
            customerOrderId={customerOrderId}
            onNewClicked={this.showModal}
            hideModal={this.hideModal}
            isAttachSupplierOrdersModalShown={this.state.showModal}
            cached
            performDeletion={this.props.performDeletion}
            confirmOkToSave={this.props.confirmOkToSave}
            dataRequest={this.props.dataRequest}
          />
        </div>
      </Col>
    );
  }
}
