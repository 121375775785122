import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { RoutePlanAction, RoutePlanGQLResult, RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { formatDateTime } from 'shared/helpers/date-helpers';
import { toDateTimeStr } from 'shared/types';

export const PrintingListQueryName = 'RoutePlanPrintingListQuery';
export const PrintingListQuery = gql`
  query RoutePlanPrintingListQuery($id: Int!) {
    getRoutePlan: GetRoutePlan(id: $id) {
      routePlan {
        id
        loadCount
      }
    }
    printingList: GetRoutePlan(id: $id) {
      routePlan {
        id
        customerIdentifier
        actions {
          createRoutePackets {
            ...actionFieldsFragment
          }
          downloadDittyCards {
            ...actionFieldsFragment
          }
          emailMerchandiserAsn {
            ...actionFieldsFragment
          }
          createCustomerAsn {
            ...actionFieldsFragment
          }
          downloadStoreDeliverySpreadsheet {
            ...actionFieldsFragment
          }
          downloadStoreAsn {
            ...actionFieldsFragment
          }
          sendLoadsToLoadmaster {
            ...actionFieldsFragment
          }
          emailRouteSheetsToPeak {
            ...actionFieldsFragment
          }
          createPalletReport {
            ...actionFieldsFragment
          }
        }
      }
    }
  }
  fragment actionFieldsFragment on RoutePlanAction {
    completed
    completedAt
    user {
      id
      firstName
      lastName
    }
  }
`;

export interface PrintingListProps {
  isLoading: boolean;
  hasLoads: boolean;
  actions: {
    createRoutePackets?: string;
    downloadDittyCards?: string;
    emailMerchandiserAsn?: string;
    createCustomerAsn?: string;
    downloadStoreDeliverySpreadsheet?: string;
    sendLoadsToLoadmaster?: string;
    emailRouteSheetsToPeak?: string;
    createPalletReport?: string;
    downloadStoreAsn?: string;
  };
}

function formatRoutePlanAction(action: RoutePlanAction) {
  if (!action.completed) {
    return '';
  }

  const formattedDateTime = formatDateTime(toDateTimeStr(action.completedAt), 'MM/DD/YYYY [at] hh:mm:ss A zz');

  const formattedName = action.user
    ? `by ${action.user.firstName} ${action.user.lastName.substr(0, 1)}`
    : '';

  return `Action taken on ${formattedDateTime} ${formattedName}`;
}

interface PrintingListGQLResult {
  getRoutePlan: {
    routePlan: {
      id: number;
      loadCount: number;
    },
  };

  printingList: {
    routePlan: {
      customerIdentifier: RoutePlanGQLResult['customerIdentifier'];
      actions: RoutePlanGQLResult['actions'];
    };
  };
}

interface OwnProps {
  routePlanId: RoutePlanId;
}

export const WithRoutePlanPrintingList = msyncQuery<PrintingListGQLResult, OwnProps, PrintingListProps>(PrintingListQuery, {
  alias: 'withRoutePlanPrintingList',
  skip(ownProps: OwnProps) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps: OwnProps): { variables } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): PrintingListProps {
    const { data } = props;
    const printingList = data ? data.printingList : undefined;
    const routePlan = printingList ? printingList.routePlan : undefined;

    if (data.loading) {
      return {
        isLoading: true,
        hasLoads: false,
        actions: {},
      };
    }
    if (routePlan === undefined || routePlan.actions === undefined)  {
      return {
        isLoading: false,
        hasLoads: false,
        actions: {},
      };
    }

    return {
      isLoading: false,
      hasLoads: !!(data.getRoutePlan && data.getRoutePlan.routePlan.loadCount > 0),
      actions: {
        createRoutePackets: formatRoutePlanAction(routePlan.actions.createRoutePackets),
        downloadDittyCards: formatRoutePlanAction(routePlan.actions.downloadDittyCards),
        emailMerchandiserAsn: formatRoutePlanAction(routePlan.actions.emailMerchandiserAsn),
        createCustomerAsn: formatRoutePlanAction(routePlan.actions.createCustomerAsn),
        downloadStoreDeliverySpreadsheet: formatRoutePlanAction(routePlan.actions.downloadStoreDeliverySpreadsheet),
        downloadStoreAsn: formatRoutePlanAction(routePlan.actions.downloadStoreAsn),
        sendLoadsToLoadmaster: formatRoutePlanAction(routePlan.actions.sendLoadsToLoadmaster),
        emailRouteSheetsToPeak: formatRoutePlanAction(routePlan.actions.emailRouteSheetsToPeak),
        createPalletReport: formatRoutePlanAction(routePlan.actions.createPalletReport),
      },
    };
  },
});
