import * as React from 'react';
import { Button } from 'client/components/third-party';

class DropdownToggle extends React.Component<shame> {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const { children, disabled, block, onMouseOver } = this.props;
    return (
      <Button block={block} disabled={disabled} onClick={this.handleClick} onMouseOver={onMouseOver} role="button">
        {children}
      </Button>
    );
  }

  handleClick(event) {
    if (this.props.multi) {
      event.preventDefault();
    }

    this.props.onClick(event);
  }
}
/*
DropdownToggle.propTypes = {
  children: React.PropTypes.node,
  onClick: React.PropTypes.func,
  onMouseOver: React.PropTypes.func,
  multi: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  block: React.PropTypes.bool,
};
*/
(DropdownToggle as shame).defaultProps = {
  multi: false,
  disabled: false,
  block: false,
};

export default DropdownToggle;
