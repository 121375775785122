import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  id: number | null;
  identifier: number | null;
  targetRoute: string;
}

export const IdentifierCell = (props: Props) => {
  const target = `${props.targetRoute}/${props.id}`;
  const label = props.identifier;

  return (
    <div className="table-identifier-cell">
      <div className="new-window-link">
        <a href={target} target="_new"><i className="fa fa-external-link"></i></a>
      </div>
      <div className="same-window-link">
        <Link to={target}>{label}</Link>
      </div>
    </div>
  );
};
