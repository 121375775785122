import { Lens } from '@atomic-object/lenses';
import { ImportableCustomerOrder } from 'shared/file-parsers/excel/customer-order-spreadsheet-parser';

export enum CustomerOrderImportState {
  Initial = 'Initial',
  Parsing = 'Parsing',
  Validating = 'Validating',
  Validated = 'Validated',
  Importing = 'Importing',
  Imported = 'Imported',
  Failed = 'Failed',
}

export enum CustomerOrderImportStep {
  Validation = 'Validation',
  Import = 'Import',
}

interface ImportCustomerOrderSpreadsheetState {
  readonly importCustomerOrderSpreadsheetModalShown: boolean;
  readonly customerOrderImportState: CustomerOrderImportState;
  readonly importableCustomerOrder: ImportableCustomerOrder | undefined;
  readonly failedStep: CustomerOrderImportStep | undefined;
  readonly errorMessages: string[] | undefined;
  readonly customerOrderId: number | undefined;
  readonly existingCustomerOrderId: number | undefined;
  readonly scanBasedOrPoBased: string | undefined;
}

export type Type = ImportCustomerOrderSpreadsheetState;

export const InitialState: Readonly<ImportCustomerOrderSpreadsheetState> = Object.freeze({
  importCustomerOrderSpreadsheetModalShown: false,
  customerOrderImportState: CustomerOrderImportState.Initial,
  importableCustomerOrder: undefined,
  failedStep: undefined,
  errorMessages: undefined,
  customerOrderId: undefined,
  existingCustomerOrderId: undefined,
  scanBasedOrPoBased: undefined,
});

export const importCustomerOrderSpreadsheetModalShown = Lens.from<Type>().prop('importCustomerOrderSpreadsheetModalShown');
export const customerOrderImportState = Lens.from<Type>().prop('customerOrderImportState');
export const customerOrderId = Lens.from<Type>().prop('customerOrderId');
export const importableCustomerOrder = Lens.from<Type>().prop('importableCustomerOrder');
export const failedStep = Lens.from<Type>().prop('failedStep');
export const errorMessages = Lens.from<Type>().prop('errorMessages');
export const existingCustomerOrderId = Lens.from<Type>().prop('existingCustomerOrderId');
export const scanBasedOrPoBased = Lens.from<Type>().prop('scanBasedOrPoBased');
