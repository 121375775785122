import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/vendor/vendor-graphql-types';
import { SortInput, FilterSpecificationInput, SearchInput } from 'shared/types';

export interface Input {
  sort: SortInput[];
  filters: FilterSpecificationInput[];
  search: SearchInput;
  limit: number;
  offset: number;
}

export type Vendor = Pick<GraphQLTypes.Vendor, 'id' | 'identifier' | 'name'>;
export interface QueryResponse {
  getVendors?: {
    vendors: Vendor[];
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

const VendorListItemFragment = gql`
  fragment VendorListItemFragment on Vendor {
    id
    identifier
    name
  }
`;

export const Query = gql`
  query VendorListQuery($sort: [SortInput!]!, $filters: [FilterSpecificationInput]!, $search: SearchInput!, $limit: Int!, $offset: Int!) {
    getVendors: GetVendors(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      vendors {
        ...VendorListItemFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
  ${VendorListItemFragment}
`;

export const ExcelQuery = gql`
  query VendorListExcelQuery($sort: [SortInput!]!, $filters: [FilterSpecificationInput]!, $search: SearchInput!) {
    getVendors: GetVendors(sort: $sort, filters: $filters, search: $search) {
      vendors {
        ...VendorListItemFragment
      }
    }
  }
  ${VendorListItemFragment}
`;
