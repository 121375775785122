import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../form';
import { msyncQuery } from 'client/hoc/graphql/query';
import gqlTag from 'graphql-tag';
import { getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { buildFragment } from '../../../shared/schemas';

const mapStateToProps = state => {
  const values = getFormValues('ProductSubClassForm')(state) as shame;
  let sellDept = null;
  if (values && values.sellDepartmentId) {
    sellDept = values.sellDepartmentId;
  }
  return {
    selectedSellDepartment: sellDept,
  };
};

const mapDispatchToProps = dispatch => ({
  onSellDepartmentChange: val => dispatch(change('ProductSubClassForm', 'sellDepartmentId', val)),
  onProductClassChange: val => dispatch(change('ProductSubClassForm', 'productClassId', val)),
});


/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="productSubClasses" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

/*
ProductSubClassForm.propTypes = {
  loading: React.PropTypes.bool,
  initialValues: React.PropTypes.object,

  sellDepartments: React.PropTypes.arrayOf(React.PropTypes.shape({
    value: React.PropTypes.number,
    label: React.PropTypes.string,
  })),
  sellDepartmentsLoading: React.PropTypes.bool,
  productClasses: React.PropTypes.arrayOf(React.PropTypes.shape({
    id: React.PropTypes.number,
    identifier: React.PropTypes.string,
  })),
  productClassesLoading: React.PropTypes.bool,

  onSellDepartmentChange: React.PropTypes.func.isRequired,
  onProductClassChange: React.PropTypes.func.isRequired,
  selectedSellDepartment: React.PropTypes.number,

  // come from redux-form
  pristine: React.PropTypes.bool.isRequired,
  submitting: React.PropTypes.bool.isRequired,
  handleSubmit: React.PropTypes.func.isRequired,
};
*/
const ProductSubClassForm = ({
  sellDepartments,
  productClasses,
  handleSubmit,
  onSellDepartmentChange,
  onProductClassChange,
  selectedSellDepartment,
  sellDepartmentsLoading,
  productClassesLoading,
  record,
}: shame) => {
  return (
    <Col sm={12}>
      <Form horizontal onSubmit={handleSubmit}>
        <div className="simple-form" data-testid="product-sub-class">
          <FormGroup data-testid="info-row-1">
            <Field
              name="sellDepartmentId"
              labelColSize={2}
              inputColSize={4}
              options={sellDepartments}
              placeholder="Select Sell Department"
              handleChange={onSellDepartmentChange}
              disabled={!!selectedSellDepartment}
              loading={sellDepartmentsLoading}
              autoFocus={!record || !record.id}
            />
          </FormGroup>
          <FormGroup data-testid="info-row-2">
            <Field name="productClassId" labelColSize={2} inputColSize={4} options={productClasses}
              placeholder="Select Product Class" handleChange={onProductClassChange} loading={productClassesLoading} />
          </FormGroup>
          <FormGroup data-testid="info-row-3">
            <Field name="identifier" labelColSize={2} inputColSize={4}/>
          </FormGroup>
        </div>
      </Form>
    </Col>
  );
};


const fragmentName = 'ProductSubClass_SellDepartmentFragment';
const classFragmentName = 'PRoductSubClass_ProductClassFragment';

const sellDeptFragment = gqlTag`${buildFragment('sellDepartments', ['identifier'], fragmentName)}`;
const classFragment = gqlTag`${buildFragment('productClasses', ['identifier'], classFragmentName)}`;

const PRODUCT_CLASS_QUERY =
    gqlTag`
    query findAllProductClassesForCrud($type: RecordType!, $filters: [FilterSpecificationInput]) {
      content: findAll(type: $type, filters: $filters) {
        id,
        ...${classFragmentName}
      }
    }
    ${classFragment}
  `;
const SELL_DEPT_QUERY =
    gqlTag`
    query findAllSellDepartmentsForCrud($type: RecordType!) {
      content: findAll(type: $type) {
        id,
        ...${fragmentName}
      }
    }
    ${sellDeptFragment}
  `;

const withSellDepts = msyncQuery(SELL_DEPT_QUERY, {
  options() {
    return {
      variables: {
        type: 'SellDepartment',
      },
    };
  },
  props({ data: { content, loading } }) {
    return {
      sellDepartments: content,
      sellDepartmentsLoading: loading,
    };
  },
} as shame);

const withProductClasses = msyncQuery(PRODUCT_CLASS_QUERY, {
  options({ selectedSellDepartment }) {
    return {
      variables: {
        type: 'ProductClass',
        filters: [{
          field: 'sellDepartment',
          values: selectedSellDepartment ? [selectedSellDepartment] : [],
        }],
      },
    };
  },
  props({ data: { content, loading } }) {
    return {
      productClasses: content,
      productClassesLoading: loading,
    };
  },
} as shame);

export default withSellDepts(connect(mapStateToProps, mapDispatchToProps)(withProductClasses(ProductSubClassForm)));
