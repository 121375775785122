import * as React from 'react';
import { RecordNavItem } from '../../components/record-nav';

const buildRecordNavItems = supplierItemId => {
  const baseCreateUrl = '/admin/supplierItems/create';
  const baseDetailUrl = `/admin/supplierItems/details/${supplierItemId}`;

  const supplierItemInfoTab = supplierItemId
    ? <RecordNavItem label="Product Info" key="supplier-item-info" route={`${baseDetailUrl}/info`}/>
    : <RecordNavItem label="Product Info" key="supplier-item-info" route={`${baseCreateUrl}`}/>;

  return [
    supplierItemInfoTab,
    <RecordNavItem label="Cost Info" key="cost-info" route={`${baseDetailUrl}/costs`}/>,
  ];
};

export default buildRecordNavItems;
