import { buildAdminListPage } from '../admin';
import { ProductToss } from 'shared/schemas/product-toss';
import { ImportProductToss } from 'client/components/import-product-toss';

export default buildAdminListPage(ProductToss, {
  title: 'Product Toss',
  emptyTablePlaceholder: 'No Product Toss Records',
  newButtonLabel: 'New Toss Record',
  hardDeleteRecords: true,
  maxRecordsToDelete: 1000,
  supplementalRecordBarComponent: ImportProductToss,
  columns: ['customer', 'store', 'product', 'sku', 'weekEndingDate', 'quantity'],
  excelColumns: ['customer', 'store', 'product', 'sku', 'weekEndingDate', 'quantity', 'adjustmentType', 'enteredBy', 'merchandiser', 'region', 'market'],
});
