import { connect, ConnectedProps } from 'react-redux';
import Content from './content';
import * as Actions from 'client/actions/product-worksheet';
import * as CustomerOrderActions from 'client/actions/customer-order';
import { wrapComponent } from 'client/hoc/hoc';

export interface OwnProps {
  children: any;
}

export const mapDispatchToProps = {
  handleProductWorksheetUnmounted: Actions.productWorksheetUnmounted,
  handleSectionUnmounted: CustomerOrderActions.customerOrderSectionUnmounted,
};

const connector = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector>;

export default wrapComponent(Content)<OwnProps, CombinedProps>(
  connector,
);
