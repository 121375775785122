import { parseDate } from 'shared/helpers/date-helpers';
import { DateStr } from 'shared/types';
import { uniqBy } from 'lodash';

// vbills field: # of characters
export const fileFormat = {
  nroute: 4,
  ctruckid: 5,
  nseq: 4,
  ccuststore: 8,
  cpo: 9,
  dorderdate: 11,
  ccity: 26,
  cstate: 2,
  czip: 12,
  nboxes: 12,
  nracks: 10,
  npallets: 10,
  nmiles: 8,
  ntotmiles: 10,
  // npmwmiles: 8, // Our test files don't include this column, but VBills does
  // npmwtotmil: 10, // Our test files don't include this column, but VBills does
};

export interface VBillsFileFormat {
  nroute: number | null; // int
  ctruckid: string;
  nseq: number | null; // int
  ccuststore: string;
  cpo: string;
  dorderdate: DateStr | null; // date
  ccity: string;
  cstate: string;
  czip: string;
  nboxes: number | null; // int
  nracks: number | null; // float
  npallets: number | null; // float
  nmiles: number | null; // float
  ntotmiles: number | null; // float
  // npmwmiles: number; // Our test files don't include this column, but VBills does
  // npmwtotmil: number; // Our test files don't include this column, but VBills does
}

export const parseUplFile = (file: string): VBillsFileFormat[] => splitFilesIntoLines(file).map(parseLineIntoVBillsFormat);

const parseLineIntoVBillsFormat = (line: string): VBillsFileFormat => {
  return {
    nroute: grabIntFromLine(0, 4, line),
    ctruckid: grabStringFromLine(4, 5, line),
    nseq: grabIntFromLine(9, 4, line),
    ccuststore: grabStringFromLine(13, 8, line),
    cpo: grabStringFromLine(21, 9, line),
    dorderdate: grabDateStrFromLine(30, 11, line),
    ccity: grabStringFromLine(41, 26, line),
    cstate: grabStringFromLine(67, 2, line),
    czip: grabStringFromLine(69, 12, line),
    nboxes: grabIntFromLine(81, 12, line),
    nracks: grabFloatFromLine(93, 10, line),
    npallets: grabFloatFromLine(103, 10, line),
    nmiles: grabFloatFromLine(113, 8, line),
    ntotmiles: grabFloatFromLine(121, 10, line),
    // npmwmiles: line.substr(131, 8).trim(), // Our test files don't include this column, but VBills does
    // npmwtotmil: line.substr(139, 10).trim(), // Our test files don't include this column, but VBills does
  };
};

const splitFilesIntoLines = (file: string): string[] => {
  return file.split(/\r\n|\n/).filter(line => line.trim().length > 0);
};

const grabStringFromLine = (start: number, length: number, line: string): string => {
  return line.substr(start, length).trim();
};

const grabFloatFromLine = (start: number, length: number, line: string): number | null => {
  return returnNullOnError(() => Number.parseFloat(grabStringFromLine(start, length, line)));
};

const grabIntFromLine = (start: number, length: number, line: string): number | null => {
  return returnNullOnError(() => Number.parseInt(grabStringFromLine(start, length, line)));
};

const grabDateStrFromLine = (start: number, length: number, line: string): DateStr | null => {
  return returnNullOnError(() => parseDate(grabStringFromLine(start, length, line), 'MM/DD/YYYY'));
};

const returnNullOnError = <T>(fn: () => T): T | null => {
  try {
    return fn();
  } catch { // Gobble Gobble
    return null;
  }
};

export const getCustomers = (file: VBillsFileFormat[]) => {
  return uniqBy(file.map(line => {
    return {
      identifier: getCustomerIdentifier(line),
    };
  }), x => x.identifier);
};

export const getStores = (file: VBillsFileFormat[]) => {
  return uniqBy(file.map(line => {
    return {
      identifier: getStoreNumber(line),
      customerIdentifier: getCustomerIdentifier(line),
      city: getCity(line),
      state: getStoreState(line),
      zip: getZip(line),
    };
  }), x => x.customerIdentifier + x.identifier);
};

export const getCustomerOrders = (file: VBillsFileFormat[]) => {
  return uniqBy(file.map(line => {
    return {
      identifier: getCustomerOrderPoNumber(line),
      customerIdentifier: getCustomerIdentifier(line),
    };
  }), x => x.customerIdentifier + x.identifier);
};

export const getLoads = (file: VBillsFileFormat[]) => {
  return uniqBy(file.map(line => {
    return {
      identifier: null,
      truckId: getTruckId(line),
      routeNumber: getRouteNumber(line),
      totalMiles: getTotalMiles(line),
    };
  }), x => x.truckId);
};

export const getStops = (file: VBillsFileFormat[]) => {
  return uniqBy(file.map(line => {
    return {
      customerIdentifier: getCustomerIdentifier(line),
      storeIdentifier: getStoreNumber(line),
      truckId: getTruckId(line),
      boxes: getBoxes(line),
      racks: getRacks(line),
      pallets: getPallets(line),
      mileage: getMileage(line),
      sequenceNumber: getSequenceNumber(line),
      customerOrderIdentifier: getCustomerOrderPoNumber(line),
    };
  }), x => x.truckId + x.sequenceNumber);
};

const getStoreNumber = (data: VBillsFileFormat) => {
  return data.ccuststore.substr(3, 4);
};

const getCustomerIdentifier = (data: VBillsFileFormat) => {
  return data.ccuststore.substr(0, 3);
};

const getCustomerOrderPoNumber = (data: VBillsFileFormat) => {
  return data.cpo;
};

const getCity = (data: VBillsFileFormat) => {
  return data.ccity;
};

const getStoreState = (data: VBillsFileFormat) => {
  return data.cstate;
};

const getZip = (data: VBillsFileFormat) => {
  return data.czip;
};

const getTruckId = (data: VBillsFileFormat) => {
  return data.ctruckid;
};

const getRouteNumber = (data: VBillsFileFormat) => {
  return data.nroute;
};

const getTotalMiles = (data: VBillsFileFormat) => {
  return data.ntotmiles;
};

const getBoxes = (data: VBillsFileFormat) => data.nboxes;
const getRacks = (data: VBillsFileFormat) => data.nracks;
const getPallets = (data: VBillsFileFormat) => data.npallets;
const getMileage = (data: VBillsFileFormat) => data.nmiles;
const getSequenceNumber = (data: VBillsFileFormat) => data.nseq;
