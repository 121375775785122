import 'reflect-metadata';
import { tableName, property, definePresentation, hasOne, hasMany, gqlResolver, required } from './dsl';
import { SupplierContact } from './supplier-contact';
import { DISPLAY_TYPES, INPUT_TYPES, ActiveInactive } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';
import { CartTrackingSupplier } from 'shared/schemas/cart-tracking-supplier';
import { TYPES } from 'shared/types/meta';
import { DateStr } from 'shared/types/date-str';
import { CartTrackingSupplierEntry } from 'shared/schemas/cart-tracking-supplier-entry';

export type SupplierId = number;
export type SupplierIdentifier = Flavor<string, 'supplierIdentifier'>;
export type SupplierName = Flavor<string, 'supplierName'>;

const cartTrackingSupplierResolver = async ({ id }, _, context) => {
  // NOTE: Removed the type cast (CartTrackingSupplierRepository) from here to avoid
  //       importing server code into shared.
  const result = await (context.findRepository('CartTrackingSupplier', context)).findBySupplierId(id);
  return result ? result[0] : undefined;
};

export enum SupplierCartTrackingStatus {
  Track = 'Track',
  DoNotTrack = 'Do Not Track',
}

@tableName('suppliers')
export class Supplier implements IRecord {
  id?: number;
  @property @required identifier: SupplierIdentifier;
  @property @required name: SupplierName;
  @property growerId?: string | null;
  @property phone?: string | null;
  @property address1?: string | null;
  @property address2?: string | null;
  @property city?: string | null;
  @property state?: string | null;
  @property zip?: string | null;
  @property country?: string | null;
  @property fax?: string | null;
  @property website?: string | null;
  @property @required activeStatus: ActiveInactive;

  @hasOne('supplierContacts', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'], andWhere: { primary: true } })
  @gqlResolver<Supplier>(({ id }, _, context) => context.findRepository('SupplierContact', context).findPrimaryContactBySupplierId(id))
  @property primaryContact?: SupplierContact | null;

  @hasMany('supplierContacts', { foreignDisplayKey: 'name' })
  @property contacts: SupplierContact[];

  @property totalCarts?: number | null;
  @property cartTrackingStatus: SupplierCartTrackingStatus;
  @property lastAdjustmentDate?: DateStr | null;

  @hasOne('cartTrackingSuppliers') // JCN 2018-03-29 hasOne is broken(?)
  @gqlResolver(cartTrackingSupplierResolver)
  @property cartTrackingSupplier?: CartTrackingSupplier | null;

  @hasMany('cartTrackingSupplierEntries')
  @property cartTrackingSupplierEntries?: CartTrackingSupplierEntry[] | null;
}

definePresentation(Supplier, {
  identifier: {
    displayName: 'Code',
    searchable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 5,
  },
  name: {
    displayName: 'Supplier Name',
    searchable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 20,
  },
  growerId: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  phone: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
    columnWidth: 10,
  },
  address1: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 20,
  },
  address2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  city: {
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
  },
  state: {
    filterable: true,
    sortable: true,
    displayName: 'State',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 5,
  },
  zip: {
    displayName: 'ZIP',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 5,
  },
  country: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  fax: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
  },
  website: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.URL }),
  },
  primaryContact: {
    tableDisplay: true,
    columnWidth: 15,
    searchable: true,
    formDisplay: false,
  },
  contacts: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
    tableDisplayColumns: { name: 'Contacts'},
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  totalCarts: {
    displayName: 'Total Carts',
    searchable: false,
    sortable: true,
    filterable: false,
    tableDisplay: false,
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    calculationSpec: {
      dependencies: {},
      calculation: s => {
        return `(
          SELECT cart_tracking_suppliers.total_carts
          FROM cart_tracking_suppliers
          WHERE cart_tracking_suppliers.supplier_id = ${s.id})
          `;
      },
    },
  },
  cartTrackingStatus: {
    displayName: 'Tracking Status',
    searchable: false,
    sortable: true,
    filterable: false,
    tableDisplay: false,
    type: TYPES.STRING,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    calculationSpec: {
      dependencies: {},
      calculation: s => {
        return `(
          SELECT
          CASE
            WHEN COUNT(*) = 0 THEN 'Do Not Track'
            ELSE 'Track'
          END
        FROM cart_tracking_suppliers
        WHERE
          cart_tracking_suppliers.supplier_id = ${s.id}
      )`;

      },
    },
  },
  lastAdjustmentDate: {
    displayName: 'Last Adjustment Date',
    searchable: false,
    sortable: true,
    filterable: false,
    tableDisplay: false,
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    calculationSpec: {
      dependencies: {},
      calculation: s => {
        return `(
          SELECT MAX(cart_tracking_supplier_entries.entry_date)
          FROM cart_tracking_supplier_entries
          WHERE cart_tracking_supplier_entries.supplier_id = ${s.id}
        )`;
      },
    },
  },
  cartTrackingSupplier: {
    tableDisplay: false,
    searchable: false,
    formDisplay: false,
  },
});
