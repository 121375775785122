import * as React from 'react';
import { FormGroup } from 'client/components/third-party';
import { FieldWrapper } from '../../form';
import { CustomerContact } from './customer-form';
import { CustomerContactRole } from 'shared/types';
import * as CustomerFormHelpers from './customer-form-helpers';
import { WrappedFieldArrayProps } from 'redux-form';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="customerContacts" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

export interface CustomerContactFormGrouProps {
  handleAddContactClick: (fields: shame) => (() => void);
  handleDeleteContactClick: (fields, index) => (() => void);
  handleRoleChange: (value, index) => void;
  contacts: CustomerContact[];
}

class CustomerContactFormGroup extends React.Component<WrappedFieldArrayProps & CustomerContactFormGrouProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div data-testid="customer-contacts">
        {this.props.fields.map((contact, index) => {
          const currentContact = this.props.contacts[index];
          const headingText = CustomerFormHelpers.createContactHeader(currentContact);
          const email = currentContact ? currentContact.email : null;

          const otherRoles = this.props.contacts
            .filter((c, contactIndex) => contactIndex !== index)
            .map(c => c.role);

          const roles = Object.keys(CustomerContactRole)
            .filter(role => {
              return !otherRoles.includes(role as CustomerContactRole);
            })
            .map(role => {
              return {
                id: role,
                value: role,
              };
            });

          return (
            <div key={index} data-testid="customer-contact" data-field-array-index={index}>
              <div className="mfc-form-heading">
                {headingText}
                <div className="pull-right">
                  {(email && <span className="mfc-simple-icon-button"><a href={`mailto:${email}`}><span className="fa fa-envelope" /></a></span>)}
                  <span className="mfc-simple-icon-button" data-testid={`contact-${index}-delete-button`} onClick={this.props.handleDeleteContactClick(this.props.fields, index)}><span className="fa fa-trash" /></span>
                </div>
              </div>

              <FormGroup data-testid={`contact-${index}-row-1`}>
                <Field name={`${contact}.name`} labelColSize={2} inputColSize={9} testid="name"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-2`}>
                <Field
                  name={`${contact}.role`}
                  labelColSize={2}
                  inputColSize={3}
                  testid="role"
                  options={roles}
                  handleChange={value => this.props.handleRoleChange(value, index)}
                />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-3`}>
                <Field name={`${contact}.email`} labelColSize={2} inputColSize={9} testid="email"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-4`}>
                <Field name={`${contact}.phone`} labelColSize={2} inputColSize={4} testid="phone"/>
                <Field name={`${contact}.fax`} labelColSize={1} inputColSize={4} testid="fax"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-5`}>
                <Field name={`${contact}.address1`} labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-6`}>
                <Field name={`${contact}.address2`} labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-7`}>
                <Field name={`${contact}.city`} labelColSize={2} inputColSize={4} />
                <Field name={`${contact}.state`} labelColSize={1} inputColSize={1} />
                <Field name={`${contact}.zip`} labelColSize={1} inputColSize={2} />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-8`}>
                <Field name={`${contact}.country`} labelColSize={2} inputColSize={4} />
              </FormGroup>
            </div>
          );
        })}

        {this.props.fields.length < Object.keys(CustomerContactRole).length &&
          <div className="text-center">
            <div className="mfc-form-button add-contact-button" data-testid="qa-new-customer-contact-button" onClick={this.props.handleAddContactClick(this.props.fields)}>
              <span className="fa fa-plus-circle" /> Add Another Contact
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CustomerContactFormGroup;
