import gql from 'graphql-tag';
import { extractQueryName } from 'client/utils/query-utils';
import { CustomerOrderInvoice } from 'schema/invoice/invoice-graphql-types';

export interface CustomerOrderInvoiceQueryInput {
  customerOrderId: number;
}

export interface CustomerOrderInvoiceQueryResponse {
  customerOrder: {
    customerOrderInvoice: Pick<CustomerOrderInvoice, 'id' | 'firstDownloadedAt' | 'firstDownloadedByUser' | 'ediInvoicedAt' | 'ediInvoicedByUser' | 'identifier' | 'emailedAt' | 'emailedByUser'> | null;
  };
}

export const CustomerOrderInvoiceQuery = gql`
  query CustomerOrderInvoiceQuery($customerOrderId: Int!) {
    customerOrder: FindCustomerOrderById(customerOrderId: $customerOrderId) {
      id
      customerOrderInvoice {
        id
        identifier
        firstDownloadedAt
        firstDownloadedByUser
        ediInvoicedAt
        ediInvoicedByUser
        emailedAt
        emailedByUser
      }
    }
  }
`;
export const CustomerOrderInvoiceQueryName = extractQueryName(CustomerOrderInvoiceQuery);
