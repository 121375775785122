import gql from 'graphql-tag';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';

export interface Input {
  invoiceId: number;
}

export interface QueryResponse {
  getInvoice?: {
    invoice: {
      id: InvoiceGraphqlTypes.Invoice['id'];
      ediInvoiced: InvoiceGraphqlTypes.Invoice['ediInvoiced'];
      transferredToAccounting: InvoiceGraphqlTypes.Invoice['transferredToAccounting'];
      firstDownloadedAt: InvoiceGraphqlTypes.Invoice['firstDownloadedAt'];
      emailedAt: InvoiceGraphqlTypes.Invoice['emailedAt'];
    };
  };
}

export const Query = gql`
  query GetInvoiceForAddRoutePlansModal($invoiceId: Int!) {
    getInvoice: GetInvoice(id: $invoiceId) {
      invoice {
        id
        ediInvoiced
        transferredToAccounting
        firstDownloadedAt
        emailedAt
      }
    }
  }
`;
