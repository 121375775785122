import gql from 'graphql-tag';

export const generateProductReplenishmentReportByCategory = gql`
mutation GenerateReplenishmentByProductReport($reportByCategoryInput: GenerateProductReportByCategoryInput) {
  response: GenerateProductReplenishmentReportByCategory(reportByCategoryInput: $reportByCategoryInput) {
    id
  }
}
`;

export const generateProductReplenishmentReportBySalesPlan = gql`
mutation GenerateReplenishmentBySalesPlanReport($reportBySalesPlanInput: GenerateProductReportBySalesPlanInput) {
  response: GenerateProductReplenishmentReportBySalesPlan(reportBySalesPlanInput: $reportBySalesPlanInput) {
    id
  }
}
`;
