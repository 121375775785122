import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { RoutePlanStatus, RoutePlanIdentifier } from 'schema/route-plan/route-plan-typescript-types';
import { ApolloRefetch } from 'client/types';
import { LoadOrigin, LoadType, DropoffLocation, RouteType, LoadDestination, Carrier } from 'shared/types';

export interface RoutePlanLoadInformation {
  loadType?: LoadType;
  origin?: LoadOrigin;
  dropoffLocation?: DropoffLocation;
  routeType?: RouteType;
  destination?: LoadDestination;
  carrier?: Carrier;
  trailerTemperature?: number;
}

export const RoutePlanHeaderFormQuery = gql`
  query RoutePlanSidebarQuery($id: Int!) {
    routePlanResult: GetRoutePlan(id: $id) {
      routePlan {
        id
        identifier
        deliveryDate
        origin
        customerId
        sellDepartmentId
        mfcAreaId
        status
        numberOfAttachedOrders
        loadCount
        loadType
        dropoffLocation
        routeType
        destination
        carrier
        trailerTemperature
      }
    }
  }
`;

export interface RoutePlanQueryProps {
  initialValues?: shame;
  status?: RoutePlanStatus;
  routePlanIdentifier?: RoutePlanIdentifier;
  attachOrdersButtonDeemphasized?: boolean;
  readyToRouteButtonDisabled?: boolean;
  readyToRouteButtonDeemphasized?: boolean;
  refetch?: ApolloRefetch | null;
  loadsPresent?: boolean;
  loadInformation?: RoutePlanLoadInformation;
}

export const WithRoutePlan = msyncQuery<shame, { routePlanId: number }, RoutePlanQueryProps, { id: number }>(RoutePlanHeaderFormQuery, {
  alias: 'withRoutePlan',
  skip(ownProps: shame) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps: shame): { variables } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): RoutePlanQueryProps {
    const { data } = props;
    const routePlanResult = data ? data.routePlanResult : undefined;
    const routePlan = routePlanResult ? routePlanResult.routePlan : undefined;
    if (routePlan === undefined)  {
      return {
        initialValues: {},
        status: undefined,
        attachOrdersButtonDeemphasized: false,
        readyToRouteButtonDisabled: true,
        readyToRouteButtonDeemphasized: false,
        refetch: null,
        loadsPresent: false,
        loadInformation: {},
      };
    }

    return {
      initialValues: {
        id: routePlan.id,
        identifier: routePlan.identifier,
        origin: routePlan.origin,
        deliveryDate: routePlan.deliveryDate,
        customerId: routePlan.customerId,
        sellDepartmentId: routePlan.sellDepartmentId,
        mfcAreaId: routePlan.mfcAreaId,
      },
      status: routePlan.status,
      routePlanIdentifier: routePlan.identifier,
      attachOrdersButtonDeemphasized: routePlan.numberOfAttachedOrders > 0,
      readyToRouteButtonDisabled: routePlan.numberOfAttachedOrders === 0 || routePlan.status === RoutePlanStatus.ReadyToRoute,
      readyToRouteButtonDeemphasized: routePlan.status !== RoutePlanStatus.Draft,
      refetch: data.refetch,
      loadsPresent: routePlan.loadCount > 0,
      loadInformation: {
        origin: routePlan.origin,
        loadType: routePlan.loadType,
        dropoffLocation: routePlan.dropoffLocation,
        routeType: routePlan.routeType,
        destination: routePlan.destination,
        carrier: routePlan.carrier,
        trailerTemperature: routePlan.trailerTemperature,
      },
    };
  },
});
