import * as _ from 'lodash';
import * as Actions from './actions';
import * as HOC from 'client/hoc/hoc';
import * as ImportModalTypes from 'client/components/import-modal/types';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import * as State from 'client/state/state';
import * as ReduxForm from 'redux-form';
import * as Constants from './constants';
import assertCompatible from 'shared/helpers/assert-compatible';
import { optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';

export interface OwnProps {
  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;
}

interface StateProps {
  customerId: number | undefined;
  regionIds: number[] | undefined;
  marketIds: number[] | undefined;
}

interface DispatchProps {
  handleParsingStarted: ImportModalTypes.ParseFn;
  handleValidationStarted: ImportModalTypes.ValidateFn;
  handleImportStarted: ImportModalTypes.ImportFn;
  handleCustomerChanged: (customerId: number) => void;
  handleRegionChanged: (regionIds: number[]) => void;
  handleMarketChanged: (marketIds: number[]) => void;
  handleModalOpened: () => void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, OwnProps> = {
  handleParsingStarted: Actions.handleParsingStarted,
  handleValidationStarted: Actions.handleValidationStarted,
  handleImportStarted: Actions.handleImportStarted,
  handleCustomerChanged: Actions.handleCustomerChanged,
  handleRegionChanged: Actions.handleRegionChanged,
  handleMarketChanged: Actions.handleMarketChanged,
  handleModalOpened: Actions.handelModalOpened,
};

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state);

  if (!formValues) {
    return {
      customerId: undefined,
      regionIds: undefined,
      marketIds: undefined,
    };
  }

  return {
    customerId: formValues[Constants.FormFields.customerId],
    regionIds: formValues[Constants.FormFields.regionIds],
    marketIds: formValues[Constants.FormFields.marketIds],
  };
};

const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });

const withRegions = optionsContainerWithFilters({
  table: 'regions',
  columns: ['identifier', 'description'],
  skip: props => {
    return !props.customerId;
  },
  getFilters: props => {
    return [
      { field: 'customer', values: [props.customerId] },
    ];
  },
});

const withMarkets = optionsContainerWithFilters({
  table: 'markets',
  columns: ['identifier', 'description', 'regionIdentifier'],
  skip: props => {
    return !props.customerId;
  },
  getFilters: props => {
    if (props.regionIds && props.regionIds.length > 0) {
      return [
        { field: 'region', values: props.regionIds },
        { field: 'customer', values: [ props.customerId ]},
      ];
    }

    return [
      { field: 'customer', values: [ props.customerId ]},
    ];
  },
});

interface WithCustomers {
  customers: Array<{ id: number; identifier: string; name: string }>;
}

interface WithRegions {
  regions: Array<{ id: number; identifier: string; description: string; }> | undefined;
}

interface WithMarkets {
  markets: Array<{ id: number; identifier: string; description: string; regionIdentifier: string; }> | undefined;
}

export type ContainerProps =
  OwnProps &
  DispatchProps &
  WithCustomers &
  WithRegions &
  WithMarkets &
  StateProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.ComponentProps, ContainerProps>();

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withCustomers,
  withRegions,
  withMarkets,
)(UI.ImportProductPriceModalUI as shame) as shame as Component<OwnProps>;

export const ImportProductPriceModalContainer = (props: OwnProps) => HOC.propToComponent(component, props);
