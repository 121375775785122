import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Helpers from './helpers';
import * as Types from './types';

interface SuccessfulParsedSalesPlanVarieties {
  success: true;
  parsed: Types.ImportableSalesPlanVarieties;
}

interface FailedParsedSalesPlanVarieties {
  success: false;
  reason: string;
}

type ParsedSalesPlanVarietiesResult = SuccessfulParsedSalesPlanVarieties | FailedParsedSalesPlanVarieties;

export async function getImportableSalesPlanVarieties(workBookContainer: ExcelCommon.WorkBookContainer): Promise<ParsedSalesPlanVarietiesResult> {
  const corruptMessage = 'The selected spreadsheet may be corrupt or invalid.';

  try {
    const workBook = await Helpers.getSalesPlanWorkBookFromWorkBookContainer(workBookContainer);
    if (!workBook) {
      return {
        success: false,
        reason: 'Unable to load workbook.',
      };
    }

    const workSheet = Helpers.getSalesPlanWorkSheet(workBook);
    if (!workSheet) {
      return {
        success: false,
        reason: 'Unable to load worksheet.',
      };
    }

    const workSheetData = Helpers.getSalesPlanWorkSheetData(workSheet);
    if (workSheetData.length === 0 || Object.keys(workSheetData[0]).length < 1) {
      return {
        success: false,
        reason: 'Unable to load rows and columns.',
      };
    }

    const headerData = Helpers.getHeaderData(workSheetData);

    if (!headerData.customerIdentifier) {
      return {
        success: false,
        reason: 'Could not find Customer Identifier in spreadsheet',
      };
    }

    if (!headerData.year) {
      return {
        success: false,
        reason: 'Could not find Production Year in spreadsheet',
      };
    }

    if (!headerData.seasonIdentifier) {
      return {
        success: false,
        reason: 'Could not find Production Season in spreadsheet',
      };
    }

    const products = Helpers.getProducts(workBook);

    return {
      success: true,
      parsed: {
        year: headerData.year,
        customerIdentifier: headerData.customerIdentifier,
        seasonIdentifier: headerData.seasonIdentifier,
        products,
      },
    } as shame;
  } catch (error) {
    return {
      success: false,
      reason: `${error}. ${corruptMessage}`,
    };
  }
}
