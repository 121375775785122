//
// NOTE: THIS IS THE "NEW" RECORD BAR :-/
//

import * as React from 'react';
import { Row } from 'client/components/third-party';
import { RecordBarBackButton } from './record-bar-back-button';
import { RecordBarUndoRedoButtons } from './record-bar-undo-redo-buttons';
import { RecordBarDetailSet } from 'client/components/record-bar';
import { formatDateTime } from 'shared/helpers/date-helpers';
import { DateTimeStr } from 'shared/types';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';

export interface Props {
  children?: React.ReactNode | React.ReactNode[];
  onBackButtonClicked?: () => void;
  updatedAt?: DateTimeStr;
  status?: React.ReactNode;
  onSaveButtonClicked?: () => void;
  saveButtonDisabled?: boolean;
}

export const RecordBar: React.SFC<Props> = (props: Props) => {
  const saveButtonDisabled = !!props.saveButtonDisabled;

  return (
    <Row className="record-bar-row">
      <div className="record-bar">
        <div className="detail-area">
          <RecordBarBackButton onClick={props.onBackButtonClicked} />
          {Array.isArray(props.children) ? <RecordBarDetailSet>{props.children}</RecordBarDetailSet> : props.children}
        </div>
        <div className="action-area">
          {props.updatedAt &&
            <div className="last-save" data-testid="record-bar-last-modified-at">
              Last Modified: {formatDateTime(props.updatedAt, 'l - LT z')}
            </div>
          }

          {props.status && props.status}

          <RecordBarUndoRedoButtons />
          <div className="detail">
            <GlobalSaveButton
              label={'Save'}
              disabled={saveButtonDisabled}
              testid={'save-button'}
              onClick={props.onSaveButtonClicked}
            />
          </div>
        </div>
      </div>
    </Row>
  );
};
