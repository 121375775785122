import gql from 'graphql-tag';

export const SendMicsFileToMeijerMutation = gql`
  mutation SendMicsFileToMeijer($customerOrderIds: [Int!]!) {
    mutationResult: SendMicsFileToMeijer(customerOrderIds: $customerOrderIds) {
      customerOrders {
        id
        micsSentAt
      }
    }
  }`;
