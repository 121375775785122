import * as _ from 'lodash';

// ------ README --------
// WATCH OUT FOR REGEX
// https://stackoverflow.com/questions/51367051/react-native-javascript-regular-expression-exception-invalid-group-on-release
// -----------------------

const linePattern = /\n|\\n/gi;
const framePattern = /([^@]+(?=@))|(^\s*at\s+)(\S+)/i;
const isUsingHermes = 1 + 1 > 0;
const gobbledygookPattern = isUsingHermes
  ? /https?:\/\/\d+\.\d+\.\d+\.\d+:\d+(\/[^?]+\?).+$/gi
  : /^([^@]+)@[^:\[]*:?([^\)]*)\)?$|^([^\(]+) \([^:\[\s]*bundle:([^\)]*)\)?$/gi;

/**
 * remove useless noise prefixes of bundle path, e.g.: /var/mobile/Containers/Data/Application/09EF47E7-EB7C-4D64-9071-E40D7FF181A7/Library/Application Support/CodePush/f1c5593dadc5e75f5f90b8e052338c7bacd9b5b879d4c153f5da640afcbea680/CodePush/
 */
 const sanitizeError = (e: Error) => Object.assign(e, {
  message: e.message,
  stackLines: isUsingHermes
    ? e.stack?.split(linePattern).map(l => l.replace(gobbledygookPattern, '$1'))
    : e.stack?.split(linePattern).map(l => l.replace(gobbledygookPattern, (_s, ...matches) => `bundle:${(matches[3] || 'NULL').trim()}    :    ${(matches[2] || 'NULL').trim()}`)),
});

const stackFrames = () => {
  try {
    const rawStack = new Error().stack ?? '';
    return rawStack.split(linePattern).map(x => {
      const matches = framePattern.exec(x.trim());
      if ((matches?.length ?? 0) > 0) {
        const lastMatch = matches!.slice(-1)[0];
        const funcName = (typeof lastMatch === 'string') ? lastMatch : `${lastMatch}`;
        return funcName.trim();
      }

      // return `ON-DENAND STACK-TRACE: unable to extract function name: {${rawStack}}`;
      return `meta.stackFrames(): unable to extract function name`;
    });
  } catch (err) {
    console.info(`callerName helper function must be safe.... clearly it isn't quite safe/`, err);
    return ['UNRESOLVABLE STACK TRACE', err];
  }
};

const stackFrame = (depth: number = 0): string | Error | {err: 'UNRESOLVABLE CALLER NAME', frames: []} => {
  try {
    const frames = stackFrames();
    return (frames[1] instanceof Error ? frames[1] : frames.find((_x, i) => i > depth + 2)) || {err: 'UNRESOLVABLE CALLER NAME', frames};
  } catch (err) {
    console.info(`callerName helper function must be safe.... clearly it isn't quite safe/`, err);
    return 'UNRESOLVABLE CALLER NAME';
  }
};

export enum LogLevel {
  ERROR = 4,
  WARN = 3,
  INFO = 2,
  DEBUG = 1,
  VERBOSE = 0,
}

let level = LogLevel.VERBOSE;
let logFuncs = {
  error: console.warn,
  warn: console.warn,
  info: console.info,
  M_SYNC: console.info,
  debug: console.info,
  verbose: console.info,
  navigating: console.info,
  mounting: console.info,
  mounted: console.info,
  rendering: console.info,
  interaction: console.info,
  unmounting: console.info,
  trace: console.info,
  network: console.info,
};

const splitStacks = (args: unknown[]) => _.flatMap(args, a =>
  a instanceof Error       ? sanitizeError(a)
  : typeof a === 'string' ? a.split('\n')
  : [a]
);

export const log = Object.freeze({
  M_SYNC     : <A extends unknown[]>(...args: A) => { if (level <= 5) logFuncs.M_SYNC      ('Ⓜ️-SYNC:'     , ...splitStacks(args)); return args; },
  error      : <A extends unknown[]>(...args: A) => { if (level <= 4) logFuncs.error       ('🔴ERROR:'     , ...splitStacks(args)); return args; },
  warn       : <A extends unknown[]>(...args: A) => { if (level <= 3) logFuncs.warn        ('⚠️ WARN:'     , ...splitStacks(args)); return args; },
  info       : <A extends unknown[]>(...args: A) => { if (level <= 2) logFuncs.info        ('   INFO:'     , ...splitStacks(args)); return args; },
  debug      : <A extends unknown[]>(...args: A) => { if (level <= 1) logFuncs.debug       ('  DEBUG:'     , ...splitStacks(args)); return args; },
  verbose    : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.verbose     ('VERBOSE:'     , ...splitStacks(args)); return args; },
  navigating : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.navigating  ('🔷  ->  '     , ...splitStacks(args)); return args; },
  mounting   : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.mounting    ('|[ -->🟢'     , ...splitStacks(args)); return args; },
  mounted    : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.mounted     (']>- ->🔆'     , ...splitStacks(args)); return args; },
  rendering  : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.rendering   ('  🖍️->  '     , ...splitStacks(args)); return args; },
  interaction: <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.interaction ('  🌟->  '     , ...splitStacks(args)); return args; },
  unmounting : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.unmounting  ('|<--    '     , ...splitStacks(args)); return args; },
  trace      : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.trace       ('~~~~~> T', ...splitStacks(args), stackFrames()); return args; },
  network    : <A extends unknown[]>(...args: A) => { if (level <= 0) logFuncs.interaction ('  🛰 ...'     , ...splitStacks(args)); return args; },
  callstack  : (frameOffset: null | number = null) => frameOffset === null ? stackFrames() : stackFrame((frameOffset || 0) + 1),
  setLevel   : (val: LogLevel) => logFuncs.info('log level set to ', (level = val)),
  getLevel   : () => level,
  setCoreLogFuncs: (replacementCore: typeof logFuncs) => { logFuncs = replacementCore; logFuncs.info('logging core mounted'); },
  getLogVerbs: () => _.keys(logFuncs) as [keyof typeof logFuncs],
});
