import * as React from 'react';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import RoutePlanDetails from './route-plan-details';
import LoadsTable from 'client/app/transportation/routing/route-plan-details/loads/loads-table-container';
import { withRoutePlanIdFromParams } from './with-route-plan-id-from-params';
import { RoutePlanSectionNames } from '../route-plan-section-names';
import { RouteChildrenProps } from 'react-router';

type OwnProps = RouteChildrenProps<{ id?: string }>;

interface WithRoutePlanIdProps {
  routePlanId?: RoutePlanId;
}

const RoutePlanLoads: React.SFC<OwnProps & WithRoutePlanIdProps> = props => {
  return (
    <RoutePlanDetails routePlanId={props.routePlanId} sectionName={RoutePlanSectionNames.LoadList}>
      <LoadsTable routePlanId={props.routePlanId} />
    </RoutePlanDetails>
  );
};

const component = flowRight(
  withRoutePlanIdFromParams,
)(RoutePlanLoads);

export default (props: OwnProps) => propToComponent(component, props);
