import { SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { createSelector } from 'reselect';
import gql from 'graphql-tag';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES, SORT_TYPES, castOrThrow, isQueryAsExcelColumn } from 'shared/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

const QUERY = gql`
query findAllCustomerOrderProductsWithTotal(, $sort: [SortInput!], $scope: [FilterSpecificationInput] ) {
  response: findAllWithTotal(type: CustomerOrderProduct, sort: $sort, scope: $scope) {
    records {
      ... on CustomerOrderProduct {
        product {
          identifier
          description
          sku
        }
        customerOrderProductGroup {
          identifier
        }
        piecesPerShelf
        shelvesPerRack
        piecesPerShippingUnit
        totalRackQuantity
      }
    }
    ids
    totalCount
    totalUnfilteredCount
  }
}
`;

const getVariables = props => ({ sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'product', foreignColumn: 'identifier' }] , scope: [{ field: 'customerOrder', values: [props.customerOrderId] }]});
const getCustomerIdentifier = props => props.customerIdentifier;
const getOrderIdentifier = props => props.customerOrderIdentifier;

const makeExcelRequestData = createSelector([getVariables, getCustomerIdentifier, getOrderIdentifier], (variables, customerIdentifier, orderIdentifier) => {
  return {
    query: QUERY,
    variables,
    worksheetName: `${customerIdentifier} - ${orderIdentifier}`,
    workbookName: `${customerIdentifier} - ${orderIdentifier}`,
    additionalInformation: [
      {
        label: 'PO #',
        value: orderIdentifier,
      },
      {
        label: 'Customer',
        value: customerIdentifier,
      },
    ],
  } as MsyncDataRequest;
});

const columns: IColumn[] = [
  {
    id: 'product.description',
    header: 'Description',
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    accessor: 'product.description',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'product.identifier',
    header: 'Product ID',
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    accessor: 'product.identifier',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'product.sku',
    header: 'SKU',
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    accessor: 'product.sku',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'customerOrderProductGroup.identifier',
    header: 'Rack Type',
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    accessor: 'customerOrderProductGroup.identifier',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'piecesPerShelf',
    header: 'Piece / Shelf',
    cellType: CELL_TYPES.SPINNER,
    type: TYPES.NUMBER,
    accessor: 'piecesPerShelf',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'shelvesPerRack',
    header: 'Shelves / Rack',
    cellType: CELL_TYPES.SPINNER,
    type: TYPES.NUMBER,
    accessor: 'shelvesPerRack',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'piecesPerShippingUnit',
    header: 'Pieces / Rack',
    cellType: CELL_TYPES.SPINNER,
    type: TYPES.NUMBER,
    accessor: 'piecesPerShippingUnit',
    tableEditable: false,
    sortable: false,
  },
  {
    id: 'totalRackQuantity',
    header: 'Total Racks',
    cellType: CELL_TYPES.SPINNER,
    type: TYPES.NUMBER,
    accessor: 'totalRackQuantity',
    tableEditable: false,
    sortable: false,
  },
];

export const downloadExcelAction = props => async (setInProgress: SetInProgress) => {
  props.downloadExcel({
    dataRequest: makeExcelRequestData(props),
    columns: columns.map(c => castOrThrow(isQueryAsExcelColumn, c)),
    setInProgress: () => setInProgress(true),
    setNotInProgress: () => setInProgress(false),
  });
};
