import 'reflect-metadata';
import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES, TYPES, CustomerContactRole } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';
import { Customer } from './customer';
import { CustomerContactRoleDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type CustomerContactId = number;

@tableName('customerContacts')
export class CustomerContact implements IRecord {
  id?: CustomerContactId;
  @property @required role: string;

  @belongsTo('customers')
  @property @required customer: Customer;
  customerId: number;

  @property address1?: string | null;
  @property address2?: string | null;
  @property city?: string | null;
  @property country?: string | null;
  @property email?: string | null;
  @property fax?: string | null;
  @property name?: string | null;
  @property phone?: string | null;
  @property state?: string | null;
  @property zip?: string | null;
}

definePresentation(CustomerContact, {
  role: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: CustomerContactRole, displayValueResolver: CustomerContactRoleDisplayValueResolver }),
    type: TYPES.STRING,
  },
  address1: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  address2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  city: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  country: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  email: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  fax: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  name: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  phone: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  state: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  zip: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
  },
  customer: {
    formDisplay: false,
  },
});
