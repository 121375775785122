import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import * as React from 'react';
import * as TableActions from 'client/actions/table';
import { ActiveFilter } from 'client/types';
import { TYPES } from 'shared/types';

interface SetFiltersDispatch { setFilter: (field: string, values: Array<string | number>, type?: TYPES) => void }
export const withDefaultFilters = <Props extends {}>(tableName: string, getFilters: (props: Props) => ActiveFilter[]) =>
  flowRight(
    connect(undefined, dispatch => ({ setFilter: (field, values, type) => dispatch(TableActions.setFilter(tableName, field, values, type)) })),
    (WrappedComponent: (p: Props & SetFiltersDispatch) => React.ReactElement<Props & SetFiltersDispatch>) => function WithFiltersComponent(props: Props & SetFiltersDispatch) {
      const [isFiltersLoaded, setFiltersLoaded] = React.useState(false);
      if (!isFiltersLoaded) {
        const filters = getFilters(props as any);
        if (filters.length > 0) {
          filters.forEach(filter => props.setFilter(filter.field, filter.values, filter.type));
          setFiltersLoaded(true);
        }
      }

      return (<WrappedComponent {...props} />);
    }
  );
