import * as React from 'react';
import * as _ from 'lodash';
import { FormGroup, Form, Col, ControlLabel } from 'client/components/third-party';
import { SpecializeField } from '../form';
import { optionsContainerGenerator } from '../../hoc/options-container-generator';
import { Merchandiser } from 'shared/schemas/merchandiser';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { Saved } from 'shared/schemas/record';
import { HorizontalLayout } from '../form/layouts';
import { sortNaturally } from 'shared/helpers/sort-naturally';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const Field = SpecializeField(Merchandiser, { alwaysShowErrors: true, hideOptionalLabel: true });

interface DropDownOptions {
  value: number;
  label: string;
}

interface OwnProps {
  handleSubmit(): void;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  record?: Saved<Merchandiser>;
  coordinators: DropDownOptions[];
  coordinatorsLoading?: boolean;
}

class MerchandiserForm extends React.Component<OwnProps & WithStoresQueryProps & DispatchProps, never> {
  buildStoreElement = (store: Store) => {
    return <div>
      <a className="mfc-link" onClick={() => this.props.handleStoreClicked(store.id)} key={store.id}>
        {store.identifier} - {store.city}, {store.state}
      </a>
    </div>;
  }

  render() {
    return (
      <Form horizontal onSubmit={this.props.handleSubmit}>
        <div className="simple-form" data-testid="merchandiser">
          <Col sm={12}>
            <div className="mfc-form-heading">
              Merchandiser Information
            </div>
            <FormGroup data-testid="info-row-name">
              <Field name="identifier" labelColSize={2} inputColSize={5} autoFocus={!this.props.record || !this.props.record.id} />
            </FormGroup>
            <FormGroup data-testid="info-row-name">
              <Field name="name" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup data-testid="info-row-coordinator">
              <Field name="coordinator" labelColSize={2} inputColSize={5} options={this.props.coordinators} loading={this.props.coordinatorsLoading} />
            </FormGroup>
            <FormGroup data-testid="info-row-address1">
              <Field name="address1" labelColSize={2} inputColSize={10} />
            </FormGroup>
            <FormGroup data-testid="info-row-address2">
              <Field name="address2" labelColSize={2} inputColSize={10} />
            </FormGroup>
            <FormGroup data-testid="info-row-location">
              <Field name="city" labelColSize={2} inputColSize={4} />
              <Field name="state" labelColSize={1} inputColSize={2} />
              <Field name="zip" labelColSize={1} inputColSize={2} />
            </FormGroup>
            <FormGroup data-testid="info-row-email">
              <Field name="email" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup data-testid="info-row-phone">
              <Field name="phone" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup data-testid="info-row-stores">
              <HorizontalLayout labelColSize={2} label="Stores" labelComponent={ControlLabel} offset={0} inputColSize={5}>
                <div className="merchandiser-stores-list">{(this.props.stores || []).map(this.buildStoreElement)}</div>
              </HorizontalLayout>
            </FormGroup>
          </Col>
        </div>
      </Form>
    );
  }
}

const withCoordinators = optionsContainerGenerator({ table: 'merchandisers', columns: ['name'], resultPropName: 'coordinators' });

const query = gql`
  query GetStoresForMerchandiser($merchandiserId: Int!) {
    stores: GetStoresForMerchandiser(merchandiserId: $merchandiserId) {
      id
      identifier
      city
      state
    }
  }
`;

interface Store {
  id: number;
  identifier: string;
  city: string | null;
  state: string | null;
}
interface QueryResponse {
  stores?: Store[];
}

type WithStoresQueryProps = QueryResponse;

const withStores = msyncQuery<QueryResponse, OwnProps, WithStoresQueryProps, { merchandiserId: number }>(query, {
  alias: 'withStores',
  skip(ownProps) {
    return ownProps.record === undefined || ownProps.record.id === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        merchandiserId: ownProps.record!.id,
      },
    };
  },
  props(props) {
    const { data } = props;
    if (data.loading || data.stores === undefined) {
      return { stores: [] };
    }
    return {
      stores: sortNaturally(data.stores, [ {sortField: store => store.identifier }]),
    };
  },
});

interface DispatchProps {
  handleStoreClicked: (id: number) => void;
}

const mapDispatchToProps = {
  handleStoreClicked: (id: number) => push(`/admin/stores/details/${id}`),
};

export default _.flowRight(
  withCoordinators,
  withStores,
  connect(undefined, mapDispatchToProps),
)(MerchandiserForm);
