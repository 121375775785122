import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class Component extends React.Component<Props, State> {
  public render() {
    return (<div className="all-rows-selector-checkbox">
      <span />
    </div>);
  }
}

export default Component;
