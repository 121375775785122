import * as _ from 'lodash';
import { Numeric } from './Numeric';
export { Numeric } from './Numeric';

export type MoneyStr = string & { __unit__: 'usd' | 'USD' | void; };
export type NumericSources = number | string | Numeric | undefined | null;

export const toMoneyStr = (usd?: NumericSources) => Numeric.from(usd).toString({min: 2, max: Numeric.SCALE}) as MoneyStr;
export const moneyStrToNumber = (usd: NumericSources) => Numeric.from(usd).toFloat();
export const optionalMoneyStrToNumber = (usd: NumericSources | undefined | null) => _.isNil(usd) ? usd : moneyStrToNumber(usd);

export const addMoney = (augend: NumericSources, ...addends: NumericSources[]) => toMoneyStr(_.compact(addends).reduce((total: Numeric, value) => total.add(Numeric.from(value)), Numeric.from(augend)));
export const subtractMoney = (minuend: NumericSources, subtrahend: NumericSources) => toMoneyStr(Numeric.from(minuend).sub(Numeric.from(subtrahend)));
export const multiplyMoney = (multiplicand: NumericSources, multiplier: number) => toMoneyStr(Numeric.from(multiplicand).mul(multiplier));
export const divideMoney = (dividend: NumericSources, divisor: number) => toMoneyStr(Numeric.from(dividend).div(divisor));

export const formatNumber = (n: NumericSources , scale: null | number | {min: Int, max: Int} = null, thousandsSeparator: string = '', prefix: string = '', suffix: string = '') =>
  Numeric.from(n).toString((scale === null ? {min: 0, max: 2} : typeof scale !== 'number' ? scale : {min: scale, max: scale}) as {min: Int, max: Int}, thousandsSeparator, prefix, suffix);

export const formatDollarsAndCents = (n?: NumericSources) => formatNumber(n || 0, 2, ',', '$');
export const formatWholeDollars = (n?: NumericSources) => formatNumber(n || 0, 0, ',', '$');
export const formatMoney = (n?: NumericSources) => formatNumber(n || 0, {min: 2, max: Numeric.SCALE}, ',', '$');
