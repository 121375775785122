import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import * as Immutable from 'immutable';
import { Button } from 'client/components/third-party';
import { ProductList } from './product-list/product-list-container';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ProductShipmentConfiguration, WithProductsProps } from 'client/types/product-worksheet';
import AddNewProductModal from 'client/app/orders/customer-orders/product-worksheet/product-list/modals/add-new-product-modal-container';
import EditComboCartModal from 'client/app/orders/customer-orders/product-worksheet/product-list/modals/edit-combo-cart-modal-container';
import * as Actions from 'client/actions/product-worksheet';
import { ProductWorksheetTable } from './product-worksheet-table';
import { CustomerOrderProduct, CustomerOrderProductPackSize } from 'shared/schemas/customer-order-product';
import { getRegularCartsFromCustomerOrderProductGroups, getComboCartsFromCustomerOrderProductGroups, getSelectedProductInfo } from 'client/helpers/product-worksheet';
import { Saved } from 'shared/schemas/record';
import { CustomerOrderProductGroup, CustomerOrderProductGroupId, CustomerOrderProductGroupPacksPerShippingUnit } from 'shared/schemas/customer-order-product-group';
import { CustomerOrderAllocation } from 'shared/schemas/customer-order-allocation';
import { Product } from 'shared/schemas/product';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { ComboCart, ProductWorksheetStats } from 'shared/types';
import { ProductWorksheetStatsRow } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-stats-row';
import { ApolloRefetch } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { AddFromSalesPlanButton } from 'client/app/orders/customer-orders/product-worksheet/product-list/add-from-sales-plan-button';
import { FindAllCustomerOrderProductGroupsQuery, customFindAllCustomerOrderFilter } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-find-all-customer-order-product-groups-query';
import { FindProductByIdQuery, ProductWorksheetFindProductQueryResponse } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-find-product-query';
import { ProductWorksheetStatsQuery, ProductWorksheetStatsQueryResponse } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-stats-query';
import { ComboProductGroup } from 'shared/schemas/combo-product-group';
import { createShallowEqualSelector } from 'client/utils/reselect-utils';
import { EMPTY_ARRAY } from 'client/constants';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { LabeledSelect } from 'client/components/form';

type PartialCustomerOrderAllocation = Pick<Saved<CustomerOrderAllocation>, 'id'>;
interface PartialProduct {
  id: number;
  identifier: string;
  description: string;
  comparableProduct: {
    id: number;
    identifier: string;
  } | undefined;
}
type PartialCustomerOrder = Pick<Saved<CustomerOrder>, 'id' | 'orderMethod' | 'shippingUnitType'>;
type PartialComboProductGroup = Pick<Saved<ComboProductGroup>, 'id' | 'identifier' | 'description'>;
type PartialCustomerOrderProduct = Pick<Saved<CustomerOrderProduct>, 'id' | 'packSize' | 'packsPerShippingUnit' | 'packsPerShelf' | 'shelvesPerRack'> &
  {
    customerOrderAllocations: PartialCustomerOrderAllocation[],
    product: PartialProduct,
  };

export type ProductListResponseItem = Pick<Saved<CustomerOrderProductGroup>, 'id' | 'identifier' | 'description' | 'isCombo' | 'packsPerShippingUnit'> &
  {
    customerOrderProducts: PartialCustomerOrderProduct[]
    customerOrder: PartialCustomerOrder;
    packsPerShippingUnit?: number | null;
    comboProductGroup?: PartialComboProductGroup | null;
  };

const empty = {};
const ProductWorksheetStatsDataContainer = msyncQuery<ProductWorksheetStatsQueryResponse>(ProductWorksheetStatsQuery, {
  skip(ownProps: { params: { id: number, productId: number } }) {
    return !ownProps.params.id || !ownProps.params.productId;
  },
  options(ownProps: { params: { id: number, productId: number } }) {
    return {
      variables: {
        customerOrderId: ownProps.params.id || -1,
        selectedProductId: ownProps.params.productId || -1,
      },
      fetchPolicy: 'network-only',
    };
  },
  props({ data }) {
    if (data.error) {
      // Since there was error, just return something that will hopefully not cause further re-rendering
      return empty;
    } else {
      return {
        worksheetStats: data.worksheetStats,
        worksheetStatsRefetch: data.refetch,
      };
    }
  },
});

const getContent = (data: { content?: ProductListResponseItem[] }, ownProps?: shame) => data.content || EMPTY_ARRAY;
const filteredProductListSelector = createSelector(getContent, copgs => _.filter(copgs, group => !!group.customerOrderProducts));

const getProductIdForCustomerOrderProductGroupsSelector = (data: any, ownProps: Props) => getProductId(ownProps);
const customerOrderProductGroupsSelector = createSelector([getContent, getProductIdForCustomerOrderProductGroupsSelector], (content, productId) => {
  return (content || []).map(customerOrderProductGroup => {
    const customerOrderProduct = (customerOrderProductGroup?.customerOrderProducts)
      ? customerOrderProductGroup.customerOrderProducts.filter(cop => cop.product.id === productId)[0]
      : undefined;
    const packSize = customerOrderProduct ? customerOrderProduct.packSize : 0;

    return {
      id: customerOrderProductGroup.id,
      packsPerShippingUnit: customerOrderProductGroup.packsPerShippingUnit || undefined,
      packSize,
    };
  });
});

export const ProductWorksheetDataContainer = msyncQuery<{ content: ProductListResponseItem[] }, Props, {}>(FindAllCustomerOrderProductGroupsQuery, {
  options(ownProps: { params: { id?: string } }) {
    const scope = customFindAllCustomerOrderFilter(ownProps);

    return {
      variables: {
        scope,
        type: 'CustomerOrderProductGroup',
      },
      fetchPolicy: 'network-only',
    };
  },
  props({ data, ownProps }) {
    const productListResponseItems = filteredProductListSelector(data);
    return {
      regularCartList: getRegularCartsFromCustomerOrderProductGroups(productListResponseItems),
      comboCartList: getComboCartsFromCustomerOrderProductGroups(productListResponseItems),
      productListResponseItems,
      refreshProductsList: data.refetch,
      productListLoading: data.loading || !data.content,
      customerOrderProductGroups: customerOrderProductGroupsSelector(data, ownProps),
    };
  },
});

const productSelector = createShallowEqualSelector(
  (data: { product?: ProductWorksheetFindProductQueryResponse }) => data.product,
  product => {
    return { selectedProduct: product };
  });

export const ProductWorksheetSelectedProductInfo = msyncQuery<{ product: ProductWorksheetFindProductQueryResponse }, Props, {}>(FindProductByIdQuery, {
  skip(ownProps: StateProps & ComponentProps) {
    return _.isNil(ownProps.selectedProductId);
  },
  options(ownProps: StateProps & ComponentProps) {
    const id: number = ownProps.selectedProductId as number;

    return {
      variables: {
        id,
      },
    };
  },
  props({ data }) {
    return productSelector(data);
  },
});

const withProducts = optionsContainerWithFilters<WithProductsProps>({
  table: 'products',
  columns: ['identifier', 'description', 'packSize', 'packsPerRack', 'shelvesPerRack', 'packsPerShelf', 'casesPerPallet'],
  getFilters: (props: Props) => {
    const customerOrderDetails = props.record;

    if (!customerOrderDetails || _.isEmpty(customerOrderDetails)) {
      return [];
    }

    return [
      { field: 'customer', values: [customerOrderDetails.customer.id.toString()] },
      { field: 'sellDepartment.identifier', values: [customerOrderDetails.sellDepartment.identifier, 'MISC'] },
      { field: 'scanProduct', values: [customerOrderDetails.scanBased ? 'true' : 'false'] },
      // Intentionally not filtering out Inactive products here - want to be able to select them for comparison
    ];
  },
});

type ProductOption = Pick<Product, 'id' | 'identifier' | 'description'>;
function formatProductDisplayName(product: ProductOption): string {
  return `${product.identifier} - ${product.description}`;
}

export interface Props {
  params: {
    id?: string;
    productId?: string;
  };
  confirmOkToSave: () => Promise<boolean>;
  productListLoading: boolean;
  record?: Pick<Saved<CustomerOrder>,
    'id' | 'customer' | 'sellDepartment' | 'subSellDepartment' | 'scanBased' | 'orderMethod' | 'shippingUnitType' | 'salesPlan' | 'identifier' | 'customerId'
  >;
}

export interface DispatchProps {
  redirectToProduct: (customerOrderId: number, productId: number) => void;
  redirectToProductWorksheet: (customerOrderId: number) => void;
  handleAddNewProductButtonClicked: () => void;
  handleRemoveProductModalClosed(): void;
  onAutoReplenishmentClicked: (storeIdsFromMenuAction: number[]) => void;
  handleSelectedComparableProductIdChanged: (productId: number, selectedComparableProductId: number | undefined) => void;
}

export interface DataProps {
  regularCartList: ProductShipmentConfiguration[];
  comboCartList: ComboCart[];
  refreshProductsList: ApolloRefetch;
  productListResponseItems: ProductListResponseItem[];
  worksheetStats?: ProductWorksheetStats;
  worksheetStatsRefetch?: ApolloRefetch;
  selectedProduct: PartialProduct | null;
  customerOrderProductGroups: Array<{
    id: CustomerOrderProductGroupId;
    packsPerShippingUnit: CustomerOrderProductGroupPacksPerShippingUnit | undefined;
    packSize: CustomerOrderProductPackSize;
    comparableProductId: number | undefined;
  }>;
  dataRequest: MsyncDataRequest;
}

interface StateProps {
  customerOrderId: number;
  checkedProductIds: Immutable.Set<number>;
  customerOrderProductIds?: Immutable.Set<number>;
  selectedProductId?: number;
  removeCustomerOrderProductGroupIds: CustomerOrderProductGroupId[];
}

interface TableParentProps {
  tableParentInfo: TableParentInfo;
}

export interface ComponentProps extends DataProps, DispatchProps, Props, StateProps, TableParentProps, WithProductsProps { }

export interface ComponentState {
  productListInitiallyLoaded: boolean;
}

export const mapStateToProps = (state: any, ownProps: DataProps & Props): StateProps => {
  return {
    customerOrderId: _.parseInt(ownProps.params.id || ''),
    checkedProductIds: state.productWorksheet.checkedProductIds,
    customerOrderProductIds: state.productWorksheet.shipmentConfigurationIds,
    selectedProductId: state.productWorksheet.selectedProductId,
    removeCustomerOrderProductGroupIds: state.productWorksheet.removeCustomerOrderProductGroupIds,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    redirectToProduct: (customerOrderId: number, productId: number) => dispatch(Actions.productClicked(customerOrderId, productId)),
    redirectToProductWorksheet: (customerOrderId: number) => dispatch(Actions.redirectToProductWorksheet(customerOrderId)),
    handleAddNewProductButtonClicked: () => dispatch(Actions.addNewProductButtonClicked()),
    handleRemoveProductModalClosed: () => dispatch(Actions.removeProductModalCancelButtonClicked()),
    handleSelectedComparableProductIdChanged: (productId: number, selectedComparableProductId: number | undefined) => dispatch(Actions.selectedComparableProductIdChanged(productId, selectedComparableProductId)),
    onAutoReplenishmentClicked: (storeIdsFromMenuAction: number[]) => {
      return dispatch(Actions.setAutoReplenishmentModalVisibility(storeIdsFromMenuAction, true));
    },
  };
};

const getProductListResponseItems = (props: ComponentProps) => props.productListResponseItems;
const getProductId = (props: { params: { productId?: string } }) => _.parseInt(props.params.productId || '');
const convertToSelectedProductInfo = createSelector([getProductListResponseItems, getProductId], (productListResponseItems, productId) => getSelectedProductInfo({ productListResponseItems, productId }));

class ProductWorksheet extends React.PureComponent<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) { super(props); this.state = { productListInitiallyLoaded: false }; }
  public componentWillReceiveProps(nextProps: ComponentProps) {
    const { params: { productId: urlProductId }, productListResponseItems, redirectToProduct, redirectToProductWorksheet } = nextProps;
    if (!nextProps.productListLoading)
      this.setState({ productListInitiallyLoaded: true });

    // heyy, just gimme a sec, umm-kay?
    if (nextProps.productListLoading)
      return;

    if (urlProductId) {
      // When product id is specified on the url
      const productIdNumber = Number.parseInt(urlProductId);
      const productFound: boolean = productListResponseItems.some(item => item.customerOrderProducts.some(cop => cop.product.id === productIdNumber));
      if (!productFound) {
        // When specified product is not in the product list
        // Then redirect to main worksheet (when may later redirect to first product in list if it exists)
        redirectToProductWorksheet(nextProps.customerOrderId);
        return;
      }
      // When specified product is in the product list
      if (productIdNumber !== nextProps.selectedProductId) {
        // Then show newly selected product and updated selected product in state
        redirectToProduct(nextProps.customerOrderId, productIdNumber);
        return;
      }

      // The productIdNumber must be the same as the selectedProductId, so no need to do anything here
      return;
    }

    // When product id is not specified on the url
    if (nextProps.selectedProductId) {
      // When a selected product id is in state
      const productFound = productListResponseItems.some(item => item.customerOrderProducts.some(cop => cop.product.id === nextProps.selectedProductId));
      if (productFound) {
        // When select product id from state is in the product list
        // Then redirect to the product id from the state
        redirectToProduct(nextProps.customerOrderId, nextProps.selectedProductId);
      }
      return;
    }

    // When no selected product id is in state
    const productIds = _.flatten(productListResponseItems.map(item => item.customerOrderProducts.map(cop => cop.product.id)));
    if (productIds.length === 0) {
      // Then do nothing. An "Add Product" button should show on an empty Product Worksheet tab.
      return;
    }
    // When the product list has products
    // Then redirect to the first item in the list.
    let nextProductId = productIds[0];
    if (nextProps.regularCartList && nextProps.regularCartList.length > 0) {
      nextProductId = nextProps.regularCartList[0].productId;
    } else if (nextProps.comboCartList && nextProps.comboCartList.length > 0) {
      nextProductId = nextProps.comboCartList[0].products[0].productId;
    }

    redirectToProduct(nextProps.customerOrderId, nextProductId);
  }

  public render() {
    const {
      params,
      regularCartList,
      comboCartList,
      record: customerOrderDetails,
      refreshProductsList,
      worksheetStatsRefetch,
      handleAddNewProductButtonClicked,
      checkedProductIds,
      productListResponseItems,
      worksheetStats,
    } = this.props;

    const selectedProductInfo = convertToSelectedProductInfo(this.props);

    // Added check for loading to prevent the wrong "Add Product" button from being visible
    // before we realize there's nothing to display (this was causing intermittent test failures).
    // Haven't seen any issues with a "second loading" causing the page to go blank after it was loaded,
    // but if that occurs might need to get more sophisticated with this check.
    if (!customerOrderDetails)
      return <div />;

    if (this.props.productListLoading && !this.state.productListInitiallyLoaded)
      return <div />;

    const salesPlanId = customerOrderDetails.salesPlan?.id;
    const salesPlanIdentifier = customerOrderDetails.salesPlan ? `${customerOrderDetails.salesPlan.year} - ${customerOrderDetails.salesPlan.identifier}` : undefined;
    if (!this.props.productListLoading && productListResponseItems.length === 0)
      return (
        <div className="table-empty">
          <div className="placeholder">
            There are currently no products in this customer order.
          </div>

          <div className="action">
            <AddFromSalesPlanButton customerOrderId={this.props.customerOrderId} refreshProductsList={refreshProductsList} salesPlanIdentifier={salesPlanIdentifier}/>
            <Button bsClass="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary" onClick={handleAddNewProductButtonClicked}>
              Add Product
            </Button>
          </div>
          <AddNewProductModal
            customerOrderId={this.props.customerOrderId}
            customerOrder={customerOrderDetails}
            comboCartList={comboCartList}
            refreshProductsList={refreshProductsList}
            worksheetStatsRefetch={worksheetStatsRefetch}
            regularCartList={regularCartList}
            productListResponseItems={productListResponseItems}
            confirmOkToSave={this.props.confirmOkToSave}
          />
        </div>
      );

    const subSellDepartmentIdentifier = customerOrderDetails.subSellDepartment ? customerOrderDetails.subSellDepartment.identifier : undefined;
    return (
      <div className="product-worksheet">
        <div className="mfc-form-left-sidebar">
          <ProductList
            customerOrderId={params.id}
            customerOrderDetails={customerOrderDetails}
            products={regularCartList}
            comboCarts={comboCartList}
            selectedProduct={selectedProductInfo}
            refreshProductsList={refreshProductsList}
            worksheetStatsRefetch={worksheetStatsRefetch}
            productListResponseItems={productListResponseItems}
            confirmOkToSave={this.props.confirmOkToSave}
            salesPlanIdentifier={salesPlanIdentifier}
            loading={this.props.productListLoading}
          />
        </div>
        <div className="mfc-form-details-with-left-sidebar">
          {this.props.selectedProduct &&
            <div>
              <div>
                <div className="product-worksheet-comparable-product">
                  <LabeledSelect
                    input={{
                      name: 'comparableProductId',
                      value: this.props.selectedProduct.comparableProduct ? this.props.selectedProduct.comparableProduct.id : undefined,
                      onChange: _.noop,
                    }}
                    inputColSize={8}
                    label="Compare with"
                    labelColSize={4}
                    meta={{ submitting: false }}
                    offset={0}
                    testid="comparable-product-dropdown"
                    textFormatter={formatProductDisplayName}
                    valueField="id"
                    options={ (this.props.products || []).filter(product => product.id !== this.props.selectedProductId) }
                    alwaysShowErrors={false}
                    hideOptionalLabel={true}
                    handleChange={(selectedComparableProductId: number | undefined) => {
                      if (this.props.selectedProduct) {
                        this.props.handleSelectedComparableProductIdChanged(this.props.selectedProduct.id, selectedComparableProductId);
                      }
                    }}
                    clearable
                    inline
                    placeholder="Select a Product"
                  />
                </div>
                <h1 className="mfc-product-worksheet-title">
                  {this.props.selectedProduct ? `${this.props.selectedProduct.identifier } - ${this.props.selectedProduct.description }` : null }
                </h1>
              </div>
              <div>
                <ProductWorksheetStatsRow worksheetStats={worksheetStats} orderMethod={customerOrderDetails.orderMethod} shippingUnitType={customerOrderDetails.shippingUnitType} />
                <ProductWorksheetTable
                  customerOrderId={this.props.customerOrderId}
                  customerOrderIdentifier={customerOrderDetails.identifier}
                  selectedProduct={selectedProductInfo}
                  selectedComparableProductId={this.props.selectedProduct.comparableProduct ? this.props.selectedProduct.comparableProduct.id : undefined}
                  sellDepartment={customerOrderDetails.sellDepartment}
                  customer={customerOrderDetails.customer}
                  salesPlanId={salesPlanId}
                  subSellDepartmentIdentifier={subSellDepartmentIdentifier}
                  tableParentInfo={this.props.tableParentInfo}
                  confirmOkToSave={this.props.confirmOkToSave}
                  onAutoReplenishmentClicked={this.props.onAutoReplenishmentClicked}
                  productLabel={this.props.selectedProduct ? `${this.props.selectedProduct.identifier} - ${this.props.selectedProduct.description}` : undefined}
                  customerOrderProductGroups={this.props.customerOrderProductGroups}
                  orderMethod={customerOrderDetails.orderMethod}
                  dataRequest={this.props.dataRequest}
                />
              </div>
            </div>
          }
        </div>

        <AddNewProductModal
          customerOrderId={this.props.customerOrderId}
          customerOrder={customerOrderDetails}
          refreshProductsList={refreshProductsList}
          worksheetStatsRefetch={worksheetStatsRefetch}
          regularCartList={regularCartList}
          comboCartList={comboCartList}
          productIds={checkedProductIds}
          productListResponseItems={productListResponseItems}
          confirmOkToSave={this.props.confirmOkToSave}
        />

        <EditComboCartModal
          customerOrder={customerOrderDetails}
          refreshProductsList={refreshProductsList}
          worksheetStatsRefetch={worksheetStatsRefetch}
          productListResponseItems={productListResponseItems}
          regularCartList={regularCartList}
          comboCartList={comboCartList}
          confirmOkToSave={this.props.confirmOkToSave}
        />
      </div>);
  }
}

export const Component = _.flowRight(
  withProducts,
  connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps),
  ProductWorksheetSelectedProductInfo,
  ProductWorksheetStatsDataContainer,
  ProductWorksheetDataContainer,
  tableParentHoc(),  // After the datacontainers, but before the ProductWorksheet, so that if stats change it will check the table size
)(ProductWorksheet) as React.ComponentType<Props>;
