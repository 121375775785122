import * as React from 'react';
import * as classnames from 'classnames';

interface ModalProps {
  isVisible: boolean;
  children?: any;
  id: string;
  dialogClasses?: string;
  modalClasses?: string;
}

class Modal extends React.PureComponent<ModalProps, {}> {
  render() {
    const classes = classnames('modal', this.props.modalClasses, {
      show: this.props.isVisible,
    });
    const diagClasses = classnames('modal-dialog', this.props.dialogClasses);

    if (!this.props.isVisible) {
      return <div/>;
    }
    return (
      <div className={classes} id={this.props.id} tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div role="document" className={diagClasses}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
