import * as _ from 'lodash';
import * as React from 'react';
import { Modal, Col } from 'client/components/third-party';
import { Checkbox } from 'client/components/form/checkbox';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ComponentProps } from 'client/app/transportation/receiving/details/related-bols/send-receiving-receipt-modal-container';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';

enum ModalFormatting {
  Title             = 'send-receiving-receipt-modal-title'              ,
  Detail            = 'send-receiving-receipt-modal-detail'             ,
  Green             = 'send-receiving-receipt-modal-detail-green'       ,
  RecipientCheckbox = 'send-receiving-receipt-modal-recipients-checkbox',
  RecipientLabel    = 'send-receiving-receipt-modal-recipients-label'   ,
  Notes             = 'send-receiving-receipt-modal-notes-form'         ,
}

const Recipients = (p: ComponentProps) => (
  <div>
    {(p.recipients || []).map(r => {
      const checkboxInputChangeHandler = {
        value: r.selected,
        onChange: (value: boolean) => {
          if (r?.supplierContactId)
            p.handleRecipientClicked(r.supplierContactId);
        },
      };

      const detailLabel
        = r.receivesReceipts && r.primary ? 'Receiving Receipt Recipient, Primary Contact'
        : r.receivesReceipts              ? 'Receiving Receipt Recipient'
        :                       r.primary ? 'Primary Contact'
        :                                   ' ';

      return (
        <div key={r.supplierContactId} data-testid={`recipient-${_.kebabCase(r.name)}`}>
          <Checkbox className={ModalFormatting.RecipientCheckbox} input={checkboxInputChangeHandler} />
          <div className={ModalFormatting.RecipientLabel} onClick={() => checkboxInputChangeHandler.onChange(!checkboxInputChangeHandler.value)}>{r.name}</div>
          {detailLabel && <div className={ModalFormatting.Detail}> {detailLabel}</div>}
        </div>
      );
    })}
  </div>
);

export const SendReceivingReceiptModal = (p: ComponentProps) => (
  <Modal
    id="send-receiving-receipt-modal"
    animation
    backdrop="static"
    show={p.isShown}
    onHide={p.handleCancelButtonClicked}
    onEnter={() => { /*why does Enter essentially Reset?*/ p.setSupplierOrderContacts(p.initialRecipients || []); p.setReceivingReceiptNotes(''); }}
    dialogClassName="send-receiving-receipt-modal-container"
    className="send-receiving-receipt-modal">
    <GlobalAsyncModalHeader>Send Receiving Receipts</GlobalAsyncModalHeader>
    <Modal.Body>
      <Col sm={12}>
        <div className={ModalFormatting.Detail}> The order has been fully received. To whom would you like to send the Receiving Receipt? </div>
        <div className={ModalFormatting.Title}>Send to</div>
        <div className="send-receiving-receipt-modal-recipients"> <Recipients {...p} /> </div>
        <div className={ModalFormatting.Title}>Notes</div>
        <textarea value={p.notes} onChange={event => p.setReceivingReceiptNotes(event.target.value)} className={ModalFormatting.Notes} />
      </Col>
    </Modal.Body>
    <Modal.Footer>
      <GlobalCancelButton onClick={p.handleCancelButtonClicked} />
      <AsyncButton
        label="Send"
        disabled={p.recipients.filter(c => c.selected).length === 0}
        type={'submit'}
        testid="modal-submit"
        actionStatus={p.actionStatus}
        onClick={p.handleSubmit} />
    </Modal.Footer>
  </Modal>
);
