import * as State from 'client/state/state';
import * as MutationState from 'client/state/mutations';

export const isMutationInFlight = (state: State.Type) =>
  MutationState.mutationsInFlightCounterLens.get(state.mutations) > 0;

export const isMutationRecentlyReceived = (state: State.Type) =>
  MutationState.mutationRecentlyReceivedCounterLens.get(state.mutations) > 0;

export const isBusyModalShown = (state: State.Type) =>
  State.showBusyModalLens.get(state);

export const busyModalMessage = (state: State.Type) =>
  State.busyModalMessageLens.get(state);
