import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import * as EditVendorMutation from './mutation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { VendorFormValues } from '../vendor-form';


const updateVendor = async (dispatch: Dispatch<any>, formValues: Partial<VendorFormValues>): Promise<number> => {
  if (!formValues.id) {
    throw new Error('Trying to update vendor with no vendor ID');
  }

  const contacts = formValues.contacts ? formValues.contacts.map(contact => {
    return {
      id: contact.id,
      name: contact.name,
      email: contact.email,
      phoneOffice: contact.phoneOffice,
      phoneCell: contact.phoneCell,
      primary: contact.primary,
    };
  }) : [];
  const input: EditVendorMutation.Input = {
    vendorId: formValues.id,
    newVendorValues: {
      identifier: formValues.identifier,
      name: formValues.name,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      country: formValues.country,
      phone: formValues.phone,
      fax: formValues.fax,
      contacts,
    },
  };
  const result = await msyncClientMutation<EditVendorMutation.Response>({
    mutation: EditVendorMutation.Mutation,
    variables: input,
    dispatch,
  });
  return result.data.editVendor.vendor.id;
};

export const formSubmitted = (formValues: VendorFormValues, dispatchArg, props: { initialValues: { identifier: string } }): Thunker => {
  return async dispatch => {
    const { identifier, ...rest } = formValues;

    await updateVendor(dispatch, {
      ...rest,
      ...identifier !== props.initialValues.identifier ? { identifier } : {},
    });
  };
};
