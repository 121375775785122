import { connect } from 'react-redux';
import * as Actions from 'client/actions/import-route';
import * as State from 'client/state/state';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { FileUploadStatus, ImportRouteProcessStep } from 'client/state/import-route';
import { propToComponent } from 'client/hoc/hoc';
import { ImportRouteModalUI, ComponentProps as ImportRouteModalComponentProps } from './import-route-modal';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  routePlanId?: number;
}

interface StateProps {
  isShown: boolean;
  cancelButtonDisabled: boolean;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
  showNextButton: boolean;
  showFinishButton: boolean;
  finishButtonDisabled: boolean;
  preventEscFromClosingModal: boolean;
  processStep: ImportRouteProcessStep;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {

  let showNextButton = false;
  let nextButtonDisabled = true;
  let finishButtonDisabled = true;
  let preventEscFromClosingModal = false;

  switch (state.importRoute.processStep) {
    case ImportRouteProcessStep.RouteInformation:
      showNextButton = true;
      nextButtonDisabled = !ImportRouteSelectors.isValidRouteInformation(state);
      break;
    case ImportRouteProcessStep.ImportFile:
      showNextButton = true;
      nextButtonDisabled = state.importRoute.fileUploadStatus !== FileUploadStatus.Succeed;
      break;
    case ImportRouteProcessStep.ProcessSplitStops:
      showNextButton = false;
      nextButtonDisabled = true;
      preventEscFromClosingModal = true;
      finishButtonDisabled = ImportRouteSelectors.splitStopsExist(state) && !ImportRouteSelectors.areAllStoresFullyAllocated(state);
      break;
    default:
      break;
  }

  return {
    isShown: state.importRoute.importRouteModalShown,
    cancelButtonDisabled: ImportRouteSelectors.uploadInProgress(state) || ImportRouteSelectors.submittingRoutes(state),
    previousButtonDisabled:
      ImportRouteSelectors.uploadInProgress(state) ||
      ImportRouteSelectors.submittingRoutes(state) ||
      ImportRouteSelectors.onFirstProcessStep(state),
    nextButtonDisabled,
    showNextButton,
    showFinishButton: !showNextButton,
    finishButtonDisabled,
    processStep: state.importRoute.processStep,
    preventEscFromClosingModal,
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleNextButtonClicked(): void;
  handlePreviousButtonClicked(): void;
  handleFinishButtonClicked(): void;
}

const mapDispatchToProps: DispatchProps = {
  handleCancelButtonClicked: Actions.cancelButtonClicked,
  handleNextButtonClicked: Actions.nextButtonClicked,
  handlePreviousButtonClicked: Actions.previousButtonClicked,
  handleFinishButtonClicked: Actions.finishButtonClicked,
};

// Move this to common spot
interface WithApolloProps {
  client: ApolloClient<NormalizedCacheObject>;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithApolloProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<ImportRouteModalComponentProps, ComponentProps>();

const component = flowRight(
  withApollo,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ImportRouteModalUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
