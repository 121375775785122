import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import { SupplierName, SupplierCartTrackingStatus, SupplierId } from 'shared/schemas/supplier';
import Dropdown from 'client/components/dropdown';

interface OwnProps {
  supplierId: SupplierId;
  supplierName: SupplierName;
  cartTrackingStatus: SupplierCartTrackingStatus;
  onBackButtonClicked(): void;
  onSupplierClick(supplierId: SupplierId): void;
  updateSupplierCartTrackingStatus: (args: { supplierId: SupplierId, status: string }) => void;
}

const statuses = Object.keys(SupplierCartTrackingStatus).map(k => SupplierCartTrackingStatus[k]);

export class CartTrackingDetailsRecordBar extends React.PureComponent<OwnProps, {}> {

  onChangeHandler = async (status: SupplierCartTrackingStatus) => {
    await this.props.updateSupplierCartTrackingStatus({ supplierId: this.props.supplierId, status });
  }

  onSupplierClick = () => {
    this.props.onSupplierClick(this.props.supplierId);
  }

  render() {
    const props = this.props;

    const cartTrackingDetailsRecordBarStatus = (
      <div className="detail">
        <div className="heading"></div>
        <Dropdown
          className="mfc-dropdown btn-group-record-bar-button"
          data={statuses}
          value={[props.cartTrackingStatus]}
          placeholder={props.cartTrackingStatus}
          updateLabel
          block
          disabled={false}
          onChange={this.onChangeHandler}
        />
      </div >
    );

    return (
      <RecordBar
        onBackButtonClicked={props.onBackButtonClicked}
        status={cartTrackingDetailsRecordBarStatus}>
        <RecordBarDetail title="Supplier" testid="record-bar-supplier" onClick={this.onSupplierClick}>
          {props.supplierName}
        </RecordBarDetail>
        <RecordBarDetail >
        </RecordBarDetail>
      </RecordBar>
    );
  }
}
