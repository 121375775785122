import * as React from 'react';
import { RecordNavItem } from '../../components/record-nav';


const buildRecordNavItems = (productId, record) => {
  const externalDistributionProduct = record && !!record.externalDistribution;

  const baseCreateUrl = '/admin/products/create';
  const baseDetailUrl = `/admin/products/details/${productId}`;

  const productInfoTab = productId
    ? <RecordNavItem label="Product Info" key="product-info" route={`${baseDetailUrl}/info`}/>
    : <RecordNavItem label="Product Info" key="product-info" route={`${baseCreateUrl}`}/>;

  return [
    productInfoTab,
    ...(!externalDistributionProduct
      ? [
        <RecordNavItem label="Spec Info" key="spec-info" route={`${baseDetailUrl}/spec-info`}/>,
        <RecordNavItem label="Price Info" key="price-info" route={`${baseDetailUrl}/price-info`}/>,
        <RecordNavItem label="Attachments" key="attachments" route={`${baseDetailUrl}/attachments`}/>,
      ]
      : []
    ),
  ];
};

export default buildRecordNavItems;
