import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { PerformanceReportDateColumnBehavior, PerformanceReportStoreDetailLevel, PerformanceReportDateDetailLevel, ReportTypes, PerformanceReportProductGroupingBehavior, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { FormFields } from 'shared/app/reports/performance';
export { FormFields } from 'shared/app/reports/performance';

export const formName = 'performanceReport';
export const reportType = ReportTypes.PerformanceReport;

export interface FormValues {
  [FormFields.id                     ] : number | undefined                      ; // Preset ID
  [FormFields.customerId             ] : number | undefined                      ;
  [FormFields.marketIds              ] : SelectableValue | undefined             ;
  [FormFields.measures               ]?: SimpleObject                            ;
  [FormFields.mfcAreaIds             ] : SelectableValue | undefined             ;
  [FormFields.poBased                ] : boolean                                 ;
  [FormFields.productClassIds        ] : SelectableValue | undefined             ;
  [FormFields.productIds             ] : SelectableValue | undefined             ;
  [FormFields.productSubClassIds     ] : SelectableValue | undefined             ;
  [FormFields.regionIds              ] : SelectableValue | undefined             ;
  [FormFields.scanBased              ] : boolean                                 ;
  [FormFields.sellDepartmentId       ] : number | undefined                      ;
  [FormFields.sortBy                 ] : string | undefined                      ;
  [FormFields.storeIds               ] : SelectableValue | undefined             ;
  [FormFields.dateRangeYearComparison] : DateRangeYearComparisonValue | undefined;
  [FormFields.dateColumnBehavior     ] : PerformanceReportDateColumnBehavior     ;
  [FormFields.storeDetailLevel       ] : PerformanceReportStoreDetailLevel       ;
  [FormFields.dateDetailLevel        ] : PerformanceReportDateDetailLevel        ;
  [FormFields.productGroupingBehavior] : PerformanceReportProductGroupingBehavior;
  [FormFields.primaryGlobalAll       ] : PrimaryGlobalAll                        ;
}
