import { flatten } from 'lodash';
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

export const DELETE_SUPPLIER_ORDER_PRODUCT_GROUP_MUTATION = gql`
  mutation deleteSupplierOrderProductGroup($supplierOrderProductIds: [Int]!) {
    deletedSupplierOrderGroupIds: deleteSupplierOrderProductGroup(supplierOrderProductIds: $supplierOrderProductIds)
  }
`;

export type DeleteSupplierOrderProductGroupFunction = (supplierOrderProductIds: number | number[]) => Promise<number[]>;

export interface OwnProps {
  refetchTable: () => void;
  client: ApolloClient<NormalizedCacheObject>;
}

export interface GraphQlProps {
  deleteSupplierOrderProductGroup: DeleteSupplierOrderProductGroupFunction;
}

export interface DeleteSupplierOrderProductGroupVariables {
  supplierOrderProductIds: number[];
}

interface NetworkResult {
  deletedSupplierOrderGroupIds: number[];
}

/** defines an msyncMutation HOC which deletes any SOPGs containing indicated SOPs. */
export const withDeleteSupplierOrderProductGroup = (table: string) => msyncMutation<NetworkResult, OwnProps, GraphQlProps>(DELETE_SUPPLIER_ORDER_PRODUCT_GROUP_MUTATION, {
  props: props => ({
    async deleteSupplierOrderProductGroup(supplierOrderProductIds) {
      const result = await props.mutate({ variables: { supplierOrderProductIds: flatten([supplierOrderProductIds]) } });
      if (result && result.data) {
        await props.ownProps.client.resetStore(); // TODO: this resetStore call should go away at some point...
        await props.ownProps.refetchTable();
        return result.data.deletedSupplierOrderGroupIds;
      }

      return [];
    },
  }),
});
