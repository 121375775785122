import * as React from 'react';
import SupplierOrderHeaderForm from './supplier-order-header-form';
import SupplierOrderDetails from './supplier-order-details';
import { RecordNav } from 'client/components/record-nav';
import navBuilder from '../nav-items';

export interface SupplierOrderFormProps {
  confirmOkToSave: () => Promise<boolean>;
  handleSubmit: () => Promise<boolean>;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  record: { id: number };
  formName: string;
}

export function SupplierOrderForm({record, formName, confirmOkToSave, handleSubmit}: SupplierOrderFormProps) {
  return (
    <div>
      <RecordNav RecordNavItems={record && navBuilder(record.id)} disabled={!record || !record.id}/>
      <div className="mfc-form-details-with-sidebar">
        <SupplierOrderDetails record={record} formName={formName} confirmOkToSave={confirmOkToSave} handleSubmit={handleSubmit} />
      </div>
      <div className="mfc-form-sidebar">
        <SupplierOrderHeaderForm formName={formName} record={record}/>
      </div>
    </div>
  );
}
