import * as _ from 'lodash';
import * as React from 'react';

export function StatsCardBasic(props: { title: string, children?: any, condition?: boolean }) {
  if (props.condition === false) {
    return null;
  }

  return (
    <div className="card-pf card-pf-accented card-pf-aggregate-status-notifications" data-testid={`stats-card-${_.kebabCase(props.title)}`}>
      <div className="card-pf-title">{props.title}</div>
      <div className="card-pf-body">{props.children}</div>
    </div>
  );
}
