import { flowRight } from 'lodash';
import { push } from 'connected-react-router';
import { propToComponent } from 'client/hoc/hoc';
import { ReceivingDetailsRecordBar } from 'client/app/transportation/receiving/details/receiving-details-record-bar/receiving-details-record-bar';
import { ReceivingDetailsRecordBarQuery, ReceivingDetailsRecordBarResponse } from 'client/app/transportation/receiving/details/receiving-details-record-bar/receiving-details-record-bar-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SupplierOrderPlannedArrivalDate } from 'shared/schemas/supplier-order';
import { SupplierName } from 'shared/schemas/supplier';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { CustomerName } from 'shared/schemas/customer';
import { connect } from 'react-redux';
import { ReceivableOrderReceivingStatus } from 'shared/types';

interface OwnProps {
  receivableOrderId: number;
}

interface ReceivableOrderRecordBarProps {
  receivableOrderIdentifier: string;
  supplierName: SupplierName;
  received: ReceivableOrderReceivingStatus;
  customerName: CustomerName;
  mfcAreaIdentifier: MfcAreaIdentifier | undefined;
  arrivalDate: SupplierOrderPlannedArrivalDate;
  receivableOrderType: string;
  sourceOrderId: number;
}

const WithReceivableOrder = msyncQuery<ReceivingDetailsRecordBarResponse, OwnProps, ReceivableOrderRecordBarProps | undefined>(ReceivingDetailsRecordBarQuery, {
  options(ownProps): { variables } {
    return {
      variables: {
        id: ownProps.receivableOrderId,
      },
    };
  },
  props({ data }): ReceivableOrderRecordBarProps | undefined {
    if (!data.response) {
      return undefined;
    }

    return {
      arrivalDate: data.response.arrivalDate,
      customerName: data.response.customerName,
      mfcAreaIdentifier: data.response.mfcAreaIdentifier,
      supplierName: data.response.supplierVendorName,
      received: data.response.receivingStatus,
      receivableOrderIdentifier: data.response.identifier,
      receivableOrderType: data.response.receivableOrderType,
      sourceOrderId: data.response.sourceOrderId,
    };
  },
});

interface DispatchProps {
  onBackButtonClicked(): void;
  onSupplierPOClick(supplierOrderId: number): void;
  onCustomerPOClick(customerOrderId: number): void;
}

const mapDispatchToProps = (dispatch, ownProps: OwnProps): DispatchProps => {
  return {
    onBackButtonClicked() {
      dispatch(push(`/transportation/receiving/list`));
    },
    onSupplierPOClick(supplierOrderId) {
      dispatch(push(`/orders/supplier/details/${supplierOrderId}`));
    },
    onCustomerPOClick(customerOrderId) {
      dispatch(push(`/orders/customer/details/${customerOrderId}`));
    },
  };
};

export type ComponentProps =
  OwnProps;

const component = flowRight(
  connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
  WithReceivableOrder,
)(ReceivingDetailsRecordBar as shame);

export default (props: OwnProps) => propToComponent(component, props);
