import * as _                                from 'lodash';
import * as React                            from 'react';
import {    useMemo                        } from 'react';
import {    useDispatch, useSelector       } from 'react-redux';
import      gql                              from 'graphql-tag';
import {    CELL_TYPES, TYPES, toInt       } from 'shared/types';
import {    firstElementOrThrowError       } from 'shared/helpers/andys-little-helpers';
import {    FieldValidator                 } from 'shared/validators';
import {    CustomerOrderProductGroupId    } from 'shared/schemas/customer-order-product-group';
import {    CustomerOrderProductGroupV2    } from 'schema/customer-order-product-group/customer-order-product-group-types';
import {    buildDataTable                 } from 'client/containers/table/basic-table';
import {    IColumn                        } from 'client/components/table/column';
import {    useMsyncQuery                  } from 'client/hoc/graphql/query';
import {    TRANSPORTATION_SPLIT_STOPS     } from 'client/constants';
import * as Actions                          from 'client/actions/import-route';
import * as ImportRouteState                 from 'client/state/import-route';
import * as ImportRouteSelectors             from 'client/state/import-route-selectors';
import * as GlobalState                      from 'client/state/state';
import {    LoadColumnFooterCell           } from './load-column-footer-cell';
import { useLifeCycleLogging, useObject, useOnce } from 'client/lib/react';

interface SplitStopTableQueryResponse {
  splitStopTableQueryResponse?: {
    customerOrderProductGroups: Array<
      Pick<CustomerOrderProductGroupV2,
        'id' |
        'description' |
        'rackType' |
        'customerOrderPoNumber' |
        'isCombo' |
        'rackQuantity' |
        'productIdentifiers' |
        'productDescriptions'
      >
    >
  }
}

export const buildLoadCellTestId = (args: { loadNumber: string, customerOrderPoNumber: string, productId: string, rackType: string }): string => `${args.loadNumber}-${args.customerOrderPoNumber}-${args.productId}-${args.rackType}`;
export const SplitStopTableQuery = gql`
  query SplitStopTableQuery ($storeIdentifier: GlobalStoreIdentifier!, $routePlanId: Int!) {
    splitStopTableQueryResponse: GetCustomerOrderProductGroupForRoutePlanAndStore(storeIdentifier: $storeIdentifier, routePlanId: $routePlanId) {
      customerOrderProductGroups {
        id
        description
        rackType
        customerOrderPoNumber
        isCombo
        packsPerShippingUnit
        rackQuantity(filter: { storeIdentifier: $storeIdentifier })
        productIdentifiers
        productDescriptions
      }
    }
  }
`;

interface SplitStopTableRow { id: number, productId: string, description: string, customerOrderPoNumber: string, rackType: string, rackQuantity: Int }
interface TableRowProps { tableRows: SplitStopTableRow[]; loading: boolean; totalCount: number; totalUnfilteredCount: number; filteredRecordIds: number[]; }
export const SplitStopTable = (p: {}) => {
  useLifeCycleLogging('SplitStopTable');
  const dispatch = useDispatch();
  const actions = useObject({
    cellBlurred: ( groupsWithOrderQuantities: Array<{ customerOrderProductGroupId: number, rackQuantityOrdered: Int }> ) => dispatch(Actions.cellBlurred(groupsWithOrderQuantities)),
    storeLoadRackQuantityChanged: (
      loadNumber: string,
      customerOrderProductGroupId: number,
      quantity: Int,
      totalRackQuantityForGroup: Int,
    ) => dispatch(Actions.storeLoadRackQuantityChanged(loadNumber, customerOrderProductGroupId, quantity, totalRackQuantityForGroup)),
  });

  const rdx = useObject(useSelector((s: GlobalState.Type) => ({
    storeIdentifier                           : ImportRouteSelectors.getSelectedStore                          (s),
    routePlanId                               : ImportRouteSelectors.getRoutePlanId                            (s),
    loadNumbersForSelectedStore               : ImportRouteSelectors.getLoadNumbersForSelectedStore            (s),
    getStoreLoadRackQuantity                  : ImportRouteSelectors.getStoreLoadRackQuantities                (s),
    spacesRemainingForAllocatedProductForStore: ImportRouteSelectors.spacesRemainingForAllocatedProductForStore(s),
    serializedState: ` // for dirty checking / reducing re-renders
      π ${ImportRouteSelectors.getSelectedStore(s)?.customerIdentifier}
      π ${ImportRouteSelectors.getSelectedStore(s)?.storeNumber}
      π ${ImportRouteSelectors.getRoutePlanId(s)}
      π ${ImportRouteSelectors.getLoadNumbersForSelectedStore(s).join()}
      π ${JSON.stringify(GlobalState.importRouteSplitStopQuantities(s))}
      π ${JSON.stringify(ImportRouteState.splitStopQuantities(s.importRoute))}
      `,
  }), (l, r) => l.serializedState === r.serializedState));

  const d = useObject(useMsyncQuery<SplitStopTableQueryResponse, typeof rdx, TableRowProps>(rdx, SplitStopTableQuery, {
    alias: 'withSplitStopTableRows',
    skip: ownProps => !ownProps.routePlanId || !ownProps.storeIdentifier,
    options: ownProps => ({ fetchPolicy: 'network-only', variables: { storeIdentifier: ownProps.storeIdentifier, routePlanId: ownProps.routePlanId } }),
    props: ({data: {loading, splitStopTableQueryResponse: {customerOrderProductGroups = []} = {}}, ownProps}): TableRowProps => ({
      loading,
      totalCount: customerOrderProductGroups.length,
      totalUnfilteredCount: customerOrderProductGroups.length,
      filteredRecordIds: [],
      tableRows: customerOrderProductGroups.map(copg => ({
        id: copg.id,
        productId: copg.isCombo ? 'Combo' : firstElementOrThrowError('No products found on this particular group', copg.productIdentifiers),
        description: copg.isCombo ? copg.description : firstElementOrThrowError('No products found on this particular group', copg.productDescriptions),
        customerOrderPoNumber: copg.customerOrderPoNumber,
        rackType: copg.rackType,
        rackQuantity: copg.rackQuantity,
      })),
    }),
  }));

  const TableContainerWidth = 661; // WTF magic
  const DataTable = useOnce(() => buildDataTable(TRANSPORTATION_SPLIT_STOPS));
  const cols = useMemo((): IColumn[] => {
    const loadNumbers = rdx.loadNumbersForSelectedStore;
    const validateRackQuantity = (): FieldValidator => ({
      isValid: (newValue: string, r: SplitStopTableRow) => 0 <= rdx.spacesRemainingForAllocatedProductForStore(r.rackQuantity, r.id, _.keys(r).find(k => loadNumbers.includes(k)) ?? 'unknown-load', Number.parseInt(newValue)),
      shortMessage: (newTrailerNumber: any, r: SplitStopTableRow) => `Quantity allocated to ${_.keys(r).find(k => loadNumbers.includes(k))} exceeds remaining rack quantity.`,
      message: (label: string, newTrailerNumber: any, r: SplitStopTableRow) => '',
    });

    const columns = [
      { id: 'productId'            , accessor: 'productId'            , header: 'Prod ID'    , tableEditable: false, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
      { id: 'description'          , accessor: 'description'          , header: 'Description', tableEditable: false, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
      { id: 'customerOrderPoNumber', accessor: 'customerOrderPoNumber', header: 'PO #'       , tableEditable: false, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
      { id: 'rackType'             , accessor: 'rackType'             , header: 'Rk Type'    , tableEditable: false, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
      { id: 'rackQuantity'         , accessor: 'rackQuantity'         , header: 'Rk Qty'     , tableEditable: false, sortable: false, cellType: CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED, type: TYPES.NUMBER },
      ...loadNumbers.map((loadNumber): IColumn => ({
        id: loadNumber,
        accessor: ({id: customerOrderProductGroupId}) => `${rdx.getStoreLoadRackQuantity(loadNumber, customerOrderProductGroupId)}`,
        header: loadNumber,
        tableEditable: true,
        sortable: false,
        getTestId: values => buildLoadCellTestId({ ...values, loadNumber }),
        cellType: CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED,
        type: TYPES.NUMBER,
        validators: [ validateRackQuantity ],
        options: { arrowKeyDownDuringEditMovesFocusDown: true, arrowKeyUpDuringEditMovesFocusUp: true },
        footerComponent: () => <LoadColumnFooterCell loadNumber={loadNumber}/>,
        onBlur: async () => { actions.cellBlurred((d.tableRows ?? []).map(tr => ({ customerOrderProductGroupId: tr.id, rackQuantityOrdered: tr.rackQuantity }))); },
        onSave: (customerOrderProductGroupId: CustomerOrderProductGroupId, value: string, prevValue: string) => {
          if (value === prevValue) return;
          const group = (d.tableRows ?? []).find(tr => tr.id === customerOrderProductGroupId);
          actions.storeLoadRackQuantityChanged(loadNumber, customerOrderProductGroupId, toInt(value), group!.rackQuantity);
        },
        saveOnChange: true,
    })),
    ];

    return columns.map(column => ({ ...column, width: _.floor(1.0 * TableContainerWidth / columns.length) }));
  }, [d.tableRows.length, rdx.serializedState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (!d.tableRows || d.tableRows.length === 0) ? <div/> : (
    <div className="import-route-split-stop-table-container">
      <div className="import-route-split-stop-table">
        <DataTable
          content={d.tableRows}
          loading={d.loading}
          columns={cols}
          totalCount={d.totalCount}
          onSort={_.noop}
          filteredRecordIds={d.filteredRecordIds}
          setTablePageNumber={_.noop}
          tablePageNumber={1}
          tablePaginated={false}
          tableParentInfo={{
            containerHeight: 260,
            containerWidth: TableContainerWidth,
            adjustTableDimensions: _.noop,
          }}
          noDataText="No Groups"
          tableClassName="split-stops-table"
        />
      </div>
    </div>
  );
};
