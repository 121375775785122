import * as React from 'react';
import { Modal, Button, Form } from 'client/components/third-party';
import ProductNewPriceFormGroup from '../crud-forms/product/product-new-price-form-group';
import { formContainer } from '../../hoc/form-container';
import gqlTag from 'graphql-tag';
import { formQueryColumns, buildFragment, tableName } from '../../../shared/schemas';
import { propToComponent } from 'client/hoc/hoc';
import { GlobalSaveButton } from '../async-button/global-save-button-container';
import { SelectableValue } from '../selectable/selectable';
import { DateStr, MoneyStr } from 'shared/types';

const table = tableName('ProductPrice');
const formName = 'ProductPriceForm';
const ProductNewPriceModal = (p: React.PropsWithChildren<{
  show: boolean,
  onClose: () => void,
  refetchTable: () => void,
  invalid: boolean,
  pristine: boolean,

  // formContainer
  handleSubmit: () => Promise<true | { [k: string]: string }>,
  reset: () => void,
  customerId: number,
}>) => {
  const handleCancel = () => {
    p.reset();
    p.onClose();
  };
  const onSubmitClick = async () => {
    if (true === await p.handleSubmit()) {
      await p.refetchTable();
      p.reset();
      p.onClose();
    }
  };

  return !p.show ? <div/> : (
    <Modal
      key="new-price-modal"
      id="new-price-modal"
      animation
      backdrop="static"
      show={p.show}
      onHide={handleCancel}>
      <Form horizontal onSubmit={p.handleSubmit} data-testid="price-info">
        <Modal.Header closeButton>
          <Modal.Title>Create New Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductNewPriceFormGroup formName={formName} customerId={p.customerId} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsClass="mfc-button mfc-clear-button" onClick={handleCancel}>
            Cancel
          </Button>
          <GlobalSaveButton
            label="Create"
            onClick={onSubmitClick}
            disabled={p.invalid || p.pristine}
            testid="modal-submit"
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const WrappedForm = formContainer({
  table,
  mutationStrings: {
    create: gqlTag`
      mutation CreateProductPrices($input: [CreateProductPriceInput]) {
        data: createManyProductPrices(input: $input) {
          id,
          ...ProductPriceFragment
        }
      }
      ${gqlTag`${buildFragment(table, formQueryColumns(table), 'ProductPriceFragment')}`}
    `,
  },
  onSubmit: ({ create }, _, { productId }) => (rec: {
    marketId: SelectableValue | null,
    beginDate: DateStr | null,
    changeReason: any,
    mfcPrice: MoneyStr,
    retailPrice: MoneyStr,
    scanbackPrice: MoneyStr | null,
    upcRetailPrice: MoneyStr | null,
    notes: string | null,
    productId: number,
    primary: boolean | null,
    primaryMarketIds: number[] | null,
  }) => {
    const marketIds: number[] = rec.primary ? (rec.primaryMarketIds || []) : rec.marketId ? rec.marketId.values : [];
    const baseRecord = {
      beginDate: rec.beginDate,
      changeReason: rec.changeReason,
      mfcPrice: rec.mfcPrice,
      retailPrice: rec.retailPrice,
      scanbackPrice: rec.scanbackPrice,
      upcRetailPrice: rec.upcRetailPrice,
      notes: rec.notes,
      productId,
    };

    const records = marketIds.length > 0
      ? marketIds.map(marketId => ({ ...baseRecord, marketId }))
      : [baseRecord];

    return create(records);
  },
  formName,
  isNewRecord: undefined,
  refetchQueries: { create: ['findProduct'] }, // refetch product record after price creation to allow active price and active retail to update properly.
})(ProductNewPriceModal as shame<'wip'>) as any;

export default props => propToComponent(WrappedForm, props);
