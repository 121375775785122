import 'reflect-metadata';
import * as _ from 'lodash';
import { pluralize } from 'inflection';
import { TYPES, DISPLAY_TYPES, INPUT_TYPES, SchemaOptions, FKSpec, DropdownOption, Arg4, dropdownOptions } from 'shared/types';
import { FieldValidator, REQUIRED } from 'shared/validators';

const PROPERTIES_KEY = Symbol('properties');
export const property = (target: any, propertyKey: string) => {
  const columns: string[] = Reflect.getMetadata(PROPERTIES_KEY, target.constructor) || [];
  columns.push(propertyKey);
  Reflect.defineMetadata(PROPERTIES_KEY, columns, target.constructor);
};

export const getProperties = <T>(schemaDef: Constructor<T>): Array<keyof T> => Reflect.getMetadata(PROPERTIES_KEY, schemaDef);
export const containsProperty = <T>(schemaDef: Constructor<T>, field: string): field is keyof T => (getProperties(schemaDef) as string[]).includes(field);

const REQUIRED_KEY = Symbol('required');
export const required = (target: any, propertyKey: string) => {
  const columns: string[] = Reflect.getMetadata(REQUIRED_KEY, target.constructor) || [];
  columns.push(propertyKey);
  Reflect.defineMetadata(REQUIRED_KEY, columns, target.constructor);
};

export const getRequiredColumns = <T>(schemaDef: Constructor<T>): Array<keyof T> => Reflect.getMetadata(REQUIRED_KEY, schemaDef) || [];

////////////////////////////////////////////////////////////
// Presentation

export interface FormDisplayOptions extends Pick<FormDisplayType, 'inputType' | 'valueField' | 'displayValueResolver'> {
  options?: Dictionary<string | shame>;
  multiselect?: boolean;
  /**
   * Allows to specify the increment size of valid number values starting at the minimum (usually zero);
   * e.g., step of 0.1 allows single digit decimal numbers.
   * Provided to the `step` attribute of html `input` tags via our FormControls.
   * Defaults to 1.0;
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
   */
  step?: number;
}

export interface FormDisplayType {
  type: keyof typeof DISPLAY_TYPES;
  inputType?: INPUT_TYPES;

  /** see FormDisplayOptions.step */
  step?: number;
  valueField?: string;
  displayValueResolver?(obj: any): string;
  options?: DropdownOption[];
  multiselect?: boolean;
}

export interface PresentationOptions<PropertyType> {
  required?: boolean;
  searchable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  formDisplayType?: FormDisplayType;
  displayName?: string;
  tableDisplay?: boolean;
  tableEditable?: boolean;
  columnWidth?: number;
  formDisplay?: boolean;
  includeInFormQuery?: boolean;
  gqlType?: any;
  type?: TYPES;
  defaultValue?: PropertyType;
  // validators?: FieldValidator[];
  tableDisplayColumns?: { [k: string]: any };
  calculationSpec?: {
    dependencies: any;
    calculation: any;
  };
  defaultFilterWithValues?: any[];
  includeInSubmittedForm?: boolean; // If true, this field will be submitted as part of the form, even if it's not "displayed"
}

export const displayType = (type: DISPLAY_TYPES, opts: FormDisplayOptions = {}): FormDisplayType => {
  if (!Object.keys(DISPLAY_TYPES).map((t: keyof typeof DISPLAY_TYPES) => DISPLAY_TYPES[t]).includes(type))
    throw new Error(`Invalid displayType: ${type}`);

  switch (type) {
    case DISPLAY_TYPES.INPUT: return { type, inputType: opts.inputType || INPUT_TYPES.TEXT, step: opts.step };
    case DISPLAY_TYPES.DROPDOWN: return { ...opts, options: dropdownOptions(opts.options), type };
    case DISPLAY_TYPES.RADIO: return { type, options: dropdownOptions(opts.options) };
    default: return {type};
  }
};

const PROPERTY_PRESENTATION_KEY = Symbol('propertyPresentation');
export const getPropertyPresentationOptions = <T, K extends keyof T>(c: Constructor<T>, column: K): PresentationOptions<T[K]> | undefined => Reflect.getMetadata(PROPERTY_PRESENTATION_KEY, c.prototype, column);
export type PropertyPresentationMap<T> = { [P in keyof T]?: PresentationOptions<T[P]> };
export const isRequired = <T, K extends keyof T>(c: Constructor<T>, column: K): boolean => (getRequiredColumns(c)).includes(column);
export const definePresentation = <T>(schemaDef: Constructor<T>, props: PropertyPresentationMap<T>): void => {
  const presentableProperties: string[] = Reflect.getMetadata('presentableProperties', schemaDef) || [];
  for (const propertyName in props) {
    if (propertyName === 'required')
      throw new Error('required should now be specified with @required annotation!');

    presentableProperties.push(propertyName);
    Reflect.defineMetadata(
      PROPERTY_PRESENTATION_KEY,
      Object.assign(props[propertyName] ?? {}, {
        required: isRequired(schemaDef, propertyName),
        displayName: props[propertyName]?.displayName ?? _.startCase(propertyName), // Provide a reasonable default for the display name if none was provided
      }),
      schemaDef.prototype,
      propertyName
    );
  }

  Reflect.defineMetadata('presentableProperties', presentableProperties, schemaDef);
};

const FIELD_VALIDATOR_KEY = Symbol('fieldValidator');
type FieldValidatorMap<T> = { [P in keyof T]?: FieldValidator[] };
export const getFieldValidators = <T, K extends keyof T>(c: Constructor<T>, column: K): FieldValidator[] | undefined => [
  ...(getRequiredColumns(c).includes(column) ? [REQUIRED] : []),
  ...(Reflect.getMetadata(FIELD_VALIDATOR_KEY, c.prototype, column) ?? []),
];

/** Adds field validators to any previously defined/appended validators for a specified schema class */
export const appendFieldValidators = <T>(schemaDef: Constructor<T>, fieldValidators: FieldValidatorMap<T>): void => {
  for (const propertyName in fieldValidators) {
    const validators = fieldValidators[propertyName];
    if (Array.isArray(validators))
      Reflect.defineMetadata(
        FIELD_VALIDATOR_KEY,
        [
          ...(Reflect.getMetadata(FIELD_VALIDATOR_KEY, schemaDef.prototype, propertyName) ?? []),
          ...validators,
        ],
        schemaDef.prototype,
        propertyName
      );
  }
};

// For ease of transition not forcing all uses of defineFieldValidators right away
export const defineFieldValidators = appendFieldValidators;

/////////////////////////////////////////////////////////////
// Schema Options
const SCHEMA_OPTION_KEY = Symbol('schemaOptions');
export const getSchemaOptions = <T>(c: Constructor<T>): SchemaOptions<T> | undefined => Reflect.getMetadata(SCHEMA_OPTION_KEY, c);
export const setSchemaOptions = <T>(c: Constructor<T>, opts: SchemaOptions<T>): void => Reflect.defineMetadata( SCHEMA_OPTION_KEY, { ...opts }, c, );

////////////////////////////////////////////////////////////
// Table/Columns
const TABLE_KEY = Symbol('table');
/** Decorator to set tableName metadata on target Ctor */
export const tableName = (name: string) => Reflect.metadata(TABLE_KEY, name);
/** From associated metadata decorator on given Ctor */
export const getTable = <T, C extends Constructor<T>>(c: C): string | undefined => Reflect.getMetadata(TABLE_KEY, c);

const ASSOCIATION_KEY = Symbol('association');
const makeDecoratorForFK = <T extends (typeof FKSpec)['belongsTo' | 'hasMany' | 'hasOne' | 'manyToMany']>
  (fn: T) =>
  (table: string, options?: Arg4<T>) =>
  (target, key) => Reflect.defineMetadata(
    ASSOCIATION_KEY,
    fn(
      getTable(target) ?? _.camelCase(pluralize(target.constructor?.name ?? target.name)),
      key,
      table,
      options as shame<'only manyToMany requires options -- let runtime checks guard this.'>
    ),
    target,
    key
  );

export const belongsTo      =    makeDecoratorForFK(FKSpec.belongsTo);
export const hasMany        =    makeDecoratorForFK(FKSpec.hasMany);
export const hasOne         =    makeDecoratorForFK(FKSpec.hasOne);
export const manyToMany     =    makeDecoratorForFK(FKSpec.manyToMany);
export const getAssociation = <T>(c: Constructor<T>, column: keyof T): FKSpec => Reflect.getMetadata( ASSOCIATION_KEY, c.prototype, column);

const GQL_RESOLVER_KEY = Symbol('gqlResolver');
type GqlResolver<T> = (obj: T, _: any, context: any) => any;
export const gqlResolver = <T>(resolver: GqlResolver<T>) => Reflect.metadata(GQL_RESOLVER_KEY, { gqlResolver: resolver });
export const getGqlResolver = <T>(c: Constructor<T>, column: keyof T)         => Reflect.getMetadata(GQL_RESOLVER_KEY, c.prototype, column);
