import { connect } from 'react-redux';
import * as Actions from './actions';
import * as State from 'client/state/state';
import { propToComponent } from 'client/hoc/hoc';
import { ImportDistributionRackShippingWorksheetModal, ComponentProps as ImportCustomerOrderSpreadsheetModalComponentProps } from './ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { ImportDistributionRackShippingWorksheetImportState } from './state';

export interface OwnProps {
  routePlanId: RoutePlanId;
}

interface StateProps {
  buttonDisabled: boolean;
  buttonIsCloseButton: boolean;
  cancelButtonDisabled: boolean;
  isShown: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    isShown: state.importDistributionRackShippingWorksheet.isModalShown,
    cancelButtonDisabled: state.importDistributionRackShippingWorksheet.importState === ImportDistributionRackShippingWorksheetImportState.Imported,
    buttonDisabled: state.importDistributionRackShippingWorksheet.importState !== ImportDistributionRackShippingWorksheetImportState.Parsed &&
      state.importDistributionRackShippingWorksheet.importState !== ImportDistributionRackShippingWorksheetImportState.Imported,
    buttonIsCloseButton: state.importDistributionRackShippingWorksheet.importState === ImportDistributionRackShippingWorksheetImportState.Imported,
  };
};

interface DispatchProps {
  handleCancelButtonClicked: () => void;
  handleImportButtonClicked: (routePlanId: number) => void;
}

const mapDispatchToProps: DispatchProps = {
  handleCancelButtonClicked: Actions.cancelButtonClicked,
  handleImportButtonClicked: Actions.importButtonClicked,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<ImportCustomerOrderSpreadsheetModalComponentProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ImportDistributionRackShippingWorksheetModal) as Component<OwnProps>;

export const ImportDistributionRackShippingWorksheetModalContainer = (props: OwnProps) => propToComponent(component, props);
