import { tableName, property, setSchemaOptions, belongsTo, definePresentation, hasMany } from './dsl';
import { User, UserId } from 'shared/schemas/user';
import { DateTimeStr } from 'shared/types';
import { IRecord } from 'shared/schemas/record';
import { DISPLAY_TYPES, TYPES, INPUT_TYPES } from 'shared/types';
import { displayType } from './dsl';
import { BolDetail } from 'shared/schemas/bol-detail';
import { UserDisplayValueResolver, CartTrackingSupplierEntryDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { CartTrackingSupplierEntry, CartTrackingSupplierAdjustmentType } from 'shared/schemas/cart-tracking-supplier-entry';
import { ReceivableOrder } from './receivable-order';

export type BolId = Flavor<number, 'bolId'>;
export type BolNotes = Flavor<string, 'bolNotes'>;
export type BolReceivedAt = Flavor<DateTimeStr, 'bolReceivedAt'>;
export type BolIdentifier = Flavor<string, 'bolIdentifier'>;
export type BolReceiver = Flavor<string, 'bolReceiver'>;
export type BolReceivedByInitials = Flavor<string, 'bolReceivedBy'>;
export type BolRacksReturned = Flavor<number, 'bolRacksReturned'>;
export type BolPackQuantityReceived = Flavor<number, 'bolPacksReceived'>;
export type BolShippingUnitQuantityReceived = Flavor<number, 'bolShippingUnitsOrdered'>;
export type BolAutoCartTrackingEnabled = Flavor<boolean, 'bolAutoCartTrackingEnabled'>;

@tableName('bols')
export class Bol implements IRecord {
  static readonly UniqueConstraints: Array<keyof Bol> = ['identifier', 'receivableOrder'];
  id?: BolId;

  @belongsTo('receivableOrders')
  @property receivableOrder: ReceivableOrder;
  receivableOrderId: number;

  @property notes: BolNotes | null;
  @property receivedAt: BolReceivedAt;
  @property identifier: BolIdentifier;

  @belongsTo('users', { nativeTableFK: 'userId', foreignDisplayKey: 'firstName', foreignQueryKeys: ['username'] })
  @property user: User;
  userId: UserId; // Not redundant, this is our internal representation of the receiver
  @property receivedByInitials: BolReceivedByInitials; // Not redundant, this is how the receiver is synced between VBills and MSync
  @property receiver: BolReceiver; // Not redundant, this is the display name of the receiver (server side calculated for excel / pdf downloads)

  @hasMany('bolDetails')
  @property bolDetails: BolDetail[];

  @property racksReturned?: BolRacksReturned;

  @property packQuantityReceived: BolPackQuantityReceived;
  @property shippingUnitQuantityReceived: BolShippingUnitQuantityReceived;

  @hasMany('cartTrackingSupplierEntries', { foreignDisplayKey: 'id' })
  @property cartTrackingSupplierEntry?: CartTrackingSupplierEntry[];

  @property autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
}

// Validators moved to server/validations

definePresentation(Bol, {
  identifier: {
    displayName: 'BOL #',
    type: TYPES.STRING,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  receivedAt: {
    displayName: 'Receive Time',
    type: TYPES.DATE_TIME,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  user: {
    displayName: 'Receiver',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { displayValueResolver: UserDisplayValueResolver }),
    tableDisplay: true,
    sortable: true,
    filterable: true,
  },
  racksReturned: {
    displayName: 'Racks Returned',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    tableDisplay: true,
    sortable: true,
    filterable: false,
    calculationSpec: {
      dependencies: {
      },
      calculation: (bol: Bol) => {
        return `(
          SELECT SUM(cart_tracking_supplier_entries.quantity)
          FROM cart_tracking_supplier_entries
          WHERE bols.id = cart_tracking_supplier_entries.bol_id
          AND cart_tracking_supplier_entries.adjustment_type = 'Returned'
        )`;
      },
    },
  },
  notes: {
    displayName: 'Notes',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  receiver: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {
      },
      calculation: (bol: Bol) => {
        return `(SELECT first_name || ' ' || SUBSTRING(last_name FROM 1 FOR 1) || '.' FROM users WHERE id = bols.user_id)`;
      },
    },
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  packQuantityReceived: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {
      },
      calculation: (bol: Bol) => {
        return `
        (
          SELECT
            SUM(packs_received)
          FROM
            bol_details
          WHERE
            bol_details.bol_id = ${bol.id}
        )`;
      },
    },
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  shippingUnitQuantityReceived: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {
      },
      calculation: (bol: Bol) => {
        return `
        (
          SELECT
            SUM(data.shipping_unit_quantity_received)
          FROM
            (
              SELECT
                SUM(packs_received) / supplier_order_product_groups.packs_per_shipping_unit AS shipping_unit_quantity_received
              FROM
                bol_details
              JOIN
                supplier_order_product_groups on supplier_order_product_groups.receivable_order_detail_id = bol_details.receivable_order_detail_id
              WHERE
                bol_details.bol_id = ${bol.id}
              GROUP BY
                supplier_order_product_groups.packs_per_shipping_unit
            ) data
        )`;
      },
    },
    tableDisplay: true,
    sortable: true,
    filterable: false,
  },
  receivedByInitials: {
    displayName: 'Received By',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
  cartTrackingSupplierEntry: {
    displayName: 'Cart Tracking Supplier Entry',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { displayValueResolver: CartTrackingSupplierEntryDisplayValueResolver }),
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
  autoCartTrackingEnabled: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXT }),
    type: TYPES.BOOLEAN,
    calculationSpec: {
      dependencies: {
      },
      calculation: (bol: Bol) => {
        return `(
          SELECT
          CASE WHEN SUM(received) = 1 AND SUM(returned) = 1 THEN TRUE ELSE FALSE END
          FROM
            (
              SELECT
                COUNT(*) AS received,
                0 as returned
              FROM
                cart_tracking_supplier_entries
              WHERE
                bol_id = ${bol.id}
                AND adjustment_type = '${CartTrackingSupplierAdjustmentType.Received}'
              UNION ALL
              SELECT
                0 as received,
                COUNT(*) AS returned
              FROM
                cart_tracking_supplier_entries
              WHERE
                bol_id = ${bol.id}
                AND adjustment_type = '${CartTrackingSupplierAdjustmentType.Returned}'
            ) nums
        )`;
      },
    },
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
});

setSchemaOptions(Bol, {
  hasTimestamps: true,
  uniqueConstraints: Bol.UniqueConstraints,
});
