import * as BS from 'client/components/third-party';
import * as InvoiceRecordBar from '../invoice-record-bar';
import * as React from 'react';
import InvoiceTable from '../invoice-table';
import Sidebar from '../sidebar';
import InvoiceStatsRow from '../stats-row';
import { useRouterParamsId } from 'client/hooks/use-router-params-id';

export interface UIProps {
  invoiceId: number;
}

export const InvoiceDetailFormUI: React.FC = () => {
  const invoiceId = useRouterParamsId();

  if (!invoiceId) {
    return <div>Unknown invoice</div>;
  }

  return (
    <div id="mfc-page-content" className="record">
      <InvoiceRecordBar.RecordBar invoiceId={invoiceId} />
      <div className="mfc-form">
        <div>
          <div className="mfc-form-details-with-sidebar-but-no-tabs">
            <BS.Col sm={12}>
              <InvoiceStatsRow invoiceId={invoiceId} />
              <InvoiceTable invoiceId={invoiceId} />
            </BS.Col>
          </div>
          <div className="mfc-form-sidebar">
            <Sidebar invoiceId={invoiceId} />
          </div>
        </div>
      </div>
    </div>
  );
};
