import * as classNames from 'classnames';
import * as React from 'react';
import * as Moment from 'moment-timezone';
import { DateRange, ImmutableDateRange, sameDates } from 'shared/types';
import * as $ from 'jquery';
import { formatDate, parseDate, isAfter } from 'shared/helpers/date-helpers';
import { Row, BSDatePicker } from 'client/components/third-party';
import { normalizeNullToUndefined } from 'shared/helpers/andys-little-helpers';

interface Props {
    disabled?: boolean,
    label: string,
    testid: string,
    onSelected: (dateRange: DateRange) => void,
    dateRange?: DateRange | ImmutableDateRange,
    verticalStackLabel?: boolean,
    className?: string,
}
interface State { focused: 'none' | 'start' | 'end' }
function validDate(dateText: string) {
    // OK to use Moment here because it's just validating, not returning anything
    const date = Moment(dateText, 'MM/DD/YYYY');
    return dateText.trim().length > 4
        && dateText.trim().length < 11
        && date.isValid()
        && date.year() > 2000;
}

export class FilterDate extends React.PureComponent<Props, State> {
    private startDatePickerJqueryResult: BSDatePicker;
    private endDatePickerJqueryResult: BSDatePicker;
    private getStartDateInput(): HTMLInputElement { return this.startDatePickerJqueryResult && this.startDatePickerJqueryResult[0] as HTMLInputElement; }
    private getEndDateInput(): HTMLInputElement { return this.endDatePickerJqueryResult && this.endDatePickerJqueryResult[0] as HTMLInputElement; }
    private getStartDateText() { return this.getStartDateInput() && this.getStartDateInput().value || ''; }
    private getEndDateText() { return this.getEndDateInput() && this.getEndDateInput().value || ''; }
    private isRangeEmpty() { return this.getStartDateText() === '' && this.getEndDateText() === ''; }
    private receivingProps = true; // used to prevent feedback loops when copying new props to jQuery state
    private filterDateRoot?: HTMLDivElement = undefined;
    componentDidMount() {
        this.startDatePickerJqueryResult = $('input[name="startDate"]', this.filterDateRoot);
        this.endDatePickerJqueryResult = $('input[name="endDate"]', this.filterDateRoot);

        // http://bootstrap-datepicker.readthedocs.io/en/latest/
        ($('.input-daterange', this.filterDateRoot) as BSDatePicker)
            .datepicker({
                assumeNearbyYear: true,
                calendarWeeks: false,
                updateViewDate: true,
                keepEmptyValues: true,
                maxViewMode: 2,
                templates: {
                    leftArrow: '<i class="fa fa-chevron-left"></i>',
                    rightArrow: '<i class="fa fa-chevron-right"></i>',
                },
            })
            .on('changeDate', e => {
                const rangeFirstEntered = !this.props.dateRange || !this.props.dateRange.startDate || !this.props.dateRange.endDate;
                this.setDateRange();

                // don't mess with autohiding and focusing if the change is due to receiving props
                // (prevents bug that shows all the calendars at once without user input)
                if (this.receivingProps)
                    return;

                const startDatePickerFocused = this.state.focused === 'start';
                const endDatePickerFocused = this.state.focused === 'end';
                // if nothing in the end date, and mouse is used to choose a start date, automatically tab to next picker / focus it
                // ... plus vice versa
                if (validDate(this.getStartDateText()) && startDatePickerFocused) {
                    this.startDatePickerJqueryResult.datepicker('hide');
                    this.getEndDateInput().focus();
                } else if (validDate(this.getEndDateText()) && endDatePickerFocused) {
                    this.endDatePickerJqueryResult.datepicker('hide');
                    if (this.getStartDateText().trim().length === 0)
                        this.getStartDateInput().focus();
                } else if (rangeFirstEntered) {
                    this.endDatePickerJqueryResult.datepicker('hide');
                    this.startDatePickerJqueryResult.datepicker('hide');
                }
            });

        if (this.props.dateRange && this.props.dateRange.startDate && this.props.dateRange.endDate) {
            this.startDatePickerJqueryResult.datepicker('setDate', formatDate(this.props.dateRange.startDate, 'MM/DD/YYYY'));
            this.endDatePickerJqueryResult.datepicker('setDate', formatDate(this.props.dateRange.endDate, 'MM/DD/YYYY'));
        }

        this.receivingProps = false;
    }

    // copy new props into jQuery
    componentWillReceiveProps(nextProps: Props) {
        if (!this.startDatePickerJqueryResult || !this.endDatePickerJqueryResult || sameDates(this.props.dateRange, nextProps.dateRange))
            return;

        this.receivingProps = true;
        if (nextProps.dateRange && nextProps.dateRange.startDate && nextProps.dateRange.endDate) {
            this.startDatePickerJqueryResult.datepicker('setDate', formatDate(nextProps.dateRange.startDate, 'MM/DD/YYYY'));
            this.endDatePickerJqueryResult.datepicker('setDate', formatDate(nextProps.dateRange.endDate, 'MM/DD/YYYY'));
        } else {
            this.getStartDateInput().value = '';
            this.getEndDateInput().value = '';
            this.startDatePickerJqueryResult.datepicker('clearDates');
            this.endDatePickerJqueryResult.datepicker('clearDates');
        }

        this.receivingProps = false;
    }

    // send new jQuery value to parents; do not send value to jQuery, jQuery gets that directly from DOM events
    private setDateRange() {
        if (this.receivingProps)
            return;

        const start = this.getStartDateText();
        const end = this.getEndDateText();
        if (!validDate(start) || !validDate(end))
            return;

        const startDate = parseDate(start, 'MM/DD/YYYY');
        const endDate = parseDate(end, 'MM/DD/YYYY');
        if (isAfter(startDate, endDate))
            return;

        const modifiedDateRange = { startDate, endDate };
        if (sameDates(this.props.dateRange, modifiedDateRange))
            return;

        this.props.onSelected(modifiedDateRange);
    }

    onFocus = (focused: 'start' | 'end') => (e: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ focused });
        (e.target as HTMLInputElement).select();
    }

    render() {
        const startInvalid = !this.isRangeEmpty() && !validDate(this.getStartDateText());
        const endInvalid = !this.isRangeEmpty() && !validDate(this.getEndDateText());
        const labelClassName = classNames('mfc-filterbar-label', {
            'mfc-filterbar-label-stacked': !!this.props.verticalStackLabel,
            'disabled': this.props.disabled,
        });
        const label = <div className={labelClassName}>{this.props.label}</div>;
        const verticalLabel = <Row>{label}</Row>;
        return (
            <div ref={e => this.filterDateRoot = normalizeNullToUndefined(e)} className={this.props.className}>
                {!!this.props.verticalStackLabel ? verticalLabel : label}
                <div className="input-daterange input-group" data-testid={this.props.testid}>
                    <input
                        type="text"
                        name="startDate"
                        placeholder="mm/dd/yyyy"
                        className={classNames('input-sm form-control left no-pad-right', { error: startInvalid })}
                        onFocus={this.onFocus('start')}
                        // DK 2019-01-09: I tried replacing this lambda with {this.setDateRange} and it broke everything, so I reverted it. Apparently it's necessary 🤷
                        onBlur={() => this.setDateRange()}
                        disabled={this.props.disabled}
                        autoComplete="off"
                    />
                    <div
                        className={classNames('input-group-addon mfc-date-range-separator no-pad fa fa-play', { error: startInvalid || endInvalid, disabled: this.props.disabled })}
                    />
                    <input
                        type="text"
                        name="endDate"
                        placeholder="mm/dd/yyyy"
                        className={classNames('input-sm form-control right no-pad-left', { error: endInvalid })}
                        onFocus={this.onFocus('end')}
                        // DK 2019-01-09: I tried replacing this lambda with {this.setDateRange} and it broke everything, so I reverted it. Apparently it's necessary 🤷
                        onBlur={() => this.setDateRange()}
                        disabled={this.props.disabled}
                        autoComplete="off"
                    />
                </div>
            </div>
        );
    }
}
