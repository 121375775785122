import { Lens } from '@atomic-object/lenses';
import { LoadConfiguration } from 'shared/types';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { MerchandisersWithStores, StoresWithMerchandisers } from 'schema/route-plan/route-plan-typescript-types';
import { every } from 'lodash';
import { MutationStatus } from 'client/actions/mutations';

export interface CheckableMerchandisersWithStores extends MerchandisersWithStores {
  checked: boolean;
}

export interface CheckableStoresWithMerchandisers extends StoresWithMerchandisers {
  checked: boolean;
}

interface RoutePlanPrintingState {
  readonly downloadDittyCardModalShown: boolean;
  readonly emailMerchandisersModalShown: boolean;
  readonly downloadStoreAsnModalShown: boolean;
  readonly createRoutePacketsModalShown: boolean;
  readonly routeSheetsChecked: boolean;
  readonly deliveryAcknowledgementsChecked: boolean;
  readonly purchaseOrderInvoicesChecked: boolean;
  readonly returnInstructionsChecked: boolean;
  readonly specialInstructionsChecked: boolean;
  readonly markOrdersAsShippedChecked: boolean;
  readonly selectedLoadConfiguration: LoadConfiguration;
  readonly sendLoadsToLoadMasterActionStatus: MutationStatus;
  readonly emailRouteSheetsToPeakActionStatus: MutationStatus;
  readonly downloadStoreAdvanceShipNoticeActionStatus: MutationStatus;
  readonly createCustomerAdvanceShipNoticeActionStatus: MutationStatus;
  readonly downloadStoreDeliverySpreadsheetActionStatus: MutationStatus;
  readonly downloadPalletReportActionStatus: MutationStatus;
  readonly selectedRoutingLoadIds: RoutingLoadId[];
  readonly merchandisersWithStores: CheckableMerchandisersWithStores[];
  readonly storesWithMerchandisers: Array<CheckableStoresWithMerchandisers & {merchandisers: Array<{id: number, name: string}>}>
  readonly merchandiserSearchText: string;
  readonly storeSearchText: string;
}

export const InitialState: RoutePlanPrintingState = {
  downloadDittyCardModalShown: false,
  emailMerchandisersModalShown: false,
  downloadStoreAsnModalShown: false,
  createRoutePacketsModalShown: false,
  routeSheetsChecked: true,
  deliveryAcknowledgementsChecked: true,
  purchaseOrderInvoicesChecked: false,
  returnInstructionsChecked: true,
  specialInstructionsChecked: false,
  markOrdersAsShippedChecked: true,
  selectedLoadConfiguration: LoadConfiguration.Columns,
  sendLoadsToLoadMasterActionStatus: MutationStatus.Initial,
  emailRouteSheetsToPeakActionStatus: MutationStatus.Initial,
  downloadStoreAdvanceShipNoticeActionStatus: MutationStatus.Initial,
  createCustomerAdvanceShipNoticeActionStatus: MutationStatus.Initial,
  downloadStoreDeliverySpreadsheetActionStatus: MutationStatus.Initial,
  downloadPalletReportActionStatus: MutationStatus.Initial,
  selectedRoutingLoadIds: [],
  merchandisersWithStores: [],
  merchandiserSearchText: '',
  storesWithMerchandisers: [],
  storeSearchText: '',
};

export type Type = RoutePlanPrintingState;

export const downloadDittyCardModalShown = Lens.from<Type>().prop('downloadDittyCardModalShown');
export const emailMerchandisersModalShown = Lens.from<Type>().prop('emailMerchandisersModalShown');
export const createRoutePacketsModalShown = Lens.from<Type>().prop('createRoutePacketsModalShown');
export const downloadStoreAsnModalShown = Lens.from<Type>().prop('downloadStoreAsnModalShown');
export const routeSheetsChecked = Lens.from<Type>().prop('routeSheetsChecked');
export const deliveryAcknowledgementsChecked = Lens.from<Type>().prop('deliveryAcknowledgementsChecked');
export const purchaseOrderInvoicesChecked = Lens.from<Type>().prop('purchaseOrderInvoicesChecked');
export const returnInstructionsChecked = Lens.from<Type>().prop('returnInstructionsChecked');
export const specialInstructionsChecked = Lens.from<Type>().prop('specialInstructionsChecked');
export const markOrdersAsShippedChecked = Lens.from<Type>().prop('markOrdersAsShippedChecked');
export const selectedLoadConfiguration = Lens.from<Type>().prop('selectedLoadConfiguration');
export const sendLoadsToLoadMasterActionStatus = Lens.from<Type>().prop('sendLoadsToLoadMasterActionStatus');
export const emailRouteSheetsToPeakActionStatus = Lens.from<Type>().prop('emailRouteSheetsToPeakActionStatus');
export const downloadStoreAdvanceShipNoticeActionStatus = Lens.from<Type>().prop('downloadStoreAdvanceShipNoticeActionStatus');
export const createCustomerAdvanceShipNoticeActionStatus = Lens.from<Type>().prop('createCustomerAdvanceShipNoticeActionStatus');
export const downloadStoreDeliverySpreadsheetActionStatus = Lens.from<Type>().prop('downloadStoreDeliverySpreadsheetActionStatus');
export const downloadPalletReportActionStatus = Lens.from<Type>().prop('downloadPalletReportActionStatus');
export const selectedRoutingLoadIds = Lens.from<Type>().prop('selectedRoutingLoadIds');
export const merchandisersWithStores = Lens.from<Type>().prop('merchandisersWithStores');
export const merchandiserSearchText = Lens.from<Type>().prop('merchandiserSearchText');
export const storesWithMerchandisers = Lens.from<Type>().prop('storesWithMerchandisers');
export const storeSearchText = Lens.from<Type>().prop('storeSearchText');

export const isMerchandisersSelectAllChecked = Lens.of<Type, boolean>(
  {
    get: state => state.merchandisersWithStores.length > 0 && every(state.merchandisersWithStores, (merchandiserWithStore => merchandiserWithStore.checked)),
    set: (state, value) => {
      return {
        ...state,
        merchandisersWithStores: state.merchandisersWithStores.map(merchandiserWithStore => {
          return {
            ...merchandiserWithStore,
            checked: value,
          };
        }),
      };
    },
  },
);

export const isStoresSelectAllChecked = Lens.of<Type, boolean>(
  {
    get: state => state.storesWithMerchandisers.length > 0 && every(state.storesWithMerchandisers, (storeWithMerchandiser => storeWithMerchandiser.checked)),
    set: (state, value) => {
      return {
        ...state,
        storesWithMerchandisers: state.storesWithMerchandisers.map(storeWithMerchandiser => {
          return {
            ...storeWithMerchandiser,
            checked: value,
          };
        }),
      };
    },
  },
);
