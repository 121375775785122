import { tableName, property, belongsTo, hasMany, definePresentation, setSchemaOptions, required } from './dsl';
import { DISPLAY_TYPES, ActiveInactive, SORT_TYPES } from '../types';
import { ProductSubClass } from './product-sub-class';
import { SellDepartment } from './sell-department';
import { IRecord } from './record';
import { displayType } from './dsl';

export type ProductClassId = number;

@tableName('productClasses')
export class ProductClass implements IRecord {
  id?: ProductClassId;

  @belongsTo('sellDepartments')
  @property @required sellDepartment: SellDepartment;
  sellDepartmentId: number;

  @property @required identifier: string;
  @property @required activeStatus: ActiveInactive;

  @hasMany('productSubClasses')
  @property productSubClasses: ProductSubClass[];
}

definePresentation(ProductClass, {
  sellDepartment: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  identifier: {
    sortable: true,
    searchable: true,
    displayName: 'Product Class',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 65,
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  productSubClasses: {
    formDisplay: false,
  },
});

setSchemaOptions(ProductClass, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'sellDepartment', foreignColumn: 'identifier' },
});
