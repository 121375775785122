import * as React from 'react';

interface YesNoCellTypes {
  editing: boolean;
  focused: boolean;
  value: boolean;
}

const YesNoCell = ({ value, focused, editing }: YesNoCellTypes) => {
  return (
    <div>
      {value ? 'Yes' : '–'}
    </div>
  );
};

export default YesNoCell;
