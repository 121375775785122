import * as _ from 'lodash';
import * as Constants from '../constants';
import * as React from 'react';
import * as Types from '../types';
import { Checkbox } from 'client/components/form/checkbox';

interface EmailRecipientsProps {
  recipients: Types.CheckableEmailRecipient[];
  recipientClicked(recipient: Types.EmailRecipient): void;
}

export class EmailRecipients extends React.Component<EmailRecipientsProps, {}> {
  render() {
    const recipientCheckboxes = this.props.recipients.map(recipient => {
      const checkboxInputChangeHandler = {
        onChange: () => {
          this.props.recipientClicked(recipient);
        },
        value: recipient.checked,
      };
      return (
        <div key={`${recipient.name}-${recipient.emailAddress}`} data-testid={`recipient-${_.kebabCase(recipient.name)}`}>
          <Checkbox className={Constants.ModalFormatting.Checkbox} input={checkboxInputChangeHandler} />
          <div className={Constants.ModalFormatting.RecipientLabel} onClick={() => checkboxInputChangeHandler.onChange()}>{recipient.name}</div>
        </div>
      );
    });
    return (
      <div>
        {recipientCheckboxes}
      </div>
    );
  }
}
