import { Saved } from 'shared/schemas/record';
import { CustomerOrderProduct } from 'shared/schemas/customer-order-product';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { ComboProductGroupId } from 'shared/schemas/combo-product-group';
import { LatLong } from 'shared/types/lat-long';

export type Json = SimpleObject;

export const FULL_RACK_IDENTIFIER = 'FR';
export const FULL_RACK_DESCRIPTION = 'Full Rack';
export const FULL_CASE_IDENTIFIER = 'FR';
export const FULL_CASE_DESCRIPTION = 'Full Case';
export const MASTERPIECE_LAT_LONG: LatLong = { latitude: 42.816, longitude: -85.68162 };
export const WHITEWATER_LAT_LONG: LatLong = { latitude: 42.85586, longitude: -88.73367 };

export const MFC_LONG = 'Masterpiece Flower Company';
export const MFC_ABBREV = 'MFC';

// === Known Customers === //
export enum KnownCustomer {
  /** MMC is a concept only in ACUMATICA at the moment */
  MeijerCutFloral = 'MMC',
  Meijer = 'MMM',
  HomeDepot = 'HHH',
  Costco = 'CCC',
}

export enum KnownVendor {
  MFC = 'MFC',
}

export const MEIJER: KnownCustomer.Meijer = KnownCustomer.Meijer;
export const HOME_DEPOT: KnownCustomer.HomeDepot = KnownCustomer.HomeDepot;
export const COSTCO: KnownCustomer.Costco = KnownCustomer.Costco;

export function isKnownCustomer(identifier: string): identifier is KnownCustomer {
  return Object.keys(KnownCustomer).map(c => KnownCustomer[c]).includes(identifier);
}

// === Sell Department === //
export enum KnownSellDepartment {
  IndoorFloral = 'INDOOR FLORAL',
  OutdoorGarden = 'OUTDOOR GARDEN',
  Misc = 'MISC',
  Unassigned = 'UNASSIGNED',
}

// === Sub Sell Department === //
export enum KnownSubSellDepartment {
  CutFloral = 'Cut Floral',
  PottedFloral = 'Potted Floral',
}

export enum BooleanFilters {
  Yes = 'Yes',
  No = 'No',
}

export enum FloralShipTypes {
  Wet = 'Wet',
  Dry = 'Dry',
}

export enum DeliveryMethods {
  Pickup = 'Pick Up',
  Deliver = 'Deliver',
}

export enum ActiveInactive {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ProductPriceChangeReasons {
  Regular = 'Regular Retail / Price',
  AD = 'AD - Reduced Retail',
  UAD = 'UAD - Reduced Retail',
  QualityIssue = 'Quality Issue - Reduced Retail',
  SeasonalMarkdown = 'Seasonal Markdown',
  Other = 'Other',
}

export enum SupplierItemCostChangeReasons {
  SupplierCommitment = 'Created from Supplier Commitment',
  SupplierCommitmentCostUdate = 'Supplier Commitment Cost Update',
  Regular = 'Regular Cost',
  AD = 'AD - Reduced Retail',
  UAD = 'UAD - Reduced Retail',
  QualityIssue = 'Quality Issue',
  TimingIssue = 'Timing Issue',
  SeasonalMarkdown = 'Seasonal Markdown',
  SupplierPromotion = 'Supplier Promotion',
  Other = 'Other',
}

// === Shipping Unit === //
export enum ShippingUnitType {
  Rack = 'Rack',
  Pallet = 'Pallet',
  NotApplicable = 'N/A',
}

export type RackShippingUnit = ShippingUnitType.Rack;
export type PalletShippingUnit = ShippingUnitType.Pallet;
export type NotApplicableShippingUnit = ShippingUnitType.NotApplicable;

export const RackShippingUnit: RackShippingUnit = ShippingUnitType.Rack; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export const PalletShippingUnit: PalletShippingUnit = ShippingUnitType.Pallet; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export const NotApplicableShippingUnit: NotApplicableShippingUnit = ShippingUnitType.NotApplicable; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

// === Order Method === //
export enum OrderMethod {
  Pack = 'Pack',
  ShippingUnit = 'Shipping Unit',
}

export type ShippingUnitOrderMethod = OrderMethod.ShippingUnit;
export type PackOrderMethod = OrderMethod.Pack;

export const ShippingUnitOrderMethod: ShippingUnitOrderMethod = OrderMethod.ShippingUnit; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export const PackOrderMethod: PackOrderMethod = OrderMethod.Pack; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export enum SalesPlanIdentifier {
  ValentinesEaster = 'Valentines/Easter',
  SpringSummer = 'Spring/Summer',
  Fall = 'Fall',
  Christmas = 'Christmas',
  Weekly = 'Weekly',
}

export enum PrimaryGlobal {
  Primary = 'Primary',
  Global = 'Global',
}

export enum PrimaryGlobalAll {
  Primary = 'Primary',
  Global = 'Global',
  All = 'All',
}

export enum CustomerOrderType {
  Replenishment = 'Replenishment',
  SpecialOrder = 'Special Order',
  PO = 'PO',
  ExternalDistribution = 'External Distribution',
}

export enum SupplierOrderTypes {
  Replenishment = 'Replenishment',
  SpecialOrder = 'Special Order',
  WarehouseSupplies = 'Warehouse Supplies',
  HardGoods = 'Hard Goods',
  External = 'External',
}

export enum CustomerOrderStatus {
  Draft = 'Draft',
  Approved = 'Approved',
}

export enum SupplierOrderStatus {
  Draft = 'Draft',
  Sent = 'PO Sent',
}

export enum FobLocations {
  Mfc = 'MFC',
  Supplier = 'Supplier',
  Store = 'Store',
  Port = 'Port'
}

export interface ComboCart {
  id: number;
  identifier: string;
  description: string;
  products: Array<Pick<Saved<CustomerOrderProduct>, 'id' | 'packSize' | 'packsPerShippingUnit' | 'shelvesPerRack' | 'packsPerShelf' | 'productId'> & { product: { identifier: string, description: string } }>;
}

export interface EditComboCartPayload {
  id: number;
  description: string;
  identifier: string;
  addedProducts: Array<{
    productId: number,
    packsPerShippingUnit: number,
    packSize: number,
    shelvesPerRack: number,
  }>;
  removedCustomerOrderProductIds: number[];
  products: any[];
}

export interface ComboProductGroupResponseComboProduct {
  packSize: number;
  supplierItemId: SupplierItemId;
}

export interface ComboProductGroupResponse {
  id: ComboProductGroupId;
  identifier: string;
  description: string;
  packsPerShippingUnit: number;
  comboProducts: ComboProductGroupResponseComboProduct[];
}

/** No, this does not identify Global stores.  It is the Natural Key of stores inherited from legacy VBills design, and incorporated (unfortunately) into m-sync's Transportation subsystem. */
export interface GlobalStoreIdentifier {
  customerIdentifier: string;
  storeNumber: string;
}

export interface StopIdentifier {
  storeId: number;
  routingLoadId: number;
}

export enum KnownSupplierId {
  HMG = 28105,
}

export enum KnownHmgLocation {
  Hudsonville = 'Hudsonville',
  North = 'North',
  Cooler = 'Cooler',
  South = 'South',
  West = 'West',
}

export enum KnownAdjustmentType {
  Count = 'count',
  Toss = 'toss',
  Reduction = 'reduction',
  Addition = 'addition',
}

export enum Known820SourceType {
  /** Consolidated Invoice Number */ _2F = '2F',
  /* Buyer's Credit Memo          */  CM = 'CM',
  /* Outbound-from Party          */  OS = 'OS',
  /* Seller's Invoice Number      */  IV = 'IV',
}

export enum Known820ReasonCode {
  /** Payment                */ PT = 'PT',
  /** Covered by Credit Memo */ CM = 'CM',
}

export enum Known820ReferenceType {
  PO = 'PO',
  RZ = 'RZ',
}

/**
 * | Partner    | EDI Doc Type | Description       | Flatfile Path | dhmgsys field | Vbills Tables                   | Notes
 * | ---        | ---          | ---               | ---           | ---           | ---                             | ---
 * | Meijer     | 810          | Invoice           |               |               |                                 |
 * |            | 812          | Credit Memo       |               |               |                                 |
 * |            | 820          | Remittance Advice |               |               |                                 |
 * |            | 850          | PO                | DX-FX-VF.214  | stxtflatmpo   | MMME PROD                       | MDVE MDOH MDIT MDOD, if Meijer Distribution
 * |            | 852          | Sales Activity    | DX-XF-VF.204  | stxflat852    | PACE PACT PROD STORE PRICE PRMI | filename also mapped in stxflatneal; scans and po's alike
 * | Home Depot | 810          | Invoice           |               |               |                                 |
 * |            | 812          | Credit Memo       | DX-XF-VF.250  | stxflat812hd  | HDCM                            |
 * |            | 820          | Remittance Advice | DX-XF-VF.080  | stxflathd820  | HDCD HDCH                       | 850CN expected already processed
 * |            | 852          | Sales Activity    | DX-XF-VF.176  | stxflat852hd  | HACT                            | PO sales only (not scans, unlike for meijer)
 * |            | 850 CN       | PO                | DX-XF-VF.217  | stxflathdpo   | HACT PRMI HDCD HDPP             | what does CN indicate?
 * |            | 850 non CN   | PO                | DX-XF-VF.217  | stxflathdpo   | HHHE                            |
 * | Unknown    |              |                   | DX-FX-VF.080  | stxflatinv    |                                 | unused? also mapped stxflatminv
 * |            |              |                   | DX-FX-VF.081  | stxflatdinv   |                                 | unused?
 * |            |              |                   | DX-FX-VF.082  | stxflatfinv   |                                 | unused?
 * |            |              |                   | DX-XF-VF.240  | stxflatfpo    |                                 | unused?
 * names of these files is determined by TrustedLink and is meaningful based on TL export params:
 * DX-  : Data eXport
 * -XF- : inbound to M-Sync
 * -FX- : outbound from M-Sync
 * -VF  : Variable line count, fixed line width
 * .### : max char per line
 */
export enum KnownEdiFileNames {
  CreditDebitAdjustmentDocument812 = 'DX-XF-VF.250',
  HomeDepot820RemittanceAdvice = 'DX-XF-VF.080',
  HomeDepotSalesActivityDocument852 = 'DX-XF-VF.176',
  ProductActivityDataDocument852 = 'DX-XF-VF.204',
  MeijerPurchaseOrderDocument850 = 'DX-XF-VF.214',
  HomeDepotPurchaseOrderDocument850 = 'DX-XF-VF.217',

  Meijer810Invoice = 'DX-FX-VF.080',
  HomeDepot810Invoice = 'DX-FX-VF.081',
}
