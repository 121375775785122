import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/supplier-commitment/types';
import * as SharedQuery from 'client/app/planning/supplier-commitments/shared/query';

export type CreateSupplierCommitmentInput = GraphQLTypes.CreateSupplierCommitmentInput;

export type CreateSupplierCommitmentResponse = SharedQuery.QueryResponse;

export const CreateSupplierCommitmentMutation = gql`
  mutation CreateSupplierCommitmentMutation($input: CreateSupplierCommitmentInput!) {
    supplierCommitment: CreateSupplierCommitment(input: $input) {
      ...SupplierCommitmentFragment
    }
  }
  ${SharedQuery.SupplierCommitmentFragment}
`;

export interface DownloadSupplierCommitmentMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadSupplierCommitmentMutationInput {
  supplierCommitmentId: number;
}
export const DownloadSupplierCommitmentMutation = gql`
  mutation DownloadSupplierCommitmentMutation($supplierCommitmentId: Int!) {
    response: DownloadSupplierCommitment(supplierCommitmentId: $supplierCommitmentId) {
      report {
        id
      }
    }
  }
`;

export interface DownloadSupplierSpecSheetPdfMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadSupplierSpecSheetPdfMutationInput {
  supplierCommitmentId: number;
}

export const DownloadSupplierSpecSheetPdfMutation = gql`
  mutation DownloadSupplierSpecSheetPdfFromSidebarMutation($supplierCommitmentId: Int!) {
    response: DownloadSupplierSpecSheetPdf(supplierCommitmentIds: [$supplierCommitmentId]) {
      report {
        id
      }
    }
  }
`;
