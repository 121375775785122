import FormComponent from 'client/components/crud-forms/box-form';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminDetailPage({
  formName: 'BoxDetailsForm',
  table: 'Box',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
