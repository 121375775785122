import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { BulkAddProductsModal, Props as BulkAddProductsModalProps } from 'client/app/orders/supplier-orders/overview/modals/bulk-add-products-modal';
import assertCompatible from 'shared/helpers/assert-compatible';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import gql from 'graphql-tag';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { WithSupplierItemsProps, withSupplierItems } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import { msyncMutation } from 'client/hoc/graphql/mutation';

const FORM_NAME: string = 'supplier-order-add-new-product';

export interface OwnProps {
  show: boolean;
  onClose: () => void;
  refetchTable: () => void;
  userConfirmation: () => Promise<boolean | void>;

  supplierOrderId?: number;
  supplierId: number;
  customerId: number;
  sellDepartmentId: number;
  sellDepartmentIdentifier: string;

  entryLabel: string;

  name: string;
  isModal: boolean;
  tableContent: any[];
}

export interface StateProps {
  formName: string;
}

interface WithMutationProps {
  bulkAddSupplierItemsToSupplierOrder: (payload: { supplierItemIds: SupplierItemId[], quantity: number }) => Promise<void>;
}

interface ReduxFormProps {
  handleSubmit: () => void;
  reset: () => void;
}

export const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    formName: FORM_NAME,
  };
};

const mutation = gql`
  mutation BulkAddSupplierItemsToSupplierOrder($supplierOrderId: Int!, $supplierItemIds: [Int!], $quantity: Int!) {
    result: bulkAddSupplierItemsToSupplierOrder(supplierOrderId: $supplierOrderId, supplierItemIds: $supplierItemIds, quantity: $quantity) {
      id
      supplierOrderProductGroup {
        supplierOrder {
          id
          lastModifiedAt
        }
      }
    }
  }
`;

interface BulkAddSupplierItemsToSupplierOrderInput {
  supplierItemIds: SupplierItemId[];
  quantity: number;
}

interface AddSupplierItemsToSupplierOrderMutationVariables extends BulkAddSupplierItemsToSupplierOrderInput {
  supplierOrderId: SupplierOrderId;
}

const withMutation = msyncMutation <{}, OwnProps, { bulkAddSupplierItemsToSupplierOrder: (payload: BulkAddSupplierItemsToSupplierOrderInput) => Promise<void> }, AddSupplierItemsToSupplierOrderMutationVariables>(mutation, {
  props: props => ({
    bulkAddSupplierItemsToSupplierOrder: async (payload: BulkAddSupplierItemsToSupplierOrderInput) => {
      if (_.isNil(props.ownProps.supplierOrderId)) {
        // this should never happen X-)
        throw new Error('Bug: at this point in dataflow, supplierOrderId should never be nil.');
      }

      await props.mutate({
        variables: {
          ...payload,
          supplierOrderId: props.ownProps.supplierOrderId,
        },
      });
    },
  }),
});

assertCompatible<BulkAddProductsModalProps, StateProps & OwnProps & WithSupplierItemsProps & WithMutationProps & ReduxFormProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  withSupplierItems({ filterExistingSupplierItems: true, activeOnly: true }),
  withMutation,
  ReduxForm.reduxForm({ form: FORM_NAME, enableReinitialize: true }),
)(BulkAddProductsModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
