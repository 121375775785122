import * as React from 'react';
import { StatusPill } from 'client/components/status-pill';
import { TYPES } from 'shared/types';

interface YesNoNullCellTypes {
  editing: boolean;
  focused: boolean;
  value: boolean;
}

// This cell type is for boolean values that should only be selectively displayed; i.e., some will be true or false, but some will also
// have a dash or not have an actual displayed for some reason. This type exists for whenever there are 3 possible display values for a boolean
const YesNoNullCell = ({ value, focused, editing }: YesNoNullCellTypes) => {
  let cellValue = '-';
  if (value !== null && value !== undefined) {
   cellValue = value ? 'Yes' : 'No';
  }

  if (cellValue === '-') {
    return <div className="number">{cellValue}</div>;
  }

  return (
    <div className="status-cell">
      <StatusPill status={cellValue} type={TYPES.BOOLEAN}/>
    </div>
  );
};

export default YesNoNullCell;
