import * as React from 'react';
import * as classnames from 'classnames';

export interface ComponentProps {
  label?: string;
  waitToDisplayMillis?: number;
  isTableSpinner?: boolean;
  className?: string;
  style?: React.CSSProperties | undefined;
}

export class ProgressSpinner extends React.Component<ComponentProps, { isHidden: boolean }> {
  private componentIsMounted: boolean = false;

  constructor(props: ComponentProps) {
    super(props);

    const isHidden = !!props.waitToDisplayMillis;
    this.state = { isHidden };
  }

  componentDidMount() {
    this.componentIsMounted = true;
    if (this.props.waitToDisplayMillis) {
      setTimeout(() => {
        // Only show the spinner if still mounted
        if (this.componentIsMounted) {
          this.setState({ isHidden: false });
        }
      }, this.props.waitToDisplayMillis);
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  render() {
    const label = this.props.label !== undefined ? this.props.label : 'Loading...';

    const className = classnames('loading-container', this.props.className);

    if (this.props.isTableSpinner) {
      return (
        <div className={className} style={this.props.style}>
          <div className="loading-overlay">
          </div>

          {!this.state.isHidden &&
            <div className="loading-indicator">
              <div><i className="fa fa-spinner fa-spin fa-5x"></i></div>
              <div className="loading-indicator-text">
                {label}
              </div>
            </div>}
        </div>
      );
    }

    if (this.state.isHidden) {
      return <div></div>;
    }

    return (
      <div className="text-center">
        <span className="fa fa-spinner fa-spin fa-5x progress-spinner-icon" />
        <div className="progress-spinner-label">{label}</div>
      </div>
    );
  }
}
