import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { SalesReportProductDetailLevel, ReportTypes, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';

export const formName = 'dailySalesReportReport';
export const reportType = ReportTypes.SalesReport;

export enum FormFields {
  id = 'id',
  customerId = 'customerId',

  dateRangeYearComparison = 'dateRangeYearComparison',

  sellDepartmentId = 'sellDepartmentId',
  scanBased = 'scanBased',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  productIds = 'productIds',

  primaryGlobalAll = 'primaryGlobalAll',
  mfcAreaIds = 'mfcAreaIds',
  regionIds = 'regionIds',
  marketIds = 'marketIds',
  storeIds = 'storeIds',

  productDetailLevel = 'productDetailLevel',
  emailReportDaily = 'emailReportDaily',
  emailSubject = 'emailSubject',
  emailRecipients = 'emailRecipients',

  includeGrossMargin = 'includeGrossMargin',
}

export interface FormValues {
  [FormFields.id]: number | undefined;
  [FormFields.customerId]: number | undefined;
  [FormFields.dateRangeYearComparison]: DateRangeYearComparisonValue | undefined;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;

  [FormFields.primaryGlobalAll]: PrimaryGlobalAll | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.regionIds]: SelectableValue | undefined;
  [FormFields.marketIds]: SelectableValue | undefined;
  [FormFields.storeIds]: SelectableValue | undefined;

  [FormFields.productDetailLevel]: SalesReportProductDetailLevel | undefined;
  [FormFields.emailReportDaily]: boolean;
  [FormFields.emailSubject]: string | undefined;
  [FormFields.emailRecipients]: string | undefined;

  [FormFields.includeGrossMargin]: boolean;
}
