import * as React from 'react';
import { Nav, Row } from 'client/components/third-party';

interface OwnProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const RecordNavigationBar: React.SFC<OwnProps> = props => {
  return (
    <Row>
      <Nav bsStyle="pills" className="record-nav-bar">
        {props.children}
      </Nav>
    </Row>
  );
};
