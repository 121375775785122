import * as React from 'react';
import { RecordNavItem } from '../../components/record-nav';

const buildRecordNavItems = (storeId: number) => {
  const baseCreateUrl = '/admin/stores/create';
  const baseDetailUrl = `/admin/stores/details/${storeId}`;

  const storeDetailsTab = storeId
    ? <RecordNavItem label="Store Details" key="details" route={`${baseDetailUrl}/info`} />
    : <RecordNavItem label="Store Details" key="details" route={`${baseCreateUrl}`}/>;

  return [
    storeDetailsTab,
    <RecordNavItem label="Contact" key="contact" route={`${baseDetailUrl}/contact`}/>,
    <RecordNavItem label="Transportation" key="transportation" route={`${baseDetailUrl}/transportation`}/>,
    <RecordNavItem label="Attachments" key="attachments" route={`${baseDetailUrl}/attachments`}/>,
  ];
};

export default buildRecordNavItems;
