import * as _ from 'lodash';

export const requiredMessages = {
  shortMessage(value: any, record: any) {
    return 'Required';
  },
  message(label: string, value: any, record: any) {
    return `${label} is required.`;
  },
};

export function isPresent<T>(value: T | null | undefined): value is T {
  if (_.isNil(value)) {
    return false;
  }

  if (typeof value === 'string') {
    return !_.isEmpty(value.trim());
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
}
