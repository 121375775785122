import { createSelector } from 'reselect';
import { flowRight } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { propToComponent } from 'client/hoc/hoc';
import * as Actions from 'client/actions/product-worksheet';
import { reduxForm, reset, InjectedFormProps } from 'redux-form';
import * as State from 'client/state/state';
import * as ProductWorksheetSelectors from 'client/state/product-worksheet-selectors';
import { AutoReplenishmentModalUI } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-modal-ui';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { ShippingUnitTypesQueryResponse, ShippingUnitTypesQuery, ShippingUnitTypesQueryVariables } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/shipping-unit-types-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { ProductId } from 'shared/schemas/product';
import { CustomerOrderProductGroupId, CustomerOrderProductGroupPacksPerShippingUnit } from 'shared/schemas/customer-order-product-group';
import { StoreId } from 'shared/schemas/store';
import * as _ from 'lodash';
import { FULL_RACK_IDENTIFIER, OrderMethod, ShippingUnitOrderMethod, PackOrderMethod } from 'shared/types';
import { CustomerOrderProductPackSize } from 'shared/schemas/customer-order-product';
import { determineUnitsForTargetPercentShipped } from 'client/helpers/product-worksheet';
import { AllocationQueryResponse, AllocationQuery, AllocationQueryVariables } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/allocation-query';
import { createDeepEqualSelector } from 'client/utils/reselect-utils';
import { timeout } from 'shared/helpers/promises';
import { MutationStatus } from 'client/actions/mutations';
import { buildTableStateModule } from 'client/state/tables';
import assertCompatible from 'shared/helpers/assert-compatible';

const formName = 'AutoReplenishmentForm';

export interface ShippedStore {
  storeId: number;
  shipped: number;
  shippedWithDraft: number;
  pieceTarget: number;
}

interface OwnProps {
  customerOrderId: CustomerOrderId;
  productId: ProductId;
  productLabel?: string;
  orderMethod: OrderMethod;
  data: ShippedStore[];
  customerOrderProductGroups: Array<{
    id: CustomerOrderProductGroupId;
    packsPerShippingUnit?: CustomerOrderProductGroupPacksPerShippingUnit;
    packSize: CustomerOrderProductPackSize;
  }>;
  allocateProductToStores: (args: Array<{ storeId: number, quantity: number, customerOrderProductGroupId: number }>) => Promise<void>;
}

interface StateProps {
  isShown: boolean;
  storeIds: StoreId[];
  data: ShippedStore[];
  actionStatus: MutationStatus;
  cancelDisabled: boolean;
}

const TableStateHelpers = buildTableStateModule('productWorksheet');

const mapStateToProps = (state: State.Type, props: OwnProps): StateProps => {
  const storeIdsFromMenuAction = ProductWorksheetSelectors.storeIdsFromMenuAction(state);
  const storeIdsFromCheckedRecords = TableStateHelpers.checkedRecordIds(TableStateHelpers.tableStateLens.get(state));

  let storeIds: number[] = [];

  if (storeIdsFromMenuAction.size === 1) {
    // when an individual store's kebab menu item is clicked
    if (storeIdsFromCheckedRecords.includes(storeIdsFromMenuAction.get(0))) {
      // when the individual store is part of the
      // list of checked stores, then use the
      // entire list of checked stores
      storeIds = storeIdsFromCheckedRecords;
    } else {
      // otherwise, use the individual store
      storeIds = storeIdsFromMenuAction.toJS();
    }
  } else {
    // when the multi-row kebab menu item is clicked (on
    // the header row), use all the checked stores.
    storeIds = storeIdsFromMenuAction.toJS();
  }

  const filteredData = props.data.filter(d => storeIds.includes(d.storeId));

  const actionStatus = ProductWorksheetSelectors.autoReplenishmentMutationStatus(state);
  return {
    isShown: ProductWorksheetSelectors.isAutoReplenishmentModalShown(state),
    storeIds,
    data: filteredData,
    actionStatus,
    cancelDisabled: actionStatus === MutationStatus.InProgress,
  };
};

interface FormValues {
  customerOrderProductGroupId: CustomerOrderProductGroupId;
  targetPercentShipped: string;
}

export interface AutoReplenishmentProps {
  customerOrderProductGroupIds: Array<{
    id: CustomerOrderProductGroupId;
    label: string;
  }>;
  initialValues?: {
    customerOrderProductGroupId?: CustomerOrderProductGroupId;
  };
}

type ShippingUnitAllocation = Flavor<number, 'shippingUnitAllocation'>;
type ShippingUnitAllocationByCustomerOrderProductGroupId = Dictionary<ShippingUnitAllocation>;
type ShippingUnitAllocationByStoreId = Dictionary<ShippingUnitAllocationByCustomerOrderProductGroupId>;

export interface AllocationProps {
  allocations: ShippingUnitAllocationByStoreId;
}

interface CopgForDisplay {
  id: number;
  identifier: string;
  label: string;
}

function getOrderedCopgs(rackTypes: CopgForDisplay[]) {
  const labelOrdered = _.orderBy(rackTypes, ['label']);

  const fullRack: CopgForDisplay[] = labelOrdered.filter(r => r.identifier === FULL_RACK_IDENTIFIER);
  const otherFullRacks: CopgForDisplay[] = labelOrdered.filter(r => r.identifier.startsWith(FULL_RACK_IDENTIFIER[0]));
  const combos: CopgForDisplay[] = labelOrdered.filter(r => !r.identifier.startsWith(FULL_RACK_IDENTIFIER[0]));

  return _.union(fullRack, otherFullRacks, combos);
}

const cachedCustomerOrderProductGroups = createDeepEqualSelector([(response: any) => response.customerOrderProductGroups], _.identity);
const getCustomerOrderProductGroups = (response, productId) => cachedCustomerOrderProductGroups(response);
const getProductId = (response, productId) => productId;

const withShippingUnitTypesSelector = createSelector([getCustomerOrderProductGroups, getProductId], (customerOrderProductGroups, productId) => {
  const copgs = (customerOrderProductGroups ?? [] as shame)
    .filter(copg => (copg.customerOrderProducts || []).some(cop => cop.product.id === productId))
    .map((copg): CopgForDisplay => {
      return {
        id: copg.id,
        identifier: copg.identifier,
        label: `${copg.identifier}` + (copg.packsPerShippingUnit ? ` - ${copg.packsPerShippingUnit} PPR` : ''),
      };
    });

  const orderedCopgs = getOrderedCopgs(copgs);

  const defaultCustomerOrderProductGroupId = orderedCopgs.length > 0
    ? orderedCopgs[0].id
    : undefined;

  return {
    customerOrderProductGroupIds: orderedCopgs,
    initialValues: {
      customerOrderProductGroupId: defaultCustomerOrderProductGroupId,
    },
  };
});

const WithShippingUnitTypes = msyncQuery<ShippingUnitTypesQueryResponse, OwnProps & StateProps, AutoReplenishmentProps, ShippingUnitTypesQueryVariables>(ShippingUnitTypesQuery, {
  alias: 'withAutoReplenishmentRows',
  skip(ownProps) {
    return !ownProps.isShown;
  },
  options(ownProps) {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props({ data, ownProps }): AutoReplenishmentProps {
    if (!data.response) {
      return {
        customerOrderProductGroupIds: [],
      };
    }

    return withShippingUnitTypesSelector(data.response, ownProps.productId);
  },
});

const WithAllocations = msyncQuery<AllocationQueryResponse, OwnProps & StateProps, AllocationProps, AllocationQueryVariables>(AllocationQuery, {
  alias: 'withAllocations',
  skip(ownProps) {
    return !ownProps.isShown || !ownProps.productId || _.isNaN(ownProps.productId);
  },
  options(ownProps) {
    return {
      variables: {
        scope: [
          { field: 'customerOrder', values: [`${ownProps.customerOrderId}`] },
          { field: 'product', values: [`${ownProps.productId}`] },
        ],
      },
      fetchPolicy: 'network-only',
    };
  },
  props({ data }): AllocationProps {
    if (data.loading) {
      return {
        allocations: {},
      };
    }

    // TODO: reselect this?
    const allocationsByCustomerOrderProductGroupId = _.mapValues(_.groupBy(data.content || [], allocation => allocation.store.id), allocation => {
      return allocation.reduce<{}>((acc, val) => {
        const id = val.customerOrderProduct.customerOrderProductGroup.id;
        acc[id] = acc[id] ? acc[id] + val.quantity : val.quantity;

        return acc;
      }, {});
    });

    return {
      allocations: allocationsByCustomerOrderProductGroupId,
    };
  },
});

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps & InjectedFormProps<FormValues> & StateProps & AllocationProps & AutoReplenishmentProps) => {
  const hide = () => {
    dispatch(reset(formName));
    dispatch(Actions.setAutoReplenishmentModalVisibility([], false));
    dispatch(Actions.setAutoReplenishmentMutationStatus(MutationStatus.Initial));
  };

  return {
    onCancelButtonClicked: () => {
      hide();
    },
    handleFormSubmit: () => {
      return props.handleSubmit(async (formValues: FormValues) => {
        dispatch(Actions.setAutoReplenishmentMutationStatus(MutationStatus.InProgress));

        // Give the spinner time to spin
        await timeout(10);

        const targetPercentShipped = Number.parseInt(formValues.targetPercentShipped) / 100;

        const autoReplenishVariables = props.data.map(datum => {
          const customerOrderProductGroup = props.customerOrderProductGroups.find(copg => copg.id === formValues.customerOrderProductGroupId);
          if (!customerOrderProductGroup) {
            throw new Error(`Unable to resolve customer order product group for id ${formValues.customerOrderProductGroupId}`);
          }

          let units: number = 0;

          const shippingUnitAllocationsForStore = props.allocations[datum.storeId];
          const existingAllocations = (shippingUnitAllocationsForStore && shippingUnitAllocationsForStore[formValues.customerOrderProductGroupId]) || 0;

          if (props.orderMethod === PackOrderMethod) {
            const piecesAlreadyAccountedFor = (existingAllocations * customerOrderProductGroup.packSize);

            units = determineUnitsForTargetPercentShipped({
              oldPiecesShipped: datum.shippedWithDraft - piecesAlreadyAccountedFor,
              pieceTarget: datum.pieceTarget,
              newPercentShipped: targetPercentShipped,

              orderMethod: PackOrderMethod,
              packSize: customerOrderProductGroup.packSize,
            });
          } else if (props.orderMethod === ShippingUnitOrderMethod) {
            const piecesAlreadyAccountedFor = (existingAllocations * customerOrderProductGroup.packSize);

            units = determineUnitsForTargetPercentShipped({
              oldPiecesShipped: datum.shippedWithDraft - piecesAlreadyAccountedFor,
              pieceTarget: datum.pieceTarget,
              newPercentShipped: targetPercentShipped,

              orderMethod: ShippingUnitOrderMethod,
              packSize: customerOrderProductGroup.packSize,
              packsPerShippingUnit: customerOrderProductGroup.packsPerShippingUnit || 0,
            });
          }

          return {
            storeId: datum.storeId,
            customerOrderProductGroupId: formValues.customerOrderProductGroupId,
            units,
          };
        });

        try {
          await props.allocateProductToStores(autoReplenishVariables.map(ar => {
            return {
              quantity: ar.units,
              storeId: ar.storeId,
              customerOrderProductGroupId: ar.customerOrderProductGroupId,
            };
          }));

          hide();
        } catch (err) {
          dispatch(Actions.setAutoReplenishmentMutationStatus(MutationStatus.Initial));
        }
      });
    },
  };
};

const connector1 = connect(mapStateToProps);
type PropsFromRedux1 = ConnectedProps<typeof connector1>

const connector2 = connect(undefined, mapDispatchToProps);
type PropsFromRedux2 = ConnectedProps<typeof connector2>

export type ComponentProps =
  OwnProps &
  PropsFromRedux1 &
  PropsFromRedux2 &
  AutoReplenishmentProps &
  InjectedFormProps<FormValues>;

const component = flowRight(
  connector1,
  WithShippingUnitTypes,
  WithAllocations,
  reduxForm({
    form: formName,
    enableReinitialize: true,
  }),
  connector2
)(AutoReplenishmentModalUI);


assertCompatible<React.ComponentProps<typeof AutoReplenishmentModalUI>, ComponentProps>();

export const AutoReplenishmentModal = (props: OwnProps) => propToComponent(component, props);
