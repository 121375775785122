import * as React from 'react';
import * as DateRangeYearComparison from 'client/components/date-range-year-comparison';
import { Form, Col, Row, FormGroup, Tabs, Tab } from 'client/components/third-party';
import * as Constants from './constants';
import { SelectableRow } from 'client/components/selectable/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ReportTypes, SalesReportProductDetailLevel } from 'shared/types';
import * as DailySalesReportComponents from './components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { DropDownOptions } from 'client/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';

export interface ComponentProps {
  handleDownloadDailySalesReportClicked(): void;
  handleDownloadStoreBreakdownReportClicked(): void;

  customerOptions: DropDownOptions;
  sellDepartmentOptions: DropDownOptions;
  scanBased: boolean;
  poBased: boolean;

  productClassOptions?: SelectableRow[];
  productOptions?: SelectableRow[];
  productSubClassOptions?: SelectableRow[];
  mfcAreaOptions?: SelectableRow[];
  regionOptions?: SelectableRow[];
  marketOptions?: SelectableRow[];
  storeOptions?: SelectableRow[];

  productClassesLoading?: boolean;
  productSubClassesLoading?: boolean;
  productsLoading?: boolean;
  mfcAreasLoading?: boolean;
  regionsLoading?: boolean;
  marketsLoading?: boolean;
  storesLoading?: boolean;

  primaryGlobalAll: PrimaryGlobalAll | undefined;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  productDetailLevel: SalesReportProductDetailLevel | undefined;
  reportDownloadStatus: MutationStatus;

  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
  handleDateRangesChanged(state: DateRangeYearComparisonValue): void;
  downloadButtonDisabled: boolean;
  storeBreakdownDownloadButtonDisabled: boolean;
  emailFieldsDisabled: boolean;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class DailySalesReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar title="Daily Sales Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-daily-sales-table-wrapper  mfc-scrolling-region-adjacent-to-sidebar">
            <Form horizontal>
              <Row className="report-fields-outside-tabs">
                <Col sm={12}>
                  <FormGroup>
                    <DailySalesReportComponents.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="report-fields-outside-tabs">
                <Col sm={6}>
                  <FormGroup>
                    <DateRangeYearComparison.DateRangeYearComparison
                      handleChange={this.props.handleDateRangesChanged}
                      testid="date-range-comparison-component"
                      value={this.props.dateRangeYearComparison}
                      lastYearRequired
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="product-tabs">
                    <Tab disabled={true} title="Sales Email Options">
                      <FormGroup>
                        <DailySalesReportComponents.IncludeGrossMargin name={Constants.FormFields.includeGrossMargin} />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
              <div>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="product-tabs">
                    <Tab disabled={true} title="Products">
                      <FormGroup>
                        <DailySalesReportComponents.SellDepartment
                          name={Constants.FormFields.sellDepartmentId}
                          options={this.props.sellDepartmentOptions}
                        />
                      </FormGroup>
                      <FormGroup className="report-product-order-type-area">
                        <label className="report-scan-or-po-based-label">Product Order Type</label>
                        <div>
                          <DailySalesReportComponents.ScanBased
                            name={Constants.FormFields.scanBased}
                          />
                          <DailySalesReportComponents.PoBased
                            name={Constants.FormFields.poBased}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <DailySalesReportComponents.ProductClass
                          name={Constants.FormFields.productClassIds}
                          options={this.props.productClassOptions}
                          loading={this.props.productClassesLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.ProductSubClass
                          name={Constants.FormFields.productSubClassIds}
                          options={this.props.productSubClassOptions}
                          loading={this.props.productSubClassesLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.Product
                          name={Constants.FormFields.productIds}
                          options={this.props.productOptions}
                          loading={this.props.productsLoading}
                        />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="stores-tab">
                    <Tab disabled={true} title="Stores">
                      <FormGroup>
                        <DailySalesReportComponents.PrimaryGlobalAllField
                          name={Constants.FormFields.primaryGlobalAll}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.MfcArea
                          name={Constants.FormFields.mfcAreaIds}
                          options={this.props.mfcAreaOptions}
                          loading={this.props.mfcAreasLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.Region
                          name={Constants.FormFields.regionIds}
                          options={this.props.regionOptions}
                          loading={this.props.regionsLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.Market
                          name={Constants.FormFields.marketIds}
                          options={this.props.marketOptions}
                          loading={this.props.marketsLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <DailySalesReportComponents.Store
                          name={Constants.FormFields.storeIds}
                          options={this.props.storeOptions}
                          loading={this.props.storesLoading}
                        />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
              </div>
            </Form>
          </div>
          <DailySalesReportComponents.Sidebar
            reportType={ReportTypes.SalesReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            downloadButtonLabel="Download Daily Sales Report Excel File"
            handleDownloadExcelReportClicked={this.props.handleDownloadDailySalesReportClicked}
            secondDownloadButtonLabel="Download Store Breakdown Excel File"
            secondDownloadButtonDisabled={this.props.storeBreakdownDownloadButtonDisabled}
            secondHandleDownloadExcelReportClicked={this.props.handleDownloadStoreBreakdownReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          >
            <FormGroup>
              <div className="report-sidebar-field">
                <DailySalesReportComponents.SelectField
                  label="Product Detail Level"
                  name={Constants.FormFields.productDetailLevel}
                  options={[
                    { id: SalesReportProductDetailLevel.Class },
                    { id: SalesReportProductDetailLevel.Subclass },
                    { id: SalesReportProductDetailLevel.Product },
                  ]}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="report-sidebar-field-checkbox">
                <DailySalesReportComponents.EmailReportDaily
                  name={Constants.FormFields.emailReportDaily}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="report-sidebar-field">
                <DailySalesReportComponents.EmailSubject
                  name={Constants.FormFields.emailSubject}
                  disabled={this.props.emailFieldsDisabled}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="report-sidebar-field-text-area">
                <DailySalesReportComponents.EmailRecipients
                  name={Constants.FormFields.emailRecipients}
                  disabled={this.props.emailFieldsDisabled}
                />
              </div>
            </FormGroup>
          </DailySalesReportComponents.Sidebar>
        </div>
      </div >
    );
  }
}
