import * as React from 'react';
import { Row, Col } from 'client/components/third-party';
import  { StatusPill } from './status-pill';
import { formatDateTime } from 'shared/helpers/date-helpers';
import { DateTimeStr, toDateTimeStr, TYPES } from 'shared/types';

interface Props {
  input: {value?: boolean | string | null, type?: TYPES};
  label: string;
  username?: string | null;
  lastUpdatedDate?: DateTimeStr | null;
}

export function OrderStatus({input: { value: status, type }, label, username, lastUpdatedDate}: Props) {
  return (
    <Col sm={12}>
      <Row>
        <div className="mfc-form-stacked-label">{label}</div>
      </Row>
      <Row className="mfc-order-status-details">
        <Col sm={4} className="status">
          <StatusPill status={status} type={type}/>
        </Col>
        <Col sm={5}>
          {username}
        </Col>
        <Col sm={3}>
          <div className="date">
            {lastUpdatedDate ? formatDateTime(toDateTimeStr(lastUpdatedDate), 'MM/DD/YYYY') : ''}
          </div>
        </Col>
      </Row>
    </Col>
  );
}
