import * as _ from 'lodash';
import { singularize } from 'inflection';
import { SchemaSearchInput, SchemaSortInput, SchemaFilterSpecificationInput } from 'shared/types/user-interaction-types';
import { Saved } from 'shared/schemas/record';
import { Bug } from 'shared/helpers';

export const buildGqlType = (name: PascalCasing) => (opts: {isRequired?: boolean, isArray?: boolean} = {}) => ({
  typeName: name,
  isRequired: opts.isRequired || false,
  isArray: opts.isArray || false,
});

export type Created<T> = Saved<T>;
export const GQL_TYPES = {
  STRING    : buildGqlType('String'     ),
  INT       : buildGqlType('Int'        ),
  FLOAT     : buildGqlType('Float'      ),
  BOOLEAN   : buildGqlType('Boolean'    ),
  BILL_TYPE : buildGqlType('BillType'   ),
  INVOICE_BY: buildGqlType('InvoiceBy'  ),
  DATE_TIME : buildGqlType('DateTimeStr'),
  DATE      : buildGqlType('DateStr'    ),
};

export const isNumber = (x: any): x is number => typeof x === 'number';
export const isString = (x: any): x is string => typeof x === 'string';
export enum RecordAction { insert = 'insert', update = 'update', delete = 'delete' }

/**
 * Defines relationships and retrieval rules between FK-connected tables.
 * A FKSpec is defined in the context of a nativeColumn,
 * and explains how to link to the foreignTable (`FKSpec.table`),
 * and then how to interpret or load those foreign records. */
export class FKSpec {
  /** in Rozema's taxonomy, these are the kinds of foreign relationships we need to model.  I'd like to change this to just be one: FK -- but that is a future project. (DVM 2023 11 30) */
  readonly relationshipType: 'belongsTo' | 'hasMany' | 'hasOne' | 'manyToMany' = 'belongsTo';

  /** FKSpecs are defined in the context of a given column on a given table, explaining how the value of that columns is derived from a foreignTable. */
  readonly nativeTable: camelCasings;

  /** FKSpecs are defined in the context of a given nativeColumn on a given nativeTable, explaining how the value of that columns is derived from a foreignTable. typically done by decorators on the nativeColumn as defined in shared/schemas. */
  readonly nativeColumn: camelCasing;

  /** the Native column containing an ID pointing to a Foreign table -- optional because by convention is to use the name of the foreign table, singularized, with the suffix `Id` or `_id` depending whether in ECMA script or SQL at the time. */
  nativeTableFK: camelCasing;

  /** the name of the target / referenced / referencing Foreign table -- possibly a transitive afferent reference, but never an efferent transitive reference (that is still unsupported .... EXCEPT for many-to-many, i.e. join tables ---- unverified!!!!)*/
  readonly foreignTable: camelCasings;

  /** the Foreign column uniquely referenced by `nativeRecord[idKey]` on the native table */
  foreignTablePK: camelCasing;

  /** Permits matching native record based on a search through these properties on the referenced foreign record. e.g., find supplier by name of contact. */
  foreignQueryKeys: camelCasing[];

  /** the column on the Foreign record used to represent its identity to the end users (typically Identifier, Name, Description, or some combination) */
  foreignDisplayKey: camelCasing;

  /** whether the Native record points to the Foreign record (the other way around is possible) */
  get belongsTo(): boolean { return this.relationshipType === 'belongsTo'; }

  /** When the Native record DOES NOT point to the Foreign record(s), but rather is referenced BY the foreigners. -- i.e., the Native record has an Array property of foreign "children" */
  get hasMany(): boolean { return this.relationshipType === 'hasMany' || this.relationshipType === 'hasOne' || this.relationshipType === 'manyToMany'; }

  /**
   * @deprecated TODO: not sure this is worth having an extra concept; but the gist is:
   * a foreign table references this native table, and has a constraint that makes the realtionship 1:1;
   * hence this PROJECTED field is resolved via a hasOne relationship.  Example, primaryProductUPCCode:
   * ```typescript
   * [hasOne('productUpcs', { specification: { primary: true } })]
   * [gqlResolver(({ id }: any, _: any, context: any) => context.findRepository('ProductUpc', context).findPrimaryUpcCodeByProductId(id))]
   * [property]
   * primaryUpcCode: Saved<ProductUpc>;
   * ```
   */
  get hasOne(): boolean { return this.relationshipType === 'hasOne'; }

  /** a many-to-many relationship MUST have exactly on through table (the join table.) */
  get manyToMany(): boolean { return this.relationshipType === 'manyToMany'; }

  /** when the Foreign table referenced by the Native record is actually resolved via a transitive reference
   * through one or more intermediary foreign tables
   * (may include join tables for many-to-many relationships ---- unverified!!!!)
   *
   * SPECIFICALLY, `through` should be the transitively referenced tableName that must be traversed immediately prior to reaching the target table.
   * When these are chained with 2 or more transitive references (e.g. coa.co => copg, cop), the other transitive references must also be defined on the origin table's schema.
   *
   * CAVEAT: for *:* relationships, `through` is rquired and is the name of the join table. Multi-hop manyToMany relationships are not permitted.
   */
  through?: camelCasings;

  /** The column on the table indicated by `through` which forwards a reference to the foreign table - camelCasing of `{through}Id` - eg, `market.Region` -> `regionId` */
  throughIdKey?: camelCasing;

  /** camelCasing of `through` - eg, `market.Region` -> `region` */
  throughColumn?: string;

  /** table alias for specification in join clauses (JoinSpecification type) */
  alias?: camelCasing;

  /** if applicable, a knex andWhere spec object -- kvps*/
  andWhere?: SimpleObject | null;

  /** @deprecated TODO: not sure what this adds; possible clue: server/type-schemas/index.ts:160ish::buildFieldResolver when c.fkSpec!.hasMany */
  foreignFindByRecordType?: string;

  get synopsis(): string { return `${this.relationshipType}::${this.nativeTable}.${this.nativeColumn}=>${this.foreignTable}.${this.foreignTablePK}::${_.sortedUniq(this.foreignQueryKeys).join(',')}`; }
  private constructor(
    nativeTable: string,
    nativeColumn: string,
    relationshipType: 'belongsTo' | 'hasMany' | 'hasOne' | 'manyToMany',
    foreignTable: string
  ) {
    this.relationshipType = relationshipType;
    this.nativeTable = nativeTable;
    this.nativeColumn = nativeColumn;
    this.nativeTableFK = `${_.camelCase(singularize(foreignTable))}Id`;
    this.foreignTable = foreignTable;
    this.foreignDisplayKey = 'identifier';
    this.foreignQueryKeys = ['identifier'];
    this.foreignTablePK = 'id';
  }

  private augment(options?: Partial<FKSpec>) {
    if (!!options?.foreignQueryKeys?.length && !options?.foreignDisplayKey && options!.foreignQueryKeys.length > 1 && !options!.foreignQueryKeys.includes('identifier'))
      throw new Bug(`it is invalid to specify multiple foreignQueryKeys without also specifying the foreignDisplayKey ... unless foreignQueryKeys still contains the default foreignDisplayKey, 'identifier'.`);

    const merged = Object.assign(this, options ?? {});
    merged.foreignDisplayKey = options?.foreignDisplayKey ?? options?.foreignQueryKeys?.[0] ?? this.foreignDisplayKey ?? this.foreignQueryKeys?.[0] ?? 'identifier';
    if (options?.foreignQueryKeys?.length === 0)
      merged.foreignDisplayKey = 'id'; // accommodate, e.g., customerOrder.importJob (which has an fkSpec, but no default display.)

    merged.foreignQueryKeys = options?.foreignQueryKeys ?? this.foreignQueryKeys ?? [];
    if (merged.foreignQueryKeys?.includes(merged.foreignDisplayKey) === false) {
      if (!!options?.foreignDisplayKey && !options?.foreignQueryKeys?.length)
        merged.foreignQueryKeys = [merged.foreignDisplayKey];
      else
        merged.foreignQueryKeys.push(merged.foreignDisplayKey);
    }

    return merged;
  }
  /**
   * Either points directly at the foreign table, e.g. `Store.customer : stores.customer_id -> customers.id : customers.identifier`
   * or points transitively through an intermedate table, e.g. `Store.region : stores.market_id -> markets.id + markets.region_id -> regions.id : regions.identifier`
   * -- though in current implementation, to achieve this, the Store schema must also define FKSpecs to resolve the intermediate tables, e.g.:
   *
   * `Store.region :: Store.market` ⬇️
   * `Store.region : { Store.market : stores.market_id -> markets.id : markets.region_id -> regions.id } : regions.identifier`
   */
  static belongsTo = (nativeTable: camelCasings, nativeColumn: camelCasing, foreignTable: camelCasings, options?: Partial<FKSpec>) => new FKSpec(nativeTable, nativeColumn, 'belongsTo', foreignTable).augment({
    // nativeTableFK: `${_.camelCase(singularize(foreignTable))}Id`, // pretty sure this is un-needed
    ...(options?.through ? {
      through: options.through,
      throughIdKey: `${_.camelCase(singularize(options.through))}Id`,
      throughColumn: singularize(foreignTable),
    } : {}),
    ..._.omit(options ?? {}, 'through', 'throughIdKey', 'throughColumn'),
  });

  static hasMany = (nativeTable: camelCasings, nativeColumn: camelCasing, foreignTable: camelCasings, options?: Partial<FKSpec>) => new FKSpec(nativeTable, nativeColumn, 'hasMany', foreignTable).augment(options);

  /** Special case of hasMany */
  static hasOne = (nativeTable: camelCasings, nativeColumn: camelCasing, foreignTable: camelCasings, options?: Partial<FKSpec>) => new FKSpec(nativeTable, nativeColumn, 'hasOne', foreignTable).augment(options);

  /** like hasMany, but mediated by a join table. */
  static manyToMany = (nativeTable: camelCasings, nativeColumn: camelCasing, foreignTable: camelCasings, options: {foreignDisplayKey: camelCasing, through: camelCasings} & Omit<Partial<FKSpec>, 'foreignDisplayKey' | 'through'>) =>
    new FKSpec(nativeTable, nativeColumn, 'manyToMany', foreignTable).augment({
      foreignTablePK: `${singularize(foreignTable)}Id`,
      nativeTableFK: `${singularize(nativeTable)}Id`,
      ...options,
    });
}

export interface SchemaOptions<T> {
  defaultSearch?: SchemaSearchInput<T>;
  defaultSort?: SchemaSortInput<T> | Array<SchemaSortInput<T>>;
  defaultFilter?: Array<SchemaFilterSpecificationInput<T>>;
  hasTimestamps?: boolean;
  hasLastModifiedInfo?: boolean;
  softDeletable?: boolean;
  joins?: Array<keyof T>;
  type?: string;
  uniqueConstraints?: string[];
  generateGraphqlSchema?: boolean;
}
