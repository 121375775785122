import { resetTable } from 'client/actions/table';
import { Dispatch } from 'redux';
import { TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME, TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME } from 'client/constants';
import { Thunker } from 'client/types/redux-types';

export function handleReceivingDetailsUnmounted(): Thunker {
  return (dispatch: Dispatch<any>) => {
    resetTable(dispatch, TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME);
    resetTable(dispatch, TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME);
  };
}
