import gql from 'graphql-tag';

export const DeleteAttachmentMutation = gql`
  mutation DeleteAttachmentMutaion($input: DeleteAttachmentInput!) {
    deleteAttachment: DeleteAttachment(input: $input) {
      success
      errors
    }
  }
`;

export const UpdateAttachmentMutation = gql`
  mutation UpdateAttachmentMutaion($input: UpdateAttachmentInput!) {
    updateAttachment: UpdateAttachment(input: $input) {
      success
      errors
    }
  }
`;
