import * as React from 'react';
import { Col } from 'client/components/third-party';
import { ProductWorksheetStats, OrderMethod, PackOrderMethod, ShippingUnitType, formatNumber } from 'shared/types';

export interface WorksheetStatsRackCount {
  externalIdentifier?: string | null;
  orderProductIdentifier: string;
  numberOfRacks: number | string | null;
  numberOfPacks: number | string;
}

function statRow(orderMethod: string, index: number, configuration: {externalIdentifier?: string | null, orderProductIdentifier: string, numberOfRacks: number | string | null, numberOfPacks: number | string, source: 'customer' | 'supplier'}) {
  let value = typeof configuration.numberOfRacks === 'number'
    ? formatNumberWithOptions(configuration.numberOfRacks)
    : configuration.numberOfRacks;

  if (orderMethod === PackOrderMethod) {
    value = typeof configuration.numberOfPacks === 'number'
      ? formatNumberWithOptions(configuration.numberOfPacks)
      : configuration.numberOfPacks;
  }
  const ID = `stats-rack-allocation-${configuration.source}-${configuration.orderProductIdentifier}`;

  const externalIdentifier = configuration.externalIdentifier ? ` (${configuration.externalIdentifier})` : '';
  return (
    <div className="stat-card" data-testid={ID} key={`${configuration.source}-${configuration.orderProductIdentifier}-${index}`}>
      <div className="stat-card-rack-description" data-testid="identifier">{`${configuration.orderProductIdentifier}${externalIdentifier}`}</div>
      <div className="stat-card-rack-quantity" data-testid="quantity">{value}</div>
    </div>
  );
}

const noValueIndicator = '-';
const unknown = [{ orderProductIdentifier: noValueIndicator, numberOfRacks: noValueIndicator, numberOfPacks: noValueIndicator }];
const unknownAllocated = unknown.map(value => statRow('', 0, { ...value, source: 'customer' }));
const unknownOrdered = unknown.map(value => statRow('', 0, { ...value, source: 'supplier' }));

function formatNumberWithOptions(value: number) {
  const formatted = formatNumber(value);

  if (formatted === '0') {
    return noValueIndicator;
  }

  return formatted;
}

export function ProductWorksheetStatsRow(props: { worksheetStats?: ProductWorksheetStats, orderMethod: OrderMethod, shippingUnitType: ShippingUnitType }) {
  let piecesAllocated = noValueIndicator;
  let allocated = unknownAllocated;
  let piecesAvailable = noValueIndicator;
  let available = unknownOrdered;

  if (!!props.worksheetStats) {
    piecesAllocated = formatNumberWithOptions(props.worksheetStats.piecesAllocated);
    piecesAvailable = formatNumberWithOptions(props.worksheetStats.reportedInventory);

    if (props.worksheetStats.allocations.length > 0) {
      allocated = props.worksheetStats.allocations.map((value, index) => statRow(props.orderMethod, index, { ...value, source: 'customer' }));
    }

    if (props.worksheetStats.possibleAllocations.length > 0) {
      available = props.worksheetStats.possibleAllocations.map((value, index) => statRow(props.orderMethod, index, { ...value, source: 'supplier' }));
    }
  }

  let allocatedLabel = 'Racks Allocated';
  let availableLabel = 'Racks Ordered';

  if (props.orderMethod === OrderMethod.Pack) {
    allocatedLabel = 'Cases Allocated';
    availableLabel = 'Case Quantities';
  }

  return (
    <Col sm={12} className="stats-row" data-testid="stats-card-container">
      <div className="card-pf card-pf-accented stat-card-split card-pf-aggregate-status-notifications">
        <div className="card-pf-aggregate-status-notification stat-card-split-left-segment">
          <div className="card-pf-title" data-testid="stats-card-pieces-title">Pieces Allocated</div>
          <div className="card-pf-body" data-testid="stats-card-pieces-body">{piecesAllocated}</div>
        </div>
        <div className="card-pf-aggregate-status-notification stat-card-split-right-segment">
          <div className="card-pf-title" data-testid="stats-card-racks-allocated-title">{allocatedLabel}</div>
          <div data-testid="stats-card-racks-allocated-body">{allocated}</div>
        </div>
      </div>
      <div className="card-pf card-pf-accented stat-card-split card-pf-aggregate-status-notifications">
        <span className="card-pf-aggregate-status-notification stat-card-split-left-segment">
          <div className="card-pf-title" data-testid="stats-card-reported-pieces-title">Supplier POs (Pieces)</div>
          <div className="card-pf-body" data-testid="stats-card-reported-pieces-body">{piecesAvailable}</div>
        </span>
        <span className="card-pf-aggregate-status-notification stat-card-split-right-segment">
          <div className="card-pf-title" data-testid="stats-card-possible-racks-title">{availableLabel}</div>
          <div data-testid="stats-card-possible-racks-body">{available}</div>
        </span>
      </div>
    </Col>
  );
}
