// 1-based index to align with Excel
export const CUSTOMER_IDENTIFIER_ROW = 4;
export const CUSTOMER_IDENTIFIER_COL = 'B';

export const SUPPLIER_IDENTIFIER_ROW = 3;
export const SUPPLIER_IDENTIFIER_COL = 'B';

export const PRODUCTION_YEAR_ROW = 5;
export const PRODUCTION_YEAR_COL = 'B';

export const PRODUCTION_SEASON_ROW = 6;
export const PRODUCTION_SEASON_COL = 'B';

export const MASTER_SHEET_NAME = 'Master';
export const PRODUCT_LIST_SHEET_NAME = 'Product List';

export const FIRST_PRODUCT_ROW = 9;

export const PRODUCT_IDENTIFIER_COLUMN = 'B';
export const GROWER_COST_COL = 'C';
export const TOTAL_QUANTITY_COL = 'D';

export const ALLOCATIONS_STARTING_COL = 'E';
export const START_WEEK = 1;
export const END_WEEK = 52;
