import * as _ from 'lodash';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { DifferenceCalculationReportUI, UIProps} from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Actions from './actions';
import { Thunker } from 'client/types/redux-types';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { SORT_TYPES } from 'shared/types';
import { MutationStatus } from 'client/actions/mutations';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import { DropDownOptions } from 'client/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  reportDownloadStatus: MutationStatus;
}

const mapStateToProps = (state: State.Type): StateProps => {
  return {
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(salesPlanId: number | null): Thunker;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
};

interface ReduxFormProps {
  pristine: boolean;
}

interface SalesPlanOptionProps {
  salesPlanOptions: DropDownOptions;
}

const withSalesPlanOptions = optionsContainerWithFilters({
  table: 'salesPlans',
  columns: ['year', 'identifier', 'customer', 'startWeek', 'endWeek', 'sellDepartment'],
  resultPropName: 'salesPlanOptions',
  getFilters: () => [],
  sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year'}],
});

export type ComponentProps =
  OwnProps &
  SalesPlanOptionProps &
  ReduxFormProps &
  DispatchProps &
  StateProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withSalesPlanOptions,
)(DifferenceCalculationReportUI as shame) as shame as Component<OwnProps>;

export const DifferenceCalculationReportContainer = (props: OwnProps) => propToComponent(component, props);
