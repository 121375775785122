import * as React from 'react';
import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { FieldArray, getFormValues, Field as ReduxFormField} from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { every } from 'lodash';
import { Modal, Form, Row, FormGroup } from 'client/components/third-party';
import { SupplierItem } from 'shared/schemas/supplier-item';
import { SupplierItemsForm, SupplierItemsFormProps } from './supplier-item-form';
import { LabeledInput, SpecializeField } from 'client/components/form';
import { OrderMethod, PackOrderMethod, INPUT_TYPES, ShippingUnitType } from 'shared/types';
import { SupplierOrderProduct } from 'shared/schemas/supplier-order-product';
import * as Validators from 'shared/validators';
import { SupplierOrderProductGroup } from 'shared/schemas/supplier-order-product-group';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { wrapComponent } from 'client/hoc/hoc';

type SupplierItemOption = Pick<SupplierItem, 'id'>;
const Field = SpecializeField(SupplierOrderProductGroup, { horizontalLabel: false });

export interface FormValues {
  id?: number;
  identifier: string;
  description: string;
  isCombo: boolean;
  quantity: number;
  packsPerShippingUnit?: number;
  supplierOrderProducts: Array<{
    id: number;
    supplierItemId: number;
    packsPerShippingUnit: number;
    shelvesPerRack: number;
    packSize: number;
    packsPerShelf: number;
  }>;
}

export interface OwnProps {
  supplierOrderId: number;
  isShown: boolean;
  supplierItems: SupplierItemOption[];
  activeSupplierItems: SupplierItemOption[];
  handleCancelButtonClicked: () => void;
  handleSupplierItemChanged: (supplierItemId: number, supplierItemFieldIdentifier: string) => void;
  handlePacksPerRackCalculationInputChange: (supplierItemFieldIdentifier: string, field?: 'shelvesPerRack' | 'packsPerShelf' | 'packSize', value?: number) => void;
  handleFormSubmit: () => React.ReactEventHandler<any>;
  invalid: boolean;
  supplierItemIds?: Immutable.Set<number>;
  submitButtonText: string;
  id: string;
  title: string;
  disableSupplierItemField: boolean;
  showAddSupplierItemButton: boolean;
  formName: string;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  supplierOrderProducts: SupplierOrderProduct[];
  initialValues: Partial<FormValues>;
  isCombo: boolean;
}

export interface StateProps {
  rackTypeDisabled: boolean;
  quantityDisabled: boolean;
  packsPerShippingUnitDisabled: boolean;
  supplierItemSelectedList: boolean[];
  currentSupplierOrderProductIds: number[];
}

type ComponentProps = StateProps & OwnProps;

const MAX_LENGTH_2_VALIDATOR = Validators.MAX_LENGTH(2);

class SupplierItemModal extends React.Component<ComponentProps, {}> {
  componentWillReceiveProps(nextProps: ComponentProps) {
    if ((!this.props.isShown && nextProps.isShown)) {
      const supplierItemIds = nextProps.supplierItemIds;

      if (supplierItemIds) {
        supplierItemIds.toJS().forEach((supplierItemId, index) => {
          if (supplierItemId) {
            this.props.handleSupplierItemChanged(supplierItemId, `supplierOrderProducts[${index}]`);
          }
        });
      }
    }
  }

  render() {
    const {
      id,
      title,
      isShown,
      invalid,
      submitButtonText,
      handleFormSubmit,
      handleCancelButtonClicked,
      supplierItemIds,
      handleSupplierItemChanged,
      handlePacksPerRackCalculationInputChange,
      disableSupplierItemField,
      supplierItems,
      activeSupplierItems,
      showAddSupplierItemButton,
      quantityDisabled,
      supplierItemSelectedList,
      initialValues,
      packsPerShippingUnitDisabled,
      orderMethod,
      shippingUnitType,
      currentSupplierOrderProductIds,
      isCombo,
    } = this.props;

    const savedSupplierOrderProductIds = initialValues?.supplierOrderProducts ? _.compact(initialValues.supplierOrderProducts.map(sop => sop.id)) : [];

    const isShowComboCheckBox = orderMethod !== OrderMethod.Pack;

    return (
      <Modal
        id={id}
        animation
        backdrop="static"
        show={isShown}
        onHide={handleCancelButtonClicked}>
        <Form horizontal onSubmit={handleFormSubmit()}>
          <GlobalAsyncModalHeader>{title}</GlobalAsyncModalHeader>
          <Modal.Body>
            { isShowComboCheckBox &&
              <Field name="isCombo" label={'Combo Cart'} inputColSize={6}/>
            }
            <FieldArray<SupplierItemsFormProps>
              name="supplierOrderProducts"
              component={SupplierItemsForm}
              orderMethod={orderMethod}
              supplierItemSelectedList={supplierItemSelectedList}
              supplierItemIds={supplierItemIds}
              handleSupplierItemChanged={handleSupplierItemChanged}
              handlePacksPerRackCalculationInputChange={handlePacksPerRackCalculationInputChange}
              disableSupplierItemField={disableSupplierItemField}
              supplierItems={supplierItems}
              activeSupplierItems={activeSupplierItems}
              showAddSupplierItemButton={showAddSupplierItemButton && isCombo}
              savedSupplierOrderProductIds={savedSupplierOrderProductIds}
              currentSupplierOrderProductIds={currentSupplierOrderProductIds}
            />
            <Row>
              <hr />
            </Row>
            <Row>
              <FormGroup>
                <Field
                  name="identifier"
                  inputColSize={3}
                  horizontalLabel={false}
                  // TODO: This validator should be on the server. See https://www.pivotaltracker.com/story/show/152687026 (AP 11/8/17)
                  validators={[MAX_LENGTH_2_VALIDATOR]} // configurationIdentifierUnique
                  disabled={every(supplierItemSelectedList, selected => !selected)} // Disable field if no products selected
                />
                <Field
                  name="description"
                  inputColSize={9}
                  horizontalLabel={false}
                  label="Description"
                  disabled={!isCombo}
                />
              </FormGroup>
              <FormGroup>
                <ReduxFormField
                  component={LabeledInput}
                  name="quantity"
                  type={INPUT_TYPES.NUMBER}
                  inputColSize={3}
                  horizontalLabel={false}
                  label="Quantity"
                  disabled={quantityDisabled}
                  validate={Validators.REQUIRED_FIELD_VALIDATOR}
                />

                {orderMethod === PackOrderMethod && shippingUnitType !== ShippingUnitType.NotApplicable &&
                  <Field
                    name="packsPerShippingUnit"
                    inputColSize={3}
                    horizontalLabel={false}
                    label={`Supplier ${shippingUnitType === ShippingUnitType.Pallet ? 'CPP' : 'PPR'}`}
                    disabled={packsPerShippingUnitDisabled}
                  />}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <GlobalCancelButton onClick={handleCancelButtonClicked} />
            <GlobalSaveButton
              disabled={invalid}
              label={submitButtonText}
              testid={'modal-submit'}
              type="submit"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const { formName } = ownProps;
  const values = getFormValues(formName)(state) as FormValues;
  const supplierOrderProducts = values?.supplierOrderProducts || [];

  return {
    rackTypeDisabled: supplierOrderProducts.every(sop => !sop.supplierItemId),
    quantityDisabled: supplierOrderProducts.every(sop => !sop.supplierItemId),
    packsPerShippingUnitDisabled: supplierOrderProducts.every(sop => !sop.supplierItemId),
    supplierItemSelectedList: supplierOrderProducts.map(sop => !!sop.supplierItemId),
    currentSupplierOrderProductIds: supplierOrderProducts.map(sop => sop.id),
  };
};

const connector = connect(mapStateToProps);
type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector>;

export default wrapComponent(SupplierItemModal)<OwnProps, CombinedProps>(
  connector,
);
