import { CellData } from './cell';
import { ColumnDef } from '../../types';
import { createSelector } from 'reselect';
import * as _ from 'lodash';
import * as Types from './types';
import * as Constants from '../../constants';

const getRowHeight = (props: Types.TableBodyProps) => props.rowHeight;

export const getRowHeightFunction = createSelector([
  getRowHeight,
], rowHeight => () => rowHeight);

export const getColumns = (props: Types.TableBodyProps) => props.columns;

export const getBodyHeight = (props: Types.TableBodyProps) => props.bodyHeight;

const getRows = (props: Types.TableBodyProps) => props.rows;
export const getRowCount = (props: Types.TableBodyProps) => props.rows.length;

const getCellValues = (rows: any[], columns: ColumnDef[]): CellData['values'] => {
  return rows.map(row => {
    return columns.map(column => {
      return row[column.accessor];
    });
  });
};

const getPrimaryClickHandler = (props: Types.TableBodyProps) => props.primaryClickHandler;

const getCustomClickHandler = (props: Types.TableBodyProps) => props.customClickHandler;

const getTableName = (props: Types.TableBodyProps) => props.tableName;

const getHoveredRowIndex = (props: Types.TableBodyProps) => props.hoveredRowIndex;

const getHoveredHandler = (props: Types.TableBodyProps) => props.hoverHandler;

export const getOnHorizontalScrollFunction = (props: Types.TableBodyProps) => props.onHorizontalScroll;

export const getOnVerticalScrollFunction = (props: Types.TableBodyProps) => props.onVerticalScroll;

const getGrowableColumnHeader = (props: Types.TableBodyProps) => props.growableColumnHeader;

const getTotalColumnWidth = createSelector([
  getColumns,
], columns => _.sumBy(columns, 'width'));

export const getBodyWidth = (props: Types.TableBodyProps) => props.bodyWidth;

export const getHasVerticalScrollbar = (props: Types.TableBodyProps) => {
  return true; // TBD until we can find a better solution sometime  props.hasVerticalScrollbar;
};

export const getTwinHasVerticalScrollbar = (props: Types.TableBodyProps) => {
  return props.twinHasVerticalScrollbar;
};

export const getFreeColumnWidthFunction = createSelector([
  getColumns,
  getGrowableColumnHeader,
  getBodyWidth,
  getTotalColumnWidth,
  getHasVerticalScrollbar,
  getTwinHasVerticalScrollbar,
], (columns, growableColumnHeader, bodyWidth, totalColumnWidth, hasVerticalScrollbar, twinHasVerticalScrollbar) => index => {
  const scrollbarWidthAdjustment = hasVerticalScrollbar
    ? Constants.SCROLLBAR_WIDTH
    : 0;

  return growableColumnHeader === columns[index].header && totalColumnWidth < bodyWidth
    ? columns[index].width + (_.max([bodyWidth - totalColumnWidth - scrollbarWidthAdjustment - 2, 0]) ?? 0)
    : columns[index].width;
});

export const getItemDataForFreeCells = createSelector([
  getRows,
  getColumns,
  getPrimaryClickHandler,
  getCustomClickHandler,
  props => props.selectedRowIndex,
  getTableName,
  getHoveredRowIndex,
  getHoveredHandler,
], (rows, columns, primaryClickHandler, customClickHandler, selectedRowIndex, tableName, hoveredRowIndex, hoverHandler): CellData => {
  return {
    columns,
    values: getCellValues(rows, columns),
    customClickHandler,
    primaryClickHandler,
    selectedRowIndex,
    headers: columns.map(c => c.header),
    tableName,
    hoveredRowIndex,
    hoverHandler,
  };
});

export const getHorizontalScrollPosition = (props: Types.TableBodyProps) => props.horizontalScrollPosition;
export const getVerticalScrollPosition = (props: Types.TableBodyProps) => props.verticalScrollPosition;
