import * as ImportModal from 'client/components/import-modal';
import * as React from 'react';
import * as ImportModalTypes from 'client/components/import-modal/types';
import { SpreadsheetType } from 'shared/file-parsers/excel/excel-parser-helpers';
import { useSelector } from 'react-redux';
import * as Actions from './actions';
import * as State from 'client/state/state';
import { useDispatch } from 'react-redux';

export const ImportSupplierCommitmentModal = (p: {
  supplierCommitmentId: number;
}) => {
  const dispatch = useDispatch();
  const handleCloseButtonClicked = () => dispatch(Actions.handleCloseButtonClicked());
  const handleCancelButtonClicked = () => dispatch(Actions.handleCancelButtonClicked());
  const handleParsingStarted: ImportModalTypes.ParseFn = (args, done) => dispatch(Actions.handleParsingStarted(args, done));
  const handleValidationStarted: ImportModalTypes.ValidateFn = (args, done) => dispatch(Actions.handleValidationStarted(args, done));
  const handleImportStarted: ImportModalTypes.ImportFn = (args, done) => dispatch(Actions.handleImportStarted(args, done));
  const rdx = useSelector((s: State.Type) => ({ isShown: s.supplierCommitment.importSupplierCommitmentModalShown }));
  return !rdx.isShown ? <div /> : <ImportModal.ImportUI
    id="import-supplier-commitment-modal"
    existingRecordId={p.supplierCommitmentId}
    modalTitle="Import Supplier Commitments"
    expectedSpreadsheetType={SpreadsheetType.SupplierCommitment}
    fileExtension=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    importButtonLabel="Import Supplier Commitments"
    closeButtonLabel="Close"
    cancelButtonLabel="Cancel"
    handleCloseButtonClicked={handleCloseButtonClicked}
    handleCancelButtonClicked={handleCancelButtonClicked}
    handleParsingStarted={handleParsingStarted}
    handleValidationStarted={handleValidationStarted}
    handleImportStarted={handleImportStarted}
  />;
};

