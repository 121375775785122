import * as React from 'react';
import * as _ from 'lodash';
import { Col } from 'client/components/third-party';
import { ComponentProps } from 'client/app/orders/customer-orders/reconciliation/reconciliation-page-container';
import ReconciliationTable from './reconciliation-table-container';

type OwnProps = ComponentProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
}

export default class Reconciliation extends React.Component<OwnProps, State> {
  public render() {
    const customerOrderId = _.parseInt(this.props.params.id || '');
    if (customerOrderId) {
      return (
        <Col sm={12} className="customer-order-sub-tab-page">
          <div data-testid="customer-order-reconciliation">
            <ReconciliationTable customerOrderId={customerOrderId} />
          </div>
        </Col>
      );
    }
    return <div/>;
  }
}
