import { DateStr, ReportTypes, ReplenishmentByStoreGroupingOptions, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';

export const formName = 'replenishmentByStoreReport';
export const reportType = ReportTypes.ReplenishmentByStore;

export enum FormFields {
  scanBased = 'scanBased',
  poBased = 'poBased',
  customerId = 'customerId',
  sellDepartmentId = 'sellDepartmentId',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  beginDate = 'beginDate',
  endDate = 'endDate',
  productIds = 'productIds',
  mfcAreaIds = 'mfcAreaIds',
  salesPlanId = 'salesPlanId',
  regionIds = 'regionIds',
  marketIds = 'marketIds',
  storeIds = 'storeIds',
  includeStoresNotShipped = 'includeStoresNotShipped',
  pieceTargetBeginDate = 'pieceTargetBeginDate',
  pieceTargetEndDate = 'pieceTargetEndDate',
  pieceTargetPrimaryStoresOnly = 'pieceTargetPrimaryStoresOnly',
  primaryGlobalAll = 'primaryGlobalAll',
  filterByCategoryOrSalesPlan = 'filterByCategoryOrSalesPlan',
  groupBy = 'groupBy',
}

export interface FormValues {
  [FormFields.customerId]: number | undefined;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.salesPlanId]: number | undefined;
  [FormFields.beginDate]: DateStr | undefined;
  [FormFields.endDate]: DateStr | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.regionIds]: SelectableValue | undefined;
  [FormFields.marketIds]: SelectableValue | undefined;
  [FormFields.storeIds]: SelectableValue | undefined;
  [FormFields.includeStoresNotShipped]: boolean;
  [FormFields.pieceTargetBeginDate]: DateStr | undefined;
  [FormFields.pieceTargetEndDate]: DateStr | undefined;
  [FormFields.pieceTargetPrimaryStoresOnly]: boolean | undefined;
  [FormFields.primaryGlobalAll]: PrimaryGlobalAll;
  [FormFields.filterByCategoryOrSalesPlan]: FilterByCategoryOrSalesPlan;
  [FormFields.groupBy]: ReplenishmentByStoreGroupingOptions;
}

export enum FilterByCategoryOrSalesPlan {
  ByCategory = 'ByCategory',
  BySalesPlan = 'BySalesPlan',
}
