import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/sales-plan/types';

export interface Input {
  salesPlanId: number;
}

interface SalesPlan {
  id: GraphQLTypes.SalesPlanV2['id'];
  identifier: GraphQLTypes.SalesPlanV2['identifier'];
  customerId: GraphQLTypes.SalesPlanV2['customerId'];
  sellDepartmentId: GraphQLTypes.SalesPlanV2['sellDepartmentId'];
  year: GraphQLTypes.SalesPlanV2['year'];
  lastModifiedAt: GraphQLTypes.SalesPlanV2['lastModifiedAt'];
  lastModifiedUser?: {
    firstName: string;
    lastName: string;
  };
}
export interface QueryResponse {
  salesPlan: SalesPlan;
}

export const Query = gql`
  query SalesPlanSideBarQuery($salesPlanId: Int!) {
    salesPlan: GetSalesPlan(id: $salesPlanId) {
      id
      identifier
      customerId
      sellDepartmentId
      year
      lastModifiedAt
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  }
`;
