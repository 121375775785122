import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { ReviewStoresTableUI, ReviewStoresTableRow, OwnProps as UIProps } from './review-stores-table-ui';
import { propToComponent } from 'client/hoc/hoc';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { AvailableFilter } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import * as _ from 'lodash';
import { GetRoutePlanReviewStoresQuery, GetReviewStoresCustomerOrderAllocationsResponse, GetRoutePlanReviewStoresExcelQuery } from './review-stores-query';
import { GetReviewStoresCustomerOrderAllocationsInput } from 'schema/customer-order-allocation/customer-order-allocation-types';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Actions from './actions';
import * as ImportDistributionRackShippingWorksheetActions from './import-distribution-rack-shipping-worksheet-modal/actions';
import { withClientSideSorting } from 'client/containers/table/client-side-sorting';
import { TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME } from 'client/constants';
import { createSelector } from 'reselect';
import { FetchPolicy } from 'apollo-client';

interface OwnProps {routePlanId: RoutePlanId}
const getRoutePlanId = (props: OwnProps) => props.routePlanId; // must be stable reference to function object (do not inline)
const buildDataRequest = createSelector([getRoutePlanId], (routePlanId: number) => ({
  operationName: '',
  query: GetRoutePlanReviewStoresExcelQuery,
  workbookName: 'Review Stores',
  variables: { routePlanId },
}));

interface WithRoutePlansReviewStoresRowProps {
  rows: ReviewStoresTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
  tableEditable: boolean;
}

const WithReviewStoresRows = msyncQuery<GetReviewStoresCustomerOrderAllocationsResponse, OwnProps & {} & WithTableParentProps, WithRoutePlansReviewStoresRowProps, GetReviewStoresCustomerOrderAllocationsInput>(GetRoutePlanReviewStoresQuery, {
  alias: 'withRoutePlansReviewStoresRows',
  skip: (ownProps: OwnProps & {} & WithTableParentProps) => _.isNil(ownProps.routePlanId),
  options: (ownProps: OwnProps): { fetchPolicy: FetchPolicy, variables: GetReviewStoresCustomerOrderAllocationsInput } => ({
    fetchPolicy: 'network-only',
    variables: { routePlanId: ownProps.routePlanId as RoutePlanId }, // the query should be skipped if routePlanId is nil
  }),
  props({data, ownProps}): WithRoutePlansReviewStoresRowProps {
    // The table is very slow to render. Don't want to lock things up rendering a stale table so wait until done loading before rendering rows
    const vals = data.response && !data.loading ? data.response.reviewStoresCustomerOrderAllocations : [];
    return {
      loading: _.isNil(data.loading) ? true : data.loading,
      totalCount: vals.length,
      totalUnfilteredCount: vals.length,
      tableEditable: !!data.response, // no response? no editing.
      dataRequest: buildDataRequest(ownProps),
      availableFilters: [],
      filteredRecordIds: vals.map(x => x.storeId),
      rows: vals.map(val => ({
        ...val,
        customer: `${val.customer.identifier} - ${val.customer.name}`,
        customerIdentifier: val.customer.identifier,
        id: val.storeId,
      })),
    };
  },
});

interface WithFilterAndSortCapabilitiesProps { onTableSort: (field: string) => void }
interface WithTableParentProps { tableParentInfo: TableParentInfo }
interface DispatchProps {
  overridePalletsSaved: (routePlanId: RoutePlanId | undefined, storeId: number, pallets: number) => void;
  overrideRacksSaved: (routePlanId: RoutePlanId | undefined, storeId: number, racks: number) => void;
  onNewClicked(): void;
  removeStoresFromRoutePlan: (routePlanId: RoutePlanId, storeIds: number[]) => void;
  importDistributionRackShippingSpreadsheetButtonClicked: (storeIdentifiersInRoutePlan: string[]) => void;
}
export type ComponentProps = OwnProps & DispatchProps & {} & WithRoutePlansReviewStoresRowProps & WithFilterAndSortCapabilitiesProps & WithTableParentProps;
type Component<P> = new (props: P) => React.Component<P, any>;
const component = flowRight(
  tableParentHoc(),
  connect<{}, DispatchProps, OwnProps>(
    (state, ownProps: OwnProps): {} => ({}),
    // mapDispatchToProps:
    {
      overridePalletsSaved: Actions.overridePalletsSaved,
      overrideRacksSaved: Actions.overrideRacksSaved,
      onNewClicked: Actions.addStoreButtonClicked,
      removeStoresFromRoutePlan: Actions.removeStoresFromRoutePlan,
      importDistributionRackShippingSpreadsheetButtonClicked: ImportDistributionRackShippingWorksheetActions.openModalButtonClicked,
    }),
  WithReviewStoresRows,
  withClientSideSorting<ComponentProps>(TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME, 'rows', false, 'storeNumber'),
)(ReviewStoresTableUI) as Component<OwnProps>;

assertCompatible<UIProps, ComponentProps>();

export default (props: OwnProps) => propToComponent(component, props);
