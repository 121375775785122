import { TYPES, SupplierItemCostChangeReasons, SORT_TYPES, GQL_TYPES } from '../types';
import { tableName, property, definePresentation, belongsTo, setSchemaOptions, required, defineFieldValidators } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, DateStr } from '../types';
import { IRecord } from './record';
import { SupplierItem, SupplierItemId } from 'shared/schemas/supplier-item';
import { displayType } from './dsl';
import * as Validators from 'shared/validators';

@tableName('supplierItemCosts')
export class SupplierItemCost implements IRecord {
  id?: number;
  @property @required beginDate: DateStr;
  @property @required changeReason: string;
  @property notes?: string | null;
  @property @required cost: number;
  @property supplierScanRisk?: boolean | null;

  @belongsTo('supplierItems', { foreignDisplayKey: 'supplierProdIdentifier', foreignQueryKeys: ['supplierProdIdentifier'] })
  @property @required supplierItem: SupplierItem;
  supplierItemId: SupplierItemId;
}

defineFieldValidators(SupplierItemCost, {
  cost: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
});

definePresentation(SupplierItemCost, {
  beginDate: {
    displayName: 'Begin Date',
    sortable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
  },
  changeReason: {
    displayName: 'Change Reason',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 35,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: SupplierItemCostChangeReasons }),
  },
  notes: {
    searchable: true,
    tableDisplay: true,
    columnWidth: 40,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
  },
  cost: {
    displayName: 'Cost',
    sortable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    gqlType: GQL_TYPES.FLOAT(),
  },
  supplierScanRisk: {
    displayName: 'Scan Risk',
    sortable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
  },
  supplierItem: {
    formDisplay: false,
    includeInSubmittedForm: true,
  },
});

setSchemaOptions(SupplierItemCost, {
  defaultSort: { sortOrder: SORT_TYPES.DESC, sortField: 'beginDate' },
});
