import gql from 'graphql-tag';
import { DateStr, DateTimeStr } from 'shared/types';
import { ActiveFilter } from 'client/types';

interface NestedName {
  id: number;
  name: string;
}

interface NestedIdentifier {
  id: number;
  identifier: string;
}

export interface DistributionReceivingQueryResponse {
  response?: {
    records: Array<{
      id: number;
      identifier: string;
      customer: NestedName;
      mfcArea?: NestedIdentifier;
      sellDepartment: NestedIdentifier;
      orderType: string;
      orderMethod: string;
      supplierOrderArrivalDate?: string;
      received: boolean;
      orderDate: DateStr;
      micsSentAt: DateTimeStr;
      lastModifiedAt?: DateTimeStr | null;
      vendorName: string;
    }>;
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

export const DistributionReceivingQuery = gql`
  query DistributionReceivingQuery($filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    response: FindAllCustomerOrders(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      records {
        id
        identifier
        customer {
          id
          name
        }
        vendorName
        mfcArea {
          id
          identifier
        }
        orderType
        orderMethod
        supplierOrderArrivalDate
        orderDate
        received
        micsSentAt
        lastModifiedAt
        sellDepartment {
          id
          identifier
        }
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
`;

export interface CustomerOrderReceivingStatusResponse {
  id: number;
  identifier: string;
  orderType: string;
  received: boolean;
  micsSent: boolean;
  scanBased: boolean;
}

export interface CustomerOrderReceivingStatusInput {
  filters: ActiveFilter[];
}

export const CustomerOrderReceivingStatusQuery = gql`
query CustomerOrderReceivingStatusQuery($type: RecordType = CustomerOrder, $filters: [FilterSpecificationInput]) {
  customerOrderReceivingStatusResponse: findAll(type: $type, filters: $filters) {
    id
    ...CustomerOrderReceivingOrderFields
  }
}
fragment CustomerOrderReceivingOrderFields on CustomerOrder {
  identifier
  orderType
  received
  micsSent
  scanBased
}
`;
