import gql from 'graphql-tag';
import { BolId } from 'shared/schemas/bol';
import { ReceivableOrderReceivingStatus, ReceivableOrderType } from 'shared/types';

export interface SaveBolDetailQuantityReceivedVariables {
  receivableOrderProductId: number;
  bolId: BolId;
  packOrShippingUnitQuantity: number;
}

export const SaveBolDetailQuantityReceivedMutation = gql`
  mutation saveBolDetailQuantityReceived($receivableOrderProductId: Int!, $bolId: Int!, $packOrShippingUnitQuantity: Int!) {
    response: saveBolDetailQuantityReceived(receivableOrderProductId: $receivableOrderProductId, bolId: $bolId, packOrShippingUnitQuantity: $packOrShippingUnitQuantity) {
      receivableOrder {
        id
        receivingStatus
      }
      affectedReceivableOrderProducts {
        id
        receivableOrderType
        packQuantityReceivedTotal
        shippingUnitQuantityReceivedTotal
        shippingUnitQuantityReceivedInBol(bolId: $bolId)
        packQuantityReceivedInBol(bolId: $bolId)
      }
      affectedCustomerOrder {
        id
        received # Update the Apollo cache so the received checkbox is checked on a cached customer order
      }
    }
  }
`;

export interface SaveBolDetailQuantityReceivedResponse {
  response: {
    receivableOrder: {
      id: number;
      receivingStatus: ReceivableOrderReceivingStatus;
    },
    affectedReceivableOrderProducts: Array<{
      id: number;
      receivableOrderType: ReceivableOrderType;
      packQuantityReceivedTotal: Int;
      shippingUnitQuantityReceivedTotal: Int;
      packQuantityReceivedInBol: Int;
      shippingUnitQuantityReceivedInBol: Int;
    }>,
  };
}
