import * as React from 'react';
import { ComponentProps } from 'client/app/transportation/cart-tracking/details/overview/add-new-transaction-modal-container';
import { FormModal } from 'client/components/form-modal';
import { MutationStatus } from 'client/actions/mutations';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledInput, LabeledDate } from 'client/components/form';
import { Row, FormGroup } from 'client/components/third-party';
import { convertEnumToDropdownOptions } from 'client/helpers/enum-helpers';

export enum AddNewTransactionType {
  Delivered = 'Delivered',
  Returned = 'Returned',
  BalanceReset = 'BalanceReset',
}

const transactionTypes = convertEnumToDropdownOptions(AddNewTransactionType);

export const AddNewTransactionModal: React.StatelessComponent<ComponentProps> = props => {
  const disabled = !props.quantity;
  const quantityText = props.transactionType === AddNewTransactionType.BalanceReset
    ? 'Balance Reset'
    : props.transactionType === AddNewTransactionType.Delivered
      ? 'Racks Delivered'
      : 'Racks Returned';

  return (
    <FormModal
      isShown={props.isShown}
      onModalEnter={props.onModalEnter}
      cancelDisabled={props.actionStatus !== MutationStatus.Initial}
      onCancelButtonClicked={props.handleCancelButtonClicked}
      handleFormSubmit={props.handleFormSubmit}
      invalid={disabled}
      submitButtonLabel="Add"
      submitButtonTestId="add-button"
      modalTitle="Add New Transaction"
      modalId="add-new-transaction-modal"
      actionStatus={props.actionStatus}
      className="add-new-transaction-modal">
      <Row>
        <FormGroup>
          <Field
            name="transactionType"
            component={LabeledSelect}
            options={transactionTypes}
            textFormatter={(r: { value: string }) => r.value}
            inputColSize={9}
            label="Transaction Type"
            labelColSize={9}
            offset={0}
            testid="add-new-transaction-modal-transaction-type"
            horizontalLabel={false}
            required
            valueField="id"
          />
          <Field
            name="entryDate"
            component={LabeledDate}
            inputColSize={3}
            label="Date"
            labelColSize={3}
            offset={0}
            testid="add-new-transaction-modal-entry-date"
            horizontalLabel={false}
            required
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <Field
            name="quantity"
            component={LabeledInput}
            type="number"
            inputColSize={3}
            label={quantityText}
            labelColSize={3}
            offset={0}
            testid="add-new-transaction-modal-quantity"
            horizontalLabel={false}
            required
            autoFocus
          />
          <Field
            name="notes"
            component={LabeledInput}
            type="textarea"
            inputColSize={9}
            label="Notes"
            labelColSize={9}
            offset={0}
            testid="add-new-transaction-modal-notes"
            horizontalLabel={false}
          />
        </FormGroup>
      </Row>
    </FormModal>
  );
};

export default AddNewTransactionModal;
