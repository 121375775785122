import * as TablesState from 'client/state/tables';
import * as TableState from 'client/state/table';
import { SORT_TYPES, SEARCH_FIELD_ANY } from 'shared/types';
import { BILLING_INVOICES_TABLE_NAME } from 'client/constants';

export const tableName = BILLING_INVOICES_TABLE_NAME;
export type Type = TableState.Type;
export const genericTableActions = TablesState.buildTableStateModule(tableName,
  {
    sort: [{
      sortOrder: SORT_TYPES.DESC,
      sortField: 'invoiceDate',
    }],
    availableSearchFields: [
      {
        id: SEARCH_FIELD_ANY,
        name: 'Search All',
      },
      {
        id: 'identifier',
        name: 'Invoice Number',
      },
      {
        id: 'customerIdentifier',
        name: 'Customer Code',
      },
      {
        id: 'storeIdentifier',
        name: 'Store',
      },
      {
        id: 'customerOrderIdentifier',
        name: 'PO #',
      },
      {
        id: 'notes',
        name: 'Notes',
      },
    ],
  },
);
