import { SelectableRow } from 'client/components/selectable/types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES  } from 'shared/types';
import * as Constants from '../replenishment-by-store/constants';
import * as _ from 'lodash';
import { applyFilterIfListHasValues, FindAllMfcAreasQueryResponse, FindAllMfcAreasQuery } from 'client/app/reports/shared/query';

export interface MfcAreasQueryProps {
  mfcAreaOptions?: SelectableRow[];
  mfcAreasLoading?: boolean;
}
export const withMfcAreas = msyncQuery<FindAllMfcAreasQueryResponse, { customerId?: number, sellDepartmentId?: number, filterByCategoryOrSalesPlan: Constants.FilterByCategoryOrSalesPlan }, MfcAreasQueryProps, {}>(FindAllMfcAreasQuery, {
  alias: 'withMfcAreas',
  skip(ownProps) {
    return ownProps.customerId === undefined;
  },
  options(ownProps) {
    const includeSellDepartmentFilter = _.isNil(ownProps.filterByCategoryOrSalesPlan) || ownProps.filterByCategoryOrSalesPlan === Constants.FilterByCategoryOrSalesPlan.ByCategory;
    return {
      variables: {
        filters: [
          { field: 'customer', values: [ownProps.customerId] },
          ...applyFilterIfListHasValues('sellDepartment',
            includeSellDepartmentFilter ? [ownProps.sellDepartmentId] : []), // Only filter MFC areas by sell department if we are filtering by category
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): MfcAreasQueryProps {
    const { data } = props;
    if (data.loading || data.mfcAreas === undefined) {
      return {
        mfcAreaOptions: [],
        mfcAreasLoading: data.loading,
      };
    }

    return {
      mfcAreaOptions: data.mfcAreas.map(mfcArea => ({
        id: mfcArea.id,
        cells: [
          mfcArea.identifier,
          mfcArea.sellDepartment.identifier,
        ],
      })),
      mfcAreasLoading: data.loading,
    };
  },
});
