import * as React                                              from 'react'                                ;
import {    FunctionComponent    , useState, useMemo }         from 'react'                                ;
import * as _                                                  from 'lodash'                               ;
import * as pluralize                                          from 'pluralize'                            ;
import      PageTitle                                          from 'client/components/page-title'         ;
import {    CELL_TYPES           , ElementOf, Numeric, TYPES } from 'shared/types'                         ;
import {    useClearStoreOnMount }                             from 'client/hoc/clear-store'               ;
import {    tableParentHoc       }                             from 'client/components/table/table-parent' ;
import {    buildDataTable       , OwnProps as TableProps }    from 'client/containers/table/basic-table'  ;
import {    createCustomTable    }                             from 'client/reducers/table-helpers'        ;
import {    RouteProps           }                             from 'react-router'                         ;
import {    IColumn              }                             from 'client/components/table/column'       ;
import {    Bug                  }                             from 'shared/helpers'                       ;
import {    MaGiQl               }                             from 'client/lib/magiql'                    ;
import { useLifeCycleLogging } from 'client/lib/react';
import { DateTime } from 'luxon';

const withTableParent = tableParentHoc();
type Data = SimpleObject[];
type DataInteraction<D extends Data> = {[K in keyof ElementOf<D>]?: {
    header?: string,
    cell?: CELL_TYPES,
    width?: number,
    sort?: boolean,
    edit?: boolean,
    type?: TYPES,
} & Partial<Omit<IColumn, 'id' | 'accessor' | 'header' | 'cellType' | 'columnWidth' | 'sortable' | 'tableEditable' | 'type'>>};

const inferType = (x: unknown, expected: TYPES | undefined) => {
    /** lower indices can supercede higher indices, but not the other way around. */
    const typeSpecificity = [
        TYPES.STRING,
        TYPES.BOOLEAN,
        TYPES.NUMBER,
        TYPES.FLOAT,
        TYPES.MONEY,
        TYPES.OBJECT,
        TYPES.DATE,
        TYPES.DATE_TIME,
        TYPES.CHOICE,
    ];

    let result: TYPES | undefined;
    switch (typeof x) {
        case 'object':
            result = x instanceof Date ? TYPES.DATE : TYPES.OBJECT;
            break;
        case 'boolean':
            result = TYPES.BOOLEAN;
            break;
        case 'string':
            result
                = !!Date.parse(x)                           ?   TYPES.DATE
                : Numeric.isNumber(x) && x.includes('$')    ?   TYPES.MONEY
                : Numeric.isNumber(x)                       ?   TYPES.NUMBER
                :                                               TYPES.STRING;
            break;
        case 'number':
            result = TYPES.NUMBER;
            break;
    }

    return !!result && (!expected || typeSpecificity.indexOf(result) < typeSpecificity.indexOf(expected!)) ? result : expected;
};

const deriveColumns = <D extends Data>(data: D, spec?: DataInteraction<D>): IColumn[] => {
    const cols = (spec
            ? _.keys(spec)
            : _(data).flatMap(_.keys).uniq().filter(x => x !== 'id' && !x.startsWith('_')).value()
    ).sort() as Array<keyof ElementOf<D>>;

    const widths = _.fromPairs(cols.map(x => [x, x.length]));
    const types = _.fromPairs(cols.map(x => [x, undefined as TYPES | undefined]));
    const distinctIds = new Set();
    for (const row of (data ?? []).filter(x => !_.isNil(x))) {
        const rowId = (row as any).id;
        if (_.isNil(rowId))
            throw new Bug(`Every row must contain an integer id; this one did not: ${row}`);

        distinctIds.add(rowId);
        for (const col of cols) {
            widths[col] = Math.max(widths[col], `${(row as ElementOf<D>)[col]}`.length);
            types[col] = inferType((row as ElementOf<D>)[col], spec?.[col]?.type ?? types[col]);
        }
    }

    if (distinctIds.size !== data.length)
        throw new Bug(`All rows must contain distinct integer ids, but some were repeated.`);

    const totalRequiredCharWidth = _.sum(_.values(widths));
    const percents = _.mapValues(widths, v => 100 * v / totalRequiredCharWidth);
    const remainder = 100 - _.sum(_.values(percents));
    const overrideHeaders = {};
    if (remainder > 0) {
        const c = '∫---FILLER---∫';
        cols.push(c as any);
        percents[c] = remainder;
        overrideHeaders[c] = '';
    }

    return cols.map(c => ({
        ... spec?.[c] ?? {},
        id: c,
        accessor: c,
        header: spec?.[c]?.header ?? overrideHeaders[c as any] ?? c,
        cellType: spec?.[c]?.cell ?? (
                types[c] === TYPES.NUMBER       ?   CELL_TYPES.NUMBER
            :   types[c] === TYPES.DATE         ?   CELL_TYPES.DATE
            :   types[c] === TYPES.DATE_TIME    ?   CELL_TYPES.DATE_TIME
            :   types[c] === TYPES.OBJECT       ?   CELL_TYPES.TEXTAREA
            :   types[c] === TYPES.CHOICE       ?   CELL_TYPES.DROPDOWN
            :                                       CELL_TYPES.TEXT
        ),
        type: types[c] ?? TYPES.STRING,
        sortable: spec?.[c]?.sort ?? true,
        tableEditable: spec?.[c]?.edit ?? true,
        columnWidth: widths[c] ?? spec?.[c]?.width ?? (100 / (cols.length || 10)),
    }));
};

// eslint-disable-next-line react-hooks/exhaustive-deps
const useColumnsOf = <D extends Data>(data: D, spec?: () => DataInteraction<D>) => useMemo(() => deriveColumns(data, spec?.()), [data]);

export const QuickDataTable = (p: Readonly<RouteProps>) => {
    useLifeCycleLogging('QuickDataTable');
    const shortDataDescription = 'users';

    // useState abuse -- guaranteed component-lifetime-bound memo
    const [Table] = useState(() => withTableParent(buildDataTable(`adhoc-${shortDataDescription}`, createCustomTable({}))) as FunctionComponent<Partial<TableProps>>);
    const {loading, merch_carts_at_stores = []} = MaGiQl({
        merch_carts_at_stores: MaGiQl.where(`( where: {store: {merchandiser: {user_mappings: {user_id: {_eq: ${313} } } } } } )`, [{
            id: MaGiQl.int,
            empty_carts: MaGiQl.int,
            entered_at: MaGiQl.string,
            entered_by_user: { id: MaGiQl.int, first_name: MaGiQl.string, last_name: MaGiQl.string },
            store: { id: MaGiQl.int, identifier: MaGiQl.string, city: MaGiQl.string},
        }]),
    });

    const data = useMemo(() => _.sortBy(merch_carts_at_stores, x => DateTime.fromISO(x.entered_at).toMillis()).map(x => ({
        'id': x.id,
        'Entered By': `${x.entered_by_user.first_name} ${x.entered_by_user.last_name}`,
        'Entered At': x.entered_at,
        'Store': `${x.store.identifier} - ${x.store.city}`,
        'Carts': x.empty_carts,
    })), [merch_carts_at_stores]);

    const allIds = useMemo(() => data.map(x => x.id), [data]);
    const columns = useColumnsOf(data);
    return (!useClearStoreOnMount() || loading) ? <div id='will be QuickDataTable after useClearStoreOnMount is ready'/> : (
        <div id= "mfc-page-content">
            <PageTitle title={pluralize(_.startCase(shortDataDescription))}/>
            <Table checkable list tablePaginated columns={columns} content={data} totalCount={data.length} filteredRecordIds={allIds} />
        </div>
    );
};
