import * as _ from 'lodash';
import * as Actions from './actions';
import * as HOC from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { GetSalesPlansByProductIdResponse } from 'schema/sales-plan/types';

interface OwnProps {
  handleCancelButtonClicked: () => void;
  handleCloseButtonClicked: () => void;
  productId: number;
  salesPlanOptionsLoading?: boolean;
}

interface DispatchProps {
  downloadButtonClicked: (args: { productId: number, salesPlanId: number | undefined }) => void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, OwnProps> = {
  downloadButtonClicked: Actions.downloadButtonClicked,
};

interface SalesPlanOptionProps {
  salesPlanOptions: GetSalesPlansByProductIdResponse;
}

export const GetAllSalesPlansByProductId = gql`
  query getAllSalesPlansByProductId($productId: Int!) {
    result: GetAllSalesPlansByProductId(productId: $productId) {
      id
      identifier
      year
    }
  }
`;

const withSalesPlanOptions = msyncQuery<{ result: GetSalesPlansByProductIdResponse }, OwnProps, {}>(GetAllSalesPlansByProductId, {
  options(ownProps) {
    return {
      variables: {
        productId: ownProps.productId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props({ data }) {
    return {
      salesPlanOptions: data.result ? _.orderBy(data.result, 'year', 'desc') : [],
      salesPlanOptionsLoading: data.loading,
    };
  },
});

export type ContainerProps =
  OwnProps &
  DispatchProps &
  SalesPlanOptionProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.ComponentProps, ContainerProps>();

const component = _.flowRight(
  ReactRedux.connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
  withSalesPlanOptions,
)(UI.DownloadSpecSheetModalUI) as shame as Component<OwnProps>;

export const DownloadSpecSheetModalContainer = (props: OwnProps) => HOC.propToComponent(component, props);
