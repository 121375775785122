import * as React from 'react';
import * as classNames from 'classnames';

export const PageTitle = (config: { title: string, fieldClasses?: string }) => {
  const classes = classNames('mfc-page-title', config.fieldClasses);
  return (
    <div className={classes}>
      {config.title}
    </div>
  );
};

export default PageTitle;
