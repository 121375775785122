import gql from 'graphql-tag';
import * as Types from 'schema/import-sales-plan/types';

export interface ImportSalesPlanVarietyColorDetailsMutationInput {
  input: Types.ImportSalesPlanVarietyColorDetailsInput;
}
export interface ImportSalesPlanVarietyColorDetailsMutationResponse {
  importSalesPlanVarietyColorDetails: {
    success: boolean;
    errors: string[];
  };
}

export const ImportSalesPlanVarietyColorDetailsMutation = gql`
  mutation ImportSalesPlanVarietyColorDetailsMutation($input: ImportSalesPlanVarietyColorDetailsInput!) {
    importSalesPlanVarietyColorDetails: ImportSalesPlanVarietyColorDetails(input: $input) {
      success
      errors
      salesPlan {
        id
        lastModifiedBy
        lastModifiedAt
      }
    }
  }
`;
