import gql from 'graphql-tag';
import { SalesPlanV2 } from 'schema/sales-plan/types';

export interface GetSalesPlanWeeklyProductVolumeQueryInput {
  salesPlanId: number;
}
export interface GetSalesPlanWeeklyProductVolumeQueryResponse {
  salesPlan: Pick<SalesPlanV2, 'id' | 'startWeek' | 'endWeek' | 'details'>;
}

export const GetSalesPlanWeeklyProductVolumeQuery = gql`
  query GetSalesPlanWeeklyProductVolume($salesPlanId: Int!) {
    salesPlan: GetSalesPlan(id: $salesPlanId) {
      id
      startWeek
      endWeek
      details {
        product {
          id
          identifier
          description
        }
        allocations {
          weekNumber
          pieceQuantity
        }
        varieties {
          id
          mix
          name
          percentages {
            weekNumber
            percentage
            missingProductVolume
          }
        }
      }
    }
  }
`;
