import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, setSchemaOptions, required } from './dsl';
import { DISPLAY_TYPES, ActiveInactive, SORT_TYPES } from '../types';
import { Customer, CustomerId } from './customer';
import { IRecord } from './record';
import { displayType } from './dsl';
import { CustomerDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('regions')
export class Region implements IRecord {
  id?: number;

  @property @required identifier: string;

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required customer: Customer;
  customerId: CustomerId;

  @property @required description: string;
  @property @required activeStatus: ActiveInactive;
}

definePresentation(Region, {
  identifier: {
    sortable: true,
    searchable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Region',
  },

  customer: {
    searchable: true,
    filterable: true,
    sortable: true,
    tableDisplay: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
  },
  description: {
    sortable: true,
    filterable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 50,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
});

setSchemaOptions(Region, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'customer', foreignColumn: 'name' },
});
