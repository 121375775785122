import * as React from 'react';
import * as _ from 'lodash';
import { CELL_TYPES, TYPES, DateStr, ReceivableOrderReceivingStatus } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME } from 'client/constants';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import PageTitle from 'client/components/page-title';
import * as RowMenu from 'client/components/table/row-menu/menu';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { AvailableSearchField, AvailableFilter } from 'client/types';

interface ReceivingRow {
  id: number;
  identifier: string;
  supplierVendorName: any;
  supplierLocationIdentifier: string | null;
  customerName: any;
  mfcAreaIdentifier: string | null;
  sellDepartmentIdentifier: string | null;
  orderType: string;
  orderMethod: string;
  arrivalDate: DateStr | null;
  storeDeliveryDate: DateStr | null;
  receivingStatus: ReceivableOrderReceivingStatus;
}

export interface OwnProps {
  tableParentInfo: TableParentInfo;
  receivingRows?: ReceivingRow[];
  loading?: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  onRowSelect(record): void;
  downloadReceivingFormMenuItemClicked(customerOrderIds: number[]): void;
}

const tableName = TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME;

const columns: IColumn[] = [
  { id: 'identifier'                 , accessor: 'identifier'                 , header: 'PO #'                , tableEditable: false, columnWidth: 8  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'supplierVendorName'         , accessor: 'supplierVendorName'         , header: 'Supplier / Vendor'   , tableEditable: false, columnWidth: 13 , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'supplierLocationIdentifier' , accessor: 'supplierLocationIdentifier' , header: 'Pickup At'           , tableEditable: false, columnWidth: 8  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'customerName'               , accessor: 'customerName'               , header: 'Customer'            , tableEditable: false, columnWidth: 14 , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'mfcAreaIdentifier'          , accessor: 'mfcAreaIdentifier'          , header: 'MFC Area'            , tableEditable: false, columnWidth: 6  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'sellDepartmentIdentifier'   , accessor: 'sellDepartmentIdentifier'   , header: 'Sell Department'     , tableEditable: false, columnWidth: 9  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'orderType'                  , accessor: 'orderType'                  , header: 'Order Type'          , tableEditable: false, columnWidth: 9  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'orderMethod'                , accessor: 'orderMethod'                , header: 'Order Method'        , tableEditable: false, columnWidth: 9  , sortable: true, cellType: CELL_TYPES.TEXT   , type: TYPES.STRING },
  { id: 'arrivalDate'                , accessor: 'arrivalDate'                , header: 'Arrival Date'        , tableEditable: false, columnWidth: 8  , sortable: true, cellType: CELL_TYPES.DATE   , type: TYPES.DATE   },
  { id: 'storeDeliveryDate'          , accessor: 'storeDeliveryDate'          , header: 'Store Delivery Date' , tableEditable: false, columnWidth: 8  , sortable: true, cellType: CELL_TYPES.DATE   , type: TYPES.DATE   },
  { id: 'receivingStatus'            , accessor: 'receivingStatus'            , header: 'Received'            , tableEditable: false, columnWidth: 8  , sortable: true, cellType: CELL_TYPES.STATUS , type: TYPES.STRING },
];

export default class ReceivingTable extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private readonly menuItems: RowMenu.RowMenuItem[];
  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
    this.menuItems = [ { label: 'Download Receiving Form', onClick: this.onDownloadReceivingFormMenuItemClicked, uncheckRecordFollowingClick: false, willRemove: false } ];
  }

  private onDownloadReceivingFormMenuItemClicked = (receivableOrderIds: number[]) => { this.props.downloadReceivingFormMenuItemClicked(receivableOrderIds); }
  public render() {
    return (
      <div id= "mfc-page-content">
        <div className="receiving-summary-table-wrapper">
          <PageTitle title="Receiving"/>
          <this.FilterableTable
            table={tableName}
            content={this.props.receivingRows || []}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={_.noop}
            loadMoreRecords={_.noop}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={'No Receivable Orders'}
            checkable={true}
            list={true}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            disableCreate={true}
            displayLoadingIndicator={true}
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
            headerMenuItems={this.menuItems}
            rowMenuItems={this.menuItems}
          />
        </div>
      </div>
    );
  }
}
