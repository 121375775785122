import * as React from 'react';
import { StatsCardBasic } from 'client/components/cards';
import { formatInteger } from 'shared/types';
import * as _ from 'lodash';

export function RacksCard(props: { racks?: number }) {
  return (
    <StatsCardBasic title="Racks">{_.isNil(props.racks) ? '-' : formatInteger(props.racks)}</StatsCardBasic>
  );
}
