import { Action, Dispatch } from 'redux';
import { GlobalStoreIdentifier, Return } from 'shared/types';
import { ImportRoutingLoadsInput } from 'schema/routing-load/routing-load-graphql-types';
import * as State from 'client/state/state';
import { RoutePlanId, AcquireRoutingLoadNumbersResponse } from 'schema/route-plan/route-plan-typescript-types';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import gql from 'graphql-tag';
import { timeout } from 'shared/helpers';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { ImportRouteMutation } from 'client/app/transportation/routing/import-route-modal/import-route-mutation';
import { AcquireRoutingLoadNumbersMutation } from 'client/app/transportation/routing/import-route-modal/acquire-routing-load-numbers-mutation';
import { RoutePlanLoadsTableQueryName } from 'client/app/transportation/routing/route-plan-details/loads/routing-loads-query';
import * as R from 'ramda';
import { Thunker } from 'client/types/redux-types';

export type ActionTypes =
  UploadStartedAction |
  ParsingStartedAction |
  ParsingSucceededAction |
  ParsingFailedAction |
  ImportRouteFileButtonClickedAction |
  PreviousButtonClickedAction |
  NextButtonClickedAction |
  CancelButtonClickedAction |
  RouteInformationValueChangedAction |
  SubmitStartedAction |
  SubmitSucceededAction |
  SubmitFailedAction |
  RoutingLoadNumbersAcquiredAction |
  ApplyTestDataAction |
  SplitStopStoreSelectedAction |
  StoreLoadRackQuantityChangedAction |
  CellBlurredAction
  ;

export enum ActionTypeKeys {
  UploadStarted = 'App/IMPORT_ROUTE_UPLOAD_STARTED',
  ParsingStarted = 'App/IMPORT_ROUTE_PARSING_STARTED',
  ParsingSucceeded = 'App/IMPORT_ROUTE_PARSING_SUCCEEDED',
  ParsingFailed = 'App/IMPORT_ROUTE_PARSING_FAILED',
  ImportRouteFileButtonClicked = 'App/IMPORT_ROUTE_IMPORT_ROUTE_FILE_BUTTON_CLICKED',
  PreviousButtonClicked = 'App/IMPORT_ROUTE_PREVIOUS_BUTTON_CLICKED',
  NextButtonClicked = 'App/IMPORT_ROUTE_NEXT_BUTTON_CLICKED',
  CancelButtonClicked = 'App/IMPORT_ROUTE_CANCEL_BUTTON_CLICKED',
  RouteInformationValueChanged = 'App/ROUTE_INFORMATION_VALUE_CHANGED',
  SubmitStarted = 'App/IMPORT_ROUTE_SUBMIT_STARTED',
  SubmitSucceeded = 'App/IMPORT_ROUTE_SUBMIT_SUCCEEDED',
  SubmitFailed = 'App/IMPORT_ROUTE_SUBMIT_FAILED',
  RoutingLoadNumbersAcquired = 'App/IMPORT_ROUTE_ROUTING_LOAD_NUMBERS_ACQUIRED',
  ApplyTestData = 'App/TEMP-ApplyTestData',
  SplitStopStoreSelected = 'App/SPLIT_STOP_STORE_SELECTED',
  StoreLoadRackQuantityChanged = 'App/STORE_LOAD_RACK_QUANTITY_CHANGED',
  CellBlurred = 'App/IMPORT_ROUTE_CELL_BLURRED',
}

export interface UploadStartedAction extends Action {
  type: ActionTypeKeys.UploadStarted;
  payload: {
    fileContents: string,
    fileName: string,
  };
}

export function uploadStarted(fileName: string, fileContents: string): UploadStartedAction {
  return {
    type: ActionTypeKeys.UploadStarted,
    payload: {
      fileName,
      fileContents,
    },
  };
}

export interface ImportRouteFileButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportRouteFileButtonClicked;
  payload: {
    routePlanId: RoutePlanId;
  };
}

export function importRouteFileButtonClicked(routePlanId: RoutePlanId): ImportRouteFileButtonClickedAction {
  return {
    type: ActionTypeKeys.ImportRouteFileButtonClicked,
    payload: {
      routePlanId,
    },
  };
}

export interface PreviousButtonClickedAction extends Action {
  type: ActionTypeKeys.PreviousButtonClicked;
}
export function previousButtonClicked(): PreviousButtonClickedAction {
  return {
    type: ActionTypeKeys.PreviousButtonClicked,
  };
}

export interface NextButtonClickedAction extends Action {
  type: ActionTypeKeys.NextButtonClicked;
}
export function nextButtonClicked(): NextButtonClickedAction {
  return {
    type: ActionTypeKeys.NextButtonClicked,
  };
}

interface MutationResult {
  importRoutingLoadsResult: {
    routingLoads: Array<{
      id: number;
    }>;
  };
}
const submitRoutesToServer = async (dispatch: Dispatch, state: State.Type) => {
  return msyncClientMutation<MutationResult>({
    mutation: ImportRouteMutation,
    variables: {
      input: ImportRouteSelectors.getLoadsInput(state),
    },
    dispatch,
    refetchQueries: [RoutePlanLoadsTableQueryName],
  });
};

interface AcquireRoutingLoadNumbersMutationResult {
  result: AcquireRoutingLoadNumbersResponse;
}

const acquireRoutingLoadNumbers = async (dispatch: Dispatch<any>, routePlanId: number, loadCount: number): Promise<string[]> => {
  const results = await msyncClientMutation<AcquireRoutingLoadNumbersMutationResult>({
    mutation: AcquireRoutingLoadNumbersMutation,
    variables: {
      input: {
        routePlanId,
        loadCount,
      },
    },
    dispatch,
  });

  return results.data.result.loadNumbers;
};

export interface SubmitStartedAction extends Action {
  type: ActionTypeKeys.SubmitStarted;
}
export function submitStarted(): SubmitStartedAction {
  return {
    type: ActionTypeKeys.SubmitStarted,
  };
}

export interface SubmitSucceededAction extends Action {
  type: ActionTypeKeys.SubmitSucceeded;
}
export function submitSucceeded(): SubmitSucceededAction {
  return {
    type: ActionTypeKeys.SubmitSucceeded,
  };
}

export interface SubmitFailedAction extends Action {
  type: ActionTypeKeys.SubmitFailed;
}
export function submitFailed(): SubmitFailedAction {
  return {
    type: ActionTypeKeys.SubmitFailed,
  };
}

export const finishButtonClicked = (): Thunker => async (dispatch, getState: () => State.Type) => {
  dispatch(submitStarted());
  try {
    await submitRoutesToServer(dispatch, getState());
    dispatch(submitSucceeded());
  } catch (e) {
    dispatch(submitFailed());
  }
};

export interface CancelButtonClickedAction extends Action {
  type: ActionTypeKeys.CancelButtonClicked;
}

export function cancelButtonClicked(): CancelButtonClickedAction {
  return {
    type: ActionTypeKeys.CancelButtonClicked,
  };
}

export interface ParsingStartedAction extends Action {
  type: ActionTypeKeys.ParsingStarted;
}
export function parsingStarted(): ParsingStartedAction {
  return {
    type: ActionTypeKeys.ParsingStarted,
  };
}

export interface ParsingSucceededAction extends Action {
  type: ActionTypeKeys.ParsingSucceeded;
}
export function parsingSucceeded(): ParsingSucceededAction {
  return {
    type: ActionTypeKeys.ParsingSucceeded,
  };
}

export interface ParsingFailedAction extends Action {
  type: ActionTypeKeys.ParsingFailed;
  payload: {
    messages: string[];
  };
}

export function parsingFailed(messages: string[]): ParsingFailedAction {
  return {
    type: ActionTypeKeys.ParsingFailed,
    payload: {
      messages,
    },
  };
}

export interface RoutingLoadNumbersAcquiredAction extends Action {
  type: ActionTypeKeys.RoutingLoadNumbersAcquired;
  payload: {
    pairs: LoadNumberRoutePlanIdPair[];
  };
}

interface LoadNumberRoutePlanIdPair {
  loadNumber: string;
  routeNumber: Int;
}

export function routingLoadNumbersAcquired(pairs: LoadNumberRoutePlanIdPair[]): RoutingLoadNumbersAcquiredAction {
  return {
    type: ActionTypeKeys.RoutingLoadNumbersAcquired,
    payload: {
      pairs,
    },
  };
}

interface QueryResponse {
  validationResult: {
    success: boolean,
    messages: string[],
  };
}

const validateLoadsInput = async (dispatch: Dispatch<any>, input: ImportRoutingLoadsInput) => {
  try {
    const result = await msyncClientQuery<QueryResponse>({
      dispatch,
      query: gql`
        query ValidateImportRoutingLoadsWhenUploading ($input: ImportRoutingLoadsInput!){
          validationResult: ValidateImportRoutingLoadsInput(input: $input) {
            success
            messages
          }
        }
      `,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
      disableGlobalError: true,
    });
    return {
      success: result.data.validationResult.success,
      messages: result.data.validationResult.messages,
    };
  } catch { // Gobble Gobble Gobble
    return {
      success: false,
      messages: ['The given UPL file is corrupted. Please choose another file.'],
    };
  }
};

export function routeFileUploaded(fileName: string, fileContents: string): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    dispatch(uploadStarted(fileName, fileContents));
    await timeout(100); // Get the spinner up and displayed

    dispatch(parsingStarted());
    const loadsInput = ImportRouteSelectors.getLoadsInput(getState());
    const validationResult = await validateLoadsInput(dispatch, loadsInput);
    if (validationResult.success) {
      const loadNumbers: string[] = await acquireRoutingLoadNumbers(dispatch, loadsInput.routePlanId, loadsInput.routingLoads.length);
      const routeNumbers = loadsInput.routingLoads.map(load => load.routeNumber);
      const pairs = R.zipWith((loadNumber, routeNumber) => {
        return {
          loadNumber,
          routeNumber,
        };
      }, loadNumbers, routeNumbers);
      dispatch(routingLoadNumbersAcquired(pairs));
      await timeout(500); // Doing this for looks, allow the spinner to be seen
      dispatch(parsingSucceeded());
    } else {
      dispatch(parsingFailed(validationResult.messages));
    }

  };
}

export interface RouteInformationValueChangedAction extends Action {
  type: ActionTypeKeys.RouteInformationValueChanged;
  payload: {
    fieldName: string;
    value: string;
  };
}

export function routeInformationValueChanged(fieldName: string): (value: string) => RouteInformationValueChangedAction {
  return (value: string) => {
    return {
      type: ActionTypeKeys.RouteInformationValueChanged,
      payload: {
        fieldName,
        value,
      },
    };
  };
}

export interface ApplyTestDataAction extends Action {
  type: ActionTypeKeys.ApplyTestData;
}

export function applyTestData(): ApplyTestDataAction {
  return {
    type: ActionTypeKeys.ApplyTestData,
  };
}

export interface SplitStopStoreSelectedAction extends Action {
  type: ActionTypeKeys.SplitStopStoreSelected;
  payload: {
    storeIdentifier: GlobalStoreIdentifier,
  };
}

export function splitStopStoreSelected(storeIdentifier: GlobalStoreIdentifier): SplitStopStoreSelectedAction {
  return {
    type: ActionTypeKeys.SplitStopStoreSelected,
    payload: { storeIdentifier },
  };
}

export interface StoreLoadRackQuantityChangedAction extends Action {
  type: ActionTypeKeys.StoreLoadRackQuantityChanged;
  payload: {
    loadNumber: string;
    customerOrderProductGroupId: number;
    quantity: Int;
    totalRackQuantityForGroup: Int;
  };
}

export function storeLoadRackQuantityChanged(loadNumber: string, customerOrderProductGroupId: number, quantity: Int, totalRackQuantityForGroup: Int): StoreLoadRackQuantityChangedAction {
  return {
    type: ActionTypeKeys.StoreLoadRackQuantityChanged,
    payload: {
      loadNumber,
      customerOrderProductGroupId,
      quantity,
      totalRackQuantityForGroup,
    },
  };
}

export const cellBlurred = ( groupsWithOrderQuantities: Array<{ customerOrderProductGroupId: number, rackQuantityOrdered: Int }> ) =>
  ({ type: ActionTypeKeys.CellBlurred as const, payload: { groupsWithOrderQuantities } });

export type CellBlurredAction = Return<typeof cellBlurred>;
