import 'reflect-metadata';
import { Supplier } from './supplier';
import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';

export type SupplierContactId = number;

@tableName('supplierContacts')
export class SupplierContact implements IRecord {
  id?: SupplierContactId;
  @property @required name: string;
  @property role?: string | null;
  @property phoneCell?: string | null;
  @property phoneOffice?: string | null;
  @property email?: string | null;
  @property primary: boolean;
  @property receivesReceipts: boolean;

  @belongsTo('suppliers')
  @property @required supplier: Supplier;
  supplierId: number;
}

definePresentation(SupplierContact, {
  name: {
    displayName: 'Name',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  role: {
    displayName: 'Role',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  phoneCell: {
    displayName: 'Cell',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
  },
  phoneOffice: {
    displayName: 'Office',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
  },
  email: {
    displayName: 'Email',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.EMAIL }),
  },
  primary: {
    displayName: 'Primary Contact',
    formDisplayType: displayType(DISPLAY_TYPES.RADIO),
    type: TYPES.BOOLEAN,
  },
  receivesReceipts: {
    displayName: 'Receives Receipts',
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
  },
  supplier: {
    formDisplay: false,
  },
});
