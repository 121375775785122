import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { isInvalid, isPristine } from 'redux-form';
import { msyncQuery } from 'client/hoc/graphql/query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { VendorRecordBarUI, UIProps } from 'client/app/admin/vendors/vendor-record-bar/ui';
import * as VendorRecordBarQuery from './query';
import * as Actions from './actions';
import { formName } from 'client/app/admin/vendors/vendor-form';
import { DateTimeStr } from 'shared/types';

interface ContainerProps {
  vendorId?: number;
}

interface StateProps {
  saveButtonDisabled: boolean;
  vendorIdentifier: string;
}

const mapStateToProps = (state: any, ownProps: ContainerProps): StateProps => {
  const invalid = isInvalid(formName)(state);
  const pristine = isPristine(formName)(state);

  return {
    saveButtonDisabled: pristine || invalid,
    vendorIdentifier: 'New Vendor',
  };
};

interface DispatchProps {
  onBackButtonClicked(): void;
  onSaveButtonClicked(): void;
}

const mapDispatchToProps = {
  onBackButtonClicked: Actions.backButtonClicked,
  onSaveButtonClicked: Actions.saveButtonClicked,
};

interface QueryProps {
  vendorIdentifier: string;
  vendorLastModifiedAt?: DateTimeStr;
}

const withVendorInfo = msyncQuery<VendorRecordBarQuery.QueryResponse, StateProps & ContainerProps, QueryProps, VendorRecordBarQuery.Input>(VendorRecordBarQuery.Query, {
  alias: 'withVendorInfo',
  skip(ownProps) {
    return ownProps.vendorId === undefined;
  },
  options(ownProps): { variables: VendorRecordBarQuery.Input } {
    return {
      variables: {
        vendorId: ownProps.vendorId as any, // TODO: Gracefully handle this. Options can be called before skip, but never actually fire the query. The types should reflect this.
      },
    };
  },
  props(props): QueryProps {
    const { data } = props;
    if (data.loading || data.getVendor === undefined) {
      return {
        vendorIdentifier: '',
      };
    }

    const vendor = data.getVendor.vendor;
    return {
      vendorIdentifier: vendor.identifier,
      vendorLastModifiedAt: vendor.lastModifiedAt ? vendor.lastModifiedAt : undefined,
    };
  },
});

const component = flowRight(
  connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  withVendorInfo,
)(VendorRecordBarUI as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  QueryProps &
  StateProps;

assertCompatible<UIProps, CombinedProps>();
export const VendorRecordBarContainer = (props: ContainerProps) => propToComponent(component, props);
