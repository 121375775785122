import * as GlobalState from 'client/state/state';
import { Lens } from 'client/utils/lenses';

export const buildMapStateToPropsFunction = <LocalState, StateProps, OwnProps>(
  lens: Lens<GlobalState.Type, LocalState>,
  mapStateToPropsFunction: (state: LocalState, props: OwnProps) => StateProps,
) => {
  return (state: GlobalState.Type, props: OwnProps) => {
    return mapStateToPropsFunction(lens(state), props);
  };
};
