import * as BS from '../third-party';
import * as Mutations from 'client/actions/mutations';
import * as React from 'react';
import * as SharedTypes from 'shared/types';
import * as SimpleSelect from '../simple-components/simple-select';
import * as Types from './types';
import Dropdown from 'client/components/dropdown';
import { SavePresetAsModal } from './modal';

export interface PresetOption {
  id: number;
  reportDefault: boolean;
  userDefault: boolean;
  userId: number | null;
  value: string;
}

export interface ComponentProps {
  actionStatus: Mutations.MutationStatus;
  allPresetOptions: PresetOption[];
  confirmOkToSave: () => Promise<boolean>;
  mapFromReportUserParams: Types.MapFromReportUserParams;
  mapToReportUserParams: Types.MapToReportUserParams;
  onActionTaken(reportType: SharedTypes.ReportTypes, presetId: number | undefined, action: Types.PresetActionTypes, mapToReportUserParams: Types.MapToReportUserParams, mapFromReportUserParams: Types.MapFromReportUserParams, reportFormName: string): void;
  onComponentUnloaded: (reportType: SharedTypes.ReportTypes) => void;
  onModalSaveButtonClicked: (reportType: SharedTypes.ReportTypes, mapToReportUserParams: Types.MapToReportUserParams, name: string) => void;
  onPresetClicked(presetId: number, reportFormName: string): void;
  onSaveAsPresetModalHidden(): void;
  presetId: number | undefined;
  pristine: boolean;
  reportType: SharedTypes.ReportTypes;
  saveAsPresetModalShown: boolean;
  userId: number | undefined;
  anyoneCanEdit?: boolean;
  noDefault?: boolean;
  componentsDisabled: boolean;
  currentPreset: PresetOption | undefined;
  canSetAsDefault: boolean;
  showSetAsDefault: boolean;
  canDelete: boolean;
  canSave: boolean;
  reportFormName: string;
}

export class UserParamsUI extends React.PureComponent<ComponentProps, {}> {
  onPresetClicked = (presetId: number) => {
    this.props.onPresetClicked(presetId, this.props.reportFormName);
  }

  onActionTaken = async (action: Types.PresetActionTypes) => {
    if (action === Types.PresetActionTypes.Delete)
      if (!await this.props.confirmOkToSave())
        return;

    this.props.onActionTaken(this.props.reportType, this.props.presetId, action, this.props.mapToReportUserParams, this.props.mapFromReportUserParams, this.props.reportFormName);
  }

  componentWillUnmount() {
    // This clears out all current state for the presets apparatus
    this.props.onComponentUnloaded(this.props.reportType);
  }

  modalSaveButtonClicked = (name: string) => {
    this.props.onModalSaveButtonClicked(this.props.reportType, this.props.mapToReportUserParams, name);
  }

  render() {
    return (
      <div>
        <BS.Row>
          <BS.Col sm={12} className="report-user-params-preset-select-container">
            <SimpleSelect.SimpleSelect
              disabled={this.props.componentsDisabled}
              horizontalLabel={false}
              inputColSize={10}
              labelColSize={10}
              onChange={this.onPresetClicked}
              options={this.props.allPresetOptions}
              testid="report-user-params-preset-select"
              value={this.props.presetId ? this.props.presetId : ''}
            />
          </BS.Col>
        </BS.Row>
        <BS.Row>
          <BS.Col sm={12}>
            <div className="report-user-params-preset-save-button-container">
              <BS.Button bsStyle="primary"
                bsClass="mfc-button mfc-new-record-button"
                data-testid="report-user-params-save-button"
                disabled={!this.props.canSave}
                onClick={() => this.onActionTaken(Types.PresetActionTypes.Save)}
              >
                {this.props.presetId ? 'Save Preset' : 'Save as New Preset'}
              </BS.Button>
            </div>
            <div className="report-user-params-preset-action-dropdown-container">
              <Dropdown
                className=""
                data={[
                  { id: Types.PresetActionTypes.Reload, value: 'Reload Preset' },
                  { id: Types.PresetActionTypes.SaveAs, value: 'Save as New Preset' },
                  ...(this.props.showSetAsDefault ? [{ id: Types.PresetActionTypes.SetAsDefault, value: 'Set as Default' }] : []),
                  ...(this.props.canDelete ? [{ id: Types.PresetActionTypes.Delete, value: 'Delete Preset' }] : []),
                ]}
                disabled={this.props.componentsDisabled || !this.props.presetId}
                onChange={this.onActionTaken}
                placeholder="Actions"
                testid="report-user-params-actions-dropdown"
                textFormatter={entry => entry.value}
                value={[]}
                valueField="id"
              />
            </div>

            <SavePresetAsModal
              actionStatus={this.props.actionStatus}
              isShown={this.props.saveAsPresetModalShown}
              onCancelButtonClicked={this.props.onSaveAsPresetModalHidden}
              saveButtonClicked={this.modalSaveButtonClicked}
            />
          </BS.Col>
        </BS.Row>
      </div>
    );
  }
}
