import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as Mutations from './mutations';
import * as ReduxTypes from 'client/types/redux-types';
import { push } from 'connected-react-router';
import { CustomerOrderInvoiceQueryName } from '../product-worksheet/customer-order-invoice-query';
import * as Redux from 'redux';

export const createInvoiceMenuItemClicked = (customerOrderId: number): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    try {
      const response = await GraphQLMutation.msyncClientMutation<Mutations.CreateInvoiceFromCustomerOrderResponse, Mutations.CreateInvoiceFromCustomerOrderInput>({
        mutation: Mutations.createInvoiceFromCustomerOrderMutation,
        showBusyModal: true,
        refetchQueries: [CustomerOrderInvoiceQueryName],
        variables: {
          customerOrderId,
        },
        dispatch,
      });

      if (response) {
        const invoiceId = response.data.response.invoice.id;
        dispatch(push(`/billing/invoicing/details/${invoiceId}`));
      }

    } catch {
      // The msyncClientMutation call above will show an error to the user,
      // and there's nothing else we need to do here so just swallow the error.
    }
  };
};

export const viewInvoiceMenuItemClicked = (invoiceId: number): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    dispatch(push(`/billing/invoicing/details/${invoiceId}`));
  };
};

export const downloadCustomerOrderSpreadsheet = (customerOrderId: number): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    try {
      const response = await GraphQLMutation.msyncClientMutation<Mutations.GenerateCustomerOrderSpreadsheetResponse, Mutations.GenerateCustomerOrderSpreadsheetInput>({
        mutation: Mutations.generateCustomerOrderSpreadsheetMutation,
        showBusyModal: true,
        variables: {
          customerOrderId,
        },
        dispatch,
      });

      if (response) {
        const reportId = response.data.response.report.id;
        window.location.replace(`/report/fileDownload/${reportId}`);
      }

    } catch {
      // The msyncClientMutation call above will show an error to the user,
      // and there's nothing else we need to do here so just swallow the error.
    }
  };
};

export type ActionTypes =
  OpenModalButtonClickedAction |
  AlertModalCancelButtonClickedAction;

export interface OpenModalButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.OpenModalButtonClicked;
  payload: {
    situation: string;
  };
}

export function replaceCustomerOrderMenuItemClicked(situation: string): OpenModalButtonClickedAction {
  return {
    type: ActionTypeKeys.OpenModalButtonClicked,
    payload: { situation },
  };
}

export enum ActionTypeKeys {
  OpenModalButtonClicked = 'ReplaceCustomerOrderAlertModal/OPEN_MODAL_BUTTON_CLICKED',
  AlertModalCancelButtonClicked = 'ReplaceCustomerOrderAlertModal/ALERT_MODAL_CANCEL_BUTTON_CLICKED',
}

export interface AlertModalCancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.AlertModalCancelButtonClicked;
  payload: {};
}

export function handleAlertModalCancelButtonClicked(): AlertModalCancelButtonClickedAction {
  return {
    type: ActionTypeKeys.AlertModalCancelButtonClicked,
    payload: {},
  };
}
