import * as _ from 'lodash';
import * as React from 'react';
import { formatInteger, ReceivingSummaryStats } from 'shared/types';
import { StatsCard } from 'client/components/simple-components/simple-stats-card';

export function ReceivingOrderedCard(props: Pick<ReceivingSummaryStats, 'unit' | 'ordered'>) {
  return (
    <StatsCard title={`${props.unit || ''} Ordered`}>{_.isNil(props.ordered) ? '-' : formatInteger(props.ordered)}</StatsCard>
  );
}
