import * as _ from 'lodash';
import {
  PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_CHECKED,
  PRODUCT_WORKSHEET_UNMOUNTED,
  PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_NAVIGATED,
  PRODUCT_WORKSHEET_COMBO_CART_CHECKED,
  PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_COMBO_CART_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED,
} from 'client/constants';
import { push } from 'connected-react-router';
import { Product, ProductId } from 'shared/schemas/product';
import { change, reset, formValueSelector } from 'redux-form';
import { Dispatch, Action } from 'redux';
import { AddProductsToCustomerOrderPayload } from 'client/types/product-worksheet';
import { ProductShipmentConfiguration, EditCustomerOrderProductGroupPayload, ComboProductGroupOption } from 'client/types/product-worksheet';
import { determineNextRackIdentifier, determineNextPalletIdentifier, determineNextComboIdentifier } from 'client/helpers/product-worksheet';
import { ComboCart, OrderMethod, ShippingUnitOrderMethod, PackOrderMethod, DateRange } from 'shared/types';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { ApolloRefetch } from 'client/types';
import gql from 'graphql-tag';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { getRegularProductGroupDescription } from 'shared/helpers/order-helpers';
import { PromiseResolution, PromiseRejection } from 'shared/types/promise';
import * as RecordActions from 'client/actions/record';
import * as State from 'client/state/state';
import * as ProductWorksheetSelectors from 'client/state/product-worksheet-selectors';
import { Thunker } from 'client/types/redux-types';
import { MutationStatus } from 'client/actions/mutations';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { ComboDetail } from 'schema/product-worksheet/types';

export type ActionTypes =
  SetAutoReplenishmentModalVisibilityAction |
  SetAutoReplenishmentMutationStatusAction;

export enum ActionTypeKeys {
  PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MODAL_VISIBILITY = 'App/PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MODAL_VISIBILITY',
  PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MUTATION_STATUS = 'App/PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MUTATION_STATUS',
}

export function productChecked(productId: number) {
  return {
    type: PRODUCT_WORKSHEET_PRODUCT_CHECKED,
    payload: { productId },
  };
}

export function comboCartChecked(comboCartId: number) {
  return {
    type: PRODUCT_WORKSHEET_COMBO_CART_CHECKED,
    payload: { comboCartId },
  };
}

export function productWorksheetUnmounted() {
  return async (dispatch: Dispatch<any>, getState: () => SimpleObject) => {
    dispatch({
      type: PRODUCT_WORKSHEET_UNMOUNTED,
    });
  };
}

export function productNavigated(customerOrderId: number, productId: number): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: PRODUCT_WORKSHEET_PRODUCT_NAVIGATED,
      payload: { productId },
    });
  };
}

export function packsPerShippingUnitCalculationInputChange(formName: string, productFieldIdentifier: string, field?: 'packsPerShelf' | 'shelvesPerRack', value?: number | string) {
  return (dispatch: Dispatch<any>, getState: () => SimpleObject) => {
    if (field) {
      dispatch(change(formName, `${productFieldIdentifier}.${field}`, value));
    }

    const selector = formValueSelector(formName);

    const formPacksPerShelf = selector(getState(), `${productFieldIdentifier}.packsPerShelf`) as number;
    const formShelvesPerRack = selector(getState(), `${productFieldIdentifier}.shelvesPerRack`) as number;

    const packsPerShippingUnit = Math.floor(formPacksPerShelf * formShelvesPerRack);
    if (formPacksPerShelf && formShelvesPerRack) {
      dispatch(change(formName, `${productFieldIdentifier}.packsPerShippingUnit`, packsPerShippingUnit));
      dispatch(piecesPerShippingUnitInputChange(formName, productFieldIdentifier));
    }
  };
}

export function piecesPerShippingUnitInputChange(formName: string, productFieldIdentifier: string, field?: 'packSize' | 'packsPerShippingUnit', value?: number | string) {
  return (dispatch: Dispatch<any>, getState: () => SimpleObject) => {
    if (field) {
      dispatch(change(formName, `${productFieldIdentifier}.${field}`, value));
    }

    const selector = formValueSelector(formName);
    const formPackSize = selector(getState(), `${productFieldIdentifier}.packSize`) as number;
    const formPacksPerShippingUnit = selector(getState(), `${productFieldIdentifier}.packsPerShippingUnit`) as number;

    const piecesPerShippingUnit = Math.floor(formPacksPerShippingUnit * formPackSize);
    dispatch(change(formName, `${productFieldIdentifier}.piecesPerShippingUnit`, _.isNaN(piecesPerShippingUnit) ? '' : piecesPerShippingUnit));
  };
}

export function productClicked(customerOrderId: number, productId: number): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: PRODUCT_WORKSHEET_PRODUCT_CLICKED,
      payload: { productId },
    });

    dispatch(push(`/orders/customer/details/${customerOrderId}/product-worksheet/${productId}`));
  };
}

export function redirectToProductWorksheet(customerOrderId: number) {
  return push(`/orders/customer/details/${customerOrderId}/product-worksheet`);
}

export function addNewProductButtonClicked() {
  return {
    type: PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  };
}

export function selectRecentComboButtonClicked() {
  return {
    type: PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  };
}

export function cancelSelectRecentComboButtonClicked() {
  return {
    type: PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  };
}

export function recentComboSelected(formName: string, orderMethod: OrderMethod, comboDetails: ComboDetail[], comboCartList: ComboCart[]) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(cancelSelectRecentComboButtonClicked());

    const customerOrderProducts: any[] = [];

    if (orderMethod === ShippingUnitOrderMethod) {
      comboDetails.forEach(comboDetail => {
        customerOrderProducts.push({
          productId: comboDetail.productId,
          shelvesPerRack: comboDetail.shelvesPerRack,
          packsPerShelf: comboDetail.packsPerShelf,
          packsPerShippingUnit: comboDetail.packsPerShippingUnit,
          packSize: comboDetail.packSize,
        });
      });
    } else if (orderMethod === PackOrderMethod) {
      comboDetails.forEach(comboDetail => {
        customerOrderProducts.push({
          productId: comboDetail.productId,
          packsPerShippingUnit: comboDetail.packsPerShippingUnit,
          packSize: comboDetail.packSize,
        });
      });
    }

    const identifier = determineNextComboIdentifier(comboCartList);

    dispatch(change(formName, 'customerOrderProducts', customerOrderProducts));
    customerOrderProducts.forEach((customerOrderProduct, index) => dispatch(piecesPerShippingUnitInputChange(formName, `customerOrderProducts[${index}]`)));
    dispatch(change(formName, 'identifier', identifier));
    dispatch(change(formName, 'description', identifier));
    dispatch(change(formName, 'isCombo', true));
  };
}

export function addNewRackTypeButtonClicked() {
  return {
    type: PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED,
  };
}

export function removeProductButtonClicked(customerOrderProductGroupIds: CustomerOrderProductGroupId[]) {
  return {
    type: PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED,
    payload: {
      customerOrderProductGroupIds,
    },
  };
}

export function editRackTypeButtonClicked(customerOrderProductGroupId: CustomerOrderProductGroupId) {
  return {
    type: PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED,
    payload: {
      customerOrderProductGroupId,
    },
  };
}

export function editComboCartButtonClicked(customerOrderProductGroupId: CustomerOrderProductGroupId) {
  return {
    type: PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED,
    payload: {
      customerOrderProductGroupId,
    },
  };
}

export function editComboCartModalCancelButtonClicked(formName: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(reset(formName));
    dispatch({
      type: PRODUCT_WORKSHEET_EDIT_COMBO_CART_CANCEL_BUTTON_CLICKED,
    });
  };
}

export function removeRackTypeButtonClicked(customerOrderProductGroupId: CustomerOrderProductGroupId) {
  return {
    type: PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED,
    payload: {
      customerOrderProductGroupId,
    },
  };
}

export function newProductModalCancelButtonClicked(formName: string): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch(reset(formName));
    dispatch({
      type: PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
    });
  };
}

export function editProductModalCancelButtonClicked(formName: string): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch(reset(formName));
    dispatch({
      type: PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
    });
  };
}

export function removeProductModalCancelButtonClicked() {
  return {
    type: PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED,
  };
}

export interface SetAutoReplenishmentModalVisibilityAction extends Action {
  type: ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MODAL_VISIBILITY;
  payload: {
    storeIdsFromMenuAction: number[];
    showModal: boolean;
  };
}

export function setAutoReplenishmentModalVisibility(storeIdsFromMenuAction: number[], showModal: boolean): SetAutoReplenishmentModalVisibilityAction {
  return {
    type: ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MODAL_VISIBILITY,
    payload: {
      storeIdsFromMenuAction,
      showModal,
    },
  };
}

export function removeModalConfirmed(args: { customerOrderProductGroupIds: CustomerOrderProductGroupId[], checkedProductIdsToUncheck: ProductId[] }) {
  return {
    type: PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED,
    payload: args,
  };
}

type ProductChanged = Pick<Product, 'id' | 'identifier' | 'packSize' | 'packsPerRack' | 'packsPerShelf' | 'shelvesPerRack' | 'description' | 'casesPerPallet'>;

function updateModalFieldsOnProductChange(args: { dispatch: any, formName: string, productFieldIdentifier: string, product: ProductChanged, orderMethod: OrderMethod }) {
  const { dispatch, formName, product, productFieldIdentifier, orderMethod } = args;

  dispatch(change(formName, `${productFieldIdentifier}.productId`, product.id));
  dispatch(change(formName, `${productFieldIdentifier}.packSize`, _.isNil(product.packSize) ? '' : product.packSize.toString()));

  if (orderMethod === ShippingUnitOrderMethod) {
    dispatch(change(formName, `${productFieldIdentifier}.shelvesPerRack`, _.isNil(product.shelvesPerRack) ? '' : product.shelvesPerRack.toString()));
    dispatch(change(formName, `${productFieldIdentifier}.packsPerShelf`, _.isNil(product.packsPerShelf) ? '' : product.packsPerShelf.toString()));
    dispatch(change(formName, `${productFieldIdentifier}.packsPerShippingUnit`, _.isNil(product.packsPerRack) ? '' : product.packsPerRack.toString()));
    dispatch(packsPerShippingUnitCalculationInputChange(formName, productFieldIdentifier));
    dispatch(piecesPerShippingUnitInputChange(formName, productFieldIdentifier));
  }
}

export function newProductModalProductChanged(args: { formName: string, productId: number, products: ProductChanged[], regularCartList: ProductShipmentConfiguration[], comboCartList: ComboCart[], orderMethod: OrderMethod, productFieldIdentifier: string, isCombo: boolean }): Thunker {
  const { formName, productId, products, regularCartList, orderMethod, productFieldIdentifier, comboCartList } = args;
  return (dispatch: Dispatch<any>) => {
    const product = products.find(p => p.id === productId);
    if (!product) {
      return;
    }
    const isCombo = args.isCombo || productFieldIdentifier !== 'customerOrderProducts[0]';
    const identifier = determineCustomerOrderProductGroupIdentifier(isCombo, comboCartList, orderMethod, productId, regularCartList);

    updateModalFieldsOnProductChange({ dispatch, product, formName, productFieldIdentifier, orderMethod });
    if (isCombo) {
      // Clear description and packsPerShippingUnit (CPP) if a new product is added
      dispatch(change(formName, 'description', ''));
      if (orderMethod === PackOrderMethod) {
        dispatch(change(formName, 'packsPerShippingUnit', ''));
      }
    } else {
      dispatch(change(formName, 'description', getRegularProductGroupDescription(product)));
      if (orderMethod === PackOrderMethod) {
        dispatch(change(formName, 'packsPerShippingUnit', _.isNil(product.casesPerPallet) ? '' : product.casesPerPallet.toString()));
      }
    }
    dispatch(change(formName, 'identifier', identifier));
  };
}

function determineCustomerOrderProductGroupIdentifier(isCombo: boolean, comboCartList: ComboCart[], orderMethod: OrderMethod, productId: number, regularCartList: ProductShipmentConfiguration[]) {
  let identifier = '';
  if (isCombo) {
    identifier = determineNextComboIdentifier(comboCartList);
  } else if (orderMethod === ShippingUnitOrderMethod) {
    identifier = determineNextRackIdentifier(productId, regularCartList);
  } else if (orderMethod === PackOrderMethod) {
    identifier = determineNextPalletIdentifier(productId, regularCartList);
  }
  return identifier;
}

export function editComboCartModalProductChanged(args: { formName: string, productId: number, products: ProductChanged[], productFieldIdentifier: string, orderMethod: OrderMethod, regularCartList: ProductShipmentConfiguration[], comboCartList: ComboCart[], isCombo: boolean, numProducts: number }): Thunker {
  const { formName, productId, products, productFieldIdentifier, orderMethod } = args;
  return (dispatch: Dispatch<any>) => {
    const product = products.find(p => p.id === productId);
    if (!product) {
      return;
    }

    const newCombo = args.numProducts < 2;
    const identifier = determineCustomerOrderProductGroupIdentifier(args.isCombo, args.comboCartList, orderMethod, productId, args.regularCartList);

    updateModalFieldsOnProductChange({ dispatch, product, formName, productFieldIdentifier, orderMethod });
    if (args.isCombo && newCombo) {
      dispatch(change(formName, 'description', identifier));
      if (orderMethod === PackOrderMethod) {
        dispatch(change(formName, 'packsPerShippingUnit', ''));
      }
      dispatch(change(formName, 'identifier', identifier));
    }
  };
}

export function newProductModalProductCleared(formName: string): Thunker {
  return (dispatch: Dispatch<any>) => {
    dispatch(change(formName, 'productId', undefined));
    dispatch(change(formName, 'packSize', ''));
    dispatch(change(formName, 'packsPerShippingUnit', ''));
    dispatch(change(formName, 'shelvesPerRack', ''));
    dispatch(change(formName, 'packsPerShelf', ''));
    dispatch(change(formName, 'description', ''));
    dispatch(change(formName, 'identifier', ''));
  };
}

export function removedFromProductList(
  customerOrderProductGroupIds: CustomerOrderProductGroupId[],
  deleteFunction: (customerOrderProductGroupIds: CustomerOrderProductGroupId[]) => Promise<void>,
): Thunker {
  return async (dispatch: Dispatch<any>) => {
    const { data: { findAllCount: countBasedOnCopgs } } = await msyncClientQuery<{findAllCount: number}>({
      query: gql`query removedFromProductListQuery ($type: RecordType!, $filters: [FilterSpecificationInput]) { findAllCount(type: $type, filters: $filters) }`,
      variables: {
        type: 'CustomerOrderAllocation',
        filters: [
          { field: 'customerOrderProductGroup', values: customerOrderProductGroupIds },
          { field: 'quantity', values: [0], not: true },
        ],
      },
      fetchPolicy: 'network-only',
      dispatch,
    });
    if (countBasedOnCopgs > 0) {
      // will trigger the display of a confirmation popup
      dispatch(removeProductButtonClicked(customerOrderProductGroupIds));
    } else {
      // will immediately perform the deletion
      await deleteFunction(customerOrderProductGroupIds);
    }
  };
}

export function newProductModalSaveClicked(
  formName: string,
  mutation: (payload: AddProductsToCustomerOrderPayload) => any,
  refreshProductsList: ApolloRefetch,
  worksheetStatsRefetch: ApolloRefetch | undefined,
  payload: AddProductsToCustomerOrderPayload,
): Thunker {
  return async (dispatch: Dispatch<any>) => {
    let productId;
    try {
      const result = await mutation(payload);
      if (!result)
        return; // Mutation did not execute (likely user did not confirm ok to save)

      const customerOrderProducts = result?.data?.data?.customerOrderProducts ?? [] as Array<{ product: { id: number } }>;
      if (customerOrderProducts.length > 0)
        productId = customerOrderProducts[0].product.id;
    } catch (error) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem saving new customer order product', error.message);
      return;
    }

    // If this is a different product than the one that we're currently looking at,
    // then we wouldn't need to do the worksheetStatsRefetch() call here - it will
    // get called when the productClicked action below is dispatched. That would be
    // an optimization someone could do at some point. For now, to make sure what's
    // displayed is correct, just refresh the stats (not 100% why this is even
    // needed, but it's here so I'm leaving it).
    await Promise.all([ refreshProductsList(), ...(worksheetStatsRefetch ? [worksheetStatsRefetch()] : [/*Might not have worksheetStatsRefetch function*/]) ]);
    dispatch(reset(formName));
    dispatch({ type: PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED });
    if (productId)
      dispatch(productClicked(payload.customerOrderId, productId));
  };
}

export function editProductModalUpdateClicked(formName: string, mutation: (payload: EditCustomerOrderProductGroupPayload) => void, refreshProductsList: ApolloRefetch, worksheetStatsRefetch: ApolloRefetch | undefined, payload: EditCustomerOrderProductGroupPayload): Thunker {
  return editProductListModalUpdateClicked({
    formName,
    mutation,
    refreshProductsList,
    worksheetStatsRefetch,
    payload,
    action: PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  });
}

function editProductListModalUpdateClicked(args: { formName: string, mutation: (payload: EditCustomerOrderProductGroupPayload) => any, refreshProductsList: ApolloRefetch, worksheetStatsRefetch: ApolloRefetch | undefined, payload: EditCustomerOrderProductGroupPayload, action: string }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    let productId;
    try {
      const result = await args.mutation(args.payload);
      if (!result)
        return; // Mutation did not execute (likely user did not confirm ok to save)

      dispatch({ type: args.action });

      const customerOrderProducts = result?.data?.data?.customerOrderProducts ?? [] as Array<{ product: { id: number } }>;
      if (customerOrderProducts.length > 0)
        productId = customerOrderProducts[0].product.id;

      if (productId)
        dispatch(productClicked(args.payload.customerOrderId, productId));
    } catch (error) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem updating customer order product', error.message);
      return;
    }
    try {
      await Promise.all([ args.refreshProductsList(), ...(args.worksheetStatsRefetch ? [args.worksheetStatsRefetch()] : [/*Might not have worksheetStatsRefetch function*/]) ]);
    } catch (error) {
      console.info('Problem refreshing', error.message);
    }

    dispatch(reset(args.formName));
  };
}

// NOTE: This function assumes that we are dealing with an order method of case
export function newProductModalComboProductGroupChanged(args: { formName: string, selectedComboProductGroup: ComboProductGroupOption | null, comboCartList: ComboCart[] }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    if (args.selectedComboProductGroup) {
      dispatch(change(args.formName, 'comboProductGroupId', args.selectedComboProductGroup.id));
      const orderedComboProducts = _.orderBy(args.selectedComboProductGroup.comboProducts, ['id']);
      dispatch(change(args.formName, 'customerOrderProducts', orderedComboProducts.map(comboProduct => {
        return {
          productId: comboProduct.product.id,
          packSize: comboProduct.packSize,
        };
      })));
      dispatch(change(args.formName, 'packsPerShippingUnit', args.selectedComboProductGroup.packsPerShippingUnit));
      dispatch(change(args.formName, 'description', args.selectedComboProductGroup.description));
      dispatch(change(args.formName, 'identifier', determineNextComboIdentifier(args.comboCartList)));
    } else {
      dispatch(change(args.formName, 'comboProductGroupId', null));
    }
  };
}

export function setAddProductsFromSalesPlanMutation(status: MutationStatus) {
  return {
    type: PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS,
    payload: {
      status,
    },
  };
}

interface SetMutationStatusAction extends Action {
  payload: {
    status: MutationStatus,
  };
}

export interface SetAutoReplenishmentMutationStatusAction extends SetMutationStatusAction {
  type: ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MUTATION_STATUS;
}

export function setAutoReplenishmentMutationStatus(status: MutationStatus): SetAutoReplenishmentMutationStatusAction {
  return {
    type: ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MUTATION_STATUS,
    payload: {
      status,
    },
  };
}

const GET_UPDATED_ALLOCATION_DETAILS_QUERY = gql`
  query GetProductWorksheetAllocationDetails($customerOrderId: Int!, $selectedProductId: Int!, $selectedComparableProductId: Int, $storeIds: [Int]!, $performanceDateRange: DateRange!, $salesDateRange: DateRange!, $pieceTargetDateRange: DateRange!, $pieceTargetPrimaryStoresOnly: Boolean!) {
    details: GetProductWorksheetAllocationDetails(customerOrderId: $customerOrderId, selectedProductId: $selectedProductId, selectedComparableProductId: $selectedComparableProductId, storeIds: $storeIds, performanceDateRange: $performanceDateRange, salesDateRange: $salesDateRange, pieceTargetDateRange: $pieceTargetDateRange, pieceTargetPrimaryStoresOnly: $pieceTargetPrimaryStoresOnly) {
      stats {
        customerOrderId
        productId
        piecesAllocated,
        allocations {
          orderProductIdentifier,
          numberOfRacks,
          numberOfPacks,
        },
        reportedInventory,
        possibleAllocations {
          orderProductIdentifier,
          numberOfRacks,
          numberOfPacks,
        }
      }
      allocations {
        customerOrderId
        storeId
        productId
        allPiecesSales
        allPiecesAllocated
        percentReplenished
        customerOrderProducts
        percentShippedOfPieceTarget
        percentShippedOfPieceTargetWithCurrentOrder
        shippedWithDraft
        shipped
      }
      customerOrder {
        id
        lastModifiedAt
      }
    }
  }
`;

export function refetchProductAllocations(args: {
  customerOrderId: number,
  selectedProductId: number,
  selectedComparableProductId: number | undefined,
  storeIds: number[],
  performanceDateRange: DateRange,
  salesDateRange: DateRange,
  pieceTargetDateRange: DateRange,
  pieceTargetPrimaryStoresOnly: boolean,
}): Thunker {
  return async (dispatch: Dispatch<any>) => {
    await msyncClientQuery<{findAllCount: number}>({
      query: GET_UPDATED_ALLOCATION_DETAILS_QUERY,
      variables: {
        customerOrderId: args.customerOrderId,
        selectedProductId: args.selectedProductId,
        selectedComparableProductId: args.selectedComparableProductId,
        storeIds: args.storeIds,
        performanceDateRange: args.performanceDateRange,
        salesDateRange: args.salesDateRange,
        pieceTargetDateRange: args.pieceTargetDateRange,
        pieceTargetPrimaryStoresOnly: args.pieceTargetPrimaryStoresOnly,
      },
      fetchPolicy: 'network-only', // Whole point of this thing is to get the latest from the server
      dispatch,
    });
  };
}

function cacheKeyForAllocation(allocInfo: { customerOrderProductGroupId: number, storeId: number }) {
  return `${allocInfo.customerOrderProductGroupId}-${allocInfo.storeId}`;
}

type MutationCallbackMap = {
  [key: string]: {
    mutationResolve: PromiseResolution<void>,
    mutationReject: PromiseRejection,
  };
};

type SaveAllocationFunc = (allocations: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number }>) => void;
type RefetchAllocationFunc = (storeIds: number[]) => void;

// This is a smell that we're doing something wrong. In order to get the cell to highlight
// when there's an error the onSave callback it uses needs to throw an error. That's currently
// being done by wrapping the mutation in a Promise and then rejecting or resolving as
// appropriate. In this case, for the product allocations, the path to the mutation is long
// and convoluted. In addition, the redux state is being used to keep track of batched
// and sequenced allocations that are pending. It's frowned up to include functions in the
// redux state, but since the dispatched action thunk does some batching it needs to keep
// the resolve/reject somewhere so they can be called when necessary.
//
// So they'll be kept here, keyed by a combination of the COPG ID and store ID. Need to be
// careful to avoid memory leaks by not cleaning up properly. There has to be a better way,
// but a lot of clean options are eliminated by the size of the product worksheet table.
const mutationCallbacks: MutationCallbackMap = {};

let nonce = 0;
let mostRecentRefetchFunc: RefetchAllocationFunc | undefined;

type ProductAllocationChangedArgs = {
  customerOrderProductGroupId: number,
  storeId: number,
  quantity: number,
  prevQuantity: number,
  saveFunc: SaveAllocationFunc,
  refetchFunc: RefetchAllocationFunc,
  mutationResolve: PromiseResolution<void>,
  mutationReject: PromiseRejection,
};
/**
 * This "thunk" manages (using redux state) batching up allocation changes and sending a few at a time
 * to the server, waiting for the batch to finish, and then sending another batch. When all queued
 * up changes have been sent it refetches stats, etc.
 */
export function productAllocationChanged(args: ProductAllocationChangedArgs) {
  // Hold on to the refetchFunc for later use
  mostRecentRefetchFunc = args.refetchFunc;

  return (dispatch: Dispatch<any>, getState: () => State.Type) => {

    async function saveAllocations(finished: boolean = false) {
      const state: State.Type = getState();
      const queuedAllocations = ProductWorksheetSelectors.findQueuedAllocationsToSave(5)(state);

      if (queuedAllocations.length === 0) {
        // Remove the spinner on the save button
        if (finished) {
          dispatch(RecordActions.globalSaveFinished());
        }

        // Nothing left to save
        return;
      }

      const lastSavedNonce = _.sortBy(queuedAllocations, 'nonce').reverse()[0].nonce;

      // Are there any in flight changes already being saved, or is it
      // refreshing details? Want to hold off doing anything if either is the case.
      if (ProductWorksheetSelectors.isAllocationRequestInFlight(state)) {
        return;
      }

      try {
        // This will move the queued allocations into a in-flight bucket of allocations (and out of the queued bucket)
        dispatch({
          type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED,
          payload: {
            allocationsToSave: queuedAllocations,
          },
        });

        // Retrieve the callbacks used to show errors
        const queuedAllocationsWithPromiseFuncs = queuedAllocations.map(q => {
          const callbackKey = cacheKeyForAllocation(q);
          const promiseFuncs = mutationCallbacks[callbackKey];
          delete mutationCallbacks[callbackKey];
          return {
            ...q,
            mutationResolve: promiseFuncs.mutationResolve,
            mutationReject: promiseFuncs.mutationReject,
          };
        });
        await args.saveFunc(queuedAllocationsWithPromiseFuncs);

        // And this clears the in-flight bucket
        dispatch({ type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED });

        // After the previous request completed, it might already be time
        // to run the next request right away
        const state2 = getState();
        if (ProductWorksheetSelectors.numberOfQueuedAllocationChanges(state2) > 0) {
          setTimeout(async () => {
            await saveAllocations();
          }, 1);
        } else {
          // Done saving, and no new changes have been made. Refetch details to update
          // all the totals and stats on the screen
          try {
            dispatch({ type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED });
            const storeIds = ProductWorksheetSelectors.findStoreIdsUpToNonce(lastSavedNonce)(state2);

            // NOTE: This refetchFunc is specific to a selected product. The way this currently
            //       works, it's closing over the currently selected product each time. The most
            //       recent version is being cached, and whenever it gets to this point it's
            //       going to only call the most recent version.

            //       If the user has changed to a different product, but not changed any allocations
            //       then the refetch will retrieve updated data for stuff that's not displayed
            //       to the user (no harm doing that). If the user has made an allocation change
            //       after switching products then the cached function will have been updated and
            //       we should be retrieving data for the correct product.
            //
            //       Because the data is pulled fresh from the server when switching products it's
            //       OK to not update the data from the previous product. It will get updated if the
            //       user ever switches back.
            if (mostRecentRefetchFunc) {
              await mostRecentRefetchFunc(storeIds);
            }

            // Unfortunately there's a delay between when the refetch function returns and
            // when the screen updates with the results of the cache being updated.
            // To prevent the totals from bouncing around, throw in a delay before dispatching the action.
            setTimeout(async () => {
              dispatch({ type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED, payload: { nonce: lastSavedNonce } });

              // After a refetch there might be new pending changes that need to be saved
              await saveAllocations(true);
            }, 500);

          } catch (err) {
            dispatch({ type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED });

            // After a failed refetch, but it's possible new stuff to save was added
            // during the refetch
            await saveAllocations();
          }
        }
      } catch (err) {
        dispatch({ type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED });

        // Try again in case there's more to save (it won't retry anything since
        // the in-flight allocations were cleared).
        setTimeout(async () => {
          await saveAllocations();
        }, 1);
      }
    }

    // Should this (the nonce) be in state instead of just a variable in the module?
    // Or is that just causing unecessary state change / possible re-render?
    nonce += 1;

    // Update a cache that will store these callbacks until they are needed. Would love to
    // figure out a better way to do this
    const key = cacheKeyForAllocation(args);
    mutationCallbacks[key] = {
      mutationResolve: args.mutationResolve,
      mutationReject: args.mutationReject,
    };

    // If nothing is currently waiting to be saved, or in-flight on the way to be saved, set a
    // timeout to come back and do the save (giving the user a brief window to make an additional
    // change that will get batched up). Don't want to make this initial delay too long in case the
    // user is only updating a single allocation. And can't do it immediately because we're not
    // dispatching the change (to queue it up) until below.
    if (ProductWorksheetSelectors.isAllocationsBeingProcessed(getState())) {
      setTimeout(async () => {
        await saveAllocations();
      }, 100);
    }

    dispatch(RecordActions.globalSaveStarted());
    dispatch({
      type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED,
      payload: {
        customerOrderProductGroupId: args.customerOrderProductGroupId,
        quantity: args.quantity,
        prevQuantity: args.prevQuantity,
        storeId: args.storeId,
        nonce,
      },
    });
  };
}

export function pendingAllocationResetNeeded() {
  return {
    type: PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED,
  };
}

export function selectedComparableProductIdChanged(productId: number, selectedComparableProductId: number | undefined): Thunker {
  return async (dispatch: Dispatch<any>) => {
    await msyncClientMutation<{}, {}>({
      mutation: gql`
        mutation ComparableProductIdChangedMutation($input: EditProductInput!) {
          response: editProduct(input: $input) {
            id
            comparableProduct {
              id
              identifier
            }
          }
        }
      `,
      variables: {
        input: {
          id: productId,
          comparableProductId: selectedComparableProductId,
        },
      },
      dispatch,
    });
  };
}
