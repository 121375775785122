import { buildAdminDetailPage } from '../admin/admin-detail-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';
import FormComponent from 'client/components/crud-forms/fuel-surcharge-rate-form';

export default buildAdminDetailPage({
  FormComponent,
  formName: 'FuelSurchargeRateDetailsForm',
  OptionalStatusToggle: EmptyStatusToggle,
  table: 'FuelSurchargeRate',
});
