import { DateStr, LoadTrackingSortByOptions, Carrier, ReportTypes } from 'shared/types';

export const formName = 'loadTrackingListReport';
export const reportType = ReportTypes.LoadTrackingList;

export enum FormFields {
  carriers = 'carriers',
  beginDate = 'beginDate',
  endDate = 'endDate',
  selectedLoadTrackingSortByOption = 'selectedLoadTrackingSortByOption',
  loadTrackingReportHeader = 'loadTrackingReportHeader',
}

export interface FormValues {
  [FormFields.carriers]: Carrier[];
  [FormFields.beginDate]: DateStr | undefined;
  [FormFields.endDate]: DateStr | undefined;
  [FormFields.loadTrackingReportHeader]: string;
  [FormFields.selectedLoadTrackingSortByOption]: LoadTrackingSortByOptions;
}
