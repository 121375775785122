import * as React from 'react';
import { Checkbox } from 'client/components/form/checkbox';
import { createSelector } from 'reselect';

export interface SimpleCheckboxProps {
  id?: string;
  label?: string;
  testid: string;
  value: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const getCheckedState = (state: { checked: boolean }) => state.checked;
const makeCheckboxInput = createSelector([getCheckedState], value => ({ value }));

/**
 * The SimpleCheckbox keeps track of its checked state using internal component state. The checked
 * state is updated whenever a user clicks the component, or when new props come in.
 */
export class SimpleCheckbox extends React.PureComponent<SimpleCheckboxProps, { checked: boolean }> {
  constructor(props: SimpleCheckboxProps) {
    super(props);
    this.state = {
      checked: props.value,
    };
  }

  handleCheckboxChange = (value: boolean) => {
    this.setState({ checked: value });
    this.props.onChange(value);
  }

  componentWillReceiveProps(nextProps: SimpleCheckboxProps) {
    this.setState({ checked: nextProps.value });
  }

  render() {
    const input = makeCheckboxInput(this.state);

    return <Checkbox
      testid={this.props.testid}
      input={input}
      onClick={this.handleCheckboxChange}
      disabled={this.props.disabled}
      label={this.props.label}
      className={this.props.className}
    />;
  }
}
