import * as State from 'client/state/state';
import { BusyModalUI, ComponentProps as UIProps } from './busy-modal-ui';
import { connect } from 'react-redux';
import assertCompatible from 'shared/helpers/assert-compatible';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import * as MutationSelectors from 'client/state/mutation-selectors';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
  show: boolean;
  message?: string;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    show: MutationSelectors.isBusyModalShown(state),
    message: MutationSelectors.busyModalMessage(state),
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
}

const mapDispatchToProps: DispatchProps = {
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(BusyModalUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
