import { SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';

export const saveConfirmationOptionsForSalesPlans: SaveConfirmationArgs<any> = {
  onlyAskOnce: false,
  shouldDisplay: (record?: never) => true,
  message: {
    title: 'Delete Sales Plan',
    body: `Are you sure you would like to delete this sales plan?`,
  },
  saveConfirmMessage: 'Delete Sales Plan',
};

export type ConfirmOkToSaveFunction = () => Promise<boolean>;
