import gql from 'graphql-tag';

export const FindProductByIdQuery = gql`
query findProductById($type: RecordType = Product, $id: Int!) {
  product: find(type: $type, id: $id) {
    id,
    ...FindProductByIdFragment
    __typename
  }
}
fragment FindProductByIdFragment on Product {
  id
  identifier
  description
  comparableProduct {
    id
    identifier
  }
}
`;

export interface ProductWorksheetFindProductQueryResponse {
  id: number;
  identifier: string;
  description: string;
  comparableProduct: {
    id: number;
    identifier: string;
  } | undefined;
}
