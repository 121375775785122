import { Rows } from './rows';
import { SelectableColumn, SelectableRow } from './types';
import * as React from 'react';

export interface CheckedRowsProps {
  cols: SelectableColumn[];
  onUnchecked: (row: SelectableRow) => void;
  rows: SelectableRow[];
  rowsRef?: (element: HTMLDivElement) => void;
}

export const CheckedRows = (props: CheckedRowsProps) => {
  return (
    <Rows
      cols={props.cols}
      onUnchecked={props.onUnchecked}
      rows={props.rows}
      rowsRef={props.rowsRef}
      checked={true}
    />
  );
};
