import gql from 'graphql-tag';

export const GetRecentCombosQuery = gql`
  query RecentCombosQuery($input: GetRecentCombosInput!) {
    response: GetRecentCombos(input: $input) {
      combos {
        lastUsed
        details {
          productId
          packSize
          packsPerShippingUnit
          shelvesPerRack
          packsPerShelf
          productIdentifier
          productDescription
        }
      }
    }
  }
`;
