import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import { DateTimeStr } from 'shared/types';

export interface UIProps {
  invoiceLastModifiedAt?: DateTimeStr;
  invoiceIdentifier?: string;
  onBackButtonClicked?: () => void;
  onSaveButtonClicked(): void;
  saveButtonDisabled: boolean;
  customerOrderId: number | undefined;
  customerOrderIdentifier: string | undefined;
  onCustomerOrderIdentifierClicked(customerOrderId: number): void;
}

export class RecordBarUI extends React.PureComponent<UIProps, {}> {
  onCustomerOrderIdentifierClicked = () => {
    if (this.props.customerOrderId) {
      this.props.onCustomerOrderIdentifierClicked(this.props.customerOrderId);
    }
  }

  render() {
    return (
      <RecordBar
        updatedAt={this.props.invoiceLastModifiedAt}
        onBackButtonClicked={this.props.onBackButtonClicked}
        onSaveButtonClicked={this.props.onSaveButtonClicked}
        saveButtonDisabled={this.props.saveButtonDisabled}>
        <RecordBarDetail title="Invoice ID" testid="record-bar-invoice-identifier">
          {this.props.invoiceIdentifier}
        </RecordBarDetail>
        {!!this.props.customerOrderIdentifier &&
          <RecordBarDetail
            title="Customer Order"
            testid="record-bar-customer-order-identifier">
            <a onClick={this.onCustomerOrderIdentifierClicked}>{this.props.customerOrderIdentifier}</a>
          </RecordBarDetail>}
      </RecordBar>
    );
  }
}
