import { tableName, property, belongsTo, definePresentation, required } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';
import { DateStr, DISPLAY_TYPES } from 'shared/types';
import { Customer, CustomerId } from 'shared/schemas/customer';
import { displayType } from './dsl';
import { ImportJob } from 'shared/schemas/import-job';

@tableName('remittanceAdvices')
export class RemittanceAdvice implements IRecord {
  id?: number;

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required customer: Customer;
  customerId: CustomerId;

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @property @required checkNumber: string;
  @property totalAmount?: number | null;
  @property checkDate?: DateStr | null;
  @property vendor?: string | null;
  @property paid?: boolean | null;
  @property reconciled?: boolean | null;
  @property transferred?: boolean | null;
}

definePresentation(RemittanceAdvice, {
  customer: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  totalAmount: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  checkNumber: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  checkDate: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  vendor: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  paid: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  reconciled: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  transferred: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  importJob: { formDisplay: false },
});
