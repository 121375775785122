import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as SchemaTypes from 'schema/planning-report/types';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

    const salesPlanId = formValues[Constants.FormFields.salesPlanId];
    const productIds = extractSelectedValues(formValues[Constants.FormFields.productIds]);

    if (_.isNil(salesPlanId) || _.isNil(productIds)) {
      throw new Error('Missing some required fields');
    }

    dispatch(ReportActions.reportDownloadStarted());
    try {
      const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateSalesPlanVarietiesExportInput>({
        mutation: Mutations.generateSalesPlanVarietiesExport,
        variables: {
          generateSalesPlanVarietiesExportInput: {
            salesPlanId,
            productIds,
          },
        },
        dispatch,
      });

      if (!response) {
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
