import * as React from 'react';
import EditableCell from './editable-cell';
import { DateStr } from 'shared/types';
import { formatDate } from 'shared/helpers/date-helpers';

interface ReadOnlyDateCellProps {
  value: DateStr;
  testid: string;
}

interface DateCellProps extends ReadOnlyDateCellProps {
  editing: boolean;
  onChange: (newValue: any) => void;
  testid: string;
}

const tryFormatDate = (v: unknown, style: string) => {
  try {
    const formatted = formatDate(v as DateStr | null | undefined, style);
    return formatted === 'Invalid date' ? `${v}` : formatted;
  } catch {
    return `${v}`;
  }
};

const ReadOnlyDateCell = ({ value, testid }: ReadOnlyDateCellProps) => (value && value !== '--')
  ? <div data-testid={testid}>{tryFormatDate(value, 'L')}</div>
  : <div data-testid={testid} className="number">-</div>;

export default function DateCell({ value, editing, onChange, testid }: DateCellProps) {
  return editing
    // We're not using the date picker right now - there are some issues in the table-ui.tsx with mousetrapped events
    // so tab/enter events are getting captured and not passed down here,
    // so we can click the datepicker to choose a date but we can't type one in
    // ? <DatePicker onChange={onChange} testid={testid} value={value} name="table-date-cell"/>
    ? <EditableCell onChange={onChange} testid={testid} initialValue={value || ''} inputType="date" />
    : <ReadOnlyDateCell testid={testid} value={value} />;
}
