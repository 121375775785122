// import { log } from 'client/lib/logger';
import * as _ from 'lodash';
import * as React from 'react';
import { useState, useEffect, ComponentType } from 'react';
import { useApolloClient } from 'react-apollo';


export type CancellableFn = (taskState?: {cancelled: boolean}) => Promise<unknown>;
export type CleanupFn = () => void;
export const onMountDeps = [];
export const nameof = (fn: any, fallback?: string) => !_.isFunction(fn) ? 'NOT_A_COMPONENT' : ((fn as any).displayName || fn.name || fallback || 'ANON_COMP');
export const unAnon = <F extends (...args: unknown[]) => unknown>(name: string, fn: F) => Object.defineProperties(fn, {
          name : {configurable: true, enumerable: false, writable: false, value: name},
    displayName: {configurable: true, enumerable: true, writable: true, value: name},
});

export const usePromise = <F extends CancellableFn>(fn: F, deps?: unknown[], cleanup: CleanupFn = _.noop) => useEffect(unAnon(`usePromise_effectWrapper_${nameof(fn)}`, () => { // eslint-disable-line react-hooks/exhaustive-deps
  const taskState = {cancelled: false};
  fn(taskState).catch(console.error);
  return () => { taskState.cancelled = true; cleanup(); };
}), deps); // eslint-disable-line react-hooks/exhaustive-deps

export const usePromiseOnMount = <F extends CancellableFn>(fn: F, cleanup?: CleanupFn) => usePromise(fn, onMountDeps, cleanup);
export const useEffectWhileMounted = <F extends CancellableFn>(sideEffect: F, cleanup: CleanupFn = _.noop) => {
  const [isEffectActualized, setIsEffectReversed] = useState(false);
  usePromiseOnMount(
    unAnon(`useEffectWhileMounted_mounting_${  nameof(sideEffect, '')}`, async () => { await sideEffect(); setIsEffectReversed(true); }),
    unAnon(`useEffectWhileMounted_unMounting_${nameof(sideEffect, '')}`, () => { cleanup(); setIsEffectReversed(false); }),
  );

  return isEffectActualized;
};

/** Clears the Apollo store on mount; returns whether the store is clear. */
export const useClearStoreOnMount = () => {
  const client = useApolloClient();
  return useEffectWhileMounted(unAnon('apolloClient.clearStore', () => {
    // can cause : NetworkError : Store reset while query was in flight
    // https://github.com/apollographql/apollo-client/issues/2919
    // log.debug(`clearing the store: ${new Error('moo').stack}`);
    return client.clearStore();
  }));
};

/** Due to difficulties with the Apollo cache, we're just clearing the whole damn thing when displaying a list so that when you drill down into something in the list it won't accidentally display some cached info. 😬 */
export const clearStore = <P extends {}>(WrappedComponent: ComponentType<P>) =>
  unAnon('ClearStoreHoc', (p: P) => !useClearStoreOnMount() ? null : <WrappedComponent {...p} />);
