import * as Helpers from '../../helpers';
import * as React from 'react';
import * as Reselect from 'reselect';
import * as Types from '../../types';
import * as classnames from 'classnames';
import { shallowEqual } from 'shared/object-difference';

export interface CellData {
  columns: Types.ColumnDef[];
  values: any[][];
  customClickHandler?(rowIndex: number): void;
  primaryClickHandler(rowIndex?: number): void;
  selectedRowIndex: number | undefined;
  tableName?: string;
  headers: string[];
  hoveredRowIndex: number | null;
  hoverHandler(rowIndex: number | null): void;
}
interface CellProps {
  style: any; // Will come from react-window
  data: CellData;
  rowIndex: number; // Will come from react-window Grid
  columnIndex: number; // Will come from react-window Grid
}

function getRows(props: CellProps) {
  return props.data.values;
}

function getRowIndex(props: CellProps) {
  return props.rowIndex;
}

function getHoveredRowIndex(props: CellProps) {
  return props.data.hoveredRowIndex;
}

function getHoverHandler(props: CellProps) {
  return props.data.hoverHandler;
}

function getColumnIndex(props: CellProps) {
  return props.columnIndex;
}

function getTableName(props: CellProps) {
  return props.data.tableName;
}

function getColumns(props: CellProps) {
  return props.data.columns;
}

function getColumn(props: CellProps) {
  return getColumns(props)[props.columnIndex];
}

function getIsRowStriped(props: CellProps) {
  return props.rowIndex % 2 === 0;
}

function getCurrentRow(props: CellProps) {
  return getRows(props)[props.rowIndex];
}

function getRawValue(props: CellProps) {
  return getCurrentRow(props)[props.columnIndex];
}

function getCellValue(props: CellProps) {
  return Helpers.formatColumn(getColumn(props).contentType, getRawValue(props), false);
}

function getIsRowSelected(props: CellProps) {
  return props.data.selectedRowIndex === props.rowIndex;
}

function getIsLastColumn(props: CellProps) {
  return getCurrentRow(props).length === props.columnIndex + 1;
}

function getIsLastRow(props: CellProps) {
  return getRows(props).length - 1 === props.rowIndex;
}

function getIsRowHovered(props: CellProps) {
  return props.rowIndex === getHoveredRowIndex(props);
}

function getClassName(props: CellProps) {
  const contentType = getColumn(props).contentType;
  return classnames('readonly-table-body-cell',
    {
      'readonly-table-body-cell-selected': getIsRowSelected(props),
      'readonly-table-body-cell-striped': getIsRowStriped(props),
      'readonly-table-body-cell-not-last-column': !getIsLastColumn(props),
      'readonly-table-body-cell-amount': contentType === Types.ColumnContentType.Amount,
      'readonly-table-body-cell-percent': contentType === Types.ColumnContentType.Percent,
      'readonly-table-body-cell-money': contentType === Types.ColumnContentType.Money,
      'readonly-table-body-cell-checkmark': contentType === Types.ColumnContentType.Checkmark,
      'readonly-table-body-cell-hovered': getIsRowHovered(props),
      'readonly-table-body-cell-last-row': getIsLastRow(props),
    },
  );
}

function getHeaders(props: CellProps) {
  return props.data.headers;
}

const getHeader = Reselect.createSelector([
  getColumnIndex,
  getHeaders,
], (columnIndex, headers) => headers[columnIndex]);

const getPrimaryClickHandler = (props: CellProps) => props.data.primaryClickHandler;
const getCustomClickHandler = (props: CellProps) => props.data.customClickHandler;

export const Cell = React.memo((props: CellProps) => {
  const rowIndex = props.rowIndex;

  const onClick = () => {
    const primaryClickHandler = getPrimaryClickHandler(props);
    const customClickHandler = getCustomClickHandler(props);

    primaryClickHandler(rowIndex);
    if (customClickHandler) {
      customClickHandler(rowIndex);
    }
  };

  const onMouseEnter = () => {
    getHoverHandler(props)(getRowIndex(props));
  };

  const onMouseLeave = () => {
    getHoverHandler(props)(null);
  };

  return (
    <div
      style={props.style}
      className={getClassName(props)}
      onClick={onClick}
      data-cell-type="body"
      data-row-index={rowIndex}
      data-column-index={getColumnIndex(props)}
      data-column-header={getHeader(props)}
      data-table-name={getTableName(props)}
      data-value={getRawValue(props)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="readonly-table-body-cell-inner-div">
        {getCellValue(props)}
      </div>
    </div>
  );
}, (prevProps: CellProps, nextProps: CellProps) => {
    const prevCellProps = extractCellProps(prevProps);
    const nextCellProps = extractCellProps(nextProps);

    const prevStyle = prevProps.style;
    const nextStyle = nextProps.style;

    return shallowEqual(prevStyle, nextStyle) && shallowEqual(prevCellProps, nextCellProps);
});

function extractCellProps(props: CellProps) {
  return {
    rowIndex: props.rowIndex,
    columnIndex: props.columnIndex,
    contentType: getColumn(props).contentType,
    value: getRawValue(props),
    isHovered: props.rowIndex === props.data.hoveredRowIndex,
    isSelected: props.rowIndex === props.data.selectedRowIndex,
  };
}
