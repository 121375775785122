import gql from 'graphql-tag';
import { CartTrackingSupplierEntryQuantity, CartTrackingSupplierEntryType, CartTrackingSupplierEntryDate, CartTrackingSupplierEntryNotes } from 'shared/schemas/cart-tracking-supplier-entry';

export interface CartTrackingSupplierEntry {
  id: number;
  quantity: CartTrackingSupplierEntryQuantity;
  entryType: CartTrackingSupplierEntryType;
  entryDate: CartTrackingSupplierEntryDate ;
  notes?: CartTrackingSupplierEntryNotes;
}

export interface CartTrackingSupplierEntriesResponse {
  response?: {
    supplier: shame;
  };
}

export const SupplierCartTrackingStatusMutation = gql`
  mutation updateSupplierCartTrackingStatus($supplierId: Int!, $status: String!) {
    response: updateSupplierCartTrackingStatus(supplierId: $supplierId, status: $status) {
      ... on Supplier {
        id
        cartTrackingStatus
        cartTrackingSupplierEntries {
          id
        }
      }
    }
  }
`;
