import * as React from 'react';
import { Col } from 'client/components/third-party';
import { CartTrackingSupplierTable } from 'client/app/transportation/cart-tracking/details/overview/cart-tracking-supplier-table-container';
import AddNewTransactionModal from 'client/app/transportation/cart-tracking/details/overview/add-new-transaction-modal-container';
import { useRouterParamsId } from 'client/hooks/use-router-params-id';
import CartTrackingDetailsRecordBar from 'client/app/transportation/cart-tracking/details/cart-tracking-details-record-bar/cart-tracking-details-record-bar-container';

export const CartTrackingSupplierOverview: React.SFC<{}> = props => {
  const supplierId = useRouterParamsId();

  if (!supplierId) {
    return <div />;
  }

  return (
    <>
      <CartTrackingDetailsRecordBar supplierId={supplierId} />
      <div className="mfc-form">
        <Col sm={12} className="cart-tracking-sub-tab-page">
          <CartTrackingSupplierTable
            supplierId={supplierId} />
          <AddNewTransactionModal supplierId={supplierId} />
        </Col>
      </div>
    </>
  );
};
