import * as React from 'react';
import RoutePlanDetails from '../route-plan-details';
import PrintingList from 'client/app/transportation/routing/route-plan-details/printing/printing-list-container';
import { RoutePlanPrintingWarning } from '../route-plan-printing-warning';
import { RoutePlanSectionNames } from '../../route-plan-section-names';
import { ContainerParentInfo } from 'client/components/table/container-parent';

export interface UIProps {
  routePlanId?: number;
  containerParentInfo: ContainerParentInfo;
}

export class RoutePlanPrintingUI extends React.PureComponent<UIProps, {}> {
  render() {
    if (this.props.routePlanId === undefined) {
      return (<div/>);
    }

    return (
      <RoutePlanDetails routePlanId={this.props.routePlanId} sectionName={RoutePlanSectionNames.Printing}>
        <div className="route-plan-printing-and-warning-container" style={{height: this.props.containerParentInfo.containerHeight}}>
          <RoutePlanPrintingWarning routePlanId={this.props.routePlanId} />
          <PrintingList routePlanId={this.props.routePlanId}/>
        </div>
      </RoutePlanDetails>
    );
  }
}
