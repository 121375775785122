import * as _ from 'lodash';
import * as React from 'react';

const Title = (p: {children?}) => <div className="card-pf-title">{p.children}</div>;
const Body = (p: {children?}) => <div className="card-pf-body">{p.children}</div>;
const Card = (p: { title, children?, condition? }) => p.condition === false ? null : (
  <div className="card-pf card-pf-accented card-pf-aggregate-status-notifications" data-testid={`stats-card-${_.kebabCase(p.title)}`}>
    <Title>{p.title}</Title>
    <Body>{p.children}</Body>
  </div>
);

export function StatsCard(props: { title: string, children?: any }) {
  return (
    <Card title={props.title}> {props.children} </ Card>
  );
}
