import {
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_SESSION_LOADED,
  LOGIN_SESSION_NOT_FOUND,
  LOGOUT_USER,
} from '../constants';
import { Action } from 'client/types/redux-types';

export enum SessionStatus {
  Unknown = 'UNKNOWN',
  Loading = 'LOADING',
  SignedIn = 'SIGNED_IN',
  NoSession = 'NO_SESSION',
}

export interface SessionState {
  sessionStatus: SessionStatus;
  user?: { token: string, firstName: string, lastName: string };
  hasError: boolean;
  isLoading: boolean;
}

const INITIAL_STATE: SessionState = {
  sessionStatus: SessionStatus.Unknown,
  hasError: false,
  isLoading: false,
  user: undefined,
};

function sessionReducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }) {
  switch (action.type) {

    case LOGIN_USER_PENDING:
      return Object.assign({}, state, {
        sessionStatus: SessionStatus.Loading,
        user: undefined,
        hasError: false,
        isLoading: true,
      });

    case LOGIN_USER_ERROR:
      return Object.assign({}, state, {
        sessionStatus: SessionStatus.NoSession,
        hasError: true,
        isLoading: false,
        user: undefined,
      });

    case LOGOUT_USER:
      return INITIAL_STATE;

    case LOGIN_USER_SUCCESS:
    case LOGIN_SESSION_LOADED:
      return Object.assign({}, state, {
        sessionStatus: SessionStatus.SignedIn,
        user: action.payload,
        hasError: false,
        isLoading: false,
      });

    case LOGIN_SESSION_NOT_FOUND:
      return Object.assign({}, state, {
        sessionStatus: SessionStatus.NoSession,
        user: undefined,
        hasError: false,
        isLoading: false,
      });

    default:
      return state;
  }
}

export default sessionReducer;
