import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { VendorChargebackAddLineItemsModal, Props } from './add-vendor-chargeback-line-item-modal-ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Query from './queries';
import * as Actions from './actions';
import { msyncQuery } from 'client/hoc/graphql/query';

const FORM_NAME: string = 'vendor-chargeback-add-new-line-item';

export interface OwnProps {
  invoiceId: number;
}

export interface StateProps {
  formName: string;
}

interface ReduxFormProps {
  handleSubmit: () => void;
  reset: () => void;
}

export const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    formName: FORM_NAME,
  };
};

type DispatchProps = {
  addVendorChargebackLineItemsFromSupplierItems: (supplierItemIds: number[], invoiceId: number) => any,
  onClose: () => void;
};

export const mapDispatchToProps: DispatchProps = {
  addVendorChargebackLineItemsFromSupplierItems: Actions.addVendorChargebackLineItemsFromSupplierItems,
  onClose: Actions.onAddVendorChargebackLineItemsModalCloseClicked,
};

interface WithInvoiceInitialValues {
  customerId?: number;
  supplierId?: number;
}

const withInvoiceInfo = msyncQuery<Query.QueryResponse, OwnProps, WithInvoiceInitialValues, Query.Input>(Query.Query, {
  alias: 'withCustomerAndSupplierForInvoice',
  skip(ownProps) {
    return ownProps.invoiceId === undefined;
  },
  options(ownProps) {
    if (ownProps.invoiceId === undefined) {
      throw new Error('Must have an invoice ID');
    }
    return {
      variables: {
        invoiceId: ownProps.invoiceId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithInvoiceInitialValues {
    const { data } = props;
    if (data.loading || data.getInvoice === undefined) {
      return { };
    }

    const invoice = data.getInvoice.invoice;

    return {
      customerId: invoice.customer.id,
      supplierId: invoice.supplier.id,
    };
  },
});

assertCompatible<Props, StateProps & DispatchProps & OwnProps & Query.WithSupplierItemsProps & ReduxFormProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withInvoiceInfo,
  Query.withSupplierItems({ filterExistingSupplierItems: true }),
  ReduxForm.reduxForm({ form: FORM_NAME, enableReinitialize: true }),
)(VendorChargebackAddLineItemsModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
