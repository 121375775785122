import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { FormValues, formName, HmgOpsReportType } from './constants';
import { getHmgOpsReportType } from './selectors';
import { Thunker, Action } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { generateHmgOpsPoDetailReport, generateHmgOpsPoSummaryReport, generateHmgOpsPicasExport } from './mutation';
import { reportDownloadStarted, reportDownloadFinished } from 'client/app/orders/reports/shared/actions';
import { HmgOpsInput } from 'schema/transportation-report/types';
import { GenerateReportMutationResponse } from 'shared/types/graphql-types';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { orThrow } from 'shared/helpers';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export type ActionTypes = HmgOpsReportTypeChangedAction;
export enum ActionTypeKeys { HmgOpsReportTypeSelectionChanged = 'App/HmgOps/ProductTabSelectionChanged' }

type Validation<T extends {}> = {[K in keyof T]: (V: T[K]) => true | never};
const pickAndValidate = <T extends {}, V extends Validation<T> = Validation<T>>(input: any, validation: V) =>
!!input && 'object' === typeof input
? _.mapValues(validation, (v: V[keyof V], k: keyof T) => v(input[k]) && input[k]) as unknown as T
: orThrow(`input must be an object`);

export const handleDownloadExcelReportClicked = (): Thunker => async (dispatch, getState) => {
  const state = getState();
  const formValues = getFormValues(formName)(state) as FormValues;
  const hmgOpsInput = {
    beginDate: formValues.beginDate,
    endDate: formValues.endDate,
    customerIds: extractSelectedValues(formValues.customerIds),
    supplierId: formValues.supplierId,
    supplierOrderIds: extractSelectedValues(formValues.supplierOrderIds),
  };

  const reportInput = pickAndValidate<HmgOpsInput>(hmgOpsInput, {
    beginDate: x => notNil(x) || orThrow(`required: Begin Date`),
    endDate: x => notNil(x) || orThrow(`required: End Date`),
    customerIds: x => notNil(x) && x.length > 0 || orThrow(`required: Customer Ids`),
    supplierId: x => notNil(x) || orThrow(`required: Supplier Id`),
    supplierOrderIds: x => notNil(x) && x.length > 0 || orThrow(`required: Supplier Order Ids`),
  });

  try {
    dispatch(reportDownloadStarted());
    const response = await msyncClientMutation<GenerateReportMutationResponse, {reportInput: HmgOpsInput}>({
      mutation: {
        [HmgOpsReportType.Detail]: generateHmgOpsPoDetailReport,
        [HmgOpsReportType.Summary]: generateHmgOpsPoSummaryReport,
        [HmgOpsReportType.Picas]: generateHmgOpsPicasExport,
      }[getHmgOpsReportType(state)],
      variables: { reportInput },
      dispatch,
    });

    if (!response) {
      throw new Error('Unable to generate report');
    }

    window.location.replace(`/report/fileDownload/${response.data.response.id}`);
  } finally {
    dispatch(reportDownloadFinished());
  }
};

export interface HmgOpsReportTypeChangedAction extends Action {
  type: ActionTypeKeys.HmgOpsReportTypeSelectionChanged;
  payload: HmgOpsReportType;
}

export const handleReportTypeChanged = (key: HmgOpsReportType): HmgOpsReportTypeChangedAction => {
  return {
    type: ActionTypeKeys.HmgOpsReportTypeSelectionChanged,
    payload: key,
  };
};
