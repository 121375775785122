import gqlTag from 'graphql-tag';
import { buildFragment, tableDisplayColumns } from 'shared/schemas';
import { CUSTOMER_ORDER_OVERVIEW_TABLE_NAME } from 'client/constants';

const table = CUSTOMER_ORDER_OVERVIEW_TABLE_NAME;

export const updateAllocationsOnLineItemMutation = gqlTag`
  mutation UpdateAllocationsOnLineItem($id: Int!, $quantity: Int, $rackQuantity: Int) {
    updateAllocationsOnLineItem(id: $id, quantity: $quantity, rackQuantity: $rackQuantity) {
      updatedAllocations {
        id
        ...updateAllocationsOnLineItemFragment
      }
      receivableOrder {
        id
        receivingStatus # Need this both here and below in customerOrder since they're cached in Apollo under the two different types, used in different places
        details {
          id
          receivableOrderType
          packQuantity
          shippingUnitQuantity
          packSize
          packsPerShelf
          shelvesPerRack
          packsPerShippingUnit
        }
      }
      customerOrder {
        id
        received
        receivingStatus # Needed to display a notification to the user if it changes
        lastModifiedAt
      }
    }
  }
  ${buildFragment(table, tableDisplayColumns(table), 'updateAllocationsOnLineItemFragment') }
`;

export interface CreateInvoiceFromCustomerOrderInput {
  customerOrderId: number;
}

export interface CreateInvoiceFromCustomerOrderResponse {
  response: {
    invoice: {
      id: number;
    };
  };
}

export const createInvoiceFromCustomerOrderMutation = gqlTag`
  mutation CreateInvoiceFromCustomerOrder($customerOrderId: Int!) {
    response: CreateInvoiceFromCustomerOrder(customerOrderId: $customerOrderId) {
      invoice {
        id
      }
    }
  }
`;

export interface GenerateCustomerOrderDetailsInput {
  customerOrderIds: number[];
}

export interface GenerateCustomerOrderDetailsResponse {
  response: {
    report: {
      id: number;
    };
  };
}

export const generateCustomerOrderDetailsMutation = gqlTag`
  mutation GenerateCustomerOrderDetails($customerOrderIds: [Int!]!) {
    response: GenerateCustomerOrderDetails(customerOrderIds: $customerOrderIds) {
      report {
        id
      }
    }
  }
`;

export interface GenerateCustomerOrderSpreadsheetInput {
  customerOrderId: number;
}

export interface GenerateCustomerOrderSpreadsheetResponse {
  response: {
    report: {
      id: number;
    };
  };
}

export const generateCustomerOrderSpreadsheetMutation = gqlTag`
  mutation GenerateCustomerOrderSpreadsheet($customerOrderId: Int!) {
    response: GenerateCustomerOrderSpreadsheet(customerOrderId: $customerOrderId) {
      report {
        id
      }
    }
  }
`;
