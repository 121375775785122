import { wrapComponent } from 'client/hoc/hoc';
import { LoadDetailsSidebarUI } from './ui';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { SalesPlanFormValues } from './types';
import * as Constants from './constants';
import * as Actions from './actions';
import * as Query from './query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { Options, optionsContainerGenerator } from 'client/hoc/options-container-generator';
import { DateTimeStr } from 'shared/types';

interface OwnProps {
  salesPlanId?: number;
}

interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

interface WithSalesPlanInfo {
  salesPlanInfoLoading?: boolean;
  initialValues?: SalesPlanFormValues;
  lastModifiedAt: DateTimeStr | undefined;
  lastModifiedBy: string | undefined;
}

const withSalesPlanInfo = msyncQuery<Query.QueryResponse, OwnProps, WithSalesPlanInfo, Query.Input>(Query.Query, {
  alias: 'withSalesPlanInfo',
  skip(ownProps) {
    return ownProps.salesPlanId === undefined;
  },
  options(ownProps) {
    if (ownProps.salesPlanId === undefined) {
      throw new Error('Must have a sales plan ID');
    }
    return {
      variables: {
        salesPlanId: ownProps.salesPlanId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSalesPlanInfo {
    const { data } = props;
    if (data.loading || data.salesPlan === undefined) {
      return {
        salesPlanInfoLoading: true,
        lastModifiedAt: undefined,
        lastModifiedBy: undefined,
      };
    }
    const salesPlan = data.salesPlan;

    const lastModifiedBy = salesPlan.lastModifiedUser
      ? `${salesPlan.lastModifiedUser.firstName} ${salesPlan.lastModifiedUser.lastName}`
      : undefined;

    return {
      salesPlanInfoLoading: data.loading,
      initialValues: {
        ...salesPlan,
        year: `${salesPlan.year}`,
      },
      lastModifiedAt: salesPlan.lastModifiedAt,
      lastModifiedBy,
    };
  },
});

type ReduxFormProps = InjectedFormProps<SalesPlanFormValues>;

const WithReduxForm = reduxForm({
  form: Constants.formName,
  enableReinitialize: true,
  async onSubmit(formValues: SalesPlanFormValues, dispatch, props: OwnProps & WithSaveConfirmationProps & WithSalesPlanInfo): Promise<void> {
    dispatch(Actions.formSubmitted(formValues, props));
  },
});

const mapDispatchToProps = {
  onDownloadMenuItemClicked: Actions.downloadMenuItemClicked,
  onDownloadCustomerSpecSheetMenuItemClicked: Actions.downloadCustomerSpecSheetMenuItemClicked,
  onDownloadDifferenceReportCalculationSpreadsheetMenuItemClicked: Actions.downloadDifferenceReportCalculationSpreadsheetMenuItemClicked,
};

interface CustomerOptionsProps {
  customerOptions: Options | undefined;
}

interface SellDepartmentOptionsProps {
  sellDepartmentOptions: Options | undefined;
}

const withCustomerOptions = optionsContainerGenerator({ resultPropName: 'customerOptions', table: 'customers', columns: ['identifier', 'name'] });
const withSellDepartmentOptions = optionsContainerGenerator({ resultPropName: 'sellDepartmentOptions', table: 'sellDepartments', columns: ['identifier'] });

const connector = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  WithSalesPlanInfo &
  CustomerOptionsProps &
  SellDepartmentOptionsProps &
  ReduxFormProps &
  ConnectedProps<typeof connector>;

export const SidebarContainer = wrapComponent(LoadDetailsSidebarUI)<OwnProps, CombinedProps>(
  withSalesPlanInfo,
  withCustomerOptions,
  withSellDepartmentOptions,
  connector,
  WithReduxForm,
);
