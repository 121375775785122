import * as React from 'react';
import * as _ from 'lodash';
import gqlTag from 'graphql-tag';
import { singularize } from 'inflection';
import { buildFragment, formQueryColumns, recordType as getRecordType, tableName, TableName } from '../../../shared/schemas';
import { RecordBar } from '../../components/record-bar';
import { RecordNav } from '../../components/record-nav';
import { formContainer } from '../../hoc/form-container';
import { withSaveConfirmation, SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';
import { withNotification, NotificationArgs } from 'client/hoc/with-notification';
import { RecordBarActionMenuItem } from 'client/components/record-bar/record-bar-action-menu';
import { withRouter, RouteChildrenProps } from 'react-router';

export const buildAdminDetailPage = <Table extends TableName> (opt: {
  table: Table,
  RecordBarDetailComponent?: any,
  OptionalStatusToggle?: any,
  title?: string,
  FormComponent: any,
  mutations?: any,
  query?: any,
  /**
   * @see src/components/supplier-item/new-cost-modal.tsx
   * @example
   * ```typescript
   * mutationStrings: {
   *   create: gqlTag`
   *     mutation ($input: CreateSupplierItemCostInput!) {
   *       data: createSupplierItemCost(input: $input) {
   *         id,
   *         ...SupplierItemCostFragment
   *       }
   *     }
   *     ${gqlTag`${buildFragment(table, formQueryColumns(table), 'SupplierItemCostFragment')}`}
   *   `,
   * },
   * onSubmit: ({ create }, _, { supplierItemId }) => rec => create(Object.assign({}, rec, { supplierItemId })),
   * ````
   */
  onSubmit?: (fn: any, unknown: any, record: any) => (data: any) => Promise<any>,
  resetApolloStoreAfterSave?: boolean,
  formName?: string,
  navBuilder?: any,
  saveConfirmationOptions?: SaveConfirmationArgs<any>,
  notificationOptions?: NotificationArgs<shame, shame>,
  descriptionResolver?: (...args: any[]) => string,
  cleanData?: (data: any) => any,
  destroyOnUnmount?: boolean,
  recordBarActionMenuItems?: RecordBarActionMenuItem[],
  formClassName?: string,
}) => {
  const recordType = getRecordType(opt.table);
  const adminDetailFormName = opt.formName || `${recordType}Form`;
  const AdminDetailPage = (p: React.PropsWithChildren<RouteChildrenProps & {
    handleSubmit: () => Promise<void | boolean>;
    confirmOkToSave: () => Promise<boolean>;
    notification: () => Promise<boolean>;
    onChangeRecordStatus: (name: string, field: string, value: any) => void;
    resetForm: any;
    submitting: boolean;
    invalid: boolean;
    pristine: boolean;
    submitted: boolean;
    initialValues: any;
    record: any;
    loading: boolean;
    onClose: any;
  }>) => p.loading ? <div/> : (
    <div className="record" id={`${_.kebabCase(singularize(opt.table))}-detail-page`}>
      <RecordBar
        submitting={p.submitting}
        submitted={p.submitted}
        invalid={p.invalid}
        pristine={p.pristine}
        onClose={p.onClose}
        handleSubmit={p.handleSubmit}
        confirmOkToSave={p.confirmOkToSave}
        record={p.record}
        onChangeRecordStatus={(field: string, val: any) => p.onChangeRecordStatus(adminDetailFormName, field, val)}
        resetForm={() => p.resetForm(adminDetailFormName)}
        initialRecord={p.initialValues}
        table={opt.table}
        title={opt.title}
        description={(Object.keys(p.initialValues ?? {}).length ? opt.descriptionResolver?.(p.initialValues) : undefined) ?? p.initialValues?.identifier ?? null}
        RecordBarDetailComponent={opt.RecordBarDetailComponent}
        OptionalStatusToggle={opt.OptionalStatusToggle}
        recordBarActionMenuItems={opt.recordBarActionMenuItems}
      />
      <div className={opt.formClassName || 'mfc-form'}>
        {opt.navBuilder && <RecordNav RecordNavItems={(p.record?.id ?? p.initialValues?.id) && opt.navBuilder((p.record?.id ?? p.initialValues?.id), p.record) || []} disabled={false} />}
        <opt.FormComponent
          handleSubmit={p.handleSubmit}
          confirmOkToSave={p.confirmOkToSave}
          submitting={p.submitting}
          invalid={p.invalid}
          pristine={p.pristine}
          formName={adminDetailFormName}
          record={p.initialValues}
          params={p.match ? p.match.params : {}}
        />
      </div>
    </div>
  );

  const fragmentName = `${recordType}DetailFragment`;
  const fragment = gqlTag`${buildFragment(opt.table, formQueryColumns(opt.table), fragmentName)}`;
  let intermediate = AdminDetailPage as shame;
  intermediate = withRouter(intermediate);
  intermediate = withNotification(opt.notificationOptions)(intermediate);
  intermediate = withSaveConfirmation(opt.saveConfirmationOptions)(intermediate);
  intermediate = formContainer({
    formName: adminDetailFormName,
    mutationStrings: opt.mutations ?? {
      save: gqlTag`
        mutation ($input: Edit${recordType}Input!) {
          data: edit${recordType}(input: $input) {
            id,
            ...${fragmentName}
          }
        }
        ${fragment}
      `,
    },
    isNewRecord: undefined,
    onSubmit: opt.onSubmit ?? (({save}: {save: (rec: any) => any}) => (rec: any) => save(rec)),
    queryString: opt.query ?? gqlTag`
      query find${recordType}($type: RecordType!, $id: Int!) {
        data: find(type: $type, id: $id) {
          id,
          ...${fragmentName}
        }
      }
      ${fragment}
    `,
    resetApolloStoreAfterSave: opt.resetApolloStoreAfterSave,
    refetchQueries: undefined,
    table: tableName(opt.table),
    initialValues: undefined,
    cleanData: opt.cleanData,
    destroyOnUnmount: opt.destroyOnUnmount,
  })(intermediate);
  return intermediate as typeof AdminDetailPage;
};
