import * as React from 'react';
import * as classNames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isActiveRoute } from 'client/helpers/route-helpers';

interface OwnProps {
  children: React.ReactNode;
  to: string;
  title: string;
  isVisible?: boolean;
  testid?: string;
}

function PrimaryNavLink({
  children,
  to,
  title,
  isVisible = true,
  testid,
}: OwnProps) {
  const location = useLocation();
  const isActive = isActiveRoute(to, location);

  const navItemClasses = classNames('primary-nav-item', 'mfc-navigation-bar-primary-tab', {
    active: isActive,
    inactive: !isActive,
    hide: !isVisible,
  });

  let childrenClasses = '';
  if (!!children && React.Children.toArray(children).length > 0) {
    childrenClasses = classNames('nav', 'navbar-nav', 'navbar-persistent');
  }

  if (!children || React.Children.toArray(children).length === 0) {
    return (
      <li data-testid={testid} className={navItemClasses}>
        <Link to={ to } >{ title }</Link>
      </li>
    );
  }

  return (
    <li data-testid={ testid } className={ navItemClasses }>
      <Link to={ to }>{ title }</Link>
      <ul className={ childrenClasses }>
        { children }
      </ul>
    </li>
  );
}

export default PrimaryNavLink;
