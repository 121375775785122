import * as _ from 'lodash';
import { notNil } from './andys-little-helpers';

/**
 * Extract the most popular value of an expression applied to a collection of elements.
 * @example
 * const input = [
 *   {name: 'alice', age: 35},
 *   {name: 'bob', age: 7},
 *   {name: 'charlie', age: 35},
 *   {name: 'dotty', age: 7},
 *   {name: 'erma', age: 35},
 * ];
 * expect(modeBy(input, x => x.age)).to.equal(35);
 */
export const modeBy = <X, M extends null | undefined | number | string = string>(xs: X[], by: (x: X) => M = (x => x as unknown as M)) => {
  const [mode]
    = // at the end of the ensuing lodash chain, a 2-tuple is yielded and the first element is a stringified key of the extracted values to mode over
    _(xs || [])           // coalesce to zero here is runtime defensiveness; in theory, the type system forbids it statically... but, shame.
    .map(by)              // extract the values eligible to win the popularity contest
    .filter(notNil)       // the by function can yield nils, but input elements that yield a nil value don't participate in the mode
    .map(JSON.stringify)  // countBy converts everything to a string using naïve methods; this gives a deep representation
    .orderBy(_.identity)  // if more than one value has the same frequencey as the mode, the lexicographically first string value wins
    .countBy()            // converts each distinct value to a string key and creates an object assigning to each key the count of its occurrences
    .toPairs()            // converts the object to a sequence of tuples with the string key in the first element and the count in the second
    .maxBy(([, count]) => count || 0) // takes the tuple from the sequence of pairs with the largest secnod element (count)
    ?? [undefined];         // ... e.g., a tuple of the mode and its count; if xs is empty, this whole chain yields undefined,
                            // so here I colaesce with a single tuple of undefined

  return _.isNil(mode) ? undefined : JSON.parse(mode) as M;
};
