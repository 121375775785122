import { MarkRoutePlanAsReadyToRouteMutation, markRoutePlanAsReadyToRouteMutationRefetchQueries } from 'client/app/transportation/routing/sidebar/route-plan-mutation';
import { MarkRoutePlanAsReadyToRouteResponse } from 'schema/route-plan/route-plan-typescript-types';
import { Dispatch } from 'redux';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { Thunker } from 'client/types/redux-types';
import { Action } from 'redux';

export type ActionTypes =
  MarkingRoutePlansStartedAction |
  MarkingRoutePlansFinishedAction
  ;

export enum ActionTypeKeys {
  MarkingRoutePlansStarted = 'App/MarkingRoutePlansStarted',
  MarkingRoutePlansFinished = 'App/MarkingRoutePlansFinished',
}

const markRoutePlanAsReadyToRoute = (dispatch: Dispatch<any>, routePlanId: number) => {
  return msyncClientMutation<MarkRoutePlanAsReadyToRouteResponse>({
    mutation: MarkRoutePlanAsReadyToRouteMutation,
    variables: {
      routePlanId,
    },
    dispatch,
    refetchQueries: markRoutePlanAsReadyToRouteMutationRefetchQueries,
  });
};

interface MarkingRoutePlansStartedAction extends Action {
  type: ActionTypeKeys.MarkingRoutePlansStarted;
}
function markingRoutePlansStarted(): MarkingRoutePlansStartedAction {
  return {
    type: ActionTypeKeys.MarkingRoutePlansStarted,
  };
}

interface MarkingRoutePlansFinishedAction extends Action {
  type: ActionTypeKeys.MarkingRoutePlansFinished;
}
function markingRoutePlansFinished(): MarkingRoutePlansFinishedAction {
  return {
    type: ActionTypeKeys.MarkingRoutePlansFinished,
  };
}

export const startOverButtonClicked = (routePlanId: number): Thunker => {
  return async dispatch => {
    dispatch(markingRoutePlansStarted());
    await markRoutePlanAsReadyToRoute(dispatch, routePlanId);
    dispatch(markingRoutePlansFinished());
  };
};
