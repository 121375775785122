import { PrimaryGlobal, PrimaryGlobalAll } from 'shared/types';

export { PrimaryGlobalAll } from 'shared/types';

export const convertPrimaryGlobalAllToJustPrimaryGlobal = (primaryGlobalAll: PrimaryGlobalAll): PrimaryGlobal | null => {
  if (!primaryGlobalAll) {
    throw new Error('Could not generate email - the preset had no value set for primary/global');
  }
  if (primaryGlobalAll === PrimaryGlobalAll.Global) {
    return PrimaryGlobal.Global;
  } else if (primaryGlobalAll === PrimaryGlobalAll.Primary) {
    return PrimaryGlobal.Primary;
  } else {
    return null;
  }
};
