import * as BS from 'client/components/third-party';
import * as Column from 'client/components/table/column';
import * as DownloadExcel from 'client/utils/download-excel';
import * as ErrorActions from 'client/actions/error';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as React from 'react';
import * as SharedTypes from 'shared/types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { QueryAsExcelColumn } from 'shared/types';

interface Props {
  dataRequest: GraphQLQuery.MsyncDataRequest;
  columns: Column.IColumn[];
  disabled?: boolean;
}

interface State {
  inProgress: boolean;
}

export interface DispatchProps {
  handleExpectedError: (args: { message: string, debugInfo: any }) => void;
  downloadExcel: (args: {
    dataRequest: GraphQLQuery.MsyncDataRequest,
    columns: QueryAsExcelColumn[],
    setInProgress: () => void;
    setNotInProgress: () => void;
    handleExpectedError: (args: { message: string, debugInfo: any }) => void;
  }) => any;
}

const mapDispatchToProps: shame = {
  handleExpectedError: ErrorActions.expectedErrorReceived,
  downloadExcel: DownloadExcel.downloadExcel,
};

class DownloadAsExcelButtonUI extends React.Component<Props & DispatchProps, State> {
  constructor(props: Props & DispatchProps) {
    super(props);

    this.state = {
      inProgress: false,
    };
  }

  setInProgress = () => {
    this.setState({ inProgress: true });
  }

  setNotInProgress = () => {
    this.setState({ inProgress: false });
  }

  render() {
    return <BS.Button
      key="download-table-button"
      bsClass="btn btn-default mfc-button-table download-table-button"
      data-testid="download-table-button"
      disabled={this.state.inProgress || this.props.disabled}
      onClick={async () => {
        this.props.downloadExcel({
          dataRequest: this.props.dataRequest,
          columns: this.props.columns.map(x => SharedTypes.castOrThrow(SharedTypes.isQueryAsExcelColumn, x)),
          setInProgress: this.setInProgress,
          setNotInProgress: this.setNotInProgress,
          handleExpectedError: this.props.handleExpectedError,
        });
      }}>
      {this.state.inProgress &&
        <i className="fa fa-spinner fa-spin"></i>
      }
      {!this.state.inProgress &&
        <i className="fa fa-download"></i>
      }
    </BS.Button>;
  }
}

export const DownloadAsExcelButton = _.flowRight(
  connect<{}, DispatchProps, Props>(undefined, mapDispatchToProps),
)(DownloadAsExcelButtonUI);
