import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import Page from './reconciliation-page';
import { propToComponent } from 'client/hoc/hoc';

interface OwnProps {
  params: {
    id?: string;
  };
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
}
const mapDispatchToProps = (dispatch, ownProps: OwnProps) => {
  return {
  };
};

export type ComponentProps =
  OwnProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<any, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(Page) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
