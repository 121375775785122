import gql from 'graphql-tag';

export const ImportAttachmentMutation = gql`
  mutation ImportAttachmentMutaion($input: ImportAttachmentInput!) {
    importAttachment: ImportAttachment(input: $input) {
      success
      id
      errors
    }
  }
`;
