import * as React from 'react';
import * as _ from 'lodash';
import * as TableActions from 'client/actions/table';
import * as TableState from 'client/state/table';
import { useDispatch, useSelector } from 'react-redux';
import { TableUI } from 'client/components/table/table-ui';
import { IColumn } from 'client/components/table/column';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { Arg1, NavigateTableDirection, rename, Return, SORT_TYPES } from 'shared/types';
import { useOnce, useValue, useObject } from 'client/lib/react';
import { TableParentInfo } from 'client/components/table/table-parent';
import { buildTableStateModule } from 'client/state/tables';
import * as GlobalState from 'client/state/state';
const onClickOutside: any = require('react-onclickoutside');

export interface PendingRowActionState { pendingRowAction: boolean }
type MenuItemFunction = (ids: number | number[], record?: shame) => Promise<number[]>;
export const pendingRowActionHandler = <P, S extends PendingRowActionState>(component: React.Component<P, S>, fn: MenuItemFunction): MenuItemFunction =>
  async (ids: number[] | number, record?: shame): Promise<number[]> => {
    component.setState({ pendingRowAction: true });
    try {
      return await fn(ids, record);
    } finally {
      component.setState({ pendingRowAction: false });
    }
};

export type OwnProps = Arg1<Return<typeof buildDataTable>>;

/** the `uiTable` parameter is used as the key in the `tables` object off the root state object.  The actual tableState for this data table is stored in there. */
export const buildDataTable = (uiTable: string, initialState?: Partial<TableState.Type>) => rename((p: {
  // required; mostly supplied by HOC
  columns: IColumn[];
  content: any[] | undefined;
  totalCount: number;
  filteredRecordIds: number[];
  setTablePageNumber: (pageNumber: number, row?: number) => void;
  tablePageNumber: number;
  tableParentInfo: TableParentInfo;
  tablePaginated: boolean;

  // optional
  checkable?: boolean;
  list?: boolean;
  onSort?: (columnName: string, multiColumn?: boolean) => void;
  sorting?: Array<{ sortField: string; sortOrder: SORT_TYPES }>;
  onRowFocused?: any;
  onRowSelect?: (row: Dictionary<any>) => void;
  fieldValidators?: any;
  loading?: boolean;
  loadMoreRecords?: () => void;
  headerMenuItems?: RowMenuItem[];
  rowMenuItems?: RowMenuItem[];
  toolbarVisible?: boolean;
  noDataText?: string;
  confirmOkToSave?: () => Promise<boolean>;
  handleSubmit?: () => Promise<boolean>;
  refetchStats?: () => Promise<void>;
  tableClassName?: string;
  footerData?: { [k: string ]: any };
  footerProps?: { [k: string]: any };
}) => {
  const TableStateHelpers = useOnce(() => buildTableStateModule(uiTable, initialState));
  const Table = useOnce(() => onClickOutside(TableUI));
  const stable = useObject({columns: p.columns ?? [], rows: p.content ?? [], dispatch: useDispatch()});
  return <Table {...({
    ...useSelector((s: GlobalState.Type) => ({
      checkedRecordIds: TableStateHelpers.checkedRecordIds(TableStateHelpers.tableStateLens.get(s))       ,
      editing         : TableStateHelpers.editing         (TableStateHelpers.tableStateLens.get(s))       ,
      selectedColumn  : TableStateHelpers.selectedCell    (TableStateHelpers.tableStateLens.get(s)).column,
      selectedRow     : TableStateHelpers.selectedCell    (TableStateHelpers.tableStateLens.get(s)).row   ,
    }), _.isEqual),
    ...useValue({
      click                   : (row: number, column: number                              ) => stable.dispatch(TableActions.clickCell               (uiTable, row, column                                                                            )),
      clickOutside            : (                                                         ) => stable.dispatch(TableActions.clickOutside            (uiTable                                                                                         )),
      edit                    : (isEditing: boolean, shouldSave: boolean = true           ) => stable.dispatch(TableActions.edit                    (uiTable, isEditing, shouldSave                                                                  )),
      move                    : (direction: any, distance: any, blurOnSaveLastRow: boolean) => stable.dispatch(TableActions.moveDirectionByDistance (uiTable, direction, stable.rows.length, stable.columns.length, distance, blurOnSaveLastRow  )),
      toggleCheckAllRecords   : (checkedRecordIds: any[], filteredRecordIds: any[]        ) => stable.dispatch(TableActions.toggleCheckAllRecords   (uiTable, checkedRecordIds, filteredRecordIds                                                    )),
      toggleCheckSingleRecord : (recordId: any                                            ) => stable.dispatch(TableActions.toggleCheckSingleRecord (uiTable, recordId                                                                               )),
      uncheckMultipleRecords  : (recordIds: any[]                                         ) => stable.dispatch(TableActions.uncheckMultipleRecords  (uiTable, recordIds                                                                              )),
      moveEditCell            : (direction: any, distance: any, blurOnSaveLastRow: boolean) => stable.dispatch(TableActions.moveEditCell            (uiTable, direction, stable.rows.length, stable.columns.length, distance, blurOnSaveLastRow  )),
      editNextEditableCell    : (direction: NavigateTableDirection, columns: IColumn[]    ) => stable.dispatch(TableActions.editNextEditableCell    (uiTable, direction, stable.rows.length, stable.columns.length, columns, stable.rows)),
      onInvalidCellDetected   : (isInvalid: boolean                                       ) => stable.dispatch(TableActions.invalidCellDetected     (uiTable, isInvalid                                                                              )),
    }),
    ...p,
  })} />;
}, 'BasicTable');
