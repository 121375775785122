import gql from 'graphql-tag';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { BolRacksReturned, BolNotes, BolIdentifier, BolId } from 'shared/schemas/bol';

export interface EditBolVariables {
  input: {
    id: BolId;
    receivableOrderId: number;
    identifier: BolIdentifier;
    racksReturned?: BolRacksReturned;
    notes?: BolNotes;
  };
}

export const EditBolMutation = gql`
  mutation editBolAndRacksReturned($input: EditBolInput!) {
    response: editBolAndRacksReturned(input: $input) {
      bolId
      receivableOrder {
        id
        bols {
          id
          identifier
          notes
        }
      }
    }
  }
`;

export interface EditBolResponse {
  response: {
    boldId: number;
    receivableOrder: {
      id: SupplierOrderId;
      bols: Array<{
        id: BolId;
        identifier: BolIdentifier;
        notes: string;
      }>;
    };
  };
}
