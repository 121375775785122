import * as State from 'client/state/state';
import * as ProductWorksheetState from 'client/state/product-worksheet';
import { List } from 'immutable';

export const isAutoReplenishmentModalShown = (state: State.Type) =>
  ProductWorksheetState.autoReplenishmentModalShownLens.get(state.productWorksheet);

export const storeIdsFromMenuAction = (state: State.Type) =>
  ProductWorksheetState.storeIdsFromMenuActionLens.get(state.productWorksheet);

export const autoReplenishmentMutationStatus = (state: State.Type) =>
  ProductWorksheetState.autoReplenishmentMutationStatusLens.get(state.productWorksheet);

// TODO: Is it acceptable to not uses lenses for everything?

export const isAllocationDetailsRefetching = (state: State.Type) =>
  state.productWorksheet.isAllocationDetailsRefetching;

export const isAllocationRequestInFlight = (state: State.Type) =>
  !state.productWorksheet.inFlightAllocationChanges.isEmpty() || isAllocationDetailsRefetching(state);

export const findQueuedAllocationsToSave = (amount: number) => {
  // Grab the N oldest changes that have been queued up
  return (state: State.Type) =>
    state.productWorksheet.queuedAllocationChanges
      .valueSeq()
      .sortBy(obj => obj ? obj.nonce : 0)
      .take(amount)
      .toArray();
};

export const numberOfQueuedAllocationChanges = (state: State.Type) =>
  state.productWorksheet.queuedAllocationChanges.size;

/**
 * Find all of the storeIds that have been modified up to the specified nonce
 */
export const findStoreIdsUpToNonce = (nonce: number) => {
  return (state: State.Type) => {
    const set = new Set<number>();

    // TODO: There's probably a nice way to do this with reduce. This works for now though.
    state.productWorksheet.allocatedProductChangesByCustomerOrderProductGroup.valueSeq().forEach((changes: List<ProductWorksheetState.AllocatedProductChange>) => {
      changes.forEach(ch => {
        if (ch && ch.nonce <= nonce) {
          set.add(ch.storeId);
        }
      });
    });

    return Array.from(set);
  };
};

export const isAllocationsBeingProcessed = (state: State.Type) =>
  state.productWorksheet.queuedAllocationChanges.isEmpty() && state.productWorksheet.inFlightAllocationChanges.isEmpty();

export const findAllocatedProductChangesForCustomerOrderProductGroup = (copgId: number) => {
  return (state: State.Type): List<ProductWorksheetState.AllocatedProductChange> => {
    return state.productWorksheet.allocatedProductChangesByCustomerOrderProductGroup.get(copgId) || List();
  };
};
