import * as React from 'react';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues, change, FieldArray } from 'redux-form';

import { ProductBulkType, formatNumber, RackShippingUnit, PalletShippingUnit, Return } from 'shared/types';
import { KnownSupplierId } from 'shared/types/mfc-types';
import * as FormHelpers from 'client/helpers/form-helpers';
import { HorizontalLayout } from 'client/components/form/layouts';
import * as State from 'client/containers/product/state';
import { DownloadSpecSheetModal } from 'client/containers/product/download-spec-sheet-modal';
import * as Actions from 'client/containers/product/actions';
import { Form, FormGroup, Col, Row } from 'client/components/third-party';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';

import { optionsContainerGenerator, optionsContainerWithFilters } from '../../../hoc/options-container-generator';
import { SpecializeField, LabeledDisplayMoney, LabeledDisplay, LabeledDisplayPercentage } from '../../form';
// import { FieldWrapper } from '../../form';
import { UpcCodeFormGroup as UpcCodes } from './upc-code-form-group';
import { Saved } from 'shared/schemas/record';
import { Product } from 'shared/schemas/product';
import { ErrorBoundary } from 'client/containers/error-boundary';

const Field = SpecializeField(Product, { alwaysShowErrors: true, hideOptionalLabel: true });
// const Field = p => <FieldWrapper table="products" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

const numberOrZero = value => !Number.isNaN(Number.parseFloat(value)) ? Number.parseFloat(value) : 0;
const formatCubicFeet = value => formatNumber(value);
const cubicInchesInCubicFoot = 12.0 * 12.0 * 12.0;
const getFormattedCubicFeet = (height, width, length) =>
  height > 0 && length > 0 && width > 0
  ? (height * width * length) * 1.0 / cubicInchesInCubicFoot
  : 0;

const mapStateToProps = (state: {product: {downloadSpecSheetModalShown: boolean}}, p: { formName: string }) => {
  const values = getFormValues(p.formName)(state) as shame<'i really dont like redux'>;
  return {
    isScanProduct: !!values?.scanProduct,
    selectedCustomerId: values?.customerId || null,
    selectedSellDepartmentId: values?.sellDepartmentId || null,
    selectedProductClassId: values?.productClassId || null,
    externalDistributionProduct: !!values?.externalDistribution,
    bulkType: values?.bulkType || null,
    height: numberOrZero(values?.height) || 0,
    width: numberOrZero(values?.width) || 0,
    length: numberOrZero(values?.length) || 0,
    downloadSpecSheetModalShown: State.downloadSpecSheetModalShown.get(state.product),
  };
};

const mapDispatchToProps = (dispatch, p: {
  formName: string,
  externalDistributionProduct: boolean,
  unassignedProductSubClassId: number,
  width: number,
  length: number,
  height: number,
}) => ({
  onDistributionIdentifierChange: event => {
    const newValue = (event.target.value || '').toUpperCase();
    dispatch(change(p.formName, 'identifier', newValue));
    if (p.externalDistributionProduct)
      dispatch(change(p.formName, 'replenishmentIdentifier', newValue));
  },
  onScanProductChange: value => {
    dispatch(change(p.formName, 'comparableProduct', null));
    dispatch(change(p.formName, 'scanProduct', value));
  },
  onCustomerChange: value => {
    dispatch(change(p.formName, 'comparableProduct', null));
    dispatch(change(p.formName, 'customerId', value));
  },
  onSellDepartmentChange: value => {
    dispatch(change(p.formName, 'comparableProduct', null));
    dispatch(change(p.formName, 'productSubClassId', null));
    dispatch(change(p.formName, 'productClassId', null));
    dispatch(change(p.formName, 'sellDepartmentId', value));
  },
  onProductClassChange: value => {
    dispatch(change(p.formName, 'productSubClassId', null));
    dispatch(change(p.formName, 'productClassId', value));
  },
  onExternalDistributionProductChanged: value => {
    dispatch(change(p.formName, 'externalDistribution', value));
    dispatch(change(p.formName, 'productSubClassId', value ? p.unassignedProductSubClassId : null));
  },
  onBulkTypeChanged: value => dispatch(change(p.formName, 'bulkType', value ? ProductBulkType.ITEM : ProductBulkType.NONE)),
  onHeightChanged: event => {
    const newHeight = numberOrZero(event.target.value);
    dispatch(change(p.formName, 'height', newHeight));
    dispatch(change(p.formName, 'cubicFeet', getFormattedCubicFeet(newHeight, p.width, p.length)));
  },
  onLengthChanged: event => {
    const newLength = numberOrZero(event.target.value);
    dispatch(change(p.formName, 'length', newLength));
    dispatch(change(p.formName, 'cubicFeet', getFormattedCubicFeet(p.height, p.width, newLength)));
  },
  onWidthChanged: event => {
    const newWidth = numberOrZero(event.target.value);
    dispatch(change(p.formName, 'width', newWidth));
    dispatch(change(p.formName, 'cubicFeet', getFormattedCubicFeet(p.height, newWidth, p.length)));
  },
  handleCloseButtonClicked: () => dispatch({ type: Actions.ActionTypeKeys.HideDownloadSpecSheetModal }),
  handleCancelButtonClicked: () => dispatch({ type: Actions.ActionTypeKeys.HideDownloadSpecSheetModal }),
});

const ProductInfoFormGroupCore = (p: Return<typeof mapStateToProps> & Return<typeof mapDispatchToProps> & {
  record?: Saved<Product>,
  onCustomerChange: () => void,
  customers: Array<{ value: number, label: string, id: number, identifier: string }>,
  customersLoading?: boolean,
  boxes: Array<{ value: number, label: string, id: number, identifier: string }>,
  boxesLoading?: boolean,
  buckets: Array<{ value: number, label: string, id: number, identifier: string }>,
  bucketsLoading?: boolean,
  sellDepartments: Array<{ value: number, label: string, id: number, identifier: string }>,
  sellDepartmentsLoading?: boolean,
  onSellDepartmentChange: () => void,
  productClasses: Array<{ value: number, label: string, id: number, identifier: string }>,
  productClassesLoading?: boolean,
  onProductClassChange: () => void,
  productSubClasses: Array<{ value: number, label: string, id: number, identifier: string }>,
  productSubClassesLoading?: boolean,
  selectedSellDepartmentId: number,
  supplierLocations: Array<{ id: number, identifier: string }>,
  supplierLocationsLoading?: boolean,
  comparableProducts: Array<{ value: number, label: string, id: number, identifier: string }>,
  comparableProductsLoading?: boolean,
  scanProduct?: boolean,
  pristine: boolean,
  invalid: boolean,
  submitting: boolean,
  formName: string,
  handleSubmit: () => void,
  externalDistributionProduct: boolean,
  onScanProductChanged: () => void,
  onExternalDistributionProductChanged: () => void,
  onDistributionIdentifierChange: () => void,
  onBulkTypeChanged: () => void,
  onHeightChanged: () => void,
  onWidthChanged: () => void,
  onLengthChanged: () => void,
  bulkType: string,
  unassignedProductSubClassId: number,
  height: number,
  width: number,
  length: number,
}) => {
  const sellDepartmentsForCutFloralDetails = ['INDOOR FLORAL'];
  const selectedSellDepartmentIdentifier = p.selectedSellDepartmentId && p.sellDepartments?.find(s => s.id === p.selectedSellDepartmentId)?.identifier || undefined;
  const cutFloralDetailsVisible = sellDepartmentsForCutFloralDetails.indexOf(selectedSellDepartmentIdentifier!) !== -1;

  return (
    <ErrorBoundary>
    <Col sm={12}>
      {p.downloadSpecSheetModalShown && p.record &&
        <DownloadSpecSheetModal
          handleCancelButtonClicked={p.handleCancelButtonClicked}
          handleCloseButtonClicked={p.handleCloseButtonClicked}
          productId={p.record.id}
        />
      }
      <Form horizontal onSubmit={p.handleSubmit} data-testid="product-info">
        <div data-testid="product">
          <Row>
            <div className="mfc-form-heading">
              Product Details
            </div>
          </Row>
          <Row>
            <FormGroup>
              <HorizontalLayout labelColSize={2} inputColSize={3}>
                <SimpleCheckbox
                    id="external-distribution-checkbox"
                    testid="external-distribution-checkbox"
                    value={p.externalDistributionProduct}
                    onChange={p.onExternalDistributionProductChanged}
                    label="External Distribution Product"
                  />
              </HorizontalLayout>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup data-testid="info-row-1">
              <Field
                name="identifier"
                labelColSize={2}
                label={p.externalDistributionProduct ? 'Case Pack ID' : 'Product ID'}
                inputColSize={3}

                normalize={FormHelpers.UpperCaseString}
                handleChange={p.onDistributionIdentifierChange}
                autoFocus={!p.record || !p.record.id}
              />
              {p.externalDistributionProduct
                ? <HorizontalLayout labelColSize={0} inputColSize={3}>
                    <SimpleCheckbox
                      id="bulk-item-checkbox"
                      testid="bulk-item-checkbox"
                      value={p.externalDistributionProduct ? p.bulkType === ProductBulkType.ITEM : !!p.scanProduct}
                      onChange={p.externalDistributionProduct ? p.onBulkTypeChanged : p.onScanProductChange}
                      label={p.externalDistributionProduct ? 'Bulk Item' : 'Scan Product'}
                    />
                  </HorizontalLayout>
                : <Field name="scanProduct" value={p.scanProduct} labelColSize={0} inputColSize={7} handleChange={p.onScanProductChange} />
              }
            </FormGroup>
          </Row>
          <Row>
            <FormGroup data-testid="info-row-2">
              <Field name="description" labelColSize={2} inputColSize={8} normalize={FormHelpers.UpperCaseString} />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup data-testid="info-row-3">
              <Field name="customerId" labelColSize={2} inputColSize={8} options={p.customers} loading={p.customersLoading} />
            </FormGroup>
          </Row>
          {!p.externalDistributionProduct &&
            <div>
              <Row>
                <FormGroup data-testid="info-row-5">
                  <Field name="sellDepartment" labelColSize={2} inputColSize={3} options={p.sellDepartments} loading={p.sellDepartmentsLoading} handleChange={p.onSellDepartmentChange}/>
                  <Field
                    name="replenishmentIdentifier"
                    labelColSize={2}
                    inputColSize={3}
                    normalize={FormHelpers.UpperCaseString}
                  />
                </FormGroup>
                <FormGroup data-testid="info-row-6">
                  <Field name="productClass" labelColSize={2} inputColSize={3} options={p.productClasses} loading={p.productClassesLoading} handleChange={p.onProductClassChange}/>
                  <Field name="replenishmentStatus" labelColSize={2} inputColSize={3}/>
                </FormGroup>
                <FormGroup data-testid="info-row-7">
                  <Field name="productSubClassId" labelColSize={2} inputColSize={3} options={p.productSubClasses} loading={p.productSubClassesLoading} />
                  <Field name="duplicateSkuUpc" labelColSize={2} inputColSize={3} placeholder="Select Status" />
                </FormGroup>
                <FormGroup data-testid="info-row-8">
                  <Field name="supplierLocations" labelColSize={2} inputColSize={3} options={p.supplierLocations} loading={p.supplierLocationsLoading} placeholder="Select HMG Location" multi />
                  <Field name="sku" labelColSize={2} inputColSize={3} />
                </FormGroup>
                <FormGroup>
                  <Field name="comparableProductId" labelColSize={2} inputColSize={3} options={p.comparableProducts} loading={p.comparableProductsLoading} />
                  <Field name="picasContainerCode" labelColSize={2} inputColSize={3}/>
                </FormGroup>
                <FormGroup>
                  <Field name="generalLedgerAccountIdentifier" label="GL Code" labelColSize={2} inputColSize={3}/>
                </FormGroup>
                <FormGroup>
                  <Field name="reportTossQuantities" labelColSize={2} inputColSize={3}/>
              </FormGroup>
              {p.record?.id &&
                <React.Fragment>
                  <FormGroup>x
                    <LabeledDisplayMoney labelColSize={2} inputColSize={1} label="Price" value={p.record.activePrice} testid="price" />
                    <LabeledDisplayMoney labelColSize={1} inputColSize={1} label="Retail" value={p.record.activeRetail} testid="retail" />
                    <LabeledDisplayMoney labelColSize={1} inputColSize={1} label="UPC Retail" value={p.record.activeUpcRetail} testid="upcRetail" />
                    <LabeledDisplayMoney labelColSize={1} inputColSize={1} label="Scanback" value={p.record.activeScanback} testid="scanback" />
                    <LabeledDisplayPercentage labelColSize={1} inputColSize={1} label="Margin" value={p.record.activeRetailMargin} testid="margin" />
                  </FormGroup>
                  <FormGroup>
                    <LabeledDisplay labelColSize={2} inputColSize={3} label="Weights &amp; Measures" value={p.record.currentWeightAndMeasure} testid="weightsAndMeasures" />
                  </FormGroup>
                </React.Fragment>
              }
              </Row>
            </div>
          }
          <Row>
            <div className="mfc-form-heading">
              UPCs
            </div>
            <FieldArray name="upcCodes" component={UpcCodes} formName={p.formName} />
          </Row>
          <Row>
            <div className="mfc-form-heading">
              Packaging Details
            </div>
            {!p.externalDistributionProduct &&
              <FormGroup>
                <Field name="sellPack" labelColSize={2} inputColSize={3} />
                <Field name="boxId" labelColSize={2} inputColSize={3} options={p.boxes} loading={p.boxesLoading} placeholder="Select Box" />
              </FormGroup>
            }
            <FormGroup>
              <Field name="packSize" labelColSize={2} inputColSize={p.externalDistributionProduct ? 2 : 3} />
              {p.externalDistributionProduct
                ? <Field name="height" labelColSize={2} inputColSize={2} handleChange={p.onHeightChanged} hideOptionalLabel />
                : <Field name="bucketId" labelColSize={2} inputColSize={3} options={p.buckets} loading={p.bucketsLoading} placeholder="Select Bucket" />
              }
            </FormGroup>
            {p.externalDistributionProduct &&
            <>
            <FormGroup>
              <Field name="packsPerRack" labelColSize={2} inputColSize={2} hideOptionalLabel />
              <Field name="width" labelColSize={2} inputColSize={2} handleChange={p.onWidthChanged} hideOptionalLabel />
            </FormGroup>
            <FormGroup>
              <Field name="casesPerPallet" labelColSize={2} inputColSize={2} hideOptionalLabel />
              <Field name="length" labelColSize={2} inputColSize={2} handleChange={p.onLengthChanged} hideOptionalLabel />
            </FormGroup>
            <FormGroup>
              <Field name="cubicFeet" offset={4} labelColSize={2} inputColSize={2} inputStyle textFormatter={formatCubicFeet} />
            </FormGroup></> }
          </Row>
          {!p.externalDistributionProduct &&
            <div>
              <Row>
                <Col sm={6} className="shipping-option-column">
                  <div className="mfc-form-heading">
                    Ship by Rack
                  </div>
                  <FormGroup>
                    <Field mfcClassName="mfc-primary-shipping-method" name="primaryShippingUnitType" labelColSize={0} inputColSize={8} optionValue={RackShippingUnit}/>
                  </FormGroup>
                  <FormGroup>
                    <Field name="packsPerShelf" labelColSize={4} inputColSize={4} />
                  </FormGroup>
                  <FormGroup>
                    <Field name="shelvesPerRack" labelColSize={4} inputColSize={4} />
                  </FormGroup>
                  <FormGroup>
                    <Field name="packsPerRack" labelColSize={4} inputColSize={4} />
                  </FormGroup>
                </Col>
                <Col sm={6} className="shipping-option-column">
                  <div className="mfc-form-heading">
                    Ship by Pallet
                  </div>
                  <FormGroup>
                    <Field mfcClassName="mfc-primary-shipping-method" name="primaryShippingUnitType" labelColSize={0} inputColSize={8} optionValue={PalletShippingUnit}/>
                  </FormGroup>
                  <FormGroup>
                    <Field name="casesPerPallet" labelColSize={4} inputColSize={3} />
                  </FormGroup>
                </Col>
              </Row>
              {cutFloralDetailsVisible &&
              <Row>
                <div className="mfc-form-heading">
                  Cut Floral Details
                </div>
                <FormGroup>
                  <Field name="sellDays" labelColSize={2} inputColSize={2} />
                  <Field name="shipType" labelColSize={3} inputColSize={3} placeholder="Select Ship Type" />
                </FormGroup>
                <FormGroup>
                  <Field name="stemCount" labelColSize={2} inputColSize={2} />
                  <Field name="requiresProcessing" labelColSize={3} inputColSize={3}/>
                </FormGroup>
                <FormGroup>
                  <Field name="stemLength" labelColSize={2} inputColSize={2} />
                </FormGroup>
              </Row>
              }
            </div>
          }
        </div>
      </Form>
    </Col>
    </ErrorBoundary>
  );
};

const withSupplierLocations = optionsContainerGenerator({
  table: 'supplierLocations',
  columns: ['identifier'],
  resultPropName: 'supplierLocations',
  getAdditionalFilters: ownProps => [{field: 'supplierId', values: [KnownSupplierId.HMG]}],
});

const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
const withBuckets = optionsContainerGenerator({ table: 'buckets', columns: ['identifier', 'description'] });
const withBoxes = optionsContainerGenerator({ table: 'boxes', columns: ['identifier', 'description'] });
const withSellDepartments = optionsContainerGenerator({ table: 'sellDepartments', columns: ['identifier'] });
const withProductClasses = optionsContainerGenerator({ table: 'productClasses', columns: ['identifier'], parent: 'sellDepartment' });
const withProductSubClasses = optionsContainerGenerator({ table: 'productSubClasses', columns: ['identifier'], parent: 'productClass' });
const withUnassignedProductSubClass = optionsContainerWithFilters({ table: 'productSubClasses', columns: ['identifier'],
  getFilters: () => [ { field: 'identifier', values: ['UNASSIGNED'] } ],
  props: result => (result?.data?.content?.length ?? 0) > 0 ? { unassignedProductSubClassId: result.data.content[0].id } : {},
});

const withProducts = optionsContainerWithFilters({ table: 'products', columns: ['identifier', 'description'],
  getFilters: props => [
    { field: 'customer', values: [props.selectedCustomerId] },
    { field: 'sellDepartment', values: [props.selectedSellDepartmentId] },
    { field: 'scanProduct', values: [props.isScanProduct ? 'true' : 'false'] },
  ],
  props: result => (result?.data?.content?.length ?? 0) > 0 ? { comparableProducts: result.data.content, comparableProductsLoading: result.data.loading } : {},
});

export const ProductInfoFormComponent = flowRight(
  withSupplierLocations,
  withSellDepartments,
  withUnassignedProductSubClass,
  connect(mapStateToProps),
  connect(undefined, mapDispatchToProps),
  withProductClasses,
  withProductSubClasses,
  withCustomers,
  withBoxes,
  withBuckets,
  withProducts,
)(ProductInfoFormGroupCore);
