import { tableName, property, belongsTo, hasOne, hasMany, manyToMany, definePresentation, gqlResolver, setSchemaOptions, required } from './dsl';
import { displayType } from './dsl';
import { Customer, CustomerId } from './customer';
import { Bucket } from './bucket';
import { Box } from './box';
import { SellDepartment } from './sell-department';
import { ProductClass } from './product-class';
import { ProductSubClass } from './product-sub-class';
import { ProductSpec } from './product-spec';
import { ProductUpc } from './product-upc';
import { SupplierLocation } from './supplier-location';
import { IRecord, Saved } from './record';
import { DISPLAY_TYPES, INPUT_TYPES, ActiveInactive, FloralShipTypes, TYPES, ShippingUnitType, RackShippingUnit, ProductBulkType } from '../types';
import { ProductDisplayValueResolver, CustomerDisplayValueResolver, ProductClassDisplayValueResolver, ProductSubClassDisplayValueResolver, BucketDisplayValueResolver, BoxDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { SupplierItem } from './supplier-item';
import { ProductPrice } from './product-price';

export type ProductId = Flavor<number, 'productId'>;
export type ProductIdentifier = Flavor<string, 'productIdentifier'>;
export type ProductDescription = string;

export type ProductHeight = Flavor<number, 'productHeight'>;
export type ProductWidth = Flavor<number, 'productWidth'>;
export type ProductLength = Flavor<number, 'productLength'>;
export type ProductCubicFeet = Flavor<number, 'productCubicFeet'>;

@tableName('products')
export class Product implements IRecord {
  static readonly SchemaName = 'Product';

  id?: ProductId;
  @property @required identifier: ProductIdentifier;
  @property @required description: ProductDescription;

  @belongsTo('customers', {foreignDisplayKey: 'name', foreignQueryKeys: ['name']})
  @property @required customer: Partial<Customer>;
  customerId: CustomerId;

  @belongsTo('sellDepartments', {through: 'productClasses'})
  @property @required sellDepartment: Saved<SellDepartment>;

  @belongsTo('productClasses', {through: 'productSubClasses'})
  @property @required productClass: Partial<ProductClass>;

  @belongsTo('productSubClasses')
  @property @required productSubClass: Partial<ProductSubClass>;
  productSubClassId: number;

  @hasMany('productSpecs', {foreignDisplayKey: 'specYear'})
  @property productSpecs: ProductSpec[];

  @hasMany('supplierItems')
  @property supplierItems: SupplierItem[];

  @property @required packSize: number;
  @property @required sellPack: number;
  @property externalDistribution: boolean; // Is this a product that MFC provides, or does it come from some external vendor
  @property scanOrPo?: string | null;
  @property @required activeStatus: ActiveInactive;
  @property scanProduct: boolean;
  @property reportTossQuantities: boolean;
  @property @required replenishmentIdentifier: string;
  @property replenishmentStatus?: ActiveInactive | null;
  @property duplicateSkuUpc?: ActiveInactive | null;
  @property sku?: string | null;
  @property picasContainerCode?: string | null;

  @hasMany('productUpcs')
  @property upcCodes: Array<Partial<ProductUpc>>;

  @hasMany('productPrices')
  @property productPrices: Array<Partial<ProductPrice>>;

  @property packsPerRack?: Int | null;
  @property packsPerShelf?: number | null;
  @property shelvesPerRack?: number | null;
  @property casesPerPallet?: number | null;
  @property sellDays?: number | null;
  @property stemCount?: string | null;
  @property stemLength?: number | null;
  @property shipType?: FloralShipTypes | null;
  @property requiresProcessing: boolean | null;

  @property @required primaryShippingUnitType: ShippingUnitType;

  @gqlResolver((prod: Product, _: any, context: any) => {
    return context
      .findRepository('ProductPrice', context)
      .findActivePriceAcrossAllMarketsByProductId(prod.id)
      .then(price => {
        if (price) {
          return Promise.resolve(price.mfcPrice);
        } else {
          return Promise.resolve(0);
        }
      });
  })
  @property activePrice: number;

  @gqlResolver((prod: Product, _: any, context: any) => {
    return context
      .findRepository('ProductPrice', context)
      .findActivePriceAcrossAllMarketsByProductId(prod.id)
      .then(price => {
        if (price) {
          return Promise.resolve(price.retailPrice);
        } else {
          return Promise.resolve(0);
        }
      });
  })
  @property activeRetail: number;

  @gqlResolver((prod: Product, _: any, context: any) => {
    return context
      .findRepository('ProductPrice', context)
      .findActivePriceAcrossAllMarketsByProductId(prod.id)
      .then(price => {
        if (price) {
          return Promise.resolve(price.scanbackPrice);
        } else {
          return Promise.resolve(0);
        }
      });
  })
  @property activeScanback: number;

  @gqlResolver((prod: Product, _: any, context: any) => {
    return context
      .findRepository('ProductPrice', context)
      .findActivePriceAcrossAllMarketsByProductId(prod.id)
      .then(price => {
        if (price) {
          return Promise.resolve(price.upcRetailPrice);
        } else {
          return Promise.resolve(0);
        }
      });
  })
  @property activeUpcRetail: number;

  @gqlResolver((prod: Product, _: any, context: any) => {
    return context
      .findRepository('ProductPrice', context)
      .findActivePriceAcrossAllMarketsByProductId(prod.id)
      .then(price => {
        if (price) {
          return Promise.resolve(ProductPrice.calculateMargin(price));
        } else {
          return Promise.resolve(0);
        }
      });
  })
  @property activeRetailMargin: number;

  @hasOne('productUpcs', { andWhere: { primary: true } })
  @gqlResolver(({ id }: any, _: any, context: any) => context.findRepository('ProductUpc', context).findPrimaryUpcCodeByProductId(id))
  @property primaryUpcCode: Saved<ProductUpc>;

  @manyToMany('supplierLocations', {through: 'productsSupplierLocations', foreignDisplayKey: 'identifier', nativeTableFK: 'supplierLocationId'})
  @property supplierLocations?: SupplierLocation[];

  @property bulkType: ProductBulkType;

  @property height: ProductHeight;
  @property width: ProductWidth;
  @property length: ProductLength;
  @property cubicFeet: ProductCubicFeet;

  @property bucketBoxLiner: string;
  @property hasRollingRackLabel: boolean;
  @property generalLedgerAccountIdentifier: string;

  @property isItsOwnReplenishmentProduct: boolean;

  @belongsTo('buckets', {foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier']})
  @property bucket: Bucket;
  @property bucketId?: number | null;
  @belongsTo('boxes', {foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier']})
  @property box: Box;
  @property boxId?: number | null;
  @property currentWeightAndMeasure?: string | null;

  @property productClassIdentifier: string;
  @property sellDepartmentIdentifier: string;

  @belongsTo('products', { foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier'], alias: 'comparableProduct', nativeTableFK: 'comparableProductId' })
  @property comparableProduct?: Product | null;
  comparableProductId?: ProductId | null;
}

// Validators moved to shared/validations and server/validations

definePresentation(Product, {
  identifier: {
    searchable: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Product ID',
    tableDisplay: true,
    columnWidth: 5,
  },
  customer: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    columnWidth: 20,
  },
  description: {
    searchable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 20,
  },
  sellDepartment: {
    filterable: true,
    sortable: true,
    columnWidth: 10,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  productClass: {
    sortable: true,
    filterable: true,
    displayName: 'Class',
    tableDisplay: true,
    tableDisplayColumns: { identifier: 'Class' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductClassDisplayValueResolver }),
    columnWidth: 10,
  },
  productSubClass: {
    filterable: true,
    sortable: true,
    displayName: 'Sub Class',
    tableDisplay: true,
    tableDisplayColumns: { identifier: 'Sub Class' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductSubClassDisplayValueResolver }),
    columnWidth: 10,
  },
  productSpecs: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
  },
  packSize: {
    sortable: true,
    tableDisplay: true,
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  sellPack: {
    sortable: true,
    tableDisplay: true,
    defaultValue: 1,
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  externalDistribution: {
    displayName: 'External Dist.',
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    type: TYPES.BOOLEAN,
    tableDisplay: true,
    sortable: true,
    filterable: true,
    required: true,
  },
  scanOrPo: {
    displayName: 'Scan / PO',
    tableDisplay: true,
    sortable: true,
    filterable: true,
    type: TYPES.STRING,
    columnWidth: 5,
    defaultFilterWithValues: ['Scan'],
    calculationSpec: {
      dependencies: {},
      calculation: (product: Product) => `(CASE WHEN ${product.scanProduct} = TRUE THEN 'Scan' ELSE 'PO' END)`,
    },
  },
  activeStatus: {
    displayName: 'Status',
    searchable: false,
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  scanProduct: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    defaultValue: false,
  },
  reportTossQuantities: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  replenishmentIdentifier: {
    displayName: 'Replenishment ID',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  replenishmentStatus: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  duplicateSkuUpc: {
    displayName: 'Duplicate SKU/UPC',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  sku: {
    searchable: true,
    displayName: 'Customer SKU',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  picasContainerCode: {
    searchable: true,
    displayName: 'Picas Container Code',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  upcCodes: {
    displayName: 'UPC',
    searchable: true,
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
  },
  packsPerRack: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  packsPerShelf: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  shelvesPerRack: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  casesPerPallet: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  sellDays: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  stemCount: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  stemLength: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  shipType: {
    displayName: 'Ship',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: FloralShipTypes }),
  },
  requiresProcessing: {
    displayName: 'Process',
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    type: TYPES.BOOLEAN,
  },
  primaryShippingUnitType: {
    displayName: 'Primary Shipping Unit',
    formDisplayType: displayType(DISPLAY_TYPES.RADIO, { options: ShippingUnitType }),
    defaultValue: RackShippingUnit,
  },
  activePrice: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  activeRetail: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  activeScanback: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  activeUpcRetail: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  activeRetailMargin: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.PERCENTAGE }),
  },
  primaryUpcCode: {
    formDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  supplierLocations: {
    displayName: 'HMG Locations',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { multiselect: true }),
  },
  length: {
    displayName: 'Length',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER, step: 0.01 }),
    type: TYPES.FLOAT,
  },
  width: {
    displayName: 'Width',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER, step: 0.01 }),
    type: TYPES.FLOAT,
  },
  height: {
    displayName: 'Height',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER, step: 0.01 }),
    type: TYPES.FLOAT,
  },
  cubicFeet: {
    displayName: 'Cubic Feet',
    formDisplayType: displayType(DISPLAY_TYPES.STATIC, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
  },
  bulkType: {
    displayName: 'Bulk Type',
    formDisplayType: displayType(DISPLAY_TYPES.RADIO, { options: { None: ProductBulkType.NONE, Item: ProductBulkType.ITEM } }),
    type: TYPES.STRING,
  },
  isItsOwnReplenishmentProduct: {
    tableDisplay: false,
    formDisplay: false,
    includeInFormQuery: true,
    type: TYPES.BOOLEAN,
    calculationSpec: {
      dependencies: {},
      calculation: (p: Product) => `${p.identifier} = ${p.replenishmentIdentifier}`,
    },
  },
  hasRollingRackLabel: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  bucketBoxLiner: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  generalLedgerAccountIdentifier: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  bucketId: {
    displayName: 'Bucket',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: BucketDisplayValueResolver }),
  },
  boxId: {
    displayName: 'Box',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: BoxDisplayValueResolver }),
  },
  currentWeightAndMeasure: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(
        SELECT pots.weight_and_measure
        FROM pots
        JOIN product_specs ON pots.id = product_specs.pot_id
        WHERE product_specs.product_id = products.id
        ORDER BY product_specs.spec_year DESC
        LIMIT 1
      )`,
    },
  },
  productClassIdentifier: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(
        SELECT product_classes.identifier
        FROM product_sub_classes
        JOIN product_classes ON product_classes.id = product_sub_classes.product_class_id
        WHERE product_sub_classes.id = products.product_sub_class_id
        LIMIT 1
      )`,
    },
  },
  sellDepartmentIdentifier: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(
        SELECT sell_departments.identifier
        FROM product_sub_classes
        JOIN product_classes ON product_classes.id = product_sub_classes.product_class_id
        JOIN sell_departments ON sell_departments.id = product_classes.sell_department_id
        WHERE product_sub_classes.id = products.product_sub_class_id
        LIMIT 1
      )`,
    },
  },
  supplierItems: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
  },
  comparableProduct: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver }),
  },
});

setSchemaOptions(Product, {
  softDeletable: true,
});
