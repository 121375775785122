import {
  SET_SELECTED_SPEC_YEAR,
  GLOBAL_SAVE_STARTED,
  GLOBAL_SAVE_FINISHED,
} from '../constants';
import * as RecordState from 'client/state/record';

import { fromJS } from 'immutable';
import { Action } from 'client/types/redux-types';
import { ActionTypeKeys as ProductActionTypeKeys } from 'client/containers/product/actions';

const initialJS = {
  productSpec: {
    selectedSpecYear: null,
  },
  isRecordSaving: false,
};

const INITIAL_STATE: RecordState.ImmutableRecordState = fromJS(initialJS);

export default function recordReducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }): RecordState.ImmutableRecordState {
  switch (action.type) {
    case SET_SELECTED_SPEC_YEAR: {
      const { year } = action.payload;
      return RecordState.selectedSpecYearLens.set(state, year);
    }

    case ProductActionTypeKeys.ProductDetailSectionUnmounted: {
      return RecordState.selectedSpecYearLens.set(state, undefined);
    }

    case GLOBAL_SAVE_STARTED: {
      return RecordState.recordSavingLens.set(state, true);
    }

    case GLOBAL_SAVE_FINISHED: {
      return RecordState.recordSavingLens.set(state, false);
    }

    default:
      return state;
  }
}
