import { DISPLAY_TYPES, TYPES, INPUT_TYPES, SORT_TYPES } from '../types';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { tableName, property, definePresentation, setSchemaOptions, required, defineFieldValidators } from './dsl';
import * as Validators from 'shared/validators';

@tableName('fuelSurchargeRates')
export class FuelSurchargeRate implements IRecord {
  static readonly SchemaName = 'FuelSurchargeRate';

  id?: number;
  @property effective?: boolean;

  @property @required indexStart: number;
  @property @required indexEnd: number;
  @property @required surcharge: number;
}

defineFieldValidators(FuelSurchargeRate, {
  indexStart: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  indexEnd: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  surcharge: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
});

definePresentation(FuelSurchargeRate, {
  effective: {
    displayName: 'Effective',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 25,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    type: TYPES.BOOLEAN,
  },
  indexStart: {
    displayName: 'Price Index Start',
    sortable: true,
    searchable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 25,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  indexEnd: {
    displayName: 'Price Index End',
    sortable: true,
    searchable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 25,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  surcharge: {
    sortable: true,
    searchable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 25,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
});

setSchemaOptions(FuelSurchargeRate, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'indexStart' },
});
