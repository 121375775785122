import gql from 'graphql-tag';

export const ApplyInvoiceToLoadsMutation = gql`
  mutation applyInvoiceToRoutingLoads($input: EditRoutingLoadsInput!) {
    editRoutingLoads: EditRoutingLoads(input: $input) {
      routingLoad {
        id
        invoiceNumber
        carrier {
          rawValue
          displayValue
        }
      }
    }
  }
`;

export const SetCarrierOnRoutingLoadMutation = gql`
  mutation setCarrierOnRoutingLoads($input: SetCarrierOnRoutingLoadsInput!) {
    response: SetCarrierOnRoutingLoads(input: $input) {
      routingLoad {
        id
        carrier {
          rawValue
          displayValue
        }
        totalFee
      }
    }
  }
`;
