import { Form, Col, Row, FormGroup, Tabs, Tab } from 'client/components/third-party';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as OrderComponents from 'client/app/orders/reports/shared/components';
import * as React from 'react';
import * as RecordBar from 'client/components/record-bar/record-bar-back-back-button-and-title';
import * as ReportUserParamTypes from 'client/components/report-user-params/types';
import * as SelectableTypes from 'client/components/selectable/types';
import * as SharedTypes from 'shared/types';
import { DropDownOptions } from 'client/types';
import { SelectableValue } from 'shared/types';

export interface ComponentProps {
  handleCustomerChanged: (customerId: number) => void;
  handleDownloadExcelReportClicked(): void;
  handleMarketsChanged: (ids: number[]) => void;
  handleMeasureCheckboxClicked: (categoryName: string, measure: string, isOn: boolean) => void;
  handleMfcAreasChanged: (ids: number[]) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (ids: number[]) => void;
  handleProductSubClassesFilterChanged: (ids: number[]) => void;
  handleRegionsChanged: (ids: number[]) => void;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => void;
  customerId: number | undefined;
  customerOptions: DropDownOptions;
  customersLoading: boolean;
  downloadButtonDisabled: boolean;
  lastYearDateComparisonDisabled: boolean;
  mapFromReportUserParams: ReportUserParamTypes.MapFromReportUserParams;
  mapToReportUserParams: ReportUserParamTypes.MapToReportUserParams;
  marketIds: SelectableValue | undefined;
  marketOptions?: SelectableTypes.SelectableRow[];
  marketsLoading?: boolean;
  mfcAreaIds: SelectableValue | undefined;
  mfcAreaOptions?: SelectableTypes.SelectableRow[];
  mfcAreasLoading?: boolean;
  pristine: boolean;
  productClassesLoading?: boolean;
  productClassIds: SelectableValue | undefined;
  productClassOptions?: SelectableTypes.SelectableRow[];
  productIds: SelectableValue | undefined;
  productOptions?: SelectableTypes.SelectableRow[];
  productsLoading?: boolean;
  productSubClassesLoading?: boolean;
  productSubClassIds?: SelectableValue | undefined;
  productSubClassOptions?: SelectableTypes.SelectableRow[];
  regionIds: SelectableValue | undefined;
  regionOptions?: SelectableTypes.SelectableRow[];
  regionsLoading?: boolean;
  reportDownloadStatus: Mutations.MutationStatus;
  sellDepartmentId: number | undefined;
  sellDepartmentOptions?: DropDownOptions;
  sellDepartmentsLoading?: boolean;
  storeIds: SelectableValue | undefined;
  storeOptions?: SelectableTypes.SelectableRow[];
  storesLoading?: boolean;

  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class BuyerReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar.RecordBar title="Buyer Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-hmg-ops-table-wrapper mfc-scrolling-region-adjacent-to-sidebar">
            <Form horizontal>
              <Col sm={6}>
                <Row className="report-fields-outside-tabs">
                  <FormGroup>
                    <OrderComponents.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                      handleChange={this.props.handleCustomerChanged}
                    />
                  </FormGroup>
                  <FormGroup>
                    <OrderComponents.Date
                      name={Constants.FormFields.startDate}
                      label="Start Date"
                      testid="start-date"
                      offset={0}
                    />
                    <OrderComponents.Date
                      name={Constants.FormFields.todaysDate}
                      label="Today"
                      testid="todays-date"
                      offset={0}
                    />
                  </FormGroup>
                </Row>
              </Col>
              <Col sm={12}> </Col>
              <div>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="product-tabs">
                    <Tab disabled={true} title="Products">
                      <FormGroup>
                        <OrderComponents.SellDepartment
                          name={Constants.FormFields.sellDepartmentId}
                          options={this.props.sellDepartmentOptions}
                          handleChange={this.props.handleSellDepartmentChanged}
                        />
                      </FormGroup>
                      <FormGroup className="report-product-order-type-area">
                        <label className="report-scan-or-po-based-label">Product Order Type</label>
                        <div>
                          <OrderComponents.ScanBased
                            name={Constants.FormFields.scanBased}
                            handleChange={this.props.handleScanBasedFilterChanged}
                          />
                          <OrderComponents.PoBased
                            name={Constants.FormFields.poBased}
                            handleChange={this.props.handlePOBasedFilterChanged}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <OrderComponents.ProductClass
                          name={Constants.FormFields.productClassIds}
                          options={this.props.productClassOptions}
                          loading={this.props.productClassesLoading}
                          handleChange={this.props.handleProductClassesFilterChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.ProductSubClass
                          name={Constants.FormFields.productSubClassIds}
                          options={this.props.productSubClassOptions}
                          loading={this.props.productSubClassesLoading}
                          handleChange={this.props.handleProductSubClassesFilterChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.Product
                          name={Constants.FormFields.productIds}
                          options={this.props.productOptions}
                          loading={this.props.productsLoading}
                        />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="store-tabs">
                    <Tab disabled={true} title="Stores">
                      <FormGroup>
                        <OrderComponents.PrimaryGlobalAllField
                          name={Constants.FormFields.primaryGlobalAll}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.MfcArea
                          name={Constants.FormFields.mfcAreaIds}
                          options={this.props.mfcAreaOptions}
                          loading={this.props.mfcAreasLoading}
                          handleChange={this.props.handleMfcAreasChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.Region
                          name={Constants.FormFields.regionIds}
                          options={this.props.regionOptions}
                          loading={this.props.regionsLoading}
                          handleChange={this.props.handleRegionsChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.Market
                          name={Constants.FormFields.marketIds}
                          options={this.props.marketOptions}
                          loading={this.props.marketsLoading}
                          handleChange={this.props.handleMarketsChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <OrderComponents.Store
                          name={Constants.FormFields.storeIds}
                          options={this.props.storeOptions}
                          loading={this.props.storesLoading}
                        />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
              </div>
            </Form>
          </div>
          <OrderComponents.Sidebar
            reportType={SharedTypes.ReportTypes.BuyerReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            // downloadButtonDisabledMessage={`Possible Number of Rows: ${this.props.numberOfExpectedGeneratedRows.toLocaleString()} (Limit ${BuyerReportHelpers.MAX_NUMBER_OF_GENERATED_PERFORMANCE_REPORT_ROWS.toLocaleString()})`}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          >
            <div className="mfc-form-stacked-label report-sidebar-heading">Report Options</div>
          </OrderComponents.Sidebar>
        </div>
      </div>
    );
  }
}
