import * as React from 'react';
import { Modal, Button, Form } from 'client/components/third-party';
import SupplierItemNewCostFormGroup from '../crud-forms/supplier-item/new-cost-form-group';
import { formContainer } from '../../hoc/form-container';
import gqlTag from 'graphql-tag';
import { formQueryColumns, buildFragment, recordType as getRecordType, tableName } from '../../../shared/schemas';
import query from '../../containers/supplier-item/query';

const table = tableName('SupplierItemCost');
const formName = 'SupplierItemNewCostForm';
const recordType = getRecordType('supplierItems');
const SupplierItemNewCostModalCore = (p: {
  isModal: boolean,
  name: string,
  supplierItemId: number,
  show: boolean,
  onClose: () => void,
  refetchTable: () => Promise<void>,

  // formContainer
  handleSubmit: () => Promise<void>,
  reset: () => void,
}) => {
  const handleCancel = () => { p.reset(); p.onClose(); };
  const onSubmitClick = async() => { await p.handleSubmit(); await p.refetchTable(); p.reset(); p.onClose(); };
  return (
    <Modal
      key="new-cost-modal"
      id="new-cost-modal"
      animation
      backdrop="static"
      show={p.show}
      onHide={handleCancel}>
      <Form horizontal onSubmit={p.handleSubmit} data-testid="cost-info">
        <Modal.Header closeButton>
          <Modal.Title>Create New Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupplierItemNewCostFormGroup formName={formName} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsClass="mfc-button mfc-clear-button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button bsStyle="primary" bsClass="mfc-button mfc-submit-button" type="submit" onClick={onSubmitClick} disabled={false} data-testid="modal-submit">
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>);
};

const WrappedForm = formContainer({
  table,
  mutationStrings: {
    create: gqlTag`
      mutation ($input: CreateSupplierItemCostInput!) {
        data: createSupplierItemCost(input: $input) {
          id,
          ...SupplierItemCostFragment
        }
      }
      ${gqlTag`${buildFragment(table, formQueryColumns(table), 'SupplierItemCostFragment')}`}
    `,
  },
  onSubmit: ({ create }, _, { supplierItemId }) => rec => create(Object.assign({}, rec, { supplierItemId })),
  refetchQueries: { create: props => [{ query, variables: { type: recordType, id: props.supplierItemId } }] },
  formName,
  isNewRecord: undefined,
})(SupplierItemNewCostModalCore as shame<'wip'>);

export const SupplierItemNewCostModal = (p: {
  isModal: boolean,
  name: string,
  supplierItemId: number,
  show: boolean,
  onClose: () => void,
  refetchTable: () => Promise<void>,
}) => (
  <WrappedForm {...p} />
);
