import gql from 'graphql-tag';

export interface DeleteSalesPlansInput {
  ids: number[];
}

export interface DeleteSalesPlansResponse {
  response: {
    customerOrders: Array<{
      id: number;
      salesPlan: { id: number } | null;
    }>;
  };
}

export const DeleteSalesPlansMutation = gql`
  mutation DeleteSalesPlansMutation($ids: [Int!]!) {
    response: DeleteSalesPlans(ids: $ids) {
      customerOrders {
        id
        salesPlan {
          id
        }
      }
    }
  }
 `;
