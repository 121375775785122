import { connect } from 'react-redux';
import * as Actions from 'client/actions/load-list';
import { propToComponent } from 'client/hoc/hoc';
import { AssignLoadsToTrailersModalUI, ComponentProps as UIProps, TrailerNumbers } from './assign-loads-to-trailers-modal-ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as LoadListSelectors from 'client/state/load-list-selectors';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { WithAssignLoadsToTrailersRowsQueryProps, AssignLoadsToTrailersRowsResponse, AssignLoadsToTrailersInput, AssignLoadsToTrailersQuery } from 'client/app/transportation/loads/assign-loads-to-trailers-modal/assign-loads-to-trailers-modal-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { APPLY_LOADS_TO_TRAILERS_TABLE } from 'client/constants';
import { FetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  isShown: boolean;
  routingLoadIds: RoutingLoadId[];
  getTrailerNumber(routingLoadId: RoutingLoadId): string | undefined;
  trailerNumbers: TrailerNumbers;
  hasInvalidCell: boolean;
}

const TableStateHelpers = buildTableStateModule(APPLY_LOADS_TO_TRAILERS_TABLE);

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    isShown: LoadListSelectors.isAssignLoadsToTrailersModalShown(state),
    routingLoadIds: LoadListSelectors.selectedRoutingLoadIds(state),
    getTrailerNumber: LoadListSelectors.getTrailerNumber(state),
    trailerNumbers: LoadListSelectors.trailerNumbers(state),
    hasInvalidCell: TableStateHelpers.hasInvalidCell(TableStateHelpers.tableStateLens.get(state)) ?? false,
  };
};

interface DispatchProps {
  handleAssignLoadsToTrailersAssignButtonClicked(trailerNumbers: TrailerNumbers): void;
  handleCancelButtonClicked(): void;
  setTrailerNumber(routingLoadId: RoutingLoadId, trailerNumber: string): void;
  selectFirstTrailerNumber(): void;
}

const mapDispatchToProps: DispatchProps = {
  handleAssignLoadsToTrailersAssignButtonClicked: Actions.handleAssignLoadsToTrailersApplyClicked,
  handleCancelButtonClicked: Actions.hideAssignLoadsToTrailersModal,
  setTrailerNumber: Actions.setTrailerNumberOnLoads,
  selectFirstTrailerNumber: Actions.selectFirstTrailerNumber,
};

const WithAssignLoadsToTrailersRows = msyncQuery<AssignLoadsToTrailersRowsResponse, OwnProps & StateProps & DispatchProps, WithAssignLoadsToTrailersRowsQueryProps, AssignLoadsToTrailersInput>(AssignLoadsToTrailersQuery, {
  alias: 'withRoutingLoadsForAssignLoadsToTrailersRows',
  skip: ownProps => {
    return ownProps.routingLoadIds.length === 0;
  },
  options(ownProps): { variables: AssignLoadsToTrailersInput, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: {
          id: { values: ownProps.routingLoadIds },
        },
        sort: [
          {
            sortField: 'identifier',
            sortOrder: 'ASC',
          },
        ],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithAssignLoadsToTrailersRowsQueryProps {
    const { data } = props;

    return {
      routingLoads: data.getRoutingLoads ? data.getRoutingLoads.routingLoads : [],
      loading: data.loading,
    };
  },
});

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithAssignLoadsToTrailersRowsQueryProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  WithAssignLoadsToTrailersRows,
)(AssignLoadsToTrailersModalUI as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
