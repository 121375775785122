import * as React from 'react';
import Logo from './logo';
import NavUtility from './nav-utility';
import { PropsWithChildren } from 'react';

function Navigator(props: PropsWithChildren<{
  testid: string,
  isLoggedIn: boolean,
}>) {
  const { isLoggedIn, children } = props;
  return (
    <nav className="navbar navbar-default navbar-pf" role="navigation">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse-1">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"/>
          <span className="icon-bar"/>
          <span className="icon-bar"/>
        </button>
        <Logo/>
      </div>
      <NavUtility testid="nav-utility" isLoggedIn={isLoggedIn}/>
      <div className="collapse navbar-collapse navbar-collapse-1">
        <ul className="nav navbar-nav navbar-primary persistent-secondary">
          { children }
        </ul>
      </div>
    </nav>
  );
}

export default Navigator;
