import * as React from 'react';
import { MutationStatus } from 'client/actions/mutations';
import { AutoReplenishmentForm } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-form-container';
import { AutoReplenishmentProps } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-modal-container';
import { FormModal } from 'client/components/form-modal';

interface OwnProps {
  isShown: boolean;
  cancelDisabled?: boolean;
  onCancelButtonClicked(): void;
  handleFormSubmit: shame;

  invalid: boolean;
  actionStatus: MutationStatus;

  customerOrderProductGroupIds: AutoReplenishmentProps['customerOrderProductGroupIds'];

  productLabel?: string;

  storeIds: number[];
}

export const AutoReplenishmentModalUI: React.SFC<OwnProps> = props => {
  return (
    <FormModal
      isShown={props.isShown}
      cancelDisabled={props.cancelDisabled}
      onCancelButtonClicked={props.onCancelButtonClicked}
      handleFormSubmit={props.handleFormSubmit}
      invalid={props.invalid}
      submitButtonLabel={'Apply'}
      submitButtonTestId={'apply-button'}
      modalTitle={'Allocate To Hit % Shipped Target'}
      modalId={'auto-replenishment-modal'}
      actionStatus={props.actionStatus}
      className={'auto-replenishment-modal'}
    >
      {props.isShown && <AutoReplenishmentForm customerOrderProductGroupIds={props.customerOrderProductGroupIds} productLabel={props.productLabel} storeIds={props.storeIds} />}
    </FormModal>
  );
};
