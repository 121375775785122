import * as React from 'react';
import { Form } from 'client/components/third-party';
import * as Constants from './constants';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ImmutableDateRange } from 'shared/types/date-range';
import { DateStr, ReportTypes, Carrier, LoadTrackingSortByOptions } from 'shared/types';
import * as Components from '../shared/components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';

export interface ComponentProps {
  carriers: string[];

  downloadButtonDisabled: boolean;

  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;

  selectedLoadTrackingSortByOption: string;
  loadTrackingReportHeader: string;
  handleLoadTrackingSortByOptionChanged(key: string): void;

  handleDownloadExcelReportClicked(): void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => void;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportDownloadStatus: MutationStatus;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class LoadTrackingListUI extends React.PureComponent<ComponentProps, {}> {
  componentDidUpdate() {
    if (this.props.pristine && !this.props.selectedLoadTrackingSortByOption) {
      this.props.handleLoadTrackingSortByOptionChanged(LoadTrackingSortByOptions.LoadPrefix);
    }
  }

  render() {
    return (
      <div>
        <RecordBar title="Load Tracking List" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-load-tracking-list-table-wrapper">
            <Form horizontal>
              <Components.Carrier
                name={Constants.FormFields.carriers}
                options={Object.keys(Carrier).map(carrier => ({ id: carrier, identifier: carrier, value: carrier }))} // carriers is just an enum, no ids
              />
              <Components.DateRange
                handleChange={this.props.handleDateRangeChanged}
                beginDate={this.props.beginDate}
                endDate={this.props.endDate}
                label="Store Delivery Date"
              />
            </Form>
          </div>
          <Components.LoadTrackingListSidebar
            reportType={ReportTypes.LoadTrackingList}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            selectedLoadTrackingSortByOption={this.props.selectedLoadTrackingSortByOption}
            handleLoadTrackingSortByOptionChanged={this.props.handleLoadTrackingSortByOptionChanged}
            loadTrackingReportHeader={this.props.loadTrackingReportHeader}
            loadTrackingReportHeaderName={Constants.FormFields.loadTrackingReportHeader}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}
