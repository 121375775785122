import { ImportSalesPlanModal } from 'client/app/planning/sales-plans/import-sales-plan-modal';
import * as React from 'react';
import { ImportSalesPlanVarietyColorModal } from '../../import-sales-plan-variety-color-modal';
import { WeeklyProductVolumeTable } from './weekly-product-volume-table';
import * as Types from '../types';
import { SalesPlanWeeklyVarietyDetailsTable } from './weekly-variety-details-table';
import AutoSizer from 'react-virtualized-auto-sizer';

export interface UIProps {
  salesPlanId: number;
  handleProductSelected(productId: number): void;
  productVolumeRows: Types.ProductVolumeRow[];
  varietyDetailRows: Types.VarietyDetailRow[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
  productLabel: string;
}

const HEIGHT_FUDGE_FACTOR = 200;
export function SalesPlanDetailsTableUI(props: UIProps) {
  const table1Ref = React.useRef(null as any);
  const table2Ref = React.useRef(null as any);
  if (!props.salesPlanId) {
    return (<div />);
  }
  return (
    <AutoSizer>
      {({ height: actualHeight, width: actualWidth }) => {
        const height = actualHeight - HEIGHT_FUDGE_FACTOR;
        const width = actualWidth;
        return (
          <div>
            <ImportSalesPlanModal salesPlanId={props.salesPlanId} />
            <WeeklyProductVolumeTable
              rows={props.productVolumeRows}
              startWeek={props.startWeek}
              endWeek={props.endWeek}
              loading={props.loading}
              handleProductSelected={props.handleProductSelected}
              height={height / 2}
              width={width}
              self={table1Ref}
              twin={table2Ref}
            />

            <ImportSalesPlanVarietyColorModal salesPlanId={props.salesPlanId} />
            <div className="dependent-readonly-table">
              <SalesPlanWeeklyVarietyDetailsTable
                productLabel={props.productLabel}
                rows={props.varietyDetailRows}
                startWeek={props.startWeek}
                endWeek={props.endWeek}
                loading={props.loading}
                height={height / 2}
                width={width}
                twin={table1Ref}
                self={table2Ref}
              />
            </div>

          </div>
        );
      }}
    </AutoSizer>
  );
}
