import * as Constants from './constants';
import * as Excel from '../common';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Types from './types';
import * as XLSX from 'xlsx';

type SalesPlanWorkBook = XLSX.WorkBook;
type SalesPlanWorkSheet = XLSX.WorkBook;

enum ValidSalesPlanWorkSheetDataType { }
type SalesPlanWorkSheetData = Excel.WorkSheetData & ValidSalesPlanWorkSheetDataType;

export async function getSalesPlanWorkBookFromWorkBookContainer(workBookContainer: ExcelCommon.WorkBookContainer): Promise<SalesPlanWorkBook | null> {
  return Excel.getWorkBookFromWorkBookContainer<SalesPlanWorkBook>(workBookContainer);
}

export const getSalesPlanWorkSheet = (workBook: SalesPlanWorkBook) => Excel.getFirstWorkSheet<SalesPlanWorkSheet>(workBook);
export const getSalesPlanWorkSheetData = (workSheet: SalesPlanWorkSheet) => Excel.getWorkSheetData<SalesPlanWorkSheetData>(workSheet);

export function getHeaderData(workSheetData: SalesPlanWorkSheetData): Types.ImportableSalesPlanHeader {
  const customerIdentifierRow = workSheetData[Constants.CUSTOMER_IDENTIFIER_ROW - 1];
  const productionYearRow = workSheetData[Constants.PRODUCTION_YEAR_ROW - 1];
  const productionSeasonRow = workSheetData[Constants.PRODUCTION_SEASON_ROW - 1];

  const customerIdentifier = Excel.getAsString(customerIdentifierRow[Constants.CUSTOMER_IDENTIFIER_COL]);
  const productionYear = Excel.getAsInteger(productionYearRow[Constants.PRODUCTION_YEAR_COL]);
  const productionSeason = Excel.getAsString(productionSeasonRow[Constants.PRODUCTION_SEASON_COL]);

  return {
    customerIdentifier,
    productionYear,
    productionSeason,
  };
}

const findFirstAllocationRowIndex = () => (Constants.FIRST_ALLOCATION_ROW - 1);

function findLastAllocationRow(workSheetData: SalesPlanWorkSheetData) {
  if (workSheetData.length === 0) {
    throw new Error(`Unable to find last allocation row: the spreadsheet appears empty.`);
  }

  return workSheetData.length;
}

function findAllocationColLetters(workSheetData: SalesPlanWorkSheetData) {
  if (workSheetData.length === 0) {
    throw new Error(`Unable to find last allocation row: the spreadsheet appears empty.`);
  }

  const allocationHeadingRow = workSheetData[Constants.ALLOCATION_HEADING_ROW - 1];
  const columnLetters = Object.keys(allocationHeadingRow);

  const allocationCols = columnLetters
    .filter(letter => !['A', 'B', 'C'].includes(letter));

  const columns = allocationCols
    .map(colLetter => ({ colLetter, weekNumber: allocationHeadingRow[colLetter] }));

  return columns;
}

export function getProducts(workSheetData: SalesPlanWorkSheetData) {
  const firstAllocationRowIndex = findFirstAllocationRowIndex();
  const lastAllocationRowIndex = findLastAllocationRow(workSheetData);

  const allocationRows = workSheetData.slice(firstAllocationRowIndex, lastAllocationRowIndex + 1);

  const allocationColLetters = findAllocationColLetters(workSheetData);

  const products = allocationRows
    .map((row): Types.ImportableSalesPlanProduct => {
      return {
        productIdentifier: Excel.getAsString(row[Constants.PRODUCT_IDENTIFIER_COL]) || '',

        allocations: allocationColLetters
          .map(column => {
            const weekNumber = `${column.weekNumber}`.replace(/[^0-9]/g, '');

            return {
              weekNumber: Number.parseInt(weekNumber),
              allocation: Excel.getAsInteger(row[column.colLetter]) || 0,
            };
          })
          .filter(allocation => {
            return allocation.allocation > 0;
          }),
      };
    })
    .filter(product => {
      return product.allocations.length > 0;
    });

  return products;
}
