import { FULL_RACK_IDENTIFIER, FULL_CASE_IDENTIFIER } from 'shared/types';
import { SupplierOrderProduct } from 'shared/schemas/supplier-order-product';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { FieldValidator } from 'shared/validators';

// NOTE: This function assumes that the list of incoming supplier order product groups
// has already been filtered by the supplier item (referenced from the supplier order
// product table).
function determineNextSupplierOrderProductGroupIdentifier(args: { supplierItemId: SupplierItemId, supplierOrderProducts: SupplierOrderProduct[], fullIdentifier: string }): string {
  if (!args.supplierOrderProducts.some(sop => sop.supplierOrderProductGroup.identifier === args.fullIdentifier && sop.supplierItemId === args.supplierItemId)) {
    return args.fullIdentifier;
  }

  if (!args.supplierOrderProducts.some(sop => sop.supplierOrderProductGroup.identifier.match(/^F[0-9]+$/) !== null)) {
    return 'F1';
  }

  const numbers = args.supplierOrderProducts
    .map(sop => {
      const results = sop.supplierOrderProductGroup.identifier.match(/^F([0-9]+)$/) || [];

      if (results.length === 2) {
        return Number.parseInt(results[1]);
      }

      return 0;
    })
    .sort();

  const highestNumber = numbers[numbers.length - 1];

  return `F${highestNumber + 1}`;
}

export function determineNextSupplierOrderProductGroupRackIdentifier(args: { supplierItemId: SupplierItemId, supplierOrderProducts: SupplierOrderProduct[] } ): string {
  return determineNextSupplierOrderProductGroupIdentifier({ supplierItemId: args.supplierItemId, supplierOrderProducts: args.supplierOrderProducts, fullIdentifier: FULL_RACK_IDENTIFIER });
}

export function determineNextSupplierOrderProductGroupPalletIdentifier(args: { supplierItemId: SupplierItemId, supplierOrderProducts: SupplierOrderProduct[] }): string {
  return determineNextSupplierOrderProductGroupIdentifier({ supplierItemId: args.supplierItemId, supplierOrderProducts: args.supplierOrderProducts, fullIdentifier: FULL_CASE_IDENTIFIER });
}

export function determineNextSupplierOrderProductGroupComboIdentifier(args: { supplierOrderProducts: SupplierOrderProduct[] }): string {
  if (!args.supplierOrderProducts.some(sop => sop.supplierOrderProductGroup.identifier.match(/^C[0-9]+$/) !== null)) {
    return 'C1';
  }

  const numberedComboCarts = args.supplierOrderProducts
    .map(sop => {
      const results = sop.supplierOrderProductGroup.identifier.match(/^C([0-9]+)$/) || [];

      if (results.length === 2) {
        return Number.parseInt(results[1]);
      }

      return 0;
    })
    .sort();

  const highestNumberedComboCart = numberedComboCarts[numberedComboCarts.length - 1];
  return `C${highestNumberedComboCart + 1}`;
}

interface CurrentSupplierOrderProduct {
  supplierOrderProductGroup: {
    identifier: string;
    isCombo: boolean;
  };
  supplierItemId: number;
}

interface FormValues {
  supplierOrderProducts: Array<{
    supplierItemId: number;
  }>;
  isCombo: boolean;
}
export const buildUniqueCartIdentifierConstraint = (args: { currentSupplierOrderProducts: CurrentSupplierOrderProduct[], initialValues: any }): FieldValidator => {
  const isValid = (fieldValue: string, record: FormValues) => {
    const supplierItemIdsInForm = record.supplierOrderProducts.map(sop => sop.supplierItemId);
    const currentSupplierOrderProductsWithSameSupplierItem = args.currentSupplierOrderProducts.filter(sop => supplierItemIdsInForm.includes(sop.supplierItemId));
    const comboSupplierOrderProducts = args.currentSupplierOrderProducts.filter(sop => sop.supplierOrderProductGroup.isCombo);
    const takenComboIdentifiers = comboSupplierOrderProducts.map(sop => sop.supplierOrderProductGroup.identifier);
    const takenSupplierOrderProductGroupIdentifiers = currentSupplierOrderProductsWithSameSupplierItem.map(sop => sop.supplierOrderProductGroup.identifier);

    const invalid = takenSupplierOrderProductGroupIdentifiers.includes(fieldValue) || takenComboIdentifiers.includes(fieldValue);
    return !invalid;
  };
  return {
    isValid,
    shortMessage(value: any, record: any) {
      return `${value} already exists`;
    },
    message(label: string, value: any, record: any) {
      return `Rack Configuration with identifier ${value} already exists.`;
    },
  };
};
