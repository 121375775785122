import { tableName, property, belongsTo, definePresentation, setSchemaOptions, required, defineFieldValidators } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, SORT_TYPES, DateStr } from '../types';
import * as Validators from 'shared/validators';
import { Store } from './store';
import { CustomerOrderProduct } from './customer-order-product';
import { CustomerOrder } from './customer-order';
import { Product } from './product';
import { IRecord } from 'shared/schemas/record';
import { ImportJob } from 'shared/schemas/import-job';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';
import { StoreDisplayValueResolver, ProductDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { DateTimeStr } from 'shared/types/date-str';
import { User } from 'shared/schemas/user';

export type CustomerOrderAllocationId = Flavor<number, 'customerOrderAllocationId'>;
export type CustomerOrderAllocationQuantity = Int;
export type CustomerOrderAllocationRackQuantity = number;

@tableName('customerOrderAllocations')
export class CustomerOrderAllocation implements IRecord {
  static readonly UniqueConstraints: Array<keyof CustomerOrderAllocation> = ['customerOrderProduct', 'store'];
  static readonly SchemaName = 'CustomerOrderAllocation';

  id?: CustomerOrderAllocationId;
  @property productDescription?: string | null;
  @property shipDate?: DateStr | null;
  @property @required quantity: CustomerOrderAllocationQuantity;
  @property rackQuantity?: CustomerOrderAllocationRackQuantity | null; // Calculated
  @property @required price: number;
  @property @required retail: number;
  @property upcRetail?: number | null;
  @property totalUnits: number;  // Calculated
  @property totalPrice: number;  // Calculated
  @property totalRetail: number; // Calculated

  @belongsTo('stores')
  @property @required store: Store;
  storeId: number;

  @belongsTo('customerOrderProducts', { foreignDisplayKey: 'packSize', foreignQueryKeys: ['packsPerShippingUnit', 'shelvesPerRack', 'packSize', 'packsPerShelf'] })
  @property @required customerOrderProduct: CustomerOrderProduct;
  customerOrderProductId: number;

  @belongsTo('customerOrderProductGroups', { through: 'customerOrderProducts' })
  @property @required customerOrderProductGroup: CustomerOrderProductGroup;
  customerOrderProductGroupId: number;

  @belongsTo('customerOrders', { through: 'customerOrderProductGroups' })
  @property @required customerOrder: CustomerOrder;
  customerOrderId: number;

  @belongsTo('products', { through: 'customerOrderProducts' })
  @property @required product: Product;
  productId: number;

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @property lastModifiedAt?: DateTimeStr | null;

  @belongsTo('users', { through: 'customerOrders', nativeTableFK: 'lastModifiedUserId' })
  @property lastModifiedUser?: User;
  @property lastModifiedUserId?: number | null;
}

defineFieldValidators(CustomerOrderAllocation, {
  price: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(99999.99)],
  retail: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(9999.99)],
  upcRetail: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  rackQuantity: [Validators.GREATER_THAN_ZERO],
  quantity: [Validators.GREATER_THAN_ZERO],
});

definePresentation(CustomerOrderAllocation, {
  store: {
    sortable: true,
    filterable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 6,
    tableDisplayColumns: { identifier: 'Store' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: StoreDisplayValueResolver, multiselect: true }),
  },
  product: {
    sortable: true,
    searchable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 7,
    tableDisplayColumns: { identifier: 'Product' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver, multiselect: true }),
  },
  productDescription: {
    displayName: 'Description',
    sortable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 24,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  customerOrderProduct: {
    tableDisplay: true,
    columnWidth: 5,
    sortable: true,
    formDisplay: false,
    tableDisplayColumns: { packsPerShippingUnit: 'PPR', shelvesPerRack: 'SPR', packSize: 'Pack', packsPerShelf: 'PPS' },
  },
  customerOrderProductGroup: {
    tableDisplay: true,
    sortable: true,
    filterable: true,
    formDisplay: false,
    displayName: 'Rack',
    columnWidth: 5,
    tableDisplayColumns: { identifier: 'Rack' },
  },
  customerOrder: {
    formDisplay: false,
    includeInFormQuery: true, // This is necessary to fetch the last modified info from customer order
  },
  lastModifiedUser: {
    formDisplay: false,
    includeInFormQuery: true, // This is necessary to fetch the last modified info from customer order
  },
  shipDate: {
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
  },
  quantity: { // Pack Quantity
    displayName: 'Qty',
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
    tableEditable: true,
  },
  rackQuantity: {
    displayName: 'Qty',
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    tableEditable: true,
    calculationSpec: {
      dependencies: {
        customerOrderProduct: ['packsPerShippingUnit'],
      },
      calculation: ({ packsPerShippingUnit, quantity }) => `CASE WHEN ${packsPerShippingUnit} = 0 THEN NULL ELSE ${quantity} * 1.0 / ${packsPerShippingUnit} END`,
    },
  },
  price: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    tableEditable: true,
  },
  retail: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    tableEditable: true,
  },
  upcRetail: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    tableEditable: true,
  },
  totalUnits: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 0,
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    calculationSpec: {
      dependencies: {
        customerOrderProduct: ['packSize'],
      },
      calculation: ({ packSize, quantity }) => `${quantity} * ${packSize}`,
    },
  },
  totalPrice: {
    tableDisplay: true,
    sortable: true,
    columnWidth: 13,
    type: TYPES.FLOAT,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    calculationSpec: {
      dependencies: {
        customerOrderProduct: ['packSize'],
      },
      calculation: ({ packSize, quantity, price }) => `${quantity} * ${packSize} * ${price}`,
    },
  },
  totalRetail: {
    tableDisplay: true,
    sortable: true,
    columnWidth: 13,
    type: TYPES.FLOAT,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    calculationSpec: {
      dependencies: {
        customerOrderProduct: ['packSize'],
      },
      calculation: ({ packSize, quantity, retail }) => `${quantity} * ${packSize} * ${retail}`,
    },
  },
  importJob: { formDisplay: false },
});

setSchemaOptions(CustomerOrderAllocation, {
  defaultSort: [
    { sortField: 'store', foreignColumn: 'identifier', sortOrder: SORT_TYPES.ASC },
    { sortField: 'customerOrderProductGroup', foreignColumn: 'identifier', sortOrder: SORT_TYPES.ASC },
    { sortField: 'productDescription', sortOrder: SORT_TYPES.ASC },
  ],
  uniqueConstraints: CustomerOrderAllocation.UniqueConstraints,
  hasLastModifiedInfo: true,
});
