import * as _ from 'lodash';
import { ExpectedError } from './expected-error';

export * from './coded-error';
export * from './error-with-data';
export * from './expected-error';

export const orThrow = <T = never>(message: string): T & never => { throw new Error(message); };
export const orThrowExpected = <T = never>(message: string): T & never => { throw new ExpectedError(message); };
export const assertNotLongerThan = <T>(array: T[], maxLengthInclusive: number, errorMessage?: string) =>
  array.length <= maxLengthInclusive ? array : orThrow(errorMessage || `Expected fewer than ${maxLengthInclusive + 1} items, but given list had ${array.length} items.`);

export const assertUserInputNotLongerThan = <T>(array: T[], maxLengthInclusive: number, errorMessage: string) =>
  array.length <= maxLengthInclusive ? array : orThrowExpected(errorMessage);

export class AggregateError extends Error {
  constructor(
    readonly errors: Error[],
    readonly message: string = 'Multiple errors occurred',
  ) {
    super(message);
    this.name = 'AggregateError';
    Object.setPrototypeOf(this, AggregateError.prototype); // Set the prototype explicitly. https://stackoverflow.com/a/41429145/4592309
  }
}

export class SendError<Content extends string | SimpleObject | Error = string> extends Error {
  constructor(
    readonly url: string,
    readonly status: number,
    readonly content: Content,
  ) {
    super(`HTTP request failed with code ${status}:\n ${url}   \n---\n\n   ${(content as any)?.message ?? content}`);
    if ((content as any)?.stack)
      this.stack = ((content as any)?.stack ?? []).concat('\n\n------------- end of server stack ---------\n\n').concat(this.stack ?? []);

    this.name = 'SendError';
    Object.setPrototypeOf(this, SendError.prototype); // Set the prototype explicitly. https://stackoverflow.com/a/41429145/4592309
  }
}

export const splitStackFrames = (stack: string) => {
  const lines = stack.split(/\n|\r\n/);
  const messageLines = _.takeWhile(lines, l => !l.startsWith('    at'));
  return [messageLines, _.drop(lines, messageLines.length)];
};

let unsanitaryPrefix: string | undefined | null;
const prefixToBeSanitized = (): string | undefined | null => {
  if (unsanitaryPrefix) {
    return unsanitaryPrefix;
  }

  // Create a sample error - content doesn't matter, just to create a stack that we can sanitize
  const sampleErrorStack = new Error('moo').stack;

  // Split the stack into individual lines (if there is no stack, ignore)
  const sampleStackFrame = sampleErrorStack ? sampleErrorStack.split('\n').find(l => l.startsWith('    at')) : undefined;

  // Grab the prefix that is specific to the environment/machine/user that we're running on (will return a list but they should all be the same)
  const prefixesSpecificToAUsersMachine = sampleStackFrame ? /\(([^\/]*\/)+(msync|app)\//.exec(sampleStackFrame) : null;
  unsanitaryPrefix = prefixesSpecificToAUsersMachine ? prefixesSpecificToAUsersMachine[0] : null;
  return unsanitaryPrefix;
};

// If the stack line is a path in the stack trace, remove the variable portion of the trace (the user-specific directories preceding msync)
// Only do this if a line is passed in and we actually found a user-specific prefix to remove from each stack trace
export const sanitizeMsyncPath = (l?: string) => {
  const prefix = prefixToBeSanitized();
  return l && prefix && l.startsWith('    at') ? l.replace(prefix, '(msync/') : l;
};

export const sanitizeLineNumbers = (s: string) => s
  .replace(/\([\w\d\/-]*msync-?[^\/]*\/([^\):]+):\d+:\d+\)/ig, '($1)')
  .replace(/:\d+:\d+\)/g, ')');

export const wrapError = (err: Error, outerMessage: string) => {
  const wrapper = new Error(outerMessage);
  const [[firstMessageLine, ...others], stack] = splitStackFrames(wrapper.stack || '');
  const [innerMessage, innerStack] = splitStackFrames(err.stack || '');
  wrapper.stack = [
    firstMessageLine.substr('Error: '.length),
    ...others,
    '',
    'STACK:',
    ..._.drop(stack, 1),
    '',
    '-- RE-THROWN UPON CATCHING: --',
    '',
    err.message,
    'STACK:',
    ...innerMessage,
    ...innerStack,
  ].map(sanitizeMsyncPath).join('\n');

  return wrapper;
};
