import * as React from 'react';

export interface Props {
  elements: JSX.Element[];
  testId?: string;
  onClick?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ComponentState { }

export class ProductListItemDetail extends React.Component<Props, ComponentState> {
  public render() {
    const { testId, elements } = this.props;

    return (
      <div data-testid={testId} className="product-worksheet-products-list-product-rack-configuration product-worksheet-recent-combo-product-rack-configuration">
        <div className="product-worksheet-products-list-product-shipment-configuration-label" onClick={this.props.onClick}>
          {elements}
        </div>
      </div>
    );
  }
}
