import * as Redux from 'redux';
import * as Mutation from './mutation';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { BILLING_INVOICES_TABLE_NAME } from 'client/constants';
import { uncheckAllRecords } from 'client/actions/table';
import { GetInvoicesQueryName } from '../list/query';

export type ActionTypes =
  SendInvoicesToAcumaticaModalCloseClickedAction |
  SendInvoicesToAcumaticaCompletedAction
  ;

export enum ActionTypeKeys {
  SendInvoicesToAcumaticaModalCloseClicked = 'App/SEND_INVOICES_TO_ACUMATICA_MODAL_CLOSE_CLICKED',
  SendInvoicesToAcumaticaCompleted = 'App/SEND_INVOICES_TO_ACUMATICA_COMPLETED',
}

export interface SendInvoicesToAcumaticaModalCloseClickedAction extends Redux.Action {
  type: ActionTypeKeys.SendInvoicesToAcumaticaModalCloseClicked;
}

export const onSendInvoicesToAcumaticaModalCloseClicked = (): SendInvoicesToAcumaticaModalCloseClickedAction => {
  return {
    type: ActionTypeKeys.SendInvoicesToAcumaticaModalCloseClicked,
  };
};

export interface SendInvoicesToAcumaticaCompletedAction extends Redux.Action {
  type: ActionTypeKeys.SendInvoicesToAcumaticaCompleted;
}

const onSendInvoicesToAcumaticaCompleted = (): SendInvoicesToAcumaticaCompletedAction => {
  return {
    type: ActionTypeKeys.SendInvoicesToAcumaticaCompleted,
  };
};

export const handleSendInvoicesToAcumaticaClicked = (invoiceIdsToBeSentToAcumatica: Array<{ id: number, identifier: string }>): Thunker => {
  return async (dispatch, getState) => {
    try {
      await msyncClientMutation<Mutation.Response, Mutation.Input>({
        mutation: Mutation.Mutation,
        variables: {
          input: {
            invoiceIds: invoiceIdsToBeSentToAcumatica.map(invoice => invoice.id),
          },
        },
        dispatch,
        refetchQueries: [GetInvoicesQueryName],
      });

      dispatch(onSendInvoicesToAcumaticaCompleted());
      dispatch(uncheckAllRecords(BILLING_INVOICES_TABLE_NAME));

    } catch {
      // No-op - we don't want to close the modal if theres an error
    }
  };
};
