import {
  CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY,
  CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS,
} from 'client/constants';
import { MutationStatus } from 'client/actions/mutations';

export function setAddNewTransactionModalVisibility(showModal: boolean) {
  return {
    type: CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY,
    payload: showModal,
  };
}

export function setAddNewTransactionActionStatus(actionStatus: MutationStatus) {
  return {
    type: CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS,
    payload: actionStatus,
  };
}
