import * as _ from 'lodash';
import * as React from 'react';
import { formatInteger, ReceivingSummaryStats } from 'shared/types';
import { StatsCard } from 'client/components/simple-components/simple-stats-card';

export function ReceivingReturnedCard(props: Pick<ReceivingSummaryStats, 'returned'>) {
  return (
    <StatsCard title={`Racks Returned`}>{_.isNil(props.returned) ? '-' : formatInteger(props.returned)}</StatsCard>
  );
}
