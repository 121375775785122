import * as React from 'react';
import { Button, Modal } from 'client/components/third-party';
import { MutationStatus } from 'client/actions/mutations';
import { AsyncButton } from 'client/components/async-button/async-button-container';

interface OwnProps {
  isShown: boolean;
  onCancelButtonClicked(): void;
  onRemoveClicked(): void;
  actionStatus: MutationStatus;
}

export const RemoveBolModal: React.SFC<OwnProps> = props => {
  return (
    <Modal
      id="remove-bol-modal"
      animation
      backdrop="static"
      show={props.isShown}
      onHide={() => props.onCancelButtonClicked()}>
      <Modal.Body>
        Are you sure you want to delete this BOL?
      </Modal.Body>
      <Modal.Footer>
        <Button bsClass="mfc-button mfc-clear-button" data-testid="cancel-button" onClick={() => props.onCancelButtonClicked()}>
          Cancel
        </Button>
        <AsyncButton
          label={'Delete BOL'}
          type={'submit'}
          testid={'delete-button'}
          onClick={() => props.onRemoveClicked()}
          actionStatus={props.actionStatus}
        />
      </Modal.Footer>
    </Modal>
  );
};
