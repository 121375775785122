import { ReadonlyTable } from 'client/components/readonly-table';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReadOnlyTableTypes from 'client/components/readonly-table/types';
import * as Reselect from 'reselect';
import * as Types from '../../types';

export interface UIProps {
  rows: Types.ProductVolumeRow[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
  handleProductSelected(productId: number): void;
  height: number;
  width: number;
  handleImportModalOpenModalButtonClicked(): void;
  selectedProductId: number | null;
  self: any;
  twin: any;
}

const getStartWeek = (props: UIProps) => {
  return props.startWeek;
};

const getEndWeek = (props: UIProps) => {
  return props.endWeek;
};

const getColumns = Reselect.createSelector([
  getStartWeek,
  getEndWeek,
], (startWeek, endWeek): ReadOnlyTableTypes.ColumnDef[] => {
  const weekColumns = startWeek && endWeek
    ? _.range(startWeek, endWeek + 1).map(weekNumber => {
      return {
        header: `WK ${weekNumber}`,
        accessor: `week-${weekNumber}`,
        contentType: ReadOnlyTableTypes.ColumnContentType.Amount,
        width: 60,
      };
    })
    : [];

  return [
    {
      header: 'Prod ID',
      accessor: 'productIdentifier',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 100,
    },
    {
      header: 'Compl.',
      accessor: 'complete',
      contentType: ReadOnlyTableTypes.ColumnContentType.Checkmark,
      width: 60,
    },
    {
      header: 'Description',
      accessor: 'description',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 100,
    },
    {
      header: 'Total Units',
      accessor: 'totalUnits',
      contentType: ReadOnlyTableTypes.ColumnContentType.Amount,
      width: 100,
    },
    ...weekColumns,
  ];
});

interface UIState {
  selectedRowId: number;
}

const defaultSort: ReadOnlyTableTypes.SortDef = {
  columnHeader: 'Prod ID',
  order: 'asc',
};

export class WeeklyProductVolumeTableUI extends React.PureComponent<UIProps, UIState> {
  onRowClicked = (rowIndex: number) => {
    const row = this.props.rows[rowIndex];
    if (row?.id) {
      this.props.handleProductSelected(row.id);
    }
  }

  render() {
    const columns = getColumns(this.props);
    return (
      <ReadonlyTable
        tableName="weekly-product-volume"
        columns={columns}
        data={this.props.rows}
        onRowClicked={this.onRowClicked}
        height={this.props.height}
        width={this.props.width}
        defaultSort={defaultSort}
        growableColumnHeader={'Description'}
        loading={this.props.loading}
        twin={this.props.twin}
        self={this.props.self}
        showFooter
        highlightSelectedRow
      >
        <div className="sales-plan-product-breakdown-table-topper">
          <div className="sales-plan-product-breakdown-table-topper-button-container" >
            <div className="readonly-table-topper-button" data-testid="import-sales-plan-button" onClick={this.props.handleImportModalOpenModalButtonClicked}>
              Import Sales Plan from Excel
            </div>
          </div>
        </div>
      </ReadonlyTable>
    );
  }
}
