import { connect, ConnectedProps } from 'react-redux';
import Modal from './attach-supplier-orders-modal';
import { wrapComponent } from 'client/hoc/hoc';
import gql from 'graphql-tag';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME } from 'client/constants';
import * as Actions from 'client/actions/customer-order';
import { ApolloRefetch } from 'client/types';
import { DateStr, TYPES } from 'shared/types';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { withDefaultFilters } from 'client/hoc/default-filters';
import { createSelector } from 'reselect';
import { SchemaActiveFilter } from 'client/types';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { addDays } from 'shared/helpers/date-helpers';
import { buildTableStateModule } from 'client/state/tables';
import { ThunkerDispatch } from 'client/types/redux-types';

export interface OwnProps {
  customerOrderId: number;
  title: string;
  isShown: boolean;
  onCloseClicked(): void;
  onAddClicked(): void;
  refetchData?: ApolloRefetch;
  confirmOkToSave(): Promise<boolean>;
  dataRequest: MsyncDataRequest;
}

interface StateProps {
  supplierOrderIds: SupplierOrderId[];
}
const TableStateHelpers = buildTableStateModule(SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME);

const mapStateToProps = state => {
  const tableState = TableStateHelpers.tableStateLens.get(state);
  return {
    supplierOrderIds: TableStateHelpers.checkedRecordIds(tableState),
  };
};

const mapDispatchToProps = (dispatch: ThunkerDispatch, ownProps: OwnProps & MutationProps & StateProps) => {
  return {
    handleAddClicked: (tableName: string) => {
      dispatch(Actions.addSupplierOrdersClicked({ mutationFunction: ownProps.attachSupplierOrders, addClickHandler: ownProps.onAddClicked, refetchData: ownProps.refetchData, tableName }));
    },
    handleCloseClicked: (tableName: string) => {
      dispatch(Actions.supplierOrdersCloseDialogClicked({ closeClickHandler: ownProps.onCloseClicked, tableName }));
    },
  };
};

interface MutationProps {
  attachSupplierOrders(): Promise<boolean>;
}
const mutation = gql`
  mutation attachSupplierOrders($input: EditCustomerOrderInput!) {
    result: editCustomerOrder(input: $input) {
      id
    }
  }
`;
const withMutation = msyncMutation<{}, OwnProps & StateProps, shame>(mutation, {
  alias: 'attachSupplierOrdersMutation',
  props: props => ({
    attachSupplierOrders: async () => {
      const attachArgs = {
        variables: {
          input: {
            id: props.ownProps.customerOrderId,
            supplierOrders: {
              created: props.ownProps.supplierOrderIds.map(supplierOrderId => {
                return {
                  supplierOrderId,
                };
              }),
            },
          },
        },
      };

      if (await props.mutate(attachArgs)) {
        return true;
      } else {
        return false;
      }
    },
  }),
});

interface RelatedDefaultFilterDataQueryResponse {
  content?: {
    id: number;
    customer: {
      id: number;
      name: string;
    }
    sellDepartment: {
      id: number;
      identifier: string;
    }
    mfcArea?: {
      id: number;
      identifier: string;
    }
    orderDate: DateStr;
  };
}

const FILTER_QUERY  = gql`
query findForAttachSupplierOrderFilters($type: RecordType! $customerOrderId: Int!) {
  content: find(type: $type, id: $customerOrderId) {
    ...customerOrderFragment
  }
}
fragment customerOrderFragment on CustomerOrder {
    id
    customer {
      id
      name
    }
    sellDepartment {
      id
      identifier
    }
    mfcArea {
      id
      identifier
    }
    orderDate
}
`;

interface WithDefaultFilterDataProps {
  customer?: string;
  sellDepartment?: string;
  mfcArea?: string;
  orderDate?: DateStr;
}

const WithDefaultFilterData = msyncQuery<RelatedDefaultFilterDataQueryResponse, OwnProps, WithDefaultFilterDataProps, {}>(FILTER_QUERY, {
  alias: 'withDefaultFilterData',
  options(ownProps): { variables } {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId,
        type: 'CustomerOrder',
      },
    };
  },
  props({ data, ownProps }): WithDefaultFilterDataProps {
    const content = data.content;
    if (!content) {
      return {};
    }
    return {
      customer: content.customer.name,
      sellDepartment: content.sellDepartment.identifier,
      mfcArea: content.mfcArea ? content.mfcArea.identifier : undefined,
      orderDate: content.orderDate,
    };
  },
});

function getDefaultFilters(customer, sellDepartment, mfcArea, orderDate) {
  const filters: Array<SchemaActiveFilter<SupplierOrder>> = [];

  if (customer) {
    filters.push({
      field: 'customer.name' as shame,
      values: [customer],
    });
  }
  if (sellDepartment) {
    filters.push({
      field: 'sellDepartment.identifier' as shame,
      values: [sellDepartment],
    });
  }
  if (mfcArea) {
    filters.push({
      field: 'mfcArea.identifier' as shame,
      values: [mfcArea],
    });
  }
  if (orderDate) {
    const startDate = addDays(orderDate, -5);
    const endDate = orderDate;
    filters.push({
      field: 'plannedArrivalDate',
      values: [{ startDate, endDate }],
      type: TYPES.DATE,
    });
  }
  return filters;
}

const connector1 = connect(mapStateToProps);
const connector2 = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector2> &
  MutationProps;

export default wrapComponent(Modal)<OwnProps, CombinedProps>(
  connector1,
  withMutation,
  connector2,
  WithDefaultFilterData,
  withDefaultFilters<shame>(SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME,
    createSelector([
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.customer,
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.sellDepartment,
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.mfcArea,
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.orderDate,
    ], getDefaultFilters),
  ),
);
