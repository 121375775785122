import * as React from 'react';
import { FormControl, HelpBlock } from 'client/components/third-party';
import * as classnames from 'classnames';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';

// need these two requires to support legacy react stuff when running yarn test:client and friends (no prepare or whatever makes the other tests work)
// DVM 2024 03 29 - maybe no longer so?  perhaps createClass will still be needed....
// React.PropTypes = require('prop-types');
// React.createClass = require('create-react-class');

function LabeledRadio({
  input: { value, onChange },
  meta: { touched, error, submitting },
  label,
  labelColSize,
  inputColSize,
  testid,
  horizontalLabel,
  offset,
  mfcClassName,
  optionValue,
  handleChange,
  disabled,
  alwaysShowErrors,
}: {
  disabled?: boolean,
  alwaysShowErrors: boolean,
  optionValue: any,
  label: string,
  testid: string,
  labelColSize: number,
  inputColSize: number,
  mfcClassName?: string,
  handleChange: (...args: unknown[]) => unknown,
  horizontalLabel: boolean,
  offset: number,


  // comes from redux-form
  input: { name: string, value: any, onChange: (...args: unknown[]) => unknown },
  meta: { touched: boolean, submitting: boolean, error: string },
}) {
  const classNames = classnames('mfc-radio', mfcClassName, {
    checked: value === optionValue,
    disabled,
  });

  const onClick = () => {
    if (handleChange) {
      handleChange(optionValue);
    } else {
      onChange(optionValue);
    }
  };

  const shouldDisplayError = !submitting && error && (touched || alwaysShowErrors);

  const radioElement = (
    <div>
      <div className={classNames} onClick={onClick} data-testid={`${testid}-${optionValue}`}>
        <label>
          <span className="indicator" />
          {label}
        </label>
      </div>
      {touched && <FormControl.Feedback />}
      {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
    </div>
  );

  if (horizontalLabel) {
    return (
      <HorizontalLayout offset={offset} labelColSize={labelColSize} inputColSize={inputColSize}>
        {radioElement}
      </HorizontalLayout>
    );
  }

  return (
    <StackedLayout inputColSize={inputColSize} offset={offset}>
      {radioElement}
    </StackedLayout>
  );
}

export default LabeledRadio;
