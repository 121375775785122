import gql from 'graphql-tag';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { QueryAsExcelColumn } from 'shared/types';
import { extractQueryNameAllowingEmpty } from './query-utils';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { Thunker } from 'client/types/redux-types';

export const GenerateExcelForTable = gql`
  mutation GenerateExcelForTable($input: GenerateExcelForTableInput!) {
    response: generateExcelForTable(input: $input) {
      id
    }
  }`;

export const downloadExcel = (args: {
  dataRequest: MsyncDataRequest,
  columns: QueryAsExcelColumn[],
  setInProgress: () => void,
  setNotInProgress: () => void,
  handleExpectedError?: (args: { message: string, debugInfo: any }) => void,
}): Thunker => {
  return async dispatch => {
    if (!args.dataRequest) {
      return;
    }

    args.setInProgress();
    try {
      if (notNil(args.dataRequest.operationName)) {
        console.warn(`Download Excel - OperationName does not need to be specified (name: '${args.dataRequest.operationName}'). Cut it out.`);
      }

      const input = getGenerateExcelForTableInput(args);

      const { data } = await msyncClientMutation<any>({
        mutation: GenerateExcelForTable,
        dispatch,
        variables: {
          input,
        },
      });

      window.location.replace(`/report/fileDownload/${data.response.id}`);

    } catch (error) {
      if (args.handleExpectedError) {
        args.handleExpectedError({ message: error.message, debugInfo: {} });
      }
    } finally {
      args.setNotInProgress();
    }

  };
};

export function getGenerateExcelForTableInput(args: { dataRequest: MsyncDataRequest; columns: QueryAsExcelColumn[]; }) {
  return {
    operationName: notNil(args.dataRequest.operationName) ? args.dataRequest.operationName : extractQueryNameAllowingEmpty(args.dataRequest.query),
    query: JSON.stringify(args.dataRequest.query),
    variables: JSON.stringify(args.dataRequest.variables),
    columns: args.columns.map(col => ({
      id: col.id,
      accessor: col.accessor,
      header: col.header,
      cellType: col.cellType,
      type: col.type,
    })),
    uniqueKey: args.dataRequest.uniqueKey,
    sortField: args.dataRequest.sortField,
    worksheetName: args.dataRequest.worksheetName,
    workbookName: args.dataRequest.workbookName || 'Workbook',
    additionalInformation: args.dataRequest.additionalInformation,
    customAccessor: args.dataRequest.customAccessor,
  };
}
