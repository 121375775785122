import * as React from 'react';
import { formatInteger, formatMoneyOrNull } from 'shared/types';
import * as Types from './types';

export function formatColumn(contentType: Types.ColumnContentType, value: any, includePercent: boolean = true): string | JSX.Element {
  if (value === 0 || value === undefined || value === '0' || value === '0.00') {
    return '';
  }

  if (contentType === 'amount') {
    return formatInteger(value, '0');
  } else if (contentType === 'percent') {
    const suffix = includePercent ? '%' : '';
    return `${formatInteger(value, '0')}${suffix}`;
  } else if (contentType === 'money') {
    return formatMoneyOrNull(value, '0.00');
  } else if (contentType === 'checkmark') {
    return value ? <i className="fa fa-check"></i> : <i className="fa fa-remove"></i>;
  }

  return value || '';
}
