import * as _ from 'lodash';

/**
 * Use this when you need to make sure your number is an int (it's expected
 * to be, but because of JavaScript, it might actual be 239.99999997).
 */
export const roundToInt = (num: number): Int => _.round(num, 0) as Int;
export const roundToIntOrNil = (num: number | undefined | null): Int | undefined | null => _.isNil(num) ? num :  _.round(num, 0) as Int;

/**
 * Use this when you aren't sure if it's an int and you want to know.
 */
export const checkIsInt = (num: number): num is Int =>  num % 1 === 0;

/**
 * Use this when you need to enforce that the value is an Int when
 * it's expected to be (like something coming out of an integer
 * postgres column)
 */
export const assertAsInt = (num: number): Int => {
  try {
    if (checkIsInt(num)) {
      return num;
    }
  } catch (err) {
    throw new Error(`Invalid Int value (error): ${num}`);
  }

  throw new Error(`Invalid Int value: ${num}`);
};

/**
 * Parses the string into an Int (truncating if it's a floating point number)
 */
export const toInt = (value: string): Int => {
  return Number.parseInt(value) as Int;
};
