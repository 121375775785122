import { login, getCurrentUserInfo, logout } from '../api';

import {
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  FORM_RESET,
  LOGIN_SESSION_LOADED,
  LOGIN_SESSION_NOT_FOUND,
} from '../constants';

export function loginUser() {
  return (dispatch, getState) => {
    const user = {
      username: getState().form.login.values.username,
      password: getState().form.login.values.password,
    };

    return dispatch({
      types: [
        LOGIN_USER_PENDING,
        LOGIN_USER_SUCCESS,
        LOGIN_USER_ERROR,
      ],
      payload: {
        promise: login(user)
          .then(res => {
            dispatch({
              type: FORM_RESET,
              form: 'login',
            });

            return res;
          }),
      },
    });
  };
}

export function logoutUser() {
  return async dispatch => {
    dispatch({ type: LOGOUT_USER });
    try {
      const result = await logout();
      if (result && result.redirectUrl) {
        window.location.href = result.redirectUrl;
      } else {
        window.location.href = '/';
      }
      // window.location.href = result.redirectUrl;
    } catch (error) {
      console.info('Problem logging out', error);
      window.location.href = '/';
    }
  };
}

export function appMounted() {
  return async dispatch => {
    try {
      const userInfo = await getCurrentUserInfo();
      dispatch({ type: LOGIN_SESSION_LOADED, payload: userInfo });
    } catch (error) {
      console.error('Error getting current user info', error);
      dispatch({ type: LOGIN_SESSION_NOT_FOUND });
    }
  };
}
