import * as _ from 'lodash';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { INPUT_TYPES, Carrier, RouteType, DropoffLocation, LoadType, DateStr, toMoneyStr, MoneyStr, LoadDestination, addMoney, LoadOrigin } from 'shared/types';
import { propToComponent } from 'client/hoc/hoc';
import * as State from 'client/state/state';
import { msyncQuery } from 'client/hoc/graphql/query';
import { Thunker } from 'client/types/redux-types';
import { WithWarnUnsaved } from 'client/hoc/with-warn-unsaved';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as FormHelpers from 'client/helpers/form-helpers';
import { Form, FormGroup } from 'client/components/third-party';
import { LabeledInput, LabeledDate, LabeledSelect, LabeledRadio } from 'client/components/form';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import { REQUIRED_FIELD_VALIDATOR } from 'shared/validators';
import { FormField, FormFieldCalculationArgs } from 'client/components/form';
import { calculateTotalDropFee, calculateTotalMilesFee, calculateTotalFuelSurcharge } from 'shared/app/routing/rate-calculators';
import {
  DisplayValue,
  EditRoutingLoadResponse,
  EditRoutingLoadInput,
  RoutingLoadId,
  RoutingLoadIdentifier,
  RoutingLoadMfcAreaIdentifiers,
  RoutingLoadTrailerNumber,
  RoutingLoadLoadType,
  RoutingLoadTailgateFee,
  RoutingLoadTotalFee,
  RoutingLoadTotalFuelSurcharge,
  RoutingLoadTotalMilesFee,
  RoutingLoadTotalDropFee,
  RoutingLoadInvoiceNumber,
  RoutingLoadNotes,
  RoutingLoadTrailerTemperature,
  RoutingLoadCarrier,
  DropRateTableRow,
  RoutingLoadGQLResult,
  RoutingLoadCustomerNames,
  RoutingLoadDeliveryDate,
  RoutingLoadDrops,
  MileageRateTableRow,
  RoutingLoadTotalMiles,
  TailgateFeeTableRow,
} from 'schema/routing-load/routing-load-graphql-types';

export interface RoutingLoadFormValues {
  // id: number;
  identifier: RoutingLoadIdentifier | null;
  customerNames?: string;
  mfcAreaIdentifiers: string | null;
  trailerNumber: string | null;
  loadType: string | null;
  carrier: string | null;
  tailgateFee: MoneyStr | null;
  totalFee: MoneyStr | null;
  totalFuelSurcharge: MoneyStr | null;
  totalMilesFee: MoneyStr | null;
  totalDropFee: MoneyStr | null;
  invoiceNumber: string | null;
  notes: string | null;
  routeType: RouteType | null;
  deliveryDate: DateStr | null;
  dropoffLocation: DropoffLocation | null;
  origin: LoadOrigin | null;
  destination: LoadDestination | null;
  trailerTemperature: RoutingLoadTrailerTemperature | null;
  firstDropRate: MoneyStr | null;
  restDropRate: MoneyStr | null;
  additionalDrops: number | null;
  additionalMiles: number | null;
  fuelSurchargeRate: MoneyStr | null;
  miscFees: MoneyStr | null;
  claimNumber: string | null;
  peakOrderNumber: string | null;
  mtiOrderNumber: string | null;
  driverName: string | null;
}

interface RoutingLoadSidebarQueryResponse {
  routingLoadSidebarQueryResult?: {
    routingLoad: {
      id: RoutingLoadId;
      identifier: RoutingLoadIdentifier;
      customerNames: RoutingLoadCustomerNames;
      mfcAreaIdentifiers: RoutingLoadMfcAreaIdentifiers;
      trailerNumber: RoutingLoadTrailerNumber;
      loadType: DisplayValue<RoutingLoadLoadType>;
      carrier: DisplayValue<RoutingLoadCarrier>;
      tailgateFee: RoutingLoadTailgateFee;
      totalFee: RoutingLoadTotalFee;
      totalFuelSurcharge: RoutingLoadTotalFuelSurcharge;
      totalMilesFee: RoutingLoadTotalMilesFee;
      totalDropFee: RoutingLoadTotalDropFee;
      invoiceNumber: RoutingLoadInvoiceNumber;
      notes: RoutingLoadNotes;
      routeType: DisplayValue<RouteType>;
      deliveryDate: RoutingLoadDeliveryDate;
      dropoffLocation: DropoffLocation;
      origin: LoadOrigin;
      destination: LoadDestination;
      trailerTemperature: RoutingLoadTrailerTemperature;
      drops: RoutingLoadGQLResult['drops'];
      firstDropRate: RoutingLoadGQLResult['firstDropRate'];
      restDropRate: RoutingLoadGQLResult['restDropRate'];
      additionalDrops: RoutingLoadGQLResult['additionalDrops'];
      additionalMiles: RoutingLoadGQLResult['additionalMiles'];
      fuelSurchargeRate: RoutingLoadGQLResult['fuelSurchargeRate'];
      miscFees: RoutingLoadGQLResult['miscFees'];
      claimNumber: RoutingLoadGQLResult['claimNumber'];
      peakOrderNumber: RoutingLoadGQLResult['peakOrderNumber'];
      mtiOrderNumber: RoutingLoadGQLResult['mtiOrderNumber'];
      driverName: RoutingLoadGQLResult['driverName'];
      miles: RoutingLoadGQLResult['miles'];

      dropRateTable: DropRateTableRow[];
      mileageRateTable: RoutingLoadGQLResult['mileageRateTable'];
      tailgateFeeTable: RoutingLoadGQLResult['tailgateFeeTable'];
    };
  };
}

const RoutingLoadSidebarQuery = gql`
  query routingLoadSidebarQuery($routingLoadId: Int!) {
    routingLoadSidebarQueryResult: GetRoutingLoad(routingLoadId: $routingLoadId) {
      routingLoad {
        id
        identifier
        customerNames
        mfcAreaIdentifiers,
        trailerNumber,
        loadType {
          rawValue
          displayValue
        }
        carrier {
          rawValue
          displayValue
        }
        tailgateFee
        totalFee
        totalFuelSurcharge
        totalMilesFee
        totalDropFee
        invoiceNumber
        routeType {
          rawValue
          displayValue
        }
        notes
        deliveryDate
        dropoffLocation
        origin
        destination
        trailerTemperature
        drops
        firstDropRate
        restDropRate
        additionalDrops
        additionalMiles
        fuelSurchargeRate
        miscFees
        claimNumber
        peakOrderNumber
        mtiOrderNumber
        driverName
        miles
        dropRateTable {
          carrier
          loadType
          firstDropRate
          restDropRate
        }
        tailgateFeeTable {
          carrier
          loadType
          tailgateFee
        }
        mileageRateTable {
          carrier
          loadType
          routeType
          chargeType
          startMiles
          endMiles
          rate
        }
      }
    }
  }
`;

interface UIProps {
  handleSubmit: React.EventHandler<React.FormEvent<Form>>;
  dropRateTable?: DropRateTableRow[];
  mileageRateTable?: MileageRateTableRow[];
  tailgateFeeTable?: TailgateFeeTableRow[];
  drops: RoutingLoadDrops;
  miles: RoutingLoadTotalMiles;
  changeFieldValue(field: string, value: string | number | null);
  routeType?: RouteType;
  destination?: LoadDestination;
  disableAdditionalMilesField: boolean;
}

const optionFormatter = option => option.label;
const dropoffLocationOptions = [
  { id: DropoffLocation.Outdoor, label: 'Outdoor' },
  { id: DropoffLocation.Indoor, label: 'Indoor' },
];

interface FormValues {
  drops?: string;
  firstDropRate?: string;
  restDropRate?: string;
  additionalDrops?: string;
  carrier?: RoutingLoadCarrier;
  loadType?: LoadType;
  routeType?: RouteType;
  totalDropFee?: string;
  totalMilesFee?: string;
  additionalMiles?: string;
  fuelSurchargeRate?: string;
  totalFuelSurcharge?: string;
  tailgateFee?: string;
  miscFees?: string;
}

const formName = 'loadDetailsHeaderForm';
const parseIntOrZero = (valueString?: string) => {
  if (!valueString) { return 0; }
  const value = Number.parseInt(valueString);
  return _.isNaN(value) ? 0 : value;
};

const parseMoneyOrZero = (valueString?: string) => {
  if (!valueString) { return toMoneyStr(0); }
  const value = Number.parseFloat(valueString);
  return _.isNaN(value) ? toMoneyStr(0) : toMoneyStr(value);
};

class LoadDetailsSidebarUI extends React.PureComponent<UIProps, {}> {
  private dropRateTable?: DropRateTableRow[];
  private mileageRateTable?: MileageRateTableRow[];
  private tailgateFeeTable?: TailgateFeeTableRow[];
  handleCarrierChange = (value, formValues: FormValues) => {
    if (!_.isNil(this.dropRateTable)) {
      const dropRates = this.dropRateTable.find(r => r.carrier === value && r.loadType === formValues.loadType);
      if (!_.isNil(dropRates)) {
        this.props.changeFieldValue('firstDropRate', toMoneyStr(dropRates.firstDropRate));
        this.props.changeFieldValue('restDropRate', toMoneyStr(dropRates.restDropRate));
      }
    }

    if (!_.isNil(this.tailgateFeeTable)) {
      const tailgateFeeResult = this.tailgateFeeTable.find(r => r.carrier === value && r.loadType === formValues.loadType);
      if (!_.isNil(tailgateFeeResult)) {
        this.props.changeFieldValue('tailgateFee', toMoneyStr(tailgateFeeResult.tailgateFee));
      }
    }

    this.props.changeFieldValue('carrier', value);
  }

  handleLoadTypeChange = (value, formValues: FormValues) => {
    if (!_.isNil(this.dropRateTable)) {
      const dropRates = this.dropRateTable.find(r => r.carrier === formValues.carrier && r.loadType === value);
      if (!_.isNil(dropRates)) {
        this.props.changeFieldValue('firstDropRate', toMoneyStr(dropRates.firstDropRate));
        this.props.changeFieldValue('restDropRate', toMoneyStr(dropRates.restDropRate));
      }
    }

    if (!_.isNil(this.tailgateFeeTable)) {
      const tailgateFeeResult = this.tailgateFeeTable.find(r => r.carrier === value && r.loadType === formValues.loadType);
      if (!_.isNil(tailgateFeeResult)) {
        this.props.changeFieldValue('tailgateFee', toMoneyStr(tailgateFeeResult.tailgateFee));
      }
    }

    this.props.changeFieldValue('loadType', value);
  }

  calculateTotalDropFee = (formValues: FormValues, initialValues: FormValues, calculationArgs?: FormFieldCalculationArgs): MoneyStr => {
    if (!formValues) { return toMoneyStr(0); }
    if (_.isNil(formValues.firstDropRate) || _.isNil(formValues.restDropRate)) {
      return parseMoneyOrZero(initialValues.totalDropFee);
    }

    const additionalDrops = parseIntOrZero(formValues.additionalDrops);
    const firstDropRate = parseMoneyOrZero(formValues.firstDropRate);
    const restDropRate = parseMoneyOrZero(formValues.restDropRate);
    const totalDrops = (calculationArgs?.drops ?? 0) + additionalDrops;
    return calculateTotalDropFee(firstDropRate, restDropRate, totalDrops);
  }

  calculateTotalMilesFee = (formValues: FormValues, initialValues: FormValues, calculationArgs?: FormFieldCalculationArgs): MoneyStr => {
    if (!formValues) { return toMoneyStr(0); }
    if (_.isNil(formValues.carrier) || _.isNil(formValues.loadType) || _.isNil(this.mileageRateTable) || _.isNil(initialValues.routeType)) {
      return parseMoneyOrZero(initialValues.totalMilesFee);
    }

    const additionalMiles = parseIntOrZero(formValues.additionalMiles);
    if (!calculationArgs) {
      throw new Error('Must specify miles in the calculation args for total fuel surcharge calculation');
    }

    const miles = calculationArgs.miles;
    return calculateTotalMilesFee({
      carrier: formValues.carrier,
      loadType: formValues.loadType,
      routeType: initialValues.routeType,
      mileageRateTable: this.mileageRateTable,
      totalMiles: miles + additionalMiles,
    });
  }

  calculateTotalFuelSurcharge = (formValues: FormValues, initialValues: FormValues, calculationArgs?: FormFieldCalculationArgs): MoneyStr => {
    if (!formValues) { return toMoneyStr(0); }
    if (_.isNil(formValues.fuelSurchargeRate)) {
      return parseMoneyOrZero(initialValues.totalFuelSurcharge);
    }

    if (!calculationArgs) {
      throw new Error('Must specify miles in the calculation args for total fuel surcharge calculation');
    }

    const additionalMiles = parseIntOrZero(formValues.additionalMiles);
    const miles = calculationArgs.miles;
    return calculateTotalFuelSurcharge(parseMoneyOrZero(formValues.fuelSurchargeRate), miles + additionalMiles);
  }

  calculateTotalFee = (formValues: FormValues) => {
    const totalDropFee = parseMoneyOrZero(formValues.totalDropFee);
    const totalFuelSurcharge = parseMoneyOrZero(formValues.totalFuelSurcharge);
    const totalMilesFee = parseMoneyOrZero(formValues.totalMilesFee);
    const tailgateFee = parseMoneyOrZero(formValues.tailgateFee);
    const miscFees = parseMoneyOrZero(formValues.miscFees);
    return addMoney(totalDropFee, totalFuelSurcharge, totalMilesFee, tailgateFee, miscFees);
  }

  constructor(props: UIProps) {
    super(props);
  }

  componentWillReceiveProps(nextProps: UIProps) {
    if (!this.dropRateTable && nextProps.dropRateTable) {
      this.dropRateTable = nextProps.dropRateTable;
    }

    if (!this.mileageRateTable && nextProps.mileageRateTable) {
      this.mileageRateTable = nextProps.mileageRateTable;
    }

    if (!this.tailgateFeeTable && nextProps.tailgateFeeTable) {
      this.tailgateFeeTable = nextProps.tailgateFeeTable;
    }

    if (nextProps.routeType === RouteType.OneWay && nextProps.destination) {
      this.props.changeFieldValue('destination', null);
    }
  }

  render() {
    const f = (name: string, label: string, inputColSize: Int = 6, labelColSize: Int | undefined = 6, type: INPUT_TYPES = INPUT_TYPES.NUMBER, horizontalLabel: boolean = false) => ({
      formName, type, name, label, inputColSize, labelColSize, horizontalLabel,
      component: LabeledInput,
      testid: `load-details-sidebar-${_.kebabCase(name)}`,
    });

    return (
      <div className="mfc-form-sidebar">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <HiddenFocusField />
          <FormGroup>
            <FormField {...f('identifier', 'Load ID', 12, 12, INPUT_TYPES.TEXT)} required maxLength={10} normalize={FormHelpers.TrimString} />
          </FormGroup>
          <FormGroup>
            <FormField {...f('customerNames', 'Customer', 6, undefined, INPUT_TYPES.TEXT)} disabled required />
            <FormField {...f('mfcAreaIdentifiers', 'MFC Area', 6, undefined, INPUT_TYPES.TEXT)} disabled />
          </FormGroup>

          <FormGroup>
            <FormField {...f('trailerNumber', 'Trailer #', 6, 10, INPUT_TYPES.TEXT)} normalize={FormHelpers.TrimString} />
            <FormField {...f('deliveryDate', 'Store Delivery Date')} testid="load-details-sidebar-store-delivery-date" component={LabeledDate} required />
          </FormGroup>

          <FormGroup>
            <FormField {...f('loadType', 'Load Type')} component={LabeledSelect} offset={0} textFormatter={optionFormatter} valueField="value" options={[{ id: 'MADD', label: 'MADD' }, {id: 'SEAS', label: 'SEAS'}]} onChange={this.handleLoadTypeChange} required />
            <FormField {...f('dropoffLocation', 'Dropoff Loc.', )} clearable options={dropoffLocationOptions} component={LabeledSelect} valueField="value" textFormatter={optionFormatter} />
          </FormGroup>

          <FormGroup>
            <FormField {...f('routeType', 'Round Trip')} optionValue={RouteType.RoundTrip} component={LabeledRadio} />
            <FormField {...f('routeType', 'One-way')} optionValue={RouteType.OneWay} component={LabeledRadio} />
          </FormGroup>

          <FormGroup>
            <FormField {...f('origin', 'Origin')} component={LabeledSelect} offset={0} textFormatter={optionFormatter} valueField="value" options={[{ id: 'Masterpiece', label: 'Masterpiece' }, {id: 'Whitewater', label: 'Whitewater'}]} />
            {this.props.routeType === RouteType.RoundTrip &&
              <FormField {...f('destination', 'Destination')} component={LabeledSelect} offset={0} textFormatter={optionFormatter} valueField="value" options={[{ id: 'Masterpiece', label: 'Masterpiece' }, { id: 'Whitewater', label: 'Whitewater' }]} />
            }
          </FormGroup>

          <FormGroup>
            <FormField {...f('carrier', 'Carrier')} component={LabeledSelect} offset={0} textFormatter={optionFormatter} valueField="value" options={[{ id: Carrier.MTI, label: 'MTI' }, { id: Carrier.PEAK, label: 'Peak' }]} required validate={REQUIRED_FIELD_VALIDATOR} onChange={this.handleCarrierChange} />
            <FormField {...f('trailerTemperature', 'Trailer Temp', )} />
          </FormGroup>

          <FormGroup>
            <FormField {...f('firstDropRate', 'First Drop Rate', )} money />
            <FormField {...f('restDropRate', 'Rest Drop Rate', )} money />
          </FormGroup>

          <FormGroup>
            <FormField {...f('additionalDrops', 'Additional Drops', )} />
            <FormField {...f('totalDropFee', 'Total Drop Fee', )} money disabled calculation={this.calculateTotalDropFee} calculationArgs={{ drops: this.props.drops }} required />
          </FormGroup>

          <FormGroup>
            <FormField {...f('additionalMiles', 'Additional Miles', )} disabled={this.props.disableAdditionalMilesField} />
            <FormField {...f('totalMilesFee', 'Total Miles Fee', )} money disabled calculation={this.calculateTotalMilesFee} calculationArgs={{ miles: this.props.miles }} required />
          </FormGroup>

          <FormGroup>
            <div className="load-details-sidebar-fuel-surcharge-input">
              <FormField {...f('fuelSurchargeRate', 'Fuel Surch. Rate', 6, 6, INPUT_TYPES.TEXT)} money />
            </div>

            <FormField {...f('totalFuelSurcharge', 'Total Fuel Surcharge', )} money disabled calculation={this.calculateTotalFuelSurcharge} calculationArgs={{ miles: this.props.miles }} required />
          </FormGroup>

          <FormGroup>
            <FormField {...f('tailgateFee', 'Tailgate Fee', )} money maxValue={999.99} />
            <FormField {...f('miscFees', 'Misc Fees', )} money />
          </FormGroup>

          <FormGroup>
            <FormField {...f('totalFee', 'Total Fee', )} money disabled calculation={this.calculateTotalFee} required />
            <FormField {...f('invoiceNumber', 'Invoice Number', 6, 6, INPUT_TYPES.TEXT)} maxLength={6} />
          </FormGroup>

          <FormGroup>
            <FormField {...f('peakOrderNumber', 'Peak Order #', 6, 6, INPUT_TYPES.TEXT)} disabled required />
            <FormField {...f('mtiOrderNumber', 'MTI Order #', 6, 6, INPUT_TYPES.TEXT)} disabled required />
          </FormGroup>

          <FormGroup>
            <FormField {...f('claimNumber', 'Claim #', 6, 6, INPUT_TYPES.TEXT)} />
            <FormField {...f('driverName', 'Driver Name', 6, 6, INPUT_TYPES.TEXT)} disabled required />
          </FormGroup>

          <FormGroup>
            <FormField {...f('notes', 'Notes', 12, 12, INPUT_TYPES.TEXTAREA)} />
          </FormGroup>
        </Form>
      </div>
    );
  }
}

// DVM 2020 06 09 - I don't know what these commented out things were doing here before... but I'll keep them in case it is an incomplete thought that will be helpful when a bug arises
// export type ActionTypes = SetApplyInvoiceToLoadsModalVisibilityAction |
// export enum ActionTypeKeys { LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY = 'App/LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY' }

// Note: this is not requesting the origin/destination/routeType as those can't be changed in this mutation.
// The reason we're even asking for all of this back is to avoid flicker when the form is reset, but before
// a refetched query has completed.
const EditRoutingLoadMutation = gql`
  mutation editRoutingLoad($input: EditRoutingLoadInput!) {
    editRoutingLoad: EditRoutingLoad(input: $input) {
      routingLoad {
        id
        identifier
        trailerNumber,
        loadType {
          rawValue
          displayValue
        }
        carrier {
          rawValue
          displayValue
        }
        tailgateFee
        totalFee
        totalFuelSurcharge
        totalMilesFee
        totalDropFee
        invoiceNumber
        notes
        deliveryDate
        dropoffLocation
        trailerTemperature
        firstDropRate
        restDropRate
        additionalDrops
        additionalMiles
        fuelSurchargeRate
        miscFees
        claimNumber
        lastModified
      }
    }
  }
`;

/**
 * This mutation is for saving changes that require going out to PC Miler
 * to get updated mileage data. It requests back fields that would be changed
 * by a change to the number of miles, along with the fields that are 'input'
 * fields (origin, destination, routeType) to prevent flicker when the form
 * is reset (needed because the user might have changed other non route-info
 * related fields and we want those to go back to what they were previously).
 */
const EditRoutingLoadRouteInfoMutation = gql`
  mutation EditRoutingLoadRouteInfo($input: EditRoutingLoadRouteInfoInput!) {
    response: EditRoutingLoadRouteInfo(input: $input) {
      routingLoad {
        id
        lastModified

        origin
        destination

        totalFee
        totalMilesFee
        routeType {
          rawValue
          displayValue
        }
      }
    }
  }
`;

const EDIT_ROUTING_LOAD_REFETCH_QUERIES = ['routingLoadStatsRowQuery', 'routingStopLoadTableListForDisplayToTheUserOnTheirScreen', 'routingLoadSidebarQuery'];

const loadDetailsSidebarFormSubmitted = (routingLoadId: RoutingLoadId, formValues: RoutingLoadFormValues, initialValues: RoutingLoadFormValues): Thunker =>
  async (dispatch: Dispatch<any>) => {
    const formChanges = FormHelpers.getFormChanges(formValues, initialValues);
    const {routeType, origin, destination, ...nonRouteInfoChanges} = formChanges;

    // FIRTST save any non-PC-Miler connected changes
    if (_.keys(nonRouteInfoChanges).length > 0) {
      await msyncClientMutation<EditRoutingLoadResponse>({
        mutation: EditRoutingLoadMutation,
        variables: { input: { id: routingLoadId, ..._.omit(nonRouteInfoChanges, 'customerNames') } },
        refetchQueries: EDIT_ROUTING_LOAD_REFETCH_QUERIES,
        awaitRefetchQueries: true,
        dispatch,
      });
    }

    // await timeout(5000);

    // Then save PC-Miler related changes.  These will require a recalculation of mileage - which will lead to changes in fees; and we don't want manual fee overrides in this change to stomp on those recalculated values
    if (routeType || origin || destination) {
      await msyncClientMutation<EditRoutingLoadResponse>({
        mutation: EditRoutingLoadRouteInfoMutation,
        variables: { input: { id: routingLoadId, ..._.omitBy({routeType, origin, destination}, _.isUndefined) } },
        refetchQueries: EDIT_ROUTING_LOAD_REFETCH_QUERIES,
        awaitRefetchQueries: true,
        dispatch,
      });
    }

  };

const WithRoutingLoadInitialValues = msyncQuery<RoutingLoadSidebarQueryResponse, OwnProps, WithInitialValuesProps>(RoutingLoadSidebarQuery, {
  alias: 'WithRoutingLoadInitialValues',
  options(ownProps: OwnProps): { variables: { routingLoadId: RoutingLoadId } } {
    return {
      variables: {
        routingLoadId: ownProps.routingLoadId,
      },
    };
  },
  props(props): WithInitialValuesProps {
    const { data } = props;
    if (data.loading || !data.routingLoadSidebarQueryResult) {
      return { drops: 0, miles: 0, disableAdditionalMilesField: true };
    }
    const routingLoad = data.routingLoadSidebarQueryResult.routingLoad;
    return {
      initialValues: {
        identifier: routingLoad.identifier || null,
        customerNames: routingLoad.customerNames,
        mfcAreaIdentifiers: routingLoad.mfcAreaIdentifiers,
        trailerNumber: routingLoad.trailerNumber || null,
        loadType: routingLoad.loadType.rawValue || null,
        carrier: routingLoad.carrier.rawValue || null,
        tailgateFee: !_.isNil(routingLoad.tailgateFee) ? toMoneyStr(routingLoad.tailgateFee) : null,
        totalFee: toMoneyStr(routingLoad.totalFee),
        totalFuelSurcharge: toMoneyStr(routingLoad.totalFuelSurcharge),
        totalMilesFee: toMoneyStr(routingLoad.totalMilesFee),
        totalDropFee: toMoneyStr(routingLoad.totalDropFee),
        invoiceNumber: routingLoad.invoiceNumber || null,
        notes: routingLoad.notes || null,
        routeType: routingLoad.routeType.rawValue || null,
        deliveryDate: routingLoad.deliveryDate || null,
        dropoffLocation: routingLoad.dropoffLocation || null,
        origin: routingLoad.origin || null,
        destination: routingLoad.destination || null,
        trailerTemperature: routingLoad.trailerTemperature || null,
        firstDropRate: !_.isNil(routingLoad.firstDropRate) ? toMoneyStr(routingLoad.firstDropRate) : null,
        restDropRate: !_.isNil(routingLoad.restDropRate) ? toMoneyStr(routingLoad.restDropRate) : null,
        additionalDrops: routingLoad.additionalDrops || null,
        additionalMiles: routingLoad.additionalMiles || null,
        fuelSurchargeRate: !_.isNil(routingLoad.fuelSurchargeRate) ? toMoneyStr(routingLoad.fuelSurchargeRate) : null,
        miscFees: !_.isNil(routingLoad.miscFees) ? toMoneyStr(routingLoad.miscFees) : null,
        claimNumber: routingLoad.claimNumber || null,
        peakOrderNumber: routingLoad.peakOrderNumber || null,
        mtiOrderNumber: routingLoad.mtiOrderNumber || null,
        driverName: routingLoad.driverName || null,
      },
      drops: routingLoad.drops || 0,
      miles: routingLoad.miles || 0,
      dropRateTable: routingLoad.dropRateTable,
      mileageRateTable: routingLoad.mileageRateTable,
      tailgateFeeTable: routingLoad.tailgateFeeTable,
      disableAdditionalMilesField: routingLoad.mileageRateTable ? false : true,
    };
  },
});

const WithReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  async onSubmit(formValues: RoutingLoadFormValues, dispatch, props: OwnProps & EditRoutingLoadMutationProps & WithInitialValuesProps): Promise<void> {
    if (props.initialValues) // I don't think there's any real way the form could be submitted without initial values, but the types are making me do this check.
      dispatch(loadDetailsSidebarFormSubmitted(props.routingLoadId, formValues, props.initialValues));
  },
});

interface EditRoutingLoadMutationProps { editRoutingLoad: (args: { variables: { input: EditRoutingLoadInput } }) => Promise<ApolloQueryResult<EditRoutingLoadResponse> | undefined> }
interface DispatchProps { changeFieldValue(field: string, value: string | number); }
interface StateProps { routeType?: RouteType; destination?: LoadDestination; }
interface FormValues { routeType?: RouteType; destination?: LoadDestination; }
interface WithInitialValuesProps {
  initialValues?: RoutingLoadFormValues;
  drops: RoutingLoadDrops;
  miles: RoutingLoadTotalMiles;
  dropRateTable?: DropRateTableRow[];
  mileageRateTable?: MileageRateTableRow[];
  tailgateFeeTable?: TailgateFeeTableRow[];
  disableAdditionalMilesField: boolean;
}
interface OwnProps { routingLoadId: RoutingLoadId; }
export type ComponentProps = OwnProps; // is this actually used?  shift F12 isn't giving me matches

const component = _.flowRight(
  WithRoutingLoadInitialValues,
  // connect<StateProps, DispatchProps, {}>(
  connect<StateProps, DispatchProps, OwnProps, State.Type>(
    (state: State.Type) => {
      const formValues = getFormValues(formName)(state) as FormValues;
      const destination: LoadDestination | undefined = formValues ? formValues.destination : undefined;
      return (!formValues || !formValues.routeType)
        ? { destination }
        : { destination, routeType: formValues.routeType };
    },
    dispatch => ({
      changeFieldValue: (field: string, value: string | number) => dispatch(change(formName, field, value)),
    })),
  WithReduxForm,
  WithWarnUnsaved({ recordIdProperty: 'routingLoadId' }),
)(LoadDetailsSidebarUI);

// TODO: any way to get types to properly flow through this if we don't use _.flowRight?
// const res1 = WithRoutingLoadInitialValues(LoadDetailsSidebarUI);
// const res3 = WithReduxForm(res2);
// DVM 2020 06 10 : no. flowRight, flow, R.pipe -- all add extraneous indirection and mangle type hints, making them counter-productive except in wird places where the alternative is much worse and refactoring is not possible
export const LoadDetailsSidebar = (props: OwnProps) => propToComponent(component, props);

/** Constrain test-induced design damage : these things are needed for tests, but not anywhere else but in this module. */
export const TESTING_EXPORTS = {EditRoutingLoadMutation, EditRoutingLoadRouteInfoMutation, loadDetailsSidebarFormSubmitted};
