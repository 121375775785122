import * as React from 'react';
import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as ReportUserParamMapperInjector from 'client/components/report-user-params/mapper-injector';
import * as SharedTypes from 'shared/types';
import * as UserParamTypes from 'client/components/report-user-params/types';
import { makeMapToReportUserParams } from 'client/helpers/form-helpers';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as ReportSelectors from 'client/state/reports';
import { extractSelectedValues } from 'client/components/selectable/selectable';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as SchemaTypes from 'schema/planning-report/types';
import { orThrow } from 'shared/helpers';
import gql from 'graphql-tag';
import { Form, Col, Row, FormGroup, Tabs, Tab } from 'client/components/third-party';
import { SelectableRow } from 'client/components/selectable/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import * as Components from './components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { DropDownOptions } from 'client/types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES, ReportTypes, SelectableValue } from 'shared/types';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import { FindAllSalesPlansQueryResponse, FindAllSalesPlansQuery, FindAllProductClassesQueryResponse, FindAllProductClassesQuery, applyFilterIfListHasValues } from 'client/app/reports/shared/query';

const formName = 'hardgoodsDataExport';
const reportType = ReportTypes.HardgoodsDataExport;
enum FormFields {
  salesPlanId = 'salesPlanId',
  supplierId = 'supplierId',
  scanBased = 'scanBased',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  productIds = 'productIds',
}

interface FormValues {
  [FormFields.salesPlanId]: number | undefined;
  [FormFields.supplierId]: number | undefined | null;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
}

interface SalesPlanQueryProps {
  salesPlanOptions: DropDownOptions;
  salesPlansLoading: boolean;
  customerIdsBySalesPlanId: { [salesPlanId: number]: number };
}

const withSalesPlans = msyncQuery<FindAllSalesPlansQueryResponse, { customerId?: number }, SalesPlanQueryProps, {}>(FindAllSalesPlansQuery, {
  alias: 'withSalesPlans',
  options(ownProps) {
    return {
      variables: { sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year' }] },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SalesPlanQueryProps {
    const { data } = props;
    if (data.loading || data.salesPlans === undefined)
      return { salesPlanOptions: [], salesPlansLoading: data.loading, customerIdsBySalesPlanId: {} };

    const customerIdsBySalesPlanId = data.salesPlans.reduce((acc, salesPlan) => Object.assign(acc, {[salesPlan.id]: salesPlan.customerId}), {});
    return {
      salesPlanOptions: data.salesPlans.map(salesPlan => ({
        id: salesPlan.id,
        value: `${salesPlan.customerIdentifier} - ${salesPlan.year} - ${salesPlan.sellDepartmentIdentifier} - ${salesPlan.identifier}`,
        identifier: salesPlan.identifier,
      })),
      salesPlansLoading: data.loading,
      customerIdsBySalesPlanId,
    };
  },
});

interface SuppliersQueryResponse {
  supplierCommitments?: {
    supplierCommitments: Array<{
      supplier: {
        id: number,
        name: string,
        identifier: string,
      },
      supplierCommitmentProducts: Array<{
        id: number,
        supplierItem: {
          id: number,
          product: {
            id: number,
          },
        },
      }>,
    }>,
  };
}

const SuppliersQuery = gql`
  query SuppliersFromCommitmentsForSalesPlanQuery($filters: [FilterSpecificationInput!]!) {
    supplierCommitments: GetSupplierCommitments (filters: $filters) {
      supplierCommitments {
        id
        salesPlan {
          id
        }
        supplier {
          id
          name
          identifier
        }
        supplierCommitmentProducts {
          id
          supplierItem {
            id
            product {
              id
            }
          }
        }
      }
    }
  }
`;

interface SupplierQueryProps {
  supplierOptions: DropDownOptions,
  suppliersLoading: boolean,
  productIdsBySupplierId: {[productId: number]: number[]},
}

const withSuppliers = msyncQuery<SuppliersQueryResponse, { salesPlanId?: number }, SupplierQueryProps, {}>(SuppliersQuery, {
  alias: 'withSuppliers',
  options(ownProps) {
    return {
      variables: { filters: [{field: 'salesPlanId', values: [ownProps.salesPlanId]}] },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SupplierQueryProps {
    const { data } = props;
    if (data.loading || (data.supplierCommitments?.supplierCommitments.length ?? -1) < 0)
      return { supplierOptions: [], suppliersLoading: data.loading, productIdsBySupplierId: {} };

    return {
      supplierOptions: _.flatMap(data.supplierCommitments?.supplierCommitments, sc => ({
        id: sc.supplier.id,
        value: `${sc.supplier.identifier} - ${sc.supplier.name}`,
        identifier: sc.supplier.identifier,
      })),
      suppliersLoading: data.loading,
      productIdsBySupplierId: _(data.supplierCommitments?.supplierCommitments)
          .flatMap(sc => sc.supplierCommitmentProducts.map(scp => ({supplierId: sc.supplier.id, productId: scp.supplierItem.product.id})))
          .groupBy(x => x.supplierId)
          .mapValues(v => v.map(x => x.productId))
          .value(),
    };
  },
});

interface ProductClassQueryProps {
  productClassOptions?: SelectableRow[];
  productClassesLoading?: boolean;
}

const withProductClasses = msyncQuery<FindAllProductClassesQueryResponse, {
  salesPlanId?: number,
  possibleClassIds?: SelectableValue,
  customerIdsBySalesPlanId: { [salesPlanId: number]: number },
}, ProductClassQueryProps, {}>(FindAllProductClassesQuery, {
  alias: 'withProductClasses',
  skip(ownProps) { return ownProps.salesPlanId === undefined || ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId] === undefined; },
  options(ownProps) {
    return {
      variables: {
        filters: [ ...applyFilterIfListHasValues('id', ownProps.possibleClassIds) ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): ProductClassQueryProps {
    const { data } = props;
    if (data.loading || data.productClasses === undefined)
      return { productClassOptions: [], productClassesLoading: data.loading };

    return {
      productClassOptions: data.productClasses.map(productClass => ({
        id: productClass.id,
        cells: [ productClass.identifier, productClass.sellDepartment.identifier ],
      })),
      productClassesLoading: data.loading,
    };
  },
});

interface FindAllProductsQueryResponse {
  salesPlanProducts?: Array<{
    id: number;
    product: {
      id: number;
      identifier: string;
      replenishmentIdentifier: string;
      description: string;
    };
  }>;
}

const FindAllSalesPlanProductsAsProductsQuery = gql`
  query FindAllSalesPlanProductsAsProductsHardgoodsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlanProducts: findAll(type: SalesPlanProduct, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlanProduct {
        id
        product {
          id
          identifier
          replenishmentIdentifier
          description
        }
      }
    }
  }
`;

interface ProductQueryProps { productOptions?: SelectableRow[], productsLoading?: boolean }
const withProducts = (opts?: { onlyIncludeParentReplenishmentProducts?: boolean }) => {
  return msyncQuery<FindAllProductsQueryResponse, {
    salesPlanId?: number,
    supplierId?: number,
    productIdsBySupplierId?: {[supplierId: number]: number[]},
    customerIdsBySalesPlanId: { [salesPlanId: number]: number },
    productClassIds?: SelectableValue,
    productSubClassIds?: SelectableValue,
    scanProductOptions?: boolean[]
  }, ProductQueryProps, {}>(FindAllSalesPlanProductsAsProductsQuery, {
    alias: 'withProducts',
    skip(ownProps) { return ownProps.salesPlanId === undefined || ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId] === undefined; },
    options(ownProps) {
      const customerId = ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId!];
      return {
        variables: {
          filters: [
            ...(opts && opts.onlyIncludeParentReplenishmentProducts ? [{ field: 'isItsOwnReplenishmentProduct', values: [true] }] : []),
            { field: 'customer', values: [customerId] },
            ...applyFilterIfListHasValues('salesPlan', [ownProps.salesPlanId]),
            // ...applyFilterIfListHasValues('supplier', [ownProps.supplierId]),
            ...applyFilterIfListHasValues('productClass', ownProps.productClassIds),
            ...applyFilterIfListHasValues('productSubClass', ownProps.productSubClassIds),
            ...applyFilterIfListHasValues('scanProduct', ownProps.scanProductOptions),
          ],
          sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
        },
        fetchPolicy: 'network-only',
      };
    },
    props(props): ProductQueryProps {
      const { data } = props;
      if (data.loading || data.salesPlanProducts === undefined)
        return { productOptions: [], productsLoading: data.loading };

      const supplierFocused = props.ownProps.productIdsBySupplierId?.[props.ownProps.supplierId ?? -1];
      const uniqueProducts = _.uniqBy(data.salesPlanProducts.filter(x => supplierFocused?.includes(x.product.id) ?? true).map(salesPlanProduct => salesPlanProduct.product), product => product.id);
      return {
        productOptions: uniqueProducts.map(product => ({ id: product.id, cells: [product.identifier, product.description] })),
        productsLoading: data.loading,
      };
    },
  });
};

interface FindAllSalesPlanProductsQueryResponse {
  salesPlanProducts?: Array<{
    id: number;
    product: {
      id: number;
      productSubClass: {
        id: number;
        productClass: {
          id: number;
        };
      };
    };
  }>;
}

const FindAllSalesPlanProductsQuery = gql`
  query FindAllSalesPlanProductsHardgoodsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlanProducts: findAll(type: SalesPlanProduct, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlanProduct {
        id
        product {
          id
          productSubClass {
            id
            productClass {
              id
            }
          }
        }
      }
    }
  }
`;

interface SalesPlanProductsQueryProps { possibleSubClassIds: number[], possibleClassIds: number[] }
const withSalesPlanProducts = msyncQuery<FindAllSalesPlanProductsQueryResponse, { salesPlanId?: number }, SalesPlanProductsQueryProps, {}>(FindAllSalesPlanProductsQuery, {
  alias: 'withSalesPlanProducts',
  skip(ownProps) { return !ownProps.salesPlanId; },
  options(ownProps) { return { fetchPolicy: 'network-only', variables: { filters: [ ...applyFilterIfListHasValues('salesPlanId', [ownProps.salesPlanId]) ] } }; },
  props(props) {
    const { data } = props;
    if (data.loading || data.salesPlanProducts === undefined)
      return { possibleSubClassIds: [], possibleClassIds: [] };

    const uniqueProducts = _.uniqBy(data.salesPlanProducts.map(salesPlanProduct => salesPlanProduct.product), product => product.id);
    const uniqueSubClasses = _.uniqBy(uniqueProducts.map(product => product.productSubClass), subClass => subClass.id);
    const uniqueClasses = _.uniqBy(uniqueSubClasses.map(subClass => subClass.productClass), productClass => productClass.id);
    return {
      possibleSubClassIds: idsFor(uniqueSubClasses),
      possibleClassIds: idsFor(uniqueClasses),
    };
  },
});

const HardgoodsDataExportUI = (p: {
  handleDownloadExcelReportClicked(): void;
  reportDownloadStatus: MutationStatus,
  downloadButtonDisabled: boolean,
  customerOptions: DropDownOptions,
  supplierOptions: DropDownOptions,
  scanBased: boolean,
  poBased: boolean,
  salesPlanOptions: DropDownOptions,
  productClassOptions?: SelectableRow[],
  productSubClassOptions?: SelectableRow[],
  productOptions?: SelectableRow[],
  productClassesLoading?: boolean,
  productSubClassesLoading?: boolean,
  productsLoading?: boolean,
  mapToReportUserParams: MapToReportUserParams,
  mapFromReportUserParams: MapFromReportUserParams,
  selectedPresetId?: number,
  isLoadingPreset: boolean,
  pristine: boolean,
}) => (
  <div>
    <RecordBar title="Hardgoods Data Export" hideBackButton />
    <div id="mfc-page-content" className="mfc-page-content mfc-form">
      <div className="mfc-form-details-with-sidebar-but-no-tabs planning-reports-supplier-performance-table-wrapper  mfc-scrolling-region-adjacent-to-sidebar">
        <Form horizontal>
          <Row className="report-fields-outside-tabs">
            <Col sm={12}>
              <FormGroup>
                <Components.SalesPlan name={FormFields.salesPlanId} options={p.salesPlanOptions} label={'Sales Plan'} colSize={4} />
              </FormGroup>
              <FormGroup>
                <Components.Supplier name={FormFields.supplierId} options={p.supplierOptions} label={'Supplier [optional]'} colSize={4} />
              </FormGroup>
            </Col>
          </Row>
          <Row className="report-fields-outside-tabs">
            <Col sm={6}>
              <Tabs className="report-tabs" id="product-tabs">
                <Tab disabled={true} title="Products">
                  <FormGroup className="report-product-order-type-area">
                    <label className="report-scan-or-po-based-label">Product Order Type</label>
                    <div>
                      <Components.ScanBased name={FormFields.scanBased} />
                      <Components.PoBased name={FormFields.poBased} />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Components.ProductClass name={FormFields.productClassIds} options={p.productClassOptions} loading={p.productClassesLoading} />
                  </FormGroup>
                  <FormGroup>
                    <Components.ProductSubClass name={FormFields.productSubClassIds} options={p.productSubClassOptions} loading={p.productSubClassesLoading} />
                  </FormGroup>
                  <FormGroup>
                    <Components.Product name={FormFields.productIds} options={p.productOptions} loading={p.productsLoading} />
                  </FormGroup>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Form>
      </div>
      <Components.Sidebar
        reportType={ReportTypes.HardgoodsDataExport}
        downloadButtonDisabled={p.downloadButtonDisabled}
        handleDownloadExcelReportClicked={p.handleDownloadExcelReportClicked}
        mapToReportUserParams={p.mapToReportUserParams}
        mapFromReportUserParams={p.mapFromReportUserParams}
        pristine={p.pristine}
        excelDownloadStatus={p.reportDownloadStatus}
        reportFormName={formName}
        selectedPresetId={p.selectedPresetId}
        isLoadingPreset={p.isLoadingPreset}
      />
    </div>
  </div >
);

const generateHardgoodsDataExport = gql`
mutation generateHardgoodsDataExport($generateHardgoodsDataExportInput: GenerateHardgoodsDataExportInput) {
  response: GenerateHardgoodsDataExport(generateHardgoodsDataExportInput: $generateHardgoodsDataExportInput) {
    id
  }
}
`;

const mapStateToProps = (state: State.Type) => {
  const formValues = getFormValues(formName)(state) as FormValues;
  return {
    salesPlanId           : !formValues ? undefined              : formValues[FormFields.salesPlanId]       ,
    supplierId            : !formValues ? undefined              : formValues[FormFields.supplierId]        ,
    scanBased             : !formValues ? false                  : formValues[FormFields.scanBased]         ,
    poBased               : !formValues ? false                  : formValues[FormFields.poBased]           ,
    productClassIds       : !formValues ? undefined              : formValues[FormFields.productClassIds]   ,
    productSubClassIds    : !formValues ? undefined              : formValues[FormFields.productSubClassIds],
    productIds            : !formValues ? undefined              : formValues[FormFields.productIds]        ,
    reportDownloadStatus  : !formValues ? MutationStatus.Initial : State.reportState.comp(ReportState.reportDownloadStatus).get(state),
    scanProductOptions    : !formValues ? []                     : ReportSelectors.getScanProductOptions(state, formName),
    downloadButtonDisabled
      :  _.isNil(formValues?.[FormFields.salesPlanId])
      || extractSelectedValues(formValues[FormFields.productIds]).length === 0,
  };
};

const component = _.flowRight(
  reportingForm({
    form: formName,
    reportType,
    initialValues: { },
  }),
  connect(
    mapStateToProps,
    {
      handleDownloadExcelReportClicked: (): Thunker => async (dispatch, getState) => {
        const state = getState();
        const formValues = getFormValues(formName)(state) as FormValues;
        const salesPlanId = formValues[FormFields.salesPlanId] ?? orThrow('Missing some required fields');
        const productIds = extractSelectedValues(formValues[FormFields.productIds]) ?? orThrow('Missing some required fields');
        dispatch(ReportActions.reportDownloadStarted());
        try {
          const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateHardgoodsDataExportInput>({
            mutation: generateHardgoodsDataExport,
            variables: { generateHardgoodsDataExportInput: { salesPlanId, productIds } },
            dispatch,
          }) ?? orThrow('Unable to generate report');

          window.location.replace(`/report/fileDownload/${response.data.response.id}`);
        } finally {
          dispatch(ReportActions.reportDownloadFinished());
        }
      },
    },
  ),
  withSalesPlans,
  withSuppliers,
  withSalesPlanProducts,
  withProductClasses,
  SharedQueries.withProductSubClasses,
  withProducts({ onlyIncludeParentReplenishmentProducts: true }),
  ReportUserParamMapperInjector.withReportUserParamMappers(
    makeMapToReportUserParams(formName, SharedTypes.ReportTypes.HardgoodsDataExport),
    ((dispatch, reportUserParams) => { /* Do nothing */ }) as UserParamTypes.MapFromReportUserParams
  ),
)(HardgoodsDataExportUI) as (p: {}) => JSX.Element;

export const HardgoodsDataExport = (props: {}) => propToComponent(component, props);
