import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, required, hasMany, setSchemaOptions } from './dsl';
import { SalesPlanIdentifier, DISPLAY_TYPES, TYPES } from 'shared/types';
import { Customer, CustomerId } from 'shared/schemas/customer';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { SellDepartment } from './sell-department';
import { SalesPlanProduct } from './sales-plan-product';

export type SalesPlanId = Flavor<number, 'salesPlanId'>;
@tableName('salesPlans')
export class SalesPlan implements IRecord {
  static readonly UniqueConstraints: Array<keyof SalesPlan> = ['customer', 'identifier', 'year'];

  id?: number;
  @property @required year: number;
  @property @required identifier: SalesPlanIdentifier;
  @property startWeek: number;
  @property endWeek: number;

  @belongsTo('customers')
  @property @required customer: Customer;
  customerId: CustomerId;

  @belongsTo('sellDepartments')
  @property @required sellDepartment: SellDepartment;
  sellDepartmentId: number;

  @hasMany('customerOrders')
  @property customerOrders?: Array<Partial<CustomerOrder>>;

  @hasMany('salesPlanProducts')
  @property salesPlanProducts?: Array<Partial<SalesPlanProduct>>;
}

setSchemaOptions(SalesPlan, {
  uniqueConstraints: SalesPlan.UniqueConstraints,
  softDeletable: false,
});

// Moved validators to shared/validations and server/validations

definePresentation(SalesPlan, {
  year: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.NUMBER,
    filterable: true,
    sortable: true,
  },
  identifier: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    sortable: true,
  },
  customer: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    filterable: true,
    sortable: true,
  },
  sellDepartment: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    filterable: false,
    sortable: false,
  },
  startWeek: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.NUMBER,
    calculationSpec: {
      dependencies: {},
      calculation: s => {
        return `(
          WITH week_number_query AS (
            SELECT sales_plan_products.week_number
            FROM sales_plan_products
            WHERE sales_plan_products.sales_plan_id = ${s.id}
            UNION ALL
            SELECT sales_plan_variety_details.week_number
            FROM sales_plan_variety_details
            JOIN sales_plan_varieties ON sales_plan_varieties.id = sales_plan_variety_details.sales_plan_variety_id
            WHERE sales_plan_varieties.sales_plan_id = ${s.id}
          )
          SELECT COALESCE(MIN(week_number_query.week_number), 0)
          FROM week_number_query
        )`;
      },
    },
  },
  endWeek: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.NUMBER,
    calculationSpec: {
      dependencies: {},
      calculation: s => {
        return `(
          WITH week_number_query AS (
            SELECT sales_plan_products.week_number
            FROM sales_plan_products
            WHERE sales_plan_products.sales_plan_id = ${s.id}
            UNION ALL
            SELECT sales_plan_variety_details.week_number
            FROM sales_plan_variety_details
            JOIN sales_plan_varieties ON sales_plan_varieties.id = sales_plan_variety_details.sales_plan_variety_id
            WHERE sales_plan_varieties.sales_plan_id = ${s.id}
          )
          SELECT COALESCE(MAX(week_number_query.week_number), 0)
          FROM week_number_query
        )`;
      },
    },
  },
});
