import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import UI, { OwnProps as UIProps } from './ui';
import { msyncQuery } from 'client/hoc/graphql/query';
import { InvoiceType, formatMoneyOrNull, formatInteger } from 'shared/types';
import * as Query from './query';
import { assertUnreachable } from 'shared/helpers/andys-little-helpers';
import assertCompatible from 'shared/helpers/assert-compatible';

interface OwnProps {
  invoiceId: number;
}

interface WithStatCardProps {
  loading?: boolean;
  invoice?: {
    type: InvoiceType.DropShip;
    storesDelivered: string;
    totalPrice: string;
  } | {
    type: InvoiceType.CustomerOrder;
    totalPacks: string;
    totalPieces: string;
    totalPrice: string;
  } | {
    type: InvoiceType.VendorChargeback;
    totalPacks: string;
    totalPieces: string;
    totalPrice: string;
  };
}

const WithStatCardsData = msyncQuery<Query.QueryResponse, OwnProps, WithStatCardProps, Query.Input>(Query.Query, {
  alias: 'WithStatCardsData',

  options(ownProps: OwnProps) {
    return {
      variables: {
        invoiceId: ownProps.invoiceId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props) {
    const { data } = props;
    if (data.loading || !data.getInvoice) {
      return {
        loading: true,
      };
    }
    const invoice = data.getInvoice.invoice;

    // NOTE: In case of performance issues, createSelector could be used to
    //       prevent new objects from being created here unecessarily
    switch (invoice.type) {
      case InvoiceType.CustomerOrder:
        return {
          loading: false,
          invoice: {
            type: invoice.type,
            totalPrice: formatMoneyOrNull(invoice.totalPrice),
            totalPieces: formatInteger(invoice.totalPieces),
            totalPacks: formatInteger(invoice.totalPacks),
          },
        };
      case InvoiceType.DropShip:
        return {
          loading: false,
          invoice: {
            type: invoice.type,
            totalPrice: formatMoneyOrNull(invoice.totalPrice),
            storesDelivered: formatInteger(invoice.storesDelivered),
          },
        };
      case InvoiceType.VendorChargeback:
        return {
          loading: false,
          invoice: {
            type: invoice.type,
            totalPacks: formatInteger(invoice.vendorChargebackTotalPacks),
            totalPieces: formatInteger(invoice.vendorChargebackTotalPieces),
            totalPrice: formatMoneyOrNull(invoice.totalPrice),
          },
        };
      default:
        return assertUnreachable(invoice);
    }
  },
});

export type ComponentProps =
  OwnProps & WithStatCardProps;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  WithStatCardsData,
)(UI);

export const StatsRowContainer = (props: OwnProps) => propToComponent(component, props);
