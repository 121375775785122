import { connect } from 'react-redux';
import * as Actions from 'client/actions/import-route';
import * as State from 'client/state/state';
import { propToComponent } from 'client/hoc/hoc';
import { ImportRouteModalContentStepOneUI, ComponentProps as UIProps } from './import-route-modal-step-one-ui';
import { flowRight, isNil } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { msyncQuery } from 'client/hoc/graphql/query';
import gql from 'graphql-tag';
import { LoadOrigin } from 'shared/types';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';

export interface OwnProps {
  routePlanId?: number;
}

interface StateProps {
  loadType: string;
  carrier: string;
  routeType: string;
  dropoffLocation: string;
  origin: string;
  destination: string;
  trailerTemperature: string;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps & RoutePlanOriginQueryProps): StateProps => {
  const loadsInput = State.importRouteRouteInformation(state);

  return {
    loadType: loadsInput.loadType || '',
    carrier: loadsInput.carrier || '',
    routeType: loadsInput.routeType || '',
    dropoffLocation: loadsInput.dropoffLocation || '',
    origin: loadsInput.origin || '',
    destination: loadsInput.destination || '',
    trailerTemperature: isNil(loadsInput.trailerTemperature) ? '' : `${loadsInput.trailerTemperature}`,
  };
};

interface DispatchProps {
  loadTypeChanged: (value: shame) => void;
  carrierChanged: (value: shame) => void;
  routeTypeChanged: (value: shame) => void;
  dropoffLocationChanged: (value: shame) => void;
  originChanged: (value: shame) => void;
  destinationChanged: (value: shame) => void;
  trailerTemperatureChanged: (value: shame) => void;
}

const mapDispatchToProps: DispatchProps = {
  loadTypeChanged: Actions.routeInformationValueChanged('loadType'),
  carrierChanged: Actions.routeInformationValueChanged('carrier'),
  routeTypeChanged: Actions.routeInformationValueChanged('routeType'),
  dropoffLocationChanged: Actions.routeInformationValueChanged('dropoffLocation'),
  originChanged: Actions.routeInformationValueChanged('origin'),
  destinationChanged: Actions.routeInformationValueChanged('destination'),
  trailerTemperatureChanged: Actions.routeInformationValueChanged('trailerTemperature'),
};

const RoutePlanHeaderFormQuery = gql`
  query RoutePlanImportModalQuery($id: Int!) {
    routePlanResult: GetRoutePlan(id: $id) {
      routePlan {
        id
        origin
      }
    }
  }
`;

export interface RoutePlanOriginQueryProps {
  routePlanOrigin?: LoadOrigin;
}

export interface RoutePlanOriginQueryResponse {
  routePlanResult?: {
    routePlan: {
      id: RoutePlanId;
      origin: LoadOrigin;
    };
  };
}

export const WithRoutePlanOrigin = msyncQuery<RoutePlanOriginQueryResponse, OwnProps, RoutePlanOriginQueryProps>(RoutePlanHeaderFormQuery, {
  alias: 'withRoutePlanOrigin',
  skip(ownProps: OwnProps) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps: OwnProps): { variables } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): RoutePlanOriginQueryProps {
    const { data } = props;
    const routePlanResult = data ? data.routePlanResult : undefined;
    const routePlan = routePlanResult ? routePlanResult.routePlan : undefined;
    if (routePlan === undefined) {
      return {
        routePlanOrigin: undefined,
      };
    }

    return {
      routePlanOrigin: routePlan.origin,
    };
  },
});

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  RoutePlanOriginQueryProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  WithRoutePlanOrigin,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ImportRouteModalContentStepOneUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
