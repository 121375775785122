import * as _ from 'lodash';
import * as React from 'react';
import * as classNames from 'classnames';
import { Button, Col } from 'client/components/third-party';
import { connect, MapDispatchToProps } from 'react-redux';
import { getFormValues } from 'redux-form';
import gqlTag from 'graphql-tag';
import { createSelector } from 'reselect';
import { ApolloClient } from 'apollo-client';
import { withApollo } from 'react-apollo';

import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { AvailableSearchField, AvailableFilter, ActiveFilter, ApolloRefetch } from 'client/types';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { optionsContainerWithFilters, optionsContainerGenerator } from 'client/hoc/options-container-generator';
import SupplierOrderDetailBulkAddProductsModal from 'client/app/orders/supplier-orders/overview/modals/bulk-add-products-modal-container';
import SupplierOrderDetailAddProductModal from 'client/app/orders/supplier-orders/overview/modals/add-new-product-modal-container';
import EditSupplierOrderProductGroupModal from 'client/app/orders/supplier-orders/overview/modals/edit-supplier-order-product-group-container';
import SendSupplierOrderToSupplierModal from 'client/app/orders/supplier-orders/overview/modals/send-supplier-order-modal-container';
import { SupplierOrderDetailsStatsRow } from 'client/app/orders/supplier-orders/overview/supplier-order-details-stats-row';
import { findAllRecords } from 'client/hoc/graphql/find-all-records';
import { withRecordFilterOptions } from 'client/hoc/graphql/record-filter-options';
import { withSchemaColumnMappings } from 'client/hoc/schema-column-map';
import { withDeleteSupplierOrderProductGroup, DeleteSupplierOrderProductGroupFunction } from 'client/app/orders/supplier-orders/overview/supplier-order-product-group-deletion-mutation';
import * as Actions from 'client/actions/supplier-order';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { IColumn } from 'client/components/table/column';

import { tableDisplayColumns, filterableColumns, buildFragment, formQueryColumns } from 'shared/schemas';
import {
  OrderMethod,
  ShippingUnitType,
  KnownSubSellDepartment,
  SupplierOrderDetailsStats,
  SupplierOrderProductGroupShippingUnitQuantity,
  DateStr,
  CELL_TYPES,
  TYPES,
  InlineUpdateSupplierOrderProductInput,
  toInt,
} from 'shared/types';
import { SupplierOrderProduct, SupplierOrderProductId } from 'shared/schemas/supplier-order-product';
import { IRecord } from 'shared/schemas/record';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { buildFunctionHolder } from 'client/utils/side-effect-function-holder';
import { PendingRowActionState, pendingRowActionHandler } from 'client/containers/table/basic-table';
import { addDays } from 'shared/helpers/date-helpers';
import { SUPPLIER_ORDER_DETAILS_TABLE_NAME } from 'client/constants';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';

const tableName = SUPPLIER_ORDER_DETAILS_TABLE_NAME;

export interface StateProps {
  id: number;
  supplierId: number;
  supplierOrderId?: number;
  customerId: number;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  orderType: string;
  sellDepartmentId: number;
  sellDepartment: {
    identifier: string;
  };
  subSellDepartmentId: number;
  storeDeliveryDate: DateStr;
}

interface OwnProps {
  record: IRecord;
  columns: IColumn[];
  loading?: boolean;
  content: any[];
  refetchTable: ApolloRefetch;
  refetchStats: () => Promise<void>;
  totalUnfilteredCount: number;
  totalCount: number;
  filteredRecordIds: number[];
  loadMoreRecords?: () => void;
  client: ApolloClient<NormalizedCacheObject>;
  deleteSupplierOrderProductGroup: DeleteSupplierOrderProductGroupFunction;
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  formName: string;
  tablePageNumber: number;
  tableParentInfo: TableParentInfo;
  confirmOkToSave: () => Promise<boolean>;
  handleSubmit: () => Promise<boolean>;
  dataRequest: MsyncDataRequest;
}

type ShippingUnitQuantityMutation = (args: SupplierOrderProductGroupShippingUnitQuantity) => Promise<any>;
interface WithShippingUnitQuantityMutation { changeSupplierOrderProductGroupShippingUnitQuantity: ShippingUnitQuantityMutation; }
interface WithInlineUpdateSupplierOrderProductMutation { inlineUpdateSupplierOrderProduct: (supplierOrderProductId: number, input: InlineUpdateSupplierOrderProductInput) => Promise<void>; }
interface WithSupplierOrderDetailsStats { supplierOrderDetailsStats: SupplierOrderDetailsStats; }
interface WithSubSellDepartmentProps { subSellDepartments: any[]; }

type Props
  = OwnProps
  & StateProps
  & DispatchProps
  & WithSubSellDepartmentProps
  & WithSupplierOrderDetailsStats
  & WithShippingUnitQuantityMutation
  & WithInlineUpdateSupplierOrderProductMutation;

export interface DispatchProps {
  handleAddNewProductButtonClicked(): void;
  handleEditSupplierOrderProductButtonClicked(supplierOrderProductId: SupplierOrderProductId): void;
  handleSectionUnmounted(): void;
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const values: shame = getFormValues(ownProps.formName)(state);
  return { ...values, supplierOrderId: values.id } as StateProps;
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleAddNewProductButtonClicked: Actions.addNewProductButtonClicked,
  handleEditSupplierOrderProductButtonClicked: Actions.editSupplierOrderProductButtonClicked,
  handleSectionUnmounted: Actions.handleSectionUnmounted,
};

const getEntryLabel: (orderMethod: OrderMethod, shippingUnitType: ShippingUnitType) => string = (orderMethod, shippingUnitType) => {
  let quantityLabel = 'Qty';

  if (orderMethod === OrderMethod.ShippingUnit) {
    if (shippingUnitType === ShippingUnitType.Rack) {
      quantityLabel = 'Rack Qty';
    } else if (shippingUnitType === ShippingUnitType.Pallet) {
      quantityLabel = 'Pallet Qty';
    }
  } else if (orderMethod === OrderMethod.Pack) {
      if (shippingUnitType === ShippingUnitType.Rack) {
        quantityLabel = 'Pack Qty';
      } else if (shippingUnitType === ShippingUnitType.Pallet) {
        quantityLabel = 'Case Qty';
      }
  }

  return quantityLabel;
};

const parseIntFromTable = (value: string): Int => {
  const parsedValue = toInt(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
};

interface GetColumnsArgs {
  subSellDepartment: KnownSubSellDepartment;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  storeDeliveryDate: DateStr;
  changeSupplierOrderProductGroupShippingUnitQuantity: ShippingUnitQuantityMutation;
  inlineUpdateSupplierOrderProduct: (supplierOrderProductId: number, input: InlineUpdateSupplierOrderProductInput) => Promise<void>;
}

function getColumnCustomizations(args: GetColumnsArgs): Array<Partial<IColumn>> {
  const selectedColumns: Array<Partial<IColumn>> = [];
  const {
    subSellDepartment,
    shippingUnitType,
    orderMethod,
    storeDeliveryDate,
    changeSupplierOrderProductGroupShippingUnitQuantity,
    inlineUpdateSupplierOrderProduct,
  } = args;

  selectedColumns.push(...[
    { id: 'product.identifier', header: 'Prod ID', tableEditable: false },
    { id: 'productDescription', header: 'Description', tableEditable: true },
  ]);

  if (shippingUnitType === ShippingUnitType.Pallet) {
    if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        {
          id: 'supplierOrderProductGroup.identifier',
          header: 'Case Type',
          tableEditable: true,
          onSave(supplierOrderProductId: number, value: string) {
            return inlineUpdateSupplierOrderProduct(supplierOrderProductId, { supplierOrderProductGroupIdentifier: value });
          },
        },
      ]);
    } else {
      selectedColumns.push(...[
        { id: 'supplierOrderProductGroup.identifier', header: 'Plt Type', tableEditable: false },
      ]);
    }
  } else if (shippingUnitType === ShippingUnitType.Rack && orderMethod === OrderMethod.ShippingUnit) {
    selectedColumns.push(...[
      {
        id: 'supplierOrderProductGroup.identifier',
        header: 'Rack Type',
        tableEditable: true,
        onSave(supplierOrderProductId: number, value: string) {
          return inlineUpdateSupplierOrderProduct(supplierOrderProductId, { supplierOrderProductGroupIdentifier: value });
        },
      },
    ]);
  } else {
    selectedColumns.push(...[
      { id: 'supplierOrderProductGroup.identifier', header: 'Pk Type', tableEditable: false },
    ]);
  }

  selectedColumns.push(...[
    { id: 'cost', header: 'Pc Cost', tableEditable: true },
    { id: 'retailPrice', header: 'Pc Retail', tableEditable: true },
    { id: 'packSize', header: 'Supp Pk Size', tableEditable: true, sortable: true },
  ]);

  if (shippingUnitType === ShippingUnitType.Pallet) {
    if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        { id: 'product.packSize', header: 'Cust Pk Size', tableEditable: false },
        {
          id: 'supplierOrderProductGroup.packsPerShippingUnit',
          header: 'CPP',
          tableEditable: true,
          onSave(supplierOrderProductId: number, value: string) {
            return inlineUpdateSupplierOrderProduct(supplierOrderProductId, { packsPerShippingUnit: parseIntFromTable(value) });
          },
        },
      ]);
    } else if (orderMethod === OrderMethod.ShippingUnit) {
      selectedColumns.push({ id: 'packsPerShippingUnit', header: 'CPP', tableEditable: false });
    }
  } else if (shippingUnitType === ShippingUnitType.Rack) {
    if (orderMethod === OrderMethod.ShippingUnit) {
      selectedColumns.push(...[
        {
          id: 'packsPerShelf',
          header: 'PPS',
          tableEditable: true,
          sortable: true,
          onSave(supplierOrderProductId: number, value: string) {
            return inlineUpdateSupplierOrderProduct(supplierOrderProductId, { packsPerShelf: parseIntFromTable(value) });
          },
        },
        {
          id: 'shelvesPerRack',
          header: 'SPR',
          tableEditable: true,
          onSave(supplierOrderProductId: number, value: string) {
            return inlineUpdateSupplierOrderProduct(supplierOrderProductId, { shelvesPerRack: parseIntFromTable(value) });
          },
        },
        { id: 'packsPerShippingUnit', header: 'PPR', tableEditable: false },
        { id: 'piecesPerShippingUnit', header: 'PcPR', tableEditable: false},
      ]);
    } else if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        { id: 'product.packSize', header: 'Cust Pk Size', tableEditable: false },
        { id: 'supplierOrderProductGroup.packsPerShippingUnit', header: 'PPR', tableEditable: false },
        { id: 'supplierOrderProductGroup.piecesPerShippingUnit', header: 'PcPR', tableEditable: false},
      ]);
    }
  }

  if (subSellDepartment === KnownSubSellDepartment.CutFloral) {
    selectedColumns.push(...[
      { id: 'stems', header: 'Stem Count', tableEditable: true },
      { id: 'sellDays', header: 'Sell Days', tableEditable: true },
      // override the schema accessor to allow the value to
      // update live when the delivery dates input box changes.
      {
        id: 'sellDate',
        header: 'Sell Date',
        tableEditable: false,
        accessor: (sop: SupplierOrderProduct) => {
          if (_.isNil(sop.sellDays) || _.isNil(storeDeliveryDate)) {
            return '--';
          } else {
            return addDays(storeDeliveryDate, sop.sellDays);
          }
        },
      },
    ]);
  }

  if (shippingUnitType === ShippingUnitType.Rack || shippingUnitType === ShippingUnitType.Pallet) {
    selectedColumns.push(...[{
      id: 'supplierOrderProductGroup.shippingUnitQuantity',
      tableEditable: orderMethod === OrderMethod.ShippingUnit,
      header:
          shippingUnitType === ShippingUnitType.Rack   && orderMethod === OrderMethod.ShippingUnit  ? 'Rk Qty'
        : shippingUnitType === ShippingUnitType.Pallet && orderMethod === OrderMethod.ShippingUnit  ? 'Plt Qty'
        : shippingUnitType === ShippingUnitType.Rack   && orderMethod === OrderMethod.Pack          ? 'Rks Order'
        : shippingUnitType === ShippingUnitType.Pallet && orderMethod === OrderMethod.Pack          ? 'Plts Order'
        :                                                    orderMethod === OrderMethod.Pack       ? 'Ship Unit Order'
        :                                                                                             'Ship Unit Qty',
      async onSave(id: number, value: string) {
        return await changeSupplierOrderProductGroupShippingUnitQuantity({ supplierOrderProductId: id, quantity: parseIntFromTable(value) });
      },
    }]);
  }

  if (shippingUnitType === ShippingUnitType.Rack) {
    if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        {
          id: 'packQuantity',
          header: 'Pk Qty',
          tableEditable: true,
          async onSave(id: number, value: string) {
            return await changeSupplierOrderProductGroupShippingUnitQuantity({ supplierOrderProductId: id, quantity: parseIntFromTable(value) });
          },
        },
      ]);
    } else {
      selectedColumns.push(...[
        { id: 'packQuantity', header: 'Pks Order', tableEditable: false },
      ]);
    }
  } else {
    if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        {
          id: 'packQuantity',
          header: 'Case Qty',
          tableEditable: true,
          async onSave(id: number, value: string, prevValue: string) {
            return await changeSupplierOrderProductGroupShippingUnitQuantity({ supplierOrderProductId: id, quantity: parseIntFromTable(value) });
          },
        },
      ]);
    } else {
      selectedColumns.push(...[
        { id: 'packQuantity', header: 'Case Order', tableEditable: false },
      ]);
    }
  }

  selectedColumns.push(...[
    { id: 'pieceQty', header: 'Pcs Order', tableEditable: false },
  ]);

  if (shippingUnitType === ShippingUnitType.Pallet || shippingUnitType === ShippingUnitType.Rack) {
    selectedColumns.push(...[
      { id: 'additionalCost', header: "Add'l Cost", tableEditable: true },
    ]);
  }

  if (shippingUnitType === ShippingUnitType.Rack) {
    selectedColumns.push(...[
      { id: 'supplierPackCost', header: 'Pk Cost', tableEditable: false },
    ]);
  } else {
    selectedColumns.push(...[
      { id: 'supplierPackCost', header: 'Case Cost', tableEditable: false },
      { id: 'packsReceived', header: 'Cases Recvd', tableEditable: false },
    ]);
  }

  selectedColumns.push(...[
    { id: 'extendedCost', header: 'Ext Cost', tableEditable: false },
  ]);

  const mappedColumns = selectedColumns
    .map(column => {
      const columnWidth = column.id === 'productDescription'
        ? ((1.0 / (selectedColumns.length + 1)) * 100) * 2 // 2 columns for description
        : ((1.0 / (selectedColumns.length + 1)) * 100);

      return {
        ...column,
        columnWidth,
      };
    });

  return mappedColumns;
}

/**
 * Memoizes the `getColumns` function against its dependence on a subset of Redux state,
 * using the Reselect library: https://github.com/reactjs/reselect
 */
function getCustomColumnsSelector(baseCols) {
  return createSelector([getColumnCustomizations], cols => cols
    .filter(customization => baseCols.some(c => c.id === customization.id))
    .map(customization => {
      const baseColumn = baseCols.find(c => c.id === customization.id);
      if (!baseColumn) {
        throw new Error(`Column ${customization.id} was not found in the base columns for Supplier Order Products`);
      }

      const { onSave: baseColumnOnSave, ...baseColumnProps } = baseColumn;
      const { onSave: customizedColumnOnSave, ...customizezColumnProps } = customization;

      const onSave = customizedColumnOnSave || baseColumnOnSave;
      return { ...baseColumnProps, ...customizezColumnProps, onSave } as IColumn;
    }));
}

interface State extends PendingRowActionState {
  showBulkAddProductsModal: boolean;
  initialLoadingComplete: boolean;
}

class SupplierOrderDetails<TProps extends Props> extends React.Component<TProps, State> {
  private readonly tableComponent: React.StatelessComponent<FilterableTableProps>;
  private readonly customColumnsSelector: (args: GetColumnsArgs) => IColumn[];

  constructor(props: TProps) {
    super(props);
    this.tableComponent = buildFilterableTable(tableName);

    if (props.loading !== false) {
      this.state = { showBulkAddProductsModal: false, initialLoadingComplete: false, pendingRowAction: false };
    } else {
      this.state = { showBulkAddProductsModal: false, initialLoadingComplete: true, pendingRowAction: false };
    }

    this.customColumnsSelector = getCustomColumnsSelector(props.columns.concat([{
      id: 'sellDate',
      tableEditable: false,
      accessor: () => 'foo',
      header: 'Sell Date',
      cellType: CELL_TYPES.DATE,
      sortable: false,
      type: TYPES.DATE,
    }]));
  }

  componentWillUnmount() {
    this.props.handleSectionUnmounted();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.state.initialLoadingComplete && (this.props.loading === undefined || this.props.loading === true) && nextProps.loading === false) {
      this.setState({ initialLoadingComplete: true });
    }
  }

  public render() {
    const placeholder = 'There are currently no products allocated for this supplier';
    const {
      supplierOrderId,
      totalUnfilteredCount,
      subSellDepartmentId,
      shippingUnitType,
      orderMethod,
      subSellDepartments,
      storeDeliveryDate,
      changeSupplierOrderProductGroupShippingUnitQuantity,
      inlineUpdateSupplierOrderProduct,
      confirmOkToSave,
    } = this.props as TProps;

    const rowMenuItems: RowMenuItem[] = [
      {
        label: 'Edit Configuration',
        // TODO: HANDLE BULK EDIT???? We probably don't want to show this option if multiple things are selected. (AP 9/19/17)
        onClick: args => this.props.handleEditSupplierOrderProductButtonClicked(args[0]),
        uncheckRecordFollowingClick: false,
        willRemove: false,
      },
      {
        label: 'Delete Row',
        onClick: pendingRowActionHandler(this, this.props.deleteSupplierOrderProductGroup),
        uncheckRecordFollowingClick: true,
        willRemove: true,
      },
    ];

    const headerMenuItems: RowMenuItem[] = [{
      label: 'Delete Selected Rows',
      onClick: pendingRowActionHandler(this, this.props.deleteSupplierOrderProductGroup),
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    const onClose = () => { this.setState({ showBulkAddProductsModal: false }); };
    const shouldDisplayTable = this.state.initialLoadingComplete && !_.isNil(supplierOrderId) && totalUnfilteredCount > 0;
    const shouldDisplayNewRecordButton = _.isNil(supplierOrderId) || (this.state.initialLoadingComplete && (!this.props.loading && totalUnfilteredCount === 0));

    const buttons = [
      {
        label: 'Bulk Add Products',
        onClick: () => this.setState({ showBulkAddProductsModal: true }),
      },
      {
        label: 'Add Products',
        onClick: () => this.props.handleAddNewProductButtonClicked(),
      },
    ];

    let outerClassName = '';
    if (!shouldDisplayTable) {
      if (this.props.content) {
        outerClassName = 'table-hidden';
      } else {
        outerClassName = 'table-invisible';
      }
    }

    const subSellDepartment = (subSellDepartments?.filter(ssd => ssd.id === subSellDepartmentId).map(s => s.identifier).shift()) || null as KnownSubSellDepartment | null;

    const disabled = _.isNil(supplierOrderId);

    return (
      <div>
        {!_.isNil(supplierOrderId) &&
          <div>
            <Col sm={12} className={outerClassName}>
              <SupplierOrderDetailsStatsRow
                supplierOrderDetailsStats={this.props.supplierOrderDetailsStats}
                conditions={this.props}/>
              <div data-testid="supplier-order-allocations">
                <this.tableComponent
                  table={tableName}
                  content={this.props.content}
                  loading={this.props.loading || this.state.pendingRowAction}
                  columns={this.customColumnsSelector({ subSellDepartment, shippingUnitType, orderMethod, changeSupplierOrderProductGroupShippingUnitQuantity, storeDeliveryDate, inlineUpdateSupplierOrderProduct })}
                  totalCount={this.props.totalCount}
                  totalUnfilteredCount={this.props.totalUnfilteredCount}
                  filteredRecordIds={this.props.filteredRecordIds}
                  refetchTable={this.props.refetchTable}
                  loadMoreRecords={this.props.loadMoreRecords}
                  searchableFields={this.props.searchableFields}
                  availableFilters={this.props.availableFilters}
                  placeholder={placeholder}
                  buttons={buttons}
                  checkable
                  list={false}
                  headerMenuItems={headerMenuItems}
                  rowMenuItems={rowMenuItems}
                  tablePaginated
                  tableParentInfo={this.props.tableParentInfo}
                  handleSubmit={this.props.handleSubmit}
                  confirmOkToSave={confirmOkToSave}
                  refetchStats={this.props.refetchStats}
                  displayLoadingIndicator
                  dataRequest={this.props.dataRequest}
                >
                </this.tableComponent>
              </div>
            </Col>
            {this.state.showBulkAddProductsModal && <SupplierOrderDetailBulkAddProductsModal
              name="supplierOrderAddProducts"
              onClose={onClose}
              refetchTable={this.props.refetchTable}
              sellDepartmentId={this.props.sellDepartmentId}
              sellDepartmentIdentifier={this.props.sellDepartment.identifier}
              show={this.state.showBulkAddProductsModal}
              supplierId={this.props.supplierId}
              supplierOrderId={supplierOrderId}
              customerId={this.props.customerId}
              entryLabel={getEntryLabel(orderMethod, shippingUnitType)}
              userConfirmation={this.props.confirmOkToSave}
              isModal
              tableContent={this.props.content}
            />}
            <SupplierOrderDetailAddProductModal
              supplierOrderId={supplierOrderId}
              refetchTable={this.props.refetchTable}
              customerId={this.props.customerId}
              sellDepartmentId={this.props.sellDepartmentId}
              sellDepartmentIdentifier={this.props.sellDepartment.identifier}
              supplierId={this.props.supplierId}
              orderMethod={this.props.orderMethod}
              shippingUnitType={this.props.shippingUnitType}
              userConfirmation={this.props.confirmOkToSave}
              isModal
              supplierOrderProducts={this.props.content}
            />
            <EditSupplierOrderProductGroupModal
              supplierOrderId={supplierOrderId}
              refetchTable={this.props.refetchTable}
              customerId={this.props.customerId}
              sellDepartmentId={this.props.sellDepartmentId}
              sellDepartmentIdentifier={this.props.sellDepartment.identifier}
              supplierId={this.props.supplierId}
              orderMethod={this.props.orderMethod}
              shippingUnitType={this.props.shippingUnitType}
              userConfirmation={this.props.confirmOkToSave}
              isModal
              supplierOrderProducts={this.props.content}
            />
            <SendSupplierOrderToSupplierModal
              supplierOrderId={supplierOrderId}
              supplierOrderDetailsStats={this.props.supplierOrderDetailsStats}
              isModal
            />
        </div>
        }
        {shouldDisplayNewRecordButton &&
          <div className="table-placeholder">
            <h2 className="message">There are currently no products in this order.</h2>
            {buttons.map((displayButton, index) => {
              if (index === 0) {
                return <Button key={displayButton.label} bsStyle="primary"
                  bsClass="mfc-button mfc-submit-button"
                  onClick={displayButton.onClick} disabled={disabled}>
                  {displayButton.label}
                </Button>;
              }

              const buttonClassName = classNames('mfc-form-button', { disabled });

              return (
                <div key={displayButton.label} className="text-center">
                  <div className={buttonClassName} data-testid="add-another-product" onClick={!disabled ? displayButton.onClick : undefined}>
                    {displayButton.label}
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
    );
  }
}

const withSellDepartmentOptions = optionsContainerGenerator({ table: 'sellDepartments', columns: ['identifier']});

const subSellDepartmentFilters = (ownProps: StateProps) => {
  const theFilters: ActiveFilter[] = [];
  if (ownProps.sellDepartmentId) {
    theFilters.push({ field: 'sellDepartment', values: [ownProps.sellDepartmentId]});
  }
  return theFilters;
};

const withSubSellDepartmentOptions = optionsContainerWithFilters<WithSubSellDepartmentProps>({ table: 'subSellDepartments', columns: ['identifier'], getFilters: subSellDepartmentFilters, skip: (ownProps: StateProps) => !ownProps.sellDepartmentId });

const statsGqlQuery = gql`
query supplierOrderDetailsStats($supplierOrderId: Int!) {
  supplierOrderDetailsStats: supplierOrderDetailsStats(supplierOrderId: $supplierOrderId) {
    piecesOrdered,
    piecesReceived,
    totalSupplierCases,
    totalShippingUnits,
    totalCost,
  }
}`;

function withSupplierOrderDetailsStats(WrappedComponent) {

  const refetchStatsHolder = buildFunctionHolder('refetchStats');

  const withStats = msyncQuery<{ supplierOrderDetailsStats }, StateProps, {}, {}>(statsGqlQuery, {
    options(ownProps) {
      return {
        variables: {
          supplierOrderId: ownProps.id || -1,
        },
      };
    },
    props({ data }) {
      // SIDE-EFFECT - Update the refetch function in a way that won't cause downstream components
      //               to be re-rendered. (This is totally cheating)
      refetchStatsHolder.update(data.refetch);

      const result = {
        supplierOrderDetailsStats: data.supplierOrderDetailsStats,
        refetchStats: refetchStatsHolder.refetchStats,
      };
      return result;
    },
  });

  return _.flowRight(
    withStats,
  )(WrappedComponent);
}

const sopFragment = gqlTag`${buildFragment('supplierOrderProducts', formQueryColumns('supplierOrderProducts'), 'sopFragment')}`;
const shippingUnitQuantityMutation = gql`
mutation ChangeSupplierOrderProductGroupShippingUnitQuantity($supplierOrderProductId: Int!, $quantity: Int!) {
  changeSupplierOrderProductGroupShippingUnitQuantity(supplierOrderProductId: $supplierOrderProductId, quantity: $quantity) {
    ...sopFragment
    ... on SupplierOrderProduct {
      id
      supplierOrderProductGroup {
        id
        supplierOrder {
          id
          lastModifiedAt
          receivingStatus
          receivableOrder {
            id
            receivingStatus
            details {
              id
              receivableOrderType
              packQuantity
              shippingUnitQuantity
              packSize
              packsPerShelf
              shelvesPerRack
              packsPerShippingUnit
            }
          }
        }
      }
    }
  }
}
${sopFragment}`;

const withShippingUnitQuantityMutation = component => msyncMutation<any, any, any>(shippingUnitQuantityMutation, {
  props: ({ mutate, ownProps }) => {
    return {
      async changeSupplierOrderProductGroupShippingUnitQuantity(variables: SupplierOrderProductGroupShippingUnitQuantity) {
        return mutate({ variables });
      },
    };
  },
})(component);

const inlineUpdateSupplierOrderProductMutation = gql`
mutation InlineUpdateSupplierOrderProduct($supplierOrderProductId: Int!, $input: InlineUpdateSupplierOrderProductInput!) {
  inlineUpdateSupplierOrderProduct(supplierOrderProductId: $supplierOrderProductId, input: $input) {
    supplierOrderProduct {
      ...sopFragment
      ... on SupplierOrderProduct {
        id
        supplierOrderProductGroup {
          id
          supplierOrder {
            id
            lastModifiedAt
            receivingStatus
            receivableOrder {
              id
              receivingStatus
              details {
                id
                receivableOrderType
                packQuantity
                shippingUnitQuantity
                packSize
                packsPerShelf
                shelvesPerRack
                packsPerShippingUnit
              }
            }
          }
        }
      }
    }
  }
}
${sopFragment}`;

const withInlineUpdateSupplierOrderProductMutation = component => msyncMutation<any, any, any>(inlineUpdateSupplierOrderProductMutation, {
  props: ({ mutate, ownProps }) => {
    return {
      async inlineUpdateSupplierOrderProduct(supplierOrderProductId: number, input: InlineUpdateSupplierOrderProductInput) {
        return mutate({
          variables: {
            supplierOrderProductId,
            input,
          },
        });
      },
    };
  },
})(component);

const columns = tableDisplayColumns(tableName).filter(c => c !== 'supplierOrder');
const scope = x => ([{ field: 'supplierOrder', values: x?.record?.id ? [x.record.id] : [] }]);
const filters = filterableColumns(tableName);

export default _.flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  tableParentHoc(),
  withApollo,
  withSupplierOrderDetailsStats,
  findAllRecords({
    table: tableName,
    columns,
    scope,
    workbookName: (props: OwnProps) => `${props.record.identifier}`,
  }),
  withDeleteSupplierOrderProductGroup(tableName),
  withRecordFilterOptions({ table: tableName, fields: filters, scope }),
  withSchemaColumnMappings({ schema: tableName, displayColumns: columns }),
  withSellDepartmentOptions,
  withSubSellDepartmentOptions,
  withShippingUnitQuantityMutation,
  withInlineUpdateSupplierOrderProductMutation,
)(SupplierOrderDetails);
