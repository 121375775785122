import { tableName, property, belongsTo, required, setSchemaOptions } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerOrderAllocation } from './customer-order-allocation';

/**
 * This is currently only used to delete line items when an allocation is deleted.
 * Use the "new" repository for anything else (have to use the "old" repository
 * because these need to be cleaned up when a COA is deleted).
 */
@tableName('customerOrderInvoiceLineItems')
export class CustomerOrderInvoiceLineItem implements IRecord {
  id?: number;

  @belongsTo('customerOrderAllocations')
  @property @required customerOrderAllocation: Partial<CustomerOrderAllocation>;
  customerOrderAllocationId: number;
}

setSchemaOptions(CustomerOrderInvoiceLineItem, {
  generateGraphqlSchema: false,
});
