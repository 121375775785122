import * as ImportModalTypes from 'client/components/import-modal/types';
import * as ReduxTypes from 'client/types/redux-types';
import * as Redux from 'redux';
import * as SharedHelpers from 'shared/helpers';
import * as ExcelHelpers from 'shared/file-parsers/excel/common';
import * as ProductTossSpreadsheetParser from 'shared/file-parsers/excel/product-toss-spreadsheet-parser';
import * as Queries from './queries';
import * as Mutations from './mutations';
import { ImportableProductTossSheet } from 'shared/file-parsers/excel/product-toss-spreadsheet-parser/types';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { ImportProductTossResponse, ImportProductTossInput } from 'schema/import-product-toss/types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

export function handleParsingStarted(args: { fileName: string, contents: string }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    const workBookContainer = await ExcelHelpers.getWorkBookContainerFromBinaryString(args.fileName, args.contents);
    const importableProductTossData = await ProductTossSpreadsheetParser.getImportableProductTossSheet(workBookContainer);

    if (importableProductTossData.success) {
      done([], importableProductTossData.parsed);
    } else {
      done([importableProductTossData.reason]);
    }

  };
}

export function handleValidationStarted(args: { existingRecordId: number | undefined, importableObject: ImportableProductTossSheet }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);
    try {
      const result = await msyncClientQuery<{ validateImportableProductToss: ImportProductTossResponse }, ImportProductTossInput>(
        {
          query: Queries.ValidateImportProductTossQuery,
          variables: {
            input: {
              importableProductToss: args.importableObject,
            },
          },
          dispatch,
          disableGlobalError: true,
          fetchPolicy: 'network-only',
        },
      );
      const errors = result.data.validateImportableProductToss.errors;
      done(errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleImportStarted(args: { existingRecordId: number | undefined, importableObject: ImportableProductTossSheet }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      const response = await msyncClientMutation<{ importProductToss: ImportProductTossResponse }, ImportProductTossInput>(
        {
          mutation: Mutations.ImportProductTossMutation,
          variables: {
            input: {
              importableProductToss: args.importableObject,
            },
          },
          dispatch,
          suppressErrorModal: true,
          refetchQueries: ['findAllProductTossesWithTotal'], // Not great, but this will refetch the most recent of these queries, which will definitely be the Product Toss list if this mutation is being invoked.
        },
      );
      done(response.data.importProductToss.errors);
    } catch (error) {
      done([error.message]);
    }
  };
}
