import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/route-plan/route-plan-typescript-types';

export interface Input {
  id: number;
}

export interface Response {
  routePlanResult?: {
    routePlan: Pick<GraphQLTypes.RoutePlan, 'id' | 'forceReroute' | 'customerOrdersUpdatedSinceRoutePacketPrinting'>;
  };
}

export const RoutePlanPrintingQuery = gql`
  query RoutePlanHeaderFormWarningQuery($id: Int!) {
    routePlanResult: GetRoutePlan(id: $id) {
      routePlan {
        id
        forceReroute
        customerOrdersUpdatedSinceRoutePacketPrinting
      }
    }
  }
`;
