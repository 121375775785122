import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES, DateStr, MoneyStr, CustomerOrderType, KnownVendor, KnownCustomer } from '../types';
import { ImportableDocument } from 'shared/schemas/importable-document';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';

export interface RemittanceAdviceDetailObject extends RegisteredKinds {
  readonly kind: 'remittanceAdvice';
  customerIdentifier?: string;

  // header
  totalAmount?: MoneyStr;
  checkNumber?: string;
  checkDate?: DateStr;
  vendor?: string;

  details: Array<{
    sourceType?: string;
    sourceIdentifier?: string;
    net?: MoneyStr;
    gross?: MoneyStr;
    discount?: MoneyStr;

    reasonCode?: string;
    referenceType?: string;
    referenceIdentifier?: string; // usually a poNumber, but depends on referenceType
    creditMemoDate?: DateStr;
  }>;
}

export interface InvoiceTotalObject extends RegisteredKinds {
  readonly kind: 'invoiceTotal';

  customerIdentifier?: string;
  poNumber?: string;
  sellDate?: DateStr;
  total?: MoneyStr;
}

export interface SalesActivityRowObject extends RegisteredKinds {
  readonly kind: 'salesActivity';

  customerIdentifier?: string;
  storeIdentifier?: string;
  upc?: string;
  sellDate?: DateStr;
  quantitySold?: number;
  priceOrRetail?: MoneyStr;
  dollarsSold?: MoneyStr; // only reported by HHH 852. We'll have this value instead of priceOrRetail
  quantityOnHand?: Int;

  sku?: string; // only appears consistently on the HHH 852, always appears on a product row but the UPC does not
  isPartOfDailySalesDocument?: boolean; // should be undefined or false unless the specific document type being parsed should send out daily sales reports after being imported
  shouldInvoiceDirectlyToAcumatica?: boolean; // should be undefined or false unless it's a HHH 850 document with a total retail type of TT - causes it to be written directly to acumatica

  poNumber?: string; // only needed for HHH 850 to be written to Acumatica, doesn't actually correspond to a customer order in our system

  ignoreIfScanProduct?: boolean; // Some sales activity (HHH 852) should be ignored if it's a scan product (that's what VBills does). Use this flag to tell the importer.
}

export interface CreditMemoDetailObject extends RegisteredKinds {
  readonly kind: 'creditMemo';

  customerIdentifier?: string;
  storeIdentifier?: string;
  date?: DateStr;
  adjustmentNumber?: string;
  scan?: boolean;
  transactionCode?: string;
  transactionDescription?: string;
  creditDebitCode?: string;
  creditDebitDescription?: string;
  vendorNumber?: string;
  departmentNumber?: string;
  rgaNumber?: string;
  noteCode?: string;
  noteDescription?: string;
  actionCode?: string;
  actionDescription?: string;
  reasonCode?: string;
  reasonDescription?: string;
  creditDebitFlag?: string;
  flagDescription?: string;
  quantity?: number;
  price?: number;
  sku?: string;
  description?: string;
}

export interface PurchaseOrderDetailObjectAllocation {
  storeIdentifier: string;
  sku?: string;
  productIdentifier?: string;
  description?: string;
  packSize: number;
  price: MoneyStr;
  quantity: number;
  retail?: MoneyStr;
}

export interface PurchaseOrderDetailObject extends RegisteredKinds {
  readonly kind: 'purchaseOrder';

  // Header Info
  customerIdentifier: KnownCustomer | string;
  poNumber: string;
  storeDeliveryDate: DateStr;
  arrivalDate?: DateStr;
  orderType: CustomerOrderType;
  vendorIdentifier: KnownVendor | string;
  poDate?: DateStr;

  allocations: PurchaseOrderDetailObjectAllocation[];
}

// This is temporary just to illustrate the discriminated union
export interface EmptyDetailObject extends RegisteredKinds {
  kind: 'empty';
}

/** Primary API for converting objects extracted from JSON to their expected row type. */
export const as = Object.freeze({
  salesActivityRowObject(candidate: ImportableDocumentDetailObject) { return validateAndCast(candidate, 'salesActivity'); },
  creditMemoDetailObject(candidate: ImportableDocumentDetailObject) { return validateAndCast(candidate, 'creditMemo'); },
  purchaseOrderDetailObject(candidate: Partial<ImportableDocumentDetailObject>) { return validateAndCast(candidate, 'purchaseOrder'); },
  invoiceTotalObject(candidate: ImportableDocumentDetailObject) { return validateAndCast(candidate, 'invoiceTotal'); },
  remittanceAdviceObject(candidate: ImportableDocumentDetailObject) { return validateAndCast(candidate, 'remittanceAdvice'); },
  emptyDetailObject(candidate: ImportableDocumentDetailObject) { return validateAndCast(candidate, 'empty'); },
});

/** Union of all registerd row types */
export type ImportableDocumentDetailObject = ObjectTypesRegisteredByKind[keyof ObjectTypesRegisteredByKind];

/** Extend this to get verification that your kind is registered. */
export interface RegisteredKinds { kind: keyof ObjectTypesRegisteredByKind; }

/** Actual registry of row types by kind. Kinds are implementation details, as is this registry. */
interface ObjectTypesRegisteredByKind {
  salesActivity: SalesActivityRowObject;
  creditMemo: CreditMemoDetailObject;
  purchaseOrder: PurchaseOrderDetailObject;
  invoiceTotal: InvoiceTotalObject;
  remittanceAdvice: RemittanceAdviceDetailObject;
  empty: EmptyDetailObject;
}

/** Runtime validation of kind, compile time mapping of kind to type for given object instance. */
function validateAndCast<T extends keyof ObjectTypesRegisteredByKind>(row: Partial<ImportableDocumentDetailObject>, kind: T): ObjectTypesRegisteredByKind[T] {
  if (row.kind !== kind)
    throw new Error(`row.kind was ${row.kind} but ${kind} was expected. \n ${typeof row} =>  ${JSON.stringify(row, null, 4)}`);

  return row as ObjectTypesRegisteredByKind[T];
}

@tableName('importableDocumentDetails')
export class ImportableDocumentDetail implements IRecord {
  id?: number;

  @property @required imported: boolean;

  /**
   * Some JSON thing correlated with a single row in a table holding extracted, parsed, validated, and thus imported, records.
   */
  @property @required detailObject: ImportableDocumentDetailObject;

  @belongsTo('importableDocuments')
  @property @required importableDocument?: ImportableDocument;
  importableDocumentId?: number;
}

definePresentation(ImportableDocumentDetail, {
  imported: { formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX) },
  importableDocument: { formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN) },
  detailObject: { formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN) },
});
