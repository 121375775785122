import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { AutoReplenishmentFormUI } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-form-ui';
import { AutoReplenishmentProps } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-modal-container';

interface OwnProps {
  customerOrderProductGroupIds: AutoReplenishmentProps['customerOrderProductGroupIds'];
  productLabel?: string;
  storeIds: number[];
}

export type ComponentProps =
  OwnProps;

const component = flowRight(
)(AutoReplenishmentFormUI);

export const AutoReplenishmentForm = (props: OwnProps) => propToComponent(component, props);
