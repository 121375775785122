import * as React from 'react';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { CELL_TYPES, TYPES, DateStr } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { AvailableFilter, ApolloRefetch } from 'client/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { TableParentInfo } from 'client/components/table/table-parent';

export interface OwnProps {
  tableName: string;

  searchableFields: shame;
  availableFilters: AvailableFilter[];
  disableCreate?: boolean;

  onNewClicked?(): void;
  onRowSelect(): void;
  confirmOkToSave: () => Promise<boolean>;

  supplierOrders: Array<{
    id: number;
    identifier: string;
    supplierName: string;
    customerName: string;
    sellDepartmentIdentifier: string;
    mfcAreaIdentifier: string;
    relatedCustomerOrders: number;
    plannedArrivalDate: DateStr;
    pieceQuantity: number;
    totalCost: shame;
  }>;

  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  refetchTable?: ApolloRefetch;
  loadMoreRecords: shame;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  tableParentInfo: TableParentInfo;

  displayLoadingIndicator: boolean;

  headerMenuItems?: shame[];
  rowMenuItems?: shame[];
}

const columns: IColumn[] = [
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'PO #',
    tableEditable: false,
    columnWidth: 11,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'supplierName',
    accessor: 'supplierName',
    header: 'Supplier',
    tableEditable: false,
    columnWidth: 19,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'customerName',
    accessor: 'customerName',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 19,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'sellDepartmentIdentifier',
    accessor: 'sellDepartmentIdentifier',
    header: 'Sell Dept.',
    tableEditable: false,
    columnWidth: 11,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'mfcAreaIdentifier',
    accessor: 'mfcAreaIdentifier',
    header: 'MFC Area',
    tableEditable: false,
    columnWidth: 5,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'relatedCustomerOrders',
    accessor: 'relatedCustomerOrders',
    header: 'Cust Orders',
    tableEditable: false,
    columnWidth: 7,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'plannedArrivalDate',
    accessor: 'plannedArrivalDate',
    header: 'Arrival Date',
    tableEditable: false,
    columnWidth: 11,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
  {
    id: 'pieceQuantity',
    accessor: 'pieceQuantity',
    header: 'Pc Qty',
    tableEditable: false,
    columnWidth: 5,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'totalCost',
    accessor: 'totalCost',
    header: 'PO Amount',
    tableEditable: false,
    columnWidth: 11,
    sortable: true,
    cellType: CELL_TYPES.MONEY,
    type: TYPES.FLOAT,
  },
];

export class SupplierOrdersJustTheTable extends React.PureComponent<OwnProps, any> {
  private readonly tableComponent: React.StatelessComponent<FilterableTableProps>;

  constructor(props: OwnProps) {
    super(props);
    this.tableComponent = buildFilterableTable(props.tableName);
  }

  public render() {
    return (
      <this.tableComponent
        table={this.props.tableName}
        loading={this.props.loading}
        columns={columns}
        content={this.props.supplierOrders}
        refetchTable={this.props.refetchTable}
        searchableFields={this.props.searchableFields}
        availableFilters={this.props.availableFilters}
        placeholder={'There are currently no related Supplier POs.'}
        newButtonLabel={'Attach Supplier POs'}
        totalUnfilteredCount={this.props.totalUnfilteredCount}
        totalCount={this.props.totalCount}
        loadMoreRecords={this.props.loadMoreRecords}
        filteredRecordIds={this.props.filteredRecordIds}
        checkable
        list
        onNewClicked={this.props.onNewClicked}
        onRowSelect={this.props.onRowSelect}
        disableCreate={this.props.disableCreate}
        displayLoadingIndicator={this.props.displayLoadingIndicator}
        tableParentInfo={this.props.tableParentInfo}
        tablePaginated
        headerMenuItems={this.props.headerMenuItems}
        rowMenuItems={this.props.rowMenuItems}
        confirmOkToSave={this.props.confirmOkToSave}
        dataRequest={this.props.dataRequest}
      />
    );
  }
}
