import * as classNames from 'classnames';
import * as _ from 'lodash';
import * as Mousetrap from 'mousetrap';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { SORT_TYPES } from '../../../shared/types';
import { RowMenuItem } from './row-menu/menu';
import { IColumn } from './column';
import RowMenu from './row-menu';
import RowSelector from './row-selector';
import { IRecord } from 'shared/schemas/record';
import { FieldValidator, makeFormValidator } from 'shared/validators';
import { PaginationComponent } from 'client/components/table/pagination-component';
import { NavigateTableDirection, NAVIGATE_TABLE_DIRECTIONS, CELL_TYPES_FOOTER_RENDERERS } from 'shared/types/user-interaction-types';
import { TableCell } from 'client/components/table/cell-renderer';
import { TableParentInfo } from 'client/components/table/table-parent';
import { isCellEditable } from 'client/helpers/table-helpers';
import CellRenderer from './cell-renderer';

const Table: any = require('react-table').default;
// (window as any).ReactPerf = require('react-addons-perf'); // ANDY SEZ REACT 16 DOESN'T SUPPORT THIS, SO BLAH...

export type OnRowSelect = (record: IRecord) => any;

export interface TableProps {
  checkable?: boolean;
  columns: IColumn[];
  list?: boolean;
  onSort?: (columnName: string, multiColumn?: boolean) => void;
  sorting?: Array<{ sortField: string; sortOrder: SORT_TYPES }>;
  onRowFocused?: any;
  onRowSelect?: OnRowSelect;
  fieldValidators?: FieldValidator[];
  totalCount: number;
  content: any[] | undefined;
  loading?: boolean;
  filteredRecordIds: number[];
  loadMoreRecords?: () => void;
  checkedRecordIds: number[];
  editing: { value: boolean; shouldSave: boolean };
  selectedColumn: number;
  selectedRow: number;
  click: (row: number, column: number) => void;
  clickOutside: () => void;
  edit: (isEditing: boolean, shouldSave?: boolean) => void;
  move: (direction: any, distance: any, blurOnSaveLastRow: boolean) => void;
  moveEditCell: (direction: any, distance: number, blurOnSaveLastRow: boolean) => void;
  editNextEditableCell: (direction: NavigateTableDirection, columns: IColumn[]) => void;
  toggleCheckAllRecords: (checkedRecordIds: number[], filteredRecordIds: number[]) => void;
  toggleCheckSingleRecord: (recordId: any) => void;
  uncheckMultipleRecords: (recordIds: any[]) => void;
  setTablePageNumber: (pageNumber: number, row?: number) => void;
  tablePageNumber: number;
  headerMenuItems?: RowMenuItem[];
  rowMenuItems?: RowMenuItem[];
  toolbarVisible?: boolean;
  tablePaginated: boolean;
  tableParentInfo: TableParentInfo;
  noDataText?: string;
  confirmOkToSave?: () => Promise<boolean>;
  handleSubmit?: () => Promise<boolean>;
  refetchStats?: () => Promise<void>;
  tableClassName?: string;
  onInvalidCellDetected: (isInvalid: boolean) => void;
  footerData?: { [k: string ]: any };
  footerProps?: { [k: string ]: any };
}

interface StateProps {
  keysBound: boolean;
}

export const buildDefaultCellTestId = (columnName: string, rowIndex: number) => `${columnName}-${rowIndex}`;
interface TableCellProps {
  // comes from react-table
  index: number;
  row: { id: number };
  value?: any;
}

const buildTableCell = (args: {
  column: Dictionary<any>,
  props: TableProps,
  setRef?: (index: number, cell: any) => void,
  isCurrentCellValid: () => boolean,
  focusRowContainingCell: (cellRef: TableCell, focus: boolean) => void,
}) => {
  return ({index, row, value }: TableCellProps) => {
    const columnName = args.column.accessor;
    return (
      <CellRenderer
        ref={(cell: any) => args.setRef && args.setRef(index, cell)}
        required={args.column.required}
        value={value}
        type={args.column.type}
        columnName={columnName}
        onSave={args.column.onSave}
        onBlur={args.column.onBlur}
        handleSubmit={args.props.handleSubmit}
        confirmOkToSave={args.props.confirmOkToSave}
        refetchStats={args.props.refetchStats}
        tableDisplayType={args.column.cellType}
        id={row.id}
        testid={(args.column.getTestId && args.props.content && args.props.content[index]) ? args.column.getTestId(args.props.content[index]) : buildDefaultCellTestId(columnName, index)}
        sorted={!!(args.props.sorting && args.props.sorting.find(s => s.sortField === args.column.id))}
        className={args.column.getClassNames ? args.column.getClassNames(value, row) : undefined}
        options={args.column.options}
        onArrowKeyUpDuringEdit={() => {
          if (args.props.columns[args.props.selectedColumn] && args.props.columns[args.props.selectedColumn].saveOnChange && !args.isCurrentCellValid()) {
            return;
          }

          const blurOnSaveLastRow = args.props.columns[args.props.selectedColumn].options ?
            args.props.columns[args.props.selectedColumn].options!.blurOnSaveLastRow ?? false : false;

          // We use this function for the spinner cell in order to use the arrow keys to navigate the cells.
          args.props.move(NAVIGATE_TABLE_DIRECTIONS.UP, 1, blurOnSaveLastRow);
          args.props.edit(true);
        }}
        onArrowKeyDownDuringEdit={() => {
          if (args.props.columns[args.props.selectedColumn] && args.props.columns[args.props.selectedColumn].saveOnChange && !args.isCurrentCellValid()) {
            return;
          }

          const blurOnSaveLastRow = args.props.columns[args.props.selectedColumn].options ?
            args.props.columns[args.props.selectedColumn].options!.blurOnSaveLastRow ?? false : false;

          // We use this function for the spinner cell in order to use the arrow keys to navigate the cells.
          args.props.move(NAVIGATE_TABLE_DIRECTIONS.DOWN, 1, blurOnSaveLastRow);
          args.props.edit(true);
        }}
        saveOnChange={args.column.saveOnChange}
        editing={false}
        placeholder=""
        onClick={_.noop}
        onDoubleClick={_.noop}
        focusRowContainingCell={args.focusRowContainingCell}
      />
    );
  };
};

function footerCellRenderer(v: string | (({ data, column }) => string)) {
  return ({ data, column }) => {
    const value = typeof v === 'string' ? v : v({ data, column });
    const tableDisplayType = CELL_TYPES_FOOTER_RENDERERS[column.cellType];

    return <CellRenderer
      required={column.required}
      value={value}
      type={column.type}
      columnName={column.id}
      tableDisplayType={tableDisplayType}
      id={column.id}
      testid={`footer-${column.id}`}
      sorted={false}
      editing={false}
      placeholder=""
      onClick={() => { /*empty*/ }}
      onDoubleClick={() => { /*empty*/ }}
      className=""
    />;
  };
}

function footerComponentRenderer(Component: any, footerData?: { [k: string]: any }, footerProps?: { [k: string]: any }) {
  return ({ data, column }) => {
    return <Component data={data} column={column} footerData={footerData} footerProps={footerProps} />;
  };
}

const buildMenuCell = (menuItems: RowMenuItem[]) => {
  return (props: { index: number; row: {id: number}; }) => {
    return (<RowMenu.Cell id={props.row.id} items={menuItems} record={props.row} />);
  };
};

export class TableUI extends React.Component<TableProps, StateProps> {
  private checkableColumnWidth: number;
  private checkable: boolean;
  private cellRefs: {[rowIndex: number]: {[columnIndex: number]: TableCell}} = {};
  private tableColumns: any;
  private handleMoveToBottomRowDebounce: () => void;

  public static defaultProps: Partial<TableProps> = {
    content: undefined,
    filteredRecordIds: [],
    list: false,
  };

  constructor(props: TableProps) {
    super(props);

    this.checkableColumnWidth = 0;

    this.checkable = (props.checkable) as boolean;
    this.setValidationErrorForCell = this.setValidationErrorForCell.bind(this);
    this.setValueForCell = this.setValueForCell.bind(this);
    this.isCurrentCellValid = this.isCurrentCellValid.bind(this);
    this.focusRowContainingCell = this.focusRowContainingCell.bind(this);

    this.buildColumns(props);

    this.state = {
      keysBound: false,
    };
    this.handleMoveToBottomRowDebounce = _.debounce(this.handleMoveToBottomRow, 100).bind(this);
  }

  public componentWillReceiveProps(nextProps: TableProps) {
    const currentlySelectedRow = this.props.selectedRow;
    const currentlySelectedColumn = this.props.selectedColumn;
    const nextSelectedRow = nextProps.selectedRow;
    const nextSelectedColumn = nextProps.selectedColumn;

    const cellIsCurrentlySelected = currentlySelectedRow !== -1 && currentlySelectedColumn !== -1;

    if (nextSelectedRow === -1 || nextSelectedColumn === -1) {
      this.unbindKeys();
    } else {
      this.bindKeys();
    }

    const selectedRowChanged = nextSelectedRow !== currentlySelectedRow;
    const selectedColumnChanged = nextSelectedColumn !== currentlySelectedColumn;
    if (selectedRowChanged) {
      this.ensureRowVisible(nextProps.selectedRow);
    }

    const exitingEditMode = this.props.editing.value && !nextProps.editing.value;
    const editingDifferentCell = nextProps.editing.value && (selectedRowChanged || selectedColumnChanged);

    if (this.props.loading === false) {
      this.validateCell();
    }

    if (cellIsCurrentlySelected && ((exitingEditMode && nextProps.editing.shouldSave) || editingDifferentCell)) {
      if (this.isCurrentCellValid()) {
        // This creates a promise, but we don't await it... it's OK to let it
        // finish when it finishes. Eventually we'll want to update the UI to indicate
        // this is in progress, at which point we'll probably want to do something with
        // returned promise.
        void this.submitCell(currentlySelectedRow, currentlySelectedColumn);
      }
    }

    if (!this.props.editing.value || this.isCurrentCellValid()) {
      const enteringEditMode = !this.props.editing.value && nextProps.editing.value;

      if (selectedRowChanged || selectedColumnChanged) {
        if (cellIsCurrentlySelected) {
          this.setCellBlur(currentlySelectedRow, currentlySelectedColumn);
        }
        if (nextSelectedRow !== -1 && nextSelectedColumn !== -1) {
          this.setCellFocus(nextSelectedRow, nextSelectedColumn);
        }
      }

      if (exitingEditMode && cellIsCurrentlySelected) {
        this.setCellEdit(currentlySelectedRow, currentlySelectedColumn, false);
      }
      if (enteringEditMode || editingDifferentCell) {
        this.setCellEdit(nextSelectedRow, nextSelectedColumn, true);
      }
    }

    if (nextProps.columns !== this.props.columns ||
      nextProps.sorting !== this.props.sorting ||
      nextProps.content !== this.props.content) {  // If content changes, that could mean that the row and header menu items need to be rebuilt
      this.buildColumns(nextProps);
    }

    if (this.props.tableParentInfo.rowsPerPage && nextProps.tableParentInfo.rowsPerPage !== this.props.tableParentInfo.rowsPerPage) {

      // Indication of resized window, need to re-calculate the current page number
      const nextRowsPerPage = nextProps.tableParentInfo.rowsPerPage;

      if (nextRowsPerPage) {
        const currentPageNumber = this.props.tablePageNumber;
        const currentRowsPerPage = this.props.tableParentInfo.rowsPerPage;
        const currentOffset = currentPageNumber * currentRowsPerPage;

        // const totalRows = this.props.totalCount;
        const totalPages = this.getTotalPages(nextProps);

        // Attempt to remap the page number into something reasonable (attempting to keep
        // the topmost row visible on the updated page)
        let newPageNumber;

        if (currentOffset === 0) {
          newPageNumber = 0;
        } else {
          newPageNumber = Math.floor(currentOffset / nextRowsPerPage);
        }

        if (newPageNumber < 0) {
          newPageNumber = 0;
        }

        if (newPageNumber >= totalPages) {
          newPageNumber = totalPages - 1;
        }

        this.props.setTablePageNumber(newPageNumber, 0);
      }
    }

    this.handleMoveToBottomRowDebounce();
  }

  private handleMoveToBottomRow() {
    if (!this.props.content) {
      // If there is no content, then there's nothing to select in the table
      return;
    }

    // Check to see if on the last page
    if (this.props.tablePageNumber === this.getTotalPages() - 1) {
      if (this.props.selectedRow > this.props.content.length - 1) {
        // last page
        this.props.click(this.props.content.length - 1, this.props.selectedColumn);
      }
    } else {
      // Not on last page, check to see if selected row is past the number of available rows
      if (this.props.selectedRow > this.props.content.length - 1) {
        // handle resizing when table has less rows than before resize.
        // also handle when paging up from last page when last page
        // has less rows than the second to last page.
        // the timeout is a bit of a hack but the table doesn't always
        // get loaded fast enough, so we wait a little bit before
        // trying to focus...
        setTimeout(() => {
          if (this.props.tableParentInfo.rowsPerPage) {
            this.props.click(this.props.tableParentInfo.rowsPerPage - 1, this.props.selectedColumn);
            this.setCellFocus(this.props.tableParentInfo.rowsPerPage - 1, this.props.selectedColumn);
          }
        }, 100);
      }
    }
  }

  public shouldComponentUpdate(nextProps: TableProps, nextState: StateProps) {
    const shouldUpdate = (
      nextProps.sorting !== this.props.sorting ||
      nextProps.content !== this.props.content ||
      nextProps.totalCount !== this.props.totalCount ||
      nextProps.loading !== this.props.loading ||
      nextProps.checkedRecordIds !== this.props.checkedRecordIds ||
      nextProps.columns !== this.props.columns ||
      nextProps.tableParentInfo.rowsPerPage !== this.props.tableParentInfo.rowsPerPage ||
      nextProps.tableParentInfo.containerWidth !== this.props.tableParentInfo.containerWidth ||
      nextProps.footerData !== this.props.footerData
    );

    return shouldUpdate;
  }

  public getRowRef = (row: number) => {
    if (row !== -1) {
      const rowRef = this.cellRefs[row];
      if (rowRef && Object.keys(rowRef).length > 0) {
        return rowRef;
      }
      console.warn(`Could not find row ref for [${row}]`);
    }
    return null;
  }

  public getCellRef = (row: number, column: number) => {
    const rowRef = this.getRowRef(row);
    if (rowRef) {
      return rowRef[column];
    }
    return null;
  }

  public render() {
    const { content, columns, loading, onRowSelect, sorting, onSort, list, checkedRecordIds, filteredRecordIds, setTablePageNumber, tablePageNumber } = this.props;
    const checkedIds = checkedRecordIds;
    const showPagination = this.props.tablePaginated;

    const tableClassName = classNames({
      list,
      '-striped': !list,
      'checkable': this.checkable,
      'paginated': showPagination,
    });

    const containerWidth = this.props.tableParentInfo.containerWidth || 0;

    const overallWidth = containerWidth;

    const totalColumnsWidth = this.tableColumns.reduce((accumulator: number, column: any) => {
      const { columnWidth, columnWidthFixed } = column;

      const remainingContainerWidth = overallWidth - this.checkableColumnWidth;

      // add one to account for width of right border.
      return accumulator + (columnWidthFixed ? columnWidthFixed : Math.floor((columnWidth * remainingContainerWidth) / 100.0)) + 1;
    }, 0);

    const totalCalculatedColumnWidth = overallWidth - totalColumnsWidth;
    const overflowColumnSpecified = _.some(this.tableColumns, (c: shame) => c.overflowWidth === true);
    const pageSize = this.props.tableParentInfo.rowsPerPage || 1;

    const totalPages = this.getTotalPages();

    let outerClassName = '';
    if (!content) {
      outerClassName = 'table-invisible';
    }

    const tableContent = !this.props.tableParentInfo.containerHeight && _.isEmpty(content) ? [{}] : content;

    return (
      <div className={`${this.props.tableClassName} ${outerClassName}`}>
        <Table
          className={tableClassName}
          data={tableContent}
          columns={this.tableColumns.map((c: IColumn, index: number) => {
            const sortInfo = sorting?.find(s => s.sortField === c.id);
            const headerClassName = classNames(
              ...(c.extraHeaderClassNames ?? []),
              {
                'mfc-ascending': sortInfo?.sortOrder === SORT_TYPES.ASC,
                'mfc-descending': sortInfo?.sortOrder === SORT_TYPES.DESC,
                'mfc-sorted': sortInfo?.sortField === c.id,
              },
            );

            const { columnWidth, columnWidthFixed, footer, footerComponent, ...columnProps } = c;

            const remainingContainerWidth = overallWidth - this.checkableColumnWidth;

            // Only adding one here - seems to allow the options menu column to show up better when there's a scrollbar
            let width = remainingContainerWidth;
            if (columnWidthFixed !== undefined) {
              width = columnWidthFixed;
            } else if (columnWidth !== undefined) {
              width = Math.floor((columnWidth * remainingContainerWidth) / 100.0) + 1;
            }

            if (!overflowColumnSpecified) {
              // Adjust first column (if not checkable) or second column (if checkable)
              // to account for "left-over" pixels due to floating point percentages.
              const adjustmentColumnIndex = this.checkable ? 1 : 0;
              if (index === adjustmentColumnIndex) {
                width += totalCalculatedColumnWidth;
              }
            } else {
              if (c.overflowWidth) {
                width += totalCalculatedColumnWidth;
              }
            }

            const withFooterCellRenderer =
              footerComponent ? footerComponentRenderer(footerComponent, this.props.footerData, this.props.footerProps) : footer ? footerCellRenderer(footer) : undefined;

            return {
              headerClassName,
              width,
              footer: withFooterCellRenderer,
              ...(columnWidthFixed ? { minWidth: width, maxWidth: width } : { minWidth: width }),
              ...columnProps,
            };
          })}
          noDataText={this.props.noDataText || 'No results for given filter and search criteria.'}
          loading={loading}
          showPagination={showPagination}
          showPageSizeOptions={false}
          pageSize={pageSize}
          pages={totalPages}
          page={tablePageNumber}
          PaginationComponent={showPagination ? PaginationComponent : undefined}
          minRows={0}
          manual
          onPageChange={setTablePageNumber}
          getTbodyProps={() => {
            return {
              style: {
                maxHeight: this.props.tableParentInfo.bodyHeight,
                height: this.props.tableParentInfo.bodyHeight,
              },
            };
          }}
          getTableProps={() => {
            return {
              style: {
                maxHeight: this.props.tableParentInfo.containerHeight,
                height: this.props.tableParentInfo.containerHeight,
                width: this.props.tableParentInfo.containerWidth,
              },
            };
          }}
          getTheadTrProps={() => {
            const intersectedIds = _.intersection(checkedIds, filteredRecordIds);

            return {
              className: classNames({
                'all-rows-checked': this.checkable && intersectedIds.length > 0 && intersectedIds.length === filteredRecordIds.length,
                'some-rows-checked': this.checkable && checkedRecordIds.length > 0,
              }),
            };
          }}
          getTheadThProps={(irrelevant: any, rowInfo: any, column: any) => {
            return {
              onClick: event => {
                if (this.props.editing.value) {
                  this.handleClickOutside();

                  // Treat the click like a lack of focus for the edit cell only. Make the user click
                  // again to do the actual sort. This prevents the table from sorting immediately and then
                  // getting new data back that looks screwy because it's not sorted properly (and seems to
                  // result in other oddities like the value in unexpected rows as well).
                  return;
                }

                if (column.id === 'row-checkbox' && this.checkable) {
                  this.props.toggleCheckAllRecords(checkedIds, filteredRecordIds);
                  return;
                } else if (column.id === 'row-menu') {
                  // Don't intercept a kebab menu
                  return;
                }

                if (column.sortable && onSort) {
                  onSort(column.id, event.shiftKey);
                }
              },
            };
          }}
          getTrProps={(irrelevant: any, rowInfo: any) => {
            if (!this.checkable) {
              return {
                id: `record-id-${rowInfo.row.id}`,
              };
            }

            if (checkedRecordIds.includes(rowInfo.row.id)) {
              return {
                className: 'row-checked',
                id: `record-id-${rowInfo.row.id}`,
              };
            }

            return {
              id: `record-id-${rowInfo.row.id}`,
            };
          }}
          getTdProps={(irrelevant: any, rowInfo: any, column: any) => {
            const columnIndex = columns.findIndex(c => c.id === column.id);

            return {
              onClick: () => {
                if (this.props.editing.value) {
                  this.validateCell();
                  if (!this.isCurrentCellValid()) {
                    return;
                  }
                }

                // allow kabab menu to do its thing
                if (column.id === 'row-menu') {
                  return;
                }

                // user is toggling a checkbox
                if (this.checkable && column.id === 'row-checkbox') {
                  this.props.toggleCheckSingleRecord(rowInfo.row.id);
                  return;
                }

                this.props.click(rowInfo.index, columnIndex);

                // we have a record backing this row, fire off any handlers that have been passed by props
                if (rowInfo.row  && onRowSelect) {
                  onRowSelect(rowInfo.row);
                }
              },
              onDoubleClick: () => {
                const currentCellIsEditable = this.isCellEditable();

                if (!this.props.editing.value && currentCellIsEditable) {
                  this.props.edit(true);
                }
              },
            };
          }}
        />
      </div>
    );
  }

  public setCellFocus = (row: number, column: number) => {
    const cellRef = this.getCellRef(row, column);
    if (!cellRef) {
      console.warn(`Could not find cell ref for [${row}][${column}] while focusing.`);
    } else {
      cellRef.focus();
    }
  }

  private focusRowContainingCell(cellRef: TableCell, focus: boolean) {
    // This isn't a very "React" way of doing things, but with the ReactTable library's
    // performance re-rendering large tables we need to be really careful to avoid
    // re-renders (especially on the Product Worksheet). So we're cheating here and
    // just doing some old-fashioned DOM manipulation to add/remove a class to highlight
    // the row the focused cell is in.

    const cellDomNode: HTMLElement = findDOMNode<HTMLElement>(cellRef);
    const rowDomNode: HTMLElement | Nil = cellDomNode?.parentElement?.parentElement;
    if (!rowDomNode)
      return;

    if (focus) {
      if (!rowDomNode.classList.contains('focused-row'))
        rowDomNode.classList.add('focused-row');
    } else if (rowDomNode.classList.contains('focused-row'))
      rowDomNode.classList.remove('focused-row');
  }

  public isCellEditable = (): boolean => {
    const cell = {
      column: this.props.selectedColumn,
      row: this.props.selectedRow,
    };
    return isCellEditable(this.props.columns, cell, this.props.content);
  }

  public setCellEdit = (row: number, column: number, editing: boolean) => {
    const cellRef = this.getCellRef(row, column);
    if (!cellRef)
      console.warn(`Could not find cell ref for [${row}][${column}] while editing.`);
    else
      cellRef.setEdit(editing);
  }

  public setCellBlur = (row: any, column: any) => {
    const cellRef = this.getCellRef(row, column);
    if (!cellRef)
      console.warn(`Could not find cell ref for [${row}][${column}] while blurring.`);
    else
      cellRef.blur();
  }

  public setCellRef = (colIdx: number) => (rowIdx: number, ref: TableCell) => {
    if (!this.cellRefs[rowIdx]) {
      this.cellRefs[rowIdx] = {};
    }
    this.cellRefs[rowIdx][colIdx] = ref;
  }

  private getPageSize(props: TableProps = this.props) {
    return props.tableParentInfo.rowsPerPage || 1;
  }

  private getTotalPages(props: TableProps = this.props) {
    if (props.content) {
      return props.totalCount > this.getPageSize(props)
      ? Math.ceil(props.totalCount * 1.0 / this.getPageSize(props))
      : 1;
    }

    return 1;
  }

  private inScrollingMode() {
    return !this.props.content || this.props.content.length === this.props.totalCount;
  }

  public bindKeys = () => {
    if (!this.state.keysBound && !this.props.list) {
      const moveIt = (direction: any, distance: any) => (event: ExtendedKeyboardEvent) => this.moveCell(event, direction, distance);

      const pageUp = () => (event: ExtendedKeyboardEvent) => {
        if (this.inScrollingMode()) {
          if (this.props.tableParentInfo.rowsPerPage) {
            // scrolling: move up one page full of rows
            this.moveCell(event, NAVIGATE_TABLE_DIRECTIONS.UP, this.props.tableParentInfo.rowsPerPage);
          }
        } else {
          // paginated
          if (this.props.tablePageNumber > 0) {
            // if we are not on the first page
            if (this.props.selectedRow === 0) {
              // if we are on the top row, move to the previous page
              // and set the row position to the first row on the previous page.
              this.props.setTablePageNumber(this.props.tablePageNumber - 1, 0);
            } else {
              // otherwise, set the row position to the top of the current page
              this.props.click(0, this.props.selectedColumn);
            }
          } else {
            // if we are on the first page, move to the top row
            this.props.click(0, this.props.selectedColumn);
          }
        }
      };

      const pageDown = () => (event: ExtendedKeyboardEvent) => {
        if (this.inScrollingMode()) {
          if (this.props.tableParentInfo.rowsPerPage) {
            // scrolling: move down one page full of rows
            this.moveCell(event, NAVIGATE_TABLE_DIRECTIONS.DOWN, this.props.tableParentInfo.rowsPerPage);
          }
        } else if (this.props.content) {
          // if are not on the last page
          if (this.props.tablePageNumber < this.getTotalPages() - 1) {
            // if we are on the last row of the current page,
            // move to the next page and set the row position to the bottom
            if (this.props.selectedRow >= this.props.content.length - 1) {
              this.props.setTablePageNumber(this.props.tablePageNumber + 1, this.props.content.length - 1);
            } else {
              // if we are on the last page, set the cursor position
              // to the last row on the page
              this.props.click(this.props.content.length - 1, this.props.selectedColumn);
            }
          } else {
            // if we are not on the last row of the current page,
            // set the cursor position to the last row on the current page.
            this.props.click(this.props.content.length - 1, this.props.selectedColumn);
          }

          if (this.props.selectedRow > this.props.content.length) {
            // set the cursor position to the last visible row on the page
            // in case the page has less rows than can fit.
            this.props.click(this.props.content.length - 1, this.props.selectedColumn);
          }
        }
      };

      const home = () => (event: ExtendedKeyboardEvent) => {
        if (this.inScrollingMode()) {
          // if we scrolling, set the cursor position
          // to the first row.
          this.props.click(0, this.props.selectedColumn);
        } else {
          // if we are paginated, move to the first
          // page and set the cursor position to the
          // first row.
          this.props.setTablePageNumber(0);
          this.props.click(0, this.props.selectedColumn);
        }
      };

      const end = () => (event: ExtendedKeyboardEvent) => {
        if (this.inScrollingMode()) {
          // if we are scrolling, set the cursor position
          // to the last row.
          this.props.click(this.props.totalCount - 1, this.props.selectedColumn);
        } else if (this.props.content) {
          // if we are paginated, move to the last
          // page and set the cursor position to the
          // last row.
          this.props.setTablePageNumber(this.getTotalPages() - 1);
          this.props.click(this.props.content.length - 1, this.props.selectedColumn);
        }
      };

      Mousetrap.bind('up', moveIt(NAVIGATE_TABLE_DIRECTIONS.UP, 1));
      Mousetrap.bind('down', moveIt(NAVIGATE_TABLE_DIRECTIONS.DOWN, 1));
      Mousetrap.bind('home', home());
      Mousetrap.bind('pageup', pageUp());
      Mousetrap.bind('pagedown', pageDown());
      Mousetrap.bind('end', end());
      Mousetrap.bind('left', moveIt(NAVIGATE_TABLE_DIRECTIONS.LEFT, 1));
      Mousetrap.bind('right', moveIt(NAVIGATE_TABLE_DIRECTIONS.RIGHT, 1));
      Mousetrap.bind('tab', this.handleTab);
      Mousetrap.bind('shift+tab', this.handleShiftTab);
      Mousetrap.bind('enter', this.handleEnter);
      Mousetrap.bind('shift+enter', this.handleShiftEnter);
      Mousetrap.bind('esc', this.handleEsc);
      Mousetrap.bind('f2', this.handleF2);
      Mousetrap.prototype.stopCallback = (e: ExtendedKeyboardEvent, element: HTMLElement, combo: string) => {
        // Overwriting the default behavior as described here: https://craig.is/killing/mice#api.stopCallback (AP 4/3/17)
        // if the element has the class "mousetrap" then no need to stop
        if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
          return false;
        }

        // stop for input, select, and textarea
        const keys = ['esc', 'enter', 'tab', 'shift+tab', 'shift+enter'];
        return (element.tagName === 'INPUT' && !_.includes(keys, combo)) || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA' || (element.contentEditable && element.contentEditable === 'true');
      };

      this.setState({ keysBound: true });
    }
  }

  public clickOutsideHandler = () => {
    this.validateCell();
    if (this.isCurrentCellValid()) {
      if (this.props.selectedRow !== -1 || this.props.selectedColumn !== -1) {
        this.setCellBlur(this.props.selectedRow, this.props.selectedColumn);
        this.props.clickOutside();
      }
    }
  }

  public unbindKeys = () => {
    if (this.state.keysBound && !this.props.list) {
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
      Mousetrap.unbind('pageup');
      Mousetrap.unbind('pagedown');
      Mousetrap.unbind('left');
      Mousetrap.unbind('right');
      Mousetrap.unbind('tab');
      Mousetrap.unbind('shift+tab');
      Mousetrap.unbind('enter');
      Mousetrap.unbind('shift+enter');
      Mousetrap.unbind('esc');
      Mousetrap.unbind('f2');

      this.setState({ keysBound: false });
    }
  }

  public ensureRowVisible(row: any) {
    // The following was adapted from https://github.com/furqanZafar/react-selectize/blob/master/src/DropdownMenu.ls#L195-L220
    const rowRefs = this.getRowRef(row);

    if (!rowRefs) {
      return;
    }

    const itemRef = rowRefs[0];
    const rowElement = findDOMNode<HTMLElement>(itemRef);
    const parentElement: any = document.querySelectorAll(`${this.props.tableClassName ? `.${this.props.tableClassName}` : ''} .rt-tbody`)[0];

    if (!rowElement || !parentElement) {
      console.warn('could not find row and/or parent element for scrolling');
      return;
    }

    const rowHeight = rowElement.offsetHeight;

    // in other words, if the option element is below the visible region
    if ((rowElement.offsetTop - parentElement.scrollTop) >= parentElement.offsetHeight) {
      // scroll the option element into view, by scrolling the parent element downward by an amount equal to the
      // distance between the bottom-edge of the parent-element and the bottom-edge of the option element
      parentElement.scrollTop = rowElement.offsetTop - parentElement.offsetHeight + rowHeight;

      // in other words, if the option element is above the visible region
    } else if ((rowElement.offsetTop - (parentElement.scrollTop + rowHeight)) <= 0) {
      // scroll the option element into view, by scrolling the parent element upward by an amount equal to the
      // distance between the top-edge of the option element and the top-edge of the parent element
      parentElement.scrollTop = rowElement.offsetTop - rowHeight - 1;
    }
  }

  private setValueForCell(row: number, col: number, value: string) {
    const cellRef = this.getCellRef(row, col);

    if (cellRef) {
      cellRef.setValue(value);
    }
  }

  private setValidationErrorForCell(row: number, col: number, message: string) {
    const cellRef = this.getCellRef(row, col);

    if (cellRef) {
      cellRef.setValidationError(message);
    }
  }

  private clearErrorFromSavingIfOptionEnabled() {
    const cellRef = this.getCellRef(this.props.selectedRow, this.props.selectedColumn);
    if (cellRef) {
      const columnOptions = this.props.columns[this.props.selectedColumn].options;

      if (columnOptions?.clearSaveErrorBeforeSave) {
        cellRef.setErrorFromSaving(undefined);
      }
    }
  }

  private clearErrors() {
    const cellRef = this.getCellRef(this.props.selectedRow, this.props.selectedColumn);
    if (cellRef) {
      cellRef.setErrorFromSaving(undefined);
      cellRef.setValidationError(undefined);
    }
  }

  private handleEnterInCell(direction: NavigateTableDirection) {
    const currentCellIsEditable = this.isCellEditable();
    const blurOnSaveLastRow = this.props.columns[this.props.selectedColumn].options ?
      this.props.columns[this.props.selectedColumn].options!.blurOnSaveLastRow ?? false : false;

    if (this.props.editing.value) {
      this.clearErrorFromSavingIfOptionEnabled();
      this.validateCell();
      if (this.isCurrentCellValid()) {
        this.clearErrors();
        this.props.moveEditCell(direction, 1, blurOnSaveLastRow);
      }

    } else if (!this.props.editing.value && currentCellIsEditable) {
      this.props.edit(true);
    }
  }

  private handleTabInCell(direction: NavigateTableDirection) {
    if (_.isNil(this.props.selectedRow) || _.isNil(this.props.selectedColumn)) {
      return true;
    }

    const isEditing = this.props.editing.value;
    if (isEditing) {
      this.clearErrorFromSavingIfOptionEnabled();
      this.validateCell();
    }

    if (!isEditing || (isEditing && this.isCurrentCellValid())) {
      this.clearErrors();
      this.props.editNextEditableCell(direction, this.props.columns);
    }

    // Don't let the default tab behavior escape
    return false;
  }

  public handleEnter = () => {
    this.handleEnterInCell(NAVIGATE_TABLE_DIRECTIONS.DOWN);
  }

  public handleShiftEnter = () => {
    this.handleEnterInCell(NAVIGATE_TABLE_DIRECTIONS.UP);
  }

  public handleTab = () => {
    return this.handleTabInCell(NAVIGATE_TABLE_DIRECTIONS.RIGHT);
  }

  public handleShiftTab = () => {
    return this.handleTabInCell(NAVIGATE_TABLE_DIRECTIONS.LEFT);
  }

  public handleF2 = () => {
    const currentCellIsEditable = this.isCellEditable();

    if (!this.props.editing.value && currentCellIsEditable) {
      this.props.edit(true);
    }
  }

  public handleEsc = () => {
    if (this.props.columns[this.props.selectedColumn] && this.props.columns[this.props.selectedColumn].saveOnChange && !this.isCurrentCellValid()) {
      return;
    }

    if (this.props.editing.value) {
      const cellRef = this.getCellRef(this.props.selectedRow, this.props.selectedColumn);
      if (cellRef) {
        const fieldName = this.props.columns[this.props.selectedColumn].id;
        const accessor = this.props.columns[this.props.selectedColumn].accessor;
        const originalValue = typeof accessor === 'string'
          ? this.props.content && _.get(this.props.content[this.props.selectedRow], fieldName)
          : this.props.content ?
            accessor(this.props.content[this.props.selectedRow])
            : '';

        cellRef.setValue(originalValue);
        this.clearErrors();
      }

      this.setCellEdit(this.props.selectedRow, this.props.selectedColumn, false);
      this.props.edit(false, false);
    } else {
      this.props.clickOutside();
    }
  }

  public moveCell = (event: ExtendedKeyboardEvent, direction: any, distance: number) => {
    if (this.props.columns[this.props.selectedColumn] && this.props.columns[this.props.selectedColumn].saveOnChange && !this.isCurrentCellValid()) {
      return;
    }

    const blurOnSaveLastRow = this.props.columns[this.props.selectedColumn].options ?
      this.props.columns[this.props.selectedColumn].options!.blurOnSaveLastRow ?? false : false;

    if (event.preventDefault) {
      event.preventDefault();
    } else {
      // internet explorer
      event.returnValue = false;
    }

    if (this.props.editing.value) {
      this.validateCell();
    }

    if (!this.props.editing.value || this.isCurrentCellValid()) {
      this.props.move(direction, distance, blurOnSaveLastRow);
    }
  }

  private validateCell(): void {
    let errorMessage: string | undefined;
    const { selectedRow, selectedColumn, content } = this.props;

    if (selectedRow === -1 || selectedColumn === -1) {
      return undefined;
    }

    const cellRef = this.getCellRef(selectedRow, selectedColumn);
    if (cellRef) {
      const record = content && content[selectedRow];
      const fieldName = this.props.columns[selectedColumn].id;
      const value = cellRef.getValue();

      const validators = this.props.columns[selectedColumn].validators || null;

      if (validators) {
        for (const validator of validators) {
          const theValidator = typeof validator === 'function'
            ? validator(this.props)
            : validator;

          errorMessage = makeFormValidator(theValidator)(value, Object.assign({}, record, { [fieldName]: value }));
          if (errorMessage) {
            break;
          }
        }
      }

      if (!errorMessage) {
        cellRef.setValidationError(undefined);
        this.props.onInvalidCellDetected(false);
      } else {
        cellRef.setValidationError(errorMessage);
        this.props.onInvalidCellDetected(true);
      }
    }
  }

  private isCurrentCellValid(): boolean {
    const { selectedRow, selectedColumn } = this.props;
    const cellRef = this.getCellRef(selectedRow, selectedColumn);
    return !_.isNil(cellRef) && _.isNil(cellRef.getValidationError()) && _.isNil(cellRef.getErrorFromSaving());
  }

  private async submitCell(row: number, column: number) {
    const { content } = this.props;
    const record = content && content[row];

    const cellRef = this.getCellRef(row, column);
    if (!cellRef) {
      console.error(`Could not find cell ref for record ${record.id} on ${row} - ${column}`);
    } else {
      return cellRef.saveCurrentValue();
    }
  }

  private buildColumns(props: TableProps) {
    const dataColumns = props.columns.map((c: any, columnIndex: number) => Object.assign({}, c, {
      render: buildTableCell({
        column: c,
        props,
        setRef: this.setCellRef(columnIndex),
        isCurrentCellValid: this.isCurrentCellValid,
        focusRowContainingCell: this.focusRowContainingCell,
      }),
    }));

    if (!this.checkable) {
      this.tableColumns = dataColumns;
    } else {
      const rowSelectorColumn = RowSelector.Column();

      const mappedHeaderMenuItems: RowMenuItem[] = (props.headerMenuItems || []).map(headerMenuItem => ({
        label: headerMenuItem.label,
        onClick: () => {
          const checkedIds = this.props.checkedRecordIds;
          headerMenuItem.onClick(checkedIds);
          if (headerMenuItem.uncheckRecordFollowingClick) {
            this.props.uncheckMultipleRecords(checkedIds);
          }
        },
        shouldDisplay: headerMenuItem.shouldDisplay,
        willRemove: headerMenuItem.willRemove,
      }));

      const mappedRowMenuItems: RowMenuItem[] = (props.rowMenuItems || []).map(menuItem => ({
        label: menuItem.label,
        onClick: (recordId: number[], record?: shame) => {
          menuItem.onClick(recordId, record);
          if (menuItem.uncheckRecordFollowingClick) {
            this.props.uncheckMultipleRecords([recordId]);
          }
        },
        shouldDisplay: menuItem.shouldDisplay,
        willRemove: menuItem.willRemove,
      }));

      const rowMenuColumn = RowMenu.Column(mappedHeaderMenuItems);

      this.checkableColumnWidth = (rowSelectorColumn.columnWidthFixed || 0) + (rowMenuColumn.columnWidthFixed || 0);

      this.tableColumns = [
        Object.assign({}, rowSelectorColumn, {
          render: buildTableCell({
            column: rowSelectorColumn,
            props: this.props,
            isCurrentCellValid: this.isCurrentCellValid,
            focusRowContainingCell: this.focusRowContainingCell,
          }),
        }),
        ...dataColumns,
        Object.assign({}, rowMenuColumn, { render: buildMenuCell(mappedRowMenuItems) }),
      ];
    }
  }

  public handleClickOutside() {
    this.clickOutsideHandler();
  }
}

export default TableUI;
