import { buildAdminDetailPage } from 'client/containers/admin/admin-detail-page';
import RelatedSupplierOrdersPage from './related-supplier-orders-page-container';
import RecordBarActions from 'client/app/orders/customer-orders/record-bar-actions';
import navBuilder from 'client/app/orders/customer-orders/nav-items';
import { saveConfirmationOptionsForCustomerOrder } from 'client/app/orders/customer-orders/save-confirmation-message';

export default buildAdminDetailPage({
  table: 'CustomerOrder',
  formName: 'CustomerOrderRelatedSupplierOrdersForm',
  FormComponent: RelatedSupplierOrdersPage,
  OptionalStatusToggle: RecordBarActions,
  navBuilder,
  saveConfirmationOptions: saveConfirmationOptionsForCustomerOrder,
  formClassName: 'mfc-form mfc-form-not-scrollable',
});
