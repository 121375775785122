import * as _ from 'lodash';
import { ServerSideValidationInfo } from 'shared/validators';
import { Action } from 'client/types/redux-types';
import { ErrorActionTypeKeys } from 'client/constants';

export interface ErrorState {
  readonly validationError?: {
    readonly validations: ServerSideValidationInfo[];
    // message: string
  };
  readonly expectedError?: {
    readonly message: string;
    readonly debugInfo?: any;
  };
  readonly unexpectedError?: {
    readonly message: string;
    readonly stack?: string[] | string;
  };
}

const INITIAL_STATE: ErrorState = { };

function errorReducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }): ErrorState {
  switch (action.type) {

    case ErrorActionTypeKeys.ERRORS_VALIDATION_ERROR_RECEIVED: {
      const validations: ServerSideValidationInfo[] = action.payload.validations;

      const newState = {
        validationError: {
          validations,
        },
      };

      // To avoid re-rendering parts of the page, potentially causing an infinite loop of
      // errors, whenever an error is displayed - make sure there's a need to update the state.
      return _.isEqual(newState, state) ? state : newState;
    }

    case ErrorActionTypeKeys.ERRORS_ERROR_ALERT_CLOSE_CLICKED: {
      return {};
    }

    case ErrorActionTypeKeys.ERRORS_EXPECTED_ERROR_RECEIVED: {
      const newState = {
        expectedError: action.payload,
      };

      // To avoid re-rendering parts of the page, potentially causing an infinite loop of
      // errors, whenever an error is displayed - make sure there's a need to update the state.
      return _.isEqual(newState, state) ? state : newState;
    }

    case ErrorActionTypeKeys.ERRORS_UNEXPECTED_ERROR_RECEIVED: {
      const newState = {
        unexpectedError: action.payload,
      };

      // To avoid re-rendering parts of the page, potentially causing an infinite loop of
      // errors, whenever an error is displayed - make sure there's a need to update the state.
      return _.isEqual(newState, state) ? state : newState;
    }

    default:
      return state;
  }
}

export default errorReducer;
