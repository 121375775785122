import { buildAdminListPage } from '../admin';
import { Customer } from 'shared/schemas/customer';

export default buildAdminListPage(Customer, {
  columns: ['identifier', 'name', 'phone', 'city', 'state', 'zip', 'contact', 'email', 'activeStatus'],
  excelColumns: [
    'identifier',
    'name',
    'phone',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'country',
    'fax',
    'contact',
    'email',
    'terms',
    'discount',
    'billType',
    'skuRequired',
    'billingAddress1',
    'billingAddress2',
    'billingCity',
    'billingState',
    'billingZip',
    'billingPhone',
    'billingFax',
    'billingContact',
    'billingEmail',
    'mfcContactEmail',
    'vendorNumber',
    'supportsEdiInvoicing',
    'prefersIsoWeeks',
    'activeStatus',
  ],
});
