// ///////////////////////////////////////////////////////////
// Files that need to be loaded for their side-effects
// import 'babel-polyfill';
import 'es5-shim';
import 'es6-shim';
import 'es6-promise';
import 'jquery';
import 'bootstrap-datepicker'; // needed to pre-initialize
// import './styles/main.scss';
import './assets/precompiled.css';
import 'shared/validations';
// ///////////////////////////////////////////////////////////

import * as React from 'react';
/* REACT-16-UPGRADE-ROLLBACK
 * Used for "Duck Punching" react 16 upgrade. Temporarily rolling back upgrade for now.
 */
import * as PropTypes from 'prop-types';
import * as CreateClass from 'create-react-class';
(React as shame).PropTypes = PropTypes;
(React as shame).createClass = CreateClass;

import * as ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { getClient } from './client';
import routes from './store/routes';
import configureStore, { history } from './store/configure-store';
import { ConnectedRouter } from 'connected-react-router';

function disableCssAnimations() {
  const head = document.head;

  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(`
    *,
    *:after,
    *:before {
      transition: none !important;
      transform: none !important;
    }
  `));

  head.appendChild(style);
}

if (process.env.__ACCEPTANCE_TESTS__ || process.env.NODE_ENV !== 'production') {
  (window as any).timekeeper = require('timekeeper');
}

if (process.env.__ACCEPTANCE_TESTS__ || process.env.NODE_ENV === 'test') {
  disableCssAnimations();
}

(global as any).__root = __dirname + '/';

const store = configureStore();
const client = getClient(undefined, store.getState as shame<'TODO enhance types of configureStore'>);

// Make the client available globally so it can be accessed in Action Creators. Should
// be accessed with the apolloClient() function from those Action Creators.
(global as any).apolloClient = client;

ReactDOM.render(
  <ApolloProvider client={ client }>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        { routes }
      </ConnectedRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
