import * as React from 'react';
import { createSelector } from 'reselect';
import { Modal, Row, FormGroup, Col } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { Carrier, formatDollarsAndCents } from 'shared/types';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { SimpleSelect } from 'client/components/simple-components/simple-select';
import { SimpleInput } from 'client/components/simple-components/simple-input';
import { ProgressSpinner } from 'client/components/progress-spinner';

export interface ComponentProps {
  isShown: boolean;
  applyButtonDisabled: boolean;
  setCarrierButtonDisabled: boolean;
  routingLoadIds: RoutingLoadId[];
  handleCancelButtonClicked(): void;
  handleSetCarrierButtonClicked(): void;
  handleApplyButtonClicked(): void;
  routingLoads: Array<{
    identifier: string;
    totalFee: number;
    carrier: string;
    invoiceNumber: string;
  }>;
  loading: boolean;
  totalCost: number;
  carrier: Carrier;
  handleCarrierChanged(value: string): void;
  invoiceNumber: string;
  handleInvoiceNumberChanged(value: string): void;
}

const carrierOptions = Object.keys(Carrier);

const getRoutingLoads = (props: ComponentProps) => props.routingLoads || [];
const buildRoutingLoadModalRows = (routingLoads: ComponentProps['routingLoads']) => (
  <div>
    {routingLoads.map(routingLoad => {
      return (
        <div key={routingLoad.identifier} className="apply-invoice-to-loads-modal-load" data-testid={`apply-invoice-to-loads-modal-row-${routingLoad.identifier}`}>
          <Col sm={4} className="apply-invoice-to-loads-modal-load-number">{routingLoad.identifier}</Col>
          <Col sm={2} className="apply-invoice-to-loads-modal-carrier">{routingLoad.carrier}</Col>
          <Col sm={3} className="apply-invoice-to-loads-modal-invoice-number">{routingLoad.invoiceNumber}</Col>
          <Col sm={3} className="apply-invoice-to-loads-modal-total-fee">{formatDollarsAndCents(routingLoad.totalFee)}</Col>
        </div>
      );
    })}
  </div>);
// Memoize routingLoads so that unnecessary re-renders are avoided.
const routingLoadsSelector = createSelector([getRoutingLoads], buildRoutingLoadModalRows);

export class ApplyInvoiceToLoadsModalUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="apply-invoice-to-loads-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="apply-invoice-to-loads-modal-container"
        className="apply-invoice-to-loads-modal">
        <GlobalAsyncModalHeader>Apply Invoice to Loads</GlobalAsyncModalHeader>
        <Modal.Body>
          {this.props.loading && <ProgressSpinner label="Loading..." />}

          {!this.props.loading &&
            <div>
              <div className="apply-invoice-to-loads-modal-load apply-invoice-to-loads-modal-header">
                <Col sm={4} className="apply-invoice-to-loads-modal-load-number-header">Load ID</Col>
                <Col sm={2} className="apply-invoice-to-loads-modal-carrier-header">Carrier</Col>
                <Col sm={3} className="apply-invoice-to-loads-modal-invoice-number-header">Invoice #</Col>
                <Col sm={3} className="apply-invoice-to-loads-modal-total-fee-header">Cost</Col>
              </div>

              {routingLoadsSelector(this.props)}

              <Col sm={12} className="apply-invoice-to-loads-modal-total-cost">
                Total Cost {formatDollarsAndCents(this.props.totalCost)}
              </Col>
              <Row className="apply-invoice-to-loads-modal-form">
                <FormGroup>
                  <SimpleSelect
                    label="Carrier"
                    testid="apply-invoice-to-loads-modal-carrier"
                    options={carrierOptions}
                    onChange={this.props.handleCarrierChanged}
                    value={this.props.carrier}
                    horizontalLabel
                    labelColSize={3}
                    inputColSize={5}
                  />
                  <Col sm={4} className="apply-invoice-to-loads-modal-submit-button">
                    <GlobalSaveButton
                      label="Set Carrier"
                      testid="apply-invoice-to-loads-modal-set-carrier-button"
                      disabled={this.props.setCarrierButtonDisabled}
                      onClick={this.props.handleSetCarrierButtonClicked}
                      classNames={['mfc-button-primary']}
                    />
                  </Col>
                </FormGroup>
              </Row>

              <Row className="apply-invoice-to-loads-modal-form">
                <FormGroup>
                  <SimpleInput
                    label="Invoice #"
                    testid="apply-invoice-to-loads-modal-invoice-number"
                    onChange={this.props.handleInvoiceNumberChanged}
                    value={this.props.invoiceNumber}
                    horizontalLabel
                    labelColSize={3}
                    inputColSize={5}
                    autoFocus={true}
                  />
                  <Col sm={4} className="apply-invoice-to-loads-modal-submit-button">
                    <GlobalSaveButton
                      label="Apply Invoice"
                      testid="apply-invoice-to-loads-modal-set-invoice-number-button"
                      disabled={this.props.applyButtonDisabled}
                      onClick={this.props.handleApplyButtonClicked}
                      classNames={['mfc-button-primary']}
                    />
                  </Col>
                </FormGroup>
              </Row>

            </div>
          }

        </Modal.Body>
        <Modal.Footer>

          <GlobalCancelButton
            label="Close"
            onClick={this.props.handleCancelButtonClicked}
            testid="apply-invoice-to-loads-modal-cancel-button"
          />
        </Modal.Footer>
      </Modal >
    );
  }
}
