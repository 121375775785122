import * as ExcelHelpers from 'shared/file-parsers/excel/common';
import * as ImportModalTypes from 'client/components/import-modal/types';
import * as Mutations from './mutations';
import * as Queries from './queries';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as SupplierCommitmentParser from 'shared/file-parsers/excel/supplier-commitment-spreadsheet-parser';
import { timeout } from 'shared/helpers';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GetSupplierCommitmentDetailTableQueryName } from '../supplier-commitment-allocation-table/query';

export type ActionTypes =
  CancelButtonClickedAction;

export enum ActionTypeKeys {
  CancelButtonClicked = 'App/ImportSupplierCommitmentCanceled',
}

export interface CancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.CancelButtonClicked;
}

export function handleCancelButtonClicked(): CancelButtonClickedAction {
  return {
    type: ActionTypeKeys.CancelButtonClicked,
  };
}

export function handleCloseButtonClicked(): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(handleCancelButtonClicked());
  };
}

export function handleParsingStarted(args: { fileName: string, contents: string }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await timeout(100);

    const workBookContainer = await ExcelHelpers.getWorkBookContainerFromBinaryString(args.fileName, args.contents);
    const parsedResults = await SupplierCommitmentParser.getImportableSupplierCommitments(workBookContainer);

    if (parsedResults.success) {
      done([], parsedResults.importableSupplierCommitments);
    } else {
      done([parsedResults.reason]);
    }
  };
}

export function handleValidationStarted(args: { existingRecordId: number | undefined, importableObject: SupplierCommitmentParser.ImportableSupplierCommitment[] }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to validate a supplier commitment');
  }

  return async (dispatch: Redux.Dispatch<any>) => {
    await timeout(100);

    try {
      const result = await msyncClientQuery<Queries.ValidateImportSupplierCommitmentQueryResponse, Queries.ValidateImportSupplierCommitmentQueryArgs>(
        {
          query: Queries.ValidateImportSupplierCommitmentQuery,
          variables: {
            input: {
              supplierCommitmentId: existingRecordId,
              parsedSupplierCommitments: args.importableObject,
            },
          },
          dispatch,
          disableGlobalError: true,
          fetchPolicy: 'network-only',
        },
      );

      const errors = result.data.validateSupplierCommitmentImport.errors;
      done(errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleImportStarted(args: { existingRecordId: number | undefined, importableObject: SupplierCommitmentParser.ImportableSupplierCommitment[] }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to import a supplier commitment');
  }

  return async (dispatch: Redux.Dispatch<any>) => {
    await timeout(100);

    try {
      const response = await msyncClientMutation<Mutations.ImportSupplierCommitmentFromSpreadsheetResponse, Mutations.ImportSupplierCommitmentFromSpreadsheetArgs>(
        {
          mutation: Mutations.ImportSupplierCommitmentMutation,
          variables: {
            input: {
              supplierCommitmentId: existingRecordId,
              parsedSupplierCommitments: args.importableObject,
            },
          },
          dispatch,
          suppressErrorModal: true,
          refetchQueries: [GetSupplierCommitmentDetailTableQueryName],
        },
      );

      done(response.data.importSupplierCommitmentResult.errors);
    } catch (error) {
      done([error.message]);
    }
  };
}
