import * as _ from 'lodash';
import * as React from 'react';
import { formatInteger, ReceivingSummaryStats } from 'shared/types';
import { StatsCard } from 'client/components/simple-components/simple-stats-card';

export function ReceivingSupplierOnHandCard(props: Pick<ReceivingSummaryStats, 'supplierOnHand'>) {
  return (
    <StatsCard title={`Supplier Racks on Hand`}>{_.isNil(props.supplierOnHand) ? '-' : formatInteger(props.supplierOnHand)}</StatsCard>
  );
}
