import { connect } from 'react-redux';
import ContentUI from './route-plan-content-ui';
import { OwnProps as ComponentOwnProps } from './route-plan-content-ui';
import * as RoutePlanActions from 'client/actions/route-plan';
import assertCompatible from 'shared/helpers/assert-compatible';

export interface OwnProps {
  children: any;
}

export interface DispatchProps {
  handleUnmounted: () => void;
}

export const mapDispatchToProps: DispatchProps = {
  handleUnmounted: RoutePlanActions.routePlanUnmounted,
};

assertCompatible<ComponentOwnProps, DispatchProps>();

export default connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps)(ContentUI);
