import * as createLogger from 'redux-logger';
import immutableToJS from '../utils/immutable-to-js';

export default createLogger({
  collapsed: true,
  logger: console,
  stateTransformer: state => {
    return immutableToJS(state);
  },
  predicate: (getState, { type }) => {
    return type !== '@@redux-form/BLUR' &&
           type !== '@@redux-form/CHANGE' &&
           type !== '@@redux-form/REGISTER_FIELD' &&
           type !== '@@redux-form/UNREGISTER_FIELD' &&
           type !== '@@redux-form/FOCUS' &&
           type !== '@@redux-form/INITIALIZE' &&
           type !== '@@redux-form/TOUCH';
  },
});
