import * as React from 'react';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledCheckbox, LabeledDate } from 'client/components/form';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import { ColumnAlignment, SelectableRow } from 'client/components/selectable/types';
import LabeledDateRange from 'client/components/form/labeled-date-range';
import { DateStr, ImmutableDateRange, ReportTypes, ReplenishmentByStoreGroupingOptions, ReplenishmentByProductGroupingOptions } from 'shared/types';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ReportUserParams } from 'client/components/report-user-params';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { Col, FormGroup } from 'client/components/third-party';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { DropDownOptions } from 'client/types';

const emptyArray = [];
const optionTextFormatter = option => option.value;

interface LabeledSelectFieldProps {
  name: string;
  options: DropDownOptions;
  handleChange?(id: number): void;
  label?: string;
  colSize?: number;
}

interface LabeledDateFieldProps {
  name: string;
  label?: string;
  testid?: string;
  offset?: number;
}

interface LabeledDateRangeFieldProps {
  handleChange(dateRange: ImmutableDateRange): void;
  beginDate?: DateStr;
  endDate?: DateStr;
  label?: string;
  disabled?: boolean;
  testid?: string;
}

interface LabeledSelectableFieldProps {
  name: string;
  options?: SelectableRow[];
  loading?: boolean;
  required?: boolean;
  handleChange?(ids: number[]): void;
}

interface LabeledCheckboxFieldProps {
  name: string;
  handleChange?(isOn: boolean): void;
}

export class Customer extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={4}
        label="Customer"
        options={this.props.options || emptyArray}
        labelColSize={4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        required
        horizontalLabel={false}
        testid="report-customer"
      />
    );
  }
}

export class DateRange extends React.PureComponent<LabeledDateRangeFieldProps, {}> {
  render() {
    return (
      <LabeledDateRange
        label={this.props.label || 'Date Range'}
        testid={this.props.testid || 'date-range'}
        onChange={this.props.handleChange}
        beginDate={this.props.beginDate}
        endDate={this.props.endDate}
        offset={0}
        labelColSize={12}
        inputColSize={12}
        disabled={this.props.disabled}
        horizontalLabel
      />
    );
  }
}

export class Date extends React.PureComponent<LabeledDateFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledDate}
        inputColSize={5}
        label={this.props.label || 'Date'}
        labelColSize={5}
        offset={notNil(this.props.offset) ? this.props.offset : 1}
        horizontalLabel={false}
        testid={this.props.testid || 'date'}
        required
      />
    );
  }
}

export class SalesPlan extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={this.props.colSize || 12}
        label={this.props.label || 'Sales Plan'}
        labelColSize={this.props.colSize || 12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sales-plan"
        required
      />
    );
  }
}

export class SalesPlanForPieceTarget extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={4}
        label="Sales Plan For Piece Target"
        labelColSize={4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sales-plan-piece-target"
        required
      />
    );
  }
}

export class SellDepartment extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={12}
        label="Sell Department"
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sell-department"
        required
      />
    );
  }
}

export class ScanBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="Scan Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-scan-based"
        class="scanBased"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class PoBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="PO Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-po-based"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-class"
        optional
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductSubClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        handleChange={this.props.handleChange}
        inputColSize={12}
        label="Sub Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-subclass"
        optional
      />
    );
  }
}

export class Product extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Products"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        handleChange={this.props.handleChange}
        required
        testid="report-products"
      />
    );
  }
}

export class MfcArea extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 50 },
    { alignment: ColumnAlignment.Left, width: 50 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="MFC Areas"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        handleChange={this.props.handleChange}
        required={this.props.required === undefined ? true : this.props.required}
        testid="report-mfc-area"
      />
    );
  }
}

export class Region extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [{ alignment: ColumnAlignment.Left, width: 40 },
          { alignment: ColumnAlignment.Left, width: 60 }];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Regions"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-region"
        handleChange={this.props.handleChange}
        optional
      />
    );
  }
}

export class Market extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [{ alignment: ColumnAlignment.Left, width: 40 },
          { alignment: ColumnAlignment.Left, width: 60 }];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Markets"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-market"
        handleChange={this.props.handleChange}
        optional
      />
    );
  }
}

export class Store extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 10 },   // number
    { alignment: ColumnAlignment.Left, width: 40 },   // city
    { alignment: ColumnAlignment.Left, width: 10 },   // state
    { alignment: ColumnAlignment.Left, width: 20 },   // Primary/Global
    { alignment: ColumnAlignment.Left, width: 20 },   // active
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Stores"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-store"
        handleChange={this.props.handleChange}
        required
      />
    );
  }
}

export class IncludeStoresNotShipped extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={6}
        label="Include Stores Not Shipped"
        labelColSize={6}
        offset={0}
        valueField="value"
        horizontalLabel={false}
        testid="report-include-stores-not-shipped"
        required
      />
    );
  }
}

export class PieceTargetPrimaryStoresOnly extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={6}
        label="Piece Target - Primary Stores Only"
        labelColSize={6}
        offset={0}
        valueField="value"
        horizontalLabel={false}
        testid="report-piece-target-primary-stores-only"
        required
      />
    );
  }
}

interface SidebarProps {
  reportType: ReportTypes;
  downloadButtonDisabled: boolean;
  downloadButtonDisabledMessage?: string;
  handleDownloadExcelReportClicked(): void;
  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;
  pristine: boolean;
  excelDownloadStatus: MutationStatus;
  reportFormName: string;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export class Sidebar extends React.PureComponent<SidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        {this.props.children}

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            actionStatus={this.props.excelDownloadStatus}
            testid="download-excel-report-button"
            />
        </div>
        {this.props.downloadButtonDisabledMessage && <Col sm={12}><div className="mfc-form-stacked-label help-block validation-error">{this.props.downloadButtonDisabledMessage}</div></Col>}

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}

type ReplenishmentReportGroupByOptions = ReplenishmentByStoreGroupingOptions | ReplenishmentByProductGroupingOptions;

interface ReplenishmentReportGroupByProps {
  name: string;
  options: DropDownOptions;
  handleChange?: (groupByOption: ReplenishmentReportGroupByOptions) => void;
}

export class ReplenishmentReportGroupBy extends React.PureComponent<ReplenishmentReportGroupByProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={11}
        label="Grouping and Subtotals"
        options={this.props.options || emptyArray}
        labelColSize={11}
        offset={1}
        textFormatter={optionTextFormatter}
        valueField="value"
        required
        horizontalLabel={false}
        testid="replenishment-report-group-by"
      />
    );
  }
}

interface ReplenishmentReportSidebarProps extends SidebarProps {
  groupByFieldName: string;
  groupingOptions: DropDownOptions;
}

export class ReplenishmentReportSidebar extends React.PureComponent<ReplenishmentReportSidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        {this.props.children}
        <div className="mfc-form-stacked-label report-sidebar-heading report-sidebar-heading-with-margin">Report Options</div>

        <FormGroup>
          <div className="report-sidebar-field">
            <ReplenishmentReportGroupBy
              name={this.props.groupByFieldName}
              options={this.props.groupingOptions}
            />
          </div>
        </FormGroup>

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            actionStatus={this.props.excelDownloadStatus}
            testid="download-excel-report-button"
            />
        </div>
        {this.props.downloadButtonDisabledMessage && <Col sm={12}><div className="mfc-form-stacked-label help-block validation-error">{this.props.downloadButtonDisabledMessage}</div></Col>}

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}

export class PrimaryGlobalAllField extends React.PureComponent<{ name: string }, {}> {
  options = [
    { id: PrimaryGlobalAll.Primary },
    { id: PrimaryGlobalAll.Global },
    { id: PrimaryGlobalAll.All },
  ];
  render() {
    return (
      <Field
        component={LabeledSelect}
        horizontalLabel={false}
        inputColSize={12}
        labelColSize={12}
        label="Primary/Global/All"
        name={this.props.name}
        offset={0}
        options={this.options}
        required
        testid={`report-primary-global-all}`}
        textFormatter={value => value.id}
        valueField="value"
      />
    );
  }
}
