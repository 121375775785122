import { buildAdminListPage } from 'client/containers/admin';
import { Merchandiser } from 'shared/schemas/merchandiser';

export default buildAdminListPage(Merchandiser, {
  title: 'Merchandising Supervisors / Coordinators',
  newButtonLabel: 'New Merchandising Supervisor / Coordinator',
  emptyTablePlaceholder: 'No Merchandising Supervisor / Coordinator Records',
  hardDeleteRecords: true,
  columns: ['name', 'coordinator', 'storeList', 'supervisorList'],
  excelColumns: [
    'name',
    'coordinator',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'email',
    'phone',
    'fax',
    'cell',
    'storeList',
    'supervisorList',
  ],
  filters: ['name', 'coordinator'],
});
