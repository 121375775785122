import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import * as _ from 'lodash';
import EditProductModal from './product-modal';
import * as Actions from 'client/actions/product-worksheet';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { Product } from 'shared/schemas/product';
import gqlTag from 'graphql-tag';
import { EditCustomerOrderProductGroupPayload, ProductShipmentConfiguration } from 'client/types/product-worksheet';
import { buildFragment, formQueryColumns } from 'shared/schemas';
import { OrderMethod, ShippingUnitOrderMethod, ComboCart, PackOrderMethod, Numeric } from 'shared/types';
import { ProductWorksheetState } from 'client/reducers/product-worksheet';
import { ProductListResponseItem } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-container';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { wrapComponent } from 'client/hoc/hoc';
import { ApolloRefetch } from 'client/types';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { Saved } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { CustomerOrderProductId } from 'shared/schemas/customer-order-product';
import { ThunkerDispatch } from 'client/types/redux-types';
import { orThrow } from 'shared/helpers';

const formName = 'edit-combo-cart';

export interface OwnProps {
  customerOrder: Pick<Saved<CustomerOrder>, 'id' | 'customer' | 'orderMethod' | 'shippingUnitType'| 'sellDepartment' | 'orderStatus' | 'scanBased' | 'customerId'>;
  refreshProductsList: ApolloRefetch;
  regularCartList: ProductShipmentConfiguration[];
  comboCartList: ComboCart[];
  worksheetStatsRefetch?: ApolloRefetch;
  productListResponseItems: ProductListResponseItem[];
  confirmOkToSave: () => Promise<boolean>;
}

export interface StateProps {
  isShown: boolean;
  initialValues: EditComboCartFormValues;
  isProductSelectedList: boolean[];
  packsPerShelfList: Array<number | undefined>;
  comboProductGroupId: number | null;
  isCombo: boolean;
  isComboDisabled: boolean;
  isDescriptionDisabled: boolean;
}

type WithProductsPropsProduct = Pick<Product, 'id' | 'identifier' | 'packSize' | 'packsPerRack' | 'shelvesPerRack' | 'packsPerShelf' | 'description' | 'activeStatus'>;

interface WithProductsProps {
  products: WithProductsPropsProduct[];
}

interface WithMutationProps {
  editCustomerOrderProductGroup(payload: EditCustomerOrderProductGroupPayload);
}

type ReduxFormProps = ReduxForm.InjectedFormProps<EditComboCartFormValues>;

interface EditComboCartFormValues {
  id: CustomerOrderProductGroupId;
  identifier: string;
  description: string;
  packsPerShippingUnit?: number | null;
  comboProductGroupId: number | null;
  isCombo: boolean;
  customerOrderProducts: Array<{
    id: number;
    productId: number;
    packsPerShippingUnit?: number | null;
    shelvesPerRack?: number | null;
    packsPerShelf?: number | null;
    packSize: number;
  }>;
}

export const mapStateToProps = (state: any, ownProps: OwnProps & WithProductsProps): StateProps => {
  const productWorksheetState: ProductWorksheetState = state.productWorksheet;

  const formValues = ReduxForm.getFormValues(formName)(state) as EditComboCartFormValues;
  const packsPerShelfList: StateProps['packsPerShelfList'] = [];
  const isProductSelectedList: StateProps['isProductSelectedList'] = [];
  const initialValues: EditComboCartFormValues = {
    id: -1,
    isCombo: false,
    identifier: '',
    description: '',
    customerOrderProducts: [],
    comboProductGroupId: null,
  };

  const customerOrderProductGroupId = productWorksheetState.editCustomOrderProductGroupId;
  const productListResponseItem = ownProps.productListResponseItems.find(item => item.id === customerOrderProductGroupId);

  if (productListResponseItem) {
    initialValues.id = productListResponseItem.id;
    initialValues.identifier = productListResponseItem.identifier;
    initialValues.description = productListResponseItem.description;
    initialValues.isCombo = productListResponseItem.customerOrderProducts.length > 1 || productListResponseItem.isCombo;

    if (productListResponseItem.customerOrder.orderMethod === ShippingUnitOrderMethod) {
      for (const customerOrderProduct of productListResponseItem.customerOrderProducts) {
        initialValues.customerOrderProducts.push({
          id: customerOrderProduct.id,
          productId: customerOrderProduct.product.id,
          shelvesPerRack: customerOrderProduct.shelvesPerRack,
          packsPerShelf: customerOrderProduct.packsPerShelf,
          packsPerShippingUnit: customerOrderProduct.packsPerShippingUnit,
          packSize: customerOrderProduct.packSize,
        });
      }
    } else if (productListResponseItem.customerOrder.orderMethod === PackOrderMethod) {
      for (const customerOrderProduct of productListResponseItem.customerOrderProducts) {
        initialValues.customerOrderProducts.push({
          id: customerOrderProduct.id,
          productId: customerOrderProduct.product.id,
          packsPerShippingUnit: customerOrderProduct.packsPerShippingUnit,
          packSize: customerOrderProduct.packSize,
        });

        initialValues.packsPerShippingUnit = productListResponseItem.packsPerShippingUnit;
      }
    }
  }

  initialValues.customerOrderProducts = _.sortBy(initialValues.customerOrderProducts, ['id']);

  if (formValues?.customerOrderProducts) {
    formValues.customerOrderProducts.forEach(formProductInfo => {
      isProductSelectedList.push(!!formProductInfo.productId);
    });
  }

  return {
    isShown: state.productWorksheet.editComboCartModalShown,
    isCombo: formValues?.isCombo,
    packsPerShelfList,
    initialValues: (initialValues as EditComboCartFormValues),
    isProductSelectedList,
    comboProductGroupId: formValues?.comboProductGroupId,
    isComboDisabled: formValues?.customerOrderProducts.length > 1,
    isDescriptionDisabled: formValues && !formValues.isCombo,
  };
};

/** The empty value for a number in redux form seemed to be stored as an empty string. We want this to be null, so I make that translation here. */
const nullifBlankIntegerInput = (x: number | null | undefined | string) => Numeric.from(x).toInt() || null;
const isOrderByPack = ({customerOrder}: {customerOrder?: {orderMethod: OrderMethod}}) => OrderMethod.Pack === (customerOrder || orThrow(`customerOrderDetails is undefined`)).orderMethod;

export const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps & WithProductsProps & WithMutationProps & ReduxFormProps & StateProps) => ({
  handleProductChanged: (productId, productFieldIdentifier) => {
    const { products, isCombo, comboCartList, regularCartList } = props;
    dispatch(Actions.editComboCartModalProductChanged({ formName, productId, products, productFieldIdentifier, orderMethod: props.customerOrder.orderMethod, regularCartList, comboCartList, isCombo, numProducts: props.initialValues.customerOrderProducts.length }));
  },
  handleProductCleared: () => { /* Don't need to do anything when a product is cleared (not sure this is even possible right now in the UI) */ },
  handlePacksPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field: 'packsPerShelf' | 'shelvesPerRack', value: number) =>
    dispatch(Actions.packsPerShippingUnitCalculationInputChange(formName, productFieldIdentifier, field, value)),
  handlePiecesPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field?: 'packSize' | 'packsPerShippingUnit', value?: number) =>
    dispatch(Actions.piecesPerShippingUnitInputChange(formName, productFieldIdentifier, field, value)),
  handleCancelButtonClicked: () => dispatch(Actions.editComboCartModalCancelButtonClicked(formName)),
  handleFormSubmit: () => props.handleSubmit(async (formValues: EditComboCartFormValues) =>
    await dispatch(Actions.editProductModalUpdateClicked(formName, props.editCustomerOrderProductGroup, props.refreshProductsList, props.worksheetStatsRefetch, {
      id: formValues.id,
      customerOrderId: props.customerOrder.id,
      identifier: formValues.identifier,
      description: formValues.description,
      packsPerShippingUnit: isOrderByPack(props) ? nullifBlankIntegerInput(formValues.packsPerShippingUnit) : undefined, // do not specify COPG.PPSU if OrderMethod.ShippingUnit --> allow server to calculate instead
      customerOrderProducts: {
        created: formValues.customerOrderProducts.filter(x => !x.id).map(x => ({
          productId: x.productId,
          packSize: x.packSize,
          packsPerShelf: x.packsPerShelf || null,
          shelvesPerRack: x.shelvesPerRack || null,
          packsPerShippingUnit: isOrderByPack(props) ? nullifBlankIntegerInput(formValues.packsPerShippingUnit) : x.packsPerShippingUnit, // COPs directly inherit COPG.PPSU when OrderByPack - the opposite otherwise
        })),
        updated: formValues.customerOrderProducts.filter(x => x.id).map(x => ({
          id: x.id,
          productId: x.productId,
          packSize: x.packSize,
          packsPerShelf:  x.packsPerShelf || null,
          shelvesPerRack: x.shelvesPerRack || null,
          packsPerShippingUnit: isOrderByPack(props) ? nullifBlankIntegerInput(formValues.packsPerShippingUnit) : x.packsPerShippingUnit, // COPs directly inherit COPG.PPSU when OrderByPack - the opposite otherwise
        })),
        deleted: (_.compact(_.difference(props.initialValues.customerOrderProducts.map(x => x.id), formValues.customerOrderProducts.map(x => x.id))) as CustomerOrderProductId[]).map(id => ({ id })),
      },
      isCombo: formValues.isCombo,
    }))
  ),
});

const withProducts = optionsContainerWithFilters<WithProductsProps>({
  table: 'products',
  columns: ['identifier', 'description', 'packSize', 'packsPerRack', 'shelvesPerRack', 'packsPerShelf', 'activeStatus'],
  getFilters: (props: OwnProps) => (!props.customerOrder || _.isEmpty(props.customerOrder)) ? [] : [
    { field: 'customer', values: [props.customerOrder.customer.id.toString()] },
    { field: 'sellDepartment.identifier', values: [props.customerOrder.sellDepartment.identifier, 'MISC'] },
    { field: 'scanProduct', values: [props.customerOrder.scanBased ? 'true' : 'false'] },
  ],
});

const fragmentName = 'CustomerOrderProductGroupFragment';
const fragment = gqlTag`${buildFragment('customerOrderProductGroups', formQueryColumns('customerOrderProductGroups'), fragmentName)}`;
const mutation = gqlTag`
  mutation ($input: EditCustomerOrderProductGroupInput!) {
    data: editCustomerOrderProductGroup(input: $input) {
      id,
      ...${fragmentName}
      customerOrder {
        id
        lastModifiedAt
      }
    }
  }
  ${fragment}
`;

const withMutation = msyncMutation(mutation, {
  props: ({ mutate }): WithMutationProps => ({ editCustomerOrderProductGroup: (input: EditCustomerOrderProductGroupPayload) => mutate({ variables: { input } }) }),
});

interface WithModalProps {
  title: string;
  submitButtonText: string;
  showAddProductButton: boolean;
  formName: string;
  disableProductField: boolean;
  isCombo: boolean;
  isDescriptionDisabled: boolean;
  id: string;
}

const withModalProps = WrappedComponent => props => {
  return (
    <WrappedComponent
      {...props}
      title={props.isCombo ? 'Edit Combo Cart' : 'Edit Product'}
      submitButtonText={'Update'}
      showAddProductButton={props.isCombo}
      formName={formName}
      disableProductField={false}
      isCombo
      isDescriptionDisabled={props.isDescriptionDisabled}
      id={'edit-product-modal'}
    />
  );
};

const connector1 = connect(mapStateToProps);
const connector2 = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector1> &
  ConnectedProps<typeof connector2> &
  ReduxFormProps &
  WithProductsProps &
  WithMutationProps &
  WithModalProps;

export default wrapComponent(EditProductModal)<OwnProps, CombinedProps>(
  withProducts,
  withMutation,
  connector1,
  ReduxForm.reduxForm({
    form: formName,
    enableReinitialize: true,
    touchOnChange: true,
  }),
  connector2,
  withModalProps,
);
