import * as Immutable from 'immutable';
import { Lens } from '@atomic-object/lenses';
import { ProductWorksheetState } from 'client/reducers/product-worksheet';

export interface AllocatedProductChange {
  readonly nonce: number;
  readonly change: number;
  readonly quantity: number;
  readonly storeId: number;
  readonly customerOrderProductGroupId: number;
}

export type AllocatedProductChanges = Immutable.Map<number, Immutable.List<AllocatedProductChange>>;
export type QueuedAllocationChanges = Immutable.Map<string, AllocatedProductChange>;

export type Type = ProductWorksheetState;

function keyForAllocatedProductChangeValues(customerOrderProductGroupId: number, storeId: number) {
  return `${customerOrderProductGroupId}-${storeId}`;
}
export function keyForAllocatedProductChange(allocatedProductChange: AllocatedProductChange) {
  return keyForAllocatedProductChangeValues(allocatedProductChange.customerOrderProductGroupId, allocatedProductChange.storeId);
}

export const autoReplenishmentModalShownLens = Lens.from<ProductWorksheetState>().prop('autoReplenishmentModalShown');
export const autoReplenishmentMutationStatusLens = Lens.from<ProductWorksheetState>().prop('autoReplenishmentMutationStatus');
export const storeIdsFromMenuActionLens = Lens.from<ProductWorksheetState>().prop('storeIdsFromMenuAction');

export const allocatedProductChangesByCustomerOrderProductGroupLens = Lens.of<ProductWorksheetState, AllocatedProductChanges>({
  get: state => state.allocatedProductChangesByCustomerOrderProductGroup,
  set: (state, value) => state.with({allocatedProductChangesByCustomerOrderProductGroup: value}),
});

export const queuedAllocationChangesLens = Lens.of<ProductWorksheetState, QueuedAllocationChanges>({
  get: state => state.queuedAllocationChanges,
  set: (state, value) => state.with({queuedAllocationChanges: value}),
});

export const allocatedProductChangesForCustomerOrderProductGroupLens = (copgId: number) => {
  const lens = Lens.of<AllocatedProductChanges, Immutable.List<AllocatedProductChange>>({
    get: map => map.get(copgId) || Immutable.List(),
    set: (map, changes) => {
      return map.set(copgId, changes);
    },
  });
  return allocatedProductChangesByCustomerOrderProductGroupLens.comp(lens);
};

export const queuedAllocationChangeLens = (customerOrderProductGroupId: number, storeId: number) => {
  const key = keyForAllocatedProductChangeValues(customerOrderProductGroupId, storeId);
  const lens = Lens.of<QueuedAllocationChanges, AllocatedProductChange>({
    get: map => map.get(key),
    set: (map, value) => {
      return map.set(key, value);
    },
  });
  return queuedAllocationChangesLens.comp(lens);
};
