import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/sales-plan/types';

export type CreateSalesPlanInput = GraphQLTypes.CreateSalesPlanInput;

export interface CreateSalesPlanResponse {
  salesPlan: Pick<GraphQLTypes.SalesPlanV2, 'id'>;
}

export const CreateSalesPlanMutation = gql`
  mutation CreateSalesPlanMutation($input: CreateSalesPlanV2Input!) {
    salesPlan: CreateSalesPlan(input: $input) {
      id
      year
      identifier
      customerId
      sellDepartmentId
      lastModifiedAt
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface UpdateSalesPlanInput {
  salesPlanId: number;
  input: {
    year?: number;
    identifier?: string;
    customerId?: number;
    sellDepartmentId?: number;
  };
}

export interface UpdateSalesPlanResponse {
  salesPlan: Pick<GraphQLTypes.SalesPlanV2, 'id'>;
}

export const UpdateSalesPlanMutation = gql`
  mutation UpdateSalesPlanMutation($salesPlanId: Int!, $input: UpdateSalesPlanInput!) {
    salesPlan: UpdateSalesPlan(id: $salesPlanId, input: $input) {
      id
      year
      identifier
      customerId
      customerIdentifier
      sellDepartmentId
      sellDepartmentIdentifier
      lastModifiedAt
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface DownloadSalesPlanMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadSalesPlanMutationInput {
  salesPlanId: number;
}

export const DownloadSalesPlanMutation = gql`
  mutation DownloadSalesPlanMutation($salesPlanId: Int!) {
    response: DownloadSalesPlan(salesPlanId: $salesPlanId) {
      report {
        id
      }
    }
  }
`;

export interface DownloadCustomerSpecSheetPdfMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadCustomerSpecSheetPdfMutationInput {
  salesPlanId: number;
}

export const DownloadCustomerSpecSheetPdfMutation = gql`
  mutation DownloadCustomerSpecSheetPdfMutation($salesPlanId: Int!) {
    response: DownloadCustomerSpecSheetPdf(salesPlanId: $salesPlanId) {
      report {
        id
      }
    }
  }
`;

export interface DownloadDifferenceReportCalculationSpreadsheetMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadDifferenceReportCalculationSpreadsheetMutationInput {
  salesPlanId: number;
}

export const DownloadDifferenceReportCalculationSpreadsheetMutation = gql`
  mutation DownloadDifferenceReportCalculationSpreadsheetMutation($salesPlanId: Int!) {
    response: DownloadDifferenceReportCalculationSpreadsheet(salesPlanId: $salesPlanId) {
      report {
        id
      }
    }
  }
`;
