import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/route-plan/route-plan-typescript-types';

export interface DefaultCustomerQueryInput {
  routePlanId: number;
}

export type RoutePlanGraphqlResult = Pick<GraphQLTypes.RoutePlan, 'id' | 'customerId'>;
export interface DefaultCustomerQueryResponse {
  getRoutePlan?: {
    routePlan: RoutePlanGraphqlResult;
  };
}

export const DefaultCustomerQuery = gql`
  query WithDefaultCustomer($routePlanId: Int!) {
    getRoutePlan: GetRoutePlan(id: $routePlanId) {
      routePlan {
        customerId
      }
    }
  }
`;
