import * as React from 'react';
import { flowRight } from 'lodash';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter, RouteChildrenProps } from 'react-router';
import { isInvalid, isPristine, submit } from 'redux-form';
import { DateTimeStr } from 'shared/types';
import { RoutingLoadIdentifier, RoutingLoadId, RoutingLoadLastModified } from 'schema/routing-load/routing-load-graphql-types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { propToComponent } from 'client/hoc/hoc';
import { RecordBarDetail } from 'client/components/record-bar';
import { RecordBar } from 'client/components/record-bar/record-bar-component';

interface RoutingLoadRecordBarQueryResponse {
  routingLoadRecordBarResult?: {
    routingLoad: {
      id: RoutingLoadId;
      identifier: RoutingLoadIdentifier;
      lastModified: RoutingLoadLastModified;
    };
  };
}

const RoutingLoadQuery = gql`
  query routingLoadQuery($routingLoadId: Int!) {
    routingLoadRecordBarResult: GetRoutingLoad(routingLoadId: $routingLoadId) {
      routingLoad {
        id
        identifier
        lastModified
      }
    }
  }
`;

const LoadDetailsRecordBarCore = (p: {
  lastModified?: DateTimeStr;
  identifier?: RoutingLoadIdentifier;
  peakNumbers?: string;
  onBackButtonClicked(): void;
  onSaveButtonClicked(): void;
  saveButtonDisabled: boolean;
}) => (
  <RecordBar
    updatedAt={p.lastModified}
    onBackButtonClicked={p.onBackButtonClicked}
    onSaveButtonClicked={p.onSaveButtonClicked}
    saveButtonDisabled={p.saveButtonDisabled}>
    <RecordBarDetail title="Load ID" testid="record-bar-identifier">
      <span data-testid="record-bar-identifier-value">{p.identifier}</span>
    </RecordBarDetail>
  </RecordBar>
);

interface OwnProps { routingLoadId?: RoutingLoadId; }
const WithRoutingLoad = msyncQuery<RoutingLoadRecordBarQueryResponse, OwnProps, { identifier: RoutingLoadIdentifier, lastModified?: RoutingLoadLastModified }>(RoutingLoadQuery, {
  alias: 'withRoutingLoadRecordBar',
  skip: (ownProps: OwnProps) => ownProps.routingLoadId === undefined,
  options: (ownProps: OwnProps): { variables: { routingLoadId?: RoutingLoadId } } => ({ variables: { routingLoadId: ownProps.routingLoadId } }),
  props: ({ data }) => (data.loading || ! data.routingLoadRecordBarResult) ? { identifier: '' } : {
    identifier: data.routingLoadRecordBarResult.routingLoad.identifier,
    lastModified: data.routingLoadRecordBarResult.routingLoad.lastModified,
  },
});

interface StateProps { saveButtonDisabled: boolean; }
const formName = 'loadDetailsHeaderForm';
const mapStateToProps = (state: any): StateProps => ({ saveButtonDisabled: isPristine(formName)(state) || isInvalid(formName)(state) });

interface DispatchProps { onBackButtonClicked(): void; onSaveButtonClicked(): void; }
const mapDispatchToProps = (dispatch, props: RouteChildrenProps): DispatchProps => ({
  onBackButtonClicked: () => props.history.goBack(),
  onSaveButtonClicked: () => dispatch(submit(formName)),
});

// export type ComponentProps = OwnProps;
const component = flowRight(
  WithRoutingLoad,
  withRouter,
  connect<StateProps, DispatchProps, OwnProps & RouteChildrenProps>(mapStateToProps, mapDispatchToProps),
)(LoadDetailsRecordBarCore);

export const LoadDetailsRecordBar = (props: OwnProps) => propToComponent(component, props);
