
import * as React from 'react';
import { Modal, FormGroup, Form } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledInput } from 'client/components/form';
import { INPUT_TYPES, DropdownOption } from 'shared/types';
import * as Validators from 'shared/validators';

interface CustomerInfo {
  identifier: string;
  name: string;
}
type CustomerDropdownOption = DropdownOption & CustomerInfo;

interface StoreInfo {
  identifier: string;
  city: string;
  state: string;
}
type StoreDropdownOption = DropdownOption & StoreInfo;

interface ComponentProps {
  isShown: boolean;
  createButtonDisabled: boolean;
  routePlanId: RoutePlanId;
  handleCancelButtonClicked(): void;
  handleAddStoreButtonClicked(routePlanId: RoutePlanId): void;
  customerOptions: CustomerDropdownOption[];
  storeOptions: StoreDropdownOption[];
  handleCustomerChanged(customerId: number): any;
}

export class AddStoreModalUI extends React.PureComponent<ComponentProps, {}> {
  handleAddStoreButtonClicked = () => {
    this.props.handleAddStoreButtonClicked(this.props.routePlanId);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }
    return (
      <Modal
        id="add-store-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="add-store-modal-container"
        className="add-store-modal" >
        <Form horizontal onSubmit={this.handleAddStoreButtonClicked} data-testid="add-vendor-chargeback-line-item-modal">
          <GlobalAsyncModalHeader>Add Store</GlobalAsyncModalHeader>
          <Modal.Body>
            <Form horizontal>
              <FormGroup>
                <Field
                  name="customerId"
                  component={LabeledSelect}
                  inputColSize={6}
                  label="Customer"
                  labelColSize={12}
                  offset={0}
                  testid="add-store-modal-customer"
                  textFormatter={(option: CustomerInfo) => `${option.identifier} - ${option.name}`}
                  valueField="value"
                  required
                  options={this.props.customerOptions}
                  horizontalLabel={false}
                  handleChange={this.props.handleCustomerChanged}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  name="storeId"
                  component={LabeledSelect}
                  inputColSize={6}
                  label="Store"
                  labelColSize={12}
                  offset={0}
                  testid="add-store-modal-store"
                  textFormatter={store => `${store.identifier} - ${store.city}, ${store.state}`}
                  valueField="value"
                  required
                  options={this.props.storeOptions}
                  horizontalLabel={false}
                  autoFocus
                />
              </FormGroup>
              <FormGroup>
                <Field
                  name="numPallets"
                  component={LabeledInput}
                  labelColSize={12}
                  inputColSize={6}
                  horizontalLabel={false}
                  label="# of Pallets for Delivery"
                  testid="add-store-num-pallets"
                  type={INPUT_TYPES.NUMBER}
                  validators={[Validators.GREATER_THAN_OR_EQUAL_TO_ZERO]}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  name="numRacks"
                  component={LabeledInput}
                  labelColSize={12}
                  inputColSize={6}
                  horizontalLabel={false}
                  label="# of Racks for Delivery"
                  testid="add-store-num-racks"
                  type={INPUT_TYPES.NUMBER}
                  validators={[Validators.GREATER_THAN_OR_EQUAL_TO_ZERO]}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
        </Form>
        <Modal.Footer>
          <GlobalCancelButton
            label="Cancel"
            onClick={this.props.handleCancelButtonClicked}
            testid="add-store-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Add Store"
            testid="add-store-modal-create-button"
            disabled={this.props.createButtonDisabled}
            onClick={this.handleAddStoreButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
