import * as React from 'react';
import PageTitle from 'client/components/page-title';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { VendorListColumns } from './columns';
import { AvailableSearchField, AvailableFilter } from 'client/types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

interface VendorRow {
  id: number;
  identifier: string;
  name: string;
}

export interface UIProps {
  tableName: string;
  vendorRows: VendorRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  onNewClicked: () => any;
  tableParentInfo: TableParentInfo;
  onRowSelected: (row: { id: number }) => any;
  dataRequest: MsyncDataRequest;
}

const noop = () => { /* noop */ };

export class VendorListUI extends React.PureComponent<UIProps, {}> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(props.tableName);
  }

  render() {
    return (
      <div id="mfc-page-content">
        <PageTitle title="Distribution Vendors"/>
        <this.FilterableTable
          table={this.props.tableName}
          content={this.props.vendorRows}
          loading={this.props.loading}
          columns={VendorListColumns}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={noop}
          loadMoreRecords={noop}
          searchableFields={this.props.searchableFields}
          availableFilters={this.props.availableFilters}
          placeholder="No Vendors"
          newButtonLabel="New Vendor"
          onNewClicked={this.props.onNewClicked}
          checkable
          list
          tablePaginated
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          onRowSelect={this.props.onRowSelected}
          dataRequest={this.props.dataRequest}
        />
      </div>
    );
  }
}
