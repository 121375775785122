import * as Validators from 'shared/validators';
import { appendFieldValidators } from 'shared/schemas/dsl';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { OrderMethod, ShippingUnitType, SupplierOrderTypes, DeliveryMethods } from 'shared/types';

appendFieldValidators(SupplierOrder, {
  orderMethod: [Validators.INCLUSION(OrderMethod)],
  shippingUnitType: [Validators.INCLUSION(ShippingUnitType)],
  orderType: [Validators.INCLUSION(SupplierOrderTypes)],
  deliveryMethod: [Validators.INCLUSION(DeliveryMethods)],
  identifier: [
    Validators.MAX_LENGTH(6),
    Validators.REQUIRED_IF_EDITING,
  ],
  invoiceNumber: [
    Validators.MAX_LENGTH(10),
  ],
});
