import * as React from 'react';
import { ShippingUnitType, OrderMethod } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME } from 'client/constants';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { Col } from 'client/components/third-party';
import { AvailableSearchField, AvailableFilter } from 'client/types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { BolId } from 'shared/schemas/bol';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

export interface ReceivingWorksheetTableRow {
  id: number;
  productIdentifier: string;
  productDescription: string;
  upc: string | null;
  rackType: string;
  retailPrice: number;
  packSize: number | null;
  packsPerShelf: number | null;
  shelvesPerRack: number | null;
  packsPerShippingUnit: number | null;
  shippingUnitQuantity: number | null;
  shippingUnitQuantityReceivedInBol: number | null;
  packQuantityReceivedInBol: number;
  packQuantity: number;
  packQuantityReceivedTotal: Int;
  shippingUnitQuantityReceivedTotal: number | null;
}

export interface ReceivingWorksheetTableComponentProps {
  tableRows: ReceivingWorksheetTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  tableParentInfo: TableParentInfo;
  onRowSelect(): void;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  requestedBolId?: BolId;
  onSaveQuantityReceived: OnSaveQuantityReceived;
  bolTitle: string;
  showAutoCartTrackingWarning: boolean;
  dataRequest: MsyncDataRequest;
  columns: IColumn[];
}

export type OnSaveQuantityReceived = (receivableOrderProductId: number, bolId: BolId, packOrShippingUnitQuantity: number) => void;

const tableName = TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME;

export class ReceivingWorksheetTable extends React.PureComponent<ReceivingWorksheetTableComponentProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  componentDidUpdate() {
    this.props.tableParentInfo.adjustTableDimensions();
  }

  public render() {
    const columns = this.props.columns;

    return (
      <div>
        <div>
          <h1 className="mfc-product-worksheet-title">
            {this.props.bolTitle || 'BOL'}
            {this.props.showAutoCartTrackingWarning && <div className="bol-auto-cart-tracking-disabled-warning"> - Auto Cart Tracking Disabled</div>}
          </h1>
        </div>
        <Col sm={12} data-testid="receiving-worksheet-table">
          <this.FilterableTable
            table={tableName}
            content={this.props.tableRows}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={'There are no products on this order.'}
            checkable={false}
            tablePaginated={false}
            tableParentInfo={this.props.tableParentInfo}
            disableCreate
            displayLoadingIndicator
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
          />
        </Col>
      </div>
    );
  }
}
