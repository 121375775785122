import * as React from 'react';
import * as FormHelpers from 'client/helpers/form-helpers';
import { Col, Row, Form, FormGroup } from 'client/components/third-party';
import { Field, FieldArray, FormSubmitHandler } from 'redux-form';
import { LabeledInput } from 'client/components/form';
import { INPUT_TYPES } from 'shared/types';
import { makeFormValidator, MAX_LENGTH, REQUIRED_FIELD_VALIDATOR } from 'shared/validators';
import { VendorContactFormGroup, Props as VendorContactFormGroupProps } from './contact';

interface UIProps {
  onSubmit: FormSubmitHandler<VendorFormValues, { initialValues: { identifier: string } }>
  onPrimaryClicked: (fields, index, name) => void;
  displayContacts: shame;
  vendorId?: number;
}

export interface VendorFormValues {
  id?: number;
  identifier: string;
  name: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  fax?: string;
  contacts?: VendorFormContact[];
}

interface VendorFormContact {
  id?: number;
  name?: string;
  email?: string;
  phoneOffice?: string;
  phoneCell?: string;
  primary?: boolean;
}

const nameValidators = [makeFormValidator(MAX_LENGTH(30)), REQUIRED_FIELD_VALIDATOR];
const identifierValidators = [makeFormValidator(MAX_LENGTH(10)), REQUIRED_FIELD_VALIDATOR];

export class VendorFormUI extends React.PureComponent<UIProps, {}> {
  render() {
    const onAddContactClick = fields => {
      return () => {
        if (fields.length === 0) {
          return fields.push({ primary: true });
        }
        return fields.push({});
      };
    };

    const onDeleteContactClick = (fields, index, isPrimary) => {
      return () => {
        if (isPrimary && fields.length > 1) {
          const field = 'contacts[0].primary';
          const fieldNames = fields.map((_, theIndex) => `contacts[${theIndex}]`);
          this.props.onPrimaryClicked(fieldNames, 0, field);
        }

        const removedContact = fields.remove(index);
        return removedContact;
      };
    };

    return (
      <div className="record">
        <div className="mfc-form">
          <Col sm={12}>
            <Form horizontal onSubmit={this.props.onSubmit}>
              <Row>
                <div className="mfc-form-heading">Vendor Details</div>
                <FormGroup data-testid="info-row-1">
                  <Field
                    name="name"
                    component={LabeledInput}
                    labelColSize={2}
                    inputColSize={5}
                    label="Vendor Name"
                    testid="vendor-form-name"
                    type={INPUT_TYPES.TEXT}
                    validate={nameValidators}
                    required
                    alwaysShowErrors={true}
                    hideOptionalLabel={true}
                    autoFocus={!this.props.vendorId}
                  />
                  <Field
                    name="identifier"
                    component={LabeledInput}
                    labelColSize={1}
                    inputColSize={3}
                    label="Code"
                    testid="vendor-form-identifier"
                    type={INPUT_TYPES.TEXT}
                    required
                    validate={identifierValidators}
                    normalize={FormHelpers.TrimString}
                    alwaysShowErrors={true}
                    hideOptionalLabel={true}
                  />
                </FormGroup>

                <FormGroup data-testid="info-row-2">
                  <Field
                    name="address1"
                    label="Address 1"
                    labelColSize={2}
                    inputColSize={9}
                    component={LabeledInput}
                  />
                </FormGroup>
                <FormGroup data-testid="info-row-3">
                  <Field
                    name="address2"
                    label="Address 2"
                    labelColSize={2}
                    inputColSize={9}
                    component={LabeledInput}
                  />
                </FormGroup>
                <FormGroup data-testid="info-row-4">
                  <Field
                    name="city"
                    label="City"
                    labelColSize={2}
                    inputColSize={4}
                    component={LabeledInput}
                  />
                  <Field
                    name="state"
                    label="State"
                    labelColSize={1}
                    inputColSize={1}
                    component={LabeledInput}
                  />
                  <Field
                    name="zip"
                    label="ZIP"
                    labelColSize={1}
                    inputColSize={2}
                    component={LabeledInput}
                  />
                </FormGroup>
                <FormGroup data-testid="info-row-5">
                  <Field
                    name="country"
                    label="Country"
                    labelColSize={2}
                    inputColSize={9}
                    component={LabeledInput}
                  />
                </FormGroup>
                <FormGroup data-testid="info-row-6">
                  <Field
                    name="phone"
                    label="Phone"
                    labelColSize={2}
                    inputColSize={4}
                    component={LabeledInput}
                  />
                  <Field
                    name="fax"
                    label="Fax"
                    labelColSize={1}
                    inputColSize={4}
                    component={LabeledInput}
                  />
                </FormGroup>

                <FieldArray<VendorContactFormGroupProps>
                  name="contacts"
                  component={VendorContactFormGroup}
                  onPrimaryClicked={this.props.onPrimaryClicked}
                  handleAddContactClick={onAddContactClick}
                  handleDeleteContactClick={onDeleteContactClick}
                  displayContacts={this.props.displayContacts}
                />
              </Row>
            </Form>
          </Col>
        </div>
      </div>
    );
  }
}
