import { flowRight } from 'lodash';
import { ReceivingStatsRow as ReceivingStatsRowUI } from './receiving-stats-row';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import { ReceivingSummaryStats } from 'shared/types';
import { ReceivingSummaryStatsResponse, ReceivingSummaryQuery } from 'client/app/transportation/receiving/details/stats-row/receiving-stats-row-query';

interface OwnProps {
  receivableOrderId: number;
  showExtraInfo?: boolean;
}

const WithSummaryMetrics = msyncQuery<ReceivingSummaryStatsResponse, OwnProps, ReceivingSummaryStats>(ReceivingSummaryQuery, {
  alias: 'withSummaryMetrics',
  options(ownProps): { variables, fetchPolicy } {
    const { receivableOrderId } = ownProps;
    return {
      variables: {
        receivableOrderId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): ReceivingSummaryStats {
    if (!props.data || !props.data.response || props.data.loading) {
      return {};
    }

    return {
      ...props.data.response,
    };
  },
});
export type ComponentProps =
  OwnProps &
  ReceivingSummaryStats;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  WithSummaryMetrics,
)(ReceivingStatsRowUI) as Component<OwnProps>;

export const ReceivingStatsRow = (props: OwnProps) => propToComponent(component, props);

export default ReceivingStatsRow;
