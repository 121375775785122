import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import * as Actions from '../actions';
import * as ReactRedux from 'react-redux';
import * as State from 'client/state/state';
import * as Types from '../types';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  isShown: boolean;
  customerOrdersIdsToBeSent: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsAlreadySent: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsNotFullyReceived: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsThatAreScanBased: Types.CustomerOrderIdWithIdentifier[];
}

const mapStateToProps = (state: State.Type): StateProps => {
  return {
    isShown: State.isSendMicsModalShown(state),
    customerOrdersIdsAlreadySent: State.customerOrdersIdsAlreadySent(state),
    customerOrdersIdsNotFullyReceived: State.customerOrdersIdsNotFullyReceived(state),
    customerOrdersIdsToBeSent: State.customerOrdersIdsToBeSent(state),
    customerOrdersIdsThatAreScanBased: State.customerOrdersIdsThatAreScanBased(state),
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleSendMicsFileButtonClicked(customerOrdersIdsToBeSent: number[], additionalCustomerOrderIdsToBeUnchecked: number[]): void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.sendMicsFileCancelButtonClicked,
  handleSendMicsFileButtonClicked: Actions.sendMicsFileToMeijerButtonClicked,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

assertCompatible<UI.ComponentProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(UI.SendMicsModal) as Component<OwnProps>;

export const SendMicsModalContainer = (props: OwnProps) => propToComponent(component, props);
