import * as React from 'react';
import { Modal } from 'client/components/third-party';

interface GlobalAsyncModalHeaderUIProps {
  closeButton: boolean;
}

export class GlobalAsyncModalHeaderUI extends React.PureComponent<GlobalAsyncModalHeaderUIProps, {}> {
  render() {
    return (
      <Modal.Header closeButton={this.props.closeButton}>
        <Modal.Title>{this.props.children}</Modal.Title>
      </Modal.Header>
    );
  }
}
