import * as React from 'react';
import * as Menu from './menu';

export interface Props {
  menuItems: Menu.RowMenuItem[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class Component extends React.Component<Props, State> {
  public render() {
    const { menuItems } = this.props;

    return (
      <div>
        <div className="multiple-selection-rows-menu">
          <Menu.Component items={menuItems}/>
        </div>
        <div className="multiple-selection-rows-menu-empty">
          <div className="mfc-icon-kebab" />
        </div>
      </div>);
  }
}

export default Component;
