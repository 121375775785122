import * as React from 'react';
import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import PageTitle from 'client/components/page-title';
import { ComponentProps } from 'client/app/transportation/routing/route-plans-list/route-plans-table-container';
import { TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME } from 'client/constants';

type OwnProps = ComponentProps;
const tableName = TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME;

export const columns: IColumn[] = [
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'Route Plan ID',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'customerName',
    accessor: 'customerName',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 15,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'mfcAreaIdentifier',
    accessor: 'mfcAreaIdentifier',
    header: 'MFC Area',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'storeCount',
    accessor: 'storeCount',
    header: 'Stores',
    tableEditable: false,
    columnWidth: 5,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'loadCount',
    accessor: 'loadCount',
    header: '# of Loads',
    tableEditable: false,
    columnWidth: 5,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'stopCount',
    accessor: 'stopCount',
    header: '# of Stops',
    tableEditable: false,
    columnWidth: 5,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'racks',
    accessor: 'racks',
    header: 'Racks',
    tableEditable: false,
    columnWidth: 5,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'pallets',
    accessor: 'pallets',
    header: 'Pallets',
    tableEditable: false,
    columnWidth: 5,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'deliveryDate',
    accessor: 'deliveryDate',
    header: 'Delivery Date',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
  {
    id: 'origin',
    accessor: 'origin',
    header: 'Origin',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'status',
    accessor: 'status',
    header: 'Status',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.STATUS,
    type: TYPES.STRING,
  },
  {
    id: 'lastModified',
    accessor: 'lastModified',
    header: 'Last Update',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.DATE_TIME,
    type: TYPES.STRING,
  },
];

export default class RoutePlansTable extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  public render() {
    return (
      <div id="mfc-page-content">
        <div className="receiving-summary-table-wrapper">
          <PageTitle title="Route Plans"/>
          <this.FilterableTable
            table={tableName}
            content={this.props.routePlansRows || []}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={() => { /* nothing?? */ }}
            loadMoreRecords={() => { /* nothing?? */ }}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder="No Route Plans"
            newButtonLabel="New Route Plan"
            onNewClicked={this.props.onNewClicked}
            checkable
            list
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            displayLoadingIndicator
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
          />
        </div>
      </div>
    );
  }
}
