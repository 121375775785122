import { tableName, property, definePresentation, setSchemaOptions } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { IRecord } from 'shared/schemas/record';

export type VendorId = number;

@tableName('vendors')
export class Vendor implements IRecord {
  static readonly SchemaName = 'Vendor';

  id?: VendorId;
  @property identifier: string;
  @property name: string;

  // There are more fields, but we don't ever access them with the old schema/repository
}

definePresentation(Vendor, {
  identifier: {
    searchable: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Identifier',
    tableDisplay: true,
    columnWidth: 16,
  },
  name: {
    searchable: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Name',
    tableDisplay: true,
    columnWidth: 16,
  },
});

setSchemaOptions(Vendor, {
  defaultSort: { sortField: 'identifier', sortOrder: 'DESC' },
  uniqueConstraints: [],
  hasLastModifiedInfo: true,
  generateGraphqlSchema: false,
});
