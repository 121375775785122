import gql from 'graphql-tag';

export const FindAllCustomerOrderProductGroupsQuery = gql`
query findAllCustomerOrderProductGroupsForProductWorksheet($type: RecordType!, $scope: [FilterSpecificationInput]) {
  content: findAll(type: $type, scope: $scope) {
    id
    ...customerOrderProductGroupFragment
    __typename
  }
}
fragment customerOrderProductGroupFragment on CustomerOrderProductGroup {
  id
  identifier
  description
  isCombo
  packsPerShippingUnit
  comboProductGroup {
    id
    identifier
    description
  }
  customerOrderProducts {
    id
    packsPerShippingUnit
    shelvesPerRack
    packSize
    packsPerShelf
    product {
      id
      identifier
      description
    }
    customerOrderAllocations {
      id
    }
    customerOrderProductGroup {
      id
      identifier
    }
  }
  customerOrder {
    id
    orderMethod
    shippingUnitType
  }
}
`;

export const customFindAllCustomerOrderFilter = (props: { params: { id?: string } }) => ([
  { field: 'customerOrder', values: [props.params.id] },
]);
