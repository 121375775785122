import FormComponent from '../../components/crud-forms/store/details-form-group';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import navBuilder from './nav-items';
import { RecordBarDetailComponent } from 'client/components/crud-forms/store/store-record-bar-detail';

export default buildAdminDetailPage({
  formName: 'StoreDetailsForm',
  table: 'Store',
  FormComponent,
  navBuilder,
  RecordBarDetailComponent,
});
