import { propToComponent } from 'client/hoc/hoc';
import { flowRight } from 'lodash';
import { MutationStatus } from 'client/actions/mutations';
import { AsyncButtonUI } from 'client/components/async-button/async-button-ui';

interface AsyncButtonProps {
  label: string;
  onClick?(): void;
  disabled?: boolean;
  classNames?: string[];
  skipBaseClasses?: boolean;
  testid: string;
  type?: 'submit';
  actionStatus?: MutationStatus;
}

interface WithSpinnerAndCheckStateProps {
  showSpinner: boolean;
  showCheck: boolean;
}

const withSpinnerAndCheckState = WrappedComponent => (props: AsyncButtonProps): shame => {
  const updatedProps = {
    ...props,
    showCheck: props.actionStatus === MutationStatus.Complete,
    showSpinner: props.actionStatus === MutationStatus.InProgress,
    disabled: props.disabled || (props.actionStatus && props.actionStatus !== MutationStatus.Initial),
  };
  return propToComponent(WrappedComponent, updatedProps);
};

export type ComponentProps =
  AsyncButtonProps &
  WithSpinnerAndCheckStateProps;

const component = flowRight(
  withSpinnerAndCheckState,
)(AsyncButtonUI);

export const AsyncButton = (props: AsyncButtonProps) => propToComponent(component, props);
