import gql from 'graphql-tag';
import { ProductWorksheetStats } from 'shared/types';

export interface ProductWorksheetStatsQueryResponse {
  worksheetStats: ProductWorksheetStats;
}

export const ProductWorksheetStatsQuery = gql`
query customerOrderWorksheetStats($customerOrderId: Int!, $selectedProductId: Int!) {
  worksheetStats: customerOrderWorksheetStats(customerOrderId: $customerOrderId, selectedProductId: $selectedProductId) {
    customerOrderId
    productId
    piecesAllocated,
    allocations {
      orderProductIdentifier,
      numberOfRacks,
      numberOfPacks,
    },
    reportedInventory,
    possibleAllocations {
      externalIdentifier,
      orderProductIdentifier,
      numberOfRacks,
      numberOfPacks,
    }
  }
}`;
