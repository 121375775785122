import * as _ from 'lodash';
import * as State from 'client/state/mutations';
import { ActionTypes, ActionTypeKeys } from 'client/actions/mutations';
import { orThrowBug } from 'shared/helpers';

type MutationsState = State.Type;

const increment = (x: number) => x + 1;
const decrement = (x: number) => _.max([x - 1, 0]) ?? 0;

export default function reducer(state = State.InitialState, action: ActionTypes): MutationsState {
  switch (action.type) {
    case ActionTypeKeys.MUTATIONS_MUTATION_SENT:
      return _.flow(
        action.payload.disableGlobalSpinner ? _.identity : State.mutationsInFlightCounterLens.update(increment),
        State.showBusyModalLens.set(action.payload.showBusyModal),
        State.busyModalMessageLens.set(undefined), // Want the default text to be used for this generic case
      )(state);

    case ActionTypeKeys.BUSY_MODAL_VISIBILITY_CHANGED:
      return _.flow(
        State.showBusyModalLens.set(action.payload.showBusyModal),
        State.busyModalMessageLens.set(action.payload.message),
      )(state);

    case ActionTypeKeys.MUTATIONS_MUTATION_RESPONSE_RECEIVED:
      const newState = _.flow(
        State.mutationsInFlightCounterLens.update(decrement),
        action.payload.disableGlobalSpinner ? _.identity : State.mutationRecentlyReceivedCounterLens.update(increment),
      )(state) || orThrowBug(`expcted lens composition to yield state object -- ActionTypeKeys.MUTATIONS_MUTATION_RESPONSE_RECEIVED`);
      if (State.mutationsInFlightCounterLens(newState) === 0) {
        return State.showBusyModalLens.set(newState, false);
      }
      return newState;

    case ActionTypeKeys.MUTATIONS_REMOVE_RECENTLY_RECEIVED_MUTATION_RESPONSE:
      return State.mutationRecentlyReceivedCounterLens.update(state, decrement);

    default:
      return state;
  }
}
