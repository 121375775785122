import { send } from 'shared/send';
import * as FileSaver from 'client/utils/filesaver';

export const downloadReceivingReceipt = async (args: { receivableOrderId: number, identifier: string, fileNameSuffix: string }) => {
  const pdfBlob = await send({
    url: `/report/receivingReceipt/${args.receivableOrderId}`,
    method: 'GET',
    asBlob: true,
  });

  const filename = `${args.identifier} - ${args.fileNameSuffix}` || 'Supplier Order';

  FileSaver.saveAs(pdfBlob, `${filename}.pdf`);

  return [];
};
