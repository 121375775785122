import * as _ from 'lodash';
import * as AllSchemas from 'shared/schemas/all';
import { pluralize, singularize, classify } from 'inflection';
import { orLog, LogLevel, Bug, orThrowBug, orThrow } from 'shared/helpers';
import { notNilOrEmptyString } from 'shared/helpers/andys-little-helpers';
import { TYPES          , DISPLAY_TYPES   , GQL_TYPES       , buildGqlType ,
        SORT_TYPES      , ActiveInactive  , toDateStr       ,
        SEARCH_FIELD_ANY, FKSpec          , SchemaOptions   , DateStr      ,
        ConformTo       , UnknownTable    , UnknownColumn   , For          ,
        Of              , Suggest         , SuggestArray    ,
} from '../types';
import { SchemaSearchInput, SchemaSortInput, SchemaFilterSpecificationInput } from 'shared/types/user-interaction-types';
import { getTable      , getSchemaOptions   , getProperties      , getPropertyPresentationOptions ,
        getAssociation , getGqlResolver     , getFieldValidators , FormDisplayType                ,
} from './dsl';
import { FieldValidator } from 'shared/validators';

export { AllSchemas };

/** Pascal-cased exported names of Rozema Schema Classes */
export type SchemaNames = keyof typeof AllSchemas;

/** The Barrel of all Rozema Schema Classes */
export type Schemas = {[K in SchemaNames]: Constructed<(typeof AllSchemas)[K]>};

/** Union of all Rozema Schema Clases - in this capacity, we mean the constructed type of each class. */
export type SchemaRecords = Schemas[SchemaNames];

/** Union of the CTOR TYPES of all the Rozema Schema Classes - the type of the class itself, i.e. the ctor function that can produce a `SchemaRecords` */
export type SchemaClasses = Constructor<SchemaRecords>;

/**
 * A mishmash of information for a field on a Rozema Schema Class, compiled from introspection, decorators, and separate metadata definitions --
 * encompassing some blend of database-level knowledge, gql-level knowledge, ui-form-level knowledge, and ui-table-level knowledge.
 * There is MUCH room for this to be clarified and distilled.
 */
export class ColumnInfo {
  /** name of the column*/ id: string & camelCasing;
  /** name of the column*/ name: string & camelCasing;
  /** name of the table containing this column*/ tableName?: string & camelCasing;
  searchable: boolean = false;
  filterable: boolean = false;
  sortable: boolean = false;
  tableDisplay: boolean = false;
  tableEditable: boolean = false;
  formDisplay: boolean = true;
  includeInFormQuery: boolean;
  includeInSubmittedForm?: boolean; // If true, this field will be submitted as part of the form, even if it's not "displayed" (per Patrick in PR#915)
  required: boolean = false;
  type: TYPES;
  gqlType: {typeName: string, isRequired: boolean, isArray: boolean};
  /** A column's FKSpec holds terms needed to resolve that column's value from a join sequence */
  fkSpec: FKSpec | null = null;
  gqlResolver: any = null;
  calculationSpec: any = null;
  displayName: string;
  formDisplayType: FormDisplayType;
  tableDisplayColumns: { [k: string]: string };
  defaultFilterWithValues?: any[];
  columnWidth?: number;
  validators?: Array<FieldValidator | ((props: any) => FieldValidator)>;
  defaultValue?: shame;
  deserializeColumnValue: <T>(src: T) => T | DateStr | null;
  get backRef() { return backRef(this); }
  constructor(col: string, overrides?: Partial<ColumnInfo>, schemaDef?: Constructor<shame>) {
    const overidden: Partial<ColumnInfo> = overrides ?? {
      validators: getFieldValidators(schemaDef!, col),
      ...getPropertyPresentationOptions(schemaDef!, col),
      ...getGqlResolver(schemaDef!, col),
      fkSpec: getAssociation(schemaDef!, col) ?? null,
    };

    Object.assign(this, {
      id                      : col,
      name                    : col,
    }, overidden, { // note, overrides used to be the last assignment... but then what would all this logic be for?  I moved it to before the logic so the logic always works -- but if bugs happen....
      includeInFormQuery      : overidden?.includeInFormQuery ?? overidden?.formDisplay ?? true,
      type                    : overidden?.fkSpec ? TYPES.NUMBER : (overidden?.type ?? TYPES.STRING),
      gqlType                 : overidden?.fkSpec?.belongsTo        ? buildGqlType(getSchemaTypeFromTableName(overidden.fkSpec.foreignTable))()
                              : overidden?.fkSpec?.hasOne           ? buildGqlType(getSchemaTypeFromTableName(overidden.fkSpec.foreignTable))()
                              : overidden?.fkSpec                   ? buildGqlType(getSchemaTypeFromTableName(overidden.fkSpec.foreignTable))({ isArray: true })
                              : overidden?.type === TYPES.NUMBER    ? GQL_TYPES.INT()
                              : overidden?.type === TYPES.FLOAT     ? GQL_TYPES.FLOAT()
                              : overidden?.type === TYPES.BOOLEAN   ? GQL_TYPES.BOOLEAN()
                              : overidden?.type === TYPES.DATE      ? GQL_TYPES.DATE()
                              : overidden?.type === TYPES.DATE_TIME ? GQL_TYPES.DATE_TIME()
                              :                                       GQL_TYPES.STRING(),
      tableDisplayColumns     : overidden?.fkSpec && !!_.keys(overidden?.tableDisplayColumns ?? {}).length ? _.fromPairs(overidden.fkSpec.foreignQueryKeys.filter(k => !!overidden?.tableDisplayColumns?.[k]).map(k => [k, overidden?.tableDisplayColumns?.[k]])) // when tableDisplayColumns, it dictates what is included here
                              : overidden?.fkSpec       ? _.fromPairs(overidden.fkSpec.foreignQueryKeys.map(k => [k, overidden?.displayName ?? _.startCase(col)])) // otherwise, do your best with displayName or startCase
                              : overidden?.tableDisplay ? { [col]: overidden?.displayName ?? _.startCase(col) } // seems weird tableDisplay and foreignTable case are now mutually exclusive -- original formulation had non-exclusive if branches, but the last one always won (so I moved it to first in this ternary)
                              : {},
      defaultFilterWithValues : overidden?.defaultFilterWithValues,
      // we _.merge these ColumnInfo objects with a series of other object graphs, so `this` context is lost. any methods need to be pure functions assigned as ownProps, or they won't be available later on.
      deserializeColumnValue  : <T>(v: T) => !overidden?.fkSpec && overidden?.type === TYPES.DATE ? (v ? toDateStr(v as shame<'fn will crash if incompatible'>) : null) : v,
    });

    // TODO: this is a mega hack to get around the fact that we don't have a way to specify a GQL type separate from Table name in the schema decorators
    if (col === 'receivableOrder') {
      this.gqlType = { typeName: 'UnifiedReceivableOrder', isRequired: false, isArray: false };
    }

    if (!overrides && !!schemaDef) {
      const tableName = getTable(schemaDef) ?? orThrow(`Can't create schema without a @tableName`);
      this.tableName = tableName;
      if (this.fkSpec?.hasMany)
        this.fkSpec.nativeTableFK ??= `${_.camelCase(singularize(tableName))}Id`;

      // ensure dropdowns know how to display values, and how to identifiy values internally.
      if (this.formDisplayType?.type === DISPLAY_TYPES.DROPDOWN) {
        this.formDisplayType.valueField ??= this.fkSpec?.foreignTablePK ?? 'id';
        this.formDisplayType.displayValueResolver ??= choice =>
          this.fkSpec?.foreignQueryKeys?.map(k => choice[k])?.join('-') ?? choice.value;
      }
    }
  }
  static readonly makeIdColumn        = () => new ColumnInfo('id'       , { gqlType: GQL_TYPES.INT()      , type: TYPES.NUMBER   , formDisplay: false });
  static readonly makeCreatedAtColumn = () => new ColumnInfo('createdAt', { gqlType: GQL_TYPES.DATE_TIME(), type: TYPES.DATE_TIME, formDisplay: false });
  static readonly makeUpdatedAtColumn = () => new ColumnInfo('updatedAt', { gqlType: GQL_TYPES.DATE_TIME(), type: TYPES.DATE_TIME, formDisplay: false, includeInFormQuery: true });
}

/**
 * A TableInfo is a collection of ColumnInfo objects, and some additional metadata about the table itself.
 * By `Table` we mean a mishmash of information for a Rozema Schema Class, compiled from introspection, decorators, and separate metadata definitions --
 * encompassing some blend of database-level knowledge, gql-level knowledge, ui-form-level knowledge, and ui-table-level knowledge.
 * There is MUCH room for this to be clarified and distilled.
*/
export class TableInfo<T extends SchemaRecords> implements SchemaOptions<T> {
  /** recordType -- typically the name of a ctor (inflection.Singularized and _.Classified), but can be overriden, or ctor may not exist. */
  type: PascalCasing;
  /** nativeTable -- in db terms (snake cased, plural) */
  tableName: TableName & snake_casings;
  defaultSearch?: SchemaSearchInput<T>;
  defaultSort?: SchemaSortInput<T> | Array<SchemaSortInput<T>>;
  defaultFilter?: Array<SchemaFilterSpecificationInput<T>>;
  hasTimestamps?: boolean;
  hasLastModifiedInfo?: boolean;
  softDeletable?: boolean;
  joins?: Array<keyof T>;
  uniqueConstraints?: string[];
  generateGraphqlSchema?: boolean;
  columns: { [k in keyof T]: ColumnInfo };
  get columnInfos() { return _.values(this.columns); }
  constructor(schemaDef: Constructor<T>) {
    const schemaOpts = getSchemaOptions(schemaDef) ?? {};
    this.columns = _.fromPairs([
      ColumnInfo.makeIdColumn(),
      ...(schemaOpts.hasTimestamps !== false ? [ColumnInfo.makeCreatedAtColumn()] : []), // explicit comparison to false, because included by default
      ...(schemaOpts.hasTimestamps !== false ? [ColumnInfo.makeUpdatedAtColumn()] : []), // explicit comparison to false, because included by default
      ...getProperties(schemaDef).filter(p => p !== 'id' && p !== 'createdAt' && p !== 'updatedAt'),
    ]
      .map(n => n instanceof ColumnInfo ? n : new ColumnInfo(n, undefined, schemaDef))
      .map(c => [c.name, c])
    ) as shame;

    const tableName = getTable(schemaDef) ?? orThrow(`Can't create schema without a @tableName`);
    Object.assign(this, {
      ...schemaOpts,
      tableName,
      type: schemaOpts.type ?? getSchemaTypeFromTableName(tableName),
      hasTimestamps: schemaOpts.hasTimestamps || !!this.columns['updatedAt'] || !!this.columns['createdAt'],
      hasLastModifiedInfo: schemaOpts.hasLastModifiedInfo || !!this.columns['lastModifiedAt'],
      generateGraphqlSchema: schemaOpts.generateGraphqlSchema ?? true,
      defaultSearch: schemaOpts.defaultSearch ?? { fields: [SEARCH_FIELD_ANY], text: '' },
      defaultSort: schemaOpts.defaultSort ?? { sortOrder: SORT_TYPES.ASC, sortField: 'identifier' as keyof T }, // TODO: ideally, runtime assert
      defaultFilter: schemaOpts.defaultFilter ?? [
        ...(this.columns['activeStatus']?.tableDisplay ? [{ field: 'activeStatus', values: [ActiveInactive.Active] }] : []),
        ..._.values(this.columns).filter(x => !!x?.defaultFilterWithValues).map(x => ({ field: x.id, values: x.defaultFilterWithValues })),
      ],
      joins: schemaOpts.joins,
    });
  }
}

/** The Barrel of Rozema Schema Classes, projected to their derived TableInfos. */
export type RootSchema = {[K in SchemaNames]: TableInfo<Schemas[K]>};

/** Pascal-cased keys for TableInfos derived from Rozema Schema Classes. */
export type TableName = keyof RootSchema;

/** A particular TableInfo with known TableName */
export type TableInfoFor<T extends string>
  = T extends TableName ? RootSchema[T]
  : TableInfo<UnknownTable<T>>;

/** A dictionary of ColumnInfos with known TableName; the keys for the columns / fields are camel-cased. */
export type ColumnsDictFor<T extends string>
  = T extends TableName ? RootSchema[T]['columns']
  : TableInfo<UnknownTable<T>>['columns'] & UnknownTable<T>;

/** A particular camel-cased name for a column / field from a particular TableInfo; or, if the C type parameter is unspecified, then the union of all such column names for the given TableMame entry in the `RootSchema` (see above, not to be confused with a GQL schema root) */
export type ColumnOf<T extends string, C extends string | undefined = undefined>
  = T extends TableName
  ? ( C extends undefined ? keyof ColumnsDictFor<T>
    : C extends keyof ColumnsDictFor<T> ? C & Of<T>
    : string & UnknownColumn<C> & Of<T>)
  : ( C extends undefined ? (string & Of<UnknownTable<T>>)
    : (string & UnknownColumn<C> & Of<UnknownTable<T>>));

/** A particular ColumnInfo with known TableName and ColumnName */
export type ColumnInfoFor<T extends string, C extends string>
  = T extends TableName
  ? ( C extends keyof RootSchema[T]['columns']
    ? ColumnInfo & For<C & Of<T>>
    : ColumnInfo & For<UnknownColumn<C> & Of<T>>)
  : ( ColumnInfo & For<UnknownColumn<C> & Of<UnknownTable<T>>>);

const getSchemaTypeFromTableName = (table: string) => !table.endsWith('Data')
  ? classify(singularize(table)) as SchemaNames
  : _.upperFirst(table) as SchemaNames;

/** normal production schemas, generated from AllSchemas definitions */
const baseSchemas = _.mapKeys(_.mapValues(AllSchemas, schemaDef => new TableInfo(schemaDef as thisIsFine)), v => v.tableName) as thisIsFine as RootSchema;

/** generated schemas - i.e., the actual JS value of the barrel of TableInfos and their contained ColumnInfos derived from the Barrel of all Rozema Schema Classes. */
export let schemas = baseSchemas; // TODO - encapsulate to force external use of overrideMasterSchema and revertMasterSchema

/** Constructs a JS array of the Pascal-cased names of the TableInfos PRESENTLY in `schemas`, usually it is the barrel of TableInfos derived from all Rozema Schema Classes. */
export const allSchemaNames = () => _.keys(schemas).filter(k => _.isString(schemas[k]?.type)).sort() as TableName[];

/** An array of the PascalCased names of the Rozema Classes */
export const allRecordTypes = allSchemaNames().map(t => schemas[t].type);

/** automorphic map of the PascalCased `TableName`s (Rozema Class names) */
export const Tables = _.mapValues(schemas, (v, k) => k) as {[T in TableName]: T};

/** set this to a different LogLlevel to adjust warning behavior */
// const shouldWarnOnUnknownSchema: LogLevel = process?.env?.NODE_ENV === 'development' ? 'warn' : 'log'; // do not use word "error" in test logs - circleci treats as failure
const shouldWarnOnUnknownSchema: LogLevel = process?.env?.NODE_ENV === 'development' ? 'warn' : 'silent'; // do not use word "error" in test logs - circleci treats as failure

/** deescribe the problem with the missing type `t` */
const missingSchema = (t: unknown) => `WARNING: Schema for type '${t}' does not exist!`; // \n${new Error(`missingSchema '${t}'`).stack}`;
export const isTableName = (t: string): t is TableName => _.has(schemas, t);

/**
 * normalizes a string to a TableName, as determined by keys generated from AllSchemas - or undefined if not found.
 * ---
 * Sometimes literal types are known at compile time -- sometimes not.
 * how to allow hard constrain to a broad type, accommodating runtime-deferred values,
 * but also permitting and propagating const literal types also?
 * -- ConformTo and Suggest with For,Of,Unrecognized to annotate WHY */
export const tableName = <T extends string>(t: Suggest<T, TableName>, maybe?: 'maybe'): ConformTo<T, TableName> => {
  if (!notNilOrEmptyString(t))
    return maybe ? undefined : orLog(undefined as any, `WARNING: Table '${t}' is blank and could not possibly be defined in AllSchemas.`, shouldWarnOnUnknownSchema);

  if (isTableName(t)) return t as shame<'just coerce - DFA seems broken in ts4.9'>;
  const sanitizations = _.uniq([
    pluralize(_.camelCase(t)),
    _.camelCase(t), // e.g., productToss
    singularize(_.upperFirst(_.camelCase(t))), // TODO - not sure about this one, seems either legacy or mis-guided on spike/node12 branch
  ]);

  for (const sanitized of sanitizations)
    if (isTableName(sanitized)) return sanitized as shame<'just coerce - DFA seems broken in ts4.9'>;

  const alternatives = sanitizations.filter(x => x !== t).map(x => `'${x}'`);
  const sanityMessage = alternatives.length < 1 ? '' : ` (nor were sanitized variants {${alternatives.join()}})`;
  return maybe ? undefined : orLog(undefined as any, `WARNING: Table '${t}' not defined in AllSchemas${sanityMessage}`, shouldWarnOnUnknownSchema);
};

export const tableInfo   = <T extends string>(t: Suggest<T, TableName>, maybe?: 'maybe') => (schemas[tableName(t, maybe)] ?? orLog({columns: {}}, missingSchema(t), shouldWarnOnUnknownSchema)) as TableInfoFor<T>;
export const columns     = <T extends string>(t: Suggest<T, TableName>) => Object.keys(tableInfo(t).columns) as [ColumnOf<T>];
/** recordType -- typically the name of a ctor (inflection.Singularized and _.Classified), but can be overriden, or ctor may not exist. */
export const recordType  = <T extends string>(t: Suggest<T, TableName>) => tableInfo(t).type ?? 'Unknown';
export const isColumnOf  = <T extends string>(t: Suggest<T, TableName>, c: string): c is ColumnOf<T, typeof c> => _.has(tableInfo(t).columns, c);

/** Given a ColumnInfo with a hasMany fkSpec, return the ColumnInfo on the referenced foreign table that reciprocates with a belongsTo fkSpec pointing at  the original nativeTable.  hasOne relationships do count as valid input. */
export const backRef = (col: ColumnInfo): ColumnInfo | Nil => !col.fkSpec?.hasMany ? undefined : _(tableInfo(col.fkSpec.foreignTable).columnInfos).filter(c => !!c.fkSpec).find(c => tableName(c.fkSpec!.foreignTable) === tableName(col.tableName ?? orThrow(`col must have tableName: ${col}`))); // TODO - what if there are many?

/** Sometimes literal types are known at compile time -- sometimes not.  how to allow hard constrain to a broad type, accommodating runtime-deferred values, but also permitting and propagating const literal types also? */
export const columnName = <T extends string, C extends string>(t: Suggest<T, TableName>, c: Suggest<C, ColumnOf<ConformTo<T, TableName>>>, maybe?: 'maybe'): ColumnOf<T, C> => {
  if (_.isNil(t)) throw new Bug(`epected never nil: 't' === ${t}`);
  if (_.isNil(c)) throw new Bug(`epected never nil: 'c' === ${c}`);
  const tn = tableName(t);
  if (!tn) return orLog(undefined as any, `WARNING: Column '${c}' not part of Table '${tn}' -- table not recognized ! -- TODO convert to throw`, shouldWarnOnUnknownSchema);
  const sanitizations = _.uniq([
    c,
    c.replace(/Id$/, ''),
    singularize(_.camelCase(c)),
    singularize(_.camelCase(c).replace(/Id$/, '')),
    singularize(_.camelCase(c).replace(/Id$/, '')) + 'Id',
    pluralize(_.camelCase(c)),
    pluralize(_.camelCase(c).replace(/Id$/, '')),
    pluralize(_.camelCase(c).replace(/Id$/, '')) + 'Ids',
  ]);

  for (const sanitized of sanitizations)
    if (isColumnOf(tn, sanitized)) return sanitized as shame<'just coerce - DFA seems broken in ts4.9'>;

  const alternatives = sanitizations.filter(x => x !== c).map(x => `'${x}'`);
  const sanityMessage = alternatives.length < 1 ? '' : ` (nor were sanitized variants {${alternatives.join()}})`;
  return maybe
    ? (!shouldWarnOnUnknownSchema ? undefined : orLog(undefined as any, `WARNING: Column '${c}' not part of Table '${tn}'${sanityMessage}`, shouldWarnOnUnknownSchema))
    : orThrow(`WARNING: Column '${c}' not part of Table '${tn}'${sanityMessage}`);
};

/** From Metadata Reflection -- Get the designated ColumnInfo or an empty object if it doesn't exist. Coerces to camelCase and removes 'Id' suffix if needed */
export const columnInfo = <T extends string, C extends string>(t: Suggest<T, TableName>, c: Suggest<C, ColumnOf<ConformTo<T, TableName>>>, maybe?: 'maybe'): ColumnInfoFor<T, C> =>
  (tableInfo(t, maybe)?.columns[columnName(t, c, maybe) as string] as shame<'should conform at runtime.  USE ZOD !!!'>) ?? undefined; // orLog(undefined as any, `WARNING: '${t}'.'${c}' absent from schema`, warnOnUnknownSchema);

/** examples */
// const zzzzzzzz = 0 as any as UnknownTable<'abc'> & UnknownColumn<'fff'> & 'fff';
// const zzzzzzz = 0 as any as TableInfoFor<'AgentEvent'>;
// const zzzzzz = 0 as any as ColumnsDictFor<'AgentE4vent'>;
// const azzzzzz = 0 as any as ColumnsDictFor<'AgentEvent'>;
// const zzzzz = 0 as any as UnknownTable<'Agentvent'>;
// const zzzz = 0 as any as TableInfoFor<'AgentE4vent'>;
// const affzzz = columns('moo');
// const affzzzrr = columns('SupplierItemCost');
// const azzz = columnName(Tables.AgentEvent, 'messageType');
// const zzz = columnName(Tables.AgentEvent, '354564');
// const zz = columnName('Store', 'indoorDeliveryLocation');
// const zza = columnName('Storeee', 'indoorDeliveryLocation');
// const zzaa = columnName('Storeee', 'moo');
// const z = columnInfo('Customer', 'identifier');
// const az = columnInfo('Customer', 'identiier');
// const azz = columnInfo('Custoer', 'identiier');

/**
 * Deceptively named!  Does not return a format, and only occasionally applies to FK relationships.
 * Better explanation: tells you the validated column name on the native table, and if applicable, the validated field on the foreign table.
 * If `nativeDotForeign` has not dot, it will be used like a simple native column and no FK relationship.
 * @param nativeT: native table name
 * @param nativeDotForeign: native column that points to a foreign table -- OPTIONALY with a .foreignColumnName suffix
 */
export const getForeignColumnFormat = <T extends string>(nativeT: Suggest<T, TableName>, nativeDotForeign: string, foreignColumnOverride?: string) => {
  if (!/^(\w+)\.(\w+)$|^(\w+)$/.test(nativeDotForeign)) throw new Bug(`nativeDotForeign: '${nativeDotForeign}' -- does not conform to either FK pattern '{nativeColumn::w+}.{foreignColumn::w+}' or to simple pattern 'w+'`);
  const [nativeC,foreignC] = nativeDotForeign.split('.') as [string, string | undefined];
  const nativeTable = tableName(nativeT ?? orThrowBug(`epected never nil: t === '${nativeT};`)) ?? orThrowBug(`'${nativeT}'::'${[nativeC,foreignC]}' does not reference a recognized nativeTable`);
  if (_.isNil(nativeC)) throw new Bug(`epected never nil: nativeC === '${nativeC}'`);
  const nativeColumn = columnName(nativeTable, nativeC) ?? orLog(undefined, `could not validate afferent FK column '${nativeC}' on nativeTable '${nativeTable}'`, shouldWarnOnUnknownSchema);
  if (!foreignC && !foreignColumnOverride)
    return { field: nativeColumn, foreignColumn: undefined };

  const nativeColumnInfo = columnInfo(nativeTable, nativeColumn);
  const foreignTableName = nativeColumnInfo.fkSpec?.foreignTable ?? orLog('_foreignTable_unSpecified_', `could not resolve foreignTable from ${JSON.stringify(nativeColumnInfo, null, 4)}`, shouldWarnOnUnknownSchema);
  const foreignTable = tableName(foreignTableName) ?? orLog('_foreignTable_unSpecified_', `could not validate foreignTable '${foreignTableName}'`, shouldWarnOnUnknownSchema);
  // DVM 2024 04 08 - I think maybe to allow foreignColumnOverride to work as intended, cannot use the columnName helper here.
  // const foreignColumn = columnName(foreignTable, foreignC ?? foreignColumnOverride ?? '_foreignColumn_unSpecified_') ?? orLog('_foreignColumn_unSpecified_', `could not validate efferent FK column '${foreignC ?? foreignColumnOverride}' on foreignTable '${foreignTable}'`);
  const foreignColumn = foreignColumnOverride ?? foreignC ?? orLog('_foreignColumn_unSpecified_', `could not validate efferent FK column '${foreignC ?? foreignColumnOverride}' on foreignTable '${foreignTable}'`, shouldWarnOnUnknownSchema);
  const foreignColumnInfo = columnInfo( foreignTable , foreignColumn );
  if (foreignColumnInfo.id)
    return { field: nativeColumn, foreignColumn };

  if (nativeColumnInfo.id)
    return { field: nativeColumn, foreignColumn: undefined };

  return orLog({ field: undefined,     foreignColumn: undefined }, `Foreign column does not exist for table ${foreignTable} => ${foreignColumn} (2)`, shouldWarnOnUnknownSchema);
};

       const columnsWhere         = <T extends string>(t: Suggest<T, TableName>, fn: (x: ColumnInfo) => boolean) => columns(t).filter((c: ColumnOf<T>) => fn(columnInfo(t, c)));
export const searchableColumns    = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.searchable                                     );
export const filterableColumns    = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.filterable                                     );
export const calculatedColumns    = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.calculationSpec                                );
export const nativeColumns        = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>  !x.fkSpec       && !x.gqlResolver                 );
export const belongsToColumns     = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x => !!x.fkSpec      ?.belongsTo && !x.fkSpec?.through  );
export const sortableColumns      = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.sortable                                       );
export const formDisplayColumns   = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.formDisplay                                    );
export const tableDisplayColumns  = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.tableDisplay                                   );
export const formQueryColumns     = <T extends string>(t: Suggest<T, TableName>                                ) => columnsWhere(t, x =>   x.includeInFormQuery || x.formDisplay || x.tableDisplay);
export const defaultValues        = <T extends string>(t: Suggest<T, TableName>) => _.pickBy(_.mapValues(tableInfo(t).columns, 'defaultValue'), defaultValue => defaultValue !== undefined);

export class JoinSpecification {
  /** earlier table in join list */
  readonly from: { readonly table: camelCasings, readonly column: camelCasing };
  /** currently introduced foreign table */
  readonly to: { readonly table: camelCasings, readonly column: camelCasing };
  /** join TO (for currently introduced foreign table) alias */
  readonly alias?: camelCasing;
  /** also called FKSpec['specification']
   * or ForeignNode['info']['specification']
   * or ElementOf<ForeignTree['nodes']>['info']['specification']
   * -- a knex andWhere spec object -- kvps*/
  readonly predicateExtension?: SimpleObject | null;
  readonly isValid: boolean;
  constructor(nativeTable: camelCasings, nativeColumn: camelCasing | Nil, foreignTable: camelCasings, foreignColumn?: camelCasing, alias?: camelCasing, predicateExtension?: SimpleObject | null) {
    this.from = { table: nativeTable, column: nativeColumn ?? (singularize(foreignTable) + 'Id') };
    this.to = { table: foreignTable, column: foreignColumn ?? 'id' };
    this.alias = alias;
    this.predicateExtension = predicateExtension;
    this.isValid = !!this.to?.table && !!this.to?.column && !!this.from?.table && !!this.from?.column;
  }
  static readonly sameTables = (a: JoinSpecification, b: JoinSpecification) => a.from.table === b.from.table && a.to.table === b.to.table;
  readonly toSQL = (forFulltextSearch: boolean) => {
    const toTable = _.snakeCase(this.to.table);
    const toAlias = this.alias ? _.snakeCase(this.alias) : toTable;
    const toAliasDeclaration = this.alias ? `AS ${toAlias}` : '';
    let keyPredicate = `(${toAlias}.${_.snakeCase(this.to.column)} = ${_.snakeCase(this.from.table)}.${_.snakeCase(this.from.column)})`;
    if (forFulltextSearch && this.predicateExtension) {
      const extensions = _.toPairs(this.predicateExtension).map(([k, v]) => `(${toAlias}.${_.snakeCase(k)} = ${(typeof v === 'string' ? `'${v}'` : v)})`).join(' AND ');
      keyPredicate += ` AND ${extensions}`;
    }

    return `${toTable} ${toAliasDeclaration} ON ${keyPredicate}`;
  };
}

/**
 * a single hop along a chain from an origin nativeColumn to a foreignTable and its FKSpec (which explains how to bind to it.)
 * FKNode's foreign vs. native perspective is always in DB terms, native.FK -> foreign.ID. (though identifiers are camelCasing.camelCasing)
 */
export type FKNode = {nativeColumn: camelCasing, nativeTableWhoseColumnResolvesIndirectly?: camelCasings | Nil, info: FKSpec};

/**
 * holds an FK chain of 1 or more hops from an origin nativeColumn to an ultimate foreignTable;
 * the very first FKNode.FKSpec explains how to load / display the terminal
 * foreign table's records in the context of the origin nativeColumn.
 * Subsequent FKSpecs really only explain how to traverse the chain.
 * TODO: explore dis-entangling these details of the FKSpec : display-oriented, traversal-oriented.
 * SUPER-TODO: change FKSpecs to only point in the direction of the atual FK relationships in the DB.
 * The reversability of 'belongs to' vs 'has many' is confusing, it means that sometimes the spec explains how nativeColumn is resolved by nativeTable.pointerColumn -> foreignTable.idColumn,
 * and sometimes the spec explains how nativeColumn is resolved (possibly to an array) by foreignTable.pointerColumn -> nativeTable.idColumn.
 * A cleaner approach might be to simply embed nativeColumn => (foreignTable, traversal-aware projection),
 * and resolve the joins solely from the graph of nativeTable.pointerColumn -> foreignTable.idColumn,
 * following a shortest-path.  The same graph could be used to enforce transitive integrity rules (A -> C must agree with A -> B -> C).
 * @param type : e.g., hasMany is inbound FK chain, belongsTo is outbound.  hasMany is currently not allowed to be transitive.
 */
export type FKChain = {
  type: 'hasMany' | 'belongsTo',
  /** in format camelCasings.camelCasing */
  origin: string,
  /** in format camelCasings.camelCasing */
  target: string,
  nodes: FKNode[],
  joins: JoinSpecification[],
};

/**
 * indirect references MANDATE that the origin table's schema includes references for each link in the requisite reference chain.
 * TODO : in the future, probably allow schema traversal instead of partial schema replication / inlining.
 */
const findIntermediaryLinks = <T extends string, C extends string>(t: Suggest<T, TableName>, c: Suggest<C, ColumnOf<ConformTo<T, TableName>>>) => {
  let info: ColumnInfo = columnInfo(t, c);
  const dependencies = [info];
  while (info?.fkSpec?.through)
    dependencies.push(info = columnInfo(t, info.fkSpec!.through, 'maybe'));

  return dependencies.filter(x => !!x);
};

/** You give me an origin nativeTable and a list of desired (possibly fk-projected) nativeColumns, and I will give you a matching list of FKChains to resolve everything. */
export const buildFKChains = <T extends string, Cols extends string[]>(nativeTable: Suggest<T, TableName>, cols: SuggestArray<Cols, ColumnOf<T>>): FKChain[] => {
  const originRefs = _(cols)
    .flatMap(c => findIntermediaryLinks(nativeTable, c))
    .filter(fk => notNilOrEmptyString(tableName(fk?.fkSpec?.foreignTable ?? '', 'maybe')))
    .uniqBy(fk => fk.id)
    .value();

  const chains = cols
    .map(col => originRefs.find(x => x.id === col))
    .filter(ref => !!ref)
    .map((originRef: ColumnInfo) => {
    const originRefSpec = originRef.fkSpec!;
    if (!originRefSpec.belongsTo && !originRefSpec.hasMany)
      throw new Bug(`Expected unreachable state: foreignTable spec of unknown kind in schema for ${nativeTable}`);

    if (originRefSpec.hasMany) {
      const nodes = [
        { nativeColumn: originRef.id, nativeTableWhoseColumnResolvesIndirectly: nativeTable, info: originRefSpec }, // <-- foreign and native are reversed for hasMany relationships (to enable FK traversal in DB terms)
        // SPECIAL for hasMany: the first node gets you 1 hop to the foreign table (transitive relationship not permitted)
        // the REST are belongsTo dependencies FROM that foreign table.  e.g., product.productSpecs => productSpec + productSpec.{pot, tag, tray}
        ..._(columns(originRefSpec.foreignTable)).map(fc => columnInfo(originRefSpec.foreignTable, fc))             // <-- all columns from the foreignTable of whose records the native record "hasMany"
          .filter(fc => !!fc.fkSpec)                                                                                // <-- considering only those foreignColumns which are FKs themselves
          .filter(fc => !fc.fkSpec?.through)                                                                        // <-- transitive hasMany is not currently allowed -- probably best, due to cost of combinatorial explosion of record count along a cascading 1:* join path
          .filter(fc => !!fc.fkSpec!.belongsTo)                                                                     // <-- we're interested in the foreign columns that point outward along FKs
          .filter(fc => fc.fkSpec!.foreignTable !== nativeTable)                                                    // <-- give us the foreign columns that point AWAY FROM the origin nativeTable
          .map(fc => ({ nativeColumn: fc.id, nativeTableWhoseColumnResolvesIndirectly: originRefSpec.foreignTable, info: fc.fkSpec }))
          .orderBy(x => x.nativeColumn)                                                                             // <-- sort them alphabetically by 1st hop foreign column (e.g., products -> productSpecs + productSpecs.[pot, tag, tray])
          .value()                                                                                                  //
          ?? orThrowBug(`expected ${nativeTable}.${originRef.id} to be referenced by exactly one column of ${originRefSpec.foreignTable}, but none did so.`),
      ] as FKNode[];

      return {
        type: 'hasMany',
        origin: `${nativeTable}.${originRefSpec.nativeColumn}`,
        target: `${originRefSpec.foreignTable}.${originRefSpec.foreignTablePK}`,
        nodes,
        joins: nodes.map(node => new JoinSpecification(
          nativeTable,
          'id',
          node.info.foreignTable,
          `${singularize(nativeTable)}Id`,
          node.info.alias,
          node.info.andWhere
        )),
      } as const;
    }

    const nodes: FKNode[] = [];
    for (let currentRef: ColumnInfo | Nil = originRef; !!currentRef;) {
      const prerequisite = originRefs.find(x => x.fkSpec!.foreignTable === currentRef!.fkSpec!.through); // this is the part that requires origin table to reference all intermediaries.
      nodes.push({
        nativeColumn: currentRef.id,
        nativeTableWhoseColumnResolvesIndirectly: tableName(prerequisite?.fkSpec?.foreignTable!, 'maybe') ?? null,
        info: currentRef.fkSpec!,
      });
      currentRef = prerequisite;
    }
    nodes.reverse();

    if (nodes.length < 1) throw new Bug(`construction algorithm must guarantee at least one FKNode per FKChain`);
    const joins = nodes.map(node => new JoinSpecification(
      node.info.through ?? nativeTable,
      node.info.nativeTableFK,
      node.info.foreignTable,
      node.info.foreignTablePK,
      node.info.alias,
      node.info.andWhere
    ));

    return {
      type: 'belongsTo',
      origin: `${nativeTable}.${originRefSpec.nativeColumn}`,
      target: `${originRefSpec.foreignTable}.${originRefSpec.foreignTablePK}`,
      nodes,
      joins,
    } as const;
  });

  return chains;
};

/** Allow for constructing a hypothetical tree of possible GQL non-scalar fields. */
class GqlFragNode {
  private constructor(
    readonly field: string,
    readonly args?: null | Record<string, string | number | boolean | null>,
    readonly fkSpec?: null | FKSpec,
    readonly children?: null | GqlFragNode[]
  ) { }

  private static readonly Indent = (depth: number) => _.repeat(' ', /*TAB_SIZE*/ 4 * depth);
  * gqlLines(depth: number = 1) {
    const indentation = GqlFragNode.Indent(depth);
    if (!this.children?.length) {
      yield `${indentation}${this.field}`;
      return;
    }

    yield `${indentation}${this.field} {`;
    for (const child of this.children ?? [])
      yield* child.gqlLines(depth + 1);

    yield `${indentation}}`;
  }

  /** workhorse: recursively convert non-scalar fields to GqlFragNodes linked in the appropriate tree structure. */
  private static readonly expandFragment = (cols: ColumnInfo[], requested: string[], visitedTables: string[], depth: number = 0) => {
    // in-fill transitive intermediary links
    // -- assumes it is allowable for this invocation to modify the `requested` array in-place:
    // (callsite is aware or holds no reference.... allowable because this function is private, so callsites are designed in the same context.)
    requested.splice(0, requested.length, ..._(cols)
      .filter(c => requested.includes(c.name))
      .filter(c => !!c.fkSpec?.nativeTable)
      .flatMap(c => findIntermediaryLinks(c.fkSpec?.nativeTable ?? '', c.id))
      .map(c => c.name)
      .concat('id', ...requested)
      .uniq()
      .sort()
      .value()
    );

    const subFrag: _.Collection<GqlFragNode> = _(cols)
      .filter(c => requested.includes(c.name))
      .filter(c => !c.fkSpec?.through || depth === 0) // force indirect references to be mounted as deep as possible (unless this is top level.)
      .filter(c => !c.fkSpec || !visitedTables.includes(c.fkSpec.foreignTable) || (!!c.fkSpec.alias && !visitedTables.includes(c.fkSpec.alias))) // prevent re-visiting tables (but watch out, sometimes with an alias, you need to anyway)
      .uniqBy(c => c.name)
      .sortBy(c => !!c.fkSpec?.through, c => c.name) // try to knock things out direct first, indirect last, and alphabetically within each group
      .map(col => {
        if (col.fkSpec && !!col.fkSpec.alias && !visitedTables.includes(col.fkSpec.alias))
          visitedTables.push(col.fkSpec.alias);
        else if (col.fkSpec && !visitedTables.includes(col.fkSpec.nativeTable))
          visitedTables.push(col.fkSpec.nativeTable);

        return new GqlFragNode(
          col.name,
          undefined,
          col.fkSpec,
          !col.fkSpec ? undefined : GqlFragNode.expandFragment(
            tableInfo(col.fkSpec.foreignTable).columnInfos.concat([ColumnInfo.makeIdColumn()]),
            _.uniq([
              'id',
              'identifier',
              'name',
              'description',
              'lastModifiedAt',
              col.fkSpec.foreignDisplayKey,
              ...col.fkSpec.foreignQueryKeys,
              ...tableInfo(col.fkSpec.foreignTable).columnInfos.filter(x => !!x.fkSpec?.belongsTo).map(x => x.name),
              ...(!col.fkSpec.hasMany ? [] : formQueryColumns(col.fkSpec.foreignTable).filter(x => !columnInfo(col.fkSpec!.foreignTable, x).fkSpec)),
              // ...requested, top level requested does not imply sub-fragment requested
            ]),
            visitedTables, // KEEP SAME ARRAY OBJECT REFERENCE
            depth + 1,
          ).value()
        );
      });

      return subFrag;
  };

  /** The entrypoint for cosntructing a sub-tree of GQL non-scalar fields.  Returns a string in GQL fragment syntax. */
  static readonly buildFragment = <T extends string, Cols extends string[]>(t: Suggest<T, TableName>, cols: SuggestArray<Cols, ColumnOf<T>>, fragmentName?: string) => {
    const fragName = fragmentName ?? `${recordType(t)}Fragment`;
    const fragDef = `fragment ${fragName} on ${recordType(t)}`;
    const visitedTables = [t];
    const tree = GqlFragNode.expandFragment(tableInfo(t).columnInfos, [/*protective clone*/...cols], visitedTables)
      // Lastly, put things back in order as specified by callsites:
      // (mostly - additional or intermediate columns can just pile onto the end.)
      .sortBy(node => Math.max(0, 1 + cols.indexOf(node.field as ColumnOf<T>)) || 1000 )
      .value();

    return [
      `${fragDef} {`,
      ..._.flatMap(tree, field => [...field.gqlLines()]),
      `}`,
    ].join('\n');
  };
}

/** tell us what table and what list of cols, and we'll give you a GQL frag string.  */
export const buildFragment = <T extends string, Cols extends string[]>(t: Suggest<T, TableName>, cols: SuggestArray<Cols, ColumnOf<T>>, fragmentName?: string) =>
  GqlFragNode.buildFragment(t, cols, fragmentName);

// DVM 2024 07 03 - I may have "typescript-ified" this, but Rozema wrote it, Andy augmented it, and I don't understand it yet.  I think I broke it possibly, too.
// DVM 2024 07 15 - the more I need to tweak this to make tests or UI code happy, the more I know it was another RozemaTurd idea.
// DVM 2024 07 22 - this function has caused me MORE MISERY.....
/**
 * Used on the client to expand the results of a GQL query in a manner usable by Rozema's FormComponents.
 * In general, traverse the object with schema in-hand, and uses the schema (Rozema class) to decide whether to
 * recursively flatten child records by mounting them directly to their parent, with id explicitly listed as `{camelCasingTableName}Id`
 * @param recordWithEmbeddedChildren: the data to be expanded, usually the `data` prop returned by an Apollo query
 */
export const flattenFragment = <D extends SimpleObject, T extends string>(recordWithEmbeddedChildren: D, table: Suggest<T, TableName>) =>
  (!_.keys(recordWithEmbeddedChildren ?? {}).length) ? {} : {
    // spread the original result  data
    ..._.omit(recordWithEmbeddedChildren, '__typename'),
    // ..._.omit(belongsToOnly ? {} : recordWithEmbeddedChildren, '__typename'),
    // deserialize native columns
    ..._(tableInfo(table).columnInfos)
      // .filter(c => !belongsToOnly)
      .filter(c => !c.fkSpec)
      .filter(c => _.has(recordWithEmbeddedChildren, c.id))
      .orderBy(c => c.id, 'asc')
      .map(c => [c.id, c.deserializeColumnValue(recordWithEmbeddedChildren[c.id])])
      .fromPairs()
      .value(),
    // deserialize foreign columns (and special extra stuff..........)
    ..._(tableInfo(table).columnInfos)
      .filter(c => !!c.fkSpec)
      .filter(c => _.has(recordWithEmbeddedChildren, c.id))
      .filter(c => ['belongsTo', 'hasMany', 'hasOne', 'manyToMany'].includes(c.fkSpec!.relationshipType) || orLog(false, `Unknown foreign association: ${c.fkSpec}`, shouldWarnOnUnknownSchema))
      // .filter(c => !belongsToOnly || ['belongsTo', 'hasOne']       .includes(c.fkSpec!.relationshipType))
      .orderBy(c => c.id, 'asc')
      .flatMap(c => {
        const fk = c.fkSpec! ?? orThrow(`expected fkSpec to be defined for ${table}.${c.id} - code above filters to only proceed with such fields`);
        // if (visitedTables[fk.foreignTable])
        //     return [];

        // if (!belongsToOnly)
        //   visitedTables[fk.foreignTable] = true;

        const fieldName = c.id;
        const fieldValue = recordWithEmbeddedChildren[fieldName];
        if (fk.hasMany && !fk.hasOne) { // note that hasOne is still as hasMany, and yet Rozema's code needs to handle hasOne like belongsTo
          // const currentlyVisited = {...visitedTables as shame}; // clone because recuresive call must be permitted to work on each element of the hasMany array (and not just the first element.)
          const manyFlattened = !Array.isArray(fieldValue) ? [] : [
            [`${fieldName}Id`, fieldValue.map(e => e.id)], // mount the ids of the children directly to the root -- needed for Rozema forms
            [   fieldName    , fieldValue.map(child => flattenFragment(child, tableName(fk.foreignTable) ?? orThrowBug(`assumed impossible`)))],
          ];

          return manyFlattened;
        }

        // const reRooted = flattenFragment(fieldValue, tableName(fk.foreignTable) ?? orThrowBug(`assumed impossible`), visitedTables, true);
        // if (c.name.startsWith('sellDep'))
        //   console.log('sellDep', {table, tableInfoOfCustomerOrders: tableInfo('CustomerOrder'), c, fk, fieldName, fieldValue});
        return [
          (!(_.keys(fieldValue).includes(fk.foreignTablePK)) ? [] : [fk.nativeTableFK ?? orThrowBug(`assumed impossible`), fieldValue[fk.foreignTablePK]]),
          [fieldName, flattenFragment(fieldValue, tableName(fk.foreignTable) ?? orThrowBug(`assumed impossible`))],
          // (belongsToOnly ? [] : [fieldName, flattenFragment(fieldValue, tableName(fk.foreignTable) ?? orThrowBug(`assumed impossible`), visitedTables)]),
          // ..._.toPairs(reRooted),
        ]
        .filter(pair => pair.length === 2)
        .filter(pair => !_.isNil(pair[1]) && (typeof pair[1] !== 'object' || !_.isEmpty(pair[1])))
        ;
      })
      .filter(pair => pair.length === 2)
      .filter(pair => !_.isNil(pair[1]) && (typeof pair[1] !== 'object' || !_.isEmpty(pair[1])))
      .reverse() // reversing means that the first entry in flatMap above wins for each key (whereas the default behavior of fromPairs is that the last entry wins.)
      .fromPairs()
      .value(),
  };

/** Ability to overweite the active schemas, for testing purposes */
const activeSchemaWarnOrDo = (fn: () => void) => process.env.NODE_ENV === 'test' ? fn() : orThrowBug(`BUG: Can only override the active schema in test env, but env is '${process.env.NODE_ENV}'.`);
export const overrideActiveSchema = (substitution: RootSchema) => activeSchemaWarnOrDo(() => schemas = substitution);
export const revertActiveSchema = () => activeSchemaWarnOrDo(() => schemas = baseSchemas);
/** TODO works because defensive copy in `table` is shallow.  table is probably bogus, but I need to audit before simplifying */
export const setSearchable = <T extends string, C extends string>(t: Suggest<T, TableName>, c: Suggest<C, ColumnOf<ConformTo<T, TableName>>>, searchable: boolean) =>
  activeSchemaWarnOrDo(() => columnInfo(t, c).searchable = searchable);
