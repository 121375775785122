import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import * as LoadsPage                 from 'client/app/transportation/loads';
import      App                       from 'client/containers/app';
import      BoxesPage                 from 'client/containers/box';
import      BucketsPage               from 'client/containers/bucket';
import      CarrierRatePage           from 'client/containers/carrier-rate';
import      CartTrackingPage          from 'client/app/transportation/cart-tracking';
import      CustomerOrders            from 'client/app/orders/customer-orders';
import      CustomerPage              from 'client/containers/customer';
import      DistributionReceivingPage from 'client/app/transportation/distribution-receiving';
import      FuelSurchargeRatePage     from 'client/containers/fuel-surcharge-rate';
import      InvoicesPage              from 'client/app/billing/invoices';
import * as Lab                       from 'client/app/lab';
import      MarketsPage               from 'client/containers/market';
import      MerchandisersPage         from 'client/containers/merchandiser';
import      MfcAreasPage              from 'client/containers/mfc-area';
import      MileageRatePage           from 'client/containers/mileage-rate';
import      OrderReportPage           from 'client/app/orders/reports';
import      PlanningReports           from 'client/app/planning/reports';
import      PotsPage                  from 'client/containers/pot';
import      ProductClass              from 'client/containers/product-class';
import      ProductPage               from 'client/containers/product';
import      ProductSubClass           from 'client/containers/product-sub-class';
import      ProductTossPage           from 'client/containers/product-toss';
import      ReceivingPage             from 'client/app/transportation/receiving';
import      RegionsPage               from 'client/containers/region';
import      ReportsPage               from 'client/app/reports';
import      RoutingPage               from 'client/app/transportation/routing';
import      SalesPlanPage             from 'client/app/planning/sales-plans';
import      SellDepartment            from 'client/containers/sell-department';
import      StoresPage                from 'client/containers/store';
import      SupplierCommitmentPage    from 'client/app/planning/supplier-commitments';
import      SupplierItemsPage         from 'client/containers/supplier-item';
import      SupplierLocationsPage     from 'client/containers/supplier-location';
import      SupplierOrders            from 'client/app/orders/supplier-orders';
import      SupplierPage              from 'client/containers/supplier';
import      TagsPage                  from 'client/containers/tag';
import      TransportationReportPage  from 'client/app/transportation/reports';
import      TraysPage                 from 'client/containers/tray';
import      VendorPage                from 'client/app/admin/vendors';

export default (
  <>
    <App>
      <Switch>
        <Route path="/admin">
          <Switch>
            <Redirect exact path="/admin" to="/admin/customers/list" />
            <Redirect exact path="/admin/customers" to="/admin/customers/list" />

            <Route path="/admin/customers/list" component={CustomerPage.ListPage} />
            <Route path="/admin/customers/create" component={CustomerPage.CreatePage}/>
            <Route path="/admin/customers/details/:id" component={CustomerPage.DetailPage}/>

            <Redirect exact path="/admin/suppliers" to="/admin/suppliers/list" />
            <Route path="/admin/suppliers/list" component={SupplierPage.ListPage} />
            <Route path="/admin/suppliers/create" component={SupplierPage.CreatePage} />
            <Route path="/admin/suppliers/details/:id" component={SupplierPage.DetailPage} />

            <Redirect exact path="/admin/supplierItems" to="/admin/supplierItems/list" />
            <Route path="/admin/supplierItems/list" component={SupplierItemsPage.ListPage} />
            <Route path="/admin/supplierItems/create" component={SupplierItemsPage.CreatePage} />
            <Redirect exact path="/admin/supplierItems/details/:id" to="/admin/supplierItems/details/:id/info" />
            <Route path="/admin/supplierItems/details/:id/info" component={SupplierItemsPage.DetailPage} />
            <Route path="/admin/supplierItems/details/:id/costs" component={SupplierItemsPage.CostsPage} />

            <Redirect exact path="/admin/supplierLocations" to="/admin/supplierLocations/list" />
            <Route path="/admin/supplierLocations/list" component={SupplierLocationsPage.ListPage} />
            <Route path="/admin/supplierLocations/create" component={SupplierLocationsPage.CreatePage} />
            <Route path="/admin/supplierLocations/details/:id" component={SupplierLocationsPage.DetailPage} />

            <Redirect exact path="/admin/products" to="/admin/products/list" />
            <Route path="/admin/products/list" component={ProductPage.ListPage} />
            <Route path="/admin/products/create" component={ProductPage.CreatePage} />
            <Redirect exact path="/admin/products/details/:id" to="/admin/products/details/:id/info" />
            <Route path="/admin/products/details/:id">
              <ProductPage.DetailPage>
                <Switch>
                  <Route path="/admin/products/details/:id/info" component={ProductPage.ProductInfoPage} />
                  <Route path="/admin/products/details/:id/spec-info" component={ProductPage.SpecInfoPage} />
                  <Route path="/admin/products/details/:id/price-info" component={ProductPage.PriceInfoPage} />
                  <Route path="/admin/products/details/:id/attachments" component={ProductPage.AttachmentsPage} />
                </Switch>
              </ProductPage.DetailPage>
            </Route>

            <Redirect exact path="/admin/productClasses" to="/admin/productClasses/list" />
            <Route path="/admin/productClasses/list" component={ProductClass.ListPage} />
            <Route path="/admin/productClasses/create" component={ProductClass.CreatePage} />
            <Route path="/admin/productClasses/details/:id" component={ProductClass.DetailPage} />

            <Redirect exact path="/admin/productSubClasses" to="/admin/productSubClasses/list" />
            <Route path="/admin/productSubClasses/list" component={ProductSubClass.ListPage} />
            <Route path="/admin/productSubClasses/create" component={ProductSubClass.CreatePage} />
            <Route path="/admin/productSubClasses/details/:id" component={ProductSubClass.DetailPage} />

            <Redirect exact path="/admin/tags" to="/admin/tags/list" />
            <Route path="/admin/tags/list" component={TagsPage.ListPage} />
            <Route path="/admin/tags/create" component={TagsPage.CreatePage} />
            <Route path="/admin/tags/details/:id" component={TagsPage.DetailPage} />

            <Redirect exact path="/admin/trays" to="/admin/trays/list" />
            <Route path="/admin/trays/list" component={TraysPage.ListPage} />
            <Route path="/admin/trays/create" component={TraysPage.CreatePage} />
            <Route path="/admin/trays/details/:id" component={TraysPage.DetailPage} />

            <Redirect exact path="/admin/pots" to="/admin/pots/list" />
            <Route path="/admin/pots/list" component={PotsPage.ListPage} />
            <Route path="/admin/pots/create" component={PotsPage.CreatePage} />
            <Route path="/admin/pots/details/:id" component={PotsPage.DetailPage} />

            <Redirect exact path="/admin/boxes" to="/admin/boxes/list" />
            <Route path="/admin/boxes/list" component={BoxesPage.ListPage} />
            <Route path="/admin/boxes/create" component={BoxesPage.CreatePage} />
            <Route path="/admin/boxes/details/:id" component={BoxesPage.DetailPage} />

            <Redirect exact path="/admin/buckets" to="/admin/buckets/list" />
            <Route path="/admin/buckets/list" component={BucketsPage.ListPage} />
            <Route path="/admin/buckets/create" component={BucketsPage.CreatePage} />
            <Route path="/admin/buckets/details/:id" component={BucketsPage.DetailPage} />

            <Redirect exact path="/admin/sellDepartments" to="/admin/sellDepartments/list" />
            <Route path="/admin/sellDepartments/list" component={SellDepartment.ListPage} />
            <Route path="/admin/sellDepartments/create" component={SellDepartment.CreatePage} />
            <Route path="/admin/sellDepartments/details/:id" component={SellDepartment.DetailPage} />

            <Redirect exact path="/admin/mfcAreas" to="/admin/mfcAreas/list" />
            <Route path="/admin/mfcAreas/list" component={MfcAreasPage.ListPage} />
            <Route path="/admin/mfcAreas/create" component={MfcAreasPage.CreatePage} />
            <Route path="/admin/mfcAreas/details/:id" component={MfcAreasPage.DetailPage} />

            <Redirect exact path="/admin/regions" to="/admin/regions/list" />
            <Route path="/admin/regions/list" component={RegionsPage.ListPage} />
            <Route path="/admin/regions/create" component={RegionsPage.CreatePage} />
            <Route path="/admin/regions/details/:id" component={RegionsPage.DetailPage} />

            <Redirect exact path="/admin/markets" to="/admin/markets/list" />
            <Route path="/admin/markets/list" component={MarketsPage.ListPage} />
            <Route path="/admin/markets/create" component={MarketsPage.CreatePage} />
            <Route path="/admin/markets/details/:id" component={MarketsPage.DetailPage} />

            <Redirect exact path="/admin/merchandisers" to="/admin/merchandisers/list" />
            <Route path="/admin/merchandisers/list" component={MerchandisersPage.ListPage} />
            <Route path="/admin/merchandisers/create" component={MerchandisersPage.CreatePage} />
            <Route path="/admin/merchandisers/details/:id" component={MerchandisersPage.DetailPage} />

            <Redirect exact path="/admin/stores" to="/admin/stores/list" />
            <Route path="/admin/stores/list" component={StoresPage.ListPage} />
            <Route path="/admin/stores/create" component={StoresPage.CreatePage} />
            <Redirect exact path="/admin/stores/details/:id" to="/admin/stores/details/:id/info" />
            <Route path="/admin/stores/details/:id">
              <Route path="/admin/stores/details/:id/info" component={StoresPage.InfoPage} />
              <Route path="/admin/stores/details/:id/contact" component={StoresPage.ContactPage} />
              <Route path="/admin/stores/details/:id/transportation" component={StoresPage.TransportationPage} />
              <Route path="/admin/stores/details/:id/attachments" component={StoresPage.AttachmentsPage} />
            </Route>

            <Redirect exact path="/admin/vendors" to="/admin/vendors/list" />
            <Route path="/admin/vendors/list" component={VendorPage.ListPage} />
            <Route path="/admin/vendors/create" component={VendorPage.CreatePage} />
            <Route path="/admin/vendors/details/:id" component={VendorPage.DetailPage} />

            <Redirect exact path="/admin/productToss" to="/admin/productToss/list" />
            <Route path="/admin/productToss/list" component={ProductTossPage.ListPage} />
            <Route path="/admin/productToss/create" component={ProductTossPage.CreatePage} />
            <Route path="/admin/productToss/details/:id" component={ProductTossPage.DetailPage} />

            <Redirect exact path="/admin/mileage-rates" to="/admin/mileage-rates/list" />
            <Route path="/admin/mileage-rates/list" component={MileageRatePage.ListPage} />
            <Route path="/admin/mileage-rates/create" component={MileageRatePage.CreatePage} />
            <Route path="/admin/mileage-rates/details/:id" component={MileageRatePage.DetailPage} />

            <Redirect exact path="/admin/fuel-surcharge-rates" to="/admin/fuel-surcharge-rates/list" />
            <Route path="/admin/fuel-surcharge-rates/list" component={FuelSurchargeRatePage.ListPage} />
            <Route path="/admin/fuel-surcharge-rates/create" component={FuelSurchargeRatePage.CreatePage} />
            <Route path="/admin/fuel-surcharge-rates/details/:id" component={FuelSurchargeRatePage.DetailPage} />

            <Redirect exact path="/admin/carrier-rates" to="/admin/carrier-rates/list" />
            <Route path="/admin/carrier-rates/list" component={CarrierRatePage.ListPage} />
            <Route path="/admin/carrier-rates/create" component={CarrierRatePage.CreatePage} />
            <Route path="/admin/carrier-rates/details/:id" component={CarrierRatePage.DetailPage} />
          </Switch>
        </Route>
        <Route path="/planning">
          <Switch>
            <Redirect exact path="/planning/sales" to="/planning/sales/list" />
            <Route path="/planning/sales/list" component={SalesPlanPage.ListPage} />
            <Route path="/planning/sales/create" component={SalesPlanPage.CreatePage} />
            <Route path="/planning/sales/details/:id" component={SalesPlanPage.DetailPage} />

            <Redirect exact path="/planning/supplier-commitments" to="/planning/supplier-commitments/list" />
            <Route path="/planning/supplier-commitments/list" component={SupplierCommitmentPage.ListPage} />
            <Route path="/planning/supplier-commitments/create" component={SupplierCommitmentPage.CreatePage} />
            <Route path="/planning/supplier-commitments/details/:id" component={SupplierCommitmentPage.DetailPage} />

            <Route path="/planning/reports/supplier-future-commitment" component={PlanningReports.SupplierFutureCommitmentReport} />
            <Route path="/planning/reports/hardgoods-data-export" component={PlanningReports.HardgoodsDataExportPage} />
            <Route path="/planning/reports/sales-plan-varieties-export" component={PlanningReports.SalesPlanVarietiesExportPage} />
            <Route path="/planning/reports/difference-calculation-report" component={PlanningReports.DifferenceCalculationReportPage} />

            <Redirect exact path="/planning" to="/planning/sales"/>
          </Switch>
        </Route>

        <Route path="/orders">
          <Switch>
            <Redirect exact path="/orders" to="/orders/customer/list" />

            <Route path="/orders/customer">
              <Switch>
                <Redirect exact path="/orders/customer" to="/orders/customer/list" />
                <Route path="/orders/customer/list" component={ CustomerOrders.List }/>
                <Route path="/orders/customer/create" component={CustomerOrders.NewRecord} />

                <Redirect exact path="/orders/customer/details/:id" to="/orders/customer/details/:id/overview" />
                <Route path="/orders/customer/details/:id">
                  <CustomerOrders.Content>
                    <Switch>
                      <Route path="/orders/customer/details/:id/overview" component={CustomerOrders.Overview} />
                      <Route exact path="/orders/customer/details/:id/product-worksheet" component={CustomerOrders.ProductWorksheet} />
                      <Route path="/orders/customer/details/:id/product-worksheet/:productId" component={CustomerOrders.ProductWorksheet} />
                      <Route path="/orders/customer/details/:id/related-supplier-orders" component={CustomerOrders.RelatedSupplierOrders} />
                      <Route path="/orders/customer/details/:id/customer-order-reconciliation" component={CustomerOrders.Reconciliation} />
                    </Switch>
                  </CustomerOrders.Content>
                </Route>
              </Switch>
            </Route>

            <Route path="/orders/supplier">
              <Switch>
                <Redirect exact path="/orders/supplier" to="/orders/supplier/list" />
                <Route path="/orders/supplier/list" component={SupplierOrders.List}/>
                <Route path="/orders/supplier/create" component={SupplierOrders.NewRecord} />

                <Redirect exact path="/orders/supplier/details/:id" to="/orders/supplier/details/:id/overview" />
                <Route path="/orders/supplier/details/:id">
                  <SupplierOrders.Content>
                    <Switch>
                      <Route path="/orders/supplier/details/:id/overview" component={SupplierOrders.Overview} />
                      <Route path="/orders/supplier/details/:id/related-customer-orders" component={SupplierOrders.RelatedCustomerOrders} />
                    </Switch>
                  </SupplierOrders.Content>
                </Route>
              </Switch>
            </Route>

            <Route path="/orders/reports">
              <Switch>
                <Route path="/orders/reports/replenishment-by-store" component={OrderReportPage.ReplenishmentByStore}/>
                <Route path="/orders/reports/replenishment-by-product" component={OrderReportPage.ReplenishmentByProduct}/>
                <Route path="/orders/reports/store-on-hand" component={OrderReportPage.StoreOnHand}/>
                <Route path="/orders/reports/cut-processing" component={OrderReportPage.CutProcessing}/>
              </Switch>
            </Route>

          </Switch>
        </Route>

        <Route path="/transportation">
          <Switch>
            <Redirect exact path="/transportation" to="/transportation/receiving/list" />

            <Redirect exact path="/transportation/receiving" to="/transportation/receiving/list" />
            <Route path="/transportation/receiving/list" component={ReceivingPage.ReceivingOrdersTable} />
            <Redirect exact path="/transportation/receiving/details/:id" to="/transportation/receiving/details/:id/overview" />
            <Route path="/transportation/receiving/details/:id">
              <ReceivingPage.ReceivingDetails>
                <Switch>
                  <Route path="/transportation/receiving/details/:id/overview" component={ReceivingPage.ReceivingOverview} />
                  <Route exact path="/transportation/receiving/details/:id/worksheet" component={ReceivingPage.ReceivingWorksheet} />
                  <Route path="/transportation/receiving/details/:id/worksheet/:bolId" component={ReceivingPage.ReceivingWorksheet} />
                  <Route path="/transportation/receiving/details/:id/related-bols" component={ReceivingPage.RelatedBols} />
                </Switch>
              </ReceivingPage.ReceivingDetails>
            </Route>

            <Redirect exact path="/transportation/distribution-receiving" to="/transportation/distribution-receiving/list" />
            <Route path="/transportation/distribution-receiving/list" component={DistributionReceivingPage.DistributionReceivingOrdersTable} />

            <Redirect exact path="/transportation/routing" to="/transportation/routing/list" />
            <Route path="/transportation/routing/list" component={RoutingPage.RoutePlansTable} />
            <Route path="/transportation/routing/create" component={RoutingPage.RoutePlanAttachOrders} />
            <Redirect exact path="/transportation/routing/details/:id" to="/transportation/routing/details/:id/attach-orders" />
            <Route path="/transportation/routing/details/:id">
              <RoutingPage.RoutePlanContent>
                <Switch>
                  <Route path="/transportation/routing/details/:id/attach-orders" component={RoutingPage.RoutePlanAttachOrders} />
                  <Route path="/transportation/routing/details/:id/review-stores" component={RoutingPage.RoutePlanReviewStores} />
                  <Route path="/transportation/routing/details/:id/loads" component={RoutingPage.RoutePlanLoads} />
                  <Route path="/transportation/routing/details/:id/printing" component={RoutingPage.RoutePlanPrinting} />
                </Switch>
              </RoutingPage.RoutePlanContent>
            </Route>

            <Redirect exact path="/transportation/loads" to="/transportation/loads/list" />
            <Route path="/transportation/loads/list" component={LoadsPage.LoadList} />
            <Route path="/transportation/loads/details/:id" component={LoadsPage.LoadDetails} />

            <Redirect exact path="/transportation/cartTracking" to="/transportation/cartTracking/list" />
            <Route path="/transportation/cartTracking/list" component={CartTrackingPage.SuppliersTable} />
            <Redirect exact path="/transportation/cartTracking/details/:id" to="/transportation/cartTracking/details/:id/overview" />
            <Route path="/transportation/cartTracking/details/:id">
              <Route path="/transportation/cartTracking/details/:id/overview" component={CartTrackingPage.CartTrackingSupplierOverview} />
            </Route>

            <Route path="/transportation/reports/hmg-ops" component={TransportationReportPage.HmgOps} />
            <Route path="/transportation/reports/load-tracking-list" component={TransportationReportPage.LoadTrackingList} />
            <Route path="/transportation/reports/shipping-status-warehouse-report" component={TransportationReportPage.ShippingStatusWarehouseReport} />
            <Route path="/transportation/reports/cart-estimate-report" component={TransportationReportPage.CartEstimateReport} />
          </Switch>
        </Route>

        <Route path="/billing">
          <Switch>
            <Redirect exact path="/billing" to="/billing/invoicing/list" />

            <Redirect exact path="/billing/invoicing" to="/billing/invoicing/list" />
            <Route path="/billing/invoicing/list" component={InvoicesPage.ListPage} />
            <Route path="/billing/invoicing/create" component={InvoicesPage.CreatePage} />
            <Route path="/billing/invoicing/details/:id" component={InvoicesPage.DetailPage} />

            <Redirect exact path="/billing/reports" to="billing/reports/customer-performance" />
            <Route path="/billing/reports/customer-performance" component={ReportsPage.PerformanceReport}/>
            <Route path="/billing/reports/supplier-performance" component={PlanningReports.SupplierPerformanceReportPage} />
            <Route path="/billing/reports/buyer" component={ReportsPage.BuyerReport}/>
            <Route path="/billing/reports/daily-sales" component={ReportsPage.DailySalesReport}/>
            <Route path="/billing/reports/mobile-sales-performance" component={ReportsPage.MobileSalesPerformanceReport}/>
          </Switch>
        </Route>

        <Route path="/reports">
          <Switch>
            <Redirect exact path="/reports" to="/reports/customer-performance" />
            <Route path="/reports/customer-performance" component={ReportsPage.PerformanceReport}/>
            <Route path="/reports/supplier-performance" component={PlanningReports.SupplierPerformanceReportPage} />
            <Route path="/reports/buyer" component={ReportsPage.BuyerReport}/>
            <Route path="/reports/daily-sales" component={ReportsPage.DailySalesReport}/>
            <Route path="/reports/mobile-sales-performance" component={ReportsPage.MobileSalesPerformanceReport}/>
          </Switch>
        </Route>

        <Route path="/lab">
          <Switch>
            <Redirect exact path="/lab" to="/lab/quick-data-table" />
            <Route path="/lab/quick-data-table" component={Lab.QuickDataTable}/>
          </Switch>
        </Route>

        <Redirect path="*" to="/admin/customers/list" />
      </Switch>
    </App>
  </>
);
