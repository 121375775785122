import * as React from 'react';
import { FormGroup } from 'client/components/third-party';
import { FieldWrapper } from '../../../form';

const Field = p => <FieldWrapper table="productUpcs" horizontalLabel={false} alwaysShowErrors={true} hideOptionalLabel={true} { ...p } />;

export const UpcCodeField = (code: string, index: number, colOffset: number, onPrimaryRadioChanged: () => void) => {
  return (
    <div key={index} data-testid="product-upc">
      <FormGroup>
        <Field name={`${code}.identifier`} label={' '} labelColSize={1} inputColSize={1} offset={colOffset} testid="identifier" />
        <Field name={`${code}.notes`} label={' '} labelColSize={1} inputColSize={2} testid="notes" />
        <Field name={`${code}.primary`} label={' '} handleChange={() => onPrimaryRadioChanged()} optionValue={true} labelColSize={0} inputColSize={1} testid="primary" />
      </FormGroup>
    </div>
  );
};
