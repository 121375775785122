import { ControlLabel } from 'client/components/third-party';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';
import * as React from 'react';
import { FilterDate } from '../toolbar/filter-date';
import { DateStr, ImmutableDateRange } from 'shared/types';

interface Props {
  required?: boolean;
  disabled?: boolean;
  horizontalLabel?: boolean;
  inputColSize: number;
  label: string;
  labelColSize: number;
  offset: number;
  testid: string;

  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;

  onChange: (dateRange: ImmutableDateRange) => void;

  className?: string;
}

export default function LabeledDateRange(props: Props) {
  const dateRange = (
    <div>
      <FilterDate
        label={props.label}
        testid={props.testid}
        onSelected={props.onChange}
        dateRange={{
          startDate: props.beginDate,
          endDate: props.endDate,
        } as shame}
        verticalStackLabel
        disabled={props.disabled}
        className={props.className}
      />
    </div>
  );

  const labelClassNames = props.disabled ? ['disabled'] : [];

  return props.horizontalLabel
    ? (
      <HorizontalLayout
        inputColSize={props.inputColSize}
        label={''}
        labelColSize={props.labelColSize}
        labelComponent={ControlLabel}
        offset={props.offset}
        classNames={labelClassNames}
      >
        {dateRange}
      </HorizontalLayout>
    ) : (
      <StackedLayout
        inputColSize={props.inputColSize}
        label={''}
        offset={props.offset}
        classNames={labelClassNames}
      >
        {dateRange}
      </StackedLayout>
    );
}
