import { send } from 'shared/send';
import * as FileSaver from 'client/utils/filesaver';
import { RoutePlanId, RoutePlanIdentifier } from 'schema/route-plan/route-plan-typescript-types';

export const downloadRoutePacket = async (args: {
  routePlanId?: RoutePlanId,
  routePlanIdentifier: RoutePlanIdentifier,
  fileNameSuffix: string,
  routeSheetsChecked: boolean,
  deliveryAcknowledgementsChecked: boolean,
  purchaseOrderInvoicesChecked: boolean,
  returnInstructionsChecked: boolean,
  specialInstructionsChecked: boolean,
  selectedRoutingLoadIds: number[],
  specialInstructionsPdfFile?: File | null,
}) => {
  const options = [
    `routeSheets=${args.routeSheetsChecked}`,
    `deliveryAcknowledgements=${args.deliveryAcknowledgementsChecked}`,
    `purchaseOrderInvoices=${args.purchaseOrderInvoicesChecked}`,
    `returnInstructions=${args.returnInstructionsChecked}`,
    `specialInstructions=${args.specialInstructionsChecked}`,
    `selectedRoutingLoadIds=${args.selectedRoutingLoadIds.join()}`,
  ].join('&');

  const formData = new FormData();

  if (args.specialInstructionsChecked && args.specialInstructionsPdfFile) {
    formData.append('special-instructions-pdf-file', args.specialInstructionsPdfFile);
  }

  const pdfBlob = await send({
    fetch,
    url: `/report/routePacket/${args.routePlanId || ''}?${options}`,
    method: 'POST',
    asBlob: true,
    formData,
    overwriteHeaders: true,
    headers: {},
  });

  const filename = `${args.routePlanIdentifier} - ${args.fileNameSuffix}`;

  FileSaver.saveAs(pdfBlob, `${filename}.pdf`);
};
