import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Col } from 'client/components/third-party';
import { LoadDetailsRecordBar } from './load-details-record-bar';
import { LoadDetailsSidebar } from './load-details-sidebar';
import { LoadDetailsStopsTable } from './load-details-stops-table';
import { LoadDetailsStatsRow } from 'client/app/transportation/loads/stats-row/stats-row-container';

export const LoadDetails = (props: RouteChildrenProps<{ id: string }>) => {
  const parsedId = Number.parseInt(props.match ? props.match.params.id : '');
  const routingLoadId = isNaN(parsedId) ? undefined : parsedId;
  return (!routingLoadId) ? (<div>Invalid routing load!</div>) : (
    <div className="record">
      <LoadDetailsRecordBar routingLoadId={routingLoadId} />
      <div className="mfc-form mfc-form-not-scrollable">
        <div>
          <div className="mfc-form-details-with-sidebar-but-no-tabs">
            <Col sm={12}>
              <LoadDetailsStatsRow routingLoadId={routingLoadId} />
              <LoadDetailsStopsTable routingLoadId={routingLoadId} />
            </Col>
          </div>
          <div className="mfc-form-sidebar">
            <LoadDetailsSidebar routingLoadId={routingLoadId} />
          </div>
        </div>
      </div>
    </div>
  );
};
