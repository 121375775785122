import { MileageRatesForm } from 'client/components/crud-forms/mileage-rate-form';
import buildAdminCreatePage from '../admin/admin-create-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminCreatePage({
  formName: 'MileageRateCreateForm',
  table: 'mileageRates',
  FormComponent: MileageRatesForm,
  OptionalStatusToggle: EmptyStatusToggle,
});
