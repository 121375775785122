export class CodedError extends Error {
  readonly debugInfo: any | undefined;
  readonly isExpectedError: boolean;
  readonly httpErrorCode: number | undefined;

  constructor(message: string, httpErrorCode?: number) {
    super(message);
    this.httpErrorCode = httpErrorCode;
    this.isExpectedError = true;
  }
}
