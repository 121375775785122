import * as _ from 'lodash';
import * as QueryString from 'query-string';
import * as Actions from './actions';
import * as MutationActions from 'client/actions/mutations';
import * as ReactRedux from 'react-redux';
import * as ReportUserParamsState from './state';
import * as SaveConfirmation from 'client/hoc/with-save-confirmation';
import * as SharedTypes from 'shared/types';
import * as State from 'client/state/state';
import * as Types from './types';
import * as UI from './ui';
import * as Wrapper from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as ReportUserParamsTypes from 'shared/types/report-user-params';
import * as ReportUserParamsQueries from 'client/components/report-user-params/queries';
import { msyncQuery } from 'client/hoc/graphql/query';
import { determineDefaultPresetId } from 'shared/app/reports/determine-default-preset';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { withRouter } from 'react-router';

interface OwnProps {
  mapFromReportUserParams: Types.MapFromReportUserParams;
  mapToReportUserParams: Types.MapToReportUserParams;
  pristine: boolean;
  reportType: SharedTypes.ReportTypes;
  reportFormName: string;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
  anyoneCanEdit?: boolean;
  noDefault?: boolean;
}

interface StateProps {
  actionStatus: MutationActions.MutationStatus;
  presetId: number | undefined;
  saveAsPresetModalShown: boolean;
  userId: number | undefined;
  componentsDisabled: boolean;
  isAdminFeaturesEnabled: boolean;
}

interface DispatchProps {
  onActionTaken(reportType: SharedTypes.ReportTypes, presetId: number | undefined, action: Types.PresetActionTypes, mapToReportUserParams: Types.MapToReportUserParams, mapFromReportUserParams: Types.MapFromReportUserParams, reportFormName: string): void;
  onComponentUnloaded: (reportType: SharedTypes.ReportTypes) => void;
  onModalSaveButtonClicked: (reportType: SharedTypes.ReportTypes, mapToReportUserParams: Types.MapToReportUserParams, name: string) => void;
  onPresetClicked(presetId: number, reportFormName: string): void;
  onSaveAsPresetModalHidden(): void;
}

interface WithRouterProps {
  // From the withRouter
  location: {
    search?: string;
  };
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  onActionTaken: Actions.onActionTaken,
  onComponentUnloaded: Actions.onComponentUnloaded,
  onModalSaveButtonClicked: Actions.onModalSaveButtonClicked,
  onPresetClicked: Actions.onPresetClicked,
  onSaveAsPresetModalHidden: Actions.onSaveAsPresetModalHidden,
};

const mapStateToProps = (state: State.Type, ownProps: OwnProps & WithRouterProps): StateProps => {
  const session = state.session;

  const actionStatus = ReportUserParamsState.actionStatusLens.get(state.reportUserParams);

  const presetId = ownProps.selectedPresetId;
  const saveAsPresetModalShown = ReportUserParamsState.saveAsPresetModalShownLens.get(state.reportUserParams);
  const userId = session.user ? session.user.id : undefined;

  const componentsDisabled = actionStatus === MutationActions.MutationStatus.InProgress || ownProps.isLoadingPreset;

  const admin = QueryString.parse(ownProps.location.search || '').admin;
  const adminParam = admin ? (Array.isArray(admin) ? admin[0] : admin) : undefined;

  return {
    actionStatus,
    presetId,
    saveAsPresetModalShown,
    userId,
    componentsDisabled,
    isAdminFeaturesEnabled: adminParam === 'true',
  };
};

interface WithReportPresetsProps {
  defaultPresetId?: number | undefined;
  allPresetOptions: Array<{
    id: number;
    value: string;
    userId: number | null;
    userDefault: boolean;
    reportDefault: boolean;
  }>;
  currentPreset: UI.PresetOption | undefined;
  canSetAsDefault: boolean;
  showSetAsDefault: boolean;
  canDelete: boolean;
  canSave: boolean;
}
const withReportPresets = msyncQuery<{ response: ReportUserParamsTypes.GetAllPresetsForReportTypeResponse }, shame, WithReportPresetsProps, ReportUserParamsTypes.GetAllPresetsForReportTypeInput>(ReportUserParamsQueries.AllPresetsQuery, {
  alias: 'withReportPresets',
  skip(ownProps) {
    return !ownProps.reportType;
  },
  options(ownProps) {
    return {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          reportType: ownProps.reportType,
        },
      },
    };
  },
  props(props) {
    if (props.data.loading || !props.data.response) {
      return {
        allPresetOptions: [],
        currentPreset: undefined,
        canSetAsDefault: false,
        showSetAsDefault: false,
        canDelete: false,
        canSave: false,
        defaultPresetId: undefined,
      };
    }

    const defaultPresetId = determineDefaultPresetId(props.data.response.presets, props.ownProps.userId);

    const options = props.data.response.presets.map(preset => {
      const isDefault = notNil(defaultPresetId) && preset.id === defaultPresetId;

      return {
        id: preset.id,
        value: `${preset.prefix} - ${preset.name}${isDefault ? '*' : ''}`,
        userId: preset.userId,
        userDefault: preset.userDefault,
        reportDefault: preset.reportDefault,
      };
    });

    const currentPreset = options.find(option => option.id === props.ownProps.presetId);
    const canSetAsDefault = notNil(currentPreset) && currentPreset.userId === props.ownProps.userId;
    const showSetAsDefault = canSetAsDefault && !props.ownProps.noDefault;
    const canDelete = currentPreset?.userId === props.ownProps.userId;
    const canSave = currentPreset?.userId === props.ownProps.userId || !currentPreset || !!props.ownProps.anyoneCanEdit || (props.ownProps.isAdminFeaturesEnabled && props.ownProps.userId === 42); // The answer to life, the universe, and everything... and the ID of the msync.admin user
    return {
      allPresetOptions: options,
      currentPreset,
      canSetAsDefault,
      showSetAsDefault,
      canDelete,
      canSave,
      defaultPresetId,
    };
  },
});

export interface SaveConfirmationProps {
  confirmOkToSave: () => Promise<boolean>;
}

const saveConfirmationOptionsForDeletePreset = {
  onlyAskOnce: false,
  message: {
    title: 'Delete Preset',
    body: 'Delete this preset? This action can\'t be undone.',
  },
  saveConfirmMessage: 'Delete',
  shouldDisplay: () => true,
};

type Component<P> = new (props: P) => React.Component<P, any>;

type ContainerProps =
  OwnProps &
  StateProps &
  DispatchProps &
  SaveConfirmationProps &
  WithReportPresetsProps;

assertCompatible<UI.ComponentProps, ContainerProps>();

const component = _.flowRight(
  withRouter,
  ReactRedux.connect(mapStateToProps, mapDispatchToProps),
  withReportPresets,
  SaveConfirmation.withSaveConfirmation(saveConfirmationOptionsForDeletePreset),
)(UI.UserParamsUI) as Component<OwnProps>;

export const ReportUserParams = (props: OwnProps) => Wrapper.propToComponent(component, props);
