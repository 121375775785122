import * as React from 'react';
import { Col } from 'client/components/third-party';

export function Card(props: { title: string, content: any }) {
  return (
    <Col sm={6} lg={3}>
      <div className="card-pf card-pf-accented">
        <h2 className="card-pf-title">
          {props.title}
        </h2>
        <div className="card-pf-body">
          {props.content || '--'}
        </div>
      </div>
    </Col>
  );
}
