import { SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';

export const saveConfirmationOptionsForSupplierCommitments: SaveConfirmationArgs<any> = {
  onlyAskOnce: false,
  shouldDisplay: (record?: never) => true,
  message: {
    title: 'Delete Supplier Commitments',
    body: `Are you sure you would like to delete these supplier commitments?`,
  },
  saveConfirmMessage: 'Delete Supplier Commitment(s)',
};

export type ConfirmOkToSaveFunction = () => Promise<boolean>;
