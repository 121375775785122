import * as _ from 'lodash';

/**
 * Extracts the name of the query from an object returned from the
 * "gql" function so it can be referenced elsewhere (like in a
 * refetchQueries call) without requiring interpolation in the actual
 * query string (which messes up vscode plugins)
 */
export const extractQueryName = (queryObj: any): string => {
  const queryName = extractQueryNameAllowingEmpty(queryObj);
  if (!queryName)
    throw new Error(`Unable to extract query name from: ${JSON.stringify(queryObj, null, 2)}`);

  return queryName;
};

export const extractQueryNameAllowingEmpty = (queryObj: any): string => queryObj?.definitions?.[0]?.name?.value || '';
