import * as React from 'react';
import { FormGroup, Col, Button } from 'client/components/third-party';
import { UpcCodeField } from './upc-code';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import * as Actions from './actions';
import { withSaveConfirmation } from 'client/hoc/with-save-confirmation';
import { saveConfirmationOptionsForUpcCodes, ConfirmOkToSaveFunction } from './save-confirmation';

interface OwnProps {
  // Fields is an object (field array) that you can push/pop from and map over... it's really just an array of strings
  // but with some add-on methods. Just making it an any here to satisfy the types.
  fields: any;
  limit: number;
  colOffset: number;
  formName: string;
}

type DispatchProps = {
  onGetNewUpcClicked(formName: string, upcFields: any[], confirmOkToSave: ConfirmOkToSaveFunction): void;
  onPrimaryRadioChanged(formName: string, index: number, upcCount: number): void;
};

const mapDispatchToProps: DispatchProps = {
  onGetNewUpcClicked: Actions.handleGetNewUpcClicked,
  onPrimaryRadioChanged: Actions.handlePrimaryRadioChanged,
};

interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

type ComponentProps = OwnProps & DispatchProps & WithSaveConfirmationProps;

class UpcCodeFormGroupClass extends React.Component<ComponentProps, never> {
  constructor(props) {
    super(props);
  }

  onAddNewUpcClicked = () => {
    const hasUpc = this.props.fields.getAll() ? this.props.fields.length > 0 : false;
    this.props.fields.push({});
    if (!hasUpc) {
      this.props.onPrimaryRadioChanged(this.props.formName, 0, 1);
    }
  }

  onGetNewUpcClicked = () => {
    this.props.onGetNewUpcClicked(this.props.formName, this.props.fields.getAll(), this.props.confirmOkToSave);
  }

  onPrimaryRadioChanged = (index: number) => {
    this.props.onPrimaryRadioChanged(this.props.formName, index, this.props.fields.getAll().length);
  }

  render() {
    const { fields, limit, colOffset } = this.props;

    const buttonText = fields.length > 0 ? 'Add Another UPC Code' : 'Add a UPC Code';
    const offset = !_.isNil(colOffset) ? colOffset : 1;

    const upcFields: string[] | undefined = fields.getAll();

    // The id field is only populated once a new UPC field is saved and the id is retrieved from
    // the new record in the database.
    const isMostRecentUpcUnsaved = () => {
      const upcs = fields.getAll();
      const mostRecentUpc = upcs[upcs.length - 1];
      return mostRecentUpc && _.isNil(mostRecentUpc.id);
    };

    return (
      <div data-testid="product-upcs">
        {((upcFields?.length ?? 0) > 0) &&
          < FormGroup >
            <Col sm={1} smOffset={offset} className="mfc-form-horizontal-label">UPC</Col>
            <Col sm={2} className="mfc-form-horizontal-label">Notes</Col>
            <Col sm={1} className="mfc-form-horizontal-label">Primary</Col>
          </FormGroup>}

        {fields.map((code: string, index) => UpcCodeField(code, index, offset, () => this.onPrimaryRadioChanged(index)))}

        {((upcFields?.length ?? 0) > 0) && isMostRecentUpcUnsaved() &&
          <FormGroup>
            <Col sm={5} smOffset={offset + 1}>
              <Button className="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary" data-testid="get-new-upc-button" onClick={this.onGetNewUpcClicked}>
                Get New UPC
              </Button>
            </Col>
          </FormGroup>
        }

        {(fields.length < limit || limit === undefined) &&
          <FormGroup>
            <Col sm={5} smOffset={offset}>
              <div className="text-center">
                <div className="mfc-form-button add-upc-code-button" data-testid="qa-new-upc-code-button" onClick={this.onAddNewUpcClicked}>
                  <span className="fa fa-plus-circle" /> {buttonText}
                </div>
              </div>
            </Col>
          </FormGroup>
        }
      </div>
    );
  }
}

export const UpcCodeFormGroup = flowRight(
  withSaveConfirmation(saveConfirmationOptionsForUpcCodes),
  connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(UpcCodeFormGroupClass);
