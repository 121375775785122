import * as _ from 'lodash';
import { DateStr } from './date-str';

export const sameDates = (a?: DateRange | ImmutableDateRange | null, b?: DateRange | ImmutableDateRange | null): boolean =>
  (_.isNil(a) && _.isNil(b)) ? true
  : (_.isNil(a) || _.isNil(b))  ? false
  : a.startDate === b.startDate && a.endDate === b.endDate;

export interface DateRange { startDate: DateStr, endDate: DateStr }

export class ImmutableDateRange implements DateRange {
  readonly startDate: DateStr;
  readonly endDate: DateStr;
  constructor(params: DateRange) {
    Object.defineProperties(this, {
      startDate: {value: params.startDate, enumerable: true, configurable: false, writable: false},
      endDate: {value: params.endDate, enumerable: true, configurable: false, writable: false},
    });
  }
  with(values: DateRange) {
    if (this.startDate !== values.startDate || this.endDate !== values.endDate) {
      return new ImmutableDateRange(Object.assign({startDate: this.startDate, endDate: this.endDate}, values));
    }
    return this;
  }
}
