import * as React from 'react';
import * as _ from 'lodash';
import { Modal } from 'client/components/third-party';
import { connect, ConnectedProps } from 'react-redux';
import { ErrorState } from 'client/reducers/error';
import { wrapComponent } from 'client/hoc/hoc';
import * as Actions from 'client/actions/error';
import { createSelector } from 'reselect';
import { ServerSideValidationInfo } from 'shared/validators';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {

}

function getValidations(errors?: ErrorState): ServerSideValidationInfo[] {
  if (errors?.validationError) {
    return errors.validationError.validations;
  } else {
    return [];
  }
}

const convertValidationsToMessages = createSelector(getValidations, (validations): string[] | undefined => {
  if (validations.length > 0) {
    return validations.map(v => v.fullMessage); // The reason for the reselect usage - this would return a new array every time
  } else {
    return undefined;
  }
});

const mapStateToProps = state => {
  const errors: ErrorState = state.errors;

  const showValidationError = !!errors.validationError;
  const showExpectedError = !!errors.expectedError && !showValidationError;
  const showUnexpectedError = !!errors.unexpectedError && !showValidationError && !showExpectedError;
  return {
    showAlert: showExpectedError || showValidationError || showUnexpectedError,
    showValidationError,
    showExpectedError,
    showUnexpectedError,
    validationMessages: convertValidationsToMessages(errors),
    expectedErrorMessage: errors.expectedError?.message,
    unexpectedErrorMessage: errors.unexpectedError?.message,
    unexpectedErrorStack: _.isArray(errors.unexpectedError?.stack) ? errors.unexpectedError?.stack.join() : `${errors.unexpectedError?.stack}`,
    debugInfo: errors.expectedError && errors.expectedError.debugInfo,
  };
};

const mapDispatchToProps =  {
  handleCloseClicked: Actions.errorAlertCloseClicked,
};

const patternForBeginningOfJson = /\{\s*"[^"]+"\s*:/;
const findEndOfJson = (message: string, startIndex: number): number => {
  let openBraces = 0;
  let closeBraces = 0;
  let index = startIndex;
  while (index < message.length) {
    if (message[index] === '{')
      openBraces++;
    else if (message[index] === '}')
      closeBraces++;

    if (openBraces > 0 && openBraces === closeBraces)
      return index;

    index++;
  }
  return -1;
};

const detectAndFormatJson = (message: string | Nil): string | Nil => {
  if (_.isNil(message) || !message)
    return message;

  const firstIndexOfJson = message.search(patternForBeginningOfJson);
  if (firstIndexOfJson === -1)
    return message;

  const endOfJson = findEndOfJson(message, firstIndexOfJson);
  return message.substring(0, firstIndexOfJson) + '\n' + JSON.stringify(JSON.parse(message.substring(firstIndexOfJson, endOfJson + 1)), null, 6) + '\n' + message.substring(endOfJson + 1);
};

const ErrorAlertComponent = (props: ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
  const handleHide = () => {
    // This action is called when clicking outside the modal
  };
  const handleCloseClick = () => {
    props.handleCloseClicked();
  };
  return (
    <Modal show={props.showAlert} dialogClassName="toast-error-alert" onHide={handleHide} data-testid="error-alert" className="error-alert-modal" backdropClassName="error-alert-backdrop">
      <Modal.Body>
        <div className="alert alert-danger">
          {props.showValidationError &&
            <div>
              <h5>Validation errors:</h5>
              <ul>
                {props.validationMessages && props.validationMessages.map((validationMessage, index) => (
                  <li key={`validation-${index}`} data-test-selector="validation-error">{validationMessage}</li>
                ))}
              </ul>
            </div>
          }

          {props.showExpectedError &&
            <div>
              <h5>The application has encountered an error</h5>
              <span data-test-selector="expected-error">{props.expectedErrorMessage}</span>
            </div>
          }

          {props.showUnexpectedError &&
            <div>
              <h5>The application has encountered an unexpected error</h5>
              {!!props.unexpectedErrorMessage?.trim().length && (<pre data-test-selector="unexpected-error">{detectAndFormatJson(props.unexpectedErrorMessage)}</pre>)}
              {!!props.unexpectedErrorStack?.trim().length && props.unexpectedErrorStack.trim() !== 'undefined' && (<pre data-test-selector="unexpected-error-stack">{props.unexpectedErrorStack}</pre>)}
            </div>
          }
          <div className="actions pull-right">
            <strong><a data-testid="error-alert-close" onClick={handleCloseClick}>Close</a></strong>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ErrorAlert = wrapComponent(ErrorAlertComponent as shame)<OwnProps, OwnProps & ConnectedProps<typeof connector>>( connector );
export default ErrorAlert; // Breaking this into two lines provides a name to React DevTools
