function filterByBeginners<T>(options: T[], selector: (option: T) => string, filter: string) {
  return options.filter(option => {
    const optionValue = selector(option);

    return optionValue.toLowerCase().indexOf(filter) === 0;
  });
}

function filterByFuzzies<T>(options: T[], selector: (option: T) => string, filter: string) {
  return options.filter(option => {
    const optionValue = selector(option);

    return optionValue.toLowerCase().indexOf(filter) > 0;
  });
}

/**
 * ![Fuzzies](https://66.media.tumblr.com/tumblr_lnca8idYap1qa6bvzo1_500.gif)
 */
export function filterByBeginnersThenFuzzies<T>(options: T[], selector: (option: T) => string, filter: string | undefined) {
  if (!filter || filter.trim().length === 0) {
    return options;
  }

  const lowerCaseFilter = filter.toLowerCase();

  const beginners = filterByBeginners<T>(options, selector, lowerCaseFilter);
  const fuzzies = filterByFuzzies<T>(options, selector, lowerCaseFilter);

  return [
    ...beginners,
    ...fuzzies,
  ];
}
