import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import * as Actions from './actions';
import Table from './receiving-table';
import { propToComponent } from 'client/hoc/hoc';
import { TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME, EMPTY_ARRAY } from 'client/constants';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableSearchField, AvailableFilter, ActiveSearch, ActiveFilter } from 'client/types';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { ReceivingQuery, FilterableQuery, ReceivingExcelQuery } from './receiving-query';
import { DateStr, ReceivableOrderReceivingStatus, TYPES } from 'shared/types';
import { GetUnifiedReceivableOrdersResponse, GetUnifiedReceivableOrdersFilterOptionsResponse } from 'schema/unified-receivable-order/unified-receivable-order-graphql-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { OwnProps as UIProps } from './receiving-table';
import { buildTableStateModule } from 'client/state/tables';

const tableName = TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME;
interface OwnProps { tableName: string, dataRequest: MsyncDataRequest }
interface StateProps { activeSortFields: ActiveSort[], activeSearch: ActiveSearch, activeFilters: ActiveFilter[], tablePageNumber: number }
const TableStateHelpers = buildTableStateModule(tableName);
const mapStateToProps = (state): StateProps => TableStateHelpers.commonTableProps(state);
interface WithAvailableFiltersProps { availableFilters: AvailableFilter[] }
interface WithSearchableFieldsProps { searchableFields: AvailableSearchField[] }
const withSearchableFields = WrappedComponent => props => propToComponent(WrappedComponent, { ...props, searchableFields: [ { id: 'identifier', name: 'PO #' } ] });
interface DispatchProps { onRowSelect(record): void; downloadReceivingFormMenuItemClicked(customerOrderIds: number[]): void }
const mapDispatchToProps = (dispatch, ownProps: OwnProps): DispatchProps => ({
  onRowSelect(record) { dispatch(push(`/transportation/receiving/details/${record.id}`)); },
  downloadReceivingFormMenuItemClicked(customerOrderIds) { dispatch(Actions.downloadReceivingFormMenuItemClicked(customerOrderIds)); },
});

export interface ReceivingRow {
  id: number;
  identifier: string;
  supplierVendorName: any;
  supplierLocationIdentifier: string | null;
  customerName: any;
  mfcAreaIdentifier: string | null;
  sellDepartmentIdentifier: string | null;
  orderType: string;
  orderMethod: string;
  arrivalDate: DateStr | null;
  storeDeliveryDate: DateStr | null;
  receivingStatus: ReceivableOrderReceivingStatus;
}

interface WithReceivingRowProps {
  receivingRows?: ReceivingRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithReceivingRows = msyncQuery<{ response: GetUnifiedReceivableOrdersResponse }, OwnProps & StateProps & WithTableParentProps, WithReceivingRowProps, {}>(ReceivingQuery, {
  alias: 'withReceivingRows',
  skip(ownProps) { return !ownProps.tableParentInfo.rowsPerPage; },
  options(ownProps): { variables } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: ownProps.activeSearch,
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
    };
  },
  props(props): WithReceivingRowProps {
    const { data, ownProps } = props;
    const receivingRows = !data.response ? undefined : data.response.unifiedReceivableOrders.map(record => ({
      id: record.id,
      identifier: record.identifier,
      supplierVendorName: record.supplierVendorName,
      supplierLocationIdentifier: record.supplierLocationIdentifier || '',
      customerName: record.customerName,
      mfcAreaIdentifier: record.mfcAreaIdentifier,
      sellDepartmentIdentifier: record.sellDepartmentIdentifier,
      orderType: record.orderType,
      orderMethod: record.orderMethod,
      arrivalDate: record.arrivalDate,
      storeDeliveryDate: record.storeDeliveryDate,
      receivingStatus: record.receivingStatus,
    }));

    const filters = _.flatten(ownProps.activeFilters.map(filter => filter.values)).join(', ');
    return {
      receivingRows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.response ? data.response.ids : [],
      totalCount: data.response ? data.response.totalCount : 0,
      totalUnfilteredCount: data.response ? data.response.totalUnfilteredCount : 0,
      dataRequest: {
        operationName: '',
        query: ReceivingExcelQuery,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: ownProps.activeSearch,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        workbookName: `Receiving${filters ? ` - ${filters}` : ''}`,
      },
    };
  },
});

const WithUnifiedReceivingAvailableFilters = msyncQuery<GetUnifiedReceivableOrdersFilterOptionsResponse, OwnProps & WithTableParentProps, WithAvailableFiltersProps>(FilterableQuery, {
  alias: 'withUnifiedReceivingAvailableFilters',
  skip(ownProps: OwnProps & WithTableParentProps) { return !ownProps.tableParentInfo.rowsPerPage; },
  options(ownProps) { return { fetchPolicy: 'cache-first' }; },
  props(props): WithAvailableFiltersProps {
    return {
      availableFilters: [
        { displayName: 'Supplier / Vendor'  , field: 'supplierVendorIdentifier'   , options: (props.data.response || {}).supplierVendors   || EMPTY_ARRAY },
        { displayName: 'Pickup At'          , field: 'supplierLocationIdentifier' , options: (props.data.response || {}).supplierLocations || EMPTY_ARRAY },
        { displayName: 'Customer'           , field: 'customerIdentifier'         , options: (props.data.response || {}).customers         || EMPTY_ARRAY },
        { displayName: 'Order Type'         , field: 'orderType'                  , options: (props.data.response || {}).orderTypes        || EMPTY_ARRAY },
        { displayName: 'Sell Department'    , field: 'sellDepartmentIdentifier'   , options: (props.data.response || {}).sellDepartments   || EMPTY_ARRAY },
        { displayName: 'MFC Area'           , field: 'mfcAreaIdentifier'          , options: (props.data.response || {}).mfcAreas          || EMPTY_ARRAY },
        { displayName: 'Receiving Status'   , field: 'receivingStatus'            , options: (props.data.response || {}).receivingStatuses || EMPTY_ARRAY },
        { displayName: 'Arrival Date'       , field: 'arrivalDate'                , type: TYPES.DATE                                                       },
        { displayName: 'Store Delivery Date', field: 'storeDeliveryDate'          , type: TYPES.DATE                                                       },
      ],
    };
  },
});

interface WithFilterAndSortCapabilitiesProps { onTableSort: (field: string) => void }
interface WithTableParentProps { tableParentInfo: TableParentInfo }
export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithReceivingRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithAvailableFiltersProps &
  WithTableParentProps;

assertCompatible<UIProps, ComponentProps>();
type Component<P> = new (props: P) => React.Component<P, any>;
const component = flowRight(
  tableParentHoc(),
  withSearchableFields,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(tableName),
  WithUnifiedReceivingAvailableFilters,
  WithReceivingRows,
)(Table) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
