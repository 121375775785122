import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/supplier-commitment/types';
import * as SharedQuery from 'client/app/planning/supplier-commitments/shared/query';

export interface UpdateSupplierCommitmentInput {
  supplierCommitmentId: number;
  input: {
    supplierId?: number;
    salesPlanId?: number;
    confirmed?: boolean;
  };
}

export interface UpdateSupplierCommitmentResponse {
  supplierCommitment: Pick<GraphQLTypes.SupplierCommitment, 'id'>;
}

export const UpdateSupplierCommitmentMutation = gql`
  mutation UpdateSupplierCommitmentMutation($supplierCommitmentId: Int!, $input: UpdateSupplierCommitmentInput!) {
    supplierCommitment: UpdateSupplierCommitment(id: $supplierCommitmentId, input: $input) {
      ...SupplierCommitmentFragment
    }
  }
  ${SharedQuery.SupplierCommitmentFragment}
`;
