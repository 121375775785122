import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import { connect } from 'react-redux';
import { FilterDate } from 'client/components/toolbar/filter-date';
import { Col, Row } from 'client/components/third-party';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { flowRight } from 'lodash';
import gql from 'graphql-tag';
import { DateStr } from 'shared/types';
import { GetWorksheetProps } from 'shared/types';
import { TYPES, ImmutableDateRange, CELL_TYPES, OrderMethod } from 'shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { IColumn } from 'client/components/table/column';
import { createSelector } from 'reselect';
import { ActiveSort, AvailableFilter, ActiveSearch, ActiveFilter } from 'client/types';
import { ProductId } from 'shared/schemas/product';
import { CustomerOrderProductGroupId, CustomerOrderProductGroupPacksPerShippingUnit } from 'shared/schemas/customer-order-product-group';
import { CustomerOrderProductId } from 'shared/schemas/customer-order-product';
import { getFilterOptionsSelector } from 'client/hoc/graphql/record-filter-options';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { Customer } from 'shared/schemas/customer';
import { Saved } from 'shared/schemas/record';
import { SellDepartment } from 'shared/schemas/sell-department';
import { withDefaultFilters } from 'client/hoc/default-filters';
import { getDefaultFilters } from 'client/helpers/product-worksheet';
import { PRODUCT_WORKSHEET_TABLE_NAME } from 'client/constants';
import { TableParentInfo } from 'client/components/table/table-parent';
import { COLUMN_AGGREGATE_SUM, COLUMN_AGGREGATE_MEAN, COLUMN_AGGREGATE_TWO_COLUMN_DIVISION } from 'client/components/table/table-helpers';
import { FindProductWorksheetReportPreferencesQuery, ProductWorksheetReportPreferencesResponse, ProductWorksheetReportPreferencesQueryResponse, EditProductWorksheetReportPreferencesMutation } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-report-preferences-query';
import { Clock } from 'shared/clock';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { AutoReplenishmentModal, ShippedStore } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-modal-container';
import { CustomerOrderProductPackSize } from 'shared/schemas/customer-order-product';
import { createDeepEqualSelector } from 'client/utils/reselect-utils';
import { ProductAllocationComponentProps, withProductAllocationSupport } from 'client/app/orders/customer-orders/product-worksheet/product-allocation';
import { productAllocationFooter } from 'client/app/orders/customer-orders/product-worksheet/product-allocation-footer';
import { FetchPolicy } from 'apollo-client';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import { sortNaturally } from 'shared/helpers/sort-naturally';
import { buildTableStateModule } from 'client/state/tables';
import { ProductWorksheetAllocation, ProductWorksheetStore } from 'shared/types';
import { orThrowBug } from 'shared/helpers';

export const createTableData = (args: { report?: ProductWorksheetStore[], allocations?: ProductWorksheetAllocation[], product: ProductWorksheetSelectedProduct }): ProductWorksheetColumns[] | undefined =>
  !args.allocations ? undefined : args.report?.map(({ storeId: id, identifier, comparableStoreIdentifier, ...store }, i) => ({
    ...store,
    ...args.allocations?.[i] ?? orThrowBug(`allocations arg expected never nullish on this conditional branch.`),
    id,
    identifier: `${identifier}` + (comparableStoreIdentifier ? ` (*${comparableStoreIdentifier})` : ''),
    customerOrderProducts: _.omit(_.mapKeys(args.allocations?.[i].customerOrderProducts, (_v, k) =>
      args.product.shipmentConfigurations.find(x => x.customerOrderProductId.toString() === k)?.customerOrderProductId ?? 0), 0),
  }));

const tableName = PRODUCT_WORKSHEET_TABLE_NAME;
const SEARCHABLE_FIELDS = [{ id: 'identifier', name: 'Store' }];

const NO_PRODUCT_OBJ: ProductWorksheetSelectedProduct = { productId: -1, shipmentConfigurations: [] };
const getAllocations = (data, props: Props) =>  data.productWorksheet ? data.productWorksheet.allocations : undefined;
const getReport = (data, props: Props) => data.productWorksheet ? data.productWorksheet.report : undefined;
const getProduct = (data, props: Props) => props.selectedProduct ? props.selectedProduct : NO_PRODUCT_OBJ;

// Using deep equal here because the data coming out of the GraphQL query doesn't
// seem to be === or shallowEqual even when you might think it is
const deserializeDataSelector = createDeepEqualSelector([getAllocations, getReport, getProduct], (allocations, report, product) => createTableData({ report, allocations, product }));
const getVariables = (data, props: Props) => data.variables;
const getProductLabel = (data, props: Props) => props.productLabel;
const getOrderIdentifier = (data, props: Props) => props.customerOrderIdentifier;
const makeRequestData = createSelector([getVariables, getProductLabel, getOrderIdentifier], (variables, productLabel, orderIdentifier) => ({
  operationName: 'productWorksheet',
  query: QUERY,
  variables,
  uniqueKey: 'storeId',
  sortField: 'identifier',
  worksheetName: productLabel,
  workbookName: `${orderIdentifier} - Product Worksheet`,
}));

const QUERY = gql`
  query productWorksheet($queryArgs: ProductWorksheetQueryArgs!, $filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput) {
    productWorksheet(queryArgs: $queryArgs, filters: $filters, sort: $sort, search: $search) {
      report {
        customerOrderId
        storeId
        comparableStoreId
        comparableStoreIdentifier
        productId
        identifier
        sold
        sellThru
        piecesOnHand
        racksOnHand
        recentSales
        forecastedSalesTrend
        pieceTarget
        daysSinceLastShipped
      }
      allocations {
        customerOrderId
        storeId
        productId
        allPiecesSales
        allPiecesAllocated
        percentReplenished
        customerOrderProducts
        percentShippedOfPieceTarget
        percentShippedOfPieceTargetWithCurrentOrder
        shipped
        shippedWithDraft
      }
    }
  }
`;

const ProductWorksheetTableDataContainer = msyncQuery<shame, Props & StateProps & ReportTypeProps, {}, GetWorksheetProps>(QUERY, {
  skip(ownProps) {
    return !ownProps.salesDateRange || !ownProps.selectedProduct.productId;
  },
  options(ownProps): { variables: GetWorksheetProps, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        queryArgs: {
          customerOrderId: ownProps.customerOrderId,
          productId: ownProps.selectedProduct.productId,
          selectedComparableProductId: ownProps.selectedComparableProductId,
          today: ownProps.today,
          performanceDateRange: ownProps.performanceDateRange,
          inventoryDateRange: ownProps.inventoryDateRange,
          salesDateRange: ownProps.salesDateRange,
          trendsDateRange: ownProps.trendsDateRange,
          pieceTargetDateRange: ownProps.pieceTargetDateRange,
          pieceTargetPrimaryStoresOnly: ownProps.pieceTargetPrimaryStoresOnly,
        },
        search: ownProps.activeSearch,
        filters: ownProps.activeFilters,
      },
      // DK 11/29/2017 - this query should be kept as network-only to ensure we
      // do not get any stale data when switching products or updating date ranges
      // on the product worksheet. Caching can cause staleness issues here.
      fetchPolicy: 'network-only',
    };
  },
  props({ data, ownProps }) {
    const content = deserializeDataSelector(data, ownProps);

    return {
      content,
      loading: data.loading,
      dataRequest: makeRequestData(data, ownProps),
    };
  },
});

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  headerMenuItems: RowMenuItem[];
  rowMenuItems: RowMenuItem[];
}

export interface ProductWorksheetSelectedProduct {
  productId: ProductId;
  shipmentConfigurations: Array<{
    identifier: string;
    customerOrderProductGroupId: CustomerOrderProductGroupId;
    customerOrderProductId: CustomerOrderProductId;
  }>;
}

interface Props {
  customerOrderId: number;
  customerOrderIdentifier: string;
  selectedProduct: ProductWorksheetSelectedProduct;
  selectedComparableProductId: number | undefined;
  sellDepartment: Saved<Partial<SellDepartment>>;
  customer: Pick<Saved<Customer>, 'id' | 'identifier'>;
  salesPlanId?: number;
  subSellDepartmentIdentifier?: string;
  tableParentInfo: TableParentInfo;
  confirmOkToSave: () => Promise<boolean>;
  onAutoReplenishmentClicked: (storeIdsFromMenuAction: number[]) => void;
  productLabel?: string;
  customerOrderProductGroups: Array<{
    id: CustomerOrderProductGroupId;
    packsPerShippingUnit?: CustomerOrderProductGroupPacksPerShippingUnit;
    packSize: CustomerOrderProductPackSize;
  }>;
  orderMethod: OrderMethod;
  dataRequest: MsyncDataRequest;
}

interface ColumnInfoProps {
  columns: IColumn[];
}

interface ReportTypeProps {
  productWorksheetPreferencesId: number;
  today: DateStr;
  performanceDateRange: ImmutableDateRange;
  inventoryDateRange: ImmutableDateRange;
  salesDateRange: ImmutableDateRange;
  trendsDateRange: ImmutableDateRange;
  pieceTargetDateRange: ImmutableDateRange;
  pieceTargetPrimaryStoresOnly: boolean;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

interface WithPreferencesMutationProps {
  persistProductWorksheetPreferences: (args: shame) => void;
}

const getMutate = props => props.mutate;
const getPrefId = props => props.ownProps.productWorksheetPreferencesId;
const makePersist = createSelector([getMutate, getPrefId], (mutate, prefId) => {
  return {
    async persistProductWorksheetPreferences(args: shame) {
      return await mutate({
        variables: {
          input: { id: prefId, ...args },
        },
      });
    },
  };
});

const withProductWorksheetPreferencesMutation = msyncMutation<{}, Props & ReportTypeProps, WithPreferencesMutationProps>(EditProductWorksheetReportPreferencesMutation, {
  skipSaveConfirmationDialog: true,
  props(props) {
    return makePersist(props);
  },
});

interface DispatchProps {
  handlePerformanceDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => void;
  handleInventoryDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => void;
  handleSalesDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => void;
  handleTrendsDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => void;
  handlePieceTargetDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => void;
  handlePieceTargetPrimaryStoresOnlyClicked: (persister: shame, value: boolean) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    handlePerformanceDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => persister({ performanceStartDate: dateRange.startDate, performanceEndDate: dateRange.endDate }),
    handleInventoryDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => persister({ inventoryStartDate: dateRange.startDate, inventoryEndDate: dateRange.endDate }),
    handleSalesDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => persister({ salesStartDate: dateRange.startDate, salesEndDate: dateRange.endDate }),
    handleTrendsDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => persister({ trendsStartDate: dateRange.startDate, trendsEndDate: dateRange.endDate }),
    handlePieceTargetDateRangeSelected: (persister: shame, dateRange: ImmutableDateRange) => persister({ pieceTargetStartDate: dateRange.startDate, pieceTargetEndDate: dateRange.endDate }),
    handlePieceTargetPrimaryStoresOnlyClicked: async (persister: shame, value: boolean) => persister({ pieceTargetPrimaryStoresOnly: value }),
  };
};

const getSalesPlanId = (props: ComponentProps) => props.salesPlanId;
const getAutoReplenishmentClicked = (props: ComponentProps) => props.onAutoReplenishmentClicked;

const headerMenuItems = createSelector([getSalesPlanId, getAutoReplenishmentClicked], (salesPlanId, onAutoReplenishmentClicked) => {
  return [
    ...(salesPlanId ? [{
      label: 'Allocate To Hit % Shipped Target',
      onClick: onAutoReplenishmentClicked,
      shouldDisplay: (record: any) => true,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    }] : []),
  ];
});

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state, ownProps): StateProps => {
  return {
    ...TableStateHelpers.commonTableProps(state),
    headerMenuItems: headerMenuItems(ownProps),
    rowMenuItems: headerMenuItems(ownProps),
  };
};

interface BaseComponentProps extends Props, StateProps, DispatchProps, ReportTypeProps, ColumnInfoProps, WithPreferencesMutationProps, WithTableParentProps {
  content: any;
  loading: boolean;
  availableFilters: any;
}

type ComponentProps = BaseComponentProps & ProductAllocationComponentProps;

export interface ProductWorksheetColumns {
  id: number;
  identifier: string;
  shipped: number;
  shippedWithDraft: number;
  sold: number;
  sellThru: number;
  piecesOnHand: number;
  racksOnHand: number;
  recentSales: number;
  forecastedSalesTrend: number;
  daysSinceLastShipped: number;
  pieceTarget: number;
  percentShippedOfPieceTarget: number;
  allPiecesSales: number;
  allPiecesAllocated: number;
  percentReplenished: number;
}

const memoizedAutoReplenishmentModalData = createSelector([_.identity], (content: ProductWorksheetColumns[]) => {
  return _.map(content, (r: ProductWorksheetColumns): ShippedStore => {
    return {
      storeId: r.id,
      shipped: r.shipped, // purposefully does not include current order's shipped numbers
      shippedWithDraft: r.shippedWithDraft,
      pieceTarget: r.pieceTarget,
    };
  });
});

class ProductWorksheetTableComponent extends React.PureComponent<ComponentProps, any> {
  private readonly tableComponent: React.StatelessComponent<FilterableTableProps>;
  private readonly mouseEnterPerformance: () => void;
  private readonly mouseEnterInventory: () => void;
  private readonly mouseEnterSales: () => void;
  private readonly mouseEnterTrends: () => void;
  private readonly mouseEnterPieceTarget: () => void;
  private readonly mouseLeave: () => void;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      dateRangeHoverClassName: undefined,
    };
    this.tableComponent = buildFilterableTable(tableName);

    const mouseEnter = (className: string) => {
      return () => {
        this.setState({ dateRangeHoverClassName: className });
      };
    };

    const mouseLeave = () => {
      this.setState({ dateRangeHoverClassName: undefined });
    };

    this.mouseEnterPerformance = mouseEnter('highlight-performance');
    this.mouseEnterInventory = mouseEnter('highlight-inventory');
    this.mouseEnterSales = mouseEnter('highlight-sales');
    this.mouseEnterTrends = mouseEnter('highlight-trends');
    this.mouseEnterPieceTarget = mouseEnter('highlight-piece-target');

    this.mouseLeave = mouseLeave;

    this.handlePerformanceDateRangeSelected = this.handlePerformanceDateRangeSelected.bind(this);
    this.handleInventoryDateRangeSelected = this.handleInventoryDateRangeSelected.bind(this);
    this.handleSalesDateRangeSelected = this.handleSalesDateRangeSelected.bind(this);
    this.handleTrendsDateRangeSelected = this.handleTrendsDateRangeSelected.bind(this);
    this.handlePieceTargetDateRangeSelected = this.handlePieceTargetDateRangeSelected.bind(this);
    this.handlePieceTargetPrimaryStoresOnlyClicked = this.handlePieceTargetPrimaryStoresOnlyClicked.bind(this);
  }

  componentDidUpdate() {
    this.props.tableParentInfo.adjustTableDimensions();
  }

  handlePerformanceDateRangeSelected(dateRange: ImmutableDateRange) {
    this.props.handlePerformanceDateRangeSelected(this.props.persistProductWorksheetPreferences, dateRange);
  }

  handleInventoryDateRangeSelected(dateRange: ImmutableDateRange) {
    this.props.handleInventoryDateRangeSelected(this.props.persistProductWorksheetPreferences, dateRange);
  }

  handleSalesDateRangeSelected(dateRange: ImmutableDateRange) {
    this.props.handleSalesDateRangeSelected(this.props.persistProductWorksheetPreferences, dateRange);
  }

  handleTrendsDateRangeSelected(dateRange: ImmutableDateRange) {
    this.props.handleTrendsDateRangeSelected(this.props.persistProductWorksheetPreferences, dateRange);
  }

  handlePieceTargetDateRangeSelected(dateRange: ImmutableDateRange) {
    this.props.handlePieceTargetDateRangeSelected(this.props.persistProductWorksheetPreferences, dateRange);
  }

  handlePieceTargetPrimaryStoresOnlyClicked(value: boolean) {
    this.props.handlePieceTargetPrimaryStoresOnlyClicked(this.props.persistProductWorksheetPreferences, value);
  }

  private allocateProductToStores = async (args: [{ storeId: number, quantity: number, customerOrderProductGroupId: number, prevQuantity: number}]) => {
    await this.props.allocateProductToStores(args.map(ar => ({
      customerOrderProductGroupId: ar.customerOrderProductGroupId,
      storeId: ar.storeId,
      quantity: ar.quantity,
      salesDateRange: this.props.salesDateRange,
      performanceDateRange: this.props.performanceDateRange,
      pieceTargetDateRange: this.props.pieceTargetDateRange,
    })));
  }

  public render() {
    return (
      <Col sm={12}>
        <Row className="mfc-date-range-toolbar mfc-toolbar">
          <Col sm={2} className="mfc-date-range-tool" onMouseEnter={this.mouseEnterPerformance} onMouseLeave={this.mouseLeave}>
            <FilterDate label="Performance" testid="performance-date-range" onSelected={this.handlePerformanceDateRangeSelected} dateRange={this.props.performanceDateRange} verticalStackLabel />
          </Col>
          <Col sm={2} className="mfc-date-range-tool" onMouseEnter={this.mouseEnterInventory} onMouseLeave={this.mouseLeave}>
            <FilterDate label="Inventory" testid="inventory-date-range" onSelected={this.handleInventoryDateRangeSelected} dateRange={this.props.inventoryDateRange} verticalStackLabel />
          </Col>
          <Col sm={2} className="mfc-date-range-tool" onMouseEnter={this.mouseEnterSales} onMouseLeave={this.mouseLeave}>
            <FilterDate label="Sales" testid="sales-date-range" onSelected={this.handleSalesDateRangeSelected} dateRange={this.props.salesDateRange} verticalStackLabel />
          </Col>
          <Col sm={2} className="mfc-date-range-tool" onMouseEnter={this.mouseEnterTrends} onMouseLeave={this.mouseLeave}>
            <FilterDate label={this.props.selectedComparableProductId ? 'Trends*' : 'Trends'} testid="trends-date-range" onSelected={this.handleTrendsDateRangeSelected} dateRange={this.props.trendsDateRange} verticalStackLabel />
          </Col>
          <Col sm={4} className="mfc-date-range-tool" onMouseEnter={this.mouseEnterPieceTarget} onMouseLeave={this.mouseLeave}>
            <Row>
              <Col sm={4} className="product-worksheet-piece-target-date-range-col">
                <FilterDate label={this.props.selectedComparableProductId ? 'Piece Target*' : 'Piece Target'} testid="piece-target-date-range" onSelected={this.handlePieceTargetDateRangeSelected} dateRange={this.props.pieceTargetDateRange} verticalStackLabel />
              </Col>
              <Col sm={6} className="product-worksheet-piece-target-primary-stores-only-col">
                <SimpleCheckbox
                  className="product-worksheet-piece-target-primary-stores-only-checkbox"
                  testid="piece-target-primary-stores-only"
                  value={this.props.pieceTargetPrimaryStoresOnly}
                  onChange={this.handlePieceTargetPrimaryStoresOnlyClicked}
                  label="Primary Stores Only" />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={classNames(this.state.dateRangeHoverClassName, 'product-worksheet-table-wrapper')}>
          <this.tableComponent
            table={tableName}
            content={this.props.content}
            loading={this.props.loading}
            columns={this.props.columns}
            totalCount={this.props.content ? this.props.content.length : undefined}
            totalUnfilteredCount={this.props.content ? this.props.content.length || 1 : 1}
            filteredRecordIds={filteredRecordIdsFromContent(this.props)}
            searchableFields={SEARCHABLE_FIELDS}
            availableFilters={this.props.availableFilters}
            checkable
            list={false}
            disableCreate
            displayLoadingIndicator
            tablePaginated={false}
            tableParentInfo={this.props.tableParentInfo}
            confirmOkToSave={this.props.confirmOkToSave}
            headerMenuItems={this.props.headerMenuItems}
            rowMenuItems={this.props.rowMenuItems}
            dataRequest={this.props.dataRequest}
            alwaysDisplayTable
          />

          <AutoReplenishmentModal
            customerOrderId={this.props.customerOrderId}
            productId={this.props.selectedProduct.productId}
            productLabel={this.props.productLabel}
            orderMethod={this.props.orderMethod}
            customerOrderProductGroups={this.props.customerOrderProductGroups}
            data={memoizedAutoReplenishmentModalData(this.props.content)}
            allocateProductToStores={this.allocateProductToStores}
          />
        </div>
      </Col>);
  }
}

const sortContent = (args: { data?: any[], sortOption: ActiveSort[] }) => {
  const { data, sortOption } = args;
  if (!data) {
    return undefined;
  }

  type SortField = string | ((o: any) => any);

  const mappedSorts = sortOption.map(option => {
    let sortField: SortField = option.sortField;
    const foreignColumn = option.foreignColumn;

    // HACK!
    // Special product worksheet specific handling for the customer
    // order product columns (FR, F1, ...)
    if (sortField === 'customerOrderProducts' && foreignColumn) {
      sortField = row => row.customerOrderProducts[foreignColumn];
    }

    return { sortField, sortOrder: option.sortOrder };
  });

  // Make sure the identifier is always the tie breaker
  return sortNaturally(data, [...mappedSorts, { sortField: 'identifier', sortOrder: 'asc' }]);
};

interface SortState {
  sortedContent: shame[] | undefined;
}

function buildSorting(args: { skipSortingOnIds?: (props: ComponentProps) => string[] }) {
  return function withSorting(WrappedComponent: React.StatelessComponent<ComponentProps>): any {
    return class Sort extends React.Component<ComponentProps, SortState> {
      constructor(props) {
        super(props);

        this.state = {
          sortedContent: sortContent({ data: props.content, sortOption: props.activeSortFields }),
        };
      }

      /**
       * This is very specific to how the ProductWorksheet table should be sorted. Specifically, since this
       * is an editable table, need to be careful about the user making a change that causes rows to bounce
       * around while they're making changes. For that reason, content changes are not enough to cause a
       * re-sort. It will only re-sort when something else changes that would warrant updating the sort
       * order (things like changing the sort field, or changing a filter, etc.)
       */
      componentWillReceiveProps(nextProps: ComponentProps) {
        const sortNeeded =
          nextProps.activeSortFields !== this.props.activeSortFields ||
          nextProps.activeFilters !== this.props.activeFilters ||
          nextProps.activeSearch !== this.props.activeSearch ||
          nextProps.selectedProduct.productId !== this.props.selectedProduct.productId ||
          (!nextProps.loading && this.props.loading) ||
          (_.isNil(this.props.content) && !_.isNil(nextProps.content)) ||
          (this.props.content && nextProps.content && this.props.content.length !== nextProps.content.length);

        if (sortNeeded || _.isNil(this.state.sortedContent)) {
          this.setState({
            sortedContent: sortContent({ data: nextProps.content, sortOption: nextProps.activeSortFields }),
          });
        } else if (this.props.content !== nextProps.content) {
          // Don't want to change the sort order, but if the content has changed need to re-render
          // while maintaining the previous sort order

          // Turn the new content into a hash keyed by id (for quick look up)
          const lookup = (nextProps.content as Array<{ id: number }>).reduce((memo, row) => {
            memo[row.id] = row;
            return memo;
          }, {});

          const newContent = this.state.sortedContent.map(row => lookup[row.id]);
          this.setState({ sortedContent: newContent });
        }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            content={this.state.sortedContent}
          />
        );
      }
    };
  };
}

const getRecordIdsFromProps = (props: ComponentProps) => (props.content || []).map(r => r.id);
const filteredRecordIdsFromContent = createDeepEqualSelector([getRecordIdsFromProps], ids => {
  return ids;
});

const getSubSellDepartmentIdentifier = (props: ComponentProps) => props.subSellDepartmentIdentifier;
const getSelectedProduct = (props: ComponentProps) => props.selectedProduct;
const getAllocateProductToStore = (props: ComponentProps) => props.allocateProductToStore;
const getSalesDateRange = (props: ComponentProps) => props.salesDateRange;
const getGetPerformanceDateRange = (props: ComponentProps) => props.performanceDateRange;
const getComparableProductId = (props: ComponentProps) => props.selectedComparableProductId;

const productWorksheetColumns = createSelector(
  [
    getSubSellDepartmentIdentifier,
    getSalesPlanId,
    getSelectedProduct,
    getAllocateProductToStore,
    getSalesDateRange,
    getGetPerformanceDateRange,
    getComparableProductId,
  ],
  (subSellDepartmentIdentifier, salesPlanId, selectedProduct, allocateProductToStore, salesDateRange, performanceDateRange, selectedComparableProductId) => {
    let columns: IColumn[] = [
      {
        id: 'identifier',
        accessor: 'identifier',
        header: 'Store',
        tableEditable: false,
        columnWidth: 12,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'shipped',
        accessor: 'shipped',
        header: 'Shipped',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-performance'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'shippedWithDraft',
        accessor: 'shippedWithDraft',
        header: 'Shpd w/ Drafts',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-performance'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'sold',
        accessor: 'sold',
        header: 'Sold',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-performance'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'sellThru',
        accessor: 'sellThru',
        header: 'Sell Thru',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.PERCENTAGE,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-performance'],
        footer: COLUMN_AGGREGATE_TWO_COLUMN_DIVISION('sold', 'shipped'),
      },
      {
        id: 'piecesOnHand',
        accessor: 'piecesOnHand',
        header: 'POH',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-inventory'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'racksOnHand',
        accessor: 'racksOnHand',
        header: 'ROH',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.FLOAT,
        extraHeaderClassNames: ['highlight-inventory'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'recentSales',
        accessor: 'recentSales',
        header: 'Recent Sales',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-sales'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'allPiecesSales',
        accessor: 'allPiecesSales',
        header: 'All Pieces Sales',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-sales'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'allPiecesAllocated',
        accessor: 'allPiecesAllocated',
        header: 'All Pieces Allocated',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'percentReplenished',
        accessor: 'percentReplenished',
        header: '% Replenished',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.PERCENTAGE,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-sales'],
        footer: COLUMN_AGGREGATE_MEAN,
      },
      {
        id: 'forecastedSalesTrend',
        accessor: 'forecastedSalesTrend',
        header: selectedComparableProductId ? 'Forecasted*' : 'Forecasted',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
        extraHeaderClassNames: ['highlight-trends'],
        footer: COLUMN_AGGREGATE_SUM,
      },
      {
        id: 'daysSinceLastShipped',
        accessor: 'daysSinceLastShipped',
        header: 'Days Last Ship',
        tableEditable: false,
        columnWidth: 11,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ];

    if (subSellDepartmentIdentifier === 'Potted Floral' || subSellDepartmentIdentifier === 'Cut Floral') {
      columns = columns.filter(column => column.id !== 'racksOnHand');
    }

    if (subSellDepartmentIdentifier !== 'Cut Floral') {
      columns = columns.filter(column =>
        column.id !== 'allPiecesSales' &&
        column.id !== 'allPiecesAllocated' &&
        column.id !== 'percentReplenished');
    }

    if (salesPlanId) {
      columns.push(
        {
          id: 'pieceTarget',
          accessor: 'pieceTarget',
          header: selectedComparableProductId ? 'Piece Target*' : 'Piece Target',
          tableEditable: false,
          columnWidth: 11,
          sortable: true,
          cellType: CELL_TYPES.INT,
          type: TYPES.NUMBER,
          extraHeaderClassNames: ['highlight-piece-target'],
          footer: COLUMN_AGGREGATE_SUM,
        },
        {
          id: 'percentShippedOfPieceTarget',
          accessor: 'percentShippedOfPieceTarget',
          header: selectedComparableProductId ? '% Shipped*' : '% Shipped',
          tableEditable: false,
          columnWidth: 11,
          sortable: true,
          cellType: CELL_TYPES.PERCENTAGE,
          type: TYPES.NUMBER,
          extraHeaderClassNames: ['highlight-performance', 'highlight-piece-target'],
          footer: COLUMN_AGGREGATE_TWO_COLUMN_DIVISION('shipped', 'pieceTarget'),
        },
        {
          id: 'percentShippedOfPieceTargetWithCurrentOrder',
          accessor: 'percentShippedOfPieceTargetWithCurrentOrder',
          header: selectedComparableProductId ? '% Shpd w/ Drafts*' : '% Shpd w/ Drafts',
          tableEditable: false,
          columnWidth: 11,
          sortable: true,
          cellType: CELL_TYPES.PERCENTAGE,
          type: TYPES.NUMBER,
          extraHeaderClassNames: ['highlight-performance', 'highlight-piece-target'],
          footer: COLUMN_AGGREGATE_TWO_COLUMN_DIVISION('shippedWithDraft', 'pieceTarget'),
        },
      );
    }

    selectedProduct.shipmentConfigurations.forEach(configuration => {
      columns.push(
        {
          id: `customerOrderProducts.${configuration.customerOrderProductId}`,
          accessor: `customerOrderProducts.${configuration.customerOrderProductId}`,
          header: configuration.identifier,
          tableEditable: true,
          columnWidth: 11,
          sortable: true,
          cellType: CELL_TYPES.SPINNER_NO_DEBOUNCE,
          onSave: (storeId, quantity: string, prevValue: string) => {
            const parsedValue = parseInt(quantity);
            const newQuantity = isNaN(parsedValue) ? 0 : parsedValue;

            const prevParsedValue = parseInt(prevValue);
            const prevQuantity = isNaN(prevParsedValue) ? 0 : prevParsedValue;

            return allocateProductToStore({
              customerOrderProductGroupId: configuration.customerOrderProductGroupId,
              storeId,
              quantity: newQuantity,
              prevQuantity,
            });
          },
          type: TYPES.NUMBER,
          getTestId: (data: ProductWorksheetColumns) => {
            const storeIdentifier = data.identifier;
            return `${storeIdentifier}-${configuration.identifier}`;
          },
          footerComponent: productAllocationFooter(configuration.customerOrderProductGroupId),
        });
    });

    const adjustedWidthColumns = columns.map(column => {
      return {
        ...column,
        columnWidth: 100.0 / columns.length,
      };
    });

    return adjustedWidthColumns;
  });

function withColumnInfo(WrappedComponent: React.StatelessComponent<ComponentProps>): React.StatelessComponent<ComponentProps> {
  const result: React.StatelessComponent<ComponentProps> = (props: ComponentProps) => {

    const columns = productWorksheetColumns(props);

    return (
      <WrappedComponent
        { ...props }
        columns={columns}
      />
    );
  };

  return result;
}

const FILTER_OPTIONS_QUERY = gql`
  query worksheetFilterOptions($customerOrderId: Int!) {
    filterOptions: worksheetFilterOptions(customerOrderId: $customerOrderId) {
      field,
      options {
        id,
        value,
        _customType,
        displayValue
      }
    }
  }
`;

const filterSelector = getFilterOptionsSelector('stores', ['region', 'market', 'primaryGlobal', 'encoreStore']);
const withWorksheetFilterOptions = msyncQuery<{ filterOptions: AvailableFilter[] }, { customerOrderId: number }, {}, { customerOrderId: number }>(FILTER_OPTIONS_QUERY, {
  options(ownProps) {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId,
      },
    };
  },

  props(props) {
    return filterSelector(props.data, []);
  },
});

const getPerformanceStartDate = (data: ProductWorksheetReportPreferencesResponse) => data.performanceStartDate;
const getPerformanceEndDate = (data: ProductWorksheetReportPreferencesResponse) => data.performanceEndDate;
const getInventoryStartDate = (data: ProductWorksheetReportPreferencesResponse) => data.inventoryStartDate;
const getInventoryEndDate = (data: ProductWorksheetReportPreferencesResponse) => data.inventoryEndDate;
const getSalesStartDate = (data: ProductWorksheetReportPreferencesResponse) => data.salesStartDate;
const getSalesEndDate = (data: ProductWorksheetReportPreferencesResponse) => data.salesEndDate;
const getTrendsStartDate = (data: ProductWorksheetReportPreferencesResponse) => data.trendsStartDate;
const getTrendsEndDate = (data: ProductWorksheetReportPreferencesResponse) => data.trendsEndDate;
const getPieceTargetStartDate = (data: ProductWorksheetReportPreferencesResponse) => data.pieceTargetStartDate;
const getPieceTargetEndDate = (data: ProductWorksheetReportPreferencesResponse) => data.pieceTargetEndDate;
const getPieceTargetPrimaryStoresOnly = (data: ProductWorksheetReportPreferencesResponse) => data.pieceTargetPrimaryStoresOnly;

const reportPreferencesSelector = createSelector([getPerformanceStartDate, getPerformanceEndDate, getInventoryStartDate, getInventoryEndDate, getSalesStartDate, getSalesEndDate, getTrendsStartDate, getTrendsEndDate, getPieceTargetStartDate, getPieceTargetEndDate, getPieceTargetPrimaryStoresOnly], (performanceStart, performanceEnd, inventoryStart, inventoryEnd, salesStart, salesEnd, trendsStart, trendsEnd, pieceTargetStart, pieceTargetEnd, pieceTargetPrimaryStoresOnly) => ({
    performanceDateRange: new ImmutableDateRange({
      startDate: performanceStart,
      endDate: performanceEnd,
    }),
    inventoryDateRange: new ImmutableDateRange({
      startDate: inventoryStart,
      endDate: inventoryEnd,
    }),
    salesDateRange: new ImmutableDateRange({
      startDate: salesStart,
      endDate: salesEnd,
    }),
    trendsDateRange: new ImmutableDateRange({
      startDate: trendsStart,
      endDate: trendsEnd,
    }),
    pieceTargetDateRange: pieceTargetStart && pieceTargetEnd ? new ImmutableDateRange({
      startDate: pieceTargetStart,
      endDate: pieceTargetEnd,
    }) : undefined,
    pieceTargetPrimaryStoresOnly,
  }));

const withProductWorksheetReportPreferences = msyncQuery<ProductWorksheetReportPreferencesQueryResponse, Props & StateProps & ReportTypeProps, {}>(FindProductWorksheetReportPreferencesQuery, {
  skip(ownProps) {
    return _.isNil(ownProps.customerOrderId);
  },
  options(ownProps): { variables: { customerOrderId } } {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId,
      },
    };
  },
  props({ data, ownProps }) {
    if (data.loading || _.isNil(data.productWorksheetReportPreferences)) {
      return {
        loading: _.isNil(data.loading) ? true : data.loading,
      };
    }
    const preferences: ProductWorksheetReportPreferencesResponse = data.productWorksheetReportPreferences;
    return {
      productWorksheetPreferencesId: preferences.id,
      ...reportPreferencesSelector(preferences),
      today: Clock.today(),
      loading: _.isNil(data.loading) ? true : data.loading,
    };
  },
});

export const ProductWorksheetTable = flowRight(
  withProductWorksheetReportPreferences,
  withProductAllocationSupport,
  withProductWorksheetPreferencesMutation,
  withWorksheetFilterOptions,
  withDefaultFilters<Props>(tableName,
    createSelector([
      (props: ComponentProps) => props.customer.identifier,
      (props: ComponentProps) => props.sellDepartment.identifier,
      (props: ComponentProps) => props.subSellDepartmentIdentifier,
    ], getDefaultFilters),
  ),
  connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps),
  ProductWorksheetTableDataContainer,
  buildSorting({ skipSortingOnIds: (props: ComponentProps) => props.selectedProduct.shipmentConfigurations.map(sc => sc.identifier) }),
  withColumnInfo, // Must come after withMutation (it provides the handler for mutating a cell value)
)(ProductWorksheetTableComponent) as React.ComponentType<Props>;
