import buildAdminAttachmentPage from '../admin/admin-attachments-container';
import navBuilder from './nav-items';
import { ProductRecordBar } from './product-record-bar';
import { PRODUCT_ATTACHMENTS_TABLE_NAME } from 'client/constants';

export default buildAdminAttachmentPage({
  formName: 'ProductAttachmentsForm',
  tableName: PRODUCT_ATTACHMENTS_TABLE_NAME,
  navBuilder,
  descriptionResolver: product => `${product.identifier} - ${product.description}`,
  RecordBarDetailComponent: ProductRecordBar,
  recordType: 'Product',
});
