import * as React from 'react';
import * as _ from 'lodash';
import { Form, FormGroup, Row, Col, Button } from 'client/components/third-party';
import { FieldWrapper, LabeledDisplay } from '../../form';
import { FieldArray, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import Dropdown from '../../dropdown';
import { setSelectedSpecYear } from '../../../actions/form';
import { optionsContainerGenerator } from '../../../hoc/options-container-generator';
import { columns, columnInfo } from '../../../../shared/schemas';
import { Clock } from 'shared/clock';
import { yearOf } from 'shared/helpers/date-helpers';
import { ElementOf } from 'shared/types';
import { z as Z } from 'zod';
import { buildFragment, columns as getColumns, flattenFragment } from 'shared/schemas';
import gqlTag from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { upperFirst } from 'lodash';
import { singular } from 'pluralize';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="productSpecs" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;
// cannot use SpecializeField becaus the ReduxForm FieldArray requires non-standard field addressing syntax
// const Field = SpecializeField(ProductSpec, {alwaysShowErrors: true, hideOptionalLabel: true});

const SupplierSchema = Z.object({
  id: Z.optional(Z.number()),
  identifier: Z.optional(Z.string()),
  name: Z.optional(Z.string()),
});

const ChildSchemas = {
  pot: Z.object({
    id: Z.number(),
    identifier: Z.string(),
    description: Z.string(),
    supplier: Z.nullable(SupplierSchema),
    size: Z.nullable(Z.string()),
    color: Z.nullable(Z.string()),
    partNumber: Z.nullable(Z.string()),
    weightAndMeasure: Z.nullable(Z.string()),
  }),
  tray: Z.object({
    id: Z.number(),
    identifier: Z.string(),
    description: Z.string(),
    supplier: Z.nullable(SupplierSchema),
    size: Z.nullable(Z.string()),
    color: Z.nullable(Z.string()),
    partNumber: Z.nullable(Z.string()),
  }),
  tag: Z.object({
    id: Z.number(),
    identifier: Z.string(),
    tagType: Z.string(),
    supplier: Z.nullable(SupplierSchema),
    size: Z.nullable(Z.string()),
    partNumber: Z.nullable(Z.string()),
  }),
};

const SpecSchema = Z.object({
  specYear: Z.number(),
  potId: Z.optional(Z.nullable(Z.number())),
  trayId: Z.optional(Z.nullable(Z.number())),
  tagId: Z.optional(Z.nullable(Z.number())),
  generalNotes: Z.optional(Z.nullable(Z.string())),
  decorationNotes: Z.optional(Z.nullable(Z.string())),
});

const ReduxFormFieldsArraySchema = Z.object({
    _isFieldArray: Z.boolean(),
    length: Z.number(),
    name: Z.string(),
    map: Z.function(Z.tuple([Z.function(Z.tuple([Z.string()]), Z.string())]), Z.array(Z.string())),
    push: Z.function(),
});

const ProductSpecFormGroupPropsSchema = Z.object({
  // options from withOptionsContainer wrappers
  pots : Z.optional(Z.array(ChildSchemas.pot)),
  trays: Z.optional(Z.array(ChildSchemas.tray)),
  tags : Z.optional(Z.array(ChildSchemas.tag)),
  potsLoading : Z.optional(Z.boolean()),
  traysLoading: Z.optional(Z.boolean()),
  tagsLoading : Z.optional(Z.boolean()),

  // from withRecordDetails HOCs
  selectedPotDetails : Z.optional(ChildSchemas.pot),
  selectedTrayDetails: Z.optional(ChildSchemas.tray),
  selectedTagDetails : Z.optional(ChildSchemas.tag),

  // change callbacks (from redux mapDisparchToProps)
  // onSpecYearChange  : Z.function(),
  onPotChange       : Z.function(),
  onTrayChange      : Z.function(),
  onTagChange       : Z.function(),
  onCheckboxClicked : Z.function(),
  onDuplicateClicked: Z.function(),
  handleSubmit      : Z.function(),
  onSpecYearChange  : Z.function(Z.tuple([Z.number()]), Z.void()),
  // onPotChange       : Z.function(Z.tuple([Z.string(), Z.number()]), Z.void()),
  // onTrayChange      : Z.function(Z.tuple([Z.string(), Z.number()]), Z.void()),
  // onTagChange       : Z.function(Z.tuple([Z.string(), Z.number()]), Z.void()),
  // onCheckboxClicked : Z.function(Z.tuple([]), Z.void()),
  // onDuplicateClicked: Z.function(Z.tuple([ReduxFormFieldsArraySchema, Z.optional(SpecSchema), Z.number()]), Z.void()),
  // handleSubmit      : Z.function(Z.tuple([]), Z.void()),

  fields: ReduxFormFieldsArraySchema,
  specYears: Z.array(Z.number()),
  selectedSpecIndex: Z.number(),
  selectedSpec: Z.optional(SpecSchema),
  nextAvailableSpecYear: Z.number(),
});

class ProductSpecFormGroup extends React.Component<Z.TypeOf<typeof ProductSpecFormGroupPropsSchema>> {
  constructor(props) { super(ProductSpecFormGroupPropsSchema.parse(props)); }
  shouldComponentUpdate(nextProps) {
    return (
      this.props.pots?.length !== nextProps.pots?.length ||
      this.props.trays?.length !== nextProps.trays?.length ||
      this.props.tags?.length !== nextProps.tags?.length ||
      this.props.fields?.length !== nextProps.fields?.length ||
      this.props.nextAvailableSpecYear !== nextProps.nextAvailableSpecYear ||
      this.props.selectedSpecIndex !== nextProps.selectedSpecIndex ||
      (this.props.selectedSpec && (this.props.selectedSpec.potId !== nextProps.selectedSpec.potId)) ||
      (this.props.selectedSpec && (this.props.selectedSpec.trayId !== nextProps.selectedSpec.trayId)) ||
      (this.props.selectedSpec && (this.props.selectedSpec.tagId !== nextProps.selectedSpec.tagId)) ||
      (this.props.selectedSpec && (this.props.selectedSpec.generalNotes !== nextProps.selectedSpec.generalNotes)) ||
      (this.props.selectedSpec && (this.props.selectedSpec.decorationNotes !== nextProps.selectedSpec.decorationNotes)) ||
      this.props.selectedPotDetails?.id !== nextProps.selectedPotDetails?.id ||
      this.props.selectedTrayDetails?.id !== nextProps.selectedTrayDetails?.id ||
      this.props.selectedTagDetails?.id !== nextProps.selectedTagDetails?.id
    );
  }

  onNewSpecClicked = () => {
    this.props.fields.push({
      specYear: this.props.nextAvailableSpecYear,
      retailRequired: false,
      specComplete: false,
    });
    this.props.onSpecYearChange(this.props.nextAvailableSpecYear);
  }

  render() {
    const p = this.props;
    //const p = ProductSpecFormGroupPropsSchema.parse(this.props);

    return (
      <Col sm={12}>
        <Form horizontal onSubmit={p.handleSubmit} data-testid="spec-info">
          {!p.fields?.length &&
            <div className="table-empty">
              <div className="placeholder">There are currently no specs for this product.</div>
              <div className="action">
                <Button
                  bsClass="mfc-button mfc-submit-button mfc-submit-button-primary mfc-submit-button-primary-default"
                  onClick={this.onNewSpecClicked}
                  data-testid="qa-new-element-button">
                  Add Spec
                </Button>
              </div>
            </div>
          }
          {p.fields.map(f => f).filter((field, index) => index === p.selectedSpecIndex).map(field => (
            <div id="product-spec-page" data-testid="product-spec" key={field}>
              <Row className="record-tools">
                <div className="specRetail">
                  <Field name={`${field}.specComplete`} labelColSize={0} inputColSize={2} handleClick={p.onCheckboxClicked} />
                  <Field name={`${field}.retailRequired`} labelColSize={0} inputColSize={2} handleClick={p.onCheckboxClicked} />
                </div>
                <Col sm={6} className="specYear">
                  <div className="mfc-form-input">
                    <Dropdown
                      data={p.specYears}
                      value={[p.selectedSpec?.specYear]}
                      placeholder="Select a Spec Year"
                      onChange={p.onSpecYearChange}
                      updateLabel
                      block
                      testid="spec-years"
                    />
                  </div>
                  <div>
                    <Button
                      className="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary"
                      bsStyle="primary"
                      onClick={() => p.onDuplicateClicked(p.fields, p.selectedSpec, p.nextAvailableSpecYear)}
                    >
                      Duplicate
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="mfc-form-heading">Pot</div>
                <FormGroup> <Field name={`${field}.potId`} labelColSize={2} inputColSize={8} options={p.pots ?? []} loading={p.potsLoading} handleChange={v => p.onPotChange(field, v)}/> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Description"        value={p.selectedPotDetails?.description           } testid="pot-description"       /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Size"               value={p.selectedPotDetails?.size                  } testid="pot-size"              /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Color"              value={p.selectedPotDetails?.color                 } testid="pot-color"             /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier"           value={p.selectedPotDetails?.supplier        ?.name} testid="pot-supplier-name"     /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier Part #"    value={p.selectedPotDetails?.partNumber            } testid="pot-part-number"       /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Weights & Measures" value={p.selectedPotDetails?.weightAndMeasure      } testid="pot-weight-and-measure"/> </FormGroup>
              </Row>

              <Row>
                <div className="mfc-form-heading">Tray</div>
                <FormGroup> <Field name={`${field}.trayId`} labelColSize={2} inputColSize={8} options={p.trays ?? []} loading={p.traysLoading} handleChange={v => p.onTrayChange(field, v)}/> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Description"     value={p.selectedTrayDetails?.description      } testid="tray-description"  /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Size"            value={p.selectedTrayDetails?.size             } testid="tray-size"         /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Color"           value={p.selectedTrayDetails?.color            } testid="tray-color"        /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier"        value={p.selectedTrayDetails?.supplier   ?.name} testid="tray-supplier-name"/> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier Part #" value={p.selectedTrayDetails?.partNumber       } testid="tray-part-number"  /> </FormGroup>
              </Row>
              <Row>
                <div className="mfc-form-heading">Tag</div>
                <FormGroup> <Field name={`${field}.tagId`} labelColSize={2} inputColSize={8} options={p.tags ?? []} loading={p.tagsLoading} handleChange={v => p.onTagChange(field, v)}/> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Type"            value={p.selectedTagDetails?.tagType         } testid="tag-tag-type"     /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Size"            value={p.selectedTagDetails?.size            } testid="tag-size"         /> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier"        value={p.selectedTagDetails?.supplier  ?.name} testid="tag-supplier-name"/> </FormGroup>
                <FormGroup> <LabeledDisplay labelColSize={2} inputColSize={8} label="Supplier Part #" value={p.selectedTagDetails?.partNumber      } testid="tag-part-number"  /> </FormGroup>
              </Row>
              <Row>
                <div className="mfc-form-heading">Notes</div>
                <FormGroup> <Field name={`${field}.generalNotes`} labelColSize={2} inputColSize={8}/> </FormGroup>
                <FormGroup> <Field name={`${field}.decorationNotes`} labelColSize={2} inputColSize={8}/> </FormGroup>
              </Row>
            </div>
          ))}
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = (state, { formName }) => {
  const values = getFormValues(formName)(state) as {
    productSpecs: Array<{
      specYear: number,
      potId: number,
      trayId: number,
      tagId: number,
      generalNotes: string,
      decorationNotes: string,
    }>,
  };
  let selectedSpec = {} as Nil | ElementOf<typeof values.productSpecs>;
  let nextAvailableSpecYear = yearOf(Clock.today());
  const specYears = !values?.productSpecs ? [] : _.orderBy(values.productSpecs, ['specYear'], ['desc']).map(s => s.specYear);
  const currentSelectedSpecYear = state.record.getIn(['productSpec', 'selectedSpecYear']);
  const maxSpecYear = Math.max(...specYears);
  const selectedSpecYear = currentSelectedSpecYear && specYears.includes(currentSelectedSpecYear) ? currentSelectedSpecYear : maxSpecYear;
  nextAvailableSpecYear = Math.max(yearOf(Clock.today()) - 1, maxSpecYear) + 1;
  if (values?.productSpecs)
    selectedSpec = values.productSpecs.find(s => s.specYear === selectedSpecYear);

  return ({
    formName,
    specYears,
    nextAvailableSpecYear,
    currentSelectedSpecYear,
    selectedSpecIndex: Math.max(0, values.productSpecs.indexOf(selectedSpec!)),
    selectedSpec,
    selectedPotId: selectedSpec?.potId ,
    selectedTrayId: selectedSpec?.trayId,
    selectedTagId: selectedSpec?.tagId ,
  });
};

const mapDispatchToProps = (dispatch, { formName }) => ({
  onSpecYearChange :        value => dispatch(setSelectedSpecYear(value)),
  onPotChange      : (field, value) => dispatch(change(formName, `${field}.potId` ,  value)),
  onTrayChange     : (field, value) => dispatch(change(formName, `${field}.trayId`,  value)),
  onTagChange      : (field, value) => dispatch(change(formName, `${field}.tagId` ,  value)),
  onCheckboxClicked: ( name, value) => dispatch(change(formName,            name  , !value)),
  onDuplicateClicked: (fields, selectedSpec, newSpecYear) => {
    const newSpec = columns('productSpecs')
      .filter(col => col !== 'id' && col !== 'updatedAt' && col !== 'createdAt')
      .reduce((agg, col) => {
        const info = columnInfo('productSpecs', col);
        if (info.fkSpec && info.fkSpec.belongsTo)
          agg[info.fkSpec.nativeTableFK] = selectedSpec[info.fkSpec.nativeTableFK];
        else
          agg[col] = selectedSpec[col];

        return agg;
      }, {}) as shame;

    newSpec.specYear = newSpecYear;
    fields.push(newSpec);
    dispatch(setSelectedSpecYear(newSpec.specYear));
  },
});

const withRecordDetails = (table: string, field: string, detailColumns?: string[]) => msyncQuery<{ content: shame }>(gqlTag`
  query WithRecordDetails${_.upperFirst(_.camelCase(table))}($type: RecordType!, $id: Int!) {
    content: find(type: $type, id: $id) {
      id,
      ...${table}DetailFragment
    }
  }
  ${gqlTag`${buildFragment(table, detailColumns || getColumns(table), `${table}DetailFragment`)}`}
  `, {
    skip    : p => _.isNil(p[`${field}Id`]),
    options : p => ({ variables: { type: upperFirst(singular(table)), id: p[`${field}Id`] } }),
    props   : result => !result?.data?.content ? {} : { [`${field}Details`]: flattenFragment(result.data.content, table) },
  });

const fieldArray = formProps => <FieldArray name="productSpecs" component={ProductSpecFormGroup} {...formProps} />;
let intermediate = fieldArray as shame;
intermediate = withRecordDetails('tags', 'selectedTag')(intermediate);
intermediate = optionsContainerGenerator({ table: 'tags', columns: ['identifier', 'tagType', 'supplier', 'partNumber', 'size'] })(intermediate);
intermediate = withRecordDetails('trays', 'selectedTray')(intermediate);
intermediate = optionsContainerGenerator({ table: 'trays', columns: ['identifier', 'description', 'supplier', 'partNumber', 'size', 'color'] })(intermediate);
intermediate = withRecordDetails('pots', 'selectedPot')(intermediate);
intermediate = optionsContainerGenerator({ table: 'pots', columns: ['identifier', 'description', 'supplier', 'partNumber', 'size', 'color', 'weightAndMeasure'] })(intermediate);
intermediate = connect(mapStateToProps, mapDispatchToProps)(intermediate);
export const FormComponent = intermediate as typeof fieldArray;
