import { connect } from 'react-redux';
import { buildMapStateToPropsFunction } from 'client/containers/msync-redux';
import * as TablesState from 'client/state/tables';
import * as TableState from 'client/state/table';
import { sortNaturally } from 'shared/helpers/sort-naturally';
import { createSelector } from 'reselect';
import { SORT_TYPES } from 'shared/types';

/**
 * We're storing the sorted rows here because we need to re-use them when the user is editing a row
 * If we're always sorting the rows, the user could type something in, and then it would sort the rows immediately
 * which causes the row to disappear.
 * NOTE: This object gets closed over in the mapStateToProps.
 */
export const withClientSideSorting = <Props>(tableName: string, keyForRows: keyof Props, disableSortingOnEdit: boolean = false, lastSortField: string | undefined = undefined) => {
  const sortedRowsForTable: { [k: string]: any[] } = {};
  const tableModule = TablesState.buildTableStateModule(tableName);
  const sortRows = createSelector(
    (state: TableState.Type, props: Props) => props[keyForRows] as any as any[],
    (state: TableState.Type, props: Props) => tableModule.activeSortFields(state),
    (rows, sortInfos) => sortedRowsForTable[tableName] = sortNaturally(rows, [...sortInfos, ...(lastSortField ? [{ sortField: lastSortField, sortOrder: SORT_TYPES.ASC }] : [])])
  );

  return connect(buildMapStateToPropsFunction(tableModule.tableStateLens, (state: TableState.Type, props: Props) => ({
    [keyForRows]: disableSortingOnEdit && tableModule.editing(state).value
      ? sortedRowsForTable[tableName]
      : sortRows(state, props),
  })));
};
