import * as React from 'react';
import { ControlLabel, FormControl, HelpBlock } from 'client/components/third-party';
import { DateStr, toDateStr } from 'shared/types';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';
import { DatePicker } from 'client/components/datepicker';

interface Props extends ReduxFormProps {
  label: string;
  placeholder?: string;
  disabled: boolean;
  testid: string;
  labelColSize: number;
  inputColSize: number;
  offset: number;
  horizontalLabel?: boolean;
  required: boolean;
  disabledDates?: DateStr[];
  alwaysShowErrors?: boolean;
  hideOptionalLabel?: boolean;
  autoFocus?: boolean;
}

interface ReduxFormProps {
  input: {
    name: string;
    onChange: (x: any) => any;
    value: string | DateStr | null;
  };
  meta: {
    touched: boolean;
    error: string;
    submitting: boolean;
  };
}

export default function LabeledDate(props: Props) {
  const {
    label,
    disabled,
    testid,
    labelColSize,
    inputColSize,
    offset,
    horizontalLabel,
    required,
    placeholder,
    disabledDates,
    meta: { touched, error },
    input: { value, onChange, name },
  } = props;

  const date = value && toDateStr(value) || null;
  const labelClassName = required ? ['required'] : props.hideOptionalLabel ? [] : ['optional'];
  const picker = (
    <DatePicker
      testid={testid}
      name={name}
      value={date}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      disabledDates={disabledDates}
      autoFocus={props.autoFocus}
    />
  );

  const shouldDisplayError = !props.meta.submitting && props.meta.error && (props.meta.touched || props.alwaysShowErrors);

  return horizontalLabel
    ? (
      <HorizontalLayout labelComponent={ControlLabel} labelColSize={labelColSize} offset={offset} label={label} inputColSize={inputColSize} classNames={labelClassName}>
        {picker}
        {touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
      </HorizontalLayout>
    ) : (
      <StackedLayout inputColSize={inputColSize} offset={offset} label={label} classNames={labelClassName}>
        {picker}
        {touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
      </StackedLayout>
    );
}
