import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import * as TableActions from 'client/actions/table';

import { propToComponent } from 'client/hoc/hoc';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableFilter, ApolloRefetch, ActiveFilter, AvailableSearchField, ActiveSearch } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { RoutePlanDeliveryDate, RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import * as Actions from 'client/actions/route-plan';
import { GetRoutePlanAttachedCustomerOrdersInput, RoutePlanAttachedCustomerOrderGQLResult, RoutePlanAttachedCustomerOrderSortInput, RoutePlanAttachedCustomerOrdersFilterInput } from 'schema/route-plan-attached-customer-orders/route-plan-attached-customer-orders-typescript-types';
import { RoutePlanAttachedCustomerOrdersQuery, RoutePlanAttachedCustomerOrderResponse, DetachCustomerOrderFromRoutePlanMutation, WithRoutePlanStatus, RoutePlanStatusQueryProps } from 'client/app/transportation/routing/attached-customer-orders-table/attached-customer-orders-table-query';
import * as _ from 'lodash';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { Type as RoutePlanState } from 'client/state/route-plan';
import { TYPES } from 'shared/types';
import { CustomerId } from 'shared/schemas/customer';
import { MfcAreaIdentifier, MfcAreaId } from 'shared/schemas/mfc-area';
import { getFormValues } from 'redux-form';
import { TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME } from 'client/constants';
import { addDays } from 'shared/helpers/date-helpers';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { routePlanStatusLocked } from 'shared/helpers/route-plan';
import { mapToSort, mapToFilters } from 'client/helpers/table-helpers';
import { setRecordBarGoBackToLocation } from 'client/actions/navigation';
import { FetchPolicy } from 'apollo-client';
import { routePlanFormName } from '../helpers';
import { RoutePlanSectionNames } from '../route-plan-section-names';
import { buildTableStateModule } from 'client/state/tables';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  routePlanId?: RoutePlanId;
  type: 'Attach' | 'Attached';
  tableName: string;
  disableCreate?: boolean;
  tablePaginated?: boolean;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  attachOrdersModalShown: boolean;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
  customerId?: CustomerId;
  routePlanDeliveryDate?: RoutePlanDeliveryDate;
  mfcAreaId?: MfcAreaId;
  sellDepartmentId?: SellDepartmentId;
  disableCreate: boolean;
  detachOptionDisabled: boolean;
  searchableFields: AvailableSearchField[];
  activeSearch: ActiveSearch;
}

interface MfcAreaIdentifierStateProps {
  mfcAreaIdentifier?: MfcAreaIdentifier;
}

interface WithDetachCustomerOrderFromRoutePlanProps {
  detachCustomerOrderFromRoutePlan: (customerOrderIds: CustomerOrderId[], refetchTable: ApolloRefetch) => Promise<CustomerOrderId[]>;
}

interface FormValues {
  customerId?: CustomerId;
  mfcAreaId?: MfcAreaId;
  deliveryDate?: RoutePlanDeliveryDate;
  sellDepartmentId?: SellDepartmentId;
}
interface DispatchProps {
  mfcAreaIdentifier?: MfcAreaIdentifier;
  onAttachOrdersClicked(customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier): void;
  onRowSelect: (routePlanId?: RoutePlanId) => (record: { id: number }) => void;
}

interface WithAttachedCustomerOrderRowProps {
  attachedCustomerOrderRows?: RoutePlanAttachedCustomerOrderGQLResult[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  refetchTable?: ApolloRefetch;
  availableFilters: AvailableFilter[];
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  MfcAreaIdentifierStateProps &
  WithAttachedCustomerOrderRowProps &
  WithDetachCustomerOrderFromRoutePlanProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

// A builder function allows us to use this table in two places
export function buildCustomerOrdersTable<P>(buildTableArgs: { component: new (props: P) => React.Component<P, any>, isInModal: boolean, alias: 'attached' | 'attach' }) {
  const formName = routePlanFormName(RoutePlanSectionNames.AttachOrders);

  const mapStateToProps = (state: { routePlan: RoutePlanState; tables: shame }, ownProps: OwnProps & RoutePlanStatusQueryProps): StateProps => {
    const values = getFormValues(formName)(state) as FormValues;
    const disabled = _.isNil(ownProps.routePlanId) || routePlanStatusLocked(ownProps.status) || (!values || !values.customerId);
    const TableStateHelpers = buildTableStateModule(ownProps.tableName);

    return {
      ...TableStateHelpers.commonTableProps(state as shame),
      attachOrdersModalShown: state.routePlan.attachOrdersModalShown,
      customerId: values ? values.customerId : undefined,
      mfcAreaId: values ? values.mfcAreaId : undefined,
      routePlanDeliveryDate: values ? values.deliveryDate : undefined,
      sellDepartmentId: values ? values.sellDepartmentId : undefined,
      disableCreate: (ownProps.hasOwnProperty('disableCreate') && ownProps.disableCreate) || disabled,
      detachOptionDisabled: disabled,
      searchableFields: [
        {
          id: 'identifier',
          name: 'PO #',
        },
      ],

    };
  };

  const mfcAreaFilters = (ownProps: StateProps) => {
    const filters: ActiveFilter[] = [];
    if (ownProps.customerId) {
      filters.push({ field: 'customer', values: [ownProps.customerId] });
    }
    if (ownProps.sellDepartmentId) {
      filters.push({ field: 'sellDepartment', values: [ownProps.sellDepartmentId] });
    }
    return filters;
  };

  const withMfcAreaOptions = optionsContainerWithFilters({ resultPropName: 'mfcAreaOptions', table: 'mfcAreas', columns: ['identifier'], getFilters: mfcAreaFilters, skip: (ownProps: StateProps) => !ownProps.customerId || !ownProps.sellDepartmentId });

  const mapMfcAreaFromStateToProps = (state: any, ownProps: { mfcAreaId?: MfcAreaId, mfcAreaOptions?: Array<{ id: number, identifier: string; }> }): MfcAreaIdentifierStateProps => {
    const mfcArea = ownProps.mfcAreaId
      ? (ownProps.mfcAreaOptions || []).find(mfcAreaOption => mfcAreaOption.id === ownProps.mfcAreaId)
      : undefined;

    return {
      mfcAreaIdentifier: mfcArea ? mfcArea.identifier : undefined,
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onAttachOrdersClicked: (customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier) => {
      dispatch(Actions.showAttachOrdersModal());
      dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'customerId', [`${customerId}`]));
      dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'mfcAreaIdentifier', mfcAreaIdentifier ? [`${mfcAreaIdentifier}`] : []));
      if (routePlanDeliveryDate) {
        dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'deliveryDate', [{ startDate: addDays(routePlanDeliveryDate, -1), endDate: addDays(routePlanDeliveryDate, 1) }], TYPES.DATE));
      }
    },
    onRowSelect: (routePlanId?: RoutePlanId) => (record: { id: number }) => {
      dispatch(setRecordBarGoBackToLocation(`/transportation/routing/details/${routePlanId}/attach-orders`));
      dispatch(push(`/orders/customer/details/${record.id}`));
    },
  });

  const WithAttachedCustomerOrderRows = msyncQuery<RoutePlanAttachedCustomerOrderResponse, OwnProps & StateProps & WithTableParentProps, WithAttachedCustomerOrderRowProps, GetRoutePlanAttachedCustomerOrdersInput>(RoutePlanAttachedCustomerOrdersQuery, {
    alias: buildTableArgs.alias,
    skip(ownProps: OwnProps & StateProps & WithTableParentProps) {
      if (ownProps.type === 'Attached') {
        return false;
      }

      if (!ownProps.tableParentInfo || !ownProps.tableParentInfo.rowsPerPage) {
        return false;
      }

      return false;
    },
    options(ownProps): { fetchPolicy: FetchPolicy, variables: GetRoutePlanAttachedCustomerOrdersInput } {
      return {
        fetchPolicy: 'network-only',
        variables: {
          sort: mapToSort<RoutePlanAttachedCustomerOrderSortInput>(ownProps.activeSortFields),
          filters: mapToFilters<RoutePlanAttachedCustomerOrdersFilterInput>(ownProps.activeFilters),
          routePlanId: ownProps.routePlanId,
          search: ownProps.activeSearch,
          ...(ownProps.type === 'Attach' ? { limit: ownProps.tableParentInfo.rowsPerPage } : {}),
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        } as GetRoutePlanAttachedCustomerOrdersInput,
      };
    },
    props(props): WithAttachedCustomerOrderRowProps {
      const { data } = props;
      const workbookName = `RoutePlansAttachedOrders`;
      const attachedCustomerOrderRows = data.getRoutePlanAttachedCustomerOrders ? data.getRoutePlanAttachedCustomerOrders.routePlanAttachedCustomerOrders || [] : [];

      return {
        attachedCustomerOrderRows,
        loading: _.isNil(data.loading) ? true : data.loading,
        filteredRecordIds: data.getRoutePlanAttachedCustomerOrders ? data.getRoutePlanAttachedCustomerOrders.ids : [],
        totalCount: data.getRoutePlanAttachedCustomerOrders ? data.getRoutePlanAttachedCustomerOrders.totalCount : 0,
        totalUnfilteredCount: data.getRoutePlanAttachedCustomerOrders ? data.getRoutePlanAttachedCustomerOrders.totalUnfilteredCount : 0,
        ...(props.ownProps.type === 'Attached' ? { refetchTable: data.refetch } : {}),
        dataRequest: {
          operationName: '',
          query: '',
          variables: {
          },
          workbookName,
        },
        availableFilters: [
          {
            displayName: 'Customer',
            field: 'customerId',
            options: data.getRoutePlanAttachedCustomerOrdersFilterOptions ? data.getRoutePlanAttachedCustomerOrdersFilterOptions.customers : [],
          },
          {
            displayName: 'MFC Area',
            field: 'mfcAreaIdentifier',
            options: data.getRoutePlanAttachedCustomerOrdersFilterOptions ? data.getRoutePlanAttachedCustomerOrdersFilterOptions.mfcAreas : [],
          },
          {
            displayName: 'Status',
            field: 'status',
            options: data.getRoutePlanAttachedCustomerOrdersFilterOptions ? data.getRoutePlanAttachedCustomerOrdersFilterOptions.statuses : [],
          },
          {
            displayName: 'Order Type',
            field: 'orderType',
            options: data.getRoutePlanAttachedCustomerOrdersFilterOptions ? data.getRoutePlanAttachedCustomerOrdersFilterOptions.orderTypes : [],
          },
          {
            displayName: 'Delivery Date',
            field: 'deliveryDate',
            type: TYPES.DATE,
          },
        ],
      };
    },
  });

  const WithDetachCustomerOrderFromRoutePlan = msyncMutation<{}, OwnProps, shame>(DetachCustomerOrderFromRoutePlanMutation, {
    alias: 'detachCustomerOrderFromRoutePlan',
    props: props => ({
      detachCustomerOrderFromRoutePlan: async (customerOrderIds: CustomerOrderId[], refetchTable: ApolloRefetch) => {
        await props.mutate({ variables: { customerOrderIds } });
        await refetchTable();
      },
    }),
  });

  const component = flowRight(
    tableParentHoc({ isInModal: buildTableArgs.isInModal }),
    WithRoutePlanStatus,
    connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
    WithAttachedCustomerOrderRows,
    WithDetachCustomerOrderFromRoutePlan,
    withMfcAreaOptions,
    connect(mapMfcAreaFromStateToProps),
  )(buildTableArgs.component) as Component<OwnProps>;

  return (props: OwnProps) => propToComponent(component, props);
}
