import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { HmgOpsUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as SharedActions from '../shared/actions';
import { DateStr, ImmutableDateRange, SelectableValue } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import * as Queries from '../shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm } from 'client/components/report-user-params/reporting-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerIds: SelectableValue | undefined;
  supplierId: number | undefined;
  supplierOrderIds: SelectableValue | undefined;
  downloadButtonDisabled: boolean;
  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;
  reportType: Constants.HmgOpsReportType;
  reportDownloadStatus: MutationStatus;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const reportType = Selectors.getHmgOpsReportType(state);

  if (!formValues) {
    return {
      customerIds: undefined,
      supplierId: undefined,
      supplierOrderIds: undefined,
      downloadButtonDisabled: true,
      beginDate: undefined,
      endDate: undefined,
      reportType,
      reportDownloadStatus: MutationStatus.Initial,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);

  return {
    customerIds: formValues[Constants.FormFields.customerIds],
    supplierId: formValues[Constants.FormFields.supplierId],
    supplierOrderIds: formValues[Constants.FormFields.supplierOrderIds],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    beginDate: formValues[Constants.FormFields.beginDate],
    endDate: formValues[Constants.FormFields.endDate],
    reportType,
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(): Thunker;
  handleReportTypeChanged: (key: Constants.HmgOpsReportType) => void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => Thunker;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleReportTypeChanged: Actions.handleReportTypeChanged,
  handleDateRangeChanged: SharedActions.handleDateRangeChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  Queries.CustomerQueryProps &
  SharedQueries.SupplierQueryProps &
  Queries.SupplierOrderQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: { },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withCustomers,
  SharedQueries.withSuppliers,
  Queries.withSupplierOrders,
  UserParamsMappers.withHmgOpsReportUserParamMappers,
)(HmgOpsUI) as Component<OwnProps>;

export const HmgOps = (props: OwnProps) => propToComponent(component, props);
