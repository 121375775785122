import * as _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { propToComponent, withPropsInjector } from 'client/hoc/hoc';
import { TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME } from 'client/constants';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { ActiveSort, ApolloRefetch, ActiveSearch, AvailableSearchField } from 'client/types';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { RoutingLoadSortInput, RoutingLoadId, RoutingLoadSearchInput, GetRoutingLoadsInput } from 'schema/routing-load/routing-load-graphql-types';
import { RoutePlanLoadsTableQuery, RoutingLoadQueryResponse, RoutePlanLoadsExcelQuery, GetRoutePlanIdentifierForExcelDownload, RoutePlanQueryResponse } from 'client/app/transportation/routing/route-plan-details/loads/routing-loads-query';
import { LoadsTableUI, LoadsTableRow } from 'client/app/transportation/routing/route-plan-details/loads/loads-table-ui';
import { RoutePlanId, RoutePlanIdentifier, RoutePlanStatus } from 'schema/route-plan/route-plan-typescript-types';
import { withDownloadCsvAction, withExtractAndImportButtonDisabledStates } from 'client/app/transportation/routing/load-actions';
import { WithExtractRoutePlanMutation } from 'client/app/transportation/routing/sidebar/route-plan-mutation';
import { mapToSort, convertToTypedSearchCriteriaForTransit } from 'client/helpers/table-helpers';
import * as ImportRouteActions from 'client/actions/import-route';
import * as RoutePlanPrintingActions from 'client/actions/route-plan-printing';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import * as MutationActions from 'client/actions/mutations';
import { FetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';
import { SEARCH_FIELD_ANY } from 'shared/types';

const tableName = TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME;
interface OwnProps {
  routePlanId: RoutePlanId | undefined;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  tablePageNumber: number;
  selectedRoutingLoadIds: RoutingLoadId[];
}
const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state): StateProps => {
  return {
    ...TableStateHelpers.commonTableProps(state),
    selectedRoutingLoadIds: RoutePlanPrintingSelectors.selectedRoutingLoadIds(state),
  };
};

interface WithSearchableFieldsProps {
  searchableFields: AvailableSearchField[];
}

const withSearchableFields = withPropsInjector({
  searchableFields: [
    {
      id: SEARCH_FIELD_ANY,
      name: 'Search All',
    },
    {
      id: 'storeIdentifiers',
      name: 'Store',
    },
  ] as AvailableSearchField[],
});

interface DispatchProps {
  onRowSelect(record): void;
  onImportRouteFileButtonClicked(id: RoutePlanId): void;
  onCreateRoutePacketMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadDittyCardMenuItemClicked(routingLoadIds: RoutingLoadId[]): void;
  loadsListTableUnmounted(): void;
  showBusyModal(): void;
  hideBusyModal(): void;
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    onRowSelect(record) {
      dispatch(push(`/transportation/loads/details/${record.id}`));
    },
    onImportRouteFileButtonClicked(id: RoutePlanId) {
      dispatch(ImportRouteActions.importRouteFileButtonClicked(id));
    },
    onCreateRoutePacketMenuItemClicked(routingLoadIds: RoutingLoadId[]) {
      dispatch(RoutePlanPrintingActions.createRoutePacketMenuItemClicked(routingLoadIds));
    },
    onDownloadDittyCardMenuItemClicked(routingLoadIds: RoutingLoadId[]) {
      dispatch(RoutePlanPrintingActions.downloadDittyCardMenuItemClicked(routingLoadIds));
    },
    loadsListTableUnmounted() {
      RoutePlanPrintingActions.loadsListTableUnmounted(dispatch);
    },
    showBusyModal: () => dispatch(MutationActions.showBusyModal()),
    hideBusyModal: () => dispatch(MutationActions.hideBusyModal()),
  };
};

interface WithRoutingLoadRowProps {
  rows?: LoadsTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

interface WithRoutePlanIdentifierProps {
  routePlanIdentifier: RoutePlanIdentifier;
  status: RoutePlanStatus | '';
  refetch: ApolloRefetch;
}

const WithRoutePlanIdentifierAndStatus = msyncQuery<RoutePlanQueryResponse, OwnProps & StateProps & WithTableParentProps, WithRoutePlanIdentifierProps>(GetRoutePlanIdentifierForExcelDownload, {
  alias: 'WithRoutePlanIdentifierAndStatus',
  skip(ownProps) {
    return !ownProps.routePlanId;
  },
  options(ownProps): { variables: shame } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): WithRoutePlanIdentifierProps {
    const { data } = props;
    const { routePlanResult } = data;

    return {
      routePlanIdentifier: routePlanResult ? routePlanResult.routePlan.identifier : '',
      status: routePlanResult ? routePlanResult.routePlan.status : '',
      refetch: data.refetch,
    };
  },
});

const WithRoutingLoadRows = msyncQuery<RoutingLoadQueryResponse, OwnProps & StateProps & WithTableParentProps & WithSearchableFieldsProps & WithRoutePlanIdentifierProps, WithRoutingLoadRowProps>(RoutePlanLoadsTableQuery, {
  alias: 'withRoutingLoadRows',
  skip(ownProps: OwnProps & StateProps & WithTableParentProps) {
    return !ownProps.tableParentInfo.rowsPerPage || !ownProps.routePlanId;
  },
  options(ownProps): { variables: GetRoutingLoadsInput, fetchPolicy: FetchPolicy } {
    if (!ownProps.routePlanId) {
      throw new Error('No route plan, query should have been skipped');
    }
    return {
      fetchPolicy: 'network-only',
      variables: {
        sort: mapToSort<RoutingLoadSortInput>(ownProps.activeSortFields),
        search: convertToTypedSearchCriteriaForTransit<RoutingLoadSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        scope: {
          routePlanId: ownProps.routePlanId,
        },
      },
    };
  },
  props(props): WithRoutingLoadRowProps {
    const { data } = props;
    const { ownProps } = props;

    const workbookName = `Route Plan ${ownProps.routePlanIdentifier} - Loads`;
    const rows: LoadsTableRow[] = data.getRoutingLoads
      ? data.getRoutingLoads.routingLoads.map(gqlResult => {
        return {
          id: gqlResult.id,
          identifier: gqlResult.identifier,
          loadType: gqlResult.loadType,
          customerNames: gqlResult.customerNames,
          mfcAreaIdentifiers: gqlResult.mfcAreaIdentifiers,
          routeType: gqlResult.routeType,
          carrier: gqlResult.carrier,
          milesCombinedWithAdditionalMiles: _.round(gqlResult.milesCombinedWithAdditionalMiles),
          dropsCombinedWithAdditionalDrops: gqlResult.dropsCombinedWithAdditionalDrops || 0,
          racks: gqlResult.racks,
          pallets: gqlResult.pallets,
          trailerNumber: gqlResult.trailerNumber,
          peakOrderNumber: gqlResult.peakOrderNumber,
          totalFee: gqlResult.totalFee,
        };
      })
      : [];

    return {
      rows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getRoutingLoads ? data.getRoutingLoads.ids : [],
      totalCount: data.getRoutingLoads ? data.getRoutingLoads.totalCount : 0,
      totalUnfilteredCount: data.getRoutingLoads ? data.getRoutingLoads.totalUnfilteredCount : 0,
      dataRequest: {
        operationName: '',
        query: RoutePlanLoadsExcelQuery,
        variables: {
          sort: mapToSort<RoutingLoadSortInput>(ownProps.activeSortFields),
          search: convertToTypedSearchCriteriaForTransit(ownProps.activeSearch, ownProps.searchableFields),
          scope: {
            routePlanId: ownProps.routePlanId,
          },
        },
        workbookName,
      },
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithRoutingLoadRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  tableParentHoc(),
  withSearchableFields,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(tableName),
  WithRoutePlanIdentifierAndStatus,
  WithRoutingLoadRows,
  WithExtractRoutePlanMutation,
  withDownloadCsvAction,
  withExtractAndImportButtonDisabledStates,
)(LoadsTableUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
