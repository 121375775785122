import { appendFieldValidators } from 'shared/schemas/dsl';
import { CustomerOrder } from 'shared/schemas/customer-order';
import * as Validators from 'shared/validators';
import { OrderMethod, ShippingUnitType, CustomerOrderType, CustomerOrderStatus } from 'shared/types';

appendFieldValidators(CustomerOrder, {
  orderMethod: [Validators.INCLUSION(OrderMethod)],
  shippingUnitType: [Validators.INCLUSION(ShippingUnitType)],
  orderType: [Validators.INCLUSION(CustomerOrderType)],
  orderStatus: [Validators.INCLUSION([CustomerOrderStatus.Draft, CustomerOrderStatus.Approved, 'Routed'])],
  identifier: [
    Validators.REQUIRED_IF_EDITING,
  ],
});
