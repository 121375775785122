import * as State from 'client/state/load-list';
import { ActionTypes, ActionTypeKeys } from 'client/actions/load-list';
import * as R from 'ramda';
import { Carrier } from 'shared/types';

type LoadListState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): LoadListState {
  switch (action.type) {
    case ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_MODAL_VISIBILITY: {
      return R.pipe(
        State.applyInvoiceToLoadsModalShown.set(action.payload.showModal),
        State.routingLoadIds.set(action.payload.routingLoadIds),
        State.selectedCarrier.set(Carrier.PEAK),
        State.enteredInvoiceNumber.set(''),
      )(state);
    }
    case ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_CARRIER_CHANGED:
      return State.selectedCarrier.set(state, action.payload.carrier);
    case ActionTypeKeys.LOAD_LIST_APPLY_INVOICE_TO_LOADS_INVOICE_NUMBER_CHANGED:
      return State.enteredInvoiceNumber.set(state, action.payload.invoiceNumber);
    case ActionTypeKeys.LOAD_LIST_ASSIGN_LOADS_TO_TRAILERS_MODAL_VISIBILITY:
      return R.pipe(
        State.assignLoadsToTrailersModalShown.set(action.payload.showModal),
        State.routingLoadIds.set(action.payload.routingLoadIds),
        action.payload.showModal ? R.identity : State.assignedTrailerNumbers.set({}),
      )(state);
    case ActionTypeKeys.LOAD_LIST_SET_TRAILER_NUMBER_ON_LOADS:
      return State.assignedTrailerNumbers.update(state, currentValue => {
        return {
          ...currentValue,
          [action.payload.routingLoadId]: action.payload.trailerNumber,
        };
      });
    case ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_MODAL_VISIBILITY:
      return R.pipe(
        State.notifyPeakModalShown.set(action.payload.showModal),
        State.routingLoadIds.set(action.payload.routingLoadIds),
        action.payload.showModal ? R.identity : State.checkedLoadNumbers.set({}),
      )(state);
    case ActionTypeKeys.LOAD_LIST_NOTIFY_PEAK_CHANGES_CHECKBOX_CLICKED:
    return State.checkedLoadNumbers.update(state, currentValue => {
      return {
        ...currentValue,
        [action.payload.routingLoadId]: action.payload.checked,
      };
    });
  default:
      return state;
  }
}
