import { Lens } from 'client/utils/lenses';
import * as State from 'client/state/state';

// List State
export type InvoiceStateType = InvoiceState;

interface InvoiceState {
  readonly isAddRoutePlansModalShown: boolean;
  readonly isAddVendorChargebackLineItemsModalShown: boolean;
  readonly isSendInvoicesToAcumaticaModalShown: boolean;
  readonly isSendInvoicesViaEdiModalShown: boolean;
  readonly selectedRecordIds: number[];
  readonly isEmailInvoiceModalShown: boolean;
}

export const InitialState: InvoiceState = {
  isAddVendorChargebackLineItemsModalShown: false,
  isAddRoutePlansModalShown: false,
  isSendInvoicesToAcumaticaModalShown: false,
  isSendInvoicesViaEdiModalShown: false,
  selectedRecordIds: [],
  isEmailInvoiceModalShown: false,
};

export const invoiceStateLens = Lens.from<State.Type>().prop('invoice');
export const isSendInvoicesToAcumaticaModalShown = Lens.from<InvoiceState>().prop('isSendInvoicesToAcumaticaModalShown');
export const isSendInvoicesViaEdiModalShown = Lens.from<InvoiceState>().prop('isSendInvoicesViaEdiModalShown');
export const selectedRecordIds = Lens.from<InvoiceState>().prop('selectedRecordIds');
export const isEmailInvoiceModalShown = Lens.from<InvoiceState>().prop('isEmailInvoiceModalShown');
export const isAddRoutePlansModalShownLens = Lens.from<InvoiceState>().prop('isAddRoutePlansModalShown');
export const isAddVendorChargebackLineItemsModalShownLens = Lens.from<InvoiceState>().prop('isAddVendorChargebackLineItemsModalShown');
