import gql from 'graphql-tag';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { BolId } from 'shared/schemas/bol';
import { SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { CartTrackingSupplierEntryId } from 'shared/schemas/cart-tracking-supplier-entry';
import { ReceivableOrderReceivingStatus } from 'shared/types';

export interface RemoveBolVariables {
  input: {
    id: BolId;
    receivableOrderId: number;
  };
}

export const RemoveBolMutation = gql`
  mutation removeBol($input: RemoveBolInput!) {
    response: removeBols(input: $input) {
      receivableOrder {
        id
        receivingStatus
        bols {
          id
        }
        supplier {
          id
          cartTrackingSupplierEntries {
            id
          }
        }
        details {
          id
          receivableOrderType
          packQuantityReceivedTotal
          shippingUnitQuantityReceivedTotal
        }
      }
    }
  }
`;

export interface RemoveBolResponse {
  response: {
    receivableOrder: {
      id: SupplierOrderId;
      receivingStatus: ReceivableOrderReceivingStatus;
      bols: Array<{
        id: BolId;
      }>;
      supplier?: {
        id: SupplierId;
        cartTrackingStatus: SupplierCartTrackingStatus;
        cartTrackingSupplierEntries: Array<{
          id: CartTrackingSupplierEntryId;
        }>;
      };
      details: Array<{
        id: number;
        packQuantityReceivedTotal: Int;
        shippingUnitQuantityReceivedTotal: number;
      }>;
    };
  };
}
