import { connect } from 'react-redux';
import gql from 'graphql-tag';

import * as Actions from 'client/actions/supplier-order';
import { propToComponent } from 'client/hoc/hoc';
import SendSupplierOrderModal from './send-supplier-order-modal';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SupplierOrderId, SupplierOrderIdentifier, SupplierOrderPlannedArrivalDate } from 'shared/schemas/supplier-order';
import { MfcAreaId, MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { CustomerId, CustomerName } from 'shared/schemas/customer';
import { SupplierId, SupplierName } from 'shared/schemas/supplier';
import { flowRight } from 'lodash';
import { SupplierContact } from 'shared/schemas/supplier-contact';
import { Saved } from 'shared/schemas/record';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { MutationStatus } from 'client/actions/mutations';
import * as State from 'client/state/state';
import { SupplierOrderEmailRecipient } from 'client/state/supplier-order';
import { createSelector } from 'reselect';
import { SupplierOrderDetailsStats } from 'shared/types';

const formName = 'send-supplier-po';

interface OwnProps {
  supplierOrderId: number;
  isModal?: boolean;
  supplierOrderDetailsStats: SupplierOrderDetailsStats;
}

interface StateProps {
  isShown: boolean;
  recipients: SupplierOrderEmailRecipient[];
  selectAllSelected: boolean;
  actionStatus: MutationStatus;
  disableSubmitButton: boolean;
  cancelDisabled: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps & SendSupplierOrderModalInitialValues): StateProps => {
  const recipients = state.supplierOrder.supplierOrderContacts;
  return {
    isShown: state.supplierOrder.sendSupplierPOModalShown,
    recipients: (recipients && recipients.length > 0) ? recipients : ownProps.initialRecipients,
    selectAllSelected: State.isSendSupplierOrderSelectAllSelected(state),
    actionStatus: state.supplierOrder.sendSupplierOrderActionStatus,
    disableSubmitButton: recipients.filter(c => c.selected).length === 0,
    cancelDisabled: state.supplierOrder.sendSupplierOrderActionStatus === MutationStatus.InProgress,
  };
};

interface DispatchProps {
  handleCancelButtonClicked: () => void;
  handleSubmit: () => Promise<any>;
  handleRecipientClicked: (supplierContactId: number) => void;
  setInitalSupplierContacts: () => void;
  handleSelectAllClicked: () => void;
}

const dispatchFunctions = createSelector(
  [
    (props: shame) => props.dispatch,
    (props: OwnProps & StateProps & MutationProps & SendSupplierOrderModalInitialValues) => props.sendSupplierOrderToSuppliers,
    (props: OwnProps & StateProps & MutationProps & SendSupplierOrderModalInitialValues) => props.supplierOrderId,
    (props: OwnProps & StateProps & MutationProps & SendSupplierOrderModalInitialValues) => props.recipients,
    (props: OwnProps & StateProps & MutationProps & SendSupplierOrderModalInitialValues) => props.initialRecipients,
  ],
  (dispatch, sendSupplierOrderToSuppliers: shame, supplierOrderId: shame, recipients: shame, initialRecipients: shame) => {
    return {
      handleCancelButtonClicked: () => dispatch(Actions.setSendSupplierPOModalVisibility(false)),
      handleSubmit: async () => {
        dispatch(Actions.setSendSupplierOrderActionStatus(MutationStatus.InProgress));
        try {
          await sendSupplierOrderToSuppliers({ supplierOrderId, recipients });
          dispatch(Actions.setSendSupplierPOModalVisibility(false));
        } finally {
          dispatch(Actions.setSendSupplierOrderActionStatus(MutationStatus.Initial));
        }
      },
      setInitalSupplierContacts: () => dispatch(Actions.setSupplierOrderContacts(initialRecipients)),
      handleRecipientClicked: (supplierContactId: number) => {
        dispatch(Actions.supplierOrderEmailRecipientCheckboxClicked(supplierContactId));
      },
      handleSelectAllClicked: () => dispatch(Actions.selectAllClicked()),
    };
  },
);

const mapDispatchToProps = (dispatch, ownProps: OwnProps & StateProps & MutationProps & SendSupplierOrderModalInitialValues): DispatchProps => {
  return dispatchFunctions(Object.assign({}, ownProps, { dispatch }));
};

const withModalProps = WrappedComponent => (props: OwnProps & StateProps & DispatchProps) => {
  const modalProps = {
    ...props,
    title: 'Send Supplier PO',
    formName,
    id: 'send-supplier-po-modal',
    totalCost: props.supplierOrderDetailsStats ? props.supplierOrderDetailsStats.totalCost : undefined,
    handleRecipientClicked: props.handleRecipientClicked,
    handleSubmit: props.handleSubmit,
    actionStatus: props.actionStatus,
  };
  return propToComponent(WrappedComponent, modalProps);
};

const MUTATION = gql`
mutation emailSupplierOrder($supplierOrderId: Int!, $supplierContactIds: [Int]!) {
  emailSupplierOrder(supplierOrderId: $supplierOrderId, supplierContactIds: $supplierContactIds) {
    supplierOrder {
      id
      lastModifiedAt
      orderStatus
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  }
}
`;

interface MutationProps {
  sendSupplierOrderToSuppliers: (args: { supplierOrderId: SupplierOrderId, recipients: SupplierOrderEmailRecipient[] }) => shame; // Promise<ApolloQueryResult<shame>>;
}

const withMutation = msyncMutation(MUTATION, {
  props: ({ mutate }): MutationProps => {
    return {
      sendSupplierOrderToSuppliers: (args: { supplierOrderId: SupplierOrderId, recipients: SupplierOrderEmailRecipient[] }) => mutate({
        variables: {
          supplierOrderId: args.supplierOrderId,
          supplierContactIds: args.recipients.filter(contact => contact.selected).map(contact => contact.supplierContactId),
        },
      }),
    };
  },
});

const QUERY = gql`
  query sendSupplierOrderQuery($type: RecordType = SupplierOrder, $id: Int!) {
    supplierOrder: find(type: $type, id: $id) {
      ... on SupplierOrder {
        id
        identifier
        plannedArrivalDate
        supplier {
          id
          name
          contacts {
            id
            name
            email
            primary
          }
        }
        customer {
          id
          name
        }
        mfcArea {
          id
          identifier
        }
      }
    }
  }
`;

interface InitialValuesQueryResponse {
  supplierOrder: {
    id: SupplierOrderId;
    identifier: SupplierOrderIdentifier;
    plannedArrivalDate: SupplierOrderPlannedArrivalDate;
    supplier: {
      id: SupplierId;
      name: SupplierName;
      contacts: Array<Pick<Saved<SupplierContact>, 'id' | 'name' | 'email' | 'primary'>>
    }
    customer: {
      id: CustomerId;
      name: CustomerName;
    }
    mfcArea?: {
      id: MfcAreaId;
      identifier: MfcAreaIdentifier;
    }
  };
}

export interface SendSupplierOrderModalInitialValues {
  supplierOrderIdentifier?: SupplierOrderIdentifier;
  supplierName?: SupplierName;
  customerName?: CustomerName;
  mfcArea?: MfcAreaIdentifier;
  arrivalDate?: SupplierOrderPlannedArrivalDate;
  initialRecipients: SupplierOrderEmailRecipient[];
}

const withInitialValues = msyncQuery<InitialValuesQueryResponse, OwnProps & StateProps, SendSupplierOrderModalInitialValues>(QUERY, {
  skip(ownProps) {
    return ownProps.supplierOrderId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        id: ownProps.supplierOrderId,
      },
    };
  },
  props({ data, ownProps }): SendSupplierOrderModalInitialValues {
    if (data.loading || !data.supplierOrder) {
      return {
        initialRecipients: [],
      };
    }

    return {
      supplierOrderIdentifier: data.supplierOrder.identifier,
      supplierName: data.supplierOrder.supplier.name,
      customerName: data.supplierOrder.customer.name,
      mfcArea: data.supplierOrder.mfcArea && data.supplierOrder.mfcArea.identifier,
      arrivalDate: data.supplierOrder.plannedArrivalDate,
      initialRecipients: data.supplierOrder.supplier.contacts.map(c => ({
        supplierContactId: c.id,
        name: c.name,
        selected: c.primary,
      })),
    };
  },
});

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  withInitialValues,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps),
  withMutation,
  connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
  withModalProps,
)(SendSupplierOrderModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
