import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { ShippingStatusWarehouseReportUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as SharedActions from '../shared/actions';
import { DateStr, ImmutableDateRange } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import * as Queries from '../shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm } from 'client/components/report-user-params/reporting-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  downloadButtonDisabled: boolean;
  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;
  mfcArrivalBeginDate: DateStr | undefined;
  mfcArrivalEndDate: DateStr | undefined;
  reportDownloadStatus: MutationStatus;
  storeDeliveryDateDisabled: boolean;
  mfcArrivalDateDisabled: boolean;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (_.isEmpty(formValues)) {
    return {
      downloadButtonDisabled: true,
      beginDate: undefined,
      endDate: undefined,
      mfcArrivalBeginDate: undefined,
      mfcArrivalEndDate: undefined,
      reportDownloadStatus: MutationStatus.Initial,
      storeDeliveryDateDisabled: false,
      mfcArrivalDateDisabled: false,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);

  return {
    downloadButtonDisabled: shouldDownloadBeDisabled,
    beginDate: formValues[Constants.FormFields.beginDate],
    endDate: formValues[Constants.FormFields.endDate],
    mfcArrivalBeginDate: formValues[Constants.FormFields.mfcArrivalBeginDate],
    mfcArrivalEndDate: formValues[Constants.FormFields.mfcArrivalEndDate],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    storeDeliveryDateDisabled: !formValues[Constants.FormFields.includeCustomerSheet],
    mfcArrivalDateDisabled: !formValues[Constants.FormFields.includeSupplierSheet],
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(): Thunker;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => Thunker;
  handleMfcArrivalDateRangeChanged: (dateRange: ImmutableDateRange) => Thunker;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleDateRangeChanged: SharedActions.handleDateRangeChanged(Constants.formName, Constants.FormFields),
  handleMfcArrivalDateRangeChanged: Actions.handleMfcArrivalDateRangeChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  ReduxFormProps &
  Queries.CustomerQueryProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.includeCustomerSheet]: false,
      [Constants.FormFields.includeSupplierSheet]: false,
    },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withCustomers,
  UserParamsMappers.withLoadTrackingListReportUserParamMappers,
)(ShippingStatusWarehouseReportUI) as Component<OwnProps>;

export const ShippingStatusWarehouseReport = (props: OwnProps) => propToComponent(component, props);
