import { buildAdminListPage } from '../admin';
import { Box } from 'shared/schemas/box';

export default buildAdminListPage(Box, {
  columns: [
    'identifier',
    'description',
    'supplier',
    'partNumber',
    'productIdentifier',
  ],
  excelColumns: [
    'identifier',
    'description',
    'supplier',
    'partNumber',
    'productIdentifier',
    'height',
    'width',
    'length',
  ],
});
