import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import * as Actions from './actions';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import { msyncQuery } from 'client/hoc/graphql/query';
import gql from 'graphql-tag';
import { Invoice } from 'schema/invoice/invoice-graphql-types';
import { FetchPolicy } from 'apollo-client';
import * as InvoiceHelpers from 'shared/app/invoice-helpers';

export interface OwnProps {
  selectedInvoiceIds: number[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
}

const mapStateToProps = (state: any): StateProps => {
  return {};
};

export interface InvoicesQueryResponse {
  getInvoices: {
    invoices: Array<{
      id: Invoice['id'];
      identifier: Invoice['identifier'];
      type: Invoice['type'];
      transferredToAccounting: Invoice['transferredToAccounting'];
      ediInvoiced: Invoice['ediInvoiced'];
      customerSupportsEdiInvoicing: Invoice['customerSupportsEdiInvoicing'];
    }>,
  };
}
export const GetInvoicesQuery = gql`
query GetInvoicesWithFilterOptionForSendToAcumaticaModal($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
  getInvoices: GetInvoices(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
    invoices {
      ... on Invoice {
        id
        identifier
        type
        transferredToAccounting
        ediInvoiced
        customerSupportsEdiInvoicing
      }
    }
  }
}
`;
interface WithInvoiceDetails {
  invoiceIdentifiersToBeSentToAcumatica: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersAlreadySentToAcumatica: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatNeedToBeEdiInvoicedFirst: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatCannotBeSent: Array<{ id: number, identifier: string }>;
}

const withInvoiceDetails = msyncQuery<InvoicesQueryResponse, OwnProps, WithInvoiceDetails, {}>(GetInvoicesQuery, {
  alias: 'withInvoiceDetails',
  skip(ownProps) {
    return false;
  },
  options(ownProps): { variables, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: [{ field: 'id', values: ownProps.selectedInvoiceIds }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithInvoiceDetails {
    const { data } = props;
    const invoices = data.getInvoices ? data.getInvoices.invoices : [];

    return {
      invoiceIdentifiersToBeSentToAcumatica: invoices.filter(invoice =>
        !invoice.transferredToAccounting && InvoiceHelpers.isInvoiceEligibleForTransferringToAcumatica(invoice.customerSupportsEdiInvoicing, invoice.type, invoice.ediInvoiced)),
      invoiceIdentifiersThatNeedToBeEdiInvoicedFirst: invoices.filter(invoice =>
        !invoice.transferredToAccounting && InvoiceHelpers.doesInvoiceNeedToBeSentToEdiBeforeAcumatica(invoice.customerSupportsEdiInvoicing, invoice.type, invoice.ediInvoiced)),
      invoiceIdentifiersAlreadySentToAcumatica: invoices.filter(invoice => invoice.transferredToAccounting),
      invoiceIdentifiersThatCannotBeSent: invoices.filter(invoice => !InvoiceHelpers.isInvoiceEligibleToBeSentToAcumatica(invoice.type)),
    };
  },
});

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleSendInvoicesToAcumaticaButtonClicked(invoiceIdsToBeSentToAcumatica: Array<{ id: number, identifier: string }>): void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.onSendInvoicesToAcumaticaModalCloseClicked,
  handleSendInvoicesToAcumaticaButtonClicked: Actions.handleSendInvoicesToAcumaticaClicked,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  WithInvoiceDetails &
  DispatchProps;

assertCompatible<UI.ComponentProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  withInvoiceDetails,
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(UI.SendInvoicesToAcumaticaModal) as Component<OwnProps>;

export const SendInvoicesToAcumaticaModalContainer = (props: OwnProps) => propToComponent(component, props);
