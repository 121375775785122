import * as React from 'react';
import { noop, isNil } from 'lodash';
import { LabeledSelect } from 'client/components/form';

export interface SimpleSelectProps {
  label?: string;
  testid: string;
  value: string | number | string[] | number[] | undefined;
  onChange: (value: shame) => void;
  options: Array<{ id: number | string, value: string } | string>;
  horizontalLabel?: boolean;
  labelColSize?: number;
  inputColSize?: number;
  disabled?: boolean;
  multi?: boolean;
}

export const SimpleSelect = (props: SimpleSelectProps) => {
  const meta = { touched: false, error: '', warning: '', submitting: false };
  const input = {
    name: `${props.testid}-input`,
    value: props.value,
    onChange: props.onChange,
    onBlur: noop,
  };

  const options: Array<{ id: number | string, value: string, displayValue: string }> = props.options.map(option => {
    if (typeof option === 'string') {
      return { id: option, value: option, displayValue: option };
    } else {
      return {
        ...option,
        displayValue: option.value,
      };
    }
  });

  return (
    <LabeledSelect
      disabled={props.disabled ?? false}
      horizontalLabel={!!props.horizontalLabel}
      input={input || ''}
      inputColSize={isNil(props.inputColSize) ? 6 : props.inputColSize}
      label={props.label || ''}
      labelColSize={isNil(props.labelColSize) ? 6 : props.labelColSize}
      meta={meta}
      offset={0}
      options={options}
      placeholder={props.label}
      required
      testid={props.testid}
      textFormatter={option => option.displayValue}
      valueField="id"
      multi={props.multi}
      {...!props.multi ? { handleChange: input.onChange } : {}}
    />
  );
};
