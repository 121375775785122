import * as _ from 'lodash';
import { Set, Record, List, Map } from 'immutable';
// import { Arrays } from '@atomic-object/lenses/arrays';
import * as State from 'client/state/product-worksheet';
import * as ImportCustomerOrderSpreadsheetActions from 'client/actions/import-customer-order-spreadsheet';

import {
  PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_CHECKED,
  PRODUCT_WORKSHEET_UNMOUNTED,
  PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  // PRODUCT_WORKSHEET_ADD_FROM_SALES_PLAN_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_CLICKED,
  PRODUCT_WORKSHEET_PRODUCT_NAVIGATED,
  PRODUCT_WORKSHEET_COMBO_CART_CHECKED,
  PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_COMBO_CART_CANCEL_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_EDIT_COMBO_CART_MODAL_SAVE_BUTTON_CLICKED,
  PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED,
  PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED,
} from 'client/constants';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { ProductId } from 'shared/schemas/product';
import { ActionTypeKeys } from 'client/actions/product-worksheet';
import { AllocatedProductChanges, QueuedAllocationChanges, AllocatedProductChange } from 'client/state/product-worksheet';
import { Action } from 'client/types/redux-types';
import { MutationStatus } from 'client/actions/mutations';

export type ProductWorksheetStateParams = Pick<Partial<ProductWorksheetState>,
  'checkedProductIds' | 'checkedComboCartIds' | 'addNewProductModalShown' | 'selectRecentComboModalShown' | 'editProductModalShown' |
  'editComboCartModalShown' | 'removeProductModalShown' | 'editCustomOrderProductGroupId' | 'selectedProductId' |
  'removeCustomerOrderProductGroupIds' | 'addProductsFromSalesPlanMutationStatus' |
  'autoReplenishmentModalShown' | 'storeIdsFromMenuAction' | 'autoReplenishmentMutationStatus' |
  'allocatedProductChangesByCustomerOrderProductGroup' | 'queuedAllocationChanges' |
  'inFlightAllocationChanges' | 'isAllocationDetailsRefetching'>;

export class ProductWorksheetState extends Record({
  checkedProductIds: Set([]),
  checkedComboCartIds: Set([]),
  addNewProductModalShown: false,
  selectRecentComboModalShown: false,
  editProductModalShown: false,
  editComboCartModalShown: false,
  removeProductModalShown: false,
  editCustomOrderProductGroupId: null,
  removeCustomerOrderProductGroupIds: null,
  selectedProductId: null,
  addProductsFromSalesPlanMutationStatus: MutationStatus.Initial,
  autoReplenishmentModalShown: false,
  storeIdsFromMenuAction: List<number>(),
  autoReplenishmentMutationStatus: MutationStatus.Initial,
  allocatedProductChangesByCustomerOrderProductGroup: Map({}),
  queuedAllocationChanges: Map({}),
  inFlightAllocationChanges: Map({}),
  isAllocationDetailsRefetching: false,
}) {
  checkedProductIds: Set<number>;
  checkedComboCartIds: Set<number>;
  addNewProductModalShown: boolean;
  selectRecentComboModalShown: boolean;
  editProductModalShown: boolean;
  editComboCartModalShown: boolean;
  removeProductModalShown: boolean;
  editCustomOrderProductGroupId?: CustomerOrderProductGroupId;
  removeCustomerOrderProductGroupIds?: Set<CustomerOrderProductGroupId>;
  selectedProductId?: number;
  addProductsFromSalesPlanMutationStatus: MutationStatus;
  autoReplenishmentModalShown: boolean;
  storeIdsFromMenuAction: List<number>;
  autoReplenishmentMutationStatus: MutationStatus;
  allocatedProductChangesByCustomerOrderProductGroup: AllocatedProductChanges;
  queuedAllocationChanges: QueuedAllocationChanges;
  inFlightAllocationChanges: QueuedAllocationChanges;
  isAllocationDetailsRefetching: boolean;

  constructor(params?: ProductWorksheetStateParams) {
    // eslint-disable-next-line no-unused-expressions
    params ? super(params) : super();
  }

  with(values: ProductWorksheetStateParams) {
    return this.merge(values) as this;
  }
}

export const INITIAL_STATE: ProductWorksheetState = new ProductWorksheetState();

function clearCheckedItems(state: ProductWorksheetState) {
  return state.with({
    checkedProductIds: Set([]),
    checkedComboCartIds: Set([]),
  });
}

function uncheckItems(customerOrderProductGroupIdsToUncheck: CustomerOrderProductGroupId[], productIdsToUncheck: ProductId[]) {
  return (state: ProductWorksheetState) => {

    const checkedProductIds = state.checkedProductIds.subtract(productIdsToUncheck);
    const checkedComboCartIds = state.checkedComboCartIds.subtract(customerOrderProductGroupIdsToUncheck);
    return state.with({
      checkedProductIds,
      checkedComboCartIds,
    });
  };
}

function closeModals(state: ProductWorksheetState) {
  return state.with({
    editComboCartModalShown: false,
    editProductModalShown: false,
    addNewProductModalShown: false,
    removeProductModalShown: false,
    selectRecentComboModalShown: false,
  });
}

function resetEditAndRemoveState(state: ProductWorksheetState) {
  return state.with({
    removeCustomerOrderProductGroupIds: Set([]),
    editCustomOrderProductGroupId: undefined,
  });
}

export default function reducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }) {
  switch (action.type) {
    case PRODUCT_WORKSHEET_PRODUCT_CLICKED:
    case PRODUCT_WORKSHEET_PRODUCT_NAVIGATED: {
      const productId: number = action.payload.productId;

      return state.with({
        selectedProductId: productId,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_CHECKED: {
      const productId: number = action.payload.productId;

      if (state.checkedProductIds.includes(productId)) {
        return state.with({
          checkedProductIds: state.checkedProductIds.remove(action.payload.productId),
        });
      } else {
        return state.with({
          checkedProductIds: state.checkedProductIds.add(action.payload.productId),
        });
      }
    }

    case PRODUCT_WORKSHEET_COMBO_CART_CHECKED: {
      const comboCartId: CustomerOrderProductGroupId = action.payload.comboCartId;

      if (state.checkedComboCartIds.includes(comboCartId)) {
        return state.with({
          checkedComboCartIds: state.checkedComboCartIds.remove(comboCartId),
        });
      } else {
        return state.with({
          checkedComboCartIds: state.checkedComboCartIds.add(comboCartId),
        });
      }
    }

    case PRODUCT_WORKSHEET_UNMOUNTED:
    case ImportCustomerOrderSpreadsheetActions.ActionTypeKeys.ImportCustomerOrderSpreadsheetImported: {
      return state.with({
        checkedProductIds: Set([]),
        selectedProductId: undefined,
      });
    }

    case PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED: {
      return state.with({
        addNewProductModalShown: true,
      });
    }

    case PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED: {
      return state.with({
        selectRecentComboModalShown: true,
      });
    }

    case PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED: {
      return state.with({
        selectRecentComboModalShown: false,
      });
    }

    case PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED: {
      return state.with({
        addNewProductModalShown: true,
      });
    }

    case PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED: {
      const customerOrderProductGroupId: CustomerOrderProductGroupId = action.payload.customerOrderProductGroupId;

      return state.with({
        editProductModalShown: true,
        editCustomOrderProductGroupId: customerOrderProductGroupId,
      });
    }

    case PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED: {
      const customerOrderProductGroupId: CustomerOrderProductGroupId = action.payload.customerOrderProductGroupId;

      return state.with({
        editComboCartModalShown: true,
        editCustomOrderProductGroupId: customerOrderProductGroupId,
      });
    }

    case PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED: {
      const customerOrderProductGroupId: CustomerOrderProductGroupId = action.payload.customerOrderProductGroupId;

      return state.with({
        removeProductModalShown: true,
        removeCustomerOrderProductGroupIds: Set([customerOrderProductGroupId]),
      });
    }

    case PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED: {
      const customerOrderProductGroupIds: CustomerOrderProductGroupId[] = action.payload.customerOrderProductGroupIds;

      return state.with({
        removeProductModalShown: true,
        removeCustomerOrderProductGroupIds: Set(customerOrderProductGroupIds),
      });
    }

    case PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED: {
      return state.with({
        addNewProductModalShown: false,
      });
    }

    case PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED: {
      return state.with({
        editProductModalShown: false,
      });
    }

    case PRODUCT_WORKSHEET_EDIT_COMBO_CART_CANCEL_BUTTON_CLICKED: {
      return state.with({
        editComboCartModalShown: false,
      });
    }

    case PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED: {
      return state.with({
        removeProductModalShown: false,
        removeCustomerOrderProductGroupIds: Set([]),
      });
    }

    case PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED:
    case PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED:
    case PRODUCT_WORKSHEET_EDIT_COMBO_CART_MODAL_SAVE_BUTTON_CLICKED: {
      return _.flow(
        clearCheckedItems,
        resetEditAndRemoveState,
        closeModals,
      )(state);
    }

    case PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED: {
      return _.flow(
        uncheckItems(action.payload.customerOrderProductGroupIds, action.payload.checkedProductIdsToUncheck),
        resetEditAndRemoveState,
        closeModals,
        s => s.with({selectedProductId: undefined}),
      )(state);
    }

    case PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS: {
      return state.with({
        addProductsFromSalesPlanMutationStatus: action.payload.status,
      });
    }

    case ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MODAL_VISIBILITY: {
      return state.with({
        autoReplenishmentModalShown: action.payload.showModal,
        storeIdsFromMenuAction: action.payload.storeIdsFromMenuAction,
      });
    }

    case ActionTypeKeys.PRODUCT_WORKSHEET_SET_AUTO_REPLENISHMENT_MUTATION_STATUS: {
      return state.with({
        autoReplenishmentMutationStatus: action.payload.status,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED: {
      const copgId = action.payload.customerOrderProductGroupId;
      const storeId = action.payload.storeId;

      const allocationChange: AllocatedProductChange = {
        customerOrderProductGroupId: copgId,
        nonce: action.payload.nonce,
        storeId,
        quantity: action.payload.quantity,
        change: action.payload.quantity - action.payload.prevQuantity,
      };

      const allocatedProductChangesLens = State.allocatedProductChangesForCustomerOrderProductGroupLens(copgId);

      const result = _.flow(
        allocatedProductChangesLens.update(changes => changes.push(allocationChange)),
        State.queuedAllocationChangeLens(copgId, storeId).set(allocationChange),
      )(state);

      return result;
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED: {
      const allocationsBeingSaved: AllocatedProductChange[] = action.payload.allocationsToSave;

      // All of the changes to be saved are moved to inFlight
      const inFlightAllocationChanges = _.reduce(allocationsBeingSaved, (memo, allocatedProductChange) => {
        return memo.set(State.keyForAllocatedProductChange(allocatedProductChange), allocatedProductChange);
      }, Map<string, AllocatedProductChange>({}));

      // Remove all of the changes that are now in flight
      const queuedAllocationChanges = state.queuedAllocationChanges.keySeq().reduce((memo, key) => {
        if (inFlightAllocationChanges.has(key!)) {
          return memo!;
        } else {
          return memo!.set(key!, state.queuedAllocationChanges.get(key!));
        }
      }, Map<string, AllocatedProductChange>({}));

      return state.with({
        queuedAllocationChanges,
        inFlightAllocationChanges,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED: {
      return state.with({
        inFlightAllocationChanges: Map({}),
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED: {
      // Clear out the in-flight changes. They had their chance, and they failed.
      // This will give someone else a chance.
      return state.with({
        inFlightAllocationChanges: Map({}),
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED: {
      return state.with({
        isAllocationDetailsRefetching: true,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED: {
      // Need to remove all of the changes up to the nonce specified in the
      // payload, as they should have been included in the refetched results
      const nonce = action.payload.nonce;

      const unsavedChanges = state.allocatedProductChangesByCustomerOrderProductGroup.reduce((memo, changes, key) => {
        if (memo && key && changes) {
          const updatedChanges = changes.filter(ch => !!(ch && ch.nonce > nonce)).toList();
          const result = memo.set(key, updatedChanges);
          return result;
        }

        return Map<number, List<AllocatedProductChange>>({});

      }, state.allocatedProductChangesByCustomerOrderProductGroup);

      return state.with({
        allocatedProductChangesByCustomerOrderProductGroup: unsavedChanges,
        isAllocationDetailsRefetching: false,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED: {
      return state.with({
        isAllocationDetailsRefetching: false,
      });
    }

    case PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED: {
      return state.with({
        allocatedProductChangesByCustomerOrderProductGroup: Map<number, List<AllocatedProductChange>>({}),
        queuedAllocationChanges: Map<string, AllocatedProductChange>({}),
      });
    }

    default:
      return state;
  }
}
