import * as _ from 'lodash';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Excel from './common';
import * as XLSX from 'xlsx';
import { orThrow } from 'shared/helpers';
import { Values } from 'shared/types';

// 1-based index to align with Excel
const CELLS = { customerIdentifier: { col: 'B', row: 1 } };
const COLS = {
  productIdentifier : 'A',
  productDescription: 'B', // This is not currently used by the parser
  price             : 'C',
  scanback          : 'D',
  retail            : 'E',
  upcRetail         : 'F',
};
const FIRST_PRICE_RECORD_ROW = 4;
const genericFailureMessage = 'The file cannot be processed. Please verify that the format of the spreadsheet is correct.';
const orThrowFailure = (specificProblem: string) => orThrow(`${genericFailureMessage}\n(${specificProblem})`);

export enum ValidProductPriceSheetWorkSheetDataType { }
export type ProductPriceSheetWorkSheetData = Excel.WorkSheetData & ValidProductPriceSheetWorkSheetDataType;

export interface ImportableProductPriceRecord {
  productIdentifier: string;
  price: number;
  scanback?: number;
  retail: number;
  upcRetail?: number;
}

export interface ImportableProductPriceSheet {
  customerIdentifier: string;
  productIdentifierField: Values<typeof validProductIdentifierTypes>;
  productPriceRecords: ImportableProductPriceRecord[];
}

export type ProductPriceParsingResult = { success: true; parsed: ImportableProductPriceSheet; } | { success: false; reason: string; };

const validProductIdentifierTypes = {
  'Product ID': 'identifier',
  'SKU': 'sku',
} as const;

const validProductIdentifierType = s => s in validProductIdentifierTypes ? s as keyof typeof validProductIdentifierTypes : orThrowFailure(`A3 should be either ${_.keys(validProductIdentifierTypes).join(' or ')}`);

const getHeaderData = (worksheetData: ProductPriceSheetWorkSheetData) => ({
  customerIdentifier: Excel.getAsString(worksheetData[CELLS.customerIdentifier.row - 1][CELLS.customerIdentifier.col]) || orThrowFailure(`3 character customer expected in B1`),
  productIdentifierField: validProductIdentifierTypes[validProductIdentifierType(Excel.getAsString(worksheetData[FIRST_PRICE_RECORD_ROW - 2][COLS.productIdentifier]))],
});

const getProductPriceRecords = (worksheetData: ProductPriceSheetWorkSheetData) => worksheetData
  .slice( FIRST_PRICE_RECORD_ROW - 1, 1 + (worksheetData.length || orThrowFailure(`Unable to find last Price row: the spreadsheet appears empty.`)))
  .map((row): ImportableProductPriceRecord => ({
    productIdentifier: Excel.getAsString(row[COLS.productIdentifier]) || '',
    price: Excel.getAsDecimal(row[COLS.price]) || 0,
    scanback: Excel.getAsDecimal(row[COLS.scanback]) || undefined,
    retail: Excel.getAsDecimal(row[COLS.retail]) || 0,
    upcRetail: Excel.getAsDecimal(row[COLS.upcRetail]) || undefined,
  }))
  // at least one of productIdentifier or SKU are mandatory - but omitting them is not an error, it just excludes a record from import consideration
  .filter(productPriceRecord => productPriceRecord.productIdentifier.trim().length > 0);

export const getImportableProductPriceSheet = async (workBookContainer: ExcelCommon.WorkBookContainer): Promise<ProductPriceParsingResult> => {
  try {
    const workBook = await Excel.getWorkBookFromWorkBookContainer<XLSX.WorkBook>(workBookContainer) || orThrowFailure(`raw file format not XLSX`);
    const workSheet = await Excel.getFirstWorkSheet<XLSX.WorkBook>(workBook) || orThrowFailure(`first sheet inaccessible`);
    const worksheetData = await Excel.getWorkSheetData<ProductPriceSheetWorkSheetData>(workSheet);
    if (worksheetData.length === 0 || Object.keys(worksheetData[0]).length < 1) {
      orThrowFailure(`spreadsheet appears empty`);
    }

    return {
      success: true,
      parsed: {
        ...getHeaderData(worksheetData),
        productPriceRecords: getProductPriceRecords(worksheetData),
      },
    };
  } catch (error) {
    return { success: false, reason: error.message };
  }
};

export const FOR_TESTS = { getHeaderData, getProductPriceRecords };
