import * as React from 'react';
import Dropdown from 'client/components/dropdown';
import { RecordBarDetail } from 'client/components/record-bar/record-bar-detail';

function buildRecordBarStatusToggle(args: {
  field: string,
  value: string | undefined,
  options: string[],
  default: string,
  onChangeRecordStatus: (field: string, val: any) => void,
  disabled?: boolean,

}) {
  const displayValue = args.value ? args.value : args.default;

  return (
    <RecordBarDetail>
      <Dropdown
        className="btn-group-record-bar-button"
        data={args.options}
        value={[displayValue]}
        valueField="id"
        onChange={(v: any) => args.onChangeRecordStatus(args.field, v)}
        updateLabel
        testid="status-toggle"
        disabled={!!args.disabled}
      />
    </RecordBarDetail>
  );
}

export default buildRecordBarStatusToggle;
