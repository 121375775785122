// export * from './andys-little-helpers'; circular?
export * from './assert-compatible';
export * from './camel-case-keys';
export * from './split-by-size';
export * from './date-helpers';
export * from './debounce';
export * from './determine-whether-to-allow-combos';
export * from './first-or-default';
export * from './logging';
export * from './mode-by';
export * from './omit-nils';
export * from './or-throw';
export * from './promises';
export * from './sort-naturally';
export * from './trust-me-its-not-undefined';
export * from './bug';

import { Bug } from './bug';
import { orThrow } from './or-throw';

// TODO: see m-sync mobile for how to setup IOC centralized logging so it can live in an xplat core location
const log = {
  silent: (...content: unknown[]) => {                                                                                                            return undefined; },
  info  : (...content: unknown[]) => { console. info(...content);                                                                                 return undefined; },
  log   : (...content: unknown[]) => { console.  log(...content);                                                                                 return undefined; },
  // console.debug doesn't always emit in a brower
  debug : (...content: unknown[]) => { console.debug(...content);                                                                                 return undefined; },
  warn  : (...content: unknown[]) => { console.warn(...content, '\n', new Error('STACK TRACE FOR WARNING').stack?.replace(/Error/gi, 'Warning')); return undefined; },
  error : (...content: unknown[]) => { console.error(new Error('STACK TRACE FOR ERROR'), ...content);                                             return undefined; },
};

export type LogLevel = keyof typeof log;
export const orLog = <T>(substitution: T, message: string, severity: LogLevel = 'warn') => log[severity](message) ?? substitution;
export const orThrowBug = <T = never>(message: string, ...thingsToDump: any[]): T & never => orThrow(new Bug(message), ...thingsToDump);
export const throwIfError = <X>(x: X | Error) => !(x instanceof Error) ? x : orThrow(x);
export const throwAnyErrors = <X>(x: Array<Error | X>) => {
  const errors = x.filter(y => y instanceof Error);
  if (errors.length > 0) throw new AggregateError(errors);
  return x as X[];
};

/**
 * ![Fuzzies](https://66.media.tumblr.com/tumblr_lnca8idYap1qa6bvzo1_500.gif)
 */
export function filterByBeginnersThenFuzzies<T>(options: T[], extractMatchableText: (option: T) => string, pattern: string | undefined) {
  if (!pattern?.trim()?.length) return options;                                                   // early abort for useless patterns
  const lowerCaseFilter = pattern.toLowerCase();                                                  // comparisons should be case-insensitive
  return [
    ...options.filter(o => extractMatchableText(o).toLowerCase().startsWith(lowerCaseFilter)),  // those which begin with the pattern
    ...options.filter(o => extractMatchableText(o).toLowerCase().includes(lowerCaseFilter)),    // those which contain the pattern later on
  ];
}

/** Given two arrays of the same type, produces a new array of tuples. Like lodash.zip, but makes strong-typed heterogenous tuples, not homogenous arrays. */
export const zipTuples = <A, B>(a: A[], b: B[]) => {
  if (!Array.isArray(a) || !Array.isArray(b))
    throw new Bug(`one of the inputs was not an array.  Did you abuse any or shame?  The type checker would have caught this.`);

  if (a.length !== b.length)
    throw new Bug(`The input arrays must have the same length, but they were ${a.length} and ${b.length} respectively.`);

  return a.map((x, i) => [x, b[i]] as [A, B]);
};

/** Given two arrays of the same type, produces a new array of tuples. Like lodash.zip, but makes strong-typed heterogenous tuples, not homogenous arrays. */
export const zipTuplesWithRemainder = <A, B>(a: A[], b: B[]) => {
  if (!Array.isArray(a) || !Array.isArray(b))
    throw new Bug(`one of the inputs was not an array.  Did you abuse any or shame?  The type checker would have caught this.`);

  return (a.length > b.length)
    ? { tuples: b.map((x, i) => [a[i], x] as [A, B]), remainder: { a: a.slice(b.length, a.length), b: [] } }
    : { tuples: a.map((x, i) => [x, b[i]] as [A, B]), remainder: { a: [], b: b.slice(a.length, b.length) } };
};
