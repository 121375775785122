import * as React from 'react';
import { RecordBar } from '../invoice-record-bar';
import Sidebar from '../sidebar';
import { Col } from 'client/components/third-party';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UIProps {
}

export class InvoiceCreatePage extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <div id="mfc-page-content" className="record">
        <RecordBar />
        <div className="mfc-form">
          <div>
            <div className="mfc-form-details-with-sidebar-but-no-tabs">
              <Col sm={12}>
              </Col>
            </div>
            <div className="mfc-form-sidebar">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
