import gql from 'graphql-tag';
import * as GQLTypes from 'schema/supplier-commitment/types';

export interface ValidateImportSupplierCommitmentQueryResponse {
  validateSupplierCommitmentImport: {
    success: boolean;
    errors: string[];
  };
}

export interface ValidateImportSupplierCommitmentQueryArgs {
  input: GQLTypes.ImportSupplierCommitmentInput;
}

export const ValidateImportSupplierCommitmentQuery = gql`
  query ValidateSupplierCommitmentImportBeforeImporting($input: ImportSupplierCommitmentInput!) {
    validateSupplierCommitmentImport: ValidateSupplierCommitmentImport(input: $input) {
      success
      errors
    }
  }
`;
