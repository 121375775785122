import { Action } from 'redux';

export type ActionTypes =
  SetRecordBarGoBackToLocationAction;

export enum ActionTypeKeys {
  NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION = 'App/NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION',
}

export interface SetRecordBarGoBackToLocationAction extends Action {
  type: ActionTypeKeys.NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION;
  payload: {
    recordBarGoBackToLocation: string;
  };
}

export function setRecordBarGoBackToLocation(recordBarGoBackToLocation: string): SetRecordBarGoBackToLocationAction {
  return {
    type: ActionTypeKeys.NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION,
    payload: {
      recordBarGoBackToLocation,
    },
  };
}

export function clearRecordBarGoBackToLocation(): SetRecordBarGoBackToLocationAction {
  return {
    type: ActionTypeKeys.NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION,
    payload: {
      recordBarGoBackToLocation: '',
    },
  };
}
