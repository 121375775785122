import * as _ from 'lodash';
import { getFormValues, change } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import { DateRangeYearComparisonValue, getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import * as SchemaTypes from 'schema/planning-report/types';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
    const presetId = formValues.id;

    const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
    if (!dateRangeYearComparison) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
    if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const beginDate = dateRanges.dateRange.startDate;
    const endDate = dateRanges.dateRange.endDate;
    const lastYearBeginDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.startDate : undefined;
    const lastYearEndDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.endDate : undefined;

    const customerId = formValues[Constants.FormFields.customerId];
    const supplierId = formValues[Constants.FormFields.supplierId];
    const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];
    const productClassIds = extractSelectedValues(formValues[Constants.FormFields.productClassIds]);
    const productSubClassIds = extractSelectedValues(formValues[Constants.FormFields.productSubClassIds]);
    const supplierItemIds = extractSelectedValues(formValues[Constants.FormFields.supplierItemIds]);
    const poBased = formValues[Constants.FormFields.poBased];
    const scanBased = formValues[Constants.FormFields.scanBased];
    const poOrScanBased = _.compact([scanBased ? 'Scan Based' : undefined, poBased ? 'PO Based' : undefined]);

    if (_.isNil(beginDate) ||
      _.isNil(endDate) ||
      _.isNil(customerId) ||
      _.isNil(supplierId) ||
      _.isEmpty(supplierItemIds) ||
      _.isNil(sellDepartmentId) ||
      _.isNil(lastYearBeginDate) ||
      _.isNil(lastYearEndDate)) {
      throw new Error('Missing some required fields');
    }

    dispatch(ReportActions.reportDownloadStarted());
    try {
      const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateSupplierPerformanceReportInput>({
        mutation: Mutations.generateSupplierPerformanceReport,
        variables: {
          generateSupplierPerformanceReportInput: {
            customerId,
            supplierId,
            beginDate,
            endDate,
            lastYearBeginDate,
            lastYearEndDate,
            poOrScanBased,
            sellDepartmentId,
            productClassIds,
            productSubClassIds,
            supplierItemIds,
            presetId,
          },
        },
        dispatch,
      });

      if (!response) {
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};

export const handleDateRangesChanged = (state: DateRangeYearComparisonValue | undefined): Thunker => {
  return async dispatch => {
    dispatch(change(Constants.formName, Constants.FormFields.dateRangeYearComparison, state));
  };
};
