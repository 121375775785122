/* eslint-disable arrow-parens */
import * as React from 'react';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { FormGroup, Row, Col } from 'client/components/third-party';
import { getFormValues, change } from 'redux-form';
import { FieldWrapper } from '../../form';
import { optionsContainerGenerator, optionsContainerWithFilters } from '../../../hoc/options-container-generator';
import { connect, ConnectedProps } from 'react-redux';
import { ColumnAlignment } from 'client/components/selectable/types';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import { LabeledDisplay } from 'client/components/form';
import * as Validators from 'shared/validators';
import { FormField } from 'client/components/form';
import { LabeledInput } from 'client/components/form';
import { INPUT_TYPES, SelectableValue } from 'shared/types';
import { FieldValidator } from 'shared/validators/types';
import { requiredMessages } from 'shared/validators/required';
import { wrapComponent } from 'client/hoc/hoc';

const table = 'productPrices';
/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper horizontalLabel table={table} alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

interface OwnProps {
  formName: string;
  customerId: number;
}

const mapStateToProps = (state, ownProps: { formName: string }) => {
  const { formName } = ownProps;
  const values = getFormValues(formName)(state) as { primary?: boolean, regionId: SelectableValue;  };

  return {
    selectedRegionId: (values?.regionId) ? values.regionId.values : null, // This is used by the HOC that gets the markets for the selected region
    primary: !!(values?.primary),
  };
};

const mapDispatchToProps = (dispatch, ownProps: { formName: string, primaryMarkets: Array<{ id: number }>}) => {
  const { formName } = ownProps;

  return {
    onPrimaryChange: value => {
      dispatch(change(formName, 'regionId', null));
      dispatch(change(formName, 'primary', value));
      if (value) {
        dispatch(change(formName, 'primaryMarketIds', ownProps.primaryMarkets.map(primaryMarket => primaryMarket.id)));
        dispatch(change(formName, 'marketId', null));
      } else {
        dispatch(change(formName, 'primaryMarketIds', []));
        dispatch(change(formName, 'marketId', null));
      }
    },
  };
};

const hidden = { display: 'none' };

const marketsRequiredValidator: FieldValidator = {
  isValid(value, record) {
    if (record.primary) {
      return true;
    }
    return Validators.REQUIRED_SELECTABLE.isValid(value, record);
  },
  ...requiredMessages,
};

interface ComponentProps {
  formName: string;
  regions: Array<{
    id: number;
    identifier: string;
  }>;
  regionsLoading: boolean;
  markets: Array<{
    id: number;
    identifier: string;
  }>;
  marketsLoading: boolean;
  customerId: number;
  primaryMarkets: Array<{
    id: number;
    identifier: string;
  }>;
  primary: boolean;
  onPrimaryChange(value: boolean): any;
}

class ProductNewPriceFormGroup extends React.Component<ComponentProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onPrimaryChange, primary, regions, markets } = this.props;

    return (
      <Row data-testid="product-price">
        <FormGroup>
          <Field name="beginDate" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Col sm={3} />
          <Col className="mfc-form-input" sm={9}>
            <SimpleCheckbox
              testid="primary-checkbox-test"
              value={primary}
              onChange={onPrimaryChange}
              label="All Markets Containing a Primary Store"
            />
          </Col>
        </FormGroup>
        {!primary &&
          <>
            <FormGroup>
              <Field
                name="regionId"
                fields={['identifier', 'description']}
                cols={[
                  { alignment: ColumnAlignment.Left, width: 20 },
                  { alignment: ColumnAlignment.Left, width: 80 },
                ]}
                labelColSize={3}
                inputColSize={9}
                options={regions}
                loading={this.props.regionsLoading}
                horizontalLabel
                hideOptionalLabel
              />
            </FormGroup>
            <FormGroup>
              <Field
                name="marketId"
                fields={['regionIdentifier', 'identifier', 'description']}
                cols={[
                  { alignment: ColumnAlignment.Left, width: 20 },
                  { alignment: ColumnAlignment.Left, width: 20 },
                  { alignment: ColumnAlignment.Left, width: 60 },
                ]}
                labelColSize={3}
                inputColSize={9}
                options={markets}
                loading={this.props.marketsLoading}
                horizontalLabel
                hideOptionalLabel
                validators={[marketsRequiredValidator]}
              />
             </FormGroup>
          </>
        }
        <div style={hidden}>
          <FormField
            formName="ProductPriceForm"
            name="primaryMarketIds"
            component={LabeledInput}
            labelColSize={6}
            inputColSize={6}
            horizontalLabel={false}
            label=""
            testid=""
            type={INPUT_TYPES.TEXT}
            disabled
          />
        </div>
        {primary &&
          <FormGroup>
            <LabeledDisplay
              labelColSize={3}
              inputColSize={9}
              label="Markets"
              value={this.props.primaryMarkets.map(primaryMarket => primaryMarket.identifier).join(', ')}
              testid="markets-primary"
              textarea
            />
          </FormGroup>
        }
        <FormGroup>
          <Field name="mfcPrice" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="scanbackPrice" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="retailPrice" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="upcRetailPrice" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="changeReason" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="notes" labelColSize={3} inputColSize={9} />
        </FormGroup>
      </Row>);
  }
}

const withRegions = optionsContainerWithFilters({
  table: 'regions',
  columns: ['identifier', 'description', 'customer'],
  getFilters: props => {
    if (props.customerId) {
      return [
        { field: 'customer', values: [props.customerId.toString()] },
      ];
    }

    return null;
  },
});

export const GetPrimaryMarketsByCustomerId = gql`
  query getPrimaryMarketsByCustomerId($customerId: Int!) {
    result: GetPrimaryMarketsByCustomerId(customerId: $customerId) {
      id
      identifier
    }
  }
`;

export const withPrimaryMarkets = msyncQuery<shame, OwnProps, shame, { customerId: number }>(GetPrimaryMarketsByCustomerId, {
  options(ownProps) {
    return {
      variables: {
        customerId: ownProps.customerId,
      },
    };
  },
  props({ data }) {
    return {
      primaryMarkets: data ? data.result : [],
    };
  },
});

const withMarkets = optionsContainerGenerator({ table: 'markets', columns: ['identifier', 'description', 'regionIdentifier'], parent: 'region' });

const connector = connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector> &
  {
    regionsLoading: boolean;
    regions: Array<{
      id: number;
      identifier: string;
    }>;
  } &
  {
    marketsLoading: boolean;
    markets: Array<{
      id: number;
      identifier: string;
    }>;
  } &
  {
    primaryMarkets: Array<{
      id: number;
      identifier: string;
    }>;
  };

export default wrapComponent(ProductNewPriceFormGroup)<OwnProps, CombinedProps>(
  withPrimaryMarkets,
  withRegions,
  connect(mapStateToProps, mapDispatchToProps),
  withMarkets, // Has to come after the connect so it knows which regions have been selected
);
