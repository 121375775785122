import * as React from 'react';
import { useCallback } from 'react';
import { connect } from 'react-redux';

import { ScanBasedOrPoBased } from 'shared/types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { ImportableCustomerOrder } from 'shared/file-parsers/excel/customer-order-spreadsheet-parser';

import { Modal, Button, Col } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { SimpleRadio } from 'client/components/simple-components/simple-radio';

import * as State from 'client/state/state';
import * as Selectors from 'client/state/import-customer-order-spreadsheet-selectors';
import { CustomerOrderImportState, CustomerOrderImportStep } from 'client/state/import-customer-order-spreadsheet';
import { MutationStatus } from 'client/actions/mutations';
import * as Actions from 'client/actions/import-customer-order-spreadsheet';

type OutermostProps = { customerOrderId?: number };
type ComponentProps = OutermostProps & {
  handleFileSelected(fileName: string, fileContents: string, customerOrderId?: number): void,
  openExistingCustomerOrderButtonClicked(): void,
  customerOrderImportState: CustomerOrderImportState,
  failedStep?: CustomerOrderImportStep,
  errorMessages?: string[],
  existingCustomerOrderId?: number,
  importableCustomerOrder?: ImportableCustomerOrder,
  scanBasedOrPoBased?: string,
  radioButtonClicked(value: string): void,
};

const ChooseFileButton = (p: {
  label: string,
  handleFileSelected(fileName: string, fileContents: string, customerOrderId?: number): void,
  customerOrderId?: number,
}) => {
  const onFileInputChange = useCallback((event: React.SyntheticEvent<HTMLInputElement>) => {
    const files = (event.target as any).files;
    if (files.length !== 1)
      return;

    const file: File = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result)
        throw new Error('Unable to read file!');

      if (typeof reader.result !== 'string')
        throw new Error('Unexpected file contents (not a string)');

      p.handleFileSelected(file.name, reader.result, p.customerOrderId);
    };
    reader.readAsBinaryString(file);
  }, [p.handleFileSelected, p.customerOrderId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <label className="import-customer-order-modal-file-picker align-bottom btn btn-default btn-file mfc-button mfc-submit-button mfc-button-primary">
      {p.label}
      <input
        type="file"
        id="import-customer-order-modal-file-picker-input-element"
        className="import-customer-order-modal-file-picker-input-element"
        data-testid="file-picker"
        onChange={onFileInputChange}
        style={{ display: 'none' }}
        accept=".xlsx" />
    </label>
  );
};

const ChooseFileContent = (p: ComponentProps) => (
  <div className="import-customer-order-modal-content-area">
    <div className="text-center">
      <ChooseFileButton {...p} label="Choose File" />
    </div>
  </div>
);

const ParsingContent = (p: ComponentProps) => (
  <div className="import-customer-order-modal-content-area">
    <div className="import-customer-order-modal-spinner-container">
      <ProgressSpinner label="Parsing..." />
    </div>
  </div>
);

const ValidatingContent = (p: ComponentProps) => (
  <div className="import-customer-order-modal-content-area">
    <div className="import-customer-order-modal-spinner-container">
      <ProgressSpinner label={`Validating ${p.importableCustomerOrder ? p.importableCustomerOrder.poNumber : ''}...`} />
    </div>
  </div>
);

const ValidatedContent = (p: ComponentProps) => {
  const scanBasedRadioButtonClicked = useCallback(() => p.radioButtonClicked(ScanBasedOrPoBased.scanBased), [p.radioButtonClicked]); // eslint-disable-line react-hooks/exhaustive-deps
  const poBasedRadioButtonClicked = useCallback(() => p.radioButtonClicked(ScanBasedOrPoBased.poBased), [p.radioButtonClicked]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="import-customer-order-modal-content-area">
      <div className="text-center">
        <span className="fa fa-check fa-5x import-customer-order-modal-validated-status-icon" />
        <div className="import-customer-order-modal-status-text">Validated {p.importableCustomerOrder && p.importableCustomerOrder.poNumber}</div>
        <div className="import-customer-order-modal-scan-based-container">
          { !p.customerOrderId &&
            <>
              <Col smOffset={3} sm={3}>
                <SimpleRadio
                  label="Scan-Based"
                  onChange={scanBasedRadioButtonClicked}
                  value={p.scanBasedOrPoBased}
                  optionValue={ScanBasedOrPoBased.scanBased}
                  testid="import-customer-order-scan-order"
                  labelColSize={4}
                  inputColSize={0}
                />
              </Col>

              <Col sm={4}>
                <SimpleRadio
                  label="PO-Based"
                  onChange={poBasedRadioButtonClicked}
                  value={p.scanBasedOrPoBased}
                  optionValue={ScanBasedOrPoBased.poBased}
                  testid="import-customer-order-non-scan-order"
                  labelColSize={4}
                  inputColSize={0}
                />
              </Col>
            </>
          }
        </div>
      </div>
    </div>
  );
};

const ImportingContent = (p: ComponentProps) => (
  <div className="import-customer-order-modal-content-area">
    <div className="import-customer-order-modal-spinner-container">
      <ProgressSpinner label={`Importing ${p.importableCustomerOrder ? p.importableCustomerOrder.poNumber : ''}...`} />
    </div>
  </div>
);

const ImportedContent = (p: ComponentProps) => (
  <div className="import-customer-order-modal-content-area">
    <div className="text-center">
      <span className="fa fa-check fa-5x import-customer-order-modal-imported-status-icon" />
      <div className="import-customer-order-modal-status-text">Imported {p.importableCustomerOrder && p.importableCustomerOrder.poNumber}</div>
    </div>
  </div>
);

const FailedContent = (p: ComponentProps) => (
  <div>
    <div className="text-center">
      <span className="fa fa-times fa-5x import-customer-order-modal-failed-status-icon" />
      <div className="import-customer-order-modal-status-text">{p.failedStep} Failed</div>
    </div>
    <div id="import-customer-order-error-message-box" className="alert alert-danger">
      <strong>There was a problem with the customer order spreadsheet file.</strong>
      <ul id="import-customer-order-error-list">
        {(p.errorMessages || []).map((message, index) => <li key={`import-customer-order-message-${index}`}>{message}</li>)}
      </ul>
    </div>
    <div className="text-center">
      <ChooseFileButton {...p} label={'Choose Another File'} />
      {p.existingCustomerOrderId &&
        <Button className="import-customer-order-open-existing-order-button mfc-button mfc-clear-button mfc-clear-button-default" onClick={p.openExistingCustomerOrderButtonClicked}>Open Existing Customer Order</Button>
      }
    </div>
  </div>
);

const FileChooserUI = (p: ComponentProps) => {
  switch (p.customerOrderImportState) {
    case CustomerOrderImportState.Initial   : return <ChooseFileContent {...p}/>;
    case CustomerOrderImportState.Parsing   : return <ParsingContent    {...p}/>;
    case CustomerOrderImportState.Validating: return <ValidatingContent {...p}/>;
    case CustomerOrderImportState.Validated : return <ValidatedContent  {...p}/>;
    case CustomerOrderImportState.Importing : return <ImportingContent  {...p}/>;
    case CustomerOrderImportState.Imported  : return <ImportedContent   {...p}/>;
    case CustomerOrderImportState.Failed    : return <FailedContent     {...p}/>;
  }
};

const mapDispatchToProps = {
  handleFileSelected: Actions.customerOrderSpreadsheetSelected,
  openExistingCustomerOrderButtonClicked: Actions.importCustomerOrderSpreadsheetOpenExistingButtonClicked,
  radioButtonClicked: Actions.radioButtonClicked,
};

assertCompatible<ComponentProps, OutermostProps & State.Type['importCustomerOrderSpreadsheet'] & typeof mapDispatchToProps>();
const ConnectedFileChooserUI = connect( (state: State.Type, p: OutermostProps) => ({...state.importCustomerOrderSpreadsheet, ...p}), mapDispatchToProps )(FileChooserUI);
const FileChooser = (p: OutermostProps) => <ConnectedFileChooserUI {...p}/>;

interface ImportCustomerOrderSpreadsheetModalComponentProps {
  isShown: boolean;
  handleCancelButtonClicked(): void;
  cancelButtonDisabled: boolean;
  buttonDisabled: boolean;
  buttonLabel: string;
  handleButtonClicked: (customerOrderId: number | undefined) => void;
  customerOrderId?: number | undefined;
}

const ImportCustomerOrderSpreadsheetModalUI = (p: ImportCustomerOrderSpreadsheetModalComponentProps) => !p.isShown ? <div/> : (
  <Modal
    id="import-customer-order-modal"
    animation
    backdrop="static"
    show={p.isShown}
    onHide={p.handleCancelButtonClicked}
    dialogClassName="import-customer-order-modal-container"
    className="import-customer-order-modal"
  >
    <GlobalAsyncModalHeader>Import Customer Order</GlobalAsyncModalHeader>
    <Modal.Title>
    </Modal.Title>
    <Modal.Body>
      <div className="import-customer-order-spreadsheet-modal-content">
        <FileChooser customerOrderId={p.customerOrderId}/>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <GlobalCancelButton
        onClick={p.handleCancelButtonClicked}
        disabled={p.cancelButtonDisabled}
      />
      <AsyncButton
        label={p.buttonLabel}
        testid="import-customer-order-spreadsheet-modal-finish"
        type={undefined}
        disabled={p.buttonDisabled}
        onClick={() => p.handleButtonClicked(p.customerOrderId)}
        actionStatus={MutationStatus.Initial}
      />
    </Modal.Footer>
  </Modal>
);

const UI = connect(
  (state: State.Type, p: { customerOrderId?: number | undefined }) => ({
    isShown: Selectors.isShown(state.importCustomerOrderSpreadsheet),
    cancelButtonDisabled: Selectors.cancelButtonDisabled(state.importCustomerOrderSpreadsheet),
    buttonDisabled: !Selectors.buttonEnabled(state.importCustomerOrderSpreadsheet, p.customerOrderId),
    buttonLabel: Selectors.isReadyForImport(state.importCustomerOrderSpreadsheet) ? 'Import Customer Order'
      : Selectors.hasBeenImported(state.importCustomerOrderSpreadsheet) && p.customerOrderId ? 'Close'
        : 'Open Customer Order',
  }),
  {
    handleCancelButtonClicked: Actions.importCustomerOrderSpreadsheetCancelButtonClicked,
    handleButtonClicked: Actions.importCustomerOrderSpreadsheetButtonClicked,
  }
)(ImportCustomerOrderSpreadsheetModalUI);

export const ImportCustomerOrderSpreadsheetModal = (p: { customerOrderId?: number | undefined }) => <UI {...p}/>;
