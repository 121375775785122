import { tableName, property, setSchemaOptions, belongsTo, definePresentation } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { SupplierId, Supplier } from 'shared/schemas/supplier';
import { Bol, BolId } from 'shared/schemas/bol';
import { DateStr, TYPES, INPUT_TYPES, DISPLAY_TYPES } from 'shared/types';
import { displayType } from './dsl';

export type CartTrackingSupplierEntryId = Flavor<number, 'cartTrackingSupplierEntryId'>;
export type CartTrackingSupplierEntryQuantity = Flavor<number, 'cartTrackingSupplierEntryQuantity'>;
export type CartTrackingSupplierEntryDate = Flavor<DateStr, 'cartTrackingSupplierEntryDate'>;
export type CartTrackingSupplierEntryNotes = Flavor<string, 'cartTrackingSupplierEntryNotes'>;
export type CartTrackingSupplierEntrySequenceNumber = Flavor<number, 'cartTrackingSupplierEntrySequenceNumber'>;
export type CartTrackingSupplierEntryBalance = Flavor<number, 'cartTrackingSupplierEntryBalance'>;

export enum CartTrackingSupplierEntryType {
  Adjustment = 'ADJ',
  Balance = 'BAL',
}

export enum CartTrackingSupplierAdjustmentType {
  Received = 'Received',
  Returned = 'Returned',
}

@tableName('cartTrackingSupplierEntries')
export class CartTrackingSupplierEntry implements IRecord {
  static readonly UniqueConstraints: Array<keyof CartTrackingSupplierEntry> = ['supplier', 'entryDate', 'sequenceNumber'];
  id?: CartTrackingSupplierEntryId;

  @belongsTo('suppliers')
  @property supplier: Supplier;
  supplierId: SupplierId;

  @belongsTo('bols')
  @property bol?: Bol | null;
  bolId?: BolId | null;

  @property entryType: CartTrackingSupplierEntryType;
  @property quantity: CartTrackingSupplierEntryQuantity;
  @property entryDate: CartTrackingSupplierEntryDate;
  @property notes?: CartTrackingSupplierEntryNotes | null;
  @property sequenceNumber?: CartTrackingSupplierEntrySequenceNumber | null;
  @property adjustmentType: CartTrackingSupplierAdjustmentType;
}

setSchemaOptions(CartTrackingSupplierEntry, {
  hasTimestamps: true,
  uniqueConstraints: CartTrackingSupplierEntry.UniqueConstraints,
});

definePresentation(CartTrackingSupplierEntry, {
  quantity: {
    displayName: 'Quantity',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
});
