import { buildAdminListPage } from '../admin';
import { Product } from 'shared/schemas/product';
import { ImportProductPrice } from 'client/components/import-product-price';

export default buildAdminListPage(Product, {
  columns: [
    'identifier',
    'description',
    'customer',
    'sellDepartment',
    'productClass',
    'productSubClass',
    'packSize',
    'sellPack',
    'externalDistribution',
    'scanOrPo',
    'activeStatus',
  ],
  excelColumns: [
    'identifier',
    'description',
    'customer',
    'sellDepartment',
    'productClass',
    'productSubClass',
    'packSize',
    'sellPack',
    'externalDistribution',
    'sku',
    'primaryUpcCode',
    'activePrice',
    'activeRetail',
    'replenishmentIdentifier',
    'scanOrPo',
    'reportTossQuantities',
    'replenishmentStatus',
    'duplicateSkuUpc',
    'packsPerRack',
    'packsPerShelf',
    'shelvesPerRack',
    'casesPerPallet',
    'sellDays',
    'stemCount',
    'stemLength',
    'shipType',
    'box',
    'bucket',
    'requiresProcessing',
    'primaryShippingUnitType',
    'bulkType',
    'height',
    'width',
    'length',
    'cubicFeet',
    'hasRollingRackLabel',
    'bucketBoxLiner',
    'generalLedgerAccountIdentifier',
    'activeStatus',
  ],
  supplementalRecordBarComponent: ImportProductPrice,
});
