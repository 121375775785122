import { change } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';

export const customerChanged = value => change(Constants.formName, 'customerId', value);

export const primaryClicked = (fields, index, name): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    // NOTE: fields (an object) implements its own map function,
    // providing access to array-like data.
    const fieldNames = fields.map(f => f);
    const [clickedField, propertyName] = name.split('.');
    const otherFields = fieldNames.filter(f => f !== clickedField);

    dispatch(change(Constants.formName, `${clickedField}.${propertyName}`, true));
    otherFields.forEach(otherField => {
      dispatch(change(Constants.formName, `${otherField}.${propertyName}`, false));
    });
  };
};
