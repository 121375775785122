import * as _ from 'lodash';
import { Clock } from 'shared/clock';
import { formatDateTime } from 'shared/helpers/date-helpers';

export const logDateTime = () => `[${formatDateTime(Clock.now(), 'YYYY-MM-DD hh:mm:ss A z')}]`;

export enum ValidLogIcons {
  stdout = '📗',
  stderr = '📕️',
  exitCode = '⚠️',
  execute = '🖥',

  queueing = '📥',
  running = '🏃',
  succeeded = '🏁',
  failed = '⚠️',
  exception = '🛑',
  sleeping = '⌛️',
}

export type LogIcons = ValidLogIcons;

export const icons: { [k: string]: LogIcons } = {
  stdout: ValidLogIcons.stdout,
  stderr: ValidLogIcons.stderr,
  exitCode: ValidLogIcons.exitCode,
  execute: ValidLogIcons.execute,

  queueing: ValidLogIcons.queueing,
  running: ValidLogIcons.running,
  succeeded: ValidLogIcons.succeeded,
  failed: ValidLogIcons.failed,
  exception: ValidLogIcons.exception,
  sleeping: ValidLogIcons.sleeping,
};

function logLineWithIcon(icon: LogIcons, line: string, identifier: string = 'NO ID') {
  return `${logDateTime()} [${identifier}]  ${icon}   ${line}`;
}

function logLinesWithIcon(icon: LogIcons, content: string | string[], identifier?: string) {
  const lines: string[] = typeof content === 'string' ? (_.trimEnd(content, '\r\n')).split(/\r?\n/) : content;

  return lines.map(line => logLineWithIcon(icon, line, identifier)).join('\r\n');
}

export function logStdout(content: string, identifier?: string) {
  logWithIcon(ValidLogIcons.stdout, content, identifier);
}

export function logStderr(content: string, identifier?: string) {
  logWithIcon(ValidLogIcons.stderr, content, identifier);
}

export function logExitCode(content: string, identifier?: string) {
  logWithIcon(ValidLogIcons.exitCode, content, identifier);
}

export function logWithIcon(icon: LogIcons, content: string, identifier?: string) {
  console.info(logLinesWithIcon(icon, content, identifier));
}

export function logDeep(args: { [key: string]: any } | any) {
  console.info(JSON.stringify(args, undefined, 2));
  return args;
}
