import * as React from 'react';
import { Col, FormGroup } from 'client/components/third-party';
import { Field } from 'redux-form';
import { LabeledSelect } from 'client/components/form';
import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import { withRecordFilterOptions } from 'client/hoc/graphql/record-filter-options';
import assertCompatible from 'shared/helpers/assert-compatible';
import { AvailableFilter, AvailableFilterOption } from 'client/types';

const optionFormatter = option => option.value;
const emptyArray = [];

interface UIProps {
  availableFilters: AvailableFilter[];
  name: string;
  handleChange?(id: number): void;
}

class YearUI extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <FormGroup>
          <Field
            name={this.props.name}
            options={getDropDownOptionsFromAvailableFilters(this.props.availableFilters)}
            handleChange={this.props.handleChange}
            component={LabeledSelect}
            inputColSize={4}
            label="Year"
            labelColSize={4}
            offset={0}
            textFormatter={optionFormatter}
            valueField="value"
            horizontalLabel={false}
            testid="report-sales-plan-year"
            required
          />
        </FormGroup>
      </Col>
    );
  }
}

const getDropDownOptionsFromAvailableFilters = (availableFilters: AvailableFilter[]): AvailableFilterOption[] => {
  if (!availableFilters || !availableFilters[0] || !availableFilters[0].options) {
    return emptyArray;
  }
  return _.orderBy(availableFilters[0].options, 'displayValue', 'desc');
};

export interface OwnProps {
  name: string;
  handleChange?(id: number): void;
}

interface RecordFilterOptionsProps {
  availableFilters: AvailableFilter[];
}

export type ComponentProps = OwnProps & RecordFilterOptionsProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  withRecordFilterOptions({ table: 'salesPlans', fields: ['year']}),
)(YearUI) as Component<OwnProps>;

export const YearContainer = (props: OwnProps) => propToComponent(component, props);
