import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { RoutePlanLoadsStatsRow as RoutePlanLoadsStatsRowUI, OwnProps as UIProps } from 'client/app/transportation/routing/route-plan-details/loads/stats-row/stats-row-ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import { GetRoutePlanLoadsStats, RoutePlanLoadsStatsQueryResponse } from 'client/app/transportation/routing/route-plan-details/loads/stats-row/stats-row-query';
import { FetchPolicy } from 'apollo-client';

interface OwnProps {
  routePlanId: RoutePlanId | undefined;
}

interface WithRoutePlanSummaryProps {
  stopsCount: number;
  racksCount: number;
  palletsCount: number;
  loadsCount: number;
  storesCount: number;
  milesCombinedWithAdditionalMiles: number;
  totalFee: number;
}

const WithRoutePlanLoadsSummary = msyncQuery<RoutePlanLoadsStatsQueryResponse, OwnProps, WithRoutePlanSummaryProps>(GetRoutePlanLoadsStats, {
  alias: 'WithRoutePlanLoadsSummary',
  skip(ownProps) {
    return !ownProps.routePlanId;
  },
  options(ownProps): { variables: shame, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithRoutePlanSummaryProps {
    const { data } = props;
    const { routePlanLoadsSummary } = data;

    if (!routePlanLoadsSummary || !routePlanLoadsSummary.routePlan || !routePlanLoadsSummary.routePlan.loads) {
      return {
        stopsCount: 0,
        racksCount: 0,
        palletsCount: 0,
        loadsCount: 0,
        storesCount: 0,
        milesCombinedWithAdditionalMiles: 0,
        totalFee: 0,
      };
    }

    return {
      stopsCount: routePlanLoadsSummary.routePlan.loads.dropsCombinedWithAdditionalDrops,
      racksCount: routePlanLoadsSummary.routePlan.loads.racks,
      palletsCount: routePlanLoadsSummary.routePlan.loads.pallets,
      loadsCount: routePlanLoadsSummary.routePlan.loads.loadCount,
      storesCount: routePlanLoadsSummary.routePlan.loads.numberOfStores,
      milesCombinedWithAdditionalMiles: routePlanLoadsSummary.routePlan.loads.milesCombinedWithAdditionalMiles,
      totalFee: routePlanLoadsSummary.routePlan.loads.totalFee,
    };
  },
});

export type ComponentProps =
  OwnProps & WithRoutePlanSummaryProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  WithRoutePlanLoadsSummary,
)(RoutePlanLoadsStatsRowUI) as Component<OwnProps>;

export const RoutePlanLoadsStatsRow = (props: OwnProps) => propToComponent(component, props);

export default RoutePlanLoadsStatsRow;
