import * as React from 'react';
import { Row, FormGroup } from 'client/components/third-party';
import { LoadType, DropoffLocation, LoadDestination, LoadOrigin, Carrier, RouteType } from 'shared/types';
import { SimpleInput } from 'client/components/simple-components/simple-input';
import { SimpleSelect } from 'client/components/simple-components/simple-select';
import { SimpleRadio } from 'client/components/simple-components/simple-radio';

export interface ComponentProps {
  loadType: string;
  carrier: string;
  routeType: string;
  dropoffLocation: string;
  origin: string;
  destination: string;
  trailerTemperature: string;
  routePlanOrigin?: string;

  loadTypeChanged: (value: shame) => void;
  carrierChanged: (value: shame) => void;
  routeTypeChanged: (value: shame) => void;
  dropoffLocationChanged: (value: shame) => void;
  originChanged: (value: shame) => void;
  destinationChanged: (value: shame) => void;
  trailerTemperatureChanged: (value: shame) => void;
}

export class ImportRouteModalContentStepOneUI extends React.PureComponent<ComponentProps, {}> {
  constructor(props: ComponentProps) {
    super(props);
  }

  componentWillMount() {
    // We only want to set the origin if it hasn't been set yet (e.g. the first time we open the modal, not when clicking previous from step 2)
    if (!this.props.origin) {
      this.props.originChanged(this.props.routePlanOrigin);
    }
  }

  render() {
    return (
      <div data-testid="import-route-modal-step-one" className="import-route-modal-step-one">
        <Row>
          <FormGroup>
            <SimpleSelect
              label="Load Type"
              testid="import-route-modal-route-information-load-type"
              options={Object.keys(LoadType)}
              onChange={this.props.loadTypeChanged}
              value={this.props.loadType}
            />
            <SimpleSelect
              label="Dropoff Location"
              testid="import-route-modal-route-information-dropoff-location"
              options={Object.keys(DropoffLocation)}
              onChange={this.props.dropoffLocationChanged}
              value={this.props.dropoffLocation}
            />
          </FormGroup>
        </Row>
        <Row className="import-route-modal-route-information-route-type-row">
          <FormGroup>
            <SimpleRadio
              label="Round Trip"
              onChange={this.props.routeTypeChanged}
              value={this.props.routeType}
              optionValue={RouteType.RoundTrip}
              testid="import-route-modal-route-information-route-type" // generated as import-route-modal-route-information-route-type-RT
            />
            <SimpleRadio
              label="One-way"
              onChange={this.props.routeTypeChanged}
              value={this.props.routeType}
              optionValue={RouteType.OneWay}
              testid="import-route-modal-route-information-route-type" // generated as import-route-modal-route-information-route-type-1W
            />
          </FormGroup>
        </Row>
        <Row className="import-route-modal-route-information-input-row">
          <FormGroup>
            <SimpleSelect
              label="Origin"
              testid="import-route-modal-route-information-origin"
              options={Object.keys(LoadOrigin)}
              onChange={this.props.originChanged}
              value={this.props.origin}
            />
            <SimpleSelect
              label="Destination"
              testid="import-route-modal-route-information-destination"
              options={Object.keys(LoadDestination)}
              onChange={this.props.destinationChanged}
              value={this.props.destination}
            />
          </FormGroup>
        </Row>
        <Row className="import-route-modal-route-information-input-row">
          <FormGroup>
            <SimpleSelect
              label="Carrier"
              testid="import-route-modal-route-information-carrier"
              options={Object.keys(Carrier)}
              onChange={this.props.carrierChanged}
              value={this.props.carrier}
            />
            <SimpleInput
              label="Trailer Temperature"
              testid="import-route-modal-route-information-trailer-temperature"
              onChange={this.props.trailerTemperatureChanged}
              value={this.props.trailerTemperature}
            />
          </FormGroup>
        </Row>
      </div>
    );
  }
}
