import * as React from 'react';
import * as _ from 'lodash';
import { ShippingUnitOrderMethod, OrderMethod } from 'shared/types';

interface Detail {
  productId: number;
  packSize: number;
  packsPerShippingUnit: number | undefined | null;
  shelvesPerRack: number | undefined | null;
  packsPerShelf: number | undefined | null;
  productIdentifier: string;
  productDescription: string;
}

export function getFormattedProductConfiguration(orderMethod: OrderMethod, detail: Detail, key: string) {
  if (orderMethod === ShippingUnitOrderMethod) {
    const ppsText = (detail.packsPerShelf === null || detail.packsPerShelf === undefined)
      ? ''
      : `/ ${_.round(detail.packsPerShelf, 2)} PPS`;
    const sprText = detail.shelvesPerRack === null
      ? ''
      : `/ ${detail.shelvesPerRack} SPR `;

    return <div key={key} className="product-worksheet-products-list-product-shipment-configuration-detail"><span>{`${detail.packSize} Pack ${detail.packsPerShippingUnit ? `/ ${detail.packsPerShippingUnit} PPR` : ''} ${sprText} ${ppsText}`}</span></div>;
  } else {
    return <div key={key} className="product-worksheet-products-list-product-shipment-configuration-detail"><span>{`${detail.packSize} Pack`}{detail.packsPerShippingUnit && ` / ${detail.packsPerShippingUnit} PPP`}</span></div>;
  }
}

export function getHeading(productIdentifier: string, productDescription: string) {
  return `${productIdentifier} - ${productDescription}`;
}
