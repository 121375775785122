import * as GraphQL from 'client/hoc/graphql/query';
import * as State from 'client/state/state';
import * as Selectors from 'client/state/selectors';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import gql from 'graphql-tag';
import { ActiveFilter, ActiveSearch } from 'client/types';
import { CustomerOrderAllocationSummaryTotalsResponse, CustomerOrderAllocationSummaryTotalsInput } from 'schema/customer-order/types';

export interface OwnProps {
  activeFilters: ActiveFilter[];
  activeSearch: ActiveSearch;
  footerProps: { customerOrderId: number };
}

export const CUSTOMER_ORDER_ALLOCATION_SUMMARY_TOTALS_QUERY = gql`
  query CustomerOrderAllocationSummaryTotals($customerOrderId: Int!, $filters: [FilterSpecificationInput], $search: SearchInput) {
    response: CustomerOrderAllocationSummaryTotals(customerOrderId: $customerOrderId, filters: $filters, search: $search) {
      quantity
      totalUnits
      totalPrice
      totalRetail
      rackQuantity
    }
  }
  `;

const WithCustomerOrderDetailTableFooterDataHoc = GraphQL.msyncQuery < { response: CustomerOrderAllocationSummaryTotalsResponse }, OwnProps, AfterQueryProps, CustomerOrderAllocationSummaryTotalsInput>(CUSTOMER_ORDER_ALLOCATION_SUMMARY_TOTALS_QUERY, {
  skip(ownProps) {
    return !ownProps.footerProps || !ownProps.footerProps.customerOrderId;
  },
  options(ownProps) {
    return {
      variables: {
        customerOrderId: ownProps.footerProps.customerOrderId,
        filters: ownProps.activeFilters,
        search: ownProps.activeSearch,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props) {
    if (props.data.loading || !props.data.response) {
      return {
        footerData: {
          loading: props.data.loading,
          quantity: 0,
          totalUnits: 0,
          totalPrice: 0,
          totalRetail: 0,
          rackQuantity: 0,
        },
      };
    }

    return {
      footerData: {
        loading: false,
        quantity: props.data.response.quantity,
        totalUnits: props.data.response.totalUnits,
        totalPrice: props.data.response.totalPrice,
        totalRetail: props.data.response.totalRetail,
        rackQuantity: props.data.response.rackQuantity,
      },
    };
  },
});

interface FooterData {
  loading: boolean;
  quantity: number;
  totalUnits: number;
  totalPrice: number;
  totalRetail: number;
  rackQuantity: number;
}

interface AfterQueryProps {
  footerData: FooterData;
}

type StateProps = AfterQueryProps;

const getFooterDataProps = (obj: { footerData: FooterData, inFlight: boolean }) => obj.footerData;
const getInFlight = (obj: { footerData: FooterData, inFlight: boolean }) => obj.inFlight;
const getFooterData = createSelector(getFooterDataProps, getInFlight, (footerData, inFlight) => {
  return {
    ...footerData,
    loading: inFlight || footerData.loading,
  };
});

const mapStateToProps = (state: State.Type, ownProps: AfterQueryProps): StateProps => {
  const inFlight = Selectors.isGlobalSaveButtonSpinning(state);
  return {
    footerData: getFooterData({ footerData: ownProps.footerData, inFlight }),
  };
};

// TODO: If anyone wants to figure out how this needs to be configured to not have
// shame, please do so.
export const WithCustomerOrderDetailTableFooterData = (component: shame) => {
  const reduxIt = connect<StateProps, any, shame>(mapStateToProps, undefined) as shame;
  return WithCustomerOrderDetailTableFooterDataHoc(reduxIt(component));
};
