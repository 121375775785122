import * as React from 'react';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { ComponentProps } from './related-customer-orders-table';
import { IdentifierCell } from 'client/components/table/identifier-cell';

export type OwnProps = ComponentProps;

const columns: IColumn[] = [
  {
    id: 'identifier',
    accessor: r => <IdentifierCell id={r.id} identifier={r.identifier} targetRoute={'/orders/customer/details'} />,
    header: 'PO #',
    tableEditable: false,
    columnWidth: 22,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'customerName',
    accessor: 'customer.name',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 22,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'orderType',
    accessor: 'orderType',
    header: 'Order Type',
    tableEditable: false,
    columnWidth: 12,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'orderDate',
    accessor: 'orderDate',
    header: 'Delivery Date',
    tableEditable: false,
    columnWidth: 12,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
  {
    id: 'sellDepartmentIdentifier',
    accessor: 'sellDepartment.identifier',
    header: 'Sell Department',
    tableEditable: false,
    columnWidth: 17,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'mfcAreaIdentifier',
    accessor: 'mfcArea.identifier',
    header: 'MFC Area',
    tableEditable: false,
    columnWidth: 15,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
];

export class CustomerOrdersTable extends React.PureComponent<OwnProps, any> {
  private readonly tableComponent: React.StatelessComponent<FilterableTableProps>;

  constructor(props: OwnProps) {
    super(props);
    this.tableComponent = buildFilterableTable(props.tableName);
  }

  public render() {
    return (
      <this.tableComponent
        table={this.props.tableName}
        loading={this.props.loading}
        columns={columns}
        content={this.props.customerOrders || []}
        refetchTable={this.props.refetchTable}
        searchableFields={[]}
        availableFilters={[]}
        placeholder={'There are currently no related Customer POs.'}
        totalUnfilteredCount={this.props.totalUnfilteredCount}
        totalCount={this.props.totalCount}
        loadMoreRecords={this.props.loadMoreRecords}
        filteredRecordIds={this.props.filteredRecordIds}
        disableCreate={true}
        displayLoadingIndicator
        tableParentInfo={this.props.tableParentInfo}
        tablePaginated={false}
        dataRequest={this.props.dataRequest}
        list
      />
    );
  }
}
