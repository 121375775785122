import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import { PrintingListUI, OwnProps } from 'client/app/transportation/routing/route-plan-details/printing/printing-list-ui';
import { WithRoutePlanPrintingList, PrintingListProps } from 'client/app/transportation/routing/route-plan-details/printing/printing-list-query';
import * as Actions from 'client/actions/route-plan-printing';
import * as State from 'client/state/state';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';

const mapStateToProps = (state: State.Type, ownProps: OwnProps & PrintingListProps) => {
  // All buttons that rely on loads should be disabled if we don't have any in the system
  const routingButtonsDisabled = ownProps ? !ownProps.hasLoads : true;

  return {
    sendLoadsToLoadMasterActionStatus: RoutePlanPrintingSelectors.sendLoadsToLoadMasterActionStatus(state),
    emailRouteSheetsToPeakActionStatus: RoutePlanPrintingSelectors.emailRouteSheetsToPeakActionStatus(state),
    downloadStoreAdvanceShipNoticeActionStatus: RoutePlanPrintingSelectors.downloadStoreAdvanceShipNoticeActionStatus(state),
    createCustomerAdvanceShipNoticeActionStatus: RoutePlanPrintingSelectors.createCustomerAdvanceShipNoticeActionStatus(state),
    downloadStoreDeliverySpreadsheetActionStatus: RoutePlanPrintingSelectors.downloadStoreDeliverySpreadsheetActionStatus(state),
    downloadPalletReportActionStatus: RoutePlanPrintingSelectors.downloadPalletReportActionStatus(state),

    createRoutePacketDownloadPdfButtonDisabled: routingButtonsDisabled,
    downloadDittyCardsPDFButtonDisabled: false, // Never disabled, because there is an option to print this without loads/stop
    sendMerchandiserEmailsButtonDisabled: routingButtonsDisabled,
    downloadStoreAdvanceShipNoticesPDFButtonDisabled: routingButtonsDisabled,
    downloadCustomerAdvanceShipNoticesPDFButtonDisabled: routingButtonsDisabled,
    downloadStoreDeliverySpreadsheetButtonDisabled: routingButtonsDisabled,
    sendLoadsToLoadMasterDisabled: routingButtonsDisabled,
    emailRouteSheetsToPeakDisabled: routingButtonsDisabled,
    downloadPalletReportCSVButtonDisabled: routingButtonsDisabled,
  };
};

const mapDispatchToProps = {
  createRoutePacketDownloadPdfButtonClicked: Actions.createRoutePacketDownloadPdfButtonClicked,
  downloadDittyCardsPDFButtonClicked: Actions.downloadDittyCardsPDFButtonClicked,
  sendMerchandiserEmailsButtonClicked: Actions.emailMerchandisersButtonClicked,
  downloadStoreSpecificAdvanceShipNoticesPDFButtonClicked: Actions.downloadStoreSpecificAdvanceShipNoticesPDFButtonClicked,
  downloadCustomerAdvanceShipNoticesPDFButtonClicked: Actions.downloadCustomerAdvanceShipNoticesPDFButtonClicked,
  downloadStoreDeliverySpreadsheetButtonClicked: Actions.downloadStoreDeliverySpreadsheetButtonClicked,
  sendLoadsToLoadMasterClicked: Actions.sendLoadsToLoadMasterClicked,
  emailRouteSheetsToPeakClicked: Actions.emailRouteSheetsToPeakClicked,
  downloadPalletReportCSVButtonClicked: Actions.downloadPalletReportCSVButtonClicked,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector> &
  PrintingListProps;

export default wrapComponent(PrintingListUI)<Partial<OwnProps>, CombinedProps>(
  WithRoutePlanPrintingList,
  connector,
);
