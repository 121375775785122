import * as _ from 'lodash';
import { orThrow, TestError, delay } from './meta';
import { log } from './logger';
// import { ApolloClient, NormalizedCacheObject, ApolloError, useQuery, useMutation, gql } from '@apollo/client';
import { ApolloClient, ApolloError } from 'apollo-client';
import { useState } from 'react';
// import { InteractionManager } from 'react-native';
import { DateTime } from 'luxon';
import { Args, Collapse } from 'shared/types';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

type GQ<S extends string, T> = S & { type: T };
const gqt = <T>(_fn?: (v: string | number | SimpleObject | null) => T) => <S extends string>(s: S) => s as GQ<S, T>;
const fieldTypers = {
  date: gqt(v => `${v}`)('date'),
  jsonb: gqt<SimpleObject>()('jsonb'),
  string: gqt(v => `${v}`)('string'),
  arrayOfString: gqt(v => v as string[])('string[]'),
  number: gqt(v => Number.parseFloat(`${v}`))('number'),
  weekly: gqt<number[]>()('number[]'),
  int: gqt(v => parseInt(`${v}`) as Int)('int'),
  boolean: gqt<boolean>()('boolean'),
} as const;

/**
 * Given a projectin function, provides access to derive props from the raw gql result;
 * be careful to check the types, the raw result is represented as any due to order of operations
 * (this fn is defined before it's containing object is defined)
 * Cannot derive from derived values, and cannot derive from values higher than immediate siblings in the object tree.
 */
const fn = <T>(F: (x: any) => T) => ({F});

type GQT = (typeof fieldTypers)[keyof typeof fieldTypers];
type StrongGQL<O extends SimpleObject = any> = {[Field in keyof O]: GQT | StrongGQL | [StrongGQL] | {F: (x: any) => any}};
// type StrongGQL = {[Field: string]: GQT | StrongGQL | [StrongGQL] | {condition: string, children: [StrongGQL]}};
type GQResult<Q extends StrongGQL>
  = Collapse<{[K in keyof Q]
//  : Q[K] extends {children: [StrongGQL]} ?  Array<GQResult<Q[K]['children'][0]>>
  : Q[K] extends [StrongGQL]             ?  Array<GQResult<Q[K][0]>>
  : Q[K] extends  StrongGQL              ?  GQResult<Q[K]>
  : Q[K] extends        GQT              ?  Q[K]['type']
  : Q[K] extends {F: (x: any) => infer V} ?  V
  : never}>;

type Filtered<Q extends [StrongGQL] = [StrongGQL]> = {condition: string; children: Q};

// export const where = <Q extends [StrongGQL]>(condition: string, children: Q) => ({condition, children}) as Filtered<Q>;
const where = <Q extends [StrongGQL]>(condition: string, children: Q) => ({condition, children}) as unknown as Q;

const gqlLines = <Q extends StrongGQL>(q: Q): string[] =>
  _(q)
  .toPairs()
  .flatMap(([k, v]) =>
    typeof v === 'string'                              ? [`${k}`]
    : _.isObject(v) && 'F' in v                       ? [''/*client-side projection; emit no gql for this*/]
    : _.isArray(v)                                    ? [`${k} {`, ...(gqlLines(v[0] as StrongGQL).map(l => `  ${l}`) as string[]), `}` ]
    : _.isArray((v as unknown as Filtered).children)  ? [`${k}${(v as unknown as Filtered).condition} {`, ...(gqlLines((v as unknown as Filtered).children[0] as StrongGQL).map(l => `  ${l}`) as string[]), `}` ]
    :                                                   [`${k} {`,    ...(gqlLines(v as StrongGQL).map(l => `  ${l}`) as string[]), `}` ]
  )
  .value() as unknown as string[];

const formatObjects = <O extends {}>(objects: O[]) => objects.map(o => '\n{ ' + _.toPairs(o).map(([k, v]) => `${k}: ${v}`).join(', ') + ' }').join(',');

const activeTelemetryLoop = { running: false, loop: Promise.resolve() };
const connectTelemetry = async (
  AuthenticatedUserContext: {get: () => void | {id: number}},
  gqlClient: ApolloClient<NormalizedCacheObject>,
  telemetryProxy: Readonly<{telemetryBacklog: Array<{verb: string, payload: unknown}>}>,
) => {
  const user_id = (AuthenticatedUserContext.get() || {id: 0}).id;
  if (user_id <= 0) {
    log.trace('useTelemetry', 'user not logged in');
    return;
  }

  if (!gqlClient || 'function' === typeof (((gqlClient || {}) as any).then)) {
    log.trace('useTelemetry', 'gqlClient not ready');
    return;
  }

  if (activeTelemetryLoop.running) {
    log.trace('connectTelemetry', 'telemetry already running; shutting down now');
    activeTelemetryLoop.running = false;
    await activeTelemetryLoop.loop;
    log.trace('connectTelemetry', 'telemetry shut-down complete');
  }

  log.verbose(`Setting Telemetry -- for user ${user_id}`);
  activeTelemetryLoop.loop = new Promise<void>(async resolve => {
    activeTelemetryLoop.running = true;
    let sending = false;
    while (activeTelemetryLoop.running) {
      await delay(50);
      if (sending) { continue; }
      if (telemetryProxy.telemetryBacklog.length === 0) { continue; }

      // await InteractionManager.runAfterInteractions(async () => {
      await new Promise<void>(resolveLoopIteration => requestAnimationFrame(async () => {
        try {
          sending = true;
          const entries = telemetryProxy.telemetryBacklog.slice(0, Math.min(20, telemetryProxy.telemetryBacklog.length));
          const variables = _.fromPairs(_.flatMap(entries, (e, i) => [[`verb${i}`, e.verb], [`payload${i}`, e.payload]]).concat([['user_id', user_id]]));
          const variableBindings = entries.map((_e, i) => `$verb${i}: String, $payload${i}: jsonb`);
          const variableEvalutions = entries.map((_e, i) => `{user_id: $user_id, verb: $verb${i}, payload: $payload${i}}`);
          const gqlString =
`mutation Log($user_id: Int, ${variableBindings.join(', ')}) {
  insert_merch_log(objects: [
    ${variableEvalutions.join(',\n    ')}
  ]) {
    returning {
      id
      created_at
    }
  }
}`;

          const response = await gqlClient.mutate({mutation: gql`${gqlString}`, variables});
          const recordCount = response?.data?.insert_merch_log?.returning?.length ?? 0;
          telemetryProxy.telemetryBacklog.splice(0, recordCount);
          console.info(`TELEMETRY LOGGED ${recordCount}`);
        } catch (err) {
          if (err.message.includes('Authentication hook unauthorized') || err.message.includes('JWTExpired')) {
            log.error('Telemetry loop ABENDED', err);
            activeTelemetryLoop.running = false;
          } else {
            log.error('Telemetry loop   * would have *   ABENDED  - but I am wondering how often such a thing happens, so letting chug along for a while now', err);
          }

          await delay(5000);
        } finally {
          sending = false;
          resolveLoopIteration();
        }
      }));
    }
    resolve();
  }).catch(log.error) as Promise<void>;
};

const useInsert = <O extends {}, Q extends StrongGQL<O>>(entity: string, objects: Array<Partial<O>>, returning: Q) => {
  const graphQlText = `mutation {\ninsert_${entity}(objects: [${formatObjects(objects)}]) {\n${gqlLines(returning).map(l => `    ${l}`).join('\n')}\n  }\n}`;
  const documentNode = gql`${graphQlText}`;
  const [insertCore, { data, error}] = useMutation(documentNode, {refetchQueries: ['get__' + entity]});
  const insert: typeof insertCore = async (...args) => {
    const start = DateTime.now();
    const result = await insertCore(...args);
    const end = DateTime.now();
    log.network({elapsed: end.diff(start).toMillis(), mutation: graphQlText, objects, args});
    return result;
  };

  if (!_.isNil(error)) throw error;
  return [insert, data] as [typeof insert, typeof data];
};

const noopHandler = async <E extends Error>(err: E, refetch: () => Promise<unknown>): Promise<string | void> => { _.noop(err, refetch); };
const SpecialErrorHandlers = {
  'network': noopHandler,
  'JWTExpired': noopHandler,
  'Authentication hook unauthorized': noopHandler,
  'user_id': noopHandler,
  'while query was in flight': noopHandler,
};

const setSpecialErrorHAndlers = (handlers: Partial<typeof SpecialErrorHandlers>) => {
  log.info(`Installing special GQL error handlers: ${_.keys(handlers).join(', ')}`);
  Object.assign(SpecialErrorHandlers, handlers);
};

const TestGqlError = { testError: undefined as ApolloError | undefined };
const SetTestError = (err: TestError | undefined) => log.info(`Setting GQL TestError`, TestGqlError.testError = err as any);
const GetTestError = () => TestGqlError.testError;

const getDocumentNode = <Q extends StrongGQL>(q: Q) => {
  const root = _.keys(q)[0];
  const graphQlText = `query get__${root} {\n${gqlLines(q).map(l => `  ${l}`).join('\n')}\n}`;
  const documentNode = gql`${graphQlText}`;
  return Object.assign(documentNode, {graphQlText});
};

const query = async <Q extends StrongGQL>(client: ApolloClient<NormalizedCacheObject>, options: Partial<Args<ApolloClient<NormalizedCacheObject>['query']>[0]>, q: Q) => {
  const start = DateTime.now();
  const result = client.query<GQResult<Q>>({...options, query: getDocumentNode(q)} as shame);
  const end = DateTime.now();
  log.network({elapsed: end.diff(start).toMillis(), options, query: q});
  return result;
};

export const MaGiQl = Object.assign(<Q extends StrongGQL>(q: Q) => {
  const root = _.keys(q)[0];
  const [temporaryError, setTemporaryError] = useState<string | void>();
  const documentNode = getDocumentNode(q);
  const { data, error, loading, refetch } = useQuery<GQResult<Q>>(documentNode, {fetchPolicy: 'cache-and-network', context: {api: 'hasura'}});
  const err = error ?? TestGqlError.testError;
  if (_.isNil(err))
    return {...(data ?? {[root]: undefined} as unknown as GQResult<Q>), loading, refetch, temporaryError};

  const [, handler] = _.toPairs(SpecialErrorHandlers).find(([pattern]) => err.message.includes(pattern)) || [];
  if (!_.isNil(handler)) {
    void handler(err!, refetch).then(setTemporaryError);
    return {...(data ?? {[root]: undefined} as unknown as GQResult<Q>), loading: true, refetch, temporaryError};
  }

  return orThrow(log.error(Object.assign(error ?? TestGqlError.testError ?? {}, {graphQlText: documentNode.graphQlText}) as shame) as shame);
}, fieldTypers, {fn, where, useInsert, connectTelemetry, setSpecialErrorHAndlers, SetTestError, GetTestError, query});
