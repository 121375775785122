import gql from 'graphql-tag';
import { DateTimeStr, ShippingUnitType, OrderMethod, FilterSpecificationInput, SearchInput, SortInput } from 'shared/types';
import { BolIdentifier, BolId } from 'shared/schemas/bol';
import { UserId, UserFirstName, UserLastName } from 'shared/schemas/user';

export interface RelatedBolsVariables {
  id: number;
  sort?: SortInput[];
  filters?: FilterSpecificationInput[];
  search?: SearchInput;
  limit?: number;
  offset?: number;
}

export interface RelatedBolsRow {
  id: BolId;
  identifier: BolIdentifier;
  receivedAt: DateTimeStr;
  notes: string | null;
  racksReturned: number;
  packQuantityReceived: number;
  shippingUnitQuantityReceived: number;
  user: {
    id: UserId;
    firstName: UserFirstName;
    lastName: UserLastName;
  };
}

export interface RelatedBolsResponse {
  receivableOrder?: {
    id: number;
    identifier: string;
    records: RelatedBolsRow[] | null;
  };
}

export const RelatedBolsQuery = gql`
  query RelatedBolsQuery($id: Int!, $sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    receivableOrder: GetUnifiedReceivableOrder(id: $id) {
      id
      identifier
      records: bols(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        identifier
        receivedAt
        racksReturned
        notes
        packQuantityReceived
        shippingUnitQuantityReceived
        receiver
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export interface ReceivableOrderInfoResponse {
  receivableOrder?: {
    id: number;
    shippingUnitType: ShippingUnitType;
    orderMethod: OrderMethod;
  };
}

export const ReceivableOrderInfoQuery = gql`
  query ReceivableOrderInfoForRelatedBols($receivableOrderId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      shippingUnitType
      orderMethod
    }
  }
`;

export const FilterableQuery = gql`
  query GetUnifiedReceivableOrderBolsFilterOptionsQuery($id: Int!) {
    response: GetUnifiedReceivableOrderBolsFilterOptions(id: $id) {
      receivers {
        id
        value
        displayValue
      }
    }
  }
`;
