import * as React from 'react';
import { FormGroup } from 'client/components/third-party';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { LabeledInput, LabeledRadio } from 'client/components/form';
import { makeFormValidator, MAX_LENGTH, REQUIRED_FIELD_VALIDATOR } from 'shared/validators';

export interface Props {
  onPrimaryClicked: shame;
  handleAddContactClick: shame;
  handleDeleteContactClick: shame;
  displayContacts: shame;
}
const maxLength30Validator = makeFormValidator(MAX_LENGTH(30));

export class VendorContactFormGroup extends React.Component<WrappedFieldArrayProps & Props> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div data-testid="supplier-contacts">
        {this.props.fields.map((fieldName, index) => {
          const onHandlePrimaryClick = () => {
            this.props.onPrimaryClicked(this.props.fields, index, `${fieldName}.primary`);
          };

          const displayContact = this.props.displayContacts[index];
          let headingText = 'New Contact';
          if (displayContact?.name) {
            headingText = displayContact.name;
          }
          const email = displayContact ? displayContact.email : null;

          const primary = displayContact ? displayContact.primary : null;

          return (
            <div key={index} data-testid="supplier-contact">
              <div className="mfc-form-heading">
                {headingText}
                <div className="pull-right">
                  {(email && <span className="mfc-simple-icon-button"><a href={`mailto:${email}`}><span className="fa fa-envelope" /></a></span>)}
                  <span
                    className="mfc-simple-icon-button"
                    data-testid={`contact-${index}-delete-button`}
                    onClick={this.props.handleDeleteContactClick(this.props.fields, index, primary)}
                  ><span className="fa fa-trash" /></span>
                </div>
              </div>

              <FormGroup data-testid={`contact-${index}-row-5`}>
                <Field
                  component={LabeledRadio}
                  name={`${fieldName}.primary`}
                  label="Primary Contact"
                  labelColSize={2}
                  inputColSize={3}
                  offset={2}
                  optionValue
                  handleChange={onHandlePrimaryClick}
                  testid="primary"
                />
              </FormGroup>

              <FormGroup data-testid={`contact-${index}-row-1`}>
                <Field
                  component={LabeledInput}
                  name={`${fieldName}.name`}
                  label="Name"
                  labelColSize={2}
                  inputColSize={9}
                  testid="name"
                  validate={[
                    maxLength30Validator,
                    REQUIRED_FIELD_VALIDATOR,
                  ]}
                required
                />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-3`}>
                <Field
                  component={LabeledInput}
                  name={`${fieldName}.email`}
                  label="Email"
                  labelColSize={2}
                  inputColSize={9}
                  testid="email"
                />
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-4`}>
                <Field
                  component={LabeledInput}
                  name={`${fieldName}.phoneOffice`}
                  label="Office"
                  labelColSize={2}
                  inputColSize={4}
                  testid="phoneOffice"
                />
                <Field
                  component={LabeledInput}
                  name={`${fieldName}.phoneCell`}
                  label="Cell"
                  labelColSize={1}
                  inputColSize={4}
                  testid="phoneCell"
                />
              </FormGroup>
            </div>
          );
        })}

        <div className="text-center">
          <div className="mfc-form-button add-contact-button" data-testid="qa-add-contact-button" onClick={this.props.handleAddContactClick(this.props.fields)}>
            <span className="fa fa-plus-circle" /> Add New Contact
          </div>
        </div>
      </div>
    );
  }
}
