import * as React from 'react';
import { Modal, Col } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { Checkbox } from 'client/components/form/checkbox';
import * as _ from 'lodash';

export interface ComponentProps {
  isShown: boolean;
  createButtonEnabled: boolean;
  routePlanId?: RoutePlanId;
  purchaseOrderInvoiceCheckboxDisabled: boolean;
  customerOrderIdsToBeInvoiced: number[];

  handleCancelButtonClicked(): void;
  handleCreateButtonClicked(args: { routePlanId?: RoutePlanId, specialInstructionsPdfFile?: File | null, customerOrderIdsToBeInvoiced: number[] }): void;

  routeSheetsChecked: boolean;
  deliveryAcknowledgementsChecked: boolean;
  purchaseOrderInvoicesChecked: boolean;
  returnInstructionsChecked: boolean;
  specialInstructionsChecked: boolean;
  markOrdersAsShippedChecked: boolean;

  routeSheetsClicked(): void;
  deliveryAcknowledgementsClicked(): void;
  purchaseOrderInvoicesClicked(): void;
  purchaseOrderInvoicesChanged(selected: boolean): void;
  returnInstructionsClicked(): void;
  specialInstructionsClicked(): void;
  markOrdersAsShippedClicked(): void;
}

export class CreateRoutePacketModalUI extends React.PureComponent<ComponentProps, {}> {
  handleCreateButtonClicked = () => {
    this.props.handleCreateButtonClicked({
      routePlanId: this.props.routePlanId,
      specialInstructionsPdfFile: this.props.specialInstructionsChecked ? this.specialInstructionsPdfFile : undefined,
      customerOrderIdsToBeInvoiced: this.props.customerOrderIdsToBeInvoiced,
    });
  }

  private specialInstructionsPdfFile: File | null = null;

  routeSheetsCheckboxInput = () => ({ value: this.props.routeSheetsChecked, onChange: this.props.routeSheetsClicked });
  deliveryAcknowledgementsCheckboxInput = () => ({ value: this.props.deliveryAcknowledgementsChecked, onChange: this.props.deliveryAcknowledgementsClicked });
  purchaseOrderInvoicesCheckboxInput = () => ({ value: this.props.purchaseOrderInvoicesChecked, onChange: this.props.purchaseOrderInvoicesClicked });
  returnInstructionsCheckboxInput = () => ({ value: this.props.returnInstructionsChecked, onChange: this.props.returnInstructionsClicked });
  specialInstructionsCheckboxInput = () => ({ value: this.props.specialInstructionsChecked, onChange: _.noop });
  markOrdersAsShippedCheckboxInput = () => ({ value: this.props.markOrdersAsShippedChecked, onChange: this.props.markOrdersAsShippedClicked });

  onSpecialInstructionsPdfFileClicked = (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (this.props.specialInstructionsChecked) {
      this.specialInstructionsPdfFile = null;

      // clear out file from input field
      const element = document.querySelector('#create-route-packet-modal-special-instructions-pdf');
      if (element) {
        (element as shame).value = null;
      }

      event.preventDefault();
      event.nativeEvent.preventDefault();

      event.stopPropagation();
      event.nativeEvent.stopPropagation();

      this.props.specialInstructionsClicked();

      return false;
    }
  }

  onSpecialInstructionsPdfFileChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const files = (event.target as any).files;
    if (files.length !== 1) {
      return;
    }

    const file: File = files[0];
    if (file) {
      this.specialInstructionsPdfFile = file;
      this.props.specialInstructionsClicked();
    }
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="create-route-packet-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="create-route-packet-modal-container"
        className="create-route-packet-modal" >
        <GlobalAsyncModalHeader>Create Route Packets</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <div className="create-route-packet-modal-detail">Select which documents to include in the route packet:</div>
            <Checkbox testid="create-route-packet-modal-route-sheets-route-sheets" className="create-route-packet-modal-checkbox" input={this.routeSheetsCheckboxInput()} label="Route Sheets" />
            <Checkbox testid="create-route-packet-modal-route-sheets-delivery-acknowledgements" className="create-route-packet-modal-checkbox" input={this.deliveryAcknowledgementsCheckboxInput()} label="BOL / Delivery Acknowledgements" />
            <Checkbox
              testid="create-route-packet-modal-route-sheets-purchase-order-invoices"
              disabled={(this.props.purchaseOrderInvoiceCheckboxDisabled || _.isNil(this.props.purchaseOrderInvoiceCheckboxDisabled)) ? true : false }
              className="create-route-packet-modal-checkbox"
              input={this.purchaseOrderInvoicesCheckboxInput()}
              label="Purchase Order Invoices" />
            <Checkbox
              disabled={!this.props.routeSheetsChecked}
              testid="create-route-packet-modal-route-sheets-return-instructions"
              className="create-route-packet-modal-checkbox"
              input={this.returnInstructionsCheckboxInput()}
              label="Return Instructions" />
            <Checkbox
              disabled={!this.props.routeSheetsChecked}
              testid="create-route-packet-modal-route-sheets-special-instructions"
              className="create-route-packet-modal-checkbox"
              input={this.specialInstructionsCheckboxInput()}>
              Special Instructions

              <input
                disabled={!this.props.routeSheetsChecked}
                type="file"
                id="create-route-packet-modal-special-instructions-pdf"
                data-testid="create-route-packet-modal-special-instructions-pdf"
                onClick={this.onSpecialInstructionsPdfFileClicked}
                onChange={this.onSpecialInstructionsPdfFileChanged}
                style={{ display: 'none' }}
                accept=".pdf" />
              </Checkbox>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <div className="create-route-packet-modal-footer-left pull-left">
            <Checkbox testid="create-route-packet-modal-route-sheets-mark-orders-as-shipped" className="create-route-packet-modal-checkbox" input={this.markOrdersAsShippedCheckboxInput()} label="Mark orders as shipped" />
          </div>

          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="create-route-packet-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Create"
            testid="create-route-packet-modal-create-button"
            disabled={!this.props.createButtonEnabled}
            onClick={this.handleCreateButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
