import * as State from 'client/state/invoice';
import * as Actions from 'client/app/billing/invoices/list/actions';
import * as SidebarActions from 'client/app/billing/invoices/sidebar';
import * as EmailInvoiceActions from 'client/app/billing/invoices/send-invoice-modal/actions';
import * as AcumaticaModalActions from 'client/app/billing/invoices/send-invoices-to-acumatica-modal/actions';
import * as EdiModalActions from 'client/app/billing/invoices/send-invoices-via-edi-modal/actions';
import * as InvoiceTableActions from 'client/app/billing/invoices/invoice-table/actions';
import * as RoutePlanModalActions from 'client/app/billing/invoices/add-route-plans-modal/actions';
import * as VendorChargebackLineItemModalActions from 'client/app/billing/invoices/add-vendor-chargeback-line-item-modal/actions';
import * as R from 'ramda';

type ActionTypes =
  | Actions.ActionTypes
  | SidebarActions.ActionTypes
  | EmailInvoiceActions.ActionTypes
  | AcumaticaModalActions.ActionTypes
  | EdiModalActions.ActionTypes
  | InvoiceTableActions.ActionTypes
  | RoutePlanModalActions.ActionTypes
  | VendorChargebackLineItemModalActions.ActionTypes
  ;

export default function invoice(state = State.InitialState, action: ActionTypes): State.InvoiceStateType {
  switch (action.type) {
    case AcumaticaModalActions.ActionTypeKeys.SendInvoicesToAcumaticaModalCloseClicked:
    case AcumaticaModalActions.ActionTypeKeys.SendInvoicesToAcumaticaCompleted:
      return State.isSendInvoicesToAcumaticaModalShown.set(false)(state);

    case Actions.ActionTypeKeys.SendInvoicesToAcumaticaMenuItemClickedAction:
      return R.pipe(
        State.selectedRecordIds.set(action.payload.invoiceIds),
        State.isSendInvoicesToAcumaticaModalShown.set(true),
      )(state);

    case EdiModalActions.ActionTypeKeys.SendInvoicesViaEdiModalCloseClicked:
    case EdiModalActions.ActionTypeKeys.SendInvoicesViaEdiCompleted:
      return State.isSendInvoicesViaEdiModalShown.set(false)(state);

    case Actions.ActionTypeKeys.SendInvoicesViaEdiMenuItemClickedAction:
      return R.pipe(
        State.selectedRecordIds.set(action.payload.invoiceIds),
        State.isSendInvoicesViaEdiModalShown.set(true),
      )(state);

    case EmailInvoiceActions.ActionTypeKeys.EmailInvoiceCompleted:
    case EmailInvoiceActions.ActionTypeKeys.EmailInvoiceModalCloseClicked:
      return State.isEmailInvoiceModalShown.set(false)(state);

    case SidebarActions.ActionTypeKeys.EmailInvoiceDropdownMenuItemClicked:
      return State.isEmailInvoiceModalShown.set(true)(state);

    case InvoiceTableActions.ActionTypeKeys.AddRoutePlansToInvoiceButtonClicked:
      return State.isAddRoutePlansModalShownLens.set(state, true);

    case RoutePlanModalActions.ActionTypeKeys.AddRoutePlansModalCloseClicked:
    case RoutePlanModalActions.ActionTypeKeys.AddRoutePlansToInvoiceCompleted:
      return State.isAddRoutePlansModalShownLens.set(state, false);

    case InvoiceTableActions.ActionTypeKeys.AddVendorChargebackLineItemsButtonClicked:
      return State.isAddVendorChargebackLineItemsModalShownLens.set(state, true);

    case VendorChargebackLineItemModalActions.ActionTypeKeys.AddVendorChargebackLineItemsModalCloseClicked:
    case VendorChargebackLineItemModalActions.ActionTypeKeys.AddVendorChargebackLineItemsCompleted:
      return State.isAddVendorChargebackLineItemsModalShownLens.set(state, false);

    default:
      return state;
  }
}
