import * as React from 'react';
import { RecordBarDetail } from '../../record-bar';

/*
RecordBarDetailComponent.propTypes = {
  record: React.PropTypes.object,
};
*/
class RecordBarDetailComponent extends React.Component<shame> {
  render() {
    const { record } = this.props;

    return (
      <RecordBarDetail title="Identifier">
        {record.identifier}
      </RecordBarDetail>
    );
  }
}
export default RecordBarDetailComponent;
