import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import * as ImportSalesPlanActions from '../../../import-sales-plan-modal/actions';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as State from 'client/state/state';
import { ProductVolumeRow } from '../../types';
import * as Selectors from './selectors';

interface ContainerProps {
  endWeek: number | undefined;
  handleProductSelected(productId: number): void;
  height: number;
  loading: boolean;
  rows: ProductVolumeRow[];
  startWeek: number | undefined;
  width: number;
  self: any;
  twin: any;
}

interface StateProps {
  selectedProductId: number | null;
}

const mapStateToProps = (state: State.Type, ownProps: ContainerProps): StateProps => {
  return {
    selectedProductId: Selectors.selectedProductIdSelector(state),
  };
};

type DispatchProps = {
  handleImportModalOpenModalButtonClicked(): void;
};

const mapDispatchToProps: DispatchProps = {
  handleImportModalOpenModalButtonClicked: ImportSalesPlanActions.ImportModalOpenModalButtonClicked,
};

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
)(UI.WeeklyProductVolumeTableUI);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  StateProps;

assertCompatible<UI.UIProps, CombinedProps>();
export const WeeklyProductVolumeTableContainer = (props: ContainerProps) => propToComponent(component, props);
