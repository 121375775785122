
// These enums are avaliable to use across the entire
// application, and are automatically inserted into
// the graphql schema.
// Avoid using spaces in the enum values, as this will
// mess up the graphql enum.
// We want to avoid prioritizing display-like values
// for these enums as well.

export enum Carrier {
  MTI = 'MTI',
  PEAK = 'PEAK',
}

export enum LoadType {
  MADD = 'MADD',
  SEAS = 'SEAS',
}

export enum RouteType {
  OneWay = '1W',
  RoundTrip = 'RT',
}

export enum ChargeType {
  FF = 'FF',
  MR = 'MR',
}

export enum DropoffLocation {
  Indoor = 'Indoor',
  Outdoor = 'Outdoor',
}

export enum LoadOrigin {
  Masterpiece = 'Masterpiece',
  Whitewater = 'Whitewater',
}

export enum LoadDestination {
  Masterpiece = 'Masterpiece',
  Whitewater = 'Whitewater',
}

export enum RoutePlanActionTypes {
  CreateRoutePackets = 'Create Route Packets',
  DownloadDittyCards = 'Download Ditty Cards',
  EmailMerchandiserAsn = 'Email Merchandiser ASN',
  CreateCustomerAsn = 'Create Customer ASN',
  DownloadStoreDeliverySpreadsheet = 'Download Store Delivery Spreadsheet',
  DownloadStoreAsn = 'Download Store ASN',
  SendLoadsToLoadmaster = 'Upload to Loadmaster',
  EmailRouteSheetsToPeak = 'Email Route Sheets to Peak',
  CreatePalletReport = 'Create Pallet Report',
  CreateReceivingForm = 'Create Receiving Form',
}

/**
 * There are two ways to download ditty cards:
 * * The printing page on a route plan
 * * Selecting certain loads on the load list
 *
 * The path to download an "unrouted" ditty card is to create a route plan,
 * attach a few orders, and then navigate to the printing page to download ditty cards.
 * Loads & stops do not exist at this point.
 * If loads exist, it would be routed, and either generic or standard ditty cards would be printed.
 */
export enum DittyCardType {
  /** A route plan has been created, orders have been attached, but neither loads or stops have been created. */
  Unrouted = 'Unrouted',
  /** Loads and stops have been creeated - we have enough information to print out cards for racks */
  Routed = 'Routed',

  // The ones below here shouldn't really be mixed in with the types above it, but
  // there's too much to fix right now if I pull them out. So staying here for now.

  /** For Pack-based orders that are shipping on racks. Unable to determine what will be on the cart. */
  Generic = 'Generic',
  /** For Rack-based orders. We know what will be on these carts. */
  Standard = 'Standard',
}

export enum LoadConfiguration {
  Rows = 'Rows',
  Columns = 'Columns',
}

export enum CustomerOrderWorkSheetType {
  Garden = 'Garden',
  Floral = 'Floral',
  Distribution = 'Distribution',
  Unknown = 'Unknown',
}

/**
 * This enum should contain any report that has user params and presets.
 * The Report User Params Record Service has a type that allows you to specify what
 * the user params for a given report will look like coming out of the database,
 * so it's important that this type maintain its integrity by only having
 * reports with their own UI and that have user params and presets.
 */
export enum ReportTypes {
  CartEstimateReport = 'CartEstimateReport',
  DistributionRackShippingWorksheet = 'DistributionRackShippingWorksheet',
  ReplenishmentByStore = 'ReplenishmentByStore',
  ReplenishmentByProduct = 'ReplenishmentByProduct',
  StoreOnHand = 'StoreOnHand',
  HmgOps = 'HmgOps',
  CutProcessing = 'CutProcessing',
  LoadTrackingList = 'LoadTrackingList',
  PerformanceReport = 'PerformanceReport',
  ShippingStatusWarehouse = 'ShippingStatusWarehouse',
  StoreDeliverySpreadsheet = 'StoreDeliverySpreadsheet',
  SalesReport = 'SalesReport',
  MobileSalesPerformanceReport = 'MobileSalesPerformanceReport',
  BuyerReport = 'BuyerReport',
  SupplierPerformanceReport = 'SupplierPerformanceReport',
  SalesPlanVarietiesExport = 'SalesPlanVarietiesExport',
  SupplierFutureCommitmentReport = 'SupplierFutureCommitmentReport',
  HardgoodsDataExport = 'HardgoodsDataExport',
  DifferenceCalculationReport = 'DifferenceCalculationReport',
}

// TODO: Add the rest of the current PDF types (`KnownPdfTypes`) to this enum when we decide
// to also add those to the reports table.
export enum InvoicePdfTypes {
  CustomerOrderInvoicePdf = 'CustomerOrderInvoicePdf',
  DropShipInvoicePdf = 'DropShipInvoicePdf',
  VendorChargebackInvoicePdf = 'VendorChargebackInvoicePdf',
}

export enum CheckPdfTypes {
  Check = 'Check',
}

export enum FormTypes {
  ReceivingWorksheetForDistributionOrders = 'ReceivingWorksheetForDistributionOrders',
}

export enum OutboundEdiTypes {
  MeijerInvoiceEDI810 = 'MeijerInvoiceEDI810',
  HomeDepotInvoiceEDI810 = 'HomeDepotInvoiceEDI810',
}

export enum DataExtractTypes {
  RoutePlanExtractForDirectRoute = 'RoutePlanExtractForDirectRoute',
  ExcelForTable = 'ExcelForTable',
  CustomerOrderSpreadsheet = 'CustomerOrderSpreadsheet',
  CustomerOrderDetailSpreadsheet = 'CustomerOrderDetailSpreadsheet',
  SupplierOrderDetailSpreadsheet = 'SupplierOrderDetailSpreadsheet',
}

/**
 * These could also be put under report types above, but since they don't have user params or their own interface,
 * I think it's better to keep them separated out here.
 */
export enum PlanningReportTypes {
  SalesPlanSpreadsheet = 'SalesPlanSpreadsheet',
  SupplierCommitmentSpreadsheet = 'SupplierCommitmentSpreadsheet',
}

/**
 * The union of any enums or types that describe what artifacts can be created in M-sync.
 * Basically, the list of possible types and values that can be inserted into the `reports` table.
 */
export type ArtifactTypes = ReportTypes | InvoicePdfTypes | CheckPdfTypes | OutboundEdiTypes | FormTypes | DataExtractTypes | PlanningReportTypes;

export enum LoadTrackingSortByOptions {
  LoadPrefix = 'LoadPrefix',
  LoadNumber = 'LoadNumber',
  TrailerNumber = 'TrailerNumber',
  StoreDeliveryDate = 'StoreDeliveryDate',
}

export enum PerformanceReportDateColumnBehavior {
  ThisYearLastYearDateRanges = 'ThisYearLastYearDateRanges',
  CalendarYearDateRange = 'CalendarYearDateRange',
}

export enum PerformanceReportStoreDetailLevel {
  Total = 'Total',
  MFCArea = 'MFC Area',
  Region = 'Region',
  Market = 'Market',
  State = 'State',
  Store = 'Store',
}

export enum PerformanceReportDateDetailLevel {
  Total = 'Total',
  Day = 'Day',
  Week = 'Week',
}

export enum PerformanceReportProductGroupingBehavior {
  RollUpToParent = 'RollUpToParent', // default
  ShowChildDetails = 'ShowChildDetails',
  ShowUpcDetails = 'ShowUpcDetails',
}

export enum SalesReportProductDetailLevel {
  Class = 'Class',
  Subclass = 'Subclass',
  Product = 'Product',
}

export enum ScanBasedOrPoBased {
  scanBased = 'ScanBased',
  poBased = 'POBased',
}

export enum ReplenishmentByStoreGroupingOptions {
  GroupByStore = 'GroupByStore', // default
  NoGrouping = 'NoGrouping',
}

export enum ReplenishmentByProductGroupingOptions {
  GroupByProduct = 'GroupByProduct', // default
  NoGrouping = 'NoGrouping',
}

export enum SalesPlanVarietyStatus {
  Complete = 'Complete',
  NotComplete = 'NotComplete',
}

export enum SupplierCommitmentConfirmedStatus {
  Confirmed = 'Confirmed',
  NotConfirmed = 'Not Confirmed',
}

export enum ReceivableOrderType {
  SupplierOrder = 'SupplierOrder',
  CustomerOrder = 'CustomerOrder',
}
