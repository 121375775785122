import * as React from 'react';
import { MenuItem } from 'client/components/third-party';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import * as classNames from 'classnames';
import { isActiveRoute } from 'client/helpers/route-helpers';

interface OwnProps {
  title: string;
  route: string;
  close?: () => void;
  testid?: string;
}

interface DispatchProps {
  routeTo(route: string): void;
}

const mapDispatchToProps = dispatch => {
  return {
    routeTo: route => dispatch(push(route)),
  };
};

function TertiaryNavLink(props: OwnProps & DispatchProps) {
  const { title, route, close, routeTo, testid } = props;
  const location = useLocation();

  const onClick = () => {
    if (close) {
      close();
    }

    routeTo(route);
  };

  const className = classNames('mfc-dropdown-menu-item', {
    checked: !!(route && isActiveRoute(route, location)),
  });

  return (
    <MenuItem className={className} onClick={onClick} data-testid={testid}>
      {title}
    </MenuItem>
  );
}

export default connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(TertiaryNavLink);
