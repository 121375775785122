import * as React from 'react';
import { StoreListItem } from './store-list-item';
import { GlobalStoreIdentifier } from 'shared/types';

export interface ComponentProps {
  storeIdentifiers: GlobalStoreIdentifier[];
  isFullyAllocatedByStoreNumber: { [k: string]: boolean };
}

export class StoreListUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div className="import-route-store-list">
        { this.props.storeIdentifiers.map(storeIdentifier =>
        <StoreListItem
          key={storeIdentifier.storeNumber + storeIdentifier.customerIdentifier}
          storeIdentifier={storeIdentifier}
          isFullyAllocated={this.props.isFullyAllocatedByStoreNumber[storeIdentifier.storeNumber]}
          />)}
      </div>
    );
  }
}
