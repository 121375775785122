import * as Redux from 'redux';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as GraphQLTypes from 'schema/invoice/invoice-graphql-types';
import gql from 'graphql-tag';
import { GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME } from './query';
import { InvoiceType } from 'shared/types';
import { assertUnreachable, idsFor } from 'shared/helpers/andys-little-helpers';
import { editLastEditableCell, uncheckMultipleRecords } from 'client/actions/table';
import * as Constants from './constants';
import * as _ from 'lodash';
import { GET_INVOICE_STATS_QUERY_NAME } from '../stats-row/query';
import { timeout } from 'shared/helpers';
import { ConfirmOkToSaveFunction } from '../shared/save-confirmation';

export type ActionTypes =
  AddRoutePlansToInvoiceButtonClickedAction |
  AddVendorChargebackLineItemsButtonClicked
  ;

export enum ActionTypeKeys {
  AddRoutePlansToInvoiceButtonClicked = 'App/ADD_ROUTE_PLANS_BUTTON_CLICKED',
  AddVendorChargebackLineItemsButtonClicked = 'App/ADD_VENDOR_CHARGEBACK_LINE_ITEMS_BUTTON_CLICKED',
}

export interface AddRoutePlansToInvoiceButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.AddRoutePlansToInvoiceButtonClicked;
}

export const onAddRoutePlansButtonClicked = (): AddRoutePlansToInvoiceButtonClickedAction => {
  return {
    type: ActionTypeKeys.AddRoutePlansToInvoiceButtonClicked,
  };
};

export interface AddVendorChargebackLineItemsButtonClicked extends Redux.Action {
  type: ActionTypeKeys.AddVendorChargebackLineItemsButtonClicked;
}

export const onAddVendorChargebackLineItemsButtonClicked = (): AddVendorChargebackLineItemsButtonClicked => {
  return {
    type: ActionTypeKeys.AddVendorChargebackLineItemsButtonClicked,
  };
};

interface AddBlankLineItemResponse {
  response?: {
    invoice: {
      id: number;
      lineItems: Array<{ id: number }>;
    },
  };
}

interface AddBlankLineItemInput {
  invoiceId: number;
}

const addBlankLineItemToInvoiceMutation = gql`
mutation AddBlankLineItemToInvoiceMutation($invoiceId: Int!) {
  response: AddBlankLineItemToInvoice(invoiceId: $invoiceId) {
    invoice {
      id
      ... on DropShipInvoice {
        id
        lineItems {
          id
        }
      }

      ... on CustomerOrderInvoice {
        id
        lineItems {
          id
        }
      }
    }
  }
}
`;

export const onAddLineItemButtonClicked = (confirmOkToSave: ConfirmOkToSaveFunction, invoiceId?: number): Thunker => dispatch => async () => {
  const confirmed = await confirmOkToSave();
  if (!confirmed) {
    return;
  }
  if (!invoiceId) {
    return;
  }
  const response = await msyncClientMutation<AddBlankLineItemResponse, AddBlankLineItemInput>({
    mutation: addBlankLineItemToInvoiceMutation,
    variables: {
      invoiceId,
    },
    dispatch,
    refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME],
  });
  // Wait a short amount time for the row to get added to the table.
  // Ideally, we could call 'editLastEditableCell' from some hook where after new item was added to the table,
  // but I'm not smart enough to figure out how to do that, so I'll let someone else deal with it 😎
  await timeout(200);
  const lineItems = response.data.response ? response.data.response.invoice.lineItems : [];
  dispatch(editLastEditableCell(Constants.tableName, lineItems));
};

interface EditInvoiceLineItemResponse {
  response?: {
    invoice: GraphQLTypes.Invoice;
  };
}

interface EditInvoiceLineItemInput {
  lineItemId: number;
  input: shame;
}

const EditCustomerOrderInvoiceLineItemMutation = gql`
mutation EditCustomerOrderInvoiceLineItemMutation($lineItemId: Int!, $input: EditCustomerOrderInvoiceLineItemInput!) {
  response: EditCustomerOrderInvoiceLineItem(lineItemId: $lineItemId, input: $input) {
    invoice {
      id
    }
  }
}
`;

const EditDropShipInvoiceLineItemMutation = gql`
mutation EditDropShipInvoiceLineItemMutation($lineItemId: Int!, $input: EditDropShipInvoiceLineItemInput!) {
  response: EditDropShipInvoiceLineItem(lineItemId: $lineItemId, input: $input) {
    invoice {
      id
    }
  }
}
`;

const EditVendorChargebackInvoiceLineItemMutation = gql`
mutation EditVendorChargebackInvoiceLineItemMutation($lineItemId: Int!, $input: EditVendorChargebackInvoiceLineItemInput!) {
  response: EditVendorChargebackInvoiceLineItem(lineItemId: $lineItemId, input: $input) {
    invoice {
      id
    }
  }
}
`;

export const onLineItemEdit = (type: InvoiceType, lineItemId: number, updateInformation: shame, confirmOkToSave: ConfirmOkToSaveFunction): Thunker => async dispatch => {
  const confirmed = await confirmOkToSave();
  if (!confirmed) {
    return;
  }
  switch (type) {
    case InvoiceType.CustomerOrder:
      return await msyncClientMutation<EditInvoiceLineItemResponse, EditInvoiceLineItemInput>({
        mutation: EditCustomerOrderInvoiceLineItemMutation,
        variables: {
          lineItemId,
          input: updateInformation,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
    case InvoiceType.DropShip:
      return await msyncClientMutation<EditInvoiceLineItemResponse, EditInvoiceLineItemInput>({
        mutation: EditDropShipInvoiceLineItemMutation,
        variables: {
          lineItemId,
          input: updateInformation,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
    case InvoiceType.VendorChargeback:
      return await msyncClientMutation<EditInvoiceLineItemResponse, EditInvoiceLineItemInput>({
        mutation: EditVendorChargebackInvoiceLineItemMutation,
        variables: {
          lineItemId,
          input: updateInformation,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
    default:
      assertUnreachable(type);
  }
};

interface DeleteInvoiceLineItemsResponse {
  response?: {
    invoice: {
      id: number;
      lineItems: Array<{ id: number }>;
    },
  };
}

interface DeleteInvoiceLineItemsInput {
  lineItemIds: number[];
}

const DeleteCustomerOrderInvoiceLineItemsMutation = gql`
mutation DeleteCustomerOrderInvoiceLineItemsMutation($lineItemIds: [Int!]!) {
  response: DeleteCustomerOrderInvoiceLineItems(lineItemIds: $lineItemIds) {
    invoice {
      id
      ... on CustomerOrderInvoice {
        lineItems {
          id
        }
      }
    }
  }
}
`;

const DeleteDropShipInvoiceLineItemsMutation = gql`
mutation DeleteDropShipInvoiceLineItemsMutation($lineItemIds: [Int!]!) {
  response: DeleteDropShipInvoiceLineItems(lineItemIds: $lineItemIds) {
    invoice {
      id
      ... on DropShipInvoice {
        lineItems {
          id
        }
      }
    }
  }
}
`;

const DeleteVendorChargebackInvoiceLineItemsMutation = gql`
mutation DeleteVendorChargebackInvoiceLineItemsMutation($lineItemIds: [Int!]!) {
  response: DeleteVendorChargebackInvoiceLineItems(lineItemIds: $lineItemIds) {
    invoice {
      id
      ... on VendorChargebackInvoice {
        lineItems {
          id
        }
      }
    }
  }
}
`;

export const onLineItemDelete = (type: InvoiceType, confirmOkToSave: ConfirmOkToSaveFunction): Thunker => (dispatch, getState) => async (lineItemIds: number[]) => {
  const confirmed = await confirmOkToSave();
  if (!confirmed) {
    return;
  }
  switch (type) {
    case InvoiceType.CustomerOrder:
      const response = await msyncClientMutation<DeleteInvoiceLineItemsResponse, DeleteInvoiceLineItemsInput>({
        mutation: DeleteCustomerOrderInvoiceLineItemsMutation,
        variables: {
          lineItemIds,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
      const lineItemIdsNotDeleted = idsFor(response.data.response ? response.data.response.invoice.lineItems : []);
      const idsToUncheck = _.difference(lineItemIds, lineItemIdsNotDeleted);
      dispatch(uncheckMultipleRecords(Constants.tableName, idsToUncheck));
      return;
    case InvoiceType.DropShip:
      return await msyncClientMutation<DeleteInvoiceLineItemsResponse, DeleteInvoiceLineItemsInput>({
        mutation: DeleteDropShipInvoiceLineItemsMutation,
        variables: {
          lineItemIds,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
    case InvoiceType.VendorChargeback:
      return await msyncClientMutation<DeleteInvoiceLineItemsResponse, DeleteInvoiceLineItemsInput>({
        mutation: DeleteVendorChargebackInvoiceLineItemsMutation,
        variables: {
          lineItemIds,
        },
        dispatch,
        refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
      });
    default:
      assertUnreachable(type);
  }
};
