import * as _ from 'lodash';
import * as BS from 'client/components/third-party';
import * as classnames from 'classnames';
import * as Constants from '../constants';
import * as React from 'react';

export const LabeledDetail: React.StatelessComponent<{ title: string, detailClassName?: string }> = props => {
  return (
    <div className="send-email-modal-row" data-testid={`send-email-modal-${_.kebabCase(props.title)}`}>
      <BS.Row>
        <BS.Col sm={4}>
          <div className={classnames(Constants.ModalFormatting.Title)}>
            {props.title}
          </div>
        </BS.Col>
        <BS.Col sm={8}>
          <div className={classnames(Constants.ModalFormatting.Detail, props.detailClassName)}>
            {props.children}
          </div>
        </BS.Col>
      </BS.Row>
    </div>
  );
};
