import { FieldValidator } from 'shared/validators';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';

export const ORDER_PRODUCT_GROUP_IDENTIFIER_HAS_CORRECT_PREFIX: FieldValidator = {
  isValid(value: string, record: CustomerOrderProductGroup) {
    if (!value || value.length === 0) {
      return true;
    }
    if (record.isCombo) {
      return value[0] === 'C';
    }
    return value[0] === 'F' || value === 'HP';
  },

  shortMessage(value: any, record: any) {
    return 'Incorrect Prefix';
  },
  message(label: string, value: any, record: any) {
    if (record.isCombo) {
      return `${label} must begin with "C" if it is a combo.`;
    }
    return `${label} must begin with "F" if it is not a combo.`;
  },
};

/** NOTE: This is a VBills constraint (two-character rack identifiers).
 * We could remove this once VBills is gone.
 */
export const getValidCustomerOrderProductGroupIdentifier = (identifier?: string) => {
  if (!identifier) {
    throw new Error('Invalid rack identifier provided');
  } else if (identifier.length === 2 && /[FC][0-9A-Z]{1}$/.test(identifier)) {
    return identifier;
  } else if (identifier.length > 2 && /[FC][1-90]{2}$/.test(identifier)) {
    const num = (Number.parseInt(identifier.substr(1)) - 10);
    if (num > 25 || num < 0) {
      throw new Error(`The rack identifier ${identifier} is out of the acceptable range to stay within a two character limit`);
    }
    const suffix = String.fromCharCode('A'.charCodeAt(0) + num);
    return `${identifier[0]}${suffix}`;
  }
  throw new Error(`Unable to determine rack identifier from ${identifier}`);
};
