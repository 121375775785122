import * as React from 'react';
import * as classNames from 'classnames';
import { withRouter, RouteChildrenProps } from 'react-router';
import { Dropdown as BSDropdown } from 'client/components/third-party';
import DropdownMenu from '../../dropdown/dropdown-menu';
import SecondaryNavLinkToggle from './secondary-nav-link-toggle';
import { Link } from 'react-router-dom';
import { isActiveRoute } from 'client/helpers/route-helpers';

interface OwnProps {
  children?: React.ReactNode;
  route?: string;
  title?: string;
  testid?: string;
}

class SecondaryNavLink extends React.Component<OwnProps & RouteChildrenProps, { open: boolean }> {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  render() {
    const {
      route,
      title,
      testid,
      location,
    } = this.props;

    // This is required because the children prop is not an array if there is only one of them
    // And mapping over single children was causing run-time errors
    const children = React.Children.toArray(this.props.children);

    const active = isActiveRoute(route || '', location);

    const buttonClasses = classNames('secondary-nav-item', 'mfc-navigation-bar-secondary-tab', {
      active,
      inactive: !active,
    });

    if (!children || children.length === 0) {
      return (
        <li data-testid={ testid } className={ buttonClasses }>
          <Link to={route || ''}>{ title }</Link>
        </li>
      );
    }

    const childRoutes = children.map(child => (child as shame).props.route);
    const anyChildRouteActive = childRoutes.some(childRoute => isActiveRoute(childRoute, location));

    const dropdownClasses = classNames('secondary-nav-item', 'mfc-navigation-bar-secondary-tab', 'mfc-dropdown', {
      active: anyChildRouteActive,
      inactive: !anyChildRouteActive,
    });

    return (
      <li data-testid={ testid } className={ dropdownClasses }>
        <BSDropdown id={`dropdown-${title}`} open={this.state.open} onToggle={this.handleToggle} onMouseLeave={this.close}>
          <SecondaryNavLinkToggle bsRole="toggle" onMouseOver={this.handleMouseOver}>
            {title}
            <span className="mfc-icon-caret-down" />
          </SecondaryNavLinkToggle>

          <DropdownMenu bsRole="menu" isOpen={this.state.open}>
            {children.map((child: shame) => {
              return React.cloneElement(child, { close: this.close, key: child.props.title });
            })}
          </DropdownMenu>
        </BSDropdown>
      </li>
    );
  }

  handleToggle(value) {
    if (!value) {
      this.close();
      return;
    }

    this.open();
  }

  handleMouseOver() {
    this.open();
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }
}

export default withRouter(SecondaryNavLink);
