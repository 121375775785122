import * as ImportModal from 'client/components/import-modal';
import * as React from 'react';
import * as ImportModalTypes from 'client/components/import-modal/types';
import { SpreadsheetType } from 'shared/file-parsers/excel/excel-parser-helpers';

export interface ComponentProps {
  isShown: boolean;
  salesPlanId: number;

  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;

  handleImportStarted: ImportModalTypes.ImportFn;
  handleParsingStarted: ImportModalTypes.ParseFn;
  handleValidationStarted: ImportModalTypes.ValidateFn;
}

export class ImportSalesPlanModalUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return <ImportModal.ImportUI
      id="import-sales-plan-modal"
      existingRecordId={this.props.salesPlanId}

      modalTitle="Import Sales Plan"
      expectedSpreadsheetType={SpreadsheetType.SalesPlan}

      fileExtension=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

      importButtonLabel="Import Sales Plan"
      closeButtonLabel="Close"
      cancelButtonLabel="Cancel"

      handleCloseButtonClicked={this.props.handleCloseButtonClicked}
      handleCancelButtonClicked={this.props.handleCancelButtonClicked}

      handleParsingStarted={this.props.handleParsingStarted}
      handleValidationStarted={this.props.handleValidationStarted}
      handleImportStarted={this.props.handleImportStarted}
    />;
  }
}
