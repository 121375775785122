import * as React from 'react';
import { ButtonGroup, Button } from 'client/components/third-party';

export interface Props {
  onClickUndo?: () => void;
}

export function RecordBarUndoRedoButtons({ onClickUndo }: Props) {
  return (
    <div className="detail">
      <ButtonGroup bsSize="large">
        <Button className="record-bar-button record-bar-button-medium-icon" disabled={!onClickUndo} onClick={onClickUndo}>
          <span className="mfc-medium-icon mfc-icon-reply" />
        </Button>
      </ButtonGroup>
    </div>
  );
}
