import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dropdown as BSDropdown, MenuItem } from 'client/components/third-party';
import * as classNames from 'classnames';
import DropdownMenu from './dropdown-menu';
import DropdownToggle from './dropdown-toggle';
import * as onClickOutside from 'react-onclickoutside';
import * as Mousetrap from 'mousetrap';
import { filterByBeginnersThenFuzzies } from 'shared/helpers/filter-helpers';

class Dropdown extends React.Component<shame, shame> {
  constructor(props) {
    super(props);
    const data = this.formatAndFilterData(props.data, props.textFormatter, props.valueField);
    this.state = { open: false, selectedIndex: -1, data, currentSearchValue: '' };

    this.handleToggle = this.handleToggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
    this.home = this.home.bind(this);
    this.end = this.end.bind(this);
    this.pageUp = this.pageUp.bind(this);
    this.pageDown = this.pageDown.bind(this);
    this.select = this.select.bind(this);
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // FIXME: just comparing length is not ok
    if (nextProps.data.length !== this.props.data.length) {
      const data = this.formatAndFilterData(nextProps.data, nextProps.textFormatter, nextProps.valueField);
      this.setState({ data });
    }
  }

  render() {
    const { className, value, placeholder, onChange, onSecondaryClick, secondaryIcon, multi, filter, block, updateLabel, testid } = this.props;

    const dropdownClasses = classNames('mfc-dropdown', className);

    let label = placeholder;

    if (updateLabel && Array.isArray(value) && value.length > 0 && !multi) {
      const data = this.state.data;
      const selectedEntry = data.filter(entry => entry.value === value[0]).shift();
      if (selectedEntry) {
        label = selectedEntry.text;
      }
    }

    const labelClassName = classNames({
      placeholder: label === placeholder,
    });

    const headerRef = element => {(this as shame).headerRef = element; };
    const menuRef = element => { (this as shame).dropdownMenuRef = element; };
    const inputRef = element => { (this as shame).inputRef = element; };

    return (
      <div className={dropdownClasses} data-testid={testid}>
        <BSDropdown id={`dropdown-${placeholder}`} open={this.state.open} onToggle={this.handleToggle} disabled={this.props.disabled}>
          <DropdownToggle block={block} bsRole="toggle" multi={multi} disabled={this.props.disabled}>
            <span className={labelClassName}>{label}</span>
            <span className="caret" />
          </DropdownToggle>

          <DropdownMenu bsRole="menu" filter={filter} inputValue={this.state.currentSearchValue} isOpen={this.state.open} headerRef={headerRef} menuRef={menuRef} inputRef={inputRef} inputKeyPressHandler={this.handleInputKeyPress} onInputChangeHandler={this.handleInputChange} right={this.props.right}>
            {this.state.data.map((entry, index) => {
              const onOptionClick = () => {
                if (!multi) {
                  this.close();
                }

                onChange(entry.value, index);
              };

              const onOptionSecondaryClick = onSecondaryClick
                ? e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();

                  if (!multi) {
                    this.close();
                  }

                  onSecondaryClick(entry.value, index);
                }
                : null;

              const isHighlighted = this.state.data.indexOf(entry) === this.state.selectedIndex;

              const optionClassName = classNames('mfc-dropdown-menu-item', {
                checked: value.indexOf(entry.value) !== -1,
                singleSelect: !multi,
                highlighted: isHighlighted,
              });

              const menuItemProps = {
                key: entry.value,
                className: optionClassName,
                onClick: onOptionClick,
              };

              if (isHighlighted) {
                (menuItemProps as shame).ref = 'activeItem';
              }

              return (
                <MenuItem {...menuItemProps} data-testid={`dropdown-option-${entry.text}`} key={this.state.data.indexOf(entry)}>
                  {multi && <span className="mfc-dropdown-checkbox" />}
                  <div className="mfc-dropdown-entry">{entry.text}</div>
                  {onOptionSecondaryClick &&
                    <div onClick={onOptionSecondaryClick} className="mfc-dropdown-secondary-icon">
                      <i className={`fa ${secondaryIcon}`} aria-hidden="true"></i>
                    </div>
                  }
                </MenuItem>);
            })}
          </DropdownMenu>
        </BSDropdown>
      </div>
    );
  }

  formatAndFilterData(data, textFormatter, valueField, value = '') {
    let displayData;

    if (data && valueField && textFormatter) {
      displayData = data.map(entry => {
        return {
          value: entry[valueField],
          text: textFormatter(entry),
        };
      });
    } else {
      displayData = data.map(entry => {
        return {
          value: entry,
          text: entry,
        };
      });
    }

    return filterByBeginnersThenFuzzies(displayData, (o: shame) => o.text, value);
  }

  handleInputChange(newValue) {
    const { data, textFormatter, valueField} = this.props;
    const filteredData = this.formatAndFilterData(data, textFormatter, valueField, newValue);
    this.setState({ data: filteredData, currentSearchValue: newValue });
  }

  ensureActiveItemVisible() {
    const itemComponent = this.refs.activeItem;
    if (!itemComponent) {
      return;
    }

    // The following was adapted from https://github.com/furqanZafar/react-selectize/blob/master/src/DropdownMenu.ls#L195-L220

    const optionElement = ReactDOM.findDOMNode(itemComponent);
    const parentElement = ReactDOM.findDOMNode((this as shame).dropdownMenuRef);
    const headerElement = ReactDOM.findDOMNode((this as shame).headerRef);

    let headerHeight = 0;
    if (headerElement) {
      headerHeight = headerElement.offsetHeight;
    }

    const optionHeight = optionElement.offsetHeight;

    // in other words, if the option element is below the visible region
    if ((optionElement.offsetTop - parentElement.scrollTop) >= parentElement.offsetHeight) {
      // scroll the option element into view, by scrolling the parent element downward by an amount equal to the
      // distance between the bottom-edge of the parent-element and the bottom-edge of the option element
      parentElement.scrollTop = optionElement.offsetTop - parentElement.offsetHeight + optionHeight - headerHeight;

    // in other words, if the option element is above the visible region
    } else if ((optionElement.offsetTop - parentElement.scrollTop + optionHeight - headerHeight) <= 0) {
      // scroll the option element into view, by scrolling the parent element upward by an amount equal to the
      // distance between the top-edge of the option element and the top-edge of the parent element
      parentElement.scrollTop = optionElement.offsetTop - headerHeight;
    }
  }

  select() {
    if (!this.props.multi) {
      this.close();
    }

    const selectedIndex = this.state.selectedIndex;
    const entry = this.state.data[selectedIndex];
    if (!entry) {
      return;
    }

    const value = entry.value ? entry.value : entry;
    this.props.onChange(value);
  }

  home() {
    this.setState({selectedIndex: 0});
    this.ensureActiveItemVisible();
  }

  end() {
    this.setState({selectedIndex: this.state.data.length - 1});
    this.ensureActiveItemVisible();
  }

  pageUp(event, combo) {
    this.up(event, combo, 7);
  }

  pageDown(event, combo) {
    this.down(event, combo, 7);
  }

  up(event, combo, distance = 1) {
    const selectedIndex = this.state.selectedIndex;

    if (selectedIndex > 0) {
      let newPosition = selectedIndex - distance;

      if (newPosition < 0) {
        newPosition = 0;
      }

      this.setState({selectedIndex: newPosition});
      this.ensureActiveItemVisible();
    } else {
      const input = ReactDOM.findDOMNode((this as shame).inputRef);

      if (input) {
        input.focus();
        this.setState({selectedIndex: -1});
      }
    }
  }

  down(event, combo, distance = 1) {
    const selectedIndex = this.state.selectedIndex;

    let newPosition = selectedIndex + distance;
    if (newPosition > this.state.data.length - 1) {
      newPosition = this.state.data.length - 1;
    }

    this.setState({selectedIndex: newPosition});
    this.ensureActiveItemVisible();
  }

  handleInputKeyPress(event) {
    switch (event.key) {
    case 'ArrowDown':
      event.target.blur();
      this.setState({selectedIndex: 0});
      return;
    case 'Escape':
      this.close();
      return;
    case 'Tab':
      this.close();
      return;
    case 'Home':
      event.target.blur();
      this.home();
      return;
    case 'End':
      event.target.blur();
      this.setState({selectedIndex: 0});
      this.end();
      return;
    case 'PageDown':
      event.target.blur();
      this.setState({selectedIndex: 0});
      (this as shame).pageDown();
      return;
    default:
      return;
    }
  }

  open() {
    const { data, textFormatter, valueField} = this.props;
    const filteredData = this.formatAndFilterData(data, textFormatter, valueField, this.state.currentSearchValue);
    this.setState({ data: filteredData, open: true, currentSearchValue: ''});

    Mousetrap.bind('up', this.up);
    Mousetrap.bind('down', this.down);
    Mousetrap.bind('pageup', this.pageUp);
    Mousetrap.bind('pagedown', this.pageDown);
    Mousetrap.bind(['space', 'enter'], this.select);
    Mousetrap.bind('esc', this.close);
    Mousetrap.bind('tab', this.close);
    Mousetrap.bind('home', this.home);
    Mousetrap.bind('end', this.end);
  }

  close() {
    if (!this.state.open) {
      return;
    }

    this.setState({open: false, currentSearchValue: ''});

    Mousetrap.unbind('up');
    Mousetrap.unbind('down');
    Mousetrap.unbind('pageup');
    Mousetrap.unbind('pagedown');
    Mousetrap.unbind(['space', 'enter']);
    Mousetrap.unbind('esc');
    Mousetrap.unbind('tab');
    Mousetrap.unbind('home');
    Mousetrap.unbind('end');
  }

  handleToggle(value) {
    if (!value) {
      this.close();

      return;
    }

    this.open();
  }
}
/*
Dropdown.propTypes = {
  className: React.PropTypes.string,
  data: React.PropTypes.array,
  dataLoaderOptions: React.PropTypes.shape({
    table: React.PropTypes.string.isRequired,
    columns: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
    parent: React.PropTypes.string,
  }),
  value: React.PropTypes.array,
  textFormatter: React.PropTypes.func,
  valueField: React.PropTypes.string,
  placeholder: React.PropTypes.any,
  multi: React.PropTypes.bool,
  filter: React.PropTypes.bool,
  updateLabel: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  block: React.PropTypes.bool,
  testid: React.PropTypes.string,

  onChange: React.PropTypes.func,
  onSecondaryClick: React.PropTypes.func,
  secondaryIcon: React.PropTypes.string,
  right: React.PropTypes.bool,
};
*/
(Dropdown as shame).defaultProps = {
  data: [],
  multi: false,
  filter: false,
  block: false,
  updateLabel: false,
  disabled: false,
  right: false,
};

export default (onClickOutside as shame)(Dropdown, {
  handleClickOutside: instance => {
    return instance.close;
  },
});
