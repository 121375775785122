import * as R from 'ramda';
import * as State from 'client/state/supplier-commitment';
import * as SupplierCommitmentTableActions from 'client/app/planning/supplier-commitments/supplier-commitment-allocation-table/actions';
import * as ImportSupplierCommitmentActions from 'client/app/planning/supplier-commitments/import-supplier-commitment-modal/actions';
import * as SupplierCommitmentSidebarActions from 'client/app/planning/supplier-commitments/sidebar/actions';
import * as UpdateSupplierItemCostsModalActions from 'client/app/planning/supplier-commitments/update-supplier-item-costs-modal/actions';

type ActionType =
  | SupplierCommitmentSidebarActions.ActionTypes
  | UpdateSupplierItemCostsModalActions.ActionTypes
  | SupplierCommitmentTableActions.ActionTypes
  | ImportSupplierCommitmentActions.ActionTypes;

export default function reducer(state = State.InitialState, action: ActionType): State.Type {
  switch (action.type) {
    case UpdateSupplierItemCostsModalActions.ActionTypeKeys.UpdateSupplierItemCostsModalCloseClicked:
      return State.updateSupplierItemCostsModalShown.set(false)(state);

    case SupplierCommitmentSidebarActions.ActionTypeKeys.UpdateSupplierItemCostsModalShown:
      return State.updateSupplierItemCostsModalShown.set(true)(state);
    case SupplierCommitmentTableActions.ActionTypeKeys.ImportButtonClicked: {
      return R.pipe(
        State.importSupplierCommitmentModalShown.set(true),
      )(state);
    }

    case ImportSupplierCommitmentActions.ActionTypeKeys.CancelButtonClicked: {
      return R.pipe(
        State.importSupplierCommitmentModalShown.set(false),
      )(state);
    }

    default:
      return state;
  }
}
