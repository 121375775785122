import * as _ from 'lodash';
import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import * as ReactRedux from 'react-redux';
import gql from 'graphql-tag';

// x-plat libs
import { INPUT_TYPES, ElementOf, groupBy } from 'shared/types';
import { orThrow } from 'shared/helpers';

// data libs
import { StoresWithMerchandisersResponse, GetStoresWithMerchandisersInput, EmailMerchandisersInput, UniqueStopsForRoutePlanResponse, UniqueStopForRoutePlan, RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';

// ui libs
import { Modal, Col, Row } from 'client/components/third-party';
import { SimpleInput } from 'client/components/simple-components/simple-input';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { Checkbox } from 'client/components/form/checkbox';
import SearchBox from 'client/components/toolbar/search-box';
import { ProgressSpinner } from 'client/components/progress-spinner';

// app libs
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

// redux integration
import * as ReduxStuff from './email-merchandisers-redux-stuff';
import { sortNaturally } from 'shared/helpers/sort-naturally';
/*
ZZZ

          routePlanResult: GetRoutePlan(id: $routePlanId) {
            routePlan {
              id
              identifier
              status
            }
          }
*/

type ListItem = ElementOf<StoresWithMerchandisersResponse['storesWithMerchandisers']> & {stops: UniqueStopForRoutePlan[]} & {merchandisers: Array<{id: number, name: string}>};

/**
 * Mount in a portal or in a high z-index position in the ReactRoot to act as a modal.
 * Only renders an empty div (as opposed to null, to avoid flex-box layout issues) UNLESS:
 * - `({type: 'EmailMerchandisersModal/ModalVisibilityChanged', payload: {modalVisibility}})` has `modalVisibility === true` in Redux
 * - the `routePlanId` is truthy
 * - AND `GetStoresWithMerchandisersForModalQuery` returns data.
 *
 * ... Then the modal appears and allows the user to define an email to send to Merchandisers connected to the current Route Plan.
 */
export const EmailMerchandisersModal = (p: {
  routePlanId?: RoutePlanId;
}) => {
  const dispatch = ReactRedux.useDispatch();
  const modalVisibility = ReactRedux.useSelector<{emailMerchandisersModalState: ReduxStuff.EmailMerchandisersModalState}, boolean>(s => !!s.emailMerchandisersModalState.modalVisibility);
  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailCarbonCopy, setEmailCarbonCopy] = useState('');
  const [listItems, setListItems] = useState<ListItem[] | null>(null);
  useEffect(() => {
    if (!p.routePlanId || !modalVisibility) {
      setListItems(null);
      return;
    }

    msyncClientQuery<{
      getStoresWithMerchandisers: StoresWithMerchandisersResponse,
      uniqueStopsForRoutePlan: UniqueStopsForRoutePlanResponse,
      routePlanResult: {routePlan: {id: number, identifier: string, status: string}},
    }, GetStoresWithMerchandisersInput>({
      // skip: !routePlanId,
      variables: { routePlanId: p.routePlanId || orThrow('Route plan id required!') },
      dispatch,
      query: gql`
        query GetStoresWithMerchandisersForModalQuery($routePlanId: Int!) {
          getStoresWithMerchandisers: GetStoresWithMerchandisers(routePlanId: $routePlanId) {
            storesWithMerchandisers {
              storeId
              storeIdentifier
              storeDisplay
              merchandiserName
              merchandiserId
              route
              customerId
              sellDepartmentId
              supervisorRank
            }
          }
          uniqueStopsForRoutePlan: GetUniqueStopsForRoutePlan(routePlanId: $routePlanId) {
            stops {
              stopNumber
              loadNumber
              storeId
            }
          }
        }
      `,
    })
    .then(result => {
      const stopsByStore = groupBy(result.data.uniqueStopsForRoutePlan.stops, x => x.storeId as shame as string);
      const rawItems = result.data.getStoresWithMerchandisers.storesWithMerchandisers.map(swm => ({ ...swm, stops: stopsByStore[swm.storeId] || [] }));
      const byStore = groupBy(rawItems, s => s.storeId as shame as string);
      const withMerchies = _.mapValues(byStore, v => ({ ...v[0], merchandisers: v.map(m => ({id: m.merchandiserId, name: m.merchandiserName})) }));
      const items = sortNaturally(_.values(withMerchies), [{sortField: 'storeDisplay'}]) as ListItem[];
      if (items.length > 0) {
        const sample = items[0];
        const productionMeijerID = 26830;
        const productionHomeDepotID = 26857;
        const productionGardenID = 353;
        const productionFloralID = 351;
        if (sample.customerId === productionHomeDepotID && !emailCarbonCopy.includes('tadomaitis@masterpieceflower.com; rmercer@masterpieceflower.com; '))
          setEmailCarbonCopy('tadomaitis@masterpieceflower.com; rmercer@masterpieceflower.com; ' + emailCarbonCopy);

        if (sample.customerId === productionMeijerID && sample.sellDepartmentId === productionGardenID && !emailCarbonCopy.includes('mschut@masterpieceflower.com; '))
          setEmailCarbonCopy('mschut@masterpieceflower.com; ' + emailCarbonCopy);

        if (sample.customerId === productionMeijerID && sample.sellDepartmentId === productionFloralID && !emailCarbonCopy.includes('nlakos@masterpieceflower.com; '))
          setEmailCarbonCopy('nlakos@masterpieceflower.com; ' + emailCarbonCopy);
      }

      setListItems(items);
    })
    .catch(err => console.error(err));
  }, [dispatch, p.routePlanId, modalVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchText, setSearchText] = useState('');
  const [matchingStores, setMatchingStores] = useState({} as Dictionary<ElementOf<Exclude<typeof listItems, null>>>);
  useEffect(() => {
    const pattern = searchText.trim().toLocaleLowerCase();
    const filteredListItems = (listItems || []).filter(swm => pattern === ''
      || swm.storeIdentifier.toLowerCase().includes(pattern)
      || swm.storeDisplay.toLowerCase().includes(pattern)
      || swm.merchandiserName.toLowerCase().includes(pattern)
      || swm.stops.some(s => s.loadNumber.toLowerCase().includes(pattern) || `Stop ${s.stopNumber}`.toLowerCase().includes(pattern)));

    const filtered
      = pattern === ''                                               ? filteredListItems                         // default to stores.route order
      : _.orderBy(filteredListItems, item => item.stops.length > 0 ? Number.parseInt(item.stops[0].stopNumber) // sort by stop number when load number is most likely specified in the search box
                                                                     : item.route);                              // default back to sort by stop number

    setMatchingStores(_.keyBy(filtered, x => x.storeId));
  }, [listItems, searchText]);

  const [checkedStores, setCheckedStores] = useState({} as typeof matchingStores);
  const numberChecked = _.compact(_.values(checkedStores)).length;
  const allAreChecked = numberChecked > 0 && listItems?.length === numberChecked;

  // check any that are unckecked = or uncheck them all if they are already all checked; do not affect stores not matching the filter query.
  const selectAllClicked = useCallback(() => { setCheckedStores(allAreChecked ? {} : matchingStores); }, [matchingStores, allAreChecked]);
  const handleSendButtonClicked = useCallback(async () => {
    try {
      await msyncClientMutation<{}, EmailMerchandisersInput>({
        dispatch,
        mutation: gql`
          mutation EmailMerchandisers($storeIds: [Int!]!, $merchandiserIds: [Int!]!, $subject: String!, $body: String!, $carbonCopy: String!) {
            result: EmailMerchandisers(storeIds: $storeIds, merchandiserIds: $merchandiserIds, subject: $subject, body: $body, carbonCopy: $carbonCopy)
          }
        `,
        showBusyModal: true,
        variables: {
          merchandiserIds: _.compact(_.uniq(_.flatMap(_.values(checkedStores), swm => swm.merchandisers ? swm.merchandisers.map(m => m.id)  : []))),
          storeIds: _.compact(_.toPairs(checkedStores).filter(x => !!x[1]).map(x => Number.parseInt(x[0]))),
          subject: emailSubject,
          carbonCopy: emailCarbonCopy,
          body: emailBody,
        },
      });
      dispatch(ReduxStuff.modalVisibilityAction(false));
    } catch {
      // Do nothing; only hide modal upon success.
    }
  }, [dispatch, p.routePlanId, emailSubject, emailBody, emailCarbonCopy, checkedStores]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStoreChecked = useCallback((storeId: number) => () => { setCheckedStores({...checkedStores, [storeId]: !!checkedStores[storeId] ? undefined : matchingStores[storeId]}); }, [matchingStores, checkedStores]);
  const [canSend, setCanSend] = useState(false);
  useEffect(() => { setCanSend(_.values(checkedStores).length > 0 && emailBody.length > 0 && emailSubject.length > 0); }, [checkedStores, emailBody, emailSubject]);
  return !modalVisibility || !p.routePlanId ? <div/> : (
    <Modal id="email-merchandisers-modal" animation backdrop="static" show={modalVisibility} onHide={() => dispatch(ReduxStuff.modalVisibilityAction(false))} dialogClassName="email-merchandisers-modal-container" className="email-merchandisers-modal">
      <GlobalAsyncModalHeader>Email Merchandisers</GlobalAsyncModalHeader>
      <Modal.Body>
        <Col sm={12}>
          <Row className="email-merchandisers-store-header">
            <div className="email-merchandisers-store-description">
              Choose all or select specific stores, then complete message subject and body to contact merchandisers.
            </div>
          </Row>
        </Col>
        <Col sm={7}>
          <Row>
            <Col sm={4} className="email-merchandisers-store-select-all-container">
              <Checkbox label="Select All" input={{ value: allAreChecked, onChange: _.noop }} testid="email-merchandisers-store-select-all" onClick={selectAllClicked} />
            </Col>
            <Col sm={3} className="email-merchandisers-num-selected"> {numberChecked} Selected </Col>
            <Col sm={5} className="email-merchandisers-store-search-container pull-right">
              <SearchBox activeColumns={['Search All']} availableColumns={[{ id: 'Search All', name: 'Search All' } ]} onColumnSelected={_.noop} placeholder="Search" activeSearchText="" onSearch={setSearchText} />
            </Col>
          </Row>
          <Row>
            <div className="email-merchandisers-store-list-container">
              {!listItems && <div className="loading-spinner-container"> <ProgressSpinner label="Loading..." /> </div> }
              {listItems && _.keys(matchingStores).length === 0 && <div className="email-merchandisers-store-list-no-results">No results for given search criteria.</div> }
              {_.keys(matchingStores).length > 0 && _.sortBy(_.values(matchingStores), 'storeDisplay')
                .map(swm =>
                  <div className="email-merchandisers-store-list-item" key={`store-${swm.storeId}`}>
                    <div className="email-merchandisers-store-list-item-name">
                      <Checkbox label={swm.storeDisplay} input={{ value: !!checkedStores[swm.storeId], onChange: _.noop }} onClick={handleStoreChecked(swm.storeId)} testid={`email-merchandisers-store-list-item-${swm.storeId}`} />
                    </div>
                    <div className="email-merchandisers-store-list-item-stops">
                      {swm.stops.map(stop => ( <span key={`${stop.loadNumber}-${stop.stopNumber}`}>{stop.loadNumber} - Stop {stop.stopNumber}</span> ))}
                    </div>
                    <div className="email-merchandisers-store-list-item-merchandiser">
                      {swm.merchandisers.map(m => m.name).join(', ')}
                    </div>
                  </div>,
                )}
            </div>
          </Row>
        </Col>
        <Col sm={5} className="email-merchandisers-message-container">
          <div className="email-merchandisers-subject-container">
            <SimpleInput horizontalLabel inputColSize={12} label="Subject" labelColSize={12} onChange={setEmailSubject} testid="email-merchandisers-subject" value={emailSubject} />
          </div>
          <div className="email-merchandisers-carbon-copy-container">
            <SimpleInput horizontalLabel inputColSize={12} label="CC" labelColSize={12} onChange={setEmailCarbonCopy} testid="email-merchandisers-carbon-copy" value={emailCarbonCopy} />
          </div>
          <div className="email-merchandisers-body-container">
            <SimpleInput horizontalLabel inputColSize={12} label="Body" labelColSize={12} onChange={setEmailBody} testid="email-merchandisers-body" type={INPUT_TYPES.TEXTAREA} value={emailBody} />
          </div>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <GlobalCancelButton onClick={() => dispatch(ReduxStuff.modalVisibilityAction(false))} testid="email-merchandisers-modal-cancel-button" />
        <GlobalSaveButton label="Send" testid="email-merchandisers-modal-send-button" disabled={!canSend} onClick={handleSendButtonClicked} classNames={['mfc-button-primary']} />
      </Modal.Footer>
    </Modal>
  );
};
