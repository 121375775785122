import * as TablesState from 'client/state/tables';
import * as TableState from 'client/state/table';
import { SORT_TYPES } from 'shared/types';
import { SALES_PLAN_TABLE_NAME } from 'client/constants';

export const tableName = SALES_PLAN_TABLE_NAME;
export type Type = TableState.Type;
export const genericTableActions = TablesState.buildTableStateModule(tableName,
  {
    sort: [{
      sortOrder: SORT_TYPES.DESC,
      sortField: 'year',
    }],
  },
);
