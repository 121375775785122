import buildAdminCreatePage from '../admin/admin-create-page';
import FormComponent from '../../components/crud-forms/supplier-item/info-form-group';
import navBuilder from './nav-items';

export default buildAdminCreatePage({
  table: 'supplierItems',
  formName: 'SupplierItemForm',
  FormComponent,
  navBuilder,
  fieldsToClearOnDuplication: [
    'supplierId',
  ],
});
