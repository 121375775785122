import * as GraphQLTypes from 'schema/customer-order-allocation/customer-order-allocation-types';
import * as ParserTypes from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser/types';
import gqlTag from 'graphql-tag';

export interface ImportDistributionRackShippingWorksheetResponse {
  reviewStoresCustomerOrderAllocations: Array<Pick<GraphQLTypes.ReviewStoresCustomerOrderAllocations, 'storeId' | 'racks'>>;
}

export interface ImportDistributionRackShippingWorksheetInput {
  routePlanId: number;
  overrides: ParserTypes.ImportableDistributionRackShippingLineItem[];
}

export const ImportDistributionRackShippingWorksheetMutation = (resultName: string) => gqlTag`
  mutation ImportDistributionRackShippingWorksheet($routePlanId: Int!, $overrides: [CustomerStoreRackQuantity!]!) {
    ${resultName}: ReviewStoresOverrideMultipleRacks(routePlanId: $routePlanId, overrides: $overrides) {
      reviewStoresCustomerOrderAllocations {
        routePlanId
        storeId
        racks
        isOverriddenRacks
      }
    }
  }
`;
