import * as React from 'react';
import NumberCell from 'client/components/table/number-cell';
import { noop } from 'lodash';

export interface UIProps {
  totalRacks: number;
  showSpinner: boolean;
}

export class RacksFooterUI extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <div>
        <NumberCell
          editing={false}
          value={this.props.totalRacks}
          onChange={noop}
          testid="footer-racks"
        />
      </div>
    );
  }
}
