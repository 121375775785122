import * as ExcelHelpers from 'shared/file-parsers/excel/common';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as ImportModalTypes from 'client/components/import-modal/types';
import * as Mutations from './mutations';
import * as Queries from './queries';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as SalesPlanSchemaTypes from 'schema/import-sales-plan/types';
import * as SalesPlanSpreadsheetParser from 'shared/file-parsers/excel/sales-plan-spreadsheet-parser';
import * as SalesPlanTypes from 'shared/file-parsers/excel/sales-plan-spreadsheet-parser/types';
import * as SharedHelpers from 'shared/helpers';

export type ActionTypes =
  OpenModalButtonClickedAction |
  CancelButtonClickedAction;

export interface OpenModalButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.OpenModalButtonClicked;
  payload: {};
}

export function ImportModalOpenModalButtonClicked(): OpenModalButtonClickedAction {
  return {
    type: ActionTypeKeys.OpenModalButtonClicked,
    payload: {},
  };
}

export enum ActionTypeKeys {
  OpenModalButtonClicked = 'ImportSalesPlanModal/OPEN_MODAL_BUTTON_CLICKED',
  CancelButtonClicked = 'ImportSalesPlanModal/CANCEL_BUTTON_CLICKED',
}

export interface CancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.CancelButtonClicked;
  payload: {};
}

export function handleCancelButtonClicked(): CancelButtonClickedAction {
  return {
    type: ActionTypeKeys.CancelButtonClicked,
    payload: {},
  };
}

export function handleCloseButtonClicked(): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(handleCancelButtonClicked());
  };
}

export function handleParsingStarted(args: { existingRecordId: number | undefined, fileName: string, contents: string }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    const workBookContainer = await ExcelHelpers.getWorkBookContainerFromBinaryString(args.fileName, args.contents);
    const importableSalesPlan = await SalesPlanSpreadsheetParser.getImportableSalesPlan(workBookContainer);

    if (importableSalesPlan.success) {
      done([], importableSalesPlan.parsed);
    } else {
      done([importableSalesPlan.reason]);
    }
  };
}

export function handleValidationStarted(args: { existingRecordId: number | undefined, importableObject: SalesPlanTypes.ImportableSalesPlan }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to validate sales plan');
  }
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      const result = await GraphQLQuery.msyncClientQuery<{ validateImportableSalesPlan: SalesPlanSchemaTypes.ImportSalesPlanResponse }, SalesPlanSchemaTypes.ImportSalesPlanInput>(
        {
          query: Queries.ValidateImportSalesPlanQuery,
          variables: {
            input: {
              salesPlanId: existingRecordId,
              importableSalesPlan: args.importableObject,
            },
          },
          dispatch,
          disableGlobalError: true,
          fetchPolicy: 'network-only',
        },
      );

      const errors = result.data.validateImportableSalesPlan.errors;
      done(errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleImportStarted(args: { existingRecordId: number | undefined, importableObject: SalesPlanTypes.ImportableSalesPlan }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to import sales plan');
  }
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      const response = await GraphQLMutation.msyncClientMutation<{ importSalesPlan: SalesPlanSchemaTypes.ImportSalesPlanResponse }, SalesPlanSchemaTypes.ImportSalesPlanInput>(
        {
          mutation: Mutations.ImportSalesPlanMutation,
          variables: {
            input: {
              salesPlanId: existingRecordId,
              importableSalesPlan: args.importableObject,
            },
          },
          dispatch,
          suppressErrorModal: true,
          refetchQueries: ['GetSalesPlanWeeklyProductVolume', 'SalesPlanRecordBarQuery'],
        },
      );

      done(response.data.importSalesPlan.errors);
    } catch (error) {
      done([error.message]);
    }
  };
}
