import { GlobalSaveButton } from '../async-button/global-save-button-container';
import * as BS from 'client/components/third-party';
import * as Components from './components';
import * as Constants from './constants';
import * as React from 'react';
import * as Types from './types';

export interface OwnProps {
  isShown: boolean;

  title: string;
  heading: string;

  recipients: Types.EmailRecipient[];

  sendButtonClicked: (recipients: Types.EmailRecipient[]) => any;
  sendButtonDisabled: boolean;
  sendButtonTitle: string;

  cancelButtonClicked: () => void;
  cancelButtonDisabled: boolean;

  includeEmailInput?: boolean;

  initialState?: Partial<ComponentState>;
}

interface ComponentState {
  recipients: Types.CheckableEmailRecipient[];
  emailInput: string | null;
}

export class SendEmailModal extends React.PureComponent<OwnProps, ComponentState> {
  constructor(props: OwnProps) {
    super(props);

    this.state = {
      recipients: props.recipients.map(recipient => ({
        ...recipient,
        checked: false,
      })),
      emailInput: null,
      ...(props.initialState || {}),
    };
  }

  selectAllClicked = () => {
    const allChecked = this.state.recipients.every(r => r.checked);
    const recipients = this.state.recipients.map(r => ({ ...r, checked: !allChecked }));

    this.setState({
      recipients,
    });
  }

  recipientClicked = (recipient: Types.EmailRecipient) => {
    const recipients = this.state.recipients.map(r => ({
      ...r,
      checked: r.emailAddress === recipient.emailAddress && r.name === recipient.name ? !r.checked : r.checked,
    }));

    this.setState({
      recipients,
    });
  }

  emailInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailInput: event.target.value,
    });
  }

  sendButtonClicked = () => {
    const checkedRecipients = this.state.recipients.filter(r => r.checked);

    const recipients = [
      ...checkedRecipients,
      ...(this.state.emailInput
        ? this.state.emailInput.split(/[,;]/)
          .map(emailAddress => emailAddress.trim())
          .filter(emailAddress => emailAddress.length > 0)
          .map(emailAddress => ({ name: 'Unspecified', emailAddress }))
        : []),
    ];

    this.props.sendButtonClicked(recipients);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    const sendButtonDisabled = this.props.sendButtonDisabled ||
      (
        !this.state.recipients.some(r => r.checked) &&
        !this.state.emailInput
      );

    return (
      <BS.Modal
        animation
        backdrop="static"
        show={true}
        onHide={this.props.cancelButtonClicked}
        dialogClassName="send-email-modal-container"
        className="send-email-modal" >
        <BS.Modal.Header closeButton={!this.props.cancelButtonDisabled}>
          <BS.Modal.Title>{this.props.title}</BS.Modal.Title>
        </BS.Modal.Header>
        <BS.Modal.Body>
          <BS.Col sm={12}>
            <h3 className="send-email-modal-heading">
              {this.props.heading}
            </h3>
          </BS.Col>

          {this.props.children}

          <BS.Col sm={12}>
            <hr className="send-email-modal-separator" />
          </BS.Col>
          <div className="send-email-modal-recipients">
            <Components.LabeledDetail title="Recipients">
              {this.state.recipients.length > 0 &&
                <Components.SelectAllCheckbox
                  checked={this.state.recipients.every(r => r.checked)}
                  selectAllClicked={this.selectAllClicked}
                />
              }
              {this.state.recipients.length > 0 &&
                <Components.EmailRecipients
                  recipientClicked={this.recipientClicked}
                  recipients={this.state.recipients}
                />
              }
              {this.props.includeEmailInput &&
                <div className={`${Constants.ModalFormatting.Checkbox} send-email-modal-input`}>
                  <input
                    type="email"
                    onChange={this.emailInputChanged}
                    placeholder="first@example.com, second@example.com"
                    className="form-control"
                  />
                </div>
              }
            </Components.LabeledDetail>
          </div>
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button
            bsClass="mfc-button mfc-clear-button"
            disabled={this.props.cancelButtonDisabled}
            onClick={this.props.cancelButtonClicked}
          >
            Cancel
          </BS.Button>
          <GlobalSaveButton
            disabled={sendButtonDisabled}
            label={this.props.sendButtonTitle}
            onClick={this.sendButtonClicked}
            testid="modal-submit"
            type="submit"
          />
        </BS.Modal.Footer>
      </BS.Modal>
    );
  }
}
