export enum ImportState {
  Initial = 'Initial',
  Parsing = 'Parsing',
  Validating = 'Validating',
  Validated = 'Validated',
  Importing = 'Importing',
  Imported = 'Imported',
  Failed = 'Failed',
}

export enum ImportStep {
  Parsing = 'Parse',
  Validation = 'Validation',
  Import = 'Import',
}

export type ImportableObject = shame;

export type DoneFn = (errors: string[], importableObject?: ImportableObject) => void;
export type ParseFn = (args: { fileName: string, contents: string }, done: DoneFn) => void;
export type ValidateFn = (args: { existingRecordId: number | undefined, importableObject: ImportableObject, additionalArguments: any }, done: DoneFn) => void;
export type ImportFn = (args: { existingRecordId: number | undefined, importableObject: ImportableObject, additionalArguments: any }, done: DoneFn) => void;
