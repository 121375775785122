import * as pluralize from 'pluralize';
import { createSelector } from 'reselect';
import gqlTag from 'graphql-tag';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import * as Hoc from '../hoc';
import * as _ from 'lodash';
import {
  recordType as getRecordType,
  buildFragment,
  flattenFragment,
} from '../../../shared/schemas';
import { ActiveFilter, ActiveSearch, ActiveSort } from 'client/types';
import { Scope } from 'client/hoc/data-container';
import { msyncQuery, MsyncQueryProps } from 'client/hoc/graphql/query';
import { FindAllResult } from 'shared/types/graphql-types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { buildTableStateModule } from 'client/state/tables';

export interface OwnProps {
  refetchStats: () => Promise<void>;
  tablePageNumber: number;
  tableParentInfo: TableParentInfo;
}

export type WorksheetName = string | ((props: any) => string);
export type WorkbookName = string | ((props: any) => string);
export interface FindAllConfig {
  columns: string[];
  excelColumns?: string[];
  table: string;
  scope?: Scope;
  worksheetName?: WorksheetName;
  workbookName?: WorkbookName;
}

export interface DataProps {
  content: Array<Dictionary<any>>;
  filteredRecordIds: number[];
  loadMoreRecords: () => void;
  loading: boolean;
  refetchTable: () => void;
  totalCount: number;
  totalUnfilteredCount: number;
}

interface StateProps {
  activeFilters: ActiveFilter[];
  activeSearch: ActiveSearch;
  activeSortFields: ActiveSort[];
  tablePageNumber: number;
}

const clampPage = (p: FindAllContainerProps) => {
  const rpp = p.tableParentInfo?.rowsPerPage ?? 0;
  const clampedPageNumber = p.tablePageNumber >= 0 ? p.tablePageNumber : 0;
  return rpp * clampedPageNumber;
};

export type FindAllContainerProps = StateProps & DataProps & OwnProps;
export function findAllRecords(config: FindAllConfig): Hoc.Simple<FindAllContainerProps> {
  // due to createSelector, will not run flattenFragment unkes the array of records is a differrent referecne
  const recordType = getRecordType(config.table);
  const fragmentName = `FindAll${recordType}Fragment`;
  const fragment = gqlTag`${buildFragment(config.table, config.columns, fragmentName)}`;
  const FIND_ALL_QUERY =
    gqlTag`
    query findAll${pluralize(recordType)}WithTotal($type: RecordType!, $sortOptions: [SortInput!], $filterOptions: [FilterSpecificationInput], $searchOptions: SearchInput, $offset: Int, $limit: Int, $scope: [FilterSpecificationInput]) {
      content: findAllWithTotal(type: $type, sort: $sortOptions, filters: $filterOptions, search: $searchOptions, offset: $offset, limit: $limit, scope: $scope) {
        records {
          id,
          ...${fragmentName}
        },
        ids
        totalCount
        totalUnfilteredCount
      }
    }
    ${fragment}
    `;

  const FIND_ALL_EXCEL_QUERY = getExcelFindAllQuery(recordType, config);
  const {commonTableProps: mapStateToProps} = buildTableStateModule(config.table);
  const deserializeDataSelector = createSelector([(data: shame) => data.content?.records], records => records?.map(r => flattenFragment(r, config.table)));
  return WrappedComponent => flowRight(
    connect(mapStateToProps),
    msyncQuery<{ content: FindAllResult }, OwnProps, {}>(FIND_ALL_QUERY, {
      alias: 'withFindAllrecords',
      // Don't do the query if we don't know how many rows to retrieve yet
      skip: (p: FindAllContainerProps) => _.isNil(p.tableParentInfo?.rowsPerPage),
      options: (p: FindAllContainerProps) => ({
        fetchPolicy: 'cache-and-network',
        variables: {
          type: recordType,
          filterOptions: p.activeFilters,
          searchOptions: p.activeSearch,
          sortOptions: p.activeSortFields,
          limit: p.tableParentInfo?.rowsPerPage,
          offset: clampPage(p),
          scope: config.scope ? config.scope(p as any) || [] : [],
        },
      }),
      props(p: MsyncQueryProps<OwnProps, { content: FindAllResult }> & { ownProps: FindAllContainerProps }) {
        if (!p.data.loading && !p.data.content)
          console.warn('Missing content in findAll query!', {p});

        const filters = _.flatten(p.ownProps.activeFilters.map(filter => filter.values)).join(', ');
        return {
          content: deserializeDataSelector(p.data),
          filteredRecordIds: p.data?.content?.ids ?? [],
          loadMoreRecords: () => p.data.fetchMore({
            variables: { offset: clampPage(p.ownProps) },
            updateQuery: (previousResult, {fetchMoreResult: {content: more}}: {fetchMoreResult: { content: FindAllResult }}) => !more ? previousResult : {
              ...previousResult,
              content: { ...previousResult.content, records: more.records, totalCount: more.totalCount },
              loading: false,
            },
          }),
          loading: (p.data.networkStatus ?? -1) < 7,
          refetchTable: async () => { await p.ownProps.refetchStats?.(); return await p.data.refetch?.(); },
          totalCount: p.data?.content?.totalCount ?? 0,
          totalUnfilteredCount: p.data?.content?.totalUnfilteredCount ?? 0,
          dataRequest: {
            query: FIND_ALL_EXCEL_QUERY,
            variables: p.data.variables,
            workbookName: config.workbookName
              ? typeof config.workbookName === 'function'
                ? config.workbookName(p.ownProps)
                : config.workbookName
              : `${pluralize(_.startCase(recordType))}${filters ? ` - ${filters}` : ''}`,
            worksheetName: config.worksheetName
              ? typeof config.worksheetName === 'function'
                ? config.worksheetName(p.ownProps)
                : config.worksheetName
              : 'Sheet1',
          },
        };
      },
    }),
  )(WrappedComponent) as shame;
}

export function getExcelFindAllQuery(recordType: any, config: FindAllConfig) {
  const excelFragmentName = `FindAll${recordType}ExcelFragment`;
  const excelFragment = gqlTag`${buildFragment(config.table, config.excelColumns || config.columns, excelFragmentName)}`;
  const FIND_ALL_EXCEL_QUERY = gqlTag`
    query excelFindAll${pluralize(recordType)}WithTotal($type: RecordType!, $sortOptions: [SortInput!], $filterOptions: [FilterSpecificationInput], $searchOptions: SearchInput, $scope: [FilterSpecificationInput]) {
      content: findAllWithTotal(type: $type, sort: $sortOptions, filters: $filterOptions, search: $searchOptions, scope: $scope) {
        records {
          id,
          ...${excelFragmentName}
        },
        ids
        totalCount
        totalUnfilteredCount
      }
    }
    ${excelFragment}
  `;

  return FIND_ALL_EXCEL_QUERY;
}
