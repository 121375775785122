import gql from 'graphql-tag';
import { ReceivableOrderReceivingStatus } from 'shared/types';

export interface ShowSendReceivingReceiptModalFields {
  receivableOrder?: {
    id: number;
    receivingStatus?: ReceivableOrderReceivingStatus;
  };
}

export type ShowSendReceivingReceiptModalResponse = ShowSendReceivingReceiptModalFields;

export const ShowSendReceivingReceiptModalQuery = gql`
  query showSendReceivingReceiptModal($receivableOrderId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      receivingStatus
    }
  }
`;
