import * as _ from 'lodash';
import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { FetchPolicy } from 'apollo-client';
import { SORT_TYPES, CELL_TYPES, TYPES } from 'shared/types';
import { propToComponent } from 'client/hoc/hoc';
import { ActiveSort, ActiveFilter, AvailableFilter } from 'client/types';
import { EMPTY_ARRAY, TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME } from 'client/constants';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { GetRoutingStopsInput } from 'schema/routing-stop/routing-stop-graphql-types';
import { withClientSideSorting } from 'client/containers/table/client-side-sorting';
import { buildTableStateModule } from 'client/state/tables';
import { GetRoutingStopGQLResult } from 'schema/routing-stop/routing-stop-graphql-types';
import { IColumn } from 'client/components/table/column';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';

interface WithRoutingStopRowProps {
  routingStopRows: Array<{
    stopNumber: string;
    customerName: string;
    storeNumber: string;
    customerOrderIdentifiers: string;
    mileage: number;
    totalMiles: number;
  }>;
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
}

interface GetRoutingStopsResponse {
  getRoutingStops?: {
    routingStops: GetRoutingStopGQLResult[];
    totalCount: number;
  };
}

const GetRoutingStopsQuery = gql`
  query routingStopLoadTableListForDisplayToTheUserOnTheirScreen($routingLoadId: Int!, $sort: [SortInput!], $limit: Int, $offset: Int) {
    getRoutingStops: GetRoutingStops(routingLoadId: $routingLoadId, sort: $sort, limit: $limit, offset: $offset) {
      routingStops {
        stopNumber
        customerName
        storeNumber
        city
        state
        customerOrderIdentifiers
        mileage
        totalMiles
        racks
        pallets
      }
      totalCount
    }
  }
`;

const RoutingStopTableExcelQuery = gql`
  query routingStopLoadTableListForExcelDownload($routingLoadId: Int!, $sort: [SortInput!]) {
    getRoutingStops: GetRoutingStops(routingLoadId: $routingLoadId, sort: $sort) {
      routingStops {
        stopNumber
        customerName
        storeNumber
        city
        state
        customerOrderIdentifiers
        mileage
        totalMiles
        racks
        pallets
      }
    }
  }
`;

const FilterableTable: React.StatelessComponent<FilterableTableProps> = buildFilterableTable(TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME);
const columns: IColumn[] = [
  { id: 'stopNumber'              , accessor: 'stopNumber'              , header: 'Stop #'         , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'customerName'            , accessor: 'customerName'            , header: 'Customer'       , tableEditable: false, columnWidth: 20, sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'storeNumber'             , accessor: 'storeNumber'             , header: 'Store #'        , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'city'                    , accessor: 'city'                    , header: 'City'           , tableEditable: false, columnWidth: 10, sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'state'                   , accessor: 'state'                   , header: 'State'          , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'customerOrderIdentifiers', accessor: 'customerOrderIdentifiers', header: 'Customer Orders', tableEditable: false, columnWidth: 25, sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'racks'                   , accessor: 'racks'                   , header: 'Racks'          , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'pallets'                 , accessor: 'pallets'                 , header: 'Pallets'        , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'mileage'                 , accessor: 'mileage'                 , header: 'Mileage'        , tableEditable: false, columnWidth: 10, sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'totalMiles'              , accessor: 'totalMiles'              , header: 'Total Miles'    , tableEditable: false, columnWidth: 10, sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
];

const StopsTableUI = (props: WithRoutingStopRowProps & {
  activeSortFields: ActiveSort[];
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
  tableParentInfo: TableParentInfo;
}) => (
  <FilterableTable
    table={TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME}
    content={props.routingStopRows || EMPTY_ARRAY}
    loading={props.loading}
    columns={columns}
    totalCount={props.totalCount}
    totalUnfilteredCount={props.totalUnfilteredCount}
    filteredRecordIds={props.filteredRecordIds}
    searchableFields={EMPTY_ARRAY}
    availableFilters={props.availableFilters}
    disableCreate
    tablePaginated={false}
    tableParentInfo={props.tableParentInfo}
    displayLoadingIndicator
    dataRequest={props.dataRequest}
    headerMenuItems={[]}
    rowMenuItems={[]}
    placeholder="There are no stops to display."
  />
);


export interface OwnProps { routingLoadId?: RoutingLoadId; }
interface StateProps { activeSortFields: ActiveSort[]; activeFilters: ActiveFilter[]; tablePageNumber: number; }
interface WithTableParentProps { tableParentInfo: TableParentInfo; }
interface WithRoutingStopProps { routingStopRows: any[]; }
const WithRoutingStops = msyncQuery<GetRoutingStopsResponse, OwnProps & StateProps & WithTableParentProps, WithRoutingStopRowProps, GetRoutingStopsInput>(GetRoutingStopsQuery, {
  options: (ownProps): { fetchPolicy: FetchPolicy, variables: GetRoutingStopsInput } => ({
    fetchPolicy: 'network-only',
    variables: { routingLoadId: ownProps.routingLoadId } as GetRoutingStopsInput,
  }),
  props: ({ data, ownProps }): WithRoutingStopRowProps => ({
    routingStopRows: data.getRoutingStops ? data.getRoutingStops.routingStops || [] : [],
    loading: _.isNil(data.loading) ? true : data.loading,
    filteredRecordIds: [],
    totalCount: data.getRoutingStops ? data.getRoutingStops.totalCount : 0,
    totalUnfilteredCount: data.getRoutingStops ? data.getRoutingStops.totalCount : 0,
    availableFilters: [],
    dataRequest: {
      operationName: '',
      query: RoutingStopTableExcelQuery,
      workbookName: `RoutingStops`,
      variables: {
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'stopNumber' }],
        routingLoadId: ownProps.routingLoadId,
      },
    },
  }),
});

export type ComponentProps
  = OwnProps
  & StateProps
  & WithRoutingStopProps
  & WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;
const tableName = TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME;
const TableStateHelpers = buildTableStateModule(tableName);
const mapStateToProps = (state): StateProps => TableStateHelpers.commonTableProps(state);
const component = _.flowRight(
  tableParentHoc(),
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  WithRoutingStops,
  withClientSideSorting<ComponentProps>(tableName, 'routingStopRows'),
)(StopsTableUI) as Component<OwnProps>;

export const LoadDetailsStopsTable = (props: OwnProps) => propToComponent(component, props);
