import * as _ from 'lodash';
import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import * as Mutation from './mutation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { push } from 'connected-react-router';
import { SalesPlanFormValues } from './types';
import * as FormHelpers from 'client/helpers/form-helpers';
import { FormFields } from './constants';

type NonNull<T> = T extends null ? never : T;

const required = <T>(value: T, label: string): NonNull<T> => {
  if (_.isNull(value)) {
    throw new Error(`${label} is required.`);
  }
  return value as NonNull<T>;
};

const updateSalesPlan = async (dispatch: Dispatch<any>, salesPlanId: number, formValues: Partial<SalesPlanFormValues>): Promise<number> => {
  const year = required(formValues.year, 'Sales Plan Year');
  const input: Mutation.UpdateSalesPlanInput = {
    salesPlanId,
    input: {
      year: year !== undefined ? _.parseInt(year) : year,
      identifier: required(formValues.identifier, 'Sales Plan Season'),
      customerId: required(formValues.customerId, 'Sales Plan Customer'),
      sellDepartmentId: required(formValues.sellDepartmentId, 'Sales Plan Sell Department'),
    },
  };
  const result = await msyncClientMutation<Mutation.UpdateSalesPlanResponse>({
    mutation: Mutation.UpdateSalesPlanMutation,
    variables: input,
    dispatch,
  });
  return result.data.salesPlan.id;
};

const createSalesPlan = async (dispatch: Dispatch<any>, formValues: SalesPlanFormValues): Promise<number> => {
  const customerId = formValues[FormFields.customerId];
  const year = formValues[FormFields.year];
  const identifier = formValues[FormFields.identifier];
  const sellDepartmentId = formValues[FormFields.sellDepartmentId];

  if (!customerId || !year || !identifier || !sellDepartmentId) {
    throw new Error('Unable to create a sales plan without all the necessary information');
  }

  const result = await msyncClientMutation<Mutation.CreateSalesPlanResponse, Mutation.CreateSalesPlanInput>({
    mutation: Mutation.CreateSalesPlanMutation,
    variables: {
      input: {
        year: _.parseInt(year),
        identifier,
        customerId,
        sellDepartmentId,
      },
    },
    dispatch,
  });
  return result.data.salesPlan.id;
};

export const formSubmitted = (formValues: SalesPlanFormValues, props: { salesPlanId?: number, initialValues?: SalesPlanFormValues, confirmOkToSave: () => Promise<boolean> }): Thunker =>
  async dispatch => {
    if (props.salesPlanId) {
      const modified = props.initialValues ? FormHelpers.getFormChanges(formValues, props.initialValues) : formValues;
      await updateSalesPlan(dispatch, props.salesPlanId, modified);
    } else {
      const salesPlanId = await createSalesPlan(dispatch, formValues);
      dispatch(push(`/planning/sales/details/${salesPlanId}`));
    }
  };

export const downloadMenuItemClicked = (salesPlanId: number): Thunker =>
  async dispatch => {
    const result = await msyncClientMutation<Mutation.DownloadSalesPlanMutationResponse, Mutation.DownloadSalesPlanMutationInput>({
      mutation: Mutation.DownloadSalesPlanMutation,
      variables: {
        salesPlanId,
      },
      dispatch,
    });

    const reportId = result.data.response.report.id;

    window.location.replace(`/report/fileDownload/${reportId}`);
  };

export const downloadCustomerSpecSheetMenuItemClicked = (salesPlanId: number): Thunker =>
async dispatch => {
  const result = await msyncClientMutation<Mutation.DownloadCustomerSpecSheetPdfMutationResponse, Mutation.DownloadCustomerSpecSheetPdfMutationInput>({
    mutation: Mutation.DownloadCustomerSpecSheetPdfMutation,
    variables: {
      salesPlanId,
    },
    dispatch,
  });

  const reportId = result.data.response.report.id;

  window.location.replace(`/report/fileDownload/${reportId}`);
};

export const downloadDifferenceReportCalculationSpreadsheetMenuItemClicked = (salesPlanId: number): Thunker =>
async dispatch => {
  const result = await msyncClientMutation<Mutation.DownloadDifferenceReportCalculationSpreadsheetMutationResponse, Mutation.DownloadDifferenceReportCalculationSpreadsheetMutationInput>({
    mutation: Mutation.DownloadDifferenceReportCalculationSpreadsheetMutation,
    variables: {
      salesPlanId,
    },
    dispatch,
  });

  const reportId = result.data.response.report.id;

  window.location.replace(`/report/fileDownload/${reportId}`);
};
