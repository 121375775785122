import { DISPLAY_TYPES, ActiveInactive } from '../types';
import { Supplier } from './supplier';

import {
  tableName, property, belongsTo, definePresentation, setSchemaOptions, required,
} from './dsl';
import { IRecord } from './record';
import { displayType } from './dsl';
import { SupplierDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('tags')
export class Tag implements IRecord {
  id?: number;
  @property @required identifier: string;
  @property @required tagType: string;

  @belongsTo('suppliers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property supplier: Supplier;
  supplierId: number;

  @property partNumber: string;
  @property size: string;
  @property @required activeStatus: ActiveInactive;
}

definePresentation(Tag, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'ID',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  tagType: {
    searchable: true,
    sortable: true,
    displayName: 'Type',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 30,
  },
  supplier: {
    searchable: true,
    sortable: true,
    displayName: 'Supplier',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }),
    columnWidth: 20,
  },
  partNumber: {
    searchable: true,
    sortable: true,
    displayName: 'Part #',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
  },
  size: {
    searchable: true,
    sortable: true,
    displayName: 'Size',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 15,
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
});

setSchemaOptions(Tag, {
  softDeletable: true,
});
