import * as _ from 'lodash';
import { ExpectedError } from '../errors';
import { Bug } from './bug';

export const consoleColors = {
  Unchanged : '',

  Reset     : '\x1b[0m',
  Bright    : '\x1b[1m',
  Dim       : '\x1b[2m',
  Underscore: '\x1b[4m',
  Blink     : '\x1b[5m',
  Reverse   : '\x1b[7m',
  Hidden    : '\x1b[8m',

  FgBlack  : '\x1b[30m',
  FgRed    : '\x1b[31m',
  FgGreen  : '\x1b[32m',
  FgYellow : '\x1b[33m',
  FgBlue   : '\x1b[34m',
  FgMagenta: '\x1b[35m',
  FgCyan   : '\x1b[36m',
  FgWhite  : '\x1b[37m',

  BgBlack  : '\x1b[40m',
  BgRed    : '\x1b[41m',
  BgGreen  : '\x1b[42m',
  BgYellow : '\x1b[43m',
  BgBlue   : '\x1b[44m',
  BgMagenta: '\x1b[45m',
  BgCyan   : '\x1b[46m',
  BgWhite  : '\x1b[47m',
} as const;

export const Colors = _.mapValues(consoleColors, (v, k) => s => `${consoleColors[k]}${s}${consoleColors.Reset}`) as { [K in keyof typeof consoleColors]: (s: string) => string };

export const highlight = (s: string) => `${consoleColors.Bright}${consoleColors.BgGreen}${consoleColors.FgRed}${s}${consoleColors.Reset}`;
export const blockHeader = (s: string) =>{
  const lines = s.split('\n').map(x => `${x}`).filter(x => x.length > 0 );
  const repetitions = (_.maxBy(lines, l => l.length)) as shame<'lodash types 😡'> as number || s.length;
  return highlight('='.repeat(repetitions));
};
export const blockHighlight = (s: string) => [blockHeader(s), blockHeader(s), highlight(s), blockHeader(s), blockHeader(s)].join('\n');

/** leave-in tracing that disappears in test and in production */
export const debug = (...args: unknown[]) => ['development', 'agent'].includes(process.env.NODE_ENV!) ? console.debug(...args) : undefined;

/** leave-in tracing that disappears in production -- put persists in tests*/
export const debugTest = (...args: unknown[]) => ['test', 'development', 'agent'].includes(process.env.NODE_ENV!) ? console.debug(...args) : undefined;

export type ErrorLike = { name: string, message: string, stack: string };
export const isErrorLike = (o): o is Error | ErrorLike => typeof o === 'object' && 'name' in o && 'message' in o && 'stack' in o;
export const extractMessage = (err: string | Error) => isErrorLike(err) ? err.message : err;
export const orThrow = (message: Error | string, ...thingsToDump: any[]): never => {
  const err = isErrorLike(message) ? message : new Error(message);
  Object.defineProperty(err, 'dumpContext', {enumerable: true, writable: false, value: thingsToDump});
  throw err;
};

export const orThrowBug = (err: string | Error, ...thingsToDump: any[]): never => orThrow(new Bug(extractMessage(err)), ...thingsToDump);
export const orThrowExpected = (err: string | Error, ...thingsToDump: any[]): never => orThrow(new ExpectedError(extractMessage(err)), ...thingsToDump);
