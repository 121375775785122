import { appendFieldValidators } from 'shared/schemas/dsl';
import { Product } from 'shared/schemas/product';
import * as Validators from 'shared/validators';
import { ShippingUnitType, ActiveInactive, FloralShipTypes } from 'shared/types';
import { isRackProduct, isReplenishmentStatusActive, isActive } from 'shared/validators/product-validators';

appendFieldValidators(Product, {
  packSize: [Validators.GREATER_THAN_ZERO],
  sellPack: [Validators.GREATER_THAN_ZERO],
  primaryShippingUnitType: [Validators.INCLUSION(ShippingUnitType)],
  replenishmentStatus: [Validators.INCLUSION(ActiveInactive)],
  duplicateSkuUpc: [Validators.INCLUSION(ActiveInactive)],
  shipType: [Validators.INCLUSION(FloralShipTypes)],
  casesPerPallet: [Validators.GREATER_THAN_ZERO],
  packsPerRack: [
    Validators.GREATER_THAN_ZERO,
    ...Validators.whenAll(
      'When considering an Active, Replenishable, Rack-based Product: ',
      isRackProduct,
      isReplenishmentStatusActive,
      isActive,
    )(
      Validators.REQUIRED,
      Validators.VALID_PPS_SPR_CALCULATION,
    ),
  ],
  packsPerShelf: [Validators.GREATER_THAN_ZERO],
  shelvesPerRack: [Validators.GREATER_THAN_ZERO],
  sellDays: [Validators.GREATER_THAN_ZERO],
  stemLength: [Validators.GREATER_THAN_ZERO],
  generalLedgerAccountIdentifier: [Validators.MATCHES_OR_IS_NULL(/^\d{4}-\d{4}-\d{2}$/)],
});
