import * as State from './state';
import { ActionTypes, ActionTypeKeys } from './actions';
import * as R from 'ramda';

type DistributionReceivingState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): DistributionReceivingState {
  switch (action.type) {
    case ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_MENU_ITEM_CLICKED:
      return R.pipe(
        State.isSendMicsModalShown.set(true),
        State.customerOrdersIdsAlreadySent.set(action.payload.customerOrdersIdsAlreadySent),
        State.customerOrdersIdsNotFullyReceived.set(action.payload.customerOrdersIdsNotFullyReceived),
        State.customerOrdersIdsToBeSent.set(action.payload.customerOrdersIdsToBeSent),
        State.customerOrdersIdsThatAreScanBased.set(action.payload.customerOrdersIdsThatAreScanBased),
      )(state);
    case ActionTypeKeys.SEND_MICS_FILE_CANCEL_BUTTON_CLICKED_ACTION:
      return R.pipe(
        State.isSendMicsModalShown.set(false),
        State.customerOrdersIdsAlreadySent.set([]),
        State.customerOrdersIdsNotFullyReceived.set([]),
        State.customerOrdersIdsToBeSent.set([]),
        State.customerOrdersIdsThatAreScanBased.set([]),
      )(state);
    default:
      return state;
  }
}
