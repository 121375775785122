import { SORT_TYPES, SEARCH_FIELD_ANY } from 'shared/types';
import * as TableState from 'client/state/table';

export function createCustomTable(overrides?: Partial<TableState.Type>): TableState.Type {
  return {
    search: { fields: [SEARCH_FIELD_ANY] },
    filters: [],
    sort: [{
      sortOrder: SORT_TYPES.ASC,
      sortField: 'identifier',
    }],
    editing: { value: false, shouldSave: false },
    selectedCell: {
      row: -1,
      column: 0,
    },
    checkedRecordIds: [],
    pageNumber: 0,
    rowsPerPage: 1,
    availableSearchFields: [],
    hasInvalidCell: false,
    orderToDelete: undefined,
    showConfirmationModal: false,
    ...overrides,
  };
}
