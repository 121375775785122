import * as _  from 'lodash';
import gqlTag from 'graphql-tag';
import { plural, singular } from 'pluralize';
import { msyncQuery } from 'client/hoc/graphql/query';
import { buildFragment } from 'shared/schemas';
import { ActiveFilter } from 'client/types';
import { SortInput } from 'shared/types';
import { propToComponent } from 'client/hoc/hoc';

export interface Option { id: any, value: any, identifier: any }
export type Options = Option[];
export const supplyEmptyOptionsIfUndefined = <Props>(optionsProp: keyof Props) =>
  WrappedComponent =>
    (p: Props) => propToComponent(WrappedComponent, { ...(p as any), [optionsProp]: _.isNil(p[optionsProp]) ? [] : p[optionsProp] });

let fragmentCounter = 0;
export const optionsContainerWithFilters = <TChildProps>(args: {
  table: string,
  columns: string[],
  getFilters: (ownProps: any) => ActiveFilter[] | null,
  resultPropName?: string,
  props?: (ownProps: any) => any,
  skip?: (ownProps: any) => any,
  sort?: SortInput[],
}) => msyncQuery<{content}, {}, TChildProps>(
  gqlTag`
  query findAll${_.upperFirst(args.table)}($type: RecordType!, $filters: [FilterSpecificationInput], $sort: [SortInput!]) {
    content: findAll(type: $type, filters: $filters, sort: $sort) {
      id,
      ...Options${_.upperFirst(args.table)}Fragment_${fragmentCounter}
    }
  }
  ${gqlTag`${buildFragment(args.table, args.columns, (`Options${_.upperFirst(args.table)}Fragment_${fragmentCounter++}`))}`}
`, {
  options: ownProps => ({ variables: {
    sort: args.sort || { sortField: args.columns[0], sortOrder: 'ASC' },
    type: _.upperFirst(singular(args.table)),
    ...(args.getFilters(ownProps) ? { filters: args.getFilters(ownProps) } : {}),
  }}),
  props: args.props || (result => ({ [args.resultPropName || args.table]: result.data.content, [`${args.resultPropName || args.table}Loading`]: result.data.loading })),
  skip: args.skip || (() => false),
});

export const optionsContainerGenerator = <TChildProps>(args: {
  table: string,
  columns: string[],
  parent?: string,
  resultPropName?: string,
  getAdditionalFilters?: (ownProps: any) => ActiveFilter[],
  skip?: (ownProps: any) => any,
}) => optionsContainerWithFilters<TChildProps>({
  getFilters: (ownProps: any): ActiveFilter[] => [
    ...(!args.parent               ? [] : [{ field: args.parent, values: _.compact(_.flatten([ownProps[`selected${_.upperFirst(args.parent)}Id`]])) }]),
    ...(!args.getAdditionalFilters ? [] : args.getAdditionalFilters(ownProps)),
  ],
  ...args,
});

export const distinctOptionsContainerGenerator = (table: string, field: string) => msyncQuery<{content: Array<{field: string, options: any[]}>}>(
  gqlTag`
  query filterOptions${_.upperFirst(table)}($filterQuery: FilterOptionsInput!) {
    content: filterOptions(filterQuery: $filterQuery) {
      field,
      options {
        id,
        value,
        _customType,
      }
    }
  }
  `, {
  options: () => ({ variables: { filterQuery: { fields: [field], type: _.upperFirst(singular(table)) } } }),
  props: args => ({ [plural(field)]: (((args.data.content || []).find((p: any) => p.field === field)) || {}).options || [] }),
});
