import * as React from 'react';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledCheckbox, LabeledInput } from 'client/components/form';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import { ColumnAlignment, SelectableRow } from 'client/components/selectable/types';
import { ReportTypes, INPUT_TYPES } from 'shared/types';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ReportUserParams } from 'client/components/report-user-params';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import * as _ from 'lodash';
import { DropDownOptions } from 'client/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';

const emptyArray = [];
const optionTextFormatter = option => option.value;

interface LabeledSelectFieldProps {
  name: string;
  options: DropDownOptions;
  handleChange?(id: number): void;
  label?: string;
  colSize?: number;
}

interface LabeledSelectableFieldProps {
  name: string;
  options?: SelectableRow[];
  loading?: boolean;
  required?: boolean;
  handleChange?(ids: number[]): void;
}

interface LabeledCheckboxFieldProps {
  name: string;
  handleChange?(isOn: boolean): void;
}

interface LabeledInputFieldProps {
  name: string;
  disabled?: boolean;
}

export class Customer extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={4}
        label="Customer"
        options={this.props.options || emptyArray}
        labelColSize={4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        required
        horizontalLabel={false}
        testid="report-customer"
      />
    );
  }
}

export class SellDepartment extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={12}
        label="Sell Department"
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sell-department"
        required
      />
    );
  }
}

interface SelectFieldProps {
  options: Array<{
    id: string;
  }>;
  name: string;
  label: string;
  offset?: number;
  size?: number;
}

export class SelectField extends React.PureComponent<SelectFieldProps, {}> {
  render() {
    return (
      <Field
        component={LabeledSelect}
        horizontalLabel={false}
        inputColSize={this.props.size === undefined ? 11 : this.props.size}
        labelColSize={this.props.size === undefined ? 11 : this.props.size}
        label={this.props.label}
        name={this.props.name}
        offset={this.props.offset === undefined ? 1 : this.props.offset}
        options={this.props.options}
        required
        testid={`report-${_.kebabCase(this.props.label)}`}
        textFormatter={value => value.id}
        valueField="value"
      />
    );
  }
}

export class ScanBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="Scan Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-scan-based"
        class="scanBased"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class PoBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="PO Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-po-based"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-class"
        optional
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductSubClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        handleChange={this.props.handleChange}
        inputColSize={12}
        label="Sub Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-subclass"
        optional
      />
    );
  }
}

export class Product extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Products"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        handleChange={this.props.handleChange}
        required
        testid="report-products"
      />
    );
  }
}

export class PrimaryGlobalAllField extends React.PureComponent<{ name: string }, {}> {
  options = [
    { id: PrimaryGlobalAll.Primary },
    { id: PrimaryGlobalAll.Global },
    { id: PrimaryGlobalAll.All },
  ];
  render() {
    return (
      <Field
        component={LabeledSelect}
        horizontalLabel={false}
        inputColSize={12}
        labelColSize={12}
        label="Primary/Global/All"
        name={this.props.name}
        offset={0}
        options={this.options}
        required
        testid={`report-primary-global-all}`}
        textFormatter={value => value.id}
        valueField="value"
      />
    );
  }
}

export class MfcArea extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 50 },
    { alignment: ColumnAlignment.Left, width: 50 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="MFC Areas"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        handleChange={this.props.handleChange}
        testid="report-mfc-area"
        optional
      />
    );
  }
}

export class Region extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [{ alignment: ColumnAlignment.Left, width: 40 },
          { alignment: ColumnAlignment.Left, width: 60 }];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Regions"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-region"
        handleChange={this.props.handleChange}
        optional
      />
    );
  }
}

export class Market extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [{ alignment: ColumnAlignment.Left, width: 40 },
          { alignment: ColumnAlignment.Left, width: 60 }];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Markets"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-market"
        handleChange={this.props.handleChange}
        optional
      />
    );
  }
}

export class Store extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 10 },   // number
    { alignment: ColumnAlignment.Left, width: 40 },   // city
    { alignment: ColumnAlignment.Left, width: 10 },   // state
    { alignment: ColumnAlignment.Left, width: 20 },   // Primary/Global
    { alignment: ColumnAlignment.Left, width: 20 },   // active
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        cols={this.cols}
        inputColSize={12}
        label="Stores"
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-store"
        handleChange={this.props.handleChange}
        required
      />
    );
  }
}

export class EmailReportDaily extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <div>
        <div className="mfc-form-stacked-label required">Report Status</div>
        <Field
          name={this.props.name}
          component={LabeledCheckbox}
          inputColSize={11}
          label="Email Report Daily"
          labelColSize={11}
          offset={0}
          valueField="value"
          horizontalLabel={false}
          testid="report-email-report-daily"
          required
          />
      </div>
    );
  }
}

export class EmailSubject extends React.PureComponent<LabeledInputFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledInput}
        inputColSize={11}
        label="Email Subject"
        labelColSize={11}
        offset={1}
        horizontalLabel={false}
        testid="report-email-subject"
        required
        disabled={this.props.disabled}
        type={INPUT_TYPES.TEXT}
      />
    );
  }
}

export class EmailRecipients extends React.PureComponent<LabeledInputFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledInput}
        type={INPUT_TYPES.TEXTAREA}
        inputColSize={11}
        label="Email Recipients"
        labelColSize={11}
        offset={1}
        horizontalLabel={false}
        testid="report-email-recipients"
        required
        disabled={this.props.disabled}
      />
    );
  }
}

interface SidebarProps {
  reportType: ReportTypes;
  downloadButtonDisabled: boolean;
  handleDownloadExcelReportClicked(): void;
  downloadButtonLabel?: string;
  secondHandleDownloadExcelReportClicked?(): void;
  secondDownloadButtonDisabled?: boolean;
  secondDownloadButtonLabel?: string;
  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;
  pristine: boolean;
  excelDownloadStatus: MutationStatus;
  reportFormName: string;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
  children?: React.ReactNode[];
}

export class Sidebar extends React.PureComponent<SidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        <div>
          {this.props.children}
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label={this.props.downloadButtonLabel || 'Download Excel File'}
            onClick={this.props.handleDownloadExcelReportClicked}
            actionStatus={this.props.excelDownloadStatus}
            testid="download-excel-report-button"
          />
        </div>

        {this.props.secondDownloadButtonLabel && this.props.secondHandleDownloadExcelReportClicked &&
          <div className="sidebar-action-button-container">
            <AsyncButton
              classNames={['report-sidebar-button']}
              disabled={this.props.secondDownloadButtonDisabled}
              label={this.props.secondDownloadButtonLabel}
              onClick={this.props.secondHandleDownloadExcelReportClicked}
              actionStatus={this.props.excelDownloadStatus}
              testid="download-excel-report-button"
            />
          </div>
        }

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          anyoneCanEdit
          noDefault
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}
