import * as React from 'react';
import { SupplierOrderInvoiceNumber, SupplierOrderInvoiced } from 'shared/schemas/supplier-order';
import { Form, Row, FormGroup } from 'client/components/third-party';
import { LabeledDisplay } from 'client/components/form';
import { OrderMethod, ShippingUnitType, DeliveryMethods, SupplierOrderStatus, DateTimeStr, ReceivableOrderReceivingStatus, EM_DASH, DateStr, CustomerOrderType, SupplierOrderTypes } from 'shared/types';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { SellDepartmentIdentifier } from 'shared/schemas/sell-department';
import { SubSellDepartmentIdentifier } from 'shared/schemas/sub-sell-department';
import { OrderStatus } from 'client/components/order-status';
import { formatDate } from 'shared/helpers/date-helpers';
import { SimpleMenu, SetInProgress, SimpleMenuItem } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import * as _ from 'lodash';

export interface SimpleMenuItemWithSupplierOrderIdentifier {
  label: string;
  action: (setInProgress: SetInProgress, supplierOrderIdentifier: string) => void;
}

export interface OwnProps {
  receivableOrderId: number;
  identifier: string;
  orderType: SupplierOrderTypes | CustomerOrderType.ExternalDistribution;
  plannedArrivalDate: DateStr;
  storeDeliveryDate: DateStr;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  invoiceNumber: SupplierOrderInvoiceNumber;
  sellDepartment: SellDepartmentIdentifier;
  subSellDepartment?: SubSellDepartmentIdentifier;
  supplier: string;
  customer: string;
  mfcAreaIdentifier?: MfcAreaIdentifier;
  notes?: string | null;
  deliveryMethod: DeliveryMethods;
  orderStatus: SupplierOrderStatus;
  received: ReceivableOrderReceivingStatus;
  invoiced: SupplierOrderInvoiced;
  actionMenuItems?: SimpleMenuItemWithSupplierOrderIdentifier[];
  isOrderSent?: boolean;
  lastPurchaseOrderEmailedName?: string;
  lastPurchaseOrderEmailedAt?: DateTimeStr;
  lastReceivingReceiptEmailedAt?: DateTimeStr | null;
  lastReceivingReceiptEmailedName?: string | null;
  scanBased: boolean | null;
}

export const ReceivingOverviewSidebar: React.SFC<OwnProps> = props => {
  const showActionMenu = props.actionMenuItems && props.actionMenuItems.length > 0;
  const receivingStatus = (
    <React.Fragment>
      <FormGroup>
        {props.isOrderSent && <OrderStatus input={{ value: props.orderStatus }} label={'Order Status'} username={props.lastPurchaseOrderEmailedName} lastUpdatedDate={props.lastPurchaseOrderEmailedAt}/> }
        {!props.isOrderSent && <OrderStatus input={{ value: props.orderStatus }} label={'Order Status'} /> }
      </FormGroup>
      <FormGroup>
        {props.lastReceivingReceiptEmailedAt && <OrderStatus input={{ value: 'Sent' }} label={'Receiving Receipt Status'} username={props.lastReceivingReceiptEmailedName} lastUpdatedDate={props.lastReceivingReceiptEmailedAt}/> }
        {!props.lastReceivingReceiptEmailedAt && <OrderStatus input={{ value: EM_DASH }} label={'Receiving Receipt Sent'} /> }
      </FormGroup>
    </React.Fragment>
  );

  return (
    <div className="mfc-form-sidebar">
      <Form horizontal>
        <div data-testid="receivable-order">
          {props.orderType === 'External Distribution' &&
            <Row>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="PO #" inputColSize={6} value={props.identifier} testid="sidebar-po-number" />
                {showActionMenu &&
                  <SimpleMenu
                  label="Actions"
                  menuItems={(props.actionMenuItems || [])
                    .map((menuItemWithSupplierOrderIdentifier): SimpleMenuItem => {
                      return {
                        label: menuItemWithSupplierOrderIdentifier.label,
                        action: (setInProgress: SetInProgress) => menuItemWithSupplierOrderIdentifier.action(setInProgress, props.identifier),
                      };
                    })}
                    />
                  }
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Order Type" inputColSize={6} value={props.orderType} testid="sidebar-order-type" />
                <SimpleCheckbox value={props.scanBased ?? false} label="Scan Order" disabled onChange={_.noop} className="receivable-scan-based-checkbox" testid="sidebar-scan-order" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Sell Department" inputColSize={12} value={props.sellDepartment} testid="sidebar-sell-department" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Customer" inputColSize={6} value={props.customer} testid="sidebar-customer" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="MFC Area" inputColSize={6} value={props.mfcAreaIdentifier} testid="sidebar-mfc-area" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Vendor" inputColSize={12} value={props.supplier} testid="sidebar-supplier" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Order Method" inputColSize={6} value={props.orderMethod} testid="sidebar-order-method" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="Shipping Unit" inputColSize={6} value={props.shippingUnitType} testid="sidebar-shipping-unit" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Delivery Date" inputColSize={6} value={formatDate(props.storeDeliveryDate, 'MM/DD/YYYY')} testid="sidebar-delivery-date" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="Arrival Date" inputColSize={6} value={formatDate(props.plannedArrivalDate, 'MM/DD/YYYY')} testid="sidebar-arrival-date"/>
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} textarea label="Notes" inputColSize={12} value={props.notes} testid="sidebar-notes" />
              </FormGroup>
              {receivingStatus}
            </Row>
          } {props.orderType !== 'External Distribution' &&
            <Row>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Supplier" inputColSize={showActionMenu ? 6 : 12} value={props.supplier} testid="sidebar-supplier" />
                {showActionMenu &&
                  <SimpleMenu
                    label="Actions"
                    menuItems={(props.actionMenuItems || [])
                      .map((menuItemWithSupplierOrderIdentifier): SimpleMenuItem => {
                        return {
                          label: menuItemWithSupplierOrderIdentifier.label,
                          action: (setInProgress: SetInProgress) => menuItemWithSupplierOrderIdentifier.action(setInProgress, props.identifier),
                        };
                    })}
                  />
                }
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Order Type" inputColSize={12} value={props.orderType} testid="sidebar-order-type" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Delivery Method" inputColSize={12} value={props.deliveryMethod} testid="sidebar-delivery-method" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Arrival Date" inputColSize={6} value={formatDate(props.plannedArrivalDate, 'MM/DD/YYYY')} testid="sidebar-arrival-date"/>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Store Delivery Date" inputColSize={6} value={formatDate(props.storeDeliveryDate, 'MM/DD/YYYY')} testid="sidebar-delivery-date" />
              </FormGroup>
              <FormGroup>
                {!props.subSellDepartment &&
                  <LabeledDisplay horizontalLabel={false} inputStyle label="Sell Department" inputColSize={12} value={props.sellDepartment} testid="sidebar-sell-department" />
                }
                {props.subSellDepartment &&
                  <div>
                    <LabeledDisplay horizontalLabel={false} inputStyle label="Sell Department" inputColSize={6} value={props.sellDepartment} testid="sidebar-sell-department" />
                    <LabeledDisplay horizontalLabel={false} inputStyle label="Sub Sell Dept." inputColSize={6} value={props.subSellDepartment} testid="sidebar-sub-sell-dept" />
                  </div>
                }
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Customer" inputColSize={6} value={props.customer} testid="sidebar-customer" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="MFC Area" inputColSize={6} value={props.mfcAreaIdentifier} testid="sidebar-mfc-area" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="Order Method" inputColSize={6} value={props.orderMethod} testid="sidebar-order-method" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="Shipping Unit" inputColSize={6} value={props.shippingUnitType} testid="sidebar-shipping-unit" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} inputStyle label="PO #" inputColSize={6} value={props.identifier} testid="sidebar-po-number" />
                <LabeledDisplay horizontalLabel={false} inputStyle label="Invoice Number" inputColSize={6} value={props.invoiceNumber} testid="sidebar-invoice-number" />
              </FormGroup>
              <FormGroup>
                <LabeledDisplay horizontalLabel={false} textarea label="Notes" inputColSize={12} value={props.notes} testid="sidebar-notes" />
              </FormGroup>
              {receivingStatus}
            </Row>
          }
        </div>
      </Form>
    </div>
  );
};
