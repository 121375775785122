import gql from 'graphql-tag';

export interface TotalStoresFooterCellQueryResponse {
  getRoutePlanAttachedCustomerOrders?: {
    totalFilteredStoresCount: number;
  };
}

export const TotalStoresFooterCellQuery = gql`
  query TotalStoresFooterCellQuery($filters: GetRoutePlanAttachedCustomerOrdersFilterInput, $routePlanId: Int) {
    getRoutePlanAttachedCustomerOrders: GetRoutePlanAttachedCustomerOrders(filters: $filters, routePlanId: $routePlanId) {
      totalFilteredStoresCount
      routePlanId
    }
  }
`;
