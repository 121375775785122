import { isNil, isEmpty } from 'lodash';
import { propToComponent, wrapComponent } from 'client/hoc/hoc';
import { BolForm } from 'client/app/transportation/receiving/details/worksheet/bol-form/bol-form';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { ShippingUnitType, OrderMethod, ReceivableOrderType } from 'shared/types';
import { SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { cartTrackingEnabledForOrder } from 'shared/helpers/cart-tracking-enabled';
import { BolAutoCartTrackingEnabled } from 'shared/schemas/bol';

interface OwnProps {
  receivableOrderId: number;
  autoCartTrackingEnabled?: BolAutoCartTrackingEnabled;
}

interface QueryProps {
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  cartTrackingStatus: SupplierCartTrackingStatus;
}

export interface ReceivableOrderInfoResponse {
  receivableOrder: {
    id: number;
    identifier: string;
    shippingUnitType: ShippingUnitType;
    orderMethod: OrderMethod;
    receivableOrderType: ReceivableOrderType;
    supplier: {
      id: SupplierId;
      cartTrackingStatus: SupplierCartTrackingStatus;
    };
  };
}

export const ReceivableOrderInfoQuery = gql`
  query receivableOrderInfoForReceivingWorksheet($receivableOrderId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      identifier
      shippingUnitType
      orderMethod
      receivableOrderType
      supplier {
        id
        cartTrackingStatus
      }
    }
  }
`;

const WithReceivableOrderInfo = msyncQuery<ReceivableOrderInfoResponse, OwnProps, {}>(ReceivableOrderInfoQuery, {
  skip(ownProps) {
    return isNil(ownProps.receivableOrderId);
  },
  options(ownProps: OwnProps) {
    return {
      variables: {
        receivableOrderId: ownProps.receivableOrderId,
      },
    };
  },
  props({ data, ownProps }) {
    if (data.loading || !data.receivableOrder || isEmpty(data)) {
      return { };
    }
    return {
      shippingUnitType: data.receivableOrder.shippingUnitType,
      orderMethod: data.receivableOrder.orderMethod,
      cartTrackingStatus: data.receivableOrder.supplier ? data.receivableOrder.supplier.cartTrackingStatus : null,
    };
  },
});

type WithFeatureFlagsProps = { showRacksReturned: boolean };
const WithFeatureFlags = WrappedComponent => (props: OwnProps & QueryProps) => {
  // We show racks returned for a valid order (rack based order, tracked supplier)
  // AND is
  // creating a new bol (when autoCartTrackingEnabled is undefined)
  // OR
  // a bol with data integrity (when autoCartTrackingEnabled is true)
  const hideRacksReturned = props.autoCartTrackingEnabled === false || !cartTrackingEnabledForOrder({ orderMethod: props.orderMethod, shippingUnitType: props.shippingUnitType, supplierCartTrackingStatus: props.cartTrackingStatus });

  const updatedProps = {
    ...props,
    showRacksReturned: !hideRacksReturned,
  };
  return propToComponent(WrappedComponent, updatedProps);
};

type CombinedProps =
  OwnProps &
  WithFeatureFlagsProps &
  QueryProps;

export default wrapComponent(BolForm)<OwnProps, CombinedProps>(
  WithReceivableOrderInfo,
  WithFeatureFlags,
);
