import * as LensLib from '@atomic-object/lenses';
import * as MutationActions from 'client/actions/mutations';

interface ReportUserParamsState {
  actionStatus: MutationActions.MutationStatus;
  selectedPresetId: number | undefined;
  saveAsPresetModalShown: boolean;
  allPresetOptions: Array<{
    id: number;
    value: string;
    userId: number | null;
    userDefault: boolean;
    reportDefault: boolean;
  }>;
  defaultPresetId: number | undefined;
}

export const InitialState: ReportUserParamsState = {
  actionStatus: MutationActions.MutationStatus.Initial,
  selectedPresetId: undefined,
  saveAsPresetModalShown: false,
  allPresetOptions: [],
  defaultPresetId: undefined,
};

export type Type = ReportUserParamsState;

export const actionStatusLens = LensLib.Lens.from<Type>().prop('actionStatus');
export const saveAsPresetModalShownLens = LensLib.Lens.from<Type>().prop('saveAsPresetModalShown');
export const allPresetOptionsLens = LensLib.Lens.from<Type>().prop('allPresetOptions');
export const defaultPresetIdLens = LensLib.Lens.from<Type>().prop('defaultPresetId');
const selectedPresetIdLens = LensLib.Lens.from<Type>().prop('selectedPresetId');

export const setSelectedPresetId = (state: Type, presetId: number) => {
  return selectedPresetIdLens.set(state, presetId);
};

export const clearSelectedPresetId = (state: Type) => {
  return selectedPresetIdLens.set(state, undefined);
};

export const selectedPresetId = (state: Type) => {
  return selectedPresetIdLens.get(state);
};
