import { flowRight } from 'lodash';
import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { submit, isInvalid, isPristine } from 'redux-form';
import { push } from 'connected-react-router';
import { msyncQuery } from 'client/hoc/graphql/query';
import { propToComponent } from 'client/hoc/hoc';
import { RoutePlanId, RoutePlanIdentifier, RoutePlanStatus, RoutePlanLastModified } from 'schema/route-plan/route-plan-typescript-types';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import Dropdown from 'client/components/dropdown';
import { routePlanFormName } from '../helpers';

const statuses = Object.keys(RoutePlanStatus).map(k => RoutePlanStatus[k]); // DVM 2020 06 17 -- isn't this a noop?
type RoutePlanData = {
  routePlanLastModified?: RoutePlanLastModified;
  routePlanIdentifier?: RoutePlanIdentifier;
  routePlanPeakNumbers?: string;
  routePlanStatus?: RoutePlanStatus;
};

const RoutePlanDetailsRecordBarUI = (p: RoutePlanData & {
  onBackButtonClicked(): void;
  onSaveButtonClicked(formName: string): void;
  saveButtonDisabled: boolean;
  formName: string;
}) => (
  <RecordBar
    updatedAt={p.routePlanLastModified}
    onBackButtonClicked={p.onBackButtonClicked}
    status={(
      <div className="detail">
        <div className="heading"></div>
        <Dropdown
          className="mfc-dropdown btn-group-record-bar-button"
          data={statuses}
          value={[p.routePlanStatus]}
          placeholder={p.routePlanStatus || 'Draft'}
          updateLabel
          block
          disabled={true}
        />
      </div>
    )}
    onSaveButtonClicked={() => p.onSaveButtonClicked(p.formName)}
    saveButtonDisabled={p.saveButtonDisabled}>
    <RecordBarDetail title="Route Plan ID" testid="record-bar-route-plan-identifier">
      {p.routePlanIdentifier}
    </RecordBarDetail>
    <RecordBarDetail title="Peak #s" testid="record-bar-route-plan-peak-numbers">
      {p.routePlanPeakNumbers}
    </RecordBarDetail>
  </RecordBar>
);

interface RoutePlanRecordBarQueryResponse {
  routePlanResult?: {
    routePlan: {
      id: RoutePlanId;
      identifier: RoutePlanIdentifier;
      lastModified: RoutePlanLastModified;
      status: RoutePlanStatus;
      loadCount: number;
      peakOrderNumberCount: number;
    };
  };
}

const RoutePlanRecordBarQuery = gql`
  query routePlanRecordBarQuery($id: Int!) {
    routePlanResult: GetRoutePlan(id: $id) {
      routePlan {
        id
        identifier
        lastModified
        status
        loadCount
        peakOrderNumberCount
      }
    }
  }
`;

interface OwnProps { routePlanId?: RoutePlanId; sectionName: string; }
interface MutationProps { updateRoutePlanStatus: (args: { routePlanId: RoutePlanId, status: string }) => shame; }
const WithRoutePlan = msyncQuery<RoutePlanRecordBarQueryResponse, OwnProps, RoutePlanData, {id: number | undefined}>(RoutePlanRecordBarQuery, {
  alias: 'withRoutePlanRecordBar',
  skip: (ownProps: OwnProps) => ownProps.routePlanId === undefined,
  options: ownProps => ({ variables: { id: ownProps.routePlanId } }),
  props: ({ data }) =>
      data.loading                      ? { routePlanIdentifier: '' }
    : data.routePlanResult === undefined ? {}
    : {
      routePlanIdentifier: data.routePlanResult.routePlan.identifier,
      routePlanPeakNumbers: `${data.routePlanResult.routePlan.peakOrderNumberCount}/${data.routePlanResult.routePlan.loadCount}`,
      routePlanLastModified: data.routePlanResult.routePlan.lastModified,
      routePlanStatus: data.routePlanResult.routePlan.status,
    },
});

interface StateProps { saveButtonDisabled: boolean; formName: string; }
const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  const formName = routePlanFormName(ownProps.sectionName);
  return { formName, saveButtonDisabled: isPristine(formName)(state) || isInvalid(formName)(state) };
};

interface DispatchProps { onBackButtonClicked(): void; onSaveButtonClicked(formName: string): void; }
const mapDispatchToProps = (dispatch): DispatchProps => ({
  onBackButtonClicked: () => dispatch(push(`/transportation/routing/list`)),
  onSaveButtonClicked: (formName: string) => dispatch(submit(formName)),
});

const WithRoutePlanIdentifer = WrappedComponent => (props: { routePlanIdentifier?: RoutePlanIdentifier, routePlanLastModified?: RoutePlanLastModified }) =>
  propToComponent(WrappedComponent, { ...props, routePlanIdentifier: props.routePlanIdentifier === undefined ? 'New Route Plan' : props.routePlanIdentifier });

export type ComponentProps = OwnProps & MutationProps;
const component = flowRight(
  WithRoutePlan,
  WithRoutePlanIdentifer,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(RoutePlanDetailsRecordBarUI);

export const RoutePlanDetailsRecordBar = (props: OwnProps) => propToComponent(component, props);
