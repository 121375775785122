import * as ImportModal from 'client/components/import-modal';
import * as React from 'react';
import * as ImportModalTypes from 'client/components/import-modal/types';
import { SpreadsheetType } from 'shared/file-parsers/excel/excel-parser-helpers';

export interface ComponentProps {
  isShown: boolean;

  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;

  handleImportStarted: ImportModalTypes.ImportFn;
  handleParsingStarted: ImportModalTypes.ParseFn;
  handleValidationStarted: ImportModalTypes.ValidateFn;
}

export function ImportProductTossModalUI(props: ComponentProps) {
  if (!props.isShown) {
    return <div />;
  }

  return <ImportModal.ImportUI
    id="import-product-toss-modal"
    existingRecordId={undefined}

    modalTitle="Import Product Toss Records"
    expectedSpreadsheetType={SpreadsheetType.ProductToss}

    fileExtension=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    importButtonLabel="Import Product Toss Records"
    closeButtonLabel="Close"
    cancelButtonLabel="Cancel"

    handleCloseButtonClicked={props.handleCloseButtonClicked}
    handleCancelButtonClicked={props.handleCancelButtonClicked}

    handleParsingStarted={props.handleParsingStarted}
    handleValidationStarted={props.handleValidationStarted}
    handleImportStarted={props.handleImportStarted}
  />;
}
