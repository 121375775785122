import FormComponent from '../../components/crud-forms/store/details-form-group';
import buildAdminCreatePage from '../admin/admin-create-page';
import navBuilder from './nav-items';
import { RecordBarDetailComponent } from 'client/components/crud-forms/store/store-record-bar-detail';

export default buildAdminCreatePage({
  formName: 'StoreDetailsForm',
  table: 'stores',
  FormComponent,
  navBuilder,
  RecordBarDetailComponent,
});
