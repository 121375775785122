import * as React from 'react';
const onClickOutside: any = require('react-onclickoutside');

import { FormControl } from 'client/components/third-party';
import { includes } from 'lodash';

interface EditableCellProps {
  onChange: (newValue: any, shouldSave?: boolean) => void;
  testid: string;
  error?: string;
  initialValue: string | number;
  inputType: 'number' | 'money' | 'date' | 'text';
  decimal: boolean;
  saveOnChange?: boolean;
  onArrowKeyUp?: () => void;
  onArrowKeyDown?: () => void;
}

class EditableCell extends React.Component<EditableCellProps, any> {
  constructor(props) {
    super(props);
  }

  handleKeyDown = event => {
    if (event.key === 'ArrowUp' && this.props.onArrowKeyUp) {
      event.preventDefault();
      this.props.onArrowKeyUp();
    }
    if (event.key === 'ArrowDown' && this.props.onArrowKeyDown) {
      event.preventDefault();
      this.props.onArrowKeyDown();
    }
  }

  handleKeyPress = event => {
    if (this.props.inputType === 'number') {
      const keyPressed = event.key;
      const blacklist = ['e', 'E', '-', '+'];
      if (!this.props.decimal) {
        blacklist.push('.');
      }
      if (includes(blacklist, keyPressed)) {
        event.preventDefault();
      }
    }
  }

  handleFocus = event => {
    const target = event.target;
    // Select the contents of the input on focus.
    target.select();
  }

  handleChange = event => {
    this.props.onChange(event.target.value, this.props.saveOnChange);
  }

  public handleClickOutside(event) {
    event.preventDefault();
  }

  public render() {
    const { testid, inputType, initialValue } = this.props;
    const baseClassName = inputType === 'number' ? 'money' : '';
    const className = baseClassName;

    return (
      <div className={className}>
        <FormControl
          data-testid={testid}
          type={inputType}
          autoFocus
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          value={initialValue}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  }
}

export default onClickOutside(EditableCell);
