import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import AddNewSupplierItemModal, { FormValues } from './supplier-item-modal';
import * as Actions from 'client/actions/supplier-order';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { OrderMethod, ComboProductGroupResponse, ShippingUnitType } from 'shared/types';
import { withSupplierItems, WithSupplierItemsProps } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import { ApolloRefetch } from 'client/types';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { CreateSupplierOrderProductGroupWithQuantityInput } from 'shared/types';
import { wrapComponent } from 'client/hoc/hoc';
import { ThunkerDispatch } from 'client/types/redux-types';

const formName = 'add-new-product';

export interface OwnProps {
  supplierOrderId: number;
  supplierId: number;
  customerId: number;
  sellDepartmentId: number;
  sellDepartmentIdentifier: string;
  refetchTable: ApolloRefetch;
  isModal?: boolean;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  supplierOrderProducts: any[];
  userConfirmation: () => Promise<boolean | void>;
}

export interface StateProps {
  isProductSelectedList: boolean[];
  isCombo: boolean;
  isShown: boolean;
}

interface WithMutationProps {
  createSupplierOrderProductGroup(payload: CreateSupplierOrderProductGroupWithQuantityInput);
}

type ReduxFormProps = ReduxForm.InjectedFormProps<FormValues>;

export const mapStateToProps = (state: any, ownProps: OwnProps & WithSupplierItemsProps): StateProps => {
  const formValues = ReduxForm.getFormValues(formName)(state) as FormValues;
  const isProductSelectedList: StateProps['isProductSelectedList'] = [];
  formValues.supplierOrderProducts.forEach(product => {
    isProductSelectedList.push(product.supplierItemId !== undefined);
  });
  return {
    isProductSelectedList,
    isCombo: formValues.isCombo,
    isShown: state.supplierOrder.addNewProductModalShown,
  };
};

export const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps & WithComboProductGroupsProps & WithSupplierItemsProps & WithMutationProps & ReduxFormProps & StateProps) => {
  return {

    handleCancelButtonClicked: () => dispatch(Actions.newProductModalCancelButtonClicked(formName)),
    handleSupplierItemChanged: (supplierItemId, supplierItemFieldIdentifier) => {
      const { supplierItems, isCombo, orderMethod, supplierOrderProducts } = props;

      dispatch(Actions.newSupplierItemModalSupplierItemChanged({ formName, supplierItemId, supplierItems, supplierItemFieldIdentifier, isCombo, orderMethod, supplierOrderProducts}));
    },
    handlePacksPerRackCalculationInputChange: (productFieldIdentifier: string, field: 'shelvesPerRack' | 'packsPerShelf', value: number) => {
      return dispatch(Actions.packsPerRackCalculationInputChange(formName, productFieldIdentifier, field, value));
    },
    handleProductCleared: () => {
      dispatch(Actions.newProductModalProductCleared(formName));
    },
    handleFormSubmit: () => {
      return props.handleSubmit(async (formValues: FormValues) => {
        if (false === await props.userConfirmation()) {
          return;
        }

        const payload: CreateSupplierOrderProductGroupWithQuantityInput = {
          supplierOrderId: props.supplierOrderId,
          isCombo: formValues.isCombo,
          packsPerShippingUnit: (formValues.packsPerShippingUnit ? formValues.packsPerShippingUnit : null),
          identifier: formValues.identifier,
          quantity: formValues.quantity,
          description: formValues.description,
          supplierOrderProducts: {
            created: formValues.supplierOrderProducts.map(sop => {
              const supplierItem = props.supplierItems.find(si => si.id === sop.supplierItemId);

              if (!supplierItem) {
                throw new Error(`Could not find supplier item ${sop.supplierItemId}`);
              }
              return {
                id: sop.id,
                supplierItemId: sop.supplierItemId,
                packsPerShelf: sop.packsPerShelf,
                packsPerShippingUnit: sop.packsPerShippingUnit,
                shelvesPerRack: sop.shelvesPerRack,
                packSize: sop.packSize,
                productDescription: supplierItem.product.description,
                packQuantity: formValues.quantity,
              };
            }),
            // updated: [], // TS 3.5 upgrade
            // deleted: [], // TS 3.5 upgrade
          },
        };
        dispatch(Actions.newProductModalSaveClicked({
          formName,
          mutation: props.createSupplierOrderProductGroup,
          payload,
          refetchTable: props.refetchTable,
        }));
      });
    },
  };
};

const comboProductGroupQuery = gql`
  query findComboProductGroupsForSupplierModal($supplierId: Int!, $customerId: Int!, $sellDepartmentId: Int!) {
    comboProductGroups: findComboProductGroupsForSupplier(supplierId: $supplierId, customerId: $customerId, sellDepartmentId: $sellDepartmentId) {
      id
      identifier
      description
      packsPerShippingUnit
      comboProducts {
        packSize
        supplierItemId
      }
    }
  }
`;

interface WithComboProductGroupsProps {
  comboProductGroups: ComboProductGroupResponse[];
}
const withComboProductGroups = msyncQuery<{ comboProductGroups: ComboProductGroupResponse[] }, OwnProps, { comboProductGroups: ComboProductGroupResponse[] }, {}>(comboProductGroupQuery, {
  skip(ownProps) {
    return !ownProps.supplierId || !ownProps.customerId || !ownProps.sellDepartmentId;
  },
  options(ownProps) {
    return {
      variables: {
        supplierId: ownProps.supplierId,
        customerId: ownProps.customerId,
        sellDepartmentId: ownProps.sellDepartmentId,
      },
    };
  },
  props({ data }) {
    return {
      comboProductGroups: data.comboProductGroups || [],
    };
  },
});

export const AddSupplierItemsToOrderViaModalMutation = gql`
  mutation addSupplierItemsToOrderViaModal($input: SupplierOrderAddProductsInput!) {
    data: addSupplierItemsToSupplierOrder(input: $input) {
      id
      supplierOrder {
        id
        lastModifiedAt
      }
    }
  }
`;

const withMutation = msyncMutation(AddSupplierItemsToOrderViaModalMutation, {
  props: ({ mutate }): WithMutationProps => {
    return {
      createSupplierOrderProductGroup: (input: CreateSupplierOrderProductGroupWithQuantityInput) => {
        return mutate({
          variables: { input },
        });
      },
    };
  },
});

interface WithModalProps {
  title: string;
  submitButtonText: string;
  showAddSupplierItemButton: boolean;
  formName: string;
  id: string;
  disableSupplierItemField: boolean;
}

const withModalProps = WrappedComponent => props => {
  if (props.isShown) {
    return (
      <WrappedComponent
        {...props}
        title={'Add Products'}
        submitButtonText={'Add'}
        showAddSupplierItemButton={true}
        formName={formName}
        id={'add-new-product-modal'}
        disableSupplierItemField={false}
      />
    );
  }

  return <div />;
};

const connectorState = connect(mapStateToProps);
const connectorDispatch = connect(undefined, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connectorState> & ConnectedProps<typeof connectorDispatch>;

type CombinedProps =
  OwnProps &
  PropsFromRedux &
  ReduxFormProps &
  WithComboProductGroupsProps &
  WithModalProps &
  WithSupplierItemsProps;

export default wrapComponent(AddNewSupplierItemModal)<OwnProps, CombinedProps>(
  withSupplierItems({ filterExistingSupplierItems: false, activeOnly: true }),
  withMutation,
  withComboProductGroups,
  ReduxForm.reduxForm({
    form: formName,
    initialValues: { supplierOrderProducts: [{}], isCombo: false },
  }),
  connectorState,
  connectorDispatch,
  withModalProps,
);
