import { SupplierItem } from 'shared/schemas/supplier-item';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { Saved } from 'shared/schemas/record';
import gql from 'graphql-tag';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import { ActiveInactive } from 'shared/types';

function mapSupplierItemsToDropdownItems(supplierItem: SupplierItem & { product: { identifier: string, description: string } }): SupplierItemDropDownItem {
  return {
    ...supplierItem,
    label: `${supplierItem.product.identifier} - ${supplierItem.product.description}`,
  };
}

export interface SupplierItemDropDownItem extends SupplierItem {
  label: string;
}

interface WithSupplierItemsInput {
  customerId: number;
  supplierId: number;
}

export interface WithSupplierItemsProps {
  supplierItems: Array<Pick<Saved<SupplierItem>, 'id' | 'product' | 'packSize' | 'packsPerShelf' | 'shelvesPerRack'> & { label: string }>;
}

export const withSupplierItems = (args: { filterExistingSupplierItems: boolean }) => optionsContainerWithFilters<WithSupplierItemsProps>({
  table: 'supplierItems',
  columns: ['product', 'packSize', 'packsPerShelf', 'shelvesPerRack', 'casesPerPallet'],
  skip: (props: WithSupplierItemsInput) => {
    return !props.customerId || !props.supplierId;
  },
  props: (props: { data: { content: Array<Saved<SupplierItem>> }, ownProps: WithSupplierItemsInput }) => {
    const { data: { content } } = props;

    const supplierItems = (content ? content : [])
      .map(si => mapSupplierItemsToDropdownItems(si));

    return {
      supplierItems,
    };
  },
  sort: [{
    sortField: 'product',
    sortOrder: 'ASC',
    foreignColumn: 'identifier',
  }],
  getFilters: (props: WithSupplierItemsInput) => {
    return [
      { field: 'customer', values: [`${props.customerId}`] },
      { field: 'supplier', values: [`${props.supplierId}`] },
      { field: 'activeStatus', values: [ActiveInactive.Active] },
    ];
  },
});

export interface Input {
  invoiceId: number;
}

export interface QueryResponse {
  getInvoice?: {
    invoice: {
      id: InvoiceGraphqlTypes.Invoice['id'];
      customer: {
        id: number;
      };
      supplier: {
        id: number;
      };
    };
  };
}

export const Query = gql`
  query withCustomerAndSupplierForInvoice($invoiceId: Int!) {
    getInvoice: GetInvoice(id: $invoiceId) {
      invoice {
        id
        customer {
          id
        }
        ... on VendorChargebackInvoice {
          supplier {
            id
          }
        }
      }
    }
  }
`;
