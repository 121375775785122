import * as React from 'react';
import { isNil } from 'lodash';
import { LabeledDate } from 'client/components/form';
import { DateStr } from 'shared/types';

export interface SimpleDatePickerProps {
  label?: string;
  testid: string;
  value: string | DateStr | null;
  onChange: (value: shame) => void;
  horizontalLabel?: boolean;
  labelColSize?: number;
  inputColSize?: number;
  disabled?: boolean;
}

export const SimpleDatePicker = (props: SimpleDatePickerProps) => {
  const meta = { touched: false, error: '', warning: '', submitting: false };
  const input = {
    name: `${props.testid}-input`,
    value: props.value,
    onChange: props.onChange,
  };

  return <LabeledDate
    labelColSize={isNil(props.labelColSize) ? 6 : props.labelColSize}
    inputColSize={isNil(props.inputColSize) ? 6 : props.inputColSize}
    horizontalLabel={!!props.horizontalLabel}
    label={props.label || ''}
    testid={props.testid}
    disabled={props.disabled ?? false}
    input={input}
    meta={meta}
    offset={0}
    required
  />;
};
