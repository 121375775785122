import * as React from 'react';
import { FieldArray, getFormValues } from 'redux-form';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../../form';
import SupplierContactFormGroup from './supplier-contact-form-group';
import { connect } from 'react-redux';
import { change } from 'redux-form';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="suppliers" alwaysShowErrors={true} hideOptionalLabel={true} {...p} />;

function mapStateToProps(state, ownProps) {
  const values = getFormValues(ownProps.formName)(state) as shame;
  return {
    displayContacts: values && values.contacts || [],
    website: values && values.website || '',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchedChange: (form, field, value) => dispatch(change(form, field, value)),
  };
}

/*
SupplierForm.propTypes = {
  handleSubmit: React.PropTypes.func.isRequired,
  pristine: React.PropTypes.bool.isRequired,
  invalid: React.PropTypes.bool.isRequired,
  submitting: React.PropTypes.bool.isRequired,
  formName: React.PropTypes.string.isRequired,
  dispatchedChange: React.PropTypes.func,
  displayContacts: React.PropTypes.arrayOf(React.PropTypes.object),
  website: React.PropTypes.string,
};
*/
class SupplierForm extends React.Component<shame> {
  constructor(props) {
    super(props);
  }

  render() {
    const { formName, dispatchedChange, displayContacts, website } = this.props;

    const onPrimaryClick = (fields, index, name) => {
      // NOTE: fields (an object) implements its own map function,
      // providing access to array-like data.
      const fieldNames = fields.map(f => f);
      const [ clickedField, propertyName ] = name.split('.');
      const otherFields = fieldNames.filter(f => f !== clickedField);

      dispatchedChange(formName, `${clickedField}.${propertyName}`, true);
      otherFields.forEach(otherField => {
        dispatchedChange(formName, `${otherField}.${propertyName}`, false);
      });
    };

    const onAddContactClick = fields => {
      return () => {
        if (!displayContacts || (displayContacts.filter(contact => contact.primary === true).length === 0)) {
          dispatchedChange(formName, 'contacts[0].primary', true);
          return fields;
        }
        return fields.push({});
      };
    };

    const onDeleteContactClick = (fields, index, isPrimary) => {
      return () => {
        const removedContact = fields.remove(index);
        if (isPrimary && fields.length > 1) {
          dispatchedChange(formName, 'contacts[0].primary', true);
        }
        return removedContact;
      };
    };

    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div>
            <div className="mfc-form-heading">
              Supplier Info
              <div className="pull-right">
                {website && <span className="mfc-simple-icon-button"><a target="_new" href={website}><span className="fa fa-globe" /></a></span>}
              </div>
            </div>

            <div data-testid="supplier">
              <FormGroup data-testid="info-row-1">
                <Field name="name" labelColSize={2} inputColSize={6}  autoFocus={!this.props.record || !this.props.record.id} />
                <Field name="identifier" labelColSize={1} inputColSize={2} />
              </FormGroup>
              <FormGroup data-testid="info-row-2">
                <Field name="address1" labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid="info-row-3">
                <Field name="address2" labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid="info-row-4">
                <Field name="city" labelColSize={2} inputColSize={4} />
                <Field name="state" labelColSize={1} inputColSize={1} />
                <Field name="zip" labelColSize={1} inputColSize={2} />
              </FormGroup>
              <FormGroup data-testid="info-row-5">
                <Field name="country" labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid="info-row-6">
                <Field name="phone" labelColSize={2} inputColSize={4} />
                <Field name="fax" labelColSize={1} inputColSize={4} />
              </FormGroup>
              <FormGroup data-testid="info-row-7">
                <Field name="website" labelColSize={2} inputColSize={9} />
              </FormGroup>
            </div>

            <FieldArray name="contacts"
                        component={SupplierContactFormGroup as shame}
                        handlePrimaryClick={onPrimaryClick}
                        handleAddContactClick={onAddContactClick}
                        handleDeleteContactClick={onDeleteContactClick}
                        displayContacts={displayContacts}/>
          </div>
        </Form>
      </Col>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierForm);
