import { wrapComponent } from 'client/hoc/hoc';
import { connect, ConnectedProps } from 'react-redux';
import { VendorCreateFormUI } from './ui';
import * as Actions from './actions';

const mapDispatchToProps = {
  onSubmit: Actions.formSubmitted,
};

const connector = connect(undefined, mapDispatchToProps);

type CombinedProps =
  ConnectedProps<typeof connector>;

export const VendorCreateFormContainer = wrapComponent(VendorCreateFormUI)<{}, CombinedProps>(
  connector,
);
