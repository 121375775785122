import { DateStr, ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'hmgOpsReport';
export const reportType = ReportTypes.HmgOps;

export enum FormFields {
  customerIds = 'customerIds',
  supplierId = 'supplierId',
  beginDate = 'beginDate',
  endDate = 'endDate',
  supplierOrderIds = 'supplierOrderIds',
}

export interface FormValues {
  [FormFields.customerIds]: SelectableValue | undefined;
  [FormFields.supplierId]: number | undefined;
  [FormFields.beginDate]: DateStr | undefined;
  [FormFields.endDate]: DateStr | undefined;
  [FormFields.supplierOrderIds]: SelectableValue | undefined;
}

export enum HmgOpsReportType {
  Detail = 'Detail',
  Summary = 'Summary',
  Picas = 'Picas',
}
