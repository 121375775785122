import gql from 'graphql-tag';
import Page from './related-supplier-orders-page';
import { wrapComponent } from 'client/hoc/hoc';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

interface OwnProps {
  params: {
    id: string;
  };
  confirmOkToSave: () => any;
  dataRequest: MsyncDataRequest;
}

interface WithMutationProps {
  performDeletion: (ids: number[]) => Promise<any>;
}

const mutation = gql`
  mutation EditCustomerOrder($input: EditCustomerOrderInput!) {
    result: editCustomerOrder(input: $input) {
      id
    }
  }
`;

const withMutation = msyncMutation<{}, OwnProps, WithMutationProps>(mutation, {
  alias: 'detachSupplierOrdersMutation',
  props: (args: { mutate, ownProps: OwnProps }) => ({
    performDeletion: async supplierOrderIds => {
      const deletionArgs = {
        variables: {
          input: {
            id: args.ownProps.params.id,
            supplierOrders: {
              deleted: supplierOrderIds.map(supplierOrderId => {
                return {
                  supplierOrderId,
                };
              }),
            },
          },
        },
      };

      if (await args.mutate(deletionArgs)) {
        return true;
      } else {
        return false;
      }
    },
  }),
});

export type CombinedProps =
  OwnProps &
  WithMutationProps;

export default wrapComponent(Page)<OwnProps, CombinedProps>(
  withMutation,
);
