import * as FileSaver from 'client/utils/filesaver';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { flowRight } from 'lodash';
import { FormGroup, Form, Row } from 'client/components/third-party';
import { SpecializeField } from 'client/components/form';
import { optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { DeliveryMethods, KnownSellDepartment, OrderMethod, ShippingUnitType, SupplierOrderStatus, DateTimeStr } from 'shared/types';
import { SupplierOrder, SupplierOrderIdentifier, SupplierOrderId } from 'shared/schemas/supplier-order';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import * as Validators from 'shared/validators';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import * as FormHelpers from 'client/helpers/form-helpers';
import { OrderStatus } from 'client/components/order-status';
import { getUserName } from 'shared/schemas/user';
import { send } from 'shared/send';
import * as Actions from 'client/actions/supplier-order';

const Field = SpecializeField(SupplierOrder, { horizontalLabel: false });

interface CustomerOption { id: number; identifier: string; name: string; }
interface SupplierOption { id: number; identifier: string; name: string; }
interface SupplierLocationOption { id: number; identifier: string; }
interface SellDepartmentOption { id: number; identifier: string; }
interface SubSellDepartmentOption { id: number; identifier: string; }
interface MfcAreaOption { id: number; identifier: string; }
interface ShippingUnitOption { id: string; identifier: string; }

export interface OwnProps extends Partial<SupplierOrder> {
  formName: string;
  record: SupplierOrder;
}

interface WithCustomersProps { customers: CustomerOption[]; }
interface WithSupplierProps { suppliers: SupplierOption[]; }
interface WithSupplierLocationProps { supplierLocations: SupplierLocationOption[]; }
interface WithSellDepartmentProps { sellDepartments: SellDepartmentOption[]; }
interface WithSubSellDepartmentsProps { subSellDepartments: SubSellDepartmentOption[]; }
interface WithMfcAreaProps { mfcAreas: MfcAreaOption[]; }

interface FormValues {
  id?: number;
  sellDepartmentId: number | null;
  sellDepartment?: { identifier: string };
  customerId: number | null;
  supplierId: number | null;
  supplierLocationId: number | null;
  invoiced: boolean;
  shippingUnitType: string;
  warehouse: boolean;
  identifier?: string;
}

interface StateProps {
  supplierOrderIdentifier?: SupplierOrderIdentifier;
  supplierOrderId?: number;
  sellDepartmentId: number | null;
  sellDepartmentIdentifier: string | null;
  customerId: number | null;
  supplierId: number | null;
  supplierLocationId: number | null;
  deliveryMethod: DeliveryMethods;
  invoiced: boolean;
  shippingUnitType: string;
  disableMfcArea: boolean;
  plannedArrivalDateLabel: string;
  isOrderSent: boolean;
  lastPurchaseOrderEmailedName?: string;
  lastPurchaseOrderEmailedAt?: DateTimeStr;
}

interface DispatchProps {
  onSellDepartmentChange: (value: string) => void;
  onWarehouseChecked: () => void;
  setSendSupplierPOModalVisibility: (showModal: boolean) => void;
}

const mapStateToProps = (state, ownProps): StateProps => {
  const { formName } = ownProps;
  const formValues: shame = getFormValues(formName)(state) as FormValues;
  const plannedArrivalDateLabel = formValues.deliveryMethod === DeliveryMethods.Pickup ? 'Pickup Date' : 'MFC Arrival Date';

  return {
    supplierOrderIdentifier: formValues.identifier,
    supplierOrderId: formValues.id,
    sellDepartmentIdentifier: formValues.sellDepartment ? formValues.sellDepartment.identifier : null, // this is the initial sell department that was set at the time of page load
    sellDepartmentId: formValues.sellDepartmentId,
    customerId: formValues.customerId,
    supplierId: (formValues.supplier || {}).id || formValues.supplierId,
    supplierLocationId: formValues.supplierLocationId,
    deliveryMethod: (formValues.deliveryMethod),
    invoiced: formValues?.invoiced,
    shippingUnitType: formValues.shippingUnitType,
    disableMfcArea: formValues.warehouse,
    plannedArrivalDateLabel,
    isOrderSent: ownProps.record.orderStatus === SupplierOrderStatus.Sent,
    lastPurchaseOrderEmailedName: ownProps.record.lastPurchaseOrderEmailedInfo && getUserName(ownProps.record.lastPurchaseOrderEmailedInfo),
    lastPurchaseOrderEmailedAt: ownProps.record.lastPurchaseOrderEmailedInfo && ownProps.record.lastPurchaseOrderEmailedInfo.emailedAt,
  };
};

const mapDispatchToProps = (dispatch, ownProps: Props): DispatchProps => {
  const { formName, sellDepartments } = ownProps;

  return {
    onWarehouseChecked: () => {
      dispatch(change(formName, 'mfcAreaId', null));
    },
    onSellDepartmentChange: value => {
      const sellDepartment = sellDepartments.find(s => s.id === Number.parseInt(value));
      if (!sellDepartment)
        return;

      if (sellDepartment.identifier === KnownSellDepartment.IndoorFloral) {
        dispatch(change(formName, 'orderMethod', OrderMethod.Pack));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Pallet));
      } else if (sellDepartment.identifier === KnownSellDepartment.OutdoorGarden) {
        dispatch(change(formName, 'orderMethod', OrderMethod.ShippingUnit));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.Rack));
      } else if (sellDepartment.identifier === KnownSellDepartment.Misc) {
        dispatch(change(formName, 'orderMethod', OrderMethod.Pack));
        dispatch(change(formName, 'shippingUnitType', ShippingUnitType.NotApplicable));
      }

      dispatch(change(formName, 'sellDepartmentId', value));
      dispatch(change(formName, 'subSellDepartmentId', null));
    },
    setSendSupplierPOModalVisibility: (showModal: boolean) => {
      dispatch(Actions.setSendSupplierPOModalVisibility(showModal));
    },
  };
};

type Props = OwnProps &
  StateProps &
  DispatchProps &
  WithCustomersProps &
  WithMfcAreaProps &
  WithSellDepartmentProps &
  WithSubSellDepartmentsProps &
  WithSupplierProps &
  WithSupplierLocationProps;

const downloadPdfAction = async (supplierOrderId: SupplierOrderId, supplierOrderIdentifier: SupplierOrderIdentifier) => {
  const pdfBlob = await send({
    url: `/report/supplierOrder/${supplierOrderId}`,
    method: 'GET',
    asBlob: true,
  });

  const filename = supplierOrderIdentifier || 'Supplier Order';

  FileSaver.saveAs(pdfBlob, `${filename}.pdf`);
};

const SupplierOrderHeaderForm = (p: Props) => {
  const isCreated = !!p.supplierOrderId;

  // When creating a supplier order, you can select N/A as a valid shipping unit.
  // After a supplier order has been saved, we want to prevent the shipping unit to be changed to N/A.
  const filteredShippingUnitTypes = Object
    .keys(ShippingUnitType)
    .map(shippingUnitType => ShippingUnitType[shippingUnitType] as ShippingUnitType)
    .filter(shippingUnitType => !isCreated || shippingUnitType !== ShippingUnitType.NotApplicable || p.shippingUnitType === ShippingUnitType.NotApplicable )
    .map((shippingUnitType): ShippingUnitOption => ({ id: shippingUnitType, identifier: shippingUnitType }));

  return (
    <Form horizontal>
      <HiddenFocusField />
      <div data-testid="supplier-order">
        <Row>
          <FormGroup>
            <Field name="supplier" inputColSize={6} options={p.suppliers} disabled={isCreated} />
            {isCreated && <SimpleMenu label="Actions" menuItems={[
              { label: 'Download PDF', action: async (setInProgress: SetInProgress) => { setInProgress(true); try { await downloadPdfAction(p.supplierOrderId!, p.supplierOrderIdentifier!); } finally { setInProgress(false); } } },
              { label: 'Send Supplier PO', action: () => p.setSendSupplierPOModalVisibility(true) },
            ]} />}
          </FormGroup>
          <FormGroup>
            <Field name="orderType" inputColSize={6} />
            <Field name="warehouse" inputColSize={6} handleChange={React.useCallback((newValue: boolean) => { if (newValue === true) p.onWarehouseChecked(); }, [p.onWarehouseChecked] /* eslint-disable-line react-hooks/exhaustive-deps */)} />
          </FormGroup>
          <FormGroup>
            <Field name="deliveryMethod" labelColSize={0} inputColSize={6} optionValue={DeliveryMethods.Pickup} label="Pick Up" />
            <Field name="deliveryMethod" labelColSize={0} inputColSize={6} optionValue={DeliveryMethods.Deliver} label="Deliver" />
          </FormGroup>
          {((p.supplierLocations || []).length > 0 && p.deliveryMethod === DeliveryMethods.Pickup) && (
          <FormGroup>
            <Field name="supplierLocation" label="Pickup Location" placeholder="Choose or Type" inputColSize={6} options={p.supplierLocations} />
          </FormGroup>
          )}
          <FormGroup>
            <Field name="plannedArrivalDate" label={p.plannedArrivalDateLabel} placeholder="    /    /    " inputColSize={6} />
            <Field name="storeDeliveryDate" label={'Store Deliv. Date'} placeholder="    /    /    " inputColSize={6} required/>
          </FormGroup>
          <FormGroup>
            <Field name="sellDepartment" inputColSize={6} options={p.sellDepartments} placeholder="Select Department" handleChange={p.onSellDepartmentChange} disabled={isCreated} />
            {(p.subSellDepartments || []).length <= 0 ? <div /> : <Field name="subSellDepartment" label="Sub Sell Dept." inputColSize={6} options={p.subSellDepartments} required />}
          </FormGroup>
          <FormGroup>
            <Field name="customer" inputColSize={6} options={p.customers} disabled={isCreated} />
            <Field name="mfcArea" inputColSize={6} options={p.mfcAreas} placeholder="Select MFC Area" autoSetFirstOptionIfPossible={!isCreated} disabled={p.disableMfcArea || !p.mfcAreas || !p.mfcAreas.length}/>
          </FormGroup>
          <FormGroup>
            <Field name="orderMethod" inputColSize={6} placeholder="Select Order Method" disabled={isCreated} />
            <Field name="shippingUnitType" inputColSize={6} placeholder="Select Shipping Unit" options={filteredShippingUnitTypes} textFormatter={(value: ShippingUnitOption) => value.identifier} optionValue={p.shippingUnitType} />
          </FormGroup>
          <FormGroup>
            <Field name="identifier" inputColSize={6} validators={[Validators.CAPITALIZED, Validators.REQUIRED_IF_EDITING]} normalize={FormHelpers.NormalizePoNumbers}/>
            <Field name="invoiceNumber" inputColSize={6} />
          </FormGroup>
          <FormGroup>
            <Field name="notes" inputColSize={12} />
          </FormGroup>
          <FormGroup>
            <OrderStatus input={{ value: p.record.orderStatus }} label={'Order Status'} {...(!p.isOrderSent ? {} : {username: p.lastPurchaseOrderEmailedName, lastUpdatedDate: p.lastPurchaseOrderEmailedAt})}/>
          </FormGroup>
        </Row>
      </div>
    </Form>
  );
};

const mfcAreaFilters = (ownProps: OwnProps) => [
  ...(ownProps.customerId ? [{ field: 'customer', values: [ownProps.customerId]}] : []),
  ...(ownProps.sellDepartmentId ? [{ field: 'sellDepartment', values: [ownProps.sellDepartmentId]}] : []),
];

const subSellDepartmentFilters = (ownProps: OwnProps) => [
  ...(ownProps.sellDepartmentId ? [{ field: 'sellDepartment', values: [ownProps.sellDepartmentId]}] : []),
];

const withSupplierLocations = optionsContainerGenerator({
  table: 'supplierLocations',
  columns: ['identifier'],
  getAdditionalFilters: ownProps => [{field: 'supplierId', values: [ownProps.supplierId]}],
  skip: (ownProps: OwnProps) => !ownProps.supplierId,
});

const withMfcAreaOptions = optionsContainerWithFilters({ table: 'mfcAreas', columns: ['identifier'], getFilters: mfcAreaFilters, skip: (ownProps: OwnProps) => !ownProps.customerId || !ownProps.sellDepartmentId });
const withSupplierOptions = optionsContainerGenerator({ table: 'suppliers', columns: ['identifier', 'name'] });
const withCustomerOptions = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
const withSellDepartmentOptions = optionsContainerGenerator({ table: 'sellDepartments', columns: ['identifier'] });
const withSubSellDepartmentOptions = optionsContainerWithFilters({ table: 'subSellDepartments', columns: ['identifier'], getFilters: subSellDepartmentFilters, skip: (ownProps: OwnProps) => !ownProps.sellDepartmentId });

export default flowRight(
  withSupplierOptions,
  withCustomerOptions,
  withSellDepartmentOptions,
  connect(mapStateToProps, mapDispatchToProps),
  withMfcAreaOptions,
  withSubSellDepartmentOptions,
  withSupplierLocations,
)(SupplierOrderHeaderForm);
