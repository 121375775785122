import FormComponent from 'client/components/crud-forms/product-toss-form';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import { formatDateStrForDisplay } from 'shared/helpers';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminDetailPage({
  formName: 'ProductTossRecordDetailsForm',
  table: 'ProductToss',
  title: 'Toss Record',
  OptionalStatusToggle: EmptyStatusToggle,
  FormComponent,
  descriptionResolver: (record: any) => {
    return `${record.customer.name} - ${record.store.identifier} - ${record.product.identifier} - ${formatDateStrForDisplay(record.weekEndingDate)}`;
  },
});
