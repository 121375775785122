import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { FormGroup, FormControl, Glyphicon } from 'client/components/third-party';
import * as classnames from 'classnames';

class DropdownMenu extends React.Component<shame, shame> {
  constructor(props, context) {
    super(props, context);

    this.state = { value: this.props.inputValue };
  }

  componentWillReceiveProps(nextProps) {
    const { inputValue } = nextProps;
    if (inputValue === '') {
      this.setState({ value: inputValue });
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const { isOpen: previouslyOpen } = prevProps;

    if (isOpen && !previouslyOpen) {
      const input = ReactDOM.findDOMNode((this as shame).input);

      if (input) {
        input.focus();
      }
    }
  }

  render() {
    const { children, filter, headerRef, menuRef, inputRef, inputKeyPressHandler, onInputChangeHandler } = this.props;

    const onChange = event => {
      const newValue = event.target.value;
      this.setState({ value: newValue });
      onInputChangeHandler(newValue);
    };

    const ref = input => {
      (this as shame).input = input;
      inputRef(input);
    };

    const classNames = classnames('dropdown-menu', { 'pull-right': this.props.right });

    return (
      <div className={classNames}>
        <div className="dropdown-menu-content">
          {filter && <div className="mfc-dropdown-menu-filter" ref={headerRef}>
            <FormGroup controlId="formValidationSuccess3">
              <FormControl
                ref={ref}
                type="text"
                onChange={onChange}
                onKeyDown={inputKeyPressHandler}
                value={this.state.value} />
              <FormControl.Feedback>
                <Glyphicon glyph="search" />
              </FormControl.Feedback>
            </FormGroup>
          </div>}

          {(children as shame).length === 0 && <span className="mfc-dropdown-no-results-message">No Results</span>}

          <ul ref={menuRef}>
            {children}
          </ul>
        </div>
      </div>
    );
  }
}
/*
DropdownMenu.propTypes = {
  children: React.PropTypes.node,
  filter: React.PropTypes.bool,
  inputValue: React.PropTypes.string,
  isOpen: React.PropTypes.bool,
  headerRef: React.PropTypes.func,
  menuRef: React.PropTypes.func,
  inputRef: React.PropTypes.func,
  inputKeyPressHandler: React.PropTypes.func,
  onInputChangeHandler: React.PropTypes.func,
  right: React.PropTypes.bool,
};
*/
(DropdownMenu as shame).defaultProps = {
  filter: false,
  isOpen: false,
  right: false,
};

export default DropdownMenu;
