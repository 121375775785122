import * as React from 'react';
import { Cell } from './cell';
import { VariableSizeGrid as Grid } from 'react-window';
import * as Types from './types';
import * as Selectors from './selectors';

export function TableBody(props: Types.TableBodyProps) {
  const { twin, headerRef, footerRef } = props;
  const onScroll = React.useCallback(
    args => {
      if (!args.scrollUpdateWasRequested && twin?.current) {
        twin.current.scrollTo({ scrollLeft: args.scrollLeft });
      }
      if (headerRef.current) {
        headerRef.current.scrollTo({ scrollLeft: args.scrollLeft });
      }
      if (footerRef.current) {
        footerRef.current.scrollTo({ scrollLeft: args.scrollLeft });
      }
    },
    [twin, headerRef, footerRef],
  );

  return (
    <Grid
      ref={props.self}
      className="readonly-table-body"
      rowHeight={Selectors.getRowHeightFunction(props)}
      columnCount={Selectors.getColumns(props).length}
      columnWidth={Selectors.getFreeColumnWidthFunction(props)}
      height={Selectors.getBodyHeight(props)}
      rowCount={Selectors.getRowCount(props)}
      width={Selectors.getBodyWidth(props)}
      itemData={Selectors.getItemDataForFreeCells(props)}
      onScroll={onScroll}
      hasVerticalScrollbar={props.hasVerticalScrollbar}
    >
      {Cell}
    </Grid>
  );
}
