import * as State from './import-customer-order-spreadsheet';

export const radioButtonChosen = (state: State.Type) => state.scanBasedOrPoBased !== undefined;
export const hasBeenValidated = (state: State.Type) => state.customerOrderImportState === State.CustomerOrderImportState.Validated;
export const isImporting = (state: State.Type) => state.customerOrderImportState === State.CustomerOrderImportState.Importing;
export const hasBeenImported = (state: State.Type) => state.customerOrderImportState === State.CustomerOrderImportState.Imported;
export const buttonEnabled = (state: State.Type, customerOrderId: number | undefined) => {
  if (hasBeenValidated(state)) {
    if (radioButtonChosen(state) || customerOrderId) {
      return true;
    }
  }

  if (hasBeenImported(state)) {
    return true;
  }

  return false;
};

export const isShown = (state: State.Type) => state.importCustomerOrderSpreadsheetModalShown;
export const cancelButtonDisabled = (state: State.Type) => state.customerOrderImportState === State.CustomerOrderImportState.Imported;
export const isReadyForImport = (state: State.Type) => state.customerOrderImportState !== State.CustomerOrderImportState.Imported;
