import gql from 'graphql-tag';
import { ReviewStoresCustomerOrderAllocations } from 'schema/customer-order-allocation/customer-order-allocation-types';

export const RoutePlanReviewStoresTableQueryName = 'RoutePlanReviewStoresTableQuery';

export interface GetReviewStoresCustomerOrderAllocationsResponse {
  response: {
    reviewStoresCustomerOrderAllocations: ReviewStoresCustomerOrderAllocations[],
  };
}

export const GetRoutePlanReviewStoresQuery = gql`
  query GetReviewStoresCustomerOrderAllocations($routePlanId: Int!) {
    response: GetReviewStoresCustomerOrderAllocations(routePlanId: $routePlanId) {
      reviewStoresCustomerOrderAllocations {
        routePlanId
        storeId
        storeNumber
        storeAddress
        palletBoxes
        computedPallets
        pallets
        rackBoxes
        computedRacks
        racks
        isOverriddenRacks
        isOverriddenPallets
        customer {
          id
          identifier
          name
        }
      }
    }
  }
`;

export const GetRoutePlanReviewStoresExcelQuery = gql`
  query GetRoutePlanReviewStoresExcelQuery($routePlanId: Int!) {
    response: GetReviewStoresCustomerOrderAllocations(routePlanId: $routePlanId) {
      reviewStoresCustomerOrderAllocations {
        routePlanId
        storeId
        storeNumber
        storeAddress
        palletBoxes
        computedPallets
        pallets
        rackBoxes
        computedRacks
        racks
        isOverriddenRacks
        isOverriddenPallets
        customer: customerIdentifier
      }
    }
  }
`;
