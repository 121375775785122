import { createSelector } from 'reselect';

export class Stat {
  constructor(
    public scope: string, // e.g. Total, Store, Product...  correlates with StatSFilters
    public value?: number,
    public description?: string,
  ) {}

  public static deserialize(stat: Stat, statsOptions: StatsOptions) {
    // This used to do some stuff based on the filters set in the statsOptions.
    // That code was removed. Leaving this infrastructure in place in case
    // there is future need for manipulating the result based on the options.
    return stat;

  }
}
export class Card {
  constructor(
    public name: string, // e.g. Units, Price, Count...
    public stats: Stat[],
    public format?: any,
  ) {}

  public static deserialize(card: Card, statsOptions: StatsOptions): Card {
    const format = statsOptions.formatOptions && statsOptions.formatOptions[card.name];
    const formattedStats = card.stats.map(stat => {
      return Stat.deserialize(stat, statsOptions);
    });
    return new Card(card.name, formattedStats, format);
  }
}

const getFormatOptions = (options?: StatsOptions) => options === undefined || options.formatOptions === undefined ? {} : options.formatOptions;
const emptyStats = createSelector([getFormatOptions], (formatOptions: any) => Object.keys(formatOptions).map(k => new Card(k, [ new Stat('total', undefined)])));

export function EmptyStats(options?: StatsOptions) {
  return emptyStats(options);
}

export interface StatsOptions {
  tableName: string;
  formatOptions?: Dictionary<any>;
  options: Dictionary<any>;
}

export interface Accumulator<T extends Dictionary<number>> {
  accumulate(cumulative: number | undefined, next: T): number;
  readonly name: string;
  readonly projection: (record: T) => number;
}

export class SumAccumulator<T extends Dictionary<number>> implements Accumulator<T> {
  constructor(public readonly name: string, public readonly projection: (record: T) => number) {}
  public accumulate(cumulative: number | undefined, next: T) { return (cumulative || 0) + (this.projection(next) || 0); }
}

export class MinAccumulator<T extends Dictionary<number>> implements Accumulator<T> {
  constructor(public readonly name: string, public readonly projection: (record: T) => number) {}
  public accumulate(cumulative: number | undefined, next: T) {
    if (cumulative === undefined) {
      return this.projection(next);
    }

    const value = this.projection(next);
    return value < cumulative ? value : cumulative;
  }
}

export class MaxAccumulator<T extends Dictionary<number>> implements Accumulator<T> {
  constructor(public readonly name: string, public readonly projection: (record: T) => number) {}
  public accumulate(cumulative: number | undefined, next: T) {
    if (cumulative === undefined) {
      return this.projection(next);
    }

    const value = this.projection(next);
    return value > cumulative ? value : cumulative;
  }
}

export function AccumulateStatistics<T extends Dictionary<number>>(details: T[], specs: Array<Accumulator<T>>): Card[] {
  if (!details || details.length === 0) {
    throw new Error('at least one detail required to calculate any stats.');
  }

  if (!specs || specs.length === 0) {
    throw new Error('at least one spec required to calculate any stats.');
  }

  const accumulators = new Map<string, number | undefined>();
  for (const detail of details) {
    for (const spec of specs) {
      const oldAccumulatedValue = accumulators.get(spec.name) || accumulators.set(spec.name, undefined).get(spec.name);
      const newAccumulatedValue = spec.accumulate(oldAccumulatedValue, detail);
      accumulators.set(spec.name, newAccumulatedValue);
    }
  }

  const cards: Card[] = [];
  specs.forEach(spec => {
    const cardName = spec.name;
    const value = accumulators.get(cardName) || 0;
    const stats: Stat[] = [new Stat('total', parseFloat(value.toFixed(2)))];
    cards.push(new Card(cardName, stats));
  });

  return cards;
}

export function deserializeCardContent(statCards: Card[], statsOptions: StatsOptions) {
  return statCards.map(card => {
    return Card.deserialize(card, statsOptions);
  });
}
