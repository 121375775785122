import { buildAdminListPage } from 'client/containers/admin';
import { SupplierItem } from 'shared/schemas/supplier-item';

export default buildAdminListPage(SupplierItem, {
  columns: ['product', 'supplier', 'customer', 'sellDepartment', 'activeStatus'],
  excelColumns: [
    'product',
    'supplier',
    'customer',
    'sellDepartment',
    'productClass',
    'productSubClass',
    'primaryShippingUnitType',
    'packSize',
    'packsPerShelf',
    'shelvesPerRack',
    'packsPerRack',
    'casesPerPallet',
    'supplierProdIdentifier',
    'fobLocation',
    'notes',
    'scanProduct',
    'activeCost',
    'activeStatus',
  ],
});
