import * as React from 'react';
import { Form, FormGroup, Col, Tabs, Tab, Row } from 'client/components/third-party';
import * as Constants from './constants';
import { SelectableRow } from 'client/components/selectable/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ImmutableDateRange } from 'shared/types/date-range';
import { DateStr, ReportTypes, ReplenishmentByStoreGroupingOptions } from 'shared/types';
import * as Components from '../shared/components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import FormTabs from 'client/components/form/form-tabs';
import { DropDownOptions } from 'client/types';
import { convertEnumToDropdownOptions } from 'client/helpers/enum-helpers';

export interface ComponentProps {
  handleDownloadExcelReportClicked(): void;

  customerOptions: DropDownOptions;
  sellDepartmentOptions: DropDownOptions;
  mfcAreaOptions?: SelectableRow[];
  productClassOptions?: SelectableRow[];
  productSubClassOptions?: SelectableRow[];
  productOptions?: SelectableRow[];
  salesPlanOptions: DropDownOptions;
  regionOptions?: SelectableRow[];
  marketOptions?: SelectableRow[];
  storeOptions?: SelectableRow[];

  downloadButtonDisabled: boolean;

  customersLoading?: boolean;
  sellDepartmentsLoading?: boolean;
  mfcAreasLoading?: boolean;
  productClassesLoading?: boolean;
  productSubClassesLoading?: boolean;
  productsLoading?: boolean;
  salesPlansLoading?: boolean;
  regionsLoading?: boolean;
  marketsLoading?: boolean;
  storesLoading?: boolean;

  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;

  pieceTargetBeginDate: DateStr | undefined;
  pieceTargetEndDate: DateStr | undefined;
  pieceTargetPrimaryStoresOnly: boolean | undefined;

  handleCustomerChanged: (customerId: number) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handlePieceTargetDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handleProductClassesFilterChanged: (ids: number[]) => void;
  handleProductSubClassesFilterChanged: (ids: number[]) => void;
  handleMarketsChanged: (ids: number[]) => void;
  handleMfcAreasChanged: (ids: number[]) => void;
  handleRegionsChanged: (ids: number[]) => void;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;

  includeStoresNotShipped: boolean;
  filterByCategoryOrSalesPlan: Constants.FilterByCategoryOrSalesPlan;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportDownloadStatus: MutationStatus;

  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

const groupingOptions = convertEnumToDropdownOptions(ReplenishmentByStoreGroupingOptions);

export class ReplenishmentByStoreUI extends React.PureComponent<ComponentProps, {}> {

  render() {
    return (
      <div>
        <RecordBar title="Replenishment By Store Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-replenishment-by-store-table-wrapper mfc-scrolling-region-adjacent-to-sidebar">
            <Form horizontal>
              <Row className="report-fields-outside-tabs">
                <Col sm={12}>
                  <FormGroup>
                    <Components.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                      handleChange={this.props.handleCustomerChanged}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Components.DateRange
                      handleChange={this.props.handleDateRangeChanged}
                      beginDate={this.props.beginDate}
                      endDate={this.props.endDate}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Components.SalesPlan
                      name={Constants.FormFields.salesPlanId}
                      options={this.props.salesPlanOptions}
                      label={'Sales Plan For Piece Target'}
                      colSize={4}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Components.DateRange
                      label={'Date Range For Piece Target'}
                      handleChange={this.props.handlePieceTargetDateRangeChanged}
                      beginDate={this.props.pieceTargetBeginDate}
                      endDate={this.props.pieceTargetEndDate}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <Col sm={6}>
                  <FormTabs
                    id="filter-by-category-or-sales-plan"
                    name="filterByCategoryOrSalesPlan"
                  >
                    <Tab
                      eventKey={Constants.FilterByCategoryOrSalesPlan.BySalesPlan}
                      title="By Sales Plan">
                      <Components.SalesPlan
                        name={Constants.FormFields.salesPlanId}
                        options={this.props.salesPlanOptions}
                      />
                    </Tab>
                    <Tab
                      eventKey={Constants.FilterByCategoryOrSalesPlan.ByCategory}
                      title="By Category">
                      <FormGroup>
                        <Components.SellDepartment
                          name={Constants.FormFields.sellDepartmentId}
                          options={this.props.sellDepartmentOptions}
                          handleChange={this.props.handleSellDepartmentChanged}
                        />
                      </FormGroup>
                      <FormGroup className="report-product-order-type-area">
                        <label className="report-scan-or-po-based-label">Product Order Type</label>
                        <div>
                          <Components.ScanBased
                            name={Constants.FormFields.scanBased}
                            handleChange={this.props.handleScanBasedFilterChanged}
                          />
                          <Components.PoBased
                            name={Constants.FormFields.poBased}
                            handleChange={this.props.handlePOBasedFilterChanged}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Components.ProductClass
                          name={Constants.FormFields.productClassIds}
                          options={this.props.productClassOptions}
                          loading={this.props.productClassesLoading}
                          handleChange={this.props.handleProductClassesFilterChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Components.ProductSubClass
                          name={Constants.FormFields.productSubClassIds}
                          options={this.props.productSubClassOptions}
                          loading={this.props.productSubClassesLoading}
                          handleChange={this.props.handleProductSubClassesFilterChanged}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Components.Product
                          name={Constants.FormFields.productIds}
                          options={this.props.productOptions}
                          loading={this.props.productsLoading}
                        />
                      </FormGroup>
                    </Tab>
                    <Tab disabled={true} title="Products" className="reports-product-label" />
                  </FormTabs>
                </Col>
                <Col sm={6}>
                  <Tabs id="stores-tab">
                    <Tab disabled={true} title="Stores">
                    </Tab>
                    <FormGroup>
                      <Components.PrimaryGlobalAllField
                        name={Constants.FormFields.primaryGlobalAll}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Components.MfcArea
                        name={Constants.FormFields.mfcAreaIds}
                        options={this.props.mfcAreaOptions}
                        loading={this.props.mfcAreasLoading}
                        handleChange={this.props.handleMfcAreasChanged}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Components.Region
                        name={Constants.FormFields.regionIds}
                        options={this.props.regionOptions}
                        loading={this.props.regionsLoading}
                        handleChange={this.props.handleRegionsChanged}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Components.Market
                        name={Constants.FormFields.marketIds}
                        options={this.props.marketOptions}
                        loading={this.props.marketsLoading}
                        handleChange={this.props.handleMarketsChanged}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Components.Store
                        name={Constants.FormFields.storeIds}
                        options={this.props.storeOptions}
                        loading={this.props.storesLoading}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Components.IncludeStoresNotShipped
                        name={Constants.FormFields.includeStoresNotShipped}
                      />

                      <Components.PieceTargetPrimaryStoresOnly
                        name={Constants.FormFields.pieceTargetPrimaryStoresOnly}
                      />
                    </FormGroup>
                  </Tabs>
                </Col>
              </div>
            </Form>
          </div>
          <Components.ReplenishmentReportSidebar
            groupingOptions={groupingOptions}
            groupByFieldName={Constants.FormFields.groupBy}
            reportType={ReportTypes.ReplenishmentByStore}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}
