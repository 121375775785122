import 'reflect-metadata';
import { tableName, property, belongsTo, hasMany, definePresentation, required, setSchemaOptions } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, TYPES, OrderMethod, INPUT_TYPES } from 'shared/types';
import { SupplierOrder } from './supplier-order';
import { SupplierOrderProduct } from 'shared/schemas/supplier-order-product';
import { IRecord } from 'shared/schemas/record';
import { ComboProductGroup } from 'shared/schemas/combo-product-group';
import { ComboProductGroupDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { ReceivableOrderDetail } from './receivable-order-detail';

export type SupplierOrderProductGroupId = Flavor<number, 'supplierOrderProductGroupId'>;
export type SupplierOrderProductGroupIdentifier = Flavor<string, 'supplierOrderProductGroupIdentifier'>;

@tableName('supplierOrderProductGroups')
export class SupplierOrderProductGroup implements IRecord {
  static readonly UniqueConstraints: Array<keyof SupplierOrderProductGroup> = ['supplierOrder', 'identifier', 'description'];

  id?: SupplierOrderProductGroupId;

  @belongsTo('supplierOrders', { foreignQueryKeys: ['identifier', 'lastModifiedAt']})
  @property @required supplierOrder: SupplierOrder;
  supplierOrderId: number;

  @hasMany('supplierOrderProducts', { foreignQueryKeys: ['identifier', 'lastModifiedAt', 'productDescription'] })
  @property supplierOrderProducts: SupplierOrderProduct[];

  @belongsTo('comboProductGroups')
  @property comboProductGroup?: ComboProductGroup;
  comboProductGroupId?: number;

  @belongsTo('receivableOrderDetails')
  @property @required receivableOrderDetail: ReceivableOrderDetail;
  receivableOrderDetailId: number;

  @property @required identifier: string;

  /**
   * For VBills syncing, needs to be:
   * component products => combo_product_group.identifier
   * combo racks => anything you want, SOPG.identifier must be unique per order instead
   * full rack / case => product.identifier
   *
   * See SupplierOrderProductGroupRepository.findByUniqueConstraintForVBillsDataImport(Partial<COPG>)
   */
  @property @required description: string;
  @property @required isCombo: boolean;
  @property @required orderMethod: OrderMethod;

  /** Total Packs Per Rack (sum of supplier order product packs per shipping unit)
   * -- or --
   * Cases Per Pallet (denormalized across supplier order product packs per shipping unit)
   */
  @property packsPerShippingUnit?: number | null;

  /** Total number of packs being ordered */
  @property @required packQuantity: number; // Calculated field that's not stored in the DB

  /* Calculation: packQuantity / packsPerShippingUnit */
  @property shippingUnitQuantity?: number | null;

  /** The intended number of shipping units. This number is the quantity entered in for orders with an order method of shipping unit. */
  @property desiredShippingUnitQuantity?: number | null;
}

// Validators moved to shared/validations and server/validations

definePresentation(SupplierOrderProductGroup, {
  supplierOrder: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    filterable: true,
  },
  identifier: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Rack',
  },
  description: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Rack Name',
  },
  isCombo: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
  },
  orderMethod: {
    formDisplay: false,
    includeInFormQuery: true,
  },
  packsPerShippingUnit: {
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  shippingUnitQuantity: {
    displayName: 'Rack Qty',
    tableDisplay: true,
    sortable: true,
    tableEditable: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {},
      calculation: (sopg: SupplierOrderProductGroup) => {
        return `CASE WHEN ${sopg.packsPerShippingUnit} = 0 THEN NULL WHEN ${sopg.packsPerShippingUnit} IS NULL THEN NULL ELSE (${sopg.packQuantity} * 1.0) /  ${sopg.packsPerShippingUnit} END`;
      },
    },
  },
  packQuantity: {
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  comboProductGroup: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ComboProductGroupDisplayValueResolver }),
  },
  desiredShippingUnitQuantity: {
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
});

setSchemaOptions(SupplierOrderProductGroup, {
  uniqueConstraints: SupplierOrderProductGroup.UniqueConstraints,
});
