import * as _ from 'lodash';
import * as Z from 'zod';
import { PerformanceReportStoreDetailLevel, PerformanceReportDateDetailLevel, DateStr, conformingToType, Return } from 'shared/types';
import {
    bindFormulae                , validateDependencies, lastAggregate , runningTotal, fractionAggregate, yearOverYearCompAggregate, ExcelFormats               ,
    fractionPercentageAggregate , sumAggregate        , percentOfTotal, maxAggregate, minAggregate     , averageAggregate         , sellThroughAggregatePercent,
  } from 'shared/excelpers';

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// -------- ( Excel Formulae ) --------

type CategoryDecl = { index: number, categoryName: string, label: string };
const categories = conformingToType<{[K: string]: CategoryDecl}>()({
  qtyShipped    : {index:  0, categoryName: 'qty-shipped'    , label: 'Qty Shipped'                     , confidential: false},
  qtySold       : {index:  1, categoryName: 'qty-sold'       , label: 'Qty Sold'                        , confidential: false},
  sellThrough   : {index:  2, categoryName: 'sell-through'   , label: 'Sell Through'                    , confidential: false},
  cost          : {index:  3, categoryName: 'cost'           , label: 'Cost'                            , confidential: true},
  price         : {index:  4, categoryName: 'price'          , label: 'Price'                           , confidential: false},
  mfcMargin     : {index:  5, categoryName: 'mfc-margin'     , label: 'MFC Margin (Price - Cost)'       , confidential: true},
  retail        : {index:  6, categoryName: 'retail'         , label: 'Retail'                          , confidential: false},
  customerMargin: {index:  7, categoryName: 'customer-margin', label: 'Customer Margin (Retail - Price)', confidential: true},
  scanback      : {index:  8, categoryName: 'scanback'       , label: 'Scan Back'                       , confidential: false},
  toss          : {index:  9, categoryName: 'toss'           , label: 'Toss'                            , confidential: false},
  dates         : {index: 10, categoryName: 'dates'          , label: 'Dates'                           , confidential: false},
  product       : {index: 11, categoryName: 'product'        , label: 'Product'                         , confidential: false},
  store         : {index: 12, categoryName: 'store'          , label: 'Store'                           , confidential: false},
  config        : {index: 13, categoryName: 'config'         , label: 'Report Configuration'            , confidential: false},
} as const);

// DVM 2024 03 21 - use of conformingToType as a debugging tool:
// const assertCategory0 = conformingToType<PerformanceReportFieldCategory>();
// const assertCategory = <Args extends {}>(args: ConformingTo<Args, {
//   category: typeof categories[keyof typeof categories],
//   options: PerformanceReportFieldCategory['options'],
//   measures: PerformanceReportFieldCategory['measures'],
//   confidential?: true,
// }>) => assertCategory0({
//   ...args.category,
//   options: args.options,
//   measures: args.measures,
//   confidential: args.confidential,
// });

const DisabledIf = {
  never                     : undefined,
  always                    : (_args: MeasureDisabledFunctionArguments) => true                                                             ,
  previousYearNotSelected   : ( args: MeasureDisabledFunctionArguments) => !args.compareToPreviousYear                                      ,
  storeDetailLevelIsNotStore: ( args: MeasureDisabledFunctionArguments) =>  args.storeDetailLevel !== PerformanceReportStoreDetailLevel.Store,
  storeDetailLevelIsTotal   : ( args: MeasureDisabledFunctionArguments) =>  args.storeDetailLevel === PerformanceReportStoreDetailLevel.Total,
  dateDetailLevelIsNotDay   : ( args: MeasureDisabledFunctionArguments) =>  args. dateDetailLevel !== PerformanceReportDateDetailLevel.Day    ,
};

const abbreviatedFormats = {
  txt : ExcelFormats.General, // ExcelFormats.Text,
  int : ExcelFormats.Integer,
  amt : ExcelFormats.Money,
  pct : ExcelFormats.PercentWithDecimal,
  dtm : ExcelFormats.General,
  date: ExcelFormats.General,
} as const;

const defineField = <
  I extends number,
  K extends 'option' | 'dimension' | 'measure',
  T extends Z.ZodType<any, any, any>,
  C extends (typeof categories)[keyof typeof categories],
  L extends string,
  D extends undefined | ((args: MeasureDisabledFunctionArguments) => boolean),
  S extends 'sortable' | 'not-sortable',
  W extends Int,
  F extends keyof typeof abbreviatedFormats,
  X extends Return<typeof bindFormulae>,
>                                                         (index: I,      kind: K,                        type: T,               category: C,                               label: L,      disabledIf: D ,     s: S                      , width: W , format: F                                   ,    formulae: X = {} as shame) =>
                                                        ({ index   ,      kind   ,                        type   ,               category   ,                               label   ,      disabledIf    ,     sortable: s === 'sortable', width    , format: abbreviatedFormats[format ?? 'txt'] , ...formulae, header: label} as const);
const fieldDefs = validateDependencies({
  IncludeVendorChargeBacksInCost                   : defineField(0 , 'option'   , Z.optional(Z.boolean()        ), categories.config        , 'Include Vendor Charge Backs In Cost' , DisabledIf.never                      , 'not-sortable',  0 , 'txt' ),
  IncludeScanBackInPrice                           : defineField(1 , 'option'   , Z.optional(Z.boolean()        ), categories.config        , 'Include Scan Back In Price'          , DisabledIf.never                      , 'not-sortable',  0 , 'txt' ),

  sellDate                                         : defineField(0 , 'dimension', Z.optional(Z.custom<DateStr>()), categories.dates         , 'Sell Date'                           , DisabledIf.dateDetailLevelIsNotDay    , 'not-sortable', 14 , 'txt' ),
  weekNumber                                       : defineField(1 , 'dimension', Z.optional(Z.number()         ), categories.dates         , 'Week #'                              , DisabledIf.never                      , 'not-sortable', 14 , 'txt' ),
  year                                             : defineField(2 , 'dimension', Z.optional(Z.number()         ), categories.dates         , 'Year'                                , DisabledIf.never                      , 'not-sortable', 14 , 'txt' ),
  productCustomerIdentifier                        : defineField(0 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'Customer'                            , DisabledIf.never                      , 'not-sortable', 11 , 'txt' ),
  productIdentifier                                : defineField(1 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'Prod'                                , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ),
  replenishmentIdentifier                          : defineField(2 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'Replen ID'                           , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ), //MFC # (Parent)
  productSku                                       : defineField(3 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'Prod Sku'                            , DisabledIf.never                      , 'not-sortable', 15 , 'txt' ),
  productDescription                               : defineField(4 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'Prod Desc'                           , DisabledIf.never                      , 'not-sortable', 30 , 'txt' ),
  salesActivityUpc                                 : defineField(5 , 'dimension', Z.optional(Z.string()         ), categories.product       , 'UPC'                                 , DisabledIf.never                      , 'not-sortable', 20 , 'txt' ),
  storeIdentifier                                  : defineField(0 , 'dimension', Z.optional(Z.string()         ), categories.store         , 'Store'                               , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 14 , 'txt' ),

  qtyShippedTotal                                  : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.qtyShipped    , 'Unit Ship TY'                        , DisabledIf.never                      ,     'sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  qtyShippedTotalPreviousYear                      : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.qtyShipped    , 'Unit Ship LY'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  qtyShippedAmountChangeFromPreviousYear           : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.qtyShipped    , 'Unit Ship Comp'                      , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'int' , bindFormulae(  yearOverYearCompAggregate('qtyShippedTotal'                 , 'qtyShippedTotalPreviousYear'     )                                                   )),
  qtyShippedPercentChangeFromPreviousYear          : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.qtyShipped    , 'Unit Ship Comp %'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('qtyShippedTotal'                 , 'qtyShippedTotalPreviousYear'     )                                                   )),
  qtyShippedRunningTotal                           : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.qtyShipped    , 'Unit Ship (Running Total)'           , DisabledIf.never                      , 'not-sortable', 16 , 'int' , bindFormulae(              lastAggregate                                                                       , runningTotal('qtyShippedTotal'),                  )),
  qtySoldTotal                                     : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.qtySold       , 'Unit Sold TY'                        , DisabledIf.never                      ,     'sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  qtySoldTotalPreviousYear                         : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.qtySold       , 'Unit Sold LY'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  qtySoldAmountChangeFromPreviousYear              : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.qtySold       , 'Unit Sold Comp'                      , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'int' , bindFormulae(  yearOverYearCompAggregate('qtySoldTotal'                    , 'qtySoldTotalPreviousYear'        )                                                   )),
  qtySoldPercentChangeFromPreviousYear             : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.qtySold       , 'Unit Sold Comp %'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('qtySoldTotal'                    , 'qtySoldTotalPreviousYear'        )                                                   )),
  qtySoldRunningTotal                              : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.qtySold       , 'Unit Sold (Running Total)'           , DisabledIf.never                      , 'not-sortable', 16 , 'int' , bindFormulae(              lastAggregate                                                                       , runningTotal('qtySoldTotal'),                     )),
  sellThroughPercentSellThrough                    : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.sellThrough   , 'Sell Thru TY'                        , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(sellThroughAggregatePercent('qtySoldTotal'                    , 'qtyShippedTotal')                                                                    )),
  sellThroughPercentPreviousYear                   : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.sellThrough   , 'Sell Thru LY'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(sellThroughAggregatePercent('qtySoldTotalPreviousYear'        , 'qtyShippedTotalPreviousYear')                                                        )),
  sellThroughPercentChangeFromPreviousYear         : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.sellThrough   , 'Sell Thru Comp'                      , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(  yearOverYearCompAggregate('sellThroughPercentSellThrough'   , 'sellThroughPercentPreviousYear'  )                                                   )),
  costTotal                                        : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost $ TY'                           , DisabledIf.never                      ,     'sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  costTotalPreviousYear                            : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost $ LY'                           , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  costAverage                                      : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost Avg TY'                         , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('costTotal'                       , 'qtyShippedTotal'                 )                                                   )),
  costAveragePreviousYear                          : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost Avg LY'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('costTotalPreviousYear'           , 'qtyShippedTotalPreviousYear'     )                                                   )),
  costCostChangeFromPreviousYear                   : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost $ Comp'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(  yearOverYearCompAggregate('costTotal'                       , 'costTotalPreviousYear'           )                                                   )),
  costPercentChangeFromPreviousYear                : defineField(5 , 'measure'  , Z.nullable(Z.onumber()        ), categories.cost          , 'Cost % Comp'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('costTotal'                       , 'costTotalPreviousYear'           )                                                   )),
  costPercentOfTotalReport                         : defineField(6 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost % of Total'                     , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(               sumAggregate                                                                       , percentOfTotal('costTotal')                       )),
  costRunningTotal                                 : defineField(7 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost $ (Running Total)'              , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(              lastAggregate                                                                       , runningTotal('costTotal')                         )),
  costRegular                                      : defineField(8 , 'measure'  , Z.optional(Z.number()         ), categories.cost          , 'Cost $ Regular'                      , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(               maxAggregate                                                                       ,                                                   )),
  priceTotal                                       : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price $ TY'                          , DisabledIf.never                      ,     'sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  priceTotalPreviousYear                           : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price $ LY'                          , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  priceAverageSold                                 : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price Avg TY'                        , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('priceTotal'                      , 'qtySoldTotal'                    )                                                   )),
  priceAverageSoldPreviousYear                     : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price Avg LY'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('priceTotalPreviousYear'          , 'qtySoldTotalPreviousYear'        )                                                   )),
  priceAverageShipped                              : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price Per Shipped Avg TY'            , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('priceTotal'                      , 'qtyShippedTotal'                 )                                                   )),
  priceAverageShippedPreviousYear                  : defineField(5 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price Per Shipped Avg LY'            , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('priceTotalPreviousYear'          , 'qtyShippedTotalPreviousYear'     )                                                   )),
  pricePriceChangeFromPreviousYear                 : defineField(6 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price $ Comp'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(  yearOverYearCompAggregate('priceTotal'                      , 'priceTotalPreviousYear'          )                                                   )),
  pricePercentChangeFromPreviousYear               : defineField(7 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price % Comp'                        , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('priceTotal'                      , 'priceTotalPreviousYear'          )                                                   )),
  pricePercentOfTotalReport                        : defineField(8 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price % of Total'                    , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(               sumAggregate                                                                       , percentOfTotal('priceTotal'),                     )),
  priceRunningTotal                                : defineField(9 , 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price $ (Running Total)'             , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(              lastAggregate                                                                       , runningTotal('priceTotal'),                       )),
  priceRegular                                     : defineField(10, 'measure'  , Z.optional(Z.number()         ), categories.price         , 'Price $ Regular'                     , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(               minAggregate                                                                       ,                                                   )),
  mfcMarginTotal                                   : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM $ TY'                         , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  mfcMarginTotalPreviousYear                       : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM $ LY'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  mfcMarginAverage                                 : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM $ Avg TY'                     , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('mfcMarginTotal'                  , 'qtyShippedTotal'                 )                                                   )),
  mfcMarginAveragePreviousYear                     : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM $ Avg LY'                     , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('mfcMarginTotalPreviousYear'      , 'qtyShippedTotalPreviousYear'     )                                                   )),
  mfcMarginPercent                                 : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM % TY'                         , DisabledIf.never                      , 'not-sortable', 10 , 'pct' , bindFormulae(          fractionAggregate('mfcMarginTotal'                  , 'priceTotal'                      )                                                   )),
  mfcMarginPercentPreviousYear                     : defineField(5 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM % LY'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(          fractionAggregate('mfcMarginTotalPreviousYear'      , 'priceTotalPreviousYear'          )                                                   )),
  mfcMarginPriceChangeFromPreviousYear             : defineField(6 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM $ Comp'                       , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(  yearOverYearCompAggregate('mfcMarginTotal'                  , 'mfcMarginTotalPreviousYear'      )                                                   )),
  mfcMarginPercentChangeFromPreviousYear           : defineField(7 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM % Comp'                       , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('mfcMarginTotal'                  , 'mfcMarginTotalPreviousYear'      )                                                   )),
  mfcMarginPercentOfTotalReport                    : defineField(8 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM % of Total'                   , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(               sumAggregate                                                                       , percentOfTotal('mfcMarginTotal')                  )),
  mfcMarginRunningTotalOfPercentOfTotalReport      : defineField(9 , 'measure'  , Z.optional(Z.number()         ), categories.mfcMargin     , 'MFC GM % of Total (Running)'         , DisabledIf.never                      , 'not-sortable', 10 , 'pct' , bindFormulae(              lastAggregate                                                                       , runningTotal('mfcMarginPercentOfTotalReport')     )),
  retailOnUpc                                      : defineField(9 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ on UPC'                     , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(           averageAggregate                                                                                                                           )),
  retailTotal                                      : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ TY'                         , DisabledIf.never                      ,     'sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  retailTotalPreviousYear                          : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ LY'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  retailAverage                                    : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ Avg TY'                     , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('retailTotal'                     , 'qtySoldTotal'                    )                                                   )),
  retailAveragePreviousYear                        : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ Avg LY'                     , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('retailTotalPreviousYear'         , 'qtySoldTotalPreviousYear'        )                                                   )),
  retailPriceChangeFromPreviousYear                : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ Comp'                       , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(  yearOverYearCompAggregate('retailTotal'                     , 'retailTotalPreviousYear'         )                                                   )),
  retailPercentChangeFromPreviousYear              : defineField(5 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail % Comp'                       , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('retailTotal'                     , 'retailTotalPreviousYear'         )                                                   )),
  retailPercentOfTotalReport                       : defineField(6 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail % of Total'                   , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(               sumAggregate                                                                       , percentOfTotal('retailTotal'),                    )),
  retailRunningTotalOfPercentOfTotalReport         : defineField(7 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail % of Total (Running)'         , DisabledIf.never                      ,    'sortable' , 10 , 'pct' , bindFormulae(              lastAggregate                                                                       , runningTotal('retailPercentOfTotalReport')        )),
  retailRunningTotal                               : defineField(8 , 'measure'  , Z.optional(Z.number()         ), categories.retail        , 'Retail $ (Running Total)'            , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(              lastAggregate                                                                       , runningTotal('retailTotal'),                      )),
  customerMarginTotal                              : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM $ TY'                    , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  customerMarginTotalPreviousYear                  : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM $ LY'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  customerMarginAverage                            : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM $ Avg TY'                , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('customerMarginTotal'             , 'qtySoldTotal'                    )                                                   )),
  customerMarginAveragePreviousYear                : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM $ Avg LY'                , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('customerMarginTotalPreviousYear' , 'qtySoldTotalPreviousYear'        )                                                   )),
  customerMarginPercent                            : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM % TY'                    , DisabledIf.never                      , 'not-sortable', 10 , 'pct' , bindFormulae(          fractionAggregate('customerMarginTotal'             , 'retailTotal'                     )                                                   )),
  customerMarginPercentPreviousYear                : defineField(5 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM % LY'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(          fractionAggregate('customerMarginTotalPreviousYear' , 'retailTotalPreviousYear'         )                                                   )),
  customerMarginPriceChangeFromPreviousYear        : defineField(6 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM $ Comp'                  , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(  yearOverYearCompAggregate('customerMarginTotal'             , 'customerMarginTotalPreviousYear' )                                                   )),
  customerMarginPercentChangeFromPreviousYear      : defineField(7 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM % Comp'                  , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('customerMarginTotal'             , 'customerMarginTotalPreviousYear' )                                                   )),
  customerMarginPercentOfTotalReport               : defineField(8 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM % of Total'              , DisabledIf.never                      ,     'sortable', 10 , 'pct' , bindFormulae(               sumAggregate                                                                       , percentOfTotal('customerMarginTotal')             )),
  customerMarginRunningTotalOfPercentOfTotalReport : defineField(9 , 'measure'  , Z.optional(Z.number()         ), categories.customerMargin, 'Customer GM % of Total (Running)'    , DisabledIf.never                      , 'not-sortable', 10 , 'pct' , bindFormulae(              lastAggregate                                                                       , runningTotal('customerMarginPercentOfTotalReport'))),
  scanbackTotal                                    : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.scanback      , 'Scan Back $ Total TY'                , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  scanbackTotalPreviousYear                        : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.scanback      , 'Scan Back $ Total LY'                , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  scanbackAverage                                  : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.scanback      , 'Scan Back Avg per Unit'              , DisabledIf.never                      , 'not-sortable', 16 , 'amt' , bindFormulae(          fractionAggregate('scanbackTotal'                   , 'qtySoldTotal'                    )                                                   )),
  scanbackCostChangeFromPreviousYear               : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.scanback      , 'Scan Back $ Comp'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'amt' , bindFormulae(               sumAggregate                                                                                                                           )),
  scanbackPercentChangeFromPreviousYear            : defineField(4 , 'measure'  , Z.optional(Z.number()         ), categories.scanback      , 'Scan Back % Comp'                    , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('scanbackTotal'                   , 'scanbackTotalPreviousYear'       )                                                   )),
  tossTotal                                        : defineField(0 , 'measure'  , Z.optional(Z.number()         ), categories.toss          , 'Toss Units TY'                       , DisabledIf.never                      , 'not-sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  tossTotalPreviousYear                            : defineField(1 , 'measure'  , Z.optional(Z.number()         ), categories.toss          , 'Toss Units LY'                       , DisabledIf.never                      , 'not-sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )), // previousYearNotSelected
  tossAmountChangeFromPreviousYear                 : defineField(2 , 'measure'  , Z.optional(Z.number()         ), categories.toss          , 'Toss Unit Comp'                      , DisabledIf.previousYearNotSelected    , 'not-sortable', 16 , 'int' , bindFormulae(               sumAggregate                                                                                                                           )),
  tossPercentChangeFromPreviousYear                : defineField(3 , 'measure'  , Z.optional(Z.number()         ), categories.toss          , 'Toss % Comp'                         , DisabledIf.previousYearNotSelected    , 'not-sortable', 10 , 'pct' , bindFormulae(fractionPercentageAggregate('tossTotal'                       , 'tossTotalPreviousYear'           )                                                   )),
  datesWeekEndingDate                              : defineField(3 , 'measure'  , Z.optional(Z.custom<DateStr>()), categories.dates         , 'Week Ending Date'                    , DisabledIf.dateDetailLevelIsNotDay    , 'not-sortable', 14 , 'txt' ),
  productScanBased                                 : defineField(0 , 'measure'  , Z.optional(Z.string()         ), categories.product       , 'Scan Based'                          , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ),
  productSellDepartment                            : defineField(1 , 'measure'  , Z.optional(Z.string()         ), categories.product       , 'Sell Department'                     , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ),
  productClass                                     : defineField(2 , 'measure'  , Z.optional(Z.string()         ), categories.product       , 'Class'                               , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ),
  productSubClass                                  : defineField(3 , 'measure'  , Z.optional(Z.string()         ), categories.product       , 'Sub Class'                           , DisabledIf.never                      , 'not-sortable', 12 , 'txt' ),
  storeMfcAreaIdentifier                           : defineField(0 , 'measure'  , Z.optional(Z.string()         ), categories.store         , 'MFC Area Identifier'                 , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 14 , 'txt' ),
  storeRegionIdentifier                            : defineField(1 , 'measure'  , Z.optional(Z.string()         ), categories.store         , 'Region Identifier'                   , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 11 , 'txt' ),
  storeMarketIdentifier                            : defineField(2 , 'measure'  , Z.optional(Z.string()         ), categories.store         , 'Market Identifier'                   , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 14 , 'txt' ),
  storeState                                       : defineField(3 , 'measure'  , Z.optional(Z.string()         ), categories.store         , 'State'                               , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 7  , 'txt' ),
  storeMerchandiser                                : defineField(4 , 'measure'  , Z.optional(Z.string()         ), categories.store         , 'Merchandiser'                        , DisabledIf.storeDetailLevelIsNotStore , 'not-sortable', 12 , 'txt' ),
} as const);

export const columnDefinitions = _.pickBy(fieldDefs, fieldDef => fieldDef.kind === 'measure' || fieldDef.kind === 'dimension') as FieldDefsOfKind<'dimension' | 'measure'>;

type FieldDefs = typeof fieldDefs;
type FieldName = keyof FieldDefs;
type FieldDef<K extends FieldName> = FieldDefs[K];
type FieldType<K extends FieldName> = Z.infer<FieldDef<K>['type']>;
type FieldKind<K extends FieldName> = FieldDef<K>['kind'];
type FieldIs<F extends FieldName, K extends FieldKind<FieldName>> = FieldKind<F> extends K ? F : never;
type FieldsOfKind<K extends FieldKind<FieldName>> = { [F in FieldName as FieldIs<F, K>]: FieldType<F> };
type FieldDefsOfKind<K extends FieldKind<FieldName>> = { [F in FieldName as FieldIs<F, K>]: FieldDef<F> };
export type PerformanceReportExcelProductRowInput = FieldsOfKind<'dimension' | 'measure'>;
export type PerformanceReportExcelProductRowHeaders = keyof PerformanceReportExcelProductRowInput;
export interface ColumnWithYear { year: number | undefined, columnName: PerformanceReportExcelProductRowHeaders }
interface MeasureDisabledFunctionArguments {
  compareToPreviousYear: boolean;
  storeDetailLevel: PerformanceReportStoreDetailLevel;
  dateDetailLevel: PerformanceReportDateDetailLevel;
}

/** Used to support old report_user_params.params JSON objects (prior to the refactor on 2024 03 22) -- in the future, audit the blobs in that column to see if any of these legacy aliases still appear, and consider updating them, then delete this map / crutch. */
const legacyMeasureAliases = {
  qtyShippedCumulativeTotal: 'qtyShippedRunningTotal',
  qtySoldCumulativeTotal: 'qtySoldRunningTotal',
  costCumulativeTotal: 'costRunningTotal',
  priceCumulativeTotal: 'priceRunningTotal',
  retailCumulativeTotal: 'retailRunningTotal',
  retailOnUPC: 'retailOnUpc',
  mfcMarginCumulativePercentOfTotalReport: 'mfcMarginRunningTotalOfPercentOfTotalReport',
  customerMarginCumulativePercentOfTotalReport: 'customerMarginRunningTotalOfPercentOfTotalReport',
} as {[k: string]: PerformanceReportExcelProductRowHeaders};

/** Used to support old report_user_params.params JSON objects (prior to the refactor on 2024 03 22) -- in the future, audit the blobs in that column to see if any of these legacy aliases still appear, and consider updating them, then delete this map / crutch. */
export const migrateFieldAliases = (initialValues: SimpleObject) => _.mapKeys(initialValues, (_v, k) => legacyMeasureAliases[k] ?? k);

const performanceReportFieldCategories
  = _(categories)
  .values()
  .orderBy(x => x.index)
  .map(x => ({
    categoryName: x.categoryName,
    confidential: x.confidential,
    label: x.label,
    options: _(fieldDefs)
      .entries()
      .filter(f => f[1].kind === 'option' && f[1].category === x)
      .orderBy(f => f[1].index)
      .map(f => ({ fieldName: f[0] as PerformanceReportExcelProductRowHeaders, label: f[1].label }))
      .value(),
    measures: _(fieldDefs)
      .entries()
      .filter(f => f[1].kind === 'measure' && f[1].category === x)
      .orderBy(f => f[1].index)
      .map(f => ({
        fieldName: f[0] as PerformanceReportExcelProductRowHeaders,
        label: f[1].label,
        sortable: f[1].sortable,
        disabledIf: f[1].disabledIf,
      }))
      .value(),
  }))
  .value();

export const listCategories = () => performanceReportFieldCategories;

export const getPerformanceMeasureFieldNames = (storeDetailLevel: PerformanceReportStoreDetailLevel, dateDetailLevel: PerformanceReportDateDetailLevel, compareToPreviousYear: boolean = true) =>
  _(performanceReportFieldCategories)
  .flatMap(category => category.measures)
  .filter(measure => measure.disabledIf?.({ compareToPreviousYear, storeDetailLevel, dateDetailLevel }) !== true)
  .map(x => x.fieldName)
  .filter(x => !_.isNil(x))
  .value();

export const getPerformanceMeasureConfidentialFieldNames = () =>
  _(performanceReportFieldCategories)
  .filter(category => !!category.confidential)
  .flatMap(category => (category.measures))
  .map(x => x.fieldName)
  .filter(x => !_.isNil(x))
  .value();

export const getPerformanceOptionFieldNames = () =>
  _(performanceReportFieldCategories)
  .flatMap(category => (category.options ?? []).map(x => _.camelCase(`${category.categoryName}-${x.fieldName}`)))
  .filter(x => !_.isNil(x))
  .value();

export enum FormFields {
  id = 'id', // Preset ID
  customerId = 'customerId',
  marketIds = 'marketIds',
  measures = 'measures',
  mfcAreaIds = 'mfcAreaIds',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productIds = 'productIds',
  productSubClassIds = 'productSubClassIds',
  regionIds = 'regionIds',
  scanBased = 'scanBased',
  sellDepartmentId = 'sellDepartmentId',
  sortBy = 'sortBy',
  storeIds = 'storeIds',

  dateRangeYearComparison = 'dateRangeYearComparison',
  dateColumnBehavior = 'dateColumnBehavior',
  storeDetailLevel = 'storeDetailLevel',
  dateDetailLevel = 'dateDetailLevel',
  productGroupingBehavior = 'productGroupingBehavior',
  primaryGlobalAll = 'primaryGlobalAll',
}

export const getEnabledPerformanceMeasureFieldNames = (formValues: SimpleObject) => getPerformanceMeasureFieldNames(
  formValues[FormFields.storeDetailLevel],
  formValues[FormFields.dateDetailLevel],
  formValues[FormFields.dateRangeYearComparison]?.compareToPreviousYear ?? false)
  .filter(k => !!formValues[k]);

export const getEnabledPerformanceOptionFieldNames = (performanceOptionFields: string[], formValues: SimpleObject) => performanceOptionFields.filter(k => !!formValues[k]);
export const getEnabledPerformanceMeasureFieldNameLabels = (formValues: SimpleObject, storeDetailLevel: PerformanceReportStoreDetailLevel, dateDetailLevel: PerformanceReportDateDetailLevel, compareToPreviousYear: boolean = true) =>
  _(performanceReportFieldCategories)
  .flatMap(category => (category.measures).map(x => [category, x] as const))
  .filter(([_category, measure]) => measure.disabledIf?.({ compareToPreviousYear, storeDetailLevel, dateDetailLevel }) !== true)
  .map(([category, measure]) => ({
    fieldName: measure.fieldName,
    label: `${category.label}: ${measure.label}`,
    sortable: measure.sortable,
  }))
  .filter(value => !!formValues[value.fieldName])
  .filter(x => !_.isNil(x))
  .value();
