import * as React from 'react';
import { Modal, Form, Row, FormGroup } from 'client/components/third-party';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledInput } from 'client/components/form';
import { CustomerId } from 'shared/schemas/customer';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { SupplierItemDropDownItem } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import { INPUT_TYPES } from 'shared/types';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';

export interface Props {
  show: boolean;
  formName: string;
  customerId: CustomerId;
  supplierItems: Array<{ id: SupplierItemId, label: string }>;
  onClose: () => void;
  bulkAddSupplierItemsToSupplierOrder: (payload: { supplierItemIds: SupplierItemId[], quantity: number }) => Promise<void>;
  refetchTable: () => void;
  userConfirmation: () => Promise<boolean | void>;
  entryLabel: string;

  // Redux Form
  handleSubmit: (formValues: any) => any;
  reset: () => void;
}

interface FormValues {
  supplierItemIds: SupplierItemId[];
  quantity: number;
}

export function BulkAddProductsModal(props: Props) {
  if (!props.show) {
    return <span />;
  }

  function closeAndReset(): void {
    props.onClose();
    props.reset();
  }

  async function onSubmit(formValues: FormValues): Promise<void> {
    try {
      if (false === await props.userConfirmation()) {
        return;
      }

      await props.bulkAddSupplierItemsToSupplierOrder({
        quantity: formValues.quantity,
        supplierItemIds: formValues.supplierItemIds,
      });
    } catch (err) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem bulk adding supplier items to supplier order', err.message);
      return;
    }
    await props.refetchTable();

    closeAndReset();
  }

  return (
    <Modal
      key="new-detail-modal"
      id="new-detail-modal"
      animation
      backdrop="static"
      show={props.show}
      onHide={closeAndReset}>
      <Form horizontal onSubmit={props.handleSubmit(onSubmit)} data-testid="supplier-order-add-new-product-form">
        <GlobalAsyncModalHeader>Bulk Add Products</GlobalAsyncModalHeader>
        <Modal.Body>
          <Row>
            <FormGroup className="supplier-order-modal-add-new-product-details">
              <Field
                name="supplierItemIds"
                inputColSize={10}
                options={props.supplierItems}
                horizontalLabel={false}
                valueField="id"
                textFormatter={(item: SupplierItemDropDownItem) => item.label}
                label="Products"
                placeholder="Select Products"
                component={LabeledSelect}
                testid="supplier-item"
                multi
                required
                autoFocus
              >
              </Field>
              <Field
                name="quantity"
                required
                component={LabeledInput}
                type={INPUT_TYPES.NUMBER}
                label={props.entryLabel}
                inputColSize={2}
                horizontalLabel={false}
              />
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={closeAndReset} />
          <GlobalSaveButton
            label={'Add'}
            testid={'modal-submit'}
            type="submit"
            disabled={false}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
