import * as React from 'react';
import { Button } from 'client/components/third-party';
import * as classnames from 'classnames';

interface AsyncButtonUIProps {
  label: string;
  onClick?(): void;
  disabled?: boolean;
  classNames?: string[];
  skipBaseClasses?: boolean;
  showSpinner: boolean;
  showCheck: boolean;
  testid: string;
  type?: 'submit' | undefined;
}

const buildButtonContent = (props: AsyncButtonUIProps): JSX.Element | string => {
  if (props.showSpinner) {
    return <span className="fa fa-spinner fa-spin" data-testid={`${props.testid}-spinner`} />;
  } else if (props.showCheck) {
    return <span className="fa fa-check" data-testid={`${props.testid}-check`} />;
  }
  return props.label;
};

const baseClassNames = [
  'mfc-button',
  'mfc-submit-button',
  'mfc-button',
  'mfc-submit-button-primary',
];

const buildClassNames = (props: AsyncButtonUIProps): string | undefined => {
  return props.classNames ? classnames(props.classNames) : undefined;
};

const buildBaseClassNames = (props: AsyncButtonUIProps): string | undefined => {
  return props.skipBaseClasses ? undefined : classnames(baseClassNames);
};

export class AsyncButtonUI extends React.PureComponent<AsyncButtonUIProps, {}> {
  render() {
    const props = this.props;

    return (
      <Button
        bsClass={buildBaseClassNames(props)}
        className={buildClassNames(props)}
        onClick={props.disabled ? undefined : props.onClick}
        disabled={props.disabled}
        data-testid={props.testid}
        type={props.type}
      >
        {buildButtonContent(props)}
      </Button>
    );
  }
}
