import * as _ from 'lodash';
import * as Actions from 'client/app/transportation/distribution-receiving/list/actions';
import * as ClientTypes from 'client/types';
import * as Constants from 'client/constants';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as Navigation from 'client/actions/navigation';
import * as Query from './query';
import * as ReactRedux from 'react-redux';
import * as ConnectedReactRouter from 'connected-react-router';
import * as RecordFilterOptions from 'client/hoc/graphql/record-filter-options';
import * as SharedTypes from 'shared/types';
import * as Table from 'client/app/transportation/distribution-receiving/list/table';
import * as TableFilterContainer from 'client/containers/table/table-filter-container';
import * as TableParent from 'client/components/table/table-parent';
import { propToComponent } from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import { buildTableStateModule } from 'client/state/tables';

const tableName = Constants.TRANSPORTATION_DISTRIBUTION_RECEIVING_SUMMARY_TABLE_NAME;
interface OwnProps {
  tableName: string;
  dataRequest: GraphQLQuery.MsyncDataRequest;
}

interface StateProps {
  activeSortFields: ClientTypes.ActiveSort[];
  activeSearch: ClientTypes.ActiveSearch;
  activeFilters: ClientTypes.ActiveFilter[];
  tablePageNumber: number;
}
const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

// We're using the same scope for the filter options and the table query
// The filter bar requires a function that returns an array, but the table query just requires a list.
// This page shows all MICSable orders (non-scan based indoor floral and external distribution orders)
const scope = () => [
  { field: 'micsEligible', values: [true] },
];

interface WithAvailableFiltersProps {
  availableFilters: ClientTypes.AvailableFilter[];
}
const withFilterOptions = WrappedComponent => {
  const filters = ['vendor', 'customer', 'orderType', 'received', 'micsSent', 'orderDate'];
  return RecordFilterOptions.withRecordFilterOptions({ table: 'customerOrders', fields: filters, scope })(WrappedComponent);
};

interface WithSearchableFieldsProps {
  searchableFields: ClientTypes.AvailableSearchField[];
}
const withSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [{ id: 'identifier', name: 'PO #' }],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface DispatchProps {
  onRowSelect(record): void;
  sendMicsFileToMeijerMenuItemClicked(customerOrderIds: number[]): void;
}

const mapDispatchToProps = (dispatch, ownProps: OwnProps): DispatchProps => {
  return {
    onRowSelect(record) {
      dispatch(Navigation.setRecordBarGoBackToLocation('/transportation/distribution-receiving/list'));
      dispatch(ConnectedReactRouter.push(`/orders/customer/details/${record.id}`));
    },
    sendMicsFileToMeijerMenuItemClicked(customerOrderIds) {
      dispatch(Actions.sendMicsFileToMeijerMenuItemClicked(customerOrderIds));
    },
  };
};

interface WithReceivingRowProps {
  receivingRows: Table.ReceivingRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: GraphQLQuery.MsyncDataRequest;
}

const WithReceivingRows = GraphQLQuery.msyncQuery<Query.DistributionReceivingQueryResponse, OwnProps & StateProps & WithTableParentProps, WithReceivingRowProps>(Query.DistributionReceivingQuery, {
  alias: 'withDistributionReceivingRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps): { variables } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: ownProps.activeSearch,
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        scope: scope(),
      },
    };
  },
  props(props): WithReceivingRowProps {
    const { data } = props;
    const { ownProps } = props as any as { ownProps: StateProps & WithTableParentProps }; // :-/

    const receivingRows = data.response
      ? data.response.records.map(record => {
        return {
          id: record.id,
          identifier: record.identifier,
          customer: record.customer,
          vendorName: record.vendorName,
          orderType: record.orderType,
          orderDate: record.orderDate,
          supplierOrderArrivalDate: record.supplierOrderArrivalDate ? SharedTypes.toDateStr(record.supplierOrderArrivalDate) : null,
          received: record.received,
          micsSentAt: record.micsSentAt,
          lastModifiedAt: record.lastModifiedAt ? SharedTypes.toDateTimeStr(record.lastModifiedAt) : null,
        };
      })
      : [];

    const filters = _.flatten(ownProps.activeFilters.map(filter => filter.values)).join(', ');
    const workbookName = `Receiving${filters ? ` - ${filters}` : ''}`;

    return {
      receivingRows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.response ? data.response.ids : [],
      totalCount: data.response ? data.response.totalCount : 0,
      totalUnfilteredCount: data.response ? data.response.totalUnfilteredCount : 0,
      dataRequest: {
        operationName: 'DistributionReceivingQuery',
        query: Query.DistributionReceivingQuery,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: ownProps.activeSearch,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        workbookName,
      },
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParent.TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithReceivingRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithAvailableFiltersProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<Table.OwnProps, ComponentProps>();

const component = _.flowRight(
  TableParent.tableParentHoc(),
  withSearchableFields,
  withFilterOptions,
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  TableFilterContainer.withFilterAndSortCapabilities(tableName),
  WithReceivingRows,
)(Table.DistributionReceivingTable) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
