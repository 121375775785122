import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight, isNil } from 'lodash';

import { ReceivingOverviewContent, ReceivingOverviewComponentProps } from './content';
import { propToComponent } from 'client/hoc/hoc';
import { TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME } from 'client/constants';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { ReceivingOverviewContentResponse, ReceivingOverviewContentQuery, ReceivableOrderInfoResponse, ReceivableOrderInfoQuery } from './content-query';
import { ActiveSort, ActiveSearch, ActiveFilter } from 'client/types';
import { ShippingUnitType, OrderMethod, ReceivableOrderType } from 'shared/types';
import { buildTableStateModule } from 'client/state/tables';

const tableName = TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME;

interface OwnProps {
  receivableOrderId: number;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

interface WithSearchableFieldsProps {
  searchableFields: ReceivingOverviewComponentProps['searchableFields'];
}

const withFilterOptions = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    availableFilters: [],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

const withSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [{ id: 'productDescription', name: 'Description' }],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface WithSupplierOrderOverviewProps {
  tableRows?: ReceivingOverviewComponentProps['tableRows'];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithSupplierOrderOverviewRows = msyncQuery<ReceivingOverviewContentResponse, OwnProps & StateProps & WithTableParentProps & WithReceivableOrderInfoProps, WithSupplierOrderOverviewProps>(
  ReceivingOverviewContentQuery,
  {
    alias: 'withReceivingRows',
    skip(ownProps) {
      return !ownProps.tableParentInfo.rowsPerPage;
    },
    options(ownProps): { variables } {
      return {
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: ownProps.activeSearch,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
          id: ownProps.receivableOrderId,
        },
      };
    },
    props(props): WithSupplierOrderOverviewProps {
      const { data, ownProps } = props;

      const receivableOrderIdentifier = props.ownProps.identifier || '';

      const tableRows: ReceivingOverviewComponentProps['tableRows'] | undefined = data.response
        ? data.response.records.map(record => {
          return {
            id: record.id,
            productIdentifier: record.productIdentifier,
            productDescription: record.productDescription,
            upc: record.upc,
            rackType: record.rackType,
            retailPrice: record.retailPrice,
            packSize: record.packSize,
            packsPerShelf: record.packsPerShelf,
            shelvesPerRack: record.shelvesPerRack,
            packsPerShippingUnit: record.packsPerShippingUnit,
            shippingUnitQuantity: record.shippingUnitQuantity,
            packQuantity: record.packQuantity,
            shippingUnitQuantityReceivedTotal: record.shippingUnitQuantityReceivedTotal,
            packQuantityReceivedTotal: record.packQuantityReceivedTotal,
          };
        })
        : undefined;
      return {
        tableRows,
        loading: _.isNil(data.loading) ? true : data.loading,
        filteredRecordIds: data.response ? data.response.ids : [],
        totalCount: data.response ? data.response.totalCount : 0,
        totalUnfilteredCount: data.response ? data.response.totalUnfilteredCount : 0,
        dataRequest: {
          query: ReceivingOverviewContentQuery,
          workbookName: `${receivableOrderIdentifier} - Receiving Order Details`,
          variables: {
            filters: ownProps.activeFilters,
            sort: ownProps.activeSortFields,
            search: ownProps.activeSearch,
            limit: ownProps.tableParentInfo.rowsPerPage,
            offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
            id: ownProps.receivableOrderId,
          },
        },
      };
    },
  });

type WithReceivableOrderInfoProps = {
  shippingUnitType: ShippingUnitType | undefined;
  orderMethod: OrderMethod | undefined;
  identifier: string | undefined;
  receivableOrderType: ReceivableOrderType | undefined;
};

const withReceivableOrderInfo = msyncQuery<{ receivableOrder: ReceivableOrderInfoResponse }, OwnProps & StateProps & WithTableParentProps, WithReceivableOrderInfoProps>(
  ReceivableOrderInfoQuery, {
    alias: 'withReceivableOrderInfo',
    skip(ownProps) {
      return isNil(ownProps.receivableOrderId);
    },
    options(ownProps): { variables } {
      return {
        variables: {
          receivableOrderId: ownProps.receivableOrderId,
        },
      };
    },
    props({ data }) {
      return {
        shippingUnitType: data.receivableOrder ? data.receivableOrder.shippingUnitType : undefined,
        orderMethod: data.receivableOrder ? data.receivableOrder.orderMethod : undefined,
        identifier: data.receivableOrder ? data.receivableOrder.identifier : undefined,
        receivableOrderType: data.receivableOrder ? data.receivableOrder.receivableOrderType : undefined,
      };
    },
  });

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithSupplierOrderOverviewProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  withFilterOptions,
  withSearchableFields,
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  withFilterAndSortCapabilities(tableName),
  withReceivableOrderInfo,
  WithSupplierOrderOverviewRows,
)(ReceivingOverviewContent) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
