import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import Table from './reconciliation-table';
import { propToComponent } from 'client/hoc/hoc';
import { CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME } from 'client/constants';
import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { ActiveSort } from 'client/types';
import { ReconciliationRow } from 'shared/types/customer-order-types';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { buildTableStateModule } from 'client/state/tables';

const tableName = CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME;

interface OwnProps {
  customerOrderId: CustomerOrderId;
}

interface StateProps {
  activeSortFields: ActiveSort[];
}
const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state): StateProps => {
  const tableState = TableStateHelpers.tableStateLens.get(state);
  return {
    activeSortFields: TableStateHelpers.activeSortFields(tableState),
  };
};

interface ReconciliationRowsQueryResponse {
  content?: ReconciliationRow[];
}

const TABLE_QUERY = gql`
  query getCustomerOrderReconciliation($customerOrderId: Int!, $sort: [SortInput!]) {
    content: getCustomerOrderReconciliation(customerOrderId: $customerOrderId, sort: $sort) {
      productIdentifier
      productDescription
      supplierName
      supplierOrderId
      supplierOrderIdentifier
      supplierOrderQuantity
      supplierOrderTotal
      customerOrderTotal
      difference
      relatedCustomerOrderCount
    }
  }
`;

interface WithReconciliationRowProps {
  reconciliationRows?: ReconciliationRow[];
  loading: boolean;
}

const WithReconciliationRows = msyncQuery<ReconciliationRowsQueryResponse, OwnProps & StateProps, WithReconciliationRowProps>(TABLE_QUERY, {
  alias: 'withReconciliationRows',
  skip(ownProps) {
    return false;
  },
  options(ownProps) {
    return {
      variables: {
        customerOrderId: ownProps.customerOrderId,
        sort: ownProps.activeSortFields,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
  props({ data, ownProps }): WithReconciliationRowProps {
    const content = data.content;
    return {
      reconciliationRows: content ? content : undefined,
      loading: data.loading,
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithReconciliationRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  connect<StateProps, any, OwnProps>(mapStateToProps, undefined),
  withFilterAndSortCapabilities(CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME),
  WithReconciliationRows,
)(Table) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
