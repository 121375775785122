import { wrapComponent, propToComponent } from 'client/hoc/hoc';
import { RoutePlanSidebarUi } from './route-plan-sidebar-ui';
import { reduxForm, getFormValues, change } from 'redux-form';
import { optionsContainerWithFilters, optionsContainerGenerator } from 'client/hoc/options-container-generator';
import { ActiveFilter } from 'client/types';
import { connect } from 'react-redux';
import { CustomerId } from 'shared/schemas/customer';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { WithCreateRoutePlanMutation, CreateRoutePlanMutationProps, WithEditRoutePlanMutation, EditRoutePlanMutationProps, WithMarkRoutePlanAsReadytoRouteMutation, MarkRoutePlanAsReadyToRouteMutationProps, WithExtractRoutePlanMutation } from 'client/app/transportation/routing/sidebar/route-plan-mutation';
import { RoutePlanId, RoutePlanDeliveryDate, MarkRoutePlanAsReadyToRouteResponse, EditRoutePlanResponse, CreateRoutePlanResponse } from 'schema/route-plan/route-plan-typescript-types';
import { WithRoutePlan, RoutePlanQueryProps } from 'client/app/transportation/routing/sidebar/route-plan-query';
import { push } from 'connected-react-router';
import * as Actions from 'client/actions/route-plan';
import { modalVisibilityAction } from './email-merchandisers-redux-stuff';
import * as ImportRouteActions from 'client/actions/import-route';
import * as TableActions from 'client/actions/table';
import { MfcAreaId, MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME } from 'client/constants';
import { addDays } from 'shared/helpers/date-helpers';
import { TYPES } from 'shared/types';
import * as _ from 'lodash';
import { withDownloadCsvAction, withExtractAndImportButtonDisabledStates, WithDownloadCsvAction } from 'client/app/transportation/routing/load-actions';
import { ApolloQueryResult } from 'apollo-client';
import { objectDifference } from 'shared/object-difference';
import { routePlanStatusLocked } from 'shared/helpers/route-plan';
import { withSaveConfirmation, SaveConfirmationProps } from 'client/hoc/with-save-confirmation';
import * as MutationActions from 'client/actions/mutations';
import { routePlanFormName } from '../helpers';
import { WithWarnUnsaved } from 'client/hoc/with-warn-unsaved';
import { ThunkerDispatch } from 'client/types/redux-types';

interface OwnProps {
  sectionName: string;
  routePlanId?: number;
}

interface DispatchProps {
  redirectToDetails: (id: RoutePlanId) => void;
  onSellDepartmentChange: (formName: string, value: any) => void;
  onCustomerChange: (formName: string, value: any) => void;
  onAttachOrdersClicked(customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier): void;
  onImportRouteFileButtonClicked: (id: RoutePlanId) => void;
  downloadRoutePlanReceivingForm: (routePlanId?: RoutePlanId) => void;
  downloadDistributionRackShippingWorksheet: (routePlanId: RoutePlanId, setInProgress: (inProgress: boolean) => void) => void;
  downloadStoreSummaryReportActionClicked: (routePlanId: RoutePlanId) => void;
  emailMerchandisersActionClicked: (routePlanId?: RoutePlanId) => void;
  showBusyModal(): void;
  hideBusyModal(): void;
}

const mapDispatchToProps = (dispatch: ThunkerDispatch): DispatchProps => ({
  redirectToDetails: (id: RoutePlanId) => dispatch(push(`/transportation/routing/details/${id}`)),
  onSellDepartmentChange: (formName, value) => {
    dispatch(change(formName, 'sellDepartmentId', value));
    dispatch(change(formName, 'mfcAreaId', null));
  },
  onCustomerChange: (formName, value) => {
    dispatch(change(formName, 'customerId', value));
    dispatch(change(formName, 'mfcAreaId', null));
  },
  onAttachOrdersClicked: (customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier) => {
    dispatch(Actions.showAttachOrdersModal());
    dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'customerId', [`${customerId}`]));
    dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'mfcAreaIdentifier', mfcAreaIdentifier ? [`${mfcAreaIdentifier}`] : []));
    if (routePlanDeliveryDate) {
      dispatch(TableActions.setFilter(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME, 'deliveryDate', [{ startDate: addDays(routePlanDeliveryDate, -1), endDate: addDays(routePlanDeliveryDate, 1) }], TYPES.DATE));
    }
  },
  onImportRouteFileButtonClicked: (id: RoutePlanId) => dispatch(ImportRouteActions.importRouteFileButtonClicked(id)),
  downloadRoutePlanReceivingForm: (routePlanId?: RoutePlanId) => {
    if (routePlanId) {
      dispatch(Actions.downloadRoutePlanReceivingForm(routePlanId));
    }
  },
  downloadDistributionRackShippingWorksheet: (routePlanId: RoutePlanId, setInProgress: (inProgress: boolean) => void) => {
    dispatch(Actions.downloadDistributionRackShippingWorksheet(routePlanId, setInProgress));
  },
  downloadStoreSummaryReportActionClicked: (routePlanId: RoutePlanId) => {
    if (routePlanId) {
      dispatch(Actions.downloadStoreSummaryReportActionClicked(routePlanId));
    }
  },
  emailMerchandisersActionClicked: (routePlanId?: RoutePlanId) => {
    if (routePlanId) {
      dispatch(modalVisibilityAction(true));
    }
  },
  showBusyModal: () => dispatch(MutationActions.showBusyModal()),
  hideBusyModal: () => dispatch(MutationActions.hideBusyModal()),
});

interface WithMutationFunctions {
  onSubmit(formValues): Promise<ApolloQueryResult<CreateRoutePlanResponse | EditRoutePlanResponse> | undefined>;
  onReadyToRouteClicked: () => Promise<ApolloQueryResult<MarkRoutePlanAsReadyToRouteResponse> | undefined>;
}
const withMutationFunctions = WrappedComponent => (props: SaveConfirmationProps & RoutePlanQueryProps & CreateRoutePlanMutationProps & EditRoutePlanMutationProps & MarkRoutePlanAsReadyToRouteMutationProps & OwnProps & DispatchProps) => {
  const updatedProps = {
    ...props,
    onSubmit: async formValues => {
      let id: number | undefined;

      if (props.routePlanId === undefined) {
        const response = await props.createRoutePlan(formValues);
        id = response && response.data && response.data.createRoutePlan ? response.data.createRoutePlan.routePlan.id : undefined;
        if (id) {
          props.redirectToDetails(id);
        }
        return;
      }
      const update = { id: props.routePlanId, ...objectDifference(formValues, props.initialValues)};

      return props.editRoutePlan(update);
    },
    onReadyToRouteClicked: async () => {
      if (props.routePlanId) {
        if (routePlanStatusLocked(props.status)) {
          const confirmed = await props.confirmOkToSave();
          if (!confirmed) {
            return;
          }
        }
        const response = await props.markRoutePlanAsReadyToRoute(props.routePlanId);
        return response;
      }
    },
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface FormValues {
  customerId?: CustomerId;
  mfcAreaId?: MfcAreaId;
  sellDepartmentId?: SellDepartmentId;
  deliveryDate?: RoutePlanDeliveryDate;
}

interface StateProps {
  customerId?: CustomerId;
  sellDepartmentId?: SellDepartmentId;
  mfcAreaId?: MfcAreaId;
  mfcAreaIdentifier?: MfcAreaIdentifier;
  routePlanDeliveryDate?: RoutePlanDeliveryDate;
  attachOrdersButtonDisabled: boolean;
  readyToRouteButtonDisabled: boolean;
  routePlanIdFieldDisabled: boolean;
  routePlanIdFieldRequired: boolean;
  readyToRouteButtonText: string;
}

const mapStateToProps = (state: any, props: FormNameProps & RoutePlanQueryProps & CreateRoutePlanMutationProps & EditRoutePlanMutationProps & OwnProps & WithMutationFunctions): StateProps => {
  const values: FormValues = getFormValues(props.form)(state);
  const statusIsLocked = routePlanStatusLocked(props.status);

  return {
    customerId: values ? values.customerId : undefined,
    routePlanDeliveryDate: values ? values.deliveryDate : undefined,
    mfcAreaId: values ? values.mfcAreaId : undefined,
    sellDepartmentId: values ? values.sellDepartmentId : undefined,
    attachOrdersButtonDisabled: _.isNil(props.routePlanId) || statusIsLocked,
    readyToRouteButtonDisabled: _.isNil(props.readyToRouteButtonDisabled) || props.readyToRouteButtonDisabled,
    routePlanIdFieldDisabled: statusIsLocked,
    routePlanIdFieldRequired: !_.isNil(props.routePlanId), // the ID isn't required if we're creating a new route plan (i.e. routePlanId is null), but we can't delete it after we've created it
    readyToRouteButtonText: statusIsLocked ? 'Clear Routes and Start Over' : 'Ready to Route',
  };
};

interface MfcAreaStateProps {
  mfcAreaIdentifier?: MfcAreaIdentifier;
  mfcAreaFieldRequired: boolean;
  mfcAreaFieldDisabled: boolean;
}

const mapMfcAreaFromStateToProps = (state: any, ownProps: { mfcAreaId?: MfcAreaId, mfcAreaOptions?: Array<{ id: number, identifier: string; }> }): MfcAreaStateProps => {
  const mfcArea = ownProps.mfcAreaId
    ? (ownProps.mfcAreaOptions || []).find(mfcAreaOption => mfcAreaOption.id === ownProps.mfcAreaId)
    : undefined;

  return {
    mfcAreaIdentifier: mfcArea ? mfcArea.identifier : undefined,
    mfcAreaFieldRequired: (ownProps.mfcAreaOptions || []).length !== 0,
    mfcAreaFieldDisabled: (ownProps.mfcAreaOptions || []).length === 0,
  };
};

const withCustomerOptions = optionsContainerGenerator({ resultPropName: 'customerOptions', table: 'customers', columns: ['identifier', 'name'] });
const withSellDepartmentOptions = optionsContainerGenerator({ resultPropName: 'sellDepartmentOptions', table: 'sellDepartments', columns: ['identifier'] });

const mfcAreaFilters = (ownProps: StateProps) => {
  const filters: ActiveFilter[] = [];
  if (ownProps.customerId) {
    filters.push({ field: 'customer', values: [ownProps.customerId]});
  }
  if (ownProps.sellDepartmentId) {
    filters.push({ field: 'sellDepartment', values: [ownProps.sellDepartmentId]});
  }
  return filters;
};

const withMfcAreaOptions = optionsContainerWithFilters({ resultPropName: 'mfcAreaOptions', table: 'mfcAreas', columns: ['identifier'], getFilters: mfcAreaFilters, skip: (ownProps: StateProps) => !ownProps.customerId || !ownProps.sellDepartmentId });

interface ConfirmImportRoutesProps {
  confirmImport: () => Promise<boolean>;
}

type ComponentProps =
  CreateRoutePlanMutationProps &
  EditRoutePlanMutationProps &
  OwnProps &
  StateProps &
  WithMutationFunctions &
  WithDownloadCsvAction &
  SaveConfirmationProps &
  ConfirmImportRoutesProps &
  RoutePlanQueryProps & // WithRoutePlan
  DispatchProps &
  MfcAreaStateProps &
  FormNameProps;

const saveConfirmationOptionsForResetToExtract = {
  onlyAskOnce: false,
  message: {
    title: 'Loads Already Extracted',
    body: 'The loads for these orders have already been extracted. If you reset, you will need to re-extract and re-upload.',
  },
  saveConfirmMessage: 'Continue',
  shouldDisplay: (record?: shame) => {
    // The logic to show this is happening in the onReadyToRouteClicked function
    // If we get to this point, we know we should show it
    return true;
  },
};

interface FormNameProps {
  form: string;
}

const mapFormNameToProps = (state: {}, ownProps: OwnProps): FormNameProps => {
  return {
    form: routePlanFormName(ownProps.sectionName),
  };
};

export const RoutePlanSidebar = wrapComponent(RoutePlanSidebarUi)<OwnProps, ComponentProps>(
  connect(mapFormNameToProps), // Needs to be first (just to make sure any HOC below - especially reduxForm -  that needs the 'form' prop has access to it)
  WithRoutePlan,
  WithCreateRoutePlanMutation,
  WithEditRoutePlanMutation,
  WithMarkRoutePlanAsReadytoRouteMutation,
  WithExtractRoutePlanMutation,
  connect(undefined, mapDispatchToProps),
  withSaveConfirmation(saveConfirmationOptionsForResetToExtract),
  withMutationFunctions,
  reduxForm({
    enableReinitialize: true,
  }),
  withCustomerOptions,
  withSellDepartmentOptions,
  connect(mapStateToProps),
  withMfcAreaOptions,
  connect(mapMfcAreaFromStateToProps),
  withExtractAndImportButtonDisabledStates,
  withDownloadCsvAction,
  WithWarnUnsaved({ recordIdProperty: 'routePlanId' }),
);
