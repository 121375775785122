import * as moment from 'moment-timezone';
import { DateStr, DateTimeStr, toDateTimeStr, toDateStr, DATE_TIME_STR_FORMAT, ImmutableDateRange } from 'shared/types';
import { isNil } from 'lodash';
import { TIME_ZONE } from 'shared/time-zone';
import * as _ from 'lodash';

export function convertDateTimeStrToDateStr(dateTime: DateTimeStr): DateStr {
  return toDateStr(formatDateTime(dateTime, 'YYYY-MM-DD'));
}

export function formatDate(date: DateStr | null | undefined, format: string): string | null | undefined {
  if (isNil(date)) {
    return date;
  }
  return moment.tz(date, TIME_ZONE).format(format);
}

/**
 * Converts a DateStr into a display friendly date with the format MM/DD/YYYY
 */
export function formatDateStrForDisplay(date: DateStr | null | undefined): string | null | undefined {
  return formatDate(date, 'MM/DD/YYYY');
}

/**
 * Converts a DateTimeStr into a display friendly date with the format MM/DD/YYYY
 */
export function formatDateTimeStrForDisplay(date: DateTimeStr | null | undefined): string | null | undefined {
  return formatDateTime(date, 'MM/DD/YYYY [at] hh:mm:ss A zz');
}

export function formatDateTypedCorrectly<T extends DateStr | null | undefined>(date: T, format: string): T extends DateStr ? string : T {
  if (isNil(date)) {
    return date as shame;
  }
  return moment.tz(date, TIME_ZONE).format(format) as shame;
}

/**
 * Formats (in local time) the passed in DateTimeStr using the given format
 */
export function formatDateTime(dateTimeStr: DateTimeStr | null | undefined, format: string): string | null | undefined {
  if (isNil(dateTimeStr)) {
    return dateTimeStr;
  }

  return moment.tz(dateTimeStr, DATE_TIME_STR_FORMAT, TIME_ZONE).format(format);
}

export function convertDateTimeStrToIso8601(dateTimeStr: DateTimeStr | null | undefined): string | null {
  if (isNil(dateTimeStr)) {
    return null;
  }

  return moment.tz(dateTimeStr, DATE_TIME_STR_FORMAT, TIME_ZONE).format();
}

/**
 * Parses a string in the human friendly 'MM/DD/YYYY - hh:mm A' format, converting it into a ET DateTimeStr
 */
export function parseDateTime(str: string, format?: string): DateTimeStr {
  const formatToUse = format ? format : 'MM/DD/YYYY - hh:mm A';
  return toDateTimeStr(moment.tz(str, formatToUse, TIME_ZONE));
}

/**
 * Parses a local date string, converting it into a DateStr
 */
export function parseDate(str: string, format?: string): DateStr {
  if (format) {
    return toDateStr(moment.tz(str, format, TIME_ZONE));
  } else {
    return toDateStr(moment.tz(str, TIME_ZONE));
  }
}

/**
 * Returns true if the first date is after the second date.
 * @param date1
 * @param date2
 */
export function isAfter(date1: DateStr, date2: DateStr) {
  return date1.localeCompare(date2) > 0;
}

export function isSameOrAfter(date1: DateStr, date2: DateStr): boolean {
  return date1 === date2 || isAfter(date1, date2);
}

/**
 * Returns true if the first date is before the second date.
 * @param date1
 * @param date2
 */
export function isBefore(date1: DateStr, date2: DateStr) {
  return date1.localeCompare(date2) < 0;
}

export function isSameOrBefore(date1: DateStr, date2: DateStr): boolean {
  return date1 === date2 || isBefore(date1, date2);
}

/**
 * Returns true if the first time is after the second time.
 */
export function isDateTimeStrAfter(dateTimeStr1: DateTimeStr, dateTimeStr2: DateTimeStr) {
  return moment.tz(dateTimeStr1, TIME_ZONE).isAfter(moment.tz(dateTimeStr2, TIME_ZONE));
}

/**
 * Add the specified number of days to the provided date, getting back
 * a new DateStr. Add a negative number to subtract days.
 */
export function addDays(fromDate: DateStr, days: number): DateStr {
  return toDateStr(moment.tz(fromDate, TIME_ZONE).add(days, 'days'));
}

export function addWeeks(date: DateStr, weeks: number): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).add(weeks, 'weeks'));
}

export function addMonths(date: DateStr, months: number): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).add(months, 'months'));
}

export function addYears(date: DateStr, years: number): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).add(years, 'years'));
}

export function startOfYear(date: DateStr): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).startOf('year'));
}

export function endOfYear(date: DateStr): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).endOf('year'));
}

export function startOfWeek(date: DateStr, isoWeek?: 'isoWeek'): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).startOf(isoWeek || 'week'));
}

export function endOfWeek(date: DateStr): DateStr {
  return toDateStr(moment.tz(date, TIME_ZONE).endOf('week'));
}

export function yearOf(date: DateStr): number {
  return Number.parseInt(date.substring(0, 4));
}

export function addMinutes(fromDateTime: DateTimeStr, minutes: number): DateTimeStr {
  return toDateTimeStr(moment.tz(fromDateTime, TIME_ZONE).add(minutes, 'minutes'));
}

export function addSeconds(fromDateTime: DateTimeStr, seconds: number): DateTimeStr {
  return toDateTimeStr(moment.tz(fromDateTime, TIME_ZONE).add(seconds, 'seconds'));
}

export function getMonthName(date: DateStr): string {
  return moment.tz(date, TIME_ZONE).format('MMMM');
}

/**
 * We're not not totally sure that this is certainly the way to determine growers weeks,
 * since there is no 'growers documentation' etc, just calendars that have the week numbers
 * Unfortunately, we only have calendars for 2015-2018
 * 2015-12-31 is Thursday and has 53 weeks
 * 2016-12-31 is Saturday and has 52 weeks <-- maybe this should have 53 weeks (old check was just weekday > 3), but it could be an outlier because of leap year
 * 2017-12-31 is Sunday and has 52 weeks
 * 2018-12-31 is Monday and has 52 weeks
 */
export function canYearHave53GrowerWeeks(year: number): boolean {
  const date = moment.tz(`${year}-12-31`, TIME_ZONE);
  const weekday = date.weekday();
  return weekday > 3 && weekday < 6;
}

export function dateToWeekNumber(dateStr: DateStr) {
  const date = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  let weekNum = date.isoWeek();

  if (date.weekday() === 0) { // if it's sunday
    const canHave53 = canYearHave53GrowerWeeks(date.year());
    if (canHave53 && weekNum === 52) {
      weekNum = 53;
    } else if (weekNum >= 52) {
      weekNum = 1;
    } else {
      weekNum++;
    }
  }

  return weekNum;
}

export function weekNumberAndYearToDate(weekNum: number, year: number, startOrEndOfWeek: 'start' | 'end'): DateStr {
  const date = moment({
    hour: 12,
    minute: 0,
    seconds: 0,
    year,
  })
    .week(weekNum);

  const theDate = startOrEndOfWeek === 'start'
    ? date.startOf('week')
    : date.endOf('week');

  theDate.hour(12);

  const previousYearHas53Weeks = canYearHave53GrowerWeeks(year - 1);
  if (previousYearHas53Weeks) {
    theDate.add(1, 'week');
  }

  return toDateStr(theDate);
}

// given a date, return the date of the next Monday or Thursday, whichever is closest.
export function getNextMondayOrThursday(dateStr: DateStr) {
  const date = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  // if the weekday is 0 -- Sunday -- or 5 or 6 -- Friday or Saturday -- then the next
  // day is Monday.
  switch (date.weekday()) {
    case 0: return addDays(dateStr, 1);   // Sunday
    case 1: return dateStr;               // Monday
    case 2: return addDays(dateStr, 2);   // Tuesday
    case 3: return addDays(dateStr, 1);   // Wednesday
    case 4: return dateStr;               // Thursday
    case 5: return addDays(dateStr, 3);   // Friday
    case 6: return addDays(dateStr, 2);   // Saturday
  }
  throw new Error('Unexpected error occured while parsing the date');
}

export function getWeekday(dateStr: DateStr) {
  const date = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  return moment.weekdays(date.weekday());
}

export function getFirstDayOfWeek(dateStr: DateStr): DateStr {
  const date = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  return toDateStr(date.startOf('week'));
}

export function getLastDayOfWeek(dateStr: DateStr): DateStr {
  const date = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  return toDateStr(date.endOf('week'));
}

export function sameDayLastYear(dateStr: DateStr): DateStr {
  const dateThisYear = moment.tz(dateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  const dateLastYear = moment(dateThisYear)
    .add(-1, 'year')
    .hour(12);

  const weekDayThisYear = dateThisYear.weekday();
  const weekDayLastYear = dateLastYear.weekday();

  if (weekDayThisYear < weekDayLastYear) {
    dateLastYear.add(1, 'week');
  }

  dateLastYear.weekday(weekDayThisYear);

  return toDateStr(dateLastYear);
}

export function getDaysInBetween(olderDateStr: DateStr, newerDateStr: DateStr): number {
  const newerDate = moment.tz(newerDateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  const olderDate = moment.tz(olderDateStr, 'YYYY-MM-DD', TIME_ZONE).locale('us');
  return newerDate.diff(olderDate, 'days');
}

export function getListOfDatesInBetween(olderDateStr: DateStr, newerDateStr: DateStr): DateStr[] {
  const daysInBetween = getDaysInBetween(olderDateStr, newerDateStr);
  const dates: DateStr[] = _.times(daysInBetween + 1, n => {
    return addDays(olderDateStr, n);
  });
  return dates;
}

export function getDateRangeFromStartAndEndWeeks(year: number | null, startWeek: number | null, endWeek: number | null) {
  if (!year || !startWeek || !endWeek || `${year}`.length !== 4) { // Y10K bug
    return undefined;
  }

  const startDate = weekNumberAndYearToDate(startWeek, year, 'start');
  const endDate = weekNumberAndYearToDate(endWeek, year, 'end');

  if (!startDate || !endDate) {
    return undefined;
  }
  return new ImmutableDateRange({
    startDate,
    endDate,
  });
}

export function roundToNextMinute(time: DateTimeStr): DateTimeStr {
  const seconds = moment.tz(time, TIME_ZONE).seconds();
  if (seconds === 0) {
    return time;
  }

  const oneMinuteLater = addMinutes(time, 1);
  return toDateTimeStr(moment.tz(oneMinuteLater, TIME_ZONE).seconds(0));
}

export enum DateRangeType {
  DayOfWeek = 'DayOfWeek',
  ActualDate = 'ActualDate',
}

export enum PickRangeBy {
  Dates = 'Dates',
  Week = 'Week',
}

export interface DateRangeYearComparisonValue {
  pickRangeBy: PickRangeBy;
  compareToPreviousYear: boolean;

  rangeByDates: {
    dateRangeBeginDate: DateStr | undefined;
    dateRangeEndDate: DateStr | undefined;
    lastYearDateRangeBeginDate: DateStr | undefined;
    lastYearDateRangeEndDate: DateStr | undefined;
    lastYearDateRangeType: DateRangeType;
  };

  rangeByWeek: {
    year: number | null;
    beginWeek: number | null;
    endWeek: number | null;

    lastYear: number | null;
    lastYearBeginWeek: number | null;
    lastYearEndWeek: number | null;
  };
}

export interface RangeByDates {
  dateRangeBeginDate: DateStr | undefined;
  dateRangeEndDate: DateStr | undefined;
  lastYearDateRangeBeginDate: DateStr | undefined;
  lastYearDateRangeEndDate: DateStr | undefined;
  lastYearDateRangeType: DateRangeType;
}

export interface RangeByWeek {
  year: number | null;
  beginWeek: number | null;
  endWeek: number | null;

  lastYear: number | null;
  lastYearBeginWeek: number | null;
  lastYearEndWeek: number | null;
}

export function getDateRangeForDateRangeYearComparison(state: DateRangeYearComparisonValue) {
  return state.pickRangeBy === PickRangeBy.Dates
    ? getDateRangeForRangeByDates(state.compareToPreviousYear, state.rangeByDates, true)
    : state.pickRangeBy === PickRangeBy.Week
      ? getDateRangeForRangeByWeek(state.compareToPreviousYear, state.rangeByWeek)
      : undefined;
}

export function getDateRangeForRangeByDates(compareToPreviousYear: boolean, rangeByDate: RangeByDates, changingLastYearDate: boolean) {
  const startDate = rangeByDate.dateRangeBeginDate;
  const endDate = rangeByDate.dateRangeEndDate;

  const dateRange = (startDate && endDate)
    ? new ImmutableDateRange({ startDate, endDate })
    : undefined;

  let lastYearStartDate: DateStr | undefined;
  let lastYearEndDate: DateStr | undefined;
  if (changingLastYearDate) {
    lastYearStartDate = rangeByDate.lastYearDateRangeBeginDate;
    lastYearEndDate = rangeByDate.lastYearDateRangeEndDate;
  } else if (compareToPreviousYear && startDate && endDate) {
    if (rangeByDate.lastYearDateRangeType === DateRangeType.ActualDate) {
      lastYearStartDate = addYears(startDate, -1);
      lastYearEndDate = addYears(endDate, -1);
    } else if (rangeByDate.lastYearDateRangeType === DateRangeType.DayOfWeek) {
      lastYearStartDate = sameDayLastYear(startDate);
      lastYearEndDate = sameDayLastYear(endDate);
    }
  }

  const lastYearDateRange = (compareToPreviousYear && lastYearStartDate && lastYearEndDate)
    ? new ImmutableDateRange({ startDate: lastYearStartDate, endDate: lastYearEndDate })
    : null;

  return {
    dateRange,
    lastYearDateRange,
  };
}

export function getDateRangeForRangeByWeek(compareToPreviousYear: boolean, rangeByWeek: RangeByWeek) {
  const dateRange = getDateRangeFromStartAndEndWeeks(rangeByWeek.year, rangeByWeek.beginWeek, rangeByWeek.endWeek);
  const lastYearDateRange = compareToPreviousYear
    ? getDateRangeFromStartAndEndWeeks(rangeByWeek.lastYear, rangeByWeek.lastYearBeginWeek, rangeByWeek.lastYearEndWeek)
    : null;

  return {
    dateRange,
    lastYearDateRange,
  };
}

export const EPOCH = toDateStr('1970-01-01');

export function dateStrToDate(date: DateStr): Date {
  return moment.tz(date, TIME_ZONE).toDate();
}
