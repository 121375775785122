import * as React from 'react';
import * as _ from 'lodash';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { BolId } from 'shared/schemas/bol';
import { ActiveSort, ActiveSearch, ActiveFilter } from 'client/types';
import { IColumn } from 'client/components/table/column';
import { sortNaturally } from 'shared/helpers/sort-naturally';

const sortContent = ({ data, sortOption, columns }: { data: any, sortOption: ActiveSort[], columns: IColumn[]}) => {
  if (!data) {
    return undefined;
  }

  const mappedSorts = sortOption.map(option => {
    // This has gotten kind of crazy, but what's going on here is mapping the value that comes
    // back in the sortOption's 'sortField' property to the accessors on the data.
    const sortColumnId = option.sortField;
    const sortColumnForeign = option.foreignColumn;
    const sortColumn = columns.find(col => col.id === sortColumnId || col.id === `${sortColumnId}.${sortColumnForeign}`);
    let sortField = 'rackType';
    if (sortColumn && typeof sortColumn.accessor === 'string') {
      sortField = sortColumn.accessor;
    }
    const sortOrder = option.sortOrder;

    return { sortField, sortOrder };
  });

  return sortNaturally(data, [...mappedSorts, { sortField: 'productId', sortOrder: 'asc' }]);
};

interface OwnProps {
  supplierOrderId: SupplierOrderId;
  requestedBolId?: BolId;

  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];

  columns: IColumn[];

  tableRows: shame;
  loading: boolean;
}

interface SortState {
  sortedContent: shame[] | undefined;
}

export function WithWorksheetTableSorting(WrappedComponent: (props: OwnProps) => React.ReactElement<OwnProps>): any {
  return class Sort extends React.Component<OwnProps, SortState> {
    constructor(props: OwnProps) {
      super(props);

      this.state = {
        sortedContent: sortContent({ data: props.tableRows, sortOption: props.activeSortFields, columns: props.columns }),
      };
    }

    /**
     * This is very specific to how the a worksheet table should be sorted. Specifically, since this
     * is an editable table, need to be careful about the user making a change that causes rows to bounce
     * around while they're making changes. For that reason, content changes are not enough to cause a
     * re-sort. It will only re-sort when something else changes that would warrant updating the sort
     * order (things like changing the sort field, or changing a filter, etc.)
     */
    componentWillReceiveProps(nextProps: OwnProps) {
      const sortNeeded =
        nextProps.activeSortFields !== this.props.activeSortFields ||
        nextProps.activeFilters !== this.props.activeFilters ||
        nextProps.activeSearch !== this.props.activeSearch ||
        nextProps.requestedBolId !== this.props.requestedBolId ||
        nextProps.columns !== this.props.columns ||
        (!nextProps.loading && this.props.loading) ||
        (_.isNil(this.props.tableRows) && !_.isNil(nextProps.tableRows)) ||
        (this.props.tableRows && nextProps.tableRows && this.props.tableRows.length !== nextProps.tableRows.length);

      if (sortNeeded || _.isNil(this.state.sortedContent)) {
        this.setState({
          sortedContent: sortContent({ data: nextProps.tableRows, sortOption: nextProps.activeSortFields, columns: nextProps.columns }),
        });
      } else if (this.props.tableRows !== nextProps.tableRows) {
        // Don't want to change the sort order, but if the content has changed need to re-render
        // while maintaining the previous sort order

        // Turn the new content into a hash keyed by id (for quick look up)
        const lookup = (nextProps.tableRows as Array<{ id: number }>).reduce((memo, row) => {
          memo[row.id] = row;
          return memo;
        }, {});

        const newContent = this.state.sortedContent.map(row => lookup[row.id]);
        this.setState({ sortedContent: newContent });
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          tableRows={this.state.sortedContent}
        />
      );
    }
  };
}
