import * as React from 'react';
import * as classNames from 'classnames';
import EditableCell from './editable-cell';

/* eslint-disable react/no-multi-comp */

interface TextCellTypes {
  editing: boolean;
  value: string | null;
  onChange: (newValue: any) => void;
  testid: string;
  alignRight?: boolean;
}
const TextCell = ({ value, editing, onChange, testid, alignRight }: TextCellTypes) => {
  const cellValue = value || '';
  if (editing) {
    return <EditableCell inputType={'text'} onChange={onChange} testid={testid} initialValue={cellValue} />;
  }

  return <ReadOnlyTextCell value={cellValue} testid={testid} alignRight={alignRight} />;
};

interface ReadOnlyTextCellTypes {
  value: string;
  testid: string;
  alignRight?: boolean;
}
const ReadOnlyTextCell = ({ value, testid, alignRight }: ReadOnlyTextCellTypes) => {
  const className = classNames('ellipsis', {
    'pull-right': alignRight,
  });

  return (
    <div className={className} data-testid={testid}>{React.isValidElement(value) ? value : `${value}`}</div>
  );
};

export default TextCell;
