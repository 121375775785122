import { List, Set, Map } from 'immutable';
import { Lens } from 'client/utils/lenses';
import * as TableState from 'client/state/table';
import * as ReceivingWorksheetState from 'client/state/receiving-worksheet';
import * as ProductWorksheetState from 'client/state/product-worksheet';
import * as SupplierOrderState from 'client/state/supplier-order';
import * as MutationsState from 'client/state/mutations';
import * as RecordState from 'client/state/record';
import * as ImportDistributionRackShippingWorksheetState from 'client/app/transportation/routing/route-plan-details/review-stores/import-distribution-rack-shipping-worksheet-modal/state';
import * as ImportCustomerOrderSpreadsheetState from './import-customer-order-spreadsheet';
import * as ImportRouteState from './import-route';
import * as RoutePlanPrintingState from 'client/state/route-plan-printing';
import * as LoadListState from 'client/state/load-list';
import * as NavigationState from 'client/state/navigation';
import * as DistributionReceivingState from 'client/app/transportation/distribution-receiving/list/state';
import * as RoutePlanState from './route-plan';
// HMMMMMMMMM...... IS THIS FINE........ NO, BUT I CAN FIGURE OUT A BETTER WAY..... (AP 8/15/18.........)
import * as RoutePlanPrintingWarning from 'client/app/transportation/routing/route-plan-details/route-plan-printing-warning/state';
import * as ReportUserParamsState from 'client/components/report-user-params/state';
import * as OrderReportsState from './reports';
import * as TransportationReportsState from './transportation-reports';
import * as EmailMerchandisersModalState from 'client/app/transportation/routing/sidebar/email-merchandisers-redux-stuff';
import * as InvoiceState from './invoice';
import * as SalesPlanState from './sales-plan';
import * as SupplierCommitmentState from './supplier-commitment';
import * as ProductState from 'client/containers/product/state';
import * as CustomerOrderHeaderState from './customer-order-header';

import { MutationStatus } from 'client/actions/mutations';

export interface Session {
  hasError?: boolean;
  isLoading?: boolean;
  sessionStatus?: string;

  user?: {
    firstName: string;
    id: number;
    lastName: string;
    token: string;
  };
}

interface State {
  readonly customerOrderHeader: CustomerOrderHeaderState.Type;
  readonly distributionReceiving: DistributionReceivingState.Type;
  readonly emailMerchandisersModalState: EmailMerchandisersModalState.EmailMerchandisersModalState;
  readonly form: shame; // Redux form
  readonly importCustomerOrderSpreadsheet: ImportCustomerOrderSpreadsheetState.Type;
  readonly importDistributionRackShippingWorksheet: ImportDistributionRackShippingWorksheetState.Type;
  readonly importRoute: ImportRouteState.Type;
  readonly importSalesPlan: SalesPlanState.Type;
  readonly invoice: InvoiceState.InvoiceStateType;
  readonly loadList: LoadListState.Type;
  readonly mutations: MutationsState.Type;
  readonly navigation: NavigationState.Type;
  readonly orderReports: OrderReportsState.Type;
  readonly product: ProductState.Type;
  readonly productWorksheet: ProductWorksheetState.Type;
  readonly receivingWorksheet: ReceivingWorksheetState.Type;
  readonly record: RecordState.Type;
  readonly reportUserParams: ReportUserParamsState.Type;
  readonly routePlan: RoutePlanState.Type;
  readonly routePlanPrinting: RoutePlanPrintingState.Type;
  readonly routePlanPrintingWarning: RoutePlanPrintingWarning.Type;
  readonly salesPlan: SalesPlanState.Type;
  readonly session: Session;
  readonly supplierCommitment: SupplierCommitmentState.Type;
  readonly supplierOrder: SupplierOrderState.Type;
  readonly tables: TableState.TablesState;
  readonly transportationReports: TransportationReportsState.Type;
}

export const InitialState: State = {
  receivingWorksheet: ReceivingWorksheetState.InitialState,
  productWorksheet: ({
    checkedProductIds: Set<number>([]),
    checkedComboCartIds: Set<number>([]),
    addNewProductModalShown: false,
    selectRecentComboModalShown: false,
    selectedRecentCombo: null,
    editProductModalShown: false,
    editComboCartModalShown: false,
    removeProductModalShown: false,
    addProductsFromSalesPlanMutationStatus: MutationStatus.Initial,
    autoReplenishmentModalShown: false,
    storeIdsFromMenuAction: List<number>(),
    autoReplenishmentMutationStatus: MutationStatus.Initial,
    allocatedProductChangesByCustomerOrderProductGroup: Map<number, any>({}),
    queuedAllocationChanges: Map({}),
    inFlightAllocationChanges: Map({}),
    isAllocationDetailsRefetching: false,
    selectedComparableProductId: undefined,
  } as any),
  session: {},
  tables: ({} as any),
  mutations: MutationsState.InitialState,
  supplierOrder: SupplierOrderState.InitialState,
  record: ({} as any),
  form: {},
  importRoute: ImportRouteState.InitialState,
  routePlanPrinting: RoutePlanPrintingState.InitialState,
  loadList: LoadListState.InitialState,
  importDistributionRackShippingWorksheet: ImportDistributionRackShippingWorksheetState.InitialState,
  importCustomerOrderSpreadsheet: ImportCustomerOrderSpreadsheetState.InitialState,
  routePlanPrintingWarning: RoutePlanPrintingWarning.InitialState,
  navigation: NavigationState.InitialState,
  distributionReceiving: DistributionReceivingState.InitialState,
  routePlan: RoutePlanState.InitialState,
  orderReports: OrderReportsState.InitialState,
  reportUserParams: ReportUserParamsState.InitialState,
  transportationReports: TransportationReportsState.InitialState,
  emailMerchandisersModalState: EmailMerchandisersModalState.InitialState,
  invoice: InvoiceState.InitialState,
  importSalesPlan: SalesPlanState.InitialState,
  salesPlan: SalesPlanState.InitialState,
  supplierCommitment: SupplierCommitmentState.InitialState,
  product: ProductState.InitialState,
  customerOrderHeader: CustomerOrderHeaderState.InitialState,
};

export type Type = State;

export const user = Lens.from<State>().prop('session', 'user');

export const receivingWorksheet = Lens.from<State>().prop('receivingWorksheet');
export const removeBolModalShown = receivingWorksheet.comp(ReceivingWorksheetState.removeBolModalShown);
export const bolToRemoveId = receivingWorksheet.comp(ReceivingWorksheetState.bolToRemoveId);
export const removeBolMutationStatus = receivingWorksheet.comp(ReceivingWorksheetState.removeBolMutationStatus);
export const editBolModalShown = receivingWorksheet.comp(ReceivingWorksheetState.editBolModalShown);
export const bolToEditId = receivingWorksheet.comp(ReceivingWorksheetState.bolToEditId);
export const editBolMutationStatus = receivingWorksheet.comp(ReceivingWorksheetState.editBolMutationStatus);
export const addBolModalShown = receivingWorksheet.comp(ReceivingWorksheetState.addBolModalShown);
export const addBolMutationStatus = receivingWorksheet.comp(ReceivingWorksheetState.addBolMutationStatus);
export const selectedBolId = receivingWorksheet.comp(ReceivingWorksheetState.selectedBolId);

export const supplierOrder = Lens.from<State>().prop('supplierOrder');
export const isSendSupplierOrderSelectAllSelected = supplierOrder.comp(SupplierOrderState.isSelectAllSelected);

export const importRoute = Lens.from<State>().prop('importRoute');
export const importRouteRouteInformation = importRoute.comp(ImportRouteState.routeInformation);
export const importRouteRoutePlanId = importRoute.comp(ImportRouteState.routePlanId);
export const importRouteFileUploadStatus = importRoute.comp(ImportRouteState.fileUploadStatus);
export const importRouteParsingErrorMessages = importRoute.comp(ImportRouteState.parsingErrorMessages);
export const importRouteProcessStep = importRoute.comp(ImportRouteState.processStep);
export const importRouteStops = importRoute.comp(ImportRouteState.stopDataStoreEntries);
export const importRouteSelectedSplitStopStore = importRoute.comp(ImportRouteState.selectedSplitStopStore);
export const importRouteSplitStopQuantities = importRoute.comp(ImportRouteState.splitStopQuantities);

export const createCustomerForImportRoute = (customerInput: { identifier: string }) => (state: Type) =>
  importRoute.update(state, ImportRouteState.createCustomer(customerInput));
export const createStoreForImportRoute = (storeInput: { identifier: string, customerIdentifier: string }) =>
  (state: Type) => importRoute.update(state, ImportRouteState.createStore(storeInput));
export const createCustomerOrderForImportRoute = (customerOrderInput: { customerIdentifier: string, identifier: string }) => (state: Type) =>
  importRoute.update(state, ImportRouteState.createCustomerOrder(customerOrderInput));
export const createLoadForImportRoute = (loadInput: {
  loadNumber: string,
  routeNumber: Int,
  truckId: string,
}) => (state: Type) =>
  importRoute.update(state, ImportRouteState.createLoad(loadInput));
export const createStopForImportRoute = (stopInput: {
    storeIdentifier: string,
    loadIdentifier: string,
    racks: number,
    sequenceNumber: number,
    customerOrderId: string,
    customerIdentifier: string,
    truckId: string,
  }) => (state: Type) =>
  importRoute.update(state, ImportRouteState.createStop(stopInput));

export const mutations = Lens.from<State>().prop('mutations');
export const showBusyModalLens = mutations.comp(MutationsState.showBusyModalLens);
export const busyModalMessageLens = mutations.comp(MutationsState.busyModalMessageLens);

export const routePlanPrinting = Lens.from<State>().prop('routePlanPrinting');
export const sendLoadsToLoadMasterActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.sendLoadsToLoadMasterActionStatus);
export const emailRouteSheetsToPeakActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.emailRouteSheetsToPeakActionStatus);
export const downloadStoreAdvanceShipNoticeActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.downloadStoreAdvanceShipNoticeActionStatus);
export const createCustomerAdvanceShipNoticeActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.createCustomerAdvanceShipNoticeActionStatus);
export const downloadStoreDeliveryDateSpreadsheetActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.downloadStoreDeliverySpreadsheetActionStatus);
export const downloadPalletReportActionStatus = routePlanPrinting.comp(RoutePlanPrintingState.downloadPalletReportActionStatus);
export const createRoutePacketsModalShown = routePlanPrinting.comp(RoutePlanPrintingState.createRoutePacketsModalShown);
export const routeSheetsChecked = routePlanPrinting.comp(RoutePlanPrintingState.routeSheetsChecked);
export const deliveryAcknowledgementsChecked = routePlanPrinting.comp(RoutePlanPrintingState.deliveryAcknowledgementsChecked);
export const purchaseOrderInvoicesChecked = routePlanPrinting.comp(RoutePlanPrintingState.purchaseOrderInvoicesChecked);
export const returnInstructionsChecked = routePlanPrinting.comp(RoutePlanPrintingState.returnInstructionsChecked);
export const specialInstructionsChecked = routePlanPrinting.comp(RoutePlanPrintingState.specialInstructionsChecked);
export const markOrdersAsShippedChecked = routePlanPrinting.comp(RoutePlanPrintingState.markOrdersAsShippedChecked);
export const selectedRoutingLoadIds = routePlanPrinting.comp(RoutePlanPrintingState.selectedRoutingLoadIds);
export const downloadDittyCardModalShown = routePlanPrinting.comp(RoutePlanPrintingState.downloadDittyCardModalShown);
export const emailMerchandisersModalShown = routePlanPrinting.comp(RoutePlanPrintingState.emailMerchandisersModalShown);
export const downloadStoreAsnModalShown = routePlanPrinting.comp(RoutePlanPrintingState.downloadStoreAsnModalShown);
export const selectedLoadConfiguration = routePlanPrinting.comp(RoutePlanPrintingState.selectedLoadConfiguration);
export const storesWithMerchandisers = routePlanPrinting.comp(RoutePlanPrintingState.storesWithMerchandisers);
export const storeSearchText = routePlanPrinting.comp(RoutePlanPrintingState.storeSearchText);

export const loadList = Lens.from<State>().prop('loadList');
export const loadListApplyInvoiceToLoadsModalShown = loadList.comp(LoadListState.applyInvoiceToLoadsModalShown);
export const loadListSelectedRoutingLoadIds = loadList.comp(LoadListState.routingLoadIds);
export const loadListSelectedCarrier = loadList.comp(LoadListState.selectedCarrier);
export const loadListEnteredInvoiceNumber = loadList.comp(LoadListState.enteredInvoiceNumber);
export const loadListAssignLoadsToTrailersModalShown = loadList.comp(LoadListState.assignLoadsToTrailersModalShown);
export const loadListTrailerNumbers = loadList.comp(LoadListState.assignedTrailerNumbers);
export const loadListNotifyPeakModalShown = loadList.comp(LoadListState.notifyPeakModalShown);
export const checkedLoadNumbers = loadList.comp(LoadListState.checkedLoadNumbers);

export const navigation = Lens.from<State>().prop('navigation');
export const recordBarGoBackToLocation = navigation.comp(NavigationState.recordBarGoBackToLocation);

export const distributionReceiving = Lens.from<State>().prop('distributionReceiving');
export const isSendMicsModalShown = distributionReceiving.comp(DistributionReceivingState.isSendMicsModalShown);
export const sendMicsFileToMeijerActionStatus = distributionReceiving.comp(DistributionReceivingState.sendMicsFileToMeijerActionStatus);

export const customerOrdersIdsNotFullyReceived = distributionReceiving.comp(DistributionReceivingState.customerOrdersIdsNotFullyReceived);
export const customerOrdersIdsAlreadySent = distributionReceiving.comp(DistributionReceivingState.customerOrdersIdsAlreadySent);
export const customerOrdersIdsToBeSent = distributionReceiving.comp(DistributionReceivingState.customerOrdersIdsToBeSent);
export const customerOrdersIdsThatAreScanBased = distributionReceiving.comp(DistributionReceivingState.customerOrdersIdsThatAreScanBased);

export const reportState = Lens.from<State>().prop('orderReports');

export const transportationReportsState = Lens.from<State>().prop('transportationReports');
export const getHmgOpsReportType = transportationReportsState
  .comp(TransportationReportsState.hmgOpsReportTypeLens);

export const routePlan = Lens.from<State>().prop('routePlan');
export const emailMerchandisersModalState = Lens.from<State>().prop('emailMerchandisersModalState');

export const invoiceState = Lens.from<State>().prop('invoice');
export const isEmailInvoiceModalShown = invoiceState.comp(InvoiceState.isEmailInvoiceModalShown);
export const isAddRoutePlansModalShown = invoiceState.comp(InvoiceState.isAddRoutePlansModalShownLens);
export const isAddVendorChargebackLineItemsModalShown = invoiceState.comp(InvoiceState.isAddVendorChargebackLineItemsModalShownLens);

export const salesPlanLens = Lens.from<State>().prop('salesPlan');

export const supplierCommitmentLens = Lens.from<State>().prop('supplierCommitment');

export const customerOrderHeaderLens = Lens.from<State>().prop('customerOrderHeader');
