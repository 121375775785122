import * as State from 'client/state/route-plan';
import * as GeneralRoutePlanActions from 'client/actions/route-plan';
import * as ReviewStoreActions from 'client/app/transportation/routing/route-plan-details/review-stores/actions';
import * as AddStoreModalActions from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal/actions';

type RoutePlanState = State.Type;

type ActionTypes =
  GeneralRoutePlanActions.ActionTypes |
  ReviewStoreActions.ActionTypes |
  AddStoreModalActions.ActionTypes;

export default function reducer(state = State.InitialState, action: ActionTypes): RoutePlanState {
  switch (action.type) {
    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY: {
      return State.attachOrdersModalShown.set(state, action.payload.showModal);
    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY: {
      if (!action.payload.showModal) {
        return State.InitialState;
      }
      return State.downloadStoreSummaryReportModalShown.set(state, action.payload.showModal);

    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SEARCHED: {
      return State.storeSummaryReportSearchText.set(state, action.payload.searchText);
    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_STORES: {
      return State.storeSummaryReportStores.set(state, action.payload.stores);
    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SELECTED: {
      const newStores = state.storeSummaryReportStores.map(s => {
        return {
          ...s,
          checked: s.storeId === action.payload.storeId ? !s.checked : s.checked,
        };
      });
      return State.storeSummaryReportStores.set(state, newStores);
    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_DOWNLOAD_COMPLETED: {
      return State.InitialState;
    }

    case GeneralRoutePlanActions.ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_SELECT_ALL_CLICKED: {
      return State.isStoreSummaryReportStoresSelectAllChecked.update(state, currentValue => !currentValue);
    }

    case ReviewStoreActions.ActionTypeKeys.AddStoreButtonClicked: {
      return State.addStoreModalShown.set(state, true);
    }

    case AddStoreModalActions.ActionTypeKeys.AddStoreModalCancelButtonClicked: {
      return State.addStoreModalShown.set(state, false);
    }

    case AddStoreModalActions.ActionTypeKeys.AddStoreMutationSucceededAction: {
      return State.addStoreModalShown.set(state, false);
    }

    default:
      return state;
  }
}
