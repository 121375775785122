import * as UserParamTypes from './types';
import * as WrappedContainer from 'client/hoc/hoc';

export interface UserParamsMapperProps {
  mapToReportUserParams: UserParamTypes.MapToReportUserParams;
  mapFromReportUserParams: UserParamTypes.MapFromReportUserParams;
}

export const withReportUserParamMappers = (mapToReportUserParams: UserParamTypes.MapToReportUserParams, mapFromReportUserParams: UserParamTypes.MapFromReportUserParams) =>
  WrappedContainer.withPropsInjector({
    mapToReportUserParams,
    mapFromReportUserParams,
  });
