import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import Dropdown from 'client/components/dropdown';
import * as _ from 'lodash';
import { SupplierCommitmentConfirmedStatus, DateTimeStr } from 'shared/types';

export interface UIProps {
  supplierCommitmentId?: number;
  supplierCommitmentHeader?: string;
  onBackButtonClicked?: () => void;
  onSaveButtonClicked(): void;
  saveButtonDisabled: boolean;
  confirmedStatus: SupplierCommitmentConfirmedStatus;
  onConfirmedStatusChanged(supplierCommitmentId: number, status: SupplierCommitmentConfirmedStatus): void;
  lastModifiedAt?: DateTimeStr;
}

export class RecordBarUI extends React.PureComponent<UIProps, {}> {
  onConfirmedStatusChanged = (status: SupplierCommitmentConfirmedStatus) => {
    if (this.props.supplierCommitmentId) {
      this.props.onConfirmedStatusChanged(this.props.supplierCommitmentId, status);
    }
  }

  render() {
    const status = (
      <RecordBarDetail>
        {this.props.supplierCommitmentId &&
          <Dropdown
            className="btn-group-record-bar-button"
            data={_.values(SupplierCommitmentConfirmedStatus)}
            value={[this.props.confirmedStatus]}
            valueField="id"
            onChange={this.onConfirmedStatusChanged}
            updateLabel
            testid="status-toggle"
          />
        }
      </RecordBarDetail>
    );
    return (
      <RecordBar
        onBackButtonClicked={this.props.onBackButtonClicked}
        onSaveButtonClicked={this.props.onSaveButtonClicked}
        status={status}
        updatedAt={this.props.lastModifiedAt}
        saveButtonDisabled={this.props.saveButtonDisabled}>
        <RecordBarDetail title="Supplier Commitment ID" testid="record-bar-supplier-commitment-identifier">
          {this.props.supplierCommitmentHeader || 'New Supplier Commitment'}
        </RecordBarDetail>
      </RecordBar>
    );
  }
}
