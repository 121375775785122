import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/sales-plan/types';

export interface Input {
  salesPlanId: number;
}

interface SalesPlan {
  id: GraphQLTypes.SalesPlanV2['id'];
  identifier: GraphQLTypes.SalesPlanV2['identifier'];
  customerIdentifier: GraphQLTypes.SalesPlanV2['customerIdentifier'];
  sellDepartmentIdentifier: GraphQLTypes.SalesPlanV2['sellDepartmentIdentifier'];
  year: GraphQLTypes.SalesPlanV2['year'];
  varietyStatus: GraphQLTypes.SalesPlanV2['varietyStatus'];
}
export interface QueryResponse {
  salesPlan: SalesPlan;
}

export const Query = gql`
  query SalesPlanRecordBarQuery($salesPlanId: Int!) {
    salesPlan: GetSalesPlan(id: $salesPlanId) {
      id
      identifier
      customerIdentifier
      sellDepartmentIdentifier
      year
      varietyStatus
    }
  }
`;
