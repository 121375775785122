import { getZipFromS3 } from 'client/helpers/zip-helpers';
import * as FileSaver from 'client/utils/filesaver';
import { Thunker } from 'client/types/redux-types';
import { showBusyModal, hideBusyModal } from 'client/actions/mutations';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Types from 'schema/attachment/types';
import * as Mutations from 'client/containers/admin/mutations';
import { UNCHECK_MULTIPLE_RECORDS } from 'client/constants';

export const downloadMultipleAttachments = (recordIds: number[], attachments: Types.Attachment[] | undefined, record: { __typename: string, identifier: string }): Thunker =>
  async dispatch => {
    dispatch(showBusyModal('Downloading...'));
    const rows = (attachments || []).filter(r => recordIds.includes(r.id));

    const filename = record
      ? `${record.__typename} ${record.identifier} - Multiple Attachments.zip`
      : 'Multiple Attachments.zip';

    const zipContents = await getZipFromS3(rows);

    dispatch(hideBusyModal());

    FileSaver.saveAs(zipContents, filename);
  };

export const downloadAttachment = (recordIds: number[], attachments: Types.Attachment[] | undefined): Thunker =>
  async dispatch => {
    const row = (attachments || []).find(r => recordIds.includes(r.id));
    if (row) {
      window.location.replace(row.url);
    }
  };

export const deleteAttachments = (tableName: string, recordIds: number[], attachments: Types.Attachment[] | undefined): Thunker =>
  async dispatch => {
    const rows = (attachments || []).filter(r => recordIds.includes(r.id));

    await Promise.all((rows.map(row => {
      return msyncClientMutation<{ deleteAttachment: Types.DeleteAttachmentResponse }, Types.DeleteAttachmentInput>(
        {
          mutation: Mutations.DeleteAttachmentMutation,
          variables: {
            input: {
              id: row.id,
            },
          },
          dispatch,
          suppressErrorModal: false,
          refetchQueries: ['getAssociatedAttachments'],
        },
      );
    })));

    dispatch({
      payload: { table: tableName, recordIds },
      type: UNCHECK_MULTIPLE_RECORDS,
    });
  };

export const onAttachmentDescriptionUpdated = (id: number, description: string | undefined): Thunker =>
  async dispatch => {
    return msyncClientMutation<{ updateAttachment: Types.UpdateAttachmentResponse }, Types.UpdateAttachmentInput>(
      {
        mutation: Mutations.UpdateAttachmentMutation,
        variables: {
          input: {
            id,
            description,
          },
        },
        dispatch,
        suppressErrorModal: false,
      },
    );
  };
