const classNames = require('classnames');
import * as React from 'react';
import { Row } from 'client/components/third-party';
import * as Map from './map';

export interface Props {
  point: Map.LatLong;
  targetAddress: string | undefined;
}

export class MapWithLink extends React.Component<Props, any> {
  public render(): JSX.Element {
    const { point, targetAddress } = this.props;

    const mapClassName = classNames('map-container', {
      invalid: !point.valid,
    });

    return (
      <div className={mapClassName}>
        <Row key="map">
          <Map.Map point={point} />
        </Row>
        {targetAddress &&
          <Row key="link">
            <div className="map-directions-button">
              <a href={`https://www.google.com/maps/search/${encodeURIComponent(targetAddress)}`} target="_new"><span className="fa fa-map-marker" /> Open in Maps</a>
            </div>
          </Row>}
      </div>
    );
  }
}
