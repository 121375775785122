import { buildAdminDetailPage } from '../admin/admin-detail-page';
import FormComponent from '../../components/crud-forms/supplier-item/info-form-group';
import RecordBarDetailComponent from '../../components/crud-forms/supplier-item/record-bar-detail';
import navBuilder from './nav-items';
import query from './query';
import { push } from 'connected-react-router';

const detailPage = buildAdminDetailPage({
  table: 'SupplierItem',
  formName: 'SupplierItemInfoForm',
  FormComponent,
  navBuilder,
  RecordBarDetailComponent,
  descriptionResolver: record => {
    return record.product.identifier;
  },
  recordBarActionMenuItems: [
    {
      label: 'Duplicate Record',
      onClickAction: id => push(`/admin/supplierItems/create?id=${id}`),
    },
  ],
});

(detailPage as shame).query = query;

export default detailPage;
