import * as React from 'react';
import { Button } from 'client/components/third-party';
import { ImportProductTossModal } from './import-product-toss-modal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UIProps {
}

export function ImportProductTossUI(props: UIProps) {
  const [isShown, setIsShown] = React.useState<boolean>(false);
  const showModal = React.useCallback(() => setIsShown(true), []);
  const hideModal = React.useCallback(() => setIsShown(false), []);

  return (
    <div className="action">
      <ImportProductTossModal
        isShown={isShown}
        handleCancelButtonClicked={hideModal}
        handleCloseButtonClicked={hideModal}
      />
      <Button
        bsClass="btn btn-default mfc-button-table btn btn-default mfc-button-table-default"
        onClick={showModal}
        data-testid="product-toss-list-upload-spreadsheet"
      >
        Import Product Toss from Excel
      </Button>
    </div>
  );
}
