import * as classnames from 'classnames';
import * as React from 'react';

export interface TitleProps {
  checkedRowCount: number;
  disabled: boolean;
  required: boolean;
  title?: string;
  totalRowsCount: number;
  horizontalLabel?: boolean;
  hideOptionalLabel?: boolean;
}

export const Title = (props: TitleProps) => {
  return (
    <div className={classnames('selectable-title-container', { disabled: props.disabled })}>
      {!props.horizontalLabel &&
        <span>
          <span className="selectable-title">{props.title || ''}</span> <span
            className={classnames('selectable-title-counts mfc-form-label', {
            optional: !props.required && !props.hideOptionalLabel,
          })}> ({props.checkedRowCount} of {props.totalRowsCount})</span>
        </span>
      }
      {props.horizontalLabel &&
        <span>
          <span
            className={classnames('selectable-title-counts mfc-form-label', {
            optional: !props.required && !props.hideOptionalLabel,
          })}>
            {props.checkedRowCount} of {props.totalRowsCount}
          </span>
        </span>
      }
    </div>
  );
};
