import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../form';
import gqlTag from 'graphql-tag';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { buildFragment } from '../../../shared/schemas';
import { msyncQuery } from 'client/hoc/graphql/query';

const mapDispatchToProps = dispatch => ({
  onSellDepartmentChange: val => dispatch(change('ProductClassForm', 'sellDepartmentId', val)),
});


/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="productClasses" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

const ProductClassForm = (p: {
  loading: boolean,
  initialValues: {},
  sellDepartments: Array<{ value: number, label: string }>,
  sellDepartmentsLoading: boolean,
  onSellDepartmentChange: (...args: unknown[]) => unknown,

  // come from redux-form
  pristine: boolean,
  submitting: boolean,
  handleSubmit: (...args: unknown[]) => unknown,
  record: { id: number },
}) => {
  return (
    <Col sm={12}>
      <Form horizontal onSubmit={p.handleSubmit}>
        <div className="simple-form" data-testid="product-class">
          <FormGroup data-testid="info-row-1">
            <Field name="sellDepartmentId"
              labelColSize={2}
              inputColSize={4}
              options={p.sellDepartments}
              placeholder="Select Sell Department"
              handleChange={p.onSellDepartmentChange}
              loading={p.sellDepartmentsLoading}
              autoFocus={!p.record?.id}
            />
          </FormGroup>
          <FormGroup data-testid="info-row-2">
            <Field name="identifier" labelColSize={2} inputColSize={4}/>
          </FormGroup>
        </div>
      </Form>
    </Col>
  );
};

const fragmentName = 'ProductClass_SellDepartmentFragment';
const sellDeptFragment = gqlTag`${buildFragment('sellDepartments', ['identifier'], fragmentName)}`;
const SELL_DEPT_QUERY =
    gqlTag`
    query SellDepartmentQuery($type: RecordType!) {
      content: findAll(type: $type) {
        id,
        ...ProductClass_SellDepartmentFragment
      }
    }
    ${sellDeptFragment}
  `;

const withSellDepts = msyncQuery<shame>(SELL_DEPT_QUERY, {
  options: () =>({ variables: { type: 'SellDepartment' } }),
  props: (({ data: { content, loading } }) => ({ sellDepartments: content, sellDepartmentsLoading: loading })) as shame,
});


export default withSellDepts(connect(null, mapDispatchToProps)(ProductClassForm as shame));

