import * as React from 'react';
import * as Immutable from 'immutable';
import { IColumn } from 'client/components/table/column';
import { aggregateSum } from 'client/components/table/table-helpers';
import { connect } from 'react-redux';
import { AllocatedProductChange } from 'client/state/product-worksheet';
import { shallowEqual } from 'shared/object-difference';
import NumberCell from 'client/components/table/number-cell';
import * as State from 'client/state/state';
import * as ProductWorksheetSelectors from 'client/state/product-worksheet-selectors';

interface OwnProps {
  data: any;
  column: IColumn;
}

interface StateProps {
  changes: Immutable.List<AllocatedProductChange>;
  isRefetching: boolean;
}

function makeMapStateToProps(copgId: number) {
  return (state: State.Type, ownProps: OwnProps): StateProps => {
    return {
      changes: ProductWorksheetSelectors.findAllocatedProductChangesForCustomerOrderProductGroup(copgId)(state),
      isRefetching: ProductWorksheetSelectors.isAllocationDetailsRefetching(state),
    };
  };
}

class FooterComponent extends React.Component<OwnProps & StateProps, any> {

  /**
   * Don't change what's displayed while data is refreshing. This will (hopefully)
   * avoid the number jumping around between the time that Apollo updates
   * it's cache and the set of changes are all cleared out.
   */
  shouldComponentUpdate(nextProps: OwnProps & StateProps) {
    if (this.props.isRefetching && nextProps.isRefetching) {
      return false;
    }
    return !shallowEqual(nextProps, this.props);
  }

  onChangeHandler = () => {
    // Footer should never get an onChange
  }

  render() {
    const props = this.props;

    // TODO: use reselect to cache the initial total?
    let total = aggregateSum(props);
    let spinner = props.isRefetching;

    if (props.changes) {
      const changes = (props.changes.toJS() as AllocatedProductChange[]).reduce((sum, obj) => {
        if (obj?.change ) {
          return sum + obj.change;
        } else {
          return sum;
        }
      }, 0);

      total += changes;

      if (changes !== 0) {
        spinner = true;
      }
    }

    return (
      <div>
        <div>
          <NumberCell
            editing={false}
            value={total}
            centered={true}
            onChange={this.onChangeHandler}
            testid="footer-cell"
          />
        </div>
        {spinner && <span className="fa fa-spinner fa-spin mfc-footer-spinner" />}
      </div>
    );
  }
}

export function productAllocationFooter(copgId: number): shame {
  return connect<StateProps, {}, OwnProps>(makeMapStateToProps(copgId))(FooterComponent);
}
