import * as _ from 'lodash';
import { wrapComponent } from 'client/hoc/hoc';
import { connect, ConnectedProps } from 'react-redux';
import { VendorEditFormUI } from './ui';
import * as Actions from './actions';
import * as VendorRecordBarQuery from './query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { withRouter, RouteChildrenProps } from 'react-router';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {
}

interface StateProps {
  vendorId: number | undefined;
}

const mapStateToProps = (state: {}, ownProps: ContainerProps & RouteChildrenProps<{ id: string }>): StateProps => {
  return {
    vendorId: ownProps.match && ownProps.match.params.id ? _.parseInt(ownProps.match.params.id) : undefined,
  };
};

const mapDispatchToProps = {
  onSubmit: Actions.formSubmitted,
};

interface QueryProps {
  initialValues?: shame;
}

const withVendorInfo = msyncQuery<VendorRecordBarQuery.QueryResponse, StateProps & ContainerProps, QueryProps, VendorRecordBarQuery.Input>(VendorRecordBarQuery.Query, {
  alias: 'withVendorInfo',
  skip(ownProps) {
    return ownProps.vendorId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        vendorId: ownProps.vendorId!,
      },
    };
  },
  props(props) {
    const { data } = props;
    if (data.loading || data.getVendor === undefined) {
      return {};
    }
    return {
      initialValues: data.getVendor.vendor,
    };
  },
});

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

type CombinedProps = ContainerProps & QueryProps & ConnectedProps<typeof connector>;

export const VendorEditFormContainer = wrapComponent(VendorEditFormUI)<ContainerProps, CombinedProps>(
  withRouter,
  connector,
  withVendorInfo,
);
