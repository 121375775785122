import * as React from 'react';

export function useConnectedVerticalScrolling() {
  const [verticalScrollPosition, setVerticalScrollPosition] = React.useState(0);
  const onVerticalScroll = args => {
    if (!args.scrollUpdateWasRequested) {
      setVerticalScrollPosition(args.scrollTop);
    }
  };
  return {
    onVerticalScroll,
    verticalScrollPosition,
  };
}

export function useConnectedHorizontalScrolling() {
  const [horizontalScrollPosition, setHorizontalScrollPosition] = React.useState(0);
  const onHorizontalScroll = args => {
    setHorizontalScrollPosition(args.scrollLeft);
  };
  return {
    onHorizontalScroll,
    horizontalScrollPosition,
  };
}
