import * as _ from 'lodash';
import * as Actions from './actions';
import * as HOC from 'client/hoc/hoc';
import * as ImportModalTypes from 'client/components/import-modal/types';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';

export interface OwnProps {
  isShown: boolean;
  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;
}

interface DispatchProps {
  handleParsingStarted: ImportModalTypes.ParseFn;
  handleValidationStarted: ImportModalTypes.ValidateFn;
  handleImportStarted: ImportModalTypes.ImportFn;
}

const mapDispatchToProps = {
  handleParsingStarted: Actions.handleParsingStarted,
  handleValidationStarted: Actions.handleValidationStarted,
  handleImportStarted: Actions.handleImportStarted,
};

export type ContainerProps =
  OwnProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.ComponentProps, ContainerProps>();

const component = _.flowRight(
  ReactRedux.connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(UI.ImportProductTossModalUI) as Component<OwnProps>;

export const ImportProductTossModalContainer = (props: OwnProps) => HOC.propToComponent(component, props);
