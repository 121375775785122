import * as _ from 'lodash';
import * as Helpers from '../../helpers';
import * as React from 'react';
import * as classnames from 'classnames';
import * as Types from '../../types';
import { shallowEqual } from 'shared/object-difference';

interface FooterCellProps {
  data: {
    columns: Types.ColumnDef[];
    values: any[];
    flags: any[];
    footerFlags: any[];
    footerData: string[];
    tableName?: string;
    headers: string[];
  };
  columnIndex: number;
  style: any;
}

function getValues(props: FooterCellProps) {
  return props.data.values;
}

function getColumns(props: FooterCellProps) {
  return props.data.columns;
}

function getColumnIndex(props: FooterCellProps) {
  return props.columnIndex;
}

function getTableName(props: FooterCellProps) {
  return props.data.tableName;
}

function getColumn(columnIndex: number, columns: Types.ColumnDef[]) {
  return columns[columnIndex];
}

function getRawValue(columnIndex: number, values: any[]) {
  return _.sum(values.map(value => _.isNumber(value[columnIndex]) ? value[columnIndex] : 0));
}

function getCellValue(value: any, column: Types.ColumnDef) {
  return Helpers.formatColumn(column.contentType, value);
}

function getIsLastColumn(columnIndex: number, columns: Types.ColumnDef[]) {
  return columns.length === columnIndex + 1;
}

function getIsAmountCell(column: Types.ColumnDef) {
  return column.contentType === Types.ColumnContentType.Amount;
}

function getIsPercentCell(column: Types.ColumnDef) {
  return column.contentType === Types.ColumnContentType.Percent;
}

function getIsMoneyCell(column: Types.ColumnDef) {
  return column.contentType === Types.ColumnContentType.Money;
}

function getIsFlaggedCell(rawValue: number, columnIndex: number, bodyFlags: any[], footerFlags: any[]) {
  const bodyHasFlag =  _.some(bodyFlags.map(row => row[columnIndex]), _.identity);
  if (bodyHasFlag) {
    return true;
  }

  const footerHasFlag = _.some(footerFlags.map(row => row[columnIndex]), _.identity);
  if (footerHasFlag && rawValue !== 100) {
    return true;
  }

  return false;
}

function getClassName(cellValue: string | JSX.Element, rawValue: number, columnIndex: number, columns: Types.ColumnDef[], flags: any[], footerFlags: any[]) {
  const isLastColumn = getIsLastColumn(columnIndex, columns);
  const column = columns[columnIndex];

  const valueClass = `readonly-table-footer-cell-value-${typeof cellValue === 'string' ? cellValue.replace(/\D/g, '') : 'no-serializable-value'}`;

  return classnames('readonly-table-footer-cell', {
    'readonly-table-footer-cell-not-last-column': !isLastColumn,
    'readonly-table-footer-cell-amount': getIsAmountCell(column),
    'readonly-table-footer-cell-percent': getIsPercentCell(column),
    'readonly-table-footer-cell-money': getIsMoneyCell(column),
    'readonly-table-footer-cell-flag': getIsFlaggedCell(rawValue, columnIndex, flags, footerFlags),
    [valueClass]: true,
  });
}

export const FooterCell = React.memo((props: FooterCellProps) => {

  const columnIndex = getColumnIndex(props);
  const column = getColumn(columnIndex, getColumns(props));
  const values = getValues(props);
  const value = getRawValue(columnIndex, values);
  const cellValue = React.useMemo(() => getCellValue(value, column), [value, column]);
  const header = props.data.headers[columnIndex];
  const columns = getColumns(props);
  const flags = props.data.flags;
  const footerFlags = props.data.footerFlags;
  const className = React.useMemo(() => {
    return getClassName(cellValue, value, columnIndex, columns, flags, footerFlags);
  }, [cellValue, value, columnIndex, columns, flags, footerFlags]);

  return (
    <div
      style={props.style}
      className={className}
      data-cell-type="footer"
      data-column-index={props.columnIndex}
      data-column-header={header}
      data-table-name={getTableName(props)}
      data-value={value}
    >
      {cellValue}
    </div>
  );
}, (prevProps: FooterCellProps, nextProps: FooterCellProps) => {
  const prevCellProps = extractCellProps(prevProps);
  const nextCellProps = extractCellProps(nextProps);

  const prevStyle = prevProps.style;
  const nextStyle = nextProps.style;

  return shallowEqual(prevStyle, nextStyle) && shallowEqual(prevCellProps, nextCellProps);
});

function extractCellProps(props: FooterCellProps) {
  return {
    columnIndex: props.columnIndex,
    contentType: props.data.columns[props.columnIndex].contentType,
    values: getValues(props), // When we have a ROT were individual column data can change this will need to be more specific
    flags: props.data.flags,
    footerFlags: props.data.footerFlags,
  };
}
