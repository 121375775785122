import * as React from 'react';
import { buildAdminDetailPage }  from 'client/containers/admin/admin-detail-page';
import FormComponent             from './customer-order-form';
import RecordBarActions          from '../record-bar-actions';
import { saveConfirmationOptionsForCustomerOrder } from 'client/app/orders/customer-orders/save-confirmation-message';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { withReceivingEmailNotificationMutation, EmailReceivingNotificationMutationProps } from 'client/hoc/with-receiving-email-notification-mutation';
import { NotificationArgs } from 'client/hoc/with-notification';

const notificationOptions: NotificationArgs<CustomerOrder, { receivingStatusChanged: boolean }> = {
  onlyDisplayOnce: true,
  shouldDisplay: (previousRecord?: CustomerOrder, currentRecord?: CustomerOrder) => {
    if (previousRecord && currentRecord) {
      if (previousRecord.receivingStatus !== currentRecord.receivingStatus) {
        return {
          shouldDisplay: true,
          message: <div>This Customer Order is now {currentRecord.receivingStatus}. A notification email has been sent to Transportation. Please coordinate as appropriate.</div>,
          data: {
            receivingStatusChanged: true,
          },
        };
      }

      // TODO: Check for changes that would require a new receiving receipt. Not going to implement this
      //       now though. It will probably require: passing a custom query into the buildAdminDetail page (to
      //       make sure all the data for comparing the two orders is available here, like is done for Supplier
      //       Orders), and making sure that any place that allows editing of the order returns enough data
      //       so this gets triggered again with the new information.
    }

    return { shouldDisplay: false };
  },
  beforeDisplay: async (record?: CustomerOrder, props?: EmailReceivingNotificationMutationProps, data?: { receivingStatusChanged: boolean }): Promise<void> => {
    if (record?.receivableOrderId && props) {
      await props.emailNotificationOfReceivingStatus(record.receivableOrderId, data?.receivingStatusChanged ?? false);
    }
  },
};

const Overview = buildAdminDetailPage({
  table: 'CustomerOrder',
  formName: 'CustomerOrderOverviewForm',
  FormComponent,
  resetApolloStoreAfterSave: true,
  OptionalStatusToggle: RecordBarActions,
  saveConfirmationOptions: saveConfirmationOptionsForCustomerOrder,
  notificationOptions,
  formClassName: 'mfc-form mfc-form-not-scrollable',
});

export default withReceivingEmailNotificationMutation(Overview as shame);
