import * as React from 'react';
import { Button } from 'client/components/third-party';
import { ImportProductPriceModal } from './import-product-price-modal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UIProps {
}

export function ImportProductPriceUI(props: UIProps) {
  const [isShown, setIsShown] = React.useState<boolean>(false);
  const showModal = React.useCallback(() => setIsShown(true), []);
  const hideModal = React.useCallback(() => setIsShown(false), []);

  return (
    <div className="action">
      {
        isShown &&
        <ImportProductPriceModal
          handleCancelButtonClicked={hideModal}
          handleCloseButtonClicked={hideModal}
        />
      }
      <Button
        bsClass="btn btn-default mfc-button-table btn btn-default mfc-button-table-default"
        onClick={showModal}
        data-testid="product-list-price-upload-spreadsheet"
      >
        Import Product Price / Retail
      </Button>
    </div>
  );
}
