import { ActiveInactive } from 'shared/types';
import buildRecordBarStatusToggle from 'client/components/record-bar/build-record-bar-status-toggle';

export interface Props {
  record: any;
  onChangeRecordStatus: (field: string, val: string) => void;
}

export function RecordBarStatusToggle(props: Props) {
  const { onChangeRecordStatus, record } = props;
  const statuses = [ActiveInactive.Inactive, ActiveInactive.Active];

  return buildRecordBarStatusToggle({
    value: record ? record.activeStatus : undefined,
    options: statuses,
    default: ActiveInactive.Active,
    onChangeRecordStatus,
    field: 'activeStatus',
  });
}
