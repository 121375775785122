import gql from 'graphql-tag';
import { msyncMutation } from 'client/hoc/graphql/mutation';

export interface EmailReceivingNotificationMutationProps { emailNotificationOfReceivingStatus(receivableOrderId: number, receivingStatusChanged: boolean): Promise<boolean> }
const EMAIL_NOTIFICATION_MUTATION = gql`
  mutation EmailNotificationOfReceivingStatus($receivableOrderId: Int!, $receivingStatusChanged: Boolean!) {
    emailNotificationOfReceivingStatus(receivableOrderId: $receivableOrderId, receivingStatusChanged: $receivingStatusChanged)
  }
`;

export const withReceivingEmailNotificationMutation = msyncMutation<{}, {}, EmailReceivingNotificationMutationProps>(EMAIL_NOTIFICATION_MUTATION, {
  alias: 'emailReceivingNotificationOfReceivingStatus',
  props: args => ({
    emailNotificationOfReceivingStatus: async (receivableOrderId: number, receivingStatusChanged: boolean) => !!await args.mutate({ variables: { receivableOrderId, receivingStatusChanged } }),
  }),
});
