import * as React from 'react';
import * as _ from 'lodash';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { flowRight }                                              from 'lodash';
import { FieldWrapper, LabeledSelect }                            from 'client/components/form';
import { FormGroup, Row }                                         from 'client/components/third-party';
import { Field }                                                  from 'redux-form';
import { ShippingUnitOrderMethod, RackShippingUnit, CustomerOrderType, ActiveInactive }              from 'shared/types';
import { Store } from 'shared/schemas/store';
import { Saved } from 'shared/schemas/record';

function CustomerOrderDetailForm({ stores, products, selectedOrderMethod, selectedShippingUnitType }: Props) {
  const qtyLabel
    = selectedOrderMethod !== ShippingUnitOrderMethod ? 'Qty.'
    : selectedShippingUnitType === RackShippingUnit   ? 'Rack Qty.'
    :                                                   'Pallet Qty.';

  return (
    <Row>
      <FormGroup>
        <Field
          name="storeIds"
          inputColSize={12}
          options={stores || []}
          textFormatter={store => `${store.identifier} - ${store.city}, ${store.state}`}
          horizontalLabel={false}
          valueField="id"
          placeholder="Select Stores"
          label="Stores"
          component={LabeledSelect}
          testid="store"
          multi
          required
          autoFocus
        />
      </FormGroup>
      <hr/>
      <FormGroup>
        <Field
          name="productIds"
          inputColSize={10}
          options={products || []}
          horizontalLabel={false}
          valueField="id"
          textFormatter={product => `${product.identifier} - ${product.description}`}
          label="Products"
          placeholder="Select Products"
          component={LabeledSelect}
          testid="product"
          multi
          required
        />
        <FieldWrapper
          table="customerOrderAllocations"
          name="quantity"
          label={qtyLabel}
          inputColSize={2}
          horizontalLabel={false}
        />
      </FormGroup>
    </Row>
  );
}

interface Props extends WithStoresProps, WithProductsProps {
  formName: string;
  selectedOrderMethod: string;
  selectedShippingUnitType: string;

  // used for option generators
  selectedCustomerId: number;
  selectedOrderType: string;
  selectedMfcAreaId: number;
  selectedSellDepartmentIdentifier: string;
  scanBased: boolean;
}

interface WithStoresProps { stores: Array<{ value: number; label: string; }>; }
const withStores = optionsContainerWithFilters({
  table: 'stores',
  columns: ['identifier', 'city', 'state', 'mfcAreas'],
  getFilters: props => {
    return [
      { field: 'customer', values: [props.selectedCustomerId.toString()] },
      { field: 'activeStatus', values: [ActiveInactive.Active] },
    ];
  },
  props: (args: { ownProps: Props, data: { content?: any } }) => {
    // Filter such that only stores associated with the current MFC Area are returned
    if (!args.data || !args.data.content) {
      return { stores: undefined };
    }

    const content: Array<Saved<Store>> = args.data.content;

    if (_.isNil(args.ownProps.selectedMfcAreaId)) {
      return { stores: content };
    }

    const stores = content.filter(store => {
      if (!store.mfcAreas) {
        return false;
      }

      return store.mfcAreas.some(mfcArea => args.ownProps.selectedMfcAreaId === mfcArea.id);
    });

    return { stores };
  },
});

interface WithProductsProps { products: Array<{ value: number; label: string; }>; }
const withProducts = optionsContainerWithFilters({
  table: 'products',
  columns: ['identifier', 'description', 'packSize', 'packsPerRack', 'shelvesPerRack'],
  getFilters: props => {
    if (props.selectedOrderType !== CustomerOrderType.ExternalDistribution) {
      return [
        { field: 'customer', values: [props.selectedCustomerId.toString()] },
        { field: 'sellDepartment.identifier', values: [props.selectedSellDepartmentIdentifier, 'MISC'] },
        { field: 'scanProduct', values: [props.scanBased ? 'true' : 'false'] },
        { field: 'externalDistribution', values: [false] },
        { field: 'activeStatus', values: [ActiveInactive.Active] },
      ];
    } else {
      return [
        { field: 'customer', values: [props.selectedCustomerId.toString()] },
        { field: 'externalDistribution', values: [true] },
        { field: 'activeStatus', values: [ActiveInactive.Active] },
      ];
    }
  },
});

const WrappedForm = flowRight(
  withStores,
  withProducts,
)(CustomerOrderDetailForm as shame);

export default props => <WrappedForm {...props} />;  // eslint-disable-line react/no-multi-comp
