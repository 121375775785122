import * as React from 'react';
import * as classNames from 'classnames';
import { ProductListItemDetail } from './product-list-item-detail';
import { Checkbox } from 'client/components/form/checkbox';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';

export interface Props {
  header: string;
  items: Array<{
    id: number;
    elements: JSX.Element[];
  }>;
  isSelected: boolean;
  isChecked: boolean;
  handleProductClicked?: () => void;
  handleCheckboxChecked: () => void;
  handlePencilClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void;
  handleTrashCanClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void;
  testId?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class ProductListItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { header, items, handleProductClicked, isSelected, handleCheckboxChecked, isChecked, handlePencilClicked, handleTrashCanClicked, testId} = this.props;

    const labelClassName = classNames('product-worksheet-products-list-product-label', {
      'product-worksheet-is-selected': isSelected,
    });

    const checkboxInputChangeHandler = {
      onChange: handleCheckboxChecked,
      value: isChecked,
    };

    return (
      <li className="product-worksheet-products-list-product" data-testid={testId} key={testId}>
        <div>
          <Checkbox input={checkboxInputChangeHandler} />
          <span className={labelClassName} onClick={handleProductClicked}>{header}</span>
        </div>
        {items.map(item => {
          return (
            <ProductListItemDetail
              elements={item.elements}
              testId={`${testId}-detail`}
              key={item.id}
              handlePencilClicked={() => handlePencilClicked(item.id)}
              handleTrashCanClicked={() => handleTrashCanClicked(item.id)}
            />
          );
        })}
      </li>
    );
  }
}
