import FormComponent from 'client/components/crud-forms/carrier-rate-form';
import buildAdminCreatePage from '../admin/admin-create-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminCreatePage({
  formName: 'CarrierRateCreateForm',
  table: 'carrierRates',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
