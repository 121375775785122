import * as TablesState from 'client/state/tables';
import * as Constants from './constants';
import { SORT_TYPES, TYPES, SEARCH_FIELD_ANY, FilterOperator } from 'shared/types';
import { addDays } from 'shared/helpers';
import { Clock } from 'shared/clock';

export const genericTableActions = TablesState.buildTableStateModule(Constants.TableName,
  {
    sort: [{
      sortOrder: SORT_TYPES.DESC,
      sortField: 'deliveryDate',
    }],
    availableSearchFields: [
      {
        id: SEARCH_FIELD_ANY,
        name: 'Search All',
      },
      {
        id: 'identifier',
        name: 'Route Plan ID',
      },
      {
        id: 'customerName',
        name: 'Customer',
      },
      {
        id: 'mfcAreaIdentifier',
        name: 'MFC Area',
      },
    ],
    filters: [
      {
        field: 'deliveryDate',
        values: [ addDays(Clock.today(), 1), addDays(Clock.today(), 1) ],
        type: TYPES.DATE,
        operator: FilterOperator.Between,
      },
    ],
  },
);
