import * as React from 'react';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES } from 'shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { EMPTY_ARRAY, SALES_PLAN_TABLE_NAME } from 'client/constants';
import { AvailableFilter } from 'client/types';
import PageTitle from 'client/components/page-title';
import * as _ from 'lodash';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { ConfirmOkToSaveFunction } from './save-confirmation';

export interface SalesPlansListTableRow {
  id: number;
  year: number;
  identifier: string;
  customerName: string;
  sellDepartmentIdentifier: string;
  varietyStatus: string;
}

export interface UIProps {
  rows?: SalesPlansListTableRow[];
  loading: boolean;
  totalCount: number;
  filteredRecordIds: number[];
  totalUnfilteredCount: number;
  tableParentInfo: TableParentInfo;
  onNewClicked: () => void;
  dataRequest: MsyncDataRequest;
  onRowSelected(row: { id: number }): void;
  availableFilters: AvailableFilter[];
  onDeleteMenuItemClicked(ids: number[], confirmOkToSave: ConfirmOkToSaveFunction): void;
  confirmOkToSave: ConfirmOkToSaveFunction;
}

const columns: IColumn[] = [
  {
    id: 'year',
    accessor: 'year',
    header: 'Year',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'Season',
    tableEditable: false,
    columnWidth: 25,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'customerName',
    accessor: 'customerName',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 25,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'sellDepartmentIdentifier',
    accessor: 'sellDepartmentIdentifier',
    header: 'Sell Department',
    tableEditable: false,
    columnWidth: 25,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'varietyStatus',
    accessor: 'varietyStatus',
    header: 'Variety/Mix Status',
    tableEditable: false,
    columnWidth: 15,
    sortable: true,
    cellType: CELL_TYPES.STATUS,
    type: TYPES.STRING,
  },

];

export class SalesPlansTableUI extends React.PureComponent<UIProps, {}> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private tableName = SALES_PLAN_TABLE_NAME;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(this.tableName);
  }

  private headerMenuItems: RowMenuItem[] = EMPTY_ARRAY; // Not adding delete sales plan to the header to prevent accidentally deleting all of them

  private rowMenuItems: RowMenuItem[] = [
    {
      label: 'Delete Sales Plan',
      onClick: (ids: number[]) => this.props.onDeleteMenuItemClicked(ids, this.props.confirmOkToSave),
      uncheckRecordFollowingClick: true,
      willRemove: true,
    },
  ];

  render() {
    return (
      <div>
        <PageTitle title="Sales Plans"/>
        <this.FilterableTable
          table={this.tableName}
          content={this.props.rows || EMPTY_ARRAY}
          loading={this.props.loading}
          columns={columns}
          excelColumns={columns}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={_.noop}
          loadMoreRecords={_.noop}
          searchableFields={EMPTY_ARRAY}
          availableFilters={this.props.availableFilters}
          placeholder="No Sales Plans"
          checkable
          list
          tablePaginated
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          onRowSelect={this.props.onRowSelected}
          dataRequest={this.props.dataRequest}
          rowMenuItems={this.rowMenuItems}
          headerMenuItems={this.headerMenuItems}
          newButtonLabel="New Sales Plan"
          onNewClicked={this.props.onNewClicked}
        />
      </div>
    );
  }
}
