import * as Helpers from './helpers';
import { WorkBookContainer } from 'shared/file-parsers/excel/common';
import { ImportableDistributionRackShippingWorkSheet } from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser/types';
import { isErrorWithData, ErrorWithData } from 'shared/errors';
export { ImportableDistributionRackShippingWorkSheet };

interface SuccessfulParsedDistributionRackShippingSheet {
  success: true;
  parsed: ImportableDistributionRackShippingWorkSheet;
}
interface FailedParsedDistributionRackShippingSheet {
  success: false;
  reason: string | string[];
}
type ParsedDistributionRackShippingSheetResult = SuccessfulParsedDistributionRackShippingSheet | FailedParsedDistributionRackShippingSheet;

export async function getDistributionRackShippingLineItemsFromWorkBookContainer(workBookContainer: WorkBookContainer, storeIdentifiersInRoutePlan: string[]): Promise<ParsedDistributionRackShippingSheetResult> {
  try {
    const workBook = await Helpers.getDistributionRackShippingWorkBookFromWorkBookContainer(workBookContainer);
    if (!workBook) {
      return {
        success: false,
        reason: Helpers.GenericParsingError,
      };
    }

    const workSheet = await Helpers.getDistributionRackShippingWorkSheet(workBook);
    if (!workSheet) {
      return {
        success: false,
        reason: Helpers.GenericParsingError,
      };
    }

    const workSheetData = await Helpers.getDistributionRackShippingWorkSheetData(workSheet);
    if (workSheetData.length === 0 || Object.keys(workSheetData[0]).length < 1) {
      return {
        success: false,
        reason: Helpers.GenericParsingError,
      };
    }

    const lineItemsResult = await Helpers.getLineItems(workSheetData, storeIdentifiersInRoutePlan);

    if (!lineItemsResult.success) {
      return {
        success: false,
        reason: lineItemsResult.reason,
      };
    }

    const lineItems = lineItemsResult.lineItems;

    if (lineItems.length === 0) {
      return {
        success: false,
        reason: Helpers.GenericParsingError,
      };
    }

    return {
      success: true,
      parsed: {
        lineItems,
      },
    };
  } catch (error) {
    if (isErrorWithData(error)) {
      return {
        success: false,
        reason: (error as ErrorWithData).data<string[]>(),
      };
    }

    return {
      success: false,
      reason: error,
    };
  }
}
