import * as React from 'react';
import { RoutingLoadGQLResult, RoutingLoadTotalFee } from 'schema/routing-load/routing-load-graphql-types';
import { StopsCard } from 'client/app/transportation/loads/stats-row/cards/stops-card';
import { RacksCard } from 'client/app/transportation/loads/stats-row/cards/racks-card';
import { PalletsCard } from 'client/app/transportation/loads/stats-row/cards/pallets-card';
import { TotalMilesCard } from 'client/app/transportation/loads/stats-row/cards/total-miles-card';
import { TotalCostCard } from 'client/app/transportation/loads/stats-row/cards/total-cost-card';

export const StatsRow = (p: { children?}) => <div className="stats-row">{p.children}</div>;

interface OwnProps {
  dropsCombinedWithAdditionalDrops?: RoutingLoadGQLResult['dropsCombinedWithAdditionalDrops'];
  racks?: RoutingLoadGQLResult['racks'];
  pallets?: RoutingLoadGQLResult['pallets'];
  milesCombinedWithAdditionalMiles: RoutingLoadGQLResult['milesCombinedWithAdditionalMiles'];
  totalFee?: RoutingLoadTotalFee;
}

export const LoadDetailsStatsRowUI = (props: OwnProps) => {
  return (
    <StatsRow>
      <StopsCard stops={props.dropsCombinedWithAdditionalDrops} />
      <RacksCard racks={props.racks} />
      <PalletsCard pallets={props.pallets} />
      <TotalMilesCard totalMiles={props.milesCombinedWithAdditionalMiles} />
      <TotalCostCard totalCost={props.totalFee} />
    </StatsRow>
  );
};
