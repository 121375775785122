import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { OrderListPage } from 'client/components/order-list-page';
import { IRecord } from 'shared/schemas/record';
import { OnRowSelect } from 'client/components/table/table-ui';
import dataContainer from 'client/hoc/data-container';
import { clearStore } from 'client/hoc/clear-store';
import { getTable } from 'shared/schemas/dsl';
import { tableParentHoc } from 'client/components/table/table-parent';
import * as Actions from 'client/actions/table';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { generateCustomerOrderDetailsMutation, GenerateCustomerOrderDetailsInput, GenerateCustomerOrderDetailsResponse } from 'client/app/orders/customer-orders/overview/mutations';
import { generateSupplierOrderDetailsMutation, GenerateSupplierOrderDetailsInput, GenerateSupplierOrderDetailsResponse } from 'client/app/orders/supplier-orders/overview/mutations';
import { ButtonClickHandler } from 'client/types';
import { Thunker } from 'client/types/redux-types';
import { buildTableStateModule } from 'client/state/tables';
import * as State from 'client/state/state';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps { }

interface DispatchProps {
  onNewClicked: ButtonClickHandler;
  onRowSelect: OnRowSelect;
}

interface StateProps {
  schema: string;
  title?: string;
}

function getCustomerOrderDetailsMutation(dispatch, customerOrderIds: number[]) {
  return msyncClientMutation<GenerateCustomerOrderDetailsResponse, GenerateCustomerOrderDetailsInput>({
    dispatch,
    mutation: generateCustomerOrderDetailsMutation,
    variables: {
      customerOrderIds,
    },
    showBusyModal: true,
  });
}

function getSupplierOrderDetailsMutation(dispatch, supplierOrderIds: number[]) {
  return msyncClientMutation<GenerateSupplierOrderDetailsResponse, GenerateSupplierOrderDetailsInput>({
    dispatch,
    mutation: generateSupplierOrderDetailsMutation,
    variables: {
      supplierOrderIds,
    },
    showBusyModal: true,
  });
}

function buildAdminListPage<T>(schema: Constructor<T>, config?: {
  title?: string,
  columns?: Array<keyof T>,
  excelColumns?: string[];
  filters?: Array<keyof T>,
  onRowSelect?: any,
}) {
  if (!getTable(schema)) {
    console.error('Error: No table found for schema', schema);
  }
  const schemaName = getTable(schema) || 'unknown';

  const generateOrderDetails = (orderIds: number[]): Thunker => {
    // Doing this check isn't great, but this container is used by both list pages and was the easiest way to make it work.
    if (schemaName !== 'customerOrders' && schemaName !== 'supplierOrders') {
      throw new Error(`Can only generate an order detail export for customer or supplier orders, but found this type of order: ${schemaName}`);
    }

    return async dispatch => {
      const response = schemaName === 'customerOrders'
        ? await getCustomerOrderDetailsMutation(dispatch, orderIds)
        : await getSupplierOrderDetailsMutation(dispatch, orderIds);

      if (response) {
        const reportId = response.data.response.report.id;
        window.location.replace(`/report/fileDownload/${reportId}`);
      }
    };
   };

  const TableStateHelpers = buildTableStateModule(schemaName);

  const mapStateToProps = (state: State.Type) => {
    const tableState = TableStateHelpers.tableStateLens.get(state);
    return {
      schema: schemaName,
      title: config && config.title ? config.title : undefined,
      orderToDelete: tableState.orderToDelete,
      showConfirmationModal: tableState.showConfirmationModal,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    const pattern = /(.+)\/(list)?/;
    const matches = `${ownProps.location.pathname}`.match(pattern);
    const listPageRoute = matches && matches[1] || `/admin/${schemaName}`;
    return {
      onNewClicked: () => dispatch(push(`${listPageRoute}/create`)),
      onRowSelect: (record: IRecord) => {
        dispatch(() => {
          dispatch(push(`${listPageRoute}/details/${record.id}`));
          if (config && config.onRowSelect) {
            config.onRowSelect(dispatch, record.id);
          }
        });
      },
      handleCancelButtonClicked: () => {
        dispatch(Actions.closeOrderDeleteConfirmationModal(schemaName));
      },
      deleteMenuItemClicked: id => {
        dispatch(Actions.openOrderDeleteConfirmationModal(schemaName, id));
      },
      downloadOrderDetailsMenuItemClicked: id => {
        dispatch(generateOrderDetails([id]));
      },
      downloadOrderDetailsHeaderMenuItemClicked: ids => {
        dispatch(generateOrderDetails(ids));
      },
    };
  };

  return flowRight(
    clearStore,
    tableParentHoc(),
    dataContainer({
      table: schemaName,
      columns: config ? config.columns : undefined,
      excelColumns: config ? config.excelColumns : undefined,
      filters: config ? config.filters : undefined,
     }),
    connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  )(OrderListPage);
}

export default buildAdminListPage;
