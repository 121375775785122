// 1-based index to align with Excel
export const CUSTOMER_IDENTIFIER_ROW = 1;
export const CUSTOMER_IDENTIFIER_COL = 'A';

export const WEEK_ENDING_DATE_ROW = 1;
export const WEEK_ENDING_DATE_COL = 'C';

export const STORE_NUMBER_COL = 'A';

export const PRODUCT_SKU_ROW = 3;
export const PRODUCT_DESCRIPTION_ROW = 4;

export const FIRST_TOSS_RECORD_ROW = 6;
