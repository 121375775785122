import * as _ from 'lodash';
import * as BS from 'client/components/third-party';
import * as ClientTypes from 'client/types';
import * as Components from '../components';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as React from 'react';
import * as RecordBar from 'client/components/record-bar/record-bar-back-back-button-and-title';
import * as ReportUserParamTypes from 'client/components/report-user-params/types';
import * as SelectableTypes from 'client/components/selectable/types';
import * as SharedTypes from 'shared/types';
import { SalesPlanIdentifier, SelectableValue } from 'shared/types';

export interface ComponentProps {
  customerId: number | undefined;
  customerOptions: ClientTypes.DropDownOptions;
  customersLoading: boolean;
  downloadButtonDisabled: boolean;
  handleCustomerChanged: (customerId: number) => void;
  handleDownloadExcelReportClicked(): void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (ids: number[]) => void;
  handleProductSubClassesFilterChanged: (ids: number[]) => void;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => void;
  mapFromReportUserParams: ReportUserParamTypes.MapFromReportUserParams;
  mapToReportUserParams: ReportUserParamTypes.MapToReportUserParams;
  pristine: boolean;
  productClassesLoading?: boolean;
  productClassIds: SelectableValue | undefined;
  productClassOptions?: SelectableTypes.SelectableRow[];
  productIds: SelectableValue | undefined;
  productOptions?: SelectableTypes.SelectableRow[];
  productsLoading?: boolean;
  productSubClassesLoading?: boolean;
  productSubClassIds: SelectableValue | undefined;
  productSubClassOptions?: SelectableTypes.SelectableRow[];
  reportDownloadStatus: Mutations.MutationStatus;
  scanProductOptions: boolean[] | undefined;
  sellDepartmentId: number | undefined;
  sellDepartmentOptions?: ClientTypes.DropDownOptions;
  sellDepartmentsLoading?: boolean;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class SupplierFutureCommitmentReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar.RecordBar title="Supplier Future Commitment Report" hideBackButton/>
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-master-bol-table-wrapper mfc-scrolling-region-adjacent-to-sidebar">
            <BS.Col sm={6}>
              <BS.Form horizontal>
                <BS.Row className="report-fields-outside-tabs">
                  <BS.FormGroup>
                    <Components.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                      handleChange={this.props.handleCustomerChanged}
                      colSize={8}
                    />
                  </BS.FormGroup>
                  <BS.FormGroup>
                    <Components.Season
                      name={Constants.FormFields.season}
                      options={_.values(SalesPlanIdentifier).map(spi => ({id: spi, value: spi, identifier: spi}))}
                      colSize={8}
                    />
                  </BS.FormGroup>
                  <BS.FormGroup>
                    <Components.YearBeginEndMonths />
                  </BS.FormGroup>
                </BS.Row>
                <BS.Row className="report-fields-outside-tabs">
                </BS.Row>
              </BS.Form>
            </BS.Col>
            <BS.Col sm={6}>
            <BS.Tabs className="report-tabs" id="product-tabs">
              <BS.Tab disabled={true} title="Products">
                <BS.FormGroup>
                  <Components.SellDepartment
                    name={Constants.FormFields.sellDepartmentId}
                    options={this.props.sellDepartmentOptions}
                    handleChange={this.props.handleSellDepartmentChanged}
                  />
                </BS.FormGroup>
                <BS.FormGroup className="report-product-order-type-area">
                  <label className="report-scan-or-po-based-label">Product Order Type</label>
                  <div>
                    <Components.ScanBased
                      name={Constants.FormFields.scanBased}
                      handleChange={this.props.handleScanBasedFilterChanged}
                    />
                    <Components.PoBased
                      name={Constants.FormFields.poBased}
                      handleChange={this.props.handlePOBasedFilterChanged}
                    />
                  </div>
                </BS.FormGroup>

                <BS.FormGroup>
                  <Components.ProductClass
                    name={Constants.FormFields.productClassIds}
                    options={this.props.productClassOptions}
                    loading={this.props.productClassesLoading}
                    handleChange={this.props.handleProductClassesFilterChanged}
                  />
                </BS.FormGroup>
                <BS.FormGroup>
                  <Components.ProductSubClass
                    name={Constants.FormFields.productSubClassIds}
                    options={this.props.productSubClassOptions}
                    loading={this.props.productSubClassesLoading}
                    handleChange={this.props.handleProductSubClassesFilterChanged}
                  />
                </BS.FormGroup>
                <BS.FormGroup>
                  <Components.Product
                    name={Constants.FormFields.productIds}
                    options={this.props.productOptions}
                    loading={this.props.productsLoading}
                  />
                </BS.FormGroup>
              </BS.Tab>
            </BS.Tabs>
            </BS.Col>
          </div>
          <Components.Sidebar
            reportType={SharedTypes.ReportTypes.SupplierFutureCommitmentReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          >
          </Components.Sidebar>
        </div>
      </div>
    );
  }
}
