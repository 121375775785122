import * as _ from 'lodash';

// NOTE: This is a total violation of everything that React stands for. Due to some extreme caching of columns
//       (to prevent expensive re-rendering) they don't get updated once they're initially passed down. This might
//       not be the ideal way to handle columns and re-rendering, but it's how it works right now. However, the
//       current architecture has some functions being made available to the table/cells via the columns (onSave,
//       handling for the kebab menus, refetchStats,  possibly others) - so if those functions are updated they
//       never make it into the latest update of the table.
//
//       To get around this, we're going to prop down handlers that refer to a mutable function holder. The cached
//       functions will pull what they need out of this holder when they need it. If the functions being held
//       in the holder are changed, the cached handlers will automatically get the updated functions. This is totally
//       cheating and bastardizing the idea of pure state in React. But it's going to fix some issues with the
//       refetchStats not working without having to rewrite everything right now.
//
//       Be careful with using this. Other than the obvious breach of "how things are supposed to work", it could
//       be possible to have two instances of the same component both sharing the same holder if you're not careful.

export type FunctionHolder<FuncName extends string> = {
  [K in FuncName]: (args?: any) => Promise<any>
} & {
  update: ((fn: (args?: any) => Promise<any>) => typeof fn),
};

export const buildFunctionHolder = <FuncName extends string>(funcName: FuncName): FunctionHolder<FuncName> => {
  const holder = { [funcName]: undefined } as any;
  const stableFunctionReference = async (args?: any) => (holder[funcName] || _.noop)(args);
  return {
    update: (fn: (args?: any) => Promise<any>) => { holder[funcName] = fn; return stableFunctionReference; },
    ...{[funcName]: stableFunctionReference} as shame, // TODO: probably fixable the obvious way in newer TS versions
  };
};
