import * as _ from 'lodash';
import { Arg2, Arg3 } from './meta';

const jsonStringify = (typeof JSON !== 'undefined' ? JSON : require('jsonify')).stringify;

const callBind = <F extends ((...args: any[]) => any)> (fn: F) => (thisArg: ThisParameterType<F>, ...args: Parameters<F>) => fn.call(thisArg, ...args);

const defaultReplacer = <T>(_parent: T, key: keyof T, value: T[typeof key]) => value;

type NodeBasis = {} | 'late-bound';
type NodeAccessor<Node extends NodeBasis = 'late-bound'>
    = Node extends 'late-bound'
    ? <N extends {}>(k: keyof N) => N[typeof k]
    : (k: keyof Node) => Node[typeof k];

type NodeAccessorOverriden = { __proto__: null, get: NodeAccessor };
type NodeKeyConstraint<Node extends NodeBasis> = (Node extends 'late-bound' ? string | number : keyof Node);
type Comparer = <Node extends NodeBasis, K extends NodeKeyConstraint<Node>>(a: { key: K, value: Node[K extends keyof Node ? K : keyof Node] }, b: { key: K, value: Node[K extends keyof Node ? K : keyof Node] }, get?: NodeAccessorOverriden) => number;
type Replacer = Arg2<typeof JSON.stringify> extends (a: any, b: any) => any ? Arg2<typeof JSON.stringify> : never;
type OptsObj = {
    space?: Arg3<typeof JSON.stringify>,
    cycles?: boolean,
    replacer?: Replacer,
    cmp?: Comparer,
};

/** TODO: also look at this: https://stackoverflow.com/a/53593328 */
export const serialize = <TRoot>(obj: TRoot, opts?: OptsObj | Comparer) => {
    const space
        = typeof opts !== 'object'          ? ''
        : typeof opts?.space === 'number'   ? _.repeat(' ', opts.space)
        :                                     opts?.space || '';

    const cycles = true === (opts as shame<'I should be allowed CONDITIOANLLY to check for a property on a value that MIGHT be an object-ish thing.'>)?.cycles;
    const replacer = (opts as shame)?.replacer ? callBind((opts as shame)!.replacer!) : defaultReplacer;
    const cmpOpt = typeof opts === 'function' ? opts : opts?.cmp;
    const cmp = !cmpOpt ? undefined : node => {
        const get = cmpOpt.length > 2 && function get(k) { return node[k]; };
        return <Node extends NodeBasis, K extends NodeKeyConstraint<Node>>(a: K, b: K) => cmpOpt<Node, K>(
            { key: a, value: node[a] },
            { key: b, value: node[b] },
            !!get ? { __proto__: null, get } : void undefined
        );
    };

    const seen = [] as any[];
    return (function stringify(parent: any, key: string | number, n: any, level: number) {
        let node = n;
        const indent = space ? '\n' + _.repeat(space, level) : '';
        const colonSeparator = space ? ': ' : ':';
        if (node && node.toJSON && typeof node.toJSON === 'function')
            node = node.toJSON();

        node = replacer(parent, key, node);
        if (node === undefined)
            return;

        if (typeof node !== 'object' || node === null)
            return jsonStringify(node);

        if (_.isArray(node)) {
            const out = [] as any[];
            for (let i = 0; i < node.length; i++) {
                const item = stringify(node, i, node[i], level + 1) || jsonStringify(null);
                out.push(indent + space + item);
            }

            return '[' + out.join(',') + indent + ']';
        }

        if (seen.indexOf(node) !== -1) {
            if (cycles)
                return jsonStringify('__cycle__');

            throw new TypeError('Converting circular structure to JSON');
        } else
            seen.push(node);

        const out = [] as string[];
        for (const k of _.keys(node).sort(cmp && cmp(node))) {
            const value = stringify(node, k, node[k], level + 1);
            if (!value)
                continue;

            const keyValue = jsonStringify(k)
                + colonSeparator
                + value;

            out.push(indent + space + keyValue);
        }

        seen.splice(seen.indexOf(node), 1);
        return '{' + out.join(',') + indent + '}';

    }({ '': obj }, '', obj, 0));
};
