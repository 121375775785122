import * as React from 'react';
import { ImmutableDateRange, INPUT_TYPES } from 'shared/types';
import { Col, Row } from 'client/components/third-party';
import { SimpleInput } from 'client/components/simple-components/simple-input';
import { SimpleRadio } from 'client/components/simple-components/simple-radio';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import * as _ from 'lodash';
import * as DateHelpers from 'shared/helpers/date-helpers';
import * as BS from 'client/components/third-party';
import LabeledDateRange from './form/labeled-date-range';
import * as classnames from 'classnames';

interface DateRangeYearComparisonProps {
  handleChange(value: DateHelpers.DateRangeYearComparisonValue): void;
  value: DateHelpers.DateRangeYearComparisonValue | undefined;
  testid: string;
  lastYearComparisonDisabled?: boolean;
  lastYearRequired?: boolean;
}

interface DateRanges {
  dateRange: ImmutableDateRange | null | undefined;
  lastYearDateRange: ImmutableDateRange | null | undefined;
}

const defaultValue: DateHelpers.DateRangeYearComparisonValue = {
  pickRangeBy: DateHelpers.PickRangeBy.Dates,
  compareToPreviousYear: false,
  rangeByDates: {
    dateRangeBeginDate: undefined,
    dateRangeEndDate: undefined,
    lastYearDateRangeBeginDate: undefined,
    lastYearDateRangeEndDate: undefined,
    lastYearDateRangeType: DateHelpers.DateRangeType.DayOfWeek,
  },
  rangeByWeek: {
    year: null,
    beginWeek: null,
    endWeek: null,
    lastYear: null,
    lastYearBeginWeek: null,
    lastYearEndWeek: null,
  },
};

export class DateRangeYearComparison extends React.PureComponent<DateRangeYearComparisonProps> {
  pickDatesByRadioChanged = (pickRangeBy: DateHelpers.PickRangeBy) => {
    const currentValue = this.props.value || defaultValue;

    const rangeByDates = this.determineDateRanges({
      ...currentValue,
      pickRangeBy,
    }, false);

    this.props.handleChange({
      ...currentValue,
      pickRangeBy,
      rangeByDates,
    });
  }

  dateRangeTypeChanged = (lastYearDateRangeType: DateHelpers.DateRangeType) => {
    const currentValue = this.props.value || defaultValue;

    const rangeByDates = this.determineDateRanges({
      ...currentValue,
      rangeByDates: {
        ...currentValue.rangeByDates,
        lastYearDateRangeType,
      },
    }, false);

    this.props.handleChange({
      ...currentValue,
      rangeByDates,
    });
  }

  compareToPreviousYearChanged = (compareToPreviousYear: boolean) => {
    const currentValue = this.props.value || defaultValue;

    const rangeByDates = this.determineDateRanges({
      ...currentValue,
      compareToPreviousYear,
    }, false);

    this.props.handleChange({
      ...currentValue,
      compareToPreviousYear,
      rangeByDates,
    });
  }

  rangeByWeekChanged = (field: keyof DateHelpers.RangeByWeek, value: string) => {
    const currentValue = this.props.value || defaultValue;

    const parsedValue = Number.parseInt(value);
    const updateValue = Number.isNaN(parsedValue) ? null : parsedValue;

    this.props.handleChange({
      ...currentValue,
      rangeByWeek: {
        ...currentValue.rangeByWeek,
        [field]: updateValue,
      },
    });
  }

  rangeByWeekYearChanged = (value: string) => this.rangeByWeekChanged('year', value);
  rangeByWeekBeginWeekChanged = (value: string) => this.rangeByWeekChanged('beginWeek', value);
  rangeByWeekEndWeekChanged = (value: string) => this.rangeByWeekChanged('endWeek', value);
  rangeByWeekLastYearChanged = (value: string) => this.rangeByWeekChanged('lastYear', value);
  rangeByWeekLastYearBeginWeekChanged = (value: string) => this.rangeByWeekChanged('lastYearBeginWeek', value);
  rangeByWeekLastYearEndWeekChanged = (value: string) => this.rangeByWeekChanged('lastYearEndWeek', value);

  rangeByDatesChanged = (field: 'dateRange' | 'lastYearDateRange', value: ImmutableDateRange) => {
    const currentValue = this.props.value || defaultValue;

    if (field === 'dateRange') {
      const rangeByDates = this.determineDateRanges({
        ...currentValue,
        rangeByDates: {
          ...currentValue.rangeByDates,
          dateRangeBeginDate: value.startDate,
          dateRangeEndDate: value.endDate,
        },
      }, false);

      this.props.handleChange({
        ...currentValue,
        rangeByDates,
      });
    } else if (field === 'lastYearDateRange') {
      this.props.handleChange({
        ...currentValue,
        rangeByDates: {
          ...currentValue.rangeByDates,
          lastYearDateRangeBeginDate: value.startDate,
          lastYearDateRangeEndDate: value.endDate,
        },
      });
    }
  }

  rangeByDatesDateRangeChanged = (dateRange: ImmutableDateRange) => this.rangeByDatesChanged('dateRange', dateRange);
  rangeByDatesLastYearDateRangeChanged = (dateRange: ImmutableDateRange) => this.rangeByDatesChanged('lastYearDateRange', dateRange);

  private determineDateRanges(value: DateHelpers.DateRangeYearComparisonValue, changingLastYearDate: boolean) {
    let dateRanges: DateRanges | undefined;

    if (value.pickRangeBy === DateHelpers.PickRangeBy.Dates) {
      dateRanges = DateHelpers.getDateRangeForRangeByDates(value.compareToPreviousYear, value.rangeByDates, changingLastYearDate);
    } else if (value.pickRangeBy === DateHelpers.PickRangeBy.Week) {
      dateRanges = DateHelpers.getDateRangeForRangeByWeek(value.compareToPreviousYear, value.rangeByWeek);
    }

    if (!dateRanges) {
      throw new Error('An unknown error has occured');
    }

    // We always want to update the rangeByDates values
    // In the case of range by date, we do this to also update the last year date range
    // In the case of range by week, we do this so the user can see the date range in the disabled field above
    return {
      dateRangeBeginDate: !_.isNil(dateRanges.dateRange) ? dateRanges.dateRange.startDate : undefined,
      dateRangeEndDate: !_.isNil(dateRanges.dateRange) ? dateRanges.dateRange.endDate : undefined,
      lastYearDateRangeBeginDate: !_.isNil(dateRanges.lastYearDateRange) ? dateRanges.lastYearDateRange.startDate : undefined,
      lastYearDateRangeEndDate: !_.isNil(dateRanges.lastYearDateRange) ? dateRanges.lastYearDateRange.endDate : undefined,
      lastYearDateRangeType: value.rangeByDates.lastYearDateRangeType,
    };
  }

  render() {
    const currentValue = this.props.value || defaultValue;

    const pickRangeByDatesDisabled = currentValue.pickRangeBy === DateHelpers.PickRangeBy.Week;
    const pickRangeByWeekDisabled = currentValue.pickRangeBy === DateHelpers.PickRangeBy.Dates;
    const compareToPreviousYearChecked = (currentValue.compareToPreviousYear && !this.props.lastYearComparisonDisabled) || !!this.props.lastYearRequired;
    if (compareToPreviousYearChecked !== currentValue.compareToPreviousYear) {
      this.compareToPreviousYearChanged(compareToPreviousYearChecked); // will this cause infinite re-renders?  dunno
    }

    const previousYearWidgetDisabled = !compareToPreviousYearChecked || this.props.lastYearComparisonDisabled;

    return (
      <BS.Tabs className="report-tabs" id="date-range-tabs">
        <BS.Tab disabled={true} title="Date Ranges">
          <div className="date-range-year-comparison">
            <Row>
              <Col sm={4}>
              </Col>
              <Col sm={8} className="compare-to-previous-year-container">
              {!this.props.lastYearRequired &&
                <SimpleCheckbox
                  label="Compare to Previous Year"
                  onChange={this.compareToPreviousYearChanged}
                  testid={`${this.props.testid}-compare-to-previous-year`}
                  value={compareToPreviousYearChecked}
                  disabled={this.props.lastYearComparisonDisabled}
                />}
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <SimpleRadio
                  label="Pick Range by Dates"
                  onChange={this.pickDatesByRadioChanged}
                  optionValue={DateHelpers.PickRangeBy.Dates}
                  testid={`${this.props.testid}-pick-range-by-dates`}
                  value={currentValue.pickRangeBy}
                />
              </Col>
              <Col sm={4}>
              </Col>
              <Col sm={4} className="label-column">
                <label className={classnames({ disabled: pickRangeByDatesDisabled || previousYearWidgetDisabled })}>Last Year Date Range Type</label>
              </Col>
            </Row>
            <Row>
              <Col sm={4} className="date-range-year-comparison-this-year-container">
                <LabeledDateRange
                  beginDate={currentValue.rangeByDates.dateRangeBeginDate}
                  disabled={pickRangeByDatesDisabled}
                  endDate={currentValue.rangeByDates.dateRangeEndDate}
                  onChange={this.rangeByDatesDateRangeChanged}
                  label={'Date Range'}
                  testid={`${this.props.testid}-compare-to-previous-year`}
                  offset={0}
                  labelColSize={12}
                  inputColSize={12}
                  horizontalLabel
                  className="date-range-year-comparison-date-range"
                />
              </Col>
              <Col sm={4} className="date-range-year-comparison-last-year-container">
                <LabeledDateRange
                  beginDate={currentValue.rangeByDates.lastYearDateRangeBeginDate}
                  disabled={pickRangeByDatesDisabled || previousYearWidgetDisabled}
                  endDate={currentValue.rangeByDates.lastYearDateRangeEndDate}
                  onChange={this.rangeByDatesLastYearDateRangeChanged}
                  label={'Last Year Date Range'}
                  testid={`${this.props.testid}-last-year-date-range`}
                  offset={0}
                  labelColSize={12}
                  inputColSize={12}
                  horizontalLabel
                  className="date-range-year-comparison-date-range-last-year"
                />
              </Col>
              <Col sm={4} className="last-year-date-range-type-container">
                <Row>
                  <SimpleRadio
                    disabled={pickRangeByDatesDisabled || previousYearWidgetDisabled}
                    label="Day of Week"
                    onChange={this.dateRangeTypeChanged}
                    optionValue={DateHelpers.DateRangeType.DayOfWeek}
                    testid={`${this.props.testid}-last-year-date-range-type-day-of-week`}
                    value={currentValue.rangeByDates.lastYearDateRangeType}
                    offset={1}
                  />
                </Row>
                <Row>
                  <SimpleRadio
                    disabled={pickRangeByDatesDisabled || previousYearWidgetDisabled}
                    label="Actual Date"
                    onChange={this.dateRangeTypeChanged}
                    optionValue={DateHelpers.DateRangeType.ActualDate}
                    testid={`${this.props.testid}-last-year-date-range-type-actual-date`}
                    value={currentValue.rangeByDates.lastYearDateRangeType}
                    offset={1}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <SimpleRadio
                  label="Pick Range by Week #"
                  onChange={this.pickDatesByRadioChanged}
                  optionValue={DateHelpers.PickRangeBy.Week}
                  testid={`${this.props.testid}-pick-range-by-week-number`}
                  value={currentValue.pickRangeBy}
                  className="pick-range-by-week-number-week"
                />
              </Col>
            </Row>
            <Row className="pick-range-by-week-row">
              <div className="date-input-container">
                <SimpleInput
                  disabled={pickRangeByWeekDisabled}
                  horizontalLabel
                  inputColSize={2}
                  label="Year"
                  labelColSize={2}
                  onChange={this.rangeByWeekYearChanged}
                  testid={`${this.props.testid}-pick-range-by-week-number-year`}
                  value={currentValue.rangeByWeek.year || ''}
                  type={INPUT_TYPES.NUMBER}
                  min={2000}
                  max={2200}
                />
              </div>
              <SimpleInput
                disabled={pickRangeByWeekDisabled || previousYearWidgetDisabled}
                horizontalLabel
                inputColSize={2}
                label="Year"
                labelColSize={2}
                onChange={this.rangeByWeekLastYearChanged}
                testid={`${this.props.testid}-pick-range-by-week-number-last-year`}
                value={currentValue.rangeByWeek.lastYear || ''}
                type={INPUT_TYPES.NUMBER}
                min={2000}
                max={2200}
              />
            </Row>
            <Row className="pick-range-by-week-row">
              <div className="date-input-container">
                <SimpleInput
                  disabled={pickRangeByWeekDisabled}
                  horizontalLabel
                  inputColSize={2}
                  label="Begin Week"
                  labelColSize={2}
                  onChange={this.rangeByWeekBeginWeekChanged}
                  testid={`${this.props.testid}-pick-range-by-week-number-begin-week`}
                  value={currentValue.rangeByWeek.beginWeek || ''}
                  type={INPUT_TYPES.NUMBER}
                  min={1}
                  max={53}
                />
              </div>
              <SimpleInput
                disabled={pickRangeByWeekDisabled || previousYearWidgetDisabled}
                horizontalLabel
                inputColSize={2}
                label="Begin Week"
                labelColSize={2}
                onChange={this.rangeByWeekLastYearBeginWeekChanged}
                testid={`${this.props.testid}-pick-range-by-week-number-last-year-begin-week`}
                value={currentValue.rangeByWeek.lastYearBeginWeek || ''}
                type={INPUT_TYPES.NUMBER}
                min={1}
                max={53}
              />
            </Row>
            <Row className="pick-range-by-week-row">
              <div className="date-input-container">
                <SimpleInput
                  disabled={pickRangeByWeekDisabled}
                  horizontalLabel
                  inputColSize={2}
                  label="End Week"
                  labelColSize={2}
                  onChange={this.rangeByWeekEndWeekChanged}
                  testid={`${this.props.testid}-pick-range-by-week-number-end-week`}
                  value={currentValue.rangeByWeek.endWeek || ''}
                  type={INPUT_TYPES.NUMBER}
                  min={1}
                  max={53}
                />
              </div>
              <SimpleInput
                disabled={pickRangeByWeekDisabled || previousYearWidgetDisabled}
                horizontalLabel
                inputColSize={2}
                label="End Week"
                labelColSize={2}
                onChange={this.rangeByWeekLastYearEndWeekChanged}
                testid={`${this.props.testid}-pick-range-by-week-number-last-year-end-week`}
                value={currentValue.rangeByWeek.lastYearEndWeek || ''}
                type={INPUT_TYPES.NUMBER}
                min={1}
                max={53}
              />
            </Row>
          </div>
        </BS.Tab>
      </BS.Tabs>
    );
  }
}
