import { tableName, property, definePresentation, belongsTo, setSchemaOptions, required } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { SupplierOrder } from 'shared/schemas/supplier-order';

@tableName('relatedOrders')
export class RelatedOrder implements IRecord {
  id?: number;

  @belongsTo('customerOrders')
  @property @required customerOrder: CustomerOrder;
  customerOrderId: number;

  @belongsTo('supplierOrders')
  @property @required supplierOrder: SupplierOrder;
  supplierOrderId: number;
}

definePresentation(RelatedOrder, {
  customerOrder: {
    formDisplay: false,
  },
  supplierOrder: {
    formDisplay: false,
  },
});

setSchemaOptions(RelatedOrder, {
  hasTimestamps: false,
  joins: ['customerOrder', 'supplierOrder'],
});
