import * as classNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import * as StatsApi from 'shared/stats-card-api';
import { formatNumber } from 'shared/types';

export function StatsCard(props: { cardContent: StatsApi.Card }) {
  const { cardContent } = props;
  const format = cardContent.format;
  const formatStatValue = (v: number | undefined) => !v ? '--' : format ? formatNumber(v, format.precision, format.thousand, format.symbol) : v;
  const findIcon = (statScope: string) => {
    return classNames('fa', {
      'fa-building': statScope === 'store_id',
      'fa-leaf': statScope === 'product_id',
      'fa-question-circle' : !statScope,
    });
  };

  const [totalStats, ...scopedStats] = cardContent.stats;

  const scopedStatsElements = scopedStats.map(stat => {
    return (
      <div className="stat-card" key={stat.scope}>
        <span className={findIcon(stat.scope)} />
        <span className="stat-card-left">{stat.description}</span>
        <span className="stat-card-right">{formatStatValue(stat.value)}</span>
      </div>
    );
  });
  return (
    <div className="card-pf card-pf-accented">
      <div className="card-pf-title" data-testid="stats-card-title"> {`${_.upperFirst(totalStats.scope)} ${_.upperFirst(cardContent.name)}`} </div>
      <div className="card-pf-body" data-testid="stats-card-body"> {formatStatValue(totalStats.value)} </div>
      {scopedStatsElements}
    </div>
  );
}
