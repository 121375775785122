import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';

import Table from './suppliers-table';
import { propToComponent } from 'client/hoc/hoc';
import { CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME } from 'client/constants';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableSearchField, AvailableFilter, SchemaActiveFilter, ActiveSearch, ActiveFilter } from 'client/types';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { CartTrackingSuppliersQueryResponse, CartTrackingSuppliersQuery } from './cart-tracking-suppliers-query';
import { DateStr } from 'shared/types';
import { withRecordFilterOptions } from 'client/hoc/graphql/record-filter-options';
import { withDefaultFilters } from 'client/hoc/default-filters';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { push } from 'connected-react-router';
import { buildTableStateModule } from 'client/state/tables';

const tableName = CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME;
interface OwnProps {
  tableName: string;
  dataRequest: MsyncDataRequest;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

interface WithAvailableFiltersProps {
  availableFilters: AvailableFilter[];
}
const withFilterOptions = WrappedComponent => {
  const filters = ['identifier', 'cartTrackingStatus'];
  return withRecordFilterOptions({ table: 'suppliers', fields: filters })(WrappedComponent);
};

interface WithSearchableFieldsProps {
  searchableFields: AvailableSearchField[];
}
const withSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [{ id: 'identifier', name: 'Supplier ID' }],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface DispatchProps {
  onRowSelect(record): void;
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    onRowSelect(record) {
      dispatch(push(`/transportation/cartTracking/details/${record.id}`));
    },
  };
};

export interface Row {
  id: number;
  identifier: string;
  name: string;
  totalCards?: number;
  cartTrackingStatus: SupplierCartTrackingStatus;
  lastAdjustmentDate?: DateStr;
}

interface WithRowProps {
  rows?: Row[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithCartTrackingSupplierRows = msyncQuery<CartTrackingSuppliersQueryResponse, OwnProps & StateProps & WithTableParentProps, WithRowProps>(CartTrackingSuppliersQuery, {
  alias: 'withCartTrackingSupplierRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps): { variables } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: ownProps.activeSearch,
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
    };
  },
  props(props): WithRowProps {
    const { data } = props;
    const { ownProps } = props as any as { ownProps: StateProps & WithTableParentProps }; // :-/

    // TODO: reselect

    const filters = _.flatten(ownProps.activeFilters.map(filter => filter.values)).join(', ');
    const workbookName = `CartTracking${filters ? ` - ${filters}` : ''}`;

    return {
      rows: data.response ? data.response.records : [],
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.response ? data.response.ids : [],
      totalCount: data.response ? data.response.totalCount : 0,
      totalUnfilteredCount: data.response ? data.response.totalUnfilteredCount : 0,
      dataRequest: {
        operationName: '',
        query: CartTrackingSuppliersQuery,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: ownProps.activeSearch,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        workbookName,
      },
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

function getDefaultFilters(today): Array<SchemaActiveFilter<shame>> {
  return [
    {
      field: 'cartTrackingStatus',
      values: [SupplierCartTrackingStatus.Track],
    },
  ];
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithAvailableFiltersProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  withSearchableFields,
  withFilterOptions,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  tableParentHoc(),
  withFilterAndSortCapabilities(tableName),
  WithCartTrackingSupplierRows,
  withDefaultFilters<shame>(tableName, getDefaultFilters),
)(Table) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
