import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import AddNewProductModal from './product-modal';
import * as Actions from 'client/actions/product-worksheet';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import gql from 'graphql-tag';
import { AddProductsToCustomerOrderPayload, ProductShipmentConfiguration, WithProductsProps } from 'client/types/product-worksheet';
import * as Immutable from 'immutable';
import { ComboCart, OrderMethod, ShippingUnitOrderMethod, ActiveInactive } from 'shared/types';
import { ProductListResponseItem } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-container';
import { ApolloRefetch } from 'client/types';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { Saved } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import * as _ from 'lodash';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { CustomerOrderProductId } from 'shared/schemas/customer-order-product';
import { ThunkerDispatch } from 'client/types/redux-types';
import { wrapComponent } from 'client/hoc/hoc';

const formName = 'add-new-product';

export interface OwnProps {
  customerOrderId: number;
  customerOrder: Pick<Saved<CustomerOrder>, 'id' | 'customer' | 'orderMethod' | 'shippingUnitType'| 'sellDepartment' | 'orderStatus' | 'scanBased' | 'customerId'>;
  refreshProductsList: ApolloRefetch;
  worksheetStatsRefetch: ApolloRefetch | undefined;
  productListResponseItems: ProductListResponseItem[];
  regularCartList: ProductShipmentConfiguration[];
  comboCartList: ComboCart[];
  productIds?: Immutable.Set<number>;
  confirmOkToSave: () => Promise<boolean>;
}

export interface StateProps {
  isShown: boolean;
  isProductSelectedList: boolean[];
  isCombo: boolean;
  isComboDisabled: boolean;
  isDescriptionDisabled: boolean;
}

interface WithMutationProps {
  createCustomerOrderProductGroup(payload: AddProductsToCustomerOrderPayload);
}

export interface COPGFormValues {
  id?: CustomerOrderProductGroupId;
  identifier: string;
  description: string;
  isCombo: boolean;
  packsPerShippingUnit?: number | null;
  customerOrderProducts: Array<{
    id: CustomerOrderProductId;
    productId: number;
    packsPerShippingUnit?: number | null;
    shelvesPerRack?: number | null;
    packsPerShelf?: number | null;
    packSize: number;
    piecesPerShippingUnit?: number | null;
  }>;
}

type ReduxFormProps = ReduxForm.InjectedFormProps<COPGFormValues>;

export const mapStateToProps = (state: any, ownProps: OwnProps & WithProductsProps): StateProps => {
  const formValues = ReduxForm.getFormValues(formName)(state) as COPGFormValues;
  const isProductSelectedList: StateProps['isProductSelectedList'] = [];
  formValues.customerOrderProducts.forEach(cop => {
    isProductSelectedList.push(cop.productId !== undefined);
  });
  return {
    isShown: state.productWorksheet.addNewProductModalShown,
    isProductSelectedList,
    isCombo: formValues.isCombo,
    isComboDisabled: formValues.customerOrderProducts.length > 1,
    isDescriptionDisabled: formValues && !formValues.isCombo,
  };
};

export const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps & WithProductsProps & WithMutationProps & StateProps & ReduxFormProps) => {
  return {
    handleCancelButtonClicked: () => dispatch(Actions.newProductModalCancelButtonClicked(formName)),
    handleProductChanged: (productId, productFieldIdentifier) => {
      const orderMethod = props.customerOrder && props.customerOrder.orderMethod ? props.customerOrder.orderMethod : ShippingUnitOrderMethod;
      const { products, regularCartList, isCombo, comboCartList } = props;

      const productsToDisplay = products ? products : [];
      dispatch(Actions.newProductModalProductChanged({ formName, productId, products: productsToDisplay, comboCartList, regularCartList, orderMethod, productFieldIdentifier, isCombo }));
    },
    handlePacksPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field: 'packsPerShelf' | 'shelvesPerRack', value: number) => {
      return dispatch(Actions.packsPerShippingUnitCalculationInputChange(formName, productFieldIdentifier, field, value));
    },
    handlePiecesPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field: 'packSize' | 'packsPerShippingUnit', value: number) => {
      return dispatch(Actions.piecesPerShippingUnitInputChange(formName, productFieldIdentifier, field, value));
    },
    handleProductCleared: () => {
      dispatch(Actions.newProductModalProductCleared(formName));
    },
    handleFormSubmit: () => {
      let orderMethod: OrderMethod = ShippingUnitOrderMethod;
      if (!props.customerOrder) {
        console.error(new Error(`customerOrderDetails is undefined`));
      } else {
        orderMethod = props.customerOrder.orderMethod;
      }

      return props.handleSubmit!(async (formValues: COPGFormValues) => {
        const payload: AddProductsToCustomerOrderPayload = {
          ...formValues,
          customerOrderId: props.customerOrderId,
          isCombo: formValues.isCombo,
          orderMethod,
          customerOrderProducts: {
            created: formValues.customerOrderProducts.map(cop => {
              return {
                id: cop.id,
                productId: cop.productId,
                packSize: cop.packSize,
                packsPerShippingUnit: cop.packsPerShippingUnit,
                packsPerShelf:  cop.packsPerShelf || null,
                shelvesPerRack: cop.shelvesPerRack || null,
                ...(orderMethod === OrderMethod.Pack ? { packsPerShippingUnit: formValues.packsPerShippingUnit } : {}),
              };
            }),
          },
        };

        await dispatch(Actions.newProductModalSaveClicked(formName, props.createCustomerOrderProductGroup, props.refreshProductsList, props.worksheetStatsRefetch, payload));
      });
    },
  };
};

const withProducts = optionsContainerWithFilters<WithProductsProps>({
  table: 'products',
  columns: ['identifier', 'description', 'packSize', 'packsPerRack', 'shelvesPerRack', 'packsPerShelf', 'casesPerPallet', 'activeStatus'],
  getFilters: (props: OwnProps) => {
    const customerOrderDetails = props.customerOrder;

    if (!customerOrderDetails || _.isEmpty(customerOrderDetails)) {
      return [];
    }

    return [
      { field: 'customer', values: [customerOrderDetails.customer.id.toString()] },
      { field: 'sellDepartment.identifier', values: [customerOrderDetails.sellDepartment.identifier, 'MISC'] },
      { field: 'scanProduct', values: [customerOrderDetails.scanBased ? 'true' : 'false'] },
      { field: 'activeStatus', values: [ActiveInactive.Active] },
    ];
  },
});

const mutation = gql`
  mutation CreateCustomerOrderProductGroup($input: CreateCustomerOrderProductGroupInput!) {
    data: createCustomerOrderProductGroup(input: $input) {
      id
      customerOrder {
        id
        lastModifiedAt
      }
      customerOrderProducts {
        product {
          id
        }
      }
    }
  }
`;

const withMutation = msyncMutation(mutation, {
  props: ({ mutate }): WithMutationProps => {
    return {
      createCustomerOrderProductGroup: (input: AddProductsToCustomerOrderPayload) => mutate({
        variables: { input },
        refetchQueries: ['findAllCustomerOrderProductGroupsForProductWorksheet'],
      }),
    };
  },
});

interface WithModalProps {
  title: string;
  submitButtonText: string;
  showAddProductButton: boolean;
  formName: string;
  isDescriptionDisabled: boolean;
  id: string;
  showRecentCombosButton: boolean;
  disableProductField: boolean;
}

const withModalProps = WrappedComponent => props => {
  return (
    <WrappedComponent
      {...props}
      title={'Add Product'}
      submitButtonText={'Add'}
      showAddProductButton={props.isCombo}
      formName={formName}
      isDescriptionDisabled={props.isDescriptionDisabled}
      id={'add-new-product-modal'}
      showRecentCombosButton
      disableProductField={false}
    />
  );
};

const withInitialValues = WrappedComponent => (props: OwnProps) => {
  const initialValues = {
    customerOrderProducts: [{}], // Empty object in the array causes an initial empty product to be shown in the modal
    isCombo: props.productIds ? props.productIds.size > 1 : false,
  };
  return (
    <WrappedComponent
      {...props}
      initialValues={initialValues}
    />
  );
};

const connectorState = connect(mapStateToProps);
const connectorDispatch = connect(undefined, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connectorState> & ConnectedProps<typeof connectorDispatch>;

type CombinedProps =
  OwnProps &
  PropsFromRedux &
  WithMutationProps &
  WithModalProps &
  {
    productsLoading: boolean;
    products: Array<{
      id: number;
      identifier: string;
      description: string;
      activeStatus: ActiveInactive;
    }>;
  } &
  ReduxFormProps;

export default wrapComponent(AddNewProductModal)<OwnProps, CombinedProps>(
  withProducts,
  withMutation,
  withInitialValues,
  ReduxForm.reduxForm({
    form: formName,
    enableReinitialize: true,
    touchOnChange: true,
  }),
  connectorState,
  connectorDispatch,
  withModalProps,
);
