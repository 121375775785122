import * as React from 'react';
import { SupplierOrderStatus } from 'shared/types';
import buildRecordBarStatusToggle from 'client/components/record-bar/build-record-bar-status-toggle';
import { ButtonGroup, Button } from 'client/components/third-party';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import { push } from 'connected-react-router';
import { Thunker } from 'client/types/redux-types';

interface OwnProps {
  record: any;
  onChangeRecordStatus: (field: string, val: string) => void;
}

const onNewSupplierOrderButtonClicked = (): Thunker => {
  return async (dispatch, getState) => {
    dispatch(push('/orders/supplier/create'));
  };
};

interface DispatchProps {
  handleNewSupplierOrderButtonClicked(): void;
}

const mapDispatchToProps = {
  handleNewSupplierOrderButtonClicked: onNewSupplierOrderButtonClicked,
};

const getStatusToggle = (props: OwnProps) => {
  const { onChangeRecordStatus, record } = props;
  const statuses = [SupplierOrderStatus.Draft, SupplierOrderStatus.Sent];

  return buildRecordBarStatusToggle({
    value: record?.orderStatus ? record.orderStatus : undefined,
    options: statuses,
    default: SupplierOrderStatus.Draft,
    onChangeRecordStatus,
    field: 'orderStatus',
    disabled: true, // Only way to change supplier order status is by sending it to the supplier (can't do it manually through the UI)
  });
};

type ComponentProps = OwnProps & DispatchProps;

const SupplierOrderRecordBarUI = (props: ComponentProps) => {
  const statusToggle = getStatusToggle(props);

  // Don't want to show the button if this is a "new record page"
  const showNewRecordButton = props.record && props.record.id;

  return (
    <div className="detail">
      {showNewRecordButton &&
        <div className="detail">
          <ButtonGroup bsSize="large">
            <Button className="record-bar-button record-bar-button-medium-icon" onClick={props.handleNewSupplierOrderButtonClicked}>
              New Supplier Order
            </Button>
          </ButtonGroup>
        </div>
      }
      {statusToggle}
    </div>
  );
};

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(SupplierOrderRecordBarUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
