import * as React from 'react';
export { FieldWrapper, SpecializeField, FormField, FormFieldCalculationArgs } from './field-wrapper';
export { LabeledInput } from './labeled-input';
export { default as LabeledDisplay } from './labeled-display';
export { default as LabeledDisplayMoney } from './labeled-display-money';
export { LabeledDisplayPercentage } from './labeled-display-percentage';
export { LabeledSelect } from './labeled-select';
export { default as LabeledCheckbox } from './labeled-checkbox';
export { default as LabeledDate } from './labeled-date';
export { default as LabeledDropdown } from './labeled-dropdown';
export { default as LabeledRadio } from './labeled-radio';

export const Form = (p: React.PropsWithChildren<{
  handleSubmit: (...args: unknown[]) => unknown,
  className: string,
}>) => (
  <form className={p.className} onSubmit={e => { e.preventDefault(); (document.activeElement as HTMLElement)?.blur(); p.handleSubmit(); }}>
    { p.children }
  </form>
);
