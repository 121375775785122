import * as React     from 'react';
import {
  SupplierOrderDetailsStats,
  formatInteger,
  formatMoneyOrNull,
  OrderMethod,
  ShippingUnitType,
  SupplierOrderTypes,
  formatReal }        from 'shared/types';
import { StateProps } from './supplier-order-details';
import * as Pluralize from 'pluralize';

const StatsRow = (p: { children?}) => <div className="stats-row">{p.children}</div>;
const Title = (p: {children?}) => <div className="card-pf-title">{p.children}</div>;
const Body = (p: {children?}) => <div className="card-pf-body">{p.children}</div>;
const Card = (p: { title, children?, condition? }) => p.condition === false ? null : (
  <div className="card-pf card-pf-accented card-pf-aggregate-status-notifications">
    <Title>{p.title}</Title>
    <Body>{p.children}</Body>
  </div>);

export function SupplierOrderDetailsStatsRow(props: { supplierOrderDetailsStats?: SupplierOrderDetailsStats, conditions?: StateProps }) {
  const stats = props.supplierOrderDetailsStats;
  const conditions = props.conditions;
  if (!stats || !conditions) {
    return (
      <StatsRow>
        <Card title="Placeholder">Placeholder</Card>
      </StatsRow>
    );
  }

  const totalShipUnitsLabel = conditions.shippingUnitType === ShippingUnitType.NotApplicable
    ? 'not applicable'
    : `Total ${Pluralize.plural(conditions.shippingUnitType)}`;

  return (
    <StatsRow>
      <Card title="Pieces Ordered">{formatInteger(stats.piecesOrdered)}</Card>
      <Card title="Pieces Received">{formatInteger(stats.piecesReceived)}</Card>
      <Card title="Total Supplier Cases" children={formatInteger(stats.totalSupplierCases)}
        condition={conditions.orderMethod !== OrderMethod.ShippingUnit}/>
      <Card title={totalShipUnitsLabel} children={formatReal(stats.totalShippingUnits)}
        condition={conditions.orderMethod === OrderMethod.ShippingUnit
          && conditions.shippingUnitType !== ShippingUnitType.NotApplicable}/>
      <Card title="Total Cost" children={formatMoneyOrNull(stats.totalCost)}
        condition={conditions.orderType !== SupplierOrderTypes.External}/>
    </StatsRow>
  );
}
