import * as React from 'react';
import { RecordBar } from 'client/app/planning/sales-plans/record-bar';
import Sidebar from 'client/app/planning/sales-plans/sidebar';
import * as Types from './types';
import { SalesPlanProductBreakdown } from './sales-plan-product-breakdown';

export interface UIProps {
  salesPlanId: number;
  handleProductSelected(productId: number): void;
  productVolumeRows: Types.ProductVolumeRow[];
  varietyDetailRows: Types.VarietyDetailRow[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
  productLabel: string;
}

export class SalesPlanDetailPage extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <div id="mfc-page-content" className="record">
        <RecordBar salesPlanId={this.props.salesPlanId} />
        <div className="mfc-form">
          <div>
            <div className="mfc-form-details-with-sidebar-but-no-tabs" style={{ paddingLeft: 20, paddingRight: 20 }}>
              <SalesPlanProductBreakdown
                salesPlanId={this.props.salesPlanId}
                handleProductSelected={this.props.handleProductSelected}
                productVolumeRows={this.props.productVolumeRows}
                varietyDetailRows={this.props.varietyDetailRows}
                startWeek={this.props.startWeek}
                endWeek={this.props.endWeek}
                loading={this.props.loading}
                productLabel={this.props.productLabel}
              />
            </div>
            <div className="mfc-form-sidebar">
              <Sidebar salesPlanId={this.props.salesPlanId} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
