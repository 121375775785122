import { appendFieldValidators } from 'shared/schemas/dsl';
import * as Validators from 'shared/validators';
import { SupplierOrderProductGroup } from 'shared/schemas/supplier-order-product-group';
import { OrderMethod } from 'shared/types';

// There are more validators in server/validations
appendFieldValidators(SupplierOrderProductGroup, {
  packsPerShippingUnit: [Validators.GREATER_THAN_ZERO],
  packQuantity: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
  desiredShippingUnitQuantity: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
  orderMethod: [Validators.INCLUSION(OrderMethod)],
  identifier: [
    Validators.ORDER_PRODUCT_GROUP_IDENTIFIER_HAS_CORRECT_PREFIX,
    Validators.MAX_LENGTH(2),
  ],
});
