import * as React from 'react';
import { isNil, toString, debounce } from 'lodash';
import * as classNames from 'classnames';
import BooleanCell from './boolean-cell';
import YesNoCell from './yes-no-cell';
import YesNoNullCell from './yes-no-null-cell';
import DateCell from './date-cell';
import MoneyCell from './money-cell';
import NumberCell from './number-cell';
import SpinnerCell from './spinner-cell';
import PercentageCell from './percentage-cell';
import RowSelector from './row-selector';
import { StatusCell } from './status-cell';
import TextCell from './text-cell';
import { OverlayTrigger, Tooltip } from 'client/components/third-party';

import { CELL_TYPES, TYPES } from '../../../shared/types';
import DateTimeCell from 'client/components/table/datetime-cell';

interface ComponentProps {
  value: any;
  editing: boolean;
  required: boolean;
  type: string;
  placeholder: string;
  tableDisplayType: any;
  onClick: () => void;
  onDoubleClick: () => void;
  className: string;
  columnName: string;
  onSave?: (id: number, newValue: any, prevValue: any) => Promise<void>;
  confirmOkToSave?: () => Promise<boolean>;
  handleSubmit?: () => Promise<boolean>;
  refetchStats?: () => Promise<void>;
  sorted: boolean;
  id: number;
  testid: string;
  onArrowKeyUpDuringEdit?: () => void;
  onArrowKeyDownDuringEdit?: () => void;
  saveOnChange?: boolean;
  options?: {
    arrowKeyUpDuringEditMovesFocusUp?: boolean;
    arrowKeyDownDuringEditMovesFocusDown?: boolean;
    clearSaveErrorOnChange?: boolean;
    clearSaveErrorBeforeSave?: boolean;
  };
  onBlur?(id: number): Promise<void>;
  focusRowContainingCell?(cellRef: TableCell, focus: boolean): void;
}

interface ComponentState {
  editing: boolean;
  hovered: boolean;
  focused: boolean;
  value: any;
  prevValue: any;
  changeCounter: number;
  savedChangeCounter: number;
  errorFromSaving?: string;
  validationError?: string;
}

export interface TableCell {
  setErrorFromSaving: (message: string | undefined) => void;
  setValidationError: (message: string | undefined) => void;
  getValidationError: () => string | undefined;
  getErrorFromSaving: () => string | undefined;
  blur: () => void;
  setEdit: (editing: boolean) => void;
  focus: () => void;
  hover: (flag: boolean) => void;
  setValue: (value: any) => void;
  getValue: () => any;
  saveCurrentValue: () => Promise<void>;
}

export class CellRenderer extends React.Component<ComponentProps, ComponentState> implements TableCell {
  private debouncedSave?: (value: any, prevValue: any, changeCounter: number) => any;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      editing: false,
      errorFromSaving: undefined,
      validationError: undefined,
      focused: false,
      hovered: false,
      value: props.value,
      prevValue: props.value,
      savedChangeCounter: 0,
      changeCounter: 0,
    };
    this.blur = this.blur.bind(this);
    this.focus = this.focus.bind(this);
    this.hover = this.hover.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setErrorFromSaving = this.setErrorFromSaving.bind(this);
    this.setValidationError = this.setValidationError.bind(this);
    this.getValidationError = this.getValidationError.bind(this);
    this.getErrorFromSaving = this.getErrorFromSaving.bind(this);
    this.setEdit = this.setEdit.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onArrowKeyDown = this.onArrowKeyDown.bind(this);
    this.onArrowKeyUp = this.onArrowKeyUp.bind(this);
    if (this.props.onSave) {
      this.debouncedSave = debounce(this.onSave, 200);
    }
  }

  public shouldComponentUpdate(nextProps: ComponentProps, nextState: ComponentState) {
    const result = (
      this.props.value !== nextProps.value ||
      this.state.value !== nextState.value ||
      this.state.focused !== nextState.focused ||
      this.state.hovered !== nextState.hovered ||
      this.state.editing !== nextState.editing ||
      this.state.errorFromSaving !== nextState.errorFromSaving ||
      this.state.validationError !== nextState.validationError ||
      this.props.required !== nextProps.required ||
      this.props.sorted !== nextProps.sorted ||
      this.props.placeholder !== nextProps.placeholder ||
      this.props.className !== nextProps.className ||
      this.props.testid !== nextProps.testid // Not really just for tests I guess.
    );
    return result;
  }

  componentWillReceiveProps(nextProps: ComponentProps) {
    // TODO: Stop using the "test" id replace with a real unique ID for the cell (the current this.props.id is actually
    //       the store id, so it's the same for all columns in this row)
    if (this.props.testid !== nextProps.testid) {
      this.setState({
        value: nextProps.value,
        prevValue: nextProps.value,
        changeCounter: 0,
        savedChangeCounter: 0,
        editing: false,
        errorFromSaving: undefined,
        validationError: undefined,
      });
      return;
    }
    if (this.props.value !== nextProps.value || nextProps.value !== this.state.value) {
      if (this.state.changeCounter === this.state.savedChangeCounter) {
        this.setState({
          value: nextProps.value,
          prevValue: nextProps.value,
        });
      }
    }
  }

  private async onSave(value: any, prevValue: any, changeCounter?: number): Promise<void> {
    const v = (this.props.type === TYPES.NUMBER || this.props.type === TYPES.FLOAT) && `${value || ''}`.trim().length === 0
      ? 0
      : value;

    const changeCount = changeCounter || this.state.changeCounter;
    if (this.props.onSave) {

      // If confirmation is required, ask the user before saving. If the user chooses to not
      // save, revert to the previous value
      if (this.props.confirmOkToSave) {
        if (!await this.props.confirmOkToSave()) {
          // Revert back to the previous value
          this.setState({
            value: this.props.value,
            prevValue: this.props.value,
          });
          return;
        }
      }

      if (this.props.handleSubmit) {
        try {
          const submitResult = await this.props.handleSubmit();
          if (!submitResult) {
            // Revert back to the previous value
            this.setState({
              value: this.props.value,
              prevValue: this.props.value,
            });
            return;
          }
        } catch (formSubmitErr) {
          // Not sure what could cause an error to get here, but just to be safe
          // going to treat it the same as if handleSubmit returned false (above)

          // Revert back to the previous value
          this.setState({
            value: this.props.value,
            prevValue: this.props.value,
          });

          throw formSubmitErr;
        }
      }

      try {
        const beforeTestId = this.props.testid;
        await this.props.onSave(this.props.id, v, prevValue);

        // TODO: Stop using the "test" id replace with a real unique ID for the cell (the current this.props.id is actually
        //       the store id, so it's the same for all columns in this row)
        if (beforeTestId !== this.props.testid) {
          // This cell was changed out while the save was taking place. Doesn't
          // make sense to update anything
          return;
        }

        this.setState({ savedChangeCounter: changeCount, prevValue: v });

        if (this.props.options && this.props.options.clearSaveErrorBeforeSave) {
          this.clearErrorFromSaving();
        }

        if (this.props.refetchStats) {
          // Don't want a stats refetch error to appear to be a save error,
          // so just catch any error and only log it
          try {
            await this.props.refetchStats();
          } catch (err) {
            console.error('Error refetching stats after onSave', err);
          }
        }
      } catch (error) {
        this.setErrorFromSaving(error.message);
      }
    }
  }

  private onChange(newValue: any, shouldSave: boolean = false, shouldDebounce: boolean = false) {
    if (this.props.options && this.props.options.clearSaveErrorOnChange) {
      this.clearErrorFromSaving();
    }

    const changeCounter = this.state.changeCounter + 1;
    this.setState({ changeCounter });
    this.setState({ value: newValue });

    if (shouldSave) {
      if (shouldDebounce && this.debouncedSave) {
        return this.debouncedSave(newValue, this.state.value, changeCounter);
      }
      return this.onSave(newValue, this.state.value, changeCounter);
    }
  }

  public async saveCurrentValue() {
    const hasChanges = !isStringEquivalent(this.state.value, this.state.prevValue);
    if (hasChanges) {
      return this.onSave(this.state.value, this.state.prevValue, undefined);
    }

    if (this.props.onBlur) {
      await this.props.onBlur(this.props.id);
    }

    return Promise.resolve();
  }

  private onArrowKeyUp() {
    if (this.props.onArrowKeyUpDuringEdit) {
      this.props.onArrowKeyUpDuringEdit();
    }
  }
  private onArrowKeyDown() {
    if (this.props.onArrowKeyDownDuringEdit) {
      this.props.onArrowKeyDownDuringEdit();
    }
  }

  public render() {
    const { focused, editing, hovered } = this.state;
    const { tableDisplayType, type, id, columnName, testid, sorted, saveOnChange } = this.props;

    let Component: any = TextCell;
    let fieldProps = {};

    if (tableDisplayType) {
      if (tableDisplayType === CELL_TYPES.SPINNER_ALLOW_NEGATIVES || tableDisplayType === CELL_TYPES.SPINNER || tableDisplayType === CELL_TYPES.SPINNER_NO_DEBOUNCE) {
        Component = SpinnerCell;
        fieldProps = {
          onArrowKeyUp: this.onArrowKeyUp,
          onArrowKeyDown: this.onArrowKeyDown,
          allowNegatives: tableDisplayType === CELL_TYPES.SPINNER_ALLOW_NEGATIVES,
          debounceIncrementDecrements: tableDisplayType !== CELL_TYPES.SPINNER_NO_DEBOUNCE,
        };
      } else if (tableDisplayType === CELL_TYPES.MONEY) {
        Component = MoneyCell;
      } else if (tableDisplayType === CELL_TYPES.PERCENTAGE) {
        Component = PercentageCell;
      } else if (tableDisplayType === CELL_TYPES.NUMBER) {
        Component = NumberCell;
      } else if (tableDisplayType === CELL_TYPES.INT) {
        Component = NumberCell;
        fieldProps = { dashValues: [0, undefined, '0', 'undefined', ''], decimals: 0 };
      } else if (tableDisplayType === CELL_TYPES.CENTERED_NUMBER) {
        Component = NumberCell;
        fieldProps = { centered: true };
      } else if (tableDisplayType === CELL_TYPES.DASH_ZERO_NUMBER) {
        Component = NumberCell;
        fieldProps = { dashValues: [0] };
      } else if (tableDisplayType === CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED) {
        Component = NumberCell;
        fieldProps = { dashValues: [0, undefined, '0', 'undefined', ''] };
      } else if (tableDisplayType === CELL_TYPES.DROPDOWN) {
        Component = TextCell;
      } else if (tableDisplayType === CELL_TYPES.CHECKBOX) {
        Component = BooleanCell;
      } else if (tableDisplayType === CELL_TYPES.YES_NO) {
        Component = YesNoCell;
      } else if (tableDisplayType === CELL_TYPES.YES_NO_NULL) {
        Component = YesNoNullCell;
      } else if (tableDisplayType === CELL_TYPES.DATE) {
        Component = DateCell;
      } else if (tableDisplayType === CELL_TYPES.DATE_TIME) {
        Component = DateTimeCell;
      } else if (tableDisplayType === CELL_TYPES.STATUS) {
        Component = StatusCell;
      } else if (tableDisplayType === CELL_TYPES.SELECTION) {
        Component = RowSelector.Cell;
      } else if (tableDisplayType === CELL_TYPES.RIGHT_ALIGNED_TEXT) {
        fieldProps = { alignRight: true };
        Component = TextCell;
      } else {
        fieldProps = {};
        Component = TextCell;
      }
    } else {
      Component = TextCell;
    }

    fieldProps = {
      ...fieldProps,
      ...(this.props.options && this.props.options.arrowKeyUpDuringEditMovesFocusUp ? { onArrowKeyUp: this.onArrowKeyUp } : {}),
      ...(this.props.options && this.props.options.arrowKeyDownDuringEditMovesFocusDown ? { onArrowKeyDown: this.onArrowKeyDown } : {}),
    };

    const displayValue = this.state.value;

    const isErrored = !isNil(this.state.validationError) || !isNil(this.state.errorFromSaving);

    const className = classNames({
      'selected': focused,
      'cell-sorted': sorted,
      'error': isErrored,
      'rendered-cell': true,
      editing,
    }, this.props.className);

    const renderedCell = (
      <div className={className}>
        <Component
          hovered={hovered}
          editing={editing}
          value={displayValue}
          type={type}
          id={id}
          columnName={columnName}
          onChange={this.onChange}
          onSave={this.onSave}
          testid={testid}
          saveOnChange={saveOnChange}
          {...fieldProps}
        />
      </div>
    );

    if (isErrored) {
      const tooltip = <Tooltip id={`tooltip-${id}`} className="cell-error-tooltip">{this.state.validationError || this.state.errorFromSaving}</Tooltip>;
      return (
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" delay={200} animation overlay={tooltip}>
          {renderedCell}
        </OverlayTrigger>
      );
    } else {
      return renderedCell;
    }
  }

  public clearErrorFromSaving() {
    this.setState({
      errorFromSaving: undefined,
    });
  }

  public setErrorFromSaving(message: string) {
    this.setState({
      errorFromSaving: message,
    });
  }

  public setValidationError(message: string) {
    this.setState({
      validationError: message,
    });
  }

  public getValidationError() {
    return this.state.validationError;
  }

  public getErrorFromSaving() {
    return this.state.errorFromSaving;
  }

  public async blur() {
    this.setState({
      editing: false,
      focused: false,
    });
    if (this.props.focusRowContainingCell) {
      this.props.focusRowContainingCell(this, false);
    }
    if (this.props.onBlur) {
      await this.props.onBlur(this.props.id);
    }
  }

  public focus() {
    this.setState({
      focused: true,
    });
    if (this.props.focusRowContainingCell) {
      this.props.focusRowContainingCell(this, true);
    }
  }

  public setEdit(isEditing: boolean) {
    if (isEditing) {
      this.setState({
        editing: true,
        focused: true,
      });
      if (this.props.focusRowContainingCell) {
        this.props.focusRowContainingCell(this, true);
      }
    } else {
      this.setState({
        editing: false,
      });
    }
  }

  public setValue(value: any) {
    this.setState({
      value,
    });
  }
  public getValue() {
    return this.state.value;
  }

  public hover(isHovered: boolean) {
    this.setState({
      hovered: isHovered,
    });
  }
}

function isStringEquivalent(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  return toString(a) === toString(b);
}

export default CellRenderer;
