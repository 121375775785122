import { flow } from 'lodash';

import {
  SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY,
  SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS,
  SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
  SUPPLIER_ORDER_SELECT_ALL_CLICKED,
  SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS,
} from 'client/constants';
import * as SupplierOrderState from 'client/state/supplier-order';
import { Action } from 'client/types/redux-types';

function closeModals(state: SupplierOrderState.Type) {
  return {
    ...state,
    addNewProductModalShown: false,
  };
}

export default function reducer(state = SupplierOrderState.InitialState, action: Action = { type: 'unknown' }): SupplierOrderState.Type {
  switch (action.type) {
    case SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED: {
      return {
        ...state,
        addNewProductModalShown: true,
      };
    }

    case SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED: {
      return {
        ...state,
        addNewProductModalShown: false,
      };
    }

    case SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED: {
      return flow(
        closeModals,
      )(state);
    }

    case SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED: {
      return {
        ...state,
        editSupplierOrderProductGroupModalShown: true,
        supplierOrderProductToEditId: action.payload.supplierOrderProductId,
      };
    }

    case SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED: {
      return {
        ...state,
        editSupplierOrderProductGroupModalShown: false,
        supplierOrderProductToEditId: undefined,
      };
    }

    case SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED: {
      return {
        ...state,
        editSupplierOrderProductGroupModalShown: false,
        supplierOrderProductToEditId: undefined,
      };
    }

    case SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY: {
      return {
        ...state,
        sendSupplierPOModalShown: action.payload,
      };
    }

    case SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS: {
      return {
        ...state,
        supplierOrderContacts: action.payload,
      };
    }

    case SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED: {
      return {
        ...state,
        supplierOrderContacts: state.supplierOrderContacts.map(contact => {
          return {
            // select or unselect (! the current value) the contact that we're clicking on
            ...contact,
            selected: contact.supplierContactId === action.payload ? !contact.selected : contact.selected,
          };
        }),
      };
    }

    case SUPPLIER_ORDER_SELECT_ALL_CLICKED: {
       return SupplierOrderState.isSelectAllSelected.update(state, currentValue => !currentValue);
    }

    case SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS: {
      return {
        ...state,
        sendSupplierOrderActionStatus: action.payload,
      };
    }

    default:
      return state;
  }
}
