import gql from 'graphql-tag';

export const updateReportUserParamsMutation = () => gql`
mutation UpdateReportUserParams($input: UpdateReportUserParamsInput!) {
  response: UpdateReportUserParams(input: $input) {
    reportPreset {
      id
      params
    }
  }
}`;

export const createReportUserParamsMutation = () => gql`
mutation CreateReportUserParams($input: CreateReportUserParamsInput!) {
  response: CreateReportUserParams(input: $input) {
    reportPreset {
      id
      params
    }
  }
}`;

export const deleteReportUserParamsMutation = () => gql`
mutation DeleteReportUserParams($input: DeleteReportUserParamsInput!) {
  response: DeleteReportUserParams(input: $input) {
    id
  }
}`;

export const setReportUserParamsDefaultMutation = () => gql`
mutation SetReportUserParamsDefault($input: SetReportUserParamsDefaultInput!) {
  response: SetReportUserParamsDefault(input: $input) {
    id
  }
}`;
