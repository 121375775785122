import * as _ from 'lodash';
import { ActiveFilter, ActiveSearch, AvailableSearchField, ActiveSort } from 'client/types';
import { IColumn } from 'client/components/table/column';
import { SEARCH_FIELD_ANY, SearchInput } from 'shared/types';

export const mapToFilters = <T>(activeFilters: ActiveFilter[]): T => {
  return activeFilters.reduce((acc, filter) => {
    if (_.isEmpty(filter.values)) {
      return acc;
    }

    acc[filter.field] = { values: filter.values, operator: filter.operator };
    return acc;
  }, {} as T);
};

/**
 * To get some type checking for fields used in table searches, convert structures
 * used by the tables into an object that GraphQL can verify. Once received by the
 * server it will be converted back into the standard search shape.
 */
export const convertToTypedSearchCriteriaForTransit = <T extends { text: string }>(activeSearch: ActiveSearch, availableSearchFields: AvailableSearchField[]): T => {
  // Special case handling for the "Search All". Most places (all of them now?) use
  // the SEARCH_FIELD_ANY to indicate "Search All". There might still be some that use
  // an empty string, so still checking for it.
  if (activeSearch.fields.length === 1 && (activeSearch.fields[0] === '' || activeSearch.fields[0] === SEARCH_FIELD_ANY)) {
    return availableSearchFields.reduce((acc, field) => {
      if (field.id !== '' && field.id !== SEARCH_FIELD_ANY) {
        acc[field.id] = true;
      }
      return acc;
    }, { text: activeSearch.text || '' } as T);
  } else {
    // If a specific field is selected (or multiple theoretically), then just use that one.
    return activeSearch.fields.reduce((acc, fieldName) => {
      acc[fieldName] = true;
      return acc;
    }, { text: activeSearch.text || '' } as T);
  }
};

/**
 * For "new" resolver/repository queries that aren't using the typesafe shape for searches
 * (not all of them do, looks like route plans and routing loads might be the only ones that use
 * the typed search) still need to convert the "Search All" into the specific fields to be searched.
 */
export const convertSearchAllToSpecificFields = (activeSearch: ActiveSearch, availableSearchFields: AvailableSearchField[]): SearchInput => {
  if (activeSearch.fields.length === 1 && (activeSearch.fields[0] === '' || activeSearch.fields[0] === SEARCH_FIELD_ANY)) {
    return {
      text: activeSearch.text,
      fields: availableSearchFields.filter(field => field.id !== '' && field.id !== SEARCH_FIELD_ANY).map(f => f.id),
    };
  } else {
    return activeSearch;
  }
};

export const mapToSort = <T> (sort: ActiveSort[] | null | undefined): T[] => {
  if (_.isNil(sort)) {
    return [];
  }

  return sort.map(s => ({
    sortField: s.sortField,
    sortOrder: s.sortOrder,
  })) as shame as T[]; // TODO: Hmmm, this doesn't seem right
};

interface Cell {
  row: number;
  column: number;
}
export function isCellEditable(columns: IColumn[], cell: Cell, tableRows: any[] | undefined) {
  const tableEditable = columns[cell.column].tableEditable;
  if (typeof tableEditable === 'boolean') {
    return tableEditable;
  } else {
    const row = (tableRows || [])[cell.row]; // will return undefined if out of bounds
    return tableEditable(row);
  }
}
