import * as Actions from './actions';
import * as R from 'ramda';
import * as State from './state';

type ImportDistributionRackShippingWorksheetState = State.Type;

export default function reducer(state = State.InitialState, action: Actions.ActionTypes): ImportDistributionRackShippingWorksheetState {
  switch (action.type) {
    case Actions.ActionTypeKeys.OpenModalButtonClicked: {
      return R.pipe(
        State.isModalShown.set(true),
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Initial),
        State.storeIdentifiersInRoutePlan.set(action.payload.storeIdentifiersInRoutePlan),
      )(state);
    }

    case Actions.ActionTypeKeys.CancelButtonClicked: {
      return R.pipe(
        State.isModalShown.set(false),
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Initial),
        State.storeIdentifiersInRoutePlan.set([]),
      )(state);
    }

    case Actions.ActionTypeKeys.WorksheetParsingStarted: {
      return R.pipe(
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Parsing),
      )(state);
    }

    case Actions.ActionTypeKeys.WorksheetParsingCompleted: {
      return R.pipe(
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Parsed),
        State.worksheet.set(action.payload.importableDistributionRackShippingWorksheet),
      )(state);
    }

    case Actions.ActionTypeKeys.WorksheetImporting: {
      return R.pipe(
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Importing),
      )(state);
    }

    case Actions.ActionTypeKeys.WorksheetImported: {
      return R.pipe(
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Imported),
      )(state);
    }

    case Actions.ActionTypeKeys.WorksheetFailed: {
      return R.pipe(
        State.importState.set(State.ImportDistributionRackShippingWorksheetImportState.Failed),
        State.failedStep.set(action.payload.failedStep),
        State.errorMessages.set(action.payload.errorMessages),
      )(state);
    }

    default:
      return state;
  }
}
