import * as JSZip from 'jszip';
import { send } from 'shared/send';

export async function getZipFromS3(rows: Array<{ filename: string, url: string }>) {
  const files = await Promise.all(rows.map(async row => {
    const content = await send({
      url: row.url,
      method: 'GET',
      asBlob: true,
    });
    return {
      filename: row.filename,
      content,
    };
  }));

  const zip = new JSZip();
  for (const file of files) {
    zip.file(file.filename, file.content);
  }

  return zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
}
