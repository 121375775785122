import { ReceivableOrderReceivingStatus } from 'shared/types';

export function calculateReceivedStatus(received: number, ordered: number): ReceivableOrderReceivingStatus {
  if (received === 0 && ordered > 0) {
    return ReceivableOrderReceivingStatus.NotReceived;
  } else if (received === ordered) {
    return ReceivableOrderReceivingStatus.FullyReceived;
  } else if (received < ordered) {
    return ReceivableOrderReceivingStatus.PartiallyReceived;
  } else if (received > ordered) {
    return ReceivableOrderReceivingStatus.OverReceived;
  }
  return ReceivableOrderReceivingStatus.NotReceived;
}

export function determineReceivedStatusFormatting(received: number, ordered: number): string {
  const status = calculateReceivedStatus(received, ordered);
  switch (status) {
    case ReceivableOrderReceivingStatus.NotReceived:
      return '';
    case ReceivableOrderReceivingStatus.PartiallyReceived:
      return 'stop-light-yellow';
    case ReceivableOrderReceivingStatus.FullyReceived:
      return 'stop-light-green';
    case ReceivableOrderReceivingStatus.OverReceived:
      return 'stop-light-red';
  }
}
