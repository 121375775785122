import * as React from 'react';
import { ReceivingStatsRow } from 'client/app/transportation/receiving/details/stats-row/receiving-stats-container';
import { RelatedBolsTable } from 'client/app/transportation/receiving/details/related-bols/related-bols-table-container';
import { Col } from 'client/components/third-party';
import ReceivingOverviewSidebar from 'client/app/transportation/receiving/details/sidebar/sidebar-container';
import { SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { downloadReceivingReceipt } from 'client/app/transportation/receiving/details/related-bols/download-receiving-receipt';
import * as Actions from 'client/actions/related-bols';
import { MapDispatchToProps, MapDispatchToPropsFactory, connect } from 'react-redux';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { RouteChildrenProps } from 'react-router';

interface OwnProps {
  receivableOrderId: number;
}

interface DispatchProps {
  setSendReceivingReceiptModalVisibility: (showModal: boolean) => void;
}

const WithReceivableOrderId = WrappedComponent => (props: RouteChildrenProps<{ id: string }>) => {
  if (!props.match) {
    throw new Error('Missing path information for receiving details');
  }

  const params = props.match.params;

  return propToComponent(WrappedComponent, { receivableOrderId: Number.parseInt(params.id) });
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> | MapDispatchToPropsFactory<DispatchProps, OwnProps> = (dispatch, ownProps: OwnProps): DispatchProps => {
  return {
    setSendReceivingReceiptModalVisibility: (showModal: boolean) => dispatch(Actions.setSendReceivingReceiptModalVisibility(showModal)),
  };
};

const RelatedBols: React.SFC<OwnProps & DispatchProps> = props => {
  return (
    <div>
      <div className="mfc-form-details-with-sidebar">
        <Col sm={12}>
          <ReceivingStatsRow
            receivableOrderId={props.receivableOrderId}
            showExtraInfo
          />
          <RelatedBolsTable
            receivableOrderId={props.receivableOrderId} />
        </Col>
      </div>
      <ReceivingOverviewSidebar receivableOrderId={props.receivableOrderId} actionMenuItems={[
        {
          label: 'Download Receipt',
          action: async (setInProgress: SetInProgress, identifier: string) => {
            setInProgress(true);
            try {
              await downloadReceivingReceipt({
                fileNameSuffix: 'Receiving Receipt',
                receivableOrderId: props.receivableOrderId,
                identifier,
              });
            } finally {
              setInProgress(false);
            }
          },
        },
        {
          label: 'Send Receiving Receipt',
          action: async (setInProgress: SetInProgress, receivableOrderIdentifier: string) => {
            props.setSendReceivingReceiptModalVisibility(true);
          },
        },
      ]}
      />
    </div>
  );
};

const component = flowRight(
  WithReceivableOrderId,
  connect<undefined, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(RelatedBols) as shame;

export default (props: OwnProps) => propToComponent(component, props);
