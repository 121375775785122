import * as React from 'react';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import RoutePlanDetails from './route-plan-details';
import { withRoutePlanIdFromParams } from './with-route-plan-id-from-params';
import ReviewStoresTable from 'client/app/transportation/routing/route-plan-details/review-stores/review-stores-container';
import { RoutePlanSectionNames } from '../route-plan-section-names';
import { RouteChildrenProps } from 'react-router';

type OwnProps = RouteChildrenProps<{ id?: string }>;

interface WithRoutePlanIdProps {
  routePlanId?: RoutePlanId;
}

const RoutePlanReviewStores: React.SFC<OwnProps & WithRoutePlanIdProps> = props => {
  if (props.routePlanId === undefined) {
    return (<div/>);
  }
  return (
    <RoutePlanDetails routePlanId={props.routePlanId} sectionName={RoutePlanSectionNames.ReviewStores}>
      <ReviewStoresTable routePlanId={props.routePlanId}/>
    </RoutePlanDetails>
  );
};

const component = flowRight(
  withRoutePlanIdFromParams,
)(RoutePlanReviewStores);

export default (props: OwnProps) => propToComponent(component, props);
