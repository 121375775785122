import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as State from 'client/state/state';
import { VarietyDetailRow } from '../../types';
import * as Actions from '../../../import-sales-plan-variety-color-modal/actions';

interface ContainerProps {
  endWeek: number | undefined;
  height: number;
  loading: boolean;
  rows: VarietyDetailRow[];
  startWeek: number | undefined;
  width: number;
  productLabel: string;
  twin: any;
  self: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
}

const mapStateToProps = (state: State.Type, ownProps: ContainerProps): StateProps => {
  return {
  };
};

type DispatchProps = {
  handleImportVarietyColorModalOpenModalButtonClicked(): void;
};

const mapDispatchToProps: DispatchProps = {
  handleImportVarietyColorModalOpenModalButtonClicked: Actions.handleImportVarietyColorModalOpenModalButtonClicked,
};

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
)(UI.SalesPlanWeeklyVarietyDetailsTableUI);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  StateProps;

assertCompatible<UI.UIProps, CombinedProps>();
export const SalesPlanWeeklyVarietyDetailsTableContainer = (props: ContainerProps) => propToComponent(component, props);
