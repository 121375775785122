import * as React from 'react';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { InvoiceType, DateStr, INPUT_TYPES, Return } from 'shared/types';
import { connect } from 'react-redux';
import * as State from 'client/state/state';
import { optionsContainerGenerator, optionsContainerWithFilters, Options } from 'client/hoc/options-container-generator';
import { msyncQuery } from 'client/hoc/graphql/query';
import { normalizeNullToUndefined } from 'shared/helpers/andys-little-helpers';
import { withSaveConfirmation } from 'client/hoc/with-save-confirmation';
import { saveConfirmationOptionsForInvoice } from './shared/save-confirmation';
import { WithWarnUnsaved } from 'client/hoc/with-warn-unsaved';
import { orThrowBug } from 'shared/helpers';
import gql from 'graphql-tag';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import { Form, FormGroup, Col } from 'client/components/third-party';
import { LabeledInput, LabeledDate, LabeledSelect, LabeledCheckbox } from 'client/components/form';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import { FormField } from 'client/components/form';
import { EMPTY_ARRAY } from 'client/constants';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { ProgressSpinner } from 'client/components/progress-spinner';
import * as Validators from 'shared/validators';
import { SendInvoiceModal } from './send-invoice-modal';
import { useDerivation } from 'client/lib/react';
import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { push } from 'connected-react-router';
import * as FormHelpers from 'client/helpers/form-helpers';
import { getFormValues } from 'client/redux-form';
import { defaultUnreachable } from 'shared/helpers/andys-little-helpers';
import { CreateDropShipInvoiceArgs, CreateVendorChargebackInvoiceArgs } from 'schema/invoice/invoice-graphql-types';


export const formName = 'invoiceDetailForm';

enum FormFields {
  id = 'id',
  identifier = 'identifier',
  type = 'type',
  invoiceDate = 'invoiceDate',
  terms = 'terms',
  notes = 'notes',
  ediInvoiced = 'ediInvoiced',
  transferredToAccounting = 'transferredToAccounting',
  customerId = 'customerId',
  customerOrderIdentifier = 'customerOrderIdentifier',
  customerOrderDeliveryDate = 'customerOrderDeliveryDate',
  customerOrderShipDate = 'customerOrderShipDate',
  storeId = 'storeId',
  weekEndingDate = 'weekEndingDate',

  printedDownloaded = 'printedDownloaded',
  invoiceEmailed = 'invoiceEmailed',

  // vendor chargeback
  vendorChargebackRefNumber = 'vendorChargebackRefNumber',
  vendorChargebackCheckNumber = 'vendorChargebackCheckNumber',
  vendorChargebackDeliveryDate = 'vendorChargebackDeliveryDate',
  supplierId = 'supplierId',
  supplierOrderIdentifier = 'supplierOrderIdentifier',
  supplierOrderPlannedArrivalDate = 'supplierOrderPlannedArrivalDate',
  paidDate = 'paidDate',
}

interface BaseQueryInvoice {
  id: InvoiceGraphqlTypes.Invoice['id'];
  identifier: InvoiceGraphqlTypes.Invoice['identifier'];
  invoiceDate: InvoiceGraphqlTypes.Invoice['invoiceDate'];
  terms: InvoiceGraphqlTypes.Invoice['terms'];
  notes: InvoiceGraphqlTypes.Invoice['notes'];
  ediInvoiced: InvoiceGraphqlTypes.Invoice['ediInvoiced'];
  transferredToAccounting: InvoiceGraphqlTypes.Invoice['transferredToAccounting'];
  customer: InvoiceGraphqlTypes.Invoice['customer'];
  firstDownloadedAt: InvoiceGraphqlTypes.Invoice['firstDownloadedAt'];
  deliveryDate: InvoiceGraphqlTypes.Invoice['deliveryDate'];
  emailedAt: InvoiceGraphqlTypes.Invoice['emailedAt'];
}

interface QueryCustomerOrderInvoice extends BaseQueryInvoice {
  type: InvoiceType.CustomerOrder;
  customerOrder: InvoiceGraphqlTypes.CustomerOrderInvoice['customerOrder'];
  store: InvoiceGraphqlTypes.CustomerOrderInvoice['store'];
}

interface QueryDropShipInvoice extends BaseQueryInvoice {
  type: InvoiceType.DropShip;
  weekEndingDate: InvoiceGraphqlTypes.DropShipInvoice['weekEndingDate'];
}

interface QueryVendorChargebackInvoice extends BaseQueryInvoice {
  type: InvoiceType.VendorChargeback;
  supplier: InvoiceGraphqlTypes.VendorChargebackInvoice['supplier'];
  vendorChargebackRefNumber: InvoiceGraphqlTypes.VendorChargebackInvoice['vendorChargebackRefNumber'];
  vendorChargebackCheckNumber: InvoiceGraphqlTypes.VendorChargebackInvoice['vendorChargebackCheckNumber'];
  supplierOrderIdentifier: InvoiceGraphqlTypes.VendorChargebackInvoice['supplierOrderIdentifier'];
  supplierOrderPlannedArrivalDate: InvoiceGraphqlTypes.VendorChargebackInvoice['supplierOrderPlannedArrivalDate'];
  paidDate: InvoiceGraphqlTypes.VendorChargebackInvoice['paidDate'];
}

export type QueryInvoice = QueryCustomerOrderInvoice | QueryDropShipInvoice | QueryVendorChargebackInvoice;
export interface QueryResponse { getInvoice?: { invoice: QueryInvoice } }
export const Query = gql`
  query GetInvoiceForInvoiceSidebar($invoiceId: Int!) {
    getInvoice: GetInvoice(id: $invoiceId) {
      invoice {
        id
        type
        identifier
        invoiceDate
        terms
        notes
        ediInvoiced
        transferredToAccounting
        firstDownloadedAt
        emailedAt
        deliveryDate
        customer {
          id
        }

        ... on DropShipInvoice {
          id
          weekEndingDate
        }

        ... on CustomerOrderInvoice {
          id
          customerOrder {
            id
            identifier
            orderDate
            shipDate
          }
          store {
            id
          }
        }

        ... on VendorChargebackInvoice {
          id
          vendorChargebackRefNumber
          vendorChargebackCheckNumber
          paidDate
          supplierOrderIdentifier
          supplierOrderPlannedArrivalDate
          supplier {
            id
          }
        }
      }
    }
  }
`;

export interface CreateInvoiceResponse { createInvoice: { invoice: Pick<InvoiceGraphqlTypes.Invoice, 'id' | 'identifier' | 'lastModifiedAt'> } }
export const CreateDropShipInvoiceMutation = gql`
  mutation CreateDropShipInvoiceMutation($input: CreateDropShipInvoiceInput!) {
    createInvoice: CreateDropShipInvoice(input: $input) {
      invoice {
        id
        identifier
        lastModifiedAt
      }
    }
  }
`;

export const CreateVendorChargebackInvoiceMutation = gql`
  mutation CreateVendorChargebackInvoiceMutation($input: CreateVendorChargebackInvoiceInput!) {
    createInvoice: CreateVendorChargebackInvoice(input: $input) {
      invoice {
        id
        identifier
        lastModifiedAt
      }
    }
  }
`;

export interface UpdateInvoiceInput { invoiceId: InvoiceGraphqlTypes.UpdateInvoiceArgs['id'], newInvoiceValues: InvoiceGraphqlTypes.UpdateInvoiceArgs['input'] }
export interface UpdateInvoiceResponse { updateInvoice: { invoice: Pick<InvoiceGraphqlTypes.Invoice, 'id' | 'identifier' | 'lastModifiedAt'> } }
export const UpdateInvoiceMutation = gql`
  mutation UpdateInvoiceMutation($invoiceId: Int!, $newInvoiceValues: UpdateInvoiceInput!) {
    updateInvoice: UpdateInvoice(id: $invoiceId, input: $newInvoiceValues) {
      invoice {
        id
        type
        identifier
        invoiceDate
        terms
        notes
        ediInvoiced
        transferredToAccounting
        customer {
          id
        }

        ... on DropShipInvoice {
          id
          weekEndingDate
        }

        ... on CustomerOrderInvoice {
          id
          customerOrder {
            id
            identifier
            orderDate
            shipDate
          }
          store {
            id
          }
        }

        ... on VendorChargebackInvoice {
          id
          vendorChargebackRefNumber
          vendorChargebackCheckNumber
          supplierOrderIdentifier
          paidDate
          deliveryDate
          supplier {
            id
          }
        }
      }
    }
  }
`;

export interface DownloadPdfResponse { response: { report: { id: number } } }
export interface DownloadPdfInput { invoiceId: number }
export const DownloadPdfMutation = gql`
mutation GenerateInvoicePdfMutation($invoiceId: Int!) {
  response: GenerateInvoicePdf(invoiceId: $invoiceId) {
    report {
      id
    }
    invoice {
      ... on CustomerOrderInvoice {
        id
        ediInvoicedAt
        ediInvoicedByUser
        firstDownloadedAt
        firstDownloadedByUser
        emailedAt
        emailedByUser
      }
    }
    customerOrder {
      id
      invoiced
      ediInvoiced
      invoiceSentToAcumatica
      invoiceDownloaded
      invoiceEmailed
      orderStatus
    }
  }
}
`;

export interface InvoiceFormValues {
  [FormFields.identifier]: string | null;
  [FormFields.type]: InvoiceType | null;
  [FormFields.invoiceDate]: DateStr | null;
  [FormFields.terms]: string | null;
  [FormFields.notes]: string | null;
  [FormFields.ediInvoiced]: boolean;
  [FormFields.transferredToAccounting]: boolean;

  // Each of the different form types has its own customer ID field (due to layout
  // differences). This caused issues with reduxForm, so the workaround was to have
  // three different field names.
  'customerId-DropShip': number | null;
  'customerId-CustomerOrder': number | null;
  'customerId-VendorChargeback': number | null;

  invoiceDownloaded: boolean; // used for save confirmation
  [FormFields.printedDownloaded]: boolean;
  [FormFields.invoiceEmailed]: boolean;

  [FormFields.customerOrderIdentifier]: string | null;
  [FormFields.customerOrderDeliveryDate]: DateStr | null;
  [FormFields.customerOrderShipDate]?: DateStr | null;
  [FormFields.storeId]: number | null;

  [FormFields.weekEndingDate]: DateStr | null;

  [FormFields.vendorChargebackRefNumber]: string | null;
  [FormFields.vendorChargebackCheckNumber]: string | null;
  [FormFields.vendorChargebackDeliveryDate]: DateStr | null;
  [FormFields.supplierId]: number | null;
  [FormFields.supplierOrderIdentifier]: string | null;
  [FormFields.supplierOrderPlannedArrivalDate]: DateStr | null;
  [FormFields.paidDate]: DateStr | null;
}

export type ActionTypes = Return<typeof onEmailInvoiceDropdownMenuItemClicked>;
export const onEmailInvoiceDropdownMenuItemClicked = () => ({ type: ActionTypeKeys.EmailInvoiceDropdownMenuItemClicked } as const);
export enum ActionTypeKeys { EmailInvoiceDropdownMenuItemClicked = 'App/EMAIL_INVOICE_DROPDOWN_MENU_ITEM_CLICKED' }
type NonNull<T> = T extends null ? never : T;
const required = <T extends unknown>(value: T, label: string): NonNull<T> => {
  if (_.isNull(value))
    throw new Error(`${label} is required.`);

  return value as NonNull<T>;
};

const updateInvoice = async (dispatch: Dispatch<any>, invoiceId: number, invoiceType: InvoiceType, formValues: Partial<InvoiceFormValues>): Promise<number> => {
  const customerIdKey = `customerId-${invoiceType}`;
  const input: UpdateInvoiceInput = {
    invoiceId,
    newInvoiceValues: {
      identifier: required(formValues.identifier, 'Invoice Number'),
      invoiceDate: required(formValues.invoiceDate, 'Invoice Date'),
      terms: formValues.type !== InvoiceType.VendorChargeback ? formValues.terms : undefined,
      notes: formValues.notes,
      customerId: required(formValues[customerIdKey], 'Customer'),
      storeId: required(formValues.storeId, 'Store'),
      weekEndingDate: required(formValues.weekEndingDate, 'Week Ending Date'),
      vendorChargebackRefNumber: required(formValues.vendorChargebackRefNumber, 'PO #'),
      vendorChargebackCheckNumber: formValues.vendorChargebackCheckNumber,
      deliveryDate: required(formValues.vendorChargebackDeliveryDate, 'Store Delivery Date'),
      supplierId: required(formValues.supplierId, 'Supplier'),
      paidDate: formValues.paidDate ? formValues.paidDate : null,
    },
  };
  const result = await msyncClientMutation<UpdateInvoiceResponse>({
    mutation: UpdateInvoiceMutation,
    variables: input,
    dispatch,
  });

  return result.data.updateInvoice.invoice.id;
};

const unableToCreateInvoiceError = () => new Error('Unable to create an invoice for a type other than drop ship or vendor chargeback');
const createInvoice = async (dispatch: Dispatch<any>, formValues: InvoiceFormValues): Promise<number> => {
  const type = formValues[FormFields.type];
  switch (type) {
    case InvoiceType.DropShip: {
      const result = await msyncClientMutation<CreateInvoiceResponse, CreateDropShipInvoiceArgs>({
        mutation: CreateDropShipInvoiceMutation,
        variables: {
          input: {
            customerId: required(formValues[`${FormFields.customerId}-${InvoiceType.DropShip}`], 'Customer'),
            identifier: formValues[FormFields.identifier],
            invoiceDate: required(formValues[FormFields.invoiceDate], 'Invoice Date'),
            terms: formValues[FormFields.terms],
            weekEndingDate: required(formValues[FormFields.weekEndingDate], 'Week Ending Date'),
            notes: formValues[FormFields.notes],
          },
        },
        dispatch,
      });
      return result.data.createInvoice.invoice.id;
    }
    case InvoiceType.VendorChargeback: {
      const result = await msyncClientMutation<CreateInvoiceResponse, CreateVendorChargebackInvoiceArgs>({
        mutation: CreateVendorChargebackInvoiceMutation,
        variables: {
          input: {
            identifier: formValues[FormFields.identifier],
            invoiceDate: required(formValues[FormFields.invoiceDate], 'Invoice Date'),
            notes: formValues[FormFields.notes],
            customerId: required(formValues[`${FormFields.customerId}-${InvoiceType.VendorChargeback}`], 'Customer'),
            vendorChargebackRefNumber: required(formValues[FormFields.vendorChargebackRefNumber], 'PO #'),
            vendorChargebackCheckNumber: formValues[FormFields.vendorChargebackCheckNumber],
            vendorChargebackDeliveryDate: required(formValues[FormFields.vendorChargebackDeliveryDate], 'Delivery Date'),
            supplierId: formValues[FormFields.supplierId]!,
          },
        },
        dispatch,
      });
      return result.data.createInvoice.invoice.id;
    }
    case InvoiceType.CustomerOrder:
    case null:
      throw unableToCreateInvoiceError();
    default:
      return defaultUnreachable(type, unableToCreateInvoiceError);
  }
};

export const formSubmitted = (doNotUseTheseFormValues: InvoiceFormValues, props: { invoiceId?: number, initialValues?: InvoiceFormValues, confirmOkToSave: () => Promise<boolean> }): Thunker =>
  async (dispatch, getState) => {
    const formValues = getFormValues<InvoiceFormValues>(formName)(getState());
    if (!formValues)
      return;

    if (!formValues.type)
      throw new Error('Unknown invoice type!');

    if (!props.invoiceId) {
      const invoiceId = await createInvoice(dispatch, formValues);
      dispatch(push(`/billing/invoicing/details/${invoiceId}`));
      return;
    }

    if (await props.confirmOkToSave()) {
      const modified = props.initialValues ? FormHelpers.getFormChanges(formValues, props.initialValues) : formValues;
      await updateInvoice(dispatch, props.invoiceId, formValues.type, modified);
    }
  };

export const downloadPdf = (invoiceId: number): Thunker =>
  async dispatch => {
    const response = await msyncClientMutation<DownloadPdfResponse, DownloadPdfInput>({
      mutation: DownloadPdfMutation,
      variables: { invoiceId },
      dispatch,
    });
    if (response) {
      const reportId = response.data.response.report.id;
      window.location.replace(`/report/fileDownload/${reportId}`);
    }
  };

const optionFormatter = option => option.label;
const customerTextFormatter = option => `${option.identifier} - ${option.name}`;
const storeTextFormatter = option => option.identifier;
const alwaysRequiredValidator = Validators.makeRequiredFormValidator();
const makeRequiredByInvoiceTypeValidator = (invoiceType: InvoiceType): (value: any, record: any) => string | undefined => Validators.makeRequiredFormValidator({ requiredPredicate: (record: any) => record?.type === invoiceType });
const dropShipRequiredValidator = makeRequiredByInvoiceTypeValidator(InvoiceType.DropShip);
const customerOrderRequiredValidator = makeRequiredByInvoiceTypeValidator(InvoiceType.CustomerOrder);
const vendorChargeBackRequiredValidator = makeRequiredByInvoiceTypeValidator(InvoiceType.VendorChargeback);
const hidden = { display: 'none' };

export const InvoiceSidebar = (p: {
  invoiceId?: number,
  handleSubmit: React.EventHandler<React.FormEvent<Form>>,
  invoiceTypeOptions: Array<{ id: string, label: string }>,
  customerOptions: Options | undefined,
  supplierOptions: Options | undefined,
  storeOptions: Options | undefined,
  isDropShipInvoice: boolean,
  isCustomerOrderInvoice: boolean,
  isVendorChargebackInvoice: boolean,
  shouldDisplayTerms: boolean,
  invoiceDetailsText: string,
  loading?: boolean,
  isInvoiceTypeDisabled: boolean,
  downloadPdf(invoiceId: number): void,
  onEmailInvoiceDropdownMenuItemClicked(): void,
  confirmOkToSave(): Promise<boolean>,
  initialValues: Partial<InvoiceFormValues>,
}) => {
  const menuItems = useDerivation([p.invoiceId, p.downloadPdf, p.onEmailInvoiceDropdownMenuItemClicked], () => !p.invoiceId ? [] : [
    { label: 'Download PDF', action: async (setInProgress: SetInProgress) => { setInProgress(true); try { await p.downloadPdf(p.invoiceId!); } finally { setInProgress(false); } } },
    { label: 'Send Invoice', action: async (setInProgress: SetInProgress) => await p.onEmailInvoiceDropdownMenuItemClicked() },
  ]);

  const dropShipSpecificFields = p.isDropShipInvoice && (
    <div style={p.isDropShipInvoice ? {} : hidden}>
      <FormGroup>
        <FormField formName={formName} name={FormFields.weekEndingDate} label="Week Ending Date" testid="invoice-form-week-ending-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} required validate={dropShipRequiredValidator} />
      </FormGroup>

      <FormGroup>
        { /* NOTE: This "customer" field is the same as the other customer fields on this page, just has a different testid */ }
        <Field formName={formName} name={`${FormFields.customerId}-${InvoiceType.DropShip}`} label="Customer" testid="invoice-form-customer-drop-ship" horizontalLabel={false} options={p.customerOptions || EMPTY_ARRAY} valueField="value" textFormatter={customerTextFormatter} component={LabeledSelect} labelColSize={6} inputColSize={6} required validate={dropShipRequiredValidator} />
      </FormGroup>
    </div>
  );

  const customerOrderSpecificFields = p.isCustomerOrderInvoice && (
    <div style={p.isCustomerOrderInvoice ? {} : hidden}>
      <FormGroup>
        <FormField formName={formName} name={FormFields.customerOrderIdentifier} component={LabeledInput} labelColSize={6} inputColSize={6} horizontalLabel={false} label="PO #" testid="invoice-form-customer-order-identifier" type={INPUT_TYPES.TEXT} disabled required validate={customerOrderRequiredValidator} />
      </FormGroup>
      <FormGroup>
        <FormField formName={formName} name={FormFields.customerOrderDeliveryDate} label="Order Date" testid="invoice-form-customer-order-delivery-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} required disabled />
        <FormField formName={formName} name={FormFields.customerOrderShipDate} label="Ship Date" testid="invoice-form-customer-order-ship-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} required disabled />
      </FormGroup>
      <FormGroup>
      { /* NOTE: This "customer" field is the same as the other customer fields on this page, just has a different testid */ }
        <Field formName={formName} name={`${FormFields.customerId}-${InvoiceType.CustomerOrder}`} label="Customer" testid="invoice-form-customer-customer-order" horizontalLabel={false} options={p.customerOptions || EMPTY_ARRAY} valueField="value" textFormatter={customerTextFormatter} component={LabeledSelect} labelColSize={6} inputColSize={6} required disabled />
        <Field name={FormFields.storeId} component={LabeledSelect} inputColSize={6} label="Store" labelColSize={1} offset={0} testid="invoice-form-store" textFormatter={storeTextFormatter} valueField="value" required horizontalLabel={false} options={p.storeOptions || EMPTY_ARRAY} validate={customerOrderRequiredValidator} />
      </FormGroup>
    </div>
  );

  const vendorChargebackSpecificFields = p.isVendorChargebackInvoice && (
    <div style={p.isVendorChargebackInvoice ? {} : hidden}>
      <FormGroup>
        <FormField formName={formName} name={FormFields.vendorChargebackRefNumber} component={LabeledInput} labelColSize={6} inputColSize={6} horizontalLabel={false} label="PO #" testid="invoice-form-vendor-chargeback-ref-number" type={INPUT_TYPES.TEXT} required validate={vendorChargeBackRequiredValidator} />
        <FormField formName={formName} name={FormFields.vendorChargebackDeliveryDate} label="Store Delivery Date" testid="invoice-form-vendor-chargeback-delivery-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} required validate={vendorChargeBackRequiredValidator} />
        </FormGroup>
      <FormGroup>
        <Field formName={formName} name={FormFields.supplierId} label="Supplier" testid="invoice-form-supplier" horizontalLabel={false} options={p.supplierOptions || EMPTY_ARRAY} valueField="value" textFormatter={customerTextFormatter} component={LabeledSelect} labelColSize={6} inputColSize={6} required validate={vendorChargeBackRequiredValidator} />
        { /* NOTE: This "customer" field is the same as the other customer fields on this page, just has a different testid */ }
        <Field formName={formName} name={`${FormFields.customerId}-${InvoiceType.VendorChargeback}`} label="Customer" testid="invoice-form-customer-vendor-chargeback" horizontalLabel={false} options={p.customerOptions || EMPTY_ARRAY} valueField="value" textFormatter={customerTextFormatter} component={LabeledSelect} labelColSize={6} inputColSize={6} required validate={vendorChargeBackRequiredValidator} />
      </FormGroup>

      <FormGroup>
        <FormField formName={formName} name={FormFields.paidDate} label="Paid Date" testid="invoice-form-vendor-chargeback-paid-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} />
        <FormField formName={formName} name={FormFields.vendorChargebackCheckNumber} label="Credit Memo #" testid="invoice-form-vendor-chargeback-check-number" type={INPUT_TYPES.TEXT} horizontalLabel={false} component={LabeledInput} inputColSize={6} maxLength={255} />
      </FormGroup>
    </div>
  );

  return p.loading ? <ProgressSpinner label="Loading..." waitToDisplayMillis={200} /> : (
    <div className="mfc-form-sidebar">
      {p.invoiceId && <SendInvoiceModal invoiceId={p.invoiceId} /> }

      <Form horizontal onSubmit={p.handleSubmit}>
        <HiddenFocusField />
        <FormGroup>
          <FormField formName={formName} name={FormFields.identifier} component={LabeledInput} labelColSize={6} inputColSize={6} horizontalLabel={false} label="Invoice #" testid="invoice-form-identifier" type={INPUT_TYPES.TEXT} maxLength={22} normalize={FormHelpers.TrimString} autoFocus={!p.invoiceId} />
          {!!p.invoiceId &&
            <Col sm={6} className="invoicing-actions-menu-container">
              <SimpleMenu label="Actions" className="invoicing-actions-menu" menuItems={menuItems} />
            </Col>
          }
        </FormGroup>
        <FormGroup>
          <FormField formName={formName} name={FormFields.type} label="Invoice Type" testid="invoice-form-type" horizontalLabel={false} options={p.invoiceTypeOptions} valueField="value" textFormatter={optionFormatter} component={LabeledSelect} labelColSize={6} inputColSize={6} required validate={alwaysRequiredValidator} disabled={p.isInvoiceTypeDisabled} />
          <FormField formName={formName} name={FormFields.invoiceDate} label="Invoice Date" testid="invoice-form-invoice-date" horizontalLabel={false} component={LabeledDate} inputColSize={6} labelColSize={6} required validate={alwaysRequiredValidator} />
        </FormGroup>
        {p.shouldDisplayTerms &&
          <FormGroup>
            <FormField formName={formName} name={FormFields.terms} label="Terms" testid="invoice-form-terms" type={INPUT_TYPES.TEXT} horizontalLabel={false} component={LabeledInput} inputColSize={6} maxLength={255} />
          </FormGroup>
        }

        <hr className="sidebar-horizontal-rule" />
        <FormGroup>
          <Col sm={12}>
            <div className="mfc-form-stacked-label sidebar-horizontal-rule-detail-text">{p.invoiceDetailsText}</div>
          </Col>
        </FormGroup>

        {dropShipSpecificFields}
        {customerOrderSpecificFields}
        {vendorChargebackSpecificFields}

        <hr className="sidebar-horizontal-rule" />

        <FormGroup>
          <FormField formName={formName} name={FormFields.printedDownloaded} inputColSize={6} component={LabeledCheckbox} label="Printed/Downloaded" testid="invoice-form-printed-downloaded" disabled />
        </FormGroup>

        <FormGroup className="tighten-to-above-form-group">
          <FormField formName={formName} name={FormFields.invoiceEmailed} inputColSize={6} component={LabeledCheckbox} label="Emailed" testid="invoice-form-emailed" disabled />
        </FormGroup>

        <FormGroup className="tighten-to-above-form-group">
          <FormField formName={formName} name={FormFields.ediInvoiced} inputColSize={6} component={LabeledCheckbox} label="EDI Invoice Sent" testid="invoice-form-edi-invoiced" disabled />
        </FormGroup>

        <FormGroup className="tighten-to-above-form-group">
          <FormField formName={formName} name={FormFields.transferredToAccounting} inputColSize={6} component={LabeledCheckbox} label="Transferred to Acumatica" testid="invoice-form-transferred-to-accounting" disabled />
        </FormGroup>

        <FormGroup>
          <FormField formName={formName} name={FormFields.notes} component={LabeledInput} type={INPUT_TYPES.TEXTAREA} inputColSize={12} label="Notes" labelColSize={12} offset={0} horizontalLabel={false} testid="invoice-form-notes" />
        </FormGroup>
      </Form>
    </div>
  );
};

interface OwnProps { invoiceId?: number; }
interface WithSaveConfirmationProps { confirmOkToSave(): Promise<boolean>; }
interface WithInitialValuesProps { invoiceInfoLoading?: boolean; initialValues?: InvoiceFormValues; }

const withInvoiceInfo = msyncQuery<QueryResponse, OwnProps, WithInitialValuesProps, { invoiceId: number }>(Query, {
  alias: 'withInvoiceInfo',
  skip: ownProps => ownProps.invoiceId === undefined,
  options: ownProps => !ownProps.invoiceId ? orThrowBug('Must have an invoice ID') : {
    variables: { invoiceId: ownProps.invoiceId },
    fetchPolicy: 'network-only',
  },
  props: ({ownProps, data: {getInvoice: {invoice} = {}}}) => ({
    invoiceInfoLoading: !!ownProps.invoiceId && !invoice,
    initialValues: invoice === undefined ? undefined : {
      ...invoice,
      'invoiceDownloaded'           : !!invoice.firstDownloadedAt,
      [FormFields.printedDownloaded]: !!invoice.firstDownloadedAt,
      [FormFields.invoiceEmailed]   : !!invoice.emailedAt        ,

      'customerId-DropShip'        : invoice.customer.id,
      'customerId-CustomerOrder'   : invoice.customer.id,
      'customerId-VendorChargeback': invoice.customer.id,

      // Customer Order invoice stuff
      [FormFields.customerOrderIdentifier]          : invoice.type === InvoiceType.CustomerOrder    ? invoice.customerOrder.identifier        : null,
      [FormFields.customerOrderDeliveryDate]        : invoice.type === InvoiceType.CustomerOrder    ? invoice.deliveryDate                    : null,
      [FormFields.customerOrderShipDate]            : invoice.type === InvoiceType.CustomerOrder    ? invoice.customerOrder.shipDate          : null,
      [FormFields.storeId]                          : invoice.type === InvoiceType.CustomerOrder    ? invoice.store.id                        : null,

      // Drop Ship invoice stuff
      [FormFields.weekEndingDate]                   : invoice.type === InvoiceType.DropShip         ? invoice.weekEndingDate                  : null,

      // Vendor Chargeback invoice stuff
      [FormFields.vendorChargebackRefNumber]        : invoice.type === InvoiceType.VendorChargeback ? invoice.vendorChargebackRefNumber       : null,
      [FormFields.vendorChargebackCheckNumber]      : invoice.type === InvoiceType.VendorChargeback ? invoice.vendorChargebackCheckNumber     : null,
      [FormFields.vendorChargebackDeliveryDate]     : invoice.type === InvoiceType.VendorChargeback ? invoice.deliveryDate                    : null,
      [FormFields.supplierId]                       : invoice.type === InvoiceType.VendorChargeback ? invoice.supplier.id                     : null,
      [FormFields.supplierOrderIdentifier]          : invoice.type === InvoiceType.VendorChargeback ? invoice.supplierOrderIdentifier         : null,
      [FormFields.supplierOrderPlannedArrivalDate]  : invoice.type === InvoiceType.VendorChargeback ? invoice.supplierOrderPlannedArrivalDate : null,
      [FormFields.paidDate]                         : invoice.type === InvoiceType.VendorChargeback ? invoice.paidDate                        : null,
    },
  }),
});

interface StateProps {
  invoiceTypeOptions: Array<{ id: string, label: string }>;
  isDropShipInvoice: boolean;
  isCustomerOrderInvoice: boolean;
  isVendorChargebackInvoice: boolean;
  shouldDisplayTerms: boolean;
  selectedCustomerId?: number;
  invoiceDetailsText: string;
  isInvoiceTypeDisabled: boolean;
  confirmOkToSave(): Promise<boolean>;
  initialValues: Partial<InvoiceFormValues>;
}

const invoiceTypeOptionsForCreate = [
  { id: InvoiceType.DropShip, label: 'Drop Ship' },
  { id: InvoiceType.VendorChargeback, label: 'Vendor Chargeback' },
];
const invoiceTypeOptionsForExisting = [
  { id: InvoiceType.CustomerOrder, label: 'Customer Order' },
  { id: InvoiceType.DropShip, label: 'Drop Ship' },
  { id: InvoiceType.VendorChargeback, label: 'Vendor Chargeback' },
];

const defaultInitialValues = { type: InvoiceType.DropShip, terms: 'Net7' };

export const SidebarContainer = flowRight(
  withInvoiceInfo, // Must come before WithReduxForm so the initialValues are set
  optionsContainerGenerator({ resultPropName: 'customerOptions', table: 'customers', columns: ['identifier', 'name'] }),
  optionsContainerGenerator({ resultPropName: 'supplierOptions', table: 'suppliers', columns: ['identifier', 'name'] }),
  withSaveConfirmation(saveConfirmationOptionsForInvoice),
  connect(
    (state: State.Type, ownProps: OwnProps & WithSaveConfirmationProps & WithInitialValuesProps & { customerOptions: unknown }): StateProps => {
      const invoice = ownProps.initialValues || getFormValues(formName)(state) as InvoiceFormValues || defaultInitialValues;
      const invoiceDetailsText
        = invoice.type === InvoiceType.DropShip         ? 'Drop Ship Invoice Details'
        : invoice.type === InvoiceType.CustomerOrder    ? 'Customer Order Invoice Details'
        : invoice.type === InvoiceType.VendorChargeback ? 'Vendor Chargeback Invoice Details'
        :                                                 'Invoice Details';

      const result = {
        loading: ownProps.invoiceInfoLoading || !ownProps.customerOptions,
        invoiceTypeOptions: ownProps.invoiceId ? invoiceTypeOptionsForExisting : invoiceTypeOptionsForCreate,
        isInvoiceTypeDisabled: !!ownProps.invoiceId, // Not allowed to change the invoice type once it's been created
        isDropShipInvoice: invoice.type === InvoiceType.DropShip,
        isCustomerOrderInvoice: invoice.type === InvoiceType.CustomerOrder,
        isVendorChargebackInvoice: invoice.type === InvoiceType.VendorChargeback,
        shouldDisplayTerms: invoice?.[FormFields.type] !== InvoiceType.VendorChargeback,
        selectedCustomerId: invoice ? normalizeNullToUndefined(invoice[`customerId-${invoice.type}`]) : undefined,
        invoiceDetailsText,
        initialValues: ownProps.initialValues || defaultInitialValues,
        confirmOkToSave: ownProps.confirmOkToSave,
      };

      return result;
    },
    {
      downloadPdf,
      onEmailInvoiceDropdownMenuItemClicked,
    }),
  // Must come after connect/mapStateToProps
  optionsContainerWithFilters({
    table: 'stores',
    columns: ['identifier'],
    resultPropName: 'storeOptions',
    skip: (props: StateProps) => !props.selectedCustomerId || !props.isCustomerOrderInvoice,
    getFilters: (props: StateProps) => [ { field: 'customer', values: [props.selectedCustomerId?.toString()] } ],
  }),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    async onSubmit(formValues: InvoiceFormValues, dispatch, props: OwnProps & WithSaveConfirmationProps & WithInitialValuesProps): Promise<void> { dispatch(formSubmitted(formValues, props)); },
  }),
  WithWarnUnsaved({ recordIdProperty: 'invoiceId' }),
)(InvoiceSidebar);

export default SidebarContainer;
