import 'reflect-metadata';
import { tableName, property, definePresentation, belongsTo, setSchemaOptions, required } from './dsl';
import { Product, ProductId } from './product';
import { DISPLAY_TYPES, TYPES } from '../types';
import { IRecord, Saved } from './record';
import { Customer, CustomerId } from 'shared/schemas/customer';
import { displayType } from './dsl';

@tableName('productUpcs')
export class ProductUpc implements IRecord {
  id?: number;
  @property @required identifier: string;
  @property notes?: string | null;

  @belongsTo('products')
  @property @required product: Saved<Product>;
  productId: ProductId;

  @property primary: boolean;

  @belongsTo('customers', { through: 'products' })
  @property customer: Customer;
  customerId: CustomerId;

  // There can be multiple UPCs for a product. This flag indicates which
  // one is currently enabled.
  // see also duplicateSkuUpc (was on product records)
  @property enabled: boolean;
}

definePresentation(ProductUpc, {
  identifier: {
    displayName: 'UPC Code',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  notes: {
    displayName: 'UPC Notes',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  primary: {
    type: TYPES.BOOLEAN,
    formDisplay: true,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.RADIO),
    defaultValue: false,
  },
  product: {
    formDisplay: false,
  },
  customer: {
    formDisplay: false,
  },
  enabled: {
    formDisplay: false,
    includeInFormQuery: true,
  },
});

setSchemaOptions(ProductUpc, {
  softDeletable: true,
});
