import buildAdminCreatePage from 'client/containers/admin/admin-create-page';
import { SupplierOrderForm } from '../overview/supplier-order-form';
import SupplierOrderStatusToggle from '../record-bar-status-toggle';

export default buildAdminCreatePage({
  table: 'supplierOrders',
  formName: 'SupplierOrderForm',
  FormComponent: SupplierOrderForm,
  OptionalStatusToggle: SupplierOrderStatusToggle,
});
