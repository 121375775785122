import 'isomorphic-fetch';
import { ExpectedError, orThrow, SendError } from 'shared/errors';

interface SendArgs {
  url: string;
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  formData?: FormData;
  data?: any;
  headers?: Headers | { [k: string]: any };
  asBlob?: boolean;
  includeFilename?: boolean;
  overwriteHeaders?: boolean;
  body?: ReadableStream<Uint8Array>;
}

type Response<T, A>
  = A extends { includeFilename: true, asBlob: true } ? { filename: string, content: Blob }
  : A extends { includeFilename: true }               ? { filename: string, content: T }
  : A extends { asBlob: true }                        ? Blob
  :                                                    T;

export const POST = async <T>(url: string, data?: any) => await send<T>({ method: 'POST', url, data });
export const GET = async <T>(url: string, data?: any) => await send<T>({ method: 'GET', url, data });

export const putFile = async (url: string, file: ReadableStream<Uint8Array> | ArrayBuffer, headers?: Headers | { [k: string]: any }): Promise<void> => { // The type of the file parameter might not be right for browser
  const response = await fetch(url, { method: 'PUT', body: file, headers: headers || {} });
  if (!response.ok)
    throw new Error(`Unable to put file: ${await response.text()}`);
};

export const send = async <T, A extends SendArgs = SendArgs>(args: A) => {
  const response = await fetch(args.url, {
    method: args.method,
    body: args.formData || args.body || JSON.stringify(args.data),
    credentials: 'same-origin',
    headers: {
      ...(args.overwriteHeaders ? {} : { Accept: 'application/json', ['Content-Type']: 'application/json' }),
      ...(args.headers || {}),
    },
  });

  // Look for error status codes ==> We should never receive an error without a 400 or greater
  if (response.status >= 400) {
    if (!(response.headers.get('content-type') ?? response.headers.get('Content-Type') ?? '').match(/json/))
      throw new SendError(args.url, response.status, await response.text());

    const errorContent =  await response.json();
    throw errorContent.isExpectedError
      ? new ExpectedError(errorContent.message, errorContent.debugInfo)
      : new SendError(args.url, response.status, errorContent);
  }

  const content
    = !!args.asBlob                                               ? await response.blob()
    : (response.headers.get('content-type') || '').match(/json/)  ? await response.json() as T
    :                                                               await response.text();

  if (args.includeFilename) {
    const disposition = response.headers.get('Content-Disposition') || orThrow(`Content-Disposition with filename must be included in the response headers`);
    const match       = disposition.match(/filename="([^"]+)"/)     || orThrow(`Content-Disposition is malformed and does not have a valid filename`);
    const filename    = match[1]                                    || orThrow(`Content-Disposition is malformed and does not have a valid filename`);
    return { content, filename } as Response<T, A>;
  }

  return content as Response<T, A>;
};
