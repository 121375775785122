import {
  SET_SELECTED_SPEC_YEAR,
} from '../constants';

export function setSelectedSpecYear(year) {
  return {
    type: SET_SELECTED_SPEC_YEAR,
    payload: { year },
  };
}
