import { tableName, property, belongsTo, definePresentation, setSchemaOptions, hasMany, required } from './dsl';
import { Customer } from './customer';
import { Region } from './region';
import { DISPLAY_TYPES, ActiveInactive, SORT_TYPES, TYPES } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';
import { Store } from 'shared/schemas/store';
import { CustomerDisplayValueResolver, RegionDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type MarketId = Flavor<number, 'marketId'>;

@tableName('markets')
export class Market implements IRecord {
  id?: MarketId;
  @property @required identifier: string;
  @property @required description: string;

  @belongsTo('customers', { through: 'regions', foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property customer: Customer;

  @belongsTo('regions')
  @property @required region: Region;
  regionId: number;

  @property regionIdentifier: string;

  @hasMany('stores')
  @property stores?: Array<Partial<Store>>;
  @property @required activeStatus: ActiveInactive;
}

definePresentation(Market, {
  customer: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
  },
  region: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: RegionDisplayValueResolver }),
  },
  identifier: {
    sortable: true,
    filterable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Market',
  },
  description: {
    sortable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 50,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  regionIdentifier: {
    tableDisplay: false,
    displayName: 'Region',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `
        (SELECT regions.identifier FROM regions WHERE regions.id = markets.region_id)
      `,
    },
  },

});

setSchemaOptions(Market, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'customer', foreignColumn: 'name' },
});
