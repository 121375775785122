export const INCREMENT_COUNTER = 'App/INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'App/DECREMENT_COUNTER';

export const LOGIN_USER_PENDING = 'App/LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'App/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'App/LOGIN_USER_ERROR';
export const LOGIN_SESSION_LOADED = 'App/LOGIN_SESSION_LOADED';
export const LOGIN_SESSION_NOT_FOUND = 'App/LOGIN_SESSION_NOT_FOUND';

export const LOGOUT_USER = 'App/LOGOUT_USER';
export const FORM_RESET = 'redux-form/RESET';

export const TOGGLE_SORT = 'App/TOGGLE_SORT';
export const TOGGLE_FILTER = 'App/TOGGLE_FILTER';
export const SET_FILTER = 'App/SET_FILTER';
export const CLEAR_ALL_FILTERS = 'App/CLEAR_ALL_FILTERS';
export const SET_DATE_FILTER = 'App/SET_DATE_FILTER';

export const TOGGLE_CHECK_ALL_RECORDS = 'App/TOGGLE_CHECK_ALL_RECORDS';
export const TOGGLE_CHECK_SINGLE_RECORD = 'App/TOGGLE_CHECK_SINGLE_RECORD';
export const UNCHECK_MULTIPLE_RECORDS = 'App/UNCHECK_MULTIPLE_RECORDS';
export const UNCHECK_ALL_RECORDS = 'App/UNCHECK_ALL_RECORDS';

export const SET_SEARCH_FIELD = 'App/SET_SEARCH_FIELD';
export const SET_SEARCH_TEXT = 'App/SET_SEARCH_TEXT';

export const SET_SELECTED_SPEC_YEAR = 'App/SET_SELECTED_SPEC_YEAR';
export const GLOBAL_SAVE_STARTED = 'App/GLOBAL_SAVE_STARTED';
export const GLOBAL_SAVE_FINISHED = 'App/GLOBAL_SAVE_FINISHED';

export const NAVIGATE_TABLE = 'App/NAVIGATE_TABLE';
export const EDIT_TABLE = 'App/EDIT_TABLE';
export const MOVE_EDIT_CELL = 'App/MOVE_EDIT_CELL';
export const EDIT_NEXT_EDITABLE_CELL = 'App/EDIT_NEXT_EDITABLE_CELL';
export const EDIT_LAST_EDITABLE_CELL = 'App/EDIT_LAST_EDITABLE_CELL';
export const TABLE_HAS_INVALID_CELL = 'App/TABLE_HAS_INVALID_CELL';
export const CLICK_CELL = 'App/CLICK_CELL';
export const CLICK_OUTSIDE_TABLE = 'App/CLICK_OUTSIDE_TABLE';
export const SET_TABLE_PAGE_NUMBER = 'App/SET_TABLE_PAGE_NUMBER';
export const SET_TABLE_ROWS_PER_PAGE = 'App/SET_TABLE_ROWS_PER_PAGE';
export const OPEN_ORDER_DELETE_CONFIRMATION_MODAL = 'App/OPEN_ORDER_DELETE_CONFIRMATION_MODAL';
export const CLOSE_ORDER_DELETE_CONFIRMATION_MODAL = 'App/CLOSE_ORDER_DELETE_CONFIRMATION_MODAL';

export const CUSTOMER_ORDER_OVERVIEW_TABLE_NAME = 'customerOrderAllocations';
export const SUPPLIER_ORDER_DETAILS_TABLE_NAME = 'supplierOrderProducts';
export const SUPPLIER_ORDER_RELATED_CUSTOMER_ORDERS_TABLE_NAME = 'supplierOrderRelatedCustomerOrdersTable';
export const PRODUCT_WORKSHEET_TABLE_NAME = 'productWorksheet';
export const RELATED_SUPPLIER_ORDERS_TABLE_NAME = 'relatedSupplierOrders';
export const SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME = 'selectRelatedSupplierOrders';
export const CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME = 'customerOrderReconciliation';
export const TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME = 'transportationReceivingSummary';
export const TRANSPORTATION_DISTRIBUTION_RECEIVING_SUMMARY_TABLE_NAME = 'transportationDistributionReceivingSummary';
export const TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME = 'transportationReceivingOverview';
export const TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME = 'transportationReceivingWorksheet';
export const PRODUCT_TOSS_TABLE_NAME = 'productToss';
export const STORE_ATTACHMENTS_TABLE_NAME = 'storeAttachmentsTable';
export const PRODUCT_ATTACHMENTS_TABLE_NAME = 'productAttachmentsTable';

export const TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME = 'transportationRoutePlans';
export const TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME = 'TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME';
export const TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME = 'transportationRoutePlanReviewStores';
export const TRANSPORTATION_LOAD_LIST_TABLE_NAME = 'TRANSPORTATION_LOAD_LIST_TABLE_NAME';

export const TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME = 'transportationRoutePlanAttachedOrders';
export const TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME = 'transportationRoutePlanAttachOrders';
export const CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME = 'cartTrackingAllSuppliers';
export const CART_TRACKING_SUPPLIER_TABLE_NAME = 'cartTrackingSupplier';
export const TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME = 'transportationRoutingLoadStops';
export const TRANSPORTATION_SPLIT_STOPS = 'splitStopTable';
export const APPLY_LOADS_TO_TRAILERS_TABLE = 'applyLoadsToTrailersTable';

export const ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY';
export const ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY';

export const PRODUCT_WORKSHEET_PRODUCT_CHECKED = 'App/PRODUCT_WORKSHEET_PRODUCT_CHECKED';
export const PRODUCT_WORKSHEET_COMBO_CART_CHECKED = 'App/PRODUCT_WORKSHEET_COMBO_CART_CHECKED';

export const PRODUCT_WORKSHEET_PRODUCT_CLICKED = 'App/PRODUCT_WORKSHEET_PRODUCT_CLICKED';
export const PRODUCT_WORKSHEET_PRODUCT_NAVIGATED = 'App/PRODUCT_WORKSHEET_PRODUCT_NAVIGATED';

export const PRODUCT_WORKSHEET_UNMOUNTED = 'App/PRODUCT_WORKSHEET_UNMOUNTED';

export const PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_ADD_NEW_PRODUCT_BUTTON_CLICKED';
// export const PRODUCT_WORKSHEET_ADD_FROM_SALES_PLAN_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_ADD_FROM_SALES_PLAN_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_ADD_NEW_RACK_TYPE_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_REMOVE_PRODUCT_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_RACK_TYPE_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_COMBO_CART_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_REMOVE_RACK_TYPE_BUTTON_CLICKED';

export const PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_SELECT_RECENT_COMBO_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_CANCEL_SELECT_RECENT_COMBO_BUTTON_CLICKED';

export const PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_COMBO_CART_CANCEL_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_COMBO_CART_MODAL_CANCEL_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED = 'App/PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_CLOSED';

export const PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_PRODUCT_MODAL_SAVE_BUTTON_CLICKED';
export const PRODUCT_WORKSHEET_EDIT_COMBO_CART_MODAL_SAVE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_EDIT_COMBO_CART_MODAL_SAVE_BUTTON_CLICKED';

export const PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED = 'App/PRODUCT_WORKSHEET_REMOVE_PRODUCT_MODAL_REMOVE_BUTTON_CLICKED';

export const PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS = 'App/PRODUCT_WORKSHEET_SET_ADD_PRODUCTS_FROM_SALES_PLAN_MUTATION_STATUS';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_CHANGED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_STARTED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_COMPLETED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_SAVE_FAILED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_STARTED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_COMPLETED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_DETAIL_REFETCH_FAILED';
export const PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED = 'App/PRODUCT_WORKSHEET_PRODUCT_ALLOCATION_PENDING_ALLOCATION_RESET_NEEDED';

export const SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED';
export const SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED';
export const SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED';
export const SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED';
export const SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED';
export const SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED = 'App/SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED';
export const SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY = 'App/SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY';
export const SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS = 'App/SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS';
export const SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED = 'App/SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED';
export const SUPPLIER_ORDER_SELECT_ALL_CLICKED = 'App/SUPPLIER_ORDER_SELECT_ALL_CLICKED';
export const SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS = 'App/SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS';

export const RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY = 'App/RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY';
export const RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS = 'App/RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS';
export const RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS = 'App/RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS';
export const RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED = 'App/RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED';
export const RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED = 'App/RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED';

export const CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY = 'App/CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY';
export const CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS = 'App/CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS';

export const BILLING_INVOICES_TABLE_NAME = 'App/BILLING_INVOICES_TABLE_NAME';
export const BILLING_INVOICES_LINE_ITEMS_TABLE_NAME = 'App/BILLING_INVOICES_LINE_ITEMS_TABLE_NAME';

export const SALES_PLAN_TABLE_NAME = 'App/SALES_PLAN_TABLE_NAME';

export const SUPPLIER_COMMITMENT_TABLE_NAME = 'App/SUPPLIER_COMMITMENT_TABLE_NAME';

export enum ErrorActionTypeKeys {
  ERRORS_VALIDATION_ERROR_RECEIVED = 'App/ERRORS_VALIDATION_ERROR_RECEIVED',
  ERRORS_ERROR_ALERT_CLOSE_CLICKED = 'App/ERRORS_ERROR_ALERT_CLOSE_CLICKED',
  ERRORS_EXPECTED_ERROR_RECEIVED = 'App/ERRORS_EXPECTED_ERROR_RECEIVED',
  ERRORS_UNEXPECTED_ERROR_RECEIVED = 'App/ERRORS_UNEXPECTED_ERROR_RECEIVED',
}

export const NO_OP = 'App/NO_OP';

export const EMPTY_ARRAY = [];

export const VENDOR_LIST_PAGE_TABLE_NAME = 'App/VENDOR_LIST_PAGE_TABLE_NAME';

export const INVOICE_DETAIL_ADD_ROUTE_PLANS_MODAL_TABLE = 'App/INVOICE_DETAIL_ADD_ROUTE_PLANS_MODAL_TABLE';
