import { CustomerOrder } from 'shared/schemas/customer-order';
import { CustomerOrderStatus } from 'shared/types';
import * as _ from 'lodash';
import { SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';

export const saveConfirmationMessageFunction = (record?: CustomerOrder) => {
  if (_.isNil(record) || _.isNil(record.orderStatus)) {
    return {
      title: '',
      body: '',
    };
  }

  if (record.invoiceSentToAcumatica && record.ediInvoiced) {
    return {
      title: 'Invoice Sent via EDI and Sent to Acumatica',
      body: `This order's invoice has been sent to the customer via EDI and sent to Acumatica. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.invoiceSentToAcumatica && record.invoiceEmailed) {
    return {
      title: 'Invoice Emailed and Sent to Acumatica',
      body: `This order's invoice has been emailed and sent to Acumatica. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.invoiceSentToAcumatica && record.invoiceDownloaded) {
    return {
      title: 'Invoice Downloaded and Sent to Acumatica',
      body: `This order's invoice has been downloaded and sent to Acumatica. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.invoiceSentToAcumatica) {
    return {
      title: 'Invoice Sent to Acumatica',
      body: `This order's invoice has been sent to Acumatica. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.ediInvoiced) {
    return {
      title: 'Invoice Sent via EDI',
      body: `This order's invoice has been sent to the customer via EDI. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.invoiceEmailed) {
    return {
      title: 'Invoice Emailed',
      body: `This order's invoice has been emailed. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.invoiceDownloaded) {
    return {
      title: 'Invoice Downloaded',
      body: `This order's invoice has been downloaded to PDF. Be sure to communicate these changes with others who may be affected.`,
    };
  }

  switch (record && record.orderStatus) {
    case CustomerOrderStatus.Approved: {
      return {
        title: 'Approved Order',
        body: `You're changing details for an Approved order. Be sure to communicate these changes with others who may be affected.`,
      };
    }
    case 'Routed': {
      return {
        title: 'Approved Order',
        body: `You're changing details for an Routed order. Be sure to communicate these changes with the Transportation department.`,
      };
    }
    default:
      return {
        title: '',
        body: '',
      };
  }
};

export const saveConfirmationOptionsForCustomerOrder: SaveConfirmationArgs<any> = {
  onlyAskOnce: true,
  shouldDisplay: (record?: CustomerOrder) => !_.isNil(record) && ((!_.isNil(record.orderStatus) && record.orderStatus !== CustomerOrderStatus.Draft) || record.ediInvoiced || record.invoiceSentToAcumatica || record.invoiceDownloaded || record.invoiceEmailed),
  message: saveConfirmationMessageFunction,
};
