import { Lens } from '@atomic-object/lenses';

interface ProductState {
  readonly downloadSpecSheetModalShown: boolean;
}

export type Type = ProductState;

export const InitialState: Readonly<ProductState> = Object.freeze({
  downloadSpecSheetModalShown: false,
});

export const downloadSpecSheetModalShown = Lens.from<Type>().prop('downloadSpecSheetModalShown');
