import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';

import { RelatedBolsTable as RelatedBolsTableUI, RelatedBolsComponentProps } from './related-bols-table';
import { propToComponent } from 'client/hoc/hoc';
import { EMPTY_ARRAY } from 'client/constants';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { RelatedBolsResponse, RelatedBolsRow, RelatedBolsQuery, ReceivableOrderInfoQuery, ReceivableOrderInfoResponse, FilterableQuery } from './related-bols-query';
import { ActiveSort, ActiveSearch, ActiveFilter, AvailableFilter } from 'client/types';
import { ShippingUnitType, OrderMethod } from 'shared/types';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import { GetUnifiedReceivableOrderBolsFilterOptionsResponse, GetUnifiedReceivableOrderBolsFilterOptionsArgs } from 'schema/unified-receivable-order/unified-receivable-order-graphql-types';
import { sortNaturally } from 'shared/helpers/sort-naturally';
import { buildTableStateModule } from 'client/state/tables';

const tableName = 'bols';

interface OwnProps {
  receivableOrderId: number;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

interface WithAvailableFiltersProps {
  availableFilters: AvailableFilter[];
}

interface WithSearchableFieldsProps {
  searchableFields: RelatedBolsComponentProps['searchableFields'];
}

const withSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface WithRelatedBolsProps {
  tableRows?: RelatedBolsComponentProps['tableRows'];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  receivableOrderIdentifier?: string;
  dataRequest: MsyncDataRequest;
}

const sortTable = (rows: RelatedBolsRow[], naturallySortableFields: string[], sort: ActiveSort[] | null | undefined) => {
  if (sort && sort.some(s => naturallySortableFields.includes(s.sortField))) {
    return sortNaturally(rows, sort);
  }

  // These rows were already sorted by the database, no need to re-sort.
  return rows;
};

const fieldsToSortNaturally = ['identifier'];

const WithRelatedBolsRows = msyncQuery<RelatedBolsResponse, OwnProps & StateProps & WithTableParentProps, WithRelatedBolsProps>(RelatedBolsQuery, {
  alias: 'withReceivingRows',
  options(ownProps): { variables, fetchPolicy } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: ownProps.activeSearch,
        id: ownProps.receivableOrderId,
      },
      fetchPolicy: 'network-only', // :-)
    };
  },
  props(props): WithRelatedBolsProps {
    const { data, ownProps } = props;

    const receivableOrderIdentifier = data.receivableOrder
      ? data.receivableOrder.identifier
      : '';

    const tableRows: RelatedBolsRow[] = (data.receivableOrder ? (data.receivableOrder.records || []) : []);

    // This table is not paginated so there is no need to have a separate list of
    // "unfiltered" record ids/total unfiltered counts.
    return {
      tableRows: sortTable(tableRows, fieldsToSortNaturally, ownProps.activeSortFields),
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: idsFor(tableRows),
      totalCount: tableRows.length,
      totalUnfilteredCount: tableRows.length,
      receivableOrderIdentifier,
      dataRequest: {
        query: RelatedBolsQuery,
        customAccessor: 'receivableOrder.bols',
        workbookName: `${receivableOrderIdentifier} - Related BOLs`,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: ownProps.activeSearch,
          id: ownProps.receivableOrderId,
        },
      },
    };
  },
});

interface WithReceivableOrderInfoProps {
  shippingUnitType?: ShippingUnitType;
  orderMethod?: OrderMethod;
}

const WithReceivableOrderInfo = msyncQuery<ReceivableOrderInfoResponse, OwnProps & StateProps & WithTableParentProps, WithReceivableOrderInfoProps>(ReceivableOrderInfoQuery, {
  alias: 'withReceivableOrderInfo',
  skip(ownProps) {
    return _.isNil(ownProps.receivableOrderId);
  },
  options(ownProps): { variables } {
    return {
      variables: {
        receivableOrderId: ownProps.receivableOrderId,
      },
    };
  },
  props(response): WithReceivableOrderInfoProps {
    return {
      shippingUnitType: response.data.receivableOrder ? response.data.receivableOrder.shippingUnitType : undefined,
      orderMethod: response.data.receivableOrder ? response.data.receivableOrder.orderMethod : undefined,
    };
  },
});

const WithAvailableFilters = msyncQuery<GetUnifiedReceivableOrderBolsFilterOptionsResponse, OwnProps & WithTableParentProps, WithAvailableFiltersProps, GetUnifiedReceivableOrderBolsFilterOptionsArgs>(FilterableQuery, {
  alias: 'withUnifiedReceivingAvailableFilters',
  skip(ownProps: OwnProps & WithTableParentProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps) {
    return {
      fetchPolicy: 'cache-first',
      variables: {
        id: ownProps.receivableOrderId,
      },
    };
  },
  props(props): WithAvailableFiltersProps {
    const { data } = props;

    return {
      availableFilters: [
        {
          displayName: 'Receiver',
          field: 'receiver',
          options: data.response ? data.response.receivers : EMPTY_ARRAY,
        },
      ],
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithRelatedBolsProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithReceivableOrderInfoProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  withSearchableFields,
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  withFilterAndSortCapabilities(tableName),
  WithReceivableOrderInfo,
  WithRelatedBolsRows,
  WithAvailableFilters,
)(RelatedBolsTableUI) as Component<OwnProps>;

export const RelatedBolsTable = (props: OwnProps) => propToComponent(component, props);

export default RelatedBolsTable;
