import * as _ from 'lodash';

export const SupplierOrderComparator = {
  isEqualForReceivingReceiptPurposes,
};

interface NormalizeForReceivingReceiptComparisonArg {
  supplierOrderProductGroups: Array<{
    identifier: string;
    supplierOrderProducts: Array<{
      supplierItemId: number;
      productDescription: string;
      packQuantity: number
    }>;
  }>;
}
/**
 * Convert a SupplierOrder like object into something that can be used
 * in an equality comparison to see if a new receiving receipt needs to be sent.
 */
const normalizeForReceivingReceiptComparison = (supplierOrder: NormalizeForReceivingReceiptComparisonArg) => {
  return _.sortBy(supplierOrder.supplierOrderProductGroups.map(sopg => {
    return {
      identifier: sopg.identifier,
      supplierOrderProducts: _.sortBy(sopg.supplierOrderProducts.map(sop => {
        return {
          supplierItemId: sop.supplierItemId,
          productDescripton: sop.productDescription,
          packQuantity: sop.packQuantity,
        };
      }), obj => obj.supplierItemId),
    };
  }), obj => obj.identifier);
};

interface EqualForReceivingReceiptPurposesArg {
  supplierOrderProductGroups: Array<{
    identifier: string;
    supplierOrderProducts: Array<{
      supplierItemId: number;
      productDescription: string;
      packQuantity: number;
    }>;
  }>;
}
function isEqualForReceivingReceiptPurposes(order1: EqualForReceivingReceiptPurposesArg, order2: EqualForReceivingReceiptPurposesArg): boolean {
  const normalizedOrder1 = normalizeForReceivingReceiptComparison(order1);
  const normalizedOrder2 = normalizeForReceivingReceiptComparison(order2);
  return _.isEqual(normalizedOrder1, normalizedOrder2);
}
