import gqlTag from 'graphql-tag';
import { CustomerOrderId, CustomerOrderIdentifier } from 'shared/schemas/customer-order';
import { CustomerOrderProductGroupId, CustomerOrderProductGroupIdentifier, CustomerOrderProductGroupPacksPerShippingUnit } from 'shared/schemas/customer-order-product-group';
import { ProductId, ProductIdentifier, ProductDescription } from 'shared/schemas/product';
import { buildFragment, formQueryColumns } from 'shared/schemas';

export interface ShippingUnitTypesQueryResponse {
  response: {
    id: CustomerOrderId;
    identifier: CustomerOrderIdentifier;
    customerOrderProductGroups: Array<{
      id: CustomerOrderProductGroupId;
      identifier: CustomerOrderProductGroupIdentifier;
      packsPerShippingUnit?: CustomerOrderProductGroupPacksPerShippingUnit;
      customerOrderProducts: Array<{
        product: {
          id: ProductId;
          identifier: ProductIdentifier;
          description: ProductDescription;
        };
      }>
    }>
  };
}

export interface ShippingUnitTypesQueryVariables {
  customerOrderId: CustomerOrderId;
}

const table = 'customerOrders';
const customerOrderDetailFragmentName = 'CustomerOrderDetailFragment';
const customerOrderFragment = gqlTag`${buildFragment(table, formQueryColumns(table), customerOrderDetailFragmentName)}`;

export const ShippingUnitTypesQuery = gqlTag`
query($type: RecordType = CustomerOrder, $customerOrderId: Int!) {
  response: find(type: $type, id: $customerOrderId) {
    id
    ...${customerOrderDetailFragmentName}
    ...fields
  }
}

${customerOrderFragment}

fragment fields on CustomerOrder {
  customerOrderProductGroups {
    id
    identifier
    description
    packsPerShippingUnit
    customerOrderProducts {
      id
      product {
        id
        identifier
        description
      }
    }
  }
}
`;
