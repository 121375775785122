import * as React from 'react';
import { ControlLabel } from 'client/components/third-party';
import * as classNames from 'classnames';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';

class LabeledDisplay extends React.Component<shame> {
  render() {
    const { labelColSize, offset, label, inputColSize, value, testid, inputStyle, input, horizontalLabel, textarea, textFormatter } = this.props;

    const className = classNames('ellipsis', {
      'labeled-display': !inputStyle && !textarea,
      'labeled-input': inputStyle,
      'labeled-textarea': textarea,
    });

    const rawValue = value || (input ? input.value : undefined);

    const displayValue = textFormatter
      ? textFormatter(rawValue)
      : rawValue;

    const labelComponent = (
      <div className={className} data-testid={testid}>
        {displayValue}
      </div>);

    if (horizontalLabel) {
      return (
        <div data-testid={testid}>
          <HorizontalLayout labelComponent={ControlLabel} labelColSize={labelColSize} offset={offset} label={label} inputColSize={inputColSize}>
            {labelComponent}
          </HorizontalLayout>
        </div>
      );
    }

    return (
      <StackedLayout inputColSize={inputColSize} offset={offset} label={label}>
        {labelComponent}
      </StackedLayout>
    );
  }
}
/*
LabeledDisplay.propTypes = {
  labelColSize: React.PropTypes.number,
  offset: React.PropTypes.number,
  inputColSize: React.PropTypes.number,
  label: React.PropTypes.string,
  value: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]),
  testid: React.PropTypes.string,
  horizontalLabel: React.PropTypes.bool,
  inputStyle: React.PropTypes.bool,
  input: React.PropTypes.object,
  textarea: React.PropTypes.bool,
  textFormatter: React.PropTypes.func,
};
*/
(LabeledDisplay as shame).defaultProps = {
  input: {},
  horizontalLabel: true,
  textarea: false,
};

export default LabeledDisplay;
