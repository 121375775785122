import gql from 'graphql-tag';
import { RoutePlanAttachedCustomerOrderGQLResult, GetRoutePlanAttachedCustomerOrdersFilterOptionsResponse } from 'schema/route-plan-attached-customer-orders/route-plan-attached-customer-orders-typescript-types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { RoutePlanStatus } from 'schema/route-plan/route-plan-typescript-types';

export const RoutePlanHeaderFormQuery = gql`
  query RoutePlanAttachedCustomerOrdersTableQuery($id: Int!) {
    routePlanResult: GetRoutePlan(id: $id) {
      routePlan {
        id
        status
      }
    }
  }
`;

export interface RoutePlanStatusQueryProps {
  status?: RoutePlanStatus;
}

export const WithRoutePlanStatus = msyncQuery<shame, shame, RoutePlanStatusQueryProps, { id: number | undefined }>(RoutePlanHeaderFormQuery, {
  alias: 'withRoutePlan',
  skip(ownProps) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps): { variables } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): RoutePlanStatusQueryProps {
    const { data } = props;
    const routePlanResult = data ? data.routePlanResult : undefined;
    const routePlan = routePlanResult ? routePlanResult.routePlan : undefined;
    if (routePlan === undefined)  {
      return {
        status: undefined,
      };
    }
    return {
      status: routePlan.status,
    };
  },
});

export interface RoutePlanAttachedCustomerOrderResponse {
  getRoutePlanAttachedCustomerOrders?: {
    routePlanAttachedCustomerOrders: RoutePlanAttachedCustomerOrderGQLResult[];
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
  getRoutePlanAttachedCustomerOrdersFilterOptions: GetRoutePlanAttachedCustomerOrdersFilterOptionsResponse;
}

export const RoutePlanAttachedCustomerOrdersQuery = gql`
  query AttachCustomerOrderQuery ($filters: GetRoutePlanAttachedCustomerOrdersFilterInput, $sort: [RoutePlanAttachedCustomerOrderSortInput], $search: SearchInput, $routePlanId: Int, $limit: Int, $offset: Int) {
    getRoutePlanAttachedCustomerOrders: GetRoutePlanAttachedCustomerOrders(filters: $filters, sort: $sort, search: $search, routePlanId: $routePlanId, limit: $limit, offset: $offset) {
      routePlanAttachedCustomerOrders {
        customerId
        customerIdentifier
        customerName
        deliveryDate
        id
        identifier
        lastModified
        mfcAreaId
        mfcAreaIdentifier
        orderType
        pallets
        racks
        status
        stores
        vendor
      }
      ids
      totalCount
      totalUnfilteredCount
      totalFilteredStoresCount
      routePlanId
    }
    getRoutePlanAttachedCustomerOrdersFilterOptions: GetRoutePlanAttachedCustomerOrdersFilterOptions {
      customers {
        id
        value
        displayValue
      }
      mfcAreas {
        id
        value
        displayValue
      }
      statuses {
        id
        value
        displayValue
      }
      orderTypes {
        id
        value
        displayValue
      }
    }
  }
`;

export const AttachCustomerOrderToRoutePlanMutation = gql`
  mutation AttachCustomerOrderToRoutePlanMutation($routePlanId: Int!, $customerOrderIds: [Int]!) {
    attachCustomerOrderToRoutePlan: AttachCustomerOrderToRoutePlan(routePlanId: $routePlanId, customerOrderIds: $customerOrderIds) {
      routePlan {
        id
        numberOfAttachedOrders
      }
    }
  }
`;

export const DetachCustomerOrderFromRoutePlanMutation = gql`
  mutation DetachCustomerOrderFromRoutePlanMutation($customerOrderIds: [Int]!) {
    detachCustomerOrderFromRoutePlan: DetachCustomerOrderFromRoutePlan(customerOrderIds: $customerOrderIds) {
      routePlans {
        id
        numberOfAttachedOrders
      }
    }
  }
`;
