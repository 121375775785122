import * as Selectors from './selectors';
import * as Constants from './constants';
import * as ReportUserParamMapperInjector from 'client/components/report-user-params/mapper-injector';
import * as SharedTypes from 'shared/types';
import * as UserParamTypes from 'client/components/report-user-params/types';
import { makeMapToReportUserParams } from 'client/helpers/form-helpers';

const mapToReportUserParams = makeMapToReportUserParams(Constants.formName, SharedTypes.ReportTypes.HmgOps, state => {
  return {
    reportType: Selectors.getHmgOpsReportType(state),
  };
});

const mapFromReportUserParams: UserParamTypes.MapFromReportUserParams = (dispatch, reportUserParams) => {
  // Do nothing
};

export const withHmgOpsReportUserParamMappers = ReportUserParamMapperInjector.withReportUserParamMappers(mapToReportUserParams, mapFromReportUserParams);
