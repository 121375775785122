import * as React from 'react';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledCheckbox, LabeledInput } from 'client/components/form';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import { ColumnAlignment, SelectableRow } from 'client/components/selectable/types';
import { ReportTypes, INPUT_TYPES } from 'shared/types';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ReportUserParams } from 'client/components/report-user-params';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { DropDownOptions } from 'client/types';
import { FormGroup } from 'client/components/third-party';

const emptyArray = [];
const optionTextFormatter = option => option.value;

interface LabeledSelectFieldProps {
  name: string;
  options: DropDownOptions;
  handleChange?(id: number): void;
  label?: string;
  colSize?: number;
  required?: boolean;
}

interface LabeledSelectableFieldProps {
  name: string;
  options?: SelectableRow[];
  loading?: boolean;
  required?: boolean;
  handleChange?(ids: number[]): void;
}

interface LabeledCheckboxFieldProps {
  name: string;
  handleChange?(isOn: boolean): void;
}

export class Customer extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={this.props.colSize || 4}
        label="Customer"
        options={this.props.options || emptyArray}
        labelColSize={this.props.colSize || 4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        required
        horizontalLabel={false}
        testid="report-customer"
      />
    );
  }
}

export class Season extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={this.props.colSize || 4}
        label="Season"
        options={this.props.options || emptyArray}
        labelColSize={this.props.colSize || 4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        required
        horizontalLabel={false}
        testid="report-season"
      />
    );
  }
}

export class YearBeginEndMonths extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="supplier-future-commitment-dates">
        <FormGroup>
          <Field
            name="year"
            component={LabeledInput}
            type={INPUT_TYPES.NUMBER}
            min={2000}
            max={2200}
            inputColSize={4}
            label="Year"
            labelColSize={2}
            offset={0}
            required
            horizontalLabel={true}
            testid="report-year"
          />

        </FormGroup>
        <FormGroup>
          <Field
            name="beginWeek"
            component={LabeledInput}
            type={INPUT_TYPES.NUMBER}
            min={0}
            max={53}
            inputColSize={4}
            label="Begin Week"
            labelColSize={2}
            offset={0}
            required
            horizontalLabel={true}
            testid="report-begin-week"
          />
        </FormGroup>
        <FormGroup>
          <Field
            name="endWeek"
            component={LabeledInput}
            type={INPUT_TYPES.NUMBER}
            min={0}
            max={53}
            inputColSize={4}
            label="End Week"
            labelColSize={2}
            offset={0}
            required
            horizontalLabel={true}
            testid="report-end-week"
          />
        </FormGroup>
      </div>
    );
  }
}

export class Supplier extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelect}
        handleChange={this.props.handleChange}
        inputColSize={4}
        label="Supplier"
        options={this.props.options || emptyArray}
        labelColSize={4}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        required={this.props.required ?? true}
        horizontalLabel={false}
        testid="report-supplier"
      />
    );
  }
}

export class SellDepartment extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={12}
        label="Sell Department"
        labelColSize={12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sell-department"
        required
      />
    );
  }
}

export class ScanBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="Scan Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-scan-based"
        class="scanBased"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class PoBased extends React.PureComponent<LabeledCheckboxFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledCheckbox}
        inputColSize={3}
        label="PO Based"
        labelColSize={3}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-product-po-based"
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-class"
        optional
        handleChange={this.props.handleChange}
      />
    );
  }
}

export class ProductSubClass extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        handleChange={this.props.handleChange}
        inputColSize={12}
        label="Sub Classes"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        testid="report-product-subclass"
        optional
      />
    );
  }
}

export class Product extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 40 },
    { alignment: ColumnAlignment.Left, width: 60 },
  ];
  render() {
    return (
      <Field
        name={this.props.name}
        component={LabeledSelectable}
        inputColSize={12}
        label="Products"
        cols={this.cols}
        options={this.props.options || emptyArray}
        loading={this.props.loading}
        labelColSize={12}
        offset={0}
        horizontalLabel={false}
        handleChange={this.props.handleChange}
        required
        testid="report-products"
      />
    );
  }
}

interface SidebarProps {
  reportType: ReportTypes;
  downloadButtonDisabled: boolean;
  handleDownloadExcelReportClicked(): void;
  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;
  pristine: boolean;
  excelDownloadStatus: MutationStatus;
  reportFormName: string;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
  children?: React.ReactNode[];
}

export class Sidebar extends React.PureComponent<SidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        <div>
          {this.props.children}
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            actionStatus={this.props.excelDownloadStatus}
            testid="download-excel-report-button"
          />
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          anyoneCanEdit
          noDefault
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}

export class SalesPlan extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Field
        name={this.props.name}
        options={this.props.options || emptyArray}
        handleChange={this.props.handleChange}
        component={LabeledSelect}
        inputColSize={this.props.colSize || 12}
        label={this.props.label || 'Sales Plan'}
        labelColSize={this.props.colSize || 12}
        offset={0}
        textFormatter={optionTextFormatter}
        valueField="value"
        horizontalLabel={false}
        testid="report-sales-plan"
        required
      />
    );
  }
}
