import * as Redux from 'redux';
import * as Constants from '../constants';
import { DateRange, TYPES, NavigateTableDirection } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { Dispatch } from 'redux';

export interface Payload { table: string; [prop: string]: any; }
export interface Action extends Redux.Action { payload?: Payload; }
export type DispatchFunction = (action: Action) => void;

export function resetTable(dispatch: Dispatch<any>, tableName: string) {
  dispatch(setSearchText(tableName, ''));
  dispatch(clearAllFilters(tableName));
  dispatch(uncheckAllRecords(tableName));
}

export function toggleSort(tableName: string, field: string, multiColumn?: boolean): Action {
  return {
    payload: { table: tableName, field, multiColumn },
    type: Constants.TOGGLE_SORT,
  };
}

export function toggleFilter(tableName: string, field: string, value: any): Action {
  if (value && (value.startDate !== undefined || value.endDate !== undefined)) {
    if (value.startDate !== undefined && value.endDate !== undefined) {
      return setDateFilter(tableName, field, value);
    } else {
      throw new Error('Trying to filter by date without specifying both start and end');
    }
  }

  return {
    payload: { table: tableName, field, value },
    type: Constants.TOGGLE_FILTER,
  };
}

export function setDateFilter(tableName: string, field: string, dateRange: DateRange): Action {
  return {
    payload: { table: tableName, field, startDate: dateRange.startDate, endDate: dateRange.endDate },
    type: Constants.SET_DATE_FILTER,
  };
}

export function setFilter(table: string, field: string, values: any[], type?: TYPES): Action {
  if (type === TYPES.DATE) {
    return setDateFilter(table, field, values[0]);
  }

  return {
    payload: { table, field, values },
    type: Constants.SET_FILTER,
  };
}

export function clearAllFilters(tableName: string): Action {
  return {
    payload: { table: tableName },
    type: Constants.CLEAR_ALL_FILTERS,
  };
}

export function setSearchField(tableName: string, field: string): Action {
  return {
    payload: { table: tableName, field },
    type: Constants.SET_SEARCH_FIELD,
  };
}

export function setSearchText(tableName: string, text: string): Action {
  return {
    payload: { table: tableName, text },
    type: Constants.SET_SEARCH_TEXT,
  };
}

export function moveDirectionByDistance(tableName: string, direction: string, totalRows: number, totalColumns: number, distance: number = 1, blurOnSaveLastRow: boolean = false): Action {
  return {
    payload: { table: tableName, direction, totalRows, totalColumns, distance, blurOnSaveLastRow },
    type: Constants.NAVIGATE_TABLE,
  };
}

export function moveEditCell(tableName: string, direction: string, totalRows: number, totalColumns: number, distance: number = 1, blurOnSaveLastRow: boolean = false): Action {
  return {
    payload: { table: tableName, direction, totalRows, totalColumns, distance, blurOnSaveLastRow },
    type: Constants.MOVE_EDIT_CELL,
  };
}

export function editNextEditableCell(tableName: string, direction: NavigateTableDirection, totalRows: number, totalColumns: number, columns: IColumn[], tableRows: any[]) {
  return {
    payload: { table: tableName, direction, totalRows, totalColumns, columns, tableRows },
    type: Constants.EDIT_NEXT_EDITABLE_CELL,
  };
}

export function editLastEditableCell(tableName: string, tableRows: any[]) {
  return {
    payload: { table: tableName, tableRows },
    type: Constants.EDIT_LAST_EDITABLE_CELL,
  };
}

export function invalidCellDetected(tableName: string, isInvalid: boolean) {
  return {
    payload: { table: tableName, value: isInvalid },
    type: Constants.TABLE_HAS_INVALID_CELL,
  };
}

export function edit(tableName: string, editing: boolean, shouldSave: boolean): Action {
  return {
    payload: { table: tableName, editing, shouldSave },
    type: Constants.EDIT_TABLE,
  };
}

export function clickCell(tableName: string, row: number, column: number): Action {
  return {
    payload: { table: tableName, row, column },
    type: Constants.CLICK_CELL,
  };
}

export function clickOutside(tableName: string): Action {
  return {
    payload: { table: tableName },
    type: Constants.CLICK_OUTSIDE_TABLE,
  };
}

export function toggleCheckAllRecords(tableName: string, checkedRecordIds: number[], filteredRecordIds: number[]): Action {
  return {
    payload: { table: tableName, checkedRecordIds, filteredRecordIds },
    type: Constants.TOGGLE_CHECK_ALL_RECORDS,
  };
}

export function toggleCheckSingleRecord(tableName: string, recordId: number): Action {
  return {
    payload: { table: tableName, recordId },
    type: Constants.TOGGLE_CHECK_SINGLE_RECORD,
  };
}

export function uncheckMultipleRecords(tableName: string, recordIds: number[]): Action {
  return {
    payload: { table: tableName, recordIds },
    type: Constants.UNCHECK_MULTIPLE_RECORDS,
  };
}

export function uncheckAllRecords(tableName: string): Action {
  return {
    payload: { table: tableName },
    type: Constants.UNCHECK_ALL_RECORDS,
  };
}

export function setTablePageNumber(tableName, pageNumber, row?: number) {
  return {
    payload: { table: tableName, pageNumber, row },
    type: Constants.SET_TABLE_PAGE_NUMBER,
  };
}

export function setTableRowsPerPage(tableName, rowsPerPage, totalRows) {
  return {
    payload: { table: tableName, rowsPerPage, totalRows },
    type: Constants.SET_TABLE_ROWS_PER_PAGE,
  };
}

export function openOrderDeleteConfirmationModal(tableName: string, orderId: CustomerOrderId | SupplierOrderId) {
  return {
    payload: { table: tableName, orderId },
    type: Constants.OPEN_ORDER_DELETE_CONFIRMATION_MODAL,
  };
}

export function closeOrderDeleteConfirmationModal(tableName: string) {
  return {
    payload: { table: tableName },
    type: Constants.CLOSE_ORDER_DELETE_CONFIRMATION_MODAL,
  };
}
