import * as React from 'react';
import { Modal, Col, Row } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { noop } from 'lodash';
import SearchBox from 'client/components/toolbar/search-box';
import { CheckableMerchandisersWithStores } from 'client/state/route-plan-printing';
import { Checkbox } from 'client/components/form/checkbox';

export interface ComponentProps {
  isShown: boolean;
  sendButtonDisabled: boolean;
  routePlanId?: RoutePlanId;
  handleCancelButtonClicked(): void;
  handleSendButtonClicked(routePlanId: RoutePlanId): void;
  merchandisersWithStores: CheckableMerchandisersWithStores[];
  handleMerchandiserChecked(merchandiserId: number): void;
  handleSelectAllClicked(): void;
  selectAllChecked: boolean;
  handleSearch(text: string): void;
  searchText: string;
  numSelected: number;
}

export class SendMerchandisersAsnModalUI extends React.PureComponent<ComponentProps, {}> {
  handleSendButtonClicked = () => {
    if (!this.props.routePlanId) {
      throw new Error('No route plan id found.');
    }
    this.props.handleSendButtonClicked(this.props.routePlanId);
  }

  handleMerchandiserChecked = (merchandiserId: number) => {
    return () => this.props.handleMerchandiserChecked(merchandiserId);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="send-merchandisers-asn-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="send-merchandisers-asn-modal-container"
        className="send-merchandisers-asn-modal">
        <GlobalAsyncModalHeader>Email Merchandiser-Specific Advance Ship Notices</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <Row className="send-merchandisers-asn-merchandiser-header">
              <div className="send-merchandisers-asn-merchandiser-description">
                Choose all or select specific merchandiser to email a store-specific ASN.
              </div>

              <Col sm={4} className="send-merchandisers-asn-merchandiser-select-all-container">
                <Checkbox
                  label="Select All"
                  input={{
                    value: this.props.selectAllChecked,
                    onChange: noop,
                  }}
                  testid="send-merchandisers-asn-merchandiser-select-all"
                  onClick={this.props.handleSelectAllClicked} />
              </Col>
              <Col sm={3} className="send-merchandisers-asn-num-selected">
                {this.props.numSelected} Selected
              </Col>
              <Col sm={5} className="send-merchandisers-asn-merchandiser-search-container pull-right">
                <SearchBox
                  activeColumns={['Search All']}
                  availableColumns={[{ id: 'Search All', name: 'Search All' } ]}
                  onColumnSelected={noop}
                  placeholder="Search"
                  activeSearchText=""
                  onSearch={this.props.handleSearch}
                / >
              </Col>
            </Row>
            <Row>
              <div className="send-merchandisers-asn-merchandiser-list-container">

                {this.props.merchandisersWithStores
                  .map(merchandiserWithStores =>
                    <div className="send-merchandisers-asn-merchandiser-list-item" key={`merchandiser-${merchandiserWithStores.merchandiserId}`}>
                      <div className="send-merchandisers-asn-merchandiser-list-item-name">
                        <Checkbox
                          label={merchandiserWithStores.name}
                          input={{
                            value: merchandiserWithStores.checked,
                            onChange: noop,
                          }}
                          onClick={this.handleMerchandiserChecked(merchandiserWithStores.merchandiserId)}
                          testid={`send-merchandisers-asn-merchandiser-list-item-${merchandiserWithStores.merchandiserId}`}
                        />
                      </div>
                      <div className="send-merchandisers-asn-merchandiser-list-item-stores">
                        Stores: {merchandiserWithStores.storeIdentifiers.join(', ')}
                      </div>
                    </div>,
                  )}
              </div>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="send-merchandisers-asn-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Send"
            testid="send-merchandisers-asn-modal-send-button"
            disabled={this.props.sendButtonDisabled}
            onClick={this.handleSendButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
