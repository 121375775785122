import { SupplierItem } from 'shared/schemas/supplier-item';
import { optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { SupplierOrderProduct } from 'shared/schemas/supplier-order-product';
import { FULL_CASE_IDENTIFIER, FULL_RACK_IDENTIFIER, ActiveInactive } from 'shared/types';
import { Saved } from 'shared/schemas/record';

function mapSupplierItemsToDropdownItems(supplierItem: SupplierItem & { product: { identifier: string, description: string } }): SupplierItemDropDownItem {
  return {
    ...supplierItem,
    label: `${supplierItem.product.identifier} - ${supplierItem.product.description}`,
  };
}

export interface SupplierItemDropDownItem extends SupplierItem {
  label: string;
}

export interface WithSupplierItemsProps {
  customerId: number;
  supplierId: number;
  sellDepartmentId: number;
  sellDepartmentIdentifier: string;
  supplierItems: Array<Pick<Saved<SupplierItem>, 'id' | 'product' | 'packSize' | 'packsPerShelf' | 'shelvesPerRack'> & { label: string }>;
  activeSupplierItems: Array<Pick<Saved<SupplierItem>, 'id' | 'product' | 'packSize' | 'packsPerShelf' | 'shelvesPerRack'> & { label: string }>;
}

export const withSupplierItems = (args: { filterExistingSupplierItems: boolean, activeOnly?: boolean }) => optionsContainerWithFilters<WithSupplierItemsProps>({
  table: 'supplierItems',
  columns: ['product', 'packSize', 'packsPerShelf', 'shelvesPerRack', 'casesPerPallet', 'activeStatus'],
  props: (props: { data: { content: Array<Saved<SupplierItem>> }, ownProps: { tableContent: SupplierOrderProduct[] } }) => {
    const { data: { content }, ownProps: { tableContent } } = props;

    const existingSupplierItemIds = (tableContent ? tableContent : [])
      .filter(tc => tc.supplierOrderProductGroup.identifier === FULL_CASE_IDENTIFIER || tc.supplierOrderProductGroup.identifier === FULL_RACK_IDENTIFIER)
      .map(tc => tc.supplierItemId);

    const supplierItems = (content ? content : [])
      .filter(dd => !existingSupplierItemIds.includes(dd.id) || !args.filterExistingSupplierItems)
      .map(si => mapSupplierItemsToDropdownItems(si));

    return {
      supplierItems,
      activeSupplierItems: args.activeOnly ? supplierItems : supplierItems.filter(si => si.activeStatus !== ActiveInactive.Inactive),
    };
  },
  sort: [{
    sortField: 'product',
    sortOrder: 'ASC',
    foreignColumn: 'identifier',
  }],
  getFilters: (props: WithSupplierItemsProps) => {
    return [
      { field: 'customer', values: [`${props.customerId}`] },
      { field: 'supplier', values: [`${props.supplierId}`] },
      { field: 'sellDepartment.identifier', values: [props.sellDepartmentIdentifier, 'MISC'] },
      ...(args.activeOnly ? [{ field: 'activeStatus', values: [ActiveInactive.Active] }] : []),
    ];
  },
});
