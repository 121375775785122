import * as ExcelHelpers from 'shared/file-parsers/excel/common';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as ImportModalTypes from 'client/components/import-modal/types';
import * as Mutations from './mutations';
import * as Queries from './queries';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as SalesPlanVarietySpreadsheetParser from 'shared/file-parsers/excel/sales-plan-variety-spreadsheet-parser';
import * as SalesPlanVarietyTypes from 'shared/file-parsers/excel/sales-plan-variety-spreadsheet-parser/types';
import * as SharedHelpers from 'shared/helpers';
import { extractQueryName } from 'client/utils/query-utils';
import { GetSalesPlanWeeklyProductVolumeQuery } from '../detail/query';

export type ActionTypes =
  OpenModalButtonClickedAction |
  CancelButtonClickedAction;

export interface OpenModalButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.OpenModalButtonClicked;
  payload: {};
}

export function handleImportVarietyColorModalOpenModalButtonClicked(): OpenModalButtonClickedAction {
  return {
    type: ActionTypeKeys.OpenModalButtonClicked,
    payload: {},
  };
}

export enum ActionTypeKeys {
  OpenModalButtonClicked = 'ImportSalesPlanVarietyColorModal/OPEN_MODAL_BUTTON_CLICKED',
  CancelButtonClicked = 'ImportSalesPlanVarietyColorModal/CANCEL_BUTTON_CLICKED',
}

export interface CancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.CancelButtonClicked;
  payload: {};
}

export function handleCancelButtonClicked(): CancelButtonClickedAction {
  return {
    type: ActionTypeKeys.CancelButtonClicked,
    payload: {},
  };
}

export function handleCloseButtonClicked(): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(handleCancelButtonClicked());
  };
}

export function handleParsingStarted(args: { fileName: string, contents: string }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      const workBookContainer = await ExcelHelpers.getWorkBookContainerFromBinaryString(args.fileName, args.contents);
      const salesPlanVarietyParseResult = await SalesPlanVarietySpreadsheetParser.getImportableSalesPlanVarieties(workBookContainer);
      if (salesPlanVarietyParseResult.success) {
        done([], salesPlanVarietyParseResult.parsed);
      } else {
        done([salesPlanVarietyParseResult.reason]);
      }
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleValidationStarted(args: { existingRecordId: number | undefined, importableObject: SalesPlanVarietyTypes.ImportableSalesPlanVarieties }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to validate sales plan variety color');
  }

  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      const result = await GraphQLQuery.msyncClientQuery<Queries.ValidateSalesPlanVarietyColorDetailsResponse, Queries.ValidateSalesPlanVarietyColorDetailsInput>(
        {
          query: Queries.ValidateSalesPlanVarietyColorDetailsQuery,
          variables: {
            input: {
              salesPlanId: existingRecordId,
              importableSalesPlanVarieties: args.importableObject,
            },
          },
          dispatch,
          disableGlobalError: true,
          fetchPolicy: 'network-only',
        },
      );

      const errors = result.data.validateSalesPlanVarietyColorDetails.errors;
      done(errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleImportStarted(args: { existingRecordId: number | undefined, importableObject: SalesPlanVarietyTypes.ImportableSalesPlanVarieties }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  const existingRecordId = args.existingRecordId;
  if (!existingRecordId) {
    throw new Error('Must provide an existingRecordId to import sales plan variety color');
  }

  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    try {
      await GraphQLMutation.msyncClientMutation<Mutations.ImportSalesPlanVarietyColorDetailsMutationResponse, Mutations.ImportSalesPlanVarietyColorDetailsMutationInput>(
        {
          mutation: Mutations.ImportSalesPlanVarietyColorDetailsMutation,
          variables: {
            input: {
              salesPlanId: existingRecordId,
              importableSalesPlanVarieties: args.importableObject,
            },
          },
          dispatch,
          suppressErrorModal: true,
          refetchQueries: [extractQueryName(GetSalesPlanWeeklyProductVolumeQuery)],
        },
      );

      done([]);
    } catch (errors) {
      done([errors.message]);
    }
  };
}
