import { Lens } from '@atomic-object/lenses';
import { StoreSummaryReportCheckableStore } from 'schema/route-plan/route-plan-typescript-types';
import { every } from 'lodash';

interface RoutePlanState {
  readonly attachOrdersModalShown: boolean;
  readonly addStoreModalShown: boolean;
  readonly downloadStoreSummaryReportModalShown: boolean;
  readonly storeSummaryReportStores: StoreSummaryReportCheckableStore[];
  readonly storeSummaryReportSearchText: string;
}

export const InitialState: RoutePlanState = {
  attachOrdersModalShown: false,
  addStoreModalShown: false,
  downloadStoreSummaryReportModalShown: false,
  storeSummaryReportStores: [],
  storeSummaryReportSearchText: '',
};

export type Type = RoutePlanState;

export const attachOrdersModalShown = Lens.from<Type>().prop('attachOrdersModalShown');
export const addStoreModalShown = Lens.from<Type>().prop('addStoreModalShown');
export const downloadStoreSummaryReportModalShown = Lens.from<Type>().prop('downloadStoreSummaryReportModalShown');

export const storeSummaryReportStores = Lens.from<Type>().prop('storeSummaryReportStores');
export const storeSummaryReportSearchText = Lens.from<Type>().prop('storeSummaryReportSearchText');

export const isStoreSummaryReportStoresSelectAllChecked = Lens.of<Type, boolean>(
  {
    get: state => state.storeSummaryReportStores.length > 0 && every(state.storeSummaryReportStores, (s => s.checked)),
    set: (state, value) => {
      return {
        ...state,
        storeSummaryReportStores: state.storeSummaryReportStores.map(s => {
          return {
            ...s,
            checked: value,
          };
        }),
      };
    },
  },
);
