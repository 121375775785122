import { tableName, property, setSchemaOptions } from './dsl';

export enum ExportableDataStatus {
  initial = 'initial',
  exported = 'exported',
  error = 'error',
}

export enum ExportableDataTypes {

}

@tableName('exportableData')
export class ExportableData {
  id?: number;

  @property status: ExportableDataStatus;
  @property dataType: ExportableDataTypes;
  @property messages?: string[];
}

setSchemaOptions(ExportableData, {
  type: 'ExportableData',
});
