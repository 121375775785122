import * as React from 'react';
import { StatsCardBasic } from 'client/components/cards';
import { formatInteger } from 'shared/types';
import * as _ from 'lodash';

export function PalletsCard(props: { pallets?: number }) {
  return (
    <StatsCardBasic title="Pallets">{_.isNil(props.pallets) ? '-' : formatInteger(props.pallets)}</StatsCardBasic>
  );
}
