import * as React from 'react';
import { Modal } from 'client/components/third-party';
import * as _ from 'lodash';

interface OwnProps {
  error: Error;
  errorInfo: { componentStack: string };
}

export const BoundaryErrorModal = (props: OwnProps) => {
  const componentStack = props.errorInfo.componentStack.replace('\\n', '\n');

  return (
    <Modal show onHide={_.noop} dialogClassName="toast-error-alert" data-testid="boundary-error-modal" className="alert-modal" backdropClassName="error-alert-backdrop">
      <Modal.Body>
        <div className="alert alert-danger">
          <h5>An application error has occured</h5>

          <p>{props.error.message}</p>

          <p>Please reload the page and try again.</p>

          <details style={{ whiteSpace: 'pre-wrap' }}>
            {componentStack}
          </details>
        </div>
      </Modal.Body>
    </Modal>
  );
};
