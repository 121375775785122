import {
  RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY,
  RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS,
  RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS,
  RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
  RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED,
} from 'client/constants';

import { MutationStatus } from 'client/actions/mutations';
import { ReceivingReceiptRecipient } from 'client/app/transportation/receiving/details/related-bols/send-receiving-receipt-modal-container';
import { Action } from 'client/types/redux-types';

export interface RelatedBolsState {
  sendReceivingReceiptModalShown: boolean;
  supplierOrderContacts: ReceivingReceiptRecipient[];
  sendReceivingReceiptActionStatus: MutationStatus;
  receivingReceiptNotes?: string;
}

const INITIAL_STATE: RelatedBolsState = {
  sendReceivingReceiptModalShown: false,
  supplierOrderContacts: [],
  sendReceivingReceiptActionStatus: MutationStatus.Initial,
  receivingReceiptNotes: '',
};

export default function reducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }): RelatedBolsState {
  switch (action.type) {
    case RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY: {
      return {
        ...state,
        sendReceivingReceiptModalShown: action.payload,
      };
    }
    case RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS: {
      return {
        ...state,
        supplierOrderContacts: action.payload,
      };
    }
    case RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS: {
      return {
        ...state,
        sendReceivingReceiptActionStatus: action.payload,
      };
    }
    case RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED: {
      return {
        ...state,
        supplierOrderContacts: state.supplierOrderContacts.map(contact => {
          return {
            // select or unselect (! the current value) the contact that we're clicking on
            ...contact,
            selected: contact.supplierContactId === action.payload ? !contact.selected : contact.selected,
          };
        }),
      };
    }
    case RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED: {
      return {
        ...state,
        receivingReceiptNotes: action.payload,
      };
    }
    default:
      return state;
  }
}
