import gql from 'graphql-tag';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { ShippingUnitType, OrderMethod, ReceivableOrderType } from 'shared/types';
import { GetUnifiedReceivableOrderWithDetailsResponse } from 'schema/unified-receivable-order/unified-receivable-order-graphql-types';
import { BolId } from 'shared/schemas/bol';

export interface ReceivingOverviewContentVariables {
  id: SupplierOrderId;
  bolId?: BolId;
}

export interface ReceivingOverviewContentResponse {
  response?: GetUnifiedReceivableOrderWithDetailsResponse;
}

export const ReceivingOverviewContentQuery = gql`
  query ReceivingOverviewContentQuery($id: Int!, $filters: [FilterSpecificationInput!], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    response: GetUnifiedReceivableOrder(id: $id) {
      id
      records: details(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        receivableOrderType
        productIdentifier
        productDescription
        upc
        rackType
        packSize
        packsPerShelf
        shelvesPerRack
        packsPerShippingUnit
        retailPrice
        shippingUnitQuantity
        packQuantity
        packQuantityReceivedTotal
        shippingUnitQuantityReceivedTotal
      }
      ids: detailsIds(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset)
      totalCount: detailsTotalCount(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset)
      totalUnfilteredCount: detailsTotalUnfilteredCount
    }
  }
`;

export interface ReceivableOrderInfoResponse {
  id: number;
  identifier: string;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  receivableOrderType: ReceivableOrderType;
  orderType: string;
}

export const ReceivableOrderInfoQuery = gql`
  query ReceivableOrderInfoForReceivingOverview($receivableOrderId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      identifier
      shippingUnitType
      orderMethod
      receivableOrderType
      orderType
    }
  }
`;
