import * as React from 'react';
import { Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { MutationStatus } from 'client/actions/mutations';
import FileChooser from './file-chooser/file-chooser-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';

export interface ComponentProps {
  buttonDisabled: boolean;
  buttonIsCloseButton: boolean;
  cancelButtonDisabled: boolean;
  handleCancelButtonClicked: () => void;
  handleImportButtonClicked: (routePlanId: number) => void;
  isShown: boolean;
  routePlanId: RoutePlanId;
}

export class ImportDistributionRackShippingWorksheetModal extends React.PureComponent<ComponentProps, {}> {
  handleButtonClicked = () => {
    if (this.props.buttonIsCloseButton) {
      return this.props.handleCancelButtonClicked();
    }

    return this.props.handleImportButtonClicked(this.props.routePlanId);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="import-distribution-rack-shipping-worksheet-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="import-distribution-rack-shipping-worksheet-modal-container"
        className="import-distribution-rack-shipping-worksheet-modal"
      >
        <GlobalAsyncModalHeader>Import Distribution Rack Shipping Worksheet</GlobalAsyncModalHeader>
        <Modal.Title>
        </Modal.Title>
        <Modal.Body>
          <div className="import-distribution-rack-shipping-worksheet-modal-content">
            <FileChooser routePlanId={this.props.routePlanId} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            disabled={this.props.cancelButtonDisabled}
          />
          <AsyncButton
            label={this.props.buttonIsCloseButton ? 'Close' : 'Import Worksheet'}
            testid="import-distribution-rack-shipping-worksheet-modal-finish"
            type={undefined}
            disabled={this.props.buttonDisabled}
            onClick={this.handleButtonClicked}
            actionStatus={MutationStatus.Initial}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
