import * as Constants from './constants';
import * as ReportUserParamMapperInjector from 'client/components/report-user-params/mapper-injector';
import * as SharedTypes from 'shared/types';
import * as UserParamTypes from 'client/components/report-user-params/types';
import { makeMapToReportUserParams } from 'client/helpers/form-helpers';

const mapToReportUserParams = makeMapToReportUserParams(Constants.formName, SharedTypes.ReportTypes.BuyerReport);

const mapFromReportUserParams: UserParamTypes.MapFromReportUserParams = (dispatch, reportUserParams) => {
  // Do nothing
};

export const withBuyerReportUserParamMappers = ReportUserParamMapperInjector.withReportUserParamMappers(mapToReportUserParams, mapFromReportUserParams);
