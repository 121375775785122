import * as React from 'react';
import * as R from 'ramda';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { RacksFooterUI, UIProps } from './ui';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import { withRouter, RouteChildrenProps } from 'react-router';
import * as ReviewStoreQuery from '../review-stores-query';
import * as State from 'client/state/state';
import * as Selectors from 'client/state/selectors';
import { connect } from 'react-redux';
import assertCompatible from 'shared/helpers/assert-compatible';
import { FetchPolicy } from 'apollo-client';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
}

type Component<P> = new (props: P) => React.Component<P, any>;

interface RoutePlanIdProps {
  routePlanId: number;
}
const withRoutePlanId = WrappedComponent => (props: RouteChildrenProps<{ id?: string }>) => {
  return propToComponent(WrappedComponent, {
    ...props,
    routePlanId: props.match ? props.match.params.id : 0,
  });
};

const withRoutePlanIdFromUrl = R.compose(
  withRouter,
  withRoutePlanId,
);

interface TotalRacksProps {
  totalRacks: number;
}
const WithTotalRacks = msyncQuery<shame, RoutePlanIdProps, TotalRacksProps, { routePlanId: number }>(ReviewStoreQuery.GetRoutePlanReviewStoresQuery, {
  skip(ownProps: RoutePlanIdProps) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps): { fetchPolicy?: FetchPolicy, variables: shame } {
    return {
      variables: {
        routePlanId: ownProps.routePlanId,
      },
    };
  },
  props(props): TotalRacksProps {
    const { data } = props;
    const vals = data.response ? data.response.reviewStoresCustomerOrderAllocations : [];
    return {
      totalRacks: _.sum(vals.map(v => v.racks)),
    };
  },
});

interface StateProps {
  showSpinner: boolean;
}
const mapStateToProps = (state: State.Type): StateProps => {
  const inFlight = Selectors.isGlobalSaveButtonSpinning(state);
  return {
    showSpinner: inFlight,
  };
};

const component = flowRight(
  withRoutePlanIdFromUrl,
  WithTotalRacks,
  connect<StateProps, {}, {}>(mapStateToProps),
)(RacksFooterUI) as Component<OwnProps>;

type CombinedProps =
  StateProps &
  TotalRacksProps &
  OwnProps;

assertCompatible<UIProps, CombinedProps>();

export const RacksFooterContainer =  (props: OwnProps) => propToComponent(component, props);
