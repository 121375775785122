import * as ImportSalesPlanModalActions from 'client/app/planning/sales-plans/import-sales-plan-modal/actions';
import * as ImportSalesPlanVarietyModalActions from 'client/app/planning/sales-plans/import-sales-plan-variety-color-modal/actions';
import * as SalesPlanDetailActions from 'client/app/planning/sales-plans/detail/actions';
import * as R from 'ramda';
import * as State from 'client/state/sales-plan';

type ActionType = ImportSalesPlanModalActions.ActionTypes | ImportSalesPlanVarietyModalActions.ActionTypes | SalesPlanDetailActions.ActionTypes;

export default function reducer(state = State.InitialState, action: ActionType): State.Type {
  switch (action.type) {
    case ImportSalesPlanVarietyModalActions.ActionTypeKeys.OpenModalButtonClicked: {
      return R.pipe(
        State.importSalesPlanVarietyModalState.set(true),
      )(state);
    }

    case ImportSalesPlanVarietyModalActions.ActionTypeKeys.CancelButtonClicked: {
      return R.pipe(
        State.importSalesPlanVarietyModalState.set(false),
      )(state);
    }

    case ImportSalesPlanModalActions.ActionTypeKeys.OpenModalButtonClicked: {
      return R.pipe(
        State.importSalesPlanModalShown.set(true),
      )(state);
    }

    case ImportSalesPlanModalActions.ActionTypeKeys.CancelButtonClicked: {
      return R.pipe(
        State.importSalesPlanModalShown.set(false),
      )(state);
    }

    case SalesPlanDetailActions.ActionTypeKeys.ProductSelected: {
      return R.pipe(
        State.selectedProductId.set(action.payload.productId),
      )(state);
    }

    default:
      return state;
  }
}
