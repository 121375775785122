import * as React from 'react';
import { Modal, Col, FormGroup, Row } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { LoadConfiguration } from 'shared/types';
import { SimpleRadio } from 'client/components/simple-components/simple-radio';

export interface ComponentProps {
  isShown: boolean;
  createButtonEnabled: boolean;
  routePlanId?: RoutePlanId;
  handleCancelButtonClicked(): void;
  handleCreateButtonClicked(routePlanId?: RoutePlanId): void;
  loadConfiguration: LoadConfiguration;
  loadConfigurationChanged(loadConfiguration: LoadConfiguration): void;
}

export class DownloadDittyCardModalUI extends React.PureComponent<ComponentProps, {}> {
  handleCreateButtonClicked = () => {
    this.props.handleCreateButtonClicked(this.props.routePlanId);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="download-ditty-card-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="download-ditty-card-modal-container"
        className="download-ditty-card-modal" >
        <GlobalAsyncModalHeader>Download Ditty Cards</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <Row>
              <div className="download-ditty-card-modal-detail">Choose your Ditty Card settings:</div>
            </Row>
            <label className="download-ditty-card-modal-label">Load Configuration</label>
            <FormGroup className="download-ditty-card-modal-form-group">
              <Row>
                <SimpleRadio
                  label={LoadConfiguration.Rows}
                  onChange={this.props.loadConfigurationChanged}
                  value={this.props.loadConfiguration}
                  optionValue={LoadConfiguration.Rows}
                  testid="download-ditty-card-modal-load-configuration"
                  labelColSize={12}
                  inputColSize={0}
                />
              </Row>
              <Row>
                <SimpleRadio
                  label={LoadConfiguration.Columns}
                  onChange={this.props.loadConfigurationChanged}
                  value={this.props.loadConfiguration}
                  optionValue={LoadConfiguration.Columns}
                  testid="download-ditty-card-modal-load-configuration"
                  labelColSize={12}
                  inputColSize={0}
                />
              </Row>
            </FormGroup>
          </Col>
        </Modal.Body>
        <Modal.Footer>

          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="download-ditty-card-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Create"
            testid="download-ditty-card-modal-create-button"
            disabled={!this.props.createButtonEnabled}
            onClick={this.handleCreateButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal >
    );
  }
}
