import * as Excel from './common';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { WorkBookContainer } from 'shared/file-parsers/excel/common';
import { firstOrDefault, orThrow } from 'shared/helpers';
import { CustomerOrderWorkSheetType, CustomerOrderType, KnownVendor, DateStr, MoneyStr } from 'shared/types';
import { formatDate, parseDate } from 'shared/helpers/date-helpers';
import { ExpectedError } from 'shared/errors';

interface ImportableCustomerOrderAllocation {
  quantity: Int | undefined;
  storeIdentifier: string | undefined;
  retail?: MoneyStr;
  price?: MoneyStr;
  upcRetail?: MoneyStr;
  productDescription?: string;
}

interface ImportableCustomerOrderProduct {
  allocations?: ImportableCustomerOrderAllocation[];
  packSize: Int | undefined;
  sku?: string | undefined;
  identifier?: string | undefined;
  totalRacks: number | undefined;
}

export interface ImportableCustomerOrderProductGroup {
  identifier: string | undefined;
  description?: string | undefined;
  isCombo: boolean;
  packsPerShippingUnit: Int | undefined;
  customerOrderProducts: ImportableCustomerOrderProduct[];
}

export interface ImportableCustomerOrder {
  customerIdentifier?: string;
  poNumber: string;
  orderType: CustomerOrderType;
  storeDeliveryDate: DateStr;
  arrivalDate?: DateStr;
  poDate?: DateStr | null;
  vendor: string;
  customerOrderProductGroups: ImportableCustomerOrderProductGroup[];
}

enum ValidCustomerOrderWorkBookType { }
type CustomerOrderWorkBook = XLSX.WorkBook & ValidCustomerOrderWorkBookType;

enum ValidCustomerOrderWorkSheetType { }
type CustomerOrderWorkSheet = XLSX.WorkSheet & ValidCustomerOrderWorkSheetType;

enum ValidCustomerOrderWorkSheetDataType { }
type CustomerOrderWorkSheetData = Excel.WorkSheetData & ValidCustomerOrderWorkSheetDataType;

const CustomerOrderWorkSheetFieldNames = {
  [CustomerOrderWorkSheetType.Distribution]: {
    Identifier          : new RegExp(/^(item|case ?(pack)? ?id( ?\/ ?sku)?)$/i),
    Description         : new RegExp(/^desc(ription)?$/i),
    UPC                 : new RegExp(/^(upc)$/i),
    POLetter            : new RegExp(/^(po ?letter( ?\(ending letter\))?)$/i),
    ArrivalDate         : new RegExp(/^(mfc arrival date)$/i),
    Date                : new RegExp(/^(date|store delivery date)$/i),
    PackSize            : new RegExp(/^(mpack|pack|packs \(pieces per case\))$/i),
  },
  [CustomerOrderWorkSheetType.Floral]: {
    Identifier          : new RegExp(/^(item|product|mfc\s*#|mfc\s+id|mfc\s+code)$/i),
    UPC                 : new RegExp(/^(upc)$/i),
    SKU                 : new RegExp(/^(sku)$/i),
    CartType            : new RegExp(/^(carttype)$/i),
    PurchaseOrderNumber : new RegExp(/^(po)$/i),
    PackSize            : new RegExp(/^(pack)$/i),
  },
  [CustomerOrderWorkSheetType.Garden]: {
    Identifier          : new RegExp(/^(item|product|mfc\s*#|mfc\s+id|mfc\s+code)$/i),
    UPC                 : new RegExp(/^(upc)$/i),
    SKU                 : new RegExp(/^(sku)$/i),
    CartType            : new RegExp(/^(carttype)$/i),
    PurchaseOrderNumber : new RegExp(/^(po)$/i),
    TotalCarts          : new RegExp(/^(total|total carts)$/i),
    CartSize            : new RegExp(/^(cartsize)$/i),
  },
};

const getCustomerOrderWorkBookFromWorkBookContainer = async (workBookContainer: WorkBookContainer) => Excel.getWorkBookFromWorkBookContainer<CustomerOrderWorkBook>(workBookContainer);
const getCustomerOrderWorkSheet = (workBook: CustomerOrderWorkBook) => Excel.getFirstWorkSheet<CustomerOrderWorkSheet>(workBook);
const getCustomerOrderWorkSheetData = (workSheet: CustomerOrderWorkSheet) => Excel.getWorkSheetData<CustomerOrderWorkSheetData>(workSheet);
const getCustomerOrderWorkSheetType = (workSheetData: CustomerOrderWorkSheetData): CustomerOrderWorkSheetType => {
  const firstColumn = _.uniq(workSheetData.map(workSheetRow => `${workSheetRow.A}`));
  const hasAllFieldsFromCustomerOrderWorkSheetType = (fieldPatterns: { [k: string]: RegExp }, wsType: CustomerOrderWorkSheetType) => _.values(fieldPatterns).every(pattern =>
       wsType === CustomerOrderWorkSheetType.Distribution && pattern === CustomerOrderWorkSheetFieldNames[wsType].ArrivalDate // arrivalDate is optional for Distribution
    || wsType === CustomerOrderWorkSheetType.Floral       && pattern === CustomerOrderWorkSheetFieldNames[wsType].SKU         // SKU is optional for Floral
    || wsType === CustomerOrderWorkSheetType.Floral       && pattern === CustomerOrderWorkSheetFieldNames[wsType].Identifier  // Identifier is optional for Floral
    || wsType === CustomerOrderWorkSheetType.Floral       && pattern === CustomerOrderWorkSheetFieldNames[wsType].UPC         // UPC is optional for Floral
    || wsType === CustomerOrderWorkSheetType.Garden       && pattern === CustomerOrderWorkSheetFieldNames[wsType].SKU         // SKU is optional for Garden
    || wsType === CustomerOrderWorkSheetType.Garden       && pattern === CustomerOrderWorkSheetFieldNames[wsType].Identifier  // Identifier is optional for Garden
    || wsType === CustomerOrderWorkSheetType.Garden       && pattern === CustomerOrderWorkSheetFieldNames[wsType].UPC         // UPC is optional for Garden
    || firstColumn.some(cell => pattern.test(cell))
  ) ? wsType : undefined;

  // Try to determine the type of worksheet based on the various fields specified in CustomerOrderWorkSheetFieldNames below.
  const matchingWorkSheetTypes = _.compact(_.values(_.mapValues(CustomerOrderWorkSheetFieldNames, hasAllFieldsFromCustomerOrderWorkSheetType)));
  return matchingWorkSheetTypes.length === 1 ? matchingWorkSheetTypes[0] : CustomerOrderWorkSheetType.Unknown;
};

const getColumnIndexes = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType, rowIndex: number = 0) => {
  const firstColumn = workSheetType === CustomerOrderWorkSheetType.Distribution ? 'B' : 'D';
  const lastColumn = Excel.findLastColumn(workSheetData, rowIndex) || orThrow('Unable to find column indexes.');
  return _
    .range(XLSX.utils.decode_col(firstColumn), XLSX.utils.decode_col(lastColumn) + 1) // 🤯
    .map(columnIndex => XLSX.utils.encode_col(columnIndex));
};

interface HeaderDatum {
  columnIndex: string            ;
  sku        : string | undefined;
  identifier : string | undefined;
  description: string | undefined;
  upc        : string | undefined;
  poLetter   : string | undefined;
  date       : string | undefined;
  arrivalDate: string | undefined;
}

const getHeaderData = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType): HeaderDatum[] => {
  const skuRowIndex            = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'SKU'        );
  const identifierRowIndex     = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'Identifier' );
  const descriptionRowIndex    = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'Description' );
  const upcRowIndex            = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'UPC'        );
  const poLetterRowIndex       = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'POLetter'   );
  const dateRowRowIndex        = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'Date'       );
  const arrivalDateRowRowIndex = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'ArrivalDate');
  if (skuRowIndex === -1 && identifierRowIndex === -1)
    throw new Error('Unable to find SKU nor identifier row.');

  const skuRow         = workSheetData[skuRowIndex           ];
  const identifierRow  = workSheetData[identifierRowIndex    ];
  const descriptionRow = workSheetData[descriptionRowIndex   ];
  const upcRow         = workSheetData[upcRowIndex           ];
  const poLetterRow    = workSheetData[poLetterRowIndex      ];
  const dateRow        = workSheetData[dateRowRowIndex       ];
  const arrivalDateRow = workSheetData[arrivalDateRowRowIndex];
  const definingRowIndexForColumns = skuRowIndex !== -1 ? skuRowIndex : identifierRowIndex;
  const headerColumnIndexes = __INTERNAL.getColumnIndexes(workSheetData, workSheetType, definingRowIndexForColumns);
  if (headerColumnIndexes.length === 0)
    throw new Error('Unable to find products.');

  // Only care about columns that have allocations, so filter out those that don't
  const columnIndexesThatHaveAllocations = __INTERNAL.getImportableCustomerOrderProductsWithAllocations(workSheetData, headerColumnIndexes.map(columnIndex => ({
    columnIndex,
    // All of the stuff below here is just to make the type checker happy. Not really needed.
    columnGroupingKey: '---',
    customerOrderProductGroupIdentifier: undefined,
    identifier: undefined,
    description: undefined,
    packSize: undefined,
    packsPerShippingUnit: undefined,
    sku: undefined,
    totalRacks: undefined,
  }))).map(obj => obj.columnIndex);

  return columnIndexesThatHaveAllocations.map(columnIndex => ({
    columnIndex,
    sku        : skuRow         ? Excel.getAsString(skuRow        [columnIndex]) : undefined,
    identifier : identifierRow  ? Excel.getAsString(identifierRow [columnIndex]) : undefined,
    description: descriptionRow ? Excel.getAsString(descriptionRow[columnIndex]) : undefined,
    upc        : upcRow         ? Excel.getAsString(upcRow        [columnIndex]) : undefined,
    poLetter   : poLetterRow    ? Excel.getAsString(poLetterRow   [columnIndex]) : undefined,
    date       : dateRow        ? Excel.getAsString(dateRow       [columnIndex]) : undefined,
    arrivalDate: arrivalDateRow ? Excel.getAsString(arrivalDateRow[columnIndex]) : undefined,
  }));
};

const defaultCartTypeRowIndex = 4;
const getRowIndexForLabel = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType, rowLabel: string) => Excel.findRowIndex(workSheetData, CustomerOrderWorkSheetFieldNames[workSheetType][rowLabel]);
const getCartTypeRowIndex = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType) => {
  const cartTypeRowIndex = __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'CartType');
  return cartTypeRowIndex === -1 ? defaultCartTypeRowIndex : cartTypeRowIndex;
};

const getIndexes = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType) => ({
  cartSizeRowIndex: __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'CartSize'),
  cartTypeRowIndex: __INTERNAL.getCartTypeRowIndex(workSheetData, workSheetType),
  packSizeRowIndex: __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'PackSize'),
  totalCartsRowIndex: __INTERNAL.getRowIndexForLabel(workSheetData, workSheetType, 'TotalCarts'),
});

const getVendor = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType, headerData: HeaderDatum[]): string => {
  if (workSheetType !== CustomerOrderWorkSheetType.Distribution)
    return KnownVendor.MFC;

  if (workSheetData.length === 0)
    throw new Error('Unable to find vendor.');

  const vendor = Excel.getAsString(workSheetData[0].A);
  if (!vendor || vendor.length === 0)
    throw new Error('Vendor is empty');

  return vendor;
};

const getDateFromHeaderData = (headerData: HeaderDatum[]) => {
  const dates = _.uniq(_.compact(headerData.map(data => data.date)));
  if (dates.length !== 1)
    throw new Error(`Invalid order date provided in the spreadsheet. There can only be one order date for this order (it contains: ${dates.join(', ')}). Please update the spreadsheet to include only a single order date.`);

  return parseDate(dates[0], 'MM/DD/YYYY');
};

const getArrivalDateFromHeaderData = (headerData: HeaderDatum[]) => {
  const dates = _.uniq(_.compact(headerData.map(data => data.arrivalDate)));
  if (dates.length === 0)
    return undefined;
  else if (dates.length > 1)
    throw new Error('Invalid delivery date provided in the spreadsheet. There can only be delivery one date for this order. Please update the spreadsheet to include only a single delivery date.');

  return parseDate(dates[0], 'MM/DD/YYYY');
};

const getPoLetterFromHeaderData = (headerData: HeaderDatum[]) => {
  const poLetters = _.uniq(_.compact(headerData.map(data => data.poLetter)));
  if (poLetters.length !== 1)
    throw new Error('Invalid PO Letter provided in the spreadsheet. There can only be one PO Letter for this order. Please update the spreadsheet to include only a single PO Letter.');

  return poLetters[0];
};

const getPoNumber = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType, headerData: HeaderDatum[]): string | undefined => {
  if (workSheetData.length === 0)
    throw new Error('Unable to find PO number.');

  if (workSheetType === CustomerOrderWorkSheetType.Distribution) {
    const date = __INTERNAL.getDateFromHeaderData(headerData);
    const poLetter = __INTERNAL.getPoLetterFromHeaderData(headerData);
    if (poLetter && poLetter.length > 1)
      return poLetter;

    return `S${formatDate(date, 'MMDDYY')}${poLetter || ''}`;
  } else {
    const poNumber = Excel.getAsString(workSheetData[0].C);
    if (!poNumber || poNumber.length === 0)
      throw new Error('PO number is empty.');

    return poNumber;
  }
};

const findFirstAllocationRowIndex = (workSheetData: CustomerOrderWorkSheetData) => _.uniq(workSheetData.map(row => `${row.A}`)).findIndex(cell => storeIdentifierRegExp.test(cell));
const storeIdentifierRegExp = /^[0-9]{1,4}$/;
const findLastAllocationRow = (workSheetData: CustomerOrderWorkSheetData) => {
  if (workSheetData.length === 0)
    throw new Error(`Unable to find last allocation row: the spreadsheet appears empty.`);

  const firstAllocationRowIndex = __INTERNAL.findFirstAllocationRowIndex(workSheetData);
  if (firstAllocationRowIndex < 0)
    throw new Error(`Unable to find last allocation row: It seems there were no allocation rows at all.`);

  const rowAfterLastAllocation = workSheetData.findIndex((x, i) => i > firstAllocationRowIndex && !storeIdentifierRegExp.test(`${x.A}`));
  return (rowAfterLastAllocation > -1 ? rowAfterLastAllocation : workSheetData.length) - 1;
};

const isComboCart = (cartType: string | undefined) => !!cartType && cartType.startsWith('C');

interface InternalImportableCustomerOrderProduct {
  allocations?: ImportableCustomerOrderAllocation[];
  columnGroupingKey: string;
  columnIndex: string;
  customerOrderProductGroupIdentifier: string | undefined;
  identifier: string | undefined;
  description: string | undefined;
  packSize: Int | undefined;
  packsPerShippingUnit: Int | undefined;
  sku: string | undefined;
  totalRacks: number | undefined;
}

const undefinedPacksPerShippingUnit = 9999;

const getImportableCustomerOrderProducts = (workSheetData: CustomerOrderWorkSheetData, workSheetType: CustomerOrderWorkSheetType, headerData: HeaderDatum[]): InternalImportableCustomerOrderProduct[] => {
  const indexes = getIndexes(workSheetData, workSheetType);
  if (indexes.cartTypeRowIndex === -1)
    throw new Error('Unable to find cart type row.');

  const products = headerData
    .map((headerDatum): InternalImportableCustomerOrderProduct => {
      const customerOrderProductGroupIdentifier = workSheetType === CustomerOrderWorkSheetType.Distribution
        ? 'FR'
        : Excel.getAsString(workSheetData[indexes.cartTypeRowIndex][headerDatum.columnIndex]);

      const columnGroupingKey = __INTERNAL.isComboCart(customerOrderProductGroupIdentifier)
        ? `${customerOrderProductGroupIdentifier}`
        : workSheetType === CustomerOrderWorkSheetType.Distribution
          ? `${customerOrderProductGroupIdentifier}-${headerDatum.identifier}-${!headerDatum.upc?.length ? headerDatum.description : headerDatum.upc}`
          : `${customerOrderProductGroupIdentifier}-${headerDatum.sku || headerDatum.identifier}`;

      const totalRacks = indexes.totalCartsRowIndex !== -1
        ? Excel.getAsDecimal(workSheetData[indexes.totalCartsRowIndex][headerDatum.columnIndex])
        : undefined;

      const packsPerShippingUnit = indexes.cartSizeRowIndex !== -1
        ? Excel.getAsInteger(workSheetData[indexes.cartSizeRowIndex][headerDatum.columnIndex])
        : undefined;

      return {
        columnIndex: headerDatum.columnIndex,
        columnGroupingKey,
        sku: headerDatum.sku,
        identifier: headerDatum.identifier,
        description: headerDatum.description,
        customerOrderProductGroupIdentifier,
        totalRacks,
        packsPerShippingUnit: packsPerShippingUnit !== undefinedPacksPerShippingUnit ? packsPerShippingUnit : undefined,
        packSize: indexes.packSizeRowIndex >= 0 ? Excel.getAsInteger(workSheetData[indexes.packSizeRowIndex][headerDatum.columnIndex]) : undefined,
      };
    })
    .filter(product => product.sku !== undefined || product.identifier !== undefined);

  return products;
};

const getImportableCustomerOrderProductsWithAllocations = (workSheetData: CustomerOrderWorkSheetData, importableCustomerOrderProducts: InternalImportableCustomerOrderProduct[]): InternalImportableCustomerOrderProduct[] => {
  const storeIdentifierColumnIndex = 'A';
  const firstAllocationRowIndex = __INTERNAL.findFirstAllocationRowIndex(workSheetData);
  const lastAllocationRowIndex = __INTERNAL.findLastAllocationRow(workSheetData);
  const allocationRows = workSheetData.slice(firstAllocationRowIndex, lastAllocationRowIndex + 1);
  return importableCustomerOrderProducts
    .map(iCOP => ({
      ...iCOP,
      allocations: _.compact(allocationRows.map((allocationRow, index): ImportableCustomerOrderAllocation | undefined => {
          const storeIdentifier = Excel.getAsString(allocationRow[storeIdentifierColumnIndex]);
          const quantity = iCOP.columnIndex
            ? Excel.getAsInteger(allocationRow[iCOP.columnIndex])
            : undefined;

          if (!quantity || !storeIdentifier)
            return undefined;

          if (quantity < 0) {
            const excelRow = index + firstAllocationRowIndex + 1;
            throw new Error(`Negative value found at cell ${iCOP.columnIndex}${excelRow}.`);
          }

          return { quantity, storeIdentifier: _.padStart(`${storeIdentifier}`, 4, '0') };
        })),
    }))
    .filter(importableCustomerOrderProduct => importableCustomerOrderProduct.allocations.length > 0);
};

const getImportableCustomerOrderProductGroups = (importableCustomerOrderProducts: InternalImportableCustomerOrderProduct[]): ImportableCustomerOrderProductGroup[] =>
    Object.values(_.groupBy(importableCustomerOrderProducts, 'columnGroupingKey')).map((iCOPG): ImportableCustomerOrderProductGroup => ({
        identifier: (firstOrDefault(iCOPG) || orThrow('Unable to find customer order product')).customerOrderProductGroupIdentifier,
        description: firstOrDefault(iCOPG)?.description,
        isCombo: iCOPG.length > 1,
        customerOrderProducts: iCOPG.map(internalCustomerOrderProduct => _.omit(internalCustomerOrderProduct, ['columnGroupingKey', 'columnIndex', 'customerOrderProductGroupIdentifier', 'packsPerShippingUnit', 'description'])),
        packsPerShippingUnit: (firstOrDefault(iCOPG) || orThrow('Unable to find customer order product')).packsPerShippingUnit,
    }));

const getOrderType = (workSheetType: CustomerOrderWorkSheetType): CustomerOrderType => workSheetType === CustomerOrderWorkSheetType.Distribution ? CustomerOrderType.ExternalDistribution : CustomerOrderType.Replenishment;
const deliveryDateReplaceRegex = /^(([A-Z]+)|(2))([0-9]{2})([0-9]{2})([0-9]{2}).*$/;
const getOrderDates = (workSheetType: CustomerOrderWorkSheetType, headerData: HeaderDatum[], workSheetPoNumber: string): { storeDeliveryDate: DateStr, arrivalDate?: DateStr } => {
  if (workSheetType === CustomerOrderWorkSheetType.Distribution) {
    return {
      storeDeliveryDate: __INTERNAL.getDateFromHeaderData(headerData),
      arrivalDate: __INTERNAL.getArrivalDateFromHeaderData(headerData),
    };
  } else {
    if (!deliveryDateReplaceRegex.test(workSheetPoNumber))
      throw new Error(`Unable to determine Delivery Date from the PO Number ${workSheetPoNumber}. Ensure the PO Number in the spreadsheet contains the date in MMDDYY format.`);

    return { storeDeliveryDate: parseDate(workSheetPoNumber.replace(deliveryDateReplaceRegex, '20$6-$4-$5'), 'YYYY-MM-DD') };
  }
};

interface SuccessfulParsedCustomerOrder { success: true, parsed: ImportableCustomerOrder }
interface FailedParsedCustomerOrder { success: false, reason: string, stack?: string }
type ParsedCustomerOrderResult = SuccessfulParsedCustomerOrder | FailedParsedCustomerOrder;
export const getImportableCustomerOrderFromWorkBookContainer = async (workBookContainer: WorkBookContainer): Promise<ParsedCustomerOrderResult> => {
  try {
    const workBook = await __INTERNAL.getCustomerOrderWorkBookFromWorkBookContainer(workBookContainer) || orThrow(new ExpectedError('Unable to load workbook.'));
    const workSheet = __INTERNAL.getCustomerOrderWorkSheet(workBook) || orThrow(new ExpectedError('Unable to load worksheet.'));
    const workSheetData = __INTERNAL.getCustomerOrderWorkSheetData(workSheet);
    if (Object.keys(workSheetData[0] ?? {}).length < 1) orThrow(new ExpectedError('Unable to load rows and columns.'));
    const workSheetType = __INTERNAL.getCustomerOrderWorkSheetType(workSheetData);
    if (workSheetType === CustomerOrderWorkSheetType.Unknown) orThrow(new ExpectedError('Unrecognized worksheet format.'));
    const headerData = __INTERNAL.getHeaderData(workSheetData, workSheetType);
    const poNumber = __INTERNAL.getPoNumber(workSheetData, workSheetType, headerData) || orThrow(new ExpectedError('Unable to find PO number.'));
    const importableCustomerOrderProducts = __INTERNAL.getImportableCustomerOrderProducts(workSheetData, workSheetType, headerData);
    if (importableCustomerOrderProducts.length === 0) orThrow(new ExpectedError('Unable to find products.'));
    const importableCustomerOrderProductGroups = __INTERNAL.getImportableCustomerOrderProductGroups(await __INTERNAL.getImportableCustomerOrderProductsWithAllocations(workSheetData, importableCustomerOrderProducts));
    if (importableCustomerOrderProductGroups.length === 0) orThrow(new ExpectedError('Unable to find allocations.'));
    return {
      success: true,
      parsed: {
        poNumber,
        ...__INTERNAL.getOrderDates(workSheetType, headerData, poNumber),
        vendor: __INTERNAL.getVendor(workSheetData, workSheetType, headerData),
        orderType: __INTERNAL.getOrderType(workSheetType),
        customerOrderProductGroups: importableCustomerOrderProductGroups,
        customerIdentifier: workSheetType === CustomerOrderWorkSheetType.Distribution ? undefined : _(workSheetData).map(x => Excel.getAsString(x.B)).find(x => 3 === x?.length)?.trim(),
      },
    };
  } catch (error) {
    return { success: false, reason: `${error} ${error.isExpectedError ? '' : 'The selected spreadsheet may be corrupt or invalid.'}`, stack: error.stack };
  }
};

/**
 * These functions and types are deliberately not exported,
 * so we can make inferences about their usage and provenance.
 * But for unit testing, we need to access them from test modules.
 * This exported const wraps the internal functions and types and punches
 * a hole for tests to use them, while making it painfully obvious to devs
 * that only tests should use this back door, thus preserving our ability
 * to make those helpfully constraining assumptions about the execution and data flow graphs.
 *
 * This object also facilitates mocking when targeting ES modules.
 */
export const __INTERNAL = {
  getColumnIndexes,
  getRowIndexForLabel,
  getCartTypeRowIndex,
  getPoLetterFromHeaderData,
  findFirstAllocationRowIndex,
  findLastAllocationRow,
  isComboCart,
  VALID_DATA: 0 as unknown as CustomerOrderWorkSheetData,
  getCustomerOrderWorkBookFromWorkBookContainer,
  getCustomerOrderWorkSheet,
  getCustomerOrderWorkSheetData,
  getCustomerOrderWorkSheetType,
  getHeaderData,
  getVendor,
  getDateFromHeaderData,
  getArrivalDateFromHeaderData,
  getPoNumber,
  getImportableCustomerOrderProducts,
  getImportableCustomerOrderProductsWithAllocations,
  getImportableCustomerOrderProductGroups,
  getOrderType,
  getOrderDates,
};
