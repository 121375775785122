import buildAdminCreatePage from '../admin/admin-create-page';
import {ProductInfoFormComponent as FormComponent} from '../../components/crud-forms/product/product-info-form-group';
import navBuilder from './nav-items';

export default buildAdminCreatePage({
  table: 'products',
  formName: 'ProductForm',
  FormComponent,
  navBuilder,

  duplicateOptions: {
    type: 'custom',
    handler: (initialValues, props) => {
      const {
        id, // eslint-disable-line @typescript-eslint/no-unused-vars
        replenishmentIdentifier, // eslint-disable-line @typescript-eslint/no-unused-vars
        sku, // eslint-disable-line @typescript-eslint/no-unused-vars
        upcCodesId, // eslint-disable-line @typescript-eslint/no-unused-vars
        upcCodes, // find primary from this
        ...rest } = initialValues;

      const primaryUpcCode = upcCodes.filter(u => !!u.primary);

      return {
        upcCodes: primaryUpcCode,
        ...rest,
      };
    },
  },
});
