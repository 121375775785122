import { DISPLAY_TYPES, INPUT_TYPES } from '../types';
import { Supplier } from './supplier';
import { tableName, property, belongsTo, definePresentation, required } from './dsl';
import { IRecord } from './record';
import { displayType } from './dsl';
import { SupplierDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('boxes')
export class Box implements IRecord {
  id?: number;
  @property @required identifier: string;
  @property @required description: string;

  @belongsTo('suppliers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required supplier: Supplier;
  supplierId: number;

  @property partNumber?: string | null;
  @property productIdentifier?: string | null;

  @property height?: number | null;
  @property width?: number | null;
  @property length?: number | null;
}

definePresentation(Box, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'Box ID',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
  },
  description: {
    searchable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 30,
  },
  supplier: {
    searchable: true,
    sortable: true,
    tableDisplay: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }),
    columnWidth: 25,
  },
  partNumber: {
    searchable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
  },
  productIdentifier: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  height: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  width: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  length: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
});
