import * as React from 'react';
import * as classNames from 'classnames';

interface ModalContentProps {
  children?: any;
  modalTitle: string;
  headerClasses: string;
}

class ModalContent extends React.PureComponent<ModalContentProps, {}> {
  render() {

    const headClasses = classNames('modal-header', this.props.headerClasses);
    return (
      <div className="modal-content">
        <div className={headClasses}>
          <h4 className="modal-title" id="myModalLabel">{this.props.modalTitle}</h4>
        </div>
        <div className="modal-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ModalContent;
