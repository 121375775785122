import * as State from 'client/state/navigation';
import { ActionTypes, ActionTypeKeys } from 'client/actions/navigation';

type NavigationState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): NavigationState {
  switch (action.type) {
    case ActionTypeKeys.NAVIGATION_SET_RECORDBAR_GO_BACK_TO_LOCATION:
      return State.recordBarGoBackToLocation.set(action.payload.recordBarGoBackToLocation)(state);
    default:
        return state;
  }
}
