import { change } from 'redux-form';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

export function customerChanged(formName: string, customerId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(change(formName, 'customerId', customerId));
    dispatch(change(formName, 'regionId', null));
    dispatch(change(formName, 'marketId', null));
    dispatch(change(formName, 'mfcAreas', []));
    dispatch(change(formName, 'mfcAreasId', []));
    dispatch(change(formName, 'comparableCustomerId', null));
  };
}

export function regionChanged(formName: string, regionId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(change(formName, 'regionId', regionId));
    dispatch(change(formName, 'marketId', null));
  };
}

export function mfcAreaChanged(formName: string, index: number, mfcAreaId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(change(formName, `mfcAreasId[${index}]`, mfcAreaId));
  };
}

export function merchandiserClicked(id: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(push(`/admin/merchandisers/details/${id}`));
  };
}
