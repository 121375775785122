import * as React from 'react';
import * as _ from 'lodash';
import { Form, FormGroup, Col, Row } from 'client/components/third-party';
import { LabeledSelect, FormField } from 'client/components/form';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import * as Constants from './constants';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { Options } from 'client/hoc/options-container-generator';
import * as Validators from 'shared/validators';
import { DateTimeStr } from 'shared/types';
import * as classnames from 'classnames';
import { formatDateTimeStrForDisplay } from 'shared/helpers';
import { EMPTY_ARRAY } from 'client/constants';
import { createSelector } from 'reselect';

export interface OwnProps {
  supplierCommitmentId?: number;
  handleSubmit: React.EventHandler<React.FormEvent<Form>>;
  supplierOptions: Options | undefined;
  salesPlanOptions: Options | undefined;
  loading?: boolean;
  lastModifiedAt: DateTimeStr | undefined;
  lastModifiedBy: string | undefined;
  onDownloadMenuItemClicked(supplierCommitmentId: number): Promise<void>;
  onDownloadSupplierSpecSheetMenuItemClicked(supplierCommitmentId: number): Promise<void>;
  onUpdateSupplierItemCostsClicked(supplierCommitmentId: number): any;
}

const supplierTextFormatter = option => `${option.identifier} - ${option.name}`;
const salesPlanTextFormatter = option => `${option.year} - ${option.customer.identifier} - ${option.identifier} - ${_.startCase(_.lowerCase(option.sellDepartment.identifier))}`;

const requiredValidator = Validators.makeRequiredFormValidator();

export class LoadDetailsSidebarUI extends React.PureComponent<OwnProps, {}> {

  constructor(props: OwnProps) {
    super(props);
  }

  getMenuItems = createSelector([
    (props: OwnProps) => props.onDownloadMenuItemClicked,
    (props: OwnProps) => props.onUpdateSupplierItemCostsClicked,
    (props: OwnProps) => props.onDownloadSupplierSpecSheetMenuItemClicked,
    (props: OwnProps) => props.supplierCommitmentId,
  ], (handleDownloadClicked, handleUpdateSupplierItemCostsClicked, handleDownloadSupplierSpecSheet, supplierCommitmentId) => {
    return [
      {
        label: 'Download Spreadsheet',
        action: async (setInProgress: SetInProgress) => {
          setInProgress(true);
          try {
            if (supplierCommitmentId) {
              await handleDownloadClicked(supplierCommitmentId);
            }
          } finally {
            setInProgress(false);
          }
        },
      },
      {
        label: 'Download Spec Sheet',
        action: async (setInProgress: SetInProgress) => {
          setInProgress(true);
          try {
            if (supplierCommitmentId) {
              await handleDownloadSupplierSpecSheet(supplierCommitmentId);
            }
          } finally {
            setInProgress(false);
          }
        },
      },
      {
        label: 'Update Supplier Item Costs',
        action: () => {
          if (supplierCommitmentId) {
            handleUpdateSupplierItemCostsClicked(supplierCommitmentId);
          }
        },
      },
    ];
  });

  render() {
    if (this.props.loading) {
      return <ProgressSpinner label="Loading..." waitToDisplayMillis={200} />;
    }

    return (
      <div className="mfc-form-sidebar">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <HiddenFocusField />
          <FormGroup>
            {!!this.props.supplierCommitmentId &&
              <>
                <Col sm={6}>

                </Col>
                <SimpleMenu
                  label="Actions"
                  className="supplier-commitment-actions-menu"
                  menuItems={this.getMenuItems(this.props)}
                  right
                />
              </>
            }
          </FormGroup>
          <FormGroup>
            <FormField
                formName={Constants.formName}
                name={Constants.FormFields.salesPlanId}
                label="Sales Plan"
                testid="supplier-commitment-form-sales-plan"
                horizontalLabel={false}
                options={this.props.salesPlanOptions || EMPTY_ARRAY}
                valueField="id"
                textFormatter={salesPlanTextFormatter}
                component={LabeledSelect}
                labelColSize={12}
                inputColSize={12}
                required
                validate={requiredValidator}
                disabled={(this.props.salesPlanOptions || EMPTY_ARRAY).length === 0}
              />
          </FormGroup>
          <FormGroup>
            <FormField
              formName={Constants.formName}
              name={Constants.FormFields.supplierId}
              label="Supplier"
              testid="supplier-commitment-form-supplier"
              horizontalLabel={false}
              options={this.props.supplierOptions || EMPTY_ARRAY}
              valueField="id"
              textFormatter={supplierTextFormatter}
              component={LabeledSelect}
              labelColSize={12}
              inputColSize={12}
              required
              validate={requiredValidator}
              disabled={(this.props.supplierOptions || EMPTY_ARRAY).length === 0}
            />
          </FormGroup>
          <hr className="sidebar-horizontal-rule" />
          { this.props.lastModifiedAt && <FormGroup>
              <Col sm={12}>
                <Row>
                  <div className="mfc-form-stacked-label">Last Modified</div>
                </Row>
                <Row className="mfc-order-status-details">
                  <Col sm={12} className="status">
                    <div className={classnames('status-pill', 'neutral', {ellipsis: true})}>
                        {this.props.lastModifiedBy} - {formatDateTimeStrForDisplay(this.props.lastModifiedAt) || ''}
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup> }
        </Form>
      </div>
    );
  }
}
