import { isNil, noop } from 'lodash';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import * as Actions from 'client/actions/receiving-worksheet';
import ReduxForm, { reduxForm, reset } from 'redux-form';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { BolIdentifier, BolRacksReturned, BolNotes, BolId, BolAutoCartTrackingEnabled } from 'shared/schemas/bol';
import { EditBolModal } from 'client/app/transportation/receiving/details/worksheet/edit-bol-modal/edit-bol-modal';
import { EditBolMutation, EditBolResponse } from 'client/app/transportation/receiving/details/worksheet/edit-bol-modal/edit-bol-mutation';
import { msyncQuery } from 'client/hoc/graphql/query';
import { InitialBolResponse, InitialBolQuery, InitialBolVariables } from 'client/app/transportation/receiving/details/worksheet/edit-bol-modal/edit-bol-initial-values-query';
import { WithMutationStatusProps, withMutationStatusProps, mutationStatusWrapper } from 'client/app/transportation/receiving/details/worksheet/mutation-status';
import * as State from 'client/state/state';
import { formatDateTime } from 'shared/helpers/date-helpers';
import { toDateTimeStr } from 'shared/types';

const formName = 'EditBolForm';

interface OwnProps {
  receivableOrderId: number;
}

const mapStateToProps = (state: State.Type) => {
  return {
    isShown: State.editBolModalShown(state),
    bolId: State.bolToEditId(state),
  };
};

type ReduxFormProps = ReduxForm.InjectedFormProps<FormValues>;

interface FormValues {
  identifier: BolIdentifier;
  receivedAt: string;
  racksReturned: BolRacksReturned;
  notes: BolNotes;
  user: string;
}

interface WithEditBolProps {
  editBolAndRacksReturned(input: Pick<FormValues, 'identifier' | 'racksReturned' | 'notes'> & { id: BolId, receivableOrderId: number });
}

const WithEditBol = msyncMutation<EditBolResponse, OwnProps & WithMutationStatusProps, WithEditBolProps>(EditBolMutation, {
  props: ({ mutate, ownProps }): WithEditBolProps => {
    return {
      editBolAndRacksReturned: async input => {
        return mutationStatusWrapper(mutate, ownProps)({
          variables: { input },
        });
      },
    };
  },
});

interface WithInitialBolProps {
  initialValues: FormValues | undefined;
  autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
}

const WithInitialBol = msyncQuery<InitialBolResponse, OwnProps & ReturnType<typeof mapStateToProps>, WithInitialBolProps, InitialBolVariables>(InitialBolQuery, {
  alias: 'withIntialBol',
  skip(ownProps) {
    return isNil(ownProps.bolId);
  },
  options(ownProps): { variables: InitialBolVariables } {
    return {
      variables: {
        bolId: ownProps.bolId!, // We skip if this is null
      },
    };
  },
  props({ data, ownProps }): WithInitialBolProps {
    if (!data.response) {
      return {
        initialValues: undefined,
        autoCartTrackingEnabled: false,
      };
    }

    return {
      autoCartTrackingEnabled: data.response.autoCartTrackingEnabled,
      initialValues: {
        identifier: data.response.identifier,
        receivedAt: formatDateTime(toDateTimeStr(data.response.receivedAt), 'MM/DD/YYYY - hh:mm A z') || '',
        notes: data.response.notes,
        racksReturned: data.response.racksReturned,
        user: `${data.response.user.firstName} ${data.response.user.lastName[0]}.`,
      },
    };
  },
});

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps & ReduxFormProps & WithEditBolProps & ReturnType<typeof mapStateToProps>) => {
  const hide = () => {
    dispatch(reset(formName));
    dispatch(Actions.hideEditBolModal());
  };

  return {
    onModalEnter: noop,
    onCancelButtonClicked: () => {
      hide();
    },
    handleFormSubmit: () => {
      return props.handleSubmit(async (formValues: FormValues) => {
        if (props.bolId === null) {
          // This should not happen
          throw new Error('Bol to edit not specificed when submitting form.');
        }
        const response = await props.editBolAndRacksReturned({
          id: props.bolId,
          identifier: formValues.identifier,
          notes: formValues.notes,
          racksReturned: formValues.racksReturned,
          receivableOrderId: props.receivableOrderId,
        });

        if (!response) {
          // msyncMutation will handle error display.
          return;
        }

        hide();
      });
    },
  };
};

const connector1 = connect(mapStateToProps);
const connector2 = connect(undefined, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  WithMutationStatusProps &
  ConnectedProps<typeof connector1> &
  WithInitialBolProps &
  WithEditBolProps &
  ReduxFormProps &
  ConnectedProps<typeof connector2>;

export default wrapComponent(EditBolModal)<OwnProps, CombinedProps>(
  withMutationStatusProps(State.editBolMutationStatus.get, Actions.setEditBolMutationStatus),
  connector1,
  WithEditBol,
  WithInitialBol,
  reduxForm({
    form: formName,
    enableReinitialize: true,
  }),
  connector2,
);
