import gql from 'graphql-tag';
import { DateStr } from 'shared/types';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { CartTrackingSupplierEntryQuantity, CartTrackingSupplierEntryType, CartTrackingSupplierEntryDate, CartTrackingSupplierEntryNotes, CartTrackingSupplierEntryBalance } from 'shared/schemas/cart-tracking-supplier-entry';
import { CartTrackingSupplier } from 'shared/schemas/cart-tracking-supplier';
import * as _ from 'lodash';

export interface CartTrackingSupplierEntryResponse {
  id: number;
  quantity: CartTrackingSupplierEntryQuantity;
  entryType: CartTrackingSupplierEntryType;
  entryDate: CartTrackingSupplierEntryDate;
  notes: CartTrackingSupplierEntryNotes;
  balance?: CartTrackingSupplierEntryBalance;
}

export interface SupplierSubsetWithCartTrackingResponse {
  response?: {
    id: number;
    totalCarts?: number;
    cartTrackingStatus?: SupplierCartTrackingStatus;
    lastAdjustmentDate: DateStr;
    cartTrackingSupplier?: CartTrackingSupplier;
    cartTrackingSupplierEntries?: CartTrackingSupplierEntryResponse[];
  };
}

export const CartTrackingSupplierEntryQuery = gql`
  query findCartTrackingSupplierEntryForDetails($type: RecordType = Supplier, $id: Int!) {
    response: find(type: $type, id: $id) {
      ... on Supplier {
        id
        totalCarts
        cartTrackingStatus
        lastAdjustmentDate
        cartTrackingSupplierEntries {
          id
          quantity
          entryDate
          entryType
          notes
        }
      }
    }
  }
`;

export const cartTrackingSupplierEntryRecordsSelector = (queryResponse: SupplierSubsetWithCartTrackingResponse): CartTrackingSupplierEntryResponse[] => {
  const records = queryResponse && queryResponse.response ? queryResponse.response.cartTrackingSupplierEntries || [] : [];
  let runningTotal = 0;

  // Sorting the entry type descending allows us to put the balance resets first,
  // then any other adjustment entries for the same day follow the balance reset.
  return _.reverse(
    _.orderBy(records, ['entryDate', 'entryType', 'id'], ['asc', 'desc', 'asc'])
      .filter(record => !(record.entryType === CartTrackingSupplierEntryType.Adjustment && record.quantity === 0)) // we don't want to show adjustments of zero quantity
      .map(record => {
        runningTotal = record.entryType === CartTrackingSupplierEntryType.Balance
          ? record.quantity
          : runningTotal + record.quantity;

        return { ...record, balance: runningTotal };
      }));
};
