import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { propToComponent, wrapComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import * as Actions from 'client/actions/receiving-worksheet';
import * as RelatedBolActions from 'client/actions/related-bols';
import ReceivingWorksheetUI from 'client/app/transportation/receiving/details/worksheet/worksheet-ui';
import { BolListCountQuery, BolListCountResponse } from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list-query';
import { ShowSendReceivingReceiptModalResponse, ShowSendReceivingReceiptModalQuery } from 'client/app/transportation/receiving/details/worksheet/bol-list/show-send-receiving-receipt-query';
import { ReceivableOrderReceivingStatus } from 'shared/types';
import { RouteChildrenProps } from 'react-router';

interface OwnProps {
}

interface WithBolCountProps {
  showNothing?: boolean;
  showEmptyState?: boolean;
}

interface WithShowSendReceivingReceiptModalProps {
  receivedStatus?: ReceivableOrderReceivingStatus;
}

interface WithReceivableOrderId {
  receivableOrderId: number;
  requestedBolId?: number;
}

const withReceivableOrderId = WrappedComponent => (props: RouteChildrenProps<{ id: string, bolId?: string }>) => {
  if (!props.match) {
    throw new Error('Missing path information for receiving details');
  }

  const params = props.match.params;

  const updatedProps = {
    receivableOrderId: Number.parseInt(params.id),
    requestedBolId: params.bolId ? Number.parseInt(params.bolId) : undefined,
  };

  return propToComponent(WrappedComponent, updatedProps);
};

const WithBolCount = msyncQuery<BolListCountResponse, OwnProps & WithReceivableOrderId, WithBolCountProps>(BolListCountQuery, {
  alias: 'withBolCount',
  options(ownProps) {
    return {
      variables: {
        id: ownProps.receivableOrderId,
      },
    };
  },
  props({ data, ownProps }) {
    return {
      showNothing: data.loading,
      showEmptyState: data.receivableOrder ? data.receivableOrder.bols === null || data.receivableOrder.bols.length === 0 : false,
    };
  },
});

const WithShowSendReceivingReceiptModal = msyncQuery<ShowSendReceivingReceiptModalResponse, OwnProps & WithReceivableOrderId, WithShowSendReceivingReceiptModalProps>(
  ShowSendReceivingReceiptModalQuery, {
    alias: 'withShowSendReceivingReceiptModal',
    options(ownProps) {
      return {
        variables: {
          receivableOrderId: ownProps.receivableOrderId,
        },
      };
    },
    props({ data, ownProps }) {
      return {
        receivedStatus: data.receivableOrder && data.receivableOrder.receivingStatus,
      };
    },
  });


const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAddBolClicked: () => {
      dispatch(Actions.setAddBolModalVisibility(true));
    },
    showSendReceivingReceiptModal: () => {
      dispatch(RelatedBolActions.setSendReceivingReceiptModalVisibility(true));
    },
  };
};

const connector = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  WithReceivableOrderId &
  WithBolCountProps &
  WithShowSendReceivingReceiptModalProps &
  ConnectedProps<typeof connector>;

export const ReceivingWorksheet = wrapComponent(ReceivingWorksheetUI)<OwnProps, CombinedProps>(
  withReceivableOrderId,
  WithBolCount,
  WithShowSendReceivingReceiptModal,
  connector,
);
