import * as Actions from './actions';
import * as HOC from 'client/hoc/hoc';
import * as SalesPlanState from 'client/state/sales-plan';
import * as ReactRedux from 'react-redux';
import * as State from 'client/state/state';
import * as UI from './ui';

export interface OwnProps {
  salesPlanId: number;
}

const mapStateToProps = (state: State.Type) => {
  return {
    isShown: SalesPlanState.importSalesPlanVarietyModalState(state.salesPlan),
  };
};

const mapDispatchToProps = {
  handleCloseButtonClicked: Actions.handleCloseButtonClicked,
  handleCancelButtonClicked: Actions.handleCancelButtonClicked,

  handleParsingStarted: Actions.handleParsingStarted,
  handleValidationStarted: Actions.handleValidationStarted,
  handleImportStarted: Actions.handleImportStarted,
};

const connector = ReactRedux.connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ReactRedux.ConnectedProps<typeof connector>;

export const ImportSalesPlanVarietyColorModalContainer = HOC.wrapComponent(UI.ImportSalesPlanVarietyColorModalUI)<OwnProps, CombinedProps>(
  connector,
);
