import { tableName, property, definePresentation, belongsTo, setSchemaOptions, required } from 'shared/schemas/dsl';
import { Store } from 'shared/schemas/store';
import { MfcArea } from 'shared/schemas/mfc-area';
import { IRecord } from 'shared/schemas/record';

@tableName('storesMfcAreas')
export class StoresMfcArea implements IRecord {
  id?: number;

  @belongsTo('stores')
  @property @required store: Store;
  storeId: number;

  @belongsTo('mfcAreas')
  @property @required mfcArea: MfcArea;
  mfcAreaId: number;
}

definePresentation(StoresMfcArea, {
  store: {
    formDisplay: false,
  },
  mfcArea: {
    formDisplay: false,
    displayName: 'MFC Area',
  },
});

setSchemaOptions(StoresMfcArea, {
  hasTimestamps: false,
  joins: ['store', 'mfcArea'],
});
