import { RoutePlanIdentifier, RoutePlanId, RoutePlanStatus, ExtractRoutePlanResponse } from 'schema/route-plan/route-plan-typescript-types';
import { propToComponent } from 'client/hoc/hoc';
import { isNil } from 'lodash';
import { ApolloQueryResult } from 'apollo-client';
import { ExtractRoutePlanMutationProps } from 'client/app/transportation/routing/sidebar/route-plan-mutation';
import { Bug } from 'shared/helpers';

export interface WithDownloadCsvAction {
  onExtractOrdersClicked(): Promise<void>;
}

interface WithDownloadCsvActionProps {
  routePlanId: RoutePlanId;
  routePlanIdentifier: RoutePlanIdentifier;
  extractRoutePlan?: (routePlanId: RoutePlanId) => Promise<ApolloQueryResult<ExtractRoutePlanResponse> | undefined>;
  showBusyModal?(): void;
  hideBusyModal?(): void;
}

/**
 * Note: If you call this, make sure you prop down the extractRoutePlan mutation
 * Otherwise, the route plan status and other necessary information (e.g. csvName) will not be updated
 */
export const withDownloadCsvAction = WrappedComponent => (upstreamProps: WithDownloadCsvActionProps & ExtractRoutePlanMutationProps) => {
  return propToComponent(WrappedComponent, {
    ...upstreamProps,
    onExtractOrdersClicked: async () => {
      if (!upstreamProps.extractRoutePlan || !upstreamProps.showBusyModal || !upstreamProps.hideBusyModal) {
        throw new Bug('An error has occured while trying to extract customer orders and download the CSV');
      }

      try {
        upstreamProps.showBusyModal();

        if (upstreamProps.routePlanId && upstreamProps.routePlanIdentifier) {
          const response = await upstreamProps.extractRoutePlan(upstreamProps.routePlanId);
          if (response) {
            window.location.replace(`/report/fileDownload/${response.data.extractRoutePlan.directRouteCsv.id}`);
          }
        }
      } finally {
        upstreamProps.hideBusyModal();
      }
    },
  });
};

export const withExtractAndImportButtonDisabledStates = WrappedComponent => upstreamProps => {
  return propToComponent(WrappedComponent, {
    ...upstreamProps,
    extractOrdersButtonDisabled: isNil(upstreamProps.routePlanId) || upstreamProps.status !== RoutePlanStatus.ReadyToRoute,
    importRouteFileButtonDisabled: isNil(upstreamProps.routePlanId) || upstreamProps.status !== RoutePlanStatus.Extracted,
  });
};
