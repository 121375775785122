import * as React from 'react';
import { sortBy } from 'lodash';
import { ProductListItem } from './product-list-item';
import { ComboCart, OrderMethod } from 'shared/types';
import * as classnames from 'classnames';
import { getFormattedProductConfiguration, getHeading } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-helpers';

export interface Props {
  comboCart: ComboCart;
  orderMethod: OrderMethod;
  selectedProductId: number;
  isChecked: boolean;
  handleProductClicked: (productId: number) => void;
  handleComboCartChecked: (comboCartId: number) => void;
  handleTrashCanClicked: (comboCartId: number) => void;
  handleComboCartPencilClicked: (comboCartId: number) => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class ComboCartProductListItem extends React.Component<Props, State> {
  public render() {
    const { comboCart, selectedProductId, isChecked, orderMethod, handleComboCartChecked, handleProductClicked, handleTrashCanClicked, handleComboCartPencilClicked } = this.props;

    let isSelected = false;

    const elements = sortBy(comboCart.products, ['id']).reduce((result, detail) => {
      const key = `${detail.id}-detail`;
      const className = classnames('product-worksheet-products-list-combo-cart-shipment-configuration', {
        'product-worksheet-is-selected': detail.productId === selectedProductId,
      });

      const heading = getHeading(detail.product.identifier, detail.product.description);
      const productConfiguration = getFormattedProductConfiguration(orderMethod, {
        packSize: detail.packSize,
        packsPerShelf: detail.packsPerShelf,
        packsPerShippingUnit: detail.packsPerShippingUnit,
        productDescription: detail.product.description,
        productId: detail.productId,
        productIdentifier: detail.product.identifier,
        shelvesPerRack: detail.shelvesPerRack,
      }, key);

      if (detail.productId === selectedProductId) {
        isSelected = true;
      }

      result.push(
        <div key={detail.id} className={className} onClick={() => handleProductClicked(detail.productId)}>
          <span>{heading}</span>
          {productConfiguration}
        </div>,
      );
      return result;

    }, [] as  JSX.Element[]);
    const items = [
      {
        id: comboCart.products[0].id,
        elements,
      },
    ];
    return (
      <ProductListItem
        header={`${comboCart.identifier} - ${comboCart.description}`}
        testId={`product-list-item-${comboCart.identifier}`}
        items={items}
        isSelected={isSelected}
        isChecked={isChecked}
        handleProductClicked={() => handleProductClicked(comboCart.products[0].productId)}
        handleCheckboxChecked={() => handleComboCartChecked(comboCart.id)}
        handlePencilClicked={() => handleComboCartPencilClicked(comboCart.id)}
        handleTrashCanClicked={() => handleTrashCanClicked(comboCart.id)}
      />
    );
  }
}
