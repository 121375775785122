import * as State from 'client/state/reports';
import * as SharedReportActions from 'client/app/orders/reports/shared/actions';
import { MutationStatus } from 'client/actions/mutations';

const ActionTypeKeys = {
  ...SharedReportActions.ActionTypeKeys,
};

type ActionTypes = SharedReportActions.ActionTypes;
/*
 * DK TODO 2018-10-31 - I don't love this, I was hoping to have each reducer in
 * the respective sub-folder, however, it wasn't working. Talk to me for more
 * information.
 */
export default function reducer(state = State.InitialState, action: ActionTypes): State.Type {
  switch (action.type) {
    case ActionTypeKeys.ReportDownloadStarted: {
      return State.reportDownloadStatus.set(state, MutationStatus.InProgress);
    }
    case ActionTypeKeys.ReportDownloadFinished: {
      return State.reportDownloadStatus.set(state, MutationStatus.Initial);
    }
    default:
      return state;
  }
}
