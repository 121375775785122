import * as React from 'react';
import * as classNames from 'classnames';
import FilterDropdown from './filter-dropdown';
import { FilterDate } from './filter-date';
import { TYPES, toDateStr } from '../../../shared/types';
import { AvailableFilterOption } from 'client/types';

const Filter = ({ separator, type, selectedOptions, ...props }: {
  type: string,
  separator: boolean,
  options?: AvailableFilterOption[],
  selectedOptions: string[],
  placeholder: string,
  onSelected: (...args: unknown[]) => unknown,
  testid: string,
  multi: boolean,
}) => {
  const classes = classNames('form-group', {
    'no-separator': !separator,
  });

  if (type === TYPES.DATE) {
    const dateRange = selectedOptions?.length !== 2 ? undefined : {
      startDate: toDateStr(selectedOptions[0]),
      endDate: toDateStr(selectedOptions[1]),
    };
    return <div className={classes}><FilterDate dateRange={dateRange} label={props.placeholder} {...props} /></div>;
  }

  return <div className={classes}><FilterDropdown type={type} selectedOptions={selectedOptions} {...props} /></div>;
};

Filter.defaultProps = {
  separator: false,
};

export default Filter;
