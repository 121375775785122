import * as React from 'react';
import EditableCell from './editable-cell';
import { DateTimeStr, toDateTimeStr } from 'shared/types';
import { formatDateTime } from 'shared/helpers/date-helpers';

interface ReadOnlyDateTimeCellProps {
  value: DateTimeStr;
}

interface DateTimeCellProps extends ReadOnlyDateTimeCellProps {
  editing: boolean;
  onChange: (newValue: any) => void;
  testid: string;
}

function ReadOnlyDateTimeCell({ value }: ReadOnlyDateTimeCellProps) {
  if (value && value !== '--') {
    // Calling 'toDateTimeStr' on something that is already typed as a DateTimeStr might seem redundant
    // However, the type in the schema (coming back from postgres) is a bit of a misnomer.
    // We need to create a DateTimeStr to keep the time zone, otherwise it will ignore it.
    return <div>{formatDateTime(toDateTimeStr(value), 'MM/DD/YYYY - h:mm A z')}</div>;
  } else {
    return <div className="number">-</div>;
  }
}

export default function DateTimeCell({ value, editing, onChange, testid }: DateTimeCellProps) {
  return editing
    ? <EditableCell onChange={onChange} testid={testid} initialValue={value} inputType={'datetime'} decimal/>
    : <ReadOnlyDateTimeCell value={value} />;
}
