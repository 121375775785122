import { SalesPlan, SalesPlanId } from 'shared/schemas/sales-plan';
import { Saved } from 'shared/schemas/record';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { CustomerId } from 'shared/schemas/customer';
import { DateStr, CustomerOrderType } from 'shared/types';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { dateToWeekNumber, yearOf } from 'shared/helpers/date-helpers';

// Partial<OwnProps & DataProps & StateProps & DispatchProps>
export interface ArgsForGetAutoSelectableSalesPlanId extends Dictionary<any> {
  // from data
  salesPlans?: Array<Saved<SalesPlan>>;
  // from redux form
  salesPlanId?: SalesPlanId;
  customerId?: CustomerId;
  sellDepartmentId?: SellDepartmentId;
  orderDate?: DateStr;
  orderType?: CustomerOrderType;
  // from current props (not nextProps)
  oldCustomerOrderId: CustomerOrderId | null;
  oldCustomerId: CustomerId | null;
  oldSellDepartmentId: SellDepartmentId | null;
  oldOrderDate: DateStr | null;
  oldOrderType: CustomerOrderType | null;
}

export function getAutoSelectableSalesPlanId(args: ArgsForGetAutoSelectableSalesPlanId): Saved<SalesPlan> | undefined {
  // Require a NEW customer order (not editing an old one) for REPLENISHMENT only.  Also we need sell depts.
  if (args.oldCustomerOrderId || args.orderType !== CustomerOrderType.Replenishment) {
    return;
  }

  // Require that some of customer, order date, sell department, or orderType have CHANGED
  if (args.oldCustomerId === args.customerId
    && args.oldOrderDate === args.orderDate
    && args.oldSellDepartmentId === args.sellDepartmentId
    && args.oldOrderType === args.orderType) {
    return;
  }

  // Require available sales plans, a chosen customer, and a chosen order date - AND require that a sales plan isn't already selected
  if (!args.salesPlans || !args.customerId || !args.orderDate || !args.sellDepartmentId || !!args.salesPlanId) {
    return;
  }

  const orderWeek = dateToWeekNumber(args.orderDate);

  return args.salesPlans
    .filter(x => args.orderDate && x.year === yearOf(args.orderDate))
    .filter(x => x.customer.id === args.customerId)
    .filter(x => x.endWeek >= orderWeek)
    .filter(x => x.startWeek <= orderWeek + 2) // Matt said: "I've found that sometimes the orders are placed up to two weeks before the start of the season (??)"
    .filter(x => x.sellDepartment.id === args.sellDepartmentId)
    .shift();
}
