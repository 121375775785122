import * as React from 'react';
import * as classNames from 'classnames';

export const Checkbox = (p: {
  label?: string;
  disabled?: boolean;
  input: { value: any, onChange?: (value: boolean) => void };
  onClick?: (newValue: boolean) => void;
  className?: string;
  testid?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <label
      data-testid={p.testid}
      className={classNames('mfc-checkbox', { checked: p.input.value, disabled: p.disabled }, p.className || '')}
      onClick={() => { p.input.onChange?.(!p.input.value); p.onClick?.(!p.input.value); }}
    >
      <span />
      {p.label}
      {p.children}
    </label>
  );
};
