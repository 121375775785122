import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import { DateTimeStr } from 'shared/types';

export interface UIProps {
  vendorLastModifiedAt?: DateTimeStr;
  vendorIdentifier?: string;
  onBackButtonClicked(): void;
  onSaveButtonClicked(): void;
  saveButtonDisabled: boolean;
}

export class VendorRecordBarUI extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <RecordBar
        updatedAt={this.props.vendorLastModifiedAt}
        onBackButtonClicked={this.props.onBackButtonClicked}
        onSaveButtonClicked={this.props.onSaveButtonClicked}
        saveButtonDisabled={this.props.saveButtonDisabled}>
        <RecordBarDetail title="Vendor ID" testid="record-bar-vendor-identifier">
          {this.props.vendorIdentifier}
        </RecordBarDetail>
      </RecordBar>
    );
  }
}
