import * as _ from 'lodash';
import { flatten } from 'lodash';
import gql from 'graphql-tag';
import { recordType as getRecordType } from '../../../shared/schemas';
import { ApolloClient } from 'apollo-client';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { expectedErrorReceived } from 'client/actions/error';
import { connect } from 'react-redux';

const DEACTIVATE_MUTATION = gql`
  mutation DeactivateRecords($type: RecordType!, $ids: [Int]!) {
    deactivatedIds: deactivate(type: $type, ids: $ids)
  }
`;

export type DeactivateRecordFunction = (ids: number | number[]) => Promise<number[]>;
export interface DeactivateContainerProps { performDeactivation: DeactivateRecordFunction }
export interface OwnProps { refetchTable: () => void, client: ApolloClient<NormalizedCacheObject>, maxRecordsToDeactivate?: number }
export interface GraphQlProps { deactivatedIds: number[] }
export interface DeactivationMutationVariables { ids: number[], type: string }
interface DispatchProps { displayMessage(args: { message: string }): void }
const mapDispatchToProps = { displayMessage: expectedErrorReceived };
export const withRecordDeactivations = (table: string, maxRecordsToDeactivate?: number) => {
  const recordType = getRecordType(table);
  const withDeactivations = msyncMutation<GraphQlProps, OwnProps & DispatchProps, DeactivateContainerProps, DeactivationMutationVariables>(DEACTIVATE_MUTATION, {
    alias: 'withRecordDeactivations',
    props: props => ({
      performDeactivation: async ids => {
        let deactivatedIds: number[] = [];
        if (!_.isNil(maxRecordsToDeactivate) && Array.isArray(ids)) {
          if (ids.length > maxRecordsToDeactivate) {
            props.ownProps.displayMessage({ message: `Deactivating too many records. This may be harmful to m-sync. Please narrow your selection before deactivating records.` });
            return [];
          }
        }
        try {
          const result = await props.mutate({
            variables: { ids: flatten([ids]), type: recordType },
            refetchQueries: ['GetRecordFilterOptions'],
          });

          if (result && result.data)
            deactivatedIds = result.data.deactivatedIds;

        } catch (error) {
          // Error should have been handled by the global error handler, so just log it
          console.info('Problem deactivating records', error.message);
          return [];
        }

        await props.ownProps.refetchTable();
        return deactivatedIds;
      },
    }),
  });

  return _.flowRight(
    connect(undefined, mapDispatchToProps),
    withDeactivations,
  );
};
