import * as _ from 'lodash';
import * as Types from './types';
import { createSelector } from 'reselect';
import { CellData } from '../table-body/cell';
import { ColumnDef } from '../../types';
import * as Constants from '../../constants';

export const getColumnCount = (props: Types.FooterProps) => {
  return props.columns.length;
};

const getColumns = (props: Types.FooterProps) => {
  return props.columns;
};

export const getFooterWidth = (props: Types.FooterProps) => {
  return props.footerWidth;
};

const getGrowableColumnHeader = (props: Types.FooterProps) => props.growableColumnHeader;

const getTotalColumnWidth = createSelector([
  getColumns,
], columns => _.sumBy(columns, 'width'));

export const getHasVerticalScrollbar = (props: Types.FooterProps) => {
  return true; // TBD until we can find a better solution sometime  props.hasVerticalScrollbar;
};

export const getTwinHasVerticalScrollbar = (props: Types.FooterProps) => {
  return props.twinHasVerticalScrollbar;
};

export const getColumnWidthFunction = createSelector([
  getColumns,
  getGrowableColumnHeader,
  getFooterWidth,
  getTotalColumnWidth,
  getHasVerticalScrollbar,
  getTwinHasVerticalScrollbar,
], (columns, growableColumnHeader, footerWidth, totalColumnWidth, hasVerticalScrollbar) => index => {
  const isLastColumn = columns.length === index + 1;

  const scrollbarWidthAdjustment = hasVerticalScrollbar
    ? Constants.SCROLLBAR_WIDTH
    : 0;

  return growableColumnHeader === columns[index].header && footerWidth > totalColumnWidth
    ? columns[index].width + (_.max([footerWidth - totalColumnWidth - scrollbarWidthAdjustment - 2, 0]) ?? 0)
    : isLastColumn
      ? columns[index].width + scrollbarWidthAdjustment
      : columns[index].width;
});

export const getRowHeight = (props: Types.FooterProps) => {
  return props.rowHeight;
};

export const getRowHeightFunction = createSelector([
  getRowHeight,
], rowHeight => () => rowHeight);

const getTableName = (props: Types.FooterProps) => props.tableName;

// ------------ BORROWED FROM table-body selectors ----------------

const getRows = (props: Types.FooterProps) => props.rows;

export const getCellValues = (rows: any[], columns: ColumnDef[]): CellData['values'] => {
  return rows.map(row => {
    return columns.map(column => {
      return row[column.accessor];
    });
  });
};

export const getBodyCellFlags = (rows: any[], columns: ColumnDef[]): CellData['values'] => {
  return rows.map(row => {
    return columns.map(column => {
      return row[`${column.accessor}~flag`];
    });
  });
};

export const getFooterCellFlags = (rows: any[], columns: ColumnDef[]): CellData['values'] => {
  return rows.map(row => {
    return columns.map(column => {
      return row[`${column.accessor}~footer-flag`];
    });
  });
};

export const getItemDataForFooterCell = createSelector([
  getRows,
  getColumns,
  getTableName,
], (rows, columns, tableName) => {
  return {
    columns,
    values: getCellValues(rows, columns),
    flags: getBodyCellFlags(rows, columns),
    footerFlags: getFooterCellFlags(rows, columns),
    footerData: columns.map(c => c.footer),
    tableName,
    headers: columns.map(c => c.header),
  };
});
