import * as _ from 'lodash';
import * as React from 'react';
import * as classnames from 'classnames';
import { Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import { createSelector } from 'reselect';

export interface ComponentProps {
  isShown: boolean;
  handleCancelButtonClicked(): void;
  handleSendButtonClicked(routingLoadIds: RoutingLoadId[], changedLoadNumbers: RoutingLoadId[]): void;
  handleChangesCheckboxClicked(routingLoadId: RoutingLoadId, checked: boolean): void;
  routingLoads: Array<{
    id: RoutingLoadId;
    identifier: string;
    sentToPeak: boolean;
  }>;
  loading: boolean;
  checkedLoadNumbers: {
    [routingLoadId: number]: boolean;
  };
  sendButtonDisabled: boolean;
  notifyPeakInfoMessageEnabled: boolean;
}

const getRoutingLoads = (props: ComponentProps) => props.routingLoads || [];
const getCheckedLoadNumbers = (props: ComponentProps) => props.checkedLoadNumbers;
const getHandleChangesCheckboxClicked = (props: ComponentProps) => props.handleChangesCheckboxClicked;
const buildRoutingLoadModalRows = (
  routingLoads: ComponentProps['routingLoads'],
  checkedLoadNumbers: ComponentProps['checkedLoadNumbers'],
  handleChangesCheckboxClicked: ComponentProps['handleChangesCheckboxClicked']) => (
    <div>
      {routingLoads.map(routingLoad => {

        const rowClassnames = classnames('notify-peak-modal-load', {
          'already-sent': routingLoad.sentToPeak,
        });

        return (
          <div key={routingLoad.identifier} className={rowClassnames} data-testid={`notify-peak-modal-load-${routingLoad.identifier}`}>
            <div className="notify-peak-modal-load-number">{routingLoad.identifier}</div>
            <div className="notify-peak-modal-changes">
              <SimpleCheckbox
                testid={`notify-peak-modal-changes-checkbox-${routingLoad.identifier}`}
                value={checkedLoadNumbers[routingLoad.id]}
                onChange={checked => handleChangesCheckboxClicked(routingLoad.id, checked)}
                disabled={routingLoad.sentToPeak}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
// Memoize routingLoads so that unnecessary re-renders are avoided.
const routingLoadsSelector = createSelector([getRoutingLoads, getCheckedLoadNumbers, getHandleChangesCheckboxClicked], buildRoutingLoadModalRows);

export class NotifyPeakModalUI extends React.PureComponent<ComponentProps, {}> {
  handleSendButtonClicked = () => {
    const changedRoutingLoadIds: RoutingLoadId[] = _.compact(_.values(_.mapValues(this.props.checkedLoadNumbers, (changed, routingLoadId) => {
      return changed ? Number.parseInt(routingLoadId || '') : undefined;
    })));

    // We don't want to include routing loads that have already been sent to PEAK
    // DK 2017-07-05 it would be great to test this somehow but I can't think of a good way right now...
    const routingLoadIds = this.props.routingLoads.filter(routingLoad => !routingLoad.sentToPeak).map(routingLoad => routingLoad.id);
    this.props.handleSendButtonClicked(routingLoadIds, changedRoutingLoadIds);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="notify-peak-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="notify-peak-modal-container"
        className="notify-peak-modal">
        <GlobalAsyncModalHeader>Notify Peak OK to Invoice</GlobalAsyncModalHeader>
        <Modal.Body>
          {this.props.loading && <ProgressSpinner label="Loading..." />}

          {!this.props.loading &&
            <div>
              <div className="notify-peak-modal-load notify-peak-modal-header">
                <div className="notify-peak-modal-load-number-header">Load ID</div>
                <div className="notify-peak-modal-changes-header">Changes</div>
              </div>

              {routingLoadsSelector(this.props)}
              {this.props.notifyPeakInfoMessageEnabled &&
                <div className="already-sent-message">* Previously sent - will not be sent again</div>
              }
            </div>
          }

        </Modal.Body>
        <Modal.Footer>

          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="notify-peak-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Send"
            testid="notify-peak-modal-send-button"
            disabled={this.props.sendButtonDisabled}
            onClick={this.handleSendButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
