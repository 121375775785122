import * as React from 'react';
import { RecordNav } from 'client/components/record-nav';
import navBuilder from '../nav-items';
import { Col } from 'client/components/third-party';
import { RelatedCustomerOrdersContainer } from './related-customer-orders-table';
import { SUPPLIER_ORDER_RELATED_CUSTOMER_ORDERS_TABLE_NAME } from 'client/constants';

export interface UIProps {
  record: { id: number };
}

export const RelatedCustomerOrdersForm = (props: UIProps) => {
  return (
    <div>
      <RecordNav RecordNavItems={props.record && navBuilder(props.record.id)} disabled={false} />
      <Col sm={12} className="supplier-order-sub-tab-page">
          <div data-testid="related-customer-orders">
            <RelatedCustomerOrdersContainer
              tableName={SUPPLIER_ORDER_RELATED_CUSTOMER_ORDERS_TABLE_NAME}
              supplierOrderId={props.record.id}
            />
          </div>
        </Col>
    </div>
  );
};
