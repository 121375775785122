import * as _ from 'lodash';
import * as BS from 'client/components/third-party';
import * as Constants from './constants';
import * as DateRangeYearComparison from 'client/components/date-range-year-comparison';
import * as Form from 'client/components/form';
import * as Mutations from 'client/actions/mutations';
import * as OrderComponents from 'client/app/orders/reports/shared/components';
import * as React from 'react';
import * as RecordBar from 'client/components/record-bar/record-bar-back-back-button-and-title';
import * as ReduxForm from 'redux-form';
import * as ReportUserParamTypes from 'client/components/report-user-params/types';
import * as PerformanceReportHelpers from 'shared/app/reports/performance';
import * as SelectableTypes from 'client/components/selectable/types';
import * as SharedTypes from 'shared/types';
import * as SimpleCheckbox from 'client/components/simple-components/simple-checkbox';
import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { PerformanceReportDateColumnBehavior, PerformanceReportStoreDetailLevel, PerformanceReportDateDetailLevel, PerformanceReportProductGroupingBehavior, SelectableValue, classify } from 'shared/types';
import { DropDownOptions } from 'client/types';

export interface ComponentProps {
  customerId: number | undefined;
  customerOptions: DropDownOptions;
  customersLoading: boolean;
  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
  dateColumnBehavior: PerformanceReportDateColumnBehavior | undefined;
  downloadButtonDisabled: boolean;
  handleCustomerChanged: (customerId: number) => void;
  handleDateRangesChanged(state: DateRangeYearComparisonValue): void;
  handleDownloadExcelReportClicked(): void;
  handleMarketsChanged: (ids: number[]) => void;
  handleMeasureCheckboxClicked: (categoryName: string, measure: string, isOn: boolean) => void;
  handleMfcAreasChanged: (ids: number[]) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (ids: number[]) => void;
  handleProductSubClassesFilterChanged: (ids: number[]) => void;
  handleRegionsChanged: (ids: number[]) => void;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => void;
  handleDateColumnBehaviorChanged: (dateColumnBehavior: PerformanceReportDateColumnBehavior) => void;
  handleDateDetailLevelChanged: (dateDetailLevel: PerformanceReportDateDetailLevel) => void;
  lastYearDateComparisonDisabled: boolean;
  mapFromReportUserParams: ReportUserParamTypes.MapFromReportUserParams;
  mapToReportUserParams: ReportUserParamTypes.MapToReportUserParams;
  marketIds: SelectableValue | undefined;
  marketOptions?: SelectableTypes.SelectableRow[];
  marketsLoading?: boolean;
  measures: SimpleObject;
  mfcAreaIds: SelectableValue | undefined;
  mfcAreaOptions?: SelectableTypes.SelectableRow[];
  mfcAreasLoading?: boolean;
  options: SimpleObject;
  pristine: boolean;
  productClassesLoading?: boolean;
  productClassIds: SelectableValue | undefined;
  productClassOptions?: SelectableTypes.SelectableRow[];
  productIds: SelectableValue | undefined;
  productOptions?: SelectableTypes.SelectableRow[];
  productsLoading?: boolean;
  productSubClassesLoading?: boolean;
  productSubClassIds: SelectableValue | undefined;
  productSubClassOptions?: SelectableTypes.SelectableRow[];
  regionIds: SelectableValue | undefined;
  regionOptions?: SelectableTypes.SelectableRow[];
  regionsLoading?: boolean;
  reportDownloadStatus: Mutations.MutationStatus;
  scanProductOptions: boolean[] | undefined;
  sellDepartmentId: number | undefined;
  sellDepartmentOptions: DropDownOptions;
  sellDepartmentsLoading?: boolean;
  sortByOptions: DropDownOptions;
  storeIds: SelectableValue | undefined;
  storeOptions?: SelectableTypes.SelectableRow[];
  storesLoading?: boolean;
  storeDetailLevel: PerformanceReportStoreDetailLevel;
  dateDetailLevel: PerformanceReportDateDetailLevel;
  productGroupingBehavior: PerformanceReportProductGroupingBehavior;
  showDownloadDisabledReason: boolean;
  dateColumnBehaviorDropdownDisabled: boolean;
  dateDetailLevelDropdownDisabled: boolean;
  numberOfExpectedGeneratedRows: number;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class PerformanceReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar.RecordBar title="Customer Performance Report" hideBackButton/>
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-hmg-ops-table-wrapper mfc-scrolling-region-adjacent-to-sidebar">
            <BS.Col sm={6}>
              <BS.Form horizontal>
                <BS.Row className="report-fields-outside-tabs">
                  <BS.FormGroup>
                    <OrderComponents.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                      handleChange={this.props.handleCustomerChanged}
                    />
                  </BS.FormGroup>
                </BS.Row>

                <BS.Row className="report-fields-outside-tabs">
                  <BS.FormGroup>
                  <DateRangeYearComparison.DateRangeYearComparison
                    handleChange={this.props.handleDateRangesChanged}
                    testid="date-range-comparison-component"
                    value={this.props.dateRangeYearComparison}
                    lastYearComparisonDisabled={this.props.lastYearDateComparisonDisabled}
                  />
                  </BS.FormGroup>
                </BS.Row>

                <BS.Tabs className="report-tabs" id="product-tabs">
                  <BS.Tab disabled={true} title="Products">
                    <BS.FormGroup>
                      <OrderComponents.SellDepartment
                        name={Constants.FormFields.sellDepartmentId}
                        options={this.props.sellDepartmentOptions}
                        handleChange={this.props.handleSellDepartmentChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup className="report-product-order-type-area">
                      <label className="report-scan-or-po-based-label">Product Order Type</label>
                      <div>
                        <OrderComponents.ScanBased
                          name={Constants.FormFields.scanBased}
                          handleChange={this.props.handleScanBasedFilterChanged}
                        />
                        <OrderComponents.PoBased
                          name={Constants.FormFields.poBased}
                          handleChange={this.props.handlePOBasedFilterChanged}
                        />
                      </div>
                    </BS.FormGroup>

                    <BS.FormGroup>
                      <OrderComponents.ProductClass
                        name={Constants.FormFields.productClassIds}
                        options={this.props.productClassOptions}
                        loading={this.props.productClassesLoading}
                        handleChange={this.props.handleProductClassesFilterChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.ProductSubClass
                        name={Constants.FormFields.productSubClassIds}
                        options={this.props.productSubClassOptions}
                        loading={this.props.productSubClassesLoading}
                        handleChange={this.props.handleProductSubClassesFilterChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.Product
                        name={Constants.FormFields.productIds}
                        options={this.props.productOptions}
                        loading={this.props.productsLoading}
                      />
                    </BS.FormGroup>
                  </BS.Tab>
                </BS.Tabs>

                <BS.Tabs className="report-tabs" id="store-tabs">
                  <BS.Tab disabled={true} title="Stores">
                  <BS.FormGroup>
                      <OrderComponents.PrimaryGlobalAllField
                        name={Constants.FormFields.primaryGlobalAll}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.MfcArea
                        name={Constants.FormFields.mfcAreaIds}
                        options={this.props.mfcAreaOptions}
                        loading={this.props.mfcAreasLoading}
                        handleChange={this.props.handleMfcAreasChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.Region
                        name={Constants.FormFields.regionIds}
                        options={this.props.regionOptions}
                        loading={this.props.regionsLoading}
                        handleChange={this.props.handleRegionsChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.Market
                        name={Constants.FormFields.marketIds}
                        options={this.props.marketOptions}
                        loading={this.props.marketsLoading}
                        handleChange={this.props.handleMarketsChanged}
                      />
                    </BS.FormGroup>
                    <BS.FormGroup>
                      <OrderComponents.Store
                        name={Constants.FormFields.storeIds}
                        options={this.props.storeOptions}
                        loading={this.props.storesLoading}
                      />
                    </BS.FormGroup>
                  </BS.Tab>
                </BS.Tabs>
              </BS.Form>
            </BS.Col>
            <BS.Col sm={6}>
              {PerformanceReportHelpers.listCategories().map(category => {
                const [ty, ly] = ['product', 'store'].includes(category.categoryName)
                  ? _.chunk(category.measures, Math.ceil(category.measures.length / 2.0))
                  : classify(category.measures, x => x.label.endsWith('LY') || x.label.includes('Comp') ? 1 : 0, 2);
                return (
                  <div key={category.categoryName}>
                    <div className="performance-measure-category-title">{category.label}</div>
                    {ly?.length + ty?.length > 0 && (
                      <div className="performance-measure-checkbox-container-outer">
                        <div className="performance-measure-checkbox-container-inner">
                          {(ty ?? []).map(measure => {
                            const compareToPreviousYear = !!this.props.dateRangeYearComparison && this.props.dateRangeYearComparison.compareToPreviousYear;
                            const measureDisabled = measure.disabledIf?.({ compareToPreviousYear, storeDetailLevel: this.props.storeDetailLevel, dateDetailLevel: this.props.dateDetailLevel }) ?? false;
                            return (
                              <div key={measure.fieldName} className="performance-measure-checkbox-container">
                                <SimpleCheckbox.SimpleCheckbox
                                  testid={measure.fieldName}
                                  value={this.props.measures[measure.fieldName]}
                                  onChange={(isOn: boolean) => this.props.handleMeasureCheckboxClicked(category.categoryName, measure.fieldName, isOn)}
                                  label={measure.label}
                                  disabled={measureDisabled}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="performance-measure-checkbox-container-inner">
                          {(ly ?? []).map(measure => {
                            const compareToPreviousYear = !!this.props.dateRangeYearComparison && this.props.dateRangeYearComparison.compareToPreviousYear;
                            const measureDisabled = measure.disabledIf?.({ compareToPreviousYear, storeDetailLevel: this.props.storeDetailLevel, dateDetailLevel: this.props.dateDetailLevel }) ?? false;
                            return (
                              <div key={measure.fieldName} className="performance-measure-checkbox-container">
                                <SimpleCheckbox.SimpleCheckbox
                                  testid={measure.fieldName}
                                  value={this.props.measures[measure.fieldName]}
                                  onChange={(isOn: boolean) => this.props.handleMeasureCheckboxClicked(category.categoryName, measure.fieldName, isOn)}
                                  label={measure.label}
                                  disabled={measureDisabled}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {category.options?.length > 0 && (
                      <div className="performance-measure-checkbox-container-inner-options">
                        {(category.options || []).map(option => {
                          const fieldName = _.camelCase(`${category.categoryName}-${option.fieldName}`);
                          return (
                            <div key={option.fieldName} className="performance-measure-checkbox-container">
                              <SimpleCheckbox.SimpleCheckbox
                                testid={fieldName}
                                value={this.props.options[fieldName]}
                                onChange={(isOn: boolean) => this.props.handleMeasureCheckboxClicked(category.categoryName, option.fieldName, isOn)}
                                label={option.label}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </BS.Col>
          </div>
          <OrderComponents.Sidebar
            reportType={SharedTypes.ReportTypes.PerformanceReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            // downloadButtonDisabledMessage={`Possible Number of Rows: ${this.props.numberOfExpectedGeneratedRows.toLocaleString()} (Limit ${PerformanceReportHelpers.MAX_NUMBER_OF_GENERATED_PERFORMANCE_REPORT_ROWS.toLocaleString()})`}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          >
            <div className="mfc-form-stacked-label report-sidebar-heading">Report Options</div>

            <BS.FormGroup>
              <div className="report-sidebar-field">
                <ReduxForm.Field
                  component={Form.LabeledSelect}
                  horizontalLabel={false}
                  inputColSize={11}
                  label="Date Column Behavior"
                  labelColSize={11}
                  name={Constants.FormFields.dateColumnBehavior}
                  offset={1}
                  options={[
                    { id: PerformanceReportDateColumnBehavior.ThisYearLastYearDateRanges, displayValue: 'Date Range' },
                    { id: PerformanceReportDateColumnBehavior.CalendarYearDateRange, displayValue: 'Entire Year' },
                  ]}
                  required
                  handleChange={this.props.handleDateColumnBehaviorChanged}
                  testid="report-date-column-behavior"
                  textFormatter={value => value.displayValue}
                  valueField="value"
                  disabled={this.props.dateColumnBehaviorDropdownDisabled}
                />
              </div>
            </BS.FormGroup>
            <BS.FormGroup>
              <div className="report-sidebar-field">
                <ReduxForm.Field
                  component={Form.LabeledSelect}
                  horizontalLabel={false}
                  inputColSize={11}
                  label="Date Detail Level"
                  labelColSize={11}
                  name={Constants.FormFields.dateDetailLevel}
                  offset={1}
                  options={[
                    { id: PerformanceReportDateDetailLevel.Total },
                    { id: PerformanceReportDateDetailLevel.Week },
                    { id: PerformanceReportDateDetailLevel.Day },
                  ]}
                  required
                  handleChange={this.props.handleDateDetailLevelChanged}
                  testid="report-date-detail-level"
                  textFormatter={value => value.id}
                  valueField="value"
                  disabled={this.props.dateDetailLevelDropdownDisabled}
                />
              </div>
            </BS.FormGroup>
            <BS.FormGroup>
              <div className="report-sidebar-field">
                <ReduxForm.Field
                  component={Form.LabeledSelect}
                  horizontalLabel={false}
                  inputColSize={11}
                  label="Store Detail Level"
                  labelColSize={11}
                  name={Constants.FormFields.storeDetailLevel}
                  offset={1}
                  options={[
                    { id: PerformanceReportStoreDetailLevel.Total },
                    { id: PerformanceReportStoreDetailLevel.State },
                    { id: PerformanceReportStoreDetailLevel.Store },
                  ]}
                  required
                  testid="report-store-detail-level"
                  textFormatter={value => value.id}
                  valueField="value"
                />
              </div>
            </BS.FormGroup>
            <BS.FormGroup>
              <div className="report-sidebar-field">
                <ReduxForm.Field
                  component={Form.LabeledSelect}
                  horizontalLabel={false}
                  inputColSize={11}
                  label="Product Grouping Behavior"
                  labelColSize={11}
                  name={Constants.FormFields.productGroupingBehavior}
                  offset={1}
                  options={[
                    { id: PerformanceReportProductGroupingBehavior.RollUpToParent, displayValue: 'Roll up to Parent' },
                    { id: PerformanceReportProductGroupingBehavior.ShowChildDetails, displayValue: 'Show Child Details' },
                    { id: PerformanceReportProductGroupingBehavior.ShowUpcDetails, displayValue: 'Show UPC Details' },
                  ]}
                  required
                  testid="report-product-grouping-behavior"
                  textFormatter={value => value.displayValue}
                  valueField="value"
                />
              </div>
            </BS.FormGroup>
          </OrderComponents.Sidebar>
        </div>
      </div>
    );
  }
}
