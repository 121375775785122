import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Col, Form, FormGroup } from 'client/components/third-party';
import { change, getFormValues } from 'redux-form';
import { SpecializeField } from 'client/components/form';
import { ProductToss } from 'shared/schemas/product-toss';
import { optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { CustomerId } from 'shared/schemas/customer';

const Field = SpecializeField(ProductToss, { alwaysShowErrors: true, hideOptionalLabel: true });

export interface OwnProps {
  handleSubmit: any;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  formName: string;
  record: Partial<ProductToss>;
}

interface WithOptionsProps {
  customers: any[];
  customersLoading?: boolean;
  stores: any[];
  storesLoading?: boolean;
  products: any[];
  productsLoading?: boolean;
}

interface StateProps {
  selectedCustomerId: CustomerId;
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  const { formName } = ownProps;
  const values = getFormValues(formName)(state) as any;
  return {
    selectedCustomerId: values?.customerId || null,
  };
};

interface DispatchProps {
  onCustomerChange: (customerId: CustomerId, formName: string) => void;
  onProductChange: (productId: number, sku: string | undefined, formName: string) => void;
}

const mapDispatchToProps = {
  onCustomerChange: (customerId: CustomerId, formName: string) => {
    return dispatch => {
      dispatch(change(formName, 'productId', null));
      dispatch(change(formName, 'storeId', null));
      dispatch(change(formName, 'customerId', customerId));
    };
  },
  onProductChange: (productId: number, sku: string | undefined, formName: string) => {
    return dispatch => {
      dispatch(change(formName, 'productId', productId));
      dispatch(change(formName, 'sku', sku));
    };
  },
};

type ComponentProps = OwnProps & WithOptionsProps & DispatchProps & StateProps;

class Component extends React.Component<ComponentProps, undefined> {
  constructor(props) {
    super(props);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
  }

  handleCustomerChange(customerId: number) {
    if (this.props) {
      this.props.onCustomerChange(customerId, this.props.formName);
    }
  }

  handleProductChange(productId: number) {
    if (this.props) {
      const selectedProduct = this.props.products.find(product => product.id === productId);
      const sku = selectedProduct ? selectedProduct.sku : undefined;
      this.props.onProductChange(productId, sku, this.props.formName);
    }
  }

  render() {
    const {
      customers,
      stores,
      products,
      record,
    } = this.props;
    return (
      <Col sm={9}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form" data-testid="product-toss-id">
            <FormGroup>
              <Field
                name="customer"
                labelColSize={2}
                inputColSize={5}
                options={customers}
                loading={this.props.customersLoading}
                handleChange={this.handleCustomerChange}
                autoFocus={!record || !record.id}
              />
            </FormGroup>
            <FormGroup>
              <Field name="store" labelColSize={2} inputColSize={5} options={stores} loading={this.props.storesLoading} />
            </FormGroup>
            <FormGroup>
              <Field name="product" labelColSize={2} inputColSize={5} options={products} loading={this.props.productsLoading} handleChange={this.handleProductChange} />
            </FormGroup>
            <FormGroup>
              <Field name="weekEndingDate" labelColSize={2} inputColSize={2} horizontalLabel />
            </FormGroup>
            <FormGroup>
              <Field name="quantity" labelColSize={2} inputColSize={2} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }
}

const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
const withStores = optionsContainerWithFilters({
  table: 'stores',
  columns: ['identifier', 'city', 'state'],
  getFilters: props => {
    return [
      { field: 'customer', values: [props.selectedCustomerId] },
    ];
  },
});
const withProducts = optionsContainerWithFilters({
  table: 'products',
  columns: ['identifier', 'description'],
  getFilters: props => {
    return [
      { field: 'customer', values: [props.selectedCustomerId] },
    ];
  },
});

export default _.flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withCustomers,
  withProducts,
  withStores,
)(Component as shame);
