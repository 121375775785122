import { Saved } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/product-worksheet';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { SelectRecentComboModalUI } from './select-recent-combo-modal';
import { GetRecentCombosResponse, GetRecentCombosInput, Combo, ComboDetail } from 'schema/product-worksheet/types';
import { GetRecentCombosQuery } from './query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { FetchPolicy } from 'apollo-client';
import { OrderMethod, ComboCart } from 'shared/types';

export interface OwnProps {
  customerOrder: Pick<Saved<CustomerOrder>, 'orderMethod' | 'shippingUnitType'| 'sellDepartment' | 'customerId'>;
  comboCartList: ComboCart[];
  parentFormName: string;
}

interface StateProps {
  isShown: boolean;
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    isShown: state.productWorksheet.selectRecentComboModalShown,
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  recentComboSelected: (formName: string, orderMethod: OrderMethod, comboDetails: ComboDetail[], comboCartList: ComboCart[]) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.cancelSelectRecentComboButtonClicked,
  recentComboSelected: Actions.recentComboSelected,
};

interface QueryProps {
  combos: Combo[] | undefined;
  loading: boolean;
}

const recentComboDetails = msyncQuery<{ response: GetRecentCombosResponse }, OwnProps & StateProps, QueryProps, { input: GetRecentCombosInput }>(
  GetRecentCombosQuery, {
    skip(ownProps) {
      return !ownProps.isShown;
    },
    options(ownProps): { variables, fetchPolicy: FetchPolicy } {
      return {
        variables: {
          input: {
            customerId: ownProps.customerOrder.customerId,
            orderMethod: ownProps.customerOrder.orderMethod,
            sellDepartmentId: ownProps.customerOrder.sellDepartment.id,
          },
        },
        fetchPolicy: 'network-only',
      };
    },
    props(props): QueryProps {
      const { data } = props;
      const combos = data.response ? data.response.combos : undefined;
      return {
        combos,
        loading: data.loading,
      };
    },
  },
);

export type ComponentProps =
  OwnProps &
  StateProps &
  QueryProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  recentComboDetails,
)(SelectRecentComboModalUI as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
