import * as React from 'react';
import { Modal, Form, Row, FormGroup } from 'client/components/third-party';
import { Field } from 'redux-form';
import { LabeledDate } from 'client/components/form';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { makeRequiredFormValidator, makeFormValidator, MINIMUM_DATE } from 'shared/validators';
import * as Constants from './constants';
import { Clock } from 'shared/clock';
import * as _ from 'lodash';
import { addDays } from 'shared/helpers';

export interface Props {
  onClose: () => void;

  // Redux Form
  handleSubmit: (formValues: any) => any;
  reset: () => void;

  saveButtonDisabled: boolean;
  supplierCommitmentId: number;
  updateSupplierItemCosts(supplierCommitmentId: number);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FormValues {
}

export function UpdateSupplierItemCostsModal(props: Props) {

  const today = Clock.today();

  const costEffectiveDateValidators = React.useMemo(() => [
    makeRequiredFormValidator(),
    makeFormValidator(MINIMUM_DATE(today)),
  ], [today]);

  const disabledDates = React.useMemo(() => {
    // Kind of hacky, but bootstrap-datepicker doesn't allow you to specify a range of disabled dates
    // It's not feasible to prop down every possible date before today, so just make it look like every date before today is disabled
    // We have a validation to prevent actually entering an invalid date
    const yesterday = addDays(today, -1);
    return _.times(1000, n => addDays(yesterday, -n));
  }, [today]);

  function closeAndReset(): void {
    props.onClose();
    props.reset();
  }

  async function onSubmit(formValues: FormValues): Promise<void> {
    await props.updateSupplierItemCosts(props.supplierCommitmentId);
    closeAndReset();
  }

  return (
    <Modal
      key="update-supplier-item-costs-modal"
      id="update-supplier-item-costs-modal"
      animation
      backdrop="static"
      show={true}
      onHide={closeAndReset}>
      <Form horizontal onSubmit={props.handleSubmit(onSubmit)} data-testid="update-supplier-item-costs-modal">
        <GlobalAsyncModalHeader>Update Supplier Item Costs</GlobalAsyncModalHeader>
        <Modal.Body>
          <Row>
            <FormGroup className="update-supplier-item-costs-modal-form">
              <Field
                name={Constants.costEffectiveDate}
                inputColSize={10}
                options={[]}
                horizontalLabel={false}
                label="Cost Effective Date"
                component={LabeledDate}
                testid="cost-effective-date"
                touchOnBlur={true}
                touchOnChange={true}
                disabledDates={disabledDates}
                validate={costEffectiveDateValidators}
                required>
              </Field>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={closeAndReset} />
          <GlobalSaveButton
            label={'Update'}
            testid={'modal-submit'}
            type="submit"
            disabled={props.saveButtonDisabled}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
