import * as GraphQL from 'client/hoc/graphql/mutation';
import * as Mutation from './mutation';
import * as ReduxTypes from 'client/types/redux-types';
import * as SendEmailModalTypes from 'client/components/send-email-modal/types';

export type ActionTypes =
  | EmailInvoiceModalCloseClickedAction
  | EmailInvoiceCompletedAction
  ;

export enum ActionTypeKeys {
  EmailInvoiceModalCloseClicked = 'App/EMAIL_INVOICE_MODAL_CLOSE_CLICKED',
  EmailInvoiceCompleted = 'App/EMAIL_INVOICE_COMPLETED',
}

export interface EmailInvoiceModalCloseClickedAction extends ReduxTypes.Action {
  type: ActionTypeKeys.EmailInvoiceModalCloseClicked;
}

export const onEmailInvoiceModalCloseClicked = (): EmailInvoiceModalCloseClickedAction => {
  return {
    type: ActionTypeKeys.EmailInvoiceModalCloseClicked,
  };
};

export interface EmailInvoiceCompletedAction extends ReduxTypes.Action {
  type: ActionTypeKeys.EmailInvoiceCompleted;
}

export const onSendButtonClicked = (invoiceId: number, recipients: SendEmailModalTypes.EmailRecipient[]): ReduxTypes.Thunker => {
  return async dispatch => {
    await GraphQL.msyncClientMutation<Mutation.SendEmailResponse, Mutation.SendEmailInput>({
      mutation: Mutation.Mutation,
      variables: {
        invoiceId,
        emails: recipients.map(recipient => recipient.emailAddress),
      },
      dispatch,

    });

    dispatch(onEmailInvoiceModalCloseClicked());
  };
};
