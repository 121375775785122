import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import { SalesPlanVarietyStatus } from 'shared/types';
import Dropdown from 'client/components/dropdown';
import * as _ from 'lodash';

export interface UIProps {
  salesPlanId?: number;
  salesPlanHeader?: string;
  onBackButtonClicked?: () => void;
  onSaveButtonClicked(): void;
  saveButtonDisabled: boolean;
  varietyStatus: SalesPlanVarietyStatus;
}

export class RecordBarUI extends React.PureComponent<UIProps, {}> {
  render() {
    const varietyStatus = _.startCase(this.props.varietyStatus);

    const recordBarStatus = (
      <div className="detail">
        <div className="heading"></div>
        {this.props.salesPlanId &&
          <Dropdown
            className="mfc-dropdown btn-group-record-bar-button"
            data={[varietyStatus]}
            value={[varietyStatus]}
            placeholder={varietyStatus}
            updateLabel
            block
            disabled
          />
        }
      </div>
    );

    return (
      <RecordBar
        onBackButtonClicked={this.props.onBackButtonClicked}
        onSaveButtonClicked={this.props.onSaveButtonClicked}
        saveButtonDisabled={this.props.saveButtonDisabled}
        status={recordBarStatus}
      >
        <RecordBarDetail title="Sales Plan ID" testid="record-bar-sales-plan-identifier">
          {this.props.salesPlanHeader || 'New Sales Plan'}
        </RecordBarDetail>
      </RecordBar>
    );
  }
}
