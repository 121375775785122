import { buildAdminDetailPage } from '../admin/admin-detail-page';
import FormComponent from '../../components/crud-forms/customer/customer-form';

export default buildAdminDetailPage({
  table: 'Customer',
  formName: 'customerDetailForm',
  FormComponent,
  descriptionResolver: record => {
    if (record.identifier && record.name) {
      return `${record.identifier} - ${record.name}`;
    } else if (record.identifier) {
      return record.identifier;
    } else if (record.name) {
      return record.name;
    }

    return '';
  },
});
