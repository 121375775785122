import * as React from 'react';
import * as _ from 'lodash';
import { Field as ReduxFormField } from 'redux-form';
import { singular } from 'pluralize';
import { columnInfo } from '../../../shared/schemas';
import { LabeledInput } from './labeled-input';
import LabeledRadio from './labeled-radio';
import LabeledDisplay from './labeled-display';
import { LabeledSelect } from './labeled-select';
import LabeledCheckbox from './labeled-checkbox';
import { OrderStatus } from '../order-status';
import LabeledDate from './labeled-date';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import * as Validators from '../../../shared/validators';
import { DISPLAY_TYPES, INPUT_TYPES } from '../../../shared/types';
import * as dsl from 'shared/schemas/dsl';
import { makeFormValidator, FieldValidator, MAX_LENGTH, MAX_VALUE } from 'shared/validators';
import { Clock } from 'shared/clock';
import { SelectableRow, SelectableColumn } from '../selectable/types';
import { orThrowBug } from 'shared/helpers';
import { PropsOf, useDerivation } from 'client/lib/react';
import { IRecord } from 'shared/schemas/record';
import { nullIfBlank } from 'client/lib/meta';
import { getFormValues, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialValues } from 'client/state/form-selectors';
import { EMPTY_ARRAY } from 'client/constants';
import { Args } from 'shared/types';
import * as State from 'client/state/state';
// import { orLog } from 'shared/helpers';

const normalizeNumberField = (value: string | null, previousValue, allValues, previousAllValues) => {
  const result = Number.parseFloat(value || '');
  return (_.isFinite(result) && value === `${result}`) ? result : value;
};

export const FieldWrapper = (p: {
  // required: (well, unless comes from FormField)
  inputColSize?: number,
  name?: keyof any,
  table?: string,
  // optional:
  alwaysShowErrors?: boolean,
  autoFocus?: boolean,
  autoSetFirstOptionIfPossible?: boolean,
  clearable?: boolean,
  cols?: SelectableColumn[],
  creatable?: boolean,
  disabled?: boolean,
  fields?: string[],
  handleChange?: (value: any) => void,
  hideOptionalLabel?: boolean,
  horizontalLabel?: boolean,
  inputStyle?: boolean,
  isChecked?: boolean,
  label?: string,
  labelColSize?: number,
  loading?: boolean,
  mfcClassName?: string,
  value?: shame,
  normalize?: (value: shame, previousValue?: shame, allValues?: Array<shame | Nil>, previousAllValues?: Array<shame | Nil>) => string | Nil,
  offset?: number,
  options?: any[],
  optionValue?: any,
  placeholder?: string,
  required?: boolean,
  skipValidation?: boolean,
  tabIndex?: number,
  testid?: string,
  textFormatter?: (s: any) => string,
  type?: INPUT_TYPES,
  validators?: FieldValidator[],
  valueField?: string,
  component?: shame, // React.ComponentType<shame> | 'input' | 'select' | 'textarea' | ((p: any) => JSX.Element),
  min?: number, // for input type=number
  max?: number,
  money?: boolean, // I DUNNO
  multi?: boolean,
}) => {
  const [encodedTable, encodedColumn] = (p.name ?? '').split('.');
  const table = nullIfBlank(p.table ?? encodedTable);
  const lookupColumn = nullIfBlank(encodedColumn ?? p.name);
  const col = !table || !lookupColumn ? undefined : columnInfo(table, lookupColumn, 'maybe')
    ?? undefined;
    // ?? orLog(undefined, `columnInfo was undefined: ${JSON.stringify({lookupColumn, name: p.name, table: p.table}, null, 4)}`);
  const isRequired = !!col?.required || (p.required === true);
  const applicableValidators = p.skipValidation || p.disabled ? [] : [
    ...(p.validators ?? col?.validators ?? []),
    ...(isRequired ? [Validators.REQUIRED] : []),
  ];

  // If the "validate" prop changes on every render it will lead to an infinite
  // re-render and a stack-too-deep error. This means that the validators being passed
  // in have to be referentially equal from render to render. Disable the lint rule
  // since the contents of the validators array can't be statically checked.
  const validate = React.useMemo(() => applicableValidators.map(v => makeFormValidator(v as shame, {fieldName: p.name, knownTable: table, fullColumnName: `${col?.tableName}.${col?.name}`})), [p.skipValidation, p.disabled, p.validators?.length, col?.validators?.length, isRequired] ); // eslint-disable-line react-hooks/exhaustive-deps

  return <ReduxFormField {...({
    fullColumnName: `${col?.tableName}.${col?.name}`,
    ...p,
    validate,
    offset  : p.offset   ?? 0              ,
    disabled: p.disabled ?? false          ,
    label   : p.label    ?? col?.displayName,
    testid  : p.testid   ?? col?.id         ,
    required: isRequired,
    ...(p.component ?  {component: p.component} : ((displayType?: DISPLAY_TYPES) => {
      switch (_.values(DISPLAY_TYPES).includes(displayType as shame) ? displayType : DISPLAY_TYPES.INPUT) {
        case DISPLAY_TYPES.INPUT:
          const it = !_.values(INPUT_TYPES).includes(p.type ?? col?.formDisplayType.inputType as shame) ? INPUT_TYPES.TEXT : (p.type ?? col?.formDisplayType.inputType!);
          return {
            component: LabeledInput,
            type: it,
            step: col?.formDisplayType.step,
            ...([INPUT_TYPES.NUMBER, INPUT_TYPES.MONEY, INPUT_TYPES.PERCENTAGE].includes(it) ? { normalize: normalizeNumberField } : {}),
          };
        case DISPLAY_TYPES.DROPDOWN: return {
          component: LabeledSelect,
          valueField: p.valueField || col?.formDisplayType.valueField,
          options: p.options || col?.formDisplayType.options,
          textFormatter: p.textFormatter || col?.formDisplayType.displayValueResolver,
          placeholder: p.placeholder || `Select ${_.startCase(singular(col?.id ?? 'unknown'))}`,
          multi: col?.formDisplayType.multiselect,
          clearable: p.clearable ?? !isRequired,
        };
        case DISPLAY_TYPES.SELECTABLE: return { component: LabeledSelectable, isRequired, cols: p.cols || [], options:
          (p.options || col?.formDisplayType.options || []).map((option): SelectableRow => ({ id: option.id, cells: (p.fields || []).map(field => option[field]) })) };
        case DISPLAY_TYPES.RADIO: return { component: LabeledRadio };
        case DISPLAY_TYPES.CHECKBOX: return { component: LabeledCheckbox, type: 'checkbox', value: p.isChecked ?? col?.defaultValue ?? false };
        case DISPLAY_TYPES.YES_NO: return { component: LabeledCheckbox, type: 'checkbox', value: p.isChecked ?? col?.defaultValue ?? false };
        // [DISPLAY_TYPES.YES_NO_NULL]: return { component: LabeledCheckbox, type: 'checkbox', value: p.isChecked ?? col?.defaultValue ?? null },
        case DISPLAY_TYPES.DATE: return { component: LabeledDate };
        // [DISPLAY_TYPES.DATE_TIME]: return { component: LabeledDate },
        case DISPLAY_TYPES.STATUS: return { component: OrderStatus, type: col?.type, username: 'David McKay', lastUpdatedDate: Clock.today() }; // TODO: Replace username and lastUpdatedDate with real values (AP 3/13/17)
        case DISPLAY_TYPES.STATIC: return { component: LabeledDisplay, textFormatter: p.textFormatter || col?.formDisplayType.displayValueResolver };
        // [DISPLAY_TYPES.FIELD_ARRAY]:
        // [DISPLAY_TYPES.SELECTION]:
        // [DISPLAY_TYPES.MENU]:
        default: throw new Error(`Expected impossible due to coercing unknown values to DISPLAY_TYPES.INPUT...`);
        // default: return orLog({component: LabeledInput}, `No component mapping defined for field '${p.name}' (${col?.tableName}.${col?.name}): ${JSON.stringify(col?.formDisplayType)}.type not mapped`, 'error');
      }
    })(p.type ? DISPLAY_TYPES.INPUT : col?.formDisplayType.type)),
  }) as shame}/>;
};

/**
 * A helper function that accepts a shared schema record type cosntructor and returns a higher-order
 * component for declaratively specifying fields on redux-forms.  Using this helper will give your
 * IDE autocomplete hints when building out the form.  Optionally, you can provide an object literal
 * conforming to the FieldOptions interface to specify settings to apply to every field component
 * produced using the HOC that results from this function.
 * @example
 * import { Product } from 'shared/schemas/product';
 * import { SpecializeField } from 'client/components/form';
 * const Field = SpecializeField(Product, {horizontalLabel: true});
 * export function MyFormComponent(props: any) { return <Form><Field name="identifier", inputColSize={6}/></Form>; }
 * @export
 * @template T - type of the record displayed in the form.
 * @param {Constructor<T>} recordCtor - constructor function for one of the records.
 * @param {FieldOptions} [formWideOptions] - optional settings to apply to every field produced with the resulting HOC; individual fields can ovveride these with their own props.
 * @returns {FieldSpecialization<T>} - HOC that helps you define redux-forms with autocomplet.
 */
export const SpecializeField = <T extends IRecord>(
  recordConstructor: Constructor<T>,
  formWideOptions?: Partial<PropsOf<typeof FieldWrapper>>
) => function Field(p: Partial<PropsOf<typeof FieldWrapper>> & { name: keyof T, inputColSize: number }): JSX.Element {
    const { name, inputColSize, ...o } = p;
    const foreignData = dsl.getAssociation(recordConstructor, name);
    const formattedName = foreignData ? `${name}Id` : name; // TODO(8/23/17 AP): foreignData.idKey
    return <FieldWrapper
      table={dsl.getTable(recordConstructor) ?? orThrowBug(`No table name found for constructor ${recordConstructor}`)}
      name={formattedName}
      inputColSize={inputColSize}
      { ...Object.assign({}, formWideOptions, o) }/>;
  };

export interface FormFieldCalculationArgs { [k: string]: number }

type Validator = (value: any, record: any) => string | undefined;
const buildValidate = (props: {
  validate?: Validator | Validator[],
  maxLength?: number,
  maxValue?: number
}) => [
  ...(props.validate ? _.flatten([props.validate]) : EMPTY_ARRAY),
  ...(props.maxLength ? [makeFormValidator(MAX_LENGTH(props.maxLength))] : EMPTY_ARRAY),
  ...(props.maxValue ? [makeFormValidator(MAX_VALUE(props.maxValue))] : EMPTY_ARRAY),
].map((validate): FieldValidator => ({
  isValid: (value, record) => !validate(value, record),
  shortMessage: (value, record) => validate(value, record) ?? '',
  message: (label, value, record) => validate(value, record) ?? '',
}));

export const FormField = (p: PropsOf<typeof FieldWrapper> & {
  name: string,
  label?: string,

  type?: INPUT_TYPES,

  formName: string,
  maxLength?: number,
  maxValue?: number,
  calculation?: (formValues: shame, initialValues: shame, calculationArgs?: FormFieldCalculationArgs) => string | number,
  calculationArgs?: { [k: string]: number }, // Providing this to the field lets it know when a dependent value has changed so it can re-render
  disabled?: boolean,
  validate?: ((value: any, record: any) => string | undefined) | (Array<(value: any, record: any) => string | undefined>),
  onChange?: (value: any, record: any) => void,
}) => {
  const rdx = useSelector((s: State.Type) => ({
    formValues: !p.formName ? undefined : getFormValues(p.formName)(s),
    initialValues: !p.formName ? undefined : getInitialValues(p.formName)(s),
  }));
  const dispatch = useDispatch();
  const changeFieldValue = (...args: Args<typeof change>) => dispatch(change(...args));
  const validators = React.useMemo(() => buildValidate(p), [p.validate, p.maxLength, p.maxValue]);
  const calculatedValue = useDerivation( [rdx.formValues, p.calculation, p.calculationArgs], () => !rdx.formValues ? undefined : p.calculation?.(rdx.formValues, rdx.initialValues, p.calculationArgs) );
  React.useEffect(() => {
    if (rdx.formValues && p.calculation && rdx.formValues[p.name] !== calculatedValue)
      changeFieldValue(p.formName, p.name, calculatedValue);

    if (rdx.formValues && rdx.formValues[p.name] === '')
      changeFieldValue(p.formName, p.name, null);
  }, [p.calculation, rdx.formValues?.[p.name], calculatedValue, p.formName, p.name]);


  return (
    <FieldWrapper
      {...p}
      validators={validators}
      handleChange={eventArgs => {
        const v = eventArgs.target ? eventArgs.target.value : eventArgs;
        if (p.onChange)
          p.onChange(v, rdx.formValues);
        else
          changeFieldValue(p.formName, p.name, v);
      }}
    />
  );
};
