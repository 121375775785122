import gql from 'graphql-tag';
import { SupplierCommitment } from 'schema/supplier-commitment/types';

export interface GetSupplierCommitmentWeeklyProductVolumeQueryInput {
  supplierCommitmentId: number;
}
export interface GetSupplierCommitmentWeeklyProductVolumeQueryResponse {
  supplierCommitment: Pick<SupplierCommitment, 'id' | 'startWeek' | 'endWeek'>;
}

export const GetSupplierCommitmentWeeklyProductVolumeQuery = gql`
  query GetSupplierCommitmentWeeklyProductVolume($supplierCommitmentId: Int!) {
    supplierCommitment: GetSupplierCommitment(id: $supplierCommitmentId) {
      id
      startWeek
      endWeek
    }
  }
`;
