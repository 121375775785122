import gql from 'graphql-tag';
import { CustomerOrderProductGroupId, CustomerOrderProductGroupIdentifier } from 'shared/schemas/customer-order-product-group';
import { CustomerOrderAllocationId, CustomerOrderAllocationQuantity, CustomerOrderAllocationRackQuantity } from 'shared/schemas/customer-order-allocation';
import { CustomerOrderProductId } from 'shared/schemas/customer-order-product';
import { StoreId } from 'shared/schemas/store';

export interface AllocationQueryResponse {
  content: Array<{
    id: CustomerOrderAllocationId;
    quantity: CustomerOrderAllocationQuantity;
    rackQuantity: CustomerOrderAllocationRackQuantity | null;
    store: {
      id: StoreId;
    }
    customerOrderProduct: {
      id: CustomerOrderProductId;
      customerOrderProductGroup: {
        id: CustomerOrderProductGroupId;
        identifier: CustomerOrderProductGroupIdentifier;
      }
    }
  }>;
}

export interface AllocationQueryVariables {
  scope: Array<{
    field: string,
    values: string[];
  }>;
}

export const AllocationQuery = gql`
query findAllCustomerOrderAllocations($type: RecordType = CustomerOrderAllocation, $scope: [FilterSpecificationInput]) {
  content: findAll(type: $type, scope: $scope) {
    id
    ...CustomerOrderAllocationFindAllFields
  }
}

fragment CustomerOrderAllocationFindAllFields on CustomerOrderAllocation {
  id
  quantity

  store {
    id
  }

  customerOrderProduct {
    id
    customerOrderProductGroup {
      id
      identifier
    }
  }
}
`;
