import { tableName, property, belongsTo, definePresentation, setSchemaOptions, required } from './dsl';
import { DISPLAY_TYPES, ActiveInactive, SORT_TYPES } from '../types';

import { ProductClass } from './product-class';
import { SellDepartment } from './sell-department';
import { IRecord } from './record';
import { displayType } from './dsl';

@tableName('productSubClasses')
export class ProductSubClass implements IRecord {
  id?: number;

  @belongsTo('sellDepartments', { through: 'productClasses' })
  @property @required sellDepartment: SellDepartment;

  @belongsTo('productClasses', { foreignDisplayKey: 'identifier' })
  @property @required productClass: ProductClass;
  productClassId: number;

  @property @required identifier: string;
  @property @required activeStatus: ActiveInactive;
}

definePresentation(ProductSubClass, {
  sellDepartment: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  productClass: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'Product Subclass',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 35,
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
});

setSchemaOptions(ProductSubClass, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'sellDepartment', foreignColumn: 'identifier' },
});
