import { RelatedCustomerOrdersForm } from './form';
import { buildAdminDetailPage } from 'client/containers/admin/admin-detail-page';
import SupplierOrderStatusToggle from '../record-bar-status-toggle';

export default buildAdminDetailPage({
  table: 'SupplierOrder',
  formName: 'supplierOrdersRelatedCustomerPOs',
  FormComponent: RelatedCustomerOrdersForm,
  OptionalStatusToggle: SupplierOrderStatusToggle,
});
