import * as React from 'react';
import { Col, Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import AddRoutePlansTable from '../add-route-plans-table';
import { ConfirmOkToSaveFunction } from '../shared/save-confirmation';

export interface OwnProps {
  handleAddClicked(invoiceId: number, refetchQueries: string[], confirmOkToSave: ConfirmOkToSaveFunction): void;
  handleCloseClicked(): void;
  confirmOkToSave(): Promise<boolean>;
  invoiceId: number;
  refetchQueries: string[];
}

export class AddRoutePlansModal extends React.Component<OwnProps, {}> {
  constructor(props) {
    super(props);
  }

  addHandler = () => {
    this.props.handleAddClicked(this.props.invoiceId, this.props.refetchQueries, this.props.confirmOkToSave);
  }

  closeHandler = () => {
    this.props.handleCloseClicked();
  }

  render() {
    return (
      <Modal
        id={'add-route-plans-to-invoice-modal'}
        animation={false}
        backdrop="static"
        dialogClassName={'add-route-plans-to-invoice-modal'}
        show={true}
        onHide={this.props.handleCloseClicked}>
        <GlobalAsyncModalHeader>Route Plans</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <AddRoutePlansTable invoiceId={this.props.invoiceId}/>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={this.props.handleCloseClicked} />
          <GlobalSaveButton
            label={'Add Lines to Invoice'}
            testid={'modal-submit'}
            onClick={this.addHandler}
            disabled={false}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
