import * as _ from 'lodash';
import { orThrow } from '../helpers/or-throw';
import { NumericSources, MoneyStr } from './money-str';

/** https://www.postgresql.org/docs/12/datatype-numeric.html#DATATYPE-NUMERIC-DECIMAL
 * > 8.1.2. Arbitrary Precision Numbers
 * > The type numeric can store numbers with up to 1000 digits of precision and perform calculations exactly. It is especially recommended for storing monetary amounts and other quantities where exactness is required. However, arithmetic on numeric values is very slow compared to the integer types, or to the floating-point types described in the next section.
 * > We use the following terms below: The scale of a numeric is the count of decimal digits in the fractional part, to the right of the decimal point. The precision of a numeric is the total count of significant digits in the whole number, that is, the number of digits to both sides of the decimal point. So the number 23.5141 has a precision of 6 and a scale of 4. Integers can be considered to have a scale of zero.
 * > Both the maximum precision and the maximum scale of a numeric column can be configured. To declare a column of type numeric use the syntax:
 * ```NUMERIC(precision, scale)```
 */

export class Numeric {
  static readonly SCALE = 6;
  private readonly _value: bigint;
  private readonly _scale: Int;
  private constructor(input: string | bigint, scale: Int) {
    this._scale = scale;
    if (typeof input === 'bigint') { this._value = input; return; } // special private path for internal arithmetic -- assumed already shifted left for _scale


    //     2.557979428530646e-14
    // remove everything but digits, dots, and minuses, then match
    const [, sign, whole, decimals, exponentSign, exponent] = `${input}`.replace(/[^-\.\d]/g, '')
      .match(/^(?<sign>-?)(?<whole>\d*)\.?(?<decimals>\d*)e?(?<exponentSign>[+-])?(?<exponent>\d+)?$/)
      || orThrow(`input ('${input}') with unexpected decimal format`);

    this._value = BigInt(`${whole.length === 0 ? '0' : whole}${_.padEnd(decimals, this._scale, '0').substr(0, this._scale)}`);
    this._value += BigInt(decimals.length > this._scale && Number.parseInt(decimals[this._scale]) >= 5 ? 1 : 0); // bankers' rounding to max precision
    this._value *= BigInt(sign === '-' ? -1 : 1);
    if (exponent) {
      const exp = Number.parseInt(exponent);
      let compressed = new Numeric(this._value, this._scale);
      for (let e = 0; e <= exp; e++) {
        if (exponentSign === '-')
          compressed = compressed.div(10);

        else
          compressed = compressed.mul(10);
      }

      this._value = compressed._value;
    }
  }

  static isNumber = (s: string) => `${s}`.replace(/[$,\.\d\s]/g, '').length === 0;

  static readonly from = (n: NumericSources, fixedScale: Int = Numeric.SCALE): Numeric => typeof n === 'string' ? new Numeric(n, fixedScale)
    : typeof n === 'number' ? new Numeric(n.toString(10), fixedScale)
      : n instanceof Numeric ? (n._scale === fixedScale || fixedScale === null ? n : (fixedScale > n._scale ? new Numeric(`${n}`, fixedScale) : orThrow(`loss of precision from scale ${n._scale} to scale ${fixedScale}`)))
        : new Numeric('' + (Math.round((n as any || 0) * 1000000) / 1000000), fixedScale); // last ditch effort - because JankScript si not actually typed. -- the test failure here was vbery subtle, only subsequent acceptance tests failing due to inability to login... prior test hang?

  readonly components = () => {
    const abs = _.padStart(this._value < 0 ? this._value.toString(10).substr(1) : this._value.toString(10), this._scale + 1, '0');
    return { sign: this._value < 0 ? '-' : '', whole: abs.substr(0, abs.length - this._scale), decimal: abs.substr(abs.length - this._scale, this._scale) };
  };

  toString(fmtScale: { min: Int; max: Int; } = { min: 0, max: Numeric.SCALE }, thousandsSeparator: string = '', prefix: string = '', suffix: string = '') {
    let c = this.components();
    c.decimal = _.trimEnd(c.decimal, '0');
    if (fmtScale.max < fmtScale.min) { const min = fmtScale.max; fmtScale.max = fmtScale.min; fmtScale.min = min; } // handle invalid min/max by swapping
    if (fmtScale.min < 0) fmtScale.min = 0; // clamp min
    if (fmtScale.max > Numeric.SCALE) fmtScale.max = Numeric.SCALE; // clamp max
    if (fmtScale.max < c.decimal.length) c = Numeric.from(`${c.sign}${c.whole}.${c.decimal}`, fmtScale.max).components(); // round to smaller scale
    if (fmtScale.min > c.decimal.length) c.decimal = _.padEnd(c.decimal, fmtScale.min, '0'); // pad to larger scale
    if (thousandsSeparator.length > 0) {
      const leader = c.whole.substr(0, c.whole.length % 3);
      const remainder = c.whole.substr(leader.length);
      c.whole = [leader, ..._.range(0, remainder.length / 3).map(i => remainder.substr(i * 3, 3))].filter(x => x.trim().length > 0).join(thousandsSeparator);
    }

    return `${prefix}${c.sign}${c.whole}${c.decimal.length > 0 ? '.' : ''}${c.decimal}${suffix}`;
  }

  readonly toMoneyStr = () => this.toString({ min: 2, max: Numeric.SCALE }) as MoneyStr;
  readonly toFloat = (fmtScale?: Int) => Number.parseFloat(this.toString(fmtScale ? { min: fmtScale, max: fmtScale } : undefined));
  readonly toInt = () => this.toFloat(0) as Int;

  readonly add = (a: Numeric) => this._scale === a._scale ? new Numeric(this._value + a._value, this._scale) : orThrow(`mismatch between numeric values of scale ${this._scale} and scale ${a._scale}`);
  readonly sub = (a: Numeric) => this._scale === a._scale ? new Numeric(this._value - a._value, this._scale) : orThrow(`mismatch between numeric values of scale ${this._scale} and scale ${a._scale}`);
  readonly mul = (a: number): Numeric => new Numeric(this._value * BigInt(a * 100) / BigInt(100), this._scale);
  readonly div = (a: number): Numeric => new Numeric(this._value / BigInt(a), this._scale);

  static readonly defineChangeOfBase = (singlePlaceValueDigits: string) => {
    // https://ponyfoo.com/articles/es6-strings-and-unicode-in-depth
    const chars = [...singlePlaceValueDigits.normalize()];
    const digits = _.uniq(chars);
    const radix = chars.length === digits.length && digits.length > 1 ? digits.length : orThrow(`provided sequence of digits must be a list of 2 or more unique unicode codepoints: [${digits}] => [${singlePlaceValueDigits}]`);
    return (numeric: Numeric) => {
      if (numeric.toInt() === 0) return digits[0];

      const reversed: string[] = [];
      for (let unencoded = numeric.toInt() as number; unencoded > 0;) {
        const remainder = unencoded % radix;
        const whole = unencoded - remainder;
        const shifted = whole / radix;
        const digit = digits[remainder];
        reversed.push(digit);
        // console.debug({digits, radix, unencoded, remainder, digit, whole, shifted, reversed});
        unencoded = shifted;
      }

      return reversed.reverse().join('');
    };
  };

  static readonly defineChangeOfBaseWithoutZero = (singlePlaceValueDigits: string) => {
    // https://ponyfoo.com/articles/es6-strings-and-unicode-in-depth
    const chars = [...singlePlaceValueDigits.normalize()];
    const digits = _.uniq(chars);
    const radix = chars.length === digits.length && digits.length > 1 ? digits.length : orThrow(`provided sequence of digits must be a list of 2 or moreunique unicode codepoints: [${digits}] => [${singlePlaceValueDigits}]`);
    return (numeric: Numeric) => {
      const reversed: string[] = [];
      for (let unencoded = numeric.toInt() as number; unencoded >= 0; unencoded--) {
        const remainder = unencoded % radix;
        const whole = unencoded - remainder;
        const shifted = whole / radix;
        const digit = digits[remainder];
        reversed.push(digit);
        // console.debug({digits, radix, unencoded, remainder, digit, whole, shifted, reversed});
        unencoded = shifted;
      }

      return reversed.reverse().join('');
    };
  };

  private static readonly base16 = Numeric.defineChangeOfBase('0123456789abcdef');
  private static readonly excelColumn = Numeric.defineChangeOfBaseWithoutZero('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

  readonly toHex = () => Numeric.base16(this);

  /** Same as Excel columns: A,B,C... Z, AA, AB... AZ,BA, BB, BC... BAAAC...*/
  readonly toExcelColumn = () => Numeric.excelColumn(this);
}
