import * as React from 'react';
import { InvoiceType } from 'shared/types';
import { assertUnreachable } from 'shared/helpers/andys-little-helpers';
import { StatsCardBasic } from 'client/components/cards';

export const StatsRow = (p: { children?}) => <div className="stats-row">{p.children}</div>;

interface DropShipInvoiceStats {
  type: InvoiceType.DropShip;
  storesDelivered: string;
  totalPrice: string;
}

interface CustomerOrderInvoiceStats {
  type: InvoiceType.CustomerOrder;
  totalPacks: string;
  totalPieces: string;
  totalPrice: string;
}

interface VendorChargebackInvoiceStats {
  type: InvoiceType.VendorChargeback;
  totalPacks: string;
  totalPieces: string;
  totalPrice: string;
}

export interface OwnProps {
  loading?: boolean;
  invoice?: DropShipInvoiceStats | CustomerOrderInvoiceStats | VendorChargebackInvoiceStats;
}

const InvoiceDetailsStatsRowUI = (props: OwnProps) => {
  const invoiceStats = props.invoice;
  if (!invoiceStats) {
    return (
      <StatsRow>
        <StatsCardBasic title="">-</StatsCardBasic>
      </StatsRow>
    );
  }

  switch (invoiceStats.type) {
    case InvoiceType.DropShip:
      return (
        <StatsRow>
          <StatsCardBasic title="Total Stores Delivered">{invoiceStats.storesDelivered}</StatsCardBasic>
          <StatsCardBasic title="Total Price">{invoiceStats.totalPrice}</StatsCardBasic>
        </StatsRow>
      );
    case InvoiceType.CustomerOrder:
      return (
        <StatsRow>
          <StatsCardBasic title="Total Quantity">{invoiceStats.totalPacks}</StatsCardBasic>
          <StatsCardBasic title="Total Units">{invoiceStats.totalPieces}</StatsCardBasic>
          <StatsCardBasic title="Total Price">{invoiceStats.totalPrice}</StatsCardBasic>
        </StatsRow>
      );
    case InvoiceType.VendorChargeback:
      return (
        <StatsRow>
          <StatsCardBasic title="Total Quantity">{invoiceStats.totalPacks}</StatsCardBasic>
          <StatsCardBasic title="Total Units">{invoiceStats.totalPieces}</StatsCardBasic>
          <StatsCardBasic title="Total Price">{invoiceStats.totalPrice}</StatsCardBasic>
        </StatsRow>
      );
    default:
      return assertUnreachable(invoiceStats);
  }
};

export default InvoiceDetailsStatsRowUI;
