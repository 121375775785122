import * as React from 'react';
import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { COLUMN_AGGREGATE_SUM } from 'client/components/table/table-helpers';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { pendingRowActionHandler } from 'client/containers/table/basic-table';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { EMPTY_ARRAY } from 'client/constants';
import TotalFilteredStoreFooter from './total-stores-footer-cell/total-stores-footer-cell-container';
import { ApolloRefetch, AvailableFilter, ActiveSort, ActiveFilter, AvailableSearchField } from 'client/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { RoutePlanAttachedCustomerOrderGQLResult } from 'schema/route-plan-attached-customer-orders/route-plan-attached-customer-orders-typescript-types';
import { CustomerId } from 'shared/schemas/customer';
import { MfcAreaId, MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { RoutePlanDeliveryDate, RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { TableParentInfo } from 'client/components/table/table-parent';
import { send } from 'shared/send';
import * as FileSaver from 'client/utils/filesaver';
import { Bug } from 'shared/helpers';

export interface OwnProps {
  routePlanId?: RoutePlanId;
  tableName: string;
  type: 'Attached' | 'Attach';
  disableCreate?: boolean;
  detachOptionDisabled: boolean;
  refetchTable?: ApolloRefetch;
  detachCustomerOrderFromRoutePlan: (customerOrderIds: CustomerOrderId[], refetchTable: ApolloRefetch) => Promise<CustomerOrderId[]>;
  attachedCustomerOrderRows?: RoutePlanAttachedCustomerOrderGQLResult[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
  activeSortFields: ActiveSort[];
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
  customerId?: CustomerId;
  routePlanDeliveryDate?: RoutePlanDeliveryDate;
  mfcAreaId?: MfcAreaId;
  sellDepartmentId?: SellDepartmentId;
  tableParentInfo: TableParentInfo;
  mfcAreaIdentifier?: MfcAreaIdentifier;
  handleNewClicked(): void;
  placeholder: string;
  newButtonLabel?: string;
  tablePaginated: boolean;
  onRowSelect: (record: { id: number }) => void;
  searchableFields: AvailableSearchField[];
}

export class CustomerOrdersJustTheTable extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  private headerMenuItems: RowMenuItem[];
  private rowMenuItems: RowMenuItem[];
  private columns: IColumn[];

  constructor(props: OwnProps) {
    super(props);
    this.FilterableTable = buildFilterableTable(props.tableName);

    this.rowMenuItems = props.type === 'Attached' ? [{
      label: 'Detach Order',
      onClick: pendingRowActionHandler(this, (customerOrderIds: CustomerOrderId[]) => props.detachCustomerOrderFromRoutePlan(customerOrderIds, props.refetchTable!)),
      uncheckRecordFollowingClick: true,
      willRemove: true,
      disabled: this.props.detachOptionDisabled,
    },{
      label: 'Download Allocation Sheets',
      onClick: pendingRowActionHandler(this, async (customerOrderIds: CustomerOrderId[], record?: shame) => {
        if (customerOrderIds.length !== 1) throw new Bug(`Expected exactly one customer order id, but got ${customerOrderIds.length}.`);
        const customerOrderId = customerOrderIds[0];
        const pdfBlob = await send({
          url: `/report/itemAllocation/${customerOrderId}`,
          method: 'GET',
          asBlob: true,
        });

        FileSaver.saveAs(pdfBlob, `allocations-for-${record.customerIdentifier}-${record.identifier}-from-${record.vendor?.replace(/\s/ig, '-')}.pdf`);
        return customerOrderIds;
      }),
    }] : [];

    this.headerMenuItems = props.type === 'Attached' ? [{
      label: 'Detach Orders',
      onClick: pendingRowActionHandler(this, (customerOrderIds: CustomerOrderId[]) => props.detachCustomerOrderFromRoutePlan(customerOrderIds, props.refetchTable!)),
      uncheckRecordFollowingClick: true,
      willRemove: true,
      disabled: this.props.detachOptionDisabled,
    }] : [];

    this.columns = [
      {
        id: 'identifier',
        accessor: 'identifier',
        header: 'PO #',
        tableEditable: false,
        columnWidth: 10,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'customerName',
        accessor: 'customerName',
        header: 'Customer',
        tableEditable: false,
        columnWidth: 15,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'vendor',
        accessor: 'vendor',
        header: 'Vendor',
        tableEditable: false,
        columnWidth: 15,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'mfcAreaIdentifier',
        accessor: 'mfcAreaIdentifier',
        header: 'MFC Area',
        tableEditable: false,
        columnWidth: 5,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'stores',
        accessor: 'stores',
        header: 'Stores',
        tableEditable: false,
        columnWidth: 5,
        sortable: true,
        cellType: CELL_TYPES.DASH_ZERO_NUMBER,
        type: TYPES.NUMBER,
        ...(props.type === 'Attached' ? { footerComponent:  TotalFilteredStoreFooter } : {}),
      },
      {
        id: 'racks',
        accessor: 'racks',
        header: 'Racks',
        tableEditable: false,
        columnWidth: 5,
        sortable: true,
        cellType: CELL_TYPES.DASH_ZERO_NUMBER,
        type: TYPES.NUMBER,
        ...(props.type === 'Attached' ? { footer: COLUMN_AGGREGATE_SUM } : {}),
      },
      {
        id: 'pallets',
        accessor: 'pallets',
        header: 'Pallets',
        tableEditable: false,
        columnWidth: 5,
        sortable: true,
        cellType: CELL_TYPES.DASH_ZERO_NUMBER,
        type: TYPES.NUMBER,
        ...(props.type === 'Attached' ? { footer: COLUMN_AGGREGATE_SUM } : {}),
      },
      {
        id: 'orderType',
        accessor: 'orderType',
        header: 'Order Type',
        tableEditable: false,
        columnWidth: 10,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'deliveryDate',
        accessor: 'deliveryDate',
        header: 'Delivery Date',
        tableEditable: false,
        columnWidth: 8,
        sortable: true,
        cellType: CELL_TYPES.DATE,
        type: TYPES.DATE,
      },
      {
        id: 'status',
        accessor: 'status',
        header: 'Status',
        tableEditable: false,
        columnWidth: 10,
        sortable: true,
        cellType: CELL_TYPES.STATUS,
        type: TYPES.STRING,
      },
      {
        id: 'lastModified',
        accessor: 'lastModified',
        header: 'Last Update',
        tableEditable: false,
        columnWidth: 12,
        sortable: true,
        cellType: CELL_TYPES.DATE_TIME,
        type: TYPES.STRING,
      },
    ];
  }

  public render() {
    return (
      <this.FilterableTable
        table={this.props.tableName}
        content={this.props.attachedCustomerOrderRows || EMPTY_ARRAY}
        loading={this.props.loading}
        columns={this.columns}
        totalCount={this.props.totalCount}
        totalUnfilteredCount={this.props.totalUnfilteredCount}
        filteredRecordIds={this.props.filteredRecordIds}
        refetchTable={this.props.refetchTable}
        searchableFields={this.props.searchableFields}
        availableFilters={this.props.availableFilters}
        placeholder={this.props.placeholder}
        newButtonLabel={this.props.newButtonLabel}
        disableCreate={this.props.disableCreate}
        onNewClicked={this.props.handleNewClicked}
        checkable
        list
        tablePaginated={this.props.tablePaginated}
        tableParentInfo={this.props.tableParentInfo}
        displayLoadingIndicator
        dataRequest={this.props.dataRequest}
        headerMenuItems={this.headerMenuItems}
        rowMenuItems={this.rowMenuItems}
        disableTableDownload
        onRowSelect={this.props.onRowSelect}
      />
    );
  }
}
