import * as React from 'react';

export interface OwnProps {
  handleSectionUnmounted: () => void;
  handleProductWorksheetUnmounted: () => void;
}

class CustomerOrderDetailPage extends React.Component<OwnProps, {}> {
  componentWillUnmount() {
    this.props.handleSectionUnmounted();
    this.props.handleProductWorksheetUnmounted();
  }

  public render() {
    return <div>{this.props.children}</div>;
  }
}

export default CustomerOrderDetailPage;
