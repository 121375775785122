import * as React from 'react';
import { Button } from 'client/components/third-party';
import BolListItemContainer from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list-item/bol-list-item-container';
import { BolId } from 'shared/schemas/bol';
import AddBolModal from 'client/app/transportation/receiving/details/worksheet/add-bol-modal/add-bol-modal-container';
import EditBolModal from 'client/app/transportation/receiving/details/worksheet/edit-bol-modal/edit-bol-modal-container';
import RemoveBolModal from 'client/app/transportation/receiving/details/worksheet/remove-bol-modal/remove-bol-modal-container';
import { worksheetRedirectHandler } from 'client/helpers/worksheet';
import { BolListItemData } from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list-item/bol-list-item';

interface BolListProps {
  receivableOrderId: number;
  bols: BolListItemData[];
  requestedBolId?: BolId; // from url parameter
  selectedBolId?: BolId; // from redux state
  onAddBolClicked(): void;
  onBolClicked(bolId: number): void;
  redirectToReceivingWorksheet(): void;
}

export class BolList extends React.Component<BolListProps, {}> {
  public componentWillReceiveProps(nextProps: BolListProps) {
    worksheetRedirectHandler({
      availableIds: nextProps.bols.map(bol => bol.id),
      requestedId: nextProps.requestedBolId, // from url param
      selectedId: nextProps.selectedBolId, // from state
      foundAction: nextProps.onBolClicked,
      notFoundAction: nextProps.redirectToReceivingWorksheet,
    });
  }

  public render() {
    return (
      <div className="product-worksheet-products-list-sidebar">
        <div className="product-worksheet-products-list-sidebar-top">
          <h3>BOLs</h3>
          <ul className="bol-list">
            {this.props.bols.map(bol =>
              <BolListItemContainer key={bol.id} bol={bol} isSelected={bol.id === this.props.requestedBolId} onBolClicked={this.props.onBolClicked}/>,
            )}

            <div className="product-worksheet-products-list-sidebar-bottom">
              <div className="text-center">
                <Button bsClass="mfc-button mfc-submit-button mfc-submit-button-primary" data-testid="add-bol-button" onClick={() => this.props.onAddBolClicked()}>
                  Add BOL
                </Button>
              </div>
            </div>
          </ul>
          <AddBolModal receivableOrderId={this.props.receivableOrderId} />
          <EditBolModal receivableOrderId={this.props.receivableOrderId} />
          <RemoveBolModal receivableOrderId={this.props.receivableOrderId} />
        </div>
      </div>
    );
  }
}
