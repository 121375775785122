import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { UpdateSupplierItemCostsModal, Props } from './update-supplier-item-costs-modal-ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Actions from './actions';
import { isInvalid } from 'redux-form';
import { DateStr } from 'shared/types';
import { Clock } from 'shared/clock';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  supplierCommitmentId: number;
}

export interface StateProps {
  formName: string;
  saveButtonDisabled: boolean;
  initialValues: {
    costEffectiveDate: DateStr;
  };
}

export const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    formName: Actions.FORM_NAME,
    saveButtonDisabled: isInvalid(Actions.FORM_NAME)(state),
    initialValues: {
      costEffectiveDate: Clock.today(),
    },
  };
};

interface ReduxFormProps {
  handleSubmit: () => void;
  reset: () => void;
}

type DispatchProps = {
  onClose: () => void;
  updateSupplierItemCosts: (supplierCommitmentId: number) => void;
};

export const mapDispatchToProps: DispatchProps = {
  onClose: Actions.onUpdateSupplierItemCostsModalCloseClicked,
  updateSupplierItemCosts: Actions.updateSupplierItemCosts,
};

assertCompatible<Props, StateProps & DispatchProps & OwnProps & ReduxFormProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  ReduxForm.reduxForm({ form: Actions.FORM_NAME, touchOnBlur: true, touchOnChange: true, enableReinitialize: true }),
)(UpdateSupplierItemCostsModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
