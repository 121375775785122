import { wrapComponent } from 'client/hoc/hoc';
import { connect, ConnectedProps } from 'react-redux';
import { VendorFormUI, VendorFormValues } from './ui';
import { reduxForm, getFormValues, FormSubmitHandler } from 'redux-form';
import * as Actions from './actions';
import * as Constants from './constants';
import { WithWarnUnsaved } from 'client/hoc/with-warn-unsaved';

interface ContainerProps {
  onSubmit: FormSubmitHandler<VendorFormValues, { initialValues: { identifier: string } }>;
  initialValues?: shame;
  vendorId?: number;
}

const mapStateToProps = (state: {}, ownProps: ContainerProps) => {
  const values = getFormValues(Constants.formName)(state) as VendorFormValues;
  return {
    displayContacts: values && values.contacts || [],
  };
};

const mapDispatchToProps = {
  onPrimaryClicked: Actions.primaryClicked,
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

type CombinedProps =
  ContainerProps &
  ConnectedProps<typeof connector>;

export const VendorFormContainer = wrapComponent(VendorFormUI)<ContainerProps, CombinedProps>(
  reduxForm({
    form: Constants.formName,
    enableReinitialize: true,
  }),
  WithWarnUnsaved({ recordIdProperty: 'vendorId' }),
  connector,
);
