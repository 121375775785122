import * as React from 'react';
import { PropsWithChildren } from 'react';

class SecondaryNavLinkToggle extends React.Component<PropsWithChildren<{
  onClick?: (...args: unknown[]) => unknown,
  onMouseOver?: (...args: unknown[]) => unknown,
  bsRole?: string,
}>> {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    const { children, onMouseOver } = this.props;
    return (
      <a onClick={this.handleClick} onMouseOver={onMouseOver}>
        {children}
      </a>
    );
  }

  handleClick(event) {
    this.props.onClick?.(event);
  }
}

(SecondaryNavLinkToggle as shame).defaultProps = {
  multi: false,
  disabled: false,
  block: false,
};

export default SecondaryNavLinkToggle;
