import { tableName, property, hasMany, definePresentation, setSchemaOptions, required } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, ActiveInactive } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';
import { Product } from './product';
import { Store } from 'shared/schemas/store';
import { CustomerContact } from './customer-contact';

export type CustomerId = number;
export type CustomerIdentifier = Flavor<string, 'customerIdentifier'>;
export type CustomerName = Flavor<string, 'customerName'>;
export type CustomerShortName = Flavor<string, 'customerShortName'>;

@tableName('customers')
export class Customer implements IRecord {
  id?: CustomerId;
  @property @required identifier: CustomerIdentifier;
  @property @required name: CustomerName;
  @property @required shortName: CustomerShortName;
  @property phone?: string | null;
  @property address1?: string | null;
  @property address2?: string | null;
  @property city?: string | null;
  @property state?: string | null;
  @property zip?: string | null;
  @property country?: string | null;
  @property fax?: string | null;
  @property contact?: string | null;
  @property email?: string | null;
  @property billingAddress1?: string | null;
  @property billingAddress2?: string | null;
  @property billingCity?: string | null;
  @property billingState?: string | null;
  @property billingZip?: string | null;
  @property billingPhone?: string | null;
  @property billingFax?: string | null;
  @property billingContact?: string | null;
  @property billingEmail?: string | null;
  @property terms?: string | null;
  @property discount?: number | null;
  @property billType?: string | null;
  @property skuRequired: boolean;
  @property mfcContactEmail?: string | null;
  @property vendorNumber?: string | null;
  @property supportsEdiInvoicing: boolean;
  @property prefersIsoWeeks?: boolean;
  @property @required displayName: string;

  @property @required activeStatus: ActiveInactive;

  @hasMany('products')
  @property products?: Array<Partial<Product>>;

  @hasMany('stores')
  @property stores?: Array<Partial<Store>>;

  @hasMany('customerContacts', { foreignDisplayKey: 'name' })
  @property contacts?: Array<Partial<CustomerContact>>;
}

function getCalculationSpecForPrimaryContact(field: string) {
  return {
    dependencies: {},
    calculation: (customer: Customer) => getFieldForContact(field, 'PrimaryContact'),
  };
}

function getCalculationSpecForBillingContact(field: string) {
  return {
    dependencies: {},
    calculation: (customer: Customer) => getFieldForContact(field, 'AccountsPayable'),
  };
}

function getFieldForContact(field: string, role: string) {
  return `(SELECT ${field} FROM customer_contacts WHERE customer_contacts.customer_id = customers.id AND customer_contacts.role = '${role}' LIMIT 1)`;
}

definePresentation(Customer, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'Code',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 5,
  },
  name: {
    searchable: true,
    sortable: true,
    displayName: 'Customer Name',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 20,
  },
  phone: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
    columnWidth: 10,
    calculationSpec: getCalculationSpecForPrimaryContact('phone'),
  },
  shortName: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  address1: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  address2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  city: {
    tableDisplay: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  state: {
    filterable: true,
    tableDisplay: true,
    sortable: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  zip: {
    displayName: 'ZIP',
    tableDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  country: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  fax: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
    calculationSpec: getCalculationSpecForPrimaryContact('fax'),
  },
  contact: {
    displayName: 'Primary Contact',
    searchable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
    calculationSpec: getCalculationSpecForPrimaryContact('name'),
  },
  email: {
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.EMAIL }),
    columnWidth: 15,
    calculationSpec: getCalculationSpecForPrimaryContact('email'),
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  billingAddress1: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('address_1'),
  },
  billingAddress2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('address_2'),
  },
  billingCity: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('city'),
  },
  billingState: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('state'),
  },
  billingZip: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('zip'),
  },
  billingPhone: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
    calculationSpec: getCalculationSpecForBillingContact('phone'),
  },
  billingFax: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
    calculationSpec: getCalculationSpecForBillingContact('fax'),
  },
  billingContact: {
    displayName: 'Billing Contact Name',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: getCalculationSpecForBillingContact('name'),
  },
  billingEmail: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.EMAIL }),
    calculationSpec: getCalculationSpecForBillingContact('email'),
  },
  products: {
    formDisplay: false,
  },
  terms: {
    displayName: 'Terms',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  discount: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  billType: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  skuRequired: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  mfcContactEmail: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.EMAIL }),
  },
  vendorNumber: {
    displayName: 'Vendor Number',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  supportsEdiInvoicing: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  prefersIsoWeeks: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  displayName: {
    displayName: 'Display Name',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  contacts: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
    tableDisplayColumns: { name: 'Contacts'},
  },
});

setSchemaOptions(Customer, {
  softDeletable: true,
 });
