import * as React from 'react';
import { Form, FormGroup, Col, Row } from 'client/components/third-party';
import { LabeledSelect, LabeledInput, FormField } from 'client/components/form';
import { HiddenFocusField } from 'client/components/form/hidden-focus-field';
import * as Constants from './constants';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { Options } from 'client/hoc/options-container-generator';
import * as Validators from 'shared/validators';
import { SalesPlanIdentifier, INPUT_TYPES, DateTimeStr } from 'shared/types';
import * as classnames from 'classnames';
import { formatDateTimeStrForDisplay } from 'shared/helpers';
import { EMPTY_ARRAY } from 'client/constants';
import { createSelector } from 'reselect';

export interface OwnProps {
  salesPlanId?: number;
  handleSubmit: React.EventHandler<React.FormEvent<Form>>;
  customerOptions: Options | undefined;
  sellDepartmentOptions: Options | undefined;
  loading?: boolean;
  lastModifiedAt: DateTimeStr | undefined;
  lastModifiedBy: string | undefined;
  onDownloadMenuItemClicked(salesPlanId: number): Promise<void>;
  onDownloadCustomerSpecSheetMenuItemClicked(salesPlanId: number): Promise<void>;
  onDownloadDifferenceReportCalculationSpreadsheetMenuItemClicked(salesPlanId: number): Promise<void>;
}

const optionFormatter = option => option.label;
const seasonFormatter = option => option.identifier;
const customerTextFormatter = option => `${option.identifier} - ${option.name}`;
const sellDepartmentTextFormatter = option => option.identifier;

const requiredValidator = Validators.makeRequiredFormValidator();

const salesPlanIdentifierOptions = Object
  .values(SalesPlanIdentifier)
  .map(salesPlanIdentifier => {
    return {
      id: salesPlanIdentifier,
      identifier: salesPlanIdentifier,
    };
  });

export class LoadDetailsSidebarUI extends React.PureComponent<OwnProps, {}> {

  constructor(props: OwnProps) {
    super(props);
  }

  getMenuItems = createSelector([
    (props: OwnProps) => props.onDownloadMenuItemClicked,
    (props: OwnProps) => props.onDownloadCustomerSpecSheetMenuItemClicked,
    (props: OwnProps) => props.onDownloadDifferenceReportCalculationSpreadsheetMenuItemClicked,
    (props: OwnProps) => props.salesPlanId,
  ], (handleDownloadClicked, handleDownloadCustomerSpecSheet, handleDownloadDifferenceReportCalculationSpreadsheet, salesPlanId) => {
    return [
      {
        label: 'Download Excel',
        action: async (setInProgress: SetInProgress) => {
          setInProgress(true);
          try {
            if (salesPlanId) {
              await handleDownloadClicked(salesPlanId);
            }
          } finally {
            setInProgress(false);
          }
        },
      },
      {
        label: 'Download Spec Sheet',
        action: async (setInProgress: SetInProgress) => {
          setInProgress(true);
          try {
            if (salesPlanId) {
              await handleDownloadCustomerSpecSheet(salesPlanId);
            }
          } finally {
            setInProgress(false);
          }
        },
      },
      {
        label: 'Download Diff Report',
        action: async (setInProgress: SetInProgress) => {
          setInProgress(true);
          try {
            if (salesPlanId) {
              await handleDownloadDifferenceReportCalculationSpreadsheet(salesPlanId);
            }
          } finally {
            setInProgress(false);
          }
        },
      },
    ];
  });

  render() {
    if (this.props.loading) {
      return <ProgressSpinner label="Loading..." waitToDisplayMillis={200} />;
    }

    return (
      <div className="mfc-form-sidebar">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <HiddenFocusField />
          <FormGroup>
            <FormField
              formName={Constants.formName}
              name={Constants.FormFields.year}
              label="Year"
              testid="sales-plan-form-year"
              horizontalLabel={false}
              valueField="identifier"
              textFormatter={optionFormatter}
              component={LabeledInput}
              labelColSize={6}
              inputColSize={6}
              required
              validate={requiredValidator}
              type={INPUT_TYPES.NUMBER}
              min={2000}
              max={2200}
              autoFocus={!this.props.salesPlanId}
            />
            {!!this.props.salesPlanId &&
              <Col sm={6} className="sales-plan-actions-menu-container">
                <SimpleMenu
                  label="Actions"
                  className="sales-plan-actions-menu"
                  menuItems={this.getMenuItems(this.props)}
                />
              </Col>
            }
          </FormGroup>
          <FormGroup>
            <FormField
              formName={Constants.formName}
              name={Constants.FormFields.identifier}
              label="Season"
              testid="sales-plan-form-identifier"
              horizontalLabel={false}
              options={salesPlanIdentifierOptions}
              valueField="id"
              textFormatter={seasonFormatter}
              component={LabeledSelect}
              labelColSize={12}
              inputColSize={12}
              required
              validate={requiredValidator}
            />
          </FormGroup>
          <FormGroup>
            <FormField
              formName={Constants.formName}
              name={Constants.FormFields.customerId}
              label="Customer"
              testid="sales-plan-form-customer"
              horizontalLabel={false}
              options={this.props.customerOptions || EMPTY_ARRAY}
              valueField="id"
              textFormatter={customerTextFormatter}
              component={LabeledSelect}
              labelColSize={12}
              inputColSize={12}
              required
              validate={requiredValidator}
              disabled={(this.props.customerOptions || EMPTY_ARRAY).length === 0}
            />
          </FormGroup>
          <FormGroup>
            <FormField
              formName={Constants.formName}
              name={Constants.FormFields.sellDepartmentId}
              label="Sell Department"
              testid="sales-plan-form-sell-department"
              horizontalLabel={false}
              options={this.props.sellDepartmentOptions || EMPTY_ARRAY}
              valueField="value"
              textFormatter={sellDepartmentTextFormatter}
              component={LabeledSelect}
              labelColSize={12}
              inputColSize={12}
              required
              validate={requiredValidator}
              disabled={(this.props.sellDepartmentOptions || EMPTY_ARRAY).length === 0}
            />
          </FormGroup>

          <hr className="sidebar-horizontal-rule" />
          { this.props.lastModifiedAt && <FormGroup>
              <Col sm={12}>
                <Row>
                  <div className="mfc-form-stacked-label">Last Modified</div>
                </Row>
                <Row className="mfc-order-status-details">
                  <Col sm={12} className="status">
                    <div className={classnames('status-pill', 'neutral', {ellipsis: true})}>
                        {this.props.lastModifiedBy} - {formatDateTimeStrForDisplay(this.props.lastModifiedAt) || ''}
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup> }
        </Form>
      </div>
    );
  }
}
