import gql from 'graphql-tag';
import { DateStr } from 'shared/types';
import * as GQLTypes from 'schema/supplier-commitment/types';

export interface UpdateSupplierItemCostsForSupplierCommitmentMutationInput {
  supplierCommitmentId: number;
  costEffectiveDate: DateStr;
}
export interface UpdateSupplierItemCostsForSupplierCommitmentMutationResponse {
  supplierCommitment: Pick<GQLTypes.SupplierCommitment, 'id'>;
}
export const UpdateSupplierItemCostsForSupplierCommitmentMutation = gql`
  mutation UpdateSupplierItemCostsForSupplierCommitment($supplierCommitmentId: Int!, $costEffectiveDate: DateStr!) {
    supplierCommitment: UpdateSupplierItemCostsForSupplierCommitment(supplierCommitmentId: $supplierCommitmentId, costEffectiveDate: $costEffectiveDate) {
      id
    }
  }
`;
