import { Action } from 'redux';
import { ServerSideValidationInfo } from 'shared/validators';
import { ErrorActionTypeKeys } from 'client/constants';

export function validationErrorReceived(validations: ServerSideValidationInfo[]) {
  return {
    type: ErrorActionTypeKeys.ERRORS_VALIDATION_ERROR_RECEIVED,
    payload: { validations },
  };
}

export function errorAlertCloseClicked() {
  return {
    type: ErrorActionTypeKeys.ERRORS_ERROR_ALERT_CLOSE_CLICKED,
  };
}

export interface ExpectedErrorReceivedAction extends Action {
  type: ErrorActionTypeKeys.ERRORS_EXPECTED_ERROR_RECEIVED;
  payload: {
    message: string;
    debugInfo?: any;
  };
}

export function expectedErrorReceived(args: { message: string, debugInfo?: any }): ExpectedErrorReceivedAction {
  return {
    type: ErrorActionTypeKeys.ERRORS_EXPECTED_ERROR_RECEIVED,
    payload: args,
  };
}

export function unexpectedErrorReceived(args: { message: string, stack?: string[] }) {
  return {
    type: ErrorActionTypeKeys.ERRORS_UNEXPECTED_ERROR_RECEIVED,
    payload: args,
  };
}
