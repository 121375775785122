import gql from 'graphql-tag';
import { ShippingUnitType, OrderMethod, SortInput, FilterSpecificationInput, SearchInput } from 'shared/types';
import { BolId, BolIdentifier, BolAutoCartTrackingEnabled } from 'shared/schemas/bol';
import { isNil } from 'lodash';
import { SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { UnifiedReceivableOrderProduct } from 'schema/unified-receivable-order/unified-receivable-order-graphql-types';

export interface ReceivingWorksheetTableVariables {
  bolId: BolId;
  sort?: SortInput[];
  filters?: FilterSpecificationInput[];
  search?: SearchInput;
  limit?: number;
  offset?: number;
  scope?: FilterSpecificationInput[];
}

export interface ReceivingWorksheetTableResponse {
  response?: {
    id: number;
    identifier: string; // The receivable order's source identifier

    records: Array<{
      id: UnifiedReceivableOrderProduct['id'];
      receivableOrderType: UnifiedReceivableOrderProduct['receivableOrderType'];
      productIdentifier: UnifiedReceivableOrderProduct['productIdentifier'];
      productDescription: UnifiedReceivableOrderProduct['productDescription'];
      upc: UnifiedReceivableOrderProduct['upc'];
      rackType: UnifiedReceivableOrderProduct['rackType'];
      packSize: UnifiedReceivableOrderProduct['packSize'];
      packsPerShelf: UnifiedReceivableOrderProduct['packsPerShelf'];
      shelvesPerRack: UnifiedReceivableOrderProduct['shelvesPerRack'];
      packsPerShippingUnit: UnifiedReceivableOrderProduct['packsPerShippingUnit'];
      retailPrice: UnifiedReceivableOrderProduct['retailPrice'];
      shippingUnitQuantity: UnifiedReceivableOrderProduct['shippingUnitQuantity'];
      packQuantity: UnifiedReceivableOrderProduct['packQuantity'];
      packQuantityReceivedTotal: UnifiedReceivableOrderProduct['packQuantityReceivedInBol'];
      shippingUnitQuantityReceivedTotal: UnifiedReceivableOrderProduct['shippingUnitQuantityReceivedTotal'];
      packQuantityReceivedInBol: UnifiedReceivableOrderProduct['packQuantityReceivedInBol'];
      shippingUnitQuantityReceivedInBol: UnifiedReceivableOrderProduct['shippingUnitQuantityReceivedInBol'];
    }>;
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

export const receivingWorksheetRecordsSelector = (queryResponse: ReceivingWorksheetTableResponse): Required<ReceivingWorksheetTableResponse>['response']['records'] => {
  if (isNil(queryResponse.response)) {
    return [];
  }
  if (isNil(queryResponse.response.records)) {
    return [];
  }
  return queryResponse.response.records;
};

export const ReceivingWorksheetTableQuery = gql`
  query ReceivingWorksheetTableQuery($id: Int!, $filters: [FilterSpecificationInput!], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int, $bolId: Int!) {
    response: GetUnifiedReceivableOrder(id: $id) {
      id
      identifier
      records: details(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        receivableOrderType
        productIdentifier
        productDescription
        upc
        rackType
        packSize
        packsPerShelf
        shelvesPerRack
        packsPerShippingUnit
        retailPrice
        shippingUnitQuantity
        packQuantity
        packQuantityReceivedTotal
        shippingUnitQuantityReceivedTotal

        shippingUnitQuantityReceivedInBol(bolId: $bolId)
        packQuantityReceivedInBol(bolId: $bolId)
      }
      ids: detailsIds(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset)
      totalCount: detailsTotalCount(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset)
      totalUnfilteredCount: detailsTotalUnfilteredCount
    }
  }
`;

export interface ReceivableOrderInfoResponse {
  receivableOrder?: {
    id: number;
    shippingUnitType: ShippingUnitType,
    orderMethod: OrderMethod,
  };
}
export const ReceivableOrderInfoQuery = gql`
  query ReceivableOrderInfoForReceivingWorksheet($receivableOrderId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      shippingUnitType
      orderMethod
    }
  }
`;

export interface BolInfoResponse {
  receivableOrder?: {
    id: number;
    identifier: string;
    shippingUnitType: ShippingUnitType;
    orderMethod: OrderMethod;
    supplier: {
      id: SupplierId
      cartTrackingStatus: SupplierCartTrackingStatus;
    }
    bol: {
      id: BolId;
      identifier: BolIdentifier;
      packQuantityReceived: number;
      shippingUnitQuantityReceived: number;
      autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
    }
  };
}

export const BolInfoQuery = gql`
  query receivableOrderInfoForWorksheetTable($receivableOrderId: Int!, $bolId: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $receivableOrderId) {
      id
      identifier
      shippingUnitType
      orderMethod
      supplier {
        id
        cartTrackingStatus
      }
      bol: bol(bolId: $bolId) {
        id
        identifier
        packQuantityReceived
        shippingUnitQuantityReceived
        autoCartTrackingEnabled
      }
    }
  }
`;
