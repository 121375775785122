import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware/promise-middleware';
import logger from './logger';
import { buildRootReducer } from '../reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

function configureStore(initialState: any = undefined) {
  const store = createStore(
    buildRootReducer(history),
    initialState,
    compose(
      _getMiddleware(),
      ..._getEnhancers()
    )
  );

  _enableHotLoader(store);
  return store;
}

function _getMiddleware() {
  let middleware = [
    routerMiddleware(history),
    promiseMiddleware,
    thunk,
  ];

  if (globalThis.__DEV__) {
    middleware = [...middleware, logger];
  }

  return applyMiddleware(...middleware);
}

function _getEnhancers() {
  let enhancers = [
    // persistState('session', _getStorageConfig()), // No longer being used
  ] as any[];

  if (globalThis.__DEV__ && (window as shame).__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers = [...enhancers, (window as shame).__REDUX_DEVTOOLS_EXTENSION__() ];
  }

  return enhancers;
}

function _enableHotLoader(store) {
  if (globalThis.__DEV__ && (module as shame).hot) {
    (module as shame).hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }
}

// NOTE: No longer storing anything in localStorage (switched to storing token
//       in a cookie). Leaving this here as an easy reference if we need to
//       persist some of the state in the future.
// function _getStorageConfig() {
//   return {
//     key: 'react-redux-seed',
//     serialize: store => {
//       return store && store.session ?
//         JSON.stringify(store.session.toJS()) : store;
//     },
//     deserialize: state => ({
//       session: state ? fromJS(JSON.parse(state)) : fromJS({}),
//     }),
//   };
// }

export default configureStore;
