import * as _ from 'lodash';
import * as React from 'react';
import { Row, FormGroup } from 'client/components/third-party';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import * as Immutable from 'immutable';
import { isEqual } from 'lodash';
import { LabeledSelect, FieldWrapper, LabeledInput } from 'client/components/form';
import { Product } from 'shared/schemas/product';
import { OrderMethod, PackOrderMethod, ShippingUnitOrderMethod, ActiveInactive } from 'shared/types';
import * as Validators from 'shared/validators';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';

type ProductOption = Pick<Product, 'id' | 'identifier' | 'description'>;
function formatProductDisplayName(product: ProductOption): string {
  return `${product.identifier} - ${product.description}`;
}

interface FormProps {
  productFieldIdentifier: string;
  orderMethod: OrderMethod;
  isProductSelected: boolean;
  productIds?: Immutable.Set<number>;
  handleProductChanged: (productId: number, productFieldIdentifier: string) => void;
  handlePacksPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field?: 'packsPerShelf' | 'shelvesPerRack', value?: number) => void;
  handlePiecesPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field?: 'packSize' | 'packsPerShippingUnit', value?: number) => void;
  disableProductField: boolean;
  products: any[] | undefined;
  showDeleteButton: boolean;
  handleDeleteProductClick: () => void;
  productIndex: number;
}

export const buildPPRValidator = (index: number): Validators.FieldValidator => ({
  shortMessage(value: any, record: any) { return 'Must equal SPR * PPS'; },
  message(label: string, value: any, record: any) { return `${label} does not add up for the given SPR and PPS.`; },
  isValid(value: any, record: CustomerOrderProductGroup) {
    const customerOrderProduct = record.customerOrderProducts[index];
    if (_.isNil(customerOrderProduct)) {
      return true;
    }
    // Both SPR and PPS can be blank, in which case the PPR can be any non-zero value
    // If only one of the SPR or the PPS is present then the PPR can be any non-zero value
    // If both the SPR and PPS are present, their product must equal the PPR.
    if ((_.isNil(customerOrderProduct.packsPerShelf) || _.isNil(customerOrderProduct.shelvesPerRack)) && value) {
      return true;
    }

    if (customerOrderProduct.packsPerShelf && customerOrderProduct.shelvesPerRack && value) {
      const actualValue = customerOrderProduct.packsPerShelf * customerOrderProduct.shelvesPerRack;
      const roundedDownValue = _.floor(actualValue);
      return Number.parseFloat(value) === actualValue || Number.parseFloat(value) === roundedDownValue;
    }

    return  !_.isNil(value);
  },
});

class ProductForm extends React.PureComponent<FormProps, {}> {
  pprValidator: Validators.FieldValidator;

  constructor(props: FormProps) {
    super(props);
    this.pprValidator = buildPPRValidator(props.productIndex);
  }

  componentDidMount() {
    // Kickstart the calculated PcPR (pieces per rack)
    this.props.handlePiecesPerShippingUnitCalculationInputChange(this.props.productFieldIdentifier);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productIndex !== this.props.productIndex) {
      this.pprValidator = buildPPRValidator(nextProps.productIndex);
    }
  }

  handlePackSizeChange = event => {
    this.props.handlePiecesPerShippingUnitCalculationInputChange(this.props.productFieldIdentifier, 'packSize', event.target.value);
  }

  handlePacksPerShippingUnitChange = event => {
    this.props.handlePiecesPerShippingUnitCalculationInputChange(this.props.productFieldIdentifier, 'packsPerShippingUnit', event.target.value);
  }

  handlePacksPerShelfChange = event => {
    this.props.handlePacksPerShippingUnitCalculationInputChange(this.props.productFieldIdentifier, 'packsPerShelf', event.target.value);
  }

  handleShelvesPerRackChange = event => {
    this.props.handlePacksPerShippingUnitCalculationInputChange(this.props.productFieldIdentifier, 'shelvesPerRack', event.target.value);
  }

  render() {
    const props: FormProps = this.props;
    const { productFieldIdentifier, orderMethod, isProductSelected, productIds, handleProductChanged, disableProductField, showDeleteButton, handleDeleteProductClick, productIndex } = props;
    let parameterFields: JSX.Element = <div />;
    if (orderMethod === ShippingUnitOrderMethod) {
      parameterFields = (
        <FormGroup>
          <FieldWrapper
            table="customerOrderProducts"
            name={`${productFieldIdentifier}.packSize`}
            inputColSize={2}
            horizontalLabel={false}
            label="Pack"
            disabled={!isProductSelected}
            required={true}
            handleChange={this.handlePackSizeChange}
          />
          <FieldWrapper
            table="customerOrderProducts"
            name={`${productFieldIdentifier}.packsPerShelf`}
            inputColSize={2}
            horizontalLabel={false}
            handleChange={this.handlePacksPerShelfChange}
            label="PPS"
            disabled={!isProductSelected}
          />
          <FieldWrapper
            table="customerOrderProducts"
            name={`${productFieldIdentifier}.shelvesPerRack`}
            handleChange={this.handleShelvesPerRackChange}
            inputColSize={2}
            horizontalLabel={false}
            label="SPR"
            disabled={!isProductSelected}
          />
          <FieldWrapper
            table="customerOrderProducts"
            name={`${productFieldIdentifier}.packsPerShippingUnit`}
            inputColSize={2}
            horizontalLabel={false}
            label="PPR"
            required={true}
            disabled={!isProductSelected}
            validators={[Validators.REQUIRED, Validators.GREATER_THAN_ZERO, this.pprValidator]}
            handleChange={this.handlePacksPerShippingUnitChange}
          />
          <Field
            component={LabeledInput}
            name={`${productFieldIdentifier}.piecesPerShippingUnit`}
            type="number"
            disabled={true}
            label="Pieces Per Rack"
            testid="product-form-pieces-per-rack"
            labelColSize={3}
            inputColSize={3}
            offset={0}
            horizontalLabel={false}
            hideOptionalLabel={true}
          />
        </FormGroup>
      );
    } else if (orderMethod === PackOrderMethod) {
      parameterFields = (
        <FormGroup>
          <FieldWrapper
            table="customerOrderProducts"
            name={`${productFieldIdentifier}.packSize`}
            inputColSize={3}
            horizontalLabel={false}
            label="Pack"
            disabled={!isProductSelected}
            required={true}
          />
        </FormGroup>
      );
    }

    const productsFieldDisabled = disableProductField || (productIds?.size ?? 0) > 0;
    const products = props.products || [];
    return (
      <div>
        <Row>
          {showDeleteButton && <span className="mfc-simple-icon-button product-worksheet-product-form-delete-product-button" data-testid={`remove-product-${productIndex}`} onClick={handleDeleteProductClick}><span className="fa fa-trash" /></span>}
          <FormGroup>
            <Field
              name={`${productFieldIdentifier}.productId`}
              inputColSize={12}
              horizontalLabel={false}
              valueField="id"
              textFormatter={formatProductDisplayName}
              label="Product"
              placeholder="Select Product"
              component={LabeledSelect}
              testid="product-ids"
              handleChange={id => handleProductChanged(id, productFieldIdentifier)}
              required
              disabled={productsFieldDisabled}
              options={productsFieldDisabled ? products : products.filter(p => !p.activeStatus || p.activeStatus === ActiveInactive.Active)}
              autoFocus={productIndex === 0}
            />
          </FormGroup>
          {parameterFields}
        </Row>
      </div>
    );
  }
}

const handleAddProductClick = fields =>  () => fields.push({});
const onDeleteProductClick = (fields, index) => () => fields.remove(index);

export interface ProductsFormProps {
  savedProductIds: number[];
  orderMethod: OrderMethod;
  isProductSelectedList: boolean[];
  productIds?: Immutable.Set<number>;
  handleProductChanged: (productId: number, productFieldIdentifier: string) => void;
  handlePacksPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field: 'packsPerShelf' | 'shelvesPerRack', value: number) => void;
  handlePiecesPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field?: 'packSize' | 'packsPerShippingUnit', value?: number) => void;
  disableProductField: boolean;
  products: any[];
  showAddProductButton: boolean;
}

export class ProductsForm extends React.Component<WrappedFieldArrayProps & ProductsFormProps> {
  shouldComponentUpdate(nextProps: WrappedFieldArrayProps & ProductsFormProps) {
    return nextProps.fields.length !== this.props.fields.length ||
      nextProps.showAddProductButton !== this.props.showAddProductButton ||
      !isEqual(nextProps.isProductSelectedList, this.props.isProductSelectedList);
  }

  render() {
    const {
      fields                                           , orderMethod         , isProductSelectedList                             , productIds ,
      handleProductChanged                             , disableProductField , products                                          , showAddProductButton,
      handlePacksPerShippingUnitCalculationInputChange , savedProductIds     , handlePiecesPerShippingUnitCalculationInputChange ,
    } = this.props;

    const displayedProductIds = _.range(0, fields.length).map(index => fields.get(index).productId);
    const savedProductIdsRemainingOnForm = _.intersection(savedProductIds, displayedProductIds);
    return (
      <div>
        {fields.map((productFieldIdentifier, index) => {
          const currentProductId = fields.get(index).productId;
          const disabled = disableProductField ||
                  (fields.get(index).productId
                    ? savedProductIds.includes(currentProductId)
                    : false);

          const showDeleteButton = savedProductIdsRemainingOnForm.length !== 1 || !savedProductIdsRemainingOnForm.includes(currentProductId);
          return (
            <div key={index} data-testid={`product-form-${index}`}>
              <ProductForm
                productFieldIdentifier={productFieldIdentifier}
                orderMethod={orderMethod}
                isProductSelected={isProductSelectedList[index]}
                productIds={productIds}
                handleProductChanged={handleProductChanged}
                handleDeleteProductClick={onDeleteProductClick(fields, index)}
                products={products}
                disableProductField={disabled}
                productIndex={index}
                showDeleteButton={showDeleteButton}
                handlePacksPerShippingUnitCalculationInputChange={handlePacksPerShippingUnitCalculationInputChange}
                handlePiecesPerShippingUnitCalculationInputChange={handlePiecesPerShippingUnitCalculationInputChange}
              />
            </div>
          );
        })}
        {showAddProductButton &&
          <div className="text-center">
            <div className="mfc-form-button" data-testid="add-another-product" onClick={handleAddProductClick(fields)}>
              <span className="fa fa-plus-circle" /> Add Another Product
            </div>
          </div>
        }
      </div>
    );
  }
}
