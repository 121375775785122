import * as React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import gqlTag from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TYPES, FilterOption, BooleanFilters, SEARCH_FIELD_ANY, CELL_TYPES, DateStr } from 'shared/types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { send } from 'shared/send';
import { RoutingLoadSortInput, RoutingLoadGQLResult, GetRoutingLoadsInput, RoutingLoadFilterInput, RoutingLoadSearchInput, RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { ActiveSort, ActiveSearch, ActiveFilter, AvailableFilter, AvailableSearchField } from 'client/types';
import { TRANSPORTATION_LOAD_LIST_TABLE_NAME, EMPTY_ARRAY } from 'client/constants';
import { mapToSort, mapToFilters, convertToTypedSearchCriteriaForTransit } from 'client/helpers/table-helpers';
import * as FileSaver from 'client/utils/filesaver';
import { propToComponent, withPropsInjector } from 'client/hoc/hoc';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { buildTableStateModule } from 'client/state/tables';
import * as RoutePlanPrintingActions from 'client/actions/route-plan-printing';
import * as LoadListActions from 'client/actions/load-list';
import * as MutationActions from 'client/actions/mutations';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import PageTitle from 'client/components/page-title';
import { IColumn } from 'client/components/table/column';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { buildFilterableTable } from 'client/containers/table/table-filter-container';
import ApplyInvoiceToLoadsModal from 'client/app/transportation/loads/apply-invoice-to-loads-modal/apply-invoice-to-loads-modal-container';
import AssignLoadsToTrailersModal from 'client/app/transportation/loads/assign-loads-to-trailers-modal/assign-loads-to-trailers-modal-container';
import NotifyPeakModal from 'client/app/transportation/loads/notify-peak-modal/notify-peak-modal-container';
import CreateRoutePacketsModal from 'client/app/transportation/routing/route-plan-details/printing/create-route-packet-modal/create-route-packet-modal-container';
import DownloadDittyCardsModal from 'client/app/transportation/routing/route-plan-details/printing/download-ditty-card-modal/download-ditty-card-modal-container';

interface LoadListQueryResponse {
  getRoutingLoads?: {
    routingLoads: RoutingLoadGQLResult[];
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

interface LoadListAvailableFilterQueryResponse {
  filterOptions: {
    customers: FilterOption[];
    mfcAreas: FilterOption[];
    loadTypes: FilterOption[];
    routeTypes: FilterOption[];
    carriers: FilterOption[];
  };
}

const LoadListFragment = gqlTag`
  fragment LoadListFragment on RoutingLoad {
    id
    identifier
    deliveryDate
    loadType {
      rawValue
      displayValue
    }
    customerNames
    mfcAreaIdentifiers
    routeType {
      rawValue
      displayValue
    }
    carrier {
      rawValue
      displayValue
    }
    milesCombinedWithAdditionalMiles
    dropsCombinedWithAdditionalDrops
    racks
    pallets
    trailerNumber
    totalFee
    invoiceNumber
    sentToPeak
    peakOrderNumber
    totalFuelSurcharge # This field is needed for the excel download, but does not show up on the load list
  }
`;

type LoadListTableQueryInput = GetRoutingLoadsInput;
const LoadListTableQuery = gqlTag`
  query LoadListTableQuery($sort: [SortInput!], $filters: RoutingLoadFilterInput, $search: RoutingLoadSearchInput, $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput) {
    getRoutingLoads: GetRoutingLoads(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routingLoads {
        ...LoadListFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
  ${LoadListFragment}
`;

const LoadListTableExcelQuery = gqlTag`
  query LoadListTableExcelQuery($sort: [SortInput!], $filters: RoutingLoadFilterInput, $search: RoutingLoadSearchInput, $scope: RoutingLoadScopeInput) {
    getRoutingLoads: GetRoutingLoads(sort: $sort, filters: $filters, search: $search, scope: $scope) {
      routingLoads {
        ...LoadListFragment
      }
    }
  }
  ${LoadListFragment}
`;

const LoadListTableAvailableFiltersQuery = gqlTag`
  query LoadListTableAvailableFiltersQuery {
    filterOptions: GetRoutingLoadsFilterOptions {
      customers {
        id
        value
        displayValue
      }
      mfcAreas {
        id
        value
        displayValue
      }
      loadTypes {
        id
        value
        displayValue
      }
      routeTypes {
        id
        value
        displayValue
      }
      carriers {
        id
        value
        displayValue
      }
    }
  }
`;


const downloadTrailersListPdf = async ({selectedRoutingLoadIds}: { selectedRoutingLoadIds: number[], }) =>
  FileSaver.saveAs(await send({ fetch, url: `/report/trailersList`, method: 'POST', asBlob: true, data: { selectedRoutingLoadIds } }), `Trailers List.pdf`);

const tableName = TRANSPORTATION_LOAD_LIST_TABLE_NAME;
interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

const TableStateHelpers = buildTableStateModule(tableName);
const mapStateToProps = (state): StateProps => TableStateHelpers.commonTableProps(state);
interface WithAvailableFiltersProps { availableFilters: AvailableFilter[]; }
interface WithSearchableFieldsProps { searchableFields: AvailableSearchField[]; }

const withSearchableFields = withPropsInjector({
  searchableFields: [
    { id: SEARCH_FIELD_ANY, name: 'Search All' },
    { id: 'identifier', name: 'Load ID' },
    { id: 'trailerNumber', name: 'Trailer' },
    { id: 'invoiceNumber', name: 'Invoice' },
    { id: 'storeIdentifiers', name: 'Store' },
    { id: 'peakOrderNumber', name: 'Peak #' },
  ] as AvailableSearchField[],
});

interface DispatchProps {
  onRowSelect(record): void;
  onCreateRoutePacketMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadDittyCardMenuItemClicked(id: RoutingLoadId[]): void;
  onApplyInvoiceToLoadsMenuItemClicked(id: RoutingLoadId[]): void;
  onAssignLoadsToTrailersMenuItemClicked(id: RoutingLoadId[]): void;
  onNotifyPeakMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadPeakTrailersMenuItemClicked(id: RoutingLoadId[]): void;
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onRowSelect: record => dispatch(push(`/transportation/loads/details/${record.id}`)),
  onCreateRoutePacketMenuItemClicked: (routingLoadIds: RoutingLoadId[]) => dispatch(RoutePlanPrintingActions.createRoutePacketMenuItemClicked(routingLoadIds)),
  onDownloadDittyCardMenuItemClicked: (routingLoadIds: RoutingLoadId[]) => dispatch(RoutePlanPrintingActions.downloadDittyCardMenuItemClicked(routingLoadIds)),
  onApplyInvoiceToLoadsMenuItemClicked: (routingLoadIds: RoutingLoadId[]) => dispatch(LoadListActions.showApplyInvoiceToLoadsModal(routingLoadIds)),
  onAssignLoadsToTrailersMenuItemClicked: (routingLoadIds: RoutingLoadId[]) => dispatch(LoadListActions.showAssignLoadsToTrailersModal(routingLoadIds)),
  onNotifyPeakMenuItemClicked: (routingLoadIds: RoutingLoadId[]) => dispatch(LoadListActions.showNotifyPeakModal(routingLoadIds)),
  onDownloadPeakTrailersMenuItemClicked: async (routingLoadIds: RoutingLoadId[]) => {
    dispatch(MutationActions.showBusyModal());
    try {
      await downloadTrailersListPdf({ selectedRoutingLoadIds: routingLoadIds });
    } finally {
      dispatch(MutationActions.hideBusyModal());
    }
  },
});

interface WithRoutingLoadRowProps {
  rows?: LoadsTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithRoutingLoadRows = msyncQuery<LoadListQueryResponse, OwnProps & StateProps & WithTableParentProps & WithSearchableFieldsProps, WithRoutingLoadRowProps, LoadListTableQueryInput>(LoadListTableQuery, {
  alias: 'withRoutingLoadRows',
  skip(ownProps: OwnProps & StateProps & WithTableParentProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps): { variables: LoadListTableQueryInput, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: mapToFilters<RoutingLoadFilterInput>(ownProps.activeFilters),
        sort: mapToSort<RoutingLoadSortInput>(ownProps.activeSortFields),
        search: convertToTypedSearchCriteriaForTransit<RoutingLoadSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithRoutingLoadRowProps {
    const { data } = props;
    const { ownProps } = props;
    const workbookName = 'Load List';
    const rows: LoadsTableRow[] = data.getRoutingLoads
      ? data.getRoutingLoads.routingLoads.map(gqlResult => {
        return {
          id: gqlResult.id,
          identifier: gqlResult.identifier,
          deliveryDate: gqlResult.deliveryDate,
          loadType: gqlResult.loadType,
          customerNames: gqlResult.customerNames,
          mfcAreaIdentifiers: gqlResult.mfcAreaIdentifiers,
          routeType: gqlResult.routeType,
          carrier: gqlResult.carrier,
          milesCombinedWithAdditionalMiles: _.round(gqlResult.milesCombinedWithAdditionalMiles),
          dropsCombinedWithAdditionalDrops: gqlResult.dropsCombinedWithAdditionalDrops || 0,
          racks: gqlResult.racks,
          pallets: gqlResult.pallets,
          trailerNumber: gqlResult.trailerNumber,
          totalFee: gqlResult.totalFee,
          invoiceNumber: gqlResult.invoiceNumber,
          sentToPeak: gqlResult.sentToPeak,
          peakOrderNumber: gqlResult.peakOrderNumber,
        };
      })
      : [];

    return {
      rows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getRoutingLoads ? data.getRoutingLoads.ids : EMPTY_ARRAY,
      totalCount: data.getRoutingLoads ? data.getRoutingLoads.totalCount : 0,
      totalUnfilteredCount: data.getRoutingLoads ? data.getRoutingLoads.totalUnfilteredCount : 0,
      dataRequest: {
        operationName: '',
        query: LoadListTableExcelQuery,
        variables: {
          sort: mapToSort<RoutingLoadSortInput>(ownProps.activeSortFields),
          filters: mapToFilters<RoutingLoadFilterInput>(ownProps.activeFilters),
          search: convertToTypedSearchCriteriaForTransit<RoutingLoadSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
        },
        workbookName,
      },
    };
  },
});

const INVOICED_OPTIONS = [
  { id: BooleanFilters.Yes, value: BooleanFilters.Yes, displayValue: 'Yes' },
  { id: BooleanFilters.No, value: BooleanFilters.No, displayValue: 'No' },
];

const WithRoutingLoadAvailableFilters = msyncQuery<LoadListAvailableFilterQueryResponse, OwnProps & WithTableParentProps, WithAvailableFiltersProps>(LoadListTableAvailableFiltersQuery, {
  alias: 'withRoutingLoadAvailableFilters',
  skip: (ownProps: OwnProps & WithTableParentProps) => !ownProps.tableParentInfo.rowsPerPage,
  options: ownProps => ({ fetchPolicy: 'cache-first' }),
  props: ({ data }): WithAvailableFiltersProps => ({
    availableFilters: [
      { displayName: 'Customer', field: 'customer', options: data.filterOptions ? data.filterOptions.customers : EMPTY_ARRAY },
      { displayName: 'MFC Area', field: 'mfcArea', options: data.filterOptions ? data.filterOptions.mfcAreas : EMPTY_ARRAY },
      { displayName: 'Load Type', field: 'loadType', options: data.filterOptions ? data.filterOptions.loadTypes : EMPTY_ARRAY },
      { displayName: 'Route Type', field: 'routeType', options: data.filterOptions ? data.filterOptions.routeTypes : EMPTY_ARRAY },
      { displayName: 'Carrier', field: 'carrier', options: data.filterOptions ? data.filterOptions.carriers : EMPTY_ARRAY },
      { displayName: 'Invoiced', field: 'invoiced', options: INVOICED_OPTIONS },
      { displayName: 'Delivery Date', field: 'deliveryDate', type: TYPES.DATE },
    ],
  }),
});

interface LoadsTableRow {
  id: number;
  identifier: string;
  deliveryDate: DateStr;
  loadType: { rawValue: string, displayValue: string };
  customerName?: string;
  mfcAreaIdentifiers?: string;
  routeType: { rawValue: string, displayValue: string };
  carrier: { rawValue: string, displayValue: string };
  milesCombinedWithAdditionalMiles: number;
  dropsCombinedWithAdditionalDrops: number;
  racks: number;
  pallets: number;
  trailerNumber: string;
  totalFee: number;
  invoiceNumber?: string;
  sentToPeak: boolean;
  peakOrderNumber: string | null;
}

interface OwnProps {
  rows?: LoadsTableRow[];
  loading: boolean;
  totalCount: number;
  filteredRecordIds: number[];
  totalUnfilteredCount: number;
  tableParentInfo: TableParentInfo;
  onRowSelect(record: { id: number }): void;
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
  searchableFields: AvailableSearchField[];
  onCreateRoutePacketMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadDittyCardMenuItemClicked(id: RoutingLoadId[]): void;
  onApplyInvoiceToLoadsMenuItemClicked(id: RoutingLoadId[]): void;
  onAssignLoadsToTrailersMenuItemClicked(id: RoutingLoadId[]): void;
  onNotifyPeakMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadPeakTrailersMenuItemClicked(id: RoutingLoadId[]): void;
}

const columns: IColumn[] = [
  { id: 'identifier'             , accessor: 'identifier'                       , header: 'Load ID'              , tableEditable: false, columnWidth: 5, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'deliveryDate'           , accessor: 'deliveryDate'                     , header: 'Delivery Date'        , tableEditable: false, columnWidth: 7, sortable: true , cellType: CELL_TYPES.DATE             , type: TYPES.DATE    },
  { id: 'customerNames'          , accessor: 'customerNames'                    , header: 'Customer'             , tableEditable: false, columnWidth: 10, sortable: true , cellType: CELL_TYPES.TEXT            , type: TYPES.STRING  },
  { id: 'mfcAreaIdentifiers'     , accessor: 'mfcAreaIdentifiers'               , header: 'MFC Area'             , tableEditable: false, columnWidth: 6, sortable: false, cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'loadType.displayValue'  , accessor: 'loadType.displayValue'            , header: 'Load Type'            , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'routeType.displayValue' , accessor: 'routeType.displayValue'           , header: 'Route Type'           , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'carrier.displayValue'   , accessor: 'carrier.displayValue'             , header: 'Carrier'              , tableEditable: false, columnWidth: 5, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'peakOrderNumber'        , accessor: 'peakOrderNumber'                  , header: 'Peak #'               , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'trailerNumber'          , accessor: 'trailerNumber'                    , header: 'Trailer'              , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'miles'                  , accessor: 'milesCombinedWithAdditionalMiles' , header: 'Miles'                , tableEditable: false, columnWidth: 5, sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER , type: TYPES.NUMBER  },
  { id: 'drops'                  , accessor: 'dropsCombinedWithAdditionalDrops' , header: 'Stops'                , tableEditable: false, columnWidth: 3, sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER , type: TYPES.NUMBER  },
  { id: 'racks'                  , accessor: 'racks'                            , header: 'Racks'                , tableEditable: false, columnWidth: 3, sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER , type: TYPES.NUMBER  },
  { id: 'pallets'                , accessor: 'pallets'                          , header: 'Pallets'              , tableEditable: false, columnWidth: 3, sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER , type: TYPES.NUMBER  },
  { id: 'totalFee'               , accessor: 'totalFee'                         , header: 'Total Fee'            , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.MONEY            , type: TYPES.FLOAT   },
  { id: 'invoiceNumber'          , accessor: 'invoiceNumber'                    , header: 'Invoice'              , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.TEXT             , type: TYPES.STRING  },
  { id: 'sentToPeak'             , accessor: 'sentToPeak'                       , header: 'Peak Notified'        , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.YES_NO_NULL      , type: TYPES.BOOLEAN },
];

const excelColumns = columns.concat([
  { id: 'totalFuelSurcharge'     , accessor: 'totalFuelSurcharge'               , header: 'Total Fuel Surcharge' , tableEditable: false, columnWidth: 6, sortable: true , cellType: CELL_TYPES.MONEY            , type: TYPES.FLOAT   },
]);

const noop = () => {/**/};

const LoadsTableUI = p => {
  const headerMenuItems: RowMenuItem[] = [
    { label: 'Create Route Packets'     , onClick: p.onCreateRoutePacketMenuItemClicked    , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Download Ditty Cards'     , onClick: p.onDownloadDittyCardMenuItemClicked    , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Apply Invoice to Loads'   , onClick: p.onApplyInvoiceToLoadsMenuItemClicked  , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Assign Trailers'          , onClick: p.onAssignLoadsToTrailersMenuItemClicked, uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Notify Peak OK to Invoice', onClick: p.onNotifyPeakMenuItemClicked           , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Download Peak Trailers'   , onClick: p.onDownloadPeakTrailersMenuItemClicked , uncheckRecordFollowingClick: false, willRemove: false },
  ];

  const rowMenuItems: RowMenuItem[] = [
    { label: 'Create Route Packet'      , onClick: p.onCreateRoutePacketMenuItemClicked    , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Download Ditty Cards'     , onClick: p.onDownloadDittyCardMenuItemClicked    , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Apply Invoice to Load'    , onClick: p.onApplyInvoiceToLoadsMenuItemClicked  , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Assign Trailers'          , onClick: p.onAssignLoadsToTrailersMenuItemClicked, uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Notify Peak OK to Invoice', onClick: p.onNotifyPeakMenuItemClicked           , uncheckRecordFollowingClick: false, willRemove: false },
    { label: 'Download Peak Trailers'   , onClick: p.onDownloadPeakTrailersMenuItemClicked , uncheckRecordFollowingClick: false, willRemove: false },
  ];

  const [FilterableTable] = React.useState(() => buildFilterableTable(tableName));
  return (
    <div>
      <PageTitle title="Load List"/>
      <CreateRoutePacketsModal />
      <DownloadDittyCardsModal />
      <ApplyInvoiceToLoadsModal />
      <AssignLoadsToTrailersModal />
      <NotifyPeakModal />
      <FilterableTable
        table={tableName}
        content={p.rows || EMPTY_ARRAY}
        loading={p.loading}
        columns={columns}
        excelColumns={excelColumns}
        totalCount={p.totalCount}
        totalUnfilteredCount={p.totalUnfilteredCount}
        filteredRecordIds={p.filteredRecordIds}
        refetchTable={noop}
        loadMoreRecords={noop}
        searchableFields={p.searchableFields}
        availableFilters={p.availableFilters}
        placeholder="No Loads"
        checkable
        list
        disableCreate
        tablePaginated
        tableParentInfo={p.tableParentInfo}
        displayLoadingIndicator
        onRowSelect={p.onRowSelect}
        dataRequest={p.dataRequest}
        rowMenuItems={rowMenuItems}
        headerMenuItems={headerMenuItems}
      />
    </div>
  );
};

interface WithFilterAndSortCapabilitiesProps { onTableSort: (field: string) => void; }
interface WithTableParentProps { tableParentInfo: TableParentInfo; }
export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithRoutingLoadRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithAvailableFiltersProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;
assertCompatible<OwnProps, ComponentProps>();
const component = _.flowRight(
  tableParentHoc(),
  withSearchableFields,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(tableName),
  WithRoutingLoadRows,
  WithRoutingLoadAvailableFilters,
)(LoadsTableUI) as Component<OwnProps>;

export const LoadList = (props: OwnProps) => propToComponent(component, props);
