import * as React from 'react';
import { Form } from 'client/components/third-party';
import * as Constants from './constants';
import { SelectableRow } from 'client/components/selectable/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ImmutableDateRange } from 'shared/types/date-range';
import { DateStr, ReportTypes } from 'shared/types';
import * as Components from '../shared/components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { DropDownOptions } from 'client/types';

export interface ComponentProps {
  handleDownloadExcelReportClicked(): void;

  customerOptions?: SelectableRow[];
  customersLoading?: boolean;

  supplierOptions: DropDownOptions;
  suppliersLoading?: boolean;

  supplierOrderOptions?: SelectableRow[];
  supplierOrdersLoading?: boolean;

  downloadButtonDisabled: boolean;

  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;

  handleReportTypeChanged: (key: string) => void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => void;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportType: Constants.HmgOpsReportType;
  reportDownloadStatus: MutationStatus;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class HmgOpsUI extends React.PureComponent<ComponentProps, {}> {

  render() {
    return (
      <div>
        <RecordBar title="HMG Ops Reports" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-hmg-ops-table-wrapper">
            <Form horizontal>
              <Components.Customer
                name={Constants.FormFields.customerIds}
                options={this.props.customerOptions}
                loading={this.props.customersLoading}
              />
              <Components.Supplier
                name={Constants.FormFields.supplierId}
                options={this.props.supplierOptions}
                loading={this.props.suppliersLoading}
              />
              <Components.DateRange
                handleChange={this.props.handleDateRangeChanged}
                beginDate={this.props.beginDate}
                endDate={this.props.endDate}
                label="Planned Arrival Date"
              />
              <Components.SupplierOrder
                name={Constants.FormFields.supplierOrderIds}
                options={this.props.supplierOrderOptions}
                loading={this.props.supplierOrdersLoading}
              />
            </Form>
          </div>
          <Components.HmgOpsSidebar
            reportType={ReportTypes.HmgOps}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            handleHmgOpsReportTypeChanged={this.props.handleReportTypeChanged}
            selectedHmgOpsReportType={this.props.reportType}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}
