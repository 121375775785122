import { appendFieldValidators } from 'shared/schemas/dsl';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';
import { GREATER_THAN_ZERO, MAX_LENGTH, ORDER_PRODUCT_GROUP_IDENTIFIER_HAS_CORRECT_PREFIX } from 'shared/validators';

appendFieldValidators(CustomerOrderProductGroup, {
  packsPerShippingUnit: [GREATER_THAN_ZERO],
  identifier: [
    ORDER_PRODUCT_GROUP_IDENTIFIER_HAS_CORRECT_PREFIX,
    MAX_LENGTH(2),
  ],
});
