import * as Validators from 'shared/validators';
import { tableName, property, belongsTo, definePresentation, required, defineFieldValidators, setSchemaOptions } from './dsl';
import { Product, ProductId } from './product';
import { Store, StoreId } from './store';
import { DateStr, KnownAdjustmentType, DISPLAY_TYPES, TYPES, INPUT_TYPES, SORT_TYPES } from 'shared/types';
import { displayType } from './dsl';
import { Customer } from 'shared/schemas/customer';
import { Market } from 'shared/schemas/market';
import { Region } from 'shared/schemas/region';
import { CustomerDisplayValueResolver, RegionDisplayValueResolver, StoreDisplayValueResolver, ProductDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('productToss')
export class ProductToss {
  id?: number;

  @belongsTo('customers', { through: 'products', foreignQueryKeys: ['name'], foreignDisplayKey: 'name' })
  @property @required customer: Customer;

  @belongsTo('stores')
  @property @required store: Store;
  storeId: StoreId;

  @belongsTo('products', { foreignQueryKeys: ['identifier', 'description']})
  @property @required product: Product;
  productId: ProductId;

  @property sku?: string | null;

  @property @required weekEndingDate: DateStr;
  @property @required quantity: number;

  @property adjustmentType?: KnownAdjustmentType | null;
  @property enteredBy?: string | null;
  @property merchandiser?: string | null;

  @belongsTo('markets', { through: 'stores' })
  @property market: Market;
  marketId: number;

  @belongsTo('regions', { through: 'markets' })
  @property @required region: Region;
  regionId: number;
}

defineFieldValidators(ProductToss, {
  adjustmentType: [Validators.INCLUSION(KnownAdjustmentType)],
});

definePresentation(ProductToss, {
  customer: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    searchable: false,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    columnWidth: 40,
    required: true,
  },
  market: {
    formDisplay: false,
    includeInFormQuery: true,
  },
  region: {
    filterable: false,
    sortable: true,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: RegionDisplayValueResolver }),
    columnWidth: 20,
    tableDisplayColumns: { identifier: 'Region' },
  },
  product: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver }),
    columnWidth: 10,
    tableDisplayColumns: { identifier: 'Product' },
    required: true,
  },
  store: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    searchable: false,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: StoreDisplayValueResolver }),
    columnWidth: 15,
    tableDisplayColumns: { identifier: 'Store' },
    required: true,
  },
  weekEndingDate: {
    displayName: 'Date',
    filterable: true,
    sortable: true,
    tableDisplay: true,
    searchable: false,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
    required: true,
  },
  quantity: {
    sortable: true,
    tableDisplay: true,
    searchable: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 10,
    type: TYPES.NUMBER,
    required: true,
  },
  sku: {
    displayName: 'SKU',
    tableDisplay: true,
    sortable: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {
        product: ['sku'],
      },
      calculation: () => `
      (SELECT COALESCE(pt.sku, products.sku)
      FROM product_toss pt
      JOIN products ON products.id = pt.product_id
      WHERE pt.id = product_toss.id)
      `,
    },
  },
  enteredBy: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  adjustmentType: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  merchandiser: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
});

setSchemaOptions(ProductToss, {
  defaultSort: { sortOrder: SORT_TYPES.DESC, sortField: 'weekEndingDate' },
});
