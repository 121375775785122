import gql from 'graphql-tag';

export const ReceivingQuery = gql`
  query ReceivingQuery($filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    response: GetUnifiedReceivableOrders(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      unifiedReceivableOrders {
        id
        identifier
        supplierVendorIdentifier
        supplierVendorName
        supplierLocationIdentifier
        customerIdentifier
        customerName
        mfcAreaIdentifier
        sellDepartmentIdentifier
        orderType
        arrivalDate
        storeDeliveryDate
        receivingStatus
        orderMethod
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
`;

export const ReceivingExcelQuery = gql`
  query ReceivingExcelQuery($filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    response: GetUnifiedReceivableOrders(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      unifiedReceivableOrders {
        id
        identifier
        supplierVendorName
        supplierLocationIdentifier
        customerName
        mfcAreaIdentifier
        sellDepartmentIdentifier
        orderType
        arrivalDate
        storeDeliveryDate
        receivingStatus
        orderMethod
      }
    }
  }
`;

export const FilterableQuery = gql`
  query GetUnifiedReceivableOrdersFilterOptionsQuery {
    response: GetUnifiedReceivableOrdersFilterOptions {
      supplierVendors {
        id
        value
        displayValue
      }
      supplierLocations {
        id
        value
        displayValue
      }
      customers {
        id
        value
        displayValue
      }
      orderTypes {
        id
        value
        displayValue
      }
      sellDepartments {
        id
        value
        displayValue
      }
      mfcAreas {
        id
        value
        displayValue
      }
      receivingStatuses {
        id
        value
        displayValue
      }
    }
  }
`;
