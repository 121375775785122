import * as _ from 'lodash';
import { SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';

export const saveConfirmationMessageFunction = (record?: SaveConfirmationDialogInput) => {
  if (_.isNil(record)) {
    return {
      title: '',
      body: '',
    };
  }

  if (record.ediInvoiced && record.transferredToAccounting) {
    return {
      title: 'Invoice Sent via EDI and Sent to Acumatica',
      body: 'This invoice has been sent to the customer via EDI and sent to Acumatica. Be sure to communicate these changes with others who may be affected.',
    };
  } else if (record.transferredToAccounting && record.invoiceEmailed) {
    return {
      title: 'Invoice Emailed and Sent to Acumatica',
      body: `This invoice has been emailed and sent to Acumatica. Be sure to communicate these changes with others who may be affected.`,
    };
  } else if (record.transferredToAccounting && record.invoiceDownloaded) {
    return {
      title: 'Invoice Downloaded and Sent to Acumatica',
      body: 'This invoice has been downloaded and sent to Acumatica. Be sure to communicate these changes with others who may be affected.',
    };
  } else if (record.transferredToAccounting) {
    return {
      title: 'Invoice Sent to Acumatica',
      body: 'This invoice has been sent to Acumatica. Be sure to communicate these changes with others who may be affected.',
    };
  } else if (record.ediInvoiced) {
    return {
      title: 'Invoice Sent via EDI',
      body: 'This invoice has been sent to the customer via EDI. Be sure to communicate these changes with others who may be affected.',
    };
  } else if (record.invoiceEmailed) {
    return {
      title: 'Invoice Emailed',
      body: 'This invoice has been emailed. Be sure to communicate these changes with others who may be affected.',
    };
  } else if (record.invoiceDownloaded) {
    return {
      title: 'Invoice Downloaded',
      body: 'This invoice has been downloaded to PDF. Be sure to communicate these changes with others who may be affected.',
    };
  } else {
    return {
      title: '',
      body: '',
    };
  }
};

interface SaveConfirmationDialogInput {
  ediInvoiced: boolean;
  transferredToAccounting: boolean;
  invoiceDownloaded: boolean;
  invoiceEmailed: boolean;
 }

export const saveConfirmationOptionsForInvoice: SaveConfirmationArgs<any> = {
  onlyAskOnce: true,
  shouldDisplay: (record?: SaveConfirmationDialogInput) => record ? (record.ediInvoiced || record.invoiceDownloaded || record.invoiceEmailed || record.transferredToAccounting) : false,
  message: saveConfirmationMessageFunction,
};

export type ConfirmOkToSaveFunction = () => Promise<boolean>;
