import { reduxForm, getFormValues, reset, change } from 'redux-form';
import { flowRight } from 'lodash';
import gql from 'graphql-tag';
import { connect, MapDispatchToProps, MapDispatchToPropsFactory } from 'react-redux';
import * as Actions from 'client/actions/cart-tracking';
import { propToComponent } from 'client/hoc/hoc';
import AddNewTransactionModal, { AddNewTransactionType } from './add-new-transaction-modal';
import { CartTrackingState } from 'client/reducers/cart-tracking';
import { SupplierId } from 'shared/schemas/supplier';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { MutationStatus } from 'client/actions/mutations';
import { CartTrackingSupplierEntryType, CartTrackingSupplierEntryQuantity, CartTrackingSupplierEntryNotes } from 'shared/schemas/cart-tracking-supplier-entry';
import { DateStr } from 'shared/types';
import { Clock } from 'shared/clock';

const formName = 'AddNewCartTrackingTransactionForm';

export interface OwnProps {
  supplierId: number;
  isModal?: boolean;
}

interface StateProps {
  isShown: boolean;
  actionStatus: MutationStatus;
  quantity: number;
  notes?: string;
  transactionType: AddNewTransactionType;
  entryDate: DateStr;
}

const mapStateToProps = (state: { cartTracking: CartTrackingState }, ownProps: OwnProps): StateProps => {
  const formValues = getFormValues(formName)(state) as FormValues;
  return {
    isShown: state.cartTracking.addNewTransactionModalShown,
    actionStatus: state.cartTracking.addNewTransactionActionStatus,
    quantity: formValues && formValues.quantity,
    notes: formValues && formValues.notes,
    transactionType: formValues && formValues.transactionType,
    entryDate: formValues && formValues.entryDate,
  };
};

interface FormValues {
  quantity: number;
  notes?: string;
  transactionType: AddNewTransactionType;
  entryDate: DateStr;
}

interface DispatchProps {
  handleCancelButtonClicked: () => void;
  handleFormSubmit: () => Promise<any>;
  onModalEnter: () => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> | MapDispatchToPropsFactory<DispatchProps, OwnProps> = (dispatch, ownProps: OwnProps & StateProps & MutationProps & ReduxFormProps): DispatchProps => {
  return {
    onModalEnter: () => dispatch(change(formName, 'entryDate', Clock.today())),
    handleCancelButtonClicked: () => {
      dispatch(Actions.setAddNewTransactionModalVisibility(false));
      dispatch(reset(formName));
    },
    handleFormSubmit: () => {
      return ownProps.handleSubmit(async (formValues: FormValues) => {

        dispatch(Actions.setAddNewTransactionActionStatus(MutationStatus.InProgress));

        let entryType: CartTrackingSupplierEntryType;
        let quantity: number = formValues.quantity;

        switch (formValues.transactionType) {
          case AddNewTransactionType.BalanceReset:
            entryType = CartTrackingSupplierEntryType.Balance;
            break;
          case AddNewTransactionType.Delivered:
            quantity = -formValues.quantity;
            entryType = CartTrackingSupplierEntryType.Adjustment;
            break;
          case AddNewTransactionType.Returned:
          default:
            entryType = CartTrackingSupplierEntryType.Adjustment;
            break;
        }

        try {
          await ownProps.addNewTransaction({
            supplierId: ownProps.supplierId,
            entryType,
            quantity,
            notes: formValues.notes,
            entryDate: formValues.entryDate,
          });
          dispatch(Actions.setAddNewTransactionModalVisibility(false));
          dispatch(reset(formName));
        } finally {
          dispatch(Actions.setAddNewTransactionActionStatus(MutationStatus.Initial));
        }
      });
    },
  };
};

const MUTATION = gql`
mutation addCartTrackingSupplierEntry($supplierId: Int!, $entryType: String!, $quantity: Int!, $notes: String, $entryDate: DateStr) {
  addCartTrackingSupplierEntry(supplierId: $supplierId, entryType: $entryType, quantity: $quantity, notes: $notes, entryDate: $entryDate) {
    ... on Supplier {
      id
      totalCarts
      cartTrackingStatus
      lastAdjustmentDate
      cartTrackingSupplierEntries {
        id
        quantity
        entryDate
        entryType
        notes
      }
    }
  }
}
`;

interface MutationProps {
  addNewTransaction: (args: { supplierId: SupplierId, entryType: CartTrackingSupplierEntryType, quantity: CartTrackingSupplierEntryQuantity, notes?: CartTrackingSupplierEntryNotes, entryDate?: DateStr }) => shame;
}

const withMutation = msyncMutation(MUTATION, {
  props: ({ mutate }): MutationProps => {
    return {
      addNewTransaction: (args: { supplierId: SupplierId, entryType: CartTrackingSupplierEntryType, quantity: CartTrackingSupplierEntryQuantity, notes?: CartTrackingSupplierEntryNotes, entryDate?: DateStr }) => mutate({
        variables: {
          supplierId: args.supplierId,
          entryType: args.entryType,
          quantity: args.quantity,
          notes: args.notes,
          entryDate: args.entryDate,
        },
      }),
    };
  },
});

interface ReduxFormProps {
  handleSubmit: shame;
}

export type ComponentProps = OwnProps & StateProps & DispatchProps & MutationProps;
type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps),
  withMutation,
  reduxForm({
    form: formName,
    enableReinitialize: true,
    initialValues: {
      quantity: '',
      transactionType: AddNewTransactionType.Delivered,
      notes: '',
    },
  }),
  connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(AddNewTransactionModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
