import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import gql from 'graphql-tag';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { DateStr } from 'shared/types';
import { ApolloRefetch, AvailableFilter, ActiveFilter, ActiveSearch, ActiveSort } from 'client/types';
import { withRecordFilterOptions } from 'client/hoc/graphql/record-filter-options';
import { FindAllResult } from 'shared/types/graphql-types';
import { removeRowsSelected } from 'client/actions/customer-order';
import { TableParentInfo, tableParentHoc } from 'client/components/table/table-parent';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';

export interface OwnProps {
  customerOrderId: CustomerOrderId;
  onNewClicked?(): void;
  tableName: string;
  disableCreate?: boolean;
  cached?: boolean;

  performDeletion?: (ids: number[]) => Promise<boolean>;

  isAttachSupplierOrdersModalShown: boolean;
  hideModal?(): void;

  confirmOkToSave: () => Promise<boolean>;
  dataRequest: MsyncDataRequest;

  displayLoadingIndicator?: boolean;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  const TableStateHelpers = buildTableStateModule(ownProps.tableName);
  return TableStateHelpers.commonTableProps(state);
};

interface DispatchProps {
  onRowSelect(): void;
  performRemove?(id: number[]): void;
}

const mapDispatchToProps = (dispatch, ownProps: OwnProps & WithRelatedSupplierOrdersProps): DispatchProps => {
  const performDeletion = ownProps.performDeletion;
  const refetchTable = ownProps.refetchTable;
  const tableName = ownProps.tableName;

  return {
    onRowSelect: () => { }, // eslint-disable-line no-empty
    performRemove: (ids: number[]) => {
      dispatch(removeRowsSelected({
        ids,
        removeFunction: performDeletion,
        refetchFunction: refetchTable,
        tableName,
      }));
    },
  };
};

interface WithFilterOptionsProps {
  availableFilters: AvailableFilter[];
}
const withFilterOptions = WrappedComponent => {
  const filters = ['supplier', 'customer', 'sellDepartment', 'mfcArea', 'plannedArrivalDate'];
  return withRecordFilterOptions({ table: 'supplierOrders', fields: filters })(WrappedComponent);
};

interface WithSearchableFieldsProps {
  searchableFields: shame;
}
const withSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [{ id: 'identifier', name: 'PO #' }],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface RelatedSupplierOrdersResult extends FindAllResult {
  records: Array<{
    id: number;
    identifier: string;
    supplierName: string;
    customerName: string;
    sellDepartmentIdentifier: string;
    mfcAreaIdentifier: string;
    relatedCustomerOrders: number;
    plannedArrivalDate: DateStr;
    pieceQuantity: number;
    totalCost: shame;
  }>;
}

interface RelatedSupplierOrdersQueryResponse {
  content?: RelatedSupplierOrdersResult;
}

const TABLE_QUERY = gql`
  query getSupplierOrdersWithSummary($customerOrderId: Int!, $relatedToCustomerOrder: Boolean! $filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    content: getSupplierOrdersWithSummary(customerOrderId: $customerOrderId, relatedToCustomerOrder: $relatedToCustomerOrder, filters: $filters, sort: $sort, search: $search, limit: $limit, offset: $offset) {
      records {
        id
        identifier
        supplierName
        customerName
        sellDepartmentIdentifier
        mfcAreaIdentifier
        relatedCustomerOrders
        plannedArrivalDate
        pieceQuantity
        totalCost
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
`;

interface WithRelatedSupplierOrdersProps {
  supplierOrders?: shame[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  refetchTable?: ApolloRefetch;
  loadMoreRecords: shame;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const WithRelatedSupplierOrders = (args: { isRelatedToCustomerOrder: boolean }) => {
  return msyncQuery<RelatedSupplierOrdersQueryResponse, OwnProps & StateProps & WithTableParentProps, WithRelatedSupplierOrdersProps>(TABLE_QUERY, {
    alias: 'withRelatedSupplierOrders',
    skip(ownProps) {
      // Don't do the query if we don't know how many rows to retrieve yet
      return !ownProps.tableParentInfo.rowsPerPage;
    },
    options(ownProps): { variables, fetchPolicy: WatchQueryFetchPolicy } {
      return {
        variables: {
          customerOrderId: ownProps.customerOrderId,
          relatedToCustomerOrder: args.isRelatedToCustomerOrder,
          search: ownProps.activeSearch,
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        fetchPolicy: ownProps.cached ? 'cache-and-network' : 'network-only',
      };
    },
    props({ data, ownProps }): WithRelatedSupplierOrdersProps {
      const content = data.content;
      return {
        supplierOrders: content ? content.records : undefined,
        loading: _.isNil(data.loading) ? true : data.loading,
        totalCount: content ? content.totalCount : 0,
        totalUnfilteredCount: content ? content.totalUnfilteredCount : 0,
        refetchTable: data.refetch,
        filteredRecordIds: content ? content.ids : [],
        loadMoreRecords: () => { }, // eslint-disable-line no-empty
        dataRequest: {
          operationName: 'getSupplierOrdersWithSummary',
          query: TABLE_QUERY,
          variables: data.variables,
          worksheetName: 'Sheet1',
          workbookName: 'Related Supplier POs',
        },
      };
    },
  });
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithRelatedSupplierOrdersProps &
  WithSearchableFieldsProps &
  WithFilterOptionsProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

export function makeComponent<P>(args: { component: new (props: P) => React.Component<P, any>, isInModal: boolean, isRelatedToCustomerOrder: boolean }) {
  const component = flowRight(
    tableParentHoc({ isInModal: args.isInModal }),
    withFilterOptions,
    withSearchableFields,
    connect<StateProps, DispatchProps, OwnProps>(mapStateToProps),
    WithRelatedSupplierOrders({ isRelatedToCustomerOrder: args.isRelatedToCustomerOrder }),
    connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
  )(args.component) as Component<OwnProps>;

  return (props: OwnProps) => propToComponent(component, props);
}
