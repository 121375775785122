import gql from 'graphql-tag';

export const ValidateImportProductPriceQuery = gql`
  query ValidateImportProductPriceQuery($input: ImportProductPriceInput!) {
    validateImportableProductPrice: ValidateImportableProductPrice(input: $input) {
      success
      errors
    }
  }
`;
