import * as Excel from '../common';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { WorkBookContainer } from 'shared/file-parsers/excel/common';
import { InternalImportableDistributionRackShippingLineItem, ImportableDistributionRackShippingLineItem } from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser/types';
import { ErrorWithData } from 'shared/errors/error-with-data';
import { notNil } from 'shared/helpers/andys-little-helpers';

export const GenericParsingError = `Uploaded file doesn't look like a valid Distribution Rack Shipping Worksheet.`;

enum ValidDistributionRackShippingWorkBook { }
type DistributionRackShippingWorkBook = XLSX.WorkBook & ValidDistributionRackShippingWorkBook;

enum ValidDistributionRackShippingWorkSheetType { }
type DistributionRackShippingWorkSheet = XLSX.WorkSheet & ValidDistributionRackShippingWorkSheetType;

enum ValidDistributionRackShippingWorkSheetDataType { }
type DistributionRackShippingWorkSheetData = Excel.WorkSheetData & ValidDistributionRackShippingWorkSheetDataType;

export async function getDistributionRackShippingWorkBookFromWorkBookContainer(workBookContainer: WorkBookContainer): Promise<DistributionRackShippingWorkBook | null> {
  return Excel.getWorkBookFromWorkBookContainer<DistributionRackShippingWorkBook>(workBookContainer);
}

export const getDistributionRackShippingWorkSheet = (workBook: DistributionRackShippingWorkBook) => Excel.getFirstWorkSheet<DistributionRackShippingWorkSheet>(workBook);
export const getDistributionRackShippingWorkSheetData = (workSheet: DistributionRackShippingWorkSheet) => Excel.getWorkSheetData<DistributionRackShippingWorkSheetData>(workSheet);

function getColIndexes(workSheetData: DistributionRackShippingWorkSheetData, rowIndex: number = 0) {
  const lastColumn = Excel.findLastColumn(workSheetData, rowIndex);
  if (!lastColumn) {
    throw new Error('Unable to find column indexes.');
  }

  return {
    storeNumberColIndex: 'A',
    adjustedRacksColIndex: lastColumn,
  };
}

const customerIdentifierWithStoreIdentifierRegExp = /^[A-Z]+\-[0-9]{1,4}$/;

const findFirstAllocationRow = (workSheetData: DistributionRackShippingWorkSheetData) => {
  const firstRow = _.uniq(workSheetData.map(workSheetRow => `${workSheetRow.A}`));
  return firstRow.findIndex(cell => customerIdentifierWithStoreIdentifierRegExp.test(cell));
};

function findLastAllocationRow(workSheetData: DistributionRackShippingWorkSheetData) {
  const firstRow = findFirstAllocationRow(workSheetData);

  for (let rowIndex = firstRow; rowIndex < workSheetData.length; rowIndex++) {
    if (rowIndex === workSheetData.length - 1) {
      if (!customerIdentifierWithStoreIdentifierRegExp.test(`${workSheetData[rowIndex].A}`)) {
        return rowIndex - 1;
      } else {
        return rowIndex;
      }
    }

    if (!workSheetData[rowIndex]) {
      throw new ErrorWithData([GenericParsingError]);
    }

    if (!customerIdentifierWithStoreIdentifierRegExp.test(`${workSheetData[rowIndex].A}`)) {
      return rowIndex - 1;
    }
  }

  throw new ErrorWithData([GenericParsingError]);
}

const getRowIndexes = (workSheetData: DistributionRackShippingWorkSheetData) => {
  return {
    firstRow: findFirstAllocationRow(workSheetData),
    lastRow: findLastAllocationRow(workSheetData),
  };
};

interface SuccessfulGetLineItems {
  success: true;
  lineItems: ImportableDistributionRackShippingLineItem[];
}
interface FailedGetLineItems {
  success: false;
  reason: string | string[];
}
type GetLineItemsResult = SuccessfulGetLineItems | FailedGetLineItems;

export function getLineItems(workSheetData: DistributionRackShippingWorkSheetData, storeIdentifiersInRoutePlan: string[]): GetLineItemsResult {
  const colIndexes = getColIndexes(workSheetData);
  const rowIndexes = getRowIndexes(workSheetData);

  const relevantRows = workSheetData.slice(rowIndexes.firstRow, rowIndexes.lastRow + 1);
  const errors: string[] = [];

  relevantRows
    .forEach(row => {
      const customerIdentifierWithStoreIdentifier = Excel.getAsString(row[colIndexes.storeNumberColIndex]);
      if (customerIdentifierWithStoreIdentifier) {
        if (!storeIdentifiersInRoutePlan.includes(customerIdentifierWithStoreIdentifier)) {
          errors.push(`Store ${customerIdentifierWithStoreIdentifier} in worksheet not found on this route plan.`);
        } else {
          const adjustedRacksCellValue = row[colIndexes.adjustedRacksColIndex];
          let adjustedRacksDecimalValue: number | undefined;

          if (!adjustedRacksCellValue) {
            errors.push(`The Adjusted Racks value for store ${customerIdentifierWithStoreIdentifier} must not be empty`);
          } else {
            adjustedRacksDecimalValue = Excel.getAsDecimal(adjustedRacksCellValue);
            if (adjustedRacksDecimalValue === undefined) {
              errors.push(`The Adjusted Racks value for store ${customerIdentifierWithStoreIdentifier} must be a number, but was "${adjustedRacksCellValue}"`);
            } else if (adjustedRacksDecimalValue < 0) {
              errors.push(`The Adjusted Racks value for store ${customerIdentifierWithStoreIdentifier} must be a non-negative number`);
            } else if (!Number.isInteger(adjustedRacksDecimalValue)) {
              errors.push(`The Adjusted Racks value for store ${customerIdentifierWithStoreIdentifier} must be a whole number`);
            }
          }
        }
      }
    });

  if (errors.length > 0) {
    return {
      success: false,
      reason: errors,
    };
  }

  const lineItems = relevantRows
    .map((row): InternalImportableDistributionRackShippingLineItem => {
      const customerIdentifierWithStoreIdentifier = Excel.getAsString(row[colIndexes.storeNumberColIndex]);
      if (customerIdentifierWithStoreIdentifier) {
        const [customerIdentifier, storeIdentifier] = customerIdentifierWithStoreIdentifier.split('-');

        const adjustedRacksCellValue = row[colIndexes.adjustedRacksColIndex];
        let adjustedRacksIntegerValue: Int | undefined;

        if (adjustedRacksCellValue) {
          adjustedRacksIntegerValue = Excel.getAsInteger(adjustedRacksCellValue);
        }

        return {
          customerIdentifier,
          storeIdentifier,
          adjustedRacks: adjustedRacksIntegerValue,
        };
      }

      return {};
    })
    .filter(lineItem => lineItem.customerIdentifier && lineItem.storeIdentifier && notNil(lineItem.adjustedRacks)) as ImportableDistributionRackShippingLineItem[];

  if (lineItems.length === 0) {
    return {
      success: false,
      reason: GenericParsingError,
    };
  }

  return {
    success: true,
    lineItems,
  };
}
