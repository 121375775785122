import * as React from 'react';
import { GroupedList, GroupedListHeading, GroupedListItem } from 'client/app/transportation/routing/route-plan-details/printing/grouped-list-component';
import CreateRoutePacketsModal from 'client/app/transportation/routing/route-plan-details/printing/create-route-packet-modal/create-route-packet-modal-container';
import DownloadDittyCardsModal from 'client/app/transportation/routing/route-plan-details/printing/download-ditty-card-modal/download-ditty-card-modal-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { MutationStatus } from 'client/actions/mutations';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { SendMerchandisersAsnModal } from 'client/app/transportation/routing/route-plan-details/printing/send-merchandisers-asn-modal';
import { DownloadStoreAsnModal } from 'client/app/transportation/routing/route-plan-details/printing/download-store-asn-modal';

export interface OwnProps {
  isLoading: boolean;
  routePlanId: RoutePlanId;
  hasLoads: boolean;
  actions: {
    createRoutePackets?: string;
    downloadDittyCards?: string;
    emailMerchandiserAsn?: string;
    downloadStoreAsn?: string;
    createCustomerAsn?: string;
    downloadStoreDeliverySpreadsheet?: string;
    sendLoadsToLoadmaster?: string;
    emailRouteSheetsToPeak?: string;
    createPalletReport?: string;
  };
  createRoutePacketDownloadPdfButtonClicked(): any;
  downloadDittyCardsPDFButtonClicked(routePlanId: RoutePlanId): any;
  sendMerchandiserEmailsButtonClicked(routePlanId: RoutePlanId): any;
  downloadStoreSpecificAdvanceShipNoticesPDFButtonClicked(routePlanId: RoutePlanId): any;
  downloadCustomerAdvanceShipNoticesPDFButtonClicked(routePlanId: RoutePlanId): any;
  downloadStoreDeliverySpreadsheetButtonClicked(routePlanId: RoutePlanId): any;
  sendLoadsToLoadMasterClicked(routePlanId: RoutePlanId): any;
  emailRouteSheetsToPeakClicked(routePlanId: RoutePlanId): any;
  downloadPalletReportCSVButtonClicked(routePlanId: RoutePlanId): any;

  sendLoadsToLoadMasterActionStatus: MutationStatus;
  emailRouteSheetsToPeakActionStatus: MutationStatus;
  downloadStoreAdvanceShipNoticeActionStatus: MutationStatus;
  createCustomerAdvanceShipNoticeActionStatus: MutationStatus;
  downloadStoreDeliverySpreadsheetActionStatus: MutationStatus;
  downloadPalletReportActionStatus: MutationStatus;

  createRoutePacketDownloadPdfButtonDisabled: boolean;
  downloadDittyCardsPDFButtonDisabled: boolean;
  sendMerchandiserEmailsButtonDisabled: boolean;
  downloadStoreAdvanceShipNoticesPDFButtonDisabled: boolean;
  downloadCustomerAdvanceShipNoticesPDFButtonDisabled: boolean;
  downloadStoreDeliverySpreadsheetButtonDisabled: boolean;
  sendLoadsToLoadMasterDisabled: boolean;
  emailRouteSheetsToPeakDisabled: boolean;
  downloadPalletReportCSVButtonDisabled: boolean;
}

export class PrintingListUI extends React.PureComponent<OwnProps, {}> {
  downloadRoutePacketsPDF = () => {
    this.props.createRoutePacketDownloadPdfButtonClicked();
  }

  downloadDittyCardsPDF = () => {
    this.props.downloadDittyCardsPDFButtonClicked(this.props.routePlanId);
  }

  sendMerchandiserEmails = () => {
    this.props.sendMerchandiserEmailsButtonClicked(this.props.routePlanId);
  }

  downloadStoreAdvanceShipNoticesPDF = () => {
    this.props.downloadStoreSpecificAdvanceShipNoticesPDFButtonClicked(this.props.routePlanId);
  }

  downloadCustomerAdvanceShipNoticesPDF = () => {
    this.props.downloadCustomerAdvanceShipNoticesPDFButtonClicked(this.props.routePlanId);
  }

  downloadStoreDeliverySpreadsheet = () => {
    this.props.downloadStoreDeliverySpreadsheetButtonClicked(this.props.routePlanId);
  }

  sendLoadsToLoadMaster = () => {
    this.props.sendLoadsToLoadMasterClicked(this.props.routePlanId);
  }

  emailRouteSheetsToPeak = () => {
    this.props.emailRouteSheetsToPeakClicked(this.props.routePlanId);
  }

  downloadPalletReportCSV = () => {
    this.props.downloadPalletReportCSVButtonClicked(this.props.routePlanId);
  }

  constructor(props: any) {
    super(props);
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="table-empty route-plan-printing-list">
          <div className="placeholder">
            <ProgressSpinner label={'Loading...'} />
          </div>
        </div>
      );
    }

    return (
      <div className="route-plan-printing-list">
        <GroupedList>
          <GroupedListHeading number="1" label="Route Packets &amp; Ditty Cards" />
          <GroupedListItem
            disabled={this.props.createRoutePacketDownloadPdfButtonDisabled}
            heading="Create Route Packets"
            text="Select which documents to include in the route packet"
            statusText={this.props.actions.createRoutePackets}
            buttonText="Download PDF"
            buttonOnClick={this.downloadRoutePacketsPDF}
          />
          <GroupedListItem
            disabled={this.props.downloadDittyCardsPDFButtonDisabled}
            heading="Download Ditty Cards"
            text="Choose between generic or route-specific Ditty Cards"
            statusText={this.props.actions.downloadDittyCards}
            buttonText="Download PDF"
            buttonOnClick={this.downloadDittyCardsPDF}
          />
          <GroupedListHeading number="2" label="Shipping Reports" />
          <GroupedListItem
            disabled={this.props.sendMerchandiserEmailsButtonDisabled}
            heading="Email Merchandiser-Specific Advance Ship Notices"
            text="Choose all, or select specific merchandisers to email a store-specific ASN"
            statusText={this.props.actions.emailMerchandiserAsn}
            buttonText="Send Emails"
            buttonOnClick={this.sendMerchandiserEmails}
          />
          <GroupedListItem
            disabled={this.props.downloadStoreAdvanceShipNoticesPDFButtonDisabled}
            heading="Download Store-Specific Advance Ship Notices"
            text="Download ASNs for specific stores"
            statusText={this.props.actions.downloadStoreAsn}
            buttonText="Download PDF"
            buttonOnClick={this.downloadStoreAdvanceShipNoticesPDF}
            buttonActionStatus={this.props.downloadStoreAdvanceShipNoticeActionStatus}
          />
          <GroupedListItem
            disabled={this.props.downloadCustomerAdvanceShipNoticesPDFButtonDisabled}
            heading="Download Summary Advance Ship Notices"
            text="Download a summary ASN with totals for each product"
            statusText={this.props.actions.createCustomerAsn}
            buttonText="Download PDF"
            buttonOnClick={this.downloadCustomerAdvanceShipNoticesPDF}
            buttonActionStatus={this.props.createCustomerAdvanceShipNoticeActionStatus}
          />
          <GroupedListItem
            disabled={this.props.downloadStoreDeliverySpreadsheetButtonDisabled}
            heading="Download Store Delivery Spreadsheet"
            text="Download a spreadsheet that shows product and load information for each store"
            statusText={this.props.actions.downloadStoreDeliverySpreadsheet}
            buttonText="Download XLSX"
            buttonOnClick={this.downloadStoreDeliverySpreadsheet}
            buttonActionStatus={this.props.downloadStoreDeliverySpreadsheetActionStatus}
          />
          <GroupedListItem
            disabled={this.props.sendLoadsToLoadMasterDisabled}
            heading="Upload to Loadmaster"
            text="Upload all loads from the Route Plan to Loadmaster"
            statusText={this.props.actions.sendLoadsToLoadmaster}
            buttonText="Upload Loads"
            buttonOnClick={this.sendLoadsToLoadMaster}
            buttonActionStatus={this.props.sendLoadsToLoadMasterActionStatus}
          />
          <GroupedListItem
            disabled={this.props.emailRouteSheetsToPeakDisabled}
            heading="Email Route Sheets to Peak"
            text="Email a copy of the Route Sheets to Peak"
            statusText={this.props.actions.emailRouteSheetsToPeak}
            buttonText="Send Email"
            buttonOnClick={this.emailRouteSheetsToPeak}
            buttonActionStatus={this.props.emailRouteSheetsToPeakActionStatus}
          />
          <GroupedListItem
            disabled={this.props.downloadPalletReportCSVButtonDisabled}
            heading="Create iGPS Pallet Report"
            text="Download CSV of pallet usage to send to iGPS"
            statusText={this.props.actions.createPalletReport}
            buttonText="Download CSV"
            buttonOnClick={this.downloadPalletReportCSV}
            buttonActionStatus={this.props.downloadPalletReportActionStatus}
          />
        </GroupedList>
        <CreateRoutePacketsModal routePlanId={this.props.routePlanId} />
        <DownloadDittyCardsModal routePlanId={this.props.routePlanId} />
        <SendMerchandisersAsnModal routePlanId={this.props.routePlanId} />
        <DownloadStoreAsnModal routePlanId={this.props.routePlanId} />
      </div>
    );
  }
}
