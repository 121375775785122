import { camelCase, snakeCase } from 'lodash';

export function camelCaseKeys(data: SimpleObject): any {
  return Object.keys(data).reduce((camel, key) => {
    camel[camelCase(key)] = data[key];
    return camel;
  }, {});
}

export function snakeCaseKeys(data: SimpleObject): any {
  return Object.keys(data).reduce((snake, key) => {
    snake[snakeCase(key)] = data[key];
    return snake;
  }, {});
}
