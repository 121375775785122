import 'reflect-metadata';
import * as Validators from 'shared/validators';
import { tableName, property, belongsTo, definePresentation, required, defineFieldValidators, setSchemaOptions } from './dsl';
import { Product } from 'shared/schemas/product';
import { SalesPlan } from 'shared/schemas/sales-plan';
import { DISPLAY_TYPES, TYPES } from 'shared/types';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { ProductClass } from './product-class';
import { ProductSubClass } from './product-sub-class';

@tableName('salesPlanProducts')
export class SalesPlanProduct implements IRecord {
  id?: number;
  @property @required weekNumber: number;
  @property @required quantity: number;

  @belongsTo('salesPlans')
  @property @required salesPlan: SalesPlan;
  salesPlanId: number;

  @belongsTo('products')
  @property @required product: Product;
  productId: number;

  @belongsTo('productClasses', { through: 'productSubClasses' })
  @property productClass: ProductClass;

  @belongsTo('productSubClasses', { through: 'products' })
  @property productSubClass: ProductSubClass;

  @property scanProduct: boolean; // Field that exists on the new product repository and copied here as a calculation spec
}

defineFieldValidators(SalesPlanProduct, {
  quantity: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
});

definePresentation(SalesPlanProduct, {
  weekNumber: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  quantity: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  salesPlan: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  product: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  scanProduct: {
    displayName: 'Scan Product',
    tableDisplay: false,
    sortable: true,
    tableEditable: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `(
        SELECT
          scan_product
        FROM
          products
        WHERE
          products.id = sales_plan_products.product_id
        )`;
      },
    },
  },
});

setSchemaOptions(SalesPlanProduct, {
  softDeletable: false,
});
