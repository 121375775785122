import gql from 'graphql-tag';
import { SupplierOrderId, SupplierOrderIdentifier } from 'shared/schemas/supplier-order';
import { ReceivableOrderReceivingStatus, DateStr } from 'shared/types';

export interface ReceivingDetailsRecordBarVariables {
  id: SupplierOrderId;
}

export interface ReceivingDetailsRecordBarResponse {
  response?: {
    id: SupplierOrderId;
    identifier: SupplierOrderIdentifier;
    supplierVendorName: string;
    customerName: string;
    mfcAreaIdentifier: string;
    arrivalDate: DateStr;
    receivingStatus: ReceivableOrderReceivingStatus;
    receivableOrderType: string;
    sourceOrderId: number;
  };
}

export const ReceivingDetailsRecordBarQuery = gql`
  query ReceivingDetailsRecordBarQuery($id: Int!) {
    response: GetUnifiedReceivableOrder(id: $id) {
      id
      identifier
      supplierVendorName
      customerName
      mfcAreaIdentifier
      arrivalDate
      receivingStatus
      receivableOrderType
      sourceOrderId
    }
  }
`;
