import * as _ from 'lodash';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as Constants from './constants';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as Redux from 'redux';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as SchemaTypes from 'schema/buyer-report/types';
import { convertPrimaryGlobalAllToJustPrimaryGlobal } from 'client/types/primary-global-all';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;

    const customerId = formValues[Constants.FormFields.customerId];
    const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];

    const startDate = formValues[Constants.FormFields.startDate];
    const todaysDate = formValues[Constants.FormFields.todaysDate];

    if (_.isNil(startDate) ||
      _.isNil(todaysDate) ||
      _.isNil(customerId) ||
      _.isNil(sellDepartmentId)) {
      throw new Error('Missing some required fields');
    }

    // const enabledOptionFields = BuyerReportHelpers.getEnabledBuyerOptionFieldNames(buyerOptionFields, formValues);

    const variables: SchemaTypes.GenerateBuyerReportInput = {
      input: {
        includePoBased: !!formValues[Constants.FormFields.poBased],
        includeScanBased: !!formValues[Constants.FormFields.scanBased],
        productIds: extractSelectedValues(formValues[Constants.FormFields.productIds]),
        storeIds: extractSelectedValues(formValues[Constants.FormFields.storeIds]),

        startDate,
        todaysDate,

        customerId,
        marketIds: extractSelectedValues(formValues[Constants.FormFields.marketIds]),
        primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(formValues[Constants.FormFields.primaryGlobalAll]),
        mfcAreaIds: extractSelectedValues(formValues[Constants.FormFields.mfcAreaIds]),
        productClassIds: extractSelectedValues(formValues[Constants.FormFields.productClassIds]),
        productSubClassIds: extractSelectedValues(formValues[Constants.FormFields.productSubClassIds]),
        regionIds: extractSelectedValues(formValues[Constants.FormFields.regionIds]),
        sellDepartmentId,
      },
    };

    try {
      dispatch(ReportActions.reportDownloadStarted());
      const response = await GraphQLMutation.msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateBuyerReportInput>({
        mutation: Mutations.generateBuyerReport,
        variables,
        dispatch,
      });

      if (response) {
        const reportId = response.data.response.id;
        window.location.replace(`/report/fileDownload/${reportId}`);
        dispatch(ReportActions.reportDownloadFinished());
      }

    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};

export const handleMeasureCheckboxClicked = (categoryName: string, measure: string, isOn: boolean): ReduxTypes.Thunker => {
  return (dispatch: Redux.Dispatch<any>) => {
    dispatch(ReduxForm.change(Constants.formName, _.camelCase(`${categoryName}-${measure}`), isOn));
  };
};
