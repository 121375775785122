import * as React from 'react';

interface TableTopperProps {
  topperRef: any;
  width: number;
}

export const TableTopper = React.memo((props: TableTopperProps & JSX.ElementChildrenAttribute) => {
  return (
    <div ref={props.topperRef} className="readonly-table-topper" style={{ width: props.width }}>
      {props.children}
    </div>
  );
});
