import { sortNaturally } from 'shared/helpers/sort-naturally';
import { TableTopper, TableHeader, TableBody, TableFooter, LoadingOverlay } from './components';
import * as _ from 'lodash';
import * as Constants from './constants';
import * as React from 'react';
import * as Selectors from './selectors';
import * as Types from './types';
import { buildSortTogglingFunction } from 'client/helpers/sort-helpers';
import { excludeNils } from 'shared/helpers/andys-little-helpers';
import { EMPTY_ARRAY } from 'client/constants';

const toggleSortOptions = buildSortTogglingFunction<Types.SortDef>({ fieldProp: 'columnHeader', orderProp: 'order', asc: 'asc', desc: 'desc' });

export function ReadonlyTableUI(props: Types.UIProps) {
  const tableTopperRef = React.useRef(null as any);
  const headerRef = React.useRef(null as any);
  const bodyRef = React.useRef(null as any);
  const footerRef = React.useRef(null as any);
  const [currentSorts, setCurrentSort] = React.useState<Types.SortDef[] | null>(props.defaultSort ? [props.defaultSort] : null);

  const onHeaderClick = React.useCallback(
    (header: string, multiColumn?: boolean) => {
      setCurrentSort(toggleSortOptions(
        { columnHeader: header, order: 'asc' },
        multiColumn || false,
        currentSorts || [],
      ));
    },
    [currentSorts, setCurrentSort],
  );

  const totalColumnsWidth = _.sum(props.columns.map((column, i) => column.width)) + Constants.SCROLLBAR_WIDTH;
  const hasHorizontalScrollbar = totalColumnsWidth > props.width && Constants.SCROLLBAR_WIDTH > 0;
  const horizontalScrollbarHeight = hasHorizontalScrollbar ? Constants.SCROLLBAR_HEIGHT + 1 : 0;

  const bodyHeight = Selectors.getBodyHeight(props);
  const visibleRows = _.floor(bodyHeight / Constants.ROW_HEIGHT);
  const derivedBodyHeight = (visibleRows * Constants.ROW_HEIGHT) + horizontalScrollbarHeight + 1;
  const hasVerticalScrollbar = props.data.length > visibleRows;

  const sortedRows = React.useMemo(
    () => {
      if (_.isNil(currentSorts) || currentSorts.length === 0) {
        return padRows(visibleRows, props.data);
      }

      const rows = sortNaturally(props.data, currentSorts.map(s => {
        const columnIndex = props.columns.findIndex(c => c.header === s.columnHeader);
        return { sortField: props.columns[columnIndex].accessor, sortOrder: s.order };
      }));

      return padRows(visibleRows, rows);
    },
    [visibleRows, props.data, props.columns, currentSorts],
  );

  const [selectedRowId, setSelectedRowId] = React.useState<any | undefined>(undefined);

  const internalOnRowClicked = React.useCallback(
    (rowIndex: number) => {
      const rowData = sortedRows[rowIndex] || EMPTY_ARRAY;
      const values = props.columns.map(col => rowData[col.accessor]);

      // Only select if there's something in the row
      if (excludeNils(values).length > 0) {
        setSelectedRowId(rowData.id);
      }
    },
    [sortedRows, props.columns],
  );
  const selectedRowIndex = React.useMemo(
    () => {
      if (props.highlightSelectedRow === false) {
        return undefined;
      }
      const index = _.findIndex(sortedRows, r => r.id === selectedRowId);
      return index > -1 ? index : undefined;
    },
   [props.highlightSelectedRow, selectedRowId, sortedRows],
  );
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | null>(null);

  const effectiveHoveredRowIndex = React.useMemo<number | null>(() => {
    if (_.isNil(hoveredRowIndex)) {
      return null;
    }

    const rowData = sortedRows[hoveredRowIndex] || EMPTY_ARRAY;
    const values = props.columns.map(col => rowData[col.accessor]);

    return excludeNils(values).length > 0 ? hoveredRowIndex : null;
  }, [hoveredRowIndex, sortedRows, props.columns]);

  React.useEffect(
    () => {
      if (headerRef?.current) {
        headerRef.current.resetAfterColumnIndex(props.columns.findIndex(c => c.header === props.growableColumnHeader), true);
      }
      if (props.self.current) {
        props.self.current.resetAfterColumnIndex(props.columns.findIndex(c => c.header === props.growableColumnHeader), true);
      }
      if (props.showFooter && footerRef?.current) {
        footerRef.current.resetAfterColumnIndex(props.columns.findIndex(c => c.header === props.growableColumnHeader), true);
      }
    },
    [props.columns, props.width, props.self, headerRef, footerRef, props.showFooter, props.growableColumnHeader],
  );

  const twinHasVerticalScrollbar = props.twin && props.twin.current
    ? props.twin.current.props.hasVerticalScrollbar
    : false;

  // TODO: Do some experimenting to see if useCallback here would
  //       help anything.
  const customClickHandler = (rowIndex: number) => {
    if (props.onRowClicked) {
      // Translate from a row index for the sorted rows to one in the propped down data set
      const row = sortedRows[rowIndex];
      props.onRowClicked(_.findIndex(props.data, r => r.id === row.id));
    }
  };

  return (
    <div id={props.tableName} className="readonly-table">
      <LoadingOverlay loading={props.loading} width={props.width} height={Selectors.getOverlayHeight(props)} />
      {props.children ? <TableTopper topperRef={tableTopperRef} width={Selectors.getTableWidth(props)}>{props.children}</TableTopper> : <div />}
      <TableHeader
        columns={Selectors.getColumnsFromProps(props)}
        headerWidth={Selectors.getTableWidth(props)}
        rowHeight={Constants.ROW_HEIGHT}
        headerRef={headerRef}
        currentSort={currentSorts}
        onClick={onHeaderClick}
        growableColumnHeader={props.growableColumnHeader}
        hasVerticalScrollbar={hasVerticalScrollbar}
        twinHasVerticalScrollbar={twinHasVerticalScrollbar}
      />
      {!props.loading && <TableBody
        tableName={props.tableName}
        columns={props.columns}
        rows={sortedRows}
        rowHeight={Constants.ROW_HEIGHT}
        bodyHeight={derivedBodyHeight}
        bodyWidth={Selectors.getTableWidth(props)}
        onHorizontalScroll={Selectors.getOnHorizontalScrollFunction(props)}
        onVerticalScroll={Selectors.getOnVerticalScrollFunction(props)}
        horizontalScrollPosition={Selectors.getHorizontalScrollPosition(props)}
        verticalScrollPosition={Selectors.getVerticalScrollPosition(props)}
        selectedRowIndex={selectedRowIndex}
        customClickHandler={customClickHandler}
        self={props.self}
        twin={props.twin}
        headerRef={headerRef}
        bodyRef={bodyRef}
        footerRef={footerRef}
        primaryClickHandler={internalOnRowClicked}
        growableColumnHeader={props.growableColumnHeader}
        hasVerticalScrollbar={hasVerticalScrollbar}
        twinHasVerticalScrollbar={twinHasVerticalScrollbar}
        hoveredRowIndex={effectiveHoveredRowIndex}
        hoverHandler={setHoveredRowIndex}
      />}
      {props.showFooter &&
        <TableFooter
          tableName={props.tableName}
          columns={Selectors.getColumnsFromProps(props)}
          footerWidth={Selectors.getTableWidth(props)}
          rowHeight={Constants.ROW_HEIGHT}
          footerRef={footerRef}
          growableColumnHeader={props.growableColumnHeader}
          rows={sortedRows}
          hasVerticalScrollbar={hasVerticalScrollbar}
          twinHasVerticalScrollbar={twinHasVerticalScrollbar}
        />
      }
    </div>
  );
}

function padRows(visibleRows: number, rows: any[] = []) {
  const blankRows = _.max([visibleRows - rows.length, 0]) ?? 0;
  return [ ...rows, ..._.range(blankRows).map(index => ({ id: -index })) ];
}
