import * as React        from 'react';
import { RecordNavItem } from 'client/components/record-nav';
import { CustomerOrder, CustomerOrderId } from 'shared/schemas/customer-order';
import { CustomerOrderType } from 'shared/types';

export default function buildRecordNavItems(customerOrderId?: CustomerOrderId, record?: CustomerOrder) {
  const baseCreateUrl = '/orders/customer/create';
  const baseDetailUrl = `/orders/customer/details/${customerOrderId}`;
  const customerOrderOverviewTab = customerOrderId
    ? <RecordNavItem label="Overview" key="overview" route={`${baseDetailUrl}/overview`}/>
    : <RecordNavItem label="Overview" key="overview" route={`${baseCreateUrl}`}/>;

  const showAdditionalTabs = record ? record.orderType !== CustomerOrderType.ExternalDistribution : false;
  const additionalTabs = showAdditionalTabs ? [
    <RecordNavItem label="Product Worksheet" key="product-worksheet" route={`${baseDetailUrl}/product-worksheet`} />,
    <RecordNavItem label="Related Supplier POs" key="related-supplier-orders" route={`${baseDetailUrl}/related-supplier-orders`} />,
    <RecordNavItem label="Reconciliation" key="customer-order-reconciliation" route={`${baseDetailUrl}/customer-order-reconciliation`} />,
  ] : [];

  return [
    customerOrderOverviewTab,
    ...additionalTabs,
  ];
}
