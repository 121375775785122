import { Thunker } from 'client/types/redux-types';
import { putFile } from 'shared/send';
import * as uuid from 'uuid';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Mutations from './mutations';
import * as Mime from 'mime-types';
import { ImportAttachmentResponse, ImportAttachmentInput } from 'schema/attachment/types';
import { FileUploadState, FileUploadStatus } from 'client/components/import-attachment-modal/types';
import { throttledMap } from 'shared/helpers/andys-little-helpers';

const ConcurrentUploads = 2;

export function handleImportStarted(acceptedFiles: File[], recordType: string, recordId: number, status: (status: FileUploadStatus) => void): Thunker {
  return async dispatch => {

    const filesWithObjectKey = acceptedFiles.map(file => {
      return {
        objectKey: uuid.v4(),
        file,
      };
    });

    filesWithObjectKey.forEach(file => {
      status({
        state: FileUploadState.Queued,
        filename: file.file.name,
        objectKey: file.objectKey,
      });
    });

    await throttledMap(filesWithObjectKey, ConcurrentUploads, file => {
      return new Promise((fulfill, reject) => {
        const reader = new FileReader();

        reader.onabort = () => console.info('file reading was aborted');
        reader.onerror = () => console.info('file reading has failed');
        reader.onload = async () => {
          const arrayBuffer = reader.result;

          if (arrayBuffer && typeof arrayBuffer !== 'string') {
            const mimeType = Mime.lookup(file.file.name);

            status({
              state: FileUploadState.Uploading,
              filename: file.file.name,
              objectKey: file.objectKey,
            });

            try {
              await putFile(
                `/attachment/${file.file.name}/${file.objectKey}`,
                arrayBuffer,
                {
                  'Content-Length': file.file.size,
                  'Content-Type': mimeType,
                  'Content-Disposition': `attachment; filename="${file.file.name}"`,
                },
              );

              const response = await msyncClientMutation<{ importAttachment: ImportAttachmentResponse }, ImportAttachmentInput>(
                {
                  mutation: Mutations.ImportAttachmentMutation,
                  variables: {
                    input: {
                      recordType,
                      recordId,
                      objectKey: file.objectKey,
                      mimeType: mimeType || 'application/octet-stream',
                      filename: file.file.name,
                      size: file.file.size,
                    },
                  },
                  dispatch,
                  suppressErrorModal: true,
                },
              );

              const importData = response.data.importAttachment;

              if (importData.success) {
                status({
                  state: FileUploadState.Complete,
                  filename: file.file.name,
                  objectKey: file.objectKey,
                });
              } else {
                status({
                  state: FileUploadState.Failed,
                  filename: file.file.name,
                  objectKey: file.objectKey,
                  errors: [importData.errors || `Unknown error occured with file: ${file.file.name}`],
                });
              }
            } catch (error) {
              status({
                state: FileUploadState.Failed,
                filename: file.file.name,
                objectKey: file.objectKey,
                errors: [`Unknown error occured with file: ${file.file.name}`, error],
              });
            }

            fulfill(file.objectKey);
          } else {
            status({
              state: FileUploadState.Failed,
              filename: file.file.name,
              objectKey: file.objectKey,
              errors: [`Unknown error occured with file: ${file.file.name}`, 'arrayBuffer is null or a string (should be an ArrayBuffer)'],
            });
          }
        };

        reader.readAsArrayBuffer(file.file); // fire off the process
      });
    });
  };
}
