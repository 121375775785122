import { displayType } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, ActiveInactive } from '../types';
import { tableName, property, definePresentation, required } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';

export type UserId = Flavor<number, 'userId'>;
export type UserFirstName = Flavor<string, 'userFirstName'>;
export type UserLastName = Flavor<string, 'userLastName'>;

export const getUserName = (user?: { firstName?: UserFirstName | null, lastName?: UserLastName | null } | null) => {

  if (user) {
    return `${user.firstName} ${user.lastName}`.trim();
  }
  return '';
};

@tableName('users')
export class User implements IRecord {
  id?: UserId;
  @property @required username: string;
  @property @required firstName: UserFirstName;
  @property @required lastName: UserLastName;
  @property @required email: string;
  @property password?: string | null;
  @property activeDirectoryObjectIdentifier?: string | null;
  @property activeStatus?: ActiveInactive;
}

definePresentation(User, {
  username:                        { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  password:                        { formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.PASSWORD }) },
  firstName:                       { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  lastName:                        { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  activeDirectoryObjectIdentifier: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
});
