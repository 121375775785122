import * as Layers from '@atomic-object/lenses';
import * as Types from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser/types';

export enum ImportDistributionRackShippingWorksheetImportState {
  Initial = 'Initial',
  Parsing = 'Parsing',
  Parsed = 'Parsed',
  Importing = 'Importing',
  Imported = 'Imported',
  Failed = 'Failed',
}

export enum ImportDistributionRackShippingWorksheetImportStep {
  Validation = 'Validation',
  Import = 'Import',
}

interface ImportDistributionRackShippingWorksheetState {
  readonly errorMessages: string[] | undefined;
  readonly failedStep: ImportDistributionRackShippingWorksheetImportStep | undefined;
  readonly importState: ImportDistributionRackShippingWorksheetImportState;
  readonly isModalShown: boolean;
  readonly routePlanId: number | undefined;
  readonly worksheet: Types.ImportableDistributionRackShippingWorkSheet | undefined;
  readonly storeIdentifiersInRoutePlan: string[];
}

export type Type = ImportDistributionRackShippingWorksheetState;

export const InitialState: Readonly<ImportDistributionRackShippingWorksheetState> = Object.freeze({
  errorMessages: undefined,
  failedStep: undefined,
  importState: ImportDistributionRackShippingWorksheetImportState.Initial,
  isModalShown: false,
  routePlanId: undefined,
  worksheet: undefined,
  storeIdentifiersInRoutePlan: [],
});

export const errorMessages = Layers.Lens.from<Type>().prop('errorMessages');
export const failedStep = Layers.Lens.from<Type>().prop('failedStep');
export const importState = Layers.Lens.from<Type>().prop('importState');
export const isModalShown = Layers.Lens.from<Type>().prop('isModalShown');
export const routePlanId = Layers.Lens.from<Type>().prop('routePlanId');
export const worksheet = Layers.Lens.from<Type>().prop('worksheet');
export const storeIdentifiersInRoutePlan = Layers.Lens.from<Type>().prop('storeIdentifiersInRoutePlan');
