import * as React from 'react';

export interface Props {
  elements: JSX.Element[];
  handlePencilClicked: () => void;
  handleTrashCanClicked: () => void;
  testId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ComponentState { }

export class ProductListItemDetail extends React.Component<Props, ComponentState> {
  public render() {
    const { handlePencilClicked, handleTrashCanClicked, testId, elements } = this.props;

    return (
      <div data-testid={testId} className="product-worksheet-products-list-product-rack-configuration">
        <div className="product-worksheet-products-list-product-shipment-configuration-label">
          {elements}
        </div>
        <div data-testid="edit" className="product-worksheet-products-list-product-shipment-configuration-icon mfc-simple-icon-button" onClick={handlePencilClicked}><span className="fa fa-pencil" /></div>
        <div data-testid="remove" className="product-worksheet-products-list-product-shipment-configuration-icon mfc-simple-icon-button" onClick={handleTrashCanClicked}><span className="fa fa-trash" /></div>
      </div>
    );
  }
}
