import { SendEmailModal } from 'client/components/send-email-modal';
import * as Components from 'client/components/send-email-modal/components';
import * as React from 'react';
import * as SendEmailModalTypes from 'client/components/send-email-modal/types';
import * as SharedHelpers from 'shared/helpers';
import * as SharedTypes from 'shared/types';

interface CommonProps {
  invoiceId: number;
  sendButtonDisabled: boolean;
  sendButtonClicked(invoiceId: number, recipients: SendEmailModalTypes.EmailRecipient[]): void;
  cancelButtonClicked(): void;
  isShown: boolean;
}

export type OwnProps = CommonProps & ({
  loading: true;
} | {
  loading: false,
  invoiceNumber: string;
  recipients: SendEmailModalTypes.EmailRecipient[];
  recipient: string;
  invoiceDate: SharedTypes.DateStr;
  invoiceAmount: number;
});

export class SendInvoiceModalUI extends React.PureComponent<OwnProps, {}> {
  sendButtonClicked = (recipients: SendEmailModalTypes.EmailRecipient[]) => {
    if (this.props.invoiceId) {
      this.props.sendButtonClicked(this.props.invoiceId, recipients);
    }
  }

  render() {
    if (!this.props.isShown || this.props.loading) {
      return <div />;
    }

    return (
      <SendEmailModal
        isShown={this.props.isShown}

        title="Send Email"
        heading={`Invoice #${this.props.invoiceNumber}`}

        recipients={this.props.recipients}

        includeEmailInput

        sendButtonTitle="Send Email"
        sendButtonDisabled={this.props.sendButtonDisabled}
        sendButtonClicked={this.sendButtonClicked}

        cancelButtonClicked={this.props.cancelButtonClicked}
        cancelButtonDisabled={false}
      >
        <Components.LabeledDetail title="Recipient">
          {this.props.recipient}
        </Components.LabeledDetail>
        <Components.LabeledDetail title="Invoice Date">
          {SharedHelpers.formatDateStrForDisplay(this.props.invoiceDate)}
        </Components.LabeledDetail>
        <Components.LabeledDetail title="Invoice Amount">
          <span className="send-email-modal-detail-green">-{SharedTypes.formatMoney(this.props.invoiceAmount)}</span>
        </Components.LabeledDetail>
      </SendEmailModal>
    );
  }
}
