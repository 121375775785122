import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { propToComponent } from 'client/hoc/hoc';
import { RouteChildrenProps } from 'react-router';

export interface WithRoutePlanIdProps {
  routePlanId?: RoutePlanId;
}

export const withRoutePlanIdFromParams = WrappedComponent => (props: RouteChildrenProps<{ id?: string }>) => {
  if (props.match) {
    const parsedId = Number.parseInt(props.match.params.id || '');
    const updatedProps: WithRoutePlanIdProps = {
      ...props,
      routePlanId: isNaN(parsedId) ? undefined : parsedId,
    };
    return propToComponent(WrappedComponent, updatedProps);
  }

  return propToComponent(WrappedComponent, props);
};
