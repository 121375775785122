export const clientConfig: {
  googleAPIAccount?: string,
  googleAPIKey?: string,
  directionStartingAddress?: string,
  minify: boolean,
} = {
  directionStartingAddress: '8122 Clyde Park Ave SW, Byron Center, MI 49315',
  minify: false,
  ...({
    test       : {                                                                   googleAPIKey: 'INVALID KEY'                                           },
    development: { googleAPIAccount: 'jordan.nelson@atomicobject.com',               googleAPIKey: 'AIzaSyBM-_q8EgThdx0BuzS3AEDG6e3xfed6vy0'               },
    production : { googleAPIAccount: 'information.technology@masterpieceflower.com', googleAPIKey: 'AIzaSyDMckyIO4L3JEza2xL9RDM2Xlw-actraTQ', minify: true },
  }[process.env.NODE_ENV ?? ''] ?? {}),
};
