import * as ImportModalTypes from 'client/components/import-modal/types';
import * as ReduxTypes from 'client/types/redux-types';
import * as Redux from 'redux';
import * as SharedHelpers from 'shared/helpers';
import * as ExcelHelpers from 'shared/file-parsers/excel/common';
import * as ProductPriceSpreadsheetParser from 'shared/file-parsers/excel/product-price-spreadsheet-parser';
import * as Queries from './queries';
import * as Mutations from './mutations';
import * as ReduxForm from 'redux-form';
import * as Constants from './constants';
import { ImportableProductPriceSheet } from 'shared/file-parsers/excel/product-price-spreadsheet-parser';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { ImportProductPriceResponse, ProductPriceAdditionalArguments, ImportProductPriceInput } from 'schema/import-product-prices/types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

export function handleParsingStarted(args: { fileName: string, contents: string }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);

    const workBookContainer = await ExcelHelpers.getWorkBookContainerFromBinaryString(args.fileName, args.contents);
    const importableProductPriceData = await ProductPriceSpreadsheetParser.getImportableProductPriceSheet(workBookContainer);

    if (importableProductPriceData.success) {
      done([], importableProductPriceData.parsed);
    } else {
      done([importableProductPriceData.reason]);
    }

  };
}

export function handleValidationStarted(args: { existingRecordId: number | undefined, importableObject: ImportableProductPriceSheet, additionalArguments: ProductPriceAdditionalArguments }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);
    try {
      const result = await msyncClientQuery<{ validateImportableProductPrice: ImportProductPriceResponse }, ImportProductPriceInput>(
        {
          query: Queries.ValidateImportProductPriceQuery,
          variables: {
            input: {
              importableProductPrice: args.importableObject,
              additionalArguments: args.additionalArguments,
            },
          },
          dispatch,
          disableGlobalError: true,
          fetchPolicy: 'network-only',
        },
      );
      const errors = result.data.validateImportableProductPrice.errors;
      done(errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleImportStarted(args: { importableObject: ImportableProductPriceSheet, additionalArguments: ProductPriceAdditionalArguments }, done: ImportModalTypes.DoneFn): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    await SharedHelpers.timeout(100);
    try {
      const response = await msyncClientMutation<{ importProductPrice: ImportProductPriceResponse }, ImportProductPriceInput>(
        {
          mutation: Mutations.ImportProductPriceMutation,
          variables: {
            input: {
              importableProductPrice: args.importableObject,
              additionalArguments: args.additionalArguments,
            },
          },
          dispatch,
          suppressErrorModal: true,
        },
      );
      done(response.data.importProductPrice.errors);
    } catch (error) {
      done([error.message]);
    }
  };
}

export function handleCustomerChanged(customerId: number) {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.customerId, customerId));
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.regionIds, undefined));
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.marketIds, undefined));
  };
}

export function handleRegionChanged(regionIds: number[]) {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.regionIds, regionIds));
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.marketIds, undefined));
  };
}

export function handleMarketChanged(marketIds: number[]) {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.marketIds, marketIds));
  };
}

export function handelModalOpened() {
  return async dispatch => {
    dispatch(ReduxForm.destroy(Constants.formName));
  };
}
