import * as React from 'react';
import { reduxForm } from 'redux-form';
import { Alert, Button, Form, FormGroup } from 'client/components/third-party';
import { FieldWrapper as Field } from 'client/components/form';
import { propToComponent } from 'client/hoc/hoc';
import { flowRight } from 'lodash';

interface OwnProps {
  handleSubmit: () => void;
  onSubmit: () => void;
  reset: () => void;
  handleLoginViaActiveDirectory: () => void;
  isPending: boolean;
  hasError: boolean;
}

class LoginForm extends React.Component<OwnProps, {}> {
  render() {

    const { handleSubmit, reset, hasError, handleLoginViaActiveDirectory } = this.props;

    return (
      <Form horizontal onSubmit={handleSubmit}>
        {hasError &&
          <Alert data-testid="alert-error" id="qa-alert" bsStyle="danger">
            Invalid username or password
        </Alert>
        }
        <FormGroup>
          <Field table="users" name="username" labelColSize={3} inputColSize={6} required />
        </FormGroup>
        <FormGroup>
          <Field table="users" name="password" labelColSize={3} inputColSize={6} required />
        </FormGroup>

        <div className="mfc-button-group pull-left">
          <Button type="button" bsClass="mfc-button mfc-button-secondary" onClick={handleLoginViaActiveDirectory}>
            Login via Office 365
          </Button>
        </div>

        <div className="mfc-button-group">
          <Button bsStyle="primary" type="submit" bsClass="mfc-button mfc-submit-button" data-testid="login-button">
            Login
          </Button>
          <Button bsClass="mfc-button mfc-clear-button" onClick={reset} type="reset" data-testid="clear-button">
            Clear
          </Button>
        </div>
      </Form>
    );
  }
}

const WrappedForm = flowRight(
  reduxForm({
    form: 'login',
    initialValues: {
      username: '',
      password: '',
    },
  }),
)(LoginForm);

export default props => propToComponent(WrappedForm, props);
