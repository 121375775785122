import { connect } from 'react-redux';
import * as Actions from '../actions';
import * as State from 'client/state/state';
import { propToComponent } from 'client/hoc/hoc';
import { FileChooserUI, ComponentProps as UIProps } from './file-chooser-ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { ImportDistributionRackShippingWorksheetImportState, ImportDistributionRackShippingWorksheetImportStep } from '../state';
import { ImportableDistributionRackShippingWorkSheet } from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser/types';

export interface OwnProps {
  routePlanId: number;
}

interface StateProps {
  distributionRackShippingSpreadsheetImportState: ImportDistributionRackShippingWorksheetImportState;
  failedStep: ImportDistributionRackShippingWorksheetImportStep | undefined;
  errorMessages: string[] | undefined;
  importableCustomerOrder: ImportableDistributionRackShippingWorkSheet | undefined;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    distributionRackShippingSpreadsheetImportState: state.importDistributionRackShippingWorksheet.importState,
    failedStep: state.importDistributionRackShippingWorksheet.failedStep,
    errorMessages: state.importDistributionRackShippingWorksheet.errorMessages,
    importableCustomerOrder: state.importDistributionRackShippingWorksheet.worksheet,
  };
};

interface DispatchProps {
  handleFileSelected(fileName: string, fileContents: string, routePlanId: number): void;
}

const mapDispatchToProps: DispatchProps = {
  handleFileSelected: Actions.worksheetSelected,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(FileChooserUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
