import * as Immutable from 'immutable';
import * as _ from 'lodash';
import { uniqBy, orderBy, union, groupBy, parseInt } from 'lodash';
import { ProductShipmentConfiguration, RackConfiguration, PalletConfiguration, ShipmentConfiguration } from 'client/types/product-worksheet';
import { CustomerOrderProduct } from 'shared/schemas/customer-order-product';
import { Product, ProductId } from 'shared/schemas/product';
import { FULL_RACK_IDENTIFIER, FULL_CASE_IDENTIFIER, ComboCart, ShippingUnitOrderMethod, PrimaryGlobal, isKnownCustomer, MEIJER, HOME_DEPOT, BooleanFilters, KnownSellDepartment, ShippingUnitType, PackOrderMethod, KnownSubSellDepartment } from 'shared/types';
import { CustomerOrderAllocation } from 'shared/schemas/customer-order-allocation';
import { Saved } from 'shared/schemas/record';
import { CustomerOrderProductGroup, CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { ProductListResponseItem } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-container';
import { FieldValidator } from 'shared/validators';
import { Customer } from 'shared/schemas/customer';
import { SellDepartment } from 'shared/schemas/sell-department';
import { SubSellDepartment } from 'shared/schemas/sub-sell-department';
import { SchemaActiveFilter } from 'client/types';
import { Store } from 'shared/schemas/store';

function determineNextShipmentConfigurationIdentifier(productId: number, customerOrderProductList: ProductShipmentConfiguration[], fullIdentifier: string): string {
  const product = customerOrderProductList.find(cop => cop.productId === productId);
  if (!product) {
    return fullIdentifier;
  }

  if (!product.shipmentConfigurations.some(sc => sc.identifier === fullIdentifier)) {
    return fullIdentifier;
  }

  if (!product.shipmentConfigurations.some(sc => sc.identifier.match(/^F[0-9]+$/) !== null)) {
    return 'F1';
  }

  const numberedShipmentConfigurations = product.shipmentConfigurations
    .map(sc => {
      const results = sc.identifier.match(/^F([0-9]+)$/) || [];

      if (results.length === 2) {
        return Number.parseInt(results[1]);
      }

      return 0;
    })
    .sort();

  const highestNumberedShipmentConfiguration = numberedShipmentConfigurations[numberedShipmentConfigurations.length - 1];

  return `F${highestNumberedShipmentConfiguration + 1}`;
}

export function determineNextRackIdentifier(productId: number, customerOrderProductList: ProductShipmentConfiguration[]): string {
  return determineNextShipmentConfigurationIdentifier(productId, customerOrderProductList, FULL_RACK_IDENTIFIER);
}

export function determineNextPalletIdentifier(productId: number, customerOrderProductList: ProductShipmentConfiguration[]): string {
  return determineNextShipmentConfigurationIdentifier(productId, customerOrderProductList, FULL_CASE_IDENTIFIER);
}

export function determineNextComboIdentifier(comboCartList: ComboCart[]): string {
  if (!comboCartList.some(comboCart => comboCart.identifier.match(/^C[0-9]+$/) !== null)) {
    return 'C1';
  }

  const numberedComboCarts = comboCartList
    .map(comboCart => {
      const results = comboCart.identifier.match(/^C([0-9]+)$/) || [];

      if (results.length === 2) {
        return Number.parseInt(results[1]);
      }

      return 0;
    })
    .sort();

  const highestNumberedComboCart = numberedComboCarts[numberedComboCarts.length - 1];
  return `C${highestNumberedComboCart + 1}`;
}

export interface PartialProduct {
  product: Pick<Product, 'id' | 'packSize' | 'packsPerRack' | 'packsPerShelf' | 'identifier' | 'description'>;
}
export interface PartialCustomerOrderAllocation {
  customerOrderAllocations?: Array<Pick<CustomerOrderAllocation, 'id'>>;
}
export interface PartialCustomerOrderProductGroup {
  customerOrderProductGroup: Pick<CustomerOrderProductGroup, 'id' | 'identifier' | 'description'>;
}
export type PartialCustomerOrderProduct = Pick<Saved<CustomerOrderProduct>, 'id' | 'packSize' | 'packsPerShippingUnit' | 'packsPerShelf' | 'shelvesPerRack' > & PartialProduct & PartialCustomerOrderAllocation & PartialCustomerOrderProductGroup;

export function getRegularCartsFromCustomerOrderProductGroups(customerOrderProductGroups: ProductListResponseItem[]): ProductShipmentConfiguration[] {
  if (customerOrderProductGroups.length === 0) {
    return [];
  }
  const regularCartGroups = customerOrderProductGroups.filter(g => !g.isCombo);
  const regularCartGroupsByProducts = groupBy(regularCartGroups, group => group.customerOrderProducts[0].product.id );
  const uniqueProducts = uniqBy(regularCartGroups.map(customerOrderProductGroup => customerOrderProductGroup.customerOrderProducts[0].product), 'id');

  const uniformCarts: ProductShipmentConfiguration[] = Object.keys(regularCartGroupsByProducts).map(productIdString => {
    const product = uniqueProducts.find(p => p.id === parseInt(productIdString)) as ProductListResponseItem['customerOrderProducts'][0]['product'];
    const customerOrderProductGroupsForCurrentProduct = regularCartGroupsByProducts[productIdString];
    const shipmentConfigurations = customerOrderProductGroupsForCurrentProduct.map(customerOrderProductGroup => {
      const customerOrderProduct = customerOrderProductGroup.customerOrderProducts[0]; // Since this is a regular cart, we can assume we have only 1 product.
      if (customerOrderProductGroup.customerOrder.shippingUnitType === ShippingUnitType.Rack) {
        const shipmentConfiguration: RackConfiguration = {
          shippingMethod: ShippingUnitType.Rack,
          customerOrderProductGroupId: customerOrderProductGroup.id as number,
          customerOrderProductId: customerOrderProduct.id as number,
          productId: customerOrderProduct.product.id,
          identifier: customerOrderProductGroup.identifier,
          packSize: customerOrderProduct.packSize,
          packsPerShippingUnit: customerOrderProduct.packsPerShippingUnit as number,
          totalAllocations: customerOrderProduct.customerOrderAllocations ? customerOrderProduct.customerOrderAllocations.length : 0,
          shelvesPerRack: customerOrderProduct.shelvesPerRack as number,
          packsPerShelf: customerOrderProduct.packsPerShelf as number,
        };
        return shipmentConfiguration;
      } else {
        const shipmentConfiguration: PalletConfiguration = {
          shippingMethod: ShippingUnitType.Pallet,
          customerOrderProductGroupId: customerOrderProductGroup.id as number,
          customerOrderProductId: customerOrderProduct.id,
          productId: customerOrderProduct.product.id,
          identifier: customerOrderProductGroup.identifier,
          packSize: customerOrderProduct.packSize,
          packsPerShippingUnit: customerOrderProduct.packsPerShippingUnit as number,
          totalAllocations: customerOrderProduct.customerOrderAllocations ? customerOrderProduct.customerOrderAllocations.length : 0,
        };
        return shipmentConfiguration;
      }
    });

    const sortedShipmentConfigurations = orderBy(shipmentConfigurations, ['identifier'], ['asc']);

    const fullShipmentConfigurations: ShipmentConfiguration[] = sortedShipmentConfigurations.filter(sc => sc.identifier === FULL_RACK_IDENTIFIER || sc.identifier === FULL_CASE_IDENTIFIER);
    const otherShipmentConfigurations: ShipmentConfiguration[] = sortedShipmentConfigurations.filter(sc => sc.identifier !== FULL_RACK_IDENTIFIER && sc.identifier !== FULL_CASE_IDENTIFIER);
    const sortedRackConfigurations = union(fullShipmentConfigurations, otherShipmentConfigurations);
    const productShipmentConfiguration: ProductShipmentConfiguration = {
      productId: product.id,
      identifier: product.identifier,
      description: product.description,
      shipmentConfigurations: sortedRackConfigurations,
    };
    return productShipmentConfiguration;
  });

  return orderBy(uniformCarts, ['identifier', 'description'], ['asc', 'asc']);
}

export function getComboCartsFromCustomerOrderProductGroups(customerOrderProductGroups: ProductListResponseItem[]): ComboCart[] {
  const comboCartGroups = customerOrderProductGroups.filter(g => g.isCombo === true || (g.isCombo as any) === 'true');
  if (comboCartGroups.length === 0) {
    return [];
  }

  const orderMethod = customerOrderProductGroups[0].customerOrder.orderMethod;
  const sortedComboProductGroups = orderBy(comboCartGroups, ['identifier']);
  const comboCarts = sortedComboProductGroups.map(comboCartGroup => {
    const result: ComboCart = {
      id: comboCartGroup.id as number,
      identifier: comboCartGroup.identifier,
      description: comboCartGroup.description,
      products: _.sortBy(comboCartGroup.customerOrderProducts.map(cop => ({
        id: cop.id as number,
        productId: cop.product.id,
        product: {
          identifier: cop.product.identifier,
          description: cop.product.description,
        },
        identifier: cop.product.identifier,
        description: cop.product.description,
        shippingMethod: orderMethod,
        packSize: cop.packSize,
        packsPerShippingUnit: cop.packsPerShippingUnit,
        shelvesPerRack: cop.shelvesPerRack,
        packsPerShelf: cop.packsPerShelf,
      })), ['id']),
    };
    return result;
  });
  return comboCarts;
}

export const determineProductListButtonsVisibility = (checkedProductIds: number[], checkedComboCartIds: number[]): { showAddFromSalesPlanButton: boolean, showAddProductButton: boolean, showNewRackTypeButton: boolean, showRemoveButton: boolean, showCreateComboCartButton: boolean } => {
  const noCheckedComboCarts = checkedComboCartIds.length === 0;
  return {
    showAddFromSalesPlanButton: checkedProductIds.length === 0 && noCheckedComboCarts,
    showAddProductButton: checkedProductIds.length === 0 && noCheckedComboCarts,
    showCreateComboCartButton: checkedProductIds.length > 1 && noCheckedComboCarts,
    showRemoveButton: checkedProductIds.length > 0 || checkedComboCartIds.length > 0,
    showNewRackTypeButton: checkedProductIds.length === 1 && noCheckedComboCarts,
  };
};

const getCheckedRegularCartIds = (args: { checkedProductIds: Immutable.Set<ProductId>, productListResponseItems: ProductListResponseItem[] }): CustomerOrderProductGroupId[] => {
  const productIdsToMatch = args.checkedProductIds.toJS();
  const regularProductGroups = args.productListResponseItems.filter(productListResponseItem => !productListResponseItem.isCombo);
  const regularProductGroupsWithCheckedIds = regularProductGroups.filter(productGroup => _.includes(productIdsToMatch, productGroup.customerOrderProducts[0].product.id));
  return regularProductGroupsWithCheckedIds.map(group => group.id);
};

export const getCheckedCustomerOrderProductGroupIds = (args: { checkedComboCartIds: Immutable.Set<CustomerOrderProductGroupId>, checkedProductIds: Immutable.Set<ProductId>, productListResponseItems: ProductListResponseItem[] }): CustomerOrderProductGroupId[] => {
  const checkedRegularCartIds = getCheckedRegularCartIds({ checkedProductIds: args.checkedProductIds, productListResponseItems: args.productListResponseItems });
  return _.sortBy(_.union(args.checkedComboCartIds.toJS(), checkedRegularCartIds));
};

export const getSelectedProductInfo = (args: { productListResponseItems: ProductListResponseItem[], productId: number }) => {
  const filterProductListResponseItems = args.productListResponseItems.filter(item => item.customerOrderProducts.map(cop => cop.product.id).includes(args.productId));
  const fullProductListResponseItems = _.orderBy(filterProductListResponseItems.filter(sc => sc.identifier === FULL_RACK_IDENTIFIER || sc.identifier === FULL_CASE_IDENTIFIER), 'identifier');
  const downCountedProductListResponseItems = _.orderBy(filterProductListResponseItems.filter(sc => sc.identifier !== FULL_RACK_IDENTIFIER && sc.identifier !== FULL_CASE_IDENTIFIER && !sc.isCombo), 'identifier');
  const comboProductListResponseItems = _.orderBy(filterProductListResponseItems.filter(item => item.isCombo), 'identifier');
  const sortedProductListResponseItems = _.union(fullProductListResponseItems, downCountedProductListResponseItems, comboProductListResponseItems);
  const formattedShipmentConfigurations = sortedProductListResponseItems.map(item => {
    return {
      customerOrderProductGroupId: item.id,
      customerOrderProductId: (item.customerOrderProducts.find(cop => cop.product.id === args.productId) as any).id,
      identifier: item.identifier,
    };
  });
  return {
    productId: args.productId,
    shipmentConfigurations: formattedShipmentConfigurations,
  };
};

export const buildUniqueCartIdentifierConstraint = (args: { productListResponseItems: ProductListResponseItem[], initialValues: any }): FieldValidator => {
  const isValid = (fieldValue: any, record: { customerOrderProducts: any[], isCombo?: boolean }) => {
    const isCombo = record.customerOrderProducts.length > 1 || record.isCombo;
    const invalid = args.productListResponseItems.some(item => {
      return fieldValue !== args.initialValues.identifier &&
        item.identifier === fieldValue &&
        (item.customerOrderProducts.some(cop => record.customerOrderProducts.map(recordCop => recordCop.productId).includes(cop.product.id)) || !!isCombo);
    });
    return !invalid;
  };

  return {
    isValid,
    shortMessage(value: any, record: any) {
      return `${value} already exists`;
    },
    message(label: string, value: any, record: any) {
      return `Rack Configuration with identifier ${value} already exists.`;
    },
  };
};

function getPrimaryGlobalFilter(customer: Customer['identifier'], sellDepartment: SellDepartment['identifier']) {
  let pgValue: PrimaryGlobal[] | null = null;
  if (isKnownCustomer(customer)) {
    if (customer === MEIJER) {
      if (sellDepartment) {
        if (sellDepartment === KnownSellDepartment.OutdoorGarden) {
          pgValue = [PrimaryGlobal.Primary];
        } else if (sellDepartment === KnownSellDepartment.IndoorFloral) {
          pgValue = [PrimaryGlobal.Primary, PrimaryGlobal.Global];
        }
      }
    } else if (customer === HOME_DEPOT) {
      pgValue = [PrimaryGlobal.Primary];
    }
  }

  return pgValue;
}

function getEncoreFilter(customer: Customer['identifier'], sellDepartment: SellDepartment['identifier'], subSellDepartmentIdentifier: SubSellDepartment['identifier']) {
  if (customer === MEIJER && sellDepartment && sellDepartment === KnownSellDepartment.IndoorFloral) {
    if (subSellDepartmentIdentifier === KnownSubSellDepartment.CutFloral) {
      return [BooleanFilters.No];
    } else if (subSellDepartmentIdentifier === null) {
      return [BooleanFilters.No, BooleanFilters.Yes];
    }
  }

  return null;
}

export function getDefaultFilters(customer, sellDepartment, subSellDepartmentIdentifier) {
  const primaryGlobalFilters = getPrimaryGlobalFilter(customer, sellDepartment);
  const encoreFilters = getEncoreFilter(customer, sellDepartment, subSellDepartmentIdentifier);

  const filters: Array<SchemaActiveFilter<Store>> = [];
  if (primaryGlobalFilters) {
    filters.push({
      field: 'primaryGlobal',
      values: primaryGlobalFilters,
    });
  }
  if (encoreFilters) {
    filters.push({
      field: 'encoreStore',
      values: encoreFilters,
    });
  }
  return filters;
}

interface ShipTargetAllocationBaseArgs {
  pieceTarget: number;
  oldPiecesShipped: number;
  newPercentShipped: number;
  packSize: number;
}

interface ShipTargetAllocationShippingUnitArgs {
  orderMethod: ShippingUnitOrderMethod;
  packsPerShippingUnit: number;
}

interface ShipTargetAllocationUnitsPackArgs {
  orderMethod: PackOrderMethod;
}

type ShipTargetAllocationArgs = ShipTargetAllocationBaseArgs & (ShipTargetAllocationShippingUnitArgs | ShipTargetAllocationUnitsPackArgs);

export function determineUnitsForTargetPercentShipped(args: ShipTargetAllocationArgs): number {
  const pieces = Math.max(((args.newPercentShipped * args.pieceTarget) - args.oldPiecesShipped), 0);

  if (args.orderMethod === ShippingUnitOrderMethod) {
    return Math.round(pieces / args.packSize / args.packsPerShippingUnit);
  }

  // PackOrderMethod
  return Math.round(pieces / args.packSize);
}
