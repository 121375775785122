import { connect, MapDispatchToProps } from 'react-redux';
import { flowRight } from 'lodash';
import Modal, { OwnProps as ComponentOwnProps } from './attach-customer-orders-modal';
import { propToComponent } from 'client/hoc/hoc';
import { TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME } from 'client/constants';
import { ApolloRefetch } from 'client/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import * as Actions from 'client/actions/route-plan';
import { MutationFunction } from 'react-apollo';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { AttachCustomerOrderToRoutePlanMutation } from 'client/app/transportation/routing/attached-customer-orders-table/attached-customer-orders-table-query';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { buildTableStateModule } from 'client/state/tables';

export interface OwnProps {
  routePlanId: number;
  title: string;
  refetchData?: ApolloRefetch;
  dataRequest?: MsyncDataRequest;
  refetchParentTable?: ApolloRefetch;
}

interface StateProps {
  customerOrderIds: CustomerOrderId[];
  attachButtonDisabled: boolean;
}

const TableStateHelpers = buildTableStateModule(TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME);

const mapStateToProps = (state, ownProps: OwnProps) => {
  const tableState = TableStateHelpers.tableStateLens.get(state);
  const customerOrderIds = TableStateHelpers.checkedRecordIds(tableState);
  return {
    customerOrderIds,
    attachButtonDisabled: customerOrderIds.length === 0,
  };
};

interface DispatchProps {
  handleAddClicked(attachCustomerOrderToRoutePlan, routePlanId: RoutePlanId, customerOrderIds: CustomerOrderId[], refetchParentTable?: ApolloRefetch): void;
  handleCloseClicked(): void;
  handleModalUnmounted(): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleAddClicked: Actions.handleAddClicked,
  handleCloseClicked: Actions.hideAttachOrdersModal,
  handleModalUnmounted: Actions.handleModalUnmounted,
};

interface WithAttachCustomerOrderToRoutePlanProps {
  attachCustomerOrderToRoutePlanMutation: MutationFunction<any>;
}

const WithAttachCustomerOrderToRoutePlan = msyncMutation(AttachCustomerOrderToRoutePlanMutation, {
  props: (args: { mutate?: shame }) => ({ attachCustomerOrderToRoutePlanMutation: args.mutate }),
});

export type ComponentProps =
  OwnProps &
  WithAttachCustomerOrderToRoutePlanProps &
  DispatchProps &
  StateProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<ComponentOwnProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  WithAttachCustomerOrderToRoutePlan,
  connect<{}, DispatchProps, OwnProps & StateProps & WithAttachCustomerOrderToRoutePlanProps>(undefined, mapDispatchToProps),
)(Modal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
