import { isNil, noop } from 'lodash';
import * as React from 'react';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES } from 'shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME, EMPTY_ARRAY } from 'client/constants';
import { Button } from 'client/components/third-party';
import { RoutingLoadCustomerNames, RoutingLoadMfcAreaIdentifiers, RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import CreateRoutePacketsModal from 'client/app/transportation/routing/route-plan-details/printing/create-route-packet-modal/create-route-packet-modal-container';
import DownloadDittyCardsModal from 'client/app/transportation/routing/route-plan-details/printing/download-ditty-card-modal/download-ditty-card-modal-container';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { RoutePlanLoadsStatsRow } from 'client/app/transportation/routing/route-plan-details/loads/stats-row/stats-row-container';
import { AvailableSearchField } from 'client/types';

const tableName = TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME;
export interface LoadsTableRow {
  id: number;
  identifier: string;
  loadType: { rawValue: string, displayValue: string };
  customerNames?: RoutingLoadCustomerNames;
  mfcAreaIdentifiers?: RoutingLoadMfcAreaIdentifiers;
  routeType: { rawValue: string, displayValue: string };
  carrier: { rawValue: string, displayValue: string };
  milesCombinedWithAdditionalMiles: number;
  dropsCombinedWithAdditionalDrops: number;
  racks: number;
  pallets: number;
  trailerNumber: string;
  peakOrderNumber: string | null;
  totalFee: number;
}

interface OwnProps {
  routePlanId: RoutePlanId | undefined;
  rows: LoadsTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  tableParentInfo: TableParentInfo;
  onRowSelect(record: { id: number }): void;
  dataRequest: MsyncDataRequest;
  searchableFields: AvailableSearchField[];
  extractOrdersButtonDisabled?: boolean;
  importRouteFileButtonDisabled?: boolean;
  onExtractOrdersClicked(): void;
  onImportRouteFileButtonClicked(id: RoutePlanId): void;
  onCreateRoutePacketMenuItemClicked(id: RoutingLoadId[]): void;
  onDownloadDittyCardMenuItemClicked(id: RoutingLoadId[]): void;
  selectedRoutingLoadIds: RoutingLoadId[];
  loadsListTableUnmounted(): void;
}

const columns: IColumn[] = [
  { id: 'identifier'            , accessor: 'identifier'                      , header: 'Load ID'   , tableEditable: false, columnWidth: 10, sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'customerNames'         , accessor: 'customerNames'                   , header: 'Customer'  , tableEditable: false, columnWidth: 16, sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'mfcAreaIdentifiers'    , accessor: 'mfcAreaIdentifiers'              , header: 'MFC Area'  , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'loadType.displayValue' , accessor: 'loadType.displayValue'           , header: 'Load Type' , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'routeType.displayValue', accessor: 'routeType.displayValue'          , header: 'Route Type', tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'carrier.displayValue'  , accessor: 'carrier.displayValue'            , header: 'Carrier'   , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'miles'                 , accessor: 'milesCombinedWithAdditionalMiles', header: 'Miles'     , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'drops'                 , accessor: 'dropsCombinedWithAdditionalDrops', header: 'Stops'     , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'racks'                 , accessor: 'racks'                           , header: 'Racks'     , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'pallets'               , accessor: 'pallets'                         , header: 'Pallets'   , tableEditable: false, columnWidth: 5 , sortable: true, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER },
  { id: 'trailerNumber'         , accessor: 'trailerNumber'                   , header: 'Trailer'   , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'peakOrderNumber'       , accessor: 'peakOrderNumber'                 , header: 'Peak #'    , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.TEXT            , type: TYPES.STRING },
  { id: 'totalFee'              , accessor: 'totalFee'                        , header: 'Total Fee' , tableEditable: false, columnWidth: 7 , sortable: true, cellType: CELL_TYPES.MONEY           , type: TYPES.FLOAT  },
];

export class LoadsTableUI extends React.PureComponent<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private headerMenuItems: RowMenuItem[] = [
    {
      label: 'Create Route Packets',
      onClick: this.props.onCreateRoutePacketMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
    {
      label: 'Download Ditty Cards',
      onClick: this.props.onDownloadDittyCardMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
  ];

  private rowMenuItems: RowMenuItem[] = [
    {
      label: 'Create Route Packet',
      onClick: this.props.onCreateRoutePacketMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
    {
      label: 'Download Ditty Cards',
      onClick: this.props.onDownloadDittyCardMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
  ];

  constructor(props: OwnProps) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  onImportRouteFileButtonClicked = () => {
    if (this.props.routePlanId) {
      return this.props.onImportRouteFileButtonClicked(this.props.routePlanId);
    }
  }

  componentWillUnmount() {
    this.props.loadsListTableUnmounted();
  }

  render() {
    if (!this.props.loading && this.props.totalUnfilteredCount === 0) {
      return (
        <div className="table-empty">
          <div className="placeholder">
            There are no loads on this route plan.
          </div>

          <div className="action">
            <Button
              bsClass="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary"
              data-testid="extract-orders-table-button"
              onClick={this.props.onExtractOrdersClicked}
              disabled={this.props.extractOrdersButtonDisabled}
            >
              Extract Orders
            </Button>
            <Button
              bsClass="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary"
              data-testid="import-route-file-table-button"
              onClick={this.onImportRouteFileButtonClicked}
              disabled={this.props.importRouteFileButtonDisabled}
            >
              Import Route File
            </Button>
            <div>{this.props.rows}</div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {!isNil(this.props.routePlanId) && <CreateRoutePacketsModal routePlanId={this.props.routePlanId} />}
        {!isNil(this.props.routePlanId) && <DownloadDittyCardsModal routePlanId={this.props.routePlanId} />}
        <RoutePlanLoadsStatsRow routePlanId={this.props.routePlanId} />
        <this.FilterableTable
          table={tableName}
          content={this.props.rows}
          loading={this.props.loading}
          columns={columns}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={noop}
          loadMoreRecords={noop}
          searchableFields={this.props.searchableFields}
          availableFilters={EMPTY_ARRAY}
          placeholder="Extract Orders for routing or Import a Route File"
          checkable
          list
          disableCreate
          tablePaginated
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          onRowSelect={this.props.onRowSelect}
          dataRequest={this.props.dataRequest}
          rowMenuItems={this.rowMenuItems}
          headerMenuItems={this.headerMenuItems}
        />
      </div>
    );
  }
}
