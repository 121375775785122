import * as React from 'react';

interface LoadingOverlayProps {
  loading: boolean;
  width: number | null;
  height: number | null;
}

const SPINNER_HEIGHT = 58.15;

export const LoadingOverlay = React.memo((props: LoadingOverlayProps) => {
  if (!props.loading || !props.width || !props.height) {
    return <div />;
  }

  const style = {
    width: props.width - 2,
    height: props.height - 2,
    paddingTop: (props.height - 2 - SPINNER_HEIGHT) / 2,
  };

  return (
    <div className="readonly-table-loading-overlay" style={style}>
      <div><i className="fa fa-spinner fa-spin fa-5x"></i></div>
    </div>
  );
});
