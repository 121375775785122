import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import gql from 'graphql-tag';
import { Thunker, Action } from 'client/types/redux-types';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME } from '../invoice-table/query';
import { GET_INVOICE_STATS_QUERY_NAME } from '../stats-row/query';

export type ActionTypes =
  AddVendorChargebackLineItemsModalCloseClicked |
  AddVendorChargebackLineItemsCompleted
;

export enum ActionTypeKeys {
  AddVendorChargebackLineItemsModalCloseClicked = 'App/ADD_VENDOR_CHARGEBACK_LINE_ITEMS_MODAL_CLOSE_CLICKED',
  AddVendorChargebackLineItemsCompleted = 'App/ADD_VENDOR_CHARGEBACK_LINE_ITEMS_COMPLETED',
}

export interface AddVendorChargebackLineItemsModalCloseClicked extends Action {
  type: ActionTypeKeys.AddVendorChargebackLineItemsModalCloseClicked;
}

export const onAddVendorChargebackLineItemsModalCloseClicked = (): AddVendorChargebackLineItemsModalCloseClicked => {
  return {
    type: ActionTypeKeys.AddVendorChargebackLineItemsModalCloseClicked,
  };
};

export interface AddVendorChargebackLineItemsCompleted extends Action {
  type: ActionTypeKeys.AddVendorChargebackLineItemsCompleted;
}

export const onAddVendorChargebackLineItemsCompleted = (): AddVendorChargebackLineItemsCompleted => {
  return {
    type: ActionTypeKeys.AddVendorChargebackLineItemsCompleted,
  };
};

const mutation = gql`
  mutation AddVendorChargebackLineItemsFromSupplierItems($supplierItemIds: [Int!]!, $invoiceId: Int!) {
    result: AddVendorChargebackLineItemsFromSupplierItems(supplierItemIds: $supplierItemIds, invoiceId: $invoiceId) {
      invoice {
        id
        ... on VendorChargebackInvoice {
          id
          lineItems {
            id
          }
        }
      }
    }
  }
`;

export interface MutationResponse {
  id: number;
}

export interface MutationInput {
  supplierItemIds: SupplierItemId[];
  invoiceId: number;
}

export const addVendorChargebackLineItemsFromSupplierItems = (supplierItemIds: number[], invoiceId: number): Thunker => {
  return (dispatch, getState) => {
    return msyncClientMutation<MutationResponse, MutationInput>({
      mutation,
      dispatch,
      variables: {
        supplierItemIds,
        invoiceId,
      },
      refetchQueries: [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME],
    });
  };
};
