import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import { AddBolModal } from 'client/app/transportation/receiving/details/worksheet/add-bol-modal/add-bol-modal';
import * as Actions from 'client/actions/receiving-worksheet';
import ReduxForm, { reduxForm, change, reset } from 'redux-form';
import { AddBolMutation, AddBolVariables, AddBolResponse } from 'client/app/transportation/receiving/details/worksheet/add-bol-modal/add-bol-mutation';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { BolIdentifier, BolReceivedAt, BolRacksReturned, BolNotes } from 'shared/schemas/bol';
import { ApolloQueryResult } from 'apollo-client';
import { WithMutationStatusProps, withMutationStatusProps, mutationStatusWrapper } from 'client/app/transportation/receiving/details/worksheet/mutation-status';
import * as State from 'client/state/state';
import { formatDateTime, parseDateTime } from 'shared/helpers/date-helpers';
import { Clock } from 'shared/clock';
import { ThunkerDispatch } from 'client/types/redux-types';

const formName = 'AddBolForm';
interface OwnProps {
  receivableOrderId: number;
}

const mapStateToProps = (state: State.Type) => {
  const user = State.user(state);
  return {
    isShown: State.addBolModalShown(state),
    userName: user ? `${user.firstName} ${user.lastName[0]}.` : '',
  };
};

type ReduxFormProps = ReduxForm.InjectedFormProps<FormValues>;

interface FormValues {
  identifier: BolIdentifier;
  receivedAt: BolReceivedAt;
  racksReturned: BolRacksReturned;
  notes: BolNotes;
}

interface WithAddBolProps {
  addBol(input: AddBolVariables): Promise<ApolloQueryResult<AddBolResponse> | undefined>;
}
const WithAddBol = msyncMutation<AddBolResponse, OwnProps & WithMutationStatusProps, WithAddBolProps, { input: AddBolVariables }>(AddBolMutation, {
  props: ({ mutate, ownProps }): WithAddBolProps => {
    return {
      addBol: async (input: AddBolVariables) => {
        return mutationStatusWrapper(mutate, ownProps)({
          variables: { input },
        });
      },
    };
  },
});

const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps & ReduxFormProps & ReturnType<typeof mapStateToProps> & WithAddBolProps) => {
  const hide = () => {
    dispatch(reset(formName));
    dispatch(Actions.setAddBolModalVisibility(false));
  };

  return {
    onCancelButtonClicked: () => {
      hide();
    },
    onModalEnter: () => {
      dispatch(change(formName, 'receivedAt', formatDateTime(Clock.now(), 'MM/DD/YYYY - hh:mm A z')));
      dispatch(change(formName, 'user', props.userName));
    },
    handleFormSubmit: () => {
      return props.handleSubmit(async (formValues: FormValues) => {
        const response = await props.addBol({
          identifier: formValues.identifier,
          receivableOrderId: props.receivableOrderId,
          notes: formValues.notes,
          racksReturned: formValues.racksReturned,
          receivedAt: parseDateTime(formValues.receivedAt, 'MM/DD/YYYY - hh:mm A'),
        });

        // TODO: Fix this type so we don't have to do this check (AP 12/8/17)
        if (!response) {
          // msyncMutation will handle error display.
          return;
        }

        dispatch(Actions.bolClicked(props.receivableOrderId, response.data.addBol.bolId));

        hide();
      });
    },
  };
};

const connectorState = connect(mapStateToProps);
const connectorDispatch = connect(undefined, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  WithMutationStatusProps &
  WithAddBolProps &
  ConnectedProps<typeof connectorState> &
  ConnectedProps<typeof connectorDispatch> &
  ReduxFormProps;

export default wrapComponent(AddBolModal)<OwnProps, CombinedProps>(
  withMutationStatusProps(State.addBolMutationStatus.get, Actions.setAddBolMutationStatus),
  WithAddBol,
  reduxForm({
    form: formName,
  }),
  connectorState,
  connectorDispatch,
);
