import gql from 'graphql-tag';

export const ImportRouteMutation = gql`
  mutation ImportRoutingLoadsFromModal($input: ImportRoutingLoadsInput!) {
    importRoutingLoadsResult: ImportRoutingLoads(input: $input) {
      routingLoads {
        id
      }
      routePlan {
        id
        origin
        destination
        loadType
        dropoffLocation
        routeType
        carrier
        trailerTemperature
        status
        loadCount
        loads {
          numberOfStores
          racks
          pallets
          dropsCombinedWithAdditionalDrops
          totalFee
          milesCombinedWithAdditionalMiles
          loadCount
        }
      }
    }
  }
`;
