import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import * as State from 'client/state/state';
import * as Selectors from 'client/state/selectors';
import { GlobalCancelButtonUI } from 'client/components/async-button/global-cancel-button-ui';
import { ButtonClickHandler } from 'client/types';

interface GlobalCancelButtonProps {
  label?: string;
  onClick?: ButtonClickHandler;
  disabled?: boolean;
  // classNames?: string[];
  testid?: string;
}

interface StateProps {
  disabled: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: GlobalCancelButtonProps): StateProps => {
  const inFlight = Selectors.isGlobalSaveButtonSpinning(state);
  const proppedDisabled = ownProps.disabled;
  return {
    disabled: proppedDisabled || inFlight,
  };
};

export type ComponentProps =
  GlobalCancelButtonProps &
  StateProps;

const component = flowRight(
  connect<StateProps, any, GlobalCancelButtonProps>(mapStateToProps, undefined),
)(GlobalCancelButtonUI);

export const GlobalCancelButton = (props: GlobalCancelButtonProps) => propToComponent(component, props);
