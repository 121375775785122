import { ReadonlyTable } from 'client/components/readonly-table';
import * as _ from 'lodash';
import * as React from 'react';
import * as ReadOnlyTableTypes from 'client/components/readonly-table/types';
import * as Reselect from 'reselect';
import * as Types from '../../types';

export interface UIProps {
  rows: Types.VarietyDetailRow[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
  height: number;
  width: number;
  handleImportVarietyColorModalOpenModalButtonClicked(): void;
  productLabel: string;
  twin: any;
  self: any;
}

const getStartWeek = (props: UIProps) => {
  return props.startWeek;
};

const getEndWeek = (props: UIProps) => {
  return props.endWeek;
};

const getColumns = Reselect.createSelector([
  getStartWeek,
  getEndWeek,
], (startWeek, endWeek): ReadOnlyTableTypes.ColumnDef[] => {
  const weekColumns = startWeek && endWeek
    ? _.range(startWeek, endWeek + 1).map(weekNumber => {
      return {
        header: `WK ${weekNumber}`,
        accessor: `week-${weekNumber}`,
        contentType: ReadOnlyTableTypes.ColumnContentType.Percent,
        width: 60,
      };
    })
    : [];

  return [
    {
      header: 'Mix',
      accessor: 'mix',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 100,
    },
    {
      header: 'Variety/Color',
      accessor: 'name',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 260,
    },
    ...weekColumns,
  ];
});

interface UIState {
  selectedRowId: number;
}

const defaultSort: ReadOnlyTableTypes.SortDef = {
  columnHeader: 'Mix',
  order: 'asc',
};

export class SalesPlanWeeklyVarietyDetailsTableUI extends React.PureComponent<UIProps, UIState> {
  constructor(props) {
    super(props);
  }

  render() {
    const columns = getColumns(this.props);

    return (
      <ReadonlyTable
        tableName="weekly-variety-detail"
        columns={columns}
        data={this.props.rows}
        height={this.props.height}
        width={this.props.width}
        defaultSort={defaultSort}
        growableColumnHeader={'Variety/Color'}
        loading={this.props.loading}
        twin={this.props.twin}
        self={this.props.self}
        showFooter
      >
        <div className="sales-plan-product-breakdown-table-topper">
          <div className="sales-plan-product-breakdown-table-topper-label">
            {this.props.productLabel}
          </div>
          <div className="sales-plan-product-breakdown-table-topper-button-container" >
            <div
              className="readonly-table-topper-button"
              data-testid="import-sales-plan-variety-button"
              onClick={this.props.handleImportVarietyColorModalOpenModalButtonClicked}>
              Import Variety/Color Allocations from Excel
            </div>
          </div>
        </div>
      </ReadonlyTable>
    );
  }
}
