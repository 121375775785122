import gql from 'graphql-tag';
import { SelectableRow } from 'client/components/selectable/types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES } from 'shared/types';
import * as _ from 'lodash';

export interface FindAllSalesPlansQueryResponse {
  salesPlans?: Array<{
    id: number;
    identifier: string;
    year: string;
    customer: {
      id: number
      identifier: string;
    };
  }>;
}

export const FindAllSalesPlansQuery = gql`
  query FindAllSalesPlansCartEstimateQuery($type: RecordType = Product, $sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlans: findAll(type: $type, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlan {
        id
        identifier
        year
        customer {
          id
          identifier
        }
      }
    }
  }
`;

export interface SalesPlanQueryProps {
  salesPlanOptions?: SelectableRow[];
  salesPlansLoading?: boolean;
}

export const withSalesPlans = msyncQuery<FindAllSalesPlansQueryResponse, { salesPlanYear?: string }, SalesPlanQueryProps, {}>(FindAllSalesPlansQuery, {
  alias: 'withSalesPlans',
  skip(ownProps) {
    return _.isEmpty(ownProps.salesPlanYear);
  },
  options(ownProps) {
    return {
      variables: {
        type: 'SalesPlan',
        filters: [
          { field: 'year', values: [ownProps.salesPlanYear] },
        ],
        sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SalesPlanQueryProps {
    const { data } = props;
    if (data.loading || data.salesPlans === undefined) {
      return {
        salesPlanOptions: [],
        salesPlansLoading: true,
      };
    }
    return {
      salesPlanOptions: data.salesPlans.map(salesPlan => {
        return {
          id: salesPlan.id,
          cells: [
            salesPlan.year,
            salesPlan.identifier,
            salesPlan.customer.identifier,
          ],
        };
      }),
      salesPlansLoading: false,
    };
  },
});
