import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import { BolListItem, BolListItemData } from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list-item/bol-list-item';
import * as Actions from 'client/actions/receiving-worksheet';

interface OwnProps { bol: BolListItemData, isSelected: boolean, onBolClicked(bolId: number): void }
const connector = connect(undefined, (dispatch: Dispatch, props: OwnProps) => ({
  onPencilClicked: () => dispatch(Actions.showEditBolModal(props.bol.id)),
  onTrashCanClicked: () => dispatch(Actions.showRemoveBolModal(props.bol.id)),
}));

export type CombinedProps = OwnProps & ConnectedProps<typeof connector>;
export default wrapComponent(BolListItem as shame)<OwnProps, CombinedProps>( connector );
