import * as _ from 'lodash';
import * as Constants from 'client/constants';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as GraphQLTypes from 'schema/distribution-customer-order-receiving/distribution-customer-order-receiving-graphql-types';
import * as Mutation from './mutation';
import * as Query from './query';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as SharedHelpers from 'shared/helpers';
import * as SharedTypes from 'shared/types';
import * as TableActions from 'client/actions/table';
import * as Types from './types';

export type ActionTypes =
  SendMicsFileToMeijerMenuItemAction |
  SendMicsFileCancelButtonClickedAction |
  SendMicsFileToMeijerStartedAction |
  SendMicsFileToMeijerCompletedAction |
  SendMicsFileToMeijerFailedAction |
  SendMicsFileToMeijerDoneDoneAction;

export enum ActionTypeKeys {
  SEND_MICS_FILE_TO_MEIJER_MENU_ITEM_CLICKED = 'App/SEND_MICS_FILE_TO_MEIJER_MENU_ITEM_CLICKED',
  SEND_MICS_FILE_CANCEL_BUTTON_CLICKED_ACTION = 'App/SEND_MICS_FILE_CANCEL_BUTTON_CLICKED_ACTION',
  SEND_MICS_FILE_TO_MEIJER_STARTED = 'App/SEND_MICS_FILE_TO_MEIJER_STARTED',
  SEND_MICS_FILE_TO_MEIJER_COMPLETED = 'App/SEND_MICS_FILE_TO_MEIJER_COMPLETED',
  SEND_MICS_FILE_TO_MEIJER_FAILED = 'App/SEND_MICS_FILE_TO_MEIJER_FAILED',
  SEND_MICS_FILE_TO_MEIJER_DONE_DONE = 'App/SEND_MICS_FILE_TO_MEIJER_DONE_DONE',
 }

export interface SendMicsFileToMeijerStartedAction extends ReduxTypes.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_STARTED;
}

export function sendMicsFileToMeijerStarted(): SendMicsFileToMeijerStartedAction {
  return {
    type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_STARTED,
  };
}

export interface SendMicsFileToMeijerCompletedAction extends ReduxTypes.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_COMPLETED;
}

export interface SendMicsFileToMeijerDoneDoneAction extends ReduxTypes.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_DONE_DONE;
}

export function sendMicsFileToMeijerCompleted(): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch({ type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_COMPLETED }); // Show the check mark
    await SharedHelpers.timeout(1500);
    dispatch({ type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_DONE_DONE }); // Back to normal text
  };
}

export interface SendMicsFileToMeijerFailedAction extends ReduxTypes.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_FAILED;
}

export function sendMicsFileToMeijerFailed(): SendMicsFileToMeijerFailedAction {
  return {
    type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_FAILED,
  };
}

const sendMicsFileToMeijer = (dispatch: Redux.Dispatch<any>, customerOrderIds: number[]) => {
  return GraphQLMutation.msyncClientMutation<GraphQLTypes.SendMicsFileToMeijerMutationResponse, GraphQLTypes.SendMicsFileToMeijerMutationInput>({
    mutation: Mutation.SendMicsFileToMeijerMutation,
    dispatch,
    variables: {
      customerOrderIds,
    },
  });
};

export const sendMicsFileToMeijerButtonClicked = (customerOrdersIdsToBeSent: number[], additionalCustomerOrderIdsToBeUnchecked: number[]): ReduxTypes.Thunker => {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(sendMicsFileToMeijerStarted());
    try {
      await sendMicsFileToMeijer(dispatch, customerOrdersIdsToBeSent);
      dispatch(sendMicsFileToMeijerCompleted());
    } catch (err) {
      dispatch(sendMicsFileToMeijerFailed());
    } finally {
      dispatch(TableActions.uncheckMultipleRecords(
        Constants.TRANSPORTATION_DISTRIBUTION_RECEIVING_SUMMARY_TABLE_NAME,
        _.union(customerOrdersIdsToBeSent, additionalCustomerOrderIdsToBeUnchecked)));

      dispatch(sendMicsFileCancelButtonClicked());
    }
  };
};

export interface SendMicsFileToMeijerMenuItemAction extends Redux.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_MENU_ITEM_CLICKED;
  payload: {
    customerOrdersIdsAlreadySent: Types.CustomerOrderIdWithIdentifier[],
    customerOrdersIdsNotFullyReceived: Types.CustomerOrderIdWithIdentifier[],
    customerOrdersIdsToBeSent: Types.CustomerOrderIdWithIdentifier[],
    customerOrdersIdsThatAreScanBased: Types.CustomerOrderIdWithIdentifier[],
  };
}

export function sendMicsFileToMeijerMenuItem(args: { customerOrdersIdsAlreadySent: Types.CustomerOrderIdWithIdentifier[], customerOrdersIdsNotFullyReceived: Types.CustomerOrderIdWithIdentifier[], customerOrdersIdsToBeSent: Types.CustomerOrderIdWithIdentifier[], customerOrdersIdsThatAreScanBased: Types.CustomerOrderIdWithIdentifier[] }): SendMicsFileToMeijerMenuItemAction {
  return {
    type: ActionTypeKeys.SEND_MICS_FILE_TO_MEIJER_MENU_ITEM_CLICKED,
    payload: {
      customerOrdersIdsAlreadySent: args.customerOrdersIdsAlreadySent,
      customerOrdersIdsNotFullyReceived: args.customerOrdersIdsNotFullyReceived,
      customerOrdersIdsToBeSent: args.customerOrdersIdsToBeSent,
      customerOrdersIdsThatAreScanBased: args.customerOrdersIdsThatAreScanBased,
    },
  };
}

async function getCustomerOrdersSendMicsFileToMeijer(dispatch: Redux.Dispatch<any>, customerOrderIds: number[]) {
  const customerOrders = await getCustomerOrdersWithStatus(customerOrderIds, dispatch);

  return {
    customerOrdersIdsAlreadySent: _.sortBy(customerOrders.filter(hasOrderAlreadyBeenSentOnMics), co => co.identifier),
    customerOrdersIdsThatAreScanBased: _.sortBy(customerOrders.filter(shouldOrderBeListedAsScanBasedOnTheMicsModal), co => co.identifier), // not fully received orders should only be listed in the not received section
    customerOrdersIdsNotFullyReceived: _.sortBy(customerOrders.filter(shouldOrderBeListedAsNotFullyReceivedOnTheMicsModal), co => co.identifier),
    customerOrdersIdsToBeSent: _.sortBy(customerOrders.filter(canOrderBeSentOnMics), co => co.identifier),
  };
}

export const sendMicsFileToMeijerMenuItemClicked = (customerOrderIds: number[]): ReduxTypes.Thunker => {
  return async (dispatch: Redux.Dispatch<any>) => {
    const {
      customerOrdersIdsAlreadySent,
      customerOrdersIdsNotFullyReceived,
      customerOrdersIdsThatAreScanBased,
      customerOrdersIdsToBeSent,
    } = await getCustomerOrdersSendMicsFileToMeijer(dispatch, customerOrderIds);

    dispatch(sendMicsFileToMeijerMenuItem({
      customerOrdersIdsAlreadySent,
      customerOrdersIdsNotFullyReceived,
      customerOrdersIdsThatAreScanBased,
      customerOrdersIdsToBeSent,
    }));
  };
};

export interface SendMicsFileCancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.SEND_MICS_FILE_CANCEL_BUTTON_CLICKED_ACTION;
  payload: {
  };
}

async function getCustomerOrdersWithStatus(customerOrderIds: number[], dispatch: Redux.Dispatch<any>) {
  const result = await GraphQLQuery.msyncClientQuery<{ customerOrderReceivingStatusResponse: Query.CustomerOrderReceivingStatusResponse[] }, Query.CustomerOrderReceivingStatusInput>({
    fetchPolicy: 'network-only',
    variables: {
      filters: [
        {
          field: 'id',
          values: customerOrderIds,
        },
      ],
    },
    dispatch,
    query: Query.CustomerOrderReceivingStatusQuery,
    });

  return result.data.customerOrderReceivingStatusResponse;
}

export function sendMicsFileCancelButtonClicked(): SendMicsFileCancelButtonClickedAction {
  return {
    type: ActionTypeKeys.SEND_MICS_FILE_CANCEL_BUTTON_CLICKED_ACTION,
    payload: {
    },
  };
}

// Filter helpers - general
const hasOrderAlreadyBeenSentOnMics = (order: Query.CustomerOrderReceivingStatusResponse) => {
  return order.micsSent;
};

const isOrderExternalDistribution = (order: Query.CustomerOrderReceivingStatusResponse) => {
  return order.orderType === SharedTypes.CustomerOrderType.ExternalDistribution;
};

const isOrderScanBased = (order: Query.CustomerOrderReceivingStatusResponse) => {
  return order.scanBased;
};

/**
 * Returns false for all non-distribution orders because the `received` flag is meaningless for non-distribution orders
 */
const isDistributionOrderFullyReceived = (order: Query.CustomerOrderReceivingStatusResponse) => {
  return isOrderExternalDistribution(order) && order.received;
};

// Filter helpers - whether there are any valid orders for a specific action
const canOrderBeSentOnMics = (order: Query.CustomerOrderReceivingStatusResponse) => {
  return isOrderExternalDistribution(order) ? canDistributionOrderBeSentOnMics(order) : canRegularOrderBeSentOnMics(order);
};

const canRegularOrderBeSentOnMics = (order: Query.CustomerOrderReceivingStatusResponse) => {
  // a PO order can only be sent if it hasn't been sent and is not scan-based
  // TODO: determine whether we should also check if a PO based order has been received/connected to a supplier order
  // for now, assuming that we don't need to do that
  return !hasOrderAlreadyBeenSentOnMics(order) && !isOrderScanBased(order);
};

const canDistributionOrderBeSentOnMics = (order: Query.CustomerOrderReceivingStatusResponse) => {
  // A distribution order can only be sent if it hasn't been sent, is not scan based, and has been received
  return !hasOrderAlreadyBeenSentOnMics(order) && !isOrderScanBased(order) && isDistributionOrderFullyReceived(order);
};

// Filter helpers - should orders populate lists on a specific modal
const shouldOrderBeListedAsScanBasedOnTheMicsModal = (order: Query.CustomerOrderReceivingStatusResponse) => {
  // Scan-based orders can never be sent on a MICS file so a SBT order should be listed as such regardless of whether it is received
  // (checking to make sure it hasn't already been sent just to be safe)
  return !hasOrderAlreadyBeenSentOnMics(order) && isOrderScanBased(order);
};

const shouldOrderBeListedAsNotFullyReceivedOnTheMicsModal = (order: Query.CustomerOrderReceivingStatusResponse) => {
  // If an order is scan-based, or has already been sent on a MICS, it should appear on those lists instead of not being received
  // only distribution orders should be included in this list
  return isOrderExternalDistribution(order) && !hasOrderAlreadyBeenSentOnMics(order) && !isOrderScanBased(order) && !isDistributionOrderFullyReceived(order);
};
