import { Action } from 'redux';

export type ActionTypes =
  | ShowDownloadSpecSheetModalAction
  | HideDownloadSpecSheetModalAction
  | PdfDownloadStartedAction
  | PdfDownloadFinishedAction
  | ProductDetailSectionUnmounted;

export enum ActionTypeKeys {
  ShowDownloadSpecSheetModal = 'ProductCrud/ShowDownloadSpecSheetModal',
  HideDownloadSpecSheetModal = 'ProductCrud/HideDownloadSpecSheetModal',
  PdfDownloadStarted = 'ProductCrud/PdfDownloadStarted',
  PdfDownloadFinished = 'ProductCrud/PdfDownloadFinished',
  ProductDetailSectionUnmounted = 'ProductCrud/ProductDetailSectionUnmounted',
}

export interface ShowDownloadSpecSheetModalAction extends Action {
  type: ActionTypeKeys.ShowDownloadSpecSheetModal;
}

export interface HideDownloadSpecSheetModalAction extends Action {
  type: ActionTypeKeys.HideDownloadSpecSheetModal;
}

export const ShowDownloadSpecSheetModal = (): ShowDownloadSpecSheetModalAction => {
  return {
    type: ActionTypeKeys.ShowDownloadSpecSheetModal,
  };
};

export const HideDownloadSpecSheetModal = (): HideDownloadSpecSheetModalAction => {
  return {
    type: ActionTypeKeys.HideDownloadSpecSheetModal,
  };
};

interface PdfDownloadStartedAction extends Action {
  type: ActionTypeKeys.PdfDownloadStarted;
}
export const pdfDownloadStarted = (): PdfDownloadStartedAction => {
  return {
    type: ActionTypeKeys.PdfDownloadStarted,
  };
};

interface PdfDownloadFinishedAction extends Action {
  type: ActionTypeKeys.PdfDownloadFinished;
}
export const pdfDownloadFinished = (): PdfDownloadFinishedAction => {
  return {
    type: ActionTypeKeys.PdfDownloadFinished,
  };
};

export interface ProductDetailSectionUnmounted extends Action {
  type: ActionTypeKeys.ProductDetailSectionUnmounted;
}

export function productDetailSectionUnmounted() {
  return {
    type: ActionTypeKeys.ProductDetailSectionUnmounted,
  };
}
