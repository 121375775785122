import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import * as Actions from 'client/actions/receiving-worksheet';
import { RemoveBolModal } from 'client/app/transportation/receiving/details/worksheet/remove-bol-modal/remove-bol-modal';
import { BolId } from 'shared/schemas/bol';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { RemoveBolMutation, RemoveBolResponse } from 'client/app/transportation/receiving/details/worksheet/remove-bol-modal/remove-bol-mutation';
import { withMutationStatusProps, mutationStatusWrapper, WithMutationStatusProps } from 'client/app/transportation/receiving/details/worksheet/mutation-status';
import * as State from 'client/state/state';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
  receivableOrderId: number;
}

const mapStateToProps = (state: State.Type) => {
  return {
    isShown: State.removeBolModalShown(state),
    bolId: State.bolToRemoveId(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch, props: OwnProps & StateProps & WithRemoveBolProps) => {
  return {
    onCancelButtonClicked: () => {
      dispatch(Actions.hideRemoveBolModal());
    },
    onRemoveClicked: async () => {
      if (!props.bolId) {
        // This really shouldn't happen
        throw new Error('Bol to be deleted is no longer selected');
      }
      await props.removeBol(props.bolId, props.receivableOrderId);
      dispatch(Actions.hideRemoveBolModal());
    },
  };
};

interface WithRemoveBolProps {
  removeBol(bolId: BolId, receivableOrderId: number): void;
}

const WithRemoveBol = msyncMutation<RemoveBolResponse, OwnProps & StateProps & WithMutationStatusProps, WithRemoveBolProps>(
  RemoveBolMutation,
  {
    props: ({ mutate, ownProps }): WithRemoveBolProps => {
      return {
        removeBol: (bolId, receivableOrderId) => {
          return mutationStatusWrapper(mutate, ownProps)({
            variables: {
              input: {
                id: bolId,
                receivableOrderId,
              },
            },
          });
        },
      };
    },
  });

const connectorState = connect(mapStateToProps);
const connectorDispatch = connect(undefined, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  WithMutationStatusProps &
  WithRemoveBolProps &
  ConnectedProps<typeof connectorState> &
  ConnectedProps<typeof connectorDispatch>;

export default wrapComponent(RemoveBolModal)<OwnProps, CombinedProps>(
  withMutationStatusProps(State.removeBolMutationStatus.get, Actions.setRemoveBolMutationStatus),
  WithRemoveBol,
  connectorState,
  connectorDispatch,
);
