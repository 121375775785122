import * as React from 'react';

import { Modal, ModalContent } from '../modal';
import LoginForm from './login-form';

interface LoginModalProps {
  isVisible: boolean;
  isPending: boolean;
  hasError: boolean;
  onSubmit: () => void;
}
class LoginModal extends React.PureComponent < LoginModalProps, {}> {
  // TODO: This should really be propped in from a mapDispatchToProps
  //       and then have a dispatched action creator do the redirect.
  goToOffice365() {
    window.location.href = '/office365';
  }

  render() {
    const title = 'Login';
    return (
      <Modal id="login-modal-id" dialogClasses="mfc-login-modal" isVisible={this.props.isVisible}>
        <ModalContent modalTitle={ title } headerClasses="mfc-login-modal-header">
          <LoginForm
            isPending={this.props.isPending}
            hasError={this.props.hasError}
            onSubmit={this.props.onSubmit}
            handleLoginViaActiveDirectory={this.goToOffice365}
          />
        </ModalContent>
      </Modal>
    );
  }

}

export default LoginModal;
