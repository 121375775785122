import { DateStr, ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'shippingStatusWarehouseReport';
export const reportType = ReportTypes.ShippingStatusWarehouse;

export enum FormFields {
  customerIds = 'customerIds',
  beginDate = 'beginDate',
  endDate = 'endDate',
  mfcArrivalBeginDate = 'mfcArrivalBeginDate',
  mfcArrivalEndDate = 'mfcArrivalEndDate',
  includeCustomerSheet = 'includeCustomerSheet',
  includeSupplierSheet = 'includeSupplierSheet',
}

export interface FormValues {
  [FormFields.customerIds]: SelectableValue | undefined;
  [FormFields.beginDate]: DateStr | undefined;
  [FormFields.endDate]: DateStr | undefined;
  [FormFields.mfcArrivalBeginDate]: DateStr | undefined;
  [FormFields.mfcArrivalEndDate]: DateStr | undefined;
  [FormFields.includeCustomerSheet]: boolean | undefined;
  [FormFields.includeSupplierSheet]: boolean | undefined;
}
