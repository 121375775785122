import { Lens } from '@atomic-object/lenses';

interface CustomerOrderHeaderState {
  readonly alertModalVisible: boolean;
  readonly alertModalSituation: string | undefined;
}

export type Type = CustomerOrderHeaderState;

export const InitialState: Readonly<CustomerOrderHeaderState> = Object.freeze({
  alertModalVisible: false,
  alertModalSituation: undefined,
});

export const alertModalVisible = Lens.from<Type>().prop('alertModalVisible');
export const alertModalSituation = Lens.from<Type>().prop('alertModalSituation');
