import { Thunker, Action } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { BolId } from 'shared/schemas/bol';
import { MutationStatus } from 'client/actions/mutations';

export type ActionTypes =
  SetAddBolModalVisibilityAction |
  SetEditBolModalAction |
  SetRemoveBolModalAction |
  BolClickedAction |
  BolUnselectedAction |
  SetAddBolMutationStatusAction |
  SetEditBolMutationStatusAction |
  SetRemoveBolMutationStatusAction;

export enum ActionTypeKeys {
  RECEIVING_WORKSHEET_SET_ADD_BOL_MODAL_VISIBILITY = 'App/RECEIVING_WORKSHEET_SET_ADD_BOL_MODAL_VISIBILITY',
  RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY = 'App/RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY',
  RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY = 'App/RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY',
  RECEIVING_WORKSHEET_SET_ADD_BOL_MUTATION_STATUS = 'App/RECEIVING_WORKSHEET_SET_ADD_BOL_MUTATION_STATUS',
  RECEIVING_WORKSHEET_SET_EDIT_BOL_MUTATION_STATUS = 'App/RECEIVING_WORKSHEET_SET_EDIT_BOL_MUTATION_STATUS',
  RECEIVING_WORKSHEET_SET_REMOVE_BOL_MUTATION_STATUS = 'App/RECEIVING_WORKSHEET_SET_REMOVE_BOL_MUTATION_STATUS',
  RECEIVING_WORKSHEET_BOL_CLICKED = 'App/RECEIVING_WORKSHEET_BOL_CLICKED',
  RECEIVING_WORKSHEET_BOL_UNSELECTED = 'App/RECEIVING_WORKSHEET_BOL_UNSELECTED',
}

export interface SetAddBolModalVisibilityAction extends Action {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
  };
}

export function setAddBolModalVisibility(showModal: boolean): SetAddBolModalVisibilityAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MODAL_VISIBILITY,
    payload: {
      showModal,
    },
  };
}

export interface SetEditBolModalAction extends Action {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
    bolId: BolId | null;
  };
}

export function showEditBolModal(bolId: BolId): SetEditBolModalAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY,
    payload: {
      showModal: true,
      bolId,
    },
  };
}

export function hideEditBolModal(): SetEditBolModalAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY,
    payload: {
      showModal: false,
      bolId: null,
    },
  };
}

export interface SetRemoveBolModalAction extends Action {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
    bolId: BolId | null;
  };
}

export function showRemoveBolModal(bolId: BolId): SetRemoveBolModalAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY,
    payload: {
      showModal: true,
      bolId,
    },
  };
}

export function hideRemoveBolModal(): SetRemoveBolModalAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY,
    payload: {
      showModal: false,
      bolId: null,
    },
  };
}

export interface BolClickedAction extends Action {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_BOL_CLICKED;
  payload: {
    bolId: BolId;
  };
}

export function bolClicked(supplierOrderId: number, bolId: BolId): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch<BolClickedAction>({
      type: ActionTypeKeys.RECEIVING_WORKSHEET_BOL_CLICKED,
      payload: {
        bolId,
      },
    });

    dispatch(push(`/transportation/receiving/details/${supplierOrderId}/worksheet/${bolId}`));
  };
}

export interface BolUnselectedAction extends Action {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_BOL_UNSELECTED;
}

export function redirectToReceivingWorksheet(supplierOrderId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch<BolUnselectedAction>({
      type: ActionTypeKeys.RECEIVING_WORKSHEET_BOL_UNSELECTED,
    });

    dispatch(push(`/transportation/receiving/details/${supplierOrderId}/worksheet`));
  };
}

interface SetMutationStatusAction extends Action {
  payload: {
    status: MutationStatus,
  };
}

export interface SetAddBolMutationStatusAction extends SetMutationStatusAction {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MUTATION_STATUS;
}

export function setAddBolMutationStatus(status: MutationStatus): SetAddBolMutationStatusAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MUTATION_STATUS,
    payload: {
      status,
    },
  };
}

export interface SetEditBolMutationStatusAction extends SetMutationStatusAction {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MUTATION_STATUS;
}

export function setEditBolMutationStatus(status: MutationStatus): SetEditBolMutationStatusAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MUTATION_STATUS,
    payload: {
      status,
    },
  };
}

export interface SetRemoveBolMutationStatusAction extends SetMutationStatusAction {
  type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MUTATION_STATUS;
}

export function setRemoveBolMutationStatus(status: MutationStatus): SetRemoveBolMutationStatusAction {
  return {
    type: ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MUTATION_STATUS,
    payload: {
      status,
    },
  };
}
