import * as React from 'react';
import EditableCell from './editable-cell';
import { formatMoneyOrNull } from 'shared/types';

interface ReadOnlyMoneyCellTypes {
  value: number | null;
  testid: string;
}

const ReadOnlyMoneyCell = ({ value, testid }: ReadOnlyMoneyCellTypes) => (
  <div>
    <div className="money pull-left"><span className="fa fa-dollar" /></div>
    <div className="money money-amount" data-testid={testid}>{value === null ? '-' : formatMoneyOrNull(value)}</div>
  </div>
);

interface MoneyCellTypes extends ReadOnlyMoneyCellTypes {
  editing: boolean;
  onChange: (newValue: any) => void;
}

const MoneyCell = ({ value, editing, onChange, testid}: MoneyCellTypes) => editing
  ? <EditableCell onChange={onChange} testid={testid} initialValue={value} inputType="number" decimal/>
  : <ReadOnlyMoneyCell value={value} testid={testid} />;

export default MoneyCell;
