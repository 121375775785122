import { Lens } from '@atomic-object/lenses';

interface SalesPlanState {
  readonly importSalesPlanModalShown: boolean;
  readonly importSalesPlanVarietyModalState: boolean;
  readonly selectedProductId: number | null;
}

export type Type = SalesPlanState;

export const InitialState: Readonly<SalesPlanState> = Object.freeze({
  importSalesPlanModalShown: false,
  importSalesPlanVarietyModalState: false,
  selectedProductId: null,
});

export const importSalesPlanModalShown = Lens.from<Type>().prop('importSalesPlanModalShown');
export const importSalesPlanVarietyModalState = Lens.from<Type>().prop('importSalesPlanVarietyModalState');
export const selectedProductId = Lens.from<Type>().prop('selectedProductId');
