import { connect } from 'react-redux';
import ProductDetail from 'client/components/crud-forms/product/product-detail';
import { OwnProps as ComponentOwnProps } from 'client/components/crud-forms/product/product-detail';
import * as Actions from './actions';
import assertCompatible from 'shared/helpers/assert-compatible';

export interface OwnProps {
  children: any;
}

export interface DispatchProps {
  handleSectionUnmounted: () => void;
}

export const mapDispatchToProps: DispatchProps = {
  handleSectionUnmounted: Actions.productDetailSectionUnmounted,
};

assertCompatible<ComponentOwnProps, DispatchProps>();

export default connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps)(ProductDetail);
