import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/vendor/vendor-graphql-types';
import { DateTimeStr } from 'shared/types';

export interface Input {
  vendorId: GraphQLTypes.EditVendorArgs['id'];
  newVendorValues: GraphQLTypes.EditVendorArgs['input'];
}

export interface Response {
  editVendor: {
    vendor: {
      id: number;
      identifier: string;
      name: string | null;
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      phone: string;
      fax: string;
      lastModifiedAt: DateTimeStr | null;
      contacts: Array<{
        id: number;
        name: string;
        email: string;
        phoneOffice: string;
        phoneCell: string;
        primary: boolean;
      }>;
    };
  };
}

export const Mutation = gql`
  mutation EditVendorMutation($vendorId: Int!, $newVendorValues: EditVendorInput!) {
    editVendor: EditVendor(id: $vendorId, input: $newVendorValues) {
      vendor {
        id
        identifier
        name
        address1
        address2
        city
        state
        zip
        country
        phone
        fax
        lastModifiedAt
        contacts {
          id
          name
          email
          phoneOffice
          phoneCell
          primary
        }
      }
    }
  }
`;
