import * as LensLib from '@atomic-object/lenses';
import * as Types from './types';
import * as Mutations from 'client/actions/mutations';

interface DistributionReceivingState {
  readonly isSendMicsModalShown: boolean;
  readonly sendMicsFileToMeijerActionStatus: Mutations.MutationStatus;
  readonly customerOrdersIdsAlreadySent: Types.CustomerOrderIdWithIdentifier[];
  readonly customerOrdersIdsNotFullyReceived: Types.CustomerOrderIdWithIdentifier[];
  readonly customerOrdersIdsToBeSent: Types.CustomerOrderIdWithIdentifier[];
  readonly customerOrdersIdsThatAreScanBased: Types.CustomerOrderIdWithIdentifier[];
}

export const InitialState: DistributionReceivingState = {
  isSendMicsModalShown: false,
  sendMicsFileToMeijerActionStatus: Mutations.MutationStatus.Initial,
  customerOrdersIdsNotFullyReceived: [],
  customerOrdersIdsAlreadySent: [],
  customerOrdersIdsToBeSent: [],
  customerOrdersIdsThatAreScanBased: [],
};

export type Type = DistributionReceivingState;

export const isSendMicsModalShown = LensLib.Lens.from<Type>().prop('isSendMicsModalShown');
export const sendMicsFileToMeijerActionStatus = LensLib.Lens.from<Type>().prop('sendMicsFileToMeijerActionStatus');
export const customerOrdersIdsNotFullyReceived = LensLib.Lens.from<Type>().prop('customerOrdersIdsNotFullyReceived');
export const customerOrdersIdsAlreadySent = LensLib.Lens.from<Type>().prop('customerOrdersIdsAlreadySent');
export const customerOrdersIdsToBeSent = LensLib.Lens.from<Type>().prop('customerOrdersIdsToBeSent');
export const customerOrdersIdsThatAreScanBased = LensLib.Lens.from<Type>().prop('customerOrdersIdsThatAreScanBased');
