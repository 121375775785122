import * as React from 'react';
import * as classNames from 'classnames';
import { ProductListItemDetail } from './product-list-item-detail';

export interface Props {
  header: string;
  items: Array<{
    id: number | string;
    elements: JSX.Element[];
  }>;
  isSelected: boolean;
  testId?: string;
  onClick?: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class ProductListItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { header, items, isSelected, testId} = this.props;

    const labelClassName = classNames('product-worksheet-products-list-product-label', {
      'product-worksheet-is-selected': isSelected,
    });

    return (
      <div>
        <div>
          <span className={labelClassName}>{header}</span>
        </div>
        {items.map(item => {
          return (
            <ProductListItemDetail
              elements={item.elements}
              testId={`${testId}-detail`}
              key={`${item.id}-detail`}
              onClick={this.props.onClick}
            />
          );
        })}
      </div>
    );
  }
}
