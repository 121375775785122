import { msyncMutation } from 'client/hoc/graphql/mutation';
import gql from 'graphql-tag';
import { CreateRoutePlanInput, EditRoutePlanInput, CreateRoutePlanResponse, EditRoutePlanResponse, MarkRoutePlanAsReadyToRouteResponse, RoutePlanId, ExtractRoutePlanResponse } from 'schema/route-plan/route-plan-typescript-types';
import { reset } from 'redux-form';
import { Action } from 'redux';
import { connect, MapDispatchToProps } from 'react-redux';
import { flowRight } from 'lodash';
import { RoutePlanLoadsTableQueryName } from 'client/app/transportation/routing/route-plan-details/loads/routing-loads-query';
import { PrintingListQueryName } from 'client/app/transportation/routing/route-plan-details/printing/printing-list-query';
import { FetchResult } from 'apollo-link';

export interface CreateRoutePlanMutationProps {
  createRoutePlan: (args: CreateRoutePlanInput) => Promise<FetchResult<CreateRoutePlanResponse> | undefined | void>;
}

export const CreateRoutePlanMutation = gql`
  mutation createRoutePlan($input: CreateRoutePlanInput!) {
    createRoutePlan: CreateRoutePlan(input: $input) {
      routePlan {
        id
        identifier
        customerId
        mfcAreaId
        origin
        sellDepartmentId
        deliveryDate
        lastModified
      }
    }
  }
`;

export const WithCreateRoutePlanMutation = msyncMutation<CreateRoutePlanResponse, {}, CreateRoutePlanMutationProps, { input: CreateRoutePlanInput }>(CreateRoutePlanMutation, {
  props: ({ mutate }) => {
    return {
      createRoutePlan: async args => {
        const result = await mutate({
          variables: {
            input: {
              customerId: args.customerId,
              deliveryDate: args.deliveryDate,
              identifier: args.identifier,
              mfcAreaId: args.mfcAreaId,
              origin: args.origin,
              sellDepartmentId: args.sellDepartmentId,
            },
          },
        });
        return result;
      },
    };
  },
});

export interface MarkRoutePlanAsReadyToRouteMutationProps {
  markRoutePlanAsReadyToRoute: (routePlanId: RoutePlanId) => Promise<FetchResult<MarkRoutePlanAsReadyToRouteResponse> | undefined | void>;
}

export const MarkRoutePlanAsReadyToRouteMutation = gql`
  mutation MarkRoutePlanAsReadyToRoute($routePlanId: Int!) {
    routePlanResponse: MarkRoutePlanAsReadyToRoute(routePlanId: $routePlanId) {
      routePlan {
        id
        lastModified
        status
        destination
        loadType
        dropoffLocation
        routeType
        carrier
        trailerTemperature
        forceReroute
        customerOrdersUpdatedSinceRoutePacketPrinting
      }
    }
  }
`;

export const markRoutePlanAsReadyToRouteMutationRefetchQueries = [RoutePlanLoadsTableQueryName, PrintingListQueryName];

export const WithMarkRoutePlanAsReadytoRouteMutation = msyncMutation<MarkRoutePlanAsReadyToRouteResponse, {}, MarkRoutePlanAsReadyToRouteMutationProps, { routePlanId: number }>(MarkRoutePlanAsReadyToRouteMutation, {
  options: {
    refetchQueries: markRoutePlanAsReadyToRouteMutationRefetchQueries,
  },
  props: ({ mutate }): MarkRoutePlanAsReadyToRouteMutationProps => {
    return {
      markRoutePlanAsReadyToRoute: (routePlanId: RoutePlanId) => mutate({
        variables: {
          routePlanId,
        },
      }),
    };
  },
  showBusyModal: true,
});

export interface ExtractRoutePlanMutationProps {
  extractRoutePlan: (routePlanId: RoutePlanId) => Promise<FetchResult<ExtractRoutePlanResponse> | undefined | void>;
}

export const ExtractRoutePlanMutation = gql`
  mutation extractRoutePlan($routePlanId: Int!) {
    extractRoutePlan: ExtractRoutePlan(routePlanId: $routePlanId) {
      routePlan {
        id
        lastModified
        status
      }
      directRouteCsv {
        id
      }
    }
  }
`;

export const WithExtractRoutePlanMutation = msyncMutation<ExtractRoutePlanResponse, {}, ExtractRoutePlanMutationProps, { routePlanId: number }>(ExtractRoutePlanMutation, {
  options: {
    refetchQueries: [RoutePlanLoadsTableQueryName, PrintingListQueryName],
  },
  props: ({ mutate }): ExtractRoutePlanMutationProps => {
    return {
      extractRoutePlan: (routePlanId: RoutePlanId) => mutate({
        variables: {
          routePlanId,
        },
      }),
    };
  },
  showBusyModal: true,
});

export interface EditRoutePlanMutationProps {
  editRoutePlan: (args: EditRoutePlanInput) => Promise<FetchResult<EditRoutePlanResponse> | undefined | void>;
}

export const EditRoutePlanMutation = gql`
  mutation editRoutePlan($input: EditRoutePlanInput!) {
    editRoutePlan: EditRoutePlan(input: $input) {
      routePlan {
        id
        identifier
        customerId
        mfcAreaId
        origin
        sellDepartmentId
        deliveryDate
        lastModified
      }
    }
  }
`;

interface DispatchProps {
  reset(formName: string): Action;
}

interface WithEditRoutePlanMutationOwnProps {
  form: string;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  reset,
};

export const WithEditRoutePlanMutation = flowRight(
  connect<{}, DispatchProps, {}>(undefined, mapDispatchToProps),
  msyncMutation<EditRoutePlanResponse, DispatchProps & WithEditRoutePlanMutationOwnProps, EditRoutePlanMutationProps, { input: EditRoutePlanInput }>(EditRoutePlanMutation, {
  props: (props): EditRoutePlanMutationProps => {
    return {
      editRoutePlan: (args: EditRoutePlanInput) => {
        const response = props.mutate({
          variables: {
            input: {
              // TODO: Diff this with intialValues
              id: args.id,
              customerId: args.customerId,
              deliveryDate: args.deliveryDate,
              ...(args.identifier ? { identifier: args.identifier } : {}),
              mfcAreaId: args.mfcAreaId,
              origin: args.origin,
              sellDepartmentId: args.sellDepartmentId,
            },
          },
        });

        // makes it blink but puts the right values in
        props.ownProps.reset(props.ownProps.form);

        return response;
      },
    };
  },
}));

export const GenerateDistributionRackShippingWorksheetMutation = gql`
  mutation generateDistributionRackShippingWorksheet($routePlanId: Int!) {
    report: GenerateDistributionRackShippingWorksheet(routePlanId: $routePlanId) {
      id
    }
  }
`;
