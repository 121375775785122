import * as _ from 'lodash';
import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/load-list';
import { propToComponent } from 'client/hoc/hoc';
import { ApplyInvoiceToLoadsModalUI, ComponentProps as UIProps } from './apply-invoice-to-loads-modal-ui';
import * as State from 'client/state/state';
import * as LoadListSelectors from 'client/state/load-list-selectors';
import assertCompatible from 'shared/helpers/assert-compatible';
import { Carrier } from 'shared/types';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { WithApplyInvoiceToLoadsQueryProps, ApplyInvoiceToLoadsResponse, ApplyInvoiceToLoadsQuery, ApplyInvoiceToLoadsInput } from 'client/app/transportation/loads/apply-invoice-to-loads-modal/apply-invoice-to-loads-modal-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { FetchPolicy } from 'apollo-client';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  isShown: boolean;
  hasInvoiceNumber: boolean;
  setCarrierButtonDisabled: boolean;
  routingLoadIds: RoutingLoadId[];
  carrier: Carrier;
  invoiceNumber: string;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const invoiceNumber = LoadListSelectors.enteredInvoiceNumber(state);

  return {
    isShown: LoadListSelectors.isApplyInvoiceToLoadsModalShown(state),
    hasInvoiceNumber: invoiceNumber.length > 0,
    setCarrierButtonDisabled: false, // TODO???
    routingLoadIds: LoadListSelectors.selectedRoutingLoadIds(state),
    carrier: LoadListSelectors.selectedCarrier(state),
    invoiceNumber,
  };
};

interface DispatchProps {
  handleApplyButtonClicked(): void;
  handleSetCarrierButtonClicked(): void;
  handleCancelButtonClicked(): void;
  handleCarrierChanged(carrier: string): void;
  handleInvoiceNumberChanged(invoiceNumber: string): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleApplyButtonClicked: Actions.handleAssignInvoiceToLoadsApplyClicked,
  handleCancelButtonClicked: Actions.hideApplyInvoiceToLoadsModal,
  handleCarrierChanged: Actions.handleCarrierChanged,
  handleInvoiceNumberChanged: Actions.handleInvoiceNumberChanged,
  handleSetCarrierButtonClicked: Actions.handleSetCarrierButtonClicked,
};

const WithApplyInvoiceToLoads = msyncQuery<ApplyInvoiceToLoadsResponse, OwnProps & StateProps & DispatchProps, WithApplyInvoiceToLoadsQueryProps, ApplyInvoiceToLoadsInput>(ApplyInvoiceToLoadsQuery, {
  alias: 'withApplyInvoiceToLoads',
  skip: (ownProps: OwnProps & StateProps & DispatchProps) => {
    return ownProps.routingLoadIds.length === 0;
  },
  options(ownProps: OwnProps & StateProps & DispatchProps): { variables: ApplyInvoiceToLoadsInput, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: {
          id: { values: ownProps.routingLoadIds },
        },
        sort: [
          {
            sortField: 'identifier',
            sortOrder: 'ASC',
          },
        ],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithApplyInvoiceToLoadsQueryProps {
    const { data } = props;

    const routingLoads
     = data.getRoutingLoads
      ? data.getRoutingLoads.routingLoads.map(gqlResult => {
        return {
          identifier: gqlResult.identifier,
          totalFee: gqlResult.totalFee,
          carrier: gqlResult.carrier.displayValue,
          invoiceNumber: gqlResult.invoiceNumber,
        };
      })
      : [];

    const carriersAllSame = _.uniqBy(routingLoads, 'carrier').length === 1;

    return {
      routingLoads,
      totalCost: _.sumBy(routingLoads, 'totalFee'),
      loading: data.loading,
      applyButtonDisabled: !props.ownProps.hasInvoiceNumber || !carriersAllSame,
    };
  },
});

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithApplyInvoiceToLoadsQueryProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  WithApplyInvoiceToLoads,
)(ApplyInvoiceToLoadsModalUI as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
