import gql from 'graphql-tag';

export const getAssociatedAttachmentsQuery = gql`
query getAssociatedAttachments($input: GetAssociatedAttachmentsResponseInput!) {
  getAssociatedAttachments: GetAssociatedAttachments(input: $input) {
    attachments {
      id
      createdAt
      filename
      url
      description
      size
      type
      createdBy
      objectKey
      extension
      humanReadableType
    }
    totalCount
    totalUnfilteredCount
  }
  getAttachmentFilterOptions: GetAttachmentFilterOptions(input: $input) {
    typesFilterOptions {
      id
      value
      displayValue
    }
    usersFilterOptions {
      id
      value
      displayValue
    }
  }
}
`;

export const getAssociatedAttachmentsExcelQuery = gql`
query getAssociatedAttachmentsForExcel($input: GetAssociatedAttachmentsResponseInput!) {
  getAssociatedAttachments: GetAssociatedAttachments(input: $input) {
    attachments {
      id
      createdAt
      filename
      url
      description
      size
      humanReadableType
      createdBy
    }
  }
}
`;
