import * as React from 'react';

export interface Props {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
  simple?: boolean;
  onClick?: () => void;
  'testid'?: string;
}

export function RecordBarDetail(props: Props) {
  return props.simple
    ? <div className="detail" data-testid={props.testid} onClick={props.onClick}>{props.children}</div>
    : (
      <div className="detail" data-testid={props.testid} onClick={props.onClick}>
        <div className="heading">{props.title}</div>
        <span className="content">{props.children}</span>
      </div>
    );
}
