import * as React from 'react';
import { CELL_TYPES, TYPES, DateStr } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { CART_TRACKING_SUPPLIER_TABLE_NAME } from 'client/constants';
import { ComponentProps as CartTrackingSupplierComponentProps } from 'client/app/transportation/cart-tracking/details/overview/cart-tracking-supplier-table-container';

export interface CartTrackingSupplierTableRow {
  id: number;
  entryDate: DateStr;
  entryType: string;
  notes?: string;
  delivered?: number;
  returned?: number;
  balance?: number;
}

const columns: IColumn[] = [
  {
    id: 'entryDate',
    accessor: 'entryDate',
    header: 'Entry Date',
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
    columnWidth: 6,
  },
  {
    id: 'entryType',
    accessor: 'entryType',
    header: 'Transaction Type',
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    overflowWidth: true,
    columnWidth: 10,
  },
  {
    id: 'notes',
    accessor: 'notes',
    header: 'Notes',
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    columnWidth: 54,
  },
  {
    id: 'delivered',
    accessor: 'delivered',
    header: 'Delivered', // TODO: Negative quantity?
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
    columnWidth: 10,
  },
  {
    id: 'returned',
    accessor: 'returned',
    header: 'Returned', // TODO: Positive quantity?
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
    columnWidth: 10,
  },
  {
    id: 'balance',
    accessor: 'balance',
    header: 'Balance',
    tableEditable: false,
    sortable: false,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
    columnWidth: 10,
  },
];

export class CartTrackingSupplierTable extends React.Component<CartTrackingSupplierComponentProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(CART_TRACKING_SUPPLIER_TABLE_NAME);
  }

  noop() { /* no-op */}
  none = [];

  public render() {
    const tracked = this.props.cartTrackingStatus === SupplierCartTrackingStatus.Track;
    return (
      <div id= "mfc-page-content">
        <div className="cart-tracking-suppliers-table-wrapper">
          <this.FilterableTable
            table={CART_TRACKING_SUPPLIER_TABLE_NAME}
            content={this.props.tableRows || this.none}
            loading={this.props.loading}
            columns={columns || this.none}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={this.noop}
            loadMoreRecords={this.noop}
            searchableFields={this.none}
            availableFilters={this.none}
            placeholder={tracked ? 'There are currently no entries for this supplier.' : 'Enable tracking for this supplier to add entries.'}
            tablePaginated={false}
            tableParentInfo={this.props.tableParentInfo}
            list={false}
            displayLoadingIndicator={true}
            newButtonLabel="New Transaction"
            onNewClicked={this.props.newTransactionButtonClicked}
            disableCreate={this.props.cartTrackingStatus !== SupplierCartTrackingStatus.Track}
          />
        </div>
      </div>
    );
  }
}
