import * as React from 'react';
import { VendorRecordBar } from 'client/app/admin/vendors/vendor-record-bar';
import { VendorForm, VendorFormValues } from 'client/app/admin/vendors/vendor-form';
import { FormSubmitHandler } from 'redux-form';

export interface UIProps {
  vendorId?: number;
  onSubmit: FormSubmitHandler<VendorFormValues, { initialValues: { identifier: string } }>;
  initialValues?: shame;
}

export class VendorEditFormUI extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <div id="mfc-page-content">
        <VendorRecordBar vendorId={this.props.vendorId || 0}/>
        <VendorForm vendorId={this.props.vendorId} onSubmit={this.props.onSubmit} initialValues={this.props.initialValues}/>
      </div>
    );
  }
}
