import * as React from 'react';
import { Button } from 'client/components/third-party';
import { ButtonClickHandler } from 'client/types';

interface CancelButtonUIProps {
  label: string;
  onClick?: ButtonClickHandler;
  disabled: boolean;
  testid?: string;
}

export class GlobalCancelButtonUI extends React.PureComponent<CancelButtonUIProps, {}> {
  render() {
    return (
      <Button data-testid={this.props.testid} disabled={this.props.disabled} bsClass="mfc-button mfc-clear-button" onClick={this.props.onClick}>
        {this.props.label || 'Cancel'}
      </Button>
    );
  }
}
