import * as State from './state';
import * as Actions from './actions';

const ActionTypeKeys = Actions.ActionTypeKeys;

export default function reducer(state = State.InitialState, action: Actions.ActionTypes): State.Type {
  switch (action.type) {
    case ActionTypeKeys.MarkingRoutePlansStarted: {
      return State.isMarkingRoutesLens.set(state, true);
    }

    case ActionTypeKeys.MarkingRoutePlansFinished: {
      return State.isMarkingRoutesLens.set(state, false);
    }

    default:
      return state;
  }
}
