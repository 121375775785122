import gqlTag from 'graphql-tag';

export interface RoutePlanLoadsStatsQueryResponse {
  routePlanLoadsSummary?: {
    routePlan?: {
      id: number;
      loads?: {
        numberOfStores: number;
        racks: number;
        pallets: number;
        dropsCombinedWithAdditionalDrops: number;
        totalFee: number;
        milesCombinedWithAdditionalMiles: number;
        loadCount: number;
      };
    };
  };
}

export const GetRoutePlanLoadsStats = gqlTag`
query GetRoutePlanLoadsStats($id: Int!) {
  routePlanLoadsSummary: GetRoutePlan(id: $id) {
    routePlan {
      id
      loads {
        numberOfStores
        racks
        pallets
        dropsCombinedWithAdditionalDrops
        totalFee
        milesCombinedWithAdditionalMiles
        loadCount
      }
    }
  }
}
`;
