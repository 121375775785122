import {
  tableName, property, belongsTo, definePresentation, required,
} from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES } from '../types';

import { Product } from './product';
import { Pot } from './pot';
import { Tray } from './tray';
import { Tag } from './tag';
import { IRecord } from './record';
import { displayType } from './dsl';
import { PotDisplayValueResolver, TrayDisplayValueResolver, TagDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('productSpecs')
export class ProductSpec implements IRecord {
  id: number;
  @property @required specYear: string;
  @property @required specComplete: boolean;
  @property @required retailRequired: boolean;

  @belongsTo('products')
  @property @required product: Product;

  @belongsTo('pots')
  @property pot: Pot;

  @belongsTo('trays')
  @property tray: Tray;

  @belongsTo('tags')
  @property tag: Tag;

  @property generalNotes?: string | null;
  @property decorationNotes?: string | null;
}

definePresentation(ProductSpec, {
  specYear: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    displayName: 'Spec Year',
    type: TYPES.NUMBER,
  },
  specComplete: {
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
    displayName: 'Spec Complete',
  },
  retailRequired: {
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
    displayName: 'Retail Required',
  },
  product: {
    filterable: true,
    formDisplay: false,
    includeInFormQuery: true,
    displayName: 'Product',
  },
  pot: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: PotDisplayValueResolver }),
    displayName: 'Pot',
  },
  tray: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: TrayDisplayValueResolver }),
    displayName: 'Tray',
  },
  tag: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: TagDisplayValueResolver }),
    displayName: 'Tag',
  },
  generalNotes: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
    displayName: 'General Notes',
  },
  decorationNotes: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
    displayName: 'Decoration Notes',
  },
});
