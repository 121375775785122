import * as React from 'react';
import { CELL_TYPES, TYPES, ShippingUnitType, RackShippingUnit, OrderMethod, ShippingUnitOrderMethod, PackOrderMethod, ReceivableOrderType } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME } from 'client/constants';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { Col } from 'client/components/third-party';
import { AvailableSearchField, AvailableFilter } from 'client/types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { createSelector } from 'reselect';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { determineReceivedStatusFormatting } from 'shared/helpers/receiving-status';
import { ReceivingStatsRow } from 'client/app/transportation/receiving/details/stats-row/receiving-stats-container';

export interface ReceivingOverviewRow {
  id: number;
  productIdentifier: string;
  productDescription: string;
  upc?: string | null;
  rackType: string;
  retailPrice: number;
  packSize: number | null;
  packsPerShelf: number | null;
  shelvesPerRack: number | null;
  packsPerShippingUnit: number | null;
  shippingUnitQuantity: number | null;
  packQuantity: Int;
  shippingUnitQuantityReceivedTotal: number | null;
  packQuantityReceivedTotal: Int | null;
}

export interface ReceivingOverviewComponentProps {
  tableRows: ReceivingOverviewRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  tableParentInfo: TableParentInfo;
  onRowSelect(): void;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  dataRequest: MsyncDataRequest;
  receivableOrderType: ReceivableOrderType | undefined;
  receivableOrderId: number;
}

const tableName = TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME;

const getColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod, receivableOrderType: ReceivableOrderType): IColumn[] => {
  let baseColumns: IColumn[] = [
    {
      id: 'productIdentifier',
      accessor: 'productIdentifier',
      header: 'Product ID',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
    {
      id: 'productDescription',
      accessor: 'productDescription',
      header: 'Description',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
      overflowWidth: true,
    },
    {
      id: 'upc',
      accessor: 'upc',
      header: 'UPC',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
    {
      id: 'rackType',
      accessor: 'rackType',
      header: 'Rk Type',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
  ];

  if (receivableOrderType === ReceivableOrderType.SupplierOrder) {
    baseColumns = baseColumns.concat([
      {
        id: 'retailPrice',
        accessor: 'retailPrice',
        header: 'Retail',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.MONEY,
        type: TYPES.FLOAT,
      },
    ]);
  }

  baseColumns = baseColumns.concat([
    {
      id: 'packSize',
      accessor: 'packSize',
      header: getPackSizeHeader(receivableOrderType),
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
    },
  ]);

  if (shippingUnitType === RackShippingUnit) {
    baseColumns = baseColumns.concat([
      {
        id: 'packsPerShelf',
        accessor: 'packsPerShelf',
        header: 'PPS',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
      {
        id: 'shelvesPerRack',
        accessor: 'shelvesPerRack',
        header: 'SPR',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ]);
  }

  if (receivableOrderType === ReceivableOrderType.SupplierOrder) {
    baseColumns = baseColumns.concat([
      {
        id: 'packsPerShippingUnit',
        accessor: 'packsPerShippingUnit',
        header: getPacksPerShippingUnitHeader(shippingUnitType, orderMethod),
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
      {
        id: 'shippingUnitQuantity',
        accessor: 'shippingUnitQuantity',
        header: shippingUnitType === RackShippingUnit ? 'Rks Ordered' : 'Plts Ordered',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ]);
  }

  if (orderMethod === PackOrderMethod) {
    baseColumns = baseColumns.concat([
      {
        id: 'packQuantity',
        accessor: 'packQuantity',
        header: getPackQuantityHeader(shippingUnitType),
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ]);
  }

  baseColumns = baseColumns.concat([
    {
      id: getReceivedTotalAccessor(orderMethod),
      accessor: getReceivedTotalAccessor(orderMethod),
      header: getReceivedHeader(shippingUnitType, orderMethod),
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.DASH_ZERO_NUMBER,
      type: TYPES.NUMBER,
      getClassNames: (val: string, row: ReceivingOverviewRow) => {
        const ordered = row[getOrderedAccessor(orderMethod)] || 0;
        const received = row[getReceivedTotalAccessor(orderMethod)] || 0;

        return determineReceivedStatusFormatting(received, ordered);
      },
    },
  ]);

  const mappedColumns = baseColumns
  .map(column => {
    const columnWidth
      = column.id === 'productDescription' ? (200 / (baseColumns.length + 2)) // 2 columns for description
      : column.id === 'primaryUpc'         ? (200 / (baseColumns.length + 2)) // 2 columns for description
      :                                      (100 / (baseColumns.length + 2));

    return {
      ...column,
      columnWidth,
    };
  });

  return mappedColumns;
};

type PackSizeHeader = 'Supp Pk' | 'Pack';
const getPackSizeHeader = (orderType: ReceivableOrderType): PackSizeHeader => {
  return orderType === ReceivableOrderType.SupplierOrder
    ? 'Supp Pk'
    : 'Pack';
};

type PacksPerShippingUnitHeader = 'PPR' | 'PPP' | 'CPP';
const getPacksPerShippingUnitHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): PacksPerShippingUnitHeader => {
  if (shippingUnitType === RackShippingUnit) {
    return 'PPR';
  }
  if (orderMethod === ShippingUnitOrderMethod) {
    return 'PPP';
  } else {
    return 'CPP';
  }
};

type PackQuantityHeader = 'Cases Ordered' | 'Pks Ordered';
const getPackQuantityHeader = (shippingUnitType: ShippingUnitType): PackQuantityHeader => {
  if (shippingUnitType === RackShippingUnit) {
    return 'Pks Ordered';
  }
  return 'Cases Ordered';
};

type RacksReceivedHeader = 'Rks Received' | 'Plts Received' | 'Pks Received' | 'Cases Received';
const getReceivedHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): RacksReceivedHeader => {
  if (orderMethod === OrderMethod.ShippingUnit) {
    if (shippingUnitType === ShippingUnitType.Rack) {
      return 'Rks Received';
    }
    return 'Plts Received';
  }
  if (shippingUnitType === ShippingUnitType.Rack) {
    return 'Pks Received';
  }
  return 'Cases Received';
};

type OrderedAccessor = 'packQuantity' | 'shippingUnitQuantity';
const getOrderedAccessor = (orderMethod: OrderMethod): OrderedAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantity';
  }

  return 'shippingUnitQuantity';
};

type ReceivedTotalAccessor = 'shippingUnitQuantityReceivedTotal' | 'packQuantityReceivedTotal';
const getReceivedTotalAccessor = (orderMethod: OrderMethod): ReceivedTotalAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantityReceivedTotal';
  }

  return 'shippingUnitQuantityReceivedTotal';
};

const getShippingUnitType = (props: ReceivingOverviewComponentProps): ShippingUnitType => props.shippingUnitType;
const getOrderMethod = (props: ReceivingOverviewComponentProps): OrderMethod => props.orderMethod;
const getReceivableOrderType = (props: ReceivingOverviewComponentProps): ReceivableOrderType | undefined => props.receivableOrderType;
const columnsSelector = createSelector([getShippingUnitType, getOrderMethod, getReceivableOrderType], getColumns);

export class ReceivingOverviewContent extends React.Component<ReceivingOverviewComponentProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  public render() {
    const columns = columnsSelector(this.props);

    return (
      <div className="mfc-form-details-with-sidebar" data-testid="receiving-order-details-table">
        <Col sm={12}>
          <ReceivingStatsRow
            receivableOrderId={this.props.receivableOrderId}/>
          <this.FilterableTable
            table={tableName}
            content={this.props.tableRows || []}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={() => { /* nothing?? */ }}
            loadMoreRecords={() => { /* nothing?? */ }}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={'There are no products on this order.'}
            checkable={false}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            disableCreate={true}
            displayLoadingIndicator={true}
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
          />
        </Col>
      </div>
    );
  }
}
