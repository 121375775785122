import * as React                            from 'react';
import {    useCallback      }               from 'react';
import {    useDispatch      , useSelector } from 'react-redux';
import {    Modal            , Button }      from 'client/components/third-party';
import * as Actions                          from 'client/actions/product-worksheet';
import {    GlobalSaveButton }               from 'client/components/async-button/global-save-button-container';

export const RemoveProductModal = (p: {
  handleRemoveButtonClicked: () => void,
}) => {
  const dispatch = useDispatch();
  const handleCancelButtonClicked = useCallback(() => dispatch(Actions.removeProductModalCancelButtonClicked()), [dispatch]);
  const rdx = useSelector(({productWorksheet: wksht}) => ({
    isShown: wksht.removeProductModalShown,
    removingMany: (wksht.removeCustomerOrderProductGroupIds || []).length > 0,
  }));
  return (
    <Modal
      id="add-new-product-modal"
      animation
      backdrop="static"
      show={rdx.isShown}
      onHide={handleCancelButtonClicked}>
      <Modal.Body>
        {rdx.removingMany
          ? 'Removing these shipment configurations will remove allocations.'
          : 'Removing this shipment configuration will remove allocations.'
        }
      </Modal.Body>
      <Modal.Footer className="remove-product-modal-footer">
        <Button bsClass="mfc-button mfc-clear-button" onClick={handleCancelButtonClicked}>
          Cancel
        </Button>
        <GlobalSaveButton
          label={'Remove'}
          testid={'modal-submit'}
          onClick={p.handleRemoveButtonClicked}
        />
      </Modal.Footer>
    </Modal>
  );
};
