import * as _ from 'lodash';
import * as Types from './types';
import { createSelector } from 'reselect';
import * as Constants from '../../constants';

export const getColumnCount = (props: Types.HeaderProps) => {
  return props.columns.length;
};

const getColumns = (props: Types.HeaderProps) => {
  return props.columns;
};

export const getHeaderWidth = (props: Types.HeaderProps) => {
  return props.headerWidth;
};

export const getHasVerticalScrollbar = (props: Types.HeaderProps) => {
  return true; // TBD until we can find a better solution sometime  props.hasVerticalScrollbar;
};

export const getTwinHasVerticalScrollbar = (props: Types.HeaderProps) => {
  return props.twinHasVerticalScrollbar;
};

const getGrowableColumnHeader = (props: Types.HeaderProps) => props.growableColumnHeader;

const getTotalColumnWidth = createSelector([
  getColumns,
], columns => _.sumBy(columns, 'width'));

export const getColumnWidthFunction = createSelector([
  getColumns,
  getGrowableColumnHeader,
  getHeaderWidth,
  getTotalColumnWidth,
  getHasVerticalScrollbar,
  getTwinHasVerticalScrollbar,
], (columns, growableColumnHeader, headerWidth, totalColumnWidth, hasVerticalScrollbar, twinHasVerticalScrollbar) => index => {
  const isLastColumn = columns.length === index + 1;

  const scrollbarWidthAdjustment = hasVerticalScrollbar // && !twinHasVerticalScrollbar
    ? Constants.SCROLLBAR_WIDTH
    : 0;

  return growableColumnHeader === columns[index].header && headerWidth > totalColumnWidth
    ? columns[index].width + (_.max([headerWidth - totalColumnWidth - scrollbarWidthAdjustment - 2, 0]) ?? 0)
    : isLastColumn
      ? columns[index].width + scrollbarWidthAdjustment
      : columns[index].width;
});

export const getRowHeight = (props: Types.HeaderProps) => {
  return props.rowHeight;
};

export const getRowHeightFunction = createSelector([
  getRowHeight,
], rowHeight => () => rowHeight);

const getTableName = (props: Types.HeaderProps) => props.tableName;

const getCurrentSort = (props: Types.HeaderProps) => props.currentSort;

const getOnClick = (props: Types.HeaderProps) => props.onClick;

export const getItemDataForHeaderCell = createSelector([
  getColumns,
  getTableName,
  getCurrentSort,
  getOnClick,
], (columns, tableName, currentSort, handleClick) => {
  return {
    headerData: columns.map(c => c.header),
    currentSort,
    handleClick,
    tableName,
  };
});
