import { tableName, property, setSchemaOptions, belongsTo } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { SupplierId, Supplier } from 'shared/schemas/supplier';

export type CartTrackingSupplierId = Flavor<number, 'cartTrackingSupplierId'>;
export type CartTrackingSupplierTotalCarts = Flavor<number, 'cartTrackingSupplierTotalCart'>;

@tableName('cartTrackingSuppliers')
export class CartTrackingSupplier implements IRecord {
  static readonly UniqueConstraints: Array<keyof CartTrackingSupplier> = ['supplier'];
  id?: CartTrackingSupplierId;

  @belongsTo('suppliers')
  @property supplier: Supplier;
  supplierId: SupplierId;

  @property totalCarts?: CartTrackingSupplierTotalCarts | null;
}

// Someday, when we don't have to worry about the possibility of invalid data
// coming in from VBills, this can be enabled. In the meantime, not worth it
// (even though there's no indication VBills will ever send a negative number).
// defineFieldValidators(CartTrackingSupplier, {
//   totalCarts: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
// });

setSchemaOptions(CartTrackingSupplier, {
  hasTimestamps: true,
  uniqueConstraints: CartTrackingSupplier.UniqueConstraints,
});
