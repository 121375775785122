import * as React from 'react';
import { StoreList } from 'client/app/transportation/routing/import-route-modal/step-three/store-list';
import { SplitStopTable } from 'client/app/transportation/routing/import-route-modal/step-three/split-stop-table';

export interface ComponentProps {
  showSplitStopsTable: boolean;
}

export class ImportRouteModalContentStepThreeUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    if (this.props.showSplitStopsTable) {
      return (
        <div>
          <StoreList />
          <SplitStopTable/>
        </div>
      );
    }
    return (
      <div className="import-route-modal-placeholder-message" data-testid="import-route-modal-step-three" >
        There are no split stops to process.
      </div>
    );
  }
}
