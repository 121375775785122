import { tableName, property, belongsTo, definePresentation, required, setSchemaOptions, manyToMany, hasMany } from './dsl';
import { DISPLAY_TYPES, SupplierOrderStatus, TYPES, DateStr, SupplierOrderTypes, OrderMethod, ShippingUnitType, DeliveryMethods, INPUT_TYPES, ReceivableOrderReceivingStatus, CELL_TYPES } from '../types';
import { Supplier, SupplierId } from './supplier';
import { Customer, CustomerId } from './customer';
import { SellDepartment, SellDepartmentId } from './sell-department';
import { MfcArea, MfcAreaId } from './mfc-area';
import { SubSellDepartment, SubSellDepartmentId } from './sub-sell-department';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { SupplierOrderProductGroup } from 'shared/schemas/supplier-order-product-group';
import { Bol } from 'shared/schemas/bol';
import { SupplierDisplayValueResolver, CustomerDisplayValueResolver, MfcAreaDisplayValueResolver, SupplierLocationDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { DateTimeStr } from 'shared/types/date-str';
import { User } from 'shared/schemas/user';
import { ReceivableOrder } from './receivable-order';
import { SupplierLocation } from './supplier-location';

export type SupplierOrderId = Flavor<number, 'supplierOrderId'>;
export type SupplierOrderIdentifier = Flavor<string, 'supplierOrderIdentifier'>;
export type SupplierOrderPlannedArrivalDate = Flavor<DateStr, 'supplierOrderPlannedArrivalDate'>;
export type SupplierOrderStoreDeliveryDate = Flavor<DateStr, 'supplierOrderStoreDeliveryDate'>;
export type SupplierOrderInvoiceNumber = Flavor<string, 'supplierOrderInvoiceNumber'>;
export type SupplierOrderNotes = Flavor<string, 'supplierOrderInvoiceNotes'>;
export type SupplierOrderInvoiced = Flavor<boolean, 'supplierOrderInvoiced'>;
export type SupplierOrderEmailedAt = Flavor<DateTimeStr, 'supplierOrderEmailedAt'>;
export type SupplierOrderLastModifiedAt = Flavor<DateTimeStr, 'supplierOrderLastModifiedAt'>;

@tableName('supplierOrders')
export class SupplierOrder implements IRecord {
  static readonly UniqueConstraints: Array<keyof SupplierOrder> = ['identifier'];
  id?: SupplierOrderId;
  @property identifier: SupplierOrderIdentifier;

  @belongsTo('suppliers', { foreignDisplayKey: 'name' })
  @property @required supplier: Supplier;
  supplierId: SupplierId;

  @belongsTo('supplierLocations', { foreignDisplayKey: 'identifier' })
  @property supplierLocation?: SupplierLocation;
  supplierLocationId?: number | null;

  @belongsTo('customers', { foreignDisplayKey: 'name' })
  @property @required customer: Customer;
  customerId: CustomerId;

  @belongsTo('sellDepartments')
  @property @required sellDepartment: SellDepartment;
  sellDepartmentId: SellDepartmentId;

  @belongsTo('subSellDepartments')
  @property subSellDepartment?: SubSellDepartment;
  subSellDepartmentId?: SubSellDepartmentId;

  @belongsTo('mfcAreas')
  @property mfcArea: MfcArea;
  mfcAreaId?: MfcAreaId;

  @belongsTo('receivableOrders')
  @property @required receivableOrder: ReceivableOrder;
  receivableOrderId: number;

  @manyToMany('customerOrders', {through: 'relatedOrders', foreignDisplayKey: 'identifier' })
  @property customerOrders?: CustomerOrder[];

  @hasMany('supplierOrderProductGroups')
  @property supplierOrderProductGroups: SupplierOrderProductGroup[];

  @hasMany('bols', { foreignFindByRecordType: 'ReceivableOrder', foreignTablePK: 'receivableOrderId' } )
  @property bols?: Bol[];

  @property orderStatus?: SupplierOrderStatus | null;  // Calculated
  @property @required orderType: SupplierOrderTypes;
  @property @required orderMethod: OrderMethod;
  @property @required shippingUnitType: ShippingUnitType;
  @property @required plannedArrivalDate: SupplierOrderPlannedArrivalDate;
  @property deliveryMethod?: DeliveryMethods | null;
  @property invoiceNumber?: SupplierOrderInvoiceNumber | null;
  @property invoiced: SupplierOrderInvoiced;
  @property notes?: SupplierOrderNotes | null;
  @property receivingStatus?: ReceivableOrderReceivingStatus | null;
  @property invoice: boolean;
  @property storeDeliveryDate?: SupplierOrderStoreDeliveryDate | null;
  @property warehouse: boolean | null;
  @property orderDate?: DateStr | null;  // Set the first time a PO is emailed (in m-sync), it's the created time (but also editable) in VBills

  // This property drives the orderStatus. If emailedAt, then Sent; otherwise Draft.
  @property emailedAt?: DateTimeStr | null;

  @property lastModifiedAt?: DateTimeStr | null;

  @belongsTo('users', { nativeTableFK: 'lastModifiedUserId', foreignDisplayKey: 'firstName', foreignQueryKeys: ['firstName', 'lastName'] })
  @property lastModifiedUser?: User | null;
  lastModifiedUserId?: number | null;

  @property revised: boolean;

  @hasMany('supplierOrderMails')
  @property mails: SupplierOrderProductGroup[];

  @property totalRacks: number;
  @property invoiceTotal: number;
}

definePresentation(SupplierOrder, {
  identifier: {
    sortable: true,
    searchable: true,
    displayName: 'PO #',
    tableDisplay: true,
    columnWidth: 2,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  supplier: {
    tableDisplay: true,
    sortable: true,
    filterable: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }),
  },
  supplierLocation: {
    tableDisplay: false,
    // too hard to get working right now. DVM 2021 06 24
    // sortable: true,
    // filterable: true,
    // columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierLocationDisplayValueResolver }),
  },
  customer: {
    tableDisplay: true,
    sortable: true,
    filterable: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
  },
  sellDepartment: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 9,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  subSellDepartment: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    tableDisplay: false,
  },
  mfcArea: {
    displayName: 'MFC Area',
    tableDisplayColumns: { identifier: 'MFC Area' },
    sortable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: MfcAreaDisplayValueResolver }),
  },
  orderType: {
    sortable: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: SupplierOrderTypes }),
    tableDisplay: true,
    columnWidth: 10,
  },
  plannedArrivalDate: {
    displayName: 'MFC Arrival Date',
    sortable: true,
    tableDisplay: true,
    columnWidth: 8,
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
  },
  storeDeliveryDate: {
    displayName: 'Store Delivery Date',
    sortable: true,
    tableDisplay: true,
    filterable: true,
    columnWidth: 8,
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
  },
  invoiceNumber: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  invoiced: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
  },
  notes: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
  },
  orderStatus: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    calculationSpec: {
      dependencies: {},
      calculation: () => `CASE WHEN supplier_orders.emailed_at IS NULL THEN '${SupplierOrderStatus.Draft}' ELSE '${SupplierOrderStatus.Sent}' END`,
    },
  },
  receivingStatus: {
    sortable: true,
    type: TYPES.STRING,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    calculationSpec: {
      dependencies: {},
      calculation: () => `(SELECT receiving_status FROM receivable_orders WHERE receivable_orders.id = supplier_orders.receivable_order_id)`,
    },
  },
  invoice: {
    sortable: true,
    type: TYPES.BOOLEAN,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
  },
  emailedAt: {
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    tableDisplay: false,
  },
  orderMethod: {
    sortable: true,
    displayName: 'Order Method',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: OrderMethod }),
  },
  shippingUnitType: {
    displayName: 'Shipping Unit',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ShippingUnitType }),
  },
  deliveryMethod: {
    displayName: 'Delivery Method',
    formDisplayType: displayType(DISPLAY_TYPES.RADIO, { options: DeliveryMethods }),
    defaultValue: DeliveryMethods.Deliver,
  },
  warehouse: {
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    type: TYPES.BOOLEAN,
  },
  revised: {
    type: TYPES.BOOLEAN,
    tableDisplay: false,
    calculationSpec: {
      dependencies: {},
      calculation: () => `CASE WHEN supplier_orders.emailed_at IS NULL THEN false ELSE true END`,
    },
  },
  mails: {
    includeInFormQuery: false,
  },
  totalRacks: {
    displayName: 'Total Racks',
    columnWidth: 5,
    sortable: true,
    tableDisplay: true,
    formDisplayType: { type: CELL_TYPES.DASH_ZERO_NUMBER } as shame, // This could use some work. There's some differences between columns generated by the schema (like this one) and hand created columns, around the formDisplayType and setting a CELL_TYPE
    type: TYPES.NUMBER,
    calculationSpec: {
      dependencies: {},
      calculation: p => `
        (
          SELECT round(SUM(
            case when supplier_orders.shipping_unit_type != '${ShippingUnitType.Rack}' then 0 else
              case when coalesce(supplier_order_product_groups.pack_quantity, 0) = 0 then 0 else 1.0 *
	              coalesce(supplier_order_product_groups.pack_quantity, 0.0) /
	              supplier_order_product_groups.packs_per_shipping_unit
              end
            end
          ))
          FROM
            supplier_order_product_groups
          WHERE
            supplier_order_product_groups.supplier_order_id = supplier_orders.id
        )
      `,
    },
  },
  invoiceTotal: {
    displayName: 'Invoice Total',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: {},
      calculation: p => `
        (
          SELECT SUM(
            (
              1.0 *
              COALESCE(supplier_order_products.pack_size, 0) *
              COALESCE(supplier_order_products.cost, 0) *
              COALESCE(supplier_order_products.pack_quantity, 0)
            ) + COALESCE(supplier_order_products.additional_cost, 0))
          FROM
            supplier_order_products
          INNER
            JOIN supplier_order_product_groups ON supplier_order_products.supplier_order_product_group_id = supplier_order_product_groups.id
          WHERE
            supplier_order_product_groups.supplier_order_id = supplier_orders.id
        )
      `,
    },
  },
  receivableOrder: {
    gqlType: { typeName: 'UnifiedReceivableOrder', isRequired: true, isArray: false },
  },
});

setSchemaOptions(SupplierOrder, {
  defaultSort: { sortField: 'storeDeliveryDate', sortOrder: 'DESC' },
  uniqueConstraints: SupplierOrder.UniqueConstraints,
  hasLastModifiedInfo: true,
});
