import * as React from 'react';
import { useEffect } from 'react';
import * as _ from 'lodash';
import { Prompt } from 'react-router-dom';
import { useObject } from 'client/lib/react';

/**
 * NOTE: This will never warn a user on a "new record" page. This is intentional
 *       as new record pages can be incorrectly marked as dirty because of
 *       how the "dispatchDefaults" is being used to set initial values. If that is
 *       fixed then this warning can be used for the new record page as well.
 */
export const useWarnUnsaved = (dirty?: boolean, recordId?: number) => {
  // lambdas form closure on this object, but its props can still change. like ref.current.
  const detected = useObject({ unsavedChanges: dirty && !_.isNil(recordId) });
  useEffect(() => {
    const handler = e => {
      if (!detected.unsavedChanges) return;
      // This will warn the user if there are unsaved changes and they try to reload or change the URL in the browser's URL bar.
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return detected;
};

interface OwnProps { record?: { id?: number }, dirty: boolean }

/**
 * This should come after a reduxForm container, which is needed to provide the "dirty" prop.
 * ---
 * forwards to `useWarnUnsaved` internally
 */
export const WithWarnUnsaved = (opts: { recordIdProperty: string }) =>
  (WrappedComponent: new (props: OwnProps) => React.Component<OwnProps, any>) => {
    const WithWarnUnsavedComponent = (p: OwnProps) => {
      const recordId = _.get(p, opts.recordIdProperty);
      const detected = useWarnUnsaved(p.dirty, recordId);
      // This will warn the user if there are unsaved changes and they try to navigate to another page using a link in the app.
      return (
        <>
          <Prompt when={detected.unsavedChanges} message="There are unsaved changes. Are you sure you want to leave the page?" />
          <WrappedComponent {...p} />
        </>
      );
    };

    return WithWarnUnsavedComponent;
  };
