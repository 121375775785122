export enum FileUploadState {
  Queued = 'Queued',
  Uploading = 'Uploading',
  Complete = 'Complete',
  Failed = 'Failed',
}

export type FileUploadStatus = {
  state: FileUploadState.Queued;
  filename: string;
  objectKey: string;
} | {
  state: FileUploadState.Uploading;
  filename: string;
  objectKey: string;
} | {
  state: FileUploadState.Complete;
  filename: string;
  objectKey: string;
} | {
  state: FileUploadState.Failed;
  filename: string;
  objectKey: string;
  errors: string[];
};
