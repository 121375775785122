import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { LoadDetailsStatsRowUI } from './stats-row-ui';
import { RoutingLoadId, RoutingLoadGQLResult, RoutingLoadTotalFee } from 'schema/routing-load/routing-load-graphql-types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { RoutingLoadStatsRowResponse, RoutingLoadStatsRowQuery } from 'client/app/transportation/loads/stats-row/stats-row-query';

interface OwnProps {
  routingLoadId: RoutingLoadId;
}

export interface RoutingLoadFormValues {
  id: number;
  identifier: string;
}

interface WithStatCardProps {
  dropsCombinedWithAdditionalDrops?: RoutingLoadGQLResult['dropsCombinedWithAdditionalDrops'];
  racks?: RoutingLoadGQLResult['racks'];
  pallets?: RoutingLoadGQLResult['pallets'];
  milesCombinedWithAdditionalMiles?: RoutingLoadGQLResult['milesCombinedWithAdditionalMiles'];
  totalFee?: RoutingLoadTotalFee;
}

const WithStatCardsData = msyncQuery<RoutingLoadStatsRowResponse, OwnProps, WithStatCardProps, { routingLoadId: RoutingLoadId }>(RoutingLoadStatsRowQuery, {
  alias: 'WithStatCardsData',

  options(ownProps: OwnProps): { variables: { routingLoadId: RoutingLoadId } } {
    return {
      variables: {
        routingLoadId: ownProps.routingLoadId,
      },
    };
  },
  props(props): WithStatCardProps {
    const { data } = props;
    if (data.loading || !data.routingLoadStatsRowResult) {
      return {
      };
    }
    const routingLoad = data.routingLoadStatsRowResult.routingLoad;

    return {
      dropsCombinedWithAdditionalDrops: routingLoad.dropsCombinedWithAdditionalDrops,
      milesCombinedWithAdditionalMiles: routingLoad.milesCombinedWithAdditionalMiles,
      pallets: routingLoad.pallets,
      racks: routingLoad.racks,
      totalFee: routingLoad.totalFee,
    };
  },
});

export type ComponentProps =
  OwnProps;

const component = flowRight(
  WithStatCardsData,
)(LoadDetailsStatsRowUI);

export const LoadDetailsStatsRow = (props: OwnProps) => propToComponent(component, props);
