import { tableName, property, setSchemaOptions, belongsTo, definePresentation } from './dsl';
import { Bol, BolId } from 'shared/schemas/bol';
import { IRecord } from 'shared/schemas/record';
import { TYPES, DISPLAY_TYPES, INPUT_TYPES } from 'shared/types';
import { displayType } from './dsl';
import { ReceivableOrderDetail } from 'shared/schemas/receivable-order-detail';

export type BolDetailId = Flavor<number, 'bolDetailId'>;
export type BolDetailPacksReceived = Flavor<number, 'bolDetailPacksReceived'>;

@tableName('bolDetails')
export class BolDetail implements IRecord {
  static readonly UniqueConstraints: Array<keyof BolDetail> = ['bol', 'receivableOrderDetail'];
  id?: BolDetailId;

  @belongsTo('bols', { nativeTableFK: 'bolId' })
  @property bol: Bol;
  bolId: BolId;

  @belongsTo('receivableOrderDetails')
  @property receivableOrderDetail: ReceivableOrderDetail;
  receivableOrderDetailId: number;

  @property packsReceived: BolDetailPacksReceived;
}

definePresentation(BolDetail, {
  packsReceived: {
    displayName: 'Packs Received',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
});

setSchemaOptions(BolDetail, {
  hasTimestamps: true,
  uniqueConstraints: BolDetail.UniqueConstraints,
});
