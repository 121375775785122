import * as React from 'react';
import NumberCell from 'client/components/table/number-cell';
import * as classNames from 'classnames';

export interface LoadColumnFooterCellUIProps {
  loadNumber: string;
  spacesRemainingInLoad: number;
}

export class LoadColumnFooterCellUI extends React.PureComponent<LoadColumnFooterCellUIProps, {}> {
  onChangeHandler = () => {
    // Footer should never get an onChange
  }

  render() {
    const props = this.props;

    const className = classNames({
      'load-column-footer-cell-error': props.spacesRemainingInLoad < 0,
      'rendered-cell': true,
    });

    return (
      <div className={className} data-testid={`load-column-footer-cell-load-number-${props.loadNumber}`}>
        <NumberCell
          editing={false}
          value={props.spacesRemainingInLoad}
          onChange={this.onChangeHandler}
          testid="footer-cell"
        />
      </div>
    );
  }
}
