import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import { BolList } from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list';
import { BolId } from 'shared/schemas/bol';
import * as Actions from 'client/actions/receiving-worksheet';
import { BolListResponse, BolListQuery } from 'client/app/transportation/receiving/details/worksheet/bol-list/bol-list-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { toDateTimeStr, DateTimeStr } from 'shared/types';
import { sortNaturally } from 'shared/helpers/sort-naturally';
import * as State from 'client/state/state';
import { showAutoCartTrackingWarning } from 'shared/helpers/cart-tracking-enabled';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { ThunkerDispatch } from 'client/types/redux-types';

interface OwnProps {
  receivableOrderId: number;
  requestedBolId?: BolId;
}

interface WithBolListProps {
  bols: Array<{
    id: number;
    identifier: string;
    receivedAt: DateTimeStr;
    user: string;
    autoCartTrackingDisabled: boolean;
  }>;
}

const WithBolList = msyncQuery<BolListResponse, OwnProps, WithBolListProps>(BolListQuery, {
  alias: 'withBolList',
  options(ownProps: OwnProps): { variables, fetchPolicy } {
    return {
      variables: {
        id: ownProps.receivableOrderId,
      },
      fetchPolicy: 'network-only', // DK 2018-04-06
    };
  },
  props({ data, ownProps }): WithBolListProps {
    if (!data.receivableOrder || !data.receivableOrder.bols) {
      return {
        bols: [],
      };
    }
    const shippingUnitType = data.receivableOrder.shippingUnitType;
    const orderMethod = data.receivableOrder.orderMethod;
    const supplierCartTrackingStatus = data.receivableOrder.supplier ? data.receivableOrder.supplier.cartTrackingStatus : SupplierCartTrackingStatus.DoNotTrack;

    return {
      bols: sortNaturally(data.receivableOrder.bols, [{ sortField: o => o.identifier }]).map(bol => {
        const autoCartTrackingDisabled = showAutoCartTrackingWarning({
          autoCartTrackingEnabled: bol.autoCartTrackingEnabled,
          shippingUnitType,
          orderMethod,
          supplierCartTrackingStatus,
        });
        return {
          id: bol.id,
          identifier: bol.identifier,
          receivedAt: toDateTimeStr(bol.receivedAt),
          user: `${bol.user.firstName} ${bol.user.lastName[0]}.`,
          autoCartTrackingDisabled,
        };
      }),
    };
  },
});

const mapStateToProps = (state: State.Type) => {
  return {
    selectedBolId: State.selectedBolId(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps) => {
  return {
    onAddBolClicked: () => {
      dispatch(Actions.setAddBolModalVisibility(true));
    },
    onBolClicked: (bolId: number) => {
      dispatch(Actions.bolClicked(props.receivableOrderId, bolId));
    },

    redirectToReceivingWorksheet: () => {
      dispatch(Actions.redirectToReceivingWorksheet(props.receivableOrderId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  WithBolListProps &
  ConnectedProps<typeof connector>;

export default wrapComponent(BolList)<OwnProps, CombinedProps>(
  connector,
  WithBolList,
);
