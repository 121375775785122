import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/route-plan-printing';
import { propToComponent } from 'client/hoc/hoc';
import { SendMerchandisersAsnModalUI, ComponentProps as UIProps } from './ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { CheckableMerchandisersWithStores, isMerchandisersSelectAllChecked } from 'client/state/route-plan-printing';

export interface OwnProps {
  routePlanId?: RoutePlanId;
}

interface StateProps {
  isShown: boolean;
  sendButtonDisabled: boolean;
  merchandisersWithStores: CheckableMerchandisersWithStores[];
  selectAllChecked: boolean;
  searchText: string;
  numSelected: number;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const isShown = RoutePlanPrintingSelectors.isEmailMerchandisersAsnModalShown(state);
  const searchText = state.routePlanPrinting.merchandiserSearchText;

  const stateMerchandisersWithStores = state.routePlanPrinting.merchandisersWithStores;
  const merchandisersWithStores = stateMerchandisersWithStores
    .filter(merchandiserWithStores => {
      if (searchText === '') {
        return true;
      }
      return merchandiserWithStores.name.toLowerCase().includes(searchText.toLowerCase()) ||
        merchandiserWithStores.storeIdentifiers.some(storeIdentifier => storeIdentifier.includes(searchText));
    });

  return {
    isShown,
    merchandisersWithStores,
    sendButtonDisabled: stateMerchandisersWithStores.length === 0 || stateMerchandisersWithStores.every(mws => !mws.checked),
    selectAllChecked: isMerchandisersSelectAllChecked(state.routePlanPrinting),
    searchText,
    numSelected: stateMerchandisersWithStores.filter(mws => mws.checked).length,
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleSendButtonClicked(routePlanId: RoutePlanId): void;
  handleMerchandiserChecked(merchandiserId: number): void;
  handleSelectAllClicked(): void;
  handleSearch(text: string): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.merchandisersCancelButtonClickedAction,
  handleSendButtonClicked: Actions.merchandisersSendButtonClickedAction,
  handleMerchandiserChecked: Actions.selectedMerchandisersChanged,
  handleSelectAllClicked: Actions.merchandisersSelectAllClicked,
  handleSearch: Actions.merchandisersSearched,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(SendMerchandisersAsnModalUI) as Component<OwnProps>;

export const SendMerchandisersAsnModalContainer = (props: OwnProps) => propToComponent(component, props);
