import * as Redux from 'redux';
import * as State from 'client/state/state';
import * as ReportUserParamsTypes from 'shared/types/report-user-params';

export type MapToReportUserParams = (state: State.Type) => ReportUserParamsTypes.ReportUserParamsInput;
export type MapFromReportUserParams = (dispatch: Redux.Dispatch, params: ReportUserParamsTypes.ReportUserParams) => void;

export enum PresetActionTypes {
  Save = 'save',
  SaveAs = 'saveAs',
  SetAsDefault = 'setAsDefault',
  Reload = 'reload',
  Delete = 'delete',
}
