import * as _ from 'lodash';
import * as FileSaver from 'client/utils/filesaver';
import { send } from 'shared/send';
import { Dispatch } from 'redux';
import { RoutePlanActionTypes, LoadConfiguration, groupBy, Return } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import { RoutePlanId, EditRoutePlanResponse, MarkCustomerOrdersAsShippedResponse, MarkCustomerOrdersAsShippedInput, MerchandisersWithStores, MerchandisersWithStoresResponse, GetMerchandisersWithStoresInput, EmailMerchandiserSpecificASNsInput, EmailMerchandiserSpecificASNsResponse, StoresWithMerchandisers, StoresWithMerchandisersResponse, GetStoresWithMerchandisersInput } from 'schema/route-plan/route-plan-typescript-types';
import gql from 'graphql-tag';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { Action } from 'redux';
import { downloadRoutePacket } from 'client/app/transportation/routing/route-plan-details/printing/create-route-packet-modal/download-route-packet';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { mutationSent, removeRecentlyReceivedMutationResponse, mutationResponseReceived } from 'client/actions/mutations';
import * as ErrorActions from 'client/actions/error';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import * as State from 'client/state/state';
import { downloadDittyCards } from 'client/app/transportation/routing/route-plan-details/printing/download-ditty-card-modal/download-ditty-card';
import { timeout } from 'shared/helpers';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { resetTable } from 'client/actions/table';
import { TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME } from 'client/constants';
import { isNil } from 'ramda';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import { sortNaturally } from 'shared/helpers/sort-naturally';

export type ActionTypes =
  RouteSheetsClickedAction |
  DeliveryAcknowledgementsClickedAction |
  PurchaseOrderInvoicesClickedAction |
  PurchaseOrderInvoicesChangedAction |
  ReturnInstructionsClickedAction |
  SpecialInstructionsClickedAction |
  MarkOrdersAsShippedClickedAction |
  CreateRoutePacketsModalClosedAction |
  DownloadDittyCardModalVisibilityAction |
  DownloadDittyCardModalClosedAction |
  DownloadDittyCardModalCreateAction |
  LoadConfigurationChangedAction |
  DownloadDittyCardMenuItemClicked |
  CreateRoutePacketsModalVisibilityAction |
  SelectedRoutingLoadIdsChangedAction |
  CreateRoutePacketsModalVisibilityAction |
  SendLoadsToLoadMasterStartedAction |
  SendLoadsToLoadMasterCompletedAction |
  SendLoadsToLoadMasterFailedAction |
  SendLoadsToLoadMasterDoneDoneAction |
  EmailRouteSheetsToPeakStartedAction |
  EmailRouteSheetsToPeakCompletedAction |
  EmailRouteSheetsToPeakFailedAction |
  EmailRouteSheetsToPeakDoneDoneAction |
  EmailMerchandisersModalVisibilityAction |
  SetMerchandisersWithStoresAction |
  SelectedMerchandisersChangedAction |
  MerchandisersSelectAllClickedAction |
  MerchandisersSearchedAction |
  CreateCustomerAdvanceShipNoticesStartedAction |
  CreateCustomerAdvanceShipNoticesCompleteAction |
  DownloadStoreDeliverySpreadsheetStartedAction |
  DownloadStoreDeliverySpreadsheetCompleteAction |
  DownloadStoreAdvanceShipNoticesStartedAction |
  DownloadStoreSpecificAdvanceShipNoticesPDFCompletedAction |
  DownloadStoreAdvanceShipNoticesFailedAction |
  DownloadIGPSPalletReportStartedAction |
  DownloadIGPSPalletReportDoneAction |
  DownloadIGPSPalletReportCompletedAction |
  DownloadIGPSPalletReportFailedAction |
  DownloadStoreAdvanceShipNoticesButtonClickedAction |
  DownloadStoreAdvanceShipNoticesCancelButtonClickedAction |
  SetStoresWithMerchandisersAction |
  SelectedStoresChangedAction |
  StoresSearchedAction |
  StoresSelectAllClickedAction
  ;

export enum ActionTypeKeys {
  ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY',
  ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY',
  ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY',
  ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED = 'App/ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED',
  ROUTE_PLAN_SET_MERCHANDISERS_WITH_STORES = 'App/ROUTE_PLAN_SET_MERCHANDISERS_WITH_STORE',
  SELECTED_MERCHANDISERS_CHANGED = 'App/SELECTED_MERCHANDISERS_CHANGED',
  MERCHANDISERS_SELECT_ALL_CLICKED = 'App/MERCHANDISERS_SELECT_ALL_CLICKED',
  MERCHANDISERS_SEARCHED = 'App/MERCHANDISERS_SEARCHED',
  MERCHANDISERS_CANCEL_BUTTON_CLICKED_ACTION = 'App/MERCHANDISERS_CANCEL_BUTTON_CLICKED_ACTION',

  CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_STARTED = 'App/CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_STARTED',
  CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_COMPLETE = 'App/CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_COMPLETE',

  DOWNLOAD_STORE_DELIVERY_SPREADSHEET_STARTED = 'App/DOWNLOAD_STORE_DELIVERY_SPREADSHEET_STARTED',
  DOWNLOAD_STORE_DELIVERY_SPREADSHEET_COMPLETE = 'App/DOWNLOAD_STORE_DELIVERY_SPREADSHEET_COMPLETE',

  DOWNLOAD_STORE_ASN_BUTTON_CLICKED = 'App/DOWNLOAD_STORE_ASN_BUTTON_CLICKED',
  DOWNLOAD_STORE_ASN_SET_STORES_WITH_MERCHANDISERS = 'App/DOWNLOAD_STORE_ASN_SET_STORES_WITH_MERCHANDISERS',
  DOWNLOAD_STORE_ASN_CANCEL_BUTTON_CLICKED = 'App/DOWNLOAD_STORE_ASN_CANCEL_BUTTON_CLICKED',
  DOWNLOAD_STORE_ASN_STARTED = 'App/DOWNLOAD_STORE_ASN_STARTED',
  DOWNLOAD_STORE_ASN_FAILED = 'App/DOWNLOAD_STORE_ASN_FAILED',
  DOWNLOAD_STORE_ASN_COMPLETED = 'App/DOWNLOAD_STORE_ASN_COMPLETED',
  DOWNLOAD_STORE_ASN_SEARCHED = 'App/DOWNLOAD_STORE_ASN_SEARCHED',
  DOWNLOAD_STORE_ASN_SELECT_ALL_CLICKED = 'App/DOWNLOAD_STORE_ASN_SELECT_ALL_CLICKED',
  DOWNLOAD_STORE_ASN_STORE_CHANGED = 'App/DOWNLOAD_STORE_ASN_STORE_CHANGED',

  ROUTE_SHEETS_CLICKED = 'App/ROUTE_SHEETS_CLICKED',
  DELIVERY_ACKNOWLEDGEMENTS_CLICKED = 'App/DELIVERY_ACKNOWLEDGEMENTS_CLICKED',
  PURCHASE_ORDER_INVOICES_CLICKED = 'App/PURCHASE_ORDER_INVOICES_CLICKED',
  PURCHASE_ORDER_INVOICES_CHANGED = 'App/PURCHASE_ORDER_INVOICES_CHANGED',
  RETURN_INSTRUCTIONS_CLICKED = 'App/RETURN_INSTRUCTIONS_CLICKED',
  SPECIAL_INSTRUCTIONS_CLICKED = 'App/SPECIAL_INSTRUCTIONS_CLICKED',
  MARK_ORDERS_AS_SHIPPED_CLICKED = 'App/MARK_ORDERS_AS_SHIPPED_CLICKED',
  DOWNLOAD_DITTY_CARD_MODAL_CLOSED = 'App/DOWNLOAD_DITTY_CARD_MODAL_CLOSED',
  DOWNLOAD_DITTY_CARD_MODAL_CREATE_CLICKED = 'App/DOWNLOAD_DITTY_CARD_MODAL_CREATE_CLICKED',
  PRINTING_ORDER_CHANGED = 'App/PRINTING_ORDER_CHANGED',
  LOAD_CONFIGURATION_CHANGED = 'App/LOAD_CONFIGURATION_CHANGED',
  CREATE_ROUTE_PACKET_MENU_ITEM_CLICKED = 'App/CREATE_ROUTE_PACKET_MENU_ITEM_CLICKED',
  DOWNLOAD_DITTY_CARD_MENU_ITEM_CLICKED = 'App/DOWNLOAD_DITTY_CARD_MENU_ITEM_CLICKED',
  SELECTED_ROUTING_LOAD_IDS_CHANGED = 'App/SELECTED_ROUTING_LOAD_IDS_CHANGED',

  ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_STARTED = 'App/ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_STARTED',
  ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_COMPLETED = 'App/ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_COMPLETED',
  ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_FAILED = 'App/ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_FAILED',
  ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_DONE_DONE = 'App/ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_DONE_DONE',

  ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_STARTED = 'App/ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_STARTED',
  ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_COMPLETED = 'App/ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_COMPLETED',
  ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_FAILED = 'App/ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_FAILED',
  ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_DONE_DONE = 'App/ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_DONE_DONE',

  ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_STARTED = 'App/ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_STARTED',
  ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_DONE = 'App/ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_DONE',
  ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_COMPLETED = 'App/ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_COMPLETED',
  ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_FAILED = 'App/ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_FAILED',
}

export interface CreateRoutePacketsModalVisibilityAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY;
  payload: {
    visible: boolean;
  };
}

export const createRoutePacketDownloadPdfButtonClicked = (): CreateRoutePacketsModalVisibilityAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY,
    payload: { visible: true },
  };
};

export interface CreateRoutePacketsModalClosedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED;
}

export function createRoutePacketModalCancelButtonClicked(): CreateRoutePacketsModalClosedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED,
  };
}

export interface DownloadDittyCardModalClosedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CLOSED;
}

export function downloadDittyCardModalCancelButtonClicked(): DownloadDittyCardModalClosedAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CLOSED,
  };
}

export interface DownloadDittyCardModalCreateAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CREATE_CLICKED;
}

export function downloadDittyCardModalCreateButtonClicked(routePlanId?: RoutePlanId): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const state = getState();
    const loadConfiguration = RoutePlanPrintingSelectors.getSelectedLoadConfiguration(state);
    const selectedRoutingLoadIds = RoutePlanPrintingSelectors.selectedRoutingLoadIds(state);

    // Dispatch status changes for the 'Create' action loading status (for the button on the modal)
    try {
      dispatch(mutationSent(false));
      let routePlanIdentifier = 'Multiple';

      if (!isNil(routePlanId)) {
        const routePlanIdentifierResult: shame = await msyncClientQuery({
          variables: { id: routePlanId },
          dispatch,
          query: routePlanIdentifierQuery,
        });
        routePlanIdentifier = routePlanIdentifierResult.data.routePlanResult.routePlan.identifier;
      }

      await downloadDittyCards({
        routePlanId,
        routePlanIdentifier,
        fileNameSuffix: 'Ditty Card',
        loadConfiguration,
        selectedRoutingLoadIds,
      });

      if (!isNil(routePlanId))
        await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.DownloadDittyCards);

      dispatch({ type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CREATE_CLICKED });
    } catch (err) {
      dispatch(ErrorActions.unexpectedErrorReceived({ message: err.message, stack: err.stack }));
    } finally {
      dispatch(mutationResponseReceived());
      dispatch(removeRecentlyReceivedMutationResponse());
      dispatch({ type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MODAL_CLOSED });
    }
  };
}

export interface RouteSheetsClickedAction extends Action {
  type: ActionTypeKeys.ROUTE_SHEETS_CLICKED;
}

export function routeSheetsClicked(): RouteSheetsClickedAction {
  return {
    type: ActionTypeKeys.ROUTE_SHEETS_CLICKED,
  };
}
export interface DeliveryAcknowledgementsClickedAction extends Action {
  type: ActionTypeKeys.DELIVERY_ACKNOWLEDGEMENTS_CLICKED;
}

export function deliveryAcknowledgementsClicked(): DeliveryAcknowledgementsClickedAction {
  return {
    type: ActionTypeKeys.DELIVERY_ACKNOWLEDGEMENTS_CLICKED,
  };
}
export interface PurchaseOrderInvoicesClickedAction extends Action {
  type: ActionTypeKeys.PURCHASE_ORDER_INVOICES_CLICKED;
}

export function purchaseOrderInvoicesClicked(): PurchaseOrderInvoicesClickedAction {
  return {
    type: ActionTypeKeys.PURCHASE_ORDER_INVOICES_CLICKED,
  };
}

export interface PurchaseOrderInvoicesChangedAction extends Action {
  type: ActionTypeKeys.PURCHASE_ORDER_INVOICES_CHANGED;
  payload: { selected: boolean };
}

export function purchaseOrderInvoicesChanged(selected: boolean): PurchaseOrderInvoicesChangedAction {
  return {
    type: ActionTypeKeys.PURCHASE_ORDER_INVOICES_CHANGED,
    payload: {
      selected,
    },
  };
}

export interface ReturnInstructionsClickedAction extends Action {
  type: ActionTypeKeys.RETURN_INSTRUCTIONS_CLICKED;
}

export function returnInstructionsClicked(): ReturnInstructionsClickedAction {
  return {
    type: ActionTypeKeys.RETURN_INSTRUCTIONS_CLICKED,
  };
}

export interface SpecialInstructionsClickedAction extends Action {
  type: ActionTypeKeys.SPECIAL_INSTRUCTIONS_CLICKED;
}
export function specialInstructionsClicked(): SpecialInstructionsClickedAction {
  return {
    type: ActionTypeKeys.SPECIAL_INSTRUCTIONS_CLICKED,
  };
}

export interface MarkOrdersAsShippedClickedAction extends Action {
  type: ActionTypeKeys.MARK_ORDERS_AS_SHIPPED_CLICKED;
}

export function markOrdersAsShippedClicked(): MarkOrdersAsShippedClickedAction {
  return {
    type: ActionTypeKeys.MARK_ORDERS_AS_SHIPPED_CLICKED,
  };
}

const routePlanIdentifierQuery = gql`
query routePlanIdentifierQuery($id: Int!) {
  routePlanResult: GetRoutePlan(id: $id) {
    routePlan {
      id
      identifier
    }
  }
}`;

interface CreateInvoicesFromCustomerOrdersInput {
  customerOrderIds: number[];
}

interface CreateInvoicesFromCustomerOrdersResponse {
  response: {
    invoice: Array<{
      id: number;
    }>;
  };
}

const createInvoicesFromCustomerOrdersMutation = gql`
  mutation CreateInvoicesForValidCustomerOrders($customerOrderIds: [Int!]!) {
    response: CreateInvoicesForValidCustomerOrders(customerOrderIds: $customerOrderIds) {
      invoices {
        id
      }
    }
  }
`;

export function createRoutePacketModalCreateButtonClicked(args: { routePlanId?: RoutePlanId, specialInstructionsPdfFile?: File | null, customerOrderIdsToBeInvoiced: number[] }): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const state = getState();
    const markOrdersAsShippedChecked = RoutePlanPrintingSelectors.isRoutePacketMarkOrdersAsShippedChecked(state);
    const routeSheetsChecked = RoutePlanPrintingSelectors.isRoutePacketRouteSheetsChecked(state);
    const deliveryAcknowledgementsChecked = RoutePlanPrintingSelectors.isRoutePacketDeliveryAcknowledgementsChecked(state);
    const purchaseOrderInvoicesChecked = RoutePlanPrintingSelectors.isRoutePacketPurchaseOrderInvoicesChecked(state);
    const returnInstructionsChecked = RoutePlanPrintingSelectors.isRoutePacketReturnInstructionsChecked(state);
    const specialInstructionsChecked = RoutePlanPrintingSelectors.isRoutePacketSpecialInstructionsChecked(state);
    const selectedRoutingLoadIds = RoutePlanPrintingSelectors.selectedRoutingLoadIds(state);

    // Dispatch status changes for the 'Create' action loading status (for the button on the modal)
    try {
      dispatch(mutationSent(false));

      let routePlanIdentifier = 'Multiple';

      if (!isNil(args.routePlanId)) {
        const routePlanIdentifierResult: shame = await msyncClientQuery({
          variables: { id: args.routePlanId },
          dispatch,
          query: routePlanIdentifierQuery,
        });

        routePlanIdentifier = routePlanIdentifierResult.data.routePlanResult.routePlan.identifier;
      }

      // Create invoices for all the invoiceable customer orders on this route plan
      if (purchaseOrderInvoicesChecked) {
        await msyncClientMutation<CreateInvoicesFromCustomerOrdersResponse, CreateInvoicesFromCustomerOrdersInput>({
          mutation: createInvoicesFromCustomerOrdersMutation,
          variables: {
            customerOrderIds: args.customerOrderIdsToBeInvoiced,
          },
          dispatch,
        });
      }

      if (routeSheetsChecked || deliveryAcknowledgementsChecked || purchaseOrderInvoicesChecked || returnInstructionsChecked || specialInstructionsChecked) {
        await downloadRoutePacket({
          routePlanId: args.routePlanId,
          routePlanIdentifier,
          fileNameSuffix: 'Route Packet',
          routeSheetsChecked,
          deliveryAcknowledgementsChecked,
          purchaseOrderInvoicesChecked,
          returnInstructionsChecked,
          specialInstructionsChecked,
          selectedRoutingLoadIds,
          specialInstructionsPdfFile: args.specialInstructionsPdfFile,
        });
      }

      if (markOrdersAsShippedChecked) {
        await markCustomerOrdersAsShippedMutation(dispatch, { routePlanId: args.routePlanId, routingLoadIds: selectedRoutingLoadIds });
      }

      if (!isNil(args.routePlanId)) {
        await editRoutePlanActionsMutation(dispatch, args.routePlanId, RoutePlanActionTypes.CreateRoutePackets);
      }

      dispatch({
        type: ActionTypeKeys.ROUTE_PLAN_CREATE_ROUTE_PACKETS_MODAL_CLOSED,
      });
    } catch (exception) {
      if (exception.isExpectedError) {
        dispatch(ErrorActions.expectedErrorReceived({ message: exception.message, debugInfo: exception.debugInfo }));
      } else {
        dispatch(ErrorActions.unexpectedErrorReceived({ message: exception.message, stack: exception.stack }));
      }
    } finally {
      dispatch(mutationResponseReceived());
      dispatch(removeRecentlyReceivedMutationResponse());
    }
  };
}

export interface DownloadDittyCardModalVisibilityAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY;
  payload: {
    visible: boolean;
    routePlanId?: RoutePlanId;
  };
}

export const setDittyCardModalVisibility = (visible: boolean, routePlanId?: RoutePlanId): DownloadDittyCardModalVisibilityAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY,
    payload: { visible, routePlanId },
  };
};

export const downloadDittyCardsPDFButtonClicked = (routePlanId: RoutePlanId): DownloadDittyCardModalVisibilityAction => {
  return setDittyCardModalVisibility(true, routePlanId);
};

export interface EmailMerchandisersModalVisibilityAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY;
  payload: {
    visible: boolean;
  };
}

export const setMerchandiserEmailsModalVisibility = (visible: boolean): EmailMerchandisersModalVisibilityAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY,
    payload: { visible },
  };
};

export interface SetMerchandisersWithStoresAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_MERCHANDISERS_WITH_STORES;
  payload: {
    merchandisersWithStores: MerchandisersWithStores[];
  };
}

export const setMerchandisersWithStores = (merchandisersWithStores: MerchandisersWithStores[]): SetMerchandisersWithStoresAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_MERCHANDISERS_WITH_STORES,
    payload: { merchandisersWithStores },
  };
};

const GetMerchandisersWithStoresQuery = gql`
  query GetMerchandisersWithStoresQuery($routePlanId: Int!) {
    getMerchandisersWithStores: GetMerchandisersWithStores(routePlanId: $routePlanId) {
      merchandisersWithStores {
        merchandiserId
        name
        storeIdentifiers
      }
    }
  }
`;

export const emailMerchandisersButtonClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    const getMerchandisersWithStoresResponse = await msyncClientQuery<{ getMerchandisersWithStores: MerchandisersWithStoresResponse }, GetMerchandisersWithStoresInput>({
      variables: { routePlanId },
      dispatch,
      query: GetMerchandisersWithStoresQuery,
    });

    dispatch(setMerchandisersWithStores(getMerchandisersWithStoresResponse.data.getMerchandisersWithStores.merchandisersWithStores));

    dispatch(setMerchandiserEmailsModalVisibility(true));
  };
};

export interface SelectedMerchandisersChangedAction extends Action {
  type: ActionTypeKeys.SELECTED_MERCHANDISERS_CHANGED;
  payload: {
    merchandiserId: number;
  };
}

export function selectedMerchandisersChanged(merchandiserId: number): SelectedMerchandisersChangedAction {
  return {
    type: ActionTypeKeys.SELECTED_MERCHANDISERS_CHANGED,
    payload: {
      merchandiserId,
    },
  };
}

export interface MerchandisersSelectAllClickedAction extends Action {
  type: ActionTypeKeys.MERCHANDISERS_SELECT_ALL_CLICKED;
  payload: {};
}

export function merchandisersSelectAllClicked(): MerchandisersSelectAllClickedAction {
  return {
    type: ActionTypeKeys.MERCHANDISERS_SELECT_ALL_CLICKED,
    payload: {},
  };
}

export interface MerchandisersSearchedAction extends Action {
  type: ActionTypeKeys.MERCHANDISERS_SEARCHED;
  payload: {
    searchText: string;
  };
}

export function merchandisersSearched(searchText: string): MerchandisersSearchedAction {
  return {
    type: ActionTypeKeys.MERCHANDISERS_SEARCHED,
    payload: {
      searchText,
    },
  };
}

export const downloadCustomerAdvanceShipNoticesPDFButtonClicked = (routePlanId: RoutePlanId): Thunker => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(createCustomerAdvanceShipNoticesStarted());
    const { content: pdfBlob, filename } = await send({
      fetch,
      url: `/report/customerSummaryASN/${routePlanId}`, // !!!! ALERT by using the merchandiser-specific ASN report with nil merchandiserIds, this is how the button on Route Plans -> Printing -> Download Summary Advance Ship Notices works; it is NOT to be confused with same page -> Actions => Store Summary Report
      method: 'GET',
      asBlob: true,
      includeFilename: true,
    });

    FileSaver.saveAs(pdfBlob, filename);
    dispatch(createCustomerAdvanceShipNoticesComplete());
    return await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.CreateCustomerAsn);
  } catch (err) {
    dispatch(ErrorActions.unexpectedErrorReceived({ message: err.message, stack: err.stack }));
  }
};

const downloadIGPSPalletReport = async (routePlanId: RoutePlanId) => {
  const { content, filename } = await send({
    fetch,
    url: `/igps-pallet-report/${routePlanId}`,
    method: 'GET',
    asBlob: true,
    includeFilename: true,
  });
  FileSaver.saveAs(content, filename);
};

export const downloadPalletReportCSVButtonClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(downloadIGPSPalletReportStarted());
      await downloadIGPSPalletReport(routePlanId);
      await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.CreatePalletReport);
      dispatch(downloadIGPSPalletReportCompleted());

    } catch (err) {
      dispatch(downloadIGPSPalletReportFailed());
    }
  };
};

export interface DownloadIGPSPalletReportStartedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_STARTED;
}
export function downloadIGPSPalletReportStarted(): DownloadIGPSPalletReportStartedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_STARTED,
  };
}

export interface DownloadIGPSPalletReportDoneAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_DONE;
}

export interface DownloadIGPSPalletReportCompletedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_COMPLETED;
}
export function downloadIGPSPalletReportCompleted(): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_DONE }); // Show the check mark
    await timeout(1500);
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_COMPLETED }); // Back to normal text
  };
}

export interface DownloadIGPSPalletReportFailedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_FAILED;
}
export function downloadIGPSPalletReportFailed(): DownloadIGPSPalletReportFailedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_IGPS_PALLET_REPORT_FAILED,
  };
}

export const sendLoadsToLoadMasterClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(sendLoadsToLoadMasterStarted());
    try {
      await sendLoadsToLoadMaster(dispatch, routePlanId);
      dispatch(sendLoadsToLoadMasterCompleted());
    } catch (err) {
      dispatch(sendLoadsToLoadMasterFailed());
    }
  };
};

const SEND_LOADS_TO_LOADMASTER_MUTATION = gql`
  mutation SendLoadsToLoadMasterViaPrintScreen($routePlanId: Int!) {
    mutationResult: SendLoadsToLoadMaster(routePlanId: $routePlanId) {
      routePlan {
        id
        actions {
          sendLoadsToLoadmaster {
            completed
            completedAt
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }`;
const sendLoadsToLoadMaster = (dispatch: Dispatch<any>, routePlanId: number) => {
  return msyncClientMutation<any>({
    mutation: SEND_LOADS_TO_LOADMASTER_MUTATION,
    dispatch,
    variables: {
      routePlanId,
    },
  });
};

export interface SendLoadsToLoadMasterStartedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_STARTED;
}
export function sendLoadsToLoadMasterStarted(): SendLoadsToLoadMasterStartedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_STARTED,
  };
}

export interface SendLoadsToLoadMasterCompletedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_COMPLETED;
}

export interface SendLoadsToLoadMasterDoneDoneAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_DONE_DONE;
}
export function sendLoadsToLoadMasterCompleted(): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_COMPLETED }); // Show the check mark
    await timeout(1500);
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_DONE_DONE }); // Back to normal text
  };
}

export interface SendLoadsToLoadMasterFailedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_FAILED;
}
export function sendLoadsToLoadMasterFailed(): SendLoadsToLoadMasterFailedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SEND_LOADS_TO_LOADMASTER_FAILED,
  };
}

export const emailRouteSheetsToPeakClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(emailRouteSheetsToPeakStarted());
    try {
      await emailRouteSheetsToPeak(dispatch, routePlanId);
      dispatch(emailRouteSheetsToPeakCompleted());
    } catch (err) {
      dispatch(emailRouteSheetsToPeakFailed());
    }
  };
};

export const EMAIL_ROUTE_SHEETS_TO_PEAK_MUTATION = gql`
  mutation EmailRouteSheetsToPeakViaPrintScreen($routePlanId: Int!) {
    mutationResult: EmailRouteSheetsToPeak(routePlanId: $routePlanId) {
      routePlan {
        id
        actions {
          emailRouteSheetsToPeak {
            completed
            completedAt
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }`;
const emailRouteSheetsToPeak = (dispatch: Dispatch<any>, routePlanId: number) => {
  return msyncClientMutation<any>({
    mutation: EMAIL_ROUTE_SHEETS_TO_PEAK_MUTATION,
    dispatch,
    variables: {
      routePlanId,
    },
  });
};

export interface EmailRouteSheetsToPeakStartedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_STARTED;
}
export function emailRouteSheetsToPeakStarted(): EmailRouteSheetsToPeakStartedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_STARTED,
  };
}

export interface EmailRouteSheetsToPeakCompletedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_COMPLETED;
}

export interface EmailRouteSheetsToPeakDoneDoneAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_DONE_DONE;
}
export function emailRouteSheetsToPeakCompleted(): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_COMPLETED }); // Show the check mark
    await timeout(1500);
    dispatch({ type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_DONE_DONE }); // Back to normal text
  };
}

export interface EmailRouteSheetsToPeakFailedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_FAILED;
}
export function emailRouteSheetsToPeakFailed(): EmailRouteSheetsToPeakFailedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_EMAIL_ROUTE_SHEETS_TO_PEAK_FAILED,
  };
}

export const generateStoreDeliverySpreadsheetMutation = gql`
  mutation generateStoreDeliverySpreadsheet($reportInput: GenerateStoreDeliverySpreadsheetInput) {
    response: GenerateStoreDeliverySpreadsheet(reportInput: $reportInput) {
      id
    }
  }
`;

const editRoutePlanActionsMutation = (dispatch: Dispatch<any>, routePlanId: RoutePlanId, actionType: RoutePlanActionTypes) => {
  const mutation = gql`
  mutation EditRoutePlanAction($input: EditRoutePlanActionsInput!) {
    routePlanResult: EditRoutePlanActions(input: $input) {
      routePlan {
        id
        customerOrdersUpdatedSinceRoutePacketPrinting
        actions {
          createRoutePackets {
            ...actionFieldsFragment
          }
          downloadDittyCards {
            ...actionFieldsFragment
          }
          emailMerchandiserAsn {
            ...actionFieldsFragment
          }
          createCustomerAsn {
            ...actionFieldsFragment
          }
          downloadStoreDeliverySpreadsheet {
            ...actionFieldsFragment
          }
          downloadStoreAsn {
            ...actionFieldsFragment
          }
          sendLoadsToLoadmaster {
            ...actionFieldsFragment
          }
          emailRouteSheetsToPeak {
            ...actionFieldsFragment
          }
          createPalletReport {
            ...actionFieldsFragment
          }
        }
      }
    }
  }
  fragment actionFieldsFragment on RoutePlanAction {
    completed
    completedAt
    user {
      id
      firstName
      lastName
    }
  }
  `;

  return msyncClientMutation<EditRoutePlanResponse>({
    mutation,
    dispatch,
    variables: {
      input: {
        routePlanId,
        actionType,
      },
    },
  });
};

const markCustomerOrdersAsShippedMutation = (dispatch: Dispatch<any>, args: MarkCustomerOrdersAsShippedInput) => {
  const mutation = gql`
  mutation MarkCustomerOrderAsShipped($input: MarkCustomerOrdersAsShippedInput!) {
    markCustomerOrdersAsShipped: MarkCustomerOrdersAsShipped(input: $input)
  }`;

  return msyncClientMutation<MarkCustomerOrdersAsShippedResponse>({
    mutation,
    dispatch,
    variables: {
      input: {
        routePlanId: args.routePlanId,
        routingLoadIds: args.routingLoadIds,
      },
    },
  });
};

export interface LoadConfigurationChangedAction extends Action {
  type: ActionTypeKeys.LOAD_CONFIGURATION_CHANGED;
  payload: {
    loadConfiguration: LoadConfiguration,
  };
}

export const loadConfigurationChanged = (loadConfiguration: LoadConfiguration): LoadConfigurationChangedAction => {
  return {
    type: ActionTypeKeys.LOAD_CONFIGURATION_CHANGED,
    payload: {
      loadConfiguration,
    },
  };
};

export interface SelectedRoutingLoadIdsChangedAction extends Action {
  type: ActionTypeKeys.SELECTED_ROUTING_LOAD_IDS_CHANGED;
  payload: {
    routingLoadIds: RoutingLoadId[];
  };
}
function selectedRoutingLoadIdsChanged(routingLoadIds: RoutingLoadId[]): SelectedRoutingLoadIdsChangedAction {
  return {
    type: ActionTypeKeys.SELECTED_ROUTING_LOAD_IDS_CHANGED,
    payload: {
      routingLoadIds,
    },
  };
}

function setCreateRoutePacketModalVisibility(visible: boolean): CreateRoutePacketsModalVisibilityAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_CREATE_ROUTE_PACKETS_MODAL_VISIBILITY,
    payload: { visible },
  };
}

export function createRoutePacketMenuItemClicked(routingLoadIds: RoutingLoadId[]): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch(selectedRoutingLoadIdsChanged(routingLoadIds));
    dispatch(setCreateRoutePacketModalVisibility(true));
  };
}

export interface DownloadDittyCardMenuItemClicked extends Action {
  type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MENU_ITEM_CLICKED;
  payload: {
    routingLoadIds: RoutingLoadId[];
  };
}

export function downloadDittyCardMenuItemClicked(routingLoadIds: RoutingLoadId[]): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionTypeKeys.DOWNLOAD_DITTY_CARD_MENU_ITEM_CLICKED,
      payload: {
        routingLoadIds,
      },
    });
    dispatch({
      type: ActionTypeKeys.ROUTE_PLAN_SET_DOWNLOAD_DITTY_CARD_MODAL_VISIBILITY,
      payload: { visible: true },
    });
  };
}

export function loadsListTableUnmounted(dispatch: Dispatch<any>): void {
  return resetTable(dispatch, TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME);
}

export const merchandisersCancelButtonClickedAction = (): EmailMerchandisersModalVisibilityAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_EMAIL_MERCHANDISERS_MODAL_VISIBILITY,
    payload: {
      visible: false,
    },
  };
};

const merchandisersSendButtonMutation = (dispatch: Dispatch<any>, args: EmailMerchandiserSpecificASNsInput) => {
  const mutation = gql`
  mutation EmailMerchandiserSpecificASNs($routePlanId: Int!, $merchandiserIds: [Int!]!) {
    emailMerchandiserSpecificASNs: EmailMerchandiserSpecificASNs(routePlanId: $routePlanId, merchandiserIds: $merchandiserIds)
  }`;

  return msyncClientMutation<EmailMerchandiserSpecificASNsResponse, EmailMerchandiserSpecificASNsInput>({
    mutation,
    dispatch,
    variables: {
      routePlanId: args.routePlanId,
      merchandiserIds: args.merchandiserIds,
    },
  });
};

export const merchandisersSendButtonClickedAction = (routePlanId: number): Thunker => {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const state = getState();
    const selectedMerchandiserIds = state.routePlanPrinting.merchandisersWithStores
      .filter(mws => mws.checked)
      .map(mws => (mws.merchandiserId));
    try {
      await merchandisersSendButtonMutation(dispatch, { routePlanId, merchandiserIds: selectedMerchandiserIds });
    } catch (exception) {
      if (exception.isExpectedError) {
        dispatch(ErrorActions.expectedErrorReceived({ message: exception.message, debugInfo: exception.debugInfo }));
      } else {
        dispatch(ErrorActions.unexpectedErrorReceived({ message: exception.message, stack: exception.stack }));
      }
    } finally {
      await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.EmailMerchandiserAsn);
      dispatch(merchandisersCancelButtonClickedAction());
    }
  };
};

export interface DownloadStoreAdvanceShipNoticesStartedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_STARTED;
}

export interface DownloadStoreAdvanceShipNoticesButtonClickedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_BUTTON_CLICKED;
}

export const downloadStoreSpecificAdvanceShipNoticesPDFButton = (routePlanId: number): DownloadStoreAdvanceShipNoticesButtonClickedAction => {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_BUTTON_CLICKED,
  };
};

export const downloadStoreSpecificAdvanceShipNoticesPDFButtonClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    const getStoresWithMerchandisersResponse = await msyncClientQuery<{ getStoresWithMerchandisers: StoresWithMerchandisersResponse }, GetStoresWithMerchandisersInput>({
      variables: { routePlanId },
      dispatch,
      query: GetStoresWithMerchandisersQuery,
    });

    const rawItems = getStoresWithMerchandisersResponse.data.getStoresWithMerchandisers.storesWithMerchandisers;
    const byStore = groupBy(rawItems, s => s.storeId as shame as string);
    const withMerchies = _.mapValues(byStore, v => ({ ...v[0], checked: false, merchandisers: v.map(m => ({id: m.merchandiserId, name: m.merchandiserName})) }));
    const items = sortNaturally(_.values(withMerchies), [{sortField: 'storeDisplay'}]);
    dispatch(setStoresWithMerchandisers(items));
    dispatch(downloadStoreSpecificAdvanceShipNoticesPDFButton(routePlanId));
  };
};

export interface DownloadStoreAdvanceShipNoticesCancelButtonClickedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_CANCEL_BUTTON_CLICKED;
}

export const downloadStoreSpecificAdvanceShipNoticesPDFCancelButtonClicked = (): DownloadStoreAdvanceShipNoticesCancelButtonClickedAction => {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_CANCEL_BUTTON_CLICKED,
  };
};

export interface DownloadStoreSpecificAdvanceShipNoticesPDFCompletedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_COMPLETED;
}

export const downloadStoreSpecificAdvanceShipNoticesPDFCompleted = (): DownloadStoreSpecificAdvanceShipNoticesPDFCompletedAction => {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_COMPLETED,
  };
};

export interface DownloadStoreAdvanceShipNoticesStartedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_STARTED;
}

export interface DownloadStoreAdvanceShipNoticesFailedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_FAILED;
}

export interface CreateCustomerAdvanceShipNoticesStartedAction extends Action {
  type: ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_STARTED;
}

export interface CreateCustomerAdvanceShipNoticesCompleteAction extends Action {
  type: ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_COMPLETE;
}

export function createCustomerAdvanceShipNoticesStarted(): CreateCustomerAdvanceShipNoticesStartedAction {
  return {
    type: ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_STARTED,
  };
}

export function createCustomerAdvanceShipNoticesComplete(): CreateCustomerAdvanceShipNoticesCompleteAction {
  return {
    type: ActionTypeKeys.CREATE_CUSTOMER_ADVANCE_SHIP_NOTICES_COMPLETE,
  };
}

export interface DownloadStoreDeliverySpreadsheetStartedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_STARTED;
}

export interface DownloadStoreDeliverySpreadsheetCompleteAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_COMPLETE;
}

export function downloadStoreDeliverySpreadsheetStarted(): DownloadStoreDeliverySpreadsheetStartedAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_STARTED,
  };
}

export function downloadStoreDeliverySpreadsheetComplete(): DownloadStoreDeliverySpreadsheetCompleteAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_DELIVERY_SPREADSHEET_COMPLETE,
  };
}

export const downloadStoreDeliverySpreadsheetButtonClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(downloadStoreDeliverySpreadsheetStarted());
    try {

      const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse>({
        mutation: generateStoreDeliverySpreadsheetMutation,
        dispatch,
        variables: {
          reportInput: {
            routePlanId,
          },
        },
      });

      const reportId = response.data.response.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
      await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.DownloadStoreDeliverySpreadsheet);
    } finally {
      dispatch(downloadStoreDeliverySpreadsheetComplete());
    }
  };
};

export const setStoresWithMerchandisers = (storesWithMerchandisers: Array<StoresWithMerchandisers & {checked: boolean, merchandisers: Array<{id: number, name: string}>}>) => ({
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_SET_STORES_WITH_MERCHANDISERS,
  payload: { storesWithMerchandisers },
}as const);

export type SetStoresWithMerchandisersAction = Return<typeof setStoresWithMerchandisers>;

const GetStoresWithMerchandisersQuery = gql`
query GetStoresWithMerchandisersQuery($routePlanId: Int!) {
  getStoresWithMerchandisers: GetStoresWithMerchandisers(routePlanId: $routePlanId) {
      storesWithMerchandisers {
        storeId
        storeIdentifier
        storeDisplay
        merchandiserName
      }
    }
  }
`;

export interface StoresSelectAllClickedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_SELECT_ALL_CLICKED;
  payload: {};
}

export function storesSelectAllClicked(): StoresSelectAllClickedAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_SELECT_ALL_CLICKED,
    payload: {},
  };
}

export interface StoresSearchedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_SEARCHED;
  payload: {
    searchText: string;
  };
}

export function storesSearched(searchText: string): StoresSearchedAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_SEARCHED,
    payload: {
      searchText,
    },
  };
}

export interface SelectedStoresChangedAction extends Action {
  type: ActionTypeKeys.DOWNLOAD_STORE_ASN_STORE_CHANGED;
  payload: {
    storeId: number;
  };
}

export function selectedStoresChanged(storeId: number): SelectedStoresChangedAction {
  return {
    type: ActionTypeKeys.DOWNLOAD_STORE_ASN_STORE_CHANGED,
    payload: {
      storeId,
    },
  };
}

export const storesDownloadPDFButtonClickedAction = (routePlanId: number, routePlanIdentifier: string): Thunker => {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const state = getState();
    const selectedStores = RoutePlanPrintingSelectors.storesWithMerchandisers(state)
      .filter(swm => swm.checked);

    const selectedStoreIds = selectedStores
      .map(swm => (swm.storeId));

    dispatch(mutationSent(false));

    try {
      await downloadStoreAsns({
        routePlanId,
        storeIds: selectedStoreIds,
        routePlanIdentifier,
        storeIdentifiers: selectedStores.map(swm => swm.storeIdentifier) ,
      });

      await editRoutePlanActionsMutation(dispatch, routePlanId, RoutePlanActionTypes.DownloadStoreAsn);

      dispatch(mutationResponseReceived());

      dispatch(downloadStoreSpecificAdvanceShipNoticesPDFCompleted());
    } catch (exception) {
      dispatch(ErrorActions.unexpectedErrorReceived({ message: exception.message, stack: exception.stack }));
    } finally {
      dispatch(removeRecentlyReceivedMutationResponse());
    }
  };
};

export const downloadStoreAsns = async (args: {
  routePlanId?: RoutePlanId,
  storeIds: number[];
  routePlanIdentifier: string;
  storeIdentifiers: string[];
}) => {

  const options = [
    `storeIds=${args.storeIds.join()}`,
  ].join('&');

  const pdfBlob = await send({
    fetch,
    url: `/report/storeASN/${args.routePlanId}?${options}`,
    method: 'GET',
    asBlob: true,
  });

  const filename = `${args.routePlanIdentifier} - Store Specific ASN.pdf`;

  FileSaver.saveAs(pdfBlob, filename);
};
