import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as _ from 'lodash';
import * as Actions from '../actions';
import * as Mutation from './mutations';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

export function downloadButtonClicked(args: { productId: number, salesPlanId: number | undefined }): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>) => {
    if (_.isNil(args.salesPlanId)) {
      throw new Error('Sales plan not selected.');
    }
    dispatch(Actions.pdfDownloadStarted());
    try {
      const result = await msyncClientMutation<Mutation.DownloadProductSpecSheetPdfMutationResponse, Mutation.DownloadProductSpecSheetPdfMutationInput>({
        mutation: Mutation.DownloadProductSpecSheetPdf,
        variables: {
          salesPlanId: args.salesPlanId,
          productId: args.productId,
        },
        dispatch,
      });

      const reportId = result.data.response.report.id;
      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(Actions.pdfDownloadFinished());
      dispatch(Actions.HideDownloadSpecSheetModal());
    }
  };
}
