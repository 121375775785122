import * as React from 'react';
import { Col, Form, FormGroup, Row } from 'client/components/third-party';
import { FieldWrapper } from '../../form';

/* eslint-disable react/no-multi-comp */
const Field = (p: any) => <FieldWrapper table="stores" alwaysShowErrors={true} hideOptionalLabel={true} { ...p } />;

interface Props {
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  formName: string;
  handleSubmit: (value: any) => any;
}

class RecordForm extends React.Component<Props, undefined> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {
      handleSubmit,
    } = this.props;

    return (
      <Col sm={12} data-testid="transportation">
        <Form horizontal onSubmit={handleSubmit} data-testid="details">
          <div data-testid="store">
            <Row>
              <div className="mfc-form-heading">
                Delivery Information
              </div>
              <FormGroup data-testid="transportation-row-1">
                <Field name="indoorDeliveryHours" labelColSize={2} inputColSize={6} />
              </FormGroup>
              <FormGroup data-testid="transportation-row-2">
                <Field name="indoorDeliveryLocation" labelColSize={2} inputColSize={3} />
              </FormGroup>
              <FormGroup data-testid="transportation-row-3">
                <Field name="outdoorDeliveryHours" labelColSize={2} inputColSize={3} />
              </FormGroup>
              <FormGroup data-testid="transportation-row-4">
                <Field name="outdoorDeliveryLocation" labelColSize={2} inputColSize={8} />
              </FormGroup>
              <FormGroup data-testid="transportation-row-5">
                <Field name="villageRestrictions" labelColSize={2} inputColSize={8} />
              </FormGroup>
            </Row>
          </div>
        </Form>
      </Col>
    );
  }
}

export default RecordForm;
