import * as React from 'react';
import { DISPLAY_TYPES } from '../../../../shared/types';
import Header from './header';
import * as Menu from './menu';
import { IColumn } from '../column';

export default (rowMenuItems: Menu.RowMenuItem[]): IColumn => ({
  columnWidthFixed: 33,
  cellType: DISPLAY_TYPES.MENU,
  header: () => <Header menuItems={rowMenuItems} />,
  id: 'row-menu',
  accessor: 'row-menu',
  tableEditable: false,
  sortable: false,
  type: 'TBD',
});
