import * as R from 'ramda';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES, ShippingUnitType, OrderMethod, RackShippingUnit, ShippingUnitOrderMethod, PackOrderMethod } from 'shared/types';
import { ReceivingWorksheetTableRow, OnSaveQuantityReceived } from 'client/app/transportation/receiving/details/worksheet/worksheet-table/worksheet-table';
import { determineReceivedStatusFormatting } from 'shared/helpers/receiving-status';
import { BolId } from 'shared/schemas/bol';
import { propToComponent } from 'client/hoc/hoc';
import { createSelector } from 'reselect';

export const getBaseColumns = (): IColumn[] => {
  return [
    {
      id: 'productIdentifier', // excel
      accessor: 'productIdentifier', // web
      header: 'Product ID',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
    {
      id: 'productDescription', // excel
      accessor: 'productDescription', // web
      header: 'Description',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
      overflowWidth: true,
    },
    {
      id: 'upc', // excel
      accessor: 'upc', // web
      header: 'UPC',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
    {
      id: 'rackType', // excel
      accessor: 'rackType', // web
      header: 'Rk Type',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
    },
    {
      id: 'retailPrice', // excel
      accessor: 'retailPrice', // web
      header: 'Retail',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.MONEY,
      type: TYPES.FLOAT,
    },
    {
      id: 'packSize',
      accessor: 'packSize',
      header: 'Supp Pk',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
    },
  ];
};

export const getRackShippingUnitColumns = (shippingUnitType: ShippingUnitType) => (columns: IColumn[]) => {
  if (shippingUnitType === RackShippingUnit) {
    return columns.concat([
      {
        id: 'packsPerShelf',
        accessor: 'packsPerShelf',
        header: 'PPS',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
      {
        id: 'shelvesPerRack',
        accessor: 'shelvesPerRack',
        header: 'SPR',
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ]);
  }
  return columns;
};

export const getQuantityColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod) => (columns: IColumn[]) => {
  return columns.concat([
    {
      id: 'packsPerShippingUnit',
      accessor: 'packsPerShippingUnit',
      header: getPacksPerShippingUnitHeader(shippingUnitType, orderMethod),
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
    },
    {
      id: 'shippingUnitQuantity',
      accessor: 'shippingUnitQuantity',
      header: shippingUnitType === RackShippingUnit ? 'Rks Ordered' : 'Plts Ordered',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
    },
  ]);
};

export const getPackOrderMethodColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod) => (columns: IColumn[]) => {
  if (orderMethod === PackOrderMethod) {
    return columns.concat([
      {
        id: 'packQuantity',
        accessor: 'packQuantity',
        header: getPackQuantityHeader(shippingUnitType),
        tableEditable: false,
        sortable: true,
        cellType: CELL_TYPES.NUMBER,
        type: TYPES.NUMBER,
      },
    ]);
  }
  return columns;
};

export const getReceivingColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod, requestedBolId: BolId | undefined, onSaveQuantityReceived) => (columns: IColumn[]) => {
  return columns.concat([
    {
      id: getReceivedTotalAccessor(orderMethod),
      accessor: getReceivedTotalAccessor(orderMethod),
      header: getReceivedTotalHeader(shippingUnitType, orderMethod),
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.DASH_ZERO_NUMBER,
      type: TYPES.NUMBER,
      getClassNames: (val: string, row: ReceivingWorksheetTableRow) => {
        const ordered = row[getOrderedAccessor(orderMethod)] || 0;
        const received = row[getReceivedTotalAccessor(orderMethod)] || 0;

        return determineReceivedStatusFormatting(received, ordered);
      },
    },
    {
      id: getReceivedAccessor(orderMethod),
      accessor: getReceivedAccessor(orderMethod),
      header: getReceivedHeader(shippingUnitType, orderMethod),
      tableEditable: true,
      sortable: true,
      cellType: CELL_TYPES.SPINNER_ALLOW_NEGATIVES,
      type: TYPES.NUMBER,
      onSave: (receivableOrderProductId: number, quantity: string) => {
        const parsedValue = parseInt(quantity);
        const newQuantity = isNaN(parsedValue) ? 0 : parsedValue;

        if (requestedBolId) {
          return onSaveQuantityReceived(receivableOrderProductId, requestedBolId, newQuantity);
        } else {
          throw new Error('BUG: Requested BOL Id not available!');
        }
      },
    },
  ]);
};

export const determineColumnWidth = (numberOfColumns: number, column: IColumn): number => {
  switch (column.id) {
    case 'description':
    case 'upc':
      return (200 / (numberOfColumns + 2)); // 2 columns for description
    default:
      return (100 / (numberOfColumns + 2));
  }
};

const applyColumnWidth = (numberOfColumns: number) => (column: IColumn): IColumn => {
  return {
    ...column,
    columnWidth: determineColumnWidth(numberOfColumns, column),
  };
};

export const applyColumnWidths = columns => R.map(applyColumnWidth(columns.length), columns);

type PacksPerShippingUnitHeader = 'PPR' | 'PPP' | 'CPP';
const getPacksPerShippingUnitHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): PacksPerShippingUnitHeader => {
  if (shippingUnitType === RackShippingUnit) {
    return 'PPR';
  }
  if (orderMethod === ShippingUnitOrderMethod) {
    return 'PPP';
  } else {
    return 'CPP';
  }
};

type PackQuantityHeader = 'Cases Ordered' | 'Pks Ordered';
const getPackQuantityHeader = (shippingUnitType: ShippingUnitType): PackQuantityHeader => {
  if (shippingUnitType === RackShippingUnit) {
    return 'Pks Ordered';
  }

  return 'Cases Ordered';
};

type RacksReceivedHeader = 'Rks Received' | 'Plts Received' | 'Pks Received' | 'Cases Received';
const getReceivedHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): RacksReceivedHeader => {
  if (orderMethod === OrderMethod.ShippingUnit) {
    if (shippingUnitType === ShippingUnitType.Rack) {
      return 'Rks Received';
    }

    return 'Plts Received';
  }
  if (shippingUnitType === ShippingUnitType.Rack) {
    return 'Pks Received';
  }

  return 'Cases Received';
};

type RacksReceivedTotalHeader = "Rks Rec'd TD" | "Plts Rec'd TD" | "Pks Rec'd TD" | "Cases Rec'd TD";
const getReceivedTotalHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): RacksReceivedTotalHeader => {
  if (orderMethod === OrderMethod.ShippingUnit) {
    if (shippingUnitType === ShippingUnitType.Rack) {
      return "Rks Rec'd TD";
    }
    return "Plts Rec'd TD";
  }
  if (shippingUnitType === ShippingUnitType.Rack) {
    return "Pks Rec'd TD";
  }
  return "Cases Rec'd TD";
};

type ReceivedAccessor = 'shippingUnitQuantityReceivedInBol' | 'packQuantityReceivedInBol';
const getReceivedAccessor = (orderMethod: OrderMethod): ReceivedAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantityReceivedInBol';
  }

  return 'shippingUnitQuantityReceivedInBol';
};

type OrderedAccessor = 'packQuantity' | 'shippingUnitQuantity';
const getOrderedAccessor = (orderMethod: OrderMethod): OrderedAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantity';
  }

  return 'shippingUnitQuantity';
};

type ReceivedTotalAccessor = 'shippingUnitQuantityReceivedTotal' | 'packQuantityReceivedTotal';
const getReceivedTotalAccessor = (orderMethod: OrderMethod): ReceivedTotalAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantityReceivedTotal';
  }

  return 'shippingUnitQuantityReceivedTotal';
};

const getColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod, onSaveQuantityReceived: OnSaveQuantityReceived, requestedBolId?: BolId): IColumn[] =>
  R.pipe(
    getBaseColumns,
    getRackShippingUnitColumns(shippingUnitType),
    getQuantityColumns(shippingUnitType, orderMethod),
    getPackOrderMethodColumns(shippingUnitType, orderMethod),
    getReceivingColumns(shippingUnitType, orderMethod, requestedBolId, onSaveQuantityReceived),
    applyColumnWidths,
  )();

interface ReceivingWorksheetColumnProps {
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  requestedBolId?: BolId;
  onSaveQuantityReceived: OnSaveQuantityReceived;
}

const getShippingUnitType = (props: ReceivingWorksheetColumnProps): ShippingUnitType => props.shippingUnitType;
const getOrderMethod = (props: ReceivingWorksheetColumnProps): OrderMethod => props.orderMethod;
const getBolId = (props: ReceivingWorksheetColumnProps): BolId | undefined => props.requestedBolId;
const getOnSaveQuantityReceived = (props: ReceivingWorksheetColumnProps): OnSaveQuantityReceived => props.onSaveQuantityReceived;
const columnsSelector = createSelector([getShippingUnitType, getOrderMethod, getOnSaveQuantityReceived, getBolId], getColumns);

export const WithReceivingWorksheetColumns = WrappedComponent => (props: ReceivingWorksheetColumnProps) => {
  const updatedProps = {
    ...props,
    columns: columnsSelector(props),
  };
  return propToComponent(WrappedComponent, updatedProps);
};
