import gqlTag from 'graphql-tag';
import { GetRoutingLoadsInput, RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';

export interface NotifyPeakModalRowsResponse {
  getRoutingLoads?: {
    routingLoads: Array<{
      id: RoutingLoadId;
      identifier: string;
      sentToPeak: boolean;
    }>;
  };
  loading: boolean;
}

export const NotifyPeakFragment = gqlTag`
  fragment NotifyPeakFragment on RoutingLoad {
    id
    identifier
    sentToPeak
  }
`;

export type NotifyPeakInput = GetRoutingLoadsInput;
export const NotifyPeakQuery = gqlTag`
  query NotifyPeakModalRowQuery($sort: [SortInput!], $filters: RoutingLoadFilterInput, $search: RoutingLoadSearchInput, $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput) {
    getRoutingLoads: GetRoutingLoads(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routingLoads {
        ...NotifyPeakFragment
      }
    }
  }
  ${NotifyPeakFragment}
`;

export interface WithNotifyPeakModalRowsQueryProps {
  routingLoads: Array<{
    id: RoutingLoadId;
    identifier: string;
    sentToPeak: boolean;
  }>;
  loading: boolean;
  sendButtonDisabled: boolean;
  notifyPeakInfoMessageEnabled: boolean;
}
