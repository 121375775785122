import * as State from 'client/containers/product/state';
import { ActionTypes, ActionTypeKeys } from './actions';

export default function reducer(state = State.InitialState, action: ActionTypes): State.Type {
  switch (action.type) {
    case ActionTypeKeys.ShowDownloadSpecSheetModal: {
      return State.downloadSpecSheetModalShown.set(true)(state);
    }
    case ActionTypeKeys.HideDownloadSpecSheetModal: {
      return State.downloadSpecSheetModalShown.set(false)(state);
    }
    default:
      return state;
  }
}
