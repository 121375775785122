import * as Constants from '../constants';
import * as React from 'react';
import { Checkbox } from 'client/components/form/checkbox';

interface SelectAllCheckboxProps {
  selectAllClicked(): void;
  checked: boolean;
}

export class SelectAllCheckbox extends React.Component<SelectAllCheckboxProps, {}> {
  onChangeHandler = () => {
    this.props.selectAllClicked();
  }

  onLabelClick = () => this.onChangeHandler();
  render() {
    const checkboxInputChangeHandler = {
      onChange: this.onChangeHandler,
      value: this.props.checked,
    };
    return (
      <div
        className={Constants.ModalFormatting.SelectAll}
        key={'select-all'}
        data-testid={'recipient-select-all'}
      >
        <Checkbox
          className={Constants.ModalFormatting.Checkbox}
          input={checkboxInputChangeHandler}
        />
        <div
          className={Constants.ModalFormatting.RecipientLabel}
          onClick={this.onLabelClick}
        >
          Select All
        </div>
      </div>
    );
  }
}
