import * as React from 'react';
import { ControlLabel } from 'client/components/third-party';
import * as classNames from 'classnames';
import { HorizontalLayout } from './layouts';
import { formatNumber, Numeric } from 'shared/types';

export const LabeledDisplayPercentage = (p: {
    labelColSize: number,
    offset?: number,
    inputColSize: number,
    label: string,
    value: string | number,
    testid: string,
    input?: boolean,
})  => (
  <div data-testid={p.testid}>
    <HorizontalLayout labelComponent={ControlLabel} labelColSize={p.labelColSize} offset={p.offset} label={p.label} inputColSize={p.inputColSize}>
      <div className={classNames({ 'labeled-display': !p.input, 'labeled-input': p.input })}>
        {formatNumber(Numeric.from(p.value).mul(100), 2, undefined, undefined, '%')}
      </div>
    </HorizontalLayout>
  </div>
);
