import * as React from 'react';
import BolForm from 'client/app/transportation/receiving/details/worksheet/bol-form/bol-form-container';
import { MutationStatus } from 'client/actions/mutations';
import { FormModal } from 'client/components/form-modal';
import { BolAutoCartTrackingEnabled } from 'shared/schemas/bol';

interface OwnProps {
  isShown: boolean;
  onCancelButtonClicked(): void;
  onModalEnter(): void;
  handleFormSubmit: shame;

  invalid: boolean;
  actionStatus: MutationStatus;
  receivableOrderId: number;
  autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
}

export const EditBolModal: React.SFC<OwnProps> = props => {
  return (
    <FormModal
      isShown={props.isShown}
      cancelDisabled={props.actionStatus === MutationStatus.InProgress}
      onCancelButtonClicked={props.onCancelButtonClicked}
      handleFormSubmit={props.handleFormSubmit}
      invalid={props.invalid}
      submitButtonLabel={'Update'}
      submitButtonTestId={'edit-button'}
      modalTitle={'Edit BOL'}
      modalId={'edit-bol-modal'}
      actionStatus={props.actionStatus}
    >
      <BolForm receivableOrderId={props.receivableOrderId} autoCartTrackingEnabled={props.autoCartTrackingEnabled} />
    </FormModal>
  );
};
