import * as _ from 'lodash';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as Constants from './constants';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as SchemaTypes from 'schema/planning-report/types';
import { notNilOrEmptyString } from 'shared/helpers/andys-little-helpers';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
    const presetId = formValues.id;

    const customerId = formValues[Constants.FormFields.customerId];
    const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];

    const yearInput = formValues[Constants.FormFields.year];
    const beginWeekInput = formValues[Constants.FormFields.beginWeek];
    const endWeekInput = formValues[Constants.FormFields.endWeek];

    const season = formValues[Constants.FormFields.season];

    if (_.isNil(customerId) ||
      _.isNil(sellDepartmentId) ||
      !notNilOrEmptyString(yearInput) ||
      !notNilOrEmptyString(beginWeekInput) ||
      !notNilOrEmptyString(endWeekInput) ||
      !notNilOrEmptyString(season)) {
      throw new Error('Missing some required fields');
    }

    const year = Number.parseInt(yearInput);
    const beginWeek = Number.parseInt(beginWeekInput);
    const endWeek = Number.parseInt(endWeekInput);

    const variables: SchemaTypes.GenerateSupplierFutureCommitmentReportInput = {
      generateSupplierFutureCommitmentReportInput: {
        includePoBased: !!formValues[Constants.FormFields.poBased],
        includeScanBased: !!formValues[Constants.FormFields.scanBased],
        productIds: extractSelectedValues(formValues[Constants.FormFields.productIds]),

        customerId,
        productClassIds: extractSelectedValues(formValues[Constants.FormFields.productClassIds]),
        productSubClassIds: extractSelectedValues(formValues[Constants.FormFields.productSubClassIds]),
        sellDepartmentId,

        season,

        year,
        beginWeek,
        endWeek,

        presetId,
      },
    };

    try {
      dispatch(ReportActions.reportDownloadStarted());
      const response = await GraphQLMutation.msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateSupplierFutureCommitmentReportInput>({
        mutation: Mutations.generatePerformanceReport,
        variables,
        dispatch,
      });

      if (!response) {
        dispatch(ReportActions.reportDownloadFinished());
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;
      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
