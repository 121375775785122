import * as ImportModal from 'client/components/import-modal';
import * as React from 'react';
import * as ImportModalTypes from 'client/components/import-modal/types';
import { SimpleSelect } from 'client/components/simple-components/simple-select';
import { Col, FormGroup, Form, Row } from 'client/components/third-party';
import { ProductPriceChangeReasons, INPUT_TYPES, DateStr } from 'shared/types';
import { makeDropdownOptions } from 'shared/helpers/filter-options';
import { SimpleInput } from 'client/components/simple-components/simple-input';
import { SimpleDatePicker } from 'client/components/simple-components/simple-datepicker';
import { SimpleCheckbox } from 'client/components/simple-components/simple-checkbox';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import * as classnames from 'classnames';

export interface ComponentProps {
  customerId: number | undefined;
  regionIds: number[] | undefined;
  marketIds: number[] | undefined;

  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;
  handleModalOpened: () => void;

  handleImportStarted: ImportModalTypes.ImportFn;
  handleParsingStarted: ImportModalTypes.ParseFn;
  handleValidationStarted: ImportModalTypes.ValidateFn;

  handleCustomerChanged: (customerID: number) => void;
  handleRegionChanged: (regionIds: number[]) => void;
  handleMarketChanged: (marketIds: number[]) => void;

  customers: Array<{ id: number; identifier: string; name: string }>;
  regions: Array<{ id: number; identifier: string; description: string; }> | undefined;
  markets: Array<{ id: number; identifier: string; description: string; regionIdentifier: string; }> | undefined;
}

const productPriceChangeDropdownItems = makeDropdownOptions(Object.values(ProductPriceChangeReasons));

export function ImportProductPriceModalUI(props: ComponentProps) {
  const [beginDate, setBeginDate] = React.useState<DateStr | null>(null);
  const [primary, setPrimary] = React.useState<boolean>(true);
  const [changeReason, setChangeReason] = React.useState<string>('');
  const [notes, setNotes] = React.useState<string>('');
  const [isFormShown, setIsFormShown] = React.useState<boolean>(true);
  const [allRegions, setAllRegions] = React.useState<boolean>(false);
  const [allMarkets, setAllMarkets] = React.useState<boolean>(false);

  // Only fired when component is mounted
  React.useEffect(() => {
    props.handleModalOpened();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const customers = React.useMemo(() => (props.customers || []).map(d => ({
      id: d.id,
      value: `${d.identifier} - ${d.name}`,
      displayValue: `${d.identifier} - ${d.name}`,
    })), [props.customers]);

  const regions = React.useMemo(() => (props.regions || []).map(d => ({
      id: d.id,
      value: `${d.identifier} - ${d.description}`,
      displayValue: `${d.identifier} - ${d.description}`,
    })), [props.regions]);

  const markets = React.useMemo(() => (props.markets || []).map(d => ({
      id: d.id,
      value: `${d.identifier} - ${d.description} - ${d.regionIdentifier}`,
      displayValue: `${d.identifier} - ${d.description} - ${d.regionIdentifier}`,
    })), [props.markets]);

  const onAllRegionsChange = value => {
    props.handleRegionChanged(value ? idsFor(props.regions || []) : []);
    setAllRegions(value);
  };

  const onAllMarketsChange = value => {
    props.handleMarketChanged(value ? idsFor(markets) : []);
    setAllMarkets(value);
  };

  const contentClassName = classnames('import-product-price-retail-modal-content-area', {
    'import-product-price-retail-modal-content-area-not-primary': !primary,
  });

  const productPriceImportFormGroup = isFormShown ? (
    <Col sm={12} className={contentClassName}>
      <Form horizontal>
        <Row>
          <FormGroup>
            <SimpleDatePicker
              horizontalLabel
              labelColSize={3}
              inputColSize={9}
              onChange={setBeginDate}
              testid="bulk-import-price-begin-date"
              value={beginDate}
              label="Begin Date"
            />
          </FormGroup>
          <FormGroup>
            <SimpleSelect
              horizontalLabel
              labelColSize={3}
              inputColSize={9}
              onChange={props.handleCustomerChanged}
              options={customers}
              testid="bulk-import-price-customer"
              value={props.customerId}
              label="Customer"
            />
          </FormGroup>
          <FormGroup>
            <SimpleCheckbox
              testid="primary-checkbox-test"
              value={primary}
              onChange={setPrimary}
              label="All Markets Containing a Primary Store"
              className="import-modal-checkbox"
            />
          </FormGroup>
          {!primary && <>
            <Row>
              <Col sm={6}>
                <span className="import-modal-selected-count">{(props.regionIds || []).length} of {(props.regions || []).length}</span>
              </Col>
              <Col sm={6}>
                <SimpleCheckbox
                  testid="select-all-regions-checkbox-test"
                  value={allRegions}
                  onChange={onAllRegionsChange}
                  label="Select All"
                  className="import-modal-checkbox"
                />
              </Col>
            </Row>
            <FormGroup>
              <SimpleSelect
                horizontalLabel
                labelColSize={3}
                inputColSize={9}
                options={regions}
                onChange={props.handleRegionChanged}
                testid="bulk-import-price-region"
                value={props.regionIds}
                label="Region"
                multi={true}
              />
            </FormGroup>
            <Row>
              <Col sm={6}>
                <span className="import-modal-selected-count">{(props.marketIds || []).length} of {markets.length}</span>
              </Col>
              <Col sm={6}>
                <SimpleCheckbox
                  testid="select-all-regions-checkbox-test"
                  value={allMarkets}
                  onChange={onAllMarketsChange}
                  label="Select All"
                  className="import-modal-checkbox"
                />
              </Col>
            </Row>
            <FormGroup>
              <SimpleSelect
                horizontalLabel
                labelColSize={3}
                inputColSize={9}
                options={markets}
                onChange={props.handleMarketChanged}
                testid="bulk-import-price-market"
                value={props.marketIds}
                label="Market"
                multi={true}
              />
            </FormGroup>
          </>}
          <FormGroup>
            <SimpleSelect
              horizontalLabel
              labelColSize={3}
              inputColSize={9}
              onChange={setChangeReason}
              options={productPriceChangeDropdownItems}
              testid="bulk-import-price-change-reason"
              value={changeReason}
              label="Change Reason"
            />
          </FormGroup>
          <FormGroup>
            <SimpleInput
              horizontalLabel
              labelColSize={3}
              inputColSize={9}
              onChange={setNotes}
              testid="bulk-import-price-notes"
              value={notes}
              label="Notes"
              type={INPUT_TYPES.TEXTAREA}
            />
          </FormGroup>
        </Row>
      </Form>
    </Col>
  ) : undefined;

  const handleParsingStarted = (args: { fileName: string, contents: string }, done: ImportModalTypes.DoneFn) => {
    setIsFormShown(false);
    props.handleParsingStarted(args, done);
  };

  const handleValidationStarted = (args: { existingRecordId: number | undefined, importableObject: ImportModalTypes.ImportableObject, additionalArguments?: any }, done: ImportModalTypes.DoneFn) => {
    const form = {
      changeReason,
      customerId: props.customerId,
      notes,
      beginDate,
      primary,
      marketIds: props.marketIds || [],
    };
    props.handleValidationStarted({ existingRecordId: args.existingRecordId, importableObject: args.importableObject, additionalArguments: form }, done);
  };

  const handleImportStarted = (args: { existingRecordId: number | undefined, importableObject: ImportModalTypes.ImportableObject, additionalArguments?: any }, done: ImportModalTypes.DoneFn) => {
    const form = {
      changeReason,
      customerId: props.customerId,
      notes,
      beginDate,
      primary,
      marketIds: props.marketIds || [],
    };
    props.handleImportStarted({ existingRecordId: args.existingRecordId, importableObject: args.importableObject, additionalArguments: form }, done);
  };

  const chooseFileButtonDisabled = primary && !(props.customerId && beginDate && changeReason) ||
    !primary && !(props.customerId && beginDate && changeReason && (props.marketIds || []).length > 0);

  const className = isFormShown
    ? 'import-product-price-retail-modal-with-form'
    : 'import-product-price-retail-modal-without-form';

  return <ImportModal.ImportUI
    id="import-product-price-retail-modal"
    existingRecordId={undefined}
    className={className}

    modalTitle="Import Product Price/Retail"

    customFormGroup={productPriceImportFormGroup}

    fileExtension=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    importButtonLabel="Import Product Price Records"
    closeButtonLabel="Close"
    cancelButtonLabel="Cancel"

    handleCloseButtonClicked={props.handleCloseButtonClicked}
    handleCancelButtonClicked={props.handleCancelButtonClicked}

    handleParsingStarted={handleParsingStarted}
    handleValidationStarted={handleValidationStarted}
    handleImportStarted={handleImportStarted}

    chooseFileButtonDisabled={chooseFileButtonDisabled}
  />;
}
