import { NO_OP, CUSTOMER_ORDER_OVERVIEW_TABLE_NAME, PRODUCT_WORKSHEET_TABLE_NAME, RELATED_SUPPLIER_ORDERS_TABLE_NAME, CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME } from 'client/constants';
import { Thunker } from 'client/types/redux-types';
import { uncheckAllRecords, uncheckMultipleRecords, resetTable } from 'client/actions/table';
import { Dispatch } from 'redux';
import { ApolloRefetch } from 'client/types';
import { clearRecordBarGoBackToLocation } from 'client/actions/navigation';

export function customerOrderSectionUnmounted(): Thunker {
  return (dispatch: Dispatch<any>) => {
    resetTable(dispatch, CUSTOMER_ORDER_OVERVIEW_TABLE_NAME);
    resetTable(dispatch, PRODUCT_WORKSHEET_TABLE_NAME);
    resetTable(dispatch, RELATED_SUPPLIER_ORDERS_TABLE_NAME);
    resetTable(dispatch, CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME);
    dispatch(clearRecordBarGoBackToLocation());
  };
}

export function addSupplierOrdersClicked(args: { mutationFunction: () => Promise<boolean>, addClickHandler: () => void, refetchData?: ApolloRefetch, tableName: string }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    if (await args.mutationFunction()) {
      args.addClickHandler();
      if (args.refetchData) {
        await args.refetchData();
      }
      dispatch(uncheckAllRecords(args.tableName));
    }

    // Ummm... Not sure why this is here. Anyone?
    dispatch({
      type: NO_OP,
    });
  };
}
export function supplierOrdersCloseDialogClicked(args: { closeClickHandler: () => void, tableName: string }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    args.closeClickHandler();

    dispatch(uncheckAllRecords(args.tableName));
  };
}

export function removeRowsSelected(args: { ids: number[], removeFunction?: (ids: number[]) => Promise<boolean>, refetchFunction?: ApolloRefetch, tableName: string }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    if (!args.removeFunction) {
      throw new Error('Trying to remove a row without a mutation');
    }

    if (!args.refetchFunction) {
      throw new Error('Trying to remove a row without a refetchFunction');
    }

    if (await args.removeFunction(args.ids)) {
      await args.refetchFunction();
      dispatch(uncheckMultipleRecords(args.tableName, args.ids));
    }
  };
}
