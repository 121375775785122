import { buildAdminDetailPage } from '../admin/admin-detail-page';
import {ProductInfoFormComponent as FormComponent} from '../../components/crud-forms/product/product-info-form-group';
import navBuilder from './nav-items';
import * as Actions from 'client/containers/product/actions';
import { ProductRecordBar } from './product-record-bar';
import { push } from 'connected-react-router';

export default buildAdminDetailPage({
  table: 'Product',
  formName: 'ProductInfoForm',
  FormComponent,
  navBuilder,
  descriptionResolver: product => `${product.identifier} - ${product.description}`,
  recordBarActionMenuItems: [
    {
      label: 'Download Spec Sheet',
      onClickAction: () => {
        return {
          type: Actions.ActionTypeKeys.ShowDownloadSpecSheetModal,
        };
      },
    },
    {
      label: 'Duplicate Record',
      onClickAction: productId => push(`/admin/products/create?id=${productId}`),
    },
  ],
  RecordBarDetailComponent: ProductRecordBar,
});
