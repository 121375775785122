import { propToComponent, wrapComponent } from 'client/hoc/hoc';
import { ReceivingDetails } from './receiving-details';
import { BolId } from 'shared/schemas/bol';
import { connect, ConnectedProps } from 'react-redux';
import * as Actions from './actions';
import { withRouter, RouteChildrenProps } from 'react-router';

interface OwnProps {
}

interface WithReceivableOrderIdProps {
  receivableOrderId: number;
  requestedBolId?: BolId;
}

const withReceivableOrderId = WrappedComponent => (props: OwnProps & RouteChildrenProps<{ id: string, bolId?: string }>) => {
  if (!props.match) {
    throw new Error('Missing path information for receiving details');
  }

  const params = props.match.params;

  const updatedProps: OwnProps & WithReceivableOrderIdProps = {
    ...props,
    receivableOrderId: Number.parseInt(params.id),
    requestedBolId: params.bolId ? Number.parseInt(params.bolId) : undefined,
  };

  return propToComponent(WrappedComponent, updatedProps);
};

export interface DispatchProps {
  handleReceivingDetailsUnmounted: () => void;
}

export const mapDispatchToProps: DispatchProps = {
  handleReceivingDetailsUnmounted: Actions.handleReceivingDetailsUnmounted,
};

const connector = connect(undefined, mapDispatchToProps);

export type CombinedProps =
  ConnectedProps<typeof connector> &
  WithReceivableOrderIdProps;

export default wrapComponent(ReceivingDetails)<OwnProps, CombinedProps>(
  withRouter,
  withReceivableOrderId,
  connector,
);
