export enum ColumnAlignment {
  Left,
  Center,
  Right,
}

export interface SelectableColumn {
  alignment: ColumnAlignment;
  width: number;
}

export type SelectableCell = string | number | (() => string | number);

export interface SelectableRow {
  id: number;
  cells: SelectableCell[];
}
