import { getFormValues } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as SchemaTypes from 'schema/transportation-report/types';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import { timeout } from 'shared/helpers/promises';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const values = getFormValues(Constants.formName)(state) as Constants.FormValues;
    const salesPlanIds = extractSelectedValues(values[Constants.FormFields.salesPlanIds]);
    if (salesPlanIds.length < 1) {
      throw new Error('No sales plans selected.');
    }

    dispatch(ReportActions.reportDownloadStarted());

    const response: { data: CommonSchemaTypes.GenerateReportMutationResponse } | undefined = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateCartEstimateReportInput>({
      mutation: Mutations.generateCartEstimateReport,
      variables: {
        reportInput: {
          salesPlanIds,
        },
      },
      dispatch,
    });

    if (!response) {
      dispatch(ReportActions.reportDownloadFinished());
      throw new Error('Unable to generate report');
    }

    const reportId = response.data.response.id;

    window.location.replace(`/report/fileDownload/${reportId}`);
    await timeout(2000);
    dispatch(ReportActions.reportDownloadFinished());
  };
};
