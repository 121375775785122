import * as _ from 'lodash';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as ReduxForm from 'redux-form';
import * as ReportState from 'client/state/reports';
import * as State from 'client/state/state';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const startDate = formValues[Constants.FormFields.startDate];
  const todaysDate = formValues[Constants.FormFields.todaysDate];

  if (!formValues[Constants.FormFields.customerId] ||
    _.isNil(startDate) ||
    _.isNil(todaysDate) ||
    extractSelectedValues(formValues[Constants.FormFields.productIds]).length === 0 ||
    extractSelectedValues(formValues[Constants.FormFields.storeIds]).length === 0 ||
    _.isNil(formValues[Constants.FormFields.primaryGlobalAll])) {
    return true;
  }

  return false;
};

export const getReportDownloadStatus = (state: State.Type): Mutations.MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};

export const getScanProductOptions = (state: State.Type): boolean[] => {
  return ReportState.getScanProductOptions(state, Constants.formName);
};
