import * as React from 'react';
import { ReceivingSummaryStats } from 'shared/types';
import { ReceivingOrderedCard } from 'client/app/transportation/receiving/details/stats-row/cards/receiving-ordered-card';
import { ReceivingReceivedCard } from 'client/app/transportation/receiving/details/stats-row/cards/receiving-received-card';
import { ReceivingReturnedCard } from 'client/app/transportation/receiving/details/stats-row/cards/receiving-returned-card';
import { ReceivingSupplierOnHandCard } from 'client/app/transportation/receiving/details/stats-row/cards/receiving-supplier-on-hand-card';

export const StatsRow = (p: { children?}) => <div className="stats-row">{p.children}</div>;

interface ReceivingStatsRowProps extends ReceivingSummaryStats {
  showExtraInfo?: boolean;
}

export function ReceivingStatsRow(props: ReceivingStatsRowProps) {
  return (
    <StatsRow>
      <ReceivingOrderedCard unit={props.unit} ordered={props.ordered} />
      <ReceivingReceivedCard unit={props.unit} received={props.received} />
      {props.showExtraInfo && <ReceivingReturnedCard returned={props.returned} />}
      {props.showExtraInfo && <ReceivingSupplierOnHandCard supplierOnHand={props.supplierOnHand} />}
    </StatsRow>
  );
}
