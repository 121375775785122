import * as React from 'react';
import { Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { Saved } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { Combo, ComboDetail } from 'schema/product-worksheet/types';
import { ComboListItem } from './combo-list-item';
import * as _ from 'lodash';
import { OrderMethod, ComboCart, DateStr } from 'shared/types';
import { formatDateStrForDisplay } from 'shared/helpers';
import { ProgressSpinner } from 'client/components/progress-spinner';

interface ComponentProps {
  isShown: boolean;
  handleCancelButtonClicked(): void;
  recentComboSelected: (formName: string, orderMethod: OrderMethod, comboDetails: ComboDetail[], comboCartList: ComboCart[]) => void;
  customerOrder: Pick<Saved<CustomerOrder>, 'orderMethod' | 'shippingUnitType'| 'sellDepartment' | 'customerId'>;
  comboCartList: ComboCart[];
  parentFormName: string;
  combos: Combo[] | undefined;
  loading: boolean;
}

export type OwnProps = ComponentProps;

export class SelectRecentComboModalUI extends React.PureComponent<OwnProps, {}> {
  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    const comboGroups = _.groupBy(this.props.combos || [], c => c.lastUsed);
    const dates = _.keys(comboGroups);

    return (
      <Modal
        id="select-recent-combo-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="select-recent-combo-modal-container"
        className="product-worksheet-products-list"
      >
        <GlobalAsyncModalHeader>Select Recent Combo</GlobalAsyncModalHeader>
        <Modal.Body>
          {this.props.loading && <ProgressSpinner label="Loading..." />}
          {!this.props.loading &&
            <div className="product-worksheet-products-list recent-combo-modal">
              {dates
                ? dates.map((date: DateStr, firstIndex) => {
                  const combos = comboGroups[date];

                  return (
                    <div>
                      <div className="recent-combo-date">{formatDateStrForDisplay(date)}</div>
                        {combos.map((combo, secondIndex) => {
                          return (
                            <ComboListItem
                              lastUsed={combo.lastUsed}
                              comboDetails={combo.details}
                              orderMethod={this.props.customerOrder.orderMethod}
                              recentComboSelected={(comboDetails: ComboDetail[]) => this.props.recentComboSelected(this.props.parentFormName, this.props.customerOrder.orderMethod, comboDetails, this.props.comboCartList)}
                              key={`${firstIndex}-${secondIndex}`}
                            />
                          );
                        })}
                      </div>
                    );
                  })
                : <div/>
              }
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
