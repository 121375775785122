import * as Redux from 'redux';
import * as Mutation from './mutation';
import { Thunker } from 'client/types/redux-types';
import { INVOICE_DETAIL_ADD_ROUTE_PLANS_MODAL_TABLE } from 'client/constants';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { ConfirmOkToSaveFunction } from '../shared/save-confirmation';
import { buildTableStateModule } from 'client/state/tables';

export type ActionTypes =
  AddRoutePlansModalCloseClickedAction |
  AddRoutePlansToInvoiceCompletedAction
  ;

export enum ActionTypeKeys {
  AddRoutePlansModalCloseClicked = 'App/ADD_ROUTE_PLANS_MODAL_CLOSE_CLICKED',
  AddRoutePlansToInvoiceCompleted = 'App/ADD_ROUTE_PLANS_TO_INVOICE_COMPLETED',
}

export interface AddRoutePlansModalCloseClickedAction extends Redux.Action {
  type: ActionTypeKeys.AddRoutePlansModalCloseClicked;
}

export const onAddRoutePlansModalCloseClicked = (): AddRoutePlansModalCloseClickedAction => {
  return {
    type: ActionTypeKeys.AddRoutePlansModalCloseClicked,
  };
};

export interface AddRoutePlansToInvoiceCompletedAction extends Redux.Action {
  type: ActionTypeKeys.AddRoutePlansToInvoiceCompleted;
}

const onAddRoutePlansToInvoiceCompleted = (): AddRoutePlansToInvoiceCompletedAction => {
  return {
    type: ActionTypeKeys.AddRoutePlansToInvoiceCompleted,
  };
};

const TableStateHelpers = buildTableStateModule(INVOICE_DETAIL_ADD_ROUTE_PLANS_MODAL_TABLE);

export const handleAddClicked = (invoiceId: number, refetchQueries: string[], confirmOkToSave: ConfirmOkToSaveFunction): Thunker => {
  return async (dispatch, getState) => {
    const confirmed = await confirmOkToSave();
    if (!confirmed) {
      return;
    }
    const routePlanIds = TableStateHelpers.checkedRecordIds(TableStateHelpers.tableStateLens.get(getState()));
    try {

      await msyncClientMutation<Mutation.Response, Mutation.Input>({
        mutation: Mutation.Mutation,
        variables: {
          input: {
            invoiceId,
            routePlanIds,
          },
        },
        dispatch,
        refetchQueries,
      });

      dispatch(onAddRoutePlansToInvoiceCompleted());

    } catch {
      // No-op - we don't want to close the modal if theres an error
    }
  };
};
