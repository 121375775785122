import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { SalesPlanVarietiesExportUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import { Thunker } from 'client/types/redux-types';
import * as Queries from './query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { SelectableValue } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  salesPlanId: number | undefined;
  scanBased: boolean;
  poBased: boolean;
  productClassIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  productIds: SelectableValue | undefined;
  reportDownloadStatus: MutationStatus;
  downloadButtonDisabled: boolean;
  scanProductOptions: boolean[];
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const downloadButtonDisabled = Selectors.shouldDownloadBeDisabled(state);

  if (!formValues) {
    return {
      salesPlanId: undefined,
      scanBased: false,
      poBased: false,
      productClassIds: undefined,
      productSubClassIds: undefined,
      productIds: undefined,
      reportDownloadStatus: MutationStatus.Initial,
      downloadButtonDisabled,
      scanProductOptions: [],
    };
  }
  const scanProductOptions = Selectors.getScanProductOptions(state);

  return {
    salesPlanId: formValues[Constants.FormFields.salesPlanId],
    scanBased: formValues[Constants.FormFields.scanBased],
    poBased: formValues[Constants.FormFields.poBased],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    productIds: formValues[Constants.FormFields.productIds],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    downloadButtonDisabled,
    scanProductOptions,
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(): Thunker;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.SupplierQueryProps &
  SharedQueries.CustomerQueryProps &
  Queries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  Queries.SalesPlanProductQueryProps &
  Queries.SalesPlanQueryProps &
  Queries.SalesPlanProductsQueryProps &
  SharedQueries.ProductQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: { },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withSalesPlans,
  Queries.withSalesPlanProducts,
  Queries.withProductClasses,
  SharedQueries.withProductSubClasses,
  Queries.withProducts({ onlyIncludeParentReplenishmentProducts: true }),
  UserParamsMappers.withSalesPlanVarietiesExportUserParamMappers,
)(SalesPlanVarietiesExportUI) as Component<OwnProps>;

export const SalesPlanVarietiesExport = (props: OwnProps) => propToComponent(component, props);
