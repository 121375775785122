import * as React from 'react';
import ReceivingDetailsRecordBar from 'client/app/transportation/receiving/details/receiving-details-record-bar/receiving-details-record-bar-container';
import { ReceivingDetailsNavigationBar } from 'client/app/transportation/receiving/details/receiving-details-navigation-bar/receiving-details-navigation-bar';
import SendReceivingReceiptModal from 'client/app/transportation/receiving/details/related-bols/send-receiving-receipt-modal-container';

interface OwnProps {
  receivableOrderId: number;
  children?: React.ReactNode | React.ReactNode[];
  handleReceivingDetailsUnmounted: () => void;
}

export class ReceivingDetails extends React.Component<OwnProps, {}> {
  componentWillUnmount() {
    this.props.handleReceivingDetailsUnmounted();
  }

  public render() {
    return (
      <div className="record" id="TBD">
        <ReceivingDetailsRecordBar receivableOrderId={this.props.receivableOrderId} />
        <div className="mfc-form mfc-form mfc-form-not-scrollable">
          <ReceivingDetailsNavigationBar receivableOrderId={this.props.receivableOrderId} />
          <SendReceivingReceiptModal receivableOrderId={this.props.receivableOrderId} />
          {this.props.children}
        </div>
      </div>
    );
  }
}
