import { CustomerContactRole } from 'shared/types';
import * as _ from 'lodash';

export const createContactHeader = (contact?: {name?: string | null, role?: CustomerContactRole}) => {
  // Looks crazy but it basically goes like this:
  //  * If there is no contact role or name, then display: New Contact
  //  * If the contact has a role but no name, then display: <Role Name>
  //  * If the contact has a role and their name is Accounts Payable, then display: Accounts Payable
  //  * If the contact has a role and a name (that isn't Accounts Payable), then display: <Role Name> - <Name>
  return  contact && contact.name && contact.role
          ? contact.name === 'Accounts Payable'
          ? `${contact.name}`
          : `${_.startCase(contact.role)}${contact.role ? ' - ' : ''}${contact.name}`
          : contact && contact.role
          ? `${_.startCase(contact.role)}`
          : 'New Contact';
};
