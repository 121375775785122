export enum AuditLoggedActionAction {
  I = 'I',
  D = 'D',
  U = 'U',
  T = 'T',
}

export enum BoxActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum BucketActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CartTrackingSupplierEntryAdjustmentType {
  Returned = 'Returned',
  Received = 'Received',
}

export enum CustomerContactRole {
  PrimaryContact = 'PrimaryContact',
  AccountsPayable = 'AccountsPayable',
  ReceivingManager = 'ReceivingManager',
  Buyer = 'Buyer',
}

export enum CustomerActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ImportJobStatus {
  importing = 'importing',
  failed = 'failed',
  imported = 'imported',
}

export enum ImportableDocumentStatus {
  fileCopied = 'fileCopied',
  parsing = 'parsing',
  parsed = 'parsed',
  failed = 'failed',
  retry = 'retry',
  skipped = 'skipped',
}

export enum InvoiceType {
  CustomerOrder = 'CustomerOrder',
  DropShip = 'DropShip',
  VendorChargeback = 'VendorChargeback',
}

export enum MarketActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum MfcAreaActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum PotActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum PrimaryGlobalTrackingPrimaryGlobal {
  Primary = 'Primary',
  Global = 'Global',
}

export enum ProductClassActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ProductSubClassActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ProductActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum ProductBulkType {
  NONE = 'NONE',
  ITEM = 'ITEM',
}

export enum ReceivableOrderReceivingStatus {
  NotReceived = 'Not Received',
  PartiallyReceived = 'Partially Received',
  FullyReceived = 'Fully Received',
  OverReceived = 'Over Received',
}

export enum RegionActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ReportFileType {
  excel = 'excel',
  pdf = 'pdf',
  text = 'text',
  csv = 'csv',
}

export enum SellDepartmentActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum StoreActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SubSellDepartmentActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SupplierItemActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum SupplierItemPrimaryShippingUnitType {
  Rack = 'Rack',
  Pallet = 'Pallet',
  NA = 'N/A',
}

export enum SupplierLocationActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SupplierOrderMailType {
  PurchaseOrder = 'PurchaseOrder',
  ReceivingReceipt = 'ReceivingReceipt',
}

export enum SupplierActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum TagActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum TrayActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum UserActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
