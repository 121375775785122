import * as _ from 'lodash';
import { MoneyStr, toMoneyStr, addMoney, multiplyMoney, LoadType, Carrier, RouteType, ChargeType } from 'shared/types';
import { MileageRateTableRow } from 'schema/routing-load/routing-load-graphql-types';
import { findMileageRateRow } from 'shared/app/routing/snapshot-table-helpers';

export const calculateTotalDropFee = (firstDropRate: MoneyStr, restDropRate: MoneyStr, numberOfDrops: number): MoneyStr => {
  const zeroMoney = toMoneyStr(0);
  if (numberOfDrops <= 0) {
    return zeroMoney;
  } else if (numberOfDrops === 1) {
    return firstDropRate;
  } else {
    return addMoney(firstDropRate, multiplyMoney(restDropRate, numberOfDrops - 1));
  }
};

export const calculateTotalMilesFee = (args: {
  carrier: Carrier | null,
  loadType: LoadType | null,
  routeType: RouteType | null,
  totalMiles: number,
  mileageRateTable: MileageRateTableRow[],
}): MoneyStr => {

  if (args.totalMiles <= 0) {
    return toMoneyStr(0);
  }

  const roundedMiles = roundMileage(args.totalMiles);

  const rateInfo = findMileageRateRow({
    ...args,
    totalMiles: roundedMiles,
  });

  if (!rateInfo || !rateInfo.rate) {
    return toMoneyStr(0);
  }

  switch (rateInfo.chargeType) {
    case ChargeType.MR:
      return multiplyMoney(toMoneyStr(rateInfo.rate), roundedMiles);
    default:
      return toMoneyStr(rateInfo.rate);
  }
};

export const calculateTotalFuelSurcharge = (fuelSurchargeRate: MoneyStr, numberOfMiles: number): MoneyStr => {
  return multiplyMoney(fuelSurchargeRate, roundMileage(numberOfMiles));
};

const roundMileage = (miles: number) => {
  return _.round(miles, 0);
};
