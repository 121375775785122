import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/supplier-commitment/types';

export interface Input {
  supplierCommitmentId: number;
}

interface SupplierCommitment {
  id: GraphQLTypes.SupplierCommitment['id'];
  salesPlan: {
    id: number;
    year: number;
    identifier: string;
    customer: {
      id: number;
      name: string;
      identifier: string | undefined;
    };
    sellDepartment: {
      id: number;
      identifier: string;
    };
  };
  supplier: Pick<GraphQLTypes.SupplierCommitment['supplier'], 'id' | 'identifier' | 'name'>;
  confirmed: GraphQLTypes.SupplierCommitment['confirmed'];
  lastModifiedAt: GraphQLTypes.SupplierCommitment['lastModifiedAt'];
}
export interface QueryResponse {
  supplierCommitment: SupplierCommitment;
}

export const Query = gql`
  query SupplierCommitmentRecordBarQuery($supplierCommitmentId: Int!) {
    supplierCommitment: GetSupplierCommitment(id: $supplierCommitmentId) {
      id
      salesPlan {
        id
        year
        identifier
        customer {
          id
          name
          identifier
        }
        sellDepartment {
          id
          identifier
        }
      }
      confirmed
      lastModifiedAt
      supplier {
        id
        name
        identifier
      }
    }
  }
`;
