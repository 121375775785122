import navBuilder from './nav-items';
import { RecordBarDetailComponent } from 'client/components/crud-forms/store/store-record-bar-detail';
import buildAdminAttachmentPage from '../admin/admin-attachments-container';
import { STORE_ATTACHMENTS_TABLE_NAME } from 'client/constants';

export default buildAdminAttachmentPage({
  formName: 'StoreAttachmentsForm',
  tableName: STORE_ATTACHMENTS_TABLE_NAME,
  navBuilder,
  RecordBarDetailComponent,
  recordType: 'Store',
});
