import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';
import * as ReportSelectors from 'client/state/reports';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getScanProductOptions = (state: State.Type): boolean[] => {
  return ReportSelectors.getScanProductOptions(state, Constants.formName);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  return _.isNil(formValues[Constants.FormFields.salesPlanId])
    || extractSelectedValues(formValues[Constants.FormFields.productIds]).length === 0;
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
