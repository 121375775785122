import * as React from 'react';
import { formatNumber } from 'shared/types';

const PercentageCell = (args: { value: number }) => (
  <div>
    <div className="percentage pull-right">{formatNumber((args.value || 0) * 100, 3, ',')}<span className="fa fa-percent" /></div>
  </div>
);

export default PercentageCell;
