import gql from 'graphql-tag';

export const generateDailySalesReport = gql`
mutation generateDailySalesReport($reportInput: GenerateDailySalesReportInput) {
  response: GenerateDailySalesReport(reportInput: $reportInput) {
    id
  }
}
`;

export const generateStoreBreakdownReport = gql`
mutation generateStoreBreakdownReport($reportInput: GenerateStoreBreakdownReportInput) {
  response: GenerateStoreBreakdownReport(reportInput: $reportInput) {
    id
  }
}
`;
