import * as React from 'react';
import { AutoReplenishmentProps } from 'client/app/orders/customer-orders/product-worksheet/auto-replenishment/auto-replenishment-modal-container';
import { LabeledSelect, LabeledInput } from 'client/components/form';
import { Row, FormGroup } from 'client/components/third-party';
import { Field } from 'redux-form';

interface OwnProps {
  customerOrderProductGroupIds: AutoReplenishmentProps['customerOrderProductGroupIds'];
  productLabel?: string;
  storeIds: number[];
}

export const AutoReplenishmentFormUI: React.SFC<OwnProps> = props => {
  return (
    <div>
      <div className="auto-replenishment-modal-product-label">
        {props.productLabel}
      </div>

      <Row>
        <FormGroup>

          <Field
            name="customerOrderProductGroupId"
            component={LabeledSelect}
            options={props.customerOrderProductGroupIds}
            textFormatter={(r: { label: string }) => r.label}
            inputColSize={6}
            label="Rack Type"
            labelColSize={0}
            offset={0}
            testid="auto-replenishment-modal-customer-order-product-group-id"
            horizontalLabel={false}
            required
          />
          <Field
            component={LabeledInput}
            name="targetPercentShipped"
            type="number"
            step={1}
            label="Target % Shipped"
            placeholder=""
            disabled={false}
            testid="auto-replenishment-modal-target-percent-shipped"
            labelColSize={0}
            inputColSize={6}
            offset={0}
            horizontalLabel={false}
            required
            hideOptionalLabel={false}
            autoFocus
          />
        </FormGroup>
      </Row>
    </div>
  );
};
