import {
  tableName, property, belongsTo, definePresentation, gqlResolver, setSchemaOptions, required, defineFieldValidators,
} from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, ProductPriceChangeReasons, SORT_TYPES, DateStr } from 'shared/types';
import { Region } from './region';
import { Market } from './market';
// import { Product, ProductId } from './product';
import { IRecord } from './record';
import * as Validators from 'shared/validators';
import { displayType } from './dsl';

@tableName('productPrices')
export class ProductPrice implements IRecord {
  static readonly SchemaName = 'ProductPrice';

  id?: number;
  @property @required beginDate: DateStr;

  @belongsTo('regions', { through: 'markets' })
  @property region?: Region;
  regionId?: number;

  @belongsTo('markets')
  @property market?: Market;
  marketId?: number;

  @property @required changeReason: ProductPriceChangeReasons;
  @property notes?: string | null;
  @property @required mfcPrice: number;
  @property scanbackPrice?: number | null;
  @property @required retailPrice: number;
  @property upcRetailPrice?: number | null;

  static readonly calculateMargin = (x: ProductPrice) => (x.retailPrice - x.mfcPrice + (x.scanbackPrice || 0)) / x.retailPrice;

  // WARNING gqlResolver only works for GQL resolving... somewhere up the layered salad, not sure precisely.  prolly either resolver or manager.
  @gqlResolver(ProductPrice.calculateMargin)
  @property margin: number;

  @belongsTo('products')
  @property @required product: shame; // Product; latent circular dependency with Product and ProductPrice
  productId: number;// ProductId;
}

const MAX_MONEY = 999999.99;

defineFieldValidators(ProductPrice, {
  mfcPrice: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(MAX_MONEY)],
  scanbackPrice: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(MAX_MONEY)],
  retailPrice: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(MAX_MONEY)],
  upcRetailPrice: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(MAX_MONEY)],
});

definePresentation(ProductPrice, {
  beginDate: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 9,
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
  },
  region: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.SELECTABLE),
  },
  market: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.SELECTABLE),
  },
  changeReason: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 15,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ProductPriceChangeReasons }),
  },
  notes: {
    searchable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 30,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
  },
  mfcPrice: {
    displayName: 'Price',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 6,
    type: TYPES.FLOAT,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
  },
  scanbackPrice: {
    displayName: 'Scanback',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 6,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  retailPrice: {
    displayName: 'Retail',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 6,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  upcRetailPrice: {
    displayName: 'UPC Retail',
    sortable: true,
    tableDisplay: true,
    tableEditable: true,
    columnWidth: 6,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
  margin: {
    tableDisplay: true,
    columnWidth: 6,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.PERCENTAGE }),
    type: TYPES.FLOAT,
  },
  product: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
});

setSchemaOptions(ProductPrice, {
  defaultSort: { sortOrder: SORT_TYPES.DESC, sortField: 'beginDate' },
});
