import * as _ from 'lodash';
import * as React from 'react';
import { searchableColumns, columnInfo, tableDisplayColumns, formQueryColumns, buildFragment, recordType as getRecordType } from 'shared/schemas';
import { IColumn } from 'client/components/table/column';
import { AvailableSearchField } from 'client/types';
import gqlTag from 'graphql-tag';
import gql from 'graphql-tag';
import { createSelector } from 'reselect';
import { CELL_TYPES, DISPLAY_TYPES, SEARCH_FIELD_ANY } from 'shared/types';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { buildFunctionHolder } from 'client/utils/side-effect-function-holder';

interface OwnProps {
  content: any[];
  refetchStats: () => Promise<void>;
}

type MakeSaveMutation = (field: string) => (id: number, value: any) => Promise<any>;

interface ContainerProps extends ColumnProps {
  makeSaveMutation: MakeSaveMutation;
}

export interface ColumnProps {
  columns: IColumn[];
  excelColumns: IColumn[];
  searchableFields: AvailableSearchField[];
}

const getMutationMaker = (mutationMaker: MakeSaveMutation) => mutationMaker;
export const getColumnSelector = (schema: string, displayColumns?: string[], forExcel?: boolean) => createSelector([getMutationMaker], makeMutation => {
  const sourceColumns = displayColumns || tableDisplayColumns(schema);

  const columns: IColumn[] = _.flatten(sourceColumns.map(c => {
    const info = columnInfo(schema, c);
    if (info.id === 'id')
      return [];

    const cellType = info.formDisplayType?.type ?? CELL_TYPES.TEXT;
    let cols: IColumn[] = [{
      id: info.id,
      accessor: info.id,
      header: info.displayName ?? _.startCase(info.id),
      sortable: info.sortable,
      columnWidth: info.columnWidth,
      headerStyle: { textAlign: 'left' },
      cellType: cellType === DISPLAY_TYPES.INPUT ? info.formDisplayType.inputType! : cellType,
      type: info.type,
      tableEditable: info.tableEditable,
      validators: info.validators,
      onSave: makeMutation(info.id),
    }];

    if (info.fkSpec) {
      cols = Object.keys(info.tableDisplayColumns).map(k => {
        const foreignInfo = columnInfo(info.fkSpec!.foreignTable, k);
        let foreignCellType = foreignInfo.formDisplayType ? foreignInfo.formDisplayType.type : CELL_TYPES.TEXT;
        if (foreignCellType === DISPLAY_TYPES.INPUT) {
          foreignCellType = foreignInfo.formDisplayType.inputType!;
        }
        return {
          id: `${info.id}.${k}`,
          accessor: d => {
            return d[info.id] ? d[info.id][k] : '';
          },
          header: info.tableDisplayColumns[k] || foreignInfo.displayName,
          sortable: foreignInfo.sortable,
          cellType: foreignCellType,
          type: foreignInfo.type,
          columnWidth: info.columnWidth,
          minWidth: info.columnWidth,
          headerStyle: { textAlign: 'left' },
          tableEditable: false,
          validators: [],
        };
      });
    }

    return cols;
  }));

  return columns;
});

export const withSchemaColumnMappings = (options: { schema: string, displayColumns?: string[], excelColumns?: string[], refetchQueries?: string[] }) => {
  const searchColumns: AvailableSearchField[] = searchableColumns(options.schema).map(col => {
    return { id: col, name: columnInfo(options.schema, col).displayName };
  });

  if (searchColumns.length > 0) {
    searchColumns.unshift({ id: SEARCH_FIELD_ANY, name: 'Search All' });
  }

  const withColumns = (WrappedComponent: (p: ContainerProps) => React.ReactElement<ContainerProps>): React.ComponentClass<ContainerProps> => {
    const columnSelector = getColumnSelector(options.schema, options.displayColumns);
    const excelColumnSelector = getColumnSelector(options.schema, options.excelColumns);

    return class Container extends React.Component<ContainerProps, any> {
      constructor(props) {
        super(props);
      }

      public render() {
        const {columns: columnsOverride, excelColumns: excelColumnsOverride, searchableFields: searchableFieldsOverride, ...moreProps} = this.props;
        return (
          <WrappedComponent
            columns={columnsOverride || columnSelector(this.props.makeSaveMutation)}
            excelColumns={excelColumnsOverride || excelColumnSelector(this.props.makeSaveMutation)}
            searchableFields={searchableFieldsOverride || searchColumns}
            { ...moreProps }
          />
        );
      }
    };
  };

  const fragmentName = `${options.schema}EditFragment`;
  const fragment = gqlTag`${buildFragment(options.schema, formQueryColumns(options.schema), fragmentName)}`;
  const recordType = getRecordType(options.schema);
  const DEFAULT_MUTATION = gql`
  mutation Edit${recordType}($input: Edit${recordType}Input!) {
    data: edit${recordType}(input: $input) {
      id,
      ...${fragmentName}
    }
  }
  ${fragment}
  `;

  // Mutable bucket to cache-bust for the latest mutate function
  const mutateHolder = buildFunctionHolder('mutate');

  const saveMutation = msyncMutation<{}, OwnProps, { makeSaveMutation: (f: string) => (id: number, value: any) => Promise<any> }, {}>(DEFAULT_MUTATION, {
    alias: 'withSchemaColumnMappings',
    props(args) {

      // SIDE-EFFECT! - Update the closed-over object such that any cached functions will always be using the latest
      //                 versions of this functions. See the buildFunctionHolder for more info
      mutateHolder.update(args.mutate);

      return {
        makeSaveMutation(field: string) {
          return async (id: number, value: any) => {
            const record = { id, [field]: value };
            return mutateHolder.mutate({ variables: { input: record }, refetchQueries: options.refetchQueries });
          };
        },
      };
    },
  });

  return WrappedComponent => (
    _.flowRight(
      saveMutation,
      withColumns,
    )(WrappedComponent)
  );
};

export default withSchemaColumnMappings;
