import * as React from 'react';
import { Col, Form, FormGroup, Row } from 'client/components/third-party';
import { SpecializeField } from '../../form';
import {Customer} from 'shared/schemas/customer';
import { FieldArray, change, getFormValues } from 'redux-form';
import { CustomerContactRole } from 'shared/types';
import CustomerContactFormGroup, { CustomerContactFormGrouProps } from './customer-contact-form-group';
import { connect } from 'react-redux';
import * as _ from 'lodash';

const Field = SpecializeField(Customer, { alwaysShowErrors: true, hideOptionalLabel: true });

export interface CustomerContact {
  role: CustomerContactRole;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  fax?: string | null;
  name?: string | null;
  phone?: string | null;
  state?: string | null;
  zip?: string | null;
}

interface Props {
  handleSubmit: any;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  formName: string;
  contacts: CustomerContact[];
  dispatchedChange: (formName: string, fieldName: string, value: any) => void;
  handleRoleChange: (formName, contacts, value, index) => void;
  record: {
    id?: number | null;
  };
}

function mapStateToProps(state, ownProps) {
  const values = getFormValues(ownProps.formName)(state) as { contacts: any[] };
  return {
    contacts: values?.contacts || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchedChange: (form, field, value) => dispatch(change(form, field, value)),
    handleRoleChange: (formName, contacts, value, index) => {
      if (!contacts[index].name && value === CustomerContactRole.AccountsPayable) {
        dispatch(change(formName, `contacts[${index}].name`, _.startCase(value)));
      } else if (contacts[index].name === _.startCase(CustomerContactRole.AccountsPayable)) {
        dispatch(change(formName, `contacts[${index}].name`, null));
      }
      dispatch(change(formName, `contacts[${index}].role`, value));
    },
  };
}

class Component extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  public render() {
    const onAddContactClick = fields => {
      return () => {
        if (!this.props.contacts) {
          this.props.dispatchedChange(this.props.formName, 'contacts[0]', {});
          return fields;
        }
        return fields.push({});
      };
    };

    const onDeleteContactClick = (fields, index) => {
      return () => {
        const removedContact = fields.remove(index);
        return removedContact;
      };
    };

    const onRoleChange = (value, index) => {
      this.props.handleRoleChange(this.props.formName, this.props.contacts, value, index);
    };

    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div data-testid="customer">
            <Row>
              <div className="mfc-form-heading">
                Customer Details
              </div>
              <FormGroup data-testid="info-row-1">
                <Field name="name" labelColSize={2} inputColSize={4} autoFocus={!this.props.record || !this.props.record.id} />
                <Field name="displayName" labelColSize={1} inputColSize={4} />
              </FormGroup>
              <FormGroup data-testid="info-row-2">
                <Field name="shortName" labelColSize={2} inputColSize={6} />
                <Field name="identifier" labelColSize={1} inputColSize={2} />
              </FormGroup>
              <FormGroup data-testid="info-row-3">
                <Field name="address1" labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid="info-row-4">
                <Field name="address2" labelColSize={2} inputColSize={9} />
              </FormGroup>
              <FormGroup data-testid="info-row-5">
                <Field name="city" labelColSize={2} inputColSize={4} />
                <Field name="state" labelColSize={1} inputColSize={1} />
                <Field name="zip" labelColSize={1} inputColSize={2} />
              </FormGroup>
              <FormGroup data-testid="info-row-6">
                <Field name="country" labelColSize={2} inputColSize={5} />
              </FormGroup>
            </Row>
            <FieldArray<CustomerContactFormGrouProps>
              name="contacts"
              component={CustomerContactFormGroup}
              handleAddContactClick={onAddContactClick}
              handleDeleteContactClick={onDeleteContactClick}
              handleRoleChange={onRoleChange}
              contacts={this.props.contacts}
            />
          </div>
        </Form>
      </Col>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
