import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../form';
import { optionsContainerGenerator } from '../../hoc/options-container-generator';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="regions" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

/*

RegionForm.propTypes = {
  customers: React.PropTypes.arrayOf(React.PropTypes.shape({
    value: React.PropTypes.number,
    label: React.PropTypes.string,
  })),
  customersLoading: React.PropTypes.bool,
  handleSubmit: React.PropTypes.func.isRequired,
  pristine: React.PropTypes.bool.isRequired,
  invalid: React.PropTypes.bool.isRequired,
  submitting: React.PropTypes.bool.isRequired,
  record: React.PropTypes.object,
};
*/
class RegionForm extends React.Component<shame> {
  constructor(props) {
    super(props);
  }

  render() {
    const { customers, record } = this.props;

    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form" data-testid="region">
            <FormGroup data-testid="info-row-1">
              <Field name="customerId" labelColSize={2} inputColSize={5} options={customers} loading={this.props.customersLoading} autoFocus={!record || !record.id} />
            </FormGroup>
            <FormGroup data-testid="info-row-2">
              <Field name="identifier" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup data-testid="info-row-3">
              <Field name="description" labelColSize={2} inputColSize={5} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }
}

const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });

export default withCustomers(RegionForm);
