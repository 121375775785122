import { connect } from 'react-redux';
import * as State from 'client/state/state';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { propToComponent } from 'client/hoc/hoc';
import { ImportRouteModalContentStepThreeUI, ComponentProps as UIProps } from './import-route-modal-step-three-ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  showSplitStopsTable: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    showSplitStopsTable: ImportRouteSelectors.splitStopsExist(state),
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
}

const mapDispatchToProps: DispatchProps = {
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ImportRouteModalContentStepThreeUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
