import { DISPLAY_TYPES, INPUT_TYPES, Carrier, LoadType, RouteType, ChargeType, TYPES } from '../types';
import { tableName, property, definePresentation, required, defineFieldValidators } from './dsl';
import { IRecord } from './record';
import { displayType } from './dsl';
import * as Validators from 'shared/validators';

@tableName('mileageRates')
export class MileageRate implements IRecord {
  id?: number;
  @property @required carrier: Carrier;

  @property @required loadType: LoadType;
  @property @required routeType: RouteType;
  @property @required chargeType: ChargeType;

  @property @required startMiles: number;
  @property @required endMiles: number;

  @property @required charge: number;
}

defineFieldValidators(MileageRate, {
  startMiles: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  endMiles: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  charge: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
});

definePresentation(MileageRate, {
  carrier: {
    sortable: true,
    tableDisplay: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: Carrier }),
    columnWidth: 14,
  },
  loadType: {
    sortable: true,
    tableDisplay: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: LoadType }),
    columnWidth: 14,
  },
  routeType: {
    sortable: true,
    tableDisplay: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: RouteType }),
    columnWidth: 14,
  },
  chargeType: {
    sortable: true,
    tableDisplay: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ChargeType }),
    columnWidth: 14,
  },
  startMiles: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 14,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
  },
  endMiles: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 14,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
  },
  charge: {
    sortable: true,
    tableDisplay: true,
    columnWidth: 14,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
  },
});
