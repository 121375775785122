import gql from 'graphql-tag';
import { InvoiceType } from 'shared/types';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import { extractQueryName } from 'client/utils/query-utils';

export interface Input {
  invoiceId: number;
}

interface BaseQueryInvoice {
  id: InvoiceGraphqlTypes.Invoice['id'];
  totalPrice: InvoiceGraphqlTypes.Invoice['totalPrice'];
}

interface QueryCustomerOrderInvoice extends BaseQueryInvoice {
  type: InvoiceType.CustomerOrder;
  totalPieces: InvoiceGraphqlTypes.CustomerOrderInvoice['totalPieces'];
  totalPacks: InvoiceGraphqlTypes.CustomerOrderInvoice['totalPacks'];
}

interface QueryVendorChargebackInvoice extends BaseQueryInvoice {
  type: InvoiceType.VendorChargeback;
  vendorChargebackTotalPacks: InvoiceGraphqlTypes.VendorChargebackInvoice['vendorChargebackTotalPacks'];
  vendorChargebackTotalPieces: InvoiceGraphqlTypes.VendorChargebackInvoice['vendorChargebackTotalPieces'];
}

interface QueryDropShipInvoice extends BaseQueryInvoice {
  type: InvoiceType.DropShip;
  storesDelivered: InvoiceGraphqlTypes.DropShipInvoice['storesDelivered'];
}

export type QueryInvoice = QueryCustomerOrderInvoice | QueryDropShipInvoice | QueryVendorChargebackInvoice;

export interface QueryResponse {
  getInvoice?: {
    invoice: QueryInvoice;
  };
}

export const Query = gql`
  query GetInvoiceStats($invoiceId: Int!) {
    getInvoice: GetInvoice(id: $invoiceId) {
      invoice {
        id
        type
        totalPrice

        ... on DropShipInvoice {
          id
          storesDelivered
        }

        ... on CustomerOrderInvoice {
          id
          totalPacks
          totalPieces
        }

        ... on VendorChargebackInvoice {
          id
          vendorChargebackTotalPacks
          vendorChargebackTotalPieces
        }
      }
    }
  }
`;

export const GET_INVOICE_STATS_QUERY_NAME = extractQueryName(Query);
