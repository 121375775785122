/** An Error thrown deliberately by application code to disrupt control flow while returning information to an outer context which will lead to planned-contingency recovery behavior. i.e., NOT A BUG, or unpredictable platform failure.*/
export class ExpectedError extends Error {
  readonly debugInfo: any | undefined;
  readonly isExpectedError: boolean;

  constructor(message: string, debugInfo?: any) {
    super(message);
    this.debugInfo = debugInfo;
    this.isExpectedError = true;
  }
}
