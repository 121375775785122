import * as React from 'react';
import * as classnames from 'classnames';
import { GlobalStoreIdentifier } from 'shared/types';
import { noop } from 'lodash';

export interface ComponentProps {
  storeIdentifier: GlobalStoreIdentifier;
  selected?: boolean;
  handleStoreListItemClicked: (storeIdentifier: GlobalStoreIdentifier) => void;
  isFullyAllocated: boolean;
  hasStoreLoadRackQuantityError: boolean;
}

export class StoreListItemUI extends React.PureComponent<ComponentProps, {}> {

  onStoreListItemClicked = () => {
    this.props.handleStoreListItemClicked(this.props.storeIdentifier);
  }

  render() {
    const className = classnames('import-route-store-list-item', {
      selected: this.props.selected,
      error: this.props.hasStoreLoadRackQuantityError,
    });

    const icon = this.props.hasStoreLoadRackQuantityError && this.props.selected
      ? 'fa-times'
      : this.props.isFullyAllocated
        ? 'fa-check'
        : this.props.selected
          ? 'fa-chevron-right'
          : undefined;

    const onStoreListItemClicked = !this.props.hasStoreLoadRackQuantityError
      ? this.onStoreListItemClicked
      : noop;

    return (
      <div className={className} onClick={onStoreListItemClicked} data-testid={`store-list-item-${this.props.storeIdentifier.storeNumber}`}>
        <span>Store {this.props.storeIdentifier.storeNumber}</span>
        {icon && <span className="import-route-store-list-item-icon"><i className={`fa ${icon}`}></i></span>}
      </div>
    );
  }
}
