import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import * as State from 'client/state/state';
import * as Selectors from 'client/state/selectors';
import { GlobalAsyncModalHeaderUI } from 'client/components/async-button/global-async-modal-header-ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GlobalAsyncModalHeaderProps {

}

interface StateProps {
  closeButton: boolean;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const inFlight = Selectors.isGlobalSaveButtonSpinning(state);
  return {
    closeButton: !inFlight,
  };
};

export type ComponentProps =
  GlobalAsyncModalHeaderProps &
  StateProps;

const component = flowRight(
  connect<StateProps, any, GlobalAsyncModalHeaderProps>(mapStateToProps, undefined),
)(GlobalAsyncModalHeaderUI);

export const GlobalAsyncModalHeader = (props: GlobalAsyncModalHeaderProps) => propToComponent(component, props);
