import * as _ from 'lodash';
import {
  SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
  SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY,
  SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS,
  SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
  SUPPLIER_ORDER_SELECT_ALL_CLICKED,
  SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS,
  SUPPLIER_ORDER_DETAILS_TABLE_NAME,
} from 'client/constants';
import { isNil } from 'lodash';
import { SupplierItem } from 'shared/schemas/supplier-item';
import { change, reset, formValueSelector } from 'redux-form';
import { Dispatch } from 'redux';
import { determineNextSupplierOrderProductGroupRackIdentifier, determineNextSupplierOrderProductGroupPalletIdentifier, determineNextSupplierOrderProductGroupComboIdentifier } from 'client/helpers/supplier-order';
import { OrderMethod, ShippingUnitOrderMethod, PackOrderMethod, ComboProductGroupResponse, CreateSupplierOrderProductGroupWithQuantityInput } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import { ApolloRefetch } from 'client/types';
import { SupplierOrderProduct, SupplierOrderProductId } from 'shared/schemas/supplier-order-product';
import { getRegularProductGroupDescription } from 'shared/helpers/order-helpers';
import { MutationStatus } from 'client/actions/mutations';
import { SupplierOrderEmailRecipient } from 'client/state/supplier-order';
import { resetTable } from 'client/actions/table';

export function packsPerRackCalculationInputChange(formName: string, supplierItemFieldIdentifier: string, field?: 'shelvesPerRack' | 'packsPerShelf' | 'packSize', value?: number | string) {
  return async (dispatch: Dispatch<any>, getState: () => SimpleObject) => {
    if (field) {
      dispatch(change(formName, `${supplierItemFieldIdentifier}.${field}`, value));
    }

    const selector = formValueSelector(formName);

    const formPackSize = selector(getState(), `${supplierItemFieldIdentifier}.packSize`) as number;
    const formShelvesPerRack = selector(getState(), `${supplierItemFieldIdentifier}.shelvesPerRack`) as number;
    const formPacksPerShelf = selector(getState(), `${supplierItemFieldIdentifier}.packsPerShelf`) as number;

    const formPacksPerShippingUnit = selector(getState(), `${supplierItemFieldIdentifier}.packsPerShippingUnit`) as number;

    let packsPerRack;
    if (formPacksPerShelf === null && formShelvesPerRack === null) {
      packsPerRack = formPacksPerShippingUnit;
    } else {
      packsPerRack = Math.floor(formShelvesPerRack * formPacksPerShelf);
    }

    dispatch(change(formName, `${supplierItemFieldIdentifier}.packsPerShippingUnit`, _.isNaN(packsPerRack) ? '' : packsPerRack));

    const piecesPerRack = Math.floor(formPackSize * packsPerRack);
    dispatch(change(formName, `${supplierItemFieldIdentifier}.piecesPerShippingUnit`, _.isNaN(piecesPerRack) ? '' : piecesPerRack));
  };
}

export function handleSectionUnmounted() {
  return (dispatch: Dispatch<any>) => {
    resetTable(dispatch, SUPPLIER_ORDER_DETAILS_TABLE_NAME);
  };
}

export function editSupplierOrderProductButtonClicked(supplierOrderProductId: SupplierOrderProductId) {
  return {
    type: SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_BUTTON_CLICKED,
    payload: {
      supplierOrderProductId,
    },
  };
}

export function editSupplierOrderProductGroupModalCancelButtonClicked() {
  return {
    type: SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
  };
}

export function addNewProductButtonClicked() {
  return {
    type: SUPPLIER_ORDER_ADD_NEW_PRODUCT_BUTTON_CLICKED,
  };
}

export function setSendSupplierPOModalVisibility(showModal: boolean) {
  return {
    type: SUPPLIER_ORDER_SET_SEND_SUPPLIER_PO_MODAL_VISIBILITY,
    payload: showModal,
  };
}

export function setSupplierOrderContacts(contacts: SupplierOrderEmailRecipient[]) {
  return {
    type: SUPPLIER_ORDER_SET_SUPPLIER_ORDER_CONTACTS,
    payload: contacts,
  };
}

export function setSendSupplierOrderActionStatus(actionStatus: MutationStatus) {
  return {
    type: SUPPLIER_ORDER_SET_SEND_SUPPLIER_ORDER_ACTION_STATUS,
    payload: actionStatus,
  };
}

export function supplierOrderEmailRecipientCheckboxClicked(supplierContactId: number) {
  return {
    type: SUPPLIER_ORDER_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
    payload: supplierContactId,
  };
}

export function selectAllClicked() {
  return {
    type: SUPPLIER_ORDER_SELECT_ALL_CLICKED,
  };
}

export function newProductModalCancelButtonClicked(formName: string): Thunker {
  return async (dispatch: Dispatch<any>) => {
    dispatch(reset(formName));
    dispatch({
      type: SUPPLIER_ORDER_NEW_PRODUCT_MODAL_CANCEL_BUTTON_CLICKED,
    });
  };
}

type SupplierItemChanged = Partial<Pick<SupplierItem, 'id' | 'packSize' | 'packsPerShelf' | 'shelvesPerRack' | 'casesPerPallet'>> & Pick<SupplierItem, 'product'>;

function updateModalFieldsOnSupplierItemChange(args: { dispatch: any, formName: string, supplierItemFieldIdentifier: string, supplierItem: SupplierItemChanged, isCombo: boolean, orderMethod: OrderMethod }) {
  const { dispatch, formName, supplierItem, supplierItemFieldIdentifier, isCombo } = args;

  // TBD JCN 8/14/17 Handle case/pallet based stuff. Supplier items currently doesn't have a cases per pallet field.
  const packsPerShippingUnit = (isNil(supplierItem.packsPerShelf) ? 0 : supplierItem.packsPerShelf) *
  (isNil(supplierItem.shelvesPerRack) ? 0 : supplierItem.shelvesPerRack);

  dispatch(change(formName, `${supplierItemFieldIdentifier}.supplierItemId`, supplierItem.id));
  dispatch(change(formName, `${supplierItemFieldIdentifier}.packSize`, isNil(supplierItem.packSize) ? '' : supplierItem.packSize.toString()));

  if (args.orderMethod === OrderMethod.ShippingUnit) {
    dispatch(change(formName, `${supplierItemFieldIdentifier}.shelvesPerRack`, isNil(supplierItem.shelvesPerRack) ? '' : supplierItem.shelvesPerRack.toString()));
    dispatch(change(formName, `${supplierItemFieldIdentifier}.packsPerShelf`, isNil(supplierItem.packsPerShelf) ? '' : supplierItem.packsPerShelf.toString()));
    dispatch(packsPerRackCalculationInputChange(formName, supplierItemFieldIdentifier));
  }

  if (!isCombo && packsPerShippingUnit > 0) {
    dispatch(change(formName, 'packsPerShippingUnit', packsPerShippingUnit));
  }
}

export function newSupplierItemModalSupplierItemChanged(args: { formName: string, supplierItemId: number, supplierItems: SupplierItemChanged[], orderMethod: OrderMethod, supplierItemFieldIdentifier: string, isCombo: boolean, supplierOrderProducts: SupplierOrderProduct[] }): Thunker {
  const { formName, supplierItemId, supplierItems, orderMethod, supplierItemFieldIdentifier } = args;
  return (dispatch: Dispatch<any>) => {
    const supplierItem = supplierItems.find(si => si.id === supplierItemId);
    if (!supplierItem) {
      return;
    }

    let identifier = '';

    const isCombo = args.isCombo || supplierItemFieldIdentifier !== 'supplierOrderProducts[0]';
    if (isCombo) {
      identifier = determineNextSupplierOrderProductGroupComboIdentifier({ supplierOrderProducts: args.supplierOrderProducts }); // TBD
    } else if (orderMethod === ShippingUnitOrderMethod) {
      identifier = determineNextSupplierOrderProductGroupRackIdentifier({ supplierOrderProducts: args.supplierOrderProducts, supplierItemId: args.supplierItemId });
    } else if (orderMethod === PackOrderMethod) {
      identifier = determineNextSupplierOrderProductGroupPalletIdentifier({ supplierOrderProducts: args.supplierOrderProducts, supplierItemId: args.supplierItemId }); // TBD
    }

    updateModalFieldsOnSupplierItemChange({ dispatch, supplierItem, formName, supplierItemFieldIdentifier, isCombo, orderMethod });
    if (isCombo) {
      dispatch(change(formName, 'description', ''));
      if (orderMethod === PackOrderMethod) {
        dispatch(change(formName, 'packsPerShippingUnit', ''));
      }
    } else {
      dispatch(change(formName, 'description', getRegularProductGroupDescription(supplierItem.product)));
      if (orderMethod === PackOrderMethod) {
        dispatch(change(formName, 'packsPerShippingUnit', _.isNil(supplierItem.casesPerPallet) ? '' : supplierItem.casesPerPallet.toString()));
      }
    }

    dispatch(change(formName, 'identifier', identifier));
  };
}

export function newProductModalProductCleared(formName: string): Thunker {
  return (dispatch: Dispatch<any>) => {
    dispatch(change(formName, 'supplierItemId', undefined));
    dispatch(change(formName, 'packSize', ''));
    dispatch(change(formName, 'packsPerRack', ''));
    dispatch(change(formName, 'shelvesPerRack', ''));
    dispatch(change(formName, 'description', ''));
    dispatch(change(formName, 'identifier', ''));
  };
}

export function newProductModalSaveClicked(args: {
  formName: string,
  mutation: (payload: CreateSupplierOrderProductGroupWithQuantityInput) => void,
  payload: CreateSupplierOrderProductGroupWithQuantityInput,
  refetchTable: ApolloRefetch,
}): Thunker {
  return async (dispatch: Dispatch<any>) => {
    try {
      await args.mutation(args.payload);
    } catch (error) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem creating supplier order product', error.message);
      return;
    }
    await args.refetchTable();

    dispatch(reset(args.formName));
    dispatch({
      type: SUPPLIER_ORDER_NEW_PRODUCT_MODAL_SAVE_BUTTON_CLICKED,
    });
  };
}

// NOTE: This function assumes that we are dealing with an order method of case. Current, only floral uses pre-made combo products,
// and they typically order via case.
export function newSupplierItemModalComboProductGroupChanged(args: { formName: string, selectedComboProductGroup: ComboProductGroupResponse | null, supplierOrderProducts }): Thunker {
  return async (dispatch: Dispatch<any>) => {
    if (args.selectedComboProductGroup) {
      dispatch(change(args.formName, 'comboProductGroupId', args.selectedComboProductGroup.id));
      const orderedComboProducts = _.orderBy(args.selectedComboProductGroup.comboProducts, ['id']);
      dispatch(change(args.formName, 'supplierOrderProducts', orderedComboProducts.map(comboProduct => {
        return {
          supplierItemId: comboProduct.supplierItemId,
          packSize: comboProduct.packSize,
        };
      })));
      dispatch(change(args.formName, 'packsPerShippingUnit', args.selectedComboProductGroup.packsPerShippingUnit));
      dispatch(change(args.formName, 'description', args.selectedComboProductGroup.description));
      dispatch(change(args.formName, 'identifier', determineNextSupplierOrderProductGroupComboIdentifier({ supplierOrderProducts: args.supplierOrderProducts })));
    } else {
      dispatch(change(args.formName, 'comboProductGroupId', null));
    }
  };
}

export function editSupplierOrderProductGroupModalSupplierItemChanged(args: { formName: string, isCombo, orderMethod: OrderMethod, supplierItem: SupplierItemChanged, supplierItemFieldIdentifier }) {
  const { isCombo, orderMethod, formName, supplierItem, supplierItemFieldIdentifier } = args;
  return (dispatch: Dispatch<any>) => {
    // TODO: This function is a mess, and it's probably my fault... :P (AP 9/20/17)
    updateModalFieldsOnSupplierItemChange({ dispatch, supplierItem, formName, supplierItemFieldIdentifier, isCombo, orderMethod });
  };
}

export function editSupplierOrderProductGroupModalUpdateClicked(args: {
  formName: string,
  editFunction(): Promise<void>,
}): Thunker {
  return async (dispatch: Dispatch<any>) => {
    try {
      await args.editFunction();
    } catch (error) {
      // Error should have been handled by the global error handler, so just log it
      console.info('Problem saving new customer order product', error.message);
      return;
    }
    dispatch(reset(args.formName));
    dispatch({ type: SUPPLIER_ORDER_EDIT_SUPPLIER_ORDER_PRODUCT_MODAL_SAVE_BUTTON_CLICKED });
  };
}
