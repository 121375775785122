import { ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'cartEstimateReport';
export const reportType = ReportTypes.CartEstimateReport;

export enum FormFields {
  salesPlanYear = 'salesPlanYear',
  salesPlanIds = 'salesPlanIds',
}

export interface FormValues {
  [FormFields.salesPlanYear]: string;
  [FormFields.salesPlanIds]: SelectableValue | undefined;
}
