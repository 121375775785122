import * as React from 'react';
import { Col, Modal } from 'client/components/third-party';
import { SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME } from 'client/constants';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { SupplierOrdersJustTheTable } from './just-the-table';
import * as SupplierOrdersTableHoc from './supplier-orders-table-hoc';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

interface UIProps {
  customerOrderId: number;
  isShown: boolean;
  title: string;
  handleAddClicked(tableName: string): void;
  handleCloseClicked(tableName: string): void;
  confirmOkToSave(): Promise<boolean>;
  dataRequest: MsyncDataRequest;
}
const tableName = SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME;

export const AttachSupplierOrdersModalTable = SupplierOrdersTableHoc.makeComponent({ component: SupplierOrdersJustTheTable, isInModal: true, isRelatedToCustomerOrder: false });

export default class AttachSupplierOrdersModal extends React.Component<UIProps, {}> {
  constructor(props) {
    super(props);
  }

  addHandler = () => {
    this.props.handleAddClicked(tableName);
  }

  closeHandler = () => {
    this.props.handleCloseClicked(tableName);
  }

  render() {
    return (
      <Modal
        id={'attach-supplier-order-modal'}
        animation={false}
        backdrop="static"
        dialogClassName={'attach-supplier-order-modal'}
        show={this.props.isShown}
        onHide={this.closeHandler}>
        <GlobalAsyncModalHeader>{this.props.title}</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <AttachSupplierOrdersModalTable
              tableName={tableName}
              customerOrderId={this.props.customerOrderId}
              disableCreate
              isAttachSupplierOrdersModalShown={false}
              confirmOkToSave={this.props.confirmOkToSave}
              dataRequest={this.props.dataRequest}
              displayLoadingIndicator={true}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={this.closeHandler} />
          <GlobalSaveButton
            label={'Add'}
            testid={'modal-submit'}
            onClick={this.addHandler}
            disabled={false}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
