import * as React from 'react';
import * as _ from 'lodash';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { SpecializeField } from '../form';
import { optionsContainerGenerator } from '../../hoc/options-container-generator';
import { Bucket } from 'shared/schemas/bucket';

const Field = SpecializeField(Bucket, { alwaysShowErrors: true, hideOptionalLabel: true });

export interface OwnProps {
  handleSubmit: any;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  record?: {
    id?: number | undefined;
  };
}

interface WithOptionsProps {
  suppliers?: Array<{ id: number; label: string; }>;
  suppliersLoading?: boolean;
}

type ComponentProps = OwnProps & WithOptionsProps;

class BucketsForm extends React.Component<ComponentProps, undefined> {
  render() {
    const { suppliers, record } = this.props;
    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form" data-testid="bucket-id">
            <FormGroup>
              <Field
                name="identifier"
                labelColSize={2}
                inputColSize={5}
                autoFocus={!record || !record.id}
              />
            </FormGroup>
            <FormGroup>
              <Field name="description" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="supplierId" labelColSize={2} inputColSize={5} options={suppliers} loading={this.props.suppliersLoading} />
            </FormGroup>
            <FormGroup>
              <Field name="partNumber" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="productIdentifier" labelColSize={2} inputColSize={5} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }
}

const withSuppliers = optionsContainerGenerator({ table: 'suppliers', columns: ['identifier', 'name'] });

export default _.flowRight(
  withSuppliers,
)(BucketsForm);
