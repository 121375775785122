import { push } from 'connected-react-router';
import { submit } from 'redux-form';
import { formName } from '../sidebar';
import { SupplierCommitmentConfirmedStatus } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import * as SharedMutation from '../shared/mutation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

export const backButtonClicked = () => push(`/planning/supplier-commitments/list`);
export const saveButtonClicked = () => submit(formName);

export const confirmedStatusChanged = (supplierCommitmentId: number, status: SupplierCommitmentConfirmedStatus): Thunker => {
  return async dispatch => {
    const input: SharedMutation.UpdateSupplierCommitmentInput = {
      supplierCommitmentId,
      input: {
        confirmed: status === SupplierCommitmentConfirmedStatus.Confirmed ? true : false,
      },
    };
    const result = await msyncClientMutation<SharedMutation.UpdateSupplierCommitmentResponse>({
      mutation: SharedMutation.UpdateSupplierCommitmentMutation,
      variables: input,
      showBusyModal: true,
      dispatch,
    });
    return result.data.supplierCommitment.id;
  };
};
