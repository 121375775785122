import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
// import GeoLocation, { GeolocationOptions, GeolocationResponse } from '@react-native-community/geolocation';
import { delay } from './meta';
// import { Platform, PermissionsAndroid } from 'react-native';
import { log } from './logger';
import { Args } from 'shared/types';

/**
 * basically useMemo but with actual guarantee, not a wishy-washy "just a hint for optimization" half-gurantee.
 * Also very similar to useReducer, but without the dispatch idea, which isn't always a natural fit,
 * such as when deps come from hooks rather than state with imperative changes that could call dispatch.
 */
export const useDerivation = <Deps extends readonly unknown[], Derivation>(
  deps: Deps,
  derive: (prior?: Derivation) => Derivation,
  initialize?: () => Derivation,
) => {
  const [derivation, setDerivation] = useState(() => derive(initialize?.()));
  useEffect(() => { setDerivation(derive(derivation)); }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  return derivation;
};

/** A stable object reference whose contents are frozen as of first NON-UNDEFINED invocation.*/
export const useValue = <O extends unknown>(o: O) => useRef(o).current;

/** A stable object reference whose contents are frozen as of first NON-UNDEFINED invocation. Like useValue, but with a constructor function.*/
export const useOnce = <O extends unknown>(fn: () => O) => {
  const ref = useRef(undefined as O);
  if (ref.current === undefined)
    ref.current = fn();

  return ref.current;
};

/** A stable object reference whose contents are dynamic. */
export const useObject = <O extends {}>(o: O) => {
  const ref = useRef({} as O);
  Object.assign(ref.current, o);
  return ref.current;
};

/** Object reference is changed only when contents are changed (shallow compare). */
export const useUpdatableObject = <O extends {}>(o: O) => {
  const ref = useRef({} as O);
  if (ref.current !== o) {
    for (const k of _.unionWith(_.keys(ref.current), _.keys(o))) {
      if ((ref.current ?? {} as shame)[k] !== (o ?? {} as shame)[k]) {
        ref.current = o;
        break;
      }
    }
  }

  return ref.current;
};

/** A stable object reference whose contents are dynamic. */
export const useArray = <A extends unknown[]>(a: A) => {
  const ref = useRef([] as unknown as A);
  ref.current.splice(0, a.length);
  ref.current.push(...a);
  return ref.current;
};

/** WARNING: you must ensure your callbacks do not throw. */
export const useOnMount = <
  C extends 'allowCancellation',
  F extends C extends 'allowCancellation'
    ? (taskState?: {cancelled: boolean}) => unknown
    : (...args: unknown[]) => unknown
>
(fn: F, allowCancellation?: C, cleanup?: () => unknown) => useEffect(() => {
  const taskState = {cancelled: false};
  allowCancellation ? fn(taskState) : fn();
  return () => {
    taskState.cancelled = true;
    if (cleanup) cleanup();
  };
}, [/* run only on mount */]); // eslint-disable-line react-hooks/exhaustive-deps

export const usePromise = <F extends (taskState?: {cancelled: boolean}) => Promise<unknown>>
(fn: F, deps?: unknown[]) => useEffect(() => {
  const taskState = {cancelled: false};
  fn(taskState).catch(log.error);
  return () => { taskState.cancelled = true; };
}, deps); // eslint-disable-line react-hooks/exhaustive-deps

export const usePullToRefresh = (refetch: () => Promise<unknown>, onRefreshed?: () => unknown) => {
  const [refreshRequested, setRefreshRequested] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  usePromise(async () => {
    if (refreshRequested <= 0 || refreshing) return;
    setRefreshing(true);
    await Promise.all([delay(1_200), refetch()]);
    setRefreshRequested(0);
    setRefreshing(false);
    onRefreshed?.();
  }, [refreshRequested]);
  const now = Date.now();
  return {
    refreshing,
    onRefresh: () => { if (refreshRequested === 0) setRefreshRequested(now); },
  };
};

export const useComponentName = (component?: {name: string}) => {
  const backdoor = _.get(React, '__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.ReactDebugCurrentFrame.getStackAddendum') as any;
  const howDoesReactDoItAnyhow = _.isNil(backdoor) ? undefined : backdoor().split('\n').map((s: string) => s.trim())[1].replace(/^in /, '').replace(/( \(at .+)/, '            $1');
  return (component ? component.name : undefined) || howDoesReactDoItAnyhow;
};

/** @deprecated */
export function useLifeCycleLogging_AbandondedApproach(comment?: string) {
  const [comp, setComp] = useState<string>();
  const componentName = useComponentName();
  let component = comp;
  if (!component) {
    let callername: string | undefined;
    try {callername = useLifeCycleLogging?.caller?.name; } catch {/**/} // cuz this breaks on some funcs
    component = (callername || componentName || log.callstack(1)) + (comment ?? ''); // tslint-disable:line
    setComp(component);
  }

  useEffect(() => { log.mounting(component); return () => { log.unmounting(component); }; }, []); // eslint-disable-line react-hooks/exhaustive-deps
  log.rendering(component);
}

export function useLifeCycleLogging(component: string, comment?: string) {
  const tag = component + (comment ? `\n${comment}` : '');
  const [mounted, setMounted] = useState<'mounting' | 'mounted' | undefined>();
  useEffect(() => { setMounted('mounted'); log.mounted(tag); return () => { log.unmounting(tag); }; }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (!mounted) {
    log.mounting(tag);
    setMounted('mounting');
  } else if (mounted === 'mounted')
    log.rendering(tag);
}

export const useChangeLog = <LabeledArgs extends Dictionary<unknown>>(labeledArgs: LabeledArgs) => {
  useEffect(() => {
    for (const [k,v] of _(labeledArgs).toPairs().sortBy(x => x[0]).value())
      log.info(`${_.padStart(k, 16)} : ${v}`);
  }, _(labeledArgs).toPairs().sortBy(x => x[0]).map(x => x[1]).value()); // eslint-disable-line react-hooks/exhaustive-deps
};

/** wraps callback in a `requestAnimationFrame` and catches (and logs) any inner errors. -- crucial for UI event handlers to be non-blocking and non-crashing. */
export const handler = <H extends (...args: unknown[]) => void>(h: H) => (...args: Args<H>) => {
  const copiedArgs = args; // protect against problems with synthetic events
  requestAnimationFrame(() => {
    try {
      h(...copiedArgs);
    } catch (err) {
      log.error(err);
    }
  });
};

/** Combines `useCallback` with the `handler` wrapper defined above `useHandler`. */
export const useHandler = <H extends (...args: unknown[]) => void, Deps extends unknown[]>(h: H, deps: Deps) => useCallback(handler(h), deps); // eslint-disable-line react-hooks/exhaustive-deps

const permissionRequired = async () => {
  // if (Platform.OS === 'android') {
  //   log.M_SYNC('GPS: Android');
  //   while (true) {
  //     try {
  //       await delay(1500);
  //       return await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
  //     } catch (err) {
  //       if (!err.message.includes('time')) {
  //         log.warn(`something other than a timeout ocurred`, err);
  //         break;
  //       }
  //     }
  //   }

  //   return false;
  // }

  // log.M_SYNC('GPS: non-android');
  // GeoLocation.requestAuthorization();
  return true;
};

/** If this doesn't work for you, check Info.plist and gradle for manifest permissions. */
export const GPS = Object.freeze({
  permissionRequired,
  requestPermission: async () => {
    // if (await permissionRequired()) {
    //   return true;
    // }

    // try {
    //   if (PermissionsAndroid.RESULTS.GRANTED === await PermissionsAndroid.request(
    //     PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
    //     {
    //       title: 'Nearest Store',
    //       message: 'Allow m-sync to locate your nearest store with GPS?',
    //       buttonNeutral: 'Ask Me Later',
    //       buttonNegative: 'Cancel',
    //       buttonPositive: 'OK',
    //     })) {
    //     log.M_SYNC('GPS granted');
    //     return true;
    //   }

    //   log.M_SYNC('GPS denied');
    // } catch (err) {
    //   log.warn(err);
    // }

    return false;
  },
  // getCurrentPosition: (o: GeolocationOptions = {enableHighAccuracy: true, maximumAge: 600_000 /*10 minutes*/}) =>
  //   new Promise((y: (p: GeolocationResponse) => void, n) => GeoLocation.getCurrentPosition(y, n, o)),
});

export type PropsOf<Component>
  = Component extends React.Component<infer Props, unknown> ? Props
  : Component extends (p: infer P) => JSX.Element ? P
  : never;

export type StatePropsOf<Component> = Component extends React.Component<unknown, infer Props> ? Props : never;
