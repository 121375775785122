import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { toInt, roundToInt, DateStr, toDateStr } from 'shared/types';
import { notNil } from 'shared/helpers/andys-little-helpers';
import { parseDateTime } from 'shared/helpers/date-helpers';

export interface WorkSheetRow { [columnIndex: string]: string | number | undefined }
export type WorkSheetData = WorkSheetRow[];
export interface WorkBookContainer {
  fileName: string;
  data: Buffer | string;
  type: 'binary' | 'buffer';
  getWorkbook: () => Promise<XLSX.WorkBook>;
}

export const getWorkBookFromWorkBookContainer = async <T extends XLSX.WorkBook>(workBookContainer: WorkBookContainer): Promise<T | null> =>
  XLSX.read(workBookContainer.data, { type: workBookContainer.type, raw: true }) as T;

export const getWorkBookContainerFromBinaryString = (fileName: string, data: string): WorkBookContainer => ({
  fileName,
  data,
  type: 'binary',
  getWorkbook: async () => XLSX.read(data, { type: 'binary', raw: true }),
});

export const getWorkBookContainerFromFile = async (fileName: string, readFile: (path: string) => Promise<Buffer>): Promise<WorkBookContainer> => ({
  fileName,
  data: await readFile(fileName),
  type: 'buffer',
  getWorkbook: async () => XLSX.read(await readFile(fileName), { type: 'buffer', raw: true }),
});

export function getFirstWorkSheet<T>(workBook: XLSX.WorkBook) {
  const sheetNames = Object.keys(workBook.Sheets);
  if (sheetNames.length === 0)
    return null;

  const sheetName = sheetNames[0];
  return workBook.Sheets[sheetName] as T;
}

export function getWorkSheet<T>(workBook: XLSX.WorkBook, sheetName: string) {
  const sheetNames = Object.keys(workBook.Sheets);
  if (!sheetNames.includes(sheetName))
    return null;

  return workBook.Sheets[sheetName] as T;
}

export function getWorkSheetData<T extends any[]>(workSheet: XLSX.WorkSheet) {
  const data = XLSX.utils.sheet_to_json(workSheet, {
    header: 'A',
    blankrows: true,
  });

  return data as T;
}

export const findRowIndex = (rows: WorkSheetData, label: RegExp | undefined) =>
  label ? rows.findIndex(row => label.test(`${row.A}`)) : -1;

export function findLastColumn(rows: WorkSheetData, rowIndex: number = 0) {
  if (rows.length < rowIndex)
    return null;

  const selectedRow = rows[rowIndex];
  const keys = Object.keys(selectedRow);
  if (keys.length === 0)
    return null;

  return keys[keys.length - 1];
}

export const getAsString = (value: string | number | undefined): string | undefined =>
    typeof value === 'number' ? `${ value}` .trim()
  : typeof value === 'string' ?     value   .trim()
  :                                 value;

export const getAsDateStr = (value: string | number | undefined): DateStr | undefined =>
  typeof value === 'string' ? toDateStr(parseDateTime(value.trim())) : undefined;

export function getAsInteger(value: string | number | undefined): Int | undefined {
  if (typeof value === 'string') {
    const parsed = toInt(value.replace(/,/g, '')); // remove thousands-separators
    return Number.isNaN(parsed) ? undefined : parsed;
  }

  if (notNil(value))
    return roundToInt(value);

  return value;
}

export function getAsNumber(value: string | number | undefined): number | undefined {
  if (typeof value === 'string') {
    const parsed = toInt(value.replace(/,/g, '')); // remove thousands-separators
    return Number.isNaN(parsed) ? undefined : parsed;
  }

  return value;
}

export function getAsDecimal(value: string | number | undefined): number | undefined {
  if (typeof value === 'string') {
    const parsed = Number.parseFloat(value.replace(/[^\d.-]/g, '')); // strip out all non-numeric and non dot (.) characters
    return Number.isNaN(parsed) ? undefined : parsed;
  }

  return value;
}
