import * as React from 'react';
import { LabeledRadio } from 'client/components/form';
import { isNil } from 'lodash';

export interface SimpleRadioProps {
  label: string;
  testid: string;
  value: string | undefined;
  optionValue: string;
  onChange: (value: shame) => void;
  labelColSize?: number;
  inputColSize?: number;
  offset?: number;
  className?: string;
  disabled?: boolean;
}

export const SimpleRadio = (props: SimpleRadioProps) => {
  const meta = { touched: false, error: '', warning: '', submitting: false };
  const input = {
    name: props.label,
    value: props.value,
    // There are few consequences for generating a new onChange function here if any props change,
    // Because this is such a low level component, it won't cause a re-render chain reaction.
    onChange: event => props.onChange(props.optionValue),
  };

  return <LabeledRadio
    labelColSize={isNil(props.labelColSize) ? 2 : props.labelColSize}
    inputColSize={isNil(props.inputColSize) ? 2 : props.inputColSize}
    horizontalLabel={false}
    label={props.label}
    testid={props.testid}
    input={input}
    meta={meta}
    offset={props.offset || 0}
    mfcClassName={props.className}
    optionValue={props.optionValue}
    handleChange={input.onChange}
    disabled={props.disabled}
    alwaysShowErrors={false}
  />;
};
