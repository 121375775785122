import { FooterCell } from './footer-cell';
import { VariableSizeGrid as Grid } from 'react-window';
import * as React from 'react';
import * as Types from './types';
import * as Selectors from './selectors';

export const TableFooter = React.memo((props: Types.FooterProps) => {
  const freeColumns = props.columns;

  const itemData = React.useMemo(() => {
    return {
      columns: freeColumns,
      values: Selectors.getCellValues(props.rows, freeColumns),
      flags: Selectors.getBodyCellFlags(props.rows, freeColumns),
      footerFlags: Selectors.getFooterCellFlags(props.rows, freeColumns),
      footerData: freeColumns.map(c => c.footer),
      tableName: props.tableName,
      headers: freeColumns.map(c => c.header),
    };
  }, [props.rows, freeColumns, props.tableName]);

  const rowHeightFunc = React.useMemo(() => () => props.rowHeight, [props.rowHeight]);

  return (
    <Grid
      ref={props.footerRef}
      className="readonly-table-footer"
      style={{ overflow: 'hidden' }}
      rowCount={1}
      columnCount={Selectors.getColumnCount(props)}
      columnWidth={Selectors.getColumnWidthFunction(props)}
      height={Selectors.getRowHeight(props)}
      rowHeight={rowHeightFunc}
      width={Selectors.getFooterWidth(props)}
      itemData={itemData}
    >
      {FooterCell}
    </Grid>
  );
});
