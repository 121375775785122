import * as React from 'react';
import { StatusPill } from '../status-pill';
import { TYPES } from 'shared/types';

export function StatusCell({value, type}: {value?: boolean | string | null, type: TYPES}) {
  return (
    <div className="status-cell">
      <StatusPill status={value} type={type}/>
    </div>
  );
}
