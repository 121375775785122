import * as _ from 'lodash';
import { FieldValidator } from 'shared/validators/types';
import { RackShippingUnit } from 'shared/types';
import { SupplierItem } from 'shared/schemas/supplier-item';

export const SUPPLIER_ITEM_REQUIRED_IF_SHIP_BY_RACK: FieldValidator = {
  isValid(value: any, record: Partial<SupplierItem>) {
    return record.primaryShippingUnitType !== RackShippingUnit || !_.isNil(value);
  },

  shortMessage(value: any, record: any) {
    return 'Required';
  },

  message(label: string, value: any, record: any) {
    return `${label} is required when the primary shipping unit is Rack.`;
  },
};
