import * as Excel from 'shared/file-parsers/excel/common';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as ModalState from './state';
import * as Mutation from './mutation';
import * as Parser from 'shared/file-parsers/excel/distribution-rack-shipping-spreadsheet-parser';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import * as SharedHelpers from 'shared/helpers';
import * as State from 'client/state/state';
import { ErrorWithData, isErrorWithData } from 'shared/errors/error-with-data';

export type ActionTypes =
  OpenModalButtonClickedAction |
  CancelButtonClickedAction |
  WorksheetParsingStartedAction |
  WorksheetParsingCompletedAction |
  WorksheetImporting |
  WorksheetImportedAction |
  WorksheetFailedAction;

export enum ActionTypeKeys {
  OpenModalButtonClicked = 'ImportDistributionRackShippingWorksheet/OPEN_MODAL_BUTTON_CLICKED',
  CancelButtonClicked = 'ImportDistributionRackShippingWorksheet/CANCEL_BUTTON_CLICKED',
  WorksheetParsingStarted = 'ImportDistributionRackShippingWorksheet/WORKSHEET_PARSING_STARTED',
  WorksheetParsingCompleted = 'ImportDistributionRackShippingWorksheet/WORKSHEET_PARSING_COMPLETED',
  WorksheetImporting = 'ImportDistributionRackShippingWorksheet/WORKSHEET_IMPORTING',
  WorksheetImported = 'ImportDistributionRackShippingWorksheet/WORKSHEET_IMPORTED',
  WorksheetFailed = 'ImportDistributionRackShippingWorksheet/WORKSHEET_FAILED',
}

export interface CancelButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.CancelButtonClicked;
  payload: {};
}

export function cancelButtonClicked(): CancelButtonClickedAction {
  return {
    type: ActionTypeKeys.CancelButtonClicked,
    payload: {},
  };
}

export interface OpenModalButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.OpenModalButtonClicked;
  payload: {
    storeIdentifiersInRoutePlan: string[];
  };
}

export function openModalButtonClicked(storeIdentifiersInRoutePlan: string[]): OpenModalButtonClickedAction {
  return {
    type: ActionTypeKeys.OpenModalButtonClicked,
    payload: {
      storeIdentifiersInRoutePlan,
    },
  };
}

export function worksheetSelected(fileName: string, fileContents: string, routePlanId: number): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>, getState: () => State.Type) => {
    dispatch(worksheetParsingStarted());

    const storeIdentifiersInRoutePlan = getState().importDistributionRackShippingWorksheet.storeIdentifiersInRoutePlan;

    await SharedHelpers.timeout(100); // Get the spinner up and displayed

    try {
      const workBookContainer = await Excel.getWorkBookContainerFromBinaryString(fileName, fileContents);
      const distributionRackShippingParseResult = await Parser.getDistributionRackShippingLineItemsFromWorkBookContainer(workBookContainer, storeIdentifiersInRoutePlan);

      if (distributionRackShippingParseResult.success) {
        const importableDistributionRackShippingLineItems = distributionRackShippingParseResult.parsed;

        dispatch(worksheetParsingCompleted(importableDistributionRackShippingLineItems));
      } else {
        throw new ErrorWithData(distributionRackShippingParseResult.reason);
      }
    } catch (error) {
      let messages: string[] = [];
      if (isErrorWithData(error)) {
        const errorWithData = error as ErrorWithData;
        messages = errorWithData.data<string[]>();
      } else {
        messages = [error.message];
      }

      dispatch(worksheetFailed(ModalState.ImportDistributionRackShippingWorksheetImportStep.Validation, messages));
    }
  };
}

const importWorksheet = async (dispatch: Redux.Dispatch<any>, state: State.Type, routePlanId: number): Promise<void> => {
  const worksheet = state.importDistributionRackShippingWorksheet.worksheet;
  if (!worksheet) {
    throw new Error('importableDistributionRackShippingSpreadsheet not specified');
  }

  const resultName = 'importDistributionRackShippingWorksheet';

  const result = await GraphQLMutation.msyncClientMutation<{ importCustomerOrder: Mutation.ImportDistributionRackShippingWorksheetResponse }, Mutation.ImportDistributionRackShippingWorksheetInput>({
    mutation: Mutation.ImportDistributionRackShippingWorksheetMutation(resultName),
    variables: {
      overrides: worksheet.lineItems,
      routePlanId,
    },
    suppressErrorModal: true,
    dispatch,
  });

  return result.data[resultName];
};

export function importButtonClicked(routePlanId: number): ReduxTypes.Thunker {
  return async (dispatch: Redux.Dispatch<any>, getState: () => State.Type) => {
    try {
      dispatch(worksheetImporting());

      await SharedHelpers.timeout(100); // Get the spinner up and displayed

      await importWorksheet(dispatch, getState(), routePlanId);
      dispatch(worksheetImported());
    } catch (exception) {
      const errors = getErrorsFromGraphQL(exception);

      dispatch(worksheetFailed(ModalState.ImportDistributionRackShippingWorksheetImportStep.Import, errors));
    }
  };
}

// Exceptions with something more complex than a simple text string message seem to get lost when
// passing through GraphQL. We'll expect them to actually be JSON strings and we'll parse them
// out here.
const getErrorsFromGraphQL = (error: { graphQLErrors?: Array<{ message: string }> }) => {
  return JSON.parse(error && error.graphQLErrors && error.graphQLErrors.length === 1 ? error.graphQLErrors[0].message : '[]');
};

// --------------------------------------------------------------------------------

export interface WorksheetParsingStartedAction extends Redux.Action {
  type: ActionTypeKeys.WorksheetParsingStarted;
  payload: {
    distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Parsing;
  };
}

export function worksheetParsingStarted(): WorksheetParsingStartedAction {
  return {
    type: ActionTypeKeys.WorksheetParsingStarted,
    payload: {
      distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Parsing,
    },
  };
}

export interface WorksheetParsingCompletedAction extends Redux.Action {
  type: ActionTypeKeys.WorksheetParsingCompleted;
  payload: {
    distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Parsed;
    importableDistributionRackShippingWorksheet: Parser.ImportableDistributionRackShippingWorkSheet;
  };
}

export function worksheetParsingCompleted(importableDistributionRackShippingWorksheet: Parser.ImportableDistributionRackShippingWorkSheet): WorksheetParsingCompletedAction {
  return {
    type: ActionTypeKeys.WorksheetParsingCompleted,
    payload: {
      distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Parsed,
      importableDistributionRackShippingWorksheet,
    },
  };
}

export interface WorksheetImporting extends Redux.Action {
  type: ActionTypeKeys.WorksheetImporting;
  payload: {
    distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Importing;
  };
}

export function worksheetImporting(): WorksheetImporting {
  return {
    type: ActionTypeKeys.WorksheetImporting,
    payload: {
      distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Importing,
    },
  };
}

export interface WorksheetImportedAction extends Redux.Action {
  type: ActionTypeKeys.WorksheetImported;
  payload: {
    distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Imported;
  };
}

export function worksheetImported(): WorksheetImportedAction {
  return {
    type: ActionTypeKeys.WorksheetImported,
    payload: {
      distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Imported,
    },
  };
}

export interface WorksheetFailedAction extends Redux.Action {
  type: ActionTypeKeys.WorksheetFailed;
  payload: {
    distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Failed;
    failedStep: ModalState.ImportDistributionRackShippingWorksheetImportStep;
    errorMessages: string[];
  };
}

export function worksheetFailed(failedStep: ModalState.ImportDistributionRackShippingWorksheetImportStep, errorMessages: string[]): WorksheetFailedAction {
  return {
    type: ActionTypeKeys.WorksheetFailed,
    payload: {
      distributionRackShippingSpreadsheetImportState: ModalState.ImportDistributionRackShippingWorksheetImportState.Failed,
      failedStep,
      errorMessages,
    },
  };
}
