import { SaveConfirmationArgs } from 'client/hoc/with-save-confirmation';

export const saveConfirmationOptionsForUpcCodes: SaveConfirmationArgs<any> = {
  onlyAskOnce: false,
  shouldDisplay: (record?: never) => true,
  message: {
    title: 'Retrieve New UPC',
    body: `Are you sure you would like to retrieve a new UPC for this product?`,
  },
  saveConfirmMessage: 'Retrieve New UPC',
};

export type ConfirmOkToSaveFunction = () => Promise<boolean>;
