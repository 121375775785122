import * as _ from 'lodash';
import * as Actions from './actions';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as OrderQueries from 'client/app/orders/reports/shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as ReactRedux from 'react-redux';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportUserParams from 'client/components/report-user-params';
import * as Selectors from './selectors';
import * as SharedOrderActions from 'client/app/orders/reports/shared/actions';
import * as State from 'client/state/state';
import * as UI from './ui';
import * as UserParamsMappers from './user-params-mappers';
import * as WrappedContainer from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { SelectableValue } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerId: number | undefined;
  downloadButtonDisabled: boolean;
  lastYearDateComparisonDisabled: boolean;
  marketIds: SelectableValue | undefined;
  mfcAreaIds: SelectableValue | undefined;
  poBased: boolean;
  productClassIds: SelectableValue | undefined;
  productIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  regionIds: SelectableValue | undefined;
  reportDownloadStatus: Mutations.MutationStatus;
  scanBased: boolean;
  sellDepartmentId: number | undefined;
  storeIds: SelectableValue | undefined;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
  scanProductOptions: boolean[] | undefined;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (!formValues) {
    return {
      customerId: undefined,
      downloadButtonDisabled: true,
      lastYearDateComparisonDisabled: false,
      marketIds: undefined,
      mfcAreaIds: undefined,
      poBased: false,
      productClassIds: undefined,
      productIds: undefined,
      productSubClassIds: undefined,
      regionIds: undefined,
      reportDownloadStatus: Mutations.MutationStatus.Initial,
      scanBased: false,
      sellDepartmentId: undefined,
      storeIds: undefined,
      primaryGlobalAll: undefined,
      scanProductOptions: undefined,
    };
  }

  const scanProductOptions = Selectors.getScanProductOptions(state);
  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);

  return {
    customerId: formValues[Constants.FormFields.customerId],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    lastYearDateComparisonDisabled: false,
    marketIds: formValues[Constants.FormFields.marketIds],
    mfcAreaIds: formValues[Constants.FormFields.mfcAreaIds],
    poBased: formValues[Constants.FormFields.poBased],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productIds: formValues[Constants.FormFields.productIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    regionIds: formValues[Constants.FormFields.regionIds],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    scanBased: formValues[Constants.FormFields.scanBased],
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    storeIds: formValues[Constants.FormFields.storeIds],
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
    scanProductOptions,
  };
};

interface DispatchProps {
  handleCustomerChanged: (customerId: number) => void;
  handleDownloadExcelReportClicked(): ReduxTypes.Thunker;
  handleMarketsChanged: (ids: number[]) => ReduxTypes.Thunker;
  handleMeasureCheckboxClicked: (categoryName: string, measure: string, isOn: boolean) => void;
  handleMfcAreasChanged: (ids: number[]) => ReduxTypes.Thunker;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (productClassIds: number[]) => ReduxTypes.Thunker;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => ReduxTypes.Thunker;
  handleRegionsChanged: (ids: number[]) => ReduxTypes.Thunker;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => ReduxTypes.Thunker;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  handleCustomerChanged: SharedOrderActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleMarketsChanged: SharedOrderActions.handleMarketsChanged(Constants.formName, Constants.FormFields),
  handleMeasureCheckboxClicked: Actions.handleMeasureCheckboxClicked,
  handleMfcAreasChanged: SharedOrderActions.handleMfcAreasChanged(Constants.formName, Constants.FormFields),
  handlePOBasedFilterChanged: SharedOrderActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedOrderActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedOrderActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleRegionsChanged: SharedOrderActions.handleRegionsChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedOrderActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleSellDepartmentChanged: SharedOrderActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  OrderQueries.MfcAreasQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  SharedQueries.RegionQueryProps &
  SharedQueries.MarketQueryProps &
  SharedQueries.StoreQueryProps &
  ReduxFormProps &
  ReportUserParams.UserParamsMapperProps &
  DispatchProps;

assertCompatible<UI.ComponentProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.primaryGlobalAll]: PrimaryGlobalAll.All,
    },
  }),
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  OrderQueries.withMfcAreas,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({onlyIncludeParentReplenishmentProducts: true}),
  SharedQueries.withRegions,
  SharedQueries.withMarkets,
  SharedQueries.withStores,
  UserParamsMappers.withBuyerReportUserParamMappers,
)(UI.BuyerReportUI) as Component<OwnProps>;

export const BuyerReport = (props: OwnProps) => WrappedContainer.propToComponent(component, props);
