// 1-based index to align with Excel
export const CUSTOMER_IDENTIFIER_ROW = 2;
export const CUSTOMER_IDENTIFIER_COL = 'A';

export const PRODUCTION_YEAR_ROW = 2;
export const PRODUCTION_YEAR_COL = 'B';

export const PRODUCTION_SEASON_ROW = 3;
export const PRODUCTION_SEASON_COL = 'A';

export const PRODUCT_IDENTIFIER_ROW = 1;
export const PRODUCT_IDENTIFIER_COL = 'B';

export const PRODUCT_DESCRIPTION_ROW = 1;
export const PRODUCT_DESCRIPTION_COL = 'A';

export const WEEK_NUMBER_ROW = 2;

export const VARIETY_NAME_STARTING_ROW = 5;
export const VARIETY_NAME_COLUMN = 'A';

export const VARIETY_MIX_COLUMN = 'B';

export const TOTALS_ROW = 1;
