import * as React from 'react';
import { Button, Modal, Form } from 'client/components/third-party';
import { MutationStatus } from 'client/actions/mutations';
import { AsyncButton } from 'client/components/async-button/async-button-container';

interface OwnProps {
  isShown: boolean;
  cancelDisabled?: boolean;
  onCancelButtonClicked(): void;
  onModalEnter?(): void;
  handleFormSubmit: shame;

  invalid: boolean;

  submitButtonLabel: string;
  submitButtonTestId: string;
  modalTitle: string;
  modalId: string;

  actionStatus: MutationStatus;

  className?: string;
}

export class FormModal extends React.PureComponent<OwnProps, any> {
  onModalEnter = () => {
    if (this.props.onModalEnter) {
      this.props.onModalEnter();
    }
  }

  render() {
    const props = this.props;
    return (
      <Modal
        id={props.modalId}
        dialogClassName={props.className}
        animation
        backdrop="static"
        show={props.isShown}
        onEnter={this.onModalEnter}
        onHide={props.onCancelButtonClicked}>
        <Form horizontal onSubmit={this.props.handleFormSubmit()}>
          <Modal.Header closeButton={!this.props.cancelDisabled}>
            <Modal.Title>{props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.children}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsClass="mfc-button mfc-clear-button"
              data-testid="cancel-button"
              disabled={this.props.cancelDisabled}
              onClick={this.props.onCancelButtonClicked}>
              Cancel
            </Button>
            <AsyncButton
              label={props.submitButtonLabel}
              disabled={props.invalid}
              type={'submit'}
              testid={props.submitButtonTestId}
              actionStatus={props.actionStatus}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
