import { Lens } from '@atomic-object/lenses';
import { MutationStatus } from 'client/actions/mutations';
import { BolId } from 'shared/schemas/bol';

interface ReceivingWorksheetState {
  readonly addBolModalShown: boolean;
  readonly editBolModalShown: boolean;
  readonly removeBolModalShown: boolean;
  readonly selectedBolId?: BolId;
  readonly bolToEditId: BolId | null;
  readonly bolToRemoveId: BolId | null;
  readonly addBolMutationStatus: MutationStatus;
  readonly editBolMutationStatus: MutationStatus;
  readonly removeBolMutationStatus: MutationStatus;
}

export const InitialState: ReceivingWorksheetState = {
  addBolModalShown: false,
  editBolModalShown: false,
  removeBolModalShown: false,
  bolToEditId: null,
  bolToRemoveId: null,
  addBolMutationStatus: MutationStatus.Initial,
  editBolMutationStatus: MutationStatus.Initial,
  removeBolMutationStatus: MutationStatus.Initial,
};

export type Type = ReceivingWorksheetState;

export const addBolModalShown = Lens.from<Type>().prop('addBolModalShown');
export const editBolModalShown = Lens.from<Type>().prop('editBolModalShown');
export const removeBolModalShown = Lens.from<Type>().prop('removeBolModalShown');
export const selectedBolId = Lens.from<Type>().prop('selectedBolId');
export const bolToEditId = Lens.from<Type>().prop('bolToEditId');
export const bolToRemoveId = Lens.from<Type>().prop('bolToRemoveId');
export const addBolMutationStatus = Lens.from<Type>().prop('addBolMutationStatus');
export const editBolMutationStatus = Lens.from<Type>().prop('editBolMutationStatus');
export const removeBolMutationStatus = Lens.from<Type>().prop('removeBolMutationStatus');
