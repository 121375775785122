import { tableName, property, hasMany, belongsTo, definePresentation, setSchemaOptions, manyToMany, required } from './dsl';
import { Customer, CustomerId } from './customer';
import { SellDepartment, SellDepartmentId } from './sell-department';
import { Store } from './store';
import { SORT_TYPES, DISPLAY_TYPES, ActiveInactive } from '../types';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { CustomerDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type MfcAreaId = Flavor<number, 'mfcAreaId'>;
export type MfcAreaIdentifier = Flavor<string, 'mfcAreaIdentifier'>;
// NOTE: we are only doing this for compatibility with vbills.
// Once vbills is gone, this nonsense can go away
/** Used to map 3-letter market codes to Grower-friendly MFC Area descriptions, to be printed by VBills on supplier orders */
export const MfcAreaLongTextMap = {
  MIC: 'Michigan',  // MMM
  ILL: 'Illinois',  // MMM
  OHI: 'Ohio',      // MMM
  CHI: 'Chicago',   // HHH
  MSP: 'Minneapolis - St. Paul', // HHH
  STL: 'St. Louis', // HHH
  WIS: 'Wisconsin', // HHH
  GLO: 'Global',    // all
};

@tableName('mfcAreas')
export class MfcArea implements IRecord {
  id?: MfcAreaId;

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required customer: Customer;
  customerId: CustomerId;

  @belongsTo('sellDepartments')
  @property @required sellDepartment: SellDepartment;
  sellDepartmentId: SellDepartmentId;

  @hasMany('customerOrders')
  @property customerOrders: CustomerOrder[];

  @manyToMany('stores', { through: 'storesMfcAreas', foreignDisplayKey: 'identifier' })
  @property stores?: Store[];

  @property @required identifier: MfcAreaIdentifier;
  @property @required activeStatus: ActiveInactive;
}

definePresentation(MfcArea, {
  customerOrders: { tableDisplay: false, formDisplay: false, includeInFormQuery: false },
  stores        : { tableDisplay: false, formDisplay: false, includeInFormQuery: false },
  customer: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 20,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
  },
  sellDepartment: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 20,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'MFC Area',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 55,
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
});

setSchemaOptions(MfcArea, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'customer', foreignColumn: 'name' },
});
