import * as React from 'react';
import { StatsCardBasic } from 'client/components/cards';
import { formatInteger } from 'shared/types';
import * as _ from 'lodash';

export function StopsCard(props: { stops?: number }) {
  return (
    <StatsCardBasic title="Stops">{_.isNil(props.stops) ? '-' : formatInteger(props.stops)}</StatsCardBasic>
  );
}
