import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

class SupplierOrderDetailPage extends React.Component<OwnProps, {}> {
  public render() {
    return <div>{this.props.children}</div>;
  }
}

export default SupplierOrderDetailPage;
