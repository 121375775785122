import { tableName, belongsTo, property, definePresentation, setSchemaOptions } from './dsl';
import { SupplierLocation } from 'shared/schemas/supplier-location';
import { Product } from 'shared/schemas/product';
import { IRecord } from 'shared/schemas/record';

@tableName('productsSupplierLocations')
export class ProductsSupplierLocation implements IRecord {
  id?: number;
  @property @belongsTo(         'products')          product: Product;                   productId: number;
  @property @belongsTo('supplierLocations') supplierLocation: SupplierLocation; supplierLocationId: number;
}

definePresentation(ProductsSupplierLocation, {
  product: { formDisplay: false },
  supplierLocation: { formDisplay: false },
});

setSchemaOptions(ProductsSupplierLocation, {
  hasTimestamps: false,
  joins: ['supplierLocation', 'product'],
});
