import * as React from 'react';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { FileUploadStatus } from 'client/state/import-route';

export interface ComponentProps {
  handleFileSelected(fileName: string, fileContents: string): void;
  uploadState: FileUploadStatus;
  errorMessages: string[];
}

export class ImportRouteModalContentStepTwoUI extends React.PureComponent<ComponentProps, {}> {
  onFileInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const files = (event.target as any).files;
    if (files.length !== 1) {
      return;
    }
    const file: File = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) {
        throw new Error('Unable to read file!');
      }
      if (typeof reader.result !== 'string') {
        throw new Error('Unexpected file contents (not a string)');
      }
      this.props.handleFileSelected(file.name, reader.result);
    };
    reader.readAsText(file);
  }

  render() {
    const ChooseFileButton = (props: { label: string }) => (
      <div className="text-center import-route-modal-upload-initial" data-testid="import-route-modal-step-two">
        <label className="import-route-modal-file-picker align-bottom btn btn-default btn-file mfc-button mfc-submit-button mfc-button-primary">
          {props.label}
          <input
            type="file"
            id="import-route-modal-file-picker-input-element"
            className="import-route-modal-file-picker-input-element"
            data-testid="file-picker"
            onChange={this.onFileInputChange}
            style={{ display: 'none' }}
            accept=".upl" />
        </label>
      </div>
    );
    const UploadSucceededMessage = () => (
      <div className="text-center import-route-modal-upload-complete">
        <span className="fa fa-check fa-5x import-route-modal-upload-succeeded-status-icon" />
        <div className="import-route-modal-upload-status-text">Complete!</div>
      </div>
    );
    const UploadInProgressMessage = () => (
      <div className="import-route-modal-spinner-container">
        <ProgressSpinner label={'Processing...'} />
      </div>
    );
    const UploadFailedMessage = () => (
      <div>
        <div className="text-center import-route-modal-upload-complete">
          <span className="fa fa-times fa-5x import-route-modal-upload-failed-status-icon" />
          <div className="import-route-modal-upload-status-text">Failed</div>
        </div>
        <div data-testid="upl-upload-error-message-box" className="alert alert-danger">
          <strong>There was a problem with the UPL file.</strong>
          <ul data-testid="upl-upload-error-list">
            {this.props.errorMessages.map((message, index) => {
            return (
              <li key={`import-route-message-${index}`}>{message}</li>
            );
            })}
          </ul>
        </div>
        <ChooseFileButton label={'Choose Another File'}/>
      </div>
    );
    const UploadInitialStateMessage = () => (
      <ChooseFileButton label={'Choose File'}/>
    );

    switch (this.props.uploadState) {
      case FileUploadStatus.Initial:
        return <UploadInitialStateMessage />;
      case FileUploadStatus.InProgress:
        return <UploadInProgressMessage />;
      case FileUploadStatus.Succeed:
        return <UploadSucceededMessage />;
      case FileUploadStatus.Failed:
        return <UploadFailedMessage />;
    }
  }
}
