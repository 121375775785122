import gql from 'graphql-tag';
import { SupplierCommitment, SupplierCommitmentProduct, SupplierCommitmentAllocation } from 'schema/supplier-commitment/types';
import { extractQueryName } from 'client/utils/query-utils';

export interface GetSupplierCommitmentWeeklyProductVolumeQueryInput {
  supplierCommitmentId: number;
}
interface SupplierItem {
  id: number;
  product: {
    id: number;
    identifier: string;
    description: string;
  };
}
type SupplierCommitmentAllocationResponse = Pick<SupplierCommitmentAllocation, 'id' | 'weekNumber' | 'pieceQuantity'>;
type SupplierCommitmentProductResponse = Pick<SupplierCommitmentProduct, 'id' | 'cost' | 'totalPieceQuantity'> & { supplierItem: SupplierItem, supplierCommitmentAllocations: SupplierCommitmentAllocationResponse[] };
export interface GetSupplierCommitmentWeeklyProductVolumeQueryResponse {
  supplierCommitment: Pick<SupplierCommitment, 'id' | 'startWeek' | 'endWeek'> & { supplierCommitmentProducts: SupplierCommitmentProductResponse[] };
}

export const GetSupplierCommitmentDetailTableQuery = gql`
  query GetSupplierCommitmentDetailTable($supplierCommitmentId: Int!) {
    supplierCommitment: GetSupplierCommitment(id: $supplierCommitmentId) {
      id
      startWeek
      endWeek
      supplierCommitmentProducts {
        id
        supplierItem {
          id
          product {
            id
            identifier
            description
          }
        }
        cost
        totalPieceQuantity
        supplierCommitmentAllocations {
          id
          weekNumber
          pieceQuantity
        }
      }
    }
  }
`;

export const GetSupplierCommitmentDetailTableQueryName = extractQueryName(GetSupplierCommitmentDetailTableQuery);
