import * as React from 'react';
import { FormGroup, Row } from 'client/components/third-party';
import { FieldWrapper } from '../../form';

const table = 'supplierItemCosts';
/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper horizontalLabel table={table} alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

/*
SupplierItemNewCostFormGroup.propTypes = {
  formName: React.PropTypes.string.isRequired,
};
*/
class SupplierItemNewCostFormGroup extends React.Component<shame> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row data-testid="product-price">
        <FormGroup>
          <Field name="beginDate" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="cost" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="changeReason" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="notes" labelColSize={3} inputColSize={9} />
        </FormGroup>
        <FormGroup>
          <Field name="supplierScanRisk" labelColSize={3} inputColSize={9} />
        </FormGroup>
      </Row>);
  }
}

export default SupplierItemNewCostFormGroup;
