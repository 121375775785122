import gql from 'graphql-tag';

export const ValidateImportSalesPlanQuery = gql`
  query ValidateImportSalesPlanQuery($input: ImportSalesPlanInput!) {
    validateImportableSalesPlan: ValidateImportableSalesPlan(input: $input) {
      success
      errors
    }
  }
`;
