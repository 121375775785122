import { Lens } from '@atomic-object/lenses';

import * as ReplenishmentByProductConstants from 'client/app/orders/reports/replenishment-by-product/constants';
import * as ReplenishmentByStoreConstants from 'client/app/orders/reports/replenishment-by-store/constants';
import * as StoreOnHandConstants from 'client/app/orders/reports/store-on-hand/constants';
import { MutationStatus } from 'client/actions/mutations';
import { getFormValues } from 'redux-form';
import { notNil } from 'shared/helpers/andys-little-helpers';

namespace ReplenishmentByProduct {
  interface ReplenishmentByProductState {
    readonly filterByCategoryOrSalesPlan: ReplenishmentByProductConstants.FilterByCategoryOrSalesPlan;
  }

  export const ReplenishmentByProductInitialState: ReplenishmentByProductState = {
    filterByCategoryOrSalesPlan: ReplenishmentByProductConstants.FilterByCategoryOrSalesPlan.ByCategory,
  };

  export type ReplenishmentByProductType = ReplenishmentByProductState;
}

namespace ReplenishmentByStore {
  interface ReplenishmentByStoreState {
    readonly filterByCategoryOrSalesPlan: ReplenishmentByStoreConstants.FilterByCategoryOrSalesPlan;
  }

  export const ReplenishmentByStoreInitialState: ReplenishmentByStoreState = {
    filterByCategoryOrSalesPlan: ReplenishmentByStoreConstants.FilterByCategoryOrSalesPlan.ByCategory,
  };

  export type ReplenishmentByStoreType = ReplenishmentByStoreState;
  export const filterByCategoryOrSalesPlanLens = Lens.from<ReplenishmentByStoreType>().prop('filterByCategoryOrSalesPlan');
}

namespace StoreOnHand {
  interface StoreOnHandState {
    readonly filterByCategoryOrSalesPlan: StoreOnHandConstants.FilterByCategoryOrSalesPlan;
  }

  export const StoreOnHandInitialState: StoreOnHandState = {
    filterByCategoryOrSalesPlan: StoreOnHandConstants.FilterByCategoryOrSalesPlan.ByCategory,
  };

  export type StoreOnHandType = StoreOnHandState;
  export const filterByCategoryOrSalesPlanLens = Lens.from<StoreOnHandType>().prop('filterByCategoryOrSalesPlan');
}

interface OrderReportsState {
  readonly replenishmentByProduct: ReplenishmentByProduct.ReplenishmentByProductType;
  readonly replenishmentByStore: ReplenishmentByStore.ReplenishmentByStoreType;
  readonly storeOnHand: StoreOnHand.StoreOnHandType;
  readonly reportDownloadStatus: MutationStatus;
}
export const InitialState: OrderReportsState = {
  replenishmentByProduct: ReplenishmentByProduct.ReplenishmentByProductInitialState,
  replenishmentByStore: ReplenishmentByStore.ReplenishmentByStoreInitialState,
  storeOnHand: StoreOnHand.StoreOnHandInitialState,
  reportDownloadStatus: MutationStatus.Initial,
};

export type Type = OrderReportsState;

export const replenishmentByProduct = Lens.from<Type>().prop('replenishmentByProduct');
export const replenishmentByProductFilterByCategoryOrSalesPlan = Lens.from<ReplenishmentByProduct.ReplenishmentByProductType>().prop('filterByCategoryOrSalesPlan');
export const replenishmentByProductFilterByCategoryOrSalesPlanLens = replenishmentByProduct
  .comp(replenishmentByProductFilterByCategoryOrSalesPlan);

export const replenishmentByStore = Lens.from<Type>().prop('replenishmentByStore');
export const replenishmentByStoreFilterByCategoryOrSalesPlan = Lens.from<ReplenishmentByStore.ReplenishmentByStoreType>().prop('filterByCategoryOrSalesPlan');
export const replenishmentByStoreFilterByCategoryOrSalesPlanLens = replenishmentByStore
  .comp(replenishmentByStoreFilterByCategoryOrSalesPlan);

export const storeOnHand = Lens.from<Type>().prop('storeOnHand');
export const storeOnHandFilterByCategoryOrSalesPlan = Lens.from<StoreOnHand.StoreOnHandType>().prop('filterByCategoryOrSalesPlan');
export const storeOnHandFilterByCategoryOrSalesPlanLens = storeOnHand
  .comp(storeOnHandFilterByCategoryOrSalesPlan);

export const reportDownloadStatus = Lens.from<Type>().prop('reportDownloadStatus');

export const getScanProductOptions = (state: shame, formName: string): boolean[] => {
  const formValues: { scanBased?: boolean, poBased?: boolean} = getFormValues(formName)(state);
  if (!formValues) {
    return [];
  }

  return [
    formValues.scanBased === true ? true : undefined,
    formValues.poBased === true ? false : undefined,
  ].filter(notNil);
};
