import { push } from 'connected-react-router';
import { Thunker } from 'client/types/redux-types';
import { ConfirmOkToSaveFunction } from './save-confirmation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GetSupplierCommitmentsQueryName } from './query';
import * as Mutations from './mutations';

export const newRecordButtonClicked = () => push('/planning/supplier-commitments/create');
export const rowSelected = (row: { id: number }) => push(`/planning/supplier-commitments/details/${row.id}`);

export const deleteMenuItemClicked = (ids: number[], confirmOkToSave: ConfirmOkToSaveFunction): Thunker => {
  return async (dispatch, getState) => {
    if (await confirmOkToSave()) {
      await msyncClientMutation<Mutations.DeleteSupplierCommitmentsResponse, Mutations.DeleteSupplierCommitmentsInput>({
        mutation: Mutations.DeleteSupplierCommitmentsMutation,
        variables: {
          ids,
        },
        dispatch,
        refetchQueries: [GetSupplierCommitmentsQueryName], // TODO: Remove this and figure out the cache issue with the mutation
        showBusyModal: true,
      });
    }
  };
};

export const downloadSpecSheetsMenuItemClicked = (supplierCommitmentIds: number[]): Thunker => async dispatch => {
  const result = await msyncClientMutation<Mutations.DownloadSupplierSpecSheetPdfMutationResponse, Mutations.DownloadSupplierSpecSheetPdfMutationInput>({
    mutation: Mutations.DownloadSupplierSpecSheetPdfMutation,
    variables: {
      supplierCommitmentIds,
    },
    dispatch,
    showBusyModal: true,
  });

  window.location.replace(`/report/fileDownload/${result.data.response.report.id}`);
};
