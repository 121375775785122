import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/vendor/vendor-graphql-types';

export interface Input {
  vendorId: number;
}

export type Vendor = Pick<GraphQLTypes.Vendor, 'id' | 'identifier' | 'lastModifiedAt'>;
export interface QueryResponse {
  getVendor?: {
    vendor: Vendor;
  };
}

export const Query = gql`
  query VendorRecordBarQuery($vendorId: Int!) {
    getVendor: GetVendor(id: $vendorId) {
      vendor {
        id
        identifier
        lastModifiedAt
      }
    }
  }
`;
