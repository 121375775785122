import * as Actions from './actions';
import * as State from './state';
import { flow } from 'lodash';

export default function reducer(state = State.InitialState, action: Actions.ActionTypes): State.Type {
  switch (action.type) {
    case Actions.ReportUserParamsActionTypeKeys.ComponentUnloaded: {
      return State.InitialState;
    }

    case Actions.ReportUserParamsActionTypeKeys.ActionStatusChanged: {
      return State.actionStatusLens.set(state, action.payload);
    }

    case Actions.ReportUserParamsActionTypeKeys.PresetClicked: {
      return State.setSelectedPresetId(state, action.payload.presetId);
    }

    case Actions.ReportUserParamsActionTypeKeys.PresetDeleted: {
      const currentSelectedPresetId = State.selectedPresetId(state);
      if (currentSelectedPresetId !== action.payload.presetId) {
        return state;
      }
      return State.clearSelectedPresetId(state);
    }

    case Actions.ReportUserParamsActionTypeKeys.SaveAsPresetModalShown: {
      return State.saveAsPresetModalShownLens.set(state, true);
    }

    case Actions.ReportUserParamsActionTypeKeys.SaveAsPresetModalHidden: {
      return State.saveAsPresetModalShownLens.set(state, false);
    }

    case Actions.ReportUserParamsActionTypeKeys.AllPresetOptionsLoaded: {
      return flow(
        State.allPresetOptionsLens.set(action.payload.options),
        State.defaultPresetIdLens.set(action.payload.defaultPresetId),
      )(state);
    }

    default:
      return state;
  }
}
