import * as React from 'react';
import { VendorRecordBar } from 'client/app/admin/vendors/vendor-record-bar';
import { VendorForm, VendorFormValues } from 'client/app/admin/vendors/vendor-form';

interface UIProps {
  onSubmit: (formData: VendorFormValues) => any;
}

export const VendorCreateFormUI: React.FC<UIProps> = props => {
  return (
    <div id="mfc-page-content">
      <VendorRecordBar/>
      <VendorForm onSubmit={props.onSubmit}/>
    </div>
  );
};
