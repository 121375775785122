import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, required, defineFieldValidators, setSchemaOptions } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, TYPES, INPUT_TYPES } from 'shared/types';
import * as Validators from 'shared/validators';
import { ComboProductGroup, ComboProductGroupId } from 'shared/schemas/combo-product-group';
import { Product, ProductId } from 'shared/schemas/product';
import { IRecord } from 'shared/schemas/record';
import { ProductDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type ComboProductId = number;

@tableName('comboProducts')
export class ComboProduct implements IRecord {
  id?: ComboProductId;

  @belongsTo('comboProductGroups')
  @property @required comboProductGroup: ComboProductGroup;
  comboProductGroupId: ComboProductGroupId;

  @belongsTo('products', { nativeTableFK: 'productId' })
  @property @required product: Product;
  productId: ProductId;

  @property @required packSize: number;
}

defineFieldValidators(ComboProduct, {
  packSize: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO], // this should be > 0, but to support VBills component/combos, we need to allow it to be 0
});

definePresentation(ComboProduct, {
  comboProductGroup: {
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    tableDisplay: true,
    columnWidth: 10,
  },
  product: {
    filterable: true,
    displayName: 'Product ID',
    sortable: true,
    columnWidth: 10,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver }),
  },
  packSize: {
    displayName: 'Quantity',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
});

setSchemaOptions(ComboProduct, {
});
