import gql from 'graphql-tag';

export const getRelatedCustomerOrdersQuery = gql`
query getRelatedCustomerOrders($input: GetRelatedCustomerOrdersInput!) {
  getRelatedCustomerOrders: GetRelatedCustomerOrders(input: $input) {
    relatedCustomerOrders {
      id
      identifier
      orderDate
      orderType
      customer {
        id
        name
      }
      sellDepartment {
        id
        identifier
      }
      mfcArea {
        id
        identifier
      }
    }
  }
}
`;
