import { GLOBAL_SAVE_STARTED, GLOBAL_SAVE_FINISHED } from 'client/constants';

export function globalSaveStarted() {
  return {
    type: GLOBAL_SAVE_STARTED,
  };
}

export function globalSaveFinished() {
  return {
    type: GLOBAL_SAVE_FINISHED,
  };
}
