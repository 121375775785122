import * as _ from 'lodash';
import * as React from 'react';
import * as Select from 'react-select';
import * as classnames from 'classnames';
import { union } from 'lodash';
import { ControlLabel, FormControl, HelpBlock } from 'client/components/third-party';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';
import { filterByBeginnersThenFuzzies } from 'shared/helpers/filter-helpers';

export interface LabeledSelectOptions {
  disabled?: boolean;
  handleChange?: (value: any) => void;
  horizontalLabel?: boolean;
  multi?: boolean;
  clearable?: boolean;
  creatable?: boolean;
  placeholder?: string;
  required?: boolean;
  hideOptionalLabel?: boolean;
  autoFocus?: boolean;
  autoSetFirstOptionIfPossible?: boolean;
  tabIndex?: boolean;
  alwaysShowErrors?: boolean;
  inline?: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
}
export interface LabeledSelectProps extends LabeledSelectOptions {
  /**
   * comes from redux-form
   * @memberOf LabeledSelectProps
   */
  input: { name: string; value: any; onChange: (value: any) => void; onBlur?: (value: any) => void; };
  inputColSize: number;
  label: string;
  labelColSize: number;

  /**
   * comes from redux-form
   * @memberOf LabeledSelectProps
   */
  meta: { touched?: boolean; error?: string; warning?: string; submitting: boolean; };
  offset: number;
  options: any[];
  testid: string;
  textFormatter: (value: any) => string;
  valueField: string;
  loading?: boolean;
}

interface LocalState {
  newOption: SelectOption | null;
}

export class LabeledSelect extends React.Component<LabeledSelectProps, LocalState> {
  componentRef: React.RefObject<Select.Creatable | Select>;

  public static readonly defaultProps = {
    horizontalLabel: true,
    multi: false,
    creatable: false,
    clearable: false,
  };

  constructor(props) {
    super(props);
    this.state = { newOption: null };

    this.componentRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.componentRef && this.componentRef.current) {
          this.componentRef.current.focus();
        }
      }, 500);
    }
  }

  onOptionCreated = (newOption: SelectOption) => {
    this.setState({ newOption });
    this.changeHandler(newOption);
  }

  changeHandler = selectedValue => {
    if (this.props.handleChange)
      this.props.handleChange(selectedValue?.value ?? null); // null indicates value was cleared
    else
      this.props.input.onChange(this.props.multi // Apparently react-select has a different onChange api for multi-select
        ? selectedValue?.map(v => v.value) ?? null
        : selectedValue?.value ?? null
      );
  }

  componentWillReceiveProps(nextProps) {
    // Automatically choose the option if there is only one available
    if (nextProps.autoSetFirstOptionIfPossible && (!this.props.options || this.props.options.length === 0) && (nextProps.options && nextProps.options.length === 1)) {
      const selectedOption: SelectOption = {
        value: nextProps.options[0].id,
        label: nextProps.textFormatter(nextProps.options[0]),
      };
      this.changeHandler(selectedOption);
    }
  }

  render() {
    const {
      input: { value, name },
      meta: { touched, error, submitting },
      label,
      labelColSize,
      inputColSize,
      offset,
      placeholder,
      options,
      textFormatter,
      multi,
      clearable,
      creatable,
      horizontalLabel,
      testid,
      disabled,
      required,
      hideOptionalLabel,
      tabIndex,
    } = this.props;

    const displayOptions = options.map(option => {
      return {
        value: option.id,
        label: textFormatter(option),
      };
    });

    const allDisplayOptions = this.state.newOption ? union(displayOptions, [this.state.newOption]) : displayOptions;
    const SelectComponentType: any = creatable ? Select.Creatable : Select;
    const className = classnames('labeled-select', { multi });
    const disabledAttributes: any = disabled || this.props.loading ? { disabled: true, tabIndex: '-1' } : {};

    const shouldDisplayError = !submitting && error && (touched || this.props.alwaysShowErrors);

    const filterOptions = (opts, filter) => {
      const availableOptions = this.props.multi
        ? opts.filter(o => !(value || []).includes(o.value))
        : opts;

      return filterByBeginnersThenFuzzies<{ id: number, label: string }>(availableOptions, o => o.label, filter);
    };

    const SelectComponent = (
      <div className={className} data-testid={testid} data-loaded={allDisplayOptions.length > 0}>
        {this.props.loading &&
          <div className="labeled-select-loading">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        }
        <SelectComponentType
          name={name}
          value={value}
          options={allDisplayOptions}
          onChange={this.changeHandler}
          multi={multi}
          clearable={multi || clearable}
          arrowRenderer={this.arrowRenderer}
          placeholder={this.props.loading ? '' : placeholder}
          onNewOptionClick={this.onOptionCreated}
          tabIndex={!_.isNil(tabIndex) ? `${tabIndex}` : undefined}
          {...disabledAttributes}
          ref={this.componentRef}
          filterOptions={filterOptions}
        />
        {touched && <FormControl.Feedback/>}
        {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
        {this.props.children}
      </div>
    );

    const labelClassName = classnames({
      required,
      optional: !required && !hideOptionalLabel,
    });

    if (this.props.inline) {
      return (
        <>
          <div className="labeled-select-inline inline-label">{label}</div>
          <div className="labeled-select-inline component">{SelectComponent}</div>
        </>
      );
    } else if (horizontalLabel) {
      return (
        <HorizontalLayout labelComponent={ControlLabel} labelColSize={labelColSize} offset={offset} label={label} inputColSize={inputColSize} classNames={[labelClassName]}>
          {SelectComponent}
        </HorizontalLayout>
      );
    }

    return (
      <StackedLayout inputColSize={inputColSize} offset={offset} label={label} classNames={[labelClassName]}>
        {SelectComponent}
      </StackedLayout>
    );
  }

  arrowRenderer({ isOpen }) {
    const arrowClassNames = classnames('fa', {
      'fa-caret-up': isOpen,
      'fa-caret-down': !isOpen,
    });
    return <span className={arrowClassNames} />;
  }
}
