import * as React from 'react';
import { startCase, lowerCase } from 'lodash';
import * as pluralize from 'pluralize';
import PageTitle from '../components/page-title';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { ActivateRecordFunction } from 'client/hoc/graphql/activate-records';
import { DeactivateRecordFunction } from 'client/hoc/graphql/deactivate-records';
import { AvailableSearchField, AvailableFilter, ButtonClickHandler } from 'client/types';
import { OnRowSelect } from 'client/components/table/table-ui';
import { IColumn } from 'client/components/table/column';
import { TableParentInfo } from 'client/components/table/table-parent';
import { Modal, Button } from 'client/components/third-party';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import { pendingRowActionHandler, PendingRowActionState } from 'client/containers/table/basic-table';
import { MsyncDataRequest } from 'client/hoc/graphql/query';

/* eslint-disable react/no-multi-comp */
const Title = t => <PageTitle title={t} />;

const MAX_DOWNLOADABLE_ROWS = 30000;

export interface AdminListPageProps {
  schema: string;
  title?: string;
  columns: IColumn[];
  excelColumns?: IColumn[];
  loading?: boolean;
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  performActivation: ActivateRecordFunction;
  performDeletion: DeactivateRecordFunction;
  onRowSelect?: OnRowSelect;
  onNewClicked?: ButtonClickHandler;
  onRowFocused?: (row: Dictionary<any>) => void;

  content: any[];
  refetchTable: () => void;
  tablePageNumber: number;
  totalUnfilteredCount: number;
  totalCount: number;
  filteredRecordIds: number[];
  loadMoreRecords?: () => void;

  tableParentInfo: TableParentInfo;
  orderToDelete: CustomerOrderId | SupplierOrderId;
  handleCancelButtonClicked(): void;
  deleteMenuItemClicked(): void;
  showConfirmationModal: boolean;

  downloadOrderDetailsMenuItemClicked(): void;
  downloadOrderDetailsHeaderMenuItemClicked(): void;

  dataRequest: MsyncDataRequest;
}

export class OrderListPage extends React.Component<AdminListPageProps, PendingRowActionState> {
  private FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: AdminListPageProps) {
    super(props);
    this.state = { pendingRowAction: false };
    this.FilterableTable = buildFilterableTable(props.schema);
  }

  render() {
    const displayTitle = this.props.title || pluralize(startCase(this.props.schema));
    const placeholder = `There are currently no ${this.props.title || pluralize(lowerCase(this.props.schema))}.`;
    const newButtonLabel = this.props.title ? `New ${pluralize.singular(this.props.title)}` : `New ${pluralize.singular(startCase(this.props.schema))}`;

    const headerMenuItems: RowMenuItem[] = [
      {
        label: 'Download Order Details',
        onClick: this.props.downloadOrderDetailsHeaderMenuItemClicked,
        shouldDisplay: (record: any) => true,
        uncheckRecordFollowingClick: true,
        willRemove: false,
      },
    ];

    const rowMenuItems: RowMenuItem[] = [
      {
        label: 'Download Order Details',
        onClick: this.props.downloadOrderDetailsMenuItemClicked,
        shouldDisplay: (record: any) => true,
        uncheckRecordFollowingClick: true,
        willRemove: false,
      },
      {
        label: 'Delete Order',
        onClick: this.props.deleteMenuItemClicked,
        shouldDisplay: (record: any) => true,
        uncheckRecordFollowingClick: true,
        willRemove: true,
      },
    ];

    const confirmDelete = async () => {
      this.props.handleCancelButtonClicked();
      await pendingRowActionHandler(this, this.props.performDeletion)(this.props.orderToDelete);
    };

    return (
      <div className = {`mfc-${this.props.schema}-page`}>
        <div id= "mfc-page-content">
          {Title(displayTitle)}
          <this.FilterableTable
            table={this.props.schema}
            loading={this.state.pendingRowAction || this.props.loading}
            columns={this.props.columns}
            content={this.props.content}
            refetchTable={this.props.refetchTable}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={placeholder}
            newButtonLabel={newButtonLabel}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            totalCount={this.props.totalCount}
            loadMoreRecords={this.props.loadMoreRecords}
            filteredRecordIds={this.props.filteredRecordIds}
            checkable
            list
            onNewClicked={this.props.onNewClicked}
            onRowSelect={this.props.onRowSelect}
            headerMenuItems={headerMenuItems}
            rowMenuItems={rowMenuItems}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            displayLoadingIndicator={this.state.pendingRowAction}
            dataRequest={this.props.dataRequest}
            excelColumns={this.props.excelColumns}
            disableTableDownload={this.props.totalCount > MAX_DOWNLOADABLE_ROWS}
          />
        </div>
        <Modal
          id="confirm-order-delete-modal"
          animation
          backdrop="static"
          show={this.props.showConfirmationModal}
          onHide={() => this.props.handleCancelButtonClicked()}>
          <Modal.Body>
            Are you sure you want to delete this order?
          </Modal.Body>
          <Modal.Footer className="remove-order-modal-footer">
            <Button bsClass="mfc-button mfc-clear-button" data-testid="cancel-button" onClick={() => this.props.handleCancelButtonClicked()}>
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              bsClass="mfc-button mfc-submit-button"
              data-testid="remove-button"
              onClick={confirmDelete}>
              Delete Order
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
