import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/route-plan-printing';
import { propToComponent } from 'client/hoc/hoc';
import { DownloadDittyCardModalUI, ComponentProps as UIProps } from './download-ditty-card-modal-ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { LoadConfiguration } from 'shared/types';

export interface OwnProps {
  routePlanId?: RoutePlanId;
}

interface StateProps {
  isShown: boolean;
  createButtonEnabled: boolean;
  loadConfiguration: LoadConfiguration;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const isShown = RoutePlanPrintingSelectors.isDownloadDittyCardModalShown(state);

  return {
    isShown,
    createButtonEnabled: true,
    loadConfiguration: RoutePlanPrintingSelectors.getSelectedLoadConfiguration(state),
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleCreateButtonClicked(routePlanId?: RoutePlanId): void;
  loadConfigurationChanged(loadConfiguration: LoadConfiguration): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.downloadDittyCardModalCancelButtonClicked,
  handleCreateButtonClicked: Actions.downloadDittyCardModalCreateButtonClicked,
  loadConfigurationChanged: Actions.loadConfigurationChanged,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(DownloadDittyCardModalUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
