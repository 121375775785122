import * as React from 'react';
import { useMemo, useRef } from 'react';
import * as _ from 'lodash';
import { print as printGql, DocumentNode } from 'graphql';
import { ChildProps, DataProps, OperationVariables, OptionProps, useQuery, QueryHookOptions } from 'react-apollo';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import * as Actions from 'client/actions/error';
import { MsyncApolloError } from 'client/hoc/graphql/msync-apollo-error-type';
import ApolloClient, { QueryOptions } from 'apollo-client';
import { apolloClient } from 'client/msync-apollo-client';
import { orThrowBug } from 'shared/helpers';
import { useUpdatableObject, useObject } from 'client/lib/react';
import { ServerSideValidationInfo } from 'shared/validators';

export const dispatchGqlError = (
  err: MsyncApolloError,
  handlers: {
    handleExpectedError: (args: { message: string, debugInfo: any }) => void;
    handleUnexpectedError: (args: { message: string }) => void;
    handleValidationErrors: (validations: ServerSideValidationInfo[]) => void;
  },
  comment?: string,
  p?: any,
  document?: DocumentNode,
  queryOptions?: any,
) => {
  const inner: any = err.networkError ?? err.graphQLErrors?.[0] ?? err;
  const errType
    = err.networkError                                  ? `Network Error - ${inner.message}`
    : err.graphQLErrors?.[0]?.validationErrors?.length  ? `Validation error`
    : err.graphQLErrors?.[0]?.isExpectedError           ? 'Expected error'
    : err.graphQLErrors?.[0]                            ? 'Unexpected GraphQL error'
    :                                                     'Unexpected error';

  console.error(`${comment} - ${errType} - ${err.message} - ${inner.message}`, {
    source_file: 'query.tsx',
    op: document ? printGql(document).split('\n')[0].split(' ')[1].split('(')[0] : '-- no document --',
    effect: errType,
    errStack: (_.isArray(inner.stack) ? inner.stack : inner.stack?.split('\n') ?? ['-- no inner error stack available --'])
      .concat(err === inner ? ['-- no outer error --']
            : _.isArray((err as any).stack) ? (err as any).stack : (err as any).stack?.split('\n') ?? ['-- no outer error stack available --']),
    error: err,
    innerError: err !== inner ? inner : 'no inner error',
    p,
    queryOptions,
    document: document ? printGql(document).split('\n') : '-- no document --',
    debugInfo: inner.debugInfo,
    childErrors: err.graphQLErrors ?? [],
  });

  if (errType === 'Expected error')
    handlers.handleExpectedError({ message: inner.message, debugInfo: inner.debugInfo });
  else if (errType === 'Validation error')
    handlers.handleValidationErrors(err.graphQLErrors[0].validationErrors as ServerSideValidationInfo[]);
  else
    handlers.handleUnexpectedError({ message: err.networkError ? 'Network Error - ' : '' + inner.message });

  return err;
};

/** Convert the provided OptionProps into something that's query sepcific */
export type MsyncQueryProps<TProps = any, TData = any, TGraphQLVariables = OperationVariables> = Required<Pick<OptionProps<TProps, TData, TGraphQLVariables>, 'data' | 'ownProps'>>;

export const DEBUG = { active: false };

/**
 * This is a slightly modified copy/paste of the OperationOption type in react-apollo.
 * It's original purpose was to strip out the mutation stuff and make it so the types
 * knew we'd always be getting back a .data property in the result. Unfortunately
 * upgrading to newer versions of react-apollo might mean having to mess with this again.
 */
interface MsyncQueryOption<TProps, TData, TGraphQLVariables = OperationVariables, TChildProps = ChildProps<TProps, TData, TGraphQLVariables>> {
  options?: QueryHookOptions<TData, TGraphQLVariables> | ((props: TProps) => QueryHookOptions<TData, TGraphQLVariables>);
  props?: (props: MsyncQueryProps<TProps, TData, TGraphQLVariables>) => TChildProps;
  // props?: (props: MsyncQueryProps<TProps, TData, TGraphQLVariables>, lastProps?: TChildProps | void) => TChildProps;
  skip?: boolean | ((props: TProps) => boolean);
  // name?: string;
  // withRef?: boolean;
  // shouldResubscribe?: (props: TProps, nextProps: TProps) => boolean;
  alias?: string;
}

const applyOrReturn = <X, Y>(x: X, fn?: (x: X) => Y) => fn?.(x) ?? x;

export const useMsyncQuery = <
  TData extends SimpleObject = SimpleObject,
  TProps extends TGraphQLVariables | SimpleObject = SimpleObject,
  TChildProps = Partial<DataProps<TData>>,
  TGraphQLVariables = SimpleObject,
>(
  p: TProps,
  document: DocumentNode,
  operationOptions?: MsyncQueryOption<TProps, TData, TGraphQLVariables, TChildProps>,
) => {
  const dispatch = useDispatch();
  const handlers = useMemo(() => ({
    handleExpectedError: (args: { message: string, debugInfo: any }) => dispatch(Actions.expectedErrorReceived(args)),
    handleUnexpectedError: (args: { message: string }) => dispatch(Actions.unexpectedErrorReceived(args)),
    handleValidationErrors: (validations: ServerSideValidationInfo[]) => dispatch(Actions.validationErrorReceived(validations)),
  }), [dispatch]);

  const {props: originalPropsFunc, options: optionsSource, ...opts} = {...(operationOptions ?? {})};
  const skip = (_.isFunction(opts.skip) ? opts.skip(p) : opts.skip) === true;
  const queryOptions = useObject({
    ...(_.isFunction(optionsSource) ? (skip ? {} : optionsSource(p)) : (optionsSource ?? {})),
    skip,
    displayName: opts.alias ?? undefined,
  });

  const queryResult = useQuery<TData, TGraphQLVariables>(document, queryOptions);
  const apolloRefetch = useRef<(variables?: SimpleObject) => Promise<unknown>>(async () => orThrowBug(`refetch not initialized; query must first run at least once to produce a refetch callback.`));
  apolloRefetch.current = (queryResult.refetch ?? (async () => orThrowBug(`refetch not returned in Apollo result;\ngqlErrors: ${queryResult.error}`))) as shame<'use Zod in future'>;
  const msyncRefetch = useRef((variables?: any) => {
    if (DEBUG.active)
      console.debug(`REFETCH: ${printGql(document).split('\n')[0].split(' ')[1].split('(')[0]}`);

    return queryOptions.skip ? undefined : apolloRefetch.current(variables ?? queryOptions.variables).catch(err => _.defer(dispatchGqlError, err, handlers, 'During refetch:', p, document, {queryOptions, overrideVariables: variables}));
  });
  if (queryResult.error) _.defer(dispatchGqlError, queryResult.error, handlers, 'gql returned errors', p, document, queryOptions); // _.defer -- Don't want to dispatch directly from a render (you'll get warnings in the console)
  const resultingProps = useUpdatableObject(applyOrReturn({
    ...queryResult,
    ownProps: p,
    ...p,
    ...handlers/* TODO: remove this, it should be an internal detail -- but must verify first. */,
    data: {
      ...queryResult,
      ...queryResult.data,
      queryOptions,
      originalQueryText: printGql(document).split('\n'),
      loading: queryResult.loading || queryResult.networkStatus < 7 || (queryOptions.skip && !(queryResult as any).previousData && !queryResult.data),
      refetch: msyncRefetch.current,
    },
    refetch: msyncRefetch.current,
  }, originalPropsFunc as shame)) as shame as TChildProps & TProps & {loading?: boolean};

  if (DEBUG.active) {
    console.debug({
      source_file: 'query.tsx',
      op: printGql(document).split('\n')[0].split(' ')[1].split('(')[0],
      effect: queryOptions.skip ? 'skip' : resultingProps.loading ? 'loading' : (queryResult.error ?? queryResult.data),
      operationOptions,
      queryOptions,
      queryResult,
      resultingProps,
      doc: printGql(document).split('\n'),
      invocation: new Error('trace').stack?.split('\n'),
    });
  }

  return resultingProps;
};

/** This matches the react-apollo function's types, but that didn't match what we had before */
export const msyncQuery = <TData extends {} = {}, TProps extends TGraphQLVariables | {} = {}, TChildProps = Partial<DataProps<TData>>, TGraphQLVariables = {}>(
  document: DocumentNode,
  operationOptions?: MsyncQueryOption<TProps, TData, TGraphQLVariables, TChildProps>,
): (WrappedComponent: React.ComponentType<TChildProps & TProps>) => React.FunctionComponent<TProps> =>
  (Wrapped: React.ComponentType<TChildProps & TProps>) =>
    (p: TProps) => <Wrapped {...p} {...useMsyncQuery(p, document, operationOptions)} />;

/** Additional stuff we want for the m-sync specific query function */
interface MsyncClientQueryArgs<TVariables = OperationVariables> extends QueryOptions<TVariables> {
  dispatch: Dispatch<any>;
  client?: ApolloClient<shame>;
  disableGlobalError?: boolean;
  showBusyModal?: boolean;
}

/** wrap apolloClient.query with our redux-ified error handling */
export const msyncClientQuery = <T, TVariables = OperationVariables>(args: MsyncClientQueryArgs<TVariables>) =>
  apolloClient(args.client).query<T, TVariables>(args).catch(error => {
    throw args.disableGlobalError ? error : dispatchGqlError(error, {
      handleExpectedError: (...argz) => args.dispatch(Actions.expectedErrorReceived(...argz)),
      handleUnexpectedError: (...argz) => args.dispatch(Actions.unexpectedErrorReceived(...argz)),
      handleValidationErrors: (...argz) => args.dispatch(Actions.validationErrorReceived(...argz)),
    });
  });

/** Args provided to Redux action that triggers download of a file, like an Excel file. */
export interface MsyncDataRequest {
  operationName?: string;
  query: string | DocumentNode;
  variables: any;
  uniqueKey?: string;
  sortField?: string;
  worksheetName?: string;
  workbookName?: string;
  additionalInformation?: Array<{ label: string, value: string }>;
  customAccessor?: string;
}
