import * as React from 'react';
import * as _ from 'lodash';
import { ControlLabel } from 'client/components/third-party';
import * as classNames from 'classnames';
import { HorizontalLayout } from './layouts';
import { formatDollarsAndCents } from 'shared/types';

const LabeledDisplayMoney = (p: {
  label: string,
  testid: string,
  offset?: number,
  labelColSize: number,
  inputColSize: number,
  input?: boolean,
  value?: string | number | Nil,
}) => (
  <div data-testid={p.testid}>
    <HorizontalLayout labelComponent={ControlLabel} labelColSize={p.labelColSize} offset={p.offset} label={p.label} inputColSize={p.inputColSize}>
      <div className={classNames({ 'labeled-display': p.input ?? true, 'labeled-input': p.input ?? false })}>
        {_.isNil(p.value) ? '--' : formatDollarsAndCents(p.value)}
      </div>
    </HorizontalLayout>
  </div>
);

export default LabeledDisplayMoney;
