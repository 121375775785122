import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/route-plan-printing';
import { propToComponent } from 'client/hoc/hoc';
import { DownloadStoreAsnModalUI, ComponentProps as UIProps } from './ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import { CheckableStoresWithMerchandisers, isStoresSelectAllChecked } from 'client/state/route-plan-printing';
import { RoutePlanQueryResponse } from 'client/app/transportation/routing/route-plan-details/loads/routing-loads-query';
import { msyncQuery } from 'client/hoc/graphql/query';
import gql from 'graphql-tag';

export interface OwnProps {
  routePlanId?: RoutePlanId;
}

interface StateProps {
  isShown: boolean;
  sendButtonDisabled: boolean;
  storesWithMerchandisers: Array<CheckableStoresWithMerchandisers & {merchandisers: Array<{id: number, name: string}>}>;
  selectAllChecked: boolean;
  searchText: string;
  numSelected: number;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const isShown = RoutePlanPrintingSelectors.isDownloadStoreAsnModalShown(state);
  const searchText = RoutePlanPrintingSelectors.storeSearchText(state);

  const stateStoresWithMerchandisers = RoutePlanPrintingSelectors.storesWithMerchandisers(state);
  const storesWithMerchandisers = stateStoresWithMerchandisers
    .filter(storeWithMerchandiser => {
      if (searchText === '') {
        return true;
      }
      return storeWithMerchandiser.storeIdentifier.toLowerCase().includes(searchText.toLowerCase()) ||
        storeWithMerchandiser.merchandiserName.toLowerCase().includes(searchText.toLowerCase());
    });

  return {
    isShown,
    storesWithMerchandisers,
    sendButtonDisabled: stateStoresWithMerchandisers.length === 0 || stateStoresWithMerchandisers.every(mws => !mws.checked),
    selectAllChecked: isStoresSelectAllChecked(state.routePlanPrinting),
    searchText,
    numSelected: stateStoresWithMerchandisers.filter(mws => mws.checked).length,
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleDownloadPDFButtonClicked(routePlanId: RoutePlanId, routePlanIdentifier: string): void;
  handleStoreChecked(merchandiserId: number): void;
  handleSelectAllClicked(): void;
  handleSearch(text: string): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.downloadStoreSpecificAdvanceShipNoticesPDFCancelButtonClicked,
  handleDownloadPDFButtonClicked: Actions.storesDownloadPDFButtonClickedAction,
  handleStoreChecked: Actions.selectedStoresChanged,
  handleSelectAllClicked: Actions.storesSelectAllClicked,
  handleSearch: Actions.storesSearched,
};

interface WithRoutePlanIdentifierProps {
  routePlanIdentifier: string;
}

export const GetRoutePlanIdentifierQuery = gql`
query GetRoutePlanIdentifierForStoreAsnModal($id: Int!) {
  routePlanResult: GetRoutePlan(id: $id) {
    routePlan {
      id
      identifier
      status
    }
  }
}
`;

const WithRoutePlanIdentifier = msyncQuery<RoutePlanQueryResponse, OwnProps & StateProps, WithRoutePlanIdentifierProps, { id: number | undefined }>(GetRoutePlanIdentifierQuery, {
  alias: 'WithRoutePlanIdentifierAndStatus',
  skip(ownProps: OwnProps & StateProps) {
    return !ownProps.routePlanId;
  },
  options(ownProps): { variables: { id: number | undefined } } {
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): WithRoutePlanIdentifierProps {
    const { data } = props;
    const { routePlanResult } = data;

    return {
      routePlanIdentifier: routePlanResult ? routePlanResult.routePlan.identifier : '',
    };
  },
});

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithRoutePlanIdentifierProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  WithRoutePlanIdentifier,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(DownloadStoreAsnModalUI) as Component<OwnProps>;

export const DownloadStoreAsnModalContainer = (props: OwnProps) => propToComponent(component, props);
