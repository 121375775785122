import gql from 'graphql-tag';
import { ReceivingSummaryStats } from 'shared/types';

export interface ReceivingSummaryStatsResponse {
  response?: ReceivingSummaryStats;
}

export const ReceivingSummaryQuery = gql`
  query getReceivingSummaryStats($receivableOrderId: Int!) {
    response: getReceivingSummaryStats(receivableOrderId: $receivableOrderId) {
      unit
      ordered
      received
      returned
      supplierOnHand
    }
  }
`;
