import * as GlobalState from './state';
import * as ImportRouteState from './import-route';
import {ImportRoutingLoadsInput} from 'schema/routing-load/routing-load-graphql-types';
import * as _ from 'lodash';
import {assertAsInt, GlobalStoreIdentifier, objMatch} from 'shared/types';
import {createSelector} from 'reselect';
import { TRANSPORTATION_SPLIT_STOPS } from 'client/constants';
import { buildTableStateModule } from './tables';
import { orThrowBug } from 'shared/helpers';

const getAllLoads = (state: GlobalState.Type) => {
  const loadStore = GlobalState .importRoute .comp(ImportRouteState.loadDataStoreEntries)(state);
  return _.keys(loadStore) .map(loadId => ({ id: loadId, ...loadStore[loadId] }));
};

const getAllStopsForLoad = (state: GlobalState.Type, loadId: string) => {
  const stopStore = GlobalState .importRoute .comp(ImportRouteState.stopDataStoreEntries)(state);
  return _
    .keys(stopStore)
    .filter(stopId => stopStore[stopId].loadReduxId === loadId)
    .map(stopId => ({ ...stopStore[stopId], id: stopId }));
};

// TODO: Use extracted logic
const findCustomerById = (state: GlobalState.Type, customerId: string) => GlobalState.importRoute.comp(ImportRouteState.customerDataStoreEntries)(state)[customerId];

// TODO: Use extracted logic
const findStoreById = (state: GlobalState.Type, storeId: string) => GlobalState.importRoute.comp(ImportRouteState.storeDataStoreEntries)(state)[storeId];
const findCustomerOrderById = (state: GlobalState.Type, customerOrderId: string) => GlobalState.importRoute.comp(ImportRouteState.customerOrderDataStoreEntries)(state)[customerOrderId];
export const getLoadsInput = (state: GlobalState.Type): ImportRoutingLoadsInput => {
  const routeInfo = GlobalState.importRouteRouteInformation(state);
  const routePlanId = GlobalState.importRouteRoutePlanId(state);
  if (routePlanId === null)
    throw new Error('Cannot import routes without a route plan');

  if (_.isNil(routeInfo.loadType) || _.isNil(routeInfo.carrier) || _.isNil(routeInfo.routeType) || _.isNil(routeInfo.dropoffLocation) || _.isNil(routeInfo.origin) || _.isNil(routeInfo.destination) || _.isNil(routeInfo.trailerTemperature))
    throw new Error('Invalid load input data; values are required for all fields');

  return {
    loadType: routeInfo.loadType,
    carrier: routeInfo.carrier,
    routeType: routeInfo.routeType,
    dropoffLocation: routeInfo.dropoffLocation,
    origin: routeInfo.origin,
    destination: routeInfo.destination,
    trailerTemperature: routeInfo.trailerTemperature,
    routePlanId,
    routingLoads: getRoutingLoads(state),
    splitStopStores: getAllStoresWithSplitStops(state),
  };
};

const getRoutingLoads = (state: GlobalState.Type): ImportRoutingLoadsInput['routingLoads'] =>
  getAllLoads(state).map((load, index) => ({
    identifier: load.loadNumber || '---',
    routeNumber: assertAsInt(load.routeNumber ?? orThrowBug('must have routeNumber')),
    routingStops: getRoutingStops(state, load.id),
  }));

const getSplits = (state: GlobalState.Type, storeId: string, loadId: string): ImportRoutingLoadsInput['routingLoads'][0]['routingStops'][0]['splits'] =>
  _.values(GlobalState.importRouteSplitStopQuantities(state))
  .filter(s => s.loadReduxId === loadId && s.storeReduxId === storeId)
  .map(s => ({
    customerOrderProductGroupId: s.customerOrderProductGroupId,
    quantity: s.quantity,
  }));

const getRoutingStops = (state: GlobalState.Type, loadId: string): ImportRoutingLoadsInput['routingLoads'][0]['routingStops'] => {
  return getAllStopsForLoad(state, loadId).map(stop => {
    const store = findStoreById(state, stop.storeReduxId);
    const customer = findCustomerById(state, store.customerReduxId);
    const customerOrder = findCustomerOrderById(state, stop.customerOrderReduxId);
    return {
      splits: getSplits(state, stop.storeReduxId, loadId),
      stopNumber: `${stop.sequenceNumber}`,
      storeIdentifier: {
        customerIdentifier: customer.identifier,
        storeNumber: store.identifier,
      },
      customerOrderIdentifier: customerOrder.identifier,
    };
  });
};

export const uploadState = (state: GlobalState.Type): ImportRouteState.FileUploadStatus => GlobalState.importRouteFileUploadStatus(state);
export const errorMessages = (state: GlobalState.Type): string[] => GlobalState.importRouteParsingErrorMessages(state);
export const isValidRouteInformation = (state: GlobalState.Type): boolean => {
  const routeInformation = GlobalState.importRouteRouteInformation(state);
  const isValid = Object
    .keys(routeInformation)
    .every(k => !_.isNil(routeInformation[k]));
  return isValid;
};

export const uploadInProgress = (state: GlobalState.Type): boolean => GlobalState.importRouteFileUploadStatus(state) === ImportRouteState.FileUploadStatus.InProgress;
export const submittingRoutes = (state: GlobalState.Type): boolean => GlobalState.importRouteProcessStep(state) === ImportRouteState.ImportRouteProcessStep.SubmittingRoutes;
export const onFirstProcessStep = (state: GlobalState.Type): boolean => GlobalState.importRouteProcessStep(state) === ImportRouteState.ImportRouteProcessStep.RouteInformation;
export const uploadSucceeded = (state: GlobalState.Type): boolean => GlobalState.importRouteFileUploadStatus(state) === ImportRouteState.FileUploadStatus.Succeed;
export const splitStopsExist = (state: GlobalState.Type): boolean => ImportRouteState.splitStopsExist(GlobalState.importRoute(state));
export const getAllStoresWithSplitStops = (state: GlobalState.Type): GlobalStoreIdentifier[] => ImportRouteState.getAllStoresWithSplitStops(GlobalState.importRoute(state));
export const isSelectedStore = (state: GlobalState.Type, storeIdentifier: GlobalStoreIdentifier): boolean => {
  const store = GlobalState.importRouteSelectedSplitStopStore(state);
  return store
    ? store.storeNumber === storeIdentifier.storeNumber && store.customerIdentifier === storeIdentifier.customerIdentifier
    : false;
};

export const getRoutePlanId = GlobalState.importRouteRoutePlanId.get;
export const getSelectedStore = (state: GlobalState.Type) => GlobalState.importRouteSelectedSplitStopStore(state);
const getSelectedStoreReduxId = createSelector([GlobalState.importRoute, GlobalState.importRouteSelectedSplitStopStore], (importRoute, selectedStore) =>
  !selectedStore ? null : ImportRouteState.getStoreReduxId(importRoute, selectedStore.customerIdentifier, selectedStore.storeNumber));

export const getLoadNumbersForSelectedStore = createSelector([getSelectedStoreReduxId, GlobalState.importRouteStops, GlobalState.importRoute.comp(ImportRouteState.loadDataStoreEntries)], (storeReduxId, stops, loads) =>
  _.values(stops)
  .filter(s => s.storeReduxId === storeReduxId)
  .map(s => loads[s.loadReduxId].loadNumber)
  .filter(l => !_.isNil(l)) as string[]);

const TableStateHelpers = buildTableStateModule(TRANSPORTATION_SPLIT_STOPS);
export const splitStopTableHasInvalidCell = (state: GlobalState.Type): boolean => TableStateHelpers.hasInvalidCell(TableStateHelpers.tableStateLens.get(state)) ?? false;
export const getSpacesRemainingInLoad = (state: GlobalState.Type, loadNumber: string): number => {
  const selectedStore = GlobalState.importRouteSelectedSplitStopStore(state);
  if (!selectedStore)
    return 0;

  const stops = _.values(GlobalState.importRouteStops(state));
  const storeReduxId = ImportRouteState.getStoreReduxId(state.importRoute, selectedStore.customerIdentifier, selectedStore.storeNumber);
  const loadReduxId = ImportRouteState.getLoadReduxIdByLoadNumber(state.importRoute, loadNumber);
  const spaces = stops
    .filter(stop => stop.storeReduxId === storeReduxId && stop.loadReduxId === loadReduxId)
    .reduce((accum, stop) => (stop.racks ?? 0) + accum, 0);

  const allocatedQuantity = _.values(ImportRouteState.splitStopQuantities(state.importRoute))
    .filter(splitStopQuantity => splitStopQuantity.storeReduxId === storeReduxId && splitStopQuantity.loadReduxId === loadReduxId)
    .reduce((accum, split) => split.quantity + accum, 0);

  return spaces - allocatedQuantity;
};

const getLoadNumbersForStore = createSelector(
  GlobalState.importRouteStops,
  GlobalState.importRoute.comp(ImportRouteState.loadDataStoreEntries),
  (stops, loads) =>
    (storeReduxId: string) =>
      _.values(stops)
      .filter(s => s.storeReduxId === storeReduxId)
      .map(s => loads[s.loadReduxId].loadNumber)
      .filter(l => !_.isNil(l)) as string[]);

export const isStoreFullyAllocated = (state: GlobalState.Type, selectedStore: GlobalStoreIdentifier): boolean => {
  const splits = _.values(ImportRouteState.splitStopQuantities(state.importRoute));
  const stops = _.values(GlobalState.importRouteStops(state));
  const storeReduxId = ImportRouteState.getStoreReduxId(state.importRoute, selectedStore.customerIdentifier, selectedStore.storeNumber);
  const storeSplits = splits.filter(x => x.storeReduxId === storeReduxId);
  const storeStops = stops.filter(x => x.storeReduxId === storeReduxId);

  // we now check the horizontal
  const areCOPGsFullyAllocated = _.uniq(storeSplits.map(x => x.customerOrderProductGroupId)).every(copgId => {
    const copgSplits = storeSplits.filter(s => s.customerOrderProductGroupId === copgId);
    const spaces = _.sumBy(copgSplits, x => x.totalRackQuantityForGroup ?? 0) / copgSplits.length;
    const copgAllocatedQuantity = _.sumBy(copgSplits, x => x.quantity);
    return spaces === copgAllocatedQuantity;
  });

  // and the vertical
  const areLoadsFullyAllocated = _.uniq(getLoadNumbersForStore(state)(storeReduxId)).every(loadNumber => {
    const loadReduxId = ImportRouteState.getLoadReduxIdByLoadNumber(state.importRoute, loadNumber);
    const spaces = _.sumBy(storeStops.filter(s => s.loadReduxId === loadReduxId), x => x.racks ?? 0);
    const loadAllocatedQuantity = _.sumBy(storeSplits.filter(s => s.loadReduxId === loadReduxId), x => x.quantity);
    return spaces === loadAllocatedQuantity;
  });

  return areLoadsFullyAllocated && areCOPGsFullyAllocated;
};

export const areAllStoresFullyAllocated = (state: GlobalState.Type): boolean => _.every(getAllStoresWithSplitStops(state).map(storeIdentifier => isStoreFullyAllocated(state, storeIdentifier)));

export const getStoreLoadRackQuantities = (s: GlobalState.Type) => (loadNumber: string, customerOrderProductGroupId: number) => {
  const selectedStore = GlobalState.importRouteSelectedSplitStopStore(s) ?? orThrowBug('Could not find selected store');
  const storeReduxId = ImportRouteState.getStoreReduxId(s.importRoute, selectedStore.customerIdentifier, selectedStore.storeNumber);
  const loadReduxId = ImportRouteState.getLoadReduxIdByLoadNumber(s.importRoute, loadNumber);
  const quantities = _ .values(GlobalState.importRouteSplitStopQuantities(s)) .filter(objMatch({ customerOrderProductGroupId, loadReduxId, storeReduxId }));
  if (quantities.length > 1)
    throw new Error(`Found more than one quantity for load number ${loadNumber} and customer order product group id ${customerOrderProductGroupId}`);

  return quantities[0]?.quantity ?? 0;
};

export const spacesRemainingForAllocatedProductForStore = (s: GlobalState.Type) =>
  (rackQuantity: number, customerOrderProductGroupId: number, loadNumber: string, loadQuantity: number) => {
    const selectedStore = GlobalState.importRouteSelectedSplitStopStore(s);
    if (!selectedStore)
      return 0;

    const loadReduxId = ImportRouteState.getLoadReduxIdByLoadNumber(s.importRoute, loadNumber);
    const storeReduxId = ImportRouteState.getStoreReduxId(s.importRoute, selectedStore.customerIdentifier, selectedStore.storeNumber);
    const splits = ImportRouteState.splitStopQuantities(s.importRoute);
    const quantitiesOmittedFromThisLoad = _.values(splits).filter(q => q.loadReduxId !== loadReduxId && objMatch({storeReduxId, customerOrderProductGroupId})(q));
    return rackQuantity - _.sumBy(quantitiesOmittedFromThisLoad, x => x.quantity) - loadQuantity;
  };
