import * as React from 'react';
import { LabeledInput } from 'client/components/form';
import { INPUT_TYPES } from 'shared/types';
import { isNil } from 'lodash';

export interface SimpleInputProps {
  label: string;
  testid: string;
  value: string | number;
  onChange: (value: shame) => void;
  horizontalLabel?: boolean;
  labelColSize?: number;
  inputColSize?: number;
  tabIndex?: number;
  disabled?: boolean;
  type?: INPUT_TYPES;
  min?: number;
  max?: number;
  autoFocus?: boolean;
}

export const SimpleInput = (props: SimpleInputProps) => {
  const meta = { touched: false, error: '', warning: '', submitting: false };
  const input = {
    value: props.value,
    // There are few consequences for generating a new onChange function here if any props change,
    // Because this is such a low level component, it won't cause a re-render chain reaction.
    onChange: event => props.onChange(event.target.value),
  };

  return <LabeledInput
    labelColSize={isNil(props.labelColSize) ? 6 : props.labelColSize}
    inputColSize={isNil(props.inputColSize) ? 6 : props.inputColSize}
    horizontalLabel={!!props.horizontalLabel}
    label={props.label}
    testid={props.testid}
    type={props.type || INPUT_TYPES.TEXT}
    disabled={props.disabled ?? false}
    input={input}
    meta={meta}
    step={1}
    placeholder=""
    offset={0}
    handleChange={input.onChange}
    required
    hideOptionalLabel
    autoFocus={props.autoFocus ?? false}
    money={false}
    tabIndex={props.tabIndex}
    min={props.min}
    max={props.max}
  />;
};
