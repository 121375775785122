import gql from 'graphql-tag';
import gqlTag from 'graphql-tag';
import { DateStr } from 'shared/types';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { buildFragment, formQueryColumns } from 'shared/schemas';

const table = 'productWorksheetReportPreferences';
const fragmentName = 'ProductWorksheetReportPreferences';
const fragment = gqlTag`${buildFragment(table, formQueryColumns(table), fragmentName)}`;

export const FindProductWorksheetReportPreferencesQuery = gqlTag`
query getProductWorksheetReportPreferences($customerOrderId: Int!) {
  productWorksheetReportPreferences: getProductWorksheetReportPreferences(customerOrderId: $customerOrderId) {
    id
    ...${fragmentName}
  }
}
${fragment}
`;

export const EditProductWorksheetReportPreferencesMutation = gql`
mutation EditProductWorksheetReportPreferences($input: EditProductWorksheetReportPreferencesInput!) {
  productWorksheetReportPreferences: editProductWorksheetReportPreferences(input: $input) {
    id
    performanceStartDate
    performanceEndDate
    inventoryStartDate
    inventoryEndDate
    salesStartDate
    salesEndDate
    trendsStartDate
    trendsEndDate
    pieceTargetStartDate
    pieceTargetEndDate
    pieceTargetPrimaryStoresOnly
  }
}
`;

export interface ProductWorksheetReportPreferencesResponse {
  id: number;
  customerOrder: {
    id: CustomerOrderId;
  };
  performanceStartDate: DateStr;
  performanceEndDate: DateStr;
  inventoryStartDate: DateStr;
  inventoryEndDate: DateStr;
  salesStartDate: DateStr;
  salesEndDate: DateStr;
  trendsStartDate: DateStr;
  trendsEndDate: DateStr;
  pieceTargetStartDate?: DateStr | null;
  pieceTargetEndDate?: DateStr | null;
  pieceTargetPrimaryStoresOnly: boolean;
}

export interface ProductWorksheetReportPreferencesQueryResponse {
  productWorksheetReportPreferences: ProductWorksheetReportPreferencesResponse;
}
