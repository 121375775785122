import * as _ from 'lodash';
import { change, getFormValues } from 'redux-form';
import { Thunker, Action } from 'client/types/redux-types';
import { ImmutableDateRange, toDateStr } from 'shared/types';
import * as DateHelpers from 'shared/helpers/date-helpers';

export type ActionTypes =
  | ReportDownloadStartedAction
  | ReportDownloadFinishedAction
  ;

export enum ActionTypeKeys {
  ReportDownloadStarted = 'App/ReportDownloadStarted',
  ReportDownloadFinished = 'App/ReportDownloadFinished',
}

export const handleCustomerChanged = (formName: string, formFields: { customerId: string }) => (customerId: number): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.customerId, customerId));
  };
};

export const handleDateRangeChanged = (formName: string, formFields: { beginDate: string, endDate: string, pieceTargetBeginDate: string, pieceTargetEndDate: string }) => (dateRange: ImmutableDateRange): Thunker => {
  return async (dispatch, getState) => {
    const formValues: shame = getFormValues(formName)(getState());
    if (_.isNil(formValues.pieceTargetEndDate)) {
      const year = DateHelpers.yearOf(dateRange.endDate);
      const lastYear = year - 1;
      dispatch(change(formName, formFields.pieceTargetBeginDate, toDateStr(`${lastYear}-01-01`)));
      dispatch(change(formName, formFields.pieceTargetEndDate, toDateStr(`${lastYear}-12-31`)));
    }

    dispatch(change(formName, formFields.beginDate, dateRange.startDate));
    dispatch(change(formName, formFields.endDate, dateRange.endDate));
  };
};

export const handlePieceTargetDateRangeChanged = (formName: string, formFields: { pieceTargetBeginDate: string, pieceTargetEndDate: string }) => (dateRange: ImmutableDateRange): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.pieceTargetBeginDate, dateRange.startDate));
    dispatch(change(formName, formFields.pieceTargetEndDate, dateRange.endDate));
  };
};

export const handleSellDepartmentChanged = (formName: string, formFields: { sellDepartmentId: string, productClassIds: string, productSubClassIds: string, productIds: string }) => (sellDepartmentId: number): Thunker => {
  // When the sell department is changed, we want to unselect any previously selected choices.
  return async dispatch => {
    dispatch(change(formName, formFields.sellDepartmentId, sellDepartmentId));
  };
};

export const handleSalesPlanChanged = (formName: string, formFields: { salesPlanId: string, salesPlanForPieceTargetId: string }) => (salesPlanId: number): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.salesPlanId, salesPlanId));
    dispatch(change(formName, formFields.salesPlanForPieceTargetId, salesPlanId));
  };
};

export const handleScanBasedFilterChanged = (formName: string, formFields: { scanBased: string, productIds: string }) => (isOn: boolean): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.scanBased, isOn));
  };
};

export const handlePOBasedFilterChanged = (formName: string, formFields: { poBased: string, productIds: string }) => (isOn: boolean): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.poBased, isOn));
  };
};

export const handleProductClassesFilterChanged = (formName: string, formFields: { productClassIds: string, productSubClassIds: string, productIds: string }) => (productClassIds: number[]): Thunker => {
  return async (dispatch, getState) => {
    dispatch(change(formName, formFields.productClassIds, productClassIds));
  };
};

export const handleProductSubClassesFilterChanged = (formName: string, formFields: { productSubClassIds: string, productIds: string }) => (productSubClassIds: number[]): Thunker => {
  return async (dispatch, getState) => {
    dispatch(change(formName, formFields.productSubClassIds, productSubClassIds));
  };
};

export const handleMfcAreasChanged = (formName: string, formFields: { mfcAreaIds: string, storeIds: string }) => (mfcAreaIds: number[]): Thunker => {
  return async (dispatch, getState) => {
    dispatch(change(formName, formFields.mfcAreaIds, mfcAreaIds));
  };
};

export const handleMarketsChanged = (formName: string, formFields: { marketIds: string, storeIds: string }) => (marketIds: number[]): Thunker => {
  return async (dispatch, getState) => {
    dispatch(change(formName, formFields.marketIds, marketIds));
  };
};

export const handleRegionsChanged = (formName: string, formFields: { regionIds: string, storeIds: string, marketIds: string }) => (regionIds: number[]): Thunker => {
  return async (dispatch, getState) => {
    dispatch(change(formName, formFields.regionIds, regionIds));
  };
};

interface ReportDownloadStartedAction extends Action {
  type: ActionTypeKeys.ReportDownloadStarted;
}
export const reportDownloadStarted = (): ReportDownloadStartedAction => {
  return {
    type: ActionTypeKeys.ReportDownloadStarted,
  };
};

interface ReportDownloadFinishedAction extends Action {
  type: ActionTypeKeys.ReportDownloadFinished;
}
export const reportDownloadFinished = (): ReportDownloadFinishedAction => {
  return {
    type: ActionTypeKeys.ReportDownloadFinished,
  };
};
