import { buildAdminListPage } from 'client/containers/admin';
import { Store } from 'shared/schemas/store';

export default buildAdminListPage(Store, {
  columns: ['identifier', 'customer', 'region', 'market', 'address1', 'city', 'state', 'zip', 'merchandisingSupervisorName', 'merchandisingSupervisorPhone', 'primaryGlobal', 'activeStatus'],
  excelColumns: [
    'identifier',
    'customer',
    'region',
    'market',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'country',
    'activeStatus',
    'openDate',
    'closeDate',
    'indoorFloralMfcArea',
    'outdoorGardenMfcArea',
    'merchandisingSupervisor',
    'merchandisingSupervisorName',
    'merchandisingSupervisorPhone',
    'merchandisingSupervisorEmail',
    'indoorDeliveryHours',
    'indoorDeliveryLocation',
    'outdoorDeliveryHours',
    'outdoorDeliveryLocation',
    'encoreStore',
    'primarySupplier',
    'latitude',
    'longitude',
    'managerName',
    'managerPhone',
    'managerFax',
    'managerEmail',
    'villageRestrictions',
  ],
  filters: ['customer', 'region', 'market', 'state', 'activeStatus', 'merchandisingSupervisorName', 'primaryGlobal'],
});
