import { Action } from 'client/types/redux-types';

export enum MutationStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Complete = 'Complete',
}

export type ActionTypes =
  MutationSent |
  MutationResponseReceived |
  BusyModalVisibilityChanged |
  RemoveRecentlyReceivedMutationResponse;

export enum ActionTypeKeys {
  MUTATIONS_MUTATION_SENT = 'App/MUTATIONS_MUTATION_SENT',
  MUTATIONS_MUTATION_RESPONSE_RECEIVED = 'App/MUTATIONS_MUTATION_RESPONSE_RECEIVED',
  MUTATIONS_REMOVE_RECENTLY_RECEIVED_MUTATION_RESPONSE = 'App/MUTATIONS_REMOVE_RECENTLY_RECEIVED_MUTATION_RESPONSE',
  BUSY_MODAL_VISIBILITY_CHANGED = 'App/BUSY_MODAL_VISIBILITY_CHANGED',
}

export interface MutationSent extends Action {
  type: ActionTypeKeys.MUTATIONS_MUTATION_SENT;
  payload: {
    showBusyModal: boolean,
    disableGlobalSpinner: boolean,
  };
}

export function mutationSent(showModal: boolean, disableGlobalSpinner?: boolean): MutationSent {
  return {
    type: ActionTypeKeys.MUTATIONS_MUTATION_SENT,
    payload: {
      showBusyModal: showModal,
      disableGlobalSpinner: !!disableGlobalSpinner,
    },
  };
}

export interface BusyModalVisibilityChanged extends Action {
  type: ActionTypeKeys.BUSY_MODAL_VISIBILITY_CHANGED;
  payload: {
    showBusyModal: boolean;
    message?: string;
  };
}

export function showBusyModal(message?: string): BusyModalVisibilityChanged {
  return {
    type: ActionTypeKeys.BUSY_MODAL_VISIBILITY_CHANGED,
    payload: {
      showBusyModal: true,
      message,
    },
  };
}

export function hideBusyModal(): BusyModalVisibilityChanged {
  return {
    type: ActionTypeKeys.BUSY_MODAL_VISIBILITY_CHANGED,
    payload: {
      showBusyModal: false,
    },
  };
}

export interface MutationResponseReceived extends Action {
  type: ActionTypeKeys.MUTATIONS_MUTATION_RESPONSE_RECEIVED;
  payload: {
    disableGlobalSpinner: boolean,
  };
}

export function mutationResponseReceived(disableGlobalSpinner?: boolean): MutationResponseReceived {
  return {
    type: ActionTypeKeys.MUTATIONS_MUTATION_RESPONSE_RECEIVED,
    payload: {
      disableGlobalSpinner: !!disableGlobalSpinner,
    },
  };
}

export interface RemoveRecentlyReceivedMutationResponse extends Action {
  type: ActionTypeKeys.MUTATIONS_REMOVE_RECENTLY_RECEIVED_MUTATION_RESPONSE;
}

export function removeRecentlyReceivedMutationResponse(): RemoveRecentlyReceivedMutationResponse {
  return {
    type: ActionTypeKeys.MUTATIONS_REMOVE_RECENTLY_RECEIVED_MUTATION_RESPONSE,
  };
}
