import * as R from 'ramda';
import {
  tableName, property, belongsTo, hasMany, definePresentation, required, setSchemaOptions,
} from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, TYPES, OrderMethod, INPUT_TYPES, DateTimeStr } from 'shared/types';
import { CustomerOrder, CustomerOrderId } from './customer-order';
import { ImportJob } from 'shared/schemas/import-job';
import { CustomerOrderProduct, CustomerOrderProductServiceInput } from 'shared/schemas/customer-order-product';
import { IRecord, Saved } from 'shared/schemas/record';
import { ComboProductGroup } from 'shared/schemas/combo-product-group';
import { ComboProductGroupDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { ReceivableOrderDetail } from './receivable-order-detail';
import { User } from './user';

export type CustomerOrderProductGroupId = Flavor<number, 'customerOrderProductGroupId'>;
export type CustomerOrderProductGroupIdentifier = Flavor<string, 'customerOrderProductGroupIdentifier'>;
export type CustomerOrderProductGroupPacksPerShippingUnit = Int;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { customerOrderProducts, ...copgWithoutCops } = {} as Partial<CustomerOrderProductGroup>;

export type CustomerOrderProductGroupServiceInput = typeof copgWithoutCops & {
  id?: number;
  customerOrderProducts?: {
    created?: Array<Partial<CustomerOrderProductServiceInput>>;
    updated?: Array<Partial<Saved<CustomerOrderProductServiceInput>>>;
    deleted?: Array<{ id: CustomerOrderProductServiceInput['id'] }>;
  };
};

export const customerOrderIdFor = (copg: CustomerOrderProductGroup) => copg.customerOrderId;
export const customerOrderIdsFor = R.map(customerOrderIdFor);

export interface CreateCustomerOrderProductGroupInput {
  updatedAt?: DateTimeStr;
  identifier?: string;
  description?: string;
  isCombo?: boolean;
  orderMethod?: string;
  packsPerShippingUnit?: number;
  lastModifiedAt?: string;
  customerOrderId?: number;
  importJobId?: number;
  comboProductGroupId?: number;
  customerOrderProducts?: shame;
}

export interface EditCustomerOrderProductGroupInput {
  id: number;
  updatedAt?: DateTimeStr;
  identifier?: string;
  description?: string;
  isCombo?: boolean;
  orderMethod?: string;
  packsPerShippingUnit?: number;
  lastModifiedAt?: string;
  customerOrderId?: number;
  importJobId?: number;
  comboProductGroupId?: number;
  customerOrderProducts?: shame;
}

@tableName('customerOrderProductGroups')
export class CustomerOrderProductGroup implements IRecord {
  // Note: These unique constraints are not really used - the findByUniqueConstraintForVBillsDataImport method is overriden in the COPG repo.
  // Hooray for OOP design 🙃
  static readonly UniqueConstraints: Array<keyof CustomerOrderProductGroup> = ['customerOrder', 'identifier', 'description'];

  static readonly SchemaName = 'CustomerOrderProductGroup';

  id?: CustomerOrderProductGroupId;

  @belongsTo('customerOrders')
  @property @required customerOrder: CustomerOrder;
  @property customerOrderId: CustomerOrderId;

  @hasMany('customerOrderProducts')
  @property customerOrderProducts: CustomerOrderProduct[];

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @belongsTo('comboProductGroups')
  @property comboProductGroup?: ComboProductGroup;
  comboProductGroupId?: number;

  @property @required identifier: CustomerOrderProductGroupIdentifier;

  @belongsTo('receivableOrderDetails')
  @property receivableOrderDetail?: ReceivableOrderDetail;
  receivableOrderDetailId?: number;

  /**
   * For VBills syncing, needs to be:
   * component products => combo_product_group.identifier
   * combo racks => anything you want, COPG.identifier must be unique per order instead
   * full rack / case => product.identifier
   *
   * See CustomerOrderProductGroupRepository.findByUniqueConstraintForVBillsDataImport(Partial<COPG>)
   */
  @property @required description: string;
  @property @required isCombo: boolean;

  @property @required orderMethod: OrderMethod;
  @property packsPerShippingUnit?: CustomerOrderProductGroupPacksPerShippingUnit | null;
  @property lastModifiedAt?: DateTimeStr | null;

  @belongsTo('users', { through: 'customerOrders', nativeTableFK: 'lastModifiedUserId' })
  @property lastModifiedUser?: User;
  @property lastModifiedUserId?: number | null;
}

// Validators for this schema have been moved to server/validators and shared/validators

definePresentation(CustomerOrderProductGroup, {
  customerOrder: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    filterable: true,
  },
  identifier: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Rack',
  },
  description: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Rack Name',
  },
  orderMethod: {
    filterable: true,
    formDisplay: false,
    includeInFormQuery: true,
  },
  importJob: { formDisplay: false },
  isCombo: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
  },
  comboProductGroup: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ComboProductGroupDisplayValueResolver }),
  },
  packsPerShippingUnit: {
    sortable: true,
    tableDisplay: true,
    displayName: 'PPR',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
});

setSchemaOptions(CustomerOrderProductGroup, {
  uniqueConstraints: CustomerOrderProductGroup.UniqueConstraints,
  hasLastModifiedInfo: true,
});
