import * as React from 'react';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { ImportDistributionRackShippingWorksheetImportState, ImportDistributionRackShippingWorksheetImportStep } from '../state';

export interface ComponentProps {
  routePlanId: number;
  handleFileSelected(fileName: string, fileContents: string, routePlanId: number): void;
  distributionRackShippingSpreadsheetImportState: ImportDistributionRackShippingWorksheetImportState;
  failedStep: ImportDistributionRackShippingWorksheetImportStep | undefined;
  errorMessages: string[] | undefined;
}

export class FileChooserUI extends React.PureComponent<ComponentProps, {}> {
  constructor(props) {
    super(props);
  }

  onFileInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const files = (event.target as any).files;
    if (files.length !== 1) {
      return;
    }
    const file: File = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) {
        throw new Error('Unable to read file!');
      }
      if (typeof reader.result !== 'string') {
        throw new Error('Unexpected file contents (not a string)');
      }
      this.props.handleFileSelected(file.name, reader.result, this.props.routePlanId);
    };
    reader.readAsBinaryString(file);
  }

  render() {
    const ChooseFileButton = (props: { label: string }) => (
      <label className="import-distribution-rack-shipping-worksheet-modal-file-picker align-bottom btn btn-default btn-file mfc-button mfc-submit-button mfc-button-primary">
        {props.label}
        <input
          type="file"
          id="import-distribution-rack-shipping-worksheet-modal-file-picker-input-element"
          className="import-distribution-rack-shipping-worksheet-modal-file-picker-input-element"
          data-testid="file-picker"
          onChange={this.onFileInputChange}
          style={{ display: 'none' }}
          accept=".xlsx" />
      </label>
    );

    const ChooseFileContent = () => (
      <div className="import-distribution-rack-shipping-worksheet-modal-content-area">
        <div className="text-center">
          <ChooseFileButton label="Choose File" />
        </div>
      </div>
    );

    const ParsingContent = () => (
      <div className="import-distribution-rack-shipping-worksheet-modal-content-area">
        <div className="import-distribution-rack-shipping-worksheet-modal-spinner-container">
          <ProgressSpinner label="Parsing..." />
        </div>
      </div>
    );

    const ParsedContent = () => (
      <div className="import-distribution-rack-shipping-worksheet-modal-content-area">
        <div className="text-center">
          <span className="fa fa-check fa-5x" />
          <div className="import-distribution-rack-shipping-worksheet-modal-status-text">Ready to Import</div>
        </div>
      </div>
    );

    const ImportingContent = () => (
      <div className="import-distribution-rack-shipping-worksheet-modal-content-area">
        <div className="import-distribution-rack-shipping-worksheet-modal-spinner-container">
          <ProgressSpinner label={`Importing...`} />
        </div>
      </div>
    );

    const ImportedContent = () => (
      <div className="import-distribution-rack-shipping-worksheet-modal-content-area">
        <div className="text-center">
          <span className="fa fa-check fa-5x import-distribution-rack-shipping-worksheet-modal-imported-status-icon" />
          <div className="import-distribution-rack-shipping-worksheet-modal-status-text">Imported</div>
        </div>
      </div>
    );

    const FailedContent = () => (
      <div>
        <div className="text-center">
          <span className="fa fa-times fa-5x import-distribution-rack-shipping-worksheet-modal-failed-status-icon" />
          <div className="import-distribution-rack-shipping-worksheet-modal-status-text">{this.props.failedStep} Failed</div>
        </div>
        <div id="import-distribution-rack-shipping-worksheet-error-message-box" className="alert alert-danger">
          <strong>There was a problem with the distribution rack shipping worksheet file.</strong>
          <ul id="import-distribution-rack-shipping-worksheet-error-list">
            {(this.props.errorMessages || []).map((message, index) => <li key={`import-distribution-rack-shipping-worksheet-message-${index}`}>{message}</li>)}
          </ul>
        </div>
        <div className="text-center">
          <ChooseFileButton label={'Choose Another File'} />
        </div>
      </div>
    );

    switch (this.props.distributionRackShippingSpreadsheetImportState) {
      case ImportDistributionRackShippingWorksheetImportState.Initial:
        return <ChooseFileContent />;
      case ImportDistributionRackShippingWorksheetImportState.Parsing:
        return <ParsingContent />;
      case ImportDistributionRackShippingWorksheetImportState.Parsed:
        return <ParsedContent />;
      case ImportDistributionRackShippingWorksheetImportState.Importing:
        return <ImportingContent />;
      case ImportDistributionRackShippingWorksheetImportState.Imported:
        return <ImportedContent />;
      case ImportDistributionRackShippingWorksheetImportState.Failed:
        return <FailedContent />;
    }
  }
}
