import gql from 'graphql-tag';
import {
  RoutingLoadId,
  RoutingLoadTotalFee,
  RoutingLoadGQLResult,
} from 'schema/routing-load/routing-load-graphql-types';

export interface RoutingLoadStatsRowResponse {
  routingLoadStatsRowResult?: {
    routingLoad: {
      id: RoutingLoadId;
      dropsCombinedWithAdditionalDrops: RoutingLoadGQLResult['dropsCombinedWithAdditionalDrops'];
      racks: RoutingLoadGQLResult['racks'];
      pallets: RoutingLoadGQLResult['pallets'];
      miles: RoutingLoadGQLResult['miles'];
      milesCombinedWithAdditionalMiles: RoutingLoadGQLResult['milesCombinedWithAdditionalMiles'];
      totalFee: RoutingLoadTotalFee;
    };
  };
}

export const RoutingLoadStatsRowQuery = gql`
  query routingLoadStatsRowQuery($routingLoadId: Int!) {
    routingLoadStatsRowResult: GetRoutingLoad(routingLoadId: $routingLoadId) {
      routingLoad {
        id
        identifier
        dropsCombinedWithAdditionalDrops
        racks
        pallets
        milesCombinedWithAdditionalMiles
        totalFee
      }
    }
  }
`;
