import * as React from 'react';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import * as Constants from '../add-route-plans-table/constants';
import * as RoutePlansTable from 'client/app/transportation/routing/route-plans-list/route-plans-table';
import { TableParentInfo } from 'client/components/table/table-parent';
import { DateStr, DateTimeStr } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  totalCount: Int;
  totalUnfilteredCount: Int;
  filteredRecordIds: number[];
  searchableFields: shame[];
  availableFilters: shame[];
  tableParentInfo: TableParentInfo;

  routePlansRows: RoutePlanRow[];
  loading: boolean;
}

interface RoutePlanRow {
  identifier: string;
  customerName: string;
  mfcAreaIdentifier: string | null;
  storeCount: number;
  loadCount: number;
  stopCount: number;
  racks: number;
  pallets: number;
  deliveryDate: DateStr;
  origin: string;
  status: string;
  lastModified: DateTimeStr | null;
}

export class AddRoutePlansTable extends React.Component<OwnProps, {}> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props) {
    super(props);
    this.FilterableTable = buildFilterableTable(Constants.TableName);
  }

  render() {
    return (
      <this.FilterableTable
        availableFilters={this.props.availableFilters}
        checkable
        columns={RoutePlansTable.columns}
        content={this.props.routePlansRows}
        disableCreate
        disableTableDownload
        displayLoadingIndicator
        filteredRecordIds={this.props.filteredRecordIds}
        list
        loading={this.props.loading}
        placeholder="No Route Plans"
        searchableFields={this.props.searchableFields}
        table={Constants.TableName}
        tablePaginated
        tableParentInfo={this.props.tableParentInfo}
        totalCount={this.props.totalCount}
        totalUnfilteredCount={this.props.totalUnfilteredCount}
      />
    );
  }
}
