import * as React from 'react';
import Dropdown from '../dropdown';
import { debounce } from 'lodash';
import * as classNames from 'classnames';

// name is what is displayed in the dropdown
// id is the value passed in to onChange when the search field is selected.
export interface AvailableColumn {
  id: string;
  name: string;
}

interface Props {
  placeholder?: string;
  activeColumns?: string | string[];
  availableColumns: AvailableColumn[];
  separator?: boolean;
  onColumnSelected: (column: string) => void;
  onSearch: (text: string) => void;
  activeSearchText: string;
}

interface State {
  searchText: string;
}

class SearchBox extends React.Component<Props, State> {
  private debouncedSearchInput: (text: string) => void;

  public static defaultProps: Partial<Props> = {
    separator: false,
    activeSearchText: '',
  };

  constructor(props) {
    super(props);

    this.debouncedSearchInput = debounce(props.onSearch, 500);

    this.state = {
      searchText: props.activeSearchText || '',
    };
  }

  render() {
    const { placeholder, availableColumns, activeColumns, onColumnSelected, separator } = this.props;

    const onSearchTextChanged = ({ target: { value: searchText } }) => {
      this.setState({ searchText });
      this.debouncedSearchInput(searchText);
    };

    const onKeyDown: React.EventHandler<React.KeyboardEvent<HTMLInputElement>> = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    const classes = classNames('form-group', 'search-container', {
      'no-separator': !separator,
    });

    const searchTextFormatter = option => `${option.name}`;

    return (
      <div className={classes} data-testid="search-container">
        <div className="input-group">
          <Dropdown
            className="input-group-btn mfc-button-table-dropdown table-search-dropdown"
            data={availableColumns}
            value={activeColumns}
            textFormatter={searchTextFormatter}
            valueField="id"
            disabled={availableColumns.length === 1}
            placeholder={placeholder}
            onChange={onColumnSelected}
            updateLabel
          />
          <input
            type="text"
            className="form-control table-search-input"
            placeholder={placeholder}
            value={this.state.searchText}
            onChange={onSearchTextChanged}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
    );
  }
}

export default SearchBox;
