import * as AsyncButtonContainer from 'client/components/async-button/async-button-container';
import * as BS from 'client/components/third-party';
import { FileChooser } from './file-chooser';
import * as GlobalAsyncModalHeaderContainer from 'client/components/async-button/global-async-modal-header-container';
import * as GlobalCancelButtonContainer from 'client/components/async-button/global-cancel-button-container';
import * as Mutations from 'client/actions/mutations';
import * as React from 'react';
import * as Types from './types';
import { SpreadsheetType, getPossibleSpreadsheetTypeErrors } from 'shared/file-parsers/excel/excel-parser-helpers';
import { timeout } from 'shared/helpers';

export interface ComponentProps {
  id: string | undefined;

  existingRecordId: number | undefined;

  modalTitle: string;

  fileExtension: string;

  expectedSpreadsheetType?: SpreadsheetType;

  importButtonLabel: string;
  closeButtonLabel: string;
  cancelButtonLabel: string;

  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked(): void;

  handleParsingStarted: Types.ParseFn;
  handleValidationStarted: Types.ValidateFn;
  handleImportStarted: Types.ImportFn;

  initialState?: Partial<ComponentState>;

  customFormGroup?: JSX.Element;

  chooseFileButtonDisabled?: boolean;

  className?: string;
}

interface ComponentState {
  importState: Types.ImportState;
  stillWorking: boolean;
  errorMessages: string[] | undefined;
  failedStep: Types.ImportStep | undefined;

  cancelButtonDisabled: boolean;
  importButtonDisabled: boolean;

  importableObject: Types.ImportableObject | undefined;
}

const InitialState: ComponentState = {
  importState: Types.ImportState.Initial,
  stillWorking: false,
  errorMessages: [],
  failedStep: Types.ImportStep.Validation,

  cancelButtonDisabled: false,
  importButtonDisabled: true,

  importableObject: undefined,
};

export class ImportUI extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      ...InitialState,
      ...props.initialState,
    };
  }

  handleFileSelected = (fileName: string, contents: string) => {
    this.setState({
      importState: Types.ImportState.Parsing,
      cancelButtonDisabled: true,
    });

    this.props.handleParsingStarted({ fileName, contents }, async (parseErrors: [], importableObject: Types.ImportableObject) => {
      this.setState({
        stillWorking: true,
      });

      await timeout(100);

      const possibleSpreadsheetTypeError = this.props.expectedSpreadsheetType ? await getPossibleSpreadsheetTypeErrors(this.props.expectedSpreadsheetType, fileName, contents) : undefined;

      if (possibleSpreadsheetTypeError || parseErrors.length > 0) {
        this.setState({
          stillWorking: false,
          importState: Types.ImportState.Failed,
          failedStep: Types.ImportStep.Parsing,
          errorMessages: possibleSpreadsheetTypeError ? [possibleSpreadsheetTypeError] : parseErrors || [],
          cancelButtonDisabled: false,
        });
        return;
      }

      this.setState({
        importState: Types.ImportState.Validating,
        stillWorking: false,
        errorMessages: [],
      });

      this.props.handleValidationStarted({ existingRecordId: this.props.existingRecordId, importableObject, additionalArguments: undefined }, validateErrors => {
        if (validateErrors.length > 0) {
          this.setState({
            importState: Types.ImportState.Failed,
            failedStep: Types.ImportStep.Validation,
            errorMessages: validateErrors,
            cancelButtonDisabled: false,
          });

          return;
        }

        this.setState({
          importState: Types.ImportState.Validated,
          errorMessages: [],
          cancelButtonDisabled: false,
          importButtonDisabled: false,

          importableObject,
        });
      });
    });
  }

  handleImportButtonClicked = () => {
    this.setState({
      importState: Types.ImportState.Importing,
      errorMessages: [],
      cancelButtonDisabled: true,
      importButtonDisabled: true,
    });

    this.props.handleImportStarted({ existingRecordId: this.props.existingRecordId, importableObject: this.state.importableObject, additionalArguments: undefined }, importErrors => {
      if (importErrors.length > 0) {
        this.setState({
          importState: Types.ImportState.Failed,
          failedStep: Types.ImportStep.Import,
          errorMessages: importErrors,
          cancelButtonDisabled: false,
        });

        return;
      }

      this.setState({
        importState: Types.ImportState.Imported,
        errorMessages: [],
      });
    });
  }

  render() {
    return (
      <BS.Modal
        id={this.props.id || 'import-modal'}
        animation
        backdrop="static"
        show={true}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="import-modal-container"
        className={this.props.className || 'import-modal'}
      >
        <GlobalAsyncModalHeaderContainer.GlobalAsyncModalHeader>{this.props.modalTitle}</GlobalAsyncModalHeaderContainer.GlobalAsyncModalHeader>
        <BS.Modal.Title>
        </BS.Modal.Title>
        <BS.Modal.Body>
          <div className="import-modal-content">
            {this.props.customFormGroup && this.props.customFormGroup}

            <FileChooser
              fileExtension={this.props.fileExtension}
              stillWorking={this.state.stillWorking}

              errorMessages={this.state.errorMessages}
              failedStep={this.state.failedStep}
              importState={this.state.importState}

              handleFileSelected={this.handleFileSelected}

              chooseFileButtonDisabled={this.props.chooseFileButtonDisabled}
            />
          </div>
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <GlobalCancelButtonContainer.GlobalCancelButton
            disabled={this.state.cancelButtonDisabled}
            onClick={this.props.handleCancelButtonClicked}
            testid="import-modal-cancel"
          />
          {this.state.importState !== Types.ImportState.Imported &&
            <AsyncButtonContainer.AsyncButton
              actionStatus={Mutations.MutationStatus.Initial}
              disabled={this.state.importButtonDisabled}
              label={this.props.importButtonLabel}
              onClick={this.handleImportButtonClicked}
              testid="import-modal-button"
              type={undefined}
            />
          }
          {this.state.importState === Types.ImportState.Imported &&
            <AsyncButtonContainer.AsyncButton
              actionStatus={Mutations.MutationStatus.Initial}
              label={this.props.closeButtonLabel}
              onClick={this.props.handleCloseButtonClicked}
              testid="import-modal-button"
              type={undefined}
            />
          }
        </BS.Modal.Footer>
      </BS.Modal>
    );
  }
}
