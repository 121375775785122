import { Button, FormGroup } from 'client/components/third-party';
import * as React from 'react';
import { FilterGroup, Toolbar } from 'client/components/toolbar';
import { SearchBox } from 'client/components/toolbar';
import { FilterResults } from 'client/components/toolbar/filter-results';
import { buildDataTable } from 'client/containers/table/basic-table';
import { AvailableSearchField, ActiveSearch, ActiveFilter, AvailableFilter, ButtonClickHandler } from 'client/types';
import { IColumn } from 'client/components/table/column';
import { OnRowSelect } from 'client/components/table/table-ui';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import * as _ from 'lodash';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { DownloadAsExcelButton } from 'client/components/table/download-excel';
import * as classnames from 'classnames';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { Arg1, Collapse, SORT_TYPES } from 'shared/types';
import { PropsWithChildren, useState } from 'react';

export type FilterableTableProps = Collapse<Arg1<typeof FilterableTableUI> & { children?: any }>;

export const FilterableTableUI = (p: PropsWithChildren<{
  table: string,
  content: any[],
  tablePageNumber: number,
  loading?: boolean,
  refetchTable?: () => void,
  refetchStats?: () => Promise<void>,
  onSearch: (text: string) => void,
  onSearchColumnSelected: (column: string) => void,
  onTableSort: (field: string, multiColumn?: boolean) => void,
  onToggleFilter: (field: string, value: string) => void,
  onClearAllFilters: () => void,
  onFilterSelected: (field: string, value: string | number) => void,
  searchableFields: AvailableSearchField[],
  availableFilters: AvailableFilter[],
  activeSearch: ActiveSearch,
  sortFields: Array<{ sortField: string; sortOrder: SORT_TYPES }>, // Not quite the same as "ActiveSort" type, as the foreignColumn has been made part of the field
  activeFilters: ActiveFilter[],
  activeSearchText?: string,
  placeholder?: string,
  checkable?: boolean,
  columns: IColumn[],
  excelColumns?: IColumn[],
  list?: boolean,
  onRowSelect?: OnRowSelect,
  onRowFocused?: (row: Dictionary<any>) => void,
  headerMenuItems?: RowMenuItem[],
  rowMenuItems?: RowMenuItem[],

  newButtonLabel?: string,
  onNewClicked?: ButtonClickHandler,

  buttons?: Array<{ label: string, onClick?: ButtonClickHandler, testid?: string }>,

  totalUnfilteredCount: number,
  totalCount: number,
  filteredRecordIds: number[],
  loadMoreRecords?: () => void,
  setTablePageNumber: (pageNumber: number, row?: number) => void,
  disableCreate?: boolean,
  displayLoadingIndicator?: boolean,
  tableParentInfo: TableParentInfo,
  tablePaginated: boolean,

  confirmOkToSave?: () => Promise<boolean>,
  handleSubmit?: () => Promise<boolean>,

  dataRequest?: MsyncDataRequest,

  hideTableDownload?: boolean,
  disableTableDownload?: boolean,
  numCheckedRecords: number,

  footerData?: { [k: string]: any },
  footerProps?: { [k: string]: any },
  supplementalRecordBarComponent?: React.FunctionComponent,

  alwaysDisplayTable?: boolean,
  noDataText?: string,
}>) => {
  const [DataTable] = useState(() => buildDataTable(p.table));
  const [initialLoadingComplete, setInitialLoadingComplete] = useState(p.loading === false);
  if (!initialLoadingComplete && p.loading === false)
    setInitialLoadingComplete(true);

  const childIsOrChildrenHaveModals = Array.isArray((p.children as any)?.props?.children)
    ? _.some((p.children as any).props.p.children, child => child.props?.isModal === true)
    : (p.children as any)?.props?.isModal === true;

  const nonModalChild = !childIsOrChildrenHaveModals ? p.children : undefined;
  if (nonModalChild)
    return nonModalChild;

  const displayButtons: Array<{ label: string, onClick?: ButtonClickHandler, testid?: string }> = p.buttons
    ? p.buttons
    : [{ label: p.newButtonLabel || 'Create', onClick: p.onNewClicked, testid: 'qa-new-element-button' }];

  const newLabel = p.newButtonLabel || 'Create';
  const shouldDisplayTable = (initialLoadingComplete && p.totalUnfilteredCount > 0) || p.displayLoadingIndicator || p.alwaysDisplayTable;
  const shouldDisplayPlaceholder = initialLoadingComplete && p.totalUnfilteredCount === 0 && !p.alwaysDisplayTable;
  const shouldDisplayNewRecordButton = initialLoadingComplete && p.totalUnfilteredCount === 0 && !p.disableCreate;
  const searchBox = p.searchableFields.length > 0 && (
    <SearchBox
      separator
      activeColumns={p.activeSearch.fields}
      availableColumns={p.searchableFields}
      onColumnSelected={p.onSearchColumnSelected}
      activeSearchText={p.activeSearchText || ''}
      onSearch={p.onSearch}
    />
  );

  const hasFilters = _.sum(p.activeFilters.map(filter => filter.values.length)) > 0;
  const topLevelClassName = classnames(
      shouldDisplayTable  ? ''
    : p.content           ? 'table-hidden'
    :                       'table-invisible'
    , { 'table-list': p.list },
  );

  const showSearchBoxOnLeft = p.availableFilters?.length === 0 && displayButtons.length > (p.disableCreate ? 1 : 0);
  const loadingContainerStyle = p.tableParentInfo.overlay;
  const displaySpinner = !!(p.displayLoadingIndicator && (_.isNumber(loadingContainerStyle) ? loadingContainerStyle : 0) > 100);
  const SupplementalRecordBarComponent = p.supplementalRecordBarComponent;
  return (
    <div className="app-filterable-table-wrapper">
      {(displaySpinner && p.loading) && <ProgressSpinner isTableSpinner={true} waitToDisplayMillis={200} style={loadingContainerStyle} />}
      {!shouldDisplayPlaceholder && <div className={topLevelClassName}>
        <Toolbar>
          <div className="mfc-toolbar-container">
            <FilterGroup activeFilters={p.activeFilters} availableFilters={p.availableFilters} onFilterSelected={p.onFilterSelected} />
            {showSearchBoxOnLeft && searchBox}
          </div>
          <div className="pull-right mfc-toolbar-container">
            {!showSearchBoxOnLeft && searchBox}
            {SupplementalRecordBarComponent && <FormGroup><SupplementalRecordBarComponent /></FormGroup>}
            {!p.disableCreate && <FormGroup>
              {displayButtons.map(btn => (
                <Button key={btn.label} bsClass="btn btn-default mfc-button-table" onClick={btn.onClick}
                  data-testid={btn.testid || 'qa-new-element-button'}>
                  {btn.label}
                </Button>
              ))}
            </FormGroup>}
            {p.dataRequest && p.hideTableDownload !== true &&
              <FormGroup>
                <DownloadAsExcelButton disabled={p.disableTableDownload} columns={p.excelColumns || p.columns} dataRequest={p.dataRequest} />
              </FormGroup>}
          </div>
        </Toolbar>
        <FilterResults
          availableFilters={p.availableFilters}
          activeFilters={p.activeFilters}
          displayedResultsCount={p.totalCount}
          onToggleFilter={p.onToggleFilter}
          onClearAllFilters={p.onClearAllFilters}
          numCheckedRecords={p.numCheckedRecords}
          checkable={!!p.checkable}
        />
        <DataTable
          content={p.content}
          columns={p.columns}
          onSort={p.onTableSort}
          onRowFocused={p.onRowFocused}
          onRowSelect={p.onRowSelect}
          sorting={p.sortFields}
          list={p.list}
          checkable={p.checkable}
          loadMoreRecords={p.loadMoreRecords}
          totalCount={p.totalCount}
          filteredRecordIds={p.filteredRecordIds}
          rowMenuItems={p.rowMenuItems}
          headerMenuItems={p.headerMenuItems}
          setTablePageNumber={p.setTablePageNumber}
          tablePageNumber={p.tablePageNumber}
          toolbarVisible={hasFilters}
          tableParentInfo={p.tableParentInfo}
          tablePaginated={p.tablePaginated}
          handleSubmit={p.handleSubmit}
          confirmOkToSave={p.confirmOkToSave}
          refetchStats={p.refetchStats}
          footerData={p.footerData}
          footerProps={p.footerProps}
          noDataText={p.noDataText}
        />
      </div>}
      {shouldDisplayPlaceholder &&
        <div className="table-empty">
          <div className="placeholder"> {p.placeholder} </div>
          {shouldDisplayNewRecordButton && (
            <div className="action">
              <Button
                bsClass="mfc-button mfc-submit-button mfc-submit-button-primary mfc-submit-button-primary-default"
                onClick={p.onNewClicked}
                data-testid="qa-new-element-button"
              >
                {newLabel}
              </Button>
            </div>
          )}
        </div>
      }
      {childIsOrChildrenHaveModals && p.children}
    </div>
  );
};
