import * as _ from 'lodash';
import * as Excel from 'exceljs';
import { intToExcelCol, excelColToInt } from 'excel-column-name';
import { ElementOf, Intersect, Collapse, conformingToType, btw } from './types';
import { Bug, orThrow, orThrowBug } from './helpers';
import { ColumnWithYear } from './app/reports/performance';

export const EmptyCell = { content: '' };
export const EmptyFormula = { formula: '' };
export const setWorksheetToPrintLandscape = (worksheet: Excel.Worksheet) => {
  worksheet.pageSetup.fitToPage = true;
  worksheet.pageSetup.fitToWidth = 1;
  worksheet.pageSetup.fitToHeight = 9999;
  worksheet.pageSetup.orientation = 'landscape';
};

export const setWorksheetToPrintBlackAndWhite = (worksheet: Excel.Worksheet) => worksheet.pageSetup.blackAndWhite = true;

/** Makes sure that all the rows from the first row of the sheet up until the row number specified appear at the top of each sheet when this report is printed */
export const setWorksheetTitleRowsForPrint  = (worksheet: Excel.Worksheet, rowNumberToAlwaysPrintUpto: number) => worksheet.pageSetup.printTitlesRow = `1:${rowNumberToAlwaysPrintUpto}`;
export const createEmptyRow                 = (worksheet: Excel.Worksheet, numberOfBlankValues?: number) => numberOfBlankValues ? worksheet.addRow(_.times(numberOfBlankValues, _.constant(''))) : worksheet.addRow([]);
export const sumOfColumn                    = (firstRow   : number, lastRow   : number, col: string) => ({ formula:       `SUM(${     col   }${firstRow}:${    col   }${lastRow})` });
export const sumOfRow                       = (firstColumn: string, lastColumn: string, row: number) => ({ formula:       `SUM(${firstColumn}${     row}:${lastColumn}${    row})` });
export const roundedSumOfRow                = (firstColumn: string, lastColumn: string, row: number) => ({ formula: `ROUND(SUM(${firstColumn}${     row}:${lastColumn}${    row}), 0)` });

/** Expects an array of string cell addresses formatted like "B10", "A4", "F9", etc. */
export const addSumFormulaForSpecificCells = (cells: string[]) => ({ formula: `${cells.join('+' /* e.g. B1+B4+B7+B10  -- We don't actually use a sum here because a function in Excel can only have 255 arguments */)}` });

type ArrayOfLength<N, T> = T[] & {length: N};
export const skip = <N extends Int>(n: N) => _.times(n, () => ({content: ''})) as ArrayOfLength<N, StyledCell>;
export const getColumnLetter: (col: number) => string = intToExcelCol;
export const getColumnNumber: (col: string) => number = excelColToInt;

// Legacy, shouldn't really combine centering and coloring in the same function
export const fillWithColorAndCenter = (cell: Excel.Cell, color: string) => Object.assign(cell, { style: { ...cell.style, fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } }, alignment: { horizontal: 'center' } } });
export const fillWithColor          = (cell: Excel.Cell, color: string) => Object.assign(cell, { style: { ...cell.style, fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } } });

// Legacy, shouldn't really combine centering and borders in the same function
export const applyBorderToRowCellsAndCenter   = (row: Excel.Row) => btw(row, () => row.eachCell(cell => Object.assign(cell, { alignment: { horizontal: 'center' }, border: { top: { style: 'medium' }, left: { style: 'medium' }, right: { style: 'medium' }, bottom: { style: 'medium' } } })));
export const applyMediumBorderToCell          = (cell: Excel.Cell) => btw(cell, () => cell.border = { top: { style: 'medium' }, left: { style: 'medium' }, right: { style: 'medium' }, bottom: { style: 'medium' } });
export const applyFontStylesToRowCells        = (row: Excel.Row, fontStyles: SimpleObject) => btw(row, () => row.eachCell(cell => cell.font = fontStyles));
export const applyBorderToEntireRowOnlyBottom = (row: Excel.Row, columnCount: number) => btw(row, () => _.range(1, columnCount + 1).map(i => row.getCell(i).border = { bottom: { style: 'thin' } }));
export const applyBorderToEntireRow           = (row: Excel.Row, border: Partial<Excel.Borders>, columnCount?: number) => btw(row, () => _.range(1, (columnCount || row.cellCount) + 1).map(i => row.getCell(i).border = border));
export const applyBorderToEntireRowOnlyTop    = (row: Excel.Row, columnCount: number) => btw(row, () => _.range(1, columnCount + 1).map(i => Object.assign(row.getCell(i), { border: { top: { style: 'thin' } } })));
export const applyFontStylesToCell            = (cell: Excel.Cell, fontStyles: SimpleObject) => Object.assign(cell, { font: { ...cell.font, ...fontStyles } });
export const setColumnWidth                   = (worksheet: Excel.Worksheet, columnLetter: string, width: number) => worksheet.getColumn(columnLetter).width = width;
export const convertZeroBasedIndexToOneBasedIndex = (index: number): number => index + 1;
export const convertOneBasedIndexToZeroBasedIndex = (index: number): number => index - 1;
export const setColumnWidths = (worksheet: Excel.Worksheet, widths: Array<number | undefined>) => widths.map((width, i) => width ? setColumnWidth(worksheet, getColumnLetter(convertZeroBasedIndexToOneBasedIndex(i)), width) : null);
export const mergeCells = (worksheet: Excel.Worksheet, rowNumber: number, startColumn: string, endColumn: string) => worksheet.mergeCells(`${startColumn}${rowNumber}:${endColumn}${rowNumber}`);
export const Alignment = conformingToType<{ [k in 'Center' | 'CenterWithVertical' | 'CenterWithWrap' | 'Left' | 'LeftWithWrap' | 'Right' | 'RightWithWrap' | 'Vertical']: Partial<Excel.Alignment> }>()({
  Center            : { horizontal: 'center'                                      },
  CenterWithVertical: { horizontal: 'center', vertical: 'middle'                  },
  CenterWithWrap    : { horizontal: 'center'                    , wrapText: true  },
  Left              : { horizontal: 'left'                                        },
  LeftWithWrap      : { horizontal: 'left'                      , wrapText: true  },
  Right             : { horizontal: 'right'                                       },
  RightWithWrap     : { horizontal: 'right'                     , wrapText: true  },
  Vertical          : { textRotation: 90 },
});

export interface ExcelFormula { formula: string; }
export interface StyledCell {
  alignment?: Partial<Excel.Alignment>;
  bold?: boolean;
  underline?: boolean;
  border?: Partial<Excel.Borders>;
  content: ExcelFormula | string | number | boolean | null | Date | { richText: Excel.RichText[]};
  fontSize?: number;
  format?: ExcelFormats;
  width?: number;
  fill?: Excel.Fill;
}

export enum ExcelFormats {
  Decimal = '#,##0.00',
  General = 'General',
  Text    = 'Text',
  Integer = '#,##0',
  IntegerWithNegativeRed = '_(* #,##0_);[Red]_(* (-#,##0);_(* "-"??_);_(@_)',
  Money = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)',
  Currency = '$#,##0.00',
  CurrencyWithoutDecimal = '$#,##0',
  Percent = '0%',
  PercentWithNegativeRed = '0%;[Red]-0%',
  PercentWithDecimal = '0.00%',
  PercentWithOneDecimal = '#,#0.0%',
  PercentWithDecimalWithNegativeRed = '0.00%;[Red]-0.00%',
}

export const BorderStyle = conformingToType<{
  [k in
  | 'Thin'
  | 'ThinBottom'
  | 'ThinGreybeRight'
  | 'ThinRight'
  | 'ThinLeft'
  | 'ThinRightLeft'
  | 'Medium'
  | 'MediumLeft'
  | 'MediumRight'
  | 'ThinTop'
  | 'MediumBottom'
  | 'Aggregate'
  | 'ThickTop'
  ]: Partial<Excel.Borders>
}>()({
  Thin           : { top   : { style: 'thin'   }, right: { style: 'thin'   }, bottom: { style: 'thin'   },  left: { style: 'thin'   } },
  ThinGreybeRight: {                              right: { style: 'thin', color: {argb: 'FFDDDDDD'} }                                 },
  ThinBottom     : {                                                          bottom: { style: 'thin'   }                             },
  ThinRight      : {                              right : { style: 'thin'   }                                                         },
  ThinRightLeft  : {                              right : { style: 'thin'   },                              left: { style: 'thin'   } },
  ThinLeft       : {                                                                                        left: { style: 'thin'   } },
  ThinTop        : { top   : { style: 'thin'   }                                                                                      },
  Medium         : { top   : { style: 'medium' }, right: { style: 'medium' }, bottom: { style: 'medium' },  left: { style: 'medium' } },
  Aggregate      : { top   : { style: 'thin'   }                            , bottom: { style: 'double' }                             },
  MediumLeft     : {                                                                                      left  : { style: 'medium' } },
  MediumRight    : {                              right : { style: 'medium' }                                                         },
  MediumBottom   : {                                                          bottom: { style: 'medium' }                             },
  ThickTop       : { top   : { style: 'thick' }                                                                                       },
});

export const addRowWithMergedCells = (worksheet: Excel.Worksheet, ...merges: StyledContentOrFormula[][]) => {
  const unmergedCells = _.flatMap(merges) as StyledContentOrFormula[];
  const row = addRowWithStyles(worksheet, unmergedCells);
  let startCol = 1;
  for (const merge of merges) {
    const endCol = startCol + merge.length - 1;
    worksheet.mergeCells(row.number, startCol, row.number, endCol); // top, left, bottom, right (TLBR)
    startCol = endCol + 1;
  }

  return row;
};

type StyledContentOrFormula = Partial<StyledCell> & (Pick<StyledCell, 'content'> | ExcelFormula);
type CellOrContent = ExcelFormula | StyledContentOrFormula | StyledCell['content'];
type WrappedContent<V extends CellOrContent, A extends Array<{}>>
  = V extends StyledCell   ? Collapse<V & Intersect<ElementOf<A>>>
  : V extends ExcelFormula ? Collapse<V & Intersect<ElementOf<A>>>
  :                         Collapse<{content: V}  & Intersect<ElementOf<A>>>;

const wrapContent = <V extends CellOrContent, A extends Array<{}> = []>(v: V, ...attributes: A) =>
  Object.assign({}, 'object' === typeof v && v !== null && ('content' in v) ? v : {content: _.isNil(v) ? '' : v}, ...attributes) as unknown as WrappedContent<V, A>;

const wrapper = <A extends {}>(a: A) => <V extends CellOrContent>(v: V) => wrapContent(v, a);
export const align = { Left: wrapper({}), Right: wrapper({alignment: Alignment.Right}), Center: wrapper({alignment: Alignment.Center}) };
export const withBorder = { Thin: wrapper({border: BorderStyle.Thin}) };
export const withFont = { Bold: wrapper({bold: true}) };
export const withFill = { Solid: (color: string) => wrapper({fill: { type: 'pattern' as 'pattern', pattern: 'solid' as 'solid', fgColor: { argb: color } }}) };
export const setWidth = (width: Int) => wrapper({width});
export const addRowWithStyles = <C extends Array<Partial<StyledCell> & (Pick<StyledCell, 'content'> | ExcelFormula)>>(worksheet: Excel.Worksheet, styledCells: C, opts?: { commit?: boolean }): Excel.Row => {
  const row = worksheet.addRow(styledCells.map(r => r.content === null ? '' : r.content));
  setColumnWidths(worksheet, styledCells.map(style => style.width));
  setRowAlignments(row, styledCells.map(style => style.alignment));
  setRowBold(row, styledCells.map(style => style.bold));
  setRowUnderline(row, styledCells.map(style => style.underline));

  row.eachCell((cell, colNum) => {
    const style = styledCells[convertOneBasedIndexToZeroBasedIndex(colNum)];
    if (style.border) { cell.border = style.border; }
    if (style.format) { cell.numFmt = style.format; }
    if (style.fill) { cell.style.fill = style.fill; }
  });

  setFontSize(row, styledCells.map(style => style.fontSize));
  if (opts?.commit)
    row.commit();

  return row;
};

const setFontSize = (row: Excel.Row, fontSizes: Array<number | undefined>) => btw (row, () => row.eachCell((cell, i) => cell.font = { ...cell.font, size: fontSizes[convertOneBasedIndexToZeroBasedIndex(i)] ?? cell.font.size }));
export const setRowAlignments = (row: Excel.Row, alignments: Array<Partial<Excel.Alignment> | undefined>) => btw(row, () =>
  row.eachCell((cell, i) => {
    const alignment = alignments[convertOneBasedIndexToZeroBasedIndex(i)];
    return alignment ? setCellAlignment(cell, alignment) : cell;
  })
);

export const setRowHeight = (row: Excel.Row, height: number) => btw(row, () => row.height = height);
export const setRowBold = (row: Excel.Row, bold: Array<boolean | undefined>) => btw(row, () => row.eachCell((cell, i) => boldCell(cell, !!bold[convertOneBasedIndexToZeroBasedIndex(i)])));
export const setRowUnderline = (row: Excel.Row, underline: Array<boolean | undefined>) => btw(row, () => row.eachCell((cell, i) => underlineCell(cell, !!underline[convertOneBasedIndexToZeroBasedIndex(i)])));
export const setCellAlignment = (cell: Excel.Cell, alignment: Partial<Excel.Alignment>) => Object.assign(cell, { alignment: { ...cell.alignment, ...alignment } });
export const boldCell      = (cell: Excel.Cell, bold     : boolean) => Object.assign(cell, { font: { ...cell.font, bold      } });
export const underlineCell = (cell: Excel.Cell, underline: boolean) => Object.assign(cell, { font: { ...cell.font, underline } });
export const setRowColor = (row: Excel.Row, color: string) => { row.eachCell(cell => fillWithColor(cell, color)); return row; };
type BorderPosition = 'top' | 'bottom' | 'left' | 'right';
const allPositions: BorderPosition[] = ['top', 'bottom', 'left', 'right'];
const setCellBorderForPosition = (cell: Excel.Cell, border: Partial<Excel.Border>, position: BorderPosition) => Object.assign(cell, { border: { ...cell.border, [position]: border } });
const applyBorderToCell        = (cell: Excel.Cell, style: Partial<Excel.Border>, positions?: BorderPosition[]) => { (!positions?.length ? allPositions : positions).map(p => setCellBorderForPosition(cell, style, p)); return cell; };
export const setTopBorderForRows    = (rows: Excel.Row[], borderStyle: Partial<Excel.Border>) => { if (!!rows.length) _.first(rows)?.eachCell(cell => applyBorderToCell(cell, borderStyle, ['top'   ])); return rows; };
export const setBottomBorderForRows = (rows: Excel.Row[], borderStyle: Partial<Excel.Border>) => { if (!!rows.length) _.last (rows)?.eachCell(cell => applyBorderToCell(cell, borderStyle, ['bottom'])); return rows; };
export const setLeftBorderForRows  = (rows: Excel.Row[], borderStyle: Partial<Excel.Border>) => { rows.forEach(row => applyBorderToCell(row.getCell(1/* Apply border only to the fiest cell in each row. */           ), borderStyle, ['left' ])); return rows; };
export const setRightBorderForRows = (rows: Excel.Row[], borderStyle: Partial<Excel.Border>) => { rows.forEach(row => applyBorderToCell(row.getCell(row.cellCount/* Apply border only to the last cell in each row. */), borderStyle, ['right'])); return rows; };
export const setBorderAroundRows = (rows: Excel.Row[], borderStyle: Partial<Excel.Borders>) => {
  if (rows.length > 0) {
    if (borderStyle.top   ) setTopBorderForRows   (rows, borderStyle.top   );
    if (borderStyle.bottom) setBottomBorderForRows(rows, borderStyle.bottom);
    if (borderStyle.left  ) setLeftBorderForRows  (rows, borderStyle.left  );
    if (borderStyle.right ) setRightBorderForRows (rows, borderStyle.right );
  }

  return rows;
};

// ---------- Excel Formulae ----------
// ====================================
type ReportField = string & {};
type RelativeRef = string & {};
type AbsoluteRef = string & {};
type ExcelRange = string & {};
type YearOverYearReportParameters = { columnsWithYear: ColumnWithYear[]; year: number | undefined; };
type ExcelFormulaInput = Collapse<YearOverYearReportParameters & { currentRowNumber: number; totalRows: number; firstProductRowNumber: number; }>;
type VerticalRangeIndices = { columnIndex: number; topRow: number; bottomRow: number; }; // TODO: exctract generic Excel thingies into Excelpers, and keep domain-specific stuff here.
type ExcelAggregateFormulaFunctionInput = Collapse<YearOverYearReportParameters & VerticalRangeIndices & { aggregateRowNumber: number; }>;
type FormulaBinder = { dependsOn: ReportField[]; formulaBinder: (args: ExcelFormulaInput) => ExcelFormula; };
type AggregateBinder = { dependsOn: ReportField[]; formulaBinder: (args: ExcelAggregateFormulaFunctionInput) => ExcelFormula; };
const columnIndex = (args: YearOverYearReportParameters, col: ReportField) => {
  const i = args.columnsWithYear.findIndex(c => c.columnName === col && c.year === args.year);
  return convertZeroBasedIndexToOneBasedIndex(i >= 0 ? i : orThrow(`Unable to find column "${_.startCase(col)}"; It must be included to generate this report.`));
};

// absolute references
const topCell = (args: VerticalRangeIndices): AbsoluteRef => `$${getColumnLetter(args.columnIndex)}$${args.topRow}`;
const bottomCell = (args: VerticalRangeIndices): AbsoluteRef => `$${getColumnLetter(args.columnIndex)}$${args.bottomRow}`;
const verticalRange = (args: VerticalRangeIndices): ExcelRange => `${topCell(args)}:${bottomCell(args)}`;

// relative references
const relRef = (args: ExcelAggregateFormulaFunctionInput, col: ReportField | null | undefined) => !!col ? `${getColumnLetter(columnIndex(args, col))}${args.aggregateRowNumber}` as RelativeRef : orThrowBug(`specified field not in worksheet: '${col}'`);
const relRefs = (args: ExcelAggregateFormulaFunctionInput, ...cols: Array<ReportField | null | undefined>) => _.map(cols, x => relRef(args, x));

export const runningTotal = (col: ReportField): FormulaBinder => ({
  dependsOn: [col],
  formulaBinder: (args: ExcelFormulaInput) => {
    const cellLeftFormulaFragment = `INDIRECT(ADDRESS(ROW(), ${columnIndex(args, col)}))`;
    const cellAboveFormulaFragment = `OFFSET(INDIRECT(ADDRESS(ROW(), COLUMN())), -1, 0)`;
    return ({ formula: `IF(ISNUMBER(${cellAboveFormulaFragment}), ${cellLeftFormulaFragment} + ${cellAboveFormulaFragment}, ${cellLeftFormulaFragment})` });
  },
});

export const percentOfTotal = (col: ReportField): FormulaBinder => ({
  dependsOn: [col],
  formulaBinder: (args: ExcelFormulaInput) => {
    const lastProductRowNumber = args.firstProductRowNumber + args.totalRows - 1;
    const columnLetter = getColumnLetter(columnIndex(args, col));
    return ({ formula: `IFERROR(${columnLetter}${args.currentRowNumber}/SUM($${columnLetter}$${args.firstProductRowNumber}:$${columnLetter}$${lastProductRowNumber}), 0)` });
  },
});

export const sumAggregate: AggregateBinder = { dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula: `SUM(${verticalRange(args)})` }) };
export const maxAggregate: AggregateBinder = { dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula: `MAX(${verticalRange(args)})` }) };
export const minAggregate: AggregateBinder = { dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula: `MIN(${verticalRange(args)})` }) };
export const averageAggregate: AggregateBinder = { dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula: `IFERROR(AVERAGE(${verticalRange(args)}), 0)` }) };
// const firstAggregate   : AggregateBinder = {dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula:                    topCell      (args)        })}; -- Preserverd in comment form for potential future generalization to Excelpers
export const lastAggregate: AggregateBinder = { dependsOn: [], formulaBinder: (args: VerticalRangeIndices) => ({ formula: bottomCell(args) }) };

/** Short-circuit the formula if it would be malformed due to missing sub-expressions. Use at the head of a null-coalescing chain -- undefined here means "proceed with formula as planned." */
const emptyFormulaIfMissingArgs = (...args: Array<ReportField | AbsoluteRef | RelativeRef | null | undefined>) => args.some(_.isNil) ? EmptyFormula : undefined;

export const fractionAggregate = (comparative: ReportField, referencePoint: ReportField): AggregateBinder => ({
  dependsOn: [comparative, referencePoint],
  formulaBinder: (args: ExcelAggregateFormulaFunctionInput) => {
    const [comp, ref] = relRefs(args, comparative, referencePoint);
    return emptyFormulaIfMissingArgs(comp, ref) || { formula: `IF(${comp}<>${ref},${comp}/${ref},0)` };
  },
});

export const yearOverYearCompAggregate = (comparative: ReportField, referencePoint: ReportField): AggregateBinder => ({
  dependsOn: [comparative, referencePoint],
  formulaBinder: (args: ExcelAggregateFormulaFunctionInput) => {
    const [comp, ref] = relRefs(args, comparative, referencePoint);
    return emptyFormulaIfMissingArgs(comp, ref) || { formula: `${comp}-${ref}` };
  },
});

export const fractionPercentageAggregate = (comparative: ReportField, referencePoint: ReportField): AggregateBinder => ({
  dependsOn: [comparative, referencePoint],
  formulaBinder: (args: ExcelAggregateFormulaFunctionInput) => {
    const [comp, ref] = relRefs(args, comparative, referencePoint);
    return emptyFormulaIfMissingArgs(comp, ref) || { formula: `IF(AND(${comp}<>${ref},${ref}<>0),(${comp}/${ref})-1,0)` };
  },
});

export const sellThroughAggregatePercent = (comparative: ReportField, referencePoint: ReportField): AggregateBinder => ({
  dependsOn: [comparative, referencePoint],
  formulaBinder: (args: ExcelAggregateFormulaFunctionInput) => {
    const [comp, ref] = relRefs(args, comparative, referencePoint);
    return emptyFormulaIfMissingArgs(comp, ref) || { formula: `IF(AND(${comp}<>${ref},${ref}<>0),(${comp}/${ref}),0)` };
  },
});

export const bindFormulae = (agg: AggregateBinder, derivation?: FormulaBinder) => ({
  dependsOn: _(agg.dependsOn).concat(derivation?.dependsOn ?? []).uniq().sort().value(),
  formula: derivation?.formulaBinder,
  aggregateFormulaFunction: agg.formulaBinder,
} as const);

export const validateDependencies = <
  Defs extends {
    [k in ReportField]: {
      // Header Styling
      header: string;
      width: number;
      alignment?: { wrapText?: boolean; };

      // Individual row styling/formatting
      format?: ExcelFormats;
      formula?: (input: ExcelFormulaInput) => ExcelFormula;
      aggregateFormulaFunction?: (input: ExcelAggregateFormulaFunctionInput) => ExcelFormula;
      dependsOn?: ReportField[];
    };
  }
>(columnDefs: Defs) => {
  for (const [fieldName, columnDef] of Object.entries(columnDefs)) {
    if (!columnDef.dependsOn) continue;
    for (const dependency of columnDef.dependsOn)
      if (!columnDefs[dependency])
        throw new Bug(`Column definition for "${fieldName}" depends on non-existent column "${dependency}"`);
  }

  return columnDefs;
};
