import 'reflect-metadata';
import { tableName, property, belongsTo, hasMany, definePresentation, required, setSchemaOptions } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, DateTimeStr } from 'shared/types';
import { CustomerOrderAllocation } from './customer-order-allocation';
import { CustomerOrder, CustomerOrderIdentifier, CustomerOrderOrderDate } from './customer-order';
import { Product, ProductIdentifier, ProductId } from './product';
import { ImportJob } from 'shared/schemas/import-job';
import { CustomerOrderProductGroup, CustomerOrderProductGroupIdentifier } from 'shared/schemas/customer-order-product-group';
import { IRecord } from 'shared/schemas/record';
import { ProductDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { CustomerIdentifier } from 'shared/schemas/customer';
import { User } from './user';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { product, customerOrderProductGroup, ...copWithoutProduct } = {} as Partial<CustomerOrderProduct>;

export type CustomerOrderProductId = Flavor<number, 'customerOrderProductId'>;
export type CustomerOrderProductPackSize = number;
export type CustomerOrderProductPacksPerShippingUnit = Flavor<Int, 'customerOrderProductPacksPerShippingUnit'>;

export type CustomerOrderProductServiceInput = typeof copWithoutProduct & {
  id?: number;
};

export interface CustomerOrderProductPrimaryKey {
  customerOrderIdentifier: CustomerOrderIdentifier;
  customerOrderOrderDate: CustomerOrderOrderDate;
  customerIdentifier: CustomerIdentifier;
  customerOrderProductGroupIdentifier: CustomerOrderProductGroupIdentifier;
  productIdentifier: ProductIdentifier;
}

@tableName('customerOrderProducts')
export class CustomerOrderProduct implements IRecord {
  static readonly UniqueConstraints: Array<keyof CustomerOrderProduct> = ['customerOrderProductGroup', 'product'];
  static readonly SchemaName = 'CustomerOrderProduct';

  id?: CustomerOrderProductId;

  @belongsTo('customerOrders', { through: 'customerOrderProductGroups', foreignQueryKeys: ['identifier', 'lastModifiedAt'] })
  @property customerOrder: CustomerOrder;

  @belongsTo('products')
  @property @required product: Product;
  productId: ProductId;

  @hasMany('customerOrderAllocations')
  @property customerOrderAllocations: CustomerOrderAllocation[];
  customerOrderAllocationId: number;

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @belongsTo('customerOrderProductGroups')
  @property @required customerOrderProductGroup: CustomerOrderProductGroup;
  customerOrderProductGroupId: number;

  @property packsPerShippingUnit?: CustomerOrderProductPacksPerShippingUnit | null;
  @property @required packSize: CustomerOrderProductPackSize;

  @property shelvesPerRack?: number | null;
  @property packsPerShelf?: number | null;
  @property lastModifiedAt?: DateTimeStr | null;

  @belongsTo('users', { through: 'customerOrders', nativeTableFK: 'lastModifiedUserId' })
  @property lastModifiedUser?: User;
  @property lastModifiedUserId?: number | null;

  @property piecesPerShippingUnit?: number | null;
  @property piecesPerShelf?: number | null;
  @property totalRackQuantity?: number | null;
}

// Validations have been moved to server/validations and shared/validations

definePresentation(CustomerOrderProduct, {
  customerOrder: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    filterable: true,
  },
  product: {
    displayName: 'Product ID',
    sortable: true,
    filterable: true,
    searchable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver }),
  },
  customerOrderProductGroup: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  packsPerShippingUnit: {
    sortable: true,
    tableDisplay: true,
    displayName: 'PPR',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  packSize: {
    displayName: 'Pack',
    type: TYPES.NUMBER,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  packsPerShelf: {
    displayName: 'PPS',
    type: TYPES.NUMBER,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  shelvesPerRack: {
    displayName: 'SPR',
    columnWidth: 5,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER, step: 0.01 }),
    type: TYPES.FLOAT,
  },
  customerOrderAllocations: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
  },
  piecesPerShippingUnit: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: { },
      calculation: (customerOrderProduct: CustomerOrderProduct) => `(COALESCE(customer_order_products.pack_size, 0) * COALESCE(customer_order_products.packs_per_shipping_unit, 0)) `,
    },
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
  piecesPerShelf: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: { },
      calculation: (customerOrderProduct: CustomerOrderProduct) => `(COALESCE(customer_order_products.pack_size, 0) * COALESCE(customer_order_products.packs_per_shelf, 0)) `,
    },
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
  totalRackQuantity: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.FLOAT,
    calculationSpec: {
      dependencies: { },
      calculation: (customerOrderProduct: CustomerOrderProduct) => {
        return  `(
          SELECT
            CASE WHEN cop.packs_per_shipping_unit = 0
            THEN NULL
            ELSE sum(coa.quantity * 1.0 / cop.packs_per_shipping_unit)
            END
          FROM
            customer_order_products cop
          JOIN
            customer_order_allocations coa on coa.customer_order_product_id = cop.id
          WHERE
            cop.id = ${customerOrderProduct.id}
          GROUP BY
            cop.id
          )
          `;
      },
    },
    tableDisplay: false,
    sortable: false,
    filterable: false,
  },
  importJob: { formDisplay: false },
});

setSchemaOptions(CustomerOrderProduct, {
  uniqueConstraints: CustomerOrderProduct.UniqueConstraints,
  hasLastModifiedInfo: true,
});
