import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';
import { getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import * as ReportSelectors from 'client/state/reports';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getScanProductOptions = (state: State.Type): boolean[] => {
  return ReportSelectors.getScanProductOptions(state, Constants.formName);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
  if (!dateRangeYearComparison) {
    return true;
  }

  const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
  if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate || !dateRanges.lastYearDateRange || !dateRanges.lastYearDateRange.startDate || !dateRanges.lastYearDateRange.endDate) {
    return true;
  }

  const productIds = extractSelectedValues(formValues[Constants.FormFields.productIds]);
  const storeIds = extractSelectedValues(formValues[Constants.FormFields.storeIds]);

  return _.isNil(formValues[Constants.FormFields.customerId])
    || productIds.length === 0
    || storeIds.length === 0
    || _.isNil(formValues[Constants.FormFields.primaryGlobalAll])
    || _.isNil(formValues[Constants.FormFields.sellDepartmentId]);
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
