import * as React from 'react';
import { CELL_TYPES, TYPES, ShippingUnitType, OrderMethod, ReceivingSummaryStats } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { AvailableSearchField, AvailableFilter } from 'client/types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { createSelector } from 'reselect';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { RelatedBolsRow } from 'client/app/transportation/receiving/details/related-bols/related-bols-query';

export interface RelatedBolsComponentProps extends ReceivingSummaryStats {
  tableRows: RelatedBolsRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  tableParentInfo: TableParentInfo;
  onRowSelect(): void;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  dataRequest: MsyncDataRequest;
  receivableOrderId: number;
  receivableOrderIdentifier: string;
}

const tableName = 'bols';

type ReceivedAccessor = 'shippingUnitQuantityReceived' | 'packQuantityReceived';
const getReceivedAccessor = (orderMethod: OrderMethod): ReceivedAccessor => {
  if (orderMethod === OrderMethod.Pack) {
    return 'packQuantityReceived';
  }

  return 'shippingUnitQuantityReceived';
};

type RacksReceivedHeader = 'Racks Received' | 'Pallets Received' | 'Packs Received' | 'Cases Received';
const getReceivedHeader = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): RacksReceivedHeader => {
  if (orderMethod === OrderMethod.ShippingUnit) {
    if (shippingUnitType === ShippingUnitType.Rack) {
      return 'Racks Received';
    }
    return 'Pallets Received';
  }
  if (shippingUnitType === ShippingUnitType.Rack) {
    return 'Packs Received';
  }
  return 'Cases Received';
};

const getColumns = (shippingUnitType: ShippingUnitType, orderMethod: OrderMethod): IColumn[] => {
  const columns: IColumn[] = [
    {
      id: 'identifier',
      accessor: 'identifier',
      header: 'BOL #',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
      columnWidth: 10,
    },
    {
      id: 'receiver',
      accessor: 'receiver',
      header: 'Receiver',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
      overflowWidth: true,
      columnWidth: 10,
    },
    {
      id: getReceivedAccessor(orderMethod),
      accessor: getReceivedAccessor(orderMethod),
      header: getReceivedHeader(shippingUnitType, orderMethod),
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
      columnWidth: 10,
    },
    {
      id: 'notes',
      accessor: 'notes',
      header: 'Notes',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.TEXT,
      type: TYPES.STRING,
      columnWidth: 50,
    },
    {
      id: 'receivedAt',
      accessor: 'receivedAt',
      header: 'Receive Time',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.DATE_TIME,
      type: TYPES.DATE_TIME,
      columnWidth: 10,
    },
    {
      id: 'racksReturned',
      accessor: 'racksReturned',
      header: 'Racks Returned',
      tableEditable: false,
      sortable: true,
      cellType: CELL_TYPES.NUMBER,
      type: TYPES.NUMBER,
      columnWidth: 10,
    },
  ];

  return columns;
};

const getShippingUnitType = (props: RelatedBolsComponentProps): ShippingUnitType => props.shippingUnitType;
const getOrderMethod = (props: RelatedBolsComponentProps): OrderMethod => props.orderMethod;
const columnsSelector = createSelector([getShippingUnitType, getOrderMethod], getColumns);

export class RelatedBolsTable extends React.Component<RelatedBolsComponentProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(tableName);
  }

  public render() {
    const columns = columnsSelector(this.props);

    return (
      <this.FilterableTable
        table={tableName}
        content={this.props.tableRows || []}
        loading={this.props.loading}
        columns={columns || []}
        totalCount={this.props.totalCount}
        totalUnfilteredCount={this.props.totalUnfilteredCount}
        filteredRecordIds={this.props.filteredRecordIds}
        refetchTable={() => { /* nothing?? */ }}
        loadMoreRecords={() => { /* nothing?? */ }}
        searchableFields={this.props.searchableFields}
        availableFilters={this.props.availableFilters}
        placeholder={'There are currently no BOLs for this order.'}
        tablePaginated={false}
        tableParentInfo={this.props.tableParentInfo}
        disableCreate
        list
        displayLoadingIndicator={true}
        onRowSelect={this.props.onRowSelect}
        dataRequest={this.props.dataRequest}
      />
    );
  }
}
