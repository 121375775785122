import { Lens } from '@atomic-object/lenses';

interface RecordState {
  readonly isRecordSaving: boolean;
  readonly productSpec: {
    readonly selectedSpecYear: number | null;
  };
}

export type Type = ImmutableRecordState;

export interface ImmutableRecordState {
  toJS(): RecordState;
  set<K extends keyof RecordState>(key: K, value: RecordState[K]): ImmutableRecordState;
  get<K extends keyof RecordState>(key: K): RecordState[K];
  setIn(path: string[], value: any): ImmutableRecordState;
  getIn(path: string[]): any;
}

export const recordSavingLens = Lens.of<ImmutableRecordState, boolean>({
  get: state => state.get('isRecordSaving'),
  set: (state, value) => {
    return state.set('isRecordSaving', value);
  },
});

export const selectedSpecYearLens = Lens.of<ImmutableRecordState, any>({
  get: state => state.getIn(['productSpec', 'selectedSpecYear']),
  set: (state, value) => {
    return state.setIn(['productSpec', 'selectedSpecYear'], value);
  },
});
