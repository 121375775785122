import * as React from 'react';
import { Col, Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME } from 'client/constants';
import * as AttachedCustomerOrdersTableHoc from 'client/app/transportation/routing/attached-customer-orders-table/attached-customer-orders-table-hoc';
import { CustomerOrdersJustTheTable } from 'client/app/transportation/routing/attached-customer-orders-table/just-the-table';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { ApolloRefetch } from 'client/types';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { MutationFunction } from 'react-apollo';

// type OwnProps = ComponentProps;
export interface OwnProps {
  routePlanId: number;
  title: string;
  // refetchData?: ApolloRefetch;
  // dataRequest?: MsyncDataRequest;
  refetchParentTable?: ApolloRefetch;

  customerOrderIds: CustomerOrderId[];
  attachButtonDisabled: boolean;

  handleAddClicked(attachCustomerOrderToRoutePlan, routePlanId: RoutePlanId, customerOrderIds: CustomerOrderId[], refetchParentTable?: ApolloRefetch): void;
  handleCloseClicked(): void;

  attachCustomerOrderToRoutePlanMutation: MutationFunction<any>;
  handleModalUnmounted(): void;
}

export const AttachCustomerOrdersTable = AttachedCustomerOrdersTableHoc.buildCustomerOrdersTable({ component: CustomerOrdersJustTheTable, isInModal: true, alias: 'attach' });

export default class AttachCustomerOrdersModal extends React.Component<OwnProps, {}> {
  handleAddClicked = () => {
    this.props.handleAddClicked(this.props.attachCustomerOrderToRoutePlanMutation, this.props.routePlanId, this.props.customerOrderIds, this.props.refetchParentTable);
  }

  componentWillUnmount() {
    this.props.handleModalUnmounted();
  }

  render() {
    return (
      <Modal
        id="attach-customer-orders-modal"
        animation={false}
        backdrop="static"
        dialogClassName="attach-customer-orders-modal"
        show
        onHide={this.props.handleCloseClicked}>
        <GlobalAsyncModalHeader>{this.props.title}</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <AttachCustomerOrdersTable
              tableName={TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME}
              type="Attach"
              disableCreate={true}
              tablePaginated={true}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={this.props.handleCloseClicked} />
          <GlobalSaveButton
            label="Attach"
            testid={'modal-submit'}
            onClick={this.handleAddClicked}
            disabled={this.props.attachButtonDisabled}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
