import { appendFieldValidators } from 'shared/schemas/dsl';
import { SalesPlan } from 'shared/schemas/sales-plan';
import * as Validators from 'shared/validators';
import { SalesPlanIdentifier } from 'shared/types';

appendFieldValidators(SalesPlan, {
  identifier: [
    Validators.INCLUSION(SalesPlanIdentifier),
  ],
});
