import 'reflect-metadata';
import { tableName, property, definePresentation, belongsTo } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, INPUT_TYPES, TYPES, DateStr, DateTimeStr } from '../types';
import { Store } from './store';
import { Product } from './product';
import { ImportJob } from 'shared/schemas/import-job';
import { IRecord } from 'shared/schemas/record';

@tableName('creditMemos')
export class CreditMemo implements IRecord {
  id?: number;
  @property storeIdentifier: string;

  @belongsTo('stores')
  @property store: Store;
  storeId?: number;

  // We are not sure what is required here - PB/AP (8/31/17)
  @property date: DateStr;
  @property adjustmentNumber: string;
  @property scan: boolean;
  @property transactionCode: string;
  @property transactionDescription: string;
  @property creditDebitCode: string;
  @property creditDebitDescription: string;
  @property vendorNumber: string;
  @property departmentNumber: string;
  @property rgaNumber: string;
  @property noteCode: string;
  @property noteDescription: string;
  @property actionCode: string;
  @property actionDescription: string;
  @property reasonCode: string;
  @property reasonDescription: string;
  @property creditDebitFlag: string;
  @property flagDescription: string;
  @property quantity: number;
  @property price: number;
  @property sku: string;

  @belongsTo('products')
  @property product: Product;
  productId?: number;

  @belongsTo('importJobs')
  @property importJob: ImportJob;
  importJobId?: number;

  @property description: string;

  createdAt?: DateTimeStr;
}

definePresentation(CreditMemo, {
  actionCode: { formDisplay: false, includeInFormQuery: true },
  actionDescription: { formDisplay: false, includeInFormQuery: true },
  adjustmentNumber: { formDisplay: false, includeInFormQuery: true },
  creditDebitCode: { formDisplay: false, includeInFormQuery: true },
  creditDebitDescription: { formDisplay: false, includeInFormQuery: true },
  creditDebitFlag: { formDisplay: false, includeInFormQuery: true },
  date: { formDisplayType: displayType(DISPLAY_TYPES.DATE) },
  departmentNumber: { formDisplay: false, includeInFormQuery: true },
  description: { formDisplay: false, includeInFormQuery: true },
  flagDescription: { formDisplay: false, includeInFormQuery: true },
  importJob: {formDisplay: false },
  noteCode: { formDisplay: false, includeInFormQuery: true },
  noteDescription: { formDisplay: false, includeInFormQuery: true },
  price: { formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }) },
  product: { formDisplayType: displayType(DISPLAY_TYPES.STATIC) },
  quantity: { formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }), type: TYPES.NUMBER },
  reasonCode: { formDisplay: false, includeInFormQuery: true },
  reasonDescription: { formDisplay: false, includeInFormQuery: true },
  rgaNumber: { formDisplay: false, includeInFormQuery: true },
  scan: { type: TYPES.BOOLEAN, formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX) },
  sku: { formDisplay: false, includeInFormQuery: true },
  store: { formDisplayType: displayType(DISPLAY_TYPES.STATIC) },
  storeIdentifier: { formDisplay: false, includeInFormQuery: true },
  transactionCode: { formDisplay: false, includeInFormQuery: true },
  transactionDescription: { formDisplay: false, includeInFormQuery: true },
  vendorNumber: { formDisplay: false, includeInFormQuery: true },
});
