import * as Redux from 'redux';

export type ActionTypes =
  | ProductSelectedAction;

export enum ActionTypeKeys {
  ProductSelected = 'SalesPlanDetail/PRODUCT_SELECTED',
}

export interface ProductSelectedAction extends Redux.Action {
  type: ActionTypeKeys.ProductSelected;
  payload: {
    productId: number;
  };
}

export function handleProductSelected(productId: number): ProductSelectedAction {
  return {
    type: ActionTypeKeys.ProductSelected,
    payload: {
      productId,
    },
  };
}
