const GoogleMapsLoader = require('google-maps');
import * as React from 'react';
import { clientConfig } from '../../../config/client';

export interface MapProps {
  point: LatLong;
}

interface MapState {
  googleMapsInitialized: boolean;
}

export interface ValidLatLong {
  lat: number;
  lng: number;
  valid: true;
}

export interface InvalidLatLong {
  valid: false;
}

export const DefaultLatLong: ValidLatLong = {
  lat: 0,
  lng: 0,
  valid: true,
};

export type LatLong = ValidLatLong | InvalidLatLong;

interface IGoogleMap {
  setCenter(latlng: LatLong ): void;
}

export class Map extends React.Component<MapProps, MapState> {
  public static google: any;
  private root: HTMLDivElement | undefined | null;

  private map: undefined | IGoogleMap;

  private marker: undefined | any;

  constructor(props: MapProps) {
    super(props);

    this.root = undefined;
    this.map = undefined;
    this.marker = undefined;

    this.state = { googleMapsInitialized: false };

    if (!Map.google) {
      GoogleMapsLoader.KEY = clientConfig.googleAPIKey;

      GoogleMapsLoader.load((google: any) => {
        Map.google = google;

        this.setState({ googleMapsInitialized: true });
      });
    }
  }

  public shouldComponentUpdate(nextProps: MapProps, nextState: MapState) {
    let shouldUpdate = false;

    if (nextProps === this.props && nextState === this.state) {
      return false;
    }

    if (!this.root) {
      return false;
    }

    if (!Map.google) {
      return false;
    }

    let point: ValidLatLong;
    if (!this.map) {
      point = this.props.point.valid ? this.props.point : DefaultLatLong;

      this.map = new Map.google.maps.Map(this.root, {
        center: point,
        zoom: 8,
      });

      this.marker = new Map.google.maps.Marker({
        map: this.map,
        position: point,
      });

      shouldUpdate = true;
    }

    if (!this.map) {
      return false;
    }

    point = this.props.point.valid ? this.props.point : DefaultLatLong;

    this.map.setCenter(point);
    this.marker.setPosition(point);

    return shouldUpdate;
  }

  public render() {
    return <div className="map" ref={element => this.root = element} />;
  }
}
