import { connect, ConnectedProps } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { InvoicesTableUI, UIProps, InvoicesListTableRow } from './invoices-table-ui';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableSearchField, AvailableFilter, ActiveSearch } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { InvoicesQueryResponse, GetInvoicesQuery, GetInvoicesExcelQuery } from './query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { FilterSpecificationInput, BooleanFilters, TYPES, InvoiceType } from 'shared/types';
import { FetchPolicy } from 'apollo-client';
import * as Actions from './actions';
import { EMPTY_ARRAY } from 'client/constants';
import * as State from 'client/state/state';
import * as InvoiceState from 'client/state/invoice';
import * as TableState from './table-state';
import { convertSearchAllToSpecificFields } from 'client/helpers/table-helpers';

interface OwnProps {
  dataRequest: MsyncDataRequest;
}

interface StateProps {
  isSendInvoicesToAcumaticaModalShown: boolean;
  isSendInvoicesViaEdiModalShown: boolean;
  selectedInvoiceIds: number[];

  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: FilterSpecificationInput[];
  tablePageNumber: number;
  searchableFields: AvailableSearchField[];
}

const mapStateToProps = (state: State.Type): StateProps => {
  const invoiceListState = InvoiceState.invoiceStateLens(state);
  const invoiceListTableState = TableState.genericTableActions.tableStateLens(state);

  const result = {
    isSendInvoicesToAcumaticaModalShown: InvoiceState.isSendInvoicesToAcumaticaModalShown(invoiceListState),
    isSendInvoicesViaEdiModalShown: InvoiceState.isSendInvoicesViaEdiModalShown(invoiceListState),
    selectedInvoiceIds: InvoiceState.selectedRecordIds(invoiceListState),

    activeSortFields: TableState.genericTableActions.activeSortFields(invoiceListTableState),
    activeSearch: TableState.genericTableActions.activeSearch(invoiceListTableState),
    activeFilters: TableState.genericTableActions.activeFilters(invoiceListTableState),
    tablePageNumber: TableState.genericTableActions.tablePageNumber(invoiceListTableState),
    searchableFields: TableState.genericTableActions.availableSearchFields(invoiceListTableState),
  };

  return result;
};


const mapDispatchToProps = {
  onNewClicked: Actions.newRecordButtonClicked,
  onRowSelected: Actions.rowSelected,

  // Note: because of the way we initialize the row items that use this handler, they'll always use the initial value of this function.
  // We'll have to modify that if we ever want to change what this handler does dynamically
  onSendInvoicesToAcumaticaMenuItemClicked: Actions.handleSendInvoicesToAcumaticaMenuItemClicked,
  onSendInvoicesViaEdiMenuItemClicked: Actions.handleSendInvoicesViaEdiMenuItemClicked,
};

interface WithInvoiceRowProps {
  rows?: InvoicesListTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
}

const BOOLEAN_OPTIONS = [
  { id: BooleanFilters.Yes, value: BooleanFilters.Yes, displayValue: 'Yes' },
  { id: BooleanFilters.No, value: BooleanFilters.No, displayValue: 'No' },
];

const WithInvoiceRows = msyncQuery<InvoicesQueryResponse, OwnProps & StateProps & WithTableParentProps, WithInvoiceRowProps, {}>(GetInvoicesQuery, {
  alias: 'withInvoiceRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps): { variables, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        search: convertSearchAllToSpecificFields(ownProps.activeSearch, ownProps.searchableFields),
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithInvoiceRowProps {
    const { data, ownProps } = props;

    const rows: InvoicesListTableRow[] | undefined = data.getInvoices
      ? data.getInvoices.invoices.map(row => {
        return {
          ...row,
          type: _.startCase(row.type),
          poNumber
            : row.type === InvoiceType.VendorChargeback ? row.supplierOrderIdentifier
            : row.type === InvoiceType.DropShip         ? null
            : row.customerOrder                         ? row.customerOrder.identifier
            : null,
          storeIdentifier
            : row.store ? row.store.identifier
            : null,
          customerIdentifier: row.customer.identifier,
          invoiceTo
            : row.type === InvoiceType.VendorChargeback && row.supplier ? row.supplier.name
            : row.customer.name,
          deliveryDate
            : row.type === InvoiceType.VendorChargeback ? row.supplierOrderPlannedArrivalDate || null
            : row.deliveryDate,
          paidDate
            : row.type === InvoiceType.VendorChargeback ? (row.paidDate || 'pending')
            : null,
        };
      })
      : undefined;

    const filters = _.flatten(ownProps.activeFilters.map(filter => filter.values)).join(', ');
    const workbookName = `Invoices${filters ? ` - ${filters}` : ''}`;

    return {
      availableFilters: [
        {
          displayName: 'Customer',
          field: 'customerIdentifier',
          options: data.getInvoicesFilterOptions ? data.getInvoicesFilterOptions.customers : [],
        },
        {
          displayName: 'Invoice Type',
          field: 'type',
          options: data.getInvoicesFilterOptions ? data.getInvoicesFilterOptions.types : [],
        },
        {
          displayName: 'EDI',
          field: 'ediInvoiced',
          options: BOOLEAN_OPTIONS,
        },
        {
          displayName: 'Acumatica',
          field: 'transferredToAccounting',
          options: BOOLEAN_OPTIONS,
        },
        {
          displayName: 'Invoice Date',
          field: 'invoiceDate',
          type: TYPES.DATE,
        },
      ],
      rows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getInvoices ? data.getInvoices.ids : EMPTY_ARRAY,
      totalCount: data.getInvoices ? data.getInvoices.totalCount : 0,
      totalUnfilteredCount: data.getInvoices ? data.getInvoices.totalUnfilteredCount : 0,
      dataRequest: {
        query: GetInvoicesExcelQuery,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          search: convertSearchAllToSpecificFields(ownProps.activeSearch, ownProps.searchableFields),
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        workbookName,
      },
    };
  },
});

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


export type ComponentProps =
  OwnProps &
  PropsFromRedux &
  WithInvoiceRowProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  connector,
  WithInvoiceRows,
)(InvoicesTableUI) as Component<OwnProps>;

assertCompatible<UIProps, ComponentProps>();
export default (props: OwnProps) => propToComponent(component, props);
