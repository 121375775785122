import * as _ from 'lodash';
import { getFormValues } from 'redux-form';

import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getHmgOpsReportType = (state: State.Type): Constants.HmgOpsReportType =>
  State.getHmgOpsReportType.get(state);

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const customerIds = extractSelectedValues(formValues[Constants.FormFields.customerIds]);
  const supplierOrderIds = extractSelectedValues(formValues[Constants.FormFields.supplierOrderIds]);
  return customerIds.length === 0
    || _.isNil(formValues[Constants.FormFields.supplierId])
    || _.isNil(formValues[Constants.FormFields.beginDate])
    || _.isNil(formValues[Constants.FormFields.endDate])
    || supplierOrderIds.length === 0;
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
