import * as React from 'react';
import BolList from './bol-list/bol-list-container';
import ReceivingWorksheetTable from './worksheet-table/worksheet-table-container';
import AddBolModal from './add-bol-modal/add-bol-modal-container';
import { Button } from 'client/components/third-party';
import { BolId } from 'shared/schemas/bol';
import { ReceivableOrderReceivingStatus } from 'shared/types';

export interface ReceivingWorksheetUIProps {
  receivableOrderId: number;
  requestedBolId?: BolId;
  showEmptyState?: boolean;
  showNothing?: boolean;
  receivedStatus?: ReceivableOrderReceivingStatus;
  onAddBolClicked(): void;
  showSendReceivingReceiptModal(): void;
}

export default class ReceivingWorksheetUI extends React.Component<ReceivingWorksheetUIProps, {}> {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps: ReceivingWorksheetUIProps) {
    // Show the modal if we're transitioning to fully received, and we haven't previously sent an email
    if (this.props.receivedStatus && this.props.receivedStatus !== ReceivableOrderReceivingStatus.FullyReceived && nextProps.receivedStatus === ReceivableOrderReceivingStatus.FullyReceived) {
      this.props.showSendReceivingReceiptModal();
    }
  }

  render() {
    if (this.props.showNothing) {
      return <div />;
    }

    if (this.props.showEmptyState) {
      return (
        <div className="table-empty">
          <div className="placeholder">
            There are currently no BOLs for this order.
        </div>

          <div className="action">
            <Button data-testid="worksheet-add-bol" bsClass="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary" onClick={() => this.props.onAddBolClicked()}>
              Add BOL
          </Button>
          </div>
          <AddBolModal receivableOrderId={this.props.receivableOrderId} />
        </div>
      );
    }

    return (
      <div>
        <div className="mfc-form-left-sidebar">
          <BolList receivableOrderId={this.props.receivableOrderId} requestedBolId={this.props.requestedBolId} />
        </div>
        <div className="mfc-form-details-with-left-sidebar">
          {this.props.requestedBolId && <ReceivingWorksheetTable receivableOrderId={this.props.receivableOrderId} requestedBolId={this.props.requestedBolId} />}
        </div>
      </div>
    );
  }
}
