export function resolveAfterTimeout<T>(impatientPromise: Promise<T>, ms: number, timeoutResolution: T): Promise<T> {
  return new Promise(async (resolve, reject) => {
    impatientPromise.then(resolve).catch(reject);
    await timeout(ms);
    resolve(timeoutResolution);
  });
}

export function rejectAfterTimeout<T>(impatientPromise: Promise<T>, ms: number): Promise<T> {
  return new Promise(async (resolve, reject) => {
    impatientPromise.then(resolve).catch(reject);
    await timeout(ms);
    reject(new Error(`timed out after ${ms}ms`));
  });
}

export function timeout(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
