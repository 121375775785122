import * as _ from 'lodash';
import * as Constants from './constants';
import * as Excel from '../common';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Types from './types';
import * as XLSX from 'xlsx';
import { createSelector } from 'reselect';
import { excludeNils } from 'shared/helpers/andys-little-helpers';

type SalesPlanWorkBook = XLSX.WorkBook;
type SalesPlanWorkSheet = XLSX.WorkSheet;

type SalesPlanWorkSheetData = Excel.WorkSheetData;

export async function getSalesPlanWorkBookFromWorkBookContainer(workBookContainer: ExcelCommon.WorkBookContainer): Promise<SalesPlanWorkBook | null> {
  return Excel.getWorkBookFromWorkBookContainer<SalesPlanWorkBook>(workBookContainer);
}

export const getSalesPlanWorkSheet = (workBook: SalesPlanWorkBook) => Excel.getFirstWorkSheet<SalesPlanWorkSheet>(workBook);
export const getSalesPlanWorkSheetData = (workSheet: SalesPlanWorkSheet) => Excel.getWorkSheetData<SalesPlanWorkSheetData>(workSheet);

export function getHeaderData(workSheetData: SalesPlanWorkSheetData): Partial<Pick<Types.ImportableSalesPlanVarieties, 'customerIdentifier' | 'seasonIdentifier' | 'year'>> {
  const customerIdentifierRow = workSheetData[Constants.CUSTOMER_IDENTIFIER_ROW - 1];
  const productionYearRow = workSheetData[Constants.PRODUCTION_YEAR_ROW - 1];
  const productionSeasonRow = workSheetData[Constants.PRODUCTION_SEASON_ROW - 1];

  const customerIdentifier = Excel.getAsString(customerIdentifierRow[Constants.CUSTOMER_IDENTIFIER_COL]);
  const year = Excel.getAsNumber(productionYearRow[Constants.PRODUCTION_YEAR_COL]);
  const seasonIdentifier = Excel.getAsString(productionSeasonRow[Constants.PRODUCTION_SEASON_COL]);

  return {
    customerIdentifier,
    year,
    seasonIdentifier,
  };
}

const getProductIdentifier = (workSheetData: any[]) => Excel.getAsString(workSheetData[Constants.PRODUCT_IDENTIFIER_ROW - 1][Constants.PRODUCT_IDENTIFIER_COL]);
const getProductDescription = (workSheetData: any[]) => Excel.getAsString(workSheetData[Constants.PRODUCT_DESCRIPTION_ROW - 1][Constants.PRODUCT_DESCRIPTION_COL]);
const createExcelDataSelector = <T>(fn: (data: any[]) => T) => createSelector<shame, shame, T>(_.identity, fn);

const getWeekNumbers = createExcelDataSelector((data: any[]): number[] => {
  return _.values(getWeekNumberRow(data));
});

const getWeekNumberRow = createExcelDataSelector((data: any[]) => {
  const weekNumberRow: any = _.omit(data[Constants.WEEK_NUMBER_ROW - 1], 'A', 'B'); // TS 3.5 upgrade - "any" was placed here
  return _.mapValues(weekNumberRow, v => _.parseInt(v.substr(2)));
});

const getWeekNumberToColumnMap = createExcelDataSelector(data => {
  return _.invert(getWeekNumberRow(data));
});

const getColumnForWeekNumber = (data: any[], weekNumber: number): string => {
  return getWeekNumberToColumnMap(data)[weekNumber];
};

const getTotalPieceQuantityForWeek = (data: any[], weekNumber: number): Int => {
  const column = getColumnForWeekNumber(data, weekNumber);
  const total = Excel.getAsInteger(data[Constants.TOTALS_ROW - 1][column]) || 0;
  return total;
};

const getVarietyNames = createExcelDataSelector((data: any[]): string[] => {
  let currentIndex = Constants.VARIETY_NAME_STARTING_ROW - 1;
  const varietyNames: string[] = [];

  while (true) {
    const row = data[currentIndex];
    if (!row) {
      break;
    }

    const varietyName = row[Constants.VARIETY_NAME_COLUMN];
    if (!varietyName) {
      break;
    }

    varietyNames.push(varietyName);
    currentIndex++;
  }

  return varietyNames;
});

const getVarietiesForWeek = (data: any[], weekNumber: number): Types.Variety[] => {
  const startingVarietyRowIndex = Constants.VARIETY_NAME_STARTING_ROW - 1;
  const column = getColumnForWeekNumber(data, weekNumber);
  const varieties = getVarietyNames(data);

  return excludeNils(varieties.map((variety, index) => {
    const currentRow = data[startingVarietyRowIndex + index];
    const percentage = parseFloat(currentRow[column]);
    if (!percentage) {
      return null;
    }

    return {
      name: currentRow[Constants.VARIETY_NAME_COLUMN],
      mix: currentRow[Constants.VARIETY_MIX_COLUMN] || null,
      percentage,
    };
  }));
};

const getWeeklyInfo = (data: any[]): Types.ImportableSalesPlanVarietyProductWeeklyInfo[] => {
  const weekNumbers = getWeekNumbers(data);

  return excludeNils(weekNumbers.map(weekNumber => {
    const totalPieceQuantity = getTotalPieceQuantityForWeek(data, weekNumber);
    const varieties = getVarietiesForWeek(data, weekNumber);
    if (varieties.length === 0) {
      return null;
    }

    return {
      weekNumber,
      totalPieceQuantity,
      varieties,
    };
  }));
};

const getProduct = (workSheetData: any[]): Partial<Types.ImportableSalesPlanVarietyProduct> => {
  return {
    productDescription: getProductDescription(workSheetData),
    productIdentifier: getProductIdentifier(workSheetData),
    weeklyInfo: getWeeklyInfo(workSheetData),
  };
};

export function getProducts(workBook: XLSX.WorkBook) {
  const sheetNames = Object.keys(workBook.Sheets);
  return sheetNames.map(sheetName => getProduct(Excel.getWorkSheetData(workBook.Sheets[sheetName])));
}
