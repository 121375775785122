import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/supplier-commitment/types';

export interface SupplierCommitment {
  id: GraphQLTypes.SupplierCommitment['id'];
  salesPlan: {
    id: number;
    year: number;
    identifier: string;
    customer: {
      id: number;
      identifier: string;
    };
    sellDepartment: {
      id: number;
      identifier: string;
    };
  };
  supplier: Pick<GraphQLTypes.SupplierCommitment['supplier'], 'id' | 'name'>;
  confirmed: GraphQLTypes.SupplierCommitment['confirmed'];
  lastModifiedAt: GraphQLTypes.SupplierCommitment['lastModifiedAt'];
  lastModifiedUser?: {
    firstName: string;
    lastName: string;
  };
}

export const SupplierCommitmentFragment = gql`
  fragment SupplierCommitmentFragment on SupplierCommitment {
    id
    salesPlan {
      id
      year
      identifier
      customer {
        id
        name
      }
      sellDepartment {
        id
        identifier
      }
    }
    confirmed
    supplier {
      id
      name
    }
    lastModifiedAt
    lastModifiedUser {
      id
      firstName
      lastName
    }
  }
`;

export interface QueryResponse {
  supplierCommitment: SupplierCommitment;
}

export const Query = gql`
  query GetSupplierCommitment($supplierCommitmentId: Int!) {
    supplierCommitment: GetSupplierCommitment(id: $supplierCommitmentId) {
      ...SupplierCommitmentFragment
    }
  }
  ${SupplierCommitmentFragment}
`;
