import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { displayType } from './dsl';
import { ImportableDocumentDetail } from 'shared/schemas/importable-document-detail';
import { ImportJob } from 'shared/schemas/import-job';
import { IRecord } from 'shared/schemas/record';

@tableName('importJobDetails')
export class ImportJobDetail implements IRecord {
  id?: number;

  @property @required imported: boolean;
  @property messages?: string[] | null;

  @belongsTo('importableDocumentDetails')
  @property @required importableDocumentDetail?: ImportableDocumentDetail;
  importableDocumentDetailId: number;

  @belongsTo('importJobs')
  @property @required importJob?: ImportJob;
  importJobId: number;
}

definePresentation(ImportJobDetail, {
  imported: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  messages: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  importableDocumentDetail: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  importJob: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
});
