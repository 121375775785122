import * as React from 'react';
import { Modal } from 'client/components/third-party';
import { ProgressSpinner } from 'client/components/progress-spinner';

export interface ComponentProps {
  show: boolean;
  message?: string;
}

export class BusyModalUI extends React.PureComponent<ComponentProps, {}> {

  handleHide = () => {
    // Do nothing
  }

  render() {
    return (
      <Modal show={this.props.show} dialogClassName="busy-modal-dialog" onHide={this.handleHide} data-testid="busy-modal" className="busy-modal" backdropClassName="busy-modal-backdrop">
      <Modal.Body>
        <div className="alert alert-info">
          <ProgressSpinner label={this.props.message || 'Processing...'} />
        </div>
      </Modal.Body>
    </Modal>
    );
  }
}
