import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { loginUser, appMounted } from '../actions/session';

import Content from '../components/content';
import LoginModal from '../components/login/login-modal';
import Navigator from '../components/navigator';
import { PrimaryNavLink, SecondaryNavLink, TertiaryNavLink } from '../components/navigator/navigator-item';
import ErrorAlert from 'client/components/error-alert';
import { BusyModal } from 'client/components/busy-modal';
import { SessionState, SessionStatus } from 'client/reducers/session';
import { ErrorBoundary } from 'client/containers/error-boundary';

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: () => dispatch(loginUser()),
    appMounted: () => dispatch(appMounted()),
  };
}

interface OwnProps {
  children?: React.ReactNode;
}

interface StateProps {
  session: SessionState;
}

interface DispatchProps {
  login: () => void;
  appMounted: () => void;
}

const connector = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
);

type Props = OwnProps & ConnectedProps<typeof connector>;

class App extends React.PureComponent<Props, {}> {
  componentDidMount() {
    this.props.appMounted();
  }

  render() {
    const { children, session, login } = this.props;
    const token = session.sessionStatus === SessionStatus.SignedIn && session.user && session.user.token;
    const isLoggedIn = !!(token && token !== null && typeof token !== 'undefined');

    const appElement = (
      <div>
        <BusyModal />
        <ErrorAlert />
        <LoginModal
          onSubmit={ login }
          isPending={ session.isLoading }
          hasError={ session.hasError }
          isVisible={ !isLoggedIn } />
        <Navigator testid="navigator" isLoggedIn={isLoggedIn}>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/planning" title="Planning">
            <SecondaryNavLink route="/planning/sales" title="Sales Plans"/>
            <SecondaryNavLink route="/planning/supplier-commitments" title="Supplier Commitments"/>
            <SecondaryNavLink title="Reports">
              <TertiaryNavLink route="/planning/reports/difference-calculation-report" title="Difference Calcuation Report"/>
              <TertiaryNavLink route="/planning/reports/sales-plan-varieties-export" title="Sales Plan Varieties Export"/>
              <TertiaryNavLink route="/planning/reports/supplier-future-commitment" title="Supplier Future Commitment Report"/>
              <TertiaryNavLink route="/planning/reports/hardgoods-data-export" title="Hardgoods Data Export"/>
            </SecondaryNavLink>
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/orders" title="Orders">
            <SecondaryNavLink route="/orders/customer" title="Customer Orders"/>
            <SecondaryNavLink route="/orders/supplier" title="Supplier Orders"/>
            <SecondaryNavLink title="Reports">
              <TertiaryNavLink route="/orders/reports/replenishment-by-store" title="Replenishment By Store"/>
              <TertiaryNavLink route="/orders/reports/replenishment-by-product" title="Replenishment By Product"/>
              <TertiaryNavLink route="/orders/reports/store-on-hand" title="Store On Hand"/>
              <TertiaryNavLink route="/orders/reports/cut-processing" title="Cut Processing"/>
            </SecondaryNavLink>
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/transportation" title="Transportation" testid="transportation-link">
            <SecondaryNavLink route="/transportation/receiving" title="Receiving"/>
            <SecondaryNavLink route="/transportation/distribution-receiving" title="MICS"/>
            <SecondaryNavLink route="/transportation/routing" title="Route Plans"/>
            <SecondaryNavLink route="/transportation/loads" title="Load List"/>
            <SecondaryNavLink route="/transportation/cartTracking" title="Cart Tracking" />
            <SecondaryNavLink title="Reports">
              <TertiaryNavLink route="/transportation/reports/hmg-ops" title="HMG Ops" />
              <TertiaryNavLink route="/transportation/reports/load-tracking-list" title="Load Tracking List" />
              <TertiaryNavLink route="/transportation/reports/shipping-status-warehouse-report" title="Shipping Status Warehouse Report" />
              <TertiaryNavLink route="/transportation/reports/cart-estimate-report" title="Cart Estimate Report" />
            </SecondaryNavLink>
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/billing" title="Billing">
            <SecondaryNavLink route="/billing/invoicing" title="Invoicing" />
            <div /> {/* horrible, right? try removing and see how badly it messes up the rendering 🤪 */}
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/admin" title="Admin" testid="admin-link">
            <SecondaryNavLink title="Customers" testid="customer-link">
              <TertiaryNavLink route="/admin/customers" title="Customers"/>
              <TertiaryNavLink route="/admin/mfcAreas" title="MFC Areas"/>
              <TertiaryNavLink route="/admin/regions" title="Regions"/>
              <TertiaryNavLink route="/admin/markets" title="Markets"/>
              <TertiaryNavLink route="/admin/stores" title="Stores" testid="store-link"/>
              <TertiaryNavLink route="/admin/merchandisers" title="Merchandising" testid="merchandiser-link"/>
            </SecondaryNavLink>
            <SecondaryNavLink title="Suppliers" testid="supplier-link">
              <TertiaryNavLink route="/admin/suppliers" title="Suppliers"/>
              <TertiaryNavLink route="/admin/supplierItems" title="Supplier Items"/>
              <TertiaryNavLink route="/admin/supplierLocations" title="Supplier Locations"/>
            </SecondaryNavLink>
            <SecondaryNavLink title="Products" testid="products-dropdown">
              <TertiaryNavLink route="/admin/products" title="Products"/>
              <TertiaryNavLink route="/admin/sellDepartments" title="Sell Departments"/>
              <TertiaryNavLink route="/admin/productClasses" title="Product Classes"/>
              <TertiaryNavLink route="/admin/productSubClasses" title="Product Sub Classes" />
              <TertiaryNavLink route="/admin/tags" title="Tags/Labels"/>
              <TertiaryNavLink route="/admin/trays" title="Trays"/>
              <TertiaryNavLink route="/admin/pots" title="Pots"/>
              <TertiaryNavLink route="/admin/boxes" title="Boxes" testid="boxes-link"/>
              <TertiaryNavLink route="/admin/buckets" title="Buckets" testid="buckets-link"/>
              <TertiaryNavLink route="/admin/productToss" title="Product Toss" testid="product-toss-link"/>
            </SecondaryNavLink>
            <SecondaryNavLink title="Transportation" testid="transportation-dropdown">
              <TertiaryNavLink route="/admin/mileage-rates" title="Mileage Rates"/>
              <TertiaryNavLink route="/admin/fuel-surcharge-rates" title="Fuel Surcharge Rates"/>
              <TertiaryNavLink route="/admin/carrier-rates" title="Carrier Rates" />
            </SecondaryNavLink>
            <SecondaryNavLink route="/admin/vendors" title="Dist. Vendors" testid="vendors-link" />
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn } to="/reports" title="Reports">
            <SecondaryNavLink route="/reports/customer-performance" title="Customer Performance"/>
            <SecondaryNavLink route="/reports/supplier-performance" title="Supplier Performance"/>
            <SecondaryNavLink route="/reports/buyer" title="Buyer"/>
            <SecondaryNavLink title="Sales" testid="sales-dropdown">
              <TertiaryNavLink route="/reports/daily-sales" title="Daily Sales" testid="daily-sales-link"/>
              <TertiaryNavLink route="/reports/mobile-sales-performance" title="Mobile Sales Performance" testid="mobile-sales-performance-link"/>
            </SecondaryNavLink>
          </PrimaryNavLink>
          <PrimaryNavLink isVisible={ isLoggedIn && session.user && session.user.firstName === 'David' && session.user.lastName === 'McKay' } to="/lab" title="Lab">
            <SecondaryNavLink route="/lab/quick-data-table" title="Quick Table"/>
          </PrimaryNavLink>
        </Navigator>
        <Content isVisible={ isLoggedIn }>
          <ErrorBoundary>
            { children }
          </ErrorBoundary>
        </Content>
      </div>
    );

    if (session.sessionStatus === SessionStatus.Unknown) {
      // TODO: loading spinner?
      return <div />;
    } else {
      return appElement;
    }
  }
}

export default connector(App);
