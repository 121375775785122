import * as React from 'react';
import { Modal } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { DateStr, CELL_TYPES, TYPES } from 'shared/types';
import { noop } from 'lodash';
import { buildDataTable, OwnProps as DataTableProps } from 'client/containers/table/basic-table';
import { APPLY_LOADS_TO_TRAILERS_TABLE } from 'client/constants';
import { IColumn } from 'client/components/table/column';
import * as _ from 'lodash';
import { FieldValidator } from 'shared/validators';

interface RoutingLoad {
  id: number;
  identifier: string;
  deliveryDate: DateStr;
  customerNames: string;
  mfcAreaIdentifiers: string;
  dropsCombinedWithAdditionalDrops: number;
  trailerNumber: string;
}

const TrailerNumberValidator = (theProps: { content: RoutingLoad[], trailerNumbers: TrailerNumbers }): FieldValidator => ({
  isValid(newTrailerNumber: string, currentRoutingLoad: RoutingLoad) {
    const routingLoads = theProps.content || [];
    const currentRoutingLoadId = currentRoutingLoad.id;

    // Find all other routing loads in the table that are delivered on the same date as the currently selected routing load.
    const relevantRoutingLoadIds = routingLoads.filter(x => x.id !== currentRoutingLoadId && x.deliveryDate === currentRoutingLoad.deliveryDate).map(x => x.id);

    // Find same routing loads as found above, this time in redux. These are "unsaved" values not yet persisted to the database.
    const routingLoadIdsInRedux = Object.keys(theProps.trailerNumbers) .map(_.parseInt) .filter(x => relevantRoutingLoadIds.includes(x));

    // Find the relevant trailer numbers from redux.
    const trailerNumbersInRedux = routingLoadIdsInRedux .map(x => theProps.trailerNumbers[x]);

    // Find the trailer numbers from the database, excluding trailer numbers already found in redux. The values in redux take precedence to those in the database.
    const trailerNumbersInDatabase = routingLoads .filter(x => relevantRoutingLoadIds.includes(x.id) && !routingLoadIdsInRedux.includes(x.id)) .map(x => x.trailerNumber);

    // The new trailer number from the currently selected routing load.
    const newTrailerNumbers = [newTrailerNumber];

    // Place all trailer numbers into a single array and remove falsy values.
    const allTrailerNumbers = _.compact(_.concat( trailerNumbersInDatabase, trailerNumbersInRedux, newTrailerNumbers ));
    return !(allTrailerNumbers.filter(x => x === newTrailerNumber).length > 1);
  },
  shortMessage(newTrailerNumber: any, record: any) { return `The trailer number ${newTrailerNumber} has already been assigned to another load on this date.`; },
  message(label: string, newTrailerNumber: any, record: any) { return `The trailer number ${newTrailerNumber} has already been assigned to another load on this date.`; },
});

export interface ComponentProps {
  isShown: boolean;
  routingLoadIds: RoutingLoadId[];
  handleCancelButtonClicked(): void;
  handleAssignLoadsToTrailersAssignButtonClicked(trailerNumbers: TrailerNumbers): void;
  routingLoads: RoutingLoad[];
  loading: boolean;
  getTrailerNumber(routingLoadId: RoutingLoadId): string | undefined;
  setTrailerNumber(routingLoadId: RoutingLoadId, trailerNumber: string): void;
  trailerNumbers: TrailerNumbers;
  selectFirstTrailerNumber(): void;
  hasInvalidCell: boolean;
}

const tableParentInfo = { containerWidth: 658, containerHeight: 400, adjustTableDimensions: noop };
export interface TrailerNumbers { [routingLoadId: number]: string; }
export class AssignLoadsToTrailersModalUI extends React.PureComponent<ComponentProps, {}> {
  // private readonly DataTable: React.ComponentClass<DataTableProps>;
  private readonly DataTable: (props: DataTableProps) => React.ReactElement<DataTableProps>;
  constructor(props: ComponentProps) { super(props); this.DataTable = buildDataTable(APPLY_LOADS_TO_TRAILERS_TABLE); }
  handleAssignLoadsToTrailersAssignButtonClicked = () => this.props.handleAssignLoadsToTrailersAssignButtonClicked(this.props.trailerNumbers);
  private columns: IColumn[] = [
    { id: 'identifier'        , accessor: 'identifier'                      , header: 'Load #'             , tableEditable: false, columnWidth: 15, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
    { id: 'deliveryDate'      , accessor: 'deliveryDate'                    , header: 'Store Delivery Date', tableEditable: false, columnWidth: 20, sortable: false, cellType: CELL_TYPES.DATE                         , type: TYPES.DATE   },
    { id: 'customerNames'     , accessor: 'customerNames'                   , header: 'Customer'           , tableEditable: false, columnWidth: 25, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
    { id: 'mfcAreaIdentifiers', accessor: 'mfcAreaIdentifiers'              , header: 'MFC Area'           , tableEditable: false, columnWidth: 17, sortable: false, cellType: CELL_TYPES.TEXT                         , type: TYPES.STRING },
    { id: 'drops'             , accessor: 'dropsCombinedWithAdditionalDrops', header: 'Stops'              , tableEditable: false, columnWidth: 8 , sortable: false, cellType: CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED, type: TYPES.NUMBER },
    {
      id: 'trailerNumber', accessor: 'trailerNumber', header: 'Trailer #', tableEditable: true, columnWidth: 15, sortable: false, cellType: CELL_TYPES.TEXT, type: TYPES.STRING,
      validators: [ TrailerNumberValidator ],
      onSave: (currentRoutingLoadId: RoutingLoadId, newTrailerNumber: string) => { this.props.setTrailerNumber(currentRoutingLoadId, newTrailerNumber.trim()); },
      options: { clearSaveErrorBeforeSave: true, clearSaveErrorOnChange: true, blurOnSaveLastRow: true },
    },
  ];

  componentWillReceiveProps(nextProps: ComponentProps) {
    if (this.props.loading === true && nextProps.loading === false)
      this.props.selectFirstTrailerNumber();
  }
  render() {
    return !this.props.isShown ? <div/> : (
      <Modal
        id="assign-loads-to-trailers-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="assign-loads-to-trailers-modal-container"
        className="assign-loads-to-trailers-modal"
        keyboard={false}>
        <GlobalAsyncModalHeader>Assign Trailers</GlobalAsyncModalHeader>
        <Modal.Body>

          <this.DataTable
            content={this.props.routingLoads.map(rl => ({ ...rl, trailerNumber: this.props.trailerNumbers[rl.id] || rl.trailerNumber }))}
            loading={this.props.loading}
            columns={this.columns}
            totalCount={this.props.routingLoads.length}
            onSort={noop}
            filteredRecordIds={[]}
            setTablePageNumber={noop}
            tablePageNumber={1}
            tablePaginated={false}
            tableParentInfo={tableParentInfo}
            noDataText="No Loads"
            tableClassName="assign-loads-to-trailers-table"
            {...{ trailerNumbers: this.props.trailerNumbers }} // Somebody is going to kill me for this one?
          />

        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton testid="assign-loads-to-trailers-modal-cancel-button" onClick={this.props.handleCancelButtonClicked} />
          <GlobalSaveButton label="Assign" testid="assign-loads-to-trailers-modal-create-button" classNames={['mfc-button-primary']} disabled={this.props.hasInvalidCell} onClick={this.handleAssignLoadsToTrailersAssignButtonClicked} />
        </Modal.Footer>
      </Modal >
    );
  }
}
