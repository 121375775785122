import { createSelector } from 'reselect';
import * as Types from './types';
import * as Constants from './constants';
import * as _ from 'lodash';

export function getComponentWidth(props: Types.UIProps) {
  return props.width;
}

export function getHeaderHeight() {
  return Constants.HEADER_ROW_HEIGHT;
}

export function getFooterHeight(props: Types.UIProps) {
  return props.showFooter ? Constants.FOOTER_ROW_HEIGHT : 0;
}

export function getTableNameFromProps(props: Types.UIProps) {
  return props.tableName;
}

export function getRowHeight() {
  return Constants.ROW_HEIGHT;
}

export function getDataFromProps(props: Types.UIProps) {
  return props.data;
}

export function getColumnsFromProps(props: Types.UIProps) {
  return props.columns;
}

export const getItemDataForHeaderCell = createSelector([
  getTableNameFromProps,
  getColumnsFromProps,
], (tableName, columns) => {
  return createSelector([getCurrentSort], currentSort => {
    return createSelector([_.identity], headerClickHandler => {
      return {
        tableName,
        columns,
        headerClickHandler,
        currentSort,
      };
    });
  });
});

export function getClickHandlerFromProps(props: Types.UIProps) {
  return props.onRowClicked;
}

export const getCurrentSort = state => state.currentSort;

export function getComponentHeight(props: Types.UIProps) {
  return props.height;
}

export const getBodyHeight = (props: Types.UIProps) => {
  return getComponentHeight(props) - getHeaderHeight() - getFooterHeight(props);
};

export const getRowCount = createSelector([
  getDataFromProps,
], data => data.length);

export const getTableWidth = createSelector([
  getComponentWidth,
], componentWidth => componentWidth);

export const getTableTopperHeight = (props: Types.UIProps, tableTopperRef: React.MutableRefObject<any>) => {
  return tableTopperRef && tableTopperRef.current
    ? (tableTopperRef.current as shame).clientHeight
    : 0;
};

export const getHeaderRowHeight = () => Constants.ROW_HEIGHT;
export const getFooterRowHeight = (props: Types.UIProps) => props.showFooter ? Constants.ROW_HEIGHT : 0;
export const getBorderHeight = () => 1;

export const getOverlayHeight = createSelector([
  getBodyHeight,
  getFooterRowHeight,
  getBorderHeight,
], (bodyHeight, footerHeight, borderHeight) => bodyHeight + footerHeight + borderHeight - 10);

export const getOnHorizontalScrollFunction = (props: Types.UIProps) => props.onHorizontalScroll;
export const getOnVerticalScrollFunction = (props: Types.UIProps) => props.onVerticalScroll;
export const getHorizontalScrollPosition = (props: Types.UIProps) => props.horizontalScrollPosition;
export const getVerticalScrollPosition = (props: Types.UIProps) => props.verticalScrollPosition;
