import { RoutePlanGQLResult, RoutePlanFilterOption } from 'schema/route-plan/route-plan-typescript-types';
import gql from 'graphql-tag';

export interface RoutePlansQueryResponse {
  getRoutePlans?: {
    routePlans: RoutePlanGQLResult[];
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
  getRoutePlansListPageFilterOptions?: {
    customers: RoutePlanFilterOption[];
    mfcAreas: RoutePlanFilterOption[];
    statuses: RoutePlanFilterOption[];
  };
}

export const RoutePlanFragment = gql`
  fragment RoutePlanFragment on RoutePlan {
    id
    identifier
    customerIdentifier
    customerName
    deliveryDate
    status
    mfcAreaIdentifier
    loadCount
    stopCount
    lastModified
    origin
    storeCount
    racks
    pallets
  }
`;

export const GetRoutePlansQuery = gql`
  query GetRoutePlanWithFilterOption($sort: [RoutePlanSortInput], $filters: RoutePlanFilterInput, $search: RoutePlanSearchInput, $limit: Int, $offset: Int, $scope: RoutePlanFilterInput) {
    getRoutePlans: GetRoutePlans(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routePlans {
        ...RoutePlanFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
    getRoutePlansListPageFilterOptions: GetRoutePlansListPageFilterOptions(scope: $scope) {
      customers {
        id
        value
        displayValue
      }
      mfcAreas {
        id
        value
        displayValue
      }
      statuses {
        id
        value
        displayValue
      }
    }
  }
  ${RoutePlanFragment}
`;

export const GetRoutePlansExcelQuery = gql`
  query GetRoutePlansExcel($sort: [RoutePlanSortInput], $filters: RoutePlanFilterInput, $search: RoutePlanSearchInput, $limit: Int, $offset: Int, $scope: RoutePlanFilterInput) {
    GetRoutePlans(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routePlans {
        ...RoutePlanFragment
      }
    }
  }
  ${RoutePlanFragment}
`;
