import { noop } from 'lodash';
import * as React from 'react';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES } from 'shared/types';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME, EMPTY_ARRAY } from 'client/constants';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { COLUMN_AGGREGATE_SUM } from 'client/components/table/table-helpers';
import { createSelector } from 'reselect';
import { RacksFooter } from './racks-footer';
import * as _ from 'lodash';
import * as Validators from 'shared/validators';
import { AddStoreModal } from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { ImportDistributionRackShippingWorksheetModal } from './import-distribution-rack-shipping-worksheet-modal';
import { ButtonClickHandler } from 'client/types';

export interface ReviewStoresTableRow {
  customer: string;
  customerIdentifier: string;
  storeNumber: string;
  storeAddress: string;
  palletBoxes: number;
  computedPallets: number;
  pallets: number;
  rackBoxes: number;
  computedRacks: number;
  racks: number;
}

export interface OwnProps {
  routePlanId: RoutePlanId | undefined;
  rows: ReviewStoresTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  tableParentInfo: TableParentInfo;
  dataRequest: MsyncDataRequest;
  overridePalletsSaved(routePlanId: RoutePlanId | undefined, storeId: number, pallets: number): void;
  overrideRacksSaved(routePlanId: RoutePlanId | undefined, storeId: number, racks: number): void;
  tableEditable: boolean;
  onNewClicked(): void;
  removeStoresFromRoutePlan(routePlanId?: RoutePlanId, storeIds?: number[]): void;
  importDistributionRackShippingSpreadsheetButtonClicked: (storeIdentifiersInRoutePlan: string[]) => void;
}

const getRoutePlanId          = (props: OwnProps) => props.routePlanId         ;
const getOverridePalletsSaved = (props: OwnProps) => props.overridePalletsSaved;
const getOverrideRacksSaved   = (props: OwnProps) => props.overrideRacksSaved  ;
const getTableIsEditable      = (props: OwnProps) => props.tableEditable       ;
const columnSelector = createSelector([getRoutePlanId, getOverridePalletsSaved, getOverrideRacksSaved, getTableIsEditable], (routePlanId: RoutePlanId | undefined, overridePalletsSaved, overrideRacksSaved, tableEditable): IColumn[] => ([
  { id: 'customer'        , accessor: 'customer'        , header: 'Customer'            , tableEditable: false, columnWidth: 10 , sortable: true , cellType: CELL_TYPES.TEXT            , type: TYPES.STRING                                },
  { id: 'storeNumber'     , accessor: 'storeNumber'     , header: 'Store #'             , tableEditable: false, columnWidth: 8  , sortable: true , cellType: CELL_TYPES.TEXT            , type: TYPES.STRING                                },
  { id: 'storeAddress'    , accessor: 'storeAddress'    , header: 'Address'             , tableEditable: false, columnWidth: 30 , sortable: true , cellType: CELL_TYPES.TEXT            , type: TYPES.STRING                                },
  { id: 'palletBoxes'     , accessor: 'palletBoxes'     , header: 'Pallet Box Qty'      , tableEditable: false, columnWidth: 7  , sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.FLOAT  , footer: COLUMN_AGGREGATE_SUM },
  { id: 'computedPallets' , accessor: 'computedPallets' , header: 'Computed Pallet Qty' , tableEditable: false, columnWidth: 10 , sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.FLOAT  , footer: COLUMN_AGGREGATE_SUM },
  { id: 'pallets'         , accessor: 'pallets'         , header: 'Pallet Qty'          , tableEditable       , columnWidth: 10 , sortable: false, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER , footer: COLUMN_AGGREGATE_SUM  ,
    validators: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
    onSave: (id, pallets) => overridePalletsSaved(routePlanId, id, _.parseInt(pallets) || 0),
    getClassNames: (val: number, row: any) => row.isOverriddenPallets ? 'value-overridden' : undefined,
  },
  { id: 'rackBoxes'    , accessor: 'rackBoxes'    , header: 'Rack Box Qty'     , tableEditable: false, columnWidth: 7 , sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.FLOAT , footer: COLUMN_AGGREGATE_SUM },
  { id: 'computedRacks', accessor: 'computedRacks', header: 'Computed Rack Qty', tableEditable: false, columnWidth: 10, sortable: true , cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.FLOAT , footer: COLUMN_AGGREGATE_SUM },
  { id: 'racks'        , accessor: 'racks'        , header: 'Rack Qty'         , tableEditable       , columnWidth: 10, sortable: false, cellType: CELL_TYPES.DASH_ZERO_NUMBER, type: TYPES.NUMBER, footer: COLUMN_AGGREGATE_SUM,
    validators: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
    onSave: (id, racks) => overrideRacksSaved(routePlanId, id, _.parseInt(racks) || 0),
    getClassNames: (val: number, row: any) => row.isOverriddenRacks ? 'value-overridden' : undefined,
    // Without this, the table doesn't properly refresh, so the yellow highlighting doesn't update correctly.
    // Once the team can figure out proper table re-rendering, we can get rid of this.
    // This component will refetch all the table rows, so the whole table will re-render because the content is updated.
    footerComponent: RacksFooter,
  }]
));

export class ReviewStoresTableUI extends React.PureComponent<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private headerMenuItems: RowMenuItem[];
  private rowMenuItems: RowMenuItem[];
  private buttons: Array<{ label: string, onClick: ButtonClickHandler, testid?: string }>;
  private removeStores = (ids: number[]) => this.props.removeStoresFromRoutePlan(this.props.routePlanId, ids);
  constructor(props: OwnProps) {
    super(props);
    this.FilterableTable = buildFilterableTable(TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME);
    this.headerMenuItems = [ { label: 'Remove Stores', onClick: this.removeStores, shouldDisplay: () => true, willRemove: true } ];
    this.rowMenuItems    = [ { label: 'Remove Store' , onClick: this.removeStores, shouldDisplay: () => true, willRemove: true } ];
    this.buttons = [
      {
        label: 'Upload Worksheet',
        onClick: () => {
          if (this.props.routePlanId) {
            const storeIdentifiersInRoutePlan = this.props.rows.map(row => `${row.customerIdentifier}-${row.storeNumber}`);
            this.props.importDistributionRackShippingSpreadsheetButtonClicked(storeIdentifiersInRoutePlan);
          }
        },
        testid: 'upload-worksheet-button',
      },
      {
        label: 'Add Store',
        onClick: this.props.onNewClicked,
      },
    ];
  }

  render = () => (
    <div>
      {this.props.routePlanId && <AddStoreModal routePlanId={this.props.routePlanId} /> }
      {this.props.routePlanId && <ImportDistributionRackShippingWorksheetModal routePlanId={this.props.routePlanId} /> }
      <div className="review-stores-table-wrapper">
        <this.FilterableTable
          table={TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME}
          content={this.props.rows}
          loading={this.props.loading}
          columns={columnSelector(this.props)}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={noop}
          loadMoreRecords={noop}
          searchableFields={EMPTY_ARRAY}
          availableFilters={EMPTY_ARRAY}
          placeholder="There are currently no stores to review in this Route Plan"
          checkable
          tablePaginated={false}
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          dataRequest={this.props.dataRequest}
          headerMenuItems={this.headerMenuItems}
          rowMenuItems={this.rowMenuItems}
          buttons={this.buttons}
        />
      </div>
    </div>
  );
}
