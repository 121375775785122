import { connect, MapDispatchToProps } from 'react-redux';
import * as Actions from 'client/actions/route-plan-printing';
import { propToComponent } from 'client/hoc/hoc';
import { CreateRoutePacketModalUI, ComponentProps as UIProps } from './create-route-packet-modal-ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as RoutePlanPrintingSelectors from 'client/state/route-plan-printing-selectors';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Query from './create-route-packet-query';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  routePlanId?: RoutePlanId;
}

interface StateProps {
  isShown: boolean;
  createButtonEnabled: boolean;

  routeSheetsChecked: boolean;
  deliveryAcknowledgementsChecked: boolean;
  purchaseOrderInvoicesChecked: boolean;
  returnInstructionsChecked: boolean;
  specialInstructionsChecked: boolean;
  markOrdersAsShippedChecked: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const isShown = RoutePlanPrintingSelectors.isCreateRoutePacketModalShown(state);
  const routeSheetsChecked = RoutePlanPrintingSelectors.isRoutePacketRouteSheetsChecked(state);
  const deliveryAcknowledgementsChecked = RoutePlanPrintingSelectors.isRoutePacketDeliveryAcknowledgementsChecked(state);
  const purchaseOrderInvoicesChecked = RoutePlanPrintingSelectors.isRoutePacketPurchaseOrderInvoicesChecked(state);
  const returnInstructionsChecked = RoutePlanPrintingSelectors.isRoutePacketReturnInstructionsChecked(state);
  const specialInstructionsChecked = RoutePlanPrintingSelectors.isRoutePacketSpecialInstructionsChecked(state);
  const markOrdersAsShippedChecked = RoutePlanPrintingSelectors.isRoutePacketMarkOrdersAsShippedChecked(state);

  return {
    isShown,
    createButtonEnabled: routeSheetsChecked || deliveryAcknowledgementsChecked || purchaseOrderInvoicesChecked || returnInstructionsChecked || specialInstructionsChecked || markOrdersAsShippedChecked,
    routeSheetsChecked,
    deliveryAcknowledgementsChecked,
    purchaseOrderInvoicesChecked,
    returnInstructionsChecked,
    specialInstructionsChecked,
    markOrdersAsShippedChecked,
  };
};

interface DispatchProps {
  handleCancelButtonClicked(): void;
  handleCreateButtonClicked(args: { routePlanId?: RoutePlanId, specialInstructionsPdfFile?: File | null, customerOrderIdsToBeInvoiced: number[] }): void;

  routeSheetsClicked(): void;
  deliveryAcknowledgementsClicked(): void;
  purchaseOrderInvoicesClicked(): void;
  purchaseOrderInvoicesChanged(selected: boolean): void;
  returnInstructionsClicked(): void;
  specialInstructionsClicked(): void;
  markOrdersAsShippedClicked(): void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCancelButtonClicked: Actions.createRoutePacketModalCancelButtonClicked,
  handleCreateButtonClicked: (args: { routePlanId?: RoutePlanId, specialInstructionsPdfFile?: File | null, customerOrderIdsToBeInvoiced: number[] }) => {
    return Actions.createRoutePacketModalCreateButtonClicked(args);
  },
  routeSheetsClicked: Actions.routeSheetsClicked,
  deliveryAcknowledgementsClicked: Actions.deliveryAcknowledgementsClicked,
  purchaseOrderInvoicesClicked: Actions.purchaseOrderInvoicesClicked,
  purchaseOrderInvoicesChanged: Actions.purchaseOrderInvoicesChanged,
  returnInstructionsClicked: Actions.returnInstructionsClicked,
  specialInstructionsClicked: Actions.specialInstructionsClicked,
  markOrdersAsShippedClicked: Actions.markOrdersAsShippedClicked,
};

export type ComponentProps =
  OwnProps &
  StateProps &
  Query.WithCustomerOrdersForRoutePlanProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Query.withCustomerOrdersForRoutePlan,
)(CreateRoutePacketModalUI as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
