import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { ShippingUnitType, OrderMethod } from 'shared/types';
import { BolAutoCartTrackingEnabled } from 'shared/schemas/bol';

export function cartTrackingEnabledForOrder(args: { supplierCartTrackingStatus: SupplierCartTrackingStatus, shippingUnitType: ShippingUnitType, orderMethod: OrderMethod }): boolean {
  return args.shippingUnitType === ShippingUnitType.Rack
    && args.orderMethod === OrderMethod.ShippingUnit
    && args.supplierCartTrackingStatus === SupplierCartTrackingStatus.Track;
}

interface AutoCartTrackingDisplayArgs {
  supplierCartTrackingStatus: SupplierCartTrackingStatus;
  shippingUnitType: ShippingUnitType;
  orderMethod: OrderMethod;
  autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
}

export function showAutoCartTrackingWarning(args: AutoCartTrackingDisplayArgs): boolean {
  // We show a warning (or hide a field) for a valid order (rack based order, tracked supplier)
  // AND
  // creating a new bol (when autoCartTrackingEnabled is undefined)
  // OR
  // a bol with data integrity (when autoCartTrackingEnabled is true)
  return cartTrackingEnabledForOrder({ supplierCartTrackingStatus: args.supplierCartTrackingStatus, shippingUnitType: args.shippingUnitType, orderMethod: args.orderMethod })
    && !args.autoCartTrackingEnabled;
}
