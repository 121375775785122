import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, required } from './dsl';
import { Product, ProductId } from './product';
import { Store, StoreId } from './store';
import { DateStr } from 'shared/types';
import { ImportJob } from 'shared/schemas/import-job';
import { IRecord } from 'shared/schemas/record';

@tableName('salesActivities')
export class SalesActivity implements IRecord {
  id?: number;

  @property upc?: string | null;
  @property @required sellDate: DateStr;
  @property @required quantitySold: number;
  @property expectedPrice?: number | null;
  @property reportedPrice?: number | null;
  @property expectedRetail?: number | null;
  @property reportedRetail?: number | null;

  @belongsTo('products')
  @property @required product: Product;
  productId: ProductId;

  @belongsTo('stores')
  @property @required store: Store;
  storeId: StoreId;

  @belongsTo('importJobs')
  @property importJob?: ImportJob;
  importJobId?: number;
}

definePresentation(SalesActivity, {
  upc: { formDisplay: false, includeInFormQuery: true },
  sellDate: { formDisplay: false, includeInFormQuery: true },
  quantitySold: { formDisplay: false, includeInFormQuery: true },
  expectedPrice: { formDisplay: false, includeInFormQuery: true },
  reportedPrice: { formDisplay: false, includeInFormQuery: true },
  expectedRetail: { formDisplay: false, includeInFormQuery: true },
  reportedRetail: { formDisplay: false, includeInFormQuery: true },
  product: { formDisplay: false, includeInFormQuery: true },
  store: { formDisplay: false, includeInFormQuery: true },
  importJob: { formDisplay: false, includeInFormQuery: true },
});
