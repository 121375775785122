
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { withRoutePlanIdFromParams, WithRoutePlanIdProps } from '../with-route-plan-id-from-params';
import { RoutePlanPrintingUI, UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import { containerParentHoc, ContainerParentInfo } from 'client/components/table/container-parent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ComponentProps {
}

const component = flowRight(
  containerParentHoc('.mfc-form-details-with-sidebar'),
  withRoutePlanIdFromParams,
)(RoutePlanPrintingUI);

interface ContainerParentProps {
  containerParentInfo: ContainerParentInfo;
}

type CombinedProps =
  ComponentProps &
  WithRoutePlanIdProps &
  ContainerParentProps;

assertCompatible<UIProps, CombinedProps>();

export const RoutePlanPrintingContainer = (props: ComponentProps) => propToComponent(component, props);
