import gql from 'graphql-tag';
import * as RoutePlanGQLTypes from 'schema/route-plan/route-plan-typescript-types';

export interface AddAdditionalStoreToRoutePlanMutationInput {
  routePlanId: number;
  storeId: number;
  numberOfPallets: number;
  numberOfRacks: number;
}

type RoutePlanGQLResult = Pick<RoutePlanGQLTypes.RoutePlan, 'id'>;
export interface AddAdditionalStoreToRoutePlanMutationResponse {
  result: {
    routePlan: RoutePlanGQLResult;
  };
}

export const AddAdditionalStoreToRoutePlanMutation = gql`
  mutation AddAdditionalStoreToRoutePlanMutation($routePlanId: Int!, $storeId: Int!, $numberOfPallets: Int!, $numberOfRacks: Int!) {
    result: AddAdditionalStoreToRoutePlan(routePlanId: $routePlanId, storeId: $storeId, numberOfPallets: $numberOfPallets, numberOfRacks: $numberOfRacks) {
      routePlan {
        id
      }
    }
  }
`;
