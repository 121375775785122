import { RecordBarDetail } from 'client/components/record-bar/record-bar-detail';
import * as React from 'react';
import Dropdown from 'client/components/dropdown';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { Action } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
  recordId: number;
  menuItems: RecordBarActionMenuItem[];
}

export type RecordBarActionCreator = (recordId: number) => Action;

export interface RecordBarActionMenuItem {
  label: string;
  onClickAction: RecordBarActionCreator;
}

type DispatchProps = {
  onClickHandler: (fn: RecordBarActionCreator, recordId: number) => Action;
};

export const mapDispatchToProps: DispatchProps = {
  onClickHandler: (fn, recordId) => {
    return fn(recordId);
  },
};

type ComponentProps = OwnProps & DispatchProps;

const RecordBarActionMenuDude = (props: ComponentProps) => {
  const { onClickHandler, menuItems, recordId } = props;
  const onMenuItemClicked = React.useCallback((v, index) => {
   onClickHandler(menuItems[index].onClickAction, recordId);
  }, [onClickHandler, menuItems, recordId]);

  const menuItemLabels = React.useMemo(() => props.menuItems.map(d => d.label), [props.menuItems]);

  return (
    <RecordBarDetail>
      <Dropdown
        className="btn-group-record-bar-button"
        data={menuItemLabels}
        value={[null]}
        valueField="id"
        onChange={onMenuItemClicked}
        updateLabel
        testid="record-bar-action-menu"
        disabled={false}
        placeholder="Actions"
      />
    </RecordBarDetail>
  );
};

const component = flowRight(
  connect<{}, DispatchProps, {}>(undefined, mapDispatchToProps),
)(RecordBarActionMenuDude);

export const RecordBarActionMenu = (props: OwnProps) => propToComponent(component, props);
