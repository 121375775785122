import * as Validators from 'shared/validators';
import { tableName, property, definePresentation, hasMany, required, defineFieldValidators } from './dsl';
import { strEnum, DISPLAY_TYPES } from '../types';
import { ImportableDocumentDetail } from 'shared/schemas/importable-document-detail';
import { ImportJob } from 'shared/schemas/import-job';
import { ImportableDocumentContent } from 'shared/schemas/importable-document-content';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { ImportableDocumentStatus as ImportableDocumentStatusConstraint } from 'shared/types/constraint-enums';

export const STATUS = ImportableDocumentStatusConstraint;
export type STATUS = keyof typeof STATUS; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export const ImportableDocumentStatus = STATUS;
export const ValidImportableDocumentType = strEnum([
  'salesActivity',
  'creditDebitAdjustment',
  'purchaseOrder',
  'remittanceAdvice',
]);
export type ValidImportableDocumentType = keyof typeof ValidImportableDocumentType; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

@tableName('importableDocuments')
export class ImportableDocument implements IRecord {
  id?: number;

  @property @required status: STATUS;

  @property documentType?: ValidImportableDocumentType | null;
  @property documentNumber?: string | null;
  @property messages?: string[] | null;
  @property customerIdentifier?: string | null;

  @hasMany('importableDocumentDetails')
  @property details?: ImportableDocumentDetail[];

  /**
   * This is implemented as a hasMany relationship for ease of using the ORM.
   * It is actually a 1:1 relationship.
   */
  @hasMany('importableDocumentContents')
  @property documentContents?: ImportableDocumentContent[];

  @hasMany('importJobs')
  @property importJobs?: ImportJob[];
}

defineFieldValidators(ImportableDocument, {
  status: [Validators.INCLUSION(STATUS)],
  documentType: [Validators.INCLUSION(ValidImportableDocumentType)],
});

definePresentation(ImportableDocument, {
  status: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  documentType: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  documentNumber: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  messages: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  details: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  documentContents: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  importJobs: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
});
