import * as React from 'react';
import { FormControl, HelpBlock } from 'client/components/third-party';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';
import { Checkbox } from './checkbox';

const LabeledCheckbox = ({
  input,
  label,
  inputColSize,
  labelColSize,
  horizontalLabel,
  disabled,
  offset,
  testid,
  handleChange,
  meta: { touched, error, submitting },
  alwaysShowErrors,
}: {
  label: string,
  disabled: boolean,
  placeholder: string,
  testid: string,
  labelColSize: number,
  inputColSize: number,
  horizontalLabel: boolean,
  offset: number,
  alwaysShowErrors: boolean,
  handleChange: (...args: unknown[]) => unknown,
  // comes from redux-form
  input: { name: string, value: any, onChange: (...args: unknown[]) => unknown },
  meta: { touched: boolean, submitting: boolean, error: string },
}) => {
  const onClick = newValue => {
    if (handleChange) {
      handleChange(newValue);
    }
  };

  const shouldDisplayError = !submitting && error && (touched || alwaysShowErrors);

  const checkboxElement = (
    <div>
      <div data-testid={testid || input.name}>
        <Checkbox label={label} input={input} disabled={disabled} onClick={onClick} />
      </div>
      {touched && <FormControl.Feedback />}
      {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
    </div>);

  if (horizontalLabel) {
    return (
      <HorizontalLayout offset={offset} labelColSize={labelColSize} inputColSize={inputColSize}>
        {checkboxElement}
      </HorizontalLayout>
    );
  }

  return (
    <StackedLayout inputColSize={inputColSize} offset={offset}>
      {checkboxElement}
    </StackedLayout>
  );
};

LabeledCheckbox.defaultProps = {
  horizontalLabel: true,
};

export default LabeledCheckbox;
