import gql from 'graphql-tag';
import { FilterOption } from 'shared/types';
import * as GraphQLTypes from 'schema/supplier-commitment/types';
import { extractQueryName } from 'client/utils/query-utils';

const SupplierCommitmentsListFragment = gql`
  fragment SupplierCommitmentsListFragment on SupplierCommitment {
    id
    salesPlan {
      id
      identifier
      year
      customer {
        id
        name
      }
      sellDepartment {
        id
        identifier
      }
    }
    confirmed
    supplier {
      id
      name
    }
  }
`;
export interface SupplierCommitmentsQueryResponse {
  getSupplierCommitments: {
    supplierCommitments: Array<{
      id: GraphQLTypes.SupplierCommitment['id'];
      salesPlan: {
        id: number;
        identifier: string;
        year: number;
        customer: {
          id: number;
          name: string;
        };
        sellDepartment: {
          id: number;
          identifier: string;
        };
      };
      supplier: Pick<GraphQLTypes.SupplierCommitment['supplier'], 'id' | 'name'>;
      confirmed: GraphQLTypes.SupplierCommitment['confirmed'];
    }>
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
  getSupplierCommitmentsFilterOptions?: {
    years: FilterOption[];
    identifiers: FilterOption[];
    customers: FilterOption[];
    suppliers: FilterOption[];
    sellDepartments: FilterOption[];
  };
}
export const GetSupplierCommitmentsQuery = gql`
  query GetSupplierCommitmentsWithFilterOption($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    getSupplierCommitments: GetSupplierCommitments(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      supplierCommitments {
        ...SupplierCommitmentsListFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
    getSupplierCommitmentsFilterOptions: GetSupplierCommitmentFilterOptions {
      years {
        id
        value
        displayValue
      }
      identifiers {
        id
        value
        displayValue
      }
      customers {
        id
        value
        displayValue
      }
      suppliers {
        id
        value
        displayValue
      }
      sellDepartments {
        id
        value
        displayValue
      }
    }
  }
  ${SupplierCommitmentsListFragment}
`;

export const GetSupplierCommitmentsQueryName = extractQueryName(GetSupplierCommitmentsQuery);

export const GetSupplierCommitmentsExcelQuery = gql`
  query GetSupplierCommitmentsExcelQuery($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    GetSupplierCommitments(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      supplierCommitments {
        ...SupplierCommitmentsListFragment
      }
    }
  }
  ${SupplierCommitmentsListFragment}
`;
