import { ReportTypes, SalesPlanIdentifier, SelectableValue } from 'shared/types';

export const formName = 'supplierFutureCommitmentReport';
export const reportType = ReportTypes.SupplierFutureCommitmentReport;

export enum FormFields {
  id = 'id', // Preset ID
  customerId = 'customerId',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productIds = 'productIds',
  productSubClassIds = 'productSubClassIds',
  scanBased = 'scanBased',
  sellDepartmentId = 'sellDepartmentId',
  season = 'season',

  year = 'year',
  beginWeek = 'beginWeek',
  endWeek = 'endWeek',
}

export interface FormValues {
  [FormFields.id]: number | undefined; // Preset ID
  [FormFields.customerId]: number | undefined;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.season]: SalesPlanIdentifier | undefined;
  [FormFields.year]: string | undefined;
  [FormFields.beginWeek]: string | undefined;
  [FormFields.endWeek]: string | undefined;
}
