import { connect } from 'react-redux';
import * as State from 'client/state/state';
import { propToComponent } from 'client/hoc/hoc';
import { StoreListItemUI, ComponentProps as UIProps } from './store-list-item-ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { GlobalStoreIdentifier } from 'shared/types';
import * as TableActions from 'client/actions/table';
import * as Actions from 'client/actions/import-route';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { TRANSPORTATION_SPLIT_STOPS } from 'client/constants';
import { Dispatch } from 'redux';

export interface OwnProps {
  storeIdentifier: GlobalStoreIdentifier;
  isFullyAllocated: boolean;
}

interface StateProps {
  selected: boolean;
  hasStoreLoadRackQuantityError: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const isSelected = ImportRouteSelectors.isSelectedStore(state, ownProps.storeIdentifier);

  const loadNumbers = ImportRouteSelectors.getLoadNumbersForSelectedStore(state);
  const tooMuchRacks = loadNumbers.some(loadNumber => ImportRouteSelectors.getSpacesRemainingInLoad(state, loadNumber) < 0);

  const splitStopTableHasInvalidCell = ImportRouteSelectors.splitStopTableHasInvalidCell(state);

  return {
    selected: isSelected,
    hasStoreLoadRackQuantityError: splitStopTableHasInvalidCell || tooMuchRacks,
  };
};

interface DispatchProps {
  handleStoreListItemClicked: (storeIdentifier: GlobalStoreIdentifier) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    handleStoreListItemClicked: storeIdentifier => {
      dispatch(TableActions.clickCell(TRANSPORTATION_SPLIT_STOPS, 0, 5)); // :-)
      dispatch(Actions.splitStopStoreSelected(storeIdentifier));
    },
  };
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(StoreListItemUI) as Component<OwnProps>;

export const StoreListItemContainer = (props: OwnProps) => propToComponent(component, props);
