import { Action } from 'redux';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { RoutePlanId, StoreSummaryReportCheckableStore, StoreSummaryReportStoresResponse, GetStoreSummaryReportStoresInput } from 'schema/route-plan/route-plan-typescript-types';
import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import { ApolloRefetch } from 'client/types';
import { resetTable } from 'client/actions/table';
import { TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME } from 'client/constants';
import { send } from 'shared/send';
import * as FileSaver from 'client/utils/filesaver';
import * as State from 'client/state/state';
import gql from 'graphql-tag';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import * as RoutePlanSelectors from 'client/state/route-plan';
import { mutationSent, mutationResponseReceived, removeRecentlyReceivedMutationResponse } from './mutations';
import * as ErrorActions from 'client/actions/error';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GenerateDistributionRackShippingWorksheetMutation } from 'client/app/transportation/routing/sidebar/route-plan-mutation';

export type ActionTypes =
  RoutePlanUnmountedAction |
  SetAttachOrdersModalVisibilityAction |
  SetStoreSummaryReportModalVisibilityAction |
  SetStoreSummaryReportStoresAction |
  StoreSummaryReportStoresSearchedAction |
  StoreSummaryReportSelectedStoresChangedAction |
  StoreSummaryReportSelectAllClickedAction |
  StoreSummaryReportDownloadCompleted
  ;

export enum ActionTypeKeys {
  ROUTE_PLAN_UNMOUNTED = 'App/ROUTE_PLAN_UNMOUNTED',
  ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY',
  ROUTE_PLAN_DOWNLOAD_RECEIVING_FORM = 'App/ROUTE_PLAN_DOWNLOAD_RECEIVING_FORM',
  ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY',
  ROUTE_PLAN_DOWNLOAD_STORE_SUMMARY_REPORT = 'App/ROUTE_PLAN_DOWNLOAD_STORE_SUMMARY_REPORT',
  ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_STORES = 'App/ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_STORES',
  ROUTE_PLAN_STORE_SUMMARY_STORE_SEARCHED = 'App/ROUTE_PLAN_STORE_SUMMARY_STORE_SEARCHED',
  ROUTE_PLAN_STORE_SUMMARY_STORE_SELECTED = 'App/ROUTE_PLAN_STORE_SUMMARY_STORE_SELECTED',
  ROUTE_PLAN_STORE_SUMMARY_REPORT_SELECT_ALL_CLICKED = 'App/ROUTE_PLAN_STORE_SUMMARY_REPORT_SELECT_ALL_CLICKED',
  ROUTE_PLAN_STORE_SUMMARY_REPORT_DOWNLOAD_COMPLETED = 'App/ROUTE_PLAN_STORE_SUMMARY_REPORT_DOWNLOAD_COMPLETED',
  ROUTE_PLAN_STORES_WITH_MERCHANDISERS_FETCHED = 'App/ROUTE_PLAN_STORES_WITH_MERCHANDISERS_FETCHED',
}

export interface RoutePlanUnmountedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_UNMOUNTED;
}

export function routePlanUnmounted(): RoutePlanUnmountedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_UNMOUNTED,
  };
}

export interface SetAttachOrdersModalVisibilityAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
  };
}

export function showAttachOrdersModal(): SetAttachOrdersModalVisibilityAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY,
    payload: {
      showModal: true,
    },
  };
}

export function handleModalUnmounted() {
  return (dispatch: Dispatch<any>) => {
    resetTable(dispatch, TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME);
  };
}

export function hideAttachOrdersModal(): SetAttachOrdersModalVisibilityAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY,
    payload: {
      showModal: false,
    },
  };
}

export interface SetStoreSummaryReportModalVisibilityAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY;
  payload: {
    showModal: boolean;
  };
}

export interface SetStoreSummaryReportStoresAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_STORES;
  payload: {
    stores: StoreSummaryReportCheckableStore[];
  };
}

export const setStoreSummaryReportStores = (stores: StoreSummaryReportCheckableStore[]): SetStoreSummaryReportStoresAction => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_STORES,
    payload: { stores },
  };
};

const GetStoreSummaryReportStoresQuery = gql`
query GetStoresSummaryReportStoresQuery($routePlanId: Int!) {
  getStores: GetStoreSummaryReportStores(routePlanId: $routePlanId) {
      stores {
        storeId
        storeIdentifier
        storeDisplay
      }
    }
  }
`;

export const downloadStoreSummaryReportActionClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    const getStoreSummaryReportStoresResponse = await msyncClientQuery<{ getStores: StoreSummaryReportStoresResponse }, GetStoreSummaryReportStoresInput>({
      variables: { routePlanId },
      dispatch,
      query: GetStoreSummaryReportStoresQuery,
      fetchPolicy: 'network-only',
    });

    const checkableStores: StoreSummaryReportCheckableStore[] = getStoreSummaryReportStoresResponse.data.getStores.stores.map(s => {
      return {
        ...s,
        checked: false,
      };
    });

    dispatch(setStoreSummaryReportStores(checkableStores));

    dispatch(showStoreSummaryReportModal());
  };
};

export function showStoreSummaryReportModal(): SetStoreSummaryReportModalVisibilityAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY,
    payload: {
      showModal: true,
    },
  };
}

export function hideStoreSummaryReportModal(): SetStoreSummaryReportModalVisibilityAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_SET_STORE_SUMMARY_REPORT_MODAL_VISIBILITY,
    payload: {
      showModal: false,
    },
  };
}

export interface DownloadStoreSummaryReportPDF {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_STORE_SUMMARY_REPORT;
  payload: {
    routePlanId: number;
    storeIds: number[];
  };
}

export const storeSummaryReportDownloadPDFButtonClickedAction = (routePlanId: number): Thunker => {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const state = getState();
    const selectedStores = RoutePlanSelectors.storeSummaryReportStores(state.routePlan).filter(s => s.checked);
    const selectedStoreIds = selectedStores.map(s => (s.storeId));
    dispatch(mutationSent(false));

    try {
      const routePlanIdentifier = await getRoutePlanIdentifier(dispatch, routePlanId);

      await downloadStoreSummaryReport({
        routePlanId,
        routePlanIdentifier,
        storeIds: selectedStoreIds,
      });

      dispatch(mutationResponseReceived());

      dispatch(downloadStoreSummaryReportPDFCompleted());
    } catch (exception) {
      if (exception.isExpectedError) {
        dispatch(ErrorActions.expectedErrorReceived({ message: exception.message }));
      } else {
        dispatch(ErrorActions.unexpectedErrorReceived({ message: exception.message, stack: exception.stack }));
      }
    } finally {
      dispatch(removeRecentlyReceivedMutationResponse());
    }
  };
};

export interface StoreSummaryReportDownloadCompleted extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_DOWNLOAD_COMPLETED;
}

const downloadStoreSummaryReportPDFCompleted = () => {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_DOWNLOAD_COMPLETED,
  };
};

export interface StoreSummaryReportStoresSearchedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SEARCHED;
  payload: {
    searchText: string;
  };
}

export function storesSearched(searchText: string): StoreSummaryReportStoresSearchedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SEARCHED,
    payload: {
      searchText,
    },
  };
}

export interface StoreSummaryReportSelectedStoresChangedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SELECTED;
  payload: {
    storeId: number;
  };
}

export function storeSummaryReportSelectedStoresChanged(storeId: number): StoreSummaryReportSelectedStoresChangedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_STORE_SELECTED,
    payload: {
      storeId,
    },
  };
}

export interface StoreSummaryReportSelectAllClickedAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_SELECT_ALL_CLICKED;
  payload: {};
}

export function storeSummaryReportSelectAllClicked(): StoreSummaryReportSelectAllClickedAction {
  return {
    type: ActionTypeKeys.ROUTE_PLAN_STORE_SUMMARY_REPORT_SELECT_ALL_CLICKED,
    payload: {},
  };
}

export function handleAddClicked(attachCustomerOrderToRoutePlanMutation, routePlanId: RoutePlanId, customerOrderIds: CustomerOrderId[], refetchParentTable?: ApolloRefetch): Thunker {
  return async (dispatch: Dispatch<shame>) => {
    await attachCustomerOrderToRoutePlanMutation({ variables: { routePlanId, customerOrderIds } });
    if (refetchParentTable) {
      await refetchParentTable();
    }

    dispatch(hideAttachOrdersModal());
  };
}

export interface DownloadRoutePlanReceivingFormAction extends Action {
  type: ActionTypeKeys.ROUTE_PLAN_DOWNLOAD_RECEIVING_FORM;
  payload: {
    routePlanId: RoutePlanId;
  };
}

const downloadReceivingFormPdf = async (routePlanId: RoutePlanId) => {
  const {
    content: pdfBlob,
    filename,
  } = await send({
    url: `/report/receivingForm/${routePlanId}`,
    method: 'GET',
    asBlob: true,
    includeFilename: true,
  });

  FileSaver.saveAs(pdfBlob, filename);
};

export const downloadRoutePlanReceivingForm = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    await downloadReceivingFormPdf(routePlanId);
  };
};

const GetRoutePlanIdentifierForExcelDownload = gql`
query GetRoutePlanIdentifierForExcelDownload($id: Int!) {
  routePlanResult: GetRoutePlan(id: $id) {
    routePlan {
      id
      identifier
    }
  }
}
`;

const getRoutePlanIdentifier = async (dispatch: Dispatch<any>, routePlanId: RoutePlanId): Promise<string> => {
  const routePlanIdentifierResult = await msyncClientQuery<{ routePlanResult: { routePlan: { identifier: string } } }>({
    variables: {
      id: routePlanId,
    },
    dispatch,
    query: GetRoutePlanIdentifierForExcelDownload,
  });

  return routePlanIdentifierResult.data.routePlanResult.routePlan.identifier;
};

export const downloadDistributionRackShippingWorksheet = (routePlanId: RoutePlanId, setInProgress: (inProgress: boolean) => void): Thunker => {
  return async (dispatch: Dispatch<any>) => {
    setInProgress(true);
    try {
      const response = await msyncClientMutation<{ report: { id: number } }, { routePlanId: number }>({
        mutation: GenerateDistributionRackShippingWorksheetMutation,
        variables: {
          routePlanId,
        },
        dispatch,
      });

      if (response) {
        window.location.replace(`/report/fileDownload/${response.data.report.id}`);
      }
    } finally {
      setInProgress(false);
    }
  };
};

export const downloadStoreSummaryReport = async (args: { routePlanIdentifier: string, routePlanId: number, storeIds: number[] }) => {
  const pdfBlob = await send({
    fetch,
    url: `/report/storeSummaryReport/${args.routePlanId}?storeIds=${args.storeIds}`,
    method: 'POST',
    asBlob: true,
  });

  const filename = `${args.routePlanIdentifier} - Store Summary Report.pdf`;
  FileSaver.saveAs(pdfBlob, filename);
};
