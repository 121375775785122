import * as _ from 'lodash';
import { getFormValues, change } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import { DateRangeYearComparisonValue, getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import { GenerateMobileSalesPerformanceReportInput } from 'schema/sales-report/types';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadMobileSalesPerformanceReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

    const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
    if (!dateRangeYearComparison) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
    if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const beginDate = dateRanges.dateRange.startDate;
    const endDate = dateRanges.dateRange.endDate;
    const lastYearBeginDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.startDate : undefined;
    const lastYearEndDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.endDate : undefined;

    const customerId = formValues[Constants.FormFields.customerId];
    const primaryGlobalAll = formValues[Constants.FormFields.primaryGlobalAll];
    const productIds = extractSelectedValues(formValues[Constants.FormFields.productIds]);
    const storeIds = extractSelectedValues(formValues[Constants.FormFields.storeIds]);
    const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];

    if (_.isNil(beginDate) ||
      _.isNil(endDate) ||
      _.isNil(lastYearBeginDate) ||
      _.isNil(lastYearEndDate) ||
      _.isNil(customerId) ||
      _.isNil(primaryGlobalAll) ||
      _.isNil(productIds) ||
      productIds.length === 0 ||
      _.isNil(storeIds) ||
      storeIds.length === 0 ||
      _.isNil(sellDepartmentId)) {
      throw new Error('Missing some required fields');
    }

    dispatch(ReportActions.reportDownloadStarted());
    try {
      const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, { reportInput: GenerateMobileSalesPerformanceReportInput }>({
        mutation: Mutations.generateMobileSalesPerformanceReport,
        variables: {
          reportInput: {
            customerId,
            beginDate,
            endDate,
            lastYearBeginDate,
            lastYearEndDate,
            sellDepartmentId,
            includePoBased: formValues[Constants.FormFields.poBased] ?? false,
            includeScanBased: formValues[Constants.FormFields.scanBased] ?? false,
            productClassIds: extractSelectedValues(formValues[Constants.FormFields.productClassIds]),
            productSubClassIds: extractSelectedValues(formValues[Constants.FormFields.productSubClassIds]),
            productIds,
            marketIds: extractSelectedValues(formValues[Constants.FormFields.marketIds]),
            mfcAreaIds: extractSelectedValues(formValues[Constants.FormFields.mfcAreaIds]),
            storeIds,
            primaryGlobalAll,
          },
        },
        dispatch,
      });

      if (!response) {
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};

export const handleDateRangesChanged = (state: DateRangeYearComparisonValue | undefined): Thunker => {
  return async dispatch => {
    dispatch(change(Constants.formName, Constants.FormFields.dateRangeYearComparison, state));
  };
};
