import * as _ from 'lodash';
import * as Constants from './constants';
import * as ReactRedux from 'react-redux';
import * as TableParent from 'client/components/table/table-parent';
import * as UI from './ui';
import * as WrappedContainer from 'client/hoc/hoc';
import * as State from 'client/state/state';
import { GetInvoiceWithLineItemsQuery, GetInvoiceLineItemsResponse, GetInvoiceLineItemsInput, GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GetInvoiceWithLineItemsExcelQuery } from './query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { WithInvoiceLineItemProps } from './types';
import { FetchPolicy } from 'apollo-client';
import { withClientSideSorting } from 'client/containers/table/client-side-sorting';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Actions from './actions';
import { GET_INVOICE_STATS_QUERY_NAME } from '../stats-row/query';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import { withSaveConfirmation } from 'client/hoc/with-save-confirmation';
import { saveConfirmationOptionsForInvoice } from '../shared/save-confirmation';

export interface OwnProps {
  invoiceId?: number;
}

interface StateProps {
  isAddRoutePlansModalShown: boolean;
  isAddVendorChargebackLineItemModalShown: boolean;
  addLineItemRefetchQueries: string[];
}

interface WithTableParentProps {
  tableParentInfo: TableParent.TableParentInfo;
}

const WithInvoiceLineItems = msyncQuery<GetInvoiceLineItemsResponse, OwnProps & StateProps & WithTableParentProps, WithInvoiceLineItemProps, GetInvoiceLineItemsInput>(GetInvoiceWithLineItemsQuery, {
  skip(ownProps) {
    return ownProps.invoiceId === undefined;
  },
  options(ownProps): { fetchPolicy: FetchPolicy, variables: GetInvoiceLineItemsInput } {
    return {
      fetchPolicy: 'network-only',
      variables: {
        invoiceId: ownProps.invoiceId!, // ! is OK because we are checking for undefined in the "skip" above
      },
    };
  },
  props(props): WithInvoiceLineItemProps {
    const { data } = props;

    const lineItems = data.getInvoiceLineItems ? data.getInvoiceLineItems.invoice.lineItems : [];
    const type = data.getInvoiceLineItems ? data.getInvoiceLineItems.invoice.type : undefined;
    const initialValues = {
      ediInvoiced: data.getInvoiceLineItems ? data.getInvoiceLineItems.invoice.ediInvoiced : false,
      transferredToAccounting: data.getInvoiceLineItems ? data.getInvoiceLineItems.invoice.transferredToAccounting : false,
      invoiceDownloaded: data.getInvoiceLineItems ? !!data.getInvoiceLineItems.invoice.firstDownloadedAt : false,
      invoiceEmailed: data.getInvoiceLineItems ? !!data.getInvoiceLineItems.invoice.emailedAt : false,
    };

    const lineItemIds = idsFor(lineItems);

    return {
      initialValues,
      lineItems,
      type,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: lineItemIds,
      totalCount: lineItems.length,
      ids: lineItemIds,
      totalUnfilteredCount: lineItems.length || 1, // using 1 to make sure we always show the table
      dataRequest: {
        query: GetInvoiceWithLineItemsExcelQuery,
        customAccessor: 'invoice.lineItems',
        workbookName: `${_.startCase(type)} Invoice Line Items`,
        variables: {
          invoiceId: props.ownProps.invoiceId,
        },
      },
      availableFilters: [],
    };
  },
});

interface DispatchProps {
  onAddVendorChargebackLineItemsButtonClicked(): void;
  onAddRoutePlansButtonClicked(): void;
  onAddLineItemButtonClicked: UI.AddLineItemButtonClickHandler;
  onSave: UI.OnSaveFunction;
  onDelete: UI.OnDeleteFunction;
}

const mapDispatchToProps = {
  onAddVendorChargebackLineItemsButtonClicked: Actions.onAddVendorChargebackLineItemsButtonClicked,
  onAddRoutePlansButtonClicked: Actions.onAddRoutePlansButtonClicked,
  onAddLineItemButtonClicked: Actions.onAddLineItemButtonClicked,
  onSave: Actions.onLineItemEdit,
  onDelete: Actions.onLineItemDelete,
};

const addLineItemRefetchQueries = [GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME, GET_INVOICE_STATS_QUERY_NAME];

const mapStateToProps = (state: shame, ownProps: OwnProps & WithInvoiceLineItemProps & DispatchProps): StateProps => {
  return {
    isAddRoutePlansModalShown: State.isAddRoutePlansModalShown(state),
    isAddVendorChargebackLineItemModalShown: State.isAddVendorChargebackLineItemsModalShown(state),
    addLineItemRefetchQueries,
  };
};
interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithInvoiceLineItemProps &
  WithSaveConfirmationProps &
  WithTableParentProps &
  DispatchProps;

assertCompatible<UI.OwnProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  WithInvoiceLineItems,
  // we need an object like { ediInvoicedAt, transferredToAccountingAt }
  withSaveConfirmation(saveConfirmationOptionsForInvoice),
  TableParent.tableParentHoc(),
  ReactRedux.connect<StateProps, {}, OwnProps>(undefined, mapDispatchToProps), // Must come after WithInvoiceLineItems
  ReactRedux.connect<StateProps, {}, OwnProps>(mapStateToProps), // Must come after WithInvoiceLineItems
  withClientSideSorting<ComponentProps>(Constants.tableName, 'lineItems', true),
)(UI.InvoiceTableUI) as Component<OwnProps>;

export const InvoiceTable = (props: OwnProps) => WrappedContainer.propToComponent(component, props);
