import { ControlLabel, FormControl, HelpBlock } from 'client/components/third-party';
import { HorizontalLayout } from '../form/layouts';
import { SelectableProps, Selectable, SelectableValue } from './selectable';
import { SelectableRow } from './types';
import { StackedLayout } from '../form/layouts';
import * as React from 'react';
import * as classnames from 'classnames';

interface Props extends ReduxFormProps, SelectableProps {
  disabled: boolean;
  horizontalLabel?: boolean;
  inputColSize: number;
  isAtBottom?: boolean;
  label: string;
  labelColSize: number;
  loading?: boolean;
  offset: number;
  handleChange?: (value: SelectableValue) => void;
  options?: SelectableRow[];
  placeholder: string;
  required: boolean;
  testid: string;
  hideOptionalLabel?: boolean;
  alwaysShowErrors?: boolean;
}

interface ReduxFormProps {
  input: {
    name: string;
    onChange: (value: SelectableValue) => any;
    value: SelectableValue | string;
  };
  meta: {
    touched: boolean;
    error: string;
    submitting: false;
  };
}

export default function LabeledSelectable(props: Props) {
  const selectable = (
    <Selectable
      cols={props.cols}
      handleChange={props.handleChange}
      input={props.input}
      isAtBottom={props.isAtBottom}
      loading={props.loading}
      options={props.options}
      required={props.required}
      testid={props.testid}
      title={props.label}
      horizontalLabel={props.horizontalLabel}
      hideOptionalLabel={props.hideOptionalLabel}
    />
  );

  const labelClassName = classnames('labeled-selectable-label', {
    required: props.required,
    optional: !props.required && !props.hideOptionalLabel,
    disabled: props.disabled,
  });

  const shouldDisplayError = !props.meta.submitting && props.meta.error && (props.meta.touched || props.alwaysShowErrors);

  return props.horizontalLabel
    ? (
      <HorizontalLayout
        classNames={[labelClassName]}
        inputColSize={props.inputColSize}
        label={props.label}
        labelColSize={props.labelColSize}
        labelComponent={ControlLabel}
        offset={props.offset}
      >
        {selectable}
        {props.meta.touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{props.meta.error}</HelpBlock>}
      </HorizontalLayout>
    ) : (
      <StackedLayout
        classNames={[]}
        inputColSize={props.inputColSize}
        label=""
        offset={props.offset}
      >
        {selectable}
        {props.meta.touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{props.meta.error}</HelpBlock>}
      </StackedLayout>
    );
}
