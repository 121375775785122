import * as React from 'react';
import { CELL_TYPES, TYPES } from '../../../shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { IColumn } from 'client/components/table/column';
import { RecordNav } from '../record-nav';
import * as _ from 'lodash';
import { ComponentProps } from 'client/containers/admin/admin-attachments-container';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { TableParentInfo } from '../table/table-parent';
import { OnRowSelect } from '../table/table-ui';
import { DeactivateRecordFunction } from 'client/hoc/graphql/deactivate-records';
import { ActivateRecordFunction } from 'client/hoc/graphql/activate-records';
import { AvailableFilter, AvailableSearchField, ButtonClickHandler } from 'client/types';
import { RecordBar } from 'client/components/record-bar';
import * as pluralize from 'pluralize';
import { formatDateTime } from 'shared/helpers';
import { DownloadAttachmentCell } from 'client/components/table/download-attachment-cell';
import { ImportAttachmentModal } from '../import-attachment-modal';
import { RowMenuItem } from '../table/row-menu/menu';
import * as humanize from 'humanize';
import { Modal, Button } from 'client/components/third-party';
import { EMPTY_ARRAY } from 'client/constants';

export interface AdminAttachmentPageProps {
  tableName: string;
  emptyTablePlaceholder?: string;
  newButtonLabel?: string;
  supplementalRecordBarComponent?: React.FunctionComponent;
  navBuilder?: any;
  RecordBarDetailComponent?: any;
  paramsId: number | undefined;

  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  performActivation: ActivateRecordFunction;
  performDeletion: DeactivateRecordFunction;
  onRowSelect?: OnRowSelect;
  onNewClicked?: ButtonClickHandler;
  onRowFocused?: (row: Dictionary<any>) => void;

  refetchTable: () => void;
  tablePageNumber: number;
  totalUnfilteredCount: number;
  totalCount: number;
  filteredRecordIds: number[];
  loadMoreRecords?: () => void;

  tableParentInfo: TableParentInfo;

  dataRequest: MsyncDataRequest;

  hardDeleteRecords: boolean;

  recordType: string;

  onClose: () => void;
  handleSubmit: () => Promise<void | boolean>;
  confirmOkToSave: () => Promise<boolean>;
  pristine: boolean;
  submitted: boolean;
  submitting: boolean;
  onChangeRecordStatus: (name: string, field: string, value: any) => void;
  resetForm: any;

  onAttachmentDescriptionUpdated(id: number, description: string | undefined): void;
  descriptionResolver?: (...args: any[]) => string;
}

export type OwnProps = ComponentProps & AdminAttachmentPageProps;

export class AdminAttachmentPage extends React.Component<OwnProps, any> {
  private FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private headerMenuItems: RowMenuItem[];
  private rowMenuItems: RowMenuItem[];
  private buttons: Array<{ label: string, onClick: ButtonClickHandler }>;
  private columns: IColumn[];

  constructor(props: OwnProps) {
    super(props);
    this.FilterableTable = buildFilterableTable(props.tableName);
    this.state = {
      isAddModalShown: false,
      isDeleteModalShown: false,
      selectedRecordIds: [],
    };

    this.headerMenuItems = [
      {
        label: 'Download Attachments',
        onClick: (recordIds: number[]) => this.props.onDownloadMultipleAttachmentsMenuItemClicked(recordIds, this.props.attachments, this.props.record),
      },
      {
        label: 'Delete Attachments',
        onClick: (recordIds: number[]) => this.setState({selectedRecordIds: recordIds, isDeleteModalShown: true}),
        willRemove: true,
      },
    ];

    this.rowMenuItems = [
      {
        label: 'Download Attachment',
        onClick: (recordIds: number[]) => this.props.onDownloadAttachmentMenuItemClicked(recordIds, this.props.attachments),
      },
      {
        label: 'Delete Attachment',
        onClick: (recordIds: number[]) => this.setState({selectedRecordIds: recordIds, isDeleteModalShown: true}),
        willRemove: true,
      },
    ];

    this.buttons = [
      {
        label: 'Add Attachments',
        onClick: () => this.setState({isAddModalShown: true}),
      },
    ];

    this.columns = [
      {
        id: 'filename',
        accessor: record => <DownloadAttachmentCell filename={record.filename} url={record.url} />,
        header: 'Name',
        tableEditable: false,
        columnWidth: 25,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'description',
        accessor: 'description',
        header: 'Description',
        tableEditable: true,
        onSave: (id, description) => {
          this.props.onAttachmentDescriptionUpdated(id, description);
        },
        columnWidth: 35,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'size',
        accessor: record => record.size ? humanize.filesize(record.size) : '',
        header: 'Size',
        tableEditable: false,
        columnWidth: 5,
        sortable: true,
        cellType: CELL_TYPES.RIGHT_ALIGNED_TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'humanReadableType',
        accessor: 'humanReadableType',
        header: 'Type',
        tableEditable: false,
        columnWidth: 10,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'createdBy',
        accessor: 'createdBy',
        header: 'Created By',
        tableEditable: false,
        columnWidth: 10,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
      {
        id: 'createdAt',
        accessor: record => record.createdAt ? formatDateTime(record.createdAt, 'MM/DD/YYYY [at] hh:mm:ss A zz') || '' : '',
        header: 'Created At',
        tableEditable: false,
        columnWidth: 15,
        sortable: true,
        cellType: CELL_TYPES.TEXT,
        type: TYPES.STRING,
      },
    ];
  }

  closeModal = async () => {
    this.setState({isAddModalShown: false});
    await this.props.refetchTable();
  }

  closeConfirmationModal = () => {
    this.setState({isDeleteModalShown: false, selectedRecordIds: []});
  }

  deleteAttachments = () => {
    this.props.onDeleteAttachmentsMenuItemClicked(this.props.tableName, this.state.selectedRecordIds, this.props.attachments);
    this.closeConfirmationModal();
  }

  render() {
    const id = this.props.paramsId;

    if (!id || !this.props.record) {
      return <div />;
    }

    const record = this.props.record;
    const initialRecord = record;

    let recordDescription = initialRecord ? initialRecord.identifier : null;
    if (this.props.descriptionResolver && initialRecord && Object.keys(initialRecord).length) {
      recordDescription = this.props.descriptionResolver(initialRecord);
    }

    return (
      <div className="mfc-attachments-page">
        <div id="mfc-page-content">
          {this.state.isAddModalShown &&
              <ImportAttachmentModal
                handleCancelButtonClicked={this.closeModal}
                handleCloseButtonClicked={this.closeModal}
                recordType={this.props.recordType}
                recordId={id}
              />
          }
          {this.state.isDeleteModalShown &&
            <Modal
              id="confirm-attachment-delete-modal"
              animation
              backdrop="static"
              show={true}
              onHide={() => this.closeConfirmationModal}>
              <Modal.Body>
                Are you sure you want to delete these attachment(s)?
              </Modal.Body>
              <Modal.Footer className="remove-attachment-modal-footer">
                <Button bsClass="mfc-button mfc-clear-button" data-testid="cancel-button" onClick={() => this.closeConfirmationModal()}>
                  Cancel
                </Button>
                <Button
                  bsStyle="primary"
                  bsClass="mfc-button mfc-submit-button"
                  data-testid="remove-button"
                  onClick={() => this.deleteAttachments()}>
                  Delete Attachments
                </Button>
              </Modal.Footer>
            </Modal>
          }
          <RecordBar
            submitting={this.props.submitting}
            submitted={this.props.submitted}
            invalid={false}
            pristine={this.props.pristine}
            onClose={this.props.onClose}
            handleSubmit={this.props.handleSubmit}
            confirmOkToSave={this.props.confirmOkToSave}
            record={record}
            onChangeRecordStatus={(field: string, val: any) => this.props.onChangeRecordStatus(`${this.props.recordType}Form`, field, val)}
            resetForm={() => this.props.resetForm(`${this.props.recordType}Form`)}
            initialRecord={initialRecord}
            table={pluralize(_.camelCase(this.props.recordType))}
            description={recordDescription}
            RecordBarDetailComponent={this.props.RecordBarDetailComponent}
          />
          {this.props.navBuilder && <RecordNav RecordNavItems={id ? this.props.navBuilder(id, record) : EMPTY_ARRAY} disabled={false} />}
          <div className="attachments-content">
            <this.FilterableTable
              table={this.props.tableName}
              loading={this.props.loading}
              columns={this.columns}
              content={this.props.attachments || []}
              refetchTable={this.props.refetchTable}
              availableFilters={this.props.availableFilters}
              searchableFields={[{ id: 'SEARCH_FIELD_ANY', name: 'Search All' }, ...this.props.searchableFields]}
              placeholder={'There are currently no attachments.'}
              totalUnfilteredCount={this.props.totalUnfilteredCount}
              totalCount={this.props.totalCount}
              loadMoreRecords={_.noop}
              filteredRecordIds={this.props.filteredRecordIds}
              disableCreate={false}
              displayLoadingIndicator={true}
              tableParentInfo={this.props.tableParentInfo}
              tablePaginated={false}
              dataRequest={this.props.dataRequest}
              supplementalRecordBarComponent={this.props.supplementalRecordBarComponent}
              newButtonLabel={'Add New Attachment'}
              onNewClicked={() => this.setState({isAddModalShown: true})}
              checkable
              buttons={this.buttons}
              headerMenuItems={this.headerMenuItems}
              rowMenuItems={this.rowMenuItems}
              alwaysDisplayTable
              noDataText={this.props.totalUnfilteredCount === 0 ? 'There are currently no attachments.' : 'No results for given filter and search criteria.'}
            />
          </div>
        </div>
      </div>
    );
  }
}
