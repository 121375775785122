import FormComponent from 'client/components/crud-forms/bucket-form';
import buildAdminCreatePage from '../admin/admin-create-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminCreatePage({
  formName: 'BucketCreateForm',
  table: 'buckets',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
