import gql from 'graphql-tag';
import { GetRoutingLoadsInput, DisplayValue } from 'schema/routing-load/routing-load-graphql-types';
import { Carrier } from 'shared/types';

export interface ApplyInvoiceToLoadsResponse {
  getRoutingLoads?: {
    routingLoads: Array<{
      id: number;
      identifier: string;
      totalFee: number;
      carrier: DisplayValue<Carrier>;
      invoiceNumber: string;
    }>;
  };
  loading: boolean;
}

export const ApplyInvoiceToLoadsFragment = gql`
  fragment ApplyInvoiceToLoadsFragment on RoutingLoad {
    id
    identifier
    totalFee
    carrier {
      rawValue
      displayValue
    }
    invoiceNumber
  }
`;

export type ApplyInvoiceToLoadsInput = GetRoutingLoadsInput;
export const ApplyInvoiceToLoadsQuery = gql`
  query ApplyInvoiceToLoadsGetRoutingLoadsQuery($sort: [SortInput!], $filters: RoutingLoadFilterInput, $search: RoutingLoadSearchInput, $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput) {
    getRoutingLoads: GetRoutingLoads(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      routingLoads {
        ...ApplyInvoiceToLoadsFragment
      }
    }
  }
  ${ApplyInvoiceToLoadsFragment}
`;

export interface WithApplyInvoiceToLoadsQueryProps {
  routingLoads: Array<{
    identifier: string;
    totalFee: number;
    carrier: string;
    invoiceNumber: string;
  }>;
  totalCost: number;
  loading: boolean;
  applyButtonDisabled: boolean;
}
