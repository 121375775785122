import gql from 'graphql-tag';

export const ImportProductTossMutation = gql`
  mutation ImportProductTossMutation($input: ImportProductTossInput!) {
    importProductToss: ImportProductToss(input: $input) {
      success
      errors
    }
  }
`;
