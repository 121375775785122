import * as React from 'react';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import AttachCustomerOrderModal from 'client/app/transportation/routing/attach-customer-orders-modal/attach-customer-orders-modal-container';
import { ApolloRefetch, AvailableFilter, ActiveSort, ActiveFilter, AvailableSearchField } from 'client/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { RoutePlanAttachedCustomerOrderGQLResult } from 'schema/route-plan-attached-customer-orders/route-plan-attached-customer-orders-typescript-types';
import { CustomerId } from 'shared/schemas/customer';
import { MfcAreaId, MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { RoutePlanDeliveryDate, RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { SellDepartmentId } from 'shared/schemas/sell-department';
import { TableParentInfo } from 'client/components/table/table-parent';
import { CustomerOrdersJustTheTable } from './just-the-table';

export interface OwnProps {
  routePlanId?: RoutePlanId;
  tableName: string;
  disableCreate?: boolean;
  detachOptionDisabled: boolean;
  refetchTable?: ApolloRefetch;
  detachCustomerOrderFromRoutePlan: (customerOrderIds: CustomerOrderId[], refetchTable: ApolloRefetch) => Promise<CustomerOrderId[]>;
  attachedCustomerOrderRows?: RoutePlanAttachedCustomerOrderGQLResult[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
  activeSortFields: ActiveSort[];
  attachOrdersModalShown: boolean;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
  customerId?: CustomerId;
  routePlanDeliveryDate?: RoutePlanDeliveryDate;
  mfcAreaId?: MfcAreaId;
  sellDepartmentId?: SellDepartmentId;
  tableParentInfo: TableParentInfo;
  mfcAreaIdentifier?: MfcAreaIdentifier;
  onAttachOrdersClicked(customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier): void;
  onRowSelect: (routePlanId?: RoutePlanId) => (record: shame) => void;
  searchableFields: AvailableSearchField[];
}

export class AttachedCustomerOrdersTableAndModal extends React.Component<OwnProps, any> {

  handleNewClicked = () => {
    this.props.onAttachOrdersClicked(this.props.customerId, this.props.routePlanDeliveryDate, this.props.mfcAreaIdentifier);
  }

  onRowSelect = this.props.onRowSelect(this.props.routePlanId);

  public render() {
    return (
      <div className="receiving-summary-table-wrapper">
        {this.props.attachOrdersModalShown && this.props.routePlanId &&
          <AttachCustomerOrderModal
          routePlanId={this.props.routePlanId}
          title="Attach Orders"
          refetchParentTable={this.props.refetchTable}
          />
        }

        <CustomerOrdersJustTheTable
          type="Attached"
          tableName={this.props.tableName}
          attachedCustomerOrderRows={this.props.attachedCustomerOrderRows}
          loading={this.props.loading}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={this.props.refetchTable}
          availableFilters={this.props.availableFilters}
          placeholder="There are currently no Customer Orders in this Route Plan"
          newButtonLabel="Attach Orders"
          disableCreate={this.props.disableCreate}
          handleNewClicked={this.handleNewClicked}
          tablePaginated={false}
          tableParentInfo={this.props.tableParentInfo}
          dataRequest={this.props.dataRequest}
          detachOptionDisabled={this.props.detachOptionDisabled}
          detachCustomerOrderFromRoutePlan={this.props.detachCustomerOrderFromRoutePlan}
          activeFilters={this.props.activeFilters}
          activeSortFields={this.props.activeSortFields}
          tablePageNumber={this.props.tablePageNumber}
          onRowSelect={this.onRowSelect}
          searchableFields={this.props.searchableFields}
        />
      </div>
    );
  }
}
