import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import assertCompatible from 'shared/helpers/assert-compatible';
import { SupplierCommitmentCreatePage, UIProps } from './ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
}

const mapStateToProps = (state: {}, ownProps: ContainerProps): StateProps => {
  return {
  };
};

interface DispatchProps {
  onSubmit(): void;
}

const component = flowRight(
  connect<StateProps, DispatchProps, {}>(mapStateToProps),
)(SupplierCommitmentCreatePage);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  StateProps;

assertCompatible<UIProps, CombinedProps>();
export const SupplierCommitmentCreatePageContainer = (props: ContainerProps) => propToComponent(component, props);
