import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { Thunker, Action } from 'client/types/redux-types';
import { DateStr } from 'shared/types';
import { getFormValues } from 'client/redux-form';
import * as Constants from './constants';
import * as Mutation from './mutation';

export const FORM_NAME: string = 'update-supplier-item-costs';

export type ActionTypes =
  | UpdateSupplierItemCostsModalCloseClicked
  | UpdateSupplierItemCostsCompleted
;

export enum ActionTypeKeys {
  UpdateSupplierItemCostsModalCloseClicked = 'App/UPDATE_SUPPLIER_ITEM_COSTS_MODAL_CLOSE_CLICKED',
  UpdateSupplierItemCostsCompleted = 'App/UPDATE_SUPPLIER_ITEM_COSTS_COMPLETED',
}

export interface UpdateSupplierItemCostsModalCloseClicked extends Action {
  type: ActionTypeKeys.UpdateSupplierItemCostsModalCloseClicked;
}

export const onUpdateSupplierItemCostsModalCloseClicked = (): UpdateSupplierItemCostsModalCloseClicked => {
  return {
    type: ActionTypeKeys.UpdateSupplierItemCostsModalCloseClicked,
  };
};

export interface UpdateSupplierItemCostsCompleted extends Action {
  type: ActionTypeKeys.UpdateSupplierItemCostsCompleted;
}

export const onUpdateSupplierItemCostsCompleted = (): UpdateSupplierItemCostsCompleted => {
  return {
    type: ActionTypeKeys.UpdateSupplierItemCostsCompleted,
  };
};

export const updateSupplierItemCosts = (supplierCommitmentId: number): Thunker => {
  return (dispatch, getState) => {
    const formValues = getFormValues<{ [Constants.costEffectiveDate]: DateStr }>(FORM_NAME)(getState());
    if (!formValues) {
      return;
    }
    const costEffectiveDate = formValues[Constants.costEffectiveDate];
    return msyncClientMutation<Mutation.UpdateSupplierItemCostsForSupplierCommitmentMutationResponse, Mutation.UpdateSupplierItemCostsForSupplierCommitmentMutationInput>({
      mutation: Mutation.UpdateSupplierItemCostsForSupplierCommitmentMutation,
      dispatch,
      variables: {
        supplierCommitmentId,
        costEffectiveDate,
      },
    });
  };
};
