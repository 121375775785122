import { DISPLAY_TYPES, INPUT_TYPES, TYPES, Carrier, LoadType } from '../types';
import { tableName, property, definePresentation, required, setSchemaOptions, defineFieldValidators } from './dsl';
import { IRecord } from './record';
import { displayType } from './dsl';
import * as Validators from 'shared/validators';

@tableName('carrierRates')
export class CarrierRate implements IRecord {
  id?: number;

  @property @required carrier: string;
  @property @required loadType: string;
  @property @required firstDropRate: number;
  @property @required restDropRate: number;
  @property @required tailgateFee: number;
  @property @required nycCharge: number;
  @property @required eastPremium: number;
  @property @required hmgTrailer: number;
}

defineFieldValidators(CarrierRate, {
  firstDropRate: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  restDropRate: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  tailgateFee: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  nycCharge: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  eastPremium: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
  hmgTrailer: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO, Validators.MAX_VALUE(999999.99)],
});

definePresentation(CarrierRate, {
  carrier: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: Carrier }),
    columnWidth: 12.5,
  },
  loadType: {
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: LoadType }),
    columnWidth: 12.5,
  },
  firstDropRate: {
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
  restDropRate: {
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
  tailgateFee: {
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
  nycCharge: {
    displayName: 'NYC Charge',
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
  eastPremium: {
    displayName: 'East Premium Rate',
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
  hmgTrailer: {
    displayName: 'HMG Trailer Rate',
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.MONEY }),
    type: TYPES.FLOAT,
    columnWidth: 12.5,
  },
});

setSchemaOptions(CarrierRate, {
});
