import gql from 'graphql-tag';

export const ValidateImportProductTossQuery = gql`
  query ValidateImportProductTossQuery($input: ImportProductTossInput!) {
    validateImportableProductToss: ValidateImportableProductToss(input: $input) {
      success
      errors
    }
  }
`;
