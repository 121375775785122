import FormComponent from 'client/components/crud-forms/carrier-rate-form';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminDetailPage({
  formName: 'CarrierRateDetailsForm',
  table: 'CarrierRate',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
