import * as _ from 'lodash';
import * as HOC from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Actions from './actions';
import { FileUploadStatus } from 'client/components/import-attachment-modal/types';

export interface OwnProps {
  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;
  recordType: string;
  recordId: number;
}

interface StateProps {
  cancelButtonDisabled: boolean;
}

interface DispatchProps {
  handleAttachmentUpload: (acceptedFiles: File[], recordType: string, recordId: number, status: (status: FileUploadStatus) => void) => void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, OwnProps> = {
  handleAttachmentUpload: Actions.handleImportStarted,
};

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    cancelButtonDisabled: false,
  };
};

export type ContainerProps =
  OwnProps &
  DispatchProps &
  StateProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.ComponentProps, ContainerProps>();

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(UI.ImportAttachmentModalUI) as Component<OwnProps>;

export const ImportAttachmentModalContainer = (props: OwnProps) => HOC.propToComponent(component, props);
