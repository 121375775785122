import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';

export const VendorListColumns: IColumn[] = [
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'Code',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'name',
    accessor: 'name',
    header: 'Name',
    tableEditable: false,
    columnWidth: 80,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
];
