import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { Modal } from 'client/components/third-party';
import * as React from 'react';

export interface ComponentProps {
  invoiceIdentifiersToBeSentViaEdi: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatWereAlreadySent: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatCannotBeSent: Array<{ id: number, identifier: string }>;
  handleCancelButtonClicked(): void;
  handleSendInvoicesViaEdiButtonClicked(invoiceIdsToBeSent: Array<{ id: number, identifier: string }>): void;
}
const InvoiceListItemComponent = (invoice: { id: number, identifier: string }) =>
  (<li key={invoice.identifier}>{invoice.identifier}</li>);

export class SendInvoicesViaEdiModal extends React.PureComponent<ComponentProps, {}> {
  handleSendInvoicesViaEdiButtonClicked = () => {
    this.props.handleSendInvoicesViaEdiButtonClicked(this.props.invoiceIdentifiersToBeSentViaEdi);
  }

  render() {
    return (
      <Modal
        id="send-invoices-via-edi-modal"
        data-testid="send-invoices-via-edi-modal"
        animation
        show
        backdrop="static"
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="send-invoices-via-edi-modal-container"
        className="send-invoices-via-edi-modal">
        <GlobalAsyncModalHeader>Send Invoices via EDI</GlobalAsyncModalHeader>
        <Modal.Body>
          <div>
            {this.props.invoiceIdentifiersToBeSentViaEdi.length > 0 &&
              <div>
                These invoices will be sent via EDI:
              <ul data-testid="invoices-to-be-sent">
                  {this.props.invoiceIdentifiersToBeSentViaEdi.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
            {this.props.invoiceIdentifiersThatWereAlreadySent.length > 0 &&
              <div>
                These invoices were already sent via EDI and won't be sent again:
              <ul data-testid="invoices-already-sent">
                  {this.props.invoiceIdentifiersThatWereAlreadySent.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
            {this.props.invoiceIdentifiersThatCannotBeSent.length > 0 &&
              <div>
                These invoices cannot be sent via EDI because the invoices are either not created from a Customer Order or the customer doesn't support EDI:
              <ul data-testid="invoices-cannot-be-sent">
                  {this.props.invoiceIdentifiersThatCannotBeSent.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="send-invoices-via-edi-cancel-button"
          />
          <GlobalSaveButton
            label="Send Invoices via EDI"
            testid="send-invoices-via-edi-modal-send-button"
            onClick={this.handleSendInvoicesViaEdiButtonClicked}
            classNames={['mfc-button-primary']}
            disabled={this.props.invoiceIdentifiersToBeSentViaEdi.length === 0}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
