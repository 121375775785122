import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/supplier-commitment/types';

export interface Input {
  supplierCommitmentId: number;
}

interface SupplierCommitment {
  id: GraphQLTypes.SupplierCommitment['id'];
  salesPlan: {
    id: number;
    identifier: string;
    year: number;
    customer: {
      id: number;
      name: string;
    };
    sellDepartment: {
      id: number;
      identifier: string;
    };
  };
  supplier: Pick<GraphQLTypes.SupplierCommitment['supplier'], 'id' | 'name'>;
  confirmed: GraphQLTypes.SupplierCommitment['confirmed'];
  lastModifiedAt: GraphQLTypes.SupplierCommitment['lastModifiedAt'];
  lastModifiedUser?: {
    firstName: string;
    lastName: string;
  };
}
export interface QueryResponse {
  supplierCommitment: SupplierCommitment;
}

export const Query = gql`
  query SupplierCommitmentSideBarQuery($supplierCommitmentId: Int!) {
    supplierCommitment: GetSupplierCommitment(id: $supplierCommitmentId) {
      id
      salesPlan {
        id
        identifier
        year
        customer {
          id
          name
        }
        sellDepartment {
          id
          identifier
        }
      }
      confirmed
      supplier {
        id
        name
      }
      lastModifiedAt
      lastModifiedUser {
        id
        firstName
        lastName
      }
    }
  }
`;
