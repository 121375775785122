import { buildAdminDetailPage } from '../admin/admin-detail-page';
import FormComponent from '../../components/crud-forms/supplier/supplier-form';
import RecordBarDetailComponent from '../../components/crud-forms/supplier/supplier-record-bar-detail';

export default buildAdminDetailPage({
  table: 'Supplier',
  formName: 'supplierDetailForm',
  FormComponent,
  RecordBarDetailComponent,
  descriptionResolver: supplier => supplier.name,
});
