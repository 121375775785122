import * as React from 'react';
import * as Immutable from 'immutable';
import { FieldArray, getFormValues, FormProps } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { every } from 'lodash';
import { Modal, Button, Form, Row, FormGroup } from 'client/components/third-party';
import { Product } from 'shared/schemas/product';
import { ProductsForm, ProductsFormProps } from './product-form';
import { SpecializeField } from 'client/components/form';
import { ProductListResponseItem } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-container';
import { buildUniqueCartIdentifierConstraint } from 'client/helpers/product-worksheet';
import { OrderMethod, ShippingUnitType, ComboCart } from 'shared/types';
import { Saved } from 'shared/schemas/record';
import { CustomerOrder } from 'shared/schemas/customer-order';
import * as Validators from 'shared/validators';
import * as Actions from 'client/actions/product-worksheet';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';
import { COPGFormValues } from 'client/app/orders/customer-orders/product-worksheet/product-list/modals/add-new-product-modal-container';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import SelectRecentComboModal from 'client/app/orders/customer-orders/product-worksheet/product-list/modals/recent-combos-modal/select-recent-combo-modal-container';
import { wrapComponent } from 'client/hoc/hoc';

type ProductOption = Pick<Product, 'id' | 'identifier' | 'description' | 'activeStatus'>;
const Field = SpecializeField(CustomerOrderProductGroup, { horizontalLabel: false });

export interface OwnProps {
  customerOrder: Pick<Saved<CustomerOrder>, 'orderMethod' | 'shippingUnitType'| 'sellDepartment' | 'customerId' | 'orderStatus'>;
  isShown: boolean;
  isProductSelectedList: boolean[];
  products: ProductOption[];
  handleCancelButtonClicked: () => void;
  handleProductChanged: (productId: number, productFieldIdentifier: string) => void;
  handlePacksPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field: string, value: number) => void;
  handlePiecesPerShippingUnitCalculationInputChange: (productFieldIdentifier: string, field?: 'packSize' | 'packsPerShippingUnit', value?: number) => void;
  handleProductCleared?: () => void;
  handleFormSubmit: () => React.ReactEventHandler<any>;
  invalid: boolean;
  productIds?: Immutable.Set<number>;
  submitButtonText: string;
  id: string;
  title: string;
  disableProductField: boolean;
  showAddProductButton: boolean;
  formName: string;
  initialValues: Partial<COPGFormValues>;
  productListResponseItems: ProductListResponseItem[];
  isCombo: boolean;
  isComboDisabled: boolean;
  isDescriptionDisabled: boolean;
  comboCartList: ComboCart[];
  showRecentCombosButton?: boolean;

  pristine: boolean;
  submitting: boolean;
}

export interface StateProps {
  packsPerShippingUnitDisabled: boolean;
}

export interface DispatchProps {
  handleSelectRecentComboClicked: () => void;
}

type UIProps = StateProps & OwnProps & DispatchProps & FormProps<shame, OwnProps, {}>;

const MAX_LENGTH_2_VALIDATOR = Validators.MAX_LENGTH(2);

class ProductModal extends React.Component<UIProps, {}> {
  configurationIdentifierUnique: Validators.FieldValidator;

  constructor(props: UIProps) {
    super(props);
    this.configurationIdentifierUnique = buildUniqueCartIdentifierConstraint({
      productListResponseItems: props.productListResponseItems,
      initialValues: props.initialValues,
    });
  }

  componentWillReceiveProps(nextProps: UIProps) {
    if ((!this.props.isShown && nextProps.isShown)) {
      const productIds = nextProps.productIds;

      if (productIds) {
        productIds.toJS().forEach((productId, index) => {
          if (productId) {
            this.props.handleProductChanged(productId, `customerOrderProducts[${index}]`);
          }
        });
      }
    }

    // ReduxForm goes into an infinite re-render if validation functions change
    // on every render. So use this poor man's useMemo to prevent that from haooening.
    if (this.props.productListResponseItems !== nextProps.productListResponseItems ||
      this.props.initialValues !== nextProps.initialValues) {
      this.configurationIdentifierUnique = buildUniqueCartIdentifierConstraint({
        productListResponseItems: nextProps.productListResponseItems,
        initialValues: nextProps.initialValues,
      });
    }
  }

  render() {
    const {
      id,
      title,
      isShown,
      invalid,
      submitButtonText,
      handleFormSubmit,
      isProductSelectedList,
      handleCancelButtonClicked,
      customerOrder: { orderMethod, shippingUnitType },
      productIds,
      handleProductChanged,
      handlePacksPerShippingUnitCalculationInputChange,
      handlePiecesPerShippingUnitCalculationInputChange,
      disableProductField,
      products,
      showAddProductButton,
      pristine,
      submitting,
      initialValues,
      packsPerShippingUnitDisabled,
      isCombo,
      isComboDisabled,
      handleSelectRecentComboClicked,
    } = this.props;

    const submitButtonLabel = submitting ? <span className="fa fa-spinner fa-spin" /> : submitButtonText;
    const isSaved = !!initialValues.id;
    const isShowComboCheckBox = ((isSaved && isCombo) || !isSaved) && orderMethod !== OrderMethod.Pack;

    return (
      <Modal
        id={id}
        animation
        backdrop="static"
        show={isShown}
        onHide={handleCancelButtonClicked}>
        <Form horizontal onSubmit={handleFormSubmit()}>
          <SelectRecentComboModal parentFormName={this.props.formName} customerOrder={this.props.customerOrder} comboCartList={this.props.comboCartList} />
          <GlobalAsyncModalHeader>{title}</GlobalAsyncModalHeader>
          <Modal.Body>
            {isShowComboCheckBox &&
              <Row>
                <Field name="isCombo" label={'Combo Cart'} inputColSize={6} disabled={isComboDisabled}/>
                {this.props.customerOrder.orderStatus !== 'Routed' && this.props.showRecentCombosButton &&
                  <div className="mfc-form-button select-recent-combo-button" data-testid="select-recent-combo" onClick={handleSelectRecentComboClicked}>
                    <span className="fa fa-history" /> Select Recent Combo
                  </div>
                }
              </Row>
            }
            <Row>
              <FieldArray<ProductsFormProps>
                name="customerOrderProducts"
                component={ProductsForm}
                savedProductIds={initialValues?.customerOrderProducts ? initialValues.customerOrderProducts.map(p => p.productId) : []}
                orderMethod={orderMethod}
                isProductSelectedList={isProductSelectedList}
                productIds={productIds}
                handleProductChanged={handleProductChanged}
                handlePacksPerShippingUnitCalculationInputChange={handlePacksPerShippingUnitCalculationInputChange}
                handlePiecesPerShippingUnitCalculationInputChange={handlePiecesPerShippingUnitCalculationInputChange}
                disableProductField={disableProductField}
                products={products}
                showAddProductButton={showAddProductButton}
              />
            </Row>
            <Row>
              <hr />
            </Row>
            <Row>
              <FormGroup>
                <Field
                  name="identifier"
                  inputColSize={3}
                  label={orderMethod === OrderMethod.ShippingUnit ? 'Rack' : 'Case'}
                  validators={[this.configurationIdentifierUnique, MAX_LENGTH_2_VALIDATOR]}
                  disabled={every(isProductSelectedList, selected => !selected)} // Disable field if no products selected
                />
                <Field
                  name="description"
                  label="Description"
                  inputColSize={9}
                  disabled={this.props.isDescriptionDisabled}
                />
              </FormGroup>
              <FormGroup>
                {orderMethod === OrderMethod.Pack &&
                  <Field
                    name="packsPerShippingUnit"
                    inputColSize={3}
                    horizontalLabel={false}
                    label={shippingUnitType === ShippingUnitType.Pallet ? 'CPP' : 'PPR'}
                    disabled={packsPerShippingUnitDisabled}
                  />}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <GlobalCancelButton onClick={handleCancelButtonClicked} />
            <Button
              bsStyle="primary"
              bsClass="mfc-button mfc-submit-button"
              type="submit"
              disabled={pristine || invalid || submitting}
              data-testid="modal-submit">
              {submitButtonLabel}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  handleSelectRecentComboClicked: Actions.selectRecentComboButtonClicked,
};

const mapStateToProps = (state, ownProps): StateProps => {
  const { formName } = ownProps;
  const values: any = getFormValues(formName)(state);
  return {
    packsPerShippingUnitDisabled: (values || []).customerOrderProducts.every(cop => !cop.productId),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector>;

export default wrapComponent(ProductModal)<OwnProps, CombinedProps>(
  connector
);
