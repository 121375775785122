import * as React from 'react';
import * as _ from 'lodash';
import * as QueryString from 'query-string';
import { recordType, flattenFragment, columnInfo, columns, formDisplayColumns, defaultValues, tableInfo, TableName } from 'shared/schemas';
import { reduxForm, change, reset, getFormValues } from 'redux-form';
import { msyncQuery, MsyncQueryProps } from 'client/hoc/graphql/query';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { difference, intersection, compact } from 'lodash';
import { withRouter, RouteChildrenProps } from 'react-router';
import { TYPES, toDateStr, ActiveInactive, Arg1, Return } from 'shared/types';
import { RefetchQueriesFunction, withApollo } from 'react-apollo';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { Saved } from 'shared/schemas/record';
import { createSelector } from 'reselect';
import * as State from 'client/state/state';
import { WithWarnUnsaved } from './with-warn-unsaved';
import { DocumentNode } from 'graphql';
import { PureQueryOptions } from 'apollo-client';

interface ContainerProps {
  // come from redux-form
  handleSubmit: () => Promise<boolean>;
  submitting: boolean;
  invalid: boolean;
  pristine: boolean;

  // come from react-redux
  onChangeRecordStatus: () => void;
  goBack: () => void;
  goToDetails: () => void;
  dispatchDefaults: () => void;
  resetForm?: () => void;
  record?: SimpleObject;

  // comes from nav state
  recordBarGoBackToLocation?: string;

  // comes from apollo
  loading?: boolean;
  initialValues?: SimpleObject;
  refreshData?: () => void;

  // comes from withApollo HOC
  client: SimpleObject;
}

interface StateProps {
  record: any;
  recordBarGoBackToLocation: string;
  recordIdToDuplicate?: string;
}

export type DuplicateOptions
  = { type: 'fields', fieldsToRemove: string[] }
  | { type: 'custom', handler: (initialValues: any, props: any) => any };

function clearFieldsOnDuplication(fieldsToClearOnDuplication: string[] | undefined, initialValues: any, props: MsyncQueryProps<ContainerProps & StateProps, {}, {}>) {
  const fieldsToEliminate = new Set(['id', 'identifier', ...(fieldsToClearOnDuplication || [])]);
  const reducedInitialValues = Object.keys(initialValues).reduce((memo, fieldName) => {
    if (!fieldsToEliminate.has(fieldName))
      memo[fieldName] = initialValues[fieldName];

    return memo;
  }, { identifier: (props?.data as shame)?.nextIdentifier });
  return reducedInitialValues;
}

/** adapts a graphql mutation generator to a redux-form onSubmit callback */
const makeSaveWrapper = <
  Table extends TableName,
  Record extends SimpleObject & {id: number},
  Props extends Arg1<Arg1<Return<typeof buildContainerHOC>>> & {initialValues: Partial<Record>}
>(args: {isNewRecord?: true, table: Table}, mutationFn, p: Props) =>
  /** redux-form onSubmit callback that feedsinto a graphql mutation */
  function mutatingReduxFormCallback(newData: Partial<Record>, originalData: Partial<Record>) {
    const initialData = args.isNewRecord ? {} : (originalData || p.initialValues || {});
    if (Array.isArray(newData)) // DVM  2024 07 16 -- I have no idea when (or if) this actually happens 🤷
      return mutationFn(newData);

    const columnInfos = _.values(tableInfo(args.table).columns) // Only care about columns that are part of form, and aren't calculated (only want stuff that will be stored in the DB)
      .filter(c => c.id === 'activeStatus' || c.includeInSubmittedForm || c.formDisplay && !c.calculationSpec && !c.gqlResolver);

    const parentDiff = columnInfos
      .filter(info => !info.fkSpec || (info.fkSpec.belongsTo && !info.fkSpec.through))
      .map(column => [column.type, column.fkSpec?.nativeTableFK ?? column.id])
      .reduce((result, [t, k]) => newData[k] === initialData[k] ? result : Object.assign(result, { [k]: ({
          [TYPES.DATE  ]: v =>                          toDateStr (v    ),
          [TYPES.NUMBER]: v => v === '' ? null : Number.parseInt  (v, 10),
          [TYPES.FLOAT ]: v => v === '' ? null : Number.parseFloat(v    ),
        }[t] ?? _.identity) (newData[k])}),
        { id: newData.id } as Dictionary<shame<'I just want it to work.'>>
      );

    // for new records, if not otherwise specified, and if applicable, default to active
    if (args.isNewRecord && _.isNil(parentDiff.activeStatus) && columns(args.table).find(c => columnInfo(args.table, c).id === 'activeStatus'))
      parentDiff.activeStatus = ActiveInactive.Active;

    // assemble dictionary of child record arrays that must also be mutated
    const childCollectionDiffs = columnInfos.filter(info => info.fkSpec?.hasMany).reduce((d, collectionColumn) => {
      const collectionColumnPropName = collectionColumn.fkSpec?.manyToMany ? `${collectionColumn.id}Id` : collectionColumn.id;
      const initialChildren = initialData[collectionColumnPropName] || [];
      const newChildren = newData[collectionColumnPropName] || [];
      const created = collectionColumn.fkSpec?.manyToMany
        ? difference(newChildren, initialChildren).map(c => ({ [collectionColumn.fkSpec!.foreignTablePK]: c }))
        : newChildren.filter(x => !x.id).map(enfantNouveau => Object.assign(
          Object.keys(enfantNouveau).reduce((resolvedChild, key) => { // childPayload
            const col = columnInfo(collectionColumn.fkSpec!.foreignTable, key);
            return Object.assign(resolvedChild, { [(!!col.fkSpec?.through ? null : col.fkSpec?.nativeTableFK) ?? key]: enfantNouveau[key] });
          }, {}),
          // { [collectionColumn.fkSpec!.nativeTableFK!]: newData.id },
          { [collectionColumn.backRef!.fkSpec!.nativeTableFK]: newData.id },
        ));

      const deleted = collectionColumn.fkSpec?.manyToMany
        ? difference(initialChildren, newChildren).map(c => ({ [collectionColumn.fkSpec!.foreignTablePK]: c }))
        : difference(initialChildren.map(c => c.id), newChildren.map(c => c.id)).map(id => ({ id }));

      const updated = (collectionColumn.fkSpec?.manyToMany ? [] : compact(intersection(initialChildren.map(c => c.id), newChildren.map(c => c.id)))).map(id => {
        const originalChild = initialChildren.find(c => c.id === id);
        const updatedChild = newChildren.find(c => c.id === id);
        const childDelta = formDisplayColumns(collectionColumn.fkSpec!.foreignTable).reduce((c, col) => {
          const info = columnInfo(collectionColumn.fkSpec!.foreignTable, col);
          const childComparisonKey = info.fkSpec?.nativeTableFK ?? info.id;
          if (originalChild[childComparisonKey] !== updatedChild[childComparisonKey]) {
            if (info.fkSpec) {
              if (!info.fkSpec.through)
                c[info.fkSpec.nativeTableFK!] = updatedChild[childComparisonKey];
            } else
              c[childComparisonKey] = updatedChild[childComparisonKey];
          }
          return c;
        }, {}) as Saved<{}>;

        if (Object.keys(childDelta).length > 0)
          childDelta.id = originalChild.id;

        return childDelta;
      }).filter(update => Object.keys(update).length > 0);

      if (created.length || deleted.length || updated.length)
        d[collectionColumn.id] = { created, deleted, updated };

      return d;
    }, {});

    const mutationPayload = Object.assign({}, parentDiff, childCollectionDiffs);
    return !Object.keys(_.omit(mutationPayload, 'id')).length ? undefined : mutationFn(mutationPayload);
  };

const buildContainerHOC = <Table extends TableName>(args: {
  table                     : Table,
  mutationStrings           : { [ k: string ]: DocumentNode },
  onSubmit                  : (fn: any, unknown: any, record: any) => (data: any) => Promise<any>,
  resetApolloStoreAfterSave?: boolean,
  formName                  : string,
  isNewRecord              ?: true,
  duplicateOptions         ?: DuplicateOptions,
}) => <ExternalProps extends React.PropsWithChildren<{}>, WrappedComponentType extends (p: ExternalProps) => JSX.Element>(WrappedComponent: WrappedComponentType) =>
  class Container extends React.Component<React.PropsWithChildren<{
    initialValues: shame,
    submitting: boolean,
    loading: boolean,
    invalid: boolean,
    pristine: boolean,
    refreshData: () => Promise<void>,
    handleSubmit: ((onSubmit: (data: shame) => Promise<boolean>) => Promise<boolean>),
    onChangeRecordStatus: () => void,
    goToDetails: (id: number) => void,
    goBack: () => void,
    resetForm: () => void,
    client: shame,
    record: shame,
    dispatchDefaults: (formName: string, defaultInitialValues: any) => void,
  } & ExternalProps>, {submitted: boolean}> {
    static defaultProps = { initialValues: {} };
    constructor(props) { super(props); this.state = { submitted: false }; }
    componentWillMount() {
      const defaultedInitialValues = Object.assign({}, defaultValues(args.table), this.props.initialValues);
      this.props.dispatchDefaults(args.formName, defaultedInitialValues);
    }

    render() {
      const p = this.props;
      const WC = WrappedComponent as shame<'wipe -- still fixing types and refactoring and stuff.'>;
      const onClose = () => {
        this.setState({ show: false } as shame);
        this.props.goBack();
      };
      return (
        <WC
          {...p}
          onClose={(p as shame<'this is critical for doing the right thing for a "page-like" form vs a "popup-forn"'>).onClose ?? onClose}
          submitting={p.submitting}
          submitted={p.pristine && this.state.submitted}
          invalid={p.invalid}
          pristine={p.pristine}
          onChangeRecordStatus={p.onChangeRecordStatus}
          formName={args.formName}
          resetForm={p.resetForm}
          initialValues={p.initialValues}
          record={p.record}
          loading={p.loading}
          duplicateOptions={args.duplicateOptions}
          handleSubmit={p.handleSubmit(async data => {
            let navigatedAway = false;
            try {
              const mutagens = Object.keys(args.mutationStrings).reduce((mutations, name) => {
                const mutationFnToWrap = p[`_${name}`]; // see `mutagens` array near end of `formContainer` HOC generator below.
                const mutatingReduxFormCallback = makeSaveWrapper(args, mutationFnToWrap, p);
                return Object.assign(mutations, {[name]: mutatingReduxFormCallback});
              }, {});

              const result = await args.onSubmit(mutagens, p.initialValues, p)(data);
              if (_.isUndefined(result))
                return true; // There was nothing to save, so no need to bother with anything else

              const id = result.data.data.id;
              if (args.resetApolloStoreAfterSave === true)
                await p.client.resetStore();

              if (p.refreshData)
                await p.refreshData();

              if (args.isNewRecord) {
                navigatedAway = true;
                p.goToDetails(id);
              }
            } catch (error) {
              // Error should have been handled by the global error handler, so just log it
              console.info('Problem submitting form', error.message, error.stack);
              return false;
            } finally {
              if (!navigatedAway)
                this.setState({ submitted: true });
            }

            return true;
          })}
        />
      );
    }
  };

const isRefetchQueriesFunction = (x: any): x is RefetchQueriesFunction => _.isFunction(x);

export const formContainer = <Table extends TableName>(opt: Arg1<typeof buildContainerHOC<Table>> & {
  queryString              ?: DocumentNode,
  refetchQueries           ?: { [ mutationName: string ]: Array<string | PureQueryOptions> | RefetchQueriesFunction }, // support function-based or string-name based refetches
  cleanData                ?: (input: any) => any,
  destroyOnUnmount         ?: boolean,
  initialValues            ?: any,
}) => {
  const type = recordType(opt.table);
  const formatData = createSelector([(data: shame) => data.data], data => {
    if (!data) return undefined;
    const cleaner = opt.cleanData ?? _.identity;
    const flattened = flattenFragment(data, opt.table);
    return cleaner(flattened);
  });

  const formDataFromGql__HOC = !opt.queryString ? (fn => fn) : msyncQuery<
    {},
    ContainerProps & StateProps & RouteChildrenProps<{ id: string }>,
    { loading: boolean, id: string, initialValues: any, refreshData: shame }
  >(opt.queryString, {
    skip: p => !p.match?.params.id && !p.recordIdToDuplicate,
    options: p => ({ variables: { type, id: opt.isNewRecord ? p.recordIdToDuplicate : p.match?.params.id } }),
    props: p => {
      const initialValues = formatData(p.data);
      const id = (opt.isNewRecord ? undefined : (p.ownProps.match?.params.id ?? (p as shame).match?.params.id));
      //////
      // console.log({formContainerDatagen: p, initialValues, id, queryString: opt.queryString});
      /////
      return {
        loading: p.data.loading,
        refreshData: (...args: any[]) => {
          // console.log({huh: 'refreshing', p, isNewRecord, id, recordType});
          if (id)
            return p.data.refetch(...(args.length > 0 ? args : [{type, id}]));
        },
        id,
        initialValues // redux-form requires a prop called 'initialValues' for form initialization
          : !initialValues || !opt.isNewRecord      ? initialValues
          : !opt.duplicateOptions                   ? clearFieldsOnDuplication([], initialValues, p) // default includes identifier
          : opt.duplicateOptions.type === 'fields'  ? clearFieldsOnDuplication(opt.duplicateOptions.fieldsToRemove, initialValues, p)
          :                                           opt.duplicateOptions.handler(initialValues, p),
      };
    },
  });

  const mutationHOX = Object
    .keys(opt.mutationStrings)
    .reverse()
    .map(mutationName => msyncMutation(opt.mutationStrings[mutationName], {
      props: p => ({
        // DVM 2024 07 16 -- I am not sure this is why -- it could be for pure spite - but I think the prefix of underscore is to prevent colision with a prop for the mutation string, or perhaps for the mutation function... not clear yeat
        [`_${mutationName}`]: data => p.mutate({
            variables: { input: data },
            refetchQueries: (isRefetchQueriesFunction(opt.refetchQueries?.[mutationName])
              ? (opt.refetchQueries![mutationName] as RefetchQueriesFunction)(p.ownProps)
              : opt.refetchQueries?.[mutationName]) ?? [],
          }),
        }),
      })
    );

  const withFormContainer = buildContainerHOC(opt);
  return <ExternalProps extends React.PropsWithChildren<{}>, WrappedComponentType extends (p: ExternalProps) => JSX.Element>(WrappedComponent: WrappedComponentType) => {
    let WC = WrappedComponent as shame<'wip -- all this shit below must have stronger types first.'>; // NOTE: earlier applied HOCs could theoretically access props from later applied (outer) HOCs
    WC = withFormContainer(WC);
    WC = WithWarnUnsaved({ recordIdProperty: 'record.id' })(WC);
    WC = withApollo(WC);
    const reduxFormWrapper = reduxForm({ form: opt.formName, enableReinitialize: true, initialValues: opt.initialValues, destroyOnUnmount: opt.destroyOnUnmount !== false });
    WC = reduxFormWrapper(WC);
    for (const gqlMutationFromMutationStringsProp__HOC of mutationHOX)
      WC = gqlMutationFromMutationStringsProp__HOC(WC);

    WC = formDataFromGql__HOC(WC);

    // connecting Redux in 2 steps allows mapDispatch to utilize results of mapState -- yes, mapDispatch wrapper is applied first, so that in the nested HOCs, mapState (outer HOC) may inject props first
    WC = connect(undefined, (dispatch, ownProps: shame) => {
      const pattern = /(.+)\/(create|details\/\d+)/;
      const matches = `${ownProps.location.pathname}`.match(pattern);
      const listPageRoute = matches && matches[1] || `/admin/${opt.table}`;
      return {
        goBack: () => dispatch(push(ownProps.recordBarGoBackToLocation || listPageRoute)),
        goToDetails: (id: number) => dispatch(push(`${listPageRoute}/details/${id}`)),
        dispatchDefaults: (form, defaultInitialValues) => Object.keys(defaultInitialValues).forEach(field => dispatch(change(form, field, defaultInitialValues[field]))),
        onChangeRecordStatus: (form, field, value) => dispatch(change(form, field, value)),
        resetForm: form => dispatch(reset(form)),
      };
    })(WC);
    WC = connect((state: State.Type, props: ContainerProps & RouteChildrenProps): StateProps => {
      const values = getFormValues(opt.formName)(state);
      const record = values ? values : null;
      const parsedRecordIdToDuplicate = QueryString.parse(props.location.search).id;
      const recordIdToDuplicate = parsedRecordIdToDuplicate
        ? (Array.isArray(parsedRecordIdToDuplicate) ? parsedRecordIdToDuplicate[0] : parsedRecordIdToDuplicate)
        : undefined;

      return {
        record,
        recordBarGoBackToLocation: State.recordBarGoBackToLocation(state),
        recordIdToDuplicate,
      };
    })(WC);

    WC = withRouter(WC);
    return WC as WrappedComponentType;
  };
};
