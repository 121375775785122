import * as React from 'react';
import * as Immutable from 'immutable';
import { CartProductListItem } from './cart-product-list-item';
import { ComboCartProductListItem } from './combo-cart-product-list-item';
import { Button } from 'client/components/third-party';
import { ProductShipmentConfiguration } from 'client/types/product-worksheet';
import { ComboCart, OrderMethod, ShippingUnitOrderMethod } from 'shared/types';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';
import { RemoveProductModal } from 'client/app/orders/customer-orders/product-worksheet/product-list/modals/remove-product-modal';
import { AddFromSalesPlanButton } from 'client/app/orders/customer-orders/product-worksheet/product-list/add-from-sales-plan-button';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { ApolloRefetch } from 'client/types';
import { ProgressSpinner } from 'client/components/progress-spinner';

export interface Props {
  customerOrderId: CustomerOrderId,
  customerOrderDetails?: { orderMethod: OrderMethod },
  products: ProductShipmentConfiguration[],
  comboCarts: ComboCart[],
  selectedProduct: any,
  checkedProductIds: Immutable.Set<number>,
  checkedComboCartIds: Immutable.Set<number>,
  showAddProductButton: boolean,
  showAddFromSalesPlanButton: boolean,
  showNewRackTypeButton: boolean,
  showRemoveButton: boolean,
  showCreateComboCartButton: boolean,
  salesPlanIdentifier: string | undefined,
  refreshProductsList: ApolloRefetch,
  loading: boolean,
  handleProductClicked(productId: number): void,
  handleProductChecked(productId: number): void,
  handleComboCartChecked(comboCartId: number): void,
  handleAddNewProductButtonClicked(): void,
  handleAddNewRackTypeButtonClicked(): void,
  handleRemoveProductButtonClicked(): void,
  handlePencilClicked(customerOrderProductGroupId: CustomerOrderProductGroupId): void,
  handleTrashCanClicked(customerOrderProductGroupId: CustomerOrderProductGroupId): void,
  handleComboCartPencilClicked(customerOrderProductGroupId: CustomerOrderProductGroupId): void,
  handleRemoveButtonClicked(): void,
}

export const ProductList = (props: Props) => (
    <div className="product-worksheet-products-list-sidebar">
      <div className="product-worksheet-products-list-sidebar-top">
        <h3>Products</h3>
        {props.loading && <ProgressSpinner label="" /> }
        {!props.loading && (
          <>
            <ul className="product-worksheet-products-list">
              {props.products.map(product =>
                <CartProductListItem
                  key={product.productId}
                  product={product}
                  isSelected={product.productId === props.selectedProduct?.productId}
                  isChecked={props.checkedProductIds.includes(product.productId)}
                  handleProductChecked={props.handleProductChecked}
                  handleProductClicked={props.handleProductClicked}
                  handlePencilClicked={props.handleComboCartPencilClicked}
                  handleTrashCanClicked={props.handleTrashCanClicked}
                />,
              )}
              {props.comboCarts.map(comboCart =>
                <ComboCartProductListItem
                  comboCart={comboCart}
                  key={comboCart.id}
                  orderMethod={props.customerOrderDetails ? props.customerOrderDetails.orderMethod : ShippingUnitOrderMethod}
                  selectedProductId={props.selectedProduct.productId}
                  isChecked={props.checkedComboCartIds.includes(comboCart.id)}
                  handleProductClicked={props.handleProductClicked}
                  handleComboCartChecked={props.handleComboCartChecked}
                  handleTrashCanClicked={props.handleTrashCanClicked}
                  handleComboCartPencilClicked={props.handleComboCartPencilClicked}
                />,
              )}
            </ul>
          </>
        )}
      </div>

      <div className="product-worksheet-products-list-sidebar-bottom">
        <div className="text-center">
          {props.showAddFromSalesPlanButton && <AddFromSalesPlanButton customerOrderId={props.customerOrderId} salesPlanIdentifier={props.salesPlanIdentifier} refreshProductsList={props.refreshProductsList} />}
          {props.showAddProductButton      && <Button bsClass="mfc-button mfc-submit-button mfc-submit-button-primary" onClick={props.handleAddNewProductButtonClicked }> Add Product </Button> }
          {props.showRemoveButton          && <Button bsClass="mfc-button mfc-clear-button mfc-clear-button-default"   onClick={props.handleRemoveProductButtonClicked }> Remove </Button> }
          {props.showNewRackTypeButton     && <Button bsClass="mfc-button mfc-submit-button mfc-submit-button-primary" onClick={props.handleAddNewRackTypeButtonClicked}> New Rack Type </Button> }
          {props.showCreateComboCartButton && <Button bsClass="mfc-button mfc-submit-button mfc-submit-button-primary" onClick={props.handleAddNewRackTypeButtonClicked}> Create Combo </Button> }
        </div>
      </div>

      <RemoveProductModal handleRemoveButtonClicked={props.handleRemoveButtonClicked} />
    </div>
  );
