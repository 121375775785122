import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'storeOnHandReport';
export const reportType = ReportTypes.StoreOnHand;

export enum FormFields {
  scanBased = 'scanBased',
  poBased = 'poBased',
  customerId = 'customerId',
  sellDepartmentId = 'sellDepartmentId',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  productIds = 'productIds',
  mfcAreaIds = 'mfcAreaIds',
  salesPlanId = 'salesPlanId',
  regionIds = 'regionIds',
  marketIds = 'marketIds',
  storeIds = 'storeIds',
  includeStoresNotShipped = 'includeStoresNotShipped',
  dateRangeYearComparison = 'dateRangeYearComparison',
  primaryGlobalAll = 'primaryGlobalAll',
  filterByCategoryOrSalesPlan = 'filterByCategoryOrSalesPlan',
}

export interface FormValues {
  [FormFields.customerId]: number | undefined;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.salesPlanId]: number | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.regionIds]: SelectableValue | undefined;
  [FormFields.marketIds]: SelectableValue | undefined;
  [FormFields.storeIds]: SelectableValue | undefined;
  [FormFields.includeStoresNotShipped]: boolean;
  [FormFields.primaryGlobalAll]: PrimaryGlobalAll;
  [FormFields.filterByCategoryOrSalesPlan]: FilterByCategoryOrSalesPlan;
}

export enum FilterByCategoryOrSalesPlan {
  ByCategory = 'ByCategory',
  BySalesPlan = 'BySalesPlan',
}
