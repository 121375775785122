import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect, ConnectedProps } from 'react-redux';
import assertCompatible from 'shared/helpers/assert-compatible';
import { InvoiceCreatePage, UIProps } from './ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {
}

// Not real sure why we're even bothering with this... maybe just to get the shallowEqual on props changes?
const connector = connect();

const component = flowRight(
  connector,
)(InvoiceCreatePage);

type CombinedProps =
  ContainerProps &
  ConnectedProps<typeof connector>;

assertCompatible<UIProps, CombinedProps>();
export const InvoiceCreatePageContainer = (props: ContainerProps) => propToComponent(component, props);
