import * as moment from 'moment-timezone';
import { TIME_ZONE } from 'shared/time-zone';

interface IsValidDateStr {
  __datestr__: void;
}
interface IsValidTimeStr {
  __timestr__: void;
}
interface IsValidDateTimeStr {
  __datetimestr__: void;
}

export const DATE_TIME_STR_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSZZ';

export function compareDateStr(a: DateStr | undefined, b: DateStr | undefined): number {
  return moment.tz(a, TIME_ZONE).diff(moment.tz(b, TIME_ZONE), 'days');
}

export function compareDateTimeStr(a: DateTimeStr | undefined, b: DateTimeStr | undefined): number {
  return moment.tz(a, TIME_ZONE).diff(moment.tz(b, TIME_ZONE));
}

export function isDateTimeStrWithinRange(a: DateTimeStr, b: DateTimeStr, rangeMs: number): boolean {
  const aa = Date.parse(a);
  const bb = Date.parse(b);
  return Math.abs(bb - aa) <= rangeMs;
}

export type DateStr = string & IsValidDateStr;
export type TimeStr = string  & IsValidTimeStr;
export type DateTimeStr = string & IsValidDateTimeStr;
/**
 * Use this type to enforce strings properly formatted in Iso8601 at both runtime and compile time.
 * Guard relevant code paths with isDateIso8601 to enter a dataflow with the type.
 */
export type DateIso8601 = string & {_Iso8601String: 'not really a property'};
export function isDateIso8601(s: any): s is DateIso8601 {
  return typeof(s) === 'string' && Date.parse(s).toString() === s;
}

function isValidDateStr(str: string): str is DateStr {
  return true;
}

function isValidDateTimeStr(str: string): str is DateTimeStr {
  return true;
}

export function checkValidDateStr(str: string): str is DateStr {
  return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

function checkValidTimeStr(str: string): str is TimeStr {
  return str.match(/^\d{2}:\d{2}(:\d{2})?$/) !== null;
}

export function toTimeStr(time: Date | moment.Moment | string | undefined, includeSeconds: boolean = false): TimeStr {
  if (typeof time === 'string' && checkValidTimeStr(time)) {
    return time as TimeStr;
  }

  const timeString = internalDateTimeConverter(time, includeSeconds ? 'HH:mm:ss' : 'HH:mm');
  if (checkValidTimeStr(timeString)) {
    return timeString as TimeStr;
  }

  throw new Error(`Shouldn't get here (invalid toTimeStr provided): ${time}`);
}

/**
 * DateStrs are the internal representation of dates used in MSync, in the form YYYY-MM-DD.
 * If you have another date format, (e.g. MM/DD/YYYY), it's best to call parseDate in date-helpers.ts
 */
export function toDateStr(date: Date | moment.Moment | string | undefined | null): DateStr {
  if (typeof date === 'string' && checkValidDateStr(date)) {
    return date;
  }

  const dateString = internalDateTimeConverter(date, 'YYYY-MM-DD');
  if (isValidDateStr(dateString)) {
    return dateString;
  }

  throw new Error(`Shouldn't get here (invalid toDateStr provided): ${date}`);
}

export function toDateTimeStr(datetime: Date | moment.Moment | string | undefined | null): DateTimeStr {
  const dateTimeString = internalDateTimeConverter(datetime, DATE_TIME_STR_FORMAT);
  if (isValidDateTimeStr(dateTimeString)) {
    return dateTimeString;
  }

  throw new Error(`Shouldn't get here (invalid toDateTimeStr provided): ${datetime}`);
}

export const dayAfter = (day: DateStr) => toDateStr(moment.tz(day, TIME_ZONE).add(1, 'day'));

function internalDateTimeConverter(datetime: Date | moment.Moment | string | undefined | null, format: string): string {
  let theMoment: moment.Moment;

  // Should be able to just call moment(date) to turn either a Date or a Moment into
  // a Moment object. But due to what appears to be an incompatibility between different
  // versions of Moment that are being used in this codebase, we get the following
  // error when trying to moment() a Moment returned from the DatePicker we're using...
  //
  // Error message: moment.js:140 Uncaught TypeError: Array.prototype.some called on null or undefined
  //
  // Workaround is to just check that the thing is already a Moment or not.
  if (moment.isMoment(datetime)) {
    theMoment = datetime as moment.Moment;

    // This nonsense is all about time zones. If the thing is already a moment, need to make sure it's in Eastern
    // Time so when it gets formatted to show just the date it's the date we actually want.
    if (theMoment.tz) {
      theMoment.tz(TIME_ZONE);
    } else {
      theMoment = moment.tz(theMoment.format(DATE_TIME_STR_FORMAT), TIME_ZONE);
    }
  } else {
    theMoment = moment.tz(datetime, TIME_ZONE);
  }

  if (!theMoment.isValid()) {
    throw new Error(`Invalid date: ${theMoment.creationData().input}`);
  }

  return theMoment.format(format);
}
