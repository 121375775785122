import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Helpers from './helpers';
import * as Types from './types';

interface SuccessfulParsedSalesPlan {
  success: true;
  parsed: Types.ImportableSalesPlan;
}
interface FailedParsedSalesPlan {
  success: false;
  reason: string;
}
type ParsedSalesPlanResult = SuccessfulParsedSalesPlan | FailedParsedSalesPlan;
const genericFailureMessage = 'The file cannot be processed. Please verify that the format of the spreadsheet is correct.';
export async function getImportableSalesPlan(workBookContainer: ExcelCommon.WorkBookContainer): Promise<ParsedSalesPlanResult> {
  try {
    const workBook = await Helpers.getSalesPlanWorkBookFromWorkBookContainer(workBookContainer);
    if (!workBook) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const workSheet = await Helpers.getSalesPlanWorkSheet(workBook);
    if (!workSheet) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const workSheetData = await Helpers.getSalesPlanWorkSheetData(workSheet);
    if (workSheetData.length === 0 || Object.keys(workSheetData[0]).length < 1) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const headerData = Helpers.getHeaderData(workSheetData);

    if (!headerData.customerIdentifier) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    if (!headerData.productionYear) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    if (!headerData.productionSeason) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const products = Helpers.getProducts(workSheetData);

    return {
      success: true,
      parsed: {
        customerIdentifier: headerData.customerIdentifier,
        productionSeason: headerData.productionSeason,
        productionYear: headerData.productionYear,
        products,
      },
    };
  } catch (error) {
    return {
      success: false,
      reason: genericFailureMessage,
    };
  }
}
