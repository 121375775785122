import * as React from 'react';
import * as Menu from './menu';

export interface Props {
  id: number;
  items: Menu.RowMenuItem[];
  record?: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class Component extends React.Component<Props, State> {
  public render() {
    const { id, items, record } = this.props;

    return (
      <div className="row-menu mfc-dropdown">
        <Menu.Component id={id} items={items} record={record} />
      </div>);
  }
}

export default Component;
