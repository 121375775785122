import * as React from 'react';
import { Field } from 'redux-form';
import { LabeledSelect, LabeledInput } from 'client/components/form';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import { ColumnAlignment, SelectableRow } from 'client/components/selectable/types';
import LabeledDateRange from 'client/components/form/labeled-date-range';
import { DateStr, ImmutableDateRange, ReportTypes, INPUT_TYPES, LoadTrackingSortByOptions } from 'shared/types';
import { FormGroup, Col, Row } from 'client/components/third-party';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { ReportUserParams } from 'client/components/report-user-params';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { HmgOpsReportType } from '../hmg-ops/constants';
import { SimpleRadio } from 'client/components/simple-components/simple-radio';
import { MutationStatus } from 'client/actions/mutations';
import { DropDownOptions } from 'client/types';

const emptyArray = [];
const optionTextFormatter = option => option.value;

export interface LabeledSelectFieldProps {
  name: string;
  options: DropDownOptions;
  loading?: boolean;
  handleChange?(id: number): void;
}

interface LabeledInputFieldProps {
  name: string;
  value: string;
  handleChange?(input: string): void;
}

interface LabeledDateRangeFieldProps {
  label: string;
  handleChange(dateRange: ImmutableDateRange): void;
  beginDate?: DateStr;
  endDate?: DateStr;
  disabled?: boolean;
}

export interface LabeledSelectableFieldProps {
  name: string;
  options?: SelectableRow[];
  loading?: boolean;
  handleChange?(ids: number[]): void;
}

interface SimpleRadioProps {
  value: string;
  handleChange(key: string): void;
}

export class Customer extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 20 },
    { alignment: ColumnAlignment.Left, width: 80 },
  ];
  render() {
    return (
      <Col sm={12}>
        <FormGroup>
          <Field
            name={this.props.name}
            component={LabeledSelectable}
            handleChange={this.props.handleChange}
            inputColSize={4}
            label="Customer"
            cols={this.cols}
            options={this.props.options || emptyArray}
            loading={this.props.loading}
            labelColSize={4}
            offset={0}
            required
            horizontalLabel={false}
            testid="report-customer"
          />
        </FormGroup>
      </Col>
    );
  }
}

export class Supplier extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <FormGroup>
          <Field
            name={this.props.name}
            component={LabeledSelect}
            handleChange={this.props.handleChange}
            inputColSize={4}
            label="Supplier"
            options={this.props.options || emptyArray}
            loading={this.props.loading}
            labelColSize={4}
            offset={0}
            textFormatter={optionTextFormatter}
            valueField="value"
            required
            horizontalLabel={false}
            testid="report-supplier"
          />
        </FormGroup>
      </Col>
    );
  }
}

export class DateRange extends React.PureComponent<LabeledDateRangeFieldProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <FormGroup>
          <LabeledDateRange
            label={this.props.label || 'Date Range'}
            testid="date-range"
            onChange={this.props.handleChange}
            beginDate={this.props.beginDate}
            endDate={this.props.endDate}
            offset={0}
            labelColSize={5}
            inputColSize={5}
            className="date-range-with-left-aligned-text"
            disabled={this.props.disabled}
          />
        </FormGroup>
      </Col>
    );
  }
}

export class Carrier extends React.PureComponent<LabeledSelectFieldProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <FormGroup>
          <Field
            multi
            name={this.props.name}
            component={LabeledSelect}
            handleChange={this.props.handleChange}
            inputColSize={4}
            label="Carriers"
            options={this.props.options || emptyArray}
            labelColSize={4}
            offset={0}
            textFormatter={optionTextFormatter}
            valueField="value"
            required
            horizontalLabel={false}
            testid="report-carrier"
          />
        </FormGroup>
      </Col>
    );
  }
}

export class LoadTrackingReportHeader extends React.PureComponent<LabeledInputFieldProps, {}> {
  render() {
    return (
      <FormGroup>
        <Col sm={12}>
          <Field
            name={this.props.name}
            component={LabeledInput}
            type={INPUT_TYPES.TEXT}
            handleChange={this.props.handleChange}
            inputColSize={10}
            placeholder="Load Tracking List"
            label="Report Header"
            labelColSize={10}
            offset={1}
            textFormatter={optionTextFormatter}
            valueField="value"
            horizontalLabel={false}
            testid="load-tracking-sidebar-report-header"
            value={this.props.value}
            id="load-tracking-report-header-field"
          />
        </Col>
      </FormGroup>
    );
  }
}

export class SupplierOrder extends React.PureComponent<LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 20 },
    { alignment: ColumnAlignment.Left, width: 15 },
    { alignment: ColumnAlignment.Left, width: 15 },
    { alignment: ColumnAlignment.Left, width: 25 },
    { alignment: ColumnAlignment.Left, width: 25 },
  ];
  render() {
    return (
      <Col sm={8}>
        <FormGroup>
          <Field
            name={this.props.name}
            component={LabeledSelectable}
            inputColSize={12}
            label="Supplier Orders"
            cols={this.cols}
            options={this.props.options || emptyArray}
            loading={this.props.loading}
            labelColSize={12}
            offset={0}
            horizontalLabel={false}
            testid="report-supplier-orders"
            handleChange={this.props.handleChange}
            required
          />
        </FormGroup>
      </Col>
    );
  }
}

export class ReportType extends React.PureComponent<SimpleRadioProps, {}> {
  render() {
    return (
      <FormGroup>
        <Col sm={12}>
          <SimpleRadio
            label="Detail report"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={HmgOpsReportType.Detail}
            testid="transportation-reports-hmg-ops-report-type" // generated as transportation-reports-hmg-ops-report-type-Detail
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
        <Col sm={12}>
          <SimpleRadio
            label="Summary report"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={HmgOpsReportType.Summary}
            testid="transportation-reports-hmg-ops-report-type" // generated as transportation-reports-hmg-ops-report-type-Summary
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
        <Col sm={12}>
          <SimpleRadio
            label="Picas export"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={HmgOpsReportType.Picas}
            testid="transportation-reports-hmg-ops-report-type" // generated as transportation-reports-hmg-ops-report-type-Picas
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
      </FormGroup>
    );
  }
}

interface LoadTrackingSortByProps {
  value: string;
  handleChange(key: string): void;
}

export class LoadTrackingSortBy extends React.PureComponent<LoadTrackingSortByProps, {}> {
  render() {
    return (
      <FormGroup>
        <Col sm={12}>
          <SimpleRadio
            label="Load Prefix"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={LoadTrackingSortByOptions.LoadPrefix}
            testid="load-tracking-sidebar-load-prefix-radio-button"
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
        <Col sm={12}>
          <SimpleRadio
            label="Load Number"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={LoadTrackingSortByOptions.LoadNumber}
            testid="load-tracking-sidebar-load-number-radio-button"
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
        <Col sm={12}>
          <SimpleRadio
            label="Trailer Number"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={LoadTrackingSortByOptions.TrailerNumber}
            testid="load-tracking-sidebar-trailer-number-radio-button"
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
        <Col sm={12}>
          <SimpleRadio
            label="Store Delivery Date"
            onChange={this.props.handleChange}
            value={this.props.value}
            optionValue={LoadTrackingSortByOptions.StoreDeliveryDate}
            testid="load-tracking-sidebar-store-delivery-date-radio-button"
            labelColSize={2}
            inputColSize={10}
          />
        </Col>
      </FormGroup>
    );
  }
}

interface MasterBolSidebarProps extends ReportSidebarProps {
  selectedHmgOpsReportType: string;
  handleHmgOpsReportTypeChanged(key: string): void;
}

interface LoadTrackingListSidebarProps extends ReportSidebarProps {
  selectedLoadTrackingSortByOption: string;
  loadTrackingReportHeader: string;
  loadTrackingReportHeaderName: string;
  handleLoadTrackingSortByOptionChanged(key: string): void;
}

interface ReportSidebarProps {
  reportType: ReportTypes;
  downloadButtonDisabled: boolean;
  handleDownloadExcelReportClicked(): void;
  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;
  pristine: boolean;
  excelDownloadStatus: MutationStatus;
  reportFormName: string;
  selectedPresetId: number | undefined;
  isLoadingPreset: boolean;
}

export class ShippingStatusWarehouseSidebar extends React.PureComponent<ReportSidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            testid="download-excel-report-button"
            actionStatus={this.props.excelDownloadStatus}
          />
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}

export class HmgOpsSidebar extends React.PureComponent<MasterBolSidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar">
        <div className="mfc-form-stacked-label report-sidebar-heading">Report Options</div>

        <Row className="report-sidebar-field-container">
          <ReportType
            value={this.props.selectedHmgOpsReportType}
            handleChange={this.props.handleHmgOpsReportTypeChanged}
          />
        </Row>

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            testid="download-excel-report-button"
            actionStatus={this.props.excelDownloadStatus}
          />
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}

export class LoadTrackingListSidebar extends React.PureComponent<LoadTrackingListSidebarProps, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar load-tracking-sidebar">
        <div className="mfc-form-stacked-label report-sidebar-heading report-sidebar-heading-with-margin">Report Options</div>

        <Row className="report-sidebar-field-container">
          <div className="report-sidebar-field">
            <div className="mfc-form-stacked-label">Sort By</div>
            <LoadTrackingSortBy
              value={this.props.selectedLoadTrackingSortByOption}
              handleChange={this.props.handleLoadTrackingSortByOptionChanged}
            />
          </div>
        </Row>

        <Row className="load-tracking-report-header-container">
          <LoadTrackingReportHeader
            value={this.props.loadTrackingReportHeader}
            name={this.props.loadTrackingReportHeaderName}
          />
        </Row>

        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            testid="download-excel-report-button"
            actionStatus={this.props.excelDownloadStatus}
          />
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          reportFormName={this.props.reportFormName}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}
