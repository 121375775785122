import gql from 'graphql-tag';

export const getReportUserParamsQuery = () => gql`
query GetReportUserParams($input: GetReportUserParamsInput!) {
  response: GetReportUserParams(input: $input) {
    reportPreset {
      id
      params
    }
  }
}`;

export interface WithPresetsQueryResponse {
  response: {
    presets: Array<{
      id: number;
      prefix: string;
      name: string;
    }>;
  };
}

export const AllPresetsQuery = gql`
  query GetAllPresetsForReportType($input: GetAllPresetsForReportTypeInput!) {
    response: GetAllPresetsForReportType(input: $input) {
      presets {
        id
        prefix
        name
        userId
        userDefault
        reportDefault
      }
    }
  }
`;
