import buildAdminCreatePage from 'client/containers/admin/admin-create-page';
import FormComponent        from '../overview/customer-order-form';
import RecordBarActions from '../record-bar-actions';

export default buildAdminCreatePage({
  table: 'customerOrders',
  formName: 'CustomerOrderForm',
  FormComponent,
  OptionalStatusToggle: RecordBarActions,
});
