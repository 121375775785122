import { connect, ConnectedProps } from 'react-redux';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import * as Actions from './actions';
import * as UI from './ui';
import * as Query from './query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { withSaveConfirmation } from 'client/hoc/with-save-confirmation';
import { saveConfirmationOptionsForInvoice } from '../shared/save-confirmation';
import { msyncQuery } from 'client/hoc/graphql/query';

export interface OwnProps {
  invoiceId: number;
  refetchQueries: string[];
}

const mapDispatchToProps = {
  handleAddClicked: Actions.handleAddClicked,
  handleCloseClicked: Actions.onAddRoutePlansModalCloseClicked,
};

interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

interface WithInvoiceInitialValues {
  initialValues?: {
    ediInvoiced: boolean;
    transferredToAccounting: boolean;
    invoiceDownloaded: boolean;
    invoiceEmailed: boolean;
  };
}

const withInvoiceInfo = msyncQuery<Query.QueryResponse, OwnProps, WithInvoiceInitialValues, Query.Input>(Query.Query, {
  alias: 'withInvoiceInfo',
  skip(ownProps) {
    return ownProps.invoiceId === undefined;
  },
  options(ownProps) {
    if (ownProps.invoiceId === undefined) {
      throw new Error('Must have an invoice ID');
    }
    return {
      variables: {
        invoiceId: ownProps.invoiceId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithInvoiceInitialValues {
    const { data } = props;
    if (data.loading || data.getInvoice === undefined) {
      return {
      };
    }
    const invoice = data.getInvoice.invoice;

    return {
      // Needed for save confirmation
      initialValues: {
        ediInvoiced: invoice.ediInvoiced,
        transferredToAccounting: invoice.transferredToAccounting,
        invoiceDownloaded: !!invoice.firstDownloadedAt,
        invoiceEmailed: !!invoice.emailedAt,
      },
    };
  },
});

const connector = connect(undefined, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export type ComponentProps =
  OwnProps &
  WithInvoiceInitialValues &
  WithSaveConfirmationProps &
  PropsFromRedux;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.OwnProps, ComponentProps>();

const component = flowRight(
  connector,
  withInvoiceInfo,
  withSaveConfirmation(saveConfirmationOptionsForInvoice),
)(UI.AddRoutePlansModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
