import { tableName, property, definePresentation, belongsTo, manyToMany, setSchemaOptions, required } from './dsl';
import { TYPES, DISPLAY_TYPES, INPUT_TYPES, ActiveInactive, PrimaryGlobal, DateStr, KnownSellDepartment } from '../types';

import { Customer, CustomerId } from './customer';
import { Region } from './region';
import { Market, MarketId } from './market';
import { MfcArea } from './mfc-area';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { CustomerDisplayValueResolver, RegionDisplayValueResolver, MarketDisplayValueResolver, MerchandiserDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { Merchandiser } from './merchandiser';
import { Supplier } from './supplier';

export type StoreId = Flavor<number, 'storeId'>;
export type StoreIdentifier = Flavor<string, 'storeIdentifier'>;

@tableName('stores')
export class Store implements IRecord {
  static readonly SchemaName = 'Store';

  id?: StoreId;
  @property @required identifier: StoreIdentifier;

  @property address1?: string | null;
  @property address2?: string | null;
  @property city?: string | null;
  @property state?: string | null;
  @property zip?: string | null;
  @property country?: string | null;
  @property @required activeStatus: ActiveInactive;
  @property primaryGlobal: PrimaryGlobal;
  @property openDate?: DateStr | null;
  @property closeDate?: DateStr | null;
  @property latitude?: number | null;
  @property longitude?: number | null;
  @property managerName?: string | null;
  @property managerPhone?: string | null;
  @property managerFax?: string | null;
  @property managerEmail?: string | null;
  @property indoorDeliveryHours?: string | null;
  @property indoorDeliveryLocation?: string | null;
  @property outdoorDeliveryHours?: string | null;
  @property outdoorDeliveryLocation?: string | null;
  @property villageRestrictions?: string | null;
  @property encoreStore?: boolean | null;

  @belongsTo('suppliers', { nativeTableFK: 'primarySupplierId', foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property primarySupplier?: Supplier | null;
  primarySupplierId?: number | null;

  @belongsTo('merchandisers', { nativeTableFK: 'merchandisingSupervisorId', foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property merchandisingSupervisor?: Merchandiser | null;
  merchandisingSupervisorId?: number | null;

  @belongsTo('markets')
  @property market?: Market;
  marketId?: MarketId | null;

  @belongsTo('stores', { foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier'], alias: 'comparableStore', nativeTableFK: 'comparableStoreId' })
  @property comparableStore?: Store | null;
  comparableStoreId?: StoreId | null;

  // @belongsTo('regions', { nativeTable: 'stores', nativeTableFK: 'marketId', through: 'markets', throughIdKey: 'regionId', foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier']})
  @belongsTo('regions', { through: 'markets' })
  @property region: Region;

  @manyToMany('mfcAreas', {through: 'storesMfcAreas', foreignDisplayKey: 'identifier' })
  @property mfcAreas?: MfcArea[];

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required customer: Customer;
  customerId: CustomerId;

  @property indoorFloralMfcArea: string;
  @property outdoorGardenMfcArea: string;

  @property merchandisingSupervisorName: string;
  @property merchandisingSupervisorPhone: string;
  @property merchandisingSupervisorEmail: string;
}

definePresentation(Store, {
  merchandisingSupervisorName: {
    displayName: 'Superisor',
    searchable: true,
    sortable: true,
    tableDisplay: true,
    columnWidth: 7,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(SELECT name FROM merchandisers WHERE merchandisers.id = stores.merchandising_supervisor_id)`,
    },
  },
  merchandisingSupervisorPhone: {
    displayName: 'Supervisor 📞',
    searchable: true,
    sortable: true,
    tableDisplay: true,
    columnWidth: 7,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(SELECT phone FROM merchandisers WHERE merchandisers.id = stores.merchandising_supervisor_id)`,
    },
  },
  merchandisingSupervisorEmail: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => `(SELECT email FROM merchandisers WHERE merchandisers.id = stores.merchandising_supervisor_id)`,
    },
  },
  indoorFloralMfcArea: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {
      },
      calculation: () => {
        return `
        (
          SELECT
            mfc_areas.identifier
          FROM
            mfc_areas
          INNER JOIN
            stores_mfc_areas ON stores_mfc_areas.mfc_area_id = mfc_areas.id
          INNER JOIN
            sell_departments ON sell_departments.id = mfc_areas.sell_department_id
          WHERE
            sell_departments.identifier = '${KnownSellDepartment.IndoorFloral}'
            AND stores_mfc_areas.store_id = stores.id
        )
       `;
      },
    },
    tableDisplay: false,
    sortable: true,
    filterable: true,
  },
  outdoorGardenMfcArea: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {
      },
      calculation: () => {
        return `
        (
          SELECT
            mfc_areas.identifier
          FROM
            mfc_areas
          INNER JOIN
            stores_mfc_areas ON stores_mfc_areas.mfc_area_id = mfc_areas.id
          INNER JOIN
            sell_departments ON sell_departments.id = mfc_areas.sell_department_id
          WHERE
            sell_departments.identifier = '${KnownSellDepartment.OutdoorGarden}'
            AND stores_mfc_areas.store_id = stores.id
        )
       `;
      },
    },
    tableDisplay: false,
    sortable: true,
    filterable: true,
  },
  identifier: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Store',
    searchable: true,
    sortable: true,
    tableDisplay: true,
    columnWidth: 10,
  },
  customer: {
    filterable: true,
    sortable: true,
    searchable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    columnWidth: 15,
  },
  region: {
    filterable: true,
    sortable: true,
    searchable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: RegionDisplayValueResolver }),
    columnWidth: 5,
  },
  market: {
    filterable: true,
    sortable: true,
    searchable: true,
    columnWidth: 5,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: MarketDisplayValueResolver }),
  },
  mfcAreas: {
    sortable: false,
    searchable: false,
    displayName: 'MFC Areas',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  address1: {
    tableDisplay: true,
    displayName: 'Address',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 15,
  },
  address2: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  city: {
    tableDisplay: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  state: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 5,
  },
  zip: {
    displayName: 'ZIP',
    tableDisplay: true,
    columnWidth: 10,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  country: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  // currentPrimaryGlobal
  primaryGlobal: {
    searchable: true,
    sortable: true,
    tableDisplay: true,
    columnWidth: 4,
    filterable: true,
    displayName: 'Store Type',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    calculationSpec: {
      dependencies: {},
      calculation: (p: Store) => `store_primary_global(${p.id})`,
    },
  },
  openDate: {
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
  },
  closeDate: {
    type: TYPES.DATE,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
  },
  latitude: {
    type: TYPES.FLOAT,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  longitude: {
    type: TYPES.FLOAT,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  managerName: {
    displayName: 'Name',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  managerPhone: {
    displayName: 'Phone',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
  },
  managerFax: {
    displayName: 'Fax',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEL }),
  },
  managerEmail: {
    displayName: 'Email',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.EMAIL }),
  },
  indoorDeliveryHours: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  indoorDeliveryLocation: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  outdoorDeliveryHours: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  outdoorDeliveryLocation: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  villageRestrictions: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
  },
  encoreStore: {
    displayName: 'Encore',
    filterable: true,
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
  },
  merchandisingSupervisor: {
    displayName: 'Merchandising Supervisor',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: MerchandiserDisplayValueResolver }),
  },
  primarySupplier: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  comparableStore: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
});

setSchemaOptions(Store, {
  softDeletable: true,
});
