import gqlTag from 'graphql-tag';

export interface GenerateSupplierOrderDetailsInput {
  supplierOrderIds: number[];
}

export interface GenerateSupplierOrderDetailsResponse {
  response: {
    report: {
      id: number;
    };
  };
}

export const generateSupplierOrderDetailsMutation = gqlTag`
  mutation GenerateSupplierOrderDetails($supplierOrderIds: [Int!]!) {
    response: GenerateSupplierOrderDetails(supplierOrderIds: $supplierOrderIds) {
      report {
        id
      }
    }
  }
`;
