import * as _ from 'lodash';
import { findMapped } from './andys-little-helpers';
const naturalCompare = require('natural-compare');

function compare(a: any, b: any, sortOrder: 'asc' | 'desc'): number {
  // naturalCompare(a[sortDef.sortField], b[sortDef.sortField]);
  if (a === b) {
    return 0;
  }

  // Want nils last - so if a is nil and b is not, b should come first
  if ((a === null || a === undefined) && (b !== null && b !== undefined)) {
    return 1;
  }

  // Want nils last - so if a is not nil and b is, a should come first
  if ((a !== null && a !== undefined) && (b === null || b === undefined)) {
    return -1;
  }

  if (typeof a === 'number') {
    return sortOrder === 'asc' ? a - b : b - a;
  } else {
    return sortOrder === 'asc' ? naturalCompare(a, b) : naturalCompare(b, a);
  }
}

// isn't this the most amazing thing you've ever seen? 😎
export function sortNaturally(arr: any[], sort: Array<{ sortField: string | ((o: any) => any), sortOrder?: 'asc' | 'desc' | 'ASC' | 'DESC' }> = [{ sortField: _.identity }]) {
  const sorted = [...arr].sort((a: any, b: any) => {
    // Iterate through the provided sort options, returning the result of the first
    // naturalCompare that is non-zero.
    return findMapped(sort,
      sortDef => {
        const sortOrder = sortDef.sortOrder ? _.toLower(sortDef.sortOrder) : 'asc';
        const valueA = typeof sortDef.sortField === 'string' ? a[sortDef.sortField] : sortDef.sortField(a);
        const valueB = typeof sortDef.sortField === 'string' ? b[sortDef.sortField] : sortDef.sortField(b);

        return compare(valueA, valueB, sortOrder as any);
      },
      (order: number) => order !== 0,
    ) || 0;
  });

  return sorted;
}
