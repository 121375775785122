import gql from 'graphql-tag';
import { RoutingLoadGQLResult } from 'schema/routing-load/routing-load-graphql-types';
import { RoutePlanId, RoutePlanIdentifier, RoutePlanStatus } from 'schema/route-plan/route-plan-typescript-types';

export interface RoutingLoadQueryResponse {
  getRoutingLoads?: {
    routingLoads: RoutingLoadGQLResult[];
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
}

export const RoutingLoadFragment = gql`
  fragment RoutingLoadFragment on RoutingLoad {
    id
    identifier
    loadType {
      rawValue
      displayValue
    }
    customerNames
    mfcAreaIdentifiers
    routeType {
      rawValue
      displayValue
    }
    carrier {
      rawValue
      displayValue
    }
    milesCombinedWithAdditionalMiles
    dropsCombinedWithAdditionalDrops
    racks
    pallets
    trailerNumber
    peakOrderNumber
    totalFee
  }
`;

export const RoutePlanLoadsTableQueryName = 'RoutePlanLoadsTableQuery';

export const RoutePlanLoadsTableQuery = gql`
  query ${RoutePlanLoadsTableQueryName}($sort: [SortInput!], $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput, $search: RoutingLoadSearchInput) {
    getRoutingLoads: GetExtractedRoutingLoads(sort: $sort, limit: $limit, offset: $offset, scope: $scope, search: $search) {
      routingLoads {
        ...RoutingLoadFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
  }
  ${RoutingLoadFragment}
`;

export const RoutePlanLoadsExcelQuery = gql`
  query RoutePlanLoadsExcelQuery($sort: [SortInput!], $limit: Int, $offset: Int, $scope: RoutingLoadScopeInput, $search: RoutingLoadSearchInput) {
    getRoutingLoads: GetExtractedRoutingLoads(sort: $sort, limit: $limit, offset: $offset, scope: $scope, search: $search) {
      routingLoads {
        ...RoutingLoadFragment
      }
    }
  }
  ${RoutingLoadFragment}
`;

export interface RoutePlanQueryResponse {
  routePlanResult?: {
    routePlan: {
      id: RoutePlanId;
      identifier: RoutePlanIdentifier;
      status: RoutePlanStatus;
    };
  };
}
export const GetRoutePlanIdentifierForExcelDownload = gql`
query GetRoutePlanIdentifierForRoutingLoads($id: Int!) {
  routePlanResult: GetRoutePlan(id: $id) {
    routePlan {
      id
      identifier
      status
    }
  }
}
`;
