export enum ColumnContentType {
  Text = 'text',
  Amount = 'amount',
  Money = 'money',
  Percent = 'percent',
  Checkmark = 'checkmark',
}

export interface ColumnDef {
  header: string;
  footer?: string | ((data: any[]) => string | HTMLElement);
  accessor: string;
  width: number;

  contentType: ColumnContentType;
  frozen?: boolean;
}

export interface SortDef {
  columnHeader: string;
  order: 'asc' | 'desc';
}

export interface UIProps {
  self?: any;
  twin?: any;
  data: any[];
  columns: ColumnDef[];
  highlightSelectedRow?: boolean;
  onRowClicked?(rowIndex: number): void;
  defaultSort?: SortDef;
  tableName?: string;
  growableColumnHeader?: string;
  loading: boolean;
  horizontalScrollPosition?: number;
  verticalScrollPosition?: number;
  height: number;
  width: number;
  onHorizontalScroll?: (args: {
    horizontalScrollDirection: 'forward' | 'backward',
    scrollLeft: number,
    scrollUpdateWasRequested: boolean,
  }) => void;
  onVerticalScroll?: (args: {
    scrollTop: number,
    scrollUpdateWasRequested: boolean,
    verticalScrollDirection: 'forward' | 'backward',
  }) => void;

  showFooter?: boolean;

  children?: shame;
}
