import { push } from 'connected-react-router';
import { connect, MapDispatchToProps } from 'react-redux';
import { flowRight } from 'lodash';
import { RecordBarDetail, RecordBarDetailSet } from 'client/components/record-bar';
import * as _ from 'lodash';
import * as React from 'react';
import Dropdown from 'client/components/dropdown';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
  supplierItems: Array<{ text: string, supplierItemId: number }> | undefined;
}

const mapStateToProps = (state: {}, ownProps: OwnProps): StateProps => {
  return {
    supplierItems: _.orderBy((ownProps.record.supplierItems || []).map(supplierItem => {
      return {
        text: supplierItem.supplier.name,
        supplierItemId: supplierItem.id,
      };
    }), 'text'),
  };
};

interface DispatchProps {
  handleMenuItemClicked: (supplierItemId: number) => void;
}

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleMenuItemClicked: (supplierItemId: number) => {
    return push(`/admin/supplierItems/details/${supplierItemId}/info`);
  },
};

const handleExternalLinkClicked = (supplierItemId: number) => {
  window.open(`/admin/supplierItems/details/${supplierItemId}/info`);
};

interface OwnProps {
  record: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {

}

type ComponentProps = OwnProps & StateProps & DispatchProps;

function ProductRecordBarA(props: ComponentProps) {
  const { supplierItems, handleMenuItemClicked } = props;
  const onMenuItemClicked = React.useCallback((v, index) => {
    if (supplierItems && supplierItems[index]) {
      handleMenuItemClicked(supplierItems[index].supplierItemId);
    }
  }, [handleMenuItemClicked, supplierItems]);

  const onExternalLinkClicked = React.useCallback((v, index) => {
    if (supplierItems && supplierItems[index]) {
      handleExternalLinkClicked(supplierItems[index].supplierItemId);
    }
  }, [supplierItems]);

  const displayValues = React.useMemo(() => (supplierItems || []).map(d => d.text), [supplierItems]);

  return (
    <RecordBarDetailSet>
      { displayValues.length > 0 &&
        <RecordBarDetail>
          <Dropdown
            className="record-bar-warp-menu"
            data={displayValues}
            onChange={onMenuItemClicked}
            onSecondaryClick={onExternalLinkClicked}
            secondaryIcon="fa-external-link"
            placeholder="Supplier Items"
            value={[]}
            valueField="id"
            testid="supplier-item-dropdown"
          />
        </RecordBarDetail>
      }
    </RecordBarDetailSet>
  );
}

export const ProductRecordBar = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(ProductRecordBarA) as React.Component<OwnProps>;
