import * as State from 'client/state/import-route';
import { ActionTypes, ActionTypeKeys } from 'client/actions/import-route';
import * as R from 'ramda';
import * as DirectRouteFileParser from 'shared/file-parsers/direct-route-upl-file-parser';
import { ImportRouteProcessStep } from 'client/state/import-route';

type ImportRouteState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): ImportRouteState {
  switch (action.type) {
    case ActionTypeKeys.PreviousButtonClicked: {
      switch (state.processStep) {
        case State.ImportRouteProcessStep.RouteInformation:
          return state; // shouldn't get here
        case State.ImportRouteProcessStep.ImportFile:
          return R.pipe(
            State.processStep.set(ImportRouteProcessStep.RouteInformation),
            State.routePlanId.set(State.routePlanId(state)),
          )(state);
        case State.ImportRouteProcessStep.ProcessSplitStops:
          return R.pipe(
            State.processStep.set(ImportRouteProcessStep.ImportFile),
            State.fileUploadStatus.set(State.FileUploadStatus.Initial),
        )(state);
        default:
          return state;
      }
    }

    case ActionTypeKeys.NextButtonClicked: {
      switch (state.processStep) {
        case State.ImportRouteProcessStep.RouteInformation:
          return R.pipe(
            State.processStep.set(ImportRouteProcessStep.ImportFile),
            State.routePlanId.set(State.routePlanId(state)),
          )(state);
        case State.ImportRouteProcessStep.ImportFile:
          return State.processStep.set(state, ImportRouteProcessStep.ProcessSplitStops);
        default:
          return state;
      }
    }

    case ActionTypeKeys.ImportRouteFileButtonClicked: {
      return R.pipe(
        State.importRouteModalShown.set(true),
        State.routePlanId.set(action.payload.routePlanId),
      )(state);
    }

    case ActionTypeKeys.CancelButtonClicked: {
      return State.InitialState;
    }

    case ActionTypeKeys.UploadStarted: {
      return R.pipe(
        State.parsingErrorMessages.set([]),
        State.fileUploadStatus.set(State.FileUploadStatus.InProgress),
        State.fileName.set(action.payload.fileName),
        State.fileContents.set(action.payload.fileContents),
      )(state);
    }

    case ActionTypeKeys.ParsingStarted: {
      const fileContents = State.fileContents(state);
      if (fileContents === null) {
        return state;
      }
      const parsedFile = DirectRouteFileParser.parseUplFile(fileContents);
      const {
        customers,
        stores,
        loads,
        stops,
        customerOrders,
      } = State.buildDataStore(parsedFile);
      return R.pipe(
        State.customerDataStore.set(customers),
        State.storeDataStore.set(stores),
        State.loadDataStore.set(loads),
        State.stopDataStore.set(stops),
        State.customerOrderDataStore.set(customerOrders),
      )(state);
    }

    case ActionTypeKeys.ParsingSucceeded: {
      return R.pipe(
        State.fileUploadStatus.set(State.FileUploadStatus.Succeed),
        State.processStep.set(State.ImportRouteProcessStep.ProcessSplitStops),
        State.setSelectedStoreToFirstSplitStopStore,
      )(state);
    }

    case ActionTypeKeys.ParsingFailed: {
      return R.pipe(
        State.fileUploadStatus.set(State.FileUploadStatus.Failed),
        State.parsingErrorMessages.set(action.payload.messages),
      )(state);
    }

    case ActionTypeKeys.RouteInformationValueChanged: {
      let filteredValue: string | number = action.payload.value;

      if (action.payload.fieldName === 'trailerTemperature' && action.payload.value) {
        filteredValue = parseInt(action.payload.value.replace(/[^\d]/g, ''));
      }
      return State.routeInformation.update(state, currentValue => {
        return {
          ...currentValue,
          [action.payload.fieldName]: filteredValue || null,
        };
      });
    }

    case ActionTypeKeys.SubmitStarted: {
      return State.processStep.set(state, State.ImportRouteProcessStep.SubmittingRoutes);
    }

    case ActionTypeKeys.SubmitSucceeded: {
      return State.InitialState;
    }

    case ActionTypeKeys.SubmitFailed: {
      return State.processStep.set(state, State.ImportRouteProcessStep.ProcessSplitStops);
    }

    case ActionTypeKeys.RoutingLoadNumbersAcquired: {
      return action.payload.pairs.reduce((replacementState, payloadItem) => State.updateLoadNumber({ ...payloadItem, loadReduxId: State.getLoadReduxIdByRouteNumber(state, payloadItem.routeNumber) })(replacementState), state);
    }

    case ActionTypeKeys.SplitStopStoreSelected: {
      return R.pipe(
        State.selectedSplitStopStore.set(action.payload.storeIdentifier),
      )(state);
    }

    case ActionTypeKeys.StoreLoadRackQuantityChanged: {
      const selectedStore = State.selectedSplitStopStore(state);
      return !selectedStore ? state : R.pipe(
        State.updateSplitStopQuantity({
          customerOrderProductGroupId: action.payload.customerOrderProductGroupId,
          loadNumber: action.payload.loadNumber,
          quantity: action.payload.quantity,
          selectedStore,
          totalRackQuantityForGroup: action.payload.totalRackQuantityForGroup,
        }),
      )(state);
    }

    case ActionTypeKeys.CellBlurred: return State.autoFill({ groupsWithOrderQuantities: action.payload.groupsWithOrderQuantities })(state);
    default: return state;
  }
}
