import { Action } from 'redux';
import { Thunker } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import gql from 'graphql-tag';
import { push } from 'connected-react-router';

import { ScanBasedOrPoBased } from 'shared/types';
import { timeout } from 'shared/helpers';
import { getWorkBookContainerFromBinaryString } from 'shared/file-parsers/excel/common';
import * as CustomerOrderSpreadsheetParser from 'shared/file-parsers/excel/customer-order-spreadsheet-parser';

import { ValidateCustomerOrderImportQueryResponse, ImportableCustomerOrderInput, ImportCustomerOrderResponse, ValidateCustomerOrderImportForUpdateQueryResponse, ImportableCustomerOrderForUpdateInput } from 'schema/import-customer-order/import-customer-order-typescript-types';

import * as State from 'client/state/state';
import { CustomerOrderImportState, CustomerOrderImportStep } from 'client/state/import-customer-order-spreadsheet';
import { msyncClientQuery } from 'client/hoc/graphql/query';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';

export const ValidateCustomerOrderQuery = gql`
  query ValidateCustomerOrderImport($importableCustomerOrder: ImportableCustomerOrderInput!) {
    validateCustomerOrderImport: ValidateCustomerOrderImport(importableCustomerOrder: $importableCustomerOrder) {
      isValid
      errors
      existingCustomerOrderId
    }
  }
`;

export const ValidateCustomerOrderForUpdateQuery = gql`
  query ValidateCustomerOrderImportForUpdate($customerOrderId: Int, $importableCustomerOrder: ImportableCustomerOrderForUpdateInput!) {
    validateCustomerOrderImportForUpdate: ValidateCustomerOrderImportForUpdate(customerOrderId: $customerOrderId, importableCustomerOrder: $importableCustomerOrder) {
      isValid
      errors
    }
  }
`;

export const ImportCustomerOrderMutation = (resultName: string) => gql`
  mutation ImportCustomerOrder($importableCustomerOrder: ImportableCustomerOrderInput!) {
    ${resultName}: ImportCustomerOrder(importableCustomerOrder: $importableCustomerOrder) {
      success
      errors
      customerOrderId
    }
  }
`;

export const ImportCustomerOrderForUpdateMutation = (resultName: string) => gql`
  mutation ImportCustomerOrder($customerOrderId: Int!, $importableCustomerOrder: ImportableCustomerOrderForUpdateInput!) {
    ${resultName}: ImportCustomerOrderForUpdate(customerOrderId: $customerOrderId, importableCustomerOrder: $importableCustomerOrder) {
      success
      errors
      customerOrderId
    }
  }
`;

export type ActionTypes =
  ImportCustomerOrderSpreadsheetOpenButtonModalButtonClickedAction |
  ImportCustomerOrderSpreadsheetCancelButtonClickedAction |
  ImportCustomerOrderSpreadsheetImportButtonClickedAction |
  ImportCustomerOrderSpreadsheetOpenButtonClickedAction |
  ImportCustomerOrderSpreadsheetOpenExistingButtonClickedAction |
  ImportCustomerOrderSpreadsheetParsingAction |
  ImportCustomerOrderSpreadsheetValidatingAction |
  ImportCustomerOrderSpreadsheetImportingAction |
  ImportCustomerOrderSpreadsheetImportedAction |
  ImportCustomerOrderSpreadsheetFailedAction |
  ImportCustomerOrderSpreadsheetValidatedAction |
  ImportCustomerOrderSpreadsheetRadioButtonClickedAction;

export enum ActionTypeKeys {
  ImportCustomerOrderSpreadsheetOpenModalButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_OPEN_MODAL_BUTTON_CLICKED',
  ImportCustomerOrderSpreadsheetCancelButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_CANCEL_BUTTON_CLICKED',
  ImportCustomerOrderSpreadsheetImportButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_IMPORT_BUTTON_CLICKED',
  ImportCustomerOrderSpreadsheetOpenButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_OPEN_BUTTON_CLICKED',
  ImportCustomerOrderSpreadsheetOpenExistingButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_OPEN_EXISTING_BUTTON_CLICKED',
  ImportCustomerOrderSpreadsheetParsing = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_PARSING',
  ImportCustomerOrderSpreadsheetValidating = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_VALIDATING',
  ImportCustomerOrderSpreadsheetImporting = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_IMPORTING',
  ImportCustomerOrderSpreadsheetImported = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_IMPORTED',
  ImportCustomerOrderSpreadsheetFailed = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_FAILED',
  ImportCustomerOrderSpreadsheetValidated = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_VALIDATED',
  ImportCustomerOrderSpreadsheetRadioButtonClicked = 'App/IMPORT_CUSTOMER_ORDER_SPREADSHEET_RADIO_BUTTON_CLICKED',
}

export interface ImportCustomerOrderSpreadsheetCancelButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetCancelButtonClicked;
  payload: {};
}

export function importCustomerOrderSpreadsheetCancelButtonClicked(): ImportCustomerOrderSpreadsheetCancelButtonClickedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetCancelButtonClicked,
    payload: {},
  };
}

export interface ImportCustomerOrderSpreadsheetOpenButtonModalButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenModalButtonClicked;
  payload: {};
}

export function importCustomerOrderSpreadsheetOpenModalButtonClicked(): ImportCustomerOrderSpreadsheetOpenButtonModalButtonClickedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenModalButtonClicked,
    payload: {},
  };
}

export function importCustomerOrderSpreadsheetButtonClicked(customerOrderId: number | undefined): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    if (getState().importCustomerOrderSpreadsheet.customerOrderImportState === CustomerOrderImportState.Imported) {
      if (customerOrderId) {
        dispatch(importCustomerOrderSpreadsheetCancelButtonClicked());
      } else {
        dispatch(importCustomerOrderSpreadsheetOpenButtonClicked());
      }
    } else {
      dispatch(importCustomerOrderSpreadsheetImportButtonClicked(customerOrderId));
    }
  };
}

export interface ImportCustomerOrderSpreadsheetOpenButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenButtonClicked;
  payload: {};
}

export function importCustomerOrderSpreadsheetOpenButtonClicked(): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const customerOrderId = getState().importCustomerOrderSpreadsheet.customerOrderId;

    dispatch({
      type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenButtonClicked,
      payload: {},
    });

    dispatch(push(`/orders/customer/details/${customerOrderId}`));
  };
}

export interface ImportCustomerOrderSpreadsheetOpenExistingButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenExistingButtonClicked;
  payload: {};
}

export function importCustomerOrderSpreadsheetOpenExistingButtonClicked(): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    const existingCustomerOrderId = getState().importCustomerOrderSpreadsheet.existingCustomerOrderId;

    dispatch(importCustomerOrderSpreadsheetCancelButtonClicked());

    dispatch({
      type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenExistingButtonClicked,
      payload: {},
    });

    dispatch(push(`/orders/customer/details/${existingCustomerOrderId}`));
  };
}

const validateCustomerOrder = async (dispatch: Dispatch<any>, state: State.Type): Promise<ValidateCustomerOrderImportQueryResponse> => {
  const importableCustomerOrder = state.importCustomerOrderSpreadsheet.importableCustomerOrder;
  if (!importableCustomerOrder) {
    return {
      isValid: false,
      errors: [
        'customerOrder not specified',
      ],
    };
  }

  const scanBasedOrPoBased = state.importCustomerOrderSpreadsheet.scanBasedOrPoBased;
  const scanBased = scanBasedOrPoBased === ScanBasedOrPoBased.scanBased;

  const result = await msyncClientQuery<{ validateCustomerOrderImport: ValidateCustomerOrderImportQueryResponse }, ImportableCustomerOrderInput>({
    query: ValidateCustomerOrderQuery,
    dispatch,
    variables: {
      importableCustomerOrder: {
        ...importableCustomerOrder,
        scanBased,
      },
    },
    fetchPolicy: 'network-only',
  });

  return result.data.validateCustomerOrderImport;
};

const validateUpdateCustomerOrder = async (dispatch: Dispatch<any>, state: State.Type, customerOrderId: number): Promise<ValidateCustomerOrderImportQueryResponse> => {
  const importableCustomerOrder = state.importCustomerOrderSpreadsheet.importableCustomerOrder;
  if (!importableCustomerOrder) {
    return {
      isValid: false,
      errors: [
        'customerOrder not specified',
      ],
    };
  }

  const result = await msyncClientQuery<{ validateCustomerOrderImportForUpdate: ValidateCustomerOrderImportForUpdateQueryResponse }, ImportableCustomerOrderForUpdateInput>({
    query: ValidateCustomerOrderForUpdateQuery,
    dispatch,
    variables: {
      customerOrderId,
      importableCustomerOrder,
    },
    fetchPolicy: 'network-only',
  });

  return result.data.validateCustomerOrderImportForUpdate;
};

export function customerOrderSpreadsheetSelected(fileName: string, fileContents: string, customerOrderId: number | undefined): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    dispatch(customerOrderParsing());

    await timeout(100); // Get the spinner up and displayed

    try {
      const workBookContainer = await getWorkBookContainerFromBinaryString(fileName, fileContents);
      const customerOrderParseResult = await CustomerOrderSpreadsheetParser.getImportableCustomerOrderFromWorkBookContainer(workBookContainer);
      if (!customerOrderParseResult.success)
        return dispatch(importCustomerOrderFailed(CustomerOrderImportStep.Validation, [customerOrderParseResult.reason]));

      const importableCustomerOrder = customerOrderParseResult.parsed;
      dispatch(customerOrderValidating(importableCustomerOrder));

      const validateResult = customerOrderId
        ? await validateUpdateCustomerOrder(dispatch, getState(), customerOrderId)
        : await validateCustomerOrder(dispatch, getState());

      if (validateResult.isValid)
        dispatch(customerOrderValidated());
      else
        dispatch(importCustomerOrderFailed(CustomerOrderImportStep.Validation, validateResult.errors || [], validateResult.existingCustomerOrderId));

    } catch (error) {
      dispatch(importCustomerOrderFailed(CustomerOrderImportStep.Validation, [error.message]));
    }
  };
}

const importCustomerOrder = async (dispatch: Dispatch<any>, state: State.Type, customerOrderId: number | undefined): Promise<ImportCustomerOrderResponse> => {
  const importableCustomerOrder = state.importCustomerOrderSpreadsheet.importableCustomerOrder;
  if (!importableCustomerOrder)
    return { success: false, errors: [ 'customerOrder not specified' ] };

  if (!customerOrderId) {
    const scanBasedOrPoBased = state.importCustomerOrderSpreadsheet.scanBasedOrPoBased;
    const scanBased = scanBasedOrPoBased === ScanBasedOrPoBased.scanBased;
    const resultName = 'importCustomerOrder';
    const result = await msyncClientMutation<{ importCustomerOrder: ImportCustomerOrderResponse }, ImportableCustomerOrderInput>({
      mutation: ImportCustomerOrderMutation(resultName),
      variables: { importableCustomerOrder: { ...importableCustomerOrder, scanBased } },
      dispatch,
    });

    return result.data.importCustomerOrder;
  } else {
    const resultName = 'importCustomerOrderForUpdate';

    const result = await msyncClientMutation<{ importCustomerOrderForUpdate: ImportCustomerOrderResponse }, ImportableCustomerOrderForUpdateInput>({
      mutation: ImportCustomerOrderForUpdateMutation(resultName),
      variables: { customerOrderId, importableCustomerOrder },
      dispatch,
      refetchQueries: [
        // Overview
        'findCustomerOrder',
        'CustomerOrderInvoiceQuery',
        'getStats',
        'findAllCustomerOrderAllocationsWithTotal',
        'CustomerOrderAllocationSummaryTotals',

        // Product Worksheet
        //   -- no non-network-only query is affected by the import (meaning there is no cached data that needs to be refetched)

        // Related Supplier POs
        //   -- all related supplier POs queries are network-only

        // Reconciliation
        //   -- all reconciliation queries are network-only
      ],
    });

    return result.data.importCustomerOrderForUpdate;
  }
};

export interface ImportCustomerOrderSpreadsheetImportButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenButtonClicked;
  payload: {};
}

export function importCustomerOrderSpreadsheetImportButtonClicked(customerOrderId: number | undefined): Thunker {
  return async (dispatch: Dispatch<any>, getState: () => State.Type) => {
    try {
      dispatch(customerOrderImporting());

      await timeout(100); // Get the spinner up and displayed

      const importResult = await importCustomerOrder(dispatch, getState(), customerOrderId);
      if (importResult.success) {
        dispatch(customerOrderImported(importResult.customerOrderId));
      } else {
        dispatch(importCustomerOrderFailed(CustomerOrderImportStep.Import, importResult.errors || []));
      }
    } catch (error) {
      dispatch(importCustomerOrderFailed(CustomerOrderImportStep.Import, [error.message]));
    }
  };
}

// --------------------------------------------------------------------------------

export interface ImportCustomerOrderSpreadsheetParsingAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetParsing;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Parsing;
  };
}

export function customerOrderParsing(): ImportCustomerOrderSpreadsheetParsingAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetParsing,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Parsing,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetValidatingAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetValidating;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Validating;
    importableCustomerOrder: CustomerOrderSpreadsheetParser.ImportableCustomerOrder;
  };
}

export function customerOrderValidating(importableCustomerOrder: CustomerOrderSpreadsheetParser.ImportableCustomerOrder): ImportCustomerOrderSpreadsheetValidatingAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetValidating,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Validating,
      importableCustomerOrder,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetValidatedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetValidated;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Validated;
  };
}

export function customerOrderValidated(): ImportCustomerOrderSpreadsheetValidatedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetValidated,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Validated,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetImportingAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetImporting;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Importing;
  };
}

export function customerOrderImporting(): ImportCustomerOrderSpreadsheetImportingAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetImporting,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Importing,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetImportedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetImported;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Imported;
    customerOrderId: number,
  };
}

export function customerOrderImported(customerOrderId): ImportCustomerOrderSpreadsheetImportedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetImported,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Imported,
      customerOrderId,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetFailedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetFailed;
  payload: {
    customerOrderImportState: CustomerOrderImportState.Failed;
    failedStep: CustomerOrderImportStep;
    errorMessages: string[];
    existingCustomerOrderId?: number | undefined;
  };
}

export function importCustomerOrderFailed(failedStep: CustomerOrderImportStep, errorMessages: string[], existingCustomerOrderId?: number | undefined): ImportCustomerOrderSpreadsheetFailedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetFailed,
    payload: {
      customerOrderImportState: CustomerOrderImportState.Failed,
      failedStep,
      errorMessages,
      existingCustomerOrderId,
    },
  };
}

export interface ImportCustomerOrderSpreadsheetRadioButtonClickedAction extends Action {
  type: ActionTypeKeys.ImportCustomerOrderSpreadsheetRadioButtonClicked;
  payload: {
    value: string;
  };
}

export function radioButtonClicked(value: string): ImportCustomerOrderSpreadsheetRadioButtonClickedAction {
  return {
    type: ActionTypeKeys.ImportCustomerOrderSpreadsheetRadioButtonClicked,
    payload: {
      value,
    },
  };
}
