import * as React from 'react';
import { recordType as getRecordType } from '../../../shared/schemas';
import { singular } from 'pluralize';
import { startCase } from 'lodash';
import {RecordBarDetail } from './record-bar-detail';

export function RecordBarIdentifier({ table, title, description }: {
  description: string;
  table: string;
  title?: string;
}) {
  const recordType = getRecordType(table);
  return (
    <RecordBarDetail title={title ? title : singular(startCase(recordType))}>
      {description || singular(startCase(recordType))}
    </RecordBarDetail>
  );
}
