import * as React from 'react';
import * as classnames from 'classnames';
import { ListGroupItem, ListGroup } from 'client/components/third-party';
import { kebabCase } from 'lodash';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { MutationStatus } from 'client/actions/mutations';

type GroupedListProps = shame;

export function GroupedList(props: GroupedListProps) {
  return (
    <ListGroup className="grouped-list" >
      {props.children}
    </ListGroup>
  );
}

interface GroupedListHeadingProps {
  number: string;
  label: string;
}

export function GroupedListHeading(props: GroupedListHeadingProps) {
  const header = (
    <h4>
      <div className="grouped-list-heading-number">{props.number}</div>
      <div className="grouped-list-heading-label">{props.label}</div>
    </h4>
  );

  return <ListGroupItem className="grouped-list-heading" header={header}></ListGroupItem>;
}

interface GroupedListItemProps {
  heading: string;
  text: string;
  statusText?: string;
  buttonText: string;
  buttonOnClick?: () => void;
  buttonActionStatus?: MutationStatus;
  disabled?: boolean;
}

const buttonClassNames = ['mfc-button-table'];

export function GroupedListItem(props: GroupedListItemProps) {
  const checkboxClassName = classnames('mfc-checkbox disabled', {
    checked: !!props.statusText,
  });

  const headingClassName = classnames('grouped-list-item-heading', { disabled: props.disabled });
  const textClassName = classnames('grouped-list-item-text', { disabled: props.disabled });
  const statusAndButtonClassName = classnames('grouped-list-status-and-button', { disabled: props.disabled });

  return <ListGroupItem className="grouped-list-item"
          data-testid={`grouped-list-item-${kebabCase(props.heading)}`}>
    <label className={checkboxClassName}><span /></label>
    <div className={statusAndButtonClassName}>
      <div className="grouped-list-status">
        {props.statusText}
      </div>
      <div className="grouped-list-button">
        <AsyncButton
          disabled={props.disabled}
          label={props.buttonText}
          classNames={buttonClassNames}
          skipBaseClasses={true}
          onClick={props.buttonOnClick}
          actionStatus={props.buttonActionStatus}
          testid={`grouped-list-item-button-${kebabCase(props.heading)}`}
        />
      </div>
    </div>
    <div className={headingClassName}>{props.heading}</div>
    <div className={textClassName}>{props.text}</div>
  </ListGroupItem>;
}
