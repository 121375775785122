import { buildAdminDetailPage } from '../admin/admin-detail-page';
import FormComponent from '../../components/crud-forms/supplier-item/costs-form-group';
import RecordBarDetailComponent from '../../components/crud-forms/supplier-item/record-bar-detail';
import navBuilder from './nav-items';

const detailPage = buildAdminDetailPage({
  table: 'SupplierItem',
  formName: 'SupplierItemCostForm',
  FormComponent,
  navBuilder,
  RecordBarDetailComponent,
  descriptionResolver: record => record.product.identifier,
});

export default detailPage;
