import * as _ from 'lodash';
import * as Excel from '../common';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as XLSX from 'xlsx';
import * as Constants from './constants';
import * as Types from './types';
import { getColumnNumber, getColumnLetter, convertOneBasedIndexToZeroBasedIndex } from 'shared/excelpers';

type SupplierCommitmentWorkBook = XLSX.WorkBook;
type SupplierCommitmentWorkSheet = XLSX.WorkSheet;

type SupplierCommitmentWorkSheetData = Excel.WorkSheetData;

export async function getSupplierCommitmentWorkBookFromWorkBookContainer(workBookContainer: ExcelCommon.WorkBookContainer): Promise<SupplierCommitmentWorkBook | null> {
  return Excel.getWorkBookFromWorkBookContainer<SupplierCommitmentWorkBook>(workBookContainer);
}

const getWorkSheetData = (workSheet: SupplierCommitmentWorkSheet) => Excel.getWorkSheetData<SupplierCommitmentWorkSheetData>(workSheet);

export function getSupplierCommitments(workBook: XLSX.WorkBook): Types.ImportableSupplierCommitment[] {
  const sheetNames = Object.keys(workBook.Sheets);
  const sheetNamesWithSupplierCommitmentData = sheetNames.filter(sheetName => sheetName !== Constants.MASTER_SHEET_NAME && sheetName !== Constants.PRODUCT_LIST_SHEET_NAME);
  return sheetNamesWithSupplierCommitmentData.map(sheetName => getSupplierCommitment(getWorkSheetData(workBook.Sheets[sheetName])));
}

const getSupplierIdentifier = (worksheetRows: SupplierCommitmentWorkSheetData) => {
  const supplierIdentifier = Excel.getAsString(worksheetRows[convertOneBasedIndexToZeroBasedIndex(Constants.SUPPLIER_IDENTIFIER_ROW)][Constants.SUPPLIER_IDENTIFIER_COL]);
  if (supplierIdentifier === undefined) {
    throw new Error('Supplier is missing from spreadsheet.');
  }
  return supplierIdentifier;
};

const getSeason = (worksheetRows: SupplierCommitmentWorkSheetData) => {
  const season = Excel.getAsString(worksheetRows[convertOneBasedIndexToZeroBasedIndex(Constants.PRODUCTION_SEASON_ROW)][Constants.PRODUCTION_SEASON_COL]);
  if (season === undefined) {
    throw new Error('Production Season is missing from spreadsheet.');
  }
  return season;
};

const getYear = (worksheetRows: SupplierCommitmentWorkSheetData) => {
  const year = Excel.getAsNumber(worksheetRows[convertOneBasedIndexToZeroBasedIndex(Constants.PRODUCTION_YEAR_ROW)][Constants.PRODUCTION_YEAR_COL]);
  if (year === undefined) {
    throw new Error('Production Year is missing from spreadsheet.');
  }
  return year;
};

const getCustomerIdentifier = (worksheetRows: SupplierCommitmentWorkSheetData) => {
  const customerIdentifier = Excel.getAsString(worksheetRows[convertOneBasedIndexToZeroBasedIndex(Constants.CUSTOMER_IDENTIFIER_ROW)][Constants.CUSTOMER_IDENTIFIER_COL]);
  if (customerIdentifier === undefined) {
    throw new Error('Customer is missing from spreadsheet.');
  }
  return customerIdentifier;
};

function getSupplierCommitment(worksheetRows: SupplierCommitmentWorkSheetData): Types.ImportableSupplierCommitment {
  return {
    customerIdentifier: getCustomerIdentifier(worksheetRows),
    year: getYear(worksheetRows),
    season: getSeason(worksheetRows),
    supplierIdentifier: getSupplierIdentifier(worksheetRows),
    products: getProducts(worksheetRows),
  };
}

const getTotalUnits = (worksheetRow: Excel.WorkSheetRow) => Excel.getAsNumber(worksheetRow[Constants.TOTAL_QUANTITY_COL]) || 0;
const rowNotEmpty = (worksheetRow: Excel.WorkSheetRow) => getTotalUnits(worksheetRow) > 0;
function getProducts(worksheetRows: SupplierCommitmentWorkSheetData): Types.ImportableSupplierCommitmentProduct[] {
  const productRows = worksheetRows.slice(convertOneBasedIndexToZeroBasedIndex(Constants.FIRST_PRODUCT_ROW)).filter(rowNotEmpty);
  return productRows.map(getProduct);
}

const getProductIdentifier = (worksheetRow: Excel.WorkSheetRow) => {
  const productIdentifier = Excel.getAsString(worksheetRow[Constants.PRODUCT_IDENTIFIER_COLUMN]);
  if (productIdentifier === undefined) {
    throw new Error('Prod ID is missing from a line item on the spreadsheet.');
  }
  return productIdentifier;
};
const getGrowerCost = (worksheetRow: Excel.WorkSheetRow) => {
  const growerCostString = Excel.getAsString(worksheetRow[Constants.GROWER_COST_COL]);
  if (growerCostString === undefined) {
    throw new Error('Grower Cost is missing from a line item on the spreadsheet.');
  }
  let growerCost: number | undefined;
  if (growerCostString[0] === '$') {
    growerCost = parseFloat(growerCostString.slice(1));
  } else {
    growerCost = parseFloat(growerCostString);
  }
  if (_.isNil(growerCost) || Number.isNaN(growerCost)) {
    throw new Error('Grower Cost is missing from a line item on the spreadsheet.');
  }
  return growerCost;
};

function getProduct(worksheetRow: Excel.WorkSheetRow): Types.ImportableSupplierCommitmentProduct {
  return {
    growerCost: getGrowerCost(worksheetRow),
    identifier: getProductIdentifier(worksheetRow),
    totalUnits: getTotalUnits(worksheetRow),
    allocations: getAllocations(worksheetRow),
  };
}

function getAllocations(worksheetRow: Excel.WorkSheetRow): Types.ImportableSupplierCommitmentAllocation[] {
  return _.range(Constants.START_WEEK, Constants.END_WEEK + 1).map(getAllocation(worksheetRow)).filter(allocation => allocation.pieceQuantity !== 0);
}

const getPieceQuantityForWeek = (worksheetRow: Excel.WorkSheetRow, index: number) => {
  const col = getColumnLetter(getColumnNumber(Constants.ALLOCATIONS_STARTING_COL) + index);
  return (Excel.getAsInteger(worksheetRow[col]) || 0);
};

const getAllocation = (worksheetRow: Excel.WorkSheetRow) => (weekNumber: number, index: number): Types.ImportableSupplierCommitmentAllocation => {
  return {
    weekNumber,
    pieceQuantity: getPieceQuantityForWeek(worksheetRow, index),
  };
};
