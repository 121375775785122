import * as React from 'react';
import { PropsWithChildren } from 'react';

function Content(props: PropsWithChildren<{ isVisible: boolean }>) {
  const { children, isVisible } = props;
  return (
    <main>
      <div className="container-fluid">
        { isVisible ? children : null }
      </div>
    </main>
  );
}

export default Content;
