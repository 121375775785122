import * as React from 'react';
import { startCase } from 'lodash';
import Filter from './filter';
import { ActiveFilter, AvailableFilter } from '../../types';
// import { orLog } from 'shared/helpers';
import { TYPES } from 'shared/types';

const FilterGroup = (p: {
  activeFilters: ActiveFilter[],
  availableFilters: AvailableFilter[],
  onFilterSelected: (...args: unknown[]) => unknown,
}) => {
  const nonPersistentFilters = (p.availableFilters || []).filter(f => !f.persistent);
  return (
  <div>
    {nonPersistentFilters.length > 0 && (
      nonPersistentFilters.map(filter => {
        const selectedOptions = p.activeFilters.find(c => c.field === filter.field) || {values: undefined};
        const values = selectedOptions.values || [];
        return (
          <Filter
            key={filter.field}
            // type={filter.type ?? orLog(TYPES.STRING, `Filter ${filter.field} is missing a type`)} // gets very verbose.  turn this back on when I'm feeling ambitious and not in a hurry.
            type={filter.type ?? TYPES.STRING}
            onSelected={(value => p.onFilterSelected(filter.field, value)) as shame}
            options={filter.options}
            selectedOptions={values as shame}
            placeholder={filter.displayName || startCase(filter.field)}
            testid={`filter-${filter.field}`}
            multi
          />
        );
      })
      )
    }
  </div>
  );
};

export default FilterGroup;

