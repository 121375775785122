import * as React from 'react';
import * as classnames from 'classnames';
import { CustomerOrderStatus, SupplierOrderStatus, EM_DASH, ReceivableOrderReceivingStatus } from '../../shared/types';
import { TYPES } from 'shared/types/meta';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { RoutePlanStatus } from 'schema/route-plan/route-plan-typescript-types';

/**
 * This is currently a problem because CUSTOMER_ORDER_STATUS.DRAFT and SUPPLIER_ORDER_STATUS.DRAFT
 * have the same valie (so whichever is added to the colorMap last wins);
 *
 * If we ever wanted to have different colors for CUSTOMER_ORDER_STATUS.DRAFT and SUPPLIER_ORDER_STATUS.DRAFT,
 * we would need to do work so that the keys are different (the keys are shown directly in the UI so that might be a problem)
 * or we need an additional piece of metadata to indicate whether we are looking at customer order statuses or supplier order statuses.
 *
 * We may consider replacing this static object literal map with a reducer or map function instead.
 */
const colorMap: {[key: string]: string} = {
  'Yes': 'success',
  'No': 'neutral',
  'N/A': 'no-color-chip',

  [CustomerOrderStatus.Approved]: 'success',
  [CustomerOrderStatus.Draft]: 'warning',
  [SupplierOrderStatus.Draft]: 'warning',
  [SupplierOrderStatus.Sent]: 'success',
  ['-']: 'no-color-chip',
  [EM_DASH]: 'no-color-chip',

  [ReceivableOrderReceivingStatus.NotReceived]: 'neutral',
  [ReceivableOrderReceivingStatus.PartiallyReceived]: 'warning',
  [ReceivableOrderReceivingStatus.FullyReceived]: 'success',
  [ReceivableOrderReceivingStatus.OverReceived]: 'danger',

  [SupplierCartTrackingStatus.Track]: 'success',
  [SupplierCartTrackingStatus.DoNotTrack]: 'neutral',

  [RoutePlanStatus.Draft]: 'warning',
  [RoutePlanStatus.ReadyToRoute]: 'orange',
  [RoutePlanStatus.Routed]: 'success',

  'Complete': 'success',
  'Not Complete': 'danger',

  'Sent': 'success',
};

export function StatusPill({status, type}: {status?: boolean | string | null | boolean, type?: TYPES}) {
  const text
    = status === true                   ? 'Yes'
    : status === false                  ? 'No'
    : !status && type === TYPES.BOOLEAN ? 'No'
    : status === 'N/A'                  ? EM_DASH
    :                                     (status || EM_DASH);

  const color = colorMap[text] || 'default';
  return (
    <div className={classnames('status-pill', color, {ellipsis: typeof status === 'string'})}>
      {text}
    </div>
  );
}
