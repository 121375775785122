import * as React from 'react';
import { RecordBarDetail, RecordBarDetailSet } from '../../record-bar';

/*
RecordBarDetailComponent.propTypes = {
  record: React.PropTypes.object,
};
*/

class RecordBarDetailComponent extends React.Component<shame> {
  render() {
    const { record } = this.props;

    return (
      <RecordBarDetailSet>
        <RecordBarDetail title="Supplier">
          {record.supplier.name}
        </RecordBarDetail>
        <RecordBarDetail title="Customer">
          {record.customer.name}
        </RecordBarDetail>
      </RecordBarDetailSet>
    );
  }
}

export default RecordBarDetailComponent;
