import * as React from 'react';
import * as BS from 'client/components/third-party';
import * as classnames from 'classnames';
import { IRecord } from 'shared/schemas/record';

export interface Props {
  id?: number;
  items?: RowMenuItem[];
  record?: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export interface RowMenuItem {
  label: string;
  onClick: ((ids: number[]) => any) | ((ids: number[], record?: shame) => any);
  shouldDisplay?: (record?: IRecord) => boolean;
  uncheckRecordFollowingClick?: boolean;
  willRemove?: boolean;
  disabled?: boolean;
}

export class Component extends React.Component<Props, State> {
  private overlayElement: React.Component<BS.OverlayTriggerProps, React.ComponentState> | null;

  public render() {
    const { items, id, record } = this.props;

    const filteredItems = items ? items.filter(i => {
      if (record && i.shouldDisplay) {
        return i.shouldDisplay(record);
      }

      if (!id && i.shouldDisplay) {
        return i.shouldDisplay();
      }

      return true;
    }) : [];

    if (filteredItems.length === 0) {
      if (!id) {
        return (<div className="disabled">
          <div className="mfc-icon-kebab" />
        </div>);
      }
      return (<div className="row-menu-disabled">
        <div className="mfc-icon-kebab disabled" />
      </div>);
    }

    const popOver = (
      <BS.Popover id="popover-trigger-click-root-close" className="mfc-popover-menu">
        <div className="mfc-dropdown">
          <div className="mfc-menu">
            {filteredItems.length > 0 && filteredItems.map(item => {
              const handleClick = () => {
                if (this.overlayElement) {
                  this.overlayElement.setState({ show: false });
                }

                item.onClick(id ? [id] : [], record);
              };

              const classNames = classnames('mfc-dropdown-menu-item', {
                danger: item.willRemove && !item.disabled,
                disabled: item.disabled,
              });

              return <BS.MenuItem
                key={item.label}
                className={classNames}
                onSelect={handleClick}
                disabled={item.disabled}>
                {item.label}
              </BS.MenuItem>;
            })}
          </div>
        </div>
      </BS.Popover>);

    return (
      <BS.OverlayTrigger trigger="click" rootClose placement="left" overlay={popOver} ref={element => this.overlayElement = element}>
        <BS.Dropdown.Toggle bsStyle="link" noCaret className="mfc-dropdown-toggle-button">
          <div className="mfc-icon-kebab" />
        </BS.Dropdown.Toggle>
      </BS.OverlayTrigger>);
  }
}

export default Component;
