import * as State from 'client/state/import-customer-order-spreadsheet';
import * as R from 'ramda';
import { ActionTypes, ActionTypeKeys } from 'client/actions/import-customer-order-spreadsheet';
import { CustomerOrderImportState } from 'client/state/import-customer-order-spreadsheet';

type ImportCustomerOrderSpreadsheetState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): ImportCustomerOrderSpreadsheetState {
  switch (action.type) {
    case ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenModalButtonClicked: {
      return R.pipe(
        State.importCustomerOrderSpreadsheetModalShown.set(true),
        State.customerOrderImportState.set(CustomerOrderImportState.Initial),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetCancelButtonClicked: {
      return R.pipe(
        State.importCustomerOrderSpreadsheetModalShown.set(false),
        State.customerOrderImportState.set(CustomerOrderImportState.Initial),
        State.scanBasedOrPoBased.set(undefined),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetOpenButtonClicked: {
      return R.pipe(
        State.importCustomerOrderSpreadsheetModalShown.set(false),
        State.customerOrderImportState.set(CustomerOrderImportState.Initial),
        State.scanBasedOrPoBased.set(undefined),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetParsing: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Parsing),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetValidating: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Validating),
        State.importableCustomerOrder.set(action.payload.importableCustomerOrder),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetValidated: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Validated),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetImporting: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Importing),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetImported: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Imported),
        State.customerOrderId.set(action.payload.customerOrderId),
        State.scanBasedOrPoBased.set(undefined),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetFailed: {
      return R.pipe(
        State.customerOrderImportState.set(CustomerOrderImportState.Failed),
        State.failedStep.set(action.payload.failedStep),
        State.errorMessages.set(action.payload.errorMessages),
        State.existingCustomerOrderId.set(action.payload.existingCustomerOrderId),
        State.scanBasedOrPoBased.set(undefined),
      )(state);
    }

    case ActionTypeKeys.ImportCustomerOrderSpreadsheetRadioButtonClicked: {
      return R.pipe(
        State.scanBasedOrPoBased.set(action.payload.value),
      )(state);
    }

    default:
      return state;
  }
}
