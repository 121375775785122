import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { AdminAttachmentPage } from 'client/components/admin/admin-attachment-page';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { withPropsInjector } from 'client/hoc/hoc';
import { buildTableStateModule } from 'client/state/tables';
import { ApolloRefetch, ActiveFilter, ActiveSearch, ActiveSort, AvailableFilter } from 'client/types';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { GetAssociatedAttachmentsResponse, GetAssociatedAttachmentsInput, Attachment, GetAttachmentFilterOptionsResponse } from 'schema/attachment/types';
import { getAssociatedAttachmentsQuery, getAssociatedAttachmentsExcelQuery } from './query';
import * as _ from 'lodash';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import { formContainer } from 'client/hoc/form-container';
import gql from 'graphql-tag';
import { formQueryColumns, buildFragment, tableName, TableName } from 'shared/schemas';
import * as pluralize from 'pluralize';
import { convertSearchAllToSpecificFields } from 'client/helpers/table-helpers';
import * as Actions from './actions';
import { withClientSideSorting } from 'client/containers/table/client-side-sorting';
import { RouteChildrenProps } from 'react-router';

export interface OwnProps extends RouteChildrenProps<{ id: string }> {
  recordType: string;
  tableName: string;
  record: any;
  tableParentInfo: TableParentInfo;
}

interface StateProps {
  activeFilters: ActiveFilter[];
  activeSearch: ActiveSearch;
  activeSortFields: ActiveSort[];
  tablePageNumber: number;
  paramsId: number | undefined;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

interface WithAttachmentsProps {
  attachments?: Attachment[];
  loading: boolean;
  totalUnfilteredCount: number;
  totalCount: number;
  refetchTable?: ApolloRefetch;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
}

interface DispatchProps {
  onDownloadMultipleAttachmentsMenuItemClicked(recordIds: number[], attachments: Attachment[] | undefined, record: any): void;
  onDownloadAttachmentMenuItemClicked(recordIds: number[], attachments: Attachment[] | undefined): void;
  onDeleteAttachmentsMenuItemClicked(tableName: string, recordIds: number[], attachments: Attachment[] | undefined): void;
  onAttachmentDescriptionUpdated(id: number, description: string | undefined): void;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithAttachmentsProps &
  WithTableParentProps;

function buildAdminAttachmentsPage <Table extends TableName> (config: {
  recordType: Table,
  tableName: string;
  RecordBarDetailComponent?: any;
  formName: string;
  emptyTablePlaceholder?: string,
  newButtonLabel?: string,
  supplementalRecordBarComponent?: React.FunctionComponent,
  navBuilder?: any,
  descriptionResolver?: (...args: any[]) => string;
}) {

  const searchableFields = [{ id: 'filename', name: 'File Name' }, { id: 'description', name: 'Description' }];
  const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
    const TableStateHelpers = buildTableStateModule(config.tableName);
    const paramsId = ownProps.match ? _.parseInt(ownProps.match.params.id) : undefined;
    return {
      ...TableStateHelpers.commonTableProps(state),
      paramsId,
    };
  };

  const mapDispatchToProps = {
    onDownloadMultipleAttachmentsMenuItemClicked: Actions.downloadMultipleAttachments,
    onDownloadAttachmentMenuItemClicked: Actions.downloadAttachment,
    onDeleteAttachmentsMenuItemClicked: Actions.deleteAttachments,
    onAttachmentDescriptionUpdated: Actions.onAttachmentDescriptionUpdated,
  };

  const WithAttachments = msyncQuery<{ getAssociatedAttachments: GetAssociatedAttachmentsResponse, getAttachmentFilterOptions: GetAttachmentFilterOptionsResponse }, OwnProps & StateProps & WithTableParentProps, WithAttachmentsProps, GetAssociatedAttachmentsInput>(
    getAssociatedAttachmentsQuery,
    {
      alias: 'withAssociatedAttachments',
      skip(props) { return !props.paramsId; },
      options(props) {
        return {
          variables: {
            input: {
              type: _.startCase(config.recordType),
              id: props.paramsId!,
              filters: props.activeFilters,
              search: convertSearchAllToSpecificFields(props.activeSearch, searchableFields),
              // we are doing client-side sorting on this table because we are using a custom
              // resolver for the file type field.
            },
          },
          fetchPolicy: 'network-only',
        };
      },
      props({ data }): WithAttachmentsProps {
        const content = data.getAssociatedAttachments;
        const filterContent = data.getAttachmentFilterOptions;
        return {
          attachments: content ? content.attachments : [],
          loading: data.loading,
          totalUnfilteredCount: content ? content.totalUnfilteredCount : 0,
          refetchTable: data.refetch,
          filteredRecordIds: content ? idsFor(content.attachments) : [],
          totalCount: content ? content.totalCount : 0,
          availableFilters: [
            { displayName: 'File Type' , field: 'extension', options: filterContent ? filterContent.typesFilterOptions : [] },
            { displayName: 'Created By', field: 'userId'   , options: filterContent ? filterContent.usersFilterOptions : [] },
          ],
          dataRequest: {
            operationName: 'getAssociatedAttachmentsForExcel',
            query: getAssociatedAttachmentsExcelQuery,
            variables: data.variables,
            worksheetName: 'Sheet1',
            workbookName: 'Attachments',
          },
        };
      },
    },
  );

  const parentTableName = pluralize(_.camelCase(config.recordType));
  const fragmentName = `${config.recordType}DetailFragmentForFragments`;
  const fragment = gql`${buildFragment(parentTableName, formQueryColumns(parentTableName), fragmentName)}`;
  const DEFAULT_DETAIL_QUERY = gql`
    query findFromAttachmentList${config.recordType}($type: RecordType!, $id: Int!) {
      data: find(type: $type, id: $id) {
        id,
        ...${fragmentName}
      }
    }
    ${fragment}
  `;

  const DEFAULT_MUTATION = gql`
    mutation mutateFromAttachmentList${config.recordType}($input: Edit${config.recordType}Input!) {
      data: edit${config.recordType}(input: $input) {
        id,
        ...${fragmentName}
      }
    }
    ${fragment}
  `;

  const DEFAULT_ON_SUBMIT = ({save}: any) => (rec: any) => save(rec);
  const formContainerHoc = formContainer({
    formName: `${config.recordType}Form`,
    mutationStrings: { save: DEFAULT_MUTATION },
    isNewRecord: undefined,
    onSubmit: DEFAULT_ON_SUBMIT,
    queryString: DEFAULT_DETAIL_QUERY,
    resetApolloStoreAfterSave: false,
    refetchQueries: undefined,
    table: tableName(config.recordType),
    initialValues: undefined,
  });

  return flowRight(
    formContainerHoc,
    tableParentHoc(),
    connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
    WithAttachments,
    withPropsInjector({
      emptyTablePlaceholder: config ? config.emptyTablePlaceholder : undefined,
      newButtonLabel: config ? config.newButtonLabel : undefined,
      supplementalRecordBarComponent: config ? config.supplementalRecordBarComponent : undefined,
      navBuilder: config ? config.navBuilder : undefined,
      RecordBarDetailComponent: config ? config.RecordBarDetailComponent : undefined,
      tableName: config ? config.tableName : undefined,
      recordType: config ? config.recordType : undefined,
      searchableFields,
      descriptionResolver: config ? config.descriptionResolver : undefined,
    }),
  withClientSideSorting<ComponentProps>(config.tableName, 'attachments', true),
  )(AdminAttachmentPage);
}

export default buildAdminAttachmentsPage;
