import buildAdminCreatePage from '../admin/admin-create-page';
import FormComponent from 'client/components/crud-forms/merchandiser-form';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminCreatePage({
  formName: 'MerchandisersDetailsForm',
  table: 'merchandisers',
  FormComponent,
  OptionalStatusToggle: EmptyStatusToggle,
});
