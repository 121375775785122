import { tableName, property, definePresentation, belongsTo, required } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { User } from 'shared/schemas/user';
import { OutboundMail } from 'shared/schemas/outbound-mail';
import { DateTimeStr } from 'shared/types';

export enum SupplierOrderMailType {
  PurchaseOrder = 'PurchaseOrder',
  ReceivingReceipt = 'ReceivingReceipt',
}

@tableName('supplierOrderMails')
export class SupplierOrderMail implements IRecord {
  id?: number;

  @belongsTo('supplierOrders')
  @property supplierOrder?: SupplierOrder;
  supplierOrderId?: number;

  @belongsTo('outboundMails', { foreignDisplayKey: 'id', foreignQueryKeys: [] })
  @property outboundMail?: OutboundMail;
  outboundMailId?: number;

  @belongsTo('users', { foreignDisplayKey: 'firstName', foreignQueryKeys: ['firstName', 'lastName'] })
  @property user?: User;
  userId?: number;

  @property @required type: SupplierOrderMailType;

  createdAt: DateTimeStr;
}

definePresentation(SupplierOrderMail, {
  supplierOrder: { formDisplay: false, includeInFormQuery: true },
  outboundMail: { formDisplay: false, includeInFormQuery: true },
  user: { formDisplay: false, includeInFormQuery: true },
});
