import * as React from 'react';
import * as BS from 'client/components/third-party';

interface OwnProps<T> { initialValues: T }
export interface SaveConfirmationProps { confirmOkToSave: () => Promise<boolean> }
interface SaveConfirmationState { showSaveConfirmation: boolean, saveConfirmationHasBeenAccepted: boolean, closePopup?: (confirmed: boolean) => void }
interface Message { title: string, body: string }
export type SaveConfirmationArgs<T> = {
  onlyAskOnce?: boolean;
  message: Message | ((record?: T) => Message);
  saveConfirmMessage?: string;
  shouldDisplay: (record?: T) => boolean;
  propName?: string;
};

const getSaveConfirmationText = (message: Message) => (
  <span>
    {!message.title ? '' : <div><strong>{message.title}</strong></div>}
    {message.body}
  </span>
);

// return (WrappedComponent: new (passedProps: OwnProps<T>) => React.Component<OwnProps<T> & SaveConfirmationProps, any>) => {
  // I shamed this type because the prop coming back can be anything. Talk to Andy if you got questions (AP 6/1/18)
export const withSaveConfirmation = <T extends unknown>(saveConfirmationOptions?: SaveConfirmationArgs<T>) =>
  // If no options specified then no need to keep track of anything - no confirmation is required.
  (WrappedComponent: new (passedProps: OwnProps<T>) => React.Component<shame, any>) => !saveConfirmationOptions
    ? (passedProps: OwnProps<T>) => <WrappedComponent {...passedProps} confirmOkToSave={() => Promise.resolve(true)} />
    : class WithSaveConfirmation extends React.Component<OwnProps<T>, SaveConfirmationState> {
        constructor(props: OwnProps<T>) {
          super(props);
          this.state = { showSaveConfirmation: false, saveConfirmationHasBeenAccepted: false };
          this.confirmOkToSave = this.confirmOkToSave.bind(this);
          this.hideSaveConfirmation = this.hideSaveConfirmation.bind(this);
          this.confirmSaveHandler = this.confirmSaveHandler.bind(this);
        }

        private hideSaveConfirmation() {
          if (this.state.closePopup)
            this.state.closePopup(false);
        }
        private confirmSaveHandler() {
          this.setState({ saveConfirmationHasBeenAccepted: true });
          if (this.state.closePopup)
            this.state.closePopup(true);
        }

        private async confirmOkToSave(): Promise<boolean> {
          if (saveConfirmationOptions.shouldDisplay(this.props.initialValues) && !(saveConfirmationOptions.onlyAskOnce && this.state.saveConfirmationHasBeenAccepted)) {
            return new Promise<boolean>((resolve, reject) => {
              this.setState({
                showSaveConfirmation: true,
                closePopup: value => {
                  // Hide the dialog regardless of what the user selected
                  this.setState({ showSaveConfirmation: false });
                  resolve(!!value); // i.e. did the user gave the go ahead?
                },
              });
            });
          }

          // Don't need to ask the user before proceeding
          return Promise.resolve(true);
        }

        public render() {
          return (
            <div>
              <BS.Modal show={this.state.showSaveConfirmation} dialogClassName="toast-modal" onHide={this.hideSaveConfirmation} className="save-confirmation-modal" backdropClassName="save-confirmation-backdrop">
                <BS.Modal.Body>
                  <div className="alert alert-danger">
                    <span className="pficon fa fa-exclamation-triangle text-danger" />
                    {saveConfirmationOptions ? (typeof saveConfirmationOptions.message === 'function' ? getSaveConfirmationText(saveConfirmationOptions.message(this.props.initialValues)) : getSaveConfirmationText(saveConfirmationOptions.message)) : ''}
                    <div className="actions pull-right">
                      <strong><a data-testid="confirm-save" onClick={this.confirmSaveHandler}>
                        {saveConfirmationOptions.saveConfirmMessage || 'Save Changes'}</a></strong> | <a data-testid="cancel-save" onClick={this.hideSaveConfirmation}>Cancel</a>
                    </div>
                  </div>
                </BS.Modal.Body>
              </BS.Modal>
              <WrappedComponent {...this.props} {...({ [saveConfirmationOptions.propName || 'confirmOkToSave']: this.confirmOkToSave })} />
            </div>
          );
        }
      };
