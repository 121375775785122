import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import * as StatsApi from '../../../shared/stats-card-api';
import { recordType as getRecordType } from '../../../shared/schemas';
import { buildFunctionHolder } from 'client/utils/side-effect-function-holder';
import { orThrowBug } from 'shared/helpers';

interface StateProps { statsOptions?: StatsApi.StatsOptions }
export interface GraphQLProps { stats: StatsApi.Card[], refetchStats: () => Promise<void> }
export type GetStatsOptions = (state: any, formName?: string) => StatsApi.StatsOptions;
export type StatsContainerProps = StateProps & GraphQLProps;
const STATS_QUERY = gql`query getStats($type: RecordType!, $options: JSON) { stats: getStats(type: $type, options: $options) }`;

export const withRecordStats = (table: string, statsOptionsFromState?: GetStatsOptions) => {
  const recordType = getRecordType(table) || orThrowBug(`you messed up david, no recordType known for : '${table}'`);
  const refetchStatsHolder = buildFunctionHolder('refetchStats');
  const withStats = msyncQuery<{ stats: StatsApi.Card[] }, StateProps, GraphQLProps>( STATS_QUERY, {
      alias: 'withRecordStats',
      skip: p => !statsOptionsFromState || !p.statsOptions,
      // Optimize: Should be able to use reselect to prevent the same stats coming in from causing a bunch of re-renders
      props: ({ ownProps: p, data }) => ({
        // SIDE-EFFECT - Update the refetch function in a way that won't cause downstream components to be re-rendered. (This is totally cheating the holy bible of react-redux-functions-are-your-one-true-overlord)
        // refetchStats will always be the same function reference, but will end up executing the latest refetch internally
        refetchStats: refetchStatsHolder.update(data.refetch),
        stats: !!data.stats && data.stats.length > 0 && p.statsOptions
          ? StatsApi.deserializeCardContent(data.stats, p.statsOptions)
          : [],
      }),
      options: args => ({
        variables: { type: recordType || orThrowBug(`you messed up david, no recordType known for : '${table}'`), options: args.statsOptions?.options },
        fetchPolicy: 'cache-and-network',
      }),
    },
  );

  return WrappedComponent => flowRight(
    connect((state, p: {stats?: StatsApi.Card[], formName: string}) => {
      const statsOptions = statsOptionsFromState?.(state, p.formName);
      return {
        statsOptions,
        stats: p.stats ?? StatsApi.EmptyStats(statsOptions),
      };
    }),
    withStats,
  )(WrappedComponent);
};
