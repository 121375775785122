import * as _ from 'lodash';
import { connect } from 'react-redux';
import * as React from 'react';
import { ImmutableDateRange } from 'shared/types';
import gql from 'graphql-tag';
import { msyncMutation, MsyncMutationProps } from 'client/hoc/graphql/mutation';
import { withApollo } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import * as Actions from 'client/actions/product-worksheet';
import { timeout } from 'shared/helpers';
import { PromiseRejection, PromiseResolution } from 'shared/types/promise';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

interface OwnProps {
  customerOrderId: number;
  selectedProduct: {
    productId: number;
  };
  selectedComparableProductId: number | undefined;
  performanceDateRange: ImmutableDateRange;
  salesDateRange: ImmutableDateRange;
  pieceTargetDateRange: ImmutableDateRange;
  pieceTargetPrimaryStoresOnly: boolean;
  confirmOkToSave: () => Promise<boolean>;  // Want this to get passed into the mutation
}

interface RefetchAllocationDetailsArg {
  client: ApolloClient<NormalizedCacheObject>;
  customerOrderId: number;
  selectedProductId: number;
  selectedComparableProductId: number | undefined;
  storeIds: number[];
  performanceDateRange: ImmutableDateRange;
  salesDateRange: ImmutableDateRange;
  pieceTargetDateRange: ImmutableDateRange;
  pieceTargetPrimaryStoresOnly: boolean;
}

interface DispatchProps {
  refetchAllocationDetails(args: RefetchAllocationDetailsArg);
  productAllocationChanged(args: {
    customerOrderProductGroupId: number,
    storeId: number,
    quantity: number,
    prevQuantity: number,
    saveFunc: SaveAllocationFunc,
    refetchFunc: RefetchAllocationFunc,
    mutationResolve: PromiseResolution<void>,
    mutationReject: PromiseRejection,
  }): void;
  clearPendingAllocationChanges();
}

interface MutationInput {
  input: Array<{
    customerOrderProductGroupId: number;
    storeId: number;
    quantity: number;
  }>;
}
interface WithMutationProps {
  allocateProductMutate: MsyncMutationProps<any, any, MutationInput>['mutate'];
}

interface WithAllocationSaverProps {
  allocateProductToStore: (args: { customerOrderProductGroupId: number, storeId: number, quantity: number, prevQuantity: number }) => Promise<void>;
  allocateProductToStores: (input: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number }>) => Promise<void>;
}

export interface WithApolloProps {
  client: ApolloClient<NormalizedCacheObject>;
}

const mutation = gql`
  mutation productWorksheetAllocationProductsToStores($input: [AllocateProductsToStoresInput]!) {
    allocateProductsToStores(input: $input) {
      allocatedAt
    }
  }
`;

const withMutation = msyncMutation<{}, OwnProps, WithMutationProps>(mutation, {
  alias: 'withAllocateProductsToStoresMutation',
  skipMutationStatusChanges: true,
  props: props => {
    return { allocateProductMutate: props.mutate };
  },
});

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    refetchAllocationDetails(args: RefetchAllocationDetailsArg) {
      dispatch(Actions.refetchProductAllocations(args));
    },

    productAllocationChanged(args: {
      customerOrderProductGroupId: number,
      storeId: number,
      quantity: number,
      prevQuantity: number,
      saveFunc: SaveAllocationFunc,
      refetchFunc: RefetchAllocationFunc,
      mutationResolve: PromiseResolution<void>,
      mutationReject: PromiseRejection,
    }) {
      dispatch(Actions.productAllocationChanged(args));
    },

    clearPendingAllocationChanges() {
      dispatch(Actions.pendingAllocationResetNeeded());
    },
  };
}

type SaveAllocationFunc = (allocations: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number }>) => void;
type RefetchAllocationFunc = (storeIds: number[]) => void;

const withAllocationSaver = (WrappedComponent: new (passedProps: OwnProps & DispatchProps & WithMutationProps & WithApolloProps) => React.Component<shame, any>) => {
  return class WithAllocationSaver extends React.PureComponent<OwnProps & DispatchProps & WithMutationProps & WithApolloProps> {

    constructor(props: OwnProps & DispatchProps & WithMutationProps & WithApolloProps) {
      super(props);
    }

    allocateProductToStore = async (args: { customerOrderProductGroupId: number, storeId: number, quantity: number, prevQuantity: number }): Promise<void> => {
      return new Promise<void>((resolve, reject) => {

        // There's nothing about this mutate function that's specific to anything - it accepts
        // all input it needs as arguments, so it can be used to save any combination of allocations.
        const mutateFunc = this.props.allocateProductMutate;

        // The only thing the following function closes over is the mutateFunc. Everything
        // else it needs is passed in so it can be used to save any batch of allocations,
        // not just the one specified above in this particular call to allocateProductToStore.
        function saveAllocationsToServer(allocations: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number, mutationResolve: PromiseResolution<void>, mutationReject: PromiseRejection }>) {

          // This current implementation allows Apollo to do the work of combining multiple
          // mutations into a single HTTP request. Our mutateFunc interface here allows for
          // multiple allocations to go over in one call, but it works out better for error
          // handling if each one is a separate mutation which Apollo then batches together.
          const promises = allocations.map(async a => {
            const variables = {
              variables: {
                input: [{
                  customerOrderProductGroupId: a.customerOrderProductGroupId,
                  storeId: a.storeId,
                  quantity: a.quantity,
                }],
              },
            };

            try {
              await mutateFunc(variables);

              // Each allocation (corresponding to a row in the product worksheet) get resolved/rejected individually
              // Note that these are using the callbacks sitting on the allocations in the loop, not the ones
              // passed into the function. This is a little confusing, but it has to do with how we're batching up
              // allocations and then sending them over in groups. Ideally this code wouldn't know anything about
              // that, but it didn't work out that way.
              a.mutationResolve();
            } catch (err) {
              a.mutationReject(err);
              throw err;
            }
          });

          return Promise.all(promises);
        }

        const refetchFunc = this.props.refetchAllocationDetails;
        const client = this.props.client;
        const customerOrderId = this.props.customerOrderId;
        const selectedProductId = this.props.selectedProduct.productId;
        const selectedComparableProductId = this.props.selectedComparableProductId;
        const performanceDateRange = this.props.performanceDateRange;
        const salesDateRange = this.props.salesDateRange;
        const pieceTargetDateRange = this.props.pieceTargetDateRange;
        const pieceTargetPrimaryStoresOnly = this.props.pieceTargetPrimaryStoresOnly;
        function refetchAllocationDetails(storeIds: number[]) {
          return refetchFunc({
            client,
            customerOrderId,
            selectedProductId,
            selectedComparableProductId,
            storeIds,
            performanceDateRange,
            salesDateRange,
            pieceTargetDateRange,
            pieceTargetPrimaryStoresOnly,
          });
        }

        this.props.productAllocationChanged({
          ...args,
          saveFunc: saveAllocationsToServer,
          refetchFunc: refetchAllocationDetails,
          mutationResolve: resolve,
          mutationReject: reject,
        });
      });
    }

    allocateProductToStores = async (args: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number, prevQuantity: number }>): Promise<void> => {
      const mutateFunc = this.props.allocateProductMutate;
      const refetchFunc = this.props.refetchAllocationDetails;
      const client = this.props.client;
      const customerOrderId = this.props.customerOrderId;
      const selectedProductId = this.props.selectedProduct.productId;
      const selectedComparableProductId = this.props.selectedComparableProductId;
      const performanceDateRange = this.props.performanceDateRange;
      const salesDateRange = this.props.salesDateRange;
      const pieceTargetDateRange = this.props.pieceTargetDateRange;
      const clearPendingAllocationChanges = this.props.clearPendingAllocationChanges;
      const pieceTargetPrimaryStoresOnly = this.props.pieceTargetPrimaryStoresOnly;

      let encounteredFailure = false;

      return new Promise<void>(async (resolve, reject) => {
        // Normally the confirmOkToSave is called by the mutation. But in this case
        // we're going to potentially be calling many mutations, so want to ask here first
        if (this.props.confirmOkToSave) {
          if (!await this.props.confirmOkToSave()) {
            resolve();
            return undefined;
          }
        }

        /**
         * This one is used for auto-replenishment, which updates all of the checked allocations
         * without the user having to manually change each row.
         */
        function saveAllocationsToServer(allocations: Array<{ customerOrderProductGroupId: number, storeId: number, quantity: number, mutationResolve: PromiseResolution<void>, mutationReject: PromiseRejection }>) {
          const promises = allocations.map(async a => {
            const variables = {
              variables: {
                input: [{
                  customerOrderProductGroupId: a.customerOrderProductGroupId,
                  storeId: a.storeId,
                  quantity: a.quantity,
                }],
              },
            };
            try {
              await mutateFunc(variables);

              // Unlike the "single" save allocation, this one doesn't resolve the promise for each
              // allocation. It waits until the whole thing is done, and resolves an outer promise
              // to indicate that the dialog/modal can be closed.
            } catch (err) {
              // There's a loop below adding all of the allocations. It might still be looping
              // and adding. Want to make sure it stops if an error is encountered. (Where are you Rx??)
              encounteredFailure = true;

              clearPendingAllocationChanges();

              // Any failure causes the outer promise to reject
              reject(err);
            }
          });
          return Promise.all(promises);
        }

        async function refetchAllocationDetails(storeIds: number[]) {
          await refetchFunc({
            client,
            customerOrderId,
            selectedProductId,
            selectedComparableProductId,
            storeIds,
            performanceDateRange,
            salesDateRange,
            pieceTargetDateRange,
            pieceTargetPrimaryStoresOnly,
          });
          resolve();
        }

        let count = 0;
        for (const arg of args) {
          if (encounteredFailure) {
            return;
          }
          count += 1;
          this.props.productAllocationChanged({
            ...arg,
            saveFunc: saveAllocationsToServer,
            refetchFunc: refetchAllocationDetails,
            mutationResolve: resolve,
            mutationReject: reject,
          });

          // Give the UI a chance to update the spinner
          if (count % 10 === 0) {
            await timeout(5);
          }
        }
      });
    }

    public render() {
      // Want to pass along props, but not things that were added upstream just to
      // be able to support the allocateProductToStore function.
      const improvedProps = {
        ..._.omit(this.props, ['client', 'allocateProductMutate', 'refetchAllocationDetails', 'clearPendingAllocationChanges']),
        allocateProductToStore: this.allocateProductToStore,
        allocateProductToStores: this.allocateProductToStores,
      };

      return (
        <div>
          <WrappedComponent {...improvedProps} />
        </div>
      );
    }
  };
};

export type ProductAllocationComponentProps =
  OwnProps &
  WithAllocationSaverProps;

export const withProductAllocationSupport = (component: React.Component<OwnProps, any> ): React.Component<OwnProps, any> => {
  return _.flowRight(
    withMutation,
    withApollo,
    connect<{}, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
    withAllocationSaver,
  )(component);
};
