import { noop } from 'lodash';
import * as classnames from 'classnames';
import * as React from 'react';

export interface SearchProps {
  allRowsChecked: boolean;
  disabled: boolean;
  inputRef?: (element: HTMLInputElement) => void;
  isCaretDisabled: boolean;
  isOpen: boolean;
  noRowsShown: boolean;
  onCaretClicked: () => void;
  onChanged: (value: string) => void;
  onInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputClicked: (event: React.MouseEvent<HTMLInputElement>) => void;
  onInputFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  loading?: boolean;
  searchText: string;
}

export const Search = (props: SearchProps) => {
  const onCaretClicked = !props.isCaretDisabled
    ? props.onCaretClicked
    : noop;

  function onChanged(event: React.FormEvent<HTMLInputElement>) {
    return props.onChanged(event.currentTarget.value);
  }

  return (
    <div
      className={classnames('selectable-search', {
        'selectable-search-closed': props.noRowsShown,
        'disabled': props.disabled,
      })}
    >
      {props.loading &&
        <div className="selectable-search-all-loading"><i className="fa fa-spinner fa-spin"></i></div>
      }

      {!props.loading && props.allRowsChecked &&
        <div className="selectable-search-all-items-checked">All</div>
      }

      {!props.loading && !props.allRowsChecked &&
        <input
          disabled={props.disabled}
          onChange={onChanged}
          onClick={props.onInputClicked}
          onKeyDown={props.onKeyDown}
          ref={props.inputRef}
          type="text"
          onFocus={props.onInputFocus}
          onBlur={props.onInputBlur}
          value={props.searchText}
        />
      }

      <div className="selectable-search-caret" onClick={onCaretClicked}>
        <i className={classnames('fa', {
          'fa-caret-up': props.isOpen,
          'fa-caret-down': !props.isOpen,
        })}></i>
      </div>
    </div>
  );
};
