//
// NOTE: THIS IS THE "NEW" RECORD BAR :-/
//

import * as React from 'react';
import { Row } from 'client/components/third-party';
import { RecordBarBackButton } from './record-bar-back-button';
import { useHistory } from 'react-router';

export interface Props {
  children?: React.ReactNode | React.ReactNode[];
  onBackButtonClicked?: () => void;
  title?: string;
  hideBackButton?: boolean;
}

export const RecordBar: React.SFC<Props> = (props: Props) => {
  const history = useHistory();
  const onBackButtonClicked = () => {
    history.goBack();
  };

  return (
    <Row className="record-bar-row">
      <div className="record-bar">
        <div className="detail-area">
          {!props.hideBackButton && <RecordBarBackButton onClick={props.onBackButtonClicked || onBackButtonClicked} />}
          <div className="record-bar-title-text">{props.title || ''}</div>
        </div>
      </div>
    </Row>
  );
};
