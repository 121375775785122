import * as _ from 'lodash';
import * as React from 'react';
import * as classnames from 'classnames';
import { Modal, Button, Col } from 'client/components/third-party';
import { formatMoneyOrNull } from 'shared/types';
import { SendSupplierOrderModalInitialValues } from 'client/app/orders/supplier-orders/overview/modals/send-supplier-order-modal-container';
import { formatDate } from 'shared/helpers/date-helpers';
import { Checkbox } from 'client/components/form/checkbox';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { MutationStatus } from 'client/actions/mutations';
import { SupplierOrderEmailRecipient } from 'client/state/supplier-order';
import { SupplierContactId } from 'shared/schemas/supplier-contact';

export interface OwnProps {
  supplierOrderId: number;
  isShown: boolean;
  handleCancelButtonClicked: () => void;
  handleSubmit: () => any;
  invalid: boolean;
  id: string;
  title: string;
  totalCost?: number;
  handleRecipientClicked: (supplierContactId: number) => void;
  // setSupplierOrderContacts: (contacts: Array<{ supplierContactId: number, selected: boolean }>) => void;
  setInitalSupplierContacts: () => void;
  recipients: SupplierOrderEmailRecipient[];
  handleSelectAllClicked: () => void;
  selectAllSelected: boolean;
  actionStatus: MutationStatus;
  disableSubmitButton: boolean;
  cancelDisabled: boolean;
}

type ComponentProps = OwnProps & SendSupplierOrderModalInitialValues;

enum ModalFormatting {
  Title = 'send-supplier-order-modal-title',
  Detail = 'send-supplier-order-modal-detail',
  Green = 'send-supplier-order-modal-detail-green',
  RecipientCheckbox = 'send-supplier-order-modal-recipients-checkbox',
  RecipientLabel = 'send-supplier-order-modal-recipients-label',
  SelectAll = 'send-supplier-order-modal-select-all',
}

const TitleDetailLabel: React.StatelessComponent<{ title: string, detailClassName?: string }> = props => {
  return (
    <div className="send-supplier-order-modal-row" data-testid={`send-supplier-order-modal-${_.kebabCase(props.title)}`}>
      <Col sm={3}>
        <div className={classnames(ModalFormatting.Title)}>
          {props.title}
        </div>
      </Col>
      <Col sm={9}>
        <div className={classnames(ModalFormatting.Detail, props.detailClassName)}>
          {props.children}
        </div>
      </Col>
    </div>
  );
};

interface SelectAllSupplierContactCheckBoxProps {
  handleSelectAllClicked(): void;
  selectAllSelected: boolean;
}
class SelectAllSupplierContactCheckBox extends React.Component<SelectAllSupplierContactCheckBoxProps, any> {
  onChangeHandler = () => {
    this.props.handleSelectAllClicked();
  }
  onLabelClick = () => this.onChangeHandler();
  render() {
    const checkboxInputChangeHandler = {
      onChange: this.onChangeHandler,
      value: this.props.selectAllSelected,
    };
    return (
      <div className={ModalFormatting.SelectAll} key={'select-all'} data-testid={'recipient-select-all'}>
        <Checkbox className={ModalFormatting.RecipientCheckbox} input={checkboxInputChangeHandler}/>
        <div className={ModalFormatting.RecipientLabel} onClick={this.onLabelClick}>Select All</div>
      </div>
    );
  }
}

interface SupplierContactCheckboxesProps {
  recipients: SupplierOrderEmailRecipient[];
  handleRecipientClicked(supplierContactId: SupplierContactId): void;
}
class SupplierContactCheckboxes extends React.Component<SupplierContactCheckboxesProps, any> {
  render() {
    const recipientCheckboxes = this.props.recipients.map(contact => {
      const checkboxInputChangeHandler = {
        onChange: (value: boolean) => {
          if (contact?.supplierContactId) {
            this.props.handleRecipientClicked(contact.supplierContactId);
          }
        },
        value: contact.selected,
      };
      return (
        <div key={contact.supplierContactId} data-testid={`recipient-${_.kebabCase(contact.name)}`}>
          <Checkbox className={ModalFormatting.RecipientCheckbox} input={checkboxInputChangeHandler} />
          <div className={ModalFormatting.RecipientLabel} onClick={() => checkboxInputChangeHandler.onChange(!checkboxInputChangeHandler.value)}>{contact.name}</div>
        </div>
      );
    });
    return (
      <div>
        {recipientCheckboxes}
      </div>
    );
  }
}

export const SendSupplierOrderModal: React.StatelessComponent<ComponentProps> = props => {
  return (
    <Modal
      id={props.id}
      animation
      backdrop="static"
      show={props.isShown}
      onHide={props.handleCancelButtonClicked}
      onEnter={props.setInitalSupplierContacts}
      dialogClassName="send-supplier-order-modal-container"
      className="send-supplier-order-modal" >
      <Modal.Header closeButton={!props.cancelDisabled}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col sm={12}>
          <h3 className="send-supplier-order-modal-heading">
            {`PO #${props.supplierOrderIdentifier}`}
          </h3>
        </Col>
        <TitleDetailLabel title="Supplier">
          {props.supplierName}
        </TitleDetailLabel>
        <TitleDetailLabel title="Customer">
          {props.customerName}
        </TitleDetailLabel>
        {props.mfcArea && <TitleDetailLabel title="MFC Area">{props.mfcArea}</TitleDetailLabel>}
        <TitleDetailLabel title="Arrival Date">
          {formatDate(props.arrivalDate, 'MM/DD/YYYY') || ''}
        </TitleDetailLabel>
        <TitleDetailLabel title="Order Price" detailClassName={classnames(ModalFormatting.Green)}>
          {formatMoneyOrNull(props.totalCost)}
        </TitleDetailLabel>
        <Col sm={12}>
          <hr className="send-supplier-order-modal-separator" />
        </Col>
        <div className="send-supplier-order-modal-recipients">
          <TitleDetailLabel title="Recipients">
            <SelectAllSupplierContactCheckBox handleSelectAllClicked={props.handleSelectAllClicked} selectAllSelected={props.selectAllSelected}/>
            <SupplierContactCheckboxes recipients={props.recipients} handleRecipientClicked={props.handleRecipientClicked}/>
          </TitleDetailLabel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsClass="mfc-button mfc-clear-button"
          onClick={props.handleCancelButtonClicked}
          disabled={props.cancelDisabled}>
          Cancel
          </Button>
        <AsyncButton
          label="Send PO"
          disabled={props.disableSubmitButton}
          type={'submit'}
          testid="modal-submit"
          actionStatus={props.actionStatus}
          onClick={props.handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SendSupplierOrderModal;
