import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compact, difference, sortBy } from 'lodash';
import gql from 'graphql-tag';

import * as Actions from 'client/actions/supplier-order';
import { propToComponent, wrapComponent } from 'client/hoc/hoc';
import SupplierItemModal, { FormValues } from './supplier-item-modal';
import { withSupplierItems, WithSupplierItemsProps } from 'client/app/orders/supplier-orders/overview/modals/with-supplier-items';
import { ApolloRefetch } from 'client/types';
import { OrderMethod, ShippingUnitType } from 'shared/types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { SupplierOrderProductGroupId } from 'shared/schemas/supplier-order-product-group';
import { SupplierOrderProductId } from 'shared/schemas/supplier-order-product';
import { SupplierItemId } from 'shared/schemas/supplier-item';
import { EditSupplierOrderProductGroupWithQuantityInput } from 'shared/types';
import { SupplierOrderId } from 'shared/schemas/supplier-order';
import * as State from 'client/state/state';

const formName = 'edit-product-group';

interface OwnProps {
  supplierOrderId: number;
  supplierId: number;
  customerId: number;
  sellDepartmentId: number;
  sellDepartmentIdentifier: string;
  refetchTable: ApolloRefetch;
  isModal?: boolean;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  supplierOrderProducts: any[];
  userConfirmation: () => Promise<boolean | void>;
}

interface StateProps {
  isShown: boolean;
  isCombo: boolean;
  supplierOrderProductId?: number;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    isShown: state.supplierOrder.editSupplierOrderProductGroupModalShown,
    isCombo: false, // TODO: resolve this guy properly
    supplierOrderProductId: state.supplierOrder.supplierOrderProductToEditId,
  };
};

type ReduxFormProps = InjectedFormProps<FormValues>;

const mapDispatchToProps = (dispatch, ownProps: OwnProps & ReduxFormProps & StateProps & WithSupplierItemsProps & WithMutationProps & WithInitialValues) => {
  return {
    handleCancelButtonClicked: () => dispatch(Actions.editSupplierOrderProductGroupModalCancelButtonClicked()),
    handleFormSubmit: () => {
      return ownProps.handleSubmit(async (formValues: FormValues) => {
        if (false === await ownProps.userConfirmation()) {
          return;
        }

        return dispatch(Actions.editSupplierOrderProductGroupModalUpdateClicked({
          formName,
          async editFunction() {
            const addedSupplierOrderProducts = formValues.supplierOrderProducts.filter(sop => !sop.id);
            const updatedSupplierOrderProducts = formValues.supplierOrderProducts.filter(sop => sop.id);
            const deletedSupplierOrderProducts = (compact(difference((ownProps.initialValues.supplierOrderProducts || []).map(sop => sop.id), formValues.supplierOrderProducts.map(sop => sop.id))) as SupplierOrderProductId[]).map(id => ({ id }));
            const formattedFormValues: EditSupplierOrderProductGroupWithQuantityInput = {
              id: formValues.id || -1,
              identifier: formValues.identifier,
              description: formValues.description,
              quantity: formValues.quantity,
              isCombo: formValues.isCombo,
              packsPerShippingUnit: formValues.packsPerShippingUnit,
              supplierOrderProducts: {
                created: addedSupplierOrderProducts.map(sop => ({
                    packSize: sop.packSize,
                    packsPerShelf: sop.packsPerShelf,
                    shelvesPerRack: sop.shelvesPerRack,
                    packsPerShippingUnit: sop.packsPerShippingUnit,
                    supplierItemId: sop.supplierItemId,
                  }) as CreateSupplierOrderProductInput),
                updated: updatedSupplierOrderProducts.map(sop => ({
                    id: sop.id as number, // We filtered for ids above, so of couse we'll have one (AP 9/26/17)
                    packSize: sop.packSize,
                    packsPerShelf: sop.packsPerShelf,
                    shelvesPerRack: sop.shelvesPerRack,
                    packsPerShippingUnit: sop.packsPerShippingUnit,
                    supplierItemId: sop.supplierItemId,
                  }) as EditSupplierOrderProductInput),
                deleted: deletedSupplierOrderProducts,
              },
            };
            await ownProps.editSupplierOrderProductGroup(formattedFormValues);
            await ownProps.refetchTable();
          },
        }));
      });
    },
    handleSupplierItemChanged: (supplierItemId, supplierItemFieldIdentifier) => {
      const { isCombo, orderMethod, supplierItems } = ownProps;
      const supplierItem = supplierItems.find(si => si.id === supplierItemId);
      if (!supplierItem) {
        // This really shouldn't happen. Must be a developer mistake.
        throw new Error(`Supplier Item not found with id ${supplierItemId}`);
      }

      dispatch(Actions.editSupplierOrderProductGroupModalSupplierItemChanged({ formName, supplierItem, supplierItemFieldIdentifier, isCombo, orderMethod }));
    },
    handlePacksPerRackCalculationInputChange: (productFieldIdentifier: string, field?: 'shelvesPerRack' | 'packsPerShelf' | 'packSize', value?: number) => {
      return dispatch(Actions.packsPerRackCalculationInputChange(formName, productFieldIdentifier, field, value));
    },
  };
};

interface WithModalProps {
  title: string;
  submitButtonText: string;
  showAddSupplierItemButton: boolean;
  formName: string;
  id: string;
  disableSupplierItemField: boolean;
}

const withModalProps = WrappedComponent => props => {
  const modalProps = {
    ...props,
    title: 'Edit Configuration',
    submitButtonText: 'Update',
    showAddSupplierItemButton: true,
    formName,
    id: 'edit-product-modal',
    disableSupplierItemField: true,
  };
  return propToComponent(WrappedComponent, modalProps);
};

const QUERY = gql`
  query findSupplierOrderProductsForEditModal($type: RecordType!, $id: Int!) {
    content: find(type: $type, id: $id) {
      ...supplierOrderProductFragment
      __typename
    }
  }
  fragment supplierOrderProductFragment on SupplierOrderProduct {
    id
    supplierOrderProductGroup {
      id
      identifier
      description
      packQuantity
      isCombo
      comboProductGroup {
        id
        identifier
        description
      }
      shippingUnitQuantity
      packsPerShippingUnit
      supplierOrderProducts {
        id
        packSize
        packsPerShelf
        shelvesPerRack
        packsPerShippingUnit
        supplierItem {
          id
        }
      }
      supplierOrder {
        id
        orderMethod
      }
    }
  }
`;

interface InitialValuesQueryResponse {
  content: {
    id: SupplierOrderProductId;
    supplierOrderProductGroup: {
      id: SupplierOrderProductGroupId;
      identifier: string;
      description: string;
      packQuantity: number;
      isCombo: boolean;
      comboProductGroup?: {
        id: number;
        identifier: string;
        description: string;
      }
      shippingUnitQuantity: number;
      packsPerShippingUnit: number;
      supplierOrderProducts: Array<{
        id: SupplierOrderProductId;
        packSize: number;
        packsPerShelf: number;
        shelvesPerRack: number;
        packsPerShippingUnit: number;
        supplierItem: {
          id: number;
        };
      }>;
      supplierOrder: {
        id: SupplierOrderId;
        orderMethod: OrderMethod;
      }
    }
  };
}

interface WithInitialValues {
  initialValues: Partial<FormValues>;
  isShown: boolean;
  isCombo: boolean;
}
const withInitialValues = msyncQuery<InitialValuesQueryResponse, OwnProps & StateProps, {}, {}>(QUERY, {
  skip(ownProps) {
    return ownProps.supplierOrderProductId === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        type: 'SupplierOrderProduct',
        id: ownProps.supplierOrderProductId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props({ data, ownProps }) {
    let initialValues = {};
    if (!data.loading && data.content) {
      const supplierOrderProductGroup = data.content.supplierOrderProductGroup;
      const orderMethod = supplierOrderProductGroup.supplierOrder.orderMethod;
      const quantity = orderMethod === OrderMethod.ShippingUnit ? supplierOrderProductGroup.shippingUnitQuantity : supplierOrderProductGroup.packQuantity;
      const supplierOrderProducts = supplierOrderProductGroup.supplierOrderProducts.map(supplierOrderProduct => {
        return {
          ...supplierOrderProduct,
          supplierItemId: supplierOrderProduct.supplierItem.id,
          __typename: undefined, // TODO: Did this to get the form values to submit properly, there has to be a better way of doing this. (AP 9/20/17)
        };
      });
      const sortedSupplierOrderProducts = sortBy(supplierOrderProducts, 'id');
      initialValues = {
        ...supplierOrderProductGroup,
        supplierOrderProducts: sortedSupplierOrderProducts,
        __typename: undefined, // TODO: Did this to get the form values to submit properly, there has to be a better way of doing this. (AP 9/20/17)
        quantity,
      };
    }

    const sopg = data && data.content && data.content.supplierOrderProductGroup;

    return {
      initialValues,
      isShown: ownProps.isShown && !data.loading,
      // comboProductGroupId: sopg && sopg.comboProductGroup ? sopg.comboProductGroup.id : null,
      isCombo: sopg ? sopg.isCombo : false,
    };
  },
});

interface WithMutationProps {
  editSupplierOrderProductGroup(input: EditSupplierOrderProductGroupWithQuantityInput);
}

export const EditSupplierOrderProductGroupWithQuantityMutation = gql`
  mutation editSupplierOrderProductGroupWithQuantity($input: EditSupplierOrderProductGroupWithQuantityInput!) {
    data: editSupplierOrderProductGroupWithQuantity(input: $input) {
      # This is a SupplierOrderProductGroup
      id
      identifier
      supplierOrderProducts { # Need these to determine when to notify of receiving changes
        id
        productDescription
        packQuantity
        supplierItem {
          id
        }
      }
      supplierOrder {
        id
        lastModifiedAt
        receivingStatus
        receivableOrder {
          id
          receivingStatus
          details {
            id
            receivableOrderType
            packQuantity
            shippingUnitQuantity
            packSize
            packsPerShelf
            shelvesPerRack
            packsPerShippingUnit
          }
        }
        # Do not, under any circumstances, ask for the supplierOrderProductGroups
        # Apollo does not like that.
      }
    }
  }
`;

interface CreateSupplierOrderProductInput {
  packSize: number;
  packsPerShelf?: number;
  shelvesPerRack?: number;
  packsPerShippingUnit?: number;
  productDescription?: string;
  supplierItemId: SupplierItemId;
}

interface EditSupplierOrderProductInput {
  id: number;
  packSize?: number;
  packsPerShelf?: number;
  shelvesPerRack?: number;
  packsPerShippingUnit?: number;
  supplierItemId?: SupplierItemId;
}

const withMutation = msyncMutation(EditSupplierOrderProductGroupWithQuantityMutation, {
  props: ({ mutate }): WithMutationProps => {
    return {
      editSupplierOrderProductGroup: (input: EditSupplierOrderProductGroupWithQuantityInput) => {
        return mutate({
          variables: {
            input: {
              ...input,
              packsPerShippingUnit: (input.packsPerShippingUnit as shame as string) === '' ? null : input.packsPerShippingUnit,
            },
          },
        });
      },
    };
  },
});

const connector1 = connect(mapStateToProps);
const connector2 = connect(undefined, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector1> &
  ConnectedProps<typeof connector2> &
  ReduxFormProps &
  WithSupplierItemsProps &
  WithModalProps &
  WithInitialValues;

export default wrapComponent(SupplierItemModal)<OwnProps, CombinedProps>(
  withMutation,
  withSupplierItems({ filterExistingSupplierItems: false }),
  withModalProps,
  connector1,
  withInitialValues,
  reduxForm({
    form: formName,
    enableReinitialize: true,
  }),
  connector2,
);
