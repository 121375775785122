import * as _ from 'lodash';
import { getFormValues, change } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as SchemaTypes from 'schema/transportation-report/types';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import { ImmutableDateRange } from 'shared/types';
import { GenerateReportMutationResponse } from 'shared/types/graphql-types';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const values = getFormValues(Constants.formName)(state) as Constants.FormValues;

    if (_.isNil(values.customerIds)) {
      return;
    }

    const storeDeliveryDateRange = values.includeCustomerSheet && values.beginDate && values.endDate ? { startDate: values.beginDate, endDate: values.endDate } : undefined;
    const mfcArrivalDateRange = values.includeSupplierSheet && values.mfcArrivalBeginDate && values.mfcArrivalEndDate ? { startDate: values.mfcArrivalBeginDate, endDate: values.mfcArrivalEndDate } : undefined;

    dispatch(ReportActions.reportDownloadStarted());
    try {
      const response: { data: GenerateReportMutationResponse } | undefined = await msyncClientMutation<GenerateReportMutationResponse, SchemaTypes.GenerateShippingStatusWarehouseReportInput>({
        mutation: Mutations.generateShippingStatusWarehouseReport,
        variables: {
          reportInput: {
            customerIds: extractSelectedValues(values.customerIds),
            storeDeliveryDateRange,
            mfcArrivalDateRange,
          },
        },
        dispatch,
      });

      if (response) {
        const reportId = response.data.response.id;
        window.location.replace(`/report/fileDownload/${reportId}`);
      }
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};

export const handleMfcArrivalDateRangeChanged = (formName: string, formFields: { [k in Constants.FormFields]: string }) => (dateRange: ImmutableDateRange): Thunker => {
  return async dispatch => {
    dispatch(change(formName, formFields.mfcArrivalBeginDate, dateRange.startDate));
    dispatch(change(formName, formFields.mfcArrivalEndDate, dateRange.endDate));
  };
};
