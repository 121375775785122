import * as React from 'react';
import { Row, Col } from 'client/components/third-party';
import * as classnames from 'classnames';

export interface HorizontalLayoutProps {
    labelComponent?: React.ComponentClass<any>;
    labelColSize: number;
    offset?: number;
    label?: string;
    inputColSize: number;
    children?: React.ReactNode;
    classNames?: string[];
}

export function HorizontalLayout({ labelComponent, labelColSize, offset, label, inputColSize, children, classNames = [] }: HorizontalLayoutProps) {
    const labelClassName = classnames('mfc-form-horizontal-label', ...classNames);
    return (
        <div>
            <Col componentClass={labelComponent} sm={labelColSize} smOffset={offset} className={labelClassName}> { label } </Col>
            <Col className = "mfc-form-input" sm = { inputColSize }> { children } </Col>
        </div>
    );
}

export interface StackedLayoutProps {
    offset: number;
    label?: string;
    inputColSize: number;
    children?: React.ReactNode;
    classNames?: string[];
    blankLabel?: boolean;
    isMfcInput?: boolean;
    right?: boolean;
}

export function StackedLayout({ offset, label, inputColSize, children, classNames = [], blankLabel = false, isMfcInput = true, right = false }: StackedLayoutProps) {
    const labelClassName = classnames( 'mfc-form-stacked-label', ...(blankLabel ? ['mfc-form-stacked-label-empty'] : []), ...classNames);
    const colClassName = classnames({ 'pull-right': right });
    const contentClassName = classnames('stacked', ...(isMfcInput ? ['mfc-form-input'] : []) );
    return (
        <Col sm={inputColSize} className={colClassName} smOffset={offset}>
            <Row>
                <div className={labelClassName}> {label} </div>
            </Row>
            <Row>
                <div className={contentClassName}> {children} </div>
            </Row>
        </Col>
    );
}
