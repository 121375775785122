import gql from 'graphql-tag';

export const generateHmgOpsPoDetailReport = gql`
mutation generateHmgOpsPoDetailReport($reportInput: HmgOpsInput) {
  response: GenerateHmgOpsPoDetailReport(reportInput: $reportInput) {
    id
  }
}
`;

export const generateHmgOpsPoSummaryReport = gql`
mutation generateHmgOpsPoSummaryReport($reportInput: HmgOpsInput) {
  response: GenerateHmgOpsPoSummaryReport(reportInput: $reportInput) {
    id
  }
}
`;

export const generateHmgOpsPicasExport = gql`
mutation generateHmgOpsPicasExport($reportInput: HmgOpsInput) {
  response: GenerateHmgOpsPicasExport(reportInput: $reportInput) {
    id
  }
}
`;
