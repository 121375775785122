import * as _ from 'lodash';
import * as R from 'ramda';
import { camelCaseKeys } from './camel-case-keys';
import { timeout } from './promises';
import { orThrow, orThrowBug } from './or-throw';

export const idFor = <T extends number>(x: { id: T }): T => x.id;
export const idsFor = <T extends number>(x: Array<{ id: T }>): T[] => x.map(y => y.id);
export const asIds = (ids: number[]) => ids.map(id => ({ id }));
export const normalizeNullToUndefined = <T>(x: T | null | undefined): T | undefined => x === null ? undefined : x;
export const indexMap = <T, K>(fn: (elem: T, index: number) => K, elements: T[]): K[] => elements.map(fn);
export const shamePipe = R.pipe as shame;

export const logFunc = _.curry((label: string, x: any) => {
  console.info(label, x);
  return x;
});

// https://gist.github.com/zhangchiqing/2fc6cb718fc9dac12dcb
export const liftP = fn => (...args) => Promise.all(R.slice(0, Infinity, args)).then(R.apply(fn));
export const mapCamelCaseKeys = R.map(camelCaseKeys);
export const firstElement = <T>(x: T[]): T | undefined => x === null ? undefined : x[0];
export const firstElementOrThrowError = <T>(message: string, x: T[]): T => firstElement(x) ?? orThrow(message);

/**
 * Get Funky
 *
 * ![Funky Kong](https://78.media.tumblr.com/39cd3d2648faa6292e36134c40d37ca0/tumblr_ozntl68Ipd1stwfi1o1_250.gif "Funky Kong")
 */
export const firstElementOrFunc = <T>(fn: () => T, x: T[]): T => firstElement(x) ?? fn();
export const notNil = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;
export const notNilOrWhiteSpace = (value: string | null | undefined): value is string => notNilOrEmptyString(value) && value.trim() !== '';
export const notNilOrEmptyString = (value: string | null | undefined): value is string => typeof value === 'string' && value.length > 0;
export const sumBy = <T, K extends keyof T>(x: T[], property: K): number => _.sumBy(x, property);
export const asyncFilter = async <T>(collection: T[], filterFunc: (x: T) => Promise<boolean>): Promise<T[]> => R.zip(collection, await Promise.all(collection.map(filterFunc))).filter(([__, b]) => b).map(([a]) => a);
export const mapProperty = <T, K extends keyof T>(collection: T[], property: K): Array<T[K]> => collection.map(i => i[property]);

// This version was used in the repo core tests. Wasn't worth the time converting them over to the simplier interface, so maintain this version as well.
export const fancyMapProperty = <P extends string>(property: P) => <T extends Array<{ [key in P]: any }>>(collection: T) => mapProperty(collection, property);
export const excludeNils = <T>(values: T[]) => values.filter(v => notNil(v)) as Array<NonNullable<T>>;
export const excludeNilsInProperty = <T, P extends keyof T>(values: T[], property: P): Array<T & { [k in P]: NonNullable<T[P]> }> => values.filter(v => notNil(v[property])) as any /* We can trust the return type. *taps knuckles on desk**/;
export const keys = <T>(o: T): Array<keyof T> => _.keys(o) as shame as Array<keyof T>;

/**
 * Create and await promises, only ever creating a "limit" number of them at once, rather than just doing them all at the same time
 *
 * e.g. return await throttledMap(values, 10, thing => Promise.resolve(thing));
 */
export function throttledMap<T, P>(ary: T[], limit: number, func: (x: T, i: number) => Promise<P>, opts?: { rejectImmediatelyOnFailure: boolean }): Promise<P[]> {
  return new Promise<P[]>((resolve, reject) => {
    if (ary.length === 0)
      return resolve([]);

    const rejectImmediatelyOnFailure = !opts || !(opts.rejectImmediatelyOnFailure === false); // Default is to reject immediately, unless explicity told not to
    let firstError;
    let completedCount = 0;
    let curIndex = Math.min(limit, ary.length);
    const results: P[] = [];
    const queueNext = (myIndex: number) => {
      func(ary[myIndex], myIndex)
        .then((result: P) => {
          results[myIndex] = result;
          completedCount++;

          // Don't queue up any additional work if there's been a failure and going to short-circuit.
          if (firstError && rejectImmediatelyOnFailure)
            return;

          // This is the last one! Resolve (or reject if there was a failure) and get out.
          if (completedCount === ary.length)
            return firstError ? reject(firstError) : resolve(results);

          if (curIndex + 1 <= ary.length)
            return queueNext(curIndex++);
        })
        .catch(err => {
          if (!firstError)
            firstError = err;

          // Unless the caller has explicitly said they DON'T want it to
          // reject immediately, this will stop processing (not queue up any more work)
          // as soon as an error is encountered
          if (rejectImmediatelyOnFailure)
            return reject(firstError);

          completedCount++;

          // This is the last one! Reject and get out
          if (completedCount === ary.length)
            return reject(firstError);

          if (curIndex + 1 <= ary.length)
            return queueNext(curIndex++);
        });
    };

    // Kick off the specified number of promises at once. As each one completes
    // the next one will be created, but never more than the specified limit.
    for (let i = 0; i < Math.min(limit, ary.length); i++)
      queueNext(i);
  });
}

/**
 * Create and await promises, running them "batchSize" at a a time. It will wait
 * for the first batch to complete before doing the next batch, etc.
 *
 * e.g. return await batchedMap(values, 10, thing => Promise.resolve(thing));
 */
export function batchedMap<T, P>(ary: T[], batchSize: number, func: (x: T) => Promise<P>, opts?: { rejectImmediatelyOnFailure: boolean }): Promise<P[]> {
  return new Promise<P[]>(async (resolve, reject) => {
    if (ary.length === 0)
      return resolve([]);

    const rejectImmediatelyOnFailure = !opts || !(opts.rejectImmediatelyOnFailure === false); // Default is to reject immediately, unless explicity told not to
    let firstError;
    const results: P[] = [];
    const chunks = _.chunk(ary, batchSize);
    for (const chunk of chunks) {
      try {
        results.push(...(await Promise.all(chunk.map(val => func(val)))));
      } catch (err) {
        if (rejectImmediatelyOnFailure)
          return reject(err);

        if (!firstError)
          firstError = err;
      }
    }

    if (firstError)
      return reject(firstError);

    resolve(results);
  });
}

/**
 * This function can be used to combine the execution of some async
 * functionality into batches at some specified interval.
 *
 * It was originally written to handle logging many requests that come into the Agent
 * separately, but very quickly. Using this function requests could be batched up (allowing
 * for a DataLoader to turn them into a single DB statement), and they could be executed on
 * a slower interval (since it was just logging there was not harm in there being a short
 * delay before getting into the DB).
 */
export function makeBatchingPromiseFunc<T, P>(opts: { intervalMs: number }, func: (x: T[]) => Promise<P[]>): (x: T) => Promise<P> {
  let unprocessed: Array<{ args: T, resolve: shame, reject: shame }> = [];
  let timerId: NodeJS.Timeout | undefined;
  const waitAndProcess = () => {
    if (notNil(timerId))
      return;

    timerId = setInterval(async () => {
      const thingsToProcess = unprocessed;
      unprocessed = [];

      // This really shouldn't happen, but just in case...
      // (There should only be an interval going if there is work to do. If the
      //  array length is 0 then that wasn't true, so stop the interval and get out.)
      if (thingsToProcess.length === 0) {
        if (notNil(timerId)) {
          clearInterval(timerId);
          timerId = undefined;
        }

        return;
      }

      try {
        const results = await func(thingsToProcess.map(obj => obj.args));
        thingsToProcess.forEach((obj, index) => obj.resolve(results[index]));
      } catch (err) {
        thingsToProcess.forEach(obj => obj.reject(err));
      }

      // If no new work came in while processing the last batch then stop the timer
      // (if there is work it will get picked up in the next interval)
      if (unprocessed.length === 0) {
        if (notNil(timerId)) {
          clearInterval(timerId);
          timerId = undefined;
        }
      }
    }, opts.intervalMs);
  };

  return (x: T) => new Promise<P>(async (resolve, reject) => {
    unprocessed.push({ args: x, resolve, reject });
    waitAndProcess();
  });
}

export const zip2 = <T1, T2>(a1: T1[], a2: T2[]): Array<[T1, T2]> => {
  const results: Array<[T1, T2]> = [];
  for (let i = 0; i < a1.length; i++)
    results.push([a1[i], a2[i]]);

  return results;
};

export const zip2gether = zip2; // andy

// For use in switch statements
// https://stackoverflow.com/a/39419171/4592309
// DVM 2020 05 04 -- good place for new TypeScript 3.9 "throws" annotations
export const assertUnreachable = (x: never): never => orThrowBug(`Didn't expect to get here. Value ${x}`);

/** For use in switch statements. Similar to `assertUnreachable`, but less strict. Provide a default value or function to return. */
export const defaultUnreachable = (x: never, defaultValue: any | (() => any)) => typeof defaultValue === 'function' ? defaultValue() : defaultValue;

// When we do Promise.all([]), typescript automatically infers that the [] is a tuple, not an array,
// and it limits it to only 10 promises. With this function, we can do a Promise.all on an object
// https://github.com/Microsoft/TypeScript/issues/11924#issuecomment-334234973
export async function promiseObject<T>(obj: {[k in keyof T]: Promise<T[k]> | T[k]}): Promise<T> {
  const objectKeys = Object.keys(obj);
  const promises = objectKeys.map(k => (obj as any)[k]);
  const values = await Promise.all(promises);
  return objectKeys.reduce((acc, key, index) => { acc[key] = values[index]; return acc; }, {} as shame);
}

export const trueFn = (): true => true;
export const falseFn = (): false => false;

// https://stackoverflow.com/a/37836669/4592309
export const stripUndefineds = (obj: { [k: string]: any | undefined }): shame => _.pickBy(obj, _.identity);

/** Provide the number of times to retry, how long to wait before trying again, and a function that returns a Promise. */
export const retryAsync = async <T>(timesToRetry: number, delayBetweenRetries: number, fn: () => Promise<T>): Promise<T> => {
  let lastErr: Error | undefined;
  for (let i = 0; i < timesToRetry; i++) {
    if (i !== 0)
      await timeout(delayBetweenRetries);

    try {
      return await fn();
    } catch (err) {
      lastErr = err;
    }
  }

  throw lastErr ?? new Error('Unexpected end of retryAsync'); // Shouldn't be possible (famous last words). Make TypeScript happy.
};

/** Return the first mapped item (using mapFn) that matches the given predicate. */
export const findMapped = <T, S>(items: T[], mapFn: (obj: T) => S, predicate: (obj: S) => boolean): S | undefined => {
  for (const item of items) {
    const mapped = mapFn(item);
    if (predicate(mapped))
      return mapped;
  }

  return undefined;
};
