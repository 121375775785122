import * as React from 'react';
import * as _ from 'lodash';
import { RecordBar } from './record-bar';
import Sidebar from './sidebar';
import { Col } from 'client/components/third-party';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';

const SalesPlanCreatePageCore = () => (
  <div id="mfc-page-content" className="record">
    <RecordBar />
    <div className="mfc-form">
      <div>
        <div className="mfc-form-details-with-sidebar-but-no-tabs">
          <Col sm={12}>
          </Col>
        </div>
        <div className="mfc-form-sidebar">
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
);

const connector = connect(() => ({}));
const component = connector(SalesPlanCreatePageCore);
export const SalesPlanCreatePage = (props: { onSubmit(): void }) => propToComponent(component, props);
