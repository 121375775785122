import FormComponent from 'client/components/crud-forms/merchandiser-form';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import { RecordBarDetailComponent } from './merchandiser-record-bar-detail';
import { Merchandiser } from 'shared/schemas/merchandiser';
import { Saved } from 'shared/schemas/record';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminDetailPage({
  formName: 'MerchandisersDetailsForm',
  table: 'Merchandiser',
  title: 'Supervisor / Coordinator',
  FormComponent,
  RecordBarDetailComponent,
  descriptionResolver: (record: Saved<Merchandiser>) => record.name || '',
  OptionalStatusToggle: EmptyStatusToggle,
});
