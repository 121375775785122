import gql from 'graphql-tag';
import { BolRacksReturned, BolNotes, BolIdentifier, BolId, BolReceivedAt, BolReceivedByInitials, BolAutoCartTrackingEnabled } from 'shared/schemas/bol';
import { UserId } from 'shared/schemas/user';
import { SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { CartTrackingSupplierEntryId } from 'shared/schemas/cart-tracking-supplier-entry';

export interface AddBolVariables {
  identifier: BolIdentifier;
  receivableOrderId: number;
  racksReturned?: BolRacksReturned;
  notes?: BolNotes;
  receivedAt: BolReceivedAt;
}

// TODO: Maybe use a fragment for part of this? The part inside the "bols"?? Or the supplier?
export const AddBolMutation = gql`
  mutation addBol($input: AddBolInput) {
    addBol(input: $input) {
      bolId
      receivableOrder {
        id
        bols {
          id
          identifier
          receivedAt
          receivedByInitials
          racksReturned
          autoCartTrackingEnabled
          user {
            id
            firstName
            lastName
          }
        }
        supplier {
          id
          cartTrackingStatus
          cartTrackingSupplierEntries {
            id
          }
        }
      }
    }
  }
`;

export interface AddBolResponse {
  addBol: {
    bolId: number;
    receivableOrder: {
      id: number;  // ReceivableOrderId
      bols: Array<{
        id: BolId;
        identifier: BolIdentifier;
        receivedAt: shame;
        receivedByInitials: BolReceivedByInitials;
        autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
        user: {
          id: UserId;
          firstName: string;
          lastName: string;
        }
      }>;
      supplier?: {
        id: SupplierId;
        cartTrackingStatus: SupplierCartTrackingStatus;
        cartTrackingSupplierEntries: Array<{
          id: CartTrackingSupplierEntryId;
        }>;
      }
    }
  };
}
