import * as _ from 'lodash';
import { flatten } from 'lodash';
import gql from 'graphql-tag';
import { recordType as getRecordType } from '../../../shared/schemas';
import { ApolloClient } from 'apollo-client';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { expectedErrorReceived } from 'client/actions/error';
import { connect } from 'react-redux';

const DELETE_MUTATION = gql`mutation delete($type: RecordType!, $ids: [Int]!) {
  deletedIds: delete(type: $type, ids: $ids)
}`;

export type DeleteRecordFunction = (ids: number | number[]) => Promise<number[]>;
export interface DeleteContainerProps { performDeletion: DeleteRecordFunction; }
interface OwnProps { refetchTable: () => void, client: ApolloClient<NormalizedCacheObject>, maxRecordsToDelete?: number }
interface DispatchProps { displayMessage(args: { message: string }): void; }
export const withRecordDeletions = (table: string, maxRecordsToDelete?: number) => {
  const recordType = getRecordType(table);
  const withDeletions = msyncMutation<{ deletedIds: number[]; }, OwnProps & DispatchProps, DeleteContainerProps, { ids: number[]; type: string; }>(DELETE_MUTATION, {
    alias: 'withRecordDeletions',
    props: props => ({
      performDeletion: async ids => {
        if (!_.isNil(maxRecordsToDelete) && Array.isArray(ids) && ids.length > maxRecordsToDelete) {
          props.ownProps.displayMessage({ message: `Deleting too many records. This may be harmful to m-sync. Please narrow your selection before deleting records.` });
          return [];
        }

        try {
          const result = await props.mutate({
            variables: { ids: flatten([ids]), type: recordType },
            refetchQueries: ['GetRecordFilterOptions'],
          });

          return ((result || {}).data || {}).deletedIds || [];
        } catch (error) {
          // Error should have been handled by the global error handler, so just log it
          console.info('Problem deleting records', error.message);
          return [];
        } finally {
          await props.ownProps.refetchTable();
        }
      },
    }),
  });

  return _.flowRight( connect(undefined, { displayMessage: expectedErrorReceived }), withDeletions );
};
