export function worksheetRedirectHandler(args: { availableIds: number[], selectedId?: number, requestedId?: number, foundAction: (id: number) => void, notFoundAction: () => void }) {
  if (args.requestedId) {
    // When bol id is specified on the url
    const found: boolean = args.availableIds.some(id => id === args.requestedId);

    if (!found) {
      // When specified id is not in the list
      // Then redirect to main page (when may later redirect to first id in list if it exists)
      args.notFoundAction();
      return;
    }

    // When specified bol is in the bol list, and the ID is different from what was
    // already selected
    if (args.selectedId !== args.requestedId) {
      // Then show newly selected item and update selected item in state
      args.foundAction(args.requestedId);
      return;
    }

    // The new requestedId must be the same as the old selectedId, so no need to do anything here
    return;
  }

  // When id is not specified on the url
  if (args.selectedId) {
    // When a selected product id is in state
    const found: boolean = args.availableIds.some(id => id === args.selectedId); // from state

    if (found) {
      // When selected id from state is in the list
      // Then redirect to the id from the state
      args.foundAction(args.selectedId);
    }
  }

  // When no selected id is in state
  if (args.availableIds.length === 0) {
    // Then do nothing. An "Add" button should show on an empty page.
    return;
  }

  // When the list has items,
  // Then redirect to the first item in the list.
  const firstId = args.availableIds[0];

  args.foundAction(firstId);
}
