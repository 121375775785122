import { strEnum } from './meta';
import * as _ from 'lodash';
import { formatDollarsAndCents, formatNumber, formatMoney } from './money-str';

export const EM_DASH = '—'; // Unicode U+2014, the longest type of dash, used in the UI

// make sure this lines up with React.ReactType
export enum INPUT_TYPES {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  INT = 'int',
  MONEY = 'money',
  PERCENTAGE = 'percentage',
  PASSWORD = 'password',
  EMAIL = 'email',
  TEL = 'tel',
  URL = 'url',
}

export const DISPLAY_TYPES = strEnum([
  'INPUT',
  'DROPDOWN',
  'SELECTABLE',
  'CHECKBOX',
  'RADIO',
  'STATIC',
  'FIELD_ARRAY',
  'DATE',
  'DATE_TIME',
  'STATUS',
  'SELECTION',
  'MENU',
  'YES_NO',
  'YES_NO_NULL',
]);

export type DISPLAY_TYPES = keyof typeof DISPLAY_TYPES; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export function isQueryAsExcelColumn(value: any): value is QueryAsExcelColumn {
  return typeof value.id === 'string'
    && typeof value.header === 'string'
    && _.values(CELL_TYPES).includes(value.cellType)
    && 'type' in value;
}

export function castOrThrow<T>(check: ((x: any) => x is T), value: any): T {
  if (!check(value))
    throw new Error(`Incompatible: failed check ${check.name || check}: ${JSON.stringify(value, null, 2)}`);

  return value;
}

export interface QueryAsExcelColumn {
  id: string;
  accessor: string;
  header: string;
  cellType: CELL_TYPES;
  type: any;
}

export interface AdditionalInfo {
  label: string;
  value: string;
}

export interface QueryAsExcelRequest {
  operationName: string;
  query: any;
  variables: any;
  columns: QueryAsExcelColumn[];
  uniqueKey?: string;
  sortField?: string;
  worksheetName?: string;
  workbookName?: string;
  additionalInformation?: AdditionalInfo[];
  customAccessor?: string;
}

export const CELL_TYPES: {[k: string]: CELL_TYPES} = {
  SPINNER: 'spinner',
  SPINNER_NO_DEBOUNCE: 'spinnerNoDebounce',
  SPINNER_ALLOW_NEGATIVES: 'spinnerAllowNegatives',
  TEXT: INPUT_TYPES.TEXT,
  RIGHT_ALIGNED_TEXT: 'rightAlignedText',
  TEXTAREA: INPUT_TYPES.TEXTAREA,
  NUMBER: INPUT_TYPES.NUMBER,
  INT: INPUT_TYPES.INT,
  CENTERED_NUMBER: 'centeredNumber',
  DASH_ZERO_NUMBER: 'dashZeroNumber',
  DASH_ZERO_NUMBER_OR_UNDEFINED: 'dashZeroNumberOrUndefined',
  MONEY: INPUT_TYPES.MONEY,
  PERCENTAGE: INPUT_TYPES.PERCENTAGE,
  DROPDOWN: 'DROPDOWN',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  STATUS: 'STATUS',
  SELECTION: 'SELECTION',
  MENU: 'MENU',
  YES_NO: 'YES_NO',
  YES_NO_NULL: 'YES_NO_NULL',
  TEL: INPUT_TYPES.TEL,
  EMAIL: INPUT_TYPES.EMAIL,
  URL: INPUT_TYPES.URL,
  RADIO: 'RADIO',
  STATIC: 'STATIC',
  CHECKBOX: 'CHECKBOX',
};
export type CELL_TYPES = 'spinner' | 'spinnerAllowNegatives' | 'spinnerNoDebounce' | DISPLAY_TYPES | INPUT_TYPES | 'dashZeroNumberOrUndefined' | 'centeredNumber' | 'dashZeroNumber' | 'rightAlignedText'; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export const CELL_TYPES_FOOTER_RENDERERS: { [k: string]: CELL_TYPES_FOOTER_RENDERERS }  = {
  [CELL_TYPES.SPINNER]: CELL_TYPES.CENTERED_NUMBER,
  [CELL_TYPES.SPINNER_ALLOW_NEGATIVES]: CELL_TYPES.CENTERED_NUMBER,
  [CELL_TYPES.SPINNER_NO_DEBOUNCE]: CELL_TYPES.CENTERED_NUMBER,
  [CELL_TYPES.DASH_ZERO_NUMBER]: CELL_TYPES.DASH_ZERO_NUMBER,
  [CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED]: CELL_TYPES.DASH_ZERO_NUMBER_OR_UNDEFINED,
  [CELL_TYPES.TEXT]: CELL_TYPES.TEXT,
  [CELL_TYPES.RIGHT_ALIGNED_TEXT]: CELL_TYPES.RIGHT_ALIGNED_TEXT,
  [CELL_TYPES.TEXTAREA]: CELL_TYPES.TEXTAREA,
  [CELL_TYPES.NUMBER]: CELL_TYPES.NUMBER,
  [CELL_TYPES.INT]: CELL_TYPES.INT,
  [CELL_TYPES.MONEY]: CELL_TYPES.MONEY,
  [CELL_TYPES.PERCENTAGE]: CELL_TYPES.PERCENTAGE,
  [CELL_TYPES.DROPDOWN]: CELL_TYPES.TEXT,
  [CELL_TYPES.DATE]: CELL_TYPES.DATE,
  [CELL_TYPES.DATE_TIME]: CELL_TYPES.DATE_TIME,
  [CELL_TYPES.STATUS]: CELL_TYPES.STATUS,
  [CELL_TYPES.SELECTION]: CELL_TYPES.TEXT,
  [CELL_TYPES.MENU]: CELL_TYPES.TEXT,
};
export type CELL_TYPES_FOOTER_RENDERERS = 'spinner' | 'spinnerAllowNegatives' | 'spinnerNoDebounce' | DISPLAY_TYPES | INPUT_TYPES | 'dashZeroNumberOrUndefined' | 'centeredNumber' | 'dashZeroNumber' | 'rightAlignedText'; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export const SORT_TYPES: {[k: string]: SORT_TYPES} = {
  ASC: 'ASC',
  DESC: 'DESC',
};
export type SORT_TYPES = 'ASC' | 'DESC'; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

export function dropdownOptions(optionsEnum?: Dictionary<string>): DropdownOption[] {
  return !optionsEnum ? [] : Object
    .keys(optionsEnum)
    .map(enumKey => ({ id: optionsEnum[enumKey], value: optionsEnum[enumKey] }));
}

export interface FilterSpecificationInput {
  field: string;
  foreignColumn?: string;
  values: any[];
  not?: boolean;
  operator?: FilterOperator;
}

export interface SchemaFilterSpecificationInput<T> extends FilterSpecificationInput {
  field: keyof T & string;
}

export interface NewFilterSpecificationInput extends FilterSpecificationInput {
  operator?: FilterOperator;
}

export enum FilterOperator {
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThan = '<',
  LessThanOrEqual = '<=',
  Between = 'BETWEEN',
}

export interface FilterValues<T> {
  operator?: FilterOperator;
  values: T[];
}

export interface SearchInput {
  text?: string;
  fields?: string[];
}

export const SEARCH_FIELD_ANY = 'SEARCH_FIELD_ANY';
export interface SchemaSearchInput<T> extends SearchInput {
  fields?: Array<(keyof T & string) | typeof SEARCH_FIELD_ANY>;
}

export interface SortInput {
  sortOrder: SORT_TYPES;
  sortField: string;
  foreignColumn?: string;
  opts?: {
    stringAsInt?: boolean;
  };
}

export interface SchemaSortInput<T> extends SortInput {
  sortField: (keyof T & string) | 'updatedAt' | 'createdAt';
}

export interface FilterOption {
  id: string | number;
  value: any;
  _customType?: any;
  displayValue?: string;
}

export interface SchemaFilterOptions<T> extends FilterOptions {
  field: keyof T & string;
  options: FilterOption[];
}

export interface FilterOptions {
  field: string;
  options: FilterOption[];
}

// tslint:disable-next-line: no-empty-interface
export interface FindOptions {
}

export interface FindAllOptions {
  sort?: SortInput[];
  filters?: NewFilterSpecificationInput[];
  search?: SearchInput;
  scope?: NewFilterSpecificationInput[];
  limit?: number;
  offset?: number;
  name?: string;
  distinct?: boolean;
}

export type FindAllUniqueOptions = FindAllOptions;

export function formatInteger(value: number | undefined | null, fallback?: string) {
  return !!value ? formatNumber(value, 0, ',') : fallback || '-';
}

export function formatReal(value: number | undefined | null, fallback?: string, scale: null | Int = null) {
  return !!value ? formatNumber(value, scale, ',') : fallback || '-';
}

export function formatDollarsAndCentsOrNull(value: number | undefined | null, fallback?: string) {
  return !!value ? formatDollarsAndCents(value) : fallback || '-';
}

export function formatMoneyOrNull(value: number | undefined | null, fallback?: string) {
  return !!value ? formatMoney(value) : fallback || '-';
}

export function formatTemperature(value: number | undefined) {
  return !!value ? `${value}°` : '-';
}

export type NavigateTableDirection = 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';
export const NAVIGATE_TABLE_DIRECTIONS: { [k: string]: NavigateTableDirection } = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export interface DropdownOption { id: string; value: string; }
