import * as React from 'react';
import { ProductListItem } from './product-list-item';
import { OrderMethod, DateStr } from 'shared/types';
import * as classnames from 'classnames';
import { ComboDetail } from 'schema/product-worksheet/types';
import { getFormattedProductConfiguration, getHeading } from 'client/app/orders/customer-orders/product-worksheet/product-worksheet-helpers';

export interface Props {
  lastUsed: DateStr | undefined;
  comboDetails: ComboDetail[];
  orderMethod: OrderMethod;
  recentComboSelected: (comboDetails: ComboDetail[]) => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class ComboListItem extends React.Component<Props, State> {
  public render() {
    const { lastUsed, comboDetails, orderMethod, recentComboSelected } = this.props;

    const elements = comboDetails.reduce((result, detail, currentIndex) => {
      const key = `${lastUsed}-${detail.productId}-detail`;
      const className = classnames('product-worksheet-recent-combo-cart-shipment-configuration', {
        'product-worksheet-is-selected': false,
      });

      const heading = getHeading(detail.productIdentifier, detail.productDescription);
      const productConfiguration = getFormattedProductConfiguration(orderMethod, detail, key);

      result.push(
        <div key={`${currentIndex}-${detail.productId}-combo`} className={className}>
          <span>{heading}</span>
          {productConfiguration}
        </div>,
      );
      return result;

    }, [] as  JSX.Element[]);
    const items = [
      {
        id: `${lastUsed}-${comboDetails[0].productId}`,
        elements,
      },
    ];
    return (
      <div className="recent-combo-product-list-item-container">
        <ProductListItem
          header=""
          testId={`combo-modal-product-list-item-${lastUsed}-${comboDetails[0].productId}`}
          items={items}
          isSelected={false}
          onClick={() => recentComboSelected(comboDetails)}
        />
      </div>
    );
  }
}
