import { ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'salesPlanVarietiesExport';
export const reportType = ReportTypes.SalesPlanVarietiesExport;

export enum FormFields {
  salesPlanId = 'salesPlanId',

  scanBased = 'scanBased',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  productIds = 'productIds',
}

export interface FormValues {
  [FormFields.salesPlanId]: number | undefined;

  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
}
