import gql from 'graphql-tag';
import { FilterOption } from 'shared/types';
import * as SalesPlanGraphqlTypes from 'schema/sales-plan/types';
import { extractQueryName } from 'client/utils/query-utils';

export interface SalesPlansQueryResponse {
  getSalesPlans: {
    salesPlans: Array<{
      id: SalesPlanGraphqlTypes.SalesPlanV2['id'];
      year: SalesPlanGraphqlTypes.SalesPlanV2['year'];
      identifier: SalesPlanGraphqlTypes.SalesPlanV2['identifier'];
      customerName: SalesPlanGraphqlTypes.SalesPlanV2['customerName'];
      sellDepartmentIdentifier: SalesPlanGraphqlTypes.SalesPlanV2['sellDepartmentIdentifier'];
      varietyStatus: SalesPlanGraphqlTypes.SalesPlanV2['varietyStatus'];
    }>
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
  getSalesPlansFilterOptions?: {
    years: FilterOption[];
    identifiers: FilterOption[];
    customers: FilterOption[];
    sellDepartments: FilterOption[];
    varietyStatuses: FilterOption[];
  };
}

const SalesPlansListFragment = gql`
  fragment SalesPlansListFragment on SalesPlan {
    id
    year
    identifier
    customerName
    sellDepartmentIdentifier
    varietyStatus
  }
`;

export const GetSalesPlansQuery = gql`
  query GetSalesPlansWithFilterOption($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    getSalesPlans: GetSalesPlans(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      salesPlans {
        ...SalesPlansListFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
    getSalesPlansFilterOptions: GetSalesPlanFilterOptions {
      years {
        id
        value
        displayValue
      }
      identifiers {
        id
        value
        displayValue
      }
      customers {
        id
        value
        displayValue
      }
      sellDepartments {
        id
        value
        displayValue
      }
      varietyStatuses {
        id
        value
        displayValue
      }
    }
  }
  ${SalesPlansListFragment}
`;

export const GetSalesPlansQueryName = extractQueryName(GetSalesPlansQuery);

export const GetSalesPlansExcelQuery = gql`
  query GetSalesPlansExcelQuery($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    GetSalesPlans(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      salesPlans {
        ...SalesPlansListFragment
      }
    }
  }
  ${SalesPlansListFragment}
`;
