import * as _ from 'lodash';
import { getFormValues, change } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as SchemaTypes from 'schema/transportation-report/types';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as Mutations from './mutation';
import * as ReportActions from 'client/app/orders/reports/shared/actions';

export const handleLoadTrackingSortByOptionChanged = (key: string): Thunker => {
  return async dispatch => {
    dispatch(change(Constants.formName, Constants.FormFields.selectedLoadTrackingSortByOption, key));
  };
};

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const values = getFormValues(Constants.formName)(state) as Constants.FormValues;

    if (_.isNil(values.beginDate) || _.isNil(values.endDate) || _.isNil(values.carriers) || values.carriers.length < 1) {
      throw new Error('Invalid carrier or date range provided.');
    }

    dispatch(ReportActions.reportDownloadStarted());
    const response: { data: CommonSchemaTypes.GenerateReportMutationResponse } | undefined = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GenerateLoadTrackingListReportInput>({
      mutation: Mutations.generateLoadTrackingListReport,
      variables: {
        reportInput: {
          carriers: values.carriers,
          beginDate: values.beginDate,
          endDate: values.endDate,
          loadTrackingReportHeader: values.loadTrackingReportHeader,
          selectedLoadTrackingSortByOption: values.selectedLoadTrackingSortByOption,
        },
      },
      dispatch,
    });

    if (!response) {
      dispatch(ReportActions.reportDownloadFinished());
      throw new Error('Unable to generate report');
    }

    const reportId = response.data.response.id;

    window.location.replace(`/report/fileDownload/${reportId}`);
    dispatch(ReportActions.reportDownloadFinished());
  };
};
