import * as _ from 'lodash';
import * as ReactRedux from 'react-redux';
import * as Wrapper from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import { AlertModalUI, UIProps } from './ui';
import { AlertModalSituations } from './types';

interface OwnProps {
  visible: boolean;
  situation: string | undefined;
  alertModalSituations: AlertModalSituations;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {};
};

type Component<P> = new (props: P) => React.Component<P, any>;

type ContainerProps =
  OwnProps &
  StateProps;

assertCompatible<UIProps, ContainerProps>();

const component = _.flowRight(
  ReactRedux.connect(mapStateToProps, undefined),
)(AlertModalUI) as Component<OwnProps>;

export const AlertModalContainer = (props: OwnProps) => Wrapper.propToComponent(component, props);
