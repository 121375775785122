import { push } from 'connected-react-router';
import * as ReduxTypes from 'client/types/redux-types';
import * as Redux from 'redux';

export const newRecordButtonClicked = () => push('/billing/invoicing/create');
export const rowSelected = (row: { id: number }) => push(`/billing/invoicing/details/${row.id}`);

export type ActionTypes =
  | SendInvoicesToAcumaticaMenuItemClickedAction
  | SendInvoicesViaEdiMenuItemClickedAction
  ;

export enum ActionTypeKeys {
  SendInvoicesToAcumaticaMenuItemClickedAction = 'App/SEND_INVOICES_TO_ACUMATICA_MENU_ITEM_CLICKED_ACTION',
  SendInvoicesViaEdiMenuItemClickedAction = 'App/SEND_INVOICES_VIA_EDI_MENU_ITEM_CLICKED_ACTION',
}

export interface SendInvoicesToAcumaticaMenuItemClickedAction extends Redux.Action {
  type: ActionTypeKeys.SendInvoicesToAcumaticaMenuItemClickedAction;
  payload: {
    invoiceIds: number[],
  };
}

export interface SendInvoicesViaEdiMenuItemClickedAction extends Redux.Action {
  type: ActionTypeKeys.SendInvoicesViaEdiMenuItemClickedAction;
  payload: {
    invoiceIds: number[],
  };
}

const sendInvoicesToAcumaticaMenuItemClicked = (invoiceIds: number[]): SendInvoicesToAcumaticaMenuItemClickedAction => {
  return {
    type: ActionTypeKeys.SendInvoicesToAcumaticaMenuItemClickedAction,
    payload: {
      invoiceIds,
    },
  };
};

export const handleSendInvoicesToAcumaticaMenuItemClicked = (invoiceIds: number[]): ReduxTypes.Thunker => {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(sendInvoicesToAcumaticaMenuItemClicked(invoiceIds));
  };
};

const sendInvoicesViaEdiMenuItemClicked = (invoiceIds: number[]): SendInvoicesViaEdiMenuItemClickedAction => {
  return {
    type: ActionTypeKeys.SendInvoicesViaEdiMenuItemClickedAction,
    payload: {
      invoiceIds,
    },
  };
};

export const handleSendInvoicesViaEdiMenuItemClicked = (invoiceIds: number[]): ReduxTypes.Thunker => {
  return async (dispatch: Redux.Dispatch<any>) => {
    dispatch(sendInvoicesViaEdiMenuItemClicked(invoiceIds));
  };
};
