import 'reflect-metadata';
import { tableName, property, definePresentation } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, TYPES } from '../types';
import { IRecord } from 'shared/schemas/record';

@tableName('agentEvents')
export class AgentEvent implements IRecord {
  id?: number;
  @property messageType?: string | null;
  @property successful?: boolean | null;
  @property notes?: string | null;
}

definePresentation(AgentEvent, {
  messageType: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  successful: {
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
  },
  notes: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
});
