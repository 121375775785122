import * as _ from 'lodash';
import { getFormValues } from 'redux-form';

import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  return _.isNil(formValues[Constants.FormFields.endDate])
    || _.isNil(formValues[Constants.FormFields.beginDate])
    || _.isEmpty(formValues[Constants.FormFields.carriers]);
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
