import { AsyncButton } from 'client/components/async-button/async-button-container';
import * as React from 'react';
import { Form, Col, FormGroup } from 'client/components/third-party';
import * as Constants from './constants';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ReportTypes } from 'shared/types';
import * as Components from '../shared/components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { ReportUserParams } from 'client/components/report-user-params';
import { SelectableRow, ColumnAlignment } from 'client/components/selectable/types';
import LabeledSelectable from 'client/components/selectable/labeled-selectable';
import { Field } from 'redux-form';
import { YearContainer } from './sales-plan-years-dropdown';

export interface ComponentProps {
  salesPlanOptions?: SelectableRow[];
  salesPlansLoading?: boolean;

  downloadButtonDisabled: boolean;

  handleDownloadExcelReportClicked(): void;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportDownloadStatus: MutationStatus;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

class Sidebar extends React.PureComponent<shame, {}> {
  render() {
    return (
      <div className="mfc-form-sidebar cart-estimate-report-sidebar">
        <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>

        <div className="sidebar-action-button-container">
          <AsyncButton
            classNames={['report-sidebar-button']}
            disabled={this.props.downloadButtonDisabled}
            label="Download Excel File"
            onClick={this.props.handleDownloadExcelReportClicked}
            testid="download-excel-report-button"
            actionStatus={this.props.excelDownloadStatus}
          />
        </div>

        <div className="mfc-form-stacked-label report-sidebar-heading">Presets</div>

        <ReportUserParams
          reportType={this.props.reportType}
          mapToReportUserParams={this.props.mapToReportUserParams}
          mapFromReportUserParams={this.props.mapFromReportUserParams}
          pristine={this.props.pristine}
          reportFormName={Constants.formName}
          selectedPresetId={this.props.selectedPresetId}
          isLoadingPreset={this.props.isLoadingPreset}
        />
      </div>
    );
  }
}
export class CartEstimateReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar title="Cart Estimate for Sales Plan" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-cart-estimate-report-table-wrapper">
            <Form horizontal>
              <YearContainer
                name={Constants.FormFields.salesPlanYear}
              />
              <SalesPlan
                name={Constants.FormFields.salesPlanIds}
                options={this.props.salesPlanOptions}
                loading={this.props.salesPlansLoading}
              />
            </Form>
          </div>
          <Sidebar
            reportType={ReportTypes.CartEstimateReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}

const optionFormatter = option => option.value;
const emptyArray = [];

export class SalesPlan extends React.PureComponent<Components.LabeledSelectableFieldProps, {}> {
  cols = [
    { alignment: ColumnAlignment.Left, width: 20 },
    { alignment: ColumnAlignment.Left, width: 60 },
    { alignment: ColumnAlignment.Left, width: 20 },
  ];
  render() {
    return (
      <Col sm={12}>
      <FormGroup>
          <Field
            name={this.props.name}
            options={this.props.options || emptyArray}
            handleChange={this.props.handleChange}
            component={LabeledSelectable}
            loading={this.props.loading}
            inputColSize={4}
            label="Sales Plan"
            cols={this.cols}
            labelColSize={4}
            offset={0}
            textFormatter={optionFormatter}
            valueField="value"
            horizontalLabel={false}
            testid="report-sales-plan"
            required
          />
        </FormGroup>
      </Col>
    );
  }
}
