import * as React from 'react';

export interface OwnProps {
  handleSectionUnmounted: () => void;
}

class ProductDetailPage extends React.Component<OwnProps, {}> {
  componentWillUnmount() {
    this.props.handleSectionUnmounted();
  }

  public render() {
    return <>{this.props.children}</>;
  }
}

export default ProductDetailPage;
