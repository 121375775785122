import * as React from 'react';
import AttachSupplierOrdersModal from './attach-supplier-orders-modal-container';
import { SupplierOrdersJustTheTable } from './just-the-table';
import { DateStr } from 'shared/types';
import { ApolloRefetch, AvailableFilter, ActiveSearch, ActiveSort, ActiveFilter } from 'client/types';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { TableParentInfo } from 'client/components/table/table-parent';
import { CustomerOrderId } from 'shared/schemas/customer-order';

interface OwnProps {
  customerOrderId: CustomerOrderId;
  tableName: string;

  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
  searchableFields: shame;
  availableFilters: AvailableFilter[];
  disableCreate?: boolean;

  onNewClicked?(): void;
  performRemove?(id: number[]): void;
  onRowSelect(): void;
  confirmOkToSave: () => Promise<boolean>;

  supplierOrders: Array<{
    id: number;
    identifier: string;
    supplierName: string;
    customerName: string;
    sellDepartmentIdentifier: string;
    mfcAreaIdentifier: string;
    relatedCustomerOrders: number;
    plannedArrivalDate: DateStr;
    pieceQuantity: number;
    totalCost: shame;
  }>;

  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  refetchTable?: ApolloRefetch;
  loadMoreRecords: shame;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  tableParentInfo: TableParentInfo;

  isAttachSupplierOrdersModalShown: boolean;
  hideModal(): void;
}

export default class SupplierOrderSummaryTable extends React.PureComponent<OwnProps, any> {

  public render() {
    // TODO - reselect these to prevent re-render
    const headerMenuItems: any = [{
      label: 'Remove Selected Rows',
      onClick: this.props.performRemove,
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];
    const rowMenuItems: any = [{
      label: 'Remove Row',
      onClick: this.props.performRemove,
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    return (
      <div>
        <SupplierOrdersJustTheTable
          tableName={this.props.tableName}
          loading={this.props.loading}
          supplierOrders={this.props.supplierOrders}
          refetchTable={this.props.refetchTable}
          searchableFields={this.props.searchableFields}
          availableFilters={this.props.availableFilters}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          totalCount={this.props.totalCount}
          loadMoreRecords={this.props.loadMoreRecords}
          filteredRecordIds={this.props.filteredRecordIds}
          onNewClicked={this.props.onNewClicked}
          onRowSelect={this.props.onRowSelect}
          disableCreate={this.props.disableCreate}
          tableParentInfo={this.props.tableParentInfo}
          confirmOkToSave={this.props.confirmOkToSave}
          dataRequest={this.props.dataRequest}
          displayLoadingIndicator={false}
          headerMenuItems={headerMenuItems}
          rowMenuItems={rowMenuItems}
        />

        <AttachSupplierOrdersModal
          customerOrderId={this.props.customerOrderId}
          isShown={this.props.isAttachSupplierOrdersModalShown}
          title={'Attach Supplier POs'}
          onCloseClicked={this.props.hideModal}
          onAddClicked={this.props.hideModal}
          refetchData={this.props.refetchTable}
          confirmOkToSave={this.props.confirmOkToSave}
          dataRequest={this.props.dataRequest}
        />
      </div>
    );
  }
}
