import * as Validators from 'shared/validators';
import { tableName, property, definePresentation, required, defineFieldValidators, hasMany } from 'shared/schemas/dsl';
import { strEnum, DateTimeStr } from 'shared/types';
import { IRecord } from 'shared/schemas/record';
import { OutboundMailAttachment } from 'shared/schemas/outbound-mail-attachment';

export const OutboundMailStatus = strEnum([
  'pending',
  'sent',
  'sentFromTest',
  'failed',
  'skipped',
]);
export type OutboundMailStatus = keyof typeof OutboundMailStatus; // eslint-disable-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type

@tableName('outboundMails')
export class OutboundMail implements IRecord {
  id?: number;

  @property @required subject: string;
  @property @required from: string;
  @property to: string[];
  @property cc?: string[] | null;
  @property bcc?: string[] | null;
  @property text?: string | null;
  @property html?: string | null;

  @property @required status: OutboundMailStatus;
  @property processedAt?: DateTimeStr | null;

  @hasMany('outboundMailAttachments', {foreignDisplayKey: 'filename'})
  @property attachments?: OutboundMailAttachment[];
}

defineFieldValidators(OutboundMail, {
  status: [Validators.INCLUSION(OutboundMailStatus)],
});

definePresentation(OutboundMail, {
  subject: { formDisplay: false, includeInFormQuery: true },
  from: { formDisplay: false, includeInFormQuery: true },
  to: { formDisplay: false, includeInFormQuery: true },
  cc: { formDisplay: false, includeInFormQuery: true },
  bcc: { formDisplay: false, includeInFormQuery: true },
  text: { formDisplay: false, includeInFormQuery: true },
  html: { formDisplay: false, includeInFormQuery: true },
  processedAt: { formDisplay: false, includeInFormQuery: true },
  status: { formDisplay: false, includeInFormQuery: true },
});
