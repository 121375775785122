import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import gql from 'graphql-tag';

export interface Input {
  invoiceId: number;
}

interface QueryInvoice {
  id: InvoiceGraphqlTypes.Invoice['id'];
  identifier: InvoiceGraphqlTypes.Invoice['identifier'];
  invoiceDate: InvoiceGraphqlTypes.Invoice['invoiceDate'];
  totalPrice: InvoiceGraphqlTypes.Invoice['totalPrice'];

  customer: {
    id: number;
    name: string;
    email: string | null;
    billingEmail: string | null;
  };

  supplier?: {
    id: number;
    name: string;

    contacts: Array<{
      id: number;
      name: string;
      email: string;
    }>;
  };
}

export interface QueryResponse {
  getInvoice?: {
    invoice: QueryInvoice;
  };
}

export const Query = gql`
  query GetInvoiceForSendInvoiceModal($invoiceId: Int!) {
    getInvoice: GetInvoice(id: $invoiceId) {
      invoice {
        id
        type
        identifier
        invoiceDate
        totalPrice

        customer {
          id
          name
          email
          billingEmail
        }

        ... on VendorChargebackInvoice {
          id
          supplier {
            id
            name

            contacts {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;
