import * as React from 'react';
import Dropdown from '../dropdown';
import { AvailableFilterOption } from 'client/types';

const FilterDropdown = ({ placeholder, options, selectedOptions, onSelected, testid, multi }: {
  placeholder: string,
  selectedOptions: string[],
  options: AvailableFilterOption[],
  onSelected: (...args: unknown[]) => unknown,
  testid: string,
  multi: boolean,
  type: string,
}) => {
  const filterTextFormatter = option => option.displayValue;
  return (
    <Dropdown
      className="input-group-btn mfc-button-table-dropdown"
      data={options}
      value={selectedOptions}
      placeholder={placeholder}
      onChange={onSelected}
      multi={multi}
      textFormatter={filterTextFormatter}
      valueField="id"
      testid={testid}
      filter
      />);
};

FilterDropdown.defaultProps = {
  options: [],
  multi: false,
};

export default FilterDropdown;
