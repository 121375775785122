import { connect, ConnectedProps } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getFormValues } from 'client/redux-form';
import { wrapComponent } from 'client/hoc/hoc';
import { AddStoreModalUI } from './ui';
import { flowRight, isNil } from 'lodash';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import * as Actions from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal/actions';
import { optionsContainerGenerator, Option, optionsContainerWithFilters, supplyEmptyOptionsIfUndefined } from 'client/hoc/options-container-generator';
import { ActiveFilter } from 'client/types';
import * as State from 'client/state/state';
import { msyncQuery } from 'client/hoc/graphql/query';
import * as Query from './query';
import * as Constants from './constants';
import * as Types from './types';

export interface OwnProps {
  routePlanId: RoutePlanId;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps) => {
  const values = getFormValues<Types.FormValues>(Constants.formName)(state);
  return {
    isShown: state.routePlan.addStoreModalShown,
    createButtonDisabled: isNil(values) || isNil(values.customerId) || isNil(values.storeId),
    customerId: values ? values.customerId : undefined,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  handleCancelButtonClicked: Actions.cancelButtonClicked,
  handleAddStoreButtonClicked: Actions.addStoreButtonClicked,
  handleCustomerChanged: Actions.customerChanged,
};

interface WithCustomerOptions {
  customerOptions: Array<Option & {
    identifier: string;
    name: string;
  }>;
}
const withCustomerOptions = optionsContainerGenerator({ resultPropName: 'customerOptions', table: 'customers', columns: ['identifier', 'name'] });

interface WithStoreOptions {
  storeOptions: Array<Option & {
    identifier: string;
    city: string;
    state: string;
  }>;
}
const storeOptionFilters = (ownProps: StateProps) => {
  const filters: ActiveFilter[] = [];
  if (ownProps.customerId) {
    filters.push({ field: 'customer', values: [ownProps.customerId]});
  }
  return filters;
};

const withStoreOptions = flowRight(
  optionsContainerWithFilters({ resultPropName: 'storeOptions', table: 'stores', columns: ['identifier', 'city', 'state'], getFilters: storeOptionFilters, skip: (ownProps: StateProps) => !ownProps.customerId }),
  supplyEmptyOptionsIfUndefined('storeOptions' as shame),
);

interface WithDefaultCustomerProps {
  initialValues: {
    customerId: number | undefined;
  };
}

const withDefaultCustomer = msyncQuery<Query.DefaultCustomerQueryResponse, OwnProps & StateProps, WithDefaultCustomerProps, Query.DefaultCustomerQueryInput>(Query.DefaultCustomerQuery, {
  alias: 'withDefaultCustomer',
  skip(ownProps: OwnProps & StateProps) {
    return !ownProps.isShown;
  },
  options(ownProps: OwnProps): { variables: Query.DefaultCustomerQueryInput } {
    return {
      variables: {
        routePlanId: ownProps.routePlanId,
      },
    };
  },
  props(props): WithDefaultCustomerProps {
    const { data } = props;
    const customerId = data.getRoutePlan ? data.getRoutePlan.routePlan.customerId : undefined;

    return {
      initialValues: {
        customerId,
      },
    };
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector> &
  WithDefaultCustomerProps &
  WithCustomerOptions &
  WithStoreOptions

export const AddStoreModalContainer = wrapComponent(AddStoreModalUI)<OwnProps, CombinedProps>(
  connector,
  withDefaultCustomer,
  reduxForm({
    form: Constants.formName,
    enableReinitialize: true,
  }),
  withCustomerOptions,
  withStoreOptions,
);
