import * as React                                            from 'react';
import {    FunctionComponent, useState}                     from 'react';
import * as _                                                from 'lodash';
import * as pluralize                                        from 'pluralize';
import {    useDispatch              }                       from 'react-redux';
import {    push                     }                       from 'connected-react-router';
import {    IRecord                  }                       from 'shared/schemas/record';
import {    columns                  }                       from 'shared/schemas';
import {    getTable                 }                       from 'shared/schemas/dsl';
import {    ActiveInactive , Arg1    }                       from 'shared/types';
import {    AvailableSearchField , AvailableFilter, ButtonClickHandler }         from 'client/types';
import      dataContainer                                    from 'client/hoc/data-container';
import {    OnRowSelect              }                       from 'client/components/table/table-ui';
import {    tableParentHoc           }                       from 'client/components/table/table-parent';
import {    clearStore               }                       from 'client/hoc/clear-store';
import {    buildFilterableTable     }                       from 'client/containers/table/table-filter-container';
import {    ActivateRecordFunction   }                       from 'client/hoc/graphql/activate-records';
import {    DeactivateRecordFunction }                       from 'client/hoc/graphql/deactivate-records';
import {    IColumn                  }                       from 'client/components/table/column';
import {    TableParentInfo          }                       from 'client/components/table/table-parent';
import {    MsyncDataRequest         }                       from 'client/hoc/graphql/query';
import {    DeleteRecordFunction     }                       from 'client/hoc/graphql/delete-records';
import      PageTitle                                        from 'client/components/page-title';

export const AdminListPage = (p: {
  schema: string,
  title?: string,
  columns: IColumn[],
  excelColumns?: IColumn[],
  loading?: boolean,
  searchableFields: AvailableSearchField[],
  availableFilters: AvailableFilter[],
  performActivation: ActivateRecordFunction,
  performDeletion: DeleteRecordFunction,
  performDeactivation: DeactivateRecordFunction,
  onRowSelect?: OnRowSelect,
  onNewClicked?: ButtonClickHandler,
  onRowFocused?: (row: Dictionary<any>) => void,
  content: any[],
  refetchTable: () => void,
  tablePageNumber: number,
  totalUnfilteredCount: number,
  totalCount: number,
  filteredRecordIds: number[],
  loadMoreRecords?: () => void,
  tableParentInfo: TableParentInfo,
  dataRequest: MsyncDataRequest,
  hardDeleteRecords: boolean,
  emptyTablePlaceholder?: string,
  newButtonLabel?: string,
  supplementalRecordBarComponent?: React.FunctionComponent,
}) => {
  // guaranteed component-lifecycle-bound memo
  const [FilterableTable] = useState(() => buildFilterableTable(p.schema));
  return (
    <div className = {`mfc-${p.schema}-page`}>
      <div id= "mfc-page-content">
        <PageTitle title={p.title || pluralize(_.startCase(p.schema))}/>
        <FilterableTable
          table={p.schema}
          loading={p.loading}
          columns={p.columns}
          content={p.content}
          refetchTable={p.refetchTable}
          searchableFields={p.searchableFields}
          availableFilters={p.availableFilters}
          placeholder={p.emptyTablePlaceholder || (`There are currently no ${p.title || pluralize(_.lowerCase(p.schema))}.`)}
          newButtonLabel={p.newButtonLabel || `New ${pluralize.singular(p.title || _.startCase(p.schema))}`}
          totalUnfilteredCount={p.totalUnfilteredCount}
          totalCount={p.totalCount}
          loadMoreRecords={p.loadMoreRecords}
          filteredRecordIds={p.filteredRecordIds}
          checkable
          list
          onNewClicked={p.onNewClicked}
          onRowSelect={p.onRowSelect}
          headerMenuItems={[
            { label: `Activate Selected Rows`                                         , uncheckRecordFollowingClick: true, willRemove: false, onClick:                                           p.performActivation  , shouldDisplay: () => !p.hardDeleteRecords && !!columns(p.schema).find(x => x === 'activeStatus') },
            { label: `${p.hardDeleteRecords ? 'Delete' : 'Deactivate'} Selected Rows` , uncheckRecordFollowingClick: true, willRemove: true , onClick: p.hardDeleteRecords ? p.performDeletion : p.performDeactivation, shouldDisplay: () =>  p.hardDeleteRecords || !!columns(p.schema).find(x => x === 'activeStatus') },
          ]}
          rowMenuItems={[
            { label: `Activate Row`                                                   , uncheckRecordFollowingClick: true, willRemove: false, onClick:                                           p.performActivation  , shouldDisplay: (record: any) => !p.hardDeleteRecords && record && record.activeStatus === ActiveInactive.Inactive || false },
            { label: `${p.hardDeleteRecords ? 'Delete' : 'Deactivate'} Row`           , uncheckRecordFollowingClick: true, willRemove: true , onClick: p.hardDeleteRecords ? p.performDeletion : p.performDeactivation, shouldDisplay: (record: any) =>  p.hardDeleteRecords || record && record.activeStatus === ActiveInactive.Active   || false },
          ]}
          tablePaginated
          tableParentInfo={p.tableParentInfo}
          dataRequest={p.dataRequest}
          excelColumns={p.excelColumns}
          disableTableDownload={p.totalCount > 30_000 /*MAX_DOWNLOADABLE_ROWS -- DVM 2021 09 24 - the need for this makes me sad.*/}
          supplementalRecordBarComponent={p.supplementalRecordBarComponent}
        />
      </div>
    </div>
  );
};

export type AdminListPageProps = Arg1<typeof AdminListPage>;

export const buildAdminListPage = <T extends unknown>(schema: Constructor<T>, config?: {
  title?: string,
  columns?: Array<keyof T>,
  excelColumns?: string[];
  filters?: Array<keyof T>,
  onRowSelect?: any,
  hardDeleteRecords?: boolean,
  emptyTablePlaceholder?: string,
  newButtonLabel?: string,
  maxRecordsToDelete?: number,
  supplementalRecordBarComponent?: React.FunctionComponent
}) => {
  const schemaName = getTable(schema) || 'unknown';
  const hardDeleteRecords = config && config.hardDeleteRecords || false;
  const Page = _.flowRight(
    clearStore,
    tableParentHoc(),
    dataContainer({
      hardDeleteRecords,
      table: schemaName,
      columns: config ? config.columns : undefined,
      excelColumns: config ? config.excelColumns : undefined,
      filters: config ? config.filters : undefined,
      maxRecordsToDelete: config ? config.maxRecordsToDelete : undefined,
    }),
  )(AdminListPage) as FunctionComponent<{
    schema: string,
    hardDeleteRecords?: boolean,
    title?: string,
    onNewClicked: ButtonClickHandler,
    onRowSelect: OnRowSelect,
  } & Partial<Arg1<typeof AdminListPage>>>;

  return <P extends {location: {pathname: string}}>(p: P) => {
    const dispatch = useDispatch();
    const matches = `${p.location.pathname}`.match(/(.+)\/(list)?/);
    const listPageRoute = matches && matches[1] || `/admin/${schemaName}`;
    if (!schemaName) throw new Error(`wtf ${schema.name}`);
    return (
      <Page
        emptyTablePlaceholder={config ? config.emptyTablePlaceholder : undefined}
        newButtonLabel={config ? config.newButtonLabel : undefined}
        supplementalRecordBarComponent={config ? config.supplementalRecordBarComponent : undefined}
        schema={schemaName}
        hardDeleteRecords={hardDeleteRecords}
        title={config && config.title ? config.title : undefined}
        onNewClicked={() => dispatch(push(`${listPageRoute}/create`))}
        onRowSelect={(record: IRecord) => dispatch(() => { dispatch(push(`${listPageRoute}/details/${record.id}`)); if (config && config.onRowSelect) config.onRowSelect(dispatch, record.id); })}
      />
    );
  };
};
