import { wrapComponent } from 'client/hoc/hoc';
import { LoadDetailsSidebarUI } from './ui';
import * as ReduxForm from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { SupplierCommitmentFormValues } from './types';
import * as Constants from './constants';
import * as Actions from './actions';
import * as Query from './query';
import { msyncQuery } from 'client/hoc/graphql/query';
import { Options, optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { DateTimeStr, SORT_TYPES } from 'shared/types';

interface OwnProps {
  supplierCommitmentId?: number;
}

interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

interface WithSupplierCommitmentInfo {
  supplierCommitmentInfoLoading?: boolean;
  initialValues?: SupplierCommitmentFormValues;
  lastModifiedAt: DateTimeStr | undefined;
  lastModifiedBy: string | undefined;
}

const withSupplierCommitmentInfo = msyncQuery<Query.QueryResponse, OwnProps, WithSupplierCommitmentInfo, Query.Input>(Query.Query, {
  alias: 'withSupplierCommitmentInfo',
  skip(ownProps) {
    return ownProps.supplierCommitmentId === undefined;
  },
  options(ownProps) {
    if (ownProps.supplierCommitmentId === undefined) {
      throw new Error('Must have a supplier commitment ID');
    }
    return {
      variables: {
        supplierCommitmentId: ownProps.supplierCommitmentId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSupplierCommitmentInfo {
    const { data } = props;
    if (data.loading || data.supplierCommitment === undefined) {
      return {
        supplierCommitmentInfoLoading: true,
        lastModifiedAt: undefined,
        lastModifiedBy: undefined,
      };
    }
    const supplierCommitment = data.supplierCommitment;

    const lastModifiedBy = supplierCommitment.lastModifiedUser
      ? `${supplierCommitment.lastModifiedUser.firstName} ${supplierCommitment.lastModifiedUser.lastName}`
      : undefined;

    return {
      supplierCommitmentInfoLoading: data.loading,
      initialValues: {
        [Constants.FormFields.salesPlanId]: supplierCommitment.salesPlan && supplierCommitment.salesPlan.id || null,
        [Constants.FormFields.supplierId]: supplierCommitment.supplier.id,
      },
      lastModifiedAt: supplierCommitment.lastModifiedAt,
      lastModifiedBy,
    };
  },
});

type ReduxFormProps = ReduxForm.InjectedFormProps<SupplierCommitmentFormValues>;

const WithReduxForm = ReduxForm.reduxForm({
  form: Constants.formName,
  enableReinitialize: true,
  async onSubmit(formValues: SupplierCommitmentFormValues, dispatch, props: OwnProps & WithSaveConfirmationProps & WithSupplierCommitmentInfo): Promise<void> {
    dispatch(Actions.formSubmitted(formValues, props));
  },
});

const mapDispatchToProps = {
  onDownloadMenuItemClicked: Actions.downloadMenuItemClicked,
  onDownloadSupplierSpecSheetMenuItemClicked: Actions.downloadSupplierSpecSheetMenuItemClicked,
  onUpdateSupplierItemCostsClicked: Actions.updateSupplierItemCostsClicked,
};

interface SupplierOptionsProps {
  supplierOptions: Options | undefined;
}

interface SalesPlanOptionsProps {
  salesPlanOptions: Options | undefined;
}

const withSupplierOptions = optionsContainerGenerator({ resultPropName: 'supplierOptions', table: 'suppliers', columns: ['identifier', 'name'] });

const withSalesPlanOptions = optionsContainerWithFilters({
  resultPropName: 'salesPlanOptions',
  table: 'salesPlans',
  columns: ['year', 'customer', 'identifier', 'sellDepartment'],
  getFilters: () => null,
  sort: [
    { sortOrder: SORT_TYPES.DESC, sortField: 'year' },
    { sortOrder: SORT_TYPES.ASC, sortField: 'customer', foreignColumn: 'identifier' },
    { sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
});

const connector = connect(undefined, mapDispatchToProps);

type CombinedProps =
  & OwnProps
  & ConnectedProps<typeof connector>
  & WithSupplierCommitmentInfo
  & SupplierOptionsProps
  & SalesPlanOptionsProps
  & ReduxFormProps;

export const SidebarContainer = wrapComponent(LoadDetailsSidebarUI)<OwnProps, CombinedProps>(
  withSupplierCommitmentInfo,
  withSupplierOptions,
  withSalesPlanOptions,
  connector,
  WithReduxForm,
);
