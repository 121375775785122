// Exceptions with something more complex than a simple text string message seem to get lost when
// passing through GraphQL. We'll be converting the array of errors from above to a JSON string
// to be handled in the client.
export class ErrorWithData extends Error {
  readonly isExpectedError: boolean;
  readonly isErrorWithData: boolean;

  constructor(data: any) {
    super(JSON.stringify(data));
    this.isExpectedError = true;
    this.isErrorWithData = true;
  }

  data = <T>() => {
    return JSON.parse(this.message) as T;
  }
}

export const isErrorWithData = (error: any) => error.isErrorWithData;
