import { Dispatch } from 'redux';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { Action } from 'redux';
import * as OverrideMutations from './mutations';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { Thunker } from 'client/types/redux-types';

export type ActionTypes =
  SubmitPalletsOverrideStartedAction |
  SubmitPalletsOverrideSucceededAction |
  SubmitPalletsOverrideFailedAction |
  SubmitRacksOverrideStartedAction |
  SubmitRacksOverrideSucceededAction |
  SubmitRacksOverrideFailedAction |
  AddStoreButtonClickedAction;

export enum ActionTypeKeys {
  SubmitPalletsOverrideStarted = 'App/SubmitPalletsOverrideStarted',
  SubmitPalletsOverrideSucceeded = 'App/SubmitPalletsOverrideSucceeded',
  SubmitPalletsOverrideFailed = 'App/SubmitPalletsOverrideFailed',
  SubmitRacksOverrideStarted = 'App/SubmitRacksOverrideStarted',
  SubmitRacksOverrideSucceeded = 'App/SubmitRacksOverrideSucceeded',
  SubmitRacksOverrideFailed = 'App/SubmitRacksOverrideFailed',
  AddStoreButtonClicked = 'App/AddStoreButtonClickedAction',
}
export interface SubmitPalletsOverrideStartedAction extends Action {
  type: ActionTypeKeys.SubmitPalletsOverrideStarted;
}
export function submitPalletsOverrideStarted(): SubmitPalletsOverrideStartedAction {
  return {
    type: ActionTypeKeys.SubmitPalletsOverrideStarted,
  };
}

export interface SubmitPalletsOverrideSucceededAction extends Action {
  type: ActionTypeKeys.SubmitPalletsOverrideSucceeded;
}
export function submitPalletsOverrideSucceeded(): SubmitPalletsOverrideSucceededAction {
  return {
    type: ActionTypeKeys.SubmitPalletsOverrideSucceeded,
  };
}

export interface SubmitPalletsOverrideFailedAction extends Action {
  type: ActionTypeKeys.SubmitPalletsOverrideFailed;
}
export function submitPalletsOverrideFailed(): SubmitPalletsOverrideFailedAction {
  return {
    type: ActionTypeKeys.SubmitPalletsOverrideFailed,
  };
}
export const overridePalletsSaved = (routePlanId: RoutePlanId, storeId: number, palletQuantity: number): Thunker => {
  return async dispatch => {
    dispatch(submitPalletsOverrideStarted());
    try {
      await submitPalletsOverride(dispatch, routePlanId, storeId, palletQuantity);
      dispatch(submitPalletsOverrideSucceeded());
    } catch (e) {
      dispatch(submitPalletsOverrideFailed());
    }
  };
};

export interface SubmitRacksOverrideStartedAction extends Action {
  type: ActionTypeKeys.SubmitRacksOverrideStarted;
}
export function submitRacksOverrideStarted(): SubmitRacksOverrideStartedAction {
  return {
    type: ActionTypeKeys.SubmitRacksOverrideStarted,
  };
}

export interface SubmitRacksOverrideSucceededAction extends Action {
  type: ActionTypeKeys.SubmitRacksOverrideSucceeded;
}
export function submitRacksOverrideSucceeded(): SubmitRacksOverrideSucceededAction {
  return {
    type: ActionTypeKeys.SubmitRacksOverrideSucceeded,
  };
}

export interface SubmitRacksOverrideFailedAction extends Action {
  type: ActionTypeKeys.SubmitRacksOverrideFailed;
}
export function submitRacksOverrideFailed(): SubmitRacksOverrideFailedAction {
  return {
    type: ActionTypeKeys.SubmitRacksOverrideFailed,
  };
}
export const overrideRacksSaved = (routePlanId: RoutePlanId, storeId: number, rackQuantity: number): Thunker => {
  return async dispatch => {
    dispatch(submitRacksOverrideStarted());
    try {
      await submitRacksOverride(dispatch, routePlanId, storeId, rackQuantity);
      dispatch(submitRacksOverrideSucceeded());
    } catch (e) {
      dispatch(submitRacksOverrideFailed());
    }
  };
};

async function submitPalletsOverride(dispatch: Dispatch<any>, routePlanId: RoutePlanId, storeId: number, palletQuantity: number) {
  const input: OverrideMutations.PalletInput = {
    routePlanId,
    storeId,
    palletQuantity,
  };
  const result = await msyncClientMutation<OverrideMutations.PalletResponse>({
    mutation: OverrideMutations.PalletMutation,
    variables: input,
    dispatch,
    optimisticResponse: {
      __typename: 'Mutation',
      overridePallets: {
        __typename: 'ReviewStoresOverridePalletsResponse',
        reviewStoresCustomerOrderAllocation: {
          __typename: 'ReviewStoresCustomerOrderAllocations',
          routePlanId,
          storeId,
          isOverriddenPallets: true,
          pallets: palletQuantity,
        },
      },
    },
  });
  return result.data.overridePallets.reviewStoresCustomerOrderAllocation.storeId;
}

async function submitRacksOverride(dispatch: Dispatch<any>, routePlanId: RoutePlanId, storeId: number, rackQuantity: number) {
  const input: OverrideMutations.RackInput = {
    routePlanId,
    storeId,
    rackQuantity,
  };
  const result = await msyncClientMutation<OverrideMutations.RackResponse>({
    mutation: OverrideMutations.RackMutation,
    variables: input,
    dispatch,
  });
  return result.data.overrideRacks.reviewStoresCustomerOrderAllocation.storeId;
}

export interface AddStoreButtonClickedAction extends Action {
  type: ActionTypeKeys.AddStoreButtonClicked;
}
export function addStoreButtonClicked(): AddStoreButtonClickedAction {
  return {
    type: ActionTypeKeys.AddStoreButtonClicked,
  };
}

async function submitStoresToRemoveFromRoutePlan(dispatch: Dispatch<any>, routePlanId: RoutePlanId, storeIds: number[]) {
  const input: OverrideMutations.RemoveStoresInput = {
    routePlanId,
    storeIds,
  };
  const result = await msyncClientMutation<OverrideMutations.RemoveStoresResponse>({
    mutation: OverrideMutations.RemoveStoresMutation,
    variables: input,
    refetchQueries: ['GetReviewStoresCustomerOrderAllocations'],
    showBusyModal: true,
    dispatch,
  });
  return result.data.removeStores.routePlanId;
}

export const removeStoresFromRoutePlan = (routePlanId: RoutePlanId, storeIds: number[]): Thunker => {
  return async dispatch => {
    await submitStoresToRemoveFromRoutePlan(dispatch, routePlanId, storeIds);
  };
};
