import * as React from 'react';
import * as classNames from 'classnames';
import { BolId, BolIdentifier } from 'shared/schemas/bol';
import { DateTimeStr } from 'shared/types';
import { formatDateTime } from 'shared/helpers/date-helpers';

export interface BolListItemData {
  id: BolId;
  identifier: BolIdentifier;
  user: string;
  receivedAt: DateTimeStr;
  autoCartTrackingDisabled?: boolean;
}

export interface BolListItemProps {
  bol: BolListItemData;
  isSelected: boolean;
  onBolClicked(bolId: number): void;
  onPencilClicked(): void;
  onTrashCanClicked(): void;
}

export const BolListItem = (props: BolListItemProps) => {
  const labelClassName = classNames('product-worksheet-products-list-product-label', {
    'product-worksheet-is-selected': props.isSelected,
  });

  const handlePencilClicked = () => {
    props.onPencilClicked();
  };

  const handleTrashCanClicked = () => {
    props.onTrashCanClicked();
  };

  const cartTrackingDisabledMessage = props.bol.autoCartTrackingDisabled ? ' - Auto Cart Tracking Disabled' : '';
  const title = `BOL ${props.bol.identifier} - ${formatDateTime(props.bol.receivedAt, 'MM/DD/YYYY')}`;

  return (
    <li className="bol-list-item" key={props.bol.identifier} data-testid={`bol-list-item-${props.bol.identifier}`}>
      <div>
        <span onClick={() => props.onBolClicked(props.bol.id)} className={labelClassName}>
          {title}
          <div className="bol-auto-cart-tracking-disabled-warning">{cartTrackingDisabledMessage}</div>
        </span>

        <div className="bol-list-item-detail">
          <div className="bol-list-item-detail-label">
            {props.bol.user} @ {formatDateTime(props.bol.receivedAt, 'hh:mm A z')}
          </div>
          <div data-testid="edit" className="bol-list-item-icon mfc-simple-icon-button" onClick={handlePencilClicked}><span className="fa fa-pencil" /></div>
          <div data-testid="remove" className="bol-list-item-icon mfc-simple-icon-button" onClick={handleTrashCanClicked}><span className="fa fa-trash" /></div>
        </div>
      </div>
    </li>
  );
};
