import { propToComponent } from 'client/hoc/hoc';
import * as _ from 'lodash';
import * as Actions from './actions';
import * as GraphQL from 'client/hoc/graphql/query';
import * as Query from './query';
import * as ReactRedux from 'react-redux';
import * as SendEmailModalTypes from 'client/components/send-email-modal/types';
import * as SharedTypes from 'shared/types';
import * as State from 'client/state/state';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';

interface OwnProps {
  invoiceId: number;
}

const withInvoiceInfo = GraphQL.msyncQuery<Query.QueryResponse, OwnProps, QueryProps, Query.Input>(Query.Query, {
  alias: 'withInvoiceInfo',
  skip(ownProps) {
    return ownProps.invoiceId === undefined;
  },
  options(ownProps) {
    if (ownProps.invoiceId === undefined) {
      throw new Error('Must have an invoice ID');
    }
    return {
      variables: {
        invoiceId: ownProps.invoiceId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): QueryProps {
    const { data } = props;

    if (data.loading || data.getInvoice === undefined) {
      return {
        loading: true,
      };
    }

    const recipient = data.getInvoice.invoice.supplier
      ? data.getInvoice.invoice.supplier.name
      : data.getInvoice.invoice.customer.name;

    const supplierRecipients = data.getInvoice.invoice.supplier
      ? data.getInvoice.invoice.supplier.contacts.map(contact => ({
          name: contact.name,
          emailAddress: contact.email,
        }))
      : [];

    const customerRecipients = [
      {
        name: `${data.getInvoice.invoice.customer.name} - Primary ${data.getInvoice.invoice.customer.email}`,
        emailAddress: data.getInvoice.invoice.customer.email,
      },
      {
        name: `${data.getInvoice.invoice.customer.name} - Accounts Payable ${data.getInvoice.invoice.customer.billingEmail}`,
        emailAddress: data.getInvoice.invoice.customer.billingEmail,
      },
    ];

    const recipients = [
      ...customerRecipients,
      ...supplierRecipients,
    ].filter(r => r.emailAddress) as shame;

    return {
      loading: false,
      invoiceNumber: data.getInvoice.invoice.identifier,
      recipients,
      recipient,
      invoiceDate: data.getInvoice.invoice.invoiceDate,
      invoiceAmount: data.getInvoice.invoice.totalPrice,
    };
  },
});

type DispatchProps = {
  sendButtonClicked(invoiceId: number, recipients: SendEmailModalTypes.EmailRecipient[]): void;
  cancelButtonClicked(): void;
};

const mapDispatchToProps: DispatchProps = {
  sendButtonClicked: Actions.onSendButtonClicked,
  cancelButtonClicked: Actions.onEmailInvoiceModalCloseClicked,
};

interface StateProps {
  sendButtonDisabled: boolean;
  isShown: boolean;
}

const mapStateToProps = (state: State.Type, props: OwnProps): StateProps => {
  const isShown = State.isEmailInvoiceModalShown.get(state);
  return {
    sendButtonDisabled: false,
    isShown,
  };
};

type QueryProps = {
  loading: true;
} | {
  loading: false;
  invoiceNumber: string;
  recipients: SendEmailModalTypes.EmailRecipient[];
  recipient: string;
  invoiceDate: SharedTypes.DateStr;
  invoiceAmount: number;
};

export type ComponentProps =
  & OwnProps
  & StateProps
  & QueryProps
  & DispatchProps;

export const component = _.flowRight(
  withInvoiceInfo,
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(UI.SendInvoiceModalUI);

assertCompatible<UI.OwnProps, ComponentProps>();

export const SendInvoiceModalContainer = (props: OwnProps) => propToComponent(component, props);
