import * as React from 'react';
import * as DateRangeYearComparison from 'client/components/date-range-year-comparison';
import { Form, Col, Row, FormGroup, Tabs, Tab } from 'client/components/third-party';
import * as Constants from './constants';
import { SelectableRow } from 'client/components/selectable/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ReportTypes } from 'shared/types';
import * as Components from '../components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { DropDownOptions } from 'client/types';

export interface ComponentProps {
  handleDownloadExcelReportClicked(): void;
  reportDownloadStatus: MutationStatus;
  downloadButtonDisabled: boolean;

  customerOptions: DropDownOptions;
  supplierOptions: DropDownOptions;
  sellDepartmentOptions: DropDownOptions;
  scanBased: boolean;
  poBased: boolean;

  productClassOptions?: SelectableRow[];
  productSubClassOptions?: SelectableRow[];
  supplierItemOptions?: SelectableRow[];

  productClassesLoading?: boolean;
  productSubClassesLoading?: boolean;
  supplierItemsLoading?: boolean;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  selectedPresetId?: number;
  isLoadingPreset: boolean;

  pristine: boolean;

  handleDateRangesChanged(state: DateRangeYearComparisonValue): void;
  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
}

export class SupplierPerformanceReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar title="Supplier Performance Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs planning-reports-supplier-performance-table-wrapper  mfc-scrolling-region-adjacent-to-sidebar">
            <Form horizontal>
              <Row className="report-fields-outside-tabs">
                <Col sm={12}>
                  <FormGroup>
                    <Components.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Components.Supplier
                      name={Constants.FormFields.supplierId}
                      options={this.props.supplierOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="report-fields-outside-tabs">
                <Col sm={6}>
                  <FormGroup>
                    <DateRangeYearComparison.DateRangeYearComparison
                      handleChange={this.props.handleDateRangesChanged}
                      testid="date-range-comparison-component"
                      value={this.props.dateRangeYearComparison}
                      lastYearRequired
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <Tabs className="report-tabs" id="product-tabs">
                    <Tab disabled={true} title="Products">
                      <FormGroup>
                        <Components.SellDepartment
                          name={Constants.FormFields.sellDepartmentId}
                          options={this.props.sellDepartmentOptions}
                        />
                      </FormGroup>
                      <FormGroup className="report-product-order-type-area">
                        <label className="report-scan-or-po-based-label">Product Order Type</label>
                        <div>
                          <Components.ScanBased
                            name={Constants.FormFields.scanBased}
                          />
                          <Components.PoBased
                            name={Constants.FormFields.poBased}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Components.ProductClass
                          name={Constants.FormFields.productClassIds}
                          options={this.props.productClassOptions}
                          loading={this.props.productClassesLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Components.ProductSubClass
                          name={Constants.FormFields.productSubClassIds}
                          options={this.props.productSubClassOptions}
                          loading={this.props.productSubClassesLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Components.Product
                          name={Constants.FormFields.supplierItemIds}
                          options={this.props.supplierItemOptions}
                          loading={this.props.supplierItemsLoading}
                        />
                      </FormGroup>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Form>
          </div>
          <Components.Sidebar
            reportType={ReportTypes.SupplierPerformanceReport}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div >
    );
  }
}
