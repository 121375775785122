import * as React from 'react';
import { Button, FormControl } from 'client/components/third-party';
import { isNil } from 'lodash';
import * as classnames from 'classnames';

interface SpinnerCellTypes {
  focused: boolean;
  editing: boolean;
  value: number;
  onChange: (newValue: any, shouldSave?: boolean, shouldDebounce?: boolean) => void;
  allowNegatives?: boolean;
  testid: string;
  error?: string;
  onArrowKeyUp: () => void;
  onArrowKeyDown: () => void;
  debounceIncrementDecrements: boolean;
}

const handleFocus = event => {
  const target = event.target; // The synthetic event will be reused, so need to close over the target itself.

  // Select the contents of the input on focus.
  target.select();
};

const handleDoubleClick = event => {
  event.stopPropagation();
};

export class SpinnerCell extends React.Component<SpinnerCellTypes, any> {

  handlePlusClick = event => {
    this.props.onChange((Number.isFinite(this.props.value) ? this.props.value : 0) + 1, true, this.props.debounceIncrementDecrements);
  }

  handleMinusClick = event => {
    if (this.props.value > 0 || this.props.allowNegatives) {
      this.props.onChange((Number.isFinite(this.props.value) ? this.props.value : 0) - 1, true, this.props.debounceIncrementDecrements);
    }
  }

  handleChange = event => {
    const value = event.target.value;
    const parsedValue = parseInt(value);

    if (!(/^(\+|-)?\d*$/).test(value)) {
      return;
    }

    if (value === '') {
      return this.props.onChange(value);
    }

    if (this.props.allowNegatives && value === '-') {
      return this.props.onChange(value);
    }

    if (Number.isFinite(parsedValue) && (parsedValue >= 0 || this.props.allowNegatives)) {
      return this.props.onChange(parsedValue);
    }
  }

  handleKeyDown = event => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.props.onArrowKeyUp();
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.props.onArrowKeyDown();
    }
  }

  render() {
    const { value, testid, editing } = this.props;

    let displayValue: string;

    if (isNil(value) || value.toString() === '0') {
      displayValue = editing ? '0' : '-';
    } else {
      displayValue = value.toString();
    }

    const className = classnames('spinner-container');

    return (
      <div className={className}>
        <div className="spinner-button-container">
          <Button className="spinner-button" onClick={this.handleMinusClick} onDoubleClick={handleDoubleClick}>
            <span data-testid={`spinner-decrement-${testid}`}>-</span>
          </Button>
        </div>

        <div className="spinner-input">
          {editing && <FormControl
            className="numeric-spinner-input"
            value={displayValue}
            onChange={this.handleChange}
            onFocus={handleFocus}
            autoFocus
            onKeyDown={this.handleKeyDown}
          /> }
          {!editing &&
            <div className="spinner-text-wrapper" data-testid={`spinner-text-${testid}`}>
              <span className="">{displayValue}</span>
            </div>
          }
        </div>
        <div className="spinner-button-container">
          <Button className="spinner-button" onClick={this.handlePlusClick} onDoubleClick={handleDoubleClick}>
            <span data-testid={`spinner-increment-${testid}`}>+</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default SpinnerCell;
