import { DateStr, ReportTypes, ReplenishmentByProductGroupingOptions } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { SelectableValue } from 'client/components/selectable/selectable';

export const formName = 'replenishmentByProductReport';
export const reportType = ReportTypes.ReplenishmentByProduct;

export enum FormFields {
  scanBased = 'scanBased',
  poBased = 'poBased',
  customerId = 'customerId',
  sellDepartmentId = 'sellDepartmentId',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  beginDate = 'beginDate',
  endDate = 'endDate',
  pieceTargetBeginDate = 'pieceTargetBeginDate',
  pieceTargetEndDate = 'pieceTargetEndDate',
  productIds = 'productIds',
  mfcAreaIds = 'mfcAreaIds',
  salesPlanId = 'salesPlanId',
  salesPlanForPieceTargetId = 'salesPlanForPieceTargetId',
  primaryGlobalAll = 'primaryGlobalAll',
  filterByCategoryOrSalesPlan = 'filterByCategoryOrSalesPlan',
  pieceTargetPrimaryStoresOnly = 'pieceTargetPrimaryStoresOnly',
  groupBy = 'groupBy',
}

export interface FormValues {
  [FormFields.customerId]: number | undefined;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.salesPlanId]: number | undefined;
  [FormFields.salesPlanForPieceTargetId]: number | undefined;
  [FormFields.beginDate]: DateStr | undefined;
  [FormFields.endDate]: DateStr | undefined;
  [FormFields.pieceTargetBeginDate]: DateStr | undefined;
  [FormFields.pieceTargetEndDate]: DateStr | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.primaryGlobalAll]: PrimaryGlobalAll;
  [FormFields.filterByCategoryOrSalesPlan]: FilterByCategoryOrSalesPlan;
  [FormFields.pieceTargetPrimaryStoresOnly]: boolean | undefined;
  [FormFields.groupBy]: ReplenishmentByProductGroupingOptions;
}

export enum FilterByCategoryOrSalesPlan {
  ByCategory = 'ByCategory',
  BySalesPlan = 'BySalesPlan',
}
