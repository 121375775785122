import { appendFieldValidators } from 'shared/schemas/dsl';
import { CustomerOrderProduct } from 'shared/schemas/customer-order-product';
import * as Validators from 'shared/validators';

appendFieldValidators(CustomerOrderProduct, {
  packsPerShippingUnit: [Validators.VALID_PPS_SPR_CALCULATION, Validators.GREATER_THAN_ZERO],
  packSize: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO], // this should be > 0, but to support VBills component/combos, we need to allow it to be 0
  shelvesPerRack: [Validators.GREATER_THAN_ZERO],
  packsPerShelf: [Validators.GREATER_THAN_ZERO],
});
