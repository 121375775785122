import * as classNames from 'classnames';
import * as React from 'react';
import * as Moment from 'moment-timezone';
import { DateStr } from 'shared/types';
import * as $ from 'jquery';
import { formatDate, parseDate, formatDateStrForDisplay } from 'shared/helpers/date-helpers';
import { normalizeNullToUndefined } from 'shared/helpers/andys-little-helpers';
import { BSDatePicker } from 'client/components/third-party';

interface Props {
  name: string;
  onChange: (value: DateStr | undefined) => void;
  value?: DateStr | null;
  disabled?: boolean | null;
  placeholder?: string | null;
  testid?: string | null;
  disabledDates?: DateStr[];
  autoFocus?: boolean;
}

export class DatePicker extends React.PureComponent<Props, any> {
  private datePickerJqueryResult: BSDatePicker;
  private getDateInput(): HTMLInputElement { return this.datePickerJqueryResult && this.datePickerJqueryResult[0] as HTMLInputElement; }
  private getDateText() { return this.getDateInput() && this.getDateInput().value || ''; }
  private isRangeEmpty() { return this.getDateText() === ''; }
  private receivingProps = true; // used to prevent feedback loops when copying new props to jQuery state
  private filterDateRoot?: HTMLDivElement = undefined;
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  // send new jQuery value to parents; do not send value to jQuery, jQuery gets that directly from DOM events
  private setDate() {
    if (this.receivingProps) {
      return;
    }

    const value = this.getDateText();

    if (value === '') {
      this.props.onChange(undefined);
    }

    if (!validDate(value)) {
      return;
    }

    const selection = parseDate(value, 'MM/DD/YYYY');
    if (this.props.value !== selection) {
      this.props.onChange(selection);
    }
  }

  componentDidMount() {
    this.datePickerJqueryResult = $('input', this.filterDateRoot);

    // http://bootstrap-datepicker.readthedocs.io/en/latest/
    this.datePickerJqueryResult
      .datepicker({
        assumeNearbyYear: true,
        calendarWeeks: false,
        updateViewDate: true,
        datesDisabled: (this.props.disabledDates || []).map(formatDateStrForDisplay) as string[],
        templates: {
          leftArrow: '<i class="fa fa-chevron-left"></i>',
          rightArrow: '<i class="fa fa-chevron-right"></i>',
        },
        autoclose: true,
      })
      .on('changeDate', () => this.setDate());

    if (this.props.value) {
      this.datePickerJqueryResult.datepicker('setDate', formatDate(this.props.value, 'MM/DD/YYYY'));
    }

    this.receivingProps = false;

    if (this.props.autoFocus) {
      // wait until the modal finishes its animation
      setTimeout(() => {
        if (this.inputRef && this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }, 500);
    }
  }

  render() {
    // copy new props into jQuery
    if (this.datePickerJqueryResult) {
      this.receivingProps = true;
      if (this.props.value) {
        this.datePickerJqueryResult.datepicker('setDate', formatDate(this.props.value, 'MM/DD/YYYY'));
      } else {
        this.getDateInput().value = '';
        this.datePickerJqueryResult.datepicker('clearDates');
      }

      this.receivingProps = false;
    }

    const invalid = !this.isRangeEmpty() && !validDate(this.getDateText());
    return (
      <div ref={e => this.filterDateRoot = normalizeNullToUndefined(e)} className={classNames('input-group date', { disabled: this.props.disabled ?? false })}>
        <div className="date-icon">
          <i className="fa fa-calendar"></i>
        </div>
        <input
          id={this.props.name}
          name={this.props.name}
          type="text"
          data-testid={this.props.testid}
          placeholder={this.props.placeholder || 'mm/dd/yyyy'}
          className={classNames('form-control', 'date-input', { error: invalid })}
          disabled={this.props.disabled ?? false}
          autoComplete="off"
          onFocus={e => (e.target as HTMLInputElement).select()}
          onBlur={() => this.setDate()}
          ref={this.inputRef}
        />
      </div>
    );
  }
}

function validDate(dateText: string) {
  // OK to use Moment here because it's just validating, not returning anything
  const date = Moment(dateText, 'MM/DD/YYYY');
  return dateText.trim().length > 4
    && dateText.trim().length < 11
    && date.isValid()
    && date.year() > 2000;
}
