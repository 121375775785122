import { Rows } from './rows';
import { SelectableRow, SelectableColumn } from './types';
import * as React from 'react';

export interface UncheckedRowsProps {
  cols: SelectableColumn[];
  highlightIndex: number;
  onChecked: (row: SelectableRow) => void;
  onMouseOver: (row: SelectableRow) => void;
  rows: SelectableRow[];
  rowsRef?: (element: HTMLDivElement) => void;
  searchText: string;
  width?: number;
}

export const UncheckedRows = (props: UncheckedRowsProps) => {
  return (
    <Rows
      cols={props.cols}
      highlightIndex={props.highlightIndex}
      onChecked={props.onChecked}
      onMouseOver={props.onMouseOver}
      rows={props.rows}
      rowsRef={props.rowsRef}
      checked={false}
      width={props.width}
    />
  );
};
