import * as React from 'react';
import * as _ from 'lodash';
import { ProductShipmentConfiguration } from 'client/types/product-worksheet';
import { ProductListItem } from './product-list-item';
import { CustomerOrderProductGroupId } from 'shared/schemas/customer-order-product-group';

export interface Props {
  product: ProductShipmentConfiguration;
  isSelected: boolean;
  isChecked: boolean;
  handleProductClicked: (productId: number) => void;
  handleProductChecked: (productId: number) => void;
  handlePencilClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void;
  handleTrashCanClicked: (customerOrderProductGroupId: CustomerOrderProductGroupId) => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export class CartProductListItem extends React.Component<Props, State> {
  public render() {
    const { product, handleProductClicked, isSelected, handleProductChecked, isChecked, handlePencilClicked, handleTrashCanClicked} = this.props;
    const items = product.shipmentConfigurations.map(shipmentConfiguration => {
      if (shipmentConfiguration.shippingMethod === 'Rack') {
        const ppsText = shipmentConfiguration.packsPerShelf === null
          ? ''
          : ` / ${_.round(shipmentConfiguration.packsPerShelf, 2)} PPS`;
        const sprText = shipmentConfiguration.shelvesPerRack === null
          ? ''
          : ` / ${shipmentConfiguration.shelvesPerRack} SPR`;
        return {
          id: shipmentConfiguration.customerOrderProductGroupId,
          elements: [
            <span key={shipmentConfiguration.identifier}>{`${shipmentConfiguration.identifier} - ${shipmentConfiguration.packSize} Pack ${shipmentConfiguration.packsPerShippingUnit ? `/ ${shipmentConfiguration.packsPerShippingUnit} PPR` : ''}${sprText}${ppsText}`.trim()}</span>,
          ],
        };
      } else {
        return {
          id: shipmentConfiguration.customerOrderProductGroupId,
          elements: [
            <span key={shipmentConfiguration.identifier}>{`${shipmentConfiguration.identifier} - ${shipmentConfiguration.packSize} Pack`}{shipmentConfiguration.packsPerShippingUnit && ` / ${shipmentConfiguration.packsPerShippingUnit} PPP`}</span>,
          ],
        };
      }
    });
    return (
      <ProductListItem
        header={`${product.identifier} - ${product.description}`}
        testId={`product-list-item-${product.productId}`}
        items={items}
        isSelected={isSelected}
        isChecked={isChecked}
        handleProductClicked={() => handleProductClicked(product.productId)}
        handleCheckboxChecked={() => handleProductChecked(product.productId)}
        handlePencilClicked={handlePencilClicked}
        handleTrashCanClicked={handleTrashCanClicked}
      />
    );
  }
}
