import { buildAdminDetailPage } from '../admin/admin-detail-page';
import { FormComponent } from '../../components/crud-forms/product/product-spec-form-group';
import navBuilder from './nav-items';
import { ProductRecordBar } from './product-record-bar';

export default buildAdminDetailPage({
  table: 'Product',
  formName: 'ProductSpecForm',
  FormComponent,
  navBuilder,
  descriptionResolver: product => `${product.identifier} - ${product.description}`,
  RecordBarDetailComponent: ProductRecordBar,
});
