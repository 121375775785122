import * as Constants from './constants';
import * as Excel from '../common';
import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Types from './types';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

type ProductTossSheetWorkBook = XLSX.WorkBook;
type ProductTossSheetWorkSheet = XLSX.WorkBook;

enum ValidProductTossSheetWorkSheetDataType { }
type ProductTossSheetWorkSheetData = Excel.WorkSheetData & ValidProductTossSheetWorkSheetDataType;

export async function getProductTossSheetWorkBookFromWorkBookContainer(workBookContainer: ExcelCommon.WorkBookContainer): Promise<ProductTossSheetWorkBook | null> {
  return Excel.getWorkBookFromWorkBookContainer<ProductTossSheetWorkBook>(workBookContainer);
}

export const getProductTossSheetWorkSheet = (workBook: ProductTossSheetWorkBook) => Excel.getFirstWorkSheet<ProductTossSheetWorkSheet>(workBook);
export const getProductTossSheetWorkSheetData = (workSheet: ProductTossSheetWorkSheet) => Excel.getWorkSheetData<ProductTossSheetWorkSheetData>(workSheet);

export function getHeaderData(workSheetData: ProductTossSheetWorkSheetData): Types.ImportableProductTossSheetHeader {
  const customerIdentifierRow = workSheetData[Constants.CUSTOMER_IDENTIFIER_ROW - 1];
  const weekEndingDateRow = workSheetData[Constants.WEEK_ENDING_DATE_ROW - 1];

  const customerIdentifier = Excel.getAsString(customerIdentifierRow[Constants.CUSTOMER_IDENTIFIER_COL]);
  const weekEndingDate = Excel.getAsDateStr(weekEndingDateRow[Constants.WEEK_ENDING_DATE_COL]);

  return {
    customerIdentifier,
    weekEndingDate,
  };
}

const findFirstProductTossRecordRowIndex = () => (Constants.FIRST_TOSS_RECORD_ROW - 1);

function findLastProductTossRecordRow(workSheetData: ProductTossSheetWorkSheetData) {
  if (workSheetData.length === 0) {
    throw new Error(`Unable to find last toss row: the spreadsheet appears empty.`);
  }

  return workSheetData.length;
}

function findProductTossRecordColLetters(workSheetData: ProductTossSheetWorkSheetData) {
  if (workSheetData.length === 0) {
    throw new Error(`Unable to find last toss row: the spreadsheet appears empty.`);
  }

  const productSkuRow = workSheetData[Constants.PRODUCT_SKU_ROW - 1];
  const productDescriptionRow = workSheetData[Constants.PRODUCT_DESCRIPTION_ROW - 1];
  const columnLetters = Object.keys(productSkuRow);

  const productCols = columnLetters
    .filter(letter => !['A', 'B', 'C'].includes(letter));

  const columns = productCols
    .map(colLetter => ({ colLetter, productSku: productSkuRow[colLetter], productDescription: productDescriptionRow[colLetter] }));

  return columns;
}

const storeIdentifierRegex = RegExp('^[0-9]{4}$');

export function getProductTossRecords(workSheetData: ProductTossSheetWorkSheetData) {
  const firstProductTossRecordRowIndex = findFirstProductTossRecordRowIndex();
  const lastProductTossRecordRowIndex = findLastProductTossRecordRow(workSheetData);

  const productTossRecordRows = workSheetData.slice(firstProductTossRecordRowIndex, lastProductTossRecordRowIndex + 1);

  const productTossRecordColLetters = findProductTossRecordColLetters(workSheetData);

  const productTossRecords = productTossRecordRows
    .map((row): Types.ImportableProductTossStoreRecord => {
      const storeIdentifier = _.padStart(Excel.getAsString(row[Constants.STORE_NUMBER_COL]), 4, '0');
      if (!storeIdentifierRegex.test(storeIdentifier)) {
        throw new Error(`Expected store identifier to be a four-digit value but instead got ${storeIdentifier}`);
      }

      return {
        storeIdentifier,
        products: productTossRecordColLetters
          .map(column => {
            const productSku = `${column.productSku}`.replace(/[^0-9]/g, '');
            const productDescription = `${column.productDescription}`.trim();

            return {
              productSku,
              productDescription,
              tossValue: Excel.getAsInteger(row[column.colLetter]) || 0,
            };
          })
          .filter(allocation => {
            return allocation.tossValue !== 0;
          }),
      };
    })
    .filter(productTossRecord => {
      return productTossRecord.products.length > 0;
    });

  return productTossRecords;
}
