import * as _ from 'lodash';
import { Thunker, Action } from 'client/types/redux-types';
import { Dispatch } from 'redux';
import * as Mutation from './mutation';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { push } from 'connected-react-router';
import { SupplierCommitmentFormValues } from './types';
import * as FormHelpers from 'client/helpers/form-helpers';
import { FormFields } from './constants';
import * as SharedMutation from '../shared/mutation';

type NonNull<T> = T extends null ? never : T;

const required = <T>(value: T, label: string): NonNull<T> => {
  if (_.isNull(value)) {
    throw new Error(`${label} is required.`);
  }
  return value as NonNull<T>;
};

export type ActionTypes =
  | UpdateSupplierItemCostsModalShown;

export enum ActionTypeKeys {
  UpdateSupplierItemCostsModalShown = 'SupplierCommitmentDetail/UPDATE_SUPPLIER_ITEM_COSTS_MODAL_SHOWN',
}

export interface UpdateSupplierItemCostsModalShown extends Action {
  type: ActionTypeKeys.UpdateSupplierItemCostsModalShown;
}

const updateSupplierCommitment = async (dispatch: Dispatch<any>, supplierCommitmentId: number, formValues: Partial<SupplierCommitmentFormValues>): Promise<number> => {
  const salesPlanId = required(formValues[FormFields.salesPlanId], 'Supplier Commitment Sales Plan');
  const input: SharedMutation.UpdateSupplierCommitmentInput = {
    supplierCommitmentId,
    input: {
      salesPlanId,
      supplierId: formValues[FormFields.supplierId] || undefined,
    },
  };
  const result = await msyncClientMutation<SharedMutation.UpdateSupplierCommitmentResponse>({
    mutation: SharedMutation.UpdateSupplierCommitmentMutation,
    variables: input,
    dispatch,
  });
  return result.data.supplierCommitment.id;
};

const createSupplierCommitment = async (dispatch: Dispatch<any>, formValues: SupplierCommitmentFormValues): Promise<number> => {
  const supplierId = formValues[FormFields.supplierId];
  const salesPlanId = formValues[FormFields.salesPlanId];

  if (!supplierId || !salesPlanId) {
    throw new Error('Unable to create a supplier commitment without all the necessary information');
  }

  const result = await msyncClientMutation<Mutation.CreateSupplierCommitmentResponse, Mutation.CreateSupplierCommitmentInput>({
    mutation: Mutation.CreateSupplierCommitmentMutation,
    variables: {
      input: {
        salesPlanId,
        supplierId,
      },
    },
    dispatch,
  });
  return result.data.supplierCommitment.id;
};

export const formSubmitted = (formValues: SupplierCommitmentFormValues, props: { supplierCommitmentId?: number, initialValues?: SupplierCommitmentFormValues, confirmOkToSave: () => Promise<boolean> }): Thunker =>
  async dispatch => {
    if (props.supplierCommitmentId) {
      const modified = props.initialValues ? FormHelpers.getFormChanges(formValues, props.initialValues) : formValues;
      await updateSupplierCommitment(dispatch, props.supplierCommitmentId, modified);
    } else {
      const supplierCommitmentId = await createSupplierCommitment(dispatch, formValues);
      dispatch(push(`/planning/supplier-commitments/details/${supplierCommitmentId}`));
    }
  };

export const downloadMenuItemClicked = (supplierCommitmentId: number): Thunker =>
  async dispatch => {
    const result = await msyncClientMutation<Mutation.DownloadSupplierCommitmentMutationResponse, Mutation.DownloadSupplierCommitmentMutationInput>({
      mutation: Mutation.DownloadSupplierCommitmentMutation,
      variables: {
        supplierCommitmentId,
      },
      dispatch,
    });

    const reportId = result.data.response.report.id;

    window.location.replace(`/report/fileDownload/${reportId}`);
  };

export const updateSupplierItemCostsClicked = (supplierCommitmentId: number): UpdateSupplierItemCostsModalShown => {
  return {
    type: ActionTypeKeys.UpdateSupplierItemCostsModalShown,
  };
};

export const downloadSupplierSpecSheetMenuItemClicked = (supplierCommitmentId: number): Thunker => async dispatch => {
  const result = await msyncClientMutation<Mutation.DownloadSupplierSpecSheetPdfMutationResponse, Mutation.DownloadSupplierSpecSheetPdfMutationInput>({
    mutation: Mutation.DownloadSupplierSpecSheetPdfMutation,
    variables: {
      supplierCommitmentId,
    },
    dispatch,
  });

  window.location.replace(`/report/fileDownload/${result.data.response.report.id}`);
};
