import * as React from 'react';
import * as BS from 'client/components/third-party';
import * as GlobalCancelButtonContainer from 'client/components/async-button/global-cancel-button-container';
import { ContainerProps } from './container';
import Dropzone from 'react-dropzone';
import * as classnames from 'classnames';
import * as _ from 'lodash';
import { FileUploadStatus, FileUploadState } from 'client/components/import-attachment-modal/types';

export interface ComponentProps {
  handleCloseButtonClicked: () => void;
  handleCancelButtonClicked: () => void;
}

export type Props = ContainerProps & ComponentProps;

interface State {
  status: { [k: string]: FileUploadStatus };
}

const ProgressBarHeight = 24;
const PaddingTop = 10;

export class ImportAttachmentModalUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: {},
    };
  }

  statusContainerRef: HTMLDivElement;
  setStatusContainerRef = element => {
    this.statusContainerRef = element;
  }

  scroll = () => {
    const firstUploading = Object.keys(this.state.status).map(key => this.state.status[key].state).findIndex(state => state === FileUploadState.Uploading);
    if (firstUploading !== -1) {
      this.statusContainerRef.scrollTop = ProgressBarHeight * (firstUploading - 1) + PaddingTop - 1;
    }
  }

  onDrop = async (acceptedFiles: File[]) => {
    const updateStatus = (latestStatus: FileUploadStatus) => {
      this.setState({
        status: {
          ...this.state.status,
          [latestStatus.objectKey]: latestStatus,
        },
      });

      this.scroll();
    };

    this.props.handleAttachmentUpload(acceptedFiles, this.props.recordType, this.props.recordId, updateStatus);
  }

  render() {
    const haveFilesBeenUploaded = Object.keys(this.state.status).length === 0;

    const statusClassName = classnames('import-attachment-progress-area-container', {
      'import-attachment-progress-area-container-big': !haveFilesBeenUploaded,
      'import-attachment-progress-area-container-little': haveFilesBeenUploaded,
    });

    const overallState = Object.keys(this.state.status).reduce((acc, key) => {
      const state = this.state.status[key].state;

      if (!acc[state]) {
        acc[state] = 1;
      } else {
        acc[state]++;
      }

      return acc;
    }, {});

    const complete = overallState[FileUploadState.Complete] || 0;
    const failed = overallState[FileUploadState.Failed] || 0;
    const total = Object.keys(this.state.status).length;

    const uploadInProgress = complete + failed < total;

    return (
      <div>
        <BS.Modal
          id="import-attachment-modal"
          animation
          backdrop="static"
          show
          onHide={this.props.handleCloseButtonClicked}
          dialogClassName="import-modal-container"
          className="import-modal"
          keyboard={!uploadInProgress}
        >
          <BS.Modal.Header
            closeButton={!uploadInProgress}
          >
            <BS.Modal.Title>Add Attachments</BS.Modal.Title>
          </BS.Modal.Header>
          <BS.Modal.Title>
          </BS.Modal.Title>
          <BS.Modal.Body>
            <Dropzone onDrop={this.onDrop}>
              {({getRootProps, isDragActive, getInputProps}) => {
                const { onClick, ...rootProps } = getRootProps({
                  onClick: event => _.noop,
                });

                const classname = classnames('import-attachment-modal', {
                  'drag-active': isDragActive,
                  'import-attachment-modal-big': haveFilesBeenUploaded,
                  'import-attachment-modal-little': !haveFilesBeenUploaded,
                });

                return (
                  <div className={classname} {...rootProps}>
                    <input {...getInputProps()} />

                    <div className="import-attachment-modal-content-icon">
                      <i className="fa fa-cloud-upload fa-5x"></i>
                    </div>
                    <div className="import-attachment-modal-content-body">
                      <p>Drag and Drop</p>

                      or <BS.Button
                        id="attachment-modal-select-files-button"
                        className="btn btn-default mfc-button mfc-submit-button mfc-button-primary"
                        onClick={onClick as any}
                      >
                        Select Files
                      </BS.Button>
                    </div>
                  </div>
                );
              }}
            </Dropzone>

            <div className={statusClassName} ref={this.setStatusContainerRef}>
              {Object.keys(this.state.status).map(key => {
                const fileStatus = this.state.status[key];

                const bsStyle = classnames({
                  info: fileStatus.state === FileUploadState.Queued || fileStatus.state === FileUploadState.Uploading,
                  success: fileStatus.state === FileUploadState.Complete,
                  danger: fileStatus.state === FileUploadState.Failed,
                });

                const tooltip = fileStatus.state === FileUploadState.Failed
                  ? (
                      <BS.Tooltip id={`tooltip-${fileStatus.objectKey}`}>
                        {fileStatus.errors.join()}
                      </BS.Tooltip>
                    )
                  : (
                      <span />
                    );

                return (
                  <div key={key} className="import-attachment-progressbar-parent">
                    <div className="import-attachment-progressbar-label">{fileStatus.filename}</div>
                    <div className="import-attachment-progressbar-container">
                      <BS.OverlayTrigger placement="top" overlay={tooltip}>
                        <BS.ProgressBar
                          bsStyle={bsStyle}
                          active={fileStatus.state === FileUploadState.Uploading}
                          striped
                          now={getPercentage(fileStatus.state)}
                        />
                      </BS.OverlayTrigger>
                    </div>
                  </div>
                );
              })}
            </div>
          </BS.Modal.Body>
          <BS.Modal.Footer>
            <div className="import-attachment-modal-footer-progress">
              {total > 0 ? <span>Uploaded {complete} of {total}</span> : <span></span>}
              {failed > 0 ? <span>, <span className="import-attachment-modal-footer-progress-failed">{failed} Failed</span></span> : <span></span>}
            </div>

            <GlobalCancelButtonContainer.GlobalCancelButton
              disabled={uploadInProgress}
              onClick={this.props.handleCancelButtonClicked}
              testid="import-modal-cancel"
              label="Close"
            />
          </BS.Modal.Footer>
        </BS.Modal>
      </div>
    );
  }
}

const getPercentage = (state: FileUploadState) => {
  switch (state) {
    case FileUploadState.Queued:
      return 0;
    case FileUploadState.Uploading:
      return 50;
    case FileUploadState.Complete:
      return 100;
    case FileUploadState.Failed:
      return 100;
  }
};
