import * as React from 'react';
import ApolloClient from 'apollo-client';
import { flowRight } from 'lodash';
import { IRecord } from 'shared/schemas/record';
import { FindAllContainerProps, findAllRecords, WorksheetName, WorkbookName } from './graphql/find-all-records';
import { withRecordActivations, ActivateContainerProps } from './graphql/activate-records';
import { withRecordDeactivations, DeactivateContainerProps } from './graphql/deactivate-records';
import { withRecordDeletions, DeleteContainerProps } from './graphql/delete-records';
import { withRecordFilterOptions, FilterOptionsProps } from './graphql/record-filter-options';
import { StatsContainerProps, GetStatsOptions, withRecordStats } from './graphql/stats-record';
import { withSchemaColumnMappings, ColumnProps } from './schema-column-map';
import { ActiveFilter } from 'client/types';
import { Collapse } from 'shared/types';
import { filterableColumns, tableDisplayColumns } from 'shared/schemas';
import { withApollo } from 'react-apollo';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

export interface ScopeContext { record: IRecord, activeFilters: ActiveFilter[] }
export type Scope = (scopeContent: ScopeContext) => ActiveFilter[];
export interface DataContainerConfig {
  table: string;
  columns?: string[];
  excelColumns?: string[];
  filters?: string[];
  scope?: Scope;
  statsOptionsFromState?: GetStatsOptions;
  query?: string;
  worksheetName?: WorksheetName;
  workbookName?: WorkbookName;
  hardDeleteRecords?: boolean;
  refetchQueries?: string[];
  maxRecordsToDelete?: number;
}

export interface WithApolloProps { client: ApolloClient<NormalizedCacheObject> }
export type ContainerProps = Collapse<FindAllContainerProps & DeleteContainerProps & ActivateContainerProps & DeactivateContainerProps & WithApolloProps & FilterOptionsProps & ColumnProps & StatsContainerProps>;
export const dataContainer = (config: DataContainerConfig) => <P extends ContainerProps & {}>(WrappedComponent: React.ComponentType<P>) => flowRight(
  withApollo,
  withRecordStats(config.table, config.statsOptionsFromState),
  findAllRecords({ table: config.table, columns: config.columns || tableDisplayColumns(config.table), excelColumns: config.excelColumns, scope: config.scope, workbookName: config.workbookName, worksheetName: config.worksheetName }),
  withRecordActivations(config.table),
  withRecordDeactivations(config.table, config.maxRecordsToDelete),
  withRecordDeletions(config.table, config.maxRecordsToDelete),
  withRecordFilterOptions({ table: config.table, fields: config.filters || filterableColumns(config.table), scope: config.scope }),
  withSchemaColumnMappings({ schema: config.table, displayColumns: config.columns, excelColumns: config.excelColumns || tableDisplayColumns(config.table), refetchQueries: config.refetchQueries }),
)(WrappedComponent) as React.FunctionComponent<Exclude<P, ContainerProps>>;

export default dataContainer;
