import gql from 'graphql-tag';
import { msyncQuery } from 'client/hoc/graphql/query';

export interface CustomerOrdersForRoutePlanQueryResponse {
  response?: {
    ids: number[];
  };
}

export const CustomerOrdersForRoutePlanQuery = gql`
  query CustomerOrdersForRoutePlanQuery($filters: [FilterSpecificationInput], $sort: [SortInput!], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    response: FindAllCustomerOrders(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ids
    }
  }
`;

export interface WithCustomerOrdersForRoutePlanProps {
  customerOrderIdsToBeInvoiced: number[];
  purchaseOrderInvoiceCheckboxDisabled: boolean;
}

export const withCustomerOrdersForRoutePlan = msyncQuery<CustomerOrdersForRoutePlanQueryResponse, { routePlanId?: number }, WithCustomerOrdersForRoutePlanProps>(CustomerOrdersForRoutePlanQuery, {
  alias: 'withCustomerOrdersForRoutePlan',
  skip(ownProps) {
    return false;
  },
  options(ownProps): { variables } {
    return {
      variables: {
        filters: [
          {
            field: 'routePlanId',
            // This is really not awesome, but right now we don't have a good way of defaulting a query prop if that query is skipped,
            // so for this query, when we don't have a route plan, we don't want to query anything, but we still want to set the query props - this is a temporary solution.
            values: [ownProps.routePlanId || -1],
          },
          {
            field: 'orderSupportsInvoicing',
            values: [true],
          },
        ],
      },
    };
  },
  props(props): WithCustomerOrdersForRoutePlanProps {
    const customerOrderIdsToBeInvoiced = props.data.response ? props.data.response.ids : [];
    const purchaseOrderInvoiceCheckboxDisabled = customerOrderIdsToBeInvoiced.length === 0;

    return {
      customerOrderIdsToBeInvoiced,
      purchaseOrderInvoiceCheckboxDisabled,
    };
  },
});
