import * as State from 'client/state/receiving-worksheet';
import { ActionTypes, ActionTypeKeys } from 'client/actions/receiving-worksheet';
import { flow } from 'lodash';

type ReceivingWorksheetState = State.Type;

export default function reducer(state = State.InitialState, action: ActionTypes): ReceivingWorksheetState {
  switch (action.type) {
    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MODAL_VISIBILITY: {
      return State.addBolModalShown.set(state, action.payload.showModal);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MODAL_VISIBILITY: {
      return flow(
        State.editBolModalShown.set(action.payload.showModal),
        State.bolToEditId.set(action.payload.bolId),
      )(state);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MODAL_VISIBILITY: {
      return flow(
        State.removeBolModalShown.set(action.payload.showModal),
        State.bolToRemoveId.set(action.payload.bolId),
      )(state);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_BOL_CLICKED: {
      return State.selectedBolId.set(state, action.payload.bolId);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_BOL_UNSELECTED: {
      return State.selectedBolId.set(state, undefined);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_ADD_BOL_MUTATION_STATUS: {
      return State.addBolMutationStatus.set(state, action.payload.status);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_EDIT_BOL_MUTATION_STATUS: {
      return State.editBolMutationStatus.set(state, action.payload.status);
    }

    case ActionTypeKeys.RECEIVING_WORKSHEET_SET_REMOVE_BOL_MUTATION_STATUS: {
      return State.removeBolMutationStatus.set(state, action.payload.status);
    }

    default:
      return state;
  }
}
