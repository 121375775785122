import * as _ from 'lodash';
import * as TableState from './table';
import * as GlobalState from './state';
import { Lens } from '@atomic-object/lenses';
import { createCustomTable } from 'client/reducers/table-helpers';
import { EMPTY_ARRAY } from 'client/constants';
import { Bug } from 'shared/helpers';

export type Type = TableState.Type;

/**
 * The built-in prop lens always returns a new object when
 * setting a value. That's fine for shallowEquals, but is different
 * than how Immutable.js setting works. And apparently there are places
 * (at least one for sure) that was relying on the fact that some part
 * of state was exactly the same (===) when a value was being set that
 * was no different from the current value.
 *
 * The lens returned from this function returns the original state if the
 * value being set is already the current value of the property.
 */
const propLensMaker = <O>() => ({
  prop: <K extends keyof O>(propName: K, bootstrapInitialState?: O[K]): Lens<O, O[K]> => Lens.of({
      get: (state: O) => (state[propName] ?? bootstrapInitialState) as O[K],
      set: (state: O, value: O[K]) => value === state[propName] ? state : { ...state, [propName]: value },
    }),
});

export const buildTableStateLens = propLensMaker<TableState.TablesState>().prop;

const selectedCellLens = propLensMaker<TableState.Type>().prop('selectedCell');
const editingLens      = propLensMaker<TableState.Type>().prop('editing'     );
const searchLens       = propLensMaker<TableState.Type>().prop('search'      );

export const TableStateLenses = {
  hasInvalidCell       : propLensMaker<TableState.Type>().prop('hasInvalidCell'       ),
  rowsPerPage          : propLensMaker<TableState.Type>().prop('rowsPerPage'          ),
  pageNumber           : propLensMaker<TableState.Type>().prop('pageNumber'           ),
  sort                 : propLensMaker<TableState.Type>().prop('sort'                 ),
  filters              : propLensMaker<TableState.Type>().prop('filters'              ),
  checkedRecordIds     : propLensMaker<TableState.Type>().prop('checkedRecordIds'     ),
  orderToDelete        : propLensMaker<TableState.Type>().prop('orderToDelete'        ),
  showConfirmationModal: propLensMaker<TableState.Type>().prop('showConfirmationModal'),
  selectedCellRow   : selectedCellLens.comp(propLensMaker<TableState.Type['selectedCell']>().prop('row'       )),
  selectedCellColumn: selectedCellLens.comp(propLensMaker<TableState.Type['selectedCell']>().prop('column'    )),
  editingValue      : editingLens     .comp(propLensMaker<TableState.Type['editing'     ]>().prop('value'     )),
  editingShouldSave : editingLens     .comp(propLensMaker<TableState.Type['editing'     ]>().prop('shouldSave')),
  searchText        : searchLens      .comp(propLensMaker<TableState.Type['search'      ]>().prop('text'      )),
  searchFields      : searchLens      .comp(propLensMaker<TableState.Type['search'      ]>().prop('fields'    )),
};

const checkedRecordIds = (tableState: TableState.Type) => TableStateLenses.checkedRecordIds.get(tableState) || EMPTY_ARRAY;
const activeSortFields = (tableState: TableState.Type) => TableStateLenses.sort.get(tableState) || EMPTY_ARRAY;
const activeSearchText = (tableState: TableState.Type) => TableStateLenses.searchText(tableState) || '';
const activeSearch     = (tableState: TableState.Type) => tableState.search;
const activeFilters    = (tableState: TableState.Type) => TableStateLenses.filters(tableState) || EMPTY_ARRAY;
const tablePageNumber  = (tableState: TableState.Type) => TableStateLenses.pageNumber(tableState);

const tablesLens = propLensMaker<GlobalState.Type>().prop('tables');

export const buildTableStateModule = (tableName: string, initialState?: Partial<TableState.Type>) => {
  if (!_.isString(tableName)) throw new Bug(`buildTableStateModule requires a string tableName parameter, but '${tableName}' was supplied`);
  const InitialState = initialState ? { [tableName]: createCustomTable(initialState) } : undefined;
  const tableStateLens = buildTableStateLens(tableName, InitialState?.[tableName]);
  const globalTableStateLens = tablesLens.comp(tableStateLens);
  return {
    tableStateLens: globalTableStateLens,
    InitialState,
    availableSearchFields: (tableState: TableState.Type) => tableState.availableSearchFields || EMPTY_ARRAY,
    selectedCell:          (tableState: TableState.Type) => tableState.selectedCell,
    hasInvalidCell:        (tableState: TableState.Type) => TableStateLenses.hasInvalidCell(tableState),
    editing:               (tableState: TableState.Type) => tableState.editing,
    activeFilters,
    activeSearch,
    activeSearchText,
    activeSortFields,
    tablePageNumber,
    checkedRecordIds,
    commonTableProps: (state: GlobalState.Type) => {
      const tableState = globalTableStateLens.get(state);
      return {
        activeFilters: activeFilters(tableState),
        activeSearch: activeSearch(tableState),
        activeSearchText: activeSearchText(tableState),
        activeSortFields: activeSortFields(tableState),
        tablePageNumber: tablePageNumber(tableState),
        checkedRecordIds: checkedRecordIds(tableState),
      };
    },
  };
};
