const onClickOutside: any = require('react-onclickoutside');
import * as _ from 'lodash';
import { CheckedRows } from './checked-rows';
import { idsFor, excludeNils } from 'shared/helpers/andys-little-helpers';
import { RowsProps } from './rows';
import { Search } from './search';
import { SelectableRow } from './types';
import { SelectAll } from './select-all';
import { Title } from './title';
import { UncheckedRows } from './unchecked-rows';
import * as classnames from 'classnames';
import * as Mousetrap from 'mousetrap';
import * as React from 'react';
import { EMPTY_ARRAY } from 'client/constants';
import { SelectableValue as SelectableValueType } from 'shared/types';

const RowHeight = 22;
const Rows = 5;

export type SelectableValue = SelectableValueType;
export const extractSelectedValues = (formValue: SelectableValue | undefined): number[] => formValue?.values ?? EMPTY_ARRAY;

export interface SelectableProps {
  cols: RowsProps['cols'];
  handleChange?: (value: SelectableValue) => void;
  input: {
    name: string;
    onChange: (value: SelectableValue) => any;
    value: string | SelectableValue; // Appears that every redux-form input gets an empty string value to start with
  };
  isAtBottom?: boolean;
  loading?: boolean;
  options?: SelectableRow[];
  required: boolean;
  testid?: string;
  title?: string;
  horizontalLabel?: boolean;
  hideOptionalLabel?: boolean;
}

interface SelectableState {
  highlightIndex: number;
  isInputFocused: boolean;
  isOpen: boolean;
  searchText: string;
}

const DEFAULT_VALUE: SelectableValue = { selectAllChecked: false, values: [] };

const isString = (value: string | SelectableValue): value is string => {
  return typeof value === 'string';
};

const getInputSelectableValue = (props: SelectableProps): SelectableValue => {
  if (isString(props.input.value)) {
    return DEFAULT_VALUE;
  }

  return props.input.value;
};

export class SelectableUI extends React.PureComponent<SelectableProps, SelectableState> {
  private calculations: {
    checkedRows: SelectableRow[];
    checkedRowCount: number;
    totalRowCount: number;
    uncheckedRows: SelectableRow[];
    allRowsChecked: boolean;
    disabled: boolean;
  };

  constructor(props: SelectableProps) {
    super(props);

    this.state = {
      highlightIndex: -1,
      isInputFocused: false,
      isOpen: false,
      searchText: '',
    };
  }

  onChange = (value: SelectableValue) => {
    if (this.props.handleChange) {
      this.props.handleChange(value);
    } else {
      this.props.input.onChange(value);
    }
  }

  calculate = (): void => {
    const value = getInputSelectableValue(this.props);
    const options = this.props.options || [];

    const selectedIdSet = new Set(value.values);

    // Filter out any selections that aren't currently available in the provided options
    const validRowMapping: { [k: number]: SelectableRow } = options.reduce((memo, row) => {
      if (selectedIdSet.has(row.id)) {
        memo[row.id] = row;
      }
      return memo;
    }, {});

    const searchText = this.state.searchText || '';

    // Want to keep the rows in the order that the user selected them, so go through
    // each selected value and find the corresponding option row.
    const checkedRows: SelectableRow[] = excludeNils(value.values.map(id => validRowMapping[id]));

    const uncheckedRows = options.filter(row => {
      const checked = selectedIdSet.has(row.id);
      if (checked) {
        return false;
      }

      if (searchText === '') {
        return true;
      }

      if (row.cells.join(' | ').toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }

      return false;
    });

    const checkedRowCount = checkedRows.length;
    const totalRowCount = options.length;

    this.calculations = {
      checkedRows,
      checkedRowCount,
      totalRowCount,
      uncheckedRows,
      allRowsChecked: checkedRowCount === totalRowCount && totalRowCount > 0,
      disabled: totalRowCount === 0,
    };
  }

  componentDidUpdate(prevProps: SelectableProps, prevState: SelectableState) {
    if (prevState.searchText === '' && this.state.searchText !== '') {
      this.open();
    }

    const selectableValue = getInputSelectableValue(this.props);

    if (getInputSelectableValue(prevProps).values.length < selectableValue.values.length) {
      this.scrollToBottom();
    }

    if (!this.props.loading) {
      // Handle case where new props come down indicating select all is checked, but
      // no ids are specified. Want to check everything that's available.
      if (selectableValue.selectAllChecked && selectableValue.values.length === 0 && this.props.options && this.props.options.length > 0) {
        this.onChange({ selectAllChecked: true, values: idsFor(this.props.options) });
        return;
      }

      // Handle the case where the options change out from underneath a "select all" selectable
      if (selectableValue.selectAllChecked && this.props.options && !_.isEqual(this.props.options, prevProps.options)) {
        this.onChange({ selectAllChecked: true, values: idsFor(this.props.options) });
        return;
      }

      // This function is called after "render", so these calculations will already have been updated
      // with the latest and greatest.
      const { checkedRows } = this.calculations;

      // I don't remember exactly what scenario this was handling, unfortunately. But there were cases
      // where the Selectable was showing selections it shouldn't, and I think this was taking care of that.
      if (!selectableValue.selectAllChecked && !_.isEqual(_.sortBy(selectableValue.values), _.sortBy(idsFor(checkedRows)))) {
        this.onChange({ selectAllChecked: false, values: idsFor(checkedRows) });
      }
    }
  }

  onSearchInputClicked = (event: React.MouseEvent<HTMLInputElement>) => {
    this.open();
  }

  onSearchInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      isInputFocused: false,
    });
  }

  onSearchInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      isInputFocused: true,
    });
  }

  onSearchChanged = (searchText: string) => {
    this.setState({
      searchText,
    });
  }

  onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      this.setState({
        highlightIndex: 0,
      });

      (event.target as shame).blur();

      this.open();
    } else if (event.key === 'Tab' || event.key === 'Escape') {
      this.close();
    }
  }

  onSelectAllClicked = (value: boolean): void => {
    if (getInputSelectableValue(this.props).selectAllChecked) {
      this.onChange({ selectAllChecked: false, values: [] });
      this.setState({
        isOpen: false,
      });
    } else {
      const values = this.props.options ? idsFor(this.props.options) : [];
      this.onChange({ selectAllChecked: true, values });

      if (values.length === 0) {
        this.setState({
          isOpen: false,
        });
      }
    }
  }

  onRowUnchecked = (row: SelectableRow): void => {
    const values = getInputSelectableValue(this.props).values.filter(v => v !== row.id);
    this.onChange({ selectAllChecked: false, values });
  }

  onRowMouseOver = (row: SelectableRow): void => {
    const { uncheckedRows } = this.calculations;
    const highlightIndex = uncheckedRows.findIndex(r => r.id === row.id);
    if (highlightIndex !== -1) {
      this.setState({
        highlightIndex,
      });
    }
  }

  onRowChecked = (row: SelectableRow): void => {
    const { uncheckedRows } = this.calculations;

    const values = [
      ...getInputSelectableValue(this.props).values,
      row.id,
    ];

    const isAllRowsChecked = !!(this.props.options && values.length === this.props.options.length);
    this.onChange({ selectAllChecked: isAllRowsChecked, values });

    // If there was only one unchecked left, the user just checked the last one,
    // close the selection area and clear the search text
    if (uncheckedRows.length === 1) {
      this.setState({
        isOpen: false,
        searchText: '',
      });
    }

    if (this.state.highlightIndex === uncheckedRows.length - 1) {
      this.setState({
        highlightIndex: uncheckedRows.length - 2,
      });
    }
  }

  onCaretClicked = (): void => {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  selectableRef: HTMLDivElement;
  setSelectableRef = element => {
    this.selectableRef = element;
  }

  searchInputRef: HTMLDivElement;
  setSearchInputRef = element => {
    this.searchInputRef = element;
  }

  checkedRowsRef: HTMLDivElement;
  setCheckedRowsRef = element => {
    this.checkedRowsRef = element;
  }

  uncheckedRowsRef: HTMLDivElement;
  setUncheckedRowsRef = element => {
    this.uncheckedRowsRef = element;
  }

  render() {
    this.calculate();

    const width = this.selectableRef
      ? this.selectableRef.clientWidth
      : 0;

    const { disabled, checkedRowCount, uncheckedRows, totalRowCount, checkedRows } = this.calculations;
    const selectableValue = getInputSelectableValue(this.props);

    return (
      <div className={classnames('selectable', {
        'selectable-at-bottom': this.props.isAtBottom,
        'selectable-active': this.state.isOpen || this.state.isInputFocused,
        disabled,
      })}
        data-testid={this.props.testid}
        ref={this.setSelectableRef}
      >
        <div className="selectable-header">
          <Title
            checkedRowCount={checkedRowCount}
            disabled={disabled}
            required={this.props.required}
            title={this.props.title}
            totalRowsCount={totalRowCount}
            horizontalLabel={this.props.horizontalLabel}
            hideOptionalLabel={this.props.hideOptionalLabel}
          />
          <SelectAll
            checked={selectableValue.selectAllChecked}
            disabled={disabled}
            onClick={this.onSelectAllClicked}
            testid={`${this.props.testid}-select-all`}
          />
        </div>
        <div className="selectable-content">
          <Search
            allRowsChecked={selectableValue.selectAllChecked}
            disabled={disabled}
            inputRef={this.setSearchInputRef}
            isCaretDisabled={uncheckedRows.length === 0}
            isOpen={this.state.isOpen}
            noRowsShown={checkedRowCount === 0}
            onCaretClicked={this.onCaretClicked}
            onChanged={this.onSearchChanged}
            onInputBlur={this.onSearchInputBlur}
            onInputClicked={this.onSearchInputClicked}
            onInputFocus={this.onSearchInputFocus}
            onKeyDown={this.onSearchKeyDown}
            loading={this.props.loading}
            searchText={this.state.searchText}
          />
          {checkedRows.length > 0 &&
            <CheckedRows
              cols={this.props.cols}
              rows={checkedRows}
              onUnchecked={this.onRowUnchecked}
              rowsRef={this.setCheckedRowsRef}
            />
          }
          {this.state.isOpen &&
            <UncheckedRows
              cols={this.props.cols}
              rows={uncheckedRows}
              onChecked={this.onRowChecked}
              onMouseOver={this.onRowMouseOver}
              searchText={this.state.searchText}
              highlightIndex={this.state.highlightIndex}
              rowsRef={this.setUncheckedRowsRef}
              width={width}
            />
          }
        </div>
      </div>
    );
  }

  scrollToBottom = () => {
    if (this.checkedRowsRef) {
      this.checkedRowsRef.scrollTop = this.checkedRowsRef.scrollHeight - this.checkedRowsRef.clientHeight;
    }
  }

  scrollToHighlight = () => {
    if (this.uncheckedRowsRef) {
      const offset = this.state.highlightIndex * RowHeight;

      if (offset > this.uncheckedRowsRef.clientHeight && this.uncheckedRowsRef.scrollTop + (RowHeight * (Rows - 1)) < offset) {
        this.uncheckedRowsRef.scrollTop = offset - this.uncheckedRowsRef.clientHeight + RowHeight;
      } else if (offset < this.uncheckedRowsRef.scrollTop) {
        this.uncheckedRowsRef.scrollTop = offset;
      }
    }
  }

  upArrowPressed = () => {
    if (this.state.highlightIndex >= 0) {
      this.setState({
        highlightIndex: this.state.highlightIndex - 1,
      });

      if (this.state.highlightIndex === -1) {
        if (this.searchInputRef) {
          this.searchInputRef.focus();
        }
      }

      this.scrollToHighlight();
    }
  }

  tabPressed = () => {
    this.close();
  }

  escPressed = () => {
    this.close();

    if (this.searchInputRef) {
      this.searchInputRef.focus();
    }
  }

  downArrowPressed = () => {
    const { uncheckedRows } = this.calculations;

    if (this.state.highlightIndex < uncheckedRows.length - 1) {
      this.setState({
        highlightIndex: this.state.highlightIndex + 1,
      });

      this.scrollToHighlight();
    }
  }

  enterKeyPressed = () => {
    const { uncheckedRows } = this.calculations;
    const row: SelectableRow | undefined = uncheckedRows[this.state.highlightIndex];
    if (row) {
      this.onRowChecked(row);
    }
  }

  open = () => {
    Mousetrap.bind('up', this.upArrowPressed);
    Mousetrap.bind('down', this.downArrowPressed);
    Mousetrap.bind('shift+tab', this.tabPressed);
    Mousetrap.bind('tab', this.tabPressed);
    Mousetrap.bind('esc', this.escPressed);
    Mousetrap.bind(['space', 'enter'], this.enterKeyPressed);

    this.setState({
      isOpen: true,
    });
  }

  close = () => {
    Mousetrap.unbind('up');
    Mousetrap.unbind('down');
    Mousetrap.unbind('shift+tab');
    Mousetrap.unbind('tab');
    Mousetrap.unbind('esc');
    Mousetrap.unbind(['space', 'enter']);

    if (!this.state.isOpen) {
      return;
    }

    this.setState({
      isOpen: false,
      searchText: '',
      highlightIndex: -1,
    });
  }

  handleClickOutside = () => {
    this.close();
  }
}

export const Selectable = onClickOutside(SelectableUI);
