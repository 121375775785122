import * as React from 'react';
import { RecordBar } from 'client/app/planning/supplier-commitments/record-bar';
import Sidebar from 'client/app/planning/supplier-commitments/sidebar';
import UpdateSupplierItemCostsModal from '../update-supplier-item-costs-modal/update-supplier-item-costs-modal-container';
import { SupplierCommitmentAllocationTable } from '../supplier-commitment-allocation-table';
import { ImportSupplierCommitmentModal } from '../import-supplier-commitment-modal';

export interface UIProps {
  supplierCommitmentId: number;
  updateSupplierItemCostsModalShown: boolean;
  startWeek: number | null | undefined;
  endWeek: number | null | undefined;
  loading: boolean;
}

export class SupplierCommitmentDetailPage extends React.PureComponent<UIProps, {}> {
  render() {
    return (
      <div id="mfc-page-content" className="record">
        <RecordBar supplierCommitmentId={this.props.supplierCommitmentId} />

        {this.props.updateSupplierItemCostsModalShown &&
          <UpdateSupplierItemCostsModal supplierCommitmentId={this.props.supplierCommitmentId} />
        }

        <div className="mfc-form">
          <div>
            <div className="mfc-form-details-with-sidebar-but-no-tabs" style={{ paddingLeft: 20, paddingRight: 20 }}>
              <SupplierCommitmentAllocationTable supplierCommitmentId={this.props.supplierCommitmentId}/>
              <ImportSupplierCommitmentModal supplierCommitmentId={this.props.supplierCommitmentId}/>
            </div>
            <div className="mfc-form-sidebar">
              <Sidebar supplierCommitmentId={this.props.supplierCommitmentId} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
