import * as React from 'react';
import { Form, Col, Row, FormGroup } from 'client/components/third-party';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { MutationStatus } from 'client/actions/mutations';
import { DropDownOptions } from 'client/types';
import { LabeledSelect } from 'client/components/form';
import { AsyncButton } from 'client/components/async-button/async-button-container';

export interface UIProps {
  handleDownloadExcelReportClicked(salesPlanId: number | null): void;
  reportDownloadStatus: MutationStatus;
  salesPlanOptions: DropDownOptions;
}

const emptyArray = [];
const optionTextFormatter = option => {
  return `${option.customer.identifier} - ${option.sellDepartment.identifier} - ${option.identifier} - ${option.year}`;
};

export function DifferenceCalculationReportUI(props: UIProps) {
  const [selectedSalesPlanId, setSelectedSalesPlanId] = React.useState<number | null>(null);
  return (
    <div>
      <RecordBar title="Difference Calculation Report" hideBackButton />
      <div id="mfc-page-content" className="mfc-page-content mfc-form">
        <div className="mfc-form-details-with-sidebar-but-no-tabs planning-reports-supplier-performance-table-wrapper  mfc-scrolling-region-adjacent-to-sidebar">
          <Form horizontal>
            <Row className="report-fields-outside-tabs">
              <Col sm={12}>
                <FormGroup>
                  <LabeledSelect
                    inputColSize={4}
                    label="Sales Plan"
                    options={props.salesPlanOptions || emptyArray}
                    labelColSize={4}
                    offset={0}
                    textFormatter={optionTextFormatter}
                    valueField="value"
                    required
                    horizontalLabel={false}
                    testid="difference-calculation-report-sales-plan-dropdown"
                    meta={{ submitting: false }}
                    input={
                      {
                        name: 'salesPlanDropdown',
                        onChange: setSelectedSalesPlanId,
                        value: selectedSalesPlanId,
                      }
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="mfc-form-sidebar">
          <div className="mfc-form-stacked-label report-sidebar-heading">Run Report</div>
          <div className="sidebar-action-button-container">
            <AsyncButton
              classNames={['report-sidebar-button']}
              disabled={selectedSalesPlanId === null}
              label="Download Excel File"
              onClick={() => props.handleDownloadExcelReportClicked(selectedSalesPlanId)}
              actionStatus={props.reportDownloadStatus}
              testid="download-excel-report-button"
            />
          </div>
        </div>
      </div>
    </div >
  );
}
