import { Lens } from '@atomic-object/lenses';

import * as HmgOpsConstants from 'client/app/transportation/reports/hmg-ops/constants';

namespace HmgOps {
  interface HmgOpsState {
    readonly reportType: HmgOpsConstants.HmgOpsReportType;
  }

  export const HmgOpsInitialState: HmgOpsState = {
    reportType: HmgOpsConstants.HmgOpsReportType.Detail,
  };

  export type HmgOpsType = HmgOpsState;
  export const reportTypeLens = Lens.from<HmgOpsType>().prop('reportType');
}

interface TransportationReportsState {
  readonly hmgOps: HmgOps.HmgOpsType;
}
export const InitialState: TransportationReportsState = {
  hmgOps: HmgOps.HmgOpsInitialState,
};

export type Type = TransportationReportsState;

export const hmgOps = Lens.from<Type>().prop('hmgOps');
export const hmgOpsReportType = Lens.from<HmgOps.HmgOpsType>().prop('reportType');
export const hmgOpsReportTypeLens = hmgOps
  .comp(hmgOpsReportType);
