import * as React from 'react';
import { buildDataTable, OwnProps as DataTableProps } from 'client/containers/table/basic-table';
import { ComponentProps } from 'client/app/orders/customer-orders/reconciliation/reconciliation-table-container';
import { CELL_TYPES, TYPES } from 'shared/types';
import { IColumn } from 'client/components/table/column';
import { ReconciliationRow } from 'shared/types/customer-order-types';
import { CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME } from 'client/constants';
import { IdentifierCell } from 'client/components/table/identifier-cell';

const tableName = CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME;

type OwnProps = ComponentProps;

const columns: IColumn[] = [
  {
    id: 'productIdentifier',
    accessor: 'productIdentifier',
    header: 'Product ID',
    tableEditable: false,
    columnWidth: 7,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'productDescription',
    accessor: 'productDescription',
    header: 'Description',
    tableEditable: false,
    columnWidth: 29,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'supplierName',
    accessor: 'supplierName',
    header: 'Supplier',
    tableEditable: false,
    columnWidth: 14,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'supplierOrderIdentifier',
    accessor: r => <IdentifierCell id={r.supplierOrderId} identifier={r.supplierOrderIdentifier} targetRoute={'/orders/supplier/details'} />,
    header: 'Supplier PO #',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
    getClassNames: (val: string, reconciliationRow: ReconciliationRow) => {
      return reconciliationRow.relatedCustomerOrderCount && reconciliationRow.relatedCustomerOrderCount > 1 ? 'reconciliation-multiple-customer-order-warning' : undefined;
    },
  },
  {
    id: 'supplierOrderQuantity',
    accessor: 'supplierOrderQuantity',
    header: 'Supplier Qty',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'supplierOrderTotal',
    accessor: 'supplierOrderTotal',
    header: 'Supplier Ord Total',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'customerOrderTotal',
    accessor: 'customerOrderTotal',
    header: 'Customer Ord Total',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.NUMBER,
    type: TYPES.NUMBER,
  },
  {
    id: 'difference',
    accessor: 'difference',
    header: 'Difference',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.DASH_ZERO_NUMBER,
    type: TYPES.NUMBER,
    getClassNames: (val: number, reconciliationRow: ReconciliationRow) => {
      if (val > 0) {
        return 'reconciliation-difference-positive';
      } else if (val < 0) {
        return 'reconciliation-difference-negative';
      } else {
        return undefined;
      }
    },
  },
];

export default class ReconciliationTable extends React.Component<OwnProps, any> {
  // private readonly DataTable: React.ComponentClass<DataTableProps>;
  private readonly DataTable: (props: DataTableProps) => React.ReactElement<DataTableProps>;

  constructor(props: any) {
    super(props);
    this.DataTable = buildDataTable(tableName);

    this.setTablePageNumber = this.setTablePageNumber.bind(this);
  }

  // eslint-disable-next-line no-empty
  private setTablePageNumber(pageNumber: number) {
  }

  public render() {
    return (
      <div className="reconciliation-table-wrapper">
        <this.DataTable
          content={this.props.reconciliationRows}
          loading={this.props.loading}
          columns={columns}
          totalCount={this.props.reconciliationRows ? this.props.reconciliationRows.length : 0}
          filteredRecordIds={[]} // No pagination so don't need this
          setTablePageNumber={this.setTablePageNumber}
          tablePageNumber={1}
          onSort={this.props.onTableSort}
          sorting={this.props.activeSortFields}
          tablePaginated={false}
          tableParentInfo={this.props.tableParentInfo}
          noDataText="There are currently no products allocated to stores for this customer order."
        />
      </div>
    );
  }
}
