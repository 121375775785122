import { isNil } from 'lodash';
import { wrapComponent } from 'client/hoc/hoc';
import { RoutePlanPrintingWarningUI, WarningType } from './ui';
import { connect, ConnectedProps } from 'react-redux';
import * as Actions from './actions';
// Distribution branch has better redux state pattern that this should be using
// Don't want to export global state here.
// Talk to Andy more info.
import * as GlobalState from 'client/state/state';
import * as Selectors from './selectors';
import * as RoutePlanQuery from './query';
import { msyncQuery } from 'client/hoc/graphql/query';

interface OwnProps {
  routePlanId: number;
}

const mapStateToProps = (state: GlobalState.Type) => {
  const localState = Selectors.localState(state);
  return {
    showSpinner: Selectors.isMarkingRoutePlan(localState),
  };
};

const mapDispatchToProps = {
  onStartOverClicked: Actions.startOverButtonClicked,
};

interface DetermineWarningTypeArgs {
  routePlan: {
    forceReroute: boolean;
    customerOrdersUpdatedSinceRoutePacketPrinting: boolean;
  };
}
const determineWarningType = (args: DetermineWarningTypeArgs): WarningType => {
  if (args.routePlan.forceReroute) {
    return WarningType.ReRoutingNeeded;
  } else if (args.routePlan.customerOrdersUpdatedSinceRoutePacketPrinting) {
    return WarningType.OrdersUpdated;
  }
  return WarningType.None;
};
interface WithRoutePlanProps {
  warningType: WarningType;
}
const WithRoutePlan = msyncQuery<RoutePlanQuery.Response, OwnProps, WithRoutePlanProps, RoutePlanQuery.Input>(
  RoutePlanQuery.RoutePlanPrintingQuery,
  {
  alias: 'WithRoutePlan',
  skip(ownProps: OwnProps) {
    return !ownProps.routePlanId;
  },
  options(ownProps): { variables?: RoutePlanQuery.Input } {
    if (isNil(ownProps.routePlanId)) {
      return {};
    }
    return {
      variables: {
        id: ownProps.routePlanId,
      },
    };
  },
  props(props): WithRoutePlanProps {
    const { data: { routePlanResult } } = props;

    if (!routePlanResult) {
      return {
        warningType: WarningType.None,
      };
    }

    return {
      warningType: determineWarningType({
        routePlan: routePlanResult.routePlan,
      }),
    };
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CombinedProps =
  OwnProps &
  WithRoutePlanProps &
  ConnectedProps<typeof connector>;


export const RoutePlanPrintingWarningContainer = wrapComponent(RoutePlanPrintingWarningUI)<OwnProps, CombinedProps>(
  WithRoutePlan,
  connector,
);
