import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight, isNil } from 'lodash';
import { ReceivingWorksheetTable, ReceivingWorksheetTableComponentProps, ReceivingWorksheetTableRow } from './worksheet-table';
import { propToComponent } from 'client/hoc/hoc';
import { TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME } from 'client/constants';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import {
  ReceivingWorksheetTableResponse,
  ReceivingWorksheetTableQuery,
  ReceivableOrderInfoResponse,
  ReceivableOrderInfoQuery,
  BolInfoResponse,
  BolInfoQuery,
  receivingWorksheetRecordsSelector,
  ReceivingWorksheetTableVariables,
} from './worksheet-table-query';
import { ActiveSort, ActiveSearch, ActiveFilter } from 'client/types';
import { ShippingUnitType, OrderMethod } from 'shared/types';
import { BolId } from 'shared/schemas/bol';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { SaveBolDetailQuantityReceivedResponse, SaveBolDetailQuantityReceivedMutation, SaveBolDetailQuantityReceivedVariables } from 'client/app/transportation/receiving/details/worksheet/worksheet-table/worksheet-table-mutation';
import { WithMutationStatusProps } from 'client/app/transportation/receiving/details/worksheet/mutation-status';
import { WithWorksheetTableSorting } from 'client/app/transportation/receiving/details/worksheet/worksheet-table/worksheet-table-sort';
import { showAutoCartTrackingWarning } from 'shared/helpers/cart-tracking-enabled';
import { WithReceivingWorksheetColumns } from 'client/app/transportation/receiving/details/worksheet/worksheet-table/receiving-worksheet-table-columns';
import { SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { buildTableStateModule } from 'client/state/tables';

const tableName = TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME;

interface OwnProps {
  receivableOrderId: number;
  requestedBolId?: BolId;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

interface DispatchProps {
  onSaveQuantityReceived(receivableOrderProductId: number, bolId: BolId, packOrShippingUnitQuantity: number): Promise<void>;
}

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state, ownProps: OwnProps): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

function mapDispatchToProps(dispatch, props: OwnProps & WithSaveBolDetailQuantityReceivedProps): DispatchProps {
  return {
    onSaveQuantityReceived: async (receivableOrderProductId, bolId, packOrShippingUnitQuantity) => {
      await props.saveBolDetailQuantityReceived({
        bolId,
        receivableOrderProductId,
        packOrShippingUnitQuantity,
      });
    },
  };
}

interface WithSearchableFieldsProps {
  searchableFields: ReceivingWorksheetTableComponentProps['searchableFields'];
}

const WithFilterOptions = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    availableFilters: [],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

const WithSearchableFields = WrappedComponent => props => {
  const updatedProps = {
    ...props,
    searchableFields: [{ id: 'productDescription', name: 'Description' }],
  };
  return propToComponent(WrappedComponent, updatedProps);
};

interface WithWorksheetProps {
  tableRows?: ReceivingWorksheetTableComponentProps['tableRows'];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
}

const buildTableRows = (data: ReceivingWorksheetTableResponse): ReceivingWorksheetTableRow[] =>
  receivingWorksheetRecordsSelector(data);

const WithWorksheetRows = msyncQuery<ReceivingWorksheetTableResponse, OwnProps & StateProps & WithTableParentProps, WithWorksheetProps, ReceivingWorksheetTableVariables>(ReceivingWorksheetTableQuery, {
  alias: 'withReceivingRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage || _.isNil(ownProps.requestedBolId);
  },
  options(ownProps) {
    return {
      variables: {
        id: ownProps.receivableOrderId,
        filters: ownProps.activeFilters,
        search: ownProps.activeSearch,
        bolId: ownProps.requestedBolId!, // This is OK because the skip checks for the presence of the ID above
      },
    };
  },
  props(props): WithWorksheetProps {
    const { data, ownProps } = props;

    const orderIdentifier = (data.response && data.response.identifier) || '';

    return {
      tableRows: buildTableRows(data),
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.response ? data.response.ids : [],
      totalCount: data.response ? data.response.totalCount : 0,
      totalUnfilteredCount: data.response ? data.response.totalUnfilteredCount : 0,
      dataRequest: {
        query: ReceivingWorksheetTableQuery,
        workbookName: `${orderIdentifier} - Receiving Worksheet`,
        variables: {
          id: ownProps.receivableOrderId,
          filters: ownProps.activeFilters,
          search: ownProps.activeSearch,
          bolId: ownProps.requestedBolId,
        },
      },
    };
  },
});

interface WithReceivableOrderInfo {
  shippingUnitType?: ShippingUnitType;
  orderMethod?: OrderMethod;
}

const withReceivableOrderInfo = msyncQuery<ReceivableOrderInfoResponse, OwnProps & StateProps & WithTableParentProps, WithReceivableOrderInfo, {}>(ReceivableOrderInfoQuery, {
  alias: 'withReceivableOrderInfo',
  skip(ownProps) {
    return isNil(ownProps.receivableOrderId);
  },
  options(ownProps): { variables } {
    return {
      variables: {
        receivableOrderId: ownProps.receivableOrderId,
      },
    };
  },
  props({ data }): WithReceivableOrderInfo {
    return {
      shippingUnitType: data.receivableOrder ? data.receivableOrder.shippingUnitType : undefined,
      orderMethod: data.receivableOrder ? data.receivableOrder.orderMethod : undefined,
    };
  },
});

interface WithBolInfo {
  bolTitle?: string;
  showAutoCartTrackingWarning: boolean;
}

const withBolInfo = msyncQuery<BolInfoResponse, OwnProps & StateProps & WithTableParentProps, WithBolInfo, {}>(BolInfoQuery, {
  alias: 'withBolInfo',
  skip(ownProps) {
    return isNil(ownProps.requestedBolId);
  },
  options(ownProps): { variables } {
    return {
      variables: {
        receivableOrderId: ownProps.receivableOrderId,
        bolId: ownProps.requestedBolId,
      },
    };
  },
  props({ data }): WithBolInfo {
    if (!data.receivableOrder) {
      return {
        bolTitle: undefined,
        showAutoCartTrackingWarning: false,
      };
    }
    const showWarning = showAutoCartTrackingWarning({
      autoCartTrackingEnabled: data.receivableOrder.bol.autoCartTrackingEnabled,
      shippingUnitType: data.receivableOrder.shippingUnitType,
      orderMethod: data.receivableOrder.orderMethod,
      supplierCartTrackingStatus: data.receivableOrder.supplier ? data.receivableOrder.supplier.cartTrackingStatus : SupplierCartTrackingStatus.DoNotTrack,
    });

    return {
      bolTitle: `BOL ${data.receivableOrder.bol.identifier}`,
      showAutoCartTrackingWarning: showWarning,
    };
  },
});

interface WithSaveBolDetailQuantityReceivedProps {
  saveBolDetailQuantityReceived(input: SaveBolDetailQuantityReceivedVariables);
}

const WithSaveBolDetailQuantityReceivedBol = msyncMutation<SaveBolDetailQuantityReceivedResponse, OwnProps & StateProps & WithMutationStatusProps, WithSaveBolDetailQuantityReceivedProps>(SaveBolDetailQuantityReceivedMutation, {
  props: ({ mutate }): WithSaveBolDetailQuantityReceivedProps => {
    return {
      saveBolDetailQuantityReceived: async input => {
        return mutate({
          variables: input,
        });
      },
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithSaveBolDetailQuantityReceivedProps &
  WithWorksheetProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithReceivableOrderInfo &
  WithBolInfo &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  WithFilterOptions,
  WithSearchableFields,
  WithSaveBolDetailQuantityReceivedBol,
  connect<StateProps, {}, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(tableName),
  WithWorksheetRows,
  withReceivableOrderInfo,
  withBolInfo,
  WithReceivingWorksheetColumns,
  WithWorksheetTableSorting,
)(ReceivingWorksheetTable) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
