import * as React from 'react';

interface Props {
  filename: string;
  url: string;
}

export const DownloadAttachmentCell = (props: Props) => {
  const url = `${props.url}?inline=true`;

  return (
    <div className="table-identifier-cell">
      <div className="new-window-link">
        <a href={url} target="_new"><i className="fa fa-external-link"></i></a>
      </div>
      <div className="same-window-link">
        <a href={url}>{props.filename}</a>
      </div>
    </div>
  );
};
