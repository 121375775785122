import * as React from 'react';
import { ComponentType, forwardRef } from 'react';
import * as _ from 'lodash';

/** Stolen from react-redux types */
export type MyGetProps<C> = C extends React.ComponentType<infer P> ? P : never;

/** Convenience function when you want to prop things down to a component from a .ts file you can call this. */
export const propToComponent = <P extends {}>(Wrapped: React.ComponentType<P>, p: P) => <Wrapped {...p }/>;

/** Gives us a HOC wrapping the given component, which just does lodash.flowRight on a list of later-provided HOCs */
export const wrapComponent = <C extends React.ComponentType<unknown>, RequiredProps extends MyGetProps<C>>(Wrapped: C) =>
  /** a bit muddy here - what we really want to do with this is specify props that are injected, and confirm that the intersection of injected and user-provided props satisfy the RequiredProps of the inner Wrapped component */
  <UserProvidedProps, _TCombinedHocProps extends UserProvidedProps & RequiredProps>(...hocs: shame[]): React.ComponentType<UserProvidedProps> =>
    _.flowRight(...hocs)(Wrapped);

/** Creates a HOC that spreads the `injectedProps` just before props actually provided the HOC's resulting wrapper component. */
export const withPropsInjector = <TInjectedProps extends {}>(injectedProps: TInjectedProps) =>
  <TProps extends {}>(WrappedComponent: React.ComponentType<TProps>): React.ComponentType<TProps & TInjectedProps> =>
    (props: TProps & TInjectedProps) => // TODO this signature looks wrong.  Wrapper component should ALLOW but nor REQUIRE props that are injected.  If given to wrapper, they override the injected props.
      (<WrappedComponent {...({ ...injectedProps, ...props })} />);

// export type ComponentDecorator<TOriginalProps, TOwnProps> =
  // (component: React.ComponentClass<TOriginalProps> | React.StatelessComponent<TOriginalProps>) => React.ComponentClass<TOwnProps>;

export type ComponentDecorator<TOriginalProps, TOwnProps> =
  (props: TOriginalProps & { children?: React.ReactNode; }, context?: any) => React.ReactElement<TOwnProps>;

export type Hoc<TUpstreamProps, TDownstreamProps> = (componentToWrap: ComponentDecorator<TUpstreamProps, any>) => ComponentDecorator<TDownstreamProps, any>;
export type Subset<TUpstreamProps, TDownstreamProps> = (componentToWrap: ComponentDecorator<TUpstreamProps & Dictionary<any>, any>) => ComponentDecorator<TDownstreamProps & Dictionary<any>, any>;
export type Simple<T> = Hoc<T, T>;
export type Agnostic = Simple<any>;

const enforcePropName = <V extends unknown, K extends string>(v: V, fallbackPropName: K, propName?: K) => _.isObject(v) && (!propName || propName in (v as SimpleObject)) ? v : ({[propName || fallbackPropName || 'hookAsHocProps']: v});

// https://blog.logrocket.com/how-to-migrate-from-hocs-to-hooks-d0f7675fd600/
// https://github.com/ricokahler/hocify/blob/main/index.js
export const asHoc = <UP extends {}, Args extends unknown[], Injected extends {}, F extends (...args: Args) => Injected>(useHook: F, argsFromUpstreamProps?: (p: UP) => Args, propNameForScalar?: string) =>
  <P extends UP>(Wrapped: ComponentType<P & Injected>) =>
    Object.assign(forwardRef((p: P, ref) => {
      const args = argsFromUpstreamProps ? argsFromUpstreamProps(p) : [] as unknown as Args;
      const injectedProps = enforcePropName(useHook(...args), `${_.startCase(useHook.name)}Result`, propNameForScalar) as Injected;
      return <Wrapped {...({...p, ...injectedProps})} ref={ref}/>;
    }), {displayName: `${Wrapped.displayName || Wrapped.name || 'WrappedComponent'}With${_.startCase(useHook.name)}`});

/**
 * Console emits the given comment, and whetever props are passing from (hoc) parent to (wrapped) child component.
 * VERY useful tool for figuring out wtf is happening in a flowRight or whatever.
 * @example
 * ```typescript
 * const Page = _.flowRight(
 *   debugPropsHoc('0'),
 *   clearStore,
 *   debugPropsHoc('store cleared'),
 *   tableParentHoc(),
 *   debugPropsHoc('table parented'),
 *   dataContainer({
 *     hardDeleteRecords,
 *     table: schemaName,
 *     columns: config ? config.columns : undefined,
 *     excelColumns: config ? config.excelColumns : undefined,
 *     filters: config ? config.filters : undefined,
 *     maxRecordsToDelete: config ? config.maxRecordsToDelete : undefined,
 *   }),
 *   debugPropsHoc('all of it'),
 *   Z => p => {console.info({hocName: 'withAdminListPageHOCs', p}); return <Z {...p}/>; },
 * )(AdminListPage) as FunctionComponent<{
 *   schema: string,
 *   hardDeleteRecords?: boolean,
 *   title?: string,
 *   onNewClicked: OnNewClicked,
 *   onRowSelect: OnRowSelect,
 * } & Partial<Arg1<typeof AdminListPage>>>;
 * ```
 */
export const debugPropsHoc = (comment: string) => <P extends {}>(C: React.ComponentType<P>) => (p: P) => { console.info({debugPropsHoc: comment, p}); return <C {...p}/>; };
