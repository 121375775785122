import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { Modal } from 'client/components/third-party';
import * as React from 'react';

export interface ComponentProps {
  invoiceIdentifiersToBeSentToAcumatica: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersAlreadySentToAcumatica: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatNeedToBeEdiInvoicedFirst: Array<{ id: number, identifier: string }>;
  invoiceIdentifiersThatCannotBeSent: Array<{ id: number, identifier: string }>;
  handleCancelButtonClicked(): void;
  handleSendInvoicesToAcumaticaButtonClicked(invoiceIdsToBeSent: Array<{ id: number, identifier: string }>): void;
}
const InvoiceListItemComponent = (invoice: { id: number, identifier: string }) =>
  (<li key={invoice.identifier}>{invoice.identifier}</li>);

export class SendInvoicesToAcumaticaModal extends React.PureComponent<ComponentProps, {}> {
  handleSendInvoicesToAcumaticaButtonClicked = () => {
    this.props.handleSendInvoicesToAcumaticaButtonClicked(this.props.invoiceIdentifiersToBeSentToAcumatica);
  }

  render() {
    return (
      <Modal
        id="send-invoices-to-acumatica-modal"
        data-testid="send-invoices-to-acumatica-modal"
        animation
        show
        backdrop="static"
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="send-invoices-to-acumatica-modal-container"
        className="send-invoices-to-acumatica-modal">
        <GlobalAsyncModalHeader>Send Invoices to Acumatica</GlobalAsyncModalHeader>
        <Modal.Body>
          <div>
            {this.props.invoiceIdentifiersToBeSentToAcumatica.length > 0 &&
              <div>
                These invoices will be sent to Acumatica:
              <ul data-testid="invoices-to-be-sent">
                  {this.props.invoiceIdentifiersToBeSentToAcumatica.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
            {this.props.invoiceIdentifiersThatNeedToBeEdiInvoicedFirst.length > 0 &&
              <div>
                These invoices need to be invoiced via EDI first before sending to Acumatica:
              <ul data-testid="invoices-to-be-sent">
                  {this.props.invoiceIdentifiersThatNeedToBeEdiInvoicedFirst.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
            {this.props.invoiceIdentifiersAlreadySentToAcumatica.length > 0 &&
              <div>
                These invoices were already sent to Acumatica and won't be sent again:
              <ul data-testid="invoices-already-sent">
                  {this.props.invoiceIdentifiersAlreadySentToAcumatica.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
            {this.props.invoiceIdentifiersThatCannotBeSent.length > 0 &&
              <div>
                These invoices cannot be sent to Acumatica because the invoice type does not support it:
              <ul data-testid="invoices-cannot-be-sent">
                  {this.props.invoiceIdentifiersThatCannotBeSent.map(InvoiceListItemComponent)}
                </ul>
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="send-invoices-to-acumatica-cancel-button"
          />
          <GlobalSaveButton
            label="Send Invoices to Acumatica"
            testid="send-invoices-to-acumatica-modal-send-button"
            onClick={this.handleSendInvoicesToAcumaticaButtonClicked}
            classNames={['mfc-button-primary']}
            disabled={this.props.invoiceIdentifiersToBeSentToAcumatica.length === 0}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
