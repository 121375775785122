import { LoadType, Carrier, RouteType } from 'shared/types';
import { MileageRateTableRow, TailgateFeeTableRow, DropRateTableRow } from 'schema/routing-load/routing-load-graphql-types';
import { sortBy } from 'lodash';

export const findMileageRateRow = (args: {
  carrier: Carrier | null,
  loadType: LoadType | null,
  routeType: RouteType | null,
  totalMiles: number,
  mileageRateTable: MileageRateTableRow[] | null,
}): MileageRateTableRow | undefined => {
  if (!args.mileageRateTable || !args.mileageRateTable.length) {
    return;
  }

  const rateInfos = args.mileageRateTable.filter(r => r.carrier === args.carrier && r.loadType === args.loadType && r.routeType === args.routeType);

  // Sorting the rates in reverse order by startMiles, then finding the first one
  // where the total miles is greater than or equal to the start miles.
  const sortedRateInfos = sortBy(rateInfos, 'startMiles').reverse();
  const rateInfo = sortedRateInfos.find(ri => args.totalMiles >= ri.startMiles);

  return rateInfo;
};

export const findTailgateFeeRow = (args: {
  carrier: Carrier | null,
  loadType: LoadType | null,
  tailgateFeeTable: TailgateFeeTableRow[] | null,
}): TailgateFeeTableRow | undefined => {
  if (!args.tailgateFeeTable || !args.tailgateFeeTable.length) {
    return;
  }

  const tailgateFeeTableRow = (args.tailgateFeeTable || []).filter(r => r.carrier === args.carrier && r.loadType === args.loadType);
  if (tailgateFeeTableRow.length !== 1) {
    return;
  }

  return tailgateFeeTableRow[0];
};

export const findDropRateRow = (args: {
  carrier: Carrier | null,
  loadType: LoadType | null,
  dropRateTable: DropRateTableRow[] | null,
}): DropRateTableRow | undefined => {
  if (!args.dropRateTable || !args.dropRateTable.length) {
    return;
  }

  const dropRateTableRow = (args.dropRateTable || []).filter(r => r.carrier === args.carrier && r.loadType === args.loadType);
  if (dropRateTableRow.length !== 1) {
    return;
  }

  return dropRateTableRow[0];
};
