import * as _ from 'lodash';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as MutationActions from 'client/actions/mutations';
import * as Mutations from './mutations';
import * as Queries from './queries';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportUserParamsTypes from 'shared/types/report-user-params';
import * as SharedHelpers from 'shared/helpers';
import * as SharedTypes from 'shared/types';
import * as Types from './types';
import { reset, change } from 'redux-form';
import { Dispatch } from 'redux';

export type ActionTypes =
  ActionStatusChangedAction |
  AllPresetOptionsLoadedAction |
  ComponentUnloadedAction |
  PresetClickedAction |
  SaveAsPresetModalHiddenAction |
  SaveAsPresetModalShownAction |
  PresetDeleted;

export enum ReportUserParamsActionTypeKeys {
  ActionStatusChanged = 'ReportUserParams/ActionStatusChanged',
  AllPresetOptionsLoaded = 'ReportUserParams/AllPresetOptionsLoaded',
  ComponentUnloaded = 'ReportUserParams/ComponentUnloaded',
  PresetClicked = 'ReportUserParams/PresetClicked',
  SaveAsPresetModalHidden = 'ReportUserParams/SaveAsPresetModalHidden',
  SaveAsPresetModalShown = 'ReportUserParams/SaveAsPresetModalShown',
  PresetDeleted = 'ReportUserParams/PresetDeleted',
}

type AllPresetOptions = Array<{
  id: number;
  reportDefault: boolean;
  userDefault: boolean;
  userId: number | null;
  value: string;
}>;

export interface AllPresetOptionsLoadedAction {
  type: ReportUserParamsActionTypeKeys.AllPresetOptionsLoaded;
  payload: {
    defaultPresetId: number | undefined;
    options: AllPresetOptions;
  };
}

export const allPresetOptionsLoaded = (allPresetOptions: AllPresetOptions, defaultPresetId: number | undefined): AllPresetOptionsLoadedAction => {
  return {
    type: ReportUserParamsActionTypeKeys.AllPresetOptionsLoaded,
    payload: {
      defaultPresetId,
      options: allPresetOptions,
    },
  };
};

export interface ActionStatusChangedAction {
  type: ReportUserParamsActionTypeKeys.ActionStatusChanged;
  payload: MutationActions.MutationStatus;
}

export const actionStatusChanged = (mutationStatus: MutationActions.MutationStatus): ActionStatusChangedAction => {
  return {
    type: ReportUserParamsActionTypeKeys.ActionStatusChanged,
    payload: mutationStatus,
  };
};

export interface SaveAsPresetModalShownAction {
  type: ReportUserParamsActionTypeKeys.SaveAsPresetModalShown;
}

export const saveAsPresetModalShown = (): SaveAsPresetModalShownAction => ({ type: ReportUserParamsActionTypeKeys.SaveAsPresetModalShown });

export interface SaveAsPresetModalHiddenAction {
  type: ReportUserParamsActionTypeKeys.SaveAsPresetModalHidden;
}

export const onSaveAsPresetModalHidden = (): SaveAsPresetModalHiddenAction => ({ type: ReportUserParamsActionTypeKeys.SaveAsPresetModalHidden });

export interface PresetDeleted {
  type: ReportUserParamsActionTypeKeys.PresetDeleted;
  payload: {
    presetId: number;
  };
}

const presetDeleted = (presetId: number) => {
  return {
    type: ReportUserParamsActionTypeKeys.PresetDeleted,
    payload: {
      presetId,
    },
  };
};

export const deletePreset = (mapToReportUserParams: Types.MapToReportUserParams, reportFormName: string, presetId: number): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(actionStatusChanged(MutationActions.MutationStatus.InProgress));

      if (!presetId) {
        throw new Error('Preset ID not specified');
      }

      const reportUserParams = mapToReportUserParams(state);

      await GraphQLMutation.msyncClientMutation<{ response: ReportUserParamsTypes.DeleteReportUserParamsResponse }, ReportUserParamsTypes.DeleteReportUserParamsInput>({
        dispatch,
        mutation: Mutations.deleteReportUserParamsMutation(),
        variables: {
          input: {
            id: presetId,
          },
        },
        refetchQueries: [
          {
            // Easiest way to remove the deleted preset from the list
            query: Queries.AllPresetsQuery,
            variables: {
              input: {
                reportType: reportUserParams.reportType,
              },
            },
          },
        ],
      });

      // Clear out the "selectedPresetId" from redux state
      dispatch(presetDeleted(presetId));

      // Clear the preset ID out of the form data
      dispatch(change(reportFormName, 'id', null));

      // Set a flag so things realize the preset has been deleted
      dispatch(change(reportFormName, 'isDeleted', true));

      dispatch(actionStatusChanged(MutationActions.MutationStatus.Complete));
    } finally {
      await SharedHelpers.timeout(500);

      dispatch(actionStatusChanged(MutationActions.MutationStatus.Initial));
    }
  };
};

const onSetAsDefaultClicked = async (dispatch: Dispatch<any>, getState: shame, mapToReportUserParams: Types.MapToReportUserParams, presetId: number | undefined) => {
  const state = getState();

  try {
    dispatch(actionStatusChanged(MutationActions.MutationStatus.InProgress));

    if (!presetId) {
      throw new Error('Preset ID not specified');
    }
    const reportUserParams = mapToReportUserParams(state);

    await GraphQLMutation.msyncClientMutation<{ response: ReportUserParamsTypes.SetReportUserParamsDefaultResponse }, ReportUserParamsTypes.SetReportUserParamsDefaultInput>({
      dispatch,
      mutation: Mutations.setReportUserParamsDefaultMutation(),
      variables: {
        input: {
          id: presetId,
        },
      },
      refetchQueries: [
        {
          // Might be able to avoid this re-query if the mutation returned back a preset with the
          // updated info. Saving that for another day.
          query: Queries.AllPresetsQuery,
          variables: {
            input: {
              reportType: reportUserParams.reportType,
            },
          },
        },
      ],
    });
    dispatch(actionStatusChanged(MutationActions.MutationStatus.Complete));
  } finally {
    await SharedHelpers.timeout(500);
    dispatch(actionStatusChanged(MutationActions.MutationStatus.Initial));
  }
};

export const savePreset = (mapToReportUserParams: Types.MapToReportUserParams, name?: string, presetId?: number | undefined): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    const state = getState();
    const reportUserParams = mapToReportUserParams(state);
    const newPreset = _.isNil(presetId);

    try {
      dispatch(actionStatusChanged(MutationActions.MutationStatus.InProgress));

      if (newPreset) {
        if (!name) {
          throw new Error('Preset name not provided');
        }

        const response = await GraphQLMutation.msyncClientMutation<{ response: ReportUserParamsTypes.CreateReportUserParamsResponse }, ReportUserParamsTypes.CreateReportUserParamsInput>({
          dispatch,
          mutation: Mutations.createReportUserParamsMutation(),
          variables: {
            input: {
              name,
              reportType: reportUserParams.reportType,
              reportUserParams: _.omit(reportUserParams.params, 'id', 'isDeleted'),
            },
          },
          refetchQueries: [
            {
              query: Queries.AllPresetsQuery,
              variables: {
                input: {
                  reportType: reportUserParams.reportType,
                },
              },
            },
          ],
        });

        const id = response.data.response.reportPreset.id;
        dispatch(presetClicked(id));
      } else {

        if (!presetId) {
          throw new Error('Preset ID not provided');
        }

        await GraphQLMutation.msyncClientMutation<{ response: ReportUserParamsTypes.UpdateReportUserParamsResponse }, ReportUserParamsTypes.UpdateReportUserParamsInput>({
          dispatch,
          mutation: Mutations.updateReportUserParamsMutation(),
          variables: {
            input: {
              id: presetId,
              reportType: reportUserParams.reportType,
              reportUserParams: _.omit(reportUserParams.params, 'id', 'isDeleted'),
            },
          },
        });
      }

      dispatch(actionStatusChanged(MutationActions.MutationStatus.Complete));
    } finally {
      await SharedHelpers.timeout(500);
      dispatch(actionStatusChanged(MutationActions.MutationStatus.Initial));
    }
  };
};

export interface PresetClickedAction {
  type: ReportUserParamsActionTypeKeys.PresetClicked;
  payload: {
    presetId: number;
  };
}

export const presetClicked = (presetId: number): PresetClickedAction => {
  return {
    type: ReportUserParamsActionTypeKeys.PresetClicked,
    payload: {
      presetId,
    },
  };
};

export const onPresetClicked = (id: number, reportFormName: string): ReduxTypes.Thunker => {
  return async dispatch => {
    // Set a flag so the newly selected preset will be properly loaded in the case where one was just deleted
    dispatch(change(reportFormName, 'isDeleted', false));

    dispatch(presetClicked(id));
  };
};

export const onActionTaken = (reportType: SharedTypes.ReportTypes, presetId: number | undefined, action: Types.PresetActionTypes, mapToReportUserParams: Types.MapToReportUserParams, mapFromReportUserParams: Types.MapFromReportUserParams, reportFormName: string): ReduxTypes.Thunker => {
  return async (dispatch: Dispatch<any>, getState) => {

    switch (action) {
      case Types.PresetActionTypes.Delete: {
        if (presetId) {
          dispatch(deletePreset(mapToReportUserParams, reportFormName, presetId));
        }
        break;
      }

      case Types.PresetActionTypes.Reload: {
        if (!presetId) {
          throw new Error('Preset ID not specified');
        }

        dispatch(reset(reportFormName));
        break;
      }

      case Types.PresetActionTypes.SaveAs: {
        dispatch(saveAsPresetModalShown());
        break;
      }

      case Types.PresetActionTypes.Save: {
        if (!presetId) {
          dispatch(saveAsPresetModalShown());
        } else {
          dispatch(savePreset(mapToReportUserParams, undefined, presetId));
        }

        break;
      }

      case Types.PresetActionTypes.SetAsDefault: {
        await onSetAsDefaultClicked(dispatch, getState, mapToReportUserParams, presetId);
        break;
      }
    }
  };
};

export const onModalSaveButtonClicked = (reportType: SharedTypes.ReportTypes, mapToReportUserParams: Types.MapToReportUserParams, name: string) => {
  return async dispatch => {
    // Always want to create a new preset from here so don't pass a presetId (as the last argument)
    dispatch(savePreset(mapToReportUserParams, name));
    dispatch(onSaveAsPresetModalHidden());
  };
};

export interface ComponentUnloadedAction {
  type: ReportUserParamsActionTypeKeys.ComponentUnloaded;
}

export const componentUnloaded = (): ComponentUnloadedAction => {
  return {
    type: ReportUserParamsActionTypeKeys.ComponentUnloaded,
  };
};

export const onComponentUnloaded = (reportType: SharedTypes.ReportTypes) => {
  return {
    type: ReportUserParamsActionTypeKeys.ComponentUnloaded,
  };
};
