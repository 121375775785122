import * as Validators from 'shared/validators';
import { tableName, property, definePresentation, belongsTo, required, defineFieldValidators } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { ImportableDocument } from 'shared/schemas/importable-document';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';

export enum ImportJobStatus {
  importing = 'importing',
  failed = 'failed',
  imported = 'imported',
}

@tableName('importJobs')
export class ImportJob implements IRecord {
  id?: number;

  @property @required status: ImportJobStatus;
  @property messages?: string[] | null;

  @belongsTo('importableDocuments')
  @property importableDocument?: ImportableDocument;
  importableDocumentId: number;
}

defineFieldValidators(ImportJob, {
  status: [Validators.INCLUSION(ImportJobStatus)],
});

definePresentation(ImportJob, {
  status: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  messages: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  importableDocument: { formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN) },
});
