import { tableName, property, setSchemaOptions, definePresentation } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { DISPLAY_TYPES, TYPES, ReceivableOrderReceivingStatus } from 'shared/types';
import { displayType } from './dsl';

@tableName('receivableOrders')
export class ReceivableOrder implements IRecord {
  id?: number;
  @property receivingStatus: ReceivableOrderReceivingStatus;
  @property identifier: string;
  @property supplierLocationIdentifier: string | null;
}

definePresentation(ReceivableOrder, {
  receivingStatus: {
    sortable: true,
    type: TYPES.STRING,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
  },
  identifier: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => /*sql*/`(
        SELECT
          customer_orders.identifier
        FROM
          customer_orders
        WHERE
          customer_orders.receivable_order_id = receivable_orders.id
        UNION
        SELECT
          supplier_orders.identifier
        FROM
          supplier_orders
        WHERE
          supplier_orders.receivable_order_id = receivable_orders.id
      )`,
    },
  },
  supplierLocationIdentifier: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => /*sql*/`(
        SELECT supplier_locations.identifier
        FROM supplier_orders
        LEFT JOIN supplier_locations on supplier_locations.id = supplier_orders.supplier_location_id and supplier_orders.delivery_method = 'Pick Up'
        WHERE supplier_orders.receivable_order_id = receivable_orders.id
        LIMIT 1
      )`,
    },
  },
});

setSchemaOptions(ReceivableOrder, {
  hasTimestamps: false,
});
