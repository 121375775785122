import * as React from 'react';
import { ControlLabel, FormControl, HelpBlock } from 'client/components/third-party';
import { HorizontalLayout } from './layouts';
import { StackedLayout } from './layouts';
import { INPUT_TYPES } from 'shared/types';
import * as classnames from 'classnames';
import * as _ from 'lodash';

export const LabeledInput = (p: {
  input?: { onChange?: (args: shame) => void }
  type: INPUT_TYPES,
  step?: number,
  label: string,
  placeholder?: string,
  disabled?: boolean,
  testid?: string,
  labelColSize: number,
  inputColSize: number,
  offset: number,
  horizontalLabel?: boolean,
  handleChange: (args: shame) => void,
  required?: boolean,
  hideOptionalLabel?: boolean,
  autoFocus?: boolean,
  money?: boolean,
  tabIndex?: number,
  min?: number,
  max?: number,
  alwaysShowErrors?: boolean,
  meta: { touched: boolean, error: string, submitting: boolean }, // comes from redux-form
}) => {
  const inputProps = p.input || { onChange: _.noop };
  const handleOnChange = p.handleChange ? p.handleChange : inputProps.onChange;
  const disabledAttributes = p.disabled ? { disabled: p.disabled, tabIndex: -1 } : {};
  const additionalProps = p.type !== INPUT_TYPES.NUMBER ? {} : { min: p.min, max: p.max, step: p.step };
  const labelClassName = classnames({
    required: p.required,
    optional: !p.required && !p.hideOptionalLabel,
    disabled: p.disabled,
  });

  const formControl = p.type === INPUT_TYPES.TEXTAREA
    ? <FormControl
        {...disabledAttributes}
        {...inputProps}
        autoFocus={p.autoFocus}
        componentClass={INPUT_TYPES.TEXTAREA}
        data-testid={p.testid}
        onChange={handleOnChange}
        placeholder={p.placeholder}
        rows={7}
        tabIndex={p.tabIndex}
      />
    : <FormControl
        {...additionalProps}
        {...disabledAttributes}
        {...inputProps}
        autoFocus={p.autoFocus}
        data-testid={p.testid}
        onChange={handleOnChange}
        placeholder={p.placeholder}
        tabIndex={p.tabIndex}
        type={p.type}
      />;

  const wrappedFormControl = !p.money ? formControl : (
    <div className="money-input-container">
      <div className="money-input-component">
        {formControl}
      </div>
      <div className="money-input-symbol">
        <span className="fa fa-dollar" />
      </div>
    </div>
  );

  const shouldDisplayError = !p.meta.submitting && p.meta.error && (p.meta.touched || p.alwaysShowErrors);

  // We want to default this to be horizontal, so unless explicitly specified to be false, use the horizontal layout
  return p.horizontalLabel !== false
    ? <HorizontalLayout labelComponent={ControlLabel} labelColSize={p.labelColSize} offset={p.offset} label={p.label} inputColSize={p.inputColSize} classNames={[labelClassName]}>
        {wrappedFormControl}
        {p.meta.touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{p.meta.error}</HelpBlock>}
      </HorizontalLayout>
    : <StackedLayout inputColSize={p.inputColSize} offset={p.offset} label={p.label} classNames={[labelClassName]}>
        {wrappedFormControl}
        {p.meta.touched && <FormControl.Feedback />}
        {shouldDisplayError && <HelpBlock className="validation-error">{p.meta.error}</HelpBlock>}
      </StackedLayout>;
};
