import * as React from 'react';

function Logo() {
  return (
    <div className="navbar-brand mfc-msync-title">
      M SYNC
    </div>
  );
}

export default Logo;
