import * as _ from 'lodash';
import { DocumentNode } from 'graphql';
import { ChildProps, graphql, OperationVariables, ChildMutateProps, BaseMutationOptions, OptionProps, MutationFunctionOptions, MutationFetchResult } from 'react-apollo';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'client/actions/error';
import * as MutationActions from 'client/actions/mutations';
import { ServerSideValidationInfo } from 'shared/validators';
import { apolloClient } from 'client/msync-apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {  MutationOptions } from 'apollo-client/core/watchQueryOptions';
import { ApolloClient } from 'apollo-client';
import { Return } from 'shared/types';
import { dispatchGqlError } from './query';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  handleValidationErrors: (validations: ServerSideValidationInfo[]): void => { dispatch(Actions.validationErrorReceived(validations)); },
  handleExpectedError: (args: { message: string; debugInfo: any; }): void => { dispatch(Actions.expectedErrorReceived(args)); },
  handleUnexpectedError: (args: { message: string; }): void => { dispatch(Actions.unexpectedErrorReceived(args)); },
  handleMutationSent: (showBusyModal: boolean, disableGlobalSpinner: boolean) => { dispatch(MutationActions.mutationSent(showBusyModal, disableGlobalSpinner)); },
  handleMutationResponseReceived: (disableGlobalSpinner: boolean) => {
    dispatch(MutationActions.mutationResponseReceived(disableGlobalSpinner));
    setTimeout(() => dispatch(MutationActions.removeRecentlyReceivedMutationResponse()), 1500);
  },
});

/**
 * ![The Great Mutato](https://media.giphy.com/media/f4142H2M70s0w/giphy.gif "The Great Mutato")
 * Tunkable
 */
export const msyncClientMutation = async <T, TVariables = OperationVariables>(args: MutationOptions<T, TVariables> & {
  dispatch: Dispatch<any>,
  client?: ApolloClient<NormalizedCacheObject>,
  showBusyModal?: boolean,
  suppressErrorModal?: boolean,
  disableGlobalSpinner?: boolean,
}): Promise<{ data: T }> => {
  const handlers = mapDispatchToProps(args.dispatch);
  try {
    handlers.handleMutationSent(!!args.showBusyModal, !!args.disableGlobalSpinner);
    const result = await (args.client || apolloClient()).mutate<T, TVariables>(args);
    handlers.handleMutationResponseReceived(!!args.disableGlobalSpinner);
    return result as shame<'TODO: use satisfies keyword when it becomes available'>;
  } catch (error) {
    handlers.handleMutationResponseReceived(!!args.disableGlobalSpinner);
    if (!args.suppressErrorModal)
      dispatchGqlError(error, handlers, 'During MUTATION - msyncClientMutation');

    throw error;
  }
};

interface OwnProps { confirmOkToSave?: () => Promise<boolean>; }
type DispatchProps = Return<typeof mapDispatchToProps>;

// Override the Apollo mutation function to allow undefined to be returned, which could happen if the user chooses not to save changes after approval
type MsyncMutationFunction<TData = any, TVariables = OperationVariables> = (options?: MutationFunctionOptions<TData, TVariables>) => Promise<MutationFetchResult<TData> | undefined>;

// Convert the provided OptionProps into something that's mutation sepcific (instead of either query or mutation)
export type MsyncMutationProps<TProps = any, TData = any, TGraphQLVariables = OperationVariables> =
  Required<Pick<OptionProps<TProps, TData, TGraphQLVariables>, 'result' | 'ownProps'>> & { mutate: MsyncMutationFunction<TData, TGraphQLVariables>};

interface MsyncMutationOptions<TProps, TData, TGraphQLVariables = OperationVariables, TChildProps = ChildProps<TProps, TData, TGraphQLVariables>> {
  // The next new lines are nearly a straight copy from the definition of the OperationOption type in react-apollo. A coupke of minor modifications are made to make this mutation specific (instead of mutation or query)
  options?: BaseMutationOptions<TData, TGraphQLVariables> | ((props: TProps) => | BaseMutationOptions<TData, TGraphQLVariables>);
  props?: (props: MsyncMutationProps<TProps, TData, TGraphQLVariables>, lastProps?: TChildProps | void) => TChildProps;
  skip?: boolean | ((props: any) => boolean);
  name?: string;
  withRef?: boolean;
  shouldResubscribe?: (props: TProps, nextProps: TProps) => boolean;
  alias?: string;

  // m-sync specific/custom stuff
  skipSaveConfirmationDialog?: boolean;
  skipMutationStatusChanges?: boolean;
  showBusyModal?: boolean;
  disableGlobalSpinner?: boolean;
}

export const msyncMutation = <
  TData extends SimpleObject = SimpleObject,
  TProps extends TGraphQLVariables | SimpleObject = SimpleObject,
  TChildProps = ChildMutateProps<TData>,
  TGraphQLVariables = SimpleObject,
>(
  document: DocumentNode,
  operationOptions: MsyncMutationOptions<TProps, TData, TGraphQLVariables, TChildProps> = {},
) : ( (WrappedComponent: React.ComponentType<TChildProps & TProps>) => React.ComponentClass<TProps> ) => {
  const originalPropsFunc = operationOptions?.props;
  let realMutate: any;
  const mutate = async (p: BaseMutationOptions<TData, TGraphQLVariables>) => await realMutate?.(p);
  return component => _.flowRight(
    connect<undefined, Return<typeof mapDispatchToProps>, { confirmOkToSave?: () => Promise<boolean> }>(undefined, mapDispatchToProps),
    graphql(document, !originalPropsFunc ? operationOptions as shame : { ...operationOptions, props: ( (p: { ownProps: OwnProps & TProps & DispatchProps, mutate: MsyncMutationFunction<TData, TGraphQLVariables>, result: MsyncMutationProps<TProps, TData, TGraphQLVariables>['result']; }) => {
      const originalMutate = p.mutate;
      realMutate = async (opts: BaseMutationOptions<TData, TGraphQLVariables>) => {
        if (p.ownProps.confirmOkToSave && !operationOptions.skipSaveConfirmationDialog && !await p.ownProps.confirmOkToSave())
          return undefined;

        if (!operationOptions.skipMutationStatusChanges)
          p.ownProps.handleMutationSent(!!operationOptions.showBusyModal, !!operationOptions.disableGlobalSpinner);

        try {
          // console.log('MUTATE:', document.loc?.source.body, opts);
          const response = await originalMutate(opts);
          if (!operationOptions.skipMutationStatusChanges)
            p.ownProps.handleMutationResponseReceived(!!operationOptions.disableGlobalSpinner);

          return response;
        } catch (err) {
          dispatchGqlError(err, p.ownProps, 'During MUTATE:', p, document, {queryOptions: operationOptions});
          if (!operationOptions.skipMutationStatusChanges)
            p.ownProps.handleMutationResponseReceived(!!operationOptions.disableGlobalSpinner);

          throw err;
        }
      };

      return originalPropsFunc(Object.assign(p, {mutate})); // really trying to keep things from re-rendering unecessarily here.
    } ) as shame }),
  )(component);
};
