import * as React from 'react';
import { RecordNavigationBar } from 'client/app/transportation/receiving/details/receiving-details-navigation-bar/record-navigation-bar';
import { RecordNavItem } from 'client/components/record-nav';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';

interface OwnProps {
  routePlanId?: RoutePlanId;
}

export const RoutePlanDetailsNavigationBar: React.SFC<OwnProps> = props => {
  const baseUrl = `/transportation/routing/details/${props.routePlanId}`;

  return (
    <RecordNavigationBar>
      {!props.routePlanId && <RecordNavItem disabled={true} label="1. Attach Orders" key="attach-orders" route={`/transportation/routing/create`} testid="navbar-attach-orders" />}
      {props.routePlanId && <RecordNavItem label="1. Attach Orders" key="attach-orders" route={`${baseUrl}/attach-orders`} testid="navbar-attach-orders" />}
      <RecordNavItem disabled={!props.routePlanId} label="2. Review Stores" key="review-stores" route={`${baseUrl}/review-stores`} testid="navbar-review-stores" />
      <RecordNavItem disabled={!props.routePlanId} label="3. Loads" key="loads" route={`${baseUrl}/loads`} testid="navbar-loads" />
      <RecordNavItem disabled={!props.routePlanId} label="4. Printing" key="printing" route={`${baseUrl}/printing`} testid="navbar-printing" />
    </RecordNavigationBar>
  );
};
