// TODO - DVM 2024 07 08 - upgrade this top use Luxon, not moment. And pass Luxon DateTime objects around instead (or at least wrap it and use a STRUCTURED wrapper, instead of strings.) -- see money-stry.ts (to rename to numeric.ts) for inspiration.
import * as moment from 'moment-timezone';
import { toDateStr, DateStr, toDateTimeStr, DateTimeStr } from './types/date-str';
import { TIME_ZONE } from './time-zone';

let timeTravelOffset: number = 0;
let frozenTime: moment.Moment | undefined;

function internalNow(): moment.Moment {
  if (frozenTime) {
    return frozenTime;
  } else {
    return moment.tz(TIME_ZONE).subtract(timeTravelOffset, 'ms');
  }
}

function now(): DateTimeStr {
  return toDateTimeStr(internalNow());
}

function today(): DateStr {
  return toDateStr(internalNow());
}

async function timeTravel(time: Date | moment.Moment | DateTimeStr | DateStr | string) {
  assertInTest ();
  timeTravelOffset = moment.tz(TIME_ZONE).diff(moment.tz(time, TIME_ZONE));
  frozenTime = undefined;
}

async function restoreTime() {
  assertInTest();
  timeTravelOffset = 0;
  frozenTime = undefined;
}

async function freezeTime(time: Date | moment.Moment | DateStr | DateTimeStr | undefined) {
  assertInTest();
  if (time) {
    await timeTravel(time);
    frozenTime = moment.tz(time, TIME_ZONE);
  } else {
    frozenTime = internalNow();
  }
}

async function unfreezeTime() {
  assertInTest();
  frozenTime = undefined;
}

function assertInTest() {
  if (process.env.NODE_ENV !== 'test') {
    throw new Error('Time travel is only allowed in tests!');
  }
}

export const Clock = {
  now,
  today,

  timeTravel,
  restoreTime,
  freezeTime,
  unfreezeTime,
};
