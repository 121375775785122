import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { CartEstimateReportUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import { Thunker } from 'client/types/redux-types';
import * as Queries from './query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { SelectableValue } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  salesPlanYear: string;
  salesPlanIds: SelectableValue | undefined;
  downloadButtonDisabled: boolean;
  reportDownloadStatus: MutationStatus;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (_.isEmpty(formValues)) {
    return {
      salesPlanYear: '',
      salesPlanIds: undefined,
      downloadButtonDisabled: true,
      reportDownloadStatus: MutationStatus.Initial,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);

  return {
    salesPlanYear: formValues[Constants.FormFields.salesPlanYear],
    salesPlanIds: formValues[Constants.FormFields.salesPlanIds],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(): Thunker;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  ReduxFormProps &
  UserParamsMapperProps &
  Queries.SalesPlanQueryProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: { },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withSalesPlans,
  UserParamsMappers.withCartEstimateReportUserParamMappers,
)(CartEstimateReportUI) as Component<OwnProps>;

export const CartEstimateReportContainer = (props: OwnProps) => propToComponent(component, props);
