import * as React from 'react';
import { RecordBar } from 'client/components/record-bar/record-bar-component';
import { RecordBarDetail } from 'client/components/record-bar';
import { CustomerName } from 'shared/schemas/customer';
import { SupplierName } from 'shared/schemas/supplier';
import { SupplierOrderIdentifier, SupplierOrderPlannedArrivalDate, SupplierOrderId } from 'shared/schemas/supplier-order';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { formatDate } from 'shared/helpers/date-helpers';
import { Button } from 'client/components/third-party';
import { ReceivableOrderReceivingStatus } from 'shared/types';

interface OwnProps {
  receivableOrderIdentifier: SupplierOrderIdentifier;
  supplierName: SupplierName;
  received: ReceivableOrderReceivingStatus;
  customerName: CustomerName;
  mfcAreaIdentifier: MfcAreaIdentifier;
  arrivalDate: SupplierOrderPlannedArrivalDate;
  receivableOrderType: string;
  sourceOrderId: number;
  onBackButtonClicked(): void;
  onCustomerPOClick(supplierOrderId: SupplierOrderId): void;
  onSupplierPOClick(supplierOrderId: SupplierOrderId): void;
}

export const ReceivingDetailsRecordBar: React.SFC<OwnProps> = props => {
  const receivingDetailsRecordBarStatus = (
    <div className="detail">
      <div className="heading"></div>
      <div className="mfc-dropdown btn-group-record-bar-button">
        <div className="dropdown btn-group">
          <Button disabled>
            <span>{props.received}</span>
            <span className="caret"></span>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <RecordBar
      onBackButtonClicked={props.onBackButtonClicked}
      status={receivingDetailsRecordBarStatus}
      saveButtonDisabled>
      {props.receivableOrderType === 'SupplierOrder' &&
        <RecordBarDetail title="Supplier PO" testid="record-bar-supplier-po">
          <a onClick={() => props.onSupplierPOClick(props.sourceOrderId)}>{props.receivableOrderIdentifier}</a>
        </RecordBarDetail>
      }
      {props.receivableOrderType === 'CustomerOrder' &&
        <RecordBarDetail title="Customer PO" testid="record-bar-customer-po">
          <a onClick={() => props.onCustomerPOClick(props.sourceOrderId)}>{props.receivableOrderIdentifier}</a>
        </RecordBarDetail>
      }
      <RecordBarDetail title={props.receivableOrderType === 'CustomerOrder' ? 'Vendor' : 'Supplier'} testid="record-bar-supplier">
        {props.supplierName}
      </RecordBarDetail>
      <RecordBarDetail title="Customer" testid="record-bar-customer">
        {props.customerName}
      </RecordBarDetail>
      {props.mfcAreaIdentifier &&
        <RecordBarDetail title="MFC Area" testid="record-bar-mfc-area">
          {props.mfcAreaIdentifier}
        </RecordBarDetail>
      }
      <RecordBarDetail title="Arrival Date" testid="record-bar-arrival-date">
        {formatDate(props.arrivalDate, 'MM/DD/YYYY')}
      </RecordBarDetail>
    </RecordBar>
  );
};
