import gql from 'graphql-tag';
import { SupplierId, SupplierName, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { DateStr } from 'shared/types';

export interface CartTrackingDetailsRecordBarResponse {
  response?: {
    id: SupplierId;
    name: SupplierName;
    cartTrackingStatus: SupplierCartTrackingStatus;
    lastAdjustmentDate: DateStr;
  };
}

export const CartTrackingDetailsRecordBarQuery = gql`
  query CarTrackingDetailsRecordBarQuery($type: RecordType = Supplier, $id: Int!) {
    response: find(type: $type, id: $id) {
      ... on Supplier {
        id
        name
        cartTrackingStatus
        lastAdjustmentDate
      }
    }
  }
`;
