import { appendFieldValidators } from 'shared/schemas/dsl';
import * as Validators from 'shared/validators';
import { SupplierOrderProduct } from 'shared/schemas/supplier-order-product';

// There are more validators in server/validations
appendFieldValidators(SupplierOrderProduct, {
  retailPrice: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
  cost: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
  additionalCost: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO],
  packsPerShippingUnit: [Validators.GREATER_THAN_ZERO],
  packSize: [Validators.GREATER_THAN_OR_EQUAL_TO_ZERO], // this should be > 0, but to support VBills component/combos, we need to allow it to be 0
  shelvesPerRack: [Validators.GREATER_THAN_ZERO],
  packsPerShelf: [Validators.GREATER_THAN_ZERO],
  sellDays: [Validators.GREATER_THAN_ZERO],
});
