import * as _ from 'lodash';
import { getFormValues } from 'redux-form';

import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import * as ReportSelectors from 'client/state/reports';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getFilterByCategoryOrSalesPlan = (state: State.Type): Constants.FilterByCategoryOrSalesPlan =>
  (getFormValues(Constants.formName)(state) as Constants.FormValues).filterByCategoryOrSalesPlan;

export const getScanProductOptions = (state: State.Type): boolean[] => {
  return ReportSelectors.getScanProductOptions(state, Constants.formName);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const filterByCategoryOrSalesPlan = getFilterByCategoryOrSalesPlan(state);

  const scanProductOptions = getScanProductOptions(state);
  const stores = extractSelectedValues(formValues[Constants.FormFields.storeIds]);

  const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
  if (!dateRangeYearComparison) {
    return true;
  }

  const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
  if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate) {
    return true;
  }

  const beginDate = dateRanges.dateRange.startDate;
  const endDate = dateRanges.dateRange.endDate;

  if (filterByCategoryOrSalesPlan === Constants.FilterByCategoryOrSalesPlan.ByCategory) {
    const products = extractSelectedValues(formValues[Constants.FormFields.productIds]);
    return _.isNil(formValues[Constants.FormFields.customerId]) ||
      _.isNil(beginDate) ||
      _.isNil(endDate) ||
      (_.isNil(products) || products.length === 0) ||
      _.isNil(formValues[Constants.FormFields.sellDepartmentId]) ||
      scanProductOptions.length === 0 ||
      _.isNil(formValues[Constants.FormFields.primaryGlobalAll]) ||
      (_.isNil(stores) || stores.length === 0);
  } else {
    return _.isNil(formValues[Constants.FormFields.customerId]) ||
      _.isNil(beginDate) ||
      _.isNil(endDate) ||
      _.isNil(formValues[Constants.FormFields.salesPlanId]) ||
      _.isNil(formValues[Constants.FormFields.primaryGlobalAll]) ||
      (_.isNil(stores) || stores.length === 0);
  }
};

export const getReportDownloadStatus = (state: State.Type) => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
