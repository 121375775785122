import gql from 'graphql-tag';

export default gql`
  query findSupplierItemForCrud($type: RecordType!, $id: Int!) {
    data: find(type: $type, id: $id) {
      id
      ...SupplierItemFragment
    }
  }
  fragment SupplierItemFragment on SupplierItem {
    updatedAt
    activeStatus
    packSize
    packsPerShelf
    shelvesPerRack
    casesPerPallet
    supplierProdIdentifier
    fobLocation
    notes
    activeCost
    product {
      id
      identifier

      customer {
        id
        identifier
      }

      productSubClass {
        id
        identifier

        productClass {
          id
          identifier

          sellDepartment {
            id
            identifier
          }
        }
      }
    }
    supplier {
      id
      identifier
    }
  }`;
