import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';

import Table from './route-plans-table';
import { propToComponent, withPropsInjector } from 'client/hoc/hoc';
import { TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME } from 'client/constants';
import { MsyncDataRequest, msyncQuery } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableSearchField, AvailableFilter, ActiveFilter, ActiveSearch } from 'client/types';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { GetRoutePlansInput, RoutePlanGQLResult, RoutePlanSearchInput, RoutePlanFilterInput, RoutePlanSortInput } from 'schema/route-plan/route-plan-typescript-types';
import * as _ from 'lodash';
import { GetRoutePlansQuery, RoutePlansQueryResponse, GetRoutePlansExcelQuery } from 'client/app/transportation/routing/route-plans-list/route-plans-query';
import { TYPES, SEARCH_FIELD_ANY } from 'shared/types';
import { mapToFilters, convertToTypedSearchCriteriaForTransit, mapToSort } from 'client/helpers/table-helpers';
import { FetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';

const tableName = TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME;
interface OwnProps {
  tableName: string;
  dataRequest: MsyncDataRequest;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: ActiveFilter[];
  tablePageNumber: number;
}

const TableStateHelpers = buildTableStateModule(tableName);

const mapStateToProps = (state): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

interface WithAvailableFiltersProps {
  availableFilters: AvailableFilter[];
}

interface WithSearchableFieldsProps {
  searchableFields: AvailableSearchField[];
}

const withSearchableFields = withPropsInjector({
  searchableFields: [
    { id: SEARCH_FIELD_ANY   , name: 'Search All'    },
    { id: 'identifier'       , name: 'Route Plan ID' },
    { id: 'customerName'     , name: 'Customer'      },
    { id: 'mfcAreaIdentifier', name: 'MFC Area'      },
  ] as AvailableSearchField[],
});

interface DispatchProps {
  onRowSelect(record): void;
  onNewClicked(): void;
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    onRowSelect(record) {
      dispatch(push(`/transportation/routing/details/${record.id}`));
    },
    onNewClicked() {
      dispatch(push(`/transportation/routing/create`));
    },
  };
};

interface WithRoutePlansRowProps {
  routePlansRows?: RoutePlanGQLResult[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;

  availableFilters: AvailableFilter[];
}

const prependIf = (s: string | undefined | null, prefix: string) => s && s.trim().length > 0 ? prefix + s : s;
const WithRoutePlansRows = msyncQuery<RoutePlansQueryResponse, OwnProps & StateProps & WithTableParentProps & WithSearchableFieldsProps, WithRoutePlansRowProps, GetRoutePlansInput>(GetRoutePlansQuery, {
  alias: 'withRoutePlansRows',
  skip: (ownProps: OwnProps & StateProps & WithTableParentProps) => !ownProps.tableParentInfo.rowsPerPage,
  options: (ownProps): { fetchPolicy: FetchPolicy, variables: GetRoutePlansInput } => ({
    fetchPolicy: 'network-only',
    variables: {
      filters: mapToFilters<RoutePlanFilterInput>(ownProps.activeFilters),
      sort: mapToSort<RoutePlanSortInput>(ownProps.activeSortFields),
      search: convertToTypedSearchCriteriaForTransit<RoutePlanSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
      limit: ownProps.tableParentInfo.rowsPerPage,
      offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
    },
  }),
  props: ({data: {getRoutePlans, ...d}, ownProps}): WithRoutePlansRowProps => ({
    routePlansRows: getRoutePlans?.routePlans ?? [],
    loading: d.loading !== false,
    filteredRecordIds: getRoutePlans?.ids ?? [],
    totalCount: getRoutePlans?.totalCount ?? 0,
    totalUnfilteredCount: getRoutePlans?.totalUnfilteredCount ?? 0,
    dataRequest: {
      ...d,
      workbookName: `RoutePlans${prependIf(_.flatMap(ownProps.activeFilters, x => x.values).join(', '), ' - ')}`,
      query: GetRoutePlansExcelQuery,
      variables: {
        filters: mapToFilters<RoutePlanFilterInput>(ownProps.activeFilters),
        sort: mapToSort<RoutePlanSortInput>(ownProps.activeSortFields),
        search: convertToTypedSearchCriteriaForTransit<RoutePlanSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
      },
    },
    availableFilters: [
      { displayName: 'Customer'     , field: 'customer'         , options: d.getRoutePlansListPageFilterOptions?.customers ?? [] },
      { displayName: 'MFC Area'     , field: 'mfcAreaIdentifier', options: d.getRoutePlansListPageFilterOptions?.mfcAreas ?? []  },
      { displayName: 'Status'       , field: 'status'           , options: d.getRoutePlansListPageFilterOptions?.statuses ?? []  },
      { displayName: 'Delivery Date', field: 'deliveryDate'     , type   : TYPES.DATE                                            },
    ],
  }),
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithRoutePlansRowProps &
  WithFilterAndSortCapabilitiesProps &
  WithSearchableFieldsProps &
  WithAvailableFiltersProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  withSearchableFields,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(tableName),
  WithRoutePlansRows,
)(Table) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
