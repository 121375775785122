import * as React from 'react';
import BolForm from 'client/app/transportation/receiving/details/worksheet/bol-form/bol-form-container';
import { MutationStatus } from 'client/actions/mutations';
import { FormModal } from 'client/components/form-modal';

interface OwnProps {
  isShown: boolean;
  onCancelButtonClicked(): void;
  onModalEnter(): void;
  handleFormSubmit: shame;

  invalid: boolean;
  actionStatus: MutationStatus;
  receivableOrderId: number;
}

export const AddBolModal: React.SFC<OwnProps> = props => {
  if (props.isShown) {
    return (
      <FormModal
        isShown
        cancelDisabled={props.actionStatus === MutationStatus.InProgress} // Rather see this in the container
        onCancelButtonClicked={props.onCancelButtonClicked}
        onModalEnter={props.onModalEnter}
        handleFormSubmit={props.handleFormSubmit}
        invalid={props.invalid}
        submitButtonLabel={'Add'}
        submitButtonTestId={'add-button'}
        modalTitle={'Add BOL'}
        modalId={'add-bol-modal'}
        actionStatus={props.actionStatus}
      >
        <BolForm receivableOrderId={props.receivableOrderId} />
      </FormModal>
    );
  } else {
    return <div/>;
  }
};
