import { Lens } from '@atomic-object/lenses';

interface NavigationState {
  readonly recordBarGoBackToLocation: string;
}

export const InitialState: NavigationState = {
  recordBarGoBackToLocation: '',
};

export type Type = NavigationState;

export const recordBarGoBackToLocation = Lens.from<Type>().prop('recordBarGoBackToLocation');
