import * as React from 'react';

export interface OwnProps {
  handleUnmounted: () => void;
}

class RoutePlanContentUI extends React.PureComponent<OwnProps, {}> {
  componentWillUnmount() {
    this.props.handleUnmounted();
  }

  public render() {
    return <div>{this.props.children}</div>;
  }
}

export default RoutePlanContentUI;
