import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/invoice/invoice-graphql-types';

export interface Input {
  input: GraphQLTypes.AddRoutePlansToInvoiceInput;
}

export interface Response {
  result: {
    invoice: Pick<GraphQLTypes.Invoice, 'id' | 'lastModifiedAt'>;
  };
}

export const Mutation = gql`
  mutation AddRoutePlansToInvoice($input: AddRoutePlansToInvoiceInput!) {
    result: AddRoutePlansToInvoice(input: $input) {
      invoice {
        id
        lastModifiedAt
      }
    }
  }
`;
