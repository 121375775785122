import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { SpecializeField } from '../form';
import { optionsContainerGenerator } from '../../hoc/options-container-generator';
import {Market} from 'shared/schemas/market';

const Field = SpecializeField(Market, { alwaysShowErrors: true, hideOptionalLabel: true });

interface Props {
  regions: Array<{
    value: number | undefined;
    label: string | undefined;
  }>;
  regionsLoading?: boolean;
  handleSubmit: () => void;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  record: {
    id?: number | undefined;
  };
}

class RegionForm extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { regions, record } = this.props;

    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form" data-testid="market">
            <FormGroup data-testid="info-row-1">
              <Field name="regionId" labelColSize={2} inputColSize={5} options={regions} loading={this.props.regionsLoading} autoFocus={!record || !record.id} />
            </FormGroup>
            <FormGroup data-testid="info-row-2">
              <Field name="identifier" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup data-testid="info-row-3">
              <Field name="description" labelColSize={2} inputColSize={5} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }

}

const withRegions = optionsContainerGenerator({ table: 'regions', columns: ['identifier', 'description'] });

export default withRegions(RegionForm as shame);
