import * as React from 'react';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import * as State from 'client/state/state';
import { LoadColumnFooterCellUI } from './load-column-footer-cell-ui';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';

interface OwnProps {
  loadNumber: string;
}

interface StateProps {
  spacesRemainingInLoad: number;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  return {
    spacesRemainingInLoad: ImportRouteSelectors.getSpacesRemainingInLoad(state, ownProps.loadNumber),
  };
};

type Component<P> = new (props: P) => React.Component<P, any>;
const component = flowRight(
  connect<StateProps, {}, OwnProps>(mapStateToProps),
)(LoadColumnFooterCellUI) as Component<OwnProps>;

export const LoadColumnFooterCellContainer = (props: OwnProps) => propToComponent(component, props);
