import gql from 'graphql-tag';

export interface GetNewUpcResponse {
  response: {
    masterUpc: {
      id: number;
      identifier: string;
    },
  };
}

export const GetNewUpcMutation = gql`
mutation GetNewUpcMutation {
  response: GetNewUpc {
    masterUpc {
      id
      identifier
    }
  }
}
`;
