import * as _ from 'lodash';
import { Buffer } from 'buffer';
import { log } from './logger';
import { Collapse, ElementOf,  Writeable } from 'shared/types';

//                       dP
//                       88
// 88d8b.d8b. .d8888b. d8888P .d8888b.
// 88'`88'`88 88ooood8   88   88'  `88
// 88  88  88 88.  ...   88   88.  .88
// dP  dP  dP `88888P'   dP   `88888P8

export class NestedError extends Error {
  constructor(readonly inner: Error, message: string) {
    super(message);
    this.stack += `\n    -- INNER ERROR --\n${inner.stack}`;
  }
}

export class Bug extends Error { isBug: true; constructor(message: string) {super(message); } }
export class TestError extends Error { isTestError: true; constructor(message: string) {super(message); } }
export const orThrow = (message: Error | string = `Invalid precondition`) => { throw typeof message === 'string' ? new Bug(message) : message; };
export const delay = (ms: number) => new Promise<void>(resolve => setTimeout(() => resolve(), ms));
export const delayAtLeast = async <T>(ms: number, mainTask: Promise<T>) => (await Promise.all([mainTask, delay(ms)]))[0];
export const waitUntil = async (condition: () => boolean, timeoutMS: number = 1_000, incrementMS: number = 100) => {
  let total = 0;
  while (!condition() && total < timeoutMS) {
    total += incrementMS;
    await delay(incrementMS);
  }
};

export const describeClassInstance = <I extends Record<string, unknown>, T extends Constructed<Constructor<I>>>(x: T) =>
  `${x.constructor.name}:\n${_.toPairs(x).filter(([, v]) => !_.isFunction(v)).map(([k, v]) => `\t${k}:\t${v}`).join('\n')}`;

export const formatContent = (s: string) => {
  const data = s.trim();
  return (s.length === 0) ? '``` [NADA] ```' : '\n```' + data.replace(/(\s*[\{\}]\s*)/g, '\n$1\n').replace(/([,])/g, '$1\n') + '```';
};

export const urlEncode = <T extends {}>(obj: T) => _.toPairs(obj)
  .filter(([, v]) => !_.isNil(v))
  .filter(([, v]) => !_.isFunction(v))
  .map(([k, v]) => `${k}=${v}`)
  .join('&');

export const unlessTimeout = <T>(ms: number, inner: Promise<T>) => new Promise<T>(async (resolve, reject) => {
  const timeoutHandle = setTimeout(() => reject(`Times out after ${ms} ms.`), ms);
  resolve(await inner);
  clearTimeout(timeoutHandle);
});

export type Guid = string & {format: 'guid'};
export const isGuid = (x: unknown): x is Guid => typeof x === 'string'
  && /[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}/.test(x);

export type Domain = string & {format: 'domain'};
export const isDomain = (x: unknown): x is Domain => typeof x === 'string' && /[\w\d]+\.[\w\d]+\.[\w\d]+/.test(x);
export const isInt = (x: unknown): x is Int => typeof x === 'number' && x.toString(10) === x.toFixed(0);
export const isString = (x: unknown): x is string => typeof x === 'string';
export const isNumber = (x: unknown): x is number => typeof x === 'number';
export const cast = <T>(x: unknown, test: (sut: unknown) => sut is T): T =>
  test(x) ? x : orThrow(`value {${x}} failed type assertion for cast {${test.name}}.`);

  /** Object.assign with better types. */
export const merge = <A extends {}, B extends {}>(a: A, b: B) => Object.assign(a, b) as (A extends ((...args: unknown[]) => unknown) ? A&B : Collapse<A&B>);
export const B64 = Object.freeze({
  encode: (text: string) => Buffer.from(text).toString('base64'),
  decode: (b64: string) => Buffer.from(b64, 'base64').toString('ascii'),
});

export const formatPhoneNumber = (n: string | number) => {
  const unformatted = `${n}`.replace(/[\D]/gi, '');
  if (unformatted.length === 0) return '';
  if (unformatted.length === 10) return `${unformatted.slice(0, 3)}-${unformatted.slice(3, 6)}-${unformatted.slice(6, 10)}`;
  if (unformatted.length === 11 && unformatted[0] === '1') return `${unformatted.slice(1, 4)}-${unformatted.slice(4, 7)}-${unformatted.slice(7, 11)}`;
  log.warn(`unsupported input or format: "${n}"`);
  return '';
};

/**
 * @module shared/types/meta
 * Meta-programming related types and helpers.
 * Makes it easier to manipulate types and objects
 * both at compile time and runtime.
 */

export function * iterateKeys<T extends {}>(subject: T): IterableIterator<keyof T> {
  for (const key of Object.keys(subject)) {
    yield key as keyof T;
  }
}

export function isNotActuallyPartial<T>(subject: Partial<T>): subject is T {
  for (const key of iterateKeys(subject)) {
    if (subject[key] === undefined || subject[key] === null) {
      return false;
    }
  }

  return true;
}

export function allOrNothing<T>(subject: Partial<T>, onValueMissing?: (key: keyof T) => void): T | undefined {
  if (isNotActuallyPartial(subject)) {
    return subject;
  }

  if (onValueMissing) {
    for (const key of iterateKeys(subject)) {
      if (subject[key] === undefined) {
        onValueMissing(key);
      }
    }
  }

  return undefined;
}

export function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export function perEnum<E extends string, V>(map: { [K in E]: V }) {
  return map;
}

/** L for Literal -- makes the hover show the constant value you assigned instead of 'string' */
export const L = <T extends string | number | boolean | symbol>(x: T) => x;

/** Removes readonly modifiers */
export const Mut = <T extends unknown>(x: T) => x as Writeable<T>;

/** this wraps the special `unique symbol` to allow its use in mapped type values, where normally use is forbidden by ts1335. */
const UniqueSymbol: unique symbol = Symbol();

/** Just list some strings, and this will bake a bag of symbols for you to use as property keys. */
export const makeSymbols = <T extends string>(...names: T[]) =>
  Object.freeze(names.reduce((o, n) => ({...o, [n]: Symbol(n)}), {}) as unknown as {readonly [K in T]: typeof UniqueSymbol});

/** Automorphic map from a set of keys. */
export const toDictionary = <T extends string>(arr: T[]) => _.fromPairs(_.map(arr, x => [x, x]))  as any as {[K in ElementOf<T>]: K};

/** Split a collection into two collections based on a classifier, e.g. wheat vs chaff */
export const classify = <T>(xs: T[], classifier: (x: T) => Int) => {
  const result = [] as T[][];
  for (const x of xs) {
    const i = classifier(x);
    if (!result[i]) result[i] = [];
    result[i].push(x);
  }

  return result;
};

export const defaultIfEmpty = <A extends unknown[]>(array: undefined | null | A, fallback: A) => array?.length ? array : fallback;
export const isBlank = (s: string | null | undefined) => _.isNil(s) || s.trim().length === 0;
export const nullIfBlank = (s: string | null | undefined) => isBlank(s) ? null : s;
export const verifyKey = <O>(o: O, k: string, required: boolean = true) => k in (o ?? {}) ? k as keyof O : (required ? orThrow(`${k} is not a key of ${o}`) : '∫never∫' as keyof O);
export const lookup = <O>(o: O, k: string, required: boolean = true) => {
  if (!required && (_.isNil(o) || _.isNil(k))) return undefined;
  return o[verifyKey(o, k, required)];
};

/**
 * Unlike lodash keyBy, this doesnot silently absorb key collisions. Conumers must guarantee unique keys. runtime enforced.
 * ALSO, result is an object, unlike lodash which yields a dictionary that eats key and element types.
 */
export const keyByUnique = <X, Y>(xs: X[], keySelector: (x: X) => Y, includeNilKeys: boolean = false) =>
  _(xs)
  .filter(x => includeNilKeys || !_.isNil(keySelector(x)))
  .groupBy(keySelector)
  .mapValues((v, k) => v.length === 1 ? v[0] : orThrow(`meta.keyByUnique() => duplicate key detected "${k}" : [${v}]`))
  .value();

/**
 * Unlike lodash groupBy, result is an object, whereas lodash yields a dictionary that eats key and element types.
 */
export const groupBy = <X, Y>(xs: X[], keySelector: (x: X) => Y, includeNilKeys: boolean = false) =>
  _(xs)
  .filter(x => includeNilKeys || !_.isNil(keySelector(x)))
  .groupBy(keySelector)
  .mapValues((v, k) => v.length > 0 ? v : orThrow(`meta.groupBy() => empty key detected "${k}" : [${v}]`))
  .value();

export const keysOf = <O extends Record<string, unknown>>(o: O) => _.keys(o) as Array<keyof O>;
export const matchingKeys = <O extends Record<string, unknown>>(o: O, candidates: readonly string[]) => candidates.filter(k => k in o) as Array<keyof O>;

/** Applies a predicate to a sequence and returns indices of matching elements. */
export const indicesOf = <X extends unknown>(xs: X[], predicate: (x: X) => boolean) => xs.map((x,i) => [x,i] as const).filter(pair => predicate(pair[0])).map(pair => pair[1]);
