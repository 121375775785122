import * as React from 'react';
import { ControlLabel, FormControl, Col, HelpBlock, Row } from 'client/components/third-party';
import Dropdown from '../dropdown';

function LabeledDropdown({
  input: { onChange, value, name },
  meta: { touched, error, submitting },
  label,
  labelColSize,
  inputColSize,
  offset,
  placeholder,
  options,
  valueField,
  textFormatter,
  disabled,
  testid,
  handleChange,
  horizontalLabel,
  hideOptionalLabel,
}: {
  label: string,
  placeholder: string,
  disabled: boolean,
  testid: string,
  labelColSize: number,
  inputColSize: number,
  offset: number,
  textFormatter: (...args: unknown[]) => unknown,
  valueField: string,
  handleChange: (...args: unknown[]) => unknown,
  options: Array<{id: number}>,
  horizontalLabel: boolean,
  hideOptionalLabel: boolean,
  alwaysShowErrors: boolean,

  // comes from redux-form
  input: { name: string, value: any, onChange: (...args: unknown[]) => unknown },
  meta: { touched: boolean, submitting: boolean, error: string, warning: string },
}) {
  const selectedOption = options.filter(o => o.id === value).map(o => o.id);
  const disabledAttributes = disabled || options.length === 0 ? {disabled, tabIndex: -1} : {};
  const shouldDisplayError = !this.props.submitting && this.props.meta.error && (this.props.meta.touched || this.props.alwaysShowErrors);

  if (horizontalLabel) {
    return (
      <div>
        <Col componentClass={ControlLabel} sm={labelColSize} smOffset={offset}>{label}</Col>
        <Col className="mfc-form-input" sm={inputColSize}>
          <Dropdown
            data={options}
            value={selectedOption}
            placeholder={placeholder}
            textFormatter={textFormatter}
            valueField={valueField}
            {...disabledAttributes}
            onChange={handleChange || onChange}
            testid={testid || name}
            filter
            updateLabel
            block
          />
          {touched && <FormControl.Feedback/>}
          {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
        </Col>
      </div>
    );
  }

  return (
    <Col sm={inputColSize} smOffset={offset}>
      <Row>
        <div className="mfc-form-stacked-label">{label}</div>
      </Row>
      <Row>
        <div className="mfc-form-input stacked">
          <Dropdown
            data={options}
            value={selectedOption}
            placeholder={placeholder}
            textFormatter={textFormatter}
            valueField={valueField}
            {...disabledAttributes}
            onChange={handleChange || onChange}
            testid={testid || name}
            filter
            updateLabel
            block
          />
          {touched && <FormControl.Feedback/>}
          {shouldDisplayError && <HelpBlock className="validation-error">{error}</HelpBlock>}
        </div>
      </Row>
    </Col>
  );
}

LabeledDropdown.defaultProps = {
  options: [],
  horizontalLabel: true,
};

export default LabeledDropdown;
