import { Product } from 'shared/schemas/product';
import { ActiveInactive, RackShippingUnit } from 'shared/types';

export function isActive<TRecord extends { activeStatus?: ActiveInactive }>(record: TRecord): boolean {
  return record.activeStatus === ActiveInactive.Active;
}

export function isRackProduct(record: Partial<Product>): boolean {
  return record.primaryShippingUnitType === RackShippingUnit;
}

export function isReplenishmentStatusActive(record: Partial<Product>): boolean {
  return record.replenishmentStatus === ActiveInactive.Active;
}
