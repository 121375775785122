import * as _ from 'lodash';
import * as HOC from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as ApolloClient from 'apollo-client';
import * as GraphQL from 'client/hoc/graphql/query';
import * as Query from './query';
import * as Types from './types';
import * as Actions from './actions';
import * as State from 'client/state/state';
import * as SalesPlanState from 'client/state/sales-plan';
import { RouteChildrenProps } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {
}

type UrlProps = RouteChildrenProps<{ id: string }>;

interface StateProps {
  salesPlanId: number;
  selectedProductId: number | null;
}

const mapStateToProps = (state: State.Type, ownProps: ContainerProps & UrlProps): StateProps => {
  return {
    salesPlanId: ownProps.match ? _.parseInt(ownProps.match.params.id) : -1,
    selectedProductId: SalesPlanState.selectedProductId.get(state.salesPlan),
  };
};

type DispatchProps = {
  handleProductSelected(productId: number): void;
};

const mapDispatchToProps: DispatchProps = {
  handleProductSelected: Actions.handleProductSelected,
};

interface WithSalesPlanRowProps {
  productVolumeRows: Types.ProductVolumeRow[];
  varietyDetailRows: Types.VarietyDetailRow[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
  productLabel: string;
}

const WithSalesPlanRows = GraphQL.msyncQuery<Query.GetSalesPlanWeeklyProductVolumeQueryResponse, StateProps, WithSalesPlanRowProps, Query.GetSalesPlanWeeklyProductVolumeQueryInput>(Query.GetSalesPlanWeeklyProductVolumeQuery, {
  alias: 'getSalesPlanWeeklyProductVolume',
  options(ownProps): { variables, fetchPolicy: ApolloClient.FetchPolicy } {
    return {
      variables: {
        salesPlanId: ownProps.salesPlanId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSalesPlanRowProps {
    const details = props.data.salesPlan ? props.data.salesPlan.details : [];
    const salesPlan = props.data.salesPlan ? props.data.salesPlan : undefined;

    const productVolumeRows = details.map(detail => {
      const totalUnits = _.sum(detail.allocations.map(allocation => allocation.pieceQuantity));

      // we need a combined set of the week numbers from the product volume (confusingly named "allocations") and variety/color allocations (variety)
      const productVolumeWeekNumbers = detail.allocations.map(allocation => allocation.weekNumber);
      const varietyColorAllocationWeekNumbers = _.flatten(detail.varieties.map(variety => variety.percentages.map(percentage => percentage.weekNumber)));
      const weekNumbers = _.union(productVolumeWeekNumbers, varietyColorAllocationWeekNumbers);

      const varietyColorAllocationPercentages = weekNumbers.map(weekNumber => {
        const weeklyVarietyPercentages = _.flatten(detail.varieties.map(variety => {
          return variety.percentages.filter(p => p.weekNumber === weekNumber);
        }));

        return {
          weekNumber,
          weeklyPercentageForAllVarieties: _.sum(weeklyVarietyPercentages.map(wtp => wtp.percentage)),
        };
      });

      const isProductVolumeFullyAllocatedForVarietyColor = varietyColorAllocationPercentages.every(wtp => {
        const productVolumeForWeek = detail.allocations.find(allocation => allocation.weekNumber === wtp.weekNumber);
        const productVolumePieceQuantity = productVolumeForWeek ? productVolumeForWeek.pieceQuantity : 0;
        return _.round(wtp.weeklyPercentageForAllVarieties) === 100 && productVolumePieceQuantity > 0;
      });

      const baseColumns = {
        id: detail.product.id as number,
        productIdentifier: detail.product.identifier,
        description: detail.product.description,
        complete: isProductVolumeFullyAllocatedForVarietyColor,
        totalUnits,
      };

      const allColumns = detail.allocations.reduce((acc, allocation) => {
        acc[`week-${allocation.weekNumber}`] = allocation.pieceQuantity;
        return acc;
      }, baseColumns);

      return allColumns;
    });

    const selectedProduct = details.find(detail => detail.product.id === props.ownProps.selectedProductId);

    const varietyDetailRows = !!selectedProduct
      ? selectedProduct.varieties.length > 0
        ? selectedProduct.varieties.map(variety => {
            const baseColumns = {
              id: variety.id,
              name: variety.name,
              mix: variety.mix || '',
            };

            const varietyColumns = variety.percentages.reduce((acc, percentage) => {
              acc[`week-${percentage.weekNumber}`] = percentage.percentage;
              acc[`week-${percentage.weekNumber}~flag`] = percentage.missingProductVolume;

              return acc;
            }, baseColumns);

            const allColumns = selectedProduct.allocations.reduce((acc, allocation) => {
              const key = `week-${allocation.weekNumber}`;
              if (!acc[key]) {
                // We are missing a variety on this row which could potentially
                // cause a problem if the total variety percentages do not
                // add up to 100%. This is used to conditionally display
                // an error indication/red background in the footer.
                acc[`week-${allocation.weekNumber}~footer-flag`] = true;
              }

              return acc;
            }, varietyColumns);

            return allColumns;
          })
        : [
          selectedProduct.allocations.reduce((acc, allocation) => {
            // We are missing all variety percentages. This is
            // used to conditionally display an error indication/red
            // background in the footer.
            const key = `week-${allocation.weekNumber}`;
            acc[`${key}~footer-flag`] = true;

            return acc;
          }, {}),
        ]
      : [];

    return {
      productVolumeRows,
      varietyDetailRows,
      startWeek: salesPlan ? salesPlan.startWeek : undefined,
      endWeek: salesPlan ? salesPlan.endWeek : undefined,
      loading: props.data.loading,
      productLabel: selectedProduct ? `${selectedProduct.product.identifier} - ${selectedProduct.product.description}` : '',
    };
  },
});

const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  WithSalesPlanRows,
)(UI.SalesPlanDetailPage as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  WithSalesPlanRowProps &
  StateProps;

assertCompatible<UI.UIProps, CombinedProps>();
export const SalesPlanDetailContainer = (props: ContainerProps) => HOC.propToComponent(component, props);
