import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as Redux from 'redux';
import * as ReduxTypes from 'client/types/redux-types';
import { GenerateReportMutationResponse } from 'shared/types/graphql-types';
import gql from 'graphql-tag';

export const generateReceivingFormMutation = gql`
mutation GenerateReceivingForm($receivableOrderIds: [Int!]!) {
  response: GenerateReceivingForm(receivableOrderIds: $receivableOrderIds) {
    id
  }
}
`;

export interface GenerateReceivingFormInput {
  receivableOrderIds: number[];
}

export const downloadReceivingFormMenuItemClicked = (receivableOrderIds: number[]): ReduxTypes.Thunker => {
  return async (dispatch: Redux.Dispatch<any>) => {
    const response = await GraphQLMutation.msyncClientMutation<GenerateReportMutationResponse, GenerateReceivingFormInput>({
      mutation: generateReceivingFormMutation,
      variables: {
        receivableOrderIds,
      },
      showBusyModal: true,
      dispatch,
    });

    if (response) {
      const reportId = response.data.response.id;
      window.location.replace(`/report/fileDownload/${reportId}`);
    } else {
      throw new Error('Unable to generate report');
    }
  };
};
