import { FormGroup, Form, Col } from 'client/components/third-party';
import { FuelSurchargeRate } from 'shared/schemas/fuel-surcharge-rate';
import { SpecializeField } from '../form';
import * as React from 'react';

const Field = SpecializeField(FuelSurchargeRate, { alwaysShowErrors: true, hideOptionalLabel: true });

export interface OwnProps {
  handleSubmit: any;
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  record?: {
    id?: number | undefined;
  };
}

export default class FuelSurchargeRateForm extends React.Component<OwnProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form" data-testid="bucket-id">
            <FormGroup>
              <Field name="effective" labelColSize={2} inputColSize={2} />
            </FormGroup>
            <FormGroup>
              <Field
                name="indexStart"
                labelColSize={2}
                inputColSize={2}
                autoFocus={!this.props.record || !this.props.record.id}
              />
            </FormGroup>
            <FormGroup>
              <Field name="indexEnd" labelColSize={2} inputColSize={2} />
            </FormGroup>
            <FormGroup>
              <Field name="surcharge" labelColSize={2} inputColSize={2} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }
}
