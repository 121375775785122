import { tableName, definePresentation, property, hasMany, setSchemaOptions } from './dsl';
import { IRecord } from 'shared/schemas/record';
import { TYPES } from 'shared/types';
import { BolDetail } from './bol-detail';

@tableName('receivableOrderDetails')
export class ReceivableOrderDetail implements IRecord {
  id?: number;

  @property identifier: string;

  @hasMany('bolDetails')
  @property bolDetails: BolDetail[];
}

definePresentation(ReceivableOrderDetail, {
  identifier: {
    tableDisplay: false,
    formDisplay: true,
    includeInFormQuery: true,
    type: TYPES.STRING,
    calculationSpec: {
      dependencies: {},
      calculation: () => /*sql*/`(
        SELECT
          customer_order_product_groups.identifier
        FROM
          customer_order_product_groups
        WHERE
          customer_order_product_groups.receivable_order_detail_id = receivable_order_details.id
        UNION
        SELECT
          supplier_order_product_groups.identifier
        FROM
          supplier_order_product_groups
        WHERE
          supplier_order_product_groups.receivable_order_detail_id = receivable_order_details.id
      )`,
    },
  },
});

setSchemaOptions(ReceivableOrderDetail, {
  softDeletable: true,
});
