import { buildAdminListPage } from 'client/containers/admin';
import { Pot } from 'shared/schemas/pot';

export default buildAdminListPage(Pot, {
  columns: [
    'identifier',
    'description',
    'supplier',
    'partNumber',
    'size',
    'color',
    'activeStatus',
  ],
  excelColumns: [
    'identifier',
    'description',
    'supplier',
    'partNumber',
    'size',
    'color',
    'weightAndMeasure',
    'activeStatus',
  ],
});
