import { tableName, property, setSchemaOptions, belongsTo, required, definePresentation } from './dsl';
import { CustomerOrder } from 'shared/schemas/customer-order';
import { DateStr, TYPES } from 'shared/types';
import { IRecord } from 'shared/schemas/record';

@tableName('productWorksheetReportPreferences')
export class ProductWorksheetReportPreferences implements IRecord {
  id?: number;

  @belongsTo('customerOrders')
  @property @required customerOrder: CustomerOrder;
  customerOrderId: number;

  @property @required performanceStartDate: DateStr;
  @property @required performanceEndDate: DateStr;
  @property @required inventoryStartDate: DateStr;
  @property @required inventoryEndDate: DateStr;
  @property @required salesStartDate: DateStr;
  @property @required salesEndDate: DateStr;
  @property @required trendsStartDate: DateStr;
  @property @required trendsEndDate: DateStr;

  // DK 2018-04-17 ideally, these would be required, but since these were added in a migration, they have to be nullable
  @property pieceTargetStartDate?: DateStr | null;
  @property pieceTargetEndDate?: DateStr | null;

  @property @required pieceTargetPrimaryStoresOnly: boolean;
}

setSchemaOptions(ProductWorksheetReportPreferences, {
  type: 'ProductWorksheetReportPreferences',
});

definePresentation(ProductWorksheetReportPreferences, {
  pieceTargetPrimaryStoresOnly: {
    type: TYPES.BOOLEAN,
  },
});
