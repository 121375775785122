import * as _ from 'lodash';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import * as Constants from './constants';
import * as GraphQLMutation from 'client/hoc/graphql/mutation';
import * as Mutations from './mutation';
import * as PerformanceReportHelpers from 'shared/app/reports/performance';
import * as Redux from 'redux';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportActions from 'client/app/orders/reports/shared/actions';
import * as SchemaTypes from 'schema/performance-report/types';
import * as SharedTypes from 'shared/types';
import { DateRangeYearComparisonValue, getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import { convertPrimaryGlobalAllToJustPrimaryGlobal } from 'client/types/primary-global-all';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const handleDownloadExcelReportClicked = (): ReduxTypes.Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;
    const presetId = formValues.id;

    const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
    if (!dateRangeYearComparison) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const productGroupingBehavior = formValues[Constants.FormFields.productGroupingBehavior];
    const dateColumnBehavior = formValues[Constants.FormFields.dateColumnBehavior];

    const performanceOptionFields = PerformanceReportHelpers.getPerformanceOptionFieldNames();

    const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
    if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const beginDate = dateRanges.dateRange.startDate;
    const endDate = dateRanges.dateRange.endDate;
    const lastYearBeginDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.startDate : undefined;
    const lastYearEndDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.endDate : undefined;

    const customerId = formValues[Constants.FormFields.customerId];
    const sellDepartmentId = formValues[Constants.FormFields.sellDepartmentId];

    if (_.isNil(beginDate) ||
      _.isNil(endDate) ||
      _.isNil(customerId) ||
      _.isNil(sellDepartmentId)) {
      throw new Error('Missing some required fields');
    }

    const enabledOptionFields = PerformanceReportHelpers.getEnabledPerformanceOptionFieldNames(performanceOptionFields, formValues);

    const variables: SchemaTypes.GeneratePerformanceReportInput = {
      generatePerformanceReportInput: {
        measures: PerformanceReportHelpers.getEnabledPerformanceMeasureFieldNames(formValues),
        includePoBased: !!formValues[Constants.FormFields.poBased],
        includeScanBased: !!formValues[Constants.FormFields.scanBased],
        productIds: extractSelectedValues(formValues[Constants.FormFields.productIds]),
        storeIds: extractSelectedValues(formValues[Constants.FormFields.storeIds]),
        sortBy: formValues[Constants.FormFields.sortBy],

        beginDate,
        customerId,
        endDate,
        lastYearBeginDate,
        lastYearEndDate,
        marketIds: extractSelectedValues(formValues[Constants.FormFields.marketIds]),
        primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(formValues[Constants.FormFields.primaryGlobalAll]),
        mfcAreaIds: extractSelectedValues(formValues[Constants.FormFields.mfcAreaIds]),
        productClassIds: extractSelectedValues(formValues[Constants.FormFields.productClassIds]),
        productSubClassIds: extractSelectedValues(formValues[Constants.FormFields.productSubClassIds]),
        regionIds: extractSelectedValues(formValues[Constants.FormFields.regionIds]),
        sellDepartmentId,

        presetId,

        options: {
          includeScanBackInPrice: enabledOptionFields.includes('configIncludeScanBackInPrice'),
          includeVendorChargeBacksInCost: enabledOptionFields.includes('configIncludeVendorChargeBacksInCost'),
        },

        dateColumnBehavior,
        storeDetailLevel: formValues[Constants.FormFields.storeDetailLevel],
        dateDetailLevel: formValues[Constants.FormFields.dateDetailLevel],
        productGroupingBehavior,
      },
    };

    try {
      dispatch(ReportActions.reportDownloadStarted());
      const response = await GraphQLMutation.msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, SchemaTypes.GeneratePerformanceReportInput>({
        mutation: Mutations.generatePerformanceReport,
        variables,
        dispatch,
      });

      if (!response) {
        dispatch(ReportActions.reportDownloadFinished());
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;
      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};

export const handleMeasureCheckboxClicked = (categoryName: string, measure: string, isOn: boolean): ReduxTypes.Thunker => {
  return (dispatch: Redux.Dispatch<any>) => {
    dispatch(ReduxForm.change(Constants.formName, measure, isOn));
  };
};

export const handleDateRangesChanged = (state: DateRangeYearComparisonValue | undefined): ReduxTypes.Thunker => {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.dateRangeYearComparison, state));
  };
};

export const handleDateColumnBehaviorChanged = (dateColumnBehavior: SharedTypes.PerformanceReportDateColumnBehavior) => {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.dateColumnBehavior, dateColumnBehavior));
    if (dateColumnBehavior !== SharedTypes.PerformanceReportDateColumnBehavior.ThisYearLastYearDateRanges) {
      dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.dateDetailLevel, SharedTypes.PerformanceReportDateDetailLevel.Total));
    }
  };
};

export const handleDateDetailLevelChanged = (dateDetailLevel: SharedTypes.PerformanceReportDateDetailLevel) => {
  return async dispatch => {
    dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.dateDetailLevel, dateDetailLevel));
    if (dateDetailLevel !== SharedTypes.PerformanceReportDateDetailLevel.Total) {
      dispatch(ReduxForm.change(Constants.formName, Constants.FormFields.dateColumnBehavior, SharedTypes.PerformanceReportDateColumnBehavior.ThisYearLastYearDateRanges));
    }
  };
};
