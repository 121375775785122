import * as AsyncButtonContainer from 'client/components/async-button/async-button-container';
import * as BS from 'client/components/third-party';
import * as Mutations from 'client/actions/mutations';
import * as React from 'react';
import { SimpleInput } from '../simple-components/simple-input';

interface OwnProps {
  actionStatus: Mutations.MutationStatus;
  isShown: boolean;
  onCancelButtonClicked(): void;
  saveButtonClicked(name: string): void;
  presetId?: number;
}

interface State {
  presetName: string;
}

export class SavePresetAsModal extends React.PureComponent<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props);

    this.state = {
      presetName: '',
    };
  }

  onPresetNameChanged = presetName => {
    this.setState({
      presetName,
    });
  }

  modalSaveButtonClicked = () => {
    this.props.saveButtonClicked(this.state.presetName);
  }

  componentDidUpdate(prevProps: OwnProps) {
    if (!prevProps.isShown && this.props.isShown) {
      this.setState({
        presetName: '',
      });
    }
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <BS.Modal
        animation
        backdrop="static"
        dialogClassName="report-user-params-modal"
        id="tbd"
        onHide={this.props.onCancelButtonClicked}
        show>
        <BS.Form horizontal onSubmit={this.modalSaveButtonClicked}>
          <BS.Modal.Body>
            <SimpleInput
              autoFocus
              inputColSize={12}
              label="Preset Name"
              labelColSize={12}
              onChange={this.onPresetNameChanged}
              testid="report-user-params-modal-preset-name-input"
              value={this.state.presetName}
            />
          </BS.Modal.Body>
          <BS.Modal.Footer>
            <BS.Button
              bsClass="mfc-button mfc-clear-button"
              data-testid="report-user-params-modal-cancel-button"
              onClick={this.props.onCancelButtonClicked}>
              Cancel
            </BS.Button>
            <AsyncButtonContainer.AsyncButton
              label="Save Preset"
              testid="report-user-params-modal-save-preset-button"
              type={'submit'}
              onClick={this.modalSaveButtonClicked}
              disabled={this.state.presetName === ''}
            />
          </BS.Modal.Footer>
        </BS.Form>
      </BS.Modal>
    );
  }
}
