import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { isInvalid, isPristine } from 'redux-form';
import { msyncQuery } from 'client/hoc/graphql/query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RecordBarUI, UIProps } from './ui';
import * as Query from './query';
import * as Actions from './actions';
import { formName } from '../sidebar';
import { DateTimeStr } from 'shared/types';

interface ContainerProps {
  invoiceId?: number;
}

interface StateProps {
  saveButtonDisabled: boolean;
  invoiceIdentifier: string;
}

const mapStateToProps = (state: any, ownProps: ContainerProps): StateProps => {
  const invalid = isInvalid(formName)(state);
  const pristine = isPristine(formName)(state);

  return {
    saveButtonDisabled:  pristine || invalid,
    invoiceIdentifier: 'New Invoice',
  };
};

type DispatchProps = {
  onSaveButtonClicked(): void;
  onCustomerOrderIdentifierClicked(customerOrderId: number): void;
};

const mapDispatchToProps: DispatchProps = {
  onSaveButtonClicked: Actions.saveButtonClicked,
  onCustomerOrderIdentifierClicked: Actions.onCustomerOrderIdentifierClicked,
};

interface QueryProps {
  invoiceIdentifier: string;
  customerOrderId: number | undefined;
  customerOrderIdentifier: string | undefined;
  invoiceLastModifiedAt?: DateTimeStr;
}

const withInvoiceInfo = msyncQuery<Query.QueryResponse, StateProps & ContainerProps, QueryProps, Query.Input>(Query.Query, {
  alias: 'withInvoiceInfo',
  skip(ownProps) {
    return ownProps.invoiceId === undefined;
  },
  options(ownProps): { variables: Query.Input } {
    return {
      variables: {
        invoiceId: ownProps.invoiceId as any, // TODO: Gracefully handle this. Options can be called before skip, but never actually fire the query. The types should reflect this.
      },
    };
  },
  props(props): QueryProps {
    const { data } = props;
    if (data.loading || data.getRecord === undefined) {
      return {
        invoiceIdentifier: !props.ownProps.invoiceId ? 'New Invoice' : '',
        customerOrderId: undefined,
        customerOrderIdentifier: undefined,
      };
    }

    const invoice = data.getRecord.invoice;
    return {
      invoiceIdentifier: !!props.ownProps.invoiceId  ? invoice.identifier : 'New Invoice',
      invoiceLastModifiedAt: invoice.lastModifiedAt ?? undefined,
      customerOrderId: invoice.customerOrder?.id,
      customerOrderIdentifier: invoice.customerOrder?.identifier,
    };
  },
});

const component = flowRight(
  connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  withInvoiceInfo,
)(RecordBarUI as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  QueryProps &
  StateProps;

assertCompatible<UIProps, CombinedProps>();
export const RecordBarContainer = (props: ContainerProps) => propToComponent(component, props);
