import gql from 'graphql-tag';
import { BolRacksReturned, BolNotes, BolIdentifier, BolId, BolAutoCartTrackingEnabled } from 'shared/schemas/bol';
import { UserId } from 'shared/schemas/user';
import { DateTimeStr } from 'shared/types';

export interface InitialBolVariables {
  bolId: BolId;
}

export const InitialBolQuery = gql`
  query findForInitialBolQuery($type: RecordType = Bol, $bolId: Int!) {
    response: find(type: $type, id: $bolId) {
      ... on Bol {
        id
        identifier
        receivedAt
        racksReturned
        notes
        autoCartTrackingEnabled
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export interface InitialBolResponse {
  response: {
    id: BolId;
    identifier: BolIdentifier;
    receivedAt: DateTimeStr;
    racksReturned: BolRacksReturned;
    notes: BolNotes;
    autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
    user: {
      id: UserId;
      firstName: string;
      lastName: string;
    }
  };
}
