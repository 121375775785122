import {
  CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY,
  CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS,
} from 'client/constants';

import { MutationStatus } from 'client/actions/mutations';
import { Action } from 'client/types/redux-types';

export interface CartTrackingState {
  addNewTransactionModalShown: boolean;
  addNewTransactionActionStatus: MutationStatus;
}

const INITIAL_STATE: CartTrackingState = {
  addNewTransactionModalShown: false,
  addNewTransactionActionStatus: MutationStatus.Initial,
};

export default function reducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }): CartTrackingState {
  switch (action.type) {
    case CART_TRACKING_SET_ADD_NEW_TRANSACTION_MODAL_VISIBILITY: {
      return {
        ...state,
        addNewTransactionModalShown: action.payload,
      };
    }
    case CART_TRACKING_SET_ADD_NEW_TRANSACTION_ACTION_STATUS: {
      return {
        ...state,
        addNewTransactionActionStatus: action.payload,
      };
    }
    default:
      return state;
  }
}
