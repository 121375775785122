import { Lens } from '@atomic-object/lenses';
import * as _ from 'lodash';

interface MutationsState {
  readonly mutationsInFlightCounter: number;
  readonly mutationRecentlyReceivedCounter: number;
  readonly showBusyModal: boolean;
  readonly busyModalMessage?: string;
}

export const InitialState: MutationsState = {
  mutationsInFlightCounter: 0,
  mutationRecentlyReceivedCounter: 0,
  showBusyModal: false,
  busyModalMessage: 'Processing...',
};

export type Type = MutationsState;

export const mutationsInFlightCounterLens = Lens.of<Type, number>({
  get: state => state.mutationsInFlightCounter,
  set: (state, value) => {
    return {
      ...state,
      mutationsInFlightCounter: _.max([0, value]) ?? 0,
    };
  },
});

export const mutationRecentlyReceivedCounterLens = Lens.of<Type, number>({
  get: state => state.mutationRecentlyReceivedCounter,
  set: (state, value) => {
    return {
      ...state,
      mutationRecentlyReceivedCounter: _.max([0, value]) ?? 0,
    };
  },
});

export const showBusyModalLens = Lens.from<Type>().prop('showBusyModal');
export const busyModalMessageLens = Lens.from<Type>().prop('busyModalMessage');
