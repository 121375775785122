import { connect, MapDispatchToProps, MapDispatchToPropsFactory } from 'react-redux';
import gql from 'graphql-tag';
import * as Actions from 'client/actions/related-bols';
import { propToComponent } from 'client/hoc/hoc';
import { SendReceivingReceiptModal } from './send-receiving-receipt-modal';
import { RelatedBolsState } from 'client/reducers/related-bols';
import { msyncQuery } from 'client/hoc/graphql/query';
import { flowRight } from 'lodash';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { MutationStatus } from 'client/actions/mutations';
import * as _ from 'lodash';
import { defaultIfEmpty } from 'client/lib/meta';

export interface ReceivingReceiptRecipient { supplierContactId: number; name: string; selected: boolean; primary: boolean; receivesReceipts: boolean; }
export interface OwnProps { receivableOrderId: number; isModal?: boolean; }
interface StateProps { isShown: boolean; recipients: ReceivingReceiptRecipient[]; actionStatus: MutationStatus; notes?: string; }
const mapStateToProps = (state: { relatedBols: RelatedBolsState }, ownProps: OwnProps & SendReceivingReceiptModalInitialValues): StateProps => ({
  isShown: state.relatedBols.sendReceivingReceiptModalShown,
  recipients: defaultIfEmpty(state.relatedBols.supplierOrderContacts ?? [], ownProps.initialRecipients ?? []),
  actionStatus: state.relatedBols.sendReceivingReceiptActionStatus,
  notes: state.relatedBols.receivingReceiptNotes,
});

interface DispatchProps {
  handleCancelButtonClicked: () => void;
  handleSubmit: () => Promise<any>;
  setSupplierOrderContacts: (contacts: ReceivingReceiptRecipient[]) => void;
  handleRecipientClicked: (supplierContactId: number) => void;
  setReceivingReceiptNotes: (notes: string) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> | MapDispatchToPropsFactory<DispatchProps, OwnProps> = (dispatch, ownProps: OwnProps & StateProps & MutationProps): DispatchProps => ({
  handleCancelButtonClicked: () => dispatch(Actions.setSendReceivingReceiptModalVisibility(false)),
  handleSubmit: async () => {
    // send the email to the selected recipients
    // update the sent-at time
    dispatch(Actions.setSendReceivingReceiptActionStatus(MutationStatus.InProgress));
    await ownProps.sendReceivingReceipt({ receivableOrderId: ownProps.receivableOrderId, recipients: ownProps.recipients || [], notes: ownProps.notes });
    dispatch(Actions.setSendReceivingReceiptModalVisibility(false));
    dispatch(Actions.setSendReceivingReceiptActionStatus(MutationStatus.Initial));
  },
  setSupplierOrderContacts: (contacts: ReceivingReceiptRecipient[]) => dispatch(Actions.setSupplierOrderContacts(contacts)),
  handleRecipientClicked: (supplierContactId: number) => dispatch(Actions.receivingReceiptEmailRecipientCheckboxClicked(supplierContactId)),
  setReceivingReceiptNotes: (notes: string) => dispatch(Actions.setReceivingReceiptNotesText(notes)),
});

const MUTATION = gql`
mutation emailReceivingReceipt($receivableOrderId: Int!, $contactIds: [Int]!, $notes: String) {
  emailReceivingReceipt(receivableOrderId: $receivableOrderId, contactIds: $contactIds, notes: $notes) {
    id
    mails {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
}
`;

interface MutationProps { sendReceivingReceipt: (args: { receivableOrderId: number, recipients: ReceivingReceiptRecipient[], notes?: string }) => shame; }
const withMutation = msyncMutation(MUTATION, {
  props: ({ mutate }): MutationProps => ({
    sendReceivingReceipt: (args: { receivableOrderId: number; recipients: ReceivingReceiptRecipient[]; notes?: string; }) => mutate({
      variables: {
        receivableOrderId: args.receivableOrderId,
        contactIds: args.recipients.filter(contact => contact.selected).map(contact => contact.supplierContactId),
        notes: args.notes,
      },
    }),
  }),
});

export const ContactsQuery = gql`
  query contactsQuery($id: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $id) {
      id
      identifier
      supplierVendorName
      contacts {
        id
        name
        phoneCell
        phoneOffice
        email
        primary
        receivesReceipts
      }
    }
  }
`;

export interface ContactsQueryInput { id: number; }
export interface ContactsQueryResponse {
  receivableOrder: {
    id: number;
    identifier: string;
    supplierVendorName: string;
    contacts: Array<{
      id: number;
      name: string | null;
      phoneCell: string | null;
      phoneOffice: string | null;
      email: string | null;
      primary: boolean;
      receivesReceipts: boolean | null;
    }>;
  };
}

export interface SendReceivingReceiptModalInitialValues {
  receivableOrderIdentifier?: string;
  supplierVendorName?: string;
  initialRecipients?: ReceivingReceiptRecipient[];
}

const withInitialValues = msyncQuery<ContactsQueryResponse, OwnProps & StateProps, SendReceivingReceiptModalInitialValues, ContactsQueryInput>(
  ContactsQuery, {
    alias: 'sendReceivingReceiptQuery',
    skip(ownProps) { return ownProps.receivableOrderId === undefined; },
    options(ownProps) { return { variables: { id: ownProps.receivableOrderId } }; },
    props({ data, ownProps }): SendReceivingReceiptModalInitialValues {
      return (data.loading || !data.receivableOrder) ? { initialRecipients: [] } : {
        receivableOrderIdentifier: data.receivableOrder.identifier,
        supplierVendorName: data.receivableOrder.supplierVendorName,
        initialRecipients: _.sortBy(data.receivableOrder.contacts
          .filter(c => !!c.email)
          .map(c => ({
            supplierContactId: c.id,
            name: c.name || 'Unnamed',
            selected: c.primary || !!c.receivesReceipts,
            primary: c.primary,
            receivesReceipts: !!c.receivesReceipts,
          }))
        , contact => [!contact.primary, contact.name]),
      };
    },
  });

export type ComponentProps = OwnProps & StateProps & DispatchProps & MutationProps & SendReceivingReceiptModalInitialValues;
type Component<P> = new (props: P) => React.Component<P, any>;
const component = flowRight(
  withInitialValues,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps),
  withMutation,
  connect<StateProps, DispatchProps, OwnProps>(undefined, mapDispatchToProps),
)(SendReceivingReceiptModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
