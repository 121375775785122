import { User } from 'shared/schemas/user';
import { CustomerName, CustomerIdentifier } from 'shared/schemas/customer';
import { Product } from 'shared/schemas/product';
import { Store } from 'shared/schemas/store';
import { ComboProductGroup } from 'shared/schemas/combo-product-group';
import { SalesPlan } from 'shared/schemas/sales-plan';
import { Region } from 'shared/schemas/region';
import { Supplier } from 'shared/schemas/supplier';
import { Pot } from 'shared/schemas/pot';
import { Tray } from 'shared/schemas/tray';
import { Tag } from 'shared/schemas/tag';
import { Bucket } from 'shared/schemas/bucket';
import { Box } from 'shared/schemas/box';
import { Market } from 'shared/schemas/market';
import { SupplierItem } from 'shared/schemas/supplier-item';
import { ProductSubClass } from 'shared/schemas/product-sub-class';
import { ProductClass } from 'shared/schemas/product-class';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { CartTrackingSupplierEntry } from 'shared/schemas/cart-tracking-supplier-entry';
import { RouteType, Carrier, LoadType } from 'shared/types';
import * as _ from 'lodash';
import { SupplierLocation } from 'shared/schemas/supplier-location';

const spaceOut = (str: string | null | undefined, maxLength): string => {
  return _.padEnd(str ? str.substr(0, maxLength) : '', maxLength + 5);
};

const columnize = (...args): string => {
  return _.chunk(args, 2).map((stuff: [string, number]) => spaceOut(...stuff)).join('');
};

export const PotDisplayValueResolver = (pot: Pot): string => {
  return columnize(
    `${pot.identifier} - ${pot.description}`, 40,
    pot.supplier && pot.supplier ? pot.supplier.name : '', 20,
    pot.partNumber, 10,
    pot.size, 10,
    pot.color, 10,
    pot.weightAndMeasure, 30,
  );
};

export const TrayDisplayValueResolver = (tray: Tray): string => {
  return columnize(
    `${tray.identifier} - ${tray.description}`, 40,
    tray.supplier && tray.supplier ? tray.supplier.name : '', 20,
    tray.partNumber, 10,
    tray.size, 10,
    tray.color, 10,
  );
};

export const TagDisplayValueResolver = (tag: Tag): string => {
  return columnize(
    tag.identifier, 40,
    tag.supplier && tag.supplier ? tag.supplier.name : '', 20,
    tag.partNumber, 10,
    tag.size, 10,
  );
};

export const UserDisplayValueResolver = (user: User) => `${user.firstName} ${user.lastName}`;
export const CustomerDisplayValueResolver = (customer: { identifier: CustomerIdentifier, name: CustomerName }) => `${customer.identifier} - ${customer.name}`;
export const MerchandiserDisplayValueResolver = (merchandiser: { name: string }) => merchandiser.name;
export const ProductDisplayValueResolver = (product: Product) => `${product.identifier} - ${product.description}`;
export const ProductSkuDisplayValueResolver = (product: Product) => `${product.sku ? `${product.sku} - ` : ''}${product.description}`;
export const StoreDisplayValueResolver = (store: Store) => `${store.identifier} - ${store.city}, ${store.state}`;
export const ComboProductGroupDisplayValueResolver = (comboProductGroup: ComboProductGroup) => `${comboProductGroup.identifier} - ${comboProductGroup.description}`;
export const SalesPlanDisplayValueResolver = (plan: SalesPlan) => `${plan.year} - ${plan.identifier}`;
export const ReceivedDisplayValueResolver = (received: boolean) => received ? 'Yes' : 'No';
export const RegionDisplayValueResolver = (region: Region) => (region.identifier ? `${region.identifier} - ${region.description}` : 'N/A');
export const SupplierDisplayValueResolver = (supplier: Supplier) => `${supplier.identifier} - ${supplier.name}`;
export const SupplierLocationDisplayValueResolver = (supplierLocation: SupplierLocation) => `${supplierLocation.identifier}`;
export const BucketDisplayValueResolver = (bucket: Bucket) => `${bucket.identifier} - ${bucket.description}`;
export const BoxDisplayValueResolver = (box: Box) => `${box.identifier} - ${box.description}`;
export const MarketDisplayValueResolver = (market: Market) => (market.identifier ? `${market.identifier} - ${market.description}` : 'N/A');
export const SupplierItemDisplayValueResolver = (supplierItem: SupplierItem) => supplierItem.product.identifier;
export const ProductClassDisplayValueResolver = (productClass: ProductClass) => productClass.identifier;
export const ProductSubClassDisplayValueResolver = (productSubClass: ProductSubClass) => productSubClass.identifier;
export const MfcAreaDisplayValueResolver = (mfcArea: { identifier: MfcAreaIdentifier }) => mfcArea.identifier;
export const CartTrackingSupplierEntryDisplayValueResolver = (entry: CartTrackingSupplierEntry) => `${entry.quantity || 0}`;
export const VendorDisplayValueResolver = (vendor: { identifier: string, name?: string }) => `${vendor.identifier}${vendor.name ? ' - ' + vendor.name : ''}`;
export const CustomerContactRoleDisplayValueResolver = (role: {id: string, value: string}) => _.startCase(role.value);

export const getRouteTypeDisplayValue = (routeType: RouteType): 'Round Trip' | 'One Way' => {
  switch (routeType) {
    case RouteType.RoundTrip:
      return 'Round Trip';
    case RouteType.OneWay:
      return 'One Way';
  }
};

export const getLoadTypeDisplayValue = (loadType: LoadType): string => {
  // TODO What should these be
  switch (loadType) {
    case LoadType.MADD:
      return LoadType.MADD;
    case LoadType.SEAS:
      return LoadType.SEAS;
  }
};

export const getCarrierDisplayValue = (carrier: Carrier): 'Peak' | 'MTI' => {
  switch (carrier) {
    case Carrier.MTI:
      return 'MTI';
    case Carrier.PEAK:
      return 'Peak';
  }
};
