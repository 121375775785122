import * as React from 'react';
import { buildFilterableTable,  OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import ProductNewPriceModal from '../../../components/product/product-new-price-modal';
import { flowRight } from 'lodash';
import dataContainer from '../../../hoc/data-container';
import { Col } from 'client/components/third-party';
import { tableDisplayColumns } from '../../../../shared/schemas';
import { IRecord } from 'shared/schemas/record';
import { IColumn } from 'client/components/table/column';
import { ActivateRecordFunction } from 'client/hoc/graphql/activate-records';
import { DeactivateRecordFunction } from 'client/hoc/graphql/deactivate-records';
import { AvailableSearchField, AvailableFilter } from 'client/types';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import * as ProductConstants from './constants';
import { ComponentType } from 'react';

interface IProductRecord extends IRecord { customerId: number, customer: { name: string, } }
interface OwnProps {
  record: IProductRecord,
  columns: IColumn[],
  loading?: boolean,
  content: any[],
  refetchTable: () => void,
  totalUnfilteredCount: number,
  totalCount: number,
  filteredRecordIds: number[],
  loadMoreRecords?: () => void,
  performActivation: ActivateRecordFunction,
  performDeletion: DeactivateRecordFunction,
  searchableFields: AvailableSearchField[],
  availableFilters: AvailableFilter[],
  tablePageNumber: number,
  tableParentInfo: TableParentInfo,
  dataRequest: MsyncDataRequest,
}

interface OwnState { showNewPriceModal: boolean }
class ProductPriceFormGroup extends React.Component<OwnProps, OwnState> {
  private FilterableTable: React.StatelessComponent<FilterableTableProps>;
  constructor(props) {
    super(props);
    this.FilterableTable = buildFilterableTable(ProductConstants.ProductPriceTableName);
    this.state = { showNewPriceModal: false };
    this.onClose.bind(this);
  }

  onClose() { this.setState({ showNewPriceModal: false }); }
  render() {
    const placeholder = 'There are currently no prices for this product.';
    const rowMenuItems: RowMenuItem[] = [{
      label: 'Delete Row',
      onClick: this.props.performDeletion,
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    const headerMenuItems: RowMenuItem[] = [{
      label: 'Delete Selected Rows',
      onClick: this.props.performDeletion,
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    const customerId = this.props.record ? this.props.record.customerId : undefined;
    return !this.props.record ? <div/> : (
      <Col sm={12}>
        <div className="mfc-record-content" data-testid="price-info">
          <this.FilterableTable
            table={ProductConstants.ProductPriceTableName}
            content={this.props.content}
            loading={this.props.loading}
            columns={this.props.columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            onNewClicked={() => this.setState({ showNewPriceModal: true })}
            refetchTable={this.props.refetchTable}
            loadMoreRecords={this.props.loadMoreRecords}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={placeholder}
            newButtonLabel={'Add Price'}
            checkable
            list={false}
            headerMenuItems={headerMenuItems}
            rowMenuItems={rowMenuItems}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            dataRequest={this.props.dataRequest}
          >
            <ProductNewPriceModal
              name="productPrices"
              isModal
              show={this.state.showNewPriceModal}
              onClose={() => this.setState({ showNewPriceModal: false })}
              productId={this.props.record.id}
              refetchTable={this.props.refetchTable}
              customerId={customerId}
            />
          </this.FilterableTable>
        </div>
      </Col>
    );
  }
}

export default flowRight(
  tableParentHoc(),
  dataContainer({
    table: ProductConstants.ProductPriceTableName,
    columns: tableDisplayColumns(ProductConstants.ProductPriceTableName).filter(c => c !== 'product'),
    scope: ({ record: { id } }) => ([{ field: 'product', values: [id] }]),
    workbookName: (props: OwnProps) => `${props.record.identifier} - ${props.record.customer.name} - Price Info`,
    // refetch product record after price deletion to allow active price and active retail to update properly.
    refetchQueries: ['findProduct'],
  }),
)(ProductPriceFormGroup as shame) as shame as ComponentType<OwnProps>;
