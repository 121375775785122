import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { isInvalid, isPristine } from 'redux-form';
import { msyncQuery } from 'client/hoc/graphql/query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RecordBarUI, UIProps } from './ui';
import * as Query from './query';
import * as Actions from './actions';
import { formName } from '../sidebar';
import { SalesPlanVarietyStatus } from 'shared/types';

interface ContainerProps {
  salesPlanId?: number;
}

interface StateProps {
  saveButtonDisabled: boolean;
}

const mapStateToProps = (state: any): StateProps => {
  const invalid = isInvalid(formName)(state);
  const pristine = isPristine(formName)(state);

  return {
    saveButtonDisabled: pristine || invalid,
  };
};

interface DispatchProps {
  onSaveButtonClicked(): void;
  onBackButtonClicked(): void;
}

const mapDispatchToProps = {
  onBackButtonClicked: Actions.backButtonClicked,
  onSaveButtonClicked: Actions.saveButtonClicked,
};

interface QueryProps {
  salesPlanHeader: string;
  varietyStatus: SalesPlanVarietyStatus;
}

const withSalesPlanInfo = msyncQuery<Query.QueryResponse, StateProps & ContainerProps, QueryProps, Query.Input>(Query.Query, {
  alias: 'withSalesPlanInfo',
  skip(ownProps) {
    return ownProps.salesPlanId === undefined;
  },
  options(ownProps): { variables: Query.Input } {
    return {
      variables: {
        salesPlanId: ownProps.salesPlanId as any, // TODO: Gracefully handle this. Options can be called before skip, but never actually fire the query. The types should reflect this.
      },
    };
  },
  props(props): QueryProps {
    const { data } = props;
    if (data.loading || data.salesPlan === undefined) {
      return {
        salesPlanHeader: '',
        varietyStatus: SalesPlanVarietyStatus.NotComplete,
      };
    }

    const salesPlan = data.salesPlan;
    return {
      salesPlanHeader: `${salesPlan.customerIdentifier} - ${_.startCase(salesPlan.sellDepartmentIdentifier)} - ${salesPlan.identifier} - ${salesPlan.year}`,
      varietyStatus: salesPlan.varietyStatus,
    };
  },
});

const component = _.flowRight(
  connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  withSalesPlanInfo,
)(RecordBarUI as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  QueryProps &
  StateProps;

assertCompatible<UIProps, CombinedProps>();
export const RecordBarContainer = (props: ContainerProps) => propToComponent(component, props);
