import { SelectableRow } from 'client/components/selectable/types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES, DateStr, SelectableValue, FilterOperator } from 'shared/types';
import { formatDate } from 'shared/helpers/date-helpers';
import gql from 'graphql-tag';
import { extractSelectedValues } from 'client/components/selectable/selectable';
import { applyFilterIfListHasValues, FindAllCustomersQueryResponse, FindAllCustomersQuery } from 'client/app/reports/shared/query';

export interface CustomerQueryProps {
  customerOptions?: SelectableRow[];
  customersLoading?: boolean;
}
// TODO: This, and the /app/reports/shared/query.ts withCustomers are pretty much
//       the same, but the returned data from props is formatted slightly differently.
//       One is for a single pick dropdown and the other is for a Selectable. Should be
//       able to write a wrapper that uses the same underlying query and formats the results
//       slightly differently.
export const withCustomers = msyncQuery<FindAllCustomersQueryResponse, {}, CustomerQueryProps>(FindAllCustomersQuery, {
  alias: 'withCustomers',
  options(ownProps) {
    return {
      variables: {
        type: 'Customer',
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
    };
  },
  props(props): CustomerQueryProps {
    const { data } = props;
    if (data.loading || data.customers === undefined) {
      return {
        customerOptions: [],
        customersLoading: true,
      };
    }

    return {
      customerOptions: data.customers.map(customer => ({
        id: customer.id,
        cells: [
          customer.identifier,
          customer.name,
        ],
      })),
      customersLoading: false,
    };
  },
});

export interface FindAllSupplierOrdersQueryResponse {
  supplierOrders?: Array<{
    id: number;
    identifier: string;
    plannedArrivalDate: DateStr;
    customer: {
      id: number;
      identifier: string;
    }
    supplier: {
      id: number;
      identifier: string;
    }
    sellDepartment: {
      id: number;
      identifier: string;
    }
  }>;
}

export const FindAllSupplierOrdersQuery = gql`
  query FindAllSupplierOrdersQuery($type: RecordType = SupplierOrder, $sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    supplierOrders: findAll(type: $type, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SupplierOrder {
        id
        identifier
        plannedArrivalDate
        customer {
          id
          identifier
        }
        supplier {
          id
          identifier
        }
        sellDepartment {
          id
          identifier
        }
      }
    }
  }
`;

export interface SupplierOrderQueryProps {
  supplierOrderOptions?: SelectableRow[]; // Optional because if the query is skipped, this will be undefined
  supplierOrdersLoading?: boolean; // Optional because if the query is skipped, this will be undefined
}
interface SupplierQueryInputProps {
  customerIds?: SelectableValue;
  supplierId?: number;
  beginDate?: DateStr;
  endDate?: DateStr;
}

export const withSupplierOrders = msyncQuery<FindAllSupplierOrdersQueryResponse, SupplierQueryInputProps, SupplierOrderQueryProps>(FindAllSupplierOrdersQuery, {
  alias: 'withSupplierOrders',
  skip(ownProps) {

    // These options aren't strictly necessary to find supplier orders
    // but it ends up pretty slow if we're not filtering by them
    const skip = extractSelectedValues(ownProps.customerIds).length === 0
      || ownProps.supplierId === undefined
      || ownProps.beginDate === undefined
      || ownProps.endDate === undefined;

    return skip;
  },
  options(ownProps) {
    return {
      variables: {
        type: 'SupplierOrder',
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
        filters: [
          ...applyFilterIfListHasValues('customer', ownProps.customerIds),
          ...applyFilterIfListHasValues('supplier', [ownProps.supplierId]),
          ...applyFilterIfListHasValues('plannedArrivalDate', [ownProps.beginDate, ownProps.endDate], FilterOperator.Between),
        ],
      },
      fetchPolicy: 'network-only', // Can't explain it, but without this it's possible to get the Supplier Orders dropdown stuck in a loading state. Might be a bug in Apollo Jordan says. :-)
    };
  },
  props(props): SupplierOrderQueryProps {
    const { data } = props;
    if (data.loading || data.supplierOrders === undefined) {
      return {
        supplierOrderOptions: [],
        supplierOrdersLoading: true,
      };
    }

    return {
      supplierOrderOptions: data.supplierOrders.map(supplierOrder => ({
        id: supplierOrder.id,
        cells: [
          supplierOrder.identifier,
          supplierOrder.supplier.identifier,
          supplierOrder.customer.identifier,
          supplierOrder.sellDepartment.identifier,
          formatDate(supplierOrder.plannedArrivalDate, 'MM/DD/YYYY') || supplierOrder.plannedArrivalDate,
        ],
      })),
      supplierOrdersLoading: false,
    };
  },
});
