import * as _ from 'lodash';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import * as Mutation from './mutation';
import { change } from 'redux-form';
import { ConfirmOkToSaveFunction } from './save-confirmation';

const getMostRecentUpcCodeIndex = (upcFields: any[]) => upcFields.length - 1;

export const handleGetNewUpcClicked = (formName: string, upcFields: any[], confirmOkToSave: ConfirmOkToSaveFunction): Thunker => {
  return async dispatch => {
    if (await confirmOkToSave()) {
      const response = await msyncClientMutation<Mutation.GetNewUpcResponse, {}>({
        mutation: Mutation.GetNewUpcMutation,
        variables: {},
        dispatch,
        disableGlobalSpinner: true,

      });
      const upcCodeIndex = getMostRecentUpcCodeIndex(upcFields);
      dispatch(change(formName, `upcCodes[${upcCodeIndex}].identifier`, response.data.response.masterUpc.identifier));
      return response;
    }
  };
};

export const handlePrimaryRadioChanged = (formName: string, index: number, upcCodeCount: number) => {
  return async dispatch => {
    _.times(upcCodeCount, i => {
      const identifier = `upcCodes[${i}]`;
      if (i === index) {
        dispatch(change(formName, `${identifier}.primary`, true));
      } else {
        dispatch(change(formName, `${identifier}.primary`, false));
      }
    });
  };
};
