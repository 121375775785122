import gql from 'graphql-tag';

export const generateStoreOnHandReportByCategory = gql`
mutation GenerateStoreOnHandReportByCategory($reportByCategoryInput: GenerateStoreOnHandReportByCategoryInput) {
  response: GenerateStoreOnHandReportByCategory(reportByCategoryInput: $reportByCategoryInput) {
    id
  }
}
`;

export const generateStoreOnHandReportBySalesPlan = gql`
mutation GenerateStoreOnHandReportBySalesPlan($reportBySalesPlanInput: GenerateStoreOnHandReportBySalesPlanInput) {
  response: GenerateStoreOnHandReportBySalesPlan(reportBySalesPlanInput: $reportBySalesPlanInput) {
    id
  }
}
`;
