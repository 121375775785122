import * as _ from 'lodash';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { Modal } from 'client/components/third-party';
import * as React from 'react';
import * as SharedHelpers from 'shared/helpers/andys-little-helpers';
import * as Types from '../types';

export interface ComponentProps {
  isShown: boolean;
  customerOrdersIdsToBeSent: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsAlreadySent: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsNotFullyReceived: Types.CustomerOrderIdWithIdentifier[];
  customerOrdersIdsThatAreScanBased: Types.CustomerOrderIdWithIdentifier[];
  handleCancelButtonClicked(): void;
  handleSendMicsFileButtonClicked(customerOrdersIdsToBeSent: number[], additionalCustomerOrderIdsToBeUnchecked: number[]): void;
}

const CustomerOrderListItemComponent = (customerOrder: Types.CustomerOrderIdWithIdentifier) =>
  (<li key={customerOrder.identifier}>{customerOrder.identifier}</li>);

export class SendMicsModal extends React.PureComponent<ComponentProps, {}> {
  handleSendMicsFileButtonClicked = () => {
    this.props.handleSendMicsFileButtonClicked(SharedHelpers.idsFor(this.props.customerOrdersIdsToBeSent),
    SharedHelpers.idsFor(_.union(
        this.props.customerOrdersIdsAlreadySent,
        this.props.customerOrdersIdsNotFullyReceived,
        this.props.customerOrdersIdsThatAreScanBased,
      )));
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="send-mics-modal"
        data-testid="send-mics-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="send-mics-modal-container"
        className="send-mics-modal">
        <GlobalAsyncModalHeader>Send MICS File to Meijer</GlobalAsyncModalHeader>
        <Modal.Body>
          {this.props.customerOrdersIdsToBeSent.length > 0 &&
            <div>
              These orders will be sent in the MICS file:
              <ul data-testid="customer-orders-to-be-sent">
                {this.props.customerOrdersIdsToBeSent.map(CustomerOrderListItemComponent)}
              </ul>
            </div>
          }
          {this.props.customerOrdersIdsAlreadySent.length > 0 &&
            <div>
              These orders were already sent and will not be sent again:
              <ul data-testid="customer-orders-already-sent">
                {this.props.customerOrdersIdsAlreadySent.map(CustomerOrderListItemComponent)}
              </ul>
            </div>
          }
          {this.props.customerOrdersIdsNotFullyReceived.length > 0 &&
            <div>
              These orders are not fully received and will not be sent in the MICS file:
              <ul data-testid="customer-orders-not-received">
                {this.props.customerOrdersIdsNotFullyReceived.map(CustomerOrderListItemComponent)}
              </ul>
            </div>
          }
          {this.props.customerOrdersIdsThatAreScanBased.length > 0 &&
            <div>
              These orders are scan-based and will not be sent in the MICS file:
              <ul data-testid="customer-orders-scan-based">
                {this.props.customerOrdersIdsThatAreScanBased.map(CustomerOrderListItemComponent)}
              </ul>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="send-mics-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Send MICS File to Meijier"
            testid="send-mics-modal-send-button"
            onClick={this.handleSendMicsFileButtonClicked}
            classNames={['mfc-button-primary']}
            disabled={this.props.customerOrdersIdsToBeSent.length === 0}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
