import { isNil } from 'lodash';
import * as React from 'react';
import * as pluralize from 'pluralize';
import { ActiveFilter, AvailableFilter } from 'client/types';
import { TYPES, formatInteger } from 'shared/types';

export interface FilterResultsProps {
  availableFilters: AvailableFilter[];
  activeFilters: ActiveFilter[];
  displayedResultsCount: number;
  onToggleFilter: (field: string, value: string) => void;
  onClearAllFilters: () => void;
  checkable: boolean;
  numCheckedRecords: number;
}

export const FilterResults: React.StatelessComponent<FilterResultsProps> = (props: FilterResultsProps) => {
  const hasCheckedRecords = props.checkable && props.numCheckedRecords !== 0;

  const describe = (filter: ActiveFilter) => {
    const availableFilter = (props.availableFilters || []).find(f => f.field === filter.field);
    const name = availableFilter?.displayName ? availableFilter.displayName : filter.field;
    return pluralize(name, filter.values.length);
  };

  const displayValue = (filter: ActiveFilter, value: string) => {
    const availableFilter = (props.availableFilters || []).find(f => f.field === filter.field);

    let friendlyName = value;

    if (availableFilter?.options) {
      const currentFilter = (availableFilter.options || []).find(option => option.id === value);
      if (currentFilter) {
        friendlyName = currentFilter.value;
      }
    }

    return friendlyName;
  };

  const displayCheckedRecordCount = hasCheckedRecords && <h5>{`${formatInteger(props.numCheckedRecords)} Selected`}&nbsp;&middot;&nbsp;</h5>;
  const displayFilterRecordCount = <h5>{isNil(props.displayedResultsCount) ? '' : `${formatInteger(props.displayedResultsCount, '0')} ${pluralize('Result', props.displayedResultsCount)}`}</h5>;

  return (
    <div className="row toolbar-pf-results mfc-filter-results">
      <div className="col-sm-12">
        <div className="pull-right">
          {displayCheckedRecordCount}
          {displayFilterRecordCount}
        </div>
        <div>
          <ul className="list-inline">
            <li>
              {props.activeFilters.filter(f => f.type !== TYPES.DATE).map(filter => {
                return (
                  <span key={`active-filters-${filter.field}`} className="mfc-filter-group">
                    {filter.values.length > 0 && <span>
                      {describe(filter)}:
                      {filter.values.map(value => {
                        return (
                          <span className="label label-info mfc-filter-label" key={`active-filters-${filter.field}-${value}`}>
                            {displayValue(filter, value)}
                            <a onClick={() => props.onToggleFilter(filter.field, value)}><span className="pficon pficon-close" /></a>
                          </span>
                        );
                      })}
                    </span>}
                  </span>
                );
              })}
            </li>
          </ul>
          <p>{props.activeFilters.length > 0 && <a onClick={props.onClearAllFilters}>Clear All Filters</a>}</p>
        </div>
      </div>
    </div>
  );
};

FilterResults.defaultProps = {
  activeFilters: [],
};
