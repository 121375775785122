import * as React from 'react';
import * as BS from 'client/components/third-party';

interface OwnProps<T> { initialValues: T, loading: boolean }
interface NotificationState {
  showNotification: boolean;
  notificationHasBeenShown: boolean;
  message?: string | JSX.Element;
  closeNotification?: (confirmed: boolean) => void;
}

interface ShouldDisplayResultYes<T> {
  shouldDisplay: true, message: string | JSX.Element, data?: T }
interface ShouldDisplayResultNo { shouldDisplay: false }
type ShouldDisplayResult<T> = ShouldDisplayResultYes<T> | ShouldDisplayResultNo;
export type NotificationArgs<T, ResultData = {}> = {
  onlyDisplayOnce?: boolean;
  shouldDisplay: (previousRecord?: T, currentRecord?: T) => ShouldDisplayResult<ResultData>;
  beforeDisplay?: (record?: T, props?: any, data?: ResultData) => Promise<void>;
  afterDisplay?: (record?: T, props?: any, data?: ResultData) => Promise<void>;
};

export const withNotification = <T, ResultData>(notificationOptions?: NotificationArgs<T, ResultData>) => !notificationOptions
  ? WrappedComponent => (props: OwnProps<T>) => <WrappedComponent {...props} />
  : (WrappedComponent: new (passedProps: OwnProps<T>) => React.Component<OwnProps<T>, any>) =>
    class WithSaveConfirmation extends React.Component<OwnProps<T>, NotificationState> {
      constructor(props: OwnProps<T>) {
        super(props);
        this.state = { showNotification: false, notificationHasBeenShown: false, message: undefined };
      }

      componentWillReceiveProps(nextProps: OwnProps<T>) {
        const shouldDisplayResult = notificationOptions.shouldDisplay(this.props.initialValues, nextProps.initialValues);
        if (!this.props.loading && !nextProps.loading && shouldDisplayResult.shouldDisplay && !(notificationOptions.onlyDisplayOnce && this.state.notificationHasBeenShown)) {
          const message = shouldDisplayResult.message;
          this.setState({
            showNotification: true,
            message,
            closeNotification: async value => {
              this.setState({ notificationHasBeenShown: true, showNotification: false });
              if (notificationOptions.afterDisplay) {
                // Calling an async function from the componentWillReceiveProps. Yuck.
                void notificationOptions.afterDisplay(this.props.initialValues, this.props, shouldDisplayResult.data);
              }
            },
          });

          if (notificationOptions.beforeDisplay) {
            // Calling an async function from the componentWillReceiveProps. Yuck.
            void notificationOptions.beforeDisplay(nextProps.initialValues, this.props, shouldDisplayResult.data);
          }
        }
      }

      private hideNotification = () => {
        this.setState({ notificationHasBeenShown: true });
        if (this.state.closeNotification)
          this.state.closeNotification(false);
      };

      public render() {
        const modal = this.state.showNotification ? (
          <BS.Modal show={true} dialogClassName="toast-modal" onHide={this.hideNotification} className="notification-modal" backdropClassName="notification-backdrop">
            <BS.Modal.Body>
              <div className="alert alert-info">
                <span className="pficon fa fa-exclamation-circle text-info" />
                {this.state.message || ''}
                <div className="actions pull-right">
                  <strong><a data-testid="ok-notification" onClick={this.hideNotification}>OK</a></strong>
                </div>
              </div>
            </BS.Modal.Body>
          </BS.Modal>
        ) : undefined;

        return (
          <div>
            {modal}
            <WrappedComponent {...this.props} />
          </div>
        );
      }
    };
