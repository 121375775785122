import { FieldValidator } from 'shared/validators/types';
import { REQUIRED } from './validators';

export * from './validators';
export * from './order-product-group-validators';
export * from './supplier-item-validators';
export * from './types';

export const makeFormValidator = (fieldValidator: FieldValidator, debugContext?: any) =>
  (value: any, record: any) => {
    const complaint = fieldValidator.isServerSideOnly || fieldValidator.isValid(value, record) ? undefined :  fieldValidator.shortMessage(value, record);
    // if (!!complaint)
    //   console.log(`${JSON.stringify(debugContext)} - Validation error: ${complaint}`);
    return complaint;
  };

export const makeSometimesRequiredValidator = (requiredPredicate: (record: any) => boolean): FieldValidator => ({
  isValid: (value: any, record: any) => !requiredPredicate(record) || REQUIRED.isValid(value, record),
  shortMessage: REQUIRED.shortMessage,
  message: REQUIRED.message,
});

// This helper can be used to create a 'validate' function for required 'Field' elements
// when using FieldWrapper is not an option.
export const makeRequiredFormValidator = (opts?: { requiredPredicate?: (record: any) => boolean }) =>
  makeFormValidator(opts?.requiredPredicate ? makeSometimesRequiredValidator(opts.requiredPredicate) : REQUIRED);

export const REQUIRED_FIELD_VALIDATOR = makeRequiredFormValidator();
