import * as _ from 'lodash';
import { getFormValues, change } from 'redux-form';
import * as Constants from './constants';
import * as Selectors from './selectors';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GenerateStoreOnHandReportByCategoryInput, GenerateStoreOnHandReportBySalesPlanInput } from 'schema/customer-order-report/types';
import { generateStoreOnHandReportByCategory, generateStoreOnHandReportBySalesPlan } from './mutation';
import * as ReportActions from '../shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';
import { DateRangeYearComparisonValue, getDateRangeForDateRangeYearComparison } from 'shared/helpers/date-helpers';
import { convertPrimaryGlobalAllToJustPrimaryGlobal } from 'client/types/primary-global-all';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export enum ActionTypeKeys {
}

export const handleDateRangesChanged = (state: DateRangeYearComparisonValue | undefined): Thunker => {
  return async dispatch => {
    dispatch(change(Constants.formName, Constants.FormFields.dateRangeYearComparison, state));
  };
};

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const filterByCategoryOrSalesPlan = Selectors.getFilterByCategoryOrSalesPlan(state);

    const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

    let response: { data: CommonSchemaTypes.GenerateReportMutationResponse } | undefined;

    const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
    if (!dateRangeYearComparison) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const dateRanges = getDateRangeForDateRangeYearComparison(dateRangeYearComparison);
    if (!dateRanges || !dateRanges.dateRange || !dateRanges.dateRange.startDate || !dateRanges.dateRange.endDate) {
      throw new Error('Unable to find date range field data in the form.');
    }

    const beginDate = dateRanges.dateRange.startDate;
    const endDate = dateRanges.dateRange.endDate;
    const lastYearBeginDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.startDate : undefined;
    const lastYearEndDate = dateRanges.lastYearDateRange ? dateRanges.lastYearDateRange.endDate : undefined;

    dispatch(ReportActions.reportDownloadStarted());
    try {
      switch (filterByCategoryOrSalesPlan) {
        case Constants.FilterByCategoryOrSalesPlan.ByCategory:
          if (_.isNil(formValues.customerId) || _.isNil(formValues.sellDepartmentId)) {
            throw new Error('Invalid data provided');
          }
          response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, GenerateStoreOnHandReportByCategoryInput>({
            mutation: generateStoreOnHandReportByCategory,
            variables: {
              reportByCategoryInput: {
                beginDate,
                endDate,
                lastYearBeginDate,
                lastYearEndDate,
                customerId: formValues.customerId,
                sellDepartmentId: formValues.sellDepartmentId,
                includePoBased: !!formValues.poBased,
                includeScanBased: !!formValues.scanBased,
                primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(formValues.primaryGlobalAll),
                mfcAreaIds: extractSelectedValues(formValues.mfcAreaIds),
                productClassIds: extractSelectedValues(formValues.productClassIds),
                productIds: extractSelectedValues(formValues.productIds),
                productSubClassIds: extractSelectedValues(formValues.productSubClassIds),
                marketIds: extractSelectedValues(formValues.marketIds),
                regionIds: extractSelectedValues(formValues.regionIds),
                storeIds: extractSelectedValues(formValues.storeIds),
                includeStoresNotShipped: !!formValues.includeStoresNotShipped,
              },
            },
            dispatch,
          });
          break;
        case Constants.FilterByCategoryOrSalesPlan.BySalesPlan:
          if (_.isNil(formValues.customerId) || _.isNil(formValues.salesPlanId)) {
            throw new Error('Invalid data provided');
          }
          response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, GenerateStoreOnHandReportBySalesPlanInput>({
            mutation: generateStoreOnHandReportBySalesPlan,
            variables: {
              reportBySalesPlanInput: {
                beginDate,
                endDate,
                lastYearBeginDate,
                lastYearEndDate,
                customerId: formValues.customerId,
                marketIds: extractSelectedValues(formValues.marketIds),
                regionIds: extractSelectedValues(formValues.regionIds),
                storeIds: extractSelectedValues(formValues.storeIds),
                primaryGlobal: convertPrimaryGlobalAllToJustPrimaryGlobal(formValues.primaryGlobalAll),
                mfcAreaIds: extractSelectedValues(formValues.mfcAreaIds),
                salesPlanId: formValues.salesPlanId,
                includeStoresNotShipped: !!formValues.includeStoresNotShipped,
              },
            },
            dispatch,
          });
          break;
      }
      if (!response) {
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;
      window.location.replace(`/report/fileDownload/${reportId}`);

    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
