import { tableName, property, setSchemaOptions, belongsTo } from './dsl';
import { Json, RecordAction, DateTimeStr } from '../types';
import { User } from 'shared/schemas/user';

@tableName('auditableEvents')
export class AuditableEvent {
  id?: number;
  @property createdAt: DateTimeStr;
  @property recordType: string; // SchemaTypes;
  @property recordId: number;
  @property action: RecordAction;
  @property initial: Json | null;
  @property change: Json | null;
  @property final: Json | null;
  @property eventId: string | null;

  @belongsTo('users')
  @property user?: User;
  userId: number | null;
}

setSchemaOptions(AuditableEvent, {
  hasTimestamps: false,
});
