import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES, SelectableValue } from 'shared/types';
import gql from 'graphql-tag';
import { SelectableRow } from 'client/components/selectable/types';
import { applyFilterIfListHasValues } from 'client/app/reports/shared/query';

export interface FindAllSupplierItemsQueryResponse {
  supplierItems?: Array<{
    id: number;
    product: {
      id: number;
      identifier: string;
      replenishmentIdentifier: string;
      description: string;
    };
  }>;
}

export const FindAllSupplierItemsQuery = gql`
  query FindAllSupplierItemsQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    supplierItems: findAll(type: SupplierItem, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SupplierItem {
        id
        product {
          id
          identifier
          replenishmentIdentifier
          description
        }
      }
    }
  }
`;

export interface SupplierItemQueryProps {
  supplierItemOptions?: SelectableRow[];
  supplierItemsLoading?: boolean;
}
export const withSupplierItems = (opts?: { onlyIncludeParentReplenishmentProducts?: boolean }) => {
  return msyncQuery<FindAllSupplierItemsQueryResponse, { customerId?: number, supplierId?: number, sellDepartmentId?: number, productClassIds?: SelectableValue, productSubClassIds?: SelectableValue, scanProductOptions?: boolean[] }, SupplierItemQueryProps, {}>(FindAllSupplierItemsQuery, {
    alias: 'withSupplierItems',
    skip(ownProps) {
      return ownProps.customerId === undefined || ownProps.supplierId === undefined;
    },
    options(ownProps) {
      return {
        variables: {
          filters: [
            ...(opts && opts.onlyIncludeParentReplenishmentProducts ? [{ field: 'isItsOwnReplenishmentProduct', values: [true] }] : []),
            { field: 'customer', values: [ownProps.customerId] },
            { field: 'supplier', values: [ownProps.supplierId] },
            ...applyFilterIfListHasValues('sellDepartment', [ownProps.sellDepartmentId]),
            ...applyFilterIfListHasValues('productClass', ownProps.productClassIds),
            ...applyFilterIfListHasValues('productSubClass', ownProps.productSubClassIds),
            ...applyFilterIfListHasValues('scanProduct', ownProps.scanProductOptions),
          ],
          sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
        },
      };
    },
    props(props): SupplierItemQueryProps {
      const { data } = props;
      if (data.loading || data.supplierItems === undefined) {
        return {
          supplierItemOptions: [],
          supplierItemsLoading: data.loading,
        };
      }

      return {
        supplierItemOptions: data.supplierItems.map(supplierItem => ({
          id: supplierItem.id,
          cells: [
            supplierItem.product.identifier,
            supplierItem.product.description,
          ],
        })),
        supplierItemsLoading: data.loading,
      };
    },
  });
};
