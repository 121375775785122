import * as React from 'react';

import * as classNames from 'classnames';

interface BooleanCellTypes {
  editing: boolean;
  focused: boolean;
  value: boolean;
}

const BooleanCell = ({ value, focused, editing }: BooleanCellTypes) => {
  const className = classNames('text-center');
  const icon = 'fa fa-check text-success';

  return (
    <div className={className}>
      {value && <span className={icon} />}
    </div>
  );
};

export default BooleanCell;
