import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { DateStr, ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'buyerReport';
export const reportType = ReportTypes.BuyerReport;

export enum FormFields {
  customerId = 'customerId',
  marketIds = 'marketIds',
  mfcAreaIds = 'mfcAreaIds',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productIds = 'productIds',
  productSubClassIds = 'productSubClassIds',
  regionIds = 'regionIds',
  scanBased = 'scanBased',
  sellDepartmentId = 'sellDepartmentId',
  sortBy = 'sortBy',
  storeIds = 'storeIds',

  primaryGlobalAll = 'primaryGlobalAll',

  shipBeginDate = 'shipBeginDate',
  shipEndDate = 'shipEndDate',
  soldBeginDate = 'soldBeginDate',
  soldEndDate = 'soldEndDate',

  startDate = 'startDate',
  todaysDate = 'todaysDate',
}

export interface FormValues {
  [FormFields.customerId]: number | undefined;
  [FormFields.marketIds]: SelectableValue | undefined;
  [FormFields.mfcAreaIds]: SelectableValue | undefined;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.regionIds]: SelectableValue | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.sortBy]: string | undefined;
  [FormFields.storeIds]: SelectableValue | undefined;
  [FormFields.primaryGlobalAll]: PrimaryGlobalAll;

  [FormFields.shipBeginDate]: DateStr | undefined;
  [FormFields.shipEndDate]: DateStr | undefined;
  [FormFields.soldBeginDate]: DateStr | undefined;
  [FormFields.soldEndDate]: DateStr | undefined;

  [FormFields.startDate]: DateStr | undefined;
  [FormFields.todaysDate]: DateStr | undefined;
}
