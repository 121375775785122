import * as React from 'react';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES } from 'shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { EMPTY_ARRAY, SUPPLIER_COMMITMENT_TABLE_NAME } from 'client/constants';
import { AvailableFilter } from 'client/types';
import PageTitle from 'client/components/page-title';
import * as _ from 'lodash';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { ConfirmOkToSaveFunction } from './save-confirmation';

export interface SupplierCommitmentsListTableRow {
  id: number;
  salesPlan: {
    year: number;
    identifier: string;
    customer: { name: string };
    sellDepartment: { identifier: string };
  };
  supplier: { name: string };
  confirmed: boolean;
}

export interface UIProps {
  rows?: SupplierCommitmentsListTableRow[];
  loading: boolean;
  totalCount: number;
  filteredRecordIds: number[];
  totalUnfilteredCount: number;
  tableParentInfo: TableParentInfo;
  onNewClicked: () => void;
  dataRequest: MsyncDataRequest;
  onRowSelected(row: { id: number }): void;
  availableFilters: AvailableFilter[];
  onDeleteMenuItemClicked(ids: number[], confirmOkToSave: ConfirmOkToSaveFunction): void;
  onDownloadSpecSheetsMenuItemClicked(ids: number[]): void;
  confirmOkToSave: ConfirmOkToSaveFunction;
}

const columns: IColumn[] = [
  {
    id: 'salesPlanYear',
    accessor: 'salesPlan.year',
    header: 'Year',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'salesPlanIdentifier',
    accessor: 'salesPlan.identifier',
    header: 'Season',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'salesPlanCustomerName',
    accessor: 'salesPlan.customer.name',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'supplierName',
    accessor: 'supplier.name',
    header: 'Supplier',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'salesPlanSellDepartmentIdentifier',
    accessor: 'salesPlan.sellDepartment.identifier',
    header: 'Sell Department',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'confirmed',
    accessor: 'confirmed',
    header: 'Confirmed Status',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.YES_NO_NULL,
    type: TYPES.BOOLEAN,
  },

];

export class SupplierCommitmentsTableUI extends React.PureComponent<UIProps, {}> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private tableName = SUPPLIER_COMMITMENT_TABLE_NAME;

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(this.tableName);
  }

  // Not adding delete supplier commitment to the header to prevent accidentally deleting all of them
  private headerMenuItems: RowMenuItem[] = [
    {
      label: 'Download Spec Sheets',
      onClick: (ids: number[]) => this.props.onDownloadSpecSheetsMenuItemClicked(ids),
      uncheckRecordFollowingClick: true,
      willRemove: false,
    },
  ];

  private rowMenuItems: RowMenuItem[] = [
    {
      label: 'Download Spec Sheets',
      onClick: (ids: number[]) => this.props.onDownloadSpecSheetsMenuItemClicked(ids),
      uncheckRecordFollowingClick: true,
      willRemove: false,
    },
    {
      label: 'Delete Supplier Commitment',
      onClick: (ids: number[]) => this.props.onDeleteMenuItemClicked(ids, this.props.confirmOkToSave),
      uncheckRecordFollowingClick: true,
      willRemove: true,
    },
  ];

  render() {
    return (
      <div>
        <PageTitle title="Supplier Commitments"/>
        <this.FilterableTable
          table={this.tableName}
          content={this.props.rows || EMPTY_ARRAY}
          loading={this.props.loading}
          columns={columns}
          excelColumns={columns}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={_.noop}
          loadMoreRecords={_.noop}
          searchableFields={EMPTY_ARRAY}
          availableFilters={this.props.availableFilters}
          placeholder="No Supplier Commitments"
          checkable
          list
          tablePaginated
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          onRowSelect={this.props.onRowSelected}
          dataRequest={this.props.dataRequest}
          rowMenuItems={this.rowMenuItems}
          headerMenuItems={this.headerMenuItems}
          newButtonLabel="New Supplier Commitment"
          onNewClicked={this.props.onNewClicked}
        />
      </div>
    );
  }
}
