import { Lens } from '@atomic-object/lenses';

interface RoutePlanPrintingWarningState {
  readonly isMarkingRoutes: boolean;
}

export type Type = RoutePlanPrintingWarningState;

export const InitialState: Readonly<RoutePlanPrintingWarningState> = Object.freeze({
  isMarkingRoutes: false,
});

// There's a weird denpendancy issue with the global state and this local state.
// Andy is debating the value of the global state type at the moment.
export const stateLens = Lens.of<any, RoutePlanPrintingWarningState>({
  get: state => state.routePlanPrintingWarning,
  set: (state, v) => {
    return {
      ...state,
      routePlanPrintingWarning: v,
    };
  },
});
export const isMarkingRoutesLens = Lens.from<RoutePlanPrintingWarningState>().prop('isMarkingRoutes');
