// 1-based index to align with Excel
export const CUSTOMER_IDENTIFIER_ROW = 4;
export const CUSTOMER_IDENTIFIER_COL = 'B';

export const PRODUCTION_YEAR_ROW = 5;
export const PRODUCTION_YEAR_COL = 'B';

export const PRODUCTION_SEASON_ROW = 6;
export const PRODUCTION_SEASON_COL = 'B';

export const PRODUCT_IDENTIFIER_COL = 'B';

export const ALLOCATION_HEADING_ROW = 8;

export const FIRST_ALLOCATION_ROW = 9;
export const FIRST_ALLOCATION_COL = 'D';
