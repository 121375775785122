import * as _ from 'lodash';
// import { Agent } from 'https';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
const introspectionQueryResultData = require('./graphql-fragment-types.json');
import { orThrow } from 'shared/helpers';
import { DateTime } from 'luxon';

const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

export const getClient = (baseUri: string | undefined, getState?: () => { session: { user: { token: string, hasuraToken?: string } } }, rejectUnauthorized?: boolean) => {
  // const opts = {
  //   credentials: 'same-origin',
  //   agent
  //     : rejectUnauthorized !== false            ? undefined
  //     : process.env.NODE_ENV === 'production'   ? orThrow('Bad configuration! Should not be allowing unauthorized (self-signed) certs in production')
  //     :                                           new Agent({ rejectUnauthorized: false }),
  // };

  return new ApolloClient({
    name: 'm-sync',
    version: require('../package.json').version,
    link:
      ApolloLink.split( // https://www.jamalx31.com/tech-posts/using-apollo-with-multiple-graphql-endpoints
      op => op.getContext().api !== 'hasura',
      new ApolloLink((operation, forward) => {
        if (!forward || !getState) return null;
        const currentState = getState();
        if (currentState?.session) {
          operation.setContext({
            headers: {
              authorization: [`Bearer ${currentState?.session?.user?.token ?? undefined}`],
              ['msync-api-call']: [operation.operationName ?? 'UNKNOWN'],
            },
          });
        }

        return forward(operation);
      }).concat(new HttpLink({ uri: `${baseUri ?? ''}/api/graphql`, credentials: 'same-origin' })),
      new ApolloLink((operation, forward) => {
        if (!forward || !getState) return null;
        const currentState = getState();
        if (currentState?.session) {
          operation.setContext(() => ({
            headers: {
              authorization: [`Bearer ${currentState?.session?.user?.hasuraToken ?? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRGF2aWQgTWNLYXkiLCJvaWQiOiIyMjE0OTNkZC0xYTc0LTRiZGEtYWMwZS1jOGNhYmVmNmIwZmMiLCJleHBpcmF0aW9uIjoiMjAyMS0wOC0xOCAxNzoyOTowNi42NjkgWiIsImlhdCI6MTYyOTMwNjU0Nn0.lOBouvJ3aQ1GQYhQ2UNKU3pLzGIwWKikWaUwz6JqQAY'}`],
              ['Accept-Encoding']: 'gzip',
            },
            start: DateTime.now(),
          }));
        }

        return forward(operation);
      }).concat(new HttpLink({uri: `https://gql.m-sync.app/v1/graphql`})),
    ),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (result: shame) => {
        /*
         * DK 2018 -- PR #723
         * If you're looking here because you're seeing weird GQL behavior e.g. data in the network response, but not the data function in the msyncQuery
         * OR if only the __typename shows up in the response, then try the following:
         * Regenerate the graphql-fragment-types.json file by running yarn gql:fragments
         * I have no idea what this is actually used for, but it solved all of my problems
         */
        const dataId = {
          ProductWorksheetAllocation                : () => `${result.__typename}.${result.customerOrderId}.${result.productId}.${result.storeId                                                                                                  }`,
          ProductWorksheetStore                     : () => `${result.__typename}.${result.customerOrderId}.${result.productId}.${result.storeId                                                                                                  }`,
          ProductWorksheetStats                     : () => `${result.__typename}.${result.customerOrderId}.${result.productId                                                                                                                    }`,
          DisplayValue                              : () => `${result.__typename}.${result.rawValue                                                                                                                                               }`,
          GetRoutePlanAttachedCustomerOrdersResponse: () => `${result.__typename}.${result.routePlanId                                                                                                                                            }`,
          ReviewStoresCustomerOrderAllocations      : () => `${result.__typename}.${result.routePlanId    }.${                    result.storeId                                                                                                  }`,
          UnifiedReceivableOrderProduct             : () => `${result.__typename}.${result.id             }.${result.receivableOrderType ?? orThrow('You must request the `receivableOrderType` when retrieving a UnifiedReceivableOrderProduct!')}`,
        }[result.__typename ?? '']?.();

        if (dataId) return dataId;
        if (result.id && result.__typename) return (result._customType)
          ? `${result._customType}.${result.__typename}.${result.id}`
          : `${                      result.__typename}${ result.id}`;

        if (result.__typename?.match(/(Response$|Stats)/) || NOT_EXPECTING_ID_TYPES.has(result.__typename))
          return null; // It's OK for these to not have an id

        if (process.env.NODE_ENV !== 'test')
          console.warn('Msync GraphQL result missing either id:', result.id, ' or __typename:', result.__typename, ' result:', result);

        return null;
      },
    }),
  });
};

const NOT_EXPECTING_ID_TYPES = new Set([
  'FilterOptions',
  'FilterOption',
  'ProductWorksheet',
  'FindAllResult',
  'SupplierOrderSummaryResult',
  'ReconciliationRow',
  'AllocateProductsToStoresResult',
  'SupplierOrderLastPurchaseOrderEmailedInfo',
  'MileageRateTableRow',
  'DropRateTableRow',
  'TailgateFeeTableRow',
  'RoutePlanAction',
  'RoutePlanActions',
  'RoutingStop',
  'CustomerOrderProductGroup', // I'm not sure this belongs in here, but don't want to risk breaking something by removing it right now
  'CustomerOrderProduct', // I'm not sure this belongs in here, but don't want to risk breaking something by removing it right now
  'CustomerOrderAllocation', // I'm not sure this belongs in here, but don't want to risk breaking something by removing it right now
  'MerchandisersWithStores',
  'RoutePlanLoads',
  'StoresWithMerchandisers',
  'UniqueStopForRoutePlan',
  'ComboProductGroupResponseComboProduct',
  'SalesPlanWeeklyProductVolumeAllocation',
  'SalesPlanWeeklyProductVolumeDetail',
  'SalesPlanVariety',
  'SalesPlanVarietyPercentage',
  'SalesPlanDetail',
  'SalesPlanDetailAllocation',
  'UnifiedReceivableOrderLastPurchaseOrderEmailedInfo',
  'Combo',
  'ComboDetail',
]);
