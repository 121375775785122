import { tableName, property, belongsTo, hasMany, definePresentation, required, setSchemaOptions, manyToMany } from './dsl';
import { displayType } from './dsl';
import { TYPES, DISPLAY_TYPES, INPUT_TYPES, CustomerOrderStatus, DateStr, OrderMethod, ShippingUnitType, DateTimeStr, CustomerOrderType, KnownSellDepartment, KnownVendor, ReceivableOrderReceivingStatus, KnownCustomer } from '../types';
import { Customer, CustomerIdentifier, CustomerId } from './customer';
import { SellDepartment, SellDepartmentId } from './sell-department';
import { SubSellDepartment, SubSellDepartmentId } from './sub-sell-department';
import { MfcArea, MfcAreaId } from './mfc-area';
import { SalesPlan, SalesPlanId } from './sales-plan';
import { CustomerOrderProduct } from './customer-order-product';
import { ImportJob } from 'shared/schemas/import-job';
import { CustomerOrderProductGroup } from 'shared/schemas/customer-order-product-group';
import { IRecord, Saved } from 'shared/schemas/record';
import { SupplierOrder } from 'shared/schemas/supplier-order';
import { CustomerDisplayValueResolver, SalesPlanDisplayValueResolver, ReceivedDisplayValueResolver, VendorDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { User } from 'shared/schemas/user';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { ReceivableOrder } from './receivable-order';
import { Vendor } from './vendor';

export type CustomerOrderId = Flavor<number, 'customerOrderId'>;
export type CustomerOrderIdentifier = Flavor<string, 'customerOrderIdentifier'>;
export type CustomerOrderOrderDate = Flavor<DateStr, 'customerOrderOrderDate'>;
export interface CustomerOrderPrimaryKey {
  customerOrderIdentifier: CustomerOrderIdentifier;
  customerOrderOrderDate: CustomerOrderOrderDate;
  customerIdentifier: CustomerIdentifier;
}
export type CustomerOrderLastModifiedAt = Flavor<DateTimeStr, 'customerOrderLastModifiedAt'>;
export type CustomerOrderOrderType = Flavor<CustomerOrderType, 'customerOrderOrderType'>;
export type CustomerOrderOrderStatus = Flavor<CustomerOrderStatus, 'customerOrderOrderStatus'>;

export interface EditCustomerOrderInput {
  id: number;
  updatedAt?: DateTimeStr;
  identifier?: string;
  orderType?: string;
  scanBased?: boolean;
  orderDate?: DateStr;
  orderStatus?: string;
  supplierPoStatus?: string;
  received?: boolean;
  invoiced?: boolean;
  shipped?: boolean;
  notes?: string;
  csvName?: string | null;
  orderMethod?: string;
  shippingUnitType?: string;
  supplierOrderArrivalDate?: DateStr;
  lastModifiedAt?: CustomerOrderLastModifiedAt;
  customerId?: number;
  sellDepartmentId?: number;
  subSellDepartmentId?: number;
  mfcAreaId?: number;
  salesPlanId?: number;
  importJobId?: number;
  lastModifiedUserId?: number;
  customerOrderProductGroups?: shame; // CustomerOrderProductGroupInputs
  supplierOrders?: shame; // RelatedOrderInputs
  routePlanId?: RoutePlanId;
  vendorId?: number;
  receivableOrderId?: number;
  micsSent?: boolean | null;
  micsSentAt?: DateTimeStr | null;
  micsSentAtUserId?: number | null;
}

@tableName('customerOrders')
export class CustomerOrder implements IRecord {
  static readonly UniqueConstraints: Array<keyof CustomerOrder> = ['customer', 'identifier', 'orderDate'];

  static readonly SchemaName = 'CustomerOrder';

  id?: CustomerOrderId;
  @property identifier: CustomerOrderIdentifier;
  @property @required orderType: CustomerOrderType;
  @property scanBased: boolean;
  @property @required orderDate: CustomerOrderOrderDate;
  @property orderStatus?: CustomerOrderStatus | 'Routed' | null;
  @property supplierPoStatus?: string | null;
  @property received: boolean | null;
  @property invoiced: boolean;
  @property shipped: boolean;
  @property notes?: string | null;
  @property csvName?: string | null;
  @property @required orderMethod: OrderMethod;
  @property @required shippingUnitType: ShippingUnitType;
  @property isBulk?: boolean | null;
  @property isBulkHardAsn?: boolean | null;
  @property isHardGoods?: boolean | null;
  @property poDate?: DateStr | null;

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name', 'identifier'] })
  @property @required customer: Saved<Customer>;
  customerId: CustomerId;

  @belongsTo('sellDepartments')
  @property @required sellDepartment: Saved<SellDepartment>;
  sellDepartmentId: SellDepartmentId;

  @belongsTo('subSellDepartments')
  @property subSellDepartment?: SubSellDepartment;
  subSellDepartmentId?: SubSellDepartmentId;

  @belongsTo('mfcAreas')
  @property mfcArea?: MfcArea;
  mfcAreaId?: MfcAreaId;

  @belongsTo('salesPlans', { foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier', 'year'] })
  @property salesPlan?: SalesPlan;
  salesPlanId: SalesPlanId;

  @hasMany('customerOrderProducts')
  @property customerOrderProducts: CustomerOrderProduct[];

  @hasMany('customerOrderProductGroups', { foreignQueryKeys: ['isCombo'] })
  @property customerOrderProductGroups: CustomerOrderProductGroup[];

  @manyToMany('supplierOrders', { through: 'relatedOrders', foreignDisplayKey: 'identifier', foreignQueryKeys: ['plannedArrivalDate'] })
  @property supplierOrders?: SupplierOrder[];

  @property supplierOrderArrivalDate?: string;

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @property lastModifiedAt?: CustomerOrderLastModifiedAt | null;

  @belongsTo('users', { nativeTableFK: 'lastModifiedUserId', foreignDisplayKey: 'firstName', foreignQueryKeys: ['firstName', 'lastName'] })
  @property lastModifiedUser?: User;
  lastModifiedUserId?: number | null;

  @property micsSent?: boolean | null;
  @property micsSentAt?: DateTimeStr | null;

  @property micsSentAtUserId?: number | null;

  // The new stuff doesn't work with old schema
  // @belongsTo('routePlans')
  // @property routePlan?: RoutePlanRoot;
  // @belongsTo('routePlans', { foreignQueryKeys: [] })
  @property routePlanId?: RoutePlanId | null;

  @belongsTo('vendors', { nativeTableFK: 'vendorId', foreignDisplayKey: 'identifier', foreignQueryKeys: ['identifier'] })
  @property vendor?: Vendor;
  vendorId: number;

  @property micsEligible: boolean;
  @property distributionArrivalDate?: DateStr | null;

  @property invoiceIdentifier?: string;
  @property invoiceStatus?: string;

  @property ediInvoiced: boolean;
  @property invoiceSentToAcumatica: boolean;
  @property invoiceDownloaded: boolean;
  @property invoiceEmailed: boolean;

  @property receivingStatus?: ReceivableOrderReceivingStatus | null;

  @belongsTo('receivableOrders')
  @property receivableOrder: ReceivableOrder;
  receivableOrderId?: number;
}

// Validations moved to shared/validations and server/validations

definePresentation(CustomerOrder, {
  identifier: {
    searchable: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'PO #',
    tableDisplay: true,
    columnWidth: 14,
  },
  customer: {
    sortable: true,
    filterable: true,
    searchable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    tableDisplayColumns: { name: 'Customer' },
    tableDisplay: true,
    displayName: 'Customer',
    columnWidth: 15,
  },
  vendor: {
    displayName: 'Vendor',
    tableDisplayColumns: { identifier: 'Vendor' },
    sortable: true,
    searchable: true,
    filterable: false,
    tableDisplay: true,
    columnWidth: 12,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: VendorDisplayValueResolver }),
  },
  supplierOrderArrivalDate: {
    displayName: 'Arrival Date',
    sortable: true,
    filterable: false,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
    includeInFormQuery: true,
    formDisplay: true,
    calculationSpec: {
      dependencies: {
      },
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(CASE WHEN customer_orders.order_type = '${CustomerOrderType.ExternalDistribution}' THEN customer_orders.distribution_arrival_date ELSE (SELECT MAX(planned_arrival_date) FROM supplier_orders WHERE id IN (SELECT supplier_order_id FROM related_orders WHERE customer_order_id = ${customerOrder.id})) END)`;
      },
    },
  },
  orderDate: {
    displayName: 'Delivery Date',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
  },
  sellDepartment: {
    sortable: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    tableDisplay: true,
    displayName: 'Sell Department',
    columnWidth: 8,
  },
  subSellDepartment: {
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    tableDisplay: false,
  },
  mfcArea: {
    displayName: 'MFC Area',
    sortable: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    tableDisplayColumns: { identifier: 'MFC Area' },
    tableDisplay: true,
    columnWidth: 5,
  },
  orderType: {
    sortable: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: CustomerOrderType }),
    tableDisplay: true,
    columnWidth: 10,
  },
  orderMethod: {
    displayName: 'Order Method',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: OrderMethod }),
  },
  shippingUnitType: {
    displayName: 'Shipping Unit',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ShippingUnitType }),
  },
  orderStatus: {
    sortable: true,
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    tableDisplay: true,
    columnWidth: 7,
    defaultValue: CustomerOrderStatus.Draft,
    includeInSubmittedForm: true, // Can't change the order status in the UI without this
    calculationSpec: {
      dependencies: {
      },
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `CASE WHEN customer_orders.order_status = '${CustomerOrderStatus.Approved}' AND customer_orders.csv_name IS NOT NULL THEN 'Routed' ELSE customer_orders.order_status END`;
      },
    },
  },
  supplierPoStatus: {
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    displayName: 'Supplier POs',
    tableDisplay: false,
  },
  received: {
    type: TYPES.BOOLEAN,
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO_NULL, { displayValueResolver: ReceivedDisplayValueResolver }),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {

      },
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(
          SELECT
            CASE
              WHEN ro.id IS NOT NULL
                THEN ro.receiving_status = '${ReceivableOrderReceivingStatus.FullyReceived}'
              ELSE NULL
            END
          FROM
            customer_orders co
          LEFT OUTER JOIN
            receivable_orders ro ON ro.id = co.receivable_order_id
          WHERE
            co.id = ${customerOrder.id}
          )`;
      },
    },
  },
  invoiced: {
    // displayName: 'Invoiced'
    type: TYPES.BOOLEAN,
    sortable: true,
    tableDisplay: false,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    columnWidth: 5,
  },
  shipped: {
    type: TYPES.BOOLEAN,
    sortable: true,
    tableDisplay: true,
    formDisplay: false,
    includeInFormQuery: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
  },
  scanBased: {
    filterable: true,
    displayName: 'Scan Order',
    type: TYPES.BOOLEAN,
    formDisplayType: displayType(DISPLAY_TYPES.CHECKBOX),
    defaultValue: true,
  },
  notes: {
    searchable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.TEXTAREA }),
  },
  customerOrderProducts: {
    formDisplay: false,
  },
  salesPlan: {
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SalesPlanDisplayValueResolver }),
  },
  importJob: { formDisplay: false },
  supplierOrders: {
    includeInFormQuery: false,
  },
  micsSentAt: {
    type: TYPES.DATE_TIME,
    displayName: 'MICS Sent',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.DATE_TIME),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {
      },
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `CASE WHEN customer_orders.mics_sent = true AND customer_orders.mics_sent_at IS NULL THEN TIMESTAMP 'EPOCH' ELSE customer_orders.mics_sent_at END`;
      },
    },
  },
  micsSent: {
    type: TYPES.BOOLEAN,
    displayName: 'MICS Sent',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO_NULL, { displayValueResolver: ReceivedDisplayValueResolver }),
    includeInFormQuery: true,
  },
  lastModifiedAt: {
    type: TYPES.DATE_TIME,
    displayName: 'Last Modified',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplay: true,
    columnWidth: 10,
    includeInFormQuery: true,
  },
  /** DEPRECATED - This logic now lives in the CustomerOrderV2 entity (but needs to remain here to handle the scope) */
  micsEligible: {
    type: TYPES.BOOLEAN,
    displayName: 'MICS-able',
    sortable: false,
    filterable: true,
    tableDisplay: false,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {
      },
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(
          SELECT
            co.scan_based = false AND
            cust.identifier = '${KnownCustomer.Meijer}' AND
            (co.order_type = '${CustomerOrderType.ExternalDistribution}' OR sd.identifier = '${KnownSellDepartment.IndoorFloral}')
          FROM
            customer_orders co
          JOIN
            sell_departments sd ON co.sell_department_id = sd.id
          JOIN
            customers cust ON co.customer_id = cust.id
          WHERE
            co.id = ${customerOrder.id}
          )`;
      },
    },
  },
  distributionArrivalDate: {
    displayName: 'Arrival Date',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    columnWidth: 8,
    formDisplayType: displayType(DISPLAY_TYPES.DATE),
    type: TYPES.DATE,
  },
  invoiceIdentifier: {
    type: TYPES.STRING,
    displayName: 'Invoice Identifier',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(SELECT invoices.identifier FROM customer_order_invoices INNER JOIN invoices ON invoices.id = customer_order_invoices.invoice_id WHERE customer_order_invoices.customer_order_id = customer_orders.id)`;
      },
    },
  },
  // Yes means invoiced, No means not invoiced but could be, and N/A means it's not possible to invoice it
  invoiceStatus: {
    type: TYPES.STRING,
    displayName: 'Invoiced',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    formDisplay: true,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `
          CASE
            WHEN customer_orders.invoiced = TRUE
              THEN 'Yes'
            WHEN customer_orders.scan_based = FALSE AND (SELECT vendors.identifier FROM vendors WHERE vendors.id = customer_orders.vendor_id) = '${KnownVendor.MFC}'
              THEN 'No'
            ELSE
              'N/A'
          END
        `;
      },
    },
  },
  invoiceDownloaded: {
    type: TYPES.BOOLEAN,
    displayName: 'Invoiced Downloaded',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    formDisplay: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(SELECT invoices.first_downloaded_at IS NOT NULL FROM invoices WHERE id IN (SELECT invoice_id FROM customer_order_invoices WHERE customer_order_id = customer_orders.id))`;
      },
    },
  },
  ediInvoiced: {
    type: TYPES.BOOLEAN,
    displayName: 'EDI Invoiced',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    formDisplay: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(SELECT invoices.edi_invoiced_at IS NOT NULL FROM invoices WHERE id IN (SELECT invoice_id FROM customer_order_invoices WHERE customer_order_id = customer_orders.id))`;
      },
    },
  },
  invoiceEmailed: {
    type: TYPES.BOOLEAN,
    displayName: 'Invoice Emailed',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    formDisplay: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(SELECT invoices.emailed_at IS NOT NULL FROM invoices WHERE id IN (SELECT invoice_id FROM customer_order_invoices WHERE customer_order_id = customer_orders.id))`;
      },
    },
  },
  invoiceSentToAcumatica: {
    type: TYPES.BOOLEAN,
    displayName: 'Invoice Sent to Acumatica',
    sortable: false,
    filterable: false,
    tableDisplay: false,
    formDisplay: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    includeInFormQuery: true,
    calculationSpec: {
      dependencies: {},
      calculation: (customerOrder: Saved<CustomerOrder>) => {
        return `(SELECT invoices.transferred_to_accounting_at IS NOT NULL FROM invoices WHERE id IN (SELECT invoice_id FROM customer_order_invoices WHERE customer_order_id = customer_orders.id))`;
      },
    },
  },
  receivingStatus: {
    sortable: true,
    type: TYPES.STRING,
    tableDisplay: false,
    formDisplayType: displayType(DISPLAY_TYPES.STATUS),
    calculationSpec: {
      dependencies: {},
      calculation: () => `(SELECT receiving_status FROM receivable_orders WHERE receivable_orders.id = customer_orders.receivable_order_id)`,
     },
  },
});

setSchemaOptions(CustomerOrder, {
  defaultSort: { sortField: 'orderDate', sortOrder: 'DESC' },
  uniqueConstraints: CustomerOrder.UniqueConstraints,
  hasLastModifiedInfo: true,
});
