import * as React from 'react';
import { kebabCase, flowRight } from 'lodash';
import { NavItem } from 'client/components/third-party';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter, RouteChildrenProps } from 'react-router';
import { isActiveRoute } from 'client/helpers/route-helpers';

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    routeTo: route => dispatch(push(route)),
  };
};

interface OwnProps {
  label: string;
  route: string;
  // router: shame;
  disabled?: boolean;
  testid?: string;
}

interface DispatchProps {
  routeTo: shame;
}

class RecordNavItem extends React.Component<OwnProps & DispatchProps & RouteChildrenProps, shame> {
  render() {
    const onClick = () => {
      this.props.routeTo(this.props.route);
    };

    const isActive = isActiveRoute(this.props.route, this.props.location);

    const testid = this.props.testid
      ? this.props.testid
      : `${kebabCase(this.props.label)}-tab`;

    return (
      <NavItem onClick={onClick} active={isActive} disabled={this.props.disabled} data-testid={testid}>
        {this.props.label}
      </NavItem>
    );
  }
}

export default flowRight(
  withRouter,
  connect(undefined, mapDispatchToProps),
)(RecordNavItem);
