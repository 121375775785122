import * as React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'client/components/third-party';
import gql from 'graphql-tag';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { CustomerOrderId } from 'shared/schemas/customer-order';
import { ApolloRefetch } from 'client/types';
import * as Actions from 'client/actions/product-worksheet';
import { MutationStatus } from 'client/actions/mutations';
import { InitialState } from 'client/state/state';
import { PropsOf } from 'client/lib/react';
import { isBlank } from 'client/lib/meta';

const AddFromSalesPlanButtonCore = (p: {
  customerOrderId: CustomerOrderId,
  salesPlanIdentifier: string | undefined,
  refreshProductsList?: ApolloRefetch,
  onClick(): void,
  showSpinner: boolean,
  showCheck: boolean,
  dispatchLoadingState: () => void,
  dispatchCompleteState: () => void,
  dispatchInitialState: () => void,
}) => {
  const submitButtonLabel
    = p.showSpinner ? <span className="fa fa-spinner fa-spin" data-testid="add-from-sales-plan-spinner"/>
    : p.showCheck   ? <span className="fa fa-check" data-testid="add-from-sales-plan-check"/>
    :                     <span>Add from Sales Plan</span>;

  const tooltip = <Tooltip id={`sales-plan-tooltip`}>{p.salesPlanIdentifier ?? 'No Sales Plan Attached'}</Tooltip>;
  const shouldDisable = isBlank(p.salesPlanIdentifier);
  return (
    <OverlayTrigger trigger={['hover']} placement="top" delay={200} animation overlay={tooltip}>
      <div style={{ display: 'inline-block' }}>
        <Button bsClass="mfc-button mfc-submit-button mfc-button mfc-submit-button-primary mfc-add-from-sales-plan-button" onClick={p.onClick} disabled={shouldDisable}>{submitButtonLabel}</Button>
      </div>
    </OverlayTrigger>
  );
};

const withAddProductsFromSalesPlanMutation = msyncMutation<{}, PropsOf<typeof AddFromSalesPlanButtonCore>, { onClick: () => Promise<void> }>(gql`
  mutation AddProductsFromSalesPlan($customerOrderId: Int!) {
    addProductsFromSalesPlanToCustomerOrder(customerOrderId: $customerOrderId) {
      customerOrderProductGroup {
        customerOrder {
          id
          lastModifiedAt
        }
      }
    }
  }`, {
  alias: 'withAddProductsFromSalesPlanMutation',
  props: (args: shame) => ({
    onClick: async () => {
      try {
        args.ownProps.dispatchLoadingState();
        await args.mutate({ variables: { customerOrderId: args.ownProps.customerOrderId } });
        args.ownProps.dispatchCompleteState();
        setTimeout(args.ownProps.dispatchInitialState, 1500);
        if (args.ownProps.refreshProductsList) {
          await args.ownProps.refreshProductsList();
        }
      } catch (e) {
        console.error('Problem with thing');
      }
    },
  }),
});

type OwnProps = Pick<PropsOf<typeof AddFromSalesPlanButtonCore>, 'customerOrderId' | 'salesPlanIdentifier' | 'refreshProductsList'>;
type Component<P> = new (props: P) => React.Component<P, any>;
const WrappedContainer = flowRight(
  connect(
    (state: typeof InitialState) => ({
      showSpinner: state.productWorksheet.addProductsFromSalesPlanMutationStatus === MutationStatus.InProgress,
      showCheck: state.productWorksheet.addProductsFromSalesPlanMutationStatus === MutationStatus.Complete,
    }),
    dispatch => ({
      dispatchLoadingState: () => dispatch(Actions.setAddProductsFromSalesPlanMutation(MutationStatus.InProgress)),
      dispatchCompleteState: () => dispatch(Actions.setAddProductsFromSalesPlanMutation(MutationStatus.Complete)),
      dispatchInitialState: () => dispatch(Actions.setAddProductsFromSalesPlanMutation(MutationStatus.Initial)),
    })
  ),
  withAddProductsFromSalesPlanMutation,
)(AddFromSalesPlanButtonCore as shame) as shame as Component<OwnProps>;

export const AddFromSalesPlanButton = (props: OwnProps) => propToComponent(WrappedContainer, props);
