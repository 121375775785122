import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { ReportTypes, SelectableValue } from 'shared/types';

export const formName = 'supplierPerformanceReport';
export const reportType = ReportTypes.SupplierPerformanceReport;

export enum FormFields {
  id = 'id', // Preset ID

  customerId = 'customerId',
  supplierId = 'supplierId',

  dateRangeYearComparison = 'dateRangeYearComparison',

  sellDepartmentId = 'sellDepartmentId',
  scanBased = 'scanBased',
  poBased = 'poBased',
  productClassIds = 'productClassIds',
  productSubClassIds = 'productSubClassIds',
  supplierItemIds = 'supplierItemIds',
}

export interface FormValues {
  [FormFields.id]: number | undefined; // Preset ID

  [FormFields.customerId]: number | undefined;
  [FormFields.supplierId]: number | undefined;

  [FormFields.dateRangeYearComparison]: DateRangeYearComparisonValue | undefined;

  [FormFields.sellDepartmentId]: number | undefined;
  [FormFields.scanBased]: boolean;
  [FormFields.poBased]: boolean;
  [FormFields.productClassIds]: SelectableValue | undefined;
  [FormFields.productSubClassIds]: SelectableValue | undefined;
  [FormFields.supplierItemIds]: SelectableValue | undefined;
}
