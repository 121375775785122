import { send } from 'shared/send';
import * as FileSaver from 'client/utils/filesaver';
import { RoutePlanId, RoutePlanIdentifier } from 'schema/route-plan/route-plan-typescript-types';
import { LoadConfiguration } from 'shared/types';

export const downloadDittyCards = async (args: {
  routePlanId?: RoutePlanId,
  routePlanIdentifier: RoutePlanIdentifier,
  fileNameSuffix: string,
  loadConfiguration: LoadConfiguration,
  selectedRoutingLoadIds: number[],
}) => {

  const options = [
    `loadConfiguration=${args.loadConfiguration}`,
    `selectedRoutingLoadIds=${args.selectedRoutingLoadIds.join()}`,
  ].join('&');

  const pdfBlob = await send({
    fetch,
    url: `/report/dittyCard/${args.routePlanId}?${options}`,
    method: 'GET',
    asBlob: true,
  });

  const filename = `${args.routePlanIdentifier} - ${args.fileNameSuffix}.pdf`;

  FileSaver.saveAs(pdfBlob, filename);
};
