import gql from 'graphql-tag';

export const NotifyPeakMutation = gql`
  mutation notifyPeakReadyToInvoice($input: NotifyPeakReadyToInvoiceInput!) {
    notifyPeakReadyToInvoice: NotifyPeakReadyToInvoice(input: $input) {
      routingLoads {
        id
        sentToPeak
      }
    }
  }
`;
