import { DateStr, ReportTypes } from 'shared/types';

export const formName = 'cutProcessingReport';
export const reportType = ReportTypes.CutProcessing;

export enum FormFields {
  customerId = 'customerId',
  date = 'date',
}

export interface FormValues {
  [FormFields.customerId]: number | undefined;
  [FormFields.date]: DateStr | undefined;
}
