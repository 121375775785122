import { tableName, property, belongsTo, definePresentation, gqlResolver, setSchemaOptions, required, defineFieldValidators } from './dsl';
import {
  TYPES,
  DISPLAY_TYPES,
  INPUT_TYPES,
  ActiveInactive,
  FobLocations,
  SORT_TYPES,
  RackShippingUnit,
  ShippingUnitType,
} from '../types';

import { Product, ProductId } from './product';
import { Supplier, SupplierId } from './supplier';
import { Customer, CustomerId } from './customer';
import { SellDepartment } from './sell-department';
import { ProductClass } from './product-class';
import { ProductSubClass } from './product-sub-class';
import { IRecord } from './record';
import { displayType } from './dsl';
import * as Validators from 'shared/validators';
import { whenAll } from 'shared/validators';
import { isRackProduct, isActive } from 'shared/validators/product-validators';
import { ProductDisplayValueResolver, SupplierDisplayValueResolver, CustomerDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';
import { Clock } from 'shared/clock';

export type SupplierItemId = number;

@tableName('supplierItems')
export class SupplierItem implements IRecord {
  id?: SupplierItemId;
  @belongsTo('products', { foreignQueryKeys: ['identifier', 'description'] })
  @property @required product: Product;
  productId: ProductId;

  @belongsTo('suppliers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required supplier: Supplier;
  supplierId: SupplierId;

  @belongsTo('customers', { through: 'products', foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property customer: Customer;
  customerId: CustomerId;

  @belongsTo('sellDepartments', { through: 'productClasses' })
  @property sellDepartment: SellDepartment;

  @belongsTo('productClasses', { through: 'productSubClasses' })
  @property productClass: ProductClass;

  @belongsTo('productSubClasses', { through: 'products' })
  @property productSubClass: ProductSubClass;

  @property @required activeStatus: ActiveInactive;
  @property @required primaryShippingUnitType: ShippingUnitType;
  @property @required packSize: number;
  @property casesPerPallet?: number | null;
  @property packsPerRack?: number | null;
  @property packsPerShelf?: number | null;
  @property shelvesPerRack?: number | null;
  @property supplierProdIdentifier?: string | null;
  @property @required fobLocation: FobLocations;
  @property notes?: string | null;

  @property scanProduct: boolean; // Field that exists on the new product repository and copied here as a calculation spec
  @property isItsOwnReplenishmentProduct: boolean; // Field that exists on the new product repository and copied here as a calculation spec

  @gqlResolver(({ id }: any, _: any, context: any) => context.findRepository('SupplierItemCost', context).findCostOnDate(Clock.today(), id))
  @property activeCost: number | null;
}

defineFieldValidators(SupplierItem, {
  packSize: [Validators.GREATER_THAN_ZERO],
  packsPerRack: [
    Validators.GREATER_THAN_ZERO,
    ...whenAll(
      'When considering an Active, Rack-based Product: ',
      isRackProduct,
      isActive,
    )(
      Validators.REQUIRED,
      Validators.VALID_PPS_SPR_CALCULATION,
    ),
  ],
  packsPerShelf: [
    Validators.GREATER_THAN_ZERO,
    ...whenAll(
      'When considering an Active, Rack-based Product: ',
      isRackProduct,
      isActive,
    )(
      Validators.REQUIRED,
    ),
  ],
  shelvesPerRack: [
    Validators.GREATER_THAN_ZERO,
    ...whenAll(
      'When considering an Active, Rack-based Product: ',
      isRackProduct,
      isActive,
    )(
      Validators.REQUIRED,
    ),
  ],
  casesPerPallet: [Validators.GREATER_THAN_ZERO],
  primaryShippingUnitType: [Validators.INCLUSION(ShippingUnitType)],
  fobLocation: [Validators.INCLUSION(FobLocations)],
});

definePresentation(SupplierItem, {
  product: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    searchable: true,
    tableDisplayColumns: { identifier: 'Product', description: 'Description' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: ProductDisplayValueResolver }),
    columnWidth: 20,
  },
  supplier: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }),
    columnWidth: 20,
  },
  customer: {
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    columnWidth: 20,
  },
  sellDepartment: {
    columnWidth: 20,
    filterable: true,
    sortable: true,
    tableDisplay: true,
    formDisplay: false,
  },
  productClass: {
    displayName: 'Class',
    formDisplay: false,
    columnWidth: 20,
  },
  productSubClass: {
    displayName: 'Sub Class',
    formDisplay: false,
    columnWidth: 20,
  },
  activeStatus: {
    displayName: 'Status',
    searchable: false,
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 5,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
  primaryShippingUnitType: {
    displayName: 'Primary Shipping Unit',
    formDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.RADIO, { options: ShippingUnitType }),
    defaultValue: RackShippingUnit,
  },
  packSize: {
    type: TYPES.NUMBER,
    displayName: 'Supplier Pack',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  packsPerRack: {
    type: TYPES.NUMBER,
    displayName: 'Supplier PPR',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  packsPerShelf: {
    type: TYPES.NUMBER,
    displayName: 'Supplier PPS',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    columnWidth: 5,
  },
  shelvesPerRack: {
    type: TYPES.NUMBER,
    displayName: 'Supplier SPR',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  casesPerPallet: {
    type: TYPES.NUMBER,
    displayName: 'Supplier CPP',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
  },
  supplierProdIdentifier: {
    displayName: 'Supplier Prod ID',
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  fobLocation: {
    displayName: 'FOB Location',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: FobLocations }),
  },
  notes: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  activeCost: {
    formDisplayType: displayType(DISPLAY_TYPES.STATIC),
  },
  scanProduct: {
    displayName: 'Scan Product',
    tableDisplay: false,
    sortable: true,
    tableEditable: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    type: TYPES.BOOLEAN,
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `(
        SELECT
          scan_product
        FROM
          products
        WHERE
          products.id = supplier_items.product_id
        )`;
      },
    },
  },
  isItsOwnReplenishmentProduct: {
    displayName: 'Parent Replenishment Product',
    tableDisplay: false,
    sortable: true,
    tableEditable: false,
    columnWidth: 5,
    formDisplayType: displayType(DISPLAY_TYPES.YES_NO),
    type: TYPES.BOOLEAN,
    calculationSpec: {
      dependencies: {},
      calculation: () => {
        return `(
        SELECT
          products.identifier = products.replenishment_identifier
        FROM
          products
        WHERE
          products.id = supplier_items.product_id
        )`;
      },
    },
  },
});

setSchemaOptions(SupplierItem, {
  defaultSort: { sortOrder: SORT_TYPES.ASC, sortField: 'product', foreignColumn: 'identifier' },
});
