import { connect } from 'react-redux';
import * as _ from 'lodash';
import { flowRight } from 'lodash';
import { SalesPlansTableUI, UIProps, SalesPlansListTableRow } from './ui';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery, MsyncDataRequest } from 'client/hoc/graphql/query';
import { ActiveSort, AvailableFilter } from 'client/types';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { SalesPlansQueryResponse, GetSalesPlansQuery, GetSalesPlansExcelQuery } from './query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { FilterSpecificationInput, TYPES } from 'shared/types';
import { FetchPolicy } from 'apollo-client';
import * as Actions from './actions';
import { EMPTY_ARRAY } from 'client/constants';
import * as State from 'client/state/state';
// import * as SalesPlanListState from 'client/state/salesPlan-list';
import * as TableState from './table-state';
import { withSaveConfirmation } from 'client/hoc/with-save-confirmation';
import { saveConfirmationOptionsForSalesPlans, ConfirmOkToSaveFunction } from './save-confirmation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeFilters: FilterSpecificationInput[];
  tablePageNumber: number;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const salesPlanListTableState = TableState.genericTableActions.tableStateLens(state);

  return {
    activeSortFields: TableState.genericTableActions.activeSortFields(salesPlanListTableState),
    activeFilters: TableState.genericTableActions.activeFilters(salesPlanListTableState),
    tablePageNumber: TableState.genericTableActions.tablePageNumber(salesPlanListTableState),
  };
};

interface DispatchProps {
  onNewClicked(): void;
  onRowSelected(row: { id: number }): void;
  onDeleteMenuItemClicked(ids: number[], confirmOkToSave: ConfirmOkToSaveFunction): void;
}

const mapDispatchToProps = {
  onNewClicked: Actions.newRecordButtonClicked,
  onRowSelected: Actions.rowSelected,
  onDeleteMenuItemClicked: Actions.deleteMenuItemClicked,
};

interface WithSalesPlanRowProps {
  rows?: SalesPlansListTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  dataRequest: MsyncDataRequest;
  availableFilters: AvailableFilter[];
}

const WithSalesPlanRows = msyncQuery<SalesPlansQueryResponse, OwnProps & StateProps & WithTableParentProps, WithSalesPlanRowProps, {}>(GetSalesPlansQuery, {
  alias: 'withSalesPlanRows',
  skip(ownProps) {
    return !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps): { variables, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: ownProps.activeFilters,
        sort: ownProps.activeSortFields,
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSalesPlanRowProps {
    const { data, ownProps } = props;

    const rows: SalesPlansListTableRow[] | undefined = data.getSalesPlans
      ? data.getSalesPlans.salesPlans.map(r => ({ ...r, varietyStatus: _.startCase(r.varietyStatus) }))
      : undefined;

    const filters = _.flatten(ownProps.activeFilters.map(filter => filter.values)).join(', ');
    const workbookName = `Sale Plans${filters ? ` - ${filters}` : ''}`;

    return {
      availableFilters: [
        {
          displayName: 'Year',
          field: 'year',
          type: TYPES.STRING,
          options: data.getSalesPlansFilterOptions ? data.getSalesPlansFilterOptions.years : [],
        },
        {
          displayName: 'Season',
          field: 'identifier',
          type: TYPES.STRING,
          options: data.getSalesPlansFilterOptions ? data.getSalesPlansFilterOptions.identifiers : [],
        },
        {
          displayName: 'Customer',
          field: 'customerIdentifier',
          options: data.getSalesPlansFilterOptions ? data.getSalesPlansFilterOptions.customers : [],
        },
        {
          displayName: 'Sell Department',
          field: 'sellDepartmentIdentifier',
          type: TYPES.STRING,
          options: data.getSalesPlansFilterOptions ? data.getSalesPlansFilterOptions.sellDepartments : [],
        },
        {
          displayName: 'Variety/Mix Status',
          field: 'varietyStatus',
          type: TYPES.STRING,
          options: data.getSalesPlansFilterOptions ? data.getSalesPlansFilterOptions.varietyStatuses : [],
        },
      ],
      rows,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getSalesPlans ? data.getSalesPlans.ids : EMPTY_ARRAY,
      totalCount: data.getSalesPlans ? data.getSalesPlans.totalCount : 0,
      totalUnfilteredCount: data.getSalesPlans ? data.getSalesPlans.totalUnfilteredCount : 0,
      dataRequest: {
        query: GetSalesPlansExcelQuery,
        variables: {
          filters: ownProps.activeFilters,
          sort: ownProps.activeSortFields,
          limit: ownProps.tableParentInfo.rowsPerPage,
          offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
        },
        workbookName,
      },
    };
  },
});

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

interface WithSaveConfirmationProps {
  confirmOkToSave(): Promise<boolean>;
}

export type ComponentProps =
  OwnProps &
  DispatchProps &
  StateProps &
  WithSalesPlanRowProps &
  WithSaveConfirmationProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  withSaveConfirmation(saveConfirmationOptionsForSalesPlans),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  WithSalesPlanRows,
)(SalesPlansTableUI) as Component<OwnProps>;

assertCompatible<UIProps, ComponentProps>();
export default (props: OwnProps) => propToComponent(component, props);
