import { PerformanceReport } from './performance-report';
import { DailySalesReport, MobileSalesPerformanceReport } from './sales';
import { BuyerReport } from './buyer-report';

export default {
  PerformanceReport,
  DailySalesReport,
  MobileSalesPerformanceReport,
  BuyerReport,
};
