import * as React from 'react';
import NumberCell from 'client/components/table/number-cell';

export interface TotalStoresFooterCellUIProps {
  totalFilteredStoresCount: number;
}

export class TotalStoresFooterCellUI extends React.PureComponent<TotalStoresFooterCellUIProps, {}> {
  onChangeHandler = () => {
    // Footer should never get an onChange
  }

  render() {
    const props = this.props;

    return (
      <div>
        <NumberCell
          editing={false}
          value={props.totalFilteredStoresCount}
          centered={true}
          onChange={this.onChangeHandler}
          testid="footer-cell"
        />
      </div>
    );
  }
}
