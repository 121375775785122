import gql from 'graphql-tag';
import * as _ from 'lodash';
import { msyncQuery } from 'client/hoc/graphql/query';
import { recordType as getRecordType, columnInfo } from '../../../shared/schemas';
import { AvailableFilter, ActiveFilter } from 'client/types';
import { Scope, ScopeContext } from 'client/hoc/data-container';
import { createSelector } from 'reselect';

export interface FilterOptionsProps { availableFilters?: AvailableFilter[] }
interface ResultProps { filterOptions: AvailableFilter[] }
interface FilterOptionsArgs { table: string, fields: string[], scope?: Scope }
const FILTER_OPTIONS_QUERY = gql`
  query GetRecordFilterOptions($filterQuery: FilterOptionsInput!, $scope: [FilterSpecificationInput]) {
    filterOptions(filterQuery: $filterQuery, scope: $scope) {
      field,
      options {
        id,
        value,
        _customType,
        displayValue
      }
    }
  }
`;

export const getFilterOptionsSelector = (table, fields) => createSelector(
  (data: { filterOptions?: AvailableFilter[] }) => data.filterOptions || [],
  (data, activeFilters: ActiveFilter[] | undefined) => activeFilters,
  (filterOptions, activeFilters) => {
    const availableFilters: AvailableFilter[] = fields.reduce((filters: AvailableFilter[], field) => {
      const info = columnInfo(table, field);
      const option = filterOptions.find(c => c.field === field);
      filters.push({
        displayName: info.displayName,
        type: info.type,
        ...(option || {}),
        field: option && info.fkSpec ? `${info.id}.${info.fkSpec.foreignDisplayKey}` : field,
      });
      return filters;
    }, []);

    // It's possible, through user interaction, that an active filter is no longer an "available" filter
    // (meaning no records in the DB match that filter). This leads to a strange situation where the table
    // indicates we are filtering by that value, but the dropdown does not include it or provide a way to
    // turn it off.
    //
    // To address this, make sure each filter menu contains all of the currently selected "active" filters.
    if (activeFilters) {
      activeFilters.forEach(activeFilter => {
        let reSort = false;
        const matchingFilter = availableFilters.find(f => f.field === activeFilter.field);
        if (!matchingFilter) return; // continue
        const options = matchingFilter.options;
        if (options) {
          activeFilter.values
            .filter(v => !options.find(o => o.value === v))
            .forEach(v => { options.push({ id: v, value: v, displayValue: v }); reSort = true; });
        } else {
          matchingFilter.options = activeFilter.values.map(val => ({ id: val, value: val, displayValue: val }));
          reSort = true;
        }

        if (reSort && matchingFilter.options)
          matchingFilter.options = _.sortBy(matchingFilter.options, 'displayValue');

      });
    }

    return { availableFilters };
  },
);

export const withRecordFilterOptions = (config: FilterOptionsArgs) => {
  const filterOptionsSelector = getFilterOptionsSelector(config.table, config.fields);
  const recordType = getRecordType(config.table);
  const withFilters = msyncQuery<ResultProps, ScopeContext, { availableFilters: AvailableFilter[] }>(FILTER_OPTIONS_QUERY, {
    alias: 'withRecordFilterOptions',
    options: ownProps => ({ variables: {
      filterQuery: { type: recordType, fields: config.fields },
      scope: config.scope ? config.scope(ownProps) || [] : [],
    }}),
    props: props => filterOptionsSelector(props.data, props.ownProps.activeFilters),
  });

  return withFilters;
};
