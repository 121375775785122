import * as React from 'react';
import { Row } from 'client/components/third-party';
import { RecordBarStatusToggle } from './record-bar-status-toggle';
import { RecordBarIdentifier } from './record-bar-identifier';
import { RecordBarBackButton } from './record-bar-back-button';
import { RecordBarUndoRedoButtons } from './record-bar-undo-redo-buttons';
import { Saved } from 'shared/schemas/record';
import { DateTimeStr } from 'shared/types';
import { formatDateTime } from 'shared/helpers/date-helpers';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RecordBarActionMenu, RecordBarActionMenuItem } from './record-bar-action-menu';

export interface Props {
  handleSubmit: () => Promise<boolean | void>;
  confirmOkToSave?: () => Promise<boolean>;
  submitting: boolean;
  invalid: boolean;
  pristine: boolean;
  submitted: boolean;
  onClose: () => void;
  resetForm: () => void;
  table: string;
  onChangeRecordStatus: (field: string, val: any) => void;
  description: string;
  record: any;
  initialRecord?: Saved<{ lastModifiedAt?: DateTimeStr }>;
  RecordBarDetailComponent?: (x: any) => any;
  OptionalStatusToggle?: (x: any) => any;
  title?: string;
  recordBarActionMenuItems?: RecordBarActionMenuItem[];
}

export class RecordBar extends React.PureComponent<Props, {}> {

  handleSubmitButtonClicked = async () => {
    let confirmed: boolean = false;

    if (this.props.confirmOkToSave) {
      confirmed = await this.props.confirmOkToSave();
    } else {
      // No function specified, so OK to proceed
      confirmed = true;
    }

    if (confirmed) {
      await this.props.handleSubmit();
    }
  }

  render() {
    const {
      initialRecord,
      record,
      description,
      table,
      OptionalStatusToggle,
      RecordBarDetailComponent,
      pristine,
      invalid,
      submitting,
      onClose,
      resetForm,
      onChangeRecordStatus,
      title,
    } = this.props;

    return (
      <Row className="record-bar-row">
        <div className="record-bar">
          <div className="detail-area">
            <RecordBarBackButton onClick={onClose} />
            <RecordBarIdentifier description={description} table={table} title={title} />

            {RecordBarDetailComponent && <RecordBarDetailComponent record={initialRecord} />}

          </div>
          <div className="action-area">

            {initialRecord?.id && initialRecord.updatedAt &&
              <div className="last-save">
                Last Modified: {formatDateTime(initialRecord.lastModifiedAt || initialRecord.updatedAt, 'l - LT z')}
              </div>
            }

            {OptionalStatusToggle
              ? <OptionalStatusToggle onChangeRecordStatus={onChangeRecordStatus} record={record} />
              : <RecordBarStatusToggle onChangeRecordStatus={onChangeRecordStatus} record={record} />
            }

            {this.props.recordBarActionMenuItems && this.props.recordBarActionMenuItems.length > 0 && record &&
              <RecordBarActionMenu
                recordId={record.id}
                menuItems={this.props.recordBarActionMenuItems}
              />
            }

            <RecordBarUndoRedoButtons onClickUndo={!pristine ? resetForm : undefined} />
            <div className="detail">
              <GlobalSaveButton
                label={'Save'}
                disabled={pristine || submitting || invalid}
                testid={'save-button'}
                onClick={this.handleSubmitButtonClicked}
              />
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
