import * as React from 'react';
import { StatsCard } from 'client/components/simple-components/simple-stats-card';
import { formatMoneyOrNull, formatInteger } from 'shared/types';

export interface OwnProps {
  stopsCount: number;
  racksCount: number;
  palletsCount: number;
  loadsCount: number;
  storesCount: number;
  milesCombinedWithAdditionalMiles: number;
  totalFee: number;
}

export const RoutePlanLoadsStatsRow: React.SFC<OwnProps> = props => {
  return (
    <div className="stats-row small">
      <StatsCard title="Stops"> {formatInteger(props.stopsCount)} </StatsCard>
      <StatsCard title="Racks"> {formatInteger(props.racksCount)} </StatsCard>
      <StatsCard title="Pallets"> {formatInteger(props.palletsCount)} </StatsCard>
      <StatsCard title="Loads"> {formatInteger(props.loadsCount)}  </StatsCard>
      <StatsCard title="Stores"> {formatInteger(props.storesCount)}  </StatsCard>
      <StatsCard title="Total Miles"> {formatInteger(props.milesCombinedWithAdditionalMiles)}  </StatsCard>
      <StatsCard title="Total Fee"> {formatMoneyOrNull(props.totalFee)}  </StatsCard>
    </div>
  );
};
