import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { FieldWrapper } from '../form';
import { optionsContainerGenerator } from '../../hoc/options-container-generator';
import { PropsWithChildren } from 'react';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="mfcAreas" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;

const MfcAreaForm = (p: PropsWithChildren<{
  handleSubmit: ((...args: unknown[]) => unknown),
  customers: Array<{ id: number, identifier: string, name: string }>,
  customersLoading: boolean,
  sellDepartments: Array<{ id: number, identifier: string }>,
  sellDepartmentsLoading: boolean,
  record: {id?: number},
}>) => (
  <Col sm={12}>
    <Form horizontal onSubmit={p.handleSubmit}>
      <div className="simple-form" data-testid="mfc-area">
        <FormGroup>
          <Field name="identifier" labelColSize={2} inputColSize={9} autoFocus={!p.record?.id} />
        </FormGroup>
        <FormGroup>
          <Field name="customerId" labelColSize={2} inputColSize={9} options={p.customers} loading={p.customersLoading} />
        </FormGroup>
        <FormGroup>
          <Field name="sellDepartmentId" labelColSize={2} inputColSize={9} options={p.sellDepartments} loading={p.sellDepartmentsLoading} />
        </FormGroup>
      </div>
    </Form>
  </Col>
);
const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });
const withSellDepartments = optionsContainerGenerator({table: 'sellDepartments', columns: ['identifier']});

export default withCustomers(withSellDepartments(MfcAreaForm));
