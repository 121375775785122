import { connect } from 'react-redux';
import * as State from 'client/state/state';
import * as ImportRouteSelectors from 'client/state/import-route-selectors';
import { propToComponent } from 'client/hoc/hoc';
import { StoreListUI, ComponentProps as UIProps } from './store-list-ui';
import { flowRight } from 'lodash';
import assertCompatible from 'shared/helpers/assert-compatible';
import { GlobalStoreIdentifier } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  isFullyAllocatedByStoreNumber: { [k: string]: boolean };
  storeIdentifiers: GlobalStoreIdentifier[];
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {
  const storeIdentifiers = ImportRouteSelectors.getAllStoresWithSplitStops(state);
  return {
    storeIdentifiers,
    isFullyAllocatedByStoreNumber: storeIdentifiers.reduce((acc, storeIdentifier) => Object.assign(acc, {[storeIdentifier.storeNumber]: ImportRouteSelectors.isStoreFullyAllocated(state, storeIdentifier)}), {}),
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
}

const mapDispatchToProps: DispatchProps = {
};

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UIProps, ComponentProps>();

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
)(StoreListUI) as Component<OwnProps>;

export const StoreListContainer = (props: OwnProps) => propToComponent(component, props);
