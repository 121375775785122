import gql from 'graphql-tag';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';

export interface DefaultFilterDataResponse {
  response?: {
    invoice: {
      id: InvoiceGraphqlTypes.Invoice['id'];
      customer: Pick<InvoiceGraphqlTypes.DropShipInvoice['customer'], 'id' | 'name' | 'identifier'>;
      weekEndingDate: InvoiceGraphqlTypes.DropShipInvoice['weekEndingDate'];
    }
    totalCount: number;
  };
}

export interface DefaultFilterDataInput {
  invoiceId: number;
}

export const GetDefaultFilterDataQuery = gql`
query GetInvoiceForDefaultFiltersFilters($invoiceId: Int!) {
  response: GetInvoice(id: $invoiceId) {
    invoice {
      id
      customer {
        id
        name
        identifier
      }
      ... on DropShipInvoice {
        id
        weekEndingDate
      }
    }
  }
}
`;
