import gql from 'graphql-tag';

export interface DeleteSupplierCommitmentsInput {
  ids: number[];
}

export interface DeleteSupplierCommitmentsResponse {
  response: {
    count: number;
  };
}

export const DeleteSupplierCommitmentsMutation = gql`
  mutation DeleteSupplierCommitmentsMutation($ids: [Int!]!) {
    response: DeleteSupplierCommitments(ids: $ids) {
      count
    }
  }
 `;

export interface DownloadSupplierSpecSheetPdfMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadSupplierSpecSheetPdfMutationInput {
  supplierCommitmentIds: number[];
}

export const DownloadSupplierSpecSheetPdfMutation = gql`
  mutation DownloadSupplierSpecSheetPdfFromListMutation($supplierCommitmentIds: [Int!]!) {
    response: DownloadSupplierSpecSheetPdf(supplierCommitmentIds: $supplierCommitmentIds) {
      report {
        id
      }
    }
  }
`;
