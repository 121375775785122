import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { wrapComponent } from 'client/hoc/hoc';
import { LoadTrackingListUI } from './ui';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as SharedActions from '../shared/actions';
import * as Queries from '../shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { reportingForm, ReportingFormProps } from 'client/components/report-user-params/reporting-form';

export interface OwnProps {
}

const mapStateToProps = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (_.isEmpty(formValues)) {
    return {
      carriers: [],
      downloadButtonDisabled: true,
      beginDate: undefined,
      endDate: undefined,
      selectedLoadTrackingSortByOption: '',
      loadTrackingReportHeader: 'Load Tracking List',
      reportDownloadStatus: MutationStatus.Initial,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);

  return {
    carriers: formValues[Constants.FormFields.carriers],
    downloadButtonDisabled: shouldDownloadBeDisabled,
    beginDate: formValues[Constants.FormFields.beginDate],
    endDate: formValues[Constants.FormFields.endDate],
    selectedLoadTrackingSortByOption: formValues[Constants.FormFields.selectedLoadTrackingSortByOption],
    loadTrackingReportHeader: formValues[Constants.FormFields.loadTrackingReportHeader],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
  };
};

const mapDispatchToProps = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleDateRangeChanged: SharedActions.handleDateRangeChanged(Constants.formName, Constants.FormFields),
  handleLoadTrackingSortByOptionChanged: Actions.handleLoadTrackingSortByOptionChanged,
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CombinedProps =
  OwnProps &
  ConnectedProps<typeof connector> &
  ReduxFormProps &
  UserParamsMapperProps &
  ReportingFormProps;

export const LoadTrackingList = wrapComponent(LoadTrackingListUI)<OwnProps, CombinedProps>(
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {},
  }),
  connector,
  Queries.withCustomers,
  UserParamsMappers.withLoadTrackingListReportUserParamMappers,
);
