import { Lens } from '@atomic-object/lenses';
import { MutationStatus } from 'client/actions/mutations';
import { every } from 'lodash';

export interface SupplierOrderEmailRecipient {
  supplierContactId: number;
  name: string;
  selected: boolean;
}

interface SupplierOrderState {
  readonly addNewProductModalShown: boolean;
  readonly editSupplierOrderProductGroupModalShown: boolean;
  readonly supplierOrderProductToEditId?: number;
  readonly sendSupplierPOModalShown: boolean;
  readonly supplierOrderContacts: SupplierOrderEmailRecipient[];
  readonly sendSupplierOrderActionStatus: MutationStatus;
}

export const InitialState: SupplierOrderState = {
  addNewProductModalShown: false,
  editSupplierOrderProductGroupModalShown: false,
  sendSupplierPOModalShown: false,
  supplierOrderContacts: [],
  sendSupplierOrderActionStatus: MutationStatus.Initial,
};

export type Type = SupplierOrderState;

export const isSelectAllSelected = Lens.of<Type, boolean>(
  {
    get: state => state.supplierOrderContacts.length > 0 && every(state.supplierOrderContacts, (contact => contact.selected)),
    set: (state, value) => {
      return {
        ...state,
        supplierOrderContacts: state.supplierOrderContacts.map(contact => {
          return {
            ...contact,
            selected: value,
          };
        }),
      };
    },
  },
);
