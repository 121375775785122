import * as React from 'react';
import { Col, FormControl } from 'client/components/third-party';

interface Props {
  page: number;
  pages: number;
  onPageChange: (pageNumber: number) => void;
}

interface State {
  page: number | undefined;
}

export class PaginationComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      page: this.props.page + 1,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ page: nextProps.page + 1});
  }

  render() {
    const firstPreviousDisabled = this.props.page === 0;
    const nextLastDisabled = this.props.page + 1 === this.props.pages;

    return <div className="table-paginator">
      <Col sm={4}>
        <div className="btn-group" role="group">
          <button id="first-page-button" className="mfc-button-table" onClick={() => this.props.onPageChange(0)} disabled={firstPreviousDisabled}><div className={!firstPreviousDisabled ? 'mfc-icon-first-page' : 'mfc-icon-first-page-disabled'}></div></button>
          <button id="previous-page-button" className="mfc-button-table" onClick={() => this.props.onPageChange(this.props.page - 1)} disabled={firstPreviousDisabled}><div className={!firstPreviousDisabled ? 'mfc-icon-previous-page' : 'mfc-icon-previous-page-disabled'}></div></button>
        </div>
      </Col>
      <Col sm={4} className="page-indicator text-center">
        Page
        <FormControl
          id="page-number"
          onBlur={() => {
            if (this.state.page) {
              this.props.onPageChange(this.state.page - 1);
            } else {
              // reset
              this.setState({
                page: this.props.page + 1,
              });
            }
          }}
          onChange={(event: any) => {
            if (!Number.isNaN(event.target.valueAsNumber)) {
              const page: number = Math.floor(event.target.valueAsNumber);

              if (page >= 1 && page <= this.props.pages) {
                this.setState({ page });

                this.props.onPageChange(page - 1);
              } else {
                event.preventDefault();
              }
            } else {
              this.setState({ page: undefined });
            }
          }}
          type="number"
          key="page"
          value={this.state.page}
        />
        of <span id="total-pages">{this.props.pages}</span>
      </Col>
      <Col sm={4} className="text-right">
        <div className="btn-group" role="group">
          <button id="next-page-button" className="mfc-button-table" onClick={() => this.props.onPageChange(this.props.page + 1)} disabled={nextLastDisabled}><div className={!nextLastDisabled ? 'mfc-icon-next-page' : 'mfc-icon-next-page-disabled'}></div></button>
          <button id="last-page-button" className="mfc-button-table" onClick={() => this.props.onPageChange(this.props.pages - 1)} disabled={nextLastDisabled}><div className={!nextLastDisabled ? 'mfc-icon-last-page' : 'mfc-icon-last-page-disabled'}></div></button>
        </div>
      </Col>
    </div>;
  }
}
