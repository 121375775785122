import gql from 'graphql-tag';

export interface DownloadProductSpecSheetPdfMutationResponse {
  response: {
    report: {
      id: number;
    };
  };
}
export interface DownloadProductSpecSheetPdfMutationInput {
  salesPlanId: number;
  productId: number;
}

export const DownloadProductSpecSheetPdf = gql`
  mutation DownloadProductSpecSheetPdf($salesPlanId: Int!, $productId: Int!) {
    response: DownloadProductSpecSheetPdf(salesPlanId: $salesPlanId, productId: $productId) {
      report {
        id
      }
    }
  }
`;
