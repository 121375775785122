import 'reflect-metadata';
import { tableName, property, definePresentation, belongsTo, required } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { ImportableDocument } from 'shared/schemas/importable-document';
import { displayType } from './dsl';
import { IRecord } from 'shared/schemas/record';

@tableName('importableDocumentContents')
export class ImportableDocumentContent implements IRecord {
  id?: number;
  @property @required fileName: string;
  @property @required fileBytes: Buffer;

  @belongsTo('importableDocuments')
  @property @required importableDocument?: ImportableDocument;
  importableDocumentId?: number;
}

definePresentation(ImportableDocumentContent, {
  fileName: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  fileBytes: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
  importableDocument: { formDisplayType: displayType(DISPLAY_TYPES.INPUT) },
});
