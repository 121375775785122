import { Lens } from '@atomic-object/lenses';

interface SupplierCommitmentState {
  readonly importSupplierCommitmentModalShown: boolean;
  readonly updateSupplierItemCostsModalShown: boolean;
}

export type Type = SupplierCommitmentState;

export const InitialState: Readonly<SupplierCommitmentState> = Object.freeze({
  importSupplierCommitmentModalShown: false,
  updateSupplierItemCostsModalShown: false,
});

export const importSupplierCommitmentModalShown = Lens.from<Type>().prop('importSupplierCommitmentModalShown');
export const updateSupplierItemCostsModalShown = Lens.from<Type>().prop('updateSupplierItemCostsModalShown');
