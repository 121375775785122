import { HeaderCell } from './header-cell';
import { VariableSizeGrid as Grid } from 'react-window';
import * as React from 'react';
import * as Types from './types';
import * as Selectors from './selectors';

export const TableHeader = React.memo((props: Types.HeaderProps) => {
  return (
    <Grid
      ref={props.headerRef}
      className="readonly-table-header"
      style={{ overflow: 'hidden' }}
      rowCount={1}
      columnCount={Selectors.getColumnCount(props)}
      columnWidth={Selectors.getColumnWidthFunction(props)}
      height={Selectors.getRowHeight(props)}
      rowHeight={Selectors.getRowHeightFunction(props)}
      width={Selectors.getHeaderWidth(props)}
      itemData={Selectors.getItemDataForHeaderCell(props)}
    >
      {HeaderCell}
    </Grid>
  );
});
