import gql from 'graphql-tag';
import { FilterOption } from 'shared/types';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import { extractQueryName } from 'client/utils/query-utils';

// NOTE: In other places we're treating this like a discriminated union,
//       but here it's just one type with a couple of optional fields (customer order
//       and store). I think it would be better to use the discriminated union here
//       as well, but that would require more work than I have time for right now.
export interface InvoicesQueryResponse {
  getInvoices: {
    invoices: Array<{
      id: InvoiceGraphqlTypes.Invoice['id'];
      type: InvoiceGraphqlTypes.Invoice['type'];
      identifier: InvoiceGraphqlTypes.Invoice['identifier'];
      invoiceDate: InvoiceGraphqlTypes.Invoice['invoiceDate'];
      ediInvoiced: InvoiceGraphqlTypes.Invoice['ediInvoiced'];
      transferredToAccounting: InvoiceGraphqlTypes.Invoice['transferredToAccounting'];
      totalPrice: InvoiceGraphqlTypes.Invoice['totalPrice'];
      customer: Pick<InvoiceGraphqlTypes.Invoice['customer'], 'identifier' | 'name'>;
      deliveryDate: InvoiceGraphqlTypes.Invoice['deliveryDate'];
      paidDate?: InvoiceGraphqlTypes.VendorChargebackInvoice['paidDate'];
      supplier?: Pick<InvoiceGraphqlTypes.VendorChargebackInvoice['supplier'], 'name'>;
      supplierOrderIdentifier?: InvoiceGraphqlTypes.VendorChargebackInvoice['supplierOrderIdentifier'];
      supplierOrderPlannedArrivalDate?: InvoiceGraphqlTypes.VendorChargebackInvoice['supplierOrderPlannedArrivalDate'];
      customerOrder?: Pick<InvoiceGraphqlTypes.CustomerOrderInvoice['customerOrder'], 'identifier'>;
      store?: Pick<InvoiceGraphqlTypes.CustomerOrderInvoice['store'], 'identifier'>;
    }>
    ids: number[];
    totalCount: number;
    totalUnfilteredCount: number;
  };
  getInvoicesFilterOptions?: {
    customers: FilterOption[];
    types: FilterOption[];
  };
}

const InvoicesListFragment = gql`
  fragment InvoicesListFragment on Invoice {
    id
    type
    identifier
    invoiceDate
    ediInvoiced
    transferredToAccounting
    totalPrice
    deliveryDate

    customer {
      id
      identifier
      name
    }

    ... on VendorChargebackInvoice {
      paidDate
      supplierOrderIdentifier
      supplierOrderPlannedArrivalDate
      supplier {
        id
        identifier
        name
      }
    }

    ... on CustomerOrderInvoice {
      id
      customerOrder {
        id
        identifier
        orderDate
        ediInvoiced
        invoiceSentToAcumatica
      }

      store {
        id
        identifier
      }
    }
  }
`;

export const GetInvoicesQuery = gql`
  query GetInvoicesWithFilterOptionForList($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    getInvoices: GetInvoices(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      invoices {
        ...InvoicesListFragment
      }
      ids
      totalCount
      totalUnfilteredCount
    }
    getInvoicesFilterOptions: GetInvoiceFilterOptions {
      customers {
        id
        value
        displayValue
      }
      types {
        id
        value
        displayValue
      }
    }
  }
  ${InvoicesListFragment}
`;
export const GetInvoicesQueryName = extractQueryName(GetInvoicesQuery);

export const GetInvoicesExcelQuery = gql`
  query GetInvoicesExcelQuery($sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    GetInvoices(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
      invoices {
        ...InvoicesListFragment
      }
    }
  }
  ${InvoicesListFragment}
`;
