import gql from 'graphql-tag';

export const AssignLoadsToTrailersMutation = gql`
  mutation assignLoadsToTrailers($input: AssignLoadsToTrailersInput!) {
    assignLoadsToTrailers: AssignLoadsToTrailers(input: $input) {
      routingLoads {
        id
        trailerNumber
      }
    }
  }
`;
