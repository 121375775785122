import { getWorkBookContainerFromBinaryString } from './common';
import { WorkBookContainer } from './common';
import * as _ from 'lodash';
import * as CustomerOrderSpreadsheetParser from './customer-order-spreadsheet-parser';
import * as DistributionRackShippingSpreadsheetParser from './distribution-rack-shipping-spreadsheet-parser';
import * as ProductTossSpreadsheetParser from './product-toss-spreadsheet-parser';
import * as SalesPlanSpreadsheetParser from './sales-plan-spreadsheet-parser';
import * as SalesPlanVarietySpreadsheetParser from './sales-plan-variety-spreadsheet-parser';
import * as SupplierCommitmentSpreadsheetParser from './supplier-commitment-spreadsheet-parser';

export enum SpreadsheetType {
  CustomerOrder = 'CustomerOrder',
  DistributionRackShipping = 'DistributionRackShipping',
  ProductToss = 'ProductToss',
  SalesPlan = 'SalesPlan',
  SalesPlanVariety = 'SalesPlanVariety',
  SupplierCommitment = 'SupplierCommitment',
}

const SpreadsheetParsers: { [k in keyof typeof SpreadsheetType]: (workBookContainer: WorkBookContainer) => Promise<any> } = {
  CustomerOrder: CustomerOrderSpreadsheetParser.getImportableCustomerOrderFromWorkBookContainer,
  DistributionRackShipping: workBookConatiner => DistributionRackShippingSpreadsheetParser.getDistributionRackShippingLineItemsFromWorkBookContainer(workBookConatiner, []),
  ProductToss: ProductTossSpreadsheetParser.getImportableProductTossSheet,
  SalesPlan: SalesPlanSpreadsheetParser.getImportableSalesPlan,
  SalesPlanVariety: SalesPlanVarietySpreadsheetParser.getImportableSalesPlanVarieties,
  SupplierCommitment: SupplierCommitmentSpreadsheetParser.getImportableSupplierCommitments,
};

type ParseResult = {
  parsed: true;
  spreadsheetType: SpreadsheetType;
  parsedObject: any;
} | {
  parsed: false;
  spreadsheetType: SpreadsheetType;
  errors: string;
};

export async function determineSpreadsheetType(workBookContainer: WorkBookContainer): Promise<SpreadsheetType[]> {
  const results = await Promise.all(Object.keys(SpreadsheetType).map(async (spreadsheetType): Promise<ParseResult> => {
    try {
      const result = await SpreadsheetParsers[spreadsheetType](workBookContainer);

      return {
        parsed: result.success,
        spreadsheetType: spreadsheetType as SpreadsheetType,
        parsedObject: result.parsed,
      };
    } catch (errors) {
      return {
        parsed: false,
        spreadsheetType: spreadsheetType as SpreadsheetType,
        errors,
      };
    }
  }));

  const possibleSpreadsheetTypes = results.filter(parsedObject => !!parsedObject.parsed);

  return possibleSpreadsheetTypes.map(p => p.spreadsheetType);
}

export async function getPossibleSpreadsheetTypeErrors(expectedSpreadsheetType: SpreadsheetType, fileName: string, contents: string) {
  const workBookContainer = getWorkBookContainerFromBinaryString(fileName, contents);
  const possibleSpreadsheetTypes = await determineSpreadsheetType(workBookContainer);

  if (possibleSpreadsheetTypes.includes(expectedSpreadsheetType)) {
    return undefined;
  }

  const filteredSpreadsheetTypes = possibleSpreadsheetTypes.filter(pst => pst !== expectedSpreadsheetType);

  const spreadsheetNames = filteredSpreadsheetTypes.map(fst => _.startCase(fst));
  const spreadsheetMessage = filteredSpreadsheetTypes.length === 0
    ? 'but could not determine the type of spreadsheet'
    : spreadsheetNames.length === 1
      ? `but instead got a spreadsheet that looks like a ${spreadsheetNames[0]}`
      : `but instead got a spreadsheet that looks like one of the following types: ${spreadsheetNames.join(', ')}`;

  const possibleSpreadsheetTypeError = filteredSpreadsheetTypes.length > 0
    ? `Expected a ${_.startCase(expectedSpreadsheetType)} spreadsheet ${spreadsheetMessage}.`
    : undefined;

  return possibleSpreadsheetTypeError;
}
