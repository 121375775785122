import { InvoiceType } from 'shared/types';

/**
 * An invoice can only be sent via EDI if it has a customer that supports sending invoices via EDI
 * (currently only Meijer and Home Depot) and it is a customer order invoice
 *
 * NOTE: this does not check whether an invoice was already sent via EDI, only whether it could be.
 * Make sure to do that check as well if necessary.
 */
export const isInvoiceEligibleToBeSentViaEdi = (customerSupportsInvoicing: boolean, invoiceType: InvoiceType) => {
  return customerSupportsInvoicing && invoiceType === InvoiceType.CustomerOrder;
};

export const doesInvoiceNeedToBeSentToEdiBeforeAcumatica = (customerSupportsInvoicing: boolean, invoiceType: InvoiceType, hasInvoiceBeenSentViaEdi: boolean) => {
  const invoiceCanBeSentViaEdi = isInvoiceEligibleToBeSentViaEdi(customerSupportsInvoicing, invoiceType);

  // If an invoice can be sent via edi, it must have been sent before it can be transferred to Acumatica. Otherwise, it can always be transferred.
  return !(invoiceCanBeSentViaEdi ? hasInvoiceBeenSentViaEdi : true);
};

export const isInvoiceEligibleToBeSentToAcumatica = (invoiceType: InvoiceType) => {
  return invoiceType !== InvoiceType.VendorChargeback;
};

/**
 * Determines whether an invoice is eligible to be transferred to Acumatica. If an invoice can be sent via EDI,
 * it must have been sent before it can be transferred to Acumatica.
 *
 * NOTE: this does not check whether an invoice was actually already transferred to Acumatica, only whether it could be.
 * Make sure to do that check as well if necessary.
 */
export const isInvoiceEligibleForTransferringToAcumatica = (customerSupportsInvoicing: boolean, invoiceType: InvoiceType, hasInvoiceBeenSentViaEdi: boolean) => {
  const invoiceCanBeSentToAcumatica = isInvoiceEligibleToBeSentToAcumatica(invoiceType);

  // If an invoice can be sent via edi, it must have been sent before it can be transferred to Acumatica. Otherwise, it can always be transferred.
  return invoiceCanBeSentToAcumatica && !doesInvoiceNeedToBeSentToEdiBeforeAcumatica(customerSupportsInvoicing, invoiceType, hasInvoiceBeenSentViaEdi);
};
