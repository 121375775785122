import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import * as ReactRedux from 'react-redux';
import * as UI from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as State from 'client/state/state';
import * as Actions from './actions';
import { msyncQuery } from 'client/hoc/graphql/query';
import { FetchPolicy } from 'apollo-client';
import * as Query from './query';

interface ContainerProps {
  supplierCommitmentId: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {
}

const mapStateToProps = (state: State.Type, ownProps: ContainerProps): StateProps => {
  return {
  };
};

type DispatchProps = {
  onImportButtonClicked(): void;
};

const mapDispatchToProps: DispatchProps = {
  onImportButtonClicked: Actions.importButtonClicked,
};

interface WithSupplierCommitmentDetailRowsProps {
  rows: any[];
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
}
const withSupplierCommitmentDetailRows = msyncQuery<Query.GetSupplierCommitmentWeeklyProductVolumeQueryResponse, ContainerProps & StateProps, WithSupplierCommitmentDetailRowsProps, Query.GetSupplierCommitmentWeeklyProductVolumeQueryInput>(Query.GetSupplierCommitmentDetailTableQuery, {
  alias: 'getSupplierCommitmentDetailTableQuery',
  options(ownProps): { variables, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        supplierCommitmentId: ownProps.supplierCommitmentId,
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithSupplierCommitmentDetailRowsProps {
    const details = props.data.supplierCommitment ? props.data.supplierCommitment.supplierCommitmentProducts : [];
    const supplierCommitment = props.data.supplierCommitment;

    const rows = details.map(detail => {

      const baseColumns = {
        id: detail.id,
        productIdentifier: detail.supplierItem.product.identifier,
        description: detail.supplierItem.product.description,
        cost: detail.cost,
        totalUnits: detail.totalPieceQuantity,
      };

      const allColumns = detail.supplierCommitmentAllocations.reduce((acc, allocation) => {
        acc[`week-${allocation.weekNumber}`] = allocation.pieceQuantity;

        return acc;
      }, baseColumns);

      return allColumns;
    });

    return {
      rows,
      startWeek: supplierCommitment ? supplierCommitment.startWeek || undefined : undefined,
      endWeek: supplierCommitment ? supplierCommitment.endWeek || undefined : undefined,
      loading: props.data.loading,
    };
  },
});
const component = _.flowRight(
  ReactRedux.connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  withSupplierCommitmentDetailRows,
)(UI.SupplierCommitmentAllocationTableUI as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  WithSupplierCommitmentDetailRowsProps &
  StateProps;

assertCompatible<UI.UIProps, CombinedProps>();
export const SupplierCommitmentAllocationTableContainer = (props: ContainerProps) => propToComponent(component, props);
