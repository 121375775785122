import * as _ from 'lodash';
import { IColumn } from 'client/components/table/column';

interface Parameters { data: any; column: Pick<IColumn, 'id'>; }

const getColumnData = ({ data, column }: Parameters): number[] => {
  return data.map(d => d[column.id] ? Number.parseFloat(d[column.id]) : 0);
};

export const aggregateSum = ({ data, column }: Parameters): number => _.sum(getColumnData({ data, column }));
const aggregateMean = ({ data, column }: Parameters): number => _.mean(getColumnData({ data, column }));
const aggregateUniqueCount = ({ data, column }: Parameters): number => _.uniq(getColumnData({ data, column })).length;
const aggregateTwoColumnDivision = (dividendColumnName: string, divisorColumnName: string): ({ data, column }: Parameters) => number => ({ data, column }) => {
  const dividenSum = aggregateSum({ data, column: { id: dividendColumnName } });
  const divisorSum = aggregateSum({ data, column: { id: divisorColumnName } });

  if (dividenSum === 0 || divisorSum === 0) {
    return 0;
  }

  return dividenSum / divisorSum;
};

export const COLUMN_AGGREGATE_SUM = ({ data, column }: Parameters): string => aggregateSum({ data, column }).toString(10);
export const COLUMN_AGGREGATE_MEAN = ({ data, column }: Parameters): string => aggregateMean({ data, column }).toString(10);
export const COLUMN_AGGREGATE_UNIQUE_COUNT = ({ data, column }: Parameters): string => aggregateUniqueCount({ data, column }).toString();
export const COLUMN_AGGREGATE_TWO_COLUMN_DIVISION = (dividendColumnName: string, divisorColumnName: string): ({ data, column }: Parameters) => string => {
  return ({ data, column }: Parameters) => {
    return aggregateTwoColumnDivision(dividendColumnName, divisorColumnName)({ data, column }).toString();
  };
};
