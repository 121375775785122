
import * as _ from 'lodash';
import * as State from 'client/state/state';
import { createSelector } from 'reselect';

export const isDownloadDittyCardModalShown = (state: State.Type) =>
  State.downloadDittyCardModalShown.get(state);
export const isCreateRoutePacketModalShown = (state: State.Type) =>
  State.createRoutePacketsModalShown.get(state);
export const isEmailMerchandisersAsnModalShown = (state: State.Type) =>
  State.emailMerchandisersModalShown.get(state);
export const isDownloadStoreAsnModalShown = (state: State.Type) =>
  State.downloadStoreAsnModalShown.get(state);
export const isRoutePacketRouteSheetsChecked = (state: State.Type) =>
  State.routeSheetsChecked.get(state);
export const isRoutePacketDeliveryAcknowledgementsChecked = (state: State.Type) =>
  State.deliveryAcknowledgementsChecked.get(state);
export const isRoutePacketPurchaseOrderInvoicesChecked = (state: State.Type): boolean =>
  State.purchaseOrderInvoicesChecked.get(state);
export const isRoutePacketReturnInstructionsChecked = (state: State.Type) =>
  State.returnInstructionsChecked.get(state);
export const isRoutePacketSpecialInstructionsChecked = (state: State.Type) =>
  State.specialInstructionsChecked.get(state);
export const isRoutePacketMarkOrdersAsShippedChecked = (state: State.Type) =>
  State.markOrdersAsShippedChecked.get(state);
export const getSelectedLoadConfiguration = (state: State.Type) =>
  State.selectedLoadConfiguration.get(state);
export const sendLoadsToLoadMasterActionStatus = createSelector([State.sendLoadsToLoadMasterActionStatus], _.identity);
export const emailRouteSheetsToPeakActionStatus = createSelector([State.emailRouteSheetsToPeakActionStatus], _.identity);
export const downloadStoreAdvanceShipNoticeActionStatus = createSelector([State.downloadStoreAdvanceShipNoticeActionStatus], _.identity);
export const createCustomerAdvanceShipNoticeActionStatus = createSelector([State.createCustomerAdvanceShipNoticeActionStatus], _.identity);
export const downloadStoreDeliverySpreadsheetActionStatus = createSelector([State.downloadStoreDeliveryDateSpreadsheetActionStatus], _.identity);
export const downloadPalletReportActionStatus = createSelector([State.downloadPalletReportActionStatus], _.identity);
export const selectedRoutingLoadIds = (state: State.Type) =>
  State.selectedRoutingLoadIds.get(state);

export const storesWithMerchandisers = (state: State.Type) => State.storesWithMerchandisers(state);
export const storeSearchText = (state: State.Type) => State.storeSearchText(state);
