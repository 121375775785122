import * as React from 'react';
import * as _ from 'lodash';
import { DropdownButton, MenuItem } from 'client/components/third-party';
import { logoutUser } from '../../actions/session';
import { connect } from 'react-redux';
import * as classNames from 'classnames';

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logoutUser()),
  };
}

function NavUtility({ session, logout, testid, isLoggedIn }: {
  testid: string,
  isLoggedIn: boolean,
  logout: (...args: unknown[]) => unknown,
  session?: { user?: { firstName?: string, lastName?: string} },
}) {
  const firstName = session?.user?.firstName ?? '';
  const lastName = session?.user?.lastName ?? '';
  const testClass = classNames({ [testid]: isLoggedIn });
  const buttonLabel = (
    <span>
      <span className="mfc-icon-user-circle-o" />
      {firstName} {lastName}
    </span>
  ) as shame as string;

  return (
    <ul className="nav navbar-nav navbar-utility">
      <DropdownButton bsStyle="link" title={buttonLabel} data-testid={testClass} id="nav-dropdown">
        <MenuItem onClick={logout} id="logout">Logout</MenuItem>
      </DropdownButton>
    </ul>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavUtility);
