import * as classnames from 'classnames';
import * as React from 'react';
import { ImportRouteProcessStep } from 'client/state/import-route';

export interface ComponentProps {
  processStep: ImportRouteProcessStep;
}

export class ImportRouteModalHeader extends React.PureComponent<ComponentProps, {}> {
  render() {
    const routeInformationClassnames = classnames('import-route-modal-header-icon text-circle', {
      active: this.props.processStep === ImportRouteProcessStep.RouteInformation,
    });

    const importFileClassnames = classnames('import-route-modal-header-icon text-circle', {
      active: this.props.processStep === ImportRouteProcessStep.ImportFile,
    });

    const processSplitStopsClassnames = classnames('import-route-modal-header-icon text-circle', {
      active: this.props.processStep === ImportRouteProcessStep.ProcessSplitStops,
    });

    return (
      <div className="import-route-modal-header text-center">
        <div className="import-route-modal-header-progress">
          <div className="import-route-modal-header-text">Route Information</div>
          <div className={routeInformationClassnames}>1</div>
        </div>
        <div className="import-route-modal-header-progress">
          <div className="import-route-modal-header-text">Import File</div>
          <div className={importFileClassnames}>2</div>
        </div>
        <div className="import-route-modal-header-progress">
          <div className="import-route-modal-header-text">Process Split Stops</div>
          <div className={processSplitStopsClassnames}>3</div>
        </div>
        <div className="import-route-modal-header-line" />
      </div>
    );
  }
}
