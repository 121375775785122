import * as React from 'react';
import { StatsCardBasic } from 'client/components/cards';
import { formatInteger } from 'shared/types';
import * as _ from 'lodash';

export function TotalMilesCard(props: { totalMiles?: number }) {
  return (
    <StatsCardBasic title="Total Miles">{_.isNil(props.totalMiles) ? '-' : formatInteger(props.totalMiles)}</StatsCardBasic>
  );
}
