import { VENDOR_LIST_PAGE_TABLE_NAME } from 'client/constants';
import * as TablesState from 'client/state/tables';
import * as TableState from 'client/state/table';
import { SORT_TYPES, SEARCH_FIELD_ANY } from 'shared/types';

export type Type = TableState.Type;
export const tableName = VENDOR_LIST_PAGE_TABLE_NAME;
export const genericTableActions = TablesState.buildTableStateModule(VENDOR_LIST_PAGE_TABLE_NAME,
  {
    search: { fields: [SEARCH_FIELD_ANY] }, // Default search field is select all.
    sort: [{
      sortOrder: SORT_TYPES.ASC,
      sortField: 'identifier',
    }],
    availableSearchFields: [
      {
        id: SEARCH_FIELD_ANY,
        name: 'Search All',
      },
      {
        id: 'identifier',
        name: 'Code',
      },
      {
        id: 'name',
        name: 'Name',
      },
    ],
  },
);
