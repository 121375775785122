import * as _ from 'lodash';
import { Arrays } from '@atomic-object/lenses';
import * as Constants from '../constants';
import { columnInfo } from '../../shared/schemas';
import { TYPES, ensureCompactArray } from '../../shared/types';
import { intersection, without, uniq, union } from 'lodash';
import { Action } from 'client/types/redux-types';
import { NAVIGATE_TABLE_DIRECTIONS, NavigateTableDirection, SEARCH_FIELD_ANY, FilterOperator } from 'shared/types/user-interaction-types';
import { IColumn } from 'client/components/table/column';
import { Clock } from 'shared/clock';
import { createCustomTable } from 'client/reducers/table-helpers';
import * as VendorListState from 'client/app/admin/vendors/vendor-list/state';
import * as InvoiceTableState from 'client/app/billing/invoices/list/table-state';
import * as InvoiceLineItemsState from 'client/app/billing/invoices/invoice-table/table-state';

import { tableDisplayColumns, tableInfo, allSchemaNames } from '../../shared/schemas';
import { SORT_TYPES } from '../../shared/types';

import { addDays, endOfWeek } from 'shared/helpers/date-helpers';
import { ActionTypeKeys as ProductActionTypeKeys } from 'client/containers/product/actions';
import { ActionTypeKeys as RoutePlanActionTypeKeys } from 'client/actions/route-plan';
import { ActionTypeKeys as InvoiceDetailTableActionTypeKeys } from 'client/app/billing/invoices/invoice-table/actions';
import * as InvoiceAddRoutePlansModalTableConstants from 'client/app/billing/invoices/add-route-plans-table/constants';
import * as InvoiceAddRoutePlansModalTableState from 'client/app/billing/invoices/add-route-plans-table/table-state';
import * as SalesPlanTableState from 'client/app/planning/sales-plans/list/table-state';
import * as SupplierCommitmentTableState from 'client/app/planning/supplier-commitments/list/table-state';
import { isCellEditable } from 'client/helpers/table-helpers';
import { TablesState, Type as TableState } from 'client/state/table';
import { ActiveSort, ActiveFilter } from 'client/types';
import { buildTableStateLens, TableStateLenses } from 'client/state/tables';
import { buildSortTogglingFunction } from 'client/helpers/sort-helpers';
import * as ProductConstants from 'client/components/crud-forms/product/constants';

const INITIAL_STATE: TablesState = Object.assign(allSchemaNames()
  .filter(schema => tableDisplayColumns(schema).length > 0)
  .reduce((state, schema) => {
    const attrs = tableInfo(schema);
    state[schema] = {
      search: attrs.defaultSearch,
      filters: attrs.defaultFilter,
      sort: ensureCompactArray(attrs.defaultSort),
      editing: { value: false, shouldSave: false },
      selectedCell: {
        row: -1,
        column: 0,
      },
      checkedRecordIds: [],
      pageNumber: 0,
      rowsPerPage: 1,
      hasInvalidCell: false,
    };
    return state;
  }, {}),
  {
    [Constants.PRODUCT_WORKSHEET_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
    }),
    [Constants.RELATED_SUPPLIER_ORDERS_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
    }),
    [Constants.SELECT_RELATED_SUPPLIER_ORDERS_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
    }),
    [Constants.STORE_ATTACHMENTS_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] },
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'createdAt',
      }],
    }),
    [Constants.PRODUCT_ATTACHMENTS_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] },
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'createdAt',
      }],
    }),
    [Constants.TRANSPORTATION_RECEIVING_SUMMARY_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'arrivalDate',
      }],
      filters: [
        {
          field: 'arrivalDate',
          values: [Clock.today(), Clock.today()],
          type: TYPES.DATE,
          operator: FilterOperator.Between,
        },
      ],
    }),
    [Constants.PRODUCT_TOSS_TABLE_NAME]: createCustomTable({
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'weekEndingDate',
      }],
      filters: [
        {
          field: 'weekEndingDate',
          values: [addDays(endOfWeek(Clock.today()), -28), endOfWeek(Clock.today())],
          type: TYPES.DATE,
          operator: FilterOperator.Between,
        },
      ],
      search: {
        fields: [],
      },
    }),
    [Constants.TRANSPORTATION_DISTRIBUTION_RECEIVING_SUMMARY_TABLE_NAME]: createCustomTable({ // MICS table
      search: { fields: ['identifier'] },
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'orderDate',
      }],
      filters: [
        {
          field: 'orderDate',
          values: [Clock.today(), addDays(Clock.today(), 3)],
          type: TYPES.DATE,
          operator: FilterOperator.Between,
        },
      ],
    }),

    [Constants.CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME]: createCustomTable({
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'productIdentifier',
      }],
    }),

    [Constants.TRANSPORTATION_RECEIVING_OVERVIEW_TABLE_NAME]: createCustomTable({
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'productIdentifier',
      }],
      search: { fields: ['productDescription'] },
    }),

    [Constants.TRANSPORTATION_RECEIVING_WORKSHEET_TABLE_NAME]: createCustomTable({
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'supplierOrderProductGroup',
      }],
      search: { fields: ['productDescription'] },
    }),

    [Constants.CART_TRACKING_ALL_SUPPLIERS_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
    }),
    [Constants.CART_TRACKING_SUPPLIER_TABLE_NAME]: createCustomTable(),
    [Constants.TRANSPORTATION_ROUTING_PLANS_SUMMARY_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] }, // default search field
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'deliveryDate',
      }],
      filters: [
        {
          field: 'deliveryDate',
          values: [addDays(Clock.today(), 1), addDays(Clock.today(), 1)],
          type: TYPES.DATE,
          operator: FilterOperator.Between,
        },
      ],
    }),
    [Constants.TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] }, // default search field
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'identifier',
      }],
    }),
    [Constants.TRANSPORTATION_LOAD_LIST_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] }, // default search field
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'deliveryDate',
      }],
    }),
    [Constants.TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'deliveryDate',
      }],
    }),
    [Constants.TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME]: createCustomTable({
      search: { fields: ['identifier'] },
      sort: [{
        sortOrder: SORT_TYPES.DESC,
        sortField: 'deliveryDate',
      }],
    }),
    [Constants.TRANSPORTATION_ROUTING_LOAD_STOPS_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] }, // default search field
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'stopNumber',
      }],
    }),
    [Constants.TRANSPORTATION_SPLIT_STOPS]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] },
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'productIdentifier',
      }],
    }),
    [Constants.APPLY_LOADS_TO_TRAILERS_TABLE]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] },
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'loadNumber',
      }],
    }),
    [Constants.TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME]: createCustomTable({
      search: { fields: [SEARCH_FIELD_ANY] },
      sort: [{
        sortOrder: SORT_TYPES.ASC,
        sortField: 'storeNumber',
      }],
    }),
    [Constants.SUPPLIER_ORDER_RELATED_CUSTOMER_ORDERS_TABLE_NAME]: createCustomTable(),
  },
  VendorListState.genericTableActions.InitialState,
  InvoiceTableState.genericTableActions.InitialState,
  InvoiceLineItemsState.genericTableActions.InitialState,
  InvoiceAddRoutePlansModalTableState.genericTableActions.InitialState,
  SalesPlanTableState.genericTableActions.InitialState,
  SupplierCommitmentTableState.genericTableActions.InitialState,
);

const uncheckAllRecords = (tableState: TableState) => {
  return TableStateLenses.checkedRecordIds.set(tableState, Constants.EMPTY_ARRAY);
};

const setSearchText = (tableState: TableState, text: string) => {
  return _.flow(
    TableStateLenses.pageNumber.set(0),
    TableStateLenses.searchText.set(text),
  )(tableState);
};

const clearSearchText = (tableState: TableState) => setSearchText(tableState, '');

const clearAllFiltersAndSearch = (tableState: TableState) => {
  return _.flow(
    TableStateLenses.filters.set(Constants.EMPTY_ARRAY),
    clearSearchText,
  )(tableState);
};

const resetPageNumber = (tableState: TableState) => {
  return TableStateLenses.pageNumber.set(tableState, 0);
};

const resetTable = (tableState: TableState) => {
  return _.flow(
    clearAllFiltersAndSearch,
    uncheckAllRecords,
    resetPageNumber,
  )(tableState);
};

function tableReducer(state = INITIAL_STATE, action: Action = { type: 'unknown' }) {

  if (action.payload && action.payload.table) {
    const tableLens = buildTableStateLens(action.payload.table);
    const currentTableState = tableLens.get(state) ?? tableLens.get(tableLens.set(state, createCustomTable({})));
    if (!tableLens.get(state)) console.debug({state, action, currentTableState});
    return tableLens.set(state, specificTableReducer(currentTableState, action));
  }

  switch (action.type) {
    case RoutePlanActionTypeKeys.ROUTE_PLAN_UNMOUNTED: {
      const routePlanTables = [
        Constants.TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME,
        Constants.TRANSPORTATION_ROUTING_PLAN_ATTACH_ORDERS_TABLE_NAME,
        Constants.TRANSPORTATION_ROUTING_PLANS_LOADS_TABLE_NAME,
        Constants.TRANSPORTATION_ROUTING_PLANS_REVIEW_STORES_TABLE_NAME,
      ];

      return routePlanTables.reduce((updatedState, tableName) => {
        const tableLens = buildTableStateLens(tableName);
        return tableLens.set(updatedState, resetTable(tableLens.get(updatedState)));
      }, state);
    }

    case InvoiceDetailTableActionTypeKeys.AddRoutePlansToInvoiceButtonClicked: {
      const tableLens = buildTableStateLens(InvoiceAddRoutePlansModalTableConstants.TableName);
      return tableLens.set(state, resetTable(tableLens.get(state)));
    }

    case ProductActionTypeKeys.ProductDetailSectionUnmounted: {
      const tableLens = buildTableStateLens(ProductConstants.ProductPriceTableName);
      return tableLens.set(state, resetTable(tableLens.get(state)));
    }

    default:
      return state;
  }
}

const toggleSortOptions = buildSortTogglingFunction<ActiveSort>({ fieldProp: 'sortField', orderProp: 'sortOrder', asc: SORT_TYPES.ASC, desc: SORT_TYPES.DESC });

function specificTableReducer(tableState: TableState, action: Action = { type: 'unknown' }) {
  switch (action.type) {
    case Constants.TOGGLE_SORT: {
      const { table, field, multiColumn } = action.payload;
      if (!table || !field) {
        return tableState;
      }

      let column = field;
      const foreignCol = field.match(/(.+)\.(.+)/);

      if (foreignCol) {
        column = foreignCol[1];
      }

      const info = columnInfo(table, column); //, { warnOnUnknownSchema: false }

      const newSortOption = {
        sortOrder: SORT_TYPES.ASC,
        sortField: column,
        foreignColumn: undefined,
      };

      if (info && info.fkSpec) {
        if (foreignCol) {
          newSortOption.sortField = column;
          newSortOption.foreignColumn = foreignCol[2];
        } else {
          console.warn('Unknown foreign sort field', field);
        }
      } else {
        newSortOption.foreignColumn = foreignCol ? foreignCol[2] : null;
      }

      const finalSort: ActiveSort[] = toggleSortOptions(newSortOption, multiColumn, TableStateLenses.sort(tableState));

      return _.flow(
        TableStateLenses.sort.set(finalSort),
        TableStateLenses.pageNumber.set(0),
      )(tableState);
    }

    case Constants.SET_FILTER: {
      const { field, values } = action.payload;

      const existingFilters = TableStateLenses.filters.get(tableState);
      const changingFilterIndex = existingFilters.findIndex(f => f.field === field);

      let newFilters;
      if (changingFilterIndex !== -1) {
        newFilters = Arrays.index<ActiveFilter>(changingFilterIndex).set(existingFilters, { ...existingFilters[changingFilterIndex], values });
      } else {
        newFilters = Arrays.push(existingFilters, { field, values });
      }

      return _.flow(
        TableStateLenses.filters.set(newFilters),
      )(tableState);
    }

    case Constants.TOGGLE_FILTER: {
      const { field, value } = action.payload;

      const existingFilters = TableStateLenses.filters.get(tableState);
      let newFilters = existingFilters;

      const changingFilterIndex = existingFilters.findIndex(f => f.field === field);

      if (changingFilterIndex === -1) {
        const newFilter = {
          field,
          values: [value],
        };
        newFilters = Arrays.push(existingFilters, newFilter);
      } else {
        const changingFilter = existingFilters[changingFilterIndex];
        const existingOptions = changingFilter.values ? changingFilter.values : Constants.EMPTY_ARRAY;
        const optionIndex = existingOptions.indexOf(value);

        let newOptions: any[];
        if (optionIndex === -1) {
          newOptions = Arrays.push(existingOptions, value);
        } else {
          // Remove the option at optionIndex
          newOptions = Arrays.splice(existingOptions, optionIndex, 1);
        }

        if (newOptions.length === 0) {
          // Remove the option at changingFilterIndex
          newFilters = Arrays.splice(existingFilters, changingFilterIndex, 1);
        } else {
          newFilters = Arrays.index<ActiveFilter>(changingFilterIndex).set(existingFilters, { ...existingFilters[changingFilterIndex], values: newOptions });
        }
      }

      return _.flow(
        TableStateLenses.filters.set(newFilters),
        TableStateLenses.pageNumber.set(0),
      )(tableState);
    }

    case Constants.CLEAR_ALL_FILTERS: {
      return clearAllFiltersAndSearch(tableState);
    }

    case Constants.SET_SEARCH_FIELD: {
      const { field } = action.payload;
      return TableStateLenses.searchFields.set(tableState, [field]);
    }

    case Constants.SET_SEARCH_TEXT: {
      const { text } = action.payload;
      return setSearchText(tableState, text);
    }

    case Constants.NAVIGATE_TABLE: {
      return handleMove({ action, tableState, determineStillEditing: false });
    }

    case Constants.MOVE_EDIT_CELL: {
      return handleMove({ action, tableState, determineStillEditing: true });
    }

    case Constants.EDIT_NEXT_EDITABLE_CELL: {
      const direction: NavigateTableDirection = action.payload.direction;
      const totalRows: number = action.payload.totalRows;
      const totalColumns: number = action.payload.totalColumns;
      const columns: IColumn[] = action.payload.columns;
      const tableRows: any[] = action.payload.tableRows;

      const currentSelectedCell = tableState.selectedCell;

      // This check shouldn't be necessary, but I'm not willing to risk removing it at this point
      if (!currentSelectedCell) {
        return tableState;
      }

      const nextCell = findNextEditableCellInDirection({ tableRows, currentCell: currentSelectedCell, direction, totalRows, totalColumns, columns });

      if (nextCell) {
        return _.flow(
          TableStateLenses.selectedCellRow.set(nextCell.row),
          TableStateLenses.selectedCellColumn.set(nextCell.column),
          TableStateLenses.editingValue.set(true),
          TableStateLenses.editingShouldSave.set(true),
        )(tableState);
      } else {
        return _.flow(
          TableStateLenses.editingValue.set(false),
          TableStateLenses.editingShouldSave.set(true),
        )(tableState);
      }
    }

    case Constants.EDIT_LAST_EDITABLE_CELL: {
      const tableRows: any[] = action.payload.tableRows;
      return _.flow(
        TableStateLenses.selectedCellRow.set(tableRows.length - 1),
        TableStateLenses.selectedCellColumn.set(0),
        TableStateLenses.editingValue.set(true),
        TableStateLenses.editingShouldSave.set(true),
      )(tableState);
    }

    case Constants.EDIT_TABLE: {
      const { editing, shouldSave } = action.payload;
      return _.flow(
        TableStateLenses.editingValue.set(editing),
        TableStateLenses.editingShouldSave.set(shouldSave),
      )(tableState);
    }

    case Constants.CLICK_CELL: {
      const { row, column } = action.payload;
      const currentSelectedCellRow = TableStateLenses.selectedCellRow.get(tableState);
      const currentSelectedCellColumn = TableStateLenses.selectedCellColumn.get(tableState);
      if (currentSelectedCellRow === row && currentSelectedCellColumn === column)
        return tableState;

      return _.flow(
        TableStateLenses.editingValue.set(false),
        TableStateLenses.selectedCellRow.set(row),
        TableStateLenses.selectedCellColumn.set(column),
      )(tableState);
    }

    case Constants.CLICK_OUTSIDE_TABLE: {
      return _.flow(
        TableStateLenses.editingValue.set(false),
        TableStateLenses.editingShouldSave.set(true),
        TableStateLenses.selectedCellRow.set(-1),
        TableStateLenses.selectedCellColumn.set(-1),
      )(tableState);
    }

    case Constants.TOGGLE_CHECK_ALL_RECORDS: {
      const { checkedRecordIds, filteredRecordIds } = action.payload;
      const intersectedIds = intersection(checkedRecordIds, filteredRecordIds);
      const newCheckedRecordIds = (intersectedIds.length > 0 && intersectedIds.length === filteredRecordIds.length)
        ? uniq(without(checkedRecordIds, ...filteredRecordIds))
        : uniq(union(checkedRecordIds, filteredRecordIds));

      return _.flow(
        TableStateLenses.checkedRecordIds.set(newCheckedRecordIds),
      )(tableState);
    }

    case Constants.TOGGLE_CHECK_SINGLE_RECORD: {
      const {  recordId } = action.payload;
      const checkedRecordIds = tableState.checkedRecordIds;
      const index = checkedRecordIds.indexOf(recordId);
      const newCheckedRecordIds = (index === -1)
        ? Arrays.push(checkedRecordIds, recordId)
        : Arrays.splice(checkedRecordIds, index, 1); // Remove at index

      return _.flow(
        TableStateLenses.checkedRecordIds.set(newCheckedRecordIds),
      )(tableState);
    }

    case Constants.UNCHECK_MULTIPLE_RECORDS: {
      const { recordIds } = action.payload;
      const checkedRecordIds = tableState.checkedRecordIds;
      const newCheckedRecordIds = uniq(without(checkedRecordIds, ...recordIds));
      return _.flow(
        TableStateLenses.checkedRecordIds.set(newCheckedRecordIds),
      )(tableState);
    }

    case Constants.UNCHECK_ALL_RECORDS: {
      return uncheckAllRecords(tableState);
    }

    case Constants.SET_DATE_FILTER: {
      const { field, startDate, endDate } = action.payload;

      const existingFilters = TableStateLenses.filters.get(tableState);
      const filterIndex = existingFilters.findIndex(f => f.field === field);

      const filterValues = [startDate, endDate];
      if (filterIndex === -1) {
        const newFilter = {
          field,
          values: filterValues,
          type: TYPES.DATE,
          operator: FilterOperator.Between,
        };

        return _.flow(
          TableStateLenses.filters.set(Arrays.push(existingFilters, newFilter)),
          TableStateLenses.pageNumber.set(0),
        )(tableState);
      }

      return _.flow(
        TableStateLenses.filters.set(Arrays.index<ActiveFilter>(filterIndex).set(existingFilters, {
          ...existingFilters[filterIndex],
          values: filterValues,
          operator: FilterOperator.Between,
        })),
        TableStateLenses.pageNumber.set(0),
      )(tableState);
    }

    case Constants.SET_TABLE_PAGE_NUMBER: {
      const { pageNumber, row } = action.payload;
      return _.flow(
        TableStateLenses.selectedCellRow.set(row),
        TableStateLenses.pageNumber.set(pageNumber),
      )(tableState);
    }

    case Constants.SET_TABLE_ROWS_PER_PAGE: {
      const { rowsPerPage, totalRows } = action.payload;
      const currentPageNumber = TableStateLenses.pageNumber.get(tableState);
      const currentRowsPerPage = TableStateLenses.rowsPerPage.get(tableState);
      const currentOffset = currentPageNumber * currentRowsPerPage;

      const totalPages = Math.ceil(totalRows * 1.0 / rowsPerPage);

      // Attempt to remap the page number into something reasonable...
      let pageNumber = currentOffset === 0
        ? 0
        : Math.floor(currentOffset * 1.0 / rowsPerPage);

      if (pageNumber < 0) {
        pageNumber = 0;
      }

      if (pageNumber > totalPages) {
        pageNumber = totalPages;
      }
      return _.flow(
        TableStateLenses.rowsPerPage.set(rowsPerPage),
        TableStateLenses.pageNumber.set(pageNumber),
      )(tableState);
    }

    case Constants.OPEN_ORDER_DELETE_CONFIRMATION_MODAL: {
      const { orderId } = action.payload;

      return _.flow(
        TableStateLenses.orderToDelete.set(orderId),
        TableStateLenses.showConfirmationModal.set(true),
      )(tableState);
    }

    case Constants.CLOSE_ORDER_DELETE_CONFIRMATION_MODAL: {
      return _.flow(
        TableStateLenses.orderToDelete.set(undefined),
        TableStateLenses.showConfirmationModal.set(false),
      )(tableState);
    }

    case Constants.TABLE_HAS_INVALID_CELL: {
      return TableStateLenses.hasInvalidCell.set(tableState, action.payload.value);
    }

    default:
      return tableState;
  }
}

function handleMove(args: { action: Action, tableState: TableState, determineStillEditing: boolean }) {
  const { direction, distance, totalRows, totalColumns, blurOnSaveLastRow } = args.action.payload;
  const tableState = args.tableState;

  const currentSelectedCellRow = TableStateLenses.selectedCellRow.get(tableState);
  const currentSelectedCellColumn = TableStateLenses.selectedCellColumn.get(tableState);

  let newSelectedRow = currentSelectedCellRow;
  let newSelectedColumn = currentSelectedCellColumn;

  switch (direction) {
    case NAVIGATE_TABLE_DIRECTIONS.UP:
      newSelectedRow = Math.max(newSelectedRow - distance, 0);
      break;
    case NAVIGATE_TABLE_DIRECTIONS.DOWN:
      const currentlyEditing = TableStateLenses.editingValue.get(tableState);

      // [JCN 07/17/2018]
      // "blurOnSaveLastRow" is an option added to support a behavioral difference in
      // the way that the onSave event is handled for the Split Stop editor table as
      // well as the Assign Trailers to Loads table. Both of these tables use Redux to
      // store the edited value in Redux state (overriding the GraphQL query result as
      // necessary) wich allows the new values to be persisted only when the modal is
      // saved/closed, and not immediately as in other tables. As of this writing, the
      // save handler does not get called properly on the last row of editing these
      // tables. The blurOnSaveLastRow option was added to "temporarily" (hah) accomodate
      // for this until a better solution can be found (most likely in the very
      // complex componentWillReceiveProps function in TableUI).
      //
      // Note: This option is present in the table action creator, table reducer, table-ui
      // basic-table and the IColumn interface. If at a later point this needs to be removed,
      // you can check these files.

      if (blurOnSaveLastRow && !!currentlyEditing && (newSelectedRow + distance > totalRows - 1)) {
        return _.flow(
          TableStateLenses.editingValue.set(false),
          TableStateLenses.editingShouldSave.set(true),
          TableStateLenses.selectedCellRow.set(-1),
          TableStateLenses.selectedCellColumn.set(newSelectedColumn),
        )(tableState);
      } else {
        newSelectedRow = Math.min(newSelectedRow + distance, totalRows - 1);
      }
      break;
    case NAVIGATE_TABLE_DIRECTIONS.LEFT:
      newSelectedColumn = Math.max(newSelectedColumn - distance, 0);
      break;
    case NAVIGATE_TABLE_DIRECTIONS.RIGHT:
      newSelectedColumn = Math.min(newSelectedColumn + distance, totalColumns - 1);
      break;
    default:
      break;
  }

  let stillEditing: boolean = false;
  if (args.determineStillEditing) {
    stillEditing = newSelectedRow !== currentSelectedCellRow || newSelectedColumn !== currentSelectedCellColumn;
  }

  return _.flow(
    TableStateLenses.editingValue.set(stillEditing),
    TableStateLenses.selectedCellRow.set(newSelectedRow),
    TableStateLenses.selectedCellColumn.set(newSelectedColumn),
  )(tableState);
}

interface Cell {
  row: number;
  column: number;
}
type CellPredicate = (cell: Cell) => boolean;
type CellModifier = (cell: Cell) => Cell;

function lookForEditableCell(args: { currentCell: { row: number, column: number }, columns: IColumn[], isLastCell: CellPredicate, advance: CellModifier, tableRows: any[] }) {
  let cell: Cell = Object.assign({}, args.currentCell);
  let stop = false;

  while (!stop) {
    if (args.isLastCell(cell)) {
      return undefined;
    }

    cell = args.advance(cell);

    if (cell.column === args.currentCell.column) {
      // We're back to the column we started on, so stop here
      stop = true;
    }

    if (isCellEditable(args.columns, cell, args.tableRows)) {
      stop = true;
    }
  }

  if (cell.row === args.currentCell.row && cell.column === args.currentCell.column) {
    // Still looking at the same cell, couldn't find a next editable one
    return undefined;
  } else if (isCellEditable(args.columns, cell, args.tableRows)) {
    return cell;
  }
}

function findNextEditableCellInDirection(args: { currentCell: { row: number, column: number }, direction: NavigateTableDirection, totalRows: number, totalColumns: number, columns: IColumn[], tableRows: any[] }) {
  switch (args.direction) {
    case NAVIGATE_TABLE_DIRECTIONS.RIGHT: {
      return lookForEditableCell({
        tableRows: args.tableRows,
        currentCell: args.currentCell,
        columns: args.columns,
        isLastCell: cell => {
          return cell.column === args.totalColumns - 1 && cell.row === args.totalRows - 1;
        },
        advance: cell => {
          let column = cell.column + 1;
          let row = cell.row;

          if (column >= args.totalColumns) {
            column = 0;
            row += 1;
          }
          return { column, row };
        },
      });
    }

    case NAVIGATE_TABLE_DIRECTIONS.LEFT: {
      return lookForEditableCell({
        tableRows: args.tableRows,
        currentCell: args.currentCell,
        columns: args.columns,
        isLastCell: cell => {
          return cell.column === 0 && cell.row === 0;
        },
        advance: cell => {
          let column = cell.column - 1;
          let row = cell.row;

          if (column < 0) {
            column = args.totalColumns - 1;
            row -= 1;
          }
          return { column, row };
        },
      });
    }

    default:
      throw new Error(`Support for moving ${args.direction} to the next editable cell has not been implemented yet.`);
  }
}

export default tableReducer;
