import * as Redux from 'redux';

export type ActionTypes =
  | ImportButtonClickedAction;

export enum ActionTypeKeys {
  ImportButtonClicked = 'App/SupplierCommitmentImportButtonClicked',
}

export interface ImportButtonClickedAction extends Redux.Action {
  type: ActionTypeKeys.ImportButtonClicked;
}

export function importButtonClicked(): ImportButtonClickedAction {
  return {
    type: ActionTypeKeys.ImportButtonClicked,
  };
}
