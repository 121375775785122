import * as StackTrace from 'stacktrace-js';

// https://stackoverflow.com/a/45964244
const g
    = typeof globalThis === 'object' ? globalThis
    : typeof window     === 'object' ? window
    : typeof global     === 'object' ? global
    :                                  null as any;

export class Bug extends Error {
  readonly isBug = true;
  readonly shouldNeverHappen = true;
  readonly magicStack: StackTrace.StackFrame[] = [];
  dumpContext?: unknown;
  constructor(message: string) {
    super(message);
    const m = this.magicStack;

    // only use 3rd party StackTrace module in browser
    if (!g?.document)
        return;

    StackTrace
      .get()
      .then(x => {
        console.error(`Bug: ${message}`);
        for (const f of x) {
          m.push(f);
          console.error(f.toString());
        }
      }).catch(console.error);
  }
}
