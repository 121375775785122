import * as _ from 'lodash';
import * as Constants from 'client/constants';
import * as FilterableTable from 'client/containers/table/table-filter-container';
import * as GraphQLQuery from 'client/hoc/graphql/query';
import * as React from 'react';
import * as RowMenu from 'client/components/table/row-menu/menu';
import * as SharedTypes from 'shared/types';
import * as Table from 'client/components/table/table-parent';
import * as Types from 'client/types';
import { IColumn } from 'client/components/table/column';
import { SendMicsModal } from 'client/app/transportation/distribution-receiving/list/send-mics-modal';
import PageTitle from 'client/components/page-title';

export interface ReceivingRow {
  id: number;
  identifier: string;
  customer: { id: number, name: string };
  orderType: string;
  orderDate: SharedTypes.DateStr;
  supplierOrderArrivalDate: SharedTypes.DateStr | null;
  received: boolean;
  micsSentAt: SharedTypes.DateTimeStr;
  lastModifiedAt: SharedTypes.DateTimeStr | null;
  vendorName: string;
}

export interface OwnProps {
  receivingRows: ReceivingRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  searchableFields: Types.AvailableSearchField[];
  availableFilters: Types.AvailableFilter[];
  tableParentInfo: Table.TableParentInfo;
  onRowSelect(record): void;
  dataRequest: GraphQLQuery.MsyncDataRequest;
  sendMicsFileToMeijerMenuItemClicked(customerOrderIds: number[]): void;
}

const tableName = Constants.TRANSPORTATION_DISTRIBUTION_RECEIVING_SUMMARY_TABLE_NAME;

const columns: IColumn[] = [
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'PO #',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
  },
  {
    id: 'customer.name',
    accessor: 'customer.name',
    header: 'Customer',
    tableEditable: false,
    columnWidth: 16,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
  },
  {
    id: 'vendorName',
    accessor: 'vendorName',
    header: 'Vendor',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
  },
  {
    id: 'orderType',
    accessor: 'orderType',
    header: 'Order Type',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
  },
  {
    id: 'supplierOrderArrivalDate',
    accessor: 'supplierOrderArrivalDate',
    header: 'Arrival Date',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DATE,
    type: SharedTypes.TYPES.DATE,
  },
  {
    id: 'orderDate',
    accessor: 'orderDate',
    header: 'Delivery Date',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DATE,
    type: SharedTypes.TYPES.DATE,
  },
  {
    id: 'received',
    tableEditable: false,
    accessor: 'received',
    header: 'Received',
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.YES_NO_NULL,
    type: SharedTypes.TYPES.BOOLEAN,
  },
  {
    id: 'micsSentAt',
    accessor: 'micsSentAt',
    header: 'MICS Sent',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DATE_TIME,
    type: SharedTypes.TYPES.DATE_TIME,
  },
  {
    id: 'lastModifiedAt',
    accessor: 'lastModifiedAt',
    header: 'Last Modified',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DATE_TIME,
    type: SharedTypes.TYPES.DATE_TIME,
  },
];

export class DistributionReceivingTable extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTable.OwnProps>;
  private readonly rowMenuItems: RowMenu.RowMenuItem[];
  private readonly headerMenuItems: RowMenu.RowMenuItem[];

  constructor(props: any) {
    super(props);
    this.FilterableTable = FilterableTable.buildFilterableTable(tableName);

    this.rowMenuItems = [
      {
        label: 'Send MICS file to Meijer',
        onClick: this.props.sendMicsFileToMeijerMenuItemClicked,
        uncheckRecordFollowingClick: false,
        willRemove: false,
      },
    ];

    this.headerMenuItems = [
      {
        label: 'Send MICS File to Meijer',
        onClick: this.props.sendMicsFileToMeijerMenuItemClicked,
        uncheckRecordFollowingClick: false,
        willRemove: false,
      },
    ];
  }

  public render() {
    return (
      <div id="mfc-page-content">
        <div className="distribution-receiving-summary-table-wrapper">
          <PageTitle title="MICS" />
          <this.FilterableTable
            table={tableName}
            content={this.props.receivingRows}
            loading={this.props.loading}
            columns={columns}
            totalCount={this.props.totalCount}
            totalUnfilteredCount={this.props.totalUnfilteredCount}
            filteredRecordIds={this.props.filteredRecordIds}
            refetchTable={_.noop}
            loadMoreRecords={_.noop}
            searchableFields={this.props.searchableFields}
            availableFilters={this.props.availableFilters}
            placeholder={'No Orders to MICS'}
            checkable={true}
            list={true}
            tablePaginated
            tableParentInfo={this.props.tableParentInfo}
            disableCreate={true}
            displayLoadingIndicator={true}
            onRowSelect={this.props.onRowSelect}
            dataRequest={this.props.dataRequest}
            headerMenuItems={this.headerMenuItems}
            rowMenuItems={this.rowMenuItems}
          />
          <SendMicsModal />
        </div>
      </div>
    );
  }
}
