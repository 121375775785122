import * as _ from 'lodash';

export function objectDifference(object, base) {
  function changes(object1, base1) {
    return _.transform(object1, (result: SimpleObject, value, key: any) => {
      if (!_.isEqual(value, base1[key])) {
        result[key] = (_.isObject(value) && _.isObject(base1[key])) ? changes(value, base1[key]) : value;
      }
    });
  }
  if (_.isNil(object) || _.isNil(base)) {
    return undefined;
  }
  return changes(object, base);
}

//////// shallowEqual copied from react-redux ///////////////
const hasOwn = Object.prototype.hasOwnProperty;

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    // console.info('Not shallow equal because one is null and the other is not');
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    // console.info('Not shallow equal because number of keys has changed');
    return false;
  }

  for (const key of keysA) {
    if (!hasOwn.call(objB, key) || !is(objA[key], objB[key])) {
      // console.info('Not shallow equal because of', key, objA[key], objB[key]);
      return false;
    }
  }

  return true;
}
///////////////////////////////////////////////////////////////
