import * as _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';
import { connect, ConnectedProps, GetProps} from 'react-redux';
import { msyncQuery } from 'client/hoc/graphql/query';
import * as ReportUserParamsTypes from 'shared/types/report-user-params';
import * as ReportUserParamsQueries from 'client/components/report-user-params/queries';
import * as ReportUserParamsState from 'client/components/report-user-params/state';
import { ReportTypes } from 'shared/types';
import * as State from 'client/state/state';
import { migrateFieldAliases } from 'shared/app/reports/performance';

export const reportingForm = (args: { form: string, reportType: ReportTypes, initialValues?: any }) => {

  const mapSelectedPresetToProps = (state: State.Type) => {
    const formValues: { id?: number, isDeleted?: boolean } = getFormValues(args.form)(state);
    const isBrandNewForm = _.isNil(formValues);
    let selectedPresetId = ReportUserParamsState.selectedPresetId(state.reportUserParams);

    // By default want to reset the form completely when reinitializing
    let keepDirtyOnReinitialize = false;

    if (!selectedPresetId && !isBrandNewForm) {
      selectedPresetId = formValues.id;

      // This should be the case where the user is returning to this report from somehwere else
      // in the app. In this case we don't want to reset the form (remember their most recent changes)
      keepDirtyOnReinitialize = true;
    }
    // Later versions of redux-form expose a function to get this initial state, so
    // this should be replaced if we ever upgrade to a new enough version
    const previousInitialValues = _.get(state, `form.${args.form}.initial`);

    return {
      selectedPresetId,
      isBrandNewForm,
      ...(isBrandNewForm ? { initialValues: args.initialValues } : { initialValues: previousInitialValues }),
      keepDirtyOnReinitialize,
      skipLoadingPreset: !!(formValues && formValues.isDeleted),
    };
  };

  interface WithPresetProps {
    selectedPresetId?: number;
    initialValues?: any;
    isLoadingPreset: boolean;
  }

  const withPreset = msyncQuery<{ response: ReportUserParamsTypes.GetReportUserParamsResponse }, shame, WithPresetProps, ReportUserParamsTypes.GetReportUserParamsInput>(ReportUserParamsQueries.getReportUserParamsQuery(), {
    alias: 'withReportPreset',
    skip(ownProps) {
      return ownProps.skipLoadingPreset;
    },
    options(ownProps) {
      return {
        // Intentionally not setting this to network-only (it messes up the switching between reports/presets stuff)
        variables: {
          input: {
            reportType: args.reportType,
            presetId: ownProps.selectedPresetId,
          },
        },
      };
    },
    props(props) {
      if (props.data.loading) {
        return {
          isLoadingPreset: true,
        };
      }

      if (!props.data.response || !props.data.response.reportPreset || !props.data.response.reportPreset.params) {
        return {
          isLoadingPreset: false,
        };
      }

      return {
        isLoadingPreset: false,
        initialValues: {
          id: props.data.response.reportPreset.id,
          ...(args.reportType === ReportTypes.PerformanceReport
            ? migrateFieldAliases(props.data.response.reportPreset.params)
            : props.data.response.reportPreset.params),
        },
        selectedPresetId: props.data.response.reportPreset.id,
      };
    },
  });

  const connector = connect(mapSelectedPresetToProps);

  type CombinedProps =
    ConnectedProps<typeof connector> &
    WithPresetProps;

  return _.flowRight(
    connector,
    withPreset,
    reduxForm({
      form: args.form,
      enableReinitialize: true, // Allow new initialValues to initialize the form
      destroyOnUnmount: false, // Persist the selections when you leave the page and then return to it.
    }),
  ) as (comp: React.ComponentType<any>) => React.ComponentType<CombinedProps>;
};

export type ReportingFormProps = GetProps<ReturnType<ReturnType<typeof reportingForm>>>;

// interface ReportingFormProps {
//   selectedPresetId?: number;
//   isLoadingPreset: boolean;
// }

