import gql from 'graphql-tag';
import * as InvoiceGraphqlTypes from 'schema/invoice/invoice-graphql-types';
import { extractQueryName } from 'client/utils/query-utils';

export interface GetInvoiceLineItemsResponse {
  getInvoiceLineItems?: {
    invoice: {
      id: InvoiceGraphqlTypes.Invoice['id'];
      type: InvoiceGraphqlTypes.Invoice['type'];
      ediInvoiced: InvoiceGraphqlTypes.Invoice['ediInvoiced'];
      transferredToAccounting: InvoiceGraphqlTypes.Invoice['transferredToAccounting'];
      firstDownloadedAt: InvoiceGraphqlTypes.Invoice['firstDownloadedAt'];
      emailedAt: InvoiceGraphqlTypes.Invoice['emailedAt'];
      lineItems: InvoiceGraphqlTypes.CustomerOrderInvoiceLineItem[]; // TODO: handle drop ship line items
    },
  };
}

export interface GetInvoiceLineItemsInput {
  invoiceId: number;
}

const InvoiceLineItemsFragment = gql`
  fragment InvoiceLineItemsFragment on Invoice {
    id
    type
    ediInvoiced
    transferredToAccounting
    firstDownloadedAt
    emailedAt
    ... on DropShipInvoice {
      id
      lineItems(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        deliveryDate
        storesDelivered
        notes
        deliveryRate
        totalPrice
      }
    }

    ... on CustomerOrderInvoice {
      id
      lineItems(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        productIdentifier
        description
        sku
        packSize
        packQuantity
        piecePrice
        totalPieces
        totalPrice
        editable
        generalLedgerAccountIdentifier
      }
    }

    ... on VendorChargebackInvoice {
      id
      lineItems(sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset) {
        id
        productIdentifier
        productDescription
        sku
        packSize
        packOrderQuantity
        cost
        totalPieces
        totalCost
      }
    }
  }
`;

export const GetInvoiceWithLineItemsQuery = gql`
  query GetInvoiceWithLineItemsQuery($invoiceId: Int!, $sort: [SortInput!], $filters: [FilterSpecificationInput!], $search: SearchInput, $limit: Int, $offset: Int) {
    getInvoiceLineItems: GetInvoice(id: $invoiceId) {
      invoice {
        ...InvoiceLineItemsFragment
      }
    }
  }
  ${InvoiceLineItemsFragment}
`;

export const GET_INVOICE_WITH_LINE_ITEMS_QUERY_NAME = extractQueryName(GetInvoiceWithLineItemsQuery);

export const GetInvoiceWithLineItemsExcelQuery = GetInvoiceWithLineItemsQuery;
