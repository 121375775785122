import * as _ from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { shallowEqual } from 'shared/object-difference';

/**
 * Creates a "selector creator" that uses lodash.isEqual instead of ===
 */
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual,
  // (objA, objB) => { console.time('deepEqual'); const result = _.isEqual(objA, objB); console.timeEnd('deepEqual'); return result; },
);

/**
 * Creates a "selector creator" that uses shallowEqual instead of ===
 */
export const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  shallowEqual,
);
