import * as ExcelCommon from 'shared/file-parsers/excel/common';
import * as Helpers from './helpers';
import * as Types from './types';
import { isBefore } from 'shared/helpers';
import { toDateStr } from 'shared/types';

interface SuccessfulParsedProductTossSheet {
  success: true;
  parsed: Types.ImportableProductTossSheet;
}
interface FailedParsedProductTossSheet {
  success: false;
  reason: string;
}
type ParsedProductTossSheetResult = SuccessfulParsedProductTossSheet | FailedParsedProductTossSheet;
const genericFailureMessage = 'The file cannot be processed. Please verify that the format of the spreadsheet is correct.';
export async function getImportableProductTossSheet(workBookContainer: ExcelCommon.WorkBookContainer): Promise<ParsedProductTossSheetResult> {
  try {
    const workBook = await Helpers.getProductTossSheetWorkBookFromWorkBookContainer(workBookContainer);
    if (!workBook) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }
    const workSheet = await Helpers.getProductTossSheetWorkSheet(workBook);
    if (!workSheet) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }
    const workSheetData = await Helpers.getProductTossSheetWorkSheetData(workSheet);
    if (workSheetData.length === 0 || Object.keys(workSheetData[0]).length < 1) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const headerData = Helpers.getHeaderData(workSheetData);

    if (!headerData.customerIdentifier) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    if (!headerData.weekEndingDate) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    // a reasonableness test to weed-out invalid spreadsheets
    if (isBefore(headerData.weekEndingDate, toDateStr('2000-01-01'))) {
      return {
        success: false,
        reason: genericFailureMessage,
      };
    }

    const productTossRecords = Helpers.getProductTossRecords(workSheetData);

    return {
      success: true,
      parsed: {
        customerIdentifier: headerData.customerIdentifier,
        weekEndingDate: headerData.weekEndingDate,
        storeTossRecords: productTossRecords,
      },
    };
  } catch (error) {
    return {
      success: false,
      reason: genericFailureMessage,
    };
  }
}
