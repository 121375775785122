
import { msyncQuery } from 'client/hoc/graphql/query';
import { SORT_TYPES, SelectableValue } from 'shared/types';
import gql from 'graphql-tag';
import { DropDownOptions } from 'client/types';
import { SelectableRow } from 'client/components/selectable/types';
import { idsFor } from 'shared/helpers/andys-little-helpers';
import * as _ from 'lodash';
import { FindAllProductClassesQueryResponse, FindAllProductClassesQuery, applyFilterIfListHasValues, FindAllSalesPlansQueryResponse, FindAllSalesPlansQuery, ProductQueryProps } from 'client/app/reports/shared/query';

export interface ProductClassQueryProps {
  productClassOptions?: SelectableRow[];
  productClassesLoading?: boolean;
}
export const withProductClasses = msyncQuery<FindAllProductClassesQueryResponse, { salesPlanId?: number, possibleClassIds?: SelectableValue, customerIdsBySalesPlanId: { [salesPlanId: number]: number } }, ProductClassQueryProps, {}>(FindAllProductClassesQuery, {
  alias: 'withProductClasses',
  skip(ownProps) {
    return ownProps.salesPlanId === undefined || ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId] === undefined;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          ...applyFilterIfListHasValues('id', ownProps.possibleClassIds),
        ],
        sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): ProductClassQueryProps {
    const { data } = props;
    if (data.loading || data.productClasses === undefined) {
      return {
        productClassOptions: [],
        productClassesLoading: data.loading,
      };
    }

    return {
      productClassOptions: data.productClasses.map(productClass => ({
        id: productClass.id,
        cells: [
          productClass.identifier,
          productClass.sellDepartment.identifier,
        ],
      })),
      productClassesLoading: data.loading,
    };
  },
});

export interface FindAllProductsQueryResponse {
  salesPlanProducts?: Array<{
    id: number;
    product: {
      id: number;
      identifier: string;
      replenishmentIdentifier: string;
      description: string;
    };
  }>;
}

export const FindAllSalesPlanProductsAsProductsQuery = gql`
  query FindAllSalesPlanProductsAsProductsVarietiesQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlanProducts: findAll(type: SalesPlanProduct, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlanProduct {
        id
        product {
          id
          identifier
          replenishmentIdentifier
          description
        }
      }
    }
  }
`;

export interface SalesPlanProductQueryProps {
  productOptions?: SelectableRow[];
  productsLoading?: boolean;
}
export const withProducts = (opts?: { onlyIncludeParentReplenishmentProducts?: boolean }) => {
  return msyncQuery<FindAllProductsQueryResponse, { salesPlanId?: number, customerIdsBySalesPlanId: { [salesPlanId: number]: number }, productClassIds?: SelectableValue, productSubClassIds?: SelectableValue, scanProductOptions?: boolean[] }, ProductQueryProps, {}>(FindAllSalesPlanProductsAsProductsQuery, {
    alias: 'withProducts',
    skip(ownProps) {
      return ownProps.salesPlanId === undefined || ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId] === undefined;
    },
    options(ownProps) {
      const customerId = ownProps.customerIdsBySalesPlanId[ownProps.salesPlanId!];

      return {
        variables: {
          filters: [
            ...(opts && opts.onlyIncludeParentReplenishmentProducts ? [{ field: 'isItsOwnReplenishmentProduct', values: [true] }] : []),
            { field: 'customer', values: [customerId] },
            ...applyFilterIfListHasValues('salesPlan', [ownProps.salesPlanId]),
            ...applyFilterIfListHasValues('productClass', ownProps.productClassIds),
            ...applyFilterIfListHasValues('productSubClass', ownProps.productSubClassIds),
            ...applyFilterIfListHasValues('scanProduct', ownProps.scanProductOptions),
          ],
          sort: [{ sortOrder: SORT_TYPES.ASC, sortField: 'identifier' }],
        },
        fetchPolicy: 'network-only',
      };
    },
    props(props): SalesPlanProductQueryProps {
      const { data } = props;
      if (data.loading || data.salesPlanProducts === undefined) {
        return {
          productOptions: [],
          productsLoading: data.loading,
        };
      }

      const uniqueProducts = _.uniqBy(data.salesPlanProducts.map(salesPlanProduct => salesPlanProduct.product), product => product.id);

      return {
        productOptions: uniqueProducts.map(product => {
          return {
            id: product.id,
            cells: [
              product.identifier,
              product.description,
            ],
          };
        }),
        productsLoading: data.loading,
      };
    },
  });
};

export interface SalesPlanQueryProps {
  salesPlanOptions: DropDownOptions;
  salesPlansLoading: boolean;
  customerIdsBySalesPlanId: { [salesPlanId: number]: number };
}

export const withSalesPlans = msyncQuery<FindAllSalesPlansQueryResponse, { customerId?: number }, SalesPlanQueryProps, {}>(FindAllSalesPlansQuery, {
  alias: 'withSalesPlans',
  options(ownProps) {
    return {
      variables: {
        sort: [{ sortOrder: SORT_TYPES.DESC, sortField: 'year' }],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): SalesPlanQueryProps {
    const { data } = props;
    if (data.loading || data.salesPlans === undefined) {
      return {
        salesPlanOptions: [],
        salesPlansLoading: data.loading,
        customerIdsBySalesPlanId: {},
      };
    }

    const customerIdsBySalesPlanId = data.salesPlans.reduce((acc, salesPlan) => {
      acc[salesPlan.id] = salesPlan.customerId;
      return acc;
    }, {});

    return {
      salesPlanOptions: data.salesPlans.map(salesPlan => ({
        id: salesPlan.id,
        value: `${salesPlan.customerIdentifier} - ${salesPlan.year} - ${salesPlan.sellDepartmentIdentifier} - ${salesPlan.identifier}`,
        identifier: salesPlan.identifier,
      })),
      salesPlansLoading: data.loading,
      customerIdsBySalesPlanId,
    };
  },
});

interface FindAllSalesPlanProductsQueryResponse {
  salesPlanProducts?: Array<{
    id: number;
    product: {
      id: number;
      productSubClass: {
        id: number;
        productClass: {
          id: number;
        };
      };
    };
  }>;
}

const FindAllSalesPlanProductsQuery = gql`
  query FindAllSalesPlanProductsVarietiesQuery($sort: [SortInput!], $filters: [FilterSpecificationInput], $search: SearchInput, $limit: Int, $offset: Int, $scope: [FilterSpecificationInput]) {
    salesPlanProducts: findAll(type: SalesPlanProduct, sort: $sort, filters: $filters, search: $search, limit: $limit, offset: $offset, scope: $scope) {
      ... on SalesPlanProduct {
        id
        product {
          id
          productSubClass {
            id
            productClass {
              id
            }
          }
        }
      }
    }
  }
`;

export interface SalesPlanProductsQueryProps {
  possibleSubClassIds: number[];
  possibleClassIds: number[];
}

export const withSalesPlanProducts = msyncQuery<FindAllSalesPlanProductsQueryResponse, { salesPlanId?: number }, SalesPlanProductsQueryProps, {}>(FindAllSalesPlanProductsQuery, {
  alias: 'withSalesPlanProducts',
  skip(ownProps) {
    return !ownProps.salesPlanId;
  },
  options(ownProps) {
    return {
      variables: {
        filters: [
          ...applyFilterIfListHasValues('salesPlanId', [ownProps.salesPlanId]),
        ],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props) {
    const { data } = props;
    if (data.loading || data.salesPlanProducts === undefined) {
      return {
        possibleSubClassIds: [],
        possibleClassIds: [],
      };
    }

    const uniqueProducts = _.uniqBy(data.salesPlanProducts.map(salesPlanProduct => salesPlanProduct.product), product => product.id);
    const uniqueSubClasses = _.uniqBy(uniqueProducts.map(product => product.productSubClass), subClass => subClass.id);
    const uniqueClasses = _.uniqBy(uniqueSubClasses.map(subClass => subClass.productClass), productClass => productClass.id);

    return {
      possibleSubClassIds: idsFor(uniqueSubClasses),
      possibleClassIds: idsFor(uniqueClasses),
    };
  },
});
