import { MileageRatesForm } from 'client/components/crud-forms/mileage-rate-form';
import { buildAdminDetailPage } from '../admin/admin-detail-page';
import EmptyStatusToggle from 'client/components/record-bar/empty-record-bar-status-toggle';

export default buildAdminDetailPage({
  formName: 'MileageRateDetailsForm',
  table: 'MileageRate',
  FormComponent: MileageRatesForm,
  OptionalStatusToggle: EmptyStatusToggle,
});
