import * as React from 'react';
import { Form, Col, FormGroup } from 'client/components/third-party';
import * as Constants from './constants';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import { ImmutableDateRange } from 'shared/types/date-range';
import { DateStr, ReportTypes } from 'shared/types';
import * as Components from '../shared/components';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { MutationStatus } from 'client/actions/mutations';
import { Field } from 'redux-form';
import { LabeledCheckbox } from 'client/components/form';
import { SelectableRow } from 'client/components/selectable/types';

export interface ComponentProps {
  downloadButtonDisabled: boolean;

  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;

  mfcArrivalBeginDate: DateStr | undefined;
  mfcArrivalEndDate: DateStr | undefined;

  customerOptions?: SelectableRow[];
  customersLoading?: boolean;

  handleDownloadExcelReportClicked(): void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handleMfcArrivalDateRangeChanged: (dateRange: ImmutableDateRange) => void;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportDownloadStatus: MutationStatus;
  storeDeliveryDateDisabled: boolean;
  mfcArrivalDateDisabled: boolean;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class ShippingStatusWarehouseReportUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar title="Shipping Status Warehouse Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-shipping-status-warehouse-table-wrapper">
            <Form horizontal>
              <Col sm={12}>
                <FormGroup>
                  <div className="mfc-form-stacked-label">Note: This report is only for rack-based orders.</div>
                </FormGroup>
              </Col>
              <Components.Customer
                name={Constants.FormFields.customerIds}
                options={this.props.customerOptions}
                loading={this.props.customersLoading}
              />
              <Col sm={12}>
                <FormGroup>
                  <Field
                    name={Constants.FormFields.includeCustomerSheet}
                    component={LabeledCheckbox}
                    inputColSize={4}
                    label="Include Customer sheet"
                    labelColSize={4}
                    offset={0}
                    horizontalLabel={false}
                    testid="include-customer-sheet"
                  />
                </FormGroup>
              </Col>
              <Components.DateRange
                handleChange={this.props.handleDateRangeChanged}
                beginDate={this.props.beginDate}
                endDate={this.props.endDate}
                label="Store Delivery Date"
                disabled={this.props.storeDeliveryDateDisabled}
              />
              <Col sm={12}>
                <FormGroup>
                  <Field
                    name={Constants.FormFields.includeSupplierSheet}
                    component={LabeledCheckbox}
                    inputColSize={4}
                    label="Include Supplier sheet"
                    labelColSize={4}
                    offset={0}
                    horizontalLabel={false}
                    testid="include-supplier-sheet"
                  />
                </FormGroup>
              </Col>
              <Components.DateRange
                handleChange={this.props.handleMfcArrivalDateRangeChanged}
                beginDate={this.props.mfcArrivalBeginDate}
                endDate={this.props.mfcArrivalEndDate}
                label="MFC Arrival Date"
                disabled={this.props.mfcArrivalDateDisabled}
              />
            </Form>
          </div>
          <Components.ShippingStatusWarehouseSidebar
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportType={ReportTypes.ShippingStatusWarehouse}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}
