import * as React from 'react';

import buildAdminCreatePage from './admin/admin-create-page';
import { buildAdminDetailPage } from './admin/admin-detail-page';
import { buildAdminListPage } from './admin';

import { FormGroup, Form, Col } from 'client/components/third-party';
import { RecordBarDetail } from 'client/components/record-bar';
import { SpecializeField } from 'client/components/form';
import { SupplierLocation } from 'shared/schemas/supplier-location';
import { optionsContainerGenerator } from 'client/hoc/options-container-generator';

const Field = SpecializeField(SupplierLocation, { alwaysShowErrors: true, hideOptionalLabel: true });

const SupplierLocationForm = (p: {
  handleSubmit: () => unknown,
  pristine: boolean,
  invalid: boolean,
  submitting: boolean,
  formName: string,
  dispatchedChange?: () => unknown,
  displayContacts?: SimpleObject[],
  website?: string,
  record?: SupplierLocation,
  suppliers?: Array<{id: number, identifier: string, name: string}>,
  suppliersLoading?: boolean,
}) => {
  return (
    <Col sm={12}>
      <Form horizontal onSubmit={p.handleSubmit} data-testid="supplier-location">
        <div className="mfc-form-heading"></div>
        <FormGroup data-testid="info-row-1">
          <Field name="supplierId"          labelColSize={2} inputColSize={8} options={p.suppliers} loading={p.suppliersLoading} autoFocus={!(p.record || {}).id} />
        </FormGroup>
        <FormGroup data-testid="info-row-2">
          <Field name="identifier"          labelColSize={2} inputColSize={4} />
          <Field name="colorCode"           labelColSize={1} inputColSize={1} />
        </FormGroup>
        <FormGroup data-testid="info-row-3">
          <Field name="address"             labelColSize={2} inputColSize={4} />
          <Field name="contact"             labelColSize={1} inputColSize={3} />
        </FormGroup>
        <FormGroup data-testid="info-row-5"> <Field name="driverInstructions" labelColSize={2} inputColSize={8} /> </FormGroup>
        <FormGroup data-testid="info-row-6"> <Field name="mfcNotes"           labelColSize={2} inputColSize={8} /> </FormGroup>
      </Form>
    </Col>
  );
};

const withSuppliers = optionsContainerGenerator({ table: 'suppliers', columns: ['identifier', 'name'] });

export default {
  ListPage: buildAdminListPage(SupplierLocation, {
    columns     : ['supplier', 'identifier', 'address', 'contact', 'colorCode', 'driverInstructions', 'mfcNotes'],
    excelColumns: ['supplier', 'identifier', 'address', 'contact', 'colorCode', 'driverInstructions', 'mfcNotes'],
  }),
  DetailPage: buildAdminDetailPage({
    table: 'SupplierLocation',
    formName: 'supplierLocationDetailForm',
    FormComponent: withSuppliers(SupplierLocationForm as shame),
    RecordBarDetailComponent: (p: {record: {identifier: string}}) => ( <RecordBarDetail title="Identifier"> {p.record.identifier} </RecordBarDetail> ),
    descriptionResolver: supplier => supplier.name,
  }),
  CreatePage: buildAdminCreatePage({
    table: 'supplierLocations',
    formName: 'supplierLocationCreateForm',
    FormComponent: withSuppliers(SupplierLocationForm as shame),
  }),
};
