import { Col, Form, FormGroup, Row } from 'client/components/third-party';
import { DateStr } from 'shared/types';
import { MapToReportUserParams, MapFromReportUserParams } from 'client/components/report-user-params/types';
import { RecordBar } from 'client/components/record-bar/record-bar-back-back-button-and-title';
import * as Components from '../shared/components';
import * as Constants from './constants';
import * as React from 'react';
import { MutationStatus } from 'client/actions/mutations';
import { DropDownOptions } from 'client/types';

export interface ComponentProps {
  handleDownloadExcelReportClicked(): void;

  customerOptions: DropDownOptions;

  downloadButtonDisabled: boolean;

  customersLoading: boolean;

  date: DateStr | undefined;

  mapToReportUserParams: MapToReportUserParams;
  mapFromReportUserParams: MapFromReportUserParams;

  pristine: boolean;

  reportDownloadStatus: MutationStatus;
  selectedPresetId?: number;
  isLoadingPreset: boolean;
}

export class CutProcessingUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    return (
      <div>
        <RecordBar title="Cut Processing Report" hideBackButton />
        <div id="mfc-page-content" className="mfc-page-content mfc-form">
          <div className="mfc-form-details-with-sidebar-but-no-tabs reports-replenishment-by-product-table-wrapper mfc-scrolling-region-adjacent-to-sidebar">
            <Form horizontal>
              <Row className="report-fields-outside-tabs">
                <Col sm={12}>
                  <FormGroup>
                    <Components.Customer
                      name={Constants.FormFields.customerId}
                      options={this.props.customerOptions}
                    />

                    <Components.Date
                      name={Constants.FormFields.date}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <Components.Sidebar
            reportType={Constants.reportType}
            downloadButtonDisabled={this.props.downloadButtonDisabled}
            handleDownloadExcelReportClicked={this.props.handleDownloadExcelReportClicked}
            mapToReportUserParams={this.props.mapToReportUserParams}
            mapFromReportUserParams={this.props.mapFromReportUserParams}
            pristine={this.props.pristine}
            excelDownloadStatus={this.props.reportDownloadStatus}
            reportFormName={Constants.formName}
            selectedPresetId={this.props.selectedPresetId}
            isLoadingPreset={this.props.isLoadingPreset}
          />
        </div>
      </div>
    );
  }
}
