import gql from 'graphql-tag';
import { ImportSalesPlanVarietyColorDetailsInput } from 'schema/import-sales-plan/types';

export interface ValidateSalesPlanVarietyColorDetailsInput {
  input: ImportSalesPlanVarietyColorDetailsInput;
}
export interface ValidateSalesPlanVarietyColorDetailsResponse {
  validateSalesPlanVarietyColorDetails: {
    success: boolean;
    errors: string[];
  };
}

export const ValidateSalesPlanVarietyColorDetailsQuery = gql`
  query ValidateSalesPlanVarietyColorDetailsQuery($input: ImportSalesPlanVarietyColorDetailsInput!) {
    validateSalesPlanVarietyColorDetails: ValidateSalesPlanVarietyColorDetails(input: $input) {
      success
      errors
    }
  }
`;
