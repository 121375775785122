import * as _ from 'lodash';
import { getFormValues, isPristine  } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { ReplenishmentByStoreUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as SharedActions from '../shared/actions';
import { DateStr, ImmutableDateRange, ReplenishmentByStoreGroupingOptions, SelectableValue } from 'shared/types';
import { Thunker } from 'client/types/redux-types';
import * as Queries from 'client/app/orders/reports/shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  pristine: boolean;
  customerId: number | undefined;
  sellDepartmentId: number | undefined;
  productClassIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  salesPlanId: number | undefined;
  scanProductOptions: boolean[] | undefined;
  downloadButtonDisabled: boolean;
  mfcAreaIds: SelectableValue | undefined;
  beginDate: DateStr | undefined;
  endDate: DateStr | undefined;
  regionIds: SelectableValue | undefined;
  marketIds: SelectableValue | undefined;
  storeIds: SelectableValue | undefined;
  includeStoresNotShipped: boolean;
  filterByCategoryOrSalesPlan: Constants.FilterByCategoryOrSalesPlan;
  pieceTargetBeginDate: DateStr | undefined;
  pieceTargetEndDate: DateStr | undefined;
  pieceTargetPrimaryStoresOnly: boolean | undefined;
  reportDownloadStatus: MutationStatus;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
  groupBy: ReplenishmentByStoreGroupingOptions;
}

const mapStateToProps = (state: State.Type, props: shame): StateProps => {
  const pristine = isPristine(Constants.formName)(state);

  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const filterByCategoryOrSalesPlan = Selectors.getFilterByCategoryOrSalesPlan(state);

  if (!formValues) {
    return {
      pristine: true,
      customerId: undefined,
      sellDepartmentId: undefined,
      productClassIds: undefined,
      productSubClassIds: undefined,
      salesPlanId: undefined,
      scanProductOptions: undefined,
      downloadButtonDisabled: true,
      beginDate: undefined,
      endDate: undefined,
      regionIds: undefined,
      marketIds: undefined,
      storeIds: undefined,
      mfcAreaIds: undefined,
      includeStoresNotShipped: false,
      filterByCategoryOrSalesPlan,
      pieceTargetBeginDate: undefined,
      pieceTargetEndDate: undefined,
      pieceTargetPrimaryStoresOnly: false,
      reportDownloadStatus: MutationStatus.Initial,
      primaryGlobalAll: undefined,
      groupBy: ReplenishmentByStoreGroupingOptions.GroupByStore,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);
  const scanProductOptions = Selectors.getScanProductOptions(state);

  return {
    pristine,
    customerId: formValues[Constants.FormFields.customerId],
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    salesPlanId: formValues[Constants.FormFields.salesPlanId],
    scanProductOptions,
    downloadButtonDisabled: shouldDownloadBeDisabled,
    mfcAreaIds: formValues[Constants.FormFields.mfcAreaIds],
    beginDate: formValues[Constants.FormFields.beginDate],
    endDate: formValues[Constants.FormFields.endDate],
    regionIds: formValues[Constants.FormFields.regionIds],
    marketIds: formValues[Constants.FormFields.marketIds],
    storeIds: formValues[Constants.FormFields.storeIds],
    includeStoresNotShipped: formValues[Constants.FormFields.includeStoresNotShipped],
    filterByCategoryOrSalesPlan,
    pieceTargetBeginDate: formValues[Constants.FormFields.pieceTargetBeginDate],
    pieceTargetEndDate: formValues[Constants.FormFields.pieceTargetEndDate],
    pieceTargetPrimaryStoresOnly: formValues[Constants.FormFields.pieceTargetPrimaryStoresOnly],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
    groupBy: formValues[Constants.FormFields.groupBy],
  };
};

interface DispatchProps {
  handleDownloadExcelReportClicked(): Thunker;
  handleCustomerChanged: (customerId: number) => void;
  handleDateRangeChanged: (dateRange: ImmutableDateRange) => Thunker;
  handlePieceTargetDateRangeChanged: (dateRange: ImmutableDateRange) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => Thunker;
  handleProductClassesFilterChanged: (productClassIds: number[]) => Thunker;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => Thunker;
  handleMfcAreasChanged: (ids: number[]) => Thunker;
  handleMarketsChanged: (ids: number[]) => Thunker;
  handleRegionsChanged: (ids: number[]) => Thunker;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleCustomerChanged: SharedActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDateRangeChanged: SharedActions.handleDateRangeChanged(Constants.formName, Constants.FormFields),
  handlePieceTargetDateRangeChanged: SharedActions.handlePieceTargetDateRangeChanged(Constants.formName, Constants.FormFields),
  handleSellDepartmentChanged: SharedActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleMfcAreasChanged: SharedActions.handleMfcAreasChanged(Constants.formName, Constants.FormFields),
  handleMarketsChanged: SharedActions.handleMarketsChanged(Constants.formName, Constants.FormFields),
  handleRegionsChanged: SharedActions.handleRegionsChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handlePOBasedFilterChanged: SharedActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  Queries.MfcAreasQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  SharedQueries.SalesPlanQueryProps &
  SharedQueries.RegionQueryProps &
  SharedQueries.MarketQueryProps &
  SharedQueries.StoreQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.scanBased]: true,
      [Constants.FormFields.poBased]: true,
      [Constants.FormFields.primaryGlobalAll]: PrimaryGlobalAll.All,
      [Constants.FormFields.filterByCategoryOrSalesPlan]: Constants.FilterByCategoryOrSalesPlan.ByCategory,
      [Constants.FormFields.groupBy]: ReplenishmentByStoreGroupingOptions.GroupByStore,
    },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withMfcAreas,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({onlyIncludeParentReplenishmentProducts: true}),
  SharedQueries.withSalesPlans,
  SharedQueries.withRegions,
  SharedQueries.withMarkets,
  SharedQueries.withStores,
  UserParamsMappers.withReplenishmentByStoreReportUserParamMappers,
)(ReplenishmentByStoreUI) as Component<OwnProps>;

export const ReplenishmentByStore = (props: OwnProps) => propToComponent(component, props);
