import 'reflect-metadata';
import { tableName, property, definePresentation, manyToMany, required, setSchemaOptions, belongsTo, FormDisplayType } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES } from '../types';
import { IRecord, Saved } from './record';
import { Product } from 'shared/schemas/product';
import { Supplier, SupplierId } from './supplier';
import { SupplierDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('supplierLocations')
export class SupplierLocation implements IRecord {
            id                 ?: number       ;
  @required
  @property identifier          : string       ;
  @required
  @belongsTo('suppliers', { foreignDisplayKey: 'name', foreignQueryKeys: ['identifier', 'name'] })
  @property supplier            : Saved<Partial<Supplier>>;
  @property supplierId          : SupplierId              ;
  @property address            ?: string | null           ;
  @property contact            ?: string | null           ;
  @property driverInstructions ?: string | null           ;
  @property mfcNotes           ?: string | null           ;
  @property colorCode          ?: string | null           ;
  @manyToMany('products', {through: 'productsSupplierLocations', foreignDisplayKey: 'identifier' })
  @property products: Array<Saved<Partial<Product>>>;
}

const tableAble = {
  filterable: true,
  sortable: true,
  tableDisplay: true,
  searchable: true,
} as const;

const forListAndSidebar = (
  displayName: string,
  columnWidth = 10,
  formDisplayType: FormDisplayType = displayType(DISPLAY_TYPES.INPUT),
  tableDisplayColumns: undefined | SimpleObject = undefined,
) => ({
  ...tableAble,
  tableDisplayColumns,
  columnWidth,
  displayName: tableDisplayColumns ? undefined : displayName,
  formDisplayType,
});

definePresentation(SupplierLocation, {
  supplierId          : {formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }), tableDisplay: false},
  supplier            : {...tableAble, tableDisplayColumns: { identifier: 'Supplier Code', name: 'Supplier' }, columnWidth: 14 },
  identifier          : {...forListAndSidebar('Location'), filterable: false },
  address             : forListAndSidebar('Address', 17),
  contact             : forListAndSidebar('Contact', 17),
  driverInstructions  : {...forListAndSidebar('Driver Instructions', 14), sortable:   false, filterable: false },
  mfcNotes            : {...forListAndSidebar('MFC Notes', 14)          , sortable:   false, filterable: false },
  colorCode           : {...forListAndSidebar('Color', 6)              , searchable: false, filterable: false },
});

setSchemaOptions(SupplierLocation, {
  softDeletable: true,
  hasTimestamps: true,
  // generateGraphqlSchema: true,
  defaultSort: [
    {sortField: 'supplier', sortOrder: 'ASC', foreignColumn: 'name'},
    {sortField: 'identifier', sortOrder: 'ASC'},
  ],
});
