/**
 * @module shared/types/meta
 * Meta-programming related types and helpers.
 * Makes it easier to manipulate types and objects both at compile time and runtime.
 */

import * as _ from 'lodash';
import { orThrow } from 'shared/helpers/or-throw';

export const delay = (ms: number) => new Promise<void>(resolve => setTimeout(() => resolve(), ms));
export function * iterateKeys<T extends SimpleObject>(subject: T): IterableIterator<keyof T> {
  for (const key of Object.keys(subject)) {
    yield key as keyof T;
  }
}

export function isNotActuallyPartial<T>(subject: Partial<T>): subject is T {
  for (const key of iterateKeys(subject)) {
    if (subject[key] === undefined || subject[key] === null) {
      return false;
    }
  }

  return true;
}

export function allOrNothing<T>(subject: Partial<T>, onValueMissing?: (key: keyof T) => void): T | undefined {
  if (isNotActuallyPartial(subject)) {
    return subject;
  }

  if (onValueMissing) {
    for (const key of iterateKeys(subject)) {
      if (subject[key] === undefined) {
        onValueMissing(key);
      }
    }
  }

  return undefined;
}

export function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export function perEnum<E extends string, V>(map: { [K in E]: V }) {
  return map;
}

export enum TYPES {
  STRING = 'string',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  NUMBER = 'number',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
  MONEY = 'momey',
  CHOICE = 'choice',
}

export type AnyFn = (...args: any[]) => any;

/** Convert a union to an intersection, e.g. {a: 1} | {a: 2, b: 3} => {a: 1} & {a: 2, b: 3} */
export type Intersect<U> = (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;

/** Tuple to Collapsed Intersection */
export type Merge2<A, B> = {[K in Exclude<keyof A, keyof B>]: A[K]} & {[K in keyof B]: K extends keyof A ? A[K] | B[K] : B[K]};
export type Merge<T extends unknown[]>
  = T extends [infer A0] ? A0
  : T extends [infer A1, infer B1] ? Merge2<A1, B1>
  : T extends [infer A2, infer B2, infer C2] ? Merge2<A2, Merge2<B2, C2>>
  : T extends [infer A3, infer B3, infer C3, infer D3] ? Merge2<A3, Merge2<B3, Merge2<C3, D3>>>
  : T extends [infer A4, infer B4, infer C4, infer D4, infer E4] ? Merge2<A4, Merge2<B4, Merge2<C4, Merge2<D4, E4>>>>
  : T extends [infer A5, infer B5, infer C5, infer D5, infer E5, infer F5] ? Merge2<A5, Merge2<B5, Merge2<C5, Merge2<D5, Merge2<E5, F5>>>>>
  : never;

/** Convert an intersection to an object, e.g. {a: 1} & {a: 2, b: 3} => {a: 1 & 2, b: 3} */
export type Collapse<T> = T extends SimpleObject ? { [K in keyof T]: T[K] } : T;

/** Intersection of keys, union of possible values for each. */
export type Commonality<A, B> = Collapse<{[K in keyof (A | B)]: A[K] | B[K]}>;

/** Superset of keys, union of possible values for each. */
export type Superset<A, B> = Collapse<{[K in (keyof A | keyof B)]: K extends keyof A ? K extends keyof B ? A[K] | B[K] : A[K] | undefined : K extends keyof B ? B[K] | undefined : undefined}>;

/** Type that works similar to lodash.omit */
export type Omit<T, U extends keyof T> = {[K in Exclude<keyof T, U>]: T[K]};

/** Convert an object to a union of its values, e.g. {a: 1 & 2, b: 3} => (1 & 2) | 3 */
export type Values<T> = T extends SimpleObject ? T[keyof T] : never;

type InvertedEntry<T extends Dictionary<string>, K extends keyof T> = {[V in T[K]]: K};
export type InvertedMap<T extends Dictionary<string>>
  = Collapse<Intersect<Values<{[K in keyof T]: InvertedEntry<T, K>}>>>;

/** Just like the built in ReturnType<T> except if T is not a function, then T is resolved instead */
export type Return<T> = T extends ((...args: any[]) => any) ? ReturnType<T> : T;

/** Just like the built in ReturnType<T> except if T is not a function, then T is resolved instead */
export type AwaitedReturn<F> = Return<F> extends Promise<infer V> ? V : Return<F>;
// export type AwaitedReturn<F> = F extends ((...args: unknown[]) => Promise<infer V>) ? V : Return<F>; // I thinkk above version is cleaner (if it works fine)

/** Just like the built in ReturnType<T> except if T is not a function, then T is resolved instead */
export type Args<F> = F extends ((...args: infer Arguments) => unknown) ? Arguments : never;

/** Convert a map of functions to a map of results, e.g. {a: string, f: () => number, g: (x: Int) => Int} ==> {a: string, f: number, g: Int} */
export type MapValues<T> = T extends SimpleObject ? {[K in keyof T]: Return<T[K]>} : never;

/** L for Literal -- makes the hover show the constant value you assigned instead of 'string'
 * @deprecated -- DEPRECATED -- use `const` keyword instead.
*/
export const L = <T extends string | number | boolean | symbol | [] | {}>(x: T) => x;

/** https://gist.github.com/jcalz/381562d282ebaa9b41217d1b31e2c211
 * as const puts in this nasty readonly goop unwante for producing Pick arrays or Object.keys arrays.
 * Could try stripping off the readonly, but found a different way before trying it:
*/
export const Tuple = <T extends unknown[]>(...args: T) => args;

export type ValueOrPromise<T> = T | Promise<T>;
export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

/** For when you want literal, not const: `as const` gives you a (potentially complex) literal type -- this keeps it literal, but removes the readonly modifiers. */
export const unConst = <T extends {}>(x: T) => x as DeepWriteable<T>;

/** Get a union of element types in an array type. */
export type ElementOf<T> = T extends Array<infer U> ? U : T;

/** this wraps the special `unique symbol` to allow its use in mapped type values, where normally use is forbidden by ts1335. */
export const UniqueSymbol: unique symbol = Symbol();

/** Just list some strings, and this will bake a bag of symbols for you to use as property keys. */
export const makeSymbols = <T extends string>(...names: T[]) =>
  Object.freeze(names.reduce((o, n) => ({...o, [n]: Symbol(n)}), {}) as unknown as {readonly [K in T]: typeof UniqueSymbol});

/** Fized length string - like SQL Char(n) */
export type Char<Length extends Int> = string & {
  length: Length;
};

/** Limited variable length string - like SQL Varchar(n) */
export type Varchar<MaxLength extends Int> = string & {
  /** this is `length` not `maxLength` so that it matches string.length */
  length: MaxLength;
};

/** Create a type with the common properties */
export type Common<A, B> = { [P in keyof A & keyof B]: A[P] | B[P] }; // https://stackoverflow.com/a/47379147/4592309

// type Partial<T> = { [P in keyof T]?: T[P]; };
/** Similar to Partial, but props can also be null, and there can be extra props. */
export type Frag<O> = { [K in keyof O]?: O[K] | null | undefined; };

/** Applies Frag<T> recursively. */
export type FragDeep<O> = { [K in keyof O]?: null | undefined | FragDeep<O[K]>; };

/** Takes an Optional value and permits it to be null as well as undefined. */
export type OptionalAcceptsNull<O> = O extends undefined ? undefined | null | O : O;

/** Takes Optional props and permits null values on those props as well as undefined. */
export type PartialToFrag<O> = { [K in keyof O]: OptionalAcceptsNull<O[K]> };

/** Applies PartialToFrag<T> recursively. */
export type PartialToFragDeep<O> = { [K in keyof O]: OptionalAcceptsNull<PartialToFragDeep<O[K]>> };

/** Explicitly narrows types so you can use them in particular places -- e.g. WhereInField must be a numeber for most of our DataLoaders; like assertCompatible, but purley as a type. */
export type ConformingTo<Subject, Target> = Subject extends Target ? Subject : never;

/** Explicitly narrows types so you CANNOT use them in particular places -- inverse of ConformingTo<,> */
export type NotConformingTo<Subject, Target> = Subject extends Target ? never : Subject;

/** Filter types to just those having a certain prop. */
export type HavingProp<Subject, Prop extends string> = Prop extends keyof Subject ? Subject : never

/** Filter types to just those having a certain prop. */
export type MissingProp<Subject, Prop extends string> = Prop extends keyof Subject ? never : Subject;

/////
///// /** Sometimes literal types are known at compile time -- sometimes not.
///// /** how to allow hard constrain to a broad type, accommodating runtime-deferred values,
///// /** but also permitting and propagating const literal types also?
///// /** -- ConformTo and Suggest with For,Of,Unrecognized to annotate WHY   --- its magic!
/////

/** intersect this with your types to annotate them with callsite context (what is it for, what is it of, is it an unknown / unregistered value such as a Table or Column?) */
export interface For<_Actual> {}
/** intersect this with your types to annotate them with callsite context (what is it for, what is it of, is it an unknown / unregistered value such as a Table or Column?) */
export interface Of<_Actual> {}
/** intersect this with your types to annotate them with callsite context (what is it for, what is it of, is it an unknown / unregistered value such as a Table or Column?) */
export interface UnknownTable<_Actual> {}
/** intersect this with your types to annotate them with callsite context (what is it for, what is it of, is it an unknown / unregistered value such as a Table or Column?) */
export interface UnknownColumn<_Actual> {}

/**
 * Use this by making a generic type arg for your function,
 * e.g. T, but make the actual argument : Suggest<T, PreferredButNotEnforcedListOfChoices>
 * -- very helpful for incrementally enhancing types or working with external domains
 * using soft constraints without losing type inference and auto-complete suggestions
 */
export type Suggest<T extends string, O extends string> = T | O;

export type SuggestArray<T extends string[], O extends string> = T | [O];

/** Permits opportunistic narrowing; if Subject conforms to Target, specific Subject is used.  Otherwise, wider Target is substituted. */
export type ConformTo<Subject, Target> = Subject extends Target ? Subject : Target;
export type PropsExtending<T, V> = {[K in keyof T]: T[K] extends V ? ConformingTo<K, keyof T> : never}[keyof T];
export type PropsNotExtending<T, V> = {[K in keyof T]: T[K] extends V ? never : ConformingTo<K, keyof T>}[keyof T];
export type PickPropsExtending<T, V> = Pick<T, PropsExtending<T, V>>;
export type PickPropsNotExtending<T, V> = Pick<T, PropsNotExtending<T, V>>;
export type Arg1<F> = F extends ((a: infer First, ...z: any) => any) ? First : never;
export type Arg2<F> = F extends ((a: any, b: infer Second, ...z: any) => any) ? Second : never;
export type Arg3<F> = F extends ((a: any, b: any, c: infer Third, ...z: any) => any) ? Third : never;
export type Arg4<F> = F extends ((a: any, b: any, c: any, d: infer Fourth, ...z: any) => any) ? Fourth : never;
export type Arg5<F> = F extends ((a: any, b: any, c: any, d: any, e: infer Fifth, ...z: any) => any) ? Fifth : never;
export type Arg6<F> = F extends ((a: any, b: any, c: any, d: any, e: any, f: infer Sixth, ...z: any) => any) ? Sixth : never;
export const getNumber = <X, K extends PropsExtending<X, number>>(x: X, key: K) => x[key] as ConformingTo<X[K], number>;
export const conformingToType = <Target>() => <Subject = unknown>(subject: ConformingTo<Subject, Target>) => subject;

export type DelegateProps<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;
export type DelegatePropsOf<Component, DelegatedProps extends keyof Arg1<Component>> = DelegateProps<Arg1<Component>, DelegatedProps>;

/** E.G. to assign a name to an anonymous function, such as for backgrounding, or as other runtime meta programming requires. */
export const rename = <X, N extends string>(x: X, name: N) => {
  Object.defineProperty(x, 'name'       , {value: name});
  Object.defineProperty(x, 'displayName', {value: name});
  return x as X & {name: N};
};

/** e. g. to list Repository methods -- see http://code.fitness/post/2016/01/javascript-enumerate-methods.html */
export const getInstanceMethodNames = (o, prototypeChainDepth: Int = 1) => {
  const methods: string[] = [];
  let proto = o;
  for (let i = 0; proto && i <= prototypeChainDepth; i++) {
    methods.push(...(Object.getOwnPropertyNames(proto).filter (k => {
      if (k === 'constructor') return false;
      const desc = Object.getOwnPropertyDescriptor (proto, k);
      return !!desc && typeof desc.value === 'function';
    })));

    proto = Object.getPrototypeOf(proto);
  }

  return methods;
};

/** Coalesces nil to an empty X[], wraps [x], or returns x if it is already X[]. */
export const ensureArray = <X extends unknown>(x?: null | X | X[]): X[] =>
    x === undefined   ? []
  : x === null        ? []
  : Array.isArray(x)  ? x
  :                     [x];

/** Like `ensureArray()` but furthermore removes nil elements. Slightly different from _.compact because non-nil falsey elements are retained. */
export const ensureCompactArray = <X extends unknown>(x?: X | X[]): X[] => ensureArray<X>(x).filter(y => y !== null && y !== undefined);

/** Like _.remove(), but only the first match is removed, and the result is the single matching element, not an array of elements removed. */
export const removeFirst = <X>(xs: X[], predicate: (x: X) => boolean): X | undefined => {
  if (!xs?.length) return undefined;
  const i = xs.findIndex(predicate);
  if (i < 0) return undefined;
  return xs.splice(i, 1)[0];
};

export const wholePixels = (px?: string | null) => Math.ceil(Number.parseFloat(px?.toLowerCase()?.replace('px', '') ?? '0'));
export const sumResults = <O extends unknown>(o: O, ...projections: Array<(o: O) => number>) => _.sumBy(projections, p => +p(o ?? {} as O) || 0);
export const sumProps = <O extends {}>(o: O, conditioner: (v: any) => number, ...props: Array<keyof O>) => sumResults(o, ...props.map(p => x => conditioner(+(x ?? {} as O)[p])));
export const getOutsideHeight = (e?: Element) => !e ? 0 : e.clientHeight + sumProps(window.getComputedStyle(e), wholePixels, 'marginTop', 'borderTopWidth', 'borderBottomWidth', 'marginBottom');

/** https://stackoverflow.com/a/52323412/250988 */
export const shallowEquals = (a?: SimpleObject, b?: SimpleObject) => Object.keys(a ?? {}).length === Object.keys(b ?? {}).length && Object.keys(a ?? {}).every(k => b?.hasOwnProperty(k) && a?.[k] === b?.[k]);

/** helps with lodash types are crummy for _.mapValues */
export const strongMapValues =
  <T extends SimpleObject, F extends <K extends keyof T>(v: T[K], k: K) => unknown>
  (o: T, f: F) => _.mapValues(o, f) as {[K in keyof T]:  Return<F> extends undefined | null ? T[K] : Return<F>};

/**
 * Unlike lodash groupBy, result is an object, whereas lodash yields a dictionary that eats key and element types.
 */
 export const groupBy = <X, Y extends string>(xs: X[], keySelector: (x: X) => Y, includeNilKeys: boolean = false) =>
  _(xs)
  .filter(x => includeNilKeys || !_.isNil(keySelector(x)))
  .groupBy(keySelector)
  .mapValues((v: X[], k: Y) => v.length > 0 ? v : orThrow(`meta.groupBy() => empty key detected "${k}" : [${v}]`))
  .value();

/** Split a collection into two collections based on a classifier, e.g. wheat vs chaff; If totalClasses is not specified, this will work more like a histogram. */
export const classify = <T>(xs: T[], classifier: (x: T) => Int, totalClasses?: Int) => {
  const result = _.range(totalClasses ?? 0).map(x => [] as T[]);
  for (const x of xs) {
    const i = classifier(x);
    if (!result[i] && !_.isNil(totalClasses))
      throw new Error(`classifier returned ${i}, outside the bounds of pre-specified totalClasses (${totalClasses})`);

    if (!result[i]) {
      const additional = i - result.length;
      for (let j = 0; j <= additional; j++)
        result.push([]);
    }
    result[i].push(x);
  }

  return result;
};

/** return the array, or if it is nullish or empty, return a provided alternative. */
export const alternativeIfEmpty = <A extends unknown[]>(array: undefined | null | A, fallback: () => A) => array?.length ? array : fallback();

/** return the array, or create an array of one (Defaulted) element and return that. */
export const defaultIfEmpty = <T>(array: undefined | null | T[], fallback: (() => T) | undefined = undefined) => !!array && array.length > 0 ? array : [fallback ? fallback() : undefined];

/** return the array, or create an array of one (Defaulted) element and return that. */
export const defaultIfEmptyPromise = async <T>(array: undefined | null | T[] | Promise<undefined | null | T[]>, fallback: (() => Promise<T>) | undefined = undefined): Promise<T[]> => {
  const a = await array || [];
  if (a.length > 0) return a;
  if (!!fallback) return [await fallback()];
  return  [undefined as any as T];
};

/** Given O, provides a predicate that matches O as a required duck-value of X */
export const objMatch = <O extends SimpleObject>(o: O) => <X extends Partial<O>>(x: X) => _.keys(o).every(k => x[k] === o[k]);

export const numberToDice = (n: number) => ({0: ' ', 1: '.', 2: ':', 3: '⋰', 4: '∷', 5: '⁙'})[n] || ` ${n} `;
export const numberToBars = (n: number) => ({0: ' ', 1: '.', 2: ':', 3: '⋮', 4: '⁞' })[n] || ` ${n} `;
export const testInclusion = <T, N extends T[]>(v: T, ...set: N): v is ElementOf<N> => set.includes(v);
export const assertInclusion = <T, N extends T[]>(v: T, ...set: N) => testInclusion(v, ...set) ? v : orThrow(`${v} not a member of [${set}]`);
export const assertInRange = (v: number, min: number, max?: number) => testInclusion(v, ...(_.range(min, (max ?? min) + 1))) ? v : orThrow(`${v} not a member of [${ _.range(min, (max ?? min) + 1)}]`);
export const avg = (a: number[]) => _.sum(a) / a.length;
export const tee = <X>(x: X, f: (x: X) => unknown) => { f(x); return x; };
export const btw = <X>(x: X, f: () => unknown) => { f(); return x; };
export const tbd = <X>() => undefined as undefined | X;
