import * as _ from 'lodash';
import { PromiseResolution, PromiseRejection } from 'shared/types/promise';

export interface DebounceableCoreInvocation<T, R> { input: T; throttledResolve: PromiseResolution<R>; throttledReject: PromiseRejection; }
export type DebounceableFunc<T, R> = (args: Array<DebounceableCoreInvocation<T, R>>) => Promise<void> | void;
export type DebounceableCore<T, R> = (args: T) => Promise<R>;

/**
 * Wraps a given core function in stateful helper
 * whose closure variables accumulate args for invocations of the core
 * and then issues those invocations according to a debounce policy of given wait time (default 100 ms).
 *
 * The core function must return its results by invoking PromiseResolution / PromiseRejection callbacks.
 */
export function debounceAccumulatedAsync<T, R>(
  inputFunc: DebounceableFunc<T, R>,
  wait: number = 100,
  opts?: Parameters<typeof _.debounce>[2],
): DebounceableCore<T, R> {
  let accumulatedArgs: Array<DebounceableCoreInvocation<T, R>> = [];
  const wrapper = _.debounce(async () => {
    const theArgs = accumulatedArgs;
    accumulatedArgs = [];
    await inputFunc(theArgs);
  }, wait, opts);

  return (args: T) => {
    return new Promise<R>(async (resolve, reject) => {
      accumulatedArgs.push({ input: args, throttledResolve: resolve, throttledReject: reject });
      try {
        await wrapper();
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function debounceAccumulated(
  inputFunc: any,
  wait: number = 100,
  opts?: Parameters<typeof _.debounce>[2],
) {
  let accumulatedArgs: any[] = [];
  const x = _.debounce(async () => {
    const theArgs = accumulatedArgs;
    accumulatedArgs = [];
    await inputFunc(theArgs);
  }, wait, opts);
  return async args => {
    accumulatedArgs.push(args);
    await x();
  };
}
