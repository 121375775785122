import gql from 'graphql-tag';
import { BolId, BolIdentifier, BolReceivedByInitials, BolAutoCartTrackingEnabled } from 'shared/schemas/bol';
import { UserId, UserFirstName, UserLastName } from 'shared/schemas/user';
import { DateTimeStr, ShippingUnitType, OrderMethod } from 'shared/types';
import { SupplierCartTrackingStatus, SupplierId } from 'shared/schemas/supplier';
import { CartTrackingSupplierEntryId } from 'shared/schemas/cart-tracking-supplier-entry';

export interface BolListVariables {
  id: number; // receivable order id
}

export interface BolListFields {
  receivableOrder?: {
    id: number;
    shippingUnitType: ShippingUnitType;
    orderMethod: OrderMethod;
    supplier?: {
      id: SupplierId;
      cartTrackingStatus: SupplierCartTrackingStatus;
      cartTrackingSupplierEntries: Array<{
        id: CartTrackingSupplierEntryId;
      }>;
    }
    bols: Array<{
      id: BolId;
      identifier: BolIdentifier;
      receivedAt: DateTimeStr;
      receivedByInitials: BolReceivedByInitials;
      autoCartTrackingEnabled: BolAutoCartTrackingEnabled;
      user: {
        id: UserId;
        firstName: UserFirstName;
        lastName: UserLastName;
      }
    }> | null;
  };
}

export type BolListResponse = BolListFields;

export const BolListQuery = gql`
  query BolListQuery($id: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $id) {
      id
      shippingUnitType
      orderMethod
      bols {
        id
        identifier
        receivedAt
        receivedByInitials
        racksReturned
        autoCartTrackingEnabled
        user {
          id
          firstName
          lastName
        }
      }
      supplier {
        id
        cartTrackingStatus
        cartTrackingSupplierEntries {
          id
        }
      }
    }
  }
`;

export interface BolListCountResponse {
  receivableOrder?: {
    id: number;
    bols: Array<{
      id: BolId;
    }>;
  };
}

export const BolListCountQuery = gql`
  query BolListCountQuery($id: Int!) {
    receivableOrder: GetUnifiedReceivableOrder(id: $id) {
      id
      bols {
        id
      }
    }
  }
`;
