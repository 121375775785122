import * as _ from 'lodash';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { AvailableFilter, AvailableSearchField, ActiveSort, ActiveSearch } from 'client/types';
import { GetRoutePlansInput, RoutePlanFilterInput, RoutePlanSortInput, RoutePlanSearchInput, RoutePlanGQLResult, RoutePlanStatus, RoutePlanFilterOption } from 'schema/route-plan/route-plan-typescript-types';
import * as UI from './ui';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { RoutePlansQueryResponse, GetRoutePlansQuery } from 'client/app/transportation/routing/route-plans-list/route-plans-query';
import { mapToFilters, mapToSort, convertToTypedSearchCriteriaForTransit } from 'client/helpers/table-helpers';
import { msyncQuery } from 'client/hoc/graphql/query';
import { TYPES, FilterSpecificationInput, assertAsInt, DateStr } from 'shared/types';
import { buildMapStateToPropsFunction } from 'client/containers/msync-redux';
import * as TableState from './table-state';
import assertCompatible from 'shared/helpers/assert-compatible';
import { withDefaultFilters } from 'client/hoc/default-filters';
import * as Constants from './constants';
import { createSelector } from 'reselect';
import { addDays } from 'shared/helpers';
import * as Query from './query';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
  invoiceId: number;
}

interface StateProps {
  activeSortFields: ActiveSort[];
  activeSearch: ActiveSearch;
  activeFilters: FilterSpecificationInput[];
  tablePageNumber: number;
  searchableFields: AvailableSearchField[];
}

const mapStateToProps = buildMapStateToPropsFunction<shame, StateProps, OwnProps>(
  TableState.genericTableActions.tableStateLens,
  (state): StateProps => {
    return {
      activeSortFields: TableState.genericTableActions.activeSortFields(state),
      activeSearch: TableState.genericTableActions.activeSearch(state),
      activeFilters: TableState.genericTableActions.activeFilters(state),
      tablePageNumber: TableState.genericTableActions.tablePageNumber(state),
      searchableFields: TableState.genericTableActions.availableSearchFields(state),
    };
  },
);

interface WithRoutePlansRowProps {
  routePlansRows: RoutePlanGQLResult[];
  loading: boolean;
  totalCount: Int;
  totalUnfilteredCount: Int;
  filteredRecordIds: number[];
  // dataRequest: MsyncDataRequest;

  availableFilters: AvailableFilter[];
}

const WithRoutePlansRows = msyncQuery<RoutePlansQueryResponse, OwnProps & StateProps & WithTableParentProps & WithDefaultFilterDataProps, WithRoutePlansRowProps, GetRoutePlansInput>(GetRoutePlansQuery, {
  alias: 'withRoutePlansRows',
  skip(ownProps) {
    return !ownProps.customer || !ownProps.tableParentInfo.rowsPerPage;
  },
  options(ownProps) {
    return {
      fetchPolicy: 'network-only',
      variables: {
        filters: mapToFilters<RoutePlanFilterInput>(ownProps.activeFilters),
        sort: mapToSort<RoutePlanSortInput>(ownProps.activeSortFields),
        search: convertToTypedSearchCriteriaForTransit<RoutePlanSearchInput>(ownProps.activeSearch, ownProps.searchableFields),
        limit: ownProps.tableParentInfo.rowsPerPage,
        offset: ownProps.tableParentInfo.rowsPerPage ? ownProps.tableParentInfo.rowsPerPage * ownProps.tablePageNumber : 0,
      },
    };
  },
  props(props): WithRoutePlansRowProps {
    const { data } = props;

    return {
      routePlansRows: data.getRoutePlans ? data.getRoutePlans.routePlans : [],
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: data.getRoutePlans ? data.getRoutePlans.ids : [],
      totalCount: assertAsInt(data.getRoutePlans ? data.getRoutePlans.totalCount : 0),
      totalUnfilteredCount: assertAsInt(data.getRoutePlans ? data.getRoutePlans.totalUnfilteredCount : 0),
      availableFilters: [
        {
          displayName: 'Customer',
          field: 'customer',
          options: data.getRoutePlansListPageFilterOptions ? data.getRoutePlansListPageFilterOptions.customers : [],
        },
        {
          displayName: 'MFC Area',
          field: 'mfcAreaIdentifier',
          options: data.getRoutePlansListPageFilterOptions ? data.getRoutePlansListPageFilterOptions.mfcAreas : [],
        },
        {
          displayName: 'Status',
          field: 'status',
          options: data.getRoutePlansListPageFilterOptions ? data.getRoutePlansListPageFilterOptions.statuses : [],
        },
        {
          displayName: 'Delivery Date',
          field: 'deliveryDate',
          type: TYPES.DATE,
        },
      ],
    };
  },
});
interface WithDefaultFilterDataProps {
  customer?: RoutePlanFilterOption;
  weekEndingDate?: DateStr;
}

const WithDefaultFilterData = msyncQuery<Query.DefaultFilterDataResponse, OwnProps, WithDefaultFilterDataProps, Query.DefaultFilterDataInput>(Query.GetDefaultFilterDataQuery, {
  alias: 'withDefaultFilterData',
  options(ownProps): { variables } {
    return {
      variables: {
        invoiceId: ownProps.invoiceId,
      },
    };
  },
  props({ data, ownProps }): WithDefaultFilterDataProps {
    const content = data.response;
    if (!content) {
      return {};
    }
    return {
      customer: {
        id: `${content.invoice.customer.id}`,
        displayValue: `${content.invoice.customer.identifier} - ${content.invoice.customer.name}`,
        value: `${content.invoice.customer.identifier}`,
      },
      weekEndingDate: content.invoice.weekEndingDate,
    };
  },
});

function getDefaultFilters(customer?: RoutePlanFilterOption, weekEndingDate?: DateStr) {
  const filters: shame[] = [];

  if (customer) {
    filters.push({
      field: 'customer',
      values: [customer.id],
    });
  }
  if (weekEndingDate) {
    const startDate = addDays(weekEndingDate, -6);
    const endDate = weekEndingDate;
    filters.push({
      field: 'deliveryDate',
      values: [{ startDate, endDate }],
      type: TYPES.DATE,
    });
  }
  filters.push({
    field: 'status',
    values: [RoutePlanStatus.Routed],
  });

  return filters;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  WithRoutePlansRowProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

assertCompatible<UI.OwnProps, ComponentProps>();

const component = flowRight(
  WithDefaultFilterData,
  withDefaultFilters<shame>(Constants.TableName,
    createSelector([
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.customer,
      (ownProps: OwnProps & WithDefaultFilterDataProps) => ownProps.weekEndingDate,
    ], getDefaultFilters),
  ),
  tableParentHoc({ isInModal: true }),
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  WithRoutePlansRows,
)(UI.AddRoutePlansTable as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
