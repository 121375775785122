import * as _ from 'lodash';
import * as ReduxForm from 'redux-form';
import { SelectableValue } from 'shared/types';
import * as SharedTypes from 'shared/types';
import * as UserParamTypes from 'client/components/report-user-params/types';
import * as State from 'client/state/state';

export const NormalizePoNumbers = (value: string) => {
  return value.trim().toUpperCase();
};

export const UpperCaseString = (value: string | null) => _.isNil(value) ? value : value.toUpperCase();

export const TrimString = (value: string) => {
  return value.trim();
};

export const getFormChanges = <T extends SimpleObject>(formValues: T, initialValues: T): Partial<T> => {
  const modified: Partial<T> = _.reduce(formValues as {}, (memo, value, key) => {
    if (initialValues && value !== initialValues[key]) {
      memo[key] = value;
    }
    return memo;
  }, {});
  return modified;
};

export const shouldClearDependentFields = (newSelection: number[], alreadySelected: number[] = []) => {
  // We want to clear depending fields when the search selection is narrowing
  // i.e. removing items from the possible pool
  // this happens the first time we add a filter, or when we remove from an existing set of filters
  const old = alreadySelected.length;
  const latest = newSelection.length;

  return (old === 0 && latest > 0) || (old > 0 && latest < old);
};

const allCheckedObj: SelectableValue = { selectAllChecked: true, values: [] };

// We don't want to save the actual IDs when persisting a "Select All" checked
// Selectable - so this function can be used to rip through everything in the
// form and clear the IDs from the Selectables that have it checked.
export const clearIdsFromSelectAlls = (formValues: SimpleObject) => {
  return Object.keys(formValues).reduce((memo, fieldName) => {
    const value = formValues[fieldName];
    if (value.hasOwnProperty('selectAllChecked') && value.selectAllChecked) {
      memo[fieldName] = allCheckedObj;
    } else {
      memo[fieldName] = value;
    }
    return memo;
  }, {});
};

export const makeMapToReportUserParams = (formName: string, reportType: SharedTypes.ReportTypes, additionalProcessingFn?: (state: State.Type) => {}): UserParamTypes.MapToReportUserParams => state => {
  const formValues = ReduxForm.getFormValues(formName)(state);

  return {
    reportType,
    params: {
      ...(additionalProcessingFn ? additionalProcessingFn(state) : {}),
      ...clearIdsFromSelectAlls(formValues),
    },
  };
};
