import gql from 'graphql-tag';

export const ImportSalesPlanMutation = gql`
  mutation ImportSalesPlanMutation($input: ImportSalesPlanInput!) {
    importSalesPlan: ImportSalesPlan(input: $input) {
      success
      errors
      salesPlan {
        id
        lastModifiedBy
        lastModifiedAt
      }
    }
  }
`;
