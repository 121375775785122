import * as React from 'react';
import * as Reselect from 'reselect';
import * as Types from '../../types';
import * as classnames from 'classnames';

interface HeaderCellProps {
  data: {
    headerData: string[];
    currentSort: Types.SortDef[] | null;
    handleClick(header: string, multiColumn?: boolean): void;
    tableName?: string;
  };
  columnIndex: number;
  style: any;
}

function getColumns(props: HeaderCellProps) {
  return props.data.headerData;
}

function getColumnIndex(props: HeaderCellProps) {
  return props.columnIndex;
}

function getCurrentSort(props: HeaderCellProps) {
  return props.data.currentSort;
}

function getHandleClick(props: HeaderCellProps) {
  return props.data.handleClick;
}

function getTableName(props: HeaderCellProps) {
  return props.data.tableName;
}

function getCellValue(props: HeaderCellProps) {
  return getColumns(props)[getColumnIndex(props)];
}

const getIsLastColumn = Reselect.createSelector([
  getColumnIndex,
  getColumns,
], (columnIndex, columns) => columns.length === columnIndex + 1);

const getIsSortedAsc = Reselect.createSelector([
  getCurrentSort,
  getCellValue,
], (currentSorts, column) => {
  const currentSort = currentSorts?.find(s => s.columnHeader === column);
  return currentSort?.order === 'asc';
});

const getIsSortedDesc = Reselect.createSelector([
  getCurrentSort,
  getCellValue,
], (currentSorts, column) => {
  const currentSort = currentSorts?.find(s => s.columnHeader === column);
  return currentSort?.order === 'desc';
});
const getClassName = Reselect.createSelector([
  getIsLastColumn,
  getIsSortedAsc,
  getIsSortedDesc,
], (isLastColumn, isSortedAsc, isSortedDesc) => {
  return classnames('readonly-table-header-cell',
    {
      'readonly-table-header-cell-not-last-column': !isLastColumn,
      'readonly-table-header-cell-sorted-asc': isSortedAsc,
      'readonly-table-header-cell-sorted-desc': isSortedDesc,
    },
  );
});

export class HeaderCell extends React.PureComponent<HeaderCellProps, {}> {
  onClick = event => {
     // Holding down shift indicates multi-column sort
    getHandleClick(this.props)(getCellValue(this.props), event.shiftKey);
  }

  render() {
    return (
      <div
        style={this.props.style}
        className={getClassName(this.props)}
        onClick={this.onClick}
        data-cell-type="header"
        data-column-index={getColumnIndex(this.props)}
        data-column-header={getCellValue(this.props)}
        data-table-name={getTableName(this.props)}
      >
        {getCellValue(this.props)}
      </div>
    );
  }
}
