import * as React from 'react';
import { Modal, Col, Row } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { noop } from 'lodash';
import SearchBox from 'client/components/toolbar/search-box';
import { Checkbox } from 'client/components/form/checkbox';
import { CheckableStoresWithMerchandisers } from 'client/state/route-plan-printing';

type ListItem = CheckableStoresWithMerchandisers & {merchandisers: Array<{id: number, name: string}>};
export interface ComponentProps {
  isShown: boolean;
  sendButtonDisabled: boolean;
  routePlanId?: RoutePlanId;
  handleCancelButtonClicked(): void;
  handleDownloadPDFButtonClicked(routePlanId: RoutePlanId, routePlanIdentifier: string): void;
  storesWithMerchandisers: ListItem[];
  handleStoreChecked(storeId: number): void;
  handleSelectAllClicked(): void;
  selectAllChecked: boolean;
  handleSearch(text: string): void;
  searchText: string;
  numSelected: number;
  routePlanIdentifier: string;
}

export class DownloadStoreAsnModalUI extends React.PureComponent<ComponentProps, {}> {
  handleDownloadPDFButtonClicked = () => {
    if (!this.props.routePlanId) {
      throw new Error('No route plan id found.');
    }
    this.props.handleDownloadPDFButtonClicked(this.props.routePlanId, this.props.routePlanIdentifier);
  }

  handleStoreChecked = (storeId: number) => {
    return () => this.props.handleStoreChecked(storeId);
  }

  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    return (
      <Modal
        id="download-store-asn-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="download-store-asn-modal-container"
        className="download-store-asn-modal">
        <GlobalAsyncModalHeader>Download Store-Specific Advance Ship Notices</GlobalAsyncModalHeader>
        <Modal.Body>
          <Col sm={12}>
            <Row className="download-store-asn-store-header">
              <div className="download-store-asn-store-description">
                Choose all or select specific store to download a store-specific ASN.
              </div>

              <Col sm={4} className="download-store-asn-store-select-all-container">
                <Checkbox
                  label="Select All"
                  input={{
                    value: this.props.selectAllChecked,
                    onChange: noop,
                  }}
                  testid="download-store-asn-store-select-all"
                  onClick={this.props.handleSelectAllClicked} />
              </Col>
              <Col sm={3} className="download-store-asn-num-selected">
                {this.props.numSelected} Selected
              </Col>
              <Col sm={5} className="download-store-asn-store-search-container pull-right">
                <SearchBox
                  activeColumns={['Search All']}
                  availableColumns={[{ id: 'Search All', name: 'Search All' } ]}
                  onColumnSelected={noop}
                  placeholder="Search"
                  activeSearchText=""
                  onSearch={this.props.handleSearch}
                / >
              </Col>
            </Row>
            <Row>
              <div className="download-store-asn-store-list-container">

                {this.props.storesWithMerchandisers
                  .map(storeWithMerchadiser =>
                    <div className="download-store-asn-store-list-item" key={`store-${storeWithMerchadiser.storeId}`}>
                      <div className="download-store-asn-store-list-item-name">
                        <Checkbox
                          label={storeWithMerchadiser.storeDisplay}
                          input={{
                            value: storeWithMerchadiser.checked,
                            onChange: noop,
                          }}
                          onClick={this.handleStoreChecked(storeWithMerchadiser.storeId)}
                          testid={`download-store-asn-store-list-item-${storeWithMerchadiser.storeId}`}
                        />
                      </div>
                      <div className="download-store-asn-store-list-item-merchandiser">
                        {storeWithMerchadiser.merchandisers.map(m => m.name).join(', ')}
                      </div>
                    </div>,
                  )}
              </div>
            </Row>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            testid="download-store-asn-modal-cancel-button"
          />
          <GlobalSaveButton
            label="Download PDF"
            testid="download-store-asn-modal-send-button"
            disabled={this.props.sendButtonDisabled}
            onClick={this.handleDownloadPDFButtonClicked}
            classNames={['mfc-button-primary']}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
