import * as _ from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { isInvalid, isPristine } from 'redux-form';
import { msyncQuery } from 'client/hoc/graphql/query';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RecordBarUI, UIProps } from './ui';
import * as Query from './query';
import * as Actions from './actions';
import { formName } from '../sidebar';
import { SupplierCommitmentConfirmedStatus, DateTimeStr } from 'shared/types';

interface ContainerProps {
  supplierCommitmentId?: number;
}

interface StateProps {
  saveButtonDisabled: boolean;
}

const mapStateToProps = (state: any): StateProps => {
  const invalid = isInvalid(formName)(state);
  const pristine = isPristine(formName)(state);

  return {
    saveButtonDisabled: pristine || invalid,
  };
};

type DispatchProps = {
  onSaveButtonClicked(): void;
  onBackButtonClicked(): void;
  onConfirmedStatusChanged(supplierCommitmentId: number, status: SupplierCommitmentConfirmedStatus): void;
};

const mapDispatchToProps: DispatchProps = {
  onBackButtonClicked: Actions.backButtonClicked,
  onSaveButtonClicked: Actions.saveButtonClicked,
  onConfirmedStatusChanged: Actions.confirmedStatusChanged,
};

interface QueryProps {
  supplierCommitmentHeader: string;
  confirmedStatus: SupplierCommitmentConfirmedStatus;
  lastModifiedAt?: DateTimeStr;
}

const withSupplierCommitmentInfo = msyncQuery<Query.QueryResponse, StateProps & ContainerProps, QueryProps, Query.Input>(Query.Query, {
  alias: 'withSupplierCommitmentInfo',
  skip(ownProps) {
    return ownProps.supplierCommitmentId === undefined;
  },
  options(ownProps): { variables: Query.Input } {
    return {
      variables: {
        supplierCommitmentId: ownProps.supplierCommitmentId as any, // TODO: Gracefully handle this. Options can be called before skip, but never actually fire the query. The types should reflect this.
      },
    };
  },
  props(props): QueryProps {
    const { data } = props;
    if (data.loading || data.supplierCommitment === undefined) {
      return {
        supplierCommitmentHeader: '',
        confirmedStatus: SupplierCommitmentConfirmedStatus.NotConfirmed,
      };
    }

    const supplierCommitment = data.supplierCommitment;
    const salesPlan = supplierCommitment.salesPlan;
    return {
      supplierCommitmentHeader: `${supplierCommitment.supplier.identifier} - ${salesPlan.customer.identifier} - ${_.startCase(salesPlan.sellDepartment.identifier)} - ${salesPlan.identifier} - ${salesPlan.year}`,
      confirmedStatus: supplierCommitment.confirmed ? SupplierCommitmentConfirmedStatus.Confirmed : SupplierCommitmentConfirmedStatus.NotConfirmed,
      lastModifiedAt: supplierCommitment.lastModifiedAt,
    };
  },
});

const component = _.flowRight(
  connect<StateProps, DispatchProps, {}>(mapStateToProps, mapDispatchToProps),
  withSupplierCommitmentInfo,
)(RecordBarUI as shame);

type CombinedProps =
  ContainerProps &
  DispatchProps &
  QueryProps &
  StateProps;

assertCompatible<UIProps, CombinedProps>();
export const RecordBarContainer = (props: ContainerProps) => propToComponent(component, props);
