import * as Validators from 'shared/validators';
import { tableName, property, definePresentation, setSchemaOptions, defineFieldValidators } from './dsl';
import { IRecord } from './record';
import { ProductIdentifier } from 'shared/schemas/product';
import { CustomerOrderProductGroupIdentifier } from 'shared/schemas/customer-order-product-group';
import { StoreIdentifier } from 'shared/schemas/store';
import { CustomerIdentifier } from 'shared/schemas/customer';
import { CustomerOrderIdentifier, CustomerOrderOrderDate } from 'shared/schemas/customer-order';

export type RoutingStopCustomerPoNumber = CustomerOrderIdentifier;
export type RoutingStopId = Flavor<number, 'routingStopRoutingStopId'>;
export type RoutingStopDate = CustomerOrderOrderDate;
export type RoutingStopProduct = ProductIdentifier;
export type RoutingStopStoreIdentifier = StoreIdentifier;
export type RoutingStopCustomerIdentifier = CustomerIdentifier;
export type RoutingStopPackQuantity = Flavor<Int, 'routingStopPackQuantity'>;
export type RoutingStopTotalRacks = Flavor<Int, 'routingStopTotalRacks'>;
export type RoutingStopRackQuantity = Flavor<Int, 'routingStopRackQuantity'>;
export type RoutingStopRackPercent = Flavor<number, 'routingStopRackPercent'>;
export type RoutingStopRouteNumber = Flavor<string, 'routingStopRouteNumber'>;
export type RoutingStopLoadNumber = Flavor<string, 'routingLoadNumber'>;
export type RoutingStopCsvName = Flavor<string, 'routingStopCsvName'>;
export type RoutingStopScanBased = Flavor<'Y' | 'N', 'routingStopScanBased'>;
export type RoutingStopReprocess = Flavor<boolean, 'routingStopReprocess'>;

/** more like: `RoutedAllocation`, meaning, the amount of a given product to drop at a given store on a given routed load. */
@tableName('routingStops')
export class RoutingStop implements IRecord {
  id?: RoutingStopId;
  @property csvName?: RoutingStopCsvName | null;
  @property customer?: RoutingStopCustomerIdentifier | null;
  @property date?: RoutingStopDate | null;
  @property store?: RoutingStopStoreIdentifier | null;
  @property customerOrderPoNumber?: RoutingStopCustomerPoNumber | null;
  @property rackType?: CustomerOrderProductGroupIdentifier | null;
  @property product?: RoutingStopProduct | null;
  @property packQuantity?: RoutingStopPackQuantity | null;
  @property totalRacks?: RoutingStopTotalRacks | null;
  @property rackQuantity?: RoutingStopRackQuantity | null;
  /** more like: `shippingUnitFraction` */
  @property rackPercent?: RoutingStopRackPercent | null;
  @property routeNumber?: RoutingStopRouteNumber | null;
  @property stopNumber?: string | null;
  @property loadNumber?: RoutingStopLoadNumber | null;
  @property scanBased?: RoutingStopScanBased | null;
  @property reprocess?: RoutingStopReprocess | null;
}

defineFieldValidators(RoutingStop, {
  csvName: [Validators.MAX_LENGTH(20)],
  customer: [Validators.MAX_LENGTH(3)],
  store: [Validators.MAX_LENGTH(4)],
  customerOrderPoNumber: [Validators.MAX_LENGTH(9)],
  rackType: [Validators.MAX_LENGTH(2)],
  product: [Validators.MAX_LENGTH(6)],
  routeNumber: [Validators.MAX_LENGTH(2)],
  stopNumber: [Validators.MAX_LENGTH(2)],
  loadNumber: [Validators.MAX_LENGTH(10)],
});

definePresentation(RoutingStop, {
});

setSchemaOptions(RoutingStop, {
  generateGraphqlSchema: false,
});
