import * as React from 'react';
import * as BS from 'client/components/third-party';
import { AlertModalSituations } from './types';
import * as _ from 'lodash';

export interface UIProps {
  visible: boolean;
  situation: string | undefined;
  alertModalSituations: AlertModalSituations;
}

export function AlertModalUI(props: UIProps) {
  const alertModalSituation = props.situation
    ? props.alertModalSituations[props.situation]
    : undefined;

  if (!props.visible || !alertModalSituation) {
    return <div />;
  }

  return (
    <div>
      <BS.Modal show={props.visible} dialogClassName="toast-modal" onHide={_.noop} className="alert-modal" backdropClassName="save-confirmation-backdrop">
        <BS.Modal.Body>
          <div className="alert alert-danger">
            <span className="pficon fa fa-exclamation-triangle text-danger" />
            <div><strong>{alertModalSituation.title}</strong></div>
            {alertModalSituation.message}
            <div className="actions pull-right">
              {alertModalSituation.buttons.map((button, index) =>
                <>
                  {index > 0 && <span> | </span>}
                  <span key={button.label}>
                    <a data-testid={`alert-modal-${_.kebabCase(button.label)}-button`} onClick={button.action}>{button.label}</a>
                  </span>
                </>,
              )}
            </div>
          </div>
        </BS.Modal.Body>
      </BS.Modal>
    </div>
  );
}
