import * as React from 'react';
import { Form, FormGroup, Col, Button } from 'client/components/third-party';
import { LabeledSelect, LabeledDate, LabeledInput } from 'client/components/form';
import { Field } from 'redux-form';
import { INPUT_TYPES, LoadOrigin } from 'shared/types';
import { REQUIRED_FIELD_VALIDATOR, makeFormValidator, MAX_LENGTH } from 'shared/validators';
import { RoutePlanStatus, RoutePlanId, RoutePlanIdentifier, RoutePlanDeliveryDate } from 'schema/route-plan/route-plan-typescript-types';
import * as classnames from 'classnames';
import { CustomerId } from 'shared/schemas/customer';
import { MfcAreaIdentifier } from 'shared/schemas/mfc-area';
import { RoutePlanLoadInformation } from 'client/app/transportation/routing/sidebar/route-plan-query';
import * as _ from 'lodash';
import { formatTemperature } from 'client/helpers/ui-helpers';
import * as FormHelpers from 'client/helpers/form-helpers';
import { SimpleMenu, SetInProgress } from 'client/app/orders/supplier-orders/overview/simple-menu';
import { DownloadStoreSummaryReportModal } from './download-store-summary-report-modal';
import { EmailMerchandisersModal } from './email-merchandisers-modal';

interface OwnProps {
  routePlanId?: RoutePlanId;
  routePlanIdentifier?: RoutePlanIdentifier;
  status?: RoutePlanStatus;
  attachOrdersButtonDisabled: boolean;
  routePlanIdFieldDisabled: boolean;
  routePlanIdFieldRequired: boolean;
  onSubmit: (formValues: shame) => Promise<shame>;
  onCustomerChange: (formName: string, value: any) => void;
  onSellDepartmentChange: (formName: string, value: any) => void;
  onAttachOrdersClicked(customerId?: CustomerId, routePlanDeliveryDate?: RoutePlanDeliveryDate, mfcAreaIdentifier?: MfcAreaIdentifier): void;
  onReadyToRouteClicked: () => void;
  onExtractOrdersClicked: () => void;
  onImportRouteFileButtonClicked: (id: RoutePlanId) => void;
  downloadRoutePlanReceivingForm: (routePlanId: RoutePlanId) => void;
  downloadDistributionRackShippingWorksheet: (routePlanId: RoutePlanId, setInProgress: (inProgress: boolean) => void) => void;
  downloadStoreSummaryReportActionClicked: (routePlanId: RoutePlanId) => void;
  emailMerchandisersActionClicked: (routePlanId?: RoutePlanId) => void;
  customerOptions?: Options;
  mfcAreaOptions?: Options;
  mfcAreaFieldDisabled: boolean;
  mfcAreaFieldRequired: boolean;
  sellDepartmentOptions?: Options;

  customerId?: CustomerId;
  mfcAreaIdentifier?: MfcAreaIdentifier;
  routePlanDeliveryDate?: RoutePlanDeliveryDate;

  extractOrdersButtonDisabled?: boolean;
  importRouteFileButtonDisabled?: boolean;
  attachOrdersButtonDeemphasized?: boolean;
  readyToRouteButtonDeemphasized?: boolean;
  readyToRouteButtonDisabled?: boolean;
  readyToRouteButtonText: string;

  loadInformation?: RoutePlanLoadInformation;
  form: string;
}

type Options = Array<{ id: any, value: any; identifier: any; }>;
const originOptions: Options = [
  {
    id: LoadOrigin.Masterpiece,
    identifier: LoadOrigin.Masterpiece,
    value: LoadOrigin.Masterpiece,
  },
  {
    id: LoadOrigin.Whitewater,
    identifier: LoadOrigin.Whitewater,
    value: LoadOrigin.Whitewater,
  },
];

const originTextFormatter = option => `${option.identifier}`;
const customerTextFormatter = option => `${option.identifier} - ${option.name}`;
const sellDepartmentTextFormatter = value => value.identifier;
const mfcAreaTextFormatter = value => value.identifier;

const emptyArray = [];

const LoadInformationData = p => (
  <div>
    <Col sm={6}>
      <div className="mfc-form-stacked-label">
        {p.title}
      </div>
    </Col>
    <Col sm={6}>
      <div data-testid={`load-information-data-${_.kebabCase(p.title)}`}>
        {p.value || '-'}
      </div>
    </Col>
  </div>
);

const maxLength20Validator = makeFormValidator(MAX_LENGTH(20));

export class RoutePlanSidebarUi extends React.PureComponent<OwnProps, {}> {
  menuItems = [
    {
      label: 'Download Receiving Form',
      action: async (setInProgress: SetInProgress) => {
        if (this.props.routePlanId) {
          setInProgress(true);
          try {
            this.props.downloadRoutePlanReceivingForm(this.props.routePlanId);
          } finally {
            setInProgress(false);
          }
        }
      },
    },
    {
      label: 'Rack Shipping Worksheet',
      action: async (setInProgress: SetInProgress) => {
        if (this.props.routePlanId) {
          this.props.downloadDistributionRackShippingWorksheet(this.props.routePlanId, setInProgress);
        }
      },
    },
    {
      label: 'Store Summary Report',
      action: async (setInProgress: SetInProgress) => {
        if (this.props.routePlanId) {
          setInProgress(true);
          try {
            this.props.downloadStoreSummaryReportActionClicked(this.props.routePlanId);
          } finally {
            setInProgress(false);
          }
        }
      },
    },
    {
      label: 'Email Merchandisers',
      action: async (setInProgress: SetInProgress) => {
        if (this.props.routePlanId) {
          setInProgress(true);
          try {
            this.props.emailMerchandisersActionClicked(this.props.routePlanId);
          } finally {
            setInProgress(false);
          }
        }
      },
    },
  ];

  onAttachOrdersButtonClicked = () => {
    return this.props.onAttachOrdersClicked(this.props.customerId, this.props.routePlanDeliveryDate, this.props.mfcAreaIdentifier);
  }

  onImportRouteFileButtonClicked = async () => {
    if (this.props.routePlanId) {
      return this.props.onImportRouteFileButtonClicked(this.props.routePlanId);
    }
  }

  onSellDepartmentChange = (value: any) => {
    this.props.onSellDepartmentChange(this.props.form, value);
  }

  onCustomerChange = (value: any) => {
    this.props.onCustomerChange(this.props.form, value);
  }

  render() {
    const attachOrderButtonClasses = classnames('mfc-button', {
      'mfc-clear-button': this.props.attachOrdersButtonDeemphasized,
      'mfc-submit-button': !this.props.attachOrdersButtonDeemphasized,
      'mfc-submit-button-primary': !this.props.attachOrdersButtonDeemphasized,
    });

    const readyToRouteButtonClasses = classnames('mfc-button', {
      'mfc-clear-button': this.props.readyToRouteButtonDeemphasized,
      'mfc-submit-button': !this.props.readyToRouteButtonDeemphasized,
      'mfc-submit-button-primary': !this.props.readyToRouteButtonDeemphasized,
    });

    return (
      <div className="mfc-form-sidebar">
        <Form onSubmit={this.props.onSubmit} horizontal>
          <FormGroup>
            <Col sm={6}>
              <div className={'mfc-form-stacked-label'}>1. Attach Orders</div>
            </Col>
          </FormGroup>
          <FormGroup>
            <Field
              component={LabeledDate}
              labelColSize={6}
              inputColSize={6}
              horizontalLabel={false}
              label="Store Delivery Date"
              name="deliveryDate"
              required
              testid="route-plan-sidebar-delivery-date"
              validate={REQUIRED_FIELD_VALIDATOR}
              autoFocus={!this.props.routePlanId}
            />
            <Col sm={6} className="route-plan-actions-menu-container">
              {this.props.routePlanId &&
                <SimpleMenu
                  label="Actions"
                  className="route-plan-actions-menu"
                  menuItems={this.menuItems}
                />
              }
            </Col>
          </FormGroup>
          <FormGroup>
            <Field
              name="origin"
              component={LabeledSelect}
              inputColSize={12}
              label="Origin"
              labelColSize={12}
              offset={0}
              testid="route-plan-sidebar-origin"
              textFormatter={originTextFormatter}
              valueField="value"
              required
              options={originOptions}
              horizontalLabel={false}
              validate={REQUIRED_FIELD_VALIDATOR}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="customerId"
              component={LabeledSelect}
              inputColSize={12}
              label="Customer"
              labelColSize={12}
              offset={0}
              testid="route-plan-sidebar-customer"
              textFormatter={customerTextFormatter}
              valueField="value"
              required
              options={this.props.customerOptions || emptyArray}
              handleChange={this.onCustomerChange}
              horizontalLabel={false}
              validate={REQUIRED_FIELD_VALIDATOR}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="sellDepartmentId"
              component={LabeledSelect}
              inputColSize={6}
              label="Sell Department"
              labelColSize={6}
              offset={0}
              textFormatter={sellDepartmentTextFormatter}
              valueField="value"
              options={this.props.sellDepartmentOptions || emptyArray}
              horizontalLabel={false}
              testid="route-plan-sidebar-sell-department"
              handleChange={this.onSellDepartmentChange}
              required
              validate={REQUIRED_FIELD_VALIDATOR}
            />
            <Field
              name="mfcAreaId"
              component={LabeledSelect}
              inputColSize={6}
              label="MFC Area"
              labelColSize={6}
              offset={0}
              textFormatter={mfcAreaTextFormatter}
              valueField="value"
              options={this.props.mfcAreaOptions || emptyArray}
              horizontalLabel={false}
              testid="route-plan-sidebar-mfc-area"
              disabled={this.props.mfcAreaFieldDisabled}
              required={this.props.mfcAreaFieldRequired}
            // This field is required, but it's best to check it on the server
            // Redux form doesn't handle adding / removing validations very well
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="identifier"
              component={LabeledInput}
              labelColSize={12}
              inputColSize={12}
              horizontalLabel={false}
              label="Route Plan ID"
              testid="route-plan-sidebar-route-plan-id"
              type={INPUT_TYPES.TEXT}
              disabled={this.props.routePlanIdFieldDisabled}
              required={this.props.routePlanIdFieldRequired}
              validate={maxLength20Validator}
              normalize={FormHelpers.TrimString}
            />
          </FormGroup>
          <div className="sidebar-action-button-container">
            <Button
              data-testid="route-plan-sidebar-attach-orders"
              bsClass={attachOrderButtonClasses}
              onClick={this.onAttachOrdersButtonClicked}
              disabled={this.props.attachOrdersButtonDisabled}>
              Attach Orders
            </Button>
          </div>
          <hr className="sidebar-horizontal-rule" />
          <FormGroup>
            <Col sm={12}>
              <div className={'mfc-form-stacked-label'}>2. Review Stores</div>
            </Col>
          </FormGroup>
          <div className="sidebar-action-button-container">
            <Button
              data-testid="route-plan-sidebar-ready-to-route"
              bsClass={readyToRouteButtonClasses}
              onClick={this.props.onReadyToRouteClicked}
              disabled={this.props.readyToRouteButtonDisabled}>
              {this.props.readyToRouteButtonText}
            </Button>
          </div>
          <hr className="sidebar-horizontal-rule" />
          <FormGroup>
            <Col sm={12}>
              <div className={'mfc-form-stacked-label'}>3. Create Loads</div>
            </Col>
          </FormGroup>
          {this.props.loadInformation && this.props.loadInformation.loadType &&
            <FormGroup>
              <LoadInformationData title="Load Type" value={this.props.loadInformation.loadType} />
              <LoadInformationData title="Dropoff Location" value={this.props.loadInformation.dropoffLocation} />
              <LoadInformationData title="Route Type" value={this.props.loadInformation.routeType} />
              <LoadInformationData title="Origin" value={this.props.loadInformation.origin} />
              <LoadInformationData title="Destination" value={this.props.loadInformation.destination} />
              <LoadInformationData title="Carrier" value={this.props.loadInformation.carrier} />
              <LoadInformationData title="Trailer Temperature" value={formatTemperature(this.props.loadInformation.trailerTemperature)} />
            </FormGroup>
          }
          <div className="sidebar-action-button-container">
            <Button
              data-testid="route-plan-sidebar-extract-orders"
              bsClass={classnames('mfc-button mfc-submit-button mfc-button', {
                'mfc-submit-button-primary': !this.props.extractOrdersButtonDisabled,
                'mfc-clear-button': this.props.extractOrdersButtonDisabled,
              })}
              onClick={this.props.onExtractOrdersClicked}
              disabled={this.props.extractOrdersButtonDisabled}>
              Extract Orders
            </Button>
            <Button
              data-testid="route-plan-sidebar-import-route-file"
              bsClass={classnames('mfc-button mfc-submit-button mfc-button', {
                'mfc-submit-button-primary': !this.props.importRouteFileButtonDisabled,
                'mfc-clear-button': this.props.importRouteFileButtonDisabled,
              })}
              onClick={this.onImportRouteFileButtonClicked}
              disabled={this.props.importRouteFileButtonDisabled}>
              Import Route File
            </Button>
          </div>
          <hr className="sidebar-horizontal-rule" />
        </Form>
        <DownloadStoreSummaryReportModal routePlanId={this.props.routePlanId}/>
        <EmailMerchandisersModal routePlanId={this.props.routePlanId}/>
      </div>
    );
  }

}
