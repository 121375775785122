import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import * as Constants from './constants';
import { Thunker } from 'client/types/redux-types';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { GenerateCutProcessingReportInput } from 'schema/customer-order-report/types';
import { generateCutProcessingReportMutation } from './mutation';
import { ReportTypes } from 'shared/types';
import * as ReportActions from '../shared/actions';
import * as CommonSchemaTypes from 'shared/types/graphql-types';

export const handleDownloadExcelReportClicked = (): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();

    const values = getFormValues(Constants.formName)(state) as Constants.FormValues;

    if (_.isNil(values.date) || _.isNil(values.customerId)) {
      throw new Error('Invalid data provided');
    }
    dispatch(ReportActions.reportDownloadStarted());
    try {
      const response = await msyncClientMutation<CommonSchemaTypes.GenerateReportMutationResponse, GenerateCutProcessingReportInput>({
        mutation: generateCutProcessingReportMutation,
        variables: {
          cutProcessingReportInput: {
            reportType: ReportTypes.CutProcessing,
            date: values.date,
            customerId: values.customerId,
          },
        },
        dispatch,
      });

      if (!response) {
        dispatch(ReportActions.reportDownloadFinished());
        throw new Error('Unable to generate report');
      }

      const reportId = response.data.response.id;

      window.location.replace(`/report/fileDownload/${reportId}`);
    } finally {
      dispatch(ReportActions.reportDownloadFinished());
    }
  };
};
