import { change } from 'redux-form';
import { Thunker } from 'client/types/redux-types';
import { ImmutableDateRange } from 'shared/types';

export const handleDateRangeChanged = (formName: string, formFields: { beginDate: string, endDate: string, supplierOrderIds?: string }) => (dateRange: ImmutableDateRange): Thunker => {
  return async dispatch => {

    dispatch(change(formName, formFields.beginDate, dateRange.startDate));
    dispatch(change(formName, formFields.endDate, dateRange.endDate));
  };
};
