import * as Actions from 'client/app/orders/customer-orders/overview/actions';
import * as R from 'ramda';
import * as State from 'client/state/customer-order-header';

type ActionType = Actions.ActionTypes;

export default function reducer(state = State.InitialState, action: ActionType): State.Type {
  switch (action.type) {
    case Actions.ActionTypeKeys.OpenModalButtonClicked: {
      return R.pipe(
        State.alertModalVisible.set(true),
        State.alertModalSituation.set(action.payload.situation),
      )(state);
    }

    case Actions.ActionTypeKeys.AlertModalCancelButtonClicked: {
      return R.pipe(
        State.alertModalVisible.set(false),
      )(state);
    }

    default:
      return state;
  }
}
