import {
  RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY,
  RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS,
  RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS,
  RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
  RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED,
} from 'client/constants';
import { MutationStatus } from 'client/actions/mutations';
import { ReceivingReceiptRecipient } from 'client/app/transportation/receiving/details/related-bols/send-receiving-receipt-modal-container';

export function setSendReceivingReceiptModalVisibility(showModal: boolean) {
  return {
    type: RELATED_BOLS_SET_RECEIVING_RECEIPT_MODAL_VISIBILITY,
    payload: showModal,
  };
}

export function setSupplierOrderContacts(contacts: ReceivingReceiptRecipient[]) {
  return {
    type: RELATED_BOLS_SET_RECEIVING_RECEIPT_CONTACTS,
    payload: contacts,
  };
}

export function setSendReceivingReceiptActionStatus(actionStatus: MutationStatus) {
  return {
    type: RELATED_BOLS_SET_SEND_RECEIVING_RECEIPT_ACTION_STATUS,
    payload: actionStatus,
  };
}

export function receivingReceiptEmailRecipientCheckboxClicked(supplierContactId: number) {
  return {
    type: RELATED_BOLS_EMAIL_RECIPIENT_CHECKBOX_CLICKED,
    payload: supplierContactId,
  };
}

export function setReceivingReceiptNotesText(notes: string) {
  return {
    type: RELATED_BOLS_RECEIVING_RECEIPT_NOTES_CHANGED,
    payload: notes,
  };
}
