import { Row } from './row';
import { SelectableRow, SelectableColumn } from './types';
import * as classnames from 'classnames';
import * as React from 'react';

export interface RowsProps {
  cols: SelectableColumn[];
  highlightIndex?: number;
  onChecked?: (row: SelectableRow) => void;
  onMouseOver?: (row: SelectableRow) => void;
  onUnchecked?: (row: SelectableRow) => void;
  rows: SelectableRow[];
  rowsRef?: (element: HTMLDivElement) => void;
  checked: boolean;
  width?: number;
}

export const Rows = (props: RowsProps) => {
  return (
    <div className={classnames('selectable-rows', {
        'selectable-rows-checked': props.checked,
        'selectable-rows-unchecked': !props.checked,
      })}
      {...(props.width ? { style: { width: `${props.width}px` } } : {})}
      {...(props.rowsRef ? { ref: props.rowsRef } : {})}
    >
      {!props.checked && props.rows.length === 0 &&
        <div className="no-results">No Results</div>
      }
      {props.rows
        .map((row, rowIndex) => (
          <Row
            cols={props.cols}
            highlighted={rowIndex === props.highlightIndex}
            key={`${row.id}-${props.checked}`}
            onUnchecked={props.onUnchecked}
            onMouseOver={props.onMouseOver}
            onChecked={props.onChecked}
            row={row}
            checked={props.checked}
            rowIndex={rowIndex}
          />
        ))}
    </div>
  );
};
