import gql from 'graphql-tag';

export const generateStoreReplenishmentReportByCategory = gql`
mutation GenerateReplenishmentByStoreReportByCategory($reportByCategoryInput: GenerateStoreReportByCategoryInput) {
  response: GenerateStoreReplenishmentReportByCategory(reportByCategoryInput: $reportByCategoryInput) {
    id
  }
}
`;

export const generateStoreReplenishmentReportBySalesPlan = gql`
mutation GenerateReplenishmentByStoreReportBySalesPlan($reportBySalesPlanInput: GenerateStoreReportBySalesPlanInput) {
  response: GenerateStoreReplenishmentReportBySalesPlan(reportBySalesPlanInput: $reportBySalesPlanInput) {
    id
  }
}
`;
