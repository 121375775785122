import { connect } from 'react-redux';
import * as Actions from 'client/actions/load-list';
import { propToComponent } from 'client/hoc/hoc';
import { NotifyPeakModalUI, ComponentProps as UIProps } from './notify-peak-modal-ui';
import { flowRight } from 'lodash';
import * as State from 'client/state/state';
import * as LoadListSelectors from 'client/state/load-list-selectors';
import assertCompatible from 'shared/helpers/assert-compatible';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';
import { WithNotifyPeakModalRowsQueryProps, NotifyPeakModalRowsResponse, NotifyPeakInput, NotifyPeakQuery } from 'client/app/transportation/loads/notify-peak-modal/notify-peak-modal-query';
import { CheckedLoadNumbers } from 'client/state/load-list';
import { msyncQuery } from 'client/hoc/graphql/query';
import { FetchPolicy } from 'apollo-client';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  isShown: boolean;
  routingLoadIds: RoutingLoadId[];
  checkedLoadNumbers: CheckedLoadNumbers;
}

const mapStateToProps = (state: State.Type, ownProps: OwnProps): StateProps => {

  return {
    isShown: LoadListSelectors.isNotifyPeakModalShown(state),
    routingLoadIds: LoadListSelectors.selectedRoutingLoadIds(state),
    checkedLoadNumbers: LoadListSelectors.checkedLoadNumbers(state),
  };
};

interface DispatchProps {
  handleSendButtonClicked(routingLoadIds: RoutingLoadId[], changedLoadNumbers: RoutingLoadId[]): void;
  handleCancelButtonClicked(): void;
  handleChangesCheckboxClicked(routingLoadId: RoutingLoadId, checked: boolean): void;
}

const mapDispatchToProps: DispatchProps = {
  handleSendButtonClicked: Actions.handleNotifyPeakSendButtonClicked,
  handleCancelButtonClicked: Actions.hideNotifyPeakModal,
  handleChangesCheckboxClicked: Actions.handleChangesCheckboxClicked,
};

const WithNotifyPeakModalRows = msyncQuery<NotifyPeakModalRowsResponse, OwnProps & StateProps & DispatchProps, WithNotifyPeakModalRowsQueryProps, NotifyPeakInput>(NotifyPeakQuery, {
  alias: 'withNotifyPeak',
  skip: ownProps => {
    return ownProps.routingLoadIds.length === 0;
  },
  options(ownProps): { variables: NotifyPeakInput, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        filters: {
          id: { values: ownProps.routingLoadIds },
        },
        sort: [
          {
            sortField: 'identifier',
            sortOrder: 'ASC',
          },
        ],
      },
      fetchPolicy: 'network-only',
    };
  },
  props(props): WithNotifyPeakModalRowsQueryProps {
    const { data } = props;

    const routingLoads
     = data.getRoutingLoads
      ? data.getRoutingLoads.routingLoads.map(gqlResult => {
        return {
          id: gqlResult.id,
          identifier: gqlResult.identifier,
          sentToPeak: gqlResult.sentToPeak,
        };
      })
      : [];

    return {
      routingLoads,
      loading: data.loading,
      sendButtonDisabled: !!data.loading || routingLoads.filter(routingLoad => !routingLoad.sentToPeak).length === 0,
      notifyPeakInfoMessageEnabled: routingLoads.filter(routingLoad => routingLoad.sentToPeak).length > 0,
    };
  },
});

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithNotifyPeakModalRowsQueryProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  WithNotifyPeakModalRows,
)(NotifyPeakModalUI as shame) as shame as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
