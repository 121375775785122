import * as React from 'react';
import * as classNames from 'classnames';
import EditableCell from './editable-cell';
import { formatReal } from 'shared/types';

interface ReadOnlyNumberCellTypes {
  value: number | null;
  testid: string;
  centered?: boolean;
  dashValues?: Array<number | null | undefined>;
  decimals?: Int | null;
}

const ReadOnlyNumberCell = ({ value, testid, centered, dashValues, decimals }: ReadOnlyNumberCellTypes) => (
  <div>
    <div className={classNames('number', { 'pull-right': !centered, 'text-center': centered })} data-testid={testid}>
      {dashValues?.includes(value) || value === null ? '-' : formatReal(value, undefined, decimals ?? null)}
    </div>
  </div>
);

interface NumberCellTypes extends ReadOnlyNumberCellTypes {
  editing: boolean;
  onChange: (newValue: any) => void;
  saveOnChange?: boolean;
  onArrowKeyUp?: () => void;
  onArrowKeyDown?: () => void;
  error?: boolean;
}

const NumberCell = ({ value, editing, onChange, testid, centered, dashValues, saveOnChange, onArrowKeyDown, onArrowKeyUp, decimals }: NumberCellTypes) => editing
  ? <EditableCell inputType={'number'} onChange={onChange} testid={testid} initialValue={value} saveOnChange={saveOnChange} onArrowKeyUp={onArrowKeyUp} onArrowKeyDown={onArrowKeyDown} />
  : <ReadOnlyNumberCell value={value} testid={testid} centered={centered} dashValues={dashValues} decimals={decimals} />;

export default NumberCell;
