/* tslint:disable no-empty */

/**
 * No runtime effect.
 * At compile time, the TypeScript type checker will require that the second type argument extends the first.
 */
export default function assertCompatible<Target, From extends Target>(): void {
  const a: any = 1;
  const b: From = 2 as any;
  if (a === b) {
    console.info('Talk to Andy about this');
  }
} // tslint:disable-line
