import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import session from './session';
import tables from './table';
import record from './record';
import errors from './error';
import productWorksheet from './product-worksheet';
import supplierOrder from './supplier-order';
import receivingWorksheet from './receiving-worksheet';
import relatedBols from './related-bols';
import cartTracking from './cart-tracking';
import routePlan from './route-plan';
import importRoute from './import-route';
import routePlanPrinting from './route-plan-printing';
import mutations from './mutations';
import loadList from './load-list';
import navigation from './navigation';
import importCustomerOrderSpreadsheet from './import-customer-order-spreadsheet';
// Don't like this, Andy is looking into better import practices
import routePlanPrintingWarning from 'client/app/transportation/routing/route-plan-details/route-plan-printing-warning/reducer';
import distributionReceiving from 'client/app/transportation/distribution-receiving/list/reducer';
import importDistributionRackShippingWorksheet from 'client/app/transportation/routing/route-plan-details/review-stores/import-distribution-rack-shipping-worksheet-modal/reducer';
import orderReports from 'client/reducers/reports';
import reportUserParams from 'client/components/report-user-params/reducers';
import transportationReports from 'client/app/transportation/reports/reducers';
import { reduceEmailMerchandisersModalState } from 'client/app/transportation/routing/sidebar/email-merchandisers-redux-stuff';
import invoice from './invoice';
import salesPlan from './sales-plan';
import supplierCommitment from './supplier-commitment';
import product from 'client/containers/product/reducer';
import customerOrderHeader from './customer-order-header';
import { connectRouter } from 'connected-react-router';

export function buildRootReducer(history) {
  return combineReducers(Object.assign({
    router: connectRouter(history),
    session,
    tables,
    record,
    form: formReducer,
    productWorksheet,
    supplierOrder,
    receivingWorksheet,
    relatedBols,
    cartTracking,
    routePlan,
    importRoute,
    routePlanPrinting,
    errors,
    mutations,
    loadList,
    importCustomerOrderSpreadsheet,
    importDistributionRackShippingWorksheet,
    routePlanPrintingWarning,
    navigation,
    distributionReceiving,
    orderReports,
    reportUserParams,
    transportationReports,
    emailMerchandisersModalState: reduceEmailMerchandisersModalState,
    invoice,
    salesPlan,
    supplierCommitment,
    product,
    customerOrderHeader,
  }));
}
