import * as Validators from 'shared/validators';
import * as ClientConstants from 'client/constants';
import * as ClientTypes from 'client/types';
import * as Constants from './constants';
import * as InvoiceTableTypes from './types';
import * as React from 'react';
import * as SharedTypes from 'shared/types';
import * as TableColumn from 'client/components/table/column';
import * as TableFilterContainer from 'client/containers/table/table-filter-container';
import * as TableParent from 'client/components/table/table-parent';
import { InvoiceType } from 'shared/types';
import { createSelector } from 'reselect';
import AddRoutePlansModal from '../add-route-plans-modal';
import { ConfirmOkToSaveFunction } from '../shared/save-confirmation';
import AddVendorChargebackLineItemModal from '../add-vendor-chargeback-line-item-modal/add-vendor-chargeback-line-item-modal-container';
import { defaultUnreachable } from 'shared/helpers/andys-little-helpers';

export type OnSaveFunction = (type: InvoiceType, lineItemId: number, input: shame, confirmOkToSave: ConfirmOkToSaveFunction) => void;
export type OnDeleteFunction = (type: InvoiceType | undefined, confirmOkToSave: ConfirmOkToSaveFunction) => (ids: number[]) => void;
export type AddLineItemButtonClickHandler = (confirmOkToSave: ConfirmOkToSaveFunction, invoiceId?: number) => () => void;

export interface OwnProps extends InvoiceTableTypes.WithInvoiceLineItemProps {
  invoiceId?: number;
  tableParentInfo: TableParent.TableParentInfo;
  isAddRoutePlansModalShown: boolean;
  isAddVendorChargebackLineItemModalShown: boolean;
  addLineItemRefetchQueries: string[];
  onSave: OnSaveFunction;
  onDelete: OnDeleteFunction;
  onAddVendorChargebackLineItemsButtonClicked(): void;
  onAddRoutePlansButtonClicked(): void;
  onAddLineItemButtonClicked: AddLineItemButtonClickHandler;
  confirmOkToSave: ConfirmOkToSaveFunction;
}

const dropShipColumns = (type: InvoiceType, onSave: OnSaveFunction, confirmOkToSave: ConfirmOkToSaveFunction): TableColumn.IColumn[] => [
  {
    id: 'deliveryDate',
    accessor: 'deliveryDate',
    header: 'Delivery Date',
    tableEditable: true,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DATE,
    type: SharedTypes.TYPES.DATE,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { deliveryDate: val }, confirmOkToSave);
    },
  },
  {
    id: 'storesDelivered',
    accessor: 'storesDelivered',
    header: 'Stores Delivered',
    tableEditable: true,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.DASH_ZERO_NUMBER,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { storesDelivered: val }, confirmOkToSave);
    },
  },
  {
    id: 'notes',
    accessor: 'notes',
    header: 'POs Delivered / Notes',
    tableEditable: true,
    columnWidth: 60,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { notes: val }, confirmOkToSave);
    },
  },
  {
    id: 'deliveryRate',
    accessor: 'deliveryRate',
    header: 'Delivery Rate',
    tableEditable: true,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { deliveryRate: val }, confirmOkToSave);
    },
  },
  {
    id: 'totalPrice',
    accessor: 'totalPrice',
    header: 'Total Price',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
  },
];

interface CustomerOrderLineItem {
  description: string;
  productIdentifier: string | null;
  sku: string | null;
  packSize: number;
  packQuantity: number;
  totalPieces: number;
  piecePrice: number;
  totalPrice: number;
  editable: boolean;
}
const tableEditable = (row: CustomerOrderLineItem | undefined) => {
  return row ? row.editable : false;
};

const customerOrderColumns = (type: InvoiceType, onSave: OnSaveFunction, confirmOkToSave: ConfirmOkToSaveFunction): TableColumn.IColumn[] => [
  {
    id: 'productIdentifier',
    accessor: 'productIdentifier',
    header: 'Product ID',
    tableEditable,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { productIdentifier: val }, confirmOkToSave);
    },
  },
  {
    id: 'description',
    accessor: 'description',
    header: 'Product Description',
    tableEditable,
    columnWidth: 30,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { description: val }, confirmOkToSave);
    },
  },
  {
    id: 'sku',
    accessor: 'sku',
    header: 'SKU',
    tableEditable,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { sku: val }, confirmOkToSave);
    },
  },
  {
    id: 'generalLedgerAccountIdentifier',
    accessor: 'generalLedgerAccountIdentifier',
    header: 'GLA',
    tableEditable: true,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    validators: [
      Validators.MATCHES_OR_IS_NULL(/^\d{4}-\d{4}-\d{2}$/),
    ],
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { generalLedgerAccountIdentifier: val }, confirmOkToSave);
    },
  },
  {
    id: 'packSize',
    accessor: 'packSize',
    header: 'Pack',
    tableEditable,
    columnWidth: 5,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { packSize: val }, confirmOkToSave);
    },
  },
  {
    id: 'packQuantity',
    accessor: 'packQuantity',
    header: 'Case Qty',
    tableEditable,
    columnWidth: 5,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { packQuantity: val }, confirmOkToSave);
    },
  },
  {
    id: 'totalPieces',
    accessor: 'totalPieces',
    header: 'Total Units',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
  },
  {
    id: 'piecePrice',
    accessor: 'piecePrice',
    header: 'Unit Price',
    tableEditable,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { piecePrice: val }, confirmOkToSave);
    },
  },
  {
    id: 'totalPrice',
    accessor: 'totalPrice',
    header: 'Total Price',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
  },
];

const vendorChargebackColumns = (type: InvoiceType, onSave: OnSaveFunction, confirmOkToSave: ConfirmOkToSaveFunction): TableColumn.IColumn[] => [
  {
    id: 'productIdentifier',
    accessor: 'productIdentifier',
    header: 'Product ID',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { productIdentifier: val }, confirmOkToSave);
    },
  },
  {
    id: 'productDescription',
    accessor: 'productDescription',
    header: 'Product Description',
    tableEditable: true,
    columnWidth: 30,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { productDescription: val }, confirmOkToSave);
    },
  },
  {
    id: 'sku',
    accessor: 'sku',
    header: 'SKU',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.TEXT,
    type: SharedTypes.TYPES.STRING,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { sku: val }, confirmOkToSave);
    },
  },
  {
    id: 'packSize',
    accessor: 'packSize',
    header: 'Pack',
    tableEditable: true,
    columnWidth: 5,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { packSize: val }, confirmOkToSave);
    },
  },
  {
    id: 'packOrderQuantity',
    accessor: 'packOrderQuantity',
    header: 'Case Qty',
    tableEditable: true,
    columnWidth: 5,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { packOrderQuantity: val }, confirmOkToSave);
    },
  },
  {
    id: 'totalPieces',
    accessor: 'totalPieces',
    header: 'Total Units',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.NUMBER,
    type: SharedTypes.TYPES.NUMBER,
  },
  {
    id: 'cost',
    accessor: 'cost',
    header: 'Unit Price',
    tableEditable: true,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
    onSave: (lineItemId: number, val) => {
      onSave(type, lineItemId, { cost: val }, confirmOkToSave);
    },
  },
  {
    id: 'totalCost',
    accessor: 'totalCost',
    header: 'Total Price',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: SharedTypes.CELL_TYPES.MONEY,
    type: SharedTypes.TYPES.NUMBER,
  },
];

const getType = (props: OwnProps) => props.type;
const getOnSaveFunction = (props: OwnProps) => props.onSave;
const getConfirmOkToSaveFunction = (props: OwnProps) => props.confirmOkToSave;
const getOnDeleteFunction = (props: OwnProps) => props.onDelete;
const getInvoiceId = (props: OwnProps) => props.invoiceId;
const getInvoiceType = (props: OwnProps) => props.type;
const getOnAddVendorChargebackLineItemsButtonClicked = (props: OwnProps) => props.onAddVendorChargebackLineItemsButtonClicked;
const getOnAddRoutePlansButtonClicked = (props: OwnProps) => props.onAddRoutePlansButtonClicked;
const getOnAddLineItemButtonClicked = (props: OwnProps) => props.onAddLineItemButtonClicked;

const getColumns = createSelector([
  getType,
  getOnSaveFunction,
  getConfirmOkToSaveFunction,
], (
  type: InvoiceType,
  onSave: OnSaveFunction,
  confirmOkToSave: ConfirmOkToSaveFunction,
  ) => {
  switch (type) {
    case InvoiceType.CustomerOrder:
      return customerOrderColumns(type, onSave, confirmOkToSave);
    case InvoiceType.DropShip:
      return dropShipColumns(type, onSave, confirmOkToSave);
    case InvoiceType.VendorChargeback:
      return vendorChargebackColumns(type, onSave, confirmOkToSave);
    default:
      return defaultUnreachable(type, []);
  }
});

const getMenuItems = createSelector([
  getType,
  getOnDeleteFunction,
  getConfirmOkToSaveFunction,
], (
  type: InvoiceType | undefined,
  onDelete: OnDeleteFunction,
  confirmOkToSave: ConfirmOkToSaveFunction,
  ) => {
    return [{ label: 'Delete', onClick: onDelete(type, confirmOkToSave), willRemove: true }];
  });

const addLineButton = (clickHandler: ClientTypes.ButtonClickHandler) => {
  return {
    label: 'Add Line',
    onClick: clickHandler,

  };
};

const makeDropShipButtons = (args: { addRoutePlansClickHandler: ClientTypes.ButtonClickHandler, addLineButtonClickHandler: ClientTypes.ButtonClickHandler }): Array<{ label: string, onClick: ClientTypes.ButtonClickHandler }> => {
  return [
    {
      label: 'Add Route Plans',
      onClick: args.addRoutePlansClickHandler,
    },
    addLineButton(args.addLineButtonClickHandler),
  ];
};

const makeVendorChargebackButtons = (args: { addVendorChargebackLineItemsClickHandler: ClientTypes.ButtonClickHandler }): Array<{ label: string, onClick: ClientTypes.ButtonClickHandler }> => {
  return [
    { label: 'Add Products', onClick: args.addVendorChargebackLineItemsClickHandler },
  ];
};

const makeStandardButtons = (args: { addLineButtonClickHandler: ClientTypes.ButtonClickHandler }): Array<{ label: string, onClick: ClientTypes.ButtonClickHandler }> => {
  return [
    addLineButton(args.addLineButtonClickHandler),
  ];
};
const tableButtons = createSelector([
  getInvoiceId,
  getInvoiceType,
  getOnAddVendorChargebackLineItemsButtonClicked,
  getOnAddRoutePlansButtonClicked,
  getOnAddLineItemButtonClicked,
  getConfirmOkToSaveFunction,
], (
  invoiceId,
  type,
  onAddVendorChargebackLineItemsButtonClicked,
  onAddRoutePlansButtonClicked,
  onAddLineItemButtonClicked,
  confirmOkToSave: ConfirmOkToSaveFunction,
  ) => {
    const addLineButtonClickHandler = onAddLineItemButtonClicked(confirmOkToSave, invoiceId);
    switch (type) {
      case InvoiceType.DropShip:
        return makeDropShipButtons({ addRoutePlansClickHandler: onAddRoutePlansButtonClicked, addLineButtonClickHandler });
      case InvoiceType.VendorChargeback:
        return makeVendorChargebackButtons({ addVendorChargebackLineItemsClickHandler: onAddVendorChargebackLineItemsButtonClicked });
      case InvoiceType.CustomerOrder:
        return makeStandardButtons({ addLineButtonClickHandler });
      default:
        return []; // Don't use assertUnreachable here so that, while the query is still in flight, it won't throw an error
    }
  });

export class InvoiceTableUI extends React.Component<OwnProps, any> {
  private readonly FilterableTable: React.StatelessComponent<TableFilterContainer.OwnProps>;

  constructor(props: OwnProps) {
    super(props);
    this.FilterableTable = TableFilterContainer.buildFilterableTable(Constants.tableName);
  }

  public render() {
    return (
      <div>
        {this.props.isAddRoutePlansModalShown && this.props.invoiceId &&
          <AddRoutePlansModal
            invoiceId={this.props.invoiceId}
            refetchQueries={this.props.addLineItemRefetchQueries} />}

        {this.props.isAddVendorChargebackLineItemModalShown && this.props.invoiceId &&
          <AddVendorChargebackLineItemModal
            invoiceId={this.props.invoiceId}
          />}

        <this.FilterableTable
          availableFilters={this.props.availableFilters}
          buttons={tableButtons(this.props)}
          columns={getColumns(this.props)}
          content={this.props.lineItems || ClientConstants.EMPTY_ARRAY}
          dataRequest={this.props.dataRequest}
          // onNewClicked={this.props.onAddLineItemButtonClicked}

          displayLoadingIndicator
          filteredRecordIds={this.props.filteredRecordIds}
          checkable
          headerMenuItems={getMenuItems(this.props)}
          loading={this.props.loading}
          placeholder="There are no line items to display."
          rowMenuItems={getMenuItems(this.props)}
          searchableFields={ClientConstants.EMPTY_ARRAY}
          table={Constants.tableName}
          tablePaginated={false} // DK 2018-02-04 - we are doing client-side sorting for now. Eventually we may want to do server side sorting/filtering/searching, and in that case we would want to paginate this table
          tableParentInfo={this.props.tableParentInfo}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
        />
      </div>
    );
  }
}
