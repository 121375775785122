import { flowRight } from 'lodash';
import { propToComponent } from 'client/hoc/hoc';
import { connect } from 'react-redux';
import { InvoiceDetailFormUI } from './ui';

const component = flowRight(
  connect(),
)(InvoiceDetailFormUI);

export const InvoiceDetailContainer = (props: {}) => propToComponent(component, props);
