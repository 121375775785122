import { ExpectedError } from 'shared/errors/expected-error';
import { FULL_CASE_IDENTIFIER, FULL_RACK_IDENTIFIER, OrderMethod, ShippingUnitOrderMethod, PackOrderMethod } from 'shared/types';
import { Product } from 'shared/schemas/product';

export function getIdentifierFromOrderMethod(orderMethod: OrderMethod) {

  // Identifier is determined by how product is being ordered
  if (orderMethod === ShippingUnitOrderMethod) {
    return FULL_RACK_IDENTIFIER;
  } else if (orderMethod === PackOrderMethod) {
    return FULL_CASE_IDENTIFIER;
  } else {
    throw new ExpectedError(`Unexpected order method: ${orderMethod}`);
  }
}

export function getRegularProductGroupDescription(product: Pick<Product, 'identifier' | 'description'>) {
  return `${product.identifier} - ${product.description}`;
}
