import * as React from 'react';
import ReceivingOverviewSidebar from 'client/app/transportation/receiving/details/sidebar/sidebar-container';
import ReceivingOverviewContent from 'client/app/transportation/receiving/details/overview/content/content-container';
import { RouteChildrenProps } from 'react-router';

export const ReceivingOverview: React.SFC<RouteChildrenProps<{ id: string }>> = props => {
  const receivableOrderId = props.match ? Number.parseInt(props.match.params.id) : 0;
  return (
    <div>
      <ReceivingOverviewContent receivableOrderId={receivableOrderId} />
      <ReceivingOverviewSidebar receivableOrderId={receivableOrderId} />
    </div>
  );
};
