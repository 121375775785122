import * as React from 'react';
import { AsyncButtonUI as AsyncButton } from 'client/components/async-button/async-button-ui';

export enum WarningType {
  None,
  ReRoutingNeeded,
  OrdersUpdated,
}

interface UIProps {
  routePlanId: number;
  showSpinner: boolean;
  onStartOverClicked(routePlanId: number): Promise<void>;
  warningType: WarningType;
}

interface WarningContentProps {
  header: string;
  description: string;
}

const WarningContent = (props: WarningContentProps) => {
  return (
    <div>
      <div className="route-plan-printing-warning-header">
        {props.header}
      </div>
      <div className="route-plan-printing-warning-text">
        {props.description}
      </div>
    </div>
  );
};

const classNames = ['route-plan-printing-warning-button'];

type DisplayableWarningType = WarningType.ReRoutingNeeded | WarningType.OrdersUpdated;

const getHeader = (warningType: DisplayableWarningType): string => {
  switch (warningType) {
    case WarningType.ReRoutingNeeded:
      return 'Re-routing needed';
    case WarningType.OrdersUpdated:
      return 'Orders updated';
  }
};

const getDescription = (warningType: DisplayableWarningType): string => {
  switch (warningType) {
    case WarningType.ReRoutingNeeded:
      return 'Changes were made to the customer orders that require re-extracting and re-routing. Actions below will have outdated data until that is done.';
    case WarningType.OrdersUpdated:
      return 'Changes were made to the customer orders since printing the route packet. The route packet may be outdated.';
  }
};

export class RoutePlanPrintingWarningUI extends React.PureComponent<UIProps, {}> {
  handleStartOverClicked = () => {
    return this.props.onStartOverClicked(this.props.routePlanId);
  }

  render() {
    if (this.props.warningType === WarningType.None) {
      return (
        <div />
      );
    }
    return (
      <div className="route-plan-printing-warning-box">
        <WarningContent
          header={getHeader(this.props.warningType)}
          description={getDescription(this.props.warningType)}
        />
        <AsyncButton
          label="Clear Routes and Start Over"
          testid="route-plan-printing-warning-button"
          classNames={classNames}
          showSpinner={this.props.showSpinner}
          showCheck={false}
          onClick={this.handleStartOverClicked}/>
      </div>
    );
  }
}
