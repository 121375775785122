import * as React from 'react';
import { FormGroup } from 'client/components/third-party';
import { FieldWrapper } from '../../form';

/* eslint-disable react/no-multi-comp */
const Field = p => <FieldWrapper table="supplierContacts" alwaysShowErrors={true} hideOptionalLabel={true} { ...p }/>;
/*
SupplierContactFormGroup.propTypes = {
  fields: React.PropTypes.object,
  formName: React.PropTypes.string,
  handlePrimaryClick: React.PropTypes.func,
  handleAddContactClick: React.PropTypes.func,
  handleDeleteContactClick: React.PropTypes.func,
  displayContacts: React.PropTypes.arrayOf(React.PropTypes.object),
};
*/
class SupplierContactFormGroup extends React.Component<shame> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      handlePrimaryClick,
      handleAddContactClick,
      handleDeleteContactClick,
      displayContacts,
    } = this.props;

    return (
      <div data-testid="supplier-contacts">
        {fields.map((contact, index) => {
          const onHandlePrimaryClick = () => {
            handlePrimaryClick(fields, index, `${contact}.primary`);
          };

          const displayContact = displayContacts[index];
          let headingText = 'New Contact';
          if (displayContact && displayContact.name) {
            headingText = displayContact.name;
          }

          const email = displayContact ? displayContact.email : null;

          return (
            <div key={index} data-testid="supplier-contact">
              <div className="mfc-form-heading">
                {headingText}
                <div className="pull-right">
                  {(email && <span className="mfc-simple-icon-button"><a href={`mailto:${email}`}><span className="fa fa-envelope" /></a></span>)}
                  <span className="mfc-simple-icon-button" data-testid={`contact-${index}-delete-button`} onClick={handleDeleteContactClick(fields, index, displayContact.primary)}><span className="fa fa-trash" /></span>
                </div>
              </div>

              <FormGroup data-testid={`contact-${index}-row-5`}>
                <Field name={`${contact}.primary`} labelColSize={2} inputColSize={3} offset={2} optionValue handleChange={onHandlePrimaryClick} testid="primary" />
                <Field name={`${contact}.receivesReceipts`} labelColSize={2} inputColSize={3} testid="receivesReceipts"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-1`}>
                <Field name={`${contact}.name`} labelColSize={2} inputColSize={9} testid="name"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-2`}>
                <Field name={`${contact}.role`} labelColSize={2} inputColSize={9} testid="role"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-3`}>
                <Field name={`${contact}.email`} labelColSize={2} inputColSize={9} testid="email"/>
              </FormGroup>
              <FormGroup data-testid={`contact-${index}-row-4`}>
                <Field name={`${contact}.phoneOffice`} labelColSize={2} inputColSize={4} testid="phoneOffice"/>
                <Field name={`${contact}.phoneCell`} labelColSize={1} inputColSize={4} testid="phoneCell"/>
              </FormGroup>
            </div>
          );
        })}

        <div className="text-center">
          <div className="mfc-form-button add-contact-button" data-testid="qa-new-supplier-contact-button" onClick={handleAddContactClick(fields)}>
            <span className="fa fa-plus-circle" /> Add Another Contact
          </div>
        </div>
      </div>
    );
  }
}
export default SupplierContactFormGroup;
