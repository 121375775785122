import 'reflect-metadata';
import { tableName, property, belongsTo, definePresentation, hasMany, setSchemaOptions, required, defineFieldValidators } from './dsl';
import { displayType } from './dsl';
import { DISPLAY_TYPES, TYPES, INPUT_TYPES } from 'shared/types';
import * as Validators from 'shared/validators';
import { Customer, CustomerId } from 'shared/schemas/customer';
import { ProductSubClass } from 'shared/schemas/product-sub-class';
import { SellDepartment } from 'shared/schemas/sell-department';
import { ProductClass } from 'shared/schemas/product-class';
import { ComboProduct } from 'shared/schemas/combo-product';
import { IRecord } from 'shared/schemas/record';
import { CustomerDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

export type ComboProductGroupId = number;

@tableName('comboProductGroups')
export class ComboProductGroup implements IRecord {
  id?: ComboProductGroupId;

  @property @required identifier: string;
  @property @required description: string;

  @belongsTo('customers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property @required customer: Customer;
  customerId: CustomerId;

  @belongsTo('sellDepartments', { through: 'productClasses', foreignDisplayKey: 'identifier' })
  @property @required sellDepartment: SellDepartment;

  @belongsTo('productClasses', { through: 'productSubClasses', foreignDisplayKey: 'identifier' })
  @property @required productClass: Partial<ProductClass>;

  @belongsTo('productSubClasses')
  @property @required productSubClass: ProductSubClass;
  productSubClassId: number;

  @hasMany('comboProducts')
  @property comboProducts: ComboProduct[];

  @property @required packsPerShippingUnit: number;
}

defineFieldValidators(ComboProductGroup, {
  packsPerShippingUnit: [Validators.GREATER_THAN_ZERO],
});

definePresentation(ComboProductGroup, {
  identifier: {
    searchable: true,
    sortable: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    displayName: 'Product ID',
    tableDisplay: true,
    columnWidth: 10,
  },
  description: {
    tableDisplay: true,
    searchable: true,
    sortable: true,
    columnWidth: 29,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  customer: {
    filterable: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: CustomerDisplayValueResolver }),
    tableDisplay: true,
    columnWidth: 20,
  },
  sellDepartment: {
    filterable: true,
    sortable: true,
    columnWidth: 12,
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  productClass: {
    sortable: true,
    filterable: true,
    displayName: 'Class',
    tableDisplay: true,
    tableDisplayColumns: { identifier: 'Class' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    columnWidth: 12,
  },
  productSubClass: {
    filterable: true,
    sortable: true,
    displayName: 'Sub Class',
    tableDisplay: true,
    tableDisplayColumns: { identifier: 'Sub Class' },
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
    columnWidth: 12,
  },
  packsPerShippingUnit: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT, { inputType: INPUT_TYPES.NUMBER }),
    type: TYPES.NUMBER,
  },
  comboProducts: {
    formDisplayType: displayType(DISPLAY_TYPES.FIELD_ARRAY),
  },
});

setSchemaOptions(ComboProductGroup, {
});
