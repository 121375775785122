import gqlTag from 'graphql-tag';
import { singularize } from 'inflection';
import { kebabCase, startCase } from 'lodash';
import * as React from 'react';
import { buildFragment, formQueryColumns, recordType as getRecordType } from '../../../shared/schemas';
import { RecordBar } from '../../components/record-bar';
import { RecordNav } from '../../components/record-nav';
import { formContainer, DuplicateOptions } from '../../hoc/form-container';

export interface CreateAdminPageOptions {
  table: string;
  title?: string;
  RecordBarDetailComponent?: any;
  duplicateOptions?: DuplicateOptions;
  OptionalStatusToggle?: any;
  FormComponent: any;
  mutations?: any;
  onSubmit?: () => void;
  formName?: string;
  navBuilder?: any;
  initialValues?: any;
  destroyOnUnmount?: boolean;
  determineNextIdentifier?: boolean;
  fieldsToClearOnDuplication?: string[]; // DVM 2024 03 29 - unused? but provided in some cases....
}

export interface Props {
  handleSubmit: () => Promise<void>;
  onChangeRecordStatus: (name: string, field: string, value: any) => void;
  submitting: boolean;
  invalid: boolean;
  pristine: boolean;
  submitted: boolean;
  initialValues: any;
  loading: boolean;
  record: any;
  onClose: () => void;
  resetForm: (formName: string) => void;
}

const buildAdminCreatePage = ({
  table,
  RecordBarDetailComponent,
  duplicateOptions,
  OptionalStatusToggle,
  title,
  FormComponent,
  mutations,
  onSubmit,
  formName,
  navBuilder,
  initialValues: argsInitialValues,
  destroyOnUnmount,
  determineNextIdentifier,
}: CreateAdminPageOptions) => {
  const recordType = getRecordType(table);
  const adminCreateFormName = formName || `${recordType}Form`;

  const AdminCreatePage = ({
    handleSubmit,
    onChangeRecordStatus,
    resetForm,
    submitting,
    invalid,
    pristine,
    submitted,
    record,
    initialValues,
    onClose,
  }: Props) => {
    const description = `New ${singularize(startCase(title || recordType))}`;
    return (
      <div className="record" id={`${kebabCase(singularize(table))}-create-page`}>
        <RecordBar
          submitting={submitting}
          submitted={submitted}
          invalid={invalid}
          pristine={pristine}
          onClose={onClose}
          record={record}
          handleSubmit={handleSubmit}
          onChangeRecordStatus={(field: string, val: any) => onChangeRecordStatus(adminCreateFormName, field, val)}
          resetForm={() => resetForm(adminCreateFormName)}
          initialRecord={initialValues}
          table={table}
          title={title}
          description={description}
          RecordBarDetailComponent={RecordBarDetailComponent}
          OptionalStatusToggle={OptionalStatusToggle} />
        <div className="mfc-form">
          {navBuilder && <RecordNav RecordNavItems={navBuilder(undefined, record)} disabled />}

          <FormComponent
            handleSubmit={handleSubmit}
            submitting={submitting}
            invalid={invalid}
            pristine={pristine}
            formName={adminCreateFormName}
            record={initialValues}
          />
        </div>
      </div>
    );
  };

  const fragmentName = `Create${recordType}Fragment`;
  const fragment = gqlTag`${buildFragment(table, formQueryColumns(table), fragmentName)}`;

  const DEFAULT_DETAIL_QUERY = gqlTag`
    query Find${recordType}ForCreate($type: RecordType!, $id: Int!) {
      data: find(type: $type, id: $id) {
        id,
        ...${fragmentName}
      }
      ${determineNextIdentifier ? 'nextIdentifier: getNextIdentifier(type: $type)' : ''}
    }
    ${fragment}
  `;

  const DEFAULT_MUTATION = gqlTag`
    mutation ($input: Create${recordType}Input!) {
      data: create${recordType}(input: $input) {
        id,
        ...${fragmentName}
      }
    }
    ${fragment}
  `;

  const DEFAULT_ON_SUBMIT = ({ create }: { create: (rec: any) => void }) => async (rec: any) => create(rec);

  return formContainer({
    formName: adminCreateFormName,
    mutationStrings: mutations || { create: DEFAULT_MUTATION },
    isNewRecord: true,
    onSubmit: onSubmit || DEFAULT_ON_SUBMIT,
    queryString: DEFAULT_DETAIL_QUERY,
    initialValues: argsInitialValues,
    table,
    destroyOnUnmount,
    duplicateOptions,
  } as any)(AdminCreatePage as shame<'wip'>);
};

export default buildAdminCreatePage;
