import * as React from 'react';
import * as classnames from 'classnames';
import { StackedLayout } from 'client/components/form/layouts';
import Dropdown from 'client/components/dropdown';

export interface SimpleMenuItem {
  label: string;
  action: (setInProgress: SetInProgress) => void;
}

interface Props {
  label: string | any;
  menuItems: SimpleMenuItem[];
  disabled?: boolean;
  className?: string;
  right?: boolean;
}

interface State {
  inProgress: boolean;
}

export type SetInProgress = (inProgress: boolean) => void;

export class SimpleMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inProgress: false,
    };
  }

  setInProgress(inProgress: boolean) {
    this.setState({ inProgress });
  }

  render() {
    const label = this.state.inProgress
      ? <span className="spinner-container"><i className="fa fa-spinner fa-spin" /></span>
      : this.props.label;

    const className = classnames('input-group-btn mfc-button-table-dropdown mfc-form-action-menu simple-menu', this.props.className);

    return <StackedLayout inputColSize={6} offset={0} classNames={[]} blankLabel isMfcInput={false} right={this.props.right}>
      <Dropdown
        className={className}
        data={this.props.menuItems.map(menuItem => menuItem.label)}
        placeholder={label}
        value={[]}
        onChange={(_: any, index: number) => {
          this.props.menuItems[index].action(inProgress => this.setInProgress(inProgress));
        }}
        testid="record-actions"
        disabled={this.state.inProgress}
        right={this.props.right}
      />
    </StackedLayout>;
  }
}
