import * as State from 'client/state/state';
import { RoutingLoadId } from 'schema/routing-load/routing-load-graphql-types';

export const isApplyInvoiceToLoadsModalShown = (state: State.Type) =>
  State.loadListApplyInvoiceToLoadsModalShown.get(state);

export const selectedRoutingLoadIds = (state: State.Type) =>
  State.loadListSelectedRoutingLoadIds.get(state);

export const selectedCarrier = (state: State.Type) =>
  State.loadListSelectedCarrier.get(state);

export const enteredInvoiceNumber = (state: State.Type) =>
  State.loadListEnteredInvoiceNumber.get(state);

export const isAssignLoadsToTrailersModalShown = (state: State.Type) =>
  State.loadListAssignLoadsToTrailersModalShown.get(state);

export const getTrailerNumber = (state: State.Type) => {
  return (routingLoadId: RoutingLoadId): string | undefined => {
    const t = State.loadListTrailerNumbers.get(state);
    return t[routingLoadId];
  };
};

export const trailerNumbers = (state: State.Type) => State.loadListTrailerNumbers.get(state);

export const isNotifyPeakModalShown = (state: State.Type) =>
  State.loadListNotifyPeakModalShown.get(state);

export const checkedLoadNumbers = (state: State.Type) =>
  State.checkedLoadNumbers.get(state);
