import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as R from 'ramda';
import { flowRight } from 'lodash';
import * as Actions from 'client/actions/cart-tracking';
import { CartTrackingSupplierTable as CartTrackingSupplierTableUI, CartTrackingSupplierTableRow } from './cart-tracking-supplier-table';
import { propToComponent } from 'client/hoc/hoc';
import { msyncQuery } from 'client/hoc/graphql/query';
import { withFilterAndSortCapabilities } from 'client/containers/table/table-filter-container';
import { tableParentHoc, TableParentInfo } from 'client/components/table/table-parent';
import { CartTrackingSupplierEntryQuery, CartTrackingSupplierEntryResponse, cartTrackingSupplierEntryRecordsSelector, SupplierSubsetWithCartTrackingResponse } from './cart-tracking-supplier-query';
import { SupplierId, SupplierCartTrackingStatus } from 'shared/schemas/supplier';
import { ActiveSort } from 'client/types';
import { CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME } from 'client/constants';
import { CartTrackingSupplierEntryType } from 'shared/schemas/cart-tracking-supplier-entry';
import { FetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';

interface OwnProps {
  supplierId: SupplierId;
}

interface StateProps {
  activeSortFields: ActiveSort[];
}
const TableStateHelpers = buildTableStateModule(CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME);

const mapStateToProps = (state): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

interface DispatchProps {
  newTransactionButtonClicked: () => void;
}
const mapDispatchToProps = {
  newTransactionButtonClicked: () => Actions.setAddNewTransactionModalVisibility(true),
};

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithCartTrackingSupplierProps {
  tableRows?: CartTrackingSupplierTableRow[];
  loading: boolean;
  totalCount: number;
  totalUnfilteredCount: number;
  filteredRecordIds: number[];
  cartTrackingStatus: SupplierCartTrackingStatus;
}

interface RowQuantities {
  delivered?: number;
  returned?: number;
  balance?: number;
}

const getRowEntryQuantities = (quantity: number, entryType: CartTrackingSupplierEntryType): RowQuantities => {
  switch (entryType) {
    case CartTrackingSupplierEntryType.Adjustment:
      if (quantity < 0) {
        return {
          delivered: Math.abs(quantity),
          returned: 0,
        };
      } else {
        return {
          delivered: 0,
          returned: quantity,
        };
      }
    case CartTrackingSupplierEntryType.Balance:
      return {
        delivered: 0,
        returned: 0,
      };
  }
};

const buildTableRow = (ownProps: OwnProps & StateProps & WithTableParentProps) => (record: CartTrackingSupplierEntryResponse): CartTrackingSupplierTableRow => {
  const quantities = getRowEntryQuantities(record.quantity, record.entryType);

  return {
    id: record.id,
    entryDate: record.entryDate,
    entryType: record.entryType,
    notes: record.notes,
    delivered: quantities.delivered,
    returned: quantities.returned,
    balance: record.balance,
  };
};

const buildTableRows = (data: SupplierSubsetWithCartTrackingResponse, ownProps: OwnProps & StateProps & WithTableParentProps): CartTrackingSupplierTableRow[] =>
  R.map(buildTableRow(ownProps), cartTrackingSupplierEntryRecordsSelector(data));

const WithCartTrackingSupplierEntryRows = msyncQuery<SupplierSubsetWithCartTrackingResponse, OwnProps & StateProps & WithTableParentProps, WithCartTrackingSupplierProps>(CartTrackingSupplierEntryQuery, {
  alias: 'withCartTrackingSupplierEntryRows',
  options(ownProps): { variables, fetchPolicy: FetchPolicy } {
    return {
      variables: {
        id: ownProps.supplierId,
      },
      fetchPolicy: 'network-only', // DK 2018-04-06
    };
  },
  props(props): WithCartTrackingSupplierProps {
    const { data, ownProps } = props;

    return {
      tableRows: buildTableRows(data, ownProps),
      cartTrackingStatus: (data.response && data.response.cartTrackingStatus) ? data.response.cartTrackingStatus : SupplierCartTrackingStatus.DoNotTrack,
      loading: _.isNil(data.loading) ? true : data.loading,
      filteredRecordIds: [],
      totalCount: (data.response ? data.response.cartTrackingSupplierEntries || [] : []).length,
      totalUnfilteredCount: (data.response ? data.response.cartTrackingSupplierEntries || [] : []).length,
    };
  },
});

interface WithFilterAndSortCapabilitiesProps {
  onTableSort: (field: string) => void;
}

interface WithTableParentProps {
  tableParentInfo: TableParentInfo;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  DispatchProps &
  WithCartTrackingSupplierProps &
  WithFilterAndSortCapabilitiesProps &
  WithTableParentProps;

type Component<P> = new (props: P) => React.Component<P, any>;

const component = flowRight(
  tableParentHoc(),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  withFilterAndSortCapabilities(CUSTOMER_ORDER_RECONCILIATION_TABLE_NAME),
  WithCartTrackingSupplierEntryRows,
)(CartTrackingSupplierTableUI) as Component<OwnProps>;

export const CartTrackingSupplierTable = (props: OwnProps) => propToComponent(component, props);

export default CartTrackingSupplierTable;
