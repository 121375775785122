import * as React from 'react';
import { connect } from 'react-redux';
import { flowRight } from 'lodash';
import * as Actions from 'client/actions/cart-tracking';
import { propToComponent } from 'client/hoc/hoc';
import { RoutePlanDetailsRecordBar } from './route-plan-details-record-bar';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { RoutePlanSidebar } from 'client/app/transportation/routing/sidebar/route-plan-sidebar-container';
import { Col } from 'client/components/third-party';
import { RoutePlanDetailsNavigationBar } from 'client/app/transportation/routing/navigation-bar/route-plan-navigation-bar';
import ImportRouteModal from 'client/app/transportation/routing/import-route-modal/import-route-modal-container';

interface OwnProps { routePlanId?: RoutePlanId; sectionName: string; children?: React.ReactNode | React.ReactNode[]; }
interface DispatchProps { setAddNewTransactionModalVisibility: (showModal: boolean) => void; }
const mapDispatchToProps: DispatchProps = { setAddNewTransactionModalVisibility: Actions.setAddNewTransactionModalVisibility };
const RoutePlanDetail = (p: OwnProps & DispatchProps) => (
  <div className="record">
    <ImportRouteModal routePlanId={p.routePlanId} />
    <RoutePlanDetailsRecordBar routePlanId={p.routePlanId} sectionName={p.sectionName} />
    <div className="mfc-form mfc-form-not-scrollable">
      <RoutePlanDetailsNavigationBar routePlanId={p.routePlanId} />
      <div>
        <div className="mfc-form-details-with-sidebar">
          <Col sm={12}>
            {p.children}
          </Col>
        </div>
        <div className="mfc-form-sidebar">
          <RoutePlanSidebar routePlanId={p.routePlanId} sectionName={p.sectionName} />
        </div>
      </div>
    </div>
  </div>
);

const component = flowRight( connect<undefined, DispatchProps, OwnProps>(undefined, mapDispatchToProps), )(RoutePlanDetail);
export default (props: OwnProps) => propToComponent(component, props);
