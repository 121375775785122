import * as _ from 'lodash';
import { getFormValues } from 'redux-form';

import * as State from 'client/state/state';
import * as ReportState from 'client/state/reports';
import * as Constants from './constants';
import { MutationStatus } from 'client/actions/mutations';
import * as ReportSelectors from 'client/state/reports';
import { extractSelectedValues } from 'client/components/selectable/selectable';

export const getFilterByCategoryOrSalesPlan = (state: State.Type): Constants.FilterByCategoryOrSalesPlan =>
  (getFormValues(Constants.formName)(state) as Constants.FormValues).filterByCategoryOrSalesPlan;

export const getScanProductOptions = (state: State.Type): boolean[] => {
  return ReportSelectors.getScanProductOptions(state, Constants.formName);
};

export const shouldDownloadBeDisabled = (state: State.Type) => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  if (!formValues) {
    return true;
  }

  const filterByCategoryOrSalesPlan = getFilterByCategoryOrSalesPlan(state);

  const scanProductOptions = getScanProductOptions(state);

  if (filterByCategoryOrSalesPlan === Constants.FilterByCategoryOrSalesPlan.ByCategory) {
    const products = extractSelectedValues(formValues[Constants.FormFields.productIds]);
    const mfcAreas = extractSelectedValues(formValues[Constants.FormFields.mfcAreaIds]);

    return _.isNil(formValues[Constants.FormFields.customerId]) ||
      _.isNil(formValues[Constants.FormFields.beginDate]) ||
      _.isNil(formValues[Constants.FormFields.endDate]) ||
      products.length === 0 ||
      mfcAreas.length === 0 ||
      _.isNil(formValues[Constants.FormFields.sellDepartmentId]) ||
      _.isNil(formValues[Constants.FormFields.primaryGlobalAll]) ||
      _.isNil(formValues[Constants.FormFields.groupBy]) ||
      scanProductOptions.length === 0;
  } else {
    const mfcAreas = extractSelectedValues(formValues[Constants.FormFields.mfcAreaIds]);
    return _.isNil(formValues[Constants.FormFields.customerId]) ||
      _.isNil(formValues[Constants.FormFields.beginDate]) ||
      _.isNil(formValues[Constants.FormFields.endDate]) ||
      _.isNil(formValues[Constants.FormFields.salesPlanId]) ||
      _.isNil(formValues[Constants.FormFields.primaryGlobalAll]) ||
      _.isNil(formValues[Constants.FormFields.groupBy]) ||
      mfcAreas.length === 0;
  }
};

export const getReportDownloadStatus = (state: State.Type): MutationStatus => {
  return State.reportState.comp(ReportState.reportDownloadStatus).get(state);
};
