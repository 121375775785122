import * as React from 'react';
import { FormGroup, Form, Col } from 'client/components/third-party';
import { SpecializeField } from '../form';
import { CarrierRate } from 'shared/schemas/carrier-rate';

const Field = SpecializeField(CarrierRate, { alwaysShowErrors: true, hideOptionalLabel: true });

export interface OwnProps {
  handleSubmit: any;
  record?: {
    id?: number | undefined;
  };
}

export default class CarrierRateForm extends React.Component<OwnProps, {}> {
  render() {
    return (
      <Col sm={12}>
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="simple-form">
            <FormGroup>
              <Field
                name="carrier"
                labelColSize={2}
                inputColSize={5}
                autoFocus={!this.props.record || !this.props.record.id}
              />
            </FormGroup>
            <FormGroup>
              <Field name="loadType" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="firstDropRate" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="restDropRate" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="tailgateFee" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="nycCharge" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="eastPremium" labelColSize={2} inputColSize={5} />
            </FormGroup>
            <FormGroup>
              <Field name="hmgTrailer" labelColSize={2} inputColSize={5} />
            </FormGroup>
          </div>
        </Form>
      </Col>
    );
  }
}
