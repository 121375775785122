import { Arrays } from '@atomic-object/lenses';

/**
 * Builds a helper function to be used for managing multi-column sorting. Example:
 *
 * buildSortTogglingFunction({fieldProp: 'sortField', orderProp: 'sortOrder', asc: SORT_TYPES.ASC, desc: SORT_TYPES.DESC });
 */
export const buildSortTogglingFunction = <T>(args: { fieldProp: keyof T, orderProp: keyof T, asc: T[keyof T], desc: T[keyof T] }) => {
  return (sortOption: T, multiColumn: boolean, currentSorts: T[]) => {
    const toggledSort = (i: number) => currentSorts[i][args.orderProp] === args.asc ? args.desc : args.asc;

    const index = currentSorts.findIndex(s => s[args.fieldProp] === sortOption[args.fieldProp]);
    const isColumnAlreadyBeingSorted = index > -1;

    // We're going to be mutating the sort option so make a copy of it
    const newSortOption = { ...sortOption };

    if (multiColumn) {
      if (isColumnAlreadyBeingSorted) {
        // Multi-click column that's already being sorted - toggle the sort order for the column
        newSortOption[args.orderProp] = toggledSort(index);
        return Arrays.index<T>(index).set(currentSorts, newSortOption);
      } else {
        // Multi-click a column that's not already being sorted - add it to the list of sorts
        return Arrays.push(currentSorts, newSortOption);
      }
    } else {
      if (isColumnAlreadyBeingSorted && currentSorts.length === 1) {
        // Non-multi click the only column being sorted - toggle the sort order
        newSortOption[args.orderProp] = toggledSort(index);
        return Arrays.index<T>(index).set(currentSorts, newSortOption);
      } else {
        // Clear other columns and just sort on this one
        return [newSortOption];
      }
    }
  };
};
