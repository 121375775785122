import { buildAdminListPage } from '../admin';
import { Supplier } from 'shared/schemas/supplier';

export default buildAdminListPage(Supplier, {
  columns: ['identifier', 'name', 'phone', 'address1', 'city', 'state', 'zip', 'activeStatus', 'primaryContact'],
  excelColumns: [
    'identifier',
    'name',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'country',
    'phone',
    'fax',
    'website',
    'activeStatus',
    'primaryContact',
    'growerId',
    'totalCarts',
    'cartTrackingStatus',
    'lastAdjustmentDate',
  ],
});
