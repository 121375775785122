import * as TablesState from 'client/state/tables';
import * as Constants from './constants';
import * as SharedTypes from 'shared/types';

export const genericTableActions = TablesState.buildTableStateModule(Constants.tableName,
  {
    sort: [{
      sortOrder: SharedTypes.SORT_TYPES.ASC,
      sortField: 'productId',
    }],
    availableSearchFields: [],
  },
);
