import * as React from 'react';
import { IColumn } from 'client/components/table/column';
import { CELL_TYPES, TYPES, DateStr } from 'shared/types';
import { buildFilterableTable, OwnProps as FilterableTableProps } from 'client/containers/table/table-filter-container';
import { TableParentInfo } from 'client/components/table/table-parent';
import { MsyncDataRequest } from 'client/hoc/graphql/query';
import { EMPTY_ARRAY, BILLING_INVOICES_TABLE_NAME } from 'client/constants';
import { AvailableFilter, AvailableSearchField } from 'client/types';
import PageTitle from 'client/components/page-title';
import { RowMenuItem } from 'client/components/table/row-menu/menu';
import { SendInvoicesToAcumaticaModal } from '../send-invoices-to-acumatica-modal';
import { SendInvoicesViaEdiModal } from '../send-invoices-via-edi-modal';

export interface InvoicesListTableRow {
  id: number;
  type: string;
  identifier: string;
  invoiceDate: DateStr;
  invoiceTo: string;
  customerIdentifier: string;
  ediInvoiced: boolean;
  transferredToAccounting: boolean | null;
  poNumber?: string | null;
  deliveryDate: DateStr | null;
  storeIdentifier?: string | null;
  totalPrice: number;
  paidDate: DateStr | null | string
}

export interface UIProps {
  rows?: InvoicesListTableRow[];
  loading: boolean;
  totalCount: number;
  filteredRecordIds: number[];
  totalUnfilteredCount: number;
  tableParentInfo: TableParentInfo;
  onNewClicked: () => any;
  dataRequest: MsyncDataRequest;
  onRowSelected(row: { id: number }): any;
  availableFilters: AvailableFilter[];
  searchableFields: AvailableSearchField[];
  onSendInvoicesToAcumaticaMenuItemClicked: (invoiceIds: number[]) => any;
  isSendInvoicesToAcumaticaModalShown: boolean;
  selectedInvoiceIds: number[];

  onSendInvoicesViaEdiMenuItemClicked: (invoiceIds: number[]) => any;
  isSendInvoicesViaEdiModalShown: boolean;
}

const columns: IColumn[] = [
  {
    id: 'invoiceDate',
    accessor: 'invoiceDate',
    header: 'Invoice Date',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
  {
    id: 'identifier',
    accessor: 'identifier',
    header: 'Invoice #',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'customerIdentifier',
    accessor: 'customerIdentifier',
    header: 'Customer ID',
    tableEditable: false,
    columnWidth: 6,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'invoiceTo',
    accessor: 'invoiceTo',
    header: 'Invoice To',
    tableEditable: false,
    columnWidth: 20,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'storeIdentifier',
    accessor: 'storeIdentifier',
    header: 'Store',
    tableEditable: false,
    columnWidth: 6,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'poNumber',
    accessor: 'poNumber',
    header: 'PO #',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'deliveryDate',
    accessor: 'deliveryDate',
    header: 'Delivery Date',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },
  {
    id: 'type',
    accessor: 'type',
    header: 'Invoice Type',
    tableEditable: false,
    columnWidth: 8,
    sortable: true,
    cellType: CELL_TYPES.TEXT,
    type: TYPES.STRING,
  },
  {
    id: 'totalPrice',
    accessor: 'totalPrice',
    header: 'Amount',
    tableEditable: false,
    columnWidth: 10,
    sortable: true,
    cellType: CELL_TYPES.MONEY,
    type: TYPES.FLOAT,
  },
  {
    id: 'ediInvoiced',
    accessor: 'ediInvoiced',
    header: 'EDI',
    tableEditable: false,
    columnWidth: 6,
    sortable: true,
    cellType: CELL_TYPES.YES_NO_NULL,
    type: TYPES.BOOLEAN,
  },
  {
    id: 'transferredToAccounting',
    accessor: 'transferredToAccounting',
    header: 'Acumatica',
    tableEditable: false,
    columnWidth: 6,
    sortable: true,
    cellType: CELL_TYPES.YES_NO_NULL,
    type: TYPES.BOOLEAN,
  },
  {
    id: 'paidDate',
    accessor:'paidDate',
    header: 'Paid',
    tableEditable: false,
    columnWidth: 6,
    sortable: true,
    cellType: CELL_TYPES.DATE,
    type: TYPES.DATE,
  },

];

export class InvoicesTableUI extends React.PureComponent<UIProps, {}> {
  private readonly FilterableTable: React.StatelessComponent<FilterableTableProps>;
  private tableName = BILLING_INVOICES_TABLE_NAME;

  private headerMenuItems: RowMenuItem[] = [
    {
      label: 'Send Invoices to Acumatica',
      // Note: adding the handler like this means that it will always be the initial value of of `onSendInvoicesToAcumaticaMenuItemClicked`,
      // so changing the behavior of this function somewhere won't actually have an affect.
      onClick: this.props.onSendInvoicesToAcumaticaMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
    {
      label: 'Send Invoices via EDI',
      onClick: this.props.onSendInvoicesViaEdiMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
  ];

  private rowMenuItems: RowMenuItem[] = [
    {
      label: 'Send Invoices to Acumatica',
      // Note: adding the handler like this means that it will always be the initial value of of `onSendInvoicesToAcumaticaMenuItemClicked`,
      // so changing the behavior of this function somewhere won't actually have an affect.
      onClick: this.props.onSendInvoicesToAcumaticaMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
    {
      label: 'Send Invoices via EDI',
      onClick: this.props.onSendInvoicesViaEdiMenuItemClicked,
      uncheckRecordFollowingClick: false,
      willRemove: false,
    },
  ];

  constructor(props: any) {
    super(props);
    this.FilterableTable = buildFilterableTable(this.tableName);
  }

  // eslint-disable-next-line no-empty
  noop = () => { };

  render() {
    return (
      <div>
        {this.props.isSendInvoicesToAcumaticaModalShown &&
          <SendInvoicesToAcumaticaModal selectedInvoiceIds={this.props.selectedInvoiceIds}/>
        }
        {this.props.isSendInvoicesViaEdiModalShown &&
          <SendInvoicesViaEdiModal selectedInvoiceIds={this.props.selectedInvoiceIds}/>
        }
        <PageTitle title="Invoices"/>
        <this.FilterableTable
          table={this.tableName}
          content={this.props.rows || EMPTY_ARRAY}
          loading={this.props.loading}
          columns={columns}
          excelColumns={columns}
          totalCount={this.props.totalCount}
          totalUnfilteredCount={this.props.totalUnfilteredCount}
          filteredRecordIds={this.props.filteredRecordIds}
          refetchTable={this.noop}
          loadMoreRecords={this.noop}
          searchableFields={this.props.searchableFields}
          availableFilters={this.props.availableFilters}
          placeholder="No Invoices"
          checkable
          list
          tablePaginated
          tableParentInfo={this.props.tableParentInfo}
          displayLoadingIndicator
          onRowSelect={this.props.onRowSelected}
          dataRequest={this.props.dataRequest}
          rowMenuItems={this.rowMenuItems}
          headerMenuItems={this.headerMenuItems}
          newButtonLabel="New Invoice"
          onNewClicked={this.props.onNewClicked}
        />
      </div>
    );
  }
}
