import { LabeledSelect } from 'client/components/form';
import { GetSalesPlansByProductIdResponse } from 'schema/sales-plan/types';
import * as BS from 'client/components/third-party';
import * as GlobalAsyncModalHeaderContainer from 'client/components/async-button/global-async-modal-header-container';
import * as GlobalCancelButtonContainer from 'client/components/async-button/global-cancel-button-container';
import * as React from 'react';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';

export interface ComponentProps {
  downloadButtonClicked: (args: { productId: number, salesPlanId: number | undefined }) => void;
  handleCancelButtonClicked: () => void;
  handleCloseButtonClicked: () => void;
  productId: number;
  salesPlanOptions?: GetSalesPlansByProductIdResponse;
  salesPlanOptionsLoading?: boolean;
}

const optionTextFormatter = option => {
  return `${option.year} - ${option.identifier}`;
};

const INVALID_SALES_PLAN_ID = -1;

export const DownloadSpecSheetModalUI = (props: ComponentProps) => {
  const [salesPlanId, setSalesPlanId] = React.useState(INVALID_SALES_PLAN_ID);

  const { productId, downloadButtonClicked: downloadButtonClickedFromProps } = props;
  const downloadButtonClicked = React.useCallback(() => {
    downloadButtonClickedFromProps({ productId, salesPlanId });
  }, [downloadButtonClickedFromProps, productId, salesPlanId]);

  return (
    <BS.Modal
      animation
      backdrop="static"
      className="download-spec-sheet-modal"
      dialogClassName="download-spec-sheet-modal-container"
      id="download-spec-sheet-modal"
      onHide={props.handleCloseButtonClicked}
      show={true}
    >
      <GlobalAsyncModalHeaderContainer.GlobalAsyncModalHeader>
        Download Product Spec Sheet
      </GlobalAsyncModalHeaderContainer.GlobalAsyncModalHeader>
      <BS.Modal.Body>
        <BS.Col sm={12}>
          <BS.Row>
            <BS.FormGroup>
              <LabeledSelect
                horizontalLabel={false}
                input={
                  {
                    name: 'salesPlanDropdown',
                    onChange: setSalesPlanId,
                    value: salesPlanId,
                  }
                }
                inputColSize={8}
                label="Sales Plan"
                labelColSize={8}
                meta={{ submitting: false }}
                offset={2}
                options={props.salesPlanOptions || []}
                loading={props.salesPlanOptionsLoading}
                required
                testid="download-spec-sheet-sales-plan-dropdown"
                textFormatter={optionTextFormatter}
                valueField="value"
              />
            </BS.FormGroup>
          </BS.Row>
        </BS.Col>
      </BS.Modal.Body>
      <BS.Modal.Footer>
        <GlobalCancelButtonContainer.GlobalCancelButton
          disabled={false}
          onClick={props.handleCancelButtonClicked}
          testid="cancel-download-spec-sheet-button"
        />
        <GlobalSaveButton
          label="Download"
          testid="download-spec-sheet-button"
          disabled={salesPlanId === INVALID_SALES_PLAN_ID}
          onClick={downloadButtonClicked}
          classNames={['mfc-button-primary']}
        />
      </BS.Modal.Footer>
    </BS.Modal>
  );
};
