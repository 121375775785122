import gql from 'graphql-tag';
import * as GQLTypes from 'schema/supplier-commitment/types';

export interface ImportSupplierCommitmentFromSpreadsheetResponse {
  importSupplierCommitmentResult: {
    success: boolean;
    errors: string[];
    supplierCommitment: Pick<GQLTypes.SupplierCommitment, 'id' | 'startWeek' | 'endWeek'>;
  };
}

export interface ImportSupplierCommitmentFromSpreadsheetArgs {
  input: GQLTypes.ImportSupplierCommitmentInput;
}

export const ImportSupplierCommitmentMutation = gql`
  mutation ImportSupplierCommitmentFromSpreadsheet($input: ImportSupplierCommitmentInput!) {
    importSupplierCommitmentResult: ImportSupplierCommitment(input: $input) {
      success
      errors
      supplierCommitment {
        id
        startWeek
        endWeek
      }
    }
  }
`;
