import * as React from 'react';
import { BoundaryErrorModal } from 'client/components/boundary-error-modal';

export class ErrorBoundary extends React.Component<{}, { error?: any, errorInfo?: any }> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });

    // notify telemetry if it is enabled
    if (window?.onerror)
      window.onerror(error.message, window.location.href, 0, 0, error);
  }

  render() {
    // Show an modal if we have an error
    if (this.state.errorInfo) {
      return (
        <BoundaryErrorModal
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
