import * as React from 'react';
import { useHistory } from 'react-router';
import { RecordBarDetail } from 'client/components/record-bar/record-bar-detail';


export function RecordBarBackButton({ onClick }: { onClick?: () => void; }) {
  const history = useHistory();
  const onBackButtonClicked = () => {
    history.goBack();
  };

  return (
    <RecordBarDetail>
      <div className="record-bar-button">
        <a data-testid="back-button" onClick={onClick || onBackButtonClicked}> <span className="mfc-large-icon mfc-icon-long-arrow-left" /></a>
        </div>
    </RecordBarDetail>
  );
}
