import gql from 'graphql-tag';

export const AddAllocationsToCustomerOrderMutation = gql`
  mutation AddAllocationsToCustomerOrderMutation($customerOrderId: Int!, $quantity: Int!, $productsToAllocate: [ProductAllocationInput!], $storeIds: [Int!], $orderMethod: String!) {
    result: addAllocationsToCustomerOrder(customerOrderId: $customerOrderId, quantity: $quantity, productsToAllocate: $productsToAllocate, storeIds: $storeIds, orderMethod: $orderMethod) {
      customerOrderProduct {
        customerOrderProductGroup {
          customerOrder {
            id
            lastModifiedAt
          }
        }
      }
    }
  }
`;
