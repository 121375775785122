import * as React from 'react';
import * as R from 'ramda';
import { flowRight } from 'lodash';
import { TotalStoresFooterCellUI } from 'client/app/transportation/routing/attached-customer-orders-table/total-stores-footer-cell/total-stores-footer-cell-ui';
import { propToComponent } from 'client/hoc/hoc';
import { GetRoutePlanAttachedCustomerOrdersInput, RoutePlanAttachedCustomerOrdersFilterInput } from 'schema/route-plan-attached-customer-orders/route-plan-attached-customer-orders-typescript-types';
import { msyncQuery } from 'client/hoc/graphql/query';
import { TotalStoresFooterCellQueryResponse, TotalStoresFooterCellQuery } from 'client/app/transportation/routing/attached-customer-orders-table/total-stores-footer-cell/total-stores-footer-cell-query';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { connect } from 'react-redux';
import * as State from 'client/state/state';
import { TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME } from 'client/constants';
import { withRouter, RouteChildrenProps } from 'react-router';
import { mapToFilters } from 'client/helpers/table-helpers';
import { FetchPolicy } from 'apollo-client';
import { buildTableStateModule } from 'client/state/tables';
import { ActiveFilter } from 'client/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OwnProps {
}

type Component<P> = new (props: P) => React.Component<P, any>;

interface WithRoutePlanIdProps {
  routePlanId: RoutePlanId;
}
const withRoutePlanId = WrappedComponent => (props: RouteChildrenProps<{ id?: string }>) => {
  return propToComponent(WrappedComponent, {
    ...props,
    routePlanId: props.match ? props.match.params.id : 0,
  });
};

const withRoutePlanIdFromUrl = R.compose(
  withRouter,
  withRoutePlanId,
);

interface StateProps {
  activeFilters: ActiveFilter[];
}
const TableStateHelpers = buildTableStateModule(TRANSPORTATION_ROUTING_PLAN_ATTACHED_ORDERS_TABLE_NAME);

const mapStateToProps = (state: State.Type): StateProps => {
  return TableStateHelpers.commonTableProps(state);
};

const WithTotalFilteredStoresCount = msyncQuery<TotalStoresFooterCellQueryResponse, OwnProps & StateProps & WithRoutePlanIdProps, shame, GetRoutePlanAttachedCustomerOrdersInput>(TotalStoresFooterCellQuery, {
  skip(ownProps: OwnProps & WithRoutePlanIdProps) {
    return ownProps.routePlanId === undefined;
  },
  options(ownProps): { fetchPolicy?: FetchPolicy, variables: GetRoutePlanAttachedCustomerOrdersInput } {
    return {
      variables: {
        filters: mapToFilters<RoutePlanAttachedCustomerOrdersFilterInput>(ownProps.activeFilters),
        routePlanId: ownProps.routePlanId,
      },
    };
  },
  props(props) {
    return {
      totalFilteredStoresCount: props.data.getRoutePlanAttachedCustomerOrders ? props.data.getRoutePlanAttachedCustomerOrders.totalFilteredStoresCount : 0,
    };
  },
});

const component = flowRight(
  withRoutePlanIdFromUrl,
  connect<StateProps, {}, OwnProps>(mapStateToProps),
  WithTotalFilteredStoresCount,
)(TotalStoresFooterCellUI) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
