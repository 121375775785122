import * as _ from 'lodash';
import { getFormValues } from 'redux-form';
import { connect, MapDispatchToProps } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { StoreOnHandUI, ComponentProps as UIProps } from './ui';
import assertCompatible from 'shared/helpers/assert-compatible';
import * as Constants from './constants';
import * as State from 'client/state/state';
import * as Selectors from './selectors';
import * as Actions from './actions';
import * as SharedActions from '../shared/actions';
import { Thunker } from 'client/types/redux-types';
import * as Queries from 'client/app/orders/reports/shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as UserParamsMappers from './user-params-mappers';
import { UserParamsMapperProps } from 'client/components/report-user-params/mapper-injector';
import { MutationStatus } from 'client/actions/mutations';
import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { SelectableValue } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerId: number | undefined;
  sellDepartmentId: number | undefined;
  productClassIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  salesPlanId: number | undefined;
  scanProductOptions: boolean[] | undefined;
  downloadButtonDisabled: boolean;
  mfcAreaIds: SelectableValue | undefined;
  regionIds: SelectableValue | undefined;
  marketIds: SelectableValue | undefined;
  storeIds: SelectableValue | undefined;
  includeStoresNotShipped: boolean;
  filterByCategoryOrSalesPlan: Constants.FilterByCategoryOrSalesPlan;

  scanBased: boolean;
  poBased: boolean;

  reportDownloadStatus: MutationStatus;

  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = getFormValues(Constants.formName)(state) as Constants.FormValues;
  const filterByCategoryOrSalesPlan = Selectors.getFilterByCategoryOrSalesPlan(state);

  if (!formValues) {
    return {
      customerId: undefined,
      sellDepartmentId: undefined,
      productClassIds: undefined,
      productSubClassIds: undefined,
      salesPlanId: undefined,
      scanProductOptions: undefined,
      downloadButtonDisabled: true,
      regionIds: undefined,
      marketIds: undefined,
      storeIds: undefined,
      mfcAreaIds: undefined,
      includeStoresNotShipped: false,
      filterByCategoryOrSalesPlan,
      scanBased: false,
      poBased: false,
      reportDownloadStatus: MutationStatus.Initial,
      dateRangeYearComparison: undefined,
      primaryGlobalAll: undefined,
    };
  }

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);
  const scanProductOptions = Selectors.getScanProductOptions(state);

  return {
    customerId: formValues[Constants.FormFields.customerId],
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    salesPlanId: formValues[Constants.FormFields.salesPlanId],
    scanProductOptions,
    downloadButtonDisabled: shouldDownloadBeDisabled,
    mfcAreaIds: formValues[Constants.FormFields.mfcAreaIds],
    regionIds: formValues[Constants.FormFields.regionIds],
    marketIds: formValues[Constants.FormFields.marketIds],
    storeIds: formValues[Constants.FormFields.storeIds],
    includeStoresNotShipped: formValues[Constants.FormFields.includeStoresNotShipped],
    filterByCategoryOrSalesPlan,
    scanBased: formValues[Constants.FormFields.scanBased],
    poBased: formValues[Constants.FormFields.poBased],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    dateRangeYearComparison: formValues[Constants.FormFields.dateRangeYearComparison] || undefined,
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
  };
};

interface DispatchProps {
  handleCustomerChanged: (customerId: number) => void;
  handleDownloadExcelReportClicked(): Thunker;
  handleDateRangesChanged: (state: DateRangeYearComparisonValue | undefined) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => Thunker;
  handleProductClassesFilterChanged: (productClassIds: number[]) => Thunker;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => Thunker;
  handleMfcAreasChanged: (ids: number[]) => Thunker;
  handleMarketsChanged: (ids: number[]) => Thunker;
  handleRegionsChanged: (ids: number[]) => Thunker;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  handleCustomerChanged: SharedActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleDateRangesChanged: Actions.handleDateRangesChanged,
  handleSellDepartmentChanged: SharedActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleMfcAreasChanged: SharedActions.handleMfcAreasChanged(Constants.formName, Constants.FormFields),
  handleMarketsChanged: SharedActions.handleMarketsChanged(Constants.formName, Constants.FormFields),
  handleRegionsChanged: SharedActions.handleRegionsChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handlePOBasedFilterChanged: SharedActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  Queries.MfcAreasQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  SharedQueries.SalesPlanQueryProps &
  SharedQueries.RegionQueryProps &
  SharedQueries.MarketQueryProps &
  SharedQueries.StoreQueryProps &
  ReduxFormProps &
  UserParamsMapperProps &
  DispatchProps;

assertCompatible<UIProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.primaryGlobalAll]: PrimaryGlobalAll.All,
      [Constants.FormFields.filterByCategoryOrSalesPlan]: Constants.FilterByCategoryOrSalesPlan.ByCategory,
    },
  }),
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  Queries.withMfcAreas,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({ onlyIncludeParentReplenishmentProducts: true }),
  SharedQueries.withSalesPlans,
  SharedQueries.withRegions,
  SharedQueries.withMarkets,
  SharedQueries.withStores,
  UserParamsMappers.withStoreOnHandReportUserParamMappers,
)(StoreOnHandUI) as Component<OwnProps>;

export const StoreOnHand = (props: OwnProps) => propToComponent(component, props);
