import * as React from 'react';
import { RecordBarDetail, RecordBarDetailSet } from 'client/components/record-bar';
import * as _ from 'lodash';

export function RecordBarDetailComponent({ record }: { record?: any; }) {
  return (
    <RecordBarDetailSet>
      {record.customer &&
        <RecordBarDetail title="Customer">
          {record.customer.name}
        </RecordBarDetail>}
      {record.region &&
        <RecordBarDetail title="Region">
          {record.region.identifier}
        </RecordBarDetail>}
      {record.market &&
        <RecordBarDetail title="Market">
          {record.market.identifier}
        </RecordBarDetail>}
      {record.mfcAreas &&
        record.mfcAreas.map(mfcArea =>
        <RecordBarDetail key={mfcArea.id} title={`${_.startCase(_.lowerCase(mfcArea.sellDepartment.identifier))} MFC Area`}>
          {mfcArea.identifier}
          </RecordBarDetail>,
        )}
    </RecordBarDetailSet>
  );
}
