import * as _                                                            from 'lodash';
import * as React                                                        from 'react';
import {    connect                                                    } from 'react-redux';
import {    getFormValues                                              } from 'redux-form';
import {    ApolloClient                                               } from 'apollo-client';
import {    createSelector                                             } from 'reselect';
import {    NormalizedCacheObject                                      } from 'apollo-cache-inmemory';

import {    ShippingUnitType , OrderMethod, DateStr, CustomerOrderType } from 'shared/types';
import {    CELL_TYPES_FOOTER_RENDERERS                                } from 'shared/types';
import * as StatsApi                                                     from 'shared/stats-card-api';
import {    tableDisplayColumns                                        } from 'shared/schemas';
import {    CustomerOrder                                              } from 'shared/schemas/customer-order';

import {    AvailableSearchField , AvailableFilter, ApolloRefetch      } from 'client/types';
import {    CUSTOMER_ORDER_OVERVIEW_TABLE_NAME                         } from 'client/constants';
import {    ActivateRecordFunction                                     } from 'client/hoc/graphql/activate-records';
import {    DeactivateRecordFunction                                   } from 'client/hoc/graphql/deactivate-records';
import      dataContainer                                                from 'client/hoc/data-container';
import {    msyncMutation                                              } from 'client/hoc/graphql/mutation';
import {    GraphQLProps as StatsRecordProps                           } from 'client/hoc/graphql/stats-record';
import {    MsyncDataRequest                                           } from 'client/hoc/graphql/query';
import {    buildFilterableTable , OwnProps as FilterableTableProps    } from 'client/containers/table/table-filter-container';
import {    PendingRowActionState , pendingRowActionHandler            } from 'client/containers/table/basic-table';
import {    Button , Col                                               } from 'client/components/third-party';
import {    StatsRow                                                   } from 'client/components/stats-row';
import {    RowMenuItem                                                } from 'client/components/table/row-menu/menu';
import {    IColumn                                                    } from 'client/components/table/column';
import {    tableParentHoc , TableParentInfo                           } from 'client/components/table/table-parent';
import      CellRenderer                                                 from 'client/components/table/cell-renderer';
import * as ImportCustomerOrderSpreadsheetActions                        from 'client/actions/import-customer-order-spreadsheet';
import {    updateAllocationsOnLineItemMutation                        } from 'client/app/orders/customer-orders/overview/mutations';
import {     ImportCustomerOrderSpreadsheetModal                       } from 'client/app/orders/customer-orders/overview/import-customer-order-spreadsheet-modal';

import      CustomerOrderDetailModal                                     from './customer-order-detail-modal';
import {    WithCustomerOrderDetailTableFooterData                     } from './customer-order-detail-table-footer-container';

const CustomerOrderOverviewFooterCell = (p: {
  data: any,
  column: IColumn,
  footerData?: { [k: string]: any },
}) => (!p.footerData || p.footerData.loading)
  ? (<div><span className="fa fa-spinner fa-spin customer-order-overview-footer-spinner" /></div>)
  : (
    <CellRenderer
      required={false}
      value={p.footerData ? p.footerData[p.column.id] : ''}
      type={p.column.type}
      columnName={p.column.id}
      tableDisplayType={CELL_TYPES_FOOTER_RENDERERS[p.column.cellType]}
      id={0}
      testid={`footer-${p.column.id}`}
      sorted={false}
      editing={false}
      placeholder=""
      onClick={() => { } }
      onDoubleClick={() => { } }
      className=""
    />
  );

const tableName = CUSTOMER_ORDER_OVERVIEW_TABLE_NAME;

const mapDispatchToProps = {
  onImportCustomerOrderSpreadsheetOpenModalButtonClicked: ImportCustomerOrderSpreadsheetActions.importCustomerOrderSpreadsheetOpenModalButtonClicked,
};

const mapStateToProps = (state, { formName }): StateProps => {
  const formValues = getFormValues(formName)(state) as StateProps;

  return { ...formValues, customerOrderId: formValues.id };
};

interface FormValues {
  id: number;
  customerId: number;
  customerOrderId: number;
  formName: string;
  mfcAreaId: number;
  orderMethod: OrderMethod;
  shippingUnitType: ShippingUnitType;
  sellDepartment: { identifier: string };
  scanBased: boolean;
  subSellDepartment: string;
  orderDate: DateStr;
  orderType: string;
}

type StateProps = FormValues;

export interface OwnProps {
  record: CustomerOrder;
  columns: IColumn[];
  loading?: boolean;
  content: any[];
  refetchTable: () => void;
  totalUnfilteredCount: number;
  totalCount: number;
  filteredRecordIds: number[];
  loadMoreRecords?: () => void;
  client: ApolloClient<NormalizedCacheObject>;
  performActivation: ActivateRecordFunction;
  performDeletion: DeactivateRecordFunction;
  searchableFields: AvailableSearchField[];
  availableFilters: AvailableFilter[];
  stats?: StatsApi.Card[];
  tablePageNumber: number;
  tableParentInfo: TableParentInfo;
  confirmOkToSave: () => Promise<boolean>;
  handleSubmit: () => Promise<boolean>;
  refetchStats: StatsRecordProps['refetchStats'];
  dataRequest: MsyncDataRequest;
  onImportCustomerOrderSpreadsheetOpenModalButtonClicked: () => void;
}

type Props = OwnProps & StateProps & WithMutationProps;

interface GetColumnsArgs {
  orderType: string;
  subSellDepartment: string;
  shippingUnitType: string;
  orderMethod: OrderMethod;
  orderDate: DateStr;
  updateAllocationsOnLineItem: (args: { id: number, quantity?: number, rackQuantity?: number, statsRefetch?: ApolloRefetch }) => Promise<void>;
}

interface WithMutationProps {
  updateAllocationsOnLineItem: (args: { id: number, quantity?: number, rackQuantity?: number, statsRefetch?: ApolloRefetch }) => Promise<void>;
}

const withMutation = msyncMutation(updateAllocationsOnLineItemMutation, {
  props: ({ mutate }): WithMutationProps => {
    return {
      updateAllocationsOnLineItem: async (args: { id: number, quantity?: number, rackQuantity?: number, statsRefetch?: ApolloRefetch }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { statsRefetch, ...variables } = args;

        await mutate({
          variables,
          refetchQueries: ['CustomerOrderAllocationSummaryTotals'],
        });
      },
    };
  },
});

function isExternalDistribution(orderType): boolean {
  return orderType === CustomerOrderType.ExternalDistribution;
}

function getColumns(args: GetColumnsArgs): Array<Partial<IColumn>> {
  const selectedColumns: Array<Partial<IColumn>> = [];
  const {
    shippingUnitType,
    orderMethod,
    orderType,
  } = args;

  if (isExternalDistribution(orderType)) {
    selectedColumns.push(...[
      { id: 'store.identifier', header: 'Store', tableEditable: false },
      { id: 'product.identifier', header: 'Case Pack ID', tableEditable: false },
      { id: 'productDescription', header: 'Description', tableEditable: true },
      { id: 'customerOrderProduct.packSize', header: 'Pack', tableEditable: false, sortable: true },
      { id: 'quantity', header: 'Case Qty', tableEditable: true, onSave: (id, quantity) => onSaveQuantity({ args, id, quantity }), footerComponent: CustomerOrderOverviewFooterCell },
      { id: 'totalUnits', header: 'Total Pieces', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
    ]);
  } else {
    selectedColumns.push(...[
      { id: 'store.identifier', header: 'Store', tableEditable: false },
      { id: 'product.identifier', header: 'Prod ID', tableEditable: false },
      { id: 'productDescription', header: 'Description', tableEditable: true },
    ]);

    if (shippingUnitType !== ShippingUnitType.NotApplicable) {
      if (orderMethod === OrderMethod.Pack) {
        selectedColumns.push(...[
          { id: 'customerOrderProductGroup.identifier', header: 'Case Type', tableEditable: false },
        ]);
      } else {
        selectedColumns.push(...[
          { id: 'customerOrderProductGroup.identifier', header: 'Rack Type', tableEditable: false },
        ]);
      }
    }

    selectedColumns.push(...[
      { id: 'price', header: 'Price', tableEditable: true },
      { id: 'retail', header: 'Retail', tableEditable: true },
    ]);

    selectedColumns.push({ id: 'customerOrderProduct.packSize', header: 'Pack', tableEditable: false, sortable: true });

    if (shippingUnitType === ShippingUnitType.Rack) {
      selectedColumns.push(...[
        { id: 'customerOrderProduct.packsPerShelf', tableEditable: false },
        { id: 'customerOrderProduct.shelvesPerRack', tableEditable: false },
        { id: 'customerOrderProduct.packsPerShippingUnit', header: 'PPR', tableEditable: false },
      ]);
    } else if (shippingUnitType === ShippingUnitType.Pallet) {
      selectedColumns.push({ id: 'customerOrderProduct.packsPerShippingUnit', header: 'CPP', tableEditable: false });
    }

    if (orderMethod === OrderMethod.Pack) {
      selectedColumns.push(...[
        { id: 'quantity', header: 'Case Qty', tableEditable: true, onSave: (id, quantity) => onSaveQuantity({ args, id, quantity }), footerComponent: CustomerOrderOverviewFooterCell },
      ]);
    }

    if (shippingUnitType === ShippingUnitType.Rack || shippingUnitType === ShippingUnitType.Pallet) {
      selectedColumns.push(...[{
        id: 'rackQuantity',
        tableEditable: orderMethod === OrderMethod.ShippingUnit,
        onSave: (id, rackQuantity) => onSaveQuantity({ args, id, rackQuantity }),
        footerComponent: CustomerOrderOverviewFooterCell,
        header
          : shippingUnitType === ShippingUnitType.Rack    && orderMethod === OrderMethod.ShippingUnit ? 'Rk Qty'
          : shippingUnitType === ShippingUnitType.Pallet  && orderMethod === OrderMethod.ShippingUnit ? 'Plt Qty'
          : shippingUnitType === ShippingUnitType.Rack    && orderMethod === OrderMethod.Pack         ? 'Rks Order'
          : shippingUnitType === ShippingUnitType.Pallet  && orderMethod === OrderMethod.Pack         ? 'Plts Order'
          :                                                  orderMethod === OrderMethod.Pack         ? 'Ship Unit Order'
          :                                                                                             'Ship Unit Qty',
      }]);
    }

    if (orderMethod === OrderMethod.ShippingUnit) {
      if (shippingUnitType === ShippingUnitType.Rack) {
        selectedColumns.push(...[
          { id: 'quantity', header: 'Pks Order', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
        ]);
      } else {
        selectedColumns.push(...[
          { id: 'quantity', header: 'Case Order', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
        ]);
      }
    }

    selectedColumns.push(...[
      { id: 'totalUnits', header: 'Pcs Order', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
      { id: 'totalPrice', header: 'Total Price', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
      { id: 'totalRetail', header: 'Total Retail', tableEditable: false, footerComponent: CustomerOrderOverviewFooterCell },
    ]);
  }

  const mappedColumns = selectedColumns
    .map(column => {
      const columnWidth = column.id === 'productDescription'
        ? ((1.0 / (selectedColumns.length + 1)) * 100) * 2 // 2 columns for description
        : ((1.0 / (selectedColumns.length + 1)) * 100);

      return {
        ...column,
        columnWidth,
      };
    });

  return mappedColumns;
}

function parseNumberOrUndefined(value?: string) {
  const parsed = parseInt(value || '');
  if (isNaN(parsed)) {
    return undefined;
  }

  return parsed;
}

function onSaveQuantity(params: { args: GetColumnsArgs, id: number, quantity?: string, rackQuantity?: string }) {
  return params.args.updateAllocationsOnLineItem({
    id: params.id,
    quantity: parseNumberOrUndefined(params.quantity),
    rackQuantity: parseNumberOrUndefined(params.rackQuantity),
  });
}

/**
 * Memoizes the `getColumns` function against its dependence on a subset of Redux state,
 * using the Reselect library: https://github.com/reactjs/reselect
 */
const getCustomColumns = baseCols => createSelector([getColumns], cols => {
  const mapped = cols.map(column => {
    const baseColumn = baseCols.find(c => c.id === column.id);
    if (!baseColumn) {
      console.error(`Column ${column.id} was not found in the base columns for Customer Order Allocations`);
      return null;
    }
    return Object.assign({}, baseColumn, column);
  }).filter(c => c !== null) as IColumn[];

  return mapped;
});

const getCustomFilters = (orderType, availableFilters) => {
  if (! isExternalDistribution(orderType)) {
    return availableFilters;
  }
  // For External Distribution customer allocations, we allow filtering on
  // store (so keep that from the availableFilters), and Case Pack ID, which
  // is just a rename of the Product filter.
  const storeFilter = availableFilters.find(af => af.displayName === 'Store');
  // rename Product filter field to Case Pack ID filter field.
  const prodFilter = {...(availableFilters.find(af => af.displayName === 'Product')),
                      displayName: 'Case Pack ID'};
  return [ storeFilter, prodFilter ];
};

const getCustomSearchFields = (orderType, searchableFields) => {
  if (! isExternalDistribution(orderType)) {
    return searchableFields;
  }
  // For External Distribution customer allocations, we allow searching on different fields.
  // specifically: all, description, store and case pack id.
  const newSearchFields = searchableFields.filter(sf =>
    sf.name === 'Search All' || sf.name === 'Description' || sf.name === 'Store');
  // rename Product search field to Case Pack ID search field.
  const casePackIdSearchField = { ...(searchableFields.find(sf => sf.name === 'Product')),
                      name: 'Case Pack ID' };
  newSearchFields.push(casePackIdSearchField);
  return newSearchFields;
};

interface State extends PendingRowActionState {
  showModal: boolean;
  initialLoadingComplete?: boolean;
}

class CustomerOrderAllocations extends React.Component<Props, State> {
  private readonly tableComponent: (props: FilterableTableProps) => JSX.Element;
  private readonly getColumns: (args: GetColumnsArgs) => IColumn[];

  constructor(props: Props) {
    super(props);
    this.tableComponent = WithCustomerOrderDetailTableFooterData(buildFilterableTable(tableName)) as shame;

    if (props.loading !== false) {
      this.state = { showModal: false, initialLoadingComplete: false, pendingRowAction: false };
    } else {
      this.state = { showModal: false, initialLoadingComplete: true, pendingRowAction: false };
    }

    this.getColumns = getCustomColumns(props.columns);

    this.onNewClick = this.onNewClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.state.initialLoadingComplete && (this.props.loading === undefined || this.props.loading === true) && nextProps.loading === false) {
      this.setState({ initialLoadingComplete: true });
    }
  }

  public render() {
    const {
      customerOrderId,
      customerId,
      mfcAreaId,
      refetchTable,
      totalUnfilteredCount,
      orderMethod,
      shippingUnitType,
      loading,
      stats,
      sellDepartment,
      scanBased,
      orderDate,
      subSellDepartment,
      orderType,
      confirmOkToSave,

    } = this.props;
    const placeholder = 'There are currently no prices for this product.';
    const rowMenuItems: RowMenuItem[] = [{
      label: 'Delete Row',
      onClick: pendingRowActionHandler(this, this.props.performDeletion),
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    const headerMenuItems: RowMenuItem[] = [{
      label: 'Delete Selected Rows',
      onClick: pendingRowActionHandler(this, this.props.performDeletion),
      uncheckRecordFollowingClick: true,
      willRemove: true,
    }];

    const shouldDisplayTable = this.state.initialLoadingComplete && !_.isNil(customerOrderId) && totalUnfilteredCount > 0;
    const shouldDisplayNewRecordButton = _.isNil(customerOrderId) || (this.state.initialLoadingComplete && (!loading && totalUnfilteredCount === 0));

    let outerClassName = '';
    if (!shouldDisplayTable) {
      if (this.props.content) {
        outerClassName = 'table-hidden';
      } else {
        outerClassName = 'table-invisible';
      }
    }

    const customFilters = getCustomFilters(this.props.orderType, this.props.availableFilters);
    const searchFields = getCustomSearchFields(this.props.orderType, this.props.searchableFields);

    return (
      <div>
        {!_.isNil(customerOrderId) &&
          <div>
            <Col sm={12} className={outerClassName}>
              <div data-testid="customer-order-allocations">
                <StatsRow stats={stats}/>
                <this.tableComponent
                  footerProps={{ customerOrderId: this.props.customerOrderId }}
                  table={tableName}
                  content={this.props.content}
                  loading={this.props.loading || this.state.pendingRowAction}
                  columns={this.getColumns({ orderType, subSellDepartment, shippingUnitType, orderMethod, orderDate, updateAllocationsOnLineItem: this.props.updateAllocationsOnLineItem })}
                  totalCount={this.props.totalCount}
                  totalUnfilteredCount={this.props.totalUnfilteredCount}
                  filteredRecordIds={this.props.filteredRecordIds}
                  onNewClicked={this.onNewClick}
                  refetchTable={this.props.refetchTable}
                  refetchStats={this.props.refetchStats}
                  loadMoreRecords={this.props.loadMoreRecords}
                  searchableFields={searchFields}
                  availableFilters={customFilters}
                  tableParentInfo={this.props.tableParentInfo}
                  tablePaginated
                  placeholder={placeholder}
                  newButtonLabel={'Add Product'}
                  checkable
                  list={false}
                  headerMenuItems={headerMenuItems}
                  rowMenuItems={rowMenuItems}
                  confirmOkToSave={confirmOkToSave}
                  handleSubmit={this.props.handleSubmit}
                  displayLoadingIndicator
                  dataRequest={{
                    ...this.props.dataRequest,
                    worksheetName: 'Overview',
                    workbookName: `${this.props.record.identifier} - Overview`,
                  }}
                />
              </div>
            </Col>
            <CustomerOrderDetailModal
              customerId={customerId}
              customerOrderId={customerOrderId}
              isModal
              mfcAreaId={mfcAreaId}
              orderType={orderType}
              onClose={this.onClose}
              name="customerOrderAllocations"
              refetchTable={refetchTable}
              scanBased={scanBased}
              sellDepartmentIdentifier={sellDepartment.identifier}
              orderMethod={orderMethod}
              shippingUnitType={shippingUnitType}
              userConfirmation={confirmOkToSave}
              show={this.state.showModal} />
          </div>
        }
        {shouldDisplayNewRecordButton &&
          <div className="table-placeholder">
            <h2 className="message">There are currently no products allocated to stores for this customer order.</h2>
            <Button bsStyle="primary"
                    bsClass="mfc-button mfc-new-record-button"
                    data-testid="qa-new-element-button"
                    onClick={this.onNewClick} disabled={!customerOrderId}>
              Allocate Products
            </Button>

            {!customerOrderId &&
              <div>
                <ImportCustomerOrderSpreadsheetModal />

                <div className="text-center">
                  <div className="mfc-form-button" data-testid="upload-order-from-spreadsheet" onClick={this.props.onImportCustomerOrderSpreadsheetOpenModalButtonClicked}>
                      <i className="fa fa-cloud-upload"></i> Upload Order from Spreadsheet
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }

  private readonly onNewClick = () => {
    this.setState({ showModal: true });
  }

  private readonly onClose = () => {
    this.setState({ showModal: false });
  }
}

interface FormatOptions { symbol: string; decimal: string; thousand: string; precision: number; format: string; }
const formatMoneyOptions = { symbol: '$', decimal: '.', thousand: ',', precision: 2, format: '%s%v' };
const formatNumberOptions = { symbol: '', decimal: '.', thousand: ',', precision: 0, format: '%s%v' };
const formatDecimalOptions = { symbol: '', decimal: '.', thousand: ',', precision: 2, format: '%s%v' };

/**
 * In addition to providing the format options for the cards, these options allow us
 * to render empty cards if the server does not respond.
 */
function getFormatOptions(state: StateProps): Dictionary<FormatOptions> {
  const commonOptions = { units: formatNumberOptions, price: formatMoneyOptions, retail: formatMoneyOptions };
  switch (state.shippingUnitType) {
    case ShippingUnitType.Rack: return Object.assign(commonOptions, { racks: formatDecimalOptions });
    case ShippingUnitType.Pallet: return Object.assign(commonOptions, { cases: formatNumberOptions, pieces: formatNumberOptions });
    case ShippingUnitType.NotApplicable:
    default: return { price: formatMoneyOptions };
  }
}

const getFormName = (input: { state: any, formName: string }) => input.formName;
const getTheState = (input: { state: any, formName: string }) => input.state;
const getTheFormValues = createSelector(getFormName, getTheState, (formName: string, state: any) => getFormValues(formName)(state) as StateProps);

const determineStatsOptionsFromState = createSelector(getTheFormValues, values => {
  const statsOptions: StatsApi.StatsOptions = {
    tableName,
    options: {
      customerOrderId: values.id,
      orderMethod: values.orderMethod,
      shippingUnitType: values.shippingUnitType,
    },
    formatOptions: getFormatOptions(values),
  };

  return statsOptions;
});


export default _.flowRight(
  connect(mapStateToProps, mapDispatchToProps),
  tableParentHoc(),
  withMutation,
  dataContainer({
    table: tableName,
    columns: tableDisplayColumns(tableName).filter(c => c !== 'customerOrder'),
    scope: ({ record: { id } }) => ([{ field: 'customerOrder', values: [id] }]),
    statsOptionsFromState(state: any, formName: string): StatsApi.StatsOptions {
      return determineStatsOptionsFromState({ state, formName });
    },
    refetchQueries: ['CustomerOrderAllocationSummaryTotals'],
  }),
)(CustomerOrderAllocations);
