import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { MutationStatus } from 'client/actions/mutations';
import * as State from 'client/state/state';

interface StateProps {
  actionStatus: MutationStatus;
}
const mapStateToProps = (getMutationStatus: (state: State.Type) => MutationStatus) => (state: State.Type): StateProps => {
  return {
    actionStatus: getMutationStatus(state),
  };
};

interface DispatchProps {
  dispatchLoadingState(): void;
  dispatchCompleteState(): void;
  dispatchInitialState(): void;
}
const mapDispatchToProps = (setMutationStatusAction: shame) => (dispatch: Dispatch): DispatchProps => {
  return {
    dispatchLoadingState: () => dispatch(setMutationStatusAction(MutationStatus.InProgress)),
    dispatchCompleteState: () => dispatch(setMutationStatusAction(MutationStatus.Complete)),
    dispatchInitialState: () => dispatch(setMutationStatusAction(MutationStatus.Initial)),
  };
};

export type WithMutationStatusProps = StateProps & DispatchProps;
export const withMutationStatusProps = (getMutationStatus: (state: State.Type) => MutationStatus, setMutationStatusAction: shame) => connect<StateProps, DispatchProps, {}>(mapStateToProps(getMutationStatus), mapDispatchToProps(setMutationStatusAction));

export const mutationStatusWrapper = (mutateFunction, props: DispatchProps) => async input => {
  try {
    props.dispatchLoadingState();
    const result = await mutateFunction(input);
    props.dispatchCompleteState();
    setTimeout(props.dispatchInitialState, 1500);
    return result;
  } catch {
    // msyncMutation handles the error
    // Assuming that the status dispatchers are not throwing errors
    props.dispatchInitialState();
  }
};
