import { flatten } from 'lodash';
import gql from 'graphql-tag';
import { recordType as getRecordType } from '../../../shared/schemas';
import { ApolloClient } from 'apollo-client';
import { msyncMutation } from 'client/hoc/graphql/mutation';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

const ACTIVATE_MUTATION = gql`
  mutation activate($type: RecordType!, $ids: [Int]!) {
    activatedIds: activate(type: $type, ids: $ids)
  }
`;

export type ActivateRecordFunction = (ids: number | number[]) => Promise<number[]>;
export interface ActivateContainerProps { performActivation: ActivateRecordFunction }
export interface OwnProps { refetchTable: () => void, client: ApolloClient<NormalizedCacheObject> }
export interface GraphQlProps { activatedIds: number[] }
export interface ActivationMutationVariables { ids: number[], type: string }
export const withRecordActivations = (table: string) => {
  const recordType = getRecordType(table);
  const withActivations = msyncMutation<GraphQlProps, OwnProps, ActivateContainerProps, ActivationMutationVariables>(ACTIVATE_MUTATION, {
    alias: 'withRecordActivations',
    props: props => ({
      performActivation: async ids => {
        let activatedIds: number[] = [];
        try {
          const result = await props.mutate({
            variables: { ids: flatten([ids]), type: recordType },
            refetchQueries: ['GetRecordFilterOptions'],
          });

          if (result && result.data)
            activatedIds = result.data.activatedIds;

        } catch (error) {
          // Error should have been handled by the global error handler, so just log it
          console.info('Problem activating records', error.message);
          return [];
        }

        await props.ownProps.refetchTable();
        return activatedIds;
      },
    }),
  });

  return withActivations;
};
