import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { propToComponent } from 'client/hoc/hoc';
import { AsyncButtonUI } from 'client/components/async-button/async-button-ui';
import * as State from 'client/state/state';
import * as Selectors from 'client/state/selectors';
import * as MutationSelectors from 'client/state/mutation-selectors';

interface GlobalSaveButtonProps {
  label: string;
  onClick?(): void;
  disabled?: boolean;
  classNames?: string[];
  testid: string;
  type?: 'submit' | undefined;
}

interface StateProps {
  showSpinner: boolean;
  disabled?: boolean;
  showCheck: boolean;
}

const mapStateToProps = (state: State.Type, ownProps: GlobalSaveButtonProps): StateProps => {
  const inFlight = Selectors.isGlobalSaveButtonSpinning(state);
  return {
    showSpinner: inFlight,
    disabled: inFlight || ownProps.disabled,
    showCheck: !inFlight && MutationSelectors.isMutationRecentlyReceived(state),
  };
};

export type ComponentProps =
  GlobalSaveButtonProps &
  StateProps;

const component = flowRight(
  connect<StateProps, any, GlobalSaveButtonProps>(mapStateToProps, undefined),
)(AsyncButtonUI);

export const GlobalSaveButton = (props: GlobalSaveButtonProps) => propToComponent(component, props);
