import { DateRangeYearComparisonValue } from 'shared/helpers/date-helpers';
import * as _ from 'lodash';
import * as Actions from './actions';
import * as Constants from './constants';
import * as Mutations from 'client/actions/mutations';
import * as OrderQueries from 'client/app/orders/reports/shared/query';
import * as SharedQueries from 'client/app/reports/shared/query';
import * as PerformanceReportHelpers from 'shared/app/reports/performance';
import * as ReactRedux from 'react-redux';
import * as ReduxForm from 'redux-form';
import * as ReduxTypes from 'client/types/redux-types';
import * as ReportUserParams from 'client/components/report-user-params';
import * as Selectors from './selectors';
import * as SharedOrderActions from 'client/app/orders/reports/shared/actions';
import * as State from 'client/state/state';
import * as UI from './ui';
import * as UserParamsMappers from './user-params-mappers';
import * as WrappedContainer from 'client/hoc/hoc';
import assertCompatible from 'shared/helpers/assert-compatible';
import { PerformanceReportDateColumnBehavior, PerformanceReportStoreDetailLevel, PerformanceReportDateDetailLevel, PerformanceReportProductGroupingBehavior, SelectableValue } from 'shared/types';
import { PrimaryGlobalAll } from 'client/types/primary-global-all';
import { reportingForm } from 'client/components/report-user-params/reporting-form';
import { DropDownOptions } from 'client/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OwnProps {
}

interface StateProps {
  customerId: number | undefined;
  dateColumnBehavior: PerformanceReportDateColumnBehavior;
  dateRangeYearComparison: DateRangeYearComparisonValue | undefined;
  downloadButtonDisabled: boolean;
  lastYearDateComparisonDisabled: boolean;
  marketIds: SelectableValue | undefined;
  measures: SimpleObject;
  mfcAreaIds: SelectableValue | undefined;
  options: SimpleObject;
  poBased: boolean;
  productClassIds: SelectableValue | undefined;
  productIds: SelectableValue | undefined;
  productSubClassIds: SelectableValue | undefined;
  regionIds: SelectableValue | undefined;
  reportDownloadStatus: Mutations.MutationStatus;
  scanBased: boolean;
  scanProductOptions: boolean[] | undefined;
  sellDepartmentId: number | undefined;
  sortBy: string | undefined;
  sortByOptions: DropDownOptions;
  storeDetailLevel: PerformanceReportStoreDetailLevel;
  dateDetailLevel: PerformanceReportDateDetailLevel;
  productGroupingBehavior: PerformanceReportProductGroupingBehavior;
  storeIds: SelectableValue | undefined;
  showDownloadDisabledReason: boolean;
  dateColumnBehaviorDropdownDisabled: boolean;
  dateDetailLevelDropdownDisabled: boolean;
  numberOfExpectedGeneratedRows: number;
  primaryGlobalAll: PrimaryGlobalAll | undefined;
}

const mapStateToProps = (state: State.Type): StateProps => {
  const formValues = ReduxForm.getFormValues(Constants.formName)(state) as Constants.FormValues;

  if (!formValues) {
    return {
      customerId: undefined,
      dateColumnBehavior: PerformanceReportDateColumnBehavior.ThisYearLastYearDateRanges,
      dateRangeYearComparison: undefined,
      downloadButtonDisabled: true,
      lastYearDateComparisonDisabled: false,
      marketIds: undefined,
      measures: {},
      mfcAreaIds: undefined,
      options: {},
      poBased: false,
      productClassIds: undefined,
      productIds: undefined,
      productSubClassIds: undefined,
      regionIds: undefined,
      reportDownloadStatus: Mutations.MutationStatus.Initial,
      scanBased: false,
      scanProductOptions: undefined,
      sellDepartmentId: undefined,
      sortBy: undefined,
      sortByOptions: [],
      storeDetailLevel: PerformanceReportStoreDetailLevel.Total,
      dateDetailLevel: PerformanceReportDateDetailLevel.Total,
      productGroupingBehavior: PerformanceReportProductGroupingBehavior.RollUpToParent,
      storeIds: undefined,
      showDownloadDisabledReason: false,
      dateColumnBehaviorDropdownDisabled: false,
      dateDetailLevelDropdownDisabled: false,
      numberOfExpectedGeneratedRows: 0,
      primaryGlobalAll: undefined,
    };
  }

  const storeDetailLevel = formValues[Constants.FormFields.storeDetailLevel];
  const dateDetailLevel = formValues[Constants.FormFields.dateDetailLevel];
  const dateColumnBehavior = formValues[Constants.FormFields.dateColumnBehavior];
  const productGroupingBehavior = formValues[Constants.FormFields.productGroupingBehavior];

  const performanceMeasureFields = PerformanceReportHelpers.getPerformanceMeasureFieldNames(storeDetailLevel, dateDetailLevel);
  const performanceOptionFields = PerformanceReportHelpers.getPerformanceOptionFieldNames();

  const shouldDownloadBeDisabled = Selectors.shouldDownloadBeDisabled(state);
  const scanProductOptions = Selectors.getScanProductOptions(state);

  const dateRangeYearComparison = formValues[Constants.FormFields.dateRangeYearComparison];
  const compareToPreviousYear = dateRangeYearComparison && dateRangeYearComparison.compareToPreviousYear;

  const sortByOptions: DropDownOptions = PerformanceReportHelpers.getEnabledPerformanceMeasureFieldNameLabels(formValues, storeDetailLevel, dateDetailLevel, compareToPreviousYear || false)
  .filter(field => field.sortable)
    .map(field => ({
      id: field.fieldName,
      value: field.fieldName,
      identifier: field.label,
    }));

  return {
    customerId: formValues[Constants.FormFields.customerId],
    dateColumnBehavior,
    dateRangeYearComparison: formValues[Constants.FormFields.dateRangeYearComparison] || undefined,
    downloadButtonDisabled: shouldDownloadBeDisabled,
    lastYearDateComparisonDisabled: formValues[Constants.FormFields.dateColumnBehavior] === PerformanceReportDateColumnBehavior.CalendarYearDateRange,
    marketIds: formValues[Constants.FormFields.marketIds],
    measures: _.pick(formValues, performanceMeasureFields),
    mfcAreaIds: formValues[Constants.FormFields.mfcAreaIds],
    options: _.pick(formValues, performanceOptionFields),
    poBased: formValues[Constants.FormFields.poBased],
    productClassIds: formValues[Constants.FormFields.productClassIds],
    productIds: formValues[Constants.FormFields.productIds],
    productSubClassIds: formValues[Constants.FormFields.productSubClassIds],
    regionIds: formValues[Constants.FormFields.regionIds],
    reportDownloadStatus: Selectors.getReportDownloadStatus(state),
    scanBased: formValues[Constants.FormFields.scanBased],
    scanProductOptions,
    sellDepartmentId: formValues[Constants.FormFields.sellDepartmentId],
    sortBy: formValues[Constants.FormFields.sortBy],
    sortByOptions,
    storeDetailLevel,
    dateDetailLevel,
    productGroupingBehavior,
    storeIds: formValues[Constants.FormFields.storeIds],
    showDownloadDisabledReason: Selectors.isTheExpectedReportTooLarge(formValues),
    numberOfExpectedGeneratedRows: Selectors.getExpectedNumberOfGeneratedRows(formValues),
    dateColumnBehaviorDropdownDisabled: dateDetailLevel === PerformanceReportDateDetailLevel.Day || dateDetailLevel === PerformanceReportDateDetailLevel.Week,
    dateDetailLevelDropdownDisabled: dateColumnBehavior === PerformanceReportDateColumnBehavior.CalendarYearDateRange,
    primaryGlobalAll: formValues[Constants.FormFields.primaryGlobalAll],
  };
};

interface DispatchProps {
  handleCustomerChanged: (customerId: number) => void;
  handleDateRangesChanged: (state: DateRangeYearComparisonValue | undefined) => void;
  handleDownloadExcelReportClicked(): ReduxTypes.Thunker;
  handleMarketsChanged: (ids: number[]) => ReduxTypes.Thunker;
  handleMeasureCheckboxClicked: (categoryName: string, measure: string, isOn: boolean) => void;
  handleMfcAreasChanged: (ids: number[]) => ReduxTypes.Thunker;
  handlePOBasedFilterChanged: (isOn: boolean) => void;
  handleProductClassesFilterChanged: (productClassIds: number[]) => ReduxTypes.Thunker;
  handleProductSubClassesFilterChanged: (productSubClassIds: number[]) => ReduxTypes.Thunker;
  handleRegionsChanged: (ids: number[]) => ReduxTypes.Thunker;
  handleScanBasedFilterChanged: (isOn: boolean) => void;
  handleSellDepartmentChanged: (sellDepartmentId: number) => ReduxTypes.Thunker;
  handleDateColumnBehaviorChanged: (dateColumnBehavior: PerformanceReportDateColumnBehavior) => void;
  handleDateDetailLevelChanged: (dateDetailLevel: PerformanceReportDateDetailLevel) => void;
}

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = {
  handleCustomerChanged: SharedOrderActions.handleCustomerChanged(Constants.formName, Constants.FormFields),
  handleDateRangesChanged: Actions.handleDateRangesChanged,
  handleDownloadExcelReportClicked: Actions.handleDownloadExcelReportClicked,
  handleMarketsChanged: SharedOrderActions.handleMarketsChanged(Constants.formName, Constants.FormFields),
  handleMeasureCheckboxClicked: Actions.handleMeasureCheckboxClicked,
  handleMfcAreasChanged: SharedOrderActions.handleMfcAreasChanged(Constants.formName, Constants.FormFields),
  handlePOBasedFilterChanged: SharedOrderActions.handlePOBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleProductClassesFilterChanged: SharedOrderActions.handleProductClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleProductSubClassesFilterChanged: SharedOrderActions.handleProductSubClassesFilterChanged(Constants.formName, Constants.FormFields),
  handleRegionsChanged: SharedOrderActions.handleRegionsChanged(Constants.formName, Constants.FormFields),
  handleScanBasedFilterChanged: SharedOrderActions.handleScanBasedFilterChanged(Constants.formName, Constants.FormFields),
  handleSellDepartmentChanged: SharedOrderActions.handleSellDepartmentChanged(Constants.formName, Constants.FormFields),
  handleDateColumnBehaviorChanged: Actions.handleDateColumnBehaviorChanged,
  handleDateDetailLevelChanged: Actions.handleDateDetailLevelChanged,
};

interface ReduxFormProps {
  pristine: boolean;
  isLoadingPreset: boolean;
}

export type ComponentProps =
  OwnProps &
  StateProps &
  SharedQueries.CustomerQueryProps &
  SharedQueries.SellDepartmentQueryProps &
  OrderQueries.MfcAreasQueryProps &
  SharedQueries.ProductClassQueryProps &
  SharedQueries.ProductSubClassQueryProps &
  SharedQueries.ProductQueryProps &
  SharedQueries.RegionQueryProps &
  SharedQueries.MarketQueryProps &
  SharedQueries.StoreQueryProps &
  ReduxFormProps &
  ReportUserParams.UserParamsMapperProps &
  DispatchProps;

assertCompatible<UI.ComponentProps, ComponentProps>();

type Component<P> = new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  SharedQueries.withCustomers,
  SharedQueries.withSellDepartments,
  reportingForm({
    form: Constants.formName,
    reportType: Constants.reportType,
    initialValues: {
      [Constants.FormFields.primaryGlobalAll]: PrimaryGlobalAll.All,
    },
  }),
  ReactRedux.connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps),
  OrderQueries.withMfcAreas,
  SharedQueries.withProductClasses,
  SharedQueries.withProductSubClasses,
  SharedQueries.withProducts({ onlyIncludeParentReplenishmentProducts: true }),
  SharedQueries.withRegions,
  SharedQueries.withMarkets,
  SharedQueries.withStores,
  UserParamsMappers.withPerformanceReportUserParamMappers,
)(UI.PerformanceReportUI) as Component<OwnProps>;

export const PerformanceReport = (props: OwnProps) => WrappedContainer.propToComponent(component, props);
