import { tableName, property, belongsTo, definePresentation, setSchemaOptions, required } from './dsl';
import { Supplier } from './supplier';
import { DISPLAY_TYPES, ActiveInactive } from '../types';
import { IRecord } from './record';
import { displayType } from './dsl';
import { SupplierDisplayValueResolver } from 'shared/helpers/display-value-resolver-helpers';

@tableName('pots')
export class Pot implements IRecord {
  id?: number;
  @property @required identifier: string;
  @property @required description: string;

  @belongsTo('suppliers', { foreignDisplayKey: 'name', foreignQueryKeys: ['name'] })
  @property supplier: Supplier;
  supplierId: number;

  @property partNumber?: string | null;
  @property size?: string | null;
  @property color?: string | null;
  @property weightAndMeasure?: string | null;

  @property @required activeStatus: ActiveInactive;
}

definePresentation(Pot, {
  identifier: {
    searchable: true,
    sortable: true,
    displayName: 'ID',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  description: {
    searchable: true,
    sortable: true,
    displayName: 'Description',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 30,
  },
  supplier: {
    searchable: true,
    sortable: true,
    displayName: 'Supplier',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { displayValueResolver: SupplierDisplayValueResolver }),
    columnWidth: 20,
  },
  partNumber: {
    searchable: true,
    sortable: true,
    displayName: 'Part #',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  size: {
    searchable: true,
    sortable: true,
    displayName: 'Size',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  color: {
    searchable: true,
    sortable: true,
    filterable: true,
    displayName: 'Color',
    tableDisplay: true,
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
    columnWidth: 10,
  },
  weightAndMeasure: {
    searchable: true,
    sortable: false,
    filterable: true,
    displayName: 'Weights & Measures',
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN),
  },
  activeStatus: {
    displayName: 'Status',
    sortable: true,
    filterable: true,
    tableDisplay: true,
    columnWidth: 10,
    formDisplay: false,
    includeInFormQuery: true,
    formDisplayType: displayType(DISPLAY_TYPES.DROPDOWN, { options: ActiveInactive }),
  },
});

setSchemaOptions(Pot, {
  softDeletable: true,
});
