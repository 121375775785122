import * as _ from 'lodash';
import * as React from 'react';
import { Col, Form, FormGroup, Row } from 'client/components/third-party';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { optionsContainerGenerator, optionsContainerWithFilters } from 'client/hoc/options-container-generator';
import { FieldWrapper, LabeledSelect } from 'client/components/form';
import * as Map from 'client/components/map/map';
import * as MapWithDirections from 'client/components/map/map-with-directions';
import { Customer } from 'shared/schemas/customer';
import { Region } from 'shared/schemas/region';
import { Market } from 'shared/schemas/market';
import { MfcArea } from 'shared/schemas/mfc-area';
import * as Actions from 'client/actions/store';
import { SellDepartment } from 'shared/schemas/sell-department';
import { Store } from 'shared/schemas/store';
import { Merchandiser } from 'shared/schemas/merchandiser';
import { Supplier } from 'shared/schemas/supplier';
import { ThunkerDispatch } from 'client/types/redux-types';

const tableName = 'stores';
const formName = 'StoreDetailsForm';

/* eslint-disable react/no-multi-comp */
const Field = (p: any) => <FieldWrapper table={tableName} alwaysShowErrors={true} hideOptionalLabel={true} { ...p } />;

interface StateProps {
  point: Map.LatLong;
  directionsAddress: string | undefined;
  newRecord: boolean;
  customerId: number | null;
  regionId: number | null;
  storeMfcAreasId: number[];
  merchandisingSupervisorId: number | null;
  primarySupplierId: number | null;
}

interface DispatchProps {
  handleCustomerChanged: (customerId: number) => void;
  handleRegionChanged: (regionId: number) => void;
  handleMfcAreaChanged: (index: number, mfcAreaId: number) => void;
  handleMerchandiserClicked: (id: number) => void;
}

interface OwnProps extends StateProps, DispatchProps {
  pristine: boolean;
  invalid: boolean;
  submitting: boolean;
  formName: string;
  handleSubmit: (value: any) => any;
  customers: WithCustomerPropsCustomer[];
  customersLoading?: boolean;
  merchandisers: WithMerchandiserProps[];
  merchandisersLoading?: boolean;
  suppliers: WithSupplierProps[];
  suppliersLoading?: boolean;
  regions: WithRegionsPropsRegion[];
  regionsLoading?: boolean;
  markets: WithMfcAreaPropsMfcArea[];
  marketsLoading?: boolean;
  comparableStores: WithComparableStoreProps[];
  comparableStoresLoading?: boolean;
  mfcAreasBySellDepartment: Array<{ [key: string]: WithMfcAreaPropsMfcArea }>;
  record: Store;
}

interface CustomerForm {
  customerId: string;
  regionId: string;
  latitude: string;
  longitude: string;
  id: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  mfcAreasId?: number[];
  merchandisingSupervisorId: number | null;
  primarySupplierId: number | null;
}

const mapStateToProps = (state: any): StateProps => {
  const values = getFormValues(formName)(state) as CustomerForm;

  const storeMfcAreasId = values?.mfcAreasId ? values.mfcAreasId : [];

  const newRecord = (values?.id) ? false : true;

  if (!values) {
    return {
      directionsAddress: '',
      newRecord,
      point: {
        valid: false,
      },
      customerId: null,
      regionId: null,
      storeMfcAreasId,
      merchandisingSupervisorId: null,
      primarySupplierId: null,
    };
  }

  let directionsAddress;
  if (values.address1 && values.city && values.state && values.zip) {
    directionsAddress = `${values.address1}, ${values.city}, ${values.state}, ${values.zip}`;
  }
  const point: Map.LatLong = values.latitude && values.longitude
    ? {
      lat: Number.parseFloat(values.latitude),
      lng: Number.parseFloat(values.longitude),
      valid: true,
    }
    : { valid: false };

  return {
    directionsAddress,
    newRecord: values?.id ? false : true,
    point,
    customerId: Number.parseInt(values.customerId, 10),
    regionId: Number.parseInt(values.regionId, 10),
    storeMfcAreasId,
    merchandisingSupervisorId: values.merchandisingSupervisorId,
    primarySupplierId: values.primarySupplierId,
  };
};
/**
 * https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 */
function formatPhoneNumber(phoneNumberString: string | null | undefined): string {
  // removes non-digital characters
  const numbersOnly = (phoneNumberString || '').replace(/\D/g, '');
  // matches 3 digits, 3 digits, 4 digits
  const matchedNumber = numbersOnly.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (matchedNumber) {
    // match[0] is the whole string, [1], [2], [3] are the capture groups defined above
    return `(${matchedNumber[1]}) ${matchedNumber[2]}-${matchedNumber[3]}`;
  }
  return '';
}
class Component extends React.Component<OwnProps, undefined> {
  constructor(props: OwnProps) {
    super(props);

  }

  findMerchandiserForDisplay(merchandiserId: number | null) {
    if (!merchandiserId || !this.props.merchandisers) {
      return <div />;
    }
    const merchandiser = this.props.merchandisers.find(merch => merch.id === merchandiserId);
    if (merchandiser) {
      return <div>
        <Col sm={2}>{formatPhoneNumber(merchandiser.phone)}</Col>
        <Col sm={3}><a href={`mailto:${merchandiser.email}`}>{merchandiser.email}</a></Col>
        <span className="fa fa-pencil mfc-edit-icon" onClick={() => merchandiser.id && this.props.handleMerchandiserClicked(merchandiser.id)}/>
      </div>;
    } else {
      return <div />;
    }

  }

  public render() {
    const {
      handleSubmit,
      customers,
      merchandisers,
      suppliers,
      regions,
      markets,
      comparableStores,
      point,
      directionsAddress,
      newRecord,
      mfcAreasBySellDepartment,
    } = this.props;

    return (
      <div data-testid="store-details">
        <Col sm={2}>
          {!newRecord &&
            <MapWithDirections.MapWithLink point={point} targetAddress={directionsAddress} />}
        </Col>
        <Col sm={10}>
          <Form horizontal onSubmit={handleSubmit} data-testid="details">
            <div data-testid="store">
              <Row>
                <div className="mfc-form-heading">
                  Overview
                </div>
                <FormGroup data-testid="overview-row-1">
                  <Field name="identifier" labelColSize={2} inputColSize={3} autoFocus={!this.props.record || !this.props.record.id} />
                </FormGroup>
                <FormGroup data-testid="overview-row-2">
                  <Field name="customerId" labelColSize={2} inputColSize={8} options={customers} loading={this.props.customersLoading} handleChange={this.props.handleCustomerChanged} disabled={!newRecord}/>
                </FormGroup>
                <FormGroup data-testid="overview-row-3">
                  <Field name="regionId" labelColSize={2} inputColSize={8} options={regions} loading={this.props.regionsLoading} handleChange={this.props.handleRegionChanged} />
                </FormGroup>
                <FormGroup data-testid="overview-row-4">
                  <Field name="marketId" labelColSize={2} inputColSize={8} options={markets} loading={this.props.marketsLoading} />
                </FormGroup>
                <FormGroup data-testid="overview-row-5">
                  <Field name="comparableStoreId" labelColSize={2} inputColSize={8} options={comparableStores} loading={this.props.comparableStoresLoading} />
                </FormGroup>
                <FormGroup data-testid="overview-row-6">
                  <Field name="openDate" labelColSize={2} inputColSize={2} horizontalLabel />
                  <Field name="closeDate" labelColSize={2} inputColSize={2} horizontalLabel  />
                </FormGroup>
                {!newRecord &&
                  <FormGroup data-testid="overview-row-7">
                    <Field name="primaryGlobal" labelColSize={2} inputColSize={2} disabled />
                  </FormGroup>
                }
                <FormGroup data-testid="overview-row-8">
                  <Field name="encoreStore" labelColSize={2} inputColSize={2} />
                </FormGroup>
                {mfcAreasBySellDepartment && Object.keys(mfcAreasBySellDepartment).length > 0 &&
                  <div className="mfc-form-heading">
                    MFC Areas
                  </div>
                }

                {mfcAreasBySellDepartment && Object.keys(mfcAreasBySellDepartment).map((sellDepartmentId, index) => {
                  const mfcAreas = this.props.record && this.props.record.mfcAreas ? this.props.record.mfcAreas : [];

                  let mfcAreaIndex;

                  if (mfcAreas.length > 0) {
                    mfcAreaIndex = mfcAreas.findIndex(mfcArea => mfcArea.sellDepartment.id === Number.parseInt(sellDepartmentId));

                    if (mfcAreaIndex === -1) {
                      mfcAreaIndex = mfcAreas.length;
                    }
                  } else {
                    mfcAreaIndex = index;
                  }

                  const meta = { touched: false, error: '', warning: '', submitting: false };
                  const input = {
                    name: `mfcArea${sellDepartmentId}`,
                    value: this.props.storeMfcAreasId[mfcAreaIndex],
                    onChange: (value: number) => {
                      this.props.handleMfcAreaChanged(mfcAreaIndex, value);
                    },
                    // eslint-disable-next-line no-empty
                    onBlur: value => {
                    },
                  };

                  const mfcAreasForSellDepartment = mfcAreasBySellDepartment[sellDepartmentId] as WithMfcAreaPropsMfcArea[];
                  const sellDepartmentIdentifier = mfcAreasForSellDepartment?.length > 0 ? mfcAreasForSellDepartment[0].sellDepartment.identifier : undefined;

                  return (
                    <FormGroup key={`mfc-area-group-${index}`} data-testid="overview-row-5">
                      <LabeledSelect
                        input={input}
                        key={`mfc-area-${index}`}
                        inputColSize={8}
                        label={`${_.startCase(_.toLower(sellDepartmentIdentifier))}`}
                        labelColSize={2}
                        meta={meta}
                        offset={0}
                        testid={`mfc-area-for-sell-department-${sellDepartmentId}`}
                        textFormatter={value => value.identifier}
                        valueField="value"
                        options={mfcAreasBySellDepartment[sellDepartmentId]}
                        alwaysShowErrors={true}
                        hideOptionalLabel={true} />
                    </FormGroup>
                  );
                })}
              </Row>
              <Row>
                <div className="mfc-form-heading">
                  Store Location
                </div>
                <FormGroup data-testid="location-row-1">
                  <Field label="Address 1" name="address1" labelColSize={2} inputColSize={8} />
                </FormGroup>
                <FormGroup data-testid="location-row-2">
                  <Field name="address2" labelColSize={2} inputColSize={8} />
                </FormGroup>
                <FormGroup data-testid="location-row-3">
                  <Field name="city" labelColSize={2} inputColSize={3} />
                  <Field name="state" labelColSize={1} inputColSize={1} />
                  <Field name="zip" labelColSize={2} inputColSize={1} />
                </FormGroup>
                <FormGroup data-testid="location-row-4">
                  <Field name="latitude" labelColSize={2} inputColSize={3} />
                  <Field name="longitude" labelColSize={1} inputColSize={3} />
                </FormGroup>
              </Row>
              <Row>
                <div className="mfc-form-heading">
                  Merchandisers
                </div>
                <FormGroup data-testid="primary-supplier-row">
                  <Field name="primarySupplierId" labelColSize={2} inputColSize={3} options={suppliers} loading={this.props.suppliersLoading} />
                </FormGroup>
                <FormGroup data-testid="merchandising-supervisor-row" className="bottom-margin-for-dropdown">
                  <Field name="merchandisingSupervisorId" labelColSize={2} inputColSize={3} options={merchandisers} loading={this.props.merchandisersLoading} />
                  {this.findMerchandiserForDisplay(this.props.merchandisingSupervisorId)}
                </FormGroup>
              </Row>
            </div>
          </Form>
        </Col>
      </div>
    );
  }
}

export const mapDispatchToProps = (dispatch: ThunkerDispatch, props: OwnProps & WithRegionsProps): DispatchProps => {
  return {
    handleCustomerChanged: (customerId: number) => dispatch(Actions.customerChanged(formName, customerId)),
    handleRegionChanged: (regionId: number) => dispatch(Actions.regionChanged(formName, regionId)),
    handleMfcAreaChanged: (index: number, mfcAreaId: number) => dispatch(Actions.mfcAreaChanged(formName, index, mfcAreaId)),
    handleMerchandiserClicked: (id: number) => dispatch(Actions.merchandiserClicked(id)),
  };
};

type WithCustomerPropsCustomer = Pick<Customer, 'identifier' | 'name'>;

const withCustomers = optionsContainerGenerator({ table: 'customers', columns: ['identifier', 'name'] });

type WithRegionsPropsRegion = Pick<Region, 'identifier' | 'description'>;

interface WithRegionsProps {
  regions: WithRegionsPropsRegion[];
}

const withRegions = optionsContainerWithFilters<WithRegionsProps>({
  table: 'regions',
  columns: ['identifier', 'description'],
  getFilters: (props: OwnProps) => {
    if (!props.customerId) {
      return [
        { field: 'customer', values: ['-1'] },
      ];
    }

    return [
      { field: 'customer', values: [props.customerId.toString()] },
    ];
  },
});

type WithMarketPropsMarket = Pick<Market, 'identifier'>;

interface WithMarketProps {
  markets: WithMarketPropsMarket[];
}

const withMarkets = optionsContainerWithFilters<WithMarketProps>({
  table: 'markets',
  columns: ['identifier', 'description'],
  getFilters: (props: OwnProps) => {
    if (!props.regionId) {
      return [
        { field: 'region', values: ['-1'] },
      ];
    }

    return [
      { field: 'region', values: [props.regionId.toString()] },
    ];
  },
});

type WithComparableStorePropsComparableStore = Pick<Store, 'identifier'>;

interface WithComparableStoreProps {
  stores: WithComparableStorePropsComparableStore[];
}

const withComparableStores = optionsContainerWithFilters<WithComparableStoreProps>({
  table: 'stores',
  columns: ['identifier'],
  resultPropName: 'comparableStores',
  getFilters: (props: OwnProps) => {
    if (!props.customerId) {
      return [
        { field: 'customerId', values: ['-1'] },
      ];
    }

    return [
      { field: 'customerId', values: [props.customerId.toString()] },
    ];
  },
});

type WithMfcAreaPropsMfcArea = { sellDepartment: Pick<SellDepartment,  'id' | 'identifier'> } & Pick<MfcArea, 'identifier'>;

interface WithInternalMfcAreaProps {
  mfcAreas: WithMfcAreaPropsMfcArea[];
}

interface WithMfcAreaProps {
  mfcAreasBySellDepartment: Array<{ [key: string]: WithMfcAreaPropsMfcArea }>;
}

const withMfcAreas = optionsContainerWithFilters<WithMfcAreaProps>({
  table: 'mfcAreas',
  columns: ['identifier', 'sellDepartment'],
  getFilters: (props: OwnProps) => {
    if (!props.customerId) {
      return [];
    }
    return [
      { field: 'customer', values: [props.customerId.toString()] },
    ];
  },
  props: ({ data: { content } }: { data: { content: WithInternalMfcAreaProps[] } }) => {
    return {
      mfcAreasBySellDepartment: content ? _.groupBy(content, _.property('sellDepartment.id')) : [],
    };
  },
  skip: (props: OwnProps) => {
    return !props.customerId;
  },
});

type WithMerchandiserProps = Pick<Merchandiser, 'id' | 'name' | 'phone' | 'email'>;
const withMerchandisers = optionsContainerWithFilters({
  table: 'merchandisers',
  columns: ['name', 'phone', 'email'],
  getFilters: (props: OwnProps) => [/* no special filtering for choice of Merchandiser */],
});

type WithSupplierProps = Pick<Supplier, 'id' | 'name'>;
const withSuppliers = optionsContainerGenerator({ table: 'suppliers', columns: ['name'] });

export default _.flowRight(
  withCustomers,
  withMerchandisers,
  withSuppliers,
  connect(mapStateToProps, mapDispatchToProps),
  withRegions,
  withMarkets,
  withComparableStores,
  withMfcAreas,
)(Component);
