import { noop } from 'lodash';
import { SelectableColumn, SelectableRow } from './types';
import * as classnames from 'classnames';
import * as React from 'react';

export interface RowProps {
  cols: SelectableColumn[];
  highlighted: boolean;
  onUnchecked?: (row: SelectableRow) => void;
  onMouseOver?: (row: SelectableRow) => void;
  onChecked?: (row: SelectableRow) => void;
  row: SelectableRow;
  rowIndex: number;
  checked: boolean;
}

const ButtonCellWidth = 20;

export const Row = (props: RowProps) => {
  function onRowUnchecked() {
    return props.onUnchecked
      ? props.onUnchecked(props.row)
      : noop;
  }

  function onRowChecked() {
    return props.onChecked
      ? props.onChecked(props.row)
      : noop;
  }

  function onMouseOver() {
    return props.onMouseOver
      ? props.onMouseOver(props.row)
      : noop;
  }

  return (
    <div
      onMouseEnter={onMouseOver}
      className={classnames('selectable-row', {
        'selectable-row-checked': props.checked,
        'selectable-row-highlighted': props.highlighted,
      })}
      onClick={onRowChecked}
    >
      <div
        onClick={onRowUnchecked}
        className={classnames('', {
          'selectable-row-remove-button': props.checked,
          'selectable-row-no-button': !props.checked,
        })}
      >
        {props.checked &&
          <div className="indicator"></div>
        }
      </div>
      {props.row.cells
        .map((cell, colIndex) => {
          const width = props.cols.length === props.row.cells.length
            ? `calc(${props.cols[colIndex].width}% - ${(ButtonCellWidth / props.row.cells.length)}px)`
            : '10px';

          const value = typeof cell === 'function'
            ? `${cell()}`
            : `${cell}`;

          return <div
            key={colIndex}
            style={{
              width,
            }}
            data-row-index={props.rowIndex}
            data-col-index={colIndex}
            data-value={value}
            className={classnames('selectable-row-cell', { 'selectable-row-cell-selected': props.checked })}
          >
            {cell}
          </div>;
        })
      }
    </div>
  );
};
