import * as React from 'react';
import { Modal, Col } from 'client/components/third-party';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { ImportRouteModalHeader } from 'client/app/transportation/routing/import-route-modal/import-route-modal-header';
import StepOne from './step-one/import-route-modal-step-one-container';
import StepTwo from './step-two/import-route-modal-step-two-container';
import StepThree from './step-three/import-route-modal-step-three-container';
import { AsyncButton } from 'client/components/async-button/async-button-container';
import { MutationStatus } from 'client/actions/mutations';
import { ImportRouteProcessStep } from 'client/state/import-route';
import { ProgressSpinner } from 'client/components/progress-spinner';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

export interface ComponentProps {
  routePlanId?: number;
  isShown: boolean;
  client: ApolloClient<NormalizedCacheObject>;
  handleCancelButtonClicked(): void;
  handleNextButtonClicked(): void;
  handlePreviousButtonClicked(): void;
  handleFinishButtonClicked(): void;
  cancelButtonDisabled: boolean;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
  showNextButton: boolean;
  showFinishButton: boolean;
  finishButtonDisabled: boolean;
  preventEscFromClosingModal: boolean;
  processStep: ImportRouteProcessStep;
}

export class ImportRouteModalUI extends React.PureComponent<ComponentProps, {}> {
  render() {
    if (!this.props.isShown) {
      return <div />;
    }

    const modalContent = (processStep: ImportRouteProcessStep): JSX.Element => {
      switch (processStep) {
        case ImportRouteProcessStep.RouteInformation:
          return (
            <Col sm={12}>
              <StepOne routePlanId={this.props.routePlanId} />
            </Col>
          );
        case ImportRouteProcessStep.ImportFile:
          return (
            <Col sm={12}>
              <StepTwo />
            </Col>
          );
        case ImportRouteProcessStep.ProcessSplitStops:
          return <StepThree />;
        case ImportRouteProcessStep.SubmittingRoutes:
          return (
            <Col className="import-route-modal-spinner-container" sm={12}>
              <ProgressSpinner label={'Uploading...'} />
            </Col>
          );
      }
    };

    return (
      <Modal
        id="import-route-modal"
        animation
        backdrop="static"
        show={this.props.isShown}
        onHide={this.props.handleCancelButtonClicked}
        dialogClassName="import-route-modal-container"
        className="import-route-modal"

        {...(this.props.preventEscFromClosingModal
          ? {
              keyboard: false,
            }
          : {}
          )
        }
      >
        <GlobalAsyncModalHeader>Import Route File</GlobalAsyncModalHeader>
        <Modal.Title>
          <ImportRouteModalHeader processStep={this.props.processStep} />
        </Modal.Title>
        <Modal.Body>
          <div className="import-route-modal-content">
            {modalContent(this.props.processStep)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton
            onClick={this.props.handleCancelButtonClicked}
            disabled={this.props.cancelButtonDisabled}
          />
          <GlobalCancelButton
            label="Previous"
            testid="import-route-modal-previous"
            onClick={this.props.handlePreviousButtonClicked}
            disabled={this.props.previousButtonDisabled}
          />
          {this.props.showNextButton && <GlobalSaveButton
            label="Next"
            testid="import-route-modal-next"
            disabled={this.props.nextButtonDisabled}
            onClick={this.props.handleNextButtonClicked}
            classNames={['mfc-button-primary']}
          />}
          {this.props.showFinishButton && <AsyncButton
            label="Finish"
            testid="import-route-modal-finish"
            type={undefined}
            disabled={this.props.finishButtonDisabled}
            onClick={this.props.handleFinishButtonClicked}
            actionStatus={MutationStatus.Initial}
          />}
        </Modal.Footer>
      </Modal>
    );
  }
}
