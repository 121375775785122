import { tableName, property, belongsTo, definePresentation, required } from 'shared/schemas/dsl';
import { IRecord } from 'shared/schemas/record';
import { RemittanceAdvice } from 'shared/schemas/remittance-advice';
import { DateStr, DISPLAY_TYPES } from 'shared/types';
import { displayType } from './dsl';
import { ImportJob } from 'shared/schemas/import-job';

@tableName('remittanceAdviceDetails')
export class RemittanceAdviceDetail implements IRecord {
  id?: number;

  @belongsTo('remittanceAdvices')
  @property @required remittanceAdvice: Partial<RemittanceAdvice>;
  remittanceAdviceId: number;

  @belongsTo('importJobs', { foreignQueryKeys: [] })
  @property importJob?: ImportJob;
  importJobId?: number;

  @property sourceType?: string | null;
  @property sourceIdentifier?: string | null;
  @property net?: number | null;
  @property gross?: number | null;
  @property discount?: number | null;
  @property reasonCode?: string | null;
  @property referenceType?: string | null;
  @property referenceIdentifier?: string | null;
  @property creditMemoDate?: DateStr | null;
}

definePresentation(RemittanceAdviceDetail, {
  remittanceAdvice: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  sourceType: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  sourceIdentifier: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  net: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  gross: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  discount: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  reasonCode: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  referenceType: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  referenceIdentifier: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  creditMemoDate: {
    formDisplayType: displayType(DISPLAY_TYPES.INPUT),
  },
  importJob: { formDisplay: false },
});
