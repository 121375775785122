import * as React from 'react';
// import { Form } from '../form';
import FilterGroup from './filter-group';
import SearchBox from './search-box';

export const Toolbar: React.StatelessComponent<{}> = props => {
  return (
    <div className="row toolbar-pf table-view-pf-toolbar mfc-toolbar">
      <form className="toolbar-pf-actions form-inline">
        {props.children}
      </form>
    </div>
  );
};

export { FilterGroup, SearchBox };
