import * as React from 'react';
import * as _ from 'lodash';
import { ReadonlyTable } from 'client/components/readonly-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { createSelector } from 'reselect';
import * as ReadOnlyTableTypes from 'client/components/readonly-table/types';

export interface UIProps {
  rows: any[];
  onImportButtonClicked(): void;
  startWeek: number | undefined;
  endWeek: number | undefined;
  loading: boolean;
}

const HEIGHT_FUDGE_FACTOR = 150;

const getColumns = createSelector([
  (props: UIProps) => props.startWeek,
  (props: UIProps) => props.endWeek,
], (startWeek, endWeek): ReadOnlyTableTypes.ColumnDef[] => {
  const weekColumns = startWeek && endWeek
    ? _.range(startWeek, endWeek + 1).map(weekNumber => {
      return {
        header: `WK ${weekNumber}`,
        accessor: `week-${weekNumber}`,
        contentType: ReadOnlyTableTypes.ColumnContentType.Amount,
        width: 60,
      };
    })
    : [];

  return [
    {
      header: 'Prod ID',
      accessor: 'productIdentifier',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 100,
    },
    {
      header: 'Description',
      accessor: 'description',
      contentType: ReadOnlyTableTypes.ColumnContentType.Text,
      width: 100,
    },
    {
      header: 'Cost',
      accessor: 'cost',
      contentType: ReadOnlyTableTypes.ColumnContentType.Money,
      width: 100,
    },
    {
      header: 'Total Units',
      accessor: 'totalUnits',
      contentType: ReadOnlyTableTypes.ColumnContentType.Amount,
      width: 100,
    },
    ...weekColumns,
  ];
});

const defaultSort: ReadOnlyTableTypes.SortDef = {
  columnHeader: 'Prod ID',
  order: 'asc',
};
export function SupplierCommitmentAllocationTableUI(props: UIProps) {
  const self = React.useRef(null as any);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <ReadonlyTable
          tableName="supplier-commitment-allocations"
          columns={getColumns(props)}
          data={props.rows}
          height={height - HEIGHT_FUDGE_FACTOR}
          width={width}
          defaultSort={defaultSort}
          growableColumnHeader={'Description'}
          loading={props.loading}
          self={self}
          showFooter={false}
        >
          <div style={{ padding: 10, height: 45 }}>
            <ImportSupplierCommitmentButton onClick={props.onImportButtonClicked}/>
          </div>
        </ReadonlyTable>
      )}
    </AutoSizer>
  );
}

interface ButtonProps {
  onClick(): void;
}
function ImportSupplierCommitmentButton(props: ButtonProps) {
  return (
    <div style={{ float: 'right' }} >
      <div className="readonly-table-topper-button" data-testid="import-supplier-commitment-button" onClick={props.onClick}>
        Import Supplier Commitment from Excel
      </div>
    </div>
  );
}
