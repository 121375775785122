import gql from 'graphql-tag';

export const ImportProductPriceMutation = gql`
  mutation ImportProductPriceMutation($input: ImportProductPriceInput!) {
    importProductPrice: ImportProductPrice(input: $input) {
      success
      errors
    }
  }
`;
