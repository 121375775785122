import * as _ from 'lodash';
import { FieldValidator } from 'shared/validators/types';
import { requiredMessages, isPresent } from 'shared/validators/required';
import { DateStr, SelectableValue } from 'shared/types';
import { formatDateStrForDisplay, isSameOrAfter } from 'shared/helpers';

const isNumber = _.isNumber;

export type Predicate<TRecord> = (record: TRecord) => boolean;

export type FieldValidatorCombinator = (...validators: FieldValidator[]) => FieldValidator[];

export function whenAll<TRecord>(messagePrefix: string | null, ...predicates: Array<Predicate<TRecord>>): FieldValidatorCombinator {
  return (...validators: FieldValidator[]) => validators.map((fieldValidator: FieldValidator) => ({
    isServerSideOnly: fieldValidator.isServerSideOnly,
    isValid(value: any, record: TRecord) {
      return !predicates.every(p => p(record)) || fieldValidator.isValid(value, record);
    },
    shortMessage(value: any, record: TRecord) {
      return fieldValidator.shortMessage(value, record);
    },
    message(label: string, value: any, record: TRecord) {
      return (messagePrefix || '') + fieldValidator.message(label, value, record);
    },
  }));
}

export const REQUIRED: FieldValidator = {
  isValid(value: any, record: any) {
    return isPresent(value);
  },
  ...requiredMessages,
};

export const REQUIRED_SELECTABLE: FieldValidator = {
  isValid(value: SelectableValue | string | undefined | null, record: any) {
    if (typeof value === 'string') {
      return false;
    }

    return !!(value && value.values.length > 0);
  },
  ...requiredMessages,
};

export const REQUIRED_IF_EDITING: FieldValidator = {
  isValid(value: any, record: any) {
    return _.isNil(record.id) || isPresent(value);
  },
  ...requiredMessages,
};

export const MAX_VALUE = (maxValue): FieldValidator => ({
  isServerSideOnly: false,

  isValid(value: any, record: any) {
    if (_.isNil(value)) {
      return true;
    }

    if (isNumber(value)) {
      return value <= maxValue;
    } else {
      const num = Number(value);
      return !isNaN(num) && num <= maxValue;
    }
  },
  shortMessage(value: any, record: any) {
    return `Must be ≤ ${maxValue}`;
  },
  message(label: string, value: any, record: any) {
    return `${label} must be less than or equal to ${maxValue}.`;
  },
});

export const GREATER_THAN_OR_EQUAL_TO_ZERO: FieldValidator = {
  isValid(value: number | string, record: any) {
    if (!isPresent(value)) {
      return true;
    }

    if (isNumber(value)) {
      return value >= 0;
    } else {
      const num = Number(value);
      return !isNaN(num) && num >= 0;
    }
  },
  shortMessage(value: any, record: any) {
    return 'Must be ≥ 0';
  },
  message(label: string, value: any, record: any) {
    return `${label} must be greater than or equal to zero.`;
  },
};

const makeGreaterThanZero = (args: { isServerSideOnly: boolean }): FieldValidator => {
  return {
    isServerSideOnly: args.isServerSideOnly,

    isValid(value: number | string, record: any) {

      if (!isPresent(value)) {
        return true;
      }

      if (isNumber(value)) {
        return value > 0;
      } else {
        const num = Number(value);
        return !isNaN(num) && num > 0;
      }
    },
    shortMessage(value: any, record: any) {
      return 'Must be > 0';
    },
    message(label: string, value: any, record: any) {
      return `${label} must be greater than zero.`;
    },
  };
};

export const GREATER_THAN_ZERO_SERVER_SIDE_ONLY: FieldValidator = makeGreaterThanZero({ isServerSideOnly: true });
export const GREATER_THAN_ZERO: FieldValidator = makeGreaterThanZero({ isServerSideOnly: false });

export const INCLUSION = (values): FieldValidator => ({
  isServerSideOnly: false,

  isValid(value: any, record: any) {
    if (_.isNil(value)) {
      return true;
    }

    if (Array.isArray(values)) {
      return values.includes(value);
    }

    if (_.isPlainObject(values)) {
      return _.values(values).includes(value);
    }

    console.warn(`cannot validate inclusion of ${value} in`, values, 'is not an object or array');
    return true;
  },
  shortMessage(value: any, record: any) {
    return 'Not a valid option';
  },
  message(label: string, value: any, record: any) {
    return `${label} is not a valid option.`;
  },
});

export const VALID_PPS_SPR_CALCULATION: FieldValidator = {
  isValid(value: number | string, record: any) {
    if (!isPresent(value) || !isPresent(record.shelvesPerRack) || !isPresent(record.packsPerShelf)) {
      return true;
    }

    const product = Number(value);
    const shelvesPerRack = Number(record.shelvesPerRack);
    const packsPerShelf = Number(record.packsPerShelf);

    if (isNaN(product) || isNaN(shelvesPerRack) || isNaN(packsPerShelf)) {
      return false;
    } else if (product && (!shelvesPerRack || !packsPerShelf)) {
      return true;
    }

    const actualValue = packsPerShelf * shelvesPerRack;
    const roundedDownValue = _.floor(actualValue);
    return product === actualValue || product === roundedDownValue;
  },
  shortMessage(value: any, record: any) {
    return 'Must equal SPR * PPS';
  },
  message(label: string, value: any, record: any) {
    return `${label} (${value}) does not add up for the given SPR (${record.shelvesPerRack}) * PPS (${record.packsPerShelf}).`;
  },
};

export const MAX_LENGTH = (maxLength: number): FieldValidator => ({
  isValid(value: string, record: any) {
    return !value || value.length <= maxLength;
  },
  shortMessage(value: any, record: any) {
    return `Must be ≤ ${maxLength} characters`;
  },
  message(label: string, value: any, record: any) {
    return `${label} must not exceed ${maxLength} characters in length.`;
  },
});

export const MATCHES = (pattern: RegExp): FieldValidator => ({
  isValid(value: string, record: any) {
    return pattern.test(value);
  },
  shortMessage(value: any, record: any) {
    return `Must match pattern: '${pattern.source}'`;
  },
  message(label: string, value: any, record: any) {
    return `${label} Must match pattern: '${pattern.source}'`;
  },
});

export const MATCHES_OR_IS_NULL = (pattern: RegExp): FieldValidator => ({
  isValid(value: string, record: any) {
    return value === undefined || value === null || pattern.test(value);
  },
  shortMessage(value: any, record: any) {
    return `Must match pattern: '${pattern.source}' or be nullish.`;
  },
  message(label: string, value: any, record: any) {
    return `${label} Must match pattern: '${pattern.source}' or be nullish.`;
  },
});

export const CAPITALIZED: FieldValidator = {
  isValid(value: string, record: any) {
    return _.isNil(value) || value === value.toUpperCase();
  },
  shortMessage(value: any, record: any) {
    return 'Must be capitalized';
  },
  message(label: string, value: any, record: any) {
    return `${label} must be capitalized`;
  },
};

export const MINIMUM_DATE = (minimumDate: DateStr): FieldValidator => ({
  isValid(value: DateStr, record: any) {
    return isSameOrAfter(value, minimumDate);
  },
  shortMessage(value: any, record: any) {
    return `Date cannot be before ${formatDateStrForDisplay(minimumDate)}`;
  },
  message(label: string, value: any, record: any) {
    return `${label} cannot be before ${formatDateStrForDisplay(minimumDate)}`;
  },
});
