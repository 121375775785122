import * as React from 'react';
import * as _ from 'lodash';
import { withApollo } from 'react-apollo';
import { Modal, Form } from 'client/components/third-party';
import CustomerOrderDetailForm from './customer-order-detail-form';
import { reduxForm } from 'redux-form';
import { msyncMutation, MsyncMutationProps } from 'client/hoc/graphql/mutation';
import { propToComponent } from 'client/hoc/hoc';
import { GlobalAsyncModalHeader } from 'client/components/async-button/global-async-modal-header-container';
import { GlobalCancelButton } from 'client/components/async-button/global-cancel-button-container';
import { GlobalSaveButton } from 'client/components/async-button/global-save-button-container';
import { AddAllocationsToCustomerOrderMutation } from 'client/app/orders/customer-orders/overview/add-allocations-to-customer-order-mutation';
import { OrderMethod } from 'shared/types';

const formName = 'CustomerOrderDetailsAddProducts';

interface OwnProps {
  show: boolean;
  onClose: () => void;
  refetchTable: (x: any) => any;
  userConfirmation: () => Promise<boolean>;
  customerOrderId: number;
  customerId: number;
  orderType: string;
  mfcAreaId: number;
  orderMethod: string;
  shippingUnitType: string;
  sellDepartmentIdentifier: string;
  scanBased: boolean;

  isModal: boolean; // Needed somewhere in the filterable-table
  name: string;     // Not sure if this is actually needed. If it is it's also in the filterable table
}

interface ApolloProps {
  addProductsAndStores: (x: any) => any;
  client: { resetStore: () => void; };
}

interface ReduxFormProps {
  handleSubmit: React.ReactEventHandler<any>;
  reset: () => void;
}

type Props = OwnProps & ApolloProps & ReduxFormProps;

function CustomerOrderDetailModal({
  show,
  onClose,
  handleSubmit,
  reset,
  customerId,
  orderType,
  mfcAreaId,
  customerOrderId,
  orderMethod,
  shippingUnitType,
  addProductsAndStores,
  sellDepartmentIdentifier,
  scanBased,
  client: { resetStore },
  userConfirmation,
}: Props) {
  function handleCancel() {
    onClose();
    reset();
  }

  async function onSubmit({ quantity, productIds, storeIds }) {
    try {
      if (false === await userConfirmation()) {
        return;
      }

      const productsToAllocate = productIds.map(id => ({ productId: id }));
      await addProductsAndStores({ customerOrderId, storeIds, productsToAllocate, quantity, orderMethod });
      handleCancel();
      await resetStore();
    } catch (err) {
      // Should have been handled by global error handler (to display to user) so just log this
      console.info('Problem adding products and stores', err.message);
    }
  }

  // TODO: wonky typing excape hatchet: purge with extreme prejudice... as soon as we know how.
  const onSubmitHandler: any = handleSubmit(onSubmit as any);

  return (
    <Modal
      key="new-detail-modal"
      id="new-detail-modal"
      animation
      backdrop="static"
      show={show}
      onHide={handleCancel}>
      <Form horizontal onSubmit={onSubmitHandler} data-testid="customer-order-detail-form">
        <GlobalAsyncModalHeader>Add Product</GlobalAsyncModalHeader>
        <Modal.Body>
          <CustomerOrderDetailForm formName={formName} selectedCustomerId={customerId} selectedOrderType={orderType} selectedMfcAreaId={mfcAreaId} selectedOrderMethod={orderMethod} selectedShippingUnitType={shippingUnitType} selectedSellDepartmentIdentifier={sellDepartmentIdentifier} scanBased={scanBased}/>
        </Modal.Body>
        <Modal.Footer>
          <GlobalCancelButton onClick={handleCancel} />
          <GlobalSaveButton
              label={'Add'}
              testid={'modal-submit'}
              type="submit"
              disabled={false}
            />
        </Modal.Footer>
      </Form>
    </Modal>);
}

interface MutationInput {
  customerOrderId: number;
  storeIds: number[];
  productsToAllocate: any;
  quantity: number;
  orderMethod: OrderMethod;
}
interface WithMutationProps {
  addProductsAndStores: (input: MutationInput) => ReturnType<MsyncMutationProps<any, any, MutationInput> ['mutate']>;
}

const withMutation = msyncMutation< {}, {}, WithMutationProps>(AddAllocationsToCustomerOrderMutation, {
  props: props => ({
    addProductsAndStores: (payload: MutationInput) => props.mutate({ variables: payload }),
  }),
});

type Component<P> =
  new (props: P) => React.Component<P, any>;

const component = _.flowRight(
  reduxForm({ form: formName }),
  withApollo,
  withMutation,
)(CustomerOrderDetailModal) as Component<OwnProps>;

export default (props: OwnProps) => propToComponent(component, props);
