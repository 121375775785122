import { User } from 'shared/schemas/user';
import { GET, POST } from 'shared/send';
import { orThrow } from 'shared/errors';

/** Shape that was previously kept in LocalStorage; we ask the server for this to cause the server to verify the browser's auth cookie. */
export interface AuthenticatedUser extends Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> { token: string, hasuraToken?: string }

const api = (path: string) => `/api${path}`;
export const login = async user => await POST<AuthenticatedUser>(api('/auth/login'), user) || orThrow(`\n\tThe username or password you have entered is invalid.\n`);
export const getCurrentUserInfo = () => GET<AuthenticatedUser>(api('/user/current'));
export const logout = () => POST<{ redirectUrl: string }>(api('/auth/logout'));
