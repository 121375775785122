import * as React     from 'react';
import * as StatsApi  from '../../shared/stats-card-api';
import { StatsCard }  from './cards/stats-card';
import { SupplierOrderDetailsStatsRow } from 'client/app/orders/supplier-orders/overview/supplier-order-details-stats-row';

export const StatsRow = (props: { stats?: StatsApi.Card[] }) => {
  // We started seeing some rendering issues with the Customer Orders overview table
  // being rendered off the bottom of the page (the footer controls not visible unless
  // the page was resized). It seems the problem was that this stats row was not taking
  // up the desired height until after the table was fully rendered, so it didn't end up
  // sizing it correctly.
  //
  // The Supplier Order page does not have the same problem, so I'm just going to use the "blank"
  // placeholder version from that component here. Long term it would be nice if we didn't have these
  // different stats row implementations though.
  return props.stats && props.stats.length > 0 ? (
    <div className="stats-row">
      {props.stats.map(card => (<StatsCard key={card.name} cardContent={card} />))}
    </div>
  ) : (
      <SupplierOrderDetailsStatsRow />
    );
};
