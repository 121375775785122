import { change, getFormValues } from 'redux-form';
import * as Constants from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal/constants';
import { RoutePlanId } from 'schema/route-plan/route-plan-typescript-types';
import { Thunker } from 'client/types/redux-types';
import * as Types from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal/types';
import { Dispatch, Action } from 'redux';
import { msyncClientMutation } from 'client/hoc/graphql/mutation';
import { isNil } from 'lodash';
import { AddAdditionalStoreToRoutePlanMutationResponse, AddAdditionalStoreToRoutePlanMutation } from 'client/app/transportation/routing/route-plan-details/review-stores/add-store-modal/mutation';

export type ActionTypes =
  AddStoreMutationSucceededAction |
  AddStoreMutationFailedAction |
  CancelButtonClickedAction;

export enum ActionTypeKeys {
  ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY = 'App/ROUTE_PLAN_SET_ATTACH_ORDERS_MODAL_VISIBILITY',
  ROUTE_PLAN_DOWNLOAD_RECEIVING_FORM = 'App/ROUTE_PLAN_DOWNLOAD_RECEIVING_FORM',
  AddStoreModalCancelButtonClicked = 'App/AddStoreModalCancelButtonClicked',
  AddStoreMutationSucceededAction = 'App/AddStoreMutationSucceededAction',
  AddStoreMutationFailedAction = 'App/AddStoreMutationFailedAction',
}

export const customerChanged = (customerId: number) => {
  return async dispatch => {
    dispatch(change(Constants.formName, 'customerId', customerId));
    dispatch(change(Constants.formName, 'storeId', null));
  };
};

const sendAddStoreToRoutePlanMutation = async (dispatch: Dispatch<any>, routePlanId: number, storeId: number, numPallets: number, numRacks: number): Promise<void> => {
  await msyncClientMutation<AddAdditionalStoreToRoutePlanMutationResponse>({
    mutation: AddAdditionalStoreToRoutePlanMutation,
    variables: {
      routePlanId,
      storeId,
      numberOfPallets: numPallets,
      numberOfRacks: numRacks,
    },
    refetchQueries: ['GetReviewStoresCustomerOrderAllocations'],
    dispatch,
  });
};

export const addStoreButtonClicked = (routePlanId: RoutePlanId): Thunker => {
  return async (dispatch, getState) => {
    const state = getState();
    const values = getFormValues(Constants.formName)(state) as Types.FormValues;
    if (isNil(values.storeId)) {
      throw new Error('Store Id not available on save');
    }
    try {
      await sendAddStoreToRoutePlanMutation(dispatch, routePlanId, values.storeId, values.numPallets || 0, values.numRacks || 0);
      dispatch(addStoreMutationSucceeded());
    } catch {
      dispatch(addStoreMutationFailed());
    }
  };
};

export interface CancelButtonClickedAction extends Action {
  type: ActionTypeKeys.AddStoreModalCancelButtonClicked;
}
export const cancelButtonClicked = (): CancelButtonClickedAction => {
  return {
    type: ActionTypeKeys.AddStoreModalCancelButtonClicked,
  };
};

export interface AddStoreMutationSucceededAction extends Action {
  type: ActionTypeKeys.AddStoreMutationSucceededAction;
}
export const addStoreMutationSucceeded = (): AddStoreMutationSucceededAction => {
  return {
    type: ActionTypeKeys.AddStoreMutationSucceededAction,
  };
};

export interface AddStoreMutationFailedAction extends Action {
  type: ActionTypeKeys.AddStoreMutationFailedAction;
}
export const addStoreMutationFailed = (): AddStoreMutationFailedAction => {
  return {
    type: ActionTypeKeys.AddStoreMutationFailedAction,
  };
};
