import gql from 'graphql-tag';
import * as GraphQLTypes from 'schema/invoice/invoice-graphql-types';

export interface Input {
  invoiceId: number;
}

interface Invoice {
  id: GraphQLTypes.Invoice['id'];
  identifier: GraphQLTypes.Invoice['identifier'];
  lastModifiedAt: GraphQLTypes.Invoice['lastModifiedAt'];
  customerOrder?: {
    id: number;
    identifier: string;
  };
}
export interface QueryResponse {
  getRecord?: {
    invoice: Invoice;
  };
}

export const Query = gql`
  query InvoiceRecordBarQuery($invoiceId: Int!) {
    getRecord: GetInvoice(id: $invoiceId) {
      invoice {
        id
        identifier
        lastModifiedAt
        ... on CustomerOrderInvoice {
          id
          customerOrder {
            id
            identifier
          }
        }
      }
    }
  }
`;
