import { buildAdminDetailPage } from '../admin/admin-detail-page';
import FormComponent from '../../components/crud-forms/tray-form';
import { push } from 'connected-react-router';

export default buildAdminDetailPage({
  table: 'Tray',
  formName: 'traysDetailForm',
  FormComponent,
  descriptionResolver: tray => tray.description,
  recordBarActionMenuItems: [
    {
      label: 'Duplicate Record',
      onClickAction: id => push(`/admin/trays/create?id=${id}`),
    },
  ],
});
